import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import JsonForm from 'components/dynamic-form/json-form';
import { editTasks } from 'actions/action-tasks';
import { addTaskOldValues } from 'services/utils/task-service';
import { taskAbbreviationMap } from 'constants/index';
import { fetchResourceLink } from 'actions/action-resource-links';
import { workListChanged } from 'actions/action-patient';
import providerBuilder from '../providers/provider-builder';
import taskProviderHoc from './task-form-hoc';

function getPayload(updatedTask, task, selectedPatientId, taskType) {
  const payload = addTaskOldValues(
    {
      ...updatedTask,
      id: task.id,
      patient_id: selectedPatientId,
      therapy_id: task.therapy_id,
      taskType,
      service_group_id: updatedTask.service_group_id || task.service_group_id,
    },
    task,
  );

  return payload;
}

const TaskStatusForm = props => {
  const {
    task,
    task: { taskType } = {},
    forceStatusId,
    selectedPatientId,
    onCancel,
    editTasks, // eslint-disable-line
    workListChanged, // eslint-disable-line
    fetchResourceLink, // eslint-disable-line
  } = props;
  const data = { ...task };
  const providers = providerBuilder(taskType)(data, props);
  return (
    <>
      <JsonForm
        type={taskType}
        formName="therapiesEdit"
        formId={`${taskType}-edit-${task.id}`}
        jsonForm={providers.json}
        data={data}
        forceStatusId={forceStatusId}
        providers={providers}
        alwaysEnabled
        onSubmit={updatedTask => {
          const payload = getPayload(updatedTask, task, selectedPatientId, taskType);

          return editTasks(selectedPatientId, [payload], null, []).then(() => {
            if (onCancel) onCancel();
            workListChanged();
            fetchResourceLink(
              Object.keys(taskAbbreviationMap).find(it => taskAbbreviationMap[it] === taskType),
              task.id,
            );
          });
        }}
        onCancel={onCancel}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    selectedPatientId: state.selectedPatientId,
    auth: state.auth,
  };
}

export default compose(
  connect(mapStateToProps, {
    editTasks,
    workListChanged,
    fetchResourceLink,
  }),
  taskProviderHoc,
)(TaskStatusForm);

import { Theme } from '@mui/material';
import { DefaultTheme } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

export const PatientMergeStyles = (theme: Theme | DefaultTheme) => {
  const { palette, fonts } = expandTheme(theme);
  return createStyles({
    dialog: {
      marginTop: theme.spacing(8),
    },
    nextButton: {
      color: palette.primary.white,
      backgroundColor: palette.primary.trellisBlue,
    },
    profileSelectLabel: {
      color: palette.primary.steelGrey,
      fontSize: fonts.mediumFontSize,
      fontWeight: fonts.weight.medium,
    },
    profileHeader: {
      backgroundColor: palette.primary.lightRed,
      color: palette.primary.white,
      padding: theme.spacing(1, 2),
    },
    profileHeaderTitle: {
      fontSize: fonts.mediumFontSize,
      fontWeight: fonts.weight.medium,
    },
    profileHeaderSubtitle: {
      fontSize: fonts.smallFontSize,
    },
    profileContent: {
      backgroundColor: palette.primary.redWhite,
      padding: theme.spacing(1, 2),
    },
    highlight: {
      fontWeight: fonts.weight.medium,
    },
    primaryContent: {
      backgroundColor: palette.primary.patinaWhite,
    },
    primaryHeader: {
      backgroundColor: palette.primary.teal,
    },
    selectedWrp: {
      paddingLeft: '0 !important',
    },
    sectionAccordion: {
      'marginTop': '0 !important',
      'marginBottom': '0 !important',
      'border': '1px solid white',
      'boxShadow': 'none',
      '&:before': {
        backgroundColor: 'transparent',
      },
    },
    sectionAccordionSummary: {
      backgroundColor: palette.primary.greySubRow,
    },
    sectionAccordionTitle: {
      fontWeight: 500,
    },
    sectionAccordionDetails: {
      padding: theme.spacing(2),
    },
    sectionInputTitle: {
      fontSize: fonts.mediumFontSize,
      fontWeight: fonts.weight.medium,
      color: palette.primary.trellisBlue,
      margin: theme.spacing(2, 0),
    },
    checkboxFormControl: {
      alignItems: 'flex-start',
      marginTop: theme.spacing(1),
    },
    checkbox: {
      paddingTop: 0,
    },
    checkboxTitle: {
      fontWeight: fonts.weight.medium,
    },
    checkboxInfoLabel: {
      fontWeight: fonts.weight.medium,
      fontSize: fonts.smallFontSize,
      color: palette.primary.grey12,
    },
    checkboxInfoValue: {
      fontWeight: fonts.weight.medium,
    },
    staticTextItem: {
      margin: theme.spacing(1, 0),
    },
    textInfo: {
      paddingTop: '0 !important',
    },
    divider: {
      margin: theme.spacing(3, 0),
    },
    infoTag: {},
    emergencyContactTag: {
      fontWeight: fonts.weight.medium,
      color: palette.primary.white,
      background: palette.primary.lightRed,
      padding: theme.spacing(0.5, 1),
      borderRadius: theme.spacing(0.5),
    },
    therapyColumnHeader: {
      fontSize: fonts.smallFontSize,
      fontWeight: fonts.weight.medium,
      color: palette.primary.steelGrey,
    },
    therapyTitle: {
      paddingTop: theme.spacing(2),
      fontWeight: fonts.weight.medium,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    link: {
      fontWeight: fonts.weight.thick,
    },
    checkIcon: {
      color: palette.primary.lightGrey,
      fontSize: fonts.mediumFontSize,
      paddingRight: theme.spacing(1),
    },
    confirmSection: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    confirmTherapiesWrp: {
      '& > .MuiGrid-item:nth-child(even)': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& > .MuiGrid-item:nth-child(odd)': {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(2),
      },
    },
    confirmDocumentWrp: {
      'paddingTop': `${theme.spacing(1)} !important`,
      '& > .MuiGrid-root': {
        padding: theme.spacing(2),
      },
    },
    newItem: {
      backgroundColor: palette.primary.patinaWhite,
    },
    loading: {
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
      color: palette.primary.trellisBlue,
    },
  });
};

import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useSelector } from 'react-redux';
import { convertMapToList } from 'reducers/helper-reducer';
import { shouldOpenEditFAForm } from 'containers/tasks/fa/fa-data';
import FoundationDetail from './foundation/foundation-detail';
import { FinancialAssistanceTypes, FinancialAssistanceFieldsEnum } from '../../../constants/enums';
import { styles } from '../task-detail-styles';
import DetailField from '../../../components/form/field/field';

import { MMITService } from '../../../clients/mmit-client';

const isApprovedState = currentStateId => {
  return Number(currentStateId) === 3003;
};

const FaFundingDetail = props => {
  const { classes, formValues, task } = props;

  const financials = useSelector(state => state.financials);
  const [zhiProgram, setZhiProgram] = useState();
  const [foundFa, setFoundFa] = useState();

  const foundationStatuses = financials.financial_assistance_category_statuses.filter(f =>
    [
      FinancialAssistanceTypes.InternalFoundation,
      FinancialAssistanceTypes.ExternalFoundation,
      FinancialAssistanceTypes.Foundation,
    ].includes(f.category),
  );

  const financialAssistances = convertMapToList(financials.financial_assistances.data);
  const hasFinancialAssistances = !!financialAssistances?.find(f => f.task_fa_id === task.id);

  const faLabel =
    formValues.fa_expiration_date !== undefined
      ? `FA Type (Available until - ${formValues.fa_expiration_date})`
      : 'FA Type';

  useEffect(() => {
    if (!formValues.zhi_program_id) {
      return;
    }

    MMITService.getProgramById(formValues.zhi_program_id).then(program => setZhiProgram(program));
  }, [formValues.zhi_program_id]);

  useEffect(() => {
    setFoundFa(
      convertMapToList(financials.financial_assistances.data).find(f => f.task_fa_id === task.id),
    );
  }, [hasFinancialAssistances, financials]);

  const isFAWithFundingAvailable = !!formValues.zhi_program_id;
  const zhiProgramDetails = useMemo(() => {
    const coverageEligibilities = Array.isArray(zhiProgram?.CoverageEligibilities)
      ? zhiProgram?.CoverageEligibilities.join(', ')
      : zhiProgram?.CoverageEligibilities;

    return {
      program_name: zhiProgram?.ProgramName,
      income_details: zhiProgram?.IncomeDetails,
      coverage_eligibilities: coverageEligibilities,
      annual_max: zhiProgram?.AnnualMax,
      therapeutic_area: zhiProgram?.TherapeuticAreas[0],
      fa_type: zhiProgram?.AssistanceType,
      enrollment_url: zhiProgram?.EnrollmentURL,
      fa_expiration_date: zhiProgram?.ExpirationDate,
    };
  }, [zhiProgram]);

  return isFAWithFundingAvailable ? (
    <>
      <Grid item xs={12}>
        <Grid container className={classes.formRowContainer}>
          <Grid item lg={3} className={classes.fieldContainer}>
            <DetailField
              name="coverage_eligibilities"
              fieldName="Coverage Eligibilities"
              field={zhiProgramDetails.coverage_eligibilities}
            />
          </Grid>
          <Grid item lg={3} className={classes.fieldContainer}>
            <DetailField name="fa_type" field={zhiProgramDetails.fa_type} fieldName={faLabel} />
          </Grid>
          <Grid item lg={3} className={classes.fieldContainer}>
            <DetailField
              name="program_name"
              field={zhiProgramDetails.program_name}
              fieldName="Program Name"
            />
          </Grid>
          <Grid item lg={3} className={classes.fieldContainer}>
            <DetailField
              fieldName="Enrollment URL"
              field={zhiProgramDetails.enrollment_url}
              isHref
            />
          </Grid>
          <Grid item lg={3} className={classes.fieldContainer}>
            <DetailField field={zhiProgramDetails.income_details} fieldName="Income Details" />
          </Grid>
          <Grid item lg={3} className={classes.fieldContainer}>
            <DetailField field={zhiProgramDetails.annual_max} fieldName="Annual Max" />
          </Grid>
        </Grid>
      </Grid>
      {foundFa && (
        <Grid item xs={12}>
          <FoundationDetail
            displayEdit={isApprovedState(props.targetStatusId)}
            noEdit={false}
            changeDisplayEditOnChangeProps
            fieldsToShow={[
              FinancialAssistanceFieldsEnum.Bin,
              FinancialAssistanceFieldsEnum.Pcn,
              FinancialAssistanceFieldsEnum.GroupId,
              FinancialAssistanceFieldsEnum.MemberId,
            ]}
            statuses={foundationStatuses}
            financialAssistance={{
              ...foundFa,
              foundation_program: undefined,
              program_name: undefined,
            }}
          />
        </Grid>
      )}
    </>
  ) : (
    <></>
  );
};

export default withStyles(styles)(FaFundingDetail);

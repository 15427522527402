import React, { useState } from 'react';
import ReviewItem from 'components/fc-self-service/components/review-item';
import Question from 'components/fc-self-service/components/question';

const SignatureRequired = (props: any) => {
  const { response, isReview, currentAnswers, onEdit, answerOptions } = props;
  const [isConfirmationQuestion, setIsConfirmationQuestion] = useState(false);

  const keyName = 'signature_required';
  const questionText = 'Would you like to require a signature for your delivery?';
  const confirmationText = 'Please confirm you would like to require a signature upon delivery';
  const confirmationYesText =
    'That is correct and I understand the package will not be left without a signature';
  const confirmationNoText = 'Never mind, I am okay with not requiring a signature';
  const controlled_substance_count = currentAnswers.therapies.reduce((acc: any, therapy: any) => {
    if (therapy.included && therapy.is_controlled) {
      acc += 1;
    }
    return acc;
  }, 0);
  const force_signature_required = answerOptions.force_signature_required;

  const localResponse = (value: any) => {
    const responseValue = { keyName, value };
    if (value === 1 && !isConfirmationQuestion && !force_signature_required) {
      setIsConfirmationQuestion(true);
    } else {
      response(responseValue);
    }
  };

  if (!isReview) {
    if (!isConfirmationQuestion) {
      if (force_signature_required) {
        return (
          <Question
            questionText="A Signature will be required for this delivery."
            response={localResponse}
            keyName={keyName}
            buttons={{ button1Text: 'OK', button1Value: 1, button2Text: '' }}
            helperText=""
          />
        );
      }
      if (controlled_substance_count === 0) {
        return (
          <Question
            questionText={questionText}
            response={localResponse}
            keyName={keyName}
            buttons={{ button1Text: 'No', button1Value: 0, button2Text: 'Yes', button2Value: 1 }}
            helperText={
              <div style={{ fontSize: '14px' }}>
                By choosing <span style={{ fontWeight: 'bold' }}>&quot;Yes&quot;</span>, your
                package will not be released without a signature at the time of delivery.
                <br />
                By choosing <span style={{ fontWeight: 'bold' }}>&quot;No&quot;</span>, you confirm
                it is okay for your package to be left without a signature. Please note we may need
                to require a signature if your insurance company or other situation requires it.
              </div>
            }
          />
        );
      }
      // if controlled_substance_count > 0 then this will set the signature required
      // response value to yes and move to the next question.
      response({ keyName, value: 1 });
    }
    return (
      <Question
        questionText={confirmationText}
        response={localResponse}
        keyName={keyName}
        buttons={{
          button1Text: confirmationNoText,
          button1Value: 0,
          button2Text: confirmationYesText,
          button2Value: 1,
        }}
        helperText=""
      />
    );
  }

  const reviewQuestionText = force_signature_required
    ? 'Delivery signature is required for this order.'
    : questionText;
  const allowReviewEdit = !force_signature_required && controlled_substance_count === 0;
  return (
    <ReviewItem
      questionText={
        controlled_substance_count
          ? 'Delivery signature is required when your order contains a controlled substance.'
          : reviewQuestionText
      }
      onEdit={allowReviewEdit ? () => onEdit(1) : null}
      currentAnswer={currentAnswers[keyName] ? 'Yes' : 'No'}
      keyName={keyName}
    />
  );
};

export default SignatureRequired;

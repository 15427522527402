export enum HolidayPresentEnum {
  YES = 'Yes',
  NO = 'No',
}

export enum HolidayTypeEnum {
  CUSTOMER = 'customer',
  COURIER = 'courier',
  UPS = 'ups',
}

export interface IHolidayDate {
  holidayDate: string;
  customer: string;
  courier: string;
  ups: string;
}

export interface ITableProps {
  data: IHolidayDate[];
}

export interface DateMapping {
  [key: string]: IHolidayDate;
}

export interface IHocProps {
  loading: boolean;
  isMounted: boolean;
  memorizedHolidays: IHolidayDate[];
  selectedCustomerId: number;
  classes: Record<string, string>;
  handleModalAddHoliday: () => void;
  handleAddHolidayCheckboxType: (holidayType: HolidayTypeEnum) => void;
  showAddHolidayModal: boolean;
  submitRequestHoliday: (data: any, holidayForm: any) => void;
  setSelectedCustomerId: (customerId: number) => void;
  holidayTypes: HolidayTypeEnum[];
}

export interface IApiHolidayDates {
  shippingCustomerHolidays: string[];
  shippingCourierHolidays: string[];
  shippingUpsHolidays: string[];
}

export interface IHolidayFormFields {
  holidayDate: Date;
  holidayTypes: string[];
}

export interface IRequestAddHolidayDate {
  date: Date;
  holidayTypes: string[];
  customerId: number;
}

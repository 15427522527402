import moment from 'moment';
import { convertToArborDate } from 'models/time/arbor-date';
import store from '../../store';
import { evaluateCondition, filterObjectFields } from './helpers';

export const getResultValue = (config, property, value, providers, formValues) => {
  const field = config.allFields.find(it => it.property === property);
  let result = value;
  if (field) {
    switch (field.type) {
      case 'datetime':
        result = convertToArborDate(value).getUtcDatetime();
        break;
      case 'date':
        result = value ? convertToArborDate(value).getUtcDate() : null;
        break;
      case 'subform':
        result = value && value.values ? value.values : null;
        break;
      case 'subform_json':
        result = value && value.values ? JSON.stringify(value.values) : null;
        break;
      case 'subforms':
        result = value ? JSON.stringify(value.map(v => v.values)) : null;
        break;
      default:
    }
  }
  if (providers.convertResultValues && providers.convertResultValues[property]) {
    result = providers.convertResultValues[property](formValues || {}, result);
  }
  return result;
};

export const getResultValues = (config, form, providers) => {
  const statusId = form.values.status_id;
  const mandatoryPropertiesForStatus =
    providers.mandatoryProperties && providers.mandatoryProperties[statusId]
      ? providers.mandatoryProperties[statusId]
      : [];

  const keyArray = [...mandatoryPropertiesForStatus];
  if (form.fields) {
    keyArray.push(...Object.keys(form.fields));
  }
  if (form.registeredFields) {
    keyArray.push(...Object.keys(form.registeredFields));
  }
  const uniqueKeys = Array.from(new Set(keyArray));

  const resultingValues = uniqueKeys.reduce((result, key) => {
    result[key] = getResultValue(config, key, form.values[key], providers);
    return result;
  }, {});
  return resultingValues;
};

export const getInitialValues = (config, data, providers) => {
  const parsedFieldKeys = [];
  return config.fields.reduce(
    (result, field) => {
      const key = field.property;
      if (parsedFieldKeys.includes(key)) {
        return result;
      }
      if (field) {
        if (field.initialValue) {
          const { condition, valueTrue, valueFalse } = field.initialValue;
          if (evaluateCondition(config, null, condition, data)) {
            result[key] = valueTrue;
          } else {
            result[key] = valueFalse;
          }
        } else if (field.initialValueFormatter) {
          /**
           * if the value from the database needs to be transformed
           * into anything else that should happen through the
           * initialValueFormatter functionality
           */
          const formatter = providers[field.initialValueFormatter];
          if (formatter) {
            const returnValue = formatter(result[key]);
            result[key] = returnValue;
          }
        } else {
          if (
            providers &&
            providers.initialValues &&
            Object.prototype.hasOwnProperty.call(providers.initialValues, key) &&
            !result[key]
          ) {
            result[key] = providers.initialValues[key];
          }
          switch (field.type) {
            case 'datetime':
              if (result[key]) {
                result[key] =
                  field.component === 'date' || field.component === 'calendar'
                    ? moment.utc(convertToArborDate(result[key], true).getCustomerDate(true))
                    : moment.utc(convertToArborDate(result[key], true).getCustomerDatetime(true));
              }
              break;
            case 'date':
              if (result[key]) {
                result[key] = moment.utc(convertToArborDate(result[key], true).getUtcDate(true));
              }
              break;
            case 'integer':
              result[key] =
                result[key] !== undefined && result[key] !== null
                  ? Number(result[key])
                  : result[key]; // eslint-disable-line
              break;
            case 'subform_json':
              result[key] = result[key]
                ? { valid: true, values: JSON.parse(result[key]) }
                : { valid: true, values: null };
              break;
            case 'subform':
              result[key] = result[key]
                ? { valid: true, values: result[key] }
                : { valid: true, values: null };
              break;
            case 'subforms':
              result[key] = result[key]
                ? JSON.parse(result[key]).map(v => ({
                    valid: true,
                    values: v,
                  }))
                : [];
              break;
            default:
          }
        }
      }
      parsedFieldKeys.push(key);
      return result;
    },
    filterObjectFields(
      data,
      config.fields.map(it => it.property),
    ),
  );
};

export const isFormValid = formId => {
  const form = store.getState().form[formId];
  if (form) {
    return !form.syncErrors;
  }
  return false;
};

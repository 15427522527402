import { AppBar, IconButton, Toolbar, Box } from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchUsers, fetchMe } from 'actions/action-lookups';
import Notification from 'containers/shared/notification/notification';
import { getTaskTypesInOrder } from 'services/utils/task-service';
import { ROUTES } from 'constants/index';
import { useTheme } from '@mui/styles';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { createSelector } from '@reduxjs/toolkit';
import { TrellisLogo } from '../icons/icons';
import { styles } from './nav-styles';
import PatientsComponent from './patients';
import ApplicationManagerComponent from './application-manager/index';
import ReportsComponent from './reports';
import AccreditationComponent from './accreditation';
import ScheduleComponent from './schedule';
import ShippingComponent from './shipping/index';
import TopNav from './top-nav';
import { getPatientsUrl } from '../../helpers/router';
import { MenuStructure } from './application-manager/menu-structure';

const selectPatientsUrl = createSelector(
  [
    state => state.taskCounts.data,
    state => state.sidebarTaskType,
    state => state.tabControl.patientTab,
  ],
  (taskCounts, sidebarTaskType, patientTab) => {
    const taskTypes = getTaskTypesInOrder(taskCounts);
    const taskType =
      sidebarTaskType || taskTypes.find(type => taskCounts[type].task_cnt) || taskTypes[0];
    return getPatientsUrl(taskType, patientTab);
  },
);

const useNavStyles = () => {
  const theme = useTheme();
  return styles(theme);
};

const Nav = () => {
  const dispatch = useDispatch();
  const style = useNavStyles();
  const location = useLocation();
  const isUserGuide = useMemo(() => location.pathname.includes('/user-guide/'), [location]);
  const patientsUrl = useTypedSelector(selectPatientsUrl);

  useEffect(() => {
    if (isUserGuide) return;
    dispatch(fetchUsers());
    dispatch(fetchMe());
  }, [dispatch, isUserGuide]);

  return (
    !isUserGuide && (
      <Box sx={style.root}>
        <Notification />
        <AppBar sx={style.appBar}>
          <Toolbar disableGutters sx={style.appBar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              sx={styles.menuButton}
              size="large"
            >
              <TrellisLogo to={patientsUrl} />
            </IconButton>
            <TopNav />
          </Toolbar>
        </AppBar>
        <Box sx={style.content} component="main">
          <Switch>
            <Route
              path={ROUTES.APPLICATION_MANAGER}
              render={routeProps => (
                <ApplicationManagerComponent menu={MenuStructure} {...routeProps} />
              )}
            />
            <Route path="/customers/:customerId/patients" component={PatientsComponent} />
            <Route path="/customers/:customerId/accreditation" component={AccreditationComponent} />
            <Route path="/customers/:customerId/schedule" component={ScheduleComponent} />
            <Route path="/customers/:customerId/reports" component={ReportsComponent} />
            <Route path="/customers/:customerId/shipping" component={ShippingComponent} />
            <Route path="/customers/:customerId/" component={PatientsComponent} />
            <Route path="/" component={PatientsComponent} />
          </Switch>
        </Box>
      </Box>
    )
  );
};

export default Nav;

import HTTP from 'services/http';
import {
  UPDATE_REPORT_MODAL,
  FETCH_REPORT_DATA,
  UPDATE_REPORT_SIDEBAR,
  LOAD_REPORTS,
  PRIOR_AUTHORIZATION_TAT_OVERRIDE_DATA,
} from 'constants/index';

export const fetchReportData = (reportId, ids, label, fromDate, toDate, serviceGroups, clinics) => {
  const request = HTTP.post(
    '/report/data',
    {
      id: reportId,
      ids,
      label,
      fromDate,
      toDate,
      serviceGroups,
      clinics,
    },
    {},
  );
  return {
    type: FETCH_REPORT_DATA,
    payload: request,
  };
};

export const updateReportModal = modalData => ({
  type: UPDATE_REPORT_MODAL,
  payload: Promise.resolve(modalData),
});

export const updateReportSidebar = reportSidebar => ({
  type: UPDATE_REPORT_SIDEBAR,
  payload: Promise.resolve(reportSidebar),
});

export const loadReports = () => ({ type: LOAD_REPORTS });

export const addPaTatOverride = (formValues, reportId) => {
  const { priorAuthorizationId, overrideInDays, overrideBefore, overrideAfter, overrideReason } =
    formValues;
  const request = HTTP.post(
    '/reports/prior_authorization_tat_override',
    {
      priorAuthorizationId,
      overrideInDays,
      overrideBefore,
      overrideAfter,
      overrideReason,
    },
    {},
  );
  return {
    type: PRIOR_AUTHORIZATION_TAT_OVERRIDE_DATA,
    payload: request,
    meta: {
      reportId,
    },
  };
};

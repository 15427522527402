import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { expandTheme } from 'utils/theme-util';
import { ModalUtil } from 'services/utils/modal.util';
import { combineStyles } from 'services/utils/styles-service';
import { BaseTableStyles } from '../../../base-table/base-table.styles';

const ActiveTableStyles = (theme: Theme) => {
  const { font } = expandTheme(theme);

  return createStyles({
    confirmationModal: {
      ...ModalUtil.BasicModalStyling(theme, 50, 3),
      maxHeight: '60vh',
    },
    modalHeader: {
      fontWeight: 'bold',
      fontSize: font.mediumFontSize,
    },
    confirmationModalSubHeading: {
      fontSize: font.smallFontSize,
    },
    modalDrugRow: {
      paddingBottom: theme.spacing(1),
    },
    modalCheckbox: {
      paddingLeft: 0,
    },
  });
};

const styles = combineStyles(BaseTableStyles, ActiveTableStyles);
export const UseStyles = makeStyles(theme => styles(theme));
export { styles };

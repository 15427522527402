import AutocompleteMinigrid from 'components/form/field/render-minigrid-autocomplete';
import cx from 'classnames';
import LoadingOverlay from 'components/loading-overlay/loading-overlay';
import Moment from 'moment';
import React from 'react';
import Validation from 'components/form/validation/validation';
import { BooleanOption, NumberOption, StringOption } from 'interfaces/forms/types';
import { Controller, useForm } from 'react-hook-form';
import { dateFormat } from 'models/time/arbor-date';
import { getModalStyle } from 'services/utils/styles-service';
import { nameOfFactory } from 'utils/types-util';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { ReactSelect } from 'components/form/field/react-select';
import { renderDatePicker as RenderDatePicker } from 'components/form/datepicker/datetime-picker';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { yesNoTrueFalse } from 'constants/lists';
import {
  Button,
  Grid,
  Modal,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  CircularProgress,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { ArborCheckbox } from 'components/arbor-checkbox/arbor-checkbox';
import { buildQaId } from 'utils/build-qa-id';
import {
  IAddNewProductModalState,
  IAddNewProductFormFields,
  IApiSearchResult,
  IRelatedChild,
  IAddProductRequestBody,
} from './types';
import { logger } from '../../../../../../winston-logger';
import { GTD_SPECIALITY_TYPES } from '../../../../../../constants/index';
import { addProductsRequest, getRelatedRequest, getSearchRequest } from './utils';
import { AddNewProductModalStyles } from './add-new-product-modal.styles';

interface IProps extends WithStyles<typeof AddNewProductModalStyles> {
  onCancel: () => void;
  open: boolean;
  reloadTrigger: (date: Date) => void;
}

const requiredMsg = 'Required';

const initialState: IAddNewProductModalState = {
  step: 1,
};

const specialityTypeOptions: StringOption[] = GTD_SPECIALITY_TYPES.map(x => {
  return { label: x.label, value: x.value };
});

const getFieldName = nameOfFactory<IAddNewProductFormFields>();

const getQaId = buildQaId('add-new-product-modal');

const AddNewProductModal: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { classes } = props;

  // #region component state
  const form = useForm<any>();
  const dispatch = useDispatch();
  const therapeuticClasses = useTypedSelector(state => state.lookups.trellisTherapeuticsClasses);
  const [componentState, setComponentState] =
    React.useState<IAddNewProductModalState>(initialState);
  // #endregion

  // #region useMemo
  const therapeuticClassOptions = React.useMemo<NumberOption[]>(() => {
    const options = therapeuticClasses.map<NumberOption>(x => {
      return {
        label: x.name,
        value: x.id,
      };
    });

    return options;
  }, [therapeuticClasses]);
  // #endregion

  // #region useEffects

  // When a drug is selected, make an API call to get the drug + children
  React.useEffect(() => {
    if (componentState.selectedDrug != null && componentState.selectedDrug.gpi_10) {
      const gpi10 = componentState.selectedDrug.gpi_10;
      (async () => {
        try {
          setLoading(true);
          const request = getRelatedRequest(gpi10);
          const results = await request;
          setLoading(false);

          setComponentState(prev => {
            const selectedDrug: IApiSearchResult = componentState.selectedDrug!;
            const parentDrugName = selectedDrug.drug_name;
            const parentStrength = selectedDrug.strength;

            return {
              ...prev,
              parentChildResult: {
                drugName: parentDrugName,
                strength: parentStrength,
                children: results.data.map<IRelatedChild>(x => {
                  return {
                    drugName: x.drug_name,
                    drugDescriptorId: x.drug_descriptor_id,
                    inGtd: x.inGtd,
                    checked: false,
                    strength: x.strength,
                    gpi: x.generic_product_identifier,
                    route: x.route_of_administration,
                    dosage: x.dosage_form,
                    ndc: x.ndc_upc_hri,
                  };
                }),
              },
            };
          });
        } catch (error) {
          logger.error(error);
          dispatch(notifyError('Error loading results'));
          setLoading(false);
        }
      })();
    }
  }, [componentState.selectedDrug]);
  // #endregion

  // #region helper functions
  const handleFetchSearchResults = async (
    searchString: string,
  ): Promise<undefined | IApiSearchResult[]> => {
    const request = getSearchRequest(searchString);
    try {
      const results = await request;
      return results.data;
    } catch (error) {
      logger.error(error);
      dispatch(notifyError('Could not load results'));
    }

    return undefined;
  };

  const setLoading = (loading: boolean): void => {
    setComponentState(prev => {
      return {
        ...prev,
        loading: loading,
      };
    });
  };

  const setStateToNextStep = (): void => {
    const currentStep = componentState.step;
    setComponentState(prev => {
      return { ...prev, step: currentStep + 1 };
    });
  };

  const setStateParentChecked = (checked: boolean): void => {
    if (componentState.parentChildResult && componentState.parentChildResult.children.length > 0) {
      const newState = { ...componentState };
      newState.parentChildResult!.children.forEach(child => {
        child.checked = checked;
      });
      setComponentState(newState);
    }
  };

  const setStateChildChecked = (drugDescriptorId: number, checked: boolean): void => {
    if (componentState.parentChildResult && componentState.parentChildResult.children.length > 0) {
      const newState = { ...componentState };
      const child = newState.parentChildResult!.children.find(
        x => x.drugDescriptorId === drugDescriptorId,
      );
      if (child) {
        child.checked = checked;
      }
      setComponentState(newState);
    }
  };

  const selectResult = (value: IApiSearchResult): void => {
    form.setValue(getFieldName('selectedDrug'), value);
    setComponentState(prev => {
      return {
        ...prev,
        selectedDrug: value,
      };
    });
  };

  const onSubmit = async (formValues: IAddNewProductFormFields): Promise<void> => {
    const requestPayload: IAddProductRequestBody = {
      availableDate: formValues.availableDate?.format(dateFormat),
      endDate: formValues.endDate?.format(dateFormat) || undefined,
      isLdd: formValues.isLdd?.value,
      isOrphan: formValues.isOrphan?.value,
      therapeuticClass: formValues.therapeuticClass?.value,
      specialityType: formValues.specialityType?.map(x => x.value).join(','),
      drugDescriptorIds: componentState.parentChildResult?.children
        .filter(x => !x.inGtd && x.checked)
        .map(x => x.drugDescriptorId),
    };

    const request = addProductsRequest(requestPayload);

    try {
      setComponentState(prev => {
        return {
          ...prev,
          submitting: true,
        };
      });
      await request;
      dispatch(notifySuccess('Saved'));
      props.onCancel();
      props.reloadTrigger(new Date());
      setComponentState(initialState);
    } catch (error) {
      setComponentState(prev => {
        return {
          ...prev,
          submitting: false,
        };
      });
      dispatch(notifyError('Error saving product.'));
    }
  };
  // #endregion

  // #region renders
  const renderNextButton = (): JSX.Element | null => {
    switch (componentState.step) {
      case 1:
        const someSelected =
          componentState.parentChildResult &&
          componentState.parentChildResult.children.length > 0 &&
          componentState.parentChildResult.children.some(x => !x.inGtd && x.checked);
        return (
          <Button
            variant="contained"
            color="primary"
            className={classes.actionButton}
            disabled={!someSelected}
            onClick={() => {
              setStateToNextStep();
            }}
          >
            Next
          </Button>
        );
      case 2:
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={form.handleSubmit(onSubmit)}
            disabled={componentState.submitting}
            className={classes.actionButton}
          >
            {componentState.submitting ? (
              <CircularProgress color="secondary" className={classes.loadingIndicator} size={20} />
            ) : null}
            Save
          </Button>
        );
      default:
        return null;
    }
  };

  const renderParentChildSelection = (): JSX.Element | null => {
    const hasResults =
      componentState.parentChildResult && componentState.parentChildResult.children.length > 0;
    if (hasResults) {
      return (
        <div className={classes.itemsContainer}>
          <Typography>
            <ArborCheckbox
              data-qa-id={getQaId('parent-checkbox')}
              checked={componentState.parentChildResult?.children.every(x => x.checked)}
              onChange={event => {
                setStateParentChecked(event.target.checked);
              }}
            />
            {componentState.parentChildResult?.drugName}
          </Typography>
          {componentState.parentChildResult?.children.map(child => {
            return (
              // TODO: Make the whole typography clickable to make checking items easier
              <Box
                className={classes.childItemContainer}
                pt={1}
                onClick={() => {
                  setStateChildChecked(child.drugDescriptorId, !child.checked);
                }}
              >
                <Typography
                  display="inline"
                  className={cx(classes.childCheckItem, {
                    [classes.alreadyInGtdItem]: child.inGtd,
                  })}
                  key={child.drugDescriptorId}
                >
                  <ArborCheckbox
                    /**
                     * Since child.checked is possibly undefined, force this to a boolean to avoid
                     * the controlled/uncontrolled problem in React.
                     */
                    data-qa-id={getQaId(`child-checkbox-${child.drugDescriptorId}`)}
                    checked={child.inGtd || child.checked || false}
                    disabled={child.inGtd}
                    onChange={event => {
                      setStateChildChecked(child.drugDescriptorId, event.target.checked);
                    }}
                  />
                  {`${child.drugName} ${child.strength}`}
                </Typography>
              </Box>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const renderStep1 = (): JSX.Element => {
    return (
      <>
        <Controller
          defaultValue={undefined}
          name={getFieldName('selectedDrug')}
          control={form.control}
          render={(ctrlProps: any) => {
            return (
              <AutocompleteMinigrid
                label="Select Product"
                fetchOptions={handleFetchSearchResults}
                columnsToShow={{
                  drug_name: 'Drug Name',
                  strength: 'Strength',
                  gpi_10: 'GPI 10',
                  dosage_form: 'Dosage Form',
                }}
                valueCallback={selectResult}
                meta={{ touched: Boolean(form.formState.errors.selectedDrug) }}
                input={{
                  value: ctrlProps?.field?.value,
                  onBlur: ctrlProps?.field?.onBlur,
                  onChange: (item: IApiSearchResult) => {
                    selectResult(item);
                  },
                }}
                clearable={componentState.searchTerm && componentState.searchTerm.length > 0}
                columnToDisplay="Drug Name"
              />
            );
          }}
        />
        {renderParentChildSelection()}
      </>
    );
  };

  const renderStep2 = (): JSX.Element => {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            {/* Therapeutic Class */}
            <Controller
              defaultValue={undefined}
              control={form.control}
              name={getFieldName('therapeuticClass')}
              rules={{ required: true }}
              render={(ctrlProps: any) => {
                return (
                  <>
                    <ReactSelect
                      label="Therapeutic Class *"
                      value={ctrlProps?.field?.value}
                      fields={therapeuticClassOptions}
                      isMulti={false}
                      handleOnChange={(value: NumberOption) => {
                        form.setValue(getFieldName('therapeuticClass'), value);
                      }}
                      data-qa-id={getQaId('therapeutic-class')}
                    />
                    <Validation
                      touched={form.formState.errors.therapeuticClass}
                      error={requiredMsg}
                    />
                  </>
                );
              }}
            />
          </Grid>
          <Grid item xs={5}>
            {/* Speciality Type */}
            <Controller
              defaultValue={undefined}
              control={form.control}
              name={getFieldName('specialityType')}
              rules={{ required: true }}
              render={(ctrlProps: any) => {
                return (
                  <>
                    <ReactSelect
                      label="Speciality Type *"
                      value={ctrlProps?.field?.value}
                      fields={specialityTypeOptions}
                      handleOnChange={(values: StringOption[]) =>
                        form.setValue(getFieldName('specialityType'), values)
                      }
                      data-qa-id={getQaId('speciality-type')}
                    />
                    <Validation
                      touched={form.formState.errors.specialityType}
                      error={requiredMsg}
                    />
                  </>
                );
              }}
            />
          </Grid>
          <Grid item xs={2}>
            {/* Is LDD */}
            <Controller
              defaultValue={yesNoTrueFalse.find(x => x.value === false)}
              control={form.control}
              rules={{ required: true }}
              name={getFieldName('isLdd')}
              render={(ctrlProps: any) => {
                return (
                  <>
                    <ReactSelect
                      label="Is LDD *"
                      fields={yesNoTrueFalse}
                      value={ctrlProps?.field?.value}
                      isMulti={false}
                      handleOnChange={(value: BooleanOption) => {
                        form.setValue(getFieldName('isLdd'), value);
                      }}
                      data-qa-id={getQaId('is-ldd')}
                    />
                    <Validation touched={form.formState.errors.isLdd} error={requiredMsg} />
                  </>
                );
              }}
            />
          </Grid>
          <Grid item xs={2}>
            {/* Is Orphan */}
            <Controller
              defaultValue={yesNoTrueFalse.find(x => x.value === false)}
              control={form.control}
              rules={{ required: true }}
              name={getFieldName('isOrphan')}
              render={(ctrlProps: any) => {
                return (
                  <>
                    <ReactSelect
                      label="Is Orphan *"
                      fields={yesNoTrueFalse}
                      value={ctrlProps?.field?.value}
                      isMulti={false}
                      handleOnChange={(value: BooleanOption) => {
                        form.setValue(getFieldName('isOrphan'), value);
                      }}
                      data-qa-id={getQaId('is-orphan')}
                    />
                    <Validation touched={form.formState.errors.isOrphan} error={requiredMsg} />
                  </>
                );
              }}
            />
          </Grid>
          <Grid item xs={4}>
            {/* Available Date */}
            <Controller
              defaultValue={Moment.utc().startOf('day')}
              control={form.control}
              name={getFieldName('availableDate')}
              rules={{ required: true }}
              render={(ctrlProps: any) => {
                return (
                  <RenderDatePicker
                    label="Available Date *"
                    meta={{ touched: form.formState.errors.availableDate, error: requiredMsg }}
                    input={{
                      value: ctrlProps?.field?.value,
                      onChange: ctrlProps?.field?.onChange,
                      onBlur: ctrlProps?.field?.onBlur,
                    }}
                    data-qa-id={getQaId('available-date')}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={4}>
            {/* End Date */}
            <Controller
              defaultValue={undefined}
              control={form.control}
              name={getFieldName('endDate')}
              render={(ctrlProps: any) => {
                return (
                  <RenderDatePicker
                    label="End Date"
                    meta={{}}
                    input={{
                      value: ctrlProps?.field?.value,
                      onChange: ctrlProps?.field?.onChange,
                      onBlur: ctrlProps?.field?.onBlur,
                    }}
                    data-qa-id={getQaId('end-date')}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <TableContainer className={classes.tableContainer}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Drug Name</TableCell>
                    <TableCell>NDC</TableCell>
                    <TableCell>Strength</TableCell>
                    <TableCell>Dosage Form</TableCell>
                    <TableCell>Route</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {componentState.parentChildResult?.children
                    .filter(x => !x.inGtd && x.checked)
                    .map(child => {
                      return (
                        <TableRow>
                          <TableCell>{child.drugName}</TableCell>
                          <TableCell>{child.ndc}</TableCell>
                          <TableCell>{child.strength}</TableCell>
                          <TableCell>{child.dosage}</TableCell>
                          <TableCell>{child.route}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderStepForm = (): JSX.Element | null => {
    switch (componentState.step) {
      case 1:
        return renderStep1();
      case 2:
        return renderStep2();
      default:
        return null;
    }
  };
  // #endregion

  return (
    <Modal open={props.open}>
      <div style={getModalStyle()} className={classes.modal}>
        <Grid container direction="column" spacing={2}>
          {/* Title */}
          <Grid item xs="auto">
            <Typography className={classes.header}>Add New Product to GTD Global</Typography>
          </Grid>

          {/* Main Section */}
          <Grid item xs="auto">
            {renderStepForm()}
          </Grid>

          {/* Buttons */}
          <Grid item xs="auto">
            <Grid container justifyContent="flex-end" spacing={1}>
              <Grid item xs="auto">
                <Button
                  onClick={() => {
                    form.reset();
                    setComponentState(initialState);
                    props.onCancel();
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs="auto">
                {renderNextButton()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <LoadingOverlay open={componentState.loading || false} />
      </div>
    </Modal>
  );
};

export const AddNewProductModalStyled = withStyles(AddNewProductModalStyles)(AddNewProductModal);

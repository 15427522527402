import React, { useEffect, useState } from 'react';
import moment from 'moment-business-days';
import dataCollectFollowUpJson from 'config/tasks/data-collect-follow-up.json';
import { Typography, Box, Modal, Grid } from '@mui/material';
import HTTP from 'services/http';
import { getModalStyle } from 'services/utils/styles-service';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import FollowUpDataCollectComp from './follow-up-data-collect-comp';
import PreviousDataCollectComp from './previous-data-collect-comp';
import { styles } from './follow-up-data-collect-modal-style';

const getNewFollowUpDate = (date, days) => {
  const newFollowUpDate = moment(date);
  newFollowUpDate.add(days, 'days');
  if (!newFollowUpDate.isBusinessDay()) {
    return newFollowUpDate.nextBusinessDay();
  }
  return newFollowUpDate;
};

const FollowUpDataCollectModal = ({
  therapyId,
  selectedPatientId,
  taskType,
  onClose,
  open,
  onSubmit,
  dataCollectedDate,
  minAssessmentDate,
  therapyDuration,
}) => {
  const NEW_DATA_COLLECT = 0;
  const PREVIOUS_DATA_COLLECT = 1;

  const [protocolDataTypes, setProtocolDataTypes] = useState(null);
  const [nextFollowupInDays, setNextFollowupInDays] = useState(null);
  const [tabValue, setTabValue] = useState(NEW_DATA_COLLECT);

  const classes = styles();

  useEffect(() => {
    if (therapyId) {
      HTTP.post(`/pmp/therapies/${therapyId}/protocols/next`, {
        dataCollectedDate,
        minAssessmentDate,
        therapyDuration,
      }).then(response => {
        setProtocolDataTypes(
          response.data.protocols.reduce((acc, curr) => {
            acc.push({
              clinicalDataTypeId: curr[0],
              required: true,
            });
            setNextFollowupInDays(curr[1]); // # of days till next protocol
            return acc;
          }, []),
        );
      });
    }
  }, [therapyId]);

  return protocolDataTypes ? (
    <Modal open={open}>
      <div style={getModalStyle()} className={classes.modal}>
        <Grid
          justifyContent="space-between"
          alignItems="center"
          className={classes.headerContainer}
        >
          <Grid item xs={12} className={classes.header}>
            Create New Data Collect
          </Grid>
        </Grid>
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => setTabValue(newValue)}
          aria-label="Data collect tabs"
          className={classes.tabs}
        >
          <Tab label="New Data Collect" />
          <Tab label="Previous Data Collect" />
        </Tabs>
        {tabValue === NEW_DATA_COLLECT && (
          <FollowUpDataCollectComp
            instructions={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Typography component="span">
                <Box fontWeight={500}>Task settings</Box>
              </Typography>
            }
            jsonForm={dataCollectFollowUpJson}
            formId="new-task-form"
            formName="select"
            data={{
              follow_up_date: getNewFollowUpDate(moment(), nextFollowupInDays),
              data_collect: { valid: true, values: protocolDataTypes },
            }}
            providers={{
              task: {},
            }}
            onSubmit={updatedTask => {
              onSubmit({
                follow_up_date: updatedTask.follow_up_date,
                follow_up_data_collect: updatedTask.data_collect.values,
              });
              return true;
            }}
            submitButtonText="Create Task"
            cancelButtonText="Cancel"
            alwaysEnabled
            onCancel={() => {
              if (onClose) {
                onClose();
              }
            }}
            marginTop
          />
        )}
        {tabValue === PREVIOUS_DATA_COLLECT && (
          <PreviousDataCollectComp selectedPatientId={selectedPatientId} taskType={taskType} />
        )}
      </div>
    </Modal>
  ) : (
    therapyId && <div>Loading...</div>
  );
};

export default FollowUpDataCollectModal;

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from '../../utils/theme-util';
import { defaultTheme } from '../../lib/themes/index';

export const styles = (theme: Theme) => {
  const { palette, fonts } = expandTheme(theme);

  return createStyles({
    editIcon: {
      color: palette.primary.steelGrey,
    },
    subSectionTitle: {
      fontWeight: 'bold',
    },
    subSectionWrapper: {
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(3),
    },
    rightSideComponentWrapper: {
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    addendumContainer: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(3),
    },
  });
};

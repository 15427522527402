import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { buildQaId } from 'utils/build-qa-id';
import { IProps } from './interfaces/IProps';
import { styles } from './small-fdc-list-card.styles';

const qaIdBuilder = buildQaId('small-fdc-list-card');

const SmallFdcListCard: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { classes } = props;

  return (
    <Card
      variant="outlined"
      className={classes.root}
      data-qa-id={qaIdBuilder('small-fdc-list-card')}
    >
      <CardContent className={classes.cardContent}>
        <Grid container className={classes.gridContainer} alignItems="center">
          <Grid item xs={12}>
            <Typography style={{ textAlign: 'center' }}>{props.therapyName}</Typography>
          </Grid>
          <Grid item xs={12}>
            {props.fdcItems.slice(0, 3).map(item => {
              return (
                <Typography key={item} className={classes.listItem}>
                  {item}
                </Typography>
              );
            })}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(SmallFdcListCard);

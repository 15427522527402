import React, { useState } from 'react';
import { Typography, Grid } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { GridSize, GridSpacing } from '@mui/material/Grid';
import { styles } from './additional-response-info-styles';
import MessageString from '../message-string/message-string';
import { IResponseData } from '../../../../../../interfaces/benefits-investigations';

type IAdditionalResponseInfoProps = WithStyles<typeof styles>

type Props = IAdditionalResponseInfoProps & Partial<IResponseData>;

const AdditionalResponseInfo: React.FC<Props> = (props: Props): JSX.Element => {
  const { classes } = props;

  const defaultExpandedState = { text: '+ Additional Response Information', expanded: false };
  const [expanded, setExpanded] = useState<{ text: string; expanded: boolean }>(
    defaultExpandedState,
  );

  const renderAdditionalInfoRow = (
    items: { size: GridSize; label: string; text?: string }[],
    spacing: GridSpacing = 1,
  ): JSX.Element => {
    return (
      <Grid item xs={12} className={classes.infoRow}>
        <Grid container spacing={spacing}>
          {items.map(item => {
            return (
              <Grid item xs={item.size}>
                <div>
                  <Typography className={classes.label}>{item.label}</Typography>
                  <Typography className={classes.value}>{item.text || '-'}</Typography>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  const toggleExpanded = () => {
    if (!expanded.expanded) {
      setExpanded({ text: '- Additional Response Information', expanded: true });
    } else {
      setExpanded(defaultExpandedState);
    }
  };

  return (
    <>
      <Typography className={classes.expandTextButton} onClick={() => toggleExpanded()}>
        {expanded.text}
      </Typography>
      {expanded.expanded && (
        <Grid container>
          {renderAdditionalInfoRow([
            {
              size: 4,
              label: 'BIN',
              text: props.bin,
            },
            {
              size: 4,
              label: 'PCN',
              text: props.pcn,
            },
            {
              size: 4,
              label: 'GPI',
              text: props.gpi,
            },
          ])}
          {renderAdditionalInfoRow([
            {
              size: 4,
              label: 'Route of Adm.',
              text: props.routeOfAdm,
            },
            {
              size: 4,
              label: 'Qty Dispensed',
              text: props.qtyDispensed,
            },
            {
              size: 4,
              label: 'Days Supply',
              text: props.daysSupply,
            },
          ])}
          {renderAdditionalInfoRow([
            {
              size: 4,
              label: 'DAW Code',
              text: props.dawCode || '0',
            },
            {
              size: 4,
              label: 'Sub Clar Code',
              text: props.subClarCode,
            },
            {
              size: 4,
              label: 'Package Size',
              text: props.packageSize,
            },
          ])}
          {renderAdditionalInfoRow([
            {
              size: 4,
              label: 'Date Submitted',
              text: props.dateSubmitted,
            },
            {
              size: 4,
              label: 'Pref. Prod. Inc.',
              text: props.prefProdInc,
            },
            {
              size: 4,
              label: 'PA No Assigned',
              text: props.paNoAssigned,
            },
          ])}
          {renderAdditionalInfoRow([
            {
              size: 4,
              label: 'Accum Deductible',
              text: props.accumDeductible,
            },
            {
              size: 4,
              label: 'Authorization No.',
              text: props.authorizationNo,
            },
            {
              size: 4,
              label: 'Ingredient Cost',
              text: props.ingredientCost,
            },
          ])}
          {renderAdditionalInfoRow([
            {
              size: 4,
              label: 'Dispense Fee',
              text: props.dispenseFee,
            },
            {
              size: 4,
              label: 'Professional Fee',
              text: props.professionalFee,
            },
            {
              size: 4,
              label: 'Flat Tax',
              text: props.flatTax,
            },
          ])}
          {renderAdditionalInfoRow([
            {
              size: 4,
              label: 'Ptc Tax',
              text: props.ptcTax,
            },
            {
              size: 4,
              label: 'Incentive Amt Paid',
              text: props.incentiveAmountPaid,
            },
            {
              size: 4,
              label: 'Secondary Payor',
              text: props.secondaryPayor,
            },
          ])}
          {renderAdditionalInfoRow([
            {
              size: 4,
              label: 'Other Amount Rec',
              text: props.otherAmountRec,
            },
            {
              size: 4,
              label: 'Exceeded Limit',
              text: props.exceededLimit,
            },
          ])}
          {renderAdditionalInfoRow([
            {
              size: 4,
              label: 'Co Insurance',
              text: props.coInsurance,
            },
            {
              size: 4,
              label: 'Plan + Patient',
              text: props.planPatient,
            },
          ])}
          {renderAdditionalInfoRow([
            {
              size: 12,
              label: 'Amt. Att. To Prod. Sel',
              text: props.amtAttToProdSel,
            },
          ])}
          {renderAdditionalInfoRow([
            {
              size: 12,
              label: 'Amt. Att. To Prod. Sel. Brand Drug',
              text: props.amtAttToProdSelBrandDrug,
            },
          ])}
          {renderAdditionalInfoRow([
            {
              size: 12,
              label: 'Amt. Att. To Prod. Sel. Brand Non Preferred Formulary Selection',
              text: props.amtAttToProdSelBrandNonPreferredFormularySelection,
            },
          ])}
          {renderAdditionalInfoRow([
            {
              size: 12,
              label: 'Amt. Att. To Prod. Sel. Non Preferred Formulary Selection',
              text: props.amtAttToProdSelNonPreferredFormularySelection,
            },
          ])}
          <hr className={classes.divider} />
          <MessageString
            label="Processing Error"
            value={props.errors?.map(error => `${error.code} - ${error.message}`).join('. ')}
          />
        </Grid>
      )}
    </>
  );
};

export default withStyles(styles, { withTheme: true })(AdditionalResponseInfo);

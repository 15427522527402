import React from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Field } from 'redux-form';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { renderDropdown } from 'components/form/field/redux-field';
import { validateDate, required } from 'components/form/validation/validation';

export default function (props) {
  const { open, handleClose, change, getInitialValue, options } = props;

  const restoreAndClose = () => {
    change('needsby_date', getInitialValue('needsby_date'));
    handleClose();
  };

  return (
    <Dialog open={open} onClose={restoreAndClose}>
      <Grid container>
        <Grid item xs={12}>
          <DialogTitle id="alert-dialog-title">New Needs by Date</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <Field
                  name="fill_cycle_reason_id"
                  label="Reason for Change"
                  component={renderDropdown}
                  fields={options}
                  validate={[required]}
                  isOptionDisabled={opt => opt.disabled === true}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="Needs By Date"
                  name="needsby_date"
                  component={renderDatePicker}
                  relative
                  validate={[required, validateDate]}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={restoreAndClose} color="primary">
              Cancel
            </Button>
            <Button variant="contained" onClick={handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  );
}

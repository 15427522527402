export interface IAddPaymentMethodPayload {
  card_num: string;
  payment_type: number | null;
  expiration_date: string | null;
  rank: number | null;
  therapy_id?: number | null;
}

export interface IModifyPaymentMethodPayload extends Partial<IAddPaymentMethodPayload> {
  id: number;
  deleted?: boolean;
}

export interface PaymentMethodResponse {
  id: number;
  card_num: string;
  expiration_date: string;
  payment_type: number;
  rank?: number | null;
  therapy_id?: number | null;
}

export interface PaymentMethodVirtualCardResponse extends PaymentMethodResponse {
  payment_type: LookupPaymentMethodTypes.VirtualCreditCard;
  therapy_id: number;
}

export enum LookupPaymentMethodTypes {
  ManufacturerCoPay = 1,
  PersonalCreditCard = 2,
  Hsa = 3,
  VirtualCreditCard = 4,
}

export interface IFetchPaymentMethodsResponse {
  patient_id: number;
  payment_methods: PaymentMethodResponse[];
}
export interface IDeletePaymentMethodsResponse {
  patient_id: number;
  deleted_payment_method_id: number;
}
export interface IAddPaymentMethodResponse {
  patient_id: number;
  added_payment_method: PaymentMethodResponse;
}

export interface IAddPaymentMethodFields {
  add_payment_method_card_number: string | null;
  add_payment_method_type: number | null;
  add_payment_method_expiration_date: string | null;
  add_payment_method_therapy_associated_id?: number | null;
}

import { LOGIN_REQUEST, LOGOUT, FETCH_USERS, FETCH_ME } from '../constants';

const initialState = {
  isLoginSuccess: false,
  loginError: null,
  redirectToReferrer: false,
  currentUser: { roles: [], permissions: [] },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_REQUEST: {
      sessionStorage.setItem('id_token', payload.data.token.id_token); // eslint-disable-line
      return {
        ...state,
        redirectToReferrer: true,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        ...initialState,
      };
    }
    case FETCH_ME: {
      if (payload && payload.data) {
        return {
          ...state,
          isLoginSuccess: true,
          currentUser: {
            ...state.currentUser,
            ...payload.data,
          },
        };
      }
      return state;
    }
    case FETCH_USERS: {
      if (payload && payload.data && payload.data.users) {
        const { users } = payload.data;
        const currentUser = users.find(it => it.selected);
        if (currentUser && currentUser.roles) {
          const role = currentUser.roles[0].name;
          return {
            ...state,
            currentUser: {
              ...state.currentUser,
              ...currentUser,
              role,
              isSuperUser: role === 'superuser',
            },
          };
        }
        return {
          ...state,
          currentUser: initialState.currentUser,
        };
      }
      return state;
    }
    default:
      return state;
  }
};

import React, { useEffect, useCallback, useState } from 'react';
import get from 'lodash/get';

import { getInvoicingRecordHistory } from 'services/utils/invoicing-service';
import { EXPANDER_ID, SUBCELL, CELL } from '../constants';
import useStyles from './styles';

// This could be inlined into SubRowAsync, this this lets you reuse it across tables
function SubRows({ row, rowProps, columns, data, loading }) {
  const { infoCell, expanderCell, subRowDataCell, subRowMonthCell, subRowTr, borderBottom } =
    useStyles();
  if (loading) {
    return (
      <tr className={subRowTr}>
        <td className={`${infoCell} ${borderBottom}`} colSpan={columns.length - 1}>
          Loading...
        </td>
      </tr>
    );
  }
  if (data.length === 0) {
    return (
      <tr className={subRowTr}>
        <td className={`${infoCell} ${borderBottom}`} colSpan={columns.length}>
          No previous data found.
        </td>
      </tr>
    );
  }

  const getDataCellClasses = (currentCellId, isLastRow) => {
    let dataCellClass = '';
    if (currentCellId === EXPANDER_ID) {
      dataCellClass = !isLastRow ? expanderCell : `${expanderCell} ${borderBottom}`;
    } else {
      dataCellClass = subRowDataCell;
    }
    return dataCellClass;
  };

  return data.map((x, i) => {
    const [dateCaption, record] = Object.entries(x)[0];
    return (
      <>
        <tr className={subRowTr}>
          <td className={expanderCell} />
          <td className={subRowMonthCell} colSpan={columns.length - 1}>
            {dateCaption}
          </td>
        </tr>
        <tr {...rowProps} className={subRowTr} key={`${rowProps.key}-expanded-${record.id}`}>
          {row.cells.map(cell => (
            <td
              className={getDataCellClasses(cell.column.id, i === data.length - 1)}
              {...cell.getCellProps()}
            >
              {cell.render(cell.column.SubCell ? SUBCELL : CELL, {
                value: cell.column.accessor && cell.column.accessor(record, i),
                row: { ...row, original: record, isSubRow: true },
              })}
            </td>
          ))}
        </tr>
      </>
    );
  });
}

export function SubRowAsync({ row, rowProps, columns, source }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const id = get(row.values, 'id', null);

  const fetchRecordVersions = useCallback(
    // eslint-disable-next-line no-shadow
    (columns, source, id) => {
      if (columns.length > 0 && source) {
        setLoading(true);
        getInvoicingRecordHistory(
          source,
          columns.map(column => column.accessor),
          id,
        ).then(({ data: payload }) => {
          setData(payload);
          setLoading(false);
        });
      }
    },
    [columns, source, id],
  );

  useEffect(() => {
    fetchRecordVersions(columns, source, id);
  }, [columns, source, id]);

  return <SubRows row={row} rowProps={rowProps} columns={columns} data={data} loading={loading} />;
}

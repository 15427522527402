import { takeLatest, select, call, put } from 'redux-saga/effects';
import { isAxiosError } from 'axios';
import {
  SELECTED_PATIENT_ID,
  DATA_MEDICAL_INSURANCES_REQUEST,
  DATA_MEDICAL_INSURANCES_SUCCESS,
  DATA_MEDICAL_INSURANCES_FAILURE,
  DATA_PBM_INSURANCES_REQUEST,
  DATA_PBM_INSURANCES_SUCCESS,
  DATA_PBM_INSURANCES_FAILURE,
  DATA_INCOMES_REQUEST,
  DATA_INCOMES_SUCCESS,
  DATA_INCOMES_FAILURE,
  DATA_FINANCIAL_ASSISTANCE_REQUEST,
  DATA_FINANCIAL_ASSISTANCE_SUCCESS,
  DATA_FINANCIAL_ASSISTANCE_FAILURE,
  DATA_FINANCIAL_ASSISTANCE_DETAIL_REQUEST,
  DATA_FINANCIAL_ASSISTANCE_DETAIL_SUCCESS,
  DATA_FINANCIAL_ASSISTANCE_DETAIL_FAILURE,
  UPDATE_PATIENT_INSURANCES,
  ADD_PBM_INSURANCE,
} from '../constants';
import HTTP from '../services/http';
import { notifyError } from '../actions/action-notifications';

function* workerFetchMedicalInsurances() {
  const { selectedPatientId } = yield select();
  if (selectedPatientId) {
    const url = `/patients/${selectedPatientId}/medical-insurances`;
    const request = HTTP.get(url);
    yield put({
      type: DATA_MEDICAL_INSURANCES_REQUEST,
      payload: request,
      meta: { patientId: selectedPatientId },
    });
    try {
      const response = yield call(() => request);
      yield put({
        type: DATA_MEDICAL_INSURANCES_SUCCESS,
        payload: response.data.insurances,
      });
    } catch (e) {
      yield put({ type: DATA_MEDICAL_INSURANCES_FAILURE, payload: e });
    }
  }
}

function* workerFetchPbmInsuraces() {
  const { selectedPatientId } = yield select();
  if (selectedPatientId) {
    const url = `/patients/${selectedPatientId}/pbm-insurances`;
    const request = HTTP.get(url);
    yield put({
      type: DATA_PBM_INSURANCES_REQUEST,
      payload: request,
      meta: { patientId: selectedPatientId },
    });
    try {
      const response = yield call(() => request);
      yield put({
        type: DATA_PBM_INSURANCES_SUCCESS,
        payload: response.data.insurances,
      });
    } catch (e) {
      yield put({ type: DATA_PBM_INSURANCES_FAILURE, payload: e });
    }
  }
}

function* workerFetchIncomes() {
  const { selectedPatientId } = yield select();
  if (selectedPatientId) {
    const url = `/patients/${selectedPatientId}/incomes`;
    const request = HTTP.get(url);
    yield put({
      type: DATA_INCOMES_REQUEST,
      payload: request,
      meta: { patientId: selectedPatientId },
    });
    try {
      const response = yield call(() => request);
      yield put({ type: DATA_INCOMES_SUCCESS, payload: response.data.incomes });
    } catch (e) {
      yield put({ type: DATA_INCOMES_FAILURE, payload: e });
    }
  }
}

function* workerFetchFinancialAssistances() {
  const { selectedPatientId } = yield select();
  if (selectedPatientId) {
    const url = `/patients/${selectedPatientId}/financial-assistance`;
    const request = HTTP.get(url);
    yield put({
      type: DATA_FINANCIAL_ASSISTANCE_REQUEST,
      payload: request,
      meta: { patientId: selectedPatientId },
    });
    try {
      const response = yield call(() => request);
      yield put({
        type: DATA_FINANCIAL_ASSISTANCE_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      yield put({ type: DATA_FINANCIAL_ASSISTANCE_FAILURE, payload: e });
    }
  }
}

function* workerUpdatePatientInsurances({ payload }) {
  if (isAxiosError(payload) && payload.response?.data?.message) {
    yield put(notifyError(payload.response?.data?.message));
  } else {
    yield workerFetchPbmInsuraces();
  }
}

export function* watcherFinancialSaga() {
  yield takeLatest(SELECTED_PATIENT_ID, workerFetchMedicalInsurances);
  yield takeLatest([SELECTED_PATIENT_ID, ADD_PBM_INSURANCE], workerFetchPbmInsuraces);
  yield takeLatest(SELECTED_PATIENT_ID, workerFetchIncomes);
  yield takeLatest([SELECTED_PATIENT_ID], workerFetchFinancialAssistances);
  yield takeLatest(UPDATE_PATIENT_INSURANCES, workerUpdatePatientInsurances);
}

import React, { FC, useCallback, useMemo } from 'react';
import { ReactSelect } from 'components/form/field/react-select';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { RootStateOrAny, useDispatch } from 'react-redux';
import { setSidebarFilter } from 'actions/action-sidebar-filters';

const DispensingPharmacyFilter: FC = () => {
  const dispensingPharmaciesFilterSelected = useTypedSelector(
    (state: RootStateOrAny) => state.sidebarFilters?.data?.task?.dispensingPharmacies || [],
  );
  const fields = useTypedSelector((state: RootStateOrAny) => state.lookups.lookupPharmacies || []);
  const dispatch = useDispatch();

  const value = useMemo(
    () =>
      dispensingPharmaciesFilterSelected?.map(({ id: _v, display_name: label }: any) => ({
        value: _v,
        label,
      })) || [],
    [dispensingPharmaciesFilterSelected],
  );
  const handleDispensingPharmacy = useCallback(
    (values = []) => {
      dispatch(
        setSidebarFilter('task', {
          dispensingPharmacies: values.map(({ label: _l, value: _v }: any) => ({
            id: _v,
            idValue: _v,
            display_name: _l,
          })),
        }),
      );
    },
    [dispatch],
  );

  return (
    <ReactSelect
      data-qa-id=""
      label="Dispensing Pharmacy"
      handleOnChange={handleDispensingPharmacy}
      value={value}
      fields={fields}
      isMulti
    />
  );
};

export default DispensingPharmacyFilter;

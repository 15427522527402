import React from 'react';
import { Chip, Divider, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { PillIcon } from 'components/icons/icons';
import { buildQaId } from '../../utils/build-qa-id';
import { IProps } from './interfaces/IProps';
import { styles } from './title-header.styles';

const qaIdBuilder = buildQaId('dosing-regimen', '.');

const qaIdProp = buildQaId('TitleHeader');

const TitleHeader: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { classes, drugs, isCounseling } = props;

  return (
    <>
      <div className={classes.formTitleHeaderWrapper} data-qa-id={qaIdProp('wrapper')}>
        <div className={classes.formTitleHeaderWrapperItem1}>{props.title}</div>
        <div className={classes.formTitleHeaderWrapperItem2}>
          <div>Current Status</div>
          <div>
            <Chip color="primary" label={props.currentStatusName} className={classes.chip} />
          </div>
        </div>
      </div>
      {drugs && drugs.length > 0 && isCounseling ?
        drugs.map(({ drugName, dosisRegimen }) =>
          <div className={classes.pillIcon} data-qa-id={qaIdBuilder('pill')}>
            <PillIcon />
            <div className={classes.dosisRegimenText}>
              <Typography className={classes.dosisRegimenText}>({drugName}) - {dosisRegimen}</Typography>
            </div>
          </div>,
      ) : null}
      <Divider className={classes.formTitleHeaderWrapperDivider} />
    </>
  );
};

export default withStyles(styles)(TitleHeader);

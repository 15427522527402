import { AxiosPromise } from 'axios';
import { ClinicalDataItem } from 'interfaces/clinical-data/ClinicalDataResponse';
import { ClinicalDataUpsertItem } from 'interfaces/clinical-data/ClinicalDataUpsertRequest';
import HTTP from '../services/http';

export class ClinicalDataClient {
  static fetch = (patientId: number): AxiosPromise<ClinicalDataItem[]> => {
    const url = `patients/${patientId}/clinical-data`;
    return HTTP.get(url, {});
  };

  static save = (
    patientId: number,
    patientClinicalDataItems: ClinicalDataUpsertItem[],
  ): AxiosPromise<ClinicalDataItem[]> => {
    const url = `patients/${patientId}/clinical-data`;
    return HTTP.put(url, { patientClinicalDataItems }, {});
  };
}

import React from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useSelector } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import compose from 'recompose/compose';
import { HAWAII_PACIFIC_HEALTH } from 'constants/index';
import { styles } from '../financials-styles';

// Poverty Level Information is published yearly at:
// https://aspe.hhs.gov/topics/poverty-economic-mobility/poverty-guidelines

const povertyLevelYear = 2024;
const povertyBaseGeneric = 15060;
const additionalPersonGeneric = 5380;
const povertyInfoSpecial = {
  AK: { povertyBase: 18810, additional: 6730 },
  HI: { povertyBase: 17310, additional: 6190 },
};

export const FederalPovertyTable = () => {
  const patient = useSelector(state => state.patient);
  let patientState;
  if (patient?.addresses?.length) {
    patient.addresses.some(entry => {
      if (entry.rank === 1) {
        patientState = entry.state.toUpperCase();
        return true;
      }
      return false;
    });
  }

  const filters = useSelector(state => state.filters);
  const { selectedCustomerId } = filters;

  const isSpecialCase = patientState && povertyInfoSpecial[patientState];
  let povertyBase = isSpecialCase ? isSpecialCase.povertyBase : povertyBaseGeneric;
  let additionalPerson = isSpecialCase ? isSpecialCase.additional : additionalPersonGeneric;
  if (selectedCustomerId === HAWAII_PACIFIC_HEALTH) {
    povertyBase = povertyInfoSpecial.HI.povertyBase;
    additionalPerson = povertyInfoSpecial.HI.additional;
  }
  const levelList = [0, 1, 2, 3, 4, 5, 6, 7].map(i => povertyBase + i * additionalPerson);
  const percentageList = [1.38, 1.5, 2, 2.5, 3, 4];
  return (
    <Grid container justifyContent="center">
      <h3>{`Federal Poverty Levels ${povertyLevelYear}`}</h3>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Household Size</TableCell>
            {percentageList.map(percentage => (
              <TableCell align="center" key={percentage}>{`${percentage * 100}%`}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {levelList.map((level, index) => (
            <TableRow key={level}>
              <TableCell align="center">{index + 1}</TableCell>
              {percentageList.map(percentage => (
                <TableCell align="center" key={`${level}_${percentage}`}>
                  <NumericFormat
                    value={Math.round(level * percentage)}
                    variant="standard"
                    thousandSeparator
                    displayType="text"
                    prefix="$"
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(FederalPovertyTable);

import HTTP from 'services/http';
import {
  ADD_THERAPY,
  ADD_THERAPY_AR,
  EDIT_THERAPY_FLOW,
  FETCH_HISTORY,
  CLEAR_HISTORY,
  ADD_THERAPY_LINK_DRUG,
  UPDATE_THERAPY_PROPERTIES,
  UPDATE_THERAPIES_DISPENSING_PHARMACY,
  EDIT_THERAPIES_NBD,
} from '../constants';

export function addTherapy(newTherapy) {
  const postURL = `/patients/${newTherapy.patient_id}/therapies`;
  const request = HTTP.post(postURL, newTherapy, {});
  return {
    meta: {
      errorMessage: 'Therapy Add Failed: ',
      successMessage: 'Therapy Added Successfully',
    },
    type: ADD_THERAPY,
    payload: request,
  };
}

export function addTherapyLinkDrug({ newTherapy, drug, onSuccess, onFail }) {
  return {
    type: ADD_THERAPY_LINK_DRUG,
    payload: {
      newTherapy,
      drug,
      onSuccess,
      onFail,
    },
  };
}

export function editTherapy(updatedTherapy, onSuccess, onFail) {
  return {
    type: EDIT_THERAPY_FLOW,
    payload: {
      updatedTherapy,
      onSuccess,
      onFail,
    },
  };
}

export function updateTherapiesDispensingPharmacyInRedux(updatedPayload, onSuccess, onFail) {
  return {
    type: UPDATE_THERAPIES_DISPENSING_PHARMACY,
    payload: {
      updatedPayload,
    },
  };
}

export function editTherapiesNBD(updatedTherapies, onSuccess, onFail) {
  return {
    type: EDIT_THERAPIES_NBD,
    payload: {
      updatedTherapies,
      onSuccess,
      onFail,
    },
  };
}

export function fetchHistory(url) {
  const postURL = `${url}/history`;
  const request = HTTP.get(postURL, {});

  return {
    meta: {
      errorMessage: 'Fetching History Failed',
    },
    type: FETCH_HISTORY,
    payload: request,
  };
}

export function clearHistory() {
  return {
    type: CLEAR_HISTORY,
  };
}

export function updateTherapy(id, values) {
  return {
    type: UPDATE_THERAPY_PROPERTIES,
    payload: {
      id,
      values,
    },
  };
}

import React, { FC } from 'react';
import { useTypedSelector } from 'hooks/use-typed-selector';

import { Grid, Typography, Modal, Button } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { getModalStyle } from 'services/utils/styles-service';
import { EditAdd } from 'components/icons/icons';
import Divider from 'components/divider';
import History from 'containers/common/history';

import { buildQaIdProp } from 'utils/build-qa-id';

import { IPatientProblem } from 'interfaces/redux/IPatient';

import classNames from 'classnames';
import { IContainerProps } from './interfaces';
import { styles } from './container.styles';

import ProblemsList from './list';
import ProblemsForm from './form';

const qaIdProp = buildQaIdProp('problems-container');

const ProblemsContainer: FC<IContainerProps> = (props: IContainerProps): JSX.Element => {
  const {
    classes,
    stretched = false,
    showTitle = true,
    readOnly = false,
    formOpenByDefault = false,
  } = props;

  // #region component state
  const [isFormOpen, setIsFormOpen] = React.useState<boolean>(formOpenByDefault || false);
  const [isHistoryOpen, setIsHistoryOpen] = React.useState<boolean>(false);
  const [editingProblem, setEditingProblem] = React.useState<IPatientProblem | null>(null);
  const [viewingHistoryProblem, setViewingHistoryProblem] = React.useState<IPatientProblem | null>(
    null,
  );

  const patient = useTypedSelector(state => state.patient);
  // #endregion component state

  // #region handlers/helpers
  const openForm = (): void => {
    setIsFormOpen(true);
  };

  const closeForm = (): void => {
    setEditingProblem(null);
    setIsFormOpen(false);
  };

  const onClickEditRowHandlerByProblem = (problem: IPatientProblem) => {
    setEditingProblem(problem);
    openForm();
  };

  const onClickOpenHistory = (problem: IPatientProblem) => {
    if (problem?.id && patient?.id) {
      setViewingHistoryProblem(problem);
      setIsHistoryOpen(true);
    }
  };

  const onCloseHistory = () => {
    setViewingHistoryProblem(null);
    setIsHistoryOpen(false);
  };
  // #endregion handlers/helpers

  // #region renderers
  const renderEditAddModal = (): JSX.Element => (
    <Modal open={isFormOpen} {...qaIdProp('diagnosis-new')}>
      <Grid container style={getModalStyle()} className={classes.modalContentWrapper}>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.formTitle}>
            {editingProblem ? 'Edit Diagnosis' : 'Add New Diagnosis'}
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <ProblemsForm
            patientId={patient?.id}
            handleCancel={closeForm}
            patientProblems={patient?.problems}
            editingProblem={editingProblem}
            onSubmit={(problem: IPatientProblem) => {
              if (props.onProblemAdded) {
                props.onProblemAdded(problem);
              }
            }}
          />
        </Grid>
      </Grid>
    </Modal>
  );

  const renderHistoryModal = () => (
    <Modal open={isHistoryOpen} onClose={onCloseHistory} {...qaIdProp('history')}>
      <Grid container style={getModalStyle()} className={classes.modalContentWrapper}>
        <Grid item xs={12}>
          <History url={`/patients/${patient.id}/problems/${viewingHistoryProblem?.id}`} />
        </Grid>
      </Grid>
    </Modal>
  );

  const mainWrapperClassname = classNames(classes.mainWrapper, {
    [classes.mainWrapperStretched]: stretched,
  });

  return (
    <Grid container spacing={2} className={mainWrapperClassname}>
      {renderEditAddModal()}
      {renderHistoryModal()}
      <Grid item xs={6}>
        {showTitle === true && (
          <Typography variant="h6" component="span">
            Problem List
          </Typography>
        )}
      </Grid>
      {readOnly === false && (
        <Grid item xs={6} className={classes.addButtonWrapper}>
          <Button
            name="add_diagnosis_button"
            {...qaIdProp('add')}
            variant="outlined"
            onClick={openForm}
          >
            <EditAdd />
            <Typography variant="body2">New</Typography>
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <ProblemsList
          readOnly={readOnly}
          problems={props.problems || patient?.problems || []}
          onClickEditRow={onClickEditRowHandlerByProblem}
          onClickOpenHistory={onClickOpenHistory}
        />
      </Grid>
    </Grid>
  );
  // #endregion renderers
};

export default withStyles(styles)(ProblemsContainer);

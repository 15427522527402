/* eslint-disable @typescript-eslint/naming-convention */
import React, { HTMLAttributes, ReactChild, ReactElement, StyleHTMLAttributes } from 'react';
import { Box, Tooltip } from '@mui/material';
import medNotSynchronized from '../../../../lib/logos/med_not_synchronized.svg';
import medSynchronized from '../../../../lib/logos/med_synchronized.svg';
import medOptOut from '../../../../lib/logos/med_opt_out.svg';
import medConflict from '../../../../lib/logos/med_conflict.svg';
import medSync from '../../../../lib/logos/med_sync.svg';
import { MedSyncDisplayStatus } from '../common';
import { displayMedSyncDate } from '../med-sync-utils';

const tooltipInfoStyles = {
  lineHeight: '0.8rem',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
} as React.CSSProperties;

const IconSizes = {
  xs: 16,
  sm: 18,
  md: 24,
  lg: 28,
} as const;

type IconSize = keyof typeof IconSizes;

const getSizeStyles = (size: IconSize = 'md') => {
  return { height: IconSizes[size] };
};

interface IconProps {
  className?: string;
  size?: IconSize;
  style?: HTMLAttributes<HTMLImageElement>['style'];
}
export const MedNotSynchronized = React.forwardRef<any, IconProps>(
  ({ style = {}, ...props }, ref) => {
    return (
      <Box ref={ref} {...props} display="flex">
        <img
          src={medNotSynchronized}
          style={style}
          alt="No therapies synchronized yet"
          className={props.className}
          {...getSizeStyles(props.size)}
        />
      </Box>
    );
  },
);
export const MedSynchronized = React.forwardRef<any, IconProps>(({ style = {}, ...props }, ref) => {
  return (
    <Box ref={ref} {...props} display="flex">
      <img
        src={medSynchronized}
        style={style}
        alt="Therapies synchronized"
        className={props.className}
        {...getSizeStyles(props.size)}
      />
    </Box>
  );
});
export const MedOptOut = React.forwardRef<any, IconProps>(({ style = {}, ...props }, ref) => {
  return (
    <Box ref={ref} {...props} display="flex">
      <img
        src={medOptOut}
        style={style}
        alt="Med sync: Opt out"
        className={props.className}
        {...getSizeStyles(props.size)}
      />
    </Box>
  );
});
export const MedConflict = React.forwardRef<any, IconProps>(({ style = {}, ...props }, ref) => {
  return (
    <Box ref={ref} {...props} display="flex">
      <img
        src={medConflict}
        style={style}
        alt="Conflicts in the therapy synchronization"
        className={props.className}
        {...getSizeStyles(props.size)}
      />
    </Box>
  );
});
export const MedSync = React.forwardRef<any, IconProps>(({ style = {}, ...props }, ref) => {
  return (
    <Box ref={ref} {...props}>
      <img
        src={medSync}
        style={style}
        alt="Med sync"
        className={props.className}
        {...getSizeStyles(props.size)}
      />
    </Box>
  );
});

export const MedSyncStatusIcons: Record<MedSyncDisplayStatus, typeof MedOptOut> = {
  [MedSyncDisplayStatus.Undecided]: MedOptOut,
  [MedSyncDisplayStatus.NotSynchronized]: MedNotSynchronized,
  [MedSyncDisplayStatus.Synchronized]: MedSynchronized,
  [MedSyncDisplayStatus.OptOut]: MedOptOut,
  [MedSyncDisplayStatus.InConflict]: MedConflict,
  [MedSyncDisplayStatus.Default]: MedSync,
};

interface MedSyncTooltipIconProps {
  size?: IconSize;
  displayStatus?: MedSyncDisplayStatus;
  tooltipTitle?: ReactChild;
  style?: HTMLAttributes<HTMLImageElement>['style'];
}

export const MedSyncStatusIcon = React.forwardRef<any, MedSyncTooltipIconProps>(
  ({ displayStatus = MedSyncDisplayStatus.Default, size = 'md', ...props }, ref) => {
    const Icon = MedSyncStatusIcons[displayStatus];
    return <Icon ref={ref} {...props} size={size} data-qa-id="med-sync-indicator" />;
  },
);

export function MedSyncTooltip({
  children,
  title,
}: {
  children: ReactElement;
  title?: ReactChild;
}) {
  return (
    <Tooltip placement="right" title={title ?? ''}>
      {children}
    </Tooltip>
  );
}
export interface IMedSyncTooltipInfoProps {
  therapyNames: string;
  anchorDate: Date | null;
  nextSyncDate: Date | null;
}
export const MedSyncTooltipInfo = React.memo(
  ({ therapyNames, anchorDate, nextSyncDate }: IMedSyncTooltipInfoProps) => {
    return (
      <div style={{ maxWidth: '165px' }}>
        <div style={tooltipInfoStyles}>{therapyNames}</div>
        <div style={tooltipInfoStyles}>Anchor Date: {displayMedSyncDate(anchorDate)}</div>
        <div style={tooltipInfoStyles}>Next sync date: {displayMedSyncDate(nextSyncDate)}</div>
      </div>
    );
  },
);
export function MedSyncTooltipIcon({ size, displayStatus, tooltipTitle }: MedSyncTooltipIconProps) {
  return (
    <Box sx={getSizeStyles(size)}>
      <MedSyncTooltip title={tooltipTitle}>
        <MedSyncStatusIcon size={size} displayStatus={displayStatus} />
      </MedSyncTooltip>
    </Box>
  );
}

import { fetchUsers } from 'actions/action-lookups';
import CreateIcon from '@mui/icons-material/Create';
import React from 'react';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { BooleanOption, NumberOption } from 'interfaces/forms/types';
import { Button, Grid, Popover } from '@mui/material';
import { CustomerId, UserId } from 'interfaces/RecordTypes';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { ReactSelect } from 'components/form/field/react-select';
import { SearchField } from 'containers/application-manager/components/search-field';
import { useDebounce } from 'use-debounce/lib';
import { useDispatch } from 'react-redux';
import { UserPermissions } from 'interfaces/user/Permissions';
import { UserUtils } from 'utils/user-utils';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { buildQaId } from 'utils/build-qa-id';
import { UserModal } from './modals/user-modal';
import { BaseTable } from '../base-table/base-table';
import { dateConvert } from '../utils';
import { DecredentialUserModal } from './modals/decredential-user-modal';
import { FieldTypes } from '../types';
import { FormField } from '../popover-field-forms';
import { getDecredentialUserRole, licensedFormatter, statusFormatter } from './utils';
import { ICustomerRoleChild, IGetUsersResponse, IGetUsersResponseItem } from './types';
import { IHeaderCell } from '../base-table/types';
import { IPopoverStateBase } from '../ar/types';
import { logger } from '../../../winston-logger';
import { SectionTitleStyled } from '../components/section-title';
import { styles } from './index.styles';
import { USER_STATUS } from '../../../constants/index';
import BlueBadgeIcon from '../../../lib/application-manager/users/blue-badge.svg';

const initialSearchFieldValue = '';
const searchFieldPlaceholder = 'Search by name, username';

type IPopoverState = IPopoverStateBase<IGetUsersResponseItem>

const initialPopoverState = {
  open: false,
} as IPopoverState;

const getQaId = buildQaId('application-manager.users.user-manager', '.');

export const Users: React.FC = (): JSX.Element => {
  const classes: any = styles();

  const dispatch = useDispatch();

  // #region component state
  const [selectedRoles, setSelectedRoles] = React.useState<NumberOption[]>([]);
  const [selectedSites, setSelectedSites] = React.useState<NumberOption[]>([]);
  const [selectedLicensedStates, setSelectedLicensedStates] = React.useState<NumberOption[]>([]);
  const [selectedStatus, setSelectedStatus] = React.useState<BooleanOption>();
  const [editingUserId, setEditingUserId] = React.useState<undefined | number>(undefined);
  const [endpointBase, setendpointBase] = React.useState<string | undefined>(undefined);
  const [addModalOpen, setAddModalOpen] = React.useState<boolean>(false);
  const [searchTerm, setSearchTerm] = React.useState<string>(initialSearchFieldValue);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const [reloadTrigger, setReloadTrigger] = React.useState<Date | undefined>(undefined);
  const [userCustomerToDecredential, setUserCustomerToDecredential] = React.useState<
    | {
        userId: number;
        customerId: number;
        newRoleId: number;
      }
    | undefined
  >(undefined);
  const [popoverState, setPopoverState] = React.useState<IPopoverState>(initialPopoverState);
  // #endregion

  // #region redux state
  const customers = useTypedSelector(state => state.filters.customers);
  const states = useTypedSelector(state => state.lookups.states);
  const roles = useTypedSelector(state => state.lookups.roles);
  const user = useTypedSelector(state => state.auth.currentUser);
  // #endregion

  // #region constants
  const statusOptions = USER_STATUS.map(x => {
    return { label: x.label, value: x.value };
  });

  const COLUMN_SETTINGS: IHeaderCell<IGetUsersResponseItem, ICustomerRoleChild>[] = React.useMemo(
    () => [
      {
        label: 'Name',
        sortable: true,
        parentKey: 'display_name',
        childColSpan: 4,
        childCellValueOverride: child => {
          const customer = customers.find(x => x.id === child.customerId);
          const role = roles.find(x => x.id === child.roleId);
          if (customer && role) {
            return `${role.display_name} @ ${customer.name}`;
          }
          return '';
        },
      },
      {
        label: 'Licensed',
        sortable: true,
        parentKey: 'licensedStateIds',
        parentValueFormatter: licensedFormatter,
        showParentTooltip: cellContent => cellContent != null && cellContent.length > 25,
        parentCellClick: (event, parent) => {
          openPopover(event.currentTarget, parent.licensedStateIds ?? [], {
            parent: parent,
            field: 'licensedStateIds',
            fieldType: FieldTypes.Licensed,
          });
        },
      },
      {
        label: 'User Name',
        sortable: true,
        parentKey: 'username',
        hideChildCol: true,
      },
      {
        label: 'Status',
        sortable: true,
        parentKey: 'active',
        parentValueFormatter: statusFormatter,
        parentCellClick: (event, parent) => {
          openPopover(event.currentTarget, !!parent.active, {
            parent: parent,
            field: 'active',
            fieldType: FieldTypes.ActiveInactive,
          });
        },
      },
      {
        label: 'Created Date',
        sortable: true,
        parentKey: 'created',
        parentValueFormatter: dateConvert,
        hideChildCol: true,
      },
      {
        label: 'Last Updated',
        sortable: true,
        parentKey: 'updated',
        parentValueFormatter: dateConvert,
        hideChildCol: true,
      },
    ],
    [roles, customers],
  );
  // #endregion

  // #region memos
  const userPermittedToAddUsers = React.useMemo<boolean>(() => {
    return UserUtils.userIsPermitted(UserPermissions.ApplicationManagerUsersManageUserLevel);
  }, [user]);

  const userPermittedToChangeCredentialed = React.useMemo<boolean>(() => {
    return UserUtils.userIsPermitted(UserPermissions.ApplicationManagerUsersManageCustomerLevel);
  }, [user]);

  const columnSettings = React.useMemo(() => {
    setendpointBase('/application-manager/users');
    if (userPermittedToChangeCredentialed || userPermittedToAddUsers) {
      return COLUMN_SETTINGS.concat({
        sortable: true,
        parentComponent: parent => {
          return (
            <>
              <CreateIcon
                data-qa-id={getQaId('button.edit-user')}
                onClick={e => {
                  e.stopPropagation();
                  setEditingUserId(parent.id);
                }}
              />
            </>
          );
        },
        childComponent: (parent, child) => {
          const role = roles.find(x => x.id === child.roleId);
          if (role && role.fallback_role_id && userPermittedToChangeCredentialed) {
            return (
              <img
                className={classes.pointer}
                src={BlueBadgeIcon}
                onClick={e => {
                  e.stopPropagation();
                  onDecredentialClick(child.roleId, child.customerId, parent.id);
                }}
              />
            );
          }
          return undefined;
        },
      });
    }
    return COLUMN_SETTINGS;
  }, [roles, userPermittedToAddUsers, customers, userPermittedToChangeCredentialed]);

  const siteOptions = React.useMemo<NumberOption[]>(() => {
    return customers.map(x => {
      return { label: x.name, value: x.id };
    });
  }, [customers]);

  const roleOptions = React.useMemo<NumberOption[]>(() => {
    return roles
      .filter(x => x.show_in_add_user_modal)
      .map<NumberOption>(x => {
        return { label: x.display_name, value: x.id };
      });
  }, [roles]);

  const licenseOptions = React.useMemo<NumberOption[]>(() => {
    return states.map<NumberOption>(state => {
      return {
        label: state.name,
        value: state.id,
      };
    });
  }, [states]);

  const forcedQueryParams = React.useMemo<{}>(() => {
    return {
      customerIds: selectedSites?.map(x => x.value.toString()),
      roleIds: selectedRoles?.map(x => x.value.toString()),
      stateIds: selectedLicensedStates?.map(x => x.value.toString()),
      active: selectedStatus?.value.toString() || '',
    };
  }, [selectedSites, selectedRoles, selectedLicensedStates, selectedStatus]);
  // #endregion

  // #region helper functions
  const afterRequestSuccess = (callbacks: (() => void)[] | undefined = undefined) => {
    dispatch(notifySuccess('Saved'));
    if (callbacks) {
      callbacks.forEach(cb => cb());
    }
  };

  const onSubmitFieldUpdate = async (value: unknown) => {
    try {
      const request = ApplicationManagerClient.updateUserField(
        popoverState.parent.id,
        popoverState.field,
        value,
      );
      await request;

      afterRequestSuccess([() => setPopoverState(initialPopoverState)]);
      setReloadTrigger(new Date());
    } catch (error) {
      logger.error(error);
      dispatch(notifyError('An error occurred'));
    } finally {
      setPopoverState(initialPopoverState);
    }
  };

  const renderPopover = (): JSX.Element => {
    const handleCancel = () => setPopoverState(initialPopoverState);
    const handleSubmit = (value: unknown) => onSubmitFieldUpdate(value);

    return (
      <Popover
        id="form"
        open={popoverState.open}
        anchorEl={popoverState?.anchorElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transitionDuration={{ exit: 0 }}
        onClose={() => setPopoverState(initialPopoverState)}
      >
        <FormField
          data-qa-id={`${popoverState.field}-${popoverState.parent?.id || ''}`}
          initialValue={popoverState.value}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          fieldType={popoverState.fieldType}
        />
      </Popover>
    );
  };

  const openPopover = (
    element: HTMLElement,
    value: unknown,
    config: {
      parent: IGetUsersResponseItem;
      fieldType: FieldTypes;
      field: keyof IGetUsersResponseItem;
      forceAsParent?: boolean;
    },
  ): void => {
    if (config.parent === null) {
      throw new Error('Cannot select a parent cell.');
    }
    setPopoverState({
      open: true,
      anchorElement: element,
      parent: config.parent,
      field: config.field,
      fieldType: config.fieldType,
      value: value,
      forceAsParent: config.forceAsParent,
    });
  };

  const onDecredentialClick = (
    currentRole: number,
    customerId: CustomerId,
    userId: UserId,
  ): number | undefined => {
    if (roles) {
      const newRoleId = getDecredentialUserRole(currentRole, roles);
      if (newRoleId) {
        setUserCustomerToDecredential({
          userId: userId,
          customerId: customerId,
          newRoleId: newRoleId,
        });
      }
    }
    return undefined;
  };
  // #endregion

  return (
    <>
      {renderPopover()}
      {editingUserId != null && (
        <UserModal
          open={editingUserId != null}
          editingUser={{ userId: editingUserId }}
          onCancel={() => {
            setEditingUserId(undefined);
          }}
          onSuccess={() => {
            setEditingUserId(undefined);
            setReloadTrigger(new Date());
          }}
        />
      )}
      {userPermittedToChangeCredentialed && userCustomerToDecredential ? (
        <DecredentialUserModal
          open={userCustomerToDecredential != null}
          userId={userCustomerToDecredential.userId}
          customerId={userCustomerToDecredential.customerId}
          newRoleId={userCustomerToDecredential.newRoleId}
          onCancel={() => setUserCustomerToDecredential(undefined)}
          afterSuccess={() => {
            setUserCustomerToDecredential(undefined);
            setReloadTrigger(new Date());
          }}
        />
      ) : null}
      {userPermittedToAddUsers && (
        <UserModal
          open={addModalOpen}
          onCancel={() => setAddModalOpen(false)}
          onSuccess={() => {
            setAddModalOpen(false);
            setReloadTrigger(new Date());
            dispatch(fetchUsers());
          }}
          editingUser={undefined}
        />
      )}
      <SectionTitleStyled title="User Management" />
      <Grid container direction="column">
        {/* Top Part */}
        <Grid item>
          <Grid container justifyContent="space-between" alignItems="flex-end">
            <Grid container item xs={8} spacing={3}>
              <Grid item xs={3}>
                <ReactSelect
                  label="Site"
                  name="Site"
                  value={selectedSites}
                  fields={siteOptions}
                  data-qa-id={getQaId('filter.site')}
                  handleOnChange={(value: NumberOption[] | undefined) => {
                    setSelectedSites(value || []);
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <ReactSelect
                  label="Role"
                  name="Role"
                  value={selectedRoles}
                  fields={roleOptions}
                  data-qa-id={getQaId('filter.role')}
                  handleOnChange={(value: NumberOption[] | undefined) => {
                    setSelectedRoles(value || []);
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <ReactSelect
                  label="License"
                  name="License"
                  value={selectedLicensedStates}
                  fields={licenseOptions}
                  data-qa-id={getQaId('filter.states')}
                  handleOnChange={(value: NumberOption[] | undefined) => {
                    setSelectedLicensedStates(value || []);
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <ReactSelect
                  label="Status"
                  name="Status"
                  value={selectedStatus}
                  fields={statusOptions}
                  isMulti={false}
                  isClearable
                  data-qa-id={getQaId('filter.status')}
                  handleOnChange={(value: BooleanOption) => {
                    setSelectedStatus(value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs="auto">
              <Grid container spacing={2} className={classes.searchField}>
                <Grid item>
                  <SearchField
                    width={48}
                    value={searchTerm}
                    onChange={setSearchTerm}
                    initialValue={initialSearchFieldValue}
                    placeholder={searchFieldPlaceholder}
                  />
                </Grid>
                {userPermittedToAddUsers && (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setAddModalOpen(true)}
                      data-qa-id={getQaId('button.add-user')}
                    >
                      Add User
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Bottom Part */}
        <Grid item>
          <BaseTable<IGetUsersResponse, IGetUsersResponseItem, ICustomerRoleChild>
            searchTerm={debouncedSearchTerm}
            actionsPermitted={userPermittedToAddUsers}
            enableParentCheckboxes={false}
            enableChildCheckboxes={false}
            endpointBase={endpointBase}
            columnSettings={columnSettings}
            paginationQueryParamSettings={{
              pageNumberQueryStringKey: 'pageNumber',
              pageSizeQueryStringKey: 'pageSize',
              searchTermQueryStringKey: 'searchTerm',
              sortOrderQueryStringKey: 'sortOrder',
              sortPropQueryStringKey: 'sortProp',
            }}
            childPkSelector={c => c.roleId}
            parentPkSelector={p => p.id}
            classesOverride={{
              lastCell: userPermittedToAddUsers ? classes.lastCell : undefined,
            }}
            triggerReload={reloadTrigger}
            forcedQueryParams={forcedQueryParams}
          />
        </Grid>
      </Grid>
    </>
  );
};

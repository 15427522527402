import React from 'react';
import { renderDatePicker as RenderDatePicker } from 'components/form/datepicker/datetime-picker';

export default ({
  field,
  label,
  input,
  meta,
  disabled,
  labelSize,
  qaId,
}) => (
  <RenderDatePicker
    label={label}
    input={input}
    meta={meta}
    disabled={disabled}
    labelSize={labelSize}
    id={qaId}
    centerInput={field?.centerInput ?? null}
  />
);

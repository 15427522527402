import React from 'react';

import { Controller } from 'react-hook-form';

import AutocompleteMinigrid from 'components/form/field/render-minigrid-autocomplete';
import { IControlledFormField } from '../types';

interface IProps<TValue extends object = any> extends IControlledFormField {
  labelField: keyof TValue;
  columnsToShow: Record<keyof TValue, string>;
  fetchOptions: (searchText: string) => Promise<TValue[]>;
  selectValue: (value: TValue) => void;
  placeholder?: string;
  clearable?: boolean;
  labelWrapperClassName?: string;
  defaultValue: TValue | string | null;
}

export const ControlledAutoCompleteMiniGrid = <TValue extends object = any>(
  props: IProps<TValue>,
): JSX.Element => {
  return (
    <Controller
      defaultValue={props.defaultValue}
      control={props.control}
      name={props.name}
      rules={props.validations}
      render={(ctrlProps: any) => (
        <AutocompleteMinigrid
          label={props.label}
          hint={props.placeholder}
          fetchOptions={props.fetchOptions}
          columnsToShow={props.columnsToShow}
          input={{
            value: ctrlProps?.field?.value,
            onChange: (value: TValue) => {
              ctrlProps?.field?.onChange(value);
              props.selectValue(value);
            },
            onBlur: ctrlProps?.field?.onBlur,
          }}
          labelWrapperClass={props.labelWrapperClassName}
          meta={props.inputMetaData || {}}
          labelField={props.labelField}
          valueCallback={props.selectValue}
          clearable={props.clearable}
          disabled={props.disabled}
          data-qa-id={props.qaId}
        />
      )}
    />
  );
};

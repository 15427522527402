import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { styles } from './response-data-styles';
import AdditionalResponseInfo from '../additional-response-info/additional-response-info';
import MessageString from '../message-string/message-string';
import { IResponseData } from '../../../../../../interfaces/benefits-investigations';

import { buildQaIdProp } from '../../../../../../utils/build-qa-id';

const qaIdProp = buildQaIdProp('response-data');

type IResponseDataProps = WithStyles<typeof styles>

type Props = IResponseDataProps & Partial<IResponseData>;

const ResponseData: React.FC<Props> = (props: Props): JSX.Element => {
  const { classes } = props;

  const valueOrPlaceholder = (value: any, placeholder = '-') => {
    return value ?? placeholder;
  };

  return (
    <Grid container className={classes.outerContainer}>
      <Grid item xs={12} className={classes.heading}>
        <Typography variant="h6">Primary Response</Typography>
      </Grid>
      <Grid item xs={12} className={classes.gridRow}>
        <Grid container>
          <Grid item xs={4}>
            <div>
              <Typography className={classes.label}>Date Of Service</Typography>
              <Typography className={classes.value} {...qaIdProp('date-of-service')}>
                {valueOrPlaceholder(props.dateOfService)}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={8}>
            <div>
              <Typography className={classes.label}>Drug</Typography>
              <Typography className={classes.value} {...qaIdProp('drug')}>
                {valueOrPlaceholder(props.drug)}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.gridRow}>
        <Grid container>
          <Grid item xs={4}>
            <div>
              <Typography className={classes.label}>Rx Number</Typography>
              <Typography className={classes.value} {...qaIdProp('rx-number')}>
                {valueOrPlaceholder(props.rxNumber)}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={8}>
            <div>
              <Typography className={classes.label}>Response Status</Typography>
              <Typography className={classes.value} {...qaIdProp('response-status')}>
                {valueOrPlaceholder(props.responseStatus)}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.gridRow}>
        <Grid container>
          <Grid item xs={4}>
            <div>
              <Typography className={classes.label}>Reversed</Typography>
              <Typography className={classes.value} {...qaIdProp('reversed')}>
                {valueOrPlaceholder(props.reversed)}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <Typography className={classes.label}>Patient Pay</Typography>
              <Typography className={classes.value} {...qaIdProp('patient-pay')}>
                {valueOrPlaceholder(props.patientPay)}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <Typography className={classes.label}>Plan Paid</Typography>
              <Typography className={classes.value} {...qaIdProp('plan-paid')}>
                {valueOrPlaceholder(props.planPaid)}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.gridRow}>
        <Grid container>
          <Grid item xs={4}>
            <div>
              <Typography className={classes.label}>Copay</Typography>
              <Typography className={classes.value} {...qaIdProp('copay')}>
                {valueOrPlaceholder(props.coPay)}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <hr className={classes.divider} />
      <MessageString
        label="Claim Message"
        value={props.claimMessage}
        {...qaIdProp('claim-message')}
      />
      <MessageString
        label="Additional Message"
        value={props.additionalMessage}
        {...qaIdProp('additional-message')}
      />
      <MessageString
        label="Reject Messages"
        {...qaIdProp('reject-message')}
        value={props.rejectMessage}
      >
        {props.rejectMessages
          ? Object.entries(props.rejectMessages).map(([key, val]) => {
              return (
                <Typography className={classes.rejectMessage}>
                  <strong>{key}</strong> - {val}
                </Typography>
              );
            })
          : undefined}
      </MessageString>
      <hr className={classes.divider} />
      <Grid item xs={12}>
        <AdditionalResponseInfo {...props} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(ResponseData);

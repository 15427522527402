import withStyles from '@mui/styles/withStyles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { convertToArborDate } from 'models/time/arbor-date';
import FormHeader from 'components/form/header/header';
import PageHeader from 'components/form/header/page-header';
import JumpButton from 'components/anchor/jump-button';
import Anchor from 'components/anchor/anchor';
import { Loader } from 'components/loading-overlay/loading-overlay';
import ContactDetail from './contact-detail';
import ContactForm from './contact-form';
import { styles } from './patient-demographics-styles';
import PatientInformation from './patient-information';
import ResponsiblePartyReminder from './responsible-party-reminder';
import PatientPreference from './patient-preference';
import PatientStatus from './patient-status';
import { ToggleDisplayAddContact } from '../../../actions/action-form-displays';
import { getChecklistData } from '../../../actions/action-patient';
import PatientEmptyContacts from './patient-empty-contacts';

export function PatientDemographics(props) {
  const {
    classes,
    patient,
    selectedPatientId,
    contactList,
    displayAddContact,
    onToggleFormDisplay,
    patientClinicsDischarged,
    patientClinicsNotDischarged,
    fetchPatientClinics,
  } = props;

  const unmount = () => onToggleFormDisplay(false);
  useEffect(() => () => unmount(), [selectedPatientId]);
  const contactAddressesCount = Object.values(contactList || {}).reduce(
    (acc, item) => acc + Object.keys(item.filteredAddresses || {}).length,
    0,
  );
  const patientAddressesCount = Object.keys(patient?.filteredAddresses || {}).length;
  const numberOfPotentialRxDeliveryAddresses = contactAddressesCount + patientAddressesCount;
  const renderContact = (contact, index) => (
    <ContactDetail
      key={contact.id}
      patient={patient}
      contact={contact}
      contactIndex={index}
      numberOfPotentialRxDeliveryAddresses={numberOfPotentialRxDeliveryAddresses}
    />
  );

  let isUnderAge = false;
  if (patient.dob) {
    const patientArborDob = convertToArborDate(patient.dob, true);
    const patientYears = patientArborDob.getYearsFromNow();
    isUnderAge = patientYears < 18;
  }

  return (
    <div>
      <PageHeader>
        <JumpButton name="Contacts" sessionId="#contact" />
      </PageHeader>

      {displayAddContact && (
        <div className={classes.addContactFormContainer}>
          <FormHeader header="Add Contact" />
          <ContactForm
            closeHandler={() => onToggleFormDisplay(false)}
            phoneRequired
            hasDoesntProvideContactField
          />
        </div>
      )}

      <div className={classes.loadContainer}>
        <Loader loaded={!contactList.loading}>
          <PatientPreference contactList={contactList} patient={patient} />
        </Loader>
      </div>

      <PatientInformation
        patient={patient}
        numberOfPotentialRxDeliveryAddresses={numberOfPotentialRxDeliveryAddresses}
      />

      {isUnderAge && (
        <ResponsiblePartyReminder
          contactList={contactList}
          onAddResponsibleParty={() => {
            onToggleFormDisplay(true);
            window.scrollTo(0, 0);
          }}
        />
      )}

      <Anchor id="contact">
        <div className={classes.loadContainer}>
          <Loader loaded={!contactList.loading}>
            {(!contactList || !Object.keys(contactList).length) && <PatientEmptyContacts />}
            {contactList &&
              contactList.loading !== true &&
              Object.keys(contactList)
                .map((key, index) => renderContact(contactList[key], index))
                .reverse()}
          </Loader>
        </div>
      </Anchor>
      <PatientStatus
        patient={patient}
        patientClinicsDischarged={patientClinicsDischarged}
        patientClinicsNotDischarged={patientClinicsNotDischarged}
        onEnrollmentChange={fetchPatientClinics}
      />
    </div>
  );
}

function mapStateToProps(state) {
  const { formDisplays, patient, contactList, selectedPatientId, patientClinics } = state;
  const { displayAddContact } = formDisplays;
  const patientClinicsDischarged = patientClinics?.data?.filter(clinic => clinic.discharged) ?? [];
  const patientClinicsNotDischarged =
    patientClinics?.data?.filter(clinic => !clinic.discharged) ?? [];

  return {
    patient,
    contactList,
    displayAddContact,
    selectedPatientId,
    patientClinicsDischarged,
    patientClinicsNotDischarged,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onToggleFormDisplay: bool => {
      dispatch(new ToggleDisplayAddContact(bool));
    },
    fetchPatientClinics: patientId => {
      dispatch(getChecklistData(patientId));
    },
  };
}

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PatientDemographics);

import React from 'react';
import { Grid } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { ReactSelect } from 'components/form/field/react-select';
import Validation from 'components/form/validation/validation';
import { IDataType } from 'interfaces/redux/IDataType';
import { PROTOCOL_FREQUENCY_UNIT, PROTOCOL_FREQUENCY_TYPE } from '../../../../../constants/index';
import { IFormItemsErrors, ITimedFrequencies, IGpiFormFrequencyItem } from '../types';
import { styles } from '../styles';

export interface IFormFrequencyItem {
  frequencyId: string;
  clinicalDataTypes: Record<number, IDataType>;
  frequencyItem: IGpiFormFrequencyItem;
  protocolFrequencies: { label: string; value: string }[];
  frequencies: { label: string; value: string }[];
  errors: IFormItemsErrors;
  single: boolean;
  onChange: (frequencyItemId: string, value: IGpiFormFrequencyItem) => void;
  onRemove: (frequencyItemId: string) => void;
}

const dcItemToString = (dcItem: IDataType) => `${dcItem.dataTypeId} - ${dcItem.name}`;
const isOptionDisabledFunction = (item: IDataType) => !item?.isActive ?? false;
const requiredErrorMsg = 'Required';

const GpiFormFrequencyItem: React.FC<IFormFrequencyItem> = ({
  frequencyId,
  clinicalDataTypes,
  frequencyItem,
  protocolFrequencies,
  frequencies,
  errors,
  single,
  onChange,
  onRemove,
}) => {
  const classes = styles();

  const isTimedFrequency = Object.values<string>(ITimedFrequencies).includes(
    frequencyItem.protocolFrequency,
  );
  const frequencyValue = protocolFrequencies.find(
    item => item.value === frequencyItem.protocolFrequency,
  );
  const clinicalDataTypesList = Object.values(clinicalDataTypes).map(item => ({
    value: item.dataTypeId,
    label: dcItemToString(item),
    isActive: item.isActive,
  }));

  const handleOnFrequencyChange = ({ value }: { label: string; value: string }): void => {
    const newFrequency = {
      ...frequencyItem,
      protocolFrequency: value,
    };
    onChange(frequencyId, newFrequency);
  };

  const handleOnDcItemsChange = (dcItems: { label: string; value: number }[]): void => {
    const newFrequency = {
      ...frequencyItem,
      dcItems,
    };
    onChange(frequencyId, newFrequency);
  };

  const handleOnFrequencyUnitChange = (frequencyUnit: { label: string; value: number }): void => {
    const newFrequency = {
      ...frequencyItem,
      frequencyUnit: frequencyUnit.value,
    };
    onChange(frequencyId, newFrequency);
  };

  const handleOnFrequencyTypeChange = (frequencyType: { label: string; value: string }): void => {
    const newFrequency = {
      ...frequencyItem,
      frequencyType: frequencyType.value,
    };
    onChange(frequencyId, newFrequency);
  };

  const renderFrequencyFields = (): JSX.Element => {
    if (isTimedFrequency) {
      const frequencyUnit = frequencyItem.frequencyUnit
        ? PROTOCOL_FREQUENCY_UNIT.find(item => item.value === frequencyItem.frequencyUnit)
        : {};
      const frequencyType = frequencyItem.frequencyType
        ? PROTOCOL_FREQUENCY_TYPE.find(item => item.value === frequencyItem.frequencyType)
        : {};
      return (
        <>
          <Grid item xs={4} className={classes.frequencyItemGridPadding}>
            <ReactSelect
              label="Frequency unit*"
              isMulti={false}
              value={frequencyUnit}
              fields={PROTOCOL_FREQUENCY_UNIT}
              handleOnChange={handleOnFrequencyUnitChange}
              fullWidth
            />
            <Validation
              touched={errors?.frequencyUnit}
              error={errors?.frequencyUnit && requiredErrorMsg}
            />
          </Grid>
          <Grid item xs={5} className={classes.frequencyItemGridPadding}>
            <ReactSelect
              label="Frequency type*"
              isMulti={false}
              value={frequencyType}
              fields={PROTOCOL_FREQUENCY_TYPE}
              handleOnChange={handleOnFrequencyTypeChange}
              fullWidth
            />
            <Validation
              touched={errors?.frequencyType}
              error={errors?.frequencyType && requiredErrorMsg}
            />
          </Grid>
        </>
      );
    }

    return <></>;
  };

  const dcItems = (frequencyItem.dcItems || []).map(item =>
    typeof item === 'object'
      ? item
      : {
          value: item,
          label: dcItemToString(clinicalDataTypes[item]),
          isDisabled: !clinicalDataTypes[item].isActive,
        },
  );

  return (
    <Grid container className={classes.frequencyItem}>
      <Grid item xs={11} className={classes.frequencyItemGridPadding}>
        <Grid container spacing={4}>
          <Grid item xs={3} className={classes.frequencyItemGridPadding}>
            <ReactSelect
              label="Frequency"
              isMulti={false}
              value={frequencyValue}
              fields={frequencies}
              handleOnChange={handleOnFrequencyChange}
              fullWidth
            />
          </Grid>
          {renderFrequencyFields()}
          <Grid item xs={9} className={classes.frequencyItemGridPadding}>
            <ReactSelect
              label="DC Item*"
              isMulti
              value={dcItems}
              fields={clinicalDataTypesList}
              handleOnChange={handleOnDcItemsChange}
              isOptionDisabled={isOptionDisabledFunction}
              fullWidth
            />
            <Validation touched={errors?.dcItems} error={errors?.dcItems && requiredErrorMsg} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} className={classes.frequencyItemGridPadding}>
        {single ? (
          <></>
        ) : (
          <a className={classes.frequencyItemRemoveButton} onClick={() => onRemove(frequencyId)}>
            <CloseIcon />
          </a>
        )}
      </Grid>
    </Grid>
  );
};

export default GpiFormFrequencyItem;

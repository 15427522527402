import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DrugsClient } from 'clients/drugs-client';
import { ITherapy } from '../interfaces/redux/ITherapy';

export const NAME = 'benefitsInvestigationSubmissionForm';
export interface IBenefitsInvestigationSubmissionFormState {
  open?: boolean;
  therapyId?: number;
  drugUnitPricing?: number;
  therapy?: ITherapy;
  arTaskId?: number;
  dispenseQty?: string;
  taskId?: number;
}
export interface IBIFromArPayAction {
  therapy: ITherapy;
  arTaskId: number;
}

const initialState: IBenefitsInvestigationSubmissionFormState = {
  open: false,
};

export const loadDrugPricing = createAsyncThunk(`${NAME}/loadDrugPricing`, async (ndc: string) => {
  const response = await DrugsClient.getDrugPricing(ndc);
  if (response?.data?.unit_price) {
    return response.data.unit_price;
  }
  return undefined;
});

const benefitsInvestigationSubmissionFormStateSlice = createSlice({
  name: NAME,
  initialState: initialState,
  reducers: {
    setDrugUnitPricing: (state, action: PayloadAction<number>) => {
      if (action.payload) {
        state.drugUnitPricing = action.payload;
      }
    },
    open: (state, action: PayloadAction<number>) => {
      if (action.payload) {
        state.open = true;
        state.therapyId = action.payload;
      }
    },
    openFromArForm: (state, action: PayloadAction<IBIFromArPayAction>) => {
      if (action.payload) {
        state.open = true;
        state.therapy = action.payload.therapy;
        state.arTaskId = action.payload.arTaskId;
      }
    },
    close: () => {
      return initialState;
    },
    setDispenseQty: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        state.dispenseQty = action.payload;
      }
    },
    setTaskId: (state, action: PayloadAction<number>) => {
      if (action.payload) {
        state.taskId = action.payload;
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(loadDrugPricing.fulfilled, (state, action) => {
      if (action.payload) {
        state.drugUnitPricing = action.payload;
      }
    });
  },
});

export const benefitsInvestigationSubmissionFormActions =
  benefitsInvestigationSubmissionFormStateSlice.actions;
export const benefitsInvestigationSubmissionFormReducer =
  benefitsInvestigationSubmissionFormStateSlice.reducer;

import withStyles, { Styles } from '@mui/styles/withStyles';
import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Grid, ClickAwayListener } from '@mui/material';
import { convertToArborDate } from '../../models/time/arbor-date';
import { styles } from './floating-action-button-styles';
import { ReactComponent as TrellisLogo } from '../../lib/logos/trellis_logo.svg';
import { buildHandleEnterKeyPress } from '../../services/utils/accessibility';
import { formatPatientNameShort } from '../../services/utils/demographic-service';
import { ToggleFabPanel } from '../../actions/action-view';
import NewNoteActionButton from './new-note-action-button';
import NewPatientActionButton from './new-patient-action-button';
import NewTherapyActionButton from './new-therapy-action-button';
import NewContactActionButton from './new-contact-action-button';
import NewMedicationActionButton from './new-medication-action-button';
import {
  NewIncomeButton,
  NewPBMInsuranceButton,
  NewMedicalInsuranceButton,
} from './income-and-benefits-buttons';

interface IFabClasses {
  fabContainer: string;
  fabButton: string;
  fabControlPanel: string;
  fabControlPanelMenuItem: string;
  patientName: string;
}

export interface IFloatingActionButtonProps {
  classes: IFabClasses;
  pathname: string;
  hash: string;
  search: string;
  patientTab: string;
  patient: any;
  selectedPatientId: number;
  togglePanel: (bool?: boolean) => void;
  isControlPanelOpen: boolean;
}

function relevantActionItemsForView(
  patientTab: string,
  actionItemClass: string,
  selectedPatientId: number,
) {
  const actionItems = [<NewPatientActionButton inheritedClass={actionItemClass} />];
  if (!selectedPatientId) {
    return actionItems;
  }
  actionItems.push(<NewNoteActionButton inheritedClass={actionItemClass} />);

  if (patientTab === 'therapies') {
    actionItems.push(<NewTherapyActionButton inheritedClass={actionItemClass} />);
  }
  if (patientTab === 'demographics') {
    actionItems.push(<NewContactActionButton inheritedClass={actionItemClass} />);
  }
  if (patientTab === 'financials') {
    actionItems.push(<NewIncomeButton inheritedClass={actionItemClass} />);
    actionItems.push(<NewMedicalInsuranceButton inheritedClass={actionItemClass} />);
    actionItems.push(<NewPBMInsuranceButton inheritedClass={actionItemClass} />);
  }
  if (
    ((window as any).FEATURES.therapy_show_non_specialty && patientTab === 'medications') ||
    (!(window as any).FEATURES.therapy_show_non_specialty && patientTab === 'clinical')
  ) {
    actionItems.push(<NewMedicationActionButton inheritedClass={actionItemClass} />);
  }
  return actionItems;
}

function renderActionRow(leftAction: JSX.Element, rightAction: JSX.Element, isNotLast: boolean) {
  const style = isNotLast ? { borderBottom: '1px solid #5d87af' } : {};
  return (
    <Grid key={Math.random().toString(36).substring(2, 15)} item xs={12} style={style}>
      <Grid container spacing={2}>
        {leftAction}
        {rightAction}
      </Grid>
    </Grid>
  );
}

function renderTabSpecificButtons(actionItems: JSX.Element[], patientTab: string) {
  // This special case logic is obviously sub-optimal;
  // we should consider re-aligning the patient tab with the actual patient tab name
  let patientTabLabel = patientTab;
  if (patientTab === 'financials') {
    patientTabLabel = 'income & benefits';
  }
  if (!actionItems.slice(2).length) {
    return null;
  }
  return (
    <>
      <Grid
        item
        xs={12}
        style={{
          borderBottom: '1px solid #5d87af',
          paddingTop: 10,
          paddingBottom: 10,
          borderTop: '1px solid #5d87af',
          fontSize: '.8em',
          fontWeight: 400,
          textTransform: 'uppercase',
        }}
      >
        {patientTabLabel}
      </Grid>
      {renderActionRows(actionItems.slice(2))}
    </>
  );
}

function renderActionRows(actionItems: JSX.Element[]) {
  // This loops through action items in twos
  // and returns an array of 'action rows' for
  // the FAB control panel to render
  const rows = [];
  for (let i = 0; i < actionItems.length; i += 2) {
    rows.push(renderActionRow(actionItems[i], actionItems[i + 1], actionItems.length > i + 2));
  }
  return rows;
}

function renderFabControlPanel(
  classes: IFabClasses,
  patientTab: string,
  patient: any,
  selectedPatientId: number,
) {
  const actionItems = relevantActionItemsForView(
    patientTab,
    classes.fabControlPanelMenuItem,
    selectedPatientId,
  );

  return (
    <div className={classes.fabControlPanel}>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ pb: 2 }}>
          <Grid container>
            <Grid item xs={8}>
              <span className={classes.patientName} data-qa-id="tfb-patientName">
                {formatPatientNameShort(patient)}
              </span>
            </Grid>
            <Grid item xs={4}>
              <span style={{ float: 'right' }} data-qa-id="tfb-dob">
                {patient.dob ? convertToArborDate(patient.dob, true).getUtcDate(true) : ''}
              </span>
            </Grid>
          </Grid>
        </Grid>
        {renderTabSpecificButtons(actionItems, patientTab)}
        <Grid
          item
          xs={12}
          style={{
            borderBottom: '1px solid #5d87af',
            paddingTop: 10,
            paddingBottom: 10,
            borderTop: '1px solid #5d87af',
            fontSize: '.8em',
            fontWeight: 400,
            textTransform: 'uppercase',
          }}
        >
          General
        </Grid>
        {renderActionRows(actionItems.slice(0, 2))}
      </Grid>
    </div>
  );
}

// tslint:disable-next-line
function FloatingActionButton(props: IFloatingActionButtonProps): JSX.Element | null {
  function onClickButton() {
    props.togglePanel();
  }

  function onClickAway() {
    if (props.isControlPanelOpen) {
      props.togglePanel(false);
    }
  }

  const { classes, patient, patientTab, isControlPanelOpen, pathname, selectedPatientId } = props;

  if (pathname.indexOf('/patients/new') > -1) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div className={`${classes.fabContainer} drag-handle`}>
        <div
          className={classes.fabButton}
          onClick={onClickButton}
          onKeyPress={buildHandleEnterKeyPress(onClickButton)}
          role="button"
          tabIndex={0}
          data-qa-id="tfb-floating-button"
        >
          <TrellisLogo />
        </div>
        {isControlPanelOpen &&
          renderFabControlPanel(classes, patientTab, patient, selectedPatientId)}
      </div>
    </ClickAwayListener>
  );
}

function mapStateToProps(state: any) {
  const { router, tabControl, patient, view, selectedPatientId } = state;
  return {
    patient,
    selectedPatientId,
    pathname: router.location.pathname,
    search: router.location.search,
    hash: router.location.hash,
    patientTab: tabControl.patientTab,
    isControlPanelOpen: view.fabPanelOpen,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    togglePanel: (bool?: boolean) => dispatch(new ToggleFabPanel(bool)),
  };
}

export default compose<IFloatingActionButtonProps, {}>(
  withStyles(styles as Styles<any, any, string>),
  connect(mapStateToProps, mapDispatchToProps),
)(FloatingActionButton);

import React, { useEffect, useState } from 'react';
import { Button, Grid, Link, Tooltip, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { TherapyId } from 'interfaces/RecordTypes';
import { BenefitInvestigationStatus } from 'interfaces/redux/ITherapyBenefitsInvestigationLatest';
import {
  benefitsInvestigationSubmissionFormActions,
  loadDrugPricing,
} from 'slices/benefits-investigation-modal-slice';
import { benefitsInvestigationDetailedHistoryActions } from 'slices/benefits-investigation-detailed-history-slice';
import { pbmModalActions } from 'slices/pbm-modal-slice';
import { withCustomer } from '../../../../helpers/router';
import { useStyles } from './therapy-benefits-investigation-styles';
import { ToggleDisplayAddPBMInsurance } from '../../../../actions/action-form-displays';
import { ITherapyBenefitInvestigationProps } from './types';
import { EditHistory } from '../../../../components/icons/icons';
import { convertToArborDate } from '../../../../models/time/arbor-date';
import { useTypedSelector } from '../../../../hooks/use-typed-selector';
import { fetchPatientBi } from '../../../../actions/action-patient';

function TherapyBenefitsInvestigation(props: ITherapyBenefitInvestigationProps): JSX.Element {
  const { pbmInsurances, therapy, benefitInvestigation } = props;

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();

  const patientId = useSelector((state: any) => state.selectedPatientId);
  const insurancesLoading = useSelector((state: any) => state.financials.pbm_insurances.loading);

  const hasInsurance = pbmInsurances && Object.keys(pbmInsurances).length > 0;
  const hasPrescriberNpi = therapy && therapy.prescribing_physician_npi;

  const selectedPatientId = useTypedSelector(state => state.selectedPatientId);

  useEffect(() => {
    if (!selectedPatientId) {
      return;
    }
    dispatch(fetchPatientBi(selectedPatientId));
  }, [selectedPatientId]);

  const handleOnClickOpen = () => {
    dispatch(loadDrugPricing(therapy.ndc));
    dispatch(benefitsInvestigationSubmissionFormActions.open(therapy.id));
  };

  const handleOpenDetailedHistory = (therapyId: TherapyId) => {
    dispatch(benefitsInvestigationDetailedHistoryActions.open(therapyId));
  };

  const financialLink = () => {
    const query = location.search;
    history.push(withCustomer(`/patients/${patientId}/financials${query}`));
    setTimeout(() => {
      dispatch(new ToggleDisplayAddPBMInsurance(true));
    }, 150);
  };

  const renderNoInsurance = () => {
    return (
      <Typography data-qa-id="therapy-benefits-investigation-no-insurance-message">
        This patient does not have any PBM insurance on file.{' '}
        <Link
          onClick={() => {
            dispatch(pbmModalActions.showModal());
          }}
          className={classes.link}
        >
          Add insurance
        </Link>{' '}
        before running a benefit investigation.
      </Typography>
    );
  };

  const renderNoPrescriber = () => {
    return (
      <>
        <Typography data-qa-id="therapy-benefits-investigation-no-prescriber-message">
          This therapy does not have any physician info on file.
        </Typography>
        <Typography>
          <Typography className={classes.boldInLine}>Add a physician</Typography> before running a
          benefit investigation.
        </Typography>
      </>
    );
  };

  const renderWithInsurance = () => {
    return (
      <Grid spacing={1} direction="column" container>
        <Grid item>
          <div className={classes.subtitle}>Last Benefit Investigation</div>
        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            {benefitInvestigation && benefitInvestigation.created ? (
              <Grid data-qa-id="last-test-date" item>
                <span className={classes.boldLabel}>
                  {`${convertToArborDate(benefitInvestigation.created, true).getCustomerDate(
                    true,
                  )} -` || ''}
                </span>
              </Grid>
            ) : (
              <Grid data-qa-id="no-previous-tests" item>
                <span className={classes.boldLabel}>
                  There is no previous Benefits Investigations
                </span>
              </Grid>
            )}
            <Grid data-qa-id="test-status" item>
              {benefitInvestigation &&
                benefitInvestigation.b1Status === BenefitInvestigationStatus.Paid && (
                  <span className={classes.statusLabelAccepted}>Approved</span>
                )}
              {benefitInvestigation &&
                benefitInvestigation.b1Status === BenefitInvestigationStatus.Rejected && (
                  <span className={classes.statusLabelRejected}>Rejected</span>
                )}
              {benefitInvestigation &&
                benefitInvestigation.b1Status === BenefitInvestigationStatus.Captured && (
                  <span className={classes.statusLabelAccepted}>Captured</span>
                )}
              {benefitInvestigation &&
                benefitInvestigation.b1Status === BenefitInvestigationStatus.Accepted && (
                  <span className={classes.statusLabelAccepted}>Accepted</span>
                )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            {benefitInvestigation &&
              benefitInvestigation.b1Status === BenefitInvestigationStatus.Paid && (
                <>
                  <Grid item data-qa-id="test-copay-amount">
                    <span className={classes.boldLabel}>Copay: </span>
                    <span className={classes.label}>{benefitInvestigation.b1Copay || '-'}</span>
                  </Grid>
                  <Grid item data-qa-id="test-patient-pay-amount">
                    <span className={classes.boldLabel}>Patient Pay: </span>
                    <span className={classes.label}>
                      {benefitInvestigation.b1PatientPay || '-'}
                    </span>
                  </Grid>
                </>
              )}
          </Grid>
        </Grid>
        <Grid item>
          {benefitInvestigation && benefitInvestigation.b1RejectionMessages && (
            <div data-qa-id="test-rejection-messages">
              {benefitInvestigation.b1RejectionMessages.some(x => x.message) &&
                benefitInvestigation.b1RejectionMessages.map((rejection, idx) => (
                  <span key={idx} className={classes.statusLabelRejected}>
                    {rejection.code && `${rejection.code}/`}
                    {rejection.message}{' '}
                  </span>
                ))}
            </div>
          )}
          {benefitInvestigation && benefitInvestigation.errorCodeMessages && (
            <div
              data-qa-id="test-rejection-error-messages"
              className={classes.errorButtonContainer}
            >
              {Object.entries(benefitInvestigation.errorCodeMessages).map(([key, val]) => (
                <Tooltip title={val}>
                  <Button className={classes.errorButton} variant="outlined" size="small">
                    {key}
                  </Button>
                </Tooltip>
              ))}
            </div>
          )}
        </Grid>
        <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
          <Button
            data-qa-id="run-benefits-investigation"
            onClick={handleOnClickOpen}
            className={classes.therapyBenefitsInvestigationButton}
            variant="contained"
          >
            Run benefit investigation
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderInsurance = () => {
    if (insurancesLoading) {
      return <Typography>Loading...</Typography>;
    }
    if (!hasInsurance) {
      return renderNoInsurance();
    }
    if (!hasPrescriberNpi) {
      return renderNoPrescriber();
    }
    return renderWithInsurance();
  };

  return (
    <>
      <Grid container>
        <Grid item>
          <div className={classes.statusTitle}>Benefit Investigations</div>
        </Grid>
        <Grid item xs className={classes.actionIconContainer}>
          {benefitInvestigation && benefitInvestigation.created && (
            <Typography variant="caption">
              <Button
                data-qa-id="detailed-history"
                className={classes.toggleEdit}
                onClick={() => handleOpenDetailedHistory(therapy.id)}
              >
                <EditHistory />
              </Button>
            </Typography>
          )}
        </Grid>
      </Grid>
      {renderInsurance()}
    </>
  );
}

export default TherapyBenefitsInvestigation;

import { Reducer } from 'redux';

import { IDosesRegimenReasonState } from '../interfaces/doses-regimen-reason/IDosesRegimenReasonState';
import { DosesRegimenReasonAction } from '../actions/action-doses-regimen';
import { FETCH_DOSES_REGIMEN_REASONS } from '../constants';

const initialState: IDosesRegimenReasonState = {
  reasons: [],
};

export const dosesRegimenReasonsReducer: Reducer<IDosesRegimenReasonState, DosesRegimenReasonAction> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_DOSES_REGIMEN_REASONS:
      return {
        ...state,
        reasons: payload.data,
      };
    default:
      return state;
  }
};

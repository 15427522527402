import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) => {
  return createStyles({
    iconHistory: {
      '&': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 32,
        width: 32,
        paddingBottom: 1,
        paddingRight: 1,
        marginTop: 4,
        borderRadius: 5,
      },
      '& svg': {
        height: 24,
        width: 24,
      },
      '&:hover': {
        backgroundColor: '#0001',
      },
    },
    adminitrationTooltip: {
      '&': {
        opacity: 0.75,
        color: 'white',
        maxWidth: 246,
        padding: theme.spacing(0.5),
      },
      '& hr': {
        width: '100%',
        height: '0',
        opacity: 0.5,
        backgroundColor: 'white',
      },
      '& .bold': {
        fontWeight: 'Bold',
      },
      '& .info': {
        fontSize: theme.typography.pxToRem(14),
        textTransform: 'capitalize',
      },
      'lineHeight': '18px',
      'fontWeight': 500,
    },
    dosisRegimenText: {
      marginBottom: 5,
      fontSize: (theme as any).font.smallFontSize,
    },
  });
};

export { styles };

import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from '../../../utils/theme-util';

const styles = (theme: Theme) => {
  const { palette, fonts } = expandTheme(theme);

  return createStyles({
    container: {
      position: 'absolute',
      width: theme.spacing(120),
      backgroundColor: palette.primary.background,
      padding: 0,
      top: 'calc(50% + 56px)',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxHeight: 'calc(90vh - 90px)',
      boxSizing: 'border-box',
      boxShadow: 'none',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      overflowY: 'auto',
      position: 'relative',
    },
    instructions: {
      padding: '15px 30px',
    },
    tabs: {
      'marginTop': -48,
      '& > div > .MuiTabs-flexContainer': {
        maxHeight: 48,
      },
    },
    tab: {
      'backgroundColor': palette.primary.greySubRow,
      'borderRadius': '5px 5px 0 0',
      'marginRight': 2.5,
      'marginLeft': 2.5,
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
    tabLabel: {
      justifyContent: 'space-between',
      display: 'flex',
      width: '100%',
    },
    selectedTab: {
      backgroundColor: palette.primary.background,
    },
    scrollButtons: {
      'width': 0,
      'borderRadius': '5px 5px 0 0',
      'position': 'relative',
      '& svg': {
        position: 'absolute',
        top: 15,
        backgroundColor: palette.primary.background,
        marginLeft: -35,
        zIndex: 1,
      },
      '&:first-child svg': {
        backgroundColor: palette.primary.background,
        marginLeft: 35,
      },
    },
  });
};

export { styles };

import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { expandTheme } from 'utils/theme-util';

export const styles = (theme: Theme) => {
  const { palette } = expandTheme(theme);
  return createStyles({
    adherenceHistoryModal: {
      position: 'absolute',
      width: theme.spacing(150),
      backgroundColor: palette.primary.background,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1),
      overflow: 'hidden',
    },
    adherenceInstanceOuterContainer: {
      alignContent: 'flex-start',
      border: `1px solid ${palette.primary.adherenceIntHistoryBorderGrey}`,
      borderRadius: theme.spacing(1.25),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      marginTop: theme.spacing(-2),
    },
    adherenceInstanceTitleContainer: {
      alignItems: 'center',
      paddingTop: theme.spacing(2.875),
      paddingBottom: theme.spacing(2.5),
      height: theme.spacing(3.75),
      display: 'flex',
    },
    adherenceInstanceTitle: {
      fontWeight: 'bold',
      fontSize: theme.spacing(2),
      color: palette.primary.adherenceIntHistoryTitleGrey,
      lineHeight: theme.spacing(2.375),
    },
    adherenceInstanceInnerContainer: {
      border: `1px solid ${palette.primary.adherenceIntHistoryBorderGrey}`,
      paddingLeft: theme.spacing(1),
      height: '85%',
    },
    adherenceInstanceInnerTitleContainer: {
      justifyContent: 'space-between',
    },
    adherenceInstanceInnerTitle: {
      margin: `${theme.spacing(1.25)} 0px ${theme.spacing(1.25)} ${theme.spacing(1)}`,

      fontWeight: 'bold',
    },
    adherenceInstanceUpdateMarker: {
      color: palette.primary.rollingStone,
      margin: theme.spacing(1.25),
      fontSize: theme.spacing(1.375),
    },
    adherenceInstanceRow: {
      height: theme.spacing(7.5),
      marginLeft: theme.spacing(1),
    },
    adherenceInstanceLabel: {
      color: palette.primary.rollingStone,
      fontSize: theme.spacing(1.25),
    },
    adherenceInstanceValue: {
      color: palette.primary.adherenceIntHistoryTitleGrey,
      fontSize: theme.spacing(1.75),
    },
    adherenceLoading: {
      textAlign: 'center',
      width: '100%',
    },
    historyInstancePadding: {
      marginBottom: theme.spacing(-2),
    },
    historyTabsContainer: {
      height: theme.spacing(59.375),
    },
  });
};

type Styles = WithStyles<typeof styles>;

const useStyles = makeStyles(styles);

export const useTypedStyles = (): Styles => ({
  classes: useStyles(),
});

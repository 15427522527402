import React, { useEffect, useState } from 'react';
import { useTypedSelector } from 'hooks/use-typed-selector';
import HTTP from 'services/http';
import { useDispatch } from 'react-redux';
import AdherenceTable from '../../adherence/adherence-table';
import { InterventionStatuses } from '../../../constants/intervention-statuses';
import { fetchPatientAdherenceData } from '../../../actions/action-patient';

const AdherenceTableRenderer = props => {
  const { xs = 12, providers } = props;
  const dispatch = useDispatch();
  // eslint-disable-next-line camelcase
  const desiredStatusId = providers?.formValues?.status_id;
  // eslint-disable-next-line camelcase
  const currStatusId = providers?.task?.status_id;
  const taskId = providers?.task?.id;

  const finishedInterventionStatuses = [
    InterventionStatuses.Completed,
    InterventionStatuses.Canceled_PatientDeceased,
    InterventionStatuses.Canceled,
  ];
  const willStayFinished =
    finishedInterventionStatuses.includes(currStatusId) &&
    finishedInterventionStatuses.includes(desiredStatusId);

  const currAdherenceData = useTypedSelector(state => state.patient.adherenceData);
  const patientId = useTypedSelector(state => state.selectedPatientId);

  useEffect(() => {
    if (!currAdherenceData) {
      dispatch(fetchPatientAdherenceData(patientId));
    }
  }, [currAdherenceData]);

  const [snapshotData, setSnapshotData] = useState();

  useEffect(() => {
    if (!taskId) {
      return;
    }

    HTTP.get(`/task/${taskId}/intervention/snapshot`).then(response => {
      if (response?.status === 200) {
        setSnapshotData(response?.data);
      }
    });
  }, [taskId]);

  const adherenceData = willStayFinished ? snapshotData : currAdherenceData;
  if (!adherenceData?.rows?.length) return <></>;

  return <AdherenceTable adherenceData={adherenceData} gridProps={{ xs }} />;
};

export default AdherenceTableRenderer;

import { useSelector } from 'react-redux';
import { IState } from 'interfaces/redux/IState';
import { convertToArborDate } from 'models/time/arbor-date';
import { IPatient } from 'interfaces/redux/IPatient';
import { IFiltersCustomerItem } from 'interfaces/redux/IFilters';
import { ITherapy } from 'interfaces/redux/ITherapy';
import { TherapyUtil } from 'utils/therapy-util';
import { StringUtils } from 'utils/string-utils';
import { ICounselingTask } from 'interfaces/redux/task-types/ICounselingTask';
import { Utils } from '../documentation/utils';

const lineSeparator = '<br />';

const getPatientAgeText = (patient: IPatient): string | null => {
  const patientArborDob = convertToArborDate(patient.dob, true);
  const formattedAge = patientArborDob.getCustomerAgeFormatted('year old', 'month old', 'day old');
  return formattedAge;
};

const getHealthSystemText = (
  selectedCustomerId: number | null,
  customers: IFiltersCustomerItem[],
): string | undefined => {
  const matchingCustomer = customers.find(x => x.id === selectedCustomerId);
  if (matchingCustomer) {
    return matchingCustomer.alternate_name_sms;
  }
  return undefined;
};

const getTherapyText = (therapies: ITherapy[], tasks: ICounselingTask[]): string | undefined => {
  const taskTherapies = tasks.map(x => x.therapy_id);
  const therapiesText = therapies
    .filter(therapy => taskTherapies.includes(therapy.id))
    .map(therapy => TherapyUtil.getParagraphFormat(therapy));

  const result = StringUtils.oxfordCommaConcatenate(...therapiesText);

  if (!result) {
    return undefined;
  }

  return result;
};

const getDiagnosisText = (therapies: ITherapy[], tasks: ICounselingTask[]): string | undefined => {
  const taskTherapies = tasks.map(x => x.therapy_id);
  const diagnosisText = therapies
    .filter(therapy => taskTherapies.includes(therapy.id))
    .map(therapy => `Diagnosis ${TherapyUtil.getDiagnosisText(therapy)}`);

  return StringUtils.concatenate(lineSeparator, lineSeparator, ...diagnosisText);
};

const usePatientSubjectiveText = (
  tasks: ICounselingTask[],
): { content: string; secondaryContent: string; initialContent: string } => {
  const therapies = useSelector((state: IState) => Object.values(state.therapies.data));
  const patientName = useSelector(
    (state: IState) => `${state.patient.first_name} ${state.patient.last_name}`,
  );
  const patientAge = useSelector((state: IState) => getPatientAgeText(state.patient));
  const gender = useSelector((state: IState) => state.patient.gender);
  const healthSystemText = useSelector((state: IState) =>
    getHealthSystemText(state.filters.selectedCustomerId, state.filters.customers),
  );
  const therapyText = getTherapyText(therapies, tasks);
  const diagnosisText = getDiagnosisText(therapies, tasks);

  const patientText = `${patientName} is a ${patientAge} ${gender} who was referred to ${healthSystemText} for clinical management services for ${therapyText}.`;
  const content = Utils.wrapWithPtags(
    StringUtils.concatenate(lineSeparator, patientText, diagnosisText),
  );
  return {
    content: content || '',
    initialContent: content || '',
    secondaryContent: '',
  };
};

export {
  usePatientSubjectiveText,
  getPatientAgeText,
  getTherapyText,
  getHealthSystemText,
  getDiagnosisText,
};

import { Typography, Grid, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Info as InfoIcon } from '@mui/icons-material';
import React from 'react';
import classNames from 'classnames';
import Audit from 'components/display/audit';
import { getActionedByUserText } from 'utils/user-utils';
import { styles } from './verification-styles';
import {
  VERIFICATION_STATUS_TOUCHED,
  VERIFICATION_STATUS_VERIFIED,
  VERIFICATION_STATUS_REVERIFIED,
} from '../../../constants';
import { auditContext } from '../../../constants/lists';

const confirmedWithEmrWording = 'Confirmed with EMR';
const confirmedWithPatientWording = 'Confirmed with patient';
const reverifyWithPatientWording = 'Reverify with Patient';

const verificationPanel = ({
  classes,
  type,
  isVerified,
  handleVerify,
  disabled,
  verifiedBy,
  verifiedDate,
  auditRules,
}) => {
  const verificationBtnId = `${type}_verification_btn`;
  const verifiedWording = getActionedByUserText({
    action: 'Verified',
    user: verifiedBy,
    date: verifiedDate,
    dateFormat: 'MM/DD/YYYY',
  });

  // not verify
  if (isVerified === 0) {
    return (
      <Grid container className={classes.nonVerifyContainer}>
        <Grid item xs={6}>
          <Grid container alignItems="center">
            {auditRules && (
              <Audit iconType="warning" auditRules={auditRules} context={auditContext.Warning} />
            )}
            <Typography className={classes.nonVerifyWording}>Not Verified</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.verificationBtnContainer}>
            <Button
              id={verificationBtnId}
              type="button"
              onClick={handleVerify}
              className={classes.verificationBtn}
              disabled={disabled}
            >
              Verify
            </Button>
          </div>
        </Grid>
      </Grid>
    );
  }
  // verified
  return (
    <Grid container className={classes.verifyContainer}>
      <Grid item xs={6}>
        <Typography className={classes.verifyWording}>{verifiedWording}</Typography>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.verificationBtnContainer}>
          <Button
            id={verificationBtnId}
            type="button"
            onClick={handleVerify}
            className={classes.verificationBtn}
            disabled={disabled}
          >
            Reverify
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

// export default withStyles(styles, { withTheme: true })(VerificationPanel);
export const VerificationPanel = withStyles(styles, { withTheme: true })(verificationPanel);

const quaternaryVerificationPanel = ({
  classes,
  type,
  verificationId,
  verifiedBy,
  handleVerify,
  handleMarkEmrConfirmed,
  handleMarkPatientConfirmed,
  disabled,
  containerClass,
  auditRules,
  auditCategory,
  emrConfirmed,
  confirmedWithPatientDate,
  showEMR = true,
}) => {
  const verificationBtnId = `${type}_verification_btn`;
  const emrVerificationBtnId = `${type}_emr_verification_btn`;

  const RenderConfirmedWithEMR = ({ buttonHandler, buttonLabel }) => (
    <Grid container className={classNames(containerClass, classes.emrVerifiedContainer)}>
      <Grid item xs={6}>
        <InfoIcon className={classes.emrVerifiedIcon} />
        <Typography className={classes.emrVerifiedWording}>{confirmedWithEmrWording}</Typography>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.verificationBtnContainer}>
          <Button
            id={verificationBtnId}
            type="button"
            onClick={buttonHandler}
            className={classes.verificationBtn}
            disabled={disabled}
          >
            {buttonLabel}
          </Button>
        </div>
      </Grid>
    </Grid>
  );

  // Touched
  if (verificationId === VERIFICATION_STATUS_TOUCHED) {
    return emrConfirmed && showEMR ? (
      <RenderConfirmedWithEMR
        buttonHandler={handleVerify}
        buttonLabel={confirmedWithPatientWording}
      />
    ) : (
      <Grid container className={classNames(containerClass, classes.nonVerifyContainer)}>
        <Grid item xs={6}>
          <Grid container alignItems="center">
            {auditRules && (
              <Audit
                iconType="warning"
                auditRules={auditRules}
                context={auditContext.Warning}
                auditCategory={auditCategory}
              />
            )}
            <Typography className={classes.nonVerifyWording}>Not Verified</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.verificationBtnContainer}>
            <Button
              id={verificationBtnId}
              type="button"
              onClick={handleMarkPatientConfirmed}
              className={classes.verificationBtn}
              disabled={disabled}
            >
              Confirmed with patient
            </Button>
            {showEMR && (
              <Button
                id={emrVerificationBtnId}
                type="button"
                onClick={handleMarkEmrConfirmed}
                className={classes.verificationBtn}
                disabled={disabled || emrConfirmed}
              >
                {confirmedWithEmrWording}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    );
  }
  // verified
  if (verificationId === VERIFICATION_STATUS_VERIFIED) {
    const verifiedWording = getActionedByUserText({
      action: confirmedWithPatientWording,
      user: verifiedBy,
      date: confirmedWithPatientDate,
      dateFormat: 'MM/DD/YYYY',
    });
    return (
      <Grid container className={classNames(containerClass, classes.verifyContainer)}>
        <Grid item xs={6}>
          <Typography className={classes.verifyWording}>{verifiedWording}</Typography>
        </Grid>
      </Grid>
    );
  }

  // Reverified
  if (verificationId === VERIFICATION_STATUS_REVERIFIED) {
    return (
      <Grid container className={classNames(containerClass, classes.nonVerifyContainer)}>
        <Grid item xs={6}>
          <Typography className={classes.nonVerifyWording}>Need Reverify</Typography>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.verificationBtnContainer}>
            <Button
              id={verificationBtnId}
              type="button"
              onClick={handleVerify}
              className={classes.verificationBtn}
            >
              {reverifyWithPatientWording}
            </Button>
            {showEMR && (
              <Button
                id={emrVerificationBtnId}
                type="button"
                onClick={handleMarkEmrConfirmed}
                className={classes.verificationBtn}
                disabled={emrConfirmed}
              >
                {confirmedWithEmrWording}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    );
  }

  // Not Touched
  return emrConfirmed && showEMR ? (
    <RenderConfirmedWithEMR
      buttonHandler={handleVerify}
      buttonLabel={confirmedWithPatientWording}
    />
  ) : (
    <Grid container className={classNames(containerClass, classes.nonVerifyContainer)}>
      <Grid item xs={6}>
        <Grid container alignItems="center">
          {auditRules && (
            <Audit
              iconType="warning"
              auditRules={auditRules}
              auditCategory={auditCategory}
              context={auditContext.Warning}
            />
          )}
          <Typography className={classes.nonVerifyWording}>Not Confirmed with Patient</Typography>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.verificationBtnContainer}>
          <Button
            id={verificationBtnId}
            type="button"
            onClick={handleMarkPatientConfirmed}
            className={classes.verificationBtn}
            disabled={disabled}
          >
            {confirmedWithPatientWording}
          </Button>
          {showEMR && (
            <Button
              id={emrVerificationBtnId}
              type="button"
              onClick={handleMarkEmrConfirmed}
              className={classes.verificationBtn}
              disabled={emrConfirmed}
            >
              {confirmedWithEmrWording}
            </Button>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export const QuaternaryVerificationPanel = withStyles(styles, {
  withTheme: true,
})(quaternaryVerificationPanel);

import React from 'react';
import { compose } from 'recompose';
import Divider from 'components/divider';
import { cancelPATask } from 'actions/action-tasks';
import { expandTheme } from 'utils/theme-util';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { getModalStyle } from 'services/utils/styles-service';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { RootStateOrAny, connect, useDispatch, useSelector } from 'react-redux';
import { taskPaApprovedModalActions } from 'actions/action-task-pa-approved-modal';
import { IOptionTaskPaApprovedModal } from 'interfaces/redux/ITaskPaApprovedModal';
import {
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@mui/material';
import { PA } from 'constants/index.js';

function formatDate(str: string) {
  return str.split('T')[0].split('-').reverse().join('-');
}

const styles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    addPAModal: {
      position: 'absolute',
      width: theme.spacing(80),
      backgroundColor: palette.primary.background,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
      borderRadius: 5,
    },
    text: {
      color: palette.primary.grey23,
      marginTop: theme.spacing(1.25),
      marginBottom: theme.spacing(0.25),
    },
    textRadio: {
      fontSize: theme.spacing(1.8),
      marginTop: theme.spacing(1.5),
      marginBotton: theme.spacing(1.5),
    },
    textRadioStrong: {
      fontSize: theme.spacing(1.9),
      fontWeight: 'bold',
    },
  });
};

interface IProps {
  classes: Record<string, string>
  cancelPATask: (taskId: number) => void;
}

function TaskPAApproved(props: any) {
  // eslint-disable-next-line no-shadow
  const { classes, cancelPATask } = props as IProps;
  const dispatch = useDispatch();
  const [mode, setMode] = React.useState<IOptionTaskPaApprovedModal>();
  const isOpen = useSelector((state: RootStateOrAny) => state.taskPaApprovedModal.visible);
  const lastApproved = useSelector((state: RootStateOrAny) => state.taskPaApprovedModal.lastApproved);
  const newTask = useSelector((state: RootStateOrAny) => state.taskPaApprovedModal.newTask);
  const event = useSelector((state: RootStateOrAny) => state.taskPaApprovedModal.event);

  const handleCancel = React.useCallback(() => {
    dispatch(taskPaApprovedModalActions.resetModal());
  }, []);
  const handleSubmit = React.useCallback(() => {
    if (mode === 'close') cancelPATask(lastApproved.id);

    event?.();
    handleCancel?.();
  }, [lastApproved, newTask, mode]);

  return (
    <Modal open={isOpen}>
      <form>
        <div style={getModalStyle()} className={classes.addPAModal}>
          <h2>This therapy has an active PA chain open</h2>
          <Divider />
          <Grid container>
            <Grid item xs={4}>
              <Typography className={classes.text}>
                Insurance
              </Typography>
              <Typography>
                {lastApproved?.insurance || '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.text}>
                Expiration Date
              </Typography>
              <Typography>
                {(lastApproved?.expiration_date && formatDate(lastApproved?.expiration_date)) || '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <Typography className={classes.text}>
                BIN:
              </Typography>
              <Typography>
                {lastApproved?.bin || '-'}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.text}>
                PCN:
              </Typography>
              <Typography>
                {lastApproved?.pcn || '-'}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.text}>
                Group Number:
              </Typography>
              <Typography>
                {lastApproved?.group_number || '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container marginTop={2} paddingBottom={2}>
            <RadioGroup
              name="radio-buttons-group"
              onChange={({ target }) =>
                setMode(target.defaultValue as IOptionTaskPaApprovedModal)}
            >
              <FormControlLabel
                value="close"
                control={<Radio />}
                label={
                  <Typography className={classes.textRadio}>
                    <span className={classes.textRadioStrong}>
                      CLOSE
                    </span> previous PA chain and Create new<br />
                    (therapy will have one active PA chain)
                  </Typography>
                }
              />
              <FormControlLabel
                value="keep"
                control={<Radio />}
                label={
                  <Typography className={classes.textRadio}>
                    <span className={classes.textRadioStrong}>
                      KEEP
                    </span> previous PA chain and Create new<br />
                    (therapy will have multiple active PA chains)
                  </Typography>
                }
              />
            </RadioGroup>
          </Grid>
          <Divider />
          <Grid container>
            <Grid item xs={12}>
            <ConfirmationPanel
              handleSubmit={handleSubmit}
              handleCancel={handleCancel}
              disableSubmit={!mode}
              submitButtonText="Create"
            />
            </Grid>
          </Grid>
        </div>
      </form>
    </Modal>
  );
}
export default compose(
  connect(() => ({}), {
    cancelPATask,
  }),
  withStyles(styles),
)(TaskPAApproved);

import { IResponsible, IncidentResponsibleTypes } from 'interfaces/redux/task-types/IIncidentTask';

/**
 * Get Responsible string formatted wrapping the responsibles with type IncidentResponsibleTypes.User in brackets
 * only when the incident is external
 * @param {IResponsible[]} responsibles A List of responsibles that can be any of the IncidentResponsibleTypes
 * @param {boolean} isExternal is the incident external
 * @returns {string} formatted responsible string
 * External: NonUserResponsible1, NonUserResponsible2 [UserResponsible1, UserResposible2]
 * Internal: NonUserResponsible1, NonUserResponsible2, UserResponsible1, UserResposible2
 */
export const getResponsiblesString = (
  responsibles: IResponsible[] | undefined,
  isExternal: boolean,
): string => {
  const responsiblesList: IResponsible[] = responsibles || [];
  const separator = ', ';
  if (isExternal) {
    const nonUsersResponsibles = (responsiblesList || [])
      .filter(responsible => responsible.type !== IncidentResponsibleTypes.User)
      .map(({ display }) => display);

    const usersResponsibles = (responsibles || [])
      .filter(responsible => responsible.type === IncidentResponsibleTypes.User)
      .map(({ display }) => display);

    return (nonUsersResponsibles.length > 0 || usersResponsibles.length > 0) ? nonUsersResponsibles.concat(usersResponsibles).join(separator) : ' - ';
  }
  return responsiblesList.map(responsible => responsible.display).join(separator);
};

import {
  DUR_STATUS_TRANSITION_REQUEST,
  DUR_CREATE_NEW_REQUEST,
  DUR_FETCH_HISTORY_REQUEST,
  DUR_TOGGLE_SHOW_NEW_INTERACTIONS,
  DUR_TRIGGER_CHECK_INTERACTION_REQUEST,
  INTERACTION_FINDER_TRIGGER,
} from 'constants/index';

import { DurStatus } from 'interfaces/enums/TaskStatuses/DrugUtilizationReviewStatus';

const generateDur = (patientId, serviceGroupId, followUpDate) => ({
  type: DUR_CREATE_NEW_REQUEST,
  patientId,
  serviceGroupId,
  followUpDate,
});

const verifyDur = durId => ({
  type: DUR_STATUS_TRANSITION_REQUEST,
  durId,
  statusId: DurStatus.Reviewed,
});

const fetchDurHistory = ({ patientId, allSnapshots }) => ({
  type: DUR_FETCH_HISTORY_REQUEST,
  patientId,
  allSnapshots,
});

const toggleShowNewInteractions = (durId, newInteractionsOnly) => ({
  type: DUR_TOGGLE_SHOW_NEW_INTERACTIONS,
  payload: {
    durId,
    newInteractionsOnly,
  },
});

const triggerInteractions = patientId => ({
  type: DUR_TRIGGER_CHECK_INTERACTION_REQUEST,
  patientId,
  source: INTERACTION_FINDER_TRIGGER.User,
  sourceValue: {},
});

export { verifyDur, generateDur, fetchDurHistory, toggleShowNewInteractions, triggerInteractions };

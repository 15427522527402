import React from 'react';
import { Grid } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { UserUtils } from 'utils/user-utils';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { UserPermissions } from 'interfaces/user/Permissions';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { IGetPayorDataResponse } from 'models/application-manager/cpmp/IGetPayorDataResponse';
import { useDebounce } from 'use-debounce';
import { SectionTitleStyled } from '../../components/section-title';
import { SearchField } from '../../components/search-field';
import { CpmpStyles } from '../index.styles';
import { DataTable } from './tables/data-table';

const searchFieldPlaceholder = 'Search by BIN, PCN or GROUP';
const initialSearchTerm = '';
const debounceTimeMs = 500;

type IPayorProps = WithStyles<typeof CpmpStyles>;

function CpmpPayor(props: IPayorProps): JSX.Element {
  const { classes } = props;

  // # region redux state
  const user = useTypedSelector(state => state.auth.currentUser);
  const stateSelectedCustomerId = useTypedSelector(state => state.filters.selectedCustomerId);
  const customers = useTypedSelector(state => state.filters.customers);
  // # endregion

  // #region useState
  const [selectedCustomerId, setSelectedCustomerId] =
    React.useState<number>(stateSelectedCustomerId);
  const [searchTerm, setSearchTerm] = React.useState<string>(initialSearchTerm);
  const [reloadTrigger, setReloadTrigger] = React.useState<Date | undefined>(undefined);
  const [payorList, setPayorList] = React.useState<IGetPayorDataResponse>({ result: [] });
  const [debouncedSearchTerm] = useDebounce<string | undefined>(searchTerm, debounceTimeMs);
  const [reloadDataTrigger, setReloadDataTrigger] = React.useState<Date | undefined>(undefined);
  const dataTableContext = React.createContext(payorList);
  // #endregion

  // # region useEffect
  React.useEffect(() => {
    if (selectedCustomerId == null) {
      if (stateSelectedCustomerId != null) {
        setSelectedCustomerId(stateSelectedCustomerId);
      } else if (customers.length > 0) {
        setSelectedCustomerId(customers[0].id);
      }
    }
  }, [stateSelectedCustomerId]);

  React.useEffect(() => {
    let active = true;
    (async () => {
      const result = await ApplicationManagerClient.fetchPayorData();
      if (result.data.result.length > 0) {
        setPayorList({ result: result.data.result });
      }
    })();
    return () => {
      active = false;
    };
  }, [reloadDataTrigger]);
  // #endregion

  // # region memos
  const userHasPermissionToUpdatePayorStatusItem = React.useMemo<boolean>(() => {
    return UserUtils.userIsPermitted(UserPermissions.ApplicationManagerCpmpPayorEdit);
  }, [user]);

  const userManageUserLevel = React.useMemo<boolean>(() => {
    return UserUtils.userIsPermitted(UserPermissions.ApplicationManagerUsersManageUserLevel);
  }, [user]);
  // # endregion

  return (
    <>
      <SectionTitleStyled title="Payor" />
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container justifyContent="flex-end">
            <Grid item xs="auto">
              <Grid container spacing={2}>
                <Grid item>
                  <SearchField
                    width={48}
                    value={searchTerm}
                    onChange={setSearchTerm}
                    initialValue={initialSearchTerm}
                    placeholder={searchFieldPlaceholder}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <dataTableContext.Provider value={payorList}>
              <DataTable
                searchTerm={debouncedSearchTerm}
                triggerReload={reloadTrigger}
                handleReloadDataTrigger={date => setReloadDataTrigger(date)}
                activeAnyField={searchTerm.length > 0}
                payorList={payorList}
                userHasPermissionToEditPayorStatusItems={
                  userHasPermissionToUpdatePayorStatusItem || userManageUserLevel
                }
              />
            </dataTableContext.Provider>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default withStyles(CpmpStyles)(CpmpPayor);

import { createSelector, Selector } from '@reduxjs/toolkit';
import { IState } from 'interfaces/redux/IState';
import { useSelector } from 'react-redux';
import { getUserById } from 'services/utils/users-service';
import { getActionedByUserText } from 'utils/user-utils';
import { IPbmInsurance } from 'interfaces/redux/IPbmInsurances';
import { IMedicalInsurance } from 'interfaces/redux/IMedicalInsurances';

export interface VerifiedInfo {
  userId: number;
  date: string;
}
export type SelectVerifiedInfo = Selector<IState, VerifiedInfo | null>;

const createSelectPatientVerification = (
  selectVerified: SelectVerifiedInfo,
  action: string,
): Selector<IState, string | null> =>
  createSelector(
    (state: IState) => state.lookups.users,
    selectVerified,
    (users, verifiedInfo) =>
      verifiedInfo
        ? getActionedByUserText({
            user: getUserById(verifiedInfo.userId, users),
            date: verifiedInfo.date,
            action,
          })
        : null,
  );

export const useVerifiedText = (selectVerified: SelectVerifiedInfo, action = 'Verified') =>
  useSelector(createSelectPatientVerification(selectVerified, action));

export const isActiveInsurance = (insurance: IPbmInsurance | IMedicalInsurance) =>
  insurance.end_date === null || new Date(insurance.end_date).getTime() > Date.now();

export const selectInsuranceVerification = (
  insurances: Array<IPbmInsurance | IMedicalInsurance>,
  selectedPatientId: number,
): VerifiedInfo | null => {
  const activeInsurances = insurances.filter(
    ins => ins.patient_id === selectedPatientId && isActiveInsurance(ins),
  );

  if (activeInsurances.some(ins => ins.is_verified !== 1)) {
    return null;
  }

  activeInsurances.sort(
    (a, b) => new Date(String(b.verified_dt)).getTime() - new Date(String(a.verified_dt)).getTime(),
  );

  const [mostRecentlyVerified] = activeInsurances;

  if (
    mostRecentlyVerified?.is_verified === 1 &&
    mostRecentlyVerified.verified_dt &&
    mostRecentlyVerified.verified_by
  ) {
    return {
      userId: mostRecentlyVerified.verified_by,
      date: mostRecentlyVerified.verified_dt,
    };
  }
  return null;
};

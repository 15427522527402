import React, { useMemo, useState } from 'react';
import { Grid, Button } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { useDebounce } from 'use-debounce/lib';

import { UserPermissions } from 'interfaces/user/Permissions';
import { UserUtils } from 'utils/user-utils';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { CpmpStyles } from '../index.styles';

import { SectionTitleStyled } from '../../components/section-title';
import GPITable from './components/GpiTable';
import GPISearch from './components/GpiSearch';
import AddNewGpiProtocol from './modal/AddNewGpiProtocol';
import EditGpiProtocol from './modal/EditGpiProtocol';
import { IGpiProtocolItem } from './types';

const debounceTimeMs = 500;

type ICpmpProps = WithStyles<typeof CpmpStyles>

const CpmpGpi = (props: ICpmpProps): JSX.Element => {
  const [search, setSearch] = useState<string>('');
  const [reload, triggerReload] = useState<Date | undefined>(undefined);
  const [debouncedSearchTerm] = useDebounce(search, debounceTimeMs);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [editGpiProtocol, setEditGpiProtocol] = useState<IGpiProtocolItem | undefined>();

  const { classes } = props;

  const user = useTypedSelector(state => state.auth.currentUser);
  const userHasPermissionToaddDcItem = useMemo<boolean>(() => {
    return UserUtils.userIsPermitted(UserPermissions.ApplicationManagerCpmpEdit);
  }, [user]);
  const userManageUserLevel = useMemo<boolean>(() => {
    return UserUtils.userIsPermitted(UserPermissions.ApplicationManagerUsersManageUserLevel);
  }, [user]);

  const renderAddGpiProtocolButton = (): JSX.Element => (
    <Button variant="contained" className={classes.addButton} onClick={() => setAddModalOpen(true)}>
      Add GPI-10 Protocol
    </Button>
  );

  const renderAddGpiProtocolModal = (): JSX.Element => (
    <AddNewGpiProtocol
      open={addModalOpen}
      closeModal={() => setAddModalOpen(false)}
      reloadListTrigger={triggerReload}
    />
  );

  const renderEditGpiProtocolModal = (): JSX.Element => (
    <EditGpiProtocol
      open={editModalOpen}
      gpiProtocol={editGpiProtocol as unknown as IGpiProtocolItem}
      closeModal={handleCloseGpiProtocolEdit}
      reloadListTrigger={triggerReload}
    />
  );

  const handleGpiProtocolEdit = (gpi: IGpiProtocolItem): void => {
    setEditGpiProtocol(gpi);
    setEditModalOpen(true);
  };

  const handleCloseGpiProtocolEdit = (): void => {
    setEditModalOpen(false);
    setEditGpiProtocol(undefined);
  };

  const rightSideOptions = [];
  if (userHasPermissionToaddDcItem || userManageUserLevel) {
    rightSideOptions.push({
      element: renderAddGpiProtocolButton(),
      key: 'add-gpi-item-button',
    });
  }

  return (
    <>
      <SectionTitleStyled title="GPI-10 Codex" rightSideItems={rightSideOptions} />
      <Grid container direction="column" spacing={2}>
        <GPISearch initialValue={search} searchTerm={search} onChange={setSearch} />
        <GPITable
          userPermission={userHasPermissionToaddDcItem || userManageUserLevel}
          searchString={debouncedSearchTerm}
          reloadTrigger={reload}
          onReload={date => triggerReload(date)}
          handleGpiProtocolEdit={handleGpiProtocolEdit}
        />
      </Grid>
      {renderAddGpiProtocolModal()}
      {renderEditGpiProtocolModal()}
    </>
  );
};

export default withStyles(CpmpStyles)(CpmpGpi);

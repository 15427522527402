import {
  TOGGLE_RECONCILIATION_MODAL,
  RESOLVE_RECONCILIATION_MEDICATION_REQUEST,
  RESOLVE_RECONCILIATION_PATIENT_REQUEST,
  LINK_RECONCILIATION_MEDICATION_REQUEST,
  RESOLVE_RECONCILIATION_ALLERGY_REQUEST,
} from 'constants/index';

export const toggleReconciliationModal = newVal => ({
  type: TOGGLE_RECONCILIATION_MODAL,
  payload: newVal,
});

export const resolveReconciliationMedication = (action, reconciliation, excludedVals) => {
  const errorMessageText = 'Medication Reconciliation Failed';
  let successMessageText;
  const request = reconciliation;
  switch (action) {
    case 'reject': {
      successMessageText = 'Medication changes rejected successfully';
      break;
    }
    case 'accept': {
      successMessageText = 'Medication changes accepted successfully';
      break;
    }
    case 'merge': {
      successMessageText = 'Medication linked successfully';
      break;
    }
    case 'add': {
      successMessageText = 'Medication added successfully';
      break;
    }
    default:
      break;
  }
  const jsonExcludedVals = excludedVals ? JSON.stringify(excludedVals) : null;
  return {
    type: RESOLVE_RECONCILIATION_MEDICATION_REQUEST,
    payload: { ...request, jsonExcludedVals },
    meta: {
      errorMessage: errorMessageText,
      successMessage: successMessageText,
    },
  };
};
export const resolveReconciliationPatient = (
  action,
  type,
  reconciliation,
  excludedVals,
  isLastPatientCard,
  isDeceased = false,
) => {
  const errorMessageText = 'Patient Reconciliation Failed';
  let successMessageText;
  switch (action) {
    case 'reject': {
      successMessageText = `Patient ${type} changes rejected successfully`;
      break;
    }
    case 'accept': {
      successMessageText = `Patient ${type} changes accepted successfully`;
      break;
    }
    case 'add': {
      successMessageText = `Patient ${type} added successfully`;
      break;
    }
    case 'update': {
      successMessageText = `Patient ${type} updated successfully`;
      break;
    }
    default:
      break;
  }
  const jsonExcludedVals = excludedVals ? JSON.stringify(excludedVals) : null;
  return {
    type: RESOLVE_RECONCILIATION_PATIENT_REQUEST,
    payload: {
      reconciliation,
      reconciliationAction: action,
      reconciliationType: type,
      jsonExcludedVals,
      isLastPatientCard,
      isDeceased,
    },
    meta: {
      errorMessage: errorMessageText,
      successMessage: successMessageText,
    },
  };
};

export const resolveReconciliationAllergy = (action, reconciliation, excludedVals) => {
  const errorMessageText = 'Allergy Reconciliation Failed';
  let successMessageText;
  const request = reconciliation;
  switch (action) {
    case 'reject': {
      successMessageText = 'Allergy changes rejected successfully';
      break;
    }
    case 'accept': {
      successMessageText = 'Allergy changes accepted successfully';
      break;
    }
    case 'merge': {
      successMessageText = 'Allergy Merged successfully';
      break;
    }
    case 'add': {
      successMessageText = 'Allergy added successfully';
      break;
    }
    default:
      break;
  }
  const jsonExcludedVals = excludedVals ? JSON.stringify(excludedVals) : null;
  return {
    type: RESOLVE_RECONCILIATION_ALLERGY_REQUEST,
    payload: { ...request, jsonExcludedVals },
    meta: {
      errorMessage: errorMessageText,
      successMessage: successMessageText,
    },
  };
};

export const linkReconciliationMedication = payload => ({
  type: LINK_RECONCILIATION_MEDICATION_REQUEST,
  payload,
});

import React from 'react';

import ReviewItem from 'components/fc-self-service/components/review-item';
import Question from 'components/fc-self-service/components/question';
import FillCoordinationFieldNames from './fill_coordination_field_names';

const YesNoTemplate = (props: any) => {
  const { saveAnswer, isReview, currentAnswers, onEdit, questionText, keyName, nextQuestion } =
    props;

  const saveAnswerCurried = (value: any) => {
    saveAnswer({ keyName, value });
    nextQuestion();
  };

  return isReview ? (
    <ReviewItem
      questionText={`${questionText}?`}
      onEdit={onEdit}
      currentAnswer={currentAnswers[keyName] ? 'Yes' : 'No'}
      keyName={keyName}
    />
  ) : (
    <Question
      questionText={`${questionText}?`}
      response={saveAnswerCurried}
      keyName={keyName}
      buttons={{ button1Text: 'Yes', button2Text: 'No' }}
    />
  );
};

export const NewAllergies = (props: any) => {
  const { saveAnswer, isReview, currentAnswers, onEdit, nextQuestion } = props;
  const keyName = FillCoordinationFieldNames.New_Allergies;
  const propsOfInterest = { saveAnswer, isReview, currentAnswers, onEdit, keyName, nextQuestion };
  const qText = 'Do you have any new allergies';
  return <YesNoTemplate {...propsOfInterest} questionText={qText} />;
};

export const SideEffects = (props: any) => {
  const { saveAnswer, isReview, currentAnswers, onEdit, nextQuestion } = props;
  const keyName = FillCoordinationFieldNames.Side_Effects;
  const propsOfInterest = { saveAnswer, isReview, currentAnswers, onEdit, keyName, nextQuestion };
  const qText = 'Have you experienced any side effects since last time we spoke';
  return <YesNoTemplate {...propsOfInterest} questionText={qText} />;
};

export const MedicationChanges = (props: any) => {
  const { saveAnswer, isReview, currentAnswers, onEdit, nextQuestion } = props;
  const keyName = FillCoordinationFieldNames.Changes_In_Medication;
  const propsOfInterest = { saveAnswer, isReview, currentAnswers, onEdit, keyName, nextQuestion };
  const qText = 'Are there any changes to your medications';
  return <YesNoTemplate {...propsOfInterest} questionText={qText} />;
};

export const MissedDoses = (props: any) => {
  const { saveAnswer, isReview, currentAnswers, onEdit, nextQuestion } = props;
  const keyName = FillCoordinationFieldNames.Patient_Missed_Doses;
  const propsOfInterest = { saveAnswer, isReview, currentAnswers, onEdit, keyName, nextQuestion };
  const qText = 'Have you missed any doses of medication';
  return <YesNoTemplate {...propsOfInterest} questionText={qText} />;
};

export const PatientQuestions = (props: any) => {
  const { saveAnswer, isReview, currentAnswers, onEdit, nextQuestion } = props;
  const keyName = FillCoordinationFieldNames.Patient_Questions;
  const propsOfInterest = { saveAnswer, isReview, currentAnswers, onEdit, keyName, nextQuestion };
  const qText = 'Do you have any questions for the pharmacist';
  return <YesNoTemplate {...propsOfInterest} questionText={qText} />;
};

export const NewConditions = (props: any) => {
  const { saveAnswer, isReview, currentAnswers, onEdit, nextQuestion } = props;
  const keyName = FillCoordinationFieldNames.New_Medical_Conditions;
  const propsOfInterest = { saveAnswer, isReview, currentAnswers, onEdit, keyName, nextQuestion };
  const qText = 'Do you have any new medical conditions';
  return <YesNoTemplate {...propsOfInterest} questionText={qText} />;
};

export const HospitalVisits = (props: any) => {
  const { saveAnswer, isReview, currentAnswers, onEdit, nextQuestion } = props;
  const keyName = FillCoordinationFieldNames.Hospital_Visit;
  const propsOfInterest = { saveAnswer, isReview, currentAnswers, onEdit, keyName, nextQuestion };
  const qText =
    'Have you been hospitalized or gone to the emergency department since we last spoke';
  return <YesNoTemplate {...propsOfInterest} questionText={qText} />;
};

export const NewInfections = (props: any) => {
  const { saveAnswer, isReview, currentAnswers, onEdit, nextQuestion } = props;
  const keyName = FillCoordinationFieldNames.New_Infections;
  const propsOfInterest = { saveAnswer, isReview, currentAnswers, onEdit, keyName, nextQuestion };
  const qText = 'Have you experienced any infections since we last spoke';
  return <YesNoTemplate {...propsOfInterest} questionText={qText} />;
};

import React from 'react';
import { Typography, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Audit from 'components/display/audit';
import { auditContext } from 'constants/lists';
import { styles } from './field-styles';

const DetailField = ({
  fieldName,
  field,
  defaultValue = undefined,
  variant = undefined,
  classes = undefined,
  id = undefined,
  component = 'p',
  showAudit = false,
  auditRules = undefined,
  isHref = false,
  tooltipComponentFront = undefined,
}) => {
  let value = field ? field.toString() : defaultValue;
  let styleDef = {};
  switch (variant) {
    case 'raw':
      break;
    case 'element':
      value = field;
      styleDef = { textTransform: 'capitalize' };
      break;
    default:
      value = value && value.toLowerCase();
      styleDef = { textTransform: 'capitalize' };
      break;
  }
  return (
    <div>
      <Grid container align-items="center">
        <Typography variant="caption" className={classes.detailHeader}>
          {fieldName}
        </Typography>
        {showAudit && (
          <Audit iconType="info" auditRules={auditRules} context={auditContext.Warning} />
        )}
      </Grid>
      {tooltipComponentFront ? (
        <Grid container>
          <Grid item xs={1}>
            {tooltipComponentFront}
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body2" component={component} style={styleDef} id={id}>
              {value || '-'}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Typography variant="body2" component={component} style={styleDef} id={id}>
          {isHref && value ? (<a href={value}>{value}</a>) : value || ''}
        </Typography>
      )}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(DetailField);

import { ISmsResponse } from 'models/patient-sms/ISmsResponse';
import { IPatientSmsMessage } from 'interfaces/redux/IPatient';
import { PhiEntityType, PhiEntity } from 'models/patient-sms/ISendSmsErrorResponse';
import { convertToArborDate } from 'models/time/arbor-date';

const phiTypeMap: Record<PhiEntityType, string> = {
  [PhiEntityType.Address]: 'Address',
  [PhiEntityType.Age]: 'Age',
  [PhiEntityType.Date]: 'Date',
  [PhiEntityType.Email]: 'Email',
  [PhiEntityType.Id]: 'Identifier',
  [PhiEntityType.Name]: 'Person Name',
  [PhiEntityType.PhoneOrFax]: 'Phone or Fax',
  [PhiEntityType.Profession]: 'Profession',
  [PhiEntityType.Url]: 'URL',
};

export const mapSmsResponseToPatientSms = (
  patientFullName: string,
  usedPhone: string,
  smsResponse: ISmsResponse,
): IPatientSmsMessage => {
  return {
    id: smsResponse.id,
    content: smsResponse.content,
    date: String(
      convertToArborDate(smsResponse.created_date).getCustomerDatetime(false, 'MM/DD/YYYY h:mm a'),
    ),
    isPinned: Boolean(smsResponse.pinned),
    status: smsResponse.status,
    type: smsResponse.direction,
    from: null,
    phoneNumber: usedPhone,
    sentBy: smsResponse.sent_by || null,
    to: patientFullName,
    toNumber: smsResponse.to_number,
    readStatusChangedByUserName: smsResponse.updatingUser,
    tags: smsResponse.tags.map(tag => ({ id: tag.id, name: tag.tag_display })),
    display_status: smsResponse.display_status,
    display_status_additional: smsResponse.display_status_additional,
  };
};

export const mapPhiEntityToInfoRow = (
  entity: PhiEntity,
): { messagePortion: string; phiType: string } => ({
  messagePortion: entity.text,
  phiType: phiTypeMap[entity.type] || entity.type,
});

import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import cx from 'classnames';
import React, { useState } from 'react';
import { compose } from 'redux';
import { Field } from 'redux-form';
import { renderDropdown, renderTextField } from 'components/form/field/redux-field';
import {
  Grid,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@mui/material';

import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { styles } from './benefits-investigation-form-styles';
import { IDur } from '../../../../interfaces/benefits-investigations/IDur';
import { BenefitsInvestigationFields } from '../../../../constants/lists';

interface IBenefitsInvestigationDurProps extends WithStyles<typeof styles> {
  onAdd: () => void;
  reasonForServiceFieldName: string;
  professionalServiceFieldName: string;
  resultOfServiceFieldName: string;
  levelOfEffortFieldName: string;
  coAgentIdQualifierFieldName: string;
  coAgentIdFieldName: string;
  durItems: IDur[];
  disableAllFields: boolean;
}

const BenefitsInvestigationDur: React.FC<IBenefitsInvestigationDurProps> = (props): JSX.Element => {
  const { classes } = props;

  const [durFormOpen, setDurFormOpen] = useState<boolean>(false);
  const displayDurForm = () => setDurFormOpen(true);
  const hideDurForm = () => setDurFormOpen(false);

  const getNameFromList = (
    value: string,
    options: { label: string; value: string }[],
  ): string | undefined => {
    return options.find(x => x.value === value)?.label;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Button
          onClick={() => displayDurForm()}
          className={durFormOpen ? classes.hide : classes.benefitsInvestigationButton}
          disabled={props.disableAllFields}
        >
          ADD DUR ITEM
        </Button>
      </Grid>
      <Grid
        container
        spacing={3}
        className={durFormOpen ? undefined : classes.hide}
        style={{ marginLeft: 0, marginRight: 0 }}
      >
        <Grid item xs={6}>
          <Field
            name={props.reasonForServiceFieldName}
            label="Reason for Service"
            component={renderDropdown}
            fields={BenefitsInvestigationFields.DurReasonForService}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={props.professionalServiceFieldName}
            label="Professional Service"
            component={renderDropdown}
            fields={BenefitsInvestigationFields.DurProfessionalService}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={props.resultOfServiceFieldName}
            label="Result of Service"
            component={renderDropdown}
            fields={BenefitsInvestigationFields.DurResultOfService}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={props.levelOfEffortFieldName}
            label="Level of Effort"
            component={renderDropdown}
            fields={BenefitsInvestigationFields.DurLevelOfEffort}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={props.coAgentIdQualifierFieldName}
            label="Co-Agent ID Qualifier"
            component={renderDropdown}
            fields={BenefitsInvestigationFields.DurCoAgentIdQualifier}
            width="100%"
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={props.coAgentIdFieldName}
            label="Co-Agent ID"
            component={renderTextField}
            width="100%"
          />
        </Grid>
        <Grid item xs={12}>
          <ConfirmationPanel
            cancelButtonName="dur_cancel_button"
            submitButtonName="dur_submit_button"
            submitButtonClass={classes.benefitsInvestigationSaveButton}
            handleCancel={() => hideDurForm()}
            handleSubmit={() => {
              props.onAdd();
              hideDurForm();
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Table>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell key="reason_for_service">Reason for Service</TableCell>
              <TableCell key="professional_service">Professional Service</TableCell>
              <TableCell key="result_of_service">Result of Service</TableCell>
              <TableCell key="level_of_effort">Level of Effort</TableCell>
              <TableCell key="co_agent_id_qualifier">Co-Agent ID Qualifier</TableCell>
              <TableCell key="co_agent_id">Co-Agent ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.durItems.length !== 0 &&
              props.durItems.map((dur, index) => {
                return (
                  <TableRow key={`dur-${index}`}>
                    <TableCell>
                      {getNameFromList(
                        dur.reasonForService,
                        BenefitsInvestigationFields.DurReasonForService,
                      )}
                    </TableCell>
                    <TableCell>
                      {getNameFromList(
                        dur.professionalService,
                        BenefitsInvestigationFields.DurProfessionalService,
                      )}
                    </TableCell>
                    <TableCell>
                      {getNameFromList(
                        dur.resultOfService,
                        BenefitsInvestigationFields.DurResultOfService,
                      )}
                    </TableCell>
                    <TableCell>
                      {getNameFromList(
                        dur.levelOfEffort,
                        BenefitsInvestigationFields.DurLevelOfEffort,
                      )}
                    </TableCell>
                    <TableCell>
                      {getNameFromList(
                        dur.coAgentIdQualifier,
                        BenefitsInvestigationFields.DurCoAgentIdQualifier,
                      )}
                    </TableCell>
                    <TableCell>{dur.coAgentId}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {props.durItems.length === 0 && (
          <Typography variant="body2" className={classes.noDursWording}>
            No DURs have been added to display
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(BenefitsInvestigationDur);

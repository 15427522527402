import React from 'react';
import { Button, Grid, Modal, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { getModalStyle } from 'services/utils/styles-service';
import { IAlertModal } from './interface';
import { styles } from './alert-modal.styles';

const AlertModal = ({ message, isOpen, setIsOpen, classes }: IAlertModal): JSX.Element => {
  return (
    <Modal open={isOpen}>
      <Grid spacing={2} container style={getModalStyle()} className={classes.modalContentWrapper}>
        <Grid item xs={12}>
          <Typography>{message}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={() => setIsOpen(false)}>Close</Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default withStyles(styles)(AlertModal);

import React from 'react';
import { Paper, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import cx from 'classnames';
import { getDispensingIcon, getClinicalSupportIcon } from 'components/icons/icons';

import { styles } from './therapy-detail.styles';
import { DispensingStatusValue } from '../../../constants/enums';

const TherapyEnrollmentStatus = ({
  classes,
  status,
  clinicalSupportStatusId,
  dispensingStatusId,
  categoryId,
  isAncillaryMedTherapyOptInOutDispense,
  id,
}) => (
  <Paper className={classes.therapyStatus} elevation={1}>
    <Grid container>
      <Grid item>
        {/* eslint-disable-next-line max-len */}
        <div className={classes.therapyStatusIconBorder}>
          {getDispensingIcon(dispensingStatusId)}
        </div>
      </Grid>
      <Grid item>
        <div className={classes.therapyStatusIcon}>
          {isAncillaryMedTherapyOptInOutDispense
            ? getClinicalSupportIcon(DispensingStatusValue.OptIn)
            : getClinicalSupportIcon(clinicalSupportStatusId)}
        </div>
      </Grid>
      <Grid item>
        <p className={classes.therapyStatusReason}>Enrollment</p>
        <h3
          className={cx(classes.therapyStatusTitle, classes[`therapyStatusTitle${categoryId}`])}
          id={id}
        >
          {status || 'No reason'}
        </h3>
      </Grid>
    </Grid>
  </Paper>
);

export default withStyles(styles)(TherapyEnrollmentStatus);

import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Button } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { useDebounce } from 'use-debounce';
import { UserUtils } from 'utils/user-utils';
import { UserPermissions } from 'interfaces/user/Permissions';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { notifyError } from 'actions/action-notifications';
import { TtcTable } from '../tables/ttc-table';
import { SectionTitleStyled } from '../../components/section-title';
import { SearchField } from '../../components/search-field';
import { AddNewTtcModal } from '../modals/add-new-ttc-modal';
import { TtcStyles } from './index.styles';
import { logger } from '../../../../winston-logger';

const searchFieldPlaceholder = 'Search by Therapeutic Class';
const debounceTimeMs = 500;
const initialSearchTerm = '';

type ITtcProps = WithStyles<typeof TtcStyles>

const Ttc = (props: ITtcProps): JSX.Element => {
  const dispatch = useDispatch();
  const { classes } = props;
  // #region useState
  const [searchTerm, setSearchTerm] = React.useState<string>(initialSearchTerm);
  const [debouncedSearchTerm] = useDebounce<string | undefined>(searchTerm, debounceTimeMs);
  const [addModalOpen, setAddModalOpen] = React.useState<boolean>(false);
  const [reloadTrigger, setReloadTrigger] = React.useState<Date | undefined>(undefined);
  const [therapeuticAreas, setTherapeuticAreas] = React.useState<{ id: number; name: string }[]>(
    [],
  );
  const user = useTypedSelector(state => state.auth.currentUser);
  // #endregion

  // #region memos
  const userHasEditPermission = React.useMemo<boolean>(() => {
    return UserUtils.userIsPermitted(UserPermissions.ApplicationManagerTtcEdit);
  }, [user]);
  // #endregion

  React.useEffect(() => {
    (async () => {
      try {
        const result = await ApplicationManagerClient.getTherapeuticArea();
        setTherapeuticAreas(result?.data?.results || []);
      } catch (error) {
        logger.error(error);
        dispatch(notifyError('Error fetching therapeutic areas'));
      }
    })();
  }, []);
  return (
    <>
      {userHasEditPermission && (
        <AddNewTtcModal
          open={addModalOpen}
          onCancel={() => setAddModalOpen(false)}
          onSuccess={() => setReloadTrigger(new Date())}
          therapeuticAreas={therapeuticAreas}
        />
      )}
      <SectionTitleStyled title="TTC" />
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container justifyContent="flex-end">
            <Grid item xs="auto">
              <Grid container spacing={2}>
                <Grid item>
                  <SearchField
                    width={48}
                    value={searchTerm}
                    onChange={setSearchTerm}
                    initialValue={initialSearchTerm}
                    placeholder={searchFieldPlaceholder}
                  />
                </Grid>
                {userHasEditPermission && (
                  <Grid item>
                    <Button
                      className={classes.addButton}
                      variant="contained"
                      color="primary"
                      onClick={() => setAddModalOpen(true)}
                    >
                      Add New TTC
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Below the buttons */}
        <Grid item>
          <TtcTable
            searchTerm={debouncedSearchTerm}
            triggerReload={reloadTrigger}
            handleReloadDataTrigger={date => setReloadTrigger(date)}
            therapeuticAreas={therapeuticAreas}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(TtcStyles)(Ttc);

import { AxiosResponse } from 'axios';

const fileNameRegex = /attachment; filename="(.*)"/;

export class DownloadFileUtils {
  private static getFileName = (response: AxiosResponse<any>, fileNameOverride: string): string => {
    const fileNameHeader = response.headers['content-disposition'];
    if (fileNameHeader && fileNameRegex.test(fileNameHeader)) {
      const match = fileNameHeader.match(fileNameRegex);
      if (match) {
        return match[1];
      }
    }

    return fileNameOverride;
  };

  // TODO: Is there a better way to do this?
  // https://stackoverflow.com/a/53230807/1325534
  // https://medium.com/@drevets/you-cant-prompt-a-file-download-with-the-content-disposition-header-using-axios-xhr-sorry-56577aa706d6
  public static downloadFile = (response: AxiosResponse<any>, fileNameOverride = 'file'): void => {
    const fileName = DownloadFileUtils.getFileName(response, fileNameOverride);

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  public static downloadUrl = (url: string, filename: string): void => {
    const hyperlink = Object.assign(document.createElement('a'), {
      href: url,
      download: filename,
    });
    document.body.appendChild(hyperlink);
    hyperlink.click();
    URL.revokeObjectURL(url);
    hyperlink.remove();
  };
}

import { IPatient } from 'interfaces/redux/IPatient';
import { IPreferredContact } from 'interfaces/redux/IPreferredContact';
import { parseFullName } from 'utils/name';

export const getPreferredContactName = (
  preferredContact?: IPreferredContact,
): string | undefined => {
  if (preferredContact) {
    const { relationship } = preferredContact;
    const contactFullName = parseFullName(preferredContact);
    return relationship ? `${contactFullName} (${relationship})` : contactFullName;
  }
  return undefined;
};

export const getPatientFullName = (patient: IPatient): string | undefined => {
  if (patient?.first_name || patient?.last_name) {
    const relationship = '(Self)';
    const patientFullName = `${patient.first_name || ''} ${patient.last_name || ''}`;
    return `${patientFullName} ${relationship}`;
  }
  return undefined;
};

import React, { useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import { TableCell, TableRow, Button, Modal, Grid } from '@mui/material';
import { EditHistory, EditPencil } from 'components/icons/icons';
import History from 'containers/common/history';
import compose from 'recompose/compose';
import { getModalStyle } from 'services/utils/styles-service';
import { buildQaId } from 'utils/build-qa-id';
import { useSelector } from 'react-redux';
import PatientSpecialPopulationForm from './patient-special-population-form';
import { styles } from '../patient-clinical-styles';

const qaIdBuilder = buildQaId('patient-special-populations-row');

export const SpecialPopulationRow = props => {
  const { classes, editHandler, specialPopulation, patient, tableColWidths, readOnly, index } =
    props;
  const [specialPopulationHistory, setSpecialPopulationHistory] = useState(false);
  const [hover, setHover] = useState(true);

  const lookups = useSelector(state => state.lookups);

  const toggleHistory = () => {
    setSpecialPopulationHistory(!specialPopulationHistory);
  };
  const onHover = val => {
    setHover(val);
  };
  const onEditClick = e => {
    e.preventDefault();
    editHandler(true, specialPopulation);
  };

  return (
    <>
      <Modal open={specialPopulationHistory} onClose={toggleHistory}>
        <Grid item xs={12} style={getModalStyle()} className={classes.allergiesHistoryModal}>
          {specialPopulation && patient && (
            <History url={`/patients/${patient.id}/special_populations/${specialPopulation.id}`} />
          )}
        </Grid>
      </Modal>
      <TableRow
        key={`tablerow_special_population_${
          specialPopulation && specialPopulation.id
            ? specialPopulation.id
            : 'special_population_id_not_found'
        }`}
        className={specialPopulation?.new ? classes.newAllergyRow : classes.allergyRow}
        onMouseEnter={() => onHover(true)}
        onMouseLeave={() => onHover(false)}
        data-qa-id={qaIdBuilder(index)}
      >
        <TableCell
          colSpan={tableColWidths ? tableColWidths.specialPopulation : null}
          className={classes.allergyCell}
        >
          {specialPopulation.type}
        </TableCell>
        <TableCell
          colSpan={tableColWidths ? tableColWidths.startDate : null}
          className={classes.allergyCell}
        >
          {specialPopulation.start_date || '-'}
        </TableCell>
        <TableCell
          colSpan={tableColWidths ? tableColWidths.endDate : null}
          className={classes.allergyCell}
        >
          {specialPopulation.end_date || '-'}
        </TableCell>

        <TableCell
          colSpan={tableColWidths ? tableColWidths.active : null}
          className={classes.allergyCell}
        >
          {specialPopulation.active ? 'Active' : 'Inactive'}
        </TableCell>
        <TableCell
          colSpan={tableColWidths ? tableColWidths.edit : null}
          align="right"
          className={classes.editHistoryCell}
        >
          {hover && !readOnly && (
            <>
              <Button onClick={toggleHistory} data-qa-id="special_population_history_button">
                <EditHistory />
              </Button>
              <Button onClick={e => onEditClick(e)} data-qa-id="special_population_edit_button">
                <EditPencil />
              </Button>
            </>
          )}
        </TableCell>
      </TableRow>

      <PatientSpecialPopulationForm lookups={lookups} />
    </>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(SpecialPopulationRow);

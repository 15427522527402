import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { FileCopy } from '@mui/icons-material';
import { styles } from './error-styles';

export default ({ uuid }) => (
  <Grid container sx={styles.container}>
    <Grid item xs={12}>
      <Typography variant="h4" sx={styles.header}>
        An unexpected error has occurred
      </Typography>
      <Typography variant="body" component="p" sx={styles.instructions}>
        Please contact Trellis Rx Administrators for support.
      </Typography>
      <Typography variant="body" component="p" sx={styles.hint}>
        Provide this error ID if requested:
      </Typography>
      <CopyToClipboard text={`Error ID: ${uuid}`}>
        <Button variant="contained" endIcon={<FileCopy />} color="primary" sx={styles.copyButton}>
          {uuid}
        </Button>
      </CopyToClipboard>
      <Typography variant="body" component="p" sx={styles.hint}>
        Please reload the page to continue using the system
      </Typography>
      <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
        Reload
      </Button>
    </Grid>
  </Grid>
);

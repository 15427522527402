import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

export const styles = (theme: Theme) => {
  const themeAny = theme as any;
  const colorPalette = themeAny.palette.primary as any;
  const fontTheme = themeAny.font as any;

  return createStyles({
    outerContainer: {
      backgroundColor: colorPalette.grey14,
      padding: 20,
    },
    noWeatherLabel: {
      color: colorPalette.grey12,
      fontSize: fontTheme.smallFontSize,
    },
  });
};

import { renderDropdown } from 'components/form/field/redux-field';
import { ITaskTypeDescription } from './ITaskTypeDescription';
import { IDropdownDescription } from './ITaskFieldDescription';
import { renderDatePicker } from '../../components/form/datepicker/datetime-picker';
import { validateDateTime, required } from '../../components/form/validation/validation';

type InterventionField =
  | 'status'
  | 'service_group_id'
  | 'followup_dt'
  | 'last_checked_dt'
  | 'category'
  | 'type'
  | 'severity'
  | 'outcome'
  | 'additional_reason';

type InterventionStatus = 'Required' | 'In Progress' | 'On Hold' | 'Canceled';

export const InterventionDescription: ITaskTypeDescription<InterventionField, InterventionStatus> =
  {
    taskType: 'Intervention',

    taskAbbreviation: 'Intervention',

    fieldMap: {
      service_group_id: {
        type: 'number',
        component: renderDatePicker,
        label: 'Service Group',
        validators: [required],
      },
      followup_dt: {
        type: 'datetime',
        component: renderDatePicker,
        label: 'Follow Up Date',
        validators: [validateDateTime, required],
      },
      last_checked_dt: {
        type: 'datetime',
        component: renderDatePicker,
        label: 'Last Checked Date',
        validators: [validateDateTime],
      },
      status: {
        type: 'dropdown',
        component: renderDropdown,
        label: 'Status',
        options: [
          { label: 'Patient', value: 'Patient' },
          { label: 'Prescriber', value: 'Prescriber' },
          { label: 'Other', value: 'Other' },
        ],
        validators: [required],
      } as IDropdownDescription,
      category: {
        type: 'dropdown',
        component: renderDropdown,
        label: 'Category',
        options: [
          { label: 'Patient', value: 'Patient' },
          { label: 'Prescriber', value: 'Prescriber' },
          { label: 'Other', value: 'Other' },
        ],
        validators: [required],
      } as IDropdownDescription,

      type: {
        type: 'dropdown',
        component: renderDropdown,
        label: 'Type',
        options: [
          { label: 'Adherence', value: 'Adherence' },
          { label: 'Care Plan', value: 'Care Plan' },
          { label: 'DDI', value: 'DDI' },
          { label: 'ADR', value: 'ADR' },
          { label: 'Regimen', value: 'Regimen' },
          { label: 'Referral of service', value: 'Referral of service' },
          { label: 'Lab', value: 'Lab' },
        ],
        validators: [required],
      } as IDropdownDescription,
      severity: {
        type: 'dropdown',
        component: renderDropdown,
        label: 'Severity',
        options: [
          { label: 'Low', value: 'Low' },
          { label: 'Medium', value: 'Medium' },
          { label: 'High', value: 'High' },
        ],
        validators: [required],
      } as IDropdownDescription,
      outcome: {
        type: 'dropdown',
        component: renderDropdown,
        label: 'Outcome',
        options: [
          { label: 'Ship', value: 'Ship' },
          { label: 'Pick up', value: 'Pick up' },
        ],
        validators: [required],
      } as IDropdownDescription,
      additional_reason: {
        type: 'dropdown',
        component: renderDropdown,
        label: 'Additional Reason',
        options: [
          { label: 'Ship', value: 'Ship' },
          { label: 'Pick up', value: 'Pick up' },
        ],
        validators: [required],
      } as IDropdownDescription,
    },

    statusMap: {
      'Required': 8001,
      'In Progress': 8002,
      'On Hold': 8004,
      'Canceled': 8005,
    },

    defaultStatus: 'Required',

    fieldsByStatus: {
      8001: ['status', 'service_group_id', 'followup_dt', 'last_checked_dt'],
      8002: [
        'status',
        'service_group_id',
        'followup_dt',
        'last_checked_dt',
        'category',
        'type',
        'severity',
      ],
      8003: ['status', 'service_group_id', 'followup_dt', 'last_checked_dt'],
      8004: ['status', 'service_group_id', 'followup_dt', 'last_checked_dt', 'outcome'],
      8005: ['status', 'service_group_id', 'followup_dt', 'last_checked_dt', 'additional_reason'],
    },
  };

import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from '../../../utils/theme-util';

const styles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    selected: {
      fontWeight: 'bold',
    },
    readOnlyField: {
      width: '75%',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    dialogTitle: {
      position: 'relative',
      minWidth: 800,
    },
    questionnaireModal: {
      top: theme.spacing(2),
    },
    footer: {
      padding: theme.spacing(2, 4),
    },
    footerRight: {
      textAlign: 'right',
      display: 'flex',
      flexDirection: 'column',
    },
    savingFlag: {
      flex: 1,
    },
    submitSuccessFlag: {
      color: palette.primary.success,
      fontWeight: 'bold',
    },
    submitIncompleteFlag: {
      color: palette.primary.lightGrey,
      fontWeight: 'bold',
    },
    multipleScore: {
      fontWeight: 'bold',
    },
  });
};

export { styles };

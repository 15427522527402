import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { expandTheme } from 'utils/theme-util';

export const styles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    adherenceValueGreen: {
      color: palette.primary.cuttySark,
    },
    adherenceValueRed: {
      color: palette.primary.lightRed,
    },
    adherenceValueGrey: {
      color: palette.primary.steelGrey,
    },
    adherenceTable: {
      'borderCollapse': 'collapse',
      'color': palette.primary.steelGrey,
      'width': '100%',
      '& th': {
        border: `1px solid ${palette.primary.timberWolf}`,
        width: '7%',
      },
      '& th:first-of-type': {
        padding: '0 0 0 5px',
        width: '44%',
        textAlign: 'left',
      },
      '& td:first-of-type': {
        padding: '0 0 0 5px',
        textAlign: 'left',
        fontSize: theme.spacing(1.625),
      },
      '& td': {
        border: `1px solid ${palette.primary.timberWolf}`,
        height: theme.spacing(3.5),
        fontSize: theme.spacing(1.75),
        fontWeight: 'bold',
        textAlign: 'center',
      },
    },
    adherenceTableHeader: {
      padding: 0,
      height: theme.spacing(3.5),
      fontSize: theme.spacing(1.375),
      backgroundColor: palette.primary.pearlGrey,
    },
    adherenceDataContainer: {
      overflowY: 'auto',
      maxHeight: theme.spacing(19.125),
      marginBottom: theme.spacing(0.625),
    },
  });
};

type Styles = WithStyles<typeof styles>;

const useStyles = makeStyles(styles);

export const useTypedStyles = (): Styles => ({
  classes: useStyles(),
});

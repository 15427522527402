import {
  Grid,
  DialogContent,
  Dialog,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CloseIcon from '@mui/icons-material/Close';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import debounce from 'debounce-promise';
import { renderTextField, renderPhoneTextField } from 'components/form/field/redux-field';
import { fetchSearch } from 'services/utils/therapy-service';
import { buildHandleEnterKeyPress } from 'services/utils/accessibility';
import { styles } from './therapy-detail.styles';

class NpiLookup extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.debouncedSubmit = debounce(() => {
      this.triggerSubmit();
    }, 1000).bind(this);
    this.state = {
      loading: false,
      results: null,
    };
  }

  componentDidMount() {
    this.debouncedSubmit();
  }

  handleSubmit(values) {
    if (
      (values.organization && values.organization.length >= 2) ||
      (values.npi && Number(values.npi) && values.npi.length === 10) ||
      !!values.phone_number ||
      !!values.fax_number
    ) {
      this.setState({ loading: true }, () => {
        fetchSearch(values).then(result => {
          const { loading } = this.state;
          if (loading) {
            this.setState({
              results: result && result.data ? result.data.result : [],
              loading: false,
            });
          }
        });
      });
    } else {
      this.setState({ results: null });
    }
  }

  handleSelect(item) {
    const { reset, handleChange } = this.props;
    handleChange(item);
    reset();
    this.handleCancel();
  }

  handleCancel() {
    const { reset, handleClose } = this.props;
    reset();
    this.setState({ results: null, loading: false });
    handleClose();
  }

  triggerSubmit() {
    const { handleSubmit } = this.props;
    const submitter = handleSubmit(this.handleSubmit);
    submitter();
  }

  renderForm() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit(this.handleSubmit)} autoComplete="off">
        <DialogContent>
          <Grid container>
            <Grid item xs={6}>
              <Field
                name="taxonomy"
                label="Taxonomy Description"
                component={renderTextField}
                onChange={() => {
                  this.debouncedSubmit();
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="organization"
                label="Organization Name"
                component={renderTextField}
                onChange={() => {
                  this.debouncedSubmit();
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="npi"
                label="NPI"
                component={renderTextField}
                onChange={() => {
                  this.debouncedSubmit();
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="city"
                label="City"
                component={renderTextField}
                onChange={() => {
                  this.debouncedSubmit();
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="state"
                label="State"
                component={renderTextField}
                onChange={() => {
                  this.debouncedSubmit();
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="postal"
                label="Postal Code"
                component={renderTextField}
                onChange={() => {
                  this.debouncedSubmit();
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="phone_number"
                label="Phone Number"
                component={renderPhoneTextField}
                onChange={() => {
                  this.debouncedSubmit();
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="fax_number"
                label="Fax Number"
                component={renderPhoneTextField}
                onChange={() => {
                  this.debouncedSubmit();
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    );
  }

  renderList() {
    const { classes } = this.props;
    const { results } = this.state;
    return results.length ? (
      <Table className={classes.minigridTable}>
        <TableHead classes={{ root: classes.minigridTableHeader }}>
          <TableRow>
            <TableCell className={classes.minigridCols}>Name</TableCell>
            <TableCell className={classes.minigridCols}>Address</TableCell>
            <TableCell className={classes.minigridCols}>NPI</TableCell>
            <TableCell className={classes.minigridCols}>Phone</TableCell>
            <TableCell className={classes.minigridCols}>Fax</TableCell>
            <TableCell className={classes.minigridCols}>Primary Taxonomy</TableCell>
            <TableCell className={classes.minigridCols}>Is Internal</TableCell>
            <TableCell className={classes.minigridCols}>Doing Business As</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map(item => (
            <TableRow
              className={classes.minigridRow}
              key={item.npi}
              onMouseDown={() => this.handleSelect(item)}
              onKeyPress={buildHandleEnterKeyPress(() => this.handleSelect(item))}
              tabIndex="0"
              role="button"
            >
              <TableCell className={classes.cell}>{item.name}</TableCell>
              <TableCell className={classes.cell}>{item.address}</TableCell>
              <TableCell className={classes.cell}>{item.npi}</TableCell>
              <TableCell className={classes.cell}>{item.phone ? item.phone : ''}</TableCell>
              <TableCell className={classes.cell}>{item.fax ? item.fax : ''}</TableCell>
              <TableCell className={classes.cell}>{item.primary_taxonomy}</TableCell>
              <TableCell className={classes.cell}>{item.is_internal}</TableCell>
              <TableCell className={classes.cell}>{item.doing_business_as}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    ) : (
      <p>
        <i>No results found</i>
      </p>
    );
  }

  render() {
    const { open, title, classes } = this.props;
    if (!open) {
      return null;
    }
    const { results, loading } = this.state;
    return (
      <Grid container spacing={7}>
        <Grid item xs={12}>
          <Dialog open onClose={this.handleCancel} maxWidth="lg">
            <DialogTitle id="alert-dialog-title">
              {`Search ${title || 'NPI registry'}`}
              <IconButton
                aria-label="Close"
                className={classes.npiDialogClose}
                onClick={this.handleCancel}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            {this.renderForm()}
            <DialogContent>
              {loading ? (
                <p>
                  <i>Loading...</i>
                </p>
              ) : (
                results && this.renderList()
              )}
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state, props) {
  const formName = 'NPI_LOOKUP';
  const organizationName = formValueSelector(formName)(state, 'organization');
  return {
    form: formName,
    initialValues: props.values,
    organizationName,
  };
}

export default compose(withStyles(styles), connect(mapStateToProps))(reduxForm({})(NpiLookup));

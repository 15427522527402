import React, { useState, useEffect } from 'react';
import { Button, Modal, Grid } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { buildQaId } from 'utils/build-qa-id';
import { getModalStyle } from 'services/utils/styles-service';
import { useDebounce } from 'use-debounce';
import { SectionTitleStyled } from '../components/section-title';
import { RulesEngineManagerStyles } from './index.styles';
import { UserPermissions } from '../../../interfaces/user/Permissions';
import { UserUtils } from '../../../utils/user-utils';
import { RulesTable } from './table/rules-table';

import { AddRuleForm } from './modal/add-edit-rule-modal';
import { ReactSelect } from '../../../components/form/field/react-select';
import { NumberOption } from '../../../interfaces/forms/types';
import { SearchField } from '../components/search-field';
import { EventTypeOptions, RuleStatusOptions } from '../../../constants/rules-engine';

type IProps = WithStyles<typeof RulesEngineManagerStyles>;

const searchFieldPlaceholder = 'Search by Rule Name';
const initialSearchTerm = '';
const initialValue = { label: 'All', value: -1 };
const [activeStatusOption] = RuleStatusOptions;
const debounceTimeMs = 500;
const RulesEngineManager = (props: IProps): JSX.Element => {
  const { classes } = props;

  const qaIdBuilder = buildQaId('rules-engine-manager');
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [selectedEventTypes, setSelectedEventTypes] = useState<NumberOption[]>([]);
  const [filters, setFilters] = useState({});
  const [selectedStatus, setSelectedStatus] = useState<NumberOption>(activeStatusOption);
  const [debouncedSearchTerm] = useDebounce(searchTerm, debounceTimeMs);

  useEffect(() => {
    setFilters({
      status: selectedStatus.value,
      event_type: selectedEventTypes
        .filter(opt => opt.value !== initialValue.value)
        .map(opt => opt.value),
    });
  }, [selectedStatus, selectedEventTypes]);

  const [modalOpen, setModalOpen] = useState(false);
  const [nextTableReloadDate, setNextTableReloadDate] = useState<Date | undefined>();
  const [triggerTableRefresh, setTriggerTableRefresh] = useState<boolean>(false);

  useEffect(() => {
    if (triggerTableRefresh) {
      setNextTableReloadDate(new Date());
      setTriggerTableRefresh(false);
    }
  }, [triggerTableRefresh]);

  return (
    <>
      {/* Section Title */}
      <SectionTitleStyled
        title="Workflow Manager"
        rightSideItems={[
          {
            element: (
              <Button
                variant="contained"
                className={classes.addButton}
                disabled={
                  !UserUtils.userIsPermitted(UserPermissions.ApplicationManagerRulesEngineEdit)
                }
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                Add Rule
              </Button>
            ),
            key: 'add-engine-rule-button',
          },
        ]}
      />
      {/* /Section Title */}

      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        data-qa-id={qaIdBuilder('modal-add-rule')}
      >
        <Grid spacing={2} container style={getModalStyle()} className={classes.modalContentWrapper}>
          <Grid item xs={12}>
            <AddRuleForm
              onCloseModal={() => setModalOpen(false)}
              onSave={() => {
                setModalOpen(false);
                setTriggerTableRefresh(true);
              }}
            />
          </Grid>
        </Grid>
      </Modal>

      <Grid container direction="column" spacing={1}>
        {/* Search Container */}
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {/* Search filters */}
            <Grid item xs={3}>
              <ReactSelect
                label="Rule Apply To"
                name="event_type_filter"
                isMulti
                value={
                  selectedEventTypes.length === 0
                    ? [initialValue]
                    : selectedEventTypes.filter(x => x.value !== initialValue.value)
                }
                fields={[...EventTypeOptions, initialValue]}
                handleOnChange={(value: NumberOption[] | undefined) => {
                  setSelectedEventTypes(value || []);
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <ReactSelect
                label="Status"
                name="status_filter"
                isMulti={false}
                value={selectedStatus}
                fields={RuleStatusOptions}
                handleOnChange={(value: NumberOption) => {
                  setSelectedStatus(value);
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={4} marginTop={3.75}>
              <SearchField
                width={48}
                value={searchTerm}
                onChange={setSearchTerm}
                initialValue={initialSearchTerm}
                placeholder={searchFieldPlaceholder}
              />
            </Grid>
            {/* /Search filters */}
          </Grid>
        </Grid>
        {/* /Search Container */}

        {/* Rules Table */}
        <Grid item>
          <RulesTable
            filters={filters}
            searchTerm={debouncedSearchTerm}
            setCount={(count: number) => {}}
            triggerReload={nextTableReloadDate}
          />
        </Grid>
        {/* /Rules Table */}
      </Grid>
    </>
  );
};

export default withStyles(RulesEngineManagerStyles)(RulesEngineManager);

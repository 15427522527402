import cx from 'classnames';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { GridSize } from '@mui/material/Grid';
import { Accordion, AccordionSummary, Grid, Typography, AccordionDetails } from '@mui/material';

import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { styles } from './expanding-header-styles';

interface IFieldLabel {
  label?: string;
  value?: string;
  field?: JSX.Element;
  key?: string;
  gridSize?: GridSize;
}

interface IExpandingHeaderProps extends WithStyles<typeof styles> {
  label: string;
  text?: string;
  expandable: boolean;
  defaultExpanded?: boolean;
  fieldsAndLabels?: IFieldLabel[];
}

const ExpandingHeader: React.FC<IExpandingHeaderProps> = (
  props: IExpandingHeaderProps,
): JSX.Element => {
  const { classes } = props;

  return (
    <Accordion
      className={classes.expansionPanel}
      elevation={0}
      defaultExpanded={props.defaultExpanded}
    >
      <AccordionSummary expandIcon={props.expandable && <ExpandMoreIcon />}>
        <div>
          <Typography
            className={classes.headerLabel}
            variant="caption"
            data-qa-id="expanding-header-label"
          >
            {props.label}
          </Typography>
          <Typography
            className={classes.headerText}
            variant="body2"
            data-qa-id="expanding-header-text"
          >
            {props.text}
          </Typography>
        </div>
      </AccordionSummary>
      {props.expandable && props.fieldsAndLabels && (
        <AccordionDetails>
          <Grid container spacing={3}>
            {props.fieldsAndLabels.map((fieldLabel, index) => {
              const className = cx({
                [classes.detailGridItem]: props.fieldsAndLabels && props.fieldsAndLabels.length > 4,
              });

              if (!fieldLabel.field) {
                return (
                  <Grid
                    item
                    xs={fieldLabel.gridSize || 3}
                    className={className}
                    key={`${fieldLabel.label}-${fieldLabel.value}`}
                  >
                    <div data-qa-id="addl-info">
                      <Typography className={classes.detailLabel}>{fieldLabel.label}</Typography>
                      <Typography className={classes.detailText}>
                        {fieldLabel.value ? fieldLabel.value : '-'}
                      </Typography>
                    </div>
                  </Grid>
                );
              }
                return (
                  <Grid
                    item
                    xs={fieldLabel.gridSize || 4}
                    className={className}
                    key={`${fieldLabel.key}`}
                  >
                    {fieldLabel.field}
                  </Grid>
                );
            })}
          </Grid>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default withStyles(styles, { withTheme: true })(ExpandingHeader);

import { Grid, Switch } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { convertToArborDate } from 'models/time/arbor-date';
import { togglePinnedNote } from 'actions/action-notes';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { PIN_NOTE_FORM } from 'constants/index';
import { noteTagTypes } from 'constants/lists';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { renderSwitch } from 'components/form/field/redux-field';
import FieldLabel from 'components/form/field/field-label';
import RadioButton from 'components/form/radio/radio-button';
import { handlePinned, getPinnedDefaultForForm } from 'services/utils/note-service';
import RadioGroup from 'components/form/radio/radio-group';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import Validation, { required, validateDate } from 'components/form/validation/validation';
import { styles } from './note-styles';

class NotePinnedForm extends Component {
  constructor(props) {
    super(props);
    const { note } = props;
    this.state = {
      timedCheckbox: !!note.pinned_expiration_dt,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTimedCheckbox = this.handleTimedCheckbox.bind(this);
  }

  handleSubmit(values) {
    const {
      reset,
      togglePinnedNote, // eslint-disable-line
      note,
      cancel,
      isPinnedVal,
      selectedPatientId,
      notes,
    } = this.props; // eslint-disable-line
    const { timedCheckbox } = this.state;

    const payload = {
      ...values,
      note_id: note.id,
      is_pinned: values.is_pinned ? 1 : 0,
      was_already_pinned: note.is_pinned ? 1 : 0,
      therapy_id: note.therapy_id,
      resource_id: notes.tag_resource_id,
      tag_type_id: notes.tag_type_id,
      patient_id: selectedPatientId,
      level: handlePinned(values.level, values.is_pinned),
      pinned_expiration_dt:
        timedCheckbox && isPinnedVal
          ? convertToArborDate(values.pinned_expiration_dt).getUtcDatetime(false)
          : null,
      is_all_of_type: values.is_pinned && values.level === 'All' ? 1 : 0,
    };
    togglePinnedNote(payload);
    cancel();
    reset();
  }

  handleTimedCheckbox() {
    this.setState(prevState => ({
      timedCheckbox: !prevState.timedCheckbox,
    }));
  }

  render() {
    const {
      handleSubmit,
      classes,
      submitting,
      isPinnedVal,
      cancel,
      // patientName,
      note,
    } = this.props;
    const { timedCheckbox } = this.state;

    const renderPinnedForTherapyTask = ({ input, meta: { touched, error, warning } }) => (
      <Grid container>
        <RadioGroup horizontal name="level" id="level" {...input} className={classes.radioGroup}>
          {/* These are placeholder until we determine how we are setting this tag thing */}
          <RadioButton value="This Task">This Task</RadioButton>
          <RadioButton value="All">
            {`All ${
              noteTagTypes.find(
                type => type.value === note.tags.find(tag => tag.is_owner === 1).tag_type_id,
              ).label
            }'s`}
          </RadioButton>
          <RadioButton value="Patient">Patient</RadioButton>
          <RadioButton value="Therapy">Therapy</RadioButton>
        </RadioGroup>
        <Validation touched={touched} error={error} warning={warning} />
      </Grid>
    );

    const renderPinnedForTherapy = ({ input, meta: { touched, error, warning } }) => (
      <Grid container>
        <RadioGroup horizontal name="level" id="level" {...input} className={classes.radioGroup}>
          {/* These are placeholder until we determine how we are setting this tag thing */}
          <RadioButton value="Therapy">Therapy</RadioButton>
          <RadioButton value="Patient">Patient</RadioButton>
        </RadioGroup>
        <Validation touched={touched} error={error} warning={warning} />
      </Grid>
    );

    const renderPinnedForPatientTask = ({ input, meta: { touched, error, warning } }) => (
      <Grid container>
        <RadioGroup horizontal name="level" id="level" {...input} className={classes.radioGroup}>
          {/* These are placeholder until we determine how we are setting this tag thing */}
          <RadioButton value="This Task">This Task</RadioButton>
          <RadioButton value="All">
            {`All ${
              noteTagTypes.find(
                type => type.value === note.tags.find(tag => tag.is_owner === 1).tag_type_id,
              ).label
            }'s`}
          </RadioButton>
          <RadioButton value="Patient">Patient</RadioButton>
        </RadioGroup>
        <Validation touched={touched} error={error} warning={warning} />
      </Grid>
    );
    return (
      <Grid item xs={12} data-qa-id="pin-level">
        <form onSubmit={handleSubmit(this.handleSubmit)} autoComplete="off">
          <Grid container>
            <Grid item xs={2}>
              <Field name="is_pinned" label="Pinned" component={renderSwitch} />
            </Grid>
            {isPinnedVal && (
              <Grid item xs={9} className={classes.noteFieldContainer}>
                {note.tags[0].tag_type_id > 1 && <FieldLabel fieldName="Note Pinned For *" />}
                {note.therapy_id && note.tags[0].tag_type_id > 10 && (
                  <Field
                    name="level"
                    component={renderPinnedForTherapyTask}
                    validate={[required]}
                  />
                )}
                {note.therapy_id && note.tags[0].tag_type_id === 2 && (
                  <Field name="level" component={renderPinnedForTherapy} validate={[required]} />
                )}
                {!note.therapy_id && note.tags[0].tag_type_id > 10 && (
                  <Field
                    name="level"
                    component={renderPinnedForPatientTask}
                    validate={[required]}
                  />
                )}
              </Grid>
            )}
          </Grid>
          <Grid container>
            {isPinnedVal && (
              <Grid item xs={2}>
                <FieldLabel fieldName="Timed" />
                <Switch
                  checked={timedCheckbox}
                  color="primary"
                  onChange={this.handleTimedCheckbox}
                  value="timedCheckbox"
                  label="Secondary"
                />
              </Grid>
            )}
            {timedCheckbox && isPinnedVal && (
              <Grid item xs={9}>
                <Field
                  name="pinned_expiration_dt"
                  label="Expiration Date"
                  component={renderDatePicker}
                  validate={[validateDate, required]}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} className={classes.confirmationPanelContainer}>
            <ConfirmationPanel
              cancelButtonName="archive_note_reason_cancel_button"
              submitButtonName="archive_note_reason_button"
              handleCancel={cancel}
              disableSubmit={submitting}
            />
          </Grid>
        </form>
      </Grid>
    );
  }
}

function handleFormName(note) {
  if (!note) {
    return null;
  }
  const name = `${PIN_NOTE_FORM}_${note.id}`;
  return name;
}

function mapStateToProps(state, props) {
  const { note } = props;
  const { patient, notes, tasks, selectedPatientId } = state;
  const name = handleFormName(note);
  const selector = formValueSelector(name);
  const isPinnedVal = selector(state, 'is_pinned');
  return {
    isPinnedVal,
    patientName: `${patient.first_name} ${patient.last_name}`,
    tasks: tasks.data,
    selectedPatientId,
    notes,
    form: name,
    initialValues: {
      is_pinned: true,
      level:
        note && note.tags && note.tags.length > 0 ? getPinnedDefaultForForm(note) : 'This Task',
      pinned_expiration_dt: convertToArborDate(note.pinned_expiration_dt, true).getCustomerDate(
        true,
        'MM/DD/YYYY',
      ),
    },
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    togglePinnedNote,
  }),
)(reduxForm({})(NotePinnedForm));

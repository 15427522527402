import { IndexType } from 'interfaces/IndexType';
import {
  IEfficacyProtocolCriteria,
  ISafetyProtocolCriteria,
  IClinicalProtocolsResponse,
} from './types';

export type IResultChildren = {
  clinical_data_id?: IndexType;
  safetyProtocols: ISafetyProtocolCriteria[];
  efficacyProtocols: IEfficacyProtocolCriteria[];
};
export type IResult = {
  id?: IndexType;
  clinical_data_id?: IndexType;
  safetyProtocols: ISafetyProtocolCriteria[];
  efficacyProtocols: IEfficacyProtocolCriteria[];
  clinicalDataId: number;
  clinical_item_name: string;
  efficacyProtocolsId: string;
  safetyProtocolsId: string;
  clinical_item_category_name: string;
  children: IResultChildren[];
};

export type IResultTable = IResult & {
  safetyProtocolsId: string | null;
  efficacyProtocolsId: string | null;
};
export interface IResponse {
  totalCount: number;
  items: IResult[];
  results: IResult[];
  page: number;
  pageSize: number;
  totalItems: number;
}

export interface IProtocolData extends IResponse {
  fullData: IResult[];
}

export const mapProtocolsToTableResult = (response: IClinicalProtocolsResponse): IResultTable => {
  const result = {
    ...response,
    safetyProtocolsId:
      response.safetyProtocols && response.safetyProtocols.length > 0
        ? response.safetyProtocols.length
        : '-',
    efficacyProtocolsId:
      response.efficacyProtocols && response.efficacyProtocols.length > 0
        ? response.efficacyProtocols.length
        : '-',
    children: [
      {
        safetyProtocols: response.safetyProtocols || [],
        efficacyProtocols: response.efficacyProtocols || [],
      } as IResultChildren,
    ],
  } as IResultTable;
  return result;
};

import Moment, { Moment as moment } from 'moment';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DownloadFileUtils } from 'utils/download-file-utils';
import { getModalStyle } from 'services/utils/styles-service';
import { nameOfFactory } from 'utils/types-util';
import { notifyError } from 'actions/action-notifications';
import { renderDatePicker as RenderDatePicker } from 'components/form/datepicker/datetime-picker';
import { TimeoutUtils } from 'utils/timeout-utils';
import { useDispatch } from 'react-redux';
import { Modal, Grid, Button, Typography, CircularProgress, Divider } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { buildQaId } from 'utils/build-qa-id';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { dateFormat } from 'models/time/arbor-date';
import { styles } from './report-modal-styles';
import { logger } from '../../../../winston-logger';

interface IHistoryFormFields {
  startDate: moment;
  endDate: moment;
}

interface IReportModalProps extends WithStyles<typeof styles> {
  open: boolean;
  close: () => void;
  customerId: number;
}

const getFieldName = nameOfFactory<IHistoryFormFields>();
const requiredMsg = 'Required';
const qaIdBuilder = buildQaId('ctd-report-modal');

const ReportModal: React.FC<IReportModalProps> = (props: IReportModalProps): JSX.Element => {
  // #region component state
  const dispatch = useDispatch();
  const historyForm = useForm<any>();
  const [submittingAuditReport, setSubmittingAuditReport] = React.useState<boolean>(false);
  const [submittingAllAuditReport, setSubmittingAllAuditReport] = React.useState<boolean>(false);
  const customers = useTypedSelector(state => state.filters.customers);
  const allCustomers = customers.map(customer => ({
    label: customer.name,
    value: customer.id,
  }));
  const currentCustomer = allCustomers.find(x => x.value === props.customerId);
  // #endregion

  // #region helpers
  const downloadAuditReport = async (formValues: IHistoryFormFields) => {
    try {
      setSubmittingAuditReport(true);

      const waitPromise = TimeoutUtils.wait(500);
      if (currentCustomer) {
        const request = ApplicationManagerClient.ctdReport(
          formValues.startDate.format(dateFormat),
          formValues.endDate.format(dateFormat),
          currentCustomer.label,
          currentCustomer.value,
        );
        const [, response] = await Promise.all([waitPromise, request]);
        DownloadFileUtils.downloadFile(response);
      }
    } catch (error) {
      logger.error(error);
      dispatch(notifyError('Could not generate report'));
    } finally {
      setSubmittingAuditReport(false);
    }
  };

  const downloadAllAuditReport = async (formValues: IHistoryFormFields) => {
    try {
      setSubmittingAllAuditReport(true);
      for (const customer of allCustomers) {
        const waitPromise = TimeoutUtils.wait(500);
        const request = ApplicationManagerClient.ctdReport(
          formValues.startDate.format(dateFormat),
          formValues.endDate.format(dateFormat),
          customer.label,
          customer.value,
        );
        const [, response] = await Promise.all([waitPromise, request]);
        DownloadFileUtils.downloadFile(response);
      }
    } catch (error) {
      logger.error(error);
      dispatch(notifyError('Could not generate all reports'));
    } finally {
      setSubmittingAllAuditReport(false);
    }
  };
  // #endregion

  // #region renders
  const renderHistoryForm = (): JSX.Element => {
    return (
      <>
        <Grid container className={props.classes.labelContainer}>
          <Grid item xs={12}>
            <Typography className={props.classes.headerText}>Generate Reports</Typography>
          </Grid>
        </Grid>
        <Divider className={props.classes.dividerMargin} />
        <Grid container className={props.classes.subtitle}>
          <Typography className={props.classes.subtitleText}>Audit Report</Typography>
        </Grid>
        <Grid container justifyContent="space-between">
          {/* Start Date */}
          <Grid item xs={3} className={props.classes.controller}>
            <Controller
              defaultValue={Moment.utc().add(-1, 'months').startOf('day')}
              control={historyForm.control}
              name={getFieldName('startDate')}
              rules={{ required: true }}
              render={(ctrlProps: any) => {
                return (
                  <RenderDatePicker
                    label="Start Date *"
                    data-qa-id={qaIdBuilder(`audit-${getFieldName('startDate')}`)}
                    meta={{ touched: historyForm.formState.errors.startDate, error: requiredMsg }}
                    input={{
                      value: ctrlProps?.field?.value,
                      onChange: ctrlProps?.field?.onChange,
                      onBlur: ctrlProps?.field?.onBlur,
                    }}
                  />
                );
              }}
            />
          </Grid>

          {/* End Date */}
          <Grid item xs={3} className={props.classes.controller}>
            <Controller
              defaultValue={Moment.utc().startOf('day')}
              control={historyForm.control}
              name={getFieldName('endDate')}
              rules={{ required: true }}
              render={(ctrlProps: any) => {
                return (
                  <RenderDatePicker
                    label="End Date *"
                    data-qa-id={qaIdBuilder(`audit-${getFieldName('endDate')}`)}
                    meta={{ touched: historyForm.formState.errors.endDate, error: requiredMsg }}
                    input={{
                      value: ctrlProps?.field?.value,
                      onChange: ctrlProps?.field?.onChange,
                      onBlur: ctrlProps?.field?.onBlur,
                    }}
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={5} className={props.classes.centerButton}>
            <Button
              variant="contained"
              color="primary"
              onClick={historyForm.handleSubmit(downloadAuditReport)}
              className={props.classes.button}
              disabled={submittingAuditReport || submittingAllAuditReport}
              data-qa-id={qaIdBuilder('audit-generate')}
            >
              {submittingAuditReport ? (
                <CircularProgress
                  color="secondary"
                  size={20}
                  className={props.classes.submittingIndicator}
                />
              ) : null}
              Generate
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={historyForm.handleSubmit(downloadAllAuditReport)}
              className={props.classes.button}
              disabled={submittingAuditReport || submittingAllAuditReport}
              data-qa-id={qaIdBuilder('audit-generate')}
            >
              {submittingAllAuditReport ? (
                <CircularProgress
                  color="secondary"
                  size={20}
                  className={props.classes.submittingIndicator}
                />
              ) : null}
              Generate For All Sites
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };
  // #endregion

  return (
    <Modal
      open={props.open}
      onBackdropClick={() => {
        props.close();
      }}
    >
      <div style={getModalStyle()} className={props.classes.modal}>
        <Grid container direction="column">
          {/* Ctd Report Form */}
          <Grid item xs={12}>
            {renderHistoryForm()}
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export const ReportModalStyled = withStyles(styles)(ReportModal);

import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { expandTheme } from 'utils/theme-util';

export const styles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    adherenceInterventionButtonContainer: {
      paddingLeft: theme.spacing(1.25),
    },
    adherenceSectionButtonsContainer: {
      paddingLeft: theme.spacing(1.875),
    },
    adherenceSectionContainer: {
      paddingLeft: theme.spacing(1.25),
    },
    adherenceHistoryLabel: {
      paddingLeft: theme.spacing(0.375),
      textTransform: 'none',
      fontSize: theme.spacing(1.625),
      color: palette.primary.steelGrey,
    },
    adherenceSectionLoading: {
      display: 'flex',
      height: theme.spacing(15),
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
  });
};

type Styles = WithStyles<typeof styles>;

const useStyles = makeStyles(styles);

export const useTypedStyles = (): Styles => ({
  classes: useStyles(),
});

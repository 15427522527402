// eslint-disable-next-line max-len
import DrugUtilizationReviewContainerWrapper from 'containers/tasks/drug-utilization-review/drug-utilization-review-container-wrapper';
import DocumentDisplay from 'components/document/document-display';
import NoteDisplay from 'components/note/note-display';
import NoteDocumentDisplayCombined from 'components/dynamic-form/custom-elements/note-document-display-combined';
import PatientClinical from 'containers/patient/clinical/patient-clinical';
import RenderDocumentUpload from './renders/render-document-upload';
import renderDropdown from './renders/render-dropdown';
import renderDropdownMultipleAsync from './renders/render-dropdown-async';
import renderCheckboxList from './renders/render-checkbox-list';
import renderTableSelect from './renders/render-table-select';
import renderTextAreaField from './renders/render-text-area-field';
import renderStaticDropdown from './renders/render-static-dropdown';
import renderStaticText from './renders/render-static-text';
import renderCustomRadio from './renders/render-radio';
import renderRadioList from './renders/render-radio-list';
import renderCustomCheckboxGroup from './renders/render-checkbox-group';
import renderCustomCheckbox from './renders/render-checkbox';
import renderCustomStatusSelector from './renders/render-status-selector';
import renderCustomStatusReasonSelector from './renders/render-status-reason-selector';
import renderText from './renders/render-text';
import renderTextStatic from './renders/render-text-static';
import renderPhoneTextField from './renders/render-phone-text-field';
import renderMoneyTextField from './renders/render-money-field';
import renderNumericField from './renders/render-numeric-field';
import renderCustomDate from './renders/render-date';
import renderCustomDateTime from './renders/render-datetime';
import renderCustomCalendar from './renders/render-calendar';
import renderCustomNote from './renders/render-note';
import renderCustomElement from './renders/render-custom-element';
import renderHidden from './renders/render-hidden';
import renderHiddenInput from './renders/render-input-hidden';
import renderReadOnly from './renders/render-readonly';
import renderCheckboxMultiSelect from './renders/render-checkbox-multi-select';
import renderCheckboxSingleSelect from './renders/render-checkbox-single-select';
import renderWeather from './renders/render-weather';
import renderLocation from './renders/render-location';
import renderDataCollectForm from './renders/render-data-collect-form';
import renderQuestionnaire from './renders/render-questionnaire';
import renderTitle from './renders/render-title';
import renderLabel from './renders/render-label';
import renderReference from './renders/render-reference';
import renderRange from './renders/render-range';
import renderTherapySelector from './renders/render-therapy-selector';
import renderQuestionnaireValue from './renders/render-questionnaire-value';
import renderPillsMultiple from './renders/render-pills-multiple';
import renderInterventionFormDC from './renders/render-intervention-form-dc';
import renderInterventionFormsEfficacySafety from './renders/render-intervention-forms-efficacy-safety';
import renderDaysSelector from './renders/render-days-selector';
import renderInterventionFormDUR from './renders/render-intervention-form-dur';
import renderInterventionFormDURStatus from './renders/render-intervention-form-dur-status';
import renderWorkAsGroup from './renders/render-work-as-group';
import renderTherapyAppropriate from './renders/render-therapy-appropriate';
import renderTherapyNonAppropriateList from './renders/render-therapy-non-appropriate-list';
import renderLengthOfStay from './renders/render-length-of-stay';
import renderAdherenceTable from './renders/render-adherence-table';
import renderRemovedTherapiesNotice from './renders/render-removed-therapies-notice';

export const getRenderer = field => {
  if (field.readOnly) {
    return renderReadOnly;
  }
  if (typeof field.component === 'function') {
    return field.component;
  }
  switch (field.component) {
    case 'dropdownStatic':
      return renderStaticDropdown;
    case 'staticText':
      return renderStaticText;
    case 'dropdown':
    case 'dropdownMultiple':
      return renderDropdown;
    case 'dropdownMultipleAsync':
      return renderDropdownMultipleAsync;
    case 'range':
      return renderRange;
    case 'radio':
      return renderCustomRadio;
    case 'radioList':
      return renderRadioList;
    case 'checkbox':
      return renderCustomCheckbox;
    case 'checkboxGroup':
      return renderCustomCheckboxGroup;
    case 'checkboxList':
      return renderCheckboxList;
    case 'tableSelect':
    case 'tableSelectMultiple':
      return renderTableSelect;
    case 'statusSelector':
      return renderCustomStatusSelector;
    case 'statusReasonSelector':
      return renderCustomStatusReasonSelector;
    case 'date':
      return renderCustomDate;
    case 'datetime':
      return renderCustomDateTime;
    case 'calendar':
      return renderCustomCalendar;
    case 'note':
      return renderCustomNote;
    case 'noteDisplay':
      return NoteDisplay;
    case 'number':
      return renderNumericField;
    case 'customElement':
      return renderCustomElement;
    case 'textarea':
      return renderTextAreaField;
    case 'textStatic':
      return renderTextStatic;
    case 'hidden':
      return renderHidden;
    case 'hiddenInput':
      return renderHiddenInput;
    case 'checkboxMultiSelect':
      return renderCheckboxMultiSelect;
    case 'checkboxSingleSelect':
      return renderCheckboxSingleSelect;
    case 'dataCollectForm':
      return renderDataCollectForm;
    case 'interventionFormDC':
      return renderInterventionFormDC;
    case 'interventionFormsEfficacySafety':
      return renderInterventionFormsEfficacySafety;
    case 'interventionFormDUR':
      return renderInterventionFormDUR;
    case 'interventionFormDURStatus':
      return renderInterventionFormDURStatus;
    case 'durContainer':
      return DrugUtilizationReviewContainerWrapper;
    case 'patientClinicalTable':
      return PatientClinical;
    case 'questionnaire':
      return renderQuestionnaire;
    case 'daysSelector':
      return renderDaysSelector;
    case 'title':
      return renderTitle;
    case 'label':
      return renderLabel;
    case 'phoneTextField':
      return renderPhoneTextField;
    case 'money':
      return renderMoneyTextField;
    case 'reference':
      return renderReference;
    case 'therapySelector':
      return renderTherapySelector;
    case 'questionnaireValue':
      return renderQuestionnaireValue;
    case 'pillsMultiple':
      return renderPillsMultiple;
    case 'weather':
      return renderWeather;
    case 'location':
      return renderLocation;
    case 'documentDisplay':
      return DocumentDisplay;
    case 'selectFileForm':
      return RenderDocumentUpload;
    case 'noteDocumentDisplayCombined':
      return NoteDocumentDisplayCombined;
    case 'workAsGroup':
      return renderWorkAsGroup;
    case 'therapyAppropriate':
      return renderTherapyAppropriate;
    case 'therapyNonAppropriateList':
      return renderTherapyNonAppropriateList;
    case 'lengthOfStay':
      return renderLengthOfStay;
    case 'adherenceTable':
      return renderAdherenceTable;
    case 'removedTherapiesNotice':
      return renderRemovedTherapiesNotice;
    case 'empty':
      return () => null;
    default:
      return renderText;
  }
};

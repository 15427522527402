import React, { useEffect, useState } from 'react';

export function useVisibilityChecker(
  elRef: React.MutableRefObject<HTMLDivElement | null>,
  startBool: boolean,
) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const el = elRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null, // Use the viewport as the root
        rootMargin: '0px', // No margin around the viewport
        threshold: 0.01, // Trigger when 1% is visible
      },
    );

    if (el) {
      if (startBool === false) {
        observer.unobserve(el);
      } else {
        observer.observe(el);
      }
    }

    return () => {
      if (el) {
        observer.unobserve(el);
      }
    };
  }, [elRef, startBool]);
  return isVisible;
}

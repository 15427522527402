import { compose } from 'recompose';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { styles } from './benefits-investigation-form-styles';
import { BENEFITS_INVESTIGATION_FORM } from '../../../../constants';

import {
  BenefitsInvestigationFormProps,
  mapStateToProps,
  BenefitsInvestigationForm,
} from './benefits-investigation-form';

export default compose<BenefitsInvestigationFormProps, Partial<BenefitsInvestigationFormProps>>(
  withStyles(styles),
  connect(mapStateToProps),
  reduxForm({
    form: BENEFITS_INVESTIGATION_FORM,
    enableReinitialize: false,
  }),
)(BenefitsInvestigationForm);

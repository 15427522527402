import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IPbmModalState {
  open: boolean;
}

export const NAME = 'pbmModal';

const initialState: IPbmModalState = {
  open: false,
};

const pbmModalSlice = createSlice({
  name: NAME,
  initialState: initialState,
  reducers: {
    showModal: state => {
      state.open = true;
    },
    hideModal: state => {
      state.open = false;
    },
    toggleModal: state => {
      state.open = !state.open;
    },
  },
});

export const pbmModalActions = pbmModalSlice.actions;
export const pbmModalReducer = pbmModalSlice.reducer;

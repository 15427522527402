import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { expandTheme } from 'utils/theme-util';

export const styles = (theme: Theme) => {
  const { palette, font } = expandTheme(theme);

  return createStyles({
    title: {
      fontSize: font.largeFontSize,
      fontWeight: 'bold',
      color: palette.primary.steelGrey,
    },
    errorLabel: {
      color: palette.primary.valenciaRed,
    },
    phiEntityTextLabel: {
      color: palette.primary.valenciaRed,
      fontSize: font.smallFontSize,
    },
    phiEntityTypeLabel: {
      fontSize: font.smallFontSize,
    },
  });
};

type Styles = WithStyles<typeof styles>;

const useStyles = makeStyles(styles);

export const useTypedStyles = (): Styles => ({
  classes: useStyles(),
});

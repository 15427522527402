import React from 'react';
import { Grid, Typography } from '@mui/material';
import RichTextEditor, { EditorValueFormat } from 'components/rich-text-editor';
import { convertToArborDate } from 'models/time/arbor-date';
import cx from 'classnames';
import { ICounselingAddendum } from '../../interfaces/ICounselingAddendum';
import { useTypedStyles } from './addendums-list.styles';

interface IAddendumsListProps {
  addendums?: ICounselingAddendum[];
}

export const AddendumsList: React.FC<IAddendumsListProps> = (
  props: IAddendumsListProps,
): JSX.Element | null => {
  const { classes } = useTypedStyles();

  if (props.addendums == null || props.addendums.length === 0) {
    return null;
  }

  return (
    <Grid item xs={12} className={classes.addendumsContainer}>
      <Grid container>
        <Grid item xs={12}>
          <Typography className={cx(classes.addendumsTitle, classes.padLeft)}>Addendums</Typography>
        </Grid>
        <Grid item xs={12}>
          {props.addendums.map((add, index) => {
            const formattedDate = convertToArborDate(add.created, true).getCustomerDatetime(true);
            return (
              <React.Fragment key={`addendum-${add.id || index}`}>
                <RichTextEditor
                  autoFocus={false}
                  initialValue={add.content}
                  initialValueFormat={EditorValueFormat.Html}
                  outputFormat={EditorValueFormat.Html}
                  placeHolder={add.content}
                  readOnly
                />
                <Typography
                  variant="caption"
                  className={cx(classes.padLeft, classes.addendumCaption)}
                >
                  Updated by {add.created_username} {formattedDate}
                </Typography>
              </React.Fragment>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

// eslint-disable-next-line no-shadow
export enum TaskPaApprovedModalActionType {
  open = 'TASK_PA_APPROVED_MODAL_OPEN',
  resetState = 'TASK_PA_APPROVED_MODAL_RESET_STATE',
}

export interface ITaskForm {
  lastApproved: object;
  newTask: object;
  event?: () => void;
}

export interface ITaskPaApprovedModalAction {
  type: TaskPaApprovedModalActionType;
  payload: ITaskForm;
}

export const taskPaApprovedModalActions = {
  open: (state: ITaskForm): ITaskPaApprovedModalAction => ({
    type: TaskPaApprovedModalActionType.open,
    payload: state,
  }),
  resetModal: () => {
    return {
      type: TaskPaApprovedModalActionType.resetState,
    };
  },
};

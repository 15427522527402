import React from 'react';
import { useIcdGroup } from 'containers/patient/clinical/use-cdm-group';
import { renderDropdown as RenderDropdown } from 'components/form/field/redux-field';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { IRenderProps } from '../interfaces/interfaces';

const RenderTherapyNonAppropriateList = ({
  providers,
  label,
  input,
  meta,
  disabled,
  labelSize,
  qaId,
}: IRenderProps) => {
  const { therapy_id: therapyId, id: taskId } = providers.task;
  const { therapies, patientId, tasks } = useTypedSelector(state => {
    return {
      therapies: state?.therapies?.data,
      patientId: state?.patient?.id,
      tasks: state?.tasks?.data,
    };
  });

  const icdGroup = useIcdGroup(patientId, therapyId, tasks[`DC${taskId}`].status_id);

  const workingAsGroup = providers.formValues.work_as_group;
  const therapyFields = workingAsGroup?.length > 0
    ? icdGroup.therapyIds.map((therapyId) => {
        const therapy = therapies[therapyId];
        return {
          value: therapyId,
          label: therapy.drug_name,
        };
      })
    : [];

  return (
    <RenderDropdown
      label={label}
      required
      meta={meta}
      input={input}
      disabled={disabled}
      labelSize={labelSize}
      fields={therapyFields}
      isMulti
      qaId={qaId}
    />
  );
};

export default RenderTherapyNonAppropriateList;

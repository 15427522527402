import React, { PropsWithChildren, ReactNode } from 'react';

interface IConditionalWrapperProps {
  condition: boolean;
  wrapper: (children: any) => any;
}

const ConditionalWrapper: React.FC<PropsWithChildren<IConditionalWrapperProps>> = (
  props: PropsWithChildren<IConditionalWrapperProps>,
): any => {
  if (!props.condition) {
    return props.children;
  }

  return props.wrapper(props.children);
};

export { ConditionalWrapper };

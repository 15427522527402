import HTTP from 'services/http';
import { AxiosPromise } from 'axios';
import { IAddProductRequestBody, IApiRelatedDrugResult, IApiSearchResult } from './types';

const searchLimit = 10;

export const getSearchRequest = (term: string): AxiosPromise<IApiSearchResult[]> => {
  const baseUrl = '/application-manager/gtd/active/add-product/search';
  const requestParams: Record<string, string> = {
    searchTerm: term,
    limit: searchLimit.toString(),
  };
  const queryParams = new URLSearchParams(requestParams).toString();
  return HTTP.get(`${baseUrl}?${queryParams}`);
};

export const getRelatedRequest = (gpi10: string): AxiosPromise<IApiRelatedDrugResult[]> => {
  const baseUrl = '/application-manager/gtd/active/add-product/related';
  return HTTP.get(`${baseUrl}/${gpi10}`);
};

export const addProductsRequest = (body: IAddProductRequestBody): AxiosPromise<any> => {
  const baseUrl = '/application-manager/gtd/active/add-product';
  return HTTP.post(baseUrl, body, {});
};

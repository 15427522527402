import { useTypedSelector } from 'hooks/use-typed-selector';
import { IPreferredContactFilteredPhone } from 'interfaces/redux/IPreferredContact';
import React from 'react';
import { PhoneUtils } from 'utils/phone-utils';
import { Grid } from '@mui/material';
import { buildQaId } from 'utils/build-qa-id';
import { PreferredContactName } from 'containers/patient/patient-overview/components/preferred-contact-name';
import { PreferredContactMethodNote } from 'containers/patient/patient-overview/components/preferred-contact-method-note';
import TalkDeskPhoneNumber from 'components/talkdesk-phone-number/talkdesk-phone-number';

interface IPreferredContactPhoneProps {
  sms: boolean;
}

const getQaId = buildQaId('preferred-contact-phone');

const PREFERRED_CONTACT_RANK = 1;
export const PreferredContactPhone: React.FC<IPreferredContactPhoneProps> = (
  props: IPreferredContactPhoneProps,
): JSX.Element | null => {
  const { sms } = props;
  const patient = useTypedSelector(state => state.patient);
  const preferredContact = useTypedSelector(state => state.preferredContact);

  const contactPhone = React.useMemo<IPreferredContactFilteredPhone | undefined>(() => {
    const phones = preferredContact ? preferredContact.filteredPhones : patient.phones;
    return phones
      ?.filter(phone => !phone.deleted)
      ?.find(phone => phone.rank === PREFERRED_CONTACT_RANK);
  }, [preferredContact, patient]);

  const contactPhoneNumber = React.useMemo<string | undefined>(() => {
    return contactPhone?.value;
  }, [contactPhone]);

  const phoneAvailability = React.useMemo<string | undefined>(() => {
    if (contactPhone) {
      return PhoneUtils.formatAvailableTimes(contactPhone) ?? undefined;
    }
    return undefined;
  }, [contactPhone]);

  return (
    <>
      <PreferredContactName />
      <Grid container alignItems="center" data-qa-id={getQaId('wrapper')}>
        <Grid item>
          <TalkDeskPhoneNumber
            number={contactPhoneNumber}
            availability={phoneAvailability}
            owner={preferredContact ?? patient}
            preferSms={sms}
          />
        </Grid>
        <Grid item>
          <PreferredContactMethodNote />
        </Grid>
      </Grid>
    </>
  );
};

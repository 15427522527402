import React from 'react';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { Button, Grid, Modal, TextField, Typography } from '@mui/material';
import { CircularLoadingButton } from 'components/circular-loading-button/circular-loading-button';
import { Controller, useForm } from 'react-hook-form';
import { getModalStyle } from 'services/utils/styles-service';
import { nameOfFactory } from 'utils/types-util';
import { notifyError } from 'actions/action-notifications';
import { renderDropdown as RenderDropdown } from 'components/form/field/redux-field';
import { useDispatch } from 'react-redux';
import Validation from 'components/form/validation/validation';
import { buildQaId } from 'utils/build-qa-id';
import { renderDatePicker as RenderDatePicker } from 'components/form/datepicker/datetime-picker';
import { convertToArborDate } from 'models/time/arbor-date';
import { ADD_TRELLIS_THERAPEUTIC_CLASS, DATABASE_DATETIME_FORMAT } from 'constants/index';
import { yesNoTrueFalse } from 'constants/lists';
import { styles } from './add-new-ttc-modal.styles';
import { logger } from '../../../../winston-logger';

interface IAddNewTtcModalProps {
  open: boolean;
  therapeuticAreas: { id: number; name: string }[];
  onCancel: () => void;
  onSuccess: () => void;
}

interface IFormFields {
  name: string;
  active: boolean;
  is_restricted: boolean;
  pdc_eligible: boolean;
  therapeutic_area: number | null;
  start_date: string | null;
  end_date: string | null;
}

const getFieldName = nameOfFactory<IFormFields>();
const requiredErrorMsg = 'Required';
const getQaId = buildQaId('application-manager.ttc.add-new-ttc-modal', '.');

export const AddNewTtcModal: React.FC<IAddNewTtcModalProps> = (
  props: IAddNewTtcModalProps,
): JSX.Element => {
  const classes: any = styles();

  // #region component state
  const form = useForm<any>({
    defaultValues: {
      name: '',
      active: true,
      is_restricted: false,
      pdc_eligible: false,
      therapeutic_area: null,
      start_date: null,
      end_date: null,
    },
  });
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  // #endregion

  // #region helper functions
  const onSubmit = async (formValues: IFormFields): Promise<void> => {
    setSubmitting(true);
    try {
      const name = formValues.name as string;
      const { active } = formValues;
      const { is_restricted } = formValues;
      const { pdc_eligible } = formValues;
      const therapeutic_area_id = formValues.therapeutic_area;
      const start_date = formValues.start_date
        ? (convertToArborDate(formValues.start_date, true).getUtcDate(
            true,
            DATABASE_DATETIME_FORMAT,
          ) as string)
        : (null as null);
      const end_date = formValues.end_date
        ? (convertToArborDate(formValues.end_date, true).getUtcDate(
            true,
            DATABASE_DATETIME_FORMAT,
          ) as string)
        : (null as null);

      const response = await ApplicationManagerClient.addNewTtc(
        name,
        active,
        is_restricted,
        pdc_eligible,
        therapeutic_area_id,
        start_date,
        end_date,
      );

      dispatch({ type: ADD_TRELLIS_THERAPEUTIC_CLASS, payload: response.data });
      props.onSuccess();
      props.onCancel();
    } catch (error: any) {
      logger.error(error);
      const errorData = error?.response?.data;
      dispatch(
        notifyError(`${errorData.validationError || errorData.message || 'Error creating ttc'}`),
      );
    } finally {
      setSubmitting(false);
    }
  };
  // #endregion

  return (
    <Modal open={props.open} data-qa-id={getQaId('wrapper')}>
      <div style={getModalStyle()} className={classes.modal}>
        <Grid container direction="column" spacing={2}>
          {/* Title */}
          <Grid item xs="auto">
            <Typography className={classes.title} data-qa-id={getQaId('title')}>
              Add New TTC
            </Typography>
          </Grid>

          {/* Form */}
          <Grid item xs="auto" data-qa-id={getQaId('form')}>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <Controller
                  name={getFieldName('name')}
                  control={form.control}
                  rules={{ required: true }}
                  render={(ctrlProps: any) => {
                    return (
                      <>
                        <TextField
                          variant="standard"
                          fullWidth
                          label="Therapeutic Class Name"
                          data-qa-id={getQaId(getFieldName('name'))}
                          value={ctrlProps?.field?.value}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            className: classes.textFieldInput,
                            value: ctrlProps?.field?.value,
                            onChange: ctrlProps?.field?.onChange,
                            onBlur: ctrlProps?.field?.onBlur,
                          }}
                        />
                        <Validation touched={form.formState.errors.name} error={requiredErrorMsg} />
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name={getFieldName('active')}
                  control={form.control}
                  render={(ctrlProps: any) => {
                    return (
                      <RenderDropdown
                        width="100%"
                        label="Active"
                        data-qa-id={getQaId(getFieldName('active'))}
                        fields={yesNoTrueFalse}
                        input={{
                          value: ctrlProps?.field?.value,
                          onChange: ctrlProps?.field?.onChange,
                          onBlur: ctrlProps?.field?.onBlur,
                        }}
                        meta={{
                          touched: Boolean(form.formState.errors.is_restricted),
                          error: requiredErrorMsg,
                        }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name={getFieldName('is_restricted')}
                  control={form.control}
                  render={(ctrlProps: any) => {
                    return (
                      <RenderDropdown
                        width="100%"
                        label="Restricted"
                        data-qa-id={getQaId(getFieldName('is_restricted'))}
                        fields={yesNoTrueFalse}
                        input={{
                          value: ctrlProps?.field?.value,
                          onChange: ctrlProps?.field?.onChange,
                          onBlur: ctrlProps?.field?.onBlur,
                        }}
                        meta={{
                          touched: Boolean(form.formState.errors.is_restricted),
                          error: requiredErrorMsg,
                        }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name={getFieldName('pdc_eligible')}
                  control={form.control}
                  render={(ctrlProps: any) => {
                    return (
                      <RenderDropdown
                        width="100%"
                        label="PDC Eligible"
                        data-qa-id={getQaId(getFieldName('pdc_eligible'))}
                        fields={yesNoTrueFalse}
                        input={{
                          value: ctrlProps?.field?.value,
                          onChange: ctrlProps?.field?.onChange,
                          onBlur: ctrlProps?.field?.onBlur,
                        }}
                        meta={{
                          touched: Boolean(form.formState.errors.is_restricted),
                          error: requiredErrorMsg,
                        }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name={getFieldName('start_date')}
                  control={form.control}
                  render={(ctrlProps: any) => {
                    return (
                      <RenderDatePicker
                        width="100%"
                        label="Start Date"
                        data-qa-id={getQaId(getFieldName('start_date'))}
                        input={{
                          value: ctrlProps?.field?.value,
                          onChange: ctrlProps?.field?.onChange,
                          onBlur: ctrlProps?.field?.onBlur,
                        }}
                        meta={{
                          touched: Boolean(form.formState.errors.start_date),
                          error: requiredErrorMsg,
                        }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name={getFieldName('end_date')}
                  control={form.control}
                  render={(ctrlProps: any) => {
                    return (
                      <RenderDatePicker
                        width="100%"
                        label="End Date"
                        data-qa-id={getQaId(getFieldName('end_date'))}
                        input={{
                          value: ctrlProps?.field?.value,
                          onChange: ctrlProps?.field?.onChange,
                          onBlur: ctrlProps?.field?.onBlur,
                        }}
                        meta={{
                          touched: Boolean(form.formState.errors.end_date),
                          error: requiredErrorMsg,
                        }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <Controller
                  name={getFieldName('therapeutic_area')}
                  control={form.control}
                  render={(ctrlProps: any) => {
                    return (
                      <RenderDropdown
                        width="100%"
                        label="Therapeutic area"
                        data-qa-id={getQaId(getFieldName('therapeutic_area'))}
                        fields={props.therapeuticAreas.map(item => ({
                          value: item.id,
                          label: item.name,
                        }))}
                        input={{
                          value: ctrlProps?.field?.value,
                          onChange: ctrlProps?.field?.onChange,
                          onBlur: ctrlProps?.field?.onBlur,
                        }}
                        meta={{
                          touched: Boolean(form.formState.errors.therapeutic_area),
                        }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button
                      data-qa-id={getQaId('button.cancel')}
                      onClick={() => {
                        form.reset();
                        props.onCancel();
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <CircularLoadingButton
                      data-qa-id={getQaId('button.save')}
                      buttonLabel="Save"
                      onClick={form.handleSubmit(onSubmit)}
                      isLoading={submitting}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

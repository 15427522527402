import React, { useState, useEffect } from 'react';
import { Modal } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { IMedsRowData } from 'models/meds/IMed';
import { convertToArborDate } from 'models/time/arbor-date';
import { IMedicationListItem } from 'interfaces/redux/IMedications';
import { windowFeatureIsEnabled } from 'config/window-features';
import { MED_SYNC_STATUS } from 'constants/index';
import { styles } from './meds-styles';
import EnrollmentSelectForm from './meds-enrollment-select-form';
import FinalForm from './meds-final-form';
import { useTypedSelector } from '../../../hooks/use-typed-selector';

interface IProps {
  classes: any;
  onClose: (event: {}, reason: string) => void;
}

const AddMeds = (props: IProps) => {
  const { onClose, classes } = props;
  const [selectListData, setSelectListData] = useState<IMedsRowData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [medsSelected, setMedsSelected] = useState(false);
  const [selectedMedsData, setSelectedMedsData] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState('...Loading Medications');
  const patientId = useTypedSelector(state => state.patient?.id);
  const medSync = useTypedSelector(state => state.patient?.med_sync === MED_SYNC_STATUS.OPT_IN);
  const medsList = useTypedSelector(state => state.medications.medicationGroups);

  const reopenSelectForm = () => {
    setMedsSelected(false);
  };
  const openNextStep = (selectedMeds: any) => {
    setMedsSelected(true);
    setSelectedMedsData(selectedMeds);
  };

  const sortDrugName = (a: IMedsRowData, b: IMedsRowData) => {
    if (b.drug_name < a.drug_name) {
      return 1;
    }
    if (b.drug_name > a.drug_name) {
      return -1;
    }
    return 0;
  };

  function getMeds() {
    try {
      setIsLoading(true);
      const mappedResponseData: IMedsRowData[] = medsList
        .filter((entry: IMedicationListItem) => entry.status_code === 'active')
        .filter((entry: IMedicationListItem) => entry.is_specialty_drug !== 'Y')
        .map((entry: IMedicationListItem): IMedsRowData => {
          const startDateFormatted = convertToArborDate(entry.start_dt).getCustomerDate(
            true,
            'YYYY-MM-DD',
          );
          return {
            isSelected: false,
            drug_name: entry.drug_name,
            ndc: entry.ndc,
            dosage_form: entry.dosage_form,
            start_date: entry.start_dt,
            start_date_sortable: startDateFormatted,
            id: entry.id,
            strength_with_unit: `${entry.strength} ${entry.strength_unit_of_measure}`,
            therapy_id: entry.therapy_id,
            is_specialty_drug: entry.is_specialty_drug === 'Y',
            specialty_type: entry.specialty_type,
          };
        });
      const managedMeds = mappedResponseData.filter(item => item.therapy_id).sort(sortDrugName);
      const unmanagedMeds = mappedResponseData.filter(item => !item.therapy_id).sort(sortDrugName);
      const initialSortData = unmanagedMeds.concat(managedMeds);
      setSelectListData(initialSortData);
      setIsLoading(false);
    } catch (error) {
      setLoadingMessage('Failed to load Medications');
    }
  }

  useEffect(() => {
    getMeds();
  }, [medsList]);

  return (
    <Modal open onClose={onClose}>
      <>
        {!medsSelected && (
          <div className={classes.modalSelectInnerContainer}>
            <EnrollmentSelectForm
              onClose={onClose}
              handleContinue={openNextStep}
              medList={selectListData}
              isLoading={isLoading}
              loadingMessage={loadingMessage}
            />
          </div>
        )}
        {medsSelected && (
          <div className={classes.modalFormInnerContainer}>
            <FinalForm
              back={reopenSelectForm}
              cancel={onClose}
              save={onClose}
              patientId={patientId}
              drugs={selectedMedsData}
              medSync={medSync}
            />
          </div>
        )}
      </>
    </Modal>
  );
};

export default withStyles(styles)(AddMeds);

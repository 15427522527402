import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { styles } from '../patient-overview-styles';
import arborTheme from '../../../../lib/themes';

export const useStyles = makeStyles((theme: Theme) => {
  const baseStyles = styles(arborTheme);

  return {
    summarySectionHeader: {
      ...(baseStyles.summarySectionHeader as any),
    },
    typographyTextSmall: {
      ...(baseStyles.typographyTextSmall as any),
    },
    phoneAvailableTimes: {
      ...(baseStyles.phoneAvailableTimes as any),
    },
    icon: {
      height: theme.spacing(2.0),
      paddingRight: theme.spacing(0.5),
    },
  };
});

import React, { useState, useMemo, useEffect } from 'react';

import {
  Grid,
  Box,
  Button,
  Popover,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import DatetimePicker from 'components/form/datepicker/datetime-picker';
import RadioGroup from 'components/form/radio/radio-group';
import RadioButton from 'components/form/radio/radio-button';
import { get } from 'lodash';

import useStyles from './styles';

import {
  LIKE,
  GT,
  LT,
  EQ,
  BETWEEN,
  IN,
  DT_STRING,
  DT_NUMBER,
  DT_DATE,
  SORT_DEFAULT,
  SORT_ASC,
  SORT_DESC,
} from '../../constants';

const CheckboxList = ({ checkedList: checked, list, handleOnToggleList: handleToggle }) => {
  const { listItemIcon, filterListItem, checkboxList } = useStyles();
  const [textFilter, setTextFilter] = useState('');
  const handleOnChangeTextFilter = e => {
    setTextFilter(e.target.value);
  };
  return (
    <Box>
      <Box pt={2} />
      <FormControl fullWidth>
        <TextField
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          id="outlined-basic"
          label="Filter value"
          value={textFilter}
          onChange={handleOnChangeTextFilter}
        />
      </FormControl>
      <Box pt={2} />
      <List className={checkboxList}>
        {list
          .filter(
            d => textFilter === '' || d.label.toLowerCase().includes(textFilter.toLowerCase()),
          )
          .map(item => {
            const labelId = `checkbox-list-label-${item.value}`;
            return (
              <ListItem
                className={filterListItem}
                key={item.value}
                button
                onClick={() => handleToggle(item.value)}
              >
                <ListItemIcon className={listItemIcon}>
                  <Checkbox
                    edge="start"
                    color="primary"
                    checked={checked.indexOf(item.value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={item.label} />
              </ListItem>
            );
          })}
      </List>
    </Box>
  );
};

export default function ColumnFilter(props) {
  const {
    anchorEl,
    onClose,
    open,
    id: popoverId,
    setSortValue,
    setSelectedCriteria,
    sortValue,
    selectedCriteria,
    searchValueCriteria,
    setSearchValueCriteria,
    secondSearchValueCriteria,
    setSecondSearchValueCriteria,
    onColumnFilterChange,
    onClearColumnFilter,
    columnFilter,
    columnValues,
    dataType,
    columnId,
    goToPage,
  } = props;

  const { overflowPopover } = useStyles();

  const sortList = useMemo(() => [
    { label: 'Default', value: SORT_DEFAULT },
    { label: 'Ascending', value: SORT_ASC },
    { label: 'Descending', value: SORT_DESC },
  ]);

  const criteriaList = useMemo(() => [
    { label: 'Like', value: LIKE, in: [DT_STRING] },
    { label: 'Greater than', value: GT, in: [DT_NUMBER, DT_DATE] },
    { label: 'Less than', value: LT, in: [DT_NUMBER, DT_DATE] },
    { label: 'Equal to', value: EQ, in: [DT_STRING, DT_NUMBER, DT_DATE] },
    { label: 'Between', value: BETWEEN, in: [DT_NUMBER, DT_DATE] },
  ]);

  const filter = get(columnFilter, columnId, null);

  const [checkedList, setCheckedList] = useState(filter ? filter.value : []);

  const handleOnToggleList = value => {
    const currentIndex = checkedList.indexOf(value);
    const newChecked = [...checkedList];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedList(newChecked);
  };

  useEffect(() => {
    if (dataType === 'list') {
      setSelectedCriteria(IN);
      setSearchValueCriteria(checkedList);
    }
  }, [checkedList]);

  const handleOnApply = () => {
    goToPage(0);
    onColumnFilterChange(checkedList);
    onClose();
  };

  const handleOnClear = () => {
    setCheckedList([]);
    setSearchValueCriteria('');
    setSecondSearchValueCriteria('');
    setSelectedCriteria(null);
    setSortValue(SORT_DEFAULT);
    onClearColumnFilter();
  };

  const handleOnChangeSort = e => {
    setSortValue(e);
  };

  const handleOnSelectCriteria = ({ target }) => {
    setSelectedCriteria(target.value);
  };

  return (
    <Popover
      id={popoverId}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      onClick={e => {
        e.stopPropagation();
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      className={overflowPopover}
    >
      <Box pt={2} pl={3} pr={3} pb={2}>
        <Grid direction="column" container>
          <Typography variant="caption">Sort:</Typography>
          <Box pt={1} />
          <FormControl fullWidth required>
            <RadioGroup value={sortValue} onChange={handleOnChangeSort} horizontal>
              {sortList.map(item => (
                <RadioButton key={item.value} maxItemWidth="100px" value={item.value}>
                  {item.label}
                </RadioButton>
              ))}
            </RadioGroup>
          </FormControl>
          {!columnValues && dataType !== 'list' ? (
            <>
              <Box pt={2} />
              <Typography variant="caption">Criteria</Typography>
              <Box pt={1} />
              <FormControl fullWidth variant="outlined">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCriteria}
                  onChange={handleOnSelectCriteria}
                  variant="standard"
                >
                  {criteriaList
                    .filter(o => o.in.some(t => t === dataType))
                    .map(item => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Box pt={2} />
              {dataType !== 'date' ? (
                <Grid container item spacing={1}>
                  <Grid item xs>
                    <FormControl fullWidth>
                      <TextField
                        variant="standard"
                        id="outlined-multiline-static"
                        label="Search value"
                        value={searchValueCriteria}
                        onChange={({ target }) => setSearchValueCriteria(target.value)}
                      />
                    </FormControl>
                  </Grid>
                  {selectedCriteria === BETWEEN && (
                    <Grid item xs>
                      <FormControl fullWidth>
                        <TextField
                          variant="standard"
                          id="outlined-multiline-static1"
                          label="Search value"
                          value={secondSearchValueCriteria}
                          onChange={({ target }) => setSecondSearchValueCriteria(target.value)}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Grid container item spacing={1}>
                  <Grid item xs>
                    <FormControl variant="outlined" fullWidth>
                      <DatetimePicker
                        style={{ width: '100%' }}
                        id="editable-date-picker"
                        value={searchValueCriteria}
                        onChange={e => setSearchValueCriteria(e)}
                        dateFormat="MM/dd/yyyy"
                        placeholder="mm/dd/yyyy"
                        onBlur={() => {}}
                      />
                    </FormControl>
                  </Grid>
                  {selectedCriteria === BETWEEN && (
                    <Grid item>
                      <FormControl variant="outlined" fullWidth>
                        <DatetimePicker
                          style={{ width: '100%' }}
                          id="editable-date-picker"
                          onChange={e => setSecondSearchValueCriteria(e)}
                          dateFormat="MM/dd/yyyy"
                          placeholder="mm/dd/yyyy"
                          value={secondSearchValueCriteria}
                          onBlur={() => {}}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              )}
            </>
          ) : (
            Array.isArray(columnValues) && (
              <CheckboxList
                list={columnValues}
                checkedList={checkedList}
                handleOnToggleList={handleOnToggleList}
              />
            )
          )}
          <Box pt={2} />
          <Grid container item justifyContent="flex-end">
            <Button onClick={handleOnClear}>Clear</Button>
            <Box ml={1}>
              <Button variant="contained" color="primary" onClick={handleOnApply}>
                Apply
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  );
}

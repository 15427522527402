import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import IncomesTable from 'containers/patient/financials/tables/incomes-table';
import { IPatientEnrollmentSectionDetailsProps } from '../types';
import { useTypedSelector } from '../../../hooks/use-typed-selector';
import { IIncome } from '../../patient-merge-modal/interfaces';
import AddIncome from '../../../containers/patient/financials/incomes/add-income';

export function PatientEnrollmentIncomeSection(props: IPatientEnrollmentSectionDetailsProps) {
  const incomes: IIncome[] = useTypedSelector(state =>
    Object.values(state.financials.incomes.data),
  );
  const patientId = useTypedSelector(state => state.selectedPatientId);
  const [displayAddButton, setDisplayAddButton] = useState(true);
  const [displayAddForm, setDisplayAddForm] = useState(false);

  const toggleAddForm = () => {
    setDisplayAddButton(!displayAddButton);
    setDisplayAddForm(!displayAddForm);
  };

  return (
    <>
      <IncomesTable
        // @ts-ignore
        dataset={incomes}
        hideTitleHeader
      />

      {displayAddForm && <AddIncome patientId={patientId} verifyOnSubmit cancel={toggleAddForm} />}
      {displayAddButton && (
        <Grid marginTop={1}>
          <Button
            variant="outlined"
            onClick={toggleAddForm}
            startIcon={<AddIcon />}
            sx={{ float: 'right' }}
          >
            Add Income
          </Button>
        </Grid>
      )}
    </>
  );
}

import HTTP from '../services/http';
import {
  FETCH_ICD10S,
  FETCH_GPIS,
  FETCH_PHYSICIANS,
  FETCH_INSURANCE_TYPES,
  FETCH_USERS,
  FETCH_SERVICE_GROUPS,
  FETCH_CUSTOMER_CLINICS,
  FETCH_LOOKUPS,
  FETCH_ALLERGY_LOOKUPS,
  FETCH_ALLERGY_REACTION_TYPES,
  FETCH_APPOINTMENT_CLINICS,
  FETCH_ME,
  ADD_DATA_COLLECT_ITEM,
  EDIT_DATA_COLLECT_ITEM,
} from '../constants';

const baseUrl = 'lookup';

export function fetchLookups() {
  const request = HTTP.get('lookups', {}, true);

  return {
    type: FETCH_LOOKUPS,
    payload: request,
  };
}

export function fetchICD10s(filter) {
  const request = HTTP.get(`${baseUrl}/icd10?q=${filter}`, {});

  return {
    type: FETCH_ICD10S,
    payload: request,
  };
}

export function fetchGPIs(filter) {
  const request = HTTP.get(`${baseUrl}/gpi?q=${filter}`, {});

  return {
    type: FETCH_GPIS,
    payload: request,
  };
}
export function fetchPhysicians(filter, exact = false) {
  const request = HTTP.get(`${baseUrl}/physicians?q=${filter}&exact=${exact}`, {});

  return {
    type: FETCH_PHYSICIANS,
    payload: request,
  };
}

export function fetchInsuranceTypes(filter) {
  const request =
    filter === undefined
      ? HTTP.get(`${baseUrl}/insurance-types`, {})
      : HTTP.get(`${baseUrl}/insurance-types?q=${filter}`, {});

  return {
    type: FETCH_INSURANCE_TYPES,
    payload: request,
  };
}

export function fetchMe() {
  let payload;
  if (window.USER === undefined) {
    payload = HTTP.get('/users/me', {});
  } else {
    payload = {
      data: window.USER,
    };
  }

  return {
    type: FETCH_ME,
    // eslint-disable-next-line object-shorthand
    payload: payload,
  };
}

export function fetchUsers(filter) {
  const request =
    filter === undefined ? HTTP.get('/users', {}, true) : HTTP.get(`/users/?q=${filter}`, {});

  return {
    type: FETCH_USERS,
    payload: request,
  };
}

export function fetchServiceGroups(filter) {
  const request =
    filter === undefined
      ? HTTP.get('/service-groups', {}, true)
      : HTTP.get(`/service-groups/?q=${filter}`, {});

  return {
    type: FETCH_SERVICE_GROUPS,
    payload: request,
  };
}

export const fetchCustomerClinics = () => {
  const request = HTTP.get(`${baseUrl}/customer-clinics`, {});

  return {
    type: FETCH_CUSTOMER_CLINICS,
    payload: request,
  };
};

export const fetchAppointmentClinics = () => {
  const request = HTTP.get(`${baseUrl}/appointment-clinics`, {});

  return {
    type: FETCH_APPOINTMENT_CLINICS,
    payload: request,
  };
};

export function fetchAllergyLookups() {
  const request = HTTP.get('/allergies/lookups', {});
  return {
    type: FETCH_ALLERGY_LOOKUPS,
    payload: request,
  };
}

export function fetchAllergyReactionTypes() {
  const request = HTTP.get('/allergies/reactions', {});
  return {
    type: FETCH_ALLERGY_REACTION_TYPES,
    payload: request,
  };
}

export function addDataCollectItem(newDcItem) {
  return {
    type: ADD_DATA_COLLECT_ITEM,
    payload: newDcItem,
  };
}

export function editDataCollectItem(dcItem) {
  return {
    type: EDIT_DATA_COLLECT_ITEM,
    payload: dcItem,
  };
}

import React from 'react';
import { Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';

import { noteTagTypes } from 'constants/lists';
import { getDocumentsForResource } from 'services/utils/document-service';

import { getDisplayNotes } from 'services/utils/note-service';
import InterventionDetail from 'containers/tasks/interventions/intervention-detail';
import { DUR } from 'constants/index';

import { styles } from '../../form/field/field-styles';

export default () => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const tasks = useSelector(state => state.tasks.data);
  const DURTask = Object.values(tasks).find(task => task.taskType === DUR);
  const interventionStatuses = useSelector(state => state.taskStatuses.statuses.int);
  const allPinnedNotes = useSelector(state => state.notes.notes.allNonPinnedNotes);
  const allNonPinnedNotes = useSelector(state => state.notes.notes.allNonPinnedNotes);
  const users = useSelector(state => state.lookups.users);
  const patient = useSelector(state => state.patient);
  const serviceGroups = useSelector(state => state.lookups.serviceGroups);
  const documents = useSelector(state => state.uploadedDocuments.documents);

  const noteDisplayCommonProps = {
    patientId: patient.id,
    isInContext: true,
    containerClasses: classes,
    maxLine: '1',
  };

  const renderInterventionTask = task => {
    if (!interventionStatuses || !interventionStatuses.length || !task) {
      return null;
    }
    const { value: intTypeId, displayLabel: intTypeLabel } = noteTagTypes.find(
      tag => tag.label === 'INT',
    );
    const displayNotes = getDisplayNotes(
      allPinnedNotes,
      allNonPinnedNotes,
      intTypeId,
      task.id,
      users,
      serviceGroups,
      2,
    );
    const displayDocuments = getDocumentsForResource(
      documents,
      [{ tagTypeId: intTypeId, resourceId: task.id }],
      1,
    );
    const notesWithProps = {
      tagName: 'INT',
      tagTypeId: intTypeId,
      notes: displayNotes,
      ...noteDisplayCommonProps,
      patient,
    };
    return (
      <InterventionDetail
        task={task}
        statuses={interventionStatuses}
        notes={notesWithProps}
        documents={displayDocuments}
        serviceGroups={serviceGroups}
        tagTypeId={intTypeId}
        tagTypeLabel={intTypeLabel}
        displayEdit
      />
    );
  };
  const intTasks = DURTask.interactions
    .map(i => i.intervention_id)
    .map(id => Object.values(tasks).find(task => task.taskType === 'INT' && task.id === id));

  return (
    <>
      <Typography>Interventions</Typography>
      <Box className={classes.innerFormContainer}>
        {intTasks.map(task => renderInterventionTask(task))}
      </Box>
    </>
  );
};

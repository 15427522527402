import { IAddContactModal } from 'interfaces/redux/IAddContactModal';

export enum AddContactModalActionType {
  SetState = 'ADD_CONTACT_MODAL_STATE',
  ResetModal = 'ADD_CONTACT_MODAL_RESET',
}

export interface IAddContactModalAction {
  type: AddContactModalActionType;
  payload: IAddContactModal;
}

export const addContactModalActions = {
  setState: (state: IAddContactModal): IAddContactModalAction => ({
    type: AddContactModalActionType.SetState,
    payload: state,
  }),
  resetModal: () => {
    return {
      type: AddContactModalActionType.ResetModal,
    };
  },
};

import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import TherapyList from 'containers/patient/therapy/therapy-list';
import { goToTop } from 'utils/react-scrollable-anchor';
import { styles } from '../nav-styles';
import { ToggleDisplayAddTherapy } from '../../../actions/action-form-displays';

class Therapies extends Component {
  constructor(props) {
    super(props);
    this.handleAddClick = this.handleAddClick.bind(this);
  }

  componentWillUnmount() {
    const { onToggleFormDisplay } = this.props;
    onToggleFormDisplay(false);
  }

  handleAddClick() {
    const { onToggleFormDisplay } = this.props;
    onToggleFormDisplay(true);
    setTimeout(() => goToTop(), 0);
  }

  render() {
    const { location, selectedTaskType, selectedTaskId, selectedTherapyId } = this.props;
    const selectedTask = `${selectedTaskType}_${selectedTaskId}`;
    const parsed = queryString.parse(location.search);
    const { sidebar } = parsed;

    return (
      <Grid container>
        <Grid item xs={12}>
          <TherapyList
            openTherapyId={selectedTherapyId}
            selectedTaskType={selectedTaskType}
            selectedTask={selectedTask}
            isTaskSidebar={sidebar === 'tasks'}
            addTherapyHandler={this.handleAddClick}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  const { selectedTaskType, selectedTaskId, selectedTherapyId } = state;
  return {
    selectedTaskType,
    selectedTaskId,
    selectedTherapyId,
  };
};

const mapDispatchToProps = dispatch => ({
  onToggleFormDisplay: bool => {
    dispatch(new ToggleDisplayAddTherapy(bool));
  },
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(Therapies);

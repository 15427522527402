import React from 'react';
import { benefitsInvestigationSubmissionFormActions } from 'slices/benefits-investigation-modal-slice';
import { compose } from 'recompose';
import { getModalStyle } from 'services/utils/styles-service';
import { Modal } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { StringUtils } from '../../../../utils/string-utils';
import { styles } from './benefits-investigation-modal-styles';
import BenefitsInvestigationForm from './benefits-investigation-form';

type IBenefitsInvestigationModalProps = WithStyles<typeof styles>;

type Props = IBenefitsInvestigationModalProps;

const BenefitsInvestigationModal: React.FC<Props> = (props: Props): JSX.Element => {
  const { classes } = props;

  const open = useTypedSelector(state => state.benefitsInvestigationSubmissionForm.open);
  const therapy = useTypedSelector(state => state.benefitsInvestigationSubmissionForm.therapy);
  const arTaskId = useTypedSelector(state => state.benefitsInvestigationSubmissionForm.arTaskId);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(benefitsInvestigationSubmissionFormActions.close());
  };

  return (
    <Modal
      open={open || false}
      onClick={event => event.stopPropagation()}
      onBackdropClick={onClose}
      data-qa-id="benefits-investigation-dialog"
    >
      <div style={getModalStyle()} className={classes.addBenefitsInvestigationModal}>
        <BenefitsInvestigationForm
          defaultRxNumber={StringUtils.randomNumberStringWithNDigits(6)}
          therapy={therapy}
          arTaskId={arTaskId}
        />
      </div>
    </Modal>
  );
};

export default compose<Props, IBenefitsInvestigationModalProps>(withStyles(styles))(
  BenefitsInvestigationModal,
);

import React from 'react';
import { getProvider } from 'components/dynamic-form/helpers';

export const makeIdNameListFormatter = (list, idProp, nameProp) => {
  const result =
    list &&
    list.map(item => ({
      [idProp]: String(item.id),
      [nameProp]: item.name,
    }));

  return result;
};

export const noFormatter = list => list;

export default (providers, providerName, defaultValue, formatter) => {
  const result = React.useMemo(() => {
    if (providerName) {
      let providerObject = getProvider(providers, providerName);
      if (providerObject === undefined) {
        providerObject = defaultValue;
      }
      return formatter ? formatter(providerObject) : providerObject;
    }
    return defaultValue;
  }, [providers, formatter]);
  return result;
};

import React from 'react';
import { convertToArborDate } from 'models/time/arbor-date';
import { PatientOverviewCopy } from 'components/icons/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import store from 'store';
import { notifySuccess } from 'actions/action-notifications';

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(1),
  },
}));

export default ({ details = null, interventionType = null, multipleForms = [], ...props }) => {
  const classes = useStyles();
  let data = null;
  const createCopyData = (detail, type) =>
    `Date: ${convertToArborDate(new Date()).getUtcDate(true, 'MM/DD/YYYY')}${
      type ? `\nType: ${type}` : ''
    }${detail && detail !== '' ? `\nDetails: ${detail}` : ''}`;
  if (multipleForms.length > 0) {
    const result = multipleForms.map(int => createCopyData(int.details, int.type));
    data = multipleForms.length > 1 ? result.join('\n\n') : result;
  } else {
    data = createCopyData(details, interventionType);
  }

  return (
    <CopyToClipboard
      text={data}
      onCopy={() => {
        store.dispatch(notifySuccess('Intervention task has been copied to clipboard!'));
      }}
    >
      <Button variant="outlined" {...props}>
        <PatientOverviewCopy className={classes.icon} />
        COPY INT CONTENT
      </Button>
    </CopyToClipboard>
  );
};

import React from 'react';
import { withStyles, WithStyles } from '@mui/styles';
import { FillDeliveryConfirmationStatus } from 'interfaces/enums/TaskStatuses/FillDeliveryConfirmationStatus';
import { ShippingStyles } from './shipping.styles';
import { IPrintLabelButton } from './types';
import VoidPickupButton from './void-pickup-button';
import PickupButton from './pickup-button';

interface IShippingProps extends IPrintLabelButton, WithStyles<typeof ShippingStyles> {}

const PickupShippingButtons = (props: IShippingProps): JSX.Element | null => {
  const {
    orderId,
    statusId,
    currentPrintInformation,
    currentDeliveryInformation,
    currentFdcInformation,
    disabled,
  } = props;

  const isStatusValidForShowingPrintButtons =
    statusId === FillDeliveryConfirmationStatus.WillPickUp ||
    statusId === FillDeliveryConfirmationStatus.Packed;

  const isPacked = currentFdcInformation?.statusId === FillDeliveryConfirmationStatus.Packed;

  const [readyForPickupClicked, setReadyForPickupClicked] = React.useState<boolean | undefined>(
    false,
  );
  const [firstTimePrinting, setFirstTimePrinting] = React.useState<boolean | undefined>(isPacked);

  const displayPickUpButton = isStatusValidForShowingPrintButtons;

  const displayPickUpButtonsGroup = (): JSX.Element => {
    return (
      <>
        {firstTimePrinting && !readyForPickupClicked ? (
          <PickupButton
            currentDeliveryInformation={currentDeliveryInformation}
            currentPrintInformation={currentPrintInformation}
            currentFdcInformation={currentFdcInformation}
            orderId={orderId}
            setReadyForPickupClicked={setReadyForPickupClicked}
            disabled={disabled}
          />
        ) : (
          <>
            <VoidPickupButton
              currentDeliveryInformation={currentDeliveryInformation}
              currentPrintInformation={currentPrintInformation}
              currentFdcInformation={currentFdcInformation}
              orderId={orderId}
              setReadyForPickupClicked={setReadyForPickupClicked}
              setFirstTimePrinting={setFirstTimePrinting}
            />
          </>
        )}
      </>
    );
  };

  return (
    <>
      {displayPickUpButton && displayPickUpButtonsGroup()}
      {!statusId && displayPickUpButtonsGroup()}
    </>
  );
};

export default withStyles(ShippingStyles)(PickupShippingButtons);

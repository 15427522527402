import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import JsonForm from 'components/dynamic-form/json-form';
import json from 'config/tasks/single-data-collect.json';
import {
  getTreeMapFromDataTypes,
  getClinicalDataTypesMap,
  getFieldProperties,
} from 'services/utils/data-collect';
import { getUniqueListOfGpi10 } from 'services/utils/medication-service';
import { AdministrationStatus } from 'interfaces/redux/ITherapy';
import { MedicationStatus, FailedTherapies } from '../../../constants/enums';

export default function (props) {
  const [clinicalDataTypes, therapies] = useSelector(state => [
    state.lookups.clinicalDataTypes,
    state.therapies.data,
  ]);
  const [selectedDataType, setSelectedDataType] = useState();
  const { dcItem, onSubmit, onCancel, patientLevel } = props;
  const map = getClinicalDataTypesMap(clinicalDataTypes);
  const medications = useSelector(state => state.medications.medicationGroups);

  const f = map[selectedDataType] || {};

  const medicationList = getUniqueListOfGpi10(medications);
  if (Object.keys(f).length > 0 && f.dataTypeId === FailedTherapies.Id) {
    f.option = medications
      .reduce((acc, currentVal) => {
        if (currentVal.status_code !== MedicationStatus.Active) {
          acc.push(currentVal.drug_name);
        }
        return acc;
      }, [])
      .join(';');
    if (f.option.length > 0) {
      f.options = f.option.split(';');
    }
  }

  const providers = {
    fields: getTreeMapFromDataTypes(clinicalDataTypes),
    therapies: therapies
      ? Object.keys(therapies).filter(id => ![AdministrationStatus.NoGo, AdministrationStatus.Discontinued].includes(therapies[id].administration_status)).map(id => ({
          id,
          name: `${therapies[id].drug_name} - ${therapies[id].strength} ${therapies[id].strength_unit_of_measure} ${therapies[id].administration_status}`,
        }))
      : [],
    valueList: (f.options || []).map(k => ({
      id: k?.trim().toLowerCase(),
      name: k,
    })),
    field: f.questionnaireId ? f : medicationList,
  };

  return (
    <JsonForm
      title="Data Collect"
      jsonForm={{
        ...json,
        fields: json.fields.map(field => {
          if (f && field.id === 'value') {
            return {
              ...field,
              ...getFieldProperties(f),
              provider: ['list', 'multiselect'].includes(f.type) ? 'valueList' : 'field',
              suffix: f.unit,
              tooltip: f.tooltip,
            };
          }
          return field;
        }),
      }}
      formName={`add${patientLevel ? 'PatientLevel' : ''}`}
      formId="single-data-collect"
      data={dcItem || {}}
      providers={providers}
      onFormChange={values =>
        setSelectedDataType(values.data_type ? values.data_type.split('-')[1] : null)}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
}

import React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { severityAlertList } from 'constants/lists';
import colors from '../../lib/themes/colors.module.scss';

const { cornflowerblue, yellow2, valenciaRed } = colors;
const useStyles = makeStyles(theme => ({
  severityAlert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(1.4),
    paddingBottom: theme.spacing(1.4),
    paddingInline: theme.spacing(2),
    borderRadius: 5,
  },
  severityHigh: {
    color: valenciaRed,
    backgroundColor: `${valenciaRed}33`,
  },
  severityMedium: {
    backgroundColor: `${yellow2}55`,
  },
  severityLow: {
    backgroundColor: `${cornflowerblue}AA`,
  },
}));

export function InterventionSeverityBanner({ severity_id }) {
  const classes = useStyles();

  return (
    <div
      className={cx(classes.severityAlert, {
        [classes.severityLow]: severity_id === 1,
        [classes.severityMedium]: severity_id === 2,
        [classes.severityHigh]: severity_id === 3,
      })}
    >
      {severityAlertList[severity_id]}
    </div>
  );
}

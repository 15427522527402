import React from 'react';
import { Switch, SwitchProps } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { defaultTheme } from '../../lib/themes';

interface IArborSwitchProps extends SwitchProps {
  ['data-qa-id']?: string;
}

export const ArborSwitch = withStyles({
  switchBase: {
    'color': defaultTheme.palette.primary.patina,
    '&.Mui-checked': {
      color: defaultTheme.palette.primary.patina,
    },
    '&.Mui-checked + $track': {
      backgroundColor: defaultTheme.palette.primary.patina,
    },
    '&': {
      color: defaultTheme.palette.primary.grey2,
    },
  },
  checked: {},
  track: {},
})((props: IArborSwitchProps) => <Switch {...props} />);

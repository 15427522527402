import React, { useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Add as AddIcon } from '@mui/icons-material';
import BenefitsTable from 'containers/patient/financials/tables/benefits-table';
import { IPatientEnrollmentSectionDetailsProps } from '../types';
import { useTypedSelector } from '../../../hooks/use-typed-selector';
import { convertMapToList } from '../../../reducers/helper-reducer';
import { ToggleDisplayAddMedicalInsurance } from '../../../actions/action-form-displays';
import AddMedicalInsurance from '../../../containers/patient/financials/medical-insurances/add-medical-insurance';
import { selectInsuranceVerification, useVerifiedText, SelectVerifiedInfo } from '../utils';

const selectMedicalInsuranceVerification: SelectVerifiedInfo = ({
  financials,
  selectedPatientId,
}) =>
  selectInsuranceVerification(
    convertMapToList(financials.medical_insurances.data),
    selectedPatientId,
  );

export function PatientEnrollmentMedInsuranceSection({
  alert: alertMessage,
}: IPatientEnrollmentSectionDetailsProps) {
  const medInsurances = useTypedSelector(state => {
    return convertMapToList(state.financials.medical_insurances.data).sort(
      (a, b) => a.insurance_type_id - b.insurance_type_id,
    );
  });
  const patientId = useTypedSelector(state => state.selectedPatientId);
  const displayAddMedicalInsurance = useTypedSelector(
    state => state.formDisplays.displayAddMedicalInsurance,
  );
  const dispatch = useDispatch();

  const verifiedMessage = useVerifiedText(selectMedicalInsuranceVerification);

  useEffect(() => {
    if (verifiedMessage) alertMessage(verifiedMessage, true);
    else alertMessage('Not Verified');
  }, [verifiedMessage]);

  return (
    <Grid item xs={12}>
      <BenefitsTable
        // @ts-ignore
        type="medicalBenefit"
        dataset={medInsurances}
        hideTitleHeader
      />
      {displayAddMedicalInsurance && (
        <AddMedicalInsurance
          patientId={patientId}
          stretch
          cancel={() => dispatch(new ToggleDisplayAddMedicalInsurance(false))}
        />
      )}
      {!displayAddMedicalInsurance && (
        <Grid marginTop={1}>
          <Button
            variant="outlined"
            onClick={() => dispatch(new ToggleDisplayAddMedicalInsurance(true))}
            startIcon={<AddIcon />}
            sx={{ float: 'right' }}
          >
            Add Med Insurance
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { expandTheme } from 'utils/theme-util';
import { ModalUtil } from 'services/utils/modal.util';
import { combineStyles } from 'services/utils/styles-service';
import { BaseTableStyles } from '../../base-table/base-table.styles';

export const NewTableStyles = (theme: Theme) => {
  const { font, palette } = expandTheme(theme);

  return createStyles({
    recordContainer: {
      padding: theme.spacing(2),
    },
    recordChip: {
      verticalAlign: 'middle',
      textAlign: 'center',
      width: '100%',
      maxWidth: '120px',
      float: 'right',
      color: 'white',
      fontWeight: 'bold',
    },
    triggerChip: {
      backgroundColor: palette.primary.rollingStone,
    },
    ifChip: {
      backgroundColor: palette.primary.trellisBlue,
    },
    thenChip: {
      backgroundColor: palette.primary.yellow,
    },
    recordDescription: {
      verticalAlign: 'middle',
      height: '100%',
    },
    highlineLevel1: {
      fontWeight: 'bold',
    },
    highlineLevel2: {
      fontWeight: 'bold',
      color: palette.primary.patina,
    },
  });
};

const styles = combineStyles(BaseTableStyles, NewTableStyles);

const useStyles = makeStyles(theme => styles(theme));
export { styles, useStyles };

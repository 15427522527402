import React from 'react';
import { connect, useDispatch } from 'react-redux';
import compose from 'recompose/compose';
import { Grid, Typography, Divider } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { addScheduleDrugOutcome, fetchTaskCounts } from 'actions/action-tasks';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { renderTextField } from 'components/form/field/redux-field';
import { required } from 'components/form/validation/validation';
import { ADD_TARGET } from 'constants/index';
import { Field, reduxForm, change } from 'redux-form';
import { workListChanged } from 'actions/action-patient';
import AutocompleteMinigrid from 'components/form/field/render-minigrid-autocomplete';
import { fetchGpis, specialtyTypeFormatter } from 'services/utils/therapy-service';
import { styles } from './schedule-outreach-details-styles';

const ScheduleOutreachTherapyAdding = props => {
  const {
    handleClose,
    handleSubmit,
    classes,
    pristine,
    submitting,
    workListChanged, // eslint-disable-line
    fetchTaskCounts, // eslint-disable-line
    addScheduleDrugOutcome, // eslint-disable-line
    taskId,
  } = props;

  const dispatch = useDispatch();

  const onSubmit = values => {
    // Construct the payload from the form values
    const payload = {
      ndc: values.ndc,
    };

    return addScheduleDrugOutcome(taskId, payload).then(() => {
      workListChanged();
      fetchTaskCounts();
      handleClose();
    });
  };

  const handleFetchGpisOptions = async searchText => {
    const result = await fetchGpis(searchText);
    return (
      result?.data?.drugs?.map(therapy => ({
        ...therapy,
        id: therapy.ndc,
        label: therapy.drug_info,
      })) || []
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.addTargetForm} autoComplete="off">
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" id="add_target_title">
            Add Target
          </Typography>
          <Divider />
        </Grid>
      </Grid>
      <Grid container className={classes.addTargetFormWrapper}>
        <Grid container spacing={3}>
          <Grid item xs={6} className={classes.therapySearchWrapper}>
            <Field
              name="therapy"
              label="Therapy *"
              component={AutocompleteMinigrid}
              validate={[required]}
              fetchOptions={handleFetchGpisOptions}
              hint="Search by Therapy or GPI or NDC"
              columnsToShow={{
                gpi: 'GPI',
                drug_name: 'Drug Name',
                dosage_form_description: 'Form',
                dose: 'Dose',
                ldd: 'LDD',
                specialty_type: 'Specialty Type',
                ndc: 'NDC',
              }}
              onChange={value => {
                dispatch(change(ADD_TARGET, 'ndc', value.ndc));
                dispatch(change(ADD_TARGET, 'gpi', value.gpi));
              }}
              minSearchLength={2}
              valueFormatter={specialtyTypeFormatter}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name="ndc"
              label="NDC"
              disabled
              component={renderTextField}
              validate={[required]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <Field
              name="gpi"
              label="GPI"
              component={renderTextField}
              disabled
              validate={[required]}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ConfirmationPanel
          handleCancel={handleClose}
          disableSubmit={pristine || submitting}
          buttonIdPrefix="submit_exclude_drug"
        />
      </Grid>
    </form>
  );
};

const mapStateToProps = state => {
  const { patient } = state;
  return {
    patientId: patient.id,
    customerClinics: state.lookups.customerClinics,
    serviceGroups: state.lookups.serviceGroups,
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    addScheduleDrugOutcome,
    fetchTaskCounts,
    workListChanged,
  }),
)(
  reduxForm({
    form: ADD_TARGET,
  })(ScheduleOutreachTherapyAdding),
);

export class NcpdpStringUtils {
  /**
   * From page 772 of NCPDP documentation
   * Given an input like "999E" should return "9995"
   * Given an input like "25}" should return -250
   */
  public static convertOverpunchNumber = (input: string): string => {
    const units: Record<
      string,
      {
        value: number;
        negative?: boolean;
      }
    > = {
      '{': { value: 0 },
      'A': { value: 1 },
      'B': { value: 2 },
      'C': { value: 3 },
      'D': { value: 4 },
      'E': { value: 5 },
      'F': { value: 6 },
      'G': { value: 7 },
      'H': { value: 8 },
      'I': { value: 9 },

      '}': { value: 0, negative: true },
      'J': { value: 1, negative: true },
      'K': { value: 2, negative: true },
      'L': { value: 3, negative: true },
      'M': { value: 4, negative: true },
      'N': { value: 5, negative: true },
      'O': { value: 6, negative: true },
      'P': { value: 7, negative: true },
      'Q': { value: 8, negative: true },
      'R': { value: 9, negative: true },
    };

    const lastCharacter = input[input.length - 1];
    const valueFromUnits = units[lastCharacter];
    if (valueFromUnits) {
      const updatedInput = input.substr(0, input.length - 1) + valueFromUnits.value.toString();
      if (!valueFromUnits.negative) {
        return updatedInput;
      }
      const numberValue = Number(updatedInput);
      return (numberValue * -1).toString();
    }
      return input;
  };

  /**
   * Converts a string number from NCPDP response to something to show in the ui.
   *
   * Given: input "250", factor "2" and decimals "2" will return "2.50"
   */
  public static prettyNumberString = (input: string, factor = 2, decimals = 2) => {
    const number = Number(input);

    // eslint-disable-next-line no-restricted-properties, prefer-exponentiation-operator
    return (number / Math.pow(10, factor)).toFixed(decimals).toString();
  };

  /**
   * https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
   * Given: '1001.02' would return '$1,001.02'
   */
  public static moneyString = (input: string) => {
    return `$${input.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  };

  /**
   * Turns a number from an NCPDP request into a standard number (really it's a string)
   * Most of the time this is to go from something like "44000" to "44" since we have to add
   * zeros to the end of a string
   */
  public static toStandardNumber = (input: string | undefined): string | undefined => {
    if (input != null && /^\d*000$/.test(input)) {
      return input.slice(0, -3);
    }

    return input;
  };
}

import { IRole } from 'interfaces/redux/ILookups';

export const licensedFormatter = (value: unknown): string => {
  const defaultValue = '-';
  if (value && Array.isArray(value) && value.every(x => typeof x === 'string')) {
    return value
      .sort((a: string, b: string) => {
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      })
      .join(', ');
  }
  return defaultValue;
};

export const getDecredentialUserRole = (
  currentRole: number,
  roles: IRole[],
): number | undefined => {
  const current = roles.find(x => x.id === currentRole);
  if (current && current.fallback_role_id) {
    return current.fallback_role_id;
  }

  return undefined;
};

export const statusFormatter = (value: unknown): string => {
  if (value && value === 1) {
    return 'Active';
  }
  return 'Inactive';
};

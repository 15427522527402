import { diff } from 'deep-diff';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { isEqual } from 'lodash';
import { env } from 'process';
import { PropsWithChildren, ComponentType, memo } from 'react';
import shallowEqual from 'recompose/shallowEqual';

/**
 * HOC uses a deep-diff on the prop changes to determine equality for rendering.
 * Log changes in non-prod environment.
 * Ideally, nobody ever has to use this because we did a good job at state/prop design.
 * This is for old components that re-render too often and might be slowing things down.
 * @param BaseComponent: React.ComponentType
 * @returns React.ComponentType
 */
export function createDeepDiffProps({ logProps = false }: { logProps?: boolean } = {}) {
  return function withDeepDiffProps<T extends PropsWithChildren<any>>(
    BaseComponent: ComponentType<T>,
  ) {
    return memo(BaseComponent, (prevProps, nextProps) => {
      const differences = diff(prevProps, nextProps);
      if (env.NODE_ENV !== 'production' && logProps) {
        console.log(BaseComponent.displayName ?? 'anonymous', !!differences, { differences });
      }
      return !differences;
    });
  };
}

// Returns true if left and right are deeply equal
export function deepDiff(left: any, right: any) {
  if (shallowEqual(left, right)) return true;
  const differences = diff(left, right);
  return !differences;
}

/**
 * This prevents state changes from triggering on complex
 * state objects when nothing that important really changed, just
 * the underlying object reference.
 *
 * If you need to use this, there's probably a better way to arrange
 * the state and props in your components so that it isn't accessing any
 * state that it doesn't specifically need. This is a _temporary bandaid_
 * for existing components that are just having a hard time.
 */
export const useDeepDiffSelector = <T>(selectorFn: (state: any) => T) =>
  useTypedSelector(selectorFn, deepDiff);

import { IState } from 'interfaces/redux/IState';
import { IResourceLink, IResourceLinks } from 'interfaces/redux/IResourceLinks';
import { ICounselingTask } from 'interfaces/redux/task-types/ICounselingTask';
import { TASK_COUNSELING, TASK_INTERVENTIONS } from 'constants/index';
import { ISideEffect } from '../education-and-counseling/side-effects/interfaces/ISideEffect';
import { InterventionType } from '../education-and-counseling/side-effects/interfaces/InterventionType';

// Keep only 1 link per intervention id
const uniqByInterventionId = (interventionLinks: IResourceLink[]): IResourceLink[] => {
  const uniqIdMap: Record<number, boolean> = {};
  const uniqInterventions = [];

  for (const link of interventionLinks) {
    if (!uniqIdMap[link.resource_two_id]) {
      uniqIdMap[link.resource_two_id] = true;
      uniqInterventions.push(link);
    }
  }

  return uniqInterventions;
};

const linkToSideEffect = (link: IResourceLink): ISideEffect => {
  const linkMetadata = JSON.parse(link.meta);
  return {
    interventionId: link.resource_two_id,
    reaction: linkMetadata.reaction,
    causedBy: linkMetadata.causedBy,
    severity: linkMetadata.severity,
    type: linkMetadata.type,
  };
};

const isSideEffect = (link: IResourceLink): boolean => {
  const metaData = JSON.parse(link.meta);
  return (
    (metaData.reaction &&
      metaData.causedBy &&
      metaData.severity &&
      (metaData.type === InterventionType.SideEffect || metaData.type === undefined)) ||
    metaData.type === InterventionType.Adherence
  );
};

const getSideEffects = (state: {links: IResourceLinks}, tasks: ICounselingTask[]): ISideEffect[] => {
  const links = state.links.data;

  const counselingTaskIds = tasks.map(counselingTask => counselingTask.id);

  return uniqByInterventionId(
    links.filter(
      link =>
        link.relationship === 'parent' &&
        link.resource_one_type === TASK_COUNSELING &&
        link.resource_two_type === TASK_INTERVENTIONS &&
        isSideEffect(link) &&
        counselingTaskIds.includes(link.resource_one_id),
    ),
  ).map(linkToSideEffect);
};

export { getSideEffects };

export enum NoteTagTypes {
  Patient = 1,
  Therapy = 2,
  Clinical = 3,
  Demographic = 4,
  Financial = 5,
  MedicalInsurance = 6,
  PbmInsurance = 7,
  Income = 8,
  Contact = 9,
  BI = 10,
  PA = 11,
  FA = 12,
  CA = 13,
  CI = 14,
  FC = 15,
  TPR = 16,
  DC = 17,
  FDC = 18,
  INT = 19,
  CSL = 20,
  AR = 21,
  OR = 22,
  MR = 23,
  Document = 24,
  QRE = 25,
  DUR = 26,
  RS = 27,
  Allergy = 28,
  Problem = 29,
  SpecialPopulation = 30,
}

import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

export const styles = (theme: Theme) => {
  const palette = theme.palette as any;
  return createStyles({
    container: {
      marginBottom: 20,
      marginTop: 0,
    },
    tabsWrapper: {
      flexGrow: 1,
      backgroundColor: palette.background.paper,
      display: 'flex',
    },
    tabs: {
      backgroundColor: palette.primary.grey3,
      borderRadius: theme.spacing(1),
      minWidth: theme.spacing(24),
    },
    tab: {
      textTransform: 'none',
    },
    unselectedTab: {
      borderBottom: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: palette.primary.grey13,
    },
    selectedTab: {
      backgroundColor: palette.primary.grey25,
    },
    tabContentWrapper: {
      padding: theme.spacing(2),
      // width: '100%',
    },
    invalidIcon: {
      color: palette.primary.grey23,
      fontSize: 20,
    },
    validIcon: {
      color: palette.primary.cuttySark,
      fontSize: 20,
    },
    validIconSelected: {
      color: palette.primary.patina,
    },
    labelGrid: {
      textAlign: 'left',
    },
    navigationButtonsContainer: {
      padding: 40,
    },
  });
};

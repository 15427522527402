import HTTP from '../http';

/**
 * Update jwt token to a different customer id
 * e.g. customer_id: 2
 *
 * @param {string} customerId
 */
export const changeCustomer = (customerId: number) =>
  HTTP.post('/customers/_change', { customerId }, {});

import React, { Component } from 'react';
import { Tooltip } from '@mui/material';

import { buildHandleEnterKeyPress } from 'services/utils/accessibility';
import colors from '../../../lib/themes/colors.module.scss';

const { teal, white, grey2, grey25, steelGrey } = colors;

// This component is based on react-radio-buttons package
export default class RadioButton extends Component {
  constructor() {
    super();
    this.getStyles = this.getStyles.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { onChange, index, disabled } = this.props;
    if (!disabled && onChange) {
      onChange(index);
    }
  }

  getStyles() {
    const {
      horizontal,
      radius,
      checkedColor,
      wrap,
      last,
      padding,
      rootColor,
      pointColor,
      disabled,
      disabledColor,
      minItemWidth,
      maxItemWidth,
      label,
    } = this.props;
    let marginBottom = 0;

    if (horizontal) {
      if (wrap) {
        marginBottom = 16;
      }
    } else {
      marginBottom = padding || 16;

      if (label) {
        marginBottom /= 2;
      }
    }

    return {
      root: {
        minWidth: minItemWidth || undefined,
        maxWidth: maxItemWidth || undefined,
        cursor: disabled ? 'not-allowed' : 'pointer',
        color: disabled ? disabledColor || grey25 : rootColor || steelGrey,
        borderWidth: 1,
        borderStyle: 'solid',
        background: grey2,
        borderColor: disabled ? disabledColor || grey25 : rootColor || grey2,
        borderRadius: radius || 150,
        padding: padding || 8,
        marginBottom,
        marginRight: horizontal && !last ? (padding || 16) / 2 : 0,
        textAlign: 'center',
        flexBasis: 'auto',
        flexGrow: 1,
      },
      label: {
        color: pointColor || grey2,
        borderStyle: 'none',
        padding: padding || 8,
        marginBottom: horizontal ? 0 : padding || 8,
        marginRight: horizontal && !last ? (padding || 8) / 2 : 0,
      },
      checked: {
        borderColor: checkedColor || teal,
        color: pointColor || white,
        background: checkedColor || teal,
      },
    };
  }

  render() {
    const { checked, children, label, qaID, tooltip, ariaLabel } = this.props;
    const style = this.getStyles();
    const buttonStyle = { ...style.root, ...(checked ? style.checked : {}) };
    const labelStyle = { ...style.root, ...style.label };
    const radioButton = (
      <div
        style={buttonStyle}
        onClick={this.onClick}
        onKeyPress={buildHandleEnterKeyPress(this.onClick)}
        role="button"
        tabIndex="0"
        data-qa-id={qaID}
        aria-label={ariaLabel || children}
        data-selected={checked?.toString()}
      >
        <div style={{ display: 'inline-flex', width: '100%' }}>
          <div style={{ flex: 1 }}>{children}</div>
        </div>
        {label ? (
          <div style={labelStyle}>
            <div>{label}</div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
    return (
      <>
        {tooltip ? (
          <Tooltip arrow title={tooltip.text} key={tooltip.key}>
            {radioButton}
          </Tooltip>
        ) : (
          radioButton
        )}
      </>
    );
  }
}

import React from 'react';
import { Typography, Grid, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ErrorIcon from '@mui/icons-material/Error';

const useStyles = makeStyles(theme => ({
  patientInfoContainer: {
    margin: '15px 30px 30px 30px',
    borderRadius: 5,
    padding: theme.spacing(2),
    boxShadow: `1px 1px 3px 0px rgba(0, 0, 0, 0.2),
      -1px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12)`,
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    display: 'flex',
    flexDirection: 'row',
  },
  warningIcon: {
    color: theme.palette.primary.lightRed,
  },
}));

export default ({ contactList, onAddResponsibleParty }) => {
  const classes = useStyles();
  const hasResponsibleParty = Object.values(contactList).find(contact => contact.is_responsible);
  if (hasResponsibleParty) {
    return null;
  }
  return (
    <Grid item className={classes.patientInfoContainer}>
      <div className={classes.text}>
        <ErrorIcon className={classes.warningIcon} />
        <Typography>Since the patient is under age please provide a responsible party</Typography>
      </div>
      <Button
        type="button"
        onClick={() => onAddResponsibleParty()}
        color="secondary"
        variant="contained"
      >
        Add Responsible Party
      </Button>
    </Grid>
  );
};

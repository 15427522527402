import { Grid, Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { useTheme } from '@mui/styles';
import { IDocumentInfoProps } from 'components/patient-merge-modal/interfaces';
import { convertToArborDate } from 'models/time/arbor-date';
import { IState } from 'interfaces/redux/IState';
import { useSelector } from 'react-redux';

type TagTypeMap = Record<number, string>;
export const DocumentInfo = (props: IDocumentInfoProps) => {
  const { document } = props;
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);
  const tagTypeLookup = useSelector((state: IState) => state.lookups.patientNotesTagTypes);
  const [tagTypeMap, setTagTypeMap] = useState<TagTypeMap>({});
  const tagTypes = document.tags.map(tag => tagTypeMap[tag.tag_type_id]);

  useEffect(() => {
    const newTagTypeMap: TagTypeMap = {};
    tagTypeLookup.forEach((tagType) => {
      newTagTypeMap[tagType.id] = tagType.tag_type;
    });
    setTagTypeMap(newTagTypeMap);
  }, [tagTypeLookup]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Link sx={classes.link}>
          {`#${tagTypes.join(', ')} - ${document.filename}`}
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          {`${document.labels.join(', ')} - uploaded by ${document.createdByUser} `}
          {convertToArborDate(document.created).getCustomerDate(true)}
        </Typography>
      </Grid>
    </Grid>
  );
};

import { takeLatest, select, put, call, all } from 'redux-saga/effects';
import { isValidArray } from 'services/utils/common-service';
import {
  SIDEBAR_FILTERS_CLEAR_IDS,
  SIDEBAR_FILTERS_CLEAR,
  SIDEBAR_FILTERS_SET,
  FETCH_USER_PREFERENCES,
  LOAD_REPORTS,
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORTS_REQUEST,
  FETCH_REPORTS_FAILURE,
  NOTIFY_ERROR, UPDATE_USER_PREFERENCES,
} from '../constants';
import { getStartOfDateForDb, getEndOfDateForDb } from '../services/utils/date-service';
import HTTP from '../services/http';
import { getDatesFromSidebarFilters } from '../services/utils/filters-service';

function* fetchReportByIdsWorker(ids, requestPayload = {}, errMes) {
  try {
    const reportResponse = yield call(() =>
      HTTP.post('/reports', { ...requestPayload, ids }, {}),
    );
    yield put({
      type: FETCH_REPORTS_SUCCESS,
      payload: reportResponse,
      meta: { ids },
    });
  } catch (error) {
    yield put({
      type: FETCH_REPORTS_FAILURE,
      payload: errMes,
    });
    yield put({
      type: NOTIFY_ERROR,
      payload: errMes,
    });
  }
}

function* workerFetchReportsSaga() {
  const state = yield select();
  const { sidebarFilters, router, userPreferences } = state;
  const ids = userPreferences.selectedReports || [];
  if (ids.length === 0) return;

  const filters = sidebarFilters.data.report;
  if (
    router &&
    router.location &&
    router.location.pathname &&
    router.location.pathname.includes('report') &&
    state.userPreferences.loaded
  ) {
    yield put({
      type: FETCH_REPORTS_REQUEST,
    });
    const { from, to } = getDatesFromSidebarFilters(filters);
    const serviceGroups = isValidArray(filters.serviceGroups)
      ? filters.serviceGroups.map(group => group.idValue || group.value || group)
      : [];
    const clinics = isValidArray(filters.clinics)
      ? filters.clinics.map(group => group.idValue || group.value || group)
      : [];
    const patientStatuses = isValidArray(filters.patientStatuses)
      ? filters.patientStatuses.map(status => status.idValue || status.value || status)
      : [];
    const commonRequestPayload = {
      fromDate: getStartOfDateForDb(from),
      toDate: getEndOfDateForDb(to),
      serviceGroups,
      patientStatuses,
      clinics,
    };

    yield all([
      fetchReportByIdsWorker(ids, commonRequestPayload, 'Error fetching reports'),
    ]);
  }
}

export function* watcherReportSaga() {
  yield takeLatest(
    [
      SIDEBAR_FILTERS_CLEAR_IDS,
      SIDEBAR_FILTERS_CLEAR,
      SIDEBAR_FILTERS_SET,
      FETCH_USER_PREFERENCES,
      UPDATE_USER_PREFERENCES,
      LOAD_REPORTS,
    ],
    workerFetchReportsSaga,
  );
}

import React, { FC, useCallback } from 'react';
import SubHeader from 'components/form/header/subheader';
import { SmallSpacer } from 'components/spacer/spacer';
import RadioGroup from 'components/form/radio/radio-group';
import RadioButton from 'components/form/radio/radio-button';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { RootStateOrAny, useDispatch } from 'react-redux';
import { setSidebarFilter } from 'actions/action-sidebar-filters';
import { fillCycleFilterOptions } from 'constants/index';

const FillCycleFilter: FC = () => {
  const fillCycleFilterSelected = useTypedSelector(
    (state: RootStateOrAny) => state.sidebarFilters?.data?.task?.fillCycle || 1,
  );
  const dispatch = useDispatch();
  const hanldeFillCycle = useCallback(
    (value = 1) => {
      dispatch(
        setSidebarFilter('task', {
          fillCycle: value,
        }),
      );
    },
    [dispatch],
  );

  return (
    <>
      <SubHeader name="FILL CYCLE" />
      <SmallSpacer />
      <RadioGroup horizontal onChange={(value: number) => hanldeFillCycle(value)}>
        {fillCycleFilterOptions.map(({ label, width }, index) => (
          <RadioButton
            value={index + 1}
            maxItemWidth={width}
            key={`fill-cycle-radio-${label}`}
            checked={fillCycleFilterSelected === index + 1}
          >
            {label}
          </RadioButton>
        ))}
      </RadioGroup>
    </>
  );
};

export default FillCycleFilter;

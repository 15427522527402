import React, { useEffect } from 'react';
import { Grid, Tooltip, List, ListItem, Divider, Typography, Paper } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { convertToArborDate } from 'models/time/arbor-date';
import FormHeader from 'components/form/header/header';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { dateSorting } from 'helpers/react-table';
import classnames from 'classnames';
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useBlockLayout,
  useResizeColumns,
} from 'react-table-v7';
import { fetchHistory, clearHistory } from '../../actions/action-therapies';
import { styles } from './history-styles';

const LIMIT_TO_SHOW_CELL_TOOLTIP = 24;

const getDisplayName = field =>
  typeof field === 'string' &&
  field
    .toLowerCase()
    .split('_')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');

/* eslint-disable  no-confusing-arrow */
const getDateOrDateTime = (isDateTime, value) =>
  isDateTime
    ? convertToArborDate(value, true).getUtcDatetime(true)
    : convertToArborDate(value, true).getUtcDate(true);

const getSanitizedValue = (data, value) => {
  if (!data || !value) {
    return '';
  }
  return data.isDate ? getDateOrDateTime(data.isDateTime, value) : value;
};

const HistoryTable = props => {
  const { onLoad, onClear, url, history, medications, classes } = props;

  useEffect(() => {
    onLoad(url);
    return () => {
      onClear();
    };
  }, [onLoad, onClear, url]);

  const generateClassName = (historyRecord, field) => {
    let highlight = false;
    if (!historyRecord.oldValue) {
      highlight = true;
    } else if (historyRecord.newValue && historyRecord.oldValue) {
      highlight = historyRecord.newValue[field] !== historyRecord.oldValue[field];
    }
    return highlight ? classes.highlight : '';
  };

  const renderFieldRow = (value, label, field, historyRecord) => {
    let medStr = `${label}: `;
    if (!value) {
      medStr = medStr.concat('-');
    } else {
      medStr = medStr.concat(value);
    }
    return (
      <ListItem>
        <Typography variant="body2" className={generateClassName(historyRecord, field)}>
          {medStr}
        </Typography>
      </ListItem>
    );
  };

  const renderMedication = (historyRecord, isOld) => {
    const headerStr = isOld ? 'Old Value' : 'NewValue';
    const medication = isOld ? historyRecord.oldValue : historyRecord.newValue;
    if (!medication) {
      return (
        <List>
          <ListItem>
            <Typography variant="body1" className={classes.headerText}>
              {headerStr}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">No Medication</Typography>
          </ListItem>
        </List>
      );
    }
    return (
      <List>
        <ListItem>
          <Typography variant="body1" className={classes.headerText}>
            {headerStr}
          </Typography>
        </ListItem>
        {renderFieldRow(medication.drug_name, 'Medication Name', 'drug_name', historyRecord)}
        {renderFieldRow(medication.ndc, 'NDC', 'ndc', historyRecord)}
        {renderFieldRow(medication.status_code, 'EMR Status', 'status_code', historyRecord)}
        {renderFieldRow(
          convertToArborDate(medication.start_dt, true).getUtcDate(true),
          'Start Date',
          'start_dt',
          historyRecord,
        )}
        {renderFieldRow(
          convertToArborDate(medication.end_dt, true).getUtcDate(true),
          'End Date',
          'end_dt',
          historyRecord,
        )}
        {renderFieldRow(medication.category_code, 'Source', 'category_code', historyRecord)}
        {renderFieldRow(
          convertToArborDate(medication.updated, true).getCustomerDatetime(true),
          'Updated',
          'updated',
          historyRecord,
        )}
        {renderFieldRow(medication.updated_by, 'Updated By', 'updated_by', historyRecord)}
      </List>
    );
  };

  const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter } }) => {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
        className={classes.filterInput}
      />
    );
  };

  const ReactTableComp = ({ columns, data }) => {
    const filterTypes = React.useMemo(
      () => ({
        text: (rows, id, filterValue) => {
          return rows.filter(row => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      [],
    );

    const defaultColumn = React.useMemo(
      () => ({
        Filter: DefaultColumnFilter,
      }),
      [],
    );

    const renderCell = cell => {
      if (cell?.value !== null) {
        const cellRender = cell.render('Cell');
        const cellValue = cell.value;
        return (
          <td {...cell.getCellProps()} className={classes.tbodyTr}>
            {cellValue?.length > LIMIT_TO_SHOW_CELL_TOOLTIP ? (
              <Tooltip arrow title={cellValue}>
                <Typography>{cellRender}</Typography>
              </Tooltip>
            ) : (
              <Typography>{cellRender}</Typography>
            )}
          </td>
        );
      }
      return null;
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      row,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        filterTypes,
        initialState: { pageIndex: 0 },
      },
      useFilters,
      useSortBy,
      usePagination,
      useBlockLayout,
      useResizeColumns,
    );

    return (
      <Grid className={classes.historyTable}>
        <Grid className={classnames(classes.historyTableContainer, undefined)}>
          <table className={classes.historyTableMain} {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      className={[
                        classes.historyHeaderTh,
                        `resizer ${column.isResizing ? 'isResizing' : ''}`,
                      ]}
                      {...column.getResizerProps()}
                    >
                      {column.render('Header') && (
                        <>
                          <tr className={classes.headerTr}>
                            <th
                              {...column.getHeaderProps(column.getSortByToggleProps())}
                              className={classes.headerTh}
                            >
                              {column.render('Header')}
                            </th>
                          </tr>
                          <tr className={classes.headerTrFilter}>
                            <th className={classes.headerTh}>
                              {column.canFilter ? column.render('Filter') : null}
                            </th>
                          </tr>
                        </>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className={classes.mainTbody}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={`history-table-key-${index}`}>
                    {row.cells.map(renderCell)}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Grid>
        <Grid container xs={12} className={classes.paginationBtnsContainer}>
          <Grid item xs={4} className={classes.paginationBtns}>
            <button type="button" onClick={() => previousPage(0)} disabled={!canPreviousPage}>
              Previous
            </button>
          </Grid>
          <Grid item xs={2} className={classes.paginationControls}>
            <span>
              Page{'  '}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                value={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: '100px' }}
              />{' '}
              of {pageOptions.length}
            </span>{' '}
          </Grid>
          <Grid item xs={2} className={classes.paginationControls}>
            <span>
              <select
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize} Rows
                  </option>
                ))}
              </select>
            </span>
          </Grid>
          <Grid item xs={4} className={classes.paginationBtns}>
            <button type="button" onClick={() => nextPage()} disabled={!canNextPage}>
              Next
            </button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  if (medications) {
    return (
      <Grid
        container
        className={classes.historyContainer}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} className={classes.headerContainer}>
          <Paper className={classes.header}>
            <FormHeader header="Medication History" />
          </Paper>
        </Grid>
        <Grid item xs={12} className={classes.contentContainer}>
          {history.map(h => (
            <Grid container className={classes.container}>
              <Grid item xs={6}>
                {renderMedication(h, true)}
              </Grid>
              <Grid item xs={6}>
                {renderMedication(h)}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  }

  return (
    <ReactTableComp
      data={history}
      columns={[
        {
          Header: 'Updated',
          id: 'updated',
          minWidth: 250,
          accessor: d => convertToArborDate(d.updated).getCustomerDatetime(),
          sortMethod: dateSorting,
        },
        {
          Header: 'Field',
          id: 'field',
          minWidth: 250,
          maxWidth: 450,
          accessor: d => getDisplayName(d.field),
        },
        {
          Header: 'Old Value',
          id: 'oldValue',
          minWidth: 250,
          maxWidth: 450,
          accessor: d => getSanitizedValue(d, d.oldValue),
        },
        {
          Header: 'New Value',
          id: 'newValue',
          minWidth: 250,
          maxWidth: 450,
          accessor: d => getSanitizedValue(d, d.newValue),
        },
        {
          Header: 'Updated By',
          accessor: 'updatedBy',
          minWidth: 250,
          maxWidth: 300,
        },
      ]}
    />
  );
};

const mapStateToProps = state => {
  const { history } = state;
  return {
    history,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    onLoad(url) {
      dispatch(fetchHistory(url));
    },
    onClear() {
      dispatch(clearHistory());
    },
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, { withTheme: true }),
)(HistoryTable);

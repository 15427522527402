import makeStyles from '@mui/styles/makeStyles';
import { expandTheme } from 'utils/theme-util';
import { ModalUtil } from 'services/utils/modal.util';

export const styles = makeStyles(theme => {
  const { font } = expandTheme(theme);
  return {
    modal: {
      ...ModalUtil.BasicModalStyling(theme, 130, 3),
      width: theme.spacing(300),
      overflow: 'auto',
      maxHeight: 700,
    },
    title: {
      fontWeight: 'bold',
      fontSize: font.extraLargeFontSize,
      marginBottom: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
    textFieldInput: {
      paddingTop: theme.spacing(0.9),
      paddingBottom: theme.spacing(0.9),
    },
    centerIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
  };
});

import React from 'react';
import { renderPhoneTextField as RenderPhoneTextField } from 'components/form/field/redux-field';

export default ({ label, input, meta, disabled, labelSize, qaId }) => (
  <RenderPhoneTextField
    label={label}
    input={input}
    meta={meta}
    disabled={disabled}
    labelSize={labelSize}
    qaId={qaId}
    type="phone"
  />
);

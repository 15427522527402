import axios from 'axios';
import HttpStatus from 'http-status-codes';
import jwt_decode from 'jwt-decode';
import { datadogRum } from '@datadog/browser-rum';
import axiosRetry from 'axios-retry';

// Retries are only used when the "withRetry" param is true for each HTTP method.
const axiosRetryConfig = { retries: 3, retryDelay: () => 1000 };
export class HTTP {
  constructor(
    axiosConfig = {
      baseURL: '/api',
      withCredentials: true,
    },
    useArborService = true,
    authorizationConfig = {},
  ) {
    this.instance = axios.create(axiosConfig);
    this.useArborService = useArborService;
    this.authorizationConfig = authorizationConfig;

    // Default the retries to 0 (don't retry by default)
    axiosRetry(this.instance, { retries: 0 });

    // configure interceptor
    this.instance.interceptors.response.use(
      response => {
        const jwt = response.headers?.authorization?.split(' ')?.[1];
        if (jwt) {
          const decoded = jwt_decode(jwt);
          datadogRum.setUser({
            id: decoded.id,
            name: decoded.name,
            email: decoded.sub,
            customer_id: decoded.customer_id,
          });
        } else {
          datadogRum.clearUser();
        }

        return response;
      },
      error => {
        if (error && error.response && error.response.status === HttpStatus.UNAUTHORIZED) {
          this.redirectToAuthPage();
        }
        throw error;
      },
    );

    // Configure authorization
    if (useArborService) {
      this.authorizationConfig.headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.sessionStorage.getItem('id_token')}`,
      };
    }
  }

  redirectToAuthPage = () => {
    window.location.href = `/auth.html?returnUrl=${encodeURIComponent(window.location)}`;
  };

  get = (url, config, withRetry = false) => {
    const retryConfig = withRetry ? { 'axios-retry': axiosRetryConfig } : {};
    const requestConfig = { ...this.authorizationConfig, ...retryConfig, ...config };
    return this.instance.get(url, requestConfig);
  };

  post = (url, data, config, withRetry = false) => {
    const retryConfig = withRetry ? { 'axios-retry': axiosRetryConfig } : {};
    const requestConfig = { ...this.authorizationConfig, ...retryConfig, ...config };
    return this.instance.post(url, data, requestConfig);
  };

  put = (url, data, config, withRetry = false) => {
    const retryConfig = withRetry ? { 'axios-retry': axiosRetryConfig } : {};
    const requestConfig = { ...this.authorizationConfig, ...retryConfig, ...config };
    return this.instance.put(url, data, requestConfig);
  };

  patch = (url, data, config, withRetry = false) => {
    const retryConfig = withRetry ? { 'axios-retry': axiosRetryConfig } : {};
    const requestConfig = { ...this.authorizationConfig, ...retryConfig, ...config };
    return this.instance.patch(url, data, requestConfig);
  };

  delete = (url, config, withRetry = false) => {
    const retryConfig = withRetry ? { 'axios-retry': axiosRetryConfig } : {};
    const requestConfig = { ...this.authorizationConfig, ...retryConfig, ...config };
    return this.instance.delete(url, requestConfig);
  };
}

export default new HTTP();

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { NumberUtils } from 'utils/number-utils';
import { SettingsClient } from '../clients/settings-client';

export interface ISettingsState {
  medication_list_pagination: number;
  medication_group_pagination: number;
}

const initialState: ISettingsState = {
  medication_list_pagination: 100,
  medication_group_pagination: 100,
};

export const NAME = 'settings';

export const loadSettings = createAsyncThunk(`${NAME}/load`, async () => {
  const response = await SettingsClient.getSettings();
  if (response?.data) {
    return {
      medication_list_pagination: NumberUtils.tryConvert(
        response.data.medication_list_pagination,
        initialState.medication_list_pagination,
      ),
      medication_group_pagination: NumberUtils.tryConvert(
        response.data.medication_group_pagination,
        initialState.medication_group_pagination,
      ),
    };
  }
  return undefined;
});

const settingsSlice = createSlice({
  name: NAME,
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadSettings.fulfilled, (state, action) => {
      if (action.payload) {
        state.medication_list_pagination = action.payload.medication_list_pagination;
        state.medication_group_pagination = action.payload.medication_group_pagination;
      }
    });
  },
});

export const settingsReducer = settingsSlice.reducer;

export const styles = {
  container: {
    margin: 2,
    marginBottom: 4,
    textAlign: 'center',
  },
  header: {
    margin: 2,
    marginBottom: 4,
  },
  instructions: {
    margin: 2,
  },
  hint: {
    margin: 2,
  },
  copyButton: {
    marginBottom: 2,
  },
};

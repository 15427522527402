import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material';
import compose from 'recompose/compose';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { convertToArborDate } from 'models/time/arbor-date';
import {
  renderDropdown,
  renderTextField,
  renderMultipleDropdown,
} from 'components/form/field/redux-field';
import { workListChanged } from 'actions/action-patient';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { tprServices, submitMethods } from 'constants/lists';
import {
  getInitialServiceGroupForReactSelect,
  getInitialTPRHUBForReactSelect,
  followupDateChanged,
  addTaskOldValues,
} from 'services/utils/task-service';
import { editThirdPartyReferral, fetchTaskCounts } from 'actions/action-tasks';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { ReactSelectForReduxSingle } from 'components/form/field/react-select';
import { EDIT_THIRD_PARTY_REFERRAL_FORM } from '../../../constants';

import { styles } from '../task-detail-styles';
import {
  validateDate,
  required,
  validateNumber,
} from '../../../components/form/validation/validation';

class EditThirdPartyReferral extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleServiceGroupDropdown = this.handleServiceGroupDropdown.bind(this);
    this.state = {
      serviceGroupDropdown: -1,
      selectValue: props.taskStatus,
      otherServiceRequested: false,
    };
  }

  handleSelect(e) {
    this.setState({
      selectValue: e.target.value,
    });
  }

  handleServiceGroupDropdown(e) {
    this.setState({
      serviceGroupDropdown: e.target.value,
    });
  }

  // Handles the submit functionality. Right now it sets some fields to null for simplicity's sake
  handleSubmit(values) {
    const {
      editThirdPartyReferral, // eslint-disable-line
      task,
      cancel,
      workListChanged, //eslint-disable-line
      fetchTaskCounts, //eslint-disable-line
    } = this.props;

    const payload = {
      ...values,
      id: task.id,
      completed_date: values.completed_date
        ? convertToArborDate(values.completed_date).getUtcDatetime()
        : null,
      services_requested: values.services_requested
        ? (Array.isArray(values.services_requested) && values.services_requested.join(',')) ||
          values.services_requested
        : null,
      canceled_dt: values.canceled_dt
        ? convertToArborDate(values.canceled_dt).getUtcDatetime()
        : null,
      canceled_reason: values.canceled_reason ? values.canceled_reason : null,
      followup_dt: convertToArborDate(values.followup_dt).getUtcDatetime(),
      last_checked_dt: values.last_checked_dt
        ? convertToArborDate(values.last_checked_dt).getUtcDatetime()
        : null,
      extra_services: values.extra_services,
      hub: values.hub ? values.hub.value : null,
      status_id: values.status_id,
      service_group_id: values.service_group_id.value,
      therapy_id: task.therapy_id,
      fill_cycle_number: values.fill_cycle_number,
      submitted_date: convertToArborDate(values.submitted_date).getUtcDate(),
      requires_attention_date: convertToArborDate(values.requires_attention_date).getUtcDate(),
    };
    return editThirdPartyReferral(addTaskOldValues(payload, task)).then(() => {
      if (followupDateChanged(task.followup_dt, payload.followup_dt)) {
        workListChanged();
        fetchTaskCounts();
      }
      cancel();
    });
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      classes,
      cancel,
      serviceGroups,
      fillCycles,
      task,
      tprHubs,
    } = this.props;

    const { selectValue, otherServiceRequested, serviceGroupDropdown } = this.state;
    const { therapy_id: therapyId } = task;

    return (
      <Grid
        container
        justifyContent="flex-start"
        className={(classes.formRowContainer, classes.formContainer)}
      >
        <Grid item xs={12}>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            className={classes.editFormContainer}
            autoComplete="off"
          >
            <Grid container className={classes.formRowContainer}>
              <Grid item lg={3} lx={2} className={classes.fieldContainer}>
                <Field
                  name="followup_dt"
                  label="Follow up Date *"
                  component={renderDatePicker}
                  validate={[validateDate, required]}
                />
              </Grid>
              <Grid item lg={3} lx={2} className={classes.fieldContainer}>
                <Field
                  name="last_checked_dt"
                  label="Last Checked Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="service_group_id"
                  label="Service Group *"
                  validate={[required]}
                  component={ReactSelectForReduxSingle}
                  fields={serviceGroups.map(int => ({
                    label: int.display_name,
                    value: int.id,
                    isDisabled: !int.active,
                  }))}
                  state={serviceGroupDropdown}
                />
              </Grid>
              <Grid item lg={3} lx={2} className={classes.fieldContainer}>
                <Field
                  name="completed_date"
                  label="Completed Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item lg={3} lx={2} className={classes.fieldContainer}>
                <Field
                  name="submitted_date"
                  label="Submitted Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item lg={3} lx={2} className={classes.fieldContainer}>
                <Field
                  name="requires_attention_date"
                  label="Requires Attention Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item lg={3} lx={2} className={classes.fieldContainer}>
                <Field
                  name="submit_method"
                  label="Method of submission"
                  component={renderDropdown}
                  fields={submitMethods}
                  state={selectValue}
                />
              </Grid>
              <Grid item lg={3} lx={2} className={classes.fieldContainer}>
                <Field
                  name="canceled_dt"
                  label="Canceled Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item lg={3} lx={2} className={classes.fieldContainer}>
                <Field
                  name="canceled_reason"
                  multiline
                  label="Reason for Canceled status"
                  component={renderTextField}
                />
              </Grid>
              <Grid item xs={6} className={classes.fieldContainer}>
                <Field
                  name="services_requested"
                  label="Services Requested"
                  width="95%"
                  multiple
                  component={renderMultipleDropdown}
                  fields={tprServices.map(hub => ({
                    label: hub.label,
                    value: hub.id,
                  }))}
                />
              </Grid>
              {otherServiceRequested && (
                <Grid item lg={3} lx={2} className={classes.fieldContainer}>
                  <Field
                    name="extra_services"
                    multiline
                    label="Other services Requested"
                    component={renderTextField}
                  />
                </Grid>
              )}
              <Grid item xs={4} className={classes.fieldContainer}>
                <Field
                  name="hub"
                  label="Hub"
                  component={ReactSelectForReduxSingle}
                  fields={tprHubs.map(hub => ({
                    label: hub.label,
                    value: hub.id,
                  }))}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="fill_cycle_number"
                  component={renderDropdown}
                  fields={
                    fillCycles[therapyId] &&
                    Object.keys(fillCycles[therapyId])
                      .map(k => fillCycles[therapyId][k])
                      .map(cycle => ({
                        label: `#${cycle.fill_cycle} NBD: ${convertToArborDate(
                          cycle.needsby_date,
                          true,
                        ).getUtcDate(true)}`,
                        value: cycle.fill_cycle,
                      }))
                  }
                  label="Fill cycle *"
                  validate={[required, validateNumber]}
                />
              </Grid>
            </Grid>

            {/* Eighth Row */}
            <ConfirmationPanel
              cancelButtonName="edit_therapy_cancel_button"
              submitButtonName="edit_therapy_submit_button"
              handleCancel={cancel}
              disableSubmit={pristine || submitting}
            />
          </form>
        </Grid>
      </Grid>
    );
  }
}

function handleFormName(task) {
  if (!task) {
    return null;
  }
  const name = `${EDIT_THIRD_PARTY_REFERRAL_FORM}_${task.therapy_id}_${task.id}`;
  return name;
}

function mapStateToProps(state, props) {
  const name = handleFormName(props.task);
  const { fillCycles } = state;
  const { tprHubs } = props;

  return {
    form: name,
    fillCycles,
    initialValues: {
      followup_dt: convertToArborDate(props.task.followup_dt, true).getCustomerDate(true),
      last_checked_dt: convertToArborDate(props.task.last_checked_dt, true).getCustomerDate(true),
      status_id: props.task.status_id,
      submit_method: props.task.submit_method,
      hub: props.task.hub ? getInitialTPRHUBForReactSelect(props.task.hub, tprHubs) : '',
      services_requested: props.task.services_requested,
      canceled_dt: convertToArborDate(props.task.canceled_dt, true).getUtcDatetime(true),
      canceled_reason: props.task.canceled_reason,
      service_group_id: props.task.service_group_id
        ? getInitialServiceGroupForReactSelect(props.task.service_group_id, props.serviceGroups)
        : '',
      completed_date: convertToArborDate(props.task.completed_date, true).getCustomerDate(true),
      updated: convertToArborDate(props.task.updated, true).getCustomerDatetime(),
      fill_cycle_number: props.task.fill_cycle_number || null,
      submitted_date: convertToArborDate(props.task.submitted_date, true).getUtcDate(true),
      requires_attention_date: convertToArborDate(
        props.task.requires_attention_date,
        true,
      ).getUtcDate(true),
    },
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchTaskCounts,
    workListChanged,
    editThirdPartyReferral,
  }),
)(reduxForm({})(EditThirdPartyReferral));

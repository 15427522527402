const drawerWidth = 160;

export const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    zIndex: 1,
    overflow: 'visible',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  appBar: {
    height: theme.layout.topBarHeight,
    minHeight: theme.layout.topBarHeight,
    display: 'inline-flex',
    position: 'sticky',
    zIndex: theme.drawer.zIndex + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.primary.background,
    color: theme.palette.navigation.main,
    // BM: commented out since it doesn't work anyway, due to not being camel cased. Remove later?
    // fontweight: 300,
    borderBottomColor: theme.palette.primary.grey12,
    borderBottom: '1px solid',
    boxShadow: 'none',
  },
  topNav: {
    padding: 0,
  },
  menuButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    'padding': 0,
    'paddingLeft': 8,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8),
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.background,
  },
  patientContent: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.layout.taskBarWidth + theme.layout.taskListWidth + 2,
    },
    [theme.breakpoints.down('xl')]: {
      marginLeft: theme.layout.taskBarWidth,
    },
  },
  patientContentCollapsedTaskList: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.layout.taskBarWidth + 2,
    },
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  patientContentFullWidth: {
    marginLeft: 0,
  },

  therapyContainer: {
    padding: theme.spacing(1),
    marginBottom: 250,
  },
  insuranceContainer: {
    padding: theme.spacing(1),
    marginBottom: 250,
  },
  scheduleListContent: {
    marginLeft: 2,
  },
  reportContent: {
    marginLeft: 2,
  },
  searchContainer: {
    cursor: 'pointer',
  },
  searchHelpTooltip: {
    maxWidth: 'none',
  },
  searchFieldContainer: {
    paddingLeft: 8,
    width: theme.spacing(50),
  },
  searchTooltipContainer: {
    paddingTop: theme.spacing(2.5),
  },
  noPatientLoadedClass: {
    marginTop: 283,
    paddingLeft: 253,
  },
  halfOpacity: {
    opacity: 0.5,
  },
  tasksAtAGlanceText: {
    fontColor: theme.palette.primary.deepBlue,
    fontSize: theme.font.largeFontSize,
  },
  tasksAtAGlanceTextLarge: {
    fontColor: theme.palette.primary.deepBlue,
    fontSize: 28,
  },
  applicationManagerHeader: {
    color: theme.palette.primary.deepBlue,
    fontSize: theme.font.extraLargeFontSize,
    paddingLeft: theme.spacing(3.75),
  },
  topNavTabs: {
    flex: '0 1 auto',
  },
  topNavSearchForm: {
    flex: '1 1 342px',
  },
  topNavRight: {
    flex: '1 0 auto',
  },
});

import React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  pharagraph: {
    margin: 0,
  },
  title: {
    marginTop: 25,
    marginBottom: 25,
    fontSize: 22,
    fontWeight: 'bold',
    [`${theme.breakpoints.down('xl')} and (orientation: landscape)`]: {
      marginTop: 12,
      marginBottom: 12,
    },
  },
  subTitle: {
    marginTop: 25,
    marginBottom: 25,
    fontSize: 18,
    [`${theme.breakpoints.down('xl')} and (orientation: landscape)`]: {
      marginTop: 10,
      marginBottom: 10,
    },
  },
  secondaryColor: {
    color: theme.palette.primary.teal,
  },
}));

export default ({ type = 'text', color = 'primary', children }) => {
  const classes = useStyles();
  return (
    <p className={cx(classes.pharagraph, classes[type], classes[`${color}Color`])}>{children}</p>
  );
};

enum CounselingStatus {
  Required = 9001,
  Scheduled = 9002,
  Completed = 9003,
  Canceled = 9004,
  OnHold = 9005,
  AttemptedToReachPatient = 9006,
  CanceledByTherapyOptIn = 9007,
  PharmacistReviewNeeded = 9008,
  CanceledDeceased = 9099,
}

const CannotTransitionMap: Record<number, boolean> = {
  [CounselingStatus.Completed]: true,
  [CounselingStatus.Canceled]: true,
  [CounselingStatus.CanceledDeceased]: true,
};

export { CounselingStatus, CannotTransitionMap };

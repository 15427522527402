import React from 'react';
import { IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { renderDropdown as RenderDropdown } from 'components/form/field/redux-field';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import useProvider from 'hooks/useProvider';
import { valueLabelFormatter } from '../formatters';

const useStyles = makeStyles(() => ({
  button: {
    'verticalAlign': 'bottom',
    'marginBottom': 18,
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
}));

export const TherapySelector = ({ options, onButtonClick, qaId, ...other }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <RenderDropdown {...other} />
      {onButtonClick && (
        <IconButton
          size="small"
          onClick={onButtonClick}
          className={classes.button}
          name="therapy_selector_button"
          data-qa-id={qaId}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      )}
    </div>
  );
};

export default ({ providers, field, label, input, meta, disabled, labelSize, qaId }) => {
  const fields = useProvider(providers, field.provider, [], valueLabelFormatter);
  return (
    <TherapySelector
      label={label}
      input={input}
      meta={meta}
      disabled={disabled}
      labelSize={labelSize}
      tooltips={{ placement: 'top-start' }}
      isMulti
      fields={fields}
      preventEmpty={field.preventEmptyValue}
      closeMenuOnSelect={field.closeMenuOnSelect}
      qaId={qaId}
      onButtonClick={field.onButtonClick}
    />
  );
};

/* eslint-disable camelcase */

import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid, CircularProgress, Backdrop, Card, CardContent, Typography } from '@mui/material';
import compose from 'recompose/compose';
import { reduxForm, formValueSelector, getFormValues, change } from 'redux-form';
import { connect } from 'react-redux';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { FA_CHART_REVIEW, FDC, FILL_COORDINATION_STATUS } from 'constants/index';
import { fetchResourceLink } from 'actions/action-resource-links';
import { addNote } from 'actions/action-notes';
import { setDocumentViewer } from 'actions/action-document-viewer';
import { parseNoteToDb } from 'services/utils/note-service';
import { translateUsers } from 'services/utils/users-service';
import { fetchGpis } from 'services/utils/therapy-service';
import { updateTherapiesDispensingPharmacyInRedux } from 'actions/action-therapies';
import {
  editTasks,
  fetchTaskCounts,
  updateFCDispensingPharmacyInRedux,
} from 'actions/action-tasks';
import {
  workListChanged,
  fetchPatient,
  fetchOrders,
  updatePatientPreferences,
} from 'actions/action-patient';
import { addTaskOldValues, getReasonsByStatus } from 'services/utils/task-service';
import { verifyMedications } from 'actions/action-medications';
import { addNewDocument } from 'actions/action-uploaded-documents';
import { notifyError } from 'actions/action-notifications';
import { FillCoordinationStatusMap } from 'constants/task-statuses';
import { TaskStatusFactory } from 'factories/task-status-factory/task-status-factory';
import { StatusMapUtil } from 'utils/status-map-util';
import { getFullAddressList } from 'services/utils/demographic-service';
import { printPdfDocument } from 'containers/patient/documents/preview-document';
import { windowFeatureIsEnabled } from 'config/window-features';
import {
  EDIT_FILL_COORDINATION_FORM,
  TASK_FILL_COORDINATION,
  COMMUNICATION_FORM,
  ADD_TASK_INTERVENTION_FORM,
  ADD_TASK_INCIDENT_FORM,
} from '../../../constants';
import { styles } from '../task-detail-styles';
import {
  buildInitialValues,
  buildPayload,
  getSiblingTaskTherapies,
  getChangedField,
  calculateValueOnInputChange,
  updateWeatherBasedOnAddress,
  calculateTotalCopayAmount,
  getDefaultSideEffectsInt,
  buildNewInterventionsPayload,
  buildPreferencesByAncillarySupplies,
  buildOrderPayload,
} from './fc-util';
import FillCoordinationEditForm from './fill-coordination-edit-form';
import { SideEffectType, EditComponentMode } from './types';

class FillCoordinationStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingPreviewAfterSaving: false,
      newInterventions: [],
      taskArr: props.task ? [props.task] : [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.changeNewInterventionsHandler = this.changeNewInterventionsHandler.bind(this);
    this.initNewInterventionsFromSideEffect = this.initNewInterventionsFromSideEffect.bind(this);
    this.initNewInterventionsFromMissedDose = this.initNewInterventionsFromMissedDose.bind(this);
    this.changeAncillarySupplies = this.changeAncillarySupplies.bind(this);
  }

  setTaskArr(task) {
    this.setState({
      taskArr: [task],
    });
  }

  componentDidUpdate(prevProps) {
    // eslint-disable-next-line no-shadow
    const { change, formValues, task } = this.props;

    if (
      prevProps.formValues?.preferred_rx_delivery_method !==
      formValues?.preferred_rx_delivery_method
    ) {
      change('shipping_method', null);
    }

    if (prevProps.formValues && prevProps.formValues.on_hand_qty && formValues) {
      const taskIds = Object.keys(prevProps.formValues.on_hand_qty);
      taskIds.forEach(id => {
        const input = getChangedField(prevProps, formValues, id);
        calculateValueOnInputChange(input, change, id, formValues);
      });
    }

    if (prevProps.task?.id !== task?.id) {
      this.setTaskArr([task]);
    }

    this.handleAddressChange(prevProps);
    this.initNewInterventionsFromSideEffect(prevProps.formValues, formValues);
    this.initNewInterventionsFromMissedDose(prevProps.formValues, formValues);
  }

  // Handles the submit functionality. Right now it sets some fields to null for simplicity's sake
  async handleSubmit(values) {
    const {
      activeTherapies,
      addNote, // eslint-disable-line no-shadow
      contactList,
      editTasks, // eslint-disable-line no-shadow
      fetchPatient, // eslint-disable-line no-shadow
      fetchResourceLink, // eslint-disable-line no-shadow
      fetchTaskCounts, // eslint-disable-line no-shadow
      inprogressFCTasks,
      internalPharmacies,
      patient,
      reset,
      selectedCustomerId,
      selectedPatientId,
      selectedStatus,
      siblingTaskTherapies,
      statuses,
      tagResourceId,
      tagTypeId,
      task,
      update,
      workListChanged, // eslint-disable-line no-shadow
      updatePatientPreferences, // eslint-disable-line no-shadow
      updateFCDispensingPharmacyInRedux,
      updateTherapiesDispensingPharmacyInRedux,
      paymentMethodTypes,
      disableCommunicationFormOpen,
      disableInterventionFormOpen,
      disableIncidentFormOpen,
    } = this.props;
    if (disableCommunicationFormOpen || disableInterventionFormOpen || disableIncidentFormOpen) {
      return Promise.resolve();
    }

    const { newInterventions } = this.state;

    const method = values.preferred_rx_delivery_method
      ? values.preferred_rx_delivery_method.value
      : null;

    const tasksToUpdate = [task];
    siblingTaskTherapies.forEach(taskTherapy => {
      tasksToUpdate.push(taskTherapy.task);
    });

    const allAddresses = getFullAddressList(patient, contactList);
    let primaryTaskPayload;
    const tasksWithOldValues = [];
    tasksToUpdate.forEach(t => {
      const payload = buildPayload(
        values,
        t,
        patient,
        method,
        true,
        getReasonsByStatus(statuses, selectedStatus)[0].label
          ? values.status_id
          : getReasonsByStatus(statuses, selectedStatus)[0].value,
        paymentMethodTypes,
        allAddresses,
      );
      if (!primaryTaskPayload) {
        primaryTaskPayload = payload;
      }
      const withOldValues = addTaskOldValues(payload, task);
      tasksWithOldValues.push(withOldValues);
    });

    if (values.note_text) {
      const newValues = parseNoteToDb(values);
      const tags = [{ tag_type_id: tagTypeId, resource_id: tagResourceId }];

      const payloadNotes = {
        patient_id: selectedPatientId,
        note_type_id: 1,
        note_text: newValues.note_text,
        is_pinned: 0,
        mentions: newValues.mentions,
        tags,
      };
      addNote(payloadNotes);
    }

    // if completing FC add orders
    const orders = [];
    if (
      TaskStatusFactory.getProcessor(task.taskType).taskTransitioningToComplete(
        task,
        primaryTaskPayload,
      )
    ) {
      const order = buildOrderPayload([task], values);
      orders.push(order);

      const medicationPackagingPreferences = buildPreferencesByAncillarySupplies(
        patient,
        values.ancillary_supplies,
      );

      const updatedPatientPreferences = {
        patient_id: patient.id,
        ...medicationPackagingPreferences,
      };

      if (values?.apply_choice_to_therapies_and_FCs) {
        const newPharmacyId = internalPharmacies.find(
          pharm => pharm.npi === values.dispensing_pharmacy?.dispensing_pharmacy_npi,
        )?.id;
        updatedPatientPreferences.preferred_rx_delivery_pharmacy_id = newPharmacyId;
      }
      // Update patient preferences only if there is something to update
      if (
        Object.keys(medicationPackagingPreferences).length ||
        updatedPatientPreferences.preferred_rx_delivery_pharmacy_id
      ) {
        await updatePatientPreferences(updatedPatientPreferences, patient);
      }
    } else if (values?.apply_choice_to_therapies_and_FCs) {
      const newPharmacyId = internalPharmacies.find(pharm => {
        return pharm.npi === values.dispensing_pharmacy?.dispensing_pharmacy_npi;
      })?.id;
      const updatePayload = {
        patient_id: patient.id,
        preferred_rx_delivery_pharmacy_id: newPharmacyId,
      };
      await updatePatientPreferences(updatePayload, patient);
    }
    const sync_disp_pharm_payload = {};
    if (values?.apply_choice_to_therapies_and_FCs) {
      const newPharmacy = internalPharmacies.find(
        pharm => pharm.npi === values.dispensing_pharmacy?.dispensing_pharmacy_npi,
      );
      sync_disp_pharm_payload.sync_therapies_and_fcs_disp_pharm_payload = true;
      sync_disp_pharm_payload.pharmacy = newPharmacy;
      sync_disp_pharm_payload.therapyIds = activeTherapies.map(therapy => therapy.id);
      sync_disp_pharm_payload.fcIds = inprogressFCTasks.map(task => task.id);
      const updatedTherapiesPayload = {
        therapyIds: activeTherapies.map(therapy => therapy.id),
        pharmacy: newPharmacy,
      };
      updateTherapiesDispensingPharmacyInRedux(updatedTherapiesPayload);
      const updatedFCPayload = {
        fcIds: inprogressFCTasks.map(task => task.id),
        pharmacy: newPharmacy,
      };
      updateFCDispensingPharmacyInRedux(updatedFCPayload);
    }

    return editTasks(
      selectedPatientId,
      tasksWithOldValues,
      orders,
      undefined,
      undefined,
      buildNewInterventionsPayload(newInterventions),
      sync_disp_pharm_payload,
    ).then(async result => {
      workListChanged();
      fetchPatient(selectedPatientId);
      fetchTaskCounts();
      tasksToUpdate.forEach(t => {
        fetchResourceLink(TASK_FILL_COORDINATION, t.id);
      });
      if (!windowFeatureIsEnabled('fdc_statuses')) {
        const updatedTasks = result.payload.data.updated_tasks;
        const createdFDC = updatedTasks?.find(updatedTask => updatedTask.taskType === FDC);
        const orderFormDocumentId = createdFDC?.orderFormDocumentId;
        if (orderFormDocumentId != null) {
          await printPdfDocument(orderFormDocumentId, selectedCustomerId, patient.id);
        }
      }
      update();
      reset();
    });
  }

  handleAddressChange(prevProps) {
    const { change, formValues } = this.props; // eslint-disable-line no-shadow
    const prevAddress =
      prevProps && prevProps.formValues && prevProps.formValues.address
        ? prevProps.formValues.address
        : {};
    const prevShipDate =
      prevProps && prevProps.formValues && prevProps.formValues.ship_date
        ? prevProps.formValues.ship_date
        : '';
    if (
      (formValues.address &&
        formValues.address.value &&
        formValues.address.value !== prevAddress.value) ||
      (formValues.ship_date && formValues.ship_date !== prevShipDate)
    ) {
      updateWeatherBasedOnAddress(
        formValues && formValues.address && formValues.address.label
          ? formValues.address.label
          : '',
        formValues.ship_date,
        change,
      );
    }
  }

  changeNewInterventionsHandler(newInterventions) {
    this.setState({
      newInterventions,
    });
  }

  changeAncillarySupplies(value) {
    // eslint-disable-next-line no-shadow
    const { change } = this.props;
    change('ancillary_supplies', value);
  }

  initNewInterventionsFromSideEffect(prevFormValues, formValues) {
    if (prevFormValues && formValues && prevFormValues.side_effects !== formValues.side_effects) {
      const { newInterventions } = this.state;
      const missedDoseInterventions = newInterventions.filter(
        int => int.sideeffect_type === SideEffectType.MissedMedicationDose,
      );
      this.setState({
        newInterventions: [
          ...missedDoseInterventions,
          getDefaultSideEffectsInt(SideEffectType.Common),
        ],
      });
    }
  }

  initNewInterventionsFromMissedDose(prevFormValues, formValues) {
    if (
      prevFormValues &&
      formValues &&
      prevFormValues.patient_missed_doses !== formValues.patient_missed_doses
    ) {
      const { newInterventions } = this.state;
      const sideEffectInterventions = newInterventions.filter(
        int => int.sideeffect_type === SideEffectType.Common,
      );
      this.setState({
        newInterventions: [
          ...sideEffectInterventions,
          getDefaultSideEffectsInt(SideEffectType.MissedMedicationDose),
        ],
      });
    }
  }

  render() {
    const {
      cancel,
      classes,
      contactList,
      deliveryMethodVal,
      formValues,
      handleSubmit,
      patient,
      selectedStatus,
      siblingTaskTherapies,
      statuses,
      submitting,
      task,
      therapies,
      therapy,
      users,
      form,
      change,
    } = this.props;

    const { isLoadingPreviewAfterSaving, newInterventions, taskArr } = this.state;

    const userData = users && users.length > 0 && translateUsers(users);

    const otcProvider = inputValue =>
      new Promise((resolve, rejected) => {
        fetchGpis(inputValue)
          .then(result =>
            resolve(
              result.data.drugs.map(item => ({
                value: item,
                label: `${item.drug_info} - NDC: ${item.ndc}`,
              })),
            ),
          )
          .catch(err => rejected(err));
      });

    const methodIsChartReview = formValues && formValues.method === FA_CHART_REVIEW;
    const selectedStatusId =
      StatusMapUtil.getStatusIdFromStatusName(selectedStatus, FillCoordinationStatusMap) || 0;

    const copayAmountList = Object.values(formValues?.copay_amount || {});
    const buttonTexts = () => {
      const preview =
        (!windowFeatureIsEnabled('fdc_statuses') ||
          task.status_id === FILL_COORDINATION_STATUS.COMPLETED) &&
        selectedStatusId === FILL_COORDINATION_STATUS.COMPLETED;
      return preview ? 'Save & Preview' : 'Save';
    };

    return (
      <Grid container justifyContent="center" spacing={7}>
        <Backdrop className={classes.backdrop} open={isLoadingPreviewAfterSaving}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <Typography variant="h6">Loading Document Preview</Typography>
                </Grid>
                <Grid item xs={4}>
                  <CircularProgress
                    data-qa-id="loading-preview-after-saving"
                    className={classes.loading}
                    size={50}
                    variant="indeterminate"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Backdrop>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(this.handleSubmit)} autoComplete="off">
            <Grid container className={classes.fieldContainer}>
              <FillCoordinationEditForm
                classes={classes}
                componentMode={EditComponentMode.ChangeStatus}
                contactList={contactList}
                deliveryMethodVal={deliveryMethodVal}
                totalCopayAmount={calculateTotalCopayAmount(copayAmountList)}
                formName={form}
                methodIsChartReview={methodIsChartReview}
                otcProvider={otcProvider}
                patient={patient}
                selectedStatus={selectedStatusId}
                siblingTasks={siblingTaskTherapies}
                statuses={statuses.filter(s => s.reason !== null)}
                tasks={taskArr}
                allTasks={taskArr}
                therapies={therapies}
                therapy={therapy}
                userData={userData}
                users={users}
                haveNewAllergies={Boolean(formValues?.new_allergies)}
                haveNewInfections={Boolean(formValues?.new_infections)}
                selectedAncillarySupplies={formValues?.ancillary_supplies}
                changeAncillarySupplies={this.changeAncillarySupplies}
                areMedicationChanges={Boolean(formValues?.changes_in_medication)}
                haveNewSideEffects={Boolean(formValues?.side_effects)}
                changeNewInterventionsHandler={this.changeNewInterventionsHandler}
                newInterventions={newInterventions}
                patientMissedDoses={Boolean(formValues?.patient_missed_doses)}
                statusReason={
                  formValues && formValues.status_id
                    ? statuses.find(s => s.id === formValues.status_id).reason
                    : ''
                }
                paymentMethodOnFileSelected={Boolean(formValues?.payment_method_on_file)}
                change={change}
                upsPackagingTypeSelected={
                  formValues?.ups_packaging_type ?? { label: 'Customer Supplied Package', value: 2 }
                }
              />
            </Grid>
            {selectedStatus !== task.status && (
              <ConfirmationPanel
                cancelButtonName="fill_coordination_status_cancel_button"
                submitButtonName="fill_coordination_status_submit_button"
                submitButtonText={buttonTexts().save}
                isLoading={submitting}
                handleCancel={cancel}
                disableSubmit={submitting}
              />
            )}
          </form>
        </Grid>
      </Grid>
    );
  }
}

function handleFormName(task) {
  const name = `${EDIT_FILL_COORDINATION_FORM}_status_${task.therapy_id}_${task.id}`;
  return name;
}

function mapStateToProps(state, props) {
  const {
    auth,
    contactList,
    patient,
    selectedPatientId,
    tasks,
    therapies,
    uploadedDocuments,
    lookups: { paymentMethodTypes },
    form,
  } = state;
  const name = handleFormName(props.task);
  const selector = formValueSelector(name);
  const deliveryMethodVal = selector(state, 'preferred_rx_delivery_method');
  const formValues = getFormValues(name)(state);
  const therapy = therapies.data && therapies.data[props.task.therapy_id];
  const siblingTaskTherapies = getSiblingTaskTherapies(props.task, tasks.data, therapies.data);
  const upsPackagingTypes = state.lookups.upsPackagingType;
  const preferredRxDeliveryPharmacyId = state?.patient?.preferred_rx_delivery_pharmacy_id;
  const preferredRxDeliveryPharmacy = state?.pharmacies?.pharmacies.find(
    pharm => pharm.id === preferredRxDeliveryPharmacyId,
  );
  const internalPharmacies = state.pharmacies?.pharmacies || [];
  const initialValues = buildInitialValues(
    [props.task],
    props,
    patient,
    { [therapy.id]: therapy },
    contactList,
    state.fillCycles,
    siblingTaskTherapies,
    upsPackagingTypes,
    preferredRxDeliveryPharmacy,
    internalPharmacies,
  );
  const activeTherapies = state?.therapies?.data
    ? Object.values(state.therapies.data).filter(
        therapy =>
          (therapy.category_id === 1 || therapy.category_id === 2) &&
          therapy.dispensing_status === 'Opt in',
      )
    : [];

  const inprogressFCTasks = state?.tasks?.data
    ? Object.values(state.tasks.data).filter(
        task => task.taskType === 'FC' && task.status_id === 4007,
      )
    : [];

  const disableCommunicationFormOpen = !!(form && form[COMMUNICATION_FORM]);
  const disableInterventionFormOpen = !!(form && form[ADD_TASK_INTERVENTION_FORM]);
  const disableIncidentFormOpen = !!(form && form[ADD_TASK_INCIDENT_FORM]);

  return {
    activeTherapies,
    contactList,
    deliveryMethodVal,
    documents: uploadedDocuments.documents || [],
    form: name,
    formValues,
    initialValues,
    inprogressFCTasks,
    internalPharmacies,
    patient,
    selectedCustomerId: state.filters.selectedCustomerId,
    selectedPatientId,
    therapies: therapies.data,
    therapy,
    user: auth.currentUser,
    users: state.lookups.users,
    siblingTaskTherapies,
    paymentMethodTypes,
    disableCommunicationFormOpen,
    disableInterventionFormOpen,
    disableIncidentFormOpen,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    addNewDocument,
    addNote,
    change,
    editTasks,
    fetchOrders,
    fetchPatient,
    fetchResourceLink,
    fetchTaskCounts,
    notifyError,
    setDocumentViewer,
    verifyMedications,
    workListChanged,
    updatePatientPreferences,
    updateTherapiesDispensingPharmacyInRedux,
    updateFCDispensingPharmacyInRedux,
  }),
)(reduxForm({})(FillCoordinationStatus));

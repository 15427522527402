import { AxiosPromise } from 'axios';
import { TypedHttp } from 'services/typed-http';

interface IResponse {
  medication_list_pagination: unknown;
  medication_group_pagination: unknown;
}

export class SettingsClient {
  static getSettings(): AxiosPromise<IResponse> {
    const url = '/settings';
    return TypedHttp.GetPromise<IResponse>(url);
  }
}

import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  NOTES_VIEW_SET,
  SIDEBAR_FILTERS_CLEAR,
  SIDEBAR_FILTERS_CLEAR_IDS,
  SIDEBAR_FILTERS_SET,
  UPDATE_LOCAL_USER_PREFERECENES_RECENT_CLINICS,
  UPDATE_LOCAL_USER_PREFERECENES_RECENT_PHARMACIES,
  UPDATE_LOCAL_USER_PREFERECENES_RECENT_PROVIDERS,
  UPDATE_LOCAL_USER_PREFERENCES_BI_PHARMACY_ID,
  USER_PREFERENCES_SAVE_FAILURE,
  USER_PREFERENCES_SAVE_REQUEST,
  USER_PREFERENCES_SAVE_SUCCESS,
  USER_PREFERENCES_SELECT_RECENT_PATIENT,
  TASK_TABLE_DATE_FILTER_CHANGE,
} from '../constants';
import HTTP from '../services/http';

let timer = null;
const DEBOUNCE_TIMEOUT = 5000;
function* workerSavePreferences() {
  const { userPreferences: currentUserPreferences, sidebarFilters } = yield select();

  const data = {
    ...currentUserPreferences,
    sidebar: sidebarFilters.data,
  };

  window.localStorage.setItem('user_preferences', data);
  if (timer) {
    clearTimeout(timer);
  }

  try {
    yield put({
      type: USER_PREFERENCES_SAVE_SUCCESS,
      payload: data,
    });

    timer = setTimeout(async () => {
      const putUrl = '/users/me/preferences';
      await HTTP.put(putUrl, data, {});
    }, DEBOUNCE_TIMEOUT);
  } catch (e) {
    yield put({ type: USER_PREFERENCES_SAVE_FAILURE, payload: e });
  }
}

export function* watcherUserPreferencesSaga() {
  yield takeLatest(
    [
      NOTES_VIEW_SET,
      SIDEBAR_FILTERS_CLEAR,
      SIDEBAR_FILTERS_CLEAR_IDS,
      SIDEBAR_FILTERS_SET,
      UPDATE_LOCAL_USER_PREFERECENES_RECENT_CLINICS,
      UPDATE_LOCAL_USER_PREFERECENES_RECENT_PHARMACIES,
      UPDATE_LOCAL_USER_PREFERECENES_RECENT_PROVIDERS,
      UPDATE_LOCAL_USER_PREFERENCES_BI_PHARMACY_ID,
      USER_PREFERENCES_SELECT_RECENT_PATIENT,
      TASK_TABLE_DATE_FILTER_CHANGE,
    ],
    workerSavePreferences,
  );
}

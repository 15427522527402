import React from 'react';

import { Typography, Grid } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Close as CloseIcon } from '@mui/icons-material';

import { ICollectedItem } from 'interfaces/redux/task-types/IDataCollectTask';
import { styles } from './lab-value-card.styles';

interface IProps extends ICollectedItem, WithStyles<typeof styles> {
  onClose(): void;
}

const LabValueCard: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { classes } = props;

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <CloseIcon fontSize="small" className={classes.closeIcon} onClick={props.onClose} />
        <Typography variant="body2" className={classes.title}>
          {props.dataTypeName}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">Assessment Date: {props.assessmentDate}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">Value: {props.value}</Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(LabValueCard);

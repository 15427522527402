import React from 'react';
import withStyles from '@mui/styles/withStyles';
import ScrollableAnchor from 'utils/react-scrollable-anchor';
import { styles } from './jump-button-styles';

const Anchor = ({ id, children, classes }) => (
  <>
    <ScrollableAnchor id={id}>
      <span className={classes.anchor} />
    </ScrollableAnchor>
    {children}
  </>
);

export default withStyles(styles, { withTheme: true })(Anchor);

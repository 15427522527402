import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TypedHttp } from 'services/typed-http';

export const NAME = 'pharmacies';

export interface IPharmacy {
  id: number;
  name: string;
  npi: string;
  is_courier_integrated: boolean;
  is_ups_integrated: boolean;
  courier_name: string | null;
}

export interface IPharmacyState {
  pharmacies: IPharmacy[];
}

const initialState: IPharmacyState = { pharmacies: [] };

export const loadPharmacies = createAsyncThunk(`${NAME}/load`, async (customerId: number) => {
  const request = TypedHttp.Get<{ dispensingPharmacies: IPharmacy[] }>(
    `/customers/${customerId}/pharmacies`,
  );
  const response = await request;
  if (response.data) {
    return response.data;
  }
  return undefined;
});

const pharmaciesSlice = createSlice({
  name: NAME,
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadPharmacies.fulfilled, (state, action) => {
      if (action.payload && action.payload.dispensingPharmacies) {
        state.pharmacies = action.payload.dispensingPharmacies;
      }
    });
  },
});

export const pharmaciesActions = pharmaciesSlice.actions;
export const pharmaciesReducer = pharmaciesSlice.reducer;

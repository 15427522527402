export const styles = theme => ({
  scheduleListTitle: {
    color: theme.palette.primary.pageHeader,
    fontSize: theme.font.extraLargeFontSize,
    fontWeight: theme.font.weight.thick,
  },
  // hack to show scrollbar by setting a specific width.
  // this adds the horizontal scroll bar we need but doesn't take the 1200px into account.
  tableContainer: {
    width: 1200,
    minWidth: '100%',
  },
  addArButton: {
    'backgroundColor': theme.palette.primary.light,
    'color': theme.palette.primary.contrastText,
    'padding': theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    '&:disabled': {
      color: theme.palette.primary.white,
      opacity: 0.5,
    },
  },
  scheduleButton: {
    'backgroundColor': theme.palette.primary.dark,
    'color': theme.palette.primary.contrastText,
    'padding': theme.spacing(2),
    'marginRight': '10px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },

  scheduleListTable: {
    marginBottom: '1rem',
    fontFamily: "'Gotham SSm A', 'Gotham SSm B', Verdana, Geneva, Tahoma, sans-serif",
    padding: theme.spacing(0),
  },
  scheduleListTableContainer: {
    'overflow': 'scroll',
    'maxHeight': '60vh',
    '& thead th': {
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      zIndex: 1,
      padding: 0,
    },
  },
  scheduleListTableMain: {
    'minWidth': '100%',
    'borderSpacing': 0,
    'border': `1px solid ${theme.palette.primary.grey13}`,
    '& tr': {
      'fontSize': '0.8125rem',
      '& td': {
        borderTop: `0.1px solid ${theme.palette.primary.grey13}`,
      },
    },
  },
  scheduleListHeaderTr: {
    'minWidth': '100%',
    'display': 'table',
    '& th > input': {
      padding: theme.spacing(0),
      textIndent: theme.spacing(1),
    },
    'paddingTop': theme.spacing(0.8),
  },
  scheduleListHeaderTh: {
    fontWeight: 300,
    fontSize: theme.spacing(1.75),
    minWidth: '100%',
    height: theme.spacing(3),
  },
  scheduleListMainTbody: {
    '& tr:nth-child(odd)': {
      backgroundColor: theme.palette.primary.grey14,
    },
    '& tr': {
      height: theme.spacing(4.5),
    },
    '& td': {
      'position': 'relative',
      'borderRight': `1px solid ${theme.palette.primary.grey13}`,
      '& :last-child': {
        borderRight: 0,
      },
      '& td.resizer': {
        display: 'inline-block',
        background: theme.palette.primary.steelGrey,
        width: '10px',
        height: '100%',
        top: 0,
        transform: 'translateX(10%)',
        zIndex: 1,
        touchAction: 'none',
      },
    },
  },
  scheduleListTbodyTr: {
    textIndent: theme.spacing(1),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

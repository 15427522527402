import React from 'react';
import { Typography, Select, MenuItem, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { styles } from './field-styles';

function StaticDropdown({ classes, fieldName, field, defaultValue, variant }) {
  let value = field ? field.toString() : defaultValue;
  let styleDef = {

  };
  switch (variant) {
    case 'raw':
      break;
    default:
      value = value && value.toLowerCase();
      styleDef = { textTransform: 'capitalize' };
      break;
  }

  const labelValue = value || '-';

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '75%',
      }}
    >
      {fieldName && (
        <Typography variant="caption" className={classes.fieldLabel}>
          {fieldName}
        </Typography>
      )}
      <Tooltip
        placement="bottom-start"
        title={
          <div style={{ ...styleDef, fontSize: '12px' }}>
            {labelValue}
          </div>
        }
      >
        <Select value={-1} disabled variant="standard">
          <MenuItem value={-1}>
            <div
              style={{
                ...styleDef,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {labelValue}
            </div>
          </MenuItem>
        </Select>
      </Tooltip>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(StaticDropdown);

import { IAddEmailModal } from 'interfaces/redux/IAddEmailModal';

export enum AddEmailModalActionType {
  SetState = 'ADD_EMAIL_MODAL_STATE',
  ResetModal = 'ADD_EMAIL_MODAL_RESET',
}

export interface IAddEmailModalAction {
  type: AddEmailModalActionType;
  payload: IAddEmailModal;
}

export const addEmailModalActions = {
  setState: (state: IAddEmailModal): IAddEmailModalAction => {
    return {
      type: AddEmailModalActionType.SetState,
      payload: state,
    };
  },
  resetModal: () => {
    return {
      type: AddEmailModalActionType.ResetModal,
    };
  },
};

import { makeStyles } from '@mui/styles';
import { defaultTheme } from '../../../../lib/themes';

export const useStyles = makeStyles(() => {
  return {
    icon: {
      paddingLeft: 5,
      fontSize: defaultTheme.font.mediumFontSize,
    },
    tooltipCustomWidth: {
      maxWidth: 150,
    },
  };
});

import { Action } from 'redux';
import { AxiosResponse } from 'axios';
import HTTP from '../services/http';

import {
  FETCH_SPECIAL_POPULATIONS_SUCCESS,
  ADD_SPECIAL_POPULATION,
  EDIT_SPECIAL_POPULATION,
  VERIFY_SPECIAL_POPULATIONS,
  MARK_PATIENT_CONFIRMED_SPECIAL_POPULATIONS,
} from '../constants/index';

import {
  ISpecialPopulation,
  ISpecialPopulationState,
} from '../interfaces/redux/ISpecialPopulation';

export const addSpecialPopulation = (
  specialPopulation: ISpecialPopulation,
): SpecialPopulationAction => {
  const request = HTTP.post(
    `/patients/${specialPopulation.patient_id}/special_populations`,
    specialPopulation,
    {},
  );

  return {
    type: ADD_SPECIAL_POPULATION,
    payload: request as any,
  };
};

export const updateSpecialPopulation = (specialPopulation: any[]) => {
  return {
    type: ADD_SPECIAL_POPULATION,
    payload: specialPopulation as any,
  };
};
export const editSpecialPopulation = (
  specialPopulation: ISpecialPopulation,
): SpecialPopulationAction => {
  const request = HTTP.patch(
    `/patients/${specialPopulation.patient_id}/special_populations/${specialPopulation.id}`,
    specialPopulation,
    {},
  );

  return {
    type: EDIT_SPECIAL_POPULATION,
    payload: request as any,
  };
};

export const verifySpecialPopulations = (verifyRequest: any): SpecialPopulationAction => {
  const request = HTTP.post(
    `/patients/${verifyRequest.patient_id}/special_populations/_verify`,
    verifyRequest,
    {},
  );

  return {
    type: VERIFY_SPECIAL_POPULATIONS,
    payload: request as any,
  };
};

export const markPatientConfirmedSpecialPopulations = (
  patientId: any,
  markConfirmed: any,
): SpecialPopulationAction => {
  const request = HTTP.post(
    `/patients/${patientId}/special_populations/_mark_patient_confirmed`,
    { confirmed: markConfirmed },
    {},
  );

  return {
    type: MARK_PATIENT_CONFIRMED_SPECIAL_POPULATIONS,
    payload: request as any,
  };
};

export class FetchSpecialPopulations implements Action {
  readonly type = FETCH_SPECIAL_POPULATIONS_SUCCESS;

  constructor(public payload: ISpecialPopulationState) {}
}

export class AddSpecialPopulation implements Action {
  readonly type = ADD_SPECIAL_POPULATION;

  constructor(public payload: ISpecialPopulationState) {}
}

export class EditSpecialPopulation implements Action {
  readonly type = EDIT_SPECIAL_POPULATION;

  constructor(public payload: ISpecialPopulationState) {}
}

export class VerifySpecialPopulation implements Action {
  readonly type = VERIFY_SPECIAL_POPULATIONS;

  constructor(public payload: ISpecialPopulationState) {}
}

export class MarkPatientConfirmedSpecialPopulation implements Action {
  readonly type = MARK_PATIENT_CONFIRMED_SPECIAL_POPULATIONS;

  constructor(public payload: any) {}
}

export type SpecialPopulationAction =
  | FetchSpecialPopulations
  | AddSpecialPopulation
  | EditSpecialPopulation
  | VerifySpecialPopulation
  | MarkPatientConfirmedSpecialPopulation;

import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { expandTheme } from 'utils/theme-util';
import { combineStyles } from 'services/utils/styles-service';
import { ModalUtil } from 'services/utils/modal.util';
import { BaseTableStyles } from '../../base-table/base-table.styles';

export const TableStyles = (theme: Theme) => {
  const { font, palette } = expandTheme(theme);

  return createStyles({
    expansionHeader: {
      paddingTop: theme.spacing(1.5),
      paddingLeft: theme.spacing(1),
    },
    expansionHeaderTextLeft: {
      fontSize: font.largeFontSize,
      fontWeight: 'bold',
      color: palette.primary.steelGrey,
      paddingLeft: theme.spacing(1),
    },
    expansionHeaderTextRight: {
      fontSize: font.smallFontSize,
      color: palette.primary.steelGrey,
      paddingTop: theme.spacing(1.2),
    },
    expansionHeaderSwitch: {},
    expansionHeaderExpandIcon: {
      marginLeft: theme.spacing(0.5),
    },
    expansionPanelStyles: {
      borderBottom: `1px solid ${palette.primary.grey1}`,
      boxShadow: theme.shadows[0],
    },
    expansionDetails: {
      padding: 0,
    },
    tableContainer: {
      marginTop: 0,
    },
    rightContainer: {
      justifyItems: 'right',
      marginLeft: 50,
    },
    modalButton: {
      marginBottom: theme.spacing(1.5),
    },
    confirmationModal: {
      ...ModalUtil.BasicModalStyling(theme, 50, 3),
      maxHeight: '60vh',
    },
    confirmationModalDrugItem: {
      maxHeight: '50vh',
      overflowY: 'auto',
      paddingBottom: theme.spacing(4),
    },
    modalDrugRow: {
      paddingBottom: theme.spacing(1),
    },
    modalHeader: {
      fontWeight: 'bold',
      fontSize: font.mediumFontSize,
    },
    actionButton: {
      backgroundColor: palette.primary.trellisBlue,
      color: palette.primary.white,
    },
    submittingIndicator: {
      marginLeft: theme.spacing(1),
      color: palette.primary.white,
    },
  });
};

const styles = combineStyles(BaseTableStyles, TableStyles);
export const UseStyles = makeStyles(theme => styles(theme));
export { styles };

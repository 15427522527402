import { useTypedSelector } from 'hooks/use-typed-selector';
import React from 'react';
import { buildQaId } from 'utils/build-qa-id';
import { NoteMethodIcon } from 'components/icons/icons';
import { Tooltip } from '@mui/material';
import { useStyles } from './preferred-contact.styles';

const getQaId = buildQaId('preferred-contact-method-note');

export const PreferredContactMethodNote: React.FC = (): JSX.Element | null => {
  const classes = useStyles();

  const patient = useTypedSelector(state => state.patient);

  const methodNote = React.useMemo<string | undefined>(() => {
    if (patient?.contact_method_note) {
      return patient.contact_method_note;
    }
    return undefined;
  }, [patient]);

  return patient != null && methodNote != null ? (
    <>
      <Tooltip title={methodNote} className={classes.icon} data-qa-id={getQaId('tooltip')}>
        <NoteMethodIcon data-qa-id={getQaId('icon')} className={classes.icon} title={methodNote} />
      </Tooltip>
    </>
  ) : null;
};

import { CounselingStatusMap } from 'constants/task-statuses';
import {
  CounselingStatus,
  CannotTransitionMap,
} from 'interfaces/enums/TaskStatuses/CounselingStatus';
import { ITask } from 'interfaces/redux/ITasks';
import { TaskStatus } from 'interfaces/enums/TaskStatus';
import { ICounselingTask } from 'interfaces/redux/task-types/ICounselingTask';
import { ITaskStatusProcessor } from '../interfaces/TaskStatusProcessor';

class CounselingProcessor implements ITaskStatusProcessor {
  // old = completed before that Protocol driven counseling exist.
  private isOldTask(task: ITask): boolean {
    return (
      Boolean((task as ICounselingTask).completed_details) &&
      !(task as ICounselingTask).readonly &&
      task.status_id === CounselingStatus.Completed
    );
  }

  taskIsEditable(task: ITask): boolean {
    return true;
  }

  taskShouldRenderCustomForm(task: ITask): boolean {
    return !this.isOldTask(task);
  }

  groupTasksByStatus(tasks: ITask[]): Record<string, ITask[]> {
    const initialGroups = {
      pending: [],
      completed: [],
      oldCompleted: [],
    };

    return tasks.reduce((accum: Record<string, ITask[]>, task: ITask) => {
      if (this.isOldTask(task)) {
        accum.oldCompleted.push(task);
      } else if (this.taskIsComplete(task)) {
        accum.completed.push(task);
      } else {
        accum.pending.push(task);
      }
      return accum;
    }, initialGroups);
  }

  taskShouldRenderCustomMultipleForms(tasks: ITask[]): boolean {
    const groups = this.groupTasksByStatus(tasks);
    // If more than 1 groups has elements mean it should render multiple forms
    const groupsWithTasks = Object.keys(groups).filter(key => groups[key].length);
    return Boolean(groupsWithTasks.length > 1);
  }

  taskIsReadOnly(task: ITask): boolean {
    if (!this.isOldTask(task)) {
      const taskStatus = CounselingStatusMap[task.status_id];
      return Boolean(taskStatus && taskStatus.readonly);
    }
    return false;
  }

  // Currently complete and readonly are the same thing for counseling. May change later.
  taskIsComplete(task: ITask): boolean {
    return this.taskIsReadOnly(task);
  }

  taskNeedsReview(task: ITask): boolean {
    const taskStatus = CounselingStatusMap[task.status_id];
    return taskStatus.status === TaskStatus.PharmacistReviewNeeded;
  }

  taskCanTransition(task: ITask): boolean {
    return !CannotTransitionMap[task.status_id];
  }

  taskTransitioningToComplete(prevTask: ITask, _currentTask: ITask): boolean {
    const mapValue = CounselingStatusMap[prevTask.status_id];
    if (
      mapValue &&
      mapValue.nextStatus &&
      CounselingStatusMap[mapValue.nextStatus]?.status === TaskStatus.Completed
    ) {
      return true;
    }
    return false;
  }
}

export { CounselingProcessor };

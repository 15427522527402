import React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 600,
    margin: '0 auto',
    padding: '0 30px',
    boxSizing: 'border-box',
    [`${theme.breakpoints.down('xl')} and (orientation: landscape)`]: {
      maxWidth: 800,
    },
  },
}));

export default ({ children, className }) => {
  const classes = useStyles();
  return <div className={cx(classes.root, className)}>{children}</div>;
};

import React from 'react';
import { reports as reportList } from 'constants/lists';
import { TableHead, TableCell, TableRow } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useSelector } from 'react-redux';
import { styles } from '../side-bar-styles';

const ReportListHeader = props => {
  const { classes } = props;

  const reports = useSelector(state => state.reports);
  const { reportSidebar } = reports;

  let header = 'N/A';
  const report = reportList.find(r => r.value === reportSidebar.reportId);
  if (report) {
    header = `${report.label} - ${reportSidebar.reportLabel}`;
  }
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tableHeader}>{header}</TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default withStyles(styles, { withTheme: true })(ReportListHeader);

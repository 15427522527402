import { Typography, Button, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ToggleLeftDrawer } from 'actions/action-view';
import { ReactComponent as FilterIcon } from 'lib/logos/Filter.svg';
import { styles } from '../side-bar-styles';
import ScheduleList from './schedule-list';
import ScheduleFilterPanel from './schedule-filter-panel';

import { CollapsibleSideBar } from '../collapsible-side-bar';

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => WrappedComponent => props => <WrappedComponent {...props} width="xs" />;

class ScheduleSideBar extends Component {
  constructor(props) {
    super(props);
    this.handleToggleFilterPanel = this.handleToggleFilterPanel.bind(this);
    this.state = {
      displayFilterPanel: false,
    };
  }

  handleToggleFilterPanel() {
    const { displayFilterPanel } = this.state;
    this.setState({
      displayFilterPanel: !displayFilterPanel,
    });
  }

  render() {
    const { classes, onToggleDrawer, isLeftDrawerOpen, width } = this.props;
    const { displayFilterPanel } = this.state;
    const filterButtonClasses = displayFilterPanel
      ? classes.filterButtonChecked
      : classes.filterButtonUnchecked;
    const filterButtonIconClasses = displayFilterPanel
      ? classes.filterButtonIconChecked
      : classes.filterButtonIconUnchecked;
    const drawerClasses = displayFilterPanel
      ? classes.drawerInternalDivContainerFilter
      : classes.drawerInternalDivContainer;
    const drawerInternal = (
      <div className={drawerClasses}>
        <Grid container direction="row">
          <Grid item xs={8}>
            <Typography variant="h6" className={classes.taskTitle}>
              Schedule
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              id="filterPanelButton"
              name="filter_panel_button"
              variant="outlined"
              size="small"
              onClick={this.handleToggleFilterPanel}
              className={filterButtonClasses}
            >
              <FilterIcon className={filterButtonIconClasses} />
            </Button>
          </Grid>
        </Grid>
        <Switch>
          {displayFilterPanel ? (
            <Route component={ScheduleFilterPanel} />
          ) : (
            <Route component={ScheduleList} />
          )}
        </Switch>
      </div>
    );

    return (
      <CollapsibleSideBar
        classes={classes}
        isLeftDrawerOpen={isLeftDrawerOpen}
        width={width}
        onToggleDrawer={onToggleDrawer}
      >
        {drawerInternal}
      </CollapsibleSideBar>
    );
  }
}

function mapStateToProps(state) {
  const { tasklistSort, patient, tabControl, view, filters } = state;
  const { patientTab } = tabControl;
  return {
    tasklistSort,
    patient,
    patientTab,
    isLeftDrawerOpen: view.leftDrawer,
    filters,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onToggleDrawer: state => dispatch(new ToggleLeftDrawer(state)),
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withWidth(),
)(ScheduleSideBar);

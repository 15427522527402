import {
  DATA_DC_PROTOCOLS_REQUEST,
  DATA_DC_PROTOCOLS_SUCCESS,
  DATA_DC_PROTOCOLS_FAILURE,
  DATA_DC_PROTOCOLS_RESET,
  SELECTED_PATIENT_ID,
} from 'constants/index';
import { getReducerDataInitialState } from './helper-reducer';

const initialState = getReducerDataInitialState();

export default (state = initialState, action) => {
  const { type, payload, meta } = action;
  switch (type) {
    case SELECTED_PATIENT_ID:
      return {
        loading: false,
        loaded: true,
        data: {},
      };
    case DATA_DC_PROTOCOLS_REQUEST: {
      const { taskId } = meta;
      return {
        loading: true,
        loaded: false,
        data: {
          ...state.data,
          [taskId]: state.data[taskId] || {},
        },
      };
    }
    case DATA_DC_PROTOCOLS_SUCCESS: {
      const { taskId, protocols } = payload;
      return {
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          [taskId]: protocols || {},
        },
      };
    }
    case DATA_DC_PROTOCOLS_FAILURE: {
      const { taskId } = payload;
      return {
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          [taskId]: {},
        },
        error: true,
      };
    }
    case DATA_DC_PROTOCOLS_RESET: {
      return {
        loading: false,
        loaded: true,
        data: {},
      };
    }
    default:
      return state;
  }
};

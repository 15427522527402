import React, { useCallback } from 'react';
import useProvider from 'hooks/useProvider';
import TreeSelect from '../../form/tree-select/tree-select';

export default ({ providers, field, label, input, meta, disabled, labelSize, qaId }) => {
  const fields = useProvider(providers, field.provider, []);
  const handleBlur = useCallback(() => {
    input.onBlur();
  }, [input]);

  return (
    <TreeSelect
      onChange={input.onChange}
      onBlur={handleBlur}
      value={input.value}
      watchValue
      isSuggestedOptions={false}
      multi
      label={label}
      meta={meta}
      disabled={disabled}
      labelSize={labelSize}
      qaId={qaId}
      treeData={fields}
      suggestionLabel={field.suggestionLabel}
      title={field.label}
    />
  );
};

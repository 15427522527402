export enum EditorFeature {
  Bold = 'BOLD',
  Italic = 'ITALIC',
  Underline = 'UNDERLINE',
  Strikethrough = 'STRIKETHROUGH',
  OrderedList = 'ordered-list-item',
  UnorderedList = 'unordered-list-item',
}

export enum EditorHandleValue {
  Unhandled = 'not-handled',
  Handled = 'handled',
}

export enum EditorValueFormat {
  Html = 'html',
  PlainText = 'plain',
  DraftJsObject = 'draftjsobject',
}

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  poweredBy: {
    fontSize: 13,
    position: 'absolute',
    bottom: 20,
    width: '100%',
    left: 0,
    [`${theme.breakpoints.down('xl')} and (orientation: landscape)`]: {
      bottom: 10,
    },
  },
}));

export default () => {
  const classes = useStyles();
  return <div className={classes.poweredBy}>Powered by Trellis</div>;
};

import { takeLatest, call, put, select, takeEvery } from 'redux-saga/effects';
import { convertToArborDate } from 'models/time/arbor-date';
import { RSTypeEnum } from 'constants/enums';
import {
  DATA_DC_PROTOCOLS_REQUEST,
  DATA_DC_PROTOCOLS_SUCCESS,
  DATA_DC_PROTOCOLS_FAILURE,
  DATA_DC_PROTOCOLS_RESET,
  RESET_DC_PROTOCOLS,
  FETCH_DATA_DC_PROTOCOLS,
  PERIOD_UNITS,
} from '../constants';
import HTTP from '../services/http';

export function* workerFetchDcProtocols({ payload }) {
  const { selectedPatientId, patient } = yield select();
  let icdToUse = null;
  if (payload.rsType !== null) {
    if (payload.rsType === RSTypeEnum.Primary && payload.therapy.diagnosis_code) {
      icdToUse = payload.therapy.diagnosis_code;
    } else if (
      payload.rsType === RSTypeEnum.Secondary &&
      payload.therapy.secondary_diagnosis_code
    ) {
      icdToUse = payload.therapy.secondary_diagnosis_code;
    } else if (payload.rsType === RSTypeEnum.Tertiary && payload.therapy.tertiary_diagnosis_code) {
      icdToUse = payload.therapy.tertiary_diagnosis_code;
    }
  }
  if (selectedPatientId) {
    const url = '/pmp/therapies/protocols';
    const request = HTTP.post(url, {
      patientId: selectedPatientId,
      therapyId: payload.therapy.id,
      icd10:
        payload.rsType === null && icdToUse === null ? payload.therapy.diagnosis_code : icdToUse,
      gpi10: payload.therapy.gpi ? payload.therapy.gpi.substr(0, 10) : null,
      gender: patient.gender,
      age: convertToArborDate(patient.dob, true).getYearsFromNow(),
      clinicalData: payload.collectedData,
    });

    yield put({
      type: DATA_DC_PROTOCOLS_REQUEST,
      payload: request,
      meta: { patientId: selectedPatientId, taskId: payload.taskId },
    });

    try {
      const response = yield call(() => request);
      const protocols = {
        recommendationTherapies: response.data.recommendationTherapies.map(
          recommendationTherapy => {
            const gpi10 = [];
            const name = [];
            Object.keys(recommendationTherapy).forEach(key => {
              const duration = [];
              if (recommendationTherapy[key].durationMin) {
                duration.push(recommendationTherapy[key].durationMin);
              }
              if (recommendationTherapy[key].durationMax) {
                duration.push(recommendationTherapy[key].durationMax);
              }
              const recommendedTherapy =
                duration.length === 0
                  ? recommendationTherapy[key].name
                  : `${recommendationTherapy[key].name} for ${duration.join(' - ')} ${
                      PERIOD_UNITS[recommendationTherapy[key].durationUnit] || ''
                    }`;
              gpi10.push(recommendationTherapy[key].gpi10);
              name.push(recommendedTherapy);
            });
            return {
              gpi10: gpi10.join('+'),
              name: name.join(' + '),
              planId: recommendationTherapy.length ? recommendationTherapy[0].planId : null,
            };
          },
        ),
        efficacyProtocols: response.data.efficacyProtocols,
        safetyProtocols: response.data.safetyProtocols,
      };
      yield put({
        type: DATA_DC_PROTOCOLS_SUCCESS,
        payload: { protocols, taskId: payload.taskId },
      });
    } catch (e) {
      yield put({ type: DATA_DC_PROTOCOLS_FAILURE, payload: { taskId: payload.taskId } });
    }
  }
}

export function* workerResetDcprotocols() {
  yield put({ type: DATA_DC_PROTOCOLS_RESET, payload: {} });
}

export function* watcherDcProtocolsSaga() {
  yield takeEvery(FETCH_DATA_DC_PROTOCOLS, workerFetchDcProtocols);
  yield takeLatest(RESET_DC_PROTOCOLS, workerResetDcprotocols);
}

import React from 'react';
import HTTP from 'services/http';
import { Modal, Button, Grid, Typography, CircularProgress } from '@mui/material';
import { getModalStyle } from 'services/utils/styles-service';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { useDispatch } from 'react-redux';
import { UseStyles } from '../table.styles';
import { IModalState } from '../../types';

const baseUrl = '/application-manager/ctd';
const initialModalState: IModalState = {
  open: false,
  submitting: false,
};

export const TherapeuticModal = (props: {
  open: boolean;
  selectedCustomerId: number;
  onCancel: () => void;
  afterSuccess: () => void;
  id: number;
  included: boolean;
  text: string;
}): JSX.Element | null => {
  const classes: any = UseStyles();
  const dispatch = useDispatch();
  const [modalState, setModalState] = React.useState<IModalState>(initialModalState);

  const onConfirm = async () => {
    const url = `${baseUrl}/include-in-contract/${props.selectedCustomerId}/${props.id}`;
    try {
      setModalState({ open: true, submitting: true });
      await HTTP.put(url, { isExcluded: !props.included });
      props.afterSuccess();
      dispatch(notifySuccess('Saved'));
    } catch (_error) {
      dispatch(notifyError('An error occurred'));
    } finally {
      setModalState(initialModalState);
    }
  };

  return (
    <Modal open={props.open}>
      <div style={getModalStyle()} className={classes.confirmationModal}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs="auto">
            <Typography className={classes.modalHeader}>{props.text}</Typography>
          </Grid>
          <Grid item xs="auto">
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item xs="auto">
                <Button
                  onClick={() => {
                    props.onCancel();
                  }}
                  disabled={modalState.submitting}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs="auto">
                <Button
                  variant="contained"
                  className={classes.actionButton}
                  onClick={() => {
                    onConfirm();
                  }}
                  disabled={modalState.submitting}
                >
                  Confirm
                  {modalState.submitting ? (
                    <CircularProgress
                      variant="indeterminate"
                      size={20}
                      className={classes.submittingIndicator}
                    />
                  ) : null}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

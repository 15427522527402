import React, { useState, useCallback } from 'react';
import { compose } from 'recompose';
import { Typography, Grid, Button, Box } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import useEventListener from 'hooks/useEventListener';
import { styles } from '../document-bar-styles';

const SelectFileForm = props => {
  const {
    successMessage,
    errorMessage,
    errorCaption,
    classes,
    handleAcceptedDrop,
    handleRejectedDrop,
    acceptedFileExtensions,
    handleFilePaste,
    style,
    dropAreaText = 'Drag a File, or hover mouse and paste using (ctrl+v) to upload screenshot saved to clipboard.',
  } = props;

  const dropZoneConfiguration = {
    onDropAccepted: handleAcceptedDrop,
    onDropRejected: handleRejectedDrop,
  };

  const [focused, setFocused] = useState(false);

  if (acceptedFileExtensions) {
    dropZoneConfiguration.accept = acceptedFileExtensions;
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone(dropZoneConfiguration);

  const renderSuccessMessage = () =>
    successMessage && <h3 className={classes.uploadSuccess}>{successMessage}</h3>;

  const renderErrorMessage = () =>
    errorMessage && <h3 className={classes.uploadError}>{errorMessage}</h3>;

  const renderErrorCaption = () =>
    errorMessage && errorCaption && <Typography variant="caption">{errorCaption}</Typography>;

  const pasteCallback = useCallback(event => handleFilePaste(event), []);

  const mouseEnter = () => {
    setFocused(true);
  };

  const mouseLeave = () => {
    setFocused(false);
  };

  useEventListener('paste', pasteCallback);

  return (
    <div onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      {renderSuccessMessage()}
      {renderErrorMessage()}
      {renderErrorCaption()}
      <section>
        <Box
          {...getRootProps()}
          className={classNames(classes.uploadContainer, {
            [classes.uploadContainerDragging]: isDragActive,
            [classes.uploadContainerDragging]: focused,
          })}
          style={style}
        >
          <input {...getInputProps()} data-qa-id="select-file-form-input" />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="file-upload-container-inner"
          >
            <Grid item xs={9} className={classes.selectFileText}>
              {dropAreaText}
            </Grid>
            <Grid
              item
              className={classNames([classes.selectFileButton, 'select-file-button'])}
              component={Button}
              size="large"
            >
              Select a file
            </Grid>
          </Grid>
        </Box>
      </section>
    </div>
  );
};

export default compose(withStyles(styles))(SelectFileForm);

import React from 'react';
import { styled } from '@mui/material/styles';
import withTheme from '@mui/styles/withTheme';
import { Grid, Divider, Button, Popover } from '@mui/material';
import TalkdeskPhoneNumber from 'components/talkdesk-phone-number/talkdesk-phone-number';
import {
  SMS_STATUS_PENDING,
  SMS_STATUS_OPENED,
  SUBMITTED_BY_ARBOR_USER,
  SUBMITTED_BY_PATIENT,
} from 'constants/index';
import { convertToArborDate } from '../../../models/time/arbor-date';

const FLEX_START = 'flex-start';

const Box = styled(withTheme(Grid))(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
  maxWidth: 700,
  padding: 25,
}));

const Title = styled(withTheme(Grid))(({ theme }) => ({
  display: 'flex',
  justifyContent: FLEX_START,
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: 17,
  paddingBottom: 15,
  color: theme.palette.primary.steelGrey,
}));

const Subtitle = styled(withTheme(Grid))(({ theme }) => ({
  display: 'flex',
  justifyContent: FLEX_START,
  alignItems: 'center',
  color: theme.palette.primary.grey12,
  fontSize: 12,
  marginBottom: 8,
}));

const Container = styled(withTheme(Grid))({
  display: 'flex',
  marginBottom: 20,
});

const Column = styled(withTheme(Grid))(({ flex, marginBottom }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: flex || 0,
  marginBottom: marginBottom || 0,
}));

const QuestionnaireContent = styled(withTheme(Grid))(({ theme }) => ({
  display: 'flex',
  justifyContent: FLEX_START,
  textAlign: 'left',
  color: theme.palette.primary.steelGrey,
}));

const Content = styled(withTheme(Grid))(({ theme }) => ({
  display: 'flex',
  justifyContent: FLEX_START,
  textAlign: 'left',
  paddingBottom: 10,
  paddingTop: 8,
  width: '100%',
  color: theme.palette.primary.steelGrey,
}));

const Status = styled(Content)(() => ({
  textTransform: 'uppercase',
}));

const LineDivider = styled(Divider)(({ marginBottom }) => ({
  marginRight: 10,
  height: 2,
  marginBottom: marginBottom || 27,
}));

const ActionButtons = styled(withTheme(Grid))(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

const MainButton = styled(withTheme(Button))(({ theme }) => ({
  backgroundColor: theme.palette.primary.teal,
  color: theme.palette.primary.white,
  marginRight: 10,
}));

export default function StyledComponents(props) {
  const {
    sentTo,
    date,
    phoneNumber,
    questionnaire,
    status,
    info,
    messageSent,
    anchorEl,
    onClose,
    open,
    id: popoverId,
    onSendReminder,
    onDeleteSms,
    questionnaireSubmittedDate,
    questionnaireStatus,
    isQuestionnaireSubmitted,
    submittedBy,
  } = props;

  const qStatus = () => {
    if (isQuestionnaireSubmitted) {
      if (!submittedBy) return SUBMITTED_BY_PATIENT;
      return SUBMITTED_BY_ARBOR_USER;
    }
    return questionnaireStatus;
  };

  return (
    <Popover
      id={popoverId}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      onClick={e => {
        e.stopPropagation();
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box>
        <Title>Send DC to Patient</Title>
        <LineDivider />
        <Container>
          <Column data-qa-id flex={2.5}>
            <Subtitle>Questionnaire(s)</Subtitle>
            {questionnaire &&
              questionnaire.map(item => (
                <QuestionnaireContent data-qa-id="qaQuestionnaireContent">
                  {item}
                </QuestionnaireContent>
              ))}
          </Column>
          <Column flex={2}>
            <Subtitle>Sent to</Subtitle>
            <Content data-qa-id="qaSentTo">{sentTo || '-'}</Content>
          </Column>
          <Column flex={2}>
            <Subtitle>Phone Number</Subtitle>
            <Content data-qa-id="qaPhoneNumber">
              <TalkdeskPhoneNumber number={phoneNumber} />
            </Content>
          </Column>
        </Container>
        <Container>
          <Column flex={2.5}>
            <Subtitle>Status</Subtitle>
            <Status data-qa-id="qaSmsStatus">{status || '-'}</Status>
          </Column>
          <Column flex={2}>
            <Subtitle>Date</Subtitle>
            <Content data-qa-id="qaDateSms">
              {convertToArborDate(date).getCustomerDate(true) || '-'}
            </Content>
          </Column>
          <Column flex={2}>
            <Subtitle>Info</Subtitle>
            <Content data-qa-id="qaInfo">{info || '-'}</Content>
          </Column>
        </Container>
        <Container>
          <Column flex={2.5}>
            <Subtitle>Questionnaire Status</Subtitle>
            <Status data-qa-id="qaQuestionnaireStatus">{qStatus()}</Status>
          </Column>
          <Column flex={2}>
            <Subtitle>Date</Subtitle>
            <Content data-qa-id="qaQuestionnaireDate">{questionnaireSubmittedDate || '-'}</Content>
          </Column>
          <Column flex={2}>
            <Subtitle>Info</Subtitle>
            <Content data-qa-id="qaInfo">{info || '-'}</Content>
          </Column>
        </Container>
        <Column marginBottom={30}>
          <Subtitle>Message Sent</Subtitle>
          <Content data-qa-id="qaMessageSent">{messageSent}</Content>
        </Column>
        <LineDivider marginBottom={15} />
        {(qStatus() === SMS_STATUS_PENDING || qStatus() === SMS_STATUS_OPENED) && (
          <ActionButtons>
            <MainButton data-qa-id="qaDeleteButton" onClick={onDeleteSms}>
              Delete
            </MainButton>
            <MainButton data-qa-id="qaSendReminderButton" onClick={onSendReminder}>
              Send Reminder
            </MainButton>
          </ActionButtons>
        )}
      </Box>
    </Popover>
  );
}

import React, { useEffect, useCallback, useMemo, useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import compose from 'recompose/compose';
import { useSelector, useDispatch } from 'react-redux';
import {
  Modal,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TableHead,
  TableSortLabel,
  IconButton,
} from '@mui/material';
import { sortBy } from 'lodash';
import { globalZIndex } from 'constants/z-index';
import { AssignmentTurnedIn, Edit, Error as ErrorIcon } from '@mui/icons-material';
import { styles } from './accreditation.styles';
import { notifyError } from '../../actions/action-notifications';
import {
  fetchPendingAccreditationsList,
  fetchCompletedAccreditationsList,
} from '../../actions/action-accreditations';
import { questionnaireMapper } from './questionnaires/questionnaire_mapper';
import { useColors } from './components/question.styles';
import { AnswerChip } from './components/answers';
import { formatDate } from './utils';
import { FETCH_ERROR_MESSAGE } from './use-questionnaire-state';

const buttonTextLookup = {
  not_started: 'Edit',
  in_progress: 'Edit',
  submitted: 'View',
  rejected: 'Edit',
  completed: 'View',
};

const headItems = {
  not_started: [
    {
      label: 'Name',
      property: 'name',
    },
    {
      label: 'Status',
      property: 'status',
    },
    {
      label: 'Due Date',
      property: 'dueDate',
    },
    {
      label: 'Submitted Date',
      property: 'submittedDate',
    },
  ],
  completed: [
    {
      label: 'Name',
      property: 'name',
    },
    {
      label: 'Submitted Date',
      property: 'submittedDate',
    },
    {
      label: 'Submitted By',
      property: 'submittedBy',
    },
  ],
};

const getStatusDescriptor = ({ status, due_date_dt }, listType = 'not_started') => {
  const sdp = status.replace('_', ' ');
  const overdue = listType === 'not_started' && new Date(due_date_dt) < new Date();
  let statusDescriptor = `${sdp[0]?.toUpperCase()}${sdp?.substring(1)}`;
  if (overdue && status === 'not_started') {
    statusDescriptor = 'Due';
  } else if (status === 'submitted') {
    statusDescriptor = 'In Review';
  } else if (status === 'in_progress') {
    statusDescriptor = 'In Progress';
  }
  return statusDescriptor;
};

const getComparator = (property, order) => {
  const pushToEnd = order === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
  switch (property) {
    case 'submittedDate':
      return q =>
        ['submitted', 'completed', 'rejected'].includes(q.status)
          ? new Date(q.saved_dt)
          : pushToEnd;
    case 'dueDate':
      return q => new Date(q.due_date_dt);
    case 'status':
      return q => getStatusDescriptor(q);
    default:
      return q => q[property];
  }
};

const getSortedQuestionnaires = (list, order, orderBy) => {
  const sortedItems = sortBy(list, getComparator(orderBy, order));
  return order === 'asc' ? sortedItems : sortedItems.reverse();
};

function QuestionnaireList(props) {
  const { classes } = props;
  const dispatch = useDispatch();
  const customerId = useSelector(state => state.filters.selectedCustomerId);
  const [modalOpen, setModalOpen] = useState(false);
  const [listType, setListType] = useState('not_started');
  const [loadingQuestionnaireList, setLoadingQuestionnaireList] = useState(true);
  const [questionnaireList, setQuestionnaireList] = useState([]);
  const [currentQuestionnaire, setCurrentQuestionnaire] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('dueDate');

  const doFetchQuestionnaires = useCallback(
    (instanceType = 'not_started') => {
      setLoadingQuestionnaireList(true);
      const method =
        instanceType === 'completed'
          ? fetchCompletedAccreditationsList
          : fetchPendingAccreditationsList;
      return method(customerId)
        .then(result => {
          setQuestionnaireList(result?.data?.questionnaires || []);
          setLoadingQuestionnaireList(false);
        })
        .catch(err => {
          setLoadingQuestionnaireList(false);
          dispatch(notifyError(FETCH_ERROR_MESSAGE));
        });
    },
    [customerId, setQuestionnaireList, setLoadingQuestionnaireList, dispatch],
  );

  // Load questionnaires pending list on init
  useEffect(() => {
    doFetchQuestionnaires('not_started');
  }, []);

  function changeListType(type) {
    if (type === listType) {
      return;
    }
    setListType(type);
    doFetchQuestionnaires(type);
  }

  async function closeQuestionnaire() {
    setModalOpen(false);
    await doFetchQuestionnaires(listType);
  }

  const createSortHandler = property => _event => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedQuestionnaires = useMemo(() => {
    const list = getSortedQuestionnaires(questionnaireList, order, orderBy);
    if (listType === 'completed') {
      return list.filter(item => item.status === 'completed');
    }
    return list.filter(item => item.status !== 'completed');
  }, [questionnaireList, order, orderBy, listType]);

  const colors = useColors();

  const mappedQuestionnaire = useMemo(
    () =>
      questionnaireMapper({ questionnaire: currentQuestionnaire, closeModal: closeQuestionnaire }),
    [closeQuestionnaire, currentQuestionnaire],
  );

  return (
    <div className={classes.listContainer}>
      <Typography className={classes.title}>Accreditation</Typography>
      <div className={classes.typeSelectors}>
        <AnswerChip
          value="not_started"
          currentValue={listType}
          onChange={value => changeListType(value)}
          label="Pending"
        />
        <AnswerChip
          value="completed"
          currentValue={listType}
          onChange={value => changeListType(value)}
          label="Completed"
        />
      </div>
      {loadingQuestionnaireList && <div>Loading Questionnaires</div>}
      {!loadingQuestionnaireList && (
        <Table>
          <TableHead>
            <TableRow
              sx={{ th: { background: colors.gray, padding: '6px 14px', whiteSpace: 'nowrap' } }}
            >
              {headItems[listType].map(({ label, property }, index) => (
                <TableCell
                  sortDirection={orderBy === property ? order : false}
                  width={index === 0 ? '60%' : 'auto'}
                  key={label}
                >
                  <TableSortLabel
                    active={orderBy === property}
                    direction={orderBy === property ? order : 'asc'}
                    onClick={createSortHandler(property)}
                  >
                    {label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {questionnaireList.length > 0 ? (
              sortedQuestionnaires.map(questionnaire => {
                const { questionnaire_instance_id, name, status, due_date_dt, saved_dt, saved_by } =
                  questionnaire;
                const statusDescriptor = getStatusDescriptor(questionnaire, listType);
                const displayNegative = statusDescriptor === 'Due' || status === 'rejected';
                const inReview = status === 'submitted';
                const buttonIcon =
                  buttonTextLookup[status] === 'Edit' ? <Edit /> : <AssignmentTurnedIn />;
                return (
                  <TableRow
                    key={questionnaire_instance_id}
                    sx={{
                      td: {
                        color: displayNegative ? colors.red : colors.bodyText,
                        whiteSpace: 'nowrap',
                      },
                    }}
                  >
                    <TableCell width="60%">
                      {name}{' '}
                      {displayNegative && (
                        <ErrorIcon sx={{ fontSize: '16px', marginBottom: '-3px' }} />
                      )}
                    </TableCell>
                    {listType === 'not_started' && (
                      <TableCell
                        sx={{
                          color: inReview ? `${colors.green}!important` : 'inherit',
                        }}
                      >
                        {statusDescriptor}
                      </TableCell>
                    )}
                    {listType === 'not_started' && <TableCell>{formatDate(due_date_dt)}</TableCell>}
                    <TableCell sx={{ color: `${colors.bodyText}!important` }}>
                      {['submitted', 'completed', 'rejected'].includes(status) && saved_dt
                        ? formatDate(saved_dt)
                        : '-'}
                    </TableCell>
                    {listType === 'completed' && <TableCell>{saved_by}</TableCell>}
                    <TableCell align="right">
                      <IconButton
                        onClick={() => {
                          setCurrentQuestionnaire(questionnaire);
                          setModalOpen(true);
                        }}
                        variant="contained"
                        size="large"
                      >
                        {buttonIcon}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell className={classes.column1} colspan="100%">
                  {listType === 'not_started'
                    ? 'No Pending or Active Questionnaires'
                    : 'No Completed Questionnaires'}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
      {modalOpen && (
        <Modal sx={{ zIndex: globalZIndex.accreditationModal }} open>
          {mappedQuestionnaire}
        </Modal>
      )}
    </div>
  );
}

export default compose(withStyles(styles, { withTheme: true }))(QuestionnaireList);

import makeStyles from '@mui/styles/makeStyles';

export const styles = makeStyles(theme => {
  return {
    lastCell: {
      '&:last-child': {
        paddingRight: 0,
        paddingLeft: 0,
        textAlign: 'center',
      },
    },
    pointer: {
      cursor: 'pointer',
    },
    searchField: {
      marginBottom: theme.spacing(-0.5),
    },
  };
});

import React from 'react';
import { Grid, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import DetailField from 'components/form/field/field';
import { convertToArborDate } from 'models/time/arbor-date';
import { isEmpty } from 'lodash';
import { DeliveryMethodValue } from 'constants/enums';
import ExternalDownloadImage from './external-image';
import { IDeliveryInformation } from './types';
import { ShippingStyles } from './shipping.styles';

interface IShippingProps extends IDeliveryInformation, WithStyles<typeof ShippingStyles> {}

const DeliveryConfirmation = (props: IShippingProps): JSX.Element | null => {
  const { classes } = props;

  const { currentDeliveryInformation } = props;
  const { currentPrintInformation } = props;
  const isCurrentMethodOfDeliveryUps =
    currentDeliveryInformation?.deliveryMethod === DeliveryMethodValue.Ups;
  const isCurrentMethodOfDeliveryCourier =
    currentDeliveryInformation?.deliveryMethod === DeliveryMethodValue.Courier;
  const isLabLogistics =
    isCurrentMethodOfDeliveryCourier && currentPrintInformation?.lab_logistics_order_id;
  const isAssociatedCourier =
    isCurrentMethodOfDeliveryCourier && currentPrintInformation?.associated_courier_order_id;
  const isNuVizzCourier =
    isCurrentMethodOfDeliveryCourier && currentPrintInformation?.nuvizz_courier_order_id;

  const displayDeliveryDate = (): JSX.Element => {
    return (
      <Grid item xs={3}>
        <DetailField
          fieldName="Delivery Date"
          field={convertToArborDate(
            currentPrintInformation?.delivery_dt,
            false,
          ).getCustomerDatetime(true, 'MM/DD/YYYY hh:mm A')}
          variant="raw"
        />
      </Grid>
    );
  };

  const displayUPSReceivedBy = (): JSX.Element => {
    const receivedByFromUPS = currentPrintInformation?.received_by;
    const signatureNotRequired =
      currentDeliveryInformation?.signature == null || !currentDeliveryInformation.signature;
    let receivedByDisplay;
    if (isEmpty(receivedByFromUPS) && signatureNotRequired) {
      receivedByDisplay = 'DRIVER RELEASE';
    } else {
      receivedByDisplay = receivedByFromUPS ?? '';
    }
    return (
      <Grid item xs={3}>
        <DetailField fieldName="Received By" field={receivedByDisplay} />
      </Grid>
    );
  };

  const displayUPSLeftAt = (): JSX.Element => {
    return (
      <Grid item xs={3}>
        <DetailField fieldName="Left At" field={currentPrintInformation?.left_at} />
      </Grid>
    );
  };

  const displayCxtSoftwarePOD = (): JSX.Element => {
    return (
      <Grid item xs={3}>
        <DetailField fieldName="POD" field={currentPrintInformation?.pod} />
      </Grid>
    );
  };

  const displayNuVizzPODComment = (): JSX.Element => {
    return (
      <Grid item xs={3}>
        <DetailField fieldName="POD Comment" field={currentPrintInformation?.pod_comment} />
      </Grid>
    );
  };

  const displayNuVizzPOD = (): JSX.Element => {
    return (
      <Grid item xs={3}>
        <DetailField fieldName="POD" field={currentPrintInformation?.pod} />
      </Grid>
    );
  };

  const displayCxtSoftwarePODComment = (): JSX.Element => {
    return (
      <Grid item xs={3}>
        <DetailField fieldName="POD Comment" field={currentPrintInformation?.pod_comment} />
      </Grid>
    );
  };
  const displayAll = (): JSX.Element | null => {
    return (
      <Grid className={classes.sectionBottomMargin}>
        <Grid className={classes.deliveryInformationPanel}>
          <Typography className={classes.deliveryInformationTitle}>
            Delivery Confirmation
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.contentsOfPackage}>
          <Grid container className={classes.subsection}>
            <ExternalDownloadImage currentDeliveryInformation={props.currentDeliveryInformation} />
            <Grid container item xs={9}>
              {isCurrentMethodOfDeliveryUps && displayUPSReceivedBy()}
              {isCurrentMethodOfDeliveryUps && displayUPSLeftAt()}
              {isLabLogistics && displayCxtSoftwarePOD()}
              {isLabLogistics && displayCxtSoftwarePODComment()}
              {isAssociatedCourier && displayCxtSoftwarePOD()}
              {isAssociatedCourier && displayCxtSoftwarePODComment()}
              {isNuVizzCourier && displayNuVizzPOD()}
              {isNuVizzCourier && displayNuVizzPODComment()}
              {displayDeliveryDate()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return <>{currentPrintInformation?.delivery_dt ? displayAll() : null}</>;
};

export default withStyles(ShippingStyles)(DeliveryConfirmation);

import { ITask } from 'interfaces/redux/ITasks';
import { ITherapy } from 'interfaces/redux/ITherapy';

import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'interfaces/redux/IState';
import { find } from 'lodash';
import { IResourceLink } from 'interfaces/redux/IResourceLinks';
import { setMergeIntTasks } from 'actions/action-tasks';
import {
  TASK_INTERVENTIONS,
  TASK_DATA_COLLECT,
  DC,
  TASK_RISK_STRAT,
  RS,
} from '../../../../constants/index';
import { IUniqueIntTasks } from './types';
import { isValidJson, arraysEqual } from '../util';

export const getTherapyName = (task: ITask, therapies: ITherapy[]) => {
  if (task && therapies) {
    const matchingTherapy = therapies.find(item => item.id === task.therapy_id);
    if (matchingTherapy) {
      return matchingTherapy.drug_name;
    }
  }

  if (task && task.drugs) {
    return task.drugs[0].drug_name;
  }

  return task.therapy_id;
};

const selectLinksData = (state: IState) => state.links.data;
const selectTasksData = (state: IState) => state.tasks.data;
export const getNextFCTask = (
  linksData: IState['links']['data'],
  tasksData: IState['tasks']['data'],
  taskIdArg: number,
) => {
  const foundLink = find(linksData as IResourceLink[], {
    resource_one_type: 'task_fill_coordination',
    resource_two_type: 'task_fill_coordination',
    relationship: 'parent',
    resource_one_id: taskIdArg,
  });
  const foundLinkTaskKey = `FC${foundLink?.resource_two_id}`;
  const linkedTask = tasksData[foundLinkTaskKey];
  return linkedTask ?? null;
};
export const getSelectNextFCTask =
  (linksData: IState['links']['data'], tasksData: IState['tasks']['data']) =>
  (taskIdArg: number) => {
    const foundLink = find(linksData as IResourceLink[], {
      resource_one_type: 'task_fill_coordination',
      resource_two_type: 'task_fill_coordination',
      relationship: 'parent',
      resource_one_id: taskIdArg,
    });
    const foundLinkTaskKey = `FC${foundLink?.resource_two_id}`;
    const linkedTask = tasksData[foundLinkTaskKey];
    return linkedTask ?? null;
  };

export const getLinkedFCTaskForId = createSelector(
  [selectLinksData, selectTasksData],
  getSelectNextFCTask,
);

export const getTaskKey = (task?: Pick<ITask, 'id' | 'taskType'>) =>
  task ? `${task.taskType}${task.id}` : '';

const getAdditionalDataForClinicalItem = (
  intTasks: ITask[],
  links: any,
  clinicalDataTypeId: number,
) => {
  const additionalData: {
    linkedTaskType: string | null;
    linkedTaskId: number;
    therapyId: number;
    intId: number;
  }[] = [];
  for (const intTask of intTasks) {
    const tempLinkData = links.find(
      (l: any) =>
        l.resource_two_id === intTask.id &&
        l.resource_two_type === TASK_INTERVENTIONS &&
        l.meta &&
        typeof l.meta === 'string' &&
        isValidJson(l.meta) &&
        JSON.parse(l.meta)?.clinical_data_type_id,
    );
    if (tempLinkData && Object.keys(tempLinkData).length) {
      const tempMeta: { clinical_data_type_id: number; therapy_id: number } = JSON.parse(
        tempLinkData.meta,
      );
      if (tempMeta.clinical_data_type_id === clinicalDataTypeId) {
        additionalData.push({
          linkedTaskType:
            tempLinkData.resource_one_type === TASK_DATA_COLLECT
              ? DC
              : tempLinkData.resource_one_type === TASK_RISK_STRAT
              ? RS
              : null,
          linkedTaskId: tempLinkData.resource_one_id,
          therapyId: intTask.therapy_id,
          intId: intTask.id,
        });
      }
    }
  }
  return additionalData;
};

const getIntObjIndex = (listOfInterventions: any[], clinicalItemTherapyIds: number[]) => {
  let id: number | null = null;
  /* eslint-disable-next-line no-plusplus */
  for (let i = 0; i < listOfInterventions.length; i++) {
    const intObj = listOfInterventions[i];
    const clinicalItemIds = Object.keys(intObj);
    /* eslint-disable-next-line no-plusplus */
    for (let j = 0; j < clinicalItemIds.length; j++) {
      const additionalInfoList = intObj[clinicalItemIds[j]];
      const additionalInfoTherapies = additionalInfoList.map((t: any) => t.therapyId); // in therory the resulting array has unique numbers
      if (arraysEqual(additionalInfoTherapies, clinicalItemTherapyIds)) {
        id = i;
        return id;
      }
    }
  }
  return id;
};

const getIntsListBasedOnHash = (intTasks: ITask[], links: any) => {
  const listOfInterventions: any[] = [];
  for (const intTask of intTasks) {
    const tempLinkData = links.find(
      (l: any) =>
        l.resource_two_id === intTask.id &&
        l.resource_two_type === TASK_INTERVENTIONS &&
        l.meta &&
        typeof l.meta === 'string' &&
        isValidJson(l.meta) &&
        JSON.parse(l.meta)?.clinical_data_type_id,
    );
    if (tempLinkData && Object.keys(tempLinkData).length) {
      const tempMeta: { clinical_data_type_id: number; therapy_id: number } = JSON.parse(
        tempLinkData.meta,
      );
      if (!listOfInterventions.length) {
        listOfInterventions.push({
          [tempMeta.clinical_data_type_id]: getAdditionalDataForClinicalItem(
            intTasks,
            links,
            tempMeta.clinical_data_type_id,
          ),
        });
      } else {
        const uniqueClinicalDataTypeIds = listOfInterventions.reduce((acc, obj) => {
          Object.keys(obj).forEach(key => {
            if (!acc.includes(key)) {
              acc.push(key);
            }
          });
          return acc;
        }, [] as number[]);
        if (!uniqueClinicalDataTypeIds.includes(tempMeta.clinical_data_type_id.toString())) {
          const additionalData = getAdditionalDataForClinicalItem(
            intTasks,
            links,
            tempMeta.clinical_data_type_id,
          );
          const intObjId = getIntObjIndex(
            listOfInterventions,
            additionalData.map((t: any) => t.therapyId),
          );
          if (intObjId !== null) {
            // add the new clinical item in the object index
            listOfInterventions[intObjId][tempMeta.clinical_data_type_id] =
              getAdditionalDataForClinicalItem(intTasks, links, tempMeta.clinical_data_type_id);
          } else {
            listOfInterventions.push({
              [tempMeta.clinical_data_type_id]: getAdditionalDataForClinicalItem(
                intTasks,
                links,
                tempMeta.clinical_data_type_id,
              ),
            });
          }
        }
      }
    }
  }
  return listOfInterventions;
};

const getIntsToBeAdded = (intMapping: { wag_hash: string; ints: any[] }[], intTasks: ITask[]) => {
  const listOfInts: any[] = [];
  for (const intWag of intMapping) {
    const tempIntList = intWag.ints.sort(
      (a, b) => a[Object.keys(a)[0]].length - b[Object.keys(b)[0]].length,
    );
    /* eslint-disable-next-line no-plusplus */
    for (let i = 0; i < tempIntList.length; i++) {
      const intObj = tempIntList[i];
      const tempInnerList = intObj[Object.keys(intObj)[0]];
      for (const possibleObj of tempInnerList) {
        if (tempInnerList.length === 1 && !listOfInts.length) {
          listOfInts.push(possibleObj);
          break;
        } else {
          let exist = false;
          /* eslint-disable-next-line no-plusplus */
          for (let j = 0; j < tempIntList.length; j++) {
            if (i !== j) {
              if (
                tempIntList[j][Object.keys(tempIntList[j])[0]]
                  .map((t: any) => t.therapyId)
                  .includes(possibleObj.therapyId)
              ) {
                exist = true;
              }
            }
          }
          if (!exist && !listOfInts.map((t: any) => t.therapyId).includes(possibleObj.therapyid)) {
            listOfInts.push(possibleObj);
            break;
          }
        }
      }
    }
  }
  const intsToBeAdded = intTasks.reduce((acc, t) => {
    if (listOfInts.map((a: any) => a.intId).includes(t.id)) {
      acc.push(t);
    }
    return acc;
  }, [] as ITask[]);
  return intsToBeAdded;
};

export const getIntsWagToBeAdded = (intTasks: ITask[], links: any, dispatch: any) => {
  const intMapping = [];
  if (intTasks.length) {
    const sortedWagHashList = intTasks
      .sort((a: any, b: any) => a.wag_hash.localeCompare(b.wag_hash))
      .reduce((acc: string[], item: ITask) => {
        if (item.wag_hash && !acc.includes(item.wag_hash)) {
          acc.push(item.wag_hash);
        }
        return acc;
      }, [] as string[]);
    for (const hash of sortedWagHashList) {
      intMapping.push({
        wag_hash: hash,
        ints: getIntsListBasedOnHash(
          intTasks.filter(t => t.wag_hash === hash),
          links,
        ),
      });
    }
  }
  dispatch(setMergeIntTasks(intMapping.length ? intMapping : []));
  return intMapping.length ? getIntsToBeAdded(intMapping, intTasks) : [];
};

export const isIntDuplicated = (task: ITask, interventionsAdded: IUniqueIntTasks[], links: any) => {
  if (links && links.length) {
    const intLinkData = links.find(
      (t: any) =>
        t.resource_two_type === TASK_INTERVENTIONS &&
        t.resource_two_id === task.id &&
        t.meta &&
        typeof t.meta === 'string' &&
        isValidJson(t.meta) &&
        JSON.parse(t.meta)?.clinical_data_type_id,
    );
    if (intLinkData && Object.keys(intLinkData).length) {
      const intAlreadyAdded = interventionsAdded.find(
        t =>
          t.resourceType === intLinkData.resource_one_type &&
          t.resourceTaskId === intLinkData.resource_one_id,
      );
      if (intAlreadyAdded === undefined) {
        interventionsAdded.push({
          intId: intLinkData.resource_two_id,
          resourceType: intLinkData.resource_one_type,
          resourceTaskId: intLinkData.resource_one_id,
        });
      } else {
        return true;
      }
    }
  }
  return false;
};

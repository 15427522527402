import { AxiosResponse } from 'axios';

import { IPatient } from 'interfaces/redux/IPatient';
import { DeepPartial, Nullable } from 'utils/types-util';
import HTTP from '../http';

export interface IAdvancePatientSearchRequest {
  firstName?: string;
  lastName?: string;
  mrn?: string;
  dob?: Date;
  phone?: string;
}

export interface IAdvancedSearchResponse {
  search_patients: Nullable<DeepPartial<IPatient>>[];
}

class PatientSearchService {
  static async advanced(
    searchRequest: IAdvancePatientSearchRequest,
  ): Promise<Nullable<DeepPartial<IPatient>>[]> {
    const response = (await HTTP.post(
      '/patients/_search/advanced',
      searchRequest,
    )) as AxiosResponse<IAdvancedSearchResponse>;
    return response.data?.search_patients || [];
  }
}

export { PatientSearchService };

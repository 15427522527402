import { Button, Divider, Grid, Typography } from '@mui/material';
import { StyledComponentProps } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { buildQaId } from 'utils/build-qa-id';
import {
  renderReactRadioGroup,
  renderDropdown,
  renderTextField,
} from 'components/form/field/redux-field';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import React, { useEffect, useState } from 'react';
import { Field, FieldArray, WrappedFieldArrayProps, reduxForm, change } from 'redux-form';
import { compose } from 'recompose';
import { required } from 'components/form/validation/validation';
import NoteArea from 'components/note/NoteArea';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { ILabelValueNumber } from 'interfaces/ILabelValue';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { Add as AddIcon } from '@mui/icons-material';
import AutocompleteMinigrid from 'components/form/field/render-minigrid-autocomplete';
import { fetchGpis, specialtyTypeFormatter } from 'services/utils/therapy-service';
import { convertToArborDate } from 'models/time/arbor-date';
import {
  addAppointmentReferralTask,
  fetchScheduleOutreachByPatient,
  fetchTaskCounts,
} from 'actions/action-tasks';
import { workListChanged } from 'actions/action-patient';
import { useDispatch } from 'react-redux';
import { AxiosPromise } from 'axios';
import { EditDelete } from 'components/icons/icons';
import { useLocation } from 'react-router';
import { ISchedule } from 'interfaces/redux/ISchedule';
import { uniqBy } from 'lodash';
import { getUrlQueryParam } from '../../../../helpers/router';
import { AR_REASON_OTHER_ID, COMMUNICATION_TYPES, FORM_NAME } from './constants';
import { styles, Styles } from './styles';
import { Props, IAddAppointmentReferralProps } from './interfaces';
import { AR } from '../../../../constants';

type TargetsProps = WrappedFieldArrayProps<any> & { fields: any };
type TargetComponent = React.ComponentType<
  Pick<
    WrappedFieldArrayProps<any> & {
      fields: any;
    } & {
      children?: React.ReactNode;
    },
    keyof WrappedFieldArrayProps<any> | 'children'
  > &
  StyledComponentProps<'addFormContainer' | 'addTargetWrapper' | 'addTargetTitle'>
>;

const _renderTargets: React.FC<TargetsProps> = props => {
  const { fields } = props;
  const [selectedTherapy, setSelectedTherapy] = useState<any>({});

  const initializeSelectedTherapy = (value: any) => {
    if (Object.keys(value).length) {
      setSelectedTherapy(value);
    }
  };

  const handleFetchGpisOptions = async (searchText: string) => {
    const result = await fetchGpis(searchText);
    return (
      result?.data?.drugs?.map((therapy: { ndc: any; drug_info: any; is_specialty_drug: any }) => ({
        ...therapy,
        id: therapy.ndc,
        label: therapy.drug_info,
      })) || []
    );
  };

  useEffect(() => {
    if (fields.length === 0) fields.push({});
  }, [fields?.length]);

  const deleteTargetField = (index: number) => fields.splice(index, 1);
  const dispatch = useDispatch();
  return (
    <Grid container sx={Styles.addTargetWrapper}>
      {fields.length ? <Typography variant="subtitle2">Add Target</Typography> : <></>}
      {fields.map((target: any, index: number) => (
        <Grid container sx={Styles.targetContainer} key={String(target.gpi + index)}>
          <Grid container spacing={3}>
            <Grid item xs={5} sx={Styles.therapySearchWrapper}>
              <Field
                name={`${target}.therapy`}
                label="Therapy *"
                component={AutocompleteMinigrid}
                validate={Object.keys(selectedTherapy).length ? false : [required]}
                fetchOptions={handleFetchGpisOptions}
                hint="Search by Therapy or GPI or NDC"
                input={{
                  name: `${target}.therapy`,
                  label: '',
                  value: { label: fields.get(index).name },
                  onChange: (value: any) => {
                    dispatch(change(FORM_NAME, `${target}.ndc`, value.ndc));
                    dispatch(change(FORM_NAME, `${target}.gpi`, value.gpi));
                    dispatch(change(FORM_NAME, `${target}.specialty_type`, value.specialty_type));
                    initializeSelectedTherapy(value);
                  },
                }}
                columnsToShow={{
                  gpi: 'GPI',
                  drug_name: 'Drug Name',
                  dosage_form_description: 'Form',
                  dose: 'Dose',
                  ldd: 'LDD',
                  specialty_type: 'Specialty Type',
                  ndc: 'NDC',
                }}
                minSearchLength={2}
                valueFormatter={specialtyTypeFormatter}
              />
            </Grid>
            <Grid item xs={5}>
              <Field name={`${target}.ndc`} label="NDC *" component={renderTextField} disabled />
            </Grid>
            {index > 0 ? (
              <Grid item xs={2}>
                <Button variant="outlined" onClick={() => deleteTargetField(index)}>
                  <EditDelete />
                </Button>
              </Grid>
            ) : null}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Field name={`${target}.gpi`} label="GPI*" component={renderTextField} disabled />
            </Grid>
            <Grid item xs={4}>
              <Field name={`${target}.specialty_type`} label="Specialty Type *" component={renderTextField} disabled />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Button variant="outlined" onClick={() => fields.push({})} sx={Styles.addButton}>
          <AddIcon sx={Styles.leftIcon} />
          New target
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

const renderTargets = withStyles(styles)(_renderTargets);

const AddAppointmentReferral: React.FC<IAddAppointmentReferralProps> = (
  props: IAddAppointmentReferralProps,
): JSX.Element => {
  const { handleSubmit, cancel, formName = FORM_NAME, reset } = props;
  const generateFieldQAId = buildQaId(formName);
  const [managingClinics, setManagingClinics] = useState<ILabelValueNumber[]>([]);
  const [serviceGroups, setServiceGroups] = useState<ILabelValueNumber[]>([]);
  const [scheduleOutreachReasons, setScheduleOutreachReasons] = useState<ILabelValueNumber[]>([]);
  const [displayDescription, setDisplayDescription] = useState<boolean>(false);
  const location = useLocation();

  const isTaskTypeAR = getUrlQueryParam(location, 'selectedType') === AR;
  const appointmentId = getUrlQueryParam(location, 'appointment') || undefined;

  const reduxState = useTypedSelector(state => {
    return {
      customerClinics: state.lookups.customerClinics,
      serviceGroups: state.lookups.serviceGroups,
      selectedPatientId: state.selectedPatientId,
      scheduleOutreachReasons: state.lookups.lookupScheduleOutreachReasons,
      schedule: state.schedules.schedules,
      therapies: state.therapies.data,
      medications: state.medications.medicationGroups,
    };
  });

  const { selectedPatientId, schedule } = reduxState;
  const [appointment] = schedule.filter((x: ISchedule) => x.id === parseInt(appointmentId));
  const getTargetedTherapies = () => {
    const all = appointment.targeted_drugs.map(t => ({
      name: t.drug_name,
      ndc: t.ndc,
      gpi: t.gpi,
      therapy: { ndc: t.ndc, gpi: t.gpi },
    }));
    return uniqBy(all, x => x.ndc);
  };

  useEffect(() => {
    const data = reduxState?.customerClinics?.map(clinic => ({
      value: clinic.id,
      label: clinic.name,
      isDisabled: !clinic.active,
    }));
    setManagingClinics(data);
  }, [reduxState?.customerClinics]);

  useEffect(() => {
    const data = reduxState?.serviceGroups?.map(serviceGroup => ({
      value: serviceGroup.id,
      label: serviceGroup.display_name,
    }));
    setServiceGroups(data);
  }, [reduxState?.serviceGroups]);

  useEffect(() => {
    const data = reduxState?.scheduleOutreachReasons?.map(reason => ({
      value: reason.id,
      label: reason.name,
    }));
    setScheduleOutreachReasons(data);
  }, [reduxState?.scheduleOutreachReasons]);

  useEffect(() => {
    if (appointment === undefined) {
      dispatch(change(FORM_NAME, 'followup_dt', convertToArborDate(new Date()).getCustomerDate(true)));
    }
    if (isTaskTypeAR && appointment !== undefined) {
      const therapies = getTargetedTherapies();
      dispatch(change(FORM_NAME, 'followup_dt', appointment?.start_dt));
      dispatch(change(FORM_NAME, 'managing_clinic_id', appointment?.clinic_id));
      dispatch(change(FORM_NAME, 'service_group_id', appointment?.service_group_id));
      dispatch(change(FORM_NAME, 'therapies', therapies));
    }
  }, [reduxState?.medications, reduxState?.schedule]);

  const dispatch = useDispatch();
  const onSubmit = (values: any) => {
    const { therapies, followup_dt, ...otherFields } = values;
    const payload = {
      ...otherFields,
      patient_id: selectedPatientId,
      followup_dt: convertToArborDate(followup_dt).getUtcDate(),
      scheduled_date: null,
      therapies: therapies.map((value: any) => {
        const { ndc, gpi } = value;
        return {
          ndc,
          gpi,
        };
      }),
    };

    (dispatch(addAppointmentReferralTask(payload)) as unknown as AxiosPromise<any>).then(() => {
      dispatch(workListChanged());
      dispatch(fetchTaskCounts());
      dispatch(fetchScheduleOutreachByPatient(selectedPatientId));
      if (cancel) {
        cancel();
      }
      if (reset) {
        reset();
      }
    });
  };

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid sx={Styles.addFormContainer}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Field
              qaId={generateFieldQAId('communication_type_id')}
              name="communication_type_id"
              radioMap={COMMUNICATION_TYPES}
              component={renderReactRadioGroup}
              validate={[required]}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              label="Follow Up Date *"
              qaId={generateFieldQAId('followup_dt')}
              name="followup_dt"
              component={renderDatePicker}
              validate={[required]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Field
              name="managing_clinic_id"
              label="Managing Clinic *"
              component={renderDropdown}
              fields={managingClinics}
              validate={[required]}
              onChange={(value: any) => {
                const clinic = reduxState?.customerClinics?.find(clinic => clinic.id === value);
                if (!clinic) return;

                const group = reduxState?.serviceGroups?.find(
                  group => group.id === clinic.service_group_id,
                );
                if (!group) return;
                dispatch(change(FORM_NAME, 'service_group_id', group.id));
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              name="service_group_id"
              label="Service Group *"
              component={renderDropdown}
              fields={serviceGroups}
              disabled
              validate={[required]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Field
              name="reason_id"
              label="Reason *"
              component={renderDropdown}
              fields={scheduleOutreachReasons}
              validate={[required]}
              onChange={(value: any) => setDisplayDescription(value === AR_REASON_OTHER_ID)}
            />
          </Grid>
          {displayDescription && (
            <Grid item xs={4}>
              <Field
                name="reason_description"
                label="Description *"
                component={renderTextField}
                validate={[required]}
              />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <Field name="note" component={NoteArea} />
          </Grid>
        </Grid>
        <FieldArray<TargetComponent> name="therapies" component={renderTargets} />

        <Grid container>
          <ConfirmationPanel
            cancelButtonName={`${formName}_cancel_button`}
            submitButtonName={`${formName}_submit_button`}
            handleSubmit={handleSubmit ? handleSubmit(onSubmit) : null}
            handleCancel={cancel}
            loadingText="Saving"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default compose<Props, IAddAppointmentReferralProps>(
  withStyles(styles),
  reduxForm({ form: FORM_NAME }),
)(AddAppointmentReferral);

import cx from 'classnames';
import React, { createRef, FC, useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { DraftEditorCommand, DraftHandleValue, Editor, EditorState, RichUtils } from 'draft-js';
import {
  FormatBold as BoldIcon,
  FormatItalic as ItalicIcon,
  FormatStrikethrough as StrikethroughIcon,
  FormatUnderlined as UnderlineIcon,
  FormatListBulleted as UnorderedListIcon,
  FormatListNumbered as OrderedListIcon,
} from '@mui/icons-material';
import { buildInitialValue, buildOutputValue, moveCursorToEnd } from './utils';
import { EditorFeature, EditorHandleValue, EditorValueFormat } from './enums';
import { IProps } from './interfaces';
import { styles } from './styles';
import 'draft-js/dist/Draft.css';

const getFeatureIcon = (
  feature: EditorFeature,
  editorState: EditorState,
  classes: any,
): { element: JSX.Element | undefined; active: boolean } => {
  const currentInlineStyle = editorState.getCurrentInlineStyle();

  const isActive = currentInlineStyle.contains(feature);
  const className = cx({ [classes.activeIcon]: isActive });
  let element: JSX.Element | undefined;
  switch (feature) {
    case EditorFeature.Bold:
      element = <BoldIcon fontSize="small" className={className} />;
      break;
    case EditorFeature.Italic:
      element = <ItalicIcon fontSize="small" className={className} />;
      break;
    case EditorFeature.Strikethrough:
      element = <StrikethroughIcon fontSize="small" className={className} />;
      break;
    case EditorFeature.Underline:
      element = <UnderlineIcon fontSize="small" className={className} />;
      break;
    case EditorFeature.UnorderedList:
      element = <UnorderedListIcon fontSize="small" className={className} />;
      break;
    case EditorFeature.OrderedList:
      element = <OrderedListIcon fontSize="small" className={className} />;
      break;
  }

  return { element: element, active: isActive };
};

const RichTextEditor: FC<IProps> = (props: IProps): JSX.Element => {
  const { classes } = props;

  // Default initial value as plain text format
  const editorInitialValueFormat = props.initialValueFormat ?? EditorValueFormat.PlainText;

  // Default output format as plain text format
  const editorOutputFormat = props.outputFormat ?? EditorValueFormat.PlainText;

  const [editorState, setEditorState] = useState<EditorState>(
    buildInitialValue(editorInitialValueFormat, props.initialValue),
  );

  React.useEffect(() => {
    const initialEditorState = buildInitialValue(editorInitialValueFormat, props.initialValue);
    setEditorState(initialEditorState);
  }, [props.initialValue]);

  React.useEffect(() => {
    if (props.clearTrigger) {
      const emptyInitialState = buildInitialValue(editorInitialValueFormat, undefined);
      setEditorState(emptyInitialState);
    }
  }, [props.clearTrigger]);

  React.useEffect(() => {
    // Need to delay this to last place on callstack in order to all editorState happens before it
    setTimeout(() => {
      const initialEditorState = buildInitialValue(editorInitialValueFormat, props.initialValue);
      setEditorState(initialEditorState);
    }, 0);
  }, [props.cancelContentUpdatesTrigger]);

  React.useEffect(() => {
    if (props.saveContentUpdatesTrigger && props.onSaveContentUpdates) {
      // Build output basing on editor value format
      props.onSaveContentUpdates(buildOutputValue(editorOutputFormat, editorState));
    }
  }, [props.saveContentUpdatesTrigger, props.onSaveContentUpdates]);

  const editorReference = createRef<Editor>();

  const handleChange = (editorState: EditorState): void => {
    setEditorState(editorState);
    if (props.onChangeValue) {
      // Build output basing on editor value format
      props.onChangeValue(buildOutputValue(editorOutputFormat, editorState));
    }
  };

  const handleCommand = (
    command: DraftEditorCommand,
    editorState: EditorState,
  ): DraftHandleValue => {
    const newEditorState = RichUtils.handleKeyCommand(editorState, command);
    const editorFeatureCommand = command.toUpperCase() as EditorFeature;

    if (newEditorState && props.features?.includes(editorFeatureCommand)) {
      handleChange(newEditorState);
      return EditorHandleValue.Handled;
    }
    return EditorHandleValue.Unhandled;
  };

  const handleFeature =
    (feature: EditorFeature): any =>
    (e: Event): void => {
      e.preventDefault();
      switch (feature) {
        case EditorFeature.Bold:
        case EditorFeature.Underline:
        case EditorFeature.Italic:
        case EditorFeature.Strikethrough:
          handleChange(RichUtils.toggleInlineStyle(editorState, feature.toUpperCase()));
          break;
        case EditorFeature.OrderedList:
        case EditorFeature.UnorderedList:
          handleChange(RichUtils.toggleBlockType(editorState, feature));
          break;
      }
    };

  const handleCancel = (): void => {
    setEditorState(buildInitialValue(editorInitialValueFormat, props.initialValue));
    if (props.onCancel) {
      props.onCancel(undefined);
    }
  };

  // Component did mount
  useEffect(() => {
    if (props.autoFocus) {
      editorReference.current?.focus();
      if (props.initialValue) {
        setEditorState(moveCursorToEnd(editorState));
      }
    }
  }, []);

  // Focus  when change from readonly true to false
  useEffect(() => {
    setEditorState(moveCursorToEnd(editorState));
  }, [props.readOnly]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} data-qa-id={`${props.qaId}-wrapper`}>
        <div
          className={cx({
            [classes.readOnlyWrapper]: props.readOnly && !props.hideWrapper,
            [classes.editingWrapper]: !props.readOnly,
          })}
        >
          {props.features?.length && props.featuresBar && !props.readOnly && (
            <Grid item xs={12} className={classes.featureButtonWrapper}>
              {props.features.map((feature, key) => {
                const iconAndActive = getFeatureIcon(feature, editorState, classes);
                return (
                  <Button
                    key={key}
                    onMouseDown={handleFeature(feature)}
                    size="small"
                    classes={{
                      sizeSmall: classes.smallButton,
                    }}
                    className={cx(classes.functionButton, {
                      [classes.activeButton]: iconAndActive.active,
                    })}
                  >
                    {iconAndActive.element}
                  </Button>
                );
              })}
            </Grid>
          )}

          <Editor
            ref={editorReference}
            placeholder={props.placeHolder}
            editorState={editorState}
            onChange={handleChange}
            handleKeyCommand={handleCommand}
            readOnly={props.readOnly}
            spellCheck
          />
        </div>
      </Grid>
      {!props.readOnly && (props.onCancel || props.onUpdateValue) && (
        <Grid item xs={12} className={classes.buttonWrapper}>
          <Grid container justifyContent="flex-end">
            {props.onCancel && (
              <Button
                data-qa-id={`${props.qaId}-cancel-button`}
                className={classes.cancelButton}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            )}
            {props.onUpdateValue && (
              <Button
                data-qa-id={`${props.qaId}-update-button`}
                color="primary"
                variant="contained"
                onClick={props.onUpdateValue}
              >
                Update
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(styles)(RichTextEditor);

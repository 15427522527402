import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { ModalUtil } from 'services/utils/modal.util';
import { expandTheme } from 'utils/theme-util';

export const TasksStyles = (theme: Theme) => {
  const { palette, font } = expandTheme(theme);
  return createStyles({
    container: {
      padding: theme.spacing(1),
    },
  });
};

export const Styles = {
  tab: {
    backgroundColor: 'primary.greySubRow',
    borderRadius: '4px 4px 0 0',
    marginRight: '7px',
    border: '1px solid',
    borderColor: 'primary.silverMist',
    position: 'relative',
    paddingTop: '11px',
  },
  selectedTab: {
    backgroundColor: 'primary.background',
    marginRight: '7px',
    borderRadius: '4px 4px 0 0',
    borderTop: '1px solid',
    borderLeft: '1px solid',
    borderRight: '1px solid',
    borderBottom: '1px solid',
    borderColor: 'primary.silverMist',
    borderBottomColor: 'primary.background',
    position: 'relative',
    zIndex: 1,
    paddingTop: '11px',
  },
  formsContainer: {
    border: '1px solid',
    borderColor: 'primary.silverMist',
    borderRadius: '0 0 4px 4px',
    position: 'relative',
    bottom: '1px',
  },
  tabsStyles: {
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    'border': null,
  },
  tabText: {
    color: 'primary.grey12',
    fontSize: 12,
    fontWeight: 'bold',
  },
  tabTextSelected: {
    color: 'primary.trellisBlue',
    fontSize: 12,
    fontWeight: 'bold',
  },
};

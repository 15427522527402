import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { Loader } from 'components/loading-overlay/loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {
  getInvoicingMetadata,
  invoicingSearch,
  changeInvoiceStage,
  getInvoicingDownloadUrl,
  getInvoicingStage,
} from 'services/utils/invoicing-service';

import InvoicingTable from 'components/invoicing/table/table';
import { updateInvoicingRecord } from 'actions/action-invoicing';
import { updateUserPreferences, updateLocalInvoicingPreferences } from 'actions/action-user';
import { getUserById } from 'services/utils/users-service';
import { convertToArborDate } from 'models/time/arbor-date';
import { INVOICING_STAGES, TRELLIS_USER } from 'constants/index';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { EXPANDER_ID, MAIN, ADJUSTMENT, COMMENT } from 'components/invoicing/constants';
import ExpandedField from 'components/invoicing/table/expanded-field';
import InvoicingTopSectionFilter from './top-section-filter';
import InvoicingWorkflows from './invoicing-workflows';
import useStyles from './styles';
import { logger } from '../../../winston-logger';

const defaultHiddenColumns = { [MAIN]: [COMMENT], [ADJUSTMENT]: [COMMENT, EXPANDER_ID] };

const InvoicingTableContainer = ({ currentPage }) => {
  const { loaderContainer, invoicingStageLabel } = useStyles();
  const dispatch = useDispatch();

  const userPreferences = useSelector(state => state.userPreferences) || {};
  const {
    invoicing: invoicingPreferences = {
      visibleColumns: [],
      hiddenColumns: defaultHiddenColumns[currentPage],
    },
  } = userPreferences;
  const [dataRows, setDataRows] = useState([]);
  const [totalsColumns, setTotalsColumns] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [rawColumnsList, setRawColumnsList] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState(invoicingPreferences.hiddenColumns);
  const [columnList, setColumnList] = useState();
  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [sources, setSources] = useState();
  const [source, setSource] = useState();
  const [globalFilter, setGlobalFilter] = useState();
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [invoicingStage, setInvoicingStage] = useState({});
  const [columnFilter, setColumnFilter] = useState({});
  const [isLoadingChangeStage, setIsLoadingChangeStage] = useState(false);
  const users = useSelector(state => state.lookups.users);
  const fetchIdRef = useRef(0);

  useEffect(() => {
    getInvoicingStage()
      .then(({ data }) => {
        setInvoicingStage(data);
      })
      .catch(err => {
        dispatch(notifyError(err.message));
      });
    getInvoicingMetadata(currentPage)
      .then(({ data: { columns: _columns, sources: rawSources } }) => {
        const rawColumns = _columns;
        setRawColumnsList(rawColumns);
        setColumnList(
          rawColumns.map((item, index) => ({ name: item.name, label: item.label, order: index })),
        );

        const [firstSource] = rawSources;
        setSource(firstSource);

        const columnsData = _columns
          .filter(x => !x.source || x.source === firstSource.source)
          .map(item => ({
            accessor: item.name,
            raw: item,
          }));
        setDataColumns([ExpandedField, ...columnsData]);
        setSources(rawSources);

        setIsLoaded(true);
      })
      .catch(err => {
        dispatch(notifyError(err.message));
      });
  }, []);

  const applyUserPreferences = useCallback(({ hidden, list }) => {
    dispatch(updateLocalInvoicingPreferences({ hiddenColumns: hidden, visibleColumns: list }));
    dispatch(
      updateUserPreferences({
        ...userPreferences,
        invoicing: { hiddenColumns: hidden, visibleColumns: list },
      }),
    );
  });

  useEffect(() => {
    setSkipPageReset(false);
  }, [dataRows]);

  const setTableColumns = _source => {
    const columnsData = rawColumnsList
      .filter(x => !x.source || x.source === _source.source)
      .map(item => ({
        accessor: item.name,
        raw: item,
      }));
    setDataColumns([ExpandedField, ...columnsData]);
  };

  const handleColumnFilterChange = value => {
    setColumnFilter(value);
  };

  const handleOnSearch = searchTerm => {
    setGlobalFilter(searchTerm);
  };

  const handleOnSearchSource = _source => {
    setSource(_source);
    setTableColumns(_source);
  };

  const handleOnPreferencesChange = (hidden, list) => {
    setHiddenColumns(hidden);
    setColumnList(list);
    applyUserPreferences({ hidden, list });
  };

  const fetchData = useCallback(
    ({ pageSize, pageIndex, globalFilter: searchTerm }) => {
      // eslint-disable-next-line no-plusplus
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      if (fetchId === fetchIdRef.current && columnList.length > 0 && source) {
        invoicingSearch(
          columnList,
          columnFilter,
          source.source,
          pageIndex + 1,
          pageSize,
          searchTerm,
        ).then(({ data: { count, documents, totals } }) => {
          setDataRows(documents);
          if (totals) {
            setTotalsColumns(totals);
          }
          setPageCount(Math.ceil(count / pageSize));
          setLoading(false);
        });
      }
    },
    [columnFilter, source],
  );

  useEffect(() => {
    if (sources) {
      setSource(sources.find(o => o.sourceType === currentPage));
    }
  }, [currentPage]);

  const handleOnUpdateRecord = useCallback(
    (row, column, selectedValue, actionComment, currentSource) => {
      const commentData = JSON.stringify(
        [
          {
            column_name: column.id,
            header: column.raw.label,
            new_value: selectedValue,
            old_value: row.original[column.id],
            comment: actionComment,
          },
        ].concat(row.original.Comment ? JSON.parse(row.original.Comment) : []),
      );

      let flagged = { Flagged: '1' };

      if (column.id === 'is_invoiced' && !currentSource.isPrimary) {
        flagged = {};
      }

      const record = {
        year: moment().subtract(1, 'month').year(),
        month: moment().subtract(1, 'month').month() + 1,
        source: currentSource.source,
        record: {
          id: row.original.id,
          Comment: commentData,
          [column.id]: moment.isMoment(selectedValue) ? selectedValue.toISOString() : selectedValue,
          ...flagged,
        },
      };
      dispatch(updateInvoicingRecord(record)).then(() => {
        setSkipPageReset(true);
        setDataRows(old =>
          old.map((line, index) => {
            if (index === row.index) {
              return {
                ...old[row.index],
                [column.id]: selectedValue,
                ...flagged,
                Comment: commentData,
              };
            }
            return line;
          }),
        );
      });
      setSources(old =>
        old.map(s =>
          s.source === currentSource.source ? { ...s, isDirty: flagged.Flagged === '1' } : s,
        ),
      );
    },
    [],
  );

  const { stage } = invoicingStage;
  const updatedUser = getUserById(invoicingStage.updated_by, users);
  const getInvoicingStageStr = () => {
    if (
      stage === INVOICING_STAGES.VALIDATED ||
      stage === INVOICING_STAGES.REJECTED ||
      stage === INVOICING_STAGES.FINALIZED
    ) {
      return `Data ${stage} by ${updatedUser.display_name || ''} ${
        invoicingStage.updated
          ? convertToArborDate(invoicingStage.updated).getCustomerDatetime(true)
          : ''
      }`;
    }
    return '';
  };

  const handleDownloadFile = async () => {
    setIsLoadingChangeStage(true);
    const downloadData = await getInvoicingDownloadUrl({
      stage: invoicingStage.stage,
      source: source.source,
    });
    setIsLoadingChangeStage(false);
    window.open(downloadData.data.url, '_blank');
  };

  const handleChangeInvoiceStage = async newStage => {
    setIsLoadingChangeStage(true);
    changeInvoiceStage({
      newStage,
    })
      .then(({ data }) => {
        if (data.stage === INVOICING_STAGES.NONE && data.updated_by === TRELLIS_USER) {
          dispatch(notifySuccess('Files are being generated in the background'));
        } else {
          dispatch(notifySuccess('Successfully moved files'));
        }
        setInvoicingStage(data);
        setIsLoadingChangeStage(false);
      })
      .catch(err => {
        dispatch(notifyError('Error moving file to next stage.'));
        logger.error(err);
      });
  };

  const sourcesFiltered = sources?.filter(item => item.sourceType === currentPage);

  const currentSourceIsDirty = useMemo(() => {
    if (source) {
      const currentSourceType = sources?.find(s => s.source === source.source)?.sourceType;
      if (currentSourceType) {
        return sources.filter(s => s.sourceType === currentSourceType).some(s => !!s.isDirty);
      }
    }
    return false;
  }, [sources, source]);

  return !isLoaded && !dataRows.length ? (
    <Grid className={loaderContainer}>
      <Loader loaded={isLoaded} />
    </Grid>
  ) : (
    <>
      <Box pt={2} />
      <Typography className={invoicingStageLabel}>{getInvoicingStageStr()}</Typography>
      <InvoicingTopSectionFilter
        sources={sourcesFiltered}
        onChangeSource={handleOnSearchSource}
        onSearch={handleOnSearch}
        columnList={columnList}
        hiddenColumns={hiddenColumns}
        onPreferencesChange={handleOnPreferencesChange}
        columnFilter={columnFilter}
        onColumnFilterChange={handleColumnFilterChange}
      />
      <InvoicingTable
        columns={dataColumns}
        visibleColumns={columnList}
        hiddenColumns={hiddenColumns}
        data={dataRows}
        totalsColumns={totalsColumns}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        globalFilter={globalFilter}
        source={source}
        cellUpdate={handleOnUpdateRecord}
        skipPageReset={skipPageReset}
        columnFilter={columnFilter}
        onColumnFilterChange={handleColumnFilterChange}
        invoicingStage={stage}
      />
      {dataRows.length > 0 && (
        <Grid container justifyContent="flex-end">
          <InvoicingWorkflows
            sourceIsDirty={currentSourceIsDirty}
            invoicingStage={stage}
            downloadHandler={handleDownloadFile}
            changeStageHandler={handleChangeInvoiceStage}
            isLoadingChangeStage={isLoadingChangeStage}
          />
        </Grid>
      )}
    </>
  );
};

export default InvoicingTableContainer;

import moment from 'moment';
import { IServiceGroups } from 'interfaces/redux/ILookups';
import HTTP from 'services/http';
import { IComponentStateItem, ComponentState, IPopoverStateBase } from './types';

export const commaSeparatedStringConvert = (value: unknown): string => {
  const defaultValue = '-';
  if (typeof value === 'string') {
    const split = value.split(',');
    return split.join(', ');
  }
  return defaultValue;
};

export const utcMomentOrUndefined = (
  value: string | undefined | null,
): moment.Moment | undefined => {
  return value ? moment.utc(value) : undefined;
};

export const getDataAndUpdateState = async (
  serviceGroupUrlBuilder: string,
  serviceGroupDataUrlBuilder: (serviceGroupId: number) => string,
  currentState: ComponentState,
  tableStateSetter: (state: ComponentState) => void,
): Promise<void> => {
  const url = serviceGroupUrlBuilder;
  const request = HTTP.get(url);
  const results = (await request).data as { id: number; display_name: string; name: string }[];
  const newTableState = results
    .map<IComponentStateItem | undefined>(result => {
      const serviceGroupCurrentState = currentState.find(x => x.id === result.id);

      const url = serviceGroupDataUrlBuilder(result.id);
      return {
        id: result.id,
        name: result.name,
        displayName: result.display_name,
        open: serviceGroupCurrentState?.open || false,
        url: url,
        checkedItems: {},
        reloadTrigger: new Date(),
      };
    })
    .filter(x => x != null);
  const sortedTableState = (newTableState as ComponentState).sort((a, b) =>
    a.name.localeCompare(b.name),
  );
  tableStateSetter(sortedTableState as ComponentState);
};

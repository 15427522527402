import React, { useEffect } from 'react';
import { Tab, Tabs, Box, Typography } from '@mui/material';
import { IState } from 'interfaces/redux/IState';
import { useSelector } from 'react-redux';
import { NoteBarTrigger } from 'constants/enums';
import { IProps, ITabContentProps } from './types';

export const TabContent: React.FC<ITabContentProps> = (props: ITabContentProps) => {
  return (
    <div role="tabpanel" hidden={props.value !== props.index}>
      {props.value === props.index && (
        <Box>
          <Typography>{props.children}</Typography>
        </Box>
      )}
    </div>
  );
};

export const HorizontalTabs: React.FC<IProps> = (props: IProps) => {
  const noteBar = useSelector((state: IState) => state.noteBar);
  const [value, setValue] = React.useState<number>(props.defaultSelected || 0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const valueOnMonted = noteBar.trigger === NoteBarTrigger.Notes ? 1 : 0;
    setValue(valueOnMonted);
  }, [props.visible]);

  return (
    <div>
      <Tabs indicatorColor="primary" textColor="primary" value={value} onChange={handleChange}>
        {props.tabs.map((tab, idx) => {
          return <Tab key={`horizontal-tab-${idx}`} label={tab.label} />;
        })}
      </Tabs>
      {props.tabs.map((tab, idx) => {
        return (
          <TabContent key={`horizontal-tab-content-${idx}`} value={value} index={idx}>
            {tab.content}
          </TabContent>
        );
      })}
    </div>
  );
};

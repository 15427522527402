import React from 'react';
import BlueContainer from '../components/blue-container';
import PoweredBy from '../components/powered-by';
import Paragraph from '../components/paragraph';
import Button from '../components/button';

export default ({ onNext }) => (
  <BlueContainer>
    <Paragraph type="title">Medication effectiveness questionnaire</Paragraph>
    <hr />
    <Paragraph type="subTitle">
      Please select your BEST answer to the following question(s).
    </Paragraph>
    <Button onClick={() => onNext()}>Start</Button>
    <PoweredBy />
  </BlueContainer>
);

import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchTaskCounts } from 'actions/action-tasks';
import {
  DUR_TRIGGER_CHECK_INTERACTION_REQUEST,
  DUR_TRIGGER_CHECK_INTERACTION_SUCCESS,
  DUR_TRIGGER_CHECK_INTERACTION_FAILURE,
  DUR_STATUS_TRANSITION_REQUEST,
  DUR_STATUS_TRANSITION_SUCCESS,
  DUR_STATUS_TRANSITION_FAILURE,
  DUR_CREATE_NEW_REQUEST,
  DUR_CREATE_NEW_SUCCESS,
  DUR_CREATE_NEW_FAILURE,
  DUR_FETCH_HISTORY_REQUEST,
  DUR_FETCH_HISTORY_SUCCESS,
  DUR_FETCH_HISTORY_FAILURE,
  DUR,
  BULK_TASKS_UPDATED,
  DUR_FETCH_BY_ID_SUCCESS,
  DUR_FETCH_BY_ID,
} from '../constants';
import HTTP from '../services/http';

function* workerTriggerDurCheckInteraction({ patientId, source, sourceValue }) {
  try {
    const url = 'tasks/dur/interactions';
    const response = yield call(() =>
      HTTP.post(url, { patient_id: patientId, source, sourceValue }),
    );
    // Only add it to the store if DUR task was created effectively.
    if (response.data.task && response.data.task.id) {
      yield put({
        type: DUR_TRIGGER_CHECK_INTERACTION_SUCCESS,
        payload: {
          data: {
            updated_tasks: [
              { ...response.data.task, taskType: DUR, type: 'Drug Utilization Review' },
            ],
          },
        },
      });
    }
  } catch (e) {
    yield put({ type: DUR_TRIGGER_CHECK_INTERACTION_FAILURE, payload: e });
  }
}

function* workerSetDurStatus({ durId, statusId }) {
  try {
    const url = `tasks/dur/${durId}`;
    const response = yield call(() => HTTP.patch(url, { status_id: statusId }));
    yield put({
      type: DUR_STATUS_TRANSITION_SUCCESS,
      payload: {
        data: {
          updated_tasks: [response.data.task],
        },
      },
    });
  } catch (e) {
    yield put({ type: DUR_STATUS_TRANSITION_FAILURE, payload: e });
  }
}

function* workerCreateNewDur({ patientId, serviceGroupId, followUpDate }) {
  try {
    const url = 'tasks/dur/generate';
    const response = yield call(() =>
      HTTP.post(url, {
        patient_id: patientId,
        service_group_id: serviceGroupId,
        follow_up_date: followUpDate,
      }),
    );
    if (response && response.data) {
      yield put({
        type: DUR_CREATE_NEW_SUCCESS,
        payload: {
          data: {
            added_task: [response.data.task],
          },
        },
      });
      yield put(fetchTaskCounts());
    }
  } catch (e) {
    yield put({ type: DUR_CREATE_NEW_FAILURE, payload: e });
  }
}

function* workerFetchDurHistory({ patientId, allSnapshots = false }) {
  try {
    const url = `patients/${patientId}/drug-utilization-review/history`;
    const response = yield call(() => HTTP.get(url, { params: { all_snapshots: allSnapshots } }));
    yield put({
      type: DUR_FETCH_HISTORY_SUCCESS,
      payload: {
        data: {
          history: response.data,
        },
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error on DUR_FETCH_HISTORY_FAILURE: ', e);
    yield put({ type: DUR_FETCH_HISTORY_FAILURE, payload: e });
  }
}

function* workerFetchDurById({ payload }) {
  try {
    const url = `tasks/dur/${payload.id}`;
    const response = yield call(() => HTTP.get(url));
    const [durTask] = response.data?.task;
    yield put({
      type: DUR_FETCH_BY_ID_SUCCESS,
      payload: {
        data: {
          task: durTask,
        },
      },
    });
  } catch (e) {
    yield put({ type: DUR_FETCH_HISTORY_FAILURE, payload: e });
  }
}

export function* watcherDURSaga() {
  yield takeLatest(DUR_TRIGGER_CHECK_INTERACTION_REQUEST, workerTriggerDurCheckInteraction);
  yield takeLatest(DUR_STATUS_TRANSITION_REQUEST, workerSetDurStatus);
  yield takeLatest(DUR_CREATE_NEW_REQUEST, workerCreateNewDur);
  yield takeLatest(DUR_FETCH_HISTORY_REQUEST, workerFetchDurHistory);
  yield takeLatest(DUR_FETCH_BY_ID, workerFetchDurById);
}

import React from 'react';
import { Moment } from 'moment';

import { Controller } from 'react-hook-form';
import { renderDateTimePicker as RenderDateTimePicker } from 'components/form/datepicker/datetime-picker';

import { IControlledFormField } from '../types';

interface IProps extends IControlledFormField {
  fullWidth?: boolean;
  defaultValue: Moment | null;
}

export const ControlledDateTimePicker = (props: IProps): JSX.Element => {
  return (
    <Controller
      defaultValue={props.defaultValue}
      control={props.control}
      name={props.name}
      rules={props.validations || {}}
      render={(ctrlProps: any) => (
        <RenderDateTimePicker
          fullWidth
          label={props.label}
          input={{
            value: ctrlProps?.field?.value,
            onChange: ctrlProps?.field?.onChange,
            onBlur: ctrlProps?.field?.onBlur,
          }}
          disabled={props.disabled}
          meta={props.inputMetaData || {}}
          data-qa-id={props.qaId}
        />
      )}
    />
  );
};

// TODO - Move color to theme
export const styles = theme => ({
  reconciliationCard: {
    margin: 30,
  },
  reconciliationCardHeaderContainer: {
    backgroundColor: theme.palette.primary.headerCardBackground.main,
    paddingLeft: 20,
  },
  reconciliationCardFooterContainer: {
    backgroundColor: theme.palette.primary.headerCardBackground.main,
    height: 50,
    paddingLeft: 20,
  },
  reconciliationCardContentContainer: {
    height: '80%',
    overflow: 'auto',
  },
  reconcileBtn: {
    marginRight: 15,
  },
  tagContainer: {
    borderRadius: 5,
    paddingLeft: 10,
    paddingRight: 10,
    color: theme.palette.primary.white,
    marginRight: 10,
    backgroundColor: theme.palette.primary.success,
  },
  highlight: {
    backgroundColor: theme.palette.primary.yellow,
  },
  label: {
    marginRight: 5,
  },
  subHeader: {
    marginTop: 25,
  },
});

import React, { useMemo } from 'react';
import { Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import Divider from 'components/divider';
import PatientDischarge from './patient-discharge';
import PatientReenroll from './patient-reenroll';

const useStyles = makeStyles(theme => ({
  patientInfoContainer: {
    margin: '15px 30px 30px 30px',
    borderRadius: 5,
    paddingBottom: theme.spacing(2),
    boxShadow: `1px 1px 3px 0px rgba(0, 0, 0, 0.2),
      -1px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12)`,
  },
  patientStatusTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  activeClinicRow: {
    marginBottom: theme.spacing(-2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  dischargedClinicRow: {
    backgroundColor: theme.palette.primary.offWhite,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export const PatientStatus = ({
  patient,
  patientClinicsDischarged,
  patientClinicsNotDischarged,
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.patientInfoContainer}>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Typography className={classes.patientStatusTitle}>Patient Status:</Typography>
          </Grid>
          <Divider />
        </Grid>

        <Grid item xs={12} className={classes.activeClinicRow}>
          {patientClinicsNotDischarged?.length
            ? patientClinicsNotDischarged.map(clinic => (
                <PatientDischarge
                  key={`${patient.id}-clinic-${clinic.id}`}
                  clinicId={clinic.id}
                  verifyValue={clinic}
                />
              ))
            : null}
        </Grid>

        <Grid item xs={12} className={classes.dischargedClinicRow}>
          {patientClinicsDischarged?.length
            ? patientClinicsDischarged.map(clinic => (
                <PatientReenroll
                  key={`${patient.id}-clinic-${clinic.id}`}
                  clinic={clinic}
                  patient={patient}
                />
              ))
            : null}
        </Grid>

        {!patient.deceased && (
          <Grid item xs={12} className={classes.activeClinicRow}>
            <PatientDischarge
              verifyValue={{ name: `${patient.first_name} ${patient.last_name}`, id: patient.id }}
              isDeceasedType
            />
          </Grid>
        )}

        {!!patient.deceased && (
          <Grid item xs={12} className={classes.deceasedPatient}>
            <Typography variant="body2">Patient is deceased</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default PatientStatus;

import React, { useImperativeHandle, useState, forwardRef } from 'react';
import { Modal, Tabs, Tab, Typography, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import { TabLabel } from '../tab-label/index';
import { PatientEnrollmentTab, PATIENT_ENROLLMENT_TAB_ID } from '../patient-enrollment-tab/index';
import { ADD_TAB_ID } from './types';
import { styles } from './modal-with-tabs.styles';

const useStyles = makeStyles(styles);

function ModalWithTabsCmp(
  { tabs, instructions, children, onTabClose, onTabChange, tabPropsGetter, onClose, ...props },
  ref,
) {
  const [selectedTab, setSelectedTab] = useState(0);
  const classes = useStyles(styles);
  const [showPatientEnrollmentTab, setShowPatientEnrollmentTab] = useState(false);

  useImperativeHandle(ref, () => ({
    setSelectedTab,
  }));

  const handleTabsOnChange = (event, tabIdx) => {
    setSelectedTab(tabIdx);
    const tab = tabs[tabIdx];

    if (onTabChange) {
      onTabChange(tab, tabIdx);
    }
  };

  // If the last tab gets closed but is currently selected,
  // set the selected tab to what is now the last tab.
  // Note - a tab may be closed by action taken outside of this component.
  React.useEffect(() => {
    const numberOfTabs = tabs.length;
    if (selectedTab >= numberOfTabs && numberOfTabs !== 0) {
      setSelectedTab(numberOfTabs - 1);
    }
    setShowPatientEnrollmentTab(selectedTab === 0);
  }, [tabs.length, selectedTab]);

  const getTabLabels = tab => {
    switch (tab.id) {
      case PATIENT_ENROLLMENT_TAB_ID:
        return <TabLabel tab={tab} className={classes.tabLabel} />;
      case ADD_TAB_ID:
        return null;
      default:
        return (
          <TabLabel
            tab={tab}
            onClose={() => {
              onTabClose(tab);
            }}
            className={classes.tabLabel}
          />
        );
    }
  };

  return (
    <Modal onClose={onClose} {...props}>
      <div className={classes.container}>
        <div className={classes.modalHeader}>
          <Typography className={classes.modalTitle}>Enrollment</Typography>
          <IconButton className={classes.modalCloseButton} onClick={onClose} size="large">
            <Close />
          </IconButton>
        </div>
        <div className={classes.modalContent}>
          <Tabs
            value={selectedTab}
            onChange={handleTabsOnChange}
            variant="scrollable"
            scrollButtons
            textColor="primary"
            className={classes.tabs}
            classes={{ scrollButtons: classes.scrollButtons }}
            allowScrollButtonsMobile
          >
            {tabs.map(tab => (
              <Tab
                key={tab.label}
                icon={tab.icon}
                classes={{ root: classes.tab, selected: classes.selectedTab }}
                label={getTabLabels(tab)}
              />
            ))}
          </Tabs>
          <div className={classes.content}>
            {instructions && !showPatientEnrollmentTab ? (
              <div className={classes.instructions}>{instructions}</div>
            ) : null}
            {tabs.map((tab, index) =>
              tab.id !== PATIENT_ENROLLMENT_TAB_ID ? (
                React.Children.map(children, child => (
                  <div hidden={index !== selectedTab} key={tab.label}>
                    {React.cloneElement(child, {
                      tab,
                      ...(tabPropsGetter ? tabPropsGetter(tab) : {}),
                    })}
                  </div>
                ))
              ) : (
                <PatientEnrollmentTab visible={showPatientEnrollmentTab} />
              ),
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export const ModalWithTabs = forwardRef(ModalWithTabsCmp);

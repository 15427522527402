import React from 'react';
import { Table, TableHead, TableCell, TableRow, TableBody } from '@mui/material';

export default props => {
  const { columns, data } = props;
  return (
    <Table>
      {columns.length > 0 && (
        <TableHead>
          <TableRow>
            {columns.map(col => (
              <TableCell key={col.id || col.name}>{col.name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {data.map((d, index) => (
          <TableRow key={d.id || index}>
            {columns.map(col => (
              <TableCell key={col.id || col.name}>
                {col.getValue ? col.getValue(d) : d[col.id]}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

import React, { useCallback } from 'react';
import { withStyles, WithStyles } from '@mui/styles';
import { Grid, Button } from '@mui/material';
import { ShippingClient } from 'clients/shipping-client';
import { buildQaId } from 'utils/build-qa-id';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import axios, { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { editFillDeliveryConfirmation, editTask } from 'actions/action-tasks';
import { FillDeliveryConfirmationStatus } from 'interfaces/enums/TaskStatuses/FillDeliveryConfirmationStatus';
import { IPrintLabelButton } from './types';
import { ShippingStyles } from './shipping.styles';
import { FDC } from '../../constants/index';

const qaIdBuilder = buildQaId('shipping');

interface IShippingProps extends IPrintLabelButton, WithStyles<typeof ShippingStyles> {}

const VoidLabelButton = (props: IShippingProps): JSX.Element | null => {
  const {
    classes,
    orderId,
    setPrintLabelClicked,
    setFirstTimePrinting,
    currentFdcInformation,
    setShowManualVoidMessage,
    setFdcOrderDetails = () => {},
  } = props;
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState<boolean>(false);

  const getVoidLabel = useCallback(
    async (orderIdValue: string) => {
      setLoading(true);
      try {
        const response = await ShippingClient.voidLabel(orderIdValue);

        const updatedTask = {
          id: currentFdcInformation?.id,
          taskType: FDC,
          therapy_id: currentFdcInformation?.therapyId,
          status_id: FillDeliveryConfirmationStatus.Packed,
        };
        dispatch?.(await editTask(updatedTask, FDC));
        dispatch?.(editFillDeliveryConfirmation(updatedTask));
        setFdcOrderDetails(null);
        setPrintLabelClicked?.(false);
        setFirstTimePrinting?.(true);
        if (response?.data?.message === 'requires manual voiding') {
          setShowManualVoidMessage?.(true);
        } else {
          dispatch(notifySuccess('The order label has been voided.'));
        }
      } catch (error) {
        // Provide the user with a descriptive message, including the AxiosError message if present
        let responseErrorMessage;
        if (axios.isAxiosError(error)) {
          const responseError = error as AxiosError<{ message?: string }>;
          responseErrorMessage =
            responseError.response?.data?.message ?? responseError.response?.data;
        } else {
          responseErrorMessage = null;
        }
        const generateVoidLabelMessage = 'Unable to void/cancel shipping label.';
        const notifyMessage =
          responseErrorMessage == null
            ? generateVoidLabelMessage
            : `${generateVoidLabelMessage} ${responseErrorMessage}`;
        dispatch(notifyError(notifyMessage));
      }
      setLoading(false);
    },
    [
      currentFdcInformation?.id,
      currentFdcInformation?.therapyId,
      dispatch,
      setFirstTimePrinting,
      setPrintLabelClicked,
      setShowManualVoidMessage,
      setFdcOrderDetails,
    ],
  );

  return (
    <Grid item className={classes.voidLabelButton}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => getVoidLabel(orderId)}
        data-qa-id={qaIdBuilder('button.void-label')}
        disabled={loading}
      >
        Void Label
      </Button>
    </Grid>
  );
};

export default withStyles(ShippingStyles)(VoidLabelButton);

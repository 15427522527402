import React, { useState } from 'react';
import { ListItem, List, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReviewItem from 'components/fc-self-service/components/review-item';
import Question from 'components/fc-self-service/components/question';

const EmptyCircle = (
  <div
    style={{
      height: '16px',
      width: '16px',
      border: '2px solid green',
      borderRadius: '50%',
      marginRight: '3px',
      marginLeft: '2px',
      padding: '2px',
    }}
  />
);

const itemLookupByKey: Record<string, string> = {
  sharps_container: 'Sharps Container',
  alcohol_swabs: 'Alcohol Swabs',
  adhesive_bandages: 'Adhesive Bandages',
  not_needed: 'No Supplies Needed',
  oral_syringe: 'Oral Syringe',
  medicine_dispensing_cup: 'Medicine Dispensing Cup',
};

const AncillarySupplies = (props: any) => {
  const { classes } = props;
  const { saveAnswer, isReview, currentAnswers, onEdit, nextQuestion } = props;
  const keyName = 'ancillary_supplies';
  const questionText = 'Do you need any of these supplies';
  const questionTextReview = questionText;
  const orderContainsALiquid = currentAnswers.therapies.reduce(
    (acc: any, therapy: any) => acc || (therapy.included && therapy.is_liquid),
    false,
  );
  if (!orderContainsALiquid) {
    delete currentAnswers.ancillary_supplies.oral_syringe;
    delete currentAnswers.ancillary_supplies.medicine_dispensing_cup;
  } else if (!Object.keys(currentAnswers.ancillary_supplies).includes('oral_syringe')) {
    currentAnswers.ancillary_supplies.oral_syringe = false;
    currentAnswers.ancillary_supplies.medicine_dispensing_cup = false;
  }
  const ancillarySupplies: any[] = Object.keys(currentAnswers.ancillary_supplies).map(
    (key: string) => ({
      key: key,
      name: itemLookupByKey[key],
      included: currentAnswers.ancillary_supplies[key],
    }),
  );

  const [ancillarySuppliesState, setAncillarySuppliesState] = useState(ancillarySupplies);
  const [atLeastOneItemSelected, setAtLeastOneItemSelected] = useState(
    ancillarySupplies.reduce((acc: boolean, item: any) => {
      return item.included || acc;
    }, false),
  );

  const internalHandler = (val: any) => {
    if (val === 'Next') {
      const simpleValue = ancillarySuppliesState.reduce((acc, item) => {
        acc[item.key] = item.included;
        return acc;
      }, {});
      saveAnswer({ keyName, value: simpleValue });
      nextQuestion();
    } else {
      const newValues = ancillarySuppliesState.map(item =>
        item.key === val.key ? { ...item, included: !item.included } : item,
      );
      if (val.key === 'not_needed') {
        const no_supplies_needed = !val.included;
        if (no_supplies_needed) {
          newValues.forEach(item => {
            if (item.key !== 'not_needed') {
              item.included = false;
            }
          });
        }
      } else {
        const at_least_one_item_selected = newValues.some(
          (item: any) => item.key !== 'not_needed' && item.included,
        );
        if (at_least_one_item_selected) {
          newValues.forEach(item => {
            if (item.key === 'not_needed') {
              item.included = false;
            }
          });
        }
      }
      setAncillarySuppliesState(newValues);
      const atLeastOneItemSelected: boolean = newValues.reduce((acc: boolean, item: any) => {
        return item.included || acc;
      }, false);
      setAtLeastOneItemSelected(atLeastOneItemSelected);
    }
  };

  if (!isReview) {
    return (
      <Question
        questionText={questionText}
        keyName={keyName}
        ancillary_supplies_list={ancillarySuppliesState}
        response={internalHandler}
        buttons={{ button1Text: 'Next', button1Value: 'Next' }}
        disableButton1={!atLeastOneItemSelected}
      />
    );
  }

  if (!props.answerOptions.isColdChain) {
    return null;
  }

  return (
    <ReviewItem
      key={props.key}
      questionText={questionTextReview}
      currentAnswer=""
      onEdit={onEdit}
      keyName={keyName}
    >
      <List className={classes.medicationList} dense>
        {ancillarySuppliesState.map((supply: any, index: number) => (
          <ListItem disableGutters key={supply.key} data-qa-id={`ancillary-supply-${index}`}>
            {supply.included ? <CheckCircleIcon className={classes.icon} /> : EmptyCircle}
            <Typography className={classes.medicationList}>{supply.name}</Typography>
          </ListItem>
        ))}
      </List>
    </ReviewItem>
  );
};
export default AncillarySupplies;

import { FETCH_BENEFITS_INVESTIGATION_HISTORY } from '../constants';

export default (
  state = {
    open: false,
  },
  { type, payload },
) => {
  switch (type) {
    case FETCH_BENEFITS_INVESTIGATION_HISTORY:
      if (payload) {
        return {
          ...state,
          history: payload.data,
        };
      }
      return state;
    default:
      return state;
  }
};

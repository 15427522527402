export const styles = theme => ({
  taskIconSmall: {
    lineHeight: 2.2,
    width: 30,
    height: 30,
    border: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.task.borderColor,
    backgroundColor: theme.palette.primary.task.backgroundColor,
    borderRadius: 5,
    textAlign: 'center',
    marginRight: 5,
    paddingRight: 6,
    paddingLeft: 6,
  },
  taskNameSmall: {
    fontSize: theme.font.mediumFontSize,
    fontWeight: 'bold',
    color: theme.palette.primary.white,
  },
  taskIconMedium: {
    lineHeight: 2.6,
    width: 40,
    height: 40,
    border: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.task.borderColor,
    backgroundColor: theme.palette.primary.grey12,
    borderRadius: 5,
    textAlign: 'center',
    marginRight: theme.spacing(1),
  },
  taskNameMedium: {
    fontSize: theme.font.largeFontSize,
    fontWeight: 'bold',
    color: theme.palette.primary.white,
  },
  taskSidebar: {
    lineHeight: 2.8,
    width: 42,
    height: 42,
    border: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.task.borderColor,
    borderRadius: 5,
    textAlign: 'center',
    fontSize: theme.font.largeFontSize,
    fontWeight: 'bold',
    color: theme.palette.primary.task,
  },
  taskChecked: {
    backgroundColor: theme.palette.primary.task.checked,
    color: theme.palette.primary.white,
  },
  taskNumberCount: {
    position: 'absolute',
    top: 0,
    right: 0,
    lineHeight: 1.5,
    height: 18,
    minWidth: 18,
    width: 'auto',
    paddingLeft: 1,
    paddingRight: 1,
    marginRight: 2,
    fontSize: theme.font.smallFontSize,
    backgroundColor: theme.palette.primary.task.number,
    borderRadius: 5,
    color: theme.palette.primary.white,
  },
  taskNumberCountLong: {
    width: 'auto',
  },
  customIconSidebar: {
    width: 26,
    height: 26,
    marginTop: 8,
  },
  taskNumberChecked: {
    backgroundColor: theme.palette.error.main,
  },
  upshiftForLabel: {
    color: theme.palette.primary.important,
    transform: 'rotate(315deg) translate(3px, -2px)',
  },
  pinnedNote: {
    color: theme.palette.primary.important,
    transform: 'rotate(315deg) translate(0px, -4px)',
  },
  unPinnedNote: {
    color: theme.palette.primary.grey12,
  },
  inlineArchived: {
    color: theme.palette.primary.trellisBlue,
  },
  inlineArchivedTop: {
    color: theme.palette.primary.steelGrey,
    paddingRight: 3,
  },
});

import React from 'react';
import { ContactMethodTypes } from 'constants/enums';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { PreferredContactEhr } from './preferred-contact-ehr';
import { PreferredContactPhone } from './preferred-contact-phone';
import { PreferredContactEmail } from './preferred-contact-email';

export const PreferredContactFactory = (): JSX.Element | null => {
  const patient = useTypedSelector(state => state.patient);
  if (patient && patient.preferred_contact_method) {
    switch (patient.preferred_contact_method) {
      case ContactMethodTypes.EHRMessage:
        return <PreferredContactEhr />;
      case ContactMethodTypes.Email:
        return <PreferredContactEmail />;
      case ContactMethodTypes.SMS:
        return <PreferredContactPhone sms />;
      case ContactMethodTypes.Phone:
        return <PreferredContactPhone sms={false} />;
    }
  }

  return null;
};

import React, { PropsWithChildren, ReactNode } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { Box, CircularProgress, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { LoadingOverlayStyles } from './loading-overlay.styles';

export enum SpinnerTypeEnum {
  RotatingLines,
  TailSpin,
}

interface IProps {
  open: boolean;
  spinnerType?: SpinnerTypeEnum;
}

interface ILoadingOverlayProps extends WithStyles<typeof LoadingOverlayStyles>, IProps {}

interface ICustomLoaderProps extends WithStyles<typeof LoadingOverlayStyles>, IProps {
  loaded: boolean;
  message?: string;
}

const LoadingIndicator = (props: ILoadingOverlayProps): JSX.Element | null => {
  const { classes, spinnerType = SpinnerTypeEnum.TailSpin, open } = props;

  const renderSpinner = () => {
    switch (spinnerType) {
      case SpinnerTypeEnum.RotatingLines:
        return (
          <Box className={classes.loadingSpinner}>
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="50"
              visible
            />
          </Box>
        );
        break;
      case SpinnerTypeEnum.TailSpin:
      default:
        return <CircularProgress className={classes.loadingSpinner} />;
    }
  };

  return open ? (
    <Box className={classes.container}>
      <Box className={classes.backdrop} />
      {renderSpinner()}
    </Box>
  ) : null;
};

const StyledLoadingIndicator = withStyles(LoadingOverlayStyles)(
  LoadingIndicator,
) as React.FC<IProps>;

const CustomLoader = (props: PropsWithChildren<ICustomLoaderProps>): ReactNode | null => {
  const { classes, loaded, children, message, spinnerType } = props;

  if (loaded) {
    return children;
  }
  return (
    <Box className={classes.loaderContainer}>
      <StyledLoadingIndicator open={!loaded} spinnerType={spinnerType} />
      {!!message && <Typography>{message}</Typography>}
    </Box>
  );
};

const StyledCustomLoader = withStyles(LoadingOverlayStyles)(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  CustomLoader as any,
) as React.FC<IProps>;

export { StyledCustomLoader as Loader };

export default StyledLoadingIndicator;

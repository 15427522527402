export default () => {
  const helpers = {};
  let builder = {};

  const instance = {
    fields: [],
    forms: [],
  };

  const findForm = name => instance.forms.find(it => it.name === name);

  const form = name => {
    let existing = findForm(name);
    if (!existing) {
      existing = {
        name,
        fields: [],
      };
      instance.forms.push(existing);
    }
    helpers.currentForm = existing;
    return builder;
  };

  const addField = field => {
    helpers.currentForm.fields.push(field);
    return builder;
  };

  const build = () => instance;

  builder = {
    form,
    addField,
    build,
  };
  return builder;
};

import { createStyles } from '@mui/styles';
import { defaultTheme } from 'lib/themes';

export const styles = createStyles({
  container: {
    textAlign: 'center',
    padding: 47,
    color: defaultTheme.palette.primary.deepBlue,
    minHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  brandMock: {
    opacity: 0.2,
  },
  divider: {
    borderColor: `${defaultTheme.palette.primary.deepBlue}33`,
    borderTop: 0,
  },
  button: {
    'color': defaultTheme.palette.primary.white,
    '&.Mui-disabled': { color: defaultTheme.palette.primary.white, opacity: 0.5 },
  },
  failedAttemptsMessage: {
    color: 'red',
    paddingBottom: '25px',
  },
});

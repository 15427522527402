import { addMedication, updateMedication } from 'actions/action-medications';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { EDIT_MEDICATION_FORM_FULL } from 'constants/index';
import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import compose from 'recompose/compose';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { convertToArborDate } from 'models/time/arbor-date';
import { renderDropdown, renderCheckbox, renderTextField } from 'components/form/field/redux-field';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import AutocompleteLabel from 'components/form/field/autocomplete-label';
import { medicationStatusCodes } from 'constants/lists';
import { setMedicationExcludedValue } from 'services/utils/reconciliation-service';
import AutocompleteMinigrid from 'components/form/field/render-minigrid-autocomplete';
import { required, validateNumber, validateDate } from 'components/form/validation/validation';
import { editTherapy } from 'actions/action-therapies';
import { fetchGpis, specialtyTypeFormatter } from 'services/utils/therapy-service';
import { styles } from '../patient-clinical-styles';

const EditMedicationFormFull = props => {
  const {
    patient,
    cancelHandler,
    submitCallback,
    handleSubmit,
    medication,
    stagingMedication,
    addMedication, //eslint-disable-line
    updateMedication, //eslint-disable-line
    submitting,
    classes,
    medicationCategoryCodes,
    selectedMedication,
  } = props;

  const excludedValues = medication.excluded_values || {};

  const onSubmit = values => {
    const medicationPayload = {
      patient_id: patient.id,
      ndc: values.therapy.ndc,
      start_dt: convertToArborDate(values.start_dt).getUtcDate(),
      end_dt: convertToArborDate(values.end_dt).getUtcDate(),
      status_code: values.status_code,
      category_code: values.category_code,
      number_of_refills: values.number_of_refills,
      is_prn: values.is_prn,
      prn_instructions: values.prn_instructions,
      prn_reason: values.prn_reason,
    };
    if (medication.group_id) {
      medicationPayload.group_id = medication.group_id;
    }
    if (medication.id) {
      medicationPayload.id = medication.id;
      updateMedication(medicationPayload);
    } else {
      addMedication({
        ...stagingMedication,
        ...medicationPayload,
      });
    }
    setMedicationExcludedValue(values, medication, excludedValues);
    if (submitCallback) {
      submitCallback(excludedValues);
    }
    cancelHandler();
  };

  const handleFetchGpisOptions = searchText =>
    // eslint-disable-next-line no-confusing-arrow
    fetchGpis(searchText).then(result =>
      result.data
        ? result.data.drugs.map(therapy => ({
            ...therapy,
            id: therapy.ndc,
            label: therapy.drug_info,
          }))
        : [],
    );

  return (
    <Grid
      container
      justifyContent="center"
      spacing={1}
      className={(classes.formRowContainer, classes.formContainer)}
    >
      <Grid item xs={12}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Grid container>
            <Grid item xs={6}>
              <Field
                name="therapy"
                label="Medication *"
                component={AutocompleteMinigrid}
                validate={[required]}
                fetchOptions={handleFetchGpisOptions}
                hint="Search by Medication or GPI or NDC"
                columnsToShow={{
                  gpi: 'GPI',
                  drug_name: 'Drug Name',
                  dosage_form: 'Form',
                  dose: 'Dose',
                  ldd: 'LDD',
                  specialty_type: 'Specialty Type',
                  ndc: 'NDC',
                }}
                minSearchLength={2}
                valueFormatter={specialtyTypeFormatter}
              />
            </Grid>
            <Grid item xs={3}>
              <AutocompleteLabel idValue={selectedMedication.ndc} defaultIdValue="" label="NDC" />
            </Grid>
            <Grid item xs={3}>
              <Field
                label="Start Date"
                name="start_dt"
                component={renderDatePicker}
                validate={[validateDate]}
                placeholder="mm/dd/yyyy"
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                label="End Date"
                name="end_dt"
                component={renderDatePicker}
                validate={[validateDate]}
                placeholder="mm/dd/yyyy"
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                label="Status Code"
                name="status_code"
                component={renderDropdown}
                fields={medicationStatusCodes}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                label="Category Code"
                name="category_code"
                component={renderDropdown}
                fields={medicationCategoryCodes.map(category => ({
                  label: category.category,
                  value: category.code,
                }))}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                label="Number of Refills"
                name="number_of_refills"
                validate={[validateNumber]}
                component={renderTextField}
              />
            </Grid>
            <Grid item xs={3}>
              <Field label="Is PRN" name="is_prn" component={renderCheckbox} />
            </Grid>
            <Grid item xs={3}>
              <Field label="PRN Instructions" name="prn_instructions" component={renderTextField} />
            </Grid>
            <Grid item xs={3}>
              <Field label="PRN Reason" name="prn_reason" component={renderTextField} />
            </Grid>

            <Grid item xs={10} />
            <Grid item xs={2}>
              <ConfirmationPanel
                className={classes.medFormConfirmation}
                cancelButtonName="edit_medication_cancel_button"
                submitButtonName="edit_medication_submit_button"
                handleCancel={cancelHandler}
                justifyContent="space-between"
                disableSubmit={submitting}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

function mapStateToProps(state, props) {
  const { patient } = state;
  const { medication } = props;
  const selector = formValueSelector(EDIT_MEDICATION_FORM_FULL);
  const selectedMedication = selector(state, 'therapy') || {};

  return {
    patient,
    initialValues: {
      therapy: {
        ...medication,
        id: medication.ndc,
        label: medication.drug_name,
      },
      ndc: medication.ndc,
      active: medication.active,
      start_dt: medication.start_dt,
      end_dt: medication.end_dt,
      source_id: medication.source_id,
      status_code: medication.status_code,
      category_code: medication.category_code,
      number_of_refills: medication.number_of_refills,
      is_prn: medication.is_prn,
      prn_instructions: medication.prn_instructions,
      prn_reason: medication.prn_reason,
    },
    medicationCategoryCodes: state.lookups.medicationCategoryCodes,
    selectedMedication,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    addMedication,
    updateMedication,
    editTherapy,
  }),
)(reduxForm({ form: EDIT_MEDICATION_FORM_FULL })(EditMedicationFormFull));

import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { dateSorting, stringFilter } from 'helpers/react-table';
import { useTable, usePagination, useExpanded, useSortBy, useFilters } from 'react-table-v7';
import classnames from 'classnames';
import { fetchMedicationGroups } from 'actions/action-medications';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPatientArchivedTasks } from 'actions/action-patient';
import { DC, RS } from 'constants/index';
import { getDataType } from 'services/utils/data-collect';
import { convertToArborDate } from 'models/time/arbor-date';
import { useClinicalData } from '../../../containers/patient/clinical/use-clinical-data';
import { styles } from './follow-up-data-collect-modal-style';

const PreviousDataCollectComp = ({ selectedPatientId, taskType }) => {
  const classes = styles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const clinicalDataItems = useClinicalData(selectedPatientId);
  const lookups = useSelector(state => state.lookups);
  const therapies = useSelector(state => state.therapies.data);
  const { clinicalDataTypes, users } = lookups;
  const patientState = useSelector(state => state.patient);

  const isLoading = !clinicalDataItems;

  const getSubmittedByUserNameQuestionnaire = (questionnaireData, updatedBy) => {
    if (questionnaireData && questionnaireData.smsSent && questionnaireData.submittedDate) {
      return `${patientState.first_name} ${patientState.last_name}`;
    }
    if (!users || users.length === 0) {
      return 'Unknown User';
    }
    return users.find(user => user.id === updatedBy).username;
  };

  const clinicalDataItemToUIClinicalDataItem = it => {
    const dataType = getDataType(clinicalDataTypes, it.clinicalDataTypeId);
    // eslint-disable-next-line camelcase
    const therapyName = therapies[it?.therapyId]?.drug_name ?? '';
    const assessmentArborDate = convertToArborDate(it.assessmentDate);
    const assessmentDateFormatted = assessmentArborDate.getUtcDate(true);
    const questionnaireData = it.questionnaire;
    const itemQuestionnaireData = questionnaireData
      ? {
          id: questionnaireData.id,
          smsSent: questionnaireData.smsSent,
          submittedDate: questionnaireData.submittedDate,
          submittedBy: questionnaireData.submittedBy,
          answers: questionnaireData.answers,
          lastViewedDt: questionnaireData.lastViewedDt
            ? convertToArborDate(questionnaireData.lastViewedDt, true).getCustomerDate()
            : null,
          score: questionnaireData.score,
        }
      : undefined;

    return {
      id: it.id,
      cannot_complete_reason: it.cannotCompleteReason,
      required: it.required,
      categoryId: dataType.categoryId,
      categoryName: dataType.categoryName,
      typeName: dataType.name,
      dataTypeId: dataType.dataTypeId,
      value: it.value,
      therapyName,
      date: assessmentDateFormatted,
      recordedBy: getSubmittedByUserNameQuestionnaire(questionnaireData, it.updatedBy),
      therapy_id: it.therapyId,
      dataType,
      task_data_collect_id: it.taskDataCollectId,
      task_risk_strat_id: it.taskRiskStratId,
      questionnaireData: itemQuestionnaireData,
    };
  };

  const clinicalData = (clinicalDataItems || [])
    .filter(item => item.value)
    .map(item => clinicalDataItemToUIClinicalDataItem(item))
    .reduce((acc, it) => {
      if (acc.length) {
        let itemExists = false;
        for (const [index, savedItem] of acc.entries()) {
          if (
            it.dataTypeId === savedItem.dataTypeId &&
            it.value === savedItem.value &&
            it.date === savedItem.date &&
            !it.questionnaireData &&
            !savedItem.questionnaireData
          ) {
            if (!savedItem.therapyName.includes(it.therapyName)) {
              savedItem.therapyName += `, ${it.therapyName}`;
            }
            itemExists = true;
          } else if (
            it.questionnaireData &&
            Object.keys(it.questionnaireData).length &&
            savedItem.dataTypeId === it.dataTypeId &&
            ((savedItem.task_data_collect_id &&
              it.task_data_collect_id &&
              savedItem.task_data_collect_id === it.task_data_collect_id) ||
              (savedItem.task_risk_strat_id &&
                it.task_risk_strat_id &&
                savedItem.task_risk_strat_id === it.task_risk_strat_id))
          ) {
            if (it.id > savedItem.id) {
              acc[index] = it;
            }
            itemExists = true;
          }
        }
        if (!itemExists) {
          acc.push(it);
        }
      } else {
        acc.push(it);
      }
      return acc;
    }, []);

  useEffect(() => {
    dispatch(fetchMedicationGroups());
    if (selectedPatientId) {
      // fetching archived DCs to show in the clinical asssesment
      dispatch(fetchPatientArchivedTasks(selectedPatientId, false, [DC]));
    }
  }, [selectedPatientId]); // eslint-disable-line

  useEffect(() => {
    if (clinicalData && clinicalData.length) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [clinicalData]);

  const columnsDisplayOnly = [
    {
      Header: 'Category',
      accessor: 'categoryName',
      sortType: 'basic',
      filterMethod: stringFilter,
      getHeaderProps: () => ({
        data_qa_id: 'category_name_header',
      }),
    },
    {
      Header: 'Type',
      accessor: 'typeName',
      sortType: 'basic',
      filterMethod: stringFilter,
      getHeaderProps: () => ({
        data_qa_id: 'type_name_header',
      }),
      width: 300,
    },
    {
      Header: 'Value',
      accessor: 'value',
      sortType: 'basic',
      filterMethod: (filter, row) => {
        const rowVal =
          typeof row[filter.id] === 'string' ? row[filter.id] : row[filter.id].toString();
        return rowVal && rowVal.toLowerCase().includes(filter.value.toLowerCase());
      },
      getHeaderProps: () => ({
        data_qa_id: 'value_header',
      }),
    },
    {
      Header: 'Therapy',
      accessor: 'therapyName',
      sortType: 'basic',
      filterMethod: stringFilter,
      getHeaderProps: () => ({
        data_qa_id: 'drug_name_header',
      }),
    },
    {
      Header: 'Assessment Date',
      accessor: 'date',
      sortType: 'basic',
      filterMethod: stringFilter,
      sortMethod: dateSorting,
      getHeaderProps: () => ({
        data_qa_id: 'assessment_date_display_header',
      }),
    },
    {
      Header: 'Recorded',
      accessor: 'recordedBy',
      sortType: 'basic',
      filterMethod: stringFilter,
      getHeaderProps: () => ({
        data_qa_id: 'recorded_by_header',
      }),
    },
  ];

  const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter } }) => {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
        className={classes.filterInput}
      />
    );
  };

  const ReactTableComp = ({ columns, data, renderRowSubComponent }) => {
    const filterTypes = React.useMemo(
      () => ({
        text: (rows, id, filterValue) => {
          return rows.filter(row => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      [],
    );

    const defaultColumn = React.useMemo(
      () => ({
        Filter: DefaultColumnFilter,
      }),
      [],
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      row,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        filterTypes,
        initialState: { pageIndex: 0 },
      },
      useFilters,
      useSortBy,
      useExpanded,
      usePagination,
    );

    return (
      <Grid className={classes.clinicalAssessmentTable}>
        <Grid className={classnames(classes.clinicalAssessmentTableContainer, undefined)}>
          <table className={classes.clinicalAssessmentTableMain} {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th className={classes.clinicalAssessmentHeaderTh}>
                      {column.render('Header') && (
                        <>
                          <tr className={classes.headerTr}>
                            <th
                              {...column.getHeaderProps(column.getSortByToggleProps())}
                              className={classes.headerTh}
                            >
                              {column.render('Header')}
                            </th>
                          </tr>
                          <tr className={classes.headerTr}>
                            <th>{column.canFilter ? column.render('Filter') : null}</th>
                          </tr>
                        </>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {!loading && (
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <>
                      <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className={
                                taskType &&
                                taskType === RS &&
                                ['Value', 'Assessment Date'].includes(cell?.column?.Header)
                                  ? `${classes.tbodyTr} ${classes.centerCell}`
                                  : classes.tbodyTr
                              }
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                      {row.isExpanded ? (
                        <tr>
                          <td colSpan={row.cells.length}>{renderRowSubComponent({ row })}</td>
                        </tr>
                      ) : null}
                    </>
                  );
                })}
              </tbody>
            )}
          </table>
        </Grid>
        <Grid container xs={12} className={classes.paginationBtnsContainer}>
          <Grid item xs={4} className={classes.paginationBtns}>
            <button type="button" onClick={() => previousPage(0)} disabled={!canPreviousPage}>
              Previous
            </button>
          </Grid>
          <Grid item xs={2} className={classes.paginationControls}>
            <span>
              Page{'  '}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                value={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: '75px' }}
              />{' '}
              of {pageOptions.length}
            </span>{' '}
          </Grid>
          <Grid item xs={2} className={classes.paginationControls}>
            <span>
              <select
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize} Rows
                  </option>
                ))}
              </select>
            </span>
          </Grid>
          <Grid item xs={4} className={classes.paginationBtns}>
            <button type="button" onClick={() => nextPage()} disabled={!canNextPage}>
              Next
            </button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid className={classes.mainBox}>
      <Grid item xs={12} className={classes.tableContainer}>
        <ReactTableComp
          columns={columnsDisplayOnly}
          data={clinicalData.sort((a, b) => new Date(b.date) - new Date(a.date))}
        />
      </Grid>
    </Grid>
  );
};

export default PreviousDataCollectComp;

import { Action } from 'redux';
import { ClinicalDataItem } from 'interfaces/clinical-data/ClinicalDataResponse';
import {
  ADD_THERAPY,
  ADD_TASK,
  BULK_TASKS_UPDATED,
  CLEAR_CLINICAL_DATA,
  FETCH_CLINICAL_DATA,
  SAVE_CLINICAL_DATA,
  SAVE_QUESTIONNAIRE_ANSWERS,
  SEND_QUESTIONNAIRE,
  SUBMIT_QUESTIONNAIRE,
} from '../constants/index';

export const fetchClinicalData = (payload: ClinicalDataItem[]): FetchClinicalDataAction => ({
  payload,
  type: FETCH_CLINICAL_DATA,
});

export const saveClinicalData = (payload: ClinicalDataItem[]): SaveClinicalDataAction => {
  return {
    payload,
    type: SAVE_CLINICAL_DATA,
  };
};

export const clearClinicalData = (): ClearClinicalDataAction => {
  return {
    type: CLEAR_CLINICAL_DATA,
    payload: null,
  };
};

export const saveQuestionnaireAnswers = (
  payload: ClinicalDataItem[],
): SaveQuestionnaireAnswersAction => {
  return {
    payload,
    type: SAVE_QUESTIONNAIRE_ANSWERS,
  };
};

export const submitQuestionnaire = (payload: ClinicalDataItem[]): SubmitQuestionnaireAction => {
  return {
    payload,
    type: SUBMIT_QUESTIONNAIRE,
  };
};

export const sendQuestionnaire = (payload: any): SendQuestionnaireAction => {
  return {
    payload,
    type: SEND_QUESTIONNAIRE,
  };
};
class FetchClinicalDataAction implements Action {
  readonly type = FETCH_CLINICAL_DATA;

  constructor(public payload: ClinicalDataItem[]) {}
}

class SaveClinicalDataAction implements Action {
  readonly type = SAVE_CLINICAL_DATA;

  constructor(public payload: ClinicalDataItem[]) {}
}

class ClearClinicalDataAction implements Action {
  readonly type = CLEAR_CLINICAL_DATA;

  constructor(public payload: null) {}
}

class SaveQuestionnaireAnswersAction implements Action {
  readonly type = SAVE_QUESTIONNAIRE_ANSWERS;

  constructor(public payload: ClinicalDataItem[]) {}
}

class SubmitQuestionnaireAction implements Action {
  readonly type = SUBMIT_QUESTIONNAIRE;

  constructor(public payload: ClinicalDataItem[]) {}
}

class SendQuestionnaireAction implements Action {
  readonly type = SEND_QUESTIONNAIRE;

  constructor(public payload: any) {}
}

class BulkTasksUpdatedAction implements Action {
  readonly type = BULK_TASKS_UPDATED;

  constructor(public payload: any) {}
}
class AddTherapyAction implements Action {
  readonly type = ADD_THERAPY;

  constructor(public payload: any) {}
}

class AddTaskAction implements Action {
  readonly type = ADD_TASK;

  constructor(public payload: any) {}
}

export type ClinicalDataAction =
  | FetchClinicalDataAction
  | SaveClinicalDataAction
  | ClearClinicalDataAction
  | SaveQuestionnaireAnswersAction
  | SubmitQuestionnaireAction
  | SendQuestionnaireAction
  | BulkTasksUpdatedAction
  | AddTherapyAction
  | AddTaskAction;

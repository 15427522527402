import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import colors from 'lib/themes/colors.module.scss';

const { lightRed, trellisBlue } = colors;

export const styles = (theme: Theme) =>
  createStyles({
    content: {
      textAlign: 'right',
      backgroundColor: `${lightRed}22`,
      borderRadius: 5,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    title: {
      color: lightRed,
      fontWeight: 600,
    },
    error: {
      display: 'block',
      color: trellisBlue,
      textDecoration: 'underline',
      lineHeight: 1.75,
      whiteSpace: 'nowrap',
      cursor: 'pointer',
    },
  });

import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { process } from './helpers';
import Form from './form';
import { styles } from './dynamic-form-styles';

const JsonForm = props => {
  const { jsonForm, formName, classes, dosisRegimenText, ...otherProps } = props;

  const [forms, setForms] = useState(process(jsonForm));

  useEffect(() => {
    setForms(process(jsonForm));
  }, [jsonForm]);

  const form = forms && formName ? forms[formName] : null;

  if (!form) {
    return `Form ${formName} not found`;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Form
          {...otherProps}
          config={form}
          className={classes.form}
          dosisRegimenText={dosisRegimenText}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(JsonForm);

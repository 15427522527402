/* eslint-disable react/function-component-definition */
import React, { Fragment, useMemo } from 'react';
import { Box, Grid, Button, Tooltip } from '@mui/material/';
import { ITask } from 'interfaces/redux/ITasks';
import { useTypedSelector } from 'hooks/use-typed-selector';
import AddIcon from '@mui/icons-material/Add';
import { THERAPY_MED_SYNC } from '../../../../constants';
import { NeedsByDateDialogForm } from '../forms/needsby-date-task-therapy';
import { FollowUpDateDialogForm } from '../forms/followup-date-task-form';

import { Styles } from './table.styles';

interface TaskTableActionSectionProps {
  tasks: ITask[];
  patientId: number;
  handleDisplayAddTaskForm: () => void;
  addTaskOpen: boolean;
}

export const TaskTableActionSection: React.FC<TaskTableActionSectionProps> = (
  props,
): JSX.Element => {
  const currentUserActive = useTypedSelector(state => state?.auth?.currentUser?.active);
  const patient = useTypedSelector(state => state.patient);
  const therapiesSource = useTypedSelector(state => state.therapies.data);
  const fcTasks = useMemo(() => {
    return props.tasks
      .filter(task => task.taskType === 'FC')
      .map(task => {
        if (therapiesSource[task.therapy_id]) {
          return {
            ...task,
            in_med_sync: therapiesSource[task.therapy_id].in_med_sync,
          };
        }
        return {
          ...task,
          in_med_sync: 0,
        };
      }) as (ITask & { in_med_sync: number })[];
  }, [therapiesSource, props.tasks]);
  const nonFCTaskSelected = props.tasks.some(task => task.taskType !== 'FC');
  const therapies = fcTasks.map(item => {
    return {
      id: item.therapy_id,
      patient_id: props.patientId,
      in_med_sync: item.in_med_sync,
    };
  });
  const inMedSync = useMemo(() => {
    return therapies.some(t => {
      return ((t && t.in_med_sync) ?? null) === THERAPY_MED_SYNC.IN_MED_SYNC;
    });
  }, [therapies]);
  return (
    <Box width="100%" py={2}>
      <Grid container spacing={1} justifyContent="flex-end">
        <Grid item>
          <FollowUpDateDialogForm
            titleOverride="Select follow-up date for selected items"
            tasks={props.tasks}
            disabled={props.tasks.length === 0 || props.addTaskOpen}
          />
        </Grid>
        <Grid item>
          <Tooltip
            title={
              inMedSync ? 'Therapy is part of med sync, change the Anchor Date to move the NBD' : ''
            }
            placement="top"
          >
            <Box>
              <NeedsByDateDialogForm
                therapy={therapies}
                disabled={
                  fcTasks.length === 0 || nonFCTaskSelected || props.addTaskOpen || inMedSync
                }
              />
            </Box>
          </Tooltip>
        </Grid>
        <Grid item>
          {currentUserActive && !!patient.active && patient.status !== 'Inactive' && (
            <Button
              sx={Styles.addTaskButton}
              name="add_task_button"
              id="AddTaskButtonTaskTab"
              variant="contained"
              onClick={props.handleDisplayAddTaskForm}
            >
              <AddIcon />
              New Task
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default TaskTableActionSection;

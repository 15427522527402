import React from 'react';
import { Typography, Grid } from '@mui/material';
import { convertToArborDate } from 'models/time/arbor-date';
import withStyles from '@mui/styles/withStyles';
import compose from 'recompose/compose';
import { styles } from './note-styles';

const NoteDisplayDocumentList = props => {
  const { notes, classes, onClick } = props;
  const noteDisplayStyle = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
  };
  if (notes && notes.length > 0) {
    const note = notes[0];
    return (
      <Grid className={classes.noteDocumentContainer} onClick={onClick}>
        <Grid container>
          <Grid item xs={6}>
            <Typography className={classes.ellipsis} variant="caption">
              {note.created_by_user}
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.alignRight}>
            <Typography variant="caption" className={classes.noteDate}>
              {convertToArborDate(note.created, true).getCustomerDate(true, 'MM/DD/YY')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={11}>
            <div className={classes.noteEntry} style={noteDisplayStyle}>
              <span>{note.text}</span>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid className={classes.noteDocumentContainer} onClick={onClick}>
      <Typography variant="body2">+ Add Note</Typography>
    </Grid>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(NoteDisplayDocumentList);

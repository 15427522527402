import React from 'react';
import withStyles from '@mui/styles/withStyles';
import PatientClinical from 'containers/patient/clinical/patient-clinical';
import PatientAllergies from 'containers/patient/clinical/allergies/patient-allergies-list';
// eslint-disable-next-line max-len
import PatientSpecialPopulations from 'containers/patient/clinical/special-populations/patient-special-population-list';
import PatientProblems from 'components/problems/container';
import { windowFeatureIsEnabled } from 'config/window-features';

import { styles } from '../nav-styles';

const Clinical = () => (
  <div>
    <PatientClinical />
    {windowFeatureIsEnabled('patient_allergies') && <PatientAllergies />}
    <PatientProblems />
    <PatientSpecialPopulations />
  </div>
);

export default withStyles(styles, { withTheme: true })(Clinical);

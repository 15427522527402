import React from 'react';
import IconWithTooltip from './icon-with-tooltip';

interface Props {
  expiration?: string;
}

const PaymentMethodStatus: React.FC<Props> = ({ expiration }) => {
  if (!expiration) {
    return <IconWithTooltip state="no_payment_on_file" tooltip="No payment method on file" />;
  }

  const [month, year] = expiration.split('/');
  const expirationDate = new Date(Number(year), Number(month) - 1, 1);
  const now = new Date();
  const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  const monthAfterNext = new Date(now.getFullYear(), now.getMonth() + 2, 1);

  if (expirationDate < now) {
    return (
      <IconWithTooltip
        state="all_payment_methods_expired"
        tooltip="Payment method on file but it has expired"
      />
    );
  }
  if (expirationDate >= nextMonth && expirationDate < monthAfterNext) {
    return (
      <IconWithTooltip
        state="payment_methods_expiring_next_month"
        tooltip="Payment method on file but expiring next month"
      />
    );
  }

  return <IconWithTooltip state="active_payment_on_file" tooltip="Payment method on file" />;
};

export default PaymentMethodStatus;

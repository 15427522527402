import { Moment } from 'moment';
import { IAdvancePatientSearchRequest } from 'services/utils/patient-search-service';
import { DeepPartial } from 'utils/types-util';
import { IFormFields } from './interfaces';

const parseToServiceRequestBody = (
  searchCriteria: DeepPartial<IFormFields>,
): IAdvancePatientSearchRequest => ({
  firstName: searchCriteria?.firstName,
  lastName: searchCriteria?.lastName,
  dob: searchCriteria?.dateOfBirth && (searchCriteria.dateOfBirth as Moment).toDate(),
  mrn: searchCriteria?.medicalRecordNumber,
  phone: searchCriteria?.phone,
});

export { parseToServiceRequestBody };

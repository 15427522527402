import withStyles from '@mui/styles/withStyles';
import ReconciliationCard from 'components/card/reconciliation-card';
import { convertToArborDate } from 'models/time/arbor-date';
import { getAllergenCategoriesDisplay } from 'services/utils/allergy-service';

import React, { useState } from 'react';

import PatientAllergiesForm from 'containers/patient/clinical/allergies/patient-allergies-form';
import { ALLERGIES_ACTIVE } from 'constants/index';
import { useSelector, useDispatch } from 'react-redux';
import { resolveReconciliationAllergy } from 'actions/action-reconciliation';
import { setAllergyExcludedValue } from 'services/utils/reconciliation-service';
import { styles } from './reconciliation-styles';

export const ReconciliationAllergyCard = props => {
  const { startingIndex, index, total, matchedAllergy, newAllergy, isLoading, classes } = props;
  const [displaySubForm, setDisplaySubForm] = useState(false);
  const selectedPatientId = useSelector(state => state.selectedPatientId);
  let allergiesSources = useSelector(state => state.lookups.allergyLookups.sources);
  allergiesSources = allergiesSources
    ? allergiesSources.map(s => ({ value: s.id, label: s.value }))
    : [];
  const allergyReconcilationFields = [
    {
      label: 'Allergy Name',
      name: 'allergen_name',
    },
    {
      label: 'Allergen Id',
      name: 'allergen_id',
    },
    {
      label: 'Allergen Code',
      name: 'allergen_code',
    },
    {
      label: 'Reactions',
      name: 'reactions',
    },
    {
      label: 'Onset',
      name: 'onset_dt',
    },
    {
      label: 'Severity',
      name: 'severity_id',
    },
    {
      label: 'Source',
      name: 'allergy_source_id',
    },
    {
      label: 'Notes',
      name: 'allergen_notes',
    },
  ];

  const dispatch = useDispatch();

  const excludedValues = newAllergy && newAllergy.excluded_values ? newAllergy.excluded_values : {};

  const newAllergyFlat = newAllergy
    ? {
        ...newAllergy,
        allergen_name: newAllergy && newAllergy.allergen ? newAllergy.allergen.name : null,
        allergen_id: newAllergy && newAllergy.allergen ? newAllergy.allergen.id : null,
        allergen_code: newAllergy && newAllergy.allergen ? newAllergy.allergen.code : null,
        category: newAllergy && newAllergy.allergen ? getAllergenCategoriesDisplay(newAllergy.allergen) : null,
      }
    : {};

  const matchedAllergyFlat = matchedAllergy
    ? {
        ...matchedAllergy,
        allergen_name:
          matchedAllergy && matchedAllergy.allergen ? matchedAllergy.allergen.name : null,
        allergen_id: matchedAllergy && matchedAllergy.allergen ? matchedAllergy.allergen.id : null,
        allergen_code: matchedAllergy && matchedAllergy.allergen ? matchedAllergy.allergen.code : null,
        category: matchedAllergy && matchedAllergy.allergen ? getAllergenCategoriesDisplay(matchedAllergy.allergen) : null,
      }
    : {};

  const rejectAllergy = () => {
    setAllergyExcludedValue(matchedAllergy, newAllergy, excludedValues);
    dispatch(resolveReconciliationAllergy('reject', newAllergyFlat, excludedValues));
  };

  const resolveAllergy = excludedObj => {
    dispatch(resolveReconciliationAllergy('resolve', newAllergyFlat, excludedObj));
  };

  const renderAllergy = col => {
    let currentAllergy;
    let comparedAllergy;

    if (col === 'new') {
      currentAllergy = newAllergyFlat;
      comparedAllergy = matchedAllergyFlat;
    } else {
      currentAllergy = matchedAllergyFlat;
      comparedAllergy = newAllergyFlat;
    }
    if (!currentAllergy) {
      return [{ value: 'No matching allergy on file' }];
    }
    return allergyReconcilationFields.map(field => {
      const { name } = field;
      let highlight = false;
      if (!currentAllergy) {
        return {};
      }
      let value = currentAllergy[name];

      if (comparedAllergy) {
        highlight = value !== comparedAllergy[name];
      }
      if (name === 'onset_dt') {
        value = convertToArborDate(currentAllergy[name], true).getUtcDate(true);
        if (comparedAllergy) {
          highlight = value !== convertToArborDate(comparedAllergy[name], true).getUtcDate(true);
        }
      } else if (name === 'severity_id') {
        value = currentAllergy.severity;
        if (comparedAllergy) {
          highlight = value !== comparedAllergy.severity;
        }
      } else if (name === 'reactions') {
        value =
          currentAllergy && currentAllergy.reactions
            ? currentAllergy.reactions.map(r => r.name).join(', ')
            : null;
        if (comparedAllergy && comparedAllergy.reactions) {
          highlight = value !== comparedAllergy.reactions.map(r => r.name).join(', ');
        }
      } else if (name === 'allergen_name') {
        value = currentAllergy && currentAllergy.allergen ? currentAllergy.allergen.name : null;
        if (comparedAllergy && comparedAllergy.allergen) {
          highlight = value !== comparedAllergy.allergen.name;
        }
      } else if (name === 'allergen_id') {
        value = currentAllergy && currentAllergy.allergen ? currentAllergy.allergen.id : null;
        if (comparedAllergy && comparedAllergy.allergen) {
          highlight = value !== comparedAllergy.allergen.id;
        }
      } else if (name === 'allergen_code') {
        value = currentAllergy && currentAllergy.allergen ? currentAllergy.allergen.code : null;
        if (comparedAllergy && comparedAllergy.allergen) {
          highlight = value !== comparedAllergy.allergen.code;
        }
      } else if (name === 'allergy_source_id') {
        value =
          allergiesSources &&
          allergiesSources.find(source => source.value === currentAllergy.allergy_source_id)
            ? allergiesSources.find(source => source.value === currentAllergy.allergy_source_id)
                .label
            : null;
        if (comparedAllergy && comparedAllergy.allergy_source_id) {
          highlight =
            value !==
            allergiesSources.find(source => source.value === comparedAllergy.allergy_source_id)
              .label;
        }
      } else if (name === 'allergen_notes') {
        value = currentAllergy.allergen_notes;
        if (comparedAllergy && comparedAllergy.allergen_notes) {
          highlight = value !== comparedAllergy.allergen_notes;
        }
      }
      return {
        label: field.label,
        value,
        highlight,
      };
    });
  };

  const subForms = [];

  if (!matchedAllergy) {
    subForms.push({
      button: 'Add new allergy',
      buttonHandler: setDisplaySubForm,
      display: 'add_allergy',
      form: (
        <PatientAllergiesForm
          selectedAllergy={{
            ...newAllergy,
            id: null, // this id is for staging allergy
          }}
          excludedValues={excludedValues}
          allergy={{
            ...newAllergy,
            onset_dt: newAllergy
              ? convertToArborDate(newAllergy.onset_dt, true).getUtcDate(true)
              : null,
            status_id: ALLERGIES_ACTIVE,
          }}
          forceDocumentOnlyOption
          patientId={selectedPatientId}
          cancelHandler={() => setDisplaySubForm(false)}
          submitCallback={resolveAllergy}
          isFromReconciliationAdd
        />
      ),
    });
  }

  if (matchedAllergy) {
    subForms.push({
      button: 'Update with new changes',
      buttonHandler: setDisplaySubForm,
      display: 'update_allergy',
      form: (
        <div className={classes.subFormContainer}>
          <PatientAllergiesForm
            allergy={{
              ...newAllergy,
              id: matchedAllergy.id,
              onset_dt: convertToArborDate(matchedAllergy.onset_dt, true).getUtcDate(true),
              // Although we compare to group info we need to update matched allergy
              // by source_allergy_id,
              // then it will automatically update group
            }}
            stagingAllergy={newAllergy}
            submitCallback={excludedObj => resolveAllergy(excludedObj)}
            cancelHandler={() => setDisplaySubForm(false)}
            isFromReconciliationEdit
          />
        </div>
      ),
    });
  }
  const currentIndex = startingIndex + index;

  return (
    <ReconciliationCard
      header="Allergy Reconcile Information"
      displaySubForm={displaySubForm}
      subForms={subForms}
      oldCol={renderAllergy('old')}
      newCol={renderAllergy('new')}
      sourceName={newAllergy ? newAllergy.source_name : 'source not found'}
      rejectHandler={rejectAllergy}
      index={currentIndex}
      total={total}
      isLoading={isLoading}
    />
  );
};

export default withStyles(styles, { withTheme: true })(ReconciliationAllergyCard);

import React from 'react';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { Button, Grid, Modal, Typography } from '@mui/material';
import { CircularLoadingButton } from 'components/circular-loading-button/circular-loading-button';
import { Controller, useForm } from 'react-hook-form';
import { getModalStyle } from 'services/utils/styles-service';
import { nameOfFactory } from 'utils/types-util';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { renderYesNoDropdown as RenderYesNoDropdown } from 'components/form/field/redux-field';
import { useDispatch } from 'react-redux';
import Validation from 'components/form/validation/validation';
import { buildQaId } from 'utils/build-qa-id';
import { ReactSelect } from 'components/form/field/react-select';
import { NumberOption, StringOption } from 'interfaces/forms/types';
import { useTypedSelector } from 'hooks/use-typed-selector';
import LoadingOverlay from 'components/loading-overlay/loading-overlay';
import { logger } from '../../../../winston-logger';
import { GTD_SPECIALITY_TYPES } from '../../../../constants/index';
import { styles } from './add-new-ar-modal.styles';

interface IAddNewArModalProps {
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  selectedCustomerId: number;
}

interface IFormFields {
  customerBranch: NumberOption;
  isActive: number;
  specialtyType: StringOption[];
  therapeuticClass: NumberOption;
}

const getFieldName = nameOfFactory<IFormFields>();
const requiredErrorMsg = 'Required';
const getQaId = buildQaId('application-manager.ar.add-new-ar-modal', '.');

const specialtyTypeOptions = GTD_SPECIALITY_TYPES.map(x => {
  return { label: x.label, value: x.value };
});

export const AddNewArModal: React.FC<IAddNewArModalProps> = (
  props: IAddNewArModalProps,
): JSX.Element => {
  const classes: any = styles();

  // #region component state
  const form = useForm<any>({});
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [clinicOptions, setClinicOptions] = React.useState<NumberOption[] | undefined>();
  const therapeuticClassesOptions = useTypedSelector(
    state => state.lookups.trellisTherapeuticsClasses,
  )
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(x => {
      return { label: x.name, value: x.id };
    });
  // #endregion

  // #region useEffect
  React.useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const results = await ApplicationManagerClient.getAllCustomerClinics(
          props.selectedCustomerId,
        );
        setClinicOptions(
          results.data
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(x => {
              return { label: x.name, value: x.id };
            }),
        );
      } catch (error) {
        logger.error(error);
        dispatch(notifyError('Error fetching clinics'));
      } finally {
        setLoading(false);
      }
    })();
  }, [props.selectedCustomerId]);
  // #endregion

  // #region helper functions
  const onSubmit = async (formValues: IFormFields): Promise<void> => {
    setSubmitting(true);
    try {
      const { customerBranch } = formValues;
      const { isActive } = formValues;
      const { specialtyType } = formValues;
      const { therapeuticClass } = formValues;

      await ApplicationManagerClient.addNewAr(
        props.selectedCustomerId,
        customerBranch.value,
        Boolean(isActive),
        specialtyType.map(x => x.value),
        therapeuticClass.value,
      );

      props.onSuccess();
      props.onCancel();
      dispatch(notifySuccess('Saved'));
    } catch (error) {
      logger.error(error);
      dispatch(notifyError('Error creating AR'));
    } finally {
      setSubmitting(false);
    }
  };
  // #endregion

  return (
    <Modal open={props.open} data-qa-id={getQaId('wrapper')}>
      <div style={getModalStyle()} className={classes.modal}>
        <Grid container direction="column" spacing={2}>
          {/* Title */}
          <Grid item xs="auto">
            <Typography className={classes.title} data-qa-id={getQaId('title')}>
              Enable AR for Clinic
            </Typography>
          </Grid>

          {/* Form */}
          <Grid item xs="auto" data-qa-id={getQaId('form')}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Controller
                  name={getFieldName('customerBranch')}
                  control={form.control}
                  rules={{ required: true }}
                  render={(ctrlProps: any) => {
                    return (
                      <>
                        <LoadingOverlay open={loading} />
                        <ReactSelect
                          label="Clinic *"
                          isMulti={false}
                          value={ctrlProps?.field?.value}
                          fields={clinicOptions}
                          handleOnChange={(value: NumberOption) =>
                            ctrlProps?.field?.onChange(value)
                          }
                          fullWidth
                        />
                        <Validation
                          touched={form.formState.errors.customerBranch}
                          error={requiredErrorMsg}
                        />
                      </>
                    );
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name={getFieldName('specialtyType')}
                  control={form.control}
                  rules={{ required: true }}
                  render={(ctrlProps: any) => {
                    return (
                      <>
                        <ReactSelect
                          label="Specialty Type *"
                          value={ctrlProps?.field?.value}
                          fields={specialtyTypeOptions}
                          handleOnChange={(value: StringOption) =>
                            ctrlProps?.field?.onChange(value)
                          }
                          fullWidth
                        />
                        <Validation
                          touched={form.formState.errors.specialtyType}
                          error={requiredErrorMsg}
                        />
                      </>
                    );
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name={getFieldName('therapeuticClass')}
                  control={form.control}
                  rules={{ required: true }}
                  render={(ctrlProps: any) => {
                    return (
                      <>
                        <ReactSelect
                          label="Therapeutic Class *"
                          isMulti={false}
                          value={ctrlProps?.field?.value}
                          fields={therapeuticClassesOptions}
                          handleOnChange={(value: StringOption) =>
                            ctrlProps?.field?.onChange(value)
                          }
                          fullWidth
                        />
                        <Validation
                          touched={form.formState.errors.therapeuticClass}
                          error={requiredErrorMsg}
                        />
                      </>
                    );
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name={getFieldName('isActive')}
                  control={form.control}
                  rules={{ required: true }}
                  render={(ctrlProps: any) => {
                    return (
                      <RenderYesNoDropdown
                        fullWidth
                        label="Enabled *"
                        input={{
                          value: ctrlProps?.field?.value,
                          onChange: ctrlProps?.field?.onChange,
                          onBlur: ctrlProps?.field?.onBlur,
                        }}
                        meta={{
                          touched: Boolean(form.formState.errors.isActive),
                          error: requiredErrorMsg,
                        }}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button
                      data-qa-id={getQaId('button.cancel')}
                      onClick={() => {
                        form.reset();
                        props.onCancel();
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <CircularLoadingButton
                      data-qa-id={getQaId('button.save')}
                      buttonLabel="Save"
                      onClick={form.handleSubmit(onSubmit)}
                      isLoading={submitting}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

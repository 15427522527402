import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
// eslint-disable-next-line max-len
import {
  updateEligibilityCheck,
  fetchEligibilityCheckHistory,
} from 'actions/action-eligibility-check';
import makeStyles from '@mui/styles/makeStyles';
import { styles } from '../financials-styles';

import EligibilityCheckHistoryDialog from './history-dialog';

const useStyles = makeStyles(styles);

export function EligibilityCheckButton() {
  const dispatch = useDispatch();
  const eligibilityCheckState = useSelector(state => state.eligibilityCheck) || {};
  const openModal = () => {
    dispatch(updateEligibilityCheck({ ...eligibilityCheckState, open: true }));
  };

  const classes = useStyles();

  return (
    <Button className={classes?.eligibilityCheckButton} onClick={openModal}>
      Run eligibility check
    </Button>
  );
}

export function EligibilityCheck() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const patientId = useSelector(state => state.patient.id);

  const [openHistory, setOpenHistory] = useState(false);

  const handleOnClose = () => {
    setOpenHistory(false);
  };

  useEffect(() => {
    if (patientId && openHistory) {
      dispatch(fetchEligibilityCheckHistory(patientId));
    }
  }, [openHistory]);

  return (
    <Grid container className={classes?.eligibilityCheckWrapper}>
      <EligibilityCheckHistoryDialog onClose={handleOnClose} open={openHistory} />
      <Grid item xs={9} />
      <Grid item xs={3}>
        <span className={classes?.eligibilityButtonsWrapper}>
          <EligibilityCheckButton />
        </span>
      </Grid>
    </Grid>
  );
}

export default compose(withStyles(styles))(EligibilityCheck);

import withStyles from '@mui/styles/withStyles';
import React, { useState } from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';
import { ArchiveIcon, PencilIcon, PinnedNoteIcon } from 'components/icons/icons';
import DocumentFileIcon from 'components/document/document-file-icon';
import NoteDisplayDocument from 'components/note/note-display-document';
import compose from 'recompose/compose';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { getFileExtension } from 'services/utils/document-service';
import {
  DOCUMENT_ARCHIVE_REASON,
  UPLOAD_DOCUMENT_FORM,
  UPLOAD_DOC_FILENAME_FIELD,
  UPLOAD_DOC_FILENOTE_FIELD,
  UPLOAD_DOC_FILETYPE_FIELD,
  UPLOADED_DOCUMENT_LANGUAGE,
} from 'constants/index';
import { change } from 'redux-form';
import { getActionedByUserText } from 'utils/user-utils';
import { DownloadFileUtils } from 'utils/download-file-utils';
import { getDownloadUrl, pinUnpinDocument } from '../../../services/utils/upload-document-service';
import DocumentArchiveReasonForm from '../../../components/document/document-archive-reason-form';
import { styles } from './documents-styles';
import { updateDocumentBar, updateNewDocument } from '../../../actions/action-uploaded-documents';
import { notifySuccess, notifyError } from '../../../actions/action-notifications';

const DocumentListRow = props => {
  const { document, customerId, patientId, showPinned, classes } = props;
  const { tag_type_id: tagTypeId, resource_id: resourceId } = document.tags[0];
  const canDownload = !document.is_archived && document.scanned && document.safe;

  const [hover, setHover] = useState(false);
  const [displayArchiveReason, setDisplayArchiveReason] = useState(false);

  const dispatch = useDispatch();

  const handleDocumentClick = async doc => {
    const { id } = doc;
    if (canDownload) {
      const request = await getDownloadUrl(customerId, patientId, id);
      DownloadFileUtils.downloadUrl(request.data.url, request.data.filename);
    }
  };

  const clickAdd = e => {
    e.stopPropagation();

    const filePrefixExtension = getFileExtension(document.filename);

    dispatch(
      change(UPLOAD_DOCUMENT_FORM, UPLOAD_DOC_FILENAME_FIELD, filePrefixExtension.filePrefix),
    );

    dispatch(change(UPLOAD_DOCUMENT_FORM, UPLOAD_DOC_FILETYPE_FIELD, document.labels));

    const noteText = document.notes && document.notes[0] && document.notes[0].text;
    dispatch(change(UPLOAD_DOCUMENT_FORM, UPLOAD_DOC_FILENOTE_FIELD, noteText));

    dispatch(
      updateDocumentBar({
        display: true,
        update: true,
        tagTypeId,
        resourceId,
        documentId: document.id,
        filePrefix: filePrefixExtension.filePrefix,
        fileExtension: filePrefixExtension.fileExtension,
        tags: document.tag_display,
        hasNote: document.notes && document.notes[0] && document.notes[0].text,
      }),
    );
  };

  const pinDocument = async e => {
    e.stopPropagation();
    try {
      const pinnedDocumentresult = await pinUnpinDocument(
        document.id,
        !document.is_pinned,
        customerId,
        patientId,
      );
      dispatch(updateNewDocument(pinnedDocumentresult));
      dispatch(
        notifySuccess(!document.is_pinned === true ? 'Document pinned' : 'Document unpinned'),
      );
    } catch (err) {
      dispatch(
        notifyError(
          !document.is_pinned === true
            ? 'Error when trying to pin a document'
            : 'Error when trying to unpin a document',
        ),
      );
    }
  };

  const clickArchive = async () => {
    setDisplayArchiveReason(true);
  };

  const onHover = val => {
    setHover(val);
  };

  const onArchiveReasonClose = () => {
    setDisplayArchiveReason(false);
  };

  const iconClassNames = classNames([
    classes.iconCell,
    {
      [classes.archiveIcon]: document.is_archived,
      [classes.pointer]: !document.is_archived,
    },
  ]);

  const rowClass = classNames({
    [classes.archiveTableRow]: document.is_archived,
    [classes.tableRow]: !document.is_archived,
  });

  const cellClass = classNames({
    [classes.downloadDocumentCell]: !document.is_archived && document.scanned && document.safe,
  });

  const archiveClass = classNames([
    classes.documentNote,
    {
      [classes.pointer]: !document.is_archived,
    },
  ]);

  return (
    <TableRow
      key={document.id}
      className={rowClass}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
    >
      <TableCell onClick={() => handleDocumentClick(document)} className={cellClass}>
        <DocumentFileIcon filename={document.filename} />
      </TableCell>
      <TableCell onClick={() => handleDocumentClick(document)} className={cellClass}>
        <h4 className={classes.documentRowFileName}>{document.filename}</h4>
        <span>
          {document.tag_display &&
            document.tag_display.map(display => (display ? `#${display} ` : ''))}
        </span>
        {!document.scanned && (
          <Typography className={classNames(classes.documentMessage, classes.warningText)}>
            {UPLOADED_DOCUMENT_LANGUAGE.unscanned.join('\n')}
          </Typography>
        )}
        {Boolean(document.scanned && !document.safe) && (
          <Typography className={classNames(classes.documentMessage, classes.alertText)}>
            {UPLOADED_DOCUMENT_LANGUAGE.unsafe.join('\n')}
          </Typography>
        )}
      </TableCell>
      <TableCell>{document.labels && document.labels.join(', ')}</TableCell>
      <TableCell>
        {getActionedByUserText({
          action: '',
          user: document.created_by_user,
          date: document.created,
          dateFormat: 'MM/DD/YY',
        })}
      </TableCell>
      <TableCell>
        <div className={archiveClass}>
          <NoteDisplayDocument
            notes={document.notes}
            onClick={e => (!document.is_archived ? clickAdd(e) : null)}
          />
        </div>
        {displayArchiveReason && (
          <DocumentArchiveReasonForm
            onArchiveReasonClose={onArchiveReasonClose}
            customerId={customerId}
            patientId={patientId}
            documentId={document.id}
            fileName={document.filename}
            labels={document.labels}
            form={`${document.id}-${DOCUMENT_ARCHIVE_REASON}`}
          />
        )}
        {Boolean(document.is_archived && document.archived_reason) && (
          <>
            <h4 className={classes.archiveHeading}>Archive Reason: </h4>
            <span>{document.archived_reason}</span>
          </>
        )}
      </TableCell>
      <TableCell className={iconClassNames} onClick={!document.is_archived ? clickArchive : null}>
        {hover && !document.is_archived && <ArchiveIcon />}
      </TableCell>
      <TableCell className={iconClassNames} onClick={!document.is_archived ? clickAdd : null}>
        {hover && !document.is_archived && <PencilIcon />}
      </TableCell>
      <TableCell className={iconClassNames} onClick={pinDocument}>
        <PinnedNoteIcon isPinned={showPinned ? document.is_pinned : showPinned} />
      </TableCell>
    </TableRow>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(DocumentListRow);

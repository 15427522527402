import {
  LookupPaymentMethodTypes,
  PaymentMethodResponse,
  PaymentMethodVirtualCardResponse,
} from 'containers/add-payment-method/types';
import { IState } from 'interfaces/redux/IState';
import { IFillCoordinationTask } from 'interfaces/redux/task-types/IFillCoordinationTask';
import moment from 'moment';
import { sortBy } from 'lodash';

export const isVirtualCard = (
  paymentMethod: PaymentMethodResponse,
): paymentMethod is PaymentMethodVirtualCardResponse =>
  paymentMethod.payment_type === LookupPaymentMethodTypes.VirtualCreditCard;

export function sortPaymentMethods(
  paymentMethods: PaymentMethodResponse[],
): PaymentMethodResponse[] {
  return sortBy(paymentMethods, ['therapy_id', 'rank']);
}

export const selectPaymentMethodOptions = (state: IState, tasks: IFillCoordinationTask[] = []) => {
  const filterTherapyIds = tasks?.map(({ therapy_id }) => therapy_id) ?? [];
  const existingPaymentMethodIds = tasks[0]?.payment_method_ids ?? [];
  const paymentMethodEntities = existingPaymentMethodIds
    .map(id => state.paymentMethods.entities[id])
    .filter(Boolean);
  const virtualCards = (state.tasks.virtualCards ?? []).filter(
    card => card.therapy_id && filterTherapyIds.includes(card.therapy_id),
  );
  const allPaymentMethods = new Map<number, PaymentMethodResponse>();
  paymentMethodEntities
    .concat(virtualCards)
    .concat(state.patient.payment_methods ?? [])
    .forEach(paymentMethod => {
      allPaymentMethods.set(paymentMethod.id, paymentMethod);
    });
  const uniqueResults = Array.from(allPaymentMethods.values());
  return sortPaymentMethods(uniqueResults);
};

export function isExpired(paymentMethod: PaymentMethodResponse): boolean {
  if (!paymentMethod.expiration_date) {
    return false;
  }
  const format = paymentMethod.expiration_date.length === 7 ? 'MM/YYYY' : 'YYYY-MM-DD';
  const expiration = moment(paymentMethod.expiration_date, format).format('YYYYMM');
  return moment(Date.now()).format('YYYYMM') > expiration;
}

import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  rootContainer: {
    padding: 15,
    minWidth: theme.spacing(45),
  },
  table: {
    maxWidth: 400,
  },
  tableContainer: {
    maxHeight: 490,
    overflowY: 'auto',
  },
}));

import React, { Component } from 'react';
import { compose } from 'redux';
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import DatetimePicker from 'components/form/datepicker/datetime-picker';
import RadioButton from 'components/form/radio/radio-button';
import RadioGroup from 'components/form/radio/radio-group';
import moment from 'moment';
import { ReactSelect } from 'components/form/field/react-select';
import { MediumSpacer } from 'components/spacer/spacer';
import { setSidebarFilter } from 'actions/action-sidebar-filters';
import { styles } from './schedule-filter-panel-styles';
import {
  getDatesFromSidebarFilters,
  evaluateDate,
  getRadioCheckedDateFilter,
} from '../../../../services/utils/filters-service';

const DATE_OPTIONS = {
  YESTERDAY: 'YESTERDAY',
  TODAY: 'TODAY',
  TOMORROW: 'TOMORROW',
  NEXT_7_DAYS: 'NEXT_7_DAYS',
  NEXT_14_DAYS: 'NEXT_14_DAYS',
};

class ScheduleFilterPanel extends Component {
  constructor(props) {
    super(props);
    this.handleRadioButtonChange = this.handleRadioButtonChange.bind(this);
    this.handleServiceGroupChange = this.handleServiceGroupChange.bind(this);
    this.handleClinicsChange = this.handleClinicsChange.bind(this);
    this.handleFromDateChange = this.handleFromDateChange.bind(this);
    this.handleToDateChange = this.handleToDateChange.bind(this);
  }

  handleRadioButtonChange(value) {
    const { setSidebarFilter } = this.props; // eslint-disable-line no-shadow
    setSidebarFilter('schedule', {
      date: value,
      from: null,
      to: null,
    });
  }

  handleServiceGroupChange(value) {
    const {
      sidebarFilters,
      setSidebarFilter, // eslint-disable-line no-shadow
    } = this.props;
    const serviceGroups = value
      ? value.map(it => ({
          displayValue: it.label,
          idValue: it.value,
        }))
      : [];
    setSidebarFilter('schedule', {
      serviceGroups,
      clinics: sidebarFilters.clinics.filter(clinic =>
        (value || []).some(serviceGroup => serviceGroup.value === clinic.serviceGroupId),
      ),
    });
  }

  handleClinicsChange(value) {
    const { setSidebarFilter } = this.props; // eslint-disable-line no-shadow
    const taskClinics = value
      ? value.map(clinic => ({
          displayValue: clinic.label,
          idValue: clinic.value,
          serviceGroupId: clinic.service_group_id,
        }))
      : [];
    setSidebarFilter('schedule', {
      clinics: taskClinics,
    });
  }

  handleToDateChange(e) {
    const { setSidebarFilter } = this.props; // eslint-disable-line no-shadow
    // eslint-disable-next-line max-len
    const newDate = e instanceof moment ? moment(e).format('YYYY-MM-DD') : evaluateDate(e); // is a moment if the datepicker is clicked
    setSidebarFilter('schedule', {
      to: newDate === 'noDate' ? null : newDate,
    });
  }

  handleFromDateChange(e) {
    const { setSidebarFilter } = this.props; // eslint-disable-line no-shadow
    // eslint-disable-next-line max-len
    const newDate = e instanceof moment ? moment(e).format('YYYY-MM-DD') : evaluateDate(e); // is a moment if the datepicker is clicked
    setSidebarFilter('schedule', {
      from: newDate === 'noDate' ? null : newDate,
    });
  }

  render() {
    const { classes, sidebarFilters, lookups } = this.props;
    const { serviceGroups, customerClinics } = lookups;

    const scheduleServiceGroups = sidebarFilters.serviceGroups || [];
    const clinics = sidebarFilters.clinics || [];
    const radioChecked = getRadioCheckedDateFilter(sidebarFilters);
    const { from: fromDateValue, to: toDateValue } = getDatesFromSidebarFilters(sidebarFilters);

    const dateChipsEnabled = true;

    return (
      <Grid container direction="column" className={classes.filterPanelContainer}>
        <Grid item lg={12}>
          <RadioGroup horizontal wrap onChange={value => this.handleRadioButtonChange(value)}>
            <RadioButton
              value={DATE_OPTIONS.YESTERDAY}
              checked={dateChipsEnabled && radioChecked[DATE_OPTIONS.YESTERDAY]}
              disabled={!dateChipsEnabled}
            >
              Yesterday
            </RadioButton>
            <RadioButton
              value={DATE_OPTIONS.TODAY}
              checked={dateChipsEnabled && radioChecked[DATE_OPTIONS.TODAY]}
              disabled={!dateChipsEnabled}
            >
              Today
            </RadioButton>
            <RadioButton
              value={DATE_OPTIONS.TOMORROW}
              checked={dateChipsEnabled && radioChecked[DATE_OPTIONS.TOMORROW]}
              disabled={!dateChipsEnabled}
            >
              Tomorrow
            </RadioButton>
            <RadioButton
              value={DATE_OPTIONS.NEXT_7_DAYS}
              checked={dateChipsEnabled && radioChecked[DATE_OPTIONS.NEXT_7_DAYS]}
              disabled={!dateChipsEnabled}
            >
              Next 7 Days
            </RadioButton>
            <RadioButton
              value={DATE_OPTIONS.NEXT_14_DAYS}
              checked={dateChipsEnabled && radioChecked[DATE_OPTIONS.NEXT_14_DAYS]}
              disabled={!dateChipsEnabled}
            >
              Next 14 Days
            </RadioButton>
          </RadioGroup>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={6}>
            <Typography variant="caption" component="div">
              From
            </Typography>
            <DatetimePicker
              value={fromDateValue}
              onChange={e => this.handleFromDateChange(e)}
              dateFormat="MM/dd/yyyy"
              placeholder="mm/dd/yyyy"
              fixedPosition="bottom"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" component="div">
              To
            </Typography>
            <DatetimePicker
              value={toDateValue}
              onChange={e => this.handleToDateChange(e)}
              dateFormat="MM/dd/yyyy"
              placeholder="mm/dd/yyyy"
              fixedPosition="bottom"
            />
          </Grid>
        </Grid>
        <MediumSpacer />
        <Grid container direction="row">
          <Grid item xs={12}>
            <Typography variant="caption" component="div">
              Clinics
            </Typography>
            <ReactSelect
              name="clinics"
              value={clinics.map(clinic => ({
                value: clinic.idValue,
                label: `${clinic.displayValue}`,
              }))}
              handleOnChange={e => this.handleClinicsChange(e)}
              fields={customerClinics.map(clinic => ({
                label: clinic.name,
                value: clinic.id,
                isDisabled: !clinic.active,
              }))}
            />
          </Grid>
        </Grid>
        <MediumSpacer />
        <Grid container direction="row">
          <Grid item xs={12}>
            <Typography variant="caption" component="div">
              Service Groups
            </Typography>
            <ReactSelect
              value={scheduleServiceGroups.map(serviceGroup => ({
                value: serviceGroup.idValue,
                label: `${serviceGroup.displayValue}`,
              }))}
              name="serviceGroups"
              handleOnChange={e => this.handleServiceGroupChange(e)}
              fields={serviceGroups.map(serviceGroup => ({
                label: serviceGroup.display_name,
                value: serviceGroup.id,
                isDisabled: !serviceGroup.active,
              }))}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const { sidebarFilters, lookups } = state;
  return {
    sidebarFilters: sidebarFilters.data.schedule,
    lookups,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    setSidebarFilter,
  }),
)(ScheduleFilterPanel);

import { Reducer } from 'redux';
import { ClinicalDataAction } from 'actions/action-clinical-data';
import { ClinicalDataItem } from 'interfaces/clinical-data/ClinicalDataResponse';
import {
  FETCH_CLINICAL_DATA,
  CLEAR_CLINICAL_DATA,
  SAVE_CLINICAL_DATA,
  SAVE_QUESTIONNAIRE_ANSWERS,
  SUBMIT_QUESTIONNAIRE,
  SEND_QUESTIONNAIRE,
  BULK_TASKS_UPDATED,
  ADD_THERAPY,
  ADD_TASK,
} from '../constants/index';

const combineUpdatedItemsIntoState = (
  updatedItems: ClinicalDataItem[],
  previousItems: ClinicalDataItem[],
): ClinicalDataItem[] => {
  if (updatedItems && updatedItems.length) {
    const updatedItemIds = updatedItems.map(item => item.id);
    const unchangedPreviousItems = previousItems.filter(item => !updatedItemIds.includes(item.id));
    return unchangedPreviousItems.concat(updatedItems);
  }
  return previousItems;
};

// Combine updated items with previous items that are not from any of the updated tasks.
const combineUpdatedTaskItemsIntoState = (
  tasks: any[],
  previousItems: ClinicalDataItem[],
): ClinicalDataItem[] => {
  const newClinicalDataTasks = tasks.sort((a, b) => a.status_id - b.status_id).filter((task: any) =>
    // The property that contains the clinical data items is named inconsistently.
    // It is either snake case or camel case.
    Boolean(task.patient_clinical_data_items || task.patientClinicalDataItems),
  );
  if (newClinicalDataTasks.length) {
    const newTaskIds: number[] = newClinicalDataTasks.map(task => task.id);
    const distinctNewTaskIds = new Set(newTaskIds);

    const newClinicalDataItems = newClinicalDataTasks.reduce((acc, newTask: any) => {
      const clinicalItems = newTask.patient_clinical_data_items || newTask.patientClinicalDataItems;
      acc.push(...clinicalItems);
      return acc;
    }, []);

    // Clinical data should be retained in state if it is not associated with a task
    // or if it is associated with tasks that were not updated.
    const itemsNotInUpdatedTasks = previousItems.filter(
      item =>
        (!item.taskDataCollectId || !distinctNewTaskIds.has(item.taskDataCollectId)) &&
        (!item.taskRiskStratId || !distinctNewTaskIds.has(item.taskRiskStratId)),
    );
    return itemsNotInUpdatedTasks.concat(newClinicalDataItems);
  }
  return previousItems;
};

const clinicalDataReducer: Reducer<ClinicalDataItem[] | null, ClinicalDataAction> = (
  state = null,
  action,
) => {
  const { type, payload } = action;
  if (type === FETCH_CLINICAL_DATA) {
    // Refresh state with the latest data.
    return payload;
  }
  if (state === null) {
    // State has not yet been initialized and this is not an initial fetch -- so we will leave state un-initialized.
    return state;
  }
  switch (type) {
    case CLEAR_CLINICAL_DATA: {
      return null;
    }

    case SAVE_CLINICAL_DATA:
    case SAVE_QUESTIONNAIRE_ANSWERS:
    case SUBMIT_QUESTIONNAIRE: {
      const updatedClinicalData = payload ?? [];
      return combineUpdatedItemsIntoState(updatedClinicalData, state);
    }

    case SEND_QUESTIONNAIRE:
      const updatedClinicalData = payload.patient_clinical_data_items;
      return combineUpdatedItemsIntoState(updatedClinicalData, state);
    case ADD_TASK: {
      const newTasks: any[] = payload.data.added_task || [];
      return combineUpdatedTaskItemsIntoState(newTasks, state);
    }
    case BULK_TASKS_UPDATED: {
      const updatedTasks = payload.data.updated_tasks || [];
      return combineUpdatedTaskItemsIntoState(updatedTasks, state);
    }
    case ADD_THERAPY: {
      const addedTherapy = payload.data.therapy;
      const addedClinicalDataItems = addedTherapy.patient_clinical_data_items;
      return combineUpdatedItemsIntoState(addedClinicalDataItems, state);
    }
    default:
      return state;
  }
};

export default clinicalDataReducer;

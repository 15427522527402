import React, { Component } from 'react';
import { compose } from 'redux';
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import DatetimePicker from 'components/form/datepicker/datetime-picker';
import RadioButton from 'components/form/radio/radio-button';
import RadioGroup from 'components/form/radio/radio-group';
import { getCustomerCurrentArborDate } from 'models/time/arbor-date';
import moment from 'moment';
import { ReactSelect } from 'components/form/field/react-select';
import { DATE_OPTIONS } from 'constants/index';
import { setSidebarFilter } from 'actions/action-sidebar-filters';
import {
  getDatesFromSidebarFilters,
  getRadioCheckedDateFilter,
  evaluateDate,
} from 'services/utils/filters-service';
import { styles } from './report-filter-panel-styles';

export const MAX_DAYS_LIMIT = 90;

class ReportFilterPanel extends Component {
  constructor(props) {
    super(props);
    this.handleRadioButtonChange = this.handleRadioButtonChange.bind(this);
    this.handleServiceGroupChange = this.handleServiceGroupChange.bind(this);
    this.handleClinicsChange = this.handleClinicsChange.bind(this);
    this.handlePatientStatusesChange = this.handlePatientStatusesChange.bind(this);
    this.handleRadioButtonChange(DATE_OPTIONS.LAST_90_DAYS);
  }

  handleRadioButtonChange(value) {
    const { setSidebarFilter } = this.props; // eslint-disable-line no-shadow
    setSidebarFilter('report', {
      date: value,
      from: null,
      to: null,
    });
  }

  handleServiceGroupChange(value) {
    const {
      sidebarFilters,
      setSidebarFilter, // eslint-disable-line no-shadow
    } = this.props;
    const serviceGroups = value
      ? value.map(it => ({
          displayValue: it.label,
          idValue: it.value,
        }))
      : [];
    setSidebarFilter('report', {
      serviceGroups,
      clinics: sidebarFilters.clinics.filter(clinic =>
        (value || []).some(serviceGroup => serviceGroup.value === clinic.serviceGroupId),
      ),
    });
  }

  handleClinicsChange(value) {
    const { setSidebarFilter } = this.props; // eslint-disable-line no-shadow
    const taskClinics = value
      ? value.map(clinic => ({
          displayValue: clinic.label,
          idValue: clinic.value,
          serviceGroupId: clinic.serviceGroupId,
        }))
      : [];
    setSidebarFilter('report', {
      clinics: taskClinics,
    });
  }

  handlePatientStatusesChange(value) {
    const { setSidebarFilter } = this.props; // eslint-disable-line no-shadow
    const patientStatusesArr = value
      ? value.map(status => ({
          displayValue: status.label,
          idValue: status.value,
        }))
      : [];
    setSidebarFilter('report', {
      patientStatuses: patientStatusesArr,
    });
  }

  handleToDateChange(e) {
    const { setSidebarFilter } = this.props; // eslint-disable-line no-shadow
    // eslint-disable-next-line max-len
    const newDate = e instanceof moment ? moment(e).format('YYYY-MM-DD') : evaluateDate(e); // is a moment if the datepicker is clicked

    setSidebarFilter('report', {
      to: newDate === 'noDate' ? null : newDate,
    });
  }

  handleFromDateChange(e) {
    const { setSidebarFilter, to } = this.props; // eslint-disable-line no-shadow
    // eslint-disable-next-line max-len
    const newDate = e instanceof moment ? moment(e).format('YYYY-MM-DD') : evaluateDate(e); // is a moment if the datepicker is clicked
    let newTo = to;
    if (newDate !== 'noDate' && Math.abs(moment(to).diff(moment(newDate), 'd')) > MAX_DAYS_LIMIT) {
      const direction =
        moment(to).diff(moment(newDate), 'd') / Math.abs(moment(to).diff(moment(newDate), 'd'));
      newTo = moment(newDate).add(direction * MAX_DAYS_LIMIT, 'd');
    }
    setSidebarFilter('report', {
      from: newDate === 'noDate' ? null : newDate,
      to: newTo,
    });
  }

  render() {
    const { sidebarFilters, lookups, patientStatuses } = this.props;
    const { serviceGroups, customerClinics } = lookups;
    const { from: fromDateValue, to: toDateValue } = getDatesFromSidebarFilters(sidebarFilters);
    const radioChecked = getRadioCheckedDateFilter(sidebarFilters);

    const minDate = getCustomerCurrentArborDate().subtract(MAX_DAYS_LIMIT, 'days');

    const maxDate = getCustomerCurrentArborDate();

    return (
      <Grid container alignItems="flex-end">
        <Grid item xs={12}>
          <Grid container>
            <Grid item lg={6}>
              <RadioGroup horizontal onChange={value => this.handleRadioButtonChange(value)}>
                <RadioButton value={DATE_OPTIONS.LAST_90_DAYS} checked={radioChecked[DATE_OPTIONS.LAST_90_DAYS]}>
                  {`Last ${MAX_DAYS_LIMIT} days`}
                </RadioButton>
                <RadioButton
                  value={DATE_OPTIONS.YESTERDAY}
                  checked={radioChecked[DATE_OPTIONS.YESTERDAY]}
                >
                  Yesterday
                </RadioButton>
                <RadioButton value={DATE_OPTIONS.TODAY} checked={radioChecked[DATE_OPTIONS.TODAY]}>
                  Today
                </RadioButton>
                <RadioButton
                  value={DATE_OPTIONS.LAST_WEEK}
                  checked={radioChecked[DATE_OPTIONS.LAST_WEEK]}
                >
                  Last Week
                </RadioButton>
                <RadioButton
                  value={DATE_OPTIONS.THIS_MONTH}
                  checked={radioChecked[DATE_OPTIONS.THIS_MONTH]}
                >
                  This Month
                </RadioButton>
                <RadioButton
                  value={DATE_OPTIONS.LAST_MONTH}
                  checked={radioChecked[DATE_OPTIONS.LAST_MONTH]}
                >
                  Last Month
                </RadioButton>
              </RadioGroup>
            </Grid>
            <Grid item lg={1}>
              <Typography variant="caption" component="div">
                From
              </Typography>
              <DatetimePicker
                value={fromDateValue || minDate.format('YYYY-MM-DD')}
                onChange={e => this.handleFromDateChange(e)}
                dateFormat="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                id="report_filter_from"
                maxDate={maxDate.toDate()}
              />
            </Grid>
            <Grid item lg={1}>
              <Typography variant="caption" component="div">
                To
              </Typography>
              <DatetimePicker
                value={toDateValue || maxDate.format('YYYY-MM-DD')}
                onChange={e => this.handleToDateChange(e)}
                dateFormat="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                minDate={moment(fromDateValue).toDate() || minDate.toDate()}
                maxDate={
                  fromDateValue &&
                  moment(fromDateValue).add(MAX_DAYS_LIMIT, 'd').toDate() <= maxDate.toDate()
                    ? moment(fromDateValue).add(MAX_DAYS_LIMIT, 'd').toDate()
                    : maxDate.toDate()
                }
                id="report_filter_to"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="caption" component="div">
                Patient Status
              </Typography>
              <ReactSelect
                name="patient_status"
                value={sidebarFilters?.patientStatuses?.map(status => ({
                  value: status.idValue,
                  label: `${status.displayValue}`,
                }))}
                handleOnChange={e => this.handlePatientStatusesChange(e)}
                fields={patientStatuses.map(status => ({
                  label: status.status,
                  value: status.id,
                }))}
                id="report_filter_patient_status"
              />
            </Grid>
            <Grid item lg={2}>
              <Typography variant="caption" component="div">
                Clinics
              </Typography>
              <ReactSelect
                name="clinics"
                value={sidebarFilters.clinics.map(clinic => ({
                  value: clinic.idValue,
                  label: `${clinic.displayValue}`,
                }))}
                handleOnChange={e => this.handleClinicsChange(e)}
                fields={customerClinics.map(clinic => ({
                  label: clinic.name,
                  value: clinic.id,
                  serviceGroupId: clinic.service_group_id,
                  isDisabled: !clinic.active,
                }))}
                id="report_filter_clinics"
              />
            </Grid>
            <Grid item lg={2}>
              <Typography variant="caption" component="div">
                Service Groups
              </Typography>
              <ReactSelect
                name="serviceGroups"
                value={sidebarFilters.serviceGroups.map(serviceGroup => ({
                  value: serviceGroup.idValue,
                  label: `${serviceGroup.displayValue}`,
                }))}
                handleOnChange={e => this.handleServiceGroupChange(e)}
                fields={serviceGroups.map(serviceGroup => ({
                  label: serviceGroup.display_name,
                  value: serviceGroup.id,
                  isDisabled: !serviceGroup.active,
                }))}
                id="report_filter_service_groups"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const { sidebarFilters, lookups, taskStatuses } = state;
  const patientStatuses = taskStatuses?.statuses?.patient || [];
  return { sidebarFilters: sidebarFilters.data.report, lookups, patientStatuses };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    setSidebarFilter,
  }),
)(ReportFilterPanel);

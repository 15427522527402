import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { expandTheme } from 'utils/theme-util';
import { globalZIndex } from '../../../constants/z-index';

export const styles = (theme: Theme) => {
  const { palette, font } = expandTheme(theme);

  return createStyles({
    messageDateTime: {
      fontSize: font.smallFontSize,
      color: palette.primary.steelGrey,
    },
    tag: {
      display: 'inline',
      color: palette.primary.trellisBlue,
      fontSize: font.mediumFontSize,
      fontWeight: 'bold',
    },
    actionIconCompleted: {
      cursor: 'pointer',
      color: palette.primary.patina,
    },
    actionIconPending: {
      cursor: 'pointer',
      color: palette.primary.grey13,
    },
    editTagPopover: {
      overflow: 'visible',
      zIndex: globalZIndex.editSMSTagsModal,
    },
    fancyTooltip: {
      fontSize: '0.58rem',
      fontWeight: 'bold',
    },
  });
};

type Styles = WithStyles<typeof styles>;

const useStyles = makeStyles(styles);

export const useTypedStyles = (): Styles => ({
  classes: useStyles(),
});

import { createStyles } from '@mui/styles';

export const styles = theme =>
  createStyles({
    button: {
      '&:hover': {
        backgroundColor: theme.palette.primary.teal,
      },
      'backgroundColor': theme.palette.primary.teal,
      'color': theme.palette.primary.white,
      'width': '85%',
      'padding': 20,
      'fontSize': 16,
      'marginBottom': 6,
      'textTransform': 'initial',
      'height': 60,
      [`${theme.breakpoints.down('xl')} and (orientation: landscape)`]: {
        padding: 7,
      },
      [theme.breakpoints.down('xl')]: {
        width: '100%',
      },
    },
    link: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      'backgroundColor': 'transparent',
      'color': theme.palette.primary.deepBlue,
    },
    bordered: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      'backgroundColor': 'transparent',
      'color': theme.palette.primary.deepBlue,
      'border': '1px solid',
      'borderColor': theme.palette.primary.deepBlue,
    },
    formButton: {
      position: 'absolute',
      left: '50%',
      bottom: 12,
      width: 'calc(100% - 48px)',
      padding: 12,
      transform: 'translateX(-50%)',
    },
  });

import React from 'react';
import withStyles from '@mui/styles/withStyles';
import ChecklistTaskList from 'containers/patient/checklist/checklist-task-list';
import ScheduleOutreach from 'containers/patient/checklist/schedule-outreach/schedule-outreach';
import { styles } from '../nav-styles';

const Checklist = () => (
  <div>
    <ChecklistTaskList />
    <ScheduleOutreach />
  </div>
);

export default withStyles(styles, { withTheme: true })(Checklist);

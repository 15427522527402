import React from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel, Checkbox } from '@mui/material';
import { IMedSyncRowData, HeadCell } from 'models/meds/IMedSync';
import { styles } from './table.style';
import { Order, SelectAllType } from '../modal/use-med-sync-modal';

interface ITableHeaderProps {
  order: Order;
  orderBy: string;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IMedSyncRowData) => void;
  selectAll: SelectAllType;
  onSelectAllClick: () => void;
  disableMainCheckbox: boolean;
}

const headCells: HeadCell[] = [
  {
    id: 'drugName',
    label: 'Drug Name',
    align: 'left',
    sortable: true,
  },
  {
    id: 'ndc',
    label: 'NDC',
    align: 'left',
    sortable: false,
  },
  {
    id: 'dose',
    label: 'Dose',
    align: 'left',
    sortable: false,
  },
  {
    id: 'form',
    label: 'Form',
    align: 'left',
    sortable: false,
  },
  {
    id: 'nbd',
    label: 'NBD',
    align: 'left',
    sortable: true,
  },
  {
    id: 'adherencePack',
    label: 'Adherence Pack',
    align: 'left',
    sortable: false,
  },
  {
    id: 'daysSupply',
    label: 'Day Supply',
    align: 'left',
    sortable: false,
  },
];

export const MedSyncTableHeader: React.FC<ITableHeaderProps> = (
  props: ITableHeaderProps,
): JSX.Element => {
  const classes: any = styles();

  const { order, orderBy, onRequestSort, selectAll, onSelectAllClick, disableMainCheckbox } = props;

  const createSortHandler =
    (property: keyof IMedSyncRowData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell align="left" className={classes.headerNarrowColumn}>
          <Checkbox
            color="success"
            checked={selectAll === 'all'}
            disabled={disableMainCheckbox}
            className={classes.checkbox}
            onClick={onSelectAllClick}
          />
        </TableCell>
        {headCells?.map((headCellInfo: HeadCell) => {
          return (
            <TableCell
              key={headCellInfo.id}
              align={headCellInfo.align}
              sortDirection={orderBy === headCellInfo.id ? order : false}
              className={
                headCellInfo.id === 'daysSupply' ? classes.headerLastColumn : classes.headerColumn
              }
            >
              {headCellInfo.sortable ? (
                <TableSortLabel
                  active={orderBy === headCellInfo.id}
                  direction={orderBy === headCellInfo.id ? order : 'asc'}
                  onClick={createSortHandler(headCellInfo.id)}
                >
                  {headCellInfo.label}
                </TableSortLabel>
              ) : (
                headCellInfo.label
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

export const styles = (theme: Theme) => {
  const colorPalette = theme.palette.primary as any;

  return createStyles({
    root: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    expansionPanel: {
      border: `1px solid ${colorPalette.lightGrey}`,
    },
    title: {
      fontWeight: 'bold',
    },
    subtitle: {
      color: colorPalette.steelGrey,
      fontSize: theme.typography.pxToRem(15),
    },
    sameLevelContent: {
      padding: 0,
    },
    box: {
      paddingTop: 20,
      width: '100%',
    },
    content: {
      padding: '8px 24px 24px 24px',
    },
    header: {
      color: colorPalette.steelGrey,
    },
    expandIcon: {
      color: colorPalette.steelGrey,
    },
  });
};

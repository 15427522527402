import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import cx from 'classnames';
import React from 'react';
import Divider from 'components/divider';
import { addEmailModalActions } from 'actions/action-add-email-modal';
import { assignRankingAndKeys } from 'services/utils/demographic-service';
import { AxiosPromise } from 'axios';
import { buildQaId } from 'utils/build-qa-id';
import { resolveAudit } from 'actions/action-audit';
import {
  change,
  Field,
  FieldArray,
  InjectedFormProps,
  reduxForm,
  untouch,
  getFormValues,
  formValueSelector,
} from 'redux-form';
import { compose } from 'recompose';
import { contactEnrollment, emailTypes } from 'constants/lists';
import { ContactUtil } from 'utils/contact-util';
import { editContact } from 'actions/action-contacts';
import { editPatient } from 'actions/action-patient';
import { getModalStyle } from 'services/utils/styles-service';
import { Grid, Modal, Typography, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { IState } from 'interfaces/redux/IState';
import { nameOfFactory } from 'utils/types-util';
import { PatientUtil } from 'utils/patient-util';
import { valueIfPatientSelected, useContactOptions } from 'hooks/useContactOptions';
import { required, validateEmail } from 'components/form/validation/validation';
import { useDispatch, useSelector, connect } from 'react-redux';
import { renderEmails } from 'components/form/subform/subform';
import { checkAndResolveEmailAudits } from 'containers/patient/demographics/patient-form';
import { DECLINES_TO_PROVIDE_EMAIL, NO_EMAIL } from 'constants/index';
import {
  renderDropdown,
  renderRadioGroup,
  renderTextField,
} from 'components/form/field/redux-field';
import { styles } from '../add-address-modal/add-address-modal.styles';
import { IProps } from './interfaces/IProps';

const FORM_NAME_EDIT_EMAIL_MODAL = 'FORM_NAME_EDIT_EMAIL_MODAL';
// #region interfaces for the component
interface IAddEmailModalProps extends IProps, InjectedFormProps<IFormProps> {}
interface IStateProps {
  initialValues: Partial<IFormProps>;
  formValues: Partial<IFormProps>;
}

type ISingleEmail = { use: string; email: string; enrollment: any };

type IFormProps = {
  contact: number;
  emails: ISingleEmail[];
  email_options?: number;
};

export enum ViewModeEnum {
  FILL_COORDINATION = 1,
  PATIENT_OVERVIEW = 2,
  DEMOGRAPHICS = 3,
  THERAPIES = 'therapies',
}

type Props = IStateProps & IAddEmailModalProps & { viewMode: ViewModeEnum; auditState: any };
// #endregion

// #region constant things
const qaIdBuilder = buildQaId('add-email-modal');
const nameOfFormFields = nameOfFactory<IFormProps & ISingleEmail>();
// #endregion

const AddEmailModal: React.FC<Props> = (props: Props): JSX.Element => {
  const { classes, formValues, auditState } = props;

  const viewMode = props.viewMode || ViewModeEnum.PATIENT_OVERVIEW;

  // #region component state
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [saveDisabled, setSaveDisabled] = React.useState<boolean>(false);
  // #endregion

  // #region redux
  const patient = useSelector((state: IState) => state.patient);
  const contacts = useSelector((state: IState) => state.contactList);
  const addEmailModal = useSelector((state: IState) => state.addEmailModal);
  const contactOptions = useContactOptions();
  const dispatch = useDispatch();
  // #endregion

  // #region functions
  const handleSubmit = async (formValues: IFormProps) => {
    setSaveDisabled(true);
    const { contact, emails, email_options: emailOptions } = formValues;
    if (viewMode === ViewModeEnum.FILL_COORDINATION) {
      if (contact === valueIfPatientSelected) {
        submitNewPatientEmail(emails[0]);
      } else if (contact) {
        submitNewContactEmail(contact, emails[0]);
      }
    } else if (viewMode === ViewModeEnum.PATIENT_OVERVIEW) {
      submitNewPatientEmails(emails, emailOptions);
      checkAndResolveEmailAudits({
        emails,
        emailOptions,
        auditState,
        patient,
        resolverFunction: (resolvedAudit: any) => {
          dispatch(resolveAudit(resolvedAudit));
        },
      });
    }
  };

  const handleCancel = (): void => {
    setErrorMessage('');
    setSaveDisabled(false);
    props.reset();
    if (addEmailModal.formId && addEmailModal.field) {
      dispatch(untouch(addEmailModal.formId, addEmailModal.field));
    }
    dispatch(addEmailModalActions.resetModal());
  };

  const formatFormValues = (formValues: Partial<ISingleEmail>) => {
    const { email, ...restFormValues } = formValues;
    const formatted = {
      ...restFormValues,
      value: email,
    };
    return formatted;
  };

  const formatFieldValue = (value: string) => {
    if (addEmailModal.mode === ViewModeEnum.THERAPIES) {
      return {
        label: value,
        value,
      };
    }
    return value;
  };

  const submitNewPatientEmails = (
    emails: Partial<ISingleEmail>[],
    emailOptions: number | undefined,
  ): void => {
    const rankedEmails = assignRankingAndKeys(emails);
    const payload: any = {
      ...PatientUtil.convertPatientFromStateForUpdate(patient),
      emails: JSON.stringify(rankedEmails),
    };
    if (!!emailOptions && [DECLINES_TO_PROVIDE_EMAIL, NO_EMAIL].includes(emailOptions)) {
      payload.email_options = emailOptions;
    } else {
      payload.email_options = null;
    }
    (dispatch(editPatient(payload) as unknown) as AxiosPromise<any>)
      .then((result: any) => {
        handleCancel();
      })
      .catch(error => {
        setSaveDisabled(false);
        setErrorMessage('Could not update patient');
      });
  };

  const submitNewPatientEmail = (email: Partial<ISingleEmail>): void => {
    const formattedFormValues = formatFormValues(email);
    const rankedEmails = assignRankingAndKeys(
      (patient.emails || []).concat(formattedFormValues as any),
    );
    const payload = {
      ...PatientUtil.convertPatientFromStateForUpdate(patient),
      emails: JSON.stringify(rankedEmails),
    };
    (dispatch(editPatient(payload) as unknown) as AxiosPromise<any>)
      .then((result: any) => {
        if (addEmailModal.formId && addEmailModal.field) {
          const fieldValue = `${email.use} - ${email.email}`;
          dispatch(change(addEmailModal.formId, addEmailModal.field, formatFieldValue(fieldValue)));
        }
        handleCancel();
      })
      .catch(error => {
        setSaveDisabled(false);
        setErrorMessage('Could not update patient');
      });
  };

  const submitNewContactEmail = (contactId: number, formValues: Partial<ISingleEmail>): void => {
    const theContact = contacts[contactId];
    const formattedFormValues = formatFormValues(formValues);
    const rankedEmails = assignRankingAndKeys(theContact.emails.concat(formattedFormValues as any));
    const payload = {
      ...ContactUtil.convertContactFromStateForUpdate(theContact),
      emails: JSON.stringify(rankedEmails),
    };

    (dispatch(editContact(payload)) as unknown as AxiosPromise<any>)
      .then(result => {
        if (addEmailModal.formId && addEmailModal.field) {
          const formValue = `${theContact.first_name} ${theContact.last_name} (${theContact.relationship}) - ${formValues.email}`;
          dispatch(change(addEmailModal.formId, addEmailModal.field, formatFieldValue(formValue)));
        }
        handleCancel();
      })
      .catch(error => {
        setSaveDisabled(false);
        setErrorMessage('Could not update contact');
      });
  };
  // #endregion

  const getModalTitle = () =>
    viewMode === ViewModeEnum.FILL_COORDINATION ? 'Add New Email' : 'Edit Email Preferences';

  return (
    <Modal open={addEmailModal.visible} data-qa-id={qaIdBuilder('modal')}>
      {viewMode === ViewModeEnum.FILL_COORDINATION ? (
        <form data-qa-id={qaIdBuilder('form')}>
          <div style={getModalStyle()} className={classes.addModal}>
            <Grid container>
              {errorMessage && (
                <Grid item xs={12} className={classes.row}>
                  <Typography className={classes.error}>{errorMessage}</Typography>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} className={classes.row}>
              <Typography variant="h6" className={classes.heading}>
                Add New Email
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.row}>
              <Grid container spacing={1}>
                <Grid item xs={7}>
                  <Field
                    name={nameOfFormFields('contact')}
                    label="Contact *"
                    component={renderDropdown}
                    fields={contactOptions}
                    validate={[required]}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name={nameOfFormFields('use')}
                    label="Type *"
                    component={renderDropdown}
                    fields={emailTypes}
                    validate={[required]}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={cx(classes.row, classes.lastRow)}>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <Field
                    label="Email *"
                    name={nameOfFormFields('email')}
                    validate={[required, validateEmail]}
                    component={renderTextField}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Field
                    label="Email Enrollment *"
                    name={nameOfFormFields('enrollment')}
                    radioMap={contactEnrollment}
                    component={renderRadioGroup}
                    validate={[required]}
                    caption
                    width="100%"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ConfirmationPanel
                handleSubmit={props.handleSubmit(handleSubmit)}
                handleCancel={handleCancel}
                disableSubmit={saveDisabled}
              />
            </Grid>
          </div>
        </form>
      ) : (
        <form autoComplete="off">
          <div style={getModalStyle()} className={classes.addModal}>
            <Grid container>
              {errorMessage && (
                <Grid item xs={12} className={classes.row}>
                  <Typography className={classes.error}>{errorMessage}</Typography>
                </Grid>
              )}

              <Grid item xs={12} className={classes.row}>
                <Typography variant="h6" className={classes.heading}>
                  {getModalTitle()}
                </Typography>

                <FieldArray
                  name="emails"
                  formValues={formValues}
                  preferredField="preferred_email_index"
                  declineField="email_options"
                  component={renderEmails}
                  change={(fieldName: string, fieldValue: any) => {
                    dispatch(change(FORM_NAME_EDIT_EMAIL_MODAL, fieldName, fieldValue));
                  }}
                  classes={classes}
                  viewMode={viewMode}
                  idPrefix="patient_email_modal"
                />
              </Grid>

              <Grid item xs={12}>
                <Divider />
                <ConfirmationPanel
                  handleSubmit={props.handleSubmit(handleSubmit)}
                  handleCancel={handleCancel}
                  disableSubmit={saveDisabled}
                />
              </Grid>
            </Grid>
          </div>
        </form>
      )}
    </Modal>
  );
};

function mapStateToProps(state: any, props: any) {
  const { lookups, patient, audit } = state;

  const initialValues = {
    preferred_email_index: 0,
    contact: 0,
    emails: patient.filteredEmails,
    email_options: patient.email_options || 0,
  };

  const formValues = getFormValues(FORM_NAME_EDIT_EMAIL_MODAL)(state) || initialValues;
  return {
    patient,
    form: FORM_NAME_EDIT_EMAIL_MODAL,
    enableReinitialize: true,
    initialValues,
    formValues,
    lookups,
    auditState: audit,
  };
}

export default compose<Props, IAddEmailModalProps>(
  withStyles(styles),
  connect(mapStateToProps),
  reduxForm({}),
)(AddEmailModal);

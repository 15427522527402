import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/styles';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { convertToArborDate } from 'models/time/arbor-date';
import { IContactDemographicInfoProps } from 'components/patient-merge-modal/interfaces';

export const ContactInformationHeader = (props: Pick<IContactDemographicInfoProps, 'contact'>) => {
  const { contact } = props;
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);
  return (
    <>
      <Grid item xs={12}>
        <Typography sx={classes.checkboxTitle}>
          {contact.relationship}
          {contact.isEmergency && (
            <Box component="span" sx={classes.emergencyContactTag}>
              Emergency Contact
            </Box>
          )}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={classes.checkboxTitle}>Personal Information</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={classes.checkboxInfoLabel}>Last name</Typography>
        <Typography sx={classes.checkboxInfoValue}>{contact.lastName ?? '-'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={classes.checkboxInfoLabel}>First Name</Typography>
        <Typography sx={classes.checkboxInfoValue}>{contact.firstName ?? '-'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={classes.checkboxInfoLabel}>Preferred Name</Typography>
        <Typography sx={classes.checkboxInfoValue}>{contact.nickname ?? '-'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={classes.checkboxInfoLabel}>Date of Birth</Typography>
        <Typography sx={classes.checkboxInfoValue}>
          {contact.dob ? convertToArborDate(contact.dob, true).getUtcDate(true) : '-'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={classes.checkboxInfoLabel}>Relationship</Typography>
        <Typography sx={classes.checkboxInfoValue}>{contact.relationship}</Typography>
      </Grid>
    </>
  );
};

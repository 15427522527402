import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

export const LoadingOverlayStyles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    container: {
      bottom: 0,
      height: '100%',
      left: 0,
      position: 'relative',
      top: 0,
      width: '100%',
      minHeight: '50vh',
    },
    backdrop: {
      background: palette.primary.white,
      height: '100%',
      opacity: 0.5,
      left: 0,
      top: 0,
      position: 'absolute',
      width: '100%',
    },
    loadingSpinner: {
      left: ' 50%',
      margin: 'auto',
      position: 'absolute',
      transform: ' translate(-50%, -50%)',
      top: '50%',
    },
    loaderContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
  });
};

import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  markSmsMessageAsReadSuccess,
  markSmsMessageAsUnreadSuccess,
  workListChanged,
  refreshPatientTherapiesAndTasks,
} from 'actions/action-patient';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { Grid, Typography, CircularProgress, Tooltip } from '@mui/material';
import { CheckCircle as MarkAsReadIcon } from '@mui/icons-material';
import { IPatientSmsMessage, SmsDirection, SmsStatus } from 'interfaces/redux/IPatient';
import { SmsClient } from 'clients/sms';
import { useTypedStyles } from './action-row.styles';

import EditSmsTags from './edit-tags';
import { logger } from '../../../winston-logger';

interface IProps {
  smsMessage: Partial<IPatientSmsMessage>;
  patientId: number;
  userDisplayName: string;
}

export const ActionRow: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const { classes } = useTypedStyles();
  const [isPerformingAnAction, setIsPerformingAnAction] = React.useState<boolean>(false);
  const { status } = props.smsMessage;
  const { userDisplayName } = props;

  const toggleReadStatus = async (smsId: number) => {
    setIsPerformingAnAction(true);
    const newStatus = status === SmsStatus.Received ? SmsStatus.Read : SmsStatus.Received;
    const displayStatus = newStatus === 'read' ? 'read' : 'unread';
    try {
      await SmsClient.updateSmsStatus(smsId, newStatus);
      dispatch(notifySuccess(`SMS marked as ${displayStatus} successfully`));
      if (newStatus === SmsStatus.Read) {
        dispatch(markSmsMessageAsReadSuccess(smsId, userDisplayName));
        dispatch(workListChanged());
      } else {
        dispatch(workListChanged());
        dispatch(markSmsMessageAsUnreadSuccess(smsId));
      }
      dispatch(refreshPatientTherapiesAndTasks(props.patientId));
    } catch (error) {
      logger.error(error);
      dispatch(notifyError(`Error marking SMS as ${displayStatus}`));
    } finally {
      setIsPerformingAnAction(false);
    }
  };
  const { readStatusChangedByUserName } = props.smsMessage;
  const sentByInfo =
    props.smsMessage.type === SmsDirection.Outbound ? ` Sent by ${props.smsMessage.sentBy}` : '';
  const fancyToolTip = (
    <>
      <Typography className={classes.fancyTooltip}>Mark as unread</Typography>
      <Typography className={classes.fancyTooltip}>
        Marked as read by {readStatusChangedByUserName}
      </Typography>
    </>
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        {Boolean(props.smsMessage.date) && (
          <Typography className={classes.messageDateTime}>
            {`${props.smsMessage.date}${sentByInfo}`}
          </Typography>
        )}
      </Grid>
      <Grid item xs={8}>
        {(props.smsMessage.tags || []).map(tag => {
          return <Typography className={classes.tag}>{`#${tag.name} `}</Typography>;
        })}
      </Grid>
      <Grid item xs={4}>
        <Grid container justifyContent="flex-end">
          {isPerformingAnAction && <CircularProgress size={15} variant="indeterminate" />}
          {props.smsMessage.type === SmsDirection.Inbound && (
            <>
              <EditSmsTags smsId={props.smsMessage.id} patientId={props.patientId} />
              <Tooltip
                title={props.smsMessage.status === SmsStatus.Read ? fancyToolTip : 'Mark as read'}
              >
                <MarkAsReadIcon
                  onClick={() => {
                    if (props.smsMessage.id && isPerformingAnAction === false) {
                      toggleReadStatus(props.smsMessage.id);
                    }
                  }}
                  className={
                    props.smsMessage.status === SmsStatus.Read
                      ? classes.actionIconCompleted
                      : classes.actionIconPending
                  }
                  fontSize="small"
                />
              </Tooltip>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

import { Moment } from 'moment/moment';
import { TypedHttp } from '../services/typed-http';

export interface IBulkSmsFilters {
  customer_id?: number;
  service_group_id?: string;
  therapy_administration_status_id?: string;
  sms_opt_out_status?: string;
  therapy_type_id?: string;
  dispensing_decision_id?: number;
  follow_up_date_from?: Moment;
  follow_up_date_to?: Moment;
  exclude_first_fill?: boolean;
}

export interface IBulkSmsRecipient {
  patient_id: number;
  phone: string;
  source_patient_id: string;
  task_fill_cycle_number: number;
  therapy_id: number;
  task_followup_dt: Date;
  task_due_date: Date | null;
  phone_use: string;
  therapy_specialty_type: string | null;
  drug_name: string;
  drug_strength: string;
  drug_strength_unit_of_measure: string;
}

export interface IBulkSms {
  id: number;
  template_text: string;
  created_by: number;
  created_dt: Date;
  status: string;
  filters: string;
  message: IBulkSmsRecipient[];
}

export interface ITableBulkSms extends IBulkSms {
  children: undefined[];
}

export interface ITableBulkSmsResponse {
  totalCount: number;
  results: ITableBulkSms[];
}

export interface IBulkSmsRequestPayload
  extends Omit<
    IBulkSmsFilters,
    | 'therapy_administration_status_id'
    | 'therapy_type_id'
    | 'service_group_id'
    | 'follow_up_date_to'
    | 'follow_up_date_from'
  > {
  follow_up_date_from?: string;
  follow_up_date_to?: string;
  service_group_id?: number[];
  therapy_type_id?: number[];
  therapy_administration_status_id?: number[];
}

export interface IBulkSmsSendRequestPayload extends IBulkSmsRequestPayload {
  text_template: string;
}

export class BulkSmsClient {
  static async previewRecipientList(filters: IBulkSmsFilters) {
    const url = '/sms/batch/preview';
    return TypedHttp.PostPromise<IBulkSmsRequestPayload, IBulkSmsRecipient[]>(
      url,
      BulkSmsClient.parseFiltersToPayload(filters),
    );
  }

  static async sendBulkSms(filters: IBulkSmsFilters & { text_template: string }) {
    const url = '/sms/batch';
    const payload = {
      ...BulkSmsClient.parseFiltersToPayload(filters),
      text_template: filters.text_template,
    };
    return TypedHttp.PostPromise<IBulkSmsSendRequestPayload, { batchId: number }>(url, payload);
  }

  static async findBulkSmsSent(filters: IBulkSmsFilters) {
    const url = '/sms/batch';
    const params = { ...BulkSmsClient.parseFiltersToPayload(filters) };
    return TypedHttp.GetPromise<{ results: IBulkSmsRecipient[]; totalCount: number }>(url, params);
  }

  static parseFiltersToPayload = (filters: IBulkSmsFilters) => {
    const {
      follow_up_date_to: followUpTo,
      follow_up_date_from: followUpFrom,
      service_group_id: serviceGroupIdStr,
      therapy_type_id: therapyTypesStr,
      therapy_administration_status_id: adminStatusStr,
    } = filters;
    const serviceGroupId = serviceGroupIdStr?.split(',')?.map(id => Number(id.trim()));
    const therapyTypes = therapyTypesStr?.split(',')?.map(id => Number(id.trim()));
    const adminStatusIds = adminStatusStr?.split(',')?.map(id => Number(id.trim()));
    return {
      ...filters,
      therapy_administration_status_id: adminStatusIds,
      follow_up_date_to: followUpTo?.format('YYYY-MM-DD'),
      follow_up_date_from: followUpFrom?.format('YYYY-MM-DD'),
      service_group_id: serviceGroupId,
      therapy_type_id: therapyTypes,
    };
  };
}

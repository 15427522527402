import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { useState } from 'react';
import {
  getStatusIdByStatusName,
  getStatusByStatusId,
  getStatusReasonByStatusId,
} from 'services/utils/task-service';
import NoteDisplay from 'components/note/note-display';
import DocumentDisplay from 'components/document/document-display';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import ActionRow from 'components/note/action-row';
import { useFillCycleNumber } from 'hooks/useFillCycleNumber';
import TaskStatusForm from 'components/dynamic-form/task-forms/task-status-form';
import TaskDetailForm from 'components/dynamic-form/task-forms/task-detail-form';
import { TASK_PRIOR_AUTHORIZATION, PA } from '../../../constants/index';
import { styles } from '../task-detail-styles';
import EditPa from './edit-pa';
import TaskLinks from '../task-links';
import { StatusSelector } from '../common/status-selector';

const PaDetail = props => {
  const {
    statuses,
    task,
    classes,
    tagTypeId,
    tagTypeLabel,
    serviceGroups,
    therapy,
    notes,
    displayEdit,
    handleCancel,
    taskIDPrefix,
    drugName,
    documents,
    currentUser,
    resourceStates,
  } = props;

  const {
    status_id: statusId,
    service_group_id: serviceGroupId,
    fill_cycle_number: fillCycleNumber,
    therapy_id: therapyId,
  } = task;

  const [selectedStatus, setSelectedStatus] = useState(
    task.status ? task.status : getStatusByStatusId(task.status_id, statuses),
  );
  const selectedStatusId = getStatusIdByStatusName(selectedStatus, statuses);

  const [needsByDate] = useFillCycleNumber(therapyId, fillCycleNumber);

  const paResourceStates = (resourceStates || []).filter(
    resourceState => resourceState.resource_name === PA,
  );

  if (!displayEdit) {
    return (
      <>
        <Grid container alignItems="center" className={classes.taskDetailContainer}>
          <Grid item lg={3}>
            <Grid container alignItems="center">
              <StatusSelector
                value={selectedStatus}
                currentStatus={task.status}
                onChange={value => setSelectedStatus(value)}
                statuses={statuses}
                taskIDPrefix={taskIDPrefix}
                task={task}
                currentUser={currentUser}
                resourceStates={paResourceStates}
              />
            </Grid>
          </Grid>
          <Grid item lg={5} xs={6}>
            <TaskDetailForm
              task={{
                ...task,
                needsby_date: needsByDate,
                status_reason: getStatusReasonByStatusId(statusId, statuses),
              }}
              taskType="PA"
            />
          </Grid>
          <Grid item xs={4}>
            <Grid item xs={12}>
              <Grid container className={classes.note}>
                <NoteDisplay {...notes} tagResourceId={task.id} />
              </Grid>
              <DocumentDisplay
                taskIdResourceIds={[{ tagTypeId, resourceId: task.id }]}
                tagTypeId={tagTypeId}
                resourceId={task.id}
                drugName={drugName}
                tagTypeLabel={tagTypeLabel}
                documents={documents}
              />
              <ActionRow
                therapy={therapy}
                task={task}
                tagTypeId={tagTypeId}
                tagResourceId={task.id}
                cancel={handleCancel}
                defaultServiceGroup={serviceGroupId}
                serviceGroups={serviceGroups}
                taskType={TASK_PRIOR_AUTHORIZATION}
                taskIDPrefix={taskIDPrefix}
              />
            </Grid>
          </Grid>
        </Grid>
        {task.links?.length > 0 && <TaskLinks links={task.links} />}

        {selectedStatus !== getStatusByStatusId(statusId, statuses) && statusId && statuses && (
          <Grid container>
            <Grid
              item
              xs={12}
              className={selectedStatus !== task.status ? classes.statusContainer : null}
            >
              <TaskStatusForm
                task={task}
                forceStatusId={selectedStatusId}
                overrideForceStatusId
                onCancel={() => setSelectedStatus(getStatusByStatusId(task.status_id, statuses))}
              />
            </Grid>
          </Grid>
        )}
      </>
    );
  }

  return (
    <>
      {task.links?.length && <TaskLinks links={task.links} />}
      <EditPa task={task} statuses={statuses} cancel={handleCancel} serviceGroups={serviceGroups} />
    </>
  );
};

function mapStateToProps(state) {
  const { lookups, auth } = state;

  return {
    users: lookups.users,
    currentUser: auth?.currentUser,
    resourceStates: lookups.resourceStates,
  };
}

export default compose(withStyles(styles), connect(mapStateToProps))(PaDetail);

import React, { PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { fetchTaskCounts, refreshSMSMessageList } from 'actions/action-tasks';
import { SMS } from 'constants/index';

const REFRESH_SMS_QUEUE_INTERVAL = 120000; // every 2 minutes

export const BackgroundTimers = (props: PropsWithChildren<{}>) => {
  const dispatch = useDispatch();
  const activeQueue = useTypedSelector(state => state.sidebarTaskType);
  const path = useTypedSelector(state => state?.router?.location?.pathname);
  const { children } = props;
  React.useEffect(() => {
    // Define here your intervals
    const smsQueueRefreshIntervalId: number = window.setInterval(() => {
      if (!/\/patients/.test(path)) {
        return;
      }
      dispatch(
        fetchTaskCounts({
          forceTaskType: SMS,
        }),
      );
      if (activeQueue === SMS) {
        // if the SMS queue is active, refresh the worklist as well (patient rows in the sidebar)
        dispatch(refreshSMSMessageList());
      }
    }, REFRESH_SMS_QUEUE_INTERVAL);

    return () => {
      // clear here all the intervals, if is undefined it doesn't matter
      window.clearInterval(smsQueueRefreshIntervalId);
    };
  });

  return <>{children}</>;
};

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import tasksSelectJson from 'config/therapies/tasks-select.json';
import { convertMapToList } from 'reducers/helper-reducer';
import { getTasksOfATherapy } from 'services/utils/therapy-service';
import { formatPatientName } from 'services/utils/demographic-service';
import { convertToArborDate } from 'models/time/arbor-date';
import {
  addTaskOldValues,
  getTaskUniqueId,
  getPendingTasks,
  getStatusNameByStatusId,
  getTaskName,
  getNeedsByDateOfTask,
} from 'services/utils/task-service';
import { makeStyles } from '@mui/styles';
import { Typography, Box } from '@mui/material';
import ModalForm from '../modal-form';

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 'bold',
  },
}));

const ChangeServiceGroupForm = ({
  therapyId,
  onClose,
  tasks,
  taskStatuses,
  onSubmit,
  filterTasks,
  fillCycles,
  newServiceGroup = {},
  therapies,
  patient,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const taskList = convertMapToList(tasks);

  const filtered = getPendingTasks(getTasksOfATherapy(taskList, therapyId)).filter(
    task => !filterTasks || filterTasks.includes(task.taskType),
  );

  useEffect(() => {
    if (!filtered.length) {
      setOpen(false);
      if (onSubmit) {
        onSubmit([]);
      }
    }
  }, []);

  const headers = [
    { label: 'Task', id: 'task', getValue: task => getTaskName(task) },
    {
      label: 'Status',
      id: 'status',
      getValue: task => getStatusNameByStatusId(task.status_id, taskStatuses),
    },
    {
      label: 'NBD',
      id: 'nbd',
      getValue: task => getNeedsByDateOfTask(fillCycles, task),
    },
  ];
  const therapy = therapies[therapyId];
  return (
    <ModalForm
      title="Change service group of associated tasks"
      instructions={
        <Typography>
          <Box mb={2}>
            {'You are changing service group of therapy '}
            <span className={classes.bold}>{therapy.drug_name}</span>
            {' for patient '}
            <span className={classes.bold}>{formatPatientName(patient)}</span>
          </Box>
          <Box>
            {
              // eslint-disable-next-line max-len
              'Before changing the therapy, please select which tasks you want to change service group to '
            }
            <span className={classes.bold}>{newServiceGroup.label}</span>
          </Box>
        </Typography>
      }
      open={open}
      jsonForm={tasksSelectJson}
      formId={`tasks-service-group-${therapyId}`}
      formName="select"
      data={{
        task_ids: filtered.map(task => `${task.taskType}${task.id}`),
      }}
      alwaysEnabled
      providers={{
        table: {
          headers,
          data: filtered.map(task => ({
            ...task,
            id: `${task.taskType}${task.id}`,
          })),
        },
      }}
      onSubmit={values => {
        setOpen(false);
        const selectedTasks = filtered.filter(
          task => values.task_ids.indexOf(getTaskUniqueId(task)) > -1,
        );
        if (onSubmit) {
          return onSubmit(
            selectedTasks.map(task => {
              const newTask = {
                id: task.id,
                taskType: task.taskType,
                therapy_id: task.therapy_id,
                service_group_id: newServiceGroup.value,
              };
              return addTaskOldValues(newTask, task);
            }),
          );
        }
        return true;
      }}
      submitButtonText="Change service group"
      cancelButtonText="Cancel"
      onCancel={() => {
        setOpen(false);
        if (onClose) {
          onClose();
        }
      }}
    />
  );
};

function mapStateToProps({
  tasks,
  taskStatuses,
  fillCycles,
  therapies,
  patient,
  selectedPatientId,
}) {
  const isRightPatient = patient && patient.id === selectedPatientId;
  return {
    tasks: tasks.data,
    taskStatuses: taskStatuses.statuses,
    fillCycles,
    therapies: therapies.data,
    patient: isRightPatient ? patient : {},
  };
}

export default connect(mapStateToProps)(ChangeServiceGroupForm);

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { defaultTheme } from '../../../../lib/themes';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    button: {
      'backgroundColor': defaultTheme.palette.primary.teal,
      'color': defaultTheme.palette.primary.white,
      'padding': `${theme.spacing(1)} ${theme.spacing(3)}`,
      '&:hover': {
        backgroundColor: defaultTheme.palette.primary.teal,
        borderColor: 'none',
        boxShadow: 'none',
      },
    },
    container: {
      borderBottom: `1px solid ${defaultTheme.palette.primary.grey13}`,
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(0.5),
    },
    sectionTitle: {
      color: defaultTheme.palette.primary.steelGrey,
      fontSize: defaultTheme.font.mediumFontSize,
      fontWeight: defaultTheme.font.weight.thick,
    },
    content: {
      color: defaultTheme.palette.primary.rollingStone,
      fontSize: defaultTheme.font.mediumFontSize,
    },
    approved: {
      color: defaultTheme.palette.primary.teal,
    },
    rejected: {
      color: defaultTheme.palette.error.main,
    },
    thick: {
      fontWeight: defaultTheme.font.weight.thick,
    },
    typography: {
      fontSize: defaultTheme.typography.fontSize,
    },
    dataRow: {
      paddingBottom: theme.spacing(0.5),
    },
    historyButton: {
      minWidth: 10,
      marginRight: theme.spacing(1),
    },
    errorButton: {
      'background': 'transparent',
      'color': defaultTheme.palette.error.main,
      'cursor': 'default',
      'marginRight': theme.spacing(0.5),
      'minWidth': theme.spacing(4),
      '&:hover': {
        backgroundColor: defaultTheme.palette.primary.grey3,
      },
    },
  };
});

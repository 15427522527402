import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const SpecialityTypeFormStyles = (theme: Theme) => {
  return createStyles({
    container: {
      padding: theme.spacing(1),
    },
  });
};

import React, { useEffect } from 'react';
import { sortBy } from 'lodash';
import { ISectionDetailsProps } from '../../section/interfaces';
import { IPatientEnrollmentProps } from '../types';
import AllergiesList from '../../../containers/patient/clinical/allergies/patient-allergies-list';
import ProblemsContainer from '../../problems/container';
import PatientSpecialPopulations from '../../../containers/patient/clinical/special-populations/patient-special-population-list';
import { SelectVerifiedInfo, useVerifiedText, VerifiedInfo } from '../utils';

const selectClinicalVerification: SelectVerifiedInfo = ({ patient }) => {
  const results: VerifiedInfo[] = [];
  if (!patient.allergies_verification_status_id) return null;
  results.push({
    userId: patient.allergies_verification_verified_by,
    date: patient.allergies_verification_verified_dt,
  });
  // ignore problems for showing verification
  // patient.problems, patient.problems_verified
  if (
    patient.special_populations_verification_status_id &&
    patient.special_populations_verification_verified_by &&
    patient.special_populations_verification_verified_dt
  ) {
    results.push({
      userId: patient.special_populations_verification_verified_by,
      date: patient.special_populations_verification_verified_dt,
    });
  }

  const lastVerified = sortBy(results, 'date').slice(-1)[0];
  return lastVerified;
};

export function PatientEnrollmentClinicalSection({
  alert: alertMessage,
}: ISectionDetailsProps<IPatientEnrollmentProps>) {
  const verifiedMessage = useVerifiedText(selectClinicalVerification);
  useEffect(() => {
    if (verifiedMessage) alertMessage(verifiedMessage, true);
    else alertMessage('Not Verified');
  }, [verifiedMessage]);

  return (
    <>
      <AllergiesList stretched />
      <ProblemsContainer stretched />
      <PatientSpecialPopulations stretched />
    </>
  );
}

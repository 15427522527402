import React from 'react';
import { createRoot } from 'react-dom/client';

import ReactDOM from 'react-dom';
import './index.scss';
// import registerServiceWorker from './registerServiceWorker';
import { datadogRum } from '@datadog/browser-rum';
import { unregister } from './registerServiceWorker';
import { configureWinston } from './winston-logger';

import App from './app';
import { configureDataDog } from './datadog';

const container = document.getElementById('root');
const root = createRoot(container);

configureWinston('debug');
configureDataDog();
// NextApp = process.env.NODE_ENV === 'development' ? hot(App) : App;

unregister();

function render(AppComponent) {
  root.render(<AppComponent tab="home" />);
}

render(App);
// registerServiceWorker();

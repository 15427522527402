export class NumberUtils {
  public static isEven = (num: number): boolean => {
    return num % 2 === 0;
  };

  public static tryConvert = (input: unknown, fallback: number): number => {
    if (typeof input !== 'object') {
      try {
        const parsedNumber = Number(input);
        if (!Number.isNaN(parsedNumber)) {
          return parsedNumber;
        }
      } catch {
        // Don't care about the exception. Just don't want it to fail
      }
    }

    return fallback;
  };

  /**
   * Generates a random integer
   * @param min minimum number, inclusive
   * @param max maximum number, inclusive
   * @returns a whole number between the min and max values
   */
  public static randomInteger = (min: number, max: number): number => {
    const minCeil = Math.ceil(min);
    const maxFloor = Math.floor(max);
    return Math.floor(Math.random() * (maxFloor - minCeil + 1)) + minCeil;
  };

  public static ensureLeadingZero = (possibleNumber: string): string => {
    if (possibleNumber) {
      const parsedNumber = Number(possibleNumber);
      if (!Number.isNaN(parsedNumber)) {
        if (possibleNumber.charAt(0) === '.') {
          return `0${possibleNumber}`;
        }
      }
    }
    return possibleNumber;
  };
}

import { Theme } from '@mui/material/styles';

const expandTheme = (theme: Theme) => {
  return {
    palette: (theme as any).palette,
    fonts: (theme as any).font,
    layout: (theme as any).layout,
    typography: (theme as any).typography,
    font: (theme as any).font,
  };
};

export { expandTheme };

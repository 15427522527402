/**
 * Class that retrieves timezone info from customers table,
 * default to user local timezone if API fails
 *
 * @export
 * @class Timezone
 */
export class Timezone {
  /**
   * Singleton instance for Time object
   *
   * @static
   * @type {Timezone}
   * @memberof Timezone
   */
  static instance: Timezone;

  /**
   * the timezone of a given customer
   * e.g. 'America/Los_Angles'
   *
   * @type {string}
   */
  timezone: string;

  /**
   * check if the timezone is initialized
   *
   * @type {boolean}
   * @memberof Timezone
   */
  isTimezoneLoaded = false;

  /**
   * Creates an instance of Time object.
   *
   * @param {string} _timezone
   * @memberof Timezone
   */
  constructor(public _timezone: string) {
    this.timezone = _timezone;
    this.isTimezoneLoaded = true;
  }

  /**
   * Returns a singleton instance of Time object, default to user local timezone
   *
   * @static
   * @returns {Timezone}
   * @memberof Timezone
   */
  static getInstance(): Timezone {
    return Timezone.instance;
  }
}

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

export const PatientStyles = (theme: Theme) => {
  const { palette } = expandTheme(theme);
  return createStyles({
    provided: {
      color: palette.primary.cuttySark,
      fontSize: 20,
      padding: theme.spacing(0.5),
    },
    notProvided: {
      color: palette.primary.valenciaRed,
      fontSize: 20,
      padding: theme.spacing(0.5),
    },
    notSpecific: {
      fontSize: 20,
      color: palette.primary.deepBlue,
      padding: theme.spacing(0.5),
    },
    green: {
      color: palette.primary.cuttySark,
      fontSize: 20,
      padding: theme.spacing(0.5),
    },
    yellow: {
      color: palette.primary.yellow,
      fontSize: 20,
      padding: theme.spacing(0.5),
    },
    red: {
      color: palette.primary.valenciaRed,
      fontSize: 20,
      padding: theme.spacing(0.5),
    },
    iconActive: {
      color: palette.primary.deepBlue,
      height: theme.spacing(2.5),
      padding: theme.spacing(0.5),
    },
    iconInactive: {
      color: palette.primary.grey23,
      height: theme.spacing(2.5),
      padding: theme.spacing(0.5),
    },
    noMobileNumbers: {
      color: palette.primary.grey23,
      height: theme.spacing(2.5),
      paddingBottom: theme.spacing(0.5),
    },
  });
};

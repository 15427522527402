import React from 'react';
import { Grid } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { TasksStyles } from './index.styles';
import TasksTable from './tasks-table/tasks-table';

interface ITasksProps extends WithStyles<typeof TasksStyles> {
  patientId: number;
}

const NewPatientTasks = (props: ITasksProps): JSX.Element => {
  const { classes } = props;

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <TasksTable />
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(TasksStyles)(NewPatientTasks);

export enum InterventionTypeName {
  Adherence = 'Adherence',
  Ddi = 'DDI',
  Adr = 'ADR',
  Regimen = 'Regimen',
  ReferralOfService = 'Referral of Service',
  Lab = 'Lab',
  OtherClinical = 'Other Clinical',
  Dur = 'DUR',
  LinkageToCare = 'Linkage to Care',
  ProductIssue = 'Product Issue',
  DrugInformation = 'Drug Information',
  Vaccine = 'Vaccine',
  HospitalizationAndEr = 'Hospitalization and ER',
  InfectionControl = 'Infection Control',
}

export enum InterventionTypeId {
  Adherence = 1,
  Ddi = 3,
  Adr = 4,
  Regimen = 5,
  ReferralOfService = 6,
  Lab = 7,
  OtherClinical = 8,
  Dur = 9,
  LinkageToCare = 10,
  ProductIssue = 11,
  DrugInformation = 12,
  Vaccine = 13,
  HospitalizationAndEr = 14,
  InfectionControl = 15,
}

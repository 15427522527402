import {
  BULK_TASKS_UPDATED,
  CLEAR_WORK_LIST,
  FETCH_WORK_LIST_FAILURE,
  FETCH_WORK_LIST_REQUEST,
  FETCH_WORK_LIST_SEARCH_FAILURE,
  FETCH_WORK_LIST_SEARCH_REQUEST,
  FETCH_WORK_LIST_SEARCH_SUCCESS,
  FETCH_WORK_LIST_SUCCESS,
  RECENT_PATIENTS_REQUEST_SUCCESS,
  RESOLVE_RECONCILIATION_SUCCESS,
  SET_WORK_LIST,
  UPDATE_PATIENT_SEARCH_INDEX,
  UPDATE_TASK_PROPERTIES,
  PATIENTS_ADVANCED_SEARCH_REQUEST_SUCCESS,
  MARK_SMS_AS_READ_SUCCESS,
  SMS_RELATED_TASK,
  EDIT_PATIENT,
  LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_WORKLIST_SUCCESS,
} from 'constants/index';

const initialState = {
  loading: true,
  data: [],
  loadedTask: null,
  firstTime: true,
  selectedPatientInListIndex: 0,
};

export default (state = initialState, { type, data, task, payload }) => {
  switch (type) {
    case FETCH_WORK_LIST_REQUEST:
    case FETCH_WORK_LIST_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        loadedTask: payload?.loadedTask || state.loadedTask,
      };
    case FETCH_WORK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: data.patients,
        uniquePatientCount: data.uniquePatientCount || 0,
        loadedTask: task,
        firstTime: false,
      };
    case PATIENTS_ADVANCED_SEARCH_REQUEST_SUCCESS:
    case RECENT_PATIENTS_REQUEST_SUCCESS:
    case FETCH_WORK_LIST_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data,
        loadedTask: task,
        firstTime: false,
      };
    case SET_WORK_LIST:
      return {
        loading: false,
        data,
      };
    case FETCH_WORK_LIST_FAILURE:
    case FETCH_WORK_LIST_SEARCH_FAILURE:
      return {
        loading: false,
        data: [],
      };
    case CLEAR_WORK_LIST:
      return {
        ...state,
        data: [],
      };
    case UPDATE_TASK_PROPERTIES: {
      const { taskType, taskId, properties } = payload || {};
      if (taskType && taskId && properties && properties.followup_dt) {
        const newList = [...state.data];
        const patientIndex = newList.findIndex(
          patient => patient.taskType === taskType && patient.task_id === taskId,
        );
        if (patientIndex > -1) {
          newList[patientIndex] = {
            ...newList[patientIndex],
            followup_dt: properties.followup_dt,
          };
        }
        return {
          ...state,
          data: newList,
        };
      }
      return state;
    }

    case RESOLVE_RECONCILIATION_SUCCESS: {
      const { patient_id: patientId, needs_reconciliation: needsReconciliation } = payload || {};
      if (patientId) {
        const updatedData = state.data.map(patient => {
          if (patient.id === patientId) {
            return {
              ...patient,
              needs_reconciliation: needsReconciliation,
            };
          }
          return patient;
        });
        return {
          ...state,
          data: updatedData,
        };
      }
      return state;
    }
    case BULK_TASKS_UPDATED: {
      if (payload.data && payload.data.updated_tasks) {
        return {
          ...state,
          data: state.data.map(patientItem => {
            const relatedTask = payload.data.updated_tasks.find(
              updatedTask =>
                patientItem.task_id === updatedTask.id &&
                patientItem.taskType === updatedTask.taskType,
            );
            if (relatedTask) {
              return {
                ...patientItem,
                status_id: relatedTask.status_id,
                status_category_id: relatedTask.status_category_id,
                followup_dt: relatedTask.followup_dt,
                due_date: relatedTask.due_date,
              };
            }
            return patientItem;
          }),
        };
      }
      return state;
    }
    case UPDATE_PATIENT_SEARCH_INDEX: {
      return { ...state, selectedPatientInListIndex: payload };
    }
    case MARK_SMS_AS_READ_SUCCESS: {
      if (state.loadedTask === SMS_RELATED_TASK && payload.smsId) {
        return {
          ...state,
          data: state.data.filter(row => row.sms_id !== payload.smsId),
        };
      }
      return state;
    }
    case LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_WORKLIST_SUCCESS: {
      if (
        data &&
        data.userCustomListsUserDataForPatients &&
        data.userCustomListsUserDataForPatients.length > 0
      ) {
        return {
          ...state,
          data: state.data.map(patient => {
            const userDataMatchingPatient =
              data.userCustomListsUserDataForPatients.find(
                userData => userData.patient_id === patient.id,
              ) || [];
            return {
              ...patient,
              otherUsersWithPatientOnList:
                userDataMatchingPatient.otherUsersWithPatientOnList || [],
            };
          }),
        };
      }
      return state;
    }

    case EDIT_PATIENT: {
      const { updated_patient } = payload.data;
      return {
        ...state,
        data: state.data.map(patient => {
          if (patient.id === updated_patient.id) {
            return {
              ...patient,
              ...updated_patient,
            };
          }
          return patient;
        }),
      };
    }
    default:
      return state;
  }
};

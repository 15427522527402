import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { ModalUtil } from 'services/utils/modal.util';
import { expandTheme } from 'utils/theme-util';

export const modal = (theme: Theme): any => {
  return {
    ...ModalUtil.BasicModalStyling(theme, 90, 3),
  };
};

export const modalTitle = (theme: Theme): any => {
  const { font } = expandTheme(theme);

  return {
    fontWeight: 'bold',
    fontSize: font.extraLargeFontSize,
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  };
};

import React from 'react';
import {
  getAddressList,
  getPhoneList,
  getStatusByStatusId,
  getReasonsByStatus,
} from 'services/utils/task-service';
import fcJson from 'config/task-types/fc.json';
import {
  methodOfDelivery,
  methodOptions,
  ancillarySupplies,
  fcInProgressReasons,
} from 'constants/lists';
import { FillCoordinationStatus } from 'interfaces/enums/TaskStatuses/FillCoordinationStatus';
import { FILL_COORDINATION_STATUS } from 'constants/index';
import moment from 'moment-timezone';
import {
  getDiffDate,
  getDeliveryMethodLabel,
  getShippingMethodLabel,
  getPaymentTypeLabel,
} from 'containers/tasks/fill-coordination/fc-util';
import {
  isVirtualCard,
  sortPaymentMethods,
} from 'containers/tasks/fill-coordination/payment-methods/payment-method-utils';

import { fetchGpis } from 'services/utils/therapy-service';
import { convertToArborDate } from '../../../models/time/arbor-date';
import { staticProviders, getCommonProviders } from './common-provider';

const getFillCycle = (fillCycles, task) => {
  const { therapy_id: therapyId } = task;

  if (
    fillCycles &&
    task &&
    fillCycles[therapyId] &&
    fillCycles[therapyId][task.fill_cycle_number]
  ) {
    return fillCycles[therapyId][task.fill_cycle_number];
  }

  return {};
};

export default (task, state) => {
  const { patient, fillCycles, paymentMethods, therapies } = state;
  const therapy = state.therapies ? state.therapies[task.therapy_id] : null;
  const fillCycle = getFillCycle(fillCycles, task);
  if (task.status_id === FILL_COORDINATION_STATUS.COMPLETED) {
    task.needsby_date = convertToArborDate(fillCycle.needsby_date, false).getUtcDate();
    task.next_needsby_date = convertToArborDate(fillCycle.needsby_date, false)
      .addDays(therapy ? therapy.days_supply : 0)
      .getUtcDate();
  }
  task.drop_ship_med = therapy?.drop_ship_med;
  // eslint-disable-next-line no-confusing-arrow
  const calculateNextNeedsByDate = (daysSupply, needsByDate) =>
    daysSupply === 0 ? needsByDate : moment(needsByDate.clone().utc().add(daysSupply, 'days'));
  const handleChange = (fieldId, value, formValues) => {
    switch (fieldId) {
      case 'on_hand_qty': {
        const onHandQty = value || 0;
        if (getDiffDate(formValues.needsby_date) !== onHandQty) {
          const newNeedsByDate = moment.utc().startOf('day').add(onHandQty, 'days');

          state.change(state.formId, 'needsby_date', newNeedsByDate);
          return handleChange('needsby_date', newNeedsByDate, formValues);
        }
        return null;
      }
      case 'days_supply': {
        const daysSupply = value || 0;
        return state.change(
          state.formId,
          'next_needsby_date',
          calculateNextNeedsByDate(daysSupply, formValues.needsby_date),
        );
      }
      case 'needsby_date': {
        const diffDays = getDiffDate(value);
        state.change(state.formId, 'on_hand_qty', diffDays > 0 ? diffDays : 0);
        return state.change(
          state.formId,
          'next_needsby_date',
          calculateNextNeedsByDate(formValues.days_supply, value),
        );
      }
      default:
        return null;
    }
  };

  const commonState = getCommonProviders(task, state, fcJson);

  const { statuses, lookups } = commonState;

  const canceledStatuses = getReasonsByStatus(
    commonState.statuses,
    getStatusByStatusId(FillCoordinationStatus.Canceled, statuses),
  )?.filter(it => it.label);

  return {
    ...staticProviders,
    ...commonState,
    deliveryMethods: methodOfDelivery.map(p => ({
      id: p.value,
      name: p.label,
    })),
    methodOptions,
    phoneNumbers: getPhoneList(patient || {}).map(p => ({
      id: p.value,
      name: p.label,
    })),
    addresses: getAddressList(patient || {}).map(p => ({
      id: p.value,
      name: p.label,
    })),
    ancillarySupplies: ancillarySupplies.map(p => ({
      id: p.value,
      name: p.label,
    })),
    therapies,
    therapyFillCycleReasons: state.lookups
      ? state.lookups.therapyFillCycleReasons.map(r => ({
          name: r.description,
          id: r.id,
          new: Boolean(r.new),
        }))
      : [],
    inProgressReasons: fcInProgressReasons.map(p => ({
      id: p.value,
      name: p.label,
    })),
    canceledReasons: canceledStatuses?.map(s => ({
      id: s.value,
      name: s.label,
    })),
    upsPackagingTypes: state.lookups
      ? state.lookups.upsPackagingType?.map(p => ({
          id: p.id,
          name: p.packaging_type,
        }))
      : [],

    courierType: state.lookups
      ? state.lookups.courierTypes?.map(c => ({ id: c.id, name: c.type_name }))
      : [],
    /**
     * value should be a string like "{'something': true, 'else': false, 'other': true}"
     * and returns 'something, other'
     */
    trueFalseJsonArrayToCommaListInitialValues: value => {
      try {
        const parsed = JSON.parse(value);
        const result = Object.keys(parsed)
          .filter(key => parsed[key])
          .join(',');
        return result;
      } catch {
        return undefined;
      }
    },
    additionalMedicationsInitialValues: value => {
      try {
        const parsed = JSON.parse(value);
        // eslint-disable-next-line arrow-body-style
        return parsed.map(med => ({
          value: med.value,
          label: med.label,
        }));
      } catch {
        return undefined;
      }
    },
    orderShippingMethodFormatter: value => getShippingMethodLabel(value),
    paymentMethodCardNumFormatter: function (values) {
      if (!values?.length || !Object.keys(paymentMethods?.entities ?? {}).length) {
        return 'No payment methods';
      }
      return sortPaymentMethods(values.map(id => paymentMethods.entities?.[id] ?? {}))
        .map(paymentMethod => {
          const cardNumLabel = `****-****-****-${paymentMethod.card_num}`;
          return isVirtualCard(paymentMethod)
            ? `${cardNumLabel} (${
                therapies[paymentMethod.therapy_id]?.drug_name ?? 'Virtual Card'
              })`
            : `${cardNumLabel} (${getPaymentTypeLabel(
                paymentMethod,
                lookups?.paymentMethodTypes,
              )})`;
        })
        .map(val => <span style={{ display: 'block' }}>{val}</span>);
    },
    deliveryMethodFormatter: deliveryMethodId =>
      getDeliveryMethodLabel(task.shipping_method, deliveryMethodId),
    json: fcJson,
    events: {
      days_supply: {
        onChange: (field, value, formValues) => {
          handleChange(field.id, value, formValues);
        },
      },
      needsby_date: {
        onChange: (field, value, formValues) => {
          handleChange(field.id, value, formValues);
        },
      },
      on_hand_qty: {
        onChange: (field, value, formValues) => {
          handleChange(field.id, value, formValues);
        },
      },
      welcome_kit_sent: {
        // eslint-disable-next-line no-unused-vars
        onChange: (values, value) => {
          state.change(state.formId, 'welcome_kit_sent_date', moment.now());
        },
      },
      is_notice_privacy_practices_sent: {
        // eslint-disable-next-line no-unused-vars
        onChange: (values, value) => {
          state.change(state.formId, 'notice_privacy_practices_sent_date', moment.now());
        },
      },
    },
    patient,
    initialValues: {
      next_needsby_date: convertToArborDate(fillCycle.needsby_date, false)
        .addDays(therapy ? therapy.days_supply : 0)
        .getUtcDate(),
      spoke_to_patient_dt: moment(),
      patient_welcome_kit_sent: patient && patient.welcome_kit_sent,
      patient_is_notice_privacy_practices_sent: patient && patient.is_notice_privacy_practices_sent,
      welcome_kit_sent: undefined,
      is_notice_privacy_practices_sent: undefined,
    },
    asyncProvider: inputValue =>
      new Promise((resolve, rejected) => {
        fetchGpis(inputValue)
          .then(result =>
            resolve(
              result.data.drugs.map(item => ({
                value: item,
                label: `${item.drug_info} - NDC: ${item.ndc}`,
              })),
            ),
          )
          .catch(err => rejected(err));
      }),
  };
};

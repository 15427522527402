import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PoweredBy from '../components/powered-by';
import Paragraph from '../components/paragraph';
import Container from '../components/container';
import WidthController from '../components/width-controller';
import Button from '../components/button';

const useStyles = makeStyles(theme => ({
  container: {
    alignSelf: 'center',
  },
  contactDetail: {
    color: theme.palette.primary.deepBlue,
    margin: '30px 0',
    fontWeight: 'bold',
    [`${theme.breakpoints.down('xl')} and (orientation: landscape)`]: {
      margin: '10px 0',
    },
  },
  contactButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const addToContact = () => {};

export default () => {
  const classes = useStyles();
  return (
    <Container title="Medication effectiveness questionnaire">
      <WidthController className={classes.container}>
        <Paragraph type="title" color="secondary">
          Thank you for your participation
        </Paragraph>
        <Paragraph type="subTitle">You can contact us anytime</Paragraph>
        <Button onClick={() => addToContact()} className={classes.contactButton} variant="bordered">
          Add to your contacts
        </Button>
        <PoweredBy />
      </WidthController>
    </Container>
  );
};

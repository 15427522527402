import { Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { styles } from './therapy-detail.styles';

const ObsoleteNdcTooltip = ({ classes }) => (
  <Tooltip
    title="This NDC is no longer active. This therapy needs to be edited or discontinued."
    placement="right"
  >
    <InfoIcon className={classes.infoIcon} />
  </Tooltip>
);

export default withStyles(styles)(ObsoleteNdcTooltip);

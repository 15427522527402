import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

export const styles = (theme: Theme) => {
  const { palette } = expandTheme(theme);
  return createStyles({
    listContainer: {
      padding: '20px',
      margin: '0 auto',
      maxWidth: '1360px',
      width: '100%',
      boxSizing: 'border-box',
    },
    column1: {
      fontSize: theme.spacing(2),
      fontWeight: 500,
      width: '30%',
    },
    column2: {
      fontSize: theme.spacing(2),
      fontWeight: 500,
      width: '20%',
    },
    title: {
      color: '#569177',
      fontSize: theme.spacing(2.5),
      fontWeight: 600,
    },
    questionnaireModal: {
      height: '70%',
      width: '50%',
      background: 'white',
      margin: 'auto',
      marginTop: '8%',
    },
    button: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      minWidth: theme.spacing(13),
    },
    selectedButton: {
      backgroundColor: palette.primary.teal,
      color: palette.primary.white,
    },
    reportButton: {
      alignSelf: 'end',
    },
    typeSelectors: {
      'padding': '30px 0',
      '& * + *': {
        marginLeft: '10px',
      },
      '& > *': {
        padding: '16px',
        fontSize: '14px',
        maxWidth: '100%',
        width: 'auto',
      },
    },
  });
};

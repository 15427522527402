import { Grid } from '@mui/material';
import React, { memo, useRef } from 'react';
import shallowEqual from 'recompose/shallowEqual';
import { useVisibilityChecker } from 'utils/use-visibility-checker';

export function createLazyOnscreenRender<T extends object>(BaseComponent: React.ComponentType<T>) {
  const ObserverReceiver = ({ $inView, $props }: { $inView: boolean; $props: T }) => (
    <BaseComponent {...$props} />
  );
  const MemoObserverReceiver = memo(ObserverReceiver, (prevProps, nextProps) => {
    // If the component is not in view, don't render it or its children
    return (
      !nextProps.$inView ||
      // or, at least do a shallow compare
      shallowEqual(prevProps, nextProps)
    );
  });
  return function ObserverWrapper(props: T) {
    const ref = useRef(null);
    const isInView = useVisibilityChecker(ref, true);
    return (
      <Grid container style={{ width: '100%' }} ref={ref}>
        <MemoObserverReceiver $inView={isInView} $props={props} />
      </Grid>
    );
  };
}

export default createLazyOnscreenRender;

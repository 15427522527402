import React from 'react';
import { Divider, TableHead, TableRow, TableCell, Checkbox, TableSortLabel } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { IMedsRowData } from 'models/meds/IMed';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { styles } from '../meds-styles';

interface TitleBarProps {
  classes?: any;
}
type Order = 'asc' | 'desc';

export const TitleBar = withStyles(styles)((props: TitleBarProps) => {
  const { classes } = props;
  return <div className={classes.tableTitle}>Medications Enrollment</div>;
});

export const Footer = withStyles(styles)(
  ({
    classes,
    onClose,
    handleContinue,
    hideSubmitButton,
  }: {
    classes: any;
    onClose: (event: {}, reason: string) => void;
    handleContinue: () => void;
    hideSubmitButton: boolean;
  }) => {
    return (
      <div className={classes.footerContainer}>
        <Divider />
        <ConfirmationPanel
          className={classes.confirmationPanel}
          submitButtonText="Continue"
          handleCancel={onClose}
          handleSubmit={handleContinue}
          disableSubmit={hideSubmitButton}
        />
      </div>
    );
  },
);

interface HeadCell {
  disablePadding: boolean;
  id: keyof IMedsRowData;
  label: string;
  align: 'left' | 'center';
  style: string;
  sortable: boolean;
  sortUsing?: keyof IMedsRowData;
}

const headCells: HeadCell[] = [
  {
    id: 'drug_name',
    align: 'left',
    disablePadding: true,
    label: 'Drug Name',
    style: 'drugNameColumn',
    sortable: true,
  },
  {
    id: 'ndc',
    align: 'center',
    disablePadding: false,
    label: 'NDC',
    style: 'ndcColumn',
    sortable: false,
  },
  {
    id: 'strength_with_unit',
    align: 'center',
    disablePadding: false,
    label: 'Dose',
    style: 'doseColumn',
    sortable: false,
  },
  {
    id: 'dosage_form',
    align: 'center',
    disablePadding: false,
    label: 'Form',
    style: 'formColumn',
    sortable: false,
  },
  {
    id: 'start_date',
    align: 'center',
    disablePadding: false,
    label: 'Start Date',
    style: 'startDateColumn',
    sortable: true,
    sortUsing: 'start_date_sortable',
  },
];

interface TableHeaderProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IMedsRowData) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  classes: any;
  maxSelectable: number;
}

function TableHeaderComponent(props: TableHeaderProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    classes,
    maxSelectable,
  } = props;

  const createSortHandler =
    (property: keyof IMedsRowData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className={classes.checkboxColumn}>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < maxSelectable}
            checked={rowCount > 0 && numSelected === maxSelectable && maxSelectable !== 0}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all medications',
            }}
            disabled={maxSelectable === 0}
          />
        </TableCell>
        {headCells?.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes[headCell.style]}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === (headCell?.sortUsing || headCell.id)}
                direction={orderBy === (headCell?.sortUsing || headCell.id) ? order : 'asc'}
                onClick={createSortHandler(headCell?.sortUsing || headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
export const TableHeader = withStyles(styles)(TableHeaderComponent);

import withStyles from '@mui/styles/withStyles';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { push } from 'connected-react-router';
import { fetchPatient, toggleTabRefresh, selectPatientTab } from 'actions/action-patient';
import { PerProtocolIcon } from 'components/icons/icons';
import { fetchNotes, addNote } from 'actions/action-notes';
import { ToggleLeftDrawer } from 'actions/action-view';
import InfiniteScroller from 'components/infinite-scroller/infinite-scroller';
import { loadSchedules } from 'actions/action-schedules';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { convertToArborDate } from 'models/time/arbor-date';
import { styles } from '../side-bar-styles';
import { getScheduleUrl } from '../../../../helpers/router';

const getAppointmentId = () => {
  // eslint-disable-next-line
  const parsed = queryString.parse(location.search);
  return parsed.appointment;
};

class ScheduleList extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    // This is where we define the user-facing
    // header labels for the task list.
    this.mapTaskPropsToLabels = {
      patient: 'Patient',
      drugs: 'Therapy',
      date: 'Date',
      clinic: 'Provider',
    };
  }

  componentDidMount() {
    const {
      selectPatientTab, // eslint-disable-line no-shadow
      toggleTabRefresh, // eslint-disable-line no-shadow
      schedules: { schedules },
    } = this.props;
    const appointmentId = getAppointmentId();
    if (schedules && schedules.length) {
      const appointment = schedules.find(ap => ap.id.toString() === appointmentId);
      if (appointment) {
        selectPatientTab('therapies');
        toggleTabRefresh(true);
      }
    }
  }

  selectAppointment(patientId, appointmentId) {
    const {
      push, // eslint-disable-line
      selectPatientTab, // eslint-disable-line
      toggleTabRefresh, // eslint-disable-line
    } = this.props;
    const url = getScheduleUrl(patientId, appointmentId);
    push(url);
    selectPatientTab('therapies');
    toggleTabRefresh(true);
  }

  handleClick(appointment) {
    this.selectAppointment(appointment.patient_id, appointment.id);
  }

  renderTableHeader() {
    const { classes } = this.props;
    return (
      <TableHead>
        <TableRow>
          <TableCell
            className={classNames(classes.tableHeader, classes.patientCol)}
            value="patient"
          >
            {this.mapTaskPropsToLabels.patient}
          </TableCell>
          <TableCell className={classNames(classes.tableHeader, classes.therapyCol)} value="drugs">
            {this.mapTaskPropsToLabels.drugs}
          </TableCell>
          <TableCell
            className={classNames(classes.tableHeader, classes.appointmentDateCol)}
            value="date"
          >
            {this.mapTaskPropsToLabels.date}
          </TableCell>
          <TableCell className={classNames(classes.tableHeader, classes.needsbyCol)} value="clinic">
            {this.mapTaskPropsToLabels.clinic}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  renderTableRow(appointment) {
    const { classes } = this.props;

    let { tableClass } = classes;
    const appointmentId = getAppointmentId();
    if (appointment.id.toString() === appointmentId) {
      tableClass = classNames(classes.tableRow, classes.tableRowSelected);
    }
    const date = convertToArborDate(appointment.start_dt, true).getCustomerDatetime(
      true,
      'YYYY-MM-DD h:mm A',
    );

    return (
      <TableRow
        hover
        key={appointment.id}
        id={appointment.id}
        onClick={() => this.handleClick(appointment)} // eslint-disable-line
        className={tableClass}
      >
        <TableCell className={classes.tableCell} component="th" scope="row">
          <div className={classes.tableMainContent}>
            <div className={classes.tablePatientName}>{appointment.patient}</div>
            <div className={classes.patientDOB}>
              {`${
                appointment.dob ? convertToArborDate(appointment.dob, true).getUtcDate(true) : '-'
              }`}
            </div>
          </div>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <div className={classes.tableLongContent}>{appointment.drugs}</div>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <div className={classes.tableContent}>{date}</div>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <div className={classes.tablePPIcon}>
            <span className={classes.needsByDate}>{appointment.clinic}</span>
            {Boolean(appointment.is_needsby_per_protocol) && <PerProtocolIcon />}
          </div>
        </TableCell>
      </TableRow>
    );
  }

  render() {
    const { classes, schedules, loadSchedules } = this.props; // eslint-disable-line no-shadow

    const list = schedules.schedules;

    return (
      <InfiniteScroller
        onScrollToBottom={() => {
          loadSchedules(true);
        }}
        className={classes.tableContainer}
        isLoading={schedules.isLoading}
      >
        <Table className={classes.table}>
          {this.renderTableHeader()}
          <TableBody className={classes.tableBody}>
            {list.map(appointment => this.renderTableRow(appointment))}
          </TableBody>
        </Table>
      </InfiniteScroller>
    );
  }
}

function mapStateToProps(state) {
  const { schedules, patient, patientList, lookups, tabControl } = state;
  return {
    schedules,
    patient,
    tabControl,
    patientList,
    lookups,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    onClickTask: () => new ToggleLeftDrawer(false),
    fetchPatient,
    toggleTabRefresh,
    selectPatientTab,
    fetchNotes,
    addNote,
    push,
    loadSchedules,
  }),
  withRouter,
)(ScheduleList);

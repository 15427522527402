import React from 'react';
import RenderCheckboxList from 'components/form/field/checkbox-list';
import useProvider from 'hooks/useProvider';

export default ({ providers, field, label, input, meta, disabled, labelSize, qaId }) => {
  const fields = useProvider(providers, field.provider, []);

  return (
    <RenderCheckboxList
      label={label}
      input={input}
      meta={meta}
      disabled={disabled}
      labelSize={labelSize}
      id={qaId}
      fields={fields}
    />
  );
};

import React from 'react';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { styles } from './header-styles';

const FormHeader = ({ header, classes, withMargin = true }) => (
  <div className={withMargin ? classes.titleContainerWithMargin : classes.titleContainer}>
    <Typography variant="h6" className={classes.title}>
      {header}
    </Typography>
  </div>
);

export default withStyles(styles, { withTheme: true })(FormHeader);

import React from 'react';
import { convertToArborDate } from 'models/time/arbor-date';
import { Tooltip, Box, SvgIcon, Grid } from '@mui/material';
import { INote } from 'interfaces/redux/INotes';
import { NoteTooltipStyled, NoteContentStyled } from './tooltip.styles';
import { NoteTooltipContentProps, NoteContentProps } from '../interfaces';

const PinnedIcon = (props: { fillColor?: string; viewBox?: string }): JSX.Element => {
  const { fillColor = 'white', viewBox } = props;
  return (
    <SvgIcon viewBox={viewBox} fontSize="small">
      <path
        d="M6.91 0L5.69 1.22L6.91 2.44L4.06 5.69H1.22L3.45 7.92L0 12.5V13H0.5L5.08 9.55L7.31 11.78V8.94L10.56 6.09L11.78 7.31L13 6.09L6.91 0ZM5.69 6.91L4.88 6.09L7.72 3.25L8.53 4.06L5.69 6.91Z"
        fill={fillColor}
      />
    </SvgIcon>
  );
};

const noteTooltipStyle = {
  fontWeight: 400,
  display: 'block',
};

const noteMentionStyle = {
  background: 'rgba(171, 171, 171, 0.5)',
  padding: '2px',
  lineHeight: 'normal',
  display: 'inline-block',
};

const parseNoteText = (noteText = '') =>
  noteText.split('!@!').map((text, index) => {
    if (!text) return '';
    if (index % 2) {
      return (
        <Box key={index} component="span" sx={noteMentionStyle}>
          {text}
        </Box>
      );
    }
    return <span key={index}>{text}</span>;
  });

export const NoteTooltipContent: React.FC<NoteTooltipContentProps> = (props): JSX.Element => {
  const { displayNotes } = props;
  const [note1, note2] = displayNotes;
  const getNoteUser = (note: INote) =>
    `${note.created_by_user}${note.created_by_user_active ? '' : ' (Inactive)'}`;
  const getNoteTime = (note: INote) =>
    convertToArborDate(note.created, true).getCustomerDate(false, 'MM/DD/YY - h:mm a');

  return (
    <>
      {note1 && (
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="space-between">
            <Box>
              {getNoteUser(note1)} - {getNoteTime(note1)}
            </Box>
            {!!note1.is_pinned && <PinnedIcon />}
          </Box>
          <Box sx={noteTooltipStyle}>{parseNoteText(note1.note_text)}</Box>
          {note2 && (
            <>
              <hr />
              <Box display="flex" justifyContent="space-between">
                <Box>
                  {getNoteUser(note2)} - {getNoteTime(note2)}
                </Box>
                {!!note2.is_pinned && <PinnedIcon />}
              </Box>
              <Box sx={noteTooltipStyle}>{parseNoteText(note2.note_text)}</Box>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export const NoteContentUnstyled: React.FC<NoteContentProps> = (props): JSX.Element => {
  const { displayNotes, classes } = props;
  const [note1] = displayNotes;
  const getNoteUser = (note: INote) =>
    `${note.created_by_user}${note.created_by_user_active ? '' : ' (Inactive)'}`;
  const getNoteTime = (note: INote) =>
    convertToArborDate(note.created, true).getCustomerDate(false, 'MM/DD/YY - h:mm a');

  return (
    <>
      <Grid container className={classes.noteContainer}>
        <Grid container flexWrap="nowrap" justifyContent="space-between">
          <Grid container item flexWrap="nowrap">
            <Grid>{getNoteUser(note1)}</Grid>
            <span>&nbsp; - &nbsp;</span>
            <Grid>{getNoteTime(note1)}</Grid>
          </Grid>
          <Grid>
            <Grid item className={classes.pin}>
              {!!note1.is_pinned && <PinnedIcon viewBox="-5 -5 24 24" fillColor="#70777A" />}
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.noteLine2}>
          {parseNoteText(note1.note_text)}
        </Grid>
      </Grid>
    </>
  );
};
export const NoteContent = NoteContentStyled(NoteContentUnstyled);
export const NoteTooltip = NoteTooltipStyled(Tooltip);

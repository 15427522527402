import React from 'react';

import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { EXPANDER_ID } from '../constants';

const expandedField = {
  Header: () => null,
  accessor: EXPANDER_ID,
  id: EXPANDER_ID,
  Cell: ({ row }) => (
    <span {...row.getToggleRowExpandedProps()}>
      <IconButton size="small">
        {row.isExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
      </IconButton>
    </span>
  ),
  SubCell: () => null,
};

export default expandedField;

import { IAdherenceData } from 'interfaces/redux/IAdherenceData';
import { DurStatus } from 'interfaces/enums/TaskStatuses/DrugUtilizationReviewStatus';
import { IPatientData } from './forms/chart-review/interfaces/IPatientSection';
import { IDurSectionData } from './forms/chart-review/interfaces/IDurSection';
import { IDcSectionData } from './forms/chart-review/interfaces/IDcSection';
import { IAdherenceSectionData } from './forms/chart-review/interfaces/IAdherenceSection';

export type PatientReview = {
  patient: IPatientData;
  drugUtilizationReview: IDurSectionData | undefined;
  dataCollects: IDcSectionData[];
  fdcItems: IAdherenceSectionData[];
  readOnly: boolean;
  isCslDur: boolean;
  pdcTable: IAdherenceData | undefined;
};

export const patientReviewHasReviewedDur = (patientReview: PatientReview | undefined): boolean => {
  return patientReview?.drugUtilizationReview?.task?.status_id === DurStatus.Reviewed;
};

import React from 'react';

import { Controller } from 'react-hook-form';
import { renderDropdown as RenderDropDown } from 'components/form/field/redux-field';

import { IControlledFormField } from '../types';

interface IOption<TValue> {
  label: string;
  value: TValue;
  disabled?: boolean;
}
interface IProps<TValue> extends IControlledFormField {
  percentWith?: number;
  options: IOption<TValue>[];
  defaultValue: TValue | null;
  onChange?: (args: any) => void;
}

export function ControlledDropdown<TValue extends string | number>(
  props: IProps<TValue>,
): JSX.Element {
  return (
    <Controller
      defaultValue={props.defaultValue}
      control={props.control}
      name={props.name}
      rules={props.validations || {}}
      render={(ctrlProps: any) => (
        <RenderDropDown
          width={props.percentWith && `${props.percentWith}%`}
          label={props.label}
          fields={props.options}
          input={{
            value: ctrlProps?.field?.value || ctrlProps?.value,
            onChange: (args: TValue[]) => {
              ctrlProps?.field?.onChange(args);
              if (props.onChange) {
                props.onChange(args);
              }
            },
            onBlur: ctrlProps?.field?.onBlur || ctrlProps?.onBlur,
          }}
          meta={props.inputMetaData || {}}
          data-qa-id={props.qaId}
          disabled={props.disabled}
          isOptionDisabled={(opt: IOption<TValue>) => opt.disabled}
        />
      )}
    />
  );
}

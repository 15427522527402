import React from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field } from 'redux-form';
import { renderTextField } from 'components/form/field/redux-field';
import { required } from 'components/form/validation/validation';
import { styles } from './field-styles';

export default props => {
  const { overrideWarningText, overrideField } = props;
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <Grid container className={classes.overrideFieldSection}>
      <Grid item xs={12}>
        <Typography>{overrideWarningText}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Field
          name={`${overrideField}_override_reason`}
          label="Reason *"
          component={renderTextField}
          validate={[required]}
        />
      </Grid>
    </Grid>
  );
};

import React from 'react';

import { Controller } from 'react-hook-form';
import { renderMultipleDropdown as RenderMultipleDropdown } from 'components/form/field/redux-field';

import { IControlledFormField } from '../types';

interface IProps<TValue> extends IControlledFormField {
  percentWith?: number;
  options: { label: string; value: TValue }[];
  defaultValue: TValue[] | null;
}

export const ControlledMultipleDropdown = <TValue extends string | number>(
  props: IProps<TValue>,
): JSX.Element => {
  return (
    <Controller
      defaultValue={props.defaultValue}
      control={props.control}
      name={props.name}
      rules={props.validations || {}}
      render={(ctrlProps: any) => (
        <RenderMultipleDropdown
          width={props.percentWith && `${props.percentWith}%`}
          label={props.label}
          fields={props.options}
          input={{
            value: ctrlProps?.field?.value,
            onChange: (value: TValue) => {
              let parsedValue: (number | string)[] = [];

              /**
               * RenderMultipleDropdown component by default converts all selected values to an string of comma separated values
               * I think it makes sense to return the values as array of numbers or string (depending of the options passed)
               * In that way it's consistent and typesafe
               *
               * So converting here depending on the value type to an array of selected values
               */
              if (typeof value === 'string') {
                parsedValue = value
                  .split(',')
                  .map(val => (!Number.isNaN(Number(val)) ? Number(val) : val));
              }
              /**
               * RenderMultipleDropdown It is also incosistent with the default value, so if you selected just 1 item , it returns a single number
               * so, making it consistent here and converting it to an array  forcing the form value will be always an array of
               * number | string (depending of the options passed)
               */
              if (typeof value === 'number') {
                parsedValue = [value];
              }

              ctrlProps?.field?.onChange(parsedValue);
            },
            onBlur: ctrlProps?.field?.onBlur,
          }}
          meta={props.inputMetaData || {}}
          data-qa-id={props.qaId}
          disabled={props.disabled}
        />
      )}
    />
  );
};

import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material';
import compose from 'recompose/compose';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { ADD_FINANCIAL_ASSISTANCE_FORM } from 'constants/index';
import {
  addFinancialAssistanceTask,
  addIncome,
  fetchFinancialAssistance,
  fetchIncomes,
} from 'actions/action-financials';
import {
  validateDate,
  validateDollarValue,
  required,
  validateAlphaNumeric,
  length6,
  maxLength20,
} from 'components/form/validation/validation';
import { stripFormattedDollarValue } from 'services/utils/formatting';

import { convertToArborDate } from 'models/time/arbor-date';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import {
  renderDropdown,
  renderTextField,
  renderMoneyTextField,
} from 'components/form/field/redux-field';
import { isEmpty } from 'lodash';
import { styles } from '../../task-detail-styles';
import { FaIncomeInformationForm } from '../fa-income-information-form';
import FormHeader from '../../../../components/form/header/header';

const form = `${ADD_FINANCIAL_ASSISTANCE_FORM}_INTERNAL_FOUNDATION`;

class AddInternalFoundation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 1,
      therapyId: null,
      noGroupIdCheckbox: false,
      noPCNCheckbox: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGroupIdCheckbox = this.handleGroupIdCheckbox.bind(this);
    this.handleNoPCNCheckbox = this.handleNoPCNCheckbox.bind(this);
  }

  // Handles the submit functionality.
  // Right now it sets some fields to null for simplicity's sake
  handleSubmit(values) {
    const {
      addFinancialAssistanceTask, // eslint-disable-line
      reset,
      therapy,
      cancel,
      task,
      patientId,
      selectedPatientId,
      categoryId,
      addIncome, // eslint-disable-line
      fetchIncomes, // eslint-disable-line
    } = this.props;
    const { noGroupIdCheckbox, noPCNCheckbox } = this.state;
    const payload = {
      ...values,
      patient_id: patientId || selectedPatientId,
      therapy_id: therapy ? therapy.id : values.therapy_id,
      task_fa_id: task ? task.id : null,
      status: 1,
      financial_assistance_type: Number(categoryId),
      group_id: noGroupIdCheckbox ? null : values.group_id,
      foundation_name: values.foundation_name,
      award_amount: !isEmpty(values.award_amount)
        ? stripFormattedDollarValue(values.award_amount)
        : values.award_amount,
      expiration_date: values.expiration_date
        ? convertToArborDate(values.expiration_date).getUtcDatetime()
        : null,
      foundation_program: values.foundation_program,
      household_income: values.household_income,
      household_size: values.household_size,
      bin: values.bin,
      pcn: noPCNCheckbox ? null : values.pcn,
      member_id: values.member_id,
    };

    addFinancialAssistanceTask(payload);

    if (values.create_income) {
      const incomePayload = {
        patient_id: patientId || selectedPatientId || values.patient_id,
        taxable_income: values.total_income ? stripFormattedDollarValue(values.total_income) : 0,
        family_size: values.family_size,
        income_year: values.income_year,
      };
      addIncome(incomePayload).then(() => {
        fetchIncomes(patientId || selectedPatientId);
      });
    }

    cancel();
    reset();
  }

  handleGroupIdCheckbox() {
    this.setState(prevState => ({
      noGroupIdCheckbox: !prevState.noGroupIdCheckbox,
    }));
  }

  handleNoPCNCheckbox() {
    this.setState(prevState => ({
      noPCNCheckbox: !prevState.noPCNCheckbox,
    }));
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      classes,
      cancel,
      addingFromFinancials,
      isPharmacyBenefit,
      therapies,
      task,
    } = this.props;

    const { therapyId, noGroupIdCheckbox, noPCNCheckbox } = this.state;
    const idSuffix = task && task.therapy_id && task.id ? `_${task.therapy_id}_${task.id}` : '';
    return (
      <Grid container justifyContent="center" spacing={7}>
        <Grid item xs={12}>
          <form className={classes.addFormContainer} autoComplete="off">
            <Grid container spacing={2}>
              <FaIncomeInformationForm
                form={form}
                idPrefix="add_FA_foundation_"
                idSuffix={idSuffix}
              />
              <Grid item xs={12}>
                <FormHeader header="Program Information" />
              </Grid>
              {addingFromFinancials && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name="therapy_id"
                    label="Therapy *"
                    validate={[required]}
                    component={renderDropdown}
                    fields={Object.values(therapies).map(t => ({
                      label: t.drug_name,
                      value: t.id,
                    }))}
                    state={therapyId}
                    id={`add_FA_foundation_therapy_id${idSuffix}`}
                  />
                </Grid>
              )}
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="expiration_date"
                  label="Expiration Date *"
                  component={renderDatePicker}
                  validate={[validateDate, required]}
                  id={`add_FA_foundation_expiration_date${idSuffix}`}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="award_amount"
                  label="Award Amount"
                  component={renderMoneyTextField}
                  validate={[validateDollarValue]}
                  id={`add_FA_foundation_award_name${idSuffix}`}
                />
              </Grid>
              {isPharmacyBenefit && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name="bin"
                    label="BIN *"
                    component={renderTextField}
                    validate={[required, validateAlphaNumeric, length6]}
                    id={`add_FA_foundation_bin${idSuffix}`}
                  />
                </Grid>
              )}
              {isPharmacyBenefit && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name="pcn"
                    label="PCN"
                    component={renderTextField}
                    validate={[maxLength20]}
                    id={`add_FA_foundation_pcn${idSuffix}`}
                  />
                </Grid>
              )}
              {isPharmacyBenefit && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name="member_id"
                    label="Member ID *"
                    component={renderTextField}
                    validate={[required]}
                    id={`add_FA_foundation_member_id${idSuffix}`}
                  />
                </Grid>
              )}
              {isPharmacyBenefit && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name="group_id"
                    label="Group ID"
                    validate={null}
                    component={renderTextField}
                    disabled={noGroupIdCheckbox}
                    id={`add_FA_foundation_group_id${idSuffix}`}
                  />
                </Grid>
              )}
            </Grid>
            <ConfirmationPanel
              cancelButtonName="add_task_cancel_button"
              submitButtonName="add_task_submit_button"
              handleSubmit={handleSubmit(this.handleSubmit)}
              handleCancel={cancel}
              disableSubmit={pristine || submitting}
            />
          </form>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const { selectedPatientId, therapies } = state;
  return {
    selectedPatientId,
    therapies: therapies.data,
    initialValues: {
      status: 1,
      foundation_name: null,
      award_amount: null,
      expiration_date: null,
      remaining_balance: null,
      spend_down: null,
      foundation_program: null,
      phone_number: null,
      copay: null,
      household_income: null,
      household_size: null,
    },
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    addFinancialAssistanceTask,
    fetchFinancialAssistance,
    addIncome,
    fetchIncomes,
  }),
)(reduxForm({ form })(AddInternalFoundation));

import { useTheme } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { IState } from 'interfaces/redux/IState';
import { convertToArborDate } from 'models/time/arbor-date';
import React, { useEffect, useState } from 'react';
import {
  IPatientMergeSectionDetailsProps,
  IPatientMergeTask,
  ITask,
} from 'components/patient-merge-modal/interfaces';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { useSelector } from 'react-redux';
import { PatientMergeClient } from 'clients/patient-merge';

export const ConfirmPatientTasksSection = (props: IPatientMergeSectionDetailsProps) => {
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);

  const { mergeId, expanded } = props;

  const [infos, setInfos] = useState<IPatientMergeTask[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [primaryData, setPrimaryData] = useState<ITask[]>([]);
  const [selectedData, setSelectedData] = useState<ITask[]>([]);
  const [additionalTasks, setAdditionalTasks] = useState<ITask[]>([]);

  const primary = useSelector((state: IState) => state.patientMerge.primary);
  const selectedProfileId = useSelector((state: IState) => state.patientMerge.duplicate);
  const inputs = useSelector((state: IState) => state.patientMerge.inputs);

  useEffect(() => {
    if (!loaded && expanded) {
      PatientMergeClient.getMergePatientTasksInfo(mergeId).then(response => {
        setInfos(response.data);
        setLoaded(true);
      });
    }
  }, [mergeId, expanded]);

  useEffect(() => {
    setPrimaryData(
      infos.find(info => info.patientId === primary)?.tasks || [],
    );
  }, [infos, primary]);

  useEffect(() => {
    setSelectedData(
      infos.find(info => info.patientId === selectedProfileId)?.tasks || [],
    );
  }, [infos, selectedProfileId]);

  useEffect(() => {
    setAdditionalTasks(
      selectedData.filter(task =>
        inputs.some(input => input.selectedId === task.id && input.type === task.taskType.id),
      ),
    );
  }, [selectedData]);

  const getLabel = (task: ITask) => {
    return [
      `${task.taskType.name} ${task.status.name}`,
      convertToArborDate(task.followUpDate).getCustomerDate(),
      task.typeName ? `Type: ${task.typeName}` : undefined,
    ].join(' / ');
  };

  return (
    <Grid container spacing={3}>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          {additionalTasks.map((task: ITask) => (
            <Grid paddingY={1} key={`additional-${task.id}`} paddingLeft={1.75} sx={classes.newItem}>
              <Typography sx={classes.staticTextItem}>
                {getLabel(task)}
              </Typography>
            </Grid>
          ))}
          {primaryData.map((task: ITask) => (
            <Grid paddingY={1} key={`primary-${task.id}`} paddingLeft={1.75}>
              <Typography sx={classes.staticTextItem}>
                {getLabel(task)}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';
import { expandTheme } from 'utils/theme-util';

export const styles = (theme: Theme) => {
  const { palette, fonts, layout } = expandTheme(theme);
  return createStyles({
    checkBoxLabel: {
      display: 'block',
      fontSize: fonts.mediumFontSize,
      color: palette.primary.steelGrey,
      minHeight: layout.taskFormLabelMinHeight,
    },
    fieldWrapper: {
      padding: theme.spacing(1.5),
    },
    helperText: {
      margin: 0,
      textTransform: 'uppercase',
    },
    fieldLabel: {
      fontSize: fonts.largeFontSize,
      fontWeight: 'bold',
      color: palette.primary.steelGrey,
    },
    checkboxWrapper: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  });
};

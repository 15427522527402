import debounce from 'debounce-promise';
import { DEBOUNCE_IN_MS } from 'constants/config';
import HTTP from '../http';

export const smsInfo = debounce((id, externalUrlId) => {
  const getURL = `/pmp/sms/${id}?externalUrlId=${externalUrlId}`;
  return HTTP.get(getURL, {});
}, DEBOUNCE_IN_MS);

export const getSmsTermsData = debounce(link => {
  const url = `/public/sms-terms-service/${link}`;
  return HTTP.get(url, {});
}, DEBOUNCE_IN_MS);

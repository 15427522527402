import React from 'react';
import { Grid, CircularProgress, Button, SnackbarContent, Snackbar } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CustomerDropdown from '../components/customer-dropdown';
import { SectionTitleStyled } from '../components/section-title';
import { IDayAreaShippingProps } from './day-area-shipping.interface';
import DayAreaTable from './table';
import AddDayAreaModal from './add-day-area-shipping-modal';
import { DayAreaShippingStyles } from './day-area-shipping.styles';

const DayAreaShippingManager = ({
  loading,
  selectedCustomerId,
  daysShipping,
  classes,
  setSelectedCustomerId,
  handleModalAddWeekday,
  showAddWeekdayModal,
  submitRequestWeekday,
  handleSnackbar,
  showSnackbar,
  snackbarMessage,
  setSnackbarMessage,
}: IDayAreaShippingProps): JSX.Element => (
  <>
    <SectionTitleStyled
      title="Day Area Shipping Manager"
      rightSideItems={[
        {
          element:
            selectedCustomerId > 0 ? (
              <Grid item xs={9} marginLeft={5}>
                <Button
                  name="addDayAreaShipping"
                  type="button"
                  onClick={handleModalAddWeekday}
                  className={classes.saveButton}
                  id="addModalDayAreaShippingButton"
                  variant="contained"
                  color="primary"
                >
                  Add Day
                </Button>
              </Grid>
            ) : null,
          key: 'add-day-area-shipping-button',
        },
      ]}
    />
    <AddDayAreaModal
      open={showAddWeekdayModal}
      close={handleModalAddWeekday}
      submitRequestWeekday={submitRequestWeekday}
      daysShipping={daysShipping}
      handleSnackbar={handleSnackbar}
      setSnackbarMessage={setSnackbarMessage}
    />
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={showSnackbar}
      autoHideDuration={2000}
      onClose={handleSnackbar}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
    >
      <SnackbarContent className={classes.snackbar} message={snackbarMessage} />
    </Snackbar>
    <Grid container direction="column" spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <CustomerDropdown
              isLabelOn
              isSelectedOption
              selectedCustomerId={selectedCustomerId}
              setSelectedCustomerId={customerId => setSelectedCustomerId(customerId)}
            />
          </Grid>
        </Grid>
      </Grid>
      {loading ? (
        <Grid item>
          <CircularProgress />
        </Grid>
      ) : daysShipping.length === 0 ? (
        selectedCustomerId === 0 || 'No weekdays registered for this customer'
      ) : (
        <DayAreaTable data={daysShipping} />
      )}
    </Grid>
  </>
);

export default withStyles(DayAreaShippingStyles)(DayAreaShippingManager);

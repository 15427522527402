import React, { useEffect, useState } from 'react';
import MedicationList from 'containers/patient/clinical/medication-list/medication-list';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { linkTherapiesMedications, sortMedications } from 'services/utils/medication-service';
import { MEDICATION_THERAPY_STATUS_ACTIVE } from 'constants/index';
import { IPatientEnrollmentSectionDetailsProps } from '../types';
import { useVerifiedText } from '../utils';

export function PatientEnrollmentMedicationSection({ alert: alertMessage }: IPatientEnrollmentSectionDetailsProps) {
    const patient = useTypedSelector(state => state.patient);
    const medicationList = useTypedSelector(state => state.medications.medicationList);
    const medicationGroups = useTypedSelector(state => state.medications.medicationGroups);
    const therapyList = useTypedSelector(state => state.therapies.data);
    const verifiedMessage = useVerifiedText(state => {
        const userId = state.patient.medications_verification_verified_by;
        const date = state.patient.medications_verification_verified_dt;
        const isVerified = !!state.patient.medications_verification_status_id;
        return isVerified && userId && date ? { userId, date } : null;
    });
    const [medicationGroupsWithTherapies, setMedicationGroupsWithTherapies] = useState(
        sortMedications(linkTherapiesMedications(medicationGroups, therapyList))?.filter(
            (m: any) => m.active === MEDICATION_THERAPY_STATUS_ACTIVE,
        ) ?? [],
    );
    const [medicationsWithTherapies, setMedicationsWithTherapies] = useState(
        linkTherapiesMedications(medicationList, therapyList)
            ? linkTherapiesMedications(medicationList, therapyList).filter(
                (m: any) => m.active === MEDICATION_THERAPY_STATUS_ACTIVE,
            )
            : [],
    );
    useEffect(() => {
        setMedicationGroupsWithTherapies(
            sortMedications(linkTherapiesMedications(medicationGroups, therapyList))?.filter(
                (m: any) => m.active === MEDICATION_THERAPY_STATUS_ACTIVE,
            ) ?? [],
        );
        setMedicationsWithTherapies(
            linkTherapiesMedications(medicationList, therapyList)
                ? linkTherapiesMedications(medicationList, therapyList).filter(
                    (m: any) => m.active === MEDICATION_THERAPY_STATUS_ACTIVE,
                )
                : [],
        );
    }, [medicationGroups, therapyList]);

    useEffect(() => {
        if (verifiedMessage) alertMessage(verifiedMessage, true);
        else alertMessage('Not Verified');
    }, [verifiedMessage]);

    return (
        <MedicationList
          patient={patient}
          showMedicationList
          medicationList={medicationsWithTherapies}
          overwriteGroups={medicationGroupsWithTherapies}
          isLoading={false}
        />
    );
}

import React from 'react';
import { Controller } from 'react-hook-form';

import { renderTextField as RenderTextField } from 'components/form/field/redux-field';
import { useTypedStyles } from './controlled-text-area.styles';
import { IControlledFormField } from '../types';

interface IProps extends IControlledFormField {
  placeHolder?: string;
  percentWith?: number;
  maxLength?: number;
  showLength?: boolean;
  hideLabel?: boolean;
}

export const ControlledTextArea: React.FC<IProps> = (props: IProps): JSX.Element => {
  const [currentLength, setCurrentLength] = React.useState<number>(0);
  const { classes } = useTypedStyles();

  return (
    <div>
      <div>
        <Controller
          defaultValue=""
          control={props.control}
          name={props.name}
          rules={props.validations}
          render={(ctrlProps: any) => {
            if (props.showLength === true) {
              setCurrentLength(ctrlProps?.value?.length);
            }

            return (
              <RenderTextField
                className={classes.textArea}
                width={props.percentWith && `${props.percentWith}%`}
                hideLabel={props.hideLabel || false}
                label={props.label}
                disabled={props.disabled}
                input={{
                  value: ctrlProps?.field?.value,
                  onChange: ctrlProps?.field?.onChange,
                  onBlur: ctrlProps?.field?.onBlur,
                }}
                meta={props.inputMetaData || {}}
                placeholder={props.placeHolder}
                data-qa-id={props.qaId}
                multiline
                rows={4}
                rowsmax={9}
                inputProps={props.maxLength != null ? { maxLength: props.maxLength } : {}}
                InputProps={{ disableUnderline: true }}
              />
            );
          }}
        />
      </div>
      {props.showLength != null && props.maxLength != null && (
        <div className={classes.currentLengthLabelWrapper}>
          <span className={classes.currentLengthLabel}>
            {`${currentLength} of ${props.maxLength}`}
          </span>
        </div>
      )}
    </div>
  );
};

export const constantListLabelLookup = (list, value, nullStr) => {
  if (list && list.length > 0 && value) {
    const item = list.find(e => e.value === value);
    if (item) {
      return item.label;
    }
  }
  return nullStr;
};

export const isValidArray = array => array && Array.isArray(array) && array.length > 0;

export function insertIntoArray(array, value) {
  if (array.length === 0) {
    return [value];
  }
  const insertArr = array;

  if (value.id > array[0].id) {
    insertArr.unshift(value);
    return array; // quick check for inserting
  }

  const newArr = insertArr.filter(vals => vals.id !== value.id);

  let low = 0;
  let high = newArr.length;

  while (low < high) {
    const mid = (low + high) >>> 1; // eslint-disable-line
    if (newArr[mid].id < value.id) low = mid + 1;
    else high = mid;
  }
  const retArr = newArr;
  retArr.splice(low - 1, 0, value);
  return retArr;
}

export const isObject = obj => typeof obj === 'object' && obj !== null;

export const isNotEmptyObject = obj => Object.keys(obj).length;

export const isEqualStringIgnoreCase = (str1, str2) => {
  if (!str1 && !str2) return true;
  if (!str1 || !str2) return false;
  return str1.toUpperCase() === str2.toUpperCase();
};

export const financialsIsActive = item => !!item.valid || !!item.status;

/**
 *
 * @param {Array} arr is an array base on object with id and name props
 * @param {Object} mapping Contains an object with values such as id,
 * name, value and label.
 */
export const dropdownFormater = (arr, mapping = {}) => {
  const { id = 'id', name = 'name', value = 'value', label = 'label' } = mapping;
  return arr.map(a => ({
    [value]: a[id],
    [label]: a[name],
  }));
};

export const dropdownFormaterToFilterOpts = (arr, availableOptions, mapping = {}, excludeOptsMode = false) => {
  const { id = 'id', name = 'name', value = 'value', label = 'label', isDisabled = 'isDisabled' } = mapping;
  return arr.map(a => {
    const isDisabledValue = availableOptions.includes(a[id]) ? 0 : 1;
    return {
      [value]: a[id],
      [label]: a[name],
      [isDisabled]: excludeOptsMode ? !isDisabledValue : isDisabledValue,
    };
  });
};

const parseConstant = name => name.replace(/\W+/g, '_').toUpperCase();

/**
 * @param [{id : ${id}, name : ${name}]
 * @return { name : id }
 */
export const convertLookupToConstants = (arr, id = 'id', name = 'name') => {
  const constantObj = {};
  arr.forEach(a => {
    constantObj[parseConstant(a[name])] = a[id];
  });
  return constantObj;
};

export const isEmptyString = str => {
  if (str) {
    return !str.trim();
  }
  return true;
};

// eslint-disable-next-line
export const parseOtherDisplay = (incidentTypes, typeId, other) => {
  if (incidentTypes && typeof incidentTypes === 'object') {
    const type = incidentTypes.find(x => x.id === typeId);
    if (type) {
      return `${type.name} (${other})`;
    }
  }
  return 'Other';
};

/**
 * @param {Array} arr is an array base on value and label props
 * @param {Object} mapping Contains an object with values such as propValue, position and value
 * Is position is greater than arr.length or undefined, sets last position of arr by default.
 * propValue will value or label, if is undefined sets label by default
 * value will match to find the object to move in the arr
 */
export const changeItemPosition = (arr, mapping) => {
  const { value } = mapping;
  let { position, propValue } = mapping;
  if (!position || position > arr.length - 1) {
    position = arr.length - 1;
  }

  propValue = Object.keys(arr[0]).includes(propValue) ? propValue : 'label';
  const objIndex = arr.findIndex(item => item[propValue] === value);

  if (objIndex > -1) {
    const obj = arr[objIndex];
    // look for an existing item in that position
    const existingItem = arr[position];
    if (existingItem) {
      arr.splice(objIndex, 1, existingItem);
    } else {
      arr.splice(objIndex, 1);
    }
    arr[position] = obj;
  }

  return arr;
};

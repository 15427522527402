import paJson from 'config/task-types/pa.json';
import { REDUX_FORM } from 'constants/index';
import {
  getMedicalInsuranceArrForDropdown,
  getPbmInsuranceArrForDropdown,
  initialInsurancesForDropdown,
  mapToIdNameInsurance,
} from 'services/utils/financials-service';
import moment from 'moment';
import { staticProviders, getCommonProviders, getDateForProvider } from './common-provider';

export default (task, state) => {
  const medicalInsuranceArr = getMedicalInsuranceArrForDropdown(state);
  const pbmInsuranceArr = getPbmInsuranceArrForDropdown(state);
  return {
    ...staticProviders,
    ...getCommonProviders(task, state, paJson),
    json: paJson,
    initialValues: {
      // for dates in initial values, utc values must be used
      time_out_date: getDateForProvider(task.time_out_complete_date),
      not_required_date: task.not_required_date
        ? getDateForProvider(task.not_required_arborcomplete_date)
        : state.currentDateTime,
      not_required_complete_date: task.not_required_complete_date
        ? getDateForProvider(task.not_required_complete_date)
        : state.currentDateTime,
      canceled_complete_date: task.canceled_complete_date
        ? getDateForProvider(task.canceled_complete_date)
        : state.currentDateTime,
      submitted_dt: task.submitted_dt
        ? getDateForProvider(task.submitted_dt)
        : state.currentDateTime,
      [`submitted_dt${REDUX_FORM.ALIAS_FIELD}`]: task.submitted_dt
        ? getDateForProvider(task.submitted_dt)
        : state.currentDateTime,
      medical_insurances: initialInsurancesForDropdown(
        task.medical_insurances,
        medicalInsuranceArr,
      ),
      pbm_insurances: initialInsurancesForDropdown(task.pbm_insurances, pbmInsuranceArr),
    },
    medical_insurances: medicalInsuranceArr.map(
      mapToIdNameInsurance('id', 'plan_name', 'insurance_type'),
    ),
    pbm_insurances: pbmInsuranceArr.map(mapToIdNameInsurance('id', 'pbm_name', 'insurance_type')),
  };
};

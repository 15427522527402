import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { convertToArborDate } from 'models/time/arbor-date';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { appointmentReportIds } from 'constants/lists';
import { updateReportSidebar } from 'actions/action-report';
import { goToAnchor } from 'utils/react-scrollable-anchor';
import { getReportUrl } from 'helpers/router';
import { push } from 'connected-react-router';
import { getPatientPreferred } from 'services/utils/demographic-service';
import { styles } from '../side-bar-styles';

const ReportListRow = props => {
  const { reportRow, index, classes } = props;
  const reports = useSelector(state => state.reports);
  const { reportSidebar } = reports;
  const { selectedRow } = reportSidebar;
  const dispatch = useDispatch();
  const primaryAddr = getPatientPreferred(reportRow, 'addresses');
  const streetStr =
    primaryAddr && primaryAddr.line2
      ? `${primaryAddr.line1}, ${primaryAddr.line2}`
      : primaryAddr && primaryAddr.line1;
  const regionStr = primaryAddr && `${primaryAddr.city} ${primaryAddr.state} ${primaryAddr.zip}`;
  let tableClass;
  if (index === selectedRow) {
    tableClass = classNames(classes.tableRow, classes.tableRowSelected);
  } else {
    tableClass = classes.tableRow;
  }

  const selectPatient = () => {
    if (appointmentReportIds.includes(reportSidebar.reportId)) {
      setTimeout(
        () => goToAnchor(`APPOINTMENT_REFERRAL_${reportRow.task_schedule_outreach_id}`),
        100,
      );
    } else {
      setTimeout(() => goToAnchor(`THERAPY_${reportRow.therapy_id}`), 100);
    }
    dispatch(push(getReportUrl(reportSidebar.reportId, reportRow.patient_id, reportRow)));
    dispatch(updateReportSidebar({ ...reportSidebar, selectedRow: index }));
  };

  return (
    <TableRow
      hover
      key={index}
      id={`report_row_${index}`}
      onClick={() => selectPatient()}
      className={tableClass}
    >
      <TableCell className={classes.tableCell} component="th" scope="row">
        <div className={classes.tableMainContent}>
          <div className={classes.tablePatientName}>{reportRow.patient_name}</div>
          <div>{convertToArborDate(reportRow.dob, true).getUtcDate(true)}</div>
          {reportRow.source_patient_id}
        </div>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <div style={{ textAlign: 'right' }}>{streetStr}</div>
        <div style={{ textAlign: 'right' }}>{regionStr}</div>
      </TableCell>
    </TableRow>
  );
};

export default withStyles(styles, { withTheme: true })(ReportListRow);

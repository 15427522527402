import React, { useState } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import { SwipeableDrawer, Box, Button, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { ArchivedNoteIcon } from 'components/icons/icons';
import { updateDocumentBar } from '../../actions/action-uploaded-documents';
import { styles } from './document-bar-styles';
import UploadNewDocument from './upload-new-document';
import UploadedDocuments from './uploaded-documents';
import UpdateDocument from './update-document';

const DocumentBar = props => {
  const { classes, noSwipeableDrawer = false } = props;
  const dispatch = useDispatch();
  const isUploadedDocuments = useSelector(state => !!state.uploadedDocuments.documents);

  const [isShowingUploadedDocuments, setIsShowingUploadedDocuments] = useState(false);

  const { taskIdResourceIds, display, update } = useSelector(state => state.documentBar);

  const close = () => {
    dispatch(
      updateDocumentBar({
        display: false,
        taskIdResourceIds: [],
        tagTypeId: undefined,
        resourceId: undefined,
        drugName: undefined,
        tagTypeLabel: undefined,
        update: undefined,
      }),
    );
  };
  const handleToggleShowUploadedDocuments = () => {
    setIsShowingUploadedDocuments(!isShowingUploadedDocuments);
  };

  const toggleArchivedButton = label => (
    <div className={classes.documentBarButtonContainer}>
      <Button
        onClick={handleToggleShowUploadedDocuments}
        variant="outlined"
        className={classes.documentBarButton}
      >
        <ArchivedNoteIcon />
        <span className={classes.documentBarToggleButtonText}>{label}</span>
      </Button>
    </div>
  );

  const renderButtonShowDocuments = () =>
    isShowingUploadedDocuments ? (
      <>
        <UploadedDocuments
          isArchived={item => item.is_archived}
          taskIdResourceIds={taskIdResourceIds}
        />
        {toggleArchivedButton('Hide Archived Documents')}
      </>
    ) : (
      <>
        <UploadedDocuments
          isArchived={item => !item.is_archived}
          taskIdResourceIds={taskIdResourceIds}
        />
        {toggleArchivedButton('Show Archived Documents')}
      </>
    );

  const uploadDocumentSection = () =>
    update ? (
      <Box className={classes.drawer}>
        <UpdateDocument />
      </Box>
    ) : (
      <Box className={classes.drawer}>
        <UploadNewDocument />
        {isUploadedDocuments ? (
          renderButtonShowDocuments()
        ) : (
          <Typography className={classes.subTitleAttachments}>No Attachments yet</Typography>
        )}
      </Box>
    );

  return !noSwipeableDrawer ? (
    <SwipeableDrawer
      anchor="right"
      onClose={() => close()}
      open={!!display}
      onOpen={() => {}}
      PaperProps={{
        style: { width: 500 },
      }}
    >
      {uploadDocumentSection()}
    </SwipeableDrawer>
  ) : (
    uploadDocumentSection()
  );
};

export default compose(withStyles(styles))(DocumentBar);

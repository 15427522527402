import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import {
  Checkbox,
  Divider,
  Grid,
  Backdrop,
  InputAdornment,
  Popover,
  Typography,
  Button,
} from '@mui/material';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import DetailField from 'components/form/field/field';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import {
  UPLOAD_DOC_FILENAME_FIELD,
  UPLOAD_DOCUMENT_FORM,
  UPLOAD_DOC_FILENOTE_FIELD,
  UPLOAD_DOC_FILETYPE_FIELD,
  INSURANCE_RELATION_CODE_MAPPING,
  PBM_INSURANCE,
  ENSURE_PBM_INSURANCE_ADDRESSED,
} from 'constants/index';
import { resolveAuditDefinition } from 'services/utils/audit-service';
import { insuranceTypes, relationCodes, noteTagTypes } from 'constants/lists';
import { resolveAudit } from 'actions/action-audit';
import {
  editPbmInsurance,
  fetchHasPrimaryPbmInsurance,
  fetchPbmInsurances,
} from 'actions/action-financials';
import {
  validateDate,
  validatePhone,
  required,
  validateNumeric,
  length6,
  maxLength20,
  validateSinglePrimaryInsurance,
} from 'components/form/validation/validation';
import { StringUtils } from 'utils/string-utils';
import SubHeader from 'components/form/header/subheader';
import moment from 'moment';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { SmallSpacer } from 'components/spacer/spacer';
import { convertToArborDate } from 'models/time/arbor-date';
import { getFileExtension } from 'services/utils/document-service';
import { addNewDocument } from 'actions/action-uploaded-documents';
import { getDocuments, uploadFile } from 'services/utils/upload-document-service';
import { PatientOverviewCopy } from 'components/icons/icons';
import { buildQaId } from 'utils/build-qa-id';
import { findInsuranceTypeName } from 'services/utils/financials-service';
import { isEmpty, debounce } from 'lodash';
import { convertMapToList } from 'reducers/helper-reducer';
import { styles } from '../financials-styles';
import DocumentConfirmationDialog from '../document-confirmation-dialog';
import {
  renderTextField,
  renderPhoneTextField,
  renderDropdown,
  renderCheckboxHorizontal,
} from '../../../../components/form/field/redux-field';
import WrappedFileUpload from '../wrapped-file-upload';
import { isValidInsuranceToResolveAudit } from '../utils';

const taskTypeName = PBM_INSURANCE;
const tagType = noteTagTypes.find(tag => tag.label === taskTypeName);
const qaIdBuilder = buildQaId('edit-pbm-insurance', '.');

class EditPbmInsurance extends Component {
  constructor(props) {
    super(props);
    const { pbmInsurance } = this.props;
    this.state = {
      noInsurance: Boolean(pbmInsurance.is_no_insurance),
      requiredAndSinglePrimary: null,
      // upload file props
      pendingDocument: undefined,
      uploadedDocuments: [],
      uploadProcessing: false,
      pendingDocumentLoss: false,
      lostData: [],
      dialogCancel: undefined,
      dialogContinue: undefined,
      noGroupNumberCheckbox: false,
      open: false,
      popoverAnchorEl: null,
      noPcn: isEmpty(pbmInsurance.pbm_processor_control_number),
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setNoInsurance = this.setNoInsurance.bind(this);
    this.handleGroupNumberCheckbox = this.handleGroupNumberCheckbox.bind(this);
    // Upload file
    this.handleFileUploadCancel = this.handleFileUploadCancel.bind(this);
    this.handleFileUploadSubmit = this.handleFileUploadSubmit.bind(this);
    this.fileSelected = this.fileSelected.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleInsurancePopover = this.handleInsurancePopover.bind(this);
    this.handleCloseInsurancePopover = this.handleCloseInsurancePopover.bind(this);
    this.handleInsuranceButtonPopover = this.handleInsuranceButtonPopover.bind(this);
    this.renderMedicalInsurancesPopover = this.renderMedicalInsurancesPopover.bind(this);
    this.handleNoPcn = this.handleNoPcn.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { fileFormName, pbmInsurances } = this.props;
    const { endTime, requiredAndSinglePrimary } = this.state;
    const primaryInsurance = this.getPrimary(pbmInsurances);

    if (this.hasFileNameChanged(prevState, fileFormName)) {
      this.onPendingDocumentFileNameChange(fileFormName);
    }

    if (this.validatePrimaryInsuranceOrEndTime(primaryInsurance, prevProps, endTime, prevState)) {
      this.setRequiredAndSinglePrimaryValidation(primaryInsurance, endTime);
    }
  }

  // eslint-disable-next-line react/sort-comp
  hasFileNameChanged(prevState, fileFormName) {
    return prevState.pendingDocument && prevState.pendingDocument.fileName !== fileFormName;
  }

  onPendingDocumentFileNameChange(newFileName) {
    this.setState(prevState => ({
      pendingDocument: {
        ...prevState.pendingDocument,
        ...{ fileName: newFileName },
      },
    }));
  }

  getPrimary(pbmInsurancesArr) {
    const { pbmInsurances } = this.props;
    const pInsurances = pbmInsurancesArr || pbmInsurances;
    return pInsurances.find(insurance => {
      const result =
        !insurance.is_no_insurance &&
        insurance.insurance_type === 'Primary' &&
        (insurance.end_date === null || convertToArborDate(insurance.end_date).isAfterNow());
      return result;
    });
  }

  // eslint-disable-next-line react/sort-comp
  validatePrimaryInsuranceOrEndTime(primaryInsurance, prevProps, endTime, prevState) {
    const beforePrimaryInsurance = this.getPrimary(prevProps.pbmInsurances);
    const hasPrimaryInsuranceChanged = primaryInsurance && !beforePrimaryInsurance;
    const isNewEndTimeUndefined = endTime && !prevState.endTime;
    const hasEndTimeLengthChanged =
      endTime &&
      prevState.endTime &&
      Object.keys(endTime).length !== Object.keys(prevState.endTime).length;

    return hasPrimaryInsuranceChanged || isNewEndTimeUndefined || hasEndTimeLengthChanged;
  }

  setRequiredAndSinglePrimaryValidation(primary, endTime) {
    this.setState({
      requiredAndSinglePrimary: [required, validateSinglePrimaryInsurance(primary, endTime)],
    });
  }

  setNoInsurance() {
    this.setState(prevState => ({
      noInsurance: !prevState.noInsurance,
    }));
  }

  handleInsurancePopover = event => {
    this.setState({
      open: true,
      popoverAnchorEl: event.currentTarget,
    });
  };

  handleCloseInsurancePopover = () => {
    this.setState({
      open: false,
      popoverAnchorEl: null,
    });
  };

  finalizeSubmit(values) {
    const {
      editPbmInsurance, // eslint-disable-line
      fetchPbmInsurances, // eslint-disable-line
      cancel,
      existingDocuments,
      addNewDocument, // eslint-disable-line
      otherSuccessFunction,
      fetchHasPrimaryPbmInsurance, // eslint-disable-line
      resolveAudit, // eslint-disable-line
      patient,
      auditState,
    } = this.props;

    const { uploadedDocuments, noGroupNumberCheckbox } = this.state;

    const payload = {
      ...values,
      patient_id: values.patient_id,
      insurance_type_id: values.insurance_type_id,
      pbm_name: values.pbm_name,
      no_insurance: values.no_insurance,
      pbm_phone_number: values.pbm_phone_number,
      pbm_patient_id: values.pbm_patient_id,
      pbm_bin_number: values.pbm_bin_number,
      pbm_rx_group_number: noGroupNumberCheckbox ? null : values.pbm_rx_group_number,
      insurance_type_name: values.insurance_type_name,
      pbm_processor_control_number: values.pbm_processor_control_number,
      start_date: convertToArborDate(values.start_date).getUtcDate(),
      end_date: convertToArborDate(values.end_date).getUtcDate(),
      lis_effective_date: convertToArborDate(values.lis_effective_date).getUtcDate(),
      lis_effective_date_mbi: convertToArborDate(values.lis_effective_date_mbi).getUtcDate(),
      lis_termination_date: convertToArborDate(values.lis_termination_date).getUtcDate(),
      lis_date_of_service: convertToArborDate(values.lis_date_of_service).getUtcDate(),
      lis_next_medicare_part_d_termination: convertToArborDate(
        values.lis_next_medicare_part_d_termination,
      ).getUtcDate(),
      lis_next_medicare_part_d_effective_start: convertToArborDate(
        values.lis_next_medicare_part_d_effective_start,
      ).getUtcDate(),
      is_verified: 1, // Consider verified if edited in UI
      verified_dt: convertToArborDate(new Date()).getUtcDatetime(),
      documents: uploadedDocuments.map(doc => ({
        uuid: doc.uuid,
        file_name: doc.filename,
        labels: doc.labels,
        tags: doc.taskIds.map(id => ({
          tag_type_id: tagType.value,
          resource_id: id,
        })),
        noteText: doc.note,
      })),
    };

    return editPbmInsurance(payload).then(() => {
      fetchPbmInsurances(values.patient_id).then(() => {
        fetchHasPrimaryPbmInsurance(values.patient_id);
        cancel(payload.is_verified);
      });

      if (uploadedDocuments.length) {
        getDocuments({
          patient_id: values.patient_id,
          fetchFromId: Math.max(...existingDocuments.map(d => d.id)),
        }).then(result => {
          addNewDocument(result.data.documents);
        });
      }

      if (isValidInsuranceToResolveAudit(payload, insuranceTypes)) {
        resolveAuditDefinition({
          auditDefinitionType: ENSURE_PBM_INSURANCE_ADDRESSED,
          auditState,
          patient,
          resolverFunction: resolveAudit,
        });
      }

      if (otherSuccessFunction) {
        otherSuccessFunction();
      }
    });
  }

  // eslint-disable-next-line react/sort-comp
  handleGroupNumberCheckbox() {
    this.setState(prevState => ({
      noGroupNumberCheckbox: !prevState.noGroupNumberCheckbox,
    }));
  }

  // Wrapped File Upload
  handleFileUploadCancel() {
    this.setState({ pendingDocument: undefined });
  }

  handleFileUploadSubmit() {
    this.setState({ uploadProcessing: true });
    const { pendingDocument } = this.state;
    const {
      selectedCustomerId,
      selectedPatientId,
      fileFormName,
      fileFormTypes,
      fileFormNote,
      pbmInsurance,
    } = this.props;

    if (pendingDocument) {
      uploadFile(
        pendingDocument.file,
        `${pendingDocument.fileName}.${pendingDocument.fileExtension}`,
        selectedCustomerId,
        selectedPatientId,
      )
        .then(result => {
          this.setState(prevState => ({
            uploadedDocuments: [
              ...prevState.uploadedDocuments,
              {
                uuid: result.uuid,
                taskTypeId: tagType.value,
                taskIds: [pbmInsurance.id],
                filename: `${fileFormName}.${pendingDocument.fileExtension}`,
                labels: fileFormTypes.split(','),
                note: fileFormNote,
              },
            ],
            pendingDocument: undefined,
            uploadProcessing: false,
          }));
        })
        .catch(() => {
          this.setState({
            fileUploadErrorMessage: 'File could not be uploaded. Try again.',
          });
        });
    }
  }

  handleSubmit(values) {
    const { pendingDocument } = this.state;
    if (pendingDocument) {
      const lostData = [];

      if (pendingDocument) {
        lostData.push(`Pending: ${pendingDocument.fileName}.${pendingDocument.fileExtension}`);
      }

      this.setState({
        pendingDocumentLoss: true,
        lostData,
        dialogCancel: () => {
          this.setState({ pendingDocumentLoss: false });
        },
        dialogContinue: () => {
          this.finalizeSubmit(values);
        },
      });
    } else {
      this.finalizeSubmit(values);
    }
  }

  handleCancel() {
    const { uploadedDocuments, pendingDocument } = this.state;
    if ((uploadedDocuments && uploadedDocuments.length > 0) || pendingDocument) {
      const lostData = uploadedDocuments.map(ud => `Uploaded: ${ud.filename}`) || [];

      if (pendingDocument) {
        lostData.push(`Pending: ${pendingDocument.fileName}.${pendingDocument.fileExtension}`);
      }

      this.setState({
        pendingDocumentLoss: true,
        lostData,
        dialogCancel: () => {
          this.setState({ pendingDocumentLoss: false });
        },
        dialogContinue: () => {
          this.finalizeCancel();
        },
      });
    } else {
      this.finalizeCancel();
    }
  }

  finalizeCancel() {
    const { cancel } = this.props;
    cancel();
  }

  fileSelected(event) {
    const { changeField, fileUploadFormId } = this.props;
    const selectedFile = event[0];
    const filePrefixExtension = getFileExtension(selectedFile.name);
    changeField(fileUploadFormId, UPLOAD_DOC_FILENAME_FIELD, filePrefixExtension.filePrefix);
    this.setState({
      pendingDocument: {
        fileName: filePrefixExtension.filePrefix,
        fileExtension: filePrefixExtension.fileExtension,
        file: selectedFile,
      },
    });
  }
  // - - - - - - - - - - -

  // eslint-disable-next-line no-unused-vars
  handleInsuranceButtonPopover(medicalInsurance) {
    // eslint-disable-next-line no-shadow
    const { change, touch } = this.props;
    const medicalRelationCode = medicalInsurance.policy_holder_relationship_id;
    const mappedPbmRelationCode = INSURANCE_RELATION_CODE_MAPPING[medicalRelationCode];
    const newRelationCode = mappedPbmRelationCode || '4';

    touch('pbm_name');
    touch('pbm_phone_number');
    touch('pbm_patient_id');
    touch('pbm_rx_group_number');
    touch('pbm_relation_code');

    // This part changes the fields
    change('pbm_name', medicalInsurance.plan_name);
    change('pbm_phone_number', medicalInsurance.plan_phone_number);
    change('pbm_patient_id', medicalInsurance.member_id);
    change('pbm_rx_group_number', medicalInsurance.group_number);
    change('pbm_relation_code', newRelationCode);

    this.setState({
      open: false,
      popoverAnchorEl: null,
    });
  }

  // eslint-disable-next-line class-methods-use-this, react/sort-comp
  renderMedicalInsurancesPopover() {
    const { financials } = this.props;
    const medicalInsurances = Object.values(financials.medical_insurances.data || {});
    return medicalInsurances.map(medInsurance => (
      <>
        {medInsurance ? (
          <div>
            <Button
              onClick={() => this.handleInsuranceButtonPopover(medInsurance)}
              data-qa-id={qaIdBuilder('popup-button')}
            >
              {medInsurance.plan_name}
            </Button>
          </div>
        ) : null}
      </>
    ));
  }

  handleInsuranceTypeNameSearch = () => {
    const { noGroupNumberCheckbox, noPcn } = this.state;
    const { form, binFormVal: bin, pcnFormVal: pcn, groupFormVal: group, changeField } = this.props;
    if (noGroupNumberCheckbox || noPcn || !bin || !pcn || !group) return;

    findInsuranceTypeName({ bin, pcn, group }).then(response => {
      const { insurance_type_name } = response.data;
      if (insurance_type_name) {
        changeField(form, 'insurance_type_name', insurance_type_name);
      }
    });
  };

  // eslint-disable-next-line react/sort-comp
  debouncedInsuranceTypeNameSearch = debounce(this.handleInsuranceTypeNameSearch, 500);

  handleNoPcn() {
    this.setState(prevState => ({
      noPcn: !prevState.noPcn,
    }));
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      classes,
      lookups,
      fileUploadFormId,
      defaultDocumentLabels,
      pristineOverride,
      financials,
    } = this.props;
    const medicalInsurances = Object.values(financials.medical_insurances.data || {});

    const { noInsurance, noGroupNumberCheckbox, open, popoverAnchorEl, requiredAndSinglePrimary } =
      this.state;

    const {
      pendingDocument,
      fileUploadErrorMessage,
      uploadProcessing,
      uploadedDocuments,
      pendingDocumentLoss,
      lostData,
      dialogCancel,
      dialogContinue,
      noPcn,
    } = this.state;

    const uploadProps = {
      pendingDocument,
      fileUploadErrorMessage,
      uploadProcessing,
      uploadedDocuments,
      pendingDocumentLoss,
      classes,
      fileUploadFormId,
      defaultDocumentLabels,
      handleFileUploadSubmit: this.handleFileUploadSubmit,
      handleFileUploadCancel: this.handleFileUploadCancel,
      fileSelected: this.fileSelected,
    };

    const documentConfirmationDialogProps = {
      lostData,
      dialogCancel,
      dialogContinue,
      pendingDocumentLoss,
    };

    const disableSubmit = pristineOverride
      ? submitting
      : submitting || (pristine && !pendingDocument && !uploadedDocuments.length);

    return (
      <Grid
        container
        justifyContent="center"
        spacing={7}
        className={(classes.formRowContainer, classes.formContainer)}
      >
        <Grid item xs={12}>
          <DocumentConfirmationDialog {...documentConfirmationDialogProps} />
          <form onSubmit={handleSubmit(this.handleSubmit)} autoComplete="off">
            <Grid container alignItems="flex-end">
              <Grid item xs={12}>
                <Field
                  name="no_insurance"
                  label="No Insurance"
                  component={renderCheckboxHorizontal}
                  onChange={this.setNoInsurance}
                />
              </Grid>
            </Grid>
            <div className={classes.noInsuranceBackdropWrapper}>
              <Backdrop open={noInsurance} className={classes.noInsuranceBackdrop} />
              <Grid container alignItems="flex-end">
                <Grid item xs={3}>
                  <Field
                    name="insurance_type_id"
                    label="Type *"
                    component={renderDropdown}
                    fields={lookups.medicalInsuranceTypes
                      .map(type => ({
                        label: type.insurance_type,
                        value: type.id,
                      }))
                      .reverse()}
                    validate={noInsurance ? null : requiredAndSinglePrimary}
                    disabled={noInsurance}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="pbm_name"
                    label="PBM Name *"
                    component={renderTextField}
                    validate={noInsurance ? null : [required]}
                    disabled={noInsurance}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          {medicalInsurances.length ? (
                            <Button
                              onClick={this.handleInsurancePopover}
                              className={classes.insurancePopoverButton}
                              data-qa-id={qaIdBuilder('button')}
                            >
                              <PatientOverviewCopy />
                            </Button>
                          ) : null}
                          <Popover
                            open={open}
                            anchorEl={popoverAnchorEl}
                            onClose={this.handleCloseInsurancePopover}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                          >
                            <div className={classes.insurancePopoverBox}>
                              <Typography className={classes.insurancePopoverBoxText}>
                                List of insurances to copy:
                              </Typography>
                              {this.renderMedicalInsurancesPopover()}
                            </div>
                          </Popover>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="start_date"
                    label="Start Date"
                    component={renderDatePicker}
                    validate={[validateDate]}
                    disabled={noInsurance}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="end_date"
                    label="End Date"
                    component={renderDatePicker}
                    validate={[validateDate]}
                    disabled={noInsurance}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="flex-end">
                <Grid item xs={3}>
                  <Field
                    name="pbm_phone_number"
                    label="Phone Number"
                    component={renderPhoneTextField}
                    validate={[validatePhone]}
                    disabled={noInsurance}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="pbm_bin_number"
                    label="Bin Number *"
                    component={renderTextField}
                    validate={
                      noInsurance
                        ? [validateNumeric, length6]
                        : [required, validateNumeric, length6]
                    }
                    disabled={noInsurance}
                    onChange={this.debouncedInsuranceTypeNameSearch}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="pbm_relation_code"
                    label="Relation Code"
                    component={renderDropdown}
                    fields={relationCodes}
                    disabled={noInsurance}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="flex-end">
                <Grid item xs={3}>
                  <Field
                    name="pbm_processor_control_number"
                    label="Processor Control Number *"
                    component={renderTextField}
                    normalize={StringUtils.toUpper}
                    validate={noInsurance || noPcn ? [maxLength20] : [required, maxLength20]}
                    disabled={noInsurance || noPcn}
                    onChange={this.debouncedInsuranceTypeNameSearch}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="pbm_patient_id"
                    label="Patient ID *"
                    component={renderTextField}
                    normalize={StringUtils.toUpper}
                    validate={noInsurance ? null : [required]}
                    disabled={noInsurance}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Checkbox
                    checked={noPcn}
                    color="primary"
                    onChange={this.handleNoPcn}
                    value="noPcn"
                    label="No PCN"
                  />
                  <DetailField defaultValue="No PCN" />
                </Grid>
                <Grid container alignItems="flex-end">
                  <Grid item xs={3}>
                    <Field
                      name="pbm_rx_group_number"
                      label={!noGroupNumberCheckbox ? 'Rx Group Number *' : 'Rx Group Number'}
                      validate={noInsurance || noGroupNumberCheckbox ? null : [required]}
                      component={renderTextField}
                      normalize={StringUtils.toUpper}
                      disabled={noInsurance || noGroupNumberCheckbox}
                      onChange={this.debouncedInsuranceTypeNameSearch}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container alignItems="center" justifyContent="flex-start">
                      <Checkbox
                        checked={noGroupNumberCheckbox}
                        color="primary"
                        onChange={this.handleGroupNumberCheckbox}
                        value="checkedGroupNumber"
                        label="Secondary"
                      />
                      <DetailField defaultValue="No Rx Group Number" />
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="insurance_type_name"
                      label="Insurance Type"
                      component={renderTextField}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Grid container>
              <Grid item xs={12}>
                <SubHeader name="Low Income Subsidy" />
                <SmallSpacer />
              </Grid>
              <Grid item xs={3}>
                <Field name="lis_level" label="LIS Level" component={renderTextField} />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_effective_date"
                  label="LIS Effective Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_termination_date"
                  label="LIS Termination Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_medicare_plan_type"
                  label="Medicare Plan Type"
                  component={renderTextField}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_medicare_beneficiary_id"
                  label="Medicare Beneficiary Identifier"
                  component={renderTextField}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_effective_date_mbi"
                  label="Effective Date for MBI"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3}>
                <Field name="lis_benefit_id" label="Benefit Id" component={renderTextField} />
              </Grid>
              <Grid item xs={3}>
                <Field name="lis_formulary_id" label="Formulary Id" component={renderTextField} />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_date_of_service"
                  label="Date of Service"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_contract_number"
                  label="Contract Number"
                  component={renderTextField}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_medicare_part_d_code"
                  label="Medicare Part D Code"
                  component={renderTextField}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_other_payor_id_count"
                  label="Other Payor Id Count"
                  component={renderTextField}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_cms_low_income_cost_sharing"
                  label="Cms Low Income Cost Sharing"
                  component={renderTextField}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_transaction_response_status"
                  label="Transaction Response Status"
                  component={renderTextField}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_next_medicare_part_d_termination"
                  label="Next Medicare Part D Termination"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_next_medicare_part_d_effective_start"
                  label="Next Medicare Part D Effective Start"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
            </Grid>
            <Divider light />
            <WrappedFileUpload {...uploadProps} />
            <SmallSpacer />
            <ConfirmationPanel handleCancel={this.handleCancel} disableSubmit={disableSubmit} />
          </form>
        </Grid>
      </Grid>
    );
  }
}

function handleFormName(taskType, id) {
  const fileFormId = `${UPLOAD_DOCUMENT_FORM}-${taskType}-${id}`;
  return fileFormId;
}

function mapStateToProps(state, props) {
  const { patient, lookups, uploadedDocuments, audit, financials } = state;
  const { pbmInsurance, form } = props;

  const fileUploadFormId = handleFormName(tagType.value, pbmInsurance.id);
  const selector = formValueSelector(fileUploadFormId);
  const fileFormName = selector(state, UPLOAD_DOC_FILENAME_FIELD);
  const fileFormTypes = selector(state, UPLOAD_DOC_FILETYPE_FIELD);
  const fileFormNote = selector(state, UPLOAD_DOC_FILENOTE_FIELD);

  const formSelector = formValueSelector(form);
  const binFormVal = formSelector(state, 'pbm_bin_number');
  const pcnFormVal = formSelector(state, 'pbm_processor_control_number');
  const groupFormVal = formSelector(state, 'pbm_rx_group_number');

  return {
    patient,
    auditState: audit,
    lookups,
    financials,
    pbmInsurances: convertMapToList(state.financials.pbm_insurances.data),
    insuranceTypes: state.lookups.insuranceTypes,
    hasPrimary: state.financials.has_primary_pbm_insurance,
    enableReinitialize: true,
    initialValues: {
      id: pbmInsurance.id,
      insurance_type_id: pbmInsurance.insurance_type_id,
      patient_id: pbmInsurance.patient_id,
      pbm_name: pbmInsurance.pbm_name,
      no_insurance: Boolean(pbmInsurance.is_no_insurance),
      pbm_phone_number: pbmInsurance.pbm_phone_number,
      pbm_patient_id: pbmInsurance.pbm_patient_id,
      pbm_bin_number: pbmInsurance.pbm_bin_number,
      pbm_relation_code: pbmInsurance.pbm_relation_code,
      pbm_rx_group_number: pbmInsurance.pbm_rx_group_number,
      insurance_type_name: pbmInsurance.insurance_type_name,
      pbm_processor_control_number: pbmInsurance.pbm_processor_control_number,
      start_date: pbmInsurance.start_date ? moment.utc(pbmInsurance.start_date) : null,
      end_date: pbmInsurance.end_date ? moment.utc(pbmInsurance.end_date) : null,
      is_verified: pbmInsurance.is_verified,
      verified_dt: pbmInsurance.verified_dt // eslint-disable-line
        ? convertToArborDate(pbmInsurance.verified_dt, true).getCustomerDatetime()
        : null,
      verified_by: pbmInsurance.is_verified ? 1 : null,

      // LIS fields
      lis_benefit_id: pbmInsurance.lis_benefit_id || null,
      lis_cms_low_income_cost_sharing: pbmInsurance.lis_cms_low_income_cost_sharing || null,
      lis_contract_number: pbmInsurance.lis_contract_number || null,
      lis_date_of_service: pbmInsurance.lis_date_of_service
        ? moment.utc(pbmInsurance.lis_date_of_service)
        : null,
      lis_effective_date: pbmInsurance.lis_effective_date
        ? moment.utc(pbmInsurance.lis_effective_date)
        : null,
      lis_effective_date_mbi: pbmInsurance.lis_effective_date_mbi
        ? moment.utc(pbmInsurance.lis_effective_date_mbi)
        : null,
      lis_formulary_id: pbmInsurance.lis_formulary_id || null,
      lis_level: pbmInsurance.lis_level || null,
      lis_medicare_beneficiary_id: pbmInsurance.lis_medicare_beneficiary_id || null,
      lis_medicare_part_d_code: pbmInsurance.lis_medicare_part_d_code || null,
      lis_medicare_plan_type: pbmInsurance.lis_medicare_plan_type || null,
      // eslint-disable-next-line max-len
      lis_next_medicare_part_d_effective_start:
        pbmInsurance.lis_next_medicare_part_d_effective_start
          ? moment.utc(pbmInsurance.lis_next_medicare_part_d_effective_start)
          : null,
      lis_next_medicare_part_d_termination: pbmInsurance.lis_next_medicare_part_d_termination
        ? moment.utc(pbmInsurance.lis_next_medicare_part_d_termination)
        : null,
      lis_other_payor_id_count: pbmInsurance.lis_other_payor_id_count || null,
      lis_termination_date: pbmInsurance.lis_termination_date
        ? moment.utc(pbmInsurance.lis_termination_date)
        : null,
      lis_transaction_response_status: pbmInsurance.lis_transaction_response_status || null,
    },
    defaultDocumentLabels: state.lookups.defaultDocumentLabels,
    fileUploadFormId,
    fileFormName,
    fileFormTypes,
    fileFormNote,
    selectedCustomerId: state.filters.selectedCustomerId,
    selectedPatientId: patient.id,
    existingDocuments: uploadedDocuments.documents,
    binFormVal,
    pcnFormVal,
    groupFormVal,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      editPbmInsurance,
      fetchHasPrimaryPbmInsurance,
      fetchPbmInsurances,
      changeField: (formName, fieldName, value) => dispatch(change(formName, fieldName, value)),
      addNewDocument,
      resolveAudit,
    },
    dispatch,
  );
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(reduxForm({})(EditPbmInsurance));

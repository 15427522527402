import { createAction } from '@reduxjs/toolkit';
import { IMedSyncRowData } from 'models/meds/IMedSync';
import { IMedSyncFormFields } from './common';

export const MED_SYNC_UPDATE_SUCCESS = 'MED_SYNC_UPDATE_SUCCESS';
export interface MedSyncUpdateSuccessPayload {
  formValues: IMedSyncFormFields;
  medSyncData: IMedSyncRowData[];
}
export const medSyncUpdateSuccess =
  createAction<MedSyncUpdateSuccessPayload>(MED_SYNC_UPDATE_SUCCESS);

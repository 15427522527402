import React from 'react';
import moment from 'moment';
import { Grid, Box, Typography } from '@mui/material';
import RadioButton from 'components/form/radio/radio-button';
import RadioGroup from 'components/form/radio/radio-group';
import { MAIN, ADJUSTMENT } from 'components/invoicing/constants';
import InvoicingSummary from './invoicing-summary';
import InvoicingTable from './invoicing-table';

const SUMMARY_PAGE = 0;
const VALIDATION_PAGE = 1;
const ADJUSTMENTS_PAGE = 2;

const Invoicing = () => {
  const [page, setPage] = React.useState(SUMMARY_PAGE);

  const lastPeriod = moment().subtract(1, 'month');
  const period = `${lastPeriod.format('MMMM')} ${lastPeriod.year()}`;

  return (
    <>
      <Box pt={2} />
      <Grid container>
        <Grid item xs={3}>
          <Typography variant="h6">
            <strong>{period}</strong>
          </Typography>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs>
          <RadioGroup
            value={page}
            horizontal
            onChange={value => {
              setPage(value);
            }}
          >
            <RadioButton value={SUMMARY_PAGE}>Consolidation</RadioButton>
            <RadioButton value={VALIDATION_PAGE}>Validation</RadioButton>
            <RadioButton value={ADJUSTMENTS_PAGE}>Adjustments</RadioButton>
          </RadioGroup>
        </Grid>
      </Grid>
      {page === SUMMARY_PAGE && <InvoicingSummary />}
      {page === VALIDATION_PAGE && <InvoicingTable currentPage={MAIN} />}
      {page === ADJUSTMENTS_PAGE && <InvoicingTable currentPage={ADJUSTMENT} />}
    </>
  );
};

export default Invoicing;

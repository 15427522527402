import ReactGA from 'react-ga';
import { GA_TRACKING_ID } from '../../constants/index';

export const initGA = userId =>
  ReactGA.initialize(GA_TRACKING_ID, {
    gaOptions: { userId, cookieDomain: 'none' },
  });

export const initPatientGA = patientId =>
  ReactGA.initialize(GA_TRACKING_ID, {
    gaOptions: { patientId, cookieDomain: 'none' },
  });

export const logPageView = () =>
  ReactGA.pageview(window.location.pathname + window.location.search);

export const logEvent = (category = '', action = '', label = '') =>
  ReactGA.event({ category, action, label });

export const logException = (description = '', fatal = false) =>
  ReactGA.exception({ description, fatal });

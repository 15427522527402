import React from 'react';
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'recompose';
import { IWeatherView, IWeatherItemView } from '../../../interfaces/weather/IWeatherView';
import { styles } from './weather-styles';
import { GetWeatherIcon } from '../../../utils/weather-icon-util';

const RenderWeatherIcon: React.FC<{ icon: string }> = (props: { icon: string }): JSX.Element => {
  const weatherIcon = GetWeatherIcon(props.icon);
  return <img src={weatherIcon} alt={props.icon} />;
};

const WeatherItem = ({ weekDate, date, temp, icon }: IWeatherItemView) => {
  return (
    <Grid container>
      <Grid item xs={2}>
        <RenderWeatherIcon icon={icon} />
      </Grid>
      <Grid item xs={10}>
        <div>
          <Typography variant="caption">{weekDate}</Typography>
        </div>
        <div>
          <Typography variant="caption">{date}</Typography>
        </div>
        <Typography variant="caption" dangerouslySetInnerHTML={{ __html: temp }} />
      </Grid>
    </Grid>
  );
};

const Weather: React.FC<any> = props => {
  const weatherData = props.input.value as IWeatherView;
  const { classes } = props;
  return (
    <Grid container className={classes.outerContainer} data-qa-id="weather-container">
      {!!(weatherData.forecast && weatherData.forecast.length) &&
        weatherData.forecast.map(day => {
          return (
            <Grid item xs key={day.date}>
              <WeatherItem {...day} />
            </Grid>
          );
        })}
      {(!weatherData.forecast || weatherData.forecast.length === 0) && (
        <Typography variant="h4" className={classes.noWeatherLabel}>
          Weather data is not available
        </Typography>
      )}
    </Grid>
  );
};

export default compose<any, {}>(withStyles(styles as any, { withTheme: true }))(Weather);

import { ITask } from '../../../interfaces/redux/ITasks';
import { ITaskStatusProcessor } from '../interfaces/TaskStatusProcessor';

// this exists so that there's no need to check if the service returns a factory
class EmptyProcessor implements ITaskStatusProcessor {
  groupTasksByStatus(tasks: ITask[]): Record<string, ITask[]> {
    return {};
  }

  taskIsEditable(task: ITask): boolean {
    return true;
  }

  taskShouldRenderCustomForm(task: ITask): boolean {
    return false;
  }

  taskShouldRenderCustomMultipleForms(tasks: ITask[]) {
    return false;
  }

  taskTransitioningToComplete(prevTask: ITask, currentTask: ITask): boolean {
    return false;
  }

  taskIsReadOnly(task: ITask): boolean {
    return false;
  }

  taskIsComplete(task: ITask): boolean {
    return false;
  }

  taskCanTransition(task: ITask): boolean {
    return false;
  }
}

export { EmptyProcessor };

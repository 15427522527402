import { Reducer } from 'redux';
import { TaskListSortAction } from 'actions/action-tasklist-sort';
import {
  TOGGLE_TASKLIST_SORT,
  TOGGLE_PER_PROTOCOL_FILTER,
  SET_TASKLIST_SORT,
} from '../constants/index';

const initialState: ITaskListSortState = {
  order: 'asc',
  orderBy: 'followup_dt',
};

interface ITaskListSortState {
  order: 'asc' | 'desc';
  orderBy: string | null;
}

const taskListSortReducer: Reducer<ITaskListSortState, TaskListSortAction> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_TASKLIST_SORT: {
      if (!payload) {
        return { ...state };
      }

      return { ...state, ...(payload as ITaskListSortState) };
    }
    case TOGGLE_TASKLIST_SORT: {
      if (payload === null) {
        return { ...state, orderBy: null };
      }
      if (payload === state.orderBy) {
        let newOrder = 'asc';
        let newOrderBy = state.orderBy;
        if (state.order === 'asc') {
          newOrder = 'desc';
        }
        if (state.order === 'desc') {
          newOrderBy = null as any;
        }
        return {
          ...state,
          order: newOrder,
          orderBy: newOrderBy,
          perProtocol: false,
        } as ITaskListSortState;
      }
      return { ...state, order: 'asc', orderBy: payload } as ITaskListSortState;
    }
    case TOGGLE_PER_PROTOCOL_FILTER:
      let newOrderBy = null as any;
      if (payload === undefined && state.orderBy !== 'is_needsby_per_protocol') {
        newOrderBy = 'is_needsby_per_protocol';
      }
      return {
        ...state,
        order: 'asc',
        orderBy: newOrderBy,
      };
    default:
      return state;
  }
};

export default taskListSortReducer;

import { Moment } from 'moment';
import { dateFormat } from 'models/time/arbor-date';
import { AxiosPromise } from 'axios';
import HTTP from 'services/http';

export const getReportRequest = (startDate: Moment, endDate: Moment): AxiosPromise<any> => {
  const requestParams: Record<string, string> = {
    startDate: startDate.format(dateFormat),
    endDate: endDate.format(dateFormat),
  };

  const queryParams = new URLSearchParams(requestParams).toString();

  const url = `/application-manager/gtd/report/audit?${queryParams}`;

  return HTTP.get(url);
};

import React from 'react';
import { Divider, Grid, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/styles';
import { convertToArborDate } from 'models/time/arbor-date';
import { AddressDemographicInfo } from 'components/patient-merge-modal/info-component/address-demographic-info';
import { EmailDemographicInfo } from 'components/patient-merge-modal/info-component/email-demographic-info';
import { PhoneDemographicInfo } from 'components/patient-merge-modal/info-component/phone-demographic-info';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { IContactDemographicInfoProps } from 'components/patient-merge-modal/interfaces';
import { ContactInformationHeader } from './contact-information-header';

export const ContactDemographicInfo = (props: IContactDemographicInfoProps) => {
  const { contact, keyPrefix } = props;
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);
  return (
    <Grid container rowSpacing={3}>
      <ContactInformationHeader contact={contact} />
      <Grid item xs={12}>
        <Divider sx={classes.divider} light />
      </Grid>
      {contact.addresses?.map((address, idx) => (
        <AddressDemographicInfo
          key={`${keyPrefix}-${contact.contactId}-address-${idx}`}
          address={address}
        />
      ))}
      {!contact.addresses?.length && (
        <>
          <Grid item xs={12}>
            <Typography sx={classes.checkboxTitle}>Address</Typography>
          </Grid>
          <Grid item xs={12} sx={classes.textInfo}>
            -
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Divider sx={classes.divider} light />
      </Grid>
      {contact.emails?.map((email, idx) => (
        <EmailDemographicInfo
          key={`${keyPrefix}-${contact.contactId}-email-${idx}`}
          email={email}
        />
      ))}
      {!contact.emails?.length && (
        <>
          <Grid item xs={12}>
            <Typography sx={classes.checkboxTitle}>Email</Typography>
          </Grid>
          <Grid item xs={12} sx={classes.textInfo}>
            -
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Divider sx={classes.divider} light />
      </Grid>
      {contact.phones?.map((phone, idx) => (
        <PhoneDemographicInfo
          key={`${keyPrefix}-${contact.contactId}-phone-${idx}`}
          phone={phone}
        />
      ))}
      {!contact.phones?.length && (
        <>
          <Grid item xs={12}>
            <Typography sx={classes.checkboxTitle}>Phone</Typography>
          </Grid>
          <Grid item xs={12} sx={classes.textInfo}>
            -
          </Grid>
        </>
      )}
    </Grid>
  );
};

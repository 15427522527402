import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { Divider } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchScheduleOutreachByPatient } from 'actions/action-tasks';
import { Loader } from 'components/loading-overlay/loading-overlay';
import { toggleTabRefresh } from 'actions/action-patient';
import { styles } from './schedule-outreach-styles';
import ScheduleOutreachDetails from './schedule-outreach-details';

const ScheduleOutreach = props => {
  const { selectedPatientId, classes, scheduleOutreachList, selectedTaskId, onLoad } = props;
  const [isTaskLoaded, setIsTaskLoaded] = useState(false);

  useEffect(() => {
    setIsTaskLoaded(false);
    onLoad(selectedPatientId).then(() => {
      setIsTaskLoaded(true);
    });
    return () => {};
  }, [onLoad, selectedPatientId]);

  return (
    <div className={classes.loadContainer}>
      <Loader loaded={isTaskLoaded}>
        {scheduleOutreachList.map(scheduleOutreach => (
          <ScheduleOutreachDetails
            expanded={scheduleOutreach.id === selectedTaskId}
            key={scheduleOutreach.id}
            scheduleOutreach={scheduleOutreach}
          />
        ))}
        <Divider className={classes.divider} />
      </Loader>
    </div>
  );
};

const mapStateToProps = ({
  selectedTaskId,
  scheduleOutreachList,
  tabControl,
  selectedPatientId,
}) => ({
  selectedTaskId,
  scheduleOutreachList,
  tabControl,
  selectedPatientId,
});

const mapDispatchToProps = dispatch => ({
  onLoad: patientId => {
    const soListPromise = dispatch(fetchScheduleOutreachByPatient(patientId));
    const tabPromise = dispatch(toggleTabRefresh(false));
    return Promise.all([soListPromise, tabPromise]);
  },
});

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ScheduleOutreach);

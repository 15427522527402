const answers = {
  new_allergies: undefined,
  side_effects: undefined,
  changes_in_medication: undefined,
  patient_missed_doses: undefined,
  patient_questions: undefined,
  new_medical_conditions: undefined,
  hospital_visit: undefined,
  patient_requested_therapy_changes: null,
  patient_contact_required: null,
  new_infections: undefined,
  pickup_or_delivery: null,
  payment_method: null,
  delivery_method: null,
  preferred_shipping_address: null,
  signature_required: null,
  therapies: [],
};

export default answers;

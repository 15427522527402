export const isValidDate = (
  month: number,
  day: number,
  year: number,
  isMonthComplete: boolean,
  isDayComplete: boolean,
  isComplete: boolean,
) => {
  const yearStr = year.toString();

  if (year < 0 || year > 9999 || (yearStr.length > 1 && yearStr.startsWith('0'))) return false;

  if (isMonthComplete) {
    if (month < 1 || month > 12) return false;
  }

  if (isDayComplete) {
    if (day < 1 || day > 31) return false;
  }

  if (isComplete) {
    if (day === 0 || month === 0 || year === 0) return false;
  }

  return true;
};

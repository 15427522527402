import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Label from 'components/display/label';
import { CompleteIcon, MoneyIcon, PerProtocolIcon, RetryIcon } from 'components/icons/icons';
import { Sms } from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';
import { Grid, Paper, Popper, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { get } from 'lodash';
import { convertToArborDate } from 'models/time/arbor-date';
import { patientNeedsReconciliation } from 'containers/patient/reconciliation/common';
import { ClinicalDataClient } from 'clients/clinical-data';
import {
  AR,
  CATEGORY_ARCHIVED,
  CATEGORY_DONE,
  CSL,
  CUSTOMER_BRANCH_TTC_HIGH_DISPLAY_TEXT,
  CUSTOMER_BRANCH_TTC_LOW_DISPLAY_TEXT,
  CUSTOMER_BRANCH_TTC_MEDIUM_DISPLAY_TEXT,
  DC,
  DUR,
  FA,
  FA_FUNDING_AVAILABLE_STATUS_ID,
  FC,
  FDC,
  INT,
  OR,
  PA,
  PATIENTS,
  QRE,
  RS,
  SMS,
  SMS_RELATED_TASK,
  STAR,
  THERAPIES,
} from 'constants/index';
import Audit from 'components/display/audit';
import { buildHandleEnterKeyPress } from 'services/utils/accessibility';
import { fetchTask, getStatusNameByStatusId } from 'services/utils/task-service';
import { ToggleLeftDrawer } from 'actions/action-view';
import { recentPatientSelected } from 'actions/action-patient';
import { openCommunicationNotesSideBar } from 'actions/action-notes';
import { push } from 'connected-react-router';
import { getTaskUrl } from 'helpers/router';
import { usePatient } from 'hooks/usePatient';
import {
  getTherapyStatusById,
  isSpecialtyByTheraphyId,
  isTherapyComplete,
} from 'services/utils/therapy-service';
import {
  auditContext,
  counselingTaskTypes,
  methodOfDelivery,
  qreIncidentSubtypesForIncidentAndComplaints,
} from 'constants/lists';
import {
  CounselingTaskType,
  CustomerBranchTrellisTherapeuticClassPriority,
  NoteBarTrigger,
} from 'constants/enums';
import { NoteTagTypes } from 'constants/note-tag-types';
import { windowFeatureIsEnabled } from 'config/window-features';
import { HighlightStar } from 'components/highlight-star/highlight-star';
import moment from 'moment';
import DisplayOrderForm from '../../../shipping/display-order-form';
import { styles } from '../side-bar-styles';
import TaskListTherapyLabel from './task-list-therapy-label';
import { FaGrantNotificationEnum } from '../../../../interfaces/redux/IFaGrantNotification';
import DisplayOnlyBar from '../display-only-bar/display-only-bar';
import { MultiUserTaskIndicator } from './multi-user-task-indicator';

const filterActiveTasks = tasks => {
  if (!tasks) {
    return tasks;
  }
  const filteredTasks = {};
  Object.keys(tasks).forEach(key => {
    const task = tasks[key];
    const isComplete = task.status_category_id
      ? [CATEGORY_DONE, CATEGORY_ARCHIVED].includes(task.status_category_id)
      : false;
    const updatedToday = moment.utc(task.updated).isSame(moment.utc(), 'day');
    if (!isComplete || updatedToday) {
      filteredTasks[key] = task;
    }
  });
  return filteredTasks;
};

const filterArchivedTasks = tasks => {
  if (!tasks) {
    return tasks;
  }
  const filteredTasks = {};
  Object.keys(tasks).forEach(key => {
    const task = tasks[key];
    const isComplete = task.status_category_id
      ? [CATEGORY_DONE, CATEGORY_ARCHIVED].includes(task.status_category_id)
      : false;
    const updatedToday = moment.utc(task.updated).isSame(moment.utc(), 'day');
    if (isComplete || updatedToday) {
      filteredTasks[key] = task;
    }
  });
  return filteredTasks;
};

function TaskListRow({ classes, selectedRow, clinicalDataFields, patient }) {
  const patientList = useSelector(state => state.patientList);
  const {
    oldest_unread_sms_followup_dt: oldestUnreadSmsFollowUpDt,
    phones,
    auto_secure_link,
    asl_anchor_therapy_id,
  } = patient;

  const incidentCategories = useSelector(state => state.lookups.incidentCategories) || [];
  const INCIDENT_CATEGORIES =
    useSelector(state => state.lookups?.CONSTANTS?.INCIDENT_CATEGORIES) || {};
  const priorityToText = priority => {
    if (priority == 0) {
      return CUSTOMER_BRANCH_TTC_LOW_DISPLAY_TEXT;
    }
    if (priority == 1) {
      return CUSTOMER_BRANCH_TTC_MEDIUM_DISPLAY_TEXT;
    }
    if (priority >= 2) {
      return CUSTOMER_BRANCH_TTC_HIGH_DISPLAY_TEXT;
    }
  };

  const {
    statuses: taskStatuses,
    intervention_types: interventionTypes,
    intervention_categories: interventionCategories,
    outreach_types: outreachTypes,
    outreach_categories: outreachCategories,
  } = useSelector(state => state.taskStatuses);
  const selectedPatientId = useSelector(state => state.selectedPatientId);
  const selectedTherapyId = useSelector(state => state.selectedTherapyId);
  const selectedTaskId = useSelector(state => state.selectedTaskId);
  const selectedTaskType = useSelector(state => state.selectedTaskType);
  const therapyStatuses = useSelector(state => state.therapyStatuses);
  const sidebarTaskType = useSelector(state => state.sidebarTaskType);
  const therapies = useSelector(state => state.therapies.data);
  const userCustomLists = useSelector(state => state.userCustomLists);
  const { loadedTask: renderedList } = patientList;
  const [showHover, setShowHover] = useState(false);
  const anchorMessage = useRef(null);
  const dispatch = useDispatch();
  const { patientFirstLastName } = usePatient(patient);
  const reconciliation = useSelector(state => state.reconciliation);
  const [status, setStatus] = useState(getStatusNameByStatusId(patient.status_id, taskStatuses));
  const tasks = useSelector(state => state.tasks.data);
  const activeTasks = Object.values(filterActiveTasks(tasks) || {});
  const taskInQueue = activeTasks.find(
    task => task.order_id === patient.order_id && task.taskType === patient.taskType,
  );
  const [currentRSTask, setCurrentRSTask] = useState(null);
  const [patientClinicalData, setPatientClinicalData] = useState([]);
  const lastNotification = useSelector(state => state.socketReducer.lastNotification);

  useEffect(() => {
    if (patient.taskType !== RS) return;
    fetchTask(patient.taskType, patient.task_id).then(task => setCurrentRSTask(task));
  }, [patient.taskType, patient.task_id]);

  useEffect(() => {
    if (patient.taskType !== DC) return;
    ClinicalDataClient.fetch(patient.patient_id || patient.id).then(response =>
      setPatientClinicalData(response.data),
    );
  }, [patient.taskType, patient.patient_id, patient.id]);

  const faTasksWithFundings = Object.values(tasks).filter(
    task => task.status_id === FA_FUNDING_AVAILABLE_STATUS_ID && task.taskType === FA,
  );

  const isFATaskAndHasFundings = useMemo(() => {
    if (
      lastNotification !== undefined &&
      lastNotification?.type === FaGrantNotificationEnum.NewGrant &&
      patient.taskType === FA
    ) {
      return true;
    }
    if (faTasksWithFundings.length > 0) {
      return true;
    }
    return false;
  }, [lastNotification, tasks]);

  const archivedTasks = Object.values(filterArchivedTasks(tasks) || {});
  const fcArchivedTask = archivedTasks.find(
    task => task.order_id === patient.order_id && task.taskType === FC,
  );

  if (renderedList === 'SEARCH') {
    return null;
  }

  const isTask = patient.taskType !== THERAPIES;
  const isDueDateTask = ![OR, INT, AR, THERAPIES].includes(patient.taskType);

  const arborNeedsByDate = convertToArborDate(
    typeof patient.needsby_date === 'string' ? patient.needsby_date.substr(0, 10) : null,
  );

  const arborReferralDate = convertToArborDate(
    typeof patient.referral_dt === 'string' ? patient.referral_dt.substr(0, 10) : null,
  );
  const arborFollowUpDt = convertToArborDate(patient.followup_dt, true);

  const fcArchivedShipDate = convertToArborDate(fcArchivedTask?.ship_date, true);
  const fcArchivedDeliveryDate = convertToArborDate(fcArchivedTask?.delivery_dt, true);

  const arborDueDate = convertToArborDate(
    typeof patient.due_date === 'string' ? patient.due_date.substr(0, 10) : null,
  );
  const arborShipDate = convertToArborDate(
    typeof patient.shipped_date === 'string' ? patient.shipped_date.substr(0, 10) : null,
  );
  const arborCreatedDate = convertToArborDate(
    typeof patient.created === 'string' ? patient.created.substr(0, 10) : null,
  );
  const arborGeneratedDate = convertToArborDate(
    typeof patient.generated_date === 'string' ? patient.generated_date.substr(0, 10) : null,
  );
  const arborReceivedDate = convertToArborDate(
    typeof patient.received_dt === 'string' ? patient.received_dt : null,
  );

  let overdue = false;
  if (!isTask) {
    overdue = arborNeedsByDate.isBeforeToday();
  } else if (!isDueDateTask) {
    overdue = arborFollowUpDt.isBeforeToday();
  } else {
    overdue = arborFollowUpDt.isBeforeToday() || arborDueDate.isBeforeToday();
  }
  const isPatientSelected = patient.id === selectedPatientId;
  const isSelectedTherapiesTasks =
    patient.taskType === THERAPIES
      ? isPatientSelected && patient.therapy_id === selectedTherapyId
      : isPatientSelected &&
        patient.task_id === selectedTaskId &&
        patient.taskType === selectedTaskType;
  const isSelected = isSelectedTherapiesTasks;

  const tableClasses = [classes.tableRow];
  if (isSelected) {
    tableClasses.push(classes.tableRowSelected);
  } else if (overdue) {
    tableClasses.push(classes.tableClassOverdue);
  } else {
    tableClasses.push(classes.tableClass);
  }

  const tableClass = classNames(tableClasses);

  const isComplete = isTask
    ? [CATEGORY_DONE, CATEGORY_ARCHIVED].indexOf(patient.status_category_id) > -1
    : isTherapyComplete(patient, therapyStatuses.therapyAdministrationStatuses);
  const labelTypeOverDue = overdue ? 'overdue' : '';
  const labelType = isComplete ? 'complete' : labelTypeOverDue;

  /**
   * Check if the dataType doesn't have a value or cannotCompleteReason
   */
  const hasValue = clinicalData => {
    const itemWithoutValue = clinicalData.find(
      dataType => !(dataType.value || dataType.cannotCompleteReason),
    );
    return !itemWithoutValue;
  };

  const getDCTags = clinicalData => {
    const patientData = [];
    const liaisonData = [];

    clinicalData.forEach(data => {
      if (clinicalDataFields.patientFields.includes(data.dataType)) {
        patientData.push(data);
      } else {
        liaisonData.push(data);
      }
    });

    return {
      patientData,
      liaisonData,
      patientValue: hasValue(patientData),
      liaisonValue: hasValue(liaisonData),
    };
  };

  const additionalTaskLabels = () => {
    const labelArr = [];
    const taskType = sidebarTaskType;
    const taskId = patient.task_id;
    switch (taskType) {
      case PA:
        if (patient.is_renewal === 1) {
          labelArr.push(
            <Label
              labelName="Renewal"
              labelType="taskSidebarDescriptor"
              key={`PA-Renewal-${taskId}`}
            />,
          );
        } else if (patient.is_renewal === 0) {
          labelArr.push(
            <Label labelName="New" labelType="taskSidebarDescriptor" key={`PA-New-${taskId}`} />,
          );
        }
        break;
      case FA:
        if (patient.is_renewal === 1) {
          labelArr.push(
            <Label
              labelName="Renewal"
              labelType="taskSidebarDescriptor"
              key={`FA-Renewal-${taskId}`}
            />,
          );
        } else if (patient.is_renewal === 0) {
          labelArr.push(
            <Label labelName="New" labelType="taskSidebarDescriptor" key={`FA-New-${taskId}`} />,
          );
        }
        break;
      case FDC:
        if (patient.preferred_rx_delivery_method) {
          labelArr.push(
            <Label
              key={`FDC-${taskId}-${patient.order_id}-${patient.preferred_rx_delivery_method}`}
              labelName={
                methodOfDelivery.find(
                  method => method.value === patient.preferred_rx_delivery_method,
                )?.label
              }
              labelType="taskSidebarDescriptor"
            />,
          );
        }

        if (patient.cold_chain_packing_required) {
          labelArr.push(
            <Label
              key={`FDC-${taskId}-${patient.order_id}-${
                patient.cold_chain_packing_required ? 'cold-chain' : 'no-cold-chain'
              }`}
              labelName="ColdChain"
              labelType="taskSidebarDescriptor"
            />,
          );
        }

        if (patient?.ancillary_supplies?.includes('adherence_packaging')) {
          labelArr.push(
            <Label
              key={`FDC-${taskId}-${patient.order_id}-${
                patient.ancillary_supplies.adherence_packaging
                  ? 'adherence-pkg'
                  : 'no-adherence-pkg'
              }`}
              labelName="AdPak"
              labelType="taskSidebarDescriptor"
            />,
          );
        }
        break;
      case DC:
        {
          const { clinicalData } = patient;
          if (clinicalData && Array.isArray(clinicalData) && clinicalData.length > 0) {
            // Check if patient or Internal tags
            const dcTags = getDCTags(clinicalData);

            let liaisonLabel;
            let patientLabel;

            if (dcTags.liaisonData.length > 0) {
              liaisonLabel = (
                <Label
                  key={`DC-Internal-${taskId}`}
                  labelName="Internal"
                  labelType={dcTags.liaisonValue ? 'complete' : 'incomplete'}
                />
              );
            }
            if (dcTags.patientData.length > 0) {
              patientLabel = (
                <Label
                  key={`DC-Patient-${taskId}`}
                  labelName="Patient"
                  labelType={dcTags.patientValue ? 'complete' : 'incomplete'}
                />
              );
            }

            return (
              <div>
                {liaisonLabel}
                {patientLabel}
              </div>
            );
          }
        }
        break;
      case INT:
        {
          const intType = interventionTypes.find(type => type.id === patient.type_id);
          if (interventionTypes.length > 0 && patient.type_id && intType) {
            labelArr.push(
              <Label
                key={`INT-${intType.type_name}-${taskId}`}
                labelName={intType.type_name}
                labelType="taskSidebarDescriptor"
              />,
            );
          }
          const intCategory = interventionCategories.find(cat => cat.id === patient.category_id);
          if (interventionCategories.length > 0 && patient.category_id && intCategory) {
            labelArr.push(
              <Label
                key={`INT-${intCategory.category_name}-${taskId}`}
                labelName={intCategory.category_name}
                labelType="taskSidebarDescriptor"
              />,
            );
          }
        }
        break;
      case OR: {
        const category =
          patient.category &&
          outreachCategories.length > 0 &&
          outreachCategories.find(cat => cat.id === patient.category);
        if (category) {
          labelArr.push(
            <Label
              key={`OR-${category.name}-${taskId}`}
              labelName={category.name}
              labelType="taskSidebarDescriptor"
            />,
          );
        }
        const orType =
          patient.type_id &&
          outreachTypes.length > 0 &&
          outreachTypes.find(type => type.id === patient.type_id);
        if (orType) {
          labelArr.push(
            <Label
              key={`OR-${orType.name}-${taskId}`}
              labelName={orType.name}
              labelType="taskSidebarDescriptor"
            />,
          );
        }
        break;
      }
      case CSL:
        if (patient.counseling_type === CounselingTaskType.Initial) {
          labelArr.push(
            <Label
              key={`CSL-Initial-${taskId}`}
              labelName="Initial"
              labelType="taskSidebarDescriptor"
            />,
          );
        } else if (patient.counseling_type === CounselingTaskType.Ongoing) {
          labelArr.push(
            <Label
              key={`CSL-Ongoing-${taskId}`}
              labelName="Ongoing"
              labelType="taskSidebarDescriptor"
            />,
          );
        } else if (patient.counseling_type === CounselingTaskType.CheckIn) {
          labelArr.push(
            <Label
              key={`CSL-CheckIn-${taskId}`}
              labelName="Check-In"
              labelType="taskSidebarDescriptor"
            />,
          );
        }
        break;
      case FC:
        if (patient.fill_cycle_number) {
          labelArr.push(
            <Label
              key={`FC-${patient.fill_cycle_number}-${taskId}`}
              labelName={`Fill Cycle #${patient.fill_cycle_number}`}
              labelType="taskSidebarDescriptor"
            />,
          );
        }
        break;
      case QRE:
        if (patient.category_id) {
          const categoryName = get(
            incidentCategories.find(o => o.id === patient.category_id),
            'name',
            undefined,
          );
          labelArr.push(
            <Label
              key={`QRE-${categoryName}-${taskId}`}
              labelName={categoryName}
              labelType="taskSidebarDescriptor"
            />,
          );

          if (
            patient.is_medication_error !== null &&
            Object.keys(INCIDENT_CATEGORIES).length > 0 &&
            patient.category_id === INCIDENT_CATEGORIES.INCIDENT
          ) {
            const errorType = qreIncidentSubtypesForIncidentAndComplaints.filter(
              t => parseInt(t.value, 10) === patient.is_medication_error,
            );
            labelArr.push(
              <Label
                key={`QRE-MedError-${taskId}`}
                labelName={errorType && errorType.length ? errorType[0].label : ''}
                labelType="taskSidebarDescriptor"
                data-qa-id={patient.is_medication_error}
              />,
            );
          }
          if (patient.is_hipaa_breach) {
            labelArr.push(
              <Label
                key={`QRE-HIPPA-${taskId}`}
                labelName="Potential PHI Exposure"
                labelType="taskSidebarDescriptor"
              />,
            );
          }
        }
        break;
      default:
        break;
    }
    if (labelArr.length > 0) {
      return labelArr;
    }
    return null;
  };

  let statusDisplay;
  if (!isTask) {
    const { therapyAdministrationStatuses, therapyEnrollmentStatuses } = therapyStatuses;
    const adminStatus = getTherapyStatusById(
      therapyAdministrationStatuses,
      patient.administration_status_id,
    );
    const enrollmentStatus = getTherapyStatusById(
      therapyEnrollmentStatuses,
      patient.enrollment_status_id,
    );
    const adminStatusText = adminStatus ? adminStatus.status : '';
    const enrollmentStatusText = enrollmentStatus ? enrollmentStatus.status : '';
    statusDisplay = [adminStatusText, enrollmentStatusText].join(' - ');
  }

  const selectTask = (patientId, taskId, therapyId, taskType) => {
    // for checklist we only require taskId, for therapy we also need therapy id
    // TODO: If logic doesn't look right

    const taskList = patientList.data;
    const clickedTask = taskList.find(it => it.task_id === taskId);
    const redirectToChecklist =
      taskType === AR ||
      (taskType === INT && (clickedTask.is_patient || clickedTask.type_id === 9)) ||
      (taskType === OR && clickedTask.is_patient) ||
      (taskType === QRE && !clickedTask.therapy_id) ||
      taskType === DUR ||
      taskType === RS;

    if (
      (redirectToChecklist && taskId) ||
      (therapyId && taskId) ||
      taskType === PATIENTS ||
      taskType === THERAPIES ||
      taskType === STAR
    ) {
      // eslint-disable-next-line max-len
      // In the therapy list we have the is_specialty field, don't need to check therapies in redux
      const isSpecialty =
        clickedTask && clickedTask.is_specialty !== undefined
          ? clickedTask.is_specialty
          : isSpecialtyByTheraphyId(therapies, therapyId);
      const url = getTaskUrl(patientId, therapyId, taskType, taskId, isSpecialty);

      dispatch(push(url));
    }
  };

  const onClickTask = patientId => {
    dispatch(recentPatientSelected(patientId));
    dispatch(new ToggleLeftDrawer(false));
  };

  const handleClick = () => {
    const { task_id: taskId, therapy_id: therapyId, taskType } = patient;
    let { id: patientId } = patient;
    if (patient.patient_id) {
      patientId = patient.patient_id;
    }

    /*
     * Only the SMS queue rows contains the sms_id property
     *
     * @TODO - Once the inbound SMS edit tags is implemented, this needs to set the right
     * tagTypeId and tagResourceId based on the selected task or therapy for
     * now that is not posible since this queue only renders
     * inbound messages and they are only tagged directly with the patient
     */
    if (patient.sms_id) {
      dispatch(
        openCommunicationNotesSideBar({
          noteBar: {
            display: true,
            tag: `${patient.first_name} ${patient.last_name || ''}`,
            tagTypeId: NoteTagTypes.Patient,
            tagResourceId: patientId,
            trigger: NoteBarTrigger.Sms,
            focusOnSmsId: patient.sms_id,
          },
        }),
      );
    }

    onClickTask(patientId);
    selectTask(patientId, taskId, therapyId, taskType);
  };

  const dueDate = patient.due_date ? arborDueDate.getUtcDate(false, 'MM/DD') : 'N/A';
  const needsByDate = patient.needsby_date ? arborNeedsByDate.getUtcDate(false, 'MM/DD') : 'N/A';
  const referralDate = patient.referral_dt ? arborReferralDate.getUtcDate(false, 'MM/DD') : 'N/A';
  const createdDate = patient.created ? arborCreatedDate.getUtcDate(false, 'MM/DD') : 'N/A';
  const generatedDate = patient.generated_date
    ? arborGeneratedDate.getUtcDate(false, 'MM/DD')
    : 'N/A';

  const followUpDate = patient.followup_dt ? arborFollowUpDt.getUtcDate(false, 'MM/DD') : 'N/A';
  const receivedDate = patient.received_dt
    ? arborReceivedDate.getCustomerDatetime(false, 'MM/DD h:mm a')
    : 'N/A';

  const shipDate = patient.shipped_date ? arborShipDate.getCustomerDate(false, 'MM/DD') : 'N/A';

  const shippedDate = fcArchivedTask?.ship_date && fcArchivedShipDate.getUtcDate(false, 'MM/DD');
  const deliveryDate =
    fcArchivedTask?.delivery_dt && fcArchivedDeliveryDate.getUtcDate(false, 'MM/DD');

  const cslTaskTypeName = counselingTaskTypes.find(type => type.id === patient.counseling_type)
    ? counselingTaskTypes.find(type => type.id === patient.counseling_type).name
    : '-';
  const getDateCellByTaskTypePrimary = () => {
    switch (patient.taskType) {
      case THERAPIES:
        return (
          <TableCell className={classes.tableCell}>
            <div className={classes.tablePPIcon}>
              <span className={classes.needsByDate}>{needsByDate}</span>
              {!!patient.is_needsby_per_protocol && <PerProtocolIcon />}
            </div>
          </TableCell>
        );
      case AR:
        return (
          <TableCell className={classes.tableCell}>
            <div className={classes.tablePPIcon}>
              <span className={classes.needsByDate}>
                {patient.followup_dt ? arborFollowUpDt.getUtcDate(false, 'MM/DD') : 'N/A'}
              </span>
              {!!patient.is_needsby_per_protocol && <PerProtocolIcon />}
            </div>
          </TableCell>
        );

      case FDC:
        return (
          <TableCell className={classes.tableCell}>
            <div className={classes.tablePPIcon}>
              <span className={classes.needsByDate}>{shippedDate || shipDate}</span>
            </div>
          </TableCell>
        );
      case INT:
        return (
          <TableCell className={classes.tableCell}>
            <div>
              <span className={classes.needsByDate}>{followUpDate}</span>
            </div>
          </TableCell>
        );
      case OR:
        return (
          <TableCell className={classes.tableCell}>
            <div>
              <span className={classes.needsByDate}>{followUpDate}</span>
            </div>
          </TableCell>
        );
      case STAR:
        return <></>;
      default:
        return (
          <TableCell className={classes.tableCell}>
            <div className={classes.tableContent}>{followUpDate}</div>
          </TableCell>
        );
    }
  };

  const buildAdditionalTagProps = tag => {
    const isCompleteAdditional = [CATEGORY_DONE, CATEGORY_ARCHIVED].includes(
      tag.status_category_id,
    );
    let overdueAdditional = false;
    const isDueDateTaskAdditional = ![OR, INT, AR, THERAPIES].includes(tag.taskType);
    const arborFollowUpDtAdditional = convertToArborDate(tag.followup_dt, true);
    const arborDueDateAdditional = convertToArborDate(tag.due_date);

    if (!isDueDateTaskAdditional) {
      overdueAdditional = arborFollowUpDtAdditional.isBeforeToday();
    } else {
      overdueAdditional =
        arborFollowUpDtAdditional.isBeforeToday() || arborDueDateAdditional.isBeforeToday();
    }
    const labelTypeOverDueAdditional = overdueAdditional ? 'overdue' : '';
    const labelTypeAdditional = isCompleteAdditional ? 'complete' : labelTypeOverDueAdditional;
    const taskStatusAdditional = getStatusNameByStatusId(tag.status_id, taskStatuses);
    return { labelTypeAdditional, taskStatusAdditional };
  };

  const getDateCellByTaskTypeSecondary = () => {
    const taskType = sidebarTaskType === SMS_RELATED_TASK ? SMS : patient.taskType;
    switch (taskType) {
      case THERAPIES:
        return (
          <TableCell className={classes.tableCell}>
            <div className={classes.tablePPIcon}>
              <span className={classes.needsByDate}>{referralDate}</span>
            </div>
          </TableCell>
        );
      case AR:
        return (
          <TableCell className={classes.tableCell}>
            <div className={classes.tablePPIcon}>
              <span className={classes.needsByDate}>{priorityToText(patient.priority)}</span>
              {!!patient.is_needsby_per_protocol && <PerProtocolIcon />}
            </div>
          </TableCell>
        );
      case FDC:
        const labelArr = [];
        const taskId = patient.task_id;
        if (patient.urgent) {
          labelArr.push(<Label key={`FDC${taskId}`} labelName="Urgent" labelType="urgent" />);
        }
        return labelArr.length > 0 ? (
          <TableCell className={classes.tableCell}>
            <div>{labelArr}</div>
            <div className={classes.tablePPIcon}>
              <span className={classes.needsByDate}>{deliveryDate || followUpDate}</span>
            </div>
          </TableCell>
        ) : (
          <TableCell className={classes.tableCell}>
            <div className={classes.tablePPIcon}>
              <span className={classes.needsByDate}>{deliveryDate || followUpDate}</span>
            </div>
          </TableCell>
        );

      case CSL:
        return (
          <TableCell className={classes.tableCell}>
            <div className={classes.tablePPIcon}>
              <span className={classes.needsByDate}>{cslTaskTypeName}</span>
            </div>
          </TableCell>
        );
      case INT:
        return (
          <TableCell className={classes.tableCell}>
            <div className={classes.tablePPIcon}>
              <span className={classes.needsByDate}>{createdDate}</span>
            </div>
          </TableCell>
        );
      case OR:
        return (
          <TableCell className={classes.tableCell}>
            <div className={classes.tablePPIcon}>
              <span className={classes.needsByDate}>{createdDate}</span>
            </div>
          </TableCell>
        );
      case DUR:
        return (
          <TableCell className={classes.tableCell}>
            <div className={classes.tablePPIcon}>
              <span className={classes.needsByDate}>{generatedDate}</span>
            </div>
          </TableCell>
        );
      case SMS:
        return (
          <TableCell className={classes.tableCell}>
            <div className={classes.tablePPIcon}>
              <span className={classes.needsByDate}>{receivedDate}</span>
            </div>
          </TableCell>
        );
      case STAR:
        return <></>;
      default:
        return (
          <TableCell className={classes.tableCell}>
            <div>
              <span className={classes.needsByDate}>{dueDate}</span>
            </div>
          </TableCell>
        );
    }
  };

  const onMouseEnter = e => {
    anchorMessage.current = e.currentTarget;
    setShowHover(true);
  };

  const onMouseLeave = () => {
    setShowHover(false);
  };

  const tableRowId = `taskItem${patient.taskType}-${patient.task_id}${
    patient.sms_id ? `-${patient.sms_id}` : ''
  }`;

  const isStarHighlighted = () => {
    const userCustomListsForUser = userCustomLists.users.find(
      user => user.user_id === window.USER.id,
    );
    const patientId = patient.patient_id || patient.id;

    return userCustomListsForUser?.lists[0]?.list?.some(l => l.patient_id === patientId);
  };

  const dcWithProm = () => {
    if (![DC, RS].includes(patient.taskType)) return false;

    const dcClinicalDataItems = patientClinicalData.filter(
      item => item.taskDataCollectId === patient.task_id && !!item.questionnaire,
    );
    return dcClinicalDataItems.length > 0;
  };

  const dobInfo = `${patient.dob ? convertToArborDate(patient.dob, true).getUtcDate(true) : '-'}`;
  const mrnValue = patient.source_patient_id;
  const mrnSource = `${
    windowFeatureIsEnabled('display_mrn_source_name') && patient.mrn_source_name
      ? `(${patient.mrn_source_name})`
      : ''
  }`;
  const dobMrnInfo = `${dobInfo} | ${mrnValue} ${mrnSource}`;
  return (
    <>
      <TableRow
        hover
        id={tableRowId}
        onClick={handleClick}
        onKeyPress={buildHandleEnterKeyPress(handleClick)}
        className={tableClass}
        tabIndex="0"
        role="button"
        ref={isSelected ? selectedRow : undefined}
        type="text"
        onMouseEnter={sidebarTaskType === SMS_RELATED_TASK ? onMouseEnter : null}
        onMouseLeave={sidebarTaskType === SMS_RELATED_TASK ? onMouseLeave : null}
      >
        <TableCell className={classes.tableCell} component="th" scope="row">
          <Grid container alignItems="center" className={classes.tableMainContent}>
            <Grid container item xs={1} justifyContent="center">
              {patientNeedsReconciliation(reconciliation, patient) && <RetryIcon size={16} />}
              {patient.taskType === STAR && (
                <HighlightStar
                  isHighlight={isStarHighlighted()}
                  additionalUsers={patient.otherUsersWithPatientOnList || []}
                />
              )}
            </Grid>

            <Grid item xs={11} className={classes.patientNameGrid}>
              <div className={classes.tablePatientName}>{patientFirstLastName}</div>
              <div className={classes.patientDOB}>{dobMrnInfo}</div>
              <Grid container alignItems="center">
                <Grid item flexDirection="row" display="flex" gap="10px">
                  {patient.taskType !== STAR &&
                    (isStarHighlighted() ||
                      (patient.otherUsersWithPatientOnList || []).length > 0) && (
                      <HighlightStar
                        isHighlight={isStarHighlighted()}
                        additionalUsers={patient.otherUsersWithPatientOnList || []}
                      />
                    )}
                </Grid>
                {patient.taskType === FC && (
                  <DisplayOnlyBar
                    display="flex"
                    alignItems="center"
                    gap={0.5}
                    item
                    // Status Bar
                    adherencePackaging={!!patient.adherence_packaging}
                    needsInterpreter={!!patient.need_interpreter}
                    medSync={patient.med_sync === 2}
                    enrolledInSMS={!!patient.enrolled_in_sms}
                    dropShipMed={!!patient.drop_ship_med}
                    phones={phones || []}
                    patientAutoFCStatus={auto_secure_link}
                    anchorTherapy={therapies?.[asl_anchor_therapy_id] || {}}
                  />
                )}
                <Grid item>
                  {patient.taskType === FDC && (
                    <DisplayOrderForm
                      data={taskInQueue}
                      patientId={patient.id}
                      setStatus={setStatus}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TableCell>
        {sidebarTaskType !== STAR && (
          <TableCell className={classes.tableCell}>
            <Grid container alignItems="center">
              {/* eslint-disable-next-line */}
              <Grid item xs={!!isComplete ? 10 : 12}>
                <Grid container>
                  <TaskListTherapyLabel
                    patient={patient}
                    sidebarTaskType={sidebarTaskType}
                    task={currentRSTask}
                  />
                  {!!patient.has_audit_items && (
                    <Audit
                      iconType="warning"
                      context={auditContext.Sidebar}
                      auditPatientId={patient.id}
                    />
                  )}
                </Grid>
                <div className={classes.tableCellNoBreak}>
                  <Grid container>
                    {/* eslint-disable-next-line */}
                    {!!status && (
                      <Label labelName={`${patient.taskType} ${status}`} labelType={labelType} />
                    )}
                    {isFATaskAndHasFundings && <MoneyIcon style={styles.moneyIcon} />}
                    {patient.taskType === PATIENTS && <Label labelName="PATIENT" labelType="" />}
                    {!!statusDisplay && <Label labelName={statusDisplay} labelType={labelType} />}
                    {/* These are additional tags related to an sms message */}
                    {patient.additionalTags?.length > 0 &&
                      patient.additionalTags.map(tag => {
                        // only display tags that are not patient or therapy tags
                        if (tag.tagTypeId > NoteTagTypes.Therapy) {
                          const { labelTypeAdditional, taskStatusAdditional } =
                            buildAdditionalTagProps(tag);
                          return (
                            <Label
                              key={`${tag.taskType}-${tag.id}${tag.sms_id ? `-${tag.sms_id}` : ''}`}
                              labelName={`${tag.taskType} ${taskStatusAdditional}`}
                              labelType={labelTypeAdditional}
                            />
                          );
                        }
                        return null;
                      })}
                    {/* eslint-disable-next-line */}
                    <Grid container item xs={!!isComplete ? 10 : 12}>
                      {additionalTaskLabels()}

                      {oldestUnreadSmsFollowUpDt && (
                        <Sms
                          className={
                            convertToArborDate(oldestUnreadSmsFollowUpDt).isBeforeToday()
                              ? classes.unreadOverdueSms
                              : classes.unreadSms
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {!!isComplete && (
                <Grid item xs={2}>
                  <span className={classes.tableRowCheckIcon}>
                    <CompleteIcon />
                  </span>
                </Grid>
              )}
              {patient.taskType === FC && <MultiUserTaskIndicator taskId={patient.task_id} />}
            </Grid>
          </TableCell>
        )}

        {getDateCellByTaskTypePrimary()}
        {getDateCellByTaskTypeSecondary()}
      </TableRow>
      <Popper
        className={classes.smsHoverContainer}
        open={showHover}
        anchorEl={anchorMessage.current}
        placement="right"
        // modifiers={{  TODO: fix styling
        //   arrow: {
        //     enabled: true,
        //   },
        // }}
      >
        <span className={classes.smsHoverArrow} />
        <Paper className={classes.smsHoverContent}>
          <Typography>{patient.sms_message_text}</Typography>
        </Paper>
      </Popper>
    </>
  );
}

export default withStyles(styles, { withTheme: true })(TaskListRow);

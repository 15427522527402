import React, { useState, FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Modal, Grid, Button, Typography, CircularProgress } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { nameOfFactory } from 'utils/types-util';
import { renderDatePicker as RenderDatePicker } from 'components/form/datepicker/datetime-picker';
import { getModalStyle } from 'services/utils/styles-service';
import { ArborCheckbox } from 'components/arbor-checkbox/arbor-checkbox';
import { HolidayManagerStyles } from './holidays.styles';
import { HolidayTypeEnum, IHolidayFormFields } from './interface';

interface IHolidayModalProps extends WithStyles<typeof HolidayManagerStyles> {
  open: boolean;
  close: () => void;
  submitRequestHoliday: (data: any, holidayForm: any) => void;
  handleAddHolidayCheckboxType: (holidayType: HolidayTypeEnum) => void;
  holidayTypes: HolidayTypeEnum[];
}

const getFormFields = nameOfFactory<IHolidayFormFields>();

const HolidayModal: FC<IHolidayModalProps> = ({
  close,
  submitRequestHoliday,
  classes,
  open,
  handleAddHolidayCheckboxType,
  holidayTypes,
}: IHolidayModalProps): JSX.Element => {
  const holidayForm = useForm<IHolidayFormFields>();
  const [submittingHoliday, setSubmittingHoliday] = useState<boolean>(false);
  const isSubmitDisabled = !holidayForm.watch('holidayDate') || holidayTypes.length === 0;

  const submitHoliday = async (formValues: IHolidayFormFields) => {
    try {
      setSubmittingHoliday(true);
      submitRequestHoliday({ ...formValues, holidayTypes }, holidayForm);
    } catch (error) {
      console.error('Error on adding the holiday:', error);
    } finally {
      setSubmittingHoliday(false);
    }
  };

  const renderHolidayForm = (): JSX.Element => {
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Typography>Add Holiday</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={6}>
            <Controller
              control={holidayForm.control}
              name={getFormFields('holidayDate')}
              rules={{ required: true }}
              render={({ field }: any) => (
                <RenderDatePicker
                  label="Holiday Date *"
                  meta={{ touched: holidayForm.formState.errors.holidayDate, error: 'Required' }}
                  input={{
                    value: field?.value,
                    onChange: field?.onChange,
                    onBlur: field?.onBlur,
                  }}
                />
              )}
            />
          </Grid>
          <Grid container item xs={6}>
            <Grid container direction="row">
              <Grid item>
                <ArborCheckbox
                  checked={holidayTypes.includes(HolidayTypeEnum.CUSTOMER)}
                  onChange={() => {
                    handleAddHolidayCheckboxType(HolidayTypeEnum.CUSTOMER);
                  }}
                />
              </Grid>
              <Grid item>
                <span>{HolidayTypeEnum.CUSTOMER}</span>
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid item>
                <ArborCheckbox
                  checked={holidayTypes.includes(HolidayTypeEnum.UPS)}
                  onChange={() => {
                    handleAddHolidayCheckboxType(HolidayTypeEnum.UPS);
                  }}
                />
              </Grid>
              <Grid item>
                <span>{HolidayTypeEnum.UPS}</span>
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid item>
                <ArborCheckbox
                  checked={holidayTypes.includes(HolidayTypeEnum.COURIER)}
                  onChange={() => {
                    handleAddHolidayCheckboxType(HolidayTypeEnum.COURIER);
                  }}
                />
              </Grid>
              <Grid item>
                <span>{HolidayTypeEnum.COURIER}</span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" className={classes.modalContainerButton}>
          <Button onClick={close} style={{ marginRight: '5px' }}>
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={holidayForm.handleSubmit(submitHoliday)}
            disabled={submittingHoliday || isSubmitDisabled}
          >
            {submittingHoliday ? <CircularProgress color="secondary" size={20} /> : 'Add Holiday'}
          </Button>
        </Grid>
      </>
    );
  };

  return (
    <Modal open={open} onClose={close}>
      <div style={getModalStyle()} className={classes.modal}>
        {renderHolidayForm()}
      </div>
    </Modal>
  );
};

export default withStyles(HolidayManagerStyles)(HolidayModal);

import React from 'react';
import { Grid } from '@mui/material';
import SmallFdcListCard from 'components/card/small-fdc-list-card/small-fdc-list-card';

import { buildQaId } from 'utils/build-qa-id';

import ItemsCarousel from 'components/items-carousel/items-carousel';
import { IProps } from './interfaces/IProps';

const qaIdBuilder = buildQaId('horizontal-fdc-list');

const HorizontalFdcList: React.FC<IProps> = (props: IProps): JSX.Element => {
  const [] = React.useState<number>(0);
  return (
    <Grid container data-qa-id={qaIdBuilder('container')}>
      <Grid item xs={12}>
        <ItemsCarousel
          numberOfItems={4}
          spaceBetween={20}
          items={props.fdcItems.map(x => {
            if (x.fdcItems.length > 0) {
              return <SmallFdcListCard {...x} key={x.therapyName} />;
            }
            // eslint-disable-next-line no-useless-return, consistent-return
            return;
          })}
        />
      </Grid>
    </Grid>
  );
};

export default HorizontalFdcList;

import React from 'react';
import { Paper, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import cx from 'classnames';
import { styles } from './therapy-detail.styles';

const TherapyStatus = ({ classes, status, categoryId, id }) => (
  <Paper className={classes.therapyStatus} elevation={1}>
    <Grid container>
      <Grid item>
        <p className={classes.therapyStatusReason}>Administration</p>
        <h3
          className={cx(classes.therapyStatusTitle, classes[`therapyStatusTitle${categoryId}`])}
          id={id}
        >
          {status}
        </h3>
      </Grid>
    </Grid>
  </Paper>
);

export default withStyles(styles)(TherapyStatus);

import React from 'react';
import { Button, CircularProgress, PropTypes, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface ICircularLoadingButtonProps {
  onClick: () => void;
  isLoading: boolean;
  buttonLabel: string;
  color?: PropTypes.Color;
  variant?: 'text' | 'outlined' | 'contained';
  size?: number;
  ['data-qa-id']?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  loading: {
    marginLeft: 10,
    color: theme.palette.primary.main,
  },
}));

export const CircularLoadingButton: React.FC<ICircularLoadingButtonProps> = (
  props: ICircularLoadingButtonProps,
): JSX.Element => {
  const { loading } = useStyles();

  return (
    <Button
      type="button"
      onClick={() => props.onClick()}
      variant={props.variant || 'contained'}
      disabled={props.isLoading}
      data-qa-id={props['data-qa-id']}
    >
      {props.buttonLabel}
      {props.isLoading && (
        <CircularProgress
          data-qa-id="loading-preview"
          className={loading}
          size={props.size || 20}
          variant="indeterminate"
        />
      )}
    </Button>
  );
};

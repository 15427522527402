import React, {
  useImperativeHandle,
  useState,
  forwardRef,
  useRef,
  ForwardRefRenderFunction,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
} from 'react';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { buildQaId } from 'utils/build-qa-id';
import { RsProtocolFormModalStyles } from './styles';
import { QA_ID } from './constants';
import { RsProtocolModalInnerForm, IProps } from './inner-form';

export type IRsProtocolFormModalProps = IProps

export interface IRsProtocolFormModalRef {
  setOpen(value: boolean): void;
}

const useStyles = makeStyles(RsProtocolFormModalStyles);

const qaIdBuilder = buildQaId(QA_ID);
const RsProtocolFormModalCls: ForwardRefRenderFunction<
  IRsProtocolFormModalRef,
  IRsProtocolFormModalProps
> = (props, ref) => {
  const classes = useStyles();

  const { onSuccess, onCancel, editMode, ...otherProps } = props;

  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    setOpen,
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseWrapper = (handler?: () => void) => {
    return () => {
      handleClose();
      if (handler) {
        handler();
      }
    };
  };

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      data-qa-id={qaIdBuilder('root')}
      maxWidth="md"
    >
      <DialogTitle className={classes.titleWrp}>
        <Typography variant="h6" className={classes.title}>
          {editMode ? 'Edit' : 'Add'} RS Protocol
        </Typography>
      </DialogTitle>
      <RsProtocolModalInnerForm
        editMode={editMode}
        onSuccess={handleCloseWrapper(onSuccess)}
        onCancel={handleCloseWrapper(onCancel)}
        {...otherProps}
      />
    </Dialog>
  );
};

export const RsProtocolFormModal = forwardRef(RsProtocolFormModalCls);

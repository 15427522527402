import { takeEvery, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  IBenefitInvestigationLatest,
  BenefitInvestigationLatestMap,
} from 'interfaces/redux/ITherapyBenefitsInvestigationLatest';
import { mapValues } from 'lodash';
import { change } from 'redux-form';
import {
  BULK_TASKS_UPDATED,
  FETCH_BI_INFO,
  REFRESH_BI_HISTORY,
  SELECTED_PATIENT_ID,
} from '../constants';
import { BenefitsInvestigationService } from '../services/utils/benefits-investigation-service';
import { BenefitInvestigationLatestActionType } from '../actions/action-therapy-benefits-investigation-latest';
import { IState } from '../interfaces/redux/IState';

const service = new BenefitsInvestigationService();

export const SET_FC_DISPENSE_QTY_FROM_BI = 'SET_FC_DISPENSE_QTY_FROM_BI';

export function* getByPatientId(action: {
  type: string;
  payload: number;
}): Generator<any, any, any> {
  const patientId = Number(action.payload);
  // If action.payload it's null its parsed as 0 by Number , so checking first if patientId it's truthy
  if (
    patientId !== 0 &&
    !Number.isNaN(patientId) &&
    (window as any)?.FEATURES?.benefits_investigations === true
  ) {
    try {
      const response: IBenefitInvestigationLatest[] = yield call(
        service.getLatestTherapyBenefitInvestigationsByPatientId,
        patientId,
      );

      // Mapping to {therapyId: object} for fast lookup by therapyId
      const biMapByTherapyId: BenefitInvestigationLatestMap = response.reduce(
        (map: any, biResponse: IBenefitInvestigationLatest) => {
          map[biResponse.therapyId] = biResponse;
          return map;
        },
        {},
      );

      yield put({
        type: BenefitInvestigationLatestActionType.GetByPatientIdSuccess,
        payload: biMapByTherapyId,
      });
    } catch (error) {
      yield put({
        type: BenefitInvestigationLatestActionType.GetByPatientIdFailed,
      });
    }
  }
}

function* fetchLatestBenefitsInvestigation(action: { type: string; payload: any }) {
  const state: IState = yield select();
  const selectedPatientId = state.selectedPatientId;
  const patientId = selectedPatientId;
  if (
    patientId !== 0 &&
    !Number.isNaN(patientId) &&
    (window as any)?.FEATURES?.benefits_investigations === true
  ) {
    try {
      const response: IBenefitInvestigationLatest[] = yield call(
        service.getLatestTherapyBenefitInvestigationsByPatientId,
        patientId,
      );

      // Mapping to {therapyId: object} for fast lookup by therapyId
      const biMapByTherapyId: BenefitInvestigationLatestMap = response.reduce(
        (map: any, biResponse: IBenefitInvestigationLatest) => {
          map[biResponse.therapyId] = biResponse;
          return map;
        },
        {},
      );

      yield put({
        type: BenefitInvestigationLatestActionType.GetByPatientIdSuccess,
        payload: biMapByTherapyId,
      });
    } catch (error) {
      yield put({
        type: BenefitInvestigationLatestActionType.GetByPatientIdFailed,
      });
    }
  }
}
export function* setDipenseQtyFromBI(action: { type: string; payload: any }) {
  const { quantity } = action.payload;
  if (quantity) {
    const quantityEntries: { ['key']: string } = yield select(
      state => state.form.FILL_COORDINATION_TRANSITION?.values?.dispense_qty,
    );
    const dispenseQtys = mapValues(quantityEntries, () => quantity);
    yield put(change('FILL_COORDINATION_TRANSITION', 'dispense_qty', dispenseQtys));
  }
}

export function* watcherTherapyBenefitsInvestigationSaga() {
  // yield takeLatest(BULK_TASKS_UPDATED, fetchLatestBenefitsInvestigation);
  yield takeLatest(REFRESH_BI_HISTORY, fetchLatestBenefitsInvestigation);
  yield takeLatest(SET_FC_DISPENSE_QTY_FROM_BI, setDipenseQtyFromBI);
  yield takeLatest([FETCH_BI_INFO], getByPatientId);
}

import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid, Checkbox, Typography } from '@mui/material';
import compose from 'recompose/compose';
import { Field, change as _change, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import DetailField from 'components/form/field/field';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { ADD_FINANCIAL_ASSISTANCE_FORM } from 'constants/index';
import { stripFormattedDollarValue } from 'services/utils/formatting';
import {
  addFinancialAssistanceTask,
  addIncome,
  fetchFinancialAssistance,
  fetchIncomes,
} from 'actions/action-financials';
import {
  validateDate,
  required,
  validateAlphaNumeric,
  length6,
  maxLength20,
} from 'components/form/validation/validation';
import { convertToArborDate } from 'models/time/arbor-date';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import {
  renderDropdown,
  renderTextField,
  renderPhoneTextField,
  renderMoneyTextField,
} from 'components/form/field/redux-field';
import ConfirmationDialogPanel from 'components/form/confirmation/confirmation-dialog-panel';
import { bindActionCreators } from 'redux';
import { styles } from '../../task-detail-styles';
import { getFreeDrugReasonId } from '../../../../constants/lists';
import FormHeader from '../../../../components/form/header/header';
import { FaIncomeInformationForm } from '../fa-income-information-form';

const form = `${ADD_FINANCIAL_ASSISTANCE_FORM}_PAP`;
class AddPrescriptionAssistanceProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: null,
      therapyId: null,
      noGroupIdCheckbox: false,
      noPCNCheckbox: false,
      awardsAmountDialogSettings: {
        open: false,
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGroupIdCheckbox = this.handleGroupIdCheckbox.bind(this);
    this.handleNoPCNCheckbox = this.handleNoPCNCheckbox.bind(this);
  }

  // Handles the submit functionality. Right now it sets some fields to null for simplicity's sake
  handleSubmit(values) {
    const {
      addFinancialAssistanceTask, // eslint-disable-line
      reset,
      therapy,
      cancel,
      task,
      patientId,
      selectedPatientId,
      categoryId,
      freeDrugReason,
      addIncome, // eslint-disable-line
      fetchIncomes, // eslint-disable-line
    } = this.props;
    const { noGroupIdCheckbox, noPCNCheckbox } = this.state;

    const payload = {
      ...values,
      patient_id: patientId || selectedPatientId,
      therapy_id: therapy ? therapy.id : values.therapy_id,
      task_fa_id: task ? task.id : null,
      status: values.status,
      limit: values.limit,
      expiration_date: values.expiration_date
        ? convertToArborDate(values.expiration_date).getUtcDatetime()
        : null,
      financial_assistance_type: Number(categoryId),
      bin: values.bin,
      pcn: noPCNCheckbox ? null : values.pcn,
      group_id: noGroupIdCheckbox ? null : values.group_id,
      phone_number: values.phone_number,
      fax_number: values.fax_number,
      details: values.details,
      member_id: values.member_id,
      copay: values.copay ? stripFormattedDollarValue(values.copay) : null,
      rewards_amount: values.rewards_amount
        ? stripFormattedDollarValue(values.rewards_amount)
        : null,
      free_drug_reason: getFreeDrugReasonId(freeDrugReason),
    };
    addFinancialAssistanceTask(payload);

    cancel();
    reset();

    if (values.create_income) {
      const incomePayload = {
        patient_id: patientId || selectedPatientId || values.patient_id,
        taxable_income: values.total_income ? stripFormattedDollarValue(values.total_income) : 0,
        family_size: values.family_size,
        income_year: values.income_year,
      };
      addIncome(incomePayload).then(() => {
        fetchIncomes(patientId || selectedPatientId);
      });
    }
  }

  handleGroupIdCheckbox() {
    this.setState(prevState => ({
      noGroupIdCheckbox: !prevState.noGroupIdCheckbox,
    }));
  }

  handleNoPCNCheckbox() {
    this.setState(prevState => ({
      noPCNCheckbox: !prevState.noPCNCheckbox,
    }));
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      classes,
      cancel,
      therapies,
      addingFromFinancials,
      task,
      formValues,
      change,
    } = this.props;
    const { status, therapyId, noGroupIdCheckbox, noPCNCheckbox, awardsAmountDialogSettings } =
      this.state;

    const rewardsAmount = formValues?.rewards_amount
      ? stripFormattedDollarValue(formValues?.rewards_amount)
      : null;

    if (
      (10 ** 5 <= rewardsAmount || rewardsAmount < 0 || Number.isNaN(rewardsAmount)) &&
      !awardsAmountDialogSettings.open
    ) {
      this.setState({
        awardsAmountDialogSettings: {
          open: true,
          message:
            "Please note that you can only input values between $0 and $99,999 in the 'Awards Amount' field",
          dialogConfirm: async () => {
            let limitValue = 0;
            if (10 ** 5 <= rewardsAmount) {
              limitValue = 99999;
            } else if (rewardsAmount < 0 || Number.isNaN(rewardsAmount)) {
              limitValue = null;
            }
            await change('rewards_amount', limitValue);
            this.setState({ awardsAmountDialogSettings: { open: false } });
          },
        },
      });
    }

    const idSuffix = task && task.therapy_id && task.id ? `_${task.therapy_id}_${task.id}` : '';
    return (
      <>
        {awardsAmountDialogSettings.open && (
          <ConfirmationDialogPanel
            open
            content={<Typography>{awardsAmountDialogSettings.message}</Typography>}
            continueText="Ok"
            onContinue={awardsAmountDialogSettings?.dialogConfirm}
          />
        )}
        <Grid container justifyContent="center" spacing={7}>
          <Grid item xs={12}>
            <form className={classes.addFormContainer} autoComplete="off">
              <Grid container spacing={2}>
                <FaIncomeInformationForm
                  form={form}
                  idPrefix="add_FA_program_"
                  idSuffix={idSuffix}
                />
                <Grid item xs={12}>
                  <FormHeader header="Program Information" />
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    {addingFromFinancials && (
                      <Grid item xs={3} className={classes.fieldContainer}>
                        <Field
                          name="therapy_id"
                          label="Therapy *"
                          validate={[required]}
                          component={renderDropdown}
                          fields={Object.values(therapies).map(t => ({
                            label: t.drug_name,
                            value: t.id,
                          }))}
                          state={therapyId}
                          id={`add_FA_program_therapy_id${idSuffix}`}
                        />
                      </Grid>
                    )}
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="program_name"
                        label="Program Name *"
                        component={renderTextField}
                        validate={[required]}
                        id={`add_FA_program_program_name${idSuffix}`}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="phone_number"
                        label="Program Phone Number"
                        component={renderPhoneTextField}
                        id={`add_FA_program_phone_number${idSuffix}`}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="fax_number"
                        label="Program Fax Number"
                        component={renderPhoneTextField}
                        id={`add_FA_program_fax_number${idSuffix}`}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="expiration_date"
                        label="Expiration Date *"
                        component={renderDatePicker}
                        validate={[validateDate, required]}
                        id={`add_FA_program_expiration_date${idSuffix}`}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="bin"
                        label="BIN"
                        component={renderTextField}
                        validate={[validateAlphaNumeric, length6]}
                        id={`add_FA_program_bin${idSuffix}`}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={6} className={classes.fieldContainer}>
                          <Field
                            name="pcn"
                            label={!noPCNCheckbox ? 'PCN *' : 'PCN'}
                            disabled={noPCNCheckbox}
                            component={renderTextField}
                            validate={!noPCNCheckbox ? [required, maxLength20] : null}
                            id={`add_FA_program_pcn${idSuffix}`}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container alignItems="center" justifyContent="flex-start">
                            <Checkbox
                              checked={noPCNCheckbox}
                              color="primary"
                              onChange={this.handleNoPCNCheckbox}
                              value="checkedPCN"
                              label="Secondary"
                              id={`add_FA_program_no_pcn${idSuffix}`}
                            />
                            <DetailField defaultValue="No PCN" />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="member_id"
                        label="Member ID"
                        component={renderTextField}
                        id={`add_FA_program_member_id${idSuffix}`}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={6} className={classes.fieldContainer}>
                          <Field
                            name="group_id"
                            label={!noGroupIdCheckbox ? 'Group ID *' : 'Group ID'}
                            validate={!noGroupIdCheckbox ? [required] : null}
                            component={renderTextField}
                            disabled={noGroupIdCheckbox}
                            id={`add_FA_program_group_id${idSuffix}`}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container alignItems="center" justifyContent="flex-start">
                            <Checkbox
                              checked={noGroupIdCheckbox}
                              color="primary"
                              onChange={this.handleGroupIdCheckbox}
                              value="checkedGroupId"
                              label="Secondary"
                              id={`add_FA_program_no_group_id${idSuffix}`}
                            />
                            <DetailField defaultValue="No Group Id" />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="copay"
                        label="Copay"
                        component={renderMoneyTextField}
                        id={`add_FA_program_copay${idSuffix}`}
                        decimalScale={2}
                        fixedDecimalScale
                        decimalSeparator="."
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="rewards_amount"
                        label="Awards Amount"
                        component={renderMoneyTextField}
                        id={`add_FA_program_rewards_amount${idSuffix}`}
                        decimalScale={2}
                        fixedDecimalScale
                        decimalSeparator="."
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="status"
                        label="Status *"
                        component={renderDropdown}
                        fields={[
                          { label: 'Active', value: 1 },
                          { label: 'Inactive', value: 0 },
                        ]}
                        state={status}
                        id={`add_FA_program_status${idSuffix}`}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <ConfirmationPanel
                cancelButtonName="add_task_cancel_button"
                submitButtonName="add_task_submit_button"
                handleSubmit={handleSubmit(this.handleSubmit)}
                handleCancel={cancel}
                disableSubmit={pristine || submitting}
              />
            </form>
          </Grid>
        </Grid>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { selectedPatientId, therapies } = state;
  const formValues = getFormValues(form)(state);

  return {
    selectedPatientId,
    therapies: therapies.data,
    initialValues: {
      status: 1,
      limit: null,
      expiration_date: null,
      bin: null,
      pcn: null,
      group_id: null,
      phone_number: null,
      details: null,
      copay: null,
      rewards_amount: null,
    },
    formValues,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchFinancialAssistance,
      addFinancialAssistanceTask,
      addIncome,
      fetchIncomes,
      change: (fieldName, value) => dispatch(_change(form, fieldName, value)),
    },
    dispatch,
  );
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(reduxForm({ form })(AddPrescriptionAssistanceProgram));

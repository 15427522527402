import moment from 'moment';
import React, { FC, useState, useEffect, Fragment } from 'react';

import {
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Grid,
  IconButton,
  Collapse,
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';

import { EditAdd } from 'components/icons/icons';
import { convertToArborDate } from 'models/time/arbor-date';
import TruncatedLabel from 'components/truncated-label';
import { DUR_VACCINATION_FORM } from 'constants/index';

import { buildQaId } from 'utils/build-qa-id';
import { IProps, IVaccinationRow, IVaccinationRowsGroup } from './interfaces';
import { styles } from './vaccinations-table.styles';

import VaccinationFormDialog from '../form-dialog/vaccinations-form-dialog';

const qaIdBuilder = buildQaId('vaccinations-table');

const VaccinationsTable: FC<IProps> = (props: IProps): JSX.Element => {
  const { classes, vaccinationRows, showAddButton = true } = props;

  const [openAddModal, setOpenAddModal] = useState<boolean>(false);

  const [vaccineGroups, setVaccineGroups] = useState<Record<string, IVaccinationRowsGroup>>({});

  const groupVaccinesByCategory = (
    vaccinations: IVaccinationRow[],
  ): Record<string, IVaccinationRow[]> => {
    const categories: Record<string, IVaccinationRow[]> = {};

    for (const vaccination of vaccinations) {
      if (!categories[vaccination.category]) {
        categories[vaccination.category] = [vaccination];
      } else {
        categories[vaccination.category].push(vaccination);
      }
    }
    return categories;
  };

  const buildAndSetGroupedRows = (categories: Record<string, IVaccinationRow[]>): void => {
    const vaccineGroups: Record<string, IVaccinationRowsGroup> = {};

    for (const category of Object.keys(categories)) {
      const vaccinesSortedByDate = categories[category].sort(
        (firstVaccination: IVaccinationRow, secondVaccination: IVaccinationRow) => {
          return (
            moment(secondVaccination.dateReceived).valueOf() -
            moment(firstVaccination.dateReceived).valueOf()
          );
        },
      );
      // Building row groups by category
      vaccineGroups[category] = {
        collapsed: vaccinesSortedByDate.length > 1 ? false : undefined,
        newest: vaccinesSortedByDate[0],
        oldests: vaccinesSortedByDate.slice(1, vaccinesSortedByDate.length),
      };
    }

    setVaccineGroups(vaccineGroups);
  };

  useEffect(() => {
    buildAndSetGroupedRows(groupVaccinesByCategory(vaccinationRows));
  }, [vaccinationRows]);

  const toggleCollapseRowGroupByCategory = (vaccineGroupKey: string) => () => {
    setVaccineGroups({
      ...vaccineGroups,
      [vaccineGroupKey]: {
        ...vaccineGroups[vaccineGroupKey],
        collapsed: !vaccineGroups[vaccineGroupKey].collapsed,
      },
    });
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const renderOuterRow = (
    category: string,
    rowGroup: IVaccinationRowsGroup,
    index: number,
  ): JSX.Element => {
    return (
      <TableRow
        className={rowGroup.collapsed ? classes.mainExpandedRow : ''}
        data-qa-id={qaIdBuilder(`vaccination-outer-row-${index}`)}
      >
        <TableCell align="left">
          <IconButton size="small" onClick={toggleCollapseRowGroupByCategory(category)}>
            {rowGroup.collapsed !== undefined ? (
              rowGroup.collapsed ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )
            ) : (
              ''
            )}
          </IconButton>
          <TruncatedLabel display="inline" text={category} maxChars={20} />
        </TableCell>
        <TableCell align="center">
          <TruncatedLabel text={rowGroup.newest.vaccine} maxChars={20} />
        </TableCell>
        <TableCell align="center">{rowGroup.newest.type}</TableCell>
        <TableCell align="center">{rowGroup.newest.effectiveFor || '-'}</TableCell>
        <TableCell align="center">
          {convertToArborDate(rowGroup.newest.dateReceived).getCustomerDate(true)}
        </TableCell>
        <TableCell align="center">{rowGroup.newest.source?.toUpperCase() || '-'}</TableCell>
      </TableRow>
    );
  };

  const renderInnerRows = (rowGroup: IVaccinationRowsGroup): JSX.Element => {
    return (
      <>
        {rowGroup.oldests.map(row => (
          <TableRow className={classes.noBorderRow}>
            <TableCell align="left" className={classes.collapsedInnerHiddenCell} />
            <TableCell
              align="center"
              className={
                !rowGroup.collapsed ? classes.collapsedInnerCell : classes.expandedInnerCell
              }
            >
              <Collapse in={rowGroup.collapsed} timeout="auto" unmountOnExit mountOnEnter>
                <TruncatedLabel text={row.vaccine} maxChars={20} />
              </Collapse>
            </TableCell>
            <TableCell
              align="center"
              className={
                !rowGroup.collapsed ? classes.collapsedInnerCell : classes.expandedInnerCell
              }
            >
              <Collapse in={rowGroup.collapsed} timeout="auto" unmountOnExit mountOnEnter>
                {row.type}
              </Collapse>
            </TableCell>
            <TableCell
              align="center"
              className={
                !rowGroup.collapsed ? classes.collapsedInnerCell : classes.expandedInnerCell
              }
            >
              <Collapse in={rowGroup.collapsed} timeout="auto" unmountOnExit mountOnEnter>
                {row.effectiveFor || '-'}
              </Collapse>
            </TableCell>
            <TableCell
              align="center"
              className={
                !rowGroup.collapsed ? classes.collapsedInnerCell : classes.expandedInnerCell
              }
            >
              <Collapse in={rowGroup.collapsed} timeout="auto" unmountOnExit mountOnEnter>
                {convertToArborDate(row.dateReceived).getCustomerDate(true)}
              </Collapse>
            </TableCell>
            <TableCell
              align="center"
              className={
                !rowGroup.collapsed ? classes.collapsedInnerCell : classes.expandedInnerCell
              }
            >
              <Collapse in={rowGroup.collapsed} timeout="auto" unmountOnExit mountOnEnter>
                {row.source?.toUpperCase() || '-'}
              </Collapse>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <Grid container>
      {showAddButton && (
        <Grid item xs={12} className={classes.addButtonWrapper}>
          <VaccinationFormDialog
            form={DUR_VACCINATION_FORM}
            open={openAddModal}
            handleCloseModal={handleCloseAddModal}
          />
          <Button
            name="add_vaccination_button"
            id="add_vaccination_button"
            variant="outlined"
            onClick={handleOpenAddModal}
          >
            <EditAdd />
            <Typography variant="body2">New</Typography>
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        {vaccinationRows.length ? (
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Category</TableCell>
                  <TableCell align="center">Vaccine</TableCell>
                  <TableCell align="center">Type</TableCell>
                  <TableCell align="center">Effective for</TableCell>
                  <TableCell align="center">Date Received</TableCell>
                  <TableCell align="center">Source</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(vaccineGroups).map((category: string, index: number) => (
                  <Fragment key={category}>
                    {renderOuterRow(category, vaccineGroups[category], index)}
                    {renderInnerRows(vaccineGroups[category])}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Grid item xs={12}>
            <Paper elevation={0} className={classes.emptyMessageContainer}>
              <Typography variant="body1" className={classes.emptyMessage}>
                No known vaccinations
              </Typography>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(VaccinationsTable);

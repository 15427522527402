import { Action } from 'redux';

import {
  TOGGLE_TASKLIST_SORT,
  TOGGLE_PER_PROTOCOL_FILTER,
  SET_TASKLIST_SORT,
} from '../constants/index';

// call with `null` to clear the sort
// else, toggle the sort on the given field
export class ToggleTasklistSort implements Action {
  readonly type = TOGGLE_TASKLIST_SORT;

  constructor(public payload: string) {}
}

export class SetTasklistSort implements Action {
  readonly type = SET_TASKLIST_SORT;

  constructor(public payload: { orderBy: string; order: 'asc' | 'desc' }) {}
}

export class TogglePerProtocolFilter implements Action {
  readonly type = TOGGLE_PER_PROTOCOL_FILTER;

  constructor(public payload: boolean) {}
}

export type TaskListSortAction = ToggleTasklistSort | TogglePerProtocolFilter | SetTasklistSort;

import React, { useRef, useEffect, useState } from 'react';
import cx from 'classnames';
import { useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Button from './button';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  limits: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    alignItems: 'center',
    [`${theme.breakpoints.down('xl')} and (orientation: landscape)`]: {
      marginBottom: -46,
    },
  },
  min: {
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.white,
    zIndex: 1,
    paddingRight: 10,
  },
  max: {
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.white,
    zIndex: 1,
    paddingLeft: 10,
  },
  limitNumber: {
    color: theme.palette.primary.grey2,
  },
  limitText: {
    fontSize: 13,
    maxWidth: 142,
  },
  slider: {
    display: 'block',
    overflowX: 'auto',
    scrollSnapType: 'x mandatory',
    scrollBehavior: 'smooth',
    whiteSpace: 'nowrap',
    maxWidth: 500,
    [theme.breakpoints.down('xl')]: {
      maxWidth: 300,
    },
    margin: '0 auto 40px auto',
  },
  option: {
    padding: '20px 0',
    fontSize: 26,
    background: 'none',
    border: 0,
    scrollSnapAlign: 'start',
    width: '20%',
    display: 'inline-block',
    boxSizing: 'border-box',
    cursor: 'pointer',
    fontWeight: 300,
  },
  optionWithDecimals: {
    'padding': '20px 0',
    'fontSize': 20,
    'background': 'none',
    'border': 0,
    'scrollSnapAlign': 'start',
    'width': '20%',
    'display': 'inline-block',
    'boxSizing': 'border-box',
    'cursor': 'pointer',
    'fontWeight': 300,
    '&:nth-of-type(odd)': {
      fontSize: 26,
    },
  },
  selected: {
    width: 65,
    height: 65,
    margin: '0 auto -68px auto',
    borderColor: theme.palette.primary.teal,
    border: '1px solid',
    borderRadius: 65,
    backgroundColor: theme.palette.primary.teal,
    [theme.breakpoints.down('xl')]: {
      width: 50,
      height: 50,
      margin: '0 auto -60px auto',
    },
  },
  chevron: {
    'height': 50,
    'width': 20,
    'overflow': 'hidden',
    'position': 'relative',
    'marginRight': 10,
    'background': 'none',
    'border': 0,
    'cursor': 'pointer',
    '&::before, &:after': {
      borderRadius: 20,
      content: '""',
      position: 'absolute',
      width: 28,
      height: 3,
      background: theme.palette.primary.teal,
      left: 0,
    },
    '&::before': {
      transformOrigin: '50% 50%',
      transform: 'rotate(-70deg)',
      top: 11,
    },
    '&::after': {
      transformOrigin: '50% 50%',
      transform: 'rotate(70deg)',
      bottom: 11,
    },
    '&[disabled]': {
      'cursor': 'default',
      '&::before, &:after': {
        background: theme.palette.primary.grey12,
      },
    },
  },
  rightChevron: {
    transform: 'rotate(180deg)',
    margin: '0 0 0 10px',
  },
  active: {
    'cursor': 'pointer',
    '&::before, &:after': {
      background: theme.palette.primary.teal,
    },
  },
}));

export default ({
  options,
  onChange,
  initialValue,
  minText,
  maxText,
  selectButtonText,
  questionNumber,
}) => {
  const classes = useStyles();
  const slider = useRef();
  const [value, setValue] = useState(initialValue);

  const amount1 = useMediaQuery('(max-width:350px)') ? 1 : 0;
  const amount2 = useMediaQuery('(max-width:600px)') ? 2 : 0;

  const amount = amount1 || amount2 || 3;
  const getCellSize = () => slider.current.offsetWidth / (amount * 2 + 1);

  const getIndex = v => options.findIndex(o => String(o.value) === String(v || 0));

  useEffect(() => {
    const cellSize = getCellSize();
    const index = getIndex(initialValue);
    slider.current.scrollLeft = cellSize * index;
  }, []);

  const select = () => {
    const cellSize = getCellSize();
    const index = Math.round(slider.current.scrollLeft / cellSize);
    onChange(options[index].value);
  };

  useEffect(() => {
    const cellSize = getCellSize();
    const index = getIndex(value);
    slider.current.scrollLeft = cellSize * index;
  }, [value, amount]);

  const optionStyle = {
    width: `${Math.round(10000 / (amount * 2 + 1)) / 100}%`,
  };

  const canMove = ticks => {
    const index = getIndex(value) + ticks;
    return index >= 0 && index < options.length;
  };

  const canClickPrev = () => canMove(-1);

  const canClickNext = () => canMove(1);

  const move = ticks => {
    const index = getIndex(value) + ticks;
    setValue(options[index].value);
  };

  const prev = () => canClickPrev() && move(-1);

  const next = () => canClickNext() && move(1);

  const containsDecimals = (options || []).some(option => !Number.isInteger(Number(option.label)));
  const optionClass = containsDecimals ? classes.optionWithDecimals : classes.option;

  return (
    <div className={classes.container}>
      <div className={classes.limits}>
        <div className={classes.min}>
          <button
            type="button"
            className={cx(classes.chevron, classes.leftChevron)}
            disabled={!canClickPrev()}
            onClick={() => prev()}
            data-qa-id={`question_${questionNumber}_prev`}
          />
          <div>
            <div className={classes.limitNumber}>{options[0].label}</div>
            <div className={classes.limitText}>{minText}</div>
          </div>
        </div>
        <div className={classes.max}>
          <div>
            <div className={classes.limitNumber}>{options[options.length - 1].label}</div>
            <div className={classes.limitText}>{maxText}</div>
          </div>
          <button
            type="button"
            className={cx(classes.chevron, classes.rightChevron)}
            disabled={!canClickNext()}
            onClick={() => next()}
            data-qa-id={`question_${questionNumber}_next`}
          />
        </div>
      </div>
      <div className={classes.selected} />
      <div className={classes.slider} ref={slider}>
        {new Array(amount).fill().map((item, index) => (
          <span
            key={index} // eslint-disable-line react/no-array-index-key
            className={optionClass}
            style={optionStyle}
          />
        ))}
        {(options || []).map(option => (
          <button
            key={option.value}
            type="button"
            className={optionClass}
            style={optionStyle}
            onClick={() => {
              setValue(option.value);
            }}
            data-qa-id={`question_${questionNumber}_button_${option.value}`}
          >
            {option.label}
          </button>
        ))}
        {new Array(amount).fill().map((item, index) => (
          <span
            key={index} // eslint-disable-line react/no-array-index-key
            className={optionClass}
            style={optionStyle}
          />
        ))}
      </div>
      <Button onClick={() => select()} data-qa-id={`question_${questionNumber}_select`}>
        {selectButtonText || 'Select'}
      </Button>
    </div>
  );
};

import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

export const styles = (theme: Theme) => {
  const colorPalette = theme.palette.primary as any;
  const fonts = (theme as any).font;

  return createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: '50%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    divRow: {
      display: 'flex',
      justifyContent: 'space-around',
    },
    input: {
      display: 'flex',
      padding: 0,
    },
    header: {
      fontSize: fonts.largeFontSize,
      fontWeight: 'bold',
    },
    headerWrapper: {
      backgroundColor: colorPalette.grey2,
    },
    subHeader: {
      borderLeft: '6px solid',
      borderColor: colorPalette.main,
      fontSize: fonts.largeFontSize,
      fontWeight: 'bold',
      paddingLeft: 15,
    },
    headerGridItem: {
      paddingBottom: 13,
    },
    spacingContainer: {
      margin: 0,
      width: '100%',
    },
    scrollingColumn: {
      overflowY: 'scroll',
      height: 700,
      padding: 5,
    },
    rightColumn: {
      backgroundColor: colorPalette.grey2,
    },
    confirmationContainer: {
      borderTop: `1px solid ${colorPalette.lightGrey}`,
    },
    benefitsInvestigationButton: {
      'backgroundColor': colorPalette.task.checked,
      'textTransform': 'none',
      'color': colorPalette.white,
      'fontWeight': 'unset',
      'boxShadow': 'none',
      '&:hover': {
        backgroundColor: colorPalette.task.borderHighlightColor,
        borderColor: 'none',
        boxShadow: 'none',
      },
    },
    benefitsInvestigationSaveButton: {
      'backgroundColor': colorPalette.task.checked,
      'margin': theme.spacing(1),
      'marginLeft': 0,
      '&:hover': {
        backgroundColor: colorPalette.task.borderHighlightColor,
      },
    },
    hide: {
      display: 'none',
    },
    tableHeader: {
      backgroundColor: colorPalette.trellisLightBlue,
    },
    softwareCertMsgItem: {
      paddingTop: 0,
    },
    noDursWording: {
      textAlign: 'center',
      padding: '10px 0',
    },
    cancelButton: {
      margin: theme.spacing(1),
      marginLeft: 0,
    },
    saveButton: {
      margin: theme.spacing(1),
      marginLeft: 0,
    },
    loading: {
      marginLeft: 10,
      color: colorPalette.white,
    },
    confirmationPanel: {
      marginTop: 10,
    },
    daySupplyConflict: {
      '& p, input, div': {
        color: colorPalette.darkRed,
        borderColor: colorPalette.darkRed,
      },
    },
    daysSupplyInfo: {
      float: 'left',
      position: 'relative',
      top: theme.spacing(-8),
      paddingLeft: theme.spacing(12),
      cursor: 'pointer',
      color: colorPalette.rollingStone,
    },
    errorMissingPhysician: {
      color: colorPalette.darkRed,
      textAlign: 'right',
    },
  });
};

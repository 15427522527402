export const getForm = (forms, formName) => forms[formName];

// eslint-disable-next-line no-confusing-arrow
export const getProvider = (providers, name) => (providers ? providers[name] : null);

export const processDisplayFormat = field => field.displayFormat;

export const process = jsonForm =>
  jsonForm?.forms?.reduce((forms, formConfig) => {
    forms[formConfig.name] = {
      ...formConfig,
      fields: formConfig?.fields?.map(fieldConfig => {
        const field = jsonForm?.fields?.find(f => f.id === fieldConfig.id);
        const newField = {
          ...field,
          ...fieldConfig,
          meta: {
            ...(field || {}).meta,
            ...fieldConfig.meta,
          },
        };
        newField.displayFormat = processDisplayFormat(newField);
        return newField;
      }),
      statuses: jsonForm.statuses,
      allFields: jsonForm.fields,
    };
    return forms;
  }, {});

export const filterObjectFields = (
  data,
  fields,
  withValueOnly, // eslint-disable-line
) =>
  fields.reduce((result, field) => {
    // eslint-disable-line
    if (!withValueOnly || (data[field] !== undefined && data[field] !== null)) {
      result[field] = data[field];
    }
    return result;
  }, {});

import React from 'react';
import { Checkbox, CheckboxProps } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { defaultTheme } from '../../lib/themes';

interface IArborCheckboxProps extends CheckboxProps {
  ['data-qa-id']?: string;
}

export const ArborCheckbox = withStyles({
  root: {
    'paddingTop': 0,
    'paddingBottom': 0,
    // BM: Not ideal, but it worked
    'marginTop': -3,
    '&.Mui-checked': {
      'color': defaultTheme.palette.primary.teal,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checked: {},
  indeterminate: {
    color: defaultTheme.palette.primary.teal,
  },
})((props: IArborCheckboxProps) => (
  <Checkbox size="small" color="primary" disableRipple disableFocusRipple {...props} />
));

import { history } from './history';
import configureStore from './configureStore';

const store = configureStore({
  history,
  location: history.location,
});

export default store;

// https://redux.js.org/tutorials/typescript-quick-start#define-root-state-and-dispatch-types
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

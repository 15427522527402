import moment from 'moment';
import React, { FC, useState } from 'react';
import useHasRole from 'hooks/useHasRole';
import { buildQaIdProp } from 'utils/build-qa-id';
import { convertToArborDate, datetimeFormat } from 'models/time/arbor-date';
import { EditHistory, EditPencil, LinkIcon, TrellisLogo } from 'components/icons/icons';
import { getActionedByUserText } from 'utils/user-utils';
import { getUserById } from 'services/utils/users-service';
import { IPatientProblem } from 'interfaces/redux/IPatient';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { showMedicationTrellisIcon } from 'services/utils/medication-service';
import { updateProblemsVerificationStatus } from 'actions/action-patient';
import { useDispatch } from 'react-redux';
import { TherapyTypes, UserRoles } from 'constants/enums';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { verifyPatientProblems } from 'services/utils/patient-problems-service';
import { windowFeatureIsEnabled } from 'config/window-features';
import {
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  IconButton,
  Collapse,
  Button,
  Tooltip,
  Divider,
  List,
  ListItem,
  ListItemText,
  TableSortLabel,
  Box,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import { ITherapy } from 'interfaces/redux/ITherapy';
import { ITherapyType } from 'interfaces/redux/ILookups';
import classNames from 'classnames';
import { styles } from './list.styles';
import { logger } from '../../winston-logger';
import { IListProps } from './interfaces';
import { buildInitialRowStatusForProblemsList, parseClinicalDataName } from './utils';

const problemStatusText = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
} as const;

const emptyDateLabel = '--/--/--';

const qaIdProp = buildQaIdProp('problems-list');

type Order = 'asc' | 'desc';

function descendingComparator(a: IPatientProblem, b: IPatientProblem, orderBy: HeaderSortKey) {
  switch (orderBy) {
    case 'problem': {
      const problemA = (a.diagnosis.description ?? '').toLowerCase();
      const problemB = (b.diagnosis.description ?? '').toLowerCase();

      if (problemB < problemA) {
        return -1;
      }
      if (problemB > problemA) {
        return 1;
      }
      return 0;
    }
    case 'onset_date': {
      const bObj = (b[orderBy] ?? '').toLowerCase();
      const aObj = (a[orderBy] ?? '').toLowerCase();

      if (bObj < aObj) {
        return -1;
      }
      if (bObj > aObj) {
        return 1;
      }
      return 0;
    }
    default: {
      const bObj = b[orderBy] ?? false;
      const aObj = a[orderBy] ?? false;

      if (bObj < aObj) {
        return -1;
      }
      if (bObj > aObj) {
        return 1;
      }
      return 0;
    }
  }
}

function getComparator(
  order: Order,
  orderBy: HeaderSortKey,
): (a: IPatientProblem, b: IPatientProblem) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

type HeaderSortKey = 'problem' | 'onset_date' | 'active';

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const ProblemsList: FC<IListProps> = (props: IListProps): JSX.Element => {
  const { classes, readOnly = false } = props;

  const dispatch = useDispatch();
  const canVerifyList = useHasRole(
    UserRoles.SuperUser,
    UserRoles.CredentialedPharmacist,
    UserRoles.SiteManager,
  );

  // #region state
  const [isVerifying, setIsVerifying] = useState<boolean>(false);
  const [openRowsProblemIds, setOpenRowsProblemIds] = useState<Record<number, boolean>>(
    buildInitialRowStatusForProblemsList(props.problems),
  );

  const patientId = useTypedSelector(state => state.patient?.id);
  const therapies = useTypedSelector(state => state.therapies.data);

  const patient = useTypedSelector(state => state.patient);
  const users = useTypedSelector(state => state.lookups.users);
  const currentUser = useTypedSelector(state => state.auth.currentUser);

  const therapyTypes = useTypedSelector(state => state.lookups.therapyTypes);

  const areProblemsVerified = Boolean(patient?.problems_verified);

  const problemsVerifiedById = patient?.problems_verified_by;
  const problemsVerifiedDateTime = patient?.problems_verified_dt;

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<HeaderSortKey>('problem');

  // #endregion state

  // #region handlers
  const verificationHandler = async () => {
    setIsVerifying(true);
    try {
      await verifyPatientProblems(patientId);
      dispatch(
        updateProblemsVerificationStatus(true, currentUser.id, moment().format(datetimeFormat)),
      );
      dispatch(notifySuccess('Problems List verified successfully'));
    } catch (error) {
      dispatch(notifyError('Failed verifying problems list'));
      logger.error(error);
    } finally {
      setIsVerifying(false);
    }
  };

  const toggleProblemRow = (problemId: number) => {
    setOpenRowsProblemIds({
      ...openRowsProblemIds,
      [problemId]: !openRowsProblemIds[problemId],
    });
  };

  const buildVerifiedLabel = (): string => {
    return getActionedByUserText({
      action: 'RPH Reviewed',
      user: getUserById(problemsVerifiedById, users),
      date: problemsVerifiedDateTime,
      dateFormat: 'MM/DD/YYYY',
    });
  };

  const buildDiagnosisOrderLabel = (
    therapy: ITherapy,
    diagnosisCode: string,
  ): string | undefined => {
    let result;
    switch (diagnosisCode) {
      case therapy.diagnosis_code:
        result = '1st';
        break;
      case therapy.secondary_diagnosis_code:
        result = '2nd';
        break;
      case therapy.tertiary_diagnosis_code:
        result = '3rd';
        break;
      default:
        break;
    }
    return result;
  };

  // #endregion handlers

  // #region renderers
  const renderRow = (problem: IPatientProblem, index: number) => {
    const problemIsLinkedToTherapy = Boolean(problem.therapies?.length);
    const problemIsExpanded = openRowsProblemIds[problem.id];
    const hasCdmEncounter = problem.cdm_encounter?.id;
    const showTrellisIcon =
      problemIsLinkedToTherapy &&
      (problem.therapies || []).some(therapy => showMedicationTrellisIcon(therapies[therapy.id]));
    const tags: Array<{ text: string; description: string }> = [];
    const tagsText: Set<string> = new Set();

    if (problemIsLinkedToTherapy) {
      (problem.therapies || []).forEach(therapy => {
        const fullTherapy = therapies[therapy.id];
        if (!!fullTherapy && fullTherapy.therapy_type !== TherapyTypes.NonSpecialty) {
          const therapyTypeName = therapyTypes.find(
            (therapyType: ITherapyType) => therapyType.id === fullTherapy.therapy_type,
          )?.type;
          if (therapyTypeName != null && !tagsText.has(therapyTypeName)) {
            tags.push({
              text: therapyTypeName,
              description: therapyTypeName,
            });
            tagsText.add(therapyTypeName);
          }
        }
      });
    }
    return (
      <React.Fragment key={index}>
        <TableRow selected={problemIsExpanded} {...qaIdProp(`row-${problem.diagnosis.code}`)}>
          {problemIsLinkedToTherapy ? (
            <TableCell align="left" className={classes.narrowColumn}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => toggleProblemRow(problem.id)}
              >
                {problemIsExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          ) : (
            <TableCell />
          )}
          <TableCell align="left" {...qaIdProp('icd-10')}>
            {problemIsLinkedToTherapy ? (
              <Grid container spacing={1} className={classes.diagnosisContainer}>
                <Typography className={classes.diagnosisCellSpeciality}>
                  {`${problem.diagnosis.code} - ${problem.diagnosis.description}`}
                </Typography>
                <Typography component="span" className={classes.trellisIconDisplayFlex}>
                  {showTrellisIcon && (
                    <Typography>
                      <Tooltip title="Managed by Trellis">
                        <TrellisLogo
                          width={22}
                          height={22}
                          onClick={(e: any) => e.preventDefault()}
                        />
                      </Tooltip>
                    </Typography>
                  )}
                  {tags.map((tag: { text: string; description: string }, index: number) => (
                    <Tooltip title={tag.description} key={index}>
                      <Typography
                        component="span"
                        key="is_specialty"
                        className={classNames(classes.specialtyTag, tag.text)}
                      >
                        {tag.text}
                      </Typography>
                    </Tooltip>
                  ))}
                </Typography>
              </Grid>
            ) : (
              <Typography className={classes.diagnosisCell}>
                {`${problem.diagnosis.code} - ${problem.diagnosis.description}`}
              </Typography>
            )}
          </TableCell>
          <TableCell align="center" {...qaIdProp('onset-date')}>
            {problem.onset_date ?? emptyDateLabel}
          </TableCell>
          <TableCell align="center" {...qaIdProp('status')}>
            {problem.active ? problemStatusText.ACTIVE : problemStatusText.INACTIVE}
          </TableCell>
          <TableCell className={classes.narrowColumn} align="center">
            <IconButton
              size="small"
              aria-label="history"
              onClick={() => props.onClickOpenHistory(problem)}
              {...qaIdProp('see-history')}
            >
              <EditHistory />
            </IconButton>
          </TableCell>
          <TableCell align="center">
            <IconButton
              size="small"
              aria-label="edit"
              onClick={() => props.onClickEditRow(problem)}
              {...qaIdProp('edit')}
            >
              <EditPencil />
            </IconButton>
          </TableCell>
        </TableRow>
        {problemIsLinkedToTherapy && problemIsExpanded && (
          <TableRow>
            <TableCell className={classes.collapsableCell} colSpan={6}>
              <Collapse in timeout="auto" unmountOnExit>
                {hasCdmEncounter && windowFeatureIsEnabled('cdm_customer') && (
                  <>
                    <Grid container spacing={1} className={classes.collapsableContent}>
                      <Grid item xs={4}>
                        <Typography className={classes.patientGoalsTitle}>Patient Goals</Typography>
                        <List>
                          {(problem.cdm_encounter?.graduation.conditions || []).map(
                            (condition, index) => {
                              const parsedClinicalData = parseClinicalDataName(
                                condition.clinical_data_type.name,
                              );

                              return (
                                <ListItem className={classes.patientGoals} key={index}>
                                  <ListItemText className={classes.patientGoals}>
                                    {parsedClinicalData?.name || '-'}
                                  </ListItemText>
                                  <ListItemText className={classes.patientGoals}>
                                    {`${condition.clinical_data_value || ''} ${
                                      parsedClinicalData?.unit || ''
                                    }`}
                                  </ListItemText>
                                </ListItem>
                              );
                            },
                          )}
                        </List>
                      </Grid>
                      <Grid item xs={2}>
                        {problem.cdm_encounter?.enrollment?.date ? (
                          <Typography component="div">
                            <Typography className={classes.programNameLabel}>
                              {`[${problem.cdm_encounter?.specialty_type.name}]`}
                            </Typography>
                            <Typography className={classes.patientGoalsDateTitle}>
                              Enrollment Date
                            </Typography>
                            <Typography className={classes.patientGoalsDate}>
                              {convertToArborDate(
                                problem.cdm_encounter.enrollment.date,
                              ).getCustomerDate(true)}
                            </Typography>
                          </Typography>
                        ) : null}
                      </Grid>

                      <Grid item xs={2}>
                        {problem.cdm_encounter?.graduation?.date ? (
                          <Typography component="div" className={classes.graduationWrapper}>
                            <Typography className={classes.patientGoalsDateTitle}>
                              Graduation Date
                            </Typography>
                            <Typography className={classes.patientGoalsDate}>
                              {convertToArborDate(
                                problem.cdm_encounter.graduation.date,
                              ).getCustomerDate(true)}
                            </Typography>
                          </Typography>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Divider />
                  </>
                )}
                <Grid container spacing={2} className={classes.collapsableContent}>
                  <Grid item xs={12}>
                    <Typography className={classes.linkedTherapiesTitle}>
                      Linked Therapies
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {(problem.therapies || [])
                        .filter(therapy => therapy.active === true)
                        .map((therapy, therapyIndex) => (
                          <Grid item xs={2} key={therapyIndex}>
                            <Button
                              disabled
                              color="primary"
                              variant="outlined"
                              className={classes.therapyLinkIndicator}
                              aria-label={therapies[therapy.id]?.drug_name}
                            >
                              <LinkIcon />
                              <span className={classes.therapyDrugName}>
                                {therapies[therapy.id]?.drug_name}
                              </span>
                              <Typography component="span" className={classes.diagnosisOrderTag}>
                                {buildDiagnosisOrderLabel(
                                  therapies[therapy.id],
                                  problem.diagnosis?.code,
                                )}
                              </Typography>
                            </Button>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  };

  const handleRequestSort = (event: any, property: HeaderSortKey) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: HeaderSortKey) => (event: any) => {
    handleRequestSort(event, property);
  };
  const tableSortLabelComponent = (header: string, headerKey: HeaderSortKey) => (
    <TableSortLabel
      active={orderBy === headerKey}
      direction={orderBy === headerKey ? order : 'asc'}
      onClick={createSortHandler(headerKey)}
    >
      {header}
      {orderBy === header ? (
        <Box component="span" sx={{ display: 'none' }}>
          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        </Box>
      ) : null}
    </TableSortLabel>
  );
  return (
    <Grid container>
      {props.problems?.length ? (
        <Grid item xs={12}>
          <TableContainer component={Paper} className={classes.table}>
            <Table {...qaIdProp('table')}>
              <TableHead>
                <TableRow>
                  <TableCell align="left" className={classes.narrowColumn} />
                  <TableCell align="left">
                    {tableSortLabelComponent('Problem', 'problem')}
                  </TableCell>
                  <TableCell align="center">
                    {tableSortLabelComponent('Onset Date', 'onset_date')}
                  </TableCell>
                  <TableCell align="center">
                    {tableSortLabelComponent('Status', 'active')}
                  </TableCell>
                  <TableCell align="center" className={classes.narrowColumn} />
                  {readOnly === false && (
                    <TableCell className={classes.narrowColumn} align="center" />
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(props.problems, getComparator(order, orderBy)).map((problem, index) =>
                  renderRow(problem, index),
                )}
              </TableBody>
            </Table>
            {/*
              Commenting out for now. This may come back at a later date when we get a problems list
              feed from EMR.

              ARBOR-6220
            */}
            {/*
            <ListVerification
              verifiedLabel={buildVerifiedLabel()}
              notVerifiedLabel="Needs RPH Verification"
              allowVerification={canVerifyList}
              verificationHandler={verificationHandler}
              isVerified={areProblemsVerified}
              isVerifying={isVerifying}
              qaIdPrefix="problems-list"
            /> */}
          </TableContainer>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.emptyMessageContainer}>
            <Typography variant="body1" className={classes.emptyMessage}>
              No known problems
            </Typography>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
  // #endregion renderers
};

export default withStyles(styles)(ProblemsList);

import HTTP from 'services/http';
import { convertToArborDate } from 'models/time/arbor-date';
import { stripFormattedDollarValue } from 'services/utils/formatting';
import { AUTOMATION_USER_ID } from '../../constants';

export const getInsuranceAutogeneratedWarning = insurance => {
  const isGenerated =
    (insurance.created_by === AUTOMATION_USER_ID || insurance.updated_by === AUTOMATION_USER_ID) &&
    insurance.is_verified === 0;
  return isGenerated
    ? 'This information was generated from the patients pharmacy benefit Eligibility Check, Please verify/update if needed.'
    : '';
};

export const getMedicalInsuranceHeader = (medicalInsurance, isNoInsurance = false) => {
  if (medicalInsurance) {
    if (isNoInsurance) {
      return 'Medical Benefit - None';
    }
    return `Medical Benefit - ${medicalInsurance.insurance_type} - ${medicalInsurance.plan_name} (${
      medicalInsurance.start_date === null
        ? 'Unknown'
        : convertToArborDate(medicalInsurance.start_date, true).getUtcDate(true)
    } -
    ${
      medicalInsurance.end_date === null
        ? 'Present'
        : convertToArborDate(medicalInsurance.end_date, true).getUtcDate(true)
    })`;
  }
  return '';
};

export const getPharmacyInsuranceHeader = (pbmInsurance, isNoInsurance = false) => {
  if (pbmInsurance) {
    if (isNoInsurance) {
      return 'Pharmacy Benefit - None';
    }
    return `Pharmacy Benefit - ${pbmInsurance.insurance_type} - ${pbmInsurance.pbm_name || ''} (${
      pbmInsurance.start_date === null
        ? 'Unknown'
        : convertToArborDate(pbmInsurance.start_date, true).getUtcDate(true)
    } -
    ${
      pbmInsurance.end_date === null
        ? 'Present'
        : convertToArborDate(pbmInsurance.end_date, true).getUtcDate(true)
    })`;
  }
  return '';
};

export const totalSumIncome = (incomes, input) => {
  let sumIncome = 0;
  if (incomes && input) {
    incomes[input.name] = input.value;
    const incomeKeys = Object.keys(incomes);
    for (let i = 0; i < incomeKeys.length; i += 1) {
      if (incomes[incomeKeys[i]]) {
        const parsedIncome = Number(stripFormattedDollarValue(incomes[incomeKeys[i]]));
        sumIncome += parsedIncome;
      }
    }
  }
  return { incomes, sumIncome };
};

export const getPBM = (id, patientId, pbmInsurances) =>
  pbmInsurances &&
  id &&
  patientId &&
  pbmInsurances.find(o => o.id === id && o.patient_id === patientId);

export const mapToIdNameInsurance = (id, name, nameSecondary) => it => ({
  name: `${it[name]} - ${it[nameSecondary]}`,
  id: it[id],
});

export const mapToIdNameInsuranceLabelValue = (id, name, nameSecondary) => it => ({
  label: `${it[name]} - ${it[nameSecondary]}`,
  value: it[id],
});

export const getMedicalInsuranceArrForDropdown = state => {
  let medicalInsuranceArr = [];
  if (
    state &&
    state.financials &&
    state.financials.medical_insurances &&
    state.financials.medical_insurances.data &&
    Object.keys(state.financials.medical_insurances).map(
      i => state.financials.medical_insurances.data[i],
    ).length > 0
  ) {
    medicalInsuranceArr = Object.keys(state.financials.medical_insurances.data).map(
      i => state.financials.medical_insurances.data[i],
    );
    medicalInsuranceArr = medicalInsuranceArr.filter(i => i.valid);
  }
  return medicalInsuranceArr;
};

export const getPbmInsuranceArrForDropdown = state => {
  let pbmInsuranceArr = [];
  if (
    state &&
    state.financials &&
    state.financials.pbm_insurances &&
    state.financials.pbm_insurances.data &&
    Object.keys(state.financials.pbm_insurances).map(i => state.financials.pbm_insurances.data[i])
      .length > 0
  ) {
    pbmInsuranceArr = Object.keys(state.financials.pbm_insurances.data).map(
      i => state.financials.pbm_insurances.data[i],
    );
    pbmInsuranceArr = pbmInsuranceArr.filter(i => i.valid);
  }
  return pbmInsuranceArr;
};

export const initialInsurancesForDropdown = (valuesStr, lookupArr) => {
  let initialInsurances = [];
  if (lookupArr) {
    initialInsurances = lookupArr.find(r => r.insurance_type_id === 1)
      ? String(lookupArr.find(r => r.insurance_type_id === 1).id)
      : '';
    if (valuesStr) {
      const id = valuesStr.split(',')[0];
      initialInsurances = lookupArr.find(r => String(r.id) === id) || {};
    }
  }
  return initialInsurances;
};

export const findInsuranceTypeName = ({ pcn, bin, group }) => {
  return HTTP.get(`/pbm-insurances/insurance-type?pcn=${pcn}&bin=${bin}&group=${group}`, {});
};

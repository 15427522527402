import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { expandTheme } from 'utils/theme-util';

export const DosingTooltipStyled = withStyles((theme: Theme) => ({
  tooltip: {
    'backgroundColor': '#464646bb',
    'opacity': 0.75,
    'color': 'white',
    'maxWidth': 246,
    'padding': theme.spacing(1),
    '& hr': {
      width: '100%',
      height: '0',
      opacity: 0.5,
      backgroundColor: 'white',
    },
    '& .bold': {
      fontWeight: 'Bold',
    },
    '& .info': {
      fontSize: theme.typography.pxToRem(14),
      textTransform: 'capitalize',
    },
    'lineHeight': '18px',
    'fontWeight': 500,
  },
}));

export const NoteTooltipStyled = withStyles((theme: Theme) => ({
  tooltip: {
    'backgroundColor': '#464646',
    'opacity': 0.75,
    'color': 'white',
    'maxWidth': 300,
    'fontSize': theme.typography.pxToRem(12),
    'padding': theme.spacing(1),
    '& hr': {
      width: '100%',
      opacity: 0.5,
      border: '1px solid #FFFFFF',
    },
    'lineHeight': '18px',
    'fontWeight': 500,
  },
}));

export const NoteContentStyled = withStyles((theme: Theme) => {
  const { palette } = expandTheme(theme);
  return {
    noteContainer: {
      fontWeight: theme.spacing(40.625),
      color: palette.primary.rollingStone,
      fontSize: theme.spacing(1.5),
      lineHeight: theme.spacing(2.25),
    },
    noteLine2: {
      fontWeight: theme.spacing(43.75),
      fontSize: theme.spacing(1.5),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'left',
      width: theme.spacing(37.5),
      whiteSpace: 'nowrap',
    },
    pin: {
      height: theme.spacing(2.25),
    },
  };
});

export interface Tab {
  id: string | number;
  label: string;
  icon: React.Component;
}

export type OnTabClose = (tab: Tab) => void;
export interface TabLabelProps {
  tab: Tab;
  onClose: OnTabClose;
  className: string;
}

export const MAX_LABEL_LENGTH = 18;

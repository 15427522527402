import {
  SCHEDULE_OUTREACH_STATUS_COMMUNICATION_PHONE_ID,
  SCHEDULE_OUTREACH_STATUS_COMMUNICATION_INPERSON_ID,
} from '../../../../constants/index';

export const COMMUNICATION_TYPES = [
  {
    value: Number(SCHEDULE_OUTREACH_STATUS_COMMUNICATION_PHONE_ID),
    label: 'Phone',
  },
  {
    value: Number(SCHEDULE_OUTREACH_STATUS_COMMUNICATION_INPERSON_ID),
    label: 'In Person',
  },
];

export const AR_REASON_OTHER_ID = 5;
export const FORM_NAME = 'add_appointment_referral_form';

import { Grid, Typography, Button, Divider } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { PinnedNoteIcon, ArchivedNoteIcon } from 'components/icons/icons';
import {
  Call,
  Email,
  Face,
  Sms,
  MailOutline,
  Print,
  Computer,
  Done,
  Warning,
  ArrowDropDown,
  ArrowRightAlt,
} from '@mui/icons-material';
import { NOTE_TYPES, noteCommunicationTypes } from 'constants/lists';
import { MESSAGE_TYPE } from 'constants/index';
import { getUserById } from 'services/utils/users-service';
import { getActionedByUserText } from 'utils/user-utils';
import Label from 'components/display/label';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { convertToArborDate } from 'models/time/arbor-date';
import { getNoteTagLabel, patientTaskOnlyTagCheck } from 'services/utils/note-service';
import classNames from 'classnames';
import { ReactSelectControlled } from 'components/form/field/react-select';
import { resolveCommunicationNote } from '../../actions/action-notes';
import { styles } from './note-styles';
import NoteArchiveForm from './note-archive-form';
import NotePinnedForm from './note-pinned-form';
import colors from '../../lib/themes/colors.module.scss';
import CommunicationBox from '../sms/CommunicationBox';

const { trellisBlue } = colors;

class NoteDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showArchivedNote: false,
      showPinnedOptions: false,
      popoverAnchor: null,
      communicationHoverOver: null,
    };
    this.toggleArchivedNote = this.toggleArchivedNote.bind(this);
    this.togglePinnedOptions = this.togglePinnedOptions.bind(this);
    this.openPopover = this.openPopover.bind(this);
    this.toggleCommunicationHoverOver = this.toggleCommunicationHoverOver.bind(this);

    this.closePopover = this.closePopover.bind(this);
  }

  toggleArchivedNote() {
    const { showPinnedOptions } = this.state;
    if (!showPinnedOptions) {
      this.setState(prevState => ({
        showArchivedNote: !prevState.showArchivedNote,
      }));
    } else {
      this.setState(prevState => ({
        showArchivedNote: !prevState.showArchivedNote,
        showPinnedOptions: false,
      }));
    }
  }

  togglePinnedOptions() {
    const { showArchivedNote } = this.state;
    if (!showArchivedNote) {
      this.setState(prevState => ({
        showPinnedOptions: !prevState.showPinnedOptions,
        popoverAnchor: false,
      }));
    } else {
      this.setState(prevState => ({
        showPinnedOptions: !prevState.showPinnedOptions,
        showArchivedNote: false,
        popoverAnchor: false,
      }));
    }
  }

  toggleCommunicationHoverOver() {
    const { note, resolveCommunicationNote: resolveCommunication } = this.props; //eslint-disable-line
    resolveCommunication(note).then(res => res);
    this.setState({
      communicationHoverOver: 'hide',
    });
  }

  openPopover() {
    this.setState({
      popoverAnchor: true,
    });
  }

  closePopover() {
    this.setState({
      popoverAnchor: false,
    });
  }

  render() {
    const { note, classes, inTreeView, patient, users } = this.props;
    const createdBy = getUserById(note.created_by, users);
    const { showArchivedNote, showPinnedOptions, popoverAnchor, communicationHoverOver } =
      this.state;
    const noteId = Number(note && note.communication ? note.communication.type_id : 0);
    let noteType;
    switch (noteId) {
      case NOTE_TYPES.in_person:
        noteType = (
          <Typography variant="caption" component="span" className={classes.communication}>
            <Face className={classes.communicationInlineIcon} />
            {note.note_display}
          </Typography>
        );
        break;
      case NOTE_TYPES.email:
        noteType = (
          <Typography variant="caption" component="span" className={classes.communication}>
            <Email className={classes.communicationInlineIcon} />
            {note.note_display}
          </Typography>
        );
        break;
      case NOTE_TYPES.sms:
        noteType = (
          <Typography variant="caption" component="span" className={classes.communication}>
            <Sms className={classes.communicationInlineIcon} />
            {note.note_display}
          </Typography>
        );
        break;
      case NOTE_TYPES.phone_call:
        noteType = (
          <Typography variant="caption" component="span" className={classes.communication}>
            <Call
              className={classes.communicationInlineIcon}
              style={{ transform: 'rotate(90deg)' }}
            />
            {note.note_display}
          </Typography>
        );
        break;
      case NOTE_TYPES.mail:
        noteType = (
          <Typography variant="caption" component="span" className={classes.communication}>
            <MailOutline className={classes.communicationInlineIcon} />
            {note.note_display}
          </Typography>
        );
        break;
      case NOTE_TYPES.fax:
        noteType = (
          <Typography variant="caption" component="span" className={classes.communication}>
            <Print className={classes.communicationInlineIcon} />
            {note.note_display}
          </Typography>
        );
        break;
      case NOTE_TYPES.ehr:
        noteType = (
          <Typography variant="caption" component="span" className={classes.communication}>
            <Computer className={classes.communicationInlineIcon} />
            {note.note_display}
          </Typography>
        );
        break;
      default:
        noteType = null;
    }

    const expirationDateText =
      note && note.pinned_expiration_dt
        ? convertToArborDate(note.pinned_expiration_dt, true).getCustomerDate(true)
        : null;

    const tagList = (noteParam, beforeAdornment = '') =>
      noteParam.tags
        .map(tag => {
          if (tag.is_owner === 1) {
            return tag.tag_display
              ? `${beforeAdornment}${tag.tag_display}`
              : patientTaskOnlyTagCheck(beforeAdornment, tag, patient);
          }
          return null;
        })
        .filter(val => val !== null)
        .join(', ');

    // eslint-disable-next-line no-confusing-arrow
    const getCommunicationSpacing = noteVar =>
      noteVar &&
      noteVar.communication &&
      noteVar.note_type_id === 2 &&
      Number(noteVar.communication.is_action_required) === 1
        ? classes.contactNoteTextLvm
        : classes.contactNoteText;

    const pinList = (noteParam, beforeAdornment = '') =>
      noteParam.tags
        .map(tag => {
          if (tag.is_pinned) {
            if (tag.is_all_of_type === 1) {
              return tag.tag_display
                ? `${beforeAdornment} All ${getNoteTagLabel(tag.tag_type_id)}s`
                : patientTaskOnlyTagCheck(beforeAdornment, tag, patient);
            }
            return tag.tag_display
              ? `${beforeAdornment}${tag.tag_display}`
              : patientTaskOnlyTagCheck(beforeAdornment, tag, patient);
          }
          return null;
        })
        .filter(val => val !== null)
        .join(', ');

    const noteTypeName = noteCommunicationTypes.find(noteCommunicationType =>
       noteCommunicationType.value === noteId,
    );

    return (
      <div
        onMouseEnter={e => {
          this.openPopover(e);
        }}
        onMouseLeave={e => {
          this.closePopover(e);
        }}
        data-qa-id={`note-type-${noteTypeName && noteTypeName.label ? noteTypeName.label : 'Note'}`}
      >
        {note && (
          <Grid container className={classes.noteSpacing}>
            <Grid
              item
              xs={12}
              className={classNames(
                classes.noteDisplay,
                note &&
                  !!note.is_pinned &&
                  note.tags.some(tag => tag.is_pinned === 1) &&
                  !inTreeView
                  ? classes.pinnedNote
                  : null,
                note && note.is_archived ? classes.archivedNote : null,
              )}
            >
              <Grid container alignItems="center">
                <Grid item xs={note && !note.pinned_expiration_dt ? 6 : 3}>
                  <Grid container alignItems="center">
                    <Typography variant="caption" component="span" className={classes.noteCreator}>
                      <span>
                        {getActionedByUserText({
                          user: createdBy,
                        })}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={note && !note.pinned_expiration_dt ? 6 : 9}
                  className={classes.alignRight}
                >
                  <Grid container alignItems="center" justifyContent="flex-end">
                    {note && !note.is_archived && !!note.is_pinned && (
                      <div
                        className={classes.noteLabel}
                        onClick={this.togglePinnedOptions}
                        onKeyPress={this.togglePinnedOptions}
                        role="button"
                        tabIndex={0}
                      >
                        <Label
                          labelName={`${pinList(note)} ${expirationDateText || ''}`}
                          labelType="pinned"
                          charmBack={
                            // eslint-disable-next-line react/jsx-wrap-multilines
                            <PinnedNoteIcon
                              isPinned={note ? !!note.is_pinned : false}
                              upshiftForLabel
                            />
                          }
                          className={classes.noteLabel}
                        />
                      </div>
                    )}
                    <Typography className={classes.noteDate} variant="caption" component="span">
                      {convertToArborDate(note ? note.created : null, true).getCustomerDate(
                        true,
                        'MM/DD/YY hh:mm A',
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <div className={classes.noteEntry}>
                    <Grid container className={classes.tagBar}>
                      <Grid item xs={12}>
                        {note.communication && note.note_type_id === 2 && (
                          <div>
                            <Grid container alignItems="center">
                              {note.communication &&
                                note.communication.type !== MESSAGE_TYPE.SMS &&
                                note.communication.type !== MESSAGE_TYPE.EMAIL && (
                                  <span className={classes.communication}>{noteType}</span>
                                )}

                              {note.communication &&
                                Number(note.communication.is_action_required) === 1 &&
                                communicationHoverOver !== 'hide' && (
                                  <div
                                    className={
                                      note.communication.result === 'Unable to Reach Contact'
                                        ? classes.communicationDropdownLong
                                        : classes.communicationDropdownShort
                                    }
                                  >
                                    <ReactSelectControlled
                                      state={communicationHoverOver}
                                      onChange={this.toggleCommunicationHoverOver}
                                      fields={[
                                        {
                                          label: 'Resolved',
                                          value: 'Resolved',
                                        },
                                      ]}
                                      name="communication_dropdown"
                                      minimal
                                      placeholder={
                                        // eslint-disable-next-line react/jsx-wrap-multilines
                                        <Typography
                                          variant="caption"
                                          component="span"
                                          className={classes.communicationResolutionWarning}
                                        >
                                          <Warning
                                            className={classes.communicationInlineIconWarning}
                                          />
                                          {note.communication.result === 'Unable to Reach Contact'
                                            ? 'Unable to Reach'
                                            : note.communication.result}
                                          <ArrowDropDown
                                            className={classes.communicationInlineIconDropdownArrow}
                                          />
                                        </Typography>
                                      }
                                    />
                                  </div>
                                )}

                              {note.note_type_id === 2 &&
                                note.communication &&
                                Number(note.communication.is_action_required) === 0 && (
                                  <div>
                                    <Typography
                                      variant="caption"
                                      component="span"
                                      className={classes.communicationResolution}
                                    >
                                      {note.communication.result &&
                                      note.communication.result !== 'Reached Contact' ? (
                                        <div className={classes.communicationReasonRow}>
                                          {' '}
                                          {note.communication.result}
                                          <ArrowRightAlt
                                            className={classes.communicationInlineIconDone}
                                          />
                                          <Done className={classes.communicationInlineIconDone} />
                                        </div>
                                      ) : null}
                                    </Typography>
                                  </div>
                                )}
                            </Grid>
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={10}>
                        <Grid container alignItems="center">
                          <Typography
                            variant="body2"
                            className={classes.tag}
                            component="span"
                            style={{ display: 'inline' }}
                          >
                            {tagList(note, '#')}
                          </Typography>
                        </Grid>
                      </Grid>
                      {!note.is_archived && popoverAnchor && (
                        <Grid item xs={2}>
                          <Grid container alignItems="flex-end" justifyContent="flex-end">
                            <Button
                              id={`note-detail-${note.id}`}
                              onClick={this.togglePinnedOptions}
                              className={classes.pinButton}
                            >
                              <PinnedNoteIcon
                                isPinned={!!note.is_pinned}
                                pinExpires={
                                  note.pinned_expiration_dt && // eslint-disable-next-line
                                  convertToArborDate(
                                    note.pinned_expiration_dt,
                                    true,
                                  ).getCustomerDate(true)
                                }
                                additionalStyle={{ color: trellisBlue }}
                              />
                            </Button>
                            <Button
                              id={`note-detail-${note.id}`}
                              onClick={this.toggleArchivedNote}
                              className={classes.pinButton}
                            >
                              <ArchivedNoteIcon />
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    {note.communication &&
                    (note.communication.type === MESSAGE_TYPE.SMS ||
                      note.communication.type === MESSAGE_TYPE.EMAIL) ? (
                      <CommunicationBox {...note} />
                    ) : (
                      <Grid
                        item
                        xs={12}
                        className={
                          note && note.communication ? getCommunicationSpacing(note) : null
                        }
                      >
                        <Typography component="span" variant="body2" style={{ display: 'inline' }}>
                          {note.note_text.split('!@!').map((text, idx) => {
                            if (idx % 2) {
                              return (
                                <span key={text} className={classes.noteMentionText} data-qa-id="note-text">
                                  {text}
                                </span>
                              );
                            }
                            return <span key={text} data-qa-id="note-text">{text}</span>;
                          })}
                        </Typography>
                      </Grid>
                    )}
                  </div>
                </Grid>
              </Grid>
              {showArchivedNote && (
                <Grid item xs={12} className={classes.noteFieldContainer}>
                  <NoteArchiveForm note={note} cancel={this.toggleArchivedNote} />
                </Grid>
              )}
              {showPinnedOptions && (
                <Grid item xs={12} className={classes.noteFieldContainer}>
                  <NotePinnedForm note={note} cancel={this.togglePinnedOptions} />
                </Grid>
              )}
            </Grid>
            {!!note.is_archived && (
              <Grid item xs={12} className={classes.archivedNote}>
                <div className={classes.noteEntry}>
                  <Typography
                    className={classes.archiveReasonText}
                    component="span"
                    style={{ display: 'inline' }}
                  >
                    Archive Reason:
                  </Typography>
                  <Typography component="span" style={{ display: 'inline' }}>
                    <br />
                    {note.archived_reason}
                  </Typography>
                </div>
              </Grid>
            )}
          </Grid>
        )}
        {!inTreeView && <Divider />}
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { patient, lookups } = state;
  return {
    patient,
    lookups,
    users: lookups.users,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { resolveCommunicationNote }),
)(NoteDetail);

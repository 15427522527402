import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import DataUsageRoundedIcon from '@mui/icons-material/DataUsageRounded';
import useStyles from './styles';

const NoData = () => {
  const { noDatColor } = useStyles();
  return (
    <Grid container alignItems="center" justifyContent="center" direction="column">
      <Box pt={15} />
      <Grid item>
        <DataUsageRoundedIcon className={noDatColor} style={{ fontSize: 100 }} />
      </Grid>
      <Box pt={2} />
      <Grid item>
        <Typography className={noDatColor} variant="h4">
          No data found
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NoData;

import React, { useState, useMemo } from 'react';
import ReviewItem from 'components/fc-self-service/components/review-item';
import Question from 'components/fc-self-service/components/question';
import { FCPaymentOptions } from 'constants/fc-refill-payment-choice';
import { STRINGS } from '../../fc_ss_constants';

const PaymentMethod = (props: any) => {
  const { saveAnswer, isReview, currentAnswers, onEdit, answerOptions, isEdit, nextQuestion } =
    props;
  const keyName = 'payment_method';
  const questionText = 'Please confirm the payment method (if applicable)';
  const questionTextReview = 'Payment Method';
  const options: any = useMemo(() => {
    const options: any = answerOptions.payment?.length
      ? [
          {
            label: 'Select a payment method',
            optionIndex: 0,
            answerOptionsIndex: null,
            hideFromDropDown: true,
          },
        ]
      : [];
    const offset = answerOptions.payment?.length ? 1 : 0;
    answerOptions?.payment?.forEach((item: any, index: number) => {
      options.push({
        label: `****-****-****-${item.card_num}`,
        optionIndex: index + offset,
        answerOptionsIndex: index,
      });
    });
    if (options.length === 0) {
      options.push({
        label: STRINGS.new_payment_method,
        answerOptionsIndex: FCPaymentOptions.PatientRequestedCallIfNeeded,
        optionIndex: 0,
      });
    } else {
      options.push({
        label: STRINGS.add_new_payment_method,
        answerOptionsIndex: FCPaymentOptions.PatientRequestedCall,
        optionIndex: options.length,
      });
    }
    return options;
  }, [answerOptions.payment]);

  const [selectedValue, setSelectedValue] = useState<any>(options[0]);

  const handleResponse = (value: any) => {
    saveAnswer({ keyName, value });
    nextQuestion();
  };

  if (isReview) {
    const reviewAnswer =
      currentAnswers[keyName] &&
      currentAnswers[keyName].answerOptionsIndex === answerOptions.payment.length
        ? 'Call for payment method'
        : options[currentAnswers[keyName].optionIndex].label;
    return (
      <ReviewItem
        questionText={questionTextReview}
        currentAnswer={reviewAnswer}
        onEdit={onEdit}
        keyName={keyName}
      />
    );
  }
  return (
    <Question
      questionText={questionText}
      response={handleResponse}
      keyName={keyName}
      isEdit={isEdit}
      options={{
        label: 'Payment Method',
        items: options,
        setSelectedValue,
        selectedValue,
      }}
    />
  );
};

export default PaymentMethod;

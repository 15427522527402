import React, { useLayoutEffect, useState } from 'react';
import { Grid, Box, Button, Divider, Typography } from '@mui/material';
import { Loader } from 'components/loading-overlay/loading-overlay';

import { getPreviousInvoices, getDownloadPreviousInvoice } from 'services/utils/invoicing-service';
import RadioGroup from 'components/form/radio/radio-group';
import RadioButton from 'components/form/radio/radio-button';
import NoData from './no-data';
import useStyles from './styles';

const PreviousInvoices = () => {
  const { periodTitle, dividerList, loaderContainer } = useStyles();
  const [periods, setPeriods] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [currentPeriod, setCurrentPeriod] = useState();

  const handleOnExport = async (year, month) => {
    const downloadData = await getDownloadPreviousInvoice(year, month);
    window.open(downloadData.data.url, '_blank');
  };

  const handleOnChangePeriod = value => {
    setCurrentPeriod(periods[value]);
  };

  useLayoutEffect(() => {
    getPreviousInvoices().then(({ data }) => {
      if (data) {
        setPeriods(data);
        const [defaultPeriod] = data;
        setCurrentPeriod(defaultPeriod);
      }
      setLoaded(true);
    });
  }, [loaded]);

  const render = (
    <>
      {!loaded ? (
        <Grid className={loaderContainer}>
          <Loader loaded={loaded} />
        </Grid>
      ) : (
        <>
          {periods.length > 0 ? (
            <Box pl={2}>
              <Grid direction="column" container>
                <Grid item xs={12}>
                  <Typography className={periodTitle} variant="h6">
                    Periods
                  </Typography>
                </Grid>
                <Box pt={2} />
                <Grid item xs={12}>
                  {periods.length > 0 && (
                    <RadioGroup value={0} horizontal>
                      {periods.map((item, i) => (
                        <RadioButton
                          key={item.year}
                          maxItemWidth="100px"
                          onChange={handleOnChangePeriod}
                          radius={3}
                          value={i}
                        >
                          {item.year}
                        </RadioButton>
                      ))}
                    </RadioGroup>
                  )}
                </Grid>
                <Box pt={2} />
                <Divider className={dividerList} />
                {currentPeriod &&
                  currentPeriod.data.map(period => (
                    <Grid direction="column" item container>
                      <Box pt={1} />
                      <Grid justifyContent="space-between" item container>
                        <Typography className={periodTitle} variant="subtitle1">
                          <strong>{period.label}</strong>
                        </Typography>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleOnExport(currentPeriod.year, period.month)}
                          >
                            Export to XLSX
                          </Button>
                        </Grid>
                      </Grid>
                      <Box pt={1} />
                      <Divider className={dividerList} />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          ) : (
            <NoData />
          )}
        </>
      )}
    </>
  );

  return render;
};

export default PreviousInvoices;

import { FETCH_DATA_DC_PROTOCOLS, RESET_DC_PROTOCOLS } from 'constants/index';

export const fetchDcProtocols = (taskId, therapy, collectedData, rsType = null) => ({
  type: FETCH_DATA_DC_PROTOCOLS,
  payload: {
    taskId,
    therapy,
    collectedData,
    rsType,
  },
});

export const resetDcProtocols = () => ({
  type: RESET_DC_PROTOCOLS,
  payload: {},
});

import { convertToArborDate } from 'models/time/arbor-date';
import { windowFeatureIsEnabled } from 'config/window-features';
import boolean from 'boolean';
import {
  ADD_MEDICATION_SUCCESS,
  ADD_THERAPY,
  ADD_THERAPY_AR,
  FETCH_MEDICATION_LIST_ONLY_CURSOR,
  FETCH_MEDICATION_GROUPS_ONLY,
  FETCH_MEDICATION_GROUPS_ONLY_CURSOR,
  SELECTED_PATIENT_ID,
  SET_MEDICATIONS_VERIFICATION,
  EDIT_THERAPY,
  UPDATE_MEDICATION_SUCCESS,
  FETCH_MEDICATION_LIST_FOR_IDENTIFIER_SUCCESS,
  REFRESH_MEDICATIONS,
} from '../constants';
import { getMedicationStrengthStr } from './helper-reducer';

const mergeMedications = (orignialMedications, newMedications) => {
  const resultMap = [...orignialMedications, ...newMedications].reduce(
    (acc, med) => acc.set(med.id, med),
    new Map(),
  );
  return Array.from(resultMap.values());
};

const mergeMedicationGroups = (orignialMedications, newMedications) => {
  const resultMap = [...orignialMedications, ...newMedications].reduce(
    (acc, med) => acc.set(med.gpi, med),
    new Map(),
  );
  return Array.from(resultMap.values());
};

function convertMedicationData(data) {
  if (!data) {
    return null;
  }
  let medication = data.drug_name;
  data.start_dt = convertToArborDate(data.start_dt, true).getUtcDate(true);
  data.end_dt = convertToArborDate(data.end_dt, true).getUtcDate(true);
  data.is_prn = boolean(data.is_prn);
  if (data.strength) {
    medication += ` ${data.strength}`;
  }
  if (data.frequency) {
    medication += ` ${data.frequency}`;
  }
  const access =
    data.is_excluded_from_contract === 'N' || data.can_purchase_global === 'Y' ? 'Yes' : 'No';
  const targeted =
    data.is_customer_targeted_drug === 'Y' || data.is_targeted_drug === 'Y' ? 'Yes' : 'No';
  data.strength_str = getMedicationStrengthStr(data);
  return {
    ...data,
    access,
    targeted,
    medication,
  };
}

const convertMedicationGroups = medicationGroups =>
  medicationGroups.map(data => convertMedicationData(data));

const initialState = {
  medicationList: [],
  medicationListCursor: undefined,
  medicationGroups: [],
  medicationGroupsCursor: undefined,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SELECTED_PATIENT_ID:
    case REFRESH_MEDICATIONS:
      return initialState;
    case FETCH_MEDICATION_LIST_ONLY_CURSOR: {
      if (payload.data) {
        return {
          ...state,
          medicationListCursor: payload.data.response_metadata.next_cursor,
        };
      }
      return state;
    }
    case FETCH_MEDICATION_GROUPS_ONLY_CURSOR: {
      if (payload.data) {
        return {
          ...state,
          medicationGroupsCursor: payload.data.response_metadata.next_cursor,
        };
      }
      return state;
    }
    case FETCH_MEDICATION_GROUPS_ONLY: {
      if (payload?.data) {
        const newState = {
          ...state,
          medicationGroups: mergeMedications(
            state.medicationGroups,
            convertMedicationGroups(payload.data.medication_groups),
          ),
        };
        return newState;
      }
      return state;
    }
    case FETCH_MEDICATION_LIST_FOR_IDENTIFIER_SUCCESS: {
      if (payload?.data) {
        const newState = {
          ...state,
          medicationList: mergeMedications(
            state.medicationList,
            convertMedicationGroups(payload.data.medication_list),
          ),
        };
        return newState;
      }
      return state;
    }
    case ADD_MEDICATION_SUCCESS: {
      if (payload.data) {
        const {
          added_medication: addedMedication,
          updated_medication_group: updatedMedicationGroup,
        } = payload.data;
        return {
          ...state,
          medicationGroups: mergeMedicationGroups(
            state.medicationGroups,
            convertMedicationGroups(updatedMedicationGroup ? [updatedMedicationGroup] : []),
          ),
          medicationList: mergeMedications(
            state.medicationList,
            convertMedicationGroups([addedMedication]),
          ),
        };
      }
      return state;
    }
    case ADD_THERAPY_AR:
    case ADD_THERAPY: {
      if (payload.data) {
        const {
          updated_medications: updatedMedications,
          updated_medication_groups: updatedMedicationGroups,
        } = payload.data;
        if (updatedMedications?.length) {
          return {
            ...state,
            medicationGroups: mergeMedicationGroups(
              state.medicationGroups,
              convertMedicationGroups(updatedMedicationGroups),
            ),
            medicationList: mergeMedications(
              state.medicationList,
              convertMedicationGroups(updatedMedications),
            ),
          };
        }
      }
      return state;
    }
    case EDIT_THERAPY:
    case UPDATE_MEDICATION_SUCCESS: {
      if (payload.data) {
        const {
          updated_medications: updatedMedications,
          updated_medication_groups: updatedMedicationGroups,
        } = payload.data;
        if (updatedMedications?.length) {
          return {
            ...state,
            medicationGroups: mergeMedications(
              state.medicationGroups,
              convertMedicationGroups(updatedMedicationGroups),
            ),
            medicationList: mergeMedications(
              state.medicationList,
              convertMedicationGroups(updatedMedications),
            ),
          };
        }
      }
      return state;
    }
    case SET_MEDICATIONS_VERIFICATION: {
      if (payload && payload.updated) {
        return {
          ...state,
          medicationList: state.medicationList.map(med => ({
            ...med,
            verified_dt: payload.updated,
          })),
        };
      }
      return state;
    }
    default:
      return state;
  }
};

import React, { ReactElement, useState } from 'react';
import { Grid, Typography, Button, Divider } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { EditHistory, SmallSearch } from 'components/icons/icons';
import { Field } from 'redux-form';
import { useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import { CdmProgramClient } from 'clients/cdm-program';

import HTTP from 'services/http';
import {
  renderRadioGroup,
  renderDropdown,
  renderMultipleDropdown,
  renderTextField,
  renderDropdownNonSimple,
} from 'components/form/field/redux-field';
import AutocompleteMinigrid from 'components/form/field/render-minigrid-autocomplete';
import AutocompleteLabel from 'components/form/field/autocomplete-label';
import { required } from 'components/form/validation/validation';
import {
  getUserSelectableTherapyClinicalDispensingReasons,
  ExternalDispensingPharmacyReasons,
  staticPharmacies,
} from 'constants/lists';
import {
  fetchExternalPharmacies,
  getTherapyStatuses,
  getTherapyStatusById,
  statusArrayForEdit,
  getReasonsByStatus,
} from 'services/utils/therapy-service';
import DetailField from 'components/form/field/field';
import { getActionedByUserText } from 'utils/user-utils';
import { THERAPY_DISPENSING_OPTOUT, DISPENSING_STATUSES, RS } from 'constants/index';
import { windowFeatureIsEnabled } from 'config/window-features';
import { TherapyWorkflowLabel } from 'constants/enums';
import NpiLookup from './npi-lookup';
import type {
  IProps,
  IDispenseHistoryCount,
  IDispenseHistory,
  IDispenseHistoryRecord,
  IPharmacyValues,
} from './interfaces/IProps';
import { styles } from './therapy-detail.styles';
import { PatientTherapiesClient } from '../../../clients/patient-therapies';

const inputLabelStyling = {
  marginBottom: -30,
  minHeight: 28,
};

const EditTherapyEnrollment: React.FC<IProps> = props => {
  const therapyClinicalDispensingStatuses = props.enrollmentStatuses
    ? statusArrayForEdit(getTherapyStatuses(props.enrollmentStatuses))
    : [];
  const [dispenseHistoryCount, setDispenseHistoryCount] = useState<number | undefined>(undefined);
  const [showDispenseHistory, setShowDispenseHistory] = useState<boolean>(false);
  const [dispenseHistory, setDispenseHistory] = useState<IDispenseHistoryRecord[] | undefined>(
    undefined,
  );
  const [dispenseHistoryError, setDispenseHistoryError] = useState(false);
  const [isNpiLookupVisible, setIsNpiLookupVisible] = useState<boolean>(false);
  const [isTransferNpiLookupVisible, setIsTransferNpiLookupVisible] = useState<boolean>(false);
  const [isCdmCustomerAndDiagnosis, setIsCdmCustomerAndDiagnosis] = useState<boolean | undefined>(
    undefined,
  );
  const [selectedDispensingPharmacy, setSelectedDispensingPharmacy] = useState<{
    npi: string;
    doing_business_as: string;
  }>({ npi: '', doing_business_as: '' });
  const listOfPharmacies = useSelector((state: any) => state.pharmacies?.pharmacies || []);

  const handleFetchPharmacyOptions = (searchText: string) =>
    fetchExternalPharmacies(searchText).then((result: any) => {
      const staticMatches = [];
      const regex = new RegExp(searchText, 'gi');
      for (let i = 0; i < staticPharmacies.length; i += 1) {
        const entry = staticPharmacies[i];
        if (entry.name.search(regex) !== -1) {
          staticMatches.push(entry);
        }
      }
      const dbSearchResults = result.data ? result.data.pharmacies : [];
      return [...staticMatches, ...dbSearchResults].map((pharmacy: any) => ({
        ...pharmacy,
        label: pharmacy.name,
        description: pharmacy.address,
      }));
    });

  const therapyDispensingPharmacyReasons = useSelector(
    (state: any) => state.lookups.therapyDispensingPharmacyReasons,
  );

  const handleChangeOptInPharmacy = (pharmacy: any) => {
    setSelectedDispensingPharmacy(pharmacy);
  };

  const handleDispensingReason = (_e: any, val: any) => {
    if (props.onValueChange) {
      props.onValueChange('external_dispensing_reason', val);
    }
  };

  const handleClinicalReason = (_e: any, val: any) => {
    if (props.onValueChange) {
      props.onValueChange('clinical_support_status_reason', val);
    }
  };

  const handleDispensingStatusChange = (status: any) => {
    const { onValueChange } = props;
    if (onValueChange) {
      onValueChange('dispensing_status', status);
      onValueChange('dispensing_pharmacy', '');
    }
  };

  const handleClinicalStatusChange = (status: any) => {
    const { onValueChange } = props;
    if (onValueChange) {
      onValueChange('clinical_support_status', status);
    }
  };

  const renderDispenseHistoryError = () =>
    dispenseHistoryError && (
      <h3 className={props.classes.dispenseHistoryError} data-qa-id="error-message">
        Failed to load patient service enrollment dispense history
      </h3>
    );

  const showDispensingAdditionalReason =
    props.selectedDispensingReason &&
    (props.selectedDispensingReason
      .split(',')
      .includes(ExternalDispensingPharmacyReasons.other.toString()) ||
      props.selectedDispensingReason
        .split(',')
        .includes(ExternalDispensingPharmacyReasons.patientChoiceOther.toString()));

  const showClinicalAdditionalReason =
    props.selectedClinicalReason &&
    props.selectedClinicalReason
      .split(',')
      .includes(ExternalDispensingPharmacyReasons.patientChoiceLikesCurrentPharmacy.toString());

  React.useEffect(() => {
    async function fetchAndSetDispenseHistoryCount(): Promise<void> {
      const API = `/patients/${props.patientId}/ndc/${props.ndc}/dispenseHistoryCount`;
      try {
        const response = (await HTTP.get(API, {})) as AxiosResponse<IDispenseHistoryCount>;
        const count = response.data.dispenseHistoryCount;
        if (count === undefined) {
          throw Error(`The dispenseHistoryCount response was unexpected: ${response}`);
        }
        setDispenseHistoryCount(count);
      } catch (error) {
        setDispenseHistoryError(true);
      }
    }
    if (props.patientId && props.ndc) {
      fetchAndSetDispenseHistoryCount();
    }
  }, [props.patientId, props.ndc]);

  React.useEffect(() => {
    async function fetchAndSetDispenseHistory(): Promise<void> {
      const API = `/patients/${props.patientId}/ndc/${props.ndc}/dispenseHistory`;
      try {
        const response = (await HTTP.get(API, {})) as AxiosResponse<IDispenseHistory>;
        const historyRecords = response.data.dispenseHistory;
        if (historyRecords === undefined) {
          throw Error(`The dispenseHistory response was unexpected: ${response}`);
        }
        setDispenseHistory(historyRecords);
      } catch (error) {
        setDispenseHistoryError(true);
      }
    }
    if (showDispenseHistory && dispenseHistory === undefined) {
      fetchAndSetDispenseHistory();
    }
  }, [props.patientId, props.ndc, showDispenseHistory, dispenseHistory]);

  React.useEffect(() => {
    async function fetchAndSetIsCdmCustomerAndDiagnosis(): Promise<void> {
      if (!windowFeatureIsEnabled('cdm_customer')) {
        setIsCdmCustomerAndDiagnosis(false);
      } else if (props.patientId != null && props.diagnosisCode != null) {
        if (props.diagnosisCode && props.arTask && Object.keys(props.arTask).length) {
          const tempSpecialtyType = props.arTask.drugs?.find(
            (t: any) => t.ndc === props.ndc,
          )?.specialty_type;
          if (tempSpecialtyType) {
            const res = await PatientTherapiesClient.getTherapyWorkflow(
              props.diagnosisCode,
              tempSpecialtyType,
            );
            const label =
              TherapyWorkflowLabel[
                res?.data?.therapyWorkflow as keyof typeof TherapyWorkflowLabel
              ] ?? '';
            if (label !== RS && label !== '') {
              setIsCdmCustomerAndDiagnosis(false);
            } else {
              setIsCdmCustomerAndDiagnosis(undefined);
            }
          }
        }
      } else if (props.diagnosisCode === undefined) {
        setIsCdmCustomerAndDiagnosis(undefined);
      }
    }
    fetchAndSetIsCdmCustomerAndDiagnosis();
  }, [props.patientId, props.diagnosisCode, windowFeatureIsEnabled('cdm_customer')]);

  const handleFetchInternalPharmacyOptions = React.useMemo<any[]>(() => {
    const internalPharmaciesFromLookup = listOfPharmacies.length ? listOfPharmacies : [];
    return internalPharmaciesFromLookup.map((pharmacy: any) => ({
      ...pharmacy,
      label: pharmacy.name,
      description: pharmacy.address,
    }));
  }, [listOfPharmacies]);

  function renderHistoryRecord(historyEvent: IDispenseHistoryRecord): ReactElement {
    const enrollmentStatus = getTherapyStatusById(
      props.enrollmentStatuses,
      historyEvent.dispensing_status_id,
    );
    const outsideDispensingReason =
      historyEvent.external_dispensing_reason &&
      therapyDispensingPharmacyReasons.find((e: any) => {
        return e.value === parseInt(historyEvent.external_dispensing_reason, 10);
      })?.label;
    return (
      <Grid>
        <Grid container className={props.classes.dispenseHistoryDetails}>
          <Grid item>
            <Typography variant="caption">
              {getActionedByUserText({
                action: 'Updated',
                user: historyEvent.updated_by,
                date: historyEvent.updated,
              })}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={props.classes.dispenseHistoryDetails}>
          <Grid item xs={6}>
            <DetailField fieldName="Enrollment Status" field={enrollmentStatus.status} />
          </Grid>
          {enrollmentStatus.status === 'Undecided' && enrollmentStatus.reason && (
            <Grid item xs={6}>
              <DetailField
                fieldName="Clinical Support Undecided Reason"
                field={enrollmentStatus.reason}
              />
            </Grid>
          )}
        </Grid>
        {historyEvent.dispensing_status_id === THERAPY_DISPENSING_OPTOUT && (
          <Grid container className={props.classes.dispenseHistoryDetails}>
            <Grid item xs={6}>
              <DetailField
                fieldName="Dispensing Pharmacy"
                field={historyEvent.dispensing_pharmacy_name}
                defaultValue={undefined}
              />
            </Grid>
            <Grid item xs={3}>
              <DetailField fieldName="Pharmacy NPI" field={historyEvent.dispensing_pharmacy_npi} />
            </Grid>
            <Grid item xs={3}>
              <DetailField
                fieldName="Doing Business As"
                field={historyEvent.dispensing_pharmacy_doing_business_as}
              />
            </Grid>
          </Grid>
        )}
        {outsideDispensingReason && (
          <Grid container className={props.classes.dispenseHistoryDetails}>
            <Grid item xs={6}>
              <DetailField
                fieldName="Outside Dispensing Pharmacy Reason"
                field={outsideDispensingReason}
              />
            </Grid>
            {historyEvent.external_dispensing_additional_reason && (
              <Grid item xs={6}>
                <DetailField
                  fieldName="Outside Dispensing Pharmacy Addt'l Reason"
                  field={historyEvent.external_dispensing_additional_reason}
                />
              </Grid>
            )}
          </Grid>
        )}
        <Divider />
      </Grid>
    );
  }

  const clinicalSupportOptions = getUserSelectableTherapyClinicalDispensingReasons([]);
  return (
    <>
      <Grid>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="body1" className={props.classes.serviceEnrollmentLabel}>
              Service Enrollment - Dispensing
            </Typography>
          </Grid>
          {renderDispenseHistoryError()}
          {/* Suppress history display when the count has not yet been retrieved */}
          {dispenseHistoryCount !== undefined && (
            <Grid item>
              <Typography variant="caption">
                {dispenseHistoryCount} previous dispensing event{dispenseHistoryCount !== 1 && 's'}
              </Typography>
              {dispenseHistoryCount !== 0 && (
                <Button onClick={() => setShowDispenseHistory(!showDispenseHistory)}>
                  <EditHistory />
                </Button>
              )}
            </Grid>
          )}
        </Grid>
        {showDispenseHistory && dispenseHistory !== undefined && (
          <div className={props.classes.dispenseHistoryContainer}>
            {dispenseHistory.map(historyRecord => {
              return renderHistoryRecord(historyRecord);
            })}
          </div>
        )}
        <Grid container>
          <Grid
            item
            xs={8}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <Field
              name="dispensing_status"
              radioMap={therapyClinicalDispensingStatuses}
              component={renderRadioGroup}
              validate={[required]}
              disabled={props.disabled}
              onChange={(e: any) => handleDispensingStatusChange(e.target.value)}
              data-qa-id="dispensing-value"
            />
          </Grid>
          {props.selectedDispensingStatus === DISPENSING_STATUSES.UNDECIDED && (
            <Grid item xs={4}>
              <Field
                name="dispensing_undecided_reason"
                label="Dispensing Undecided Reason *"
                width="90"
                component={renderDropdown}
                fields={getReasonsByStatus(
                  props.enrollmentStatuses,
                  props.selectedDispensingStatus,
                )}
                validate={[required]}
              />
            </Grid>
          )}
          {props.selectedDispensingStatus === DISPENSING_STATUSES.OPT_IN && (
            <>
              <Grid container spacing={1}>
                <Grid item xs={7} className={props.classes.npiLookupButtonContainer}>
                  <Field
                    name="transfer_pharmacy"
                    label="Transfer From Pharmacy"
                    component={AutocompleteMinigrid}
                    fetchOptions={handleFetchPharmacyOptions}
                    hint="Search by Pharmacy Name or NPI"
                    onChange={props.handleSaveColumnTransferPharmacy}
                    simplistic
                    hasMinSearchLength
                  />
                  <Button
                    className={props.classes.npiLookupButton}
                    onClick={() => setIsTransferNpiLookupVisible(true)}
                  >
                    <SmallSearch />
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <AutocompleteLabel
                    idValue={props.transferPharmacyValues?.npi}
                    defaultIdValue=""
                    label="Pharmacy NPI"
                    fieldWidth="100%"
                    useLargeLabel={false}
                  />
                </Grid>
                <Grid item xs={3}>
                  <AutocompleteLabel
                    fieldWidth="100%"
                    idValue={props.transferPharmacyValues?.doing_business_as}
                    defaultIdValue=""
                    label="Doing Business As"
                    useLargeLabel={false}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={7} className={props.classes.npiLookupButtonContainer}>
                  <Field
                    name="dispensing_pharmacy"
                    label="Dispensing Pharmacy *"
                    component={renderDropdownNonSimple}
                    fields={handleFetchInternalPharmacyOptions}
                    validate={[required]}
                    inputLabelStyle={inputLabelStyling}
                    // onChange={handleChangeOptInPharmacy}
                    onChange={props.handleSaveColumnPharmacy}
                    data-qa-id={`dispensing_pharmacy_opt_in_therapy_${props.ndc}`}
                  />
                </Grid>
                <Grid item xs={2}>
                  <AutocompleteLabel
                    idValue={props.pharmacyValues?.npi}
                    label="Pharmacy NPI"
                    defaultIdValue=""
                    useLargeLabel={false}
                    fieldWidth="100%"
                  />
                </Grid>
                <Grid item xs={3}>
                  <AutocompleteLabel
                    fieldWidth="100%"
                    idValue={props.pharmacyValues?.doing_business_as}
                    label="Doing Business As"
                    defaultIdValue=""
                    useLargeLabel={false}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {props.selectedDispensingStatus === DISPENSING_STATUSES.OPT_OUT && props.isSpecialty && (
            <Grid container spacing={1}>
              <Grid item xs={7} className={props.classes.npiLookupButtonContainer}>
                <Field
                  name="dispensing_pharmacy"
                  label="Dispensing Pharmacy"
                  component={AutocompleteMinigrid}
                  fetchOptions={handleFetchPharmacyOptions}
                  hint="Search by Pharmacy Name or NPI"
                  onChange={props.handleSaveColumnPharmacy}
                  simplistic
                  hasMinSearchLength
                />
                <Button
                  className={props.classes.npiLookupButton}
                  onClick={() => setIsNpiLookupVisible(true)}
                >
                  <SmallSearch />
                </Button>
              </Grid>
              <Grid item xs={2}>
                <AutocompleteLabel
                  idValue={props.pharmacyValues?.npi}
                  label="Pharmacy NPI"
                  defaultIdValue=""
                  useLargeLabel={false}
                  fieldWidth="100%"
                />
              </Grid>
              <Grid item xs={3}>
                <AutocompleteLabel
                  fieldWidth="100%"
                  idValue={props.pharmacyValues?.doing_business_as}
                  label="Doing Business As"
                  defaultIdValue=""
                  useLargeLabel={false}
                />
              </Grid>
            </Grid>
          )}
          {(props.selectedDispensingStatus === DISPENSING_STATUSES.OPT_OUT ||
            props.pharmacyValues?.is_internal === 'No') &&
            props.selectedDispensingStatus !== DISPENSING_STATUSES.UNDECIDED &&
            props.isSpecialty &&
            therapyDispensingPharmacyReasons && (
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Field
                    name="external_dispensing_reason"
                    label="Outside Dispensing Pharmacy Reason *"
                    onChange={handleDispensingReason}
                    multiple
                    width="90"
                    component={renderMultipleDropdown}
                    fields={therapyDispensingPharmacyReasons}
                    validate={[required]}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Field
                    name="external_dispensing_additional_reason"
                    label="Outside Dispensing Pharmacy Reason Details"
                    component={renderTextField}
                    multiline
                    rows={3}
                    onChange={(e: any) =>
                      props.onValueChange &&
                      props.onValueChange('external_dispensing_additional_reason', e.target.value)
                    }
                  />
                </Grid>
              </Grid>
            )}
        </Grid>
        {/* Show the clinical support section if the therapy is specialty
      and either the customer is not CDM or the therapy is not CDM */}
        {props.isSpecialty && isCdmCustomerAndDiagnosis === false && (
          <Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1" className={props.classes.serviceEnrollmentLabel}>
                  Service Enrollment - Clinical Support
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={8}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <Field
                  name="clinical_support_status"
                  radioMap={therapyClinicalDispensingStatuses}
                  component={renderRadioGroup}
                  validate={[required]}
                  onChange={(e: any) => handleClinicalStatusChange(e.target.value)}
                  data-qa-id="clinical-support-value"
                />
              </Grid>
              {props.selectedClinicalStatus === DISPENSING_STATUSES.UNDECIDED && (
                <Grid item xs={4}>
                  <Field
                    name="clinical_support_undecided_reason"
                    label="Clinical Support Undecided Reason *"
                    width="90"
                    component={renderDropdown}
                    fields={getReasonsByStatus(
                      props.enrollmentStatuses,
                      props.selectedClinicalStatus,
                    )}
                    validate={[required]}
                  />
                </Grid>
              )}
              {props.selectedClinicalStatus === DISPENSING_STATUSES.OPT_OUT && (
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Field
                      name="clinical_support_status_reason"
                      label="Reason"
                      width="90"
                      onChange={handleClinicalReason}
                      multiple
                      component={renderMultipleDropdown}
                      fields={clinicalSupportOptions}
                    />
                  </Grid>
                  {showClinicalAdditionalReason && (
                    <Grid item xs={4}>
                      <Field
                        name="clinical_support_additional_reason"
                        label="Other Reason *"
                        validate={[required]}
                        component={renderTextField}
                        onChange={(e: any) =>
                          props.onValueChange &&
                          props.onValueChange('clinical_support_additional_reason', e.target.value)
                        }
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      {isNpiLookupVisible && (
        <NpiLookup
          open
          title="Pharmacy"
          values={{
            taxonomy: 'pharmacy',
            organization: props.pharmacyValues?.name,
          }}
          handleChange={(item: IPharmacyValues) => {
            props.handlePharmacyNpiChange?.(item);
          }}
          handleClose={() => {
            setIsNpiLookupVisible(false);
          }}
        />
      )}
      {isTransferNpiLookupVisible && (
        <NpiLookup
          open
          title="Transfer From Pharmacy"
          values={{
            taxonomy: 'pharmacy',
            organization: props.transferPharmacyValues?.name || '',
          }}
          handleChange={(item: IPharmacyValues) => {
            props.handleTransferPharmacyNpiChange?.(item);
          }}
          handleClose={() => {
            setIsTransferNpiLookupVisible(false);
          }}
        />
      )}
    </>
  );
};

export default withStyles(styles, { withTheme: true })(EditTherapyEnrollment);

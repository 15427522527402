import { createStyles } from '@mui/styles';

export const styles = theme =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.deepBlue,
      color: theme.palette.primary.white,
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 600,
      padding: 14,
      [`${theme.breakpoints.down('xl')} and (orientation: landscape)`]: {
        padding: 0,
        color: theme.palette.primary.deepBlue,
      },
    },
  });

import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  listItemIcon: {
    minWidth: 0,
  },
  filterListItem: {
    paddingTop: 0,
    paddingBottom: 0,
    cursor: 'pointer',
  },
  checkboxList: {
    width: '100%',
    maxWidth: 360,
    minWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 250,
    border: `1px solid ${theme.palette.primary.grey13}`,
    borderRadius: '4px',
  },
  overflowPopover: {
    '& .MuiPopover-paper': {
      overflow: 'visible',
    },
  },
}));

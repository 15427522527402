import HTTP from '../services/http';
import { notifyError, notifySuccess } from '../actions/action-notifications';

export class SecureFCClient {
  static sendSecureLink = async (payload: any): Promise<any> => {
    const { patient_id: patientId, fc_task_ids, patient_sms_phone_number } = payload;
    const url = `/tasks/fc/send-secure-fc-link/${patientId}`;
    const new_payload = { fc_task_ids, patient_sms_phone_number };
    const response = await HTTP.post(url, new_payload);
    const { error, updatedTasks } = response.data;
    if (error) {
      return Promise.resolve(notifyError(error));
    }
    return Promise.resolve({ updatedTasks });
  };

  static resendSecureLink = async (payload: any): Promise<any> => {
    const { secure_link_id, patient_sms_phone_number, patient_id } = payload;
    const url = '/tasks/fc/refresh-secure-fc-link';
    const new_payload = { secure_link_id, patient_sms_phone_number, patient_id };
    const response = await HTTP.post(url, new_payload);
    const { error } = response.data;
    if (error) {
      return Promise.resolve(notifyError(error));
    }

    return Promise.resolve(notifySuccess('Secure Link Text Message was resent'));
  };

  static invalidateSecureLink = async (payload: any): Promise<any> => {
    const { secure_link_id } = payload;
    const url = '/tasks/fc/invalidate-secure-fc-link';
    const new_payload = { secure_link_id };
    const response = await HTTP.post(url, new_payload);
    const { error, updatedTasks } = response.data;
    if (error) {
      return Promise.resolve(notifyError(error));
    }
    return Promise.resolve({ updatedTasks });
  };

  static removeFCsFromPendingBundle = async (payload: any): Promise<any> => {
    const { secure_link_id, fc_task_ids } = payload;
    const url = '/tasks/fc/remove-fcs-from-pending-secure-link-bundle';
    const new_payload = { secure_link_id, fc_task_ids };
    const response = await HTTP.post(url, new_payload);
    const { error, updatedTasks } = response.data;
    if (error) {
      return Promise.resolve(notifyError(error));
    }
    return Promise.resolve({ updatedTasks });
  };

  static removeFCsFromReturnedBundle = async (payload: any): Promise<any> => {
    const { secure_link_id, fc_task_ids, patient_id, therapy_description } = payload;
    const url = '/tasks/fc/remove-fcs-from-returned-secure-link-bundle';
    const new_payload = { secure_link_id, fc_task_ids, patient_id, therapy_description };
    const response = await HTTP.post(url, new_payload);
    const { error } = response.data;
    if (error) {
      return Promise.resolve(notifyError(error));
    }

    return Promise.resolve(response);
  };

  static addFCBackToBundle = async (payload: any): Promise<any> => {
    const { fc_task_id } = payload;
    const url = '/tasks/fc/add-fc-to-prior-secure-link-bundle';
    const new_payload = { fc_task_id };
    const response = await HTTP.post(url, new_payload);
    const { error } = response.data;
    if (error) {
      return Promise.resolve(notifyError(error));
    }

    return Promise.resolve(response);
  };
}

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

const styles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  const collapsedInnerCell = {
    padding: 0,
    border: 0,
  };

  return createStyles({
    emptyMessageContainer: {
      padding: theme.spacing(4),
      marginTop: theme.spacing(2),
    },
    emptyMessage: {
      color: palette.primary.darkGrey,
    },
    table: {
      borderRadius: theme.spacing(0.5),
      minWidth: theme.spacing(80),
    },
    addButtonWrapper: {
      textAlign: 'right',
      padding: theme.spacing(1),
    },
    mainExpandedRow: {
      backgroundColor: palette.primary.grey14,
    },
    collapsedInnerCell,
    collapsedInnerHiddenCell: {
      ...collapsedInnerCell,
      visibility: 'hidden',
    },
    noBorderRow: {
      border: 0,
    },
    expandedInnerCell: {},
  });
};

export { styles };

import { Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { MedicationStatus } from 'constants/enums';
import { updateMedication } from 'actions/action-medications';
import { MedicationSwitch } from './medication-switch';

interface IProps {
  submitCallback?: any;
  medication: any;
  disabled?: boolean;
}

const StatusCodeToggle: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { submitCallback, medication, disabled = false } = props;
  const dispatch = useDispatch();

  const handleStatusCodeChange = (checked: boolean) => {
    const medicationPayload = {
      id: medication.id,
      patient_id: medication.patient_id,
      therapy_id: medication.therapy ? medication.therapy.id : null,
      status_code: checked ? MedicationStatus.Active : MedicationStatus.Discontinued,
    };

    dispatch(updateMedication(medicationPayload));
    if (submitCallback) {
      submitCallback();
    }
  };

  const activeStatusCode = !![MedicationStatus.Active].includes(medication?.status_code);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <MedicationSwitch
        checked={activeStatusCode}
        disabled={disabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleStatusCodeChange(event.target?.checked);
        }}
      />
      <Typography>{activeStatusCode ? 'Active' : 'Discontinued'}</Typography>
    </div>
  );
};

export default StatusCodeToggle;

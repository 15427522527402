import { useSelector } from 'react-redux';
import { startCase } from 'lodash';
import { ICounselingTask } from 'interfaces/redux/task-types/ICounselingTask';
import { IState } from 'interfaces/redux/IState';
import { IDataCollectTask } from 'interfaces/redux/task-types/IDataCollectTask';
import { convertToArborDate } from 'models/time/arbor-date';
import { useLatestDcs } from './use-latest-dcs';
import { IObjectiveDataCollect } from '../documentation/interfaces/IProps';

const useDocObjectiveDcs = (
  taskQty: number,
  currentSelectedTasks: ICounselingTask[],
): IObjectiveDataCollect[] => {
  const therapies = useSelector((state: IState) => state.therapies);
  const parsedItemsDcTasks = useLatestDcs(taskQty, currentSelectedTasks, { includeRS: true });

  return currentSelectedTasks.reduce(
    (objectiveDataCollects: IObjectiveDataCollect[], currentTask: ICounselingTask) => {
      const perTaskObjectiveDataCollects = parsedItemsDcTasks.reduce(
        (accum: IObjectiveDataCollect[], dcTask: IDataCollectTask) => {
          const taskId = dcTask.id;
          const therapyId = dcTask.therapy_id || dcTask.therapyInitialId || 0;
          const drugName = therapies.data[therapyId]?.drug_name ?? '-';
          const date = dcTask.reviewed_dt || dcTask.acceptedDate;

          const reviewedDate = date ? convertToArborDate(date, true).getCustomerDate(true) : '';

          return [
            ...accum,
            {
              taskId,
              drugName,
              reviewedDate: reviewedDate || '',
              labs:
                dcTask.collectedItems?.filter(item => item.value != null && item.value !== '-') ||
                [],
              type: startCase(dcTask.type.toLowerCase()),
            },
          ];
        },
        [],
      );

      return [...objectiveDataCollects, ...perTaskObjectiveDataCollects];
    },
    [],
  );
};

export { useDocObjectiveDcs };

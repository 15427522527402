import HTTP from 'services/http';
import { FETCH_RESOURCE_LINK, taskAbbreviationMap } from '../constants';

export function fetchResourceLink(resourceType, resourceId) {
  const taskType = taskAbbreviationMap[resourceType];
  let query = '';
  if (taskType) {
    query = `?taskType=${taskType}`;
  }
  const request = HTTP.get(`/links/${resourceType}/${resourceId}${query}`, {});
  return {
    type: FETCH_RESOURCE_LINK,
    payload: request,
  };
}

import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { IProps } from './interfaces/IProps';
import { styles } from './secondary-title-bar.styles';
import { buildQaId } from '../../utils/build-qa-id';

const qaIdBuilder = buildQaId('secondary-title-bar');

const SecondaryTitleBar: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { classes } = props;

  return (
    <Grid item xs={12} data-qa-id={qaIdBuilder('container')}>
      <Paper elevation={0} className={props.classes.titleBar}>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          className={classes.titleContainer}
        >
          <Grid item xs={8}>
            <Typography className={classes.title} data-qa-id={qaIdBuilder('title')}>
              {props.title}
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.iconWrapper} data-qa-id={qaIdBuilder('icon')}>
            {props.button && props.button}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default withStyles(styles)(SecondaryTitleBar);

/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
} from '@mui/material';
import { useTypedSelector } from 'hooks/use-typed-selector';
import makeStyles from '@mui/styles/makeStyles';
import RichTextEditor, { EditorValueFormat, StandardFeatures } from 'components/rich-text-editor';
import { Field, reduxForm, InjectedFormProps, change } from 'redux-form';
import AutocompleteMinigrid from 'components/form/field/render-minigrid-autocomplete';
import { fetchGpis, specialtyTypeFormatter } from 'services/utils/therapy-service';
import {
  renderDropdown,
  renderTextField,
  renderNumberField,
  renderMultipleDropdown,
} from 'components/form/field/redux-field';
import {
  getClinicalDataTypesMap,
  getFieldsIdsWithConditional,
  mapValuesToClinicalDataType,
  getDataToUpdateClinicalDataItems,
} from 'services/utils/data-collect';
import {
  addCdmProgramsAndConditions,
  editCdmProgramsAndConditions,
  deactivateCdmProgram,
} from 'actions/action-cdm-programs';
import FieldLabel from 'components/form/field/field-label';
import { ILabelValueNumber } from 'interfaces/ILabelValue';
import { buildQaId } from 'utils/build-qa-id';
import { useDispatch } from 'react-redux';
import { ICdmProgramAndConditions } from 'reducers/reducer-cdm-programs';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { required, rsGraduationTypeMatchValidation } from 'components/form/validation/validation';
import { DiseaseState } from 'models/application-manager/cpmp/IGetDiseaseStateResponse';
import { AxiosPromise } from 'axios';
import { equalityOperators, daysMonths } from 'constants/lists';
import { IGetCdmProgramsResponse } from 'models/application-manager/cpmp/IGetCdmProgramsResponse';
import { FORM_NAME, QA_ID } from './constants';
import { IPostRsProtocolRequest } from './types';
import { RsProtocolFormModalStyles } from './styles';
import { notifyError } from '../../../../actions/action-notifications';

export interface IProps {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
  onCancel?: () => void;
  protocol?: ICdmProgramAndConditions;
  editMode?: boolean;
  getCdmProgramData?: () => IGetCdmProgramsResponse;
}

type IRsProtocolFormProps = IProps & InjectedFormProps<IPostRsProtocolRequest, IProps>;

const qaIdBuilder = buildQaId(QA_ID);

const useStyles = makeStyles(RsProtocolFormModalStyles);

const RsFormRs = (props: IRsProtocolFormProps) => {
  const { onSuccess, onError, onCancel, handleSubmit, getCdmProgramData, protocol, editMode } =
    props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const clinicalDataTypes = useTypedSelector(state => state.lookups.clinicalDataTypes);

  const [diseaseStates, setDiseaseStates] = useState<DiseaseState[]>([]);
  const [rsClinicalItems, setRsClinicalItems] = useState<ILabelValueNumber[]>([]);
  const [diseaseStatesOptions, setDiseaseStatesOptions] = useState<ILabelValueNumber[]>([]);
  const [loadingDiseaseStates, setLoadingDiseaseStates] = useState<boolean>(false);
  const [loadingClincialDataItems, setLoadingClincialDataItems] = useState<boolean>(false);
  const [icdCodes, setIcdCodes] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [enrollmentIsNumeric, setEnrollmentIsNumeric] = useState<boolean>(false);
  const [graduationIsNumeric, setGraduationIsNumeric] = useState<boolean>(false);
  const [enrollementGraduationTypesMatch, setEnrollementGraduationTypesMatch] =
    useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const submitPromiseHandler = async (promise: AxiosPromise) => {
    try {
      setLoading(true);

      await promise;

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const sameValueTypes = (value1: string | undefined, value2: string | undefined): boolean => {
    if (value1 && value2) {
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(Number(value1)) && !isNaN(Number(value2))) {
        return true;
      }
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(Number(value1)) && isNaN(Number(value2))) {
        return true;
      }
    }
    return false;
  };

  const isEnrollmentGraduationConditionDifferent = (
    enrollmentOperator: string | undefined,
    enrollmentValue: string | undefined,
    graduationOperator: string | undefined,
    graduationValue: string | undefined,
  ): boolean => {
    if (enrollmentOperator && enrollmentValue && graduationOperator && graduationValue) {
      if (
        enrollmentOperator === graduationOperator &&
        enrollmentValue.toLowerCase() === graduationValue.toLowerCase()
      ) {
        return false;
      }
      if (
        enrollmentValue.toLowerCase() === graduationValue.toLowerCase() &&
        enrollmentOperator !== graduationOperator
      ) {
        const operators = ['<=', '>='];
        if (operators.includes(enrollmentOperator) && operators.includes(graduationOperator)) {
          return false;
        }
        return true;
      }
      if (
        enrollmentValue.toLowerCase() !== graduationValue.toLowerCase() &&
        enrollmentOperator === graduationOperator
      ) {
        const operators = ['<', '>', '<=', '>='];
        if (operators.includes(enrollmentOperator) && operators.includes(graduationOperator)) {
          return false;
        }
        return true;
      }
      if (
        enrollmentValue.toLowerCase() !== graduationValue.toLowerCase() &&
        enrollmentOperator !== graduationOperator
      ) {
        return true;
      }
    }
    return false;
  };

  const deactivateRsProtocol = async () => {
    if (protocol?.cdm_program_id) {
      submitPromiseHandler(deactivateCdmProgram(protocol.cdm_program_id).payload);
    }
  };

  // eslint-disable-next-line consistent-return
  const onSubmit = async (formValues: Partial<IPostRsProtocolRequest>) => {
    if (
      sameValueTypes(
        formValues?.enrollment_clinical_data_value,
        formValues?.graduation_clinical_data_value,
      )
    ) {
      if (
        isEnrollmentGraduationConditionDifferent(
          formValues?.enrollment_clinical_data_operator,
          formValues?.enrollment_clinical_data_value,
          formValues?.graduation_clinical_data_operator,
          formValues?.graduation_clinical_data_value,
        )
      ) {
        const retFormVals: ICdmProgramAndConditions = {
          ...formValues,
          cdm_program_id: protocol?.cdm_program_id,
          cdm_program_conditions_id: protocol?.cdm_programs_conditions_id,
          cdm_customer_program_id: protocol?.cdm_customer_program_id,
          rs_clinical_item_id: formValues?.rs_clinical_item?.value
            ? Number(formValues.rs_clinical_item?.value)
            : Number(formValues?.rs_clinical_item),
          frequency_days:
            formValues.time_period === 'Months'
              ? Number(formValues?.frequency) * 30
              : Number(formValues?.frequency),
        };
        if (!editMode) {
          submitPromiseHandler(addCdmProgramsAndConditions(retFormVals).payload);
        } else if (editMode && protocol?.id) {
          submitPromiseHandler(editCdmProgramsAndConditions(retFormVals).payload);
        } else if (!protocol?.id) {
          return submitPromiseHandler(
            (() => {
              throw Error('Protocol is not selected for edit mode');
            })(),
          );
        }
      } else {
        const message = 'Enrollment and Graduation criteria should not be the same.';
        dispatch(notifyError(message));
      }
    } else {
      const message =
        'Enrollment criteria value and graduation goal value should be the same data type.';
      dispatch(notifyError(message));
    }
  };

  const loadDiseaseStates = async () => {
    setLoadingDiseaseStates(true);
    try {
      const result = await ApplicationManagerClient.fetchDiseaseStates();
      if (result.data.result.length > 0) {
        const diseaseStatesWithprotocols = getCdmProgramData ? getCdmProgramData() : { result: [] };
        if (diseaseStatesWithprotocols.result && diseaseStatesWithprotocols.result.length) {
          const diseaseStateIdList = diseaseStatesWithprotocols.result.reduce((acc, it) => {
            if (
              it.active &&
              it.specialty_diagnosis_id &&
              !acc.includes(it.specialty_diagnosis_id)
            ) {
              acc.push(it.specialty_diagnosis_id);
            }
            return acc;
          }, [] as number[]);
          const availableDiseaseStates = result.data.result.reduce((acc, item) => {
            if (!diseaseStateIdList.includes(item.id)) {
              acc.push(item);
            }
            return acc;
          }, [] as any[]);
          setDiseaseStates(availableDiseaseStates);
        } else {
          setDiseaseStates(result.data.result);
        }
      }
    } finally {
      setLoadingDiseaseStates(false);
    }
  };

  const loadClinicalDataItems = async () => {
    setLoadingClincialDataItems(true);
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let retArr: any[] = [];
      const result = await ApplicationManagerClient.fetchIcdProtocols();
      if (result.data.result.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const val of result.data.result) {
          const dcItemVals = Object.values(val.dcItem);
          const dcItemkeys = Object.keys(val.dcItem);
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < dcItemVals.length; i++) {
            retArr = [
              ...retArr,
              { label: Object.values(val.dcItem)[i].name, value: Object.keys(val.dcItem)[i] },
            ];
          }
        }
      }
      setRsClinicalItems(retArr);
    } finally {
      setLoadingClincialDataItems(false);
    }
  };

  const loadDiseaseStateOptions = () => {
    setDiseaseStatesOptions(
      diseaseStates.map(({ diseaseName, id }) => ({
        label: diseaseName,
        value: id,
      })),
    );
  };

  const setFieldValue = (field: string, value: any) => dispatch(change(FORM_NAME, field, value));

  const handleFetchGpisOptions = async (searchText: string) => {
    const result = await fetchGpis(searchText);
    return (
      result?.data?.drugs?.map((therapy: { ndc: any; drug_info: any; is_specialty_drug: any }) => ({
        ...therapy,
        id: therapy.ndc,
        label: therapy.drug_info,
      })) || []
    );
  };

  const getClincialDataItemByspecialtyId = (value: number | null) => {
    let specialtyId = value;

    if (protocol?.clinical_data_type_id) {
      specialtyId = protocol?.clinical_data_type_id;
    }
    if (!specialtyId) {
      setFieldValue('rs_clinical_item', null);
    }
    const valueStr = `${specialtyId}`;
    const clinicalDataTypesMap = getClinicalDataTypesMap(clinicalDataTypes);
    const retVal = Object.values(clinicalDataTypesMap).filter(
      (val: any) => val.dataTypeId === valueStr,
    );
    const retArr = retVal.map((v: any) => ({ value: v.dataTypeId, label: v.name }));
    setFieldValue('rs_clinical_item', retArr[0]);
  };

  const getRsClincialItem = async (value: number | null) => {
    const result = await ApplicationManagerClient.fetchIcdProtocols();
    let resultData = null;
    if (result.data.result.length > 0) {
      resultData = result.data.result.find(res => res.id === value) || null;
    }
    const rsClincialItemId =
      resultData && resultData.dcItem ? Number(Object.keys(resultData?.dcItem)[0]) : null;
    getClincialDataItemByspecialtyId(rsClincialItemId);
  };

  const setDiseaseState = () => {
    if (!protocol?.specialty_diagnosis_id) {
      return;
    }
    setFieldValue('specialty_id', protocol?.specialty_diagnosis_id);
    const diseaseState = diseaseStates.find(d => d.id === protocol.specialty_diagnosis_id);
    setIcdCodes(Object.values(diseaseState?.icdCode || {}).map(code => code.name));
  };

  const deactivateProtocol = () => {
    const message = 'Are you sure you want to deactivate this RS protocol?';
    return (
      <Dialog open={open} maxWidth="md">
        <DialogContent>
          <DialogContentText>
            <Typography className={classes.deactivateText}>{message}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>CANCEL</Button>
          <Button variant="contained" color="primary" onClick={() => deactivateRsProtocol()}>
            CONTINUE
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  useEffect(() => {
    loadDiseaseStates();
    loadClinicalDataItems();
  }, []);

  useEffect(() => {
    loadDiseaseStateOptions();
  }, [diseaseStates]);

  useEffect(() => {
    setDiseaseState();
    getRsClincialItem(protocol?.specialty_diagnosis_id || null);
  }, [protocol?.specialty_diagnosis_id, diseaseStates]);

  useEffect(() => {
    if (protocol && protocol.frequency_days % 30 === 0) {
      setFieldValue('frequency', protocol.frequency_days / 30);
      setFieldValue('time_period', { label: 'Months', value: 'Months' });
    } else {
      setFieldValue('frequency', protocol?.frequency_days);
      setFieldValue('time_period', { label: 'Days', value: 'Days' });
    }
  }, [protocol?.frequency_days]);

  useEffect(() => {
    setFieldValue('enrollment_clinical_data_value', protocol?.enrollment_clinical_data_value);
    setEnrollmentIsNumeric(!Number.isNaN(Number(protocol?.enrollment_clinical_data_value)));
  }, [protocol?.enrollment_clinical_data_value]);

  useEffect(() => {
    setFieldValue('enrollment_clinical_data_operator', protocol?.enrollment_clinical_data_operator);
  }, [protocol?.enrollment_clinical_data_operator]);

  useEffect(() => {
    setFieldValue('graduation_clinical_data_value', protocol?.graduation_clinical_data_value);
    setGraduationIsNumeric(!Number.isNaN(Number(protocol?.graduation_clinical_data_value)));
  }, [protocol?.graduation_clinical_data_value]);

  useEffect(() => {
    setFieldValue('graduation_clinical_data_operator', protocol?.graduation_clinical_data_operator);
  }, [protocol?.graduation_clinical_data_operator]);

  useEffect(() => {
    setEnrollementGraduationTypesMatch(enrollmentIsNumeric === graduationIsNumeric);
  }, [enrollmentIsNumeric, graduationIsNumeric]);
  return (
    <>
      <DialogContent>
        {open && deactivateProtocol()}
        <form data-qa-id={qaIdBuilder('form')}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Field
                name="specialty_id"
                label="Disease State *"
                component={renderDropdown}
                validate={[required]}
                fields={diseaseStatesOptions}
                onChange={async (value: any) => {
                  if (!value) {
                    return;
                  }
                  const diseaseState = diseaseStates.find(d => d.id === value);
                  setIcdCodes(Object.values(diseaseState?.icdCode || {}).map(code => code.name));

                  getRsClincialItem(value);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FieldLabel variant="caption" fieldName="ICD 10" />
              <div>
                {icdCodes.map(code => (
                  <Chip className={classes.icdChip} label={code} key={code} disabled />
                ))}
              </div>
            </Grid>
            <Grid item xs={8}>
              <Field
                name="rs_clinical_item"
                label="RS Clinical Item *"
                component={renderDropdown}
                disabled={editMode}
                validate={[required]}
                fields={rsClinicalItems}
              />
            </Grid>
            <Grid item xs={2}>
              <Field name="frequency" label="Frequency *" component={renderNumberField} />
            </Grid>
            <Grid item xs={2}>
              <Field
                name="time_period"
                label="Days/Months *"
                component={renderDropdown}
                validate={[required]}
                fields={daysMonths}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography>Enrollment Criteria</Typography>
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={3}>
              <Typography>Graduation Goal</Typography>
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={3}>
              <Field
                name="enrollment_clinical_data_operator"
                label="Threshold Symbol *"
                component={renderDropdown}
                validate={[required]}
                fields={equalityOperators}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name="enrollment_clinical_data_value"
                label="Value *"
                component={renderTextField}
                validate={
                  enrollementGraduationTypesMatch
                    ? [required]
                    : [required, rsGraduationTypeMatchValidation]
                } // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(e: any) => {
                  setEnrollmentIsNumeric(!Number.isNaN(Number(e.target.value)));
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name="graduation_clinical_data_operator"
                label="Threshold Symbol *"
                component={renderDropdown}
                validate={[required]}
                fields={equalityOperators}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name="graduation_clinical_data_value"
                label="Value *"
                component={renderTextField}
                validate={
                  enrollementGraduationTypesMatch
                    ? [required]
                    : [required, rsGraduationTypeMatchValidation]
                } // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(e: any) => {
                  setGraduationIsNumeric(!Number.isNaN(Number(e.target.value)));
                }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions className={classes.actionsWrp}>
        <Grid container justifyContent={editMode ? 'space-between' : 'flex-end'}>
          {editMode && (
            <Grid item>
              <Button
                onClick={() => {
                  setOpen(true);
                }}
                variant="outlined"
                className={classes.deactivateBtn}
              >
                Deactivate
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={loading}
            >
              Save {loading && <CircularProgress className={classes.progress} size={13} />}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export const RsProtocolModalInnerForm = reduxForm<IPostRsProtocolRequest, IProps>({
  form: FORM_NAME,
})(RsFormRs);

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { convertToArborDate } from 'models/time/arbor-date';
import { fetchPatientArchivedTasks } from '../../../actions/action-patient';
import { styles } from './patient-demographics-styles';
import PatientReenrollForm from './patient-reenroll-form';

export const PatientReenroll = React.memo(
  props => {
    const { classes, clinic, patient } = props;
    const dispatch = useDispatch();

    const [displayReenrollForm, setDisplayReenrollForm] = useState(false);
    const prepareReenrollForm = React.useCallback(
      setDisplay => {
        if (setDisplay) {
          dispatch(fetchPatientArchivedTasks(patient.id, true));
        }
        setDisplayReenrollForm(setDisplay);
      },
      [dispatch, fetchPatientArchivedTasks, setDisplayReenrollForm],
    );

    return (
      <>
        <Grid
          xs={12}
          className={classes.clinicItem}
          sx={{ padding: '0 16px 16px', borderBottom: '1px solid #eee' }}
        >
          <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
            <Grid item xs={6}>
              <Typography className={classes.patientDischargeText} sx={{ fontWeight: 'bold' }}>
                {clinic.name}
              </Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <Grid container justifyContent="flex-end" alignItems="center">
                {`Admitted ${convertToArborDate(clinic.admitted_start_dt, true).getCustomerDate(
                  true,
                )}`}
              </Grid>
              <Grid container justifyContent="flex-end" alignItems="center">
                {`Discharged (${clinic.discharge_reason ?? ''}) ${convertToArborDate(
                  clinic.admitted_end_dt,
                  true,
                ).getCustomerDate(true)}`}
              </Grid>
            </Grid>
            <Grid item xs={3} align="right">
              <Grid container justifyContent="flex-end" alignItems="center">
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => prepareReenrollForm(true)}
                >
                  RE-ENROLL
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <PatientReenrollForm
          open={displayReenrollForm}
          clinic={clinic}
          handleClose={() => setDisplayReenrollForm(false)}
        />
      </>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.patient?.id === nextProps.patient?.id;
  },
);

export default withStyles(styles)(PatientReenroll);

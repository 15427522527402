import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import WidthController from './width-controller';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.deepBlue,
    color: theme.palette.primary.white,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 500,
    padding: 20,
    [`${theme.breakpoints.down('xl')} and (orientation: landscape)`]: {
      padding: 0,
      color: theme.palette.primary.deepBlue,
    },
  },
}));

export default ({ title }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <WidthController>{title}</WidthController>
    </div>
  );
};

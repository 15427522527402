import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import WidthController from './width-controller';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.teal,
    padding: '20px 0',
    [`${theme.breakpoints.down('xl')}`]: {
      padding: '15px 15px',
    },
  },
  bar: {
    height: 5,
    backgroundColor: theme.palette.primary.white,
    borderRadius: 100,
    overflow: 'hidden',
    [`${theme.breakpoints.down('xl')}`]: {
      height: 15,
    },
    [`${theme.breakpoints.down('xl')} and (orientation: landscape)`]: {
      height: 5,
    },
  },
  progress: {
    height: 5,
    backgroundColor: theme.palette.primary.deepBlue,
    borderRadius: 100,
    [`${theme.breakpoints.down('xl')}`]: {
      height: 15,
    },
    [`${theme.breakpoints.down('xl')} and (orientation: landscape)`]: {
      height: 5,
    },
  },
}));

export default ({ total, value }) => {
  const classes = useStyles();
  const percentage = Math.round((value / total) * 100);
  return (
    <div className={classes.root}>
      <WidthController>
        <div className={classes.bar}>
          <div className={classes.progress} style={{ width: `${percentage}%` }} />
        </div>
      </WidthController>
    </div>
  );
};

import { specialtyTypeFormatter } from '../services/utils/therapy-service';

export interface MedTag {
  text: string;
  description: string;
}

export class MedUtils {
  static getMedicationTags(
    therapySpecialtyType?: string,
    medSpecialtyType?: string,
    defaultTags: MedTag[] = [],
  ): MedTag[] {
    const types = (therapySpecialtyType || medSpecialtyType || '').split(',');
    const tags = [
      ...defaultTags,
      ...types.map(type => ({
        text: type,
        description: specialtyTypeFormatter(null, 'specialty_type', type),
      })),
    ];

    return tags;
  }
}

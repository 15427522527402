import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';

export const styles = (theme: Theme) => {
  return createStyles({
    addFormContainer: {
      minWidth: '100%',
    },
    addTargetWrapper: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    targetContainer: {
      marginTop: theme.spacing(3),
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    addButton: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    therapySearchWrapper: {
      '& .MuiInput-root': {
        marginTop: 11,
      },
    },
  });
};

export const Styles = {
  addFormContainer: {
    minWidth: '100%',
  },
  addTargetWrapper: {
    paddingTop: 3,
    paddingBottom: 3,
  },
  targetContainer: {
    marginTop: 3,
  },
  leftIcon: {
    marginRight: 1,
  },
  addButton: {
    marginTop: 3,
    marginBottom: 3,
  },
  therapySearchWrapper: {
    '& .MuiInput-root': {
      marginTop: '11px',
    },
  },
};

import React from 'react';
import { withStyles, WithStyles } from '@mui/styles';
import { buildQaId } from 'utils/build-qa-id';
import { useDispatch } from 'react-redux';
import { Grid, Button } from '@mui/material';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { editFillDeliveryConfirmation, editTask } from 'actions/action-tasks';
import { FillDeliveryConfirmationStatus } from 'interfaces/enums/TaskStatuses/FillDeliveryConfirmationStatus';
import { IPickUpButton } from './types';
import { ShippingStyles } from './shipping.styles';
import { FDC } from '../../constants/index';

const qaIdBuilder = buildQaId('shipping');

interface IShippingProps extends IPickUpButton, WithStyles<typeof ShippingStyles> {}

const VoidPickupButton = (props: IShippingProps): JSX.Element | null => {
  const { classes, setReadyForPickupClicked, setFirstTimePrinting, currentFdcInformation } = props;
  const dispatch = useDispatch();

  const getVoidPickup = () => {
    try {
      const updatedTask = {
        id: currentFdcInformation?.id,
        taskType: FDC,
        therapy_id: currentFdcInformation?.therapyId,
        status_id: FillDeliveryConfirmationStatus.Packed,
      };
      dispatch && dispatch(editTask(updatedTask, FDC));
      dispatch && dispatch(editFillDeliveryConfirmation(updatedTask));
      setReadyForPickupClicked && setReadyForPickupClicked(false);
      setFirstTimePrinting && setFirstTimePrinting(true);
      dispatch(notifySuccess('The order label has been voided.'));
    } catch (error) {
      const notifyMessage = 'Unable to void/cancel shipping label.';
      dispatch(notifyError(notifyMessage));
    }
  };

  return (
    <Grid item className={classes.voidLabelButton}>
      <Button
        variant="contained"
        color="primary"
        onClick={async () => {
          return getVoidPickup();
        }}
        data-qa-id={qaIdBuilder('button.void-label')}
      >
        Void Pickup
      </Button>
    </Grid>
  );
};

export default withStyles(ShippingStyles)(VoidPickupButton);

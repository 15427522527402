import { Button, Grid, Typography, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Call, Email, Face, Sms, MailOutline, Print, Computer } from '@mui/icons-material';
import classNames from 'classnames';
import { BlueNoteIcon, NoteIcon, PinnedNoteIcon } from 'components/icons/icons';
import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { openCommunicationNotesSideBar } from 'actions/action-notes';
import { NOTE_TYPES } from 'constants/lists';
import { convertToArborDate } from 'models/time/arbor-date';
import { NoteBarTrigger } from 'constants/enums';
import { selectAllTasks } from 'containers/patient/tasks-new/selectors';
import { createSelector } from '@reduxjs/toolkit';
import { deepDiff } from 'utils/deep-diff-props';
import { styles } from './note-styles';

class NoteDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.clickNote = this.clickNote.bind(this);
  }

  clickNote(e) {
    e.stopPropagation();
    const {
      openCommunicationNotesSideBarAction,
      tagName,
      tagTypeId,
      tagResourceId,
      therapy,
      existingTasks,
    } = this.props;

    const payload = {
      noteBar: {
        display: true,
        tag: tagName,
        tagTypeId,
        tagResourceId,
        trigger: NoteBarTrigger.Notes,
        showAllNotes: true,
      },
      therapy,
      existingTasks,
    };

    openCommunicationNotesSideBarAction(payload);
  }

  render() {
    const { notes, classes, containerClasses, isInContext, maxLine, id, drawBorder, notesForm } =
      this.props;
    const getNoteContainerStyle = drawBorder ? classes.drawBorder : null;
    const noteDisplayStyle = {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: maxLine || 3,
    };
    const notesToUse = notesForm || notes;

    const noteText = note =>
      `${note.created_by_user}${note && note.created_by_user_active === 1 ? '' : ' (Inactive)'}`;
    const maxNoteTextCharLength = 13;
    if (notesToUse && notesToUse.length > 0) {
      return (
        <Grid
          container
          onClick={e => {
            this.clickNote(e);
            e.stopPropagation();
          }}
          className={drawBorder ? classes.drawBorder : ''}
        >
          {notesToUse.map((note, idx) => {
            let noteType = null;
            if (
              note &&
              note.note_type_id === 2 &&
              note.communication &&
              note.communication.type_id
            ) {
              switch (Number(note.communication.type_id)) {
                case NOTE_TYPES.in_person:
                  noteType = <Face className={classes.noteCommunicationIconContainer} />;
                  break;
                case NOTE_TYPES.email:
                  noteType = <Email className={classes.noteCommunicationIconContainer} />;
                  break;
                case NOTE_TYPES.sms:
                  noteType = <Sms className={classes.noteCommunicationIconContainer} />;
                  break;
                case NOTE_TYPES.phone_call:
                  noteType = (
                    <Call
                      style={{ transform: 'rotate(90deg)' }}
                      className={classes.noteCommunicationIconContainer}
                    />
                  );
                  break;
                case NOTE_TYPES.mail:
                  noteType = <MailOutline className={classes.noteCommunicationIconContainer} />;
                  break;
                case NOTE_TYPES.fax:
                  noteType = <Print className={classes.noteCommunicationIconContainer} />;
                  break;
                case NOTE_TYPES.ehr:
                  noteType = <Computer className={classes.noteCommunicationIconContainer} />;
                  break;
                default:
                  noteType = null;
              }
            }
            if (note) {
              return (
                <Grid
                  key={note && note.id}
                  className={
                    // eslint-disable-next-line
                    notesToUse.length === 1
                      ? classes.noteContainer
                      : idx === 0
                      ? classes.firstNoteContainer
                      : classes.lastNoteContainer
                  }
                >
                  <Grid container>
                    <Grid item xs={5}>
                      {noteText(note).length > maxNoteTextCharLength ? (
                        <Tooltip title={noteText(note)} placement="top">
                          <Typography className={classes.ellipsis}>{noteText(note)}</Typography>
                        </Tooltip>
                      ) : (
                        <Typography className={classes.ellipsis}>{noteText(note)}</Typography>
                      )}
                    </Grid>
                    <Grid item xs={7} className={classes.alignRight}>
                      <Typography className={classes.noteDate}>
                        {convertToArborDate(note.created, true).getCustomerDate(
                          true,
                          'MM/DD/YY hh:mm A',
                        )}
                      </Typography>
                      <Button
                        id={`note-display-${note.id}`}
                        className={classNames(classes.pinButton, classes.displayPinOffset)}
                      >
                        <PinnedNoteIcon isPinned={note.is_pinned} />
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={11}>
                      <div className={classes.noteEntry} style={noteDisplayStyle}>
                        {noteType && <span className={classes.tag}>{noteType}</span>}
                        {!isInContext && (
                          <span className={classes.tag}>
                            {note.tags &&
                              // eslint-disable-next-line no-confusing-arrow
                              note.tags.map(tag => (tag.tag_display ? `#${tag.tag_display} ` : ''))}
                          </span>
                        )}
                        {note.note_text.split('!@!').map((text, index) => {
                          if (index % 2) {
                            return (
                              /* eslint-disable-next-line */
                              <span key={index} className={classes.noteMentionText}>
                                {text}
                              </span>
                            );
                          }
                          /* eslint-disable-next-line */
                          return <span key={index}>{text}</span>;
                        })}
                      </div>
                    </Grid>
                    {/* last note */}
                    {idx === notesToUse.length - 1 && (
                      <Grid item xs={1}>
                        <span className={classes.noteIconContainer}>
                          <BlueNoteIcon className={classes.noteIconBottom} />
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              );
            }
            return null;
          })}
        </Grid>
      );
    }
    return (
      <Grid
        container
        onClick={e => {
          this.clickNote(e);
          e.stopPropagation();
        }}
        className={containerClasses ? containerClasses.noteContainer : getNoteContainerStyle}
      >
        <Grid item xs={6} className={classes.addNewNoteDisplay}>
          {id ? (
            <Typography variant="body2" id={id}>
              + Add Note
            </Typography>
          ) : (
            <Typography variant="body2">+ Add Note</Typography>
          )}
        </Grid>
        <Grid item xs className={classes.noteIconContainer}>
          <NoteIcon />
        </Grid>
      </Grid>
    );
  }
}

const tasksSelector = createSelector(selectAllTasks, allTasks => allTasks, deepDiff);

function mapStateToProps(state) {
  const { selectedPatientId } = state;
  const existingTasks = tasksSelector(state);

  return {
    selectedPatientId,
    existingTasks,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    openCommunicationNotesSideBarAction: openCommunicationNotesSideBar,
  }),
)(NoteDisplay);

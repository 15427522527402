import { createStyles } from '@mui/styles';

export const styles = theme =>
  createStyles({
    poweredBy: {
      fontSize: 13,
      width: '100%',
      left: 0,
      [`${theme.breakpoints.down('xl')} and (orientation: landscape)`]: {
        bottom: 10,
      },
    },
  });

import { createStyles } from '@mui/styles';
import { defaultTheme } from 'lib/themes';

export const styles = theme =>
  createStyles({
    boxAnswer: {
      backgroundColor: defaultTheme.palette.primary.greySubRow,
      minWidth: 50,
      padding: '6px 16px',
      borderRadius: 200,
      textAlign: 'center',
    },
    boxAnswerWrapper: {
      maxWidth: 'fit-content',
    },
    editButton: {
      textTransform: 'capitalize',
      textDecoration: 'underline',
    },
    required: {
      '&::after': {
        content: '"*"',
        display: 'inline-block',
        color: defaultTheme.palette.primary.valenciaRed,
        marginLeft: 4,
      },
    },
  });

import React from 'react';
import { Grid, Theme } from '@mui/material';

import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) => {
  return createStyles({
    container: {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      marginBottom: theme.spacing(3),
    },
    header: {
      marginBottom: theme.spacing(2),
    },
  });
};

interface ISectionTitleProps extends WithStyles<typeof styles> {
  title?: string;
  rightSideItems?: { key: string; element: JSX.Element | null }[];
}

const SectionTitle: React.FC<ISectionTitleProps> = (props: ISectionTitleProps): JSX.Element => {
  const { classes } = props;

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={classes.container}
    >
      <Grid item xs="auto">
        <h2 className={classes.header}>{props.title}</h2>
      </Grid>
      <Grid item xs="auto">
        {props.rightSideItems?.map(item => {
          return <React.Fragment key={item.key}>{item.element}</React.Fragment>;
        })}
      </Grid>
    </Grid>
  );
};

export const SectionTitleStyled = withStyles(styles)(SectionTitle);

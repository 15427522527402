import { AxiosPromise } from 'axios';
import {
  ComponentState,
  IFetchLabel,
  IShippingVerificationScanHistoryPayload,
  IShippingVerificationScanHistoryResponse,
} from 'containers/shipping/types';
import HTTP from '../services/http';

const baseUrl = '/shipping/orders';
export class ShippingClient {
  static fetch = (orderIdValue: string, source: string): AxiosPromise<ComponentState> => {
    const url = `${baseUrl}/${orderIdValue}/${source}/shipping_details`;
    return HTTP.get(url);
  };

  static fetchLabel = (orderIdValue: string): AxiosPromise<IFetchLabel> => {
    const url = `${baseUrl}/${orderIdValue}/print_label`;
    return HTTP.put(url);
  };

  static voidLabel = (orderIdValue: string): AxiosPromise<void | { message: string }> => {
    const url = `${baseUrl}/${orderIdValue}/void_label`;
    return HTTP.delete(url);
  };

  static recordScan = (
    orderId: string,
    payload: IShippingVerificationScanHistoryPayload,
  ): AxiosPromise<IShippingVerificationScanHistoryResponse> => {
    const url = `${baseUrl}/${orderId}/scan_label`;
    return HTTP.post(url, payload);
  };

  static fetchScanHistory = (
    orderId: string,
  ): AxiosPromise<IShippingVerificationScanHistoryResponse> => {
    const url = `${baseUrl}/${orderId}/scan_history`;
    return HTTP.get(url, {});
  };
}

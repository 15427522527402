import {
  FETCH_DOCUMENTS,
  ADD_PATIENT_DOCUMENT,
  ADD_PATIENT_DOCUMENT2,
  FETCH_DOCUMENTS_REQUEST,
  UPDATE_PATIENT_DOCUMENT,
} from 'constants/index';

import { buildDistinctList, mergeDocumentList } from 'services/utils/document-service';

const initialState = { documents: [], loading: true, labels: [], tags: [] };
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_DOCUMENTS:
      return {
        ...state,
        ...{ documents: payload.data.documents },
        labels: buildDistinctList(payload.data.documents.map(d => d.labels)),
        tags: buildDistinctList(payload.data.documents.map(d => d.tag_display)),
        loading: false,
      };
    case FETCH_DOCUMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_PATIENT_DOCUMENT:
      return {
        ...state,
        ...{ documents: state.documents.concat(payload) },
        labels: buildDistinctList(
          state.labels,
          payload.map(d => d.labels),
        ),
        tags: buildDistinctList(
          state.tags,
          payload.map(d => d.tag_display),
        ),
      };
    case ADD_PATIENT_DOCUMENT2:
      const docs = payload?.data?.added_documents;
      return {
        ...state,
        ...{ documents: state.documents.concat(docs) },
        labels: buildDistinctList(
          state.labels,
          docs.map(d => d.labels),
        ),
        tags: buildDistinctList(
          state.tags,
          docs.map(d => d.tag_display),
        ),
      };
    case UPDATE_PATIENT_DOCUMENT:
      return {
        ...state,
        ...{ documents: mergeDocumentList(state.documents, payload.data.result) },
        labels: buildDistinctList(state.labels, payload.data.result.labels),
        tags: buildDistinctList(state.tags, payload.data.result.tag_display),
      };
    default:
      return state;
  }
};

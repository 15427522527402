export enum DurStatus {
  Required = 16001,
  Generated = 16002,
  Intervening = 16003,
  Reviewed = 16004,
  Canceled = 16005,
  ReadyForReview = 16006,
  CanceledPatientDeceased = 16099,
}

const STATUS_LIST = [
  DurStatus.Required,
  DurStatus.Generated,
  DurStatus.Intervening,
  DurStatus.Reviewed,
  DurStatus.Canceled,
  DurStatus.ReadyForReview,
  DurStatus.CanceledPatientDeceased,
] as const;

export enum DurHistoryRecordTypeEnum {
  DurClosed = 'dur_closed',
  AllergyAdded = 'allergy_added',
  MedicationAdded = 'medication_added',
}

export type DurStatusList = typeof STATUS_LIST[number];

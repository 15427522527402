import { IPatient } from 'interfaces/redux/IPatient';
import {
  IQuestionnaireQuestion,
  IQuestionnaireReference,
} from 'models/tasks/IQuestionnaireResponse';
import { IUser } from 'interfaces/redux/ILookups';
import { DocumentTemplate } from 'components/document/generator/types';
import { formatPatientNameShort } from '../../../services/utils/demographic-service';
import { convertToArborDate } from '../../../models/time/arbor-date';
import {
  IPrintPayloadPatient,
  IPrintPayloadQuestionAnswer,
  IPrintQuestionnaireDocumentPage,
} from './interfaces/questionnaire-print-payload-types';

class QuestionnairePrintPayload {
  // Splitting the questions into multiple pages as needed, with 7 questions per page.
  // 7 works well for the Rapid-3 questionnaire.
  // If other questionnaires are added, we may have to incorporate logic
  // that looks to the questions and the number of options in each.
  private static readonly NUMBER_OF_QUESTIONS_PER_PAGE = 7;

  // Currently only being used for the Rapid-3 questionnaire
  public static readonly RAPID_3_DATA_TYPE_ID = '307';

  public getPayload = async (
    patient: IPatient,
    dcItemName: string,
    questions: IQuestionnaireQuestion[],
    answers: number[],
    score: number,
    references?: IQuestionnaireReference[],
    submittedByUser?: IUser,
    submittedDate?: string,
  ): Promise<IPrintQuestionnaireDocumentPage[]> => {
    const patientPayload = this.buildPatientPayload(patient);
    const questionsWithAnswers = this.buildQuestionsWithAnswers(questions, answers);

    return this.buildDocumentPages(
      patientPayload,
      questionsWithAnswers,
      dcItemName,
      score,
      references,
      submittedByUser,
      submittedDate,
    );
  };

  private buildPatientPayload = (patient: IPatient): IPrintPayloadPatient => {
    const dobString = convertToArborDate(patient.dob, true).getUtcDate(true);
    const payload = {
      name: formatPatientNameShort(patient),
      dob: dobString,
      source_patient_id: patient.source_patient_id,
    };

    return payload;
  };

  private buildQuestionsWithAnswers(
    questions: IQuestionnaireQuestion[],
    answers: number[],
  ): IPrintPayloadQuestionAnswer[] {
    const questionsWithAnswers = [];
    for (let i = 0; i < questions.length; i += 1) {
      // Need to explicitly check for false, because isActive may not be present
      if (questions[i].isActive !== false) {
        questionsWithAnswers.push(this.buildQuestionWithAnswer(questions[i], answers[i]));
      }
    }
    return questionsWithAnswers.map((qa, currentIndex) => {
      return {
        ...qa,
        questionNumber: currentIndex + 1,
      };
    });
  }

  private buildQuestionWithAnswer(
    question: IQuestionnaireQuestion,
    answer?: number,
  ): IPrintPayloadQuestionAnswer {
    const optionsWithSelected = question.options?.map(o => {
      return {
        ...o,
        selected: o.value?.toString() === answer?.toString(),
      };
    });
    const payload = {
      isRange: question.type === 'range',
      minText: question.minText,
      maxText: question.maxText,
      question: question.question,
      options: optionsWithSelected,
    };
    return payload;
  }

  private buildDocumentPages(
    patientPayload: IPrintPayloadPatient,
    questionsWithAnswers: IPrintPayloadQuestionAnswer[],
    questionnaireName: string,
    score?: number,
    referenceRanges?: IQuestionnaireReference[],
    submittedByUser?: IUser,
    submittedDate?: string,
  ): IPrintQuestionnaireDocumentPage[] {
    // Split the question and answers by page.
    const qaPages = this.splitQuestionsByPage(questionsWithAnswers);

    // Format non-question-answer related data
    const submittedDateArborDate = convertToArborDate(submittedDate, true);
    const submittedDateFormatted = submittedDateArborDate.getUtcDate(true);
    const submittedByUserFormatted = submittedByUser?.display_name ?? 'Patient';
    const documentTitle = `Questionnaire ${questionnaireName}`;

    // Create the pages, with the question and answers for the current page and other data.
    const numPages = qaPages.length;
    const documentPages = qaPages.map(
      (
        qasForCurrentPage: IPrintPayloadQuestionAnswer[],
        pageIndex: number,
      ): IPrintQuestionnaireDocumentPage => {
        const page: IPrintQuestionnaireDocumentPage = {
          template: DocumentTemplate.DC_Questionnaire,
          data: {
            questions: qasForCurrentPage,
            patient: patientPayload,
            submittedByUser: submittedByUserFormatted,
            submittedDate: submittedDateFormatted,
            totalPages: numPages,
            pageNumber: pageIndex + 1,
          },
        };

        // Add the title to the first page
        const isFirstPage = pageIndex === 0;
        const pageWithTitleIfNeeded = isFirstPage ? this.addTitleToPage(page, documentTitle) : page;

        // Add the score and reference ranges to the last page
        const isLastPage = pageIndex === numPages - 1;
        const pageWithScoreIfNeeded = isLastPage
          ? this.handleLastPage(pageWithTitleIfNeeded, score, referenceRanges)
          : pageWithTitleIfNeeded;
        return pageWithScoreIfNeeded;
      },
    );

    return documentPages;
  }

  private splitQuestionsByPage(
    questionsWithAnswers: IPrintPayloadQuestionAnswer[],
  ): IPrintPayloadQuestionAnswer[][] {
    const qaPages = [];
    const numberOfQuestionsPerPage = QuestionnairePrintPayload.NUMBER_OF_QUESTIONS_PER_PAGE;
    const totalPages = Math.ceil(questionsWithAnswers.length / numberOfQuestionsPerPage);
    for (let i = 0; i < totalPages; i += 1) {
      const sliceStart = i * numberOfQuestionsPerPage;
      const sliceEnd = sliceStart + numberOfQuestionsPerPage;
      const pageSlice = questionsWithAnswers.slice(sliceStart, sliceEnd);
      qaPages.push(pageSlice);
    }
    return qaPages;
  }

  private addTitleToPage(
    page: IPrintQuestionnaireDocumentPage,
    title: string,
  ): IPrintQuestionnaireDocumentPage {
    return {
      ...page,
      data: {
        ...page.data,
        title,
      },
    };
  }

  private handleLastPage(
    page: IPrintQuestionnaireDocumentPage,
    score?: number,
    referenceRanges?: IQuestionnaireReference[],
  ): IPrintQuestionnaireDocumentPage {
    // Add a selected property to the reference range where the score falls

    const refRangesWithSelected = referenceRanges?.map(rr => {
      const rangeValuesDefined = rr.from !== undefined && rr.to !== undefined;
      const selected =
        score !== undefined && rangeValuesDefined && rr.from <= score && score <= rr.to;
      return {
        ...rr,
        selected,
      };
    });

    return {
      ...page,
      data: {
        ...page.data,
        score,
        referenceRanges: refRangesWithSelected,
      },
    };
  }
}

export { QuestionnairePrintPayload };

import React, { useEffect, useState } from 'react';
import { IDocumentInfo, IPatientMergeSectionDetailsProps } from 'components/patient-merge-modal/interfaces';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/styles';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { DocumentInfo } from 'components/patient-merge-modal/info-component/document-info';
import { useSelector } from 'react-redux';
import { IState } from 'interfaces/redux/IState';
import { PatientMergeClient } from 'clients/patient-merge';
import { LookupResourceTypes } from 'constants/enums';

export const ConfirmDocumentSection = (props: IPatientMergeSectionDetailsProps) => {
  const { mergeId, expanded } = props;
  const [data, setData] = useState<IDocumentInfo[]>([]);
  const [loaded, setLoaded] = useState(false);

  const [primaryData, setPrimaryData] = useState<IDocumentInfo[]>([]);
  const [selectedData, setSelectedData] = useState<IDocumentInfo[]>([]);
  const [additionalData, setAdditionalData] = useState<IDocumentInfo[]>([]);

  const primary = useSelector((state: IState) => state.patientMerge.primary);
  const selectedProfileId = useSelector((state: IState) => state.patientMerge.duplicate);
  const inputs = useSelector((state: IState) => state.patientMerge.inputs);

  useEffect(() => {
    if (!loaded && expanded) {
      PatientMergeClient.getMergeDocumentInfo(mergeId)
        .then(response => {
          setData(response.data);
          setLoaded(true);
        });
    }
  }, [mergeId, expanded]);

  useEffect(() => {
    setPrimaryData(
      data.filter(document => document.patientId === primary),
    );
  }, [primary, data]);
  useEffect(() => {
    setSelectedData(
      data.filter(document => document.patientId === selectedProfileId),
    );
  }, [selectedProfileId, data]);

  useEffect(() => {
    const ids = inputs
      .filter(input => input.type === LookupResourceTypes.Document)
      .map(input => input.selectedId);
    setAdditionalData(
      selectedData.filter(document => ids.includes(document.id)),
    );
  }, [selectedData, inputs]);

  const theme = useTheme();
  const classes = PatientMergeStyles(theme);

  return (
    <Grid container spacing={3}>
      <Grid container sx={classes.confirmDocumentWrp} item xs={12}>
        {additionalData.map((document: IDocumentInfo) => {
          return (
            <Grid xs={12} sx={classes.newItem}>
              <DocumentInfo document={document} />
            </Grid>
          );
        })}
        {primaryData.map((document: IDocumentInfo) => {
          return <DocumentInfo document={document} />;
        })}
      </Grid>
    </Grid>
  );
};

import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { CommunicationIcon } from 'components/icons/icons';
import compose from 'recompose/compose';
import { openCommunicationNotesSideBar } from 'actions/action-notes';
import React from 'react';
import { NoteBarTrigger } from 'constants/enums';
import { styles } from './note-styles';

const Communication = props => {
  const {
    classes,
    tagTypeId,
    tagResourceId,
    task,
    buttonOverride,
    id,
    ongoingPhoneCall,
    tagName,
    therapy,
    openCommunicationNotesSideBarAction,
    disabled,
  } = props;

  const clickNote = e => {
    e.stopPropagation();

    const payload = {
      noteBar: {
        display: true,
        tag: tagName,
        tagTypeId,
        tagResourceId,
        trigger: NoteBarTrigger.Contact,
        initiatingTask: task,
      },
      therapy,
    };

    openCommunicationNotesSideBarAction(payload);
  };

  let buttonEl = (
    <Button
      data-qa-id="button-addContactToTask"
      variant="outlined"
      className={classNames(classes.button, classes.communicationButton)}
      onClick={e => {
        clickNote(e);
      }}
      disabled={ongoingPhoneCall || disabled}
      id={id}
    >
      <span className={classes.communicationIconContainer}>
        <CommunicationIcon />
      </span>
      Contact
    </Button>
  );

  if (buttonOverride) {
    buttonEl = (
      <button
        data-qa-id="button-overview-addContact"
        disabled={disabled}
        style={{
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          outline: 'none',
        }}
        type="button"
        onClick={e => {
          clickNote(e);
        }}
      >
        {buttonOverride}
      </button>
    );
  }

  return <>{buttonEl}</>;
};

function mapStateToProps(state) {
  const { view } = state;
  const { ongoingPhoneCall } = view;
  return {
    ongoingPhoneCall,
  };
}

export default compose(
  withStyles(styles),
  connect(() => mapStateToProps, {
    openCommunicationNotesSideBarAction: openCommunicationNotesSideBar,
  }),
)(Communication);

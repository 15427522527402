import React from 'react';
import { useDispatch } from 'react-redux';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { BaseTable } from 'containers/application-manager/base-table/base-table';
import { IGetPayorDataResponse } from 'models/application-manager/cpmp/IGetPayorDataResponse';
import { Popover } from '@mui/material';
import { notifyError, notifySuccess } from 'actions/action-notifications';

import { IHeaderCell } from '../../../base-table/types';
import { IResponse, IResult } from './types';
import { styles } from '../../index.styles';
import { payorStatusFormatter } from '../../utils';
import { FieldTypes } from '../../../types';
import { IPopoverStateBase } from '../../../ar/types';
import { FormField } from '../../../popover-field-forms';
import { logger } from '../../../../../winston-logger';

interface ITableProps {
  searchTerm: string | undefined;
  triggerReload: Date | undefined;
  handleReloadDataTrigger: (date: Date | undefined) => void;
  activeAnyField: boolean;
  payorList: IGetPayorDataResponse;
  userHasPermissionToEditPayorStatusItems: boolean;
}

type IPopoverState = IPopoverStateBase<IResult>

const initialPopoverState = {
  open: false,
} as IPopoverState;

export const DataTable: React.FC<ITableProps> = (props): JSX.Element => {
  const classes = styles();
  const dispatch = useDispatch();

  const [popoverState, setPopoverState] = React.useState<IPopoverState>(initialPopoverState);

  // # region helper functions
  const getPayorListData = React.useMemo<IResponse | undefined>(() => {
    const payorListData = props.payorList.result;
    if (payorListData.length) {
      const payorListRecords = payorListData.reduce((acc, item) => {
        if (Object.keys(item).length) {
          acc.push({
            bin: item.bin,
            pcn: item.pcn,
            group: item.group,
            patientCount: item.patientCount,
            locked: item.locked,
            children: [],
          });
        }
        return acc;
      }, [] as IResult[]);
      return { totalCount: payorListRecords.length, results: payorListRecords };
    }
    return undefined;
  }, [props.payorList]);
  // #endregion

  const afterRequestSuccess = (callbacks: (() => void)[] | undefined = undefined) => {
    dispatch(notifySuccess('Saved'));
    if (callbacks) {
      callbacks.forEach(cb => cb());
    }
  };

  const onSubmitFieldUpdate = async (value: unknown) => {
    try {
      const request = ApplicationManagerClient.updatePayorData(
        popoverState.parent.bin,
        popoverState.parent.pcn,
        popoverState.parent.group,
        value === true ? 1 : 0,
      );
      await request;

      afterRequestSuccess([() => setPopoverState(initialPopoverState)]);
      props.handleReloadDataTrigger(new Date());
    } catch (error) {
      logger.error(error);
      dispatch(notifyError('An error occurred'));
    } finally {
      setPopoverState(initialPopoverState);
    }
  };

  const renderPopover = (): JSX.Element => {
    const handleCancel = () => setPopoverState(initialPopoverState);
    const handleSubmit = (value: unknown) => onSubmitFieldUpdate(value);

    return (
      <Popover
        id="form"
        open={popoverState.open}
        anchorEl={popoverState?.anchorElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transitionDuration={{ exit: 0 }}
        onClose={() => setPopoverState(initialPopoverState)}
      >
        <FormField
          data-qa-id={`${popoverState.field}-${popoverState.parent?.bin || ''}`}
          initialValue={popoverState.value}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          fieldType={popoverState.fieldType}
        />
      </Popover>
    );
  };

  const openPopover = (
    element: HTMLElement,
    value: unknown,
    config: {
      parent: IResult;
      fieldType: FieldTypes;
      field: keyof IResult;
      forceAsParent?: boolean;
    },
  ): void => {
    if (config.parent === null) {
      throw new Error('Cannot select a parent cell.');
    }
    setPopoverState({
      open: true,
      anchorElement: element,
      parent: config.parent,
      field: config.field,
      fieldType: config.fieldType,
      value: value,
      forceAsParent: config.forceAsParent,
    });
  };

  // # region constants
  const COLUMN_SETTINGS: IHeaderCell<IResult, undefined>[] = [
    {
      label: 'BIN',
      parentKey: 'bin',
      hideLeftPadding: true,
      childColSpan: 2,
      sortable: true,
    },
    {
      label: 'PCN',
      parentKey: 'pcn',
      sortable: true,
      hideLeftPadding: true,
    },
    {
      label: 'GROUP',
      parentKey: 'group',
      sortable: true,
      hideLeftPadding: true,
    },
    {
      label: 'PATIENT COUNT',
      parentKey: 'patientCount',
      sortable: true,
      hideLeftPadding: true,
    },
    {
      label: 'STATUS',
      parentKey: 'locked',
      sortable: true,
      hideLeftPadding: true,
      parentValueFormatter: payorStatusFormatter,
      parentCellClick: (event, parent) => {
        openPopover(event.currentTarget, parent.locked, {
          parent: parent,
          field: 'locked',
          fieldType: FieldTypes.LockedUnlockedForm,
        });
      },
    },
  ];
  // #endregion

  return (
    <>
      {props.userHasPermissionToEditPayorStatusItems ? renderPopover() : null}
      <BaseTable<IResponse, IResult, undefined>
        actionsPermitted={props.userHasPermissionToEditPayorStatusItems}
        orderByDefaultParent="bin"
        dataSet={getPayorListData}
        paginationQueryParamSettings={{
            pageSizeQueryStringKey: 'pageSize',
            pageNumberQueryStringKey: 'pageNumber',
            searchTermQueryStringKey: 'searchTerm',
            sortPropQueryStringKey: 'sortProp',
            sortOrderQueryStringKey: 'sortOrder',
          }}
        childPkSelector={() => ''}
        parentPkSelector={p => p.bin}
        columnSettings={COLUMN_SETTINGS}
        searchTerm={JSON.stringify({
            type: 'MULTIPLE',
            search: [
              {
                values: props.searchTerm,
                columns: ['bin', 'pcn', 'group'],
                fullMatch: false,
                active: props.activeAnyField,
              },
            ],
          })}
        triggerReload={props.triggerReload}
      />
    </>
  );
};

import HTTP from 'services/http';
import { AxiosPromise } from 'axios';
import { IInterpreterServiceRequest } from 'models/services/interpreter-service/IInterpreterService';

export class InterpreterService {
  private interpreterServiceByPatientId = (
    patientId: number,
  ): AxiosPromise<IInterpreterServiceRequest> => {
    const url = `/patients/${patientId}/interpreter-service`;
    return HTTP.get(url) as AxiosPromise<IInterpreterServiceRequest>;
  };

  public getInterpreterService = async (
    patientId: number,
  ): Promise<IInterpreterServiceRequest | null> => {
    const request = this.interpreterServiceByPatientId(patientId);
    const result = await request;

    if (result) {
      return result.data;
    }

    return null;
  };
}

import { combineWithState } from 'services/utils/reducer-service';
import {
  DATA_MEDICAL_INSURANCES_REQUEST,
  DATA_MEDICAL_INSURANCES_SUCCESS,
  DATA_MEDICAL_INSURANCES_FAILURE,
  DATA_PBM_INSURANCES_REQUEST,
  DATA_PBM_INSURANCES_SUCCESS,
  DATA_PBM_INSURANCES_FAILURE,
  DATA_INCOMES_REQUEST,
  DATA_INCOMES_SUCCESS,
  DATA_INCOMES_FAILURE,
  DATA_FINANCIAL_ASSISTANCE_REQUEST,
  DATA_FINANCIAL_ASSISTANCE_SUCCESS,
  DATA_FINANCIAL_ASSISTANCE_FAILURE,
  ADD_MEDICAL_INSURANCE,
  EDIT_MEDICAL_INSURANCE,
  ADD_PBM_INSURANCE,
  EDIT_PBM_INSURANCE,
  ADD_INCOME,
  EDIT_INCOME,
  FETCH_EXISTING_INCOME_YEARS,
  FETCH_HAS_PRIMARY_MEDICAL_INSURANCE,
  FETCH_HAS_PRIMARY_PBM_INSURANCE,
  ADD_FINANCIAL_ASSISTANCE,
  EDIT_FINANCIAL_ASSISTANCE,
  FETCH_LOOKUPS,
  SELECTED_PATIENT_ID,
  LINK_FINANCIAL_ASSISTANCE,
} from '../constants';
import { getReducerDataInitialState, convertListToMap } from './helper-reducer';

const initialState = {
  medical_insurances: getReducerDataInitialState(),
  pbm_insurances: getReducerDataInitialState(),
  incomes: getReducerDataInitialState(),
  income_years: [],
  financial_assistances: getReducerDataInitialState(),
  financial_assistance_category_statuses: [],
  has_primary_pbm_insurance: null,
  has_primary_medical_insurance: null,
  has_linked: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SELECTED_PATIENT_ID: {
      return {
        ...initialState,
        financial_assistance_category_statuses: state.financial_assistance_category_statuses,
      };
    }
    case DATA_MEDICAL_INSURANCES_REQUEST: {
      return {
        ...state,
        medical_insurances: {
          loading: true,
          data: {},
          error: false,
          loaded: false,
        },
      };
    }
    case DATA_MEDICAL_INSURANCES_SUCCESS: {
      return {
        ...state,
        medical_insurances: {
          loading: false,
          data: convertListToMap(payload, 'id'),
          error: false,
          loaded: false,
        },
      };
    }
    case DATA_MEDICAL_INSURANCES_FAILURE: {
      return {
        ...state,
        medical_insurances: {
          loading: false,
          data: {},
          error: true,
          loaded: false,
        },
      };
    }
    case FETCH_HAS_PRIMARY_MEDICAL_INSURANCE: {
      return {
        ...state,
        has_primary_medical_insurance: payload.data ? payload.data.has_primary : null,
      };
    }
    case FETCH_HAS_PRIMARY_PBM_INSURANCE: {
      return {
        ...state,
        has_primary_pbm_insurance: payload.data ? payload.data.has_primary : null,
      };
    }
    case DATA_PBM_INSURANCES_REQUEST: {
      return {
        ...state,
        pbm_insurances: {
          loading: true,
          data: {},
          error: false,
          loaded: false,
        },
      };
    }
    case DATA_PBM_INSURANCES_SUCCESS: {
      return {
        ...state,
        pbm_insurances: {
          loading: false,
          data: convertListToMap(payload, 'id'),
          error: false,
          loaded: false,
        },
      };
    }
    case DATA_PBM_INSURANCES_FAILURE: {
      return {
        ...state,
        pbm_insurances: {
          loading: false,
          data: {},
          error: true,
          loaded: false,
        },
      };
    }
    case DATA_INCOMES_REQUEST: {
      return {
        ...state,
        incomes: {
          loading: true,
          data: {},
          error: false,
          loaded: false,
        },
      };
    }
    case DATA_INCOMES_SUCCESS: {
      return {
        ...state,
        incomes: {
          loading: false,
          data: convertListToMap(payload, 'id'),
          error: false,
          loaded: false,
        },
      };
    }
    case DATA_INCOMES_FAILURE: {
      return {
        ...state,
        incomes: {
          loading: false,
          data: {},
          error: true,
          loaded: false,
        },
      };
    }
    case FETCH_EXISTING_INCOME_YEARS: {
      return {
        ...state,
        income_years: payload.data ? payload.data.income_years : [],
      };
    }
    case DATA_FINANCIAL_ASSISTANCE_REQUEST: {
      return {
        ...state,
        financial_assistances: {
          loading: true,
          data: {},
          error: false,
          loaded: false,
        },
      };
    }
    case DATA_FINANCIAL_ASSISTANCE_SUCCESS: {
      return {
        ...state,
        financial_assistances: {
          loading: false,
          data: convertListToMap(payload, 'id'),
          error: false,
          loaded: true,
        },
      };
    }
    case DATA_FINANCIAL_ASSISTANCE_FAILURE: {
      return {
        ...state,
        financial_assistances: {
          loading: false,
          data: {},
          error: true,
          loaded: false,
        },
      };
    }
    case ADD_FINANCIAL_ASSISTANCE: {
      return {
        ...state,
        financial_assistances: combineWithState(
          state.financial_assistances,
          convertListToMap([payload.data], 'id'),
        ),
      };
    }
    case LINK_FINANCIAL_ASSISTANCE: {
      return {
        ...state,
        has_linked: payload.data?.task,
        financial_assistances: combineWithState(
          state.financial_assistances,
          convertListToMap([payload.data], 'id'),
        ),
      };
    }
    case EDIT_FINANCIAL_ASSISTANCE: {
      return {
        ...state,
        financial_assistances: combineWithState(
          state.financial_assistances,
          convertListToMap([payload.data], 'id'),
        ),
      };
    }
    case ADD_MEDICAL_INSURANCE:
      return {
        ...state,
        medical_insurances: combineWithState(
          state.medical_insurances,
          convertListToMap(payload.data.added_insurance, 'id'),
        ),
      };
    case EDIT_MEDICAL_INSURANCE:
      return {
        ...state,
        medical_insurances: combineWithState(
          state.medical_insurances,
          convertListToMap(payload.data.updated_insurance, 'id'),
        ),
      };
    case ADD_PBM_INSURANCE:
      return {
        ...state,
        pbm_insurances: combineWithState(
          state.pbm_insurances,
          convertListToMap(payload.data.added_insurance, 'id'),
        ),
      };
    case EDIT_PBM_INSURANCE:
      return {
        ...state,
        pbm_insurances: combineWithState(
          state.pbm_insurances,
          convertListToMap(payload.data.updated_insurance, 'id'),
        ),
      };
    case ADD_INCOME:
      return {
        ...state,
        incomes: combineWithState(state.incomes, convertListToMap(payload.data.added_income, 'id')),
      };
    case EDIT_INCOME:
      return {
        ...state,
        incomes: combineWithState(
          state.incomes,
          convertListToMap(payload.data.updated_income, 'id'),
        ),
      };
    case FETCH_LOOKUPS:
      if (payload.data.lookups) {
        return {
          ...state,
          financial_assistance_category_statuses:
            payload.data.lookups.lookup_financial_assistance_types,
        };
      }
      return state;
    default:
      return state;
  }
};

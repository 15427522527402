import React from 'react';
import { Modal, Typography, Grid, Button } from '@mui/material';
import { getModalStyle } from 'services/utils/styles-service';
import LoadingOverlay from 'components/loading-overlay/loading-overlay';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { UserId, CustomerId } from 'interfaces/RecordTypes';
import { CircularLoadingButton } from 'components/circular-loading-button/circular-loading-button';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { useDispatch } from 'react-redux';
import { buildQaId } from 'utils/build-qa-id';
import { IFiltersCustomerItem } from 'interfaces/redux/IFilters';
import { logger } from '../../../../winston-logger';
import { styles } from './decredential-user-modal.styles';

export interface IRemoveUserFromCustomerModal {
  open: boolean;
  onCancel: () => void;
  afterSuccess: () => void;
  userId: UserId;
  customerId: CustomerId;
  newRoleId: number;
}
export interface IModalState {
  open: boolean;
  submitting?: boolean;
  header?: string;
}
const initialModalState: IModalState = {
  open: false,
  submitting: false,
};

const buildText = (name: string, site: IFiltersCustomerItem | undefined): JSX.Element => {
  return (
    <Typography>{`You are removing the credentials for ${name} from ${site?.name}. Their credentials for this site will be no longer active and they will have only non-credentialed access. Do you want to continue?`}</Typography>
  );
};

const getQaId = buildQaId('application-manager.users.decredential-user-modal', '.');

export const DecredentialUserModal: React.FC<IRemoveUserFromCustomerModal> = (
  props: IRemoveUserFromCustomerModal,
): JSX.Element | null => {
  const classes: any = styles();

  // #region component state
  const [loading, setLoading] = React.useState<boolean>(true);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [modalState, setModalState] = React.useState<IModalState>(initialModalState);

  const users = useTypedSelector(state => state.lookups.users);
  const customers = useTypedSelector(state => state.filters.customers);
  const dispatch = useDispatch();
  // #endregion

  // #region memos
  const textElement = React.useMemo<JSX.Element | null>(() => {
    let textElement: JSX.Element | null = null;

    if (props.userId) {
      const user = users.find(x => x.id === props.userId);
      const currentSite = customers.find(x => x.id === props.customerId);

      if (user) {
        textElement = buildText(user.display_name, currentSite);
      }
      setLoading(false);
    }

    return textElement;
  }, [props.userId, users]);
  // #endregion

  // #region helper functions
  const handleConfirm = async (): Promise<void> => {
    setSubmitting(true);
    try {
      setModalState({ open: true, submitting: true });
      if (props.userId && props.customerId && props.newRoleId) {
        const request = ApplicationManagerClient.changeUserRoleAtCustomerV2(
          props.userId,
          props.customerId,
          props.newRoleId,
        );
        await request;
        dispatch(notifySuccess('Decredentialed user'));
        props.afterSuccess();
      } else {
        throw new Error('decredential-user-modal - userId, customerId and newRoleId are not set.');
      }
    } catch (error) {
      logger.error('Error updating customer role', { error });
      dispatch(notifyError('Error updating user role'));
    } finally {
      setSubmitting(false);
      setModalState(initialModalState);
      props.onCancel();
    }
  };
  // #endregion

  return props.open && props.userId && props.customerId ? (
    <Modal open={props.open} data-qa-id={getQaId('wrapper')}>
      <div style={getModalStyle()} className={classes.modal}>
        <LoadingOverlay open={loading} />
        <Grid container direction="column" spacing={2}>
          <Grid item>{textElement}</Grid>
          <Grid item>
            <Grid container justifyContent="flex-end" spacing={1}>
              <Grid item>
                <Button
                  onClick={() => {
                    props.onCancel();
                  }}
                  data-qa-id={getQaId('button.cancel')}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <CircularLoadingButton
                  data-qa-id={getQaId('button.confirm')}
                  onClick={() => {
                    handleConfirm();
                  }}
                  isLoading={submitting}
                  buttonLabel="Confirm"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  ) : null;
};

import React from 'react';
import { Grid, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { LockedUnlockedFormStyles } from './locked-unlocked-form.styles';

interface ILockedUnlockedFormProps extends WithStyles<typeof LockedUnlockedFormStyles> {
  initialValue: boolean;
  handleCancel: () => void;
  handleSubmit: (value: boolean) => void;
}

/** This isn't really a "form" per se, but it's still rendered in a popout thing.  */
const LockedUnlockedForm: React.FC<ILockedUnlockedFormProps> = (
  props: ILockedUnlockedFormProps,
): JSX.Element => {
  const { classes } = props;

  // #endregion

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid
        item
        className={classes.option}
        onClick={() => {
          props.handleSubmit(true);
        }}
      >
        <Typography align="center" className={classes.optionType}>
          Locked
        </Typography>
      </Grid>
      <Grid
        item
        className={classes.option}
        onClick={() => {
          props.handleSubmit(false);
        }}
      >
        <Typography align="center" className={classes.optionType}>
          Unlocked
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(LockedUnlockedFormStyles)(LockedUnlockedForm);

import React from 'react';
import { Grid, Button, CircularProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { styles } from './confirmation-panel-styles';

const ConfirmationPanel = props => {
  const {
    buttonIdPrefix,
    cancelButtonName,
    cancelButtonText,
    className,
    classes,
    disableCancel = false,
    disableSubmit,
    disableSubmitAndReview,
    handleCancel,
    handleSubmit,
    hideCancel,
    hideSubmit,
    hideSubmitAndReview,
    isLoading = false,
    justifyContent,
    leftAlignedButtons,
    loadingText = 'Loading ...',
    saveButtonQaId,
    cancelButtonQaId,
    otherButtons,
    style,
    submitAndReviewButtonName,
    submitAndReviewButtonText,
    submitButtonClass,
    submitButtonName,
    submitButtonText,
  } = props;

  const saveButtonId = buttonIdPrefix ? `${buttonIdPrefix}_save` : null;
  const saveAndReviewButtonId = buttonIdPrefix ? `${buttonIdPrefix}_save_and_review` : null;
  const cancelButtonId = buttonIdPrefix ? `${buttonIdPrefix}_cancel` : null;

  const submitProps = handleSubmit
    ? { onClick: handleSubmit, type: 'button', disabled: !!disableSubmit }
    : { type: 'submit', disabled: !!disableSubmit };

  const saveButton = (
    <Button
      {...submitProps}
      name={submitButtonName}
      color="primary"
      variant="contained"
      className={submitButtonClass || classes.saveButton}
      id={saveButtonId}
      data-qa-id={saveButtonQaId || 'confirmation-panel-submit-button'}
      disabled={isLoading || submitProps.disabled}
    >
      {isLoading ? loadingText : submitButtonText || 'Save'}
      {isLoading && <CircularProgress color="secondary" className={classes.loading} size={20} />}
    </Button>
  );

  const saveAndReviewButton = (
    <Button
      name={submitAndReviewButtonName}
      type="submit"
      disabled={!!disableSubmitAndReview}
      color="primary"
      variant="contained"
      className={submitButtonClass || classes.saveButton}
      id={saveAndReviewButtonId}
      data-qa-id="confirmation-panel-submit-and-review-button"
    >
      {submitAndReviewButtonText || 'Save and Mark As Reviewed'}
    </Button>
  );

  return (
    <Grid
      className={className}
      style={style}
      container
      justifyContent={justifyContent || 'flex-end'}
      alignItems="flex-end"
    >
      <Grid container>
        {leftAlignedButtons &&
          leftAlignedButtons
            .filter(button => button)
            // eslint-disable-next-line arrow-body-style
            .map(otherButton => {
              return (
                <Grid item xs={6}>
                  <Grid item key={otherButton.key}>
                    {otherButton.button}
                  </Grid>
                </Grid>
              );
            })}
        <Grid item xs={leftAlignedButtons ? 6 : 12}>
          <Grid container justifyContent="flex-end" alignItems="center">
            {!hideCancel && (
              <Grid item>
                <Button
                  name={cancelButtonName}
                  type="button"
                  onClick={handleCancel}
                  className={classes.cancelButton}
                  id={cancelButtonId}
                  data-qa-id={cancelButtonQaId}
                  disabled={disableCancel}
                >
                  {cancelButtonText || 'Cancel'}
                </Button>
              </Grid>
            )}
            {otherButtons &&
              Array.isArray(otherButtons) &&
              otherButtons
                .filter(button => button)
                // eslint-disable-next-line arrow-body-style
                .map(otherButton => {
                  return (
                    <Grid item key={otherButton.button?.props.name}>
                      {otherButton.button}
                    </Grid>
                  );
                })}
            {!hideSubmit && <Grid item>{saveButton}</Grid>}
            {hideSubmitAndReview !== undefined && !hideSubmitAndReview && (
              <Grid item>{saveAndReviewButton}</Grid>
            )}
            {otherButtons &&
              otherButtons.align === 'end' &&
              otherButtons.buttons
                .filter(button => button)
                // eslint-disable-next-line arrow-body-style
                .map(otherButton => {
                  return (
                    <Grid item key={otherButton.key}>
                      {otherButton.button}
                    </Grid>
                  );
                })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(ConfirmationPanel);

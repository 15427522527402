import { Action } from 'redux';
import { INoteTagForm } from 'interfaces/redux/INotes';

import {
  SCREEN_RESIZE,
  TOGGLE_LEFT_DRAWER,
  TOGGLE_FAB_PANEL,
  TOGGLE_PHONE_PANEL,
  TOGGLE_ONGOING_PHONE_CALL,
  SET_ACTIVE_SIDEBAR,
  SET_INITIAL_URL,
  SET_PHONE_NOTE_TAGS,
} from '../constants/index';

export function setActiveSidebar(sidebar: string) {
  return {
    type: SET_ACTIVE_SIDEBAR,
    payload: sidebar,
  };
}

export function setInitialUrl(url: string) {
  return {
    type: SET_INITIAL_URL,
    payload: url,
  };
}

export function togglePhonePanel(open: boolean) {
  return {
    type: TOGGLE_PHONE_PANEL,
    payload: open,
  };
}

export function toggleOngoingPhoneCall(open: boolean) {
  return {
    type: TOGGLE_ONGOING_PHONE_CALL,
    payload: open,
  };
}

export function setPhoneNoteTags(tags: INoteTagForm) {
  return {
    type: SET_PHONE_NOTE_TAGS,
    payload: tags,
  };
}

class ActiveSidebar implements Action {
  readonly type = SET_ACTIVE_SIDEBAR;

  constructor(public payload: string | undefined) {}
}

export class ToggleFabPanel implements Action {
  readonly type = TOGGLE_FAB_PANEL;

  constructor(public payload: boolean | undefined) {}
}

export class TogglePhonePanel implements Action {
  readonly type = TOGGLE_PHONE_PANEL;

  constructor(public payload: boolean | undefined) {}
}

export class ToggleOngoingPhoneCall implements Action {
  readonly type = TOGGLE_ONGOING_PHONE_CALL;

  constructor(public payload: boolean | undefined) {}
}

export class SetPhoneNoteTags implements Action {
  readonly type = SET_PHONE_NOTE_TAGS;

  constructor(public payload: INoteTagForm | undefined) {}
}

export class ToggleLeftDrawer implements Action {
  readonly type = TOGGLE_LEFT_DRAWER;

  constructor(public payload: boolean | undefined) {}
}

export class ScreenResize implements Action {
  readonly type = SCREEN_RESIZE;

  constructor(public payload: number) {}
}

export class InitialUrl implements Action {
  readonly type = SET_INITIAL_URL;

  constructor(public payload: string | undefined) {}
}

export type ViewAction =
  | ToggleFabPanel
  | TogglePhonePanel
  | ToggleOngoingPhoneCall
  | SetPhoneNoteTags
  | ToggleLeftDrawer
  | ScreenResize
  | ActiveSidebar
  | InitialUrl;

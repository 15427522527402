import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Switch, Route } from 'react-router-dom';
import Report from 'containers/report/report';
import { styles } from '../nav-styles';

const Reports = ({ classes }) => (
  <div className={classes.reportContent}>
    <Switch>
      <Route component={Report} />
    </Switch>
  </div>
);

export default withStyles(styles, { withTheme: true })(Reports);

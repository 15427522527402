import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from '../../utils/theme-util';

const styles = (theme: Theme) => {
  const { palette, fonts } = expandTheme(theme);

  return createStyles({
    container: {
      position: 'absolute',
      minWidth: theme.spacing(120),
      width: '90%',
      backgroundColor: palette.primary.background,
      padding: 0,
      top: 'calc(50% + 56px)',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxHeight: 'calc(90vh - 90px)',
      boxSizing: 'border-box',
      boxShadow: 'none',
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      overflowY: 'auto',
      position: 'relative',
      border: `1px solid ${theme.palette.grey[400]}`,
    },
    instructions: {
      padding: '15px 30px',
    },
    tabs: {
      'marginTop': 0,
      '& > div > .MuiTabs-flexContainer': {
        maxHeight: 48,
      },
    },
    tab: {
      'backgroundColor': palette.primary.greySubRow,
      'borderRadius': '5px 5px 0 0',
      'border': `1px solid ${theme.palette.grey[400]}`,
      'marginRight': 2.5,
      'marginLeft': 2.5,
      'marginTop': 1,
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
    tabLabel: {
      justifyContent: 'space-between',
      display: 'flex',
      width: '100%',
      minWidth: theme.spacing(30),
    },
    selectedTab: {
      backgroundColor: palette.primary.background,
    },
    scrollButtons: {
      'width': 0,
      'borderRadius': '5px 5px 0 0',
      'position': 'relative',
      '& svg': {
        position: 'absolute',
        top: 15,
        backgroundColor: palette.primary.background,
        marginLeft: -35,
        zIndex: 1,
      },
      '&:first-child svg': {
        backgroundColor: palette.primary.background,
        marginLeft: 35,
      },
    },
    modalHeader: {
      backgroundColor: palette.primary.trellisLightBlue,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      padding: 15,
      marginBottom: theme.spacing(5),
      height: theme.spacing(3),
    },
    modalCloseButton: {
      float: 'right',
      top: '-28px',
    },
    modalTitle: {
      fontWeight: fonts.weight.medium,
    },
    modalContent: {
      overflowY: 'auto',
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 10,
    },
  });
};

export { styles };

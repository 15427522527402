import React from 'react';
import cx from 'classnames';
import Tooltip from '@mui/material/Tooltip';
import { InfoIcon } from 'components/icons/icons';
import ErrorIcon from '@mui/icons-material/Error';
import { useSelector } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { AUDIT_NOT_RESOLVED } from 'constants/index';
import { auditContext } from 'constants/lists';
import { findAuditByCategoryRuleAndStatus, getAuditTooltip } from 'services/utils/audit-service';
import { styles } from './display-styles';

const Audit = props => {
  const auditState = useSelector(state => state.audit);
  const patient = useSelector(state => state.patient);
  const tabControl = useSelector(state => state.tabControl);
  const selectedPatientId = useSelector(state => state.selectedPatientId);
  const patientTab = tabControl ? tabControl.patientTab : null;
  const {
    iconType,
    className,
    classes,
    context,
    auditRules,
    auditCategory = patientTab,
    auditStatus = AUDIT_NOT_RESOLVED,
    auditPatientId,
    resource = null,
    resourceValue = null,
  } = props;

  // TODO: Clean up and have tab/sidebar be outside of auditRules prop
  const auditsInState = [];
  // 'tab' or 'sidebar' check
  if (context === auditContext.Warning) {
    auditRules.forEach(rule => {
      const audit = findAuditByCategoryRuleAndStatus(
        auditState,
        patient,
        auditCategory,
        context,
        rule,
        auditStatus,
        resource,
        resourceValue,
      );
      if (audit) {
        auditsInState.push(audit);
      }
    });
  }

  const displayAudit =
    auditsInState.length > 0 || context === auditContext.Tab || context === auditContext.Sidebar;

  let styleDef;
  let iconDisplay;
  switch (iconType) {
    case 'info':
      iconDisplay = (
        <InfoIcon className={cx(styleDef, classes.auditSpacing, classes.warningAudit)} />
      );
      break;
    case 'warning':
      iconDisplay = (
        <ErrorIcon className={cx(styleDef, classes.auditSpacing, classes.warningAudit)} />
      );
      break;
    default:
      iconDisplay = <div />;
      break;
  }

  let tooltip = '';
  if (auditsInState.length > 0) {
    auditsInState.forEach(
      // eslint-disable-next-line no-return-assign
      (audit, idx) =>
        (tooltip +=
          idx === auditsInState.length - 1
            ? `${audit.definition.name}`
            : `${audit.definition.name}; `),
    );
  } else {
    tooltip = getAuditTooltip(
      null,
      context,
      auditCategory,
      auditState,
      selectedPatientId,
      auditPatientId,
    );
  }
  return (
    <div
      className={cx(styleDef, className, classes.warningAudit)}
      id={`audit_${auditPatientId}_${context}_${auditCategory}_${tooltip}`}
    >
      {displayAudit && (
        <Tooltip title={tooltip} placement="top-end">
          {iconDisplay}
        </Tooltip>
      )}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Audit);

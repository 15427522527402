import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { workListChanged } from 'actions/action-patient';
import { editScheduleOutreach, fetchTaskCounts } from 'actions/action-tasks';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { ReactSelectForReduxSingle } from 'components/form/field/react-select';
import RadioButton from 'components/form/radio/radio-button';
import RadioGroup from 'components/form/radio/radio-group';
import Validation, {
  required,
  validateDate,
  validateDateTime,
} from 'components/form/validation/validation';
import { EDIT_SCHEDULE_OUTREACH_FORM } from 'constants/index';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Field, reduxForm } from 'redux-form';
import { convertToArborDate } from 'models/time/arbor-date';
import { getInitialServiceGroupForReactSelect } from 'services/utils/task-service';
import { styles } from './schedule-outreach-styles';

class EditScheduleOutreach extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleServiceGroupDropdown = this.handleServiceGroupDropdown.bind(this);
    this.state = { serviceGroupDropdown: -1 };
  }

  handleServiceGroupDropdown(e) {
    this.setState({
      serviceGroupDropdown: e.target.value,
    });
  }

  // Handles the submit functionality. Right now it sets some fields to null for simplicity's sake
  handleSubmit(values) {
    const {
      editScheduleOutreach, // eslint-disable-line
      scheduleOutreach,
      cancel,
      workListChanged, // eslint-disable-line
      fetchTaskCounts, //eslint-disable-line
    } = this.props;

    const payload = {
      ...values,
      id: scheduleOutreach.id,
      followup_dt: convertToArborDate(values.followup_dt).getUtcDate(),
      last_checked_dt: convertToArborDate(values.last_checked_dt).getUtcDatetime(),
      service_group_id: values.service_group_id.value,
      scheduled_date: convertToArborDate(values.scheduled_date).getUtcDate(),
      will_not_meet_with_patient_date: convertToArborDate(
        values.will_not_meet_with_patient_date,
      ).getUtcDate(),
      completed_date: convertToArborDate(values.completed_date).getUtcDate(),
    };
    editScheduleOutreach(payload).then(() => {
      workListChanged();
      fetchTaskCounts();
    });
    cancel();
  }

  render() {
    const { handleSubmit, pristine, submitting, classes, cancel, serviceGroups } = this.props;

    const { serviceGroupDropdown } = this.state;

    const radioProps = {
      width: '100%',
      radius: 5,
    };

    const renderCommunicationType = ({ input, meta: { touched, error, warning } }) => (
      <Grid container justifyContent="center">
        <RadioGroup
          horizontal
          name="communication_type_id"
          id="communication_type_id"
          width="100%"
          {...input}
          className={classes.radioGroup}
        >
          <RadioButton {...radioProps} value="4">
            <Typography variant="body2">Phone</Typography>
          </RadioButton>
          <RadioButton {...radioProps} value="1">
            <Typography variant="body2">In Person</Typography>
          </RadioButton>
        </RadioGroup>
        <Validation touched={touched} error={error} warning={warning} />
      </Grid>
    );

    return (
      <Grid
        container
        onClick={e => {
          e.stopPropagation();
        }}
        justifyContent="flex-start"
        className={(classes.formRowContainer, classes.formContainer)}
      >
        <Grid item xs={12}>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            className={classes.editFormContainer}
            autoComplete="off"
          >
            <Grid container spacing={7} className={classes.formRowContainer}>
              <Grid item xs={4}>
                <Field
                  name="communication_type_id"
                  component={renderCommunicationType}
                  validate={[required]}
                />
              </Grid>
              <Grid item xs={8} />
              <Grid item xs={4} className={classes.fieldContainer}>
                <Field
                  name="followup_dt"
                  component={renderDatePicker}
                  label="Follow up Date *"
                  validate={[validateDate, required]}
                />
              </Grid>
              <Grid item xs={4} className={classes.fieldContainer}>
                <Field
                  name="last_checked_dt"
                  component={renderDatePicker}
                  label="Last Checked Date"
                  validate={[validateDateTime]}
                />
              </Grid>
              <Grid item xs={4} className={classes.fieldContainer}>
                <Field
                  name="scheduled_date"
                  component={renderDatePicker}
                  label="Scheduled Date"
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={4} className={classes.fieldContainer}>
                <Field
                  name="will_not_meet_with_patient_date"
                  component={renderDatePicker}
                  label="Will not meet with patient Date"
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={4} className={classes.fieldContainer}>
                <Field
                  name="completed_date"
                  component={renderDatePicker}
                  label="Completed Date"
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={4} className={classes.fieldContainer}>
                <Field
                  name="service_group_id"
                  label="Service Group *"
                  validate={[required]}
                  component={ReactSelectForReduxSingle}
                  fields={serviceGroups.map(int => ({
                    label: int.display_name,
                    value: int.id,
                    isDisabled: !int.active,
                  }))}
                  state={serviceGroupDropdown}
                />
              </Grid>
            </Grid>

            {/* Eighth Row */}
            <ConfirmationPanel
              cancelButtonName="edit_therapy_cancel_button"
              submitButtonName="edit_therapy_submit_button"
              handleCancel={cancel}
              disableSubmit={pristine || submitting}
            />
          </form>
        </Grid>
      </Grid>
    );
  }
}

function handleFormName(so) {
  if (!so) {
    return null;
  }
  const name = `${EDIT_SCHEDULE_OUTREACH_FORM}_${so.id}`;
  return name;
}

function mapStateToProps(state, props) {
  const { patient } = state;
  const { scheduleOutreach } = props;
  const name = handleFormName(scheduleOutreach);
  return {
    patient,
    form: name,
    initialValues: {
      communication_type_id:
        scheduleOutreach.communication_type_id && scheduleOutreach.communication_type_id.toString(),
      followup_dt: convertToArborDate(scheduleOutreach.followup_dt).getUtcDate(),
      last_checked_dt: convertToArborDate(scheduleOutreach.last_checked_dt, true) // eslint-disable-line
        .getCustomerDatetime(),
      scheduled_date: convertToArborDate(scheduleOutreach.scheduled_date).getUtcDate(),
      will_not_meet_with_patient_date: convertToArborDate(
        scheduleOutreach.will_not_meet_with_patient_date,
      ).getUtcDate(),
      completed_date: convertToArborDate(scheduleOutreach.completed_date).getUtcDate(),
      service_group_id: scheduleOutreach.service_group_id
        ? getInitialServiceGroupForReactSelect(
            scheduleOutreach.service_group_id,
            props.serviceGroups,
          )
        : '',
    },
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchTaskCounts,
    workListChanged,
    editScheduleOutreach,
  }),
)(reduxForm({})(EditScheduleOutreach));

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

export const IndicatorStyles = (theme: Theme) => {
  const { palette } = expandTheme(theme);
  return createStyles({
    check: {
      color: palette.primary.cuttySark,
      fontSize: 20,
      padding: theme.spacing(0.5),
    },
    cancel: {
      color: palette.primary.valenciaRed,
      fontSize: 20,
      padding: theme.spacing(0.5),
    },
    help: {
      color: palette.primary.yellow,
      fontSize: 20,
      padding: theme.spacing(0.5),
    },
  });
};

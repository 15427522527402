export const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  label: {
    color: theme.palette.primary.main,
  },
  listItem: {
    color: theme.palette.primary.formItem,
  },
  dropdownButton: {
    width: 10,
  },
});

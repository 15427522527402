import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

export const noBackgroundHover: any = {
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

export const rowIcon = (theme: Theme): any => {
  return {
    marginRight: theme.spacing(2),
  };
};

export const BaseTableStyles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    tableContainer: {
      position: 'relative',
      borderRadius: theme.spacing(2),
      marginTop: theme.spacing(3),
      border: `1px solid ${palette.primary.grey2}`,
    },
    tableHeadCell: {
      'borderRight': `1px solid ${palette.primary.grey2}`,
      'cursor': 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
      'paddingRight': theme.spacing(1),
    },
    tableHeadCheckbox: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      width: theme.spacing(3),
    },
    tableCellCheckbox: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    tableCell: {
      borderRight: `1px solid ${theme.palette.grey[300]}`,
      margin: '0 auto',
      maxWidth: '100px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    childFirstCell: {
      paddingLeft: theme.spacing(5.5),
    },
    tableCellAction: {
      'position': 'relative',
      '&:hover': {
        backgroundColor: theme.palette.grey[300],
      },
    },
    tableCellActionIcon: {
      position: 'absolute',
      right: theme.spacing(0.5),
    },
    evenParent: {},
    oddParent: {
      backgroundColor: 'rgba(0, 0, 0, 0.025)',
    },
    button: {
      backgroundColor: theme.palette.grey[200],
    },
    actionButton: {
      backgroundColor: palette.primary.trellisBlue,
      color: palette.primary.white,
    },
    expandButton: {
      ...rowIcon,
      ...noBackgroundHover,
    },
    parentRow: {
      'cursor': 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
    },
    childCheckbox: {
      marginRight: 0,
      marginLeft: theme.spacing(2.25),
    },
    childRow: {
      'backgroundColor': 'rgba(0, 0, 0, 0.06)',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    noResultsType: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    confirmationModalDrugItem: {
      maxHeight: '50vh',
      overflowY: 'auto',
      paddingBottom: theme.spacing(4),
    },
  });
};

export const UseStyles = makeStyles(BaseTableStyles);

/* eslint-disable arrow-body-style */
import { Box, Button, Grid } from '@mui/material';
import { LargeSpacer, SmallSpacer } from 'components/spacer/spacer';
import React, { Component } from 'react';
import { editPbmInsurance, fetchPbmInsurances } from 'actions/action-financials';

import DocumentDisplay from 'components/document/document-display';
import SubHeader from 'components/form/header/subheader';
import TalkdeskPhoneNumber from 'components/talkdesk-phone-number/talkdesk-phone-number';
import { VerificationPanel } from 'components/form/verification/verification-panel';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { convertToArborDate } from 'models/time/arbor-date';
import { getDocumentsForResource } from 'services/utils/document-service';
import { getUserById } from 'services/utils/users-service';
import moment from 'moment';
import withStyles from '@mui/styles/withStyles';
import { EDIT_PBM_INSURANCE_FORM, PBM_INSURANCE } from '../../../../constants';
import { noteTagTypes, relationCodes } from '../../../../constants/lists';
import { styles } from '../financials-styles';
import DetailField from '../../../../components/form/field/field';
import EditPbmInsurance from '../pbm-insurances/edit-pbm-insurance';

const tagType = noteTagTypes.find(tag => tag.label === PBM_INSURANCE);

class PharmacyBenefitsTableDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleVerify = this.handleVerify.bind(this);
  }

  handleVerify(pbmInsurance, field = 'is_verified', value = 1) {
    const {
      editPbmInsurance, //eslint-disable-line
      fetchPbmInsurances, //eslint-disable-line
    } = this.props;
    const payload = {
      ...pbmInsurance,
      start_date: pbmInsurance.start_date
        ? convertToArborDate(pbmInsurance.start_date).getUtcDate()
        : null,
      end_date: pbmInsurance.end_date
        ? convertToArborDate(pbmInsurance.end_date).getUtcDate()
        : null,
      [field]: value,
      verified_dt: convertToArborDate(moment()).getUtcDatetime(),
    };

    if (field === 'valid') {
      payload.end_date = value ? null : convertToArborDate(moment()).getUtcDate();
    }

    editPbmInsurance(payload).then(() => {
      fetchPbmInsurances(pbmInsurance.patient_id);
    });
  }

  displayDetail(pbmInsurance) {
    const { classes, users, stretch, uploadedDocuments } = this.props;
    const isNoInsurance = Boolean(pbmInsurance.is_no_insurance);
    const verifiedUser = getUserById(pbmInsurance.verified_by, users);

    const getDocuments = (resourceId, tagTypeId) =>
      getDocumentsForResource(uploadedDocuments.documents, [{ resourceId, tagTypeId }], 1);

    const containerDivClassname = classNames(classes.paddedTop, {
      [classes.containerDivStretched]: stretch,
    });

    if (isNoInsurance) {
      return (
        <div className={containerDivClassname}>
          <Grid container>
            <Grid item xs={12}>
              <VerificationPanel
                type="pbm"
                isVerified={pbmInsurance.is_verified}
                handleVerify={() => this.handleVerify(pbmInsurance)}
                verifiedBy={verifiedUser}
                verifiedDate={pbmInsurance.verified_dt}
              />
            </Grid>
          </Grid>
        </div>
      );
    }
    return (
      <div className={containerDivClassname}>
        <Grid container>
          <Grid item xs={3}>
            <DetailField fieldName="PBM Name" field={pbmInsurance.pbm_name} />
          </Grid>
          <Grid item xs={3}>
            <TalkdeskPhoneNumber
              number={pbmInsurance.pbm_phone_number}
              headerText="PBM Phone Number"
              tagInfo={{
                tagTypeId: tagType.value,
                tagResourceId: pbmInsurance.id,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField fieldName="BIN Number" field={pbmInsurance.pbm_bin_number} />
          </Grid>
          <Grid item xs={3}>
            <DocumentDisplay
              containerClass={classes.innerDocumentContainer}
              taskIdResourceIds={[
                {
                  tagTypeId: tagType.value,
                  resourceId: pbmInsurance.id,
                },
              ]}
              tagTypeId={tagType.value}
              resourceId={pbmInsurance.id}
              drugName={PBM_INSURANCE}
              tagTypeLabel={PBM_INSURANCE}
              documents={getDocuments(pbmInsurance.id, tagType.value)}
            />
          </Grid>
        </Grid>
        <SmallSpacer />
        <Grid container>
          <Grid item xs={3}>
            <DetailField
              fieldName="Processor Control Number"
              field={pbmInsurance.pbm_processor_control_number}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Patient ID Number"
              field={pbmInsurance.pbm_patient_id}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="RX Group Number"
              field={pbmInsurance.pbm_rx_group_number}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              onClick={() => this.handleVerify(pbmInsurance, 'valid', Number(!pbmInsurance.valid))}
              color="secondary"
              variant="contained"
            >
              {pbmInsurance.valid ? 'Mark Inactive' : 'Reactivate'}
            </Button>
          </Grid>
        </Grid>
        <SmallSpacer />
        <Grid container>
          <Grid item xs={3}>
            <DetailField
              fieldName="Relation Code"
              field={
                relationCodes.find(
                  relationCode => relationCode.value === pbmInsurance.pbm_relation_code,
                ).label
              }
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Insurance Type"
              field={pbmInsurance.insurance_type}
              variant="raw"
            />
          </Grid>
        </Grid>
        <SmallSpacer />
        <Grid container>
          <Grid item xs={3}>
            <DetailField
              fieldName="Start Date"
              field={
                pbmInsurance.start_date && convertToArborDate(pbmInsurance.start_date).getUtcDate()
              }
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="End Date"
              field={
                pbmInsurance.end_date && convertToArborDate(pbmInsurance.end_date).getUtcDate()
              }
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Verified"
              field={pbmInsurance.is_verified ? 'Yes' : 'No'}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Last Updated By"
              field={pbmInsurance.updated_by_user}
              variant="raw"
            />
          </Grid>
        </Grid>
        <Box pb={3} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SubHeader name="Low Income Subsidy" />
          </Grid>
          <Grid item xs={3}>
            <DetailField fieldName="LIS Level" field={pbmInsurance.lis_level} variant="raw" />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="LIS Effective Date"
              field={convertToArborDate(pbmInsurance.lis_effective_date, true).getUtcDate()}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="LIS Termination Date"
              field={convertToArborDate(pbmInsurance.lis_termination_date, true).getUtcDate()}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Medicare Plan Type"
              field={pbmInsurance.lis_medicare_plan_type}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Medicare Beneficiary Identifier"
              field={pbmInsurance.lis_medicare_beneficiary_id}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Effective Date for MBI"
              field={convertToArborDate(pbmInsurance.lis_effective_date_mbi, true).getUtcDate()}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField fieldName="Benefit Id" field={pbmInsurance.lis_benefit_id} variant="raw" />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Formulary Id"
              field={pbmInsurance.lis_formulary_id}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Date of Service"
              field={convertToArborDate(pbmInsurance.lis_date_of_service, true).getUtcDate()}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Contract Number"
              field={pbmInsurance.lis_contract_number}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Medicare Part D Code"
              field={pbmInsurance.lis_medicare_part_d_code}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Other Payor Id Count"
              field={pbmInsurance.lis_other_payor_id_count}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Cms Low Income Cost Sharing"
              field={pbmInsurance.lis_cms_low_income_cost_sharing}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Transaction Response Status"
              field={pbmInsurance.lis_transaction_response_status}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Next Medicare Part D Termination"
              field={convertToArborDate(
                pbmInsurance.lis_next_medicare_part_d_termination,
                true,
              ).getUtcDate()}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Next Medicare Part D Effective Start"
              field={convertToArborDate(
                pbmInsurance.lis_next_medicare_part_d_effective_start,
                true,
              ).getUtcDate()}
              variant="raw"
            />
          </Grid>
        </Grid>
        <LargeSpacer />
        <Grid container>
          <Grid item xs={12}>
            <VerificationPanel
              type="pbm"
              isVerified={pbmInsurance.is_verified}
              handleVerify={() => this.handleVerify(pbmInsurance)}
              verifiedBy={verifiedUser}
              verifiedDate={pbmInsurance.verified_dt}
            />
          </Grid>
        </Grid>
        <Box pb={2} />
      </div>
    );
  }

  renderPanel(pbmInsurance) {
    const { editItemMap, handleEditClose } = this.props;
    return (
      <div>
        {pbmInsurance &&
          (!editItemMap[`pharmacyBenefit${pbmInsurance.id}`] ? (
            this.displayDetail(pbmInsurance)
          ) : (
            <EditPbmInsurance
              pbmInsurance={pbmInsurance}
              cancel={handleEditClose}
              form={`${EDIT_PBM_INSURANCE_FORM}_${pbmInsurance.id}`}
            />
          ))}
      </div>
    );
  }

  render() {
    return this.renderPanel(this.props.pbmInsurance);
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPbmInsurances,
      editPbmInsurance,
    },
    dispatch,
  );
}

function mapStateToProps(state) {
  const { lookups, uploadedDocuments } = state;

  return {
    users: lookups.users,
    uploadedDocuments,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(PharmacyBenefitsTableDetail);

import { IBenefitInvestigationLatest } from 'interfaces/redux/ITherapyBenefitsInvestigationLatest';

export enum BenefitInvestigationLatestActionType {
  GetByPatientIdSuccess = 'GET_BY_PATIENT_ID_SUCCESS',
  GetByPatientIdFailed = 'GET_BY_PATIENT_ID_FAILED',
  UpdateByTherapyId = 'UPDATE_BY_THERAPY_ID',
}

export interface BenefitInvestigationLatestAction {
  type: BenefitInvestigationLatestActionType;
  payload?: any;
}

export const benefitsInvestigationLatestActions = {
  updateByTherapyId: (update: IBenefitInvestigationLatest): BenefitInvestigationLatestAction => {
    return {
      type: BenefitInvestigationLatestActionType.UpdateByTherapyId,
      payload: update,
    };
  },
};

import React from 'react';
import { renderCheckbox, renderCheckboxInline } from 'components/form/field/redux-field';

export default ({ field, label, input, meta, disabled, labelSize, qaId }) => {
  const Component = field.displayInline ? renderCheckboxInline : renderCheckbox;

  return (
    <Component
      label={label}
      input={input}
      meta={meta}
      disabled={disabled}
      labelSize={labelSize}
      qaId={qaId}
    />
  );
};

export const styles = theme => ({
  item: {
    margin: 8,
  },
  container: {
    padding: 10,
    borderRadius: 10,
  },
  fileName: {
    fontWeight: 400,
    fontSize: theme.font.smallFontSize,
  },
  labels: {
    color: theme.palette.primary.steelGrey,
    fontSize: theme.font.smallFontSize,
  },
  tags: {
    fontSize: theme.font.extraSmallFontSize,
    fontWeight: theme.font.weight.thick,
    color: theme.palette.primary.trellisBlue,
  },
});

export default function patientIdCheckMiddleware({ getState }) {
  return next => action => {
    if (action && action.meta && action.meta.patientId) {
      const { selectedPatientId } = getState();
      if (Number(selectedPatientId) === Number(action.meta.patientId)) {
        return next(action);
      }
      // eslint-disable-next-line
      console.warn &&
        // eslint-disable-next-line no-console
        console.warn(
          'An action for the wrong patient was prevented',
          action.type,
          Number(selectedPatientId),
          Number(action.meta.patientId),
        );
      return null;
    }
    return next(action);
  };
}

import createStyles from '@mui/styles/createStyles';

const baselineStyle = {
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  justifyContent: 'flex-start',
  fontSize: '13px',
};

export const styles = theme =>
  createStyles({
    sentFailed: {
      ...baselineStyle,
      color: theme.palette.primary.lightRed,
    },
    sentDelivered: {
      ...baselineStyle,
      color: '#5c88af',
    },
    sentUnknown: {
      ...baselineStyle,
      color: theme.palette.primary.steelGrey,
    },
    deliveryInfoText: {
      paddingLeft: '5px',
      color: '#5c88af',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      fontSize: '13px',
    },
    receivedInfoText: {
      color: theme.palette.primary.steelGrey,
      fontWeight: 'bold',
      fontSize: '13px',
      display: 'flex',
      alignItems: 'center',
    },
  });

import React, { PropsWithChildren, useState, MouseEvent, Fragment } from 'react';
import { Visibility } from '@mui/icons-material';
import { Button, Checkbox, Divider, FormControlLabel, FormGroup, Menu, MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { reports } from '../../constants/lists';

import { ReportVisibilityButtonMenuPaperProps, ReportVisibilityButtonStyles } from './report-visibility-button-styles';

interface IReportVisibilityButtonProps {
  initialReports: number[];
  onClose: (selectedReports: number[]) => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function ReportVisibilityButton(props: PropsWithChildren<IReportVisibilityButtonProps>) {
  const { initialReports = [], onClose } = props;

  const theme = useTheme();
  const styles = ReportVisibilityButtonStyles(theme);

  const [selectedReports, setSelectedReports] = useState(initialReports);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    onClose(selectedReports);
  };

  const handleReportToggle = (e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLLIElement>, value: number) => {
    e.preventDefault();
    const idx = selectedReports.indexOf(value);
    if (idx !== -1) {
      setSelectedReports([
        ...selectedReports.slice(0, idx),
        ...selectedReports.slice(idx + 1, selectedReports.length),
      ].sort((a, b) => a - b));
    } else {
      setSelectedReports([...selectedReports, value].sort((a, b) => a - b));
    }
  };

  const categories = [...Array.from(new Set(reports.map(report => report.category)))];
  return (
    <>
      <Button
        variant="contained"
        sx={styles.visibilityReportButton}
        onClick={handleClick}
      >
        Show/Hide Reports
        <Visibility sx={styles.visibilityIcon} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={ReportVisibilityButtonMenuPaperProps}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <FormGroup>
          <MenuItem sx={styles.categoryMenuItem}>
            <Typography fontWeight="bold">Visible items</Typography>
          </MenuItem>
          <Divider variant="middle" />
          {categories.map((category, idx) => {
            return (
              <Fragment key={`category-${category}`}>
                {reports.filter(report => report.category === category).map(({ value, label, type }, idx) => (
                  <Fragment key={`report-${value}`}>
                    <MenuItem sx={type === 'metrics' ? styles.metricMenuItem : styles.chartMenuItem} onClick={e => handleReportToggle(e, value)}>
                      <FormControlLabel control={<Checkbox value={value} checked={selectedReports.includes(value)} />} label={label} />
                    </MenuItem>
                    {(idx < reports.length - 1) && <Divider sx={type === 'metrics' ? styles.metricDivider : styles.chartDivider} variant="middle" />}
                  </Fragment>
                ))}
              </Fragment>
            );
          })}
        </FormGroup>
      </Menu>
    </>
  );
}

import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  FETCH_RECONCILIATIONS_REQUEST,
  FETCH_RECONCILIATION_PATIENT_SUCCESS,
  FETCH_RECONCILIATION_MEDICATION_SUCCESS,
  RESOLVE_RECONCILIATION_PATIENT_REQUEST,
  RESOLVE_RECONCILIATION_PATIENT_SUCCESS,
  RESOLVE_RECONCILIATION_SUCCESS,
  RESOLVE_RECONCILIATION_MEDICATION_REQUEST,
  RESOLVE_RECONCILIATION_MEDICATION_SUCCESS,
  LINK_RECONCILIATION_MEDICATION_REQUEST,
  ADD_MEDICATION,
  MERGE_MEDICATIONS,
  EDIT_PATIENT,
  NOTIFY_SUCCESS,
  NOTIFY_ERROR,
  FETCH_RECONCILIATION_ALLERGY_SUCCESS,
  RESOLVE_RECONCILIATION_ALLERGY_REQUEST,
  RESOLVE_RECONCILIATION_ALLERGY_SUCCESS,
} from '../constants';
import HTTP from '../services/http';

export function* workerFetchPatientReconciliation() {
  try {
    const state = yield select();
    const { selectedPatientId, patient } = state;
    if (selectedPatientId) {
      const patientResponse = yield call(() =>
        HTTP.get(`/patients/${selectedPatientId}/reconciliation/demographic`, {}),
      );
      const medResponse = yield call(() =>
        HTTP.get(`/patients/${selectedPatientId}/reconciliation/medications`, {}),
      );
      const allergyResponse = yield call(() =>
        HTTP.get(`/patients/${selectedPatientId}/reconciliation/allergens`, {}),
      );
      if (patientResponse.data && patientResponse.data.staging_patient) {
        yield put({
          type: FETCH_RECONCILIATION_PATIENT_SUCCESS,
          payload: {
            patient,
            stagingPatient: patientResponse.data.staging_patient,
          },
        });
      }
      if (medResponse.data && medResponse.data.staging_medication_list) {
        yield put({
          type: FETCH_RECONCILIATION_MEDICATION_SUCCESS,
          payload: {
            patient,
            stagingMedications: medResponse.data.staging_medication_list,
          },
        });
      }
      if (allergyResponse.data && allergyResponse.data.staging_allergens_list) {
        yield put({
          type: FETCH_RECONCILIATION_ALLERGY_SUCCESS,
          payload: {
            patient,
            stagingAllergies: allergyResponse.data.staging_allergens_list,
          },
        });
      }
    }
  } catch (error) {
    yield put({
      type: NOTIFY_ERROR,
      payload: 'Error Fetching Reconciliation Information',
    });
  }
}

export function* workerPatientReconciliation(action) {
  const state = yield select();
  const { selectedPatientId, patient } = state;
  const { payload, meta } = action;
  const patientPayload = {
    id: patient.id,
    source_patient_id: patient.source_patient_id,
    source_id: patient.source_id,
  };
  try {
    if (selectedPatientId && payload) {
      const response = yield call(() =>
        HTTP.post(
          '/reconciliation/_patient',
          {
            patient: patientPayload,
            excluded_values: payload.jsonExcludedVals,
            is_reconciled: payload.isLastPatientCard,
            is_deceased: payload.isDeceased,
          },
          {},
        ),
      );
      if (response.data && response.data.reconciliation) {
        yield put({
          type: RESOLVE_RECONCILIATION_PATIENT_SUCCESS,
          payload: response.data.reconciliation,
          patient,
        });
        yield put({
          type: RESOLVE_RECONCILIATION_SUCCESS,
          payload: response.data.reconciliation,
          patient,
        });
        yield put({
          type: NOTIFY_SUCCESS,
          payload: meta.successMessage,
        });
      }
    }
  } catch (error) {
    yield put({ type: NOTIFY_ERROR, payload: meta.errorMessage });
  }
}

export function* workerAllergyReconciliation(action) {
  const state = yield select();
  const { selectedPatientId, patient } = state;
  const patientPayload = {
    id: patient.id,
    source_patient_id: patient.source_patient_id,
    source_id: patient.source_id,
  };
  const { payload } = action;
  try {
    if (selectedPatientId && payload) {
      const response = yield call(() =>
        HTTP.post(
          '/reconciliation/_allergen',
          {
            ...payload,
            patient: patientPayload,
            excluded_values: payload.jsonExcludedVals,
          },
          {},
        ),
      );
      if (response.data && response.data.reconciliation) {
        yield put({
          type: RESOLVE_RECONCILIATION_ALLERGY_SUCCESS,
          payload: response.data.reconciliation,
          patient,
        });
        yield put({
          type: RESOLVE_RECONCILIATION_SUCCESS,
          payload: response.data.reconciliation,
          patient,
        });
        yield put({
          type: NOTIFY_SUCCESS,
          payload: 'Reconcile Allergy Success',
        });
      }
    }
  } catch (error) {
    yield put({ type: NOTIFY_ERROR, payload: 'Reconcile Allergy Error' });
  }
}

export function* workerMedicationReconciliation(action) {
  const state = yield select();
  const { selectedPatientId, patient } = state;
  const patientPayload = {
    id: patient.id,
    source_patient_id: patient.source_patient_id,
    source_id: patient.source_id,
  };
  const { payload } = action;
  try {
    if (selectedPatientId && payload) {
      const response = yield call(() =>
        HTTP.post(
          '/reconciliation/_medication',
          {
            patient: patientPayload,
            source_medication_id: payload.source_medication_id,
            source_id: payload.source_id,
            excluded_values: payload.jsonExcludedVals,
          },
          {},
        ),
      );
      if (response.data && response.data.reconciliation) {
        yield put({
          type: RESOLVE_RECONCILIATION_MEDICATION_SUCCESS,
          payload: response.data.reconciliation,
          patient,
        });
        yield put({
          type: RESOLVE_RECONCILIATION_SUCCESS,
          payload: response.data.reconciliation,
          patient,
        });
        yield put({
          type: NOTIFY_SUCCESS,
          payload: 'Reconcile Medication Success',
        });
      }
    }
  } catch (error) {
    yield put({ type: NOTIFY_ERROR, payload: 'Reconcile Medication Error' });
  }
}

export function* workerLinkMedicationReconciliation(action) {
  const state = yield select();
  const { selectedPatientId, patient } = state;
  const { payload } = action;
  const { newMedication, mergedMedications, addMedication } = payload;
  try {
    if (selectedPatientId) {
      if (addMedication) {
        const medResponse = yield call(() =>
          HTTP.post(
            `/patients/${selectedPatientId}/medications/`,
            {
              ...newMedication,
              patient_id: selectedPatientId,
            },
            {},
          ),
        );
        if (medResponse.data) {
          yield put({
            type: ADD_MEDICATION,
            payload: medResponse,
            patient,
          });
        }
        mergedMedications.merged_medication_ids.push(medResponse.data.added_medication.id);
      }
      const mergeResponse = yield call(() =>
        HTTP.post(`/patients/${selectedPatientId}/medications/_merge`, mergedMedications, {}),
      );
      if (mergeResponse) {
        yield put({
          type: MERGE_MEDICATIONS,
          payload: mergedMedications,
          patient,
        });
      }
      yield put({
        type: RESOLVE_RECONCILIATION_MEDICATION_REQUEST,
        payload: newMedication,
        patient,
      });
      yield put({
        type: NOTIFY_SUCCESS,
        payload: 'Link Medication Success',
      });
    }
  } catch (error) {
    yield put({ type: NOTIFY_ERROR, payload: 'Link Medication Error' });
  }
}

export function* watcherReconciliationSaga() {
  yield takeLatest(
    [FETCH_RECONCILIATIONS_REQUEST, EDIT_PATIENT, RESOLVE_RECONCILIATION_PATIENT_SUCCESS],
    workerFetchPatientReconciliation,
  );
  yield takeEvery([RESOLVE_RECONCILIATION_PATIENT_REQUEST], workerPatientReconciliation);
  yield takeEvery([RESOLVE_RECONCILIATION_MEDICATION_REQUEST], workerMedicationReconciliation);
  yield takeEvery([RESOLVE_RECONCILIATION_ALLERGY_REQUEST], workerAllergyReconciliation);
  yield takeEvery([LINK_RECONCILIATION_MEDICATION_REQUEST], workerLinkMedicationReconciliation);
}

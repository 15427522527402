import { Action } from 'redux';
import { FETCH_AUDITS_REQUEST, RESOLVE_AUDIT_FLOW } from '../constants/index';
import { IAuditState, IAudit } from '../interfaces/redux/IAudit';

export function resolveAudit(audit: IAudit) {
  return {
    type: RESOLVE_AUDIT_FLOW,
    payload: audit as any,
  };
}

class AuditActionFetch implements Action {
  readonly type = FETCH_AUDITS_REQUEST;

  constructor(public payload: IAuditState) {}
}

class AuditActionResolve implements Action {
  readonly type = RESOLVE_AUDIT_FLOW;

  constructor(public payload: IAuditState) {}
}
export type AuditAction = AuditActionFetch | AuditActionResolve;

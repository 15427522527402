/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useEffect } from 'react';
import { Grid, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useTypedSelector } from 'hooks/use-typed-selector';
import makeStyles from '@mui/styles/makeStyles';
import { reduxForm, InjectedFormProps, change } from 'redux-form';
import { getClinicalDataTypesMap } from 'services/utils/data-collect';
import { ILabelValueNumber } from 'interfaces/ILabelValue';
import { buildQaId } from 'utils/build-qa-id';
import { useForm } from 'react-hook-form';
import { NumberOption } from 'interfaces/forms/types';
import { nameOfFactory } from 'utils/types-util';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { FORM_NAME, QA_ID, ProtocolTypeList } from './constants';
import {
  IFormFixedFields,
  IPostClinicalItemProtocolRequest,
  IEfficacyProtocolObj,
  IEfficacyProtocolCriteria,
  ISafetyProtocolObj,
  ISafetyProtocolCriteria,
  IEditEfficacyFormFieldsDefaultValues,
  IEditSafetyFormFieldsDefaultValues,
  IPatchClinicalItemProtocolRequest,
} from './types';
import { ClinicalItemProtocolFormModalStyles } from './styles';
import { EfficacyProtocolComponent } from './efficacy-protocol';
import { SafetyProtocolComponent } from './safety-protocol';
import { logger } from '../../../../winston-logger';

export interface IProps {
  onSuccess: () => void;
  onError: (error: string) => void;
  onCancel: () => void;
  protocolType: string | null;
  protocolInfo: any | undefined;
}

interface IClinicalItemsList extends ILabelValueNumber {
  type: string;
}

type IClinicalItemProtocolFormProps = IProps & InjectedFormProps<IFormFixedFields, IProps>;

const consecutiveInterventionsOptions: { label: string; value: number }[] = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];

const protocolStatus: { label: string; value: number }[] = [
  { label: 'Active', value: 1 },
  { label: 'Inactive', value: 0 },
];

const qaIdBuilder = buildQaId(QA_ID);

const useStyles = makeStyles(ClinicalItemProtocolFormModalStyles);
const getFieldName = nameOfFactory<IFormFixedFields>();

const ClinicalItemProtocolForm = (props: IClinicalItemProtocolFormProps) => {
  const { onSuccess, onError, onCancel, handleSubmit, protocolType, protocolInfo } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const form = useForm<any>({});
  const clinicalDataTypes = useTypedSelector(state => state.lookups.clinicalDataTypes);

  const [loadingDiseaseStates, setLoadingDiseaseStates] = useState<boolean>(false);
  const [loadingClincialDataItems, setLoadingClincialDataItems] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [dynamicEfficacyProtocolList, setDynamicEfficacyProtocolList] = React.useState<any[]>([]);
  const [dynamicSafetyProtocolList, setDynamicSafetyProtocolList] = React.useState<any[]>([]);
  const [clinicalItems, setClinicalItems] = useState<IClinicalItemsList[]>([]);
  const [editEfficacyFormValues, setEditEfficacyFormValues] =
    React.useState<IEditEfficacyFormFieldsDefaultValues | null>(null);
  const [editSafetyFormValues, setEditSafetyFormValues] =
    React.useState<IEditSafetyFormFieldsDefaultValues | null>(null);
  const [incorrectEfficacyInterventionScoreValue, setIncorrectEfficacyInterventionScoreValue] =
    useState<number[]>([]);
  const [incorrectSafetyInterventionScoreValue, setIncorrectSafetyInterventionScoreValue] =
    useState<number[]>([]);
  const [incorrectEfficacyExpirationDateValue, setIncorrectEfficacyExpirationDateValue] = useState<
    number[]
  >([]);
  const [incorrectSafetyExpirationDateValue, setIncorrectSafetyExpirationDateValue] = useState<
    number[]
  >([]);
  const [diseaseStateData, setDiseaseStateData] = React.useState<
    { id: number; diseaseName: string; icdCode: string[]; selected?: boolean }[]
  >([]);
  const [formListOfValues, setFormListOfValues] = useState<any>('');

  const updateIncorrectEfficacyInterventionScoreValue = (newValues: number[]) => {
    setIncorrectEfficacyInterventionScoreValue(newValues);
  };

  const updateIncorrectSafetyInterventionScoreValue = (newValues: number[]) => {
    setIncorrectSafetyInterventionScoreValue(newValues);
  };

  const updateIncorrectEfficacyExpirationDateValue = (newValues: number[]) => {
    setIncorrectEfficacyExpirationDateValue(newValues);
  };

  const updateIncorrectSafetyExpirationDateValue = (newValues: number[]) => {
    setIncorrectSafetyExpirationDateValue(newValues);
  };

  const loadClinicalDataItems = async () => {
    setLoadingClincialDataItems(true);
    try {
      const map = getClinicalDataTypesMap(clinicalDataTypes);
      const clinicalDataItems =
        map && Object.values(map).length
          ? Object.values(map).reduce((acc: any[], item: any) => {
              if (item.isActive && item.isActive === true) {
                acc.push({
                  label: item.name,
                  value: parseInt(item.dataTypeId, 10),
                  type: item.type,
                });
              }
              return acc;
            }, [] as { abel: string; value: number }[])
          : [];
      setClinicalItems(clinicalDataItems);
    } finally {
      setLoadingClincialDataItems(false);
    }
  };

  const loadDiseaseStates = async () => {
    setLoadingDiseaseStates(true);
    try {
      const result = await ApplicationManagerClient.fetchDiseaseStates();
      if (result.data.result.length > 0) {
        const diseaseStateList = result.data.result.map(item => {
          return {
            ...item,
            icdCode: Object.values(item.icdCode).map(t => t.name),
          };
        });
        setDiseaseStateData(diseaseStateList);
      }
    } finally {
      setLoadingDiseaseStates(false);
    }
  };

  const addDynamicEfficacyProtocol = () => {
    const newId =
      dynamicEfficacyProtocolList.length > 0 ? dynamicEfficacyProtocolList.slice(-1)[0].id + 1 : 1;
    setDynamicEfficacyProtocolList(dynamicEfficacyProtocolList => {
      return [
        ...dynamicEfficacyProtocolList,
        {
          id: newId,
        },
      ];
    });
  };

  const deleteDynamicEfficacyProtocol = (id: number) => {
    setDynamicEfficacyProtocolList(dynamicEfficacyProtocolList =>
      dynamicEfficacyProtocolList.filter(x => x.id !== id),
    );
  };

  const addDynamicSafetyProtocol = () => {
    const newId =
      dynamicSafetyProtocolList.length > 0 ? dynamicSafetyProtocolList.slice(-1)[0].id + 1 : 1;
    setDynamicSafetyProtocolList(dynamicSafetyProtocolList => {
      return [
        ...dynamicSafetyProtocolList,
        {
          id: newId,
        },
      ];
    });
  };

  const deleteDynamicSafetyProtocol = (id: number) => {
    setDynamicSafetyProtocolList(dynamicSafetyProtocolList =>
      dynamicSafetyProtocolList.filter(x => x.id !== id),
    );
  };

  const getEfficacyProtocolObj = (
    diseaseState: number | undefined,
    efficacyList: { id: number }[],
    formValues: any,
  ): IEfficacyProtocolObj => {
    const protocol: IEfficacyProtocolObj = {
      disease_state: undefined,
      protocol_criteria: [],
    };
    if (diseaseState && efficacyList.length) {
      const criteriaList: IEfficacyProtocolCriteria[] = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const elem of efficacyList) {
        const tempObj = {
          clinical_item_id: formValues[`efficacy_clinical_item_${elem.id}`],
          options: formValues[`efficacy_intervention_criteria_${elem.id}`]
            ? formValues[`efficacy_intervention_criteria_${elem.id}`].trim().toLowerCase()
            : null,
          clinical_data_operator: Array.isArray(
            formValues[`efficacy_criteria_clinical_data_operator_${elem.id}`],
          )
            ? null
            : formValues[`efficacy_criteria_clinical_data_operator_${elem.id}`],
          clinical_data_value:
            formValues[`efficacy_criteria_clinical_data_value_${elem.id}`] ?? null,
          consecutive_intervention_score:
            !!formValues[`efficacy_consecutive_intervention_scores_${elem.id}`],
          consecutive_type: Array.isArray(formValues[`efficacy_consecutive_type_${elem.id}`])
            ? null
            : formValues[`efficacy_consecutive_type_${elem.id}`],
          consecutive_value: formValues[`efficacy_intervention_score_value_${elem.id}`]
            ? parseInt(formValues[`efficacy_intervention_score_value_${elem.id}`], 10)
            : null,
          therapy_status: formValues[`therapy_status_${elem.id}`] ?? null,
          therapy_operator: formValues[`therapy_status_operator_${elem.id}`] ?? null,
          months_ago: formValues[`months_ago_${elem.id}`] ?? null,
          status: !!formValues[`efficacy_protocol_status_${elem.id}`],
          effective_date: moment(formValues[`efficacy_effective_date_${elem.id}`]).toDate(),
          expiration_date: formValues[`efficacy_expiration_date_${elem.id}`]
            ? moment(formValues[`efficacy_expiration_date_${elem.id}`]).toDate()
            : null,
        };
        criteriaList.push(tempObj);
      }
      protocol.disease_state = diseaseState;
      protocol.protocol_criteria = criteriaList;
    }
    return protocol;
  };

  const getSafetyProtocolObj = (
    gpi_10: string | undefined,
    safetyList: { id: number }[],
    formValues: any,
  ): ISafetyProtocolObj => {
    const protocol: ISafetyProtocolObj = {
      gpi_10: undefined,
      protocol_criteria: [],
    };
    if (gpi_10 && safetyList.length) {
      const criteriaList: ISafetyProtocolCriteria[] = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const elem of safetyList) {
        const tempObj = {
          clinical_item_id: formValues[`safety_clinical_item_${elem.id}`],
          options: formValues[`safety_intervention_criteria_${elem.id}`]
            ? formValues[`safety_intervention_criteria_${elem.id}`].trim().toLowerCase()
            : null,
          clinical_data_operator: Array.isArray(
            formValues[`safety_criteria_clinical_data_operator_${elem.id}`],
          )
            ? null
            : formValues[`safety_criteria_clinical_data_operator_${elem.id}`],
          clinical_data_value: formValues[`safety_criteria_clinical_data_value_${elem.id}`] ?? null,
          special_population:
            formValues[`special_population_${elem.id}`] &&
            !Array.isArray(formValues[`special_population_${elem.id}`])
              ? formValues[`special_population_${elem.id}`]
              : null,
          consecutive_intervention_score:
            !!formValues[`safety_consecutive_intervention_scores_${elem.id}`],
          consecutive_type: Array.isArray(formValues[`safety_consecutive_type_${elem.id}`])
            ? null
            : formValues[`safety_consecutive_type_${elem.id}`],
          consecutive_value: formValues[`safety_intervention_score_value_${elem.id}`]
            ? parseInt(formValues[`safety_intervention_score_value_${elem.id}`], 10)
            : null,
          status: !!formValues[`safety_protocol_status_${elem.id}`],
          effective_date: moment(formValues[`safety_effective_date_${elem.id}`]).toDate(),
          expiration_date: formValues[`safety_expiration_date_${elem.id}`]
            ? moment(formValues[`safety_expiration_date_${elem.id}`]).toDate()
            : null,
        };
        criteriaList.push(tempObj);
      }
      protocol.gpi_10 = gpi_10;
      protocol.protocol_criteria = criteriaList;
    }
    return protocol;
  };

  const readyToSubmitValues = (
    effectiveprotocolList: { id: number }[],
    safetyProtocolList: { id: number }[],
    formValues: any,
  ): boolean => {
    let readyToSubmit = true;
    if (effectiveprotocolList.length) {
      for (const elem of effectiveprotocolList) {
        if (formValues[`efficacy_intervention_score_value_${elem.id}`]) {
          if (!(parseInt(formValues[`efficacy_intervention_score_value_${elem.id}`], 10) > 0)) {
            readyToSubmit = false;
            setIncorrectEfficacyInterventionScoreValue(incorrectEfficacyInterventionScoreValue => {
              return [
                ...incorrectEfficacyInterventionScoreValue.filter(t => t !== elem.id),
                elem.id,
              ];
            });
          } else {
            setIncorrectEfficacyInterventionScoreValue(incorrectEfficacyInterventionScoreValue => {
              return [...incorrectEfficacyInterventionScoreValue.filter(t => t !== elem.id)];
            });
          }
        }
        if (
          formValues[`efficacy_effective_date_${elem.id}`] &&
          formValues[`efficacy_expiration_date_${elem.id}`]
        ) {
          if (
            moment(formValues[`efficacy_expiration_date_${elem.id}`]).isSameOrBefore(
              moment(formValues[`efficacy_effective_date_${elem.id}`]),
            )
          ) {
            readyToSubmit = false;
            setIncorrectEfficacyExpirationDateValue(incorrectEfficacyInterventionScoreValue => {
              return [
                ...incorrectEfficacyInterventionScoreValue.filter(t => t !== elem.id),
                elem.id,
              ];
            });
          } else {
            setIncorrectEfficacyExpirationDateValue(incorrectEfficacyInterventionScoreValue => {
              return [...incorrectEfficacyInterventionScoreValue.filter(t => t !== elem.id)];
            });
          }
        }
      }
    }
    if (safetyProtocolList.length) {
      for (const elem of safetyProtocolList) {
        if (formValues[`safety_intervention_score_value_${elem.id}`]) {
          if (!(parseInt(formValues[`safety_intervention_score_value_${elem.id}`], 10) > 0)) {
            readyToSubmit = false;
            setIncorrectSafetyInterventionScoreValue(incorrectSafetyInterventionScoreValue => {
              return [...incorrectSafetyInterventionScoreValue.filter(t => t !== elem.id), elem.id];
            });
          } else {
            setIncorrectSafetyInterventionScoreValue(incorrectSafetyInterventionScoreValue => {
              return [...incorrectSafetyInterventionScoreValue.filter(t => t !== elem.id)];
            });
          }
        }
        if (
          formValues[`safety_effective_date_${elem.id}`] &&
          formValues[`safety_expiration_date_${elem.id}`]
        ) {
          if (
            moment(formValues[`safety_expiration_date_${elem.id}`]).isSameOrBefore(
              moment(formValues[`safety_effective_date_${elem.id}`]),
            )
          ) {
            readyToSubmit = false;
            setIncorrectSafetyExpirationDateValue(incorrectSafetyInterventionScoreValue => {
              return [...incorrectSafetyInterventionScoreValue.filter(t => t !== elem.id), elem.id];
            });
          } else {
            setIncorrectSafetyExpirationDateValue(incorrectSafetyInterventionScoreValue => {
              return [...incorrectSafetyInterventionScoreValue.filter(t => t !== elem.id)];
            });
          }
        }
      }
    }
    return readyToSubmit;
  };

  const onSubmit = async (formValues: Partial<IFormFixedFields>): Promise<void> => {
    setFormListOfValues(formValues);
    if (readyToSubmitValues(dynamicEfficacyProtocolList, dynamicSafetyProtocolList, formValues)) {
      const protocolObj: IPostClinicalItemProtocolRequest = {
        efficacy_protocols: getEfficacyProtocolObj(
          formValues.disease_state,
          dynamicEfficacyProtocolList,
          formValues,
        ),
        safety_protocols: getSafetyProtocolObj(
          formValues.gpi_10,
          dynamicSafetyProtocolList,
          formValues,
        ),
      };
      await submitprotocols(protocolObj);
    }
  };

  const submitprotocols = async (protocolObj: IPostClinicalItemProtocolRequest): Promise<void> => {
    try {
      if (
        protocolObj.efficacy_protocols.protocol_criteria.length ||
        protocolObj.safety_protocols.protocol_criteria.length
      ) {
        if (protocolType === null && protocolInfo === undefined) {
          const result = await ApplicationManagerClient.addEfficacySafetyProtocol(protocolObj);
          if (result.data.success === true) {
            onSuccess();
          } else {
            onError(typeof result.data.result === 'string' ? result.data.result : '');
          }
        } else {
          let protocolToEdit: any = {};
          if (protocolType === ProtocolTypeList.Efficacy) {
            protocolToEdit = {
              ...protocolObj,
              efficacy_protocol_id: protocolInfo?.id,
              safety_protocol_id: null,
            };
          } else if (protocolType === ProtocolTypeList.Safety) {
            protocolToEdit = {
              ...protocolObj,
              efficacy_protocol_id: null,
              safety_protocol_id: protocolInfo?.id,
            };
          }
          const result = await ApplicationManagerClient.updateEfficacySafetyProtocol(
            protocolToEdit,
          );
          if (result.data.success === true) {
            onSuccess();
          } else {
            onError(typeof result.data.result === 'string' ? result.data.result : '');
          }
        }
        onCancel();
        setDynamicEfficacyProtocolList([]);
        setDynamicSafetyProtocolList([]);
      } else {
        throw new Error('Can not submit empty protocols.');
      }
    } catch (error) {
      logger.error(error);
      onError(`Error creating efficacy / safety Protocol: ${error}`);
    }
  };

  useEffect(() => {
    loadClinicalDataItems();
  }, [clinicalDataTypes]);

  useEffect(() => {
    loadDiseaseStates();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (protocolType && protocolInfo && Object.keys(protocolInfo).length) {
          if (protocolType === ProtocolTypeList.Efficacy) {
            setEditEfficacyFormValues(protocolInfo);
            setDynamicEfficacyProtocolList([{ id: 1 }]);
          } else if (protocolType === ProtocolTypeList.Safety) {
            setEditSafetyFormValues(protocolInfo);
            setDynamicSafetyProtocolList([{ id: 1 }]);
          }
        }
      } catch (err) {
        logger.error(err);
        dispatch(notifyError('Error when editing a Clinical Item Protocol'));
      }
    })();
  }, [protocolType, protocolInfo]);

  const setFieldValue = (field: string, value: any) => dispatch(change(FORM_NAME, field, value));

  return (
    <>
      <DialogContent>
        {open}
        <form data-qa-id={qaIdBuilder('form')} className={classes.protocolForm}>
          <Grid container spacing={1}>
            <Grid item>
              {dynamicEfficacyProtocolList.length > 0 && (
                <Typography className={classes.protocolType}>Efficacy Protocol</Typography>
              )}
              <EfficacyProtocolComponent
                efficacyProtocolData={dynamicEfficacyProtocolList}
                deleteDynamicEfficacyProtocol={deleteDynamicEfficacyProtocol}
                diseaseStateData={diseaseStateData}
                setFieldValue={setFieldValue}
                consecutiveInterventionsOptions={consecutiveInterventionsOptions}
                protocolStatus={protocolStatus}
                clinicalItems={clinicalItems}
                incorrectEfficacyInterventionScoreValue={incorrectEfficacyInterventionScoreValue}
                formListOfValues={formListOfValues}
                updateIncorrectEfficacyInterventionScoreValue={
                  updateIncorrectEfficacyInterventionScoreValue
                }
                incorrectEfficacyExpirationDateValue={incorrectEfficacyExpirationDateValue}
                updateIncorrectEfficacyExpirationDateValue={
                  updateIncorrectEfficacyExpirationDateValue
                }
                editEfficacyFormValues={editEfficacyFormValues}
              />
            </Grid>
            <Grid item>
              {dynamicEfficacyProtocolList.length > 0 && dynamicSafetyProtocolList.length > 0 && (
                <Grid className={classes.safetyProtocolDivider} />
              )}
              {dynamicSafetyProtocolList.length > 0 && (
                <Typography className={classes.protocolType}>Safety Protocol</Typography>
              )}
              <SafetyProtocolComponent
                safetyProtocolData={dynamicSafetyProtocolList}
                deleteDynamicSafetyProtocol={deleteDynamicSafetyProtocol}
                setFieldValue={setFieldValue}
                consecutiveInterventionsOptions={consecutiveInterventionsOptions}
                protocolStatus={protocolStatus}
                clinicalItems={clinicalItems}
                incorrectSafetyInterventionScoreValue={incorrectSafetyInterventionScoreValue}
                formListOfValues={formListOfValues}
                updateIncorrectSafetyInterventionScoreValue={
                  updateIncorrectSafetyInterventionScoreValue
                }
                incorrectSafetyExpirationDateValue={incorrectSafetyExpirationDateValue}
                updateIncorrectSafetyExpirationDateValue={updateIncorrectSafetyExpirationDateValue}
                editSafetyFormValues={editSafetyFormValues}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions className={classes.protocolActionBtns}>
        <Grid container spacing={2}>
          <Grid container item>
            <Button
              onClick={() => addDynamicEfficacyProtocol()}
              variant="outlined"
              disabled={!!protocolType}
            >
              {' '}
              {dynamicEfficacyProtocolList && dynamicEfficacyProtocolList.length
                ? '+ Disease State'
                : '+ Efficacy Protocol'}
            </Button>
          </Grid>
          {dynamicEfficacyProtocolList && dynamicEfficacyProtocolList.length > 0 && (
            <Grid className={classes.buttonDivider} />
          )}
          <Grid container item>
            <Button
              onClick={() => addDynamicSafetyProtocol()}
              variant="outlined"
              disabled={!!protocolType}
            >
              {' '}
              {dynamicSafetyProtocolList && dynamicSafetyProtocolList.length
                ? '+ GPI-10'
                : '+ Safety Protocol'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      <DialogActions className={classes.actionsWrp}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button onClick={onCancel}>Cancel</Button>
          </Grid>
          <Grid item>
            <Button color="primary" variant="contained" onClick={handleSubmit(onSubmit)}>
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export const ClinicalItemProtocolModalInnerForm = reduxForm<IFormFixedFields, IProps>({
  form: FORM_NAME,
})(ClinicalItemProtocolForm);

import React, { useState } from 'react';
import { Grid, Button, ListItem, List, Typography, ListItemIcon, Box } from '@mui/material';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReviewItem from 'components/fc-self-service/components/review-item';
import { STRINGS } from '../../fc_ss_constants';

const EmptyCircle = (
  <div style={{ height: '21px', width: '21px', border: '2px solid green', borderRadius: '50%' }} />
);

const PickUpDates = (props: any) => {
  const {
    nextQuestion,
    isReview,
    currentAnswers,
    onEdit,
    saveAnswer,
    isEdit,
    answerOptions,
    classes,
  } = props;
  const [displayOneDateOption, setDisplayOneDateOption] = useState(true);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);
  const keyName = 'pickup_date';
  const questionText = 'What date do you want to pickup your order?';

  const handleResponse = () => {
    const pickupDate =
      selectedOptionIndex === answerOptions.pickup_dates.length
        ? -1
        : answerOptions.pickup_dates[selectedOptionIndex];
    const responseValue: { keyName: string; value: string | number | null | {} }[] = [
      { keyName, value: pickupDate },
    ];
    saveAnswer(responseValue);
    if (isEdit) {
      nextQuestion();
    } else {
      nextQuestion('payment_method');
    }
  };

  if (isReview && currentAnswers.pickup_or_delivery === 'delivery') {
    return null;
  }
  if (isReview && !answerOptions.pickup_dates) {
    return null;
  }
  const singleChoiceIndex = answerOptions.pickup_dates.length > 1 ? 1 : 0;
  const list: any[] = [...answerOptions.pickup_dates];
  const disableNextButton = selectedOptionIndex === -1;
  const changeChoice = (index: number) => {
    if (index === list.length && displayOneDateOption) {
      setDisplayOneDateOption(!displayOneDateOption);
      setSelectedOptionIndex(-1);
    } else {
      setSelectedOptionIndex(index);
    }
  };

  if (isReview && currentAnswers.pickup_or_delivery === 'pickup') {
    const item = currentAnswers[keyName];
    const current_answer =
      item === -1
        ? 'Call me for pickup date'
        : `${moment(item).format('dddd')} - ${moment(item).format('M/D/YYYY')}`;
    return (
      <ReviewItem
        questionText={questionText}
        onEdit={onEdit}
        currentAnswer={current_answer}
        keyName={keyName}
      />
    );
  }

  return (
    <Box className={classes.container} data-qa-id={`page-${keyName}`}>
      <Grid container spacing={8.5}>
        <Grid item>
          <Typography className={classes.title} data-qa-id="pick-up-date-question">
            {questionText}
          </Typography>
          <List
            data-qa-id="pickup-date-list"
            style={{ maxHeight: 'calc(100vh - 230px)', overflowY: 'auto' }}
          >
            {list.map((item, index) => {
              if (displayOneDateOption && index == singleChoiceIndex) {
                return (
                  <ListItem
                    disableGutters
                    key={`${index}-${item}`}
                    data-qa-id={`pickup-date-${item}`}
                  >
                    <ListItemIcon className={classes.iconWrapper}>
                      <Button onClick={() => changeChoice(index)}>
                        {index === selectedOptionIndex ? (
                          <CheckCircleIcon className={classes.icon} />
                        ) : (
                          EmptyCircle
                        )}
                      </Button>
                    </ListItemIcon>
                    <Typography className={classes.itemListText} component="span">
                      {`${moment(item).format('dddd')} - ${moment(item).format('M/D/YYYY')}`}
                    </Typography>
                  </ListItem>
                );
              }
              if (displayOneDateOption && index !== singleChoiceIndex) {
                return null;
              }
              return (
                <ListItem
                  disableGutters
                  key={`${index}-${item}`}
                  data-qa-id={`pickup-date-${item}`}
                >
                  <ListItemIcon className={classes.iconWrapper}>
                    <Button onClick={() => changeChoice(index)}>
                      {index === selectedOptionIndex ? (
                        <CheckCircleIcon className={classes.icon} />
                      ) : (
                        EmptyCircle
                      )}
                    </Button>
                  </ListItemIcon>
                  <Typography className={classes.itemListText} component="span">
                    {`${moment(item).format('dddd')} - ${moment(item).format('M/D/YYYY')}`}
                  </Typography>
                </ListItem>
              );
            })}
            <ListItem disableGutters key="static_1" data-qa-id="pickup_date_static_1">
              <ListItemIcon className={classes.iconWrapper}>
                <Button onClick={() => changeChoice(list.length)}>
                  {selectedOptionIndex === list.length ? (
                    <CheckCircleIcon className={classes.icon} />
                  ) : (
                    EmptyCircle
                  )}
                </Button>
              </ListItemIcon>
              <Typography className={classes.itemListText} component="span">
                {displayOneDateOption ? 'Show Other Options' : 'None of these work'}
              </Typography>
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <Grid className={classes.btnContainer}>
        <Grid item xs={12}>
          <Button
            size="large"
            className={classes.btnLarge}
            onClick={handleResponse}
            data-qa-id="pickup-dates-next"
            disabled={disableNextButton}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PickUpDates;

import { AxiosPromise } from 'axios';
import { PaginatedResponse } from 'interfaces/PaginatedResponse';
import { PatientId } from 'interfaces/RecordTypes';
import { IMedicationListItem } from 'interfaces/redux/IMedications';
import { TypedHttp } from 'services/typed-http';

type MedicationListResponse = PaginatedResponse<{ medication_list: IMedicationListItem[] }>;
type MedicationGroupsResponse = PaginatedResponse<{ medication_groups: IMedicationListItem[] }>;

export class MedicationsClient {
  static getPaginatedMedications(
    patientId: PatientId,
    pageSize: number,
    cursor?: number,
  ): AxiosPromise<MedicationListResponse> {
    const requestParams: Record<string, string> = {
      pageSize: pageSize.toString(),
    };

    if (cursor) {
      requestParams.cursor = cursor.toString();
    }

    const queryParams = new URLSearchParams(requestParams).toString();
    const url = `/patients/${patientId}/medications?${queryParams}`;

    return TypedHttp.GetPromise<MedicationListResponse>(url);
  }

  static getPaginatedGroupsUrl(patientId: PatientId, pageSize: number, cursor?: number): string {
    const requestParams: Record<string, string> = {
      pageSize: pageSize.toString(),
      active: String(false),
    };

    if (cursor) {
      requestParams.cursor = cursor.toString();
    }

    const queryParams = new URLSearchParams(requestParams).toString();
    const url = `/patients/${patientId}/medications/groups?${queryParams}`;

    return url;
  }

  static getPaginatedGroups(
    patientId: PatientId,
    pageSize: number,
    cursor?: number,
  ): AxiosPromise<MedicationGroupsResponse> {
    return TypedHttp.GetPromise<MedicationGroupsResponse>(
      MedicationsClient.getPaginatedGroupsUrl(patientId, pageSize, cursor),
    );
  }

  static getMedicationsByNdc(patientId: PatientId, ndc: string): AxiosPromise {
    const queryParams = new URLSearchParams({ active: String(false) }).toString();
    const url = `/patients/${patientId}/medications/ndc/${ndc}?${queryParams}`;

    return TypedHttp.GetPromise(url);
  }

  static getMedicationsByGpi(patientId: PatientId, gpi: string): AxiosPromise {
    const queryParams = new URLSearchParams({ active: String(false) }).toString();
    const url = `/patients/${patientId}/medications/gpi/${gpi}?${queryParams}`;

    return TypedHttp.GetPromise(url);
  }
}

import { IMedicationListItem } from 'interfaces/redux/IMedications';
import { MedicationStatus } from 'constants/enums';

export const getGroupedMedicationsByGpi = (
  medicationGroups: IMedicationListItem[],
): Record<string, IMedicationListItem> => {
  return medicationGroups.reduce<Record<string, IMedicationListItem>>((acc, curr) => {
    if (!acc[curr.gpi]) {
      if (curr.status_code === MedicationStatus.Active) {
        return {
          ...acc,
          [curr.gpi]: curr,
        };
      }
    }

    return acc;
  }, {});
};

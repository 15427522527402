import React, { PropsWithChildren } from 'react';
import {
  AccordionSummary,
  Accordion,
  Typography,
  AccordionDetails,
  Grid,
  Box,
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styles } from './expansion-section-styles';

import { IProps } from './interfaces/IProps';

const ExpansionSection: React.FC<PropsWithChildren<IProps>> = (
  props: PropsWithChildren<IProps>,
): JSX.Element => {
  const { classes, title, subtitle, children, defaultExpanded } = props;
  const id = title.toLowerCase().replace(' ', '_');

  return (
    // Inline padding here is not ideal, but a spacing of 3 on container components
    // was causing unwanted padding
    <Grid item xs={12} className={classes.root}>
      <Accordion
        elevation={0}
        id={id}
        defaultExpanded={defaultExpanded}
        className={classes.expansionPanel}
        TransitionProps={{ unmountOnExit: props.unmountOnExit, mountOnEnter: props.mountOnEnter }}
        onChange={props.onChange}
      >
        <AccordionSummary
          className={classes.header}
          expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
        >
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.title}>{title}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                <Typography className={classes.subtitle}>{subtitle}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.content}>
          <Box className={classes.box}>{children}</Box>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(ExpansionSection);

import makeStyles from '@mui/styles/makeStyles';
import { expandTheme } from 'utils/theme-util';

export default makeStyles(theme => ({
  root: {
    flexDirection: 'row',
    borderColor: expandTheme(theme).palette.primary.lightGrey,
    borderWidth: 1,
    borderRadius: 5,
    borderStyle: 'solid',
    padding: theme.spacing(2),
  },
  text: {
    flex: 1,
  },
  chip: {
    marginTop: theme.spacing(2.5),
    padding: theme.spacing(0.3),
    marginRight: theme.spacing(0.7),
    color: expandTheme(theme).palette.primary.black,
    backgroundColor: expandTheme(theme).palette.primary.cornflowerblue,
    borderColor: expandTheme(theme).palette.primary.white,
  },
  boldtext: {
    fontWeight: 450,
  },
}));

const cardHeight = 215;
const cardWidth = 280;

export const styles = theme => ({
  card: {
    minWidth: cardWidth,
    maxWidth: cardWidth,
    height: cardHeight,
    cursor: 'pointer',
    border: '1px solid transparent',
  },
  cardWithDocuments: {
    height: cardHeight + 30,
  },
  cardContent: {
    height: 80,
    display: 'flex',
    flexDirection: 'row',
  },
  cardContentLeft: {
    flex: 1,
    minWidth: 0,
  },
  cardChecked: {
    borderColor: theme.palette.primary.teal,
    border: '1px solid',
    cursor: 'pointer',
    position: 'relative',
  },
  taskContainer: {
    paddingLeft: 32,
    paddingRight: 32,
  },
  cardTitle: {
    minHeight: 30,
    fontWeight: 'bold',
  },
  iconContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
  },
  actionWording: {
    fontSize: 12,
    padding: '7px 5px',
  },
  cardActionContainer: {
    marginTop: 20,
    padding: 0,
  },
  cardContentChecked: {
    opacity: 0.5,
  },
  date: {
    marginLeft: 8,
  },
  therapy: {
    fontWeight: 'bold',
    margin: '5px 0',
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  therapyAdminStatusText: {
    paddingLeft: theme.spacing(1),
  },
  notesContainer: {
    border: '1px solid',
    borderColor: theme.palette.primary.grey25,
    margin: '0 -10px',
    position: 'relative',
  },
  documentContainer: {
    margin: '0 -10px',
  },
  taskGroupTitle: {
    marginBottom: 5,
  },
  taskGroup: {
    marginBottom: 10,
  },
  complete: {
    backgroundColor: theme.palette.primary.patinaWhite,
  },
  innerDocumentContainer: {
    padding: 0,
    borderRadius: 0,
  },
  customFormCard: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  backdrop: {
    zIndex: 1301,
    color: '#fff',
  },
  smsMessageIndicator: {
    position: 'absolute',
    height: theme.spacing(1.5),
    left: theme.spacing(2.75),
    bottom: theme.spacing(1.25),
  },
});

import { StatusMap } from '../interfaces/StatusMap';
import { IStatusSelectorOption } from '../interfaces/IStatusSelectorOption';

class StatusMapUtil {
  static convertMapToStatusOptions = (
    map: StatusMap,
    defaultValue: number | undefined,
    selectedValue: number | undefined,
    clickHandler: (value: number) => void,
  ): IStatusSelectorOption[] => {
    const mappedStatuses = Object.keys(map)
      .filter(key => {
        const keyNumber = Number(key);
        return map[keyNumber].hideFromStatusSelector !== true;
      })
      .map((key, index) => {
        const keyNum = Number(key);

        const actualSelectedStatus = map[selectedValue as number];
        const currentIterationStatus = map[keyNum];

        // This happen when the current task status is hiden in selector but it has a parent status
        // which is in the list of visible statuses
        const shouldBeSelected = Boolean(
          actualSelectedStatus.hideFromStatusSelector &&
            actualSelectedStatus.status === currentIterationStatus.status,
        );

        return {
          hidden:
            keyNum !== selectedValue &&
            keyNum !== defaultValue &&
            actualSelectedStatus.status !== currentIterationStatus.status,
          label: currentIterationStatus.status.toString(),
          onClick: () => clickHandler(keyNum),
          selected: keyNum === selectedValue || shouldBeSelected === true,
          sortIndex: keyNum === defaultValue ? -1 : index,
          value: keyNum,
        };
      });

    return mappedStatuses;
  };

  static getStatusIdFromStatusName = (
    status: string,
    map: StatusMap,
    ignoreCase = true,
  ): number | undefined => {
    const matchingStatus = Object.entries(map).find(([key, value]) =>
      ignoreCase ? value.status.toLowerCase() === status.toLowerCase() : value.status === status,
    );

    let statusId: number | undefined;

    if (matchingStatus && matchingStatus[0]) {
      const convertedNumber = Number(matchingStatus[0]);
      if (!Number.isNaN(convertedNumber)) {
        statusId = convertedNumber;
      }
    }

    return statusId;
  };
}

export { StatusMapUtil };

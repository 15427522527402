import React from 'react';
import CreateIcon from '@mui/icons-material/Create';
import { BaseTable } from 'containers/application-manager/base-table/base-table';
import { IGetDiseaseStateResponse } from 'models/application-manager/cpmp/IGetDiseaseStateResponse';
import { IHeaderCell } from '../../../base-table/types';
import { IResponse, IResult } from './types';
import { styles } from '../../index.styles';
import { AddEditDiseaseStateModal } from '../modal/add-edit-disease-state-modal';

interface ITableProps {
  searchTerm: string | undefined;
  triggerReload: Date | undefined;
  handleReloadDataTrigger: (date: Date | undefined) => void;
  userHasPermissionToEditIcdItem: boolean;
  activeAnyField: boolean;
  diseaseStateList: IGetDiseaseStateResponse;
}

export const DataTable: React.FC<ITableProps> = (props): JSX.Element => {
  const classes = styles();

  const [editModalOpen, setEditModalOpen] = React.useState<boolean>(false);
  const [editingDiseaseState, setEditingDiseaseState] = React.useState<IResult>();

  // # region constants
  const COLUMN_SETTINGS: IHeaderCell<IResult, undefined>[] = [
    {
      label: 'Disease Name',
      parentKey: 'diseaseName',
      hideLeftPadding: true,
      sortable: true,
    },
    {
      label: 'Associated ICD-10',
      parentKey: 'icd',
      hideLeftPadding: true,
      sortable: true,
    },
  ];
  // #endregion

  // # region memos
  const getIcdProtocols = React.useMemo<IResponse | undefined>(() => {
    const diseaseList = props.diseaseStateList.result;
    if (diseaseList.length > 0) {
      const diseaseStateData = diseaseList.reduce((acc, item) => {
        acc.push({
          id: item.id,
          diseaseName: item.diseaseName,
          icd: Object.values(item.icdCode)
            .map(x => x.name)
            .join(', '),
          children: [],
        });
        return acc;
      }, [] as IResult[]);
      return { totalCount: diseaseStateData.length, results: diseaseStateData };
    }
    return undefined;
  }, [props.diseaseStateList]);

  const columnSettings = React.useMemo(() => {
    if (props.userHasPermissionToEditIcdItem) {
      return COLUMN_SETTINGS.concat({
        sortable: true,
        parentComponent: parent => {
          return (
            <>
              <div
                onClick={e => {
                  e.stopPropagation();
                  if (parent) {
                    setEditModalOpen(true);
                    setEditingDiseaseState(parent);
                  }
                }}
              >
                <CreateIcon />
              </div>
            </>
          );
        },
      });
    }
    return COLUMN_SETTINGS;
  }, [props.userHasPermissionToEditIcdItem]);
  // #endregion

  const rederEditDiseaseStateModal = (): JSX.Element => {
    if (!editModalOpen) {
      return <></>;
    }
    return (
      <AddEditDiseaseStateModal
        open
        editDiseaseState={editingDiseaseState}
        onCancel={() => setEditModalOpen(false)}
        onSuccess={() => props.handleReloadDataTrigger(new Date())}
      />
    );
  };

  return (
    <>
      {rederEditDiseaseStateModal()}
      <BaseTable<IResponse, IResult, undefined>
        actionsPermitted={props.userHasPermissionToEditIcdItem}
        orderByDefaultParent="diseaseName"
        dataSet={getIcdProtocols}
        paginationQueryParamSettings={{
          pageSizeQueryStringKey: 'pageSize',
          pageNumberQueryStringKey: 'pageNumber',
          searchTermQueryStringKey: 'searchTerm',
          sortPropQueryStringKey: 'sortProp',
          sortOrderQueryStringKey: 'sortOrder',
        }}
        childPkSelector={() => ''}
        parentPkSelector={p => p.id}
        columnSettings={columnSettings}
        searchTerm={JSON.stringify({
          type: 'MULTIPLE',
          search: [
            {
              values: props.searchTerm,
              columns: ['diseaseName', 'icd'],
              fullMatch: false,
              active: props.activeAnyField,
            },
          ],
        })}
        classesOverride={{
          lastCell: props.userHasPermissionToEditIcdItem ? classes.lastCell : classes.lastCellEmpty,
          table: classes.wrapText,
        }}
        triggerReload={props.triggerReload}
      />
    </>
  );
};

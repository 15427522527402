/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Box, Grid, Typography, Button, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import withStyles from '@mui/styles/withStyles';
import { WithStyles } from '@mui/styles';
import DetailField from 'components/form/field/field';
import { deliveryShippingMethodMap, methodOfDelivery } from 'constants/lists';
import { getDeliveryMethodLogo } from 'containers/tasks/fill-coordination/fc-util';
import { IFillCoordinationEditFormFields } from 'containers/tasks/fill-coordination/types';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { convertToArborDate } from 'models/time/arbor-date';
import { buildQaId } from 'utils/build-qa-id';
import { nameOfFactory } from 'utils/types-util';
import { DeliveryMethodValue, LookupNoteTagType } from 'constants/enums';
import { windowFeatureIsEnabled } from 'config/window-features';
import NoteArea from 'components/note/NoteArea';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import { addNote } from 'actions/action-notes';
import { parseNoteToDb } from 'services/utils/note-service';
import { ADD_NOTE_FORM } from 'constants/index';
import { compose } from 'recompose';
import { IDeliveryInformation } from './types';
import { ShippingStyles } from './shipping.styles';
import ValuesEdited from './values-edited';
import { ShippingVerificationStatus } from './shipping-verification';
import { isShippingPage } from './utils';
import ShippingScanHistory from './shipping-scan-history-modal';

const nameOfFormFields = nameOfFactory<IFillCoordinationEditFormFields>();
interface IFormProps {
  note_text: string;
}

interface IShippingProps
  extends IDeliveryInformation,
    WithStyles<typeof ShippingStyles>,
    InjectedFormProps<IFormProps> {}

const getQaId = buildQaId('shipping.delivery-information', '.');

// eslint-disable-next-line @typescript-eslint/naming-convention
function DeliveryInformation(props: IShippingProps): JSX.Element | null {
  const {
    currentDeliveryInformation,
    currentPrintInformation,
    currentFdcInformation,
    classes,
    scanProps,
    handleSubmit: onSubmit,
    pristine,
    reset,
    isMissingCourierConfiguration = false,
  } = props;
  const dispatch = useDispatch();
  const UPS_TRACKING_URL_PREFIX = 'http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=';

  const upsPackagingTypeOptions = useTypedSelector(state => state.lookups.upsPackagingType);

  const users = useTypedSelector(state => state.lookups.users);
  let shippingLabel: string | undefined;
  let deliveryMethodSelected;
  let deliveryMethodLogo: string | undefined;
  const isPickup = currentDeliveryInformation?.deliveryMethod === DeliveryMethodValue.PickUp;
  if (currentDeliveryInformation?.deliveryMethod) {
    const mappedData = deliveryShippingMethodMap[currentDeliveryInformation?.deliveryMethod]?.find(
      data => {
        if (currentDeliveryInformation?.shippingMethod) {
          const shippingMethodObj = JSON.parse(currentDeliveryInformation?.shippingMethod);
          const shippingMethodValue = Object.keys(shippingMethodObj)[0];
          return data.value === shippingMethodValue;
        }

        return false;
      },
    );
    shippingLabel = mappedData?.label;

    deliveryMethodSelected = methodOfDelivery.find(method => {
      return method.value === currentDeliveryInformation?.deliveryMethod;
    });

    if (deliveryMethodSelected) {
      deliveryMethodLogo = getDeliveryMethodLogo(deliveryMethodSelected.label);
    }
  }
  const handleSubmit = (values: IFormProps) => {
    const newValues: any = parseNoteToDb(values);
    const tags = [{ tag_type_id: LookupNoteTagType.FDC, resource_id: currentFdcInformation?.id }];
    const payload = {
      patient_id: currentDeliveryInformation?.patientId,
      note_text: newValues.note_text,
      is_pinned: 0,
      mentions: newValues.mentions,
      tags,
    };

    dispatch(addNote(payload));
    reset();
  };

  const displayMethodLogo = (): JSX.Element | null => {
    if (deliveryMethodLogo) {
      return (
        <Grid item className={classes.deliveryInformationLogo}>
          <img src={deliveryMethodLogo} alt="Delivery Method Logo" />
        </Grid>
      );
    }
    return <Grid />;
  };

  const internalOrExternalCourier = (): string => {
    if (!windowFeatureIsEnabled('courier_type')) {
      return '';
    }
    if (currentDeliveryInformation?.courierType === 0) {
      return ' - Internal';
    }
    if (currentDeliveryInformation?.courierType === 1) {
      return ' - External';
    }
    return '';
  };

  const displayMethodOfDelivery = (): JSX.Element | null => {
    const deliveryMethodSelected = methodOfDelivery.find(method => {
      return method.value === currentDeliveryInformation?.deliveryMethod;
    });
    if (shippingLabel) {
      return (
        <Grid data-qa-id={getQaId(nameOfFormFields('preferred_rx_delivery_method'))}>
          {deliveryMethodSelected?.label} - {shippingLabel} -{' '}
          {
            upsPackagingTypeOptions.find(x => x.id === currentDeliveryInformation?.upsPackagingType)
              ?.packaging_type
          }
          {displayMissingCourierConfiguration()}
        </Grid>
      );
    }
    return (
      <Grid style={{ display: 'flex', alignItems: 'center' }}>
        {`${deliveryMethodSelected?.label}${internalOrExternalCourier()}`}
        {displayMissingCourierConfiguration()}
      </Grid>
    );
  };

  const displayShippingOrPickUp = (): JSX.Element | null => {
    if (
      currentDeliveryInformation?.deliveryMethod === DeliveryMethodValue.PickUp &&
      currentDeliveryInformation?.orderTypeAddress
    ) {
      return (
        <Grid item xs={4} data-qa-id={getQaId(nameOfFormFields('order_type_address'))}>
          <DetailField
            fieldName="Pick-up Location"
            field={currentDeliveryInformation?.orderTypeAddress}
          />
        </Grid>
      );
    }
    return (
      <Grid item xs={4} data-qa-id={getQaId(nameOfFormFields('address'))}>
        <DetailField
          fieldName="Preferred Shipping Address"
          field={currentDeliveryInformation?.shippingAddress}
        />
      </Grid>
    );
  };

  const displayRxDeliveryNote = (): JSX.Element | null => {
    if (currentDeliveryInformation?.specialInstructions) {
      return (
        <Grid item xs={8} data-qa-id={getQaId(nameOfFormFields('special_instructions'))}>
          <DetailField
            fieldName="Rx Delivery Note"
            field={currentDeliveryInformation?.specialInstructions}
          />
        </Grid>
      );
    }
    return <Grid />;
  };

  const displayPhone = (): JSX.Element | null => {
    if (currentDeliveryInformation?.phoneNumber) {
      return (
        <Grid item xs={4} data-qa-id={getQaId(nameOfFormFields('preferred_phone_number'))}>
          <DetailField
            fieldName="Preferred Phone"
            field={currentDeliveryInformation?.phoneNumber}
          />
        </Grid>
      );
    }
    return <Grid />;
  };

  const displayDispensingPharmacy = (): JSX.Element | null => {
    if (currentDeliveryInformation?.dispensingPharmacyName) {
      return (
        <Grid item xs={4} data-qa-id={getQaId(nameOfFormFields('dispensing_pharmacy'))}>
          <DetailField
            fieldName="Dispensing Pharmacy"
            field={currentDeliveryInformation?.dispensingPharmacyName}
          />
        </Grid>
      );
    }
    return null;
  };

  const displayEmail = (): JSX.Element | null => {
    if (currentDeliveryInformation?.email) {
      return (
        <Grid item data-qa-id={getQaId(nameOfFormFields('email'))}>
          <DetailField fieldName="E-mail" field={currentDeliveryInformation?.email} />
        </Grid>
      );
    }
    return <Grid />;
  };

  const displayShipDate = (): JSX.Element | null => {
    if (currentDeliveryInformation?.shipDate) {
      return (
        <Grid item xs={4} data-qa-id={getQaId(nameOfFormFields('ship_date'))}>
          <DetailField
            fieldName="Ship Date"
            field={convertToArborDate(currentDeliveryInformation?.shipDate).getUtcDate(true)}
          />
        </Grid>
      );
    }
    return <Grid />;
  };

  const displayDeliveryDate = (): JSX.Element | null => {
    if (currentDeliveryInformation?.deliveryDt) {
      return (
        <Grid item xs={4} data-qa-id={getQaId(nameOfFormFields('delivery_dt'))}>
          <DetailField
            fieldName="Expected Delivery Date"
            field={convertToArborDate(currentDeliveryInformation?.deliveryDt).getUtcDate(true)}
          />
        </Grid>
      );
    }
    return <Grid />;
  };

  const displayUpsTrackingNumber = (): JSX.Element | null => {
    const trackingNumber = currentPrintInformation?.tracking_number;
    if (trackingNumber) {
      const url = `${UPS_TRACKING_URL_PREFIX}${trackingNumber}`;
      return (
        <Grid item className={classes.trackingNumber}>
          <Typography className={classes.trackingNumberTitle}>Tracking Number</Typography>
          <a href={url} target="_blank" rel="noreferrer">
            {trackingNumber}
          </a>
        </Grid>
      );
    }
    return <Grid />;
  };

  const displayLabLogisticsOrderId = (): JSX.Element | null => {
    const labLogisticsOrderId = currentPrintInformation?.lab_logistics_order_id;
    if (labLogisticsOrderId) {
      return (
        <Grid item className={classes.trackingNumber} data-qa-id={getQaId('order-number')}>
          <Typography className={classes.trackingNumberTitle}>Order Number</Typography>
          {labLogisticsOrderId}
        </Grid>
      );
    }
    return <Grid />;
  };

  const displayAssociatedCourierOrderId = (): JSX.Element | null => {
    const associatedCourierOrderId = currentPrintInformation?.associated_courier_order_id;
    if (associatedCourierOrderId) {
      return (
        <Grid item className={classes.trackingNumber} data-qa-id={getQaId('order-number')}>
          <Typography className={classes.trackingNumberTitle}>Order Number</Typography>
          {associatedCourierOrderId}
        </Grid>
      );
    }
    return <Grid />;
  };

  const displayNuVizzCourierOrderId = (): JSX.Element | null => {
    const nuvizzCourierOrderId = currentPrintInformation?.nuvizz_courier_order_id;
    if (nuvizzCourierOrderId) {
      return (
        <Grid item className={classes.trackingNumber} data-qa-id={getQaId('order-number')}>
          <Typography className={classes.trackingNumberTitle}>Order Number</Typography>
          {nuvizzCourierOrderId}
        </Grid>
      );
    }
    return <Grid />;
  };
  const shippingNotesField = (): JSX.Element => {
    if (isShippingPage()) {
      return (
        <Grid
          component="form"
          container
          item
          justifyContent="flex-end"
          onSubmit={onSubmit(handleSubmit)}
          autoComplete="off"
          className={classes.noteWrapper}
          spacing={1.5}
        >
          <Grid item xs={12} justifyContent="flex-end" direction="row">
            <Box className={classes.noteTextArea}>
              <Field name="note_text" component={NoteArea} />
            </Box>
          </Grid>
          {!pristine && (
            <Grid item xs={12}>
              <Button
                className={classes.saveNoteButton}
                type="submit"
                size="large"
                variant="outlined"
              >
                Save note
              </Button>
            </Grid>
          )}
        </Grid>
      );
    }
    return <Grid />;
  };

  const displayMissingCourierConfiguration = (): JSX.Element | null => {
    if (!isMissingCourierConfiguration) {
      return null;
    }
    return (
      <Tooltip title="The selected delivery pharmacy is not configured for courier delivery">
        <InfoIcon style={{ color: 'red', paddingLeft: '5px' }} />
      </Tooltip>
    );
  };

  return (
    <Grid className={classes.sectionBottomMargin}>
      <Grid className={classes.deliveryInformationPanel}>
        <Typography className={classes.deliveryInformationTitle}>Shipping Information</Typography>
        {!isShippingPage() && scanProps && !isPickup ? (
          <Box sx={{ marginRight: '6px' }}>
            <ShippingVerificationStatus status={scanProps?.shippingScanStatus} />
            <ShippingScanHistory scanProps={scanProps} />
          </Box>
        ) : null}
      </Grid>

      <Grid item xs={12} className={classes.contentsOfPackage}>
        <Grid container className={classes.subsection}>
          <Grid item xs={11}>
            <Grid container spacing={3}>
              <Grid container item>
                {displayMethodLogo()}
                <Grid item className={classes.deliveryInformationMethod}>
                  <Typography variant="caption" className={classes.detailHeader}>
                    Method of Delivery
                  </Typography>
                  {displayMethodOfDelivery()}
                </Grid>
              </Grid>
              <Grid container item>
                {displayShippingOrPickUp()}
                {displayRxDeliveryNote()}
              </Grid>
              <Grid container item>
                {displayPhone()}
                {displayEmail()}
              </Grid>
              <Grid container item>
                {displayDispensingPharmacy()}
                <Grid item xs={4} data-qa-id={getQaId(nameOfFormFields('signature_required'))}>
                  <DetailField
                    fieldName="Signature Required"
                    field={currentDeliveryInformation?.signature ? 'Yes' : 'No'}
                  />
                </Grid>
                <Grid item data-qa-id={getQaId(nameOfFormFields('cold_chain_packing_required'))}>
                  <DetailField
                    fieldName="Coldchain"
                    field={currentDeliveryInformation?.coldChain ? 'Yes' : 'No'}
                  />
                </Grid>
                <Grid container item>
                  <ValuesEdited deliveryInformation={currentDeliveryInformation} />
                </Grid>
              </Grid>
              <Grid container item>
                {displayDeliveryDate()}
                {displayShipDate()}
                {displayUpsTrackingNumber()}
                {displayLabLogisticsOrderId()}
                {displayAssociatedCourierOrderId()}
                {displayNuVizzCourierOrderId()}
                {shippingNotesField()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default compose<IShippingProps, IDeliveryInformation>(
  withStyles(ShippingStyles),
  reduxForm({ form: ADD_NOTE_FORM }),
)(DeliveryInformation);

import React from 'react';
import { Grid, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { BaseTable } from 'containers/application-manager/base-table/base-table';
import { IGetCdmProgramsResponse } from 'models/application-manager/cpmp/IGetCdmProgramsResponse';
import { ICdmProgramAndConditions } from 'reducers/reducer-cdm-programs';
import { IHeaderCell } from '../../../base-table/types';
import { IResponse, IResult } from '../types';
import { styles } from '../../index.styles';
import { IRsProtocolFormModalRef, RsProtocolFormModal } from '../form-modal';

interface ITableProps {
  searchTerm: string | undefined;
  triggerReload: Date | undefined;
  handleReloadDataTrigger: (date: Date | undefined) => void;
  userHasPermissionToAddUpdateRsprotocol: boolean;
  activeAnyField: boolean;
  cdmProgramsList: IGetCdmProgramsResponse;
  onRsProtocolFormSuccess: () => void;
}

type OnClickCb = () => void;
type OnClickCbBuilder = (protocol: ICdmProgramAndConditions) => OnClickCb;

const EditCell = (props: ICdmProgramAndConditions & { onClickBuilder: OnClickCbBuilder }) => {
  const { onClickBuilder, ...protocol } = props;

  return <EditIcon fontSize="small" onClick={onClickBuilder(protocol)} />;
};

const buildRendererEditCell = (onClickBuilder: OnClickCbBuilder) => (props: IResult) =>
  <EditCell {...props} onClickBuilder={onClickBuilder} />;

export const DataTable: React.FC<ITableProps> = (props): JSX.Element => {
  const classes = styles();

  const [protocol, setProtocol] = React.useState<ICdmProgramAndConditions | undefined>(undefined);
  const editFormModalRef = React.useRef<IRsProtocolFormModalRef>(null);

  const canEdit = props.userHasPermissionToAddUpdateRsprotocol;

  // # region helper functions
  const mapRsProtocolToTableResult = (rsProtocol: ICdmProgramAndConditions): any => {
    const result = {
      ...rsProtocol,
      icd_list: rsProtocol?.associatedIcdCodes?.join(', '),
      children: [rsProtocol],
    } as any;
    return result;
  };

  const getRsProtocolData = React.useMemo<IResponse | undefined>(() => {
    const protocolData = props.cdmProgramsList.result;
    if (protocolData.length > 0) {
      const cdmProtocols = protocolData.map((x: ICdmProgramAndConditions) =>
        mapRsProtocolToTableResult(x),
      );
      return { totalCount: cdmProtocols.length - 1, results: cdmProtocols };
    }
    return undefined;
  }, [props.cdmProgramsList]);

  const getOnEditCellClick = (clickedProtocol: ICdmProgramAndConditions) => () => {
    editFormModalRef.current?.setOpen(true);
    setProtocol(clickedProtocol);
  };
  // #endregion

  // # region constants
  const COLUMN_SETTINGS: IHeaderCell<IResult, any>[] = [
    {
      label: 'Disease State Name',
      parentKey: 'name',
      sortable: true,
      // serverSideSearchField: 'name',
      hideLeftPadding: true,
      childColSpan: 2,
      childComponent: (parent: IResult) => {
        return (
          <Grid container>
            <Grid item xs={2}>
              <Typography className={classes.contentTitle}>Risk Strat</Typography>
              <Typography className={classes.contentDescription}>{parent.type_name}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.contentTitle}>Frequency</Typography>
              <Typography className={classes.contentDescription}>
                {`${
                  parent.frequency_days % 30 === 0
                    ? parent.frequency_days / 30
                    : parent.frequency_days
                } ${parent.frequency_days % 30 === 0 ? 'Months' : 'Days'}`}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.contentTitle}>Enrollment Critera</Typography>
              <Typography className={classes.contentDescription}>
                {`${parent.enrollment_clinical_data_operator} ${parent.enrollment_clinical_data_value}`}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.contentTitle}>Graduation Critera</Typography>
              <Typography className={classes.contentDescription}>
                {`${parent.graduation_clinical_data_operator} ${parent.graduation_clinical_data_value}`}
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      label: 'Associated ICD-10',
      parentKey: 'icd_list',
      hideLeftPadding: true,
      sortable: true,
      hideChildCol: true,
    },
  ];
  // #endregion

  if (canEdit) {
    COLUMN_SETTINGS.push({
      label: '',
      sortable: false,
      hideLeftPadding: true,
      parentComponent: buildRendererEditCell(getOnEditCellClick),
    });
  }

  return (
    <>
      <BaseTable<IResponse, IResult, any>
        actionsPermitted={props.userHasPermissionToAddUpdateRsprotocol}
        orderByDefaultParent="name"
        dataSet={getRsProtocolData}
        paginationQueryParamSettings={{
          pageSizeQueryStringKey: 'pageSize',
          pageNumberQueryStringKey: 'pageNumber',
          searchTermQueryStringKey: 'searchTerm',
          sortPropQueryStringKey: 'sortProp',
          sortOrderQueryStringKey: 'sortOrder',
        }}
        childPkSelector={() => ''}
        parentPkSelector={() => ''}
        columnSettings={COLUMN_SETTINGS}
        searchTerm={JSON.stringify({
          type: 'MULTIPLE',
          search: [
            {
              values: props.searchTerm,
              columns: ['name', 'icd_list'],
              fullMatch: false,
              active: props.activeAnyField,
            },
          ],
        })}
        classesOverride={{
          lastCell: props.userHasPermissionToAddUpdateRsprotocol
            ? classes.lastCell
            : classes.lastCellEmpty,
          table: classes.wrapText,
        }}
        triggerReload={props.triggerReload}
      />
      <RsProtocolFormModal
        ref={editFormModalRef}
        editMode
        protocol={protocol}
        onSuccess={props.onRsProtocolFormSuccess}
      />
    </>
  );
};

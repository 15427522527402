import HTTP from 'services/http';
import {
  FETCH_PREFERRED_CONTACT,
  ADD_CONTACT,
  EDIT_CONTACT,
  EDIT_CONTACT_AND_UPDATE_PATIENT,
  ADD_DELIVERY_PROVIDER,
} from 'constants/index';

export function fetchPreferredContact(patientId) {
  const request = HTTP.get(`/patients/${patientId}/preferred_contact`);
  return {
    type: FETCH_PREFERRED_CONTACT,
    initialValue: { data: { contact: null } },
    payload: request,
  };
}

export function addContact(newContact) {
  const postURL = `/patients/${newContact.patient_id}/contacts`;
  const request = HTTP.post(postURL, newContact, {});
  return {
    type: ADD_CONTACT,
    payload: request,
    meta: {
      errorMessage: 'Contact Added Failed: ',
      successMessage: 'Contact Added Successfully',
    },
  };
}

export function addDeliveryProvider(deliveryProvider) {
  const postURL = `/patients/${deliveryProvider.patient_id}/delivery-provider`;
  const request = HTTP.post(postURL, deliveryProvider, {});
  return {
    type: ADD_DELIVERY_PROVIDER,
    payload: request,
    meta: {
      errorMessage: 'Delivery Provider Added Failed: ',
      successMessage: 'Delivery Provider Added Successfully',
    },
  };
}

export function editContact(updatedContact) {
  const postURL = `/patients/${updatedContact.patient_id}/contacts/${updatedContact.id}`;
  const request = HTTP.patch(postURL, updatedContact, {});
  return {
    type: EDIT_CONTACT,
    payload: request,
    meta: {
      errorMessage: 'Contact Updated Failed: ',
      successMessage: 'Contact Updated Successfully',
    },
  };
}

export function editContactAndUpdatePatient(updatedContact, callback, reject) {
  return { type: EDIT_CONTACT_AND_UPDATE_PATIENT, payload: { updatedContact, callback, reject } };
}

import { SET_DOCUMENT_VIEWER } from 'constants/index';

const initialState = {
  open: false,
  filePath: undefined,
  fileExtension: undefined,
  documentId: undefined,
  fileName: undefined,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_DOCUMENT_VIEWER:
      return { ...state, ...payload };
    default:
      return state;
  }
};

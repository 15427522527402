import React from 'react';
import { Grid, Modal, Typography } from '@mui/material';
import { IState } from 'interfaces/redux/IState';
import { useDispatch, useSelector } from 'react-redux';
import { addContactModalActions } from 'actions/action-add-contact-modal';
import withStyles from '@mui/styles/withStyles';
import { getModalStyle } from 'services/utils/styles-service';
import ContactForm from 'containers/patient/demographics/contact-form';
import { change } from 'redux-form';
import { MODAL_CLOSE_TYPE } from 'constants/modal';
import { styles } from './add-contact-modal.styles';
import { IProps } from './interfaces/IProps';

// eslint-disable-next-line react/function-component-definition, @typescript-eslint/naming-convention
const AddContactModal: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { classes, contacts } = props;
  const addContactModal = useSelector((state: IState) => state.addContactModal);
  const [lastContact, setLastContact] = React.useState<number | null>(null);

  const lastContactValue = lastContact !== null ? contacts[lastContact]?.value : undefined;

  const handleSetLastContact = () => {
    const lastContactOption = contacts.length;
    setLastContact(lastContactOption);
  };

  const dispatch = useDispatch();

  const closeHandler = (closeType: keyof typeof MODAL_CLOSE_TYPE) => {
    if (closeType === MODAL_CLOSE_TYPE.DIALOG_CLOSED) {
      dispatch(addContactModalActions.resetModal());
    }
  };

  React.useEffect(() => {
    if (lastContactValue && addContactModal.formToUpdate?.formName) {
      dispatch(
        change(
          addContactModal.formToUpdate.formName,
          addContactModal.formToUpdate.fieldName,
          lastContactValue,
        ),
      );
      dispatch(addContactModalActions.resetModal());
    }
  }, [lastContactValue]);

  return (
    <Modal open={addContactModal.visible}>
      <div style={getModalStyle()} className={classes.addModal}>
        <Grid container>
          <Grid item xs={12} className={classes.row}>
            <Typography variant="h6" className={classes.heading}>
              Add New Address
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ContactForm closeHandler={closeHandler} setLastContact={handleSetLastContact} />
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default withStyles(styles)(AddContactModal);

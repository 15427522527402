import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

const styles = (theme: Theme) => {
  const { palette, font } = expandTheme(theme);

  return createStyles({
    formWrapper: {
      marginTop: theme.spacing(1.5),
    },
    diagnosisNameLabelWrapper: {
      marginBottom: theme.spacing(1.5),
    },
    clinicalSupportWrapper: {
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2),
    },
    clinicalSupportLabel: {
      fontSize: font.smallFontSize,
    },
    diagnosisDescription: {
      marginTop: theme.spacing(2),
      fontSize: font.mediumFontSize,
      fontWeight: 'bold',
    },
    sectionWrapper: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    riskTratificationLabel: {
      fontSize: font.smallFontSize,
      fontWeight: 'bold',
    },
    patientGoalsTitle: {
      fontSize: font.smallFontSize,
      fontWeight: 'bold',
    },
    optInReasonWrapper: {
      paddingLeft: theme.spacing(2),
    },
    optOutReasonWrapper: {
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    optOutMessage: {
      fontSize: font.mediumFontSize,
      color: palette.primary.valenciaRed,
      display: 'inline',
    },
    riskStratLabelFragment: {
      display: 'inline',
      marginLeft: 4,
      cursor: 'pointer',
      fontSize: font.smallFontSize,
    },
    bold: {
      fontWeight: 'bold',
    },
    newProblemsContainer: {
      marginBottom: theme.spacing(30),
    },
  });
};

export { styles };

import { AxiosPromise } from 'axios';
import { TypedHttp } from 'services/typed-http';

export interface IDrugPricing {
  unit_price: number;
}

export class DrugsClient {
  public static getDrugPricing(ndc: string): AxiosPromise<IDrugPricing> {
    const url = `/drugs/${ndc}/pricing`;
    return TypedHttp.GetPromise<IDrugPricing>(url);
  }
}

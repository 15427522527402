import { IAddAddressModal } from 'interfaces/redux/IAddAddressModal';
import {
  AddAddressModalActionType,
  IAddAddressModalAction,
} from '../actions/action-add-address-modal';

const initialState: IAddAddressModal = {
  visible: false,
  formId: undefined,
  field: undefined,
};

export const addAddressModalReducer = (
  state: IAddAddressModal = initialState,
  action: IAddAddressModalAction,
) => {
  switch (action.type) {
    case AddAddressModalActionType.SetState:
      return {
        ...action.payload,
      };
    case AddAddressModalActionType.ResetModal:
      return initialState;
    default:
      return state;
  }
};

import {
  FETCH_PATIENT_DOCUMENTS,
  UPDATE_PATIENT_DOCUMENTS,
  SELECTED_PATIENT_ID,
} from 'constants/index';

const initialState = {};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SELECTED_PATIENT_ID:
      return initialState;
    case FETCH_PATIENT_DOCUMENTS:
    case UPDATE_PATIENT_DOCUMENTS:
      return {
        ...state,
        ...payload.data.documents,
      };
    default:
      return state;
  }
};

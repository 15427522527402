import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import compose from 'recompose/compose';
import {
  selectTherapyId,
  selectTaskType,
  selectTaskId,
  selectSidebarType,
  selectSidebarTaskType,
  selectFirstTask,
} from 'actions/action-selections';
import { selectPatientTab } from 'actions/action-patient';
import { logPageView } from 'services/utils/analytics';
import { usePatientId } from 'hooks/usePatientId';
import {
  getUrlValue,
  PATIENT_ID,
  TASK_ID,
  THERAPY_ID,
  SELECTED_TAB,
  TASK_TYPE,
  getUrlQueryParam,
} from './helpers/router';

const RouteWatcher = props => {
  const {
    location,
    selectTherapyId, // eslint-disable-line no-shadow
    selectTaskType, // eslint-disable-line no-shadow
    selectTaskId, // eslint-disable-line no-shadow
    selectPatientTab, // eslint-disable-line no-shadow
    selectSidebarType, // eslint-disable-line no-shadow
    selectSidebarTaskType, // eslint-disable-line no-shadow
    selectFirstTask, // eslint-disable-line no-shadow
    patientList, // eslint-disable-line no-shadow
  } = props;

  const patientId = getUrlValue(location, PATIENT_ID);
  const therapyId = getUrlValue(location, THERAPY_ID);
  const taskId = getUrlValue(location, TASK_ID);
  const taskType = getUrlValue(location, TASK_TYPE);
  const selectedTab = getUrlValue(location, SELECTED_TAB);
  const urlSidebarType = getUrlQueryParam(location, 'sidebar');
  const urlSidebarTaskType = getUrlQueryParam(location, 'task_type');

  usePatientId(patientId);

  useEffect(() => {
    const { data } = patientList;
    if (!patientId && urlSidebarType === 'tasks' && data.length) {
      selectFirstTask(data);
    }
  }, [patientId, urlSidebarType, patientList.data]);

  useEffect(() => {
    if (therapyId) {
      selectTherapyId(Number(therapyId));
    }
  }, [therapyId]);

  useEffect(() => {
    if (taskType) {
      selectTaskType(taskType);
    }
  }, [taskType]);

  useEffect(() => {
    if (taskId) {
      selectTaskId(Number(taskId));
    }
  }, [taskId]);

  useEffect(() => {
    if (selectedTab) {
      selectPatientTab(selectedTab);
    }
  }, [selectedTab]);

  useEffect(() => {
    if (urlSidebarType) {
      selectSidebarType(urlSidebarType);
    }
  }, [urlSidebarType]);

  useEffect(() => {
    if (urlSidebarTaskType) {
      selectSidebarTaskType(urlSidebarTaskType);
    }
  }, [urlSidebarTaskType]);

  logPageView(); // GA-tracking

  return null;
};

const mapStateToProps = ({
  selectedPatientId,
  selectedTherapyId,
  selectedTaskType,
  selectedTaskId,
  sidebarType,
  sidebarTaskType,
  patientList,
}) => ({
  selectedPatientId,
  selectedTherapyId,
  selectedTaskType,
  selectedTaskId,
  sidebarType,
  sidebarTaskType,
  patientList,
});

const mapDispatchToProps = {
  selectTherapyId,
  selectTaskType,
  selectTaskId,
  selectPatientTab,
  selectSidebarType,
  selectSidebarTaskType,
  selectFirstTask,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(RouteWatcher);

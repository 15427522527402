export const styles = theme => ({
  documentsContainer: {
    marginLeft: 30,
    marginRight: 30,
  },
  documentListHeadingItem: {
    paddingRight: theme.spacing(2),
  },
  documentTitle: {
    color: theme.palette.text.primary,
    marginLeft: 30,
  },
  documentFilter: {
    fontSize: theme.font.smallFontSize,
    marginLeft: 30,
    paddingBottom: 5,
  },
  documentFilterMultiselect: {
    fontSize: theme.font.smallFontSize,
    minWidth: '100px',
    marginLeft: 8,
    paddingLeft: 5,
    marginRight: 3,
  },
  documentFilterMultiselectItem: {
    fontSize: theme.font.smallFontSize,
  },
  documentNote: {
    fontSize: theme.font.smallFontSize,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 5,
    marginBottom: 2,
    overflow: 'hidden',
    maxHeight: 200,
    position: 'relative',
  },
  iconCell: {
    color: theme.palette.text.primary,
    width: 30,
    paddingLeft: 5,
    paddingRight: 5,
  },
  pointer: {
    cursor: 'pointer',
  },
  table: {
    marginLeft: 30,
    width: '95%',
    marginBottom: 50,
  },
  documentFilterItem: {
    width: 200,
    display: 'inline-block',
  },
  documentFilterLabel: {
    display: 'inline',
    fontSize: theme.font.extraSmallFontSize,
    paddingRight: 5,
  },
  documentFilterWrapper: {
    display: 'inline',
    paddingRight: 15,
    paddingTop: 10,
  },
  downloadDocumentCell: {
    cursor: 'pointer',
  },
  tableHeader: {
    color: theme.palette.navigation.main,
    fontWeight: 'normal',
    marginBottom: 0,
    display: 'something',
  },
  tableRow: {
    '&:hover': {
      backgroundColor: theme.palette.primary.bluesky,
    },
  },
  archiveTableRow: {
    backgroundColor: theme.palette.primary.archive.background,
    cursor: 'initial',
  },
  archiveIcon: {
    color: theme.palette.navigation.main,
  },
  loadingText: {
    paddingLeft: 30,
    fontSize: theme.font.smallFontSize,
  },
  labelHeader: {
    minWidth: 100,
  },
  noteHeader: {
    minWidth: 200,
  },
  buttonContainer: {
    float: 'right',
  },
  archiveReasonContainer: {
    backgroundColor: theme.palette.primary.white,
    paddingLeft: 10,
    paddingTop: 10,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 5,
    marginBottom: 2,
    position: 'relative',
  },
  documentRowFileName: {
    margin: 0,
    marginBottom: 10,
  },
  archiveHeading: {
    margin: '5px 0',
  },
  documentMessage: {
    fontWeight: 500,
    fontSize: theme.font.smallFontSize,
    paddingTop: theme.spacing(1),
    whiteSpace: 'pre-wrap',
  },
  alertText: {
    color: theme.palette.error.main,
  },
  warningText: {
    color: theme.palette.primary.warning,
  },
});

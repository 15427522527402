import React, { useState, useMemo, useEffect, useCallback } from 'react';
import {
  Table,
  TableCell,
  TableSortLabel,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Box,
  Grid,
  Button,
  TableBody,
} from '@mui/material';
import moment from 'moment';
import { useTypedSelector } from 'hooks/use-typed-selector';
import AddTask from 'containers/tasks/add-task';
import { taskListOrder } from 'constants/lists';
import { buildQaIdProp } from 'utils/build-qa-id';
import withStyles from '@mui/styles/withStyles';
import { ITask } from 'interfaces/redux/ITasks';
import { getNeedsByDateOfTask, getStatusNameByStatusId } from 'services/utils/task-service';
import { IStatus, StatusTypes } from 'interfaces/redux/ITaskStatuses';
import { IFillCycles } from 'interfaces/redux/IFillCycles';
import { getDateRangeFromName } from 'services/utils/filters-service';
import { updateIndexPeriod } from 'actions/action-user';
import { ITherapy } from 'interfaces/redux/ITherapy';
import { ReactSelect } from 'components/form/field/react-select';
import { useClinicalData } from 'containers/patient/clinical/use-clinical-data';
import {
  clearSelectedTask,
  clearSelectedTasks,
  selectTaskKey,
  setSelectedTasks,
  toggleSelectedTaskRow,
  clearWagSelectedTask,
  setWagSelectedTasks,
} from 'actions/action-tasks';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { clamp } from 'lodash';
import { useDeepDiffSelector } from 'utils/deep-diff-props';
import { windowFeatureIsEnabled } from 'config/window-features';
import { GroupedTaskTabs } from '../grouped-task-tabs';
import TaskTableActionSection from './task-table-action-section';
import {
  PERIOD_DAYS_SELECTOR_UNITS_TASKLIST,
  CATEGORY_DONE,
  CATEGORY_ARCHIVED,
  TASK_TABLE_DATE_FILTER_CHANGE,
  NULLISH_DATE,
  SET_SHOW_SET_SELECTED_TASKS,
  FETCH_RELATED_TASK_CLINICAL_DATA,
  DC,
  INT,
} from '../../../../constants';
import { ITaskFilterItem, ITherapyFilterItem, TaskTableContainerProps } from './interfaces';

import { styles } from './table.styles';
import TasksTableRow from './tasks-table-row';
import { getTaskKey, getTherapyName, isIntDuplicated, getIntsWagToBeAdded } from './utils';
import { selectSelectedTasks } from '../selectors';
import createMemoizedTaskComponent from '../create-memoized-task-component';
import { selectTherapiesList } from '../../med-sync/med-sync-state';
import { IUniqueIntTasks } from './types';

const TaskTableBody = createMemoizedTaskComponent(TableBody);

const qaIdProp = buildQaIdProp('active-tasks-table');

const filterActiveTasks = (
  tasks: Record<string, ITask>,
  links: any,
  dispatch: any,
): Record<string, ITask> => {
  if (!tasks) {
    return tasks;
  }
  const filteredTasks: Record<string, ITask> = {};
  const interventionsAdded: IUniqueIntTasks[] = [];
  const intWorkedAsgroup: ITask[] = [];
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in tasks) {
    const task = tasks[key];
    const isComplete = task.status_category_id
      ? [CATEGORY_DONE, CATEGORY_ARCHIVED].includes(task.status_category_id)
      : false;
    const updatedToday = moment.utc(task.updated).isSame(moment.utc(), 'day');
    if (!isComplete || updatedToday) {
      if (task.taskType === INT) {
        if (windowFeatureIsEnabled('merge_interventions')) {
          if (!task.work_as_group && !task.wag_hash) {
            const intDuplicated = isIntDuplicated(task, interventionsAdded, links);
            if (!intDuplicated) {
              filteredTasks[key] = task;
            }
          } else if (task.work_as_group && task.wag_hash) {
            // group all the INTs to handle them later
            intWorkedAsgroup.push(task);
          }
        } else {
          const intDuplicated = isIntDuplicated(task, interventionsAdded, links);
          if (!intDuplicated) {
            filteredTasks[key] = task;
          }
        }
      } else {
        filteredTasks[key] = task;
      }
    }
  }
  const wagIntTasks = getIntsWagToBeAdded(intWorkedAsgroup, links, dispatch);
  if (windowFeatureIsEnabled('merge_interventions') && wagIntTasks.length) {
    for (const wagIntTask of wagIntTasks) {
      filteredTasks[`${wagIntTask.taskType}${wagIntTask.id}`] = wagIntTask;
    }
  }
  return filteredTasks;
};

// eslint-disable-next-line react/function-component-definition
type Order = 'asc' | 'desc';

function descendingComparator(
  a: ITask,
  b: ITask,
  orderBy: keyof HeaderSortKey,
  therapies: ITherapy[],
  tasksStatuses: Record<StatusTypes, IStatus[]>,
  fillCycles: IFillCycles,
) {
  switch (orderBy) {
    case 'status': {
      const taskStatusA = (
        a?.task_status_text || getStatusNameByStatusId(a.status_id, tasksStatuses)
      ).toLowerCase();

      const taskStatusB = (
        b?.task_status_text || getStatusNameByStatusId(b.status_id, tasksStatuses)
      ).toLowerCase();

      if (taskStatusB < taskStatusA) {
        return -1;
      }
      if (taskStatusB > taskStatusA) {
        return 1;
      }
      return 0;
    }
    case 'followUpDate': {
      const fudA = Date.parse(a.followup_dt ?? NULLISH_DATE);
      const fudB = Date.parse(b.followup_dt ?? NULLISH_DATE);

      if (fudB < fudA) {
        return -1;
      }
      if (fudB > fudA) {
        return 1;
      }

      return 0;
    }
    case 'needsByDate': {
      const nbdA = getNeedsByDateOfTask(fillCycles, a) ?? NULLISH_DATE;
      const nbdB = getNeedsByDateOfTask(fillCycles, b) ?? NULLISH_DATE;

      if (nbdB < nbdA) {
        return -1;
      }
      if (nbdB > nbdA) {
        return 1;
      }
      return 0;
    }
    default: {
      const therapyA = getTherapyName(a, therapies) ?? '';
      const therapyB = getTherapyName(b, therapies) ?? '';

      if (therapyB < therapyA) {
        return -1;
      }
      if (therapyB > therapyA) {
        return 1;
      }
      return 0;
    }
  }
}

function getComparator(
  order: Order,
  orderBy: keyof HeaderSortKey,
  therapies: ITherapy[],
  taskStatuses: Record<StatusTypes, IStatus[]>,
  fillCycles: IFillCycles,
): (a: ITask, b: ITask) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, therapies, taskStatuses, fillCycles)
    : (a, b) => -descendingComparator(a, b, orderBy, therapies, taskStatuses, fillCycles);
}

interface HeaderSortKey {
  therapyName: string;
  status: string;
  followUpDate: string;
  needsByDate: string;
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const TasksTableActiveContainer: React.FC<TaskTableContainerProps & { classes: any }> = (
  props,
): JSX.Element | null => {
  const {
    classes,
    display,
    toggleActiveArchived,
    taskActiveArchivedOptions,
    taskActiveArchivedSelected,
  } = props;
  const dispatch = useAppDispatch();
  const tasks = useTypedSelector(state => state.tasks.data);
  const tasksLoading = useTypedSelector(state => state.tasks.loading);
  const selectedTasks = useDeepDiffSelector(selectSelectedTasks);
  const selectedTaskKeys = useTypedSelector(state => state.tasks.selectedTaskKeys);
  const selectedWagTasks = useTypedSelector(state => state.tasks.selectedWagTaskKeys);
  const [lastToggleTask, setLastToggleTask] = useState<ITask | null>(null);
  const filteredTaskPeriodIndex = useTypedSelector(state =>
    clamp(
      state.userPreferences.tasklist?.period ?? 0,
      0,
      PERIOD_DAYS_SELECTOR_UNITS_TASKLIST.length - 1,
    ),
  );
  const [fromDateValue, setFromDateValue] = useState<string | null>(null);
  const [toDateValue, setToDateValue] = useState<string | null>(null);
  const links = useTypedSelector(state => state.links.data);
  const activeTasks = useMemo(
    () => Object.values(filterActiveTasks(tasks, links, dispatch) || {}),
    [tasks, links],
  );
  const [filteredTherapies, setFilteredTherapies] = useState<ITherapyFilterItem[]>([]);
  const [filteredTaskTypes, setFilteredTaskTypes] = useState<ITaskFilterItem[]>([]);
  const filteredTaskPeriod = useMemo<{
    label: string;
    value: string | number;
  }>(() => PERIOD_DAYS_SELECTOR_UNITS_TASKLIST[filteredTaskPeriodIndex], [filteredTaskPeriodIndex]);

  const [selectAll, setSelectAll] = useState<'none' | 'all' | 'partial'>('none');
  const therapyIndex = null;
  const [displayAddForm, setDisplayAddForm] = useState<boolean>(false);
  const [triggerRowUpdate, setTriggerRowUpdate] = useState<ITask[]>([]);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof HeaderSortKey>('followUpDate');
  const handleDisplayAddForm = (b: boolean) => {
    setDisplayAddForm(b);
  };
  const therapies = useDeepDiffSelector(selectTherapiesList);
  const tasksStatuses = useTypedSelector(state => state.taskStatuses.statuses);
  const fillCycles = useTypedSelector(state => state.fillCycles);
  const patient = useTypedSelector(state => state.patient);
  const patientId = patient.id;
  const showForm = useTypedSelector(state => state.tasks.showSelectedTasks);
  const clinicalDataItems = useClinicalData(patientId) || [];
  const setShowForm = useCallback(
    (show?: boolean) => {
      dispatch({ type: SET_SHOW_SET_SELECTED_TASKS, payload: !!show });
    },
    [dispatch],
  );

  useEffect(() => {
    // Reset selected tasks to empty array when this component unmounts.
    return () => {
      dispatch(clearSelectedTasks());
    };
  }, [dispatch]);

  const taskTypesForFilter: string[] = useMemo(() => {
    const x: string[] = [];
    activeTasks.forEach(task => x.includes(task.taskType) || x.push(task.taskType));
    x.sort();
    return x;
  }, [activeTasks]);

  const saveUserPreferences = useCallback(
    () => dispatch({ type: TASK_TABLE_DATE_FILTER_CHANGE, payload: null }),
    [dispatch],
  );

  useEffect(() => {
    setFilteredTaskTypes([]);
    setFilteredTherapies([]);
    setDisplayAddForm(false);
  }, [patientId]);

  useEffect(() => {
    if (selectedWagTasks.length === 1) {
      dispatch(setWagSelectedTasks([]));
    } else {
      const missingKeys = selectedWagTasks.filter(t => !selectedTaskKeys.includes(t));
      if (missingKeys && missingKeys.length) {
        dispatch(setSelectedTasks(selectedTaskKeys.concat(missingKeys)));
      }
    }
  }, [selectedWagTasks]);

  useEffect(() => {
    if (
      lastToggleTask !== null &&
      lastToggleTask.taskType === DC &&
      selectedWagTasks.includes(`${lastToggleTask.taskType}${lastToggleTask.id}`)
    ) {
      dispatch(clearWagSelectedTask([`${lastToggleTask.taskType}${lastToggleTask.id}`]));
    }
    if (lastToggleTask) setLastToggleTask(null);
  }, [lastToggleTask]);

  const filteredTasks = useMemo(() => {
    // Date filter
    const tasksFilteredByDate =
      fromDateValue || toDateValue
        ? activeTasks.filter((task: ITask) => {
            if (!task.followup_dt) return true;
            const afterFromDate = !fromDateValue
              ? true
              : moment(task.followup_dt).isSameOrAfter(fromDateValue);
            const beforeToDate = !toDateValue
              ? true
              : moment(task.followup_dt).isSameOrBefore(toDateValue);

            const overdueTasks = moment(task.followup_dt).isSameOrBefore(
              moment().subtract(1, 'day').endOf('day'),
            );

            return (afterFromDate && beforeToDate) || overdueTasks;
          })
        : activeTasks;
    // Therapy filter
    const tasksFilteredByTherapy = tasksFilteredByDate.filter((task: ITask) => {
      if (filteredTherapies.length === 0) {
        return true;
      }
      let taskTherapyIdString = task.therapy_ids || '';
      if (taskTherapyIdString && task.therapy_id) {
        taskTherapyIdString = `${taskTherapyIdString},${task.therapy_id}`;
      } else if (task.therapy_id) {
        taskTherapyIdString = `${task.therapy_id}`;
      }

      const taskTherapyIdArr = taskTherapyIdString.split(',');
      return filteredTherapies.some((therapy: ITherapyFilterItem) =>
        taskTherapyIdArr.includes(`${therapy.value}`),
      );
    });

    // task type filter
    let taskTypeArr: string[] =
      filteredTaskTypes.map((taskType: ITaskFilterItem) => taskType.value) || [];
    if (filteredTaskTypes.length === 0) {
      taskTypeArr = taskListOrder;
    }
    const tasksFilteredByTaskType = tasksFilteredByTherapy.filter((task: ITask) =>
      taskTypeArr.includes(task.taskType),
    );

    tasksFilteredByTaskType.sort((a, b) => {
      if (!a.followup_dt && !b.followup_dt) {
        return 0;
      }
      if (!a.followup_dt) {
        return -1;
      }
      if (!b.followup_dt) {
        return 1;
      }
      if (moment(a.followup_dt).isBefore(moment(b.followup_dt))) {
        return -1;
      }
      if (moment(b.followup_dt).isBefore(moment(a.followup_dt))) {
        return 1;
      }
      return 0;
    });

    return tasksFilteredByTaskType;
  }, [toDateValue, fromDateValue, filteredTaskTypes, filteredTherapies, activeTasks]);

  const handleSelectAllClick = useCallback(
    (e: any) => {
      const newState = e.target.checked ? 'all' : 'none';
      const nextTasks = newState === 'none' ? [] : filteredTasks;
      setSelectAll(newState);
      dispatch(setSelectedTasks(nextTasks.map(getTaskKey)));
      if (nextTasks && !nextTasks.length) dispatch(setWagSelectedTasks([]));
    },
    [filteredTasks, dispatch],
  );

  useEffect(() => {
    if (selectedTaskKeys.length === 0) {
      setSelectAll('none');
      setShowForm(false);
      dispatch(setWagSelectedTasks([]));
    } else if (filteredTasks.length === selectedTaskKeys.length) {
      setSelectAll('all');
    } else {
      setSelectAll('partial');
    }
  }, [selectedTaskKeys.length, filteredTasks.length, setShowForm]);

  useEffect(() => {
    const { to, from } = getDateRangeFromName(
      PERIOD_DAYS_SELECTOR_UNITS_TASKLIST[filteredTaskPeriodIndex]?.value || 'ALL',
    );
    setToDateValue(to instanceof moment ? to.format('YYYY-MM-DD') : null);
    setFromDateValue(from instanceof moment ? from.format('YYYY-MM-DD') : null);
    saveUserPreferences();
  }, [saveUserPreferences, filteredTaskPeriodIndex]);

  const sortedTasks = useMemo(
    () =>
      stableSort(
        filteredTasks,
        getComparator(order, orderBy, therapies, tasksStatuses, fillCycles),
      ),
    [fillCycles, filteredTasks, order, orderBy, tasksStatuses, therapies],
  );

  const handleTaskTagFilterChange = (value: ITaskFilterItem[] | undefined) => {
    setFilteredTaskTypes(value || []);
  };

  const handleTherapyFilterChange = (value: ITherapyFilterItem[] | undefined) => {
    setFilteredTherapies(value || []);
  };

  const handleOnSelectedRow = useCallback(
    (task: ITask) => {
      setLastToggleTask(task);
      dispatch(toggleSelectedTaskRow(getTaskKey(task)));
      dispatch({ type: FETCH_RELATED_TASK_CLINICAL_DATA, payload: task });
    },
    [setLastToggleTask, dispatch],
  );

  const handleUnSelectTasks = useCallback(
    (unSelectedTasks: ITask[]) => {
      dispatch(clearSelectedTask(unSelectedTasks.map(getTaskKey)));
    },
    [dispatch],
  );

  const handleSelectTasksIds = useCallback(
    (newSelectedTasks: { newSelectedTaskId: number; newSelectedTaskType: string }[]) => {
      const tasksToAddToSelected = activeTasks.filter(task =>
        newSelectedTasks.some(
          newTask =>
            newTask.newSelectedTaskId === task.id && newTask.newSelectedTaskType === task.taskType,
        ),
      );
      dispatch(selectTaskKey(tasksToAddToSelected.map(getTaskKey)));
    },
    [activeTasks, dispatch],
  );

  const handleIndexPeriod = useCallback(
    (index: number) => {
      dispatch(updateIndexPeriod(index));
    },
    [dispatch],
  );

  const disableUpdateDates = useMemo(() => selectedTasks.length > 1, [selectedTasks]);
  const filterValuesSet =
    toDateValue || fromDateValue || filteredTaskTypes.length > 0 || filteredTherapies.length > 0;

  const handleRequestSort = (event: any, property: keyof HeaderSortKey) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof HeaderSortKey) => (event: any) => {
    handleRequestSort(event, property);
  };

  const tableSortLabelComponent = (header: string, headerKey: keyof HeaderSortKey) => (
    <TableSortLabel
      active={orderBy === headerKey}
      direction={orderBy === headerKey ? order : 'asc'}
      onClick={createSortHandler(headerKey)}
    >
      {header}
      {orderBy === header ? (
        <Box component="span" sx={{ display: 'none' }}>
          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        </Box>
      ) : null}
    </TableSortLabel>
  );

  return display ? (
    <>
      <Box display="flex" alignItems="center" width="100%" mb={2}>
        {taskActiveArchivedOptions && (
          <Grid item xs={2} className={classes.filterTaskStyling}>
            <ReactSelect
              data-qa-id="task_table_task_active_archived_select"
              defaultValues={null}
              isMulti={false}
              label="Task"
              value={taskActiveArchivedSelected}
              handleOnChange={toggleActiveArchived}
              fields={taskActiveArchivedOptions}
            />
          </Grid>
        )}
        <Grid item xs={2} className={classes.filterTaskStyling}>
          <ReactSelect
            data-qa-id="task_table_task_period_select"
            isMulti={false}
            defaultValues={null}
            label="Period"
            value={filteredTaskPeriod}
            handleOnChange={(value: { value: number; label: string }) => {
              handleIndexPeriod(value.value);
            }}
            fields={PERIOD_DAYS_SELECTOR_UNITS_TASKLIST.map(({ label, value }, index) => ({
              value: index,
              label,
            }))}
          />
        </Grid>
        <Grid item xs={2} className={classes.filterTaskStyling}>
          <ReactSelect
            data-qa-id="task_table_task_type_select"
            defaultValues={null}
            isMulti
            label="Task Type"
            value={filteredTaskTypes}
            handleOnChange={(value: ITaskFilterItem[] | undefined) =>
              handleTaskTagFilterChange(value)
            }
            fields={taskTypesForFilter.map((taskType: string) => ({
              value: taskType,
              label: taskType,
            }))}
          />
        </Grid>
        <Grid item xs={2} className={classes.filterTherapyStyling}>
          <ReactSelect
            data-qa-id="task_table_task_therapy_select"
            defaultValues={null}
            label="Therapy"
            value={filteredTherapies}
            handleOnChange={(value: ITherapyFilterItem[] | undefined) =>
              handleTherapyFilterChange(value)
            }
            fields={therapies
              .filter(
                (therapy: ITherapy) =>
                  therapy.administration_status_category_id !== CATEGORY_ARCHIVED,
              )
              .map((therapy: ITherapy) => {
                const therapyDosage =
                  therapy && therapy.strength && therapy.strength_unit_of_measure
                    ? `- ${therapy.strength} ${therapy.strength_unit_of_measure}`
                    : '';

                const therapyNameFull = `${therapy.drug_name} ${therapyDosage || ''}`;
                return {
                  value: therapy.id,
                  label: therapyNameFull,
                };
              })}
          />
        </Grid>
        <Grid item xs={4} container>
          <TaskTableActionSection
            tasks={selectedTasks}
            patientId={patientId}
            handleDisplayAddTaskForm={() => handleDisplayAddForm(!displayAddForm)}
            addTaskOpen={displayAddForm}
          />
        </Grid>
      </Box>
      {displayAddForm && (
        <Box width="100%" mb={2}>
          <AddTask
            handleCancel={() => handleDisplayAddForm(false)}
            therapyIndex={therapyIndex}
            availableTherapies={therapies}
          />
        </Box>
      )}
      {/* This will always re-render any time the parent (this component) renders or has an update.
       * we need to break this out to a separate component and possible memoize the props
       */}
      <TableContainer component={Paper} className={classes.table}>
        <Table {...qaIdProp('active-tasks-table')}>
          <TableHead>
            <TableRow className={classes.header}>
              <TableCell align="left" className={classes.headerNarrowColumn}>
                <Checkbox
                  className={selectAll === 'partial' ? classes.partialTaskSelectionSet : ''}
                  color="primary"
                  checked={selectAll !== 'none'}
                  onClick={handleSelectAllClick}
                />
              </TableCell>
              <TableCell align="left" className={classes.headerColumn}>
                {`Task ${filteredTasks.length ? `(${filteredTasks.length})` : ''}`}
              </TableCell>
              <TableCell align="left" className={classes.headerColumn}>
                {tableSortLabelComponent('Therapy', 'therapyName')}
              </TableCell>
              <TableCell align="left" className={classes.headerColumn}>
                {tableSortLabelComponent('Status', 'status')}
              </TableCell>
              <TableCell align="left" className={classes.headerColumn}>
                {tableSortLabelComponent('Follow-up Date', 'followUpDate')}
              </TableCell>
              <TableCell align="left" className={classes.headerColumn}>
                {tableSortLabelComponent('Needs by Date', 'needsByDate')}
              </TableCell>
              <TableCell align="left" className={classes.headerColumn} />
              <TableCell align="left" className={classes.headerNoteColumn}>
                Note
              </TableCell>
              <TableCell align="left" className={classes.headerNarrowColumn}>
                File
              </TableCell>
              <TableCell align="left" className={classes.headerNarrowColumn} />
            </TableRow>
          </TableHead>
          <TaskTableBody tasksList={sortedTasks}>
            {!tasksLoading &&
              sortedTasks.map((task: ITask, index: number) => {
                return (
                  <TasksTableRow
                    key={`${task.taskType}-${task.id}`}
                    taskKey={getTaskKey(task)}
                    index={index}
                    onSelectedRow={handleOnSelectedRow}
                    disableUpdateDates={disableUpdateDates}
                    updatedTasks={triggerRowUpdate}
                    setTriggerRowUpdate={setTriggerRowUpdate}
                    version="active"
                    clinicalDataItems={clinicalDataItems}
                    onTaskTypeClicked={() => {
                      setFilteredTaskTypes([{ label: task.taskType, value: task.taskType }]);
                      sortedTasks
                        .filter(t => task.taskType === t.taskType && task.status_id === t.status_id)
                        .map(handleOnSelectedRow);
                    }}
                  />
                );
              })}
          </TaskTableBody>
        </Table>
        {!tasksLoading && filterValuesSet && filteredTasks.length === 0 && (
          <div className={classes.messageContainer}>No Tasks Match the Selected Filters</div>
        )}
        {tasksLoading && <div className={classes.messageContainer}>Loading Task Information</div>}
      </TableContainer>
      {selectedTasks && selectedTasks.length > 0 && (
        <>
          <Button
            className={classes.showFormButton}
            variant="contained"
            color="primary"
            startIcon={showForm ? <VisibilityOff /> : <Visibility />}
            onClick={() => setShowForm(!showForm)}
          >
            {showForm ? 'Hide' : 'Show'} Selected Tasks
          </Button>
          {showForm && (
            <GroupedTaskTabs
              handleOnSelectedRow={handleOnSelectedRow}
              onSelectTaskIds={handleSelectTasksIds}
              onUnSelectTasks={handleUnSelectTasks}
            />
          )}
        </>
      )}
    </>
  ) : null;
};

export default withStyles(styles)(TasksTableActiveContainer);

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';
import { defaultTheme } from '../../../lib/themes';

const styles = (theme: Theme) => {
  const { palette, layout } = expandTheme(theme);

  const baseApplicationManagerContainer: any = {
    height: `calc(100vh - ${layout.topBarHeight}px)`,
    overflowY: 'scroll',
  };

  return createStyles({
    applicationManagerSelector: {
      ...baseApplicationManagerContainer,
      borderRight: `1px solid ${palette.primary.greySubRow}`,
    },
    applicationManagerSectionHeaderGrid: {
      'backgroundColor': palette.primary.grey2,
      'padding': `${theme.spacing(2)} ${theme.spacing(1.5)}`,
      'borderBottom': `1px solid ${defaultTheme.palette.primary.grey13}`,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    applicationManagerSectionHeaderTypography: {
      color: palette.primary.steelGrey,
      fontWeight: defaultTheme.font.weight.medium,
      fontSize: defaultTheme.font.largeFontSize,
    },
    applicationManagerItem: {
      padding: `${theme.spacing(2.5)} 0`,
      paddingLeft: theme.spacing(2.25),
    },
    applicationManagerSelectedItem: {
      backgroundColor: palette.primary.task.backgroundColor,
    },
    applicationManagerTopLevelSelectedItem: {
      backgroundColor: defaultTheme.palette.primary.grey23,
    },
    applicationManagerNonSelectedItem: {
      cursor: 'pointer',
      borderBottom: `1px solid ${palette.primary.greySubRow}`,
    },
    applicationManagerSubItem: {
      textIndent: '20px',
    },
    applicationManagerChildHeader: {
      backgroundColor: '#f5f9f9',
    },
    rightSideContainer: {
      ...baseApplicationManagerContainer,
      padding: theme.spacing(3),
    },
    expandOpen: {
      transform: 'rotate(-180deg)',
    },
  });
};

export { styles };

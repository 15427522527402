import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../nav-styles';
import NewPatientTasks from '../../../containers/patient/tasks-new/index';

const Tasks = ({ match }) => {
  const { patientId } = match.params;
  return (
    <div>
      <NewPatientTasks patientId={patientId} />
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Tasks);

import React from 'react';
import { Grid, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useDispatch } from 'react-redux';
import { ArborDate } from 'models/time/arbor-date';
import { integrateUpdatedTasks } from 'actions/action-tasks';
import { fetchNotes } from 'actions/action-notes';
import { notifySuccess } from 'actions/action-notifications';
import { styles } from '../../fill-coordination.styles';
import { FillCoordinationClient } from '../../../../../clients/fill-coordination-client';

const ResetToRequiredStateForm = ({
  tasks,
  smsPhoneNumber,
  secureFCLinkSender,
  patientId,
  emptyOrder,
  showResendSecureLinkOption,
  classes,
}: any) => {
  const dispatch = useDispatch();

  const moveToRequired = async () => {
    const response = await FillCoordinationClient.moveToRequiredState({
      tasks,
      patient_id: patientId,
    });
    dispatch(integrateUpdatedTasks(response));
  };

  const moveToRequiredAndSendSecureLink = async () => {
    const response = await FillCoordinationClient.moveToRequiredStateAndSendSecureLink({
      tasks,
      smsPhoneNumber,
      patientId,
    });
    dispatch(integrateUpdatedTasks(response));
    dispatch(fetchNotes({ patient_id: patientId }));
    dispatch(notifySuccess('Secure link was sent successfully'));
  };
  if (emptyOrder) {
    const secure_link_sent_dt = new ArborDate(
      tasks[0].secure_link_sent_dt,
      true,
    ).getCustomerDatetime(true);
    const patient_responded_dt = new ArborDate(
      tasks[0].secure_link_completed_dt,
      true,
    ).getCustomerDatetime(true);
    return (
      <>
        <Grid item xs={12} className={classes.fieldContainerWithRedText}>
          <div>
            <span className={classes.redCircleExclamationPoint}>!</span>
            The patient requested removal of all therapies from this secure link bundle. Click the
            Reset button below to return all FCs in the bundle to the required status.
          </div>
        </Grid>
        <Grid container item xs={12} className={classes.secureLinkContainer}>
          <Grid item xs={4}>
            <Grid item xs={12} className={classes.fieldContainer}>
              <div data-secure-link-id={tasks[0].secure_link_id}>
                {`Secure link sent to patient by ${secureFCLinkSender} on ${secure_link_sent_dt}.`}
              </div>
              <div>{`Patient responded on ${patient_responded_dt}`}</div>
            </Grid>
          </Grid>
          <Grid item>
            <Grid>
              <Button variant="contained" onClick={moveToRequired}>
                {`Reset task${tasks.length > 1 ? 's' : ''} to Required State`}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
  return (
    <Grid
      container
      style={{
        height: '75px',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'space-around',
      }}
    >
      {showResendSecureLinkOption ? (
        <Grid item>
          <Grid>
            <Button variant="contained" onClick={moveToRequiredAndSendSecureLink}>
              {`Reset task${
                tasks.length > 1 ? 's' : ''
              } to Required State and Send New Secure Link`}
            </Button>
          </Grid>
        </Grid>
      ) : null}
      <Grid item>
        <Grid>
          <Button variant="contained" onClick={moveToRequired}>
            {`Reset task${tasks.length > 1 ? 's' : ''} to Required State`}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ResetToRequiredStateForm);

import { Socket } from 'socket.io-client';

export enum FaGrantNotificationEnum {
  NewGrant = 'NewGrant',
  ClosedGrant = 'ClosedGrant',
}

export interface IFaGrantNotification {
  id: string;
  task_id: number;
  customer_id: number;
  patient_id: number;
  type: FaGrantNotificationEnum;
}

import React, { useState, useEffect } from 'react';
import { IState } from 'interfaces/redux/IState';
import { useSelector } from 'react-redux';
import {
  IProfileClinicalInfo,
  IPatientMergeSectionDetailsProps,
} from 'components/patient-merge-modal/interfaces';
import { ALLERGY_INACTIVE_STATUS } from 'components/patient-merge-modal/constants';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { useTheme } from '@mui/styles';
import { Checkbox, Divider, FormControlLabel, Grid, Typography } from '@mui/material';
import { addInput, IPatientMergeInput, removeInput } from 'actions/action-patient-merge';
import {
  ClinicalAllergySection,
  ClinicalProblemSection,
  ClinicalSpecialPopulationSection,
} from 'components/patient-merge-modal/select-form-view/sections/clinical-subsections';
import { PatientMergeClient } from 'clients/patient-merge';
import { LookupResourceTypes } from 'constants/enums';
import { isInputSelected } from 'components/patient-merge-modal/utils';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { isEmpty } from 'lodash';

export function ClinicalSection(props: IPatientMergeSectionDetailsProps) {
  const { mergeId, expanded } = props;
  const [infos, setInfos] = useState<IProfileClinicalInfo[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);
  const [selectedProfile, setSelectedProfile] = useState<IProfileClinicalInfo>();
  const [primaryProfile, setPrimaryProfile] = useState<IProfileClinicalInfo>();
  const primaryProfileId = useSelector((state: IState) => state.patientMerge.primary);
  const selectedProfileId = useSelector((state: IState) => state.patientMerge.duplicate);
  const inputs = useSelector((state: IState) => state.patientMerge.inputs);
  const dispatch = useAppDispatch();

  const disableCheckbox = (data: any, resourceType: LookupResourceTypes) => {
    switch (resourceType) {
      case LookupResourceTypes.Allergy:
        return data.allergyStatus === ALLERGY_INACTIVE_STATUS;
      case LookupResourceTypes.PatientProblem:
        return !data.isProblemActive;
      case LookupResourceTypes.PatientSpecialPopulation:
        return !data.isSpecialPopulationActive;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (!loaded && expanded) {
      PatientMergeClient.getMergeClinicalInfo(mergeId).then(response => {
        setInfos(response.data);
        setLoaded(true);
      });
    }
  }, [mergeId, expanded]);
  useEffect(() => {
    setPrimaryProfile(infos.find(profile => profile.patientId === primaryProfileId));
  }, [primaryProfileId, infos]);
  useEffect(() => {
    setSelectedProfile(infos.find(profile => profile.patientId === selectedProfileId));
  }, [selectedProfileId, infos]);

  const selectValue = (value: boolean, type: LookupResourceTypes, selectedId: number) => {
    const input: IPatientMergeInput = {
      type,
      selectedId,
    };
    dispatch(value ? addInput(input) : removeInput(input));
  };
  useEffect(() => {
    selectedProfile?.allergies
      .filter(dataValue => disableCheckbox(dataValue, LookupResourceTypes.Allergy) === false)
      .map(x => selectValue(selectAll, LookupResourceTypes.Allergy, x.allergyId));
    selectedProfile?.problems
      .filter(dataValue => disableCheckbox(dataValue, LookupResourceTypes.PatientProblem) === false)
      .map(x => selectValue(selectAll, LookupResourceTypes.PatientProblem, x.problemId));
    selectedProfile?.specialPopulations
      .filter(
        dataValue =>
          disableCheckbox(dataValue, LookupResourceTypes.PatientSpecialPopulation) === false,
      )
      .map(x =>
        selectValue(selectAll, LookupResourceTypes.PatientSpecialPopulation, x.specialPopulationId),
      );
  }, [selectAll]);

  const handleCheckbox =
    (type: LookupResourceTypes, selectedId: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      selectValue(e.target.checked, type, selectedId);
    };

  const disableSelectAll =
    isEmpty(
      selectedProfile?.allergies.filter(
        dataValue => disableCheckbox(dataValue, LookupResourceTypes.Allergy) === false,
      ),
    ) &&
    isEmpty(
      selectedProfile?.problems.filter(
        dataValue => disableCheckbox(dataValue, LookupResourceTypes.PatientProblem) === false,
      ),
    ) &&
    isEmpty(
      selectedProfile?.specialPopulations.filter(
        dataValue =>
          disableCheckbox(dataValue, LookupResourceTypes.PatientSpecialPopulation) === false,
      ),
    );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControlLabel
          sx={classes.checkboxFormControl}
          label="Select all"
          disabled={disableSelectAll}
          control={
            <Checkbox
              sx={classes.checkbox}
              onChange={event => setSelectAll(event.target.checked)}
              checked={selectAll}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={classes.sectionInputTitle}>Allergies</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid container item xs={6}>
          {selectedProfile?.allergies ? (
            selectedProfile?.allergies.map((allergy, i) => (
              <Grid key={`selected-${allergy.allergyName}-${i}`} item xs={12}>
                <FormControlLabel
                  sx={classes.checkboxFormControl}
                  label={<ClinicalAllergySection {...allergy} />}
                  control={
                    <Checkbox
                      disabled={disableCheckbox(allergy, LookupResourceTypes.Allergy)}
                      onChange={handleCheckbox(LookupResourceTypes.Allergy, allergy.allergyId)}
                      checked={isInputSelected(
                        allergy.allergyId,
                        LookupResourceTypes.Allergy,
                        inputs,
                      )}
                      sx={classes.checkbox}
                    />
                  }
                />
              </Grid>
            ))
          ) : (
            <Typography>-</Typography>
          )}
        </Grid>
        <Grid container item xs={6}>
          {primaryProfile?.allergies ? (
            primaryProfile?.allergies.map((allergy, i) => (
              <Grid key={`primary-${allergy.allergyName}-${i}`} item xs={12}>
                <ClinicalAllergySection {...allergy} />
              </Grid>
            ))
          ) : (
            <Typography>-</Typography>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={classes.divider} light />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={classes.sectionInputTitle}>Problem List</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid container item xs={6}>
          {selectedProfile?.problems ? (
            selectedProfile?.problems.map((problem, i) => (
              <Grid key={`selected-${problem.problemName}-${i}`} item xs={12}>
                <FormControlLabel
                  sx={classes.checkboxFormControl}
                  label={<ClinicalProblemSection {...problem} />}
                  control={
                    <Checkbox
                      disabled={disableCheckbox(problem, LookupResourceTypes.PatientProblem)}
                      onChange={handleCheckbox(
                        LookupResourceTypes.PatientProblem,
                        problem.problemId,
                      )}
                      checked={isInputSelected(
                        problem.problemId,
                        LookupResourceTypes.PatientProblem,
                        inputs,
                      )}
                      sx={classes.checkbox}
                    />
                  }
                />
              </Grid>
            ))
          ) : (
            <Typography>-</Typography>
          )}
        </Grid>
        <Grid container item xs={6}>
          {primaryProfile?.problems ? (
            primaryProfile?.problems.map((problem, i) => (
              <Grid key={`primary-${problem.problemName}-${i}`} item xs={12}>
                <ClinicalProblemSection {...problem} />
              </Grid>
            ))
          ) : (
            <Typography>-</Typography>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={classes.divider} light />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={classes.sectionInputTitle}>Special Population</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid container item xs={6}>
          {selectedProfile?.specialPopulations ? (
            selectedProfile.specialPopulations.map(specialPopulation => (
              <Grid key={`selected-${specialPopulation.specialPopulationId}`} item xs={12}>
                <FormControlLabel
                  sx={classes.checkboxFormControl}
                  disabled={disableCheckbox(
                    specialPopulation,
                    LookupResourceTypes.PatientSpecialPopulation,
                  )}
                  label={<ClinicalSpecialPopulationSection {...specialPopulation} />}
                  control={
                    <Checkbox
                      onChange={handleCheckbox(
                        LookupResourceTypes.PatientSpecialPopulation,
                        specialPopulation.specialPopulationId,
                      )}
                      checked={isInputSelected(
                        specialPopulation.specialPopulationId,
                        LookupResourceTypes.PatientSpecialPopulation,
                        inputs,
                      )}
                      sx={classes.checkbox}
                    />
                  }
                />
              </Grid>
            ))
          ) : (
            <Typography>-</Typography>
          )}
        </Grid>
        <Grid container item xs={6}>
          {primaryProfile?.specialPopulations ? (
            primaryProfile.specialPopulations.map(specialPopulation => (
              <Grid key={`primary-${specialPopulation.specialPopulationId}`} item xs={12}>
                <ClinicalSpecialPopulationSection {...specialPopulation} />
              </Grid>
            ))
          ) : (
            <Typography>-</Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

import React from 'react';
import { renderReactRadioGroup as RenderReactRadioGroup } from 'components/form/field/redux-field';
import useProvider from 'hooks/useProvider';
import { valueLabelFormatter } from '../formatters';

export default ({ providers, field, label, input, meta, disabled, labelSize, qaId }) => {
  const fields = useProvider(providers, field.provider, [], valueLabelFormatter);

  return (
    <RenderReactRadioGroup
      label={label}
      input={input}
      meta={meta}
      disabled={disabled}
      labelSize={labelSize}
      qaId={qaId}
      radioMap={fields}
    />
  );
};

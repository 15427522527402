import {
  approvePendingAccreditation,
  downloadAccreditationDocument,
  fetchAccreditationsStatus,
  fetchPendingAccreditation,
  rejectPendingAccreditation,
  removeAccreditationDocument,
  savePendingAccreditation,
  submitAccreditation,
  uploadAccreditationDocument,
} from 'actions/action-accreditations';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { UserPermissions } from 'interfaces/user/Permissions';
import type { AxiosResponse } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UserUtils } from 'utils/user-utils';
import { DownloadFileUtils } from 'utils/download-file-utils';
import {
  AnswerValue,
  DocumentAnswerValue,
  OnDownloadDocument,
  OnRemoveDocument,
  OnUploadDocument,
  QuestionnaireInstance,
  QuestionnaireQuestions,
  QuestionnaireStatus,
  RejectionMessage,
  UploadDocumentBody,
} from './types';
import { parseAnswerResponse } from './utils';

export const FETCH_ERROR_MESSAGE = 'There was a problem loading accreditation questionnaires.';
export const SAVE_ERROR_MESSAGE = 'There was a problem saving the questionnaire.';
export const SAVE_SUCCESS_MESSAGE = 'Questionnaire saved.';
export const SUBMIT_ERROR_MESSAGE = 'There was a problem submitting the questionnaire.';
export const SUBMIT_SUCCESS_MESSAGE = 'Questionnaire submitted.';
export const STATUS_ERROR_MESSAGE = 'There was a problem updating the questionnaire status.';
export const STATUS_SUCCESS_MESSAGE = 'Questionnaire status updated successfully.';
export const DOCUMENT_UPLOAD_SUCCESS_MESSAGE = 'Document successfully uploaded.';
export const DOCUMENT_UPLOAD_ERROR_MESSAGE = 'Could not upload document.';
export const DOCUMENT_REMOVE_ERROR_MESSAGE = 'Could not remove document.';
export const DOCUMENT_DOWNLOAD_ERROR_MESSAGE = 'Unable to download document';

interface UseQuestionnaireArgs<A extends QuestionnaireQuestions> {
  questionnaire: QuestionnaireInstance;
  questionnaireDefinition: A;
  validate: (answers: A) => boolean;
  beforeUpdateAnswers?: (answers: A, key: keyof A, value: AnswerValue) => A;
}

const getApproverPermission = (): boolean =>
  UserUtils.userIsPermitted(UserPermissions.AccreditationApprover) || UserUtils.userIsSuperUser();
const getEditPermission = (): boolean =>
  UserUtils.userHasResourcePermission('accreditation_questionnaire', 'create') ||
  UserUtils.userIsSuperUser();

export function useQuestionnaireUser() {
  const [isApprover, setApprover] = useState(false);
  const [canEdit, setEdit] = useState(false);
  const isUserReady = useTypedSelector(state => state.auth.currentUser.selected);

  useEffect(() => {
    if (isUserReady) {
      setApprover(getApproverPermission());
      setEdit(getEditPermission());
    }
  }, [isUserReady, setApprover, setEdit]);

  return {
    isApprover,
    canEdit,
  };
}

export function useQuestionnaireStatus() {
  const dispatch = useDispatch();
  const { isApprover, canEdit } = useQuestionnaireUser();
  const status = useTypedSelector(state => state.accreditation);
  const customerId = useTypedSelector(state => state.filters.selectedCustomerId);

  useEffect(() => {
    if ((canEdit || isApprover) && !status.isLoaded) {
      dispatch(fetchAccreditationsStatus(customerId));
    }
  }, [isApprover, canEdit, customerId, status, dispatch]);

  return status;
}

/**
 * Tracks accreditation questionnaire answers and provides functions for interacting with a single questionnaire
 */
export function useQuestionnaireState<A extends QuestionnaireQuestions>({
  questionnaire,
  questionnaireDefinition,
  validate,
  beforeUpdateAnswers = (nextAnswers, _key, _value) => nextAnswers,
}: UseQuestionnaireArgs<A>) {
  const dispatch = useDispatch();
  const customerId = useTypedSelector(state => state.filters.selectedCustomerId);
  const [loading, setLoading] = React.useState<string | boolean>(false);
  const [answers, setAnswers] = React.useState<A>(questionnaireDefinition);
  const [rejection, setRejection] = React.useState<RejectionMessage | null>(null);
  const isValid = React.useMemo(() => validate(answers), [answers, validate]);
  const { isApprover, canEdit } = useQuestionnaireUser();
  const isEditable =
    canEdit &&
    (['not_started', 'in_progress', 'rejected'] as QuestionnaireStatus[]).includes(
      questionnaire.status,
    );

  const questionnaireId = React.useMemo(
    () => questionnaire.questionnaire_instance_id,
    [questionnaire],
  );
  const fetchList = React.useCallback(
    async (showLoading = true) => {
      if (showLoading) setLoading('answers');
      try {
        const res = (await fetchPendingAccreditation(
          questionnaireId,
          customerId,
        )) as AxiosResponse<{
          answers: A;
          rejection?: RejectionMessage;
        }>;
        if (res?.data?.answers && Object.keys(res.data.answers).length) {
          const answerResults = parseAnswerResponse(res.data.answers, questionnaireDefinition);
          setAnswers(() => answerResults as A);
        }
        if (questionnaire.status === 'rejected' && res?.data?.rejection) {
          setRejection(res.data.rejection);
        } else {
          setRejection(null);
        }
      } catch (err) {
        console.error(err);
        dispatch(notifyError(FETCH_ERROR_MESSAGE));
      }
      setLoading(false);
    },
    [
      dispatch,
      setLoading,
      setAnswers,
      setRejection,
      questionnaireId,
      customerId,
      questionnaireDefinition,
      questionnaire,
    ],
  );

  const save = React.useCallback(async () => {
    if (loading) return;
    setLoading('save');
    try {
      await savePendingAccreditation(questionnaireId, answers, customerId);
      await fetchList(false);
      dispatch(notifySuccess(SAVE_SUCCESS_MESSAGE));
      dispatch(fetchAccreditationsStatus(customerId));
    } catch (err) {
      console.error(err);
      dispatch(notifyError(SAVE_ERROR_MESSAGE));
    }
    setLoading(false);
  }, [loading, questionnaireId, answers, customerId, fetchList, dispatch]);

  const submit = React.useCallback(async () => {
    if (loading) return;
    setLoading('submit');
    try {
      await submitAccreditation(questionnaireId, answers, customerId);
      await fetchList(false);
      dispatch(notifySuccess(SUBMIT_SUCCESS_MESSAGE));
      dispatch(fetchAccreditationsStatus(customerId));
    } catch (err) {
      console.error(err);
      dispatch(notifyError(SUBMIT_ERROR_MESSAGE));
    }
    setLoading(false);
  }, [loading, questionnaireId, answers, customerId, fetchList, dispatch]);

  const approve = React.useCallback(async () => {
    if (loading) return;
    setLoading('approve');
    try {
      await approvePendingAccreditation(questionnaireId, customerId);
      dispatch(notifySuccess(STATUS_SUCCESS_MESSAGE));
      dispatch(fetchAccreditationsStatus(customerId));
    } catch (err) {
      console.error(err);
      dispatch(notifyError(STATUS_ERROR_MESSAGE));
    }
    setLoading(false);
  }, [loading, questionnaireId, customerId, dispatch]);

  const reject = React.useCallback(
    async (rejectionMessage?: string) => {
      if (loading) return;
      if (!rejectionMessage) return;
      setLoading('reject');
      try {
        await rejectPendingAccreditation(questionnaireId, customerId, rejectionMessage);
        dispatch(notifySuccess(STATUS_SUCCESS_MESSAGE));
        dispatch(fetchAccreditationsStatus(customerId));
      } catch (err) {
        console.error(err);
        dispatch(notifyError(STATUS_ERROR_MESSAGE));
      }
      setLoading(false);
    },
    [loading, questionnaireId, customerId, dispatch],
  );

  const updateAnswers = React.useCallback(
    (answerKey: keyof A, value: AnswerValue) => {
      setAnswers(previousAnswers => {
        const nextAnswers = {
          ...previousAnswers,
          [answerKey]: {
            ...previousAnswers[answerKey],
            value,
          },
        };
        return beforeUpdateAnswers(nextAnswers, answerKey, value);
      });
    },
    [beforeUpdateAnswers],
  );

  const onUploadDocument: OnUploadDocument = React.useCallback(
    async (bodyPayload: UploadDocumentBody) => {
      const payload: UploadDocumentBody = {
        ...bodyPayload,
        question: answers[bodyPayload.answerKey].question,
      };
      try {
        const res = await uploadAccreditationDocument(questionnaireId, customerId, payload);
        if (res.data) {
          updateAnswers(res.data.answerKey, res.data.value);
          dispatch(notifySuccess(DOCUMENT_UPLOAD_SUCCESS_MESSAGE));
        }
      } catch (err) {
        console.error(err);
        dispatch(notifyError(DOCUMENT_UPLOAD_ERROR_MESSAGE));
      }
    },
    [answers, questionnaireId, customerId, updateAnswers, dispatch],
  );

  const onRemoveDocument: OnRemoveDocument = React.useCallback(
    async (docUuid: string) => {
      try {
        const res = await removeAccreditationDocument(questionnaireId, customerId, docUuid);
        if (res.data) {
          const answerKey = res.data.key_name;
          const originalFilename =
            (questionnaireDefinition[answerKey]?.value as DocumentAnswerValue)?.filename ?? null;
          updateAnswers(answerKey, {
            note: res.data.doc_note,
            uuid: res.data.doc_uuid,
            isUploaded: res.data.is_uploaded === 1,
            filename: res.data.doc_filename ?? originalFilename,
          });
        }
      } catch (err) {
        console.error(err);
        dispatch(notifyError(DOCUMENT_REMOVE_ERROR_MESSAGE));
      }
    },
    [customerId, dispatch, questionnaireDefinition, questionnaireId, updateAnswers],
  );

  const onDownloadDocument: OnDownloadDocument = React.useCallback(
    async (docUuid: string) => {
      try {
        const res = await downloadAccreditationDocument(questionnaireId, customerId, docUuid);
        const { url, filename } = res.data;
        DownloadFileUtils.downloadUrl(url, filename);
      } catch (err) {
        console.error(err);
        dispatch(notifyError(DOCUMENT_DOWNLOAD_ERROR_MESSAGE));
      }
    },
    [customerId, dispatch, questionnaireId],
  );

  /**
   * Fetch the questionnaire answers on initial load
   */
  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    answers,
    updateAnswers,
    loading,
    isValid,
    isApprover,
    canEdit,
    isEditable,
    fetchList,
    save,
    submit,
    approve,
    reject,
    rejection,
    qProps: {
      answers,
      updateAnswers,
      readOnly: !isEditable,
      required: true,
      onUploadDocument,
      onRemoveDocument,
      onDownloadDocument,
    },
    footerProps: {
      loading,
      isValid,
      isApprover,
      canEdit,
      isEditable,
      fetchList,
      save,
      submit,
      approve,
      reject,
      rejection,
      status: questionnaire.status,
      name: questionnaire.name,
    },
  };
}

import { Grid, Typography } from '@mui/material';
import { convertToArborDate } from 'models/time/arbor-date';
import React from 'react';
import { ISpecialPopulation } from 'components/patient-merge-modal/interfaces';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { useTheme } from '@mui/styles';

const ClinicalSpecialPopulationSection = ({
  specialPopulationName,
  specialPopulationStartDate,
  specialPopulationEndDate,
  isSpecialPopulationActive,
}: ISpecialPopulation) => {
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);

  const specialPopulationStartDateFormatted = specialPopulationStartDate
    ? convertToArborDate(specialPopulationStartDate).getUtcDate(true)
    : 'Unknown';
  const specialPopulationEndDateFormatted = specialPopulationEndDate
    ? convertToArborDate(specialPopulationEndDate).getUtcDate(true)
    : 'Present';
  const isSpecialPopulationActiveFormatted = isSpecialPopulationActive ? 'Active' : 'Inactive';

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <Typography sx={classes.checkboxTitle}>
          {`${specialPopulationName} (${specialPopulationStartDateFormatted} - ${specialPopulationEndDateFormatted}) - ${isSpecialPopulationActiveFormatted})`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ClinicalSpecialPopulationSection;

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

const styles = (theme: Theme) => {
  const { palette, font } = expandTheme(theme);

  return createStyles({
    table: {
      borderRadius: theme.spacing(0.5),
      minWidth: theme.spacing(80),
      border: `1px solid ${palette.primary.grey25}`,
    },
    diagnosisCell: {
      fontWeight: 'bold',
    },
    diagnosisCellSpeciality: {
      fontWeight: 'bold',
      color: palette.primary.bismark,
    },
    specialtyTag: {
      'borderRadius': 5,
      'color': palette.primary.white,
      'padding': theme.spacing(0, 0.5),
      'marginLeft': 5,
      'height': theme.spacing(2.75),
      'backgroundColor': palette.primary.success,
      'fontWeight': font.weight.thick,
      '&.CDM': {
        backgroundColor: palette.primary.yellow,
        color: palette.primary.deepBlue,
      },
    },
    diagnosisOrderTag: {
      display: 'flex',
      borderRadius: 5,
      color: palette.primary.white,
      padding: theme.spacing(0, 0.5),
      marginLeft: 5,
      height: theme.spacing(2.75),
      backgroundColor: palette.primary.deepBlue,
      fontWeight: 'bold',
      fontSize: 'smaller',
      alignItems: 'center',
    },
    trellisIconDisplayFlex: {
      display: 'flex',
      paddingLeft: '5px',
    },
    diagnosisContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
    },
    emptyMessageContainer: {
      backgroundColor: palette.primary.grey14,
      padding: theme.spacing(4),
      marginTop: theme.spacing(2),
    },
    emptyMessage: {
      color: palette.primary.darkGrey,
    },
    narrowColumn: {
      width: theme.spacing(1),
    },
    collapsableCell: {
      paddingBottom: 0,
      paddingTop: 0,
    },
    collapsableContent: {
      padding: theme.spacing(2),
    },
    linkedTherapiesTitle: {
      fontWeight: 'bold',
      fontSize: font.mediumFontSize,
    },
    patientGoalsTitle: {
      fontWeight: 'bold',
      fontSize: font.mediumFontSize,
      paddingBottom: theme.spacing(3),
    },
    patientGoalsDateTitle: {
      fontSize: theme.spacing(1.375),
      color: palette.primary.rollingStone,
    },
    patientGoalsDate: {
      fontSize: theme.spacing(1.5),
    },
    patientGoals: {
      fontSize: theme.spacing(1.75),
      padding: '0px',
    },
    therapyDrugName: {
      marginLeft: theme.spacing(1.2),
    },
    therapyLinkIndicator: {
      '&:disabled': {
        color: palette.primary.main,
        width: '100%',
      },
    },
    programNameLabel: {
      fontSize: font.mediumFontSize,
      marginBottom: theme.spacing(3.8),
      fontWeight: 'bold',
    },
    graduationWrapper: {
      paddingTop: theme.spacing(6.3),
    },
  });
};

export { styles };

import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { updateMedication } from 'actions/action-medications';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { renderCalendarPicker } from 'components/form/datepicker/datetime-picker';
import AutocompleteLabel from 'components/form/field/autocomplete-label';
import { renderDropdown } from 'components/form/field/redux-field';
import AutocompleteMinigrid from 'components/form/field/render-minigrid-autocomplete';
import { required } from 'components/form/validation/validation';
import { MEDICATION_FORM } from 'constants/index';
import { convertToArborDate } from 'models/time/arbor-date';
import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { fetchGpis, specialtyTypeFormatter } from 'services/utils/therapy-service';
import { windowFeatureIsEnabled } from 'config/window-features';
import { styles } from '../patient-clinical-styles';

const MedicationForm = props => {
  const {
    submitCallback,
    handleSubmit,
    medication,
    fieldName,
    isGroup,
    updateMedication, //eslint-disable-line
    pristine,
    submitting,
    classes,
    medicationCategoryCodes,
    selectedMedication,
    withConfirmation,
  } = props;

  const onSubmit = values => {
    const payload = {};
    if (fieldName === 'start_dt' || fieldName === 'end_dt') {
      payload[fieldName] = convertToArborDate(values.medication_field).getUtcDate();
    } else if (fieldName === 'ndc') {
      payload[fieldName] = values.therapy ? values.therapy.ndc : medication.ndc;
    } else {
      payload[fieldName] = values.medication_field;
    }
    const commonPayload = {
      patient_id: medication.patient_id,
      therapy_id: medication.therapy ? medication.therapy.id : null,
    };

    if (isGroup) {
      // This should be the only path, since there is no way to update
      const medicationPayload = {
        id: medication.id,
        ...commonPayload,
        ...payload,
      };
      updateMedication(medicationPayload);
    }
    if (submitCallback) {
      submitCallback();
    }
  };

  const handleFetchGpisOptions = searchText =>
    // eslint-disable-next-line no-confusing-arrow
    fetchGpis(searchText).then(result =>
      result.data
        ? result.data.drugs.map(therapy => ({
            ...therapy,
            id: therapy.ndc,
            label: therapy.drug_info,
          }))
        : [],
    );

  return (
    // eslint-disable-next-line
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      {fieldName === 'ndc' && (
        <Grid container>
          <Grid item xs={12}>
            <Field
              name="therapy"
              label="Medication *"
              component={AutocompleteMinigrid}
              validate={[required]}
              fetchOptions={handleFetchGpisOptions}
              hint="Search by Medication or GPI or NDC"
              columnsToShow={{
                gpi: 'GPI',
                drug_name: 'Drug Name',
                dosage_form: 'Form',
                dose: 'Dose',
                ldd: 'LDD',
                specialty_type: 'Specialty Type',
                ndc: 'NDC',
              }}
              minSearchLength={2}
              valueFormatter={specialtyTypeFormatter}
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteLabel idValue={selectedMedication.ndc} defaultIdValue="" label="NDC" />
          </Grid>
        </Grid>
      )}
      {(fieldName === 'start_dt' || fieldName === 'end_dt') && (
        <Field
          name="medication_field"
          width="100%"
          component={renderCalendarPicker}
          placeholder="mm/dd/yyyy"
        />
      )}
      {fieldName === 'category_code' && (
        <Field
          name="medication_field"
          noDropdownIndicator
          width="100%"
          component={renderDropdown}
          fields={medicationCategoryCodes.map(category => ({
            label: category.category,
            value: category.code,
          }))}
        />
      )}
      {withConfirmation && (
        <ConfirmationPanel
          className={classes.medFormConfirmation}
          cancelButtonName="edit_medication_cancel_button"
          submitButtonName="edit_medication_submit_button"
          handleCancel={submitCallback}
          justifyContent="space-between"
          disableSubmit={pristine || submitting}
        />
      )}
    </form>
  );
};

function mapStateToProps(state, props) {
  const { initialValue } = props;
  const selector = formValueSelector(MEDICATION_FORM);
  const selectedMedication = selector(state, 'therapy') || {};
  return {
    initialValues: {
      medication_field: initialValue,
    },
    selectedMedication,
    medicationCategoryCodes: state.lookups.medicationCategoryCodes,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    updateMedication,
  }),
)(reduxForm({ form: MEDICATION_FORM })(MedicationForm));

import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Popover, Box, Button, IconButton } from '@mui/material';

import { ITask } from 'interfaces/redux/ITasks';
import { ControlledDatePickerCalendar } from 'components/react-hook-form-fields/date-picker/controlled-date-picker';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { clearSelectedTasks, editTasks, updateTasksProperties } from 'actions/action-tasks';
import { convertToArborDate } from 'models/time/arbor-date';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'interfaces/redux/IState';
import moment, { Moment } from 'moment';
import { CalendarToday } from '@mui/icons-material';
import { FC, RS } from '../../../../constants';

interface TaskDateFormProps {
  disabled?: boolean;
  title?: string;
  tasks?: ITask[];
  iconButtonType?: boolean;
  titleOverride?: string;
}

export const FollowUpDateDialogForm = React.forwardRef(
  ({ tasks, ...props }: TaskDateFormProps, ref: any): JSX.Element => {
    const [openFud, setOpenFud] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
    const handleClick = (e: any) => {
      setAnchorEl(e.currentTarget);
      setOpenFud(true);
    };
    const {
      handleSubmit,
      control,
      formState: { isValid },
    } = useForm<any>({ mode: 'onChange' });

    const dispatch = useDispatch();
    const patientId = useSelector((state: IState) => state.patient.id);

    const initialFollowUpDate =
      tasks && tasks.length && tasks[0].followup_dt ? moment.utc(tasks[0].followup_dt) : null;

    const onButtonClick = (e: any) => {
      setAnchorEl(e.currentTarget);
      setOpenFud(true);
    };

    interface IFormProps {
      followup_dt?: Moment;
    }

    const onSubmit: SubmitHandler<IFormProps> = async data => {
      const updatedTasks = tasks?.map(task => {
        const fud = convertToArborDate(data.followup_dt).getUtcDatetime();
        const updatedTask = {
          id: task.id,
          taskType: task.taskType,
          therapy_id: task.therapy_id,
          ...(task.taskType === FC ? {} : { status_id: task.status_id }),
          ...(task.taskType === FC ? { method: task.method } : {}),
          ...(task.taskType === RS ? { followupDt: fud } : { followup_dt: fud }),
        };

        return updatedTask;
      });

      dispatch(editTasks(patientId, updatedTasks));
      if (data.followup_dt) {
        const followupDt = convertToArborDate(
          moment.utc(data.followup_dt.format('YYYY-MM-DD'), 'YYYY-MM-DD'),
          true,
        ).getUtcDatetime();

        dispatch(
          updateTasksProperties(
            updatedTasks?.map(task => ({
              taskId: task.id,
              taskType: task.taskType,
              status_id: task.status_id,
              followup_dt: followupDt,
            })),
          ),
        );
        setOpenFud(false);
      }

      dispatch(clearSelectedTasks());
    };

    return (
      <>
        {props.iconButtonType ? (
          <IconButton onClick={onButtonClick} disabled={props.disabled} size="small">
            <CalendarToday fontSize="small" />
          </IconButton>
        ) : (
          <Button
            onClick={onButtonClick}
            variant="contained"
            color="primary"
            startIcon={<CalendarToday />}
            disabled={props.disabled}
          >
            Edit FUD
          </Button>
        )}

        <Popover
          ref={ref}
          id="followup-popover"
          open={openFud}
          anchorEl={anchorEl}
          onClose={() => setOpenFud(false)}
          onClick={e => {
            e.stopPropagation();
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box p={2} display="flex" flexDirection="column" alignItems="center">
            <h3>{props.titleOverride || 'Select Follow up Date'}</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ControlledDatePickerCalendar
                defaultValue={initialFollowUpDate}
                name="followup_dt"
                control={control}
                validations={{ required: true }}
              />
              <ConfirmationPanel
                cancelButtonName="edit_therapy_cancel_button"
                submitButtonName="edit_therapy_submit_button"
                cancelButtonText="Cancel"
                submitButtonText="Save"
                disableSubmit={!isValid}
                handleCancel={() => setOpenFud(false)}
              />
            </form>
          </Box>
        </Popover>
      </>
    );
  },
);

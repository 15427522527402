import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';
import { ModalUtil } from 'services/utils/modal.util';

export const DayAreaShippingStyles = (theme: Theme) => {
  const { palette } = expandTheme(theme);
  return createStyles({
    addButton: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      backgroundColor: palette.primary.green,
      color: palette.primary.white,
      textTransform: 'none',
    },
    modalContentWrapper: {
      position: 'absolute',
      width: theme.spacing(150),
      backgroundColor: palette.primary.background,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      borderRadius: theme.spacing(0.6),
      overflow: 'auto',
      maxHeight: '95vh',
    },
    snackbar: {
      backgroundColor: palette.primary.grey2,
      borderRadius: 0,
      boxShadow: 'none',
      color: theme.palette.text.primary,
      fontSize: theme.typography.fontSize * 1.1,
    },
    modal: {
      ...ModalUtil.BasicModalStyling(theme, 80, 3),
      width: theme.spacing(80),
    },
    modalLabelSelect: {
      marginBottom: '10px',
      fontSize: '11px',
    },
    modalContainerButton: {
      marginTop: '50px',
    },
    zipcodeInput: {
      'minWidth': '120px',
      'flex': '1',
      '& input[type=number]': {
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          display: 'none',
        },
        'appearance': 'textfield',
      },
    },
  });
};

export const styles = theme => ({
  verificationBtnContainer: {
    textAlign: 'right',
  },
  verificationBtn: {
    'backgroundColor': theme.palette.verification.verificationBtn,
    'color': theme.palette.primary.white,
    'marginLeft': 5,
    '&[disabled]': {
      backgroundColor: theme.palette.primary.grey12,
      color: theme.palette.primary.grey3,
    },
  },
  emrVerifiedContainer: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.verification.emrVerifiedContainer,
  },

  verifyContainer: {
    backgroundColor: theme.palette.verification.verifyContainer,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1.5),
  },
  nonVerifyContainer: {
    backgroundColor: theme.palette.verification.nonVerifyContainer,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1.5),
  },
  nonTouchedWording: {
    fontSize: theme.font.largeFontSize,
    lineHeight: 2.2,
    fontWeight: 'bold',
  },
  nonVerifyWording: {
    fontSize: theme.font.largeFontSize,
    lineHeight: 2.2,
    color: theme.palette.verification.nonVerifyWording,
    fontWeight: 'bold',
  },
  verifyWording: {
    fontSize: theme.font.largeFontSize,
    lineHeight: 2.2,
    color: theme.palette.verification.verifyWording,
    fontWeight: 'bold',
  },
  emrVerifiedWording: {
    fontSize: theme.font.largeFontSize,
    lineHeight: 2.2,
    color: theme.palette.primary.darkGrey,
    fontWeight: 'bold',
    display: 'inline',
    marginLeft: theme.spacing(1),
  },
  emrVerifiedIcon: {
    color: theme.palette.verification.emrVerifiedIcon,
    display: 'inline',
    verticalAlign: 'sub',
  },
});

import { FETCH_WORK_LIST_SEARCH_REQUEST } from 'constants/index';

interface IAction {
  type: string;
  payload: { loadedTask: string };
}

export function clearPatientListAndUpdateHeader(type: string): IAction {
  return {
    type: FETCH_WORK_LIST_SEARCH_REQUEST,
    payload: { loadedTask: type },
  };
}

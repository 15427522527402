// @ts-nocheck
import * as React from 'react';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

export const MedicationSwitch: React.FC<{
  checked: boolean;
  disabled: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = styled(props => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
  ({ theme }) => ({
    'width': 42,
    'height': 26,
    'padding': 0,
    '& .MuiSwitch-switchBase': {
      'padding': 0,
      'margin': 2,
      'transitionDuration': '300ms',
      '&.Mui-checked': {
        'transform': 'translateX(16px)',
        'color': '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.primary.cuttySark,
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: 'red',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.primary.valenciaRed,
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 400,
      }),
    },
  }),
);

import React from 'react';
import { Button, Typography } from '@mui/material';

function QuestionnaireNotFound(props: any) {
  const { cancel, typeIdentifier } = props;
  console.log('error: attempted to load questionnaire of type: ', typeIdentifier);
  return (
    <div
      style={{
        height: '5%',
        width: '40%',
        background: 'white',
        margin: 'auto',
        marginTop: '8%',
        padding: '25px',
      }}
    >
      <Typography>Questionnaire not found</Typography>
      <div>
        <div>
          <Button variant="contained" onClick={cancel}>
            Close
          </Button>
        </div>
      </div>
    </div>
  );
}

export default QuestionnaireNotFound;

import React, { useState, useMemo, useCallback, useEffect } from 'react';
import AntTreeSelect from 'antd/lib/tree-select';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Validation from '../validation/validation';
// import Suggestions from './tree-select-suggestions';

// TODO: Remove antd

const { SHOW_CHILD } = AntTreeSelect;

export const styles = theme => ({
  select: {
    'margin-top': 12,
  },
  title: {
    'font-size': theme.typography.pxToRem(13),
  },
});

// const mapDataType = (dataTypes, data) =>
//   dataTypes.map((dataType) => ({
//     ...dataType,
//     dataType: data
//       .find((item) => item.value === dataType.categoryId.toString())
//       .children.find(
//         (child) =>
//           child.value === `${dataType.categoryId}-${dataType.dataTypeId}`,
//       ),
//   }));

function isFoundOption(input, option) {
  const isIncluded = option.props.title.toLowerCase().includes(input.toLowerCase());
  const isRootIncluded = (option.props.parentTitle || '')
    .toLowerCase()
    .includes(input.toLowerCase());
  return isIncluded || isRootIncluded;
}

const TreeSelect = ({
  optionFilterProp,
  filterTreeNode,
  treeData,
  showCheckedStrategy,
  searchPlaceholder,
  autoClearSearchValue = false,
  style,
  classes,
  multi,
  onChange,
  onBlur,
  label,
  treeDefaultExpandAll,
  value: initialValue,
  watchValue = false,
  meta: { touched, error, warning },
  dropdownStyle,
  // isSuggestedOptions,
  // suggestedDataTypes,
  // suggestionLabel,
}) => {
  const multiValue = initialValue || [];
  const singleValue = initialValue || '';
  const [value, setValue] = useState(multi ? multiValue : singleValue);

  const fillValues = useMemo(
    () => (multi ? treeData : treeData.map(el => ({ ...el, selectable: multi }))),
    [treeData, multi],
  );

  const handleChange = useCallback(
    newValue => {
      setValue(newValue);
      onChange(newValue);
    },
    [setValue, onChange],
  );

  useEffect(() => {
    if (watchValue && value && initialValue && value !== initialValue) {
      setValue(initialValue);
      onChange(initialValue);
    }
  }, [watchValue, value, initialValue]);

  if (fillValues.length === 0) {
    return 'Loading...';
  }

  // const suggestions = mapDataType(suggestedDataTypes, treeData)
  //   .map((suggestion) => ({
  //     ...suggestion,
  //     id: `${suggestion.categoryId}-${suggestion.dataTypeId}`,
  //     isSelected: value.includes(
  //       `${suggestion.categoryId}-${suggestion.dataTypeId}`,
  //     ),
  //   }))
  //   .filter((suggestion) => !suggestion.isSelected);

  return (
    <div>
      <Typography color="textSecondary" variant="caption" classes={{ root: classes.title }}>
        {label}
      </Typography>

      <div className={classes.select}>
        <AntTreeSelect
          treeData={fillValues}
          value={value}
          onBlur={onBlur}
          onChange={handleChange}
          treeCheckable={multi}
          optionFilterProp={optionFilterProp}
          filterTreeNode={filterTreeNode}
          multiple={multi}
          showCheckedStrategy={showCheckedStrategy}
          showSearch
          searchPlaceholder={searchPlaceholder}
          treeDefaultExpandAll={treeDefaultExpandAll}
          style={style}
          autoClearSearchValue={autoClearSearchValue}
          dropdownStyle={dropdownStyle}
        />
        <Validation touched={touched} error={error} warning={warning} />
      </div>
      {/* <div className={classes.suggestionSelect}>
        {isSuggestedOptions && (
          <Suggestions
            onChange={(option) => {
              const newValue = option.isSelected
                ? value.filter((select) => select !== option.id)
                : value.concat(option.id);

              setValue(multi ? newValue : option.id);
              onChange(multi ? newValue : option.id);
            }}
            selected={value}
            label={suggestionLabel}
            enableAddAll={multi}
            enableIcons={multi}
            options={suggestions}
            onAddAll={() => {
              const addAllSelected = suggestions
                .map((option) => `${option.categoryId}-${option.dataTypeId}`)
                .concat(value);
              const newSelection = [...new Set(addAllSelected)];
              setValue(newSelection);
              onChange(newSelection);
            }}
          />
        )}
      </div> */}
    </div>
  );
};

TreeSelect.defaultProps = {
  treeData: [],
  onChange: () => {},
  multi: false,
  searchPlaceholder: 'Search',
  optionFilterProp: 'children',
  treeDefaultExpandAll: true,
  suggestedDataTypes: [],
  filterTreeNode: (input, option) => isFoundOption(input, option),
  showCheckedStrategy: SHOW_CHILD,
  style: { width: '100%' },
  dropdownStyle: { width: '40%' },
  // isSuggestedOptions: true,
};

const MyComponent = withStyles(styles)(TreeSelect);
export default MyComponent;

import { datadogRum } from '@datadog/browser-rum';
import { useSelector } from 'react-redux';
import HTTP from '../http';

export const translateUsers = users =>
  users.map(user => {
    user.display = user.username;
    return user;
  });

export const getUserById = (id, users) => {
  if (id && users && users.length > 0) {
    const us = users.filter(user => user.id === id);
    if (us && us.length > 0) {
      if (us[0].active === 0 && us[0].inactive == null) {
        us[0].username += ' (Inactive)';
        us[0].display_name += ' (Inactive)';
        us[0].inactive = true;
      }
      return us[0];
    }
  }
  return { username: 'Unknown User', display: 'Unknown User' };
};

export const logout = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const returnUrl = urlParams.get('returnUrl');
  window.location.href = `/api/logout-azure?returnUrl=${encodeURIComponent(
    returnUrl,
  )}`;

  datadogRum.clearUser();
};

export const sessionLogout = () => {
  // eslint-disable-next-line max-len
  window.location.href = `/api/auth/expire-session?returnUrl=${encodeURIComponent(
    window.location.href,
  )}`;

  datadogRum.clearUser();
};

export const refreshToken = () => HTTP.get('/auth/validate', {});

import { ListItem, Typography, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchTaskCounts } from 'actions/action-tasks';
import { TaskIconSidebar } from 'components/icons/icons';
import { TASK_SIDEBAR } from 'constants/index';
import { ToggleLeftDrawer } from 'actions/action-view';
import { styles } from '../side-bar-styles';
import PatientList from './patient-list';

import { CollapsibleSideBar } from '../collapsible-side-bar';
import { withCustomer } from '../../../../helpers/router';

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => WrappedComponent => props => <WrappedComponent {...props} width="xs" />;

function getTaskLink(taskType) {
  return withCustomer(`/patients?sidebar=${TASK_SIDEBAR}&task_type=${taskType}`);
}

class PatientSideBar extends Component {
  constructor(props) {
    super(props);
    this.renderTask = this.renderTask.bind(this);
  }

  componentDidMount() {
    const { onInitialize } = this.props;
    onInitialize();
  }

  renderTask(task, classes) {
    const { taskCounts } = this.props;
    const taskLink = getTaskLink(task);

    return (
      <ListItem
        button
        key={task}
        id={task}
        className={classes.listItem}
        component={Link}
        to={taskLink}
      >
        <TaskIconSidebar
          taskCount={taskCounts[task].task_cnt}
          taskName={taskCounts[task].task_description}
          btnText={task}
          isLoading={taskCounts.loading}
        />
      </ListItem>
    );
  }

  render() {
    const { classes, onToggleDrawer, isLeftDrawerOpen, width } = this.props;

    const drawerInternal = (
      <div>
        <Grid container direction="row">
          <Grid item xs={8}>
            <Typography variant="h6" className={classes.taskTitle}>
              Patient
            </Typography>
          </Grid>
        </Grid>
        <Switch>
          <Route component={PatientList} />
        </Switch>
      </div>
    );
    return (
      <CollapsibleSideBar
        classes={classes}
        isLeftDrawerOpen={isLeftDrawerOpen}
        width={width}
        onToggleDrawer={onToggleDrawer}
      >
        {drawerInternal}
      </CollapsibleSideBar>
    );
  }
}

function mapStateToProps(state) {
  const { taskCounts, patient, tabControl, view } = state;
  const { patientTab } = tabControl;
  return {
    taskCounts,
    patient,
    patientTab,
    isLeftDrawerOpen: view.leftDrawer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSelectTask: taskType => {
      dispatch(new ToggleLeftDrawer(true));
    },
    onInitialize: () => dispatch(fetchTaskCounts()),
    onToggleDrawer: state => dispatch(new ToggleLeftDrawer(state)),
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withWidth(),
)(PatientSideBar);

import { AxiosPromise } from 'axios';
import HTTP from '../services/http';

// Types

export interface IAddRulePayload {
  name: string;
  event_type: string;
  start_dt: string;
  end_dt: string | null;
  status?: string;
  constraints: any[];
  actions: any[];
}
// /Types

const baseUrl = '/rule';
export class RulesEngineClient {
  static addRule = (payload: IAddRulePayload): AxiosPromise<any[]> => {
    return HTTP.post(baseUrl, payload);
  };
}

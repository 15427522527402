import React from 'react';
import SecondaryTitleBar from 'components/secondary-title-bar/secondary-title-bar';
import TherapySubTitle from 'components/therapy-subtitle/therapy-subtitle';
import { Grid, Typography, Box } from '@mui/material';
import { isEmpty } from 'lodash';
import withStyles from '@mui/styles/withStyles';
import { ICounselingTask } from 'interfaces/redux/task-types/ICounselingTask';
import { IState } from 'interfaces/redux/IState';
import { MonographContentSections } from 'interfaces/counseling/ICounselingProtocol';
import { TherapyUtil } from 'utils/therapy-util';
import { useSelector } from 'react-redux';
import { ArborCheckbox } from 'components/arbor-checkbox/arbor-checkbox';
import { InterventionType } from './side-effects/interfaces/InterventionType';
import { styles } from './education-and-counseling.styles';
import { IProps } from './interfaces/IProps';
import SideEffectsForm from './side-effects/side-effects-form';

const sectionsToIncludeAndOrder: MonographContentSections[] = [
  'Monograph',
  'Warning',
  'Uses',
  'How to Use',
  'Side Effects',
  'Drug Interactions',
  'Missed Dose',
  'Storage',
];

const EducationAndCounseling: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { classes, educationAndCounselingData } = props;

  const therapies = useSelector((state: IState) => state.therapies);

  const renderMonographSection = (sectionName: string, sectionContent: string): JSX.Element => {
    return (
      <Grid container className={classes.monographContainer}>
        <Grid xs={12}>
          <Typography className={classes.monographTitle}>{sectionName}</Typography>
        </Grid>
        <Grid xs={12}>
          <Typography>{sectionContent}</Typography>
        </Grid>
      </Grid>
    );
  };

  const renderDrugSection = (task: ICounselingTask): JSX.Element | null => {
    const therapy = therapies.data[task.therapy_id];
    const protocolData = educationAndCounselingData.protocolData[task.id];
    const therapyLabel = TherapyUtil.getParagraphFormat(therapy);
    if (therapy && protocolData) {
      const filteredAndSortedProtocolData: { sectionKey: string; sectionContent: string }[] = [];

      Object.keys(protocolData.monographContent).forEach(key => {
        if (sectionsToIncludeAndOrder.includes(key as MonographContentSections)) {
          filteredAndSortedProtocolData.push({
            sectionKey: key,
            sectionContent: protocolData.monographContent[key as MonographContentSections],
          });
        }
      });
      filteredAndSortedProtocolData.sort((a, b) => {
        const aKey = a.sectionKey as MonographContentSections;
        const bKey = b.sectionKey as MonographContentSections;

        if (sectionsToIncludeAndOrder.indexOf(aKey) === -1) {
          return 1;
        }
        if (sectionsToIncludeAndOrder.indexOf(bKey) === -1) {
          return -1;
        }
        return sectionsToIncludeAndOrder.indexOf(aKey) - sectionsToIncludeAndOrder.indexOf(bKey);
      });

      return (
        <>
          <TherapySubTitle label={therapyLabel} />
          {filteredAndSortedProtocolData.map(record => {
            return renderMonographSection(record.sectionKey, record.sectionContent);
          })}
        </>
      );
    }
    return null;
  };

  const renderSideEffectManagementSection = (): JSX.Element | null => {
    return (
      <>
        <TherapySubTitle label="Side-Effect Management" />
        <SideEffectsForm
          nameField="sideEffectManagement"
          formValues={props.formValues}
          change={props.change}
          interventionType={InterventionType.SideEffect}
          question="Has the patient experienced any side effects since the last time we spoke? *"
          readOnly={props.readOnly}
          sideEffects={props.sideEffects?.filter(
            sideEffect =>
              sideEffect.type === InterventionType.SideEffect || sideEffect.type === undefined,
          )}
          smallForm
          multiple
          selectedServiceGroupId={props.educationAndCounselingData.selectedServiceGroupId}
          counselingTaskIds={props.educationAndCounselingData.tasks.map(task => task.id)}
        />
      </>
    );
  };

  const renderAdherenceSideEffectsSection = (): JSX.Element | null => {
    return (
      <>
        <TherapySubTitle label="Adherence" />
        <SideEffectsForm
          nameField="adherence"
          formValues={props.formValues}
          change={props.change}
          interventionType={InterventionType.Adherence}
          question="Has the patient experienced any adherence problem since the last time we spoke? *"
          readOnly={props.readOnly}
          sideEffects={props.sideEffects?.filter(
            sideEffect => sideEffect.type === InterventionType.Adherence,
          )}
          smallForm={false}
          multiple
          selectedServiceGroupId={props.educationAndCounselingData.selectedServiceGroupId}
          counselingTaskIds={props.educationAndCounselingData.tasks.map(task => task.id)}
          addButtonText="Add New Adherence Intervention"
        />
      </>
    );
  };

  return (
    <>
      {props.sideEffects && (
        <>
          <SecondaryTitleBar title="Safety and Efficacy Review" />
          {renderSideEffectManagementSection()}
          {renderAdherenceSideEffectsSection()}
        </>
      )}

      {!isEmpty(educationAndCounselingData.protocolData) &&
      Object.values(educationAndCounselingData.protocolData).some(
        x => !isEmpty(x) && x.supportiveMeds && x.supportiveMeds.length > 0,
      ) ? (
        <Box pb={2}>
          <SecondaryTitleBar title="Supportive Care Medications" />
          {Object.entries(educationAndCounselingData.protocolData)
            .filter(([, value]) => value.supportiveMeds && value.supportiveMeds.length > 0)
            .map(([key, value]) => {
              const numKey = Number(key);
              const task = educationAndCounselingData.tasks.find(x => x.id === numKey);
              let therapyLabel = '';
              if (task) {
                const therapy = therapies.data[task.therapy_id];
                therapyLabel = TherapyUtil.getParagraphFormat(therapy);
              }
              return (
                <React.Fragment key={`supportive-care-med-${key}`}>
                  <TherapySubTitle label={therapyLabel} />
                  {value.supportiveMeds?.map(med => {
                    return (
                      <Box
                        onClick={() => props.onSupportiveMedChecked(numKey, med.name, !med.checked)}
                        mb={1}
                        className={classes.pointer}
                      >
                        <Typography display="inline">
                          <ArborCheckbox
                            checked={med.checked}
                            onChange={event => {
                              props.onSupportiveMedChecked(numKey, med.name, event.target.checked);
                            }}
                          />
                          {med.name}
                        </Typography>
                      </Box>
                    );
                  })}
                </React.Fragment>
              );
            })}
        </Box>
      ) : null}

      <SecondaryTitleBar title="Drug Specific Counseling" />
      {educationAndCounselingData.tasks.map(task => {
        return renderDrugSection(task);
      })}
    </>
  );
};

export default withStyles(styles)(EducationAndCounseling);

import HTTP from 'services/http';
import { AxiosPromise } from 'axios';
import { IPharmacy } from 'models/services/providers/pharmacy/IPharmacy';

export class PharmacyService {
  private pharmacyByNpiRequest = (npi: string): AxiosPromise<IPharmacy> => {
    const url = `/providers/pharmacy/${npi}`;
    return HTTP.get(url) as AxiosPromise<IPharmacy>;
  };

  public getPharmacyByNpi = async (npi: string): Promise<IPharmacy | null> => {
    try {
      const request = this.pharmacyByNpiRequest(npi);
      const result = await request;

      if (result) {
        return result.data;
      }
    // eslint-disable-next-line no-empty
    } catch (_err) {}
    return null;
  };
}

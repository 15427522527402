import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { expandTheme } from 'utils/theme-util';

export const styles = (theme: Theme) => {
  const { palette, font } = expandTheme(theme);
  return createStyles({
    currentLengthLabelWrapper: {
      textAlign: 'right',
    },
    currentLengthLabel: {
      fontSize: font.mediumFontSize,
      color: palette.primary.lightGrey,
    },
    textArea: {
      border: `1px solid ${palette.primary.grey23}`,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1),
    },
  });
};

type Styles = WithStyles<typeof styles>;

const useStyles = makeStyles(styles);

export const useTypedStyles = (): Styles => ({
  classes: useStyles(),
});

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

export const TtcStyles = (theme: Theme) => {
  const { palette } = expandTheme(theme);
  return createStyles({
    addButton: {
      'backgroundColor': palette.primary.teal,
      'color': palette.primary.white,
      '&:hover': {
        backgroundColor: palette.primary.cuttySark,
      },
    },
  });
};

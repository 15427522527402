import { AxiosPromise } from 'axios';
import { IDraftFillCoordinationDetails } from 'models/tasks/DraftFillCoordinationDetails';
import HTTP from '../services/http';

export class DraftFillCoordinationClient {
  static fetch = (fcId: number): AxiosPromise<IDraftFillCoordinationDetails | null> => {
    const url = `/tasks/fill-coordination/${fcId}/draft-details`;
    return HTTP.get(url);
  };
}

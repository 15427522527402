import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Grid, Modal, Typography } from '@mui/material';

import LoadingOverlay from 'components/loading-overlay/loading-overlay';
import { CircularLoadingButton } from 'components/circular-loading-button/circular-loading-button';
import { buildQaId } from 'utils/build-qa-id';
import { getModalStyle } from 'services/utils/styles-service';
import { IGpiProtocolRequest } from 'models/application-manager/cpmp/IGetGpiProtocolsResponse';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import GpiProtocolForm from '../components/GpiProtocolForm';
import { styles } from '../styles';
import { IAddNewGpiProtocolModal, IFormErrors, ITimedFrequencies } from '../types';

const getQaId = buildQaId('application-manager.add-new-gpi-modal', '.');

const AddNewGpiProtocol: React.FC<IAddNewGpiProtocolModal> = ({
  open,
  closeModal,
  reloadListTrigger,
}) => {
  const dispatch = useDispatch();
  const classes = styles();
  const [newGpiProtocol, setNewGpiProtocol] = useState<IGpiProtocolRequest>();
  const [loading, setloading] = useState<boolean>(false);
  const [errors, setErrors] = useState<IFormErrors>({
    drug: false,
    frequencyItems: false,
    items: {},
  });

  const validateForm = (): boolean => {
    let isValid = true;
    const formErrors: IFormErrors = {
      drug: false,
      frequencyItems: false,
      items: {},
    };
    formErrors.drug = !newGpiProtocol?.gpi_10;
    isValid = isValid && !formErrors.drug;

    newGpiProtocol?.items.forEach(frequency => {
      const isTimedFrequency = Object.values<string>(ITimedFrequencies).includes(
        frequency.protocolFrequency,
      );
      const frequencyType = isTimedFrequency && !frequency.frequencyType;
      const frequencyUnit = isTimedFrequency && !frequency.frequencyUnit;
      const dcItems = frequency.dcItems.length === 0;
      formErrors.items = {
        ...(formErrors.items as {}),
        [frequency.protocolFrequency]: {
          frequencyType,
          frequencyUnit,
          dcItems,
        },
      };
      isValid = isValid && !frequencyType && !frequencyUnit && !dcItems;
    });
    setErrors(formErrors);
    return isValid;
  };

  const handleOnSubmit = async (): Promise<void> => {
    if (validateForm()) {
      try {
        setloading(true);
        const result = await ApplicationManagerClient.addGpiDrugs(
          newGpiProtocol as IGpiProtocolRequest,
        );
        if (result.status === 200) {
          dispatch(notifySuccess('GPI Protocol created'));
          reloadListTrigger(new Date());
          closeModal();
        }
      } catch (err) {
        dispatch(notifyError('Error creating GPI Protocol'));
      } finally {
        setloading(false);
      }
    }
  };

  return (
    <Modal open={open} data-qa-id={getQaId('wrapper')}>
      <div style={getModalStyle()} className={classes.modal}>
        <LoadingOverlay open={loading} />
        <Grid container direction="column" spacing={2}>
          {/* Title */}
          <Grid item xs="auto">
            <Typography className={classes.title} data-qa-id={getQaId('title')}>
              Add GPI-10 Protocol
            </Typography>
          </Grid>

          {/* Form */}
          <Grid item xs="auto" data-qa-id={getQaId('form')}>
            <GpiProtocolForm onChange={setNewGpiProtocol} errors={errors} />
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button data-qa-id={getQaId('button.cancel')} onClick={closeModal}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <CircularLoadingButton
                  data-qa-id={getQaId('button.save')}
                  buttonLabel="Save"
                  onClick={handleOnSubmit}
                  isLoading={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default AddNewGpiProtocol;

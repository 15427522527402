import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import { getFormValues, touch } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import FormHeader from 'components/form/header/header';
import {
  assignRankingAndKeys,
  processPhoneData,
  archiveInfo,
} from 'services/utils/demographic-service';
import { convertToArborDate } from 'models/time/arbor-date';
import { ADD_CONTACT_INFO_FORM, EDIT_CONTACT_INFO_FORM } from '../../../constants';
import ContactForm from './contact-form';

const useStyles = makeStyles(theme => ({
  container: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
  divider: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  form: {
    marginBottom: theme.spacing(2),
  },
}));

function ResponsiblePartyForm({ input: { value, onChange }, formValues, meta }) {
  const classes = useStyles();
  const [visible, setVisible] = useState(Boolean(value && value.id));
  const dispatch = useDispatch();

  useEffect(() => {
    if (meta.submitFailed) {
      dispatch(touch(ADD_CONTACT_INFO_FORM, 'first_name', 'last_name', 'dob', 'relationship'));
    }
  }, [meta.submitFailed]);

  useEffect(() => {
    if (formValues) {
      onChange({
        id: value.id,
        is_emergency: formValues.is_emergency || false,
        is_responsible: formValues.is_responsible || false,
        is_additional: formValues.is_additional || false,
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        middle_name: formValues.middle_name,
        suffix: formValues.suffix,
        nickname: formValues.nickname,
        dob: convertToArborDate(formValues.dob).getUtcDate(),
        relationship: formValues.relationship,
        languages:
          formValues.languages && formValues.languages.length > 0
            ? JSON.stringify(formValues.languages)
            : null,
        need_interpreter: formValues.need_interpreter || false,
        addresses: JSON.stringify(
          assignRankingAndKeys(
            archiveInfo(formValues.addresses || [], value && value.addresses),
            formValues.preferred_address_index,
          ),
        ),
        emails: JSON.stringify(
          assignRankingAndKeys(
            archiveInfo(formValues.emails || [], value && value.emails),
            formValues.preferred_email_index,
          ),
        ),
        phones: JSON.stringify(
          processPhoneData(
            assignRankingAndKeys(
              archiveInfo(formValues.phones || [], value && value.phones),
              formValues.preferred_phone_index,
            ),
            true,
          ),
        ),
        is_declined_to_provide_addresses: formValues.is_declined_to_provide_addresses || false,
        email_options: formValues.email_options || false,
        is_declined_to_provide_phones: formValues.is_declined_to_provide_phones || false,
        is_verified: 1, // If in UI, consider verified
        verified_dt: convertToArborDate(new Date()).getUtcDatetime(),
        visible,
        doesnt_have_emergency_contact: formValues.doesnt_have_emergency_contact || false,
        declined_to_provide_emergency_contact:
          formValues.declined_to_provide_emergency_contact || false,
      });
    }
  }, [formValues, visible]);

  return (
    <>
      <hr className={classes.divider} />
      <Box className={classes.container}>
        <FormHeader header="Since the patient is under age please provide a responsible party" />
        <Button
          type="button"
          onClick={() => setVisible(!visible)}
          color="secondary"
          variant="contained"
        >
          Add Responsible Party
        </Button>
      </Box>
      <Grid container spacing={7} className={visible ? classes.form : null}>
        <Grid item xs={12}>
          {visible && (
            <ContactForm
              hideConfirmationPanel
              contact={value || { is_responsible: true }}
              phoneRequired
              hasDoesntProvideContactField
            />
          )}
        </Grid>
      </Grid>
      {!visible && <hr className={classes.divider} />}
    </>
  );
}

function mapStateToProps(state, props) {
  let formName;

  if (props.value && props.value.id) {
    formName = `${EDIT_CONTACT_INFO_FORM}_${props.value.id}`;
  } else {
    formName = ADD_CONTACT_INFO_FORM;
  }

  const formValues = getFormValues(formName)(state);

  return {
    formValues,
  };
}

export default connect(mapStateToProps)(ResponsiblePartyForm);

import React from 'react';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { Button, Grid, Modal, Typography } from '@mui/material';
import { CircularLoadingButton } from 'components/circular-loading-button/circular-loading-button';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { getModalStyle } from 'services/utils/styles-service';
import { nameOfFactory } from 'utils/types-util';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { useDispatch } from 'react-redux';
import Validation from 'components/form/validation/validation';
import { buildQaId } from 'utils/build-qa-id';
import { ReactSelect } from 'components/form/field/react-select';
import { NumberOption, StringOption } from 'interfaces/forms/types';
import LoadingOverlay from 'components/loading-overlay/loading-overlay';
import { ControlledText } from 'components/react-hook-form-fields';
import { addDataCollectItem, editDataCollectItem } from 'actions/action-lookups';
import { IResult } from '../tables/types';
import { logger } from '../../../../winston-logger';
import { CLINICAL_DATA_TYPE_STATUS, CLINICAL_DATA_TYPE_OPTIONS } from '../../../../constants/index';
import { styles } from './add-new-dc-modal.styles';
import { IPayloadData, IPayloadObject, IDcItem, IEditDcItem, IEditPayloadObject } from './types';
import { buildIdFromDataItem, numberParser } from '../utils';

import { ClinicalDataTypeTypes } from '../../../../constants/clinical-data-type-types';

interface IAddNewDcModalProps {
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  editingDcItem: IResult | undefined;
  categoryOptionsList: NumberOption[];
}

interface IFormFields {
  categoryId: NumberOption;
  typeName: string;
  unit: string;
  isActive: StringOption;
  type: StringOption[];
  loinc: StringOption[];
  freetext: string;
  integerLeft: number;
  integerRight: number;
  multiselect: string;
  numeric: number;
  options: string;
}

interface IFormFieldsForDefaultValues {
  categoryId: NumberOption | null;
  typeName: string;
  unit: string;
  isActive: { label: string; value: number } | null;
  type: StringOption[] | null;
  loinc?: { label: string; value: string } | null;
  freetext?: string;
  integerLeft?: number;
  integerRight?: number;
  multiselect?: string;
  numeric?: number | null;
  options?: string;
}

const formFieldsValues: IFormFieldsForDefaultValues = {
  categoryId: null,
  typeName: '',
  unit: '',
  isActive: CLINICAL_DATA_TYPE_STATUS[0],
  type: null,
  loinc: null,
  freetext: '',
  integerLeft: 0,
  integerRight: 0,
  multiselect: '',
  numeric: null,
  options: '',
};

const getFieldName = nameOfFactory<IFormFields>();
const requiredErrorMsg = 'Required';
const positiveNumber = 'Missing decimal values';
const postiviveNumberRequired = 'Number should be greater than 0';
const getQaId = buildQaId('application-manager.add-new-dc-modal', '.');

const clinicalDataTypeStatusOptions = CLINICAL_DATA_TYPE_STATUS.map(x => {
  return { label: x.label, value: x.value };
});

const clinicalDataTypeOptions = CLINICAL_DATA_TYPE_OPTIONS.map(x => {
  return { label: x.label, value: x.value };
});

export const AddNewDcModal: React.FC<IAddNewDcModalProps> = (
  props: IAddNewDcModalProps,
): JSX.Element => {
  const classes: any = styles();

  // #region component state
  const form = useForm<any>({});
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loincCodes, setLoincCodes] = React.useState<StringOption[]>([]);
  const [valueType, setValueType] = React.useState<StringOption>();
  const [formValues, setFormValues] = React.useState<IFormFieldsForDefaultValues>(formFieldsValues);
  // #endregion

  // #region useEffect
  React.useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        if (props.editingDcItem) {
          const children = props.editingDcItem.children[0];

          const editValues: any = {};
          editValues.categoryId = props.categoryOptionsList.filter(
            x => x.label === props.editingDcItem?.category_name,
          )[0];
          editValues.typeName = props.editingDcItem.type_name;
          editValues.unit = children.unit.trim().length > 0 ? children.unit.trim() : '';
          editValues.isActive = clinicalDataTypeStatusOptions.filter(
            x => x.value === (!!props.editingDcItem?.is_active === true ? 1 : 0),
          )[0];

          if (props.editingDcItem?.children[0].type !== null) {
            const typeValueArr = CLINICAL_DATA_TYPE_OPTIONS.filter(
              x => x.value === props.editingDcItem?.children[0].type,
            );
            editValues.type = typeValueArr;
            if (typeValueArr.length > 0) {
              setValueType(typeValueArr[0]);
              switch (typeValueArr[0].value) {
                case ClinicalDataTypeTypes.Integer:
                  const [integerLeftValue, integerRightValue] = children.option
                    ? children.option.split(';').map(option => option.trim())
                    : [0, 0];
                  editValues.integerLeft = integerLeftValue;
                  editValues.integerRight = integerRightValue;
                  break;
                case ClinicalDataTypeTypes.FreeText:
                  editValues.freetext =
                    children.example.trim().length > 0 ? children.example.trim() : '';
                  break;
                case ClinicalDataTypeTypes.Numeric:
                  editValues.numeric =
                    children.example.trim().length > 0 ? children.example.trim() : null;
                  break;
                case ClinicalDataTypeTypes.MultiSelect:
                  editValues.multiselect =
                    children.option.trim().length > 0 ? children.option.trim() : '';
                  break;
                case ClinicalDataTypeTypes.Options:
                  editValues.options =
                    children.option.trim().length > 0 ? children.option.trim() : '';
                  break;
                default:
                  break;
              }
            }
          }

          const selectedLoincCodes =
            children.loincCodes.length > 0
              ? children.loincCodes.map(x => {
                  return { label: x.shortname, value: x.code };
                })
              : [];
          setLoincCodes(selectedLoincCodes);
          editValues.loinc = selectedLoincCodes;
          setFormValues({ ...editValues });
        }
      } catch (error) {
        logger.error(error);
        dispatch(notifyError('Error when editing a clinical data type.'));
      } finally {
        setLoading(false);
      }
    })();
  }, [props.editingDcItem]);

  // #endregion

  // #region helper functions
  const onSubmit: SubmitHandler<any> = async (formValues: IFormFields): Promise<void> => {
    if (props.editingDcItem) {
      await editDcItem(formValues);
    } else {
      await submitDcItem(formValues);
    }
  };

  const editDcItem = async (formValues: IFormFields): Promise<void> => {
    try {
      const { categoryId } = formValues;
      const { typeName } = formValues;
      const { isActive } = formValues;
      const type = formValues.type.map(x => x.value).join();
      const { unit } = formValues;
      const dynamicFieldValue = getDynamicFieldValue(type, formValues);
      const loincIds =
        formValues.loinc != null && formValues.loinc.length > 0
          ? formValues.loinc.map(x => x.value)
          : [];

      if (props.editingDcItem?.id) {
        const dcItemObject: IEditDcItem = {
          id: props.editingDcItem?.id,
          category_id: categoryId.value,
          name: typeName,
          isActive: Boolean(isActive.value),
          loinc: loincIds,
          type: type,
          unit: unit,
          example:
            type === ClinicalDataTypeTypes.FreeText || type === ClinicalDataTypeTypes.Numeric
              ? dynamicFieldValue
              : null,
          option:
            type !== ClinicalDataTypeTypes.FreeText && type !== ClinicalDataTypeTypes.Numeric
              ? dynamicFieldValue
              : null,
        };

        const result = await ApplicationManagerClient.editDc(dcItemObject);
        props.onSuccess();
        props.onCancel();
        if (!result.data.hasOwnProperty('errorMessage')) {
          dispatch(notifySuccess('Edited DC item'));
          dispatch(
            editDataCollectItem(buildPayloadObjectToEditDcItem(dcItemObject, formValues.loinc)),
          );
        } else {
          const errorMessage = Object.entries(result.data).reduce((acc, val) => {
            if (val[0] === 'errorMessage') {
              acc = val[1];
            }
            return acc;
          }, '');
          if (errorMessage.length > 0) {
            dispatch(notifyError(errorMessage));
          }
        }
      }
    } catch (error) {
      logger.error(error);
      dispatch(notifyError('Error editing DC item'));
    }
  };

  const submitDcItem = async (formValues: IFormFields): Promise<void> => {
    try {
      const { categoryId } = formValues;
      const { typeName } = formValues;
      const { unit } = formValues;
      const { isActive } = formValues;
      const type = formValues.type.map(x => x.value).join();
      const dynamicFieldValue = getDynamicFieldValue(type, formValues);
      const loincCodes = formValues.loinc ?? [];

      const newDcItemObject: IDcItem = {
        category_id: categoryId.value,
        name: typeName,
        type: type,
        unit: unit,
        isActive: Boolean(isActive.value),
        dynamicFieldValue: dynamicFieldValue,
        loinc: loincCodes,
      };

      const result = await ApplicationManagerClient.addNewDc(newDcItemObject);
      props.onSuccess();
      props.onCancel();
      if (result.data.success && typeof result.data.result === 'number') {
        dispatch(notifySuccess('Saved'));
        dispatch(
          addDataCollectItem(
            buildPayloadObjectToAddNewDcItem({
              dataTypeId: result.data.result,
              dcItem: newDcItemObject,
            }),
          ),
        );
      } else {
        dispatch(notifyError(result.data.result));
      }
    } catch (error) {
      logger.error(error);
      dispatch(notifyError('Error creating DC'));
    }
  };

  const buildPayloadObjectToAddNewDcItem = (data: IPayloadData): IPayloadObject => {
    const newDcObj: IPayloadObject = {
      category_id: data.dcItem.category_id,
      dataTypeId: data.dataTypeId,
      data: {
        id: buildIdFromDataItem({ unit: data.dcItem.unit, name: data.dcItem.name }),
        name: data.dcItem.name,
        type: data.dcItem.type,
        unit: data.dcItem.unit,
        isActive: data.dcItem.isActive,
        option:
          data.dcItem.type !== ClinicalDataTypeTypes.FreeText &&
          data.dcItem.type !== ClinicalDataTypeTypes.Numeric
            ? data.dcItem.dynamicFieldValue
            : null,
        example:
          data.dcItem.type === ClinicalDataTypeTypes.FreeText ||
          data.dcItem.type === ClinicalDataTypeTypes.Numeric
            ? data.dcItem.dynamicFieldValue
            : null,
        loinc:
          data.dcItem.loinc && data.dcItem.loinc.length > 0
            ? data.dcItem.loinc.reduce((acc: any, currentVal) => {
                acc[currentVal.value] = { shortname: currentVal.label };
                return acc;
              }, {})
            : {},
      },
    };
    if (newDcObj.data.option !== null) {
      newDcObj.data.options = newDcObj.data.option
        .split(';')
        .map((x: string, index: number) => (index === 0 ? `${x}` : ` ${x}`));
    }
    return newDcObj;
  };

  const buildPayloadObjectToEditDcItem = (
    data: IEditDcItem,
    loincCodes: StringOption[],
  ): IEditPayloadObject => {
    return {
      dataTypeId: data.id,
      previousCategoryId: props.categoryOptionsList
        .filter(x => x.label === props.editingDcItem?.category_name)
        .map(x => x.value)[0],
      data: {
        category_id: data.category_id,
        name: data.name,
        isActive: data.isActive,
        loinc:
          loincCodes && loincCodes.length > 0
            ? loincCodes.reduce((acc: any, currentVal) => {
                acc[currentVal.value] = { shortname: currentVal.label };
                return acc;
              }, {})
            : {},
      },
    };
  };

  const getDynamicFieldValue = (type: string, formValues: IFormFields): string => {
    switch (type) {
      case ClinicalDataTypeTypes.FreeText:
        return formValues.freetext;
      case ClinicalDataTypeTypes.Integer:
        return `${formValues.integerLeft};${formValues.integerRight}`;
      case ClinicalDataTypeTypes.MultiSelect:
        return formValues.multiselect;
      case ClinicalDataTypeTypes.Numeric:
        return formValues.numeric !== null ? formValues.numeric.toString() : formValues.numeric;
      case ClinicalDataTypeTypes.Options:
        return formValues.options;
      default:
        return '';
    }
  };

  const setCommaSeparatedValues = (e: any, separator = ','): void => {
    if (e.key === 'Enter') {
      e.target.value = e.target.value.trim();
      if (e.target.value !== '') {
        e.target.value = `${e.target.value.replace(/(^[,\s]+)|([,\s]+$)/g, '')}${separator} `;
      }
    } else {
      e.target.value = e.target.value.replace(/(^[,]+)|([,]+$)/g, '');
    }
  };
  // #endregion

  // # region renders
  const renderFreeTextOption = (): JSX.Element => {
    return (
      <ControlledText
        name={getFieldName('freetext')}
        control={form.control}
        disabled={!!props.editingDcItem}
        defaultValue={formValues.freetext}
        validations={{ required: false }}
        percentWith={100}
        label="Example"
      />
    );
  };

  const renderIntergerOption = (): JSX.Element => {
    // {Default Value in both should be zero (0)}
    return (
      <Grid container direction="column" spacing={2}>
        <Grid item xs="auto">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ControlledText
                name={getFieldName('integerLeft')}
                control={form.control}
                defaultValue={formValues.integerLeft}
                disabled={!!props.editingDcItem}
                validations={{ required: true, pattern: /^\d+$/ }}
                percentWith={100}
                label="Min *"
                onKeyUp={(e: any) => {
                  numberParser(e);
                }}
                inputMetaData={{
                  touched: Boolean(form.formState.errors.integerLeft),
                  error: postiviveNumberRequired,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ControlledText
                name={getFieldName('integerRight')}
                control={form.control}
                defaultValue={formValues.integerRight}
                disabled={!!props.editingDcItem}
                validations={{ required: true, pattern: /^\d+$/ }}
                percentWith={100}
                label="Max *"
                onKeyUp={(e: any) => {
                  numberParser(e);
                }}
                inputMetaData={{
                  touched: Boolean(form.formState.errors.integerRight),
                  error: postiviveNumberRequired,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderMultiselectOption = (): JSX.Element => {
    return (
      <ControlledText
        name={getFieldName('multiselect')}
        control={form.control}
        disabled={!!props.editingDcItem}
        defaultValue={formValues.multiselect}
        validations={{ required: true }}
        percentWith={100}
        label={
          props.editingDcItem
            ? 'Options (separate with semicolon) *'
            : 'Options (Press enter to add a new option) *'
        }
        onKeyUp={(e: any) => {
          setCommaSeparatedValues(e, ';');
        }}
        inputMetaData={{
          touched: Boolean(form.formState.errors.multiselect),
          error: requiredErrorMsg,
        }}
      />
    );
  };

  const renderNumericOption = (): JSX.Element => {
    return (
      <ControlledText
        name={getFieldName('numeric')}
        control={form.control}
        disabled={!!props.editingDcItem}
        defaultValue={formValues.numeric}
        validations={{ required: false, pattern: /^\d+(\.\d+)?$/ }}
        percentWith={100}
        label="Example"
        placeholder="11.11"
        onKeyUp={(e: any) => {
          numberParser(e, -1);
        }}
        inputMetaData={{ touched: Boolean(form.formState.errors.numeric), error: positiveNumber }}
      />
    );
  };

  const renderOption = (): JSX.Element => {
    return (
      <ControlledText
        name={getFieldName('options')}
        control={form.control}
        disabled={!!props.editingDcItem}
        defaultValue={formValues.options}
        validations={{ required: true }}
        percentWith={100}
        label={
          props.editingDcItem
            ? 'Options (separate with semicolon) *'
            : 'Options (Press enter to add a new option) *'
        }
        onKeyUp={(e: any) => {
          setCommaSeparatedValues(e, ';');
        }}
        inputMetaData={{ touched: Boolean(form.formState.errors.options), error: requiredErrorMsg }}
      />
    );
  };

  const renderValueComponent = (): JSX.Element | null => {
    if (valueType) {
      switch (valueType.value) {
        case ClinicalDataTypeTypes.FreeText:
          return renderFreeTextOption();
        case ClinicalDataTypeTypes.Integer:
          return renderIntergerOption();
        case ClinicalDataTypeTypes.MultiSelect:
          return renderMultiselectOption();
        case ClinicalDataTypeTypes.Numeric:
          return renderNumericOption();
        case ClinicalDataTypeTypes.Options:
          return renderOption();
      }
    }
    return null;
  };
  // #endregion
  const isEditingDcItem = !!props.editingDcItem?.id;

  return (
    <Modal open={props.open} data-qa-id={getQaId('wrapper')}>
      <div style={getModalStyle()} className={classes.modal}>
        <Grid container direction="column" spacing={2}>
          {/* Title */}
          <Grid item xs="auto">
            <Typography className={classes.title} data-qa-id={getQaId('title')}>
              {!props.editingDcItem ? 'Add DC Item' : `Edit "${props.editingDcItem.type_name}"`}
            </Typography>
          </Grid>

          {/* Form */}
          <Grid item xs="auto" data-qa-id={getQaId('form')}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Controller
                  name={getFieldName('categoryId')}
                  defaultValue={formValues.categoryId}
                  control={form.control}
                  rules={{ required: true }}
                  render={(ctrlProps: any) => {
                    return (
                      <>
                        <LoadingOverlay open={loading} />
                        <ReactSelect
                          label="Category *"
                          isMulti={false}
                          value={ctrlProps?.field?.value}
                          fields={props.categoryOptionsList}
                          handleOnChange={(value: NumberOption) =>
                            ctrlProps?.field?.onChange(value)
                          }
                          fullWidth
                        />
                        <Validation
                          touched={form.formState.errors.categoryId}
                          error={requiredErrorMsg}
                        />
                      </>
                    );
                  }}
                />
              </Grid>

              <Grid item xs={5}>
                <ControlledText
                  name={getFieldName('typeName')}
                  control={form.control}
                  defaultValue={formValues.typeName}
                  validations={{ required: true }}
                  percentWith={100}
                  label="Item *"
                  inputMetaData={{
                    touched: Boolean(form.formState.errors.typeName),
                    error: requiredErrorMsg,
                  }}
                />
              </Grid>

              <Grid item xs={2}>
                <ControlledText
                  name={getFieldName('unit')}
                  control={form.control}
                  disabled={!!props.editingDcItem}
                  defaultValue={formValues.unit}
                  validations={{ required: false }}
                  percentWith={100}
                  label="Unit"
                  placeholder="e.g. %"
                />
              </Grid>

              <Grid item xs={3}>
                <Controller
                  name={getFieldName('isActive')}
                  defaultValue={formValues.isActive}
                  control={form.control}
                  rules={{ required: true }}
                  render={(ctrlProps: any) => {
                    return (
                      <>
                        <ReactSelect
                          label="Status *"
                          isDisabled={!isEditingDcItem}
                          isMulti={false}
                          value={ctrlProps?.field?.value}
                          fields={clinicalDataTypeStatusOptions}
                          handleOnChange={(value: StringOption) =>
                            ctrlProps?.field?.onChange(value)
                          }
                          fullWidth
                        />
                        <Validation
                          touched={form.formState.errors.isActive}
                          error={requiredErrorMsg}
                        />
                      </>
                    );
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <Controller
                  name={getFieldName('type')}
                  defaultValue={formValues.type}
                  control={form.control}
                  rules={{ required: true }}
                  render={(ctrlProps: any) => {
                    return (
                      <>
                        <ReactSelect
                          label="Value *"
                          isDisabled={!!props.editingDcItem}
                          isMulti={false}
                          value={ctrlProps?.field?.value}
                          fields={clinicalDataTypeOptions}
                          handleOnChange={(value: StringOption) => {
                            setValueType(value);
                            ctrlProps?.field?.onChange([value]);
                          }}
                          fullWidth
                        />
                        <Validation touched={form.formState.errors.type} error={requiredErrorMsg} />
                      </>
                    );
                  }}
                />
              </Grid>

              <Grid item xs={5}>
                {renderValueComponent()}
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name={getFieldName('loinc')}
                  defaultValue={formValues.loinc}
                  control={form.control}
                  rules={{ required: false }}
                  render={(ctrlProps: any) => {
                    return (
                      <>
                        <LoadingOverlay open={loading} />
                        <ReactSelect
                          label="LOINC"
                          isMulti
                          value={ctrlProps?.field?.value}
                          fields={loincCodes}
                          handleOnChange={(value: NumberOption) =>
                            ctrlProps?.field?.onChange(value)
                          }
                          fullWidth
                        />
                        <Validation touched={form.formState.errors.loinc} error={undefined} />
                      </>
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button
                      data-qa-id={getQaId('button.cancel')}
                      onClick={() => {
                        form.reset();
                        props.onCancel();
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <CircularLoadingButton
                      data-qa-id={getQaId('button.save')}
                      buttonLabel="Save"
                      onClick={form.handleSubmit(onSubmit)}
                      isLoading={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

import React, { useState } from 'react';
import { Grid } from '@mui/material';
import PendingDocument from 'components/document/pending-document';
import SelectFileForm from 'components/document/file-forms/select-file-form';
import FileSelectedForm from 'components/document/file-forms/file-selected-form';
import {
  acceptedFileExtensions,
  fileTypeErrorCaption,
  fileTypeError,
} from 'services/utils/document-service';

const WrappedFileUpload = props => {
  const {
    pendingDocument,
    uploadedDocuments,
    defaultDocumentLabels,
    handleFileUploadSubmit,
    handleFileUploadCancel,
    fileUploadFormId,
    fileUploadErrorMessage,
    uploadProcessing,
    classes,
    fileSelected,
  } = props;

  const [errorMessage, setErrorMessage] = useState();
  const [errorCaption, setErrorCaption] = useState();

  const acceptedDrop = e => {
    setErrorMessage();
    setErrorCaption();
    fileSelected(e);
  };

  const rejectedDrop = () => {
    setErrorMessage(fileTypeError);
    setErrorCaption(fileTypeErrorCaption);
  };

  const handleFilePaste = e => {
    if (e && e.clipboardData && e.clipboardData.files && e.clipboardData.files.length > 0) {
      acceptedDrop(e.clipboardData.files);
    } else {
      rejectedDrop();
    }
  };

  return (
    <>
      {!pendingDocument && (
        <Grid container className={classes ? classes.customFormField : null}>
          <Grid item xs={6}>
            <SelectFileForm
              acceptedFileExtensions={acceptedFileExtensions}
              errorMessage={errorMessage}
              errorCaption={errorCaption}
              handleAcceptedDrop={acceptedDrop}
              handleFilePaste={handleFilePaste}
              handleRejectedDrop={rejectedDrop}
            />
          </Grid>
        </Grid>
      )}
      {pendingDocument && (
        <Grid container>
          <Grid item xs={6} className={classes ? classes.customFormField : null}>
            <FileSelectedForm
              documentLabels={defaultDocumentLabels.map(dt => ({
                label: dt.label,
                value: dt.label,
              }))}
              submitButtonText="Upload"
              fileName={pendingDocument.fileName}
              fileExtension={pendingDocument.fileExtension}
              form={fileUploadFormId}
              formSubmit={e => handleFileUploadSubmit(e)}
              onCancel={() => handleFileUploadCancel()}
              errorMessage={fileUploadErrorMessage}
              isProcessing={uploadProcessing}
            />
          </Grid>
        </Grid>
      )}
      {uploadedDocuments && (
        <Grid container>
          {uploadedDocuments.map(document => (
            <PendingDocument documentData={document} />
          ))}
        </Grid>
      )}
    </>
  );
};

export default WrappedFileUpload;

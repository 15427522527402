import HTTP from 'services/http';
import {
  ADD_NOTE,
  ADD_NOTE_COMMUNICATION,
  ADD_TO_TREE_VIEW,
  ARCHIVE_NOTE,
  CREATE_NOTES_NEW_PATIENT,
  FETCH_NEW_NOTES,
  FETCH_NOTES,
  FETCH_NOTES_ARCHIVED,
  FETCH_NOTES_SIDEBAR,
  NOTES_VIEW_SET,
  OPEN_NOTE_COMMUNICATION_SIDEBAR,
  RESOLVE_COMMUNICATION_NOTE,
  TOGGLE_PIN_WORKFLOW,
  TRIM_NOTES,
  UPDATE_NOTES_SIDEBAR,
  UPDATE_NOTE_BAR,
  CLEAR_NOTE_BAR_SMS_MESSAGE_FOCUS,
} from 'constants/index';

const NOTES_SEARCH_URL = '/notes/_search';

export function addNote(newNote) {
  const postURL = '/notes/';
  const request = HTTP.post(postURL, newNote, {});
  return { type: ADD_NOTE, payload: request };
}

export function addNoteCommunication(notePayload) {
  return {
    type: ADD_NOTE_COMMUNICATION,
    payload: notePayload,
  };
}

export function addNoteNoApi(newNote) {
  return {
    type: ADD_NOTE,
    payload: { data: { added_note: newNote } },
  };
}

export function fetchNotes(query) {
  const postURL = NOTES_SEARCH_URL;
  const request = HTTP.post(postURL, query, {});
  return {
    type: FETCH_NOTES,
    payload: request,
    meta: { patientId: query.patient_id },
  };
}

export function fetchNewNotes(query) {
  const postURL = NOTES_SEARCH_URL;
  const request = HTTP.post(postURL, query, {});
  return {
    type: FETCH_NEW_NOTES,
    payload: request,
    meta: { patientId: query.patient_id },
  };
}

export function trimNotes() {
  return {
    type: TRIM_NOTES,
  };
}

export function fetchNotesSidebar(query, existingTasks = []) {
  const postURL = NOTES_SEARCH_URL;
  const request = HTTP.post(postURL, query, {});
  return {
    type: FETCH_NOTES_SIDEBAR,
    payload: request,
    meta: { patientId: query.patient_id, tasks: existingTasks },
  };
}

export function filterNotesSidebar(query) {
  return {
    type: UPDATE_NOTES_SIDEBAR,
    payload: query,
  };
}

export function togglePinnedNote(note) {
  const patchUrl = '/notes/_pin';
  const request = HTTP.patch(patchUrl, note, {});
  return {
    type: TOGGLE_PIN_WORKFLOW,
    payload: request,
  };
}

export function resolveCommunicationNote(note) {
  const patchUrl = '/notes/_resolve-communication';
  const request = HTTP.patch(patchUrl, note, {});
  return {
    type: RESOLVE_COMMUNICATION_NOTE,
    payload: request,
  };
}

export function archiveNote(note) {
  const patchUrl = '/notes/_archive';
  const request = HTTP.patch(patchUrl, note, {});
  return {
    type: ARCHIVE_NOTE,
    payload: request,
  };
}

export function updateNoteBar(note) {
  return {
    type: UPDATE_NOTE_BAR,
    payload: note,
  };
}
export function fetchArchivedNotes(query) {
  const postUrl = NOTES_SEARCH_URL;
  const request = HTTP.post(postUrl, query, {});
  return {
    type: FETCH_NOTES_ARCHIVED,
    payload: request,
  };
}
export function createNotesNewPatient() {
  return {
    type: CREATE_NOTES_NEW_PATIENT,
    payload: null,
  };
}

export function addToTreeView() {
  return {
    type: ADD_TO_TREE_VIEW,
    payload: null,
  };
}

export const setNotesView = view => ({
  type: NOTES_VIEW_SET,
  payload: {
    view,
  },
});

export function openCommunicationNotesSideBar(payload) {
  return {
    type: OPEN_NOTE_COMMUNICATION_SIDEBAR,
    payload,
  };
}

export function clearNoteBarMessageFocus() {
  return {
    type: CLEAR_NOTE_BAR_SMS_MESSAGE_FOCUS,
  };
}

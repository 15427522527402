import React, { useEffect, useMemo } from 'react';
import { withStyles, WithStyles, makeStyles } from '@mui/styles';
import { Dialog, DialogTitle, Typography, DialogContent, Theme } from '@mui/material';
import { FillDeliveryConfirmationStatus } from 'interfaces/enums/TaskStatuses/FillDeliveryConfirmationStatus';
import { PharmacyService } from 'services/utils/pharmacy-service';
import { windowFeatureIsEnabled } from 'config/window-features';
import { ShippingStyles } from './shipping.styles';
import { IPrintLabelButton } from './types';
import VoidLabelButton from './void-label-button';
import ReprintLabelButton from './re-print-label-button';
import PrintLabelButton from './print-label-button';
import { CourierType, DeliveryMethodValue } from '../../constants/enums';
import { defaultTheme } from '../../lib/themes';
import { transformDeliveryInformation } from './utils';

const VerticalSpacer = () => <div style={{ width: '100%', height: '72px' }} />;

interface IShippingProps extends IPrintLabelButton, WithStyles<typeof ShippingStyles> {}
const useStyles = makeStyles((theme: Theme) => ({
  title: {
    backgroundColor: defaultTheme.palette.primary.bluesky,
    marginBottom: 20,
    minWidth: 600,
  },
}));

const ModalForm = (props: any) => {
  const { close, title, instructions } = props;
  const classes = useStyles();
  return (
    <Dialog open onClose={close} maxWidth="md">
      <DialogTitle id="simple-dialog-title" className={classes.title}>
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{instructions}</Typography>
      </DialogContent>
    </Dialog>
  );
};

function PrintShippingButtons(props: IShippingProps): JSX.Element | null {
  const {
    orderId,
    statusId,
    printDetails,
    currentPrintInformation,
    currentDeliveryInformation,
    currentFdcInformation,
    disabled,
    setFdcOrderDetails,
  } = props;

  const [pharmacyService] = React.useState<PharmacyService>(new PharmacyService());
  const [isLabLogistics, setIsLabLogistics] = React.useState<boolean>(false);
  const [isSpokeLogistics, setIsSpokeLogistics] = React.useState<boolean>(false);
  const [isAssociatedCourier, setIsAssociatedCourier] = React.useState<boolean>(false);
  const [isNuVizzCourier, setIsNuVizzCourier] = React.useState<boolean>(false);
  const [courierName, setCourierName] = React.useState<string>('');
  const [showManualVoidMessage, setShowManualVoidMessage] = React.useState<boolean>(false);
  const [showManualPrintLabelMessage, setShowManualPrintLabelMessage] =
    React.useState<boolean>(false);
  const [showManualReprintLabelMessage, setShowManualReprintLabelMessage] =
    React.useState<boolean>(false);

  const currentTrackingInformation = currentPrintInformation?.tracking_number ?? undefined;
  const currentLabLogisticsOrderId = currentPrintInformation?.lab_logistics_order_id ?? undefined;
  const currentAssociatedCourierOrderId =
    currentPrintInformation?.associated_courier_order_id ?? undefined;
  const currentNuVizzCourierOrderId = currentPrintInformation?.nuvizz_courier_order_id ?? undefined;

  const isShipped =
    !currentTrackingInformation &&
    !currentLabLogisticsOrderId &&
    !currentAssociatedCourierOrderId &&
    !currentNuVizzCourierOrderId;

  const { current, previous, keys } = useMemo(
    () => transformDeliveryInformation(currentDeliveryInformation),
    [currentDeliveryInformation],
  );
  const isEnabledReprintLabel = useMemo(
    () =>
      current.deliveryMethod !== DeliveryMethodValue.PickUp &&
      keys.every(key => current[key] === previous[key] || previous[key] === null),
    [current, previous, keys],
  );

  const [printLabelClicked, setPrintLabelClicked] = React.useState<boolean | undefined>(false);
  const [firstTimePrinting, setFirstTimePrinting] = React.useState<boolean | undefined>(isShipped);

  useEffect(() => {
    const currentDispensingPharmacyNpi = currentDeliveryInformation?.dispensingPharmacyNpi;
    if (!currentDispensingPharmacyNpi) return;

    const getNpi = async (currentDispensingPharmacyNpi: string) => {
      const pharmacyResponse = await pharmacyService.getPharmacyByNpi(currentDispensingPharmacyNpi);
      setIsLabLogistics(pharmacyResponse?.lab_logistics_customer_id !== null);
      setIsSpokeLogistics(pharmacyResponse?.spoke_logistics_customer_id !== null);
      setIsAssociatedCourier(pharmacyResponse?.associated_courier_customer_id !== null);
      setIsNuVizzCourier(pharmacyResponse?.nuvizz_courier_customer_id !== null);
      if (pharmacyResponse?.nuvizz_courier_customer_id) {
        setCourierName(pharmacyResponse.nuvizz_courier_customer_id.split('-')[1] || 'Courier');
      }
    };
    getNpi(currentDispensingPharmacyNpi);
  }, [currentDeliveryInformation?.dispensingPharmacyNpi]);

  const displayPrintLabelButton = useMemo(() => {
    const isStatusValidForShowingPrintButtons =
      statusId === FillDeliveryConfirmationStatus.Shipped ||
      statusId === FillDeliveryConfirmationStatus.Dispense ||
      statusId === FillDeliveryConfirmationStatus.Packed;
    const isCurrentMethodOfDeliveryUps =
      currentDeliveryInformation?.deliveryMethod === DeliveryMethodValue.Ups;
    const isCurrentMethodOfDeliveryCourier =
      currentDeliveryInformation?.deliveryMethod === DeliveryMethodValue.Courier;
    const isExternalCourier =
      isCurrentMethodOfDeliveryCourier &&
      (currentDeliveryInformation?.courierType === CourierType.External ||
        !windowFeatureIsEnabled('courier_type'));

    const isUpsOrCourierValid =
      isCurrentMethodOfDeliveryUps ||
      (isExternalCourier && isLabLogistics) ||
      (isExternalCourier && isSpokeLogistics) ||
      (isExternalCourier && isAssociatedCourier) ||
      (isExternalCourier && isNuVizzCourier);

    return isStatusValidForShowingPrintButtons && isUpsOrCourierValid;
  }, [
    currentDeliveryInformation?.courierType,
    currentDeliveryInformation?.deliveryMethod,
    isAssociatedCourier,
    isLabLogistics,
    isNuVizzCourier,
    isSpokeLogistics,
    statusId,
  ]);

  const displayShippingButtonsGroup = (): JSX.Element => {
    return (
      <>
        {firstTimePrinting && !printLabelClicked ? (
          <PrintLabelButton
            currentDeliveryInformation={currentDeliveryInformation}
            currentPrintInformation={currentPrintInformation}
            currentFdcInformation={currentFdcInformation}
            orderId={orderId}
            setPrintLabelClicked={setPrintLabelClicked}
            setShowManualPrintLabelMessage={setShowManualPrintLabelMessage}
            disabled={disabled}
          />
        ) : (
          <>
            {isEnabledReprintLabel && (
              <ReprintLabelButton
                currentDeliveryInformation={currentDeliveryInformation}
                currentPrintInformation={currentPrintInformation}
                orderId={orderId}
                printDetails={printDetails}
                setPrintLabelClicked={setPrintLabelClicked}
                setShowManualReprintLabelMessage={setShowManualReprintLabelMessage}
              />
            )}

            <VoidLabelButton
              currentDeliveryInformation={currentDeliveryInformation}
              currentPrintInformation={currentPrintInformation}
              currentFdcInformation={currentFdcInformation}
              orderId={orderId}
              setPrintLabelClicked={setPrintLabelClicked}
              setFirstTimePrinting={setFirstTimePrinting}
              setShowManualVoidMessage={setShowManualVoidMessage}
              setFdcOrderDetails={setFdcOrderDetails}
            />
          </>
        )}
      </>
    );
  };

  return (
    <>
      {displayPrintLabelButton ? displayShippingButtonsGroup() : <VerticalSpacer />}
      {showManualVoidMessage ? (
        <ModalForm
          title="Shipment Voiding Instructions"
          instructions={`The FDC has been updated; however, you must manually cancel the shipping label using the ${courierName} portal.`}
          close={() => setShowManualVoidMessage(false)}
        />
      ) : null}
      {showManualPrintLabelMessage ? (
        <ModalForm
          title="Shipping Label Instructions"
          instructions={`The shipment order has been created; however, the shipping label for ${courierName} orders must be printed from the courier portal.`}
          close={() => setShowManualPrintLabelMessage(false)}
        />
      ) : null}
      {showManualReprintLabelMessage ? (
        <ModalForm
          title="Shipping Label Instructions"
          instructions={`The shipping label for ${courierName} orders must be printed from the courier portal.`}
          close={() => setShowManualReprintLabelMessage(false)}
        />
      ) : null}
    </>
  );
}

export default withStyles(ShippingStyles)(PrintShippingButtons);

import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ISectionDetailsProps } from '../../section/interfaces';
import { IPatientEnrollmentProps } from '../types';
import PatientInformation from '../../../containers/patient/demographics/patient-information';
import { useTypedSelector } from '../../../hooks/use-typed-selector';
import ContactDetail from '../../../containers/patient/demographics/contact-detail';
import FormHeader from '../../form/header/header';
import ContactForm from '../../../containers/patient/demographics/contact-form';
import { useVerifiedText } from '../utils';

export function PatientEnrollmentDemographicSection({
  alert: alertMessage,
}: ISectionDetailsProps<IPatientEnrollmentProps>) {
  const contactList = useTypedSelector(state => state.contactList);
  const patient = useTypedSelector(state => state.patient);

  const [contactKeys, setContactKeys] = useState<number[]>([]);
  const [displayContactForm, setDisplayContactForm] = useState(false);

  const verifiedMessage = useVerifiedText(state => {
    const userId = state.patient.verified_by;
    const date = state.patient.verified_dt;
    const isVerified = state.patient.is_verified === 1;
    return isVerified && userId && date ? { userId, date } : null;
  });
  useEffect(() => {
    if (verifiedMessage) alertMessage(verifiedMessage, true);
    else alertMessage('Ensure Email, Phones, Emergency Contact is Addressed');
  }, [verifiedMessage]);

  useEffect(() => {
    setContactKeys(contactList ? Object.keys(contactList).map(item => Number(item)) : []);
  }, [contactList]);

  return (
    <Grid container spacing={3} display="block">
      <PatientInformation patient={patient} hideExpand stretchContent />
      {contactKeys
        .map((key, index) => {
          const contact = contactList[Number(key)];
          return (
            <ContactDetail
              hideExpand
              stretchContent
              key={contact.id}
              patient={patient}
              contact={contact}
              contactIndex={index}
            />
          );
        })
        .reverse()}
      {!displayContactForm && (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => setDisplayContactForm(true)}
          >
            Add contact
          </Button>
        </Grid>
      )}
      {displayContactForm && (
        <>
          <Grid item xs={12}>
            <FormHeader header="Add Contact" />
          </Grid>
          <Grid item xs={12}>
            <ContactForm
              closeHandler={() => setDisplayContactForm(false)}
              phoneRequired
              hasDoesntProvideContactField
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}

import React, { useState } from 'react';
import { Button, Popover, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import Divider from 'components/divider';
import IncidentForm from 'containers/tasks/incidents/incident-form';
import { noteTagTypes } from 'constants/lists';
import { PATIENT_INACTIVE } from 'constants/index';
import { Warning } from 'components/icons/icons';
import { useSelector } from 'react-redux';

import { styles } from './note-styles';

const Incident = props => {
  const [anchor, setAnchor] = useState(undefined);
  const {
    disabled,
    classes,
    therapy,
    isTaskSidebar,
    serviceGroups,
    defaultServiceGroup,
    taskType,
    tagTypeId,
    tagResourceId,
    buttonOverride,
    id,
    activeForPatient,
  } = props;
  const patient = useSelector(state => state.patient);
  const taskInactive = (patient.status_id === PATIENT_INACTIVE && !activeForPatient) || disabled;

  const open = Boolean(anchor);

  const openIncident = e => {
    e.stopPropagation();
    setAnchor(e.currentTarget);
  };

  // TODO - Hard code function for now, the reason is in task tab
  // the resource table name is automatically generated based on type, since for
  // intervention and incident the table name are plural, the link doesn't work,
  // i think we need to rename task_interventions
  // to task_intervention for consistency, or store the resource mapping somewhere else
  const convertToResourceTable = type => {
    if (type === 'task_intervention') {
      return 'task_interventions';
    }
    if (type === 'task_incident') {
      return 'task_incidents';
    }
    return type;
  };

  const taskAddedFromData =
    taskType && tagResourceId && tagTypeId !== 1
      ? {
          task_type: convertToResourceTable(taskType),
          task_id: tagResourceId,
        }
      : null;

  const { displayLabel = '' } = noteTagTypes.find(tag => tag.value === tagTypeId) || {};

  let buttonEl = (
    <Button
      variant="outlined"
      className={classNames(classes.button, classes.qreButton)}
      onClick={openIncident}
      id={id}
      disabled={taskInactive}
    >
      <span className={classes.incidentIconContainer}>
        <Warning />
      </span>
      QRE
    </Button>
  );

  if (buttonOverride) {
    buttonEl = (
      <button
        data-qa-id="button-incident"
        style={{
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          outline: 'none',
        }}
        type="button"
        onClick={openIncident}
        disabled={taskInactive}
      >
        {buttonOverride}
      </button>
    );
  }
  if (!window.FEATURES.incident) {
    return null;
  }
  return (
    <>
      {buttonEl}
      <Popover
        classes={{
          paper: classes.incidentPopover,
        }}
        id="intervention-popover"
        open={open}
        anchorEl={anchor}
        onClose={() => {
          setAnchor();
        }}
        onClick={e => {
          e.stopPropagation();
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={classes.qreIncidentForm}>
          <Typography variant="h6">{`New Quality Related Event - ${displayLabel}`}</Typography>
          <Divider />
          <IncidentForm
            therapy={therapy}
            cancel={() => {
              setAnchor();
            }}
            tagTypeId={tagTypeId}
            isTaskSidebar={isTaskSidebar}
            serviceGroups={serviceGroups}
            defaultServiceGroup={defaultServiceGroup}
            taskAddedFromData={taskAddedFromData}
          />
        </div>
      </Popover>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(Incident);

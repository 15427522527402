import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { IMedSyncRowData } from 'models/meds/IMedSync';
import { IState } from 'interfaces/redux/IState';
import { useSelector } from 'react-redux';
import { createElement } from 'react';
import {
  getMedsInSyncNames,
  getNextSyncDate,
  medSyncInConflict,
  parseMedSyncDataFn,
  therapiesSynchronized,
} from './med-sync-utils';
import {
  IMedSyncIconState,
  IMedSyncPreferences,
  MedSyncDisplayStatus,
  MedSyncInConflictTooltip,
  MedSyncNotSynchronizedTooltip,
  MedSyncOptOutTooltip,
  MedSyncUndecidedTooltip,
} from './common';
import { MED_SYNC_STATUS } from '../../../constants';
import { MedSyncTooltipInfo } from './components/medSyncIcon';

export const selectPatient = (state: IState) => state.patient;
export const selectPatientSyncTimeFrame = createSelector(
  [selectPatient],
  (patient: IState['patient']) => patient.sync_time_frame,
);
export const selectTherapies = (state: IState) => state.therapies.data;
export const selectTherapiesList = createSelector([selectTherapies], data => Object.values(data));
export const selectCurrentMedSyncPreferences = createSelector(
  [selectPatient],
  (patient): IMedSyncPreferences => {
    if (patient.med_sync === MED_SYNC_STATUS.OPT_IN) {
      return {
        anchorDate: patient.anchor_date ? moment.utc(patient.anchor_date).toDate() : null,
        syncTimeFrame: (patient.sync_time_frame as number) ?? null,
        nextSyncDate: getNextSyncDate(patient),
        status: patient.med_sync,
        patientId: patient.id,
      };
    }
    return {
      anchorDate: null,
      syncTimeFrame: null,
      nextSyncDate: null,
      status: MED_SYNC_STATUS.UNDECIDED,
      patientId: patient.id,
    };
  },
);

export const selectCurrentMedSyncData = createSelector(
  [selectTherapiesList, selectPatientSyncTimeFrame],
  (therapies, patientSyncTimeFrame) =>
    parseMedSyncDataFn(therapies, patientSyncTimeFrame ?? null) ?? [],
);

const undecidedResult = {
  tooltipTitle: MedSyncUndecidedTooltip,
  displayStatus: MedSyncDisplayStatus.Undecided,
};

const selectCurrentMedSyncDisplayState = createSelector(
  [selectCurrentMedSyncPreferences, selectCurrentMedSyncData],
  (medSyncPreferences, medSyncData): IMedSyncIconState => {
    if (medSyncPreferences.status === MED_SYNC_STATUS.UNDECIDED) {
      return undecidedResult;
    }
    if (medSyncPreferences.status === MED_SYNC_STATUS.OPT_IN) {
      if (!therapiesSynchronized(medSyncData)) {
        return {
          tooltipTitle: MedSyncNotSynchronizedTooltip,
          displayStatus: MedSyncDisplayStatus.NotSynchronized,
        };
      }
      if (medSyncInConflict(medSyncData)) {
        return {
          tooltipTitle: MedSyncInConflictTooltip,
          displayStatus: MedSyncDisplayStatus.InConflict,
        };
      }
      if (medSyncPreferences.anchorDate && medSyncPreferences.syncTimeFrame) {
        return {
          tooltipTitle: createElement(MedSyncTooltipInfo, {
            therapyNames: getMedsInSyncNames(medSyncData),
            anchorDate: medSyncPreferences.anchorDate,
            nextSyncDate: medSyncPreferences.nextSyncDate,
          }),
          displayStatus: MedSyncDisplayStatus.Synchronized,
        };
      }
    }
    if (medSyncPreferences.status === MED_SYNC_STATUS.OPT_OUT) {
      return {
        tooltipTitle: MedSyncOptOutTooltip,
        displayStatus: MedSyncDisplayStatus.OptOut,
      };
    }
    return undecidedResult;
  },
);

export const useMedSyncIconState = (): IMedSyncIconState =>
  useSelector(selectCurrentMedSyncDisplayState);
export const useMedSyncPreferences = (): IMedSyncPreferences =>
  useSelector(selectCurrentMedSyncPreferences);
export const useMedSyncData = (): IMedSyncRowData[] => useSelector(selectCurrentMedSyncData);

import React, { useRef, useState, useCallback, useLayoutEffect } from 'react';
import RadioButton from 'components/form/radio/radio-button';
import RadioGroup from 'components/form/radio/radio-group';
import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  Select,
  MenuItem,
  FormControl,
  Typography,
  Box,
  TextField,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { clearTaskFiltersByKeys } from 'actions/action-filters';
import { addNote, fetchNotes } from 'actions/action-notes';
import { useDebounce } from 'use-debounce';
import { fetchPatient, selectPatientTab, toggleTabRefresh } from 'actions/action-patient';
import { connect, useSelector, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { sortTaskListBy } from 'services/utils/task-service';
import { clearSidebarFilterByKeys, setSidebarFilter } from 'actions/action-sidebar-filters';
import { fetchUserCustomLists } from 'actions/action-user-custom-lists';
import { getClinicalDataTypesMap } from 'services/utils/data-collect';
import {
  THERAPIES,
  STAR,
  AR,
  DEFAULT_AR_TASKS_LIMIT,
  MAX_AR_TASKS_LIMIT,
  FDC,
} from 'constants/index';
import { UserRoles } from 'constants/enums';
import { logger } from 'winston-logger';
import TaskListRow from './task-list-row';
import DateSortedTaskList from './date-sorted-task-list';
import { styles } from '../side-bar-styles';
import TaskListFilterPills from './task-list-filter-pills';
import TaskListHeader from './task-list-header';
import { FillDeliveryConfirmationStatus } from '../../../../interfaces/enums/TaskStatuses/FillDeliveryConfirmationStatus';
import { isTherapyComplete } from '../../../../services/utils/therapy-service';

const debounceTimeMs = 500;

const LoadingIndicator = withStyles(styles, { withTheme: true })(({ classes, type }) => {
  let descriptor = `${type} List`;
  if (type === 'SMS') {
    descriptor = 'Messages';
  } else if (type === STAR) {
    descriptor = 'Patient List';
  } else if (!type) {
    descriptor = '';
  }

  return (
    <tr className={classes.loadingIndicator}>
      <td colSpan={9}>
        <div style={{ marginBottom: '20px' }}> {`Loading ${descriptor}`}</div>
        <CircularProgress />
      </td>
    </tr>
  );
});

const NoItemsMessage = withStyles(styles, { withTheme: true })(({ classes, type }) => {
  let descriptor = 'No Items';
  if (type === 'SMS') {
    descriptor = 'No Messages';
  }

  return (
    <tr className={classes.loadingIndicator}>
      <td colSpan={9}>
        <div style={{ marginBottom: '20px' }}>{`${descriptor} Found`}</div>
      </td>
    </tr>
  );
});

const TaskList = ({
  classes,
  clearSidebarFilterByKeys, // eslint-disable-line no-shadow
  setSidebarFilter, // eslint-disable-line no-shadow
}) => {
  const [selectedUserCustomList, setSelectedUserCustomList] = useState(window.USER.id);
  const patientList = useSelector(state => state.patientList);
  const isLoading = patientList?.loading;
  const tasklistSort = useSelector(state => state.tasklistSort);
  const lookups = useSelector(state => state.lookups);
  const taskStatuses = useSelector(state => state.taskStatuses);
  const sidebarTaskType = useSelector(state => state.sidebarTaskType);
  const sidebarFilters = useSelector(state => state.sidebarFilters.data.task);
  const defaultArLimit = sidebarFilters.arTasksLimit || DEFAULT_AR_TASKS_LIMIT;
  const userList = useSelector(state => state.lookups.users);
  const userCustomLists = useSelector(state => state.userCustomLists);
  const [arTasksLimit, setArTaskLimit] = useState(defaultArLimit);
  const [debouncedArTasksLimit] = useDebounce(arTasksLimit, debounceTimeMs);
  const renderPatientList = useSelector(state => !state.view.leftDrawer);
  const therapyStatuses = useSelector(state => state.therapyStatuses);

  const userCustomListsUser = userCustomLists.users.find(
    user => user.user_id === selectedUserCustomList,
  );
  let userListFiltered = userList
    ? userList.filter(
        user =>
          (user?.active &&
            user?.roles.some(
              userRole =>
                userRole.name === UserRoles.CredentialedLiaison ||
                userRole.name === UserRoles.NonCredentialedLiaison ||
                userRole.name === UserRoles.CredentialedPharmacist ||
                userRole.name === UserRoles.NonCredentialedPharmacist ||
                userRole.name === UserRoles.SiteManager,
            )) ||
          user?.id === window.USER.id,
      )
    : [];
  userListFiltered = userListFiltered.sort((a, b) => {
    const a1 = a.display_name.toLowerCase();
    const b1 = b.display_name.toLowerCase();
    return a1.localeCompare(b1);
  });

  const currentUserCustomList =
    userCustomListsUser?.lists?.filter(item => item.user_id === selectedUserCustomList) || [];

  const dispatch = useDispatch();

  React.useEffect(() => {
    (async () => {
      try {
        if (!userCustomLists.users.some(u => u.user_id === selectedUserCustomList)) {
          dispatch(fetchUserCustomLists(selectedUserCustomList));
        }
      } catch (error) {
        logger.error(error);
      }
    })();
  }, [selectedUserCustomList]);

  React.useEffect(() => {
    setSidebarFilter('task', {
      arTasksLimit: debouncedArTasksLimit,
    });
  }, [debouncedArTasksLimit]);

  const { order, orderBy } = tasklistSort;
  const filteredTaskList = [...patientList.data];
  // auto scroll to selected row when changed
  const containerElement = useRef(null);
  const selectedRow = useCallback(node => {
    if (node !== null && containerElement.current !== null) {
      const containerHeight = containerElement.current.clientHeight;
      const headerOffset = 45;
      const rowOffset = 52;
      containerElement.current.scrollTop =
        node.offsetTop - rowOffset / 2 - (containerHeight - headerOffset) / 2;
    }
  }, []);

  sortTaskListBy(filteredTaskList, orderBy, order, true);
  let { loadedTask: renderedList } = patientList;
  if (sidebarTaskType === STAR) {
    renderedList = STAR;
  }
  const defaultDateSortType = renderedList === THERAPIES ? 'needsby_date' : 'followup_dt';
  const showDateSortedTaskListFields = ['needsby_date', 'followup_dt', null];

  const clinicalDataTypes = useSelector(state => state.lookups.clinicalDataTypes);
  const clinicalDataTypesMap = getClinicalDataTypesMap(clinicalDataTypes);

  const liaisonFields = [];
  const patientFields = [];
  Object.keys(clinicalDataTypesMap).forEach(dataTypeId => {
    const field = clinicalDataTypesMap[dataTypeId];
    if (field.type === 'questionnaire') {
      patientFields.push(dataTypeId);
    } else {
      liaisonFields.push(dataTypeId);
    }
  });

  const [allTasksSwitchState, setAllTasksSwitchState] = useState(!!sidebarFilters.allTasks);

  const handleAllArTasksSwitch = newState => {
    setAllTasksSwitchState(newState);
    setSidebarFilter('task', {
      arTasksLimit: !newState ? arTasksLimit : undefined,
    });
  };

  const handleChangeArTaskLimit = newValue => {
    if (newValue !== arTasksLimit && newValue <= MAX_AR_TASKS_LIMIT && newValue > 0) {
      setArTaskLimit(newValue);
    }
  };

  const PAGE_SIZE = 100;
  const [page, setPage] = useState(1);
  React.useEffect(() => {
    setPage(1);
  }, [patientList]);

  const taskList = (filteredTaskListVar = filteredTaskList) => {
    const handleOnUserCustomList = ({ target }) => {
      setSelectedUserCustomList(target.value);
    };

    const showLoadingMessage = isLoading;
    const listIsSortedByDate = showDateSortedTaskListFields.includes(tasklistSort.orderBy);
    const showNoItemsMessage =
      !isLoading && filteredTaskListVar.length === 0 && renderedList !== STAR;
    const showListType1 = !isLoading && listIsSortedByDate && renderedList !== THERAPIES;
    const showListType2 = !isLoading && (!listIsSortedByDate || renderedList === THERAPIES);
    let items = filteredTaskListVar;
    if (showListType1) {
      const { order, orderBy } = tasklistSort;
      items = sortTaskListBy(filteredTaskListVar, orderBy, order, true, defaultDateSortType);
    }
    if (renderedList === THERAPIES && !sidebarFilters.completed) {
      items = filteredTaskListVar.filter(
        t => !isTherapyComplete(t, therapyStatuses.therapyAdministrationStatuses),
      );
    }
    if (sidebarTaskType === FDC) {
      items = items.sort((x, y) => {
        if (x.status_id === FillDeliveryConfirmationStatus.Packed) {
          return -1;
        }
        return 1;
      });
    }
    items = items.slice(0, page * PAGE_SIZE);

    return (
      <>
        {renderedList === STAR && (
          <Box p={2}>
            <Typography variant="caption">User List</Typography>
            <Box pt={1} />
            <FormControl className={classes.userCustomListSelect}>
              <Select
                labelId="user-custom-list-label"
                id="user-custom-list"
                value={selectedUserCustomList}
                onChange={handleOnUserCustomList}
                placeholder="select user custom list"
                variant="standard"
              >
                {userListFiltered
                  ?.map(item => ({
                    value: item.id,
                    label: item.display_name,
                  }))
                  ?.map(item => (
                    <MenuItem key={`user-custom-list-${item.value}`} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )}
        <Table stickyHeader className={classes.table}>
          <TaskListHeader patientCount={patientList.uniquePatientCount} />
          <TableBody className={classes.tableBody}>
            {showLoadingMessage && <LoadingIndicator type={sidebarTaskType} />}
            {showNoItemsMessage && <NoItemsMessage type={sidebarTaskType} />}
            {showListType1 && (
              <DateSortedTaskList
                objListSort={tasklistSort}
                objList={items}
                userCustomList={currentUserCustomList}
                defaultDateSortType={defaultDateSortType}
                taskType={renderedList}
                selectedRow={selectedRow}
                clinicalDataFields={{ liaisonFields, patientFields }}
                customListId={selectedUserCustomList}
              />
            )}
            {showListType2 && (
              <>
                {items.map(patient => (
                  <TaskListRow
                    key={`filteredTaskList-${patient.id}-${patient.taskType}-${patient.task_id}${
                      patient.sms_id ? `-${patient.sms_id}` : ''
                    }`}
                    patient={patient}
                    selectedRow={selectedRow}
                    clinicalDataFields={{ liaisonFields, patientFields }}
                  />
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </>
    );
  };

  const tableRef = useRef();
  const THRESHOLD = 500; // in pixels... average size of item is 80 pixels...
  // so around 5-6 items before the end of the page

  return (
    renderPatientList && (
      <div
        className={classes.tableContainer}
        ref={tableRef}
        onScroll={() => {
          const currScrollY = tableRef.current.scrollTop + tableRef.current.clientHeight;
          const threshold = tableRef.current.scrollHeight - THRESHOLD;
          const maxPage = Math.ceil(filteredTaskList?.length / PAGE_SIZE);
          const nextPage = page + 1;
          if (currScrollY >= threshold && page < maxPage) {
            setPage(nextPage);
          }
        }}
      >
        <Grid container direction="row" className={classes.filterPills}>
          <TaskListFilterPills
            filters={sidebarFilters}
            lookups={lookups}
            sidebarTaskType={renderedList}
            taskStatuses={taskStatuses}
            onRemoveFilters={(keys, ids, parentKey) =>
              clearSidebarFilterByKeys('task', keys, ids, parentKey)
            }
          />
        </Grid>
        {sidebarTaskType === AR && (
          <Grid container direction="row" className={classes.serviceGroupContainer}>
            <Grid item xs={8}>
              <RadioGroup
                horizontal
                wrap
                onChange={() => handleAllArTasksSwitch(!allTasksSwitchState)}
              >
                <RadioButton value="all_ars" checked={allTasksSwitchState}>
                  All ARs
                </RadioButton>
                <RadioButton value="top_ars" checked={!allTasksSwitchState}>
                  Top ARs
                </RadioButton>
              </RadioGroup>
            </Grid>
            {!allTasksSwitchState && (
              <Grid item xs={4}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography>Show top</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ width: '50px' }}
                      hiddenLabel
                      value={arTasksLimit}
                      width="20px"
                      onChange={event => handleChangeArTaskLimit(event.target.value)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
        {taskList()}
      </div>
    )
  );
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(null, {
    fetchPatient,
    toggleTabRefresh,
    selectPatientTab,
    fetchNotes,
    addNote,
    clearTaskFiltersByKeys,
    clearSidebarFilterByKeys,
    setSidebarFilter,
  }),
)(TaskList);

import { Grid, Tooltip, Button, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CopyToClipboard from 'react-copy-to-clipboard';
import { PatientOverviewCopy } from 'components/icons/icons';
import React from 'react';
import { styles } from './mrn-table-styles';

interface IMrnTableProps {
  classes: { [key: string]: any };
  mrnData: { source_patient_id: string; source_name: string }[];
}

const WrappedComponents = React.forwardRef((props: any, ref: any): JSX.Element => {
  return (
    <CopyToClipboard text={props.text}>
      <Button ref={ref} {...props} style={{ padding: '4px 5px', minWidth: 'auto' }}>
        <PatientOverviewCopy />
      </Button>
    </CopyToClipboard>
  );
});

const MrnTable = (props: IMrnTableProps): JSX.Element => {
  const { mrnData, classes } = props;

  const mrnCount = mrnData.length;
  const mrnTable = (
    <Grid item container xs={6} className={classes.mrnDataContainer}>
      <table className={classes.mrnTable}>
        <thead className={classes.mrnTableHeader}>
          <tr>
            <th>MRN ({mrnCount})</th>
          </tr>
        </thead>
        <tbody>
          {mrnData.map((item): JSX.Element => {
            return (
              <tr key={item.source_patient_id}>
                <td>
                  <Grid className={classes.rowAlignment}>
                    <Grid>
                      <Typography className={classes.mrnValue} display="inline">
                        {item.source_patient_id}
                      </Typography>
                      <Typography className={classes.mrnSource} display="inline">
                        {' '}
                        ({item.source_name})
                      </Typography>
                    </Grid>
                    <Grid>
                      <Grid className={classes.copyToClipboardButtonMargin}>
                        <Tooltip title="Copy to clipboard">
                          <WrappedComponents text={item.source_patient_id} />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Grid>
  );
  return mrnTable;
};

export default withStyles(styles, { withTheme: true })(MrnTable);

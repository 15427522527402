import { createSelector } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { parseFullName, parseFirstLastName } from 'utils/name';

export const usePatient = patient => {
  const [patientFullName, setPatientFullName] = useState('');
  const [patientFirstLastName, setPatientFirstLastName] = useState('');

  useEffect(() => {
    setPatientFullName(parseFullName(patient));
    setPatientFirstLastName(parseFirstLastName(patient));
  }, [patient]);

  return { patientFullName, patientFirstLastName };
};

const getPatientName = patient => ({
  patientFullName: parseFullName(patient),
  patientFirstLastName: parseFirstLastName(patient),
});
const selectPatient = state => state.patient;
export const selectPatientId = state => state.selectedPatientId;
export const selectPatientName = createSelector([selectPatient], getPatientName);

import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import { expandTheme } from 'utils/theme-util';

export const useStyles = makeStyles((theme: Theme) => {
  const { palette } = expandTheme(theme);

  return {
    highlightUserStar: {
      position: 'absolute',
      width: theme.spacing(1.8),
      height: theme.spacing(1.8),
      backgroundColor: palette.primary.rollingStone,
      display: 'flex',
      borderRadius: '50%',
      alignItems: 'center',
      color: palette.primary.white,
      justifyContent: 'center',
      fontSize: '11px',
    },
  };
});

import React, { useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import { Typography, Grid, Button, Paper, Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import compose from 'recompose/compose';
import { activeInactive10 } from 'constants/lists';
import { getSpecialPopulationsFiltered } from 'services/utils/special-populations-service';
import { PATIENT_SPECIAL_POPULATION_STATUS_ACTIVE } from 'constants/index';
import { ReactSelect } from 'components/form/field/react-select';
import PatientSpecialPopulationForm from './patient-special-population-form';
import { styles } from '../patient-clinical-styles';
import PatientSpecialPopulationsTable from './patient-special-population-table';

export const RENDERING_SECTIONS = {
  SPECIAL_POPULATIONS_LIST: 'SPECIAL_POPULATIONS_LIST',
  ADD_SPECIAL_POPULATION_FORM: 'ADD_SPECIAL_POPULATION_FORM',
};

function SpecialPopulationsList(props) {
  const {
    classes,
    minimal,
    options,
    readOnly = false,
    cancelAddForm = null,
    postSave = null,
    stretched = false,
  } = props;

  const lookups = useSelector(state => state.lookups);
  const specialPopulations = useSelector(state => state.specialPopulations.specialPopulationsList);

  const [statusFilter, setStatusFilter] = useState([PATIENT_SPECIAL_POPULATION_STATUS_ACTIVE]);

  const opts = {
    showSpecialPopulationsStatus: true,
    showVerificationPanel: true,
    showTopActionsBar: true,
    showSpecialPopulationsList: true,
    showAddSpecialPopulationForm: false,
    showSpecialPopulationStatus: true,
    renderingOrder: [
      RENDERING_SECTIONS.ADD_SPECIAL_POPULATION_FORM,
      RENDERING_SECTIONS.SPECIAL_POPULATIONS_LIST,
    ],
    ...options,
  };

  const [displayAddSpecialPopulation, setDisplayAddSpecialPopulation] = useState(false);

  const handleSpecialPopulationStatusChange = e => {
    if (e && e.length > 0) {
      setStatusFilter(e.map(o => o.value));
    } else {
      setStatusFilter(null);
    }
  };

  const specialPopulationsToPass = getSpecialPopulationsFiltered(specialPopulations, statusFilter);

  const renderSpecialPopulationsList = () => (
    <Grid item xs={12}>
      <Paper elevation={2} className={classes.allergyContainer}>
        <PatientSpecialPopulationsTable
          specialPopulations={specialPopulationsToPass}
          readOnly={readOnly}
        />
      </Paper>
    </Grid>
  );

  const renderAddSpecialPopulationForm = () => (
    <Grid xs={12}>
      <Paper elevation={2} className={classes.addAllergyFormContainer}>
        <Grid container>
          <Grid item xs={12} className={classes.addElementTitleWrapper}>
            <Typography className={classes.addElementTitle}>Add New Special Population</Typography>
          </Grid>
          <Grid item xs={12} className={classes.smallPaddedContainer}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <PatientSpecialPopulationForm
              submitCallback={postSave}
              lookups={lookups}
              cancelHandler={() =>
                cancelAddForm ? cancelAddForm() : setDisplayAddSpecialPopulation(false)}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );

  const renderInOrder = () => (
    <>
      {opts.renderingOrder.map(section => {
        switch (section) {
          case RENDERING_SECTIONS.ADD_SPECIAL_POPULATION_FORM: {
            return (
              (displayAddSpecialPopulation || opts.showAddSpecialPopulationForm) &&
              renderAddSpecialPopulationForm()
            );
          }
          case RENDERING_SECTIONS.SPECIAL_POPULATIONS_LIST: {
            return opts.showSpecialPopulationsList && renderSpecialPopulationsList();
          }
          default: {
            return null;
          }
        }
      })}
    </>
  );

  const headerClassname = classNames(classes.allergyHeader, {
    [classes.allergyHeaderStretched]: stretched,
  });

  return (
    <Grid
      container
      alignItems="center"
      className={(minimal || stretched) ? null : classes.clinicalContainer}
      style={{ marginBottom: '30px' }}
    >
      {opts.showTopActionsBar && (
        <>
          <Grid item xs={2}>
            {!minimal && (
              <Typography variant="h6" component="span" className={headerClassname}>
                Special Populations
              </Typography>
            )}
          </Grid>
          <Grid item xs={10}>
            <Grid
              container
              justifyContent="flex-end"
              className={classes.allergyHeader}
              alignItems="center"
            >
              <Grid item xs={5} />
              <Grid item xs={4}>
                {opts.showSpecialPopulationStatus && (
                  <ReactSelect
                    name="specialPopulationStatus"
                    label="Special Population Status"
                    handleOnChange={e => handleSpecialPopulationStatusChange(e)}
                    defaultValues={[
                      { value: PATIENT_SPECIAL_POPULATION_STATUS_ACTIVE, label: 'Active' },
                    ]}
                    fields={activeInactive10}
                    className={classes.statusFilter}
                  />
                )}
              </Grid>
              {!readOnly && (
                <Grid item xs={3}>
                  <Grid container justifyContent="flex-end">
                    <Button
                      variant="outlined"
                      className={classes.rowButton}
                      disableElevation
                      onClick={() => setDisplayAddSpecialPopulation(true)}
                      disabled={displayAddSpecialPopulation}
                    >
                      <Typography variant="button">+ Add</Typography>
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
      {renderInOrder()}
    </Grid>
  );
}

export default compose(withStyles(styles, { withTheme: true }))(SpecialPopulationsList);

import React, { useState, useEffect } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid, Button } from '@mui/material';
import { SmallSearch } from 'components/icons/icons';
import AutocompleteLabel from 'components/form/field/autocomplete-label';
import AutocompleteMinigrid from 'components/form/field/render-minigrid-autocomplete';
import { fetchClinics } from 'services/utils/therapy-service';
import NpiLookup from '../npi-lookup';
import { styles } from '../edit-patient-therapy/edit-patient-therapy-styles';

export default withStyles(styles)(({ classes, therapy, input, label, ...other }) => {
  const [npiOpen, setNpiOpen] = useState(false);
  const [clinic, setClinic] = useState(
    input.value
      ? {
          ...input.value,
          id: input.value.npi,
          label: input.value.name,
        }
      : {},
  );

  useEffect(() => {
    input.onChange(clinic);
  }, [input, clinic]);

  return (
    <Grid container spacing={3} alignItems="flex-start">
      {npiOpen && (
        <NpiLookup
          open
          title="Prescribing Clinic"
          values={{
            taxonomy: '',
            organization: clinic.name || therapy.referring_clinic_name,
          }}
          handleChange={c => {
            setClinic({
              ...c,
              id: c.npi,
              label: c.name,
            });
          }}
          handleClose={() => {
            setNpiOpen(false);
          }}
        />
      )}
      <Grid item xs={6} className={classes.npiLookupButtonContainer}>
        <AutocompleteMinigrid
          name={input.name}
          label={input.label || label}
          fetchOptions={searchText =>
            // eslint-disable-next-line no-confusing-arrow
            fetchClinics(searchText, false, null).then(result =>
              result.data
                ? result.data.clinics.map(c => ({
                    ...c,
                    id: c.npi,
                    label: c.name,
                  }))
                : [],
            )
          }
          hint="Search by Clinic Name or NPI"
          onChange={c => {
            setClinic(c);
          }}
          columnsToShow={{
            name: 'Name',
            address: 'Address',
            npi: 'NPI',
            is_internal: 'Is Internal',
            id: 'ID',
          }}
          hasMinSearchLength
          input={{
            onChange: newClinic => setClinic(newClinic),
            value: clinic,
          }}
          {...other}
        />
        <Button className={classes.npiLookupButton} onClick={() => setNpiOpen(true)}>
          <SmallSearch />
        </Button>
      </Grid>
      <Grid item xs={3}>
        <AutocompleteLabel
          idValue={clinic.npi}
          defaultIdValue={therapy.referring_clinic_npi}
          label="Clinic NPI"
        />
      </Grid>
    </Grid>
  );
});

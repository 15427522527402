import { StringOption, NumberOption } from 'interfaces/forms/types';

export enum DynamicFields {
  FREQUENCY = 'frequency',
  FREQUENCY_UNIT = 'frequencyUnit',
  FREQUENCY_TYPE = 'frequencyType',
  DC_ITEM = 'dcItem',
}

export interface IcdFrequencyItem {
  frequency: string;
  frequencyUnit: number;
  frequencyType: string;
  dcItem: number[];
}

export interface IFrequency extends IcdFrequencyItem {
  id: number;
}

export interface IIcdItem {
  specialtyId: number;
  icdCodes: string[];
  mainFrequency: string;
  dcItem: number[];
  frequencyData: IcdFrequencyItem[];
}

export interface IIcdFrequencyItemToEdit {
  id: number;
  frequency: StringOption | null;
  frequencyUnit: NumberOption | null;
  frequencyType: StringOption | null;
  dcItem: NumberOption[] | null;
}

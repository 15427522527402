import momenttz from 'moment-timezone';
import HTTP from '../../services/http';
import { Timezone } from '../time/timezone';
import { initGA } from '../../services/utils/analytics';
import { DUR, MR } from '../../constants/index';
import { windowFeatureIsEnabled } from '../../config/window-features';
import { supportedTasksTabTypes } from '../../constants/lists';

/**
 * Class that loads the configuration of the application during
 * start-up phase
 *
 * @export
 * @class Config
 */
export class Config {
  /**
   * Load application features config in each environment
   *
   * @static
   * @returns {Promise<void>}
   * @memberof Config
   */
  static async loadAppFeatures(): Promise<void> {
    await HTTP.get('/config/features', {}).then(res => {
      const features = res.data;
      (window as any).FEATURES = features;
      supportedTasksTabTypes.push(DUR);
      if (!windowFeatureIsEnabled('hide_mr')) {
        supportedTasksTabTypes.push(MR);
      }
    });
  }

  /**
   * Load Services and SQL Build Information into the DOM
   *
   * @static
   * @returns {Promise<void>}
   * @memberof Config
   */
  static async getBuildInfo(): Promise<void> {
    await HTTP.get('/config/build_info', {}).then(res => {
      const servicesCommit = res.data?.servicesCommit;
      const servicesBranch = res.data?.servicesBranch;
      const servicesBuildDate = res.data?.servicesBuildDate;
      const socketIOCommit = res.data?.socketioCommit;
      const socketIOBranch = res.data?.socketioBranch;
      const socketIOBuildDate = res.data?.socketioBuildDate;
      const sqlCommit = res.data?.sqlCommit;
      const sqlBranch = res.data?.sqlBranch;
      const sqlBuildDate = res.data?.sqlBuildDate;
      const sqlDatabaseName = res.data?.sqlDatabase;
      const lambdaCommit = res.data?.lambdaCommit;
      const lambdaBranch = res.data?.lambdaBranch;
      const lambdaBuildDate = res.data?.lambdaBuildDate;
      const servicesCommitElement = document.querySelector('meta[name="arbor-services-commit"]');
      servicesCommitElement?.setAttribute('content', servicesCommit);
      const servicesBranchElement = document.querySelector('meta[name="arbor-services-branch"]');
      servicesBranchElement?.setAttribute('content', servicesBranch);
      const servicesBuildDateElement = document.querySelector(
        'meta[name="arbor-services-build-date"]',
      );
      servicesBuildDateElement?.setAttribute('content', servicesBuildDate);
      const socketIOCommitElement = document.querySelector('meta[name="arbor-socketio-commit"]');
      socketIOCommitElement?.setAttribute('content', socketIOCommit);
      const socketIOBranchElement = document.querySelector('meta[name="arbor-socketio-branch"]');
      socketIOBranchElement?.setAttribute('content', socketIOBranch);
      const socketIOBuildDateElement = document.querySelector(
        'meta[name="arbor-socketio-build-date"]',
      );
      socketIOBuildDateElement?.setAttribute('content', socketIOBuildDate);
      const sqlCommitElement = document.querySelector('meta[name="arbor-sql-commit"]');
      sqlCommitElement?.setAttribute('content', sqlCommit);
      const sqlBranchElement = document.querySelector('meta[name="arbor-sql-branch"]');
      sqlBranchElement?.setAttribute('content', sqlBranch);
      const sqlBuildDateElement = document.querySelector('meta[name="arbor-sql-build-date"]');
      sqlBuildDateElement?.setAttribute('content', sqlBuildDate);
      const sqlDbNameElement = document.querySelector('meta[name="arbor-sql-db-name"]');
      sqlDbNameElement?.setAttribute('content', sqlDatabaseName);
      const lambdaCommitElement = document.querySelector('meta[name="arbor-lambda-commit"]');
      lambdaCommitElement?.setAttribute('content', lambdaCommit);
      const lambdaBranchElement = document.querySelector('meta[name="arbor-lambda-branch"]');
      lambdaBranchElement?.setAttribute('content', lambdaBranch);
      const lambdaBuildDateElement = document.querySelector('meta[name="arbor-lambda-build-date"]');
      lambdaBuildDateElement?.setAttribute('content', lambdaBuildDate);
    });
  }

  /**
   * Load current user's metadata
   *
   * @static
   * @returns {Promise<void>}
   * @memberof Config
   */
  static async loadUserMetadata(): Promise<void> {
    const {
      data: { user },
    } = await HTTP.get('/users/me', {});
    (window as any).USER = user;
  }

  /**
   * Load customer's current timezone
   *
   * @static
   * @returns {Promise<void>}
   * @memberof Config
   */
  static async loadCustomerTimezone(): Promise<void> {
    const defaultTimezone = momenttz.tz.guess();
    if (!Timezone.instance) {
      const timezone: string = await HTTP.get('/customers/me/timezone', {}, true) // eslint-disable-line
        .then((response: any) =>
          response ? response.data.customerTimezone.toString() : defaultTimezone,
        )
        .catch((error: any) => defaultTimezone);
      Timezone.instance = new Timezone(timezone);
    }
  }

  /**
   * Initialize all on-load configurations for ARBOR
   *
   * @static
   * @returns {Promise<void>}
   * @memberof Config
   */
  static async init(): Promise<void> {
    await this.loadCustomerTimezone();
    await Promise.all([this.getBuildInfo(), this.loadAppFeatures(), this.loadUserMetadata()]).then(
      () => {
        initGA((window as any).USER.id);
      },
    );
  }
}

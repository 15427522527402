import React from 'react';
import ConfirmationDialogPanel from 'components/form/confirmation/confirmation-dialog-panel';
import { Typography } from '@mui/material';

const DocumentConfirmationDialog = ({
  dialogCancel,
  dialogContinue,
  pendingDocumentLoss,
  lostData,
}) => {
  const confirmationDialogContent = () => (
    <>
      <Typography gutterBottom>If you continue you will lose the following documents:</Typography>
      {lostData.map(ld => (
        <Typography>{ld}</Typography>
      ))}
    </>
  );

  return (
    <ConfirmationDialogPanel
      open={pendingDocumentLoss}
      title="Wait! You might lose some data."
      content={confirmationDialogContent()}
      backdropClick={dialogCancel}
      cancelText="Cancel"
      continueText="Continue"
      onCancel={dialogCancel}
      onContinue={dialogContinue}
    />
  );
};

export default DocumentConfirmationDialog;

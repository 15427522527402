import {
  TaskFCDispensingModalActionType as IType,
  ITaskFCDispensingModalAction as IAction,
} from '../actions/action-task-fc-dispensing-modal';
import { ITaskFCDispensingModal as IState } from '../interfaces/redux/ITaskFCDispensingModal';

/* eslint-disable default-param-last */

const initialState = {
  visible: false,
};

export const taskFCDispensingModal = (state: IState = initialState, { type }: IAction) => {
  switch (type) {
    case IType.open:
      return {
        ...state,
        visible: true,
      };

    case IType.resetState:
      return initialState;

    default:
      return state;
  }
};

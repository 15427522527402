import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

const styles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    mainWrapper: {
      padding: theme.spacing(4),
    },
    mainWrapperStretched: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    modalContentWrapper: {
      position: 'absolute',
      width: theme.spacing(150),
      backgroundColor: palette.primary.background,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      borderRadius: theme.spacing(0.6),
      overflow: 'auto',
      maxHeight: '95vh',
    },
    formTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(3),
    },
    addButtonWrapper: {
      textAlign: 'right',
      padding: theme.spacing(1),
    },
  });
};

export { styles };

import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import PoweredBy from '../../components/powered-by';
import Paragraph from '../../components/paragraph';
import Button from '../../components/button';
import DobInput from '../../components/dob-input';

const InitialPage = ({
  onNext,
  store,
  updateDob,
  dobValue,
  hasBirthDateError,
  classes,
  remainingAuthAttempts,
}) => (
  <Box className={classes.container}>
    <Grid container spacing={3.5}>
      <Grid container item xs={12}>
        <Grid item xs={12} data-qa-id="title">
          <Paragraph type="title">It&apos;s time for your refill</Paragraph>
        </Grid>
        <Grid item xs={12}>
          <Box my={0} component="hr" width={250} className={classes.divider} />
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={3.25}>
        <Grid item xs={12} data-qa-id="sub-title">
          <Paragraph>Please confirm the patient&apos;s</Paragraph>
          <Paragraph>date of birth to continue</Paragraph>
        </Grid>
        <Grid item xs={12}>
          <DobInput
            updateDob={updateDob}
            dobValue={dobValue}
            {...(hasBirthDateError && { error: true })}
          />
        </Grid>
        <Grid item xs={12} marginBottom={6} data-qa-id="notes">
          {!hasBirthDateError ? (
            <>
              {remainingAuthAttempts ? (
                <Typography
                  type="subText"
                  className={classes.failedAttemptsMessage}
                  data-qa-id="attempts"
                >
                  {`You have ${remainingAuthAttempts} attempt${
                    remainingAuthAttempts > 1 ? 's' : ''
                  } left.`}
                </Typography>
              ) : null}
              <Paragraph type="subText">Note - After 3 unsuccessful</Paragraph>
              <Paragraph type="subText">attempts you will not be able</Paragraph>
              <Paragraph type="subText">to continue.</Paragraph>
            </>
          ) : (
            <>
              <Paragraph type="subText">The date of birth does not match</Paragraph>
              <Paragraph type="subText">your records. A clinic representative</Paragraph>
              <Paragraph type="subText">will contact you soon for</Paragraph>
              <Paragraph type="subText">completing your fill coordination.</Paragraph>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
    <Box textAlign="center">
      <Grid container item xs={12} spacing={6}>
        <Grid item xs={12}>
          <Button
            className={classes.button}
            {...(hasBirthDateError ? { disabled: true } : { onClick: () => onNext() })}
            data-qa-id="start"
          >
            Start
          </Button>
        </Grid>

        <Grid item xs={12}>
          <PoweredBy pharmacyInfo={store.pharmacyInfo} />
        </Grid>
      </Grid>
    </Box>
  </Box>
);

export default InitialPage;

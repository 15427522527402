import { FA_CHART_REVIEW } from 'constants/index';
import { convertToArborDate } from 'models/time/arbor-date';
import { isDcTransitioningToIntervening } from 'services/utils/data-collect';

const safeNumber = value => (Number.isNaN(Number(value)) ? 0 : Number(value));

const safeArray = value => (Array.isArray(value) ? value : []);

const operate = (config, providers, formValues, fieldId, fieldValue, operator, value) => {
  let res;
  switch (operator) {
    case 'hasValues': {
      const fieldConfig = config.fields.find(it => it.id === fieldId);
      const provider = providers[fieldConfig.provider];
      return provider && provider.length > 0;
    }
    case 'diagnosisCheck': {
      return Array.isArray(value)
        ? value.includes(providers.diagnosisCode)
        : providers.diagnosisCode === value;
    }
    case 'isSpecialtyEquals': {
      return (providers.isSpecialtyTherapy || false) === value;
    }
    case 'roleDifferentFrom': {
      return !(providers.auth?.currentUser.role === value);
    }
    case 'featureFlagOn': {
      return window.FEATURES[value];
    }
    case 'featureFlagOff': {
      return !window.FEATURES[value];
    }
    case 'methodIsChartReview': {
      return (formValues.method ? formValues.method === FA_CHART_REVIEW : false) === value;
    }
    case 'welcomeKitSent': {
      if (providers.patient) {
        return !providers.patient.welcome_kit_sent;
      }
      return value;
    }
    case 'privacyPolicySent': {
      if (providers.patient) {
        return !providers.patient.privacy_policy_sent;
      }
      return value;
    }
    case 'isDirty': {
      const fieldConfig = config.allFields.find(it => it.id === fieldId);
      const currentValue = formValues[fieldConfig.property];
      const initialValue =
        providers.initialValues[fieldConfig.property] || providers.task[fieldConfig.property];
      if (fieldConfig.component === 'date' || fieldConfig.component === 'calendar') {
        return (
          convertToArborDate(currentValue).getCustomerDate(true) !==
          convertToArborDate(initialValue).getCustomerDate(true)
        );
      }
      if (fieldConfig.component === 'datetime') {
        return (
          convertToArborDate(currentValue).getCustomerDatetime(true) !==
          convertToArborDate(initialValue).getCustomerDatetime(true)
        );
      }
      return currentValue !== initialValue;
    }
    case 'eq':
      return fieldValue === value;
    case 'neq':
      return fieldValue !== value;
    case 'gt':
      return safeNumber(fieldValue) > safeNumber(value);
    case 'lt':
      return safeNumber(fieldValue) < safeNumber(value);
    case 'gte':
      return safeNumber(fieldValue) >= safeNumber(value);
    case 'lte':
      return safeNumber(fieldValue) <= safeNumber(value);
    case 'hasLength':
      return Object.keys(fieldValue).length > 0;
    case 'sizeAtLeast':
      return fieldValue && fieldValue.length >= value;
    case 'sizeAtMost':
      return fieldValue && fieldValue.length <= value;
    case 'set':
    case 'defined':
      return !!fieldValue;
    case 'unset':
    case 'notDefined':
    case 'null':
      return fieldValue === undefined || fieldValue === null;
    case 'notNull':
      return fieldValue !== undefined && fieldValue !== null;
    case 'in':
    case 'includes':
      return safeArray(value).includes(fieldValue);
    case 'has':
      return safeArray(fieldValue).includes(value);
    case 'itemEndsWith':
      return !!safeArray(fieldValue).find(it => String(it).endsWith(value));
    case 'commaIncludes':
      return String(fieldValue || '')
        .split(',')
        .includes(String(value || ''));
    case 'nin':
      return !safeArray(value).includes(fieldValue);
    case 'empty':
      return !fieldValue || fieldValue.length === 0;
    case 'truthy':
      return Boolean(fieldValue);
    case 'falsy':
      return !fieldValue;
    case 'statusIn':
      return safeArray(value).includes(providers.task.status_id);
    case 'jsonEvaluator': {
      const jsonFormValues = (fieldValue || {}).values || {};
      const [jsonFieldId, jsonOperator, jsonValue] = value || [];
      return operate(
        config,
        providers,
        jsonFormValues,
        jsonFieldId,
        jsonFormValues[jsonFieldId],
        jsonOperator,
        jsonValue,
      );
    }
    case 'isDcTransitioningToIntervening': {
      return isDcTransitioningToIntervening(formValues) === value;
    }
    case 'and':
      res = true;
      value.forEach(op => {
        // eslint-disable-next-line
        res = res && evaluateCondition(config, providers, op, formValues);
      });
      return res;
    case 'or':
      res = false;
      value.forEach(op => {
        // eslint-disable-next-line
        res = res || evaluateCondition(config, providers, op, formValues);
      });
      return res;
    default:
      return false;
  }
};

export const evaluateCondition = (config, providers, condition, formValues) => {
  if (!formValues) {
    return false;
  }
  if (typeof condition === 'object') {
    if (condition.id) {
      // eslint-disable-next-line
      condition = config.conditions.find(it => it.id === condition.id).condition;
    }
    if (condition.and) {
      return operate(config, providers, formValues, null, null, 'and', condition.and);
    }
    if (condition.or) {
      return operate(config, providers, formValues, null, null, 'or', condition.or);
    }
    const { field_id: fieldId, operator, value } = condition;
    const field = config.fields.find(it => it.id === fieldId);
    const fieldValue = field ? formValues[field.property] : null;
    return operate(config, providers, formValues, fieldId, fieldValue, operator, value);
  }
  return !!condition;
};

export const privateMethodsForTests = {
  safeNumber,
  safeArray,
  operate,
};

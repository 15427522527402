export const buildProp = (element, props) => {
  const listOfProps = Array.isArray(props) ? props : [props];
  return listOfProps.reduce((acc, prop) => `${acc}${element[prop]}`, '');
};

export const convertListToMap = (list, props, ignoreOrder) =>
  list.reduce((acc, item, index) => {
    const key = buildProp(item, props);
    acc[key] = {
      ...item,
      order: ignoreOrder ? item.order : index,
    };
    return acc;
  }, {});

export const convertMapToList = map => Object.keys(map).map(it => map[it]);

export const getReducerDataInitialState = aditionalProperties => ({
  loading: false,
  loaded: false,
  data: {},
  error: false,
  ...aditionalProperties,
});

export const addElementToReducerData = (state, element) => ({
  ...state,
  data: {
    ...state.data,
    [element.id]: {
      ...element,
      order: Object.keys(state.data).length,
    },
  },
});

export const editElementInReducerData = (state, element) => ({
  ...state,
  data: {
    ...state.data,
    [element.id]: {
      ...element,
      order: state.data[element.id].order,
    },
  },
});

export const editElementPropertiesInReducerData = (state, id, values) => ({
  ...state,
  data: {
    ...state.data,
    [id]: {
      ...state.data[id],
      ...values,
    },
  },
});

export const getMedicationStrengthStr = medication => {
  let strengthStr = '';
  if (medication.strength) {
    strengthStr += `${medication.strength}`;
  }
  if (medication.strength_unit_of_measure) {
    strengthStr += ` ${medication.strength_unit_of_measure}`;
  }
  if (medication.dosage_form) {
    strengthStr += ` ${medication.dosage_form}`;
  }
  if (medication.route_of_administration) {
    strengthStr += ` ${medication.route_of_administration}`;
  }
  return strengthStr;
};

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { grey } from '@mui/material/colors';

export const styles = (theme: Theme) => {
  const colorPalette = theme.palette.primary as any;

  return createStyles({
    expandTextButton: {
      'color': colorPalette.patina,
      'fontWeight': 'bold',
      'paddingBottom': 20,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    label: {
      fontSize: 12,
      lineHeight: '15px',
      color: colorPalette.grey12,
      paddingBottom: 6,
    },
    value: {
      fontSize: 14,
      lineHeight: '15px',
      color: colorPalette.steelGray,
      wordBreak: 'break-all',
    },
    infoRow: {
      paddingBottom: 20,
    },
    divider: {
      border: `1px solid ${grey[300]}`,
      width: '100%',
      marginBottom: 20,
    },
  });
};

import { createStyles } from '@mui/styles';

export const styles = createStyles({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    width: '100%',
    fontSize: 18,
    textAlign: 'center',
  },
  subContainer: {
    flex: 1,
    overflowY: 'auto',
    justifyContent: 'center',
    display: 'flex',
    padding: 0,
  },
});

import React, { useImperativeHandle, useState, forwardRef, ForwardRefRenderFunction } from 'react';
import { Typography, Dialog, DialogTitle } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { buildQaId } from 'utils/build-qa-id';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { useDispatch } from 'react-redux';
import { QA_ID } from './constants';
import { ClinicalItemProtocolFormModalStyles } from './styles';

import { ClinicalItemProtocolModalInnerForm } from './inner-form';

export type IClinicalItemProtocolFormModalProps = {
  onCancel?: () => void;
  onSuccess: () => void;
  protocolType: string | null;
  protocolInfo: any | undefined;
};

export interface IClinicalItemProtocolFormModalRef {
  setOpen(value: boolean): void;
}

const useStyles = makeStyles(ClinicalItemProtocolFormModalStyles);

const qaIdBuilder = buildQaId(QA_ID);
const ClinicalItemProtocolFormModal: ForwardRefRenderFunction<
  IClinicalItemProtocolFormModalRef,
  IClinicalItemProtocolFormModalProps
> = (props, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { onCancel, onSuccess, protocolType, protocolInfo, ...otherProps } = props;

  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    setOpen,
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseWrapper = (handler?: () => void) => {
    return () => {
      handleClose();
      if (handler) {
        handler();
      }
    };
  };

  const onError = (msg: string): void => {
    dispatch(notifyError(msg));
  };

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      data-qa-id={qaIdBuilder('root')}
      maxWidth="md"
    >
      <DialogTitle className={classes.titleWrp}>
        <Typography variant="h5" className={classes.title}>
          {protocolInfo === undefined
            ? 'Add Clinical Item Protocol'
            : `Edit ${protocolInfo.clinical_item_name}`}
        </Typography>
      </DialogTitle>
      <ClinicalItemProtocolModalInnerForm
        onCancel={handleCloseWrapper(onCancel)}
        onSuccess={onSuccess}
        onError={onError}
        protocolType={protocolType}
        protocolInfo={protocolInfo}
      />
    </Dialog>
  );
};

export const ClinicalItemProtocolMainModal = forwardRef(ClinicalItemProtocolFormModal);

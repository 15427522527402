import React, { useState, useEffect } from 'react';
import withStyles from '@mui/styles/withStyles';
import {
  Typography,
  Grid,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorIcon from '@mui/icons-material/Error';
import { getUserById } from 'services/utils/users-service';
import { useSelector } from 'react-redux';
import { sortMedications, linkTherapiesMedications } from 'services/utils/medication-service';
import { getActionedByUserText } from 'utils/user-utils';

import compose from 'recompose/compose';
import MedicationList from 'containers/patient/clinical/medication-list/medication-list';
import PatientAllergiesList from 'containers/patient/clinical/allergies/patient-allergies-list';
import { MedicationStatus, AllergyStatusEnum } from 'constants/enums';
// eslint-disable-next-line max-len
import PatientSpecialPopulations from 'containers/patient/clinical/special-populations/patient-special-population-list';

import {
  MEDICATION_THERAPY_STATUS_ACTIVE,
  VERIFICATION_STATUS_VERIFIED,
  VERIFICATION_STATUS_REVERIFIED,
} from 'constants/index';

import VaccinationsTable from 'components/vaccinations/table';
import ProblemsList from 'components/problems/container';
import { styles } from './drug-utilization-review-styles';

const NEED_REVERIFY = 'Need Reverify';
const DATE_FORMAT = 'MM/DD/YYYY';

export const DrugUtilizationReviewInteractorLists = props => {
  const {
    classes,
    readOnly,
    allergies,
    medicationList,
    medicationGroups,
    showVaccinations = false,
    vaccinations = [],
    problems = [],
    hideMedicationChildren = false,
    isDurOnCompletedState,
  } = props;

  const patient = useSelector(state => state.patient);

  const [expandedMedications, setExpandedMedications] = useState(false);
  const [expandedAllergies, setExpandedAllergies] = useState(false);
  const [expandedSpecialPopulations, setExpandedSpecialPopulations] = useState(false);

  const [expandedVaccinations, setExpandedVaccinations] = useState(false);
  const [expandedProblems, setExpandedProblems] = useState(false);
  const [medicationGroupsWithTherapies, setMedicationGroupsWithTherapies] = useState([]);
  const [medicationsWithTherapies, setMedicationsWithTherapies] = useState([]);

  const therapyList = useSelector(state => state.therapies.data);
  const specialPopulations = useSelector(state => state.specialPopulations.specialPopulationsList);

  const users = useSelector(state => state.lookups.users);

  useEffect(() => {
    setMedicationGroupsWithTherapies(
      (sortMedications(linkTherapiesMedications(medicationGroups, therapyList)) || []).filter(
        m => m.active === MEDICATION_THERAPY_STATUS_ACTIVE,
      ),
    );
  }, [medicationGroups, therapyList]);

  useEffect(() => {
    setMedicationsWithTherapies(
      (linkTherapiesMedications(medicationList, therapyList) || []).filter(
        m => m.active === MEDICATION_THERAPY_STATUS_ACTIVE,
      ),
    );
  }, [medicationList, therapyList]);

  const toggleExpandedMedications = () => {
    setExpandedMedications(!expandedMedications);
  };

  const toggleExpandedAllergies = () => {
    setExpandedAllergies(!expandedAllergies);
  };
  const toggleExpandedSpecialPopulations = () => {
    setExpandedSpecialPopulations(!expandedSpecialPopulations);
  };

  const toggleExpandedVaccinations = () => {
    setExpandedVaccinations(!expandedVaccinations);
  };

  const toggleExpandedProblems = () => {
    setExpandedProblems(!expandedProblems);
  };

  const medComponent = () => (
    <Grid item xs={12}>
      <MedicationList
        patient={patient}
        showMedicationList
        medicationList={medicationsWithTherapies}
        overwriteGroups={medicationGroupsWithTherapies}
        isLoading={false}
        minimal
        options={{
          showTableViewToggle: false,
          showMedicationStatus: false,
          showVerificationPanel: true,
        }}
        readOnly={readOnly || !!isDurOnCompletedState}
        hideChildren={hideMedicationChildren}
      />
    </Grid>
  );

  const allergiesComponent = isVerified => (
    <PatientAllergiesList
      minimal
      options={{
        showAllergyStatus: false,
        showVerificationPanel: !isVerified,
      }}
      readOnly={readOnly || !!isDurOnCompletedState}
      allergies={allergies}
    />
  );

  const specialPopulationsComponent = () => <PatientSpecialPopulations minimal />;

  const vaccinationComponent = vaccinationRows => (
    <VaccinationsTable vaccinationRows={vaccinationRows} showAddButton={!readOnly} />
  );

  const patientProblemsComponent = durProblems => (
    <ProblemsList
      problems={durProblems}
      showTitle={false}
      readOnly={readOnly}
      classes={{ mainWrapper: classes.problemsWrapper }}
      onProblemAdded={() => {
        props?.onChange();
      }}
    />
  );

  const isAllergyVerified = () =>
    patient &&
    !!patient.allergies_verification_status_id &&
    (patient.allergies_verification_status_id === VERIFICATION_STATUS_VERIFIED ||
      patient.allergies_verification_status_id === VERIFICATION_STATUS_REVERIFIED);

  const isSpecialPopulationsVerified = () =>
    patient &&
    !!patient.special_populations_verification_status_id &&
    (patient.special_populations_verification_status_id === VERIFICATION_STATUS_VERIFIED ||
      patient.special_populations_verification_status_id === VERIFICATION_STATUS_REVERIFIED);

  const allergyVerifiedText = () =>
    patient.allergies_verification_status_id === VERIFICATION_STATUS_REVERIFIED
      ? NEED_REVERIFY
      : getActionedByUserText({
          action: 'Verified',
          user: getUserById(patient.allergies_verification_verified_by, users),
          date: patient.allergies_verification_verified_dt,
          dateFormat: DATE_FORMAT,
        });

  const specialPopulationsVerifiedText = () =>
    patient.special_populations_verification_status_id === VERIFICATION_STATUS_REVERIFIED
      ? NEED_REVERIFY
      : getActionedByUserText({
          action: 'Verified',
          user: getUserById(patient.special_populations_verification_verified_by, users),
          date: patient.special_populations_verification_verified_dt,
          dateFormat: DATE_FORMAT,
        });

  const isMedicationVerified = () =>
    patient &&
    !!patient.medications_verification_status_id &&
    (patient.medications_verification_status_id === VERIFICATION_STATUS_VERIFIED ||
      patient.medications_verification_status_id === VERIFICATION_STATUS_REVERIFIED);

  const medicationVerifiedText =
    patient.medications_verification_status_id === VERIFICATION_STATUS_REVERIFIED
      ? NEED_REVERIFY
      : getActionedByUserText({
          action: 'Verified',
          user: getUserById(patient.medications_verification_verified_by, users),
          date: patient.medications_verification_verified_dt,
          dateFormat: DATE_FORMAT,
        });
  const medCount = medicationGroupsWithTherapies
    ? medicationGroupsWithTherapies.filter(
        ({ status_code: statusCode }) => statusCode === MedicationStatus.Active,
      ).length
    : 0;
  const medicationsData = {
    title: 'Medications',
    data: medicationGroupsWithTherapies,
    count: medCount,
    id: 'medications',
    component: medComponent(),
    isVerified: isMedicationVerified(),
    verifiedInformation: isMedicationVerified() ? medicationVerifiedText : '',
  };

  const allergiesData = {
    title: 'Allergies',
    data: allergies,
    count:
      allergies?.length ?? allergies?.filter(x => x.status_id === AllergyStatusEnum.Active)?.length,
    id: 'allergies',
    component: allergiesComponent(isAllergyVerified()),
    isVerified: isAllergyVerified(),
    verifiedInformation: isAllergyVerified() ? allergyVerifiedText() : '',
  };

  const specialPopulationsData = {
    title: 'Special Populations',
    data: specialPopulations,
    count:
      specialPopulations &&
      specialPopulations.length &&
      specialPopulations.filter(x => x.active === 1).length > 0
        ? `(${specialPopulations.filter(x => x.active === 1).length})`
        : '',
    id: 'special-populations',
    component: specialPopulationsComponent(),
  };

  const vaccinationsData = {
    title: 'Vaccinations',
    data: vaccinations,
    count: vaccinations && vaccinations.length ? `(${vaccinations.length})` : '',
    component: vaccinationComponent(vaccinations),
  };

  const problemsData = {
    id: 'problems',
    title: 'Problems List',
    data: problems,
    count: problems?.length,
    component: patientProblemsComponent(problems),
    isVerified: patient.problems_verified,
    verifiedInformation: patient.problems_verified
      ? getActionedByUserText({
          action: 'Verified',
          user: getUserById(patient.problems_verified_by, users),
          date: patient.problems_verified_dt,
          dateFormat: DATE_FORMAT,
        })
      : '',
  };

  const drugInteractionAnalysisDropdown = (
    expandedVar,
    expandedControl,
    data = {},
    verificationEnabled = true,
  ) => (
    <Accordion
      expanded={expandedVar}
      onChange={expandedControl}
      square
      className={classes.drugInteractionAnalysisDropdown}
      id={data.id}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.drugInteractionAnalysisDropdownHeader}
      >
        <Grid item xs={6} className={classes.verticalCenter}>
          <Typography>
            {data.title} {data.count > 0 ? `(${data.count})` : ''}
          </Typography>
          {verificationEnabled && (
            <Typography className={classes.verticalCenter}>
              {['allergies', 'medications'].includes(data.id) && !data.isVerified && (
                <ErrorIcon fontSize="small" className={classes.unverifiedIcon} />
              )}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          {verificationEnabled && (
            <Grid container justifyContent="flex-end">
              {data.isVerified ? (
                <Typography>{`${data.verifiedInformation}`}</Typography>
              ) : (
                <Typography className={classes.unverified}>Not verified</Typography>
              )}
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.drugInteractionAnalysisDropdownHeader}>
        <Grid item xs={12}>
          <Divider />
          {data.component ? (
            data.component
          ) : (
            <Typography>{`No ${data.title} Found in the Database`}</Typography>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <>
      <Grid item xs={12}>
        {drugInteractionAnalysisDropdown(
          expandedMedications,
          toggleExpandedMedications,
          medicationsData,
        )}
      </Grid>
      <Grid item xs={12}>
        {drugInteractionAnalysisDropdown(expandedAllergies, toggleExpandedAllergies, allergiesData)}
      </Grid>
      <Grid item xs={12}>
        {drugInteractionAnalysisDropdown(
          expandedSpecialPopulations,
          toggleExpandedSpecialPopulations,
          specialPopulationsData,
          false,
        )}
      </Grid>
      {showVaccinations && (
        <Grid item xs={12}>
          {drugInteractionAnalysisDropdown(
            expandedVaccinations,
            toggleExpandedVaccinations,
            vaccinationsData,
            false,
          )}
        </Grid>
      )}
      <Grid item xs={12}>
        {drugInteractionAnalysisDropdown(
          expandedProblems,
          toggleExpandedProblems,
          problemsData,
          // ARBOR-6280 & ARBOR-6220 - hiding verification text until data can come from EMR feed
          false,
        )}
      </Grid>
    </>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(
  DrugUtilizationReviewInteractorLists,
);

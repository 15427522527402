import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid, Divider, Backdrop } from '@mui/material';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import {
  editMedicalInsurance,
  fetchHasPrimaryMedicalInsurance,
  fetchMedicalInsurances,
} from 'actions/action-financials';
import { validateDate, required, validatePhone } from 'components/form/validation/validation';
import { StringUtils } from 'utils/string-utils';
import { noteTagTypes, insuranceTypes } from 'constants/lists';
import { resolveAudit } from 'actions/action-audit';
import { resolveAuditDefinition } from 'services/utils/audit-service';
import { convertToArborDate } from 'models/time/arbor-date';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import SubHeader from 'components/form/header/subheader';
import { SmallSpacer } from 'components/spacer/spacer';
import { prioritizeListById } from 'helpers/misc';
import { getFileExtension, acceptedFileExtensions } from 'services/utils/document-service';
import { getDocuments } from 'services/utils/upload-document-service';
import { addNewDocument } from 'actions/action-uploaded-documents';
import DocumentConfirmationDialog from '../document-confirmation-dialog';
import { styles } from '../financials-styles';
import {
  renderDropdown,
  renderTextField,
  renderPhoneTextField,
  renderCheckboxHorizontal,
} from '../../../../components/form/field/redux-field';

import {
  RELATIONSHIP_SELF_ID,
  UPLOAD_DOC_FILENAME_FIELD,
  UPLOAD_DOC_FILETYPE_FIELD,
  UPLOAD_DOC_FILENOTE_FIELD,
  UPLOAD_DOCUMENT_FORM,
  MEDICAL_INSURANCE,
  COMMERCIAL,
  ENSURE_MEDICAL_INSURANCE_ADDRESSED,
} from '../../../../constants';
import WrappedFileUpload from '../wrapped-file-upload';
import { uploadFile } from '../../../../services/utils/upload-document-service';
import { isValidInsuranceToResolveAudit } from '../utils';

const taskTypeName = MEDICAL_INSURANCE;
const tagType = noteTagTypes.find(tag => tag.label === taskTypeName);

class EditMedicalInsurance extends Component {
  constructor(props) {
    super(props);
    const { medicalInsurance } = props;
    this.state = {
      noInsurance: Boolean(medicalInsurance.is_no_insurance),
      // upload file props
      pendingDocument: undefined,
      uploadedDocuments: [],
      uploadProcessing: false,
      pendingDocumentLoss: false,
      lostData: [],
      dialogCancel: undefined,
      dialogContinue: undefined,
      selectedPlanSponsor: medicalInsurance.plan_sponsor,
    };
    this.setNoInsurance = this.setNoInsurance.bind(this);
    // Upload File
    this.handleFileUploadCancel = this.handleFileUploadCancel.bind(this);
    this.handleFileUploadSubmit = this.handleFileUploadSubmit.bind(this);
    this.fileSelected = this.fileSelected.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const {
      fetchHasPrimaryMedicalInsurance, // eslint-disable-line
      patient,
    } = this.props; // eslint-disable-line
    fetchHasPrimaryMedicalInsurance(patient.id);
  }

  componentDidUpdate(prevProps, prevState) {
    const { fileFormName } = this.props;
    if (prevState.pendingDocument && prevState.pendingDocument.fileName !== fileFormName) {
      this.onPendingDocumentFileNameChange(fileFormName);
    }
  }

  onPendingDocumentFileNameChange(newFileName) {
    this.setState(prevState => ({
      pendingDocument: {
        ...prevState.pendingDocument,
        ...{ fileName: newFileName },
      },
    }));
  }

  setNoInsurance() {
    this.setState(prevState => ({
      noInsurance: !prevState.noInsurance,
    }));
  }

  // Wrapped File Upload
  handleFileUploadCancel() {
    this.setState({ pendingDocument: undefined });
  }

  handleFileUploadSubmit() {
    this.setState({ uploadProcessing: true });
    const { pendingDocument } = this.state;
    const {
      selectedCustomerId,
      selectedPatientId,
      fileFormName,
      fileFormTypes,
      fileFormNote,
      medicalInsurance,
    } = this.props;

    if (pendingDocument) {
      uploadFile(
        pendingDocument.file,
        `${pendingDocument.fileName}.${pendingDocument.fileExtension}`,
        selectedCustomerId,
        selectedPatientId,
      )
        .then(result => {
          this.setState(prevState => ({
            uploadedDocuments: [
              ...prevState.uploadedDocuments,
              {
                uuid: result.uuid,
                taskTypeId: tagType.value,
                taskIds: [medicalInsurance.id],
                filename: `${fileFormName}.${pendingDocument.fileExtension}`,
                labels: fileFormTypes.split(','),
                note: fileFormNote,
              },
            ],
            pendingDocument: undefined,
            uploadProcessing: false,
          }));
        })
        .catch(() => {
          this.setState({
            fileUploadErrorMessage: 'File could not be uploaded. Try again.',
          });
        });
    }
  }

  handleChange(value) {
    const { initialValues, patient } = this.props;
    if (value.relationship_name === 'Self') {
      this.props.change('policy_holder_first_name', patient.first_name); // eslint-disable-line
      this.props.change('policy_holder_last_name', patient.last_name); // eslint-disable-line
      this.props.change('policy_holder_dob', convertToArborDate(patient.dob).getUtcDate(true)); // eslint-disable-line
    } else {
      this.props.change('policy_holder_first_name', initialValues.policy_holder_first_name); // eslint-disable-line
      this.props.change('policy_holder_last_name', initialValues.policy_holder_last_name); // eslint-disable-line
      this.props.change('policy_holder_dob', initialValues.policy_holder_dob); // eslint-disable-line
    }
  }

  // Handles the submit functionality. Right now it sets some fields to null for simplicity's sake
  handleSubmit(values) {
    const { pendingDocument } = this.state;
    if (pendingDocument) {
      const lostData = [];

      if (pendingDocument) {
        lostData.push(`Pending: ${pendingDocument.fileName}.${pendingDocument.fileExtension}`);
      }

      this.setState({
        pendingDocumentLoss: true,
        lostData,
        dialogCancel: () => {
          this.setState({ pendingDocumentLoss: false });
        },
        dialogContinue: () => {
          this.finalizeSubmit(values);
        },
      });
    } else {
      this.finalizeSubmit(values);
    }
  }

  handleCancel() {
    const { uploadedDocuments, pendingDocument } = this.state;
    if ((uploadedDocuments && uploadedDocuments.length > 0) || pendingDocument) {
      const lostData = uploadedDocuments.map(ud => `Uploaded: ${ud.filename}`) || [];

      if (pendingDocument) {
        lostData.push(`Pending: ${pendingDocument.fileName}.${pendingDocument.fileExtension}`);
      }

      this.setState({
        pendingDocumentLoss: true,
        lostData,
        dialogCancel: () => {
          this.setState({ pendingDocumentLoss: false });
        },
        dialogContinue: () => {
          this.finalizeCancel();
        },
      });
    } else {
      this.finalizeCancel();
    }
  }

  finalizeCancel() {
    const { cancel } = this.props;
    cancel();
  }

  fileSelected(event) {
    if (event && event.length > 0) {
      const selectedFile = event[0];
      const { filePrefix, fileExtension } = getFileExtension(selectedFile.name);
      if (acceptedFileExtensions.includes(fileExtension.toLowerCase())) {
        const { changeField, fileUploadFormId } = this.props;
        changeField(fileUploadFormId, UPLOAD_DOC_FILENAME_FIELD, filePrefix);

        this.setState({
          pendingDocument: {
            fileName: filePrefix,
            fileExtension,
            file: selectedFile,
          },
        });
      }
    }
  }

  // Handles the submit functionality. Right now it sets some fields to null for simplicity's sake
  finalizeSubmit(values) {
    const {
      editMedicalInsurance, // eslint-disable-line
      fetchMedicalInsurances, // eslint-disable-line
      cancel,
      existingDocuments,
      addNewDocument, // eslint-disable-line
      otherSuccessFunction,
      resolveAudit, // eslint-disable-line
      auditState,
      patient,
    } = this.props;

    const { uploadedDocuments } = this.state;

    const payload = {
      ...values,
      patient_id: values.patient_id,
      insurance_type_id: values.insurance_type_id,
      plan_name: values.plan_name,
      plan_phone_number: values.plan_phone_number,
      no_insurance: values.no_insurance,
      policy_holder_first_name: values.policy_holder_first_name,
      policy_holder_last_name: values.policy_holder_last_name,
      policy_holder_relationship_id: values.policy_holder_relationship_id,
      policy_holder_dob: convertToArborDate(values.policy_holder_dob).getUtcDate(),
      policy_holder_sponsor: values.policy_holder_sponsor,
      plan_sponsor: values.plan_sponsor,
      group_number: values.group_number,
      member_id: values.member_id,
      start_date: convertToArborDate(values.start_date).getUtcDate(),
      end_date: convertToArborDate(values.end_date).getUtcDate(),
      is_verified: 1, // Consider verified if edited in UI
      verified_dt: convertToArborDate(new Date()).getUtcDatetime(),
      documents: uploadedDocuments.map(doc => ({
        uuid: doc.uuid,
        file_name: doc.filename,
        labels: doc.labels,
        tags: doc.taskIds.map(id => ({
          tag_type_id: tagType.value,
          resource_id: id,
        })),
        noteText: doc.note,
      })),
    };

    return editMedicalInsurance(payload).then(() => {
      fetchMedicalInsurances(values.patient_id).then(() => {
        fetchHasPrimaryMedicalInsurance(values.patient_id);
        cancel(payload.is_verified);
      });

      if (uploadedDocuments.length) {
        getDocuments({
          patient_id: values.patient_id,
          fetchFromId: Math.max(...existingDocuments.map(d => d.id)),
        }).then(result => {
          addNewDocument(result.data.documents);
        });
      }

      if (isValidInsuranceToResolveAudit(payload, insuranceTypes)) {
        resolveAuditDefinition({
          auditDefinitionType: ENSURE_MEDICAL_INSURANCE_ADDRESSED,
          auditState,
          patient,
          resolverFunction: resolveAudit,
        });
      }

      if (otherSuccessFunction) {
        otherSuccessFunction();
      }
    });
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      classes,
      relationships,
      lookups,
      selectedRelationShipId,
      fileUploadFormId,
      defaultDocumentLabels,
      pristineOverride,
    } = this.props;

    const {
      pendingDocument,
      fileUploadErrorMessage,
      uploadProcessing,
      uploadedDocuments,
      pendingDocumentLoss,
      form,
      lostData,
      dialogCancel,
      dialogContinue,
      selectedPlanSponsor,
    } = this.state;

    const uploadProps = {
      form,
      pendingDocument,
      fileUploadErrorMessage,
      uploadProcessing,
      uploadedDocuments,
      pendingDocumentLoss,
      classes,
      fileUploadFormId,
      defaultDocumentLabels,
      handleFileUploadSubmit: this.handleFileUploadSubmit,
      handleFileUploadCancel: this.handleFileUploadCancel,
      fileSelected: this.fileSelected,
    };

    let mappedRelationships = [];
    let sortedRelationships = [];
    if (relationships && relationships.length > 0) {
      sortedRelationships = prioritizeListById(RELATIONSHIP_SELF_ID, relationships);
      mappedRelationships = sortedRelationships.map(relationship => ({
        ...relationship,
        value: relationship.id,
        label: relationship.relationship_name,
      }));
    }
    const dobRequired = selectedRelationShipId === RELATIONSHIP_SELF_ID;

    const { noInsurance } = this.state;

    const documentConfirmationDialogProps = {
      lostData,
      dialogCancel,
      dialogContinue,
      pendingDocumentLoss,
    };
    const disableSubmit = pristineOverride
      ? submitting
      : submitting || (pristine && !pendingDocument && !uploadedDocuments.length);

    const employer = lookups.planSponsors.find(o => o.id === selectedPlanSponsor);
    return (
      <Grid
        container
        justifyContent="center"
        spacing={7}
        className={(classes.formRowContainer, classes.formContainer)}
      >
        <Grid item xs={12}>
          <DocumentConfirmationDialog {...documentConfirmationDialogProps} />
          <form
            className={classes.editFormContainer}
            onSubmit={handleSubmit(this.handleSubmit)}
            autoComplete="off"
          >
            <Grid container alignItems="flex-end">
              <Grid item xs={12}>
                <Field
                  name="no_insurance"
                  label="No Insurance"
                  component={renderCheckboxHorizontal}
                  onChange={this.setNoInsurance}
                />
              </Grid>
            </Grid>
            <div className={classes.noInsuranceBackdropWrapper}>
              <Backdrop open={noInsurance} className={classes.noInsuranceBackdrop} />
              <Grid container alignItems="flex-end">
                <Grid item xs={3}>
                  <Field
                    name="insurance_type_id"
                    label="Type *"
                    component={renderDropdown}
                    fields={lookups.medicalInsuranceTypes
                      .map(type => ({
                        label: type.insurance_type,
                        value: type.id,
                      }))
                      .reverse()}
                    validate={noInsurance ? null : [required]}
                    disabled={noInsurance}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="start_date"
                    label="Start Date"
                    component={renderDatePicker}
                    validate={[validateDate]}
                    disabled={noInsurance}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="end_date"
                    label="End Date"
                    component={renderDatePicker}
                    validate={[validateDate]}
                    disabled={noInsurance}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="flex-end">
                <Grid item xs={3}>
                  <Field
                    name="plan_name"
                    label="Insurance Plan Name *"
                    component={renderTextField}
                    validate={noInsurance ? null : [required]}
                    disabled={noInsurance}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="plan_phone_number"
                    label="Insurance Plan Phone Number"
                    component={renderPhoneTextField}
                    validate={[validatePhone]}
                    disabled={noInsurance}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="group_number"
                    label="Group ID"
                    component={renderTextField}
                    normalize={StringUtils.toUpper}
                    disabled={noInsurance}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="member_id"
                    label=" Member ID *"
                    component={renderTextField}
                    normalize={StringUtils.toUpper}
                    validate={noInsurance ? null : [required]}
                    disabled={noInsurance}
                  />
                </Grid>
              </Grid>
              <Divider light />
              <SmallSpacer />
              <Grid container alignItems="flex-end">
                <Grid item xs={12}>
                  <SubHeader name="Policy Holder" />
                  <SmallSpacer />
                </Grid>
                <Grid container alignItems="flex-end">
                  <Grid item xs={6}>
                    <Field
                      name="policy_holder_relationship_id"
                      label="Relationship to Patient *"
                      component={renderDropdown}
                      fields={mappedRelationships}
                      onChange={value =>
                        this.handleChange(mappedRelationships.find(o => o.id === value))
                      }
                      validate={noInsurance ? null : [required]}
                      disabled={noInsurance}
                    />
                  </Grid>
                </Grid>
                <SmallSpacer />
                <Grid item xs={3}>
                  <Field
                    name="policy_holder_first_name"
                    label="Policy Holder First Name *"
                    component={renderTextField}
                    validate={noInsurance ? null : [required]}
                    disabled={noInsurance}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="policy_holder_last_name"
                    label="Policy Holder Last Name *"
                    component={renderTextField}
                    validate={noInsurance ? null : [required]}
                    disabled={noInsurance}
                  />
                </Grid>
                <Grid item xs={3}>
                  {dobRequired ? (
                    <Field
                      name="policy_holder_dob"
                      label="Policy Holder Date of Birth *"
                      component={renderDatePicker}
                      validate={noInsurance ? [validateDate] : [required, validateDate]}
                      disabled={noInsurance}
                    />
                  ) : (
                    <Field
                      name="policy_holder_dob"
                      label="Policy Holder Date of Birth"
                      component={renderDatePicker}
                      validate={[validateDate]}
                      disabled={noInsurance}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container alignItems="flex-end">
                <Grid item xs={3}>
                  <Field
                    name="plan_sponsor"
                    label="Plan sponsor *"
                    component={renderDropdown}
                    fields={lookups.planSponsors.map(item => ({
                      label: item.type,
                      value: item.id,
                    }))}
                    onChange={value => this.setState({ selectedPlanSponsor: value })}
                    validate={noInsurance ? null : [required]}
                    disabled={noInsurance}
                    width="75%"
                  />
                </Grid>
                {employer?.type === COMMERCIAL && (
                  <Grid item xs={3}>
                    <Field
                      name="policy_holder_sponsor"
                      label="Employer"
                      component={renderTextField}
                      disabled={noInsurance}
                    />
                  </Grid>
                )}
              </Grid>
            </div>
            <Divider light />
            <SmallSpacer />
            <WrappedFileUpload {...uploadProps} />
            <SmallSpacer />
            <ConfirmationPanel handleCancel={this.handleCancel} disableSubmit={disableSubmit} />
          </form>
        </Grid>
      </Grid>
    );
  }
}

function handleFormName(taskType, id) {
  const fileFormId = `${UPLOAD_DOCUMENT_FORM}-${taskType}-${id}`;
  return fileFormId;
}

function mapStateToProps(state, props) {
  const { patient, lookups, uploadedDocuments, audit } = state;
  const { medicalInsurance } = props;

  const fileUploadFormId = handleFormName(tagType.value, medicalInsurance.id);
  const selector = formValueSelector(fileUploadFormId);
  const fileFormName = selector(state, UPLOAD_DOC_FILENAME_FIELD);
  const fileFormTypes = selector(state, UPLOAD_DOC_FILETYPE_FIELD);
  const fileFormNote = selector(state, UPLOAD_DOC_FILENOTE_FIELD);

  const selectedRelationShipId = selector(state, 'policy_holder_relationship_id');

  return {
    patient,
    auditState: audit,
    lookups,
    insuranceTypes: state.lookups.insuranceTypes,
    relationships: state.lookups.insuranceRelationships,
    hasPrimary: state.financials.has_primary_insurance,
    enableReinitialize: true,
    selectedRelationShipId,
    defaultDocumentLabels: state.lookups.defaultDocumentLabels,
    initialValues: {
      id: medicalInsurance.id,
      patient_id: medicalInsurance.patient_id,
      insurance_type_id: medicalInsurance.insurance_type_id,
      plan_name: medicalInsurance.plan_name,
      no_insurance: Boolean(medicalInsurance.is_no_insurance),
      plan_phone_number: medicalInsurance.plan_phone_number,
      policy_holder_first_name: medicalInsurance.policy_holder_first_name,
      policy_holder_last_name: medicalInsurance.policy_holder_last_name,
      policy_holder_relationship_id: medicalInsurance.policy_holder_relationship_id,
      policy_holder_dob: medicalInsurance.policy_holder_dob
        ? convertToArborDate(medicalInsurance.policy_holder_dob).getUtcDate(true)
        : null,
      policy_holder_sponsor: medicalInsurance.policy_holder_sponsor,
      plan_sponsor: medicalInsurance.plan_sponsor,
      group_number: medicalInsurance.group_number,
      member_id: medicalInsurance.member_id,
      start_date: medicalInsurance.start_date
        ? convertToArborDate(medicalInsurance.start_date).getUtcDate(true)
        : null,
      end_date: medicalInsurance.end_date
        ? convertToArborDate(medicalInsurance.end_date).getUtcDate(true)
        : null,
      is_verified: 1, // if in UI, set to verified
      verified_dt: medicalInsurance.verified_dt
        ? convertToArborDate(medicalInsurance.verified_dt, true).getCustomerDatetime()
        : null,
      verified_by: medicalInsurance.verified_by,
    },
    fileUploadFormId,
    fileFormName,
    fileFormTypes,
    fileFormNote,
    selectedCustomerId: state.filters.selectedCustomerId,
    selectedPatientId: patient.id,
    existingDocuments: uploadedDocuments.documents,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      change,
      editMedicalInsurance,
      fetchHasPrimaryMedicalInsurance,
      fetchMedicalInsurances,
      changeField: (formName, fieldName, value) => dispatch(change(formName, fieldName, value)),
      addNewDocument,
      resolveAudit,
    },
    dispatch,
  );
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(reduxForm({})(EditMedicalInsurance));

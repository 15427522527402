import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { DefaultTheme } from '@mui/styles';

import { expandTheme } from '../../utils/theme-util';

export const ReportVisibilityButtonMenuPaperProps = {
  elevation: 0,
  sx: {
    'overflow': 'visible',
    'filter': 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    'mt': 1.5,
    'maxHeight': 421,
    'minWidth': 524,
    'overflowY': 'scroll',
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

export const ReportVisibilityButtonStyles = (theme: Theme | DefaultTheme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    visibilityIcon: {
      marginLeft: 1,
    },
    visibilityReportButton: {
      'float': 'right',
      'backgroundColor': palette.primary.background,
      'border': '1px solid #C4C4C4',
      'borderRadius': '4px',
      'boxShaddow': 'none',
      'color': '#44484C',
      'boxShadow': 'none',
      '&:hover': {
        backgroundColor: palette.primary.background,
        border: '1px solid #C4C4C4',
        borderRadius: '4px',
        color: palette.primary.steelGrey,
        boxShadow: 'none',
      },
    },
    categoryMenuItem: {
      maxHeight: '39px',
    },
    metricMenuItem: {
      maxHeight: '39px',
    },
    chartMenuItem: {
      maxHeight: '39px',
      marginLeft: '18px',
    },
    metricDivider: {
      marginLeft: '16px',
    },
    chartDivider: {
      marginLeft: '34px',
    },
  });
};

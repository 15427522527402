import React, { useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { ancillarySupplies } from 'constants/lists';
import { RectangleIcon } from 'components/icons/icons';
import { nameOfFactory } from 'utils/types-util';
import { IFillCoordinationEditFormFields } from 'containers/tasks/fill-coordination/types';
import { buildQaId } from 'utils/build-qa-id';
import { IContentsOfPackage, ITherapyNames } from './types';
import { ShippingStyles } from './shipping.styles';
import { TherapyTypes } from '../../constants/enums';
import { PackingVerificationStatus } from './shipping-verification';
import { isShippingPage } from './utils';
import ShippingScanHistory from './shipping-scan-history-modal';

const nameOfFormFields = nameOfFactory<IFillCoordinationEditFormFields>();

interface IShippingProps extends IContentsOfPackage, WithStyles<typeof ShippingStyles> {}
const getQaId = buildQaId('shipping.contents-of-package', '.');

function ContentsOfPackage(props: IShippingProps): JSX.Element | null {
  const { classes, scanProps, isPickup } = props;
  const { currentMedicationInformation } = props;
  const { currentAncillarySupply } = props;
  const sortedMedications = useMemo(() => {
    const ordered = currentMedicationInformation?.sort((a, b) => a.name.localeCompare(b.name));
    const specialtyMediations: ITherapyNames[] = [];
    const nonSpecialtyMediations: ITherapyNames[] = [];

    if (Array.isArray(ordered) && ordered.length > 0) {
      ordered.forEach(medication => {
        if (medication.therapy_type === TherapyTypes.NonSpecialty) {
          nonSpecialtyMediations.push(medication);
        } else {
          specialtyMediations.push(medication);
        }
      });
    }

    return { specialtyMediations, nonSpecialtyMediations };
  }, [currentMedicationInformation]);

  const renderRectangleIcon = (): JSX.Element => {
    return (
      <Grid item>
        <RectangleIcon className={classes.icon} data-qa-id={buildQaId('icon')} />
      </Grid>
    );
  };

  const displaySpecialtyMedicationInformation = (): JSX.Element | null => {
    const specialtyExists = sortedMedications.specialtyMediations.length > 0;
    return (
      <Grid
        container
        alignItems="center"
        className={classes.subsectionItems}
        data-qa-id={buildQaId('specialty')}
      >
        {specialtyExists &&
          sortedMedications.specialtyMediations?.map(therapy => (
            <Grid container item key={therapy.name}>
              <Grid item xs={8} className={classes.subsectionItemText}>
                <Typography>{therapy.name}</Typography>
              </Grid>
            </Grid>
          ))}
        {!specialtyExists && <Typography>N/A</Typography>}
      </Grid>
    );
  };

  const displayNonSpecialtyMedicationInformation = (): JSX.Element | null => {
    const nonSpecialtyExists = sortedMedications.nonSpecialtyMediations.length > 0;
    return (
      <Grid
        container
        alignItems="center"
        className={classes.subsectionItems}
        data-qa-id={buildQaId('non-specialty')}
      >
        {nonSpecialtyExists &&
          sortedMedications.nonSpecialtyMediations?.map(therapy => (
            <Grid container item key={therapy.name}>
              <Grid item xs={8} className={classes.subsectionItemText}>
                <Typography>{therapy.name}</Typography>
              </Grid>
            </Grid>
          ))}
        {!nonSpecialtyExists && <Typography>N/A</Typography>}
      </Grid>
    );
  };

  const displayAncillarySupplies = (): JSX.Element | null => {
    const results: string[] = [];
    if (currentAncillarySupply) {
      Object.entries(currentAncillarySupply).forEach(([key, val]) => {
        if (val === true) {
          const supplyLabel = ancillarySupplies.find(supply => supply.value === key)?.label;
          if (supplyLabel) {
            results.push(supplyLabel);
          }
        }
      });
    }

    return (
      <Grid
        container
        alignItems="center"
        className={classes.subsectionItems}
        data-qa-id={buildQaId('ancillary-supplies')}
      >
        {results?.sort().map(
          ancillarySupply =>
            results.length && (
              <Grid container item key={ancillarySupply}>
                <Grid
                  item
                  xs={8}
                  className={classes.subsectionItemText}
                  data-qa-id={getQaId(nameOfFormFields('ancillary_supplies'))}
                >
                  <Typography>{ancillarySupply}</Typography>
                </Grid>
              </Grid>
            ),
        )}
        {!results.length && <Typography>N/A</Typography>}
      </Grid>
    );
  };
  const totalItems =
    (sortedMedications.specialtyMediations.length || 0) +
    (sortedMedications.nonSpecialtyMediations.length || 0);

  return (
    <Grid className={classes.sectionBottomMargin}>
      <Grid className={classes.contentsOfPackagePanel}>
        {props.orderId ? (
          <>
            <Typography className={classes.contentsOfPackageTitle}>
              {totalItems}
              {` Items - Order Id: ${props.orderId} - Contents of Package`}
            </Typography>
            {!isShippingPage() && scanProps && !isPickup ? (
              <Box sx={{ marginRight: '6px' }}>
                <PackingVerificationStatus status={scanProps?.packageScanStatus} />
                <ShippingScanHistory scanProps={scanProps} />
              </Box>
            ) : null}
          </>
        ) : (
          <Typography className={classes.contentsOfPackageTitle}>Contents of Package</Typography>
        )}
      </Grid>
      <Grid
        container
        className={classes.contentsOfPackage}
        data-qa-id={buildQaId('contents-of-package')}
      >
        <Grid container alignItems="center" className={classes.subsection}>
          {renderRectangleIcon()}
          <Grid item>
            <Typography className={classes.subsectionTitles}>Specialty</Typography>
          </Grid>
          {displaySpecialtyMedicationInformation()}
        </Grid>
        <Grid container alignItems="center" className={classes.subsection}>
          {renderRectangleIcon()}
          <Grid item>
            <Typography className={classes.subsectionTitles}>Non-Specialty</Typography>
          </Grid>
          {displayNonSpecialtyMedicationInformation()}
        </Grid>
        <Grid container alignItems="center" className={classes.subsection}>
          {renderRectangleIcon()}
          <Grid item>
            <Typography className={classes.subsectionTitles}>Ancillary Supplies</Typography>
          </Grid>
          {displayAncillarySupplies()}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(ShippingStyles)(ContentsOfPackage);

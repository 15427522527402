import { convertToArborDate } from 'models/time/arbor-date';
import { isDate } from 'lodash';
import { AnswerValue, DocumentAnswerValue, QuestionnaireQuestions } from './types';

export const formatDate = (dateString: string) =>
  convertToArborDate(dateString, true).getCustomerDate(true, 'M/DD/YYYY');

export function readFileAsync(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (typeof reader.result === 'string') {
        if (reader.result.length === 0 || reader.result === 'data:') {
          reject(new Error('File appears to be empty. Try using a different file.'));
          return;
        }
        resolve(reader.result);
      } else {
        reject(new Error('Could not read contents of file as a string.'));
      }
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
}

export const isDocumentValid = (value: AnswerValue) => {
  if (value && typeof value === 'object' && 'isUploaded' in value) {
    return Boolean(value.isUploaded);
  }
  return false;
};

export const parseAnswerResponse = (
  savedAnswers: QuestionnaireQuestions,
  questionnaireDefinition: QuestionnaireQuestions,
) => {
  const savedAnswerKeys = Object.keys(savedAnswers);
  const answerResults = { ...questionnaireDefinition } as QuestionnaireQuestions;

  // eslint-disable-next-line no-restricted-syntax
  for (const savedAnswerKey of savedAnswerKeys) {
    // set the answer results key to the answer from the response, merged with the questionnaire definition
    if (savedAnswerKey in questionnaireDefinition) {
      const { question, ...restSavedAnswerProps } = savedAnswers[savedAnswerKey];
      answerResults[savedAnswerKey] = {
        ...questionnaireDefinition[savedAnswerKey],
        ...restSavedAnswerProps,
      };

      // Set the default filename for documents
      if (
        restSavedAnswerProps.value !== null &&
        typeof restSavedAnswerProps.value === 'object' &&
        !isDate(restSavedAnswerProps.value) &&
        'filename' in restSavedAnswerProps.value &&
        restSavedAnswerProps.value.filename === null &&
        (questionnaireDefinition[savedAnswerKey].value as DocumentAnswerValue).filename &&
        (answerResults[savedAnswerKey].value as DocumentAnswerValue).filename
      ) {
        (answerResults[savedAnswerKey].value as DocumentAnswerValue).filename = (
          questionnaireDefinition[savedAnswerKey].value as DocumentAnswerValue
        ).filename;
      }
    }
  }

  return answerResults;
};

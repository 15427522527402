export enum NoteTagLabels {
  Patient = 'Patient',
  Therapy = 'Therapy',
  Clinical = 'Clinical',
  Demographic = 'Demographic',
  Financial = 'Financial',
  MedicalInsurance = 'Medical Insurance',
  PbmInsurance = 'PBM Insurance',
  Income = 'Income',
  Contact = 'Contact',
  BI = 'BI',
  PA = 'PA',
  FA = 'FA',
  CA = 'CA',
  CI = 'CI',
  FC = 'FC',
  TPR = 'TPR',
  DC = 'DC',
  FDC = 'FDC',
  INT = 'INT',
  CSL = 'CSL',
  AR = 'AR',
  OR = 'OR',
  MR = 'MR',
  QRE = 'QRE',
  DUR = 'DUR',
  RS = 'RS',
}

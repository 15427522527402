import { CslPlan, CslPlanContent } from './types';

export type IResultChildren = CslPlanContent & {
  specialty_id: number;
};
export type IResult = CslPlan & {
  icd_list: string;
  children: IResultChildren[];
};
export interface IResponse {
  totalCount: number;
  items: CslPlan[];
  results: IResult[];
  page: number;
  pageSize: number;
  totalItems: number;
}

export const mapCslPlanToTableResult = (cslPlan: CslPlan): IResult => {
  const result = {
    ...cslPlan,
    icd_list: cslPlan.icds.join(', '),
    children: [
      {
        ...cslPlan.content,
        specialty_id: cslPlan.specitaly_id,
      } as IResultChildren,
    ],
  } as IResult;
  return result;
};

import { isNaN, get } from 'lodash';
import moment from 'moment';
import { numberToDollars } from 'services/utils/formatting';
import { DATES_EDITABLE_TYPE, NUMBER, DATE, BLANK, BETWEEN, CURRENCY } from './constants';
import DefaultHeader from './table/default-header';
import CellRender from './table/cell-render';
import DefaultFooter from './table/default-footer';

export const parseSearchValueCriteria = (dataType, value, secondValue) => {
  if (dataType === NUMBER) {
    const parsedValue = Array.isArray(value)
      ? value.map(o => parseFloat(o))
      : [parseFloat(value), parseFloat(secondValue)].filter(o => !isNaN(o));

    return parsedValue;
  }

  if (dataType === DATE) {
    const parsedValue = Array.isArray(value)
      ? value.map(o => o.toISOString())
      : [value.toISOString()].filter(o => o !== null);

    if (secondValue !== undefined) {
      parsedValue.push(secondValue.toISOString());
    }

    return parsedValue;
  }

  return Array.isArray(value)
    ? value
    : [value, secondValue].filter(o => o !== undefined || o !== null);
};

export const defaultColumn = {
  Cell: CellRender,
  Header: DefaultHeader,
  Footer: DefaultFooter,
};

export const parseValue = (value, type, format, values, blankLabel = BLANK) => {
  if (type === DATES_EDITABLE_TYPE) {
    return value ? moment(value).utc().format('MM/DD/yyyy') : BLANK;
  }

  if (values) {
    const result = values.find(item => item.value === value);
    return get(result, 'label', BLANK);
  }

  if (format === CURRENCY) {
    return numberToDollars(value);
  }

  return value === null || value === undefined || value === '' ? blankLabel : value;
};

export const validateFilterValues = ({
  dataType,
  selectedCriteria,
  searchValueCriteria,
  secondSearchValueCriteria,
  checkedList,
}) => {
  if (dataType === 'list') {
    return checkedList.length > 0;
  }

  if (selectedCriteria === BETWEEN) {
    return !!searchValueCriteria && !!secondSearchValueCriteria;
  }

  if (!selectedCriteria) {
    return false;
  }

  return !!searchValueCriteria;
};

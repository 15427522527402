import {
  SELECTED_PATIENT_ID,
  SELECTED_THERAPY_ID,
  SELECTED_TASK_TYPE,
  SELECTED_TASK_ID,
  SIDEBAR_TYPE,
  SIDEBAR_TASK_TYPE,
  SELECT_FIRST_TASK,
} from '../constants';

export function selectPatientId(patientId) {
  return {
    type: SELECTED_PATIENT_ID,
    payload: patientId,
  };
}

export function selectTherapyId(therapyId) {
  return {
    type: SELECTED_THERAPY_ID,
    payload: therapyId,
  };
}

export function selectTaskType(taskType) {
  return {
    type: SELECTED_TASK_TYPE,
    payload: taskType,
  };
}

export function selectTaskId(taskId) {
  return {
    type: SELECTED_TASK_ID,
    payload: taskId,
  };
}

export function selectSidebarType(type) {
  return {
    type: SIDEBAR_TYPE,
    payload: type,
  };
}

export function selectSidebarTaskType(taskType) {
  return {
    type: SIDEBAR_TASK_TYPE,
    payload: taskType,
  };
}

export function selectFirstTask(tasks) {
  return {
    type: SELECT_FIRST_TASK,
    data: tasks,
  };
}

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import React, { useRef } from 'react';
import '../../react-table-v6-fork/react-table.css';
import 'chartjs-plugin-datalabels';
import colors from '../../lib/themes/colors.module.scss';

const { trellisBlue, grey25 } = colors;

function ChartCard(props) {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
  const { data, reportId, clickReportHandler, total, classes } = props;
  const chartRef = useRef();

  const height = data.length * 10 + 10;

  const barData = { labels: [], datasets: [] };
  barData.labels = data.map(item => item.label);
  barData.datasets = [
    {
      data: data.map(i => i.count),
      borderColor: grey25,
      backgroundColor: grey25,
      datalabels: {
        align: 'end',
        anchor: 'start',
      },
    },
  ];

  const clicked = event => {
    if (event) {
      const index = getElementAtEvent(chartRef.current, event)[0].index;
      clickReportHandler(reportId, barData.labels[index], data[index].ids);
    }
  };

  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    interaction: {
      mode: 'index',
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: trellisBlue,
        anchor: 'center',
        align: 'end',
        formatter(value) {
          if (total === 0) {
            return '0/0 (0%)';
          }
          return `${value}/${total} (${Math.round((value / total) * 100)}%)`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <Card className={classes.chartCard}>
      <CardContent>
        {barData.labels.length > 0 && barData.datasets[0].data.length > 0 && (
          <Bar
            data={barData}
            options={options}
            plugins={[ChartDataLabels]}
            height={height}
            ref={chartRef}
            onClick={clicked}
          />
        )}
      </CardContent>
    </Card>
  );
}

export default ChartCard;

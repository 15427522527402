import React from 'react';
import { Grid, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { ActiveInactiveFormStyles } from './active-inactive-form.styles';

interface IActiveInactiveFormProps extends WithStyles<typeof ActiveInactiveFormStyles> {
  initialValue: boolean;
  handleCancel: () => void;
  handleSubmit: (value: boolean) => void;
}

/** This isn't really a "form" per se, but it's still rendered in a popout thing.  */
const ActiveInactiveForm: React.FC<IActiveInactiveFormProps> = (
  props: IActiveInactiveFormProps,
): JSX.Element => {
  const { classes } = props;

  // #endregion

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid
        item
        className={classes.option}
        onClick={() => {
          props.handleSubmit(true);
        }}
      >
        <Typography align="center" className={classes.optionType}>
          Active
        </Typography>
      </Grid>
      <Grid
        item
        className={classes.option}
        onClick={() => {
          props.handleSubmit(false);
        }}
      >
        <Typography align="center" className={classes.optionType}>
          Inactive
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(ActiveInactiveFormStyles)(ActiveInactiveForm);

import React from 'react';
import { Grid } from '@mui/material';

const styles = {
  fieldContainerWithRedText: {
    marginTop: '8px',
    marginBottom: '8px',
    paddingRight: '8px',
    paddingLeft: '8px',
    color: '#d94d44',
    fontSize: '13px',
    fontWeight: 350,
    height: '56px',
    backgroundColor: '#FFF1EF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  redCircleExclamationPoint: {
    display: 'inline-block',
    width: '16px',
    fontWeight: 500,
    fontSize: '13px',
    textAlign: 'center',
    color: 'white',
    marginRight: '8px',
    backgroundColor: 'red',
    borderRadius: '50%',
  },
};
const RemovedTherapiesNotice = props => {
  const { providers, initialValues } = props;
  const { therapies } = providers;
  const { therapy_ids_to_remove } = initialValues;
  if (!therapy_ids_to_remove || therapy_ids_to_remove.length === 0) {
    return null;
  }
  const removed_therapies = Object.values(therapies || {}).filter(therapy =>
    therapy_ids_to_remove.includes(therapy.id),
  );
  const therapiestoRemoveAsString = removed_therapies
    .map(
      therapy =>
        `  ${therapy.drug_name} ${therapy.dosage_form} ${therapy.strength} ${therapy.strength_unit_of_measure}`,
    )
    .join(',');
  return (
    <Grid item xs={12} style={{ ...styles.fieldContainerWithRedText }}>
      <div>
        <span style={{ ...styles.redCircleExclamationPoint }}>!</span>
        Patient requested removal of the following therapies from this Fill Coordination:{' '}
        {therapiestoRemoveAsString}.
      </div>
    </Grid>
  );
};

export default RemovedTherapiesNotice;

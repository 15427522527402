import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { Grid, Modal, Popover } from '@mui/material';
import { BaseTable } from 'containers/application-manager/base-table/base-table';
import { IHeaderCell } from 'containers/application-manager/base-table/types';
import History from 'containers/common/history';
import compose from 'recompose/compose';
import withStyles from '@mui/styles/withStyles';
import { editIncome } from 'actions/action-financials';
import { FieldTypes } from 'containers/application-manager/types';
import { IPopoverStateBase } from 'containers/application-manager/gtd/types';
import { FormField } from 'containers/application-manager/popover-field-forms/index';
import { PAGINATION_PARAMS } from 'containers/application-manager/ctd/constants';
import { bindActionCreators, Dispatch } from 'redux';
import { NumericFormat } from 'react-number-format';
import { getModalStyle } from 'services/utils/styles-service';
import { EditHistory, EditPencil } from 'components/icons/icons';
import { convertToArborDate } from 'models/time/arbor-date';
import { styles } from '../financials-styles';
import IncomeTableDetail from '../tables-details/income-table-detail';
import { dateConvert } from '../../../application-manager/utils';

interface IIncomesTableProps {
  editIncome: (payload: any) => void;
  dataset: IResult[];
  classes: Record<string, string>;
  hideTitleHeader?: boolean;
}

interface IResult {
  id: number;
  patient_id: number;
  taxable_income: number;
  pension_401k_retirement_income: any;
  is_no_income: number;
  gross_business_income: number;
  transcript_tax_form_completed: number;
  extension_filed: number;
  unemployment_income: number;
  unemployment_income_period_id: number;
  unemployment_start_date: string;
  unemployment_end_date: string;
  patient_ssi: number;
  patient_ssi_income_period_id: number;
  dependent_ssi: number;
  dependent_ssi_income_period_id: number;
  spouse_ssi: number;
  spouse_ssi_income_period_id: any;
  longterm_disability_income: number;
  longterm_disability_income_period_id: number;
  longterm_disability_company: string;
  retirement_income: number;
  retirement_income_period_id: number;
  family_size: number;
  number_of_dependents: number;
  has_income: number;
  income_year: number;
  created: string;
  created_by: number;
  updated: string;
  updated_by: number;
  is_verified: number;
  verified_dt: string;
  verified_by: number;
  unemployment_income_period: string;
  patient_ssi_income_period: string;
  dependent_ssi_income_period: string;
  longterm_disability_income_period: string;
  retirement_income_period: string;
  verified_by_user: string;
  updated_by_user: string;
  order: number;
  children: undefined[];
}

export interface IIncomesTableResponse {
  totalCount: number;
  results: IResult[];
}

type IPopoverState = IPopoverStateBase<IResult, IResult>;

const initialPopoverState: IPopoverState = {
  open: false,
};

const IncomesTable: React.FC<IIncomesTableProps> = (props: IIncomesTableProps): JSX.Element => {
  const { classes } = props;
  const dispatch = useDispatch();

  // #region component state
  const [tableData, setTableData] = React.useState<IIncomesTableResponse | undefined>(undefined);
  const [lastReload, setLastReload] = React.useState<Date>(new Date());
  const [popoverState, setPopoverState] = React.useState<IPopoverState>({ open: false });
  const [editHistoryModalOpen, setEditHistoryModalOpen] = React.useState<boolean>(false);
  const [editItemMap, setEditItemMap] = React.useState<any>({});
  const [selectedItem, setSelectedItem] = React.useState<IResult>({} as IResult);
  // #endregion

  // #region helpers
  const openPopover = (
    element: HTMLElement,
    value: unknown,
    config: {
      parent?: IResult;
      child?: IResult;
      fieldType: FieldTypes;
      field: keyof IResult;
      forceAsParent?: boolean;
    },
  ): void => {
    if (config.parent != null && config.child != null) {
      throw new Error('Cannot select both a parent and child cell. Either one or the other');
    }

    setPopoverState({
      open: true,
      anchorElement: element,
      parent: config.parent,
      child: config.child,
      field: config.field,
      fieldType: config.fieldType,
      value: value,
      forceAsParent: config.forceAsParent,
    });
  };

  const getEditCellComponent = (parent: IResult) => {
    return (
      <div className={classes.editCellContainer}>
        <EditHistory
          onClick={(event: Event) => {
            event.stopPropagation();
            setSelectedItem(parent);
            setEditHistoryModalOpen(true);
          }}
        />
        <EditPencil
          onClick={(event: Event) => {
            event.stopPropagation();
            setEditItemMap({ ...editItemMap, [parent.id]: true });
          }}
        />
      </div>
    );
  };

  const getTotalIncomeField = (income: IResult) => {
    const totalSumIncome =
      income.taxable_income +
      income.unemployment_income +
      income.patient_ssi +
      income.dependent_ssi +
      income.spouse_ssi +
      income.longterm_disability_income;

    return (
      <NumericFormat
        thousandSeparator
        prefix={totalSumIncome && Number(totalSumIncome) > 0 ? '$' : ''}
        displayType="text"
        value={totalSumIncome}
      />
    );
  };

  const handleEditClose = (income: IResult) =>
    setEditItemMap({ ...editItemMap, [income.id]: false });

  const COLUMN_SETTINGS: IHeaderCell<IResult, undefined>[] = [
    {
      label: 'Name',
      parentKey: 'income_year',
      sortable: true,
      hideLeftPadding: true,
      childColSpan: 4,
      // eslint-disable-next-line react/no-unstable-nested-components
      childComponent: (parent: IResult) => {
        return (
          <IncomeTableDetail
            income={parent}
            editItemMap={editItemMap}
            handleEditClose={() => handleEditClose(parent)}
          />
        );
      },
      parentMaxWidth: '300px',
      parentDisplayInline: true,
      parentValueFormatter: (value: any) => {
        return `Household Income ${value}`;
      },
    },
    {
      label: 'Total Income',
      hideLeftPadding: true,
      hideChildCol: true,
      sortable: true,
      parentComponent: getTotalIncomeField,
    },
    {
      label: 'Family Size',
      parentKey: 'family_size',
      hideLeftPadding: true,
      hideChildCol: true,
      sortable: true,
    },
    {
      label: '',
      sortable: false,
      hideLeftPadding: true,
      hideChildCol: true,
      parentComponent: getEditCellComponent,
      parentMaxWidth: '10px',
    },
  ];

  const mapDataToTableResult = (data: IResult): any => {
    const result = {
      ...data,
      children: [data],
    } as any;
    return result;
  };

  useEffect(() => {
    const data = props.dataset;
    setTableData({ totalCount: data?.length, results: data?.map(mapDataToTableResult) });
    setLastReload(new Date());
  }, [props.dataset]);

  const getHistoryURL = (patientId: number, id: number) =>
    `/patients/${patientId}/financials/patient-incomes/${id}`;

  const handlePopoverSubmit = (value: any) => {
    const {
      // eslint-disable-next-line no-shadow
      editIncome,
    } = props;
    const { field, parent: income } = popoverState;
    const payload = {
      id: income?.id,
      patient_id: income?.patient_id,
      unemployment_start_date: income?.unemployment_start_date
        ? convertToArborDate(income.unemployment_start_date).getUtcDate()
        : null,
      unemployment_end_date: income?.unemployment_end_date
        ? convertToArborDate(income.unemployment_end_date).getUtcDate()
        : null,
      [field as string]: Number(value),
    } as any;

    if (field === 'is_verified' && value) {
      payload.verified_dt = convertToArborDate(moment()).getUtcDatetime();
    }

    editIncome(payload);

    setPopoverState(initialPopoverState);
  };
  // #endregion

  // #region renders
  const renderPopover = (): JSX.Element => {
    const handleCancel = () => setPopoverState(initialPopoverState);

    return (
      <Popover
        id="form"
        open={popoverState.open}
        anchorEl={popoverState?.anchorElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transitionDuration={{ exit: 0 }}
        onClose={() => setPopoverState(initialPopoverState)}
      >
        <FormField
          initialValue={popoverState.value}
          handleCancel={handleCancel}
          handleSubmit={handlePopoverSubmit}
          fieldType={popoverState.fieldType}
        />
      </Popover>
    );
  };

  const renderHistoryModal = () => {
    return (
      <Modal open={editHistoryModalOpen} onClose={() => setEditHistoryModalOpen(false)}>
        <div style={getModalStyle()} className={classes.Modal}>
          <History url={getHistoryURL(selectedItem.patient_id, selectedItem.id)} />
        </div>
      </Modal>
    );
  };

  return (
    <>
      {renderPopover()}
      {renderHistoryModal()}
      <Grid
        container
        paddingX={props.hideTitleHeader ? 0 : 4}
        className={classes.benefitTableContainer}
      >
        {!props.hideTitleHeader && (
          <Grid item xs={12}>
            <h2 className={classes.benefitTableTitle}>Income</h2>
          </Grid>
        )}
        <Grid item xs={12}>
          <BaseTable<IIncomesTableResponse, IResult, undefined>
            triggerReload={lastReload}
            actionsPermitted
            dataSet={tableData}
            orderByDefaultParent="income_year"
            paginationQueryParamSettings={PAGINATION_PARAMS}
            childPkSelector={() => ''}
            parentPkSelector={p => p.id}
            columnSettings={COLUMN_SETTINGS}
            searchTerm=""
            qaId="income"
          />
        </Grid>
      </Grid>
    </>
  );
};

function mapStateToProps(state: any) {
  const { lookups, uploadedDocuments } = state;

  return {
    users: lookups.users,
    uploadedDocuments,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      editIncome,
    },
    dispatch,
  );
}

export default compose<IIncomesTableProps, {}>(
  withStyles(styles as any),
  connect(mapStateToProps, mapDispatchToProps),
)(IncomesTable);

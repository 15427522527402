import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

export const ClinicalItemProtocolFormModalStyles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    modal: {
      minWidth: theme.spacing(100),
    },
    title: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(1),
      fontWeight: 500,
    },
    protocolType: {
      marginTop: theme.spacing(2),
      fontWeight: 500,
      paddingBottom: theme.spacing(2),
    },
    protocolSubtitle: {
      marginTop: theme.spacing(2.5),
      fontWeight: 500,
    },
    criteriaSubtitle: {
      marginTop: theme.spacing(1.5),
      paddingLeft: theme.spacing(2),
      fontSize: theme.spacing(1.6),
      fontWeight: 500,
    },
    titleWrp: {
      paddingBottom: 0,
    },
    actionsWrp: {
      padding: theme.spacing(4, 0, 3),
      margin: theme.spacing(0, 3),
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    protocolActionBtns: {
      'paddingLeft': theme.spacing(3),
      'marginBottom': theme.spacing(2),
      '& button': {
        color: palette.primary.black,
      },
    },
    protocolForm: {
      marginTop: theme.spacing(3),
    },
    centerIcon: {
      display: 'flex',
      justifyContent: 'right',
      cursor: 'pointer',
      marginTop: theme.spacing(2),
    },
    buttonDivider: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2.5),
      marginLeft: theme.spacing(2.5),
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    safetyProtocolDivider: {
      width: '100%',
      marginTop: theme.spacing(2.5),
      marginRight: theme.spacing(2.5),
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    fixedSection: {
      paddingLeft: theme.spacing(2),
      marginTop: theme.spacing(2.5),
      position: 'relative',
    },
    interventionCriteriaRow: {
      paddingLeft: theme.spacing(2),
      marginTop: theme.spacing(3),
    },
    interventionCriteriaFields: {
      marginTop: theme.spacing(0.1),
    },
    longText: {
      maxWidth: '100%',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingRight: theme.spacing(1.3),
      paddingLeft: theme.spacing(1.3),
    },
    disabledRow: {
      pointerEvents: 'none',
      opacity: '0.4',
    },
  });
};

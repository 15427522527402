import { useTheme } from '@mui/styles';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { IState } from 'interfaces/redux/IState';
import { convertToArborDate } from 'models/time/arbor-date';
import React, { useEffect, useState } from 'react';
import {
  IPatientMergeSectionDetailsProps,
  IPatientMergeTask,
  ITask,
} from 'components/patient-merge-modal/interfaces';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { useSelector } from 'react-redux';
import { addInput, removeInput, IPatientMergeInput } from 'actions/action-patient-merge';
import { PatientMergeClient } from 'clients/patient-merge';
import { LookupResourceTypes } from 'constants/enums';
import { isInputSelected } from 'components/patient-merge-modal/utils';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { DurStatus } from 'interfaces/enums/TaskStatuses/DrugUtilizationReviewStatus';
import { isEmpty } from 'lodash';
import { DUR, RS, AR } from '../../../../constants';

export const PatientTasksSection = (props: IPatientMergeSectionDetailsProps) => {
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);
  const { mergeId, expanded } = props;

  const [infos, setInfos] = useState<IPatientMergeTask[]>([]);
  const [primaryData, setPrimaryData] = useState<ITask[]>([]);
  const [selectedData, setSelectedData] = useState<ITask[]>([]);
  const [allSelected, setAllSelected] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const primary = useSelector((state: IState) => state.patientMerge.primary);
  const selectedProfileId = useSelector((state: IState) => state.patientMerge.duplicate);
  const inputs = useSelector((state: IState) => state.patientMerge.inputs);
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useAppDispatch();

  const disableCheckbox = (primaryTasks: ITask[], secondaryTask: ITask) => {
    const primaryTask = primaryTasks.find(
      ({ taskType }) => taskType.id === secondaryTask.taskType.id,
    );
    if (primaryTask) {
      switch (primaryTask.taskType.name) {
        case DUR:
          if (
            [DurStatus.Required, DurStatus.Generated, DurStatus.Intervening].includes(
              primaryTask.status.id,
            )
          ) {
            return true;
          }
          if (
            [DurStatus.Required, DurStatus.Generated, DurStatus.Intervening].includes(
              secondaryTask.status.id,
            )
          ) {
            return false;
          }
          break;
        case RS:
          return primaryTask.specialty_diagnosis_id === secondaryTask.specialty_diagnosis_id;
        case AR:
          return primaryTask.managing_clinic_id === secondaryTask.managing_clinic_id;
        default:
          return false;
      }
    }
    return false;
  };

  useEffect(() => {
    setPrimaryData(infos.find(info => info.patientId === primary)?.tasks || []);
  }, [infos, primary]);

  useEffect(() => {
    if (!loaded && expanded) {
      PatientMergeClient.getMergePatientTasksInfo(mergeId).then(response => {
        setInfos(response.data);
        setLoaded(true);
      });
    }
  }, [mergeId, expanded]);

  useEffect(() => {
    setSelectedData(infos.find(info => info.patientId === selectedProfileId)?.tasks || []);
  }, [infos, selectedProfileId]);

  const selectValue = (value: boolean, type: LookupResourceTypes, selectedId: number) => {
    const input: IPatientMergeInput = {
      type,
      selectedId,
    };
    dispatch(value ? addInput(input) : removeInput(input));
  };

  useEffect(() => {
    selectedData
      .filter(task => disableCheckbox(primaryData, task) === false)
      .map(task => selectValue(selectAll, task.taskType.id, task.id));
  }, [selectAll]);

  const handleCheckbox =
    (type: LookupResourceTypes, selectedId: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      selectValue(e.target.checked, type, selectedId);
    };

  const getLabel = (task: ITask) => {
    return [
      `${task.taskType.name} ${task.status.name}`,
      convertToArborDate(task.followUpDate).getCustomerDate(),
      task.typeName ? `Type: ${task.typeName}` : undefined,
    ]
      .filter(value => !!value)
      .join(' / ');
  };

  const disableSelectAll = isEmpty(
    selectedData.filter(task => disableCheckbox(primaryData, task) === false),
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControlLabel
          sx={classes.checkboxFormControl}
          label="Check all"
          control={
            <Checkbox
              sx={classes.checkbox}
              disabled={disableSelectAll}
              onChange={event => setSelectAll(event.target.checked)}
              checked={selectAll}
            />
          }
        />
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          {selectedData?.map(task => (
            <FormControlLabel
              sx={classes.checkboxFormControl}
              label={getLabel(task)}
              control={
                <Checkbox
                  sx={classes.checkbox}
                  onChange={handleCheckbox(task.taskType.id, task.id)}
                  checked={isInputSelected(task.id, task.taskType.id, inputs)}
                  disabled={disableCheckbox(primaryData, task)}
                />
              }
            />
          ))}
        </Grid>
        <Grid item xs={6}>
          {primaryData.map(task => (
            <Typography sx={classes.staticTextItem}>{getLabel(task)}</Typography>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

import React from 'react';
import { getStatusByStatusId, getReasonsByStatus } from 'services/utils/task-service';
import { renderDropdown as RenderDropdown } from 'components/form/field/redux-field';

export default ({ providers, label, input, meta, disabled, labelSize, qaId }) => {
  const { statuses, formValues } = providers;
  const reasons = getReasonsByStatus(
    statuses,
    getStatusByStatusId(formValues.status_id, statuses),
  ).filter(it => it.label);

  return (
    <RenderDropdown
      label={label}
      input={input}
      meta={meta}
      disabled={disabled}
      labelSize={labelSize}
      id={qaId}
      fields={reasons}
    />
  );
};

import useStore from 'hooks/useStore';
import { UserRoles } from 'constants/enums';

export default ({
  resource,
  roles = Object.values(UserRoles),
  actions,
  children,
  and = false,
  not = false,
}) => {
  const [user] = useStore('auth.currentUser');
  const { role } = user;
  let valid = false;
  if (user) {
    if (user.isSuperUser || roles.includes(role)) {
      valid = true;
    } else if (and) {
      valid = actions.every(a =>
        user.roles.find(r => r.resource_name === resource && r.resource_action === a),
      );
    } else {
      valid = user.roles.find(
        r => r.resource_name === resource && actions.includes(r.resource_action),
      );
    }
    if (not) {
      valid = !valid;
    }
  }
  if (valid) {
    return children;
  }
  return null;
};

import React, { useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { IMedSyncRowData } from 'models/meds/IMedSync';
import { useDispatch } from 'react-redux';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import MedSyncAnchorDate from './medSyncAnchorDate';
import { MedSyncStatusIcon, MedSyncTooltip } from './medSyncIcon';
import { MedSyncModal } from './modal/medSyncModal';
import { useMedSyncData, useMedSyncIconState, useMedSyncPreferences } from '../med-sync-state';
import { submitMedSyncUpdates } from '../med-sync-utils';
import { IMedSyncFormFields } from '../common';
import { medSyncUpdateSuccess } from '../med-sync-actions';

export default function MedSyncBanner() {
  const [isModalOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const currentMedSyncPreferences = useMedSyncPreferences();
  const currentMedSyncData = useMedSyncData();
  const { tooltipTitle, displayStatus } = useMedSyncIconState();
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    async (formValues: IMedSyncFormFields, medSyncData: IMedSyncRowData[]) => {
      if (isLoading) return;
      setLoading(true);
      try {
        await submitMedSyncUpdates(
          formValues,
          currentMedSyncPreferences,
          medSyncData,
          currentMedSyncData,
        );
        dispatch(medSyncUpdateSuccess({ formValues, medSyncData }));
        dispatch(notifySuccess('Med Sync successfully updated.'));
        setOpen(false);
      } catch (err) {
        dispatch(notifyError('Could not update Med Sync. Please try again.'));
      }
      setLoading(false);
    },
    [currentMedSyncPreferences, currentMedSyncData, dispatch, isLoading, setLoading],
  );

  return (
    <>
      <Box
        borderRadius="3px"
        bgcolor="rgba(142, 166, 188, 0.1)"
        marginTop="8px"
        marginLeft="0px"
        marginRight="0px"
        paddingX="4px"
        width="100%"
        sx={{ display: 'flex' }}
      >
        <MedSyncTooltip title={tooltipTitle}>
          <Box
            alignItems="center"
            sx={{ cursor: 'pointer', display: 'inline-flex' }}
            onClick={() => setOpen(true)}
          >
            <MedSyncStatusIcon displayStatus={displayStatus} />
            {currentMedSyncPreferences.anchorDate && (
              <MedSyncAnchorDate
                anchorDate={currentMedSyncPreferences.anchorDate}
                displayStatus={displayStatus}
                sx={{ marginLeft: '6px', flex: '0 0 auto' }}
              />
            )}
          </Box>
        </MedSyncTooltip>
      </Box>
      {isModalOpen ? (
        <MedSyncModal
          open={isModalOpen}
          isLoading={isLoading}
          onCancel={() => setOpen(false)}
          onSubmit={handleSubmit}
          currentMedSyncData={currentMedSyncData}
          currentMedSyncPreferences={currentMedSyncPreferences}
        />
      ) : null}
    </>
  );
}

import React, { Component } from 'react';
import { Grid, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { INT, OR, AR, ON_GOING_APPOINTMENT_REFERRAL } from 'constants/index';
import Dropdown from 'components/form/field/dropdown';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import SubHeader from 'components/form/header/subheader';
import { styles } from 'containers/tasks/task-detail-styles';

import AddIntervention from 'containers/tasks/interventions/add-intervention';
import AddOutreach from 'containers/tasks/outreach/add-outreach';
import AddAppointmentReferral from 'containers/tasks/ar/add-appointment-referral';
import { getUrlQueryParam } from '../../../helpers/router';

class AddChecklistTask extends Component {
  constructor(props) {
    super(props);
    const isTaskTypeAR = getUrlQueryParam(props.location, 'selectedType') === AR;
    this.state = {
      selectedType: isTaskTypeAR ? AR : -1,
    };
    this.handleTaskType = this.handleTaskType.bind(this);
    this.resetTaskStatus = this.resetTaskStatus.bind(this);
  }

  handleTaskType(e) {
    this.setState({
      selectedType: e.target.value,
    });
  }

  resetTaskStatus() {
    const { handleCancel } = this.props;
    handleCancel();
    this.setState({
      selectedType: -1,
    });
  }

  render() {
    const {
      classes,
      taskCounts,
      patient,
      handleCancel,
      isTaskSidebar,
      serviceGroups,
      defaultServiceGroup,
      tasks,
    } = this.props;

    const { selectedType } = this.state;

    // Create dropdown list with allowed task types
    const taskTypes = [INT, OR];
    const statusArrForSelect = Object.keys(taskCounts)
      .filter(task => taskTypes.includes(task))
      .map(field => ({
        key: field,
        value: taskCounts[field].task_description,
      }));

    // Adds AR to the option list
    const hasOngoingAR = Object.values(tasks)?.some(
      task => task.taskType === AR && ON_GOING_APPOINTMENT_REFERRAL.includes(task.status_id),
    );

    statusArrForSelect.push({
      key: AR,
      value: `${taskCounts[AR].task_description}${hasOngoingAR ? ' (Check ongoing AR)' : ''}`,
      disabled: hasOngoingAR,
    });

    const renderConditionalDetailFields = statusSelectIdParam => {
      switch (statusSelectIdParam) {
        case AR:
          return (
            <AddAppointmentReferral
              patient={patient}
              cancel={this.resetTaskStatus}
              isTaskSidebar={isTaskSidebar}
              serviceGroups={serviceGroups}
              defaultServiceGroup={defaultServiceGroup}
              therapy={null}
            />
          );
        case INT:
          return (
            <Grid container>
              <AddIntervention
                patient={patient}
                cancel={this.resetTaskStatus}
                isTaskSidebar={isTaskSidebar}
                serviceGroups={serviceGroups}
                defaultServiceGroup={defaultServiceGroup}
                therapy={null}
              />
            </Grid>
          );
        case OR:
          return (
            <Grid container>
              <AddOutreach
                patient={patient}
                cancel={this.resetTaskStatus}
                isTaskSidebar={isTaskSidebar}
                serviceGroups={serviceGroups}
                defaultServiceGroup={defaultServiceGroup}
                therapy={null}
              />
            </Grid>
          );
        default:
          return (
            <div style={{ textAlign: 'right' }}>
              <Button
                name="add_task_cancel_button"
                type="button"
                onClick={handleCancel}
                className={classes.button}
              >
                Cancel
              </Button>
            </div>
          );
      }
    };
    return (
      <Grid container className={classes.addForm}>
        <Grid container spacing={7} className={classes.statusContainer}>
          <Grid item xs={12}>
            <SubHeader name="New Task" />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              label="Task Type"
              state={selectedType}
              handler={this.handleTaskType}
              fields={statusArrForSelect}
              name="task_type"
              width="50%"
              dataQaId="button-task-type"
            />
          </Grid>
          <Grid item xs={12} className={classes.taskContainer}>
            {renderConditionalDetailFields(selectedType)}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    patient: state.patient,
    tasks: state.tasks.data,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {}),
  withRouter,
)(AddChecklistTask);

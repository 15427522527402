import HTTP from 'services/http';
import {
  ADD_ALLERGY_FLOW,
  EDIT_ALLERGY_FLOW,
  UPDATE_ALLERGY_VERIFICATION_STATUS_SUCCESS,
  FETCH_ALLERGIES_REQUEST,
  MARK_EMR_CONFIRMED_ALLERGIES_SUCCESS,
  MARK_PATIENT_CONFIRMED_ALLERGIES_SUCCESS,
} from 'constants/index';

export function fetchAllergies() {
  return {
    type: FETCH_ALLERGIES_REQUEST,
  };
}

export function addAllergy(payload) {
  return {
    type: ADD_ALLERGY_FLOW,
    payload,
  };
}

export function editAllergy(payload) {
  return {
    type: EDIT_ALLERGY_FLOW,
    payload,
  };
}

export function verifyAllergies(payload) {
  const request = HTTP.post(`/patients/${payload.patient_id}/allergies/_verify`, payload, {});
  return {
    type: UPDATE_ALLERGY_VERIFICATION_STATUS_SUCCESS,
    payload: request,
  };
}

export function markEmrConfirmed(patientId, confirmed) {
  const request = HTTP.post(`/patients/${patientId}/allergies/_mark_emr_confirmed`, {
    confirmed,
  });
  return {
    type: MARK_EMR_CONFIRMED_ALLERGIES_SUCCESS,
    payload: request,
  };
}

export function markPatientConfirmedAllergies(patientId, confirmed) {
  const request = HTTP.post(`/patients/${patientId}/allergies/_mark_patient_confirmed`, {
    confirmed,
  });
  return {
    type: MARK_PATIENT_CONFIRMED_ALLERGIES_SUCCESS,
    payload: request,
  };
}

import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import { convertMapToList } from 'reducers/helper-reducer';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { styles } from './styles';
import {
  IPatientEnrollmentProps,
  IPatientEnrollmentSectionDetailsProps,
  IPatientEnrollmentTabProps,
} from './types';
import { EligibilityCheckButton } from '../../containers/patient/financials/eligibility/eligibility-check';
import { ISection } from '../section/interfaces';
import { PatientEnrollmentSection } from './patient-enrollment-section';
import { PatientEnrollmentMedicationSection } from './medication-section';
import { PatientEnrollmentDemographicSection } from './demographic-section';
import { PatientEnrollmentClinicalSection } from './clinical-section';
import { PatientEnrollmentPbmSection } from './pbm-section';
import { PatientEnrollmentMedInsuranceSection } from './med-insurance-section';
import { PatientEnrollmentIncomeSection } from './income-section';

const useStyles = makeStyles(styles);

export const PATIENT_ENROLLMENT_TAB_ID = 'patient-enrollment';
const PbmInsuranceTitle = 'Pharmacy Benefit';
const MedicalInsuranceTitle = 'Medical Insurance';
const IncomeTitle = 'Income';
const DemographicsTitle = 'Demographics';
const ClinicalTitle = 'Clinical';
const MedicationListTitle = 'Medication List';

const sections: ISection<IPatientEnrollmentProps>[] = [
  {
    title: PbmInsuranceTitle,
    render: (props: IPatientEnrollmentSectionDetailsProps) => (
      <PatientEnrollmentPbmSection {...props} />
    ),
  },
  {
    title: MedicalInsuranceTitle,
    render: (props: IPatientEnrollmentSectionDetailsProps) => (
      <PatientEnrollmentMedInsuranceSection {...props} />
    ),
  },
  {
    title: IncomeTitle,
    render: (props: IPatientEnrollmentSectionDetailsProps) => (
      <PatientEnrollmentIncomeSection {...props} />
    ),
  },
  {
    title: DemographicsTitle,
    render: (props: IPatientEnrollmentSectionDetailsProps) => (
      <PatientEnrollmentDemographicSection {...props} />
    ),
  },
  {
    title: ClinicalTitle,
    render: (props: IPatientEnrollmentSectionDetailsProps) => (
      <PatientEnrollmentClinicalSection {...props} />
    ),
  },
  {
    title: MedicationListTitle,
    render: (props: IPatientEnrollmentSectionDetailsProps) => (
      <PatientEnrollmentMedicationSection {...props} />
    ),
  },
];

export function PatientEnrollmentTab(props: IPatientEnrollmentTabProps) {
  const { visible, onClose, ...otherProps } = props;
  const [expanded, setExpanded] = useState<undefined | number>(undefined);
  const countMap = useTypedSelector<{ [sectionTitle: string]: number }>(state => ({
    [PbmInsuranceTitle]: convertMapToList(state.financials.pbm_insurances.data).length,
    [MedicalInsuranceTitle]: convertMapToList(state.financials.medical_insurances.data).length,
    [MedicationListTitle]: state.medications.medicationGroups.length,
    [IncomeTitle]: convertMapToList(state.financials.incomes.data).length,
  }));

  const handleChange = (idx: number) => async (_: unknown, isExpanded: boolean) => {
    setExpanded(isExpanded ? idx : undefined);
  };

  const classes = useStyles(styles);
  if (visible) {
    return (
      <div className={classes.content}>
        <Grid container rowSpacing={2} alignItems="center">
          <Grid item xs={9}>
            <Typography variant="h6">Patient Enrollment</Typography>
          </Grid>
          <Grid item xs={3} className={classes.eligibilityButtonWrp}>
            <EligibilityCheckButton />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {sections.map((section, idx) => (
            <Grid item xs={12}>
              <PatientEnrollmentSection
                key={`section-${section.title}`}
                section={{ ...section, count: countMap[section.title] }}
                expanded={idx === expanded}
                onChange={handleChange(idx)}
                {...otherProps}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }

  return <></>;
}

import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector, connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { formValueSelector } from 'redux-form';
import {
  UPLOAD_DOCUMENT_FORM,
  UPLOAD_DOC_FILENAME_FIELD,
  UPLOAD_DOC_FILENOTE_FIELD,
  UPLOAD_DOC_FILETYPE_FIELD,
} from '../../constants';
import { updateDocument } from '../../services/utils/upload-document-service';
import { updateDocumentBar, updateNewDocument } from '../../actions/action-uploaded-documents';
import { styles } from './document-bar-styles';
import DocumentForm from './document-form';

const UploadNewDocument = props => {
  const { classes, fileTypes, fileNote } = props;
  const dispatch = useDispatch();

  const [documentLabels, setDocumentLabels] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();

  const customerId = useSelector(state => state.filters.selectedCustomerId);
  const patientId = useSelector(state => state.selectedPatientId);

  const { documentId, tags, filePrefix, fileExtension, hasNote } = useSelector(
    state => state.documentBar,
  );

  const defaultDocumentTypes = useSelector(state => state.lookups.defaultDocumentLabels);

  useEffect(() => {
    if (defaultDocumentTypes) {
      const types = defaultDocumentTypes.map(dt => ({
        label: dt.label,
        value: dt.label,
      }));
      setDocumentLabels(types);
    }
  }, [defaultDocumentTypes]);

  const resetForm = () => {
    setErrorMessage();
  };

  const handleSubmit = async () => {
    setDisableSubmit(true);
    try {
      let labels = [];
      if (fileTypes) {
        labels = Array.isArray(fileTypes) ? fileTypes : fileTypes.split(',');
      }
      const fullFileName = `${filePrefix}.${fileExtension}`;
      const updateDocumentResult = await updateDocument(
        customerId,
        patientId,
        documentId,
        fullFileName,
        labels,
        fileNote,
      );
      dispatch(updateNewDocument(updateDocumentResult));
      resetForm();
      setSuccessMessage('File updated');
      setDisableSubmit(false);
      dispatch(updateDocumentBar({ hasNote: fileNote }));
    } catch (error) {
      setDisableSubmit(false);
      setSuccessMessage();
      setErrorMessage('Updating your file failed. Please try again.');
    }
  };

  const handleCancel = () => {
    dispatch(
      updateDocumentBar({
        display: false,
        tagTypeId: undefined,
        resourceId: undefined,
        drugName: undefined,
        tagTypeLabel: undefined,
        update: undefined,
        hasNote: false,
      }),
    );
  };

  return (
    <DocumentForm
      fileSelected
      formTitle="Update"
      documentLabels={documentLabels}
      submitDisabled={disableSubmit}
      cancelDisabled={disableSubmit}
      tags={tags}
      submitButtonText="Update"
      formSubmit={handleSubmit}
      onCancel={handleCancel}
      errorMessage={errorMessage}
      successMessage={successMessage}
      fileName={filePrefix}
      fileExtension={fileExtension}
      noteFieldDisabled={hasNote}
      classes={classes}
    />
  );
};

const mapStateToProps = state => {
  const selector = formValueSelector(UPLOAD_DOCUMENT_FORM);
  const fileName = selector(state, UPLOAD_DOC_FILENAME_FIELD);
  const fileTypes = selector(state, UPLOAD_DOC_FILETYPE_FIELD);
  const fileNote = selector(state, UPLOAD_DOC_FILENOTE_FIELD);

  return { fileName, fileTypes, fileNote, form: UPLOAD_DOCUMENT_FORM };
};

export default compose(withStyles(styles), connect(mapStateToProps))(UploadNewDocument);

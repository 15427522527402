import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'recompose';
import { SmallSpacer } from 'components/spacer/spacer';
import { withRouter } from 'react-router';
import { Loader } from 'components/loading-overlay/loading-overlay';
import { convertMapToList } from 'reducers/helper-reducer';
import { ReactSelect } from 'components/form/field/react-select';
import { convertToArborDate } from 'models/time/arbor-date';

import AddIncome from './incomes/add-income';
import AddMedicalInsurance from './medical-insurances/add-medical-insurance';
import BenefitsTable from './tables/benefits-table';
import IncomesTable from './tables/incomes-table';
import FinancialAssistancesTable from './tables/financial-assistances-table';
import AddPbmInsurance from './pbm-insurances/add-pbm-insurance';
import { styles } from './financials-styles';
import AddFAData from '../../tasks/fa/add-fa-data';
import { EligibilityCheckButton } from './eligibility/eligibility-check';

import {
  ToggleDisplayAddIncome,
  ToggleDisplayAddMedicalInsurance,
  ToggleDisplayAddPBMInsurance,
  ToggleDisplayAddFinancialAssistance,
} from '../../../actions/action-form-displays';

class FinancialsList extends Component {
  constructor(props) {
    super(props);
    this.handleCancelClick = this.handleCancelClick.bind(this);

    this.state = {
      filteredCategories: [{ value: 'all', label: 'All' }],
      filteredStatus: { value: 'active', label: 'Active' },
    };
  }

  componentWillUnmount() {
    const { onCancelForms } = this.props;
    onCancelForms();
  }

  handleCancelClick() {
    const { onCancelForms } = this.props;
    onCancelForms();
  }

  handleStatusChange = selectedOption => {
    this.setState({ filteredStatus: selectedOption });
  };

  handleCategoriesChange = selectedOption => {
    const previouslySelected = this.state.filteredCategories.some(x => x.value === 'all');
    if (
      (selectedOption.some(option => option.value === 'all') && !previouslySelected) ||
      selectedOption.length === 0
    ) {
      this.setState({ filteredCategories: [{ value: 'all', label: 'All' }] });
      return;
    }
    const filteredCategories = selectedOption.filter(option => option.value !== 'all');
    this.setState({ filteredCategories });
  };

  filterStatus = item => {
    const { filteredStatus } = this.state;
    const filterStatusValue = filteredStatus?.value;

    switch (filterStatusValue) {
      case 'all':
        return true;
      case 'active':
        return (
          !convertToArborDate(item.end_date || item.expiration_date, true).isBeforeToday() ||
          item.valid ||
          item.status
        );
      case 'inactive':
        return (
          (!item.valid && !item.status) ||
          convertToArborDate(item.end_date || item.expiration_date, true).isBeforeToday()
        );
      default:
        return false;
    }
  };

  shouldShowGrid = category => {
    const { filteredCategories } = this.state;
    return (
      filteredCategories.some(x => x.value === 'all') ||
      filteredCategories.some(x => x.value === category)
    );
  };

  render() {
    const {
      classes,
      patientId,
      displayAddIncome,
      displayAddMedical,
      displayAddPbm,
      displayAddFinancialAssistance,
      loading,
      financials,
    } = this.props;
    const { filteredCategories, filteredStatus } = this.state;

    const pbmInsurances = convertMapToList(financials.pbm_insurances.data)
      .sort((a, b) => a.insurance_type_id - b.insurance_type_id)
      .filter(this.filterStatus);
    const medicalInsurances = convertMapToList(financials.medical_insurances.data).filter(
      this.filterStatus,
    );
    const incomes = convertMapToList(financials.incomes.data).sort(
      (a, b) => a.insurance_type_id - b.insurance_type_id,
    );
    const financialAssistances = convertMapToList(financials.financial_assistances.data).filter(
      this.filterStatus,
    );

    return (
      <div className={classes.loadContainer}>
        <Loader loaded={!loading}>
          <Grid container paddingX={4} marginTop={2} alignItems="center">
            <Grid item xs={4} paddingRight="20px">
              <ReactSelect
                label="Income & Benefits"
                value={filteredCategories}
                handleOnChange={this.handleCategoriesChange}
                fields={[
                  { value: 'all', label: 'All' },
                  { value: 'pharmacy', label: 'Pharmacy Benefit' },
                  { value: 'medical', label: 'Medical Benefit' },
                  { value: 'income', label: 'Income' },
                  { value: 'financial', label: 'Financial Assistance' },
                ]}
              />
            </Grid>
            <Grid item xs={4} paddingRight="20px">
              <ReactSelect
                label="Status"
                value={filteredStatus}
                isMulti={false}
                handleOnChange={this.handleStatusChange}
                fields={[
                  { value: 'all', label: 'All' },
                  { value: 'active', label: 'Active' },
                  { value: 'inactive', label: 'Inactive' },
                ]}
              />
            </Grid>
            <Grid item xs={4} justifyContent="flex-end" display="flex">
              <EligibilityCheckButton />
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            {displayAddIncome && (
              <AddIncome patientId={patientId} cancel={this.handleCancelClick} />
            )}
            {displayAddMedical && (
              <AddMedicalInsurance patientId={patientId} cancel={this.handleCancelClick} />
            )}
            {displayAddPbm && (
              <AddPbmInsurance patientId={patientId} cancel={this.handleCancelClick} />
            )}
            {displayAddFinancialAssistance && (
              <AddFAData
                handleCancel={this.handleCancelClick}
                patientId={patientId}
                addingFromFinancials
              />
            )}
          </Grid>
          <SmallSpacer />
          <div>
            {this.shouldShowGrid('pharmacy') && (
              <BenefitsTable dataset={pbmInsurances} type="pharmacyBenefit" />
            )}
            {this.shouldShowGrid('medical') && (
              <BenefitsTable dataset={medicalInsurances} type="medicalBenefit" />
            )}
            {this.shouldShowGrid('income') && <IncomesTable dataset={incomes} />}
            {this.shouldShowGrid('financial') && (
              <FinancialAssistancesTable dataset={financialAssistances} />
            )}
          </div>
        </Loader>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onToggleIncomeFormDisplay: bool => {
      dispatch(new ToggleDisplayAddIncome(bool));
    },
    onToggleMedicalInsuranceFormDisplay: bool => {
      dispatch(new ToggleDisplayAddMedicalInsurance(bool));
    },
    onTogglePBMInsuranceFormDisplay: bool => {
      dispatch(new ToggleDisplayAddPBMInsurance(bool));
    },
    onToggleFinancialAssistanceFormDisplay: bool => {
      dispatch(new ToggleDisplayAddFinancialAssistance(bool));
    },
    onCancelForms: () => {
      dispatch(new ToggleDisplayAddIncome(false));
      dispatch(new ToggleDisplayAddMedicalInsurance(false));
      dispatch(new ToggleDisplayAddPBMInsurance(false));
      dispatch(new ToggleDisplayAddFinancialAssistance(false));
    },
  };
}

function mapStateToProps(state) {
  const { patient, financials, audit } = state;
  return {
    patient,
    audit,
    financials,
    displayAddIncome: state.formDisplays.displayAddIncome,
    displayAddMedical: state.formDisplays.displayAddMedicalInsurance,
    displayAddPbm: state.formDisplays.displayAddPBMInsurance,
    displayAddFinancialAssistance: state.formDisplays.displayAddFinancialAssistance,
    selectedPatientId: state.selectedPatientId,
    loading:
      financials.medical_insurances.loading ||
      financials.pbm_insurances.loading ||
      financials.incomes.loading,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(FinancialsList);

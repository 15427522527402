import {
  CLEAR_TASK_FILTERS_BY_KEYS,
  UPDATE_TASK_FILTERS,
  UPDATE_TASK_FILTER_FROM_DATE,
  UPDATE_TASK_FILTER_TO_DATE,
  UPDATE_TASK_FILTER_COMPLETED,
  ADD_TASK_FILTER_SERVICE_GROUP,
  DELETE_TASK_FILTER_SERVICE_GROUP,
  UPDATE_SCHEDULE_FILTERS,
  UPDATE_SCHEDULE_FILTER_FROM_TO_DATES,
  UPDATE_SCHEDULE_FILTER_FROM_DATE,
  UPDATE_SCHEDULE_FILTER_TO_DATE,
  UPDATE_SCHEDULE_SERVICE_GROUP,
  UPDATE_SCHEDULE_CLINICS,
  UPDATE_REPORT_FILTER_FROM_DATE,
  UPDATE_REPORT_FILTER_TO_DATE,
  UPDATE_REPORT_SERVICE_GROUP,
  UPDATE_REPORT_CLINICS,
  UPDATE_TASK_SERVICE_GROUP,
  UPDATE_TASK_CLINICS,
  FETCH_CUSTOMERS,
  SELECT_CUSTOMER,
  FETCH_EMRS,
  TOGGLE_TASKLIST_SORT,
  SET_TASKLIST_SORT,
  UPDATE_REPORT_FILTERS,
} from 'constants/index';

const initialState = {
  taskFromDate: null,
  taskToDate: null,
  taskServiceGroups: [],
  taskClinics: [],
  taskShowCompleted: false,
  scheduleFromDate: null,
  scheduleToDate: null,
  scheduleServiceGroups: [],
  scheduleClinics: [],
  reportFromDate: null,
  reportToDate: null,
  reportServiceGroups: [],
  reportClinics: [],
  customers: [],
  selectedCustomerId: null,
  emrs: [],
  perProtocol: false,
};

const parseString = str => (str === 'null' ? null : str);

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_TASK_FILTERS:
      return {
        ...state,
        taskFromDate: parseString(payload.taskFromDate),
        taskToDate: parseString(payload.taskToDate),
        therapyFromDate: parseString(payload.taskFromDate),
        therapyToDate: parseString(payload.taskToDate),
        taskServiceGroups: payload.taskServiceGroups,
        taskClinics: payload.taskClinics,
      };
    case UPDATE_TASK_FILTER_FROM_DATE:
      return {
        ...state,
        taskFromDate: parseString(payload.taskFromDate),
        therapyFromDate: parseString(payload.taskFromDate),
      };
    case UPDATE_TASK_FILTER_TO_DATE:
      return {
        ...state,
        taskToDate: parseString(payload.taskToDate),
        therapyToDate: parseString(payload.taskToDate),
      };
    case UPDATE_TASK_FILTER_COMPLETED:
      return { ...state, taskShowCompleted: payload };
    case ADD_TASK_FILTER_SERVICE_GROUP:
      return { ...state, taskServiceGroups: payload.taskServiceGroups };
    case DELETE_TASK_FILTER_SERVICE_GROUP:
      return {
        ...state,
        taskServiceGroups: payload.taskServiceGroups,
      };
    case UPDATE_TASK_SERVICE_GROUP:
      return {
        ...state,
        taskServiceGroups: payload.taskServiceGroups,
      };
    case UPDATE_TASK_CLINICS:
      return {
        ...state,
        taskClinics: payload.taskClinics,
      };
    case UPDATE_SCHEDULE_FILTERS:
      return {
        ...state,
        scheduleFromDate: parseString(payload.scheduleFromDate),
        scheduleToDate: parseString(payload.scheduleToDate),
        scheduleServiceGroups: payload.scheduleServiceGroups,
        scheduleClinics: payload.scheduleClinics,
      };

    case UPDATE_SCHEDULE_FILTER_FROM_TO_DATES:
      return {
        ...state,
        scheduleFromDate: parseString(payload.scheduleFromDate),
        scheduleToDate: parseString(payload.scheduleToDate),
      };
    case UPDATE_SCHEDULE_FILTER_FROM_DATE:
      return {
        ...state,
        scheduleFromDate: parseString(payload.scheduleFromDate),
      };
    case UPDATE_SCHEDULE_FILTER_TO_DATE:
      return {
        ...state,
        scheduleToDate: parseString(payload.scheduleToDate),
      };
    case UPDATE_SCHEDULE_SERVICE_GROUP:
      return {
        ...state,
        scheduleServiceGroups: payload.scheduleServiceGroups,
      };
    case UPDATE_SCHEDULE_CLINICS:
      return {
        ...state,
        scheduleClinics: payload.scheduleClinics,
      };
    case UPDATE_REPORT_FILTERS:
      return {
        ...state,
        reportFromDate: parseString(payload.reportFromDate),
        reportToDate: parseString(payload.reportToDate),
        reportServiceGroups: payload.reportServiceGroups,
        reportClinics: payload.reportClinics,
      };
    case UPDATE_REPORT_FILTER_FROM_DATE:
      return {
        ...state,
        reportFromDate: parseString(payload.reportFromDate),
      };
    case UPDATE_REPORT_FILTER_TO_DATE:
      return {
        ...state,
        reportToDate: parseString(payload.reportToDate),
      };
    case UPDATE_REPORT_SERVICE_GROUP:
      return {
        ...state,
        reportServiceGroups: payload.reportServiceGroups,
      };
    case UPDATE_REPORT_CLINICS:
      return {
        ...state,
        reportClinics: payload.reportClinics,
      };
    case FETCH_CUSTOMERS:
      if (payload.data && payload.data.customers) {
        return {
          ...state,
          customers: payload.data.customers,
          selectedCustomerId: payload.data.customers.find(customer => customer.selected === true)
            .id,
        };
      }
      return state;
    case SELECT_CUSTOMER:
      return {
        ...state,
        selectedCustomerId: payload.customerId,
      };
    case FETCH_EMRS:
      if (payload.data) {
        return {
          ...state,
          emrs: payload.data.emrs,
        };
      }
      return state;
    case SET_TASKLIST_SORT: {
      return {
        ...state,
        perProtocol: false,
      };
    }
    case TOGGLE_TASKLIST_SORT:
      return {
        ...state,
        perProtocol: false,
      };
    case CLEAR_TASK_FILTERS_BY_KEYS: {
      const { keys, ids } = payload;
      if (keys[0] === 'taskServiceGroups') {
        const clinicsAssociatedWithServiceGroup = state.taskClinics.filter(
          clinic => clinic.serviceGroupId === ids,
        );
        state.taskClinics = clinicsAssociatedWithServiceGroup;
      }
      if (!ids) {
        const keysToClear = {};
        keys.forEach(key => {
          keysToClear[key] = initialState[key];
        });
        return {
          ...state,
          ...keysToClear,
        };
      }
      const keysToModify = {};
      keys.forEach(key => {
        const filterByIds = state[key];
        const newIds = filterByIds.filter(filteredId => {
          if (typeof filteredId === 'object') {
            return !ids.includes(filteredId.idValue);
          }
          return !ids.includes(filteredId);
        });
        keysToModify[key] = newIds;
      });
      return {
        ...state,
        ...keysToModify,
      };
    }
    default:
      return state;
  }
};

import moment from 'moment';
import { convertToArborDate } from 'models/time/arbor-date';
import { methodOptions, ancillarySupplies, rsCancelReasons } from 'constants/lists';
import _ from 'lodash';

export const staticProviders = {
  yesNo: [
    { id: 1, name: 'Yes' },
    { id: 2, name: 'No' },
  ],
  yesNo01: [
    { id: 1, name: 'Yes' },
    { id: 0, name: 'No' },
  ],
  medSync: [
    { id: 1, name: 'Undecided' },
    { id: 2, name: 'Opt In' },
    { id: 3, name: 'Opt Out' },
  ],
};

export const getDateTimeForProvider = date =>
  moment(convertToArborDate(date).getCustomerDatetime(true));

export const getDateForProvider = date => moment(convertToArborDate(date).getCustomerDate(true));

export const getCommonProviders = (task, state, json) => {
  const {
    lookups,
    taskStatuses,
    fillCycles,
    formValues,
    therapies,
    orders,
    form,
    currentUser,
    auth,
    pharmacies,
  } = state;
  const statuses = taskStatuses ? taskStatuses.statuses[task.taskType.toLowerCase()] || [] : [];
  const jsonStatuses = json.statuses;
  const serviceGroups = lookups && lookups.serviceGroups;

  const providers = {
    auth,
    serviceGroups,
    lookups,
    task,
    form,
    fillCycles:
      fillCycles && task.therapy_id && fillCycles[task.therapy_id]
        ? Object.keys(fillCycles[task.therapy_id])
            .map(k => fillCycles[task.therapy_id][k])
            .map(cycle => ({
              name: `#${cycle.fill_cycle} NBD: ${convertToArborDate(
                cycle.needsby_date,
                true,
              ).getUtcDate(true)}`,
              id: cycle.fill_cycle,
            }))
        : [],
    formValues,
    jsonStatuses,
    currentUser,
    order: (orders && orders.list && orders.list[task.order_id]) || null,
    diagnosisCode: task.therapy_id ? (therapies[task.therapy_id] || {}).diagnosis_code : '',
    isSpecialtyTherapy:
      task.therapy_id && therapies
        ? Boolean((therapies[task.therapy_id] || {}).is_specialty)
        : false,
    statuses: statuses.map(status => ({
      ...status,
      name: status.status,
      status: jsonStatuses.find(it => it.name === status.status),
    })),
    fillCycleFormatter: () => {
      if (
        task &&
        task.fill_cycle_number &&
        task.therapy_id &&
        fillCycles &&
        fillCycles[task.therapy_id] &&
        fillCycles[task.therapy_id][task.fill_cycle_number] &&
        fillCycles[task.therapy_id][task.fill_cycle_number].needsby_date
      ) {
        return `#${task.fill_cycle_number} ${convertToArborDate(
          fillCycles[task.therapy_id][task.fill_cycle_number].needsby_date,
          true,
        ).getUtcDate(true)}`;
      }
      return '-';
    },
    dateFormatter: value => (value ? value.format('MM/DD/YYYY') : '-'),
    // eslint-disable-next-line no-confusing-arrow
    datetimeFormatter: value => (value ? value.format('MM/DD/YYYY hh:mm A') : '-'),
    // eslint-disable-next-line no-confusing-arrow
    dateFormatterDisplay: value => (value ? convertToArborDate(value, true).getUtcDate(true) : '-'),
    // eslint-disable-next-line no-confusing-arrow
    datetimeFormatterDisplay: value =>
      value ? convertToArborDate(value, true).getCustomerDatetime(true) : '-',
    orderCollectionMethodFormatter: value => {
      return methodOptions.find(it => it.id === value)?.name || 'invalid method';
    },
    orderMethodFormatter: () => {
      return (
        (methodOptions.find(it => it.id === (orders.list[task.order_id] || {}).method) || {})
          .name || ''
      );
    },
    orderAddressFormatter: () => (orders.list[task.order_id] || {}).address || '',
    orderPhoneFormatter: () => (orders.list[task.order_id] || {}).phone || '',
    orderDeliveryDateFormatter: () => (orders.list[task.order_id] || {}).delivery_dt || '',
    orderShipDateFormatter: () => (orders.list[task.order_id] || {}).ship_dt || '',
    // eslint-disable-next-line no-confusing-arrow
    serviceGroupDisplayFormatter: value => {
      return serviceGroups.find(serviceGroup => serviceGroup.id === value)
        ? serviceGroups.find(serviceGroup => serviceGroup.id === value).display_name
        : '-';
    },
    diespensingPharmacyDisplayFormatter: value => {
      return pharmacies.find(pharmacy => pharmacy.id === value)
        ? pharmacies.find(pharmacy => pharmacy.id === value).name
        : '-';
    },
    booleanFormatter: value => (value ? 'Yes' : 'No'),
    checklistFormatter: (value, checklistOptions) =>
      checklistOptions
        .map(c => (value && c.id ? c.text : ''))
        .filter(c => c.length > 0)
        .join(', '),
    idNameFormatter: (value, options) =>
      options
        .map(o => (Number(value) === Number(o.id) ? o.name : '')) // eslint-disable-line
        .filter(c => c && c.length > 0)
        .join(', '),
    therapyNameFormatter: id => (therapies[id] ? therapies[id].drug_name : ''),
    statusReasonFormatter: (value, options) => {
      return options
        .map(o => (Number(value) === Number(o.id) ? o.reason : '')) // eslint-disable-line
        .filter(c => c.length > 0)
        .join(', ');
    },
    statusFormatter: id => statuses.find(status => status.id === id).status,
    ancillarySuppliesFormatter: value => {
      const jsonValue = JSON.parse(value);
      const filteredKeys = Object.keys(jsonValue).filter(c => jsonValue[c]);
      const supplies = filteredKeys.map(
        key =>
          ancillarySupplies &&
          ancillarySupplies.find(item => item.value === key) &&
          ancillarySupplies.find(item => item.value === key).label,
      );
      return supplies.join(', ');
    },
    idNameMultipleFormatter: (value, options) => {
      const valArr = String(value).split(',');
      return (
        valArr
          // eslint-disable-next-line no-confusing-arrow
          .map(val =>
            options.find(o => Number(val) === Number(o.id))
              ? options.find(o => Number(val) === Number(o.id)).name
              : '',
          )
          .filter(c => c.length > 0)
          .join(', ')
      );
    },
    NdcFormatter: value => {
      const jsonValue = JSON.parse(value);
      return jsonValue ? jsonValue.map(item => item.label) : value;
    },
    idNameCommaSeparatedFormatter: value => _.get(_.split(value, ','), '1', '-'),
    managingClinics:
      lookups && lookups.customerClinics
        ? lookups.customerClinics.map(clinic => ({
            id: clinic.id,
            name: clinic.name,
            npi: clinic.npi,
            isDisabled: !clinic.active,
          }))
        : [],
    rsCancelFormatter: value => {
      const jsonValue = JSON.parse(value);
      const filteredKeys = Object.keys(jsonValue).filter(c => jsonValue[c]);
      const supplies = filteredKeys.map(
        key =>
          rsCancelReasons &&
          rsCancelReasons.find(item => item.value === key) &&
          rsCancelReasons.find(item => item.value === key).label,
      );
      return supplies.join(', ');
    },
  };

  return providers;
};

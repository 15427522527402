import React from 'react';
import { renderRadioGroup as RenderRadioGroup } from 'components/form/field/redux-field';
import useProvider from 'hooks/useProvider';
import { stringValueLabelFormatter } from '../formatters';

export default ({ providers, field, label, input, meta, disabled, labelSize, qaId }) => {
  const fields = useProvider(providers, field.provider, [], stringValueLabelFormatter);

  return (
    <RenderRadioGroup
      label={label}
      input={input}
      meta={meta}
      disabled={disabled}
      labelSize={labelSize}
      qaId={qaId}
      radioMap={fields}
      view="list"
      radioWidth="initial"
      caption
    />
  );
};

import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import VerticalTabs from 'components/vertical-tabs';
import { getModalStyle } from 'services/utils/styles-service';
import { useSelector, useDispatch } from 'react-redux';
import compose from 'recompose/compose';
import { convertToArborDate } from 'models/time/arbor-date';
import { getUserById } from 'services/utils/users-service';
import { windowFeatureIsEnabled } from 'config/window-features';
import { parseDurVaccines } from 'components/vaccinations/hooks/use-patient-vaccines';
import { fetchDurHistory } from 'actions/action-drug-utilization-review';
// eslint-disable-next-line
import { DurHistoryRecordTypeEnum } from 'interfaces/enums/TaskStatuses/DrugUtilizationReviewStatus';
import RadioButton from 'components/form/radio/radio-button';
import RadioGroup from 'components/form/radio/radio-group';
import { TRELLIS_USER } from 'constants/index';
import DrugUtilizationReviewContainer from './drug-utilization-review-container';

import { styles } from './drug-utilization-review-styles';

const DrugUtilizationReviewHistory = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const users = useSelector(state => state.lookups.users);
  const vaccineLookups = useSelector(state => state.lookups.vaccines) || {};
  const patient = useSelector(state => state.patient);
  const { dur: { history: durHistory = null } = {}, id: patientId } = patient || {};

  const [allDurSnapshots, setAllDurSnapshots] = useState(false);

  useEffect(() => {
    if (patientId) {
      dispatch(fetchDurHistory({ patientId, allSnapshots: allDurSnapshots }));
    }
  }, [patientId, allDurSnapshots]);

  const arborUserDisplayName = getUserById(TRELLIS_USER, users).display_name;

  const renderLabel = durTask => {
    const reviewedBy = durTask ? getUserById(durTask.reviewed_by, users).username : '';
    const reviewedDate =
      durTask && convertToArborDate(durTask.reviewed_date)
        ? convertToArborDate(durTask.reviewed_date).getCustomerDatetime(true)
        : null;

    return (
      <Grid container>
        <Grid item xs="12">
          <Typography variant="body2">{`${reviewedDate} - ${reviewedBy}`}</Typography>
        </Grid>
      </Grid>
    );
  };

  const renderLabelForTriggerRecord = durRecord => {
    const createdDate = convertToArborDate(durRecord.created).getCustomerDatetime(true);

    return (
      <Grid container>
        <Grid item xs="12">
          <Typography variant="body2">{`${createdDate} - ${arborUserDisplayName}`}</Typography>
        </Grid>
      </Grid>
    );
  };

  const renderDurTriggerIntention = ({ durSnapshot }) => {
    const auditDate = convertToArborDate(durSnapshot.created).getCustomerDatetime(true);
    return (
      <Grid container>
        <Grid item xs="12">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs="6">
              <Typography variant="h6" component="span">
                Drug Utilization Review
              </Typography>
            </Grid>
            <Grid item xs="6">
              <Typography variant="body2" align="right">
                {`Generated ${auditDate} by ${arborUserDisplayName}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs="12">
          <Typography className={classes.nonDurInteractionWording}>
            No new interaction found
          </Typography>
          <Typography>
            <Box component="span" style={{ fontWeight: 'bold' }}>
              {durSnapshot.record_type === DurHistoryRecordTypeEnum.MedicationAdded
                ? 'Medication added: '
                : 'Allergy added: '}
            </Box>
            {durSnapshot.record_type === DurHistoryRecordTypeEnum.MedicationAdded
              ? durSnapshot.medication.name
              : durSnapshot.allergy.name}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      style={getModalStyle()}
      className={classes.modal}
    >
      {!!durHistory && durHistory.length ? (
        <>
          <Grid item xs="12">
            <Grid container direction="row" className={classes.serviceGroupContainer}>
              <Grid item xs={2}>
                <RadioGroup horizontal wrap onChange={() => setAllDurSnapshots(!allDurSnapshots)}>
                  <RadioButton value="all_durs_snapshots" checked={allDurSnapshots}>
                    All
                  </RadioButton>
                  <RadioButton value="automatic_durs_snapshots" checked={!allDurSnapshots}>
                    User Reviewed
                  </RadioButton>
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs="12">
            <VerticalTabs
              tabs={durHistory.map(durSnapshot =>
                durSnapshot.record_type === DurHistoryRecordTypeEnum.DurClosed
                  ? {
                      label: renderLabel(durSnapshot),
                      content: (
                        <DrugUtilizationReviewContainer
                          currentTab="tasks"
                          generateButtonEnabled={false}
                          durTask={durSnapshot}
                          isSnapshot
                          readOnly
                          isHistoryView
                        />
                      ),
                    }
                  : {
                      label: renderLabelForTriggerRecord(durSnapshot),
                      content: renderDurTriggerIntention({ durSnapshot }),
                    },
              )}
            />
          </Grid>
        </>
      ) : (
        <Typography variant="body1">No DUR history</Typography>
      )}
    </Grid>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(DrugUtilizationReviewHistory);

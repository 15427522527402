import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { styles } from './spacer-styles';

const renderSmallSpacer = ({ classes }) => <div className={classes.smallSpacer} />;

export const SmallSpacer = withStyles(styles, { withTheme: true })(renderSmallSpacer);

const renderMediumSpacer = ({ classes }) => <div className={classes.mediumSpacer} />;

export const MediumSpacer = withStyles(styles, { withTheme: true })(renderMediumSpacer);

const renderLargeSpacer = ({ classes }) => <div className={classes.largeSpacer} />;

export const LargeSpacer = withStyles(styles, { withTheme: true })(renderLargeSpacer);

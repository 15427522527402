import React from 'react';
import useStyles from './styles';
import { parseValue } from '../utils';

function DefaultFooter(props) {
  const { invoicingCellTextContent } = useStyles();
  const { column, totalsColumns } = props;
  const total = totalsColumns[column.id];

  const cellContent = parseValue(total, column.raw?.editableType, column.raw?.format, null, '');

  return <div className={invoicingCellTextContent}>{cellContent}</div>;
}

export default DefaultFooter;

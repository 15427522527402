/* eslint-disable max-len */
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import authReducer from './reducer-auth';
import { NAME as PBM_MODAL_NAME, pbmModalReducer } from '../slices/pbm-modal-slice';
import { NAME as PHARMACIES_NAME, pharmaciesReducer } from '../slices/pharmacies-slice';
import filtersReducer from './reducer-filters';
import shippingReducer from './reducer-shipping';
import therapyReducer from './reducer-therapies';
import statusesReducer from './reducer-therapy-statuses';
import patientReducer from './reducer-patient';
import patientListReducer from './reducer-patient-list';
import schedulesReducer from './reducer-schedules';
import tasksReducer from './reducer-tasks';
import taskStatusesReducer from './reducer-task-statuses';
import taskCountsReducer from './reducer-task-counts';
import linksReducer from './reducer-links';
import tabReducer from './reducer-tabs';
import lookupReducer from './reducer-lookups';
import financialsReducer from './reducer-financials';
import contactListReducer from './reducer-contact-list';
import preferredContactReducer from './reducer-preferred-contact';
import notificationsReducer from './reducer-notifications';
import notesReducer from './reducer-notes';
import noteBarReducer from './reducer-note-bar';
import medicationListReducer from './reducer-medication-list';
import viewReducer from './reducer-view';
import formDisplayReducer from './reducer-form-displays';
import tasklistSortReducer from './reducer-tasklist-sort';
import userPreferenceReducer from './reducer-user-preferences';
import historyReducer from './reducer-history';
import reportsReducer from './reducer-reports';
import scheduleOutreachReducer from './reducer-schedule-outreach';
import patientClinicsReducer from './reducer-patient-clinics';
import fillCyclesReducer from './reducer-fill-cycles';
import ordersReducer from './reducer-orders';
import documentsReducer from './reducer-documents';
import documentViewerReducer from './reducer-document-viewer';
import sidebarFiltersReducer from './reducer-sidebar-filters';
import documentBarReducer from './reducer-document-bar';
import uploadedDocumentsReducer from './reducer-uploaded-documents';
import reducerReconciliation from './reducer-reconciliation';
import eligibilityCheckReducer from './reducer-eligibility-check';
import specialPopulationsReducer from './reducer-special-populations';
import dcProtocolsReducer from './reducer-dc-protocols';
import reducerAllergies from './reducer-allergies';
import clinicalDataItemsReducer from './reducer-clinical-data';
import therapyBenefitsInvestigationHistoryReducer from './reducer-therapy-benefits-investigation-history';
import userCustomListsReducer from './reducer-user-custom-lists';
import { therapyBenefitsInvestigationLatestReducer } from './reducer-therapy-benefits-investigation-latest';
import { addAddressModalReducer } from './reducer-add-address-modal';
import { addEmailModalReducer } from './reducer-add-email-modal';
import { addPhoneModalReducer } from './reducer-add-phone-modal';
import { addPaymentMethodModalReducer } from './reducer-add-payment-method-modal';
import { dosesRegimenReasonsReducer } from './reducer-doses-regimen';
import { patientMergeReducer } from './reducer-patient-merge';
import { socket as socketReducer } from './reducer-socket';
import { accreditation } from './reducer-accreditation';
import { taskPaApprovedModal } from './reducer-task-pa-approved-modal';
import { cdmPrograms } from './reducer-cdm-programs';
import {
  NAME as BENEFITS_INVESTIGATION_MODAL_NAME,
  benefitsInvestigationSubmissionFormReducer,
} from '../slices/benefits-investigation-modal-slice';
import paymentMethodsSlice from '../slices/patient-payment-methods-slice';
import {
  NAME as BENEFITS_INVESTIGATION_DETAILED_HISTORY_NAME,
  benefitsInvestigationDetailedHistoryReducer,
} from '../slices/benefits-investigation-detailed-history-slice';
import { auditReducer } from './reducer-audit';
import {
  SELECTED_PATIENT_ID,
  SELECTED_THERAPY_ID,
  SELECTED_TASK_ID,
  SELECTED_TASK_TYPE,
  SIDEBAR_TYPE,
  SIDEBAR_TASK_TYPE,
} from '../constants';
import { NAME as SETTINGS_NAME, settingsReducer } from '../slices/settings-slice';
import { addContactModalReducer } from './reducer-add-contact-modal';
import { taskFCDispensingModal } from './reducer-task-fc-dispensing-modal';

const createSimpleReducer =
  actionName =>
  (state = null, action) => {
    const { type, payload } = action;
    if (type === actionName) {
      return payload;
    }
    return state;
  };

const rootReducer = history =>
  // Please try and keep the list of reducers alphabetical
  combineReducers({
    accreditation,
    addAddressModal: addAddressModalReducer,
    addEmailModal: addEmailModalReducer,
    addPaymentMethodModal: addPaymentMethodModalReducer,
    addPhoneModal: addPhoneModalReducer,
    addContactModal: addContactModalReducer,
    allergies: reducerAllergies,
    audit: auditReducer,
    auth: authReducer,
    [BENEFITS_INVESTIGATION_MODAL_NAME]: benefitsInvestigationSubmissionFormReducer,
    [BENEFITS_INVESTIGATION_DETAILED_HISTORY_NAME]: benefitsInvestigationDetailedHistoryReducer,
    cdmPrograms,
    clinicalData: clinicalDataItemsReducer,
    contactList: contactListReducer,
    dcProtocols: dcProtocolsReducer,
    documentBar: documentBarReducer,
    documentViewer: documentViewerReducer,
    documents: documentsReducer,
    dosesRegimenReasons: dosesRegimenReasonsReducer,
    eligibilityCheck: eligibilityCheckReducer,
    fillCycles: fillCyclesReducer,
    filters: filtersReducer,
    financials: financialsReducer,
    form: formReducer,
    formDisplays: formDisplayReducer,
    history: historyReducer,
    links: linksReducer,
    lookups: lookupReducer,
    medications: medicationListReducer,
    noteBar: noteBarReducer,
    notes: notesReducer,
    notifications: notificationsReducer,
    orders: ordersReducer,
    patient: patientReducer,
    patientClinics: patientClinicsReducer,
    patientList: patientListReducer,
    [PBM_MODAL_NAME]: pbmModalReducer,
    [PHARMACIES_NAME]: pharmaciesReducer,
    preferredContact: preferredContactReducer,
    reconciliation: reducerReconciliation,
    reports: reportsReducer,
    router: connectRouter(history),
    scheduleOutreachList: scheduleOutreachReducer,
    schedules: schedulesReducer,
    selectedPatientId: createSimpleReducer(SELECTED_PATIENT_ID),
    selectedTaskId: createSimpleReducer(SELECTED_TASK_ID),
    selectedTaskType: createSimpleReducer(SELECTED_TASK_TYPE),
    selectedTherapyId: createSimpleReducer(SELECTED_THERAPY_ID),
    [SETTINGS_NAME]: settingsReducer,
    sidebarFilters: sidebarFiltersReducer,
    sidebarTaskType: createSimpleReducer(SIDEBAR_TASK_TYPE),
    sidebarType: createSimpleReducer(SIDEBAR_TYPE),
    shipping: shippingReducer,
    specialPopulations: specialPopulationsReducer,
    socketReducer,
    tabControl: tabReducer,
    taskCounts: taskCountsReducer,
    taskStatuses: taskStatusesReducer,
    tasklistSort: tasklistSortReducer,
    tasks: tasksReducer,
    taskFCDispensingModal,
    taskPaApprovedModal,
    therapies: therapyReducer,
    therapyBenefitsInvestigationHistory: therapyBenefitsInvestigationHistoryReducer,
    therapyBenefitsInvestigationLatest: therapyBenefitsInvestigationLatestReducer,
    therapyStatuses: statusesReducer,
    uploadedDocuments: uploadedDocumentsReducer,
    userCustomLists: userCustomListsReducer,
    userPreferences: userPreferenceReducer,
    view: viewReducer,
    patientMerge: patientMergeReducer,
    paymentMethods: paymentMethodsSlice.reducer,
  });

export default rootReducer;

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Header from './header';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    width: '100%',
    fontSize: 18,
    textAlign: 'center',
  },
  subContainer: {
    flex: 1,
    overflowY: 'auto',
    justifyContent: 'center',
    display: 'flex',
    padding: 0,
  },
}));

export default ({ title, header, footer, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Header title={title} />
      <div>{header}</div>
      <div className={classes.subContainer}>{children}</div>
      <div>{footer}</div>
    </div>
  );
};

import React from 'react';
import { renderStaticTextField as RenderStaticTextField } from 'components/form/field/redux-field';

export default ({ field, label, input, meta, disabled, labelSize, qaId }) => (
  <RenderStaticTextField
    label={label}
    input={input}
    meta={meta}
    disabled={disabled}
    labelSize={labelSize}
    qaId={qaId}
    suffix={field.suffix}
  />
);

import {
  createPatientNotes,
  populatePatientNotes,
  trim,
  togglePinNote,
  getNoteLevelInfo,
  makeUpdateTree,
  removeDuplicates,
  toggleCommunicationNote,
  createTasksStructure,
  getNoteTagLabel,
} from '../services/utils/note-service';
import {
  FETCH_NOTES,
  TRIM_NOTES,
  TOGGLE_PIN,
  ADD_NOTE,
  FETCH_NOTES_SIDEBAR,
  ARCHIVE_NOTE,
  RESOLVE_COMMUNICATION_NOTE,
  UPDATE_NOTES_SIDEBAR,
  FETCH_NOTES_ARCHIVED,
  CREATE_NOTES_NEW_PATIENT,
  ADD_TO_TREE_VIEW,
  SELECTED_PATIENT_ID,
} from '../constants';
// eslint-disable-next-line import/extensions
import { noteTagTypes } from '../constants/lists';

const initialState = createPatientNotes();

export default (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case SELECTED_PATIENT_ID:
      return initialState;
    case FETCH_NOTES: {
      if (payload.data && payload.data.notes && payload.data.notes.length > 0) {
        const newState = populatePatientNotes(
          createPatientNotes(),
          payload.data.notes,
          payload.data.tasks,
        );
        return newState;
      }
      return initialState;
    }
    case CREATE_NOTES_NEW_PATIENT: {
      return initialState;
    }
    case FETCH_NOTES_SIDEBAR: {
      const existingTasks = meta.tasks || [];
      if (payload.data && payload.data.notes && payload.data.notes.length > 0) {
        const newState = populatePatientNotes(state, payload.data.notes, existingTasks, true);
        return newState;
      }
      return state;
    }
    case TRIM_NOTES: {
      return trim(state);
    }
    case TOGGLE_PIN: {
      if (payload && payload.data && payload.data.toggled_note) {
        const toggledNote = payload.data.toggled_note;
        const pinnedUtil = togglePinNote(toggledNote, state);
        const notePathObj = getNoteLevelInfo(toggledNote);
        const newTreeState = makeUpdateTree(toggledNote, notePathObj, state);
        return {
          ...state,
          notes: {
            ...state.notes,
            allNonPinnedNotes: pinnedUtil.notpinned,
            allPinnedNotes: pinnedUtil.pinned,
            allNotes: pinnedUtil.allNotes,
            notesToDisplay: removeDuplicates(pinnedUtil.newNotesToDisplay),
            pinnedNotes: newTreeState.notes.pinnedNotes,
            nonPinnedNotes: newTreeState.notes.nonPinnedNotes,
          },
          tasks: newTreeState.tasks,
          therapies: newTreeState.therapies,
        };
      }
      return state;
    }
    case RESOLVE_COMMUNICATION_NOTE: {
      if (payload && payload.data && payload.data.resolved_note) {
        const resolvedNote = payload.data.resolved_note[0];
        const pinnedUtil = toggleCommunicationNote(resolvedNote, state);
        return {
          ...state,
          notes: {
            ...state.notes,
            allNonPinnedNotes: pinnedUtil.notpinned,
            allPinnedNotes: pinnedUtil.pinned,
            allNotes: pinnedUtil.allNotes,
            notesToDisplay: pinnedUtil.newNotesToDisplay,
          },
        };
      }
      return state;
    }
    case ARCHIVE_NOTE: {
      if (
        payload &&
        payload.data &&
        payload.data.archived_note &&
        payload.data.archived_note.length > 0
      ) {
        const archivedNoteId = payload.data.archived_note[0].id;
        const archivedNote = payload.data.archived_note[0];
        const notePathObj = getNoteLevelInfo(archivedNote);
        const newTreeState = makeUpdateTree(archivedNote, notePathObj, state);
        return {
          ...state,
          notes: {
            ...state.notes,
            allNonPinnedNotes: state.notes.allNonPinnedNotes.filter(
              note => note.id !== archivedNoteId,
            ),
            allPinnedNotes: state.notes.allPinnedNotes.filter(note => note.id !== archivedNoteId),
            allNotes: state.notes.allNotes.filter(note => note.id !== archivedNoteId),
            notesToDisplay: state.notes.notesToDisplay.filter(note => note.id !== archivedNoteId),
            pinnedNotes: newTreeState.notes.pinnedNotes,
            nonPinnedNotes: newTreeState.notes.nonPinnedNotes,
          },
          tasks: newTreeState.tasks,
          therapies: newTreeState.therapies,
        };
      }
      return state;
    }
    case ADD_NOTE: {
      if (payload?.data?.added_note?.length && state.notes?.allNotes) {
        let newAllNotes = state.notes.allNotes;
        let newNonPinnedNotes = state.notes.allNonPinnedNotes ?? [];
        let newState = state;
        let newNotesToReturn;
        payload.data.added_note.forEach(addedNote => {
          const notePathObj = getNoteLevelInfo(addedNote);
          newState = makeUpdateTree(addedNote, notePathObj, newState);
        });
        payload.data.added_note.forEach(addedNote => {
          newAllNotes = newState.notes.allNotes;
          newAllNotes.unshift(addedNote);
          newNonPinnedNotes = newState.notes.allNonPinnedNotes.filter(
            note => !note.tags.some(tag => tag.is_pinned === 1),
          );
          newNonPinnedNotes.unshift(addedNote);
          newState.notes.allNotes = newAllNotes;
          newState.notes.allNonPinnedNotes = newNonPinnedNotes;
          newNotesToReturn = newState.notes.allPinnedNotes.concat(newNonPinnedNotes);
          if (addedNote.tags.some(tag => tag.tag_type_id === 1)) {
            newState.notes.notesToDisplay = newNotesToReturn;
          }
          if (addedNote.tags.some(tag => tag.tag_type_id === 2)) {
            newNotesToReturn = newNotesToReturn.filter(
              note => note.therapy_id === addedNote.therapy_id,
            );
            newState.notes.notesToDisplay = newNotesToReturn;
          }
          if (addedNote.tags.some(tag => tag.tag_type_id > 10)) {
            newNotesToReturn = newNotesToReturn.filter(therapyNote =>
              therapyNote.tags.some(
                tag =>
                  tag.tag_type_id === addedNote.tags[0].tag_type_id &&
                  tag.resource_id === addedNote.tags[0].resource_id,
              ),
            );
            newState.notes.notesToDisplay = removeDuplicates(newNotesToReturn);
            const newNoteTaskType = noteTagTypes.find(
              tagType => tagType.value === addedNote.tags[0].tag_type_id,
            ).label;
            newState.therapies.forEach(therapy => {
              const taskIndex = therapy.tasks[newNoteTaskType]?.findIndex(
                task => task.taskId === addedNote.tags[0].resource_id,
              );
              if (taskIndex > -1) {
                if (addedNote.tags[0].is_pinned) {
                  therapy.tasks[newNoteTaskType][taskIndex].notes.pinedNotes.unshift(addedNote);
                }
                therapy.tasks[newNoteTaskType][taskIndex].notes.nonPinnedNotes.unshift(addedNote);
              }
            });
          }
        });
        return {
          ...state,
          notes: {
            ...state.notes,
            allNonPinnedNotes: newNonPinnedNotes,
            allNotes: newAllNotes,
            notesToDisplay: newState.notes.notesToDisplay,
            nonPinnedNotes: newNonPinnedNotes.filter(note =>
              note.tags.some(tag => tag.tag_type_id === 1),
            ), // eslint-disable-line
          },
          tasks: newState.tasks,
          therapies: newState.therapies,
        };
      }
      return state;
    }
    case UPDATE_NOTES_SIDEBAR: {
      if (state.notes && state.notes.allNonPinnedNotes) {
        const allPinnedFinal = state.notes.allPinnedNotes.filter(note =>
          note.tags.some(tag => tag.is_pinned === 1),
        ); // eslint-disable-line
        const allNonPinnedFinal = state.notes.allNonPinnedNotes.filter(
          note => !note.tags.some(tag => tag.is_pinned === 1),
        );
        const allNotesPinnedOrder = removeDuplicates(
          allPinnedFinal
            .concat(allNonPinnedFinal)
            .filter(note => note.is_archived === 0 && note.tags.some(tag => tag.is_owner === 1)),
        );
        const newState = state;
        if (payload.therapy_id) {
          if (!state.therapies.some(therapy => therapy.therapyId === payload.therapy_id)) {
            // add therapy to state
            newState.therapies = newState.therapies.concat([
              {
                therapyId: payload.therapy_id,
                notes: {
                  pinnedNotes: [],
                  nonPinnedNotes: [],
                },
                tasks: createTasksStructure(noteTagTypes),
              },
            ]);
            newState.therapies = newState.therapies.sort((a, b) => b.therapyId - a.therapyId);
          }
          if (payload.tag_type_id > 10) {
            const therapyIndex = newState.therapies
              .map(therapyObj => therapyObj.therapyId)
              .indexOf(payload.therapy_id);
            const therapyTasks =
              newState.therapies[therapyIndex].tasks[getNoteTagLabel(payload.tag_type_id)];
            if (!therapyTasks.some(task => task.taskId === payload.tag_resource_id)) {
              newState.therapies[therapyIndex].tasks[getNoteTagLabel(payload.tag_type_id)] =
                therapyTasks.concat([
                  {
                    taskId: payload.tag_resource_id,
                    therapy_id: payload.therapy_id,
                    tag_resource_id: payload.tag_resource_id,
                    taskType: getNoteTagLabel(payload.tag_type_id),
                    notes: { pinnedNotes: [], nonPinnedNotes: [] },
                  },
                ]);
            }
          }
        } else if (!payload.therapyId && payload.tag_type_id > 10) {
          const patientTasks = newState.tasks[getNoteTagLabel(payload.tag_type_id)];
          if (!patientTasks.some(task => task.taskId === payload.tag_resource_id)) {
            newState.tasks[getNoteTagLabel(payload.tag_type_id)] = patientTasks.concat([
              {
                taskId: payload.tag_resource_id,
                therapy_id: null,
                tag_resource_id: payload.tag_resource_id,
                taskType: getNoteTagLabel(payload.tag_type_id),
                notes: { pinnedNotes: [], nonPinnedNotes: [] },
              },
            ]);
          }
        }
        newState.tag_resource_id = payload.tag_resource_id;
        newState.tag_type_id = payload.tag_type_id;
        if (payload.showAllNotes) {
          return {
            ...state,
            expandedLevel: payload,
            notes: {
              ...state.notes,
              notesToDisplay: allNotesPinnedOrder,
            },
            therapies: newState.therapies.sort((a, b) => b.therapyId - a.therapyId),
          };
        }
        if (payload.tag_type_id === 1) {
          return {
            ...state,
            expandedLevel: payload,
            notes: {
              ...state.notes,
              notesToDisplay: allNotesPinnedOrder.filter(note =>
                note.tags.some(x => x.tag_type_id === 1),
              ),
            },
            therapies: newState.therapies.sort((a, b) => b.therapyId - a.therapyId),
          };
        }
        if (payload.tag_type_id === 2) {
          return {
            ...state,
            expandedLevel: payload,
            notes: {
              ...state.notes,
              notesToDisplay: allNotesPinnedOrder.filter(
                note => note.therapy_id === payload.therapy_id,
              ),
            },
            therapies: newState.therapies.sort((a, b) => b.therapyId - a.therapyId),
          };
        }
        if (payload.tag_type_id > 10) {
          return {
            ...state,
            expandedLevel: payload,
            notes: {
              ...state.notes,
              notesToDisplay: allNotesPinnedOrder.filter(therapyNote =>
                therapyNote.tags.some(
                  tag =>
                    tag.tag_type_id === payload.tag_type_id &&
                    (tag.resource_id === payload.tag_resource_id || tag.is_all_of_type === 1),
                ),
              ),
            },
            therapies: newState.therapies.sort((a, b) => b.therapyId - a.therapyId),
          };
        }
        return {
          ...state,
          expandedLevel: payload,
          notes: {
            ...state.notes,
            notesToDisplay: allNotesPinnedOrder,
          },
          therapies: newState.therapies.sort((a, b) => b.therapyId - a.therapyId),
        };
      }
      return state;
    }
    case FETCH_NOTES_ARCHIVED: {
      if (payload && payload.data && payload.data.notes && payload.data.notes.length > 0) {
        return {
          ...state,
          notes: {
            ...state.notes,
            archivedNotes: payload.data.notes,
          },
        };
      }
      return state;
    }
    case ADD_TO_TREE_VIEW: {
      if (payload) {
        const newState = populatePatientNotes(state, state.notes.allNotes, payload, true);
        return newState;
      }
      return state;
    }
    default:
      return state;
  }
};

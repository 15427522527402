import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';
import React from 'react';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { IAdherenceData } from 'interfaces/redux/IAdherenceData';
import { styles } from './adherence-table-styles';

interface IAdherenceTableProps {
  classes: { [key: string]: any };
  adherenceData?: IAdherenceData;
  gridProps?: object;
}

const AdherenceTable = (props: IAdherenceTableProps): JSX.Element => {
  const { adherenceData, classes, gridProps } = props;
  if (adherenceData && Array.isArray(adherenceData.rows)) {
    adherenceData.rows.forEach(element => {
      element.values.forEach((item, index) => {
        item.key = adherenceData.dates[index];
      });
    });
  }
  const therapies = useTypedSelector(state => state.therapies?.data);

  if (!adherenceData) return <></>;
  const therapyCount = adherenceData?.rows?.length || 0;
  const adherenceTable = (
    <Grid item container xs={8} className={classes.adherenceDataContainer} {...gridProps}>
      <table className={classes.adherenceTable}>
        <thead className={classes.adherenceTableHeader}>
          <tr>
            <th>Adherence Measured PDC ({therapyCount})</th>
            {adherenceData?.dates?.map(date => {
              const display_date = moment(date).format('MMM YY');
              return <th key={display_date}>{display_date}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {adherenceData?.rows?.map((item): JSX.Element => {
            const therapy = therapies[item.therapyId];
            const therapy_display_name = therapy
              ? `${therapy.drug_name} ${therapy.dosage_form || ''} ${therapy.strength || ''} ${
                  therapy.strength_unit_of_measure || ''
                }`
              : item.therapy;
            return (
              <tr key={item.therapyId}>
                <td>{therapy_display_name}</td>
                {item.values.map(entry => {
                  if (entry.value === null) {
                    return (
                      <td key={entry.key} className={classes.missing}>
                        -
                      </td>
                    );
                  }
                    const display_value = `${Math.round(entry.value * 100)} %`;
                    let valueStyleClass = classes.adherenceValueGreen;
                    if (!entry.compliant && !entry.interviened) {
                      valueStyleClass = classes.adherenceValueRed;
                    } else if (!entry.compliant && entry.interviened) {
                      valueStyleClass = classes.adherenceValueGrey;
                    }

                    return (
                      <td key={entry.key} className={valueStyleClass}>
                        {display_value}
                      </td>
                    );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Grid>
  );
  return adherenceTable;
};

export default withStyles(styles, { withTheme: true })(AdherenceTable);

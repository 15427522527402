import withStyles from '@mui/styles/withStyles';
import ReconciliationCard from 'components/card/reconciliation-card';
import { convertToArborDate } from 'models/time/arbor-date';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { resolveReconciliationPatient } from 'actions/action-reconciliation';
import { addCommunicationToExcludedValues } from 'services/utils/reconciliation-service';
import ReconciliationPatientForm from './reconciliation-patient-form';
import { styles } from './reconciliation-styles';
import { compareAny } from './common';

const SOURCE_NOT_FOUND = 'source not found';

export function ReconciliationDemographicCard(props) {
  const { classes, startingIndex, total, stagingPatient, stagingPatientCards, isLoading } = props;

  const patient = useSelector(state => state.patient);

  const isLastPatientCard = stagingPatientCards.length === 1;

  const [displaySubForm, setDisplaySubForm] = useState('');

  const dispatch = useDispatch();

  if (!stagingPatient || !patient) {
    return null;
  }

  const patientReconcilationFields = [
    {
      label: 'First Name',
      name: 'first_name',
    },
    {
      label: 'Last Name',
      name: 'last_name',
    },
    {
      label: 'Middle Name',
      name: 'middle_name',
    },
    {
      label: 'DOB',
      name: 'dob',
    },
    {
      label: 'Gender',
      name: 'gender',
    },
    {
      label: 'Is Deceased',
      name: 'deceased',
    },
  ];

  const renderPatient = (existingPatient, newPatient, col) => {
    let currentPatient;
    let comparedPatient;
    if (col === 'new') {
      currentPatient = newPatient;
      comparedPatient = existingPatient;
    } else {
      currentPatient = existingPatient;
      comparedPatient = newPatient;
    }
    return patientReconcilationFields.map(field => {
      let value = currentPatient[field.name];
      let highlight = !compareAny(value, comparedPatient[field.name]);
      if (field.name === 'dob') {
        value = convertToArborDate(currentPatient.dob, true).getUtcDate(true);
      }
      if (field.name === 'middle_name' && col === 'right' && !comparedPatient.middle_name) {
        highlight = false;
      }
      if (field.name === 'deceased') {
        value = currentPatient.deceased === 1 ? 'Yes' : 'No';
      }
      return {
        label: field.label,
        value,
        highlight,
      };
    });
  };

  const renderPatientCommunication = (existingStrArr, newStrArr, col, highlightIndexes) => {
    let currentStrArr;
    if (col === 'new') {
      currentStrArr = newStrArr;
    } else {
      currentStrArr = existingStrArr;
    }
    return currentStrArr.map((str, index) => ({
      value: str,
      highlight: col === 'left' && highlightIndexes && highlightIndexes.includes(index),
    }));
  };

  const excludedValues = stagingPatient.excluded_values || {};
  return (
    <>
      {stagingPatientCards && stagingPatientCards.length > 0
        ? stagingPatientCards.map((card, index) => {
            const currentIndex = startingIndex + index;
            switch (card) {
              case 'patient': {
                const rejectPatient = () => {
                  patientReconcilationFields.forEach(field => {
                    const { name } = field;
                    if (!compareAny(stagingPatient[name], patient[name])) {
                      excludedValues[name] = stagingPatient[name];
                    }
                  });
                  dispatch(
                    resolveReconciliationPatient(
                      'reject',
                      'patient',
                      patient,
                      excludedValues,
                      isLastPatientCard,
                    ),
                  );
                };
                const EditPatientForm = (
                  <div className={classes.subFormContainer}>
                    <ReconciliationPatientForm
                      stagingPatient={stagingPatient}
                      isLastPatientCard={isLastPatientCard}
                      cancelHandler={() => setDisplaySubForm()}
                      reconciliationType="patient"
                      reconciliationArr
                    />
                  </div>
                );
                return (
                  <ReconciliationCard
                    key="patient_card"
                    header="Patient Reconcile Information"
                    displaySubForm={displaySubForm}
                    subForms={[
                      {
                        button: 'Update patient with changes',
                        buttonHandler: setDisplaySubForm,
                        display: 'update_patient',
                        form: EditPatientForm,
                      },
                    ]}
                    oldCol={renderPatient(patient, stagingPatient, 'old')}
                    newCol={renderPatient(patient, stagingPatient, 'new')}
                    index={currentIndex}
                    total={total}
                    rejectHandler={rejectPatient}
                    sourceName={stagingPatient.source_name || SOURCE_NOT_FOUND}
                    isLoading={isLoading}
                  />
                );
              }

              case 'address': {
                const {
                  existingStrArr: existingAddressStrArr,
                  newStrArr: newAddressStrArr,
                  highlightIndexes: addressHighlightIndexes,
                  reconciliationObjs: reconciliationAddresses,
                } = stagingPatient.addressReconcileInfo;

                const filteredAddresses = get(patient, 'filteredAddresses', []);
                const initialAddresses = [...filteredAddresses, ...reconciliationAddresses];

                const rejectAddress = () => {
                  addCommunicationToExcludedValues(
                    'addresses',
                    reconciliationAddresses,
                    excludedValues,
                  );
                  dispatch(
                    resolveReconciliationPatient(
                      'reject',
                      'address',
                      reconciliationAddresses,
                      excludedValues,
                      isLastPatientCard,
                    ),
                  );
                };

                const EditAddressForm = (
                  <div className={classes.subFormContainer}>
                    <ReconciliationPatientForm
                      stagingPatient={{
                        ...stagingPatient,
                        filteredAddresses: initialAddresses,
                      }}
                      isLastPatientCard={isLastPatientCard}
                      cancelHandler={() => setDisplaySubForm()}
                      reconciliationType="address"
                      reconciliationArr={stagingPatient.addresses}
                    />
                  </div>
                );
                return (
                  <ReconciliationCard
                    key="patient_address_card"
                    header="Patient Address Reconcile Information"
                    displaySubForm={displaySubForm}
                    subForms={[
                      {
                        button: 'Update patient addresses with changes',
                        buttonHandler: setDisplaySubForm,
                        display: 'update_patient_addresses',
                        form: EditAddressForm,
                      },
                    ]}
                    oldCol={renderPatientCommunication(
                      existingAddressStrArr,
                      newAddressStrArr,
                      'old',
                      addressHighlightIndexes,
                    )}
                    newCol={renderPatientCommunication(
                      existingAddressStrArr,
                      newAddressStrArr,
                      'new',
                    )}
                    index={currentIndex}
                    total={total}
                    rejectHandler={rejectAddress}
                    sourceName={stagingPatient.source_name || SOURCE_NOT_FOUND}
                    isLoading={isLoading}
                  />
                );
              }
              case 'email': {
                const {
                  existingStrArr: existingEmailStrArr,
                  newStrArr: newEmailStrArr,
                  highlightIndexes: emailHighlightIndexes,
                  reconciliationObjs: reconciliationEmails,
                } = stagingPatient.emailReconcileInfo;

                const filteredEmails = get(patient, 'filteredEmails', []);
                const initialEmails = [...filteredEmails, ...reconciliationEmails];

                const rejectEmail = () => {
                  addCommunicationToExcludedValues('emails', reconciliationEmails, excludedValues);
                  dispatch(
                    resolveReconciliationPatient(
                      'reject',
                      'email',
                      reconciliationEmails,
                      excludedValues,
                      isLastPatientCard,
                    ),
                  );
                };

                const EditEmailForm = (
                  <div className={classes.subFormContainer}>
                    <ReconciliationPatientForm
                      stagingPatient={{
                        ...stagingPatient,
                        filteredEmails: initialEmails,
                      }}
                      isLastPatientCard={isLastPatientCard}
                      cancelHandler={() => setDisplaySubForm()}
                      reconciliationType="email"
                      reconciliationArr={stagingPatient.emails}
                    />
                  </div>
                );
                return (
                  <ReconciliationCard
                    header="Patient Email Reconcile Information"
                    key="patient_email_card"
                    displaySubForm={displaySubForm}
                    subForms={[
                      {
                        button: 'Update patient emails with changes',
                        buttonHandler: setDisplaySubForm,
                        display: 'update_patient_emails',
                        form: EditEmailForm,
                      },
                    ]}
                    oldCol={renderPatientCommunication(
                      existingEmailStrArr,
                      newEmailStrArr,
                      'old',
                      emailHighlightIndexes,
                    )}
                    newCol={renderPatientCommunication(existingEmailStrArr, newEmailStrArr, 'new')}
                    index={currentIndex}
                    total={total}
                    rejectHandler={rejectEmail}
                    sourceName={stagingPatient.source_name || SOURCE_NOT_FOUND}
                    isLoading={isLoading}
                  />
                );
              }

              case 'phone': {
                const {
                  existingStrArr: existingPhoneStrArr,
                  newStrArr: newPhoneStrArr,
                  highlightIndexes: phoneHighlightIndexes,
                  reconciliationObjs: reconciliationPhones,
                } = stagingPatient.phoneReconcileInfo;

                const filteredPhones = get(patient, 'filteredPhones', []);
                const initialPhones = [...filteredPhones, ...reconciliationPhones];

                const rejectPhone = () => {
                  addCommunicationToExcludedValues('phones', reconciliationPhones, excludedValues);
                  dispatch(
                    resolveReconciliationPatient(
                      'reject',
                      'phone',
                      reconciliationPhones,
                      excludedValues,
                      isLastPatientCard,
                    ),
                  );
                };
                const EditPhoneForm = (
                  <div className={classes.subFormContainer}>
                    <ReconciliationPatientForm
                      stagingPatient={{
                        ...stagingPatient,
                        filteredPhones: initialPhones,
                      }}
                      isLastPatientCard={isLastPatientCard}
                      cancelHandler={() => setDisplaySubForm()}
                      reconciliationType="phone"
                      reconciliationArr={stagingPatient.phones}
                    />
                  </div>
                );

                return (
                  <ReconciliationCard
                    key="patient_phone_card"
                    header="Patient Phone Reconcile Information"
                    displaySubForm={displaySubForm}
                    subForms={[
                      {
                        button: 'Update patient phones with changes',
                        buttonHandler: setDisplaySubForm,
                        display: 'update_patient_phones',
                        form: EditPhoneForm,
                      },
                    ]}
                    oldCol={renderPatientCommunication(
                      existingPhoneStrArr,
                      newPhoneStrArr,
                      'old',
                      phoneHighlightIndexes,
                    )}
                    newCol={renderPatientCommunication(existingPhoneStrArr, newPhoneStrArr, 'new')}
                    index={currentIndex}
                    total={total}
                    rejectHandler={rejectPhone}
                    sourceName={stagingPatient.source_name || SOURCE_NOT_FOUND}
                    isLoading={isLoading}
                  />
                );
              }
              default:
                return null;
            }
          })
        : null}
    </>
  );
}

export default withStyles(styles, { withTheme: true })(ReconciliationDemographicCard);

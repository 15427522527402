import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

import { noteTagTypes } from 'constants/lists';
import { handleNullString } from 'services/utils/formatting';
import { buildHandleEnterKeyPress } from 'services/utils/accessibility';

import { openCommunicationNotesSideBar } from 'actions/action-notes';
import { Grid } from '@mui/material';
import { NoteBarTrigger } from 'constants/enums';

interface INewNoteActionButtonProps {
  inheritedClass: string;
  openCommunicationNotesSideBarAction: (payload: any) => void;
  patient: any;
  selectedPatientId: any;
  existingTasks: any[];
}

class NewNoteActionButton extends React.Component<INewNoteActionButtonProps> {
  clickNote() {
    const { patient, selectedPatientId, existingTasks, openCommunicationNotesSideBarAction } =
      this.props;

    const tagTypeId = (noteTagTypes as any[]).find(tag => tag.label === 'Patient').value;

    const payload = {
      noteBar: {
        tagTypeId,
        display: true,
        tag: `${patient.first_name} ${patient.last_name} ${handleNullString(patient.suffix)}`,
        tagResourceId: selectedPatientId,
        trigger: NoteBarTrigger.Notes,
      },
      existingTasks,
    };

    openCommunicationNotesSideBarAction(payload);
  }

  render() {
    return (
      <Grid
        item
        xs={6}
        className={this.props.inheritedClass}
        onClick={() => this.clickNote()}
        onKeyPress={buildHandleEnterKeyPress(() => this.clickNote())}
        role="button"
        tabIndex={0}
        data-qa-id="tfb-newNote"
      >
        + Note
      </Grid>
    );
  }
}

function mapStateToProps(state: any) {
  const { patient, selectedPatientId } = state;
  const existingTasks = Object.values(state.tasks?.data || {});

  return {
    patient,
    selectedPatientId,
    existingTasks,
  };
}
export default compose<INewNoteActionButtonProps, { inheritedClass: string }>(
  connect(mapStateToProps, { openCommunicationNotesSideBarAction: openCommunicationNotesSideBar }),
)(NewNoteActionButton);

import React, { Component, useEffect } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid, Checkbox, Typography } from '@mui/material';
import compose from 'recompose/compose';
import DetailField from 'components/form/field/field';
import { Field, reduxForm, formValueSelector, change as _change, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { stripFormattedDollarValue } from 'services/utils/formatting';
import {
  EDIT_FINANCIAL_ASSISTANCE_FORM,
  FINANCIAL,
  UPLOAD_DOCUMENT_FORM,
  UPLOAD_DOC_FILENAME_FIELD,
  UPLOAD_DOC_FILETYPE_FIELD,
  UPLOAD_DOC_FILENOTE_FIELD,
} from 'constants/index';
import { fetchFinancialAssistance, editFinancialAssistanceTask } from 'actions/action-financials';
import {
  validateDate,
  required,
  validateAlphaNumeric,
  length6,
  maxLength20,
} from 'components/form/validation/validation';
import { convertToArborDate } from 'models/time/arbor-date';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import {
  renderDropdown,
  renderTextField,
  renderPhoneTextField,
  renderMoneyTextField,
} from 'components/form/field/redux-field';
import { uploadFile, getDocuments } from 'services/utils/upload-document-service';
import { SmallSpacer } from 'components/spacer/spacer';
import { addNewDocument } from 'actions/action-uploaded-documents';
import { getFileExtension, acceptedFileExtensions } from 'services/utils/document-service';
import {
  FreeDrugReasonsLabels,
  getFreeDrugReasonId,
  getFreeDrugsReasonDescription,
  noteTagTypes,
} from 'constants/lists';
import DocumentConfirmationDialog from 'containers/patient/financials/document-confirmation-dialog';
import ConfirmationDialogPanel from 'components/form/confirmation/confirmation-dialog-panel';
import { styles } from '../../task-detail-styles';
import WrappedFileUpload from '../../../patient/financials/wrapped-file-upload';
import Dropdown from '../../../../components/form/field/dropdown';

const tagType = noteTagTypes.find(tag => tag.label === FINANCIAL);

class EditPrescriptionAssistanceProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: null,
      therapyId: null,
      noGroupIdCheckbox: false,
      noPCNCheckbox: false,
      awardsAmountDialogSettings: {
        open: false,
      },

      // upload file props
      pendingDocument: undefined,
      uploadedDocuments: [],
      lostData: [],
      uploadProcessing: false,
      pendingDocumentLoss: false,
      dialogCancel: undefined,
      dialogContinue: undefined,
      freeDrugReason: props.freeDrugReason || -1,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGroupIdCheckbox = this.handleGroupIdCheckbox.bind(this);
    this.handleNoPCNCheckbox = this.handleNoPCNCheckbox.bind(this);
    // upload file
    this.handleFileUploadCancel = this.handleFileUploadCancel.bind(this);
    this.handleFileUploadSubmit = this.handleFileUploadSubmit.bind(this);
    this.fileSelected = this.fileSelected.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleFreeDrugReason = this.handleFreeDrugReason.bind(this);
  }

  componentDidMount() {
    const { financialAssistance } = this.props;
    this.setState({
      noGroupIdCheckbox: !financialAssistance.group_id,
      noPCNCheckbox: !financialAssistance.pcn,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { fileFormName } = this.props;
    if (prevState.pendingDocument && prevState.pendingDocument.fileName !== fileFormName) {
      this.onPendingDocumentFileNameChange(fileFormName);
    }
  }

  onPendingDocumentFileNameChange(newFileName) {
    this.setState(prevState => ({
      pendingDocument: {
        ...prevState.pendingDocument,
        ...{ fileName: newFileName },
      },
    }));
  }

  // upload file
  handleFileUploadCancel() {
    this.setState({ pendingDocument: undefined });
  }

  handleFileUploadSubmit() {
    this.setState({ uploadProcessing: true });
    const { pendingDocument } = this.state;
    const {
      selectedCustomerId,
      selectedPatientId,
      fileFormName,
      fileFormTypes,
      fileFormNote,
      financialAssistance,
    } = this.props;

    if (pendingDocument) {
      uploadFile(
        pendingDocument.file,
        `${pendingDocument.fileName}.${pendingDocument.fileExtension}`,
        selectedCustomerId,
        selectedPatientId,
      )
        .then(result => {
          this.setState(prevState => ({
            uploadedDocuments: [
              ...prevState.uploadedDocuments,
              {
                uuid: result.uuid,
                taskTypeId: tagType.value,
                taskIds: [financialAssistance.id],
                filename: `${fileFormName}.${pendingDocument.fileExtension}`,
                labels: fileFormTypes.split(','),
                note: fileFormNote,
              },
            ],
            pendingDocument: undefined,
            uploadProcessing: false,
          }));
        })
        .catch(() => {
          this.setState({
            fileUploadErrorMessage: 'File could not be uploaded. Try again.',
          });
        });
    }
  }

  handleSubmit(values) {
    const { pendingDocument } = this.state;
    if (pendingDocument) {
      const lostData = [];

      if (pendingDocument) {
        lostData.push(`Pending: ${pendingDocument.fileName}.${pendingDocument.fileExtension}`);
      }

      this.setState({
        pendingDocumentLoss: true,
        lostData,
        dialogCancel: () => {
          this.setState({ pendingDocumentLoss: false });
        },
        dialogContinue: () => {
          this.finalizeSubmit(values);
        },
      });
    } else {
      this.finalizeSubmit(values);
    }
  }

  handleCancel() {
    const { uploadedDocuments, pendingDocument } = this.state;
    if ((uploadedDocuments && uploadedDocuments.length > 0) || pendingDocument) {
      const lostData = uploadedDocuments.map(ud => `Uploaded: ${ud.filename}`) || [];

      if (pendingDocument) {
        lostData.push(`Pending: ${pendingDocument.fileName}.${pendingDocument.fileExtension}`);
      }

      this.setState({
        pendingDocumentLoss: true,
        lostData,
        dialogCancel: () => {
          this.setState({ pendingDocumentLoss: false });
        },
        dialogContinue: () => {
          this.finalizeCancel();
        },
      });
    } else {
      this.finalizeCancel();
    }
  }

  finalizeCancel() {
    const { cancel } = this.props;
    cancel();
  }

  fileSelected(event) {
    if (event && event.length > 0) {
      const selectedFile = event[0];
      const { filePrefix, fileExtension } = getFileExtension(selectedFile.name);
      if (acceptedFileExtensions.includes(fileExtension.toLowerCase())) {
        const { changeField, fileUploadFormId } = this.props;
        changeField(fileUploadFormId, UPLOAD_DOC_FILENAME_FIELD, filePrefix);

        this.setState({
          pendingDocument: {
            fileName: filePrefix,
            fileExtension,
            file: selectedFile,
          },
        });
      }
    }
  }

  // Handles the submit functionality. Right now it sets some fields to null for simplicity's sake
  finalizeSubmit(values) {
    const {
      editFinancialAssistanceTask, // eslint-disable-line
      reset,
      cancel,
      financialAssistance,
      existingDocuments,
      addNewDocument, // eslint-disable-line
    } = this.props;
    const { noGroupIdCheckbox, noPCNCheckbox, uploadedDocuments, freeDrugReason } = this.state;
    const payload = {
      ...values,
      id: financialAssistance.id,
      patient_id: financialAssistance.patient_id,
      therapy_id: financialAssistance.therapy_id,
      task_fa_id: financialAssistance.task_fa_id,
      status: values.status ? values.status : '',
      limit: values.limit ? values.limit : '',
      expiration_date: values.expiration_date
        ? convertToArborDate(values.expiration_date).getUtcDatetime()
        : null,
      group_id: noGroupIdCheckbox ? null : values.group_id,

      program_name: values.program_name ? values.program_name : '',
      phone_number: values.phone_number ? values.phone_number : '',
      fax_number: values.fax_number ? values.fax_number : '',
      bin: values.bin ? values.bin : null,
      pcn: noPCNCheckbox ? null : values.pcn,
      member_id: values.member_id,
      copay: values.copay ? stripFormattedDollarValue(values.copay) : null,
      rewards_amount: values.rewards_amount
        ? stripFormattedDollarValue(values.rewards_amount)
        : null,
      documents: uploadedDocuments.map(doc => ({
        uuid: doc.uuid,
        file_name: doc.filename,
        labels: doc.labels,
        tags: doc.taskIds.map(id => ({
          tag_type_id: tagType.value,
          resource_id: id,
        })),
        noteText: doc.note,
      })),
      free_drug_reason: getFreeDrugReasonId(freeDrugReason),
    };

    editFinancialAssistanceTask(payload);

    if (uploadedDocuments.length) {
      getDocuments({
        patient_id: financialAssistance.patient_id,
        fetchFromId: Math.max(...existingDocuments.map(d => d.id)),
      }).then(result => {
        addNewDocument(result.data.documents);
      });
    }

    cancel();
    reset();
  }

  handleGroupIdCheckbox() {
    this.setState(prevState => ({
      noGroupIdCheckbox: !prevState.noGroupIdCheckbox,
    }));
  }

  handleNoPCNCheckbox() {
    this.setState(prevState => ({
      noPCNCheckbox: !prevState.noPCNCheckbox,
    }));
  }

  handleFreeDrugReason(e) {
    this.setState({
      freeDrugReason: e.target.value,
    });
  }

  render() {
    const {
      handleSubmit,
      submitting,
      classes,
      therapies,
      addingFromFinancials,
      fileUploadFormId,
      defaultDocumentLabels,
      pristine,
      financialAssistance,
      formValues,
      change,
    } = this.props;

    const {
      status,
      therapyId,
      noGroupIdCheckbox,
      noPCNCheckbox,
      pendingDocument,
      fileUploadErrorMessage,
      uploadProcessing,
      uploadedDocuments,
      pendingDocumentLoss,
      lostData,
      dialogCancel,
      dialogContinue,
      awardsAmountDialogSettings,
      freeDrugReason,
    } = this.state;

    const rewardsAmount = formValues?.rewards_amount
      ? stripFormattedDollarValue(formValues?.rewards_amount)
      : null;

    if (
      (10 ** 5 <= rewardsAmount || rewardsAmount < 0 || Number.isNaN(rewardsAmount)) &&
      !awardsAmountDialogSettings.open
    ) {
      this.setState({
        awardsAmountDialogSettings: {
          open: true,
          message:
            "Please note that you can only input values between $0 and $99,999 in the 'Awards Amount' field",
          dialogConfirm: async () => {
            let limitValue = 0;
            if (10 ** 5 <= rewardsAmount) {
              limitValue = 99999;
            } else if (rewardsAmount < 0 || Number.isNaN(rewardsAmount)) {
              limitValue = null;
            }
            await change('rewards_amount', limitValue);
            this.setState({ awardsAmountDialogSettings: { open: false } });
          },
        },
      });
    }

    const uploadProps = {
      pendingDocument,
      fileUploadErrorMessage,
      uploadProcessing,
      uploadedDocuments,
      pendingDocumentLoss,
      classes,
      fileUploadFormId,
      defaultDocumentLabels,
      handleFileUploadSubmit: this.handleFileUploadSubmit,
      handleFileUploadCancel: this.handleFileUploadCancel,
      fileSelected: this.fileSelected,
    };

    const documentConfirmationDialogProps = {
      lostData,
      dialogCancel,
      dialogContinue,
      pendingDocumentLoss,
    };

    const idSuffix =
      financialAssistance && financialAssistance.id ? `_${financialAssistance.id}` : '';

    return (
      <>
        {awardsAmountDialogSettings.open && (
          <ConfirmationDialogPanel
            open
            content={<Typography>{awardsAmountDialogSettings.message}</Typography>}
            continueText="Confirm"
            onContinue={awardsAmountDialogSettings?.dialogConfirm}
          />
        )}
        <Grid container justifyContent="center" spacing={7}>
          <Grid item xs={12}>
            <DocumentConfirmationDialog {...documentConfirmationDialogProps} />
            <form className={classes.addFormContainer} autoComplete="off">
              <Grid container>
                <Grid item xs={12}>
                  <Grid container>
                    {addingFromFinancials && (
                      <Grid item xs={3} className={classes.fieldContainer}>
                        <Field
                          name="therapy_id"
                          label="Therapy *"
                          validate={[required]}
                          component={renderDropdown}
                          fields={Object.values(therapies).map(t => ({
                            label: t.drug_name,
                            value: t.id,
                          }))}
                          state={therapyId}
                          id={`edit_FA_program_therapy_id${idSuffix}`}
                        />
                      </Grid>
                    )}
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Dropdown
                        label="Free Drug Reason"
                        state={freeDrugReason}
                        handler={this.handleFreeDrugReason}
                        fields={FreeDrugReasonsLabels.map(x => x.label)}
                        name="free_drug_reason"
                        id="fa_mpap_reason_dropdown"
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="program_name"
                        label="Program Name *"
                        component={renderTextField}
                        validate={[required]}
                        id={`edit_FA_program_program_name${idSuffix}`}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="phone_number"
                        label="Program Phone Number"
                        component={renderPhoneTextField}
                        id={`edit_FA_program_phone_number${idSuffix}`}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="fax_number"
                        label="Program Fax Number"
                        component={renderPhoneTextField}
                        id={`edit_FA_program_fax_number${idSuffix}`}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="expiration_date"
                        label="Expiration Date *"
                        component={renderDatePicker}
                        validate={[validateDate, required]}
                        id={`edit_FA_program_expiration_date${idSuffix}`}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="bin"
                        label="BIN"
                        component={renderTextField}
                        validate={[validateAlphaNumeric, length6]}
                        id={`edit_FA_program_bin${idSuffix}`}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={6} className={classes.fieldContainer}>
                          <Field
                            name="pcn"
                            label={!noPCNCheckbox ? 'PCN *' : 'PCN'}
                            disabled={noPCNCheckbox}
                            component={renderTextField}
                            validate={!noPCNCheckbox ? [required, maxLength20] : null}
                            id={`edit_FA_program_pcn${idSuffix}`}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container alignItems="center" justifyContent="flex-start">
                            <Checkbox
                              checked={noPCNCheckbox}
                              color="primary"
                              onChange={this.handleNoPCNCheckbox}
                              value="checkedPCN"
                              label="Secondary"
                              id={`edit_FA_program_no_pcn${idSuffix}`}
                            />
                            <DetailField defaultValue="No PCN" />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name="member_id"
                        label="Member ID"
                        component={renderTextField}
                        id={`edit_FA_program_member_id${idSuffix}`}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={6} className={classes.fieldContainer}>
                          <Field
                            name="group_id"
                            label={!noGroupIdCheckbox ? 'Group ID *' : 'Group ID'}
                            validate={!noGroupIdCheckbox ? [required] : null}
                            component={renderTextField}
                            disabled={noGroupIdCheckbox}
                            id={`edit_FA_program_group_id${idSuffix}`}
                          />
                        </Grid>
                        <Grid item xs={6} className={classes.fieldContainer}>
                          <Grid container alignItems="center" justifyContent="flex-start">
                            <Checkbox
                              checked={noGroupIdCheckbox}
                              color="primary"
                              onChange={this.handleGroupIdCheckbox}
                              value="checkedGroupId"
                              label="Secondary"
                              id={`edit_FA_program_no_group_id${idSuffix}`}
                            />
                            <DetailField defaultValue="No Group Id" />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="copay"
                        label="Copay"
                        component={renderMoneyTextField}
                        id={`edit_FA_program_copay${idSuffix}`}
                        decimalScale={2}
                        fixedDecimalScale
                        decimalSeparator="."
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="rewards_amount"
                        label="Awards Amount"
                        component={renderMoneyTextField}
                        id={`edit_FA_program_rewards_amount${idSuffix}`}
                        decimalScale={2}
                        fixedDecimalScale
                        decimalSeparator="."
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="status"
                        label="Status *"
                        component={renderDropdown}
                        fields={[
                          { label: 'Active', value: 1 },
                          { label: 'Inactive', value: 0 },
                        ]}
                        state={status}
                        id={`edit_FA_program_status${idSuffix}`}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <WrappedFileUpload {...uploadProps} />
              <SmallSpacer />
              <ConfirmationPanel
                cancelButtonName="edit_task_cancel_button"
                submitButtonName="edit_task_submit_button"
                handleSubmit={handleSubmit(this.handleSubmit)}
                handleCancel={this.handleCancel}
                disableSubmit={
                  submitting || (pristine && !pendingDocument && !uploadedDocuments.length)
                }
              />
            </form>
          </Grid>
        </Grid>
      </>
    );
  }
}

function handleFormName(id) {
  const fileFormId = `${UPLOAD_DOCUMENT_FORM}-${tagType.label}-${id}`;
  return fileFormId;
}

function mapStateToProps(state, props) {
  const { therapies, uploadedDocuments } = state;
  const { financialAssistance } = props;

  const fileUploadFormId = handleFormName(financialAssistance.id);
  const selector = formValueSelector(fileUploadFormId);
  const fileFormName = selector(state, UPLOAD_DOC_FILENAME_FIELD);
  const fileFormTypes = selector(state, UPLOAD_DOC_FILETYPE_FIELD);
  const fileFormNote = selector(state, UPLOAD_DOC_FILENOTE_FIELD);
  const formValues = getFormValues(EDIT_FINANCIAL_ASSISTANCE_FORM)(state);

  return {
    therapies: therapies.data,
    initialValues: {
      status:
        props.financialAssistance.status || props.financialAssistance.status === 0
          ? props.financialAssistance.status
          : '',
      limit: props.financialAssistance.limit ? props.financialAssistance.limit : '',
      expiration_date: props.financialAssistance.expiration_date
        ? convertToArborDate(props.financialAssistance.expiration_date, true).getCustomerDate(true)
        : null,
      program_name: props.financialAssistance.program_name
        ? props.financialAssistance.program_name
        : '',
      foundation_program: props.financialAssistance.foundation_program
        ? props.financialAssistance.foundation_program
        : '',
      spend_down: props.financialAssistance.spend_down ? props.financialAssistance.spend_down : '',
      phone_number: props.financialAssistance.phone_number
        ? props.financialAssistance.phone_number
        : '',
      group_id: props.financialAssistance.group_id ? props.financialAssistance.group_id : '',
      fax_number: props.financialAssistance.fax_number ? props.financialAssistance.fax_number : '',
      bin: props.financialAssistance.bin ? props.financialAssistance.bin : '',
      pcn: props.financialAssistance.pcn ? props.financialAssistance.pcn : '',
      member_id: props.financialAssistance.member_id ? props.financialAssistance.member_id : '',
      copay: props.financialAssistance.copay,
      rewards_amount: props.financialAssistance.rewards_amount,
      free_drug_reason: getFreeDrugsReasonDescription(
        props.financialAssistance.free_drug_reason_id,
      ),
    },
    defaultDocumentLabels: state.lookups.defaultDocumentLabels,
    fileUploadFormId,
    fileFormName,
    fileFormTypes,
    fileFormNote,
    selectedCustomerId: state.filters.selectedCustomerId,
    selectedPatientId: props.financialAssistance.patient_id,
    existingDocuments: uploadedDocuments.documents,
    formValues,
    freeDrugReason: getFreeDrugsReasonDescription(props.financialAssistance.free_drug_reason_id),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchFinancialAssistance,
      editFinancialAssistanceTask,
      change: (fieldName, value) =>
        dispatch(_change(EDIT_FINANCIAL_ASSISTANCE_FORM, fieldName, value)),
      changeField: (formName, fieldName, value) => dispatch(_change(formName, fieldName, value)),
      addNewDocument,
    },
    dispatch,
  );
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(reduxForm({ form: `${EDIT_FINANCIAL_ASSISTANCE_FORM}` })(EditPrescriptionAssistanceProgram));

import React from 'react';
import { ClickAwayListener } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import { useDispatch } from 'react-redux';

import CommunicationForm from 'components/note/communication-form';
import { IState } from 'interfaces/redux/IState';
import { useTypedSelector } from 'hooks/use-typed-selector';

import { PhonePopup } from 'components/phone/phone-popup';
import { TogglePhonePanel, ToggleOngoingPhoneCall } from '../../actions/action-view';
import { buildHandleEnterKeyPress } from '../../services/utils/accessibility';
import { useStyles } from './floating-phone-button-styles';

interface IFloatingPhoneButtonProps {
  pathname: string;
  hash: string;
  search: string;
  patientTab: string;
  patient: any;
  selectedPatientId: number;
  togglePanel: (bool?: boolean) => void;
  toggleOngoingPhoneCall: (bool?: boolean) => void;
  isControlPanelOpen: boolean;
}

export const FloatingPhoneButton = (props: IFloatingPhoneButtonProps): JSX.Element | null => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const togglePanel = (bool?: boolean) => dispatch(new TogglePhonePanel(bool));
  const toggleOngoingPhoneCall = (bool?: boolean) => dispatch(new ToggleOngoingPhoneCall(bool));

  const isPhonePanelOpen = useTypedSelector(
    (state: IState) => state?.view?.phonePanelOpen || false,
  );
  const ongoingPhoneCall = useTypedSelector(
    (state: IState) => state?.view?.ongoingPhoneCall || false,
  );

  function onClickButton() {
    togglePanel();
  }

  function closePanel() {
    togglePanel(false);
    toggleOngoingPhoneCall(false);
  }

  function onClickAway() {
    if (!ongoingPhoneCall && isPhonePanelOpen) {
      togglePanel(false);
    }
  }
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div className={`${classes.fpbContainer} drag-handle`}>
        <div
          className={classes.fpbButton}
          onClick={onClickButton}
          onKeyPress={buildHandleEnterKeyPress(onClickButton)}
          role="button"
          tabIndex={0}
          data-qa-id="tfb-floating-button"
        >
          <PhoneIcon fontSize="large" />
        </div>
        {isPhonePanelOpen && ongoingPhoneCall && (
          <div className={classes.fpbControlPanelOnCall}>
            <CommunicationForm cancelCallback={closePanel} />
          </div>
        )}
        {isPhonePanelOpen && !ongoingPhoneCall && (
          <div className={classes.fpbControlPanel}>
            <PhonePopup onClose={onClickAway} />
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

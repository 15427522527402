import React from 'react';
import { Typography } from '@mui/material';
import { EditClose } from '../icons/icons';
import { TabLabelProps, MAX_LABEL_LENGTH } from './interfaces';

export * from './interfaces';

export const TabLabel = ({ tab, onClose, className }: TabLabelProps) => (
  <Typography className={className}>
    {tab.label.length > MAX_LABEL_LENGTH
      ? `${tab.label.substr(0, MAX_LABEL_LENGTH - 1)}...`
      : tab.label}
    {onClose && (
      <EditClose
        onClick={(e: Event) => {
          e.stopPropagation();
          onClose(tab);
        }}
      />
    )}
  </Typography>
);

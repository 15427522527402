import React from 'react';
import cslJson from 'config/task-types/csl.json';
import { counselingTaskTypes } from 'constants/lists';

import { staticProviders, getCommonProviders } from './common-provider';
import EditAdministration from '../../../containers/patient/therapy/edit-therapy-administration';
import { useTypedSelector } from '../../../hooks/use-typed-selector';
import { noteTagTypes } from '../../../constants/lists';
import { getTherapyStatusById } from '../../../services/utils/therapy-service';

export default (task, state) => ({
  ...staticProviders,
  ...getCommonProviders(task, state, cslJson),
  methodOptions: [
    { id: 1, name: 'In Person' },
    { id: 2, name: 'On the Phone' },
    { id: 3, name: 'Chart Review' },
  ],
  counselingTaskTypeOptions: counselingTaskTypes,
  canceledReasons: [
    { id: 1, name: 'Patient Opt Out' },
    { id: 2, name: 'Other' },
  ],
  noOngoingReasons: [
    { id: 1, name: 'Patient Opt Out' },
    { id: 2, name: 'Therapy Complete' },
  ],
  cslChecklistOptions: state.cslChecklistOptions,
  json: cslJson,
});

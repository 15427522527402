/*
 * actionClassToPlainObject.ts
 * - thomas
 *
 * The purpose of this middleware is to allow actionCreators to be
 * replaced with classes. Redux expects plain objects, but there
 * are significant advantages to be gained from adopting typesafe
 * class constructors in place of action creators. The syntax itself
 * is more concise, and TypeScript's "discriminators" feature allows
 * for actions to take on their specific class typing within a reducer's
 * switch statement.
 *
 * For a good explanation of this technique, see:
 * https://medium.com/@martin_hotell/redux-typescript-typed-actions-with-less-keystrokes-d984063901d
 *
 * For details about union discriminators in Typescript:
 * https://basarat.gitbooks.io/typescript/docs/types/discriminated-unions.html
 */

import { Middleware, AnyAction } from 'redux';

export const actionToPlainObject: Middleware = store => next => (action: AnyAction) => {
  return next({ ...action });
};

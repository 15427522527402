import { ITask } from 'interfaces/redux/ITasks';
import { TaskType } from 'interfaces/TaskType';

class TasksUtil {
  static getTasksOfType = <T extends ITask>(tasks: ITask[], taskType: TaskType): T[] => {
    const tasksOfType = tasks.filter(x => x.taskType === taskType).map(x => x as T);

    return tasksOfType;
  };
}

export { TasksUtil };

import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classnames from 'classnames';
import React from 'react';
import { IProps } from './interfaces/IProps';
import { styles } from './pill-button.styles';

const PillButton: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { classes } = props;
  return (
    <Button
      className={classnames(classes.button, props.selected ? classes.selected : undefined)}
      onClick={() => props.onClick(props.value)}
      data-qa-id={`status_id_${props.label}${props.selected ? '_selected' : ''}`}
      disabled={props.disabled}
    >
      {props.label}
    </Button>
  );
};

export default withStyles(styles)(PillButton);

import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material';
import compose from 'recompose/compose';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { EDIT_COUNSELING_FORM } from 'constants/index';
import { editCounseling, fetchTaskCounts } from 'actions/action-tasks';
import { convertToArborDate } from 'models/time/arbor-date';
import FieldLabel from 'components/form/field/field-label';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { renderDatePicker, renderDateTimePicker } from 'components/form/datepicker/datetime-picker';
import {
  renderTextField,
  renderNumberField,
  renderYesNoDropdown,
  renderCheckboxGroup,
} from 'components/form/field/redux-field';
import RadioButton from 'components/form/radio/radio-button';
import RadioGroup from 'components/form/radio/radio-group';
import Validation, {
  validateDateTime,
  validateDate,
  defined,
  required,
} from 'components/form/validation/validation';
import { ReactSelectForReduxSingle } from 'components/form/field/react-select';
import {
  getInitialServiceGroupForReactSelect,
  followupDateChanged,
  addTaskOldValues,
} from 'services/utils/task-service';
import { workListChanged } from 'actions/action-patient';
import { styles } from '../task-detail-styles';

class EditCounseling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceGroupDropdown: -1,
    };
    this.handleServiceGroupDropdown = this.handleServiceGroupDropdown.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleServiceGroupDropdown(e) {
    this.setState({
      serviceGroupDropdown: e.target.value,
    });
  }

  // Handles the submit functionality. Right now it sets some fields to null for simplicity's sake
  handleSubmit(values) {
    const {
      editCounseling, // eslint-disable-line
      task,
      cancel,
      workListChanged, // eslint-disable-line
      fetchTaskCounts, //eslint-disable-line
    } = this.props;
    const payload = {
      ...values,
      id: task.id,
      completed_date: values.completed_date
        ? convertToArborDate(values.completed_date).getUtcDatetime()
        : null,
      followup_dt: convertToArborDate(values.followup_dt).getUtcDatetime(),
      last_checked_dt: values.last_checked_dt
        ? convertToArborDate(values.last_checked_dt).getUtcDatetime()
        : null,
      therapy_id: task.therapy_id,
      scheduled_dt: convertToArborDate(values.scheduled_dt).getUtcDatetime(),
      attempted_to_reach_patient_date: convertToArborDate(
        values.attempted_to_reach_patient_date,
      ).getUtcDate(),
      service_group_id: values.service_group_id ? values.service_group_id.value : null,
      additional_reason: values.additional_reason ? values.additional_reason : null,
      method: values.method || null,
      counseling_type: values.counseling_type ? values.counseling_type : null,
      reason:
        (values.reason && String(values.ongoing_counseling_needed) === '0') ||
        values.reason === '3' ||
        values.reason === '4'
          ? values.reason
          : null,
      duration_minutes: values.duration_minutes ? values.duration_minutes : null,
      completed_details: values.completed_details || 0,
    };
    return editCounseling(addTaskOldValues(payload, task)).then(() => {
      if (followupDateChanged(task.followup_dt, payload.followup_dt)) {
        fetchTaskCounts();
        workListChanged();
      }
      cancel();
    });
  }

  render() {
    const {
      handleSubmit,
      submitting,
      classes,
      cancel,
      taskStatuses,
      lookups,
      task,
      ongoingCounselingNeededVal,
      optOutReasonVal,
    } = this.props;

    const { serviceGroupDropdown } = this.state;

    const renderInPersonRadio = ({ input, meta: { touched, error, warning } }) => (
      <Grid container>
        <RadioGroup
          horizontal
          name="lead-source"
          id="lead-source"
          {...input}
          className={classes.radioGroup}
        >
          <RadioButton value="1">In Person</RadioButton>
          <RadioButton value="2">On the Phone</RadioButton>
          <RadioButton value="3">Chart Review</RadioButton>
        </RadioGroup>
        <Validation touched={touched} error={error} warning={warning} />
      </Grid>
    );

    const renderInitialOngoing = ({ input, meta: { touched, error, warning } }) => (
      <Grid container>
        <RadioGroup
          horizontal
          name="lead-source"
          id="lead-source"
          {...input}
          className={classes.radioGroup}
        >
          <RadioButton value="1">Initial</RadioButton>
          <RadioButton value="2">Ongoing</RadioButton>
        </RadioGroup>
        <Validation touched={touched} error={error} warning={warning} />
      </Grid>
    );
    const renderCanceledReason = ({ input, meta: { touched, error, warning } }) => (
      <Grid container>
        <RadioGroup horizontal name="reason" id="reason" {...input} className={classes.radioGroup}>
          <RadioButton value="3">Patient Opt Out</RadioButton>
          <RadioButton value="4">Other</RadioButton>
        </RadioGroup>
        <Validation touched={touched} error={error} warning={warning} />
      </Grid>
    );
    const renderNoOngoingCounselingNeeded = ({ input, meta: { touched, error, warning } }) => (
      <Grid container>
        <RadioGroup horizontal name="reason" id="reason" {...input} className={classes.radioGroup}>
          <RadioButton value="1">Patient Opt Out</RadioButton>
          <RadioButton value="2">Therapy Complete</RadioButton>
        </RadioGroup>
        <Validation touched={touched} error={error} warning={warning} />
      </Grid>
    );

    return (
      <Grid
        container
        justifyContent="flex-start"
        className={(classes.formRowContainer, classes.formContainer)}
      >
        <Grid item xs={12}>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            className={classes.editFormContainer}
            autoComplete="off"
          >
            <Grid container className={classes.formRowContainer}>
              <Grid item lg={3} lx={2} className={classes.fieldContainer}>
                <Field
                  name="followup_dt"
                  label="Follow up *"
                  component={renderDateTimePicker}
                  validate={[validateDateTime, required]}
                />
              </Grid>
              <Grid item lg={3} lx={2} className={classes.fieldContainer}>
                <Field
                  name="last_checked_dt"
                  label="Last Checked Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="service_group_id"
                  label="Service Group *"
                  validate={[required]}
                  component={ReactSelectForReduxSingle}
                  fields={lookups.serviceGroups.map(int => ({
                    label: int.display_name,
                    value: int.id,
                  }))}
                  state={serviceGroupDropdown}
                />
              </Grid>
              <Grid item xs={6}>
                <FieldLabel fieldName="Method" />
                <Field name="method" component={renderInPersonRadio} />
              </Grid>
              <Grid item xs={6}>
                <FieldLabel fieldName="Initial or Ongoing *" />
                <Field
                  name="counseling_type"
                  component={renderInitialOngoing}
                  validate={[required]}
                />
              </Grid>
              <Grid item lg={3} lx={2} className={classes.fieldContainer}>
                <Field
                  name="duration_minutes"
                  label="Session Duration (minutes)"
                  component={renderNumberField}
                />
              </Grid>
              {task.status !== 'Canceled' && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name="ongoing_counseling_needed"
                    label="Ongoing Counseling Needed"
                    component={renderYesNoDropdown}
                    validate={[defined]}
                  />
                </Grid>
              )}
              {task.status !== 'Canceled' && ongoingCounselingNeededVal <= 0 && (
                <Grid item xs={6}>
                  <FieldLabel fieldName="Reason" />
                  <Field
                    name="reason"
                    component={renderNoOngoingCounselingNeeded}
                    validate={[required]}
                  />
                </Grid>
              )}

              {task.status === 'Canceled' && (
                <Grid item xs={6}>
                  <FieldLabel fieldName="Canceled Reason" />
                  <Field name="reason" component={renderCanceledReason} validate={[required]} />
                </Grid>
              )}
              {task.status === 'Canceled' && optOutReasonVal === '4' && (
                <Grid item lg={3} lx={2} className={classes.fieldContainer}>
                  <Field name="additional_reason" label="Reason" component={renderTextField} />
                </Grid>
              )}

              <Grid item lg={6} lx={3} className={classes.fieldContainer}>
                <Field
                  name="scheduled_dt"
                  label="Scheduled Date"
                  component={renderDateTimePicker}
                  validate={[validateDateTime]}
                />
              </Grid>
              <Grid item lg={3} lx={2} className={classes.fieldContainer}>
                <Field
                  name="attempted_to_reach_patient_date"
                  label="Attempted to reach patient Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="completed_details"
                  fields={taskStatuses.counseling_completed_details}
                  component={renderCheckboxGroup}
                />
              </Grid>
            </Grid>

            {/* Eighth Row */}
            <ConfirmationPanel
              cancelButtonName="edit_therapy_cancel_button"
              submitButtonName="edit_therapy_submit_button"
              handleCancel={cancel}
              disableSubmit={submitting}
            />
          </form>
        </Grid>
      </Grid>
    );
  }
}

function handleFormName(task) {
  if (!task) {
    return null;
  }
  const name = `${EDIT_COUNSELING_FORM}_${task.therapy_id}_${task.id}`;
  return name;
}

function mapStateToProps(state, props) {
  const { patient, taskStatuses, lookups } = state;
  const name = handleFormName(props.task);
  const selector = formValueSelector(name);
  const ongoingCounselingNeededVal = selector(state, 'ongoing_counseling_needed');
  const optOutReasonVal = selector(state, 'reason');

  return {
    patient,
    taskStatuses,
    lookups,
    form: name,
    ongoingCounselingNeededVal,
    optOutReasonVal,
    initialValues: {
      followup_dt: convertToArborDate(props.task.followup_dt, true).getCustomerDatetime(true),
      last_checked_dt: convertToArborDate(props.task.last_checked_dt, true).getCustomerDate(true),
      status_id: props.task.status_id,
      service_group_id: props.task.service_group_id
        ? getInitialServiceGroupForReactSelect(props.task.service_group_id, props.serviceGroups)
        : '',
      additional_reason: props.task.additional_reason ? props.task.additional_reason : '',
      method: props.task.method ? `${props.task.method}` : '',
      counseling_type: props.task.counseling_type ? `${props.task.counseling_type}` : '',
      reason: props.task.reason ? props.task.reason : '',
      duration_minutes: props.task.duration_minutes ? props.task.duration_minutes : '',
      completed_details: props.task.completed_details ? props.task.completed_details : '',
      ongoing_counseling_needed: props.task.reason ? 0 : 1,
      scheduled_dt: convertToArborDate(props.task.scheduled_dt, true).getCustomerDate(
        true,
        'MM/DD/YYYY hh:mm A',
      ),
      attempted_to_reach_patient_date: convertToArborDate(
        props.task.attempted_to_reach_patient_date,
        true,
      ).getUtcDate(true),
    },
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchTaskCounts,
    workListChanged,
    editCounseling,
  }),
)(reduxForm({})(EditCounseling));

/* eslint-disable react/function-component-definition */
import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Button, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ErrorIcon from '@mui/icons-material/Error';
import { AUDIT_DEF_MEDLIST, THERAPY_STATUS_FILTERS, AUDIT_NOT_RESOLVED } from 'constants/index';
import RadioButton from 'components/form/radio/radio-button';
import RadioGroup from 'components/form/radio/radio-group';
import { windowFeatureIsEnabled } from 'config/window-features';
import { ReactSelect } from 'components/form/field/react-select';
import { TableIcon, CardsIcon } from 'components/icons/icons';
import { useSelector } from 'react-redux';
import { styles } from './therapy-detail.styles';
import AddMedsForm from '../meds/meds';

const TherapyFilter = props => {
  const {
    classes,
    theme,
    toggleButton,
    showMedicationList,
    totalMedicationCount,
    specialityCount,
    nonSpecialityCount,
    therapyInactiveCount,
    therapyInProgressCount,
    handlerTherapyFilter,
    handlerTherapyStatusFilter,
    therapyStatusFilter,
  } = props;

  const [therapyTypesSelected, setTherapyTypeSelected] = useState([]);
  const [addMedsFormIsOpen, setAddMedsFormIsOpen] = useState(false);
  // eslint-disable-next-line arrow-body-style
  const therapyTypeFilterOptions = React.useMemo(() => {
    const options = [
      {
        label: `SP (${specialityCount})`,
        value: 1, // this represents what type of speciality we're looking for. 1 = true, 0 = false
      },
      {
        label: `Non-SP (${nonSpecialityCount})`,
        value: 0,
      },
    ];
    return options;
  }, [specialityCount, nonSpecialityCount]);

  useEffect(() => {
    const newSelectedOptions = therapyTypesSelected.map(t => {
      const matchingOption = therapyTypeFilterOptions.find(x => x.value === t.value);
      return matchingOption;
    });

    setTherapyTypeSelected(newSelectedOptions);
  }, [specialityCount, nonSpecialityCount]);

  useEffect(() => {
    let filterTherapyFunc = item => item;
    if (therapyTypesSelected && therapyTypesSelected.length === 1) {
      filterTherapyFunc = item => item.is_specialty === therapyTypesSelected[0].value;
    }
    handlerTherapyFilter(filterTherapyFunc);
  }, [therapyTypesSelected]);

  const handleTherapyChange = event => {
    setTherapyTypeSelected(event);
  };

  const handleTherapyStatusFilterChange = event => {
    handlerTherapyStatusFilter(event);
  };

  const toggleEnrollMedsButton = () => {
    setAddMedsFormIsOpen(!addMedsFormIsOpen);
  };

  const medListAuditWarning = useSelector(
    state =>
      state.audit.therapies?.find(
        t =>
          t.definition.id === AUDIT_DEF_MEDLIST && t.resolution_state.name === AUDIT_NOT_RESOLVED,
      )?.definition?.name,
  );

  const ButtonIcon = useMemo(() => {
    if (showMedicationList) {
      return <CardsIcon color={theme.palette.primary.formLabel} />;
    }
    if (medListAuditWarning) {
      return (
        <Tooltip
          title={medListAuditWarning}
          placement="top-end"
          sx={{ color: theme.palette.primary.lightRed }}
        >
          <ErrorIcon />
        </Tooltip>
      );
    }
    return <TableIcon color={theme.palette.primary.formLabel} />;
  }, [showMedicationList, medListAuditWarning]);

  return (
    <>
      <Grid item className={classes.therapyFilterContainerPadding} xs={12}>
        <Grid container className={classes.therapyFilter}>
          <Grid item xs={12}>
            <h2>{!showMedicationList ? 'Therapies' : 'Medication List'}</h2>
          </Grid>
          {!showMedicationList && (
            <Grid item xs={6}>
              <RadioGroup
                horizontal
                name="therapiesFilter"
                id="therapiesFilter"
                className={classes.radioGroup}
                onChange={handleTherapyStatusFilterChange}
              >
                <RadioButton
                  value={THERAPY_STATUS_FILTERS.IN_PROGRESS_THERAPIES}
                  checked={therapyStatusFilter === THERAPY_STATUS_FILTERS.IN_PROGRESS_THERAPIES}
                  key="inProgressTherapies"
                >
                  {`In Progress Therapies (${therapyInProgressCount || 0})`}
                </RadioButton>
                <RadioButton
                  value={THERAPY_STATUS_FILTERS.INACTIVE_THERAPIES}
                  checked={therapyStatusFilter === THERAPY_STATUS_FILTERS.INACTIVE_THERAPIES}
                  key="inactiveTherapies"
                >
                  {`Inactive Therapies (${therapyInactiveCount || 0})`}
                </RadioButton>
              </RadioGroup>
            </Grid>
          )}
          <Grid
            className={classes.therapyFilterButtonsContainer}
            item
            xs={!showMedicationList ? 6 : 12}
          >
            <Button
              onClick={toggleButton}
              variant="outlined"
              className={classes.therapyFilterButton}
            >
              <span className={classes.therapyFilterButtonText}>
                {!showMedicationList ? 'Medication List' : 'Show Therapy Cards'}
              </span>
              {ButtonIcon}
            </Button>
            {!showMedicationList && (
              <Button
                onClick={toggleEnrollMedsButton}
                variant="outlined"
                className={classes.therapyFilterMedsButton}
              >
                <span className={classes.therapyFilterButtonText}>Medication Enrollment</span>
              </Button>
            )}
          </Grid>
          {!showMedicationList ? (
            <Grid item xs={4}>
              <ReactSelect
                name="therapiesSelection"
                label="Type"
                handleOnChange={e => handleTherapyChange(e)}
                fields={therapyTypeFilterOptions}
                value={therapyTypesSelected}
              />
            </Grid>
          ) : (
            <Grid item xs={8}>
              {`Showing ${totalMedicationCount} total medications in medication list`}
            </Grid>
          )}
        </Grid>
      </Grid>
      {addMedsFormIsOpen && <AddMedsForm onClose={toggleEnrollMedsButton} />}
    </>
  );
};

export default withStyles(styles, { withTheme: true })(TherapyFilter);

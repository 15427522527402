import React, { useState } from 'react';
import { Typography, Grid, Divider } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import { convertToArborDate } from 'models/time/arbor-date';
import { compose } from 'recompose';
import { UPLOADED_DOCUMENT_LANGUAGE } from '../../constants';
import DocumentFileIcon from './document-file-icon';
import { ScreenExpand } from '../icons/icons';
import { styles } from './uploaded-document-styles';

const UploadedDocumentRecord = props => {
  const { classes, documentData, handleClick } = props;
  const [hovered, setHovered] = useState(false);
  const canDownload = !documentData.is_archived && documentData.scanned && documentData.safe;
  const recordClass = classNames(classes.docContainer, {
    [classes.archivedDocContainer]: documentData.is_archived,
    [classes.pointer]: canDownload,
  });

  return (
    <>
      <Grid
        container
        className={recordClass}
        onClick={() => {
          if (handleClick && canDownload) {
            handleClick(documentData.id, documentData.is_archived);
          }
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Grid item xs={1}>
          {hovered && canDownload ? (
            <ScreenExpand />
          ) : (
            <DocumentFileIcon filename={documentData.filename} className={classes.fileTypeIcon} />
          )}
        </Grid>
        <Grid item xs={11}>
          <Grid container>
            <Grid container item xs={12} justifyContent="space-between">
              <Grid item xs={9}>
                <Typography className={classNames(classes.fileName, classes.bottomPadding)}>
                  {documentData.filename}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={[classes.metaText, classes.alignRight]}>
                  {convertToArborDate(documentData.updated).getCustomerDate(true)}
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.tag} item xs={12}>
              {documentData.tag_display &&
                documentData.tag_display.map(item => `#${item}`).join(' ')}
            </Grid>
            <Grid item xs={12} className={classes.bottomPadding}>
              <Typography className={classes.metaText}>
                {(documentData.labels || []).join(', ')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.metaText}>
                {`by ${documentData.created_by_user}`}
              </Typography>
            </Grid>
            {!documentData.scanned && (
              <Grid item xs={12}>
                <Typography className={classNames(classes.documentMessage, classes.warningText)}>
                  {UPLOADED_DOCUMENT_LANGUAGE.unscanned.join('\n')}
                </Typography>
              </Grid>
            )}
            <br />
            {Boolean(documentData.scanned && !documentData.safe) && (
              <Grid item xs={12}>
                <Typography className={classNames(classes.documentMessage, classes.alertText)}>
                  {UPLOADED_DOCUMENT_LANGUAGE.unsafe.join('\n')}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

export default compose(withStyles(styles))(UploadedDocumentRecord);

/* eslint-disable react/destructuring-assignment */
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { useState, useEffect } from 'react';
import { getStatusByStatusId, getStatusIdByStatusName } from 'services/utils/task-service';
import NoteDisplay from 'components/note/note-display';
import ActionRow from 'components/note/action-row';
import DocumentDisplay from 'components/document/document-display';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { TASK_COUNSELING, CSL, COMPLETE_COUNSELING_FORM } from 'constants/index';
import TaskDetailForm from 'components/dynamic-form/task-forms/task-detail-form';
import { TaskStatusFactory } from 'factories/task-status-factory/task-status-factory';
import { CounselingStatus } from 'interfaces/enums/TaskStatuses/CounselingStatus';
import { fetchRelatedTasks } from 'actions/action-tasks';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { notifyError } from 'actions/action-notifications';
import CounselingTransition from './counseling-transition/counseling-transition';
import { styles } from '../task-detail-styles';
import EditCounseling from './edit-counseling';
import TaskLinks from '../task-links';
import Complete from './counseling-transition/forms/complete';
import { StatusSelector } from '../common/status-selector';
import { ClinicalDataClient } from '../../../clients/clinical-data';
import { fetchClinicalData } from '../../../actions/action-clinical-data';
import { logger } from '../../../winston-logger';

function CounselingDetail(props) {
  const {
    statuses,
    task,
    classes,
    notes,
    documents,
    serviceGroups,
    tagTypeId,
    tagTypeLabel,
    therapy,
    therapyIndex,
    displayEdit,
    handleCancel,
    taskIDPrefix,
    drugName,
    currentUser,
    resourceStates,
    fetchRelatedTasks: fetchRelatedTasksAction,
  } = props;
  const { status_id: statusId, service_group_id: serviceGroupId } = task;

  const cslResourceStates = (resourceStates || []).filter(
    resourceState => resourceState.resource_name === CSL,
  );

  const [selectedStatus, setSelectedStatus] = useState(
    task.status ? task.status : getStatusByStatusId(task.status_id, statuses),
  );
  const [isFetchingRelatedTasks, setIsFetchingRelatedTasks] = useState(false);
  const selectedStatusId = getStatusIdByStatusName(selectedStatus, statuses);
  const taskProcessor = TaskStatusFactory.getProcessor(CSL);

  useEffect(() => {
    if (getStatusIdByStatusName(selectedStatus, statuses) === CounselingStatus.Completed) {
      setIsFetchingRelatedTasks(true);
    }
  }, [selectedStatus]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isFetchingRelatedTasks === true) {
      fetchRelatedTasksAction(task.id, CSL)
        .then(async () => {
          const response = await ClinicalDataClient.fetch(therapy.patient_id);
          const fetchAction = fetchClinicalData(response.data);
          dispatch(fetchAction);
          setIsFetchingRelatedTasks(false);
        })
        .catch(err => {
          dispatch(notifyError('Failed to load related tasks.'));
          logger.error(err);
          setIsFetchingRelatedTasks(false);
        });
    }
  }, [isFetchingRelatedTasks]);

  return (
    <div>
      {isFetchingRelatedTasks ? (
        <div>Loading related tasks ... </div>
      ) : (
        <>
          <Grid
            container
            alignItems="center"
            className={displayEdit ? classes.taskDetailContainerEdit : classes.taskDetailContainer}
          >
            <Grid item lg={3}>
              <Grid container alignItems="center">
                {!displayEdit && taskProcessor && (
                  <StatusSelector
                    currentUser={currentUser}
                    resourceStates={cslResourceStates}
                    value={selectedStatus}
                    currentStatus={task.status}
                    onChange={value => setSelectedStatus(value)}
                    statuses={statuses}
                    taskIDPrefix={taskIDPrefix}
                    enabled={taskProcessor.taskCanTransition(task)}
                    task={task}
                  />
                )}
              </Grid>
            </Grid>
            {!displayEdit && (
              <Grid item lg={5} xs={6}>
                {taskProcessor && taskProcessor.taskIsComplete(task) ? null : (
                  <TaskDetailForm task={task} taskType={CSL} />
                )}
              </Grid>
            )}
            {displayEdit && <Grid item xs={5} />}
            <Grid item xs={4}>
              {!displayEdit && (
                <Grid item xs={12}>
                  <Grid container className={classes.note}>
                    <NoteDisplay {...notes} tagResourceId={task.id} />
                  </Grid>
                  <DocumentDisplay
                    taskIdResourceIds={[{ tagTypeId, resourceId: task.id }]}
                    tagTypeId={tagTypeId}
                    resourceId={task.id}
                    drugName={drugName}
                    tagTypeLabel={tagTypeLabel}
                    documents={documents}
                  />
                  <ActionRow
                    therapy={therapy}
                    task={task}
                    tagTypeId={tagTypeId}
                    tagResourceId={task.id}
                    cancel={handleCancel}
                    defaultServiceGroup={serviceGroupId}
                    serviceGroups={serviceGroups}
                    taskType={TASK_COUNSELING}
                    idPrefix={taskIDPrefix}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <TaskLinks links={task.links} />
          <Grid container>
            {/* Edit Form */}
            {displayEdit && (
              <Grid item xs={12}>
                {taskProcessor.taskShouldRenderCustomForm(task) ? (
                  <CounselingTransition
                    tasks={[task]}
                    newStatusId={task.status_id}
                    legacyFormProps={{ statuses, cancel: handleCancel, serviceGroups }}
                    legacyCancelClick={handleCancel}
                    hideTitle
                    hideStatusSelector
                    tab="therapies"
                    therapyIndex={therapyIndex}
                    editing
                  />
                ) : (
                  <EditCounseling
                    task={task}
                    statuses={statuses}
                    cancel={handleCancel}
                    serviceGroups={serviceGroups}
                  />
                )}
              </Grid>
            )}
            {/* Change Status Form */}
            {!displayEdit &&
              statusId &&
              statuses &&
              selectedStatus !== getStatusByStatusId(statusId, statuses) && (
                <Grid
                  item
                  xs={12}
                  className={selectedStatus !== task.status ? classes.statusContainer : null}
                >
                  <CounselingTransition
                    tasks={[task]}
                    therapyIndex={therapyIndex}
                    newStatusId={selectedStatusId}
                    legacyCancelClick={() =>
                      setSelectedStatus(getStatusByStatusId(task.status_id, statuses))}
                    hideTitle
                    hideStatusSelector
                    tab="therapies"
                    afterSubmit={() => setSelectedStatus(selectedStatus)}
                  />
                </Grid>
              )}
            {/* Complete Form */}
            {!displayEdit && (
              <Grid item xs={12}>
                {taskProcessor &&
                (taskProcessor.taskIsComplete(task) || taskProcessor?.taskNeedsReview(task)) ? (
                  <Complete
                    form={`${COMPLETE_COUNSELING_FORM}_${task.id}`}
                    tasks={[task]}
                    requestReview={taskProcessor?.taskNeedsReview(task)}
                    therapyIndex={therapyIndex}
                    viewOnly
                    editing
                    onCancel={props.handleCancel}
                  />
                ) : null}
              </Grid>
            )}
          </Grid>
        </>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  const { patient, taskStatuses, lookups } = state;
  return {
    patient,
    taskStatuses,
    lookups,
    users: state.lookups.users,
    currentUser: state.auth.currentUser,
    resourceStates: state.lookups.resourceStates,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { fetchRelatedTasks }),
)(CounselingDetail);

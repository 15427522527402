import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Tooltip } from '@mui/material';
import { Printer } from 'components/icons/icons';
import { ITask } from 'interfaces/redux/ITasks';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { updateFdcStatus } from './update-fdc-status';
import { printPdfDocument } from '../patient/documents/preview-document';

interface IPrintOrderForm {
  data: ITask;
  patientId: number;
  setStatus?: React.Dispatch<string>;
}

const PrintOrderForm = (props: IPrintOrderForm) => {
  const { data, patientId, setStatus } = props;
  const dispatch = useDispatch();
  const [currentStatus, setCurrentStatus] = useState(data.status_id || undefined);
  const customerId = useTypedSelector(state => state.filters.selectedCustomerId);
  const [alreadyPrinted, setAlreadyPrinted] = useState(false);

  const orderId = data.order_id.toString();

  const printUpdateOrderForm = useCallback(() => {
    printPdfDocument(data.orderFormDocumentId, customerId, patientId).then(() => {
      updateFdcStatus({
        orderId,
        data,
        dispatch,
        setStatus,
        setCurrentStatus,
      });
    });
    setAlreadyPrinted(true);
  }, [currentStatus, patientId, setStatus]);

  return !alreadyPrinted ? (
    <Tooltip title={patientId ? `Print packing list for order ${orderId}` : 'Loading'}>
      <span>
        <Button onClick={printUpdateOrderForm} disabled={!patientId}>
          <Printer />
        </Button>
      </span>
    </Tooltip>
  ) : (
    <></>
  );
};

export default PrintOrderForm;

import { IAddContactModal } from 'interfaces/redux/IAddContactModal';
import {
  AddContactModalActionType,
  IAddContactModalAction,
} from 'actions/action-add-contact-modal';

const initialState: IAddContactModal = {
  visible: false,
};

export const addContactModalReducer = (
  state: IAddContactModal = initialState,
  action: IAddContactModalAction,
) => {
  switch (action.type) {
    case AddContactModalActionType.SetState:
      return {
        ...action.payload,
      };
    case AddContactModalActionType.ResetModal:
      return initialState;
    default:
      return state;
  }
};

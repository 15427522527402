import HTTP from 'services/http';
import { SEND_INVOICING_FIELD } from 'constants/index';

export function updateInvoicingRecord(formData) {
  const URL = '/invoicing';
  const request = HTTP.patch(URL, formData, {});

  return {
    meta: {
      errorMessage: 'Save field failed: ',
      successMessage: 'Field saved successfully',
    },
    type: SEND_INVOICING_FIELD,
    payload: request,
  };
}

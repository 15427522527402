import { groupBy } from 'helpers/misc';
import { convertToArborDate } from 'models/time/arbor-date';
import { convertMapToList } from 'reducers/helper-reducer';
import { ELIGIBILITY_CHECK_MESSAGE } from 'constants/index';
import HTTP from '../http';

const insuranceTypeMapping = {
  1: 'Primary',
  2: 'Secondary',
  3: 'Tertiary',
};

export const ERROR_MESSAGE_PREFIX = 'ERX';
export const APPROVED_ELIGIBILITY_CHECK_TRANSACTION_CODE = 'A';

const getValuesFromMessage = (message, value) => {
  if (message) {
    const { position, length } = ELIGIBILITY_CHECK_MESSAGE[value];
    return message.substr(position - 1, length);
  }
  return undefined;
};

// eslint-disable-next-line arrow-body-style
const getInsuranceReconcilationFields = (isNew, item, eligibilityData, isFirst) => {
  const { message } = eligibilityData;
  const result = [
    {
      label: 'PBM Name',
      name: 'pbm_name',
      value: isNew ? undefined : item.pbm_name,
      highlight: false,
      hidden: false,
    },
    {
      label: 'Start Date',
      name: 'start_date',
      value: isNew
        ? convertToArborDate(item.benefitEffectiveStart).getCustomerDate(true)
        : convertToArborDate(item.start_date).getCustomerDate(true),
      highlight: false,
    },
    {
      label: 'End Date',
      name: 'end_date',
      value: isNew
        ? convertToArborDate(item.benefitTermination).getCustomerDate(true)
        : convertToArborDate(item.end_date).getCustomerDate(true),
      highlight: false,
    },
    {
      label: 'Phone Number',
      name: 'plan_phone_number',
      value: isNew ? item.helpDeskNumber : item.pbm_phone_number,
      highlight: false,
    },
    {
      label: 'Patient ID',
      name: 'pbm_patient_id',
      value: isNew ? item.cardholderId : item.pbm_patient_id,
      highlight: false,
    },
    {
      label: 'BIN Number',
      name: 'pbm_bin_number',
      value: isNew ? item.payorId : item.pbm_bin_number,
      highlight: false,
    },
    {
      label: 'Rx Group Number',
      name: 'pbm_rx_group_number',
      value: isNew ? item.groupId : item.pbm_rx_group_number,
      highlight: false,
    },
    {
      label: 'Processor Control Number',
      name: 'pbm_processor_control_number',
      value: isNew ? item.pcn : item.pbm_processor_control_number,
      highlight: false,
    },
    {
      label: 'Insurance Type ID',
      name: 'insurance_type_id',
      value: isNew ? item.coverageType : item.insurance_type_id,
      highlight: false,
      hidden: true,
    },
    {
      label: 'Relation Code',
      name: 'pbm_relation_code',
      value: isNew ? item.relationship : item.pbm_relation_code,
      highlight: false,
      hidden: false,
    },
  ];

  // Only add the LIS information to the very first result. Should only exist on the first one.
  if (isFirst) {
    result.push(
      ...[
        {
          isSubHeader: true,
          label: 'Low Income Subsidy',
        },
        {
          label: 'LIS Level',
          name: 'lis_level',
          value: isNew ? getValuesFromMessage(message, 'lis_level') : item.lis_level,
          highlight: false,
        },
        {
          label: 'LIS Effective Date',
          name: 'lis_effective_date',
          value: isNew
            ? convertToArborDate(
                getValuesFromMessage(message, 'lis_effective_date'),
              ).getCustomerDate(true)
            : convertToArborDate(item.lis_effective_date).getCustomerDate(true),
          highlight: false,
        },
        {
          label: 'LIS Termination Date',
          name: 'lis_termination_date',
          value: isNew
            ? convertToArborDate(
                getValuesFromMessage(message, 'lis_termination_date'),
              ).getCustomerDate(true)
            : convertToArborDate(item.lis_termination_date).getCustomerDate(true),
          highlight: false,
        },
        {
          label: 'Medicare Plan Type',
          name: 'lis_medicare_plan_type',
          value: isNew
            ? getValuesFromMessage(message, 'lis_medicare_plan_type')
            : item.lis_medicare_plan_type,
          highlight: false,
        },
        {
          label: 'Medicare Beneficiary Identifier',
          name: 'lis_medicare_beneficiary_id',
          value: isNew
            ? getValuesFromMessage(message, 'lis_medicare_beneficiary_id')
            : item.lis_medicare_beneficiary_id,
          highlight: false,
        },
        {
          label: 'Effective Date for MBI',
          name: 'lis_effective_date_mbi',
          value: isNew
            ? convertToArborDate(
                getValuesFromMessage(message, 'lis_effective_date_mbi'),
              ).getCustomerDate(true)
            : convertToArborDate(item.lis_effective_date_mbi).getCustomerDate(true),
          highlight: false,
        },
        {
          label: 'Benefit Id',
          name: 'lis_benefit_id',
          value: isNew ? eligibilityData.benefitId : item.lis_benefit_id,
          highlight: false,
        },
        {
          label: 'Formulary Id',
          name: 'lis_formulary_id',
          value: isNew ? eligibilityData.formularyId : item.lis_formulary_id,
          highlight: false,
        },
        {
          label: 'Date of Service',
          name: 'lis_date_of_service',
          value: isNew
            ? convertToArborDate(eligibilityData.dateOfService).getCustomerDate(true)
            : convertToArborDate(item.lis_date_of_service).getCustomerDate(true),
          highlight: false,
        },
        {
          label: 'Contract Number',
          name: 'lis_contract_number',
          value: isNew ? eligibilityData.contractNumber : item.lis_contract_number,
          highlight: false,
        },
        {
          label: 'Medicare Part D Code',
          name: 'lis_medicare_part_d_code',
          value: isNew ? eligibilityData.medicarePartDCode : item.lis_medicare_part_d_code,
          highlight: false,
        },
        {
          label: 'Other Payor Id Count',
          name: 'lis_other_payor_id_count',
          value: isNew ? eligibilityData.otherPayorIdCount : item.lis_other_payor_id_count,
          highlight: false,
        },
        {
          label: 'Cms Low Income Cost Sharing',
          name: 'lis_cms_low_income_cost_sharing',
          value: isNew
            ? eligibilityData.cmsLowIncomeCostSharing
            : item.lis_cms_low_income_cost_sharing,
          highlight: false,
        },
        {
          label: 'Transaction Response Status',
          name: 'lis_transaction_response_status',
          value: isNew
            ? eligibilityData.transactionResponseStatus
            : item.lis_transaction_response_status,
          highlight: false,
        },
        {
          label: 'Next Medicare Part D Termination',
          name: 'lis_next_medicare_part_d_termination',
          value: isNew
            ? convertToArborDate(eligibilityData.nextMedicarePartDTermination).getCustomerDate(true)
            : convertToArborDate(item.lis_next_medicare_part_d_termination).getCustomerDate(true),
          highlight: false,
        },
        {
          label: 'Next Medicare Part D Effective Start',
          name: 'lis_next_medicare_part_d_effective_start',
          value: isNew
            ? convertToArborDate(eligibilityData.nextMedicarePartDEffectiveStart).getCustomerDate(
                true,
              )
            : convertToArborDate(item.lis_next_medicare_part_d_effective_start).getCustomerDate(
                true,
              ),
          highlight: false,
        },
      ],
    );
  }

  return result;
};

export const matchInsurances = async (eligibilityCheckData, insurances) => {
  const { payors } = eligibilityCheckData;
  return payors.map((payer, payorsIndex) => {
    const eligibilityCheckResult = getInsuranceReconcilationFields(
      true,
      payer,
      eligibilityCheckData,
      payorsIndex === 0,
    );
    let existingInsuranceInformation = [];
    let original = {};
    let insuranceType;
    if (payer.coverageType && insuranceTypeMapping[payer.coverageType]) {
      insuranceType = insuranceTypeMapping[payer.coverageType];
      if (insurances[insuranceType]) {
        original = insurances[insuranceType];
        existingInsuranceInformation = getInsuranceReconcilationFields(
          false,
          insurances[insuranceType],
          eligibilityCheckData,
          true,
        );
        existingInsuranceInformation.forEach((item, index) => {
          if (item.value !== eligibilityCheckResult[index].value) {
            eligibilityCheckResult[index].highlight = true;
            item.highlight = true;
          }
        });
      }
    }
    let leftResult = eligibilityCheckResult;
    const [pbmInsuranceId] = eligibilityCheckResult.filter(pb => pb.name === 'pbm_patient_id');
    if (pbmInsuranceId) {
      const [insurance] =
        eligibilityCheckData.patientInsurances?.filter(
          pi => pi.pbm_patient_id === pbmInsuranceId.value,
        ) || [];
      const values = eligibilityCheckResult.filter(x => x.name !== 'pbm_name');
      leftResult = [
        {
          highlight: false,
          label: 'PBM Name',
          name: 'pbm_name',
          value: insurance?.pbm_name,
        },
        ...values,
      ];
    }
    return {
      key: insuranceType,
      left: leftResult,
      right: existingInsuranceInformation,
      original,
      payer,
    };
  });
};

export const structureInsurances = financials => {
  const insurances = {};
  if (financials && financials.pbm_insurances && financials.pbm_insurances.data) {
    const insuranceData = financials.pbm_insurances.data;
    const groupedInsurances = groupBy(
      convertMapToList(insuranceData).filter(ins => ins.valid),
      'insurance_type',
    );
    Object.keys(groupedInsurances).forEach(key => {
      [insurances[key]] = groupedInsurances[key];
    });
  }
  return insurances;
};

export const eligibilityCheck = async (
  patientId,
  lastName,
  firstName,
  dob,
  zip,
  gender,
  ssn,
  dateOfService,
  financials,
) => {
  const url = `/patients/${patientId}/eligibility-check`;
  const payload = {
    lastName,
    firstName,
    dob,
    zip,
    gender,
    ssn,
    dateOfService,
  };
  const apiResult = await HTTP.post(url, payload, {}, true);
  const insurances = structureInsurances(financials);
  const comparedData = await matchInsurances(apiResult.data, insurances);
  return {
    comparedData,
    message: apiResult.data.message,
    transactionResponseStatus: apiResult.data.transactionResponseStatus,
  };
};

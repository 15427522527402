import React, { Component, useEffect } from 'react';
import { Grid, Tabs, Tab, Button, Typography, Tooltip } from '@mui/material';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { Link, Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import compose from 'recompose/compose';
import CustomerDropdown from 'containers/nav/top/customer-dropdown';
import UserDropdown from 'containers/nav/top/user-dropdown';
import { SEARCH_FORM, SEARCH_SIDEBAR, ROUTES } from 'constants/index';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { renderSearchFieldWithAttachedField } from 'components/form/field/redux-field';
import {
  searchPatients,
  updatePatientSearchIndex,
  fetchPatient,
  toggleTabRefresh,
  selectPatientTab,
} from 'actions/action-patient';
import { windowFeatureIsEnabled } from 'config/window-features';
import HelpIcon from '@mui/icons-material/HelpOutlined';
import { styles } from './nav-styles';
import {
  MainNavPatients,
  MainNavSchedule,
  MainNavReports,
  MainNavShipping,
  MainNavAccreditation,
  SmallSearch,
} from '../icons/icons';
import { withCustomer, getPatientsUrl } from '../../helpers/router';

class TopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleKeyboardEvent = this.handleKeyboardEvent.bind(this);
    this.handleFieldBlur = this.handleFieldBlur.bind(this);
    this.handleFieldFocus = this.handleFieldFocus.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      numberOfPatients,
      patientInListIndex,
      updatePatientSearchIndex, //eslint-disable-line
      fetchPatient, // eslint-disable-line
    } = this.props;
    if (prevProps.numberOfPatients !== numberOfPatients && patientInListIndex !== 0) {
      if (numberOfPatients === 0) {
        updatePatientSearchIndex(0);
      } else {
        updatePatientSearchIndex(numberOfPatients - 1);
      }
    }
  }

  handleChange(_, value) {
    this.setState({ value });
  }

  handleSearch() {
    const { history, reset } = this.props;
    reset();
    const searchLink = withCustomer('/patients?sidebar=search');
    history.push(searchLink);
  }

  handleSearchChange(e) {
    const {
      searchPatients, // eslint-disable-line
    } = this.props;
    const searchStr = e.target.value;
    const trimSearchStr = searchStr && searchStr.trim();
    if (trimSearchStr && trimSearchStr.length > 1) {
      searchPatients({ searchStr: trimSearchStr });
    }
  }

  handleFieldBlur() {
    const {
      updatePatientSearchIndex, //eslint-disable-line
    } = this.props;
    updatePatientSearchIndex(null);
  }

  handleFieldFocus() {
    const {
      updatePatientSearchIndex, //eslint-disable-line
    } = this.props;
    updatePatientSearchIndex(0);
  }

  handleKeyboardEvent(e) {
    const {
      searchPatients, // eslint-disable-line
      updatePatientSearchIndex, //eslint-disable-line
      selectPatient, //eslint-disable-line
      toggleTabRefresh, // eslint-disable-line
      selectPatientTab, // eslint-disable-line
      patientInListIndex,
      numberOfPatients,
      patientList,
      location,
      history,
      tabControl,
    } = this.props;
    let newIndex = patientInListIndex;
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      newIndex -= 1;
      if (newIndex <= 0) {
        updatePatientSearchIndex(0);
      } else {
        updatePatientSearchIndex(newIndex);
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      newIndex += 1;
      if (newIndex >= numberOfPatients - 1) {
        updatePatientSearchIndex(numberOfPatients - 1);
      } else {
        updatePatientSearchIndex(newIndex);
      }
    } else if (e.key === 'Enter') {
      e.preventDefault();
      const patientToFetchId =
        patientList && patientList.data && patientList.data.length
          ? patientList.data[patientInListIndex].id
          : null;
      if (patientToFetchId) {
        const { patientTab } = tabControl;
        if (patientTab === 'therapies') {
          toggleTabRefresh(true);
        } else {
          selectPatientTab('therapies');
        }
        let patientLink = `/patients/${patientToFetchId}/therapies`;
        patientLink += location.search;
        history.push(withCustomer(patientLink));
      }
    }
  }

  render() {
    const { classes, sidebarTaskType, sidebarType, accreditation } = this.props;
    const { value } = this.state;

    const patientsLink = getPatientsUrl(sidebarTaskType, 'tasks');
    const scheduleLink = withCustomer('/schedule');
    const reportsLink = withCustomer('/reports');
    const shippingLink = withCustomer('/shipping');
    const accreditationLink = withCustomer('/accreditation');

    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        flexWrap="nowrap"
        className={classes.topNav}
      >
        <Switch>
          <Route path={ROUTES.APPLICATION_MANAGER} />
          <Route>
            {sidebarType === SEARCH_SIDEBAR ? (
              <form
                onSubmit={e => {
                  e.preventDefault();
                }}
                autoComplete="off"
                onKeyDown={this.handleKeyboardEvent}
                onBlur={this.handleFieldBlur}
                onFocus={this.handleFieldFocus}
                role="presentation"
                className={classes.topNavSearchForm}
              >
                <Grid container xs={12} minWidth="180px">
                  <Grid item xs={11}>
                    <Field
                      name="search"
                      width="100%"
                      component={renderSearchFieldWithAttachedField}
                      onChange={this.handleSearchChange}
                      placeholder="Search by Last or Last, First or MRN or DOB or Phone"
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={1} className={classes.searchTooltipContainer}>
                    <Tooltip
                      classes={{ tooltip: classes.searchHelpTooltip }}
                      placement="right-start"
                      title={
                        <>
                          Search By:
                          <br />
                          Last Name - min. 2 characters
                          <br />
                          Last Name, First Name - full last name, min. 2 chars first name
                          <br />
                          MRN - exact match
                          <br />
                          DOB - mm/dd/yyyy
                          <br />
                          Phone - ########## or +1##########
                        </>
                      }
                    >
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
              </form>
            ) : (
              <Grid item>
                <Button
                  className={classes.searchContainer}
                  onClick={this.handleSearch}
                  data-qa-id="button-search"
                >
                  <SmallSearch />
                </Button>
              </Grid>
            )}
          </Route>
        </Switch>
        <Grid item xs={6} md={7.5} className={classes.topNavTabs}>
          <Switch>
            <Route path={ROUTES.APPLICATION_MANAGER}>
              <Typography className={classes.applicationManagerHeader}>
                Application Manager
              </Typography>
            </Route>
            <Route>
              <Tabs value={value} onChange={this.handleChange} classes={{ root: classes.tabRoot }}>
                <Tab
                  icon={<MainNavPatients />}
                  component={Link}
                  to={patientsLink}
                  classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                />
                <Tab
                  icon={<MainNavSchedule />}
                  component={Link}
                  to={scheduleLink}
                  classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                />
                <Tab
                  icon={<MainNavReports />}
                  component={Link}
                  to={reportsLink}
                  classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                />
                <Tab
                  icon={<MainNavShipping />}
                  component={Link}
                  to={shippingLink}
                  classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                />
                {windowFeatureIsEnabled('accreditation_module') && (
                  <Tab
                    icon={<MainNavAccreditation />}
                    component={Link}
                    to={accreditationLink}
                    classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                  />
                )}
              </Tabs>
            </Route>
          </Switch>
        </Grid>
        <Grid item xs className={classes.topNavRight}>
          <Grid container justifyContent="flex-end" className={classes.topNav}>
            <div id="customerDropdown">
              <Switch>
                <Route path={ROUTES.APPLICATION_MANAGER} />
                <Route component={CustomerDropdown} />
              </Switch>
            </div>
            <div id="userDropdown">
              <Switch>
                <Route component={UserDropdown} />
              </Switch>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const { sidebarTaskType, lookups, sidebarType, patientList, tabControl } = state;
  const selector = formValueSelector(SEARCH_FORM);
  const searchStr = selector(state, 'search');
  const patientInListIndex = patientList.selectedPatientInListIndex;
  const numberOfPatients =
    patientList.data && patientList.data.length ? patientList.data.length : 0;
  return {
    sidebarTaskType,
    lookups,
    searchStr,
    sidebarType,
    patientInListIndex,
    numberOfPatients,
    patientList,
    tabControl,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    searchPatients,
    updatePatientSearchIndex,
    fetchPatient,
    toggleTabRefresh,
    selectPatientTab,
  }),
  withRouter,
)(reduxForm({ form: SEARCH_FORM })(TopNav));

/* eslint-disable @typescript-eslint/naming-convention */
import { AxiosResponse } from 'axios';
import { Reducer } from 'redux';
import { FETCH_CDM_PROGRAMS } from '../constants';

export interface ICdmProgramAndConditions {
  active?: boolean;
  associatedIcdCodes?: string[];
  id?: number;
  cdm_program_id: number | undefined;
  customer_id?: number;
  frequency_days: number;
  created_dt?: string;
  updated_dt?: string;
  created_by?: number;
  updated_by?: number;
  specialty_diagnosis_id?: number;
  comorbidity_diagnosis_id?: number | null;
  cdm_customer_program_id?: number | null;
  patient_id?: number | null;
  clinical_data_type_id?: number;
  enrollment_clinical_data_value?: string | undefined;
  graduation_clinical_data_value?: string | undefined;
  enrollment_clinical_data_operator?: string | undefined;
  graduation_clinical_data_operator?: string | undefined;
  name?: string;
  cdm_programs_frequency_days?: string;
  cdm_programs_conditions_id?: number;
  cdm_customer_programs_frequency_days?: number;
  type_name?: string;
  rs_clinical_item_id?: number | null;
  cdm_program_conditions_id?: number;
}

export interface ICdmProgramsState {
  cdmPrograms: ICdmProgramAndConditions[];
}

export interface ICdmProgramsStateResponse {
  page: number;
  pageSize: number;
  totalItems: number;
  items: ICdmProgramAndConditions[];
}

const initialState: ICdmProgramsState = { cdmPrograms: [] };

interface IAction {
  type: 'FETCH_CDM_PROGRAMS';
  payload: AxiosResponse<ICdmProgramsStateResponse>;
}

export const cdmPrograms: Reducer<ICdmProgramsState, IAction> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case FETCH_CDM_PROGRAMS:
      return { ...state, cdmPrograms: payload.data.items };

    default:
      return state;
  }
};

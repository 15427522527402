import React from 'react';
import { useSelector } from 'react-redux';
import { DUR } from 'constants/index';
import { DurStatus } from 'interfaces/enums/TaskStatuses/DrugUtilizationReviewStatus';
import DrugUtilizationReviewContainer from './drug-utilization-review-container';
import { getCurrentDur } from './common';

/**
 * Wraps the DrugUtilizationReviewContainer component by getting the proper DUR task to render and
 * passing it to the component as a prop.
 */
const DrugUtilizationReviewContainerWrapper = props => {
  const { providers: { task: durTaskFromProps } = {} } = props;
  const currentTab = useSelector(state => state.tabControl.patientTab);
  const tasks = useSelector(state => state.tasks.data);
  const patient = useSelector(state => state.patient);

  // Get the dur task from state
  const durTasks = tasks ? Object.values(tasks).filter(task => task.taskType === DUR) : [];
  const openDurs = durTasks.filter(({ status_id: statusId }) =>
    [
      DurStatus.Required,
      DurStatus.Generated,
      DurStatus.ReadyForReview,
      DurStatus.Intervening,
    ].includes(statusId),
  );

  let durTask;
  if (durTaskFromProps) {
    // Under task tab take, render the DUR task from the providers
    durTask = durTaskFromProps;
  } else {
    durTask = getCurrentDur(tasks);
  }

  const hasAllergies = useSelector(
    state =>
      state.allergies.allergiesList &&
      state.allergies.allergiesList.some(x => x.patient_id === patient.id && x.active),
  );
  const hasMedications = useSelector(
    state =>
      state.medications.medicationGroups &&
      state.medications.medicationGroups.some(x => x.patient_id === patient.id && x.active),
  );

  // Enable button only when there's no outstanding DUR, and allergies or medications are not empty.
  const generateButtonEnabled =
    openDurs && openDurs.length === 0 && (hasAllergies || hasMedications);

  return (
    <DrugUtilizationReviewContainer
      currentTab={currentTab}
      generateButtonEnabled={generateButtonEnabled}
      durTask={durTask}
      showHistoryIfNoDur
    />
  );
};

export default DrugUtilizationReviewContainerWrapper;

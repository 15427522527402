import React from 'react';
import { Modal } from '@mui/material';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { getModalStyle } from 'services/utils/styles-service';
import { useDispatch } from 'react-redux';
import { buildQaId } from 'utils/build-qa-id';
import { useStyles } from './add-pbm-insurance-modal.styles';
import AddPbmInsurance from '../../financials/pbm-insurances/add-pbm-insurance';
import { pbmModalActions } from '../../../../slices/pbm-modal-slice';

const getQaId = buildQaId('addPbmInsuranceModal');

export const AddPbmInsuranceModal: React.FC = (): JSX.Element => {
  const classes = useStyles();
  const open = useTypedSelector(state => state.pbmModal.open);
  const patientId = useTypedSelector(state => state.selectedPatientId);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(pbmModalActions.hideModal());
  };

  return (
    <Modal open={open} onBackdropClick={closeModal} data-qa-id={getQaId('modal')}>
      <div style={getModalStyle()} className={classes.modal}>
        <AddPbmInsurance patientId={patientId} cancel={closeModal} />
      </div>
    </Modal>
  );
};

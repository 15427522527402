import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import compose from 'recompose/compose';
import { convertToArborDate } from 'models/time/arbor-date';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { editIncome } from 'actions/action-financials';
import { accordianStyles } from 'components/accordian/accordian-styles';
import { combineStyles } from 'services/utils/styles-service';

import CopayCardDetail from '../../../tasks/fa/copay-card/copay-card-detail';
import PrescriptionAssistanceProgramDetail from '../../../tasks/fa/prescription-assistance-program/prescription-assistance-program-detail';
import FoundationDetail from '../../../tasks/fa/foundation/foundation-detail';

import { styles } from '../financials-styles';

import { FinancialAssistanceTypes } from '../../../../constants/enums';

class FinancialAssistanceTableDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderPanel() {
    const { financialAssistance, editItemMap, handleEditClose } = this.props;

    const type = financialAssistance.financial_assistance_type_category;

    switch (type) {
      case FinancialAssistanceTypes.CopayCard:
        return (
          <CopayCardDetail
            type={financialAssistance.financial_assistance_type_sub_category}
            financialAssistance={financialAssistance}
            bin={financialAssistance.bin}
            pcn={financialAssistance.pcn}
            group={financialAssistance.group_id}
            phoneNumber={financialAssistance.phone_number}
            expDate={convertToArborDate(financialAssistance.expiration_date).getCustomerDate(true)}
            instructions={financialAssistance.instructions}
            externalEdit
            editItemMap={editItemMap}
            handleEditClose={handleEditClose}
          />
        );
      case FinancialAssistanceTypes.Mpap:
      case FinancialAssistanceTypes.Pap:
        return (
          <PrescriptionAssistanceProgramDetail
            type={financialAssistance.financial_assistance_type_sub_category}
            programName={financialAssistance.program_name}
            financialAssistance={financialAssistance}
            group={financialAssistance.group_id}
            phoneNumber={financialAssistance.phone_number}
            expDate={convertToArborDate(financialAssistance.expiration_date, true).getCustomerDate(
              true,
            )}
            faxNumber={financialAssistance.fax_number}
            externalEdit
            editItemMap={editItemMap}
            handleEditClose={handleEditClose}
          />
        );
      case FinancialAssistanceTypes.Foundation:
      case FinancialAssistanceTypes.InternalFoundation:
      case FinancialAssistanceTypes.ExternalFoundation:
        return (
          <FoundationDetail
            financialAssistance={financialAssistance}
            type={financialAssistance.financial_assistance_type_sub_category}
            spendDown={financialAssistance.spend_down}
            group={financialAssistance.group_id}
            phoneNumber={financialAssistance.phone_number}
            expDate={convertToArborDate(financialAssistance.expiration_date, true).getCustomerDate(
              true,
            )}
            foundationProgram={financialAssistance.foundation_program}
            externalEdit
            editItemMap={editItemMap}
            handleEditClose={handleEditClose}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid item xs={12} className={classes.financialGroup}>
        {this.renderPanel()}
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      editIncome,
    },
    dispatch,
  );
}

function mapStateToProps(state) {
  const { financials } = state;

  const copayCardStatuses = financials?.financial_assistance_category_statuses.filter(
    f => f.category === FinancialAssistanceTypes.CopayCard,
  );

  const mpapStatuses = financials?.financial_assistance_category_statuses.filter(
    f => f.category === FinancialAssistanceTypes.Mpap,
  );

  return {
    financials,
    copayCardStatuses,
    mpapStatuses,
  };
}

const combinedStyles = combineStyles(styles, accordianStyles);

export default compose(
  withStyles(combinedStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(FinancialAssistanceTableDetail);

import { FETCH_LOOKUPS } from '../constants';

export default (state = {}, { type, payload }) => {
  switch (type) {
    case FETCH_LOOKUPS: {
      const { lookups } = payload.data;
      if (lookups) {
        const statuses = lookups.lookup_status_reason;
        const admin = statuses.filter(
          item => item.is_active === 1 && item.resource === 'therapy_administration',
        );
        const enroll = statuses.filter(
          item => item.is_active === 1 && item.resource === 'therapy_enrollment',
        );
        return {
          ...state,
          therapyAdministrationStatuses: admin,
          therapyEnrollmentStatuses: enroll,
        };
      }
      return state;
    }
    default:
      return state;
  }
};

import React from 'react';

import { useSelector } from 'react-redux';

import { parseOtherDisplay } from 'services/utils/common-service';

import { getResponsiblesString } from 'containers/tasks/incidents/utils';
import { qreIncidentSubtypesForIncidentAndComplaints } from 'constants/lists';
import QreDetails from './index';

export default props => {
  const { task } = props;

  const lookups = useSelector(state => state.lookups);
  const listOfPharmacies = useSelector(state => state.pharmacies?.pharmacies || []);

  const {
    CONSTANTS: {
      INCIDENT_REPORTING_PARTIES,
      INCIDENT_TYPES,
      MEDICATION_ERROR_TYPES,
      INCIDENT_CATEGORIES,
    },
    incidentTypes,
    incidentReportingParties,
    medicationErrorTypes,
    incidentCategories,
    serviceGroups,
    medicationErrorCategories,
  } = lookups;

  const isExternal = !task.is_internal;

  task.responsible_parties = getResponsiblesString(task.responsibles, isExternal);

  const incidentType = incidentTypes.find(type => type.id === task.type_id);

  task.type_display =
    task.type_id !== INCIDENT_TYPES.OTHER &&
    task.type_id !== INCIDENT_TYPES.BENEFITS_INVESTIGATION &&
    incidentType
      ? incidentType.name
      : parseOtherDisplay(incidentTypes, task.type_id, task.other_type);

  const medicationErrorType = medicationErrorTypes.find(
    type => type.id === task.medication_error_type_id,
  );

  task.medication_error_type_display =
    task.medication_error_type_id !== MEDICATION_ERROR_TYPES.OTHER && medicationErrorType
      ? medicationErrorType.name
      : parseOtherDisplay(task.other_medication_error_type);

  task.reporting_party_display =
    task.reporting_party_id !== INCIDENT_REPORTING_PARTIES.OTHER
      ? incidentReportingParties.find(rp => rp.id === task.reporting_party_id).name
      : parseOtherDisplay(task.other_reporting_party);

  const category = (incidentCategories || []).find(
    incidentCategory => incidentCategory.id === task.category_id,
  )?.name;

  const serviceGroup = (serviceGroups || []).find(
    sGroup => sGroup.id === task.service_group_id,
  )?.display_name; // eslint-disable-line camelcase

  const pharmacyName = listOfPharmacies.find(item => item.id === task.dispensing_pharmacy_id)?.name;
  const errorTypeName =
    task.is_medication_error !== null && task.is_medication_error !== undefined
      ? qreIncidentSubtypesForIncidentAndComplaints.find(
          it => parseInt(it.value, 10) === task.is_medication_error,
        )?.label
      : null;

  const medicationErrorCategoryName = medicationErrorCategories.find(item => item.id === task.medication_error_category_id)?.name ?? null;

  return (
    <QreDetails
      {...task}
      category={category}
      serviceGroup={serviceGroup}
      pharmacyName={pharmacyName}
      errorTypeName={errorTypeName}
      incidentCategories={INCIDENT_CATEGORIES}
      medicationErrorCategoryName={medicationErrorCategoryName}
    />
  );
};

import React from 'react';
import moment from 'moment';
import { OpenInNew } from '@mui/icons-material';
import { useTypedSelector } from 'hooks/use-typed-selector';

export const FCSSQuickLink = ({ noteText }: any) => {
  const patient_dob = useTypedSelector(state => state.patient.dob);
  if (typeof patient_dob !== 'string') {
    return null;
  }
  const patient_dob_query_string = moment(patient_dob.split('T')[0]).format('MM-DD-YYYY');
  let has_fc_ss_link = false;
  const fc_link_regex_string = `${window.location.origin}/refills/[a-zA-Z0-9]{6,}`;
  const fc_link_regex = new RegExp(fc_link_regex_string);
  const match = noteText.match(fc_link_regex);
  if (match && match.length && match.length > 0) {
    has_fc_ss_link = true;
  }
  return has_fc_ss_link ? (
    <a href={`${match[0]}/?dob=${patient_dob_query_string}`} target="_blank" rel="noreferrer">
      <OpenInNew style={{ height: '20px' }} />
    </a>
  ) : null;
};

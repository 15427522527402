export enum BenefitInvestigationStatus {
  Paid = 'P',
  Rejected = 'R',
  Accepted = 'A',
  Captured = 'C',
}

export type RejectionError = { code?: string; message: string };

export interface IBenefitInvestigationLatest {
  b1Copay?: string;
  b1PatientPay?: string;
  b1RejectionMessages?: RejectionError[];
  b1Status?: BenefitInvestigationStatus;
  b2Status?: BenefitInvestigationStatus;
  created: string;
  daysSupply?: number;
  errorCodeMessages?: Record<string, string>;
  id: number;
  quantity?: number;
  therapyId: number;
}

// The key here is a therapy id. Can't use type alias or this would be `key: TherapyId` :(
export type BenefitInvestigationLatestMap = { [key: number]: IBenefitInvestigationLatest };

export interface ITherapyBenefitsInvestigationLatest {
  loading: boolean;
  lastLoadFailed: boolean;
  data: BenefitInvestigationLatestMap;
}

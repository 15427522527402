import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  left: {
    paddingRight: theme.spacing(1),
  },
}));

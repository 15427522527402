export const styles = theme => ({
  filterPanelContainer: {
    overflow: 'visible',
    width: '99.5%',
    marginLeft: 10,
  },
  serviceGroupContainer: {
    width: 'calc(99.5% - 10px)',
    paddingBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(1),
  },
  noFiltersContainer: {
    paddingTop: theme.spacing(5),
    textAlign: 'center',
  },
  faCheckbox: {
    marginLeft: 0,
  },
});

import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

export const styles = (theme: Theme) => {
  const themeAny = theme as any;
  const colorPalette = themeAny.palette.primary as any;
  const fontTheme = themeAny.font as any;

  return createStyles({
    fieldLabelLarge: {
      fontSize: fontTheme.largeFontSize,
      fontWeight: fontTheme.weight.thick,
      color: theme.palette.text.primary,
      marginRight: 10,
    },
    outerContainer: {
      backgroundColor: colorPalette.grey14,
      padding: 20,
    },
    labHistoryItemDate: {
      marginRight: 10,
      display: 'inline',
    },
    value: {
      display: 'inline',
      fontWeight: fontTheme.weight.thick,
    },
    redLabel: {
      color: colorPalette.valenciaRed,
      fontWeight: fontTheme.weight.thick,
      display: 'inline',
    },
    viewListIcon: {
      color: colorPalette.rollingStone,
      paddingLeft: theme.spacing(0.7),
    },
  });
};

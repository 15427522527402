import React, { useEffect, useState } from 'react';
import {
  IPatientMergeSectionDetailsProps,
  IPatientProfile,
} from 'components/patient-merge-modal/interfaces';
import { Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector } from 'react-redux';
import { IState } from 'interfaces/redux/IState';
import { useTheme } from '@mui/styles';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';

export const MrnSection = (props: IPatientMergeSectionDetailsProps) => {
  const { profiles } = props;
  const [primaryProfile, setPrimaryProfile] = useState<IPatientProfile | undefined>();
  const [selectedProfile, setSelectedProfile] = useState<IPatientProfile | undefined>();

  const theme = useTheme();
  const classes = PatientMergeStyles(theme);

  const primary = useSelector((state: IState) => state.patientMerge.primary);
  const selectedProfileId = useSelector((state: IState) => state.patientMerge.duplicate);

  useEffect(() => {
    setPrimaryProfile(profiles.find(profile => profile.patientId === primary));
  }, [primary]);

  useEffect(() => {
    setSelectedProfile(profiles.find(profile => profile.patientId === selectedProfileId));
  }, [selectedProfileId]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {primaryProfile && (
          <Typography>
            <CheckCircleIcon sx={classes.checkIcon} />
            <b>{primaryProfile?.sourcePatientId}</b> (<i>{primaryProfile?.source.name}</i>)
          </Typography>
        )}
        {selectedProfile && (
          <Typography>
            <CheckCircleIcon sx={classes.checkIcon} />
            <b>{selectedProfile?.sourcePatientId}</b> (<i>{selectedProfile?.source.name}</i>)
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

import { AxiosPromise } from 'axios';
import { TypedHttp } from 'services/typed-http';
import { IMedSyncRequest } from 'models/meds/IMedSync';
import { IPatient } from 'interfaces/redux/IPatient';

export class MedSyncClient {
  static updateMedSync = (medSyncData: IMedSyncRequest): AxiosPromise<IMedSyncRequest> => {
    const baseUrl = `/patients/${medSyncData.patientId}/med-sync`;
    const payload = {
      status: medSyncData.status,
      anchorDate: medSyncData.anchorDate,
      timeFrame: medSyncData.syncTimeFrame,
      therapies: medSyncData.therapiesId,
      adherencePackMapping: medSyncData.adherencePackMapping,
    };
    return TypedHttp.PatchPromise(baseUrl, payload);
  };

  static getPatientData = (patientId: number): AxiosPromise<any> => {
    const baseUrl = `/patients/${patientId}`;
    return TypedHttp.GetPromise(baseUrl);
  };
}

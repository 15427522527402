import React from 'react';
import BlueContainer from '../components/blue-container';
import PoweredBy from '../components/powered-by';
import Paragraph from '../components/paragraph';

export default () => (
  <BlueContainer>
    <Paragraph type="title">The survey has already been completed.</Paragraph>
    <hr />
    <Paragraph type="subTitle">Thank you for your participation!</Paragraph>
    <PoweredBy />
  </BlueContainer>
);

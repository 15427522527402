import React, { useState, useEffect, useRef } from 'react';
import { Grid, TextField, InputAdornment, Box, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FilterIcon from '@mui/icons-material/FilterList';
import { useDebouncedCallback } from 'use-debounce';
import { isEmpty } from 'lodash';

import RadioGroup from 'components/form/radio/radio-group';
import RadioButton from 'components/form/radio/radio-button';
import VisibilityPopup from '../../../components/invoicing/popups/visibility/visibility';
import AllFilterPopup from '../../../components/invoicing/popups/all-filter/all-filter';

import useStyles from './styles';

export default function TopSection(props) {
  const {
    sources,
    onSearch,
    onChangeSource,
    columnList,
    onRemoveFilter,
    visibleColumns,
    onVisibleColumnsChange,
    hiddenColumns,
    columnFilter,
    onColumnFilterChange,
    onPreferencesChange,
  } = props;

  const [searchTerm, setSearchTerm] = useState();
  const [sourceValue, setSourceValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState();
  const [anchorAllFilterPopup, setAnchorAllFilterPopup] = useState();

  const divRef = useRef();
  const divRefFilter = useRef();
  const { rightSpacing, invoicingTopIconButton } = useStyles();

  const [debouncedCallback] = useDebouncedCallback(value => {
    onSearch(value);
  }, 300);

  useEffect(() => {
    debouncedCallback(searchTerm);
  }, [searchTerm]);

  const handleOnChangeSources = value => {
    setSourceValue(value);
    onChangeSource(sources[value]);
    onColumnFilterChange({});
  };

  const handleShowVisibility = () => {
    setAnchorEl(divRef.current);
  };

  const handleShowAllFilterPopup = () => {
    setAnchorAllFilterPopup(divRefFilter.current);
  };

  const handleOnCloseVisibility = () => {
    setAnchorEl(null);
  };

  const handleOnCloseAllFilterPopup = () => {
    setAnchorAllFilterPopup(null);
  };
  const open = Boolean(anchorEl);
  const openAllFilterPopup = Boolean(anchorAllFilterPopup);
  return (
    <Grid direction="column">
      <Box pl="1rem" pr="1rem">
        <Box pt={3} />
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box display="flex" alignItems="center">
            {sources && (
              <>
                <RadioGroup value={sourceValue} className={rightSpacing} horizontal>
                  {sources.map((item, i) => (
                    <RadioButton
                      key={item.source}
                      maxItemWidth="100px"
                      onChange={handleOnChangeSources}
                      radius={3}
                      value={i}
                    >
                      {`${item.label} (${item.count})`}
                    </RadioButton>
                  ))}
                </RadioGroup>
              </>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            <TextField
              variant="standard"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box display="flex" alignItems="center" flex={1} justifyContent="flex-end">
            {!isEmpty(columnFilter) && (
              <Box mr={1}>
                <Button
                  className={invoicingTopIconButton}
                  ref={divRefFilter}
                  variant="outlined"
                  color="primary"
                  onClick={handleShowAllFilterPopup}
                  startIcon={<FilterIcon />}
                />
              </Box>
            )}
            <Button
              className={invoicingTopIconButton}
              ref={divRef}
              variant="outlined"
              color="primary"
              onClick={handleShowVisibility}
              startIcon={<VisibilityIcon />}
            />
          </Box>
        </Box>
        <>
          {columnList && (
            <VisibilityPopup
              columnList={columnList}
              onPreferencesChange={onPreferencesChange}
              visibleColumns={visibleColumns}
              hiddenColumns={hiddenColumns}
              onVisibleColumnsChange={onVisibleColumnsChange}
              anchorEl={anchorEl}
              onClick={e => {
                e.stopPropagation();
              }}
              onClose={handleOnCloseVisibility}
              open={open}
              id="visibility-popover"
            />
          )}
        </>
        {!isEmpty(columnFilter) && (
          <AllFilterPopup
            columnFilter={columnFilter}
            onColumnFilterChange={onColumnFilterChange}
            columnList={columnList}
            onRemoveFilter={onRemoveFilter}
            anchorEl={anchorAllFilterPopup}
            onClick={e => {
              e.stopPropagation();
            }}
            onClose={handleOnCloseAllFilterPopup}
            open={openAllFilterPopup}
            id="all-filter-popover"
          />
        )}
      </Box>
    </Grid>
  );
}

import { IAddPhoneModal } from 'interfaces/redux/IAddPhoneModal';

export enum AddPhoneModalActionType {
  SetState = 'ADD_PHONE_MODAL_STATE',
  ResetModal = 'ADD_PHONE_MODAL_RESET',
}

export interface IAddPhoneModalAction {
  type: AddPhoneModalActionType;
  payload: IAddPhoneModal;
}

export const addPhoneModalActions = {
  setState: (state: IAddPhoneModal): IAddPhoneModalAction => {
    return {
      type: AddPhoneModalActionType.SetState,
      payload: state,
    };
  },
  resetModal: () => {
    return {
      type: AddPhoneModalActionType.ResetModal,
    };
  },
};

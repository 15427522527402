import { convertToArborDate } from 'models/time/arbor-date';
import { getUserById } from 'services/utils/users-service';
import { ICounselingTask } from 'interfaces/redux/task-types/ICounselingTask';
import { IState } from 'interfaces/redux/IState';
import { orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import { IDataCollectTask, ICollectedItem } from 'interfaces/redux/task-types/IDataCollectTask';
import { IDcSectionData } from '../chart-review/interfaces/IDcSection';
import { IDataCollectFetchFilters } from '../interfaces/IDataCollectFetchFilters';
import { useLatestDcs } from './use-latest-dcs';

const usePatientReviewDcs = (
  taskQty: number,
  currentSelectedTasks: ICounselingTask[],
  filters?: IDataCollectFetchFilters,
): IDcSectionData[] => {
  const therapies = useSelector((state: IState) => state.therapies);
  const users = useSelector((state: IState) => state.lookups.users);
  const parsedItemsDcTasks = useLatestDcs(taskQty, currentSelectedTasks, filters);
  const parsedTherapiesWithDcsSet = new Set(
    parsedItemsDcTasks.map(t => t.therapy_id || t.therapyInitialId),
  );
  // @ts-ignore
  const uniqueTherapiesWithDcs = filters ? [...parsedTherapiesWithDcsSet] : [];

  // Loop through current selected tasks to extract collect
  const retArr = filters
    ? uniqueTherapiesWithDcs.map(t => ({ therapy_id: t }))
    : currentSelectedTasks;
  return retArr.map((currentTask: any) => {
    const therapyDrugName = therapies.data[currentTask.therapy_id]?.drug_name ?? '-';

    let sortedByCreatedTasks = parsedItemsDcTasks;
    // Get DCs task  filtering by DC type and therapy ID of current CLS task
    sortedByCreatedTasks = parsedItemsDcTasks.filter(
      parsedDcTask =>
        currentTask.therapy_id === parsedDcTask.therapy_id ||
        (filters?.includeRS && currentTask.therapy_id === parsedDcTask.therapyInitialId),
    );

    const [lastCompletedTask] = sortedByCreatedTasks.sort(
      (firstTask, secondTask) =>
        new Date(secondTask?.completed_dt ?? '')?.getTime() -
        new Date(firstTask?.completed_dt ?? '')?.getTime(),
    );

    const [lastReviewedTask] = sortedByCreatedTasks.sort(
      (firstTask, secondTask) =>
        new Date(secondTask?.reviewed_dt ?? '')?.getTime() -
        new Date(firstTask?.reviewed_dt ?? '')?.getTime(),
    );

    const [lastUpdatedTask] = sortedByCreatedTasks.sort(
      (firstTask, secondTask) =>
        new Date(secondTask?.updated ?? '')?.getTime() -
        new Date(firstTask?.updated ?? '')?.getTime(),
    );

    const lastCompletedDate = lastCompletedTask?.completed_dt
      ? convertToArborDate(lastCompletedTask.completed_dt, true).getCustomerDate(true) || ''
      : '';

    const lastReviewedDate = lastReviewedTask?.reviewed_dt
      ? convertToArborDate(lastReviewedTask.reviewed_dt, true).getCustomerDatetime(true) || ''
      : '';

    const lastUpdatedDate = lastUpdatedTask?.updated
      ? convertToArborDate(lastUpdatedTask.updated, true).getCustomerDatetime(true) || ''
      : '';

    const lastUpdatedUser = getUserById(lastUpdatedTask?.updated_by, users)?.username ?? '';

    const collectedItems = sortedByCreatedTasks.reduce<ICollectedItem[]>(
      (accum: ICollectedItem[], task: IDataCollectTask) => {
        return [...accum, ...(task?.collectedItems || [])];
      },
      [],
    );
    return {
      task: {
        ...currentTask,
        id: sortedByCreatedTasks && sortedByCreatedTasks[0] ? sortedByCreatedTasks[0].id : null,
      },
      therapyDrugName,
      collectedItems: orderBy(
        collectedItems,
        ['dataTypeName', 'assessmentDateAsDate'],
        ['asc', 'asc'],
      ),
      lastCompletedDate,
      lastUpdatedDate,
      lastUpdatedUser,
      lastVerifiedDate: lastReviewedDate,
    };
  });
};

export { usePatientReviewDcs };

import { Accordion, AccordionDetails, Modal, AccordionSummary, Grid, Divider } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classNames from 'classnames';
import { accordianStyles } from 'components/accordian/accordian-styles';
import Dropdown from 'components/form/field/dropdown';
import DetailField from 'components/form/field/field';
import AccordionHeader from 'components/form/header/accordion-header';
import Communication from 'components/note/communication';
import NoteDisplay from 'components/note/note-display';
import { SmallSpacer } from 'components/spacer/spacer';
import { TASK_OUTREACH, AR } from 'constants/index';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Anchor from 'components/anchor/anchor';
import Incident from 'components/note/incident';
import compose from 'recompose/compose';
import { convertToArborDate } from 'models/time/arbor-date';
import { combineStyles, getModalStyle } from 'services/utils/styles-service';
import { getStatusByStatusId, stopPropagation } from 'services/utils/task-service';
import { getUserById } from 'services/utils/users-service';
import { getDisplayNotes, getNoteTagId } from 'services/utils/note-service';
import arProvider from 'components/dynamic-form/providers/ar-provider';
import { TaskStatusUtil } from 'utils/task-status-util';
import History from 'containers/common/history';
import EditScheduleOutreach from './edit-schedule-outreach';
import { styles } from './schedule-outreach-details-styles';
import ScheduleOutreachTherapies from './schedule-outreach-therapies';
import ScheduleOutreachStatus from './status-schedule-outreach';

class ScheduleOutreachDetails extends Component {
  constructor(props) {
    super(props);
    const { expanded } = this.props;
    this.state = {
      displayDetail: expanded,
      displayEdit: false,
      displayEditHistory: false,
      selectedStatus: props.scheduleOutreach.status
        ? props.scheduleOutreach.status
        : getStatusByStatusId(props.scheduleOutreach.status_id, props.taskStatuses),
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.resetTaskStatus = this.resetTaskStatus.bind(this);
    this.setTaskStatus = this.setTaskStatus.bind(this);
    this.handleTaskStatus = this.handleTaskStatus.bind(this);
    this.handleEditHistory = this.handleEditHistory.bind(this);
  }

  // If user switch AR under the same patient, the expanded panel should change
  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { expanded, currentUser, resourceStates } = this.props;
    if (nextProps.expanded !== expanded) {
      this.setState({
        displayDetail: nextProps.expanded,
      });
    }

    if (nextProps.currentUser !== currentUser || nextProps.resourceStates !== resourceStates) {
      this.canTransition = TaskStatusUtil.buildCanTransitionFromPermissions(
        currentUser?.permissions,
        (resourceStates || []).filter(resourceState => resourceState.resource_name === AR),
      );
    }
  }

  setTaskStatus(status) {
    this.setState({
      selectedStatus: status,
    });
  }

  handleTaskStatus(e) {
    this.setTaskStatus(e.target.value);
  }

  handleClick(e) {
    if (e) {
      this.setState(prevState => ({
        displayDetail: !prevState.displayDetail,
      }));
    }
  }

  handleEdit(e) {
    if (e) {
      e.stopPropagation();
    }
    this.setState(prevState => ({
      displayEdit: !prevState.displayEdit,
    }));
  }

  resetTaskStatus() {
    const { scheduleOutreach, taskStatuses } = this.props;
    this.setState({
      selectedStatus: getStatusByStatusId(scheduleOutreach.status_id, taskStatuses),
    });
  }

  handleEditHistory(e) {
    if (e) e.stopPropagation();
    this.setState(prevState => ({
      displayEditHistory: !prevState.displayEditHistory,
    }));
  }

  render() {
    const {
      classes,
      patient,
      scheduleOutreach,
      users,
      serviceGroups,
      taskStatuses,
      allPinnedNotes,
      allNonPinnedNotes,
      outreachCreatedReasons,
    } = this.props;

    const { displayDetail, displayEdit, displayEditHistory, selectedStatus } = this.state;
    const {
      id: soId,
      status_id: statusId,
      communication_type: communicationType,
      service_group_name: serviceGroupName,
      followup_dt: followupDt,
      last_checked_dt: lastCheckedDt,
      source_facility_id: sourceFacilityId,
      clinician_name: clinicianName,
      reason,
      additional_reason: additionalReason,
      updated,
      scheduled_date: scheduledDate,
      will_not_meet_with_patient_date: willNOtMeetWithPatientDate,
      completed_date: completedDate,
      updated_by: updatedBy,
      reason_id: createdReasonId,
      reason_description: createdReasonDescription,
    } = scheduleOutreach;

    const createdReason = outreachCreatedReasons.find(r => r.id === createdReasonId)?.name;
    const updatedUser = getUserById(updatedBy, users);
    const statusObj = taskStatuses && taskStatuses.find(e => e.id === statusId);
    const statusStr = statusObj && statusObj.status;
    let statusReason = statusObj && statusObj.reason;
    if (additionalReason) {
      statusReason += ` (${additionalReason})`;
    }
    scheduleOutreach.status = statusStr;
    const tagTypeId = getNoteTagId('AR');
    const displayNotes = getDisplayNotes(
      allPinnedNotes,
      allNonPinnedNotes,
      tagTypeId,
      soId,
      users,
      serviceGroups,
      2,
    );

    const tags = [];
    if (communicationType) {
      tags.push(
        <Typography
          component="span"
          key="communication_type_tag"
          className={classNames(classes.communicationTypeContainer, classes.emergency)}
        >
          {communicationType}
        </Typography>,
      );
    }

    const { statusNameIdsLookup, uniqStatusNames } = taskStatuses.reduce(
      (acc, statusData) => {
        if (statusData.status !== 'Canceled' && statusData.status.indexOf('migration') === -1) {
          acc.statusNameIdsLookup[statusData.status] = statusData.id;
          acc.uniqStatusNames.add(statusData.status);
        }
        return acc;
      },
      {
        statusNameIdsLookup: {},
        uniqStatusNames: new Set(),
      },
    );

    const statusArrForSelect = Array.from(uniqStatusNames).map(status => ({
      key: status,
      value: status,
    }));

    if (statusArrForSelect) {
      const arTaskProvider = arProvider(scheduleOutreach, this.state);
      // Set disabled field on the status options
      statusArrForSelect.forEach(statusOption => {
        statusOption.disabled =
          arTaskProvider.customStatusDisabled(statusOption.key) ||
          (this.canTransition &&
            !this.canTransition(scheduleOutreach, statusNameIdsLookup[statusOption.key]));
      }, this);
    }

    return (
      <Anchor id={`APPOINTMENT_REFERRAL_${scheduleOutreach.id}`}>
        <Accordion
          className={classNames(classes.accordianPanel, classes.soPanel)}
          expanded={!!displayDetail}
          id="therapy-"
        >
          <AccordionSummary
            className={classes.scheduleOutreachSummary}
            classes={{
              expandIconWrapper: displayDetail
                ? classes.expandIconExpanded
                : classes.expandIconColapsed,
              focused: classes.focused,
            }}
            onClick={this.handleClick}
            expandIcon={<ExpandMoreIcon />}
          >
            <Grid container className={classes.summaryContainer}>
              <Grid item xs={12}>
                <AccordionHeader
                  name="Appointment Referral"
                  editHandler={this.handleEdit}
                  updatedDt={updated}
                  updatedBy={updatedUser.username}
                  id={`schedule_outreach_information_${scheduleOutreach.id}`}
                  tags={tags}
                  withHistory
                  historyHandler={this.handleEditHistory}
                />
                <Modal open={displayEditHistory} onClose={this.handleEditHistory}>
                  <div style={getModalStyle()} className={classes.Modal}>
                    <History url={`/tasks/AR/${scheduleOutreach.id}`} />
                  </div>
                </Modal>
              </Grid>
              {displayEdit && (
                <Grid container className={classes.scheduleOutreachDetails}>
                  <EditScheduleOutreach
                    statuses={taskStatuses}
                    scheduleOutreach={scheduleOutreach}
                    serviceGroups={serviceGroups}
                    cancel={this.handleEdit}
                  />
                </Grid>
              )}
            </Grid>
          </AccordionSummary>
          {!displayEdit && (
            <AccordionDetails className={classes.detailContainer}>
              <Grid container spacing={7}>
                <Grid item xs={8}>
                  <Grid container spacing={7}>
                    <Grid item xs={3}>
                      <DetailField fieldName="Service Group" field={serviceGroupName} />
                    </Grid>
                    <Grid item xs={3}>
                      <DetailField
                        fieldName="Follow Up Date"
                        field={followupDt ? convertToArborDate(followupDt, true).getUtcDate() : '-'}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <DetailField fieldName="Office Visit" field={sourceFacilityId} />
                    </Grid>
                    <Grid item xs={3}>
                      <DetailField fieldName="Provider" field={clinicianName} />
                    </Grid>
                    <Grid item xs={3}>
                      <DetailField fieldName="Reason" field={reason} />
                    </Grid>
                    <Grid item xs={3}>
                      <DetailField
                        fieldName="Last Check Date"
                        field={convertToArborDate(lastCheckedDt, true).getCustomerDate(true)}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <DetailField
                        fieldName="Scheduled Date"
                        field={
                          scheduledDate ? convertToArborDate(scheduledDate, true).getUtcDate() : '-'
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <DetailField
                        fieldName="Will not meet with patient Date"
                        field={
                          willNOtMeetWithPatientDate
                            ? convertToArborDate(willNOtMeetWithPatientDate, true).getUtcDate()
                            : '-'
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <DetailField
                        fieldName="Completed Date"
                        field={
                          completedDate ? convertToArborDate(completedDate, true).getUtcDate() : '-'
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <DetailField fieldName="Created reason" field={createdReason} />
                    </Grid>
                    <Grid item xs={3}>
                      <DetailField
                        fieldName="Created description"
                        field={createdReasonDescription}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container className={classes.note}>
                    <NoteDisplay
                      patientId={patient.id}
                      tagName="AR"
                      tagTypeId={tagTypeId}
                      tagResourceId={soId}
                      notes={displayNotes}
                      containerClasses={classes}
                      isInContext
                    />
                  </Grid>
                  <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                      <Communication
                        tagTypeId={tagTypeId}
                        tagResourceId={soId}
                        task={scheduleOutreach}
                      />
                    </Grid>
                    <Grid item>
                      <Incident
                        tagTypeId={tagTypeId}
                        tagResourceId={soId}
                        serviceGroups={serviceGroups}
                        taskType={TASK_OUTREACH}
                        isPatient
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Dropdown
                    label="Status"
                    state={selectedStatus}
                    handler={this.handleTaskStatus}
                    onClick={stopPropagation}
                    fields={statusArrForSelect}
                    name="schedule_outreach_status"
                    width="100%"
                  />
                </Grid>
                {statusReason ? (
                  <Grid item xs={8}>
                    <DetailField fieldName="Reason" field={statusReason} />
                  </Grid>
                ) : (
                  <Grid item xs={4} />
                )}
                {!displayEdit &&
                  selectedStatus !== getStatusByStatusId(statusId, taskStatuses) &&
                  statusId &&
                  taskStatuses && (
                    <Grid
                      item
                      xs={12}
                      className={
                        selectedStatus !== scheduleOutreach.status ? classes.statusContainer : null
                      }
                    >
                      <SmallSpacer />
                      <ScheduleOutreachStatus
                        selectedStatus={selectedStatus}
                        therapyStatusName={selectedStatus}
                        task={scheduleOutreach}
                        tagTypeId={tagTypeId}
                        tagResourceId={scheduleOutreach.id}
                        statuses={taskStatuses}
                        cancel={this.resetTaskStatus}
                      />
                    </Grid>
                  )}
              </Grid>
              <Divider className={classes.divider} />
              <Grid container>
                <Switch>
                  <Route
                    render={componentProps => (
                      <ScheduleOutreachTherapies
                        {...componentProps}
                        scheduleOutreach={scheduleOutreach}
                        setTaskStatus={this.setTaskStatus}
                      />
                    )}
                  />
                </Switch>
              </Grid>
            </AccordionDetails>
          )}
        </Accordion>
      </Anchor>
    );
  }
}

const combinedStyles = combineStyles(styles, accordianStyles);

const mapStateToProps = state => {
  const { taskStatuses, notes, patient, auth, lookups } = state;
  return {
    patient,
    notes: notes && notes.notes ? notes.notes.notesToDisplay : [],
    allPinnedNotes: notes && notes.notes ? notes.notes.allPinnedNotes : [],
    allNonPinnedNotes: notes && notes.notes ? notes.notes.allNonPinnedNotes : [],
    taskStatuses: taskStatuses.statuses.ar || [],
    outreachCreatedReasons: lookups.lookupScheduleOutreachReasons,
    users: state.lookups.users,
    serviceGroups: state.lookups.serviceGroups,
    currentUser: auth?.currentUser,
    resourceStates: lookups?.resourceStates,
  };
};

export default compose(
  withStyles(combinedStyles, { withTheme: true }),
  connect(mapStateToProps, null),
)(ScheduleOutreachDetails);

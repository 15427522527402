import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Loader } from 'components/loading-overlay/loading-overlay';
// eslint-disable-next-line no-unused-vars
import { RX_TYPES } from 'constants/index';
import { getInvoicingSummaryAdjustments } from 'services/utils/invoicing-service';
import moment from 'moment';
import DateTimePicker from 'components/form/datepicker/datetime-picker';
import { notifyError } from 'actions/action-notifications';
import { useDispatch } from 'react-redux';
import SummaryCategoryRow from './summary-category-row';
import useStyles from './styles';

const allType = { value: '.*', label: 'All' };

const getPrevMonthMoment = num => moment().utc().subtract(num, 'month').startOf('month');

const InvoicingSummaryAdjustments = () => {
  const { invoicingSummaryStyledTableCell, invoicingStyledLoadContainer } = useStyles();
  const [summaryData, setSummaryData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [fromMonth, setFromMonth] = useState(getPrevMonthMoment(3));
  const [toMonth, setToMonth] = useState(getPrevMonthMoment(1));
  const [clinicTypes] = useState([allType]);
  const [pharmacyTypes] = useState([allType]);
  const [clinicFilters] = useState([...clinicTypes]);
  const [pharmacyFilters] = useState([...pharmacyTypes]);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoaded(false);
    const filters = {
      fromDate: fromMonth.format('yyyy-MM'),
      toDate: toMonth.format('yyyy-MM'),
      clinics: Array.isArray(clinicFilters) ? clinicFilters.map(f => f.value) : [],
      pharmacies: Array.isArray(pharmacyFilters) ? pharmacyFilters.map(f => f.value) : [],
    };
    getInvoicingSummaryAdjustments(filters)
      .then(({ data: { summary } }) => {
        setSummaryData(summary);
        setIsLoaded(true);
      })
      .catch(err => {
        dispatch(notifyError(err.message));
        setIsLoaded(true);
      });
  }, [fromMonth, toMonth, clinicFilters, pharmacyFilters]);

  const periodList = [];
  for (let m = moment(fromMonth); m.diff(toMonth) <= 0; m.add(1, 'month')) {
    periodList.unshift(m.format('MMMM'));
  }

  const renderPeriodColumns = classComponent => (
    <>
      <TableCell className={classComponent} align="center">
        <Typography variant="subtitle2">Value</Typography>
      </TableCell>
      <TableCell className={classComponent} align="center">
        <Typography variant="subtitle2">MoM</Typography>
      </TableCell>
      <TableCell className={classComponent} align="center">
        <Typography variant="subtitle2">MoM %</Typography>
      </TableCell>
    </>
  );

  return (
    <>
      <Box pt={2} />
      <Typography variant="h6">Adjustments</Typography>
      <Box pt={2} />
      <Grid container>
        <Grid item xs={1}>
          <Typography variant="caption" component="div">
            From
          </Typography>
          <DateTimePicker
            id="from_month"
            showMonthYearPicker
            minDate={new Date(moment().subtract(1, 'year'))}
            maxDate={new Date(moment(toMonth.toDate()).add(1, 'month'))}
            value={fromMonth}
            onChange={e => setFromMonth(e)}
            dateFormat="yyyy/MM"
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1}>
          <Typography variant="caption" component="div">
            To
          </Typography>
          <DateTimePicker
            id="to_month"
            showMonthYearPicker
            minDate={new Date(moment(fromMonth))}
            maxDate={new Date(moment().subtract(1, 'month'))}
            value={toMonth}
            onChange={e => setToMonth(e)}
            dateFormat="yyyy/MM"
          />
        </Grid>
      </Grid>
      <Box pt={2} />
      <Grid className={invoicingStyledLoadContainer}>
        <Loader loaded={isLoaded}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="invoicing summary adjustments table">
              <TableHead>
                <TableRow>
                  <TableCell className={invoicingSummaryStyledTableCell} />
                  {periodList.map(month => (
                    <TableCell
                      className={invoicingSummaryStyledTableCell}
                      align="center"
                      colspan="3"
                    >
                      <Typography variant="subtitle2">{month}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell className={invoicingSummaryStyledTableCell} align="center">
                    <Typography variant="subtitle2">Category</Typography>
                  </TableCell>
                  {periodList.map(() => renderPeriodColumns(invoicingSummaryStyledTableCell))}
                </TableRow>
              </TableHead>
              <TableBody>
                {summaryData.map(category => (
                  <SummaryCategoryRow category={category} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Loader>
      </Grid>
    </>
  );
};

export default InvoicingSummaryAdjustments;

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { grey } from '@mui/material/colors';

export const styles = (theme: Theme) => {
  const colorPalette = theme.palette.primary as any;

  return createStyles({
    gridRow: {
      paddingBottom: 15,
    },
    overflowX: {
      overflowX: 'scroll',
    },
    textBox: {
      backgroundColor: grey[200],
      padding: 10,
    },
    label: {
      fontSize: 12,
      lineHeight: '15px',
      color: colorPalette.grey12,
      paddingBottom: 6,
    },
  });
};

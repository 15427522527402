import React from 'react';
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import { ITherapy } from 'interfaces/redux/ITherapy';
import { useTypedSelector } from 'hooks/use-typed-selector';
import {
  BenefitInvestigationStatus,
  IBenefitInvestigationLatest,
} from 'interfaces/redux/ITherapyBenefitsInvestigationLatest';
import { convertToArborDate } from 'models/time/arbor-date';
import cx from 'classnames';
import { useStyles } from './styles';
import { Title } from './title';
import { RunBiRightSide } from './run-bi-right-side';

interface IProps {
  therapy: ITherapy;
  taskId?: number;
  shouldFetchPatientBi?: boolean;
}

export const BiForTherapy: React.FC<IProps> = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const latestBis = useTypedSelector(state => state.therapyBenefitsInvestigationLatest.data);

  const latestBi = React.useMemo<IBenefitInvestigationLatest | undefined>(() => {
    return latestBis[props.therapy.id];
  }, [props.therapy, latestBis]);

  const renderSeperator = (): JSX.Element => {
    return <Typography display="inline"> | </Typography>;
  };

  const renderValue = (value: string | number | undefined): JSX.Element => {
    return (
      <Typography display="inline" className={cx(classes.typography)}>
        {value || '-'}
      </Typography>
    );
  };

  const renderLabel = (value: string): JSX.Element => {
    return (
      <Typography display="inline" className={cx(classes.thick, classes.typography)}>
        {`${value} `}
      </Typography>
    );
  };

  const renderLatestDate = (): JSX.Element => {
    let resultStr = '';

    if (latestBi && latestBi.created) {
      resultStr += convertToArborDate(latestBi.created, true).getCustomerDate();
    }

    resultStr += ' - ';

    return (
      <Typography display="inline" className={cx(classes.thick, classes.typography)}>
        {resultStr}
      </Typography>
    );
  };

  const renderBiStatus = (): JSX.Element | null => {
    if (latestBi && latestBi.b1Status) {
      // eslint-disable-next-line default-case
      switch (latestBi.b1Status) {
        case BenefitInvestigationStatus.Paid: {
          return (
            <Typography
              className={cx(classes.approved, classes.thick, classes.typography)}
              display="inline"
            >
              Approved
            </Typography>
          );
        }
        case BenefitInvestigationStatus.Rejected: {
          return (
            <Typography
              className={cx(classes.rejected, classes.thick, classes.typography)}
              display="inline"
            >
              Rejected
            </Typography>
          );
        }
      }
    }

    return null;
  };

  const renderBiApprovedDetails = (): JSX.Element | null => {
    return (
      <>
        {renderLabel('Patient Pay:')}
        {renderValue(latestBi?.b1PatientPay)}
        {renderSeperator()}
        {renderLabel('Quantity:')}
        {renderValue(latestBi?.quantity)}
        {renderSeperator()}
        {renderLabel('Day Supply:')}
        {renderValue(latestBi?.daysSupply)}
      </>
    );
  };

  const renderBiRejectedDetails = (): JSX.Element | null => {
    if (latestBi) {
      const rejectionMessages = latestBi?.b1RejectionMessages
        ? latestBi.b1RejectionMessages.filter(rj => rj.message)
        : undefined;
      return (
        <>
          {rejectionMessages &&
            rejectionMessages.map<JSX.Element>((rj, index) => {
              const last = index + 1 === rejectionMessages.length;
              return (
                <Typography
                  display="inline"
                  className={cx(classes.typography, classes.rejected, classes.thick)}
                >
                  {`${rj.message} ${!last ? '| ' : ''}`}
                </Typography>
              );
            })}
          {latestBi.errorCodeMessages &&
            Object.entries(latestBi.errorCodeMessages).map<JSX.Element | null>(([key, value]) => {
              return (
                <Tooltip title={value} key={key}>
                  <Button className={classes.errorButton} variant="outlined" size="small">
                    {key}
                  </Button>
                </Tooltip>
              );
            })}
        </>
      );
    }
    return null;
  };

  const renderBiDetails = (): JSX.Element | null => {
    if (latestBi && latestBi.b1Status) {
      // eslint-disable-next-line default-case
      switch (latestBi.b1Status) {
        case BenefitInvestigationStatus.Paid: {
          return renderBiApprovedDetails();
        }
        case BenefitInvestigationStatus.Rejected:
          return renderBiRejectedDetails();
      }
    }
    return null;
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Title />
      </Grid>

      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={12} className={classes.dataRow}>
              <Typography display="inline" className={cx(classes.typography)}>
                Latest Benefit Investigation{' '}
              </Typography>
              {renderLatestDate()}
              {renderBiStatus()}
            </Grid>
            <Grid item xs={12}>
              {renderBiDetails()}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs="auto">
          <RunBiRightSide
            therapyId={props.therapy.id}
            ndc={props.therapy.ndc}
            taskId={props.taskId}
            shouldFetchPatientBi={props.shouldFetchPatientBi}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

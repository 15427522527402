import { IProfileTherapy, ITherapyInfoProps } from 'components/patient-merge-modal/interfaces';
import { Grid, Typography } from '@mui/material';
import { convertToArborDate } from 'models/time/arbor-date';
import React from 'react';
import { dosageFormToUIDisplay } from 'services/utils/dosageFormToUIDisplay';
import { useTheme } from '@mui/styles';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import moment from 'moment';

export const TherapyInfo = (props: ITherapyInfoProps) => {
  const { therapy } = props;
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);

  const formatTherapyName = (t: IProfileTherapy) => {
    const orderFormDisplay = dosageFormToUIDisplay(t.dosageForm);
    const name = `${t.drugName} ${t.dosageForm} ${t.strength} ${t.strengthUnitOfMeasure} `
      + `| NDC ${t.ndc}`;
    const nameWithDosageOrderFormDisplay = orderFormDisplay == null
      ? name
      : `${name} ${orderFormDisplay}`;
    return nameWithDosageOrderFormDisplay;
  };

  return (
    <Grid container columnSpacing={1}>
      <Grid item container xs={8}>
        <Grid item xs={12}>
          <Typography sx={classes.therapyColumnHeader}>Therapy Name</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={classes.therapyTitle}>{formatTherapyName(therapy)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={classes.checkboxInfoValue}>
            {[
              `Admin. ${therapy.administrationStatus}`,
              `Disp. ${therapy.dispensingStatus}`,
              `CS ${therapy.clinicalSupportStatus}`,
            ].join(' | ')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={2}>
        <Grid item xs={12}>
          <Typography sx={classes.therapyColumnHeader}>Creation Date</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={classes.checkboxInfoValue}>
            {convertToArborDate(therapy.created, true).getUtcDate(true)}
            {' '}
            {`(${moment().diff(
              convertToArborDate(therapy.created).getCustomerMoment(),
              'months',
              false,
            )}months`}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={2}>
        <Grid item xs={12}>
          <Typography sx={classes.therapyColumnHeader}>NDB &amp; Cycle</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={classes.checkboxInfoValue}>
            {!therapy.needsbyDate && !therapy.fillCycle ? ' - ' : ''}
            {convertToArborDate(therapy.needsbyDate, true).getUtcDate(true)}
            {therapy.fillCycle ? ` #${therapy.fillCycle}` : ''}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

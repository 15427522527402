import { THERAPY_ADMIN_STATUS_PRE_THERAPY, THERAPY_ADMIN_STATUS_ON_THERAPY } from 'constants/index';

// TODO: Add all of the other administration statuses.
export enum TherapyAdministrationStatuses {
  OnTherapy = 1002,
  OnHoldAttemptingPregnancy = 1003,
  OnHoldMedicalProcedure = 1004,
  OnHoldPatientUnreachable = 1005,
  OnHoldExcessSupplyOfMeds = 1006,
  OnHoldDrugHoliday = 1007,
  OnHoldLabsOutOfRange = 1043,
  OnHoldOther = 1040,
  OnHoldCurrentlyPregnant = 1038,
  OnHoldMedicationAvailability = 17100,
  OnHoldHospitalization = 1008,
  PreTherapy = 1001,
  MigrationUnknown = 1031,
  MigrationFallthrough = 1032,
}
export const THERAPY_ADMINISTRATION_PRE_THERAPY = [
  TherapyAdministrationStatuses.PreTherapy,
  TherapyAdministrationStatuses.MigrationUnknown,
  TherapyAdministrationStatuses.MigrationFallthrough,
];
export const THERAPY_ADMINISTRATION_ON_THERAPY = [TherapyAdministrationStatuses.OnTherapy];
export const THERAPY_ADMINISTRATION_ON_HOLD = [
  TherapyAdministrationStatuses.OnHoldAttemptingPregnancy,
  TherapyAdministrationStatuses.OnHoldMedicalProcedure,
  TherapyAdministrationStatuses.OnHoldPatientUnreachable,
  TherapyAdministrationStatuses.OnHoldExcessSupplyOfMeds,
  TherapyAdministrationStatuses.OnHoldDrugHoliday,
  TherapyAdministrationStatuses.OnHoldHospitalization,
  TherapyAdministrationStatuses.OnHoldCurrentlyPregnant,
  TherapyAdministrationStatuses.OnHoldOther,
  TherapyAdministrationStatuses.OnHoldLabsOutOfRange,
  TherapyAdministrationStatuses.OnHoldMedicationAvailability,
];
export const THERAPY_ADMINISTRATION_INITIATED = THERAPY_ADMINISTRATION_ON_THERAPY.concat(
  THERAPY_ADMINISTRATION_ON_HOLD,
);

export const THERAPY_ACTIVE_STATUS = [
  { label: THERAPY_ADMIN_STATUS_PRE_THERAPY, value: TherapyAdministrationStatuses.PreTherapy },
  { label: THERAPY_ADMIN_STATUS_ON_THERAPY, value: TherapyAdministrationStatuses.OnTherapy },
];

import {
  UPDATE_ELIGIBILITY_CHECK,
  FETCH_ELIGIBILITY_CHECK_HISTORY,
  CLOSE_ELIGIBILITY_CHECK,
} from '../constants';

export default (
  state = {
    open: false,
  },
  { type, payload },
) => {
  switch (type) {
    case UPDATE_ELIGIBILITY_CHECK:
      return { ...state, ...payload };
    case FETCH_ELIGIBILITY_CHECK_HISTORY:
      if (payload) {
        return {
          ...state,
          eligibility_check_history: payload.data,
        };
      }
      return state;
    case CLOSE_ELIGIBILITY_CHECK:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};

import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Grid } from '@mui/material';
import { push } from 'connected-react-router';
import { ToggleFabPanel } from '../../actions/action-view';
import { buildHandleEnterKeyPress } from '../../services/utils/accessibility';
import { withCustomer } from '../../helpers/router';

interface INewPatientActionButtonProps {
  inheritedClass: string;
  onClick: () => void;
}

class NewPatientActionButton extends React.Component<INewPatientActionButtonProps> {
  state = {
    isControlPanelOpen: false,
  };

  render() {
    return (
      <Grid
        item
        xs={6}
        className={this.props.inheritedClass}
        onClick={() => this.props.onClick()}
        onKeyPress={buildHandleEnterKeyPress(() => this.props.onClick())}
        role="button"
        tabIndex={0}
        data-qa-id="tfb-newPatient"
      >
        New Patient
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onClick: () =>
      dispatch(new ToggleFabPanel(false)) &&
      dispatch(push(withCustomer('/patients/new?sidebar=no_sidebar'))) &&
      window.scrollTo(0, 0),
  };
}

export default compose<INewPatientActionButtonProps, { inheritedClass: string }>(
  connect(() => ({}), mapDispatchToProps),
)(NewPatientActionButton);

interface IAdherenceDataRow {
  therapyId: number;
  therapy: string;
  values: { value: number | null; compliant: boolean; interviened: boolean; key: string }[];
}

interface IAdherenceData {
  dates: string[];
  rows: IAdherenceDataRow[];
  nonCompliantTherapies: number[];
  previousInterventionCount: number;
}

export enum AdherenceComplianceStatusEnum {
  Ok = 'ok',
  NeedCheck = 'need_check',
  Checked = 'checked',
}

export type { IAdherenceData, IAdherenceDataRow };

export interface DailyForecast {
  date: string;
  maxTemp: number;
  minTemp: number;
  weatherCondition: OpenWeatherCondition;
  weatherDescription: string;
}

export type DailyForecastAPIResponse = {
  city: string;
  forecast: DailyForecast[];
};

export enum OpenWeatherCondition {
  Thunderstorm = 'Thunderstorm',
  Drizzle = 'Drizzle',
  Rain = 'Rain',
  Snow = 'Snow',
  Mist = 'Mist',
  Smoke = 'Smoke',
  Haze = 'Haze',
  Fog = 'Fog',
  Sand = 'Sand',
  Dust = 'Dust',
  Ash = 'Ash',
  Squall = 'Squall',
  Tornado = 'Tornado',
  Clear = 'Clear',
  Clouds = 'Clouds',
  NoData = 'NoData',
}

export interface DailyForecastResponse {
  city: City;
  cod: string;
  message: number;
  cnt: number;
  list: List[];
}

export interface City {
  id: number;
  name: string;
  coord: Coord;
  country: string;
  population: number;
  timezone: number;
}

export interface Coord {
  lon: number;
  lat: number;
}

export interface List {
  dt: number;
  sunrise: number;
  sunset: number;
  temp: Temp;
  feels_like: FeelsLike;
  pressure: number;
  humidity: number;
  weather: Weather[];
  speed: number;
  deg: number;
  clouds: number;
}

export interface FeelsLike {
  day: number;
  night: number;
  eve: number;
  morn: number;
}

export interface Temp {
  day: number;
  min: number;
  max: number;
  night: number;
  eve: number;
  morn: number;
}

export interface Weather {
  id: number;
  main: OpenWeatherCondition;
  description: string;
  icon: string;
}

export enum OpenWeatherUnit {
  Fahrenheit = 'imperial',
  Celsius = 'metric',
}

export class OpenWeatherCliError extends Error {
  constructor(private statusCode: number, message?: string) {
    super(message || 'OpenWeather: Unhandled error');
    Object.setPrototypeOf(this, new.target.prototype);
  }

  getStatusCode() {
    return this.statusCode;
  }

  getMessage() {
    return this.message;
  }
}

import React from 'react';
import { FormControlLabel, FormGroup, FormControl, FormLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

const CheckboxList = ({ input, fields, label }) => {
  const values = input.value || [];
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {fields.map(f => (
          <FormControlLabel
            key={f.id}
            control={
              <Checkbox
                checked={values.some(it => it === f.id)}
                color="primary"
                onChange={() => {
                  if (values.indexOf(f.id) === -1) {
                    input.onChange([...values, f.id]);
                  } else {
                    input.onChange(values.filter(it => it !== f.id));
                  }
                }}
                value={f.id}
              />
            }
            label={f.name}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxList;

import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field } from 'redux-form';
import { useTypedSelector } from 'hooks/use-typed-selector';
import {
  renderTextField,
  renderDropdown,
  renderReactRadioGroup,
} from 'components/form/field/redux-field';
import { getClinicalDataTypesMap } from 'services/utils/data-collect';
import { required } from 'components/form/validation/validation';
import { Close as CloseIcon } from '@mui/icons-material';
import { ILabelValueNumber } from 'interfaces/ILabelValue';
import { fetchGpis, specialtyTypeFormatter } from 'services/utils/therapy-service';
import AutocompleteMinigrid from 'components/form/field/render-minigrid-autocomplete';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { equalityOperators } from 'constants/lists';
import { Timezone } from 'models/time/timezone';
import momentBD from 'moment-business-days';
import { numberParser } from '../utils';
import { ClinicalItemProtocolFormModalStyles } from './styles';
import { TEXT_CLINICAL_DATA_TYPE_OPTIONS } from '../../../../constants/index';
import { IEditSafetyFormFieldsDefaultValues } from './types';

const useStyles = makeStyles(ClinicalItemProtocolFormModalStyles);

interface IClinicalItemsList extends ILabelValueNumber {
  type: string;
}

interface ISafetyProtocolComponent {
  safetyProtocolData: { id: number }[];
  deleteDynamicSafetyProtocol: (id: number) => void;
  setFieldValue: (field: string, value: any) => void;
  consecutiveInterventionsOptions: { label: string; value: number }[];
  protocolStatus: { label: string; value: number }[];
  clinicalItems: IClinicalItemsList[];
  incorrectSafetyInterventionScoreValue: number[];
  formListOfValues: any;
  updateIncorrectSafetyInterventionScoreValue: (newValues: number[]) => void;
  incorrectSafetyExpirationDateValue: number[];
  updateIncorrectSafetyExpirationDateValue: (newValues: number[]) => void;
  editSafetyFormValues: IEditSafetyFormFieldsDefaultValues | null;
}

const fixedFields = [
  { fieldName: 'brand_name', defaultValue: [] },
  { fieldName: 'generic_name', defaultValue: '' },
  { fieldName: 'gpi_10', defaultValue: '' },
];

const dynamicFields = [
  { fieldName: 'safety_clinical_item_', defaultValue: [] },
  { fieldName: 'safety_intervention_criteria_', defaultValue: [] },
  { fieldName: 'safety_criteria_clinical_data_operator_', defaultValue: [] },
  { fieldName: 'safety_criteria_clinical_data_value_', defaultValue: '' },
  { fieldName: 'safety_intervention_score_value_', defaultValue: '' },
  { fieldName: 'safety_consecutive_intervention_scores_', defaultValue: 0 },
  { fieldName: 'safety_protocol_status_', defaultValue: [] },
  { fieldName: 'safety_effective_date_', defaultValue: null },
  { fieldName: 'safety_expiration_date_', defaultValue: null },
  { fieldName: 'special_population_', defaultValue: [] },
  { fieldName: 'safety_consecutive_type_', defaultValue: [] },
];

export const SafetyProtocolComponent: React.FC<ISafetyProtocolComponent> = (props): JSX.Element => {
  const classes = useStyles();
  const [disableInterventionScoreValue, setDisableInterventionScoreValue] = useState<number[]>([]);
  const [currentConsecutiveIntScore, setCurrentConsecutiveIntScore] = useState<
    { id: number; value: string }[]
  >([]);
  const [requiredSafetyInterventionCriteria, setRequiredSafetyInterventionCriteria] = useState<
    { groupId: number; options: { label: string; value: string }[] }[]
  >([]);
  const [selectedClinicalItems, setSelectedClinicalItems] = useState<
    { groupId: number; clinicalItemId: number; type: string | undefined }[]
  >([]);
  const [specialPopulationList, setSpecialPopulationList] = useState<ILabelValueNumber[]>([]);
  const [consecutiveTypesList, setConsecutiveTypesList] = useState<ILabelValueNumber[]>([]);
  const [disableThresholdAndValue, setDisableThresholdAndValue] = useState<number[]>([]);

  const {
    safetyProtocolData: safetyProtocolList,
    deleteDynamicSafetyProtocol,
    setFieldValue,
    consecutiveInterventionsOptions,
    protocolStatus,
    clinicalItems,
    incorrectSafetyInterventionScoreValue,
    formListOfValues,
    updateIncorrectSafetyInterventionScoreValue,
    incorrectSafetyExpirationDateValue,
    updateIncorrectSafetyExpirationDateValue,
    editSafetyFormValues,
  } = props;
  const clinicalDataTypes = useTypedSelector(state => state.lookups.clinicalDataTypes);
  const specialPopulations = useTypedSelector(state => state.lookups.specialPopulations) || [];
  const consecutiveTypes = useTypedSelector(state => state.lookups.consecutiveTypes) || [];

  const getGenericName = (value: any) => {
    const safetyProtocolDrugs = value?.associatedDrugs || [];
    const safetyProtocolGeneric = safetyProtocolDrugs.find((d: any) => d.brand_name_code === 'G')
      ? safetyProtocolDrugs
          .filter((d: any) => d.brand_name_code === 'G')
          .map((d: any) => d.drug_name)
          .join(',')
      : '';
    setFieldValue('generic_name', safetyProtocolGeneric);
  };

  const handleFetchGpisOptions = async (searchText: string) => {
    const result = await fetchGpis(searchText);
    return (
      result?.data?.drugs?.map((therapy: { ndc: any; drug_info: any; is_specialty_drug: any }) => ({
        ...therapy,
        id: therapy.ndc,
        label: therapy.drug_info,
      })) || []
    );
  };

  const setClinicalItemOptionValues = (value: number, groupId: number) => {
    setFieldValue(`safety_criteria_clinical_data_operator_${groupId}`, []);
    setFieldValue(`safety_criteria_clinical_data_value_${groupId}`, '');
    const map = getClinicalDataTypesMap(clinicalDataTypes);
    const selectedItemOptions = Object.values(map).reduce(
      (acc: { label: string; value: string }[], item: any) => {
        if (parseInt(item.dataTypeId, 10) === value && item.options && item.options.length) {
          const itemOptions = item.options.map((t: string) => {
            acc.push({
              label: t,
              value: t,
            });
          });
        }
        return acc;
      },
      [] as { label: string; value: string }[],
    );
    setRequiredSafetyInterventionCriteria(requiredSafetyInterventionCriteria => {
      const tempOpts = requiredSafetyInterventionCriteria.filter(t => t.groupId !== groupId);
      return [
        ...tempOpts,
        {
          groupId: groupId,
          options: selectedItemOptions,
        },
      ];
    });
    setSelectedClinicalItems(selectedClinicalItems => {
      const tempSelectedItems = selectedClinicalItems.filter(t => t.groupId !== groupId);
      return [
        ...tempSelectedItems,
        {
          groupId: groupId,
          clinicalItemId: value,
          type: clinicalItems.find(t => t.value === value)?.type ?? undefined,
        },
      ];
    });
  };

  const updateConsecutiveInterventionFieldState = (value: number, groupId: number) => {
    if (value === 1) {
      setDisableInterventionScoreValue(disableInterventionScoreValue.filter(t => t !== groupId));
      setDisableThresholdAndValue(disableThresholdAndValue => {
        return [...disableThresholdAndValue, groupId];
      });
      setFieldValue(`safety_criteria_clinical_data_operator_${groupId}`, []);
      setFieldValue(`safety_criteria_clinical_data_value_${groupId}`, '');
      setFieldValue(`special_population_${groupId}`, []);
    }
    if (value === 0) {
      setFieldValue(`safety_intervention_score_value_${groupId}`, '');
      setFieldValue(`safety_consecutive_type_${groupId}`, []);
      updateIncorrectSafetyInterventionScoreValue(
        incorrectSafetyInterventionScoreValue.filter(t => t !== groupId),
      );
      setDisableInterventionScoreValue(disableInterventionScoreValue => {
        return [...disableInterventionScoreValue, groupId];
      });
      setDisableThresholdAndValue(disableThresholdAndValue.filter(t => t !== groupId));
    }
    trackIntScoreValues(groupId, '');
  };

  const getSafetyInterventionCriteriaOptions = (
    groupId: number,
  ): { label: string; value: string }[] => {
    const obj = requiredSafetyInterventionCriteria.filter(t => t.groupId === groupId);
    return obj && Object.keys(obj).length > 0 ? obj[0].options : [];
  };

  const updateRequiredSafetyInterventionCriteria = (groupId: number): void => {
    setRequiredSafetyInterventionCriteria(
      requiredSafetyInterventionCriteria.filter(t => t.groupId !== groupId),
    );
  };

  const updateSelectedClinicalItems = (groupId: number): void => {
    setSelectedClinicalItems(selectedClinicalItems.filter(t => t.groupId !== groupId));
  };

  const resetFields = (groupId: number): void => {
    if (safetyProtocolList.length === 1) {
      fixedFields.map(f => setFieldValue(f.fieldName, f.defaultValue));
    }
    dynamicFields.map(f => setFieldValue(`${f.fieldName}${groupId}`, f.defaultValue));
  };

  const trackIntScoreValues = (id: number, value: string) => {
    const currentConsecutiveScoreObj = currentConsecutiveIntScore.find(t => t.id === id);
    if (currentConsecutiveScoreObj === undefined) {
      setCurrentConsecutiveIntScore(currentConsecutiveIntScore => {
        return [
          ...currentConsecutiveIntScore,
          {
            id,
            value,
          },
        ];
      });
    } else {
      setCurrentConsecutiveIntScore(currentConsecutiveIntScore => {
        const tempValues = currentConsecutiveIntScore.map(t => {
          if (t.id === id) {
            return {
              ...t,
              value,
            };
          }
          return t;
        });
        return tempValues;
      });
    }
  };

  useEffect(() => {
    setSpecialPopulationList(
      specialPopulations.reduce((acc, t) => {
        acc.push({
          label: t.type,
          value: t.id,
        });
        return acc;
      }, [] as ILabelValueNumber[]),
    );
  }, [specialPopulations]);

  useEffect(() => {
    setConsecutiveTypesList(
      consecutiveTypes.reduce((acc, t) => {
        acc.push({
          label: t.type,
          value: t.id,
        });
        return acc;
      }, [] as ILabelValueNumber[]),
    );
  }, [consecutiveTypes]);

  useEffect(() => {
    if (editSafetyFormValues && Object.keys(editSafetyFormValues).length) {
      getGenericName(editSafetyFormValues);
      if (editSafetyFormValues.drugInfo) {
        setFieldValue('brand_name', { label: editSafetyFormValues.drugInfo });
      }
      if (editSafetyFormValues.gpi_10) {
        setFieldValue('gpi_10', editSafetyFormValues.gpi_10.toString().substr(0, 10));
      }
      if (editSafetyFormValues.clinical_item_id) {
        setFieldValue('safety_clinical_item_1', editSafetyFormValues.clinical_item_id);
        setClinicalItemOptionValues(editSafetyFormValues.clinical_item_id, 1);
      }
      if (editSafetyFormValues.clinical_data_operator) {
        setFieldValue(
          'safety_criteria_clinical_data_operator_1',
          editSafetyFormValues.clinical_data_operator,
        );
      }
      if (editSafetyFormValues.clinical_data_value) {
        setFieldValue(
          'safety_criteria_clinical_data_value_1',
          editSafetyFormValues.clinical_data_value,
        );
      }
      if (editSafetyFormValues.special_population_id) {
        setFieldValue('special_population_1', editSafetyFormValues.special_population_id);
      }
      if (
        editSafetyFormValues.consecutive_intervention_score !== null &&
        editSafetyFormValues.consecutive_intervention_score !== undefined
      ) {
        setFieldValue(
          'safety_consecutive_intervention_scores_1',
          Number(editSafetyFormValues.consecutive_intervention_score),
        );
        updateConsecutiveInterventionFieldState(
          Number(editSafetyFormValues.consecutive_intervention_score),
          1,
        );
        if (
          editSafetyFormValues.consecutive_intervention_score &&
          editSafetyFormValues.consecutive_type_id
        ) {
          setFieldValue('safety_consecutive_type_1', editSafetyFormValues.consecutive_type_id);
        }
        if (editSafetyFormValues.consecutive_value) {
          setFieldValue(
            'safety_intervention_score_value_1',
            editSafetyFormValues.consecutive_value,
          );
        }
      }
      if (editSafetyFormValues.status !== null && editSafetyFormValues.status !== undefined) {
        setFieldValue('safety_protocol_status_1', Number(editSafetyFormValues.status));
      }
      const customerTimezone = Timezone.getInstance().timezone;
      if (
        editSafetyFormValues.effective_date !== null &&
        editSafetyFormValues.effective_date !== undefined
      ) {
        setFieldValue(
          'safety_effective_date_1',
          momentBD
            .utc(editSafetyFormValues.effective_date)
            .tz(customerTimezone, true)
            .startOf('day')
            .toDate(),
        );
      }
      if (
        editSafetyFormValues.expiration_date !== null &&
        editSafetyFormValues.expiration_date !== undefined
      ) {
        setFieldValue(
          'safety_expiration_date_1',
          momentBD
            .utc(editSafetyFormValues.expiration_date)
            .tz(customerTimezone, true)
            .startOf('day')
            .toDate(),
        );
      }
    }
  }, [editSafetyFormValues]);

  useEffect(() => {
    if (
      editSafetyFormValues &&
      Object.keys(editSafetyFormValues).length &&
      requiredSafetyInterventionCriteria.length
    ) {
      if (editSafetyFormValues.options) {
        const tempPossibleOptions = editSafetyFormValues.options
          .split(',')
          .map(t => t.toLowerCase().trim());
        const intCriteriaOpts = getSafetyInterventionCriteriaOptions(1);
        if (tempPossibleOptions.length && intCriteriaOpts.length) {
          const selectedOpts = [];
          for (const opt of intCriteriaOpts) {
            if (tempPossibleOptions.includes(opt.label.toLowerCase().trim())) {
              selectedOpts.push(opt);
            }
          }
          setFieldValue('safety_intervention_criteria_1', selectedOpts.map(t => t.value).join(','));
        }
      }
    }
  }, [editSafetyFormValues, requiredSafetyInterventionCriteria]);

  return (
    <>
      {safetyProtocolList.map((x, ind) => {
        return (
          <Grid container spacing={2}>
            {ind === 0 && (
              <Grid container className={classes.fixedSection}>
                <Grid item xs={6}>
                  <Field
                    name="brand_name"
                    label="Brand name"
                    labelVariant="body1"
                    component={AutocompleteMinigrid}
                    fetchOptions={handleFetchGpisOptions}
                    hint="Search by medication, GPI or NDC"
                    columnsToShow={{
                      gpi: 'GPI',
                      drug_name: 'Drug Name',
                      dosage_form_description: 'Form',
                      dose: 'Dose',
                      ldd: 'LDD',
                      specialty_type: 'Specialty Type',
                      ndc: 'NDC',
                    }}
                    minSearchLength={2}
                    valueFormatter={specialtyTypeFormatter}
                    disabled={editSafetyFormValues && editSafetyFormValues.gpi_10}
                    onChange={(value: any) => {
                      setFieldValue('gpi_10', value.gpi.substr(0, 10));
                      getGenericName(value);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="generic_name"
                    label="Generic Name"
                    component={renderTextField}
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field name="gpi_10" label="GPI-10" component={renderTextField} disabled />
                </Grid>
              </Grid>
            )}
            <Grid container className={classes.interventionCriteriaRow}>
              <Grid item xs={11}>
                <Typography className={classes.protocolSubtitle}>Intervention Criteria</Typography>
              </Grid>
              <Grid
                item
                xs={1}
                className={classes.centerIcon}
                key={`safety_delete_icon_${x.id}`}
                onClick={() => {
                  deleteDynamicSafetyProtocol(x.id);
                  updateRequiredSafetyInterventionCriteria(x.id);
                  updateSelectedClinicalItems(x.id);
                  resetFields(x.id);
                }}
              >
                <CloseIcon />
              </Grid>
            </Grid>
            <Grid container item spacing={2} className={classes.interventionCriteriaFields}>
              <Grid item xs={12}>
                <Field
                  name={`safety_clinical_item_${x.id}`}
                  label="Clinical Item *"
                  component={renderDropdown}
                  validate={[required]}
                  fields={clinicalItems}
                  onChange={(value: any) => setClinicalItemOptionValues(value, x.id)}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name={`safety_intervention_criteria_${x.id}`}
                  label={
                    getSafetyInterventionCriteriaOptions(x.id).length ? 'Options *' : 'Options'
                  }
                  component={renderDropdown}
                  isMulti
                  validate={getSafetyInterventionCriteriaOptions(x.id).length ? [required] : []}
                  disabled={!getSafetyInterventionCriteriaOptions(x.id).length}
                  fields={getSafetyInterventionCriteriaOptions(x.id)}
                />
              </Grid>
              <Grid item xs={5}>
                <Field
                  name={`safety_criteria_clinical_data_operator_${x.id}`}
                  label={
                    !disableThresholdAndValue.includes(x.id) &&
                    !getSafetyInterventionCriteriaOptions(x.id).length
                      ? 'Threshold Symbol *'
                      : 'Threshold Symbol'
                  }
                  component={renderDropdown}
                  validate={
                    disableThresholdAndValue.includes(x.id)
                      ? []
                      : !getSafetyInterventionCriteriaOptions(x.id).length
                      ? [required]
                      : []
                  }
                  disabled={
                    getSafetyInterventionCriteriaOptions(x.id).length ||
                    disableThresholdAndValue.includes(x.id)
                  }
                  fields={equalityOperators}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name={`safety_criteria_clinical_data_value_${x.id}`}
                  label={
                    !disableThresholdAndValue.includes(x.id) &&
                    !getSafetyInterventionCriteriaOptions(x.id).length
                      ? 'Value *'
                      : 'Value'
                  }
                  component={renderTextField}
                  disabled={
                    getSafetyInterventionCriteriaOptions(x.id).length ||
                    disableThresholdAndValue.includes(x.id)
                  }
                  validate={
                    disableThresholdAndValue.includes(x.id)
                      ? []
                      : !getSafetyInterventionCriteriaOptions(x.id).length
                      ? [required]
                      : []
                  }
                  onKeyUp={(e: any) => {
                    const selectedItem = selectedClinicalItems.find(t => t.groupId === x.id)?.type;
                    TEXT_CLINICAL_DATA_TYPE_OPTIONS.includes(selectedItem ?? '')
                      ? ''
                      : numberParser(e);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name={`special_population_${x.id}`}
                  label="Special Population"
                  component={renderDropdown}
                  disabled={disableThresholdAndValue.includes(x.id)}
                  fields={specialPopulationList}
                />
              </Grid>
              <Grid
                item
                xs={4}
                className={
                  getSafetyInterventionCriteriaOptions(x.id).length ? classes.disabledRow : ''
                }
              >
                <Field
                  label="Consecutive intervention scores *"
                  name={`safety_consecutive_intervention_scores_${x.id}`}
                  initialValueName={
                    consecutiveInterventionsOptions.find(t => t.label.toLowerCase() === 'no')?.label
                  }
                  radioMap={consecutiveInterventionsOptions}
                  component={renderReactRadioGroup}
                  validate={[required]}
                  width="100%"
                  onChange={(value: any) => updateConsecutiveInterventionFieldState(value, x.id)}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name={`safety_consecutive_type_${x.id}`}
                  label={
                    !disableInterventionScoreValue.includes(x.id)
                      ? 'Consecutive Type *'
                      : 'Consecutive Type'
                  }
                  component={renderDropdown}
                  disabled={disableInterventionScoreValue.includes(x.id)}
                  validate={!disableInterventionScoreValue.includes(x.id) ? [required] : []}
                  fields={consecutiveTypesList}
                />
              </Grid>
              <Grid item xs={5}>
                <Field
                  name={`safety_intervention_score_value_${x.id}`}
                  label={
                    !disableInterventionScoreValue.includes(x.id)
                      ? 'Number of consecutive intervention scores *'
                      : 'Number of consecutive intervention scores'
                  }
                  component={renderTextField}
                  disabled={disableInterventionScoreValue.includes(x.id)}
                  meta={
                    incorrectSafetyInterventionScoreValue.includes(x.id)
                      ? {
                          touched: incorrectSafetyInterventionScoreValue.includes(x.id),
                          error: 'Value must be greater than zero (0)',
                        }
                      : !disableInterventionScoreValue.includes(x.id) &&
                        (currentConsecutiveIntScore.find(t => t.id === x.id)?.value.length === 0 ||
                          currentConsecutiveIntScore.find(t => t.id === x.id) === undefined)
                      ? { touched: true, error: 'REQUIRED' }
                      : { touched: disableInterventionScoreValue.includes(x.id), error: '' }
                  }
                  onKeyUp={(e: any) => {
                    numberParser(e);
                  }}
                  onBlur={(e: any) => {
                    trackIntScoreValues(x.id, e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name={`safety_protocol_status_${x.id}`}
                  label="Protocol Status *"
                  component={renderDropdown}
                  validate={[required]}
                  fields={protocolStatus}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name={`safety_effective_date_${x.id}`}
                  label="Effective date *"
                  component={renderDatePicker}
                  validate={[required]}
                  onChange={(value: any) =>
                    updateIncorrectSafetyExpirationDateValue(
                      incorrectSafetyExpirationDateValue.filter(t => t !== x.id),
                    )
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name={`safety_expiration_date_${x.id}`}
                  label="Expiration date"
                  component={renderDatePicker}
                  meta={
                    incorrectSafetyExpirationDateValue.includes(x.id)
                      ? {
                          touched: incorrectSafetyExpirationDateValue.includes(x.id),
                          error: 'It must be after effective date',
                        }
                      : { touched: !incorrectSafetyExpirationDateValue.includes(x.id), error: '' }
                  }
                  onChange={(value: any) =>
                    updateIncorrectSafetyExpirationDateValue(
                      incorrectSafetyExpirationDateValue.filter(t => t !== x.id),
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

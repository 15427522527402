import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const styles = (theme: Theme) => {
  return createStyles({
    card: {
      margin: theme.spacing(1),
    },
  });
};

import React from 'react';
import { Grid } from '@mui/material';
import DocumentDisplay from 'components/document/document-display';
import NoteDisplay from 'components/note/note-display';

export default props => (
  <>
    <Grid item xs={12}>
      <NoteDisplay {...props} />
    </Grid>
    <Grid item xs={12}>
      <DocumentDisplay {...props} />
    </Grid>
  </>
);

import { FcGenerator } from './fc-generator';
import { Generator } from './generator';
import { DocumentGeneratorType } from './types';

export const generatorFactory = (
  taskType: DocumentGeneratorType,
  customerId: number,
  patientId: number,
): Generator | undefined => {
  switch (taskType) {
    case DocumentGeneratorType.FillCoordination:
      return new FcGenerator(customerId, patientId);
    default:
      return undefined;
  }
};

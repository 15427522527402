import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import JsonForm from 'components/dynamic-form/json-form';
import overrideForm from 'config/report/overrideForm.json';
import { addPaTatOverride } from 'actions/action-report';
import { staticProviders } from '../providers/common-provider';

const ReportOverrideForm = props => {
  const {
    overrideBefore,
    priorAuthorizationId,
    closeHandler,
    // eslint-disable-next-line no-shadow
    addPaTatOverride,
    reportId,
  } = props;

  const providers = {
    ...staticProviders,
    json: overrideForm,
  };

  const getOverrideBucketByDays = days => {
    if (days <= 1) {
      return '1-DAY-OR-LESS';
    }
    if (days === 2) {
      return '2-DAYS';
    }
    if (days >= 3 && days <= 4) {
      return '3-4-DAYS';
    }

    return 'MORE-THAN-1-WEEK';
  };

  const formId = `overrideForm-${priorAuthorizationId}`;

  return (
    <div style={{ maxWidth: 800 }}>
      <JsonForm
        formName="PaTatOverride"
        jsonForm={providers.json}
        formId={formId}
        data={{}}
        providers={providers}
        onSubmit={values => {
          const overrideInDays = parseInt(values.override_option, 10);
          const overrideAfter = getOverrideBucketByDays(overrideInDays);
          const overrideReason = values.override_reason;

          return addPaTatOverride(
            {
              priorAuthorizationId,
              overrideInDays,
              overrideBefore,
              overrideAfter,
              overrideReason,
            },
            reportId,
          );
        }}
        onCancel={closeHandler}
      />
    </div>
  );
};

export default compose(
  connect(null, {
    addPaTatOverride,
  }),
)(ReportOverrideForm);

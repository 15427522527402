import React, { useEffect, useState } from 'react';
import {
  FormControl,
  Grid,
  Button,
  MenuList,
  MenuItem,
  Typography,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useSelector, useDispatch } from 'react-redux';
import compose from 'recompose/compose';
import { fetchCustomers, selectCustomer } from 'actions/action-filters';
import { fetchCustomerClinics } from 'actions/action-lookups';
import { changeCustomer } from 'services/login/index';
import { DropdownIcon } from 'components/icons/icons';
import { styles } from './dropdown-styles';

const CustomerDropdown = props => {
  const { classes } = props;

  const dispatch = useDispatch();
  const filters = useSelector(state => state.filters);
  const { customers, selectedCustomerId } = filters;

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
    setOpen(!open);
  };

  useEffect(() => {
    dispatch(fetchCustomers());
    dispatch(fetchCustomerClinics());
  }, [fetchCustomers, fetchCustomerClinics]);

  const renderCustomers = () =>
    customers
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(customer => (
        <MenuItem
          key={customer.id}
          value={customer.id}
          onClick={() => {
            changeCustomer(customer.id).then(() => {
              dispatch(selectCustomer(customer.id)).then(() => {
                window.location.href = '/';
              });
            });
          }}
        >
          <Typography className={classes.listItem}>{customer.name}</Typography>
        </MenuItem>
      ));
  const customersList = renderCustomers();

  return (
    <form autoComplete="off">
      <FormControl className={classes.formControl}>
        <Grid container direction="row">
          <Grid item>
            <Grid container direction="column" alignItems="flex-end">
              <Typography variant="caption" className={classes.label}>
                Health System
              </Typography>
              {customers.length > 0 && selectedCustomerId ? (
                <Typography variant="body2">
                  {customers.find(customer => customer.selected).name}
                </Typography>
              ) : (
                <Typography variant="body2">Loading ...</Typography>
              )}
            </Grid>
          </Grid>

          <Grid item>
            <Button
              className={classes.dropdownButton}
              aria-owns={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <DropdownIcon />
            </Button>
            <Popper open={open} anchorEl={anchorEl} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={() => setOpen(false)}>
                      <MenuList>{customersList}</MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
        </Grid>
      </FormControl>
    </form>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(CustomerDropdown);

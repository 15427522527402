// @ts-nocheck
import { EditorState, ContentState, SelectionState } from 'draft-js';

import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

import { EditorValueFormat } from './enums';

export const buildInitialValue = (
  format: EditorValueFormat,
  value: ContentState | string | undefined,
): EditorState => {
  if (!value) {
    return EditorState.createEmpty();
  }
  switch (format) {
    case EditorValueFormat.PlainText:
      return EditorState.createWithContent(ContentState.createFromText(value as string));
    case EditorValueFormat.Html:
      return EditorState.createWithContent(stateFromHTML(value as string));
    case EditorValueFormat.DraftJsObject:
      return EditorState.createWithContent(value as ContentState);
  }
};

export const buildOutputValue = (
  format: EditorValueFormat,
  state: EditorState,
): ContentState | string => {
  switch (format) {
    case EditorValueFormat.Html:
      return stateToHTML(state.getCurrentContent());
    case EditorValueFormat.DraftJsObject:
      return state.getCurrentContent();
    case EditorValueFormat.PlainText:
    default:
      return state.getCurrentContent().getPlainText();
  }
};

export const moveCursorToEnd = (state: EditorState) => {
  const content = state.getCurrentContent();
  const blockMap = content.getBlockMap();

  const key = blockMap.last()?.getKey();
  const length = blockMap.last()?.getLength();

  const selection = new SelectionState({
    anchorKey: key,
    anchorOffset: length,
    focusKey: key,
    focusOffset: length,
  });
  return EditorState.forceSelection(state, selection);
};

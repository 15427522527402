import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import AddIcon from '@mui/icons-material/Add';
import { change } from 'redux-form';
import { useDispatch } from 'react-redux';
import { uniqueId } from 'lodash';
import { windowFeatureIsEnabled } from '../../../config/window-features';
import { styles } from './meds-final-form-styles';
import AddPatientTherapy from '../therapy/add-patient-therapy/add-patient-therapy';
import DrugCopySelector from '../therapy/fields/drug-copy-selector';
import {
  ADD_PATIENT_THERAPY_FORM,
  THERAPY_MEDICATION_STATUS_EXISTING,
  THERAPY_LEAD_SOURCE_EMR_LEAD,
  DISPENSING_STATUSES,
} from '../../../constants';
import { MedsTabs, Handler } from './meds-tabs';
import { Tab } from '../../../components/tab-label';
import store from '../../../store';

const ADD_TAB_ID = 'add-tab';
const ADD_TAB = {
  id: ADD_TAB_ID,
  label: '',
  icon: <AddIcon />,
};
const MedsFinalForm = (props: any) => {
  const { cancel, back, classes, drugs = [], patientId, medSync } = props;
  const [drugCopyFrom, setDrugCopyFrom] = useState(drugs?.[0]);
  const [tabs, setTabs] = useState([
    ...drugs.map((drug: any) => ({
      id: drug.id,
      label: drug.drug_name,
    })),
    ADD_TAB,
  ]);
  const dispatch = useDispatch();
  const tabsRef = useRef<Handler>(null);

  useEffect(() => {
    if (!drugCopyFrom && drugs?.length) {
      setDrugCopyFrom(drugs[0]);
    }
  }, [drugs]);

  const getDrugById = (id: number) => drugs.find((drug: any) => drug.id === id) || {};
  const handleCloseTab = (clonsingTab: Tab) => {
    const tabsIds = tabs.map(tab => tab.id);
    const idx = tabsIds.indexOf(clonsingTab.id);
    const newTabs = [...tabs.filter((tab: Tab) => tab.id !== clonsingTab.id)];

    setTabs(newTabs);

    // the ADD tab will still remain
    if (newTabs.length === 1) {
      cancel();
    }

    const newSelectedTab = newTabs[idx];
    if (newSelectedTab?.id === ADD_TAB_ID) {
      tabsRef.current?.setSelectedTab(idx - 1);
    }
  };

  const handleValueChange = (drug: any, field: string, value: string) => {
    if (drug.id === drugCopyFrom?.id) {
      drugs
        .filter((d: any) => drug.id !== d.id)
        .forEach((d: any) => {
          const formId = `${ADD_PATIENT_THERAPY_FORM}-${d.id}`;
          const form = store.getState().form[formId];
          const formField = form.fields && form.fields[field];
          if (!formField || !formField.touched) {
            dispatch(change(formId, field, value));
          }
        });
    }
  };

  const initialTherapyValues = {
    rxTypeId: `${THERAPY_MEDICATION_STATUS_EXISTING}`,
    leadSourceId: THERAPY_LEAD_SOURCE_EMR_LEAD,
    dispensing_status: DISPENSING_STATUSES.OPT_IN,
  };

  const getNewMedTab = () => ({
    id: uniqueId(ADD_TAB_ID),
    label: `New medication #${tabs.length - drugs.length}`,
  });

  const handleTabChange = (tab: Tab) => {
    if (tab.id !== ADD_TAB_ID) {
      return;
    }

    const newTabs = [...tabs.filter(tab => tab.id !== ADD_TAB_ID), getNewMedTab(), ADD_TAB];

    setTabs(newTabs);
    tabsRef.current?.setSelectedTab(newTabs.length - 2);
  };

  return (
    <div className={classes.nextStep}>
      <MedsTabs
        tabs={tabs}
        ref={tabsRef}
        onTabClose={handleCloseTab}
        onTabChange={handleTabChange}
        tabPropsGetter={(tab: any) => ({
          selectedDrug: getDrugById(tab.id),
          id: tab.id,
          disableTherapyInput: !String(tab.id).startsWith(ADD_TAB_ID),
          handleCloseAddForm: () => handleCloseTab(tab),
          initialTherapyValues: {
            ...initialTherapyValues,
            initialTherapyName: getDrugById(tab.id).drug_name,
          },
        })}
        instructions={
          tabs.length > 2 ? (
            <>
              <DrugCopySelector drugs={drugs} onChange={setDrugCopyFrom} />
            </>
          ) : null
        }
      >
        <AddPatientTherapy
          medsEnrollment
          ancillaryDrug={false}
          patientId={patientId}
          onValueChange={handleValueChange}
          back={back}
          medSync={medSync}
        />
      </MedsTabs>
    </div>
  );
};
export default withStyles(styles)(MedsFinalForm);

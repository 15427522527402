import momenttz from 'moment-timezone';
import moment from 'moment-business-days';
import {
  DATABASE_DATE_FORMAT,
  DATABASE_DATETIME_FORMAT_WITHOUT_SECONDS,
  DATABASE_DATETIME_FORMAT,
} from '../../constants';

export const getTodayDateTime = () => moment().format(DATABASE_DATETIME_FORMAT_WITHOUT_SECONDS);

// Functions below are deprecated
const zone = momenttz.tz.zone(moment.tz.guess());

// eslint-disable-next-line no-confusing-arrow
const localToUtc = formDate =>
  moment.isMoment(formDate)
    ? moment
        .utc(momenttz.tz(formDate.format(), DATABASE_DATETIME_FORMAT, zone.name).format())
        .format('YYYY-MM-DDTHH:mm:ss')
    : moment
        .utc(momenttz.tz(moment(formDate).format(), DATABASE_DATETIME_FORMAT, zone.name).format())
        .format('YYYY-MM-DDTHH:mm:ss');

export const toDbDateMomentFormat = date => moment(date).format(DATABASE_DATE_FORMAT);

export const toUiDateTimeFormat = date => {
  if (date) {
    return moment(date).format('M/D/YYYY, hh:mm A');
  }
  return null;
};

const toDbDateTimeFormat = date => moment(date).format(DATABASE_DATETIME_FORMAT_WITHOUT_SECONDS);

export const getStartOfDateForDb = date => {
  if (!date) return null;
  return toDbDateTimeFormat(localToUtc(moment(date).startOf('day')));
};

export const getEndOfDateForDb = date => {
  if (!date) return null;
  return toDbDateTimeFormat(localToUtc(moment(date).endOf('day')));
};

export const convert12HourTo24HourNumber = time => {
  if (time) {
    if (time.toLowerCase().includes('am')) {
      const amTime = Number(time.replace(/\D/g, ''));
      const hour = Number(time.split(':')[0]);
      if (hour !== 12) {
        return amTime;
      }
      return amTime - 1200;
    }
    if (time.toLowerCase().includes('pm')) {
      const pmTime = Number(time.replace(/\D/g, ''));
      const hour = Number(time.split(':')[0]);
      if (hour !== 12) {
        return 1200 + pmTime;
      }
      return pmTime;
    }
    return Number(time.replace(/\D/g, ''));
  }
  return null;
};

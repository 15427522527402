import { AxiosResponse } from 'axios';
import { IPatientVaccination } from 'interfaces/redux/IPatient';
import HTTP from '../http';

export interface ISavePatientVaccinationRequest {
  data: {
    vaccine_id: number;
    type: number;
    date_received: string;
    effective_for: string;
    source: string | null;
  };
}

interface ISavePatientVaccinationResponse {
  patientVaccine: IPatientVaccination;
}

class PatientVaccinationsService {
  private getBaseUrlByPatientId(patientId: number): string {
    return `patients/${patientId}/vaccines`;
  }

  async saveVaccine(
    patientId: number,
    patientVaccination: ISavePatientVaccinationRequest,
  ): Promise<IPatientVaccination> {
    if (!patientVaccination.data.source) {
      patientVaccination.data.source = null;
    }

    const response = (await HTTP.post(
      this.getBaseUrlByPatientId(patientId),
      patientVaccination,
    )) as AxiosResponse<ISavePatientVaccinationResponse>;

    return response.data.patientVaccine;
  }
}

export { PatientVaccinationsService };

import React, { useState } from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: 10,
  },
  option: {
    '&:focus': {
      borderColor: theme.palette.primary.teal,
      outline: 'none',
    },
    'marginBottom': 16,
    'padding': 8,
    'borderColor': theme.palette.primary.grey12,
    'border': '1px solid',
    'borderRadius': 6,
    'background': 'transparent',
    'width': '100%',
    'fontSize': 14,
    'textAlign': 'left',
    'display': 'inline-flex',
    'alignItems': 'center',
    'cursor': 'pointer',
    'maxWidth': 400,
    [`${theme.breakpoints.down('xl')} and (orientation: landscape)`]: {
      maxWidth: '46%',
      marginLeft: '2%',
      marginRight: '2%',
      marginBottom: 6,
    },
  },
  shrink: {
    '@media (max-height : 900px) and (orientation: portrait)': {
      marginBottom: 5,
    },
  },
  optionIcon: {
    'width': 30,
    'minWidth': 30,
    'height': 30,
    'display': 'inline-block',
    'borderColor': theme.palette.primary.grey12,
    'border': '1px solid',
    'borderRadius': 30,
    'marginRight': 10,
    '& svg': {
      display: 'none',
    },
  },
  selected: {
    borderColor: theme.palette.primary.teal,
    backgroundColor: theme.palette.primary.patinaWhite,
  },
  selectedIcon: {
    'borderColor': theme.palette.primary.teal,
    'border': '1px solid',
    'backgroundColor': theme.palette.primary.teal,
    '& svg': {
      display: 'block',
      margin: 4,
      color: theme.palette.primary.white,
    },
  },
}));

export default ({ options, onChange, initialValue, questionNumber, shrink }) => {
  const [value, setValue] = useState(initialValue);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {(options || []).map(option => (
        <button
          type="button"
          key={option.value}
          className={cx(
            classes.option,
            String(value) === String(option.value) && classes.selected,
            shrink ? classes.shrink : null,
          )}
          onClick={() => {
            setValue(option.value);
            onChange(option.value);
          }}
          data-qa-id={`question_${questionNumber}_value_${option.value}`}
        >
          <span
            className={cx(
              classes.optionIcon,
              String(value) === String(option.value) && classes.selectedIcon,
            )}
          >
            <CheckIcon />
          </span>
          {option.label}
        </button>
      ))}
    </div>
  );
};

import React from 'react';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { NumericFormat } from 'react-number-format';
import { styles } from './field-styles';

const MoneyField = ({ fieldName, field, defaultValue, suffix, classes, renderDetailFieldSize }) => (
  <div>
    <Typography
      variant={renderDetailFieldSize ? 'caption' : ''}
      className={renderDetailFieldSize ? classes.detailHeader : classes.fieldLabel}
    >
      {fieldName}
    </Typography>
    <Typography
      className={renderDetailFieldSize ? '' : classes.fieldValue}
      variant={renderDetailFieldSize ? 'body2' : ''}
    >
      {field && Number(field) > 0 ? (
        <NumericFormat
          thousandSeparator
          variant="standard"
          prefix={field && Number(field) > 0 ? '$' : ''}
          displayType="text"
          suffix={suffix ? `/${suffix.toLowerCase()}` : ''}
          value={field}
        />
      ) : (
        defaultValue
      )}
    </Typography>
  </div>
);

export default withStyles(styles, { withTheme: true })(MoneyField);

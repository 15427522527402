import { Grid, Modal } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';
import React, { Component } from 'react';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { accordianStyles } from 'components/accordian/accordian-styles';
import { combineStyles, getModalStyle } from 'services/utils/styles-service';
import AccordionHeader from 'components/form/header/accordion-header';
import DocumentDisplay from 'components/document/document-display';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SmallSpacer, LargeSpacer } from 'components/spacer/spacer';
import { editPbmInsurance, fetchPbmInsurances } from 'actions/action-financials';
import History from 'containers/common/history';
import { getPharmacyInsuranceHeader } from 'services/utils/financials-service';
import { getDocumentsForResource } from 'services/utils/document-service';
import { convertToArborDate } from 'models/time/arbor-date';
import { VerificationPanel } from 'components/form/verification/verification-panel';
import TalkdeskPhoneNumber from 'components/talkdesk-phone-number/talkdesk-phone-number';
import { styles } from '../financials-styles';
import DetailField from '../../../../components/form/field/field';
import EditPbmInsurance from './edit-pbm-insurance';

import { PBM_INSURANCE, EDIT_PBM_INSURANCE_FORM } from '../../../../constants';
import { noteTagTypes } from '../../../../constants/lists';

const tagType = noteTagTypes.find(tag => tag.label === PBM_INSURANCE);

class PbmDetailOld extends Component {
  constructor(props) {
    super(props);
    const { pbmInsurance } = this.props;
    this.state = {
      displayDetail: pbmInsurance.end_date === null,
      displayEdit: false,
      editHistoryModalOpen: false,
      verified: pbmInsurance.is_verified,
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.handleEditClose = this.handleEditClose.bind(this);
    this.handleEditHistoryModal = this.handleEditHistoryModal.bind(this);
    this.handleVerify = this.handleVerify.bind(this);
    this.handleExpand = this.handleExpand.bind(this);
  }

  handleEdit(e) {
    e.stopPropagation();
    this.setState({
      displayEdit: true,
    });
  }

  handleEditClose(verified) {
    this.setState({
      displayEdit: false,
      verified,
    });
  }

  handleExpand() {
    const { displayDetail, editHistoryModalOpen } = this.state;
    if (!editHistoryModalOpen) {
      this.setState({
        displayDetail: !displayDetail,
      });
    }
  }

  handleEditHistoryModal(e) {
    e.stopPropagation();
    this.setState(prevState => ({
      editHistoryModalOpen: !prevState.editHistoryModalOpen,
    }));
  }

  handleVerify() {
    const {
      pbmInsurance,
      editPbmInsurance, //eslint-disable-line
      fetchPbmInsurances, //eslint-disable-line
    } = this.props;
    const payload = {
      ...pbmInsurance,
      start_date: pbmInsurance.start_date
        ? convertToArborDate(pbmInsurance.start_date).getUtcDate()
        : null,
      end_date: pbmInsurance.end_date
        ? convertToArborDate(pbmInsurance.end_date).getUtcDate()
        : null,
      is_verified: 1, // set is_verified to 1
      verified_dt: convertToArborDate(moment()).getUtcDatetime(),
    };
    this.setState({
      verified: 1,
    });
    editPbmInsurance(payload).then(() => {
      fetchPbmInsurances(pbmInsurance.patient_id);
    });
  }

  displayDetail() {
    const { classes, pbmInsurance } = this.props;
    const { verified } = this.state;
    const isNoInsurance = Boolean(pbmInsurance.is_no_insurance);
    if (isNoInsurance) {
      return (
        <div className={classes.containerDiv}>
          <Grid container>
            <Grid item xs={12}>
              <VerificationPanel
                type="pbm"
                isVerified={verified}
                handleVerify={this.handleVerify}
              />
            </Grid>
          </Grid>
        </div>
      );
    }
    return (
      <div className={classes.containerDiv}>
        <Grid container alignItems="flex-end">
          <Grid item xs={3}>
            <DetailField fieldName="PBM Name" field={pbmInsurance.pbm_name} />
          </Grid>
          <Grid item xs={3}>
            <TalkdeskPhoneNumber
              number={pbmInsurance.pbm_phone_number}
              headerText="PBM Phone Number"
              tagInfo={{
                tagTypeId: tagType.value,
                tagResourceId: pbmInsurance.id,
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Patient ID Number"
              field={pbmInsurance.pbm_patient_id}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField fieldName="BIN Number" field={pbmInsurance.pbm_bin_number} />
          </Grid>
        </Grid>
        <SmallSpacer />
        <Grid container alignItems="flex-end">
          <Grid item xs={3}>
            <DetailField
              fieldName="RX Group Number"
              field={pbmInsurance.pbm_rx_group_number}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Processor Control Number"
              field={pbmInsurance.pbm_processor_control_number}
              variant="raw"
            />
          </Grid>
        </Grid>
        <LargeSpacer />
        <Grid container>
          <Grid item xs={12}>
            <VerificationPanel type="pbm" isVerified={verified} handleVerify={this.handleVerify} />
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    const { classes, pbmInsurance, uploadedDocuments } = this.props;
    const { displayEdit, displayDetail, editHistoryModalOpen } = this.state;

    const getDocuments = (resourceId, tagTypeId) =>
      getDocumentsForResource(uploadedDocuments.documents, [{ resourceId, tagTypeId }], 1);

    const getHistoryURL = (patientId, id) =>
      `/patients/${patientId}/financials/patient-pbm-insurances/${id}`;
    const isNoInsurance = Boolean(pbmInsurance.is_no_insurance);

    return (
      <div>
        <Accordion expanded={!!displayDetail} className={classes.accordianPanel}>
          <AccordionSummary
            classes={{
              expandIconWrapper: classes.expandIcon,
            }}
            onClick={this.handleExpand}
            expandIcon={<ExpandMoreIcon />}
          >
            <AccordionHeader
              name={getPharmacyInsuranceHeader(pbmInsurance, isNoInsurance)}
              updatedDt={pbmInsurance.updated}
              updatedBy={pbmInsurance.updated_by_user}
              editHandler={this.handleEdit}
              withHistory
              historyHandler={this.handleEditHistoryModal}
              id={`${pbmInsurance.pbm_name} (${convertToArborDate(
                pbmInsurance.start_date,
                true,
              ).getCustomerDate(true)} -
              ${
                pbmInsurance.end_date === null
                  ? 'Present'
                  : convertToArborDate(pbmInsurance.end_date, true).getCustomerDate(true)
              })`}
            />
            <Modal open={editHistoryModalOpen} onClose={this.handleEditHistoryModal}>
              <div style={getModalStyle()} className={classes.Modal}>
                <History url={getHistoryURL(pbmInsurance.patient_id, pbmInsurance.id)} />
              </div>
            </Modal>
          </AccordionSummary>
          <AccordionDetails>
            {!displayEdit ? (
              <Grid container direction="column">
                <Grid container justifyContent="flex-end">
                  <Grid item xs={3}>
                    <DocumentDisplay
                      containerClass={classes.innerDocumentContainer}
                      taskIdResourceIds={[
                        {
                          tagTypeId: tagType.value,
                          resourceId: pbmInsurance.id,
                        },
                      ]}
                      tagTypeId={tagType.value}
                      resourceId={pbmInsurance.id}
                      drugName={PBM_INSURANCE}
                      tagTypeLabel={PBM_INSURANCE}
                      documents={getDocuments(pbmInsurance.id, tagType.value)}
                    />
                  </Grid>
                </Grid>
                <Grid item>{this.displayDetail()}</Grid>
              </Grid>
            ) : (
              <EditPbmInsurance
                pbmInsurance={pbmInsurance}
                cancel={this.handleEditClose}
                form={`${EDIT_PBM_INSURANCE_FORM}_${pbmInsurance.id}`}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <SmallSpacer />
      </div>
    );
  }
}

const combinedStyles = combineStyles(styles, accordianStyles);

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPbmInsurances,
      editPbmInsurance,
    },
    dispatch,
  );
}

function mapStateToProps(state) {
  const { uploadedDocuments } = state;

  return {
    uploadedDocuments,
  };
}

export default compose(
  withStyles(combinedStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(PbmDetailOld);

// import debounce from 'debounce-promise';
import moment from 'moment';
import { isEmpty } from 'lodash';

// import { DEBOUNCE_IN_MS } from 'constants/config';
import HTTP from '../http';

function invoiceSearchDebounced(body) {
  return HTTP.post('/invoicing', body);
}

export const getPreviousInvoices = () => HTTP.get('/invoicing/previous-invoices', {});

export const invoicingSearch = (
  columns,
  columnFilters,
  source,
  pageNumber,
  pageSize,
  searchTerm,
  year = moment().subtract(1, 'month').year(),
  month = moment().subtract(1, 'month').month() + 1,
) => {
  const data = {
    columns: columns.map(o => o.name),
    source,
    pageNumber,
    pageSize,
    year,
    month,
  };

  if (searchTerm) {
    data.searchTerm = searchTerm;
  }

  if (!isEmpty(columnFilters)) {
    data.columnFilters = columnFilters;
  }

  return invoiceSearchDebounced(data);
};

export const getInvoicingMetadata = (
  currentPage,
  year = moment().subtract(1, 'month').year(),
  month = moment().subtract(1, 'month').month() + 1,
) => HTTP.get(`/invoicing/meta?year=${year}&month=${month}&sourceType=${currentPage}`, {});

export const getInvoicingSummary = payload => HTTP.post('/invoicing/summary', payload, {});
export const getInvoicingSummaryAdjustments = payload =>
  HTTP.post('/invoicing/summary-adjustments', payload, {});
export const getInvoicingRecordHistory = (
  source,
  columns,
  id,
  year = moment().subtract(1, 'month').year(),
  month = moment().subtract(1, 'month').month() + 1,
) =>
  HTTP.get(
    // eslint-disable-next-line max-len
    `/invoicing/record-history?year=${year}&month=${month}&source=${source}&id=${id}&columns=${columns}`,
    {},
  );

export const getInvoicingStage = async () => HTTP.get('/invoicing/stage');

export const getDownloadPreviousInvoice = async (year, month) =>
  HTTP.get(`/invoicing/previous-invoice-url?year=${year}&month=${month}`, {});

export const getInvoicingDownloadUrl = async params => {
  const {
    year = moment().subtract(1, 'month').format('yyyy'),
    month = moment().subtract(1, 'month').format('MM'),
    stage,
    source,
  } = params;
  const url = `/invoicing/url?year=${year}&month=${month}&stage=${stage}&source=${source}`;
  return HTTP.get(url, {});
};

export const changeInvoiceStage = async payload => {
  const url = '/invoicing/stage';
  return HTTP.post(url, payload, {});
};

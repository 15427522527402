import { useState } from 'react';
import uuidV4 from 'uuid/v4';

/**
 * Generate 16-byte uuid that can be used in a component and doesn't change upon re-rendering
 *
 * @param {(number)} chars The amount of characters between 1 and 36 to be returned
 */
export default (chars = 36) => {
  const [value] = useState(uuidV4().substr(0, chars));
  return value;
};

export const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  addFormContainer: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: 5,
    padding: 15,
    margin: '35px 30px 30px 30px',
  },
  noBorderFormContainer: {
    padding: theme.spacing(3),
  },
  formRowContainer: {
    marginBottom: 15,
  },
  npiLookupButtonContainer: {
    position: 'relative',
  },
  npiLookupButton: {
    position: 'absolute',
    right: 4,
    bottom: 28,
  },
  subtitle: {
    fontSize: '12px',
    fontWeight: '300',
    color: theme.palette.primary.formLabel,
    marginTop: 5,
    marginBottom: -15,
  },
  addTertiaryDiagnosis: {
    fontSize: theme.font.smallFontSize,
    paddingTop: 2,
  },
  medSyncLabel: {
    fontWeight: 'bold',
    marginTop: '10px',
    marginBottom: '10px',
  },
  medSyncIconStyle: {
    width: 35,
    height: 35,
    marginRight: 10,
    float: 'left',
  },
  medSyncInfo: {
    fontSize: theme.font.smallFontSize,
  },
  icdValidatorIcon: {
    float: 'left',
    position: 'relative',
    top: theme.spacing(-7),
    paddingLeft: theme.spacing(10),
    cursor: 'pointer',
    color: theme.palette.primary.valenciaRed,
  },
  icdValidator: {
    '& div, span': {
      color: theme.palette.primary.valenciaRed,
    },
  },
  icdValidatorText: {
    color: theme.palette.primary.black,
    fontWeight: '500',
  },
  dialogActions: {
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  radioButtonContainer: {
    paddingBottom: theme.spacing(2),
  },
  dynamicContainer: {
    marginTop: theme.spacing(5),
  },
  title: {
    verticalAlign: 'text-middle',
    fontSize: 16,
    fontWeight: 400,
  },
  emrStatus: {
    '& > div > div > div > div > div': {
      marginBottom: theme.spacing(-1.3),
    },
  },
  accordionStyle: {
    backgroundColor: theme.palette.primary.grey14,
    fontWeight: 400,
    borderRadius: '0px !important',
  },
  accordionDetailStyle: {
    backgroundColor: theme.palette.primary.white,
  },
});

import React from 'react';
import { Grid, Typography, PropTypes } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { YesNoFormStyles } from './yes-no-form.styles';

interface IYesNoFormProps extends WithStyles<typeof YesNoFormStyles> {
  initialValue: boolean;
  handleCancel: () => void;
  handleSubmit: (value: boolean) => void;
  options: { optionLeft: string; optionRight: string; align: any };
}

/** This isn't really a "form" per se, but it's still rendered in a popout thing.  */
const YesNoForm: React.FC<IYesNoFormProps> = (props: IYesNoFormProps): JSX.Element => {
  const { classes } = props;

  // #endregion

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid
        item
        className={classes.option}
        onClick={() => {
          props.handleSubmit(true);
        }}
      >
        <Typography align={props.options.align} className={classes.optionType}>
          {props.options.optionLeft}
        </Typography>
      </Grid>
      <Grid
        item
        className={classes.option}
        onClick={() => {
          props.handleSubmit(false);
        }}
      >
        <Typography align={props.options.align} className={classes.optionType}>
          {props.options.optionRight}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(YesNoFormStyles)(YesNoForm);

import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from '../../utils/theme-util';

const styles = (theme: Theme) => {
  const { palette, fonts } = expandTheme(theme);

  const sharedHeight = {
    minHeight: 42,
  };

  return createStyles({
    titleBar: {
      backgroundColor: palette.primary.grey14,
      padding: '3px 10px 3px 18px',
      ...sharedHeight,
    },
    titleContainer: {
      ...sharedHeight,
    },
    title: {
      fontSize: fonts.largeFontSize,
      fontWeight: 'bold',
      color: palette.primary.steelGrey,
    },
    iconWrapper: {
      textAlign: 'right',
    },
  });
};

export { styles };

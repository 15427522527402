export const contactNamePath = 'p[data-qa-id="preferred-contact-name-name"]';
export const secondaryLineEhrPath = 'p[data-qa-id="preferred-contact-ehr-secondary-line"]';
export const secondaryLinePhonePath = 'span[data-qa-id="preferred-contact-phone-availability"]';
export const availabilityPath = 'span[data-qa-id="preferred-contact-phone-availability"]';
export const interpreterIconPath = 'span[data-qa-id="need-interpreter-icon-typography"]';
export const smsIconPath = 'span[data-qa-id="preferred-contact-phone-sms-icon"]';
export const methodNotePath = '*[data-qa-id="preferred-contact-method-note-icon"]';

export const ehrWrapper = 'div[data-qa-id="preferred-contact-ehr-wrapper"]';
export const phoneWrapper = 'div[data-qa-id="preferred-contact-phone-wrapper"]';
export const emailWrapper = 'div[data-qa-id="preferred-contact-email-wrapper"]';
export const ACCOUNT_NA = 'Acc - n/a';
export const PIN_NA = 'Pin - n/a';

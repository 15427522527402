import React from 'react';
import cx from 'classnames';

type Props = {
  formValues: any;
  classes: any;
};

const DeliveryIntegrationWarning: React.FC<Props> = (props): JSX.Element | null => {
  const { formValues, classes } = props;
  const showCourierIntegrationWarning =
    formValues?.preferred_rx_delivery_method?.value === 3 &&
    formValues?.dispensing_pharmacy?.is_courier_integrated === 0;
  const showUpsIntegrationWarning =
    formValues?.preferred_rx_delivery_method?.value === 2 &&
    formValues?.dispensing_pharmacy?.is_ups_integrated === 0;
  let warningMessage = null;
  if (showCourierIntegrationWarning) {
    warningMessage = 'The selected dispensing pharmacy is not setup for courier integration';
  } else if (showUpsIntegrationWarning) {
    warningMessage = 'The selected dispensing pharmacy is not setup for UPS integration';
  }

  if (warningMessage) {
    return (
      <div style={{ marginTop: '6px' }} className={classes[0]}>
        <span className={classes[1]}>{warningMessage}</span>
      </div>
    );
  }
  return null;
};

export default DeliveryIntegrationWarning;

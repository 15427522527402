import { IState } from 'interfaces/redux/IState';
import { useSelector } from 'react-redux';

/**
 * useContactOptions returns a list of options that looks like:
 * [
 *  {label: "Patient", value: -1},
 *  {label: "Contact - (Relationship) - First1 Last 1", value: 1},
 *  {label: "Contact - (Relationship) - First2 Last2", value: 2},
 * ]
 * The 'valueIfPatientSelected' here is used to identify which option is the patient option.
 * The non-patient options, their value is the id of the contact from the redux state.
 */

const valueIfPatientSelected = -1;

const useContactOptions = (): { label: string; value: number }[] => {
  const contactListState = useSelector((state: IState) => state.contactList);
  const personalOption = [{ label: 'Personal', value: valueIfPatientSelected }];
  const contactOptions = Object.keys(contactListState)
    .filter(key => {
      return !contactListState[key as any].declined_to_provide_emergency_contact;
    })
    .map(key => {
      const { relationship, first_name, last_name } = contactListState[key as any];

      return {
        label: `Contact - (${relationship}) - ${first_name || ''} ${last_name || ''}`,
        value: Number(key),
      };
    });

  return [...personalOption, ...contactOptions];
};

export { useContactOptions, valueIfPatientSelected };

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from '../../utils/theme-util';

export const styles = (theme: Theme) => {
  const { palette, typography, fonts } = expandTheme(theme);

  return createStyles({
    editingWrapper: {
      border: `1px solid ${palette.primary.grey13}`,
      padding: 15,
      borderRadius: 3,
      fontFamily: typography.fontFamily,
      fontSize: fonts.mediumFontSize,
    },
    readOnlyWrapper: {
      padding: 15,
      fontFamily: typography.fontFamily,
      fontSize: fonts.mediumFontSize,
    },
    smallButton: {
      minWidth: theme.spacing(2),
    },
    featureButtonWrapper: {
      padding: 0,
      marginBottom: 20,
      borderBottom: `1px solid ${palette.primary.grey3}`,
    },
    cancelButton: {
      marginRight: theme.spacing(1),
    },
    buttonWrapper: {
      paddingTop: theme.spacing(1),
    },
    activeIcon: {
      color: palette.primary.trellisBlue,
    },
    activeButton: {
      backgroundColor: palette.primary.grey13,
    },
    functionButton: {
      marginRight: theme.spacing(1),
      marginBottom: 10,
    },
  });
};

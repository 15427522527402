import { Grid, Typography } from '@mui/material';
import { convertToArborDate } from 'models/time/arbor-date';
import React from 'react';
import { IProblem } from 'components/patient-merge-modal/interfaces';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { useTheme } from '@mui/styles';

const ClinicalProblemSection = ({ problemName, problemUpdatedAt, isProblemActive }: IProblem) => {
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);

  const problemUpdatedAtFormatted = problemUpdatedAt
    ? convertToArborDate(problemUpdatedAt).getUtcDate(true)
    : 'Unknown';
  const isProblemActiveFormatted = isProblemActive ? 'Active' : 'Inactive';

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <Typography sx={classes.checkboxTitle}>
          {`${problemName} (${problemUpdatedAtFormatted}) - ${isProblemActiveFormatted}`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ClinicalProblemSection;

import React, { useRef, useState } from 'react';
import { IconButton, Tooltip, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import FlagIcon from '@mui/icons-material/Flag';
import { get } from 'lodash';
import { INVOICING_STAGES } from 'constants/index';
import { FLAG_EDITABLE_TYPE } from '../constants';
import { parseValue } from '../utils';

import EditablePopup from '../popups/editable/editable';
import FlaggedPopup from '../popups/flagged/flagged';

import useStyles from './styles';

const flagIconClass = (value, flaggedIconLightRed, flaggedIconTeal, flaggedIconLightGrey) => {
  switch (value) {
    case '1':
      return flaggedIconLightRed;
    case '2':
      return flaggedIconTeal;
    default:
      return flaggedIconLightGrey;
  }
};

function CellRender({ value, row, column, cellUpdate: onSave, source, invoicingStage }) {
  const {
    invoicingCellTextContent,
    invoicingCenteredItems,
    flaggedIconLightRed,
    flaggedIconTeal,
    flaggedIconLightGrey,
    invoicingEditButton,
  } = useStyles();
  const values = get(column.raw, 'values', null);
  const [anchorCellEl, setAnchorCellEl] = useState();
  const [anchorFlaggedCellEl, setAnchorFlaggedCellEl] = useState();
  const [selectedValue, setSelectedValue] = useState(value);
  const [actionComment, setActionComment] = useState();
  const [showEditIcon, setShowEditIcon] = useState();
  const divRef = useRef();

  const handleShowEditablePopup = () => {
    setAnchorCellEl(divRef.current);
  };

  const handleOnClose = () => {
    setAnchorCellEl(null);
  };

  const handleOnCloseFlagged = () => {
    setAnchorFlaggedCellEl(null);
  };

  const handleSelectedValueOnChange = ({ target }) => {
    setSelectedValue(target.value);
  };
  const handleActionCommentOnChange = ({ target }) => {
    setActionComment(target.value);
  };
  const handleOnSave = () => {
    onSave(row, column, selectedValue, actionComment, source);
    setAnchorCellEl(null);
  };

  const handleOnClickFlag = event => {
    setAnchorFlaggedCellEl(event.currentTarget);
  };

  const open = Boolean(anchorCellEl);
  const openFlagged = Boolean(anchorFlaggedCellEl);

  if (column.raw.editableType === FLAG_EDITABLE_TYPE) {
    return (
      <>
        {!row.isSubRow && (
          <IconButton size="small" onClick={handleOnClickFlag}>
            <FlagIcon
              className={flagIconClass(
                value,
                flaggedIconLightRed,
                flaggedIconTeal,
                flaggedIconLightGrey,
              )}
            />
          </IconButton>
        )}
        {anchorFlaggedCellEl && row.original.Comment && (
          <FlaggedPopup
            comment={row.original.Comment}
            anchorEl={anchorFlaggedCellEl}
            onClick={e => {
              e.stopPropagation();
            }}
            onClose={handleOnCloseFlagged}
            open={openFlagged}
            id="flagged-popover"
          />
        )}
      </>
    );
  }
  const handleShowEditIcon = show => {
    setShowEditIcon(show);
  };

  if (column.raw.editableType) {
    const cellContent = parseValue(value, column.raw.editableType, column.raw.format, values);

    return (
      <>
        <div
          className={invoicingCenteredItems}
          onMouseEnter={() => handleShowEditIcon(true)}
          onMouseLeave={() => handleShowEditIcon(false)}
          onFocus={() => undefined}
        >
          <Tooltip title={cellContent}>
            <div className={invoicingCellTextContent} ref={divRef}>
              {cellContent}
            </div>
          </Tooltip>
          {!row.isSubRow &&
            showEditIcon &&
            invoicingStage !== INVOICING_STAGES.FINALIZED &&
            invoicingStage !== INVOICING_STAGES.VALIDATED && (
              <IconButton
                className={invoicingEditButton}
                onClick={handleShowEditablePopup}
                size="large"
              >
                <EditIcon />
              </IconButton>
            )}
        </div>
        {anchorCellEl && column.raw.isEditable && (
          <EditablePopup
            values={values}
            isEditable={column.raw.isEditable}
            editableType={column.raw.editableType}
            dataType={column.raw.dataType}
            onSelectedValueChange={handleSelectedValueOnChange}
            selectedValue={selectedValue}
            actionComment={actionComment}
            onActionCommentChange={handleActionCommentOnChange}
            headerTitle={column.raw.label}
            anchorEl={anchorCellEl}
            onClick={e => {
              e.stopPropagation();
            }}
            onClose={handleOnClose}
            onSave={handleOnSave}
            open={open}
            id="editable-popover"
          />
        )}
      </>
    );
  }
  const valueParsed = parseValue(value, column.raw.dataType, column.raw.format, null);
  return (
    <Tooltip title={valueParsed}>
      <Box
        className={invoicingCellTextContent}
        ref={divRef}
        textAlign={column.raw.dataType !== 'string' && 'end'}
      >
        {valueParsed}
      </Box>
    </Tooltip>
  );
}

export default CellRender;

import React from 'react';

import { Controller } from 'react-hook-form';

import { renderRadioGroup as RenderRadioGroup } from 'components/form/field/redux-field';

import { IControlledFormField } from '../types';

interface IProps<TValue> extends IControlledFormField {
  options: { label: string; value: TValue }[];
  defaultValue: TValue | null;
}

export const ControlledRadioGroup = <TValue extends string | number>(
  props: IProps<TValue>,
): JSX.Element => {
  return (
    <Controller
      defaultValue={props.defaultValue}
      control={props.control}
      name={props.name}
      rules={props.validations || {}}
      render={(ctrlProps: any) => (
        <RenderRadioGroup
          label={props.label}
          radioMap={props.options}
          input={{
            value: ctrlProps?.field?.value,
            onChange: (event: React.FormEvent<HTMLInputElement>) => {
              const { value } = event.currentTarget;
              /**
               * RenderRadioGroup converts any passed option value as string even if it was passed as a number
               * putting back to numeric value if is possible in favor of consistency
               */
              ctrlProps?.field?.onChange(!Number.isNaN(Number(value)) ? Number(value) : value);
            },
            onBlur: ctrlProps?.field?.onBlur,
          }}
          disabled={props.disabled}
          meta={props.inputMetaData || {}}
          qaId={props.qaId}
        />
      )}
    />
  );
};

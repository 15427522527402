import { useSelector } from 'react-redux';

export const useFillCycleNumber = (therapyId, fillCycleNumber) => {
  const fillCycles = useSelector(state => state.fillCycles);
  if (fillCycleNumber && fillCycles[therapyId] && fillCycles[therapyId][fillCycleNumber]) {
    const cycle =
      fillCycleNumber && fillCycles[therapyId] && fillCycles[therapyId][fillCycleNumber];
    return [
      cycle.needsby_date || null,
      cycle.is_needsby_per_protocol || null,
      cycle.days_supply || null,
    ];
  }
  return [null, 0, 0];
};

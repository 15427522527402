import HTTP from 'services/http';
import { AxiosPromise } from 'axios';
import { IZHIProgramDetail } from '../models/mmit/IZHIProgramDetail';

export class MMITService {
  static getProgramById = async (
    programId: number,
  ): Promise<IZHIProgramDetail> => {
    const url = `/mmit/${programId}`;
    const promiseResult = HTTP.get(url) as AxiosPromise<IZHIProgramDetail>;
    const response = await promiseResult;
    return response.data;
  };
}

import { appointmentReportIds, supportedTasksTabTypes } from 'constants/lists';
import queryString from 'query-string';
import { THERAPIES, REPORT_SIDEBAR, TASK_SIDEBAR, PATIENTS } from '../constants';

export const PATIENT_ID = 'PATIENT_ID';
export const TASK_ID = 'TASK_ID';
export const THERAPY_ID = 'THERAPY_ID';
export const SELECTED_TAB = 'SELECTED_TAB';
export const PAGE_ID = 'PAGE_ID';
export const TASK_TYPE = 'TASK_TYPE';

const parsers = {
  [PAGE_ID]: {
    regex: new RegExp('/customers/[^/]+/([^/]+)/?.*', 'i'),
    index: 0,
  },
  [PATIENT_ID]: {
    regex: new RegExp('/customers/[^/]+/patients/([^/]+)/?.*', 'i'),
    index: 0,
  },
  [TASK_ID]: {
    regex: [
      new RegExp('/customers/[^/]+/patients/[^/]+/therapies/[^/]+/[^/]+/([^/]+)/?.*', 'i'),
      new RegExp('/customers/[^/]+/patients/[^/]+/checklist/[^/]+/([^/]+)/?.*', 'i'),
      new RegExp('/customers/[^/]+/patients/[^/]+/tasks/[^/]+/([^/]+)/?.*', 'i'),
    ],
    index: 0,
  },
  [TASK_TYPE]: {
    regex: [
      new RegExp('/customers/[^/]+/patients/[^/]+/therapies/[^/]+/([^/]+)/[^/]+/?.*', 'i'),
      new RegExp('/customers/[^/]+/patients/[^/]+/checklist/([^/]+)/[^/]+/?.*', 'i'),
      new RegExp('/customers/[^/]+/patients/[^/]+/tasks/([^/]+)/[^/]+/?.*', 'i'),
    ],
    index: 0,
  },
  [THERAPY_ID]: {
    regex: [
      new RegExp('/customers/[^/]+/patients/[^/]+/therapies/([^/]+)/?.*', 'i'),
      new RegExp('/customers/[^/]+/patients/[^/]+/clinical/therapies/([^/]+)/?.*', 'i'),
    ],
    index: 0,
  },
  [SELECTED_TAB]: {
    regex: new RegExp('/customers/[^/]+/patients/[^/]+/([^/]+)/?.*', 'i'),
    index: 0,
  },
};

export const withCustomer = url => {
  if (url.indexOf('/customers/') > -1) {
    return url;
  }
  const matcher = window.location.pathname.match('/.*customers/(\\d+).*/');
  const customerId = matcher && matcher.length > 1 ? matcher[1] : null;
  if (customerId) {
    return `/customers/${customerId}${url}`;
  }
  return '/';
};

const getMatches = (parser, value) => {
  const regexList = Array.isArray(parser.regex) ? parser.regex : [parser.regex];
  let matches = null;
  regexList.forEach(regex => {
    const partialMatches = regex.exec(value);
    if (partialMatches && partialMatches.length > parser.index + 1) {
      matches = partialMatches;
    }
  });
  return matches;
};

export const getUrlValue = (url, param) => {
  const parser = parsers[param];
  const matches = getMatches(parser, url.pathname);
  if (matches && matches.length > parser.index + 1) {
    return matches[parser.index + 1];
  }
  return null;
};

export const getUrlQueryParam = (url, param) => {
  const parsed = queryString.parse(url.search);
  return parsed[param];
};

export const addParamToQueryString = (location, param, value) => {
  const parsed = queryString.parse(location.search);
  return queryString.stringify({
    ...parsed,
    [param]: value,
  });
};

export const getChecklistOrTherapiesTaskUrl = (
  patientId,
  therapyId,
  taskType,
  taskId,
  taskList = [],
) => {
  const query = location.search; // eslint-disable-line
  const taskObj = taskList.find(it => it.id === taskId && it.taskType === taskType);
  const isChecklist = taskType === 'AR' || (taskObj && taskObj.is_patient);
  if (isChecklist) {
    return withCustomer(`/patients/${patientId}/checklist/${taskType}/${taskId}${query}`);
  }
  return withCustomer(
    `/patients/${patientId}/therapies/${therapyId}/${taskType}/${taskId}${query}`,
  );
};

export const getTaskUrl = (patientId, therapyId, taskType, taskId, isSpecialty, search) => {
  const query = search ? `?${search}` : location.search; // eslint-disable-line
  if (taskType === PATIENTS) {
    return withCustomer(`/patients/${patientId}/tasks/${query}`);
  }
  const isTasks = supportedTasksTabTypes.includes(taskType);
  if (isTasks) {
    return withCustomer(`/patients/${patientId}/tasks/${taskType}/${taskId}${query}`);
  }
  if (taskType === THERAPIES) {
    if (isSpecialty || window.FEATURES.therapy_show_non_specialty) {
      return withCustomer(`/patients/${patientId}/therapies/${therapyId}${query}`);
    }
    return withCustomer(`/patients/${patientId}/clinical/therapies/${therapyId}${query}`);
  }
  if (isSpecialty) {
    return withCustomer(
      `/patients/${patientId}/therapies/${therapyId}/${taskType}/${taskId}${query}`,
    );
  }
  return withCustomer(`/patients/${patientId}/tasks/${taskType}/${taskId}${query}`);
};

export const getReportUrl = (reportId, patientId, reportRow) => {
  if (reportId === 101) {
    return withCustomer(
      // eslint-disable-next-line max-len
      `/patients/${patientId}/therapies/${reportRow.therapy_id}/PA/${reportRow.id}?sidebar=${REPORT_SIDEBAR}`,
    );
  }
  if (appointmentReportIds.includes(reportId)) {
    return withCustomer(
      // eslint-disable-next-line max-len
      `/patients/${patientId}/checklist/AR/${reportRow.task_schedule_outreach_id}?sidebar=${REPORT_SIDEBAR}`,
    );
  }
  return withCustomer(
    `/patients/${patientId}/therapies/${reportRow.therapy_id}?sidebar=${REPORT_SIDEBAR}`,
  );
};

export const getNewARUrl = (patientId, appointmentId) => {
  return withCustomer(
    // eslint-disable-next-line max-len
    `/patients/${patientId}/checklist?sidebar=schedule&appointment=${appointmentId}&task_type=AR&selectedType=AR`,
  );
};

export const getScheduleUrl = (patientId, appointmentId) => {
  if (appointmentId) {
    return withCustomer(
      `/patients/${patientId}/therapies/?sidebar=schedule&appointment=${appointmentId}`,
    );
  }
  return withCustomer('/schedule');
};

export const getPatientsUrl = taskType => {
  let taskLink = `/patients?sidebar=${TASK_SIDEBAR}`;
  if (taskType) {
    taskLink += `&task_type=${taskType}`;
  }
  return withCustomer(taskLink);
};

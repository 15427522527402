import React from 'react';
import { withStyles, WithStyles } from '@mui/styles';
import { buildQaId } from 'utils/build-qa-id';
import { useDispatch } from 'react-redux';
import { Grid, Button } from '@mui/material';
import { editFillDeliveryConfirmation, editTask } from 'actions/action-tasks';
import { notifyError } from 'actions/action-notifications';
import { FillDeliveryConfirmationStatus } from 'interfaces/enums/TaskStatuses/FillDeliveryConfirmationStatus';
import { IPickUpButton } from './types';
import { ShippingStyles } from './shipping.styles';
import { FDC } from '../../constants/index';

const qaIdBuilder = buildQaId('shipping');

interface IShippingProps extends IPickUpButton, WithStyles<typeof ShippingStyles> {}

const PickupButton = (props: IShippingProps): JSX.Element | null => {
  const { classes, setReadyForPickupClicked, currentFdcInformation, disabled } = props;
  const dispatch = useDispatch();

  const getPickup = () => {
    try {
      const updatedTask = {
        id: currentFdcInformation?.id,
        taskType: FDC,
        therapy_id: currentFdcInformation?.therapyId,
        status_id: FillDeliveryConfirmationStatus.WillPickUp,
      };
      dispatch && dispatch(editTask(updatedTask, FDC));
      dispatch && dispatch(editFillDeliveryConfirmation(updatedTask));
      setReadyForPickupClicked && setReadyForPickupClicked(true);
    } catch (error) {
      const notifyMessage = 'Unable to pick up.';
      dispatch(notifyError(notifyMessage));
    }
  };

  return (
    <Grid item className={classes.printLabelButton}>
      <Button
        color="primary"
        variant="contained"
        onClick={async () => {
          return getPickup();
        }}
        data-qa-id={qaIdBuilder('button.print-label')}
        disabled={disabled}
      >
        Ready for Pickup
      </Button>
    </Grid>
  );
};

export default withStyles(ShippingStyles)(PickupButton);

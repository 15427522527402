import React from 'react';
import BlueContainer from '../components/blue-container';
import PoweredBy from '../components/powered-by';
import Paragraph from '../components/paragraph';

export default ({ message, uuid }) => (
  <BlueContainer>
    <Paragraph type="title">An unexpected error has happened.</Paragraph>
    <Paragraph type="subTitle">{uuid}</Paragraph>
    <hr />
    <Paragraph type="subTitle">{message}</Paragraph>
    <PoweredBy />
  </BlueContainer>
);

import { fromJS } from 'immutable';

export const assignEmptyLinksToTaskList = taskList =>
  taskList.map(task => Object.assign(task, { links: [] })); // eslint-disable-line

export const getLinksForTaskLists = (taskList, linksForTaskList) => {
  const emptyLinksTaskList = assignEmptyLinksToTaskList(taskList);
  return emptyLinksTaskList.map(task => {
    const matchedLinks = linksForTaskList.filter(link => link.sourceResourceId === task.id);
    if (matchedLinks.length > 0) {
      task.links = matchedLinks;
    }
    return task;
  });
};

export const assignLinksToTherapy = (taskList, linksForTaskList) => {
  const emptyLinksTaskList = assignEmptyLinksToTaskList(taskList);
  return emptyLinksTaskList.map(task => {
    const fromLinks = linksForTaskList.filter(link => link.sourceResourceId === task.id);
    if (fromLinks.length > 0) {
      task.links = fromLinks;
    }
    return task;
  });
};

export const convertLinkWithDirection = (link, reverse) => {
  if (reverse) {
    return {
      ...link,
      sourceResourceType: link.resource_two_type,
      sourceResourceId: link.resource_two_id,
      targetResourceType: link.resource_one_type,
      targetResourceId: link.resource_one_id,
    };
  }
  return {
    ...link,
    sourceResourceType: link.resource_one_type,
    sourceResourceId: link.resource_one_id,
    targetResourceType: link.resource_two_type,
    targetResourceId: link.resource_two_id,
  };
};

export const combineUpdatedTaskLists = (existingTasks, updatedTasks) => {
  const newList = [...existingTasks];
  updatedTasks.forEach(updated => {
    const existing = newList.find(it => it.id === updated.id);
    if (existing) {
      newList[newList.indexOf(existing)] = {
        ...updated,
        order: existing.order,
        links: existing.links,
      };
    } else {
      newList.push(updated);
    }
  });
  return newList;
};

export const stateAlreadyContainsLink = (newState, link, links, taskType) => {
  if (links && links.length > 0) {
    const newLinks = newState[links[0].meta.therapy_id][taskType] // eslint-disable-line
      .find(task => task.id === link.resource_two_id).links;
    if (newLinks && newLinks.length > 0) {
      return newLinks.some(oldLink => oldLink.id === link.id);
    }
  }
  return false;
};

export const updateTaskListWithProperties = (list, taskType, taskId, properties) => {
  let newList = list;
  const taskIndex = list.findIndex(task => task.taskType === taskType && task.id === taskId);
  if (taskIndex > -1) {
    newList = [...list];
    newList[taskIndex] = {
      ...newList[taskIndex],
      ...properties,
    };
  }
  return newList;
};

export const combineWithState = (state, obj) => {
  const newState = fromJS(state);
  return newState.mergeIn(['data'], fromJS(obj)).toJS();
};

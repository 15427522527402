import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { defaultTheme } from '../../../../../../../lib/themes/index';

const AddendumsListStyles = (theme: Theme) => {
  return createStyles({
    addendumsContainer: {
      borderTop: `1px solid ${defaultTheme.palette.primary.grey12}`,
    },
    addendumsTitle: {
      fontWeight: defaultTheme.font.weight.thick,
      fontSize: defaultTheme.font.largeFontSize,
      paddingTop: theme.spacing(2),
    },
    addendumCaption: {
      color: defaultTheme.palette.primary.rollingStone,
    },
    padLeft: {
      paddingLeft: theme.spacing(2),
    },
  });
};

type Styles = WithStyles<typeof AddendumsListStyles>;
const useStyles = makeStyles(AddendumsListStyles);
export const useTypedStyles = (): Styles => ({ classes: useStyles() });

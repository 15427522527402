import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from '../../utils/theme-util';

const styles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    errorIcon: {
      color: palette.primary.valenciaRed,
      fontSize: '16px',
      marginLeft: '3px',
    },
    radioGroupInlineWrapper: {
      backgroundColor: palette.primary.grey2,
      padding: `12px ${theme.spacing(1)}`,
      margin: `${theme.spacing(1)} 0`,
    },
    radioGroup: {
      'display': 'flex',
      '& > button:disabled': {
        color: 'white',
        opacity: 0.5,
      },
    },
    radioGroupInline: {
      'display': 'inline',
      'paddingLeft': 15,
      '& > button:disabled': {
        color: 'white',
        opacity: 0.5,
      },
    },
  });
};

export { styles };

import { fetchNotes, fetchNewNotes } from 'actions/action-notes';
import { fetchPatient, workListChanged } from 'actions/action-patient';
import { addNewDocument } from 'actions/action-uploaded-documents';
import { IDocument, INote } from 'interfaces/fill-coordination/bundle-types';
import { INotes } from 'interfaces/redux/INotes';
import { IUploadedDocuments } from 'interfaces/redux/IUploadedDocuments';
import { Dispatch } from 'redux';
import { NotesUtil } from 'utils/notes-util';
import { getDocuments } from './upload-document-service';

/**
 * This class contains all of the various things that needs to happen after a bundle task
 * form has been submitted. Right now I think this is only used on the tasks FC form
 */
export class BundleTaskUtil {
  private static fetchNotes = (dispatchFn: Dispatch<any>, patientId: number): void => {
    dispatchFn(
      fetchNotes({
        patient_id: patientId,
        group_by_tag: true,
      }),
    );
  };

  private static reloadPatient = (dispatchFn: Dispatch<any>, patientId: number): void => {
    dispatchFn(fetchPatient(patientId));
  };

  private static fetchNewNotes = (
    dispatchFn: Dispatch<any>,
    patientId: number,
    fetchFromId: number,
  ): void => {
    dispatchFn(
      fetchNewNotes({
        patient_id: patientId,
        group_by_tag: true,
        fetchFromId: fetchFromId,
      }),
    );
  };

  private static getDocumentsAndAdd = (
    dispatchFn: Dispatch<any>,
    patientId: number,
    fromId: number,
  ): void => {
    getDocuments({
      patient_id: patientId,
      fetchFromId: fromId,
    }).then((result: any) => {
      dispatchFn(addNewDocument(result.data.documents));
    });
  };

  /**
   * The main entry point for what to do when a bundle task form has been submitted.
   *
   * Currently only used on the tasks -> FC form. If there needs to be special logic for different
   * task types need to explore a factory pattern of some sort to encapsulate that logic per task
   * type rather than adding if statements and stuff like that in here for each specific task type
   * in order to keep everything organized.
   *
   * @param dispatchFn Dispatch for redux
   * @param payloadNotes Notes from the payload that was submitted to the bundle endpoint
   * @param stateNotes Notes from the redux state
   * @param patientId Patient id
   * @param stateDocuments Uploaded documents from the state
   */
  static handleBundleTaskCompletion = (
    dispatchFn: Dispatch<any>,
    payloadNotes: INote[],
    stateNotes: INotes,
    patientId: number,
    stateDocuments: IUploadedDocuments,
    payloadDocuments: IDocument[] | undefined,
  ): void => {
    dispatchFn(workListChanged());

    // Get new notes if we need to
    if (payloadNotes.length) {
      const { pinnedNoteHighestId: pinnedId, nonPinnedNoteHighestId: nonPinnedId } =
        NotesUtil.getNotesHighestId(stateNotes);

      if (pinnedId === -1 && nonPinnedId === -1) {
        BundleTaskUtil.fetchNotes(dispatchFn, patientId);
      } else {
        const idToFetchNotes = pinnedId > nonPinnedId ? pinnedId : nonPinnedId;
        BundleTaskUtil.fetchNewNotes(dispatchFn, patientId, idToFetchNotes);
      }
    }

    // Get new documents if we need to
    if (payloadDocuments?.length) {
      const maxDocumentId = Math.max(...stateDocuments.documents.map(doc => doc.id));
      BundleTaskUtil.getDocumentsAndAdd(dispatchFn, patientId, maxDocumentId);
    }

    /**
     * Reload the patient because something may have changed
     * FC form can set certain properties that are needed for other FCs for example
     */
    BundleTaskUtil.reloadPatient(dispatchFn, patientId);
  };
}

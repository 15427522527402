import { Grid, Typography } from '@mui/material';
import React from 'react';
import { IIncome } from 'components/patient-merge-modal/interfaces';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { useTheme } from '@mui/styles';

const IncomeSection = ({ year }: IIncome) => {
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <Typography sx={classes.checkboxTitle}>{`Household Income ${year}`}</Typography>
      </Grid>
    </Grid>
  );
};

export default IncomeSection;

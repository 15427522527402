import React, { Component } from 'react';
import moment from 'moment';
import { Grid, Button } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import {
  PA,
  FA,
  FC,
  DC,
  TPR,
  INT,
  CSL,
  MR,
  OR,
  AR,
  FDC,
  THERAPIES,
  DUR,
  QRE,
  SMS,
  STAR,
} from 'constants/index';
import Dropdown from 'components/form/field/dropdown';

import SubHeader from 'components/form/header/subheader';
import TaskAddForm from 'components/dynamic-form/task-forms/task-add-form';
import { getTaskInitialValues } from 'helpers/automation-tasks';
import { convertToArborDate } from 'models/time/arbor-date';
import { styles } from './task-detail-styles';

class AddTherapyTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedType: -1,
      selectedTherapyId: -1,
    };
    this.handleTaskType = this.handleTaskType.bind(this);
    this.resetTaskStatus = this.resetTaskStatus.bind(this);
    this.handleTherapy = this.handleTherapy.bind(this);
  }

  handleTherapy(e) {
    this.setState({
      selectedTherapyId: e.target.value,
    });
  }

  handleTaskType(e) {
    this.setState({
      selectedType: e.target.value,
    });
  }

  resetTaskStatus() {
    const { handleCancel } = this.props;
    this.setState({
      selectedType: -1,
      selectedTherapyId: -1,
    });
    handleCancel();
  }

  render() {
    const {
      classes,
      taskCounts,
      therapy,
      handleCancel,
      therapyIndex,
      availableTherapies = {},
    } = this.props;

    const { selectedType, selectedTherapyId } = this.state;

    const shouldSelectTherapyManually =
      availableTherapies && Object.keys(availableTherapies).length > 1;

    // Filter out AR task as it cannot be created by user
    let filterOutTaskArr = [AR, FDC, THERAPIES, DUR, SMS, STAR];
    if (window.FEATURES.hide_mr) {
      filterOutTaskArr = [...filterOutTaskArr, MR];
    }
    const statusArrForSelect = Object.keys(taskCounts)
      .filter(task => filterOutTaskArr.indexOf(task) === -1)
      .map(field => ({
        key: field,
        value: taskCounts[field].task_description,
      }));

    const availableTherapiesForSelect = Object.keys(availableTherapies).map(therapyId => ({
      key: therapyId,
      value: availableTherapies[therapyId].drug_name,
    }));

    const initialValues = {
      [PA]: {
        taskType: PA,
        service_group_id: therapy?.service_group_id, // eslint-disable-line
        followup_dt: getTaskInitialValues(therapy, PA),
        status_id: 2001,
      },
      [FA]: {
        taskType: FA,
        service_group_id: therapy?.service_group_id, // eslint-disable-line
        followup_dt: getTaskInitialValues(therapy, FA),
        status_id: 3001,
      },
      [FC]: {
        taskType: FC,
        service_group_id: therapy?.service_group_id, // eslint-disable-line
        status_id: 4001,
        needsby_date: therapy?.needsby_date, // eslint-disable-line
        days_supply: therapy?.days_supply, // eslint-disable-line
      },
      [TPR]: {
        taskType: TPR,
        service_group_id: therapy?.service_group_id, // eslint-disable-line
        status_id: 5001,
      },
      [MR]: {
        taskType: MR,
        service_group_id: therapy?.service_group_id, // eslint-disable-line
        status_id: 14001,
      },
      [DC]: {
        taskType: DC,
        service_group_id: therapy?.service_group_id, // eslint-disable-line
        status_id: 6001,
      },
      [INT]: {
        taskType: INT,
        service_group_id: therapy?.service_group_id, // eslint-disable-line
        status_id: 8001,
        followup_dt: convertToArborDate(moment()).getCustomerDatetime(true),
      },
      [CSL]: {
        taskType: CSL,
        service_group_id: therapy?.service_group_id, // eslint-disable-line
        additional_reason: null,
        status_id: 9001,
        completed_details: 0,
      },
      [OR]: {
        taskType: OR,
        service_group_id: therapy?.service_group_id, // eslint-disable-line
        additional_reason: null,
        status_id: 13001,
      },
      [QRE]: {
        taskType: QRE,
        service_group_id: therapy?.service_group_id, // eslint-disable-line
        additional_reason: null,
        status_id: 15001,
      },
    };

    const renderConditionalDetailFields = (
      statusSelectIdParam,
      statusSelectedTherapyIdParam,
      shouldSelectTherapy = false,
    ) => {
      const buttonIdPrefix = `${therapyIndex}_newTaskButton`;
      if (
        statusSelectIdParam === -1 ||
        (shouldSelectTherapy && statusSelectedTherapyIdParam === -1)
      ) {
        return (
          <div style={{ textAlign: 'right' }}>
            <Button
              name="add_task_cancel_button"
              type="button"
              onClick={handleCancel}
              className={classes.button}
              id={`${buttonIdPrefix}_cancel`}
            >
              Cancel
            </Button>
          </div>
        );
      }
      if (initialValues[statusSelectIdParam]) {
        return (
          <TaskAddForm
            key={`${statusSelectIdParam}-add`}
            therapy={
              shouldSelectTherapyManually
                ? availableTherapies[statusSelectedTherapyIdParam]
                : therapy
            }
            taskType={statusSelectIdParam}
            therapyId={shouldSelectTherapyManually ? statusSelectedTherapyIdParam : therapy?.id}
            onCancel={this.resetTaskStatus}
            task={initialValues[statusSelectIdParam]}
            buttonIdPrefix={buttonIdPrefix}
          />
        );
      }
      return (
        <Grid container>
          {`${statusSelectIdParam} not supported`}
          <div style={{ textAlign: 'right' }}>
            <Button
              name="add_task_cancel_button"
              type="button"
              onClick={handleCancel}
              className={classes.button}
              id={`${buttonIdPrefix}_cancel`}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      );
    };

    return (
      <Grid container className={classes.addForm}>
        <Grid container spacing={1} className={classes.statusContainer}>
          <Grid item xs={12}>
            <SubHeader name="New Task" />
          </Grid>
          {shouldSelectTherapyManually && (
            <Grid item xs={6}>
              <Dropdown
                label="Therapy *"
                state={selectedTherapyId}
                handler={this.handleTherapy}
                fields={availableTherapiesForSelect}
                name="therapy"
                width="90%"
                id={`addTherapyTaskTherapiesDropdown_${therapyIndex}`}
              />
            </Grid>
          )}
          <Grid item xs={shouldSelectTherapyManually ? 6 : 12}>
            <Dropdown
              label="Task Type *"
              state={selectedType}
              handler={this.handleTaskType}
              fields={statusArrForSelect}
              name="task_type"
              width={shouldSelectTherapyManually ? '90%' : '50%'}
              id={`addTherapyTaskDropdown_${therapyIndex}`}
            />
          </Grid>
          <Grid item xs={12} className={classes.taskContainer}>
            {renderConditionalDetailFields(
              selectedType,
              selectedTherapyId,
              shouldSelectTherapyManually,
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AddTherapyTask);

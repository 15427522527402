import React from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RadioGroup from 'components/form/radio/radio-group';
import {
  THERAPY_LEAD_SOURCE_EMR_LEAD,
  THERAPY_LEAD_SOURCE_CLINICIAN_REFERRAL,
  THERAPY_LEAD_SOURCE_HEALTH_PLAN,
  THERAPY_LEAD_SOURCE_HUB,
  THERAPY_LEAD_SOURCE_RPH_INITIATED_REFERRAL,
} from 'constants/index';
import RadioButton from 'components/form/radio/radio-button';
import Validation from 'components/form/validation/validation';
import { styles } from './styles';

// TODO: all radio button group components in this folder need to be abstracted
// TODO: into a component which takes a list of values and labels and render
// TODO: into a radio group

const useStyles = makeStyles(styles);

export default ({ input, onChange, meta: { touched, error, warning } }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <RadioGroup
        horizontal
        name="lead-source"
        id="lead-source"
        {...input}
        onChange={value => {
          if (onChange) {
            onChange(value);
          }
          if (input.onChange) {
            input.onChange(value);
          }
        }}
        className={classes.radioGroup}
      >
        <RadioButton value={THERAPY_LEAD_SOURCE_EMR_LEAD}>EMR Lead</RadioButton>
        <RadioButton value={THERAPY_LEAD_SOURCE_CLINICIAN_REFERRAL}>Clinician Referral</RadioButton>
        <RadioButton value={THERAPY_LEAD_SOURCE_HEALTH_PLAN}>Health Plan</RadioButton>
        <RadioButton value={THERAPY_LEAD_SOURCE_HUB}>Hub</RadioButton>
        <RadioButton value={THERAPY_LEAD_SOURCE_RPH_INITIATED_REFERRAL}>
          RPh Initiated Referral
        </RadioButton>
      </RadioGroup>
      <Validation touched={touched} error={error} warning={warning} />
    </Grid>
  );
};

import React from 'react';
import { renderDebouncedTextField as DebouncedTextField } from 'components/form/field/redux-field';
import { IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ListIcon from '@mui/icons-material/List';
import PdfIcon from '@mui/icons-material/PictureAsPdf';

const useStyles = makeStyles(() => ({
  button: {
    verticalAlign: 'bottom',
    marginBottom: 18,
  },
  flexDiv: {
    display: 'flex',
  },
}));

export const QuestionnaireValue = ({ onButtonClick, onPrintButtonClick, ...other }) => {
  const classes = useStyles();
  return (
    <div className={classes.flexDiv}>
      <DebouncedTextField {...other} />
      {onButtonClick && (
        <IconButton
          size="small"
          onClick={onButtonClick}
          className={classes.button}
          name="questionnaire_button"
        >
          <ListIcon />
        </IconButton>
      )}
      {onPrintButtonClick && (
        <IconButton
          size="small"
          onClick={onPrintButtonClick}
          className={classes.button}
          name="questionnaire_print_button"
        >
          <PdfIcon />
        </IconButton>
      )}
    </div>
  );
};

export default ({ field, label, input, meta, disabled, labelSize, qaId }) => (
  <QuestionnaireValue
    label={label}
    input={input}
    meta={meta}
    disabled={disabled}
    labelSize={labelSize}
    qaId={qaId}
    onButtonClick={field.onButtonClick}
    onPrintButtonClick={field.onPrintButtonClick}
  />
);

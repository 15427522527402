import React, { FC, useState, useEffect } from 'react';

import { compose } from 'recompose';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';

import { Typography, Grid, Modal } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { VACCINATION_SOURCES } from 'constants/index';
import { IState } from 'interfaces/redux/IState';

import { nameOfFactory } from 'utils/types-util';

import {
  renderTextField as RenderTextField,
  renderReactRadioGroup as RenderRadioGroup,
} from 'components/form/field/redux-field';

import { getModalStyle } from 'services/utils/styles-service';
import Divider from 'components/divider';
import { required, validateDateIsNotInTheFuture } from 'components/form/validation/validation';
import { ReactSelectForReduxSingle } from 'components/form/field/react-select';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { renderDatePicker as RenderDatePicker } from 'components/form/datepicker/datetime-picker';

import { buildQaId } from 'utils/build-qa-id';

import { IVaccinationForm, IPropsWithReduxFormProps } from './interfaces';
import { styles } from './vaccinations-form-dialog.styles';
import {
  useVaccinesData,
  VaccineMap,
  IVaccine,
  IVaccineCategory,
} from '../hooks/use-vaccines-data';

import { saveVaccination } from './utils';

const nameOfFormFields = nameOfFactory<IVaccinationForm>();
const getQaId = buildQaId('VaccinationForm');

const VaccinationFormDialog: FC<IPropsWithReduxFormProps> = (
  props: IPropsWithReduxFormProps,
): JSX.Element => {
  const { classes, handleCloseModal, onSaveCallback, open, handleSubmit, form, change, reset } =
    props;

  const dispatch = useDispatch();

  const vaccinesData = useVaccinesData();

  // State is required for formValueSelector
  const state = useSelector((state: IState) => state);
  const currentFormSelector = formValueSelector(form);

  // Keeps track of available vaccines for a given category selected (starts with all vaccines)
  const [vaccineOptions, setVaccineOptions] = useState<VaccineMap>(vaccinesData.vaccinesMap);
  const [vaccineSelected, setVaccineSelected] = useState<IVaccine | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  // Selector for vaccine and category fields
  const categoryFormField = currentFormSelector(state, nameOfFormFields('category'));
  const vaccineFormField = currentFormSelector(state, nameOfFormFields('vaccine'));

  const resetForm = () => {
    setVaccineSelected(null);
    setVaccineOptions(vaccinesData.vaccinesMap);
    reset();
  };

  const submit = (formData: Partial<IVaccinationForm>) => {
    setIsSaving(true);
    const saveCallbackParams = {
      patientId: state.patient.id,
      formData: formData as IVaccinationForm,
      actionDispatcher: dispatch,
      afterSaveCallback: () => {
        setIsSaving(false);
        handleCloseModal?.();
        resetForm();
      },
    };

    // If a custom callback it provided use it otherwise use default one
    if (onSaveCallback) {
      onSaveCallback(saveCallbackParams);
    } else {
      saveVaccination(saveCallbackParams);
    }
  };

  const onVaccineChange = (selectedVaccine: IVaccine) => {
    change(nameOfFormFields('type'), selectedVaccine.type.name);
    change(nameOfFormFields('effectiveFor'), selectedVaccine.effectiveFor);
    change(nameOfFormFields('category'), {
      value: selectedVaccine.category.id,
      label: selectedVaccine.category.name,
    });
    change(nameOfFormFields('type'), {
      value: selectedVaccine.type.id,
      label: selectedVaccine.type.name,
    });
    setVaccineSelected(selectedVaccine);
  };

  const onCategoryChange = (selectedCategory: IVaccineCategory) => {
    setVaccineOptions(selectedCategory.vaccines);
    // Deselect current selected vaccine if the current selected vaccine not belongs to the selected category
    if (selectedCategory.id !== vaccineSelected?.category.id) {
      const defaultSelectedVaccine = selectedCategory.vaccines.find(
        vaccineOption => vaccineOption.default_selected,
      );

      change(
        nameOfFormFields('vaccine'),
        defaultSelectedVaccine
          ? {
              value: defaultSelectedVaccine.id,
              label: defaultSelectedVaccine.name,
            }
          : null,
      );
      setVaccineSelected(defaultSelectedVaccine || null);
    }
  };

  const closeModalHandler = () => {
    handleCloseModal?.();
    resetForm();
  };

  // Listens for changes on category field
  useEffect(() => {
    if (categoryFormField?.value) {
      const selectedCategory = vaccinesData.categoriesMap[categoryFormField.value];
      onCategoryChange(selectedCategory);
    }
  }, [categoryFormField]);

  // Listens for changes on vaccine field
  useEffect(() => {
    if (vaccineFormField?.value) {
      const selectedVaccine = vaccinesData.vaccinesMap[vaccineFormField.value];
      onVaccineChange(selectedVaccine);
    }
  }, [vaccineFormField]);

  return (
    <Modal open={open} onClose={closeModalHandler}>
      <Grid style={getModalStyle()} container className={classes.modalContentWrapper}>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.modalTitle}>
            New Vaccine
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <form data-qa-id={getQaId(`${form}_main_form`)} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={4} className={classes.fieldsWrapper}>
                <Field
                  id={getQaId(`${form}_${nameOfFormFields('category')}`)}
                  name={nameOfFormFields('category')}
                  label="Category *"
                  validate={[required]}
                  component={ReactSelectForReduxSingle}
                  fields={Object.values(vaccinesData.categoriesMap).map(category => ({
                    value: category.id,
                    label: category.name,
                  }))}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  id={getQaId(`${form}_${nameOfFormFields('vaccine')}`)}
                  name={nameOfFormFields('vaccine')}
                  label="Vaccine *"
                  validate={[required]}
                  component={ReactSelectForReduxSingle}
                  fields={Object.values(vaccineOptions)
                    .filter(vaccine => categoryFormField || !vaccine.default_selected)
                    .map(vaccine => ({
                      value: vaccine.id,
                      label: vaccine.name,
                    }))}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  id={getQaId(`${form}_${nameOfFormFields('type')}`)}
                  name={nameOfFormFields('type')}
                  label="Type"
                  component={ReactSelectForReduxSingle}
                  fields={Object.values(vaccinesData.typesMap).map(type => ({
                    value: type.id,
                    label: type.name,
                  }))}
                  disabled={!vaccineSelected}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  id={getQaId(`${form}_${nameOfFormFields('effectiveFor')}`)}
                  name={nameOfFormFields('effectiveFor')}
                  label="Effective for"
                  component={RenderTextField}
                  disabled={!vaccineSelected}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  id={getQaId(`${form}_${nameOfFormFields('dateReceived')}`)}
                  name={nameOfFormFields('dateReceived')}
                  label="Date Received *"
                  component={RenderDatePicker}
                  validate={[required, validateDateIsNotInTheFuture]}
                  disabled={!vaccineSelected}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  id={getQaId(`${form}_${nameOfFormFields('source')}`)}
                  name={nameOfFormFields('source')}
                  label="Source"
                  radioMap={VACCINATION_SOURCES}
                  component={RenderRadioGroup}
                  disabled={!vaccineSelected}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <ConfirmationPanel
            cancelButtonName={`${form}_form_cancel_button`}
            submitButtonName={`${form}_form_submit_button`}
            hideSubmit={false}
            hideCancel={false}
            submitButtonText="Save"
            handleCancel={() => {
              handleCloseModal?.();
              resetForm();
            }}
            handleSubmit={handleSubmit(submit)}
            isLoading={isSaving}
            loadingText="Saving"
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default compose<IPropsWithReduxFormProps, Partial<IPropsWithReduxFormProps>>(
  withStyles(styles),
  reduxForm({}),
)(VaccinationFormDialog);

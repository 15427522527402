import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) => {
  return createStyles({
    headingContainer: {
      margin: `${theme.spacing(1)} 0`,
    },
    editPanelLeftButton: {
      marginRight: theme.spacing(0.5),
    },
    newAddendumField: {
      paddingTop: theme.spacing(3),
    },
  });
};

export { styles };

import { logger } from '../winston-logger';

export const exceptionHandlerMiddleware = () => next => action => {
  try {
    return next(action);
  } catch (error) {
    if (error instanceof Error) {
      logger.error(`${error.message} - ${error.stack}`);
    } else {
      logger.error(error);
    }
    return next(action);
  }
};

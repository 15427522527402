enum RiskStratStatus {
  Required = 17001,
  Accepted = 17002,
  Canceled = 17003,
  InProgress = 17004,
  Intervening = 17005,
  Patient_Deceased = 17099,
}

export { RiskStratStatus };

import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  loading: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgb(255, 255, 255, 0.6)',
    zIndex: 2,
    opacity: 0,
    transition: 'opacity 4s',
  },
}));

export default () => {
  const classes = useStyles();
  const [opacity, setOpacity] = useState(0);

  React.useEffect(() => {
    // after a second of loading, start fading in the loading page
    setTimeout(() => setOpacity(1), 1000);
  }, [1]);

  return <div className={classes.loading} style={{ opacity }} />;
};

import HTTP from './services/http';

// eslint-disable-next-line import/no-mutable-exports, prefer-const
export let logger: any = console;

export class EndpointLogger {
  logs: any[] = [];

  constructor(opts?: any) {
    setInterval(() => this.postLogs(), 2000);
  }

  log(info: any, callback?: any) {
    this.logs.push(info);

    if (callback) {
      callback();
    }
  }

  postLogs() {
    const numberOfLogsSent = this.logs.length;
    if (!numberOfLogsSent) {
      return;
    }
    HTTP.post('/client_logs', this.logs).then(() => {
      this.logs.splice(0, numberOfLogsSent);
    });
  }
}

export function configureWinston(level: string) {
  /*
  logger = winston.createLogger({
    transports: [
      new BrowserConsole({
        level,
        handleExceptions: true,
        format: winston.format.simple(),
      }) as any,
      new EndpointLogger({
        level,
        handleExceptions: true,
        format: winston.format.simple(),
      }),
    ],
  });
  */
}

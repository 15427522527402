import React from 'react';
import { Grid, Typography, Popover } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';

import { BaseTable } from 'containers/application-manager/base-table/base-table';
import { useDispatch } from 'react-redux';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { UserUtils } from 'utils/user-utils';
import { UserPermissions } from 'interfaces/user/Permissions';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { editDataCollectItem } from 'actions/action-lookups';
import { NumberOption } from 'interfaces/forms/types';
import { FormField } from '../../popover-field-forms';
import { FieldTypes } from '../../types';
import { IHeaderCell } from '../../base-table/types';
import { IPopoverStateBase } from '../types';
import { IResponse, IResult, IResultChildren } from './types';
import { statusFormatter } from '../utils';
import { styles } from '../index.styles';
import { AddNewDcModal } from '../modal/add-new-dc-modal';
import { IEditDcItem, IEditPayloadObject } from '../modal/types';

const baseUrl = '/application-manager/cpmp';

interface ITableProps {
  searchTerm: string | undefined;
  triggerReload: Date | undefined;
  searchTermByCategory: string | undefined;
  handleReloadDataTrigger: (date: Date | undefined) => void;
  userHasPermissionToEditDcItem: boolean;
  activeCategory: boolean;
  activeItem: boolean;
  activeOnlyItem: boolean;
  categoryOptionsList: NumberOption[];
}

type IPopoverState = IPopoverStateBase<IResult>

const initialPopoverState = { open: false } as IPopoverState;

export const DataTable: React.FC<ITableProps> = (props): JSX.Element => {
  const classes: any = styles();

  // #region component state
  const dispatch = useDispatch();
  const [popoverState, setPopoverState] = React.useState<IPopoverState>(initialPopoverState);
  const [editModalOpen, setEditModalOpen] = React.useState<boolean>(false);
  const [categoryOptions, setCategoryOptions] = React.useState<NumberOption[]>([]);
  const [reloadDataTrigger, setReloadDataTrigger] = React.useState<Date | undefined>(undefined);
  const [editDc, setEditDc] = React.useState<IResult | undefined>(undefined);
  // #endregion

  // # region redux state
  const clinicalDataTypesData = useTypedSelector(state => state.lookups.clinicalDataTypes);
  // # endregion

  // # region helper functions
  const getClinicalDataTypesData = React.useMemo<IResponse>(() => {
    let totalCount = 0;
    const results: IResult[] = [];
    for (const categoryId in clinicalDataTypesData) {
      const categoryName = clinicalDataTypesData[categoryId].name;
      for (const dataTypeId in clinicalDataTypesData[categoryId].dataTypes) {
        const {
          name: dataTypeName,
          isActive: dataTypeIsActive,
          unit: dataTypeUnit,
          type: dataTypeType,
          option: dataTypeOption,
          example: dataTypeExample,
          loinc: dataTypeLoincCodes,
        } = clinicalDataTypesData[categoryId].dataTypes[dataTypeId];
        const dataTypeLoincCodesIds = dataTypeLoincCodes
          ? Object.entries(dataTypeLoincCodes).map(loinc => {
              return {
                code: loinc[0],
                shortname: Object.entries(loinc[1])
                  .map(x => x[1])
                  .join(),
              };
            })
          : [];
        totalCount += 1;
        results.push({
          id: parseInt(dataTypeId),
          type_name: dataTypeName,
          category_name: categoryName,
          category_id: categoryId,
          is_active: dataTypeIsActive,
          children: [
            {
              unit: dataTypeUnit !== null ? dataTypeUnit : '',
              type: dataTypeType,
              option: dataTypeOption !== null ? dataTypeOption : '',
              example: dataTypeExample !== null ? dataTypeExample : '',
              loincCodes: dataTypeLoincCodesIds,
            },
          ],
        });
      }
    }
    return {
      totalCount,
      results,
    };
  }, [clinicalDataTypesData]);

  const buildPayloadObjectToEditDcItem = (
    data: IEditDcItem,
    loincCodes: { code: string; shortname: string }[],
  ): IEditPayloadObject => {
    return {
      dataTypeId: data.id,
      previousCategoryId: data.category_id,
      data: {
        category_id: data.category_id,
        name: data.name,
        isActive: data.isActive,
        loinc:
          loincCodes && loincCodes.length > 0
            ? loincCodes.reduce((acc: any, currentVal) => {
                acc[currentVal.code] = { shortname: currentVal.shortname };
                return acc;
              }, {})
            : {},
      },
    };
  };

  const onSubmitFieldUpdate = async (value: unknown) => {
    try {
      const singleItem = popoverState.parent.children[0];
      const dcItemObject: IEditDcItem = {
        id: popoverState.parent.id,
        category_id: Number(popoverState.parent.category_id),
        name: popoverState.parent.type_name,
        isActive: Boolean(value),
        loinc: singleItem.loincCodes.map(x => x.code),
        type: singleItem.type,
        unit: singleItem.unit,
        example: singleItem.example,
        option: singleItem.option,
      };
      const result = await ApplicationManagerClient.editDc(dcItemObject);
      if (!result.data.hasOwnProperty('errorMessage')) {
        dispatch(notifySuccess('Edited DC item'));
        dispatch(
          editDataCollectItem(buildPayloadObjectToEditDcItem(dcItemObject, singleItem.loincCodes)),
        );
      } else {
        const errorMessage = Object.entries(result.data).reduce((acc, val) => {
          if (val[0] === 'errorMessage') {
            acc = val[1];
          }
          return acc;
        }, '');
        if (errorMessage.length > 0) {
          dispatch(notifyError(errorMessage));
        }
      }
    } catch (error) {
      dispatch(notifyError('An error occured updating DC item'));
    }
  };

  const renderPopover = (): JSX.Element => {
    const handleCancel = () => setPopoverState(initialPopoverState);
    const handleSubmit = (value: unknown) => onSubmitFieldUpdate(value);

    return (
      <Popover
        id="form"
        open={popoverState.open}
        anchorEl={popoverState?.anchorElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transitionDuration={{ exit: 0 }}
        onClose={() => setPopoverState(initialPopoverState)}
      >
        <FormField
          data-qa-id={`${popoverState.field}-${popoverState.parent?.id || ''}`}
          initialValue={popoverState.value}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          fieldType={popoverState.fieldType}
        />
      </Popover>
    );
  };

  const openPopover = (
    element: HTMLElement,
    value: boolean,
    config: {
      parent: IResult;
      fieldType: FieldTypes;
      field: keyof IResult;
      forceAsParent?: boolean;
    },
  ): void => {
    if (config.parent === null) {
      throw new Error('Cannot select a parent cell.');
    }
    setPopoverState({
      open: true,
      anchorElement: element,
      parent: config.parent,
      field: config.field,
      fieldType: config.fieldType,
      value: value,
      forceAsParent: config.forceAsParent,
    });
  };
  // #endregion

  // # region constants
  const COLUMN_SETTINGS: IHeaderCell<IResult, IResultChildren>[] = [
    {
      label: 'Item',
      parentKey: 'type_name',
      sortable: true,
      hideLeftPadding: true,
      childColSpan: 2,
      childComponent: (parent, child) => {
        return (
          <>
            <Grid container direction="column" spacing={2}>
              <Grid item xs="auto">
                <Grid container justifyContent="space-between">
                  <Grid item xs={6}>
                    <Grid container spacing={1}>
                      <Grid item xs="auto">
                        <Typography className={classes.dcSubFields}>Value</Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item xs="auto">
                        <Typography>
                          {child.type.charAt(0).toUpperCase() + child.type.slice(1)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item xs="auto">
                        <Typography className={classes.dcSubFields}>Arbor ID</Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs="auto">
                        <Typography>{parent.id}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={1}>
                      <Grid item xs="auto">
                        <Typography>{child.loincCodes.length > 0 ? 'LOINC' : ''}</Typography>
                        {child.loincCodes.map(x => {
                          return <Typography>{`${x.code}  ${x.shortname}`}</Typography>;
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        );
      },
    },
    {
      label: 'Category',
      parentKey: 'category_name',
      hideLeftPadding: true,
      sortable: true,
    },
    {
      label: 'Status',
      parentKey: 'is_active',
      sortable: true,
      parentValueFormatter: statusFormatter,
    },
  ];
  // #endregion

  const columnSettings = React.useMemo(() => {
    if (props.userHasPermissionToEditDcItem) {
      return COLUMN_SETTINGS.concat({
        sortable: true,
        parentComponent: parent => {
          return (
            <>
              <div
                onClick={e => {
                  e.stopPropagation();
                  setEditDc(parent);
                  setEditModalOpen(true);
                }}
              >
                <CreateIcon />
              </div>
            </>
          );
        },
      });
    }
    return COLUMN_SETTINGS;
  }, [props.userHasPermissionToEditDcItem]);
  // #endregion

  const renderEnableDcModal = (): JSX.Element => {
    return (
      <AddNewDcModal
        open={editModalOpen}
        onCancel={() => {
          setEditDc(undefined);
          setEditModalOpen(false);
        }}
        onSuccess={() => {
          setEditDc(undefined);
          setReloadDataTrigger(new Date());
        }}
        editingDcItem={editDc}
        categoryOptionsList={props.categoryOptionsList}
      />
    );
  };

  return (
    <>
      {props.userHasPermissionToEditDcItem ? renderPopover() : null}
      {props.userHasPermissionToEditDcItem ? renderEnableDcModal() : null}
      <BaseTable<IResponse, IResult, IResultChildren>
        actionsPermitted={props.userHasPermissionToEditDcItem}
        enableParentCheckboxes={false}
        enableChildCheckboxes={UserUtils.userIsPermitted(
          UserPermissions.ApplicationManagerCpmpEdit,
        )}
        orderByDefaultParent="type_name"
        dataSet={getClinicalDataTypesData}
        paginationQueryParamSettings={{
          pageSizeQueryStringKey: 'pageSize',
          pageNumberQueryStringKey: 'pageNumber',
          searchTermQueryStringKey: 'searchTerm',
          sortPropQueryStringKey: 'sortProp',
          sortOrderQueryStringKey: 'sortOrder',
        }}
        childPkSelector={c => c.type}
        parentPkSelector={p => p.id}
        columnSettings={columnSettings}
        searchTerm={JSON.stringify({
          type: 'MULTIPLE',
          search: [
            {
              values: props.searchTermByCategory,
              columns: ['category_name'],
              fullMatch: true,
              active: props.activeCategory,
            },
            {
              values: props.searchTerm,
              columns: ['type_name'],
              fullMatch: false,
              active: props.activeItem,
            },
            {
              values: props.searchTerm,
              columns: ['type_name', 'category_name'],
              fullMatch: false,
              active: props.activeOnlyItem,
            },
          ],
        })}
        classesOverride={{
          lastCell: classes.lastCell,
        }}
        triggerReload={props.triggerReload}
      />
    </>
  );
};

import React from 'react';

import { Controller } from 'react-hook-form';

import { renderTimePicker as RenderDateTimePicker } from 'components/form/datepicker/datetime-picker';
import { IControlledFormField } from '../types';

interface IProps extends IControlledFormField {
  percentWith?: number;
}

export const ControlledTimePicker: React.FC<IProps> = (props: IProps) => {
  const { name, label, control, inputMetaData, validations, ...customProps } = props;
  return (
    <Controller
      render={(ctrlProps: any) => (
        <RenderDateTimePicker
          fullWidth
          label={label}
          input={{
            value: ctrlProps?.field?.value,
            onChange: ctrlProps?.field?.onChange,
            onBlur: ctrlProps?.field?.onBlur,
          }}
          meta={inputMetaData || {}}
          {...customProps}
        />
      )}
      name={name}
      control={control}
      rules={validations || {}}
      data-qa-id={props.qaId}
    />
  );
};

import { Reducer } from 'redux';
import { IAuditState, IAudit } from 'interfaces/redux/IAudit';
import { AuditAction } from 'actions/action-audit';

import { FETCH_AUDIT_SUCCESS, RESOLVE_AUDIT } from '../constants';

const initialState: IAuditState = {
  demographics: [],
};

export const auditReducer: Reducer<any, any> = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_AUDIT_SUCCESS: {
      if (payload.audits) {
        return payload.audits;
      }
      return initialState;
    }
    case RESOLVE_AUDIT: {
      if (payload && payload.data && payload.data.audit) {
        const incomingAudit: IAudit = payload.data.audit;
        const auditCategory: string =
          incomingAudit.definition &&
          incomingAudit.definition.type &&
          incomingAudit.definition.type.name
            ? incomingAudit.definition.area.name.toLowerCase()
            : '';
        const newAuditCategoryArr: IAudit[] = state[auditCategory].map((audit: IAudit) => {
          if (audit.id === incomingAudit.id) {
            return incomingAudit;
          }
          return audit;
        });
        return {
          ...state,
          [auditCategory]: newAuditCategoryArr,
        };
      }
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

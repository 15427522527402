import React, { useEffect, useMemo, useState } from 'react';
import { Button, FormControl, Grid, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { Moment } from 'moment';
import theme from '../../lib/themes/theme';
import { renderDropdown, renderMultipleDropdown } from '../form/field/redux-field';
import { renderDatePicker } from '../form/datepicker/datetime-picker';
import { required, validateDate } from '../form/validation/validation';
import ConfirmationPanel from '../form/confirmation/confirmation-panel';
import { PAGINATION_PARAMS } from '../../containers/application-manager/ar/constants';
import { dateConvert } from '../../containers/application-manager/utils';
import { BaseTable } from '../../containers/application-manager/base-table/base-table';
import {
  BulkSmsClient,
  IBulkSmsFilters,
  IBulkSmsRecipient,
  ITableBulkSms,
  ITableBulkSmsResponse,
} from '../../clients/bulk-sms';
import { getUserById } from '../../services/utils/users-service';
import { useTypedSelector } from '../../hooks/use-typed-selector';
import {
  AdherencePackagingOptions,
  FirstFillOptions,
  SmsOptions,
  transformToOptions,
} from '../../utils/bulk-sms';
import { CATEGORY_IN_PROGRESS, CATEGORY_OUTSTANDING } from '../../constants';

const styles = {
  advancedSearchWrapper: {
    padding: 1,
    border: `${theme.palette.primary.light} 1px solid`,
    margin: 3,
    borderRadius: 1,
  },
};
const FORM_NAME = 'advanced-search-bulk-sms';

interface IBulkSmsSentTabFilters extends IBulkSmsFilters {
  from: Moment;
  to: Moment;
}
type Props = InjectedFormProps<IBulkSmsSentTabFilters>;
export const SentBulkSmsTabCmp: React.FC<Props> = (props): JSX.Element => {
  const { handleSubmit } = props;

  const [displayAdvancedFilters, setDisplayAdvancedFilters] = useState(false);
  const [filters, setFilters] = useState<IBulkSmsSentTabFilters>({} as IBulkSmsSentTabFilters);
  const queryForcedParams = useMemo(() => {
    const params: Record<string, string | string[]> = {};
    const { to, from, ...otherFilters } = filters;
    const parsedFilters = {
      to: to?.format('YYYY-MM-DD'),
      from: from?.format('YYYY-MM-DD'),
      ...BulkSmsClient.parseFiltersToPayload(otherFilters),
    };
    Object.keys(parsedFilters).forEach(key => {
      if (parsedFilters[key as keyof IBulkSmsFilters]) {
        const param = parsedFilters[key as keyof IBulkSmsFilters];
        params[key] = Array.isArray(param) ? param.map(i => String(i)) : String(param);
      }
    });
    return params;
  }, [filters]);

  const serviceGroups = useTypedSelector(state => state.lookups.serviceGroups || []);
  const customers = useTypedSelector(state => state.filters.customers || []);
  const users = useTypedSelector(state => state.lookups.users);
  const therapyTypes = useTypedSelector(state => state.lookups.therapyTypes || []);
  const therapyAdministrationStatuses = useTypedSelector(
    state => state?.therapyStatuses?.therapyAdministrationStatuses || [],
  );
  const enrollmentStatuses = useTypedSelector(
    state =>
      state.lookups.enrollmentStatuses.filter(i => ['Opt in', 'Opt out'].includes(i.status)) || [],
  );

  const therapyTypeOptions = useMemo(
    () => transformToOptions('id', 'type', therapyTypes),
    [therapyTypes],
  );
  const therapyAdministrationStatusOptions = useMemo(
    () =>
      transformToOptions(
        'id',
        (row: any) => [row.status, row.reason].filter(i => i !== null).join(' - '),
        therapyAdministrationStatuses.filter((i: any) =>
          [CATEGORY_OUTSTANDING, CATEGORY_IN_PROGRESS].includes(i.category_id),
        ),
      ),
    [therapyAdministrationStatuses],
  );
  const serviceGroupOptions = useMemo(
    () => transformToOptions('id', 'display_name', serviceGroups),
    [serviceGroups],
  );
  const customerOptions = useMemo(() => transformToOptions('id', 'name', customers), [customers]);
  const enrollmentStatusesOptions = useMemo(
    () => transformToOptions('id', 'status', enrollmentStatuses),
    [enrollmentStatuses],
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} />
      <Grid item xs={3} textAlign="right">
        <Button
          onClick={() => setDisplayAdvancedFilters(!displayAdvancedFilters)}
          variant="outlined"
        >
          Advanced search
        </Button>
      </Grid>
      <Grid item xs={3}>
        <FormControl>
          <TextField
            placeholder="Search by keyword"
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            id="outlined-basic"
          />
        </FormControl>
      </Grid>
      {displayAdvancedFilters && (
        <Grid item xs={12} sx={styles.advancedSearchWrapper}>
          <form>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={4}>
                <Field
                  name="from"
                  label="From"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="to"
                  label="To"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <Field
                  name="customer_id"
                  label="Site"
                  component={renderDropdown}
                  fields={customerOptions}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="service_group_id"
                  label="ServiceGroup"
                  component={renderMultipleDropdown}
                  fields={serviceGroupOptions}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="therapy_administration_status_id"
                  label="Administration Status"
                  component={renderMultipleDropdown}
                  fields={therapyAdministrationStatusOptions}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="sms_opt_out_status"
                  label="SMS Opt-out status"
                  component={renderDropdown}
                  fields={SmsOptions}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="therapy_type_id"
                  label="Therapy type"
                  component={renderMultipleDropdown}
                  fields={therapyTypeOptions}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="dispensing_decision_id"
                  label="Dispense Decision"
                  component={renderDropdown}
                  fields={enrollmentStatusesOptions}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="follow_up_date_from"
                  label="Follow Up Date From"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="follow_up_date_to"
                  label="Follow Up Date To"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="exclude_first_fill"
                  label="First Fill"
                  component={renderDropdown}
                  fields={FirstFillOptions}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="exclude_adherence_packaging"
                  label="Adherence Packaging"
                  component={renderDropdown}
                  fields={AdherencePackagingOptions}
                />
              </Grid>
              <Grid item xs={12}>
                <ConfirmationPanel
                  submitButtonText="Apply"
                  handleSubmit={handleSubmit((values: IBulkSmsSentTabFilters) => {
                    setDisplayAdvancedFilters(false);
                    setFilters(values);
                  })}
                  handleCancel={() => setDisplayAdvancedFilters(false)}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      )}
      <Grid item xs={12}>
        <BaseTable<ITableBulkSmsResponse, ITableBulkSms, undefined>
          actionsPermitted={false}
          enableParentCheckboxes={false}
          enableChildCheckboxes={false}
          childPkSelector={() => ''}
          paginationQueryParamSettings={PAGINATION_PARAMS}
          forcedQueryParams={queryForcedParams}
          parentPkSelector={parent => parent.id}
          endpointBase="/sms/batch"
          searchTerm=""
          columnSettings={[
            {
              label: 'Date',
              parentKey: 'created_dt',
              hideLeftPadding: true,
              sortable: true,
              parentValueFormatter: dateConvert,
              serverSideSearchField: 'created_dt',
            },
            {
              label: '# Recipients',
              parentKey: 'message',
              sortable: false,
              parentValueFormatter: (value: unknown) =>
                String((value as IBulkSmsRecipient[]).length),
            },
            {
              label: 'Message',
              parentKey: 'template_text',
              sortable: false,
            },
            {
              label: 'User',
              parentKey: 'created_by',
              sortable: false,
              parentValueFormatter: (value: unknown) => getUserById(value, users).username,
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export const SentBulkSmsTab = compose<Props, {}>(reduxForm({ form: FORM_NAME }))(SentBulkSmsTabCmp);

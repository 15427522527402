import { Theme } from '@mui/material';
import { DefaultTheme } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

export const SectionStyle = (theme: Theme|DefaultTheme) => {
  const { palette } = expandTheme(theme);
  return createStyles({
    sectionAccordion: {
      'marginTop': '0 !important',
      'marginBottom': '0 !important',
      'border': '1px solid white',
      'boxShadow': 'none',
      '&:before': {
        backgroundColor: 'transparent',
      },
    },
    sectionAccordionSummary: {
      '& > .MuiAccordionSummary-content': {
        alignItems: 'center',
      },
      'backgroundColor': palette.primary.greySubRow,
    },
    sectionAccordionTitle: {
      fontWeight: 500,
      width: '40%',
    },
    sectionAccordionDetails: {
      padding: theme.spacing(2),
    },
    sectionAccordionSubtitle: {
      width: '60%',
      textAlign: 'right',
      paddingRight: theme.spacing(1),
      color: palette.primary.deepChestnutRed,
      fontWeight: theme.typography.fontWeightMedium,
    },
    verifiedText: {
      color: '#70777A',
      fontWeight: '400',
      fontSize: '12px',
    },
  });
};

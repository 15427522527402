import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Switch, Route } from 'react-router-dom';
import Accreditation from 'containers/accreditation/accreditation';
import { styles } from '../nav-styles';

const AccreditationModule = ({ classes }) => (
  <div className={classes.accreditationContent}>
    <Switch>
      <Route component={Accreditation} />
    </Switch>
  </div>
);

export default withStyles(styles, { withTheme: true })(AccreditationModule);

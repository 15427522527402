import { AxiosResponse } from 'axios';
import { IPatient } from 'interfaces/redux/IPatient';
import { IContactList } from 'interfaces/redux/IContactList';
import { IUser } from 'interfaces/redux/IUser';
import { ITherapy } from 'interfaces/redux/ITherapy';
import { TherapyId } from 'interfaces/RecordTypes';
import { IDispensingPharmacy } from 'interfaces/redux/IDispensingPharmacy';
import { IUploadedDocument } from 'interfaces/redux/IUploadedDocuments';
import { noteTagTypes } from '../../../constants/lists';
import { b64toBlob, getGeneratedDocument } from '../../../services/utils/document-service';
import {
  uploadFile,
  addDocument,
  getDownloadUrl,
} from '../../../services/utils/upload-document-service';

import { IGeneratedDocumentResponse } from '../../../models/document_generator/IGeneratedDocumentResponse';
import { IAddDocumentResult } from '../../../models/services/documents/IAddDocumentResult';
import { IAddDocumentResponse } from '../../../models/services/documents/IAddDocumentResponse';
import { IDocumentPage } from '../../../models/document_generator/IDocumentPage';
import { IDownloadDocumentResponse } from '../../../models/services/documents/IDownloadDocumentResponse';
import { IViewableResult } from '../../../models/document_generator/IViewableResult';
import { DocumentGeneratorType } from './types';
import { GeneratorUtil } from './generator-util';

export abstract class Generator<TTaskType = any, T = Record<string, string>> {
  private tagTypeId: number;

  abstract metadataName(): string;

  abstract saveButtonText(tasks: any[], formValues: any[]): string | undefined;

  abstract showPreviewButton(tasks: any[], formValues: any[]): boolean;

  abstract buildTemplatePayload(
    task: TTaskType,
    patient: IPatient,
    contactList: IContactList,
    therapies: Record<TherapyId, ITherapy>,
    user: IUser,
    otherTasks: TTaskType[],
    dispensingPharmacy: IDispensingPharmacy,
    bundledDelivery: boolean,
  ): any;

  abstract documentLabels(): string[];

  constructor(
    private taskType: DocumentGeneratorType,
    protected customerId: number,
    protected patientId: number,
    private defaultFileName: string,
    protected defaultTemplateFileName: string,
    protected additionalTemplates?: T,
  ) {
    this.tagTypeId = noteTagTypes.find((x: any) => x.label === this.taskType)?.value as number;
  }

  /**
   * Generates a file and uploads it to s3
   */
  generateAndUploadToS3 = async (
    tasks: (TTaskType & { id: number; documents?: Record<string, any> })[],
    existingDocuments: IUploadedDocument[],
    patient: IPatient,
    contactList: IContactList,
    therapies: Record<TherapyId, ITherapy>,
    user: IUser,
    dispensingPharmacy: IDispensingPharmacy,
    bundledDelivery = true,
  ): Promise<IViewableResult> => {
    try {
      const generatedDocumentResult = await this.getGeneratedDocumentResult(
        tasks,
        patient,
        contactList,
        therapies,
        user,
        dispensingPharmacy,
        bundledDelivery,
      );
      const { generatedDocument, mimeType, fileName, fileType } = generatedDocumentResult.data;
      const blob = b64toBlob(generatedDocument, mimeType);
      const filename = `${fileName || this.defaultFileName}.${fileType}`;

      /**
       * contains info about task,
       * if it has a generated document
       * and the uuid of that document
       */
      const taskExistingDocumentStatus: Record<
        string,
        {
          alreadyGenerated: boolean;
          uuid: string | undefined;
          documentId: number | undefined;
        }
      > = {};

      /**
       * Since we only use one document for multiple tasks updated at the same time
       * we need to get the get the document id for the first task and associate that with
       * the other tasks
       */
      let uuid: string | undefined;
      const firstTask = tasks[0];
      const { id } = firstTask;
      const existingDocumentId = firstTask.documents
        ? firstTask.documents?.[this.metadataName()]
        : undefined;
      const existingDocument = existingDocuments.find(x => x.id === existingDocumentId);
      uuid = existingDocument?.uuid;
      const documentId = existingDocument?.id;

      taskExistingDocumentStatus[id] = {
        alreadyGenerated: !!existingDocumentId,
        uuid,
        documentId,
      };

      const uploadResult = await uploadFile(
        blob,
        filename,
        this.customerId,
        this.patientId,
        true,
        uuid,
      );

      if (!taskExistingDocumentStatus[id].alreadyGenerated) {
        taskExistingDocumentStatus[id] = uploadResult.uuid;
        uuid = uploadResult.uuid;
      }

      for (const task of tasks) {
        taskExistingDocumentStatus[task.id] = {
          alreadyGenerated: !!existingDocumentId,
          uuid,
          documentId,
        };
      }

      const saveResults: Partial<IAddDocumentResult>[] = [];
      const existingStatus = taskExistingDocumentStatus[firstTask.id];
      if (!existingStatus.alreadyGenerated) {
        const taskIds = tasks.map(x => x.id);
        const dbSaveResponse = await this.saveDocumentToDb(
          existingStatus.uuid as string,
          filename,
          this.documentLabels(),
          firstTask.id,
          true,
          true,
          taskIds,
        );
        const saveResult = dbSaveResponse.data.result[0];
        saveResults.push({ ...saveResult, alreadySaved: existingStatus.alreadyGenerated });
      } else {
        saveResults.push({ id: existingStatus.documentId, alreadySaved: true });
      }

      const downloadInfo = (await getDownloadUrl(
        this.customerId,
        this.patientId,
        saveResults[0].id,
      )) as AxiosResponse<IDownloadDocumentResponse>;

      return {
        url: downloadInfo.data.url,
        fileName: downloadInfo.data.filename,
        documentId: saveResults[0].id,
        saveResults: saveResults,
      };
    } catch (error) {
      console.error('Unable to save the generated document');
      throw error;
    }
  };

  convertToViewable = (documentResponse: IGeneratedDocumentResponse): IViewableResult => {
    return GeneratorUtil.convertToViewable(documentResponse, this.defaultFileName);
  };

  /**
   * Gets the generated response from services
   */
  getGeneratedDocumentResult = async (
    tasks: TTaskType[],
    patient: IPatient,
    contactList: IContactList,
    therapies: Record<TherapyId, ITherapy>,
    user: IUser,
    dispensingPharmacy: IDispensingPharmacy,
    bundledDelivery: boolean,
  ): Promise<AxiosResponse<IGeneratedDocumentResponse>> => {
    const taskList = bundledDelivery ? [tasks[0]] : tasks;
    const documentPages: IDocumentPage[] = taskList.map((task, index) => {
      const otherTasks = [...tasks];
      otherTasks.splice(index, 1);

      return {
        template: this.defaultTemplateFileName,
        data: this.buildTemplatePayload(
          task,
          patient,
          contactList,
          therapies,
          user,
          otherTasks,
          dispensingPharmacy,
          bundledDelivery,
        ),
      };
    });

    const result = await getGeneratedDocument(documentPages, this.customerId, this.patientId);
    return result;
  };

  /**
   * Saves a document to the database
   */
  saveDocumentToDb = async (
    uuid: string,
    fileName: string,
    labels: string[] | undefined,
    _taskId: number,
    safe: boolean | undefined,
    scanned: boolean | undefined,
    taskIds: number[],
  ): Promise<AxiosResponse<IAddDocumentResponse>> => {
    const tags = taskIds.map(id => {
      return {
        tag_type_id: this.tagTypeId,
        resource_id: id,
      };
    });

    const metadata: Record<string, any> = {};
    metadata[this.metadataName()] = true;

    return await addDocument(
      this.customerId,
      this.patientId,
      uuid,
      fileName,
      labels,
      tags,
      undefined,
      true,
      metadata,
      safe,
      scanned,
    );
  };
}

import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

export const styles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    addBenefitsInvestigationModal: {
      position: 'absolute',
      width: theme.spacing(160),
      backgroundColor: palette.primary.background,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
      borderRadius: 5,
      overflow: 'auto',
      maxHeight: '92vh',
    },
  });
};

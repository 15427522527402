import React from 'react';
import withStyles from '@mui/styles/withStyles';
import cx from 'classnames';
import { buildQaId } from '../../utils/build-qa-id';
import { IProps } from './interfaces/IProps';
import { styles } from './therapy-subtitle.styles';

const qaIdBuilder = buildQaId('therapy-subtitle');

const TherapySubTitle: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { classes } = props;

  const className = cx({
    [classes.therapy]: true,
    [classes.leftMargin]: props.hideLeftMargin !== true,
  });

  return (
    <div className={className} data-qa-id={qaIdBuilder('therapy-subtitle')}>
      <h3>{props.label}</h3>
    </div>
  );
};

export default withStyles(styles)(TherapySubTitle);

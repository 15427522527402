import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
  DUR_TRIGGER_CHECK_INTERACTION_REQUEST,
  ADD_ALLERGY,
  ADD_ALLERGY_FLOW,
  EDIT_ALLERGY,
  EDIT_ALLERGY_FLOW,
  NOTIFY_SUCCESS,
  NOTIFY_ERROR,
  INTERACTION_FINDER_TRIGGER,
} from '../constants';
import HTTP from '../services/http';

const allergenIdChanged = ({ previousAllergies, updatedAllergy, patientId, allergyId }) => {
  const previousAllergy = previousAllergies.find(
    allergy => allergy.patient_id === patientId && allergy.id === allergyId,
  );

  if (!previousAllergy || !updatedAllergy) {
    return false;
  }

  return previousAllergy.allergen.id !== updatedAllergy.allergen.id;
};

function* workerAddAllergyFlow({ payload }) {
  try {
    const url = `/patients/${payload.patient_id}/allergies/`;
    const request = HTTP.post(url, payload, {});
    const response = yield call(() => request);

    yield put({ type: ADD_ALLERGY, payload: response });

    if (!payload.noKnownAllergies) {
      yield put({
        type: DUR_TRIGGER_CHECK_INTERACTION_REQUEST,
        patientId: payload.patient_id,
        source: INTERACTION_FINDER_TRIGGER.Allergy,
        sourceValue: payload,
      });
    }

    yield put({
      type: NOTIFY_SUCCESS,
      payload: 'Data Updated Successfully',
    });
  } catch (err) {
    yield put({
      type: NOTIFY_ERROR,
      payload: 'Data Updated Failed',
    });
  }
}

function* workerEditAllergyFlow({ payload }) {
  try {
    const { patient_id: patientId, id: allergyId } = payload;

    const url = `/patients/${patientId}/allergies/${allergyId}`;
    const request = HTTP.patch(url, payload, {});
    const response = yield call(() => request);

    const {
      allergies: { allergiesList: previousAllergies },
    } = yield select();
    const isAllergenIdChanged = allergenIdChanged({
      previousAllergies,
      updatedAllergy: response?.data?.length ? response.data[0] : null,
      patientId,
      allergyId,
    });
    yield put({ type: EDIT_ALLERGY, payload: response });

    if (isAllergenIdChanged) {
      yield put({
        type: DUR_TRIGGER_CHECK_INTERACTION_REQUEST,
        patientId: payload.patient_id,
        source: INTERACTION_FINDER_TRIGGER.Allergy,
        sourceValue: payload,
      });
    }

    yield put({
      type: NOTIFY_SUCCESS,
      payload: 'Data Updated Successfully',
    });
  } catch (err) {
    yield put({
      type: NOTIFY_ERROR,
      payload: 'Data Updated Failed',
    });
  }
}

export function* watcherAllergiesSaga() {
  yield takeLatest(ADD_ALLERGY_FLOW, workerAddAllergyFlow);
  yield takeLatest(EDIT_ALLERGY_FLOW, workerEditAllergyFlow);
}

import React, { useState } from 'react';
import { Popover, Tooltip } from '@mui/material';
import { Edit as EditPencilIcon } from '@mui/icons-material';
import { useTypedStyles } from './action-row.styles';
import EditSmsTagsForm from './edit-sms-tags-form';

const EditSmsTags = props => {
  const { patientId, smsId } = props;
  const { classes } = useTypedStyles();

  const [anchor, setAnchor] = useState(false);

  const open = Boolean(anchor);

  const openEditTagsDialog = e => {
    e.stopPropagation();
    setAnchor(e.currentTarget);
  };

  const buttonEl = (
    <EditPencilIcon
      onClick={e => {
        openEditTagsDialog(e);
      }}
      className={classes.actionIconPending}
      fontSize="small"
    />
  );

  return (
    <>
      <Tooltip title="Edit tags">{buttonEl}</Tooltip>
      <Popover
        className={classes.editTagPopover}
        classes={{
          paper: classes.editTagPopover,
        }}
        id="edit-sms-tags"
        open={open}
        anchorEl={anchor}
        onClose={() => {
          setAnchor();
        }}
        onClick={e => {
          e.stopPropagation();
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.smsTagsForm}>
          <EditSmsTagsForm
            patientId={patientId}
            smsId={smsId}
            cancel={() => {
              setAnchor();
            }}
          />
        </div>
      </Popover>
    </>
  );
};
export default EditSmsTags;

import { createSelector } from '@reduxjs/toolkit';
import { IPatient } from 'interfaces/redux/IPatient';
import { IReconciliation } from 'interfaces/redux/IReconciliation';
import { IState } from 'interfaces/redux/IState';
import { isEqualStringIgnoreCase } from 'services/utils/common-service';

const getPatientDeceasedReconciliation = (patient: IPatient, reconciliation: IReconciliation) =>
  !patient.deceased && // if the patient is not marked as deceased
  !!reconciliation.stagingPatient.deceased && // but the incoming EMR says they are deceased
  !!window.FEATURES.patient_deceased_reconciliation && // use feature flag
  !!patient.needs_reconciliation;

const getReconciliationTotal = (reconciliation: IReconciliation) => {
  const total =
    Number(reconciliation.stagingPatientCards.length) +
    Number(reconciliation.stagingMedications.length) +
    Number(reconciliation.stagingAllergies.length);

  return total;
};

export const patientSelector = (state: IState) => state.patient;
export const reconciliationSelector = (state: IState) => state.reconciliation;
export const patientReconciliationTotalSelector = createSelector(
  reconciliationSelector,
  getReconciliationTotal,
);
export const patientNeedsReconciliationSelector = createSelector(
  patientSelector,
  patientReconciliationTotalSelector,
  (patient: IPatient, total: number) => !!patient.needs_reconciliation && !!total,
);
export const patientDeceasedReconciliationSelector = createSelector(
  patientSelector,
  reconciliationSelector,
  getPatientDeceasedReconciliation,
);
export const patientNeedsReconciliation = (reconciliation: IReconciliation, patient: IPatient) => {
  return !!patient.needs_reconciliation && !!getReconciliationTotal(reconciliation);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const compareAny = (str1: any, str2: any) => {
  const s1 = str1?.toString?.() ?? !!str1;
  const s2 = str2?.toString?.() ?? !!str2;
  return isEqualStringIgnoreCase(s1, s2);
};

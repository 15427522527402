import React from 'react';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as InterventionIcon } from 'lib/logos/intervention.svg';

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(1),
  },
}));

export function InterventionButton({ onClick, disabled }) {
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      onClick={onClick}
      disabled={disabled}
      data-qa-id="manual-intervention-button"
    >
      <InterventionIcon className={classes.icon} />
      Intervention
    </Button>
  );
}

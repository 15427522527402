import { WithStyles } from '@mui/styles';
import { DayAreaShippingStyles } from './day-area-shipping.styles';

export enum WeekdayNamesEnum {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday',
}

export const weekdayOrder = [
  WeekdayNamesEnum.MONDAY,
  WeekdayNamesEnum.TUESDAY,
  WeekdayNamesEnum.WEDNESDAY,
  WeekdayNamesEnum.THURSDAY,
  WeekdayNamesEnum.FRIDAY,
  WeekdayNamesEnum.SATURDAY,
  WeekdayNamesEnum.SUNDAY,
];

export interface IDayAreaShipping {
  weekday: WeekdayNamesEnum;
  zipCodes: string[];
}

export interface IDayAreaShippingModalForm {
  weekday?: WeekdayNamesEnum;
  zipCodes: string[];
}

export interface ITableProps {
  data: IDayAreaShipping[];
}

export interface IDayAreaShippingProps {
  loading: boolean;
  isMounted: boolean;
  selectedCustomerId: number;
  classes: Record<string, string>;
  handleModalAddWeekday: () => void;
  showAddWeekdayModal: boolean;
  submitRequestWeekday: (data: any) => void;
  setSelectedCustomerId: (customerId: number) => void;
  daysShipping: IDayAreaShipping[];
  handleSnackbar: () => void;
  snackbarMessage: string;
  setSnackbarMessage: (message: string) => void;
  showSnackbar: boolean;
}

export interface IRequestAddWeekday {
  weekday: WeekdayNamesEnum;
  zipCodes: string[];
  customerId: number;
}

export interface IDayAreaShippingModalProps extends WithStyles<typeof DayAreaShippingStyles> {
  open: boolean;
  close: () => void;
  submitRequestWeekday: (data: IDayAreaShipping) => void;
  daysShipping: IDayAreaShipping[];
  handleSnackbar: () => void;
  setSnackbarMessage: (message: string) => void;
}

enum FillDeliveryConfirmationStatus {
  Shipped = 7001,
  WillPickUp = 7002,
  Delivered = 7003,
  PickedUp = 7004,
  DeliveryException = 7005,
  Canceled = 7006,
  Dispense = 7007,
  Packed = 7008,
  NeedsReview = 7020,
  CanceledDeceased = 7099,
}

export type FillDeliveryConfirmationKeys = keyof typeof FillDeliveryConfirmationStatus;

export { FillDeliveryConfirmationStatus };

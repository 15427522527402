import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Grid, Typography, Divider } from '@mui/material';
import { IState } from 'interfaces/redux/IState';
import { useSelector } from 'react-redux';
import { addInput, removeInput, IPatientMergeInput } from 'actions/action-patient-merge';
import { LookupResourceTypes } from 'constants/enums';
import { PatientMergeClient } from 'clients/patient-merge';
import { ContactDemographicInfo } from 'components/patient-merge-modal/info-component/contact-demographic-info';
import { PreferencesDemographicInfo } from 'components/patient-merge-modal/info-component/preferences-demographic-info';
import { useTheme } from '@mui/styles';
import { EmailDemographicInfo } from 'components/patient-merge-modal/info-component/email-demographic-info';
import { PhoneDemographicInfo } from 'components/patient-merge-modal/info-component/phone-demographic-info';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import {
  IPatientMergeProps,
  IPatientMergeSectionDetailsProps,
  IProfileDemographicInfo,
} from 'components/patient-merge-modal/interfaces';
import {
  buildAddressLine,
  isInputSelected,
  isInputSelectedWithExtra,
} from 'components/patient-merge-modal/utils';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { isEmpty } from 'lodash';
import { AddressDemographicInfo } from 'components/patient-merge-modal/info-component/address-demographic-info';
import { ContactInformationHeader } from 'components/patient-merge-modal/info-component/contact-information-header';

type SelectedValue = number | string;

export const DemographicSection = function (props: IPatientMergeSectionDetailsProps) {
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);
  const { mergeId, expanded } = props;
  const [infos, setInfos] = useState<IProfileDemographicInfo[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [primaryData, setPrimaryData] = useState<IProfileDemographicInfo>();
  const [selectedData, setSelectedData] = useState<IProfileDemographicInfo>();
  const primary = useSelector((state: IState) => state.patientMerge.primary);
  const selectedProfileId = useSelector((state: IState) => state.patientMerge.duplicate);
  const inputs = useSelector((state: IState) => state.patientMerge.inputs);
  const [selectAll, setSelectAll] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [disableSelectAll, setDisableSelectAll] = useState(false);
  const dispatch = useAppDispatch();

  const getMergeDemographicInfo = async (): Promise<IProfileDemographicInfo[]> => {
    const response = await PatientMergeClient.getMergeDemographicInfo(mergeId);
    return response.data;
  };

  useEffect(() => {
    if (expanded && !loaded) {
      getMergeDemographicInfo().then(data => {
        setInfos(data);
        setLoaded(true);
      });
    }
  }, [mergeId, expanded]);

  useEffect(() => {
    setPrimaryData(infos.find(info => info.patientId === primary));
  }, [primary, infos]);

  useEffect(() => {
    setSelectedData(infos.find(info => info.patientId === selectedProfileId));
  }, [selectedProfileId, infos]);

  const selectValue = (value: boolean, type: LookupResourceTypes, selectedId: number) => {
    const input: IPatientMergeInput = {
      type,
      selectedId,
    };
    dispatch(value ? addInput(input) : removeInput(input));
  };

  const selectStringValue = (value: boolean, type: LookupResourceTypes, selectedValue: string) => {
    const input: IPatientMergeInput = {
      type,
      selectedId: 0,
      extraInfo: selectedValue,
    };
    dispatch(value ? addInput(input) : removeInput(input));
  };

  const selectContactValue = (
    value: boolean,
    type: LookupResourceTypes,
    contactId: number,
    selectedValue: string,
  ) => {
    const input: IPatientMergeInput = {
      type,
      selectedId: contactId,
      extraInfo: selectedValue,
    };
    dispatch(value ? addInput(input) : removeInput(input));
  };

  useEffect(() => {
    selectedData?.contacts?.forEach(x =>
      selectValue(selectAll, LookupResourceTypes.PatientContact, x.contactId),
    );
    checkAllCheckboxesForContacts(selectAll);
    selectedData?.patient?.emails?.forEach(email =>
      selectStringValue(selectAll, LookupResourceTypes.PatientEmail, String(email.value)),
    );
    selectedData?.patient?.phones?.forEach(phone =>
      selectStringValue(selectAll, LookupResourceTypes.PatientPhone, String(phone.value)),
    );
    selectedData?.patient?.addresses?.forEach(address =>
      selectStringValue(selectAll, LookupResourceTypes.PatientAddress, buildAddressLine(address)),
    );

    if (selectedData?.preference?.preferenceId) {
      selectValue(
        selectAll,
        LookupResourceTypes.PatientPreference,
        selectedData.preference.preferenceId,
      );
    }
  }, [selectAll]);

  useEffect(() => {
    if (selectedData && inputs) {
      const selectedCheckboxesCount =
        Number(selectedData.contacts?.length) +
        Number(selectedData.patient.emails?.length) +
        Number(selectedData.patient.phones?.length) +
        Number(selectedData.patient.addresses?.length) +
        Number(
          selectedData.contacts?.reduce((acc, contact) => acc + Number(contact?.emails?.length), 0),
        ) +
        Number(
          selectedData.contacts?.reduce((acc, contact) => acc + Number(contact?.phones?.length), 0),
        ) +
        Number(
          selectedData.contacts?.reduce(
            (acc, contact) => acc + Number(contact?.addresses?.length),
            0,
          ),
        ) +
        (selectedData.preference?.preferenceId ? 1 : 0);

      const demographicInputsCount = inputs.filter(({ type }) =>
        [
          LookupResourceTypes.PatientPhone,
          LookupResourceTypes.PatientAddress,
          LookupResourceTypes.PatientEmail,
          LookupResourceTypes.PatientContact,
          LookupResourceTypes.PatientContactEmail,
          LookupResourceTypes.PatientContactPhone,
          LookupResourceTypes.PatientContactAddress,
          LookupResourceTypes.PatientPreference,
        ].includes(type),
      ).length;
      setAllSelected(selectedCheckboxesCount === demographicInputsCount);
    }
  }, [selectedData, inputs]);

  const checkAllCheckboxesForContactHelper = (contact: any, checked: boolean) => {
    contact.emails?.forEach((item: any) => {
      selectContactValue(
        checked,
        LookupResourceTypes.PatientContactEmail,
        contact.contactId,
        String(item.value),
      );
    });
    contact.phones?.forEach((item: any) => {
      selectContactValue(
        checked,
        LookupResourceTypes.PatientContactPhone,
        contact.contactId,
        String(item.value),
      );
    });
    contact.addresses?.forEach((item: any) => {
      selectContactValue(
        checked,
        LookupResourceTypes.PatientContactAddress,
        contact.contactId,
        buildAddressLine(item),
      );
    });
  };

  const checkAllCheckboxesForContact = (contactId: number, checked: boolean) => {
    selectedData?.contacts
      ?.filter(contact => contact?.contactId === contactId)
      .forEach(contact => checkAllCheckboxesForContactHelper(contact, checked));
  };

  const checkAllCheckboxesForContacts = (checked: boolean) => {
    selectedData?.contacts.forEach(contact => checkAllCheckboxesForContactHelper(contact, checked));
  };

  const handleCheckbox =
    (type: LookupResourceTypes, selectedId: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (type === LookupResourceTypes.PatientContact && !e.target.checked) {
        checkAllCheckboxesForContact(selectedId, false);
      }
      selectValue(e.target.checked, type, selectedId);
    };

  const handleCheckboxWithExtra =
    (type: LookupResourceTypes, selectedExtra: string) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      selectStringValue(e.target.checked, type, selectedExtra);
    };
  const handleContactCheckbox =
    (type: LookupResourceTypes, selectedExtra: string, contactId: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        selectValue(true, LookupResourceTypes.PatientContact, contactId);
      }
      selectContactValue(e.target.checked, type, contactId, selectedExtra);
    };

  useEffect(() => {
    if (selectedData) {
      const selectedCheckboxesCount =
        selectedData.contacts?.length +
        selectedData.patient.emails?.length +
        selectedData.patient.phones?.length +
        Number(selectedData.patient.addresses?.length) +
        Number(
          selectedData.contacts?.reduce((acc, contact) => acc + Number(contact?.emails?.length), 0),
        ) +
        Number(
          selectedData.contacts?.reduce((acc, contact) => acc + Number(contact?.phones?.length), 0),
        ) +
        Number(
          selectedData.contacts?.reduce(
            (acc, contact) => acc + Number(contact?.addresses?.length),
            0,
          ),
        ) +
        (selectedData.preference?.preferenceId ? 1 : 0);
      setDisableSelectAll(selectedCheckboxesCount === 0);
    }
  }, [selectedData]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormControlLabel
          sx={classes.checkboxFormControl}
          label="Check all"
          control={
            <Checkbox
              sx={classes.checkbox}
              disabled={disableSelectAll}
              onChange={event => setSelectAll(event.target.checked)}
              checked={selectAll || allSelected}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={classes.sectionInputTitle}>Patient Information</Typography>
      </Grid>
      {/* Emails Duplicate */}
      <Grid item xs={6}>
        <Grid container rowSpacing={3}>
          {selectedData?.patient.emails?.map((email, idx) => (
            <Grid item xs={12}>
              <FormControlLabel
                sx={classes.checkboxFormControl}
                label={
                  <EmailDemographicInfo
                    key={`selected-${selectedData.patient.id}-email-${idx}`}
                    email={email}
                  />
                }
                control={
                  <Checkbox
                    sx={classes.checkbox}
                    checked={isInputSelectedWithExtra(
                      0,
                      String(email.value),
                      LookupResourceTypes.PatientEmail,
                      inputs,
                    )}
                    onChange={handleCheckboxWithExtra(
                      LookupResourceTypes.PatientEmail,
                      String(email.value),
                    )}
                  />
                }
              />
            </Grid>
          ))}

          {!selectedData?.patient.emails?.length && (
            <>
              <Grid item xs={12}>
                <Typography sx={classes.checkboxTitle}>Email</Typography>
              </Grid>
              <Grid item xs={12} sx={classes.textInfo}>
                -
              </Grid>
            </>
          )}
        </Grid>
        {/* /Emails Duplicate */}
      </Grid>

      {/* Emails Primary */}
      <Grid item xs={6}>
        <Grid container>
          {primaryData?.patient.emails?.map((email, idx) => (
            <Grid item xs={12}>
              <EmailDemographicInfo
                key={`primary-${primaryData.patient.id}-email-${idx}`}
                email={email}
              />
            </Grid>
          ))}
          {!primaryData?.patient.emails?.length && (
            <>
              <Grid item xs={12}>
                <Typography sx={classes.checkboxTitle}>Email</Typography>
              </Grid>
              <Grid item xs={12} sx={classes.textInfo}>
                -
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {/* /Emails Primary */}
      <Grid item xs={12}>
        <Divider sx={classes.divider} light />
      </Grid>
      {/* Phones Duplicate */}
      <Grid item xs={6}>
        <Grid container>
          {selectedData?.patient.phones?.map((phone, idx) => (
            <Grid item xs={12}>
              <FormControlLabel
                sx={classes.checkboxFormControl}
                label={
                  <PhoneDemographicInfo
                    key={`selected-${selectedData.patient.id}-phone-${idx}`}
                    phone={phone}
                  />
                }
                control={
                  <Checkbox
                    sx={classes.checkbox}
                    checked={isInputSelectedWithExtra(
                      0,
                      String(phone.value),
                      LookupResourceTypes.PatientPhone,
                      inputs,
                    )}
                    onChange={handleCheckboxWithExtra(
                      LookupResourceTypes.PatientPhone,
                      String(phone.value),
                    )}
                  />
                }
              />
            </Grid>
          ))}
        </Grid>
        {!selectedData?.patient.phones?.length && (
          <>
            <Grid item xs={12}>
              <Typography sx={classes.checkboxTitle}>Phone</Typography>
            </Grid>
            <Grid item xs={12} sx={classes.textInfo}>
              -
            </Grid>
          </>
        )}
      </Grid>
      {/* /Phones Duplicate */}

      {/* Phones Primary */}
      <Grid item xs={6}>
        <Grid container>
          {primaryData?.patient.phones?.map((phone, idx) => (
            <Grid item xs={12}>
              <PhoneDemographicInfo
                key={`primary-${primaryData.patient.id}-phone-${idx}`}
                phone={phone}
              />
            </Grid>
          ))}
          {!primaryData?.patient.phones?.length && (
            <>
              <Grid item xs={12}>
                <Typography sx={classes.checkboxTitle}>Phone</Typography>
              </Grid>
              <Grid item xs={12} sx={classes.textInfo}>
                -
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {/* /Phones Primary */}

      <Grid item xs={12}>
        <Divider sx={classes.divider} light />
      </Grid>

      {/* Address Duplicate */}
      <Grid item xs={6}>
        <Grid container>
          {selectedData?.patient.addresses?.map((address, idx) => (
            <Grid item xs={12}>
              <FormControlLabel
                sx={classes.checkboxFormControl}
                label={
                  <AddressDemographicInfo
                    key={`selected-${selectedData.patient.id}-address-${idx}`}
                    address={address}
                  />
                }
                control={
                  <Checkbox
                    sx={classes.checkbox}
                    checked={isInputSelectedWithExtra(
                      0,
                      buildAddressLine(address),
                      LookupResourceTypes.PatientAddress,
                      inputs,
                    )}
                    onChange={handleCheckboxWithExtra(
                      LookupResourceTypes.PatientAddress,
                      buildAddressLine(address),
                    )}
                  />
                }
              />
            </Grid>
          ))}
          {!selectedData?.patient.addresses?.length && (
            <>
              <Grid item xs={12}>
                <Typography sx={classes.checkboxTitle}>Address</Typography>
              </Grid>
              <Grid item xs={12} sx={classes.textInfo}>
                -
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {/* /Address Duplicate */}

      {/* Address Primary */}
      <Grid item xs={6}>
        <Grid container>
          {primaryData?.patient.addresses?.map((address, idx) => (
            <Grid item xs={12}>
              <AddressDemographicInfo
                key={`primary-${primaryData.patient.id}-address-${idx}`}
                address={address}
              />
            </Grid>
          ))}
          {!primaryData?.patient.addresses?.length && (
            <>
              <Grid item xs={12}>
                <Typography sx={classes.checkboxTitle}>Address</Typography>
              </Grid>
              <Grid item xs={12} sx={classes.textInfo}>
                -
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {/* /Address Primary */}

      <Grid item xs={12}>
        <Divider sx={classes.divider} light />
      </Grid>

      {/* Contacts */}
      <Grid item xs={12}>
        <Typography sx={classes.sectionInputTitle}>Contacts</Typography>
      </Grid>
      <Grid container item xs={6}>
        {selectedData?.contacts.map(contact => (
          <>
            {/* Contact Information */}
            <Grid item xs={12}>
              <FormControlLabel
                sx={classes.checkboxFormControl}
                label={
                  <ContactInformationHeader
                    contact={contact}
                    key={`selected-${contact.contactId}`}
                  />
                }
                control={
                  <Checkbox
                    sx={classes.checkbox}
                    value={contact.contactId}
                    checked={isInputSelected(
                      contact.contactId,
                      LookupResourceTypes.PatientContact,
                      inputs,
                    )}
                    onChange={handleCheckbox(LookupResourceTypes.PatientContact, contact.contactId)}
                  />
                }
              />
            </Grid>
            {/* /Contact Information */}

            <Grid item xs={12}>
              <Divider sx={classes.divider} light />
            </Grid>

            {/* Contact Addresses */}
            <Grid item xs={12}>
              {contact.addresses?.map((address, idx) => (
                <FormControlLabel
                  sx={classes.checkboxFormControl}
                  label={
                    <AddressDemographicInfo
                      key={`selected-${contact.contactId}-address-${idx}`}
                      address={address}
                    />
                  }
                  control={
                    <Checkbox
                      sx={classes.checkbox}
                      checked={isInputSelectedWithExtra(
                        contact.contactId,
                        buildAddressLine(address),
                        LookupResourceTypes.PatientContactAddress,
                        inputs,
                      )}
                      onChange={handleContactCheckbox(
                        LookupResourceTypes.PatientContactAddress,
                        buildAddressLine(address),
                        contact.contactId,
                      )}
                    />
                  }
                />
              ))}
            </Grid>
            {/* /Contact Addresses */}

            {/* Contact Emails */}
            <Grid item xs={12}>
              {contact.emails?.map((email, idx) => (
                <FormControlLabel
                  sx={classes.checkboxFormControl}
                  label={
                    <EmailDemographicInfo
                      key={`selected-${contact.contactId}-email-${idx}`}
                      email={email}
                    />
                  }
                  control={
                    <Checkbox
                      sx={classes.checkbox}
                      checked={isInputSelectedWithExtra(
                        contact.contactId,
                        String(email.value),
                        LookupResourceTypes.PatientContactEmail,
                        inputs,
                      )}
                      onChange={handleContactCheckbox(
                        LookupResourceTypes.PatientContactEmail,
                        String(email.value),
                        contact.contactId,
                      )}
                    />
                  }
                />
              ))}
            </Grid>
            {/* /Contact Emails */}

            {/* Contact Phones */}
            <Grid item xs={12}>
              {contact.phones?.map((phone, idx) => (
                <FormControlLabel
                  sx={classes.checkboxFormControl}
                  label={
                    <PhoneDemographicInfo
                      key={`selected-${contact.contactId}-phone-${idx}`}
                      phone={phone}
                    />
                  }
                  control={
                    <Checkbox
                      sx={classes.checkbox}
                      checked={isInputSelectedWithExtra(
                        contact.contactId,
                        String(phone.value),
                        LookupResourceTypes.PatientContactPhone,
                        inputs,
                      )}
                      onChange={handleContactCheckbox(
                        LookupResourceTypes.PatientContactPhone,
                        String(phone.value),
                        contact.contactId,
                      )}
                    />
                  }
                />
              ))}
            </Grid>
            {/* /Contact Phones */}
          </>
        ))}
      </Grid>

      <Grid item xs={6}>
        {primaryData?.contacts?.map(contact => (
          <ContactDemographicInfo
            keyPrefix="primary"
            key={`primary-${contact.contactId}`}
            contact={contact}
          />
        ))}
      </Grid>

      {/* /Contacts */}
      <Grid item xs={12}>
        <Divider sx={classes.divider} light />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={classes.sectionInputTitle}>
          Communication &amp; Rx delivery preference
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          {selectedData?.preference && (
            <FormControlLabel
              sx={classes.checkboxFormControl}
              label={
                <PreferencesDemographicInfo
                  preference={selectedData.preference}
                  patient={selectedData.patient}
                  contacts={selectedData.contacts}
                />
              }
              control={
                <Checkbox
                  sx={classes.checkbox}
                  value={selectedData.preference.preferenceId}
                  checked={isInputSelected(
                    selectedData.preference.preferenceId,
                    LookupResourceTypes.PatientPreference,
                    inputs,
                  )}
                  onChange={handleCheckbox(
                    LookupResourceTypes.PatientPreference,
                    selectedData.preference.preferenceId,
                  )}
                />
              }
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {primaryData?.preference && (
            <PreferencesDemographicInfo
              preference={primaryData.preference}
              patient={primaryData.patient}
              contacts={primaryData.contacts}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

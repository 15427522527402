import React from 'react';
import { IAddressDemographicInfoProps } from 'components/patient-merge-modal/interfaces';
import { Grid, Typography } from '@mui/material';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { useTheme } from '@mui/styles';

export const AddressDemographicInfo = (props: IAddressDemographicInfoProps) => {
  const { address } = props;
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);
  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <Typography sx={classes.checkboxTitle}>Address</Typography>
        {address.preferred && <Typography sx={classes.infoTag}>Preferred</Typography>}
      </Grid>
      <Grid item xs={6}>
        <Typography sx={classes.checkboxInfoLabel}>Type</Typography>
        <Typography sx={classes.checkboxInfoValue}>{address.use ?? '-'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={classes.checkboxInfoLabel}>Street</Typography>
        <Typography sx={classes.checkboxInfoValue}>
          {address.line2 ? `${address.line1}, ${address.line2}` : address.line1}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={classes.checkboxInfoLabel}>City</Typography>
        <Typography sx={classes.checkboxInfoValue}>{address.city}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={classes.checkboxInfoLabel}>State</Typography>
        <Typography sx={classes.checkboxInfoValue}>{address.state}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={classes.checkboxInfoLabel}>Zip</Typography>
        <Typography sx={classes.checkboxInfoValue}>{address.zip}</Typography>
      </Grid>
    </Grid>
  );
};

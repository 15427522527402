import React from 'react';
import moment from 'moment';
import withStyles from '@mui/styles/withStyles';
import { getUserById } from 'services/utils/users-service';
import { Typography, Grid } from '@mui/material';
import { convertToArborDate } from 'models/time/arbor-date';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { TherapyUtil } from 'utils/therapy-util';
import { IAdherenceHistoryData } from 'interfaces/redux/IAdherenceHistoryData';
import { ITherapy } from 'interfaces/redux/ITherapy';

import { styles } from './adherence-history-review-styles';

interface IAdherenceHistoryProps {
  snapShot: IAdherenceHistoryData;
  classes: any;
}

const buildMultiValueString = (
  input: string | number[] | null,
  reference: any,
  keyName: string,
  referenceKey: string | null = null,
) => {
  if (!input) {
    return '-';
  }
  let finalReference = reference;
  // if there is a referenceKey passed, re-index the reference by that
  // key to make lookup by that value easier.
  if (referenceKey) {
    finalReference = {};
    reference.map((item: any) => {
      finalReference[item[referenceKey]] = item;
    });
  }
  let arrInputValues = [];
  if ((input as Array<number>).map) {
    arrInputValues = input as Array<number>;
  } else {
    arrInputValues = ((input as string) || '').split(',');
  }
  const outputString =
    arrInputValues.map(id => (finalReference[id] || {})[keyName] || null).join(', ') || '-';

  return outputString;
};

const AdherenceHistoryReviewContainer = (props: IAdherenceHistoryProps): JSX.Element => {
  const { classes, snapShot } = props;
  const users = useTypedSelector(state => state.lookups.users);
  const interventionSeverities = useTypedSelector(
    state => state?.taskStatuses?.intervention_severities,
  );
  const serviceGroups = useTypedSelector(state => state?.lookups.serviceGroups);
  const patientTherapies = useTypedSelector(state => state.therapies.data);
  const reasons = useTypedSelector(state => state.taskStatuses.intervention_reasons);
  const recommendations = useTypedSelector(
    state => state.taskStatuses.intervention_recommendations,
  );
  const outcomes = useTypedSelector(state => state.taskStatuses.intervention_outcomes);
  const statusText = snapShot.task_status_text || 'Updated';

  const interventionSeverity =
    (interventionSeverities || []).find(item => item.id === snapShot.severity_id)?.severity_name ||
    '-';
  const serviceGroup =
    serviceGroups.find(item => item.id === snapShot.service_group_id)?.display_name || '-';
  // create an array of therapy ids in this Intervention
  const arrTherapyIds = snapShot.therapy_ids ? snapShot.therapy_ids.split(',') : [];
  // create an array of therapies in this Intervention
  const arrTherapies: ITherapy[] = [];
  arrTherapyIds.forEach(
    item => patientTherapies[Number(item)] && arrTherapies.push(patientTherapies[Number(item)]),
  );

  const therapyNames =
    arrTherapies.map(item => TherapyUtil.therapyFormatDosageAndForm(item)).join(', ') || '-';
  const reasonReference = reasons.filter(item =>
    (snapShot.reason || '').split(',').includes(String(item.id)),
  );
  const reasonType = reasonReference[0]?.reason_subtype;
  const reason = reasonReference.map(item => item.reason_name).join(', ') || '-';
  const recommendation = buildMultiValueString(
    snapShot.recommendation,
    recommendations,
    'recommendation_name',
    'id',
  );
  const objOutcome = outcomes.find(item => Number(item.id) === Number(snapShot.outcome));
  const outcomeType = objOutcome?.outcome_subtype || '-';
  const outcome = objOutcome?.outcome_name || '-';
  const reasonDetail = snapShot.additional_reason || '-';
  const updateTime =
    snapShot.updated && convertToArborDate(snapShot.updated)
      ? convertToArborDate(snapShot.updated).getCustomerDatetime(true)
      : '';
  const reasonStartDate =
    snapShot.reason_start_date && convertToArborDate(snapShot.reason_start_date)
      ? convertToArborDate(snapShot.reason_start_date).getCustomerDate(true)
      : '-';

  const reasonEndDate =
    snapShot.reason_end_date && convertToArborDate(snapShot.reason_end_date)
      ? convertToArborDate(snapShot.reason_end_date).getCustomerDate(true)
      : '-';

  return (
    <Grid container className={classes.adherenceInstanceOuterContainer}>
      <Grid item xs={12} className={classes.adherenceInstanceTitleContainer}>
        <Typography className={classes.adherenceInstanceTitle}>
          Adherence ({moment(snapShot.updated).format('MM-DD-YYYY')})
        </Typography>
      </Grid>
      <Grid container item xs={12} className={classes.adherenceInstanceInnerContainer}>
        <Grid item container xs={12} className={classes.adherenceInstanceInnerTitleContainer}>
          <Typography className={classes.adherenceInstanceInnerTitle} component="span">
            Intervention
          </Typography>
          <Typography className={classes.adherenceInstanceUpdateMarker} component="span">
            {statusText} {updateTime} by {getUserById(snapShot.updated_by, users).username || '-'}
          </Typography>
        </Grid>
        <Grid item container className={classes.adherenceInstanceRow} xs={12}>
          <Grid item xs={3}>
            <Typography className={classes.adherenceInstanceLabel}>Severity</Typography>
            <Typography className={classes.adherenceInstanceValue}>
              {interventionSeverity}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.adherenceInstanceLabel}>Service Group</Typography>
            <Typography className={classes.adherenceInstanceValue}>{serviceGroup}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.adherenceInstanceLabel}>Therapies</Typography>
            <Typography className={classes.adherenceInstanceValue}>{therapyNames}</Typography>
          </Grid>
        </Grid>
        <Grid item container className={classes.adherenceInstanceRow} xs={12}>
          <Grid item xs={3}>
            <Typography className={classes.adherenceInstanceLabel}>Reason Type</Typography>
            <Typography className={classes.adherenceInstanceValue}>{reasonType}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.adherenceInstanceLabel}>Reason</Typography>
            <Typography className={classes.adherenceInstanceValue}>{reason}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.adherenceInstanceLabel}>Reason Detail</Typography>
            <Typography className={classes.adherenceInstanceValue}>{reasonDetail}</Typography>
          </Grid>
        </Grid>
        <Grid item container className={classes.adherenceInstanceRow} xs={12}>
          <Grid item xs={3}>
            <Typography className={classes.adherenceInstanceLabel}>Start Date</Typography>
            <Typography className={classes.adherenceInstanceValue}>{reasonStartDate}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.adherenceInstanceLabel}>End Date</Typography>
            <Typography className={classes.adherenceInstanceValue}>{reasonEndDate}</Typography>
          </Grid>
        </Grid>
        <Grid item container className={classes.adherenceInstanceRow} xs={12}>
          <Grid item xs={12}>
            <Typography className={classes.adherenceInstanceLabel}>Recommendation</Typography>
            <Typography className={classes.adherenceInstanceValue}>{recommendation}</Typography>
          </Grid>
        </Grid>
        <Grid item container className={classes.adherenceInstanceRow} xs={12}>
          <Grid item xs={3}>
            <Typography className={classes.adherenceInstanceLabel}>Outcome Type</Typography>
            <Typography className={classes.adherenceInstanceValue}>{outcomeType}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography className={classes.adherenceInstanceLabel}>Outcome</Typography>
            <Typography className={classes.adherenceInstanceValue}>{outcome}</Typography>
          </Grid>
        </Grid>
        <Grid item container className={classes.adherenceInstanceRow} xs={12}>
          <Grid item xs={12}>
            <Typography className={classes.adherenceInstanceLabel}>Details</Typography>
            <Typography className={classes.adherenceInstanceValue}>
              {snapShot.details || '-'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(AdherenceHistoryReviewContainer);

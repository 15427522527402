/* eslint-disable prefer-destructuring */
import { takeEvery, select, call, put } from 'redux-saga/effects';
import { MedicationsClient } from 'clients/medications-client';
import {
  BULK_TASKS_UPDATED,
  EDIT_THERAPY,
  FETCH_MEDICATION_GROUPS_ONLY,
  FETCH_MEDICATION_GROUPS_ONLY_CURSOR,
  MERGE_MEDICATIONS,
  REFRESH_MEDICATIONS,
  SELECTED_PATIENT_ID,
  UPDATE_MEDICATION_SUCCESS,
  UPDATE_MEDICATION_VERIFICATION_STATUS_SUCCESS,
} from '../constants';
import HTTP from '../services/http';

function* workerFetchPartialMedicationGroupList() {
  const state = yield select();
  const medicationGroupCursor = state.medications.medicationGroupsCursor;
  const pageSize = state.settings.medication_group_pagination;

  if (state.selectedPatientId && medicationGroupCursor !== null) {
    try {
      const response = yield call(
        HTTP.get,
        MedicationsClient.getPaginatedGroupsUrl(
          state.selectedPatientId,
          pageSize,
          medicationGroupCursor,
        ),
      );
      yield put({
        type: FETCH_MEDICATION_GROUPS_ONLY,
        payload: response,
        meta: { patientId: state.selectedPatientId },
      });
      yield put({
        type: FETCH_MEDICATION_GROUPS_ONLY_CURSOR,
        payload: response,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Failed to load medication groups', e);
    }
  }
}

function* workerUpdateMedicationVerification() {
  const { selectedPatientId } = yield select();
  if (selectedPatientId) {
    try {
      const url = `/patients/${selectedPatientId}/medications-verification-status`;
      const response = yield call(HTTP.get, url);
      yield put({
        type: UPDATE_MEDICATION_VERIFICATION_STATUS_SUCCESS,
        payload: response,
        meta: { patientId: selectedPatientId },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Failed to update medication verification status', e);
    }
  }
}

function* workerRefreshMedications() {
  yield put({ type: REFRESH_MEDICATIONS });
}

export function* watcherClinicalDataSaga() {
  yield takeEvery(
    [EDIT_THERAPY, UPDATE_MEDICATION_SUCCESS, MERGE_MEDICATIONS],
    workerUpdateMedicationVerification,
  );

  // load medication list and medication groups
  yield takeEvery(
    [SELECTED_PATIENT_ID, REFRESH_MEDICATIONS, EDIT_THERAPY],
    workerFetchPartialMedicationGroupList,
  );
  yield takeEvery([BULK_TASKS_UPDATED], workerRefreshMedications);

  // When cursor updates, load medication list and medication groups again
  yield takeEvery([FETCH_MEDICATION_GROUPS_ONLY_CURSOR], workerFetchPartialMedicationGroupList);
}

import { createStyles } from '@mui/styles';

export const styles = () =>
  createStyles({
    loading: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: 'rgb(255, 255, 255, 0.6)',
      zIndex: 2,
      opacity: 0,
      transition: 'opacity 4s',
    },
  });

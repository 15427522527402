export const searchFieldPlaceholder = 'Search by clinic or TTC';
export const debounceTimeMs = 500;
export const initialSearchTerm = '';

export const PAGINATION_PARAMS = {
  pageSizeQueryStringKey: 'pageSize',
  pageNumberQueryStringKey: 'pageNumber',
  searchTermQueryStringKey: 'searchTerm',
  sortPropQueryStringKey: 'sortProp',
  sortOrderQueryStringKey: 'sortOrder',
};

export const ArConstants = {
  defaultRowsPerPage: 10,
};

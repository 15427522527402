import React, { FC } from 'react';
import { MobilePhoneSmsEnum } from 'constants/enums';
import IconWithTooltip from 'containers/patient/patient-overview/icon-with-tooltip';
import { IPhone, PhoneUsage } from 'interfaces/redux/IPhone';

interface IIconPhoneOptIn {
  phones: IPhone[];
  sourceNumber: string;
  patientAutoFCStatus: number | null;
  anchorTherapy: any;
}

const IconPhoneOptIn: FC<IIconPhoneOptIn> = ({
  phones,
  sourceNumber,
  patientAutoFCStatus,
  anchorTherapy,
}) => {
  const mobilePhones = phones?.filter(phone => phone.use === PhoneUsage.Mobile && !phone.deleted);

  const autoFCAnchorTherapy = `${anchorTherapy.drug_name} ${anchorTherapy.strength} ${anchorTherapy.strength_unit_of_measure} ${anchorTherapy.dosage_form_description}`;
  if (mobilePhones.length === 0) {
    return <IconWithTooltip state="no_mobile_numbers" tooltip="No mobile number on file" />;
  }

  let smsStopDate;
  const customerSentOptOutMessage = mobilePhones?.some(item => {
    smsStopDate = item.sms_stop_date;
    return item.is_opt_in_sent && item.patient_choice && smsStopDate;
  });

  if (customerSentOptOutMessage) {
    return (
      <IconWithTooltip
        state="patient_sent_stop"
        tooltip={`SMS Opted Out. - Patient sent STOP-(${
          smsStopDate || ''
        }).\nTo Opt In Patient needs to send START to ${sourceNumber}`}
      />
    );
  }

  if (
    mobilePhones?.some(phone => phone.sms === MobilePhoneSmsEnum.OptIn && !phone.is_opt_in_sent)
  ) {
    return (
      <IconWithTooltip
        state="opt_in_message_not_sent"
        tooltip="SMS Opt-in, opt-in message has not been sent"
      />
    );
  }

  if (mobilePhones?.every(phone => phone.sms === MobilePhoneSmsEnum.NotSpecified)) {
    return (
      <IconWithTooltip
        state="sms_not_specified"
        tooltip="SMS Opt-in status has not been selected"
      />
    );
  }

  if (
    mobilePhones?.every(phone => phone.sms !== MobilePhoneSmsEnum.OptIn) &&
    mobilePhones?.filter(phone => phone.sms === MobilePhoneSmsEnum.OptOut && !phone.sms_stop_date)
      .length > 0
  ) {
    return <IconWithTooltip state="sms_opt_out" tooltip="Patient selected SMS Opt-out" />;
  }

  if (
    mobilePhones?.some(
      phone => phone.sms === MobilePhoneSmsEnum.OptIn && phone.patient_choice === undefined,
    )
  ) {
    return (
      <IconWithTooltip
        state="opt_in_sent_waiting_patient_response"
        tooltip="SMS Opt-in - Waiting for patient confirmation"
      />
    );
  }
  const optInComplete = mobilePhones?.some(
    phone => phone.sms === MobilePhoneSmsEnum.OptIn && phone.patient_choice,
  );
  if (optInComplete && (patientAutoFCStatus === 0 || patientAutoFCStatus === null)) {
    return <IconWithTooltip state="sms_only" tooltip="SMS Opt-in / Secure Link Opt-out" />;
  }
  if (optInComplete && patientAutoFCStatus === 1) {
    return (
      <IconWithTooltip
        state="manual_secure_link"
        tooltip="SMS Opt-in / Secure Link Opt-in (Manual)"
      />
    );
  }
  if (optInComplete && patientAutoFCStatus === 2) {
    return (
      <IconWithTooltip
        state="auto_secure_link"
        tooltip={
          <div>
            SMS Opt-in
            <br />
            Automated Secure Link Opt-in
            <br />
            Anchor Therapy: {autoFCAnchorTherapy}
          </div>
        }
      />
    );
  }
  if (
    mobilePhones?.some(phone => phone.sms === MobilePhoneSmsEnum.OptOut && !phone.sms_stop_date)
  ) {
    return <IconWithTooltip state="sms_opt_out" tooltip="SMS Opt-out" />;
  }

  if (mobilePhones?.some(item => !item.patient_choice && item.is_opt_in_sent)) {
    return (
      <IconWithTooltip
        state="opt_in_sent_waiting_patient_response"
        tooltip="SMS Opt-in - Waiting for patient confirmation"
      />
    );
  }

  return <IconWithTooltip state="provided" tooltip="SMS Opt-in" />;
};

export default IconPhoneOptIn;

import React from 'react';
import useProvider from 'hooks/useProvider';
import TreeSelect from '../../form/tree-select/tree-select';

const suggestedDataTypes = [];

const HookedSelectCheckbox = ({ input, ...other }) => (
  <TreeSelect
    onChange={input.onChange}
    onBlur={() => input.onBlur()}
    suggestedDataTypes={suggestedDataTypes}
    treeData={input.treeData}
    value={input.value}
    suggestionLabel={input.suggestionLabel}
    isSuggestedOptions={false}
    {...other}
  />
);

export default ({ providers, field, label, input, meta, disabled, labelSize, qaId }) => {
  const fields = useProvider(providers, field.provider, []);

  return (
    <HookedSelectCheckbox
      label={label}
      input={input}
      meta={meta}
      disabled={disabled}
      labelSize={labelSize}
      qaId={qaId}
      treeData={fields}
      suggestionLabel={field.suggestionLabel}
      title={field.label}
    />
  );
};

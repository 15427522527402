import React, { Component } from 'react';
import { Snackbar, SnackbarContent, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { processNotification } from 'actions/action-notifications';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Portal from '@mui/material/Portal';
import { styles } from './notification-styles';

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isProcessing: false,
      messageType: null,
      message: null,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleExited = this.handleExited.bind(this);
    this.processQueue = this.processQueue.bind(this);
    this.handleAction = this.handleAction.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { isProcessing } = this.state;
    const { notifications } = this.props;
    if (notifications.length > prevProps.notifications.length && !isProcessing) {
      this.processQueue();
    }
  }

  processQueue() {
    const { notifications, processNotification } = this.props; // eslint-disable-line
    if (notifications.length) {
      const notification = notifications[0];
      const {
        type: messageType,
        msg: message,
        actionName,
        actionPayload,
        actionText,
      } = notification;
      const timeout = notification.timeout ?? 4000;
      this.setState({
        open: true,
        isProcessing: true,
        messageType,
        message,
        timeout,
        actionName,
        actionPayload,
        actionText,
      });
      processNotification();
    } else {
      this.setState({ open: false, isProcessing: false });
    }
  }

  handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
  }

  handleExited() {
    this.processQueue();
  }

  handleAction() {
    const { actionName, actionPayload } = this.state;
    const { dispatchAction } = this.props;
    dispatchAction(actionName, actionPayload);
  }

  render() {
    const { open, messageType, message, timeout, actionName, actionText } = this.state;
    const { classes } = this.props;

    let notificationClasses;
    switch (messageType) {
      case 'warning':
        notificationClasses = classNames(classes.warning);
        break;
      case 'error':
        notificationClasses = classNames(classes.error);
        break;
      case 'success':
      default:
        notificationClasses = classNames(classes.success);
        break;
    }

    return (
      <Portal>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          autoHideDuration={timeout || 250}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          TransitionProps={{
            onExited: this.handleExited,
          }}
        >
          <SnackbarContent
            onClose={this.handleClose}
            className={notificationClasses}
            message={message}
            data-qa-id="snackbar"
            data-type={messageType}
            action={
              (actionName && actionText && (
                <Button color="secondary" size="small" onClick={this.handleAction}>
                  {actionText}
                </Button>
              )) || (
                <IconButton
                  size="small"
                  aria-label="close notification"
                  color="inherit"
                  onClick={this.handleClose}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              )
            }
          />
        </Snackbar>
      </Portal>
    );
  }
}

function mapStateToProps(state) {
  const { notifications } = state;
  return { notifications };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchAction(type, payload) {
      dispatch({ type, payload });
    },
    processNotification() {
      dispatch(processNotification());
    },
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(Notification);

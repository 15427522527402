import AccordionHeader from 'components/form/header/accordion-header';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { FC } from 'react';
import { ENSURE_EMERGENCY_CONTACT_ADDRESSED } from 'constants/index';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { styles } from './patient-demographics-styles';

type IProps = WithStyles<typeof styles>

const NoContacts: FC<IProps> = (props: IProps) => {
  const { classes } = props;

  return (
    <div className={classes.paddedSection}>
      <Accordion defaultExpanded>
        <AccordionSummary
          classes={{
            expandIconWrapper: classes.expandIcon,
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          {/* @ts-ignore */}
          <AccordionHeader
            hideEdit
            name="Contact"
            data-qa-id="empty-contact-section"
            auditRules={[ENSURE_EMERGENCY_CONTACT_ADDRESSED]}
          />
        </AccordionSummary>
        <AccordionDetails>
          <strong>No contacts for this patient</strong>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default withStyles(styles)(NoContacts);

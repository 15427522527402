import { IMedSyncRowData } from 'models/meds/IMedSync';
import { MouseEvent, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment, { Moment } from 'moment';
import { difference, sortBy } from 'lodash';
import { findLargestGroup } from '../../med-sync-utils';
import { THERAPY_ADHERENCE_PACKAGE, THERAPY_MED_SYNC } from '../../../../../constants';
import { IMedSyncFormFields, IMedSyncPreferences } from '../../common';

interface UseMedSyncModalArgs {
  currentMedSyncData: IMedSyncRowData[];
  currentMedSyncPreferences: IMedSyncPreferences;
}
export type Order = 'asc' | 'desc';
export type SelectAllType = 'none' | 'all' | 'partial';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof IMedSyncRowData>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: null | number | string | boolean | Date | undefined },
  b: { [key in Key]: null | number | string | boolean | Date | undefined },
) => number {
  return order === 'asc'
    ? (a, b) => -descendingComparator(a, b, orderBy)
    : (a, b) => descendingComparator(a, b, orderBy);
}

const getSelectAllGroup = (
  selectedIds: number[],
  data: IMedSyncRowData[],
  originalData = data,
): IMedSyncRowData[] => {
  const group = findLargestGroup(data, 'daysSupply');
  if (selectedIds.length === 0) return group;
  const groupIds = group.map(i => i.id);
  const otherData = data.filter(i => !groupIds.includes(i.id));
  const hasSelectedIds = selectedIds.every(id => groupIds.includes(id));
  if (hasSelectedIds) {
    return group;
  }
  if (otherData.length) return getSelectAllGroup(selectedIds, otherData, originalData);
  return originalData;
};

const isInMedSync = (item: IMedSyncRowData) => item.inMedSync === THERAPY_MED_SYNC.IN_MED_SYNC;
const hasAdherencePack = (item: IMedSyncRowData) =>
  item.adherencePack === THERAPY_ADHERENCE_PACKAGE.ADHERENCE_PACKAGE_REQUIRED;

function useMedSyncModal({ currentMedSyncData, currentMedSyncPreferences }: UseMedSyncModalArgs) {
  const form = useForm({});
  const [workingSyncTimeFrame, setWorkingSyncTimeFrame] = useState<number | null>(
    currentMedSyncPreferences.syncTimeFrame ?? null,
  );
  const [workingAnchorDate, setWorkingAnchorDate] = useState<Moment | null>(
    currentMedSyncPreferences.anchorDate ? moment.utc(currentMedSyncPreferences.anchorDate) : null,
  );

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IMedSyncRowData>('drugName');
  const [workingData, setData] = useState<IMedSyncRowData[]>(currentMedSyncData.slice());

  const handleRequestSort = (event: MouseEvent<unknown>, property: keyof IMedSyncRowData) => {
    const isAsc = orderBy === property && order === 'asc';
    const nextOrder = isAsc ? 'desc' : 'asc';
    setOrder(nextOrder);
    setOrderBy(property);
  };

  const isEdited = useMemo(
    () =>
      JSON.stringify(sortBy(workingData, 'id')) !==
      JSON.stringify(sortBy(currentMedSyncData, 'id')),
    [workingData, currentMedSyncData],
  );

  const handleChangeAnchorDate = (value: any) => {
    form.setValue('anchorDate', value);
    form.clearErrors();
    setWorkingAnchorDate(value);
    const nextMedSyncData = workingData.map(item => {
      if (isInMedSync(item)) {
        const nbd =
          value?.toISOString() ?? currentMedSyncData.find(t => t.id === item.id)?.nbd ?? '';
        return {
          ...item,
          nbd,
        };
      }
      return item;
    });
    setData(nextMedSyncData);
  };

  const setSyncTimeFrame = (value: any) => {
    form.setValue('syncTimeFrame', value === null ? '' : value);
    setWorkingSyncTimeFrame(value);
  };

  const optInIds = (ids: number[]) => {
    const nextOptInItems = workingData.filter(i => ids.includes(i.id));
    const nextSyncTimeFrame = nextOptInItems.length
      ? Math.min(...nextOptInItems.map(item => item.daysSupply))
      : null;

    const nextOptInIds = nextOptInItems.map(i => i.id);
    const newMedSyncData = workingData.map(currentItem => {
      const nextItem = { ...currentItem };
      const isOptIn = nextOptInIds.includes(currentItem.id);

      if (isOptIn) {
        nextItem.inMedSync = THERAPY_MED_SYNC.IN_MED_SYNC;
        if (workingAnchorDate) nextItem.nbd = workingAnchorDate.toISOString();
      } else {
        nextItem.inMedSync = THERAPY_MED_SYNC.NOT_IN_MED_SYNC;
        nextItem.adherencePack = THERAPY_ADHERENCE_PACKAGE.ADHERENCE_PACKAGE_NOT_REQUIRED;
        const prevNbd = currentMedSyncData.find(t => t.id === currentItem.id)?.nbd;
        if (prevNbd) nextItem.nbd = prevNbd;
      }
      nextItem.inConflict =
        isInMedSync(nextItem) &&
        nextSyncTimeFrame !== null &&
        currentItem.daysSupply % nextSyncTimeFrame !== 0;
      return nextItem;
    });

    setData(newMedSyncData);
    if (nextSyncTimeFrame !== workingSyncTimeFrame) {
      setSyncTimeFrame(nextSyncTimeFrame);
    }
  };

  const selectAllValue = useMemo<SelectAllType>(() => {
    const idsInMedSync = workingData.filter(isInMedSync).map(i => i.id);
    const idsInGroup = getSelectAllGroup(idsInMedSync, workingData).map(i => i.id);
    const groupFull = difference(idsInGroup, idsInMedSync).length === 0;

    if (idsInMedSync.length === 0) {
      return 'none';
    }
    if (groupFull) {
      return 'all';
    }
    return 'partial';
  }, [workingData]);

  const handleClickSelectAll = () => {
    if (selectAllValue === 'partial') {
      const idsInMedSync = workingData.filter(isInMedSync).map(i => i.id);
      optInIds(getSelectAllGroup(idsInMedSync, workingData).map(i => i.id));
    } else if (selectAllValue === 'none') {
      optInIds(findLargestGroup(workingData, 'daysSupply').map(i => i.id));
    } else {
      optInIds([]);
    }
  };

  const handleSelectId = (therapyId: number) => {
    const currentItem = workingData.find(item => item.id === therapyId);
    if (!currentItem) return;
    const isItemInMedSync = isInMedSync(currentItem);
    const currentItemsInMedSync = workingData.filter(isInMedSync);

    const optInItemIds = (
      isItemInMedSync
        ? currentItemsInMedSync.filter(i => i.id !== therapyId)
        : currentItemsInMedSync.concat(currentItem)
    ).map(i => i.id);

    optInIds(optInItemIds);
  };

  const handleSelectAdherencePack = (therapyId: number) => {
    const nextMedSyncState = workingData.map(item => {
      if (item.id === therapyId) {
        return {
          ...item,
          adherencePack: hasAdherencePack(item)
            ? THERAPY_ADHERENCE_PACKAGE.ADHERENCE_PACKAGE_NOT_REQUIRED
            : THERAPY_ADHERENCE_PACKAGE.ADHERENCE_PACKAGE_REQUIRED,
        };
      }
      return item;
    });
    setData(nextMedSyncState);
  };

  const reset = () => {
    setData(currentMedSyncData);
    setWorkingSyncTimeFrame(currentMedSyncPreferences.syncTimeFrame ?? null);
    setWorkingAnchorDate(
      currentMedSyncPreferences.anchorDate
        ? moment.utc(currentMedSyncPreferences.anchorDate)
        : null,
    );
  };

  const sortedData = useMemo(
    () => [...workingData].sort(getComparator(order, orderBy)).slice(),
    [workingData, order, orderBy],
  );

  return {
    modalMedSyncData: sortedData,
    modalAnchorDate: workingAnchorDate,
    modalSyncTimeFrame: workingSyncTimeFrame,
    handleChangeAnchorDate,
    handleSelectId,
    handleClickSelectAll,
    handleSelectAdherencePack,
    handleRequestSort,
    selectAllValue,
    order,
    orderBy,
    form,
    reset,
    isEdited,
  };
}

export default useMedSyncModal;

import makeStyles from '@mui/styles/makeStyles';
import { expandTheme } from 'utils/theme-util';
import { ModalUtil } from 'services/utils/modal.util';

export const styles = makeStyles(theme => {
  const { font } = expandTheme(theme);
  return {
    modal: {
      ...ModalUtil.BasicModalStyling(theme, 130, 1),
      width: theme.spacing(300),
    },
    title: {
      fontWeight: 'bold',
      fontSize: font.extraLargeFontSize,
      marginBottom: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
    textFieldInput: {
      paddingTop: theme.spacing(0.9),
      paddingBottom: theme.spacing(0.9),
    },
    header: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      fontWeight: 400,
      fontSize: theme.spacing(2.1),
    },
    headerContainer: {
      backgroundColor: theme.palette.primary.bluesky,
      marginTop: -8,
      marginLeft: -8,
      marginRight: -8,
      marginBottom: 20,
      borderRadius: '6px 6px 0 0',
      height: theme.spacing(6),
    },
    tabs: {
      paddingLeft: theme.spacing(2),
    },
    mainBox: {
      marginTop: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      height: theme.spacing(50),
      overflowY: 'auto',
    },
    filterInput: {
      minHeight: 23,
      border: `1px solid ${theme.palette.primary.grey13}`,
      minWidth: '100%',
    },
    tableContainer: {
      width: `${theme.layout.taskBarWidth + theme.layout.taskListWidth}px`,
      minWidth: '100%',
    },
    clinicalAssessmentTable: {
      marginBottom: '1rem',
      fontFamily: "'Gotham SSm A', 'Gotham SSm B', Verdana, Geneva, Tahoma, sans-serif",
      padding: theme.spacing(0),
    },
    clinicalAssessmentTableContainer: {
      'overflow': 'scroll',
      'maxHeight': '60vh',
      '& thead th': {
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 1,
      },
    },
    clinicalAssessmentTableMain: {
      'borderSpacing': 0,
      'border': `1px solid ${theme.palette.primary.grey13}`,
      '& tr': {
        'fontSize': '0.8125rem',
        '& td': {
          borderTop: `0.1px solid ${theme.palette.primary.grey13}`,
        },
        '& :last-child': {
          '& td': {
            maxWidth: '280px',
            borderBottom: 0,
            whiteSpace: 'nowrap',
          },
        },
      },
    },
    clinicalAssessmentHeaderTh: {
      'position': 'relative',
      'margin': 0,
      'padding': '0.4rem',
      'borderRight': `1px solid ${theme.palette.primary.grey13}`,
      '& :last-child': {
        '& th': {
          borderRight: 0,
        },
      },
      'width': '100%',
      'borderTop': `1px solid ${theme.palette.primary.grey13}`,
      'borderBottom': `2px solid ${theme.palette.primary.grey13}`,
      'fontSize': '0.8125rem',
      'textAlign': 'center',
      'color': theme.palette.primary.steelGrey,
      '& button': {
        padding: '0px',
        marginLeft: '10px',
      },
      'backgroundColor': theme.palette.primary.white,
    },
    headerTh: {
      fontWeight: 300,
      fontSize: theme.spacing(1.75),
      minWidth: '100%',
    },
    headerTr: {
      'minWidth': '100%',
      'display': 'table',
      '& th > input': {
        padding: theme.spacing(0),
        textIndent: theme.spacing(1),
      },
    },
    centerCell: {
      textAlign: 'center',
    },
    tbodyTr: {
      textIndent: theme.spacing(1),
    },
    paginationBtnsContainer: {
      backgroundColor: theme.palette.primary.white,
      border: `1px solid ${theme.palette.primary.grey13}`,
      maxHeight: theme.spacing(7),
    },
    paginationBtns: {
      'padding': theme.spacing(0.5),
      '& button': {
        width: '100%',
        height: theme.spacing(6),
        border: 0,
        borderRadius: theme.spacing(0.5),
        fontSize: 14,
        transition: 'all .1s ease',
        cursor: 'pointer',
        outlineWidth: 0,
      },
    },
    paginationControls: {
      'textAlign': 'center',
      '& span': {
        'height': theme.spacing(6),
        'lineHeight': theme.spacing(6),
        'display': 'inline-block',
        '& select': {
          height: theme.spacing(4.5),
          border: `1px solid ${theme.palette.primary.grey13}`,
        },
        '& input': {
          height: theme.spacing(4),
          minWidth: theme.spacing(2.5),
          border: `1px solid ${theme.palette.primary.grey13}`,
          textIndent: theme.spacing(1),
        },
      },
    },
  };
});

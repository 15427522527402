import React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import WidthController from './width-controller';
import colors from '../../../lib/themes/colors.module.scss';

const { deepBlue, white } = colors;

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    width: '100%',
    fontSize: 18,
  },
  subContainer: {
    padding: 30,
  },
  bluePage: {
    'backgroundColor': deepBlue,
    'justifyContent': 'center',
    '& h1': {
      margin: 0,
      fontSize: 26,
    },
    '& hr': {
      width: '85%',
    },
    'color': white,
    'textAlign': 'center',
  },
}));

export default ({ children }) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.container, classes.bluePage)}>
      <WidthController className={classes.subContainer}>{children}</WidthController>
    </div>
  );
};

import HTTP from '../services/http';
import {
  FETCH_MEDICAL_INSURANCES,
  FETCH_HAS_PRIMARY_MEDICAL_INSURANCE,
  FETCH_HAS_PRIMARY_PBM_INSURANCE,
  FETCH_PBM_INSURANCES,
  ADD_MEDICAL_INSURANCE,
  EDIT_MEDICAL_INSURANCE,
  ADD_PBM_INSURANCE,
  EDIT_PBM_INSURANCE,
  FETCH_INCOMES,
  ADD_INCOME,
  EDIT_INCOME,
  FETCH_EXISTING_INCOME_YEARS,
  ADD_FINANCIAL_ASSISTANCE,
  EDIT_FINANCIAL_ASSISTANCE,
  FETCH_FINANCIAL_ASSISTANCE,
  ASSOCIATE_FINANCIAL_ASSISTANCE,
  LINK_FINANCIAL_ASSISTANCE,
} from '../constants';

export function fetchMedicalInsurances(patientId) {
  const request = HTTP.get(`/patients/${patientId}/medical-insurances`, {});

  return {
    type: FETCH_MEDICAL_INSURANCES,
    initialValue: { data: { insurances: [] } },
    payload: request,
    meta: { patientId },
  };
}

export function fetchHasPrimaryMedicalInsurance(patientId) {
  const request = HTTP.get(`/patients/${patientId}/medical-insurances/has-primary`, {});

  return {
    type: FETCH_HAS_PRIMARY_MEDICAL_INSURANCE,
    payload: request,
    meta: { patientId },
  };
}

export function fetchPbmInsurances(patientId) {
  const request = HTTP.get(`/patients/${patientId}/pbm-insurances`, {});

  return {
    type: FETCH_PBM_INSURANCES,
    initialValue: { data: { insurances: [] } },
    payload: request,
    meta: { patientId },
  };
}
export function fetchHasPrimaryPbmInsurance(patientId) {
  const request = HTTP.get(`/patients/${patientId}/pbm-insurances/has-primary`, {});

  return {
    type: FETCH_HAS_PRIMARY_PBM_INSURANCE,
    payload: request,
    meta: { patientId },
  };
}

export function addPbmInsurance(insurance) {
  const postURL = `/patients/${insurance.patient_id}/pbm-insurances`;
  const request = HTTP.post(postURL, insurance, {});
  return {
    meta: {
      errorMessage: 'PBM Insurance Added Failed: ',
      successMessage: 'PBM Insurance Added Successfully',
    },
    type: ADD_PBM_INSURANCE,
    payload: request,
  };
}

export function editPbmInsurance(insurance) {
  const patchURL = `/patients/${insurance.patient_id}/pbm-insurances/${insurance.id}`;
  const request = HTTP.patch(patchURL, insurance, {});
  return {
    meta: {
      errorMessage: 'PBM Insurance Updated Failed: ',
      successMessage: 'PBM Insurance Updated Successfully',
    },
    type: EDIT_PBM_INSURANCE,
    payload: request,
  };
}
export function addMedicalInsurance(insurance) {
  const postURL = `/patients/${insurance.patient_id}/medical-insurances`;
  const request = HTTP.post(postURL, insurance, {});
  return {
    meta: {
      errorMessage: 'Medical Insurance Added Failed: ',
      successMessage: 'Medical Insurance Added Successfully',
    },
    type: ADD_MEDICAL_INSURANCE,
    payload: request,
  };
}

export function editMedicalInsurance(insurance) {
  const patchURL = `/patients/${insurance.patient_id}/medical-insurances/${insurance.id}`;
  const request = HTTP.patch(patchURL, insurance, {});
  return {
    meta: {
      errorMessage: 'Medical Insurance Updated Failed: ',
      successMessage: 'Medical Insurance Updated Successfully',
    },
    type: EDIT_MEDICAL_INSURANCE,
    payload: request,
  };
}
export function fetchIncomes(patientId) {
  const request = HTTP.get(`/patients/${patientId}/incomes`, {});

  return {
    type: FETCH_INCOMES,
    initialValue: { data: { incomes: [] } },
    payload: request,
    meta: { patientId },
  };
}
export function addIncome(income) {
  const postURL = `/patients/${income.patient_id}/incomes`;
  const request = HTTP.post(postURL, income, {});
  return {
    meta: {
      errorMessage: 'Income Added Failed: ',
      successMessage: 'Income Added Successfully',
    },
    type: ADD_INCOME,
    payload: request,
  };
}

export function editIncome(income) {
  const patchURL = `/patients/${income.patient_id}/incomes/${income.id}`;
  const request = HTTP.patch(patchURL, income, {});
  return {
    meta: {
      errorMessage: 'Income Updated Failed: ',
      successMessage: 'Income Updated Successfully',
    },
    type: EDIT_INCOME,
    payload: request,
  };
}

export function fetchExistingIncomeYears(patientId) {
  const request = HTTP.get(`/patients/${patientId}/income-years`, {});

  return {
    type: FETCH_EXISTING_INCOME_YEARS,
    payload: request,
    meta: { patientId },
  };
}

export function fetchFinancialAssistance(patientId) {
  const request = HTTP.get(`/patients/${patientId}/financial-assistance`, {});
  return {
    type: FETCH_FINANCIAL_ASSISTANCE,
    initialValue: { data: [] },
    payload: request,
    meta: { patientId },
  };
}
export function addFinancialAssistanceTask(financialAssistance) {
  const postURL = `/patients/${financialAssistance.patient_id}/financial-assistance`;
  const request = HTTP.post(postURL, financialAssistance, {});
  return {
    type: ADD_FINANCIAL_ASSISTANCE,
    payload: request,
    meta: {
      errorMessage: 'Financial Assistance added Failed',
      successMessage: 'Financial Assistance added Successfully',
    },
  };
}

export function linkFinancialAssistanceTask(taskId, existingTaskId) {
  const url = `/tasks/fa/${taskId}/link`;
  const request = HTTP.post(url, { existingTaskId });

  return {
    type: LINK_FINANCIAL_ASSISTANCE,
    payload: request,
  };
}
export function associateFinancialAssistanceWithTask(financialAssistance, taskId) {
  const postURL = `/patients/${financialAssistance.patient_id}/financial-assistance/${taskId}`;
  const request = HTTP.post(postURL, financialAssistance, {});
  return {
    type: ASSOCIATE_FINANCIAL_ASSISTANCE,
    payload: request,
  };
}

export function editFinancialAssistanceTask(financialAssistance) {
  // eslint-disable-next-line max-len
  const patchURL = `/patients/${financialAssistance.patient_id}/financial-assistance/${financialAssistance.id}`;
  const request = HTTP.patch(patchURL, financialAssistance, {});
  return {
    type: EDIT_FINANCIAL_ASSISTANCE,
    payload: request,
    meta: {
      errorMessage: 'Financial Assistance Edited Failed',
      successMessage: 'Financial Assistance Edited Successfully',
    },
  };
}

import { Reducer } from 'redux';
import { IUserCustomListsState } from 'interfaces/redux/IUserCustomList';
import { UserCustomListAction } from 'actions/action-user-custom-lists';
import { getWindowUser } from '../config/user';
import {
  FETCH_USER_CUSTOM_LISTS_SUCCESS,
  ADD_USER_CUSTOM_LIST,
  DELETE_USER_CUSTOM_LIST_ITEM,
  ADD_USER_CUSTOM_LIST_ITEM,
  UPDATE_ADDED_USER_CUSTOM_LIST_ITEM,
} from '../constants/index';

const initialState: IUserCustomListsState = {
  loaded: false,
  users: [],
};

const userCustomListsReducer: Reducer<IUserCustomListsState, UserCustomListAction> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action;
  const windowUser = getWindowUser();
  const userId = windowUser?.id;
  switch (type) {
    case FETCH_USER_CUSTOM_LISTS_SUCCESS: {
      if (!payload) {
        return state;
      }
      if (!payload.data.userCustomLists || payload.data.userCustomLists.length === 0) {
        return {
          loaded: true,
          users: [],
        };
      }
      return {
        loaded: true,
        users: [
          ...state.users,
          {
            user_id: payload?.data?.userCustomLists[0]?.user_id,
            lists: payload?.data?.userCustomLists,
          },
        ],
      } as IUserCustomListsState;
    }
    case ADD_USER_CUSTOM_LIST: {
      if (!payload || !userId) {
        return state;
      }
      const userToUpdate =
        state.users && state.users.length > 0
          ? state.users.find(user => user?.user_id === userId)
          : null;
      const listToAdd = payload?.data?.addedList;
      if (!listToAdd.list_name) {
        listToAdd.list_name = 'My Highlighted Patients';
      }

      if (!userToUpdate) {
        return {
          ...state,
          users: [
            ...state.users,
            {
              user_id: listToAdd.user_id,
              lists: [listToAdd],
            },
          ],
        };
      }
      userToUpdate.lists = [...userToUpdate?.lists, payload.data.addedList];
      return {
        loaded: true,
        users: [...state.users.filter(u => u.user_id !== userId), userToUpdate],
      } as IUserCustomListsState;
    }
    case ADD_USER_CUSTOM_LIST_ITEM: {
      if (payload && payload.data && payload.data.addedItem && userId) {
        const userToUpdate = state.users.find(user => user.user_id === userId);
        if (!userToUpdate) {
          return state;
        }
        const listToAddItemToIndex =
          userToUpdate &&
          userToUpdate.lists &&
          userToUpdate.lists.length > 0 &&
          userToUpdate.lists[0].list &&
          userToUpdate.lists[0].list.length > 0
            ? userToUpdate.lists[0].list.findIndex(
                list => list.list_id === payload?.data?.addedItem.list_id,
              )
            : 0;
        const retStateLists = userToUpdate.lists;
        retStateLists[listToAddItemToIndex].list = [
          ...retStateLists[listToAddItemToIndex].list,
          payload?.data?.addedItem,
        ];
        userToUpdate.lists = retStateLists;
        return {
          loaded: true,
          users: [...state.users.filter(user => user.user_id !== userId), userToUpdate],
        } as any;
      }
      return state;
    }
    case UPDATE_ADDED_USER_CUSTOM_LIST_ITEM: {
      if (payload && payload.patient && userId) {
        const userToUpdate = state.users.find(user => user.user_id === userId);
        if (!userToUpdate) {
          return state;
        }
        const retStateLists = userToUpdate.lists;
        const patientInListToUpdateIndex = retStateLists[0].list.findIndex(
          p => p.patient_id === payload.patient.id,
        );
        retStateLists[0].list[patientInListToUpdateIndex] = {
          ...retStateLists[0].list[patientInListToUpdateIndex],
          first_name: payload.patient.first_name,
          last_name: payload.patient.last_name,
          source_patient_id: payload.patient.source_patient_id,
          dob: payload.patient.dob,
        };

        userToUpdate.lists = retStateLists;
        return {
          loaded: true,
          users: [...state.users.filter(user => user.user_id !== userId), userToUpdate],
        } as any;
      }
      return state;
    }
    case DELETE_USER_CUSTOM_LIST_ITEM: {
      if (payload && payload.data && payload.data.deletedItem && userId) {
        const userToUpdate = state.users.find(user => user.user_id === userId);
        if (!userToUpdate) {
          return state;
        }
        const itemToDeleteIndex = userToUpdate.lists[0].list.findIndex(
          (listValue: any) => listValue.id === payload?.data?.deletedItem,
        );
        const retList = userToUpdate.lists[0].list.filter(i => i.id !== payload?.data?.deletedItem);
        const retLists = { ...userToUpdate.lists[0], list: retList };
        userToUpdate.lists = [retLists];
        return {
          loaded: true,
          users: [...state.users.filter(user => user.user_id !== userId), userToUpdate],
        } as any;
      }
      return state;
    }

    default:
      return state;
  }
};

export default userCustomListsReducer;

import React, { useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import { TableCell, TableRow, Button, Modal, Grid, Typography, Divider } from '@mui/material';
import { EditHistory, EditPencil, MoreNotesIcon, MoreRightIcon } from 'components/icons/icons';
import History from 'containers/common/history';
import { getAllergenCategoriesDisplay } from 'services/utils/allergy-service';
import compose from 'recompose/compose';
import Label from 'components/display/label';
import { useSelector } from 'react-redux';
import { PATIENT_ALLERGY_SEVERITY_SEVERE_ID } from 'constants/index';
import { getModalStyle } from 'services/utils/styles-service';
import { buildQaId } from 'utils/build-qa-id';
import PatientAllergiesForm from './patient-allergies-form';
import { styles } from '../patient-clinical-styles';

const qaIdBuilder = buildQaId('patient-allergies-row');

export const AllergiesRow = props => {
  const { classes, editHandler, allergy, patient, tableColWidths, readOnly, index } = props;
  const [allergyHistory, setAllergyHistory] = useState(false);
  const [hover, setHover] = useState(true);
  const [displayChildren, setDisplayChildren] = useState(false);

  const patientHasKnownAllergies = useSelector(state => state.allergies.patientHasKnownAllergies);

  let allergiesSources = useSelector(state => state.lookups.allergyLookups.sources);
  allergiesSources = allergiesSources
    ? allergiesSources.map(s => ({ value: s.id, label: s.value }))
    : [];
  const toggleHistory = () => {
    setAllergyHistory(!allergyHistory);
  };
  const onHover = val => {
    setHover(val);
  };
  const onEditClick = e => {
    e.preventDefault();
    editHandler(true, allergy);
  };

  return (
    <>
      <Modal open={allergyHistory} onClose={toggleHistory}>
        <Grid item xs={12} style={getModalStyle()} className={classes.allergiesHistoryModal}>
          {allergy && patient && (
            <History url={`/patients/${patient.id}/allergies/${allergy.id}`} />
          )}
        </Grid>
      </Modal>
      {patientHasKnownAllergies ? (
        <TableRow
          key={`tablerow_allergy_${allergy && allergy.id ? allergy.id : 'allergy_id_not_found'}`}
          className={allergy?.new ? classes.newAllergyRow : classes.allergyRow}
          onMouseEnter={() => onHover(true)}
          onMouseLeave={() => onHover(false)}
          data-qa-id={qaIdBuilder(index)}
        >
          <TableCell
            colSpan={tableColWidths ? tableColWidths.allergy : null}
            className={classes.allergyCell}
          >
            <Button
              className={classes.moreIcon}
              onClick={() => setDisplayChildren(!displayChildren)}
            >
              {displayChildren ? <MoreNotesIcon /> : <MoreRightIcon />}
            </Button>
            {allergy.allergen.definition || '-'}
          </TableCell>
          <TableCell
            colSpan={tableColWidths ? tableColWidths.severity : null}
            className={classes.allergyCell}
          >
            {allergy.severity_id === PATIENT_ALLERGY_SEVERITY_SEVERE_ID ? (
              <Label labelName={allergy.severity || '-'} labelType="warning" key="key" />
            ) : (
              allergy.severity || '-'
            )}
          </TableCell>
          <TableCell
            colSpan={tableColWidths ? tableColWidths.reactions : null}
            className={classes.allergyCell}
          >
            {allergy && allergy.reactions && Array.isArray(allergy.reactions)
              ? allergy.reactions.map(reaction => reaction.name).join(', ')
              : '-'}
          </TableCell>
          <TableCell
            colSpan={tableColWidths ? tableColWidths.status : null}
            className={classes.allergyCell}
          >
            {allergy.status || '-'}
          </TableCell>
          <TableCell
            colSpan={tableColWidths ? tableColWidths.source : null}
            className={classes.allergyCell}
          >
            {allergy && allergiesSources.find(source => source.value === allergy.allergy_source_id)
              ? allergiesSources.find(source => source.value === allergy.allergy_source_id).label
              : '-'}
          </TableCell>
          <TableCell
            colSpan={tableColWidths ? tableColWidths.edit : null}
            align="right"
            className={classes.editHistoryCell}
          >
            {hover && !readOnly && (
              <>
                <Button onClick={toggleHistory} data-qa-id="allergy_history_button">
                  <EditHistory />
                </Button>
                <Button onClick={e => onEditClick(e)} data-qa-id="allergy_edit_button">
                  <EditPencil />
                </Button>
              </>
            )}
          </TableCell>
        </TableRow>
      ) : (
        <TableRow
          key={`tablerow_allergy_${allergy && allergy.id ? allergy.id : 'allergy_id_not_found'}`}
          className={classes.allergyRow}
          onMouseEnter={() => onHover(true)}
          onMouseLeave={() => onHover(false)}
        >
          <TableCell colSpan={2} className={classes.allergyCell}>
            <Typography className={classes.typographyHeader}>Source</Typography>

            <Typography>
              {allergy &&
              allergiesSources.find(source => source.value === allergy.allergy_source_id)
                ? allergiesSources.find(source => source.value === allergy.allergy_source_id).label
                : '-'}
            </Typography>
          </TableCell>
          <TableCell colSpan={9} className={classes.notesCellBorder}>
            <Typography className={classes.typographyHeader}>Notes</Typography>
            <Typography>{allergy ? allergy.allergen_notes : ''}</Typography>
          </TableCell>
          <TableCell
            colSpan={tableColWidths ? tableColWidths.edit : null}
            align="right"
            className={classes.editHistoryCell}
          >
            {hover && !readOnly && (
              <>
                <Button onClick={toggleHistory} data-qa-id="allergy_history_button">
                  <EditHistory />
                </Button>
                <Button onClick={e => onEditClick(e)} data-qa-id="allergy_edit_button">
                  <EditPencil />
                </Button>
              </>
            )}
          </TableCell>
        </TableRow>
      )}
      {displayChildren &&
        patientHasKnownAllergies &&
        [allergy].map(() => (
          <>
            <TableRow className={classes.noBorder} key={`allergy_${allergy.id}`}>
              <TableCell colSpan={5} className={classes.noBorder}>
                <Typography className={classes.typographyHeader}>Category</Typography>

                <Typography>{getAllergenCategoriesDisplay(allergy.allergen)}</Typography>
              </TableCell>

              <TableCell colSpan={3} className={classes.noBorder}>
                <Typography className={classes.typographyHeader}>Onset Date</Typography>
                <Typography>{allergy.onset_dt}</Typography>
              </TableCell>
              <TableCell colSpan={4} className={classes.noBorder} />
            </TableRow>
            <TableRow classNames={classes.dividerRow}>
              <TableCell colSpan={12} className={classes.noBorder}>
                <Divider />
              </TableCell>
            </TableRow>
            <TableRow classNames={classes.tableRow}>
              <TableCell colSpan={12} className={classes.notesCellBorder}>
                <Typography className={classes.typographyHeader}>Notes</Typography>
                <Typography>{allergy.allergen_notes}</Typography>
              </TableCell>
            </TableRow>
          </>
        ))}
      <PatientAllergiesForm />
    </>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(AllergiesRow);

import { AxiosResponse } from 'axios';
import { ICounselingAddendum } from 'containers/tasks/counseling/counseling-transition/forms/interfaces/ICounselingAddendum';
import { ICounselingDraft } from 'containers/tasks/counseling/counseling-transition/forms/interfaces/ICounselingDraft';
import { ICounselingSnapshot } from 'containers/tasks/counseling/counseling-transition/forms/interfaces/ICounselingSnapshot';
import HTTP from '../http';

type SnapshotResponse = {
  counseling_snapshot: ICounselingSnapshot;
  addendums: ICounselingAddendum[];
};

class CounselingTaskService {
  async getSnapshot(taskId: number): Promise<SnapshotResponse> {
    const response = (await HTTP.get(
      `/tasks/counseling/${taskId}/snapshot`,
      {},
    )) as AxiosResponse<SnapshotResponse>;
    return response.data;
  }

  async getDraft(taskId: number): Promise<Partial<ICounselingDraft>> {
    const response = (await HTTP.get(`/tasks/csl/draft/${taskId}`, {})) as AxiosResponse<{
      data: ICounselingDraft;
    }>;
    return response.data.data;
  }
}

export { CounselingTaskService };

import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ActionRow from 'components/note/action-row';
import NoteDisplay from 'components/note/note-display';
import DocumentDisplay from 'components/document/document-display';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import compose from 'recompose/compose';
import { useFillCycleNumber } from 'hooks/useFillCycleNumber';
import {
  getStatusByStatusId,
  getStatusReasonByStatusId,
  getDaysSupplyForTask,
} from 'services/utils/task-service';
import TaskDetailForm from 'components/dynamic-form/task-forms/task-detail-form';
import { fetchPaymentMethodsState } from 'slices/patient-payment-methods-slice';
import { TASK_FILL_COORDINATION, FC } from '../../../constants/index';
import { styles } from '../task-detail-styles';
import TaskLinks from '../task-links';
import EditFillCoordination from './edit-fill-coordination';
import { FillCoordinationDescription } from '../../../models/tasks/FillCoordinationDescription';
import StatusFillCoordination from './status-fill-coordination';

const FillCoordinationDetail = props => {
  const {
    statuses,
    task,
    classes,
    update,
    notes,
    documents,
    tagTypeId,
    tagTypeLabel,
    serviceGroups,
    therapy,
    displayEdit,
    handleCancel,
    taskIDPrefix,
    drugName,
    fillCycles,
    currentUser,
    resourceStates,
    patient,
    therapies,
  } = props;
  const {
    service_group_id: serviceGroupId,
    status_id: statusId,
    fill_cycle_number: fillCycleNumber,
    therapy_id: therapyId,
  } = task;

  const [selectedStatus, setSelectedStatus] = useState(
    task.status ? task.status : getStatusByStatusId(task.status_id, statuses),
  );

  const [needsByDate] = useFillCycleNumber(therapyId, fillCycleNumber);

  const isCompletedStatus = task.status_id === FillCoordinationDescription.statusMap.Completed;

  const fcResourceStates = (resourceStates || []).filter(
    resourceState => resourceState.resource_name === FC,
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (task.taskType === 'FC' && task.payment_method_ids?.length && patient.id) {
      dispatch(
        fetchPaymentMethodsState({
          patientId: patient.id,
          paymentMethodIds: task.payment_method_ids,
        }),
      );
    }
  }, []);

  const therapiesById = {};
  Object.values(therapies).map(therapy => (therapiesById[therapy.id] = therapy));
  const therapiesToRemove = (task.therapy_ids_to_remove || []).map(
    therapyId => therapiesById[therapyId],
  );
  const therapiestoRemoveAsString = therapiesToRemove
    .map(
      therapy =>
        `  ${therapy.drug_name} ${therapy.dosage_form} ${therapy.strength} ${therapy.strength_unit_of_measure}`,
    )
    .join(',');

  return (
    <div>
      <Grid
        container
        alignItems={isCompletedStatus ? 'flex-start' : 'center'}
        className={displayEdit ? classes.taskDetailContainerEdit : classes.taskDetailContainer}
      >
        <Grid item lg={3}>
          <Grid className={classes.statusLabelContainer}>
            <Grid item className={classes.statusLabel}>
              Status
            </Grid>
            <Grid item className={classes.statusValue}>
              {selectedStatus}
            </Grid>
          </Grid>
        </Grid>
        {!displayEdit && (
          <Grid item lg={isCompletedStatus ? 8 : 5} xs={6}>
            <TaskDetailForm
              task={{
                ...task,
                needsby_date: needsByDate,
                status_reason: getStatusReasonByStatusId(statusId, statuses),
                days_supply: getDaysSupplyForTask(task, fillCycles),
              }}
              taskType={FC}
              taskStatusId={task.status_id}
            />
          </Grid>
        )}
        {displayEdit && <Grid item xs={5} />}
        <Grid item xs={4}>
          {!displayEdit && (
            <Grid item xs={12}>
              <Grid container className={classes.note}>
                <NoteDisplay {...notes} tagResourceId={task.id} />
              </Grid>
              <DocumentDisplay
                taskIdResourceIds={[{ tagTypeId, resourceId: task.id }]}
                tagTypeId={tagTypeId}
                resourceId={task.id}
                drugName={drugName}
                tagTypeLabel={tagTypeLabel}
                documents={documents}
              />
              <ActionRow
                therapy={therapy}
                task={task}
                tagTypeId={tagTypeId}
                tagResourceId={task.id}
                cancel={handleCancel}
                defaultServiceGroup={serviceGroupId}
                serviceGroups={serviceGroups}
                taskType={TASK_FILL_COORDINATION}
                idPrefix={taskIDPrefix}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <TaskLinks links={task.links} />
      {/* When editing an FC */}
      <Grid container>
        {displayEdit && (
          <Grid item xs={12}>
            <EditFillCoordination
              task={task}
              statuses={statuses}
              cancel={handleCancel}
              serviceGroups={serviceGroups}
              selectedStatus={selectedStatus}
            />
          </Grid>
        )}
        {/* When the status is changing */}
        {!displayEdit &&
          selectedStatus !== getStatusByStatusId(statusId, statuses) &&
          statusId &&
          statuses && (
            <Grid
              item
              xs={12}
              className={selectedStatus !== task.status ? classes.statusContainer : null}
            >
              <StatusFillCoordination
                selectedStatus={selectedStatus}
                therapyStatusName={selectedStatus}
                task={task}
                statuses={statuses}
                cancel={() => setSelectedStatus(getStatusByStatusId(task.status_id, statuses))}
                update={update}
                serviceGroups={serviceGroups}
                tagTypeId={tagTypeId}
                tagResourceId={task.id}
              />
            </Grid>
          )}
      </Grid>
    </div>
  );
};

function mapStateToProps(state) {
  const { lookups, auth } = state;
  return {
    users: state.lookups.users,
    patient: state.patient,
    fillCycles: state.fillCycles,
    currentUser: auth?.currentUser,
    resourceStates: lookups?.resourceStates,
    therapies: state.therapies.data,
  };
}

export default compose(withStyles(styles), connect(mapStateToProps))(FillCoordinationDetail);

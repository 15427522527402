import { useTypedSelector } from 'hooks/use-typed-selector';
import moment from 'moment';
import { getPaymentTypeLabel } from '../fc-util';
import { isVirtualCard, selectPaymentMethodOptions, sortPaymentMethods, isExpired } from './payment-method-utils';
import { IFillCoordinationTask } from '../../../../interfaces/redux/task-types/IFillCoordinationTask';

export enum PaymentMethodBadge {
  Virtual = 0,
  Primary = 1,
  Secondary = 2,
  Tertiary = 3,
}

export type PaymentMethodDropdownSelectOption = {
  value: number;
  label: string;
  badge: PaymentMethodBadge | null;
  expired: boolean
};

/**
 * This hook returns a list of options for the payment method select dropdowns.
 * It combines draft payment methods that were added using <AddPaymentMethodModal>,
 * and existing patient preference payment methods. The drafts have a uuid for
 * temporarily linking them to the form until they are submitted.
 */
export const usePaymentMethodSelectOptions = (tasks: IFillCoordinationTask[] = []) => {
  return useTypedSelector(state => {
    const allPaymentMethods = selectPaymentMethodOptions(state, tasks);
    const therapies = state.therapies.data;
    const { paymentMethodTypes } = state.lookups;
    return allPaymentMethods.map(paymentMethod => {
      const expired = isExpired(paymentMethod);
      const cardNumLabel = `****-${paymentMethod.card_num}`;
      const label = isVirtualCard(paymentMethod)
        ? `${cardNumLabel} (${therapies[paymentMethod.therapy_id]?.drug_name ?? 'Virtual Card'}) (exp. ${paymentMethod.expiration_date}) `
        : `${cardNumLabel} (${getPaymentTypeLabel(paymentMethod, paymentMethodTypes)}) (exp. ${paymentMethod.expiration_date})`;
      const value = paymentMethod.id;
      const { rank } = paymentMethod;
      const badge =
        (rank && PaymentMethodBadge[rank]) ??
        (isVirtualCard(paymentMethod) ? PaymentMethodBadge.Virtual : null);
      return { label, value, badge, expired };
    }) as PaymentMethodDropdownSelectOption[];
  });
};

import { WithStyles } from '@mui/styles';
import { styles } from './styles';

export type IndexedDrugSections<T> = Record<number, Record<number, T>>;
export type DrugSubSection = Record<string, Record<string, string>>;

export enum EditableSection {
  Primary,
  Secondary,
  Both,
  None,
}

export interface IProps extends WithStyles<typeof styles> {
  title: string;
  initialContent?: string;
  initialSecondaryContent?: string;
  editSection?: EditableSection;
  onChange(
    section: string,
    content?: string,
    secondaryContent?: string,
    drugSubSection?: { drugIndex: number; subSectionIndex: number },
  ): void;
  drugSubSections?: DrugSubSection;
  readOnly?: boolean;
  viewOnly: boolean;
  qaId?: string;
  showEditButton?: boolean;
  editing?: boolean;
  saveContentUpdatesTrigger?: boolean;
  cancelContentUpdatesTrigger?: boolean;
  onFinishedSaving: () => void;
  rightSideComponent?: JSX.Element;
}

export const YES_VALUE = 1;
export const NO_VALUE = 0;

export const YES_LABEL = 'Yes';
export const NO_LABEL = 'No';

export const ER_ONLY_VALUE = 'er_only';
export const ER_HOSPITAL_INPATIENT_ADMISSION_VALUE = 'er_hospital_inpatient_admission';
export const DIRECT_HOSPITAL_INPATIENT_ADMISSION_VALUE = 'direct_hospital_inpatient_admission';

export const ER_ONLY_LABEL = 'ER only';
export const ER_HOSPITAL_INPATIENT_ADMISSION_LABEL = 'ER + Hospital Inpatient Admission';
export const DIRECT_HOSPITAL_INPATIENT_ADMISSION_LABEL = 'Direct Hospital Inpatient Admission';

export const DISEASE_PROGRESSION_OPTIONS = [
  {
    label: YES_LABEL,
    value: YES_VALUE,
  },
  {
    label: NO_LABEL,
    value: NO_VALUE,
  },
];

export const VISIT_TYPE_OPTIONS = [
  {
    label: ER_ONLY_LABEL,
    value: ER_ONLY_VALUE,
  },
  {
    label: ER_HOSPITAL_INPATIENT_ADMISSION_LABEL,
    value: ER_HOSPITAL_INPATIENT_ADMISSION_VALUE,
  },
  {
    label: DIRECT_HOSPITAL_INPATIENT_ADMISSION_LABEL,
    value: DIRECT_HOSPITAL_INPATIENT_ADMISSION_VALUE,
  },
];

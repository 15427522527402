import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector, connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { formValueSelector, change, reset } from 'redux-form';
import { getFileExtension, acceptedFileExtensions } from '../../services/utils/document-service';
import {
  UPLOAD_DOC_FILENAME_FIELD,
  UPLOAD_DOC_FILETYPE_FIELD,
  UPLOAD_DOC_FILENOTE_FIELD,
  UPLOAD_DOCUMENT_FORM,
} from '../../constants';
import { uploadFile, addDocument } from '../../services/utils/upload-document-service';
import { addNewDocument } from '../../actions/action-uploaded-documents';
import { styles } from './document-bar-styles';
import DocumentForm from './document-form';

const UploadNewDocument = props => {
  const { classes, fileName, fileTypes, fileNote } = props;
  const dispatch = useDispatch();

  const [documentLabels, setDocumentLabels] = useState([]);
  // This is used to change the display if a file is selected. Should be bool
  const [fileSelected, setFileSelected] = useState();
  // Contains the actual file that the user selected
  const [selectedFile, setSelectedFile] = useState();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [uploading, setUploading] = useState();
  const [fileExtension, setFileExtension] = useState();

  const customerId = useSelector(state => state.filters.selectedCustomerId);
  const patientId = useSelector(state => state.selectedPatientId);
  const { resourceId, tagTypeId, drugName, tagTypeLabel } = useSelector(state => state.documentBar);
  const defaultDocumentTypes = useSelector(state => state.lookups.defaultDocumentLabels);

  useEffect(() => {
    if (defaultDocumentTypes) {
      const types = defaultDocumentTypes.map(dt => ({
        label: dt.label,
        value: dt.label,
      }));
      setDocumentLabels(types);
    }
  }, [defaultDocumentTypes]);

  const resetForm = () => {
    setFileSelected(false);
    setSelectedFile(undefined);
    setUploading(false);
    setErrorMessage();
    dispatch(reset());
  };

  const handleFileRejected = () => {
    setSuccessMessage();
    setErrorMessage('You selected an invalid file, please try again.');
  };

  const handleFileAccepted = event => {
    if (event && event.length > 0) {
      // eslint-disable-next-line no-shadow
      const { filePrefix, fileExtension } = getFileExtension(event[0].name);
      if (!acceptedFileExtensions.includes(fileExtension.toLowerCase())) {
        handleFileRejected();
      } else {
        setErrorMessage();
        setSelectedFile(event[0]);
        setFileSelected(true);
        setDisableSubmit(false);

        dispatch(change(UPLOAD_DOCUMENT_FORM, UPLOAD_DOC_FILENAME_FIELD, filePrefix));
        setFileExtension(fileExtension);
      }
    } else {
      handleFileRejected();
    }
  };

  const handleFilePaste = event => {
    const { files } = event.clipboardData;
    handleFileAccepted(files);
  };

  const deselectFile = () => {
    resetForm();
  };

  const handleSubmit = async () => {
    setDisableSubmit(true);
    setUploading(true);

    const fullFileName = `${fileName}.${fileExtension}`;

    try {
      const uploadResult = await uploadFile(selectedFile, fullFileName, customerId, patientId);
      const labels = fileTypes ? fileTypes.split(',') : [];
      const tags = [
        {
          tag_type_id: tagTypeId,
          resource_id: resourceId,
        },
      ];
      const response = await addDocument(
        customerId,
        patientId,
        uploadResult.uuid,
        fullFileName,
        labels,
        tags,
        fileNote,
      );
      dispatch(addNewDocument(response.data.result));
      resetForm();
      setSuccessMessage('File uploaded');
    } catch (error) {
      setUploading(false);
      setDisableSubmit(false);
      setSuccessMessage();
      setErrorMessage('Uploading your file failed. Please try again.');
    }
  };

  return (
    <DocumentForm
      showClose
      handleClose={deselectFile}
      fileSelected={fileSelected}
      formTitle="Attachment"
      drugName={drugName}
      documentLabels={documentLabels}
      submitDisabled={disableSubmit}
      cancelDisabled={disableSubmit}
      tags={[tagTypeLabel, drugName]}
      submitButtonText="Upload file"
      formSubmit={handleSubmit}
      onCancel={deselectFile}
      errorMessage={errorMessage}
      successMessage={successMessage}
      fileName={fileName}
      fileExtension={fileExtension}
      classes={classes}
      acceptedFileExtensions={acceptedFileExtensions}
      errorCaption={`Accepted file types: ${acceptedFileExtensions}`}
      handleFilePaste={handleFilePaste}
      handleAcceptedDrop={handleFileAccepted}
      handleRejectedDrop={handleFileRejected}
      isProcessing={uploading}
    />
  );
};

const mapStateToProps = state => {
  const selector = formValueSelector(UPLOAD_DOCUMENT_FORM);
  const fileName = selector(state, UPLOAD_DOC_FILENAME_FIELD);
  const fileTypes = selector(state, UPLOAD_DOC_FILETYPE_FIELD);
  const fileNote = selector(state, UPLOAD_DOC_FILENOTE_FIELD);

  return { fileName, fileTypes, fileNote };
};

export default compose(withStyles(styles), connect(mapStateToProps))(UploadNewDocument);

import Countdown from 'react-countdown-now';
import { set as lsSet, get as lsGet } from 'local-storage';
import React from 'react';
import { refreshToken, sessionLogout } from 'services/utils/users-service';
import { useInterval } from 'hooks/useInterval';
import { globalZIndex } from 'constants/z-index';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
} from '@mui/material';

// Extend the timeout by 15 minutes after each user action(mouse move, keypress, click)
// The logout warning dialog modal will start in TIMEOUT_IN_MINUTES - COUNTDOWN_TIME
const TIMEOUT_IN_MINUTES = 15;
const TIMEOUT_IN_MILLISECONDS: number = 1000 * 60 * TIMEOUT_IN_MINUTES;
const CYCLE_TIME_IN_SECONDS = 1;
const COUNTDOWN_TIME_IN_SECONDS = 60;
const TOKEN_REFRESH_TIME_IN_MINUTES = 10;
const LOGOUT_DIALOG_START_TIME = 'logout_dialog_start_time';
const LOGOUT_SIGNAL = 'logout_signal';
const SESSION_EXPIRATION_TIMESTAMP = 'session_expiration_timestamp';
const LAST_TOKEN_REFRESH_TIME = 'last_token_refresh_time';

interface Props {
  children?: React.ReactNode;
}

export const ArborSessionTimer: React.FC<Props> = (props): JSX.Element => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [delay] = React.useState<number>(CYCLE_TIME_IN_SECONDS * 1000);
  const timeoutDialogStarted = React.useRef<boolean>(false);
  const setLogoutSignal = (val: boolean) => {
    lsSet(LOGOUT_SIGNAL, val);
  };
  const getLogoutSignal = (): boolean => {
    return lsGet(LOGOUT_SIGNAL) || false;
  };
  const setTimestamp = (val: number) => {
    lsSet(SESSION_EXPIRATION_TIMESTAMP, val);
  };
  const getTimestamp = (): number => {
    return lsGet(SESSION_EXPIRATION_TIMESTAMP);
  };
  const getLastTokenRefreshTime = (): number => {
    return lsGet(LAST_TOKEN_REFRESH_TIME) || TOKEN_REFRESH_TIME_IN_MINUTES + 1;
  };
  const setLastTokenRefreshTime = () => {
    lsSet(LAST_TOKEN_REFRESH_TIME, Date.now());
  };
  const updateTimestamps = () => {
    const currentTime = Date.now();
    const newTimeout = currentTime + TIMEOUT_IN_MILLISECONDS;
    setTimestamp(newTimeout);
    lsSet(
      LOGOUT_DIALOG_START_TIME,
      new Date(newTimeout - COUNTDOWN_TIME_IN_SECONDS * 1000).toLocaleString(),
    );
  };

  React.useEffect(() => {
    if (!open) {
      timeoutDialogStarted.current = false;
      setLogoutSignal(false);
      listener();
    } else {
      timeoutDialogStarted.current = true;
    }
  }, [open]);

  const listener = () => {
    if (!timeoutDialogStarted.current) {
      updateTimestamps();
    }
  };

  React.useEffect(() => {
    const b = document.getElementById('root');
    b?.addEventListener('click', listener);
    b?.addEventListener('keydown', listener);
    b?.addEventListener('mouseover', listener);
    return () => {
      b?.removeEventListener('click', listener);
      b?.removeEventListener('keydown', listener);
      b?.removeEventListener('mouseover', listener);
    };
  }, []);

  const handleStayLoggedIn = (): void => {
    setOpen(false);
    setLogoutSignal(false);
    updateTimestamps();
    refreshToken()
      .then(() => {
        setLastTokenRefreshTime();
      })
      .catch(() => sessionLogout());
  };

  const handleLogout = (): void => {
    setLogoutSignal(true);
  };

  const getTimeoutInSeconds = (): number => {
    const timeoutTimestamp = getTimestamp();
    const timeoutInSeconds = (Number(new Date(timeoutTimestamp)) - Date.now()) / 1000;
    return timeoutInSeconds >= 0 ? timeoutInSeconds : 0;
  };

  const renderer = (): JSX.Element => {
    const timeoutInSeconds = getTimeoutInSeconds();
    return <span>{` ${Math.floor(timeoutInSeconds)} seconds`}</span>;
  };

  useInterval(
    () => {
      const timeoutInSeconds = getTimeoutInSeconds();
      const logoutSignal = getLogoutSignal();
      if (logoutSignal || timeoutInSeconds <= 0) {
        sessionLogout();
      }

      if (timeoutInSeconds <= COUNTDOWN_TIME_IN_SECONDS && !open) {
        setOpen(true);
      }
      if (timeoutInSeconds > COUNTDOWN_TIME_IN_SECONDS) {
        setOpen(false);
      }
    },
    timeoutDialogStarted.current ? delay * 0.3 : delay,
  );

  useInterval(() => {
    const timeSinceLastTokenRefresh = Date.now() - getLastTokenRefreshTime();
    if (timeSinceLastTokenRefresh >= TOKEN_REFRESH_TIME_IN_MINUTES * 60 * 1000) {
      refreshToken()
        .then(() => {
          setLastTokenRefreshTime();
        })
        .catch(() => sessionLogout());
    }
  }, TOKEN_REFRESH_TIME_IN_MINUTES * 60 * 1000 * 0.55);

  return (
    <div>
      {open && (
        <Dialog
          disableEscapeKeyDown
          aria-labelledby="customized-dialog-title"
          open={open}
          sx={{ zIndex: globalZIndex.logoutModal }}
        >
          <DialogTitle id="customized-dialog-title">Warning</DialogTitle>
          <DialogContent>
            <Typography gutterBottom>
              Your session is about to expire! Would you like to stay logged in?
            </Typography>
            <Typography gutterBottom>
              You will be logged out automatically in:
              <Countdown renderer={renderer} date={Date.now()} />
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleStayLoggedIn} color="primary">
              Yes, keep me signed in
            </Button>
            <Button onClick={handleLogout} color="primary">
              No, sign me out
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {props.children}
    </div>
  );
};

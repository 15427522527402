import HTTP from 'services/http';
import { SEND_SMS, VALIDATE_PHI, INVALIDATE_QUESTIONNAIRE } from 'constants/index';

const MESSAGE_FAILED = 'Message Failed: ';

export function sendSMS(formData) {
  const { patientId, message, phone } = formData;
  const postURL = `/patients/${patientId}/sms`;
  const request = HTTP.post(
    postURL,
    {
      content: message,
      number: phone,
      queue: null,
    },
    {},
  );

  return {
    meta: {
      errorMessage: MESSAGE_FAILED,
      successMessage: 'Message sent Successfully',
    },
    type: SEND_SMS,
    payload: request,
  };
}

export function validatePHI(msg) {
  const postURL = '/pmp/sms/_detectPHI';
  const request = HTTP.post(postURL, { msg }, {});

  return {
    meta: {
      errorMessage: MESSAGE_FAILED,
    },
    type: VALIDATE_PHI,
    payload: request,
  };
}

export function invalidateExternalUrl(externalUrlId) {
  const patchURL = '/questionnaires/url';
  const request = HTTP.patch(patchURL, { externalUrlId }, {});

  return {
    meta: {
      errorMessage: MESSAGE_FAILED,
      successMessage: 'Questionnaire URL Delete Successfully',
    },
    type: INVALIDATE_QUESTIONNAIRE,
    payload: request,
  };
}

import { LookupResourceTypes } from 'constants/enums';

export enum PatientMergeActionsTypes {
  SetInputs = 'SET_PATIENT_MERGE_INPUTS',
  Reset = 'RESET_PATIENT_MERGE',
  SetPrimaryProfile = 'SET_PATIENT_MERGE_PRIMARY_PROFILE',
  SetDuplicateProfile = 'SET_PATIENT_MERGE_DUPLICATE_PROFILE',
  AddInput = 'ADD_PATIENT_MERGE_INPUT',
  RemoveInput = 'REMOVE_PATIENT_MERGE_INPUT',
  ClearDuplicateProfileInformation = 'CLEAR_DUPLICATE_PROFILE_INFORMATION',
  AddMultipleInputs = 'ADD_PATIENT_MERGE_MULTI_INPUT',
  RemoveMultiInputs = 'REMOVE_PATIENT_MERGE_MULTI_INPUT',
}

export interface IPatientMergeInput {
  type: LookupResourceTypes;
  selectedId: number;
  extraInfo?: string;
}

export interface IPatientMergeInputState {
  primary?: number;
  duplicate?: number;
  inputs: IPatientMergeInput[];
}

export type SetInputsPayload = IPatientMergeInput[];
export type AddInputPayload = IPatientMergeInput;
export type RemoveInputPayload = IPatientMergeInput;
export type SetDuplicatePayload = number;
export type SetPrimaryPayload = number;

export type IPatientMergeActionPayload =
  | SetInputsPayload
  | SetDuplicatePayload
  | SetPrimaryPayload
  | RemoveInputPayload
  | AddInputPayload;

export interface IPatientMergeAction {
  type: PatientMergeActionsTypes;
  payload: IPatientMergeActionPayload;
}

export const addInput = (payload: AddInputPayload): IPatientMergeAction => ({
  payload,
  type: PatientMergeActionsTypes.AddInput,
});

export const addMultiInput = (payload: AddInputPayload[]): IPatientMergeAction => ({
  payload,
  type: PatientMergeActionsTypes.AddMultipleInputs,
});

export const removeInput = (payload: RemoveInputPayload): IPatientMergeAction => ({
  payload,
  type: PatientMergeActionsTypes.RemoveInput,
});

export const removeMultiInput = (payload: RemoveInputPayload[]): IPatientMergeAction => ({
  payload,
  type: PatientMergeActionsTypes.RemoveMultiInputs,
});

export const setInputs = (payload: SetInputsPayload): IPatientMergeAction => ({
  payload,
  type: PatientMergeActionsTypes.SetInputs,
});

export const reset = () => {
  return {
    type: PatientMergeActionsTypes.Reset,
  };
};

export const setDuplicate = (payload: SetDuplicatePayload): IPatientMergeAction => ({
  payload,
  type: PatientMergeActionsTypes.SetDuplicateProfile,
});

export const setPrimary = (payload: SetPrimaryPayload): IPatientMergeAction => ({
  payload,
  type: PatientMergeActionsTypes.SetPrimaryProfile,
});

export const clearDuplicateProfileInformation = (
  payload: SetPrimaryPayload,
): IPatientMergeAction => ({
  payload,
  type: PatientMergeActionsTypes.ClearDuplicateProfileInformation,
});

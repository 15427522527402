import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

export const styles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    sectionWrapperHeader: {
      backgroundColor: palette.primary.trellisBlue,
      color: palette.primary.white,
      borderRadius: '5px 5px 0px 0px',
    },
    sectionWrapperExpandIcon: {
      color: palette.primary.white,
    },
    sectionWrapperSubtitle: {
      color: palette.primary.white,
      fontSize: 12,
    },
    sectionWrapperContent: {
      padding: 0,
    },
    sectionWrapperExpansionPanel: {
      border: 0,
    },
    innerSectionExpansionPanel: {
      border: 'none',
      padding: 0,
      borderRadius: 5,
    },
    innerSectionHeader: {
      backgroundColor: palette.primary.grey2,
    },
    innerSectionSubtitle: {
      color: palette.primary.steelGrey,
      fontSize: 12,
    },
    patientSectionTitle: {
      fontSize: 12,
      color: palette.primary.grey12,
      marginBottom: 10,
    },
    patientSectionContent: {
      fontSize: 13,
      color: palette.primary.darkGrey,
      lineHeight: '23px',
      fontWeight: 100,
    },
    disabledText: {
      color: palette.primary.steelGrey,
      opacity: 0.4,
    },
    titleSpacing: {
      paddingBottom: theme.spacing(1),
    },
  });
};

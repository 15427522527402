import React from 'react';

import docIcon from '../../lib/file-type-icons/doc.svg';
import fileIcon from '../../lib/file-type-icons/file.svg';
import imageIcon from '../../lib/file-type-icons/image.svg';
import pdfIcon from '../../lib/file-type-icons/pdf.svg';
import pptIcon from '../../lib/file-type-icons/ppt.svg';
import xlsIcon from '../../lib/file-type-icons/xls.svg';

const DocumentFileIcon = props => {
  const defaultIcon = fileIcon;
  const { filename, fileNameExtension } = props;

  const getIcon = fileExtension => {
    const fileExtensionMap = {
      doc: docIcon,
      docx: docIcon,
      png: imageIcon,
      gif: imageIcon,
      jpg: imageIcon,
      xls: xlsIcon,
      xlsx: xlsIcon,
      ppt: pptIcon,
      pptx: pptIcon,
      pdf: pdfIcon,
    };

    if (fileExtension) {
      const icon = fileExtensionMap[fileExtension.trim().toLowerCase()];
      if (icon) {
        return icon;
      }
    }

    return defaultIcon;
  };

  const renderFileIcon = (fname, imageProps) => {
    const fileExtension = fname && fname.length > 0 ? fname.split('.') : undefined;
    let src = defaultIcon;
    if (fileNameExtension) {
      src = getIcon(fileNameExtension);
    }
    if (fileExtension && fileExtension[fileExtension.length - 1]) {
      src = getIcon(fileExtension[fileExtension.length - 1]);
    }
    const { className } = imageProps;
    return <img src={src} alt={fname} className={className} />;
  };

  return renderFileIcon(filename, props);
};

export default DocumentFileIcon;

import printJS from 'print-js';
import { getDownloadUrl } from '../../../services/utils/upload-document-service';

export const printPdfDocument = async (
  documentId: number,
  customerId: number,
  patientId: number,
) => {
  const response = await getDownloadUrl(customerId, patientId, documentId);
  const { url, filename } = response.data;
  printJS({ printable: url, documentTitle: filename, type: 'pdf', showModal: true });
};

import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { useState } from 'react';
import { getStatusByStatusId, getStatusIdByStatusName } from 'services/utils/task-service';
import NoteDisplay from 'components/note/note-display';
import DocumentDisplay from 'components/document/document-display';
import compose from 'recompose/compose';
import { connect, useSelector } from 'react-redux';
// eslint-disable-next-line max-len
import InterventionCopyContentButton from 'components/intervention-button/intervention-copy-content-button';
import TaskStatusForm from 'components/dynamic-form/task-forms/task-status-form';
import TaskDetailForm from 'components/dynamic-form/task-forms/task-detail-form';
import { isDurInterventionTask } from 'components/dynamic-form/helpers-tasks';
import { useFillCycleNumber } from 'hooks/useFillCycleNumber';
import ActionRow from 'components/note/action-row';
import intJson from 'config/task-types/int.json';
import { TASK_INTERVENTIONS } from '../../../constants/index';
import { styles } from '../task-detail-styles';
import EditIntervention from './edit-intervention';
import TaskLinks from '../task-links';
import { StatusSelector } from '../common/status-selector';

const InterventionDetail = props => {
  const {
    task,
    classes,
    notes,
    documents,
    patient,
    serviceGroups,
    tagTypeId,
    tagTypeLabel,
    therapy,
    displayEdit,
    handleCancel,
    taskIDPrefix,
    taskStatuses,
    drugName,
    tasks,
  } = props;

  const userRole = useSelector(state => state.auth.currentUser.role);

  const filterStatusesIfRestricted = (statusList, intJsonStatuses, userRole) => {
    const filteredStatuses = statusList.reduce((acc, status) => {
      const tempJsonStatus = intJsonStatuses.find(t => t.name === status.status);
      if (!tempJsonStatus.restrictedByrole) {
        acc.push(status);
      } else if (
        !tempJsonStatus.restrictedRoles ||
        !tempJsonStatus.restrictedRoles.length ||
        !tempJsonStatus.restrictedRoles.includes(userRole.toLowerCase())
      ) {
        acc.push(status);
      }
      return acc;
    }, []);
    return filteredStatuses;
  };

  const statusList = props.statuses;
  const statuses = filterStatusesIfRestricted(statusList, intJson.statuses, userRole);

  // Disable edition only for INT created from a DUR
  const durInterventionTask = isDurInterventionTask(task, tasks);

  const {
    status_id: statusId,
    fill_cycle_number: fillCycleNumber,
    therapy_id: therapyId,
    reason,
  } = task;

  const [selectedStatus, setSelectedStatus] = useState(
    task.status ? task.status : getStatusByStatusId(task.status_id, statuses),
  );

  const selectedStatusId = getStatusIdByStatusName(selectedStatus, statuses);

  const [needsByDate] = useFillCycleNumber(therapyId, fillCycleNumber);

  const interventionType = taskStatuses.intervention_types.find(type => type.id === task.type_id);

  const reasonType =
    reason &&
    taskStatuses.intervention_reasons.find(res => Number(res.id) === Number(reason.split(',')[0]))
      ? taskStatuses.intervention_reasons.find(
          res => Number(res.id) === Number(reason.split(',')[0]),
        ).reason_subtype
      : '';

  let overrideTaskData = {
    needsby_date: needsByDate,
    call_time:
      patient.phones && patient.phones.length > 0 && patient.phones[0].period
        ? patient.phones[0].period
        : 'No phone data for patient',
    preferred_phone:
      patient.phones &&
      patient.phones.length > 0 &&
      patient.phones[0].use &&
      patient.phones[0].value
        ? `${patient.phones[0].use}-${patient.phones[0].value}`
        : 'No phone data for patient',
    reason_type: reasonType,
  };

  if (durInterventionTask) {
    overrideTaskData = {
      ...overrideTaskData,
      details: task.additional_reason,
    };
  }
  return (
    <div>
      <Grid
        container
        alignItems="center"
        className={displayEdit ? classes.taskDetailContainerEdit : classes.taskDetailContainer}
      >
        <Grid item xs={durInterventionTask ? 4 : 3}>
          <Grid container alignItems="center">
            {!displayEdit && (
              <StatusSelector
                value={selectedStatus}
                currentStatus={task.status}
                onChange={value => setSelectedStatus(value)}
                statuses={statuses}
                taskIDPrefix={taskIDPrefix}
                enabled={!durInterventionTask}
                task={task}
              />
            )}
          </Grid>
        </Grid>
        {!displayEdit && (
          <Grid item xs={durInterventionTask ? 8 : 5}>
            <TaskDetailForm
              task={{
                ...task,
                ...overrideTaskData,
              }}
              taskType="INT"
            />
          </Grid>
        )}
        {displayEdit && <Grid item xs={5} />}
        <Grid item xs={4}>
          {!displayEdit && !durInterventionTask && (
            <Grid item xs={12}>
              <Grid container className={classes.note}>
                <NoteDisplay {...notes} tagResourceId={task.id} />
              </Grid>
              <DocumentDisplay
                taskIdResourceIds={[{ tagTypeId, resourceId: task.id }]}
                tagTypeId={tagTypeId}
                resourceId={task.id}
                drugName={drugName}
                tagTypeLabel={tagTypeLabel}
                documents={documents}
              />
              <ActionRow
                therapy={therapy}
                task={task}
                tagTypeId={tagTypeId}
                tagResourceId={task.id}
                cancel={handleCancel}
                serviceGroups={serviceGroups}
                taskIDPrefix={taskIDPrefix}
                taskType={TASK_INTERVENTIONS}
                noIntervention
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid>
          <TaskLinks links={task.links} />
        </Grid>
        <Grid>
          {!displayEdit && (
            <InterventionCopyContentButton
              interventionType={interventionType ? interventionType.type_name : null}
              details={task.details}
            />
          )}
        </Grid>
      </Grid>
      <Grid container>
        {displayEdit && (
          <Grid item xs={12}>
            <EditIntervention
              task={task}
              statuses={statuses}
              cancel={handleCancel}
              serviceGroups={serviceGroups}
              therapy={therapy}
            />
          </Grid>
        )}
        {!displayEdit &&
          selectedStatus !== getStatusByStatusId(statusId, statuses) &&
          statusId &&
          statuses && (
            <Grid
              item
              xs={12}
              className={selectedStatus !== task.status ? classes.statusContainer : null}
            >
              <TaskStatusForm
                task={task}
                forceStatusId={selectedStatusId}
                onCancel={() => setSelectedStatus(getStatusByStatusId(task.status_id, statuses))}
              />
            </Grid>
          )}
      </Grid>
    </div>
  );
};

function mapStateToProps(state) {
  const { patient, taskStatuses, lookups, tasks } = state;
  return {
    patient,
    taskStatuses,
    lookups,
    users: lookups.users,
    tasks,
  };
}

export default compose(withStyles(styles), connect(mapStateToProps))(InterventionDetail);

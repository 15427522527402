import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';

export default props => {
  const { qaId, input, field, formId } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (input.value !== field.defaultValue) {
      dispatch(change(formId, field.property, field.defaultValue));
    }
  }, [field?.defaultValue]);

  return (
    <input {...input} data-qa-id={qaId} type="hidden" value={input?.value || field?.defaultValue} />
  );
};

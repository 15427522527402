import createStyles from '@mui/styles/createStyles';

export const styles = (theme: any) =>
  createStyles({
    nextStep: {
      height: '100%',
      width: '100%',
      top: 'calc(50% - 100px)',
      left: 'calc(50% - 150px)',
      position: 'absolute',
      backgroundColor: 'transparent',
    },
  });

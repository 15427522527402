import { Reducer } from 'redux';
import { FormDisplayAction } from 'actions/action-form-displays';
import {
  TOGGLE_DISPLAY_ADD_CONTACT,
  TOGGLE_DISPLAY_ADD_THERAPY,
  TOGGLE_DISPLAY_ADD_THERAPY_MODAL,
  TOGGLE_DISPLAY_ADD_FINANCIAL_ASSISTANCE,
  TOGGLE_DISPLAY_ADD_INCOME,
  TOGGLE_DISPLAY_ADD_PBM_INSURANCE,
  TOGGLE_DISPLAY_ADD_MEDICAL_INSURANCE,
} from '../constants/index';

const initialState = {
  displayAddTherapy: false,
  displayAddTherapyModal: false,
  displayAddContact: false,
  displayAddIncome: false,
  displayAddMedicalInsurance: false,
  displayAddPBMInsurance: false,
  displayAddFinancialAssistance: false,
};

interface IFormDisplayState {
  displayAddTherapy: boolean;
  displayAddTherapyModal: boolean;
  displayAddContact: boolean;
  displayAddIncome: boolean;
  displayAddMedicalInsurance: boolean;
  displayAddPBMInsurance: boolean;
  displayAddFinancialAssistance: boolean;
}

const toggleState = (
  state: IFormDisplayState,
  stateKey: keyof IFormDisplayState,
  payload: boolean | undefined,
) => {
  let newState = !!payload;
  if (payload === undefined) {
    newState = !state[stateKey];
  }
  const newStateObject: Partial<IFormDisplayState> = {};
  if (newState) {
    // Important! This assumes that only one form should be open at a time.
    // If that assumption changes, this should be re-evaluated
    for (const key of Object.keys(state)) {
      newStateObject[key as keyof IFormDisplayState] = false;
    }
  }
  newStateObject[stateKey] = newState;
  return { ...state, ...newStateObject };
};

const formDisplayReducer: Reducer<IFormDisplayState, FormDisplayAction> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case TOGGLE_DISPLAY_ADD_THERAPY: {
      return toggleState(state, 'displayAddTherapy', payload);
    }
    case TOGGLE_DISPLAY_ADD_THERAPY_MODAL: {
      return toggleState(state, 'displayAddTherapyModal', payload);
    }
    case TOGGLE_DISPLAY_ADD_CONTACT: {
      return toggleState(state, 'displayAddContact', payload);
    }
    case TOGGLE_DISPLAY_ADD_INCOME: {
      return toggleState(state, 'displayAddIncome', payload);
    }
    case TOGGLE_DISPLAY_ADD_MEDICAL_INSURANCE: {
      return toggleState(state, 'displayAddMedicalInsurance', payload);
    }
    case TOGGLE_DISPLAY_ADD_PBM_INSURANCE: {
      return toggleState(state, 'displayAddPBMInsurance', payload);
    }
    case TOGGLE_DISPLAY_ADD_FINANCIAL_ASSISTANCE: {
      return toggleState(state, 'displayAddFinancialAssistance', payload);
    }
    default:
      return state;
  }
};

export default formDisplayReducer;

import React, { useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReactSelectControlled } from 'components/form/field/react-select';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.primary.grey2,
    padding: 10,
  },
  left: {
    marginRight: 10,
    marginTop: 5,
  },
  middle: {
    width: 210,
  },
  right: {
    marginLeft: 10,
    marginTop: 5,
  },
}));

export default ({ drugs, onChange }) => {
  const fields = useMemo(
    () =>
      drugs.map(drug => ({
        label: drug.drug_name,
        value: drug.id,
        drug,
      })),
    [drugs],
  );
  const [value, setValue] = useState(fields.length ? fields[0] : null);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography className={classes.left}>You are using</Typography>
      <ReactSelectControlled
        className={classes.middle}
        fields={fields}
        value={value}
        onChange={field => {
          if (field) {
            setValue(field);
            onChange(field.drug);
          }
        }}
      />
      <Typography className={classes.right}>
        data to fill other therapies validation. You can edit them separately.
      </Typography>
    </div>
  );
};

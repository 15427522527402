import { isAfter } from 'date-fns';
import { LookupInsuranceTypeEnum } from '../../../constants/enums';

type InsuranceFormPayloadType = {
  no_insurance: boolean;
  end_date: Date | null;
  insurance_type_id: LookupInsuranceTypeEnum;
};

export const isValidInsuranceToResolveAudit = (insuranceFormPayload: InsuranceFormPayloadType) => {
  const setAsNoInsurance = !!insuranceFormPayload.no_insurance;
  const validFutureDate =
    !insuranceFormPayload.end_date || isAfter(new Date(insuranceFormPayload.end_date), new Date());
  const isPrimary = insuranceFormPayload.insurance_type_id === LookupInsuranceTypeEnum.Primary;
  // Return true when insurance was marked as 'No Insurance'
  // or it's a Primary insurance with an end date being null or in the future.
  return setAsNoInsurance || (isPrimary && validFutureDate);
};

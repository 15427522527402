import React, { useEffect } from 'react';
import { TableCell, TableSortLabel, TableHead, TableRow } from '@mui/material';
import { ArrowDownward } from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { ToggleTasklistSort, SetTasklistSort } from 'actions/action-tasklist-sort';
import { THERAPIES, AR, DUR, CSL, FC, FDC, INT, OR, SMS_RELATED_TASK, STAR } from 'constants/index';
import { styles } from '../side-bar-styles';

const CustomArrowDownwardIcon = props => <ArrowDownward style={{ fontSize: 14 }} {...props} />;

const TaskListHeader = props => {
  const { classes, patientCount } = props;

  const patientList = useSelector(state => state.patientList);
  const tasklistSort = useSelector(state => state.tasklistSort);
  const sidebarTaskType = useSelector(state => state.sidebarTaskType);
  let { loadedTask: renderedList } = patientList;
  if (sidebarTaskType === STAR) {
    renderedList = STAR;
  }
  const { order, orderBy } = tasklistSort;
  const dispatch = useDispatch();

  const onClickTableHeader = orderByColumn => dispatch(new ToggleTasklistSort(orderByColumn));
  const setInitialSort = (_orderBy, _order) => {
    dispatch(new SetTasklistSort({ orderBy: _orderBy, order: _order }));
  };

  const mapTaskPropsToLabels = {
    last_name: 'Patient',
    drug_name: 'Therapy',
    followup_dt: 'Follow Up',
    needsby_date: 'Needs By',
    due_date: 'Due Date',
    referral_dt: 'Referral Date',
    generated_date: 'Generated Date',
    communication_type: 'Appointment Type',
    counseling_type: 'Counseling type',
    ship_date: 'Ship Date',
    delivery_dt: 'Expected Delivery Date',
    created: 'Generated Date',
    received_dt: 'Received Date',
    priority: 'Priority',
  };

  const SortableTableHeaderCell = ({ value, label, className }) => (
    <TableCell className={className}>
      <TableSortLabel
        active={orderBy === value}
        direction={order}
        onClick={() => onClickTableHeader(value)}
        IconComponent={CustomArrowDownwardIcon}
      >
        {label === 'Patient' && patientCount > 0 && !patientList.loading
          ? `Patient (${patientCount})`
          : label}
      </TableSortLabel>
    </TableCell>
  );

  const taskType = renderedList;

  const dueDatePropertyName = 'due_date';

  let propertyNamePrimary = 'followup_dt';
  let propertyNameSecondary = dueDatePropertyName;

  useEffect(() => {
    if (taskType === AR) {
      setInitialSort('priority', 'desc');
    } else {
      setInitialSort('followup_dt', 'asc');
    }
  }, [taskType]);

  switch (taskType) {
    case SMS_RELATED_TASK:
      propertyNameSecondary = 'received_dt';
      break;
    case THERAPIES:
      propertyNamePrimary = 'needsby_date';
      propertyNameSecondary = 'referral_dt';
      break;
    case AR:
      propertyNameSecondary = 'priority';
      break;
    case DUR:
      propertyNameSecondary = 'generated_date';
      break;
    case CSL:
      propertyNameSecondary = 'counseling_type';
      break;
    case FC:
      propertyNameSecondary = 'needsby_date';
      break;
    case FDC:
      propertyNamePrimary = 'ship_date';
      propertyNameSecondary = 'delivery_dt';
      break;
    case INT:
      propertyNameSecondary = 'created';
      break;
    case OR:
      propertyNameSecondary = 'created';
      break;
    case STAR:
      propertyNamePrimary = null;
      propertyNameSecondary = null;
      break;
    default:
  }

  return (
    <TableHead>
      {taskType !== STAR && (
        <TableRow>
          <SortableTableHeaderCell
            className={classNames(classes.tableHeader, classes.patientCol)}
            label={mapTaskPropsToLabels.last_name}
            value="last_name"
            order={order}
            orderBy={orderBy}
            onClickTableHeader={onClickTableHeader}
          />
          <SortableTableHeaderCell
            className={classNames(classes.tableHeader, classes.therapyCol)}
            label={mapTaskPropsToLabels.drug_name}
            value="drug_name"
            order={order}
            orderBy={orderBy}
            onClickTableHeader={onClickTableHeader}
          />
          {propertyNamePrimary && (
            <SortableTableHeaderCell
              className={classNames(classes.tableHeader, classes.followupCol)}
              label={mapTaskPropsToLabels[propertyNamePrimary]}
              value={propertyNamePrimary}
              order={order}
              orderBy={orderBy}
              onClickTableHeader={onClickTableHeader}
            />
          )}
          {propertyNameSecondary && (
            <SortableTableHeaderCell
              className={classNames(classes.tableHeader, classes.needsByCol)}
              label={mapTaskPropsToLabels[propertyNameSecondary]}
              value={propertyNameSecondary}
              order={order}
              orderBy={orderBy}
              onClickTableHeader={onClickTableHeader}
            />
          )}
        </TableRow>
      )}
    </TableHead>
  );
};
export default withStyles(styles, { withTheme: true })(TaskListHeader);

import { ISpecialPopulation } from '../../interfaces/redux/ISpecialPopulation';

export const getSpecialPopulationsFiltered = (
  specialPopulations: ISpecialPopulation[],
  statusFilter: any = null,
) => {
  if (!specialPopulations || specialPopulations.length === 0) {
    return [];
  }
  const specialPopulationsToPass = specialPopulations.filter(
    (specialPopulation: ISpecialPopulation) =>
      !statusFilter || statusFilter.some((status: number) => specialPopulation.active === status),
  );

  return specialPopulationsToPass;
};

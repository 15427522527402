import { FormControl, FormLabel, Button, ButtonGroup } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import Validation from '../validation/validation';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
  },
  container: {
    width: '100%',
    overflowX: 'auto',
  },
  subContainer: {
    width: '100%',
    padding: theme.spacing(2, 0),
  },
  buttonGroup: {
    width: '100%',
  },
  maxMinContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  minText: {
    color: theme.palette.primary.main,
    width: 200,
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    flex: 1,
  },
  unselected: {
    flex: 1,
  },
  maxText: {
    color: theme.palette.primary.main,
    width: 200,
    textAlign: 'right',
  },
}));

export default ({
  input,
  label,
  fields,
  size = 'medium',
  minText,
  maxText,
  meta: { touched, error, warning },
  disabled,
  qaId,
  ...custom
}) => {
  const classes = useStyles();

  return (
    <>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">{label}</FormLabel>
        <div className={classes.container}>
          <div className={classes.subContainer}>
            {minText && maxText && (
              <div className={classes.maxMinContainer}>
                <span className={classes.minText}>{minText}</span>
                <span className={classes.maxText}>{maxText}</span>
              </div>
            )}
            <ButtonGroup size={size} className={classes.buttonGroup} {...custom}>
              {fields.map(field => {
                const selected = String(input.value) === String(field.value);
                return (
                  <Button
                    key={field.value}
                    value={field.value}
                    className={selected ? classes.selected : classes.unselected}
                    onClick={() => input.onChange(field.value)}
                    disabled={disabled}
                    data-qa-id={qaId ? `${qaId}_${field.value}` : ''}
                  >
                    {field.label}
                  </Button>
                );
              })}
            </ButtonGroup>
          </div>
        </div>
      </FormControl>
      <Validation touched={touched} error={error} warning={warning} />
    </>
  );
};

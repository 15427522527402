import { chunk, isEmpty } from 'lodash';
import { AxiosResponse } from 'axios';

import { ancillarySupplies, methodOfDelivery, deliveryShippingMethodMap } from 'constants/lists';
import { DeliveryMethodValue } from 'constants/enums';
import { IPatient } from 'interfaces/redux/IPatient';
import { IUser } from 'interfaces/redux/IUser';
import { IContactList } from 'interfaces/redux/IContactList';
import { ITherapy } from 'interfaces/redux/ITherapy';
import { TherapyId } from 'interfaces/RecordTypes';
import { IDispensingPharmacy } from 'interfaces/redux/IDispensingPharmacy';
import {
  buildCopayAmountListFromTasks,
  calculateTotalCopayAmount,
} from 'containers/tasks/fill-coordination/fc-util';
import { dosageFormToUIDisplay } from 'services/utils/dosageFormToUIDisplay';
import { convertToArborDate } from '../../../models/time/arbor-date';
import { DocumentGeneratorType, DocumentTemplate } from './types';
import { FillCoordinationDescription } from '../../../models/tasks/FillCoordinationDescription';
import { Generator } from './generator';
import { stripFormattedDollarValue } from '../../../services/utils/formatting';

import {
  getFullPhoneList,
  formatPatientName,
  getFullEmailList,
} from '../../../services/utils/demographic-service';

import { IDocumentPage } from '../../../models/document_generator/IDocumentPage';
import { getGeneratedDocument } from '../../../services/utils/document-service';
import { IGeneratedDocumentResponse } from '../../../models/document_generator/IGeneratedDocumentResponse';
import { ICompleteFcGeneratorTask } from './interfaces/fc-types';

import { barcodeImageDataFromOrderId } from './barcodeGenerator';

export class FcGenerator extends Generator<
  ICompleteFcGeneratorTask,
  { delivery: string; medications: string }
> {
  private phone: string | undefined;

  constructor(
    customerId: number,
    patientId: number,
    private defaultMedsPerPage: number = 8,
    private customMedsPerPage: number = 32,
  ) {
    super(
      DocumentGeneratorType.FillCoordination,
      customerId,
      patientId,
      'Fill Coordination Delivery',
      DocumentTemplate.FC_OrderForm,
      {
        delivery: DocumentTemplate.FC_OrderFormDelivery,
        medications: DocumentTemplate.FC_OrderFormMedications,
      },
    );
  }

  private static taskIsCompleting = (tasks: any[], formValues: any[]): boolean => {
    const currentStatusIsNotCompleted =
      tasks &&
      tasks.length > 0 &&
      tasks[0].status_id !== FillCoordinationDescription.statusMap.Completed;
    const newStatusIsComplete =
      Object.keys(formValues).length > 0 &&
      formValues[tasks[0].id]?.status_id === FillCoordinationDescription.statusMap.Completed;

    return currentStatusIsNotCompleted && newStatusIsComplete;
  };

  private getLabelFromValue = (
    value: string | undefined | number,
    labelValues: { label: string; value: string | number }[],
  ): string | undefined => {
    const match = labelValues.find(x => x.value === value);
    return match?.label;
  };

  private buildAncillarySuppliesList = (valueList?: string): (string | undefined)[] | undefined => {
    if (valueList) {
      try {
        const labelValues = valueList.split(',').map(supply => {
          return this.getLabelFromValue(supply, ancillarySupplies);
        });
        return labelValues.filter(lv => lv);
      } catch (error) {
        // TODO: implement error handling. Maybe call existing logger?
      }
    }

    return undefined;
  };

  private buildShippingPayload = (
    task: ICompleteFcGeneratorTask,
    patient: IPatient,
    contactList: IContactList,
  ): any => {
    const address = this.getAddress(task);
    const rawEmail = (getFullEmailList(patient, contactList) as any).find(
      (x: any) => x.label === task.delivery.email,
    )?.rawEmail;

    const payload = {
      instructions: task.delivery.rx_delivery_note,
      phone: this.phone,
      email: rawEmail?.value,
      expected_delivery_date: this.formatDateTimeStringToDateString(
        task.delivery.delivery_dt,
        'MM/DD/YYYY',
      ),
      date: this.formatDateTimeStringToDateString(task.delivery.ship_dt, 'MM/DD/YYYY'),
      method: this.getLabelFromValue(
        task.delivery.shipping_method,
        deliveryShippingMethodMap[task.delivery?.method || -1] || [],
      ),
      address_line_1: address.line1,
      signature_required: !!task.delivery.signature_required,
      order_method_note: '',
    };

    switch (task.delivery.method) {
      case DeliveryMethodValue.PickUp:
        payload.date = undefined;
        payload.method = undefined;
        payload.order_method_note = task.delivery.patient_advised_pickup
          ? 'Patient advised of pickup location'
          : '';
        break;
      case DeliveryMethodValue.FedEx:
      case DeliveryMethodValue.Ups:
      case DeliveryMethodValue.Usps:
        break;
      case DeliveryMethodValue.Courier:
        payload.method = task.delivery.order_type_address;
        break;
      default:
        throw new Error('Unhandled delivery type');
    }

    return payload;
  };

  private getAddress = (task: ICompleteFcGeneratorTask): { line1?: string } => {
    let deliveryMethod = task.delivery.method;

    if (typeof deliveryMethod === 'string') {
      deliveryMethod = Number(deliveryMethod);
    }
    switch (deliveryMethod) {
      case DeliveryMethodValue.PickUp:
        return {
          line1: task.order_type_address,
        };
      case DeliveryMethodValue.Courier:
      case DeliveryMethodValue.FedEx: {
        return {
          line1: task.address?.label,
        };
      }
      case DeliveryMethodValue.Ups: {
        return {
          line1: task.address?.label,
        };
      }
      case DeliveryMethodValue.Usps: {
        return {
          line1: task.address?.label,
        };
      }

      default:
        return {};
    }
  };

  private buildPatientPayload = (
    task: ICompleteFcGeneratorTask,
    patient: IPatient,
    _contactList: IContactList,
    _therapies: Record<TherapyId, ITherapy>,
  ): any => {
    const patientEmail = (getFullEmailList(patient) as any).find(
      (x: any) => x.label === task.delivery.email,
    )?.rawEmail;

    const payload = {
      name: patient.nickname
        ? `${formatPatientName(patient)} (${patient.nickname})`
        : formatPatientName(patient),
      phone: this.phone,
      dob: this.formatPatientDobString(patient.dob),
      email: patientEmail?.value,
      source_patient_id: patient.source_patient_id,
    };

    return payload;
  };

  private buildMedicationsPayload = (
    task: ICompleteFcGeneratorTask,
    _patient: IPatient,
    _contactList: IContactList,
    therapies: Record<TherapyId, ITherapy>,
    otherTasks: ICompleteFcGeneratorTask[],
    bundledDelivery: boolean,
  ): {
    medications: { name: string; otc: boolean; dispenseQuantity?: number }[];
    includesOtc: boolean;
  } => {
    let includesOtc = false;
    const medications: { name: string; otc: boolean; dispenseQuantity?: number }[] = [];
    const therapy = therapies[task.therapy_id];

    const formatTherapyName = (t: any, dispenseQuantity: number | undefined) => {
      const orderFormDisplay = dosageFormToUIDisplay(t.dosage_form);
      const name = `${t.drug_name} ${t.dosage_form} ${t.strength} ${t.strength_unit_of_measure}`;
      const nameWithDispenseQty = isEmpty(dispenseQuantity?.toString())
        ? name
        : `${name} - ${dispenseQuantity}`;
      const nameWithDosageOrderFormDisplay =
        isEmpty(dispenseQuantity?.toString()) || orderFormDisplay == null
          ? nameWithDispenseQty
          : `${nameWithDispenseQty} ${orderFormDisplay}`;
      return nameWithDosageOrderFormDisplay;
    };

    medications.push({
      name: formatTherapyName(therapy, task.dispense_qty),
      otc: false,
    });

    if (bundledDelivery) {
      otherTasks.forEach(task => {
        const therapy = therapies[task.therapy_id];
        medications.push({
          name: formatTherapyName(therapy, task.dispense_qty),
          otc: false,
        });
      });
    }

    ((task.additional_medications as unknown as any[]) || []).forEach((med: any) => {
      medications.push({
        name: med.value.drug_info,
        otc: true,
      });
      includesOtc = true;
    });

    return { medications, includesOtc };
  };

  private buildFormLabels = (
    method: number | string | undefined,
  ):
    | {
        method_address_label: string;
        method_label: string;
        method_date_label: string;
        method_expected_date_label: string;
        instructions_label: string;
      }
    | undefined => {
    if (typeof method === 'string') {
      method = Number(method);
    }
    switch (method) {
      case DeliveryMethodValue.PickUp:
        return {
          method_address_label: 'Pick Up Address',
          method_label: '',
          method_date_label: '',
          method_expected_date_label: 'Expected Pick Up Date',
          instructions_label: 'Pick Up Instructions',
        };
      case DeliveryMethodValue.FedEx:
      case DeliveryMethodValue.Ups:
      case DeliveryMethodValue.Usps:
        return {
          method_address_label: 'Shipping Address',
          method_label: 'Shipping Method',
          method_date_label: 'Ship Date',
          method_expected_date_label: 'Expected Delivery Date',
          instructions_label: 'Delivery Instructions',
        };
      case DeliveryMethodValue.Courier:
        return {
          method_address_label: 'Courier To Address',
          method_label: '',
          method_date_label: 'Courier Pick Up Date',
          method_expected_date_label: 'Expected Delivery Date',
          instructions_label: 'Courier Instructions',
        };
      default:
        return undefined;
    }
  };

  private formatDateTimeStringToDateString = (
    dateTime: string | null | undefined,
    format: 'MM/DD/YYYY' | 'MM/DD/YYYY hh:mm A',
    isUtc = false,
  ): string | undefined => {
    if (dateTime) {
      const arborDate = convertToArborDate(dateTime, isUtc);
      const customerDate = arborDate.getCustomerDate(false, format);
      return customerDate || undefined;
    }

    return undefined;
  };

  private formatPatientDobString = (dateTime: string): string | null => {
    if (dateTime) {
      const arborDob = convertToArborDate(dateTime, true);
      const displayDob = arborDob.getUtcDate(true);
      return displayDob;
    }

    return null;
  };

  private buildUserPayload = (user: any): { name: string } => {
    return { name: user.display_name };
  };

  private getCopayAmount = (task: any, bundledDelivery: boolean): number => {
    let amount = 0;
    try {
      amount = Number(task?.copay_amount);
    } catch (error) {
      // TODO: log this error some how
    }

    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(amount)) {
      return amount;
    }

    try {
      amount = stripFormattedDollarValue(task.copay_amount);
      return amount;
    } catch (error) {
      // TODO: log this error some how
    }

    return 0;
  };

  private getMedsPerPage = (currentMeds: any[]): number => {
    return currentMeds.length <= this.defaultMedsPerPage
      ? this.defaultMedsPerPage
      : this.customMedsPerPage;
  };

  private getTemplateFileName = (medsListSize: number): string | undefined => {
    return medsListSize <= this.defaultMedsPerPage
      ? this.defaultTemplateFileName
      : this.additionalTemplates?.medications;
  };

  private addDeliveryPageWhenRequired = (medsListSize: number, payloads: any[]) => {
    if (medsListSize <= this.defaultMedsPerPage) {
      return payloads;
    }
    return [
      ...payloads,
      {
        ...payloads[0],
        template: this.additionalTemplates?.delivery,
      },
    ];
  };

  buildTemplatePayload(
    task: ICompleteFcGeneratorTask,
    patient: IPatient,
    contactList: IContactList,
    therapies: Record<TherapyId, ITherapy>,
    user: IUser,
    otherTasks: ICompleteFcGeneratorTask[],
    dispensingPharmacy: IDispensingPharmacy,
    bundledDelivery: boolean,
  ): any {
    this.phone = (getFullPhoneList(patient, contactList) as any).find(
      (x: any) => x.label === task.delivery.phone,
    )?.rawPhone?.value;

    const medicationData = this.buildMedicationsPayload(
      task,
      patient,
      contactList,
      therapies,
      otherTasks,
      bundledDelivery,
    );

    const taskTherapy = therapies[task.therapy_id];
    const medsPerPage = this.getMedsPerPage(medicationData.medications);
    const medsSplit = chunk(medicationData.medications, medsPerPage);

    const copayTasks = bundledDelivery ? [task, ...otherTasks] : [task];
    const copayList = buildCopayAmountListFromTasks(copayTasks);
    const documentCopayAmount = calculateTotalCopayAmount(copayList);

    const taskPayloads = medsSplit.map((medications: any) => ({
      medications,
      shipping: this.buildShippingPayload(task, patient, contactList),
      patient: this.buildPatientPayload(task, patient, contactList, therapies),
      includes_otc: medicationData.includesOtc,
      privacy_notice: Boolean(task.is_notice_privacy_practices_sent),
      welcome_kit: Boolean(task.welcome_kit_sent),
      wk_pp_received: Boolean(task.wk_pp_received),
      ancillary_supplies: this.buildAncillarySuppliesList(task.ancillary_supplies),
      order_id: task.order_id,
      order_id_barcode_img: task.order_id ? barcodeImageDataFromOrderId(task.order_id) : null,
      order_method: this.getLabelFromValue(task.delivery.method, methodOfDelivery),
      urgent: task.delivery.urgent,
      order_notes: task.order_notes,
      cold_chain_packaging: Boolean(task.delivery.cold_chain_packing_required),
      order_date: this.formatDateTimeStringToDateString(
        new Date().toString(),
        'MM/DD/YYYY hh:mm A',
        true,
      ),
      order_type_address: dispensingPharmacy.dispensing_pharmacy_name,
      needs_by_date: this.formatDateTimeStringToDateString(task.needsby_date, 'MM/DD/YYYY'),
      ...this.buildFormLabels(task.delivery.method),
      user: this.buildUserPayload(user),
      copay_amount: documentCopayAmount,
      payment_chip: task.medicaid_only || task.payment_method_on_file,
      payment_chip_text: task.medicaid_only
        ? 'Patient is NOT able to afford copay'
        : 'Payment method on file',
      payment_chip_text_card_info:
        task.payment_method_on_file && task.payment_method_info != null
          ? task.payment_method_info
          : '',
    }));
    return {
      taskPayloads,
      medsListSize: medicationData.medications.length,
    };
  }

  metadataName(): string {
    return 'delivery_document';
  }

  saveButtonText(tasks: any[], formValues: any[]): string | undefined {
    return FcGenerator.taskIsCompleting(tasks, formValues) ? 'Save & Preview' : undefined;
  }

  showPreviewButton(tasks: any[], formValues: any[]): boolean {
    return FcGenerator.taskIsCompleting(tasks, formValues);
  }

  documentLabels(): string[] {
    return ['Specialty Order Forms if Dispensing'];
  }

  getGeneratedDocumentResult = async (
    tasks: ICompleteFcGeneratorTask[],
    patient: IPatient,
    contactList: IContactList,
    therapies: Record<TherapyId, ITherapy>,
    user: IUser,
    dispensingPharmacy: IDispensingPharmacy,
    bundledDelivery: boolean,
  ): Promise<AxiosResponse<IGeneratedDocumentResponse>> => {
    const taskList = bundledDelivery ? [tasks[0]] : tasks;

    const otherTasks = [...tasks];

    const documentPages: IDocumentPage[] = taskList.reduce(
      (pages: any[], task: any, index: number) => {
        otherTasks.splice(index, 1);
        const { taskPayloads, medsListSize } = this.buildTemplatePayload(
          task,
          patient,
          contactList,
          therapies,
          user,
          otherTasks,
          dispensingPharmacy,
          bundledDelivery,
        );

        const payloads = this.addDeliveryPageWhenRequired(
          medsListSize,
          taskPayloads.map((payload: any) => ({
            template: this.getTemplateFileName(medsListSize),
            data: payload,
          })),
        );

        return [...pages, ...payloads];
      },
      [],
    );

    // Adding page numbers to payload
    const enumeratedPages: IDocumentPage[] = documentPages.map(
      (documentPage: IDocumentPage, index: number) => ({
        ...documentPage,
        data: {
          ...documentPage.data,
          totalPages: documentPages.length,
          page: index + 1,
        },
      }),
    );

    return await getGeneratedDocument(enumeratedPages, this.customerId, this.patientId);
  };
}

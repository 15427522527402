import { AddPhoneModalActionType, IAddPhoneModalAction } from 'actions/action-add-phone-modal';
import { IAddPhoneModal } from 'interfaces/redux/IAddPhoneModal';

const initialState: IAddPhoneModal = {
  visible: false,
  formId: undefined,
  field: undefined,
};

export const addPhoneModalReducer = (
  state: IAddPhoneModal = initialState,
  action: IAddPhoneModalAction,
) => {
  switch (action.type) {
    case AddPhoneModalActionType.SetState: {
      return {
        ...action.payload,
      };
    }
    case AddPhoneModalActionType.ResetModal: {
      return initialState;
    }
    default:
      return state;
  }
};

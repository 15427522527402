import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from '../../utils/theme-util';

const styles = (theme: Theme) => {
  const { palette, fonts } = expandTheme(theme);

  return createStyles({
    button: {
      'backgroundColor': 'transparent',
      'borderRadius': 20,
      'color': palette.primary.steelGrey,
      'fontSize': fonts.mediumFontSize,
      'fontWeight': fonts.weight.regular,
      'textTransform': 'none',
      'marginRight': theme.spacing(1),
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    selected: {
      'backgroundColor': palette.primary.teal,
      'color': palette.primary.white,
      '&:hover': {
        backgroundColor: palette.primary.teal,
      },
    },
  });
};

export { styles };

import { cloneDeep } from 'lodash';
import { noteTagTypes } from 'constants/lists';
import { DRUG_UTILIZATION_REVIEW_TASK_ID, APPOINTMENT_REFERRAL_TASK_ID } from 'constants/index';
import { insertIntoArray } from 'services/utils/common-service';
import { NotesUtil } from 'utils/notes-util';

const parsePatientNote = (note, users, serviceGroups) => {
  const mentionStart = '<!@';
  const regexEnd = '!>';

  const mentionsRegex = new RegExp(`(?<=\\${mentionStart})(.*?)(?=\\${regexEnd})`, 'g');
  const mentions = note.note_text.match(mentionsRegex);

  let newText = note.note_text;
  if (mentions && serviceGroups && serviceGroups.length > 0 && users && users.length > 0) {
    mentions.forEach(mention => {
      let mentionName;
      if (Number(mention.substring(0, 1)) === 1) {
        mentionName = `!@!${
          users.find(user => user.id === Number(mention.substring(2))).active === 1
            ? users.find(user => user.id === Number(mention.substring(2))).display_name
            : `${
                users.find(user => user.id === Number(mention.substring(2))).display_name
              } (Inactive)`
        }!@!`;
      } else {
        mentionName = `!@!${
          serviceGroups.find(group => group.id === Number(mention.substring(2))).display_name
        }!@!`;
      }
      newText = newText.replace(mentionStart + mention + regexEnd, mentionName);
    });
  }

  return {
    ...note,
    note_text: newText,
  };
};

const parseCommunicationNote = (note, users, serviceGroups) => {
  note = parsePatientNote(note, users, serviceGroups);
  const communicationStart = '<_';
  const regexEnd = '_>';
  const contactReplace = '<contact>';
  const communicationRegex = new RegExp(`(?<=${communicationStart})(.*?)(?=${regexEnd})`, 'g');
  const communications = note.communication.display.match(communicationRegex);

  let newDisplay = note.communication.display;
  if (communications) {
    communications.forEach(communication => {
      const things = communication.split('|');
      if (Number(note.communication.is_incoming) === 1) {
        newDisplay = newDisplay.replace(communicationStart + communication + regexEnd, things[0]);
      } else {
        newDisplay = newDisplay.replace(
          communicationStart + communication + regexEnd,
          things[1] ? things[1] : '',
        );
      }
    });
  }

  if (Number(note.communication.is_patient) === 1) {
    newDisplay = newDisplay.replace(contactReplace, 'Patient');
  } else if (note.communication.contact_id) {
    newDisplay = newDisplay.replace(
      contactReplace,
      `${note.communication.contact_name} (${note.communication.contact_relationship})`,
    );
  } else {
    newDisplay = newDisplay.replace(contactReplace, note.communication.other_contact_type);
  }

  return {
    ...note,
    note_display: newDisplay,
  };
};

export const parseNote = (note, users, serviceGroups) => {
  if (!note) return null;
  if (note.note_type_id === 1) {
    return parsePatientNote(note, users, serviceGroups);
  }
  return parseCommunicationNote(note, users, serviceGroups);
};

// TODO: remove this function and replace usages with typescript version
export const getNotesHigestId = existingNotes => {
  const { pinnedNoteHighestId, nonPinnedNoteHighestId } =
    NotesUtil.getNotesHighestId(existingNotes);

  return { pinnedNoteHighestId, nonPinnedNoteHighestId };
};

export const parseNoteToDb = note => {
  const mentionStart = '<!@';
  const regexEnd = '!>';

  const mentionsRegex = new RegExp(`(?<=\\${mentionStart})(.*?)(?=\\${regexEnd})`, 'g');
  const mentions = note.note_text.match(mentionsRegex);

  let newText = note.note_text;
  const mentionObj = [];
  if (mentions) {
    mentions.forEach(mention => {
      const mentionArr = mention.split('|');
      mentionArr.splice(2, 1); // Remove the display
      mentionObj.push({
        mention_type_id: mentionArr[0],
        resource_id: mentionArr[1],
      });
      const newMention = mentionArr.join('|');
      newText = newText.replace(
        mentionStart + mention + regexEnd,
        mentionStart + newMention + regexEnd,
      );
    });
  }
  return {
    ...note,
    note_text: newText,
    mentions: mentionObj,
  };
};

export function taskIsTherapyAndPatient(note) {
  const noteType = note.tags.find(tag => tag.is_owner === 1);
  switch (noteType.tag_type_id) {
    case 19:
      return true;
    case 22:
      return true;
    default:
      return false;
  }
}

export const findMatchingNotes = (notes, tagTypeId, tagResourceId) => {
  if (notes && notes.length > 0) {
    return notes.filter(
      note =>
        note.is_archived === 0 &&
        note.tags.some(
          tag =>
            (tag.resource_id === tagResourceId && tag.tag_type_id === tagTypeId) ||
            (tag.is_all_of_type === 1 && tag.tag_type_id === tagTypeId),
        ),
    );
  }
  return null;
};

export const parseMatchingNotes = (
  notes,
  tagTypeId,
  tagResourceId,
  users,
  serviceGroups,
  length = 0,
) => {
  const matchingNotes = findMatchingNotes(notes, tagTypeId, tagResourceId);
  if (matchingNotes && matchingNotes.length > 0) {
    const matches = matchingNotes.map(note => parseNote(note, users, serviceGroups));
    if (length !== 0) {
      // If 0, just return first note for backwards compatability of only displaying one
      return matches.splice(0, length);
    }
    return matches[0];
  }
  return [];
};

export const handlePinned = (pinnedLevel, isPinned) => {
  switch (pinnedLevel) {
    case 'This Task':
      return { therapy: false, patient: false };
    case 'Therapy':
      return { therapy: true, patient: false };
    case 'Patient and Therapy':
      return { therapy: true, patient: true };
    case 'All':
      return { therapy: false, patient: false, is_all_of_type: !!isPinned };
    case 'Patient':
    default:
      return { patient: true, therapy: false };
  }
};

export const getPinnedDefaultForForm = note => {
  if (note) {
    const { tags } = note;
    if (tags.some(tag => tag.is_all_of_type === 1)) {
      return 'All';
    }
    if (tags.some(tag => tag.tag_type_id === 1)) {
      return 'Patient';
    }
    if (tags.some(tag => tag.tag_type_id === 2 && tag.resource_id === note.therapy_id)) {
      return 'Therapy';
    }
    return 'This Task';
  }
  return null;
};

export const noteAnchorTag = note =>
  `tag_type_${note.tags.find(tag => tag.is_owner === 1).tag_type_id}_therapy_id_${
    note.therapy_id || 'null'
  }_resource_id_${note.tags.find(tag => tag.is_owner === 1).resource_id}`;

export const noteAnchorTagFromState = noteExpandedLevel =>
  `tag_type_${noteExpandedLevel.tag_type_id}_therapy_id_${
    noteExpandedLevel.therapy_id || 'null'
  }_resource_id_${noteExpandedLevel.tag_resource_id}`;

// Reducer util functions

export function generateTagKey(tagTypeId, tagResourceId) {
  return `T${tagTypeId}R${tagResourceId}`;
}

// trim return a copy of arr that only contains the first note per tag
export function trim(arr) {
  const set = new Set();
  return arr.filter(cur => {
    const tag = cur.tags[0];
    const tagKey = generateTagKey(tag.tag_type_id, tag.resource_id);
    if (!set.has(tagKey)) {
      set.add(tagKey);
      return true;
    }
    return false;
  });
}

export function removeDuplicates(arr) {
  const seen = new Set();
  return arr.filter(note => {
    const duplicate = seen.has(note.id);
    seen.add(note.id);
    return !duplicate;
  });
}

export function minimalNote(noteVar) {
  if (noteVar) {
    return {
      id: noteVar.id,
      tags: noteVar.tags,
      therapy_id: noteVar.therapy_id,
    };
  }
  return null;
}

export function minimalTaskTree(taskId) {
  return {
    notes: {
      nonPinnedNotes: [],
      pinnedNotes: [],
    },
    taskId,
  };
}

export function filterNotesToDisplay(noteVar, tagTypeId, tagResourceId, notesToDisplay) {
  let newNotesToReturn;
  if (notesToDisplay) {
    if (tagTypeId === 1) {
      return notesToDisplay;
    }
    if (tagTypeId === 2) {
      newNotesToReturn = notesToDisplay.filter(note => tagResourceId === note.therapy_id);
      return newNotesToReturn;
    }

    if (tagTypeId > 10) {
      newNotesToReturn = notesToDisplay.filter(therapyNote =>
        therapyNote.tags.some(
          tag => tag.tag_type_id === tagTypeId && tag.resource_id === tagResourceId,
        ),
      );
      return newNotesToReturn;
    }
    return [];
  }
  return [];
}

export function getPinnedNotes(notesvar, doFilterForPinned = true) {
  if (doFilterForPinned) {
    return notesvar
      .filter(note => note.tags.some(e => e.is_pinned === 1))
      .sort((a, b) => {
        const nameA = a.id;
        const nameB = b.id;
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
  }
  return notesvar.sort((a, b) => {
    const nameA = a.id;
    const nameB = b.id;
    if (nameA < nameB) {
      return 1;
    }
    if (nameA > nameB) {
      return -1;
    }
    return 0;
  });
}

export function getNonPinnedNotes(notesvar, doFilterForPinned = true) {
  if (doFilterForPinned) {
    return notesvar
      .filter(note => !note.tags.some(e => e.is_pinned === 1))
      .sort((a, b) => {
        const nameA = a.id;
        const nameB = b.id;
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
  }
  return notesvar.sort((a, b) => {
    const nameA = a.id;
    const nameB = b.id;
    if (nameA < nameB) {
      return 1;
    }
    if (nameA > nameB) {
      return -1;
    }
    return 0;
  });
}

export function togglePinNote(note, state) {
  let retNonPinnedArr = state.notes.allNonPinnedNotes;
  let retPinnedArr = state.notes.allPinnedNotes;
  let retAllNotesArr = state.notes.allNotes;
  if (note.is_pinned) {
    if (note && note.id) {
      retNonPinnedArr = retNonPinnedArr.filter(nonPinnedNote => note.id !== nonPinnedNote.id);
      retPinnedArr = retPinnedArr.filter(pinnedNote => note.id !== pinnedNote.id);
      retPinnedArr = insertIntoArray(retPinnedArr, note);
      retAllNotesArr = retAllNotesArr.map(noteInAll => {
        if (noteInAll.id === note.id) {
          return note;
        }
        return noteInAll;
      });
      return {
        notpinned: retNonPinnedArr,
        pinned: retPinnedArr,
        allNotes: retAllNotesArr,
        newNotesToDisplay: filterNotesToDisplay(
          note,
          state.tag_type_id,
          state.tag_resource_id,
          getPinnedNotes(retPinnedArr, false).concat(getNonPinnedNotes(retNonPinnedArr, false)),
        ),
      };
    }
  } else if (note && note.id) {
    retPinnedArr = getPinnedNotes(
      retPinnedArr.filter(pinnedNote => note.id !== pinnedNote.id),
      false,
    );
    retNonPinnedArr = insertIntoArray(
      retNonPinnedArr.filter(nonPinnedNote => note.id !== nonPinnedNote.id),
      note,
    );
    retAllNotesArr = retAllNotesArr.map(noteInAll => {
      if (noteInAll.id === note.id) {
        return note;
      }
      return noteInAll;
    });
    return {
      notpinned: retNonPinnedArr,
      pinned: retPinnedArr,
      allNotes: retAllNotesArr,
      newNotesToDisplay: filterNotesToDisplay(
        note,
        state.tag_type_id,
        state.tag_resource_id,
        getPinnedNotes(retPinnedArr, false).concat(getNonPinnedNotes(retNonPinnedArr, false)),
      ),
    };
  }
  return null;
}

export function toggleCommunicationNote(note, state) {
  let retNonPinnedArr = state.notes.allNonPinnedNotes;
  let retPinnedArr = state.notes.allPinnedNotes;
  let retAllNotesArr = state.notes.allNotes;
  retPinnedArr = getPinnedNotes(
    retPinnedArr.filter(pinnedNote => note.id !== pinnedNote.id),
    false,
  );
  retNonPinnedArr = getNonPinnedNotes(
    retNonPinnedArr.filter(pinnedNote => note.id !== pinnedNote.id),
    false,
  );
  if (note.is_pinned) {
    retPinnedArr = insertIntoArray(retNonPinnedArr, note);
  } else {
    retNonPinnedArr = insertIntoArray(retNonPinnedArr, note);
  }
  retAllNotesArr = retAllNotesArr.map(noteInAll => {
    if (noteInAll.id === note.id) {
      return note;
    }
    return noteInAll;
  });
  return {
    notpinned: retNonPinnedArr,
    pinned: retPinnedArr,
    allNotes: retAllNotesArr,
    newNotesToDisplay: filterNotesToDisplay(
      note,
      state.tag_type_id,
      state.tag_resource_id,
      getPinnedNotes(retPinnedArr, false).concat(getNonPinnedNotes(retNonPinnedArr, false)),
    ),
  };
}

export function createTasksStructure(taskArr, taskNotes, patientTasks, therapyId) {
  const retObj = {};
  const filteredTaskArr = taskArr.filter(task => task.value > 10);
  filteredTaskArr.forEach(task => {
    const taskNoteIdArr = [];
    if (patientTasks && patientTasks.length > 0) {
      patientTasks.forEach(patientTask => {
        // This was usesd for determining if a task had any notes but
        // looks like it is no longer needed
        // const notesForThisTherapyTask = taskNotes.filter(
        //   (note) =>
        //     (note.therapy_id === patientTask.therapy_id &&
        //       note.tags.some((tag) => tag.tag_type_id === task.value)) ||
        //     (note.therapy_id !== patientTask.therapy_id &&
        //       note.tags.some((tag) => tag.tag_type_id === task.value
        //       && tag.is_all_of_type === 1)),
        // );
        if (
          patientTask &&
          patientTask.taskType === task.label &&
          (patientTask.therapy_id === therapyId ||
            patientTask.therapy_id === null ||
            therapyId === null)
        ) {
          taskNoteIdArr.push({
            taskId: patientTask.id,
            notes: {
              pinnedNotes: [],
              nonPinnedNotes: [],
            },
          });
        }
      });
    }
    if (taskNotes && taskNotes.length > 0) {
      const allNotesOfType = taskNotes.filter(noteVar =>
        noteVar.tags.some(tag => tag.tag_type_id === task.value),
      ); // eslint-disable-line
      if (allNotesOfType.length > 0) {
        allNotesOfType.forEach(note => {
          note.tags.forEach(tag => {
            const idObjForResourceIndex = taskNoteIdArr.findIndex(
              taskNoteObj =>
                (taskNoteObj.taskId === tag.resource_id && tag.tag_type_id === task.value) ||
                tag.is_all_of_type === 1,
            );
            if (idObjForResourceIndex > -1) {
              if (
                tag.is_all_of_type === 1 &&
                tag.is_pinned === 1 &&
                !taskNoteIdArr[idObjForResourceIndex].notes.pinnedNotes.some(
                  noteVar => noteVar.id === note.id,
                )
              ) {
                taskNoteIdArr.forEach(taskNoteVal => taskNoteVal.notes.pinnedNotes.push(note));
              } else if (
                tag.is_pinned === 1 &&
                !taskNoteIdArr[idObjForResourceIndex].notes.pinnedNotes.some(
                  noteVar => noteVar.id === note.id,
                )
              ) {
                taskNoteIdArr[idObjForResourceIndex].notes.pinnedNotes.push(note);
              } else if (
                tag.is_pinned === 0 &&
                !taskNoteIdArr[idObjForResourceIndex].notes.nonPinnedNotes.some(
                  noteVar => noteVar.id === note.id,
                )
              ) {
                taskNoteIdArr[idObjForResourceIndex].notes.nonPinnedNotes.push(note);
              }
            }
          });
        });
      }
    }
    retObj[task.label] = taskNoteIdArr;
  });
  return retObj;
}

export function createPatientNotes() {
  const retObj = {
    patientId: null,
    patientName: null,
    tagIds: null,
    expandedLevel: null,
    notes: {
      allNotes: [],
      allPinnedNotes: [],
      allNonPinnedNotes: [],
      pinnedNotes: [],
      nonPinnedNotes: [],
      notesToDisplay: [],
      archivedNotes: [],
    },
  };
  retObj.tasks = createTasksStructure(noteTagTypes);
  retObj.therapies = [];
  return retObj;
}

export function createTherapySpecificNotes(
  therapyId,
  therapyTagId,
  pinnedNotesVar,
  nonPinnedNotesVar,
  taskNotes,
  tasks,
) {
  const retObj = {
    therapyId,
    therapyTagId,
    notes: { pinnedNotes: pinnedNotesVar, nonPinnedNotes: nonPinnedNotesVar },
  };
  retObj.tasks = createTasksStructure(noteTagTypes, taskNotes, tasks, therapyId);
  return retObj;
}

export function createTherapyLevelNotes(notesVar, tasks) {
  const retArr = [];
  const therapyIds = [];
  const tasksVar = tasks || [];
  tasksVar.forEach(task => {
    if (task && !therapyIds.includes(task.therapy_id)) {
      therapyIds.push(task.therapy_id);
    }
  });
  notesVar.forEach(note => {
    if (note && note.therapy_id && !therapyIds.includes(note.therapy_id)) {
      therapyIds.push(note.therapy_id);
    }
  });
  therapyIds
    .sort((a, b) => b - a)
    .forEach(therapyId => {
      const therapyNotesVar = notesVar.filter(
        note =>
          (note && note.therapy_id === therapyId && note.is_archived === 0) ||
          note.tags.some(tag => tag.tag_type_id > 10),
      );

      const pinnedNotes = [];
      const nonPinnedNotes = [];
      const taskNotes = [];
      let therapyTagId = null;
      therapyNotesVar.forEach(note => {
        if (note.tags.some(tag => tag.tag_type_id === 2)) {
          therapyTagId = note.tags.find(tag => tag.resource_id === therapyId)?.id;
          if (note.tags.some(tag => tag.is_pinned === 1)) {
            pinnedNotes.push(minimalNote(note));
          } else {
            nonPinnedNotes.push(minimalNote(note));
          }
        }
        if (note.tags.some(tag => tag.tag_type_id > 10)) {
          taskNotes.push(note);
        }
      });
      retArr.push(
        createTherapySpecificNotes(
          therapyId,
          therapyTagId,
          pinnedNotes,
          nonPinnedNotes,
          taskNotes,
          tasksVar,
        ),
      );
    });
  return retArr;
}

export function populatePatientNotes(state, notesvar, tasks, newTasks = false) {
  if ((notesvar && notesvar.length && state.notes && state.notes.allNotes) || newTasks) {
    const notesVarIds = notesvar.map(obj => obj.id);
    const stateIds = state.notes.allNotes.map(obj => obj.id);
    const idsOfNewlyLoadedNotes = stateIds.length
      ? notesVarIds.filter(x => !stateIds.includes(x))
      : notesVarIds;
    const arrOfNewNotes = notesvar.filter(
      note => idsOfNewlyLoadedNotes.some(val => val === note.id) && note.is_archived === 0, // eslint-disable-line
    );
    if (arrOfNewNotes.length > 0 || state.notes.allNotes.length === 0 || newTasks) {
      const combinedNotesArr = state.notes.allNotes;
      Array.prototype.push.apply(combinedNotesArr, arrOfNewNotes);
      const finalTaskArr = combinedNotesArr;
      const isPatientTaskArr = finalTaskArr.filter(
        note => note && !note.therapy_id && note.tags.some(tag => tag.tag_type_id > 10),
      );
      const retObj = state;
      retObj.notes.allNotes = finalTaskArr;
      retObj.notes.allPinnedNotes = getPinnedNotes(finalTaskArr);
      retObj.notes.allNonPinnedNotes = getNonPinnedNotes(finalTaskArr);
      retObj.tasks = createTasksStructure(noteTagTypes, isPatientTaskArr, tasks, null);
      const therapyTasks = tasks ? tasks.filter(task => task && !!task.therapy_id) : [];
      retObj.therapies = createTherapyLevelNotes(
        retObj.notes.allNotes.filter(
          note =>
            note &&
            (note.therapy_id ||
              note.tags.some(tag => tag.is_all_of_type === 1) ||
              note.tags.some(tag => tag.tag_type_id > 10)),
        ),
        therapyTasks,
      ).sort((a, b) => b.therapyId - a.therapyId);
      const allPinnedFinal = retObj.notes.allPinnedNotes;
      const allNonPinnedFinal = retObj.notes.allNonPinnedNotes;

      retObj.notes.pinnedNotes = retObj.notes.allPinnedNotes.filter(
        pinnedNote => pinnedNote.tags.some(pinnedTag => pinnedTag.tag_type_id === 1), // eslint-disable-line
      );
      retObj.notes.nonPinnedNotes = retObj.notes.allNonPinnedNotes.filter(
        nonPinnedNote => nonPinnedNote.tags.some(nonPinnedTag => nonPinnedTag.tag_type_id === 1), // eslint-disable-line
      );

      retObj.notes.notesToDisplay = removeDuplicates(allPinnedFinal.concat(allNonPinnedFinal));
      return retObj;
    }
  }
  return state;
}

export function getNoteLevelInfo(note) {
  if (note) {
    const notePathObj = {
      patient: { isPatient: null, patientId: null },
      therapy: { isTherapy: null, therapyIds: [] },
      task: { isTask: null, taskIds: [] },
    };
    const sortedNoteTags =
      note.tags.length > 1
        ? note.tags.sort((a, b) => {
            const nameA = a.tag_type_id;
            const nameB = b.tag_type_id;
            if (nameA > nameB) {
              return 1;
            }
            if (nameA < nameB) {
              return -1;
            }
            return 0;
          })
        : note.tags;
    sortedNoteTags.forEach(tag => {
      if (tag.tag_type_id === 1) {
        notePathObj.patient.isPatient = true;
        notePathObj.patient.patientId = tag.resource_id;
      }
      if (tag.tag_type_id === 2) {
        notePathObj.therapy.isTherapy = true;
        notePathObj.therapy.therapyIds.push({
          tagResourceId: tag.resource_id,
          noteId: note.id,
        });
      }
      if (tag.tag_type_id > 10) {
        notePathObj.task.isTask = true;
        notePathObj.task.taskIds.push({
          tagResourceId: tag.resource_id,
          noteId: note.id,
          noteTherapyId: note.therapy_id,
          tagTypeId: tag.tag_type_id,
        });
      }
    });
    return notePathObj;
  }
  return [];
}

export function makeUpdateTree(note, notePath, state, tasks = []) {
  const newTreeState = cloneDeep(state);
  const oldNoteTags = newTreeState.notes.allNotes.find(noteVar => noteVar.id === note.id)
    ? newTreeState.notes.allNotes.find(noteVar => noteVar.id === note.id).tags
    : [];
  // Find all patient level notes
  if (notePath.patient.isPatient) {
    newTreeState.notes.pinnedNotes = newTreeState.notes.allPinnedNotes.filter(
      noteVar =>
        noteVar.tags.some(tag => tag.tag_type_id === 1) &&
        noteVar.is_archived === 0 &&
        noteVar.id !== note.id,
    );
    newTreeState.notes.nonPinnedNotes = newTreeState.notes.allNonPinnedNotes.filter(
      noteVar =>
        noteVar.tags.some(tag => tag.tag_type_id === 1) &&
        noteVar.is_archived === 0 &&
        noteVar.id !== note.id,
    );
    if (!note.is_archived) {
      if (note.tags.some(tag => tag.is_pinned === 1)) {
        newTreeState.notes.pinnedNotes = insertIntoArray(newTreeState.notes.pinnedNotes, note);
      }
      if (note.tags.some(tag => tag.tag_type_id === 1 && tag.is_pinned === 0)) {
        newTreeState.notes.nonPinnedNotes = insertIntoArray(
          newTreeState.notes.nonPinnedNotes,
          note,
        );
      }
    }
  }
  // Find all therapy level notes
  if (
    notePath.therapy.isTherapy ||
    (oldNoteTags && oldNoteTags.some(tag => tag.is_owner === 0 && tag.tag_type_id === 2))
  ) {
    const therapyIds = notePath.therapy.isTherapy
      ? notePath.therapy.therapyIds
      : oldNoteTags
          .filter(tag => tag.tag_type_id === 2)
          .map(tag => ({
            tagResourceId: tag.resource_id,
            noteId: note.id,
          }));
    therapyIds.forEach(therapyNote => {
      const therapyInTreeId = newTreeState.therapies
        .map(therapyObj => therapyObj.therapyId)
        .indexOf(therapyNote.tagResourceId);
      const therapyInTree = newTreeState.therapies[therapyInTreeId];
      if (!therapyInTree || !therapyInTree.notes) {
        return;
      }

      therapyInTree.notes.pinnedNotes = therapyInTree.notes.pinnedNotes.filter(
        existingNote => existingNote.id !== note.id,
      );

      therapyInTree.notes.nonPinnedNotes = therapyInTree.notes.nonPinnedNotes.filter(
        existingNote => existingNote.id !== note.id,
      );

      if (!note.is_archived) {
        if (note.tags.some(tag => tag.is_pinned && tag.tag_type_id === 2)) {
          therapyInTree.notes.pinnedNotes = insertIntoArray(therapyInTree.notes.pinnedNotes, note);
        }
        if (note.tags.some(tag => tag.tag_type_id === 2 && tag.is_pinned === 0)) {
          therapyInTree.notes.nonPinnedNotes = insertIntoArray(
            therapyInTree.notes.nonPinnedNotes,
            note,
          );
        }
      }

      newTreeState.therapies[therapyInTreeId] = therapyInTree;
    });
  }
  // Find all therapy task level task notes
  if (notePath.task.isTask) {
    if (note && (note.therapy_id || taskIsTherapyAndPatient(note))) {
      const treeTraversalTherapies =
        tasks.length > 0
          ? tasks.map(taskVar => ({
              tagResourceId: taskVar.id,
              noteTherapyId: taskVar.therapy_id,
              tagTypeId: noteTagTypes.find(tagType => tagType.label === taskVar.taskType).value,
            }))
          : notePath.task.taskIds;
      treeTraversalTherapies.forEach(noteTask => {
        if (noteTask.noteTherapyId) {
          const therapyInTreeId = newTreeState.therapies
            .map(therapyObj => therapyObj.therapyId)
            .indexOf(noteTask.noteTherapyId);

          const therapyInTree = newTreeState.therapies[therapyInTreeId];
          if (!therapyInTree || !therapyInTree.tasks) {
            return;
          }

          const taskType = noteTagTypes.find(tagType => tagType.value === noteTask.tagTypeId).label;

          const taskTypeInTreeId = therapyInTree.tasks[taskType];

          // Archive note
          if (note.tags.some(tag => tag.is_archived === 1)) {
            const retTaskNotes = taskTypeInTreeId.map(task => ({
              ...task,
              notes: {
                pinnedNotes: task.notes.pinnedNotes.filter(noteVar => noteVar.id !== note.id),
                nonPinnedNotes: task.notes.nonPinnedNotes.filter(noteVar => noteVar.id !== note.id),
              },
            }));
            newTreeState.therapies[therapyInTreeId].tasks[taskType] = retTaskNotes;
          }
          // Unpin note
          if (
            oldNoteTags.some(tag => tag.is_all_of_type === 1) &&
            note.tags.some(
              tag => tag.is_all_of_type === 0 && tag.tag_type_id === noteTask.tagTypeId,
            )
          ) {
            const retTaskNotes = taskTypeInTreeId.map(task => ({
              ...task,
              notes: {
                ...task.notes,
                pinnedNotes: task.notes.pinnedNotes.filter(noteVar => noteVar.id !== note.id),
                nonPinnedNotes: note.tags.some(
                  tag => tag.resource_id === task.taskId && tag.tag_type_id,
                )
                  ? insertIntoArray(task.notes.nonPinnedNotes, note)
                  : task.notes.nonPinnedNotes,
              },
            }));
            newTreeState.therapies[therapyInTreeId].tasks[taskType] = retTaskNotes;
          }
          // Add note
          if (
            oldNoteTags.length === 0 &&
            !!note &&
            note.tags.some(
              tag =>
                tag.resource_id === noteTask.tagResourceId &&
                tag.tag_type_id === noteTask.tagTypeId,
            )
          ) {
            const retTaskNotes = taskTypeInTreeId
              .filter(task => task.taskId === noteTask.tagResourceId)
              .map(task => ({
                ...task,
                notes: {
                  ...task.notes,
                  nonPinnedNotes: insertIntoArray(task.notes.nonPinnedNotes, note),
                },
              }))[0];
            const taskToAddToIndex = newTreeState.therapies[therapyInTreeId].tasks[
              taskType
            ].findIndex(task => task.taskId === noteTask.tagResourceId);
            newTreeState.therapies[therapyInTreeId].tasks[taskType][taskToAddToIndex] =
              retTaskNotes;
          }
          // Pin note
          if (
            (note.is_pinned === 1 || note.is_all_of_type === 1) &&
            !note.is_archived &&
            (!oldNoteTags.some(tag => tag.is_all_of_type === 1 && tag.is_owner === 1) ||
              note.tags.some(
                tag =>
                  tag.is_owner === 1 &&
                  tag.resource_id === noteTask.tagResourceId &&
                  tag.tag_type_id === noteTask.tagTypeId,
              )) &&
            note.tags.some(tag => tag.tag_type_id === noteTask.tagTypeId)
          ) {
            const retTaskNotes = taskTypeInTreeId.map(task => ({
              ...task,
              notes: {
                nonPinnedNotes: task.notes.nonPinnedNotes.filter(noteVar => noteVar.id !== note.id),
                pinnedNotes: insertIntoArray(task.notes.pinnedNotes, note),
              },
            }));
            newTreeState.therapies[therapyInTreeId].tasks[taskType] = retTaskNotes;
          }
        }
      });
    }
    if (!note.therapyId) {
      // For patient level tasks
      const treeTraversalTasks =
        tasks.length > 0
          ? tasks
              .filter(taskVar => taskVar && taskVar.therapy_id === null)
              .map(taskVar => ({
                tagResourceId: taskVar.id,
                noteTherapyId: null,
                tagTypeId: noteTagTypes.find(tagType => tagType.label === taskVar.taskType).value,
              }))
          : notePath.task.taskIds;
      treeTraversalTasks.forEach(taskNote => {
        const taskType = noteTagTypes.find(tagType => tagType.value === taskNote.tagTypeId).label;

        const taskTypeInTreeId = newTreeState.tasks[taskType];

        const taskInTree =
          taskTypeInTreeId.find(taskTypeVar => taskTypeVar.taskId === taskNote.tagResourceId) ||
          minimalTaskTree(taskNote.tagResourceId);

        const taskInTreeId = taskTypeInTreeId
          .map(taskObj => taskObj.taskId)
          .indexOf(taskNote.tagResourceId);

        taskInTree.notes.pinnedNotes = taskInTree.notes.pinnedNotes.filter(
          existingNote => existingNote.id !== note.id,
        );
        taskInTree.notes.nonPinnedNotes = taskInTree.notes.nonPinnedNotes.filter(
          existingNote => existingNote.id !== note.id,
        );
        // Archive note
        if (note.tags.some(tag => tag.is_archived === 1)) {
          const retTaskNotes = taskTypeInTreeId.map(task => ({
            ...task,
            notes: {
              pinnedNotes: task.notes.pinnedNotes.filter(noteVar => noteVar.id !== note.id),
              nonPinnedNotes: task.notes.nonPinnedNotes.filter(noteVar => noteVar.id !== note.id),
            },
          }));
          newTreeState.tasks[taskType][taskInTreeId] = retTaskNotes;
        }
        // Unpin note
        if (
          oldNoteTags.some(tag => tag.is_all_of_type === 1) &&
          note.tags.some(tag => tag.is_all_of_type === 0)
        ) {
          let retTaskNotes = taskTypeInTreeId.map(task => ({
            ...task,
            notes: {
              ...task.notes,
              pinnedNotes: task.notes.pinnedNotes.filter(noteVar => noteVar.id !== note.id),
              nonPinnedNotes: note.tags.some(
                tag => tag.resource_id === task.taskId && tag.tag_type_id,
              )
                ? insertIntoArray(task.notes.nonPinnedNotes, note)
                : task.notes.nonPinnedNotes,
            },
          }));
          if (note.tags[0].is_all_of_type !== 1) {
            retTaskNotes = retTaskNotes.filter(task => task.taskId === note.tags[0].resource_id);
          }

          newTreeState.tasks[taskType] = retTaskNotes;
        }
        // add Note
        if (oldNoteTags.length === 0 && !!note) {
          const retTaskNotes = taskTypeInTreeId.map(task => ({
            ...task,
            notes: {
              ...task.notes,
              nonPinnedNotes: insertIntoArray(task.notes.nonPinnedNotes, note),
            },
          }));
          newTreeState.tasks[taskType][taskInTreeId] = retTaskNotes[taskInTreeId];
        }
        // pin note
        if ((note.is_pinned === 1 || note.is_all_of_type === 1) && !note.is_archived) {
          let retTaskNotes = taskTypeInTreeId.map(task => ({
            ...task,
            notes: {
              nonPinnedNotes: task.notes.nonPinnedNotes.filter(noteVar => noteVar.id !== note.id),
              pinnedNotes: insertIntoArray(task.notes.pinnedNotes, note),
            },
          }));
          if (note.tags[0].is_all_of_type !== 1) {
            retTaskNotes = retTaskNotes.filter(task => task.taskId === note.tags[0].resource_id);
          }
          newTreeState.tasks[taskType] = retTaskNotes;
        }
      });
    }
  }
  // Find all Patient level task notes
  return newTreeState;
}
export const getDisplayNotes = (
  allPinnedNotes,
  allNonPinnedNotes,
  tagTypeId,
  tagResourceId,
  users,
  serviceGroups,
  length = 0,
) => {
  // Find all matched pinned notes
  let displayNotes = parseMatchingNotes(
    allPinnedNotes,
    tagTypeId,
    tagResourceId,
    users,
    serviceGroups,
    length,
  );
  // If pinned notes are less than length parameter,
  // we need to fetch the unpinned notes to fill the rest of length
  if (displayNotes.length < length) {
    displayNotes = [
      ...displayNotes,
      ...parseMatchingNotes(
        allNonPinnedNotes,
        tagTypeId,
        tagResourceId,
        users,
        serviceGroups,
        length - displayNotes.length,
      ),
    ];
  }
  return displayNotes.filter(note => !!note);
};

/**
 * @param {number} noteTagId
 * @return {string} noteLabel
 */
export const getNoteTagLabel = noteTagId => {
  const noteTag = noteTagTypes.find(tag => tag.value === noteTagId);
  return noteTag ? noteTag.label : '';
};

/**
 * @param {string} noteTagLabel
 * @return {number} noteTagId
 */
export const getNoteTagId = noteTagLabel => {
  const noteTag = noteTagTypes.find(tag => tag.label === noteTagLabel);
  return noteTag ? noteTag.value : null;
};

export const patientTaskOnlyTagCheck = (beforeAdornment, tag, patient) => {
  if (tag) {
    switch (tag.tag_type_id) {
      case APPOINTMENT_REFERRAL_TASK_ID:
        return `${beforeAdornment}${patient.last_name}, ${patient.first_name} AR`;
      case DRUG_UTILIZATION_REVIEW_TASK_ID:
        return `${beforeAdornment}${patient.last_name}, ${patient.first_name} DUR`;
      default:
        return null;
    }
  }
  return null;
};

import React, { useEffect } from 'react';
import moment from 'moment';
import faJson from 'config/task-types/fa.json';
import {
  staticProviders,
  getCommonProviders,
  getDateTimeForProvider,
  getDateForProvider,
} from './common-provider';
import FaData from '../../../containers/tasks/fa/fa-data';
import store from '../../../store';
import FaFundingDetail from '../../../containers/tasks/fa/fa-funding-detail';

const FaDataWrapper = props => {
  const { providers } = props;
  const { task, formValues } = providers;

  return <FaData task={task} targetStatusId={formValues?.status_id} />;
};

const FaFundingDetailsWrapper = props => {
  const { providers } = props;
  const { task, formValues } = providers;
  return (
    <FaFundingDetail task={task} targetStatusId={formValues?.status_id} formValues={formValues} />
  );
};

export default (task, state) => {
  const financials = Object.values(
    store.getState().financials?.financial_assistances?.data || {},
  ).filter(f => f.task_fa_id === task.id);
  const today = getDateForProvider(moment());
  const todayDt = getDateTimeForProvider(moment());

  const [financialAssistance] = Object.values(financials).filter(fa => fa.task_fa_id === task.id);

  const coverageEligibilities = Array.isArray(
    financialAssistance?.assistanceProgramDetails?.CoverageEligibilities,
  )
    ? financialAssistance?.assistanceProgramDetails?.CoverageEligibilities.join(', ')
    : financialAssistance?.assistanceProgramDetails?.CoverageEligibilities;
  return {
    ...staticProviders,
    ...getCommonProviders(task, state, faJson),
    json: faJson,
    initialValues: {
      submitted_dt: todayDt,
      appealed_date: today,
      approved_date: today,
      denied_date: today,
      not_requested_date: today,
      canceled_date: today,
      time_out_date: today,
      has_financials: financials?.length || undefined,
      zhi_program_id: financialAssistance?.zhi_program_id,
      status_id: task.status_id,
    },
    customElements: [
      {
        id: 'fa-funding-details',
        content: FaFundingDetailsWrapper,
      },
      {
        id: 'fa-data',
        content: FaDataWrapper,
      },
    ],
  };
};

import React from 'react';
import { Dialog, DialogTitle, Typography, DialogContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import JsonForm from 'components/dynamic-form/json-form';

const useStyles = makeStyles(theme => ({
  title: {
    backgroundColor: theme.palette.primary.bluesky,
    marginBottom: 20,
    minWidth: 600,
  },
  dialog: {
    top: '5%',
  },
}));

const ModalForm = ({ open, onSubmit, onCancel, title, instructions, marginTop, ...other }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onCancel} maxWidth="md" className={marginTop ? classes.dialog : ''}>
      {title && (
        <DialogTitle id="simple-dialog-title" className={classes.title}>
          {title}
        </DialogTitle>
      )}
      <DialogContent>
        {typeof instructions === 'string' ? (
          <Typography variant="body">{instructions}</Typography>
        ) : (
          instructions
        )}
        <JsonForm onSubmit={onSubmit} onCancel={onCancel} {...other} />
      </DialogContent>
    </Dialog>
  );
};

export default ModalForm;

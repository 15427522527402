import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import TasksCancelForm from 'components/dynamic-form/therapy-forms/tasks-cancel-form';
import { IPatient } from 'interfaces/redux/IPatient';
import { ITherapy } from 'interfaces/redux/ITherapies';
import { formatPatientName } from 'services/utils/demographic-service';
import { Field } from 'redux-form';
import { renderDropdown } from 'components/form/field/redux-field';
import { required } from 'components/form/validation/validation';
import { ILabelValueNumber } from 'interfaces/ILabelValue';
import { useTypedSelector } from 'hooks/use-typed-selector';

interface PatientDischargeButtonProps {
  verifyValue: { name: string };
  tasksCancel: boolean;
  patient: IPatient;
  activeTherapies: ITherapy[];
  handleDischargePatient(): void;
  setTasksCancel(value: boolean): void;
  dischargeButtonLabel: string;
  onSubmit?(): void;
  isLoading: boolean;
}

export const PatientDischargeButton: React.FC<PatientDischargeButtonProps> = ({
  verifyValue,
  tasksCancel,
  patient,
  activeTherapies,
  handleDischargePatient,
  setTasksCancel,
  dischargeButtonLabel,
  onSubmit,
  isLoading,
}) => {
  const [dischargeReasonList, setDischargeReasonList] = useState<ILabelValueNumber[]>([]);
  const [selectedReason, setSelectedReason] = useState<ILabelValueNumber | null>(null);
  const dischargeReasons = useTypedSelector(state => state.lookups.dischargeReasons) || [];

  useEffect(() => {
    setDischargeReasonList(
      dischargeReasons.reduce((acc, t) => {
        acc.push({
          label: t.discharge_reason,
          value: t.id,
        });
        return acc;
      }, [] as ILabelValueNumber[]),
    );
  }, [dischargeReasons]);

  const handleOnClick = () => {
    if (onSubmit) {
      onSubmit();
    } else {
      setTasksCancel(true);
    }
  };

  const renderDischargeReasons = (): JSX.Element => {
    return (
      <Field
        name="dischargeReasonField"
        label="Discharge Reason: *"
        component={renderDropdown}
        validate={[required]}
        fields={dischargeReasonList}
        onChange={(selectedReasonValue: any) => {
          const tempSelectedReason = dischargeReasonList.filter(
            (rv: ILabelValueNumber) => rv.value === selectedReasonValue,
          )[0];
          if (tempSelectedReason) {
            setSelectedReason(tempSelectedReason);
          }
        }}
      />
    );
  };
  return (
    <>
      {tasksCancel && (
        <TasksCancelForm
          title={`Are you sure you want to discharge from ${verifyValue.name}?`}
          instructions={
            <Typography>
              <Box mb={2}>
                {'You are discharging '}
                <strong>{formatPatientName(patient)}</strong>
                {' from '}
                <strong>{verifyValue.name}</strong>
                <span>.</span>
              </Box>
              <Box>Below, you can also select which tasks you want to cancel.</Box>
            </Typography>
          }
          therapyIds={activeTherapies.map(t => t.id)}
          onClose={() => {
            setTasksCancel(false);
            setSelectedReason(null);
          }}
          reason={selectedReason?.label}
          reasonId={selectedReason?.value}
          onSubmit={handleDischargePatient}
          submitButtonText="Discharge and cancel tasks"
          filterTasks={undefined}
          cancelButtonText={undefined}
          dischargeReason
          dischargeReasonDropdown={renderDischargeReasons()}
        />
      )}
      {isLoading ? (
        <CircularProgress data-qa-id="loading" size={20} />
      ) : (
        <Button
          onClick={() => handleOnClick()}
          variant="outlined"
          sx={{
            backgroundColor: 'white',
            color: 'darkRed',
          }}
        >
          {dischargeButtonLabel}
        </Button>
      )}
    </>
  );
};

PatientDischargeButton.defaultProps = {
  onSubmit: undefined,
};

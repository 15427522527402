import React, { Component, Fragment } from 'react';
import withStyles from '@mui/styles/withStyles';
import { RetryIcon } from 'components/icons/icons';
import { styles } from './field-styles';

class FetchText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
    };
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    const { promise } = this.props;
    if (prevProps.promise !== promise) {
      this.fetch();
    }
  }

  fetch() {
    const { promise, parse, errorMessage, classes } = this.props;
    this.setState(
      {
        value: 'Loading...',
      },
      () => {
        promise()
          .then(response => {
            let value;
            try {
              value = parse(response);
            } catch (e) {
              value = null;
            }
            this.setState({ value });
          })
          .catch(() => {
            this.setState({
              value: (
                <>
                  {errorMessage || 'Error'}
                  <button
                    className={classes.retryIcon}
                    type="button"
                    onClick={e => {
                      this.fetch();
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <RetryIcon size={24} />
                  </button>
                </>
              ),
            });
          });
      },
    );
  }

  render() {
    const { value } = this.state;
    return value || null;
  }
}

export default withStyles(styles, { withTheme: true })(FetchText);

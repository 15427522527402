import moment from 'moment';
import { renderDatePicker as RenderDatePicker } from 'components/form/datepicker/datetime-picker';
import Dropdown from 'components/form/field/dropdown';
import React, { useState, useEffect } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Typography, Grid, Button, Modal, FormHelperText } from '@mui/material';
import { convertToArborDate } from 'models/time/arbor-date';
import { useDispatch, useSelector } from 'react-redux';
import { getUserById } from 'services/utils/users-service';
import {
  generateDur,
  verifyDur,
  triggerInteractions,
} from 'actions/action-drug-utilization-review';
import { getActionedByUserText } from 'utils/user-utils';
import { EditHistory } from 'components/icons/icons';
import { QuaternaryVerificationPanel } from 'components/form/verification/verification-panel';
import compose from 'recompose/compose';
import { VERIFICATION_STATUS_VERIFIED, VERIFICATION_STATUS_REVERIFIED } from 'constants/index';
import { usePatientVaccines } from 'components/vaccinations/hooks/use-patient-vaccines';
import { DurStatus } from 'interfaces/enums/TaskStatuses/DrugUtilizationReviewStatus';
import DrugUtilizationReviewHistory from './drug-utilization-review-history';
// eslint-disable-next-line max-len
import DrugUtilizationReviewDrugInteractionAnalysis from './drug-utilization-review-drug-interaction-analysis';
import DrugUtilizationReviewInteractorLists from './drug-utilization-review-interactor-lists';

import { styles } from './drug-utilization-review-styles';

const DrugUtilizationReviewContainer = props => {
  const {
    classes,
    durTask,
    generateButtonEnabled,
    readOnly = false,
    isSnapshot = false,
    hideTopHeader = false,
    showVaccinations = false,
    showHistoryIfNoDur = false,
    isHistoryView = false,
    hideMedicationChildren = false,
    isCslDur = false,
  } = props;

  const patient = useSelector(state => state.patient);
  const users = useSelector(state => state.lookups.users);

  const allergies = useSelector(state => state.allergies.allergiesList);
  const medicationList = useSelector(state => state.medications.medicationList);
  const medicationGroups = useSelector(state => state.medications.medicationGroups);
  const vaccinationList = usePatientVaccines();
  const problemsList = useSelector(state => state.patient?.problems || []);

  const serviceGroups = useSelector(state => state.lookups.serviceGroups);

  const [isHistoryModalOpen, setHistoryModal] = useState(false);

  const [toggleGenerate, setToggleGenerate] = useState(true);
  const [isInterventionModalOpen, setIsInterventionModalOpen] = useState(false);

  const maspServiceGroupForDropbox = serviceGroupFromState => ({
    key: serviceGroupFromState.id,
    value: serviceGroupFromState.display_name,
  });

  const [generateDurServiceGroup, setGenerateDurServiceGroup] = useState(-1);
  const [showError, setShowError] = useState(false);
  const [generateDurFollowUpDate, setGenerateDurFollowUpDate] = useState(moment());

  const dispatch = useDispatch();

  const toggleHistoryModal = () => {
    setHistoryModal(!isHistoryModalOpen);
  };

  const toggleInterventionModal = () => {
    setIsInterventionModalOpen(!isInterventionModalOpen);
  };

  const handleSelectServiceGroup = event => {
    setShowError(false);
    setGenerateDurServiceGroup(event.target.value);
  };

  const handleOnChangeFollowUpDate = momentDate => {
    setGenerateDurFollowUpDate(momentDate);
  };

  const handleVerify = () => {
    dispatch(verifyDur(durTask.id));
  };

  const handleShowHistory = () => {
    setHistoryModal(true);
  };

  const handleRefreshDur = () => {
    dispatch(triggerInteractions(patient.id));
  };

  const generateNewDur = () => {
    if (generateDurServiceGroup !== -1) {
      setToggleGenerate(!toggleGenerate);
      dispatch(generateDur(patient.id, generateDurServiceGroup, generateDurFollowUpDate));
    } else {
      setShowError(true);
    }
  };

  const generateNewDurWithServiceGroup = () => {
    dispatch(generateDur(patient.id, durTask.service_group_id, generateDurFollowUpDate));
  };

  const [isDurOnCompletedState, setIsDurOnCompletedState] = useState(
    durTask && [DurStatus.Reviewed, DurStatus.Canceled].includes(durTask.status_id),
  );

  useEffect(() => {
    setIsDurOnCompletedState(
      durTask && [DurStatus.Reviewed, DurStatus.Canceled].includes(durTask.status_id),
    );
    // eslint-disable-next-line camelcase
  }, [durTask?.status_id]);

  const [durMedicationList, setDurMedicationList] = useState(
    isSnapshot || !!isDurOnCompletedState ? durTask?.medications : medicationList,
  );
  const [durMedicationGroups, setDurMedicationGroups] = useState(
    isSnapshot || !!isDurOnCompletedState ? durTask?.medications : medicationGroups,
  );

  useEffect(() => {
    setDurMedicationList(
      isSnapshot || !!isDurOnCompletedState ? durTask?.medications : medicationList,
    );
    setDurMedicationGroups(
      isSnapshot || !!isDurOnCompletedState ? durTask?.medications : medicationGroups,
    );
  }, [isSnapshot, isDurOnCompletedState, durTask?.medications, medicationGroups]);

  const activeInteractions = durTask
    ? // eslint-disable-next-line no-confusing-arrow
      durTask.interactions.filter(({ active, intervention_id: interventionId }) =>
        interventionId ? true : !!active,
      )
    : [];
  const activeNewInteractions =
    durTask && durTask.new_interactions
      ? // eslint-disable-next-line no-confusing-arrow
        durTask.new_interactions.filter(({ active, intervention_id: interventionId }) =>
          interventionId ? true : !!active,
        )
      : [];

  const renderDurGeneralInformation = durTaskParam => {
    const {
      followup_dt: followupDt,
      is_match_emr: isMatchEmr,
      service_group_id: serviceGroupId,
      generated_date: generatedDateParam,
      meds_reviewed_date: medsReviewedDate,
    } = durTaskParam;

    const renderFollowUpDate = followupDt ? convertToArborDate(followupDt).getCustomerDate() : null;
    const renderMatchesEmr = isMatchEmr === 1 ? 'Yes' : 'No';
    const renderServiceGroup = serviceGroups
      .filter(serviceGroup => serviceGroup.id === serviceGroupId)
      .reduce((acc, element) => element.display_name, '');
    const renderDdiGeneratedDate = generatedDateParam
      ? convertToArborDate(generatedDateParam).getCustomerDate()
      : null;
    const renderMedsReviewedDate = medsReviewedDate
      ? convertToArborDate(medsReviewedDate).getCustomerDate()
      : null;

    return (
      <Grid container className={classes.generalDurInformation}>
        <Grid item xs={4} lg={3}>
          <Typography className={classes.interventionDataTitle} variant="caption">
            Follow Up Date
          </Typography>
          <Typography variant="body2">{renderFollowUpDate || '-'}</Typography>
          <Typography className={classes.interventionDataTitle} variant="caption">
            Matches EMR?
          </Typography>
          <Typography variant="body2">{renderMatchesEmr || '-'}</Typography>
        </Grid>
        <Grid item xs={4} lg={3}>
          <Typography className={classes.interventionDataTitle} variant="caption">
            Service Group
          </Typography>
          <Typography variant="body2">{renderServiceGroup || '-'}</Typography>
          <Typography className={classes.interventionDataTitle} variant="caption">
            DDI Generated Date
          </Typography>
          <Typography variant="body2">{renderDdiGeneratedDate || '-'}</Typography>
        </Grid>
        <Grid item xs={4} lg={3}>
          <Typography className={classes.interventionDataTitle} variant="caption">
            Meds Reviewed Date
          </Typography>
          <Typography variant="body2">{renderMedsReviewedDate || '-'}</Typography>
        </Grid>
      </Grid>
    );
  };

  const renderServiceGroupAndFollowUpDate = () => (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Dropdown
          name="generateDurServiceGroupTextField"
          label="Service Group *"
          state={generateDurServiceGroup}
          handler={handleSelectServiceGroup}
          fields={
            !!serviceGroups && serviceGroups.length
              ? serviceGroups.map(maspServiceGroupForDropbox)
              : []
          }
          dataQaId="qaId-generateDurServiceGroup"
        />
        {showError && (
          <FormHelperText className={classes.errorMessage}>Service Group Required</FormHelperText>
        )}
      </Grid>
      <Grid item xs={4}>
        <RenderDatePicker
          label="Follow Up Date *"
          input={{
            value: generateDurFollowUpDate,
            onChange: handleOnChangeFollowUpDate,
            onBlur: () => {},
          }}
          name="generateDurFollowUpDatePickerField"
          id="generateDurFollowUpDatePickerField"
          meta={{ touched: false, error: false, warning: false }}
        />
      </Grid>
    </Grid>
  );

  const showDurHistoryButton = showHistoryIfNoDur || !!durTask;
  // eslint-disable-next-line max-len
  const durUpdatedToday =
    durTask?.updated &&
    moment(durTask.updated).isValid() &&
    moment(durTask.updated).isAfter(moment.utc().startOf('day'));

  const isDurGeneratedOrReadyForReview = [DurStatus.Generated, DurStatus.ReadyForReview].includes(
    // eslint-disable-next-line camelcase
    durTask?.status_id,
  );
  const showDurDetails =
    isDurGeneratedOrReadyForReview || isHistoryView || durUpdatedToday || isCslDur;

  const filteredProblems =
    isSnapshot || !!isDurOnCompletedState
      ? problemsList.filter(problem => durTask?.problems?.some(x => x.id === problem.id))
      : problemsList;

  return (
    <>
      <Grid container item xs={12}>
        {!hideTopHeader && (
          <Grid item xs={12} className={classes.durTopHeader}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item xs={3}>
                <Typography variant="h6" component="span">
                  Drug Utilization Review
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Grid container justifyContent="flex-end" alignItems="center">
                  {!isSnapshot && (
                    <>
                      {!!durTask && (
                        <Typography
                          className={`${classes.buttonIconSpacing} ${classes.lastUpdated}`}
                        >
                          {isDurGeneratedOrReadyForReview
                            ? getActionedByUserText({
                                action: 'Generated',
                                user: getUserById(durTask.created_by, users),
                                date: durTask.created,
                              })
                            : getActionedByUserText({
                                action: 'Last updated',
                                user: getUserById(durTask.updated_by, users),
                                date: durTask.updated,
                              })}
                        </Typography>
                      )}
                      {!readOnly && (
                        <>
                          {showDurHistoryButton && (
                            <Button
                              disableElevation
                              onClick={handleShowHistory}
                              data-qa-id="open_dur_history_modal_button"
                            >
                              <EditHistory />
                            </Button>
                          )}
                          {!!durTask && !generateButtonEnabled && (
                            <Button
                              variant="outlined"
                              className={classes.rowButton}
                              disableElevation
                              onClick={handleRefreshDur}
                              data-qa-id="DUR_refresh_button"
                            >
                              <Typography variant="button">Refresh DUR</Typography>
                            </Button>
                          )}
                          <Button
                            variant="outlined"
                            className={classes.rowButton}
                            disableElevation
                            onClick={generateNewDur}
                            data-qa-id="DUR_generate_button"
                            disabled={!generateButtonEnabled || !toggleGenerate}
                          >
                            <Typography variant="button">Generate New DUR</Typography>
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              {!!generateButtonEnabled && (
                <Grid item container xs={12} className={classes.durPreGenerateDurHeader}>
                  {renderServiceGroupAndFollowUpDate()}
                </Grid>
              )}
              {durTask && !!isSnapshot && (
                <Grid item container xs={12}>
                  {renderDurGeneralInformation(durTask)}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        {showDurDetails && (
          <Grid item xs={12} className={classes.durContainerOuter}>
            <Grid item xs={12} className={classes.durContainerInner}>
              <DrugUtilizationReviewInteractorLists
                readOnly={readOnly}
                isDurOnCompletedState={!!isDurOnCompletedState}
                allergies={isSnapshot || isDurOnCompletedState ? durTask.allergies : allergies}
                medicationList={durMedicationList}
                medicationGroups={durMedicationGroups}
                showVaccinations={showVaccinations}
                vaccinations={
                  isSnapshot || !!isDurOnCompletedState ? durTask.vaccines : vaccinationList
                }
                problems={filteredProblems}
                hideMedicationChildren={hideMedicationChildren}
                onChange={handleRefreshDur}
              />
            </Grid>

            <Grid container className={classes.durContainerInner}>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end" alignItems="center">
                  <Button
                    variant="outlined"
                    className={classes.rowButton}
                    disableElevation
                    onClick={generateNewDurWithServiceGroup}
                    data-qa-id="DUR_generate_button_CSL"
                    disabled={
                      isDurGeneratedOrReadyForReview || !generateButtonEnabled || !toggleGenerate
                    }
                  >
                    <Typography variant="button">Generate New DUR</Typography>
                  </Button>
                </Grid>
              </Grid>
              <DrugUtilizationReviewDrugInteractionAnalysis
                durId={durTask ? durTask.id : null}
                className={classes.durDrugInteractionAnalysisContainer}
                interactions={activeInteractions}
                newInteractions={activeNewInteractions}
                isSnapshot={isSnapshot}
                durCreatedDate={durTask ? durTask.created : ''}
                durState={durTask ? durTask.status_id : null}
              />
            </Grid>
            {durTask &&
              durTask.verification_status_id === VERIFICATION_STATUS_VERIFIED &&
              !readOnly && (
                <QuaternaryVerificationPanel
                  type="DUR"
                  verificationId={
                    durTask && durTask.verification_status_id
                      ? durTask.verification_status_id
                      : VERIFICATION_STATUS_REVERIFIED
                  }
                  handleVerify={handleVerify}
                  verifiedBy={durTask ? getUserById(durTask.reviewed_by, users) : null}
                  verifiedDate={durTask ? durTask.reviewed_date : null}
                />
              )}
          </Grid>
        )}
      </Grid>
      <Modal open={isHistoryModalOpen} onClose={toggleHistoryModal}>
        <DrugUtilizationReviewHistory data-qa-id="dur_history_modal" />
      </Modal>
      <Modal
        open={isInterventionModalOpen}
        id="interventionModal"
        onClose={toggleInterventionModal}
      >
        <Grid container justifyContent="flex-end" alignItems="center">
          <Grid item>
            <Typography variant="h6" component="span">
              The DUR will be complete but the interventions attached will remain open and will
              require follow up
            </Typography>
          </Grid>
          <Grid item>
            <Button type="button" data-qa-id="cancelDurWithIntervention">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="button"
              color="primary"
              variant="contained"
              data-qa-id="cancelDurWithIntervention"
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(DrugUtilizationReviewContainer);

import makeStyles from '@mui/styles/makeStyles';

const BOX_SHADOW = '0 2px 2px rgba(217, 224, 226, 0.4)';

export const rawStyles = theme => ({
  // FlagIcon form material-ui
  flaggedIconLightRed: {
    color: theme.palette.primary.lightRed,
  },
  flaggedIconTeal: {
    color: theme.palette.primary.teal,
  },
  flaggedIconLightGrey: {
    color: theme.palette.primary.lightGrey,
  },
  // Skeleton from material lab
  rowSkeleton: {
    width: '100%',
    backgroundColor: theme.palette.primary.grey13,
    float: 'left',
    height: 20,
    marginTop: 12,
    borderRadius: 7,
    backgroundImage: 'linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px)',
    animation: 'shine-lines 0.6s infinite',
  },
  // props: isFlagged
  invoicingContentTr: {
    backgroundColor: `${theme.palette.primary.flaggedRed} !important`,
  },
  invoicingTable: {
    padding: '1rem',
    marginBottom: '1rem',
    fontFamily: "'Gotham SSm A', 'Gotham SSm B', Verdana, Geneva, Tahoma, sans-serif",
  },
  // props: isLoading
  invoicingTableContainer: {
    'overflow': 'scroll',
    'maxHeight': '60vh',
    '& thead th': {
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      zIndex: 4,
    },
  },
  invoicingStickyFooterTh: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: theme.palette.primary.white,
    zIndex: 4,
    border: `1px solid ${theme.palette.primary.grey13}`,
    fontWeight: 'bold',
  },
  invoicingTableMain: {
    'borderSpacing': 0,
    'border': `1px solid ${theme.palette.primary.grey13}`,
    'borderRadius': '5px',
    '& tr': {
      'fontSize': '0.8125rem',
      '& :last-child': {
        '& td': {
          maxWidth: '280px',
          borderBottom: 0,
          whiteSpace: 'nowrap',
        },
      },
    },
    '& tr:nth-child(even)': {
      backgroundColor: '#f2f2f2',
    },
  },
  invoicingTableContainerIsLoading: {
    overflow: 'hidden',
  },
  invoicingTd: {
    'margin': 0,
    'padding': '0.4rem',
    'borderRight': `1px solid ${theme.palette.primary.grey13}`,
    '& :last-child': {
      borderRight: 0,
    },
    'width': '100%',
    'textAlign': 'center',
    'fontSize': '0.8125rem',
    'color': theme.palette.primary.steelGrey,
    '& button': {
      padding: '0px',
    },
    '& .MuiIconButton-root': {
      color: 'rgba(0, 0, 0, 0.4)',
    },
  },
  invoicingHeaderTh: {
    'position': 'relative',
    'margin': 0,
    'padding': '0.4rem',
    'borderRight': `1px solid ${theme.palette.primary.grey13}`,
    '& :last-child': {
      borderRight: 0,
    },
    'width': '100%',
    'borderTop': `1px solid ${theme.palette.primary.grey13}`,
    'borderBottom': `2px solid ${theme.palette.primary.grey13}`,
    'boxShadow': BOX_SHADOW,
    '& -moz-box-shadow': BOX_SHADOW,
    '& -webkit-box-shadow': BOX_SHADOW,
    'fontSize': '0.8125rem',
    'textAlign': 'center',
    'color': theme.palette.primary.steelGrey,
    '& button': {
      padding: '0px',
      marginLeft: '10px',
    },
    'backgroundColor': theme.palette.primary.white,
  },
  // is a TextField from material-ui/core
  invoicingGoToPage: {
    width: 100,
    marginBottom: 1,
    marginRight: 5,
  },
  // IconButton, replace EditButton styledComponents
  invoicingEditButton: {
    'color': 'rgba(0,0,0,0.2)',
    '& .MuiSvgIcon-root': {
      width: '0.7em',
      padding: '0.5px 3px .5px 3px',
    },
    'transition': 'visibility 0s 2s, opacity 2s linear',
  },
  // div, props: isEditable, textAlign
  invoicingCellTextContent: {
    maxWidth: '280px',
    textAlign: 'initial',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis',
  },
  // class for prop isEditable
  invoicingCellTextContentIsEditable: {
    maxWidth: '250px',
  },
  // class for prop textAlign
  invoicingCellTextContentTextAlignEnd: {
    textAlign: 'end',
  },
  invoicingPageControl: {
    'fontSize': '0.8125rem',
    'marginTop': '10px',
    'marginBottom': '10px',
    '& :last-child': {
      '& span': {
        borderBottom: 0,
      },
    },
  },
  // div
  invoicingRightCornerTriangle: {
    borderLeft: '0px solid transparent',
    borderRight: `10px solid ${theme.palette.primary.teal}`,
    borderBottom: '10px solid transparent',
    height: 5,
    width: 0,
    position: 'absolute',
    right: 0,
    zIndex: 2,
    top: '-7px',
  },
  // div, try to replace with a Grid to use justify-content prop
  // props: justify
  invoicingCenteredItems: {
    display: 'flex',
    alignItems: 'center',
    // justify-content: ${({ justify }) => justify || 'space-between'},
    position: 'relative',
    minHeight: '23px',
  },
  // formerly Typography
  invoicingStageLabel: {
    marginLeft: '1rem',
  },
  ellipsisContainer: {
    margin: '0 auto',
    maxWidth: '280px',
    width: 'max-content',
  },
  formControl: {
    minWidth: 120,
  },
  rightSpacing: {
    marginRight: theme.spacing(2),
  },
  listItemIcon: {
    minWidth: 0,
  },
  listItem: {
    padding: 0,
  },
  filterListItem: {
    paddingTop: 0,
    paddingBottom: 0,
    cursor: 'pointer',
  },
  listContainer: {
    overflowY: 'scroll',
    minWidth: 400,
    marginTop: 10,
  },
  listContainer2: {
    overflowY: 'scroll',
    maxHeight: 400,
  },
  checkboxList: {
    width: '100%',
    maxWidth: 360,
    minWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 250,
    border: `1px solid ${theme.palette.primary.grey13}`,
    borderRadius: '4px',
  },
  infoCell: {
    padding: '0.5rem',
  },
  expanderCell: {
    borderRight: `2px solid ${theme.palette.primary.grey13}`,
  },
  subRowDataCell: {
    margin: '0',
    padding: '0.5rem',
    textAlign: 'center',
    fontSize: '12px',
    borderBottom: `2px solid ${theme.palette.primary.grey13}`,
  },
  subRowMonthCell: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    paddingTop: '0.5rem',
    paddingLeft: '0.5rem',
    fontSize: '11px',
  },
  subRowTr: {
    backgroundColor: theme.palette.primary.greySubRow,
  },
  borderBottom: {
    borderBottom: `2px solid ${theme.palette.primary.grey13}`,
  },
  fullWidthTh: {
    width: '100%',
  },
  editIconButton: {
    root: {
      color: 'rgba(0,0,0,0.4)',
      width: '0.8em',
    },
  },
  loaderContainer: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 9999,
  },
});
export default makeStyles(theme => rawStyles(theme));

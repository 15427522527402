import { AxiosResponse } from 'axios';
import { Reducer } from 'redux';
import { FETCH_ACCREDITATIONS_STATUS } from '../constants';

interface IResponseData {
  count: number;
  alert: boolean;
}
export interface IAccreditationState extends IResponseData {
  isLoaded: boolean;
}

const initialState: IAccreditationState = { count: 0, alert: false, isLoaded: false };

interface IAction {
  type: 'FETCH_ACCREDITATIONS_STATUS';
  payload: AxiosResponse<IResponseData>;
}

export const accreditation: Reducer<IAccreditationState, IAction> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case FETCH_ACCREDITATIONS_STATUS:
      return { ...state, count: payload.data.count, alert: payload.data.alert, isLoaded: true };

    default:
      return state;
  }
};

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Loader } from 'components/loading-overlay/loading-overlay';
import { compose } from 'recompose';
import { push } from 'connected-react-router';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
  filterObjectForMultiCompare,
  allChecked,
  combineTherapyTasksAndLinks,
  isAnySpecialtyTherapy,
} from 'services/utils/therapy-service';
import {
  taskStatusCheckboxes,
  therapyEnrollmentStatusCheckboxes,
  therapyAdministrationStatusCheckboxes,
} from 'constants/lists';
import { DUR, THERAPY_IN_PROGRESS_CATEGORIES, THERAPY_STATUS_FILTERS } from 'constants/index';
import {
  updateUserPreferences,
  updateLocalUserPreferencesTherapiesEnrollment,
  updateLocalUserPreferencesTherapiesAdministration,
  updateLocalUserPreferencesTasks,
  resetLocalUserPreferencesTasksTherapies,
} from 'actions/action-user';
import { ToggleDisplayAddTherapy } from 'actions/action-form-displays';
import AddPatientTherapy from 'containers/patient/therapy/add-patient-therapy/add-patient-therapy';
import { sortMedications, linkTherapiesMedications } from 'services/utils/medication-service';
// eslint-disable-next-line max-len
import DrugUtilizationReviewForm from 'containers/tasks/drug-utilization-review/drug-utilization-review-form';
// eslint-disable-next-line max-len
import DrugUtilizationReviewContainerWrapper from 'containers/tasks/drug-utilization-review/drug-utilization-review-container-wrapper';
import { windowFeatureIsEnabled } from 'config/window-features';
import { TherapyTypes } from 'constants/enums';
import TherapyDetail from './therapy-detail';
import TherapyFilter from './therapy-filter';
import MedicationList from '../clinical/medication-list/medication-list';

import { styles } from './therapy-detail.styles';

const getTherapyFilter = (statusFilter, shouldBeSpeciality) => {
  const specialityFilter = therapy => {
    if (shouldBeSpeciality) {
      return therapy.is_specialty;
    }

    return !therapy.is_specialty;
  };

  const statusFilterMap = {
    // eslint-disable-next-line arrow-body-style
    [THERAPY_STATUS_FILTERS.IN_PROGRESS_THERAPIES]: therapy => {
      return (
        specialityFilter(therapy) &&
        THERAPY_IN_PROGRESS_CATEGORIES.includes(therapy.administration_status_category_id)
      );
    },
    // eslint-disable-next-line arrow-body-style
    [THERAPY_STATUS_FILTERS.INACTIVE_THERAPIES]: therapy => {
      return (
        specialityFilter(therapy) &&
        !THERAPY_IN_PROGRESS_CATEGORIES.includes(therapy.administration_status_category_id)
      );
    },
  };
  return statusFilterMap[statusFilter];
};

// eslint-disable-next-line arrow-body-style
const shouldGetAdvancedFilter = statusFilter => {
  return [
    THERAPY_STATUS_FILTERS.IN_PROGRESS_THERAPIES,
    THERAPY_STATUS_FILTERS.INACTIVE_THERAPIES,
  ].includes(statusFilter);
};

class TherapyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      therapyFilter: window.FEATURES.therapy_show_non_specialty
        ? item => item
        : item => item.is_specialty,
      therapyStatusFilter: THERAPY_STATUS_FILTERS.IN_PROGRESS_THERAPIES,
    };
    this.receiveFilterState = this.receiveFilterState.bind(this);
    this.handleCloseAddForm = this.handleCloseAddForm.bind(this);
    this.handlerToggleButton = this.handlerToggleButton.bind(this);
    this.handlerTherapyFilter = this.handlerTherapyFilter.bind(this);
    this.handlerTherapyStatusFilter = this.handlerTherapyStatusFilter.bind(this);
  }

  componentDidMount() {
    this.setState({
      ...this.getTherapyFilters(),
    });
  }

  componentDidUpdate(prevProps) {
    const { userPreferences } = this.props;
    if (prevProps.userPreferences !== userPreferences) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        ...this.getTherapyFilters(),
      });
    }
  }

  handleCloseAddForm() {
    const { onToggleFormDisplay } = this.props;
    onToggleFormDisplay(false);
  }

  handlerToggleButton() {
    const { location, push } = this.props; // eslint-disable-line no-shadow
    const pathName =
      location.pathname.indexOf('therapies') > -1
        ? location.pathname.replace('therapies', 'medications')
        : location.pathname.replace('medications', 'therapies');
    const newUrl = `${pathName}${location.search}`;
    push(newUrl);
    this.setState(prevState => ({
      showMedicationList: !prevState.showMedicationList,
    }));
  }

  handlerTherapyFilter(therapyFilter) {
    this.setState({ therapyFilter });
  }

  handlerTherapyStatusFilter(newtherapyStatusFilter) {
    this.setState({ therapyStatusFilter: newtherapyStatusFilter });
  }

  getTherapyFilters() {
    const { userPreferences } = this.props;
    const allTherapiesEnrollmentsChecked = allChecked(therapyEnrollmentStatusCheckboxes);
    const allTherapiesAdministrationsChecked = allChecked(therapyAdministrationStatusCheckboxes);
    const allTasksChecked = allChecked(taskStatusCheckboxes);
    return {
      therapyEnrollmentListFilter:
        userPreferences.worklist.filters.therapy_enrollment_statuses.length > 0
          ? userPreferences.worklist.filters.therapy_enrollment_statuses.reduce(
              (acc, obj) => (obj.id ? obj.id + acc : 0),
              0,
            )
          : allTherapiesEnrollmentsChecked,
      therapyAdministrationListFilter:
        userPreferences.worklist.filters.therapy_administration_statuses.length > 0
          ? userPreferences.worklist.filters.therapy_administration_statuses.reduce(
              (acc, obj) => (obj.id ? obj.id + acc : 0),
              0,
            )
          : allTherapiesAdministrationsChecked,
      taskListFilter:
        userPreferences.worklist.filters.category_ids.length > 0
          ? userPreferences.worklist.filters.category_ids.reduce(
              (acc, obj) => (obj.id ? obj.id + acc : 0),
              0,
            )
          : allTasksChecked,
    };
  }

  receiveFilterState(e) {
    const {
      updateReduxUserPreferencesTherapiesAdministration,
      updateReduxUserPreferencesTherapiesEnrollment,
      updateReduxUserPreferencesTasks,
      resetReduxUserPreferencesTasksTherapies,
      userPreferences,
    } = this.props;

    const allTherapiesEnrollmentsChecked = allChecked(therapyEnrollmentStatusCheckboxes);
    const allTherapiesAdministrationsChecked = allChecked(therapyAdministrationStatusCheckboxes);
    const allTasksChecked = allChecked(taskStatusCheckboxes);

    if (e.type === 'therapy_enrollment') {
      this.setState({
        therapyEnrollmentListFilter: e.value,
      });
      // eslint-disable-next-line
      const listOfTherapyEnrollmentStatusesToSubmit = therapyEnrollmentStatusCheckboxes.filter(
        t => t.id & e.value, // eslint-disable-line
      );

      updateReduxUserPreferencesTherapiesEnrollment(listOfTherapyEnrollmentStatusesToSubmit);
    }
    if (e.type === 'therapy_administration') {
      this.setState({
        therapyAdministrationListFilter: e.value,
      });

      // eslint-disable-next-line
      const listOfTherapyAdministrationStatusesToSubmit =
        therapyAdministrationStatusCheckboxes.filter(
          t => t.id & e.value, // eslint-disable-line
        );

      updateReduxUserPreferencesTherapiesAdministration(
        listOfTherapyAdministrationStatusesToSubmit,
      );
    }
    if (e.type === 'tasks') {
      this.setState({
        taskListFilter: e.value,
      });
      const listOfTaskStatusesToSubmit = taskStatusCheckboxes.filter(
        t => t.id & e.value, // eslint-disable-line
      );
      updateReduxUserPreferencesTasks(listOfTaskStatusesToSubmit);
    }
    if (e === 'reset') {
      this.setState({
        therapyEnrollmentListFilter: allTherapiesEnrollmentsChecked,
        therapyAdministrationListFilter: allTherapiesAdministrationsChecked,
        taskListFilter: allTasksChecked,
      });
      const resetPreferences = userPreferences;
      resetPreferences.worklist.filters.therapy_administration_statuses =
        therapyAdministrationStatusCheckboxes; // eslint-disable-line
      resetPreferences.worklist.filters.therapy_enrollment_statuses =
        therapyEnrollmentStatusCheckboxes; // eslint-disable-line
      resetPreferences.worklist.filters.category_ids = taskStatusCheckboxes;
      resetReduxUserPreferencesTasksTherapies(
        taskStatusCheckboxes,
        therapyAdministrationStatusCheckboxes.concat(therapyEnrollmentStatusCheckboxes),
        resetPreferences,
      );
    }
  }

  render() {
    const {
      classes,
      therapyList,
      openTherapyId,
      selectedTask,
      selectedTaskType,
      isTaskSidebar,
      displayAddForm,
      selectedPatientId,
      therapiesLoaded,
      tasks,
      links,
      patient,
      medicationList,
      medicationGroups,
      location,
      activeDur,
    } = this.props;

    const {
      therapyEnrollmentListFilter,
      therapyAdministrationListFilter,
      taskListFilter,
      therapyFilter,
      therapyStatusFilter,
    } = this.state;

    const medicationGroupsWithTherapies =
      sortMedications(linkTherapiesMedications(medicationGroups, therapyList)) || [];

    // We don't care about sorting meds
    const medicationsWithTherapies = linkTherapiesMedications(medicationList, therapyList) || [];

    const showMedicationList = location.pathname.indexOf('medications') > -1;

    const therapyListValues = Object.values(therapyList || {});

    const spCount = therapyListValues.filter(x => {
      if (shouldGetAdvancedFilter(therapyStatusFilter)) {
        return getTherapyFilter(therapyStatusFilter, true)(x);
      }

      return x.is_specialty;
    }).length;

    const nonSpCount = therapyListValues.filter(x => {
      if (shouldGetAdvancedFilter(therapyStatusFilter)) {
        return getTherapyFilter(therapyStatusFilter, false)(x);
      }

      return !x.is_specialty;
    }).length;

    const listOfTherapyEnrollmentStatusesToDisplay = therapyEnrollmentStatusCheckboxes.filter(
      // eslint-disable-next-line no-bitwise
      t => t.id & therapyEnrollmentListFilter,
    );

    // eslint-disable-next-line max-len
    const listOfTherapyAdministrationStatusesToDisplay =
      therapyAdministrationStatusCheckboxes.filter(
        // eslint-disable-next-line no-bitwise
        t => t.id & therapyAdministrationListFilter,
      );

    const filteredListOfTherapies = therapyListValues
      .filter(therapyFilter)
      .filter(t => {
        if (therapyStatusFilter === THERAPY_STATUS_FILTERS.IN_PROGRESS_THERAPIES) {
          return THERAPY_IN_PROGRESS_CATEGORIES.includes(t.administration_status_category_id);
        }
        if (therapyStatusFilter === THERAPY_STATUS_FILTERS.INACTIVE_THERAPIES) {
          return !THERAPY_IN_PROGRESS_CATEGORIES.includes(t.administration_status_category_id);
        }
        return t;
      })
      .filter(t =>
        filterObjectForMultiCompare(
          listOfTherapyEnrollmentStatusesToDisplay.concat(
            listOfTherapyAdministrationStatusesToDisplay,
          ),
          t.administration_status,
          t.enrollment_status,
        ),
      );

    const compareTherapyMedication = (a, b) => {
      // May want to sort by admin status later; see showMedicationTrellisIcon() for related logic
      // Sort meds by any qualifying Specialty therapy type
      if (!isAnySpecialtyTherapy(a) && isAnySpecialtyTherapy(b)) {
        return 1;
      }
      if (isAnySpecialtyTherapy(a) && !isAnySpecialtyTherapy(b)) {
        return -1;
      }
      // Sort meds by CDM therapy_type
      const CDM = TherapyTypes.ChronicDiseaseManagement;
      if (a.therapy_type !== CDM && b.therapy_type === CDM) {
        return 1;
      }
      if (a.therapy_type === CDM && b.therapy_type !== CDM) {
        return -1;
      }
      return a.drug_name.localeCompare(b.drug_name);
    };

    filteredListOfTherapies.sort(compareTherapyMedication);

    const listOfTaskStatusesToDisplay = taskStatusCheckboxes.filter(
      t => t.id & taskListFilter, // eslint-disable-line
    );

    const renderTherapy = (therapy, index) => {
      const { addTherapyHandler } = this.props;
      return (
        <TherapyDetail
          key={therapy.id}
          therapy={therapy}
          expanded={String(therapy.id) === String(openTherapyId)}
          selectedTask={selectedTask}
          selectedTaskType={selectedTaskType}
          isTaskSidebar={isTaskSidebar}
          selectedServiceGroup={therapy.service_group_id}
          taskStatusCategoriesToDisplay={listOfTaskStatusesToDisplay}
          addTherapyHandler={addTherapyHandler}
          index={index}
        />
      );
    };

    return (
      <div className={classes.loadContainer}>
        {window.FEATURES.therapy_show_non_specialty && (
          <TherapyFilter
            totalMedicationCount={
              medicationGroupsWithTherapies
                ? medicationGroupsWithTherapies.filter(med => !med.parent_medication_id).length
                : 0
            }
            therapyInProgressCount={
              Object.values(therapyList).filter(x =>
                THERAPY_IN_PROGRESS_CATEGORIES.includes(x.administration_status_category_id),
              ).length
            }
            therapyInactiveCount={
              Object.values(therapyList).filter(
                x => !THERAPY_IN_PROGRESS_CATEGORIES.includes(x.administration_status_category_id),
              ).length
            }
            specialityCount={spCount}
            nonSpecialityCount={nonSpCount}
            therapiesCount={filteredListOfTherapies.length}
            handlerTherapyFilter={this.handlerTherapyFilter}
            showMedicationList={showMedicationList}
            toggleButton={this.handlerToggleButton}
            handlerTherapyStatusFilter={this.handlerTherapyStatusFilter}
            therapyStatusFilter={therapyStatusFilter}
          />
        )}

        {displayAddForm && (
          <Grid item xs={12}>
            <AddPatientTherapy
              patientId={selectedPatientId}
              handleCloseAddForm={this.handleCloseAddForm}
              medSync={(windowFeatureIsEnabled('med_sync') && patient?.sync_time_frame) ?? false} // eslint-disable-line
            />
          </Grid>
        )}
        {!showMedicationList ? (
          <Loader loaded={therapiesLoaded}>
            {filteredListOfTherapies.length > 0
              ? filteredListOfTherapies.map((therapy, i) =>
                  renderTherapy(combineTherapyTasksAndLinks(therapy, tasks, links), i),
                )
              : ` No therapies to Display. Reset your therapy filters on the right or
                Add new Therapy`}
          </Loader>
        ) : (
          <Grid container>
            <Grid
              item
              xs={12}
              className={classes.therapyFilterMedicationList}
              data-section="medication list"
            >
              <MedicationList
                patient={patient}
                showMedicationList
                medicationList={medicationsWithTherapies}
                overwriteGroups={medicationGroupsWithTherapies}
                isLoading={false}
              />
            </Grid>
            <Grid item xs={12} className={classes.therapyFilterDurWrapper} data-section="dur">
              {!activeDur ? (
                <Grid container className={classes.therapyFilterMedicationList}>
                  <DrugUtilizationReviewContainerWrapper />
                </Grid>
              ) : (
                <DrugUtilizationReviewForm patientId={patient.id} />
              )}
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    selectedPatientId,
    tabControl,
    userPreferences,
    therapyStatuses,
    therapies,
    formDisplays,
    tasks,
    links,
    patient,
    medications,
  } = state;
  const { medicationList, medicationGroups } = medications;

  const [activeDur] = tasks.data
    ? Object.values(tasks.data).filter(task => task.taskType === DUR)
    : [];

  return {
    selectedPatientId,
    tabControl,
    userPreferences,
    therapyList: therapies.data,
    tasks: tasks.data,
    links: links.data,
    therapiesLoaded: !therapies.loading,
    displayAddForm: formDisplays.displayAddTherapy,
    therapyStatuses,
    patient,
    medicationList,
    medicationGroups,
    therapies,
    activeDur,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveUserPreferences: userPreferences => {
      dispatch(updateUserPreferences(userPreferences));
    },
    updateReduxUserPreferencesTherapiesAdministration: userPreferences => {
      dispatch(updateLocalUserPreferencesTherapiesAdministration(userPreferences));
    },
    updateReduxUserPreferencesTherapiesEnrollment: userPreferences => {
      dispatch(updateLocalUserPreferencesTherapiesEnrollment(userPreferences));
    },
    updateReduxUserPreferencesTasks: userPreferences => {
      dispatch(updateLocalUserPreferencesTasks(userPreferences));
    },
    resetReduxUserPreferencesTasksTherapies: (taskVals, therapyVals) => {
      dispatch(resetLocalUserPreferencesTasksTherapies(taskVals, therapyVals));
    },
    onToggleFormDisplay: bool => {
      dispatch(new ToggleDisplayAddTherapy(bool));
    },
    push: value => dispatch(push(value)),
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(TherapyList);

import React from 'react';
import { Grid, MenuItem, Box, Select, FormControl, Typography, TextField } from '@mui/material';
import DatetimePicker from 'components/form/datepicker/datetime-picker';
import {
  SELECT_EDITABLE_TYPE,
  AUTOCOMPLETE_EDITABLE_TYPE,
  DATES_EDITABLE_TYPE,
  INPUT_EDITABLE_TYPE,
} from '../constants';

import useStyles from './styles';

export default function EditableCell(props) {
  const { formControl } = useStyles();
  const { values, headerTitle, selectedValue, onSelectedValueChange, editableType, dataType } =
    props;

  return (
    <Grid container direction="column">
      {
        {
          [SELECT_EDITABLE_TYPE]: (
            <>
              <Typography variant="caption">{headerTitle}</Typography>
              <Box pt={1} />
              <FormControl className={formControl}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedValue}
                  onChange={onSelectedValueChange}
                  variant="standard"
                >
                  {values?.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          ),
          [DATES_EDITABLE_TYPE]: (
            <>
              <Typography variant="caption">{headerTitle}</Typography>
              <Box pt={1} />
              <DatetimePicker
                id="editable-date-picker"
                onChange={e => onSelectedValueChange({ target: { value: e } })}
                dateFormat="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                value={selectedValue}
                onBlur={() => {}}
              />
            </>
          ),
          [AUTOCOMPLETE_EDITABLE_TYPE]: (
            <>
              <Typography variant="caption">{headerTitle}</Typography>
              <Box pt={1} />
              <FormControl className={formControl}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedValue}
                  onChange={onSelectedValueChange}
                  variant="standard"
                >
                  {values?.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          ),
          [INPUT_EDITABLE_TYPE]: (
            <>
              <Typography variant="caption">{headerTitle}</Typography>
              <Box pt={1} />
              <FormControl className={formControl}>
                <TextField
                  variant="standard"
                  type={dataType || 'text'}
                  labelId="editable-text-label"
                  id="editable-text"
                  value={selectedValue}
                  onChange={onSelectedValueChange}
                />
              </FormControl>
            </>
          ),
        }[editableType]
      }
    </Grid>
  );
}

import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material';
import compose from 'recompose/compose';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { EDIT_OUTREACH_FORM } from 'constants/index';
import { editOutreach, editOutreachPatient, fetchTaskCounts } from 'actions/action-tasks';
import { convertToArborDate } from 'models/time/arbor-date';
import {
  getInitialServiceGroupForReactSelect,
  followupDateChanged,
  addTaskOldValues,
} from 'services/utils/task-service';
import { workListChanged } from 'actions/action-patient';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { renderDropdown, renderTextField } from 'components/form/field/redux-field';
import { ReactSelectForReduxSingle } from 'components/form/field/react-select';
import { validateDate, required, validateNumber } from 'components/form/validation/validation';
import { styles } from '../task-detail-styles';
import { OUTREACH_DEPRECATED_TYPES } from '../../../constants/index';

class EditOutreach extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeDropdown: -1,
      subtypeDropdown: -1,
      serviceGroupDropdown: -1,
      methodDropdown: -1,
    };
    this.handleTypeDropdown = this.handleTypeDropdown.bind(this);
    this.handleSubtypeDropdown = this.handleSubtypeDropdown.bind(this);
    this.handleMethodDropdown = this.handleMethodDropdown.bind(this);
    this.handleServiceGroupDropdown = this.handleServiceGroupDropdown.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleTypeDropdown(e) {
    this.setState({
      typeDropdown: e,
    });
  }

  handleSubtypeDropdown(e) {
    this.setState({
      subtypeDropdown: e,
    });
  }

  handleMethodDropdown(e) {
    this.setState({
      methodDropdown: e,
    });
  }

  handleServiceGroupDropdown(e) {
    this.setState({
      serviceGroupDropdown: e.target.value,
    });
  }

  // Handles the submit functionality. Right now it sets some fields to null for simplicity's sake
  handleSubmit(values) {
    const {
      editOutreach, // eslint-disable-line
      task,
      cancel,
      workListChanged, //eslint-disable-line
      fetchTaskCounts, //eslint-disable-line
      editOutreachPatient, // eslint-disable-line
      therapy,
    } = this.props;
    const payload = {
      ...values,
      id: task.id,
      followup_dt: convertToArborDate(values.followup_dt).getUtcDatetime(),
      therapy_id: task && task.therapy_id ? task.therapy_id : null,
      type_id: values.type_id || null,
      category: values.category || null,
      patient_id: task.patient_id,
      service_group_id: values.service_group_id.value,
      additional_reason: values.additional_reason,
      is_patient: therapy && therapy.id ? 0 : 1,
      fill_cycle_number: values.fill_cycle_number,
      on_hold_date: convertToArborDate(values.on_hold_date).getUtcDate(),
      completed_date: convertToArborDate(values.completed_date).getUtcDate(),
      canceled_date: convertToArborDate(values.canceled_date).getUtcDate(),
      scheduled_dt: convertToArborDate(values.scheduled_dt).getUtcDate(),
    };
    if (payload.is_patient === 1) {
      return editOutreachPatient(addTaskOldValues(payload, task)).then(() => {
        if (followupDateChanged(task.followup_dt, payload.followup_dt)) {
          workListChanged();
          fetchTaskCounts();
        }
        cancel();
      });
    }
    return editOutreach(addTaskOldValues(payload, task)).then(() => {
      if (followupDateChanged(task.followup_dt, payload.followup_dt)) {
        workListChanged();
        fetchTaskCounts();
      }
      cancel();
    });
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      classes,
      cancel,
      taskStatuses,
      lookups,
      categoryVal,
      fillCycles,
      therapy,
      task,
    } = this.props;

    const { typeDropdown, subtypeDropdown, methodDropdown, serviceGroupDropdown } = this.state;

    return (
      <Grid
        container
        justifyContent="flex-start"
        className={(classes.formRowContainer, classes.formContainer)}
      >
        <Grid item xs={12}>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            className={classes.editFormContainer}
            autoComplete="off"
          >
            <Grid container className={classes.formRowContainer}>
              <Grid item lg={3} lx={2} className={classes.fieldContainer}>
                <Field
                  name="followup_dt"
                  label="Follow up Date *"
                  component={renderDatePicker}
                  validate={[validateDate, required]}
                />
              </Grid>
              <Grid item lg={3} lx={2} className={classes.fieldContainer}>
                <Field
                  name="on_hold_date"
                  label="On Hold Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item lg={3} lx={2} className={classes.fieldContainer}>
                <Field
                  name="completed_date"
                  label="Completed Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item lg={3} lx={2} className={classes.fieldContainer}>
                <Field
                  name="canceled_date"
                  label="Canceled Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item lg={3} lx={2} className={classes.fieldContainer}>
                <Field
                  name="scheduled_dt"
                  label="Scheduled Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="service_group_id"
                  label="Service Group *"
                  validate={[required]}
                  component={ReactSelectForReduxSingle}
                  fields={lookups.serviceGroups.map(int => ({
                    label: int.display_name,
                    value: int.id,
                  }))}
                  state={serviceGroupDropdown}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="category"
                  label="Category"
                  validate={[required]}
                  component={renderDropdown}
                  fields={taskStatuses.outreach_categories.map(int => ({
                    label: int.name,
                    value: int.id,
                  }))}
                  state={subtypeDropdown}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="type_id"
                  label="Type"
                  validate={[required]}
                  component={renderDropdown}
                  fields={taskStatuses.outreach_types
                    .filter(outreachType => outreachType.category_id === categoryVal)
                    .map(int => ({
                      label: int.name,
                      value: int.id,
                      isDisabled: OUTREACH_DEPRECATED_TYPES.includes(int.name.toLowerCase()),
                    }))}
                  state={typeDropdown}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="method"
                  label="Method"
                  component={renderDropdown}
                  fields={taskStatuses.outreach_method.map(int => ({
                    label: int.name,
                    value: int.id,
                  }))}
                  state={methodDropdown}
                />
              </Grid>
              <Grid item lg={3} lx={2} className={classes.fieldContainer}>
                <Field
                  name="additional_reason"
                  label="Reason"
                  multiline
                  rows={3}
                  component={renderTextField}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="outcome"
                  label="Outcome"
                  component={renderTextField}
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="outreach_description"
                  label="Outreach Description"
                  component={renderTextField}
                  multiline
                  rows={3}
                />
              </Grid>
              {!!therapy && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name="fill_cycle_number"
                    component={renderDropdown}
                    fields={
                      fillCycles[therapy.id] &&
                      Object.keys(fillCycles[therapy.id])
                        .map(k => fillCycles[therapy.id][k])
                        .map(cycle => ({
                          label: `#${cycle.fill_cycle} NBD: ${convertToArborDate(
                            cycle.needsby_date,
                            true,
                          ).getUtcDate(true)}`,
                          value: cycle.fill_cycle,
                        }))
                    }
                    label="Fill cycle *"
                    validate={[required, validateNumber]}
                  />
                </Grid>
              )}
            </Grid>

            {/* Eighth Row */}
            <ConfirmationPanel
              cancelButtonName="edit_therapy_cancel_button"
              submitButtonName="edit_therapy_submit_button"
              handleCancel={cancel}
              disableSubmit={pristine || submitting}
            />
          </form>
        </Grid>
      </Grid>
    );
  }
}

function handleFormName(task) {
  if (!task) {
    return null;
  }
  const name = `${EDIT_OUTREACH_FORM}_${task.therapy_id}_${task.id}`;
  return name;
}

function mapStateToProps(state, props) {
  const { taskStatuses, lookups, fillCycles } = state;
  const name = handleFormName(props.task);
  const selector = formValueSelector(name);
  const categoryVal = selector(state, 'category');
  return {
    taskStatuses,
    lookups,
    categoryVal,
    form: name,
    fillCycles,
    initialValues: {
      followup_dt: convertToArborDate(props.task.followup_dt, true).getCustomerDate(true),
      status_id: props.task.status_id,
      service_group_id: props.task.service_group_id
        ? getInitialServiceGroupForReactSelect(props.task.service_group_id, props.serviceGroups)
        : '',
      type_id: props.task.type_id ? props.task.type_id : '',
      category: props.task.category ? props.task.category : '',
      method: props.task.method ? props.task.method : null,
      additional_reason: props.task.additional_reason ? props.task.additional_reason : '',
      outreach_description: props.task.outreach_description ? props.task.outreach_description : '',
      outcome: props.task.outcome ? props.task.outcome : '',
      fill_cycle_number: props.task.fill_cycle_number || null,
      on_hold_date: convertToArborDate(props.task.on_hold_date, true).getUtcDate(true),
      completed_date: convertToArborDate(props.task.completed_date, true).getUtcDate(true),
      canceled_date: convertToArborDate(props.task.canceled_date, true).getUtcDate(true),
      scheduled_dt: convertToArborDate(props.task.scheduled_dt, true).getUtcDate(true),
    },
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    workListChanged,
    fetchTaskCounts,
    editOutreachPatient,
    editOutreach,
  }),
)(reduxForm({})(EditOutreach));

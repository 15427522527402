import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { defaultTheme } from 'lib/themes';
import { format } from 'phone-fns';
import Container from '../../components/container';
import WidthController from '../../components/width-controller';

const FinalPage = ({ store, classes }) => {
  const {
    pharmacyInfo: { pharmacyPhoneNumber, openTime, closeTime },
  } = store;

  return (
    <Container
      title="It's time for your refill"
      footer={<Box height={55} backgroundColor={defaultTheme.palette.primary.teal} />}
    >
      <WidthController className={classes.container}>
        <Stack direction="column" spacing={5} data-qa-id="final-page">
          <Typography
            className={classes.message}
            color={defaultTheme.palette.primary.teal}
            type="title"
            data-qa-id="title"
          >
            {`Thank you, your order has been received. You will receive a confirmation message with
            your ${store.patientAnswers.pickup_or_delivery} date as soon as your order has been processed.`}
          </Typography>
          <Typography className={classes.message2} data-qa-id="sub-title">
            If you have any questions or concerns please call us at{' '}
            <b style={{ textWrap: 'nowrap' }}>{format('NNN-NNN-NNNN', pharmacyPhoneNumber)}</b>.
            <br />
            {openTime && closeTime ? `Our hours are ${openTime} to ${closeTime}.` : null}
          </Typography>
        </Stack>
      </WidthController>
    </Container>
  );
};

export default FinalPage;

import React from 'react';
import { renderDebouncedTextField as RenderDebouncedTextField } from 'components/form/field/redux-field'; // eslint-disable-line max-len

export default ({ label, input, meta, disabled, labelSize, qaId }) => (
  <RenderDebouncedTextField
    label={label}
    input={input}
    meta={meta}
    disabled={disabled}
    labelSize={labelSize}
    qaId={qaId}
    multiline
  />
);

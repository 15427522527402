import JsBarcode from 'jsbarcode';
import { createCanvas } from 'canvas';
import { windowFeatureIsEnabled } from 'config/window-features';

const BARCODE_OPTIONS = {
  height: 37,
  fontSize: 18,
  format: 'CODE128',
  width: 2.5,
};

export const barcodeImageDataFromOrderId = (orderId: number): string => {
  if (orderId == null) {
    throw new Error('OrderId missing.');
  }
  const canvas = createCanvas(0, 0);
  JsBarcode(canvas, orderId.toString(), BARCODE_OPTIONS);
  return canvas.toDataURL();
};

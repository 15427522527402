import React from 'react';
import { Field } from 'redux-form';
import { Tooltip } from '@mui/material';
import { getDcReferenceAsText } from 'services/utils/data-collect';
import { getFormLabel, generateQAID } from '../helpers';

export const Reference = ({ input, references }) => {
  if (!references || !references.length) {
    return '-';
  }
  if (
    Number.isNaN(input.value) ||
    input.value === undefined ||
    input.value === null ||
    input.value === ''
  ) {
    return '-';
  }
  const referencesText = references.map(reference => (
    <div>{`${reference.from} to ${reference.to} = ${reference.value}`}</div>
  ));
  const currentValueReference = getDcReferenceAsText(references, input.value);
  return (
    <Tooltip title={referencesText} placement="top" className={null}>
      <span>{currentValueReference}</span>
    </Tooltip>
  );
};

export default (providers, field) => (
  <Field
    name={field.property}
    label={getFormLabel(providers, field)}
    component={Reference}
    references={providers[field.provider]}
    qa-id={generateQAID(field, providers)}
  />
);

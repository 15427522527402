import murmurhash from 'murmurhash-js';
import axios from 'axios';
import {
  createAddressObjectValueFromAVComponents,
  createAddrValidationStringFromAVComponents,
} from './address-validation-helper-functions';
// import HTTP from '../services/http';

const baseUrl =
  'https://addressvalidation.googleapis.com/v1:validateAddress?key=AIzaSyDu5mjjLyZmpedVVq2dqgXpBXqY4l31Ieg';
export class AddressValidationClient {
  static validateAddress = async (addressObject: any): Promise<any> => {
    const address = addressObject.value;
    let address_lines_value = [
      address.line1,
      address.line2,
      address.city,
      address.state,
      address.zip,
    ];
    if (!address.line2) {
      address_lines_value = [address.line1, address.city, address.state, address.zip];
    }
    const payload = {
      address: {
        regionCode: 'US',
        addressLines: address_lines_value,
      },
      enableUspsCass: true,
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': null,
      },
      withCredentials: false,
    };
    const validation_results = await axios.post(baseUrl, payload, config);
    const google_address_components: any = {};
    validation_results.data.result.address.addressComponents.forEach((component: any) => {
      google_address_components[component.componentType] = component.componentName.text;
    });
    const verdict = validation_results.data.result.verdict;
    if (verdict.addressComplete === false) {
      return { validated: false, validation_results };
    }
    const nothing_was_modified = validation_results.data.result.address.addressComponents.every(
      (component: any) => {
        return !component.inferred && !component.replaced && !component.spellCorrected;
      },
    );
    const line1_correct =
      address.line1.toLowerCase() ===
      `${google_address_components.street_number} ${google_address_components.route}`?.toLowerCase();
    const line2_correct =
      address.line2?.toLowerCase() === google_address_components.subpremise?.toLowerCase();
    const city_correct =
      address.city?.toLowerCase() === google_address_components.locality?.toLowerCase();
    const state_correct =
      address.state?.toLowerCase() ===
      google_address_components.administrative_area_level_1?.toLowerCase();
    const zip_plus_4_correct =
      address.zip ===
      `${google_address_components.postal_code}-${google_address_components.postal_code_suffix}`;
    const all_components_ok_as_is =
      !verdict.hasInferredComponents &&
      !verdict.hasUnconfirmedComponents &&
      nothing_was_modified &&
      line1_correct &&
      line2_correct &&
      city_correct &&
      state_correct &&
      zip_plus_4_correct &&
      validation_results.data.result.uspsData.dpvConfirmation === 'Y';

    if (all_components_ok_as_is) {
      const validated_addr = createAddrValidationStringFromAVComponents(google_address_components);
      const validated_hash = murmurhash.murmur3(validated_addr);
      const addr_with_hash = {
        value: {},
        source: addressObject.source,
        ...(addressObject.contact_id ? { contact_id: addressObject.contact_id } : {}),
      } as any;
      addr_with_hash.value = createAddressObjectValueFromAVComponents(google_address_components);
      addr_with_hash.value.key = addressObject.value.key;
      addr_with_hash.value.use = addressObject.value.use;
      addr_with_hash.value.rank = addressObject.value.rank;

      addr_with_hash.value.hash = validated_hash;
      if (addressObject.label) {
        const split_token = address.use;
        const partA = addressObject.label.split(` - ${split_token} - `)[0];
        addr_with_hash.label = `${partA} - ${split_token} - ${addr_with_hash.value.line1}${
          addr_with_hash.value?.line2 ? `, ${addr_with_hash.value?.line2}` : ''
        }, ${addr_with_hash.value.city}, ${addr_with_hash.value.state}, ${
          addr_with_hash.value.zip
        }`;
      }
      new Audio('/media/scan_complete_1.wav').play();
      return { validated: true, updated_address: addr_with_hash };
    }

    const only_zip_needs_changes = validation_results.data.result.address.addressComponents.every(
      (component: any) => {
        if (
          component.componentType !== 'postal_code_suffix' &&
          component.componentType !== 'postal_code'
        ) {
          return (
            component.confirmationLevel === 'CONFIRMED' &&
            !component.inferred &&
            !component.replaced
          );
        }
        return component.confirmationLevel === 'CONFIRMED';
      },
    );
    const other_components_unchanged =
      line1_correct && line2_correct && city_correct && state_correct;
    const all_components_ok_except_zip_updates =
      only_zip_needs_changes &&
      other_components_unchanged &&
      validation_results.data.result.uspsData.dpvConfirmation === 'Y';
    if (all_components_ok_except_zip_updates) {
      const validated_addr: any =
        createAddrValidationStringFromAVComponents(google_address_components);
      const validated_hash = murmurhash.murmur3(validated_addr);
      const addr_with_hash = {
        value: {},
        source: addressObject.source,
        ...(addressObject.contact_id ? { contact_id: addressObject.contact_id } : {}),
      } as any;
      addr_with_hash.value = createAddressObjectValueFromAVComponents(google_address_components);
      addr_with_hash.value.key = addressObject.value.key;
      addr_with_hash.value.use = addressObject.value.use;
      addr_with_hash.value.rank = addressObject.value.rank;
      addr_with_hash.value.hash = validated_hash;

      if (addressObject.label) {
        const split_token = address.use;
        const partA = addressObject.label.split(` - ${split_token} - `)[0];
        addr_with_hash.label = `${partA} - ${split_token} - ${addr_with_hash.value.line1}${
          addr_with_hash.value?.line2 ? `, ${addr_with_hash.value?.line2}` : ''
        }, ${addr_with_hash.value.city}, ${addr_with_hash.value.state}, ${
          addr_with_hash.value.zip
        }`;
      }
      new Audio('/media/scan_complete_1.wav').play();
      return { validated: true, updated_address: addr_with_hash };
    }

    return { validated: false, validation_results };
  };
}

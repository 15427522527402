import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { useState } from 'react';
import { getStatusIdByStatusName, getStatusByStatusId } from 'services/utils/task-service';
import NoteDisplay from 'components/note/note-display';
import DocumentDisplay from 'components/document/document-display';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import ActionRow from 'components/note/action-row';
import TaskStatusForm from 'components/dynamic-form/task-forms/task-status-form';
import { useFillCycleNumber } from 'hooks/useFillCycleNumber';
import TaskDetailForm from 'components/dynamic-form/task-forms/task-detail-form';
import { TASK_THIRD_PARTY_REFERRAL } from '../../../constants/index';
import { styles } from '../task-detail-styles';
import EditThirdPartyReferral from './edit-third-party-referral';
import TaskLinks from '../task-links';
import { StatusSelector } from '../common/status-selector';

const ThirdPartyReferralDetail = props => {
  const {
    statuses,
    task,
    classes,
    notes,
    documents,
    tagTypeId,
    tagTypeLabel,
    serviceGroups,
    therapy,
    displayEdit,
    handleCancel,
    taskIDPrefix,
    drugName,
    tprHubs,
  } = props;
  const {
    status_id: statusId,
    service_group_id: serviceGroupId,
    fill_cycle_number: fillCycleNumber,
    therapy_id: therapyId,
  } = task;

  const [selectedStatus, setSelectedStatus] = useState(
    task.status ? task.status : getStatusByStatusId(task.status_id, statuses),
  );
  const selectedStatusId = getStatusIdByStatusName(selectedStatus, statuses);

  const [needsByDate] = useFillCycleNumber(therapyId, fillCycleNumber);

  return (
    <div>
      <Grid
        container
        alignItems="center"
        className={displayEdit ? classes.taskDetailContainerEdit : classes.taskDetailContainer}
      >
        <Grid item lg={3}>
          <Grid container alignItems="center">
            {!displayEdit && (
              <StatusSelector
                value={selectedStatus}
                currentStatus={task.status}
                onChange={value => setSelectedStatus(value)}
                statuses={statuses}
                taskIDPrefix={taskIDPrefix}
                task={task}
              />
            )}
          </Grid>
        </Grid>
        {!displayEdit && (
          <Grid item lg={5} xs={6}>
            <TaskDetailForm
              task={{
                ...task,
                needsby_date: needsByDate,
              }}
              taskType="TPR"
            />
          </Grid>
        )}
        {displayEdit && <Grid item xs={5} />}
        <Grid item xs={4}>
          {!displayEdit && (
            <Grid item xs={12}>
              <Grid container className={classes.note}>
                <NoteDisplay {...notes} tagResourceId={task.id} />
              </Grid>
              <DocumentDisplay
                taskIdResourceIds={[{ tagTypeId, resourceId: task.id }]}
                tagTypeId={tagTypeId}
                resourceId={task.id}
                drugName={drugName}
                tagTypeLabel={tagTypeLabel}
                documents={documents}
              />
              <ActionRow
                therapy={therapy}
                task={task}
                tagTypeId={tagTypeId}
                tagResourceId={task.id}
                cancel={handleCancel}
                defaultServiceGroup={serviceGroupId}
                serviceGroups={serviceGroups}
                taskType={TASK_THIRD_PARTY_REFERRAL}
                taskIDPrefix={taskIDPrefix}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <TaskLinks links={task.links} />
      <Grid container>
        {displayEdit && (
          <Grid item xs={12}>
            <EditThirdPartyReferral
              task={task}
              statuses={statuses}
              cancel={handleCancel}
              serviceGroups={serviceGroups}
              tprHubs={tprHubs}
            />
          </Grid>
        )}
        {!displayEdit &&
          selectedStatus !== getStatusByStatusId(statusId, statuses) &&
          statusId &&
          statuses && (
            <Grid
              item
              xs={12}
              className={selectedStatus !== task.status ? classes.statusContainer : null}
            >
              <TaskStatusForm
                task={task}
                forceStatusId={selectedStatusId}
                onCancel={() => setSelectedStatus(getStatusByStatusId(task.status_id, statuses))}
              />
            </Grid>
          )}
      </Grid>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    users: state.lookups.users,
    tprHubs: state.lookups.tprHubs,
  };
}

export default compose(withStyles(styles), connect(mapStateToProps))(ThirdPartyReferralDetail);

import createStyles from '@mui/styles/createStyles';

export const styles = theme =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.font.weight.regular,
    },
    preferenceHeading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.font.weight.thick,
    },
    panel: {
      marginTop: 0,
      marginLeft: 30,
      marginRight: 30,
    },
    summaryContainer: {
      paddingRight: '0',
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    editForm: {
      width: '100%',
    },
    dropdownContainer: {
      paddingRight: 20,
    },
    actionContainer: {
      paddingLeft: 10,
    },
    actionIconContainer: {
      textAlign: 'right',
    },
    actionBtnContainer: {
      textAlign: 'right',
      paddingRight: 10,
      marginTop: 30,
    },
    phonePeriodContainer: {
      display: 'flex',
    },
    verificationBtnContainer: {
      textAlign: 'right',
    },
    verificationBtn: {
      backgroundColor: theme.palette.primary.white,
      color: theme.palette.primary.darkRed,
    },
    enrollBtn: {
      backgroundColor: theme.palette.primary.trellisBlue,
      color: theme.palette.primary.white,
    },
    verificationBtnDisabled: {
      backgroundColor: theme.palette.primary.grey3,
      color: theme.palette.primary.grey2,
    },
    verificationBtnRow: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    verifyContainer: {
      backgroundColor: theme.palette.verification.verifyContainer,
      borderRadius: 5,
    },
    nonVerifyContainer: {
      backgroundColor: theme.palette.verification.nonVerifyContainer,
      borderRadius: 5,
    },
    nonVerifyWording: {
      fontSize: theme.font.largeFontSize,
      lineHeight: 2.2,
      color: theme.palette.verification.nonVerifyWording,
      fontWeight: 'bold',
    },
    verifyWording: {
      fontSize: theme.font.largeFontSize,
      lineHeight: 2.2,
      color: theme.palette.verification.verifyWording,
      fontWeight: 'bold',
    },
    expandIcon: {
      left: 8,
      position: 'absolute',
    },
    typography: {
      margin: theme.spacing(1),
    },
    paper: {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    patientInfoRow: {
      paddingBottom: theme.spacing(1),
    },
    patientInfoContainer: {
      margin: '15px 30px 30px 30px',
      borderRadius: 5,
      paddingBottom: theme.spacing(2),
      boxShadow: `1px 1px 3px 0px rgba(0, 0, 0, 0.2),
      -1px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12)`,
    },
    contactTypeContainer: {
      borderRadius: 5,
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 2,
      paddingBottom: 2,
      color: theme.palette.primary.white,
      marginLeft: 10,
    },
    emergency: {
      backgroundColor: theme.palette.primary.lightRed,
    },
    responsible: {
      backgroundColor: theme.palette.primary.trellisBlue,
    },
    addContactFormContainer: {
      border: '1px solid',
      margin: '15px 30px 30px 30px',
      padding: 15,
      borderColor: theme.palette.primary.trellisBlue,
      borderRadius: 5,
    },
    contactForm: {
      width: '100%',
    },
    addContactButton: {
      marginRight: 30,
    },
    radio: {
      height: 22,
      marginTop: 5,
    },
    preferredRadio: {
      height: 22,
      paddingTop: 0,
      marginTop: 3,
      marginLeft: 6,
    },
    closeButton: {
      marginTop: 15,
    },
    loadContainer: {
      position: 'relative',
      minHeight: 50,
    },
    verifyTypography: {},
    patientDischargeText: {
      fontWeight: 'bold',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalContent: {
      minWidth: 550,
      backgroundColor: theme.palette.primary.white,
      borderRadius: 5,
    },
    modalTitle: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.primary.grey12,
    },
    modalRow: {
      padding: theme.spacing(1),
    },
    editPreferenceFormDivider: {
      marginTop: 20,
      marginBottom: 20,
    },
    clinicItem: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    deceasedPatient: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
    historyModal: {
      position: 'absolute',
      width: theme.spacing(150),
      backgroundColor: theme.palette.primary.background,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
    },
    bold: {
      fontWeight: 'bold',
    },
    backdropWrapper: {
      position: 'relative',
    },
    backdrop: {
      zIndex: 2,
      position: 'absolute',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
    genericContactInfo: {
      paddingBottom: theme.spacing(3),
    },
    paymentMethodOnFileHeader: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.font.weight.thick,
      marginBottom: theme.spacing(3),
    },
    addPaymentMethodButtonText: {
      fontSize: theme.font.smallFontSize,
      // This is to vertically center the text and button icon
      paddingTop: 2,
    },
    paymentMethodOnFileContainer: {
      marginBottom: theme.spacing(3),
    },
    paymentMethodOnFileLabelContainer: {
      height: '100%',
    },
    paymentMethodOnFieldDeleteButton: {
      // Vertically centers the delete button with the field on the left
      marginTop: '20%',
    },
    paymentMethodOnFieldDeleteButtonContainer: {
      height: '100%',
    },
    rightAlign: {
      textAlign: 'right',
    },
    paymentMethodOnFileRow: {
      paddingBottom: theme.spacing(2.5),
    },
    paddedSection: {
      padding: theme.spacing(4),
    },
    modalContentWrapper: {
      position: 'absolute',
      width: theme.spacing(130),
      backgroundColor: theme.palette.primary.background,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      borderRadius: theme.spacing(0.6),
      maxHeight: '92vh',
    },
    contactInfo: {
      zIndex: 1,
      position: 'relative',
    },
    inlineCheckbox: {
      'display': 'flex',
      'flexDirection': 'row-reverse',
      'justifyContent': 'start',
      'alignItems': 'center',
      '& .MuiTypography-root': {
        minHeight: 'auto',
      },
      'zIndex': 3,
      'marginBottom': theme.spacing(2),
    },
    fieldErrorText: {
      fontSize: 11,
      color: theme.palette.error.main,
      textTransform: 'uppercase',
    },
    welcomeKitHistoryIcon: {
      marginLeft: theme.spacing(1.5),
    },
  });

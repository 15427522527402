import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { getDownloadUrl } from 'services/utils/upload-document-service';
import { useDispatch } from 'react-redux';
import { notifyError } from 'actions/action-notifications';
import { IState } from 'interfaces/redux/IState';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { IDeliveryInformation, IOrderLabelResponse } from './types';
import { ShippingStyles } from './shipping.styles';

interface IShippingProps extends IDeliveryInformation, WithStyles<typeof ShippingStyles> {}

const ExternalDownloadImage = (props: IShippingProps): JSX.Element | null => {
  const { classes } = props;
  const dispatch = useDispatch();
  const { currentDeliveryInformation } = props;
  const { currentPrintInformation } = props;
  const customerId = useTypedSelector((state: IState) => state.filters.selectedCustomerId);
  const patientId = useTypedSelector(state => state.selectedPatientId);
  const documentId = currentPrintInformation?.signature_document_id;
  const signatureNotRequired =
    currentDeliveryInformation?.signature == null || !currentDeliveryInformation.signature;

  const [imgSrc, setImgSrc] = useState('');

  React.useEffect(() => {
    async function getExternalDownloadUrl(): Promise<void> {
      if (documentId) {
        try {
          const response = await getDownloadUrl(customerId, patientId, documentId);
          const { url } = response.data;
          setImgSrc(`${url}`);
        } catch (error) {
          dispatch(notifyError('Unable to retrieve image'));
        }
      }
    }
    getExternalDownloadUrl();
  }, []);

  const displaySignatureImg = (): JSX.Element => {
    return (
      <Grid item xs={3}>
        <img src={imgSrc} className={classes.signatureImage} />
      </Grid>
    );
  };

  const displaySignatureNotRequired = (): JSX.Element => {
    return (
      <Grid item xs={3}>
        <Typography>&nbsp;</Typography>
        <Typography variant="body2" className={classes.signatureNotRequired}>
          Signature not required
        </Typography>
      </Grid>
    );
  };

  if (signatureNotRequired) {
    return displaySignatureNotRequired();
  }

  if (documentId) {
    return displaySignatureImg();
  }

  return <></>;
};

export default withStyles(ShippingStyles)(ExternalDownloadImage);

export const styles = theme => ({
  reconciliationModal: {
    position: 'absolute',
    width: theme.spacing(150),
    height: '80%',
    backgroundColor: theme.palette.primary.background,
    boxShadow: theme.shadows[5],
    borderRadius: 5,
    overflow: 'auto',
  },
  subFormContainer: {
    width: '100%',
    overflow: 'auto',
    border: '1px solid',
    margin: '15px 30px 30px 30px',
    padding: 15,
    borderColor: theme.palette.primary.trellisBlue,
    borderRadius: 5,
  },
  actionIconContainer: {
    textAlign: 'right',
  },
  therapyModal: {
    position: 'absolute',
    width: '85%',
    backgroundColor: theme.palette.primary.background,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    maxHeight: 800,
    overflow: 'auto',
  },
});

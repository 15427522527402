import React from 'react';
import { IEmailDemographicInfoProps } from 'components/patient-merge-modal/interfaces';
import { Grid, Typography } from '@mui/material';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { useTheme } from '@mui/styles';

export const EmailDemographicInfo = (props: IEmailDemographicInfoProps) => {
  const { email } = props;
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);
  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <Typography sx={classes.checkboxTitle}>Email</Typography>
        {email.preferred && <Typography sx={classes.infoTag}>Preferred</Typography>}
      </Grid>
      <Grid item xs={6}>
        <Typography sx={classes.checkboxInfoLabel}>Type</Typography>
        <Typography sx={classes.checkboxInfoValue}>{email.use ?? '-'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={classes.checkboxInfoLabel}>Email</Typography>
        <Typography sx={classes.checkboxInfoValue}>{email.value ?? '-'}</Typography>
      </Grid>
    </Grid>
  );
};

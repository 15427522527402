import {
  GA_QUESTIONNAIRE_PATIENT_VIEW,
  GA_QUESTIONNAIRE_PATIENT_ANSWER_QUESTION,
  GA_QUESTIONNAIRE_PATIENT_SUBMIT,
} from 'constants/index';

export const gaQuestionnairePatientView = (questionnaireLink, patientId) => ({
  type: GA_QUESTIONNAIRE_PATIENT_VIEW,
  questionnaireLink,
  patientId,
});

export const gaQuestionnairePatientAnswerQuestion = (
  questionnaireLink,
  patientId,
  questionNumber,
) => ({
  type: GA_QUESTIONNAIRE_PATIENT_ANSWER_QUESTION,
  questionnaireLink,
  patientId,
  questionNumber,
});

export const gaQuestionnairePatientSubmit = (questionnaireLink, patientId) => ({
  type: GA_QUESTIONNAIRE_PATIENT_SUBMIT,
  questionnaireLink,
  patientId,
});

import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { useState, useEffect } from 'react';
import { getStatusByStatusId, getStatusIdByStatusName } from 'services/utils/task-service';
import NoteDisplay from 'components/note/note-display';
import DocumentDisplay from 'components/document/document-display';
import compose from 'recompose/compose';
import { INT, DC } from 'constants/index';
import { useSelector } from 'react-redux';
import { SmallSpacer, MediumSpacer } from 'components/spacer/spacer';
import ActionRow from 'components/note/action-row';
import TaskStatusForm from 'components/dynamic-form/task-forms/task-status-form';
import TaskEditForm from 'components/dynamic-form/task-forms/task-edit-form';
import TaskDetailForm from 'components/dynamic-form/task-forms/task-detail-form';
import DataCollectViewForm from 'components/dynamic-form/task-forms/data-collect-view-form';
import DataCollectOnDemandForm from 'components/dynamic-form/task-forms/data-collect-on-demand-form'; // eslint-disable-line max-len
import { useFillCycleNumber } from 'hooks/useFillCycleNumber';
import { getInterventionsOfDc } from 'services/utils/data-collect';
import { useClinicalDataDCTask } from 'containers/patient/clinical/use-clinical-data';
import {
  TASK_DATA_COLLECT,
  DATA_COLLECT_STATUS_DATA_COLLECTED,
  DATA_COLLECT_STATUS_DATA_REVIEWED,
  DATA_COLLECT_STATUS_CANCELED,
} from '../../../constants/index';
import { styles } from '../task-detail-styles';
import TaskLinks from '../task-links';
import { StatusSelector } from '../common/status-selector';

const DataCollectDetail = props => {
  const {
    statuses,
    task,
    classes,
    notes,
    documents,
    tagTypeId,
    tagTypeLabel,
    serviceGroups,
    therapy,
    displayEdit,
    handleCancel,
    taskIDPrefix,
    drugName,
    patient,
  } = props;
  const {
    status_id: statusId,
    service_group_id: serviceGroupId,
    fill_cycle_number: fillCycleNumber,
    therapy_id: therapyId,
    links,
  } = task;

  const tasks = useSelector(state => state.tasks.data);
  const currentUser = useSelector(state => state.auth?.currentUser);
  const resourceStates = useSelector(state => state.lookups.resourceStates);

  const dcResourceStates = (resourceStates || []).filter(
    resourceState => resourceState.resource_name === DC,
  );

  const linkedInterventionTasks = getInterventionsOfDc(task.id, links, tasks, null, true);
  const taskStatus = getStatusByStatusId(statusId, statuses);
  const [selectedStatus, setSelectedStatus] = useState(task.status ? task.status : taskStatus);
  const [transitioningStatus, setTransitioningStatus] = useState(false);
  const clinicalDataItems = useClinicalDataDCTask(patient.id, task.id);

  useEffect(() => {
    setSelectedStatus(taskStatus);
  }, [taskStatus]);
  const selectedStatusId = getStatusIdByStatusName(selectedStatus, statuses);
  const [needsByDate] = useFillCycleNumber(therapyId, fillCycleNumber);
  const showDcOnDemandForm =
    taskStatus !== DATA_COLLECT_STATUS_DATA_COLLECTED &&
    taskStatus !== DATA_COLLECT_STATUS_DATA_REVIEWED &&
    taskStatus !== DATA_COLLECT_STATUS_CANCELED;

  return (
    <div>
      <Grid
        container
        alignItems="center"
        className={displayEdit ? classes.taskDetailContainerEdit : classes.taskDetailContainer}
      >
        <Grid item lg={3}>
          <Grid container alignItems="center">
            {!displayEdit && (
              <StatusSelector
                value={selectedStatus}
                currentStatus={taskStatus}
                onChange={value => {
                  setSelectedStatus(value);
                  setTransitioningStatus(true);
                }}
                manuallyRestricted={['Intervening']}
                statuses={statuses}
                taskIDPrefix={taskIDPrefix}
                task={task}
                currentUser={currentUser}
                resourceStates={dcResourceStates}
              />
            )}
          </Grid>
        </Grid>
        {!displayEdit && (
          <Grid item lg={5} xs={6}>
            <TaskDetailForm task={{ ...task, needsby_date: needsByDate }} taskType={DC} />
          </Grid>
        )}
        {displayEdit && <Grid item xs={5} />}
        <Grid item xs={4}>
          {!displayEdit && (
            <Grid item xs={12}>
              <Grid container className={classes.note}>
                <NoteDisplay {...notes} tagResourceId={task.id} />
              </Grid>
              <DocumentDisplay
                taskIdResourceIds={[{ tagTypeId, resourceId: task.id }]}
                tagTypeId={tagTypeId}
                resourceId={task.id}
                drugName={drugName}
                tagTypeLabel={tagTypeLabel}
                documents={documents}
              />
              <ActionRow
                therapy={therapy}
                task={task}
                tagTypeId={tagTypeId}
                tagResourceId={task.id}
                cancel={handleCancel}
                defaultServiceGroup={serviceGroupId}
                serviceGroups={serviceGroups}
                taskType={TASK_DATA_COLLECT}
                idPrefix={taskIDPrefix}
              />
            </Grid>
          )}
        </Grid>
        <TaskLinks links={task.links} />
        {displayEdit && (
          <Grid item xs={12}>
            <TaskEditForm therapy task={task} onCancel={handleCancel} />
          </Grid>
        )}
        {!displayEdit && showDcOnDemandForm && (
          <Grid item xs={12}>
            <DataCollectOnDemandForm
              dataCollectId={task.id}
              dc={clinicalDataItems}
              className={classes.onDemandButton}
            />
          </Grid>
        )}
        {!displayEdit &&
          linkedInterventionTasks &&
          linkedInterventionTasks.map(linkedInterventionTask => {
            const intTask = linkedInterventionTask.notLoaded ? null : linkedInterventionTask;
            return (
              <Grid item xs={12}>
                <Typography className={classes.taskDetailSubTitle}>
                  {/* eslint-disable-next-line camelcase */}
                  {`Intervention ${linkedInterventionTask?.data_collect_protocol_trigger || ''}`}
                </Typography>
                <TaskDetailForm task={intTask} id={linkedInterventionTask.id} taskType={INT} />
              </Grid>
            );
          })}
        {!displayEdit && transitioningStatus && statusId && statuses && (
          <Grid
            item
            xs={12}
            className={selectedStatus !== task.status ? classes.statusContainer : null}
          >
            <SmallSpacer />
            <TaskStatusForm
              task={task}
              forceStatusId={selectedStatusId}
              onCancel={() => {
                setSelectedStatus(getStatusByStatusId(task.status_id, statuses));
                setTransitioningStatus(false);
              }}
              onSubmit={updatedTask => {
                if (updatedTask) {
                  const newTaskStatus = getStatusByStatusId(updatedTask.status_id, statuses);
                  setSelectedStatus(newTaskStatus);
                }
                setTransitioningStatus(false);
              }}
            />
          </Grid>
        )}
        {!displayEdit && !transitioningStatus && (
          <DataCollectViewForm task={task} patient={patient} />
        )}
        <Grid item xs={12}>
          <MediumSpacer />
        </Grid>
      </Grid>
    </div>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(DataCollectDetail);

import React from 'react';
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useFillCycleNumber } from 'hooks/useFillCycleNumber';
import { convertToArborDate } from 'models/time/arbor-date';
import { styles } from './therapy-detail.styles';

const TherapyFillCycle = props => {
  const { classes, idPrefix, therapy } = props;
  const fillCycle = useFillCycleNumber(therapy.id, therapy.fill_cycle);
  const daysSupply = fillCycle[2] || '-';
  const needsByDate = fillCycle[0] || '-';
  const formattedNeedsByDate =
    needsByDate !== '-' ? convertToArborDate(needsByDate, true).getUtcDate(true) : '-';

  return (
    <>
      <Grid container>
        <Grid item>
          <Typography className={classes.statusTitle} variant="h6" id={`${idPrefix}_title`}>
            Fill Cycle
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4}>
          <Typography variant="caption">Days Supply</Typography>
          <Typography variant="body2" id={`${idPrefix}_daysSupply`}>
            {daysSupply}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="caption">Fill Cycle</Typography>
          <Typography variant="body2" id={`${idPrefix}_fillCycle`}>
            {`#${therapy.fill_cycle}`}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="caption">Needs By Date</Typography>
          <Typography variant="body2" id={`${idPrefix}_needsByDate`}>
            {formattedNeedsByDate}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(TherapyFillCycle);

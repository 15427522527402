import React from 'react';
import DebouncedNumericField from 'components/form/field/debounced-numeric-field';

export default ({ field, label, input, meta, disabled, labelSize, qaId, inputProps }) => (
  <DebouncedNumericField
    label={label}
    decimal={field.decimal}
    input={input}
    meta={meta}
    disabled={disabled}
    labelSize={labelSize}
    qaId={qaId}
    suffix={field.suffix}
    inputProps={inputProps}
  />
);

import { NumberUtils } from './number-utils';

class StringUtils {
  /**
   * Given a list of strings will concatenate those strings with the given seperator
   * [one, two, three] as params and ',' as seperator will return "one, two, three"
   *
   * Keep in mind this will automatically hide falsey values so passing in the number 0
   * is not advised.
   *
   * @param seperator Seperator between the strings
   * @param params The strings to seperate
   */
  static concatenate = (seperator: string, ...params: (string | undefined)[]): string => {
    const filteredParams = params.filter(str => str);
    const result = filteredParams.join(seperator);
    return result;
  };

  /**
   * Given a list of strings, returns the list seperated including oxford commas
   *
   * Exmaples:
   *  [a, b, c]     ->    "a, b, and c"
   *  [a, b]        ->    "a and b"
   *  [a]           ->    "a"
   *  [a, b, c, d]  ->    "a, b, c, and d"
   *
   * @param params Strings to oxford comma separate
   */
  static oxfordCommaConcatenate = (...params: string[]): string => {
    const oxfordNeeded = params.length > 2;
    const result = params.reduce<string>((acc, curr, index) => {
      if (!curr) {
        return acc;
      }
        const isLast = index === params.length - 1;
        return (
          acc +
          (index !== 0 ? (!oxfordNeeded ? ' and ' : ', ') : '') +
          (isLast && oxfordNeeded ? `and ${ curr}` : curr)
        );
    }, '');

    return result;
  };

  static upperCaseFirstLetter = (str: string | undefined): string | undefined => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : undefined;
  };

  /**
   * Returns an uppercase string
   */
  static toUpper = (value: string): string | undefined => {
    return value && value.toUpperCase();
  };

  /**
   * Takes a starting string and given a list of converters, applies them 1 by 1
   * to the string.
   *
   * Example:
   *  str: 'h-e-l-l-o'
   *  converters:
   *    - "s => s.toUpperCase()"
   *    - "s => s.replace('O', '0')"
   *    - "s => s.replace('H', 'h')"
   *  converter steps:
   *    - H-E-L-L-O
   *    - H-E-L-L-0
   *    - h-E-L-L-0
   *  result:
   *    h-E-L-L-0
   */
  static continuallyConvert = (
    str: string | undefined,
    converters: ((input: string) => string)[] = [],
  ): string | undefined => {
    if (str == null) {
      return undefined;
    }
    if (!converters || !converters.length) {
      return str;
    }
      const finalResult = converters.reduce((prev, curr) => {
        const result = curr(prev);
        return result;
      }, str);

      return finalResult;
  };

  /**
   * Given a string and a required boolean will conditionally add a space and asterisk to the string
   */
  static formatRequired = (label: string, required: boolean): string | undefined => {
    if (label) {
      return `${label}${required ? ' *' : ''}`;
    }
    return undefined;
  };

  static limitMonthValue = (val: string, max: string) => {
    if (val.length === 1 && val[0] > max[0]) {
      val = `0${val}`;
    }

    if (val.length === 2) {
      if (Number(val) === 0) {
        val = '01';
      } else if (val > max) {
        val = max;
      }
    }

    return val;
  };

  /**
   * @param value Something like "012020" represending Jan. 2020
   * @returns a formatted value like "01/2020"
   */
  static cardExpiryFormat = (value: string) => {
    const month = StringUtils.limitMonthValue(value.substring(0, 2), '12');
    const year = value.substring(2, 6);

    return month + (year.length ? `/${year}` : '');
  };

  /**
   * Generates a random number string with N digits
   */
  static randomNumberStringWithNDigits = (digits: number): string | undefined => {
    if (digits <= 0 || digits % 1 !== 0) {
      return undefined;
    }

    let numberString = '';
    while (numberString.length != digits) {
      numberString += NumberUtils.randomInteger(0, 9);
    }
    return numberString;
  };
}

export { StringUtils };

export const styles = theme => ({
  reportContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  reportTitle: {
    color: theme.palette.primary.pageHeader,
    fontSize: theme.font.extraLargeFontSize,
    fontWeight: theme.font.weight.thick,
  },
  card: {
    width: '90%',
    margin: 10,
    cursor: 'pointer',
  },
  cardHeader: {
    paddingBottom: 0,
  },
  subCardHeader: {
    paddingBottom: 0,
    fontSize: theme.font.largeFontSize,
  },
  metricContainer: {
    marginLeft: 20,
    cursor: 'pointer',
    padding: 15,
  },
  number: {
    display: 'inline',
  },
  percentage: {
    marginLeft: 10,
    fontSize: theme.font.extraSmallFontSize,
  },
  containerTabWrapper: {
    position: 'relative',
  },
  noReportSelectedContainerWrapper: {
    opacity: 0.5,
  },
  noReportSelectedTxt: {
    'width': '100%',
    'textAlign': 'center',
    'zIndex': 999,
    'marginTop': '15%',
    'position': 'absolute',
    '& .MuiButton-root': {
      float: 'inherit',
      marginTop: 6,
    },
    '& p': {
      fontSize: 36,
      fontWeight: 'bold',
    },
  },
  downloadButton: {
    textDecoration: 'none',
    color: theme.palette.primary.white,
  },
  reportTable: {
    cursor: 'pointer',
  },
  loadContainer: {
    position: 'relative',
    minHeight: 150,
  },
  reportSection: {
    marginTop: 20,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: 5,
    padding: 20,
  },
  chartCard: {
    width: '100%',
  },
  tabRoot: {
    '&.Mui-tabSelected': {
      color: theme.palette.navigation.secondary,
    },
    '&:focus': {
      color: theme.palette.primary.hover,
    },
  },
  secondaryTabRoot: {
    '&.Mui-tabSelected': {
      color: theme.palette.primary.hover,
    },
    '&:focus': {
      color: theme.palette.primary.hover,
    },
  },
  secondaryTabsIndicator: {
    backgroundColor: theme.palette.primary.hover,
  },
  tabSelected: {},
  tabsHr: {
    marginTop: 0,
    // eslint-disable-next-line comma-dangle
    opacity: 0.3,
    // eslint-disable-next-line comma-dangle
  },
  generalReportTable: {
    marginBottom: '1rem',
    fontFamily: "'Gotham SSm A', 'Gotham SSm B', Verdana, Geneva, Tahoma, sans-serif",
    padding: theme.spacing(0),
  },
  generalReportTableContainer: {
    'overflow': 'scroll',
    'maxHeight': '60vh',
    '& thead th': {
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      zIndex: 1,
      padding: 0,
    },
  },
  generalReportTableMain: {
    'minWidth': '100%',
    'borderSpacing': 0,
    'border': `1px solid ${theme.palette.primary.grey13}`,
    '& tr': {
      'fontSize': '0.8125rem',
      '& td': {
        borderTop: `0.1px solid ${theme.palette.primary.grey13}`,
      },
    },
  },
  generalReportHeaderTr: {
    'minWidth': '100%',
    'display': 'table',
    '& th > input': {
      padding: theme.spacing(0),
      textIndent: theme.spacing(1),
    },
    'paddingTop': theme.spacing(0.8),
  },
  generalReportHeaderTh: {
    fontWeight: 300,
    fontSize: theme.spacing(1.75),
    minWidth: '100%',
    height: theme.spacing(3),
  },
  generalReportMainTbody: {
    '& tr:nth-child(odd)': {
      backgroundColor: theme.palette.primary.grey14,
    },
    '& tr': {
      height: theme.spacing(4.5),
    },
    '& td': {
      'position': 'relative',
      'borderRight': `1px solid ${theme.palette.primary.grey13}`,
      '& :last-child': {
        borderRight: 0,
      },
      '& td.resizer': {
        display: 'inline-block',
        background: theme.palette.primary.steelGrey,
        width: '10px',
        height: '100%',
        top: 0,
        transform: 'translateX(10%)',
        zIndex: 1,
        touchAction: 'none',
      },
    },
  },
  generalReportTbodyTr: {
    textIndent: theme.spacing(1),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  paginationBtnsContainer: {
    backgroundColor: theme.palette.primary.white,
    border: `1px solid ${theme.palette.primary.grey13}`,
    minHeight: theme.spacing(6),
  },
  paginationBtns: {
    'padding': theme.spacing(0.5),
    '& button': {
      width: '100%',
      height: '100%',
      border: 0,
      borderRadius: theme.spacing(0.5),
      fontSize: 14,
      transition: 'all .1s ease',
      cursor: 'pointer',
      outlineWidth: 0,
    },
  },
  paginationControls: {
    'textAlign': 'center',
    '& span': {
      'height': theme.spacing(6),
      'lineHeight': theme.spacing(6),
      'display': 'inline-block',
      '& select': {
        height: theme.spacing(4.5),
        border: `1px solid ${theme.palette.primary.grey13}`,
      },
      '& input': {
        height: theme.spacing(4),
        minWidth: theme.spacing(2.5),
        border: `1px solid ${theme.palette.primary.grey13}`,
        textIndent: theme.spacing(1),
      },
    },
  },
});

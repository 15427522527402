import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import {
  getPaymentMethods,
  deletePaymentMethod as deletePaymentMethodClient,
} from 'containers/add-payment-method/payment-methods-client';
import { PaymentMethodResponse } from 'containers/add-payment-method/types';
import {
  ADDED_PAYMENT_METHOD,
  SELECTED_PATIENT_ID,
  SELECT_PATIENT_SUCCESS,
  UPDATE_PATIENT_PREFERENCE_SUCCESS,
} from '../constants/index';

export const PAYMENT_METHODS_SLICE_NAME = 'paymentMethods';

export interface IPaymentMethodsReduxState {
  loading: boolean;
  error: string | null;
  entities: Record<number, PaymentMethodResponse>;
}

const initialState: IPaymentMethodsReduxState = {
  loading: false,
  error: null,
  entities: {},
};

export interface FetchPatientPaymentMethodsArgs {
  patientId: number;
  paymentMethodIds: number[];
}
export interface DeletePaymentMethodArgs {
  patientId: number;
  paymentMethodId: number;
  orderId?: number;
}

export const fetchPaymentMethodsState = createAsyncThunk(
  `${PAYMENT_METHODS_SLICE_NAME}/fetch`,
  async ({ patientId, paymentMethodIds }: FetchPatientPaymentMethodsArgs) => {
    return getPaymentMethods(patientId, paymentMethodIds);
  },
);
export const deletePaymentMethod = createAsyncThunk(
  `${PAYMENT_METHODS_SLICE_NAME}/delete`,
  async ({ patientId, paymentMethodId, orderId }: DeletePaymentMethodArgs) => {
    return deletePaymentMethodClient(patientId, paymentMethodId, orderId);
  },
);

export const paymentMethodsSlice = createSlice({
  name: PAYMENT_METHODS_SLICE_NAME,
  initialState: initialState,
  reducers: {
    clear() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchPaymentMethodsState.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      const result = action.payload.data?.payment_methods;
      if (result) {
        state.entities = {
          ...state.entities,
          ...keyBy(result, 'id'),
        };
      }
    });
    builder.addCase(fetchPaymentMethodsState.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchPaymentMethodsState.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? 'Could not retrieve payment methods';
    });
    builder.addCase(deletePaymentMethod.fulfilled, (state, action) => {
      if (action.payload.data.deleted_payment_method_id) {
        delete state.entities[action.payload.data.deleted_payment_method_id];
        // also deletes from virtual cards in reducer-tasks.js
      }
    });
    builder.addCase(
      createAction<{ data?: any }>(UPDATE_PATIENT_PREFERENCE_SUCCESS),
      (state, action) => {
        const result = action.payload.data?.updated_patient_preference?.payment_methods;
        if (result) {
          state.entities = {
            ...state.entities,
            ...keyBy(result, 'id'),
          };
        }
      },
    );
    builder.addCase(
      createAction<{ added_payment_method?: any }>(ADDED_PAYMENT_METHOD),
      (state, action) => {
        const result = action.payload.added_payment_method;
        if (result) {
          state.entities = {
            ...state.entities,
            [result.id]: result,
          };
        }
      },
    );
    builder.addCase(createAction<{ patient?: any }>(SELECT_PATIENT_SUCCESS), (state, action) => {
      const result = action.payload.patient?.payment_methods;
      if (result) {
        state.entities = {
          ...state.entities,
          ...keyBy(result, 'id'),
        };
      }
    });
    builder.addCase(createAction(SELECTED_PATIENT_ID), () => initialState);
  },
});

export default paymentMethodsSlice;

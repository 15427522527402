import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { editIncome } from 'actions/action-financials';
import { SmallSpacer } from 'components/spacer/spacer';
import { convertMapToList } from 'reducers/helper-reducer';
import FinancialAssistanceCopayCard from './copay-card-old';
import PrescriptionAssistanceProgram from './prescription-assistance-program-old';
import FoundationList from './foundation-list-old';
import { styles } from '../financials-styles';

class FinancialAssistanceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayDetail: false,
    };

    this.handleExpand = this.handleExpand.bind(this);
  }

  handleExpand() {
    const { displayDetail } = this.state;
    this.setState({
      displayDetail: !displayDetail,
    });
  }

  render() {
    const { classes, financials } = this.props;

    const copayCardStatuses = financials.financial_assistance_category_statuses.filter(
      f => f.category === 'Manufacturer Copay Card',
    );
    const papStatuses = financials.financial_assistance_category_statuses.filter(
      f => f.category === 'PAP',
    );
    const foundationStatuses = financials.financial_assistance_category_statuses.filter(
      f => f.category === 'Foundation',
    );
    const financialAssistances = convertMapToList(financials.financial_assistances.data);

    return (
      <div>
        <Grid item xs={12}>
          {financialAssistances
            .filter(f => copayCardStatuses.some(e => e.id === f.financial_assistance_type))
            .map(t => (
              <FinancialAssistanceCopayCard
                key={t.id}
                classes={classes}
                financialAssistance={t}
                copayCardStatuses={copayCardStatuses}
              />
            ))}
        </Grid>
        <SmallSpacer />
        <Grid item xs={12}>
          {financialAssistances
            .filter(f => papStatuses.some(e => e.id === f.financial_assistance_type))
            .map(t => (
              <PrescriptionAssistanceProgram key={t.id} classes={classes} financialAssistance={t} />
            ))}
        </Grid>
        <SmallSpacer />
        <Grid item xs={12}>
          {financialAssistances
            .filter(f => foundationStatuses.some(e => e.id === f.financial_assistance_type))
            .map(t => (
              <FoundationList key={t.id} classes={classes} financialAssistance={t} />
            ))}
        </Grid>
        <SmallSpacer />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      editIncome,
    },
    dispatch,
  );
}

function mapStateToProps(state) {
  const { financials } = state;
  return {
    financials,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(FinancialAssistanceDetail);

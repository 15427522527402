import React, { useCallback } from 'react';
import Divider from 'components/divider';
import withStyles, { WithStyles } from '@mui/styles/withStyles';
import { getModalStyle } from 'services/utils/styles-service';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import compose from 'recompose/compose';
import { Grid, Modal, Typography } from '@mui/material';
import { taskFCDispensingModalActions } from 'actions/action-task-fc-dispensing-modal';
import { styles } from './task-fc-disensing-modal.styles';

type IProps = WithStyles<typeof styles>;

const TaskFCDispensing: React.FC<IProps> = ({ classes }: IProps) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state: RootStateOrAny) => state.taskFCDispensingModal?.visible);

  const handleSubmit = useCallback(
    () => dispatch(taskFCDispensingModalActions.resetModal()),
    [dispatch],
  );

  return (
    <Modal open={isOpen}>
      <div style={getModalStyle()} className={classes.addFCModal}>
        <h2>Incomplete dispensing configuration</h2>
        <Typography>
          This therapy must have the dispensing option configured as &lsquo;Opt In&lsquo;
        </Typography>

        <Divider />
        <Grid container>
          <Grid item xs={12}>
            <ConfirmationPanel hideCancel handleSubmit={handleSubmit} submitButtonText="OK" />
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default compose<IProps, object>(
  withStyles(styles),
  // connect(mapStateToProps),
  // reduxForm({}),
)(TaskFCDispensing);

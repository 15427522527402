import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../../../react-table-v6-fork/react-table.css';
import compose from 'recompose/compose';
import BundleTaskForm from 'components/dynamic-form/bundle-task-form';
import { editTasks } from 'actions/action-tasks';
import { workListChanged } from 'actions/action-patient';
import { convertToArborDate } from 'models/time/arbor-date';
import { getTodayDateTime } from 'services/utils/date-service';
import { addTasksOldValues } from 'services/utils/task-service';
import { fetchNotes, fetchNewNotes } from 'actions/action-notes';
import { noteTagTypes } from 'constants/lists';
import { parseNoteToDb, getNotesHigestId } from 'services/utils/note-service';
import { verifyAllergies } from 'actions/action-allergies';
import { getDocuments } from 'services/utils/upload-document-service';
import { setDocumentViewer } from 'actions/action-document-viewer';
import { notifyError } from 'actions/action-notifications';
import { addNewDocument } from 'actions/action-uploaded-documents';
import { styles } from 'containers/patient/tasks/patient-tasks-styles';
import { getCurrentDur } from './common';
import { ALLERGIES_VERIFIED, DUR } from '../../../constants';

class DrugUtilizationReviewForm extends Component {
  onSubmit(taskList, orders, type, existingNotes, attachedDocuments) {
    // TODO: remove some of these variables which causes eslint  no-shadow issue
    /* eslint-disable no-shadow */
    const {
      fetchNotes,
      fetchNewNotes,
      workListChanged,
      editTasks,
      existingDocuments,
      addNewDocument,
      selectedPatientId,
      verifyAllergies,
      patient,
      durTask,
    } = this.props;
    const notes = taskList
      .filter(t => t.note)
      .map(t => {
        const parsedNote = parseNoteToDb({ note_text: t.note });
        const typeId = noteTagTypes.find(tag => tag.label === t.taskType).value;
        return {
          patient_id: selectedPatientId,
          note_type_id: 1,
          note_text: parsedNote.note_text,
          is_pinned: 0,
          mentions: parsedNote.mentions,
          tags: [
            {
              tag_type_id: typeId,
              resource_id: t.id,
            },
          ],
        };
      });

    const combinedTask = durTask ? [durTask] : [];

    const withOldTasks =
      combinedTask && combinedTask.length && taskList.length === combinedTask.length
        ? addTasksOldValues(taskList, combinedTask)
        : taskList;

    const newDocuments = attachedDocuments.map(doc => ({
      uuid: doc.uuid,
      file_name: doc.filename,
      labels: doc.labels,
      tags: doc.taskIds.map(id => ({
        tag_type_id: doc.taskTypeId,
        resource_id: id,
      })),
      noteText: doc.note,
    }));
    if (withOldTasks.some(task => task.taskType === 'FC' && !task.newAllergies)) {
      const allergyVerificationPayload = {
        patient_id: patient.id,
        verification_status_id: ALLERGIES_VERIFIED,
        verified_dt: convertToArborDate(getTodayDateTime()).getUtcDatetime(),
      };
      verifyAllergies(allergyVerificationPayload);
    }

    editTasks(selectedPatientId, withOldTasks, orders, notes, newDocuments).then(() => {
      workListChanged();
      if (notes.length) {
        const { pinnedNoteHighestId, nonPinnedNoteHighestId } = getNotesHigestId(existingNotes);
        if (pinnedNoteHighestId === -1 && nonPinnedNoteHighestId === -1) {
          fetchNotes({
            patient_id: selectedPatientId,
            group_by_tag: true,
          });
        } else if (pinnedNoteHighestId > nonPinnedNoteHighestId) {
          fetchNewNotes({
            patient_id: selectedPatientId,
            group_by_tag: true,
            fetchFromId: pinnedNoteHighestId,
          });
        } else {
          fetchNewNotes({
            patient_id: selectedPatientId,
            group_by_tag: true,
            fetchFromId: nonPinnedNoteHighestId,
          });
        }
      }
      getDocuments({
        patient_id: selectedPatientId,
        fetchFromId: Math.max(...existingDocuments.map(d => d.id)),
      }).then(result => {
        addNewDocument(result.data.documents);
      });
    });
  }

  render() {
    const { classes, selectedPatientId, durTask } = this.props;
    const combinedTask = durTask ? [durTask] : [];
    const taskIds = combinedTask.map(it => it.id);

    return (
      <Grid container spacing={2} className={classes.taskContainer}>
        {Object.keys(combinedTask).map(type => {
          return (
            <Grid item key={type} xs={12}>
              <BundleTaskForm
                combinedTask={{ [DUR]: combinedTask }}
                taskType={DUR}
                patientId={selectedPatientId}
                taskIds={taskIds}
                hideCancel
                onSubmit={(taskList, orders, attachedDocuments) => {
                  const { type: propsType, existingNotes: propsExistingNotes } = this.props;
                  this.onSubmit(taskList, orders, propsType, propsExistingNotes, attachedDocuments);
                }}
                drawBorder
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const {
    tasks,
    taskStatuses,
    selectedPatientId,
    therapies,
    lookups,
    notes,
    links,
    // eslint-disable-next-line no-shadow
    uploadedDocuments,
    patient,
    contactList,
    auth,
  } = state;
  const durTask = getCurrentDur(tasks.data);
  return {
    existingNotes: notes,
    existingDocuments: uploadedDocuments.documents,
    tasks: tasks.data,
    tasksLoading: tasks.loading,
    therapies: therapies.data,
    statuses: taskStatuses.statuses,
    selectedPatientId,
    users: lookups.users,
    links: links.data,
    serviceGroups: lookups.serviceGroups,
    allPinnedNotes: notes && notes.notes ? notes.notes.allPinnedNotes : [],
    allNonPinnedNotes: notes && notes.notes ? notes.notes.allNonPinnedNotes : [],
    selectedCustomerId: state.filters.selectedCustomerId,
    documents: uploadedDocuments.documents || [],
    patient,
    contactList,
    user: auth.currentUser,
    durTask,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    editTasks,
    verifyAllergies,
    fetchNotes,
    fetchNewNotes,
    workListChanged,
    addNewDocument,
    setDocumentViewer,
    notifyError,
  }),
)(DrugUtilizationReviewForm);

import {
  FETCH_SCHEDULES_REQUEST,
  FETCH_SCHEDULES_SUCCESS,
  FETCH_SCHEDULES_FAILURE,
  RESET_SCHEDULES,
} from 'constants/index';

const initialState = {
  isLoading: false,
  schedules: [],
  error: false,
};

export default (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case FETCH_SCHEDULES_REQUEST:
      return {
        ...state,
        error: false,
        isLoading: true,
        schedules: meta && meta.paging ? state.schedules : [],
      };
    case FETCH_SCHEDULES_SUCCESS:
      if (payload.data) {
        if (meta && meta.paging) {
          return {
            ...state,
            isLoading: false,
            schedules: [...state.schedules, ...payload.data.schedules],
          };
        }
        return {
          ...state,
          isLoading: false,
          error: false,
          schedules: payload.data.schedules,
        };
      }
      return state;
    case FETCH_SCHEDULES_FAILURE:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    case RESET_SCHEDULES:
      return {
        ...state,
        error: false,
        isLoading: false,
        schedules: [],
      };
    default:
      return state;
  }
};

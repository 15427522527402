import React, { useState, FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Modal,
  Grid,
  Button,
  Typography,
  CircularProgress,
  TextField,
  Box,
  Chip,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { getModalStyle } from 'services/utils/styles-service';
import { ReactSelectControlled } from 'components/form/field/react-select';
import { DayAreaShippingStyles } from './day-area-shipping.styles';
import {
  IDayAreaShipping,
  WeekdayNamesEnum,
  IDayAreaShippingModalForm,
  IDayAreaShippingModalProps,
} from './day-area-shipping.interface';

const DayAreaShippingModal: FC<IDayAreaShippingModalProps> = ({
  close,
  submitRequestWeekday,
  classes,
  open,
  daysShipping,
  handleSnackbar,
  setSnackbarMessage,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm<IDayAreaShippingModalForm>({
    defaultValues: {
      weekday: undefined,
      zipCodes: [],
    },
  });
  const [inputValue, setInputValue] = useState('');
  const [dayShipping, setDayShipping] = useState<IDayAreaShipping | null>(null);
  const [weekdaySelected, setWeekdaySelected] = useState<boolean>(false);
  const zipCodes = watch('zipCodes') || [];
  const isSubmitDisabled = !watch('weekday') || zipCodes.length === 0;

  const handleAddZipCode = () => {
    if (inputValue && !zipCodes.includes(inputValue)) {
      if (!dayShipping?.zipCodes.includes(inputValue) && inputValue.length === 5) {
        setValue('zipCodes', [...zipCodes, inputValue]);
        setInputValue('');
      } else if (inputValue.length !== 5) {
        setSnackbarMessage('Invalid zipcode');
        handleSnackbar();
      } else {
        setSnackbarMessage('Zip Code already added');
        handleSnackbar();
        setInputValue('');
      }
    }
  };

  const handleDeleteZipCode = (zip: string) => {
    setValue(
      'zipCodes',
      zipCodes.filter(code => code !== zip),
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === '-' || event.key === '+') {
      event.preventDefault();
    }
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddZipCode();
    }
  };

  const handleClose = () => {
    reset();
    setWeekdaySelected(false);
    close();
  };

  const weekdayNames = [
    { label: WeekdayNamesEnum.MONDAY, value: 1 },
    { label: WeekdayNamesEnum.TUESDAY, value: 2 },
    { label: WeekdayNamesEnum.WEDNESDAY, value: 3 },
    { label: WeekdayNamesEnum.THURSDAY, value: 4 },
    { label: WeekdayNamesEnum.FRIDAY, value: 5 },
    { label: WeekdayNamesEnum.SATURDAY, value: 6 },
    { label: WeekdayNamesEnum.SUNDAY, value: 0 },
  ];

  const onSubmit = async (formValues: IDayAreaShippingModalForm) => {
    try {
      submitRequestWeekday(formValues as IDayAreaShipping);
      reset();
      setWeekdaySelected(false);
    } catch (error) {
      console.error('Error on adding the weekday:', error);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div style={getModalStyle()} className={classes.modal}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12}>
              <Typography>Add a weekday</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={6}>
              <Controller
                name="weekday"
                control={control}
                render={({ field }) => (
                  <ReactSelectControlled
                    {...field}
                    fields={weekdayNames}
                    label="Weekday *"
                    value={weekdayNames.find(option => option.label === field.value) || null}
                    onChange={(selected: { label: WeekdayNamesEnum; value: number } | null) => {
                      field.onChange(selected?.label || undefined);
                      setDayShipping(
                        daysShipping.find(day => day.weekday === selected?.label) || null,
                      );
                      setValue('zipCodes', []);
                      setWeekdaySelected(true);
                    }}
                  />
                )}
              />
            </Grid>
            {weekdaySelected ? (
              <Grid container item xs={6}>
                <Box>
                  <Typography variant="body1" gutterBottom>
                    ZipCodes *
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 1,
                      alignItems: 'center',
                      borderRadius: '4px',
                      padding: '8px',
                    }}
                  >
                    {zipCodes.map((zip, index) => (
                      <Chip
                        key={index}
                        label={zip}
                        onDelete={() => handleDeleteZipCode(zip)}
                        color="primary"
                        sx={{
                          fontSize: '14px',
                          fontWeight: 'bold',
                        }}
                      />
                    ))}
                    <TextField
                      variant="standard"
                      type="number"
                      placeholder="Add Zip Code"
                      value={inputValue}
                      onChange={({ target }) => {
                        const value = target.value.replace('-', '');
                        if (value.length <= 5) {
                          setInputValue(value);
                        }
                      }}
                      className={classes.zipcodeInput}
                      helperText="Press Enter to add"
                      onKeyDown={handleKeyDown}
                    />
                  </Box>
                </Box>
              </Grid>
            ) : null}
          </Grid>
          <Grid container justifyContent="flex-end" className={classes.modalContainerButton}>
            <Button onClick={handleClose} style={{ marginRight: '5px' }}>
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting || isSubmitDisabled}
            >
              {isSubmitting ? <CircularProgress color="secondary" size={20} /> : 'Add Weekday'}
            </Button>
          </Grid>
        </form>
      </div>
    </Modal>
  );
};

export default withStyles(DayAreaShippingStyles)(DayAreaShippingModal);

import React from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import NoteArea from 'components/note/NoteArea';
import Communication from 'components/note/communication';
import Intervention from 'components/note/intervention';
import Incident from 'components/note/incident';
import { noteTagTypes } from 'constants/lists';

export default ({ providers, field, label, input, meta, disabled, labelSize, qaId, formId }) => {
  const therapies = useSelector(state => state.therapies);
  const therapy = therapies?.data[providers?.task?.therapy_id]; // eslint-disable-line
  const tagType = noteTagTypes.find(tag => tag.label === providers?.task?.taskType);
  const tagTypeLabel = tagType?.label;
  const tagTypeId = tagType?.value;
  const tagResourceId = providers?.task?.id;
  const defaultServiceGroup = providers?.task?.service_group_id; // eslint-disable-line
  const serviceGroups = providers?.serviceGroups;
  const taskType = `task_${
    providers?.task && providers?.task?.type
      ? providers?.task?.type.toLowerCase().split(' ').join('_')
      : ''
  }`;

  const buttonSpacing = {
    paddingRight: 5,
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <NoteArea
          label={label}
          input={input}
          meta={meta}
          disabled={disabled}
          labelSize={labelSize}
          id={qaId}
          data={providers.users}
          defaultPlaceholder={field.defaultPlaceholder}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item style={buttonSpacing}>
            <Communication
              id={`${label}_task_contact`}
              task={providers.task}
              tagTypeId={tagTypeId}
              tagResourceId={tagResourceId}
              formId={formId}
            />
          </Grid>
          <Grid item style={buttonSpacing}>
            <Intervention
              therapy={therapy}
              defaultServiceGroup={defaultServiceGroup}
              tagTypeId={tagTypeId}
              tagResourceId={tagResourceId}
              serviceGroups={serviceGroups}
              taskType={taskType}
            />
          </Grid>
          {tagTypeLabel !== 'QRE' && (
            <Grid item style={buttonSpacing}>
              <Incident
                therapy={therapy}
                tagTypeId={tagTypeId}
                tagResourceId={tagResourceId}
                defaultServiceGroup={defaultServiceGroup} // eslint-disable-line
                serviceGroups={serviceGroups}
                taskType={taskType}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

import { TherapyUtil } from 'utils/therapy-util';
import { ITherapy } from 'interfaces/redux/ITherapy';
import { IMedicationListItem } from 'interfaces/redux/IMedications';
import { CausedByTypes } from 'containers/tasks/fill-coordination/types';

const buildCausedByOptions = (
  therapies: ITherapy[],
  groupedMedications: Record<string, IMedicationListItem>,
  showUnknownOption = true,
): Record<string, { type: CausedByTypes; id?: number }> => {
  const causedByOptions: Record<string, { type: CausedByTypes; id?: number; text: string }> = {};

  // add therapies to options
  Object.values(therapies || {}).forEach(therapy => {
    if (therapy) {
      const text = TherapyUtil.getParagraphFormat(therapy);
      if (!causedByOptions[text]) {
        causedByOptions[text] = { type: 'therapy', id: therapy.id, text };
      }
    }
  });

  // add meds to options
  Object.values(groupedMedications || {}).forEach(med => {
    if (med && !med.therapy_id) {
      const text = med.drug_name;
      if (!causedByOptions[text]) {
        causedByOptions[text] = { type: 'med', id: med.id, text };
      }
    }
  });
  // add "unknown" to options
  if (showUnknownOption) {
    causedByOptions.Unknown = { type: 'unknown', text: 'Unknown' };
  }

  return causedByOptions;
};

export { buildCausedByOptions };

import React from 'react';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'recompose';
import { UPLOAD_DOCUMENT_FORM } from '../../constants';
import { styles } from './document-bar-styles';
import FileSelectedForm from './file-forms/file-selected-form';
import SelectFileForm from './file-forms/select-file-form';

const DocumentForm = props => {
  const { formTitle, classes, tags, fileSelected } = props;

  return (
    <>
      {formTitle && <Typography className={classes.title}>{formTitle}</Typography>}
      {tags && (
        // eslint-disable-next-line max-len
        <Typography className={classes.tag}>
          {tags
            .filter(t => t)
            .map(item => `#${item}`)
            .join(' ')}
        </Typography>
      )}
      {fileSelected ? (
        <FileSelectedForm form={UPLOAD_DOCUMENT_FORM} {...props} />
      ) : (
        <SelectFileForm {...props} />
      )}
    </>
  );
};

export default compose(withStyles(styles))(DocumentForm);

import React, { useEffect, useMemo } from 'react';
import { Grid } from '@mui/material';
import { change, Field } from 'redux-form';
import { useDispatch } from 'react-redux';
import FormHeader from '../../../components/form/header/header';
import {
  renderMoneyTextField,
  renderNumberField,
  renderTextField,
} from '../../../components/form/field/redux-field';
import { useTypedSelector } from '../../../hooks/use-typed-selector';
import { required } from '../../../components/form/validation/validation';

export const FaIncomeInformationForm = props => {
  const { idSuffix, idPrefix = 'add_FA_program_', form } = props;
  const dispatch = useDispatch();

  const incomes = useTypedSelector(state => state.financials.incomes?.data);
  const latestIncome = useMemo(
    () =>
      Object.values(incomes).reduce((previousValue, currentValue) => {
        if (previousValue?.year >= currentValue.year) {
          return previousValue;
        }
        return currentValue;
      }, undefined),
    [incomes],
  );

  useEffect(() => {
    if (latestIncome) {
      dispatch(change(form, 'income_year', latestIncome?.income_year));
      dispatch(
        change(
          form,
          'total_income',
          latestIncome ? latestIncome?.taxable_income || 'No Income' : undefined,
        ),
      );
      dispatch(change(form, 'family_size', latestIncome?.family_size));
      dispatch(change(form, 'create_income', false));
    } else {
      dispatch(change(form, 'create_income', true));
    }
  }, [dispatch, form, latestIncome]);

  const wrapId = id => {
    return `${idPrefix}${id}${idSuffix}`;
  };

  return (
    <>
      <Grid item xs={12}>
        <FormHeader header="Income Information" />
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={3}>
          <Field
            name="income_year"
            label="Income Year *"
            validate={[required]}
            component={renderTextField}
            disabled={!!latestIncome}
            id={wrapId('income_year')}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            name="total_income"
            label="Total Income From Wages *"
            disabled={!!latestIncome}
            validate={[required]}
            component={
              !latestIncome || latestIncome?.taxable_income ? renderMoneyTextField : renderTextField
            }
            id={wrapId('total_income')}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            name="family_size"
            label="Family Size *"
            disabled={!!latestIncome}
            validate={[required]}
            defaultValue={latestIncome?.family_size}
            component={renderNumberField}
            id={wrapId('family_size')}
          />
        </Grid>
      </Grid>
    </>
  );
};

export interface DocumentAnswerValue {
  filename: string | null;
  note: string | null;
  isUploaded: boolean;
  uuid: string | null;
}
export type AnswerValue = null | boolean | string | Date | DocumentAnswerValue;
export type AnswerType = 'boolean' | 'text' | 'date' | 'document';
export type AnswerEntry = {
  id?: number;
  question_id?: number;
  type: AnswerType;
  question: string;
  value: AnswerValue;
  options?: { label: string; value: string }[];
};
export type QuestionnaireQuestions<A = { [Key: string]: AnswerEntry }> = Record<
  keyof A,
  AnswerEntry
>;

export interface QuestionnaireProps {
  closeModal: () => void;
  questionnaire: QuestionnaireInstance;
  children?: React.ReactNode;
}

export interface RejectionMessage {
  rejected_by: string;
  rejected_dt: string;
  rejection_message: string;
}

// Data for questionnaire list item response
export interface QuestionnaireInstance {
  approval_dt: string | null;
  approved_by: string | null;
  due_date_dt: string;
  name: string;
  questionnaire_instance_id: number;
  questionnaire_type_identifier: string;
  saved_by: string | null;
  saved_dt: string | null;
  status: QuestionnaireStatus;
}

export interface QuestionnaireComponentProps {
  closeModal: () => void;
  questionnaire: QuestionnaireInstance;
  children?: React.ReactNode;
}

/**
 * Helper/utility functions
 */

// helper function which infers keys and restricts values to ElementType
export const createAnswers = <Q extends QuestionnaireQuestions>(
  answers: Q,
): QuestionnaireQuestions<Q> => answers as QuestionnaireQuestions<Q>;

export enum QuestionnaireStatus {
  not_started = 'not_started',
  in_progress = 'in_progress',
  submitted = 'submitted',
  rejected = 'rejected',
  completed = 'completed',
}

export interface UploadDocumentBody {
  answerKey: string;
  filename: string;
  document: string;
  note?: string;
  question?: string;
}
export interface UploadDocumentResponse {
  answerKey: string;
  id: number;
  value: DocumentAnswerValue;
}
export interface DownloadDocumentResponse {
  url: string;
  filename: string;
}

export type OnUploadDocument = (body: UploadDocumentBody) => Promise<void>;
export type OnRemoveDocument = (uuid: string) => Promise<void>;
export type OnDownloadDocument = (uuid: string) => Promise<void>;

export interface RemoveDocumentResponse {
  customer_id: number;
  doc_filename: string | null;
  doc_note: string | null;
  doc_uuid: string | null;
  id: number;
  is_uploaded: number;
  key_name: string;
  questionnaire_id: number;
}

// eslint-disable-next-line no-shadow
export enum IndifferentOptions {
  Yes = 'Yes',
  No = 'No',
  NA = 'N/A',
}

export type AnswerComponentProps = {
  value: AnswerValue;
  onChange: (value: AnswerValue) => void;
  placeholder?: string;
  rows?: number;
  options?: { label: string; value: string }[];
  multiselect?: boolean;
  readOnly?: boolean;
  label?: string;
  answerKey?: string;
  onUploadDocument?: OnUploadDocument;
  onRemoveDocument?: OnRemoveDocument;
  onDownloadDocument?: OnDownloadDocument;
  children?: React.ReactNode;
  questionnaireType?: string;
};
export type AnswerComponent = React.FC<AnswerComponentProps>;

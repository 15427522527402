import {
  UPDATE_REPORT_MODAL,
  FETCH_REPORT_DATA,
  UPDATE_REPORT_SIDEBAR,
  SELECTED_PATIENT_ID,
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORTS_REQUEST,
  FETCH_REPORTS_FAILURE,
  PRIOR_AUTHORIZATION_TAT_OVERRIDE_DATA,
} from 'constants/index';
import { findsReportCategoriesByIds } from '../services/utils/report-service';

const initialState = {
  isLoading: {
    pa: false,
    therapyAdmin: false,
    therapyEnrollment: false,
    therapyDispensing: false,
    therapyClinical: false,
    ar: false,
    arTherapy: false,
  },
  reports: [],
  reportSidebar: {
    selectedRow: 0,
  },
  modal: {
    open: false,
    data: [],
  },
};

export default (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case SELECTED_PATIENT_ID:
      return initialState;
    case FETCH_REPORTS_REQUEST:
      return {
        ...initialState,
        isLoading: {
          pa: true,
          therapyAdmin: true,
          therapyEnrollment: true,
          therapyDispensing: true,
          therapyClinical: true,
          ar: true,
          arTherapy: true,
        },
      };
    case FETCH_REPORTS_SUCCESS: {
      const { isLoading } = state;
      const { ids } = meta;
      const categories = findsReportCategoriesByIds(ids);
      const newIsLoading = isLoading;
      const newReports = payload.data.reports.filter(report => !!report);
      categories.forEach(category => {
        newIsLoading[category] = false;
      });

      return {
        ...state,
        isLoading: newIsLoading,
        reports: [...state.reports, ...newReports],
      };
    }
    case FETCH_REPORTS_FAILURE: {
      return {
        ...state,
        isLoading: {
          pa: false,
          therapyAdmin: false,
          therapyEnrollment: false,
          therapyDispensing: false,
          therapyClinical: false,
          ar: false,
          arTherapy: false,
        },
      };
    }
    case FETCH_REPORT_DATA:
      if (payload.data) {
        return {
          ...state,
          modal: {
            ...state.modal,
            data: payload.data.report_data,
          },
        };
      }
      return state;
    case UPDATE_REPORT_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          ...payload,
        },
      };
    case UPDATE_REPORT_SIDEBAR: {
      return {
        ...state,
        reportSidebar: payload,
      };
    }
    case PRIOR_AUTHORIZATION_TAT_OVERRIDE_DATA:
      if (payload.data) {
        const data = payload.data.prior_authorization_tat_override;
        const report = state.reports.find(obj => obj.id === meta.reportId);
        const total = report.total.count;

        // Remove row from modal
        const modalRow = state.modal.data.find(obj => obj.id === data.prior_authorization_id);
        state.modal.data.splice(state.modal.data.indexOf(modalRow), 1);

        // Remove Old PA
        const reportTatBefore = report.data.find(obj => obj.key === data.override_before);
        const indexOfOldPA = reportTatBefore.ids.indexOf(data.prior_authorization_id);
        reportTatBefore.ids.splice(indexOfOldPA, 1);
        reportTatBefore.count -= 1;
        reportTatBefore.percentage = ((reportTatBefore.count * 100) / total).toFixed(2);

        // Add new PA with override
        const reportTatAfter = report.data.find(obj => obj.key === data.override_after);
        reportTatAfter.ids.push(data.prior_authorization_id);
        reportTatAfter.count += 1;
        reportTatAfter.percentage = ((reportTatAfter.count * 100) / total).toFixed(2);

        return {
          ...state,
        };
      }
      return state;
    default:
      return state;
  }
};

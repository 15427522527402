import { Theme } from '@mui/material';

export const styles = (theme: Theme) => ({
  adherencePackaging: {
    height: 18,
    paddingLeft: 3,
    marginTop: 6,
  },
  needsInterpreter: {
    height: 22,
    marginTop: 3,
    color: '#777',
  },
  medSync: {
    height: 18,
    marginTop: 0,
    opacity: 0.55,
  },
  enrolledInSMS: {
    height: 19,
    marginTop: 4,
    opacity: 0.55,
  },
  dropShipMed: {
    height: 18,
    marginTop: 4,
    opacity: 1,
  },
});

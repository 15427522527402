import React from 'react';
import JsonForm from 'components/dynamic-form/json-form';
import providerBuilder from '../providers/provider-builder';
import taskProviderHoc from './task-form-hoc';

function BundleTaskStatusForm(props) {
  const {
    data,
    formName,
    level,
    formId,
    title,
    type,
    onValidityChange,
    linkedFormIds,
    onInitialLoad,
    onFormChange,
    linkedTasks,
    additionalDocumentProps,
    drawBorder,
    taskIsSubmitting,
    dosisRegimenText,
    ...otherProps
  } = props;

  const providers = providerBuilder(type)(data, props);
  return (
    <JsonForm
      title={title}
      jsonForm={providers.json}
      formName={formName}
      formId={formId}
      data={data}
      providers={providers}
      level={level}
      onValidityChange={onValidityChange}
      linkedFormIds={linkedFormIds}
      onInitialLoad={onInitialLoad}
      onFormChange={onFormChange}
      linkedTasks={linkedTasks}
      forceNextStatus
      additionalDocumentProps={additionalDocumentProps}
      drawBorder={drawBorder}
      taskIsSubmitting={taskIsSubmitting}
      dosisRegimenText={dosisRegimenText}
      {...otherProps}
    />
  );
}

export default taskProviderHoc(BundleTaskStatusForm);

import React from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RadioGroup from 'components/form/radio/radio-group';
import RadioButton from 'components/form/radio/radio-button';
import Validation from 'components/form/validation/validation';
import { PRESCRIBING_CLINIC_METHOD } from 'constants/index';
import { styles } from './styles';

// TODO: all radio button group components in this folder need to be abstracted
// TODO: into a component which takes a list of values and labels and render
// TODO: into a radio group

const useStyles = makeStyles(styles);

export default props => {
  const {
    input,
    onChange,
    meta: { touched, error, warning },
  } = props;
  const classes = useStyles();

  return (
    <Grid container>
      <RadioGroup
        horizontal
        name="prescribing_method"
        id="prescribing_method"
        {...input}
        onChange={value => {
          if (onChange) {
            onChange(value);
          }
          if (input.onChange) {
            input.onChange(value);
          }
        }}
        className={classes.radioGroup}
      >
        <RadioButton value={PRESCRIBING_CLINIC_METHOD.USE_SAME}>
          Same as Managing Clinic
        </RadioButton>
        <RadioButton value={PRESCRIBING_CLINIC_METHOD.USE_LOOKUP}>Other</RadioButton>
        <RadioButton value={PRESCRIBING_CLINIC_METHOD.USE_NULL}>Unknown</RadioButton>
      </RadioGroup>
      <Validation touched={touched} error={error} warning={warning} />
    </Grid>
  );
};

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCustomers } from 'actions/action-filters';
import { fetchCustomerClinics } from 'actions/action-lookups';
import { Controller, useForm } from 'react-hook-form';
import { nameOfFactory } from 'utils/types-util';
import { ReactSelect } from 'components/form/field/react-select';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { NumberOption } from 'interfaces/forms/types';

interface ICustomerDropdownProps {
  selectedCustomerId: number;
  setSelectedCustomerId: (customerId: number) => void;
  isLabelOn?: boolean;
  isSelectedOption?: boolean;
}

interface IFormFields {
  customerId: {
    label: string;
    value: number;
  };
}

const getFormFields = nameOfFactory<IFormFields>();

export const CustomerDropdown: React.FC<ICustomerDropdownProps> = (
  props: ICustomerDropdownProps,
): JSX.Element => {
  const dispatch = useDispatch();
  const customers = useTypedSelector(state => state.filters.customers);

  const form = useForm<any>();

  useEffect(() => {
    dispatch(fetchCustomers());
    dispatch(fetchCustomerClinics());
  }, [fetchCustomers, fetchCustomerClinics]);

  const allCustomers = customers.map<NumberOption>(x => ({
    label: x.name,
    value: x.id,
  }));

  if (props.isSelectedOption) {
    allCustomers.push({ label: 'Select Customer', value: 0 });
  }

  return (
    <Controller
      defaultValue={
        allCustomers.find(customer => customer.value === props.selectedCustomerId) ||
        allCustomers[0]
      }
      control={form.control}
      name={getFormFields('customerId')}
      render={(ctrlProps: any) => {
        return (
          <ReactSelect
            value={ctrlProps?.field?.value}
            fields={allCustomers}
            isMulti={false}
            label={props.isLabelOn ? 'Customer' : undefined}
            handleOnChange={(value: { label: string; value: number }) => {
              form.setValue(getFormFields('customerId'), value);
              props.setSelectedCustomerId(value.value);
            }}
          />
        );
      }}
    />
  );
};

export default CustomerDropdown;

import Moment, { Moment as moment } from 'moment';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DownloadFileUtils } from 'utils/download-file-utils';
import { getModalStyle } from 'services/utils/styles-service';
import { nameOfFactory } from 'utils/types-util';
import { notifyError } from 'actions/action-notifications';
import { renderDatePicker as RenderDatePicker } from 'components/form/datepicker/datetime-picker';
import { TimeoutUtils } from 'utils/timeout-utils';
import { useDispatch } from 'react-redux';
import { Modal, Grid, Button, Typography, CircularProgress } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { buildQaId } from 'utils/build-qa-id';
import { styles } from './report-modal-styles';
import { logger } from '../../../../winston-logger';
import { getReportRequest } from './utils';

interface IHistoryFormFields {
  startDate: moment;
  endDate: moment;
}

interface IReportModalProps extends WithStyles<typeof styles> {
  open: boolean;
  close: () => void;
}

const getFieldName = nameOfFactory<IHistoryFormFields>();
const requiredMsg = 'Required';
const qaIdBuilder = buildQaId('gtd-report-modal');

const ReportModal: React.FC<IReportModalProps> = (props: IReportModalProps): JSX.Element => {
  // #region component state
  const dispatch = useDispatch();
  const historyForm = useForm<any>();
  const [submittingAuditReport, setSubmittingAuditReport] = React.useState<boolean>(false);
  // #endregion

  // #region helpers
  const downloadAuditReport = async (formValues: IHistoryFormFields) => {
    try {
      setSubmittingAuditReport(true);

      const waitPromise = TimeoutUtils.wait(500);
      const request = getReportRequest(formValues.startDate, formValues.endDate);
      const [, response] = await Promise.all([waitPromise, request]);

      setSubmittingAuditReport(false);
      DownloadFileUtils.downloadFile(response);
    } catch (error) {
      logger.error(error);
      dispatch(notifyError('Could not generate report'));
    }
  };
  // #endregion

  // #region renders
  const renderHistoryForm = (): JSX.Element => {
    return (
      <>
        <Grid container className={props.classes.labelContainer}>
          <Grid item xs={12}>
            <Typography>Generate GTD Report</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          {/* Start Date */}
          <Grid item xs={4}>
            <Controller
              defaultValue={Moment.utc().add(-1, 'months').startOf('day')}
              control={historyForm.control}
              name={getFieldName('startDate')}
              rules={{ required: true }}
              render={(ctrlProps: any) => {
                return (
                  <RenderDatePicker
                    label="Start Date *"
                    data-qa-id={qaIdBuilder(`audit-${getFieldName('startDate')}`)}
                    meta={{ touched: historyForm.formState.errors.startDate, error: requiredMsg }}
                    input={{
                      value: ctrlProps?.field?.value,
                      onChange: ctrlProps?.field?.onChange,
                      onBlur: ctrlProps?.field?.onBlur,
                    }}
                  />
                );
              }}
            />
          </Grid>
          {/* End Date */}

          <Grid item xs={4}>
            <Controller
              defaultValue={Moment.utc().startOf('day')}
              control={historyForm.control}
              name={getFieldName('endDate')}
              rules={{ required: true }}
              render={(ctrlProps: any) => {
                return (
                  <RenderDatePicker
                    label="End Date *"
                    data-qa-id={qaIdBuilder(`audit-${getFieldName('endDate')}`)}
                    meta={{ touched: historyForm.formState.errors.endDate, error: requiredMsg }}
                    input={{
                      value: ctrlProps?.field?.value,
                      onChange: ctrlProps?.field?.onChange,
                      onBlur: ctrlProps?.field?.onBlur,
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs="auto" className={props.classes.centerButton}>
            <Button
              variant="contained"
              color="primary"
              onClick={historyForm.handleSubmit(downloadAuditReport)}
              className={props.classes.button}
              disabled={submittingAuditReport}
              data-qa-id={qaIdBuilder('audit-generate')}
            >
              {submittingAuditReport ? (
                <CircularProgress
                  color="secondary"
                  size={20}
                  className={props.classes.submittingIndicator}
                />
              ) : null}
              Generate
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };
  // #endregion

  return (
    <Modal
      open={props.open}
      onBackdropClick={() => {
        props.close();
      }}
    >
      <div style={getModalStyle()} className={props.classes.modal}>
        <Grid container direction="column">
          {/* Gtd Report Form */}
          <Grid item xs={12}>
            {renderHistoryForm()}
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Grid item xs="auto">
                <Button
                  onClick={() => {
                    props.close();
                  }}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export const ReportModalStyled = withStyles(styles)(ReportModal);

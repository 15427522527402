export const LIKE = 'like';
export const GT = 'gt';
export const LT = 'lt';
export const EQ = 'eq';
export const BETWEEN = 'between';
export const IN = 'in';

export const DT_STRING = 'string';
export const DT_NUMBER = 'number';
export const DT_DATE = 'date';

export const SORT_DEFAULT = 'default';
export const SORT_ASC = 'ASC';
export const SORT_DESC = 'DESC';
export const COMMENT = 'Comment';

export const EXPANDER_ID = 'expander';
export const NUMBER = 'number';
export const DATE = 'date';
export const BLANK = 'Blank';

export const MAIN = 'main';
export const ADJUSTMENT = 'adjustment';

export const FLAG_EDITABLE_TYPE = 'flag';
export const SELECT_EDITABLE_TYPE = 'select';
export const AUTOCOMPLETE_EDITABLE_TYPE = 'autocomplete';
export const DATES_EDITABLE_TYPE = 'date';
export const INPUT_EDITABLE_TYPE = 'input';

export const CURRENCY = 'currency';
export const SUBCELL = 'SubCell';
export const CELL = 'Cell';

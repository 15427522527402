import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import withTheme from '@mui/styles/withTheme';
import { rawStyles } from 'components/invoicing/table/styles';
import { TableCell } from '@mui/material';

export default makeStyles(theme => ({
  noDatColor: {
    color: theme.palette.primary.grey12,
  },
  //
  invoicingTopIconButton: {
    'padding': '5px',
    'minWidth': '40px',
    '& .MuiButton-label': {
      '& .MuiButton-startIcon': {
        marginRight: 0,
        marginLeft: 0,
      },
    },
  },
  summaryStyledTableRow: {
    'whiteSpace': 'nowrap',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.primary.grey14,
    },
  },
  summaryStyledCategoryCell: {
    minWidth: 280,
    fontWeight: 'bold',
    paddingLeft: '10px',
  },
  summaryStyledClinicTableRow: {
    backgroundColor: theme.palette.primary.grey3,
  },
  summaryStyledTableCell: {
    fontSize: 12,
    color: theme.palette.primary.steelGrey,
    border: `1px solid ${theme.palette.primary.offWhite2}`,
  },
  invoicingSummaryStyledTableCell: {
    fontSize: 12,
    color: theme.palette.primary.steelGrey,
    border: '1px solid rgba(224, 224, 224, 1)',
    whiteSpace: 'nowrap',
  },
  invoicingStyledLoadContainer: {
    position: 'relative',
    minHeight: 150,
  },
  invocingWorkflowContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
  },
  invoicingWorkflowApprovalLabel: {
    color: theme.palette.primary.lightRed,
    fontStyle: 'italic',
  },
  invoicingWorkflowFirstButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: '25px',
  },
  periodTitle: {
    color: theme.palette.primary.steelGrey,
  },
  dividerList: {
    height: '2px',
    color: theme.palette.primary.steelGrey,
  },
  periodIconButton: {
    'backgroundColor': theme.palette.primary.patina,
    'color': theme.palette.primary.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.patinaWhite,
      borderColor: theme.palette.primary.patina,
      color: theme.palette.primary.patina,
    },
    'padding': '5px',
    'minWidth': '40px',
    '& .MuiButton-label': {
      '& .MuiButton-startIcon': {
        marginRight: 0,
        marginLeft: 0,
      },
    },
  },
  ...rawStyles(theme),
}));

export const TableCellStyled = styled(withTheme(TableCell))(
  ({ bgColor, fontColor, fontWeight, theme }) => ({
    backgroundColor: bgColor ? theme.palette.primary[bgColor] : null,
    color: fontColor ? theme.palette.primary[fontColor] : null,
    fontWeight: fontWeight || null,
  }),
);

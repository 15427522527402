import React from 'react';
import BlueContainer from '../components/blue-container';
import PoweredBy from '../components/powered-by';
import Paragraph from '../components/paragraph';

export default () => (
  <BlueContainer>
    <Paragraph type="title">The link is no longer valid.</Paragraph>
    <PoweredBy />
  </BlueContainer>
);

import React, { useEffect, useState } from 'react';
import { IState } from 'interfaces/redux/IState';
import { useSelector } from 'react-redux';
import {
  IPatientMergeSectionDetailsProps,
  IProfileIncomingBenefitsInfo,
} from 'components/patient-merge-modal/interfaces';
import { Divider, Grid, Typography } from '@mui/material';
import {
  FinancialSection,
  IncomeSection,
  MedicalSection,
  PharmacySection,
} from 'components/patient-merge-modal/select-form-view/sections/income-benefits-subsections';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { useTheme } from '@mui/styles';
import { PatientMergeClient } from 'clients/patient-merge';
import { LookupResourceTypes } from 'constants/enums';

export const ConfirmIncomeAndBenefitsSection = (props: IPatientMergeSectionDetailsProps) => {
  const { mergeId, expanded } = props;

  const [infos, setInfos] = useState<IProfileIncomingBenefitsInfo[]>([]);
  const [loaded, setLoaded] = useState(false);

  const [additionalInfo, setAdditionalInfo] = useState<IProfileIncomingBenefitsInfo>();
  const [selectedProfile, setSelectedProfile] = useState<IProfileIncomingBenefitsInfo>();
  const [primaryProfile, setPrimaryProfile] = useState<IProfileIncomingBenefitsInfo>();

  const theme = useTheme();
  const classes = PatientMergeStyles(theme);
  const { primary: primaryProfileId, duplicate: selectedProfileId, inputs } = useSelector(
    ({ patientMerge }: IState) => patientMerge,
  );

  useEffect(() => {
    const pharmacyBenefitIds = inputs
      .filter(input => input.type === LookupResourceTypes.PharmacyInsurance)
      .map(input => input.selectedId);
    const medicalBenefitIds = inputs
      .filter(input => input.type === LookupResourceTypes.MedicalInsurance)
      .map(input => input.selectedId);
    const financialAssitanceIds = inputs
      .filter(input => input.type === LookupResourceTypes.FinancialAssistance)
      .map(input => input.selectedId);
    const incomeIds = inputs
      .filter(input => input.type === LookupResourceTypes.Income)
      .map(input => input.selectedId);

    if (selectedProfile) {
      setAdditionalInfo({
        patientId: selectedProfile.patientId,
        pbmInsurances:
          selectedProfile.pbmInsurances?.filter(
            item => item.id && pharmacyBenefitIds.includes(item.id),
          ) || [],
        incomes:
          selectedProfile.incomes?.filter(item => item.id && incomeIds.includes(item.id)) || [],
        financialAssistances:
          selectedProfile.financialAssistances?.filter(
            item => item.id && financialAssitanceIds.includes(item.id),
          ) || [],
        medicalInsurances:
          selectedProfile.medicalInsurances?.filter(
            item => item.id && medicalBenefitIds.includes(item.id),
          ) || [],
      });
    }
  }, [selectedProfile]);

  useEffect(() => {
    setPrimaryProfile(infos.find(profile => profile.patientId === primaryProfileId));
  }, [infos, primaryProfileId]);

  useEffect(() => {
    setSelectedProfile(infos.find(profile => profile.patientId === selectedProfileId));
  }, [infos, selectedProfileId]);

  useEffect(() => {
    if (!loaded && expanded) {
      PatientMergeClient.getMergeIncomeAndBenefitInfo(mergeId).then(response => {
        setInfos(response.data);
        setLoaded(true);
      });
    }
  }, [mergeId, expanded]);

  return (
    <Grid container paddingLeft={1.75} spacing={3}>
      <Grid item xs={12}>
        <Typography sx={classes.sectionInputTitle}>Pharmacy Benefits</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid item container xs={12}>
          {!!additionalInfo?.pbmInsurances?.length || !!primaryProfile?.pbmInsurances?.length ? (
            <>
              {additionalInfo?.pbmInsurances?.map(pharmacyBenefit => (
                <Grid
                  paddingY={1}
                  key={`additional-${pharmacyBenefit.id}`}
                  sx={classes.newItem}
                  item
                  xs={12}
                >
                  <PharmacySection {...pharmacyBenefit} />
                </Grid>
              ))}
              {primaryProfile?.pbmInsurances?.map(pharmacyBenefit => (
                <Grid paddingY={1} key={`primary-${pharmacyBenefit.id}`} item xs={12}>
                  <PharmacySection {...pharmacyBenefit} />
                </Grid>
              ))}
            </>
          ) : (
            <Typography>-</Typography>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={classes.divider} light />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={classes.sectionInputTitle}>Medical Benefits</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid item container xs={12}>
          {!!additionalInfo?.medicalInsurances?.length ||
          !!primaryProfile?.medicalInsurances?.length ? (
            <>
              {additionalInfo?.medicalInsurances?.map(medicalBenefit => (
                <Grid
                  paddingY={1}
                  key={`additional-${medicalBenefit.id}`}
                  sx={classes.newItem}
                  item
                  xs={12}
                >
                  <MedicalSection {...medicalBenefit} />
                </Grid>
              ))}
              {primaryProfile?.medicalInsurances?.map(medicalBenefit => (
                <Grid paddingY={1} key={`primary-${medicalBenefit.id}`} item xs={12}>
                  <MedicalSection {...medicalBenefit} />
                </Grid>
              ))}
            </>
          ) : (
            <Typography>-</Typography>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={classes.divider} light />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={classes.sectionInputTitle}>Financial Assistance</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid item container xs={12}>
          {!!additionalInfo?.financialAssistances?.length ||
          !!primaryProfile?.financialAssistances?.length ? (
            <>
              {additionalInfo?.financialAssistances?.map(financialAssistance => (
                <Grid
                  paddingY={1}
                  key={`additional-${financialAssistance.id}`}
                  sx={classes.newItem}
                  item
                  xs={12}
                >
                  <FinancialSection {...financialAssistance} />
                </Grid>
              ))}
              {primaryProfile?.financialAssistances?.map(financialAssistance => (
                <Grid paddingY={1} key={`primary-${financialAssistance.id}`} item xs={12}>
                  <FinancialSection {...financialAssistance} />
                </Grid>
              ))}
            </>
          ) : (
            <Typography>-</Typography>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={classes.divider} light />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={classes.sectionInputTitle}>Income</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid item container xs={12}>
          {!!additionalInfo?.incomes?.length || !!primaryProfile?.incomes?.length ? (
            <>
              {additionalInfo?.incomes?.map(income => (
                <Grid
                  paddingY={1}
                  key={`additional-${income.id}`}
                  sx={classes.newItem}
                  item
                  xs={12}
                >
                  <IncomeSection {...income} />
                </Grid>
              ))}
              {primaryProfile?.incomes?.map(income => (
                <Grid paddingY={1} key={`primary-${income.id}`} item xs={12}>
                  <IncomeSection {...income} />
                </Grid>
              ))}
            </>
          ) : (
            <Typography>-</Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

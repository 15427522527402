import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { change } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import { editFillDeliveryConfirmation, fetchTask } from 'actions/action-tasks';
import { useFillCycleNumber } from 'hooks/useFillCycleNumber';

// this HOC injects all props from store that task providers need
function mapStateToProps(state) {
  return {
    patient: state.patient,
    cslChecklistOptions: state?.taskStatuses?.counseling_completed_details, //eslint-disable-line
    serviceGroups: state?.lookups?.serviceGroups,
    fillCycles: state.fillCycles,
    therapies: state?.therapies?.data,
    financials: state.financials,
    orders: state.orders,
    taskStatuses: state.taskStatuses,
    lookups: state.lookups,
    scheduleOutreachList: state.scheduleOutreachList,
    clinicalDataTypes: state?.lookups?.clinicalDataTypes,
    medications: state.medications,
    currentUser: state?.auth?.currentUser,
    paymentMethods: state?.paymentMethods ?? {},
  };
}

export default (Component, requireTask) => {
  const Wrapper = props => {
    // when a task form is mounted, capture current date time to avoid high
    // recursion when datetime's seconds change
    const [currentDateTime, setCurrentDateTime] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    // inject needs by date and per protocol based on fill cycle for FC
    const { data, type, task } = props;
    const enhancedData = { ...data };
    const [needsByDate, perProtocol, daysSupply] = useFillCycleNumber(
      data && data.therapy_id,
      data && data.fill_cycle_number,
    );
    if (data && type === 'FC') {
      enhancedData.days_supply = daysSupply;
      enhancedData.needsby_date = needsByDate;
      enhancedData.is_needsby_per_protocol = perProtocol;
    }
    useEffect(() => {
      setCurrentDateTime(moment());
    }, [setCurrentDateTime]);

    useEffect(() => {
      if (requireTask && !task && !loading) {
        setLoading(true);
        dispatch(fetchTask(props.taskType, props.id));
      }
    }, [task]);

    if (requireTask && !task) {
      return null;
    }

    return <Component {...props} data={enhancedData} currentDateTime={currentDateTime} />;
  };
  return connect(mapStateToProps, {
    editFillDeliveryConfirmation,
    change,
  })(Wrapper);
};

/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/function-component-definition */
/* eslint-disable @typescript-eslint/naming-convention */
import { TextField, Chip, Select, MenuItem, Typography, Checkbox } from '@mui/material';
import { debounce } from 'lodash';
import { convertToArborDate } from 'models/time/arbor-date';
import { Timezone } from 'models/time/timezone';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { AnswerComponent, AnswerComponentProps, AnswerValue, IndifferentOptions } from '../types';
import { useColors } from './question.styles';

export const TextAnswer: AnswerComponent = ({ value, onChange, ...props }) => {
  const [innerValue, setValue] = useState((value ?? '') as string);
  const change = debounce(onChange, 300);
  useEffect(() => {
    change(innerValue === '' ? null : innerValue.trim());
  }, [innerValue]);
  const isTextArea = props.rows && props.rows > 1;
  return (
    <div style={{ flex: 1 }}>
      {props.readOnly ? (
        <>
          <Typography variant="body1" fontStyle={isTextArea ? 'italic' : undefined}>
            <>
              {isTextArea && <span>&ldquo;</span>}
              {value}
              {isTextArea && <span>&rdquo;</span>}
            </>
          </Typography>
        </>
      ) : (
        <>
          <TextField
            multiline
            value={innerValue}
            onChange={({ target }) => setValue(target.value)}
            style={{ width: '100%' }}
            placeholder={props.placeholder}
            rows={props.rows}
          />
        </>
      )}
    </div>
  );
};
TextAnswer.displayName = 'TextAnswer';

export const CheckboxAnswer: AnswerComponent = ({ value, onChange, readOnly, label }) => {
  return (
    <div style={{ flex: 1 }}>
      <Typography>
        <Checkbox
          onChange={({ target }) => onChange(target.checked)}
          checked={Boolean(value)}
          disabled={readOnly}
        />{' '}
        {label ?? 'Confirm'}
      </Typography>
    </div>
  );
};
CheckboxAnswer.displayName = 'CheckboxAnswer';

interface AnswerChipProps {
  value: AnswerValue;
  currentValue: AnswerValue;
  onChange: (value: AnswerValue) => void;
  label: string;
  readOnly?: boolean;
}

export const AnswerChip: React.FC<AnswerChipProps> = ({
  value,
  currentValue,
  onChange,
  label,
  readOnly,
}) => {
  const isSelected = value === currentValue;
  const colors = useColors();
  const styles = React.useMemo(() => {
    const result = {
      'color': isSelected ? colors.white : colors.buttonText,
      'backgroundColor': isSelected ? colors.green : colors.chipGray,
      '&:hover': {
        backgroundColor: isSelected ? colors.greenDark : colors.chipGrayDark,
      },
      'flex': 1,
      'maxWidth': '84px',
      '&.Mui-disabled': {
        opacity: 1,
      },
    };
    if (isSelected && readOnly && (value === false || value === IndifferentOptions.No)) {
      result.backgroundColor = colors.redLight;
    }
    return result;
  }, [isSelected, readOnly, value, colors]);
  if (readOnly && !isSelected) return null;
  return <Chip sx={styles} label={label} onClick={() => onChange(value)} disabled={readOnly} />;
};
AnswerChip.displayName = 'AnswerChip';

const chipContainerStyles = {
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'flex-end',
  gap: '8px',
  width: '100%',
};
export const BooleanAnswer: AnswerComponent = ({ value, onChange, readOnly }) => {
  const currentValue = value === null ? null : Boolean(value);
  const props = { currentValue, onChange, readOnly };
  return (
    <div style={chipContainerStyles}>
      <AnswerChip value={true} label="Yes" {...props} />
      <AnswerChip value={false} label="No" {...props} />
    </div>
  );
};
BooleanAnswer.displayName = 'BooleanAnswer';

export const IndifferentAnswer: AnswerComponent = ({ value, onChange, readOnly }) => {
  const props = { currentValue: value, onChange, readOnly };
  return (
    <div style={chipContainerStyles}>
      <AnswerChip value={IndifferentOptions.Yes} label="Yes" {...props} />
      <AnswerChip value={IndifferentOptions.No} label="No" {...props} />
      <AnswerChip value={IndifferentOptions.NA} label="N/A" {...props} />
    </div>
  );
};
IndifferentAnswer.displayName = 'IndifferentAnswer';

const getSelectedString = (valueArray: string[], options: AnswerComponentProps['options']) =>
  options
    ?.reduce(
      (arr, opt) => (valueArray.includes(opt.value) ? arr.concat(opt.label) : arr),
      [] as string[],
    )
    .join(', ') ?? null;

const parseSelectedString = (str: string, options: AnswerComponentProps['options']) => {
  const labels = str.split(', ');
  return options?.reduce(
    (arr, opt) => (labels.includes(opt.label) ? arr.concat(opt.value) : arr),
    [] as string[],
  );
};

export const SelectAnswer: AnswerComponent = ({
  value,
  onChange,
  options,
  multiselect,
  readOnly,
}) => {
  const colors = useColors();
  const parsedValue = React.useMemo(() => {
    const result = typeof value === 'string' ? parseSelectedString(value, options) : [];
    return multiselect ? result : result?.[0] ?? '';
  }, [multiselect, value]);
  return readOnly && multiselect ? (
    <div style={{ flex: 1 }}>
      <TextAnswer value={value as string} readOnly onChange={onChange} rows={2} />
    </div>
  ) : (
    <Select
      onChange={evt => {
        const stringValue = Array.isArray(evt.target.value)
          ? getSelectedString(evt.target.value, options)
          : getSelectedString([evt.target.value], options);
        if (evt.target.value) onChange(stringValue);
      }}
      value={parsedValue}
      variant="outlined"
      multiple={multiselect}
      MenuProps={{
        sx: { zIndex: 9999 },
      }}
      SelectDisplayProps={{
        style: { color: colors.bodyText, WebkitTextFillColor: colors.bodyText },
      }}
      fullWidth
      disabled={readOnly}
    >
      <MenuItem key="empty" value="empty" style={{ userSelect: 'none' }}>
        {' '}
      </MenuItem>
      {options?.map(({ value: optionValue, label }) => (
        <MenuItem key={optionValue} value={optionValue}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};
SelectAnswer.displayName = 'SelectAnswer';

export const DateAnswer: AnswerComponent = ({ value, onChange, readOnly }) => {
  const inputElement = useRef(null);
  const dateChange = () => {
    const customerTimeZone = Timezone.getInstance().timezone;
    const v: any = (inputElement.current as unknown as HTMLInputElement).value;
    // eslint-disable-next-line prefer-const
    let [year, month, day] = v?.split('-') ?? [];
    if (year?.length > 4) {
      year = year.slice(0, 4);
    }
    const v2 = [year, month, day].join('-');
    const dateValue =
      v2 && v2.length ? moment.tz(`${v} 00:00`, customerTimeZone).utc().toDate() : undefined;
    onChange(dateValue ?? null);
    if ((inputElement.current as unknown as HTMLInputElement).value) {
      (inputElement.current as unknown as HTMLInputElement).value = v2;
    }
  };
  const colors = useColors();

  useEffect(() => {
    if (inputElement?.current && !!value && typeof value === 'string') {
      const tempValue = convertToArborDate(value, true).getCustomerDate(
        true,
        'YYYY-MM-DD',
      ) as string;
      (inputElement?.current as unknown as HTMLInputElement).value = tempValue;
    }
  }, [value]);

  return (
    <div style={{ flex: '1 1 auto', width: '100%' }}>
      <TextField
        inputRef={inputElement}
        type="date"
        onInput={dateChange}
        fullWidth
        disabled={readOnly}
        sx={{ color: colors.bodyText }}
        inputProps={{
          style: { color: colors.bodyText, WebkitTextFillColor: colors.bodyText },
        }}
      />
    </div>
  );
};
DateAnswer.displayName = 'DateAnswer';

export { DocumentAnswer } from './document-answer';

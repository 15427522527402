import { SET_DOCUMENT_VIEWER } from 'constants/index';

/**
 * Sets the document viewer state
 * @param {boolean} open is the viewer open
 * @param {string} filePath path to the file
 * @param {( string | undefined )} fileExtension extension of the file
 * @param {( number | undefined )} documentId database id of the document
 * @param {string} fileName name of the file
 * @param {boolean} printDisabled is the print button disabled
 * @param {boolean} downloadDisabled is the download button disabled
 */
export const setDocumentViewer = (
  open,
  filePath,
  fileExtension,
  documentId,
  fileName,
  printDisabled,
  downloadDisabled,
) => {
  // Force the file extensions to be lower case.
  let fileExt = fileExtension;
  if (fileExt) {
    try {
      fileExt = fileExt.toLowerCase();
      // eslint-disable-next-line no-empty
    } catch {}
  }

  const payload = {
    open,
    filePath,
    fileExtension: fileExt,
    documentId,
    fileName,
    printDisabled,
    downloadDisabled,
  };

  return {
    type: SET_DOCUMENT_VIEWER,
    payload,
  };
};

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { fetchShippingHolidaysRequest, addShippingHoliday } from 'actions/action-shipping';
import { useDispatch, useSelector } from 'react-redux';
import { convertToArborDate } from 'models/time/arbor-date';
import { StringUtils } from 'utils/string-utils';
import {
  DateMapping,
  IApiHolidayDates,
  IHolidayFormFields,
  HolidayPresentEnum,
  HolidayTypeEnum,
} from './interface';
import HolidayManager from './holidays';

export default (): JSX.Element => {
  const [selectedCustomerId, setSelectedCustomerId] = useState(0);
  const [showAddHolidayModal, setShowAddHolidayModal] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [holidayTypes, setHolidayTypes] = useState<HolidayTypeEnum[]>([]);
  const dispatch = useDispatch();
  const memorizedHolidays = useMemo(() => holidays, [holidays]);
  const { shippingHolidays } = useSelector((state: any) => state.shipping);

  const dateMapping = (obj: DateMapping, currentDate: string) => {
    if (!obj[currentDate]) {
      const arborDt = convertToArborDate(currentDate);
      const weekday = StringUtils.upperCaseFirstLetter(arborDt.getUtcDate(true, 'dddd') as string);
      const dt = arborDt.getUtcDate(true);
      obj[currentDate] = {
        holidayDate: `${weekday} - ${dt}`,
        customer: HolidayPresentEnum.NO,
        courier: HolidayPresentEnum.NO,
        ups: HolidayPresentEnum.NO,
      };
    }
  };

  const mergeDates = (holidaysObject: IApiHolidayDates) => {
    // TODO - create a generic type for it
    const dateMap: DateMapping = {};
    const { shippingCustomerHolidays, shippingCourierHolidays, shippingUpsHolidays } =
      holidaysObject;

    const maxLength = Math.max(
      shippingCustomerHolidays.length,
      shippingCourierHolidays.length,
      shippingUpsHolidays.length,
    );

    for (let i = 0; i < maxLength; i += 1) {
      if (shippingCustomerHolidays.length > i) {
        const date = shippingCustomerHolidays[i];
        dateMapping(dateMap, date);
        dateMap[date].customer = HolidayPresentEnum.YES;
      }

      if (shippingCourierHolidays.length > i) {
        const date = shippingCourierHolidays[i];
        dateMapping(dateMap, date);
        dateMap[date].courier = HolidayPresentEnum.YES;
      }

      if (shippingUpsHolidays.length > i) {
        const date = shippingUpsHolidays[i];
        dateMapping(dateMap, date);
        dateMap[date].ups = HolidayPresentEnum.YES;
      }
    }

    const sortedDates = Object.keys(dateMap).sort((a, b) => {
      return new Date(a).getTime() - new Date(b).getTime();
    });

    const sortedValues = sortedDates.map(date => dateMap[date]);
    return sortedValues;
  };

  const fetchHolidays = useCallback(async () => {
    setLoading(true);
    dispatch(fetchShippingHolidaysRequest(selectedCustomerId));
  }, [selectedCustomerId]);

  const submitRequestHoliday = useCallback(
    (data: IHolidayFormFields, holidayForm: any) => {
      setShowAddHolidayModal(false);
      dispatch(addShippingHoliday(data, selectedCustomerId));
      setHolidayTypes([]);
      holidayForm.reset();
    },
    [selectedCustomerId],
  );

  const handleAddHolidayCheckboxType = (holidayType: HolidayTypeEnum) => {
    if (holidayTypes.includes(holidayType)) {
      setHolidayTypes(holidayTypes.filter(type => type !== holidayType));
    } else {
      setHolidayTypes([...holidayTypes, holidayType]);
    }
  };

  useEffect(() => {
    if (shippingHolidays && isMounted) {
      const holidayTreated = mergeDates(shippingHolidays) as any;
      setHolidays(holidayTreated);
    }
  }, [shippingHolidays, isMounted]);

  useEffect(() => {
    if (isMounted && selectedCustomerId !== 0) {
      fetchHolidays();
    } else {
      setIsMounted(true);
    }
  }, [fetchHolidays, isMounted, selectedCustomerId]);

  useEffect(() => {
    if (memorizedHolidays) {
      setLoading(false);
    }
  }, [memorizedHolidays]);

  const handleModalAddHoliday = () => {
    setShowAddHolidayModal(!showAddHolidayModal);
  };

  return (
    <HolidayManager
      loading={loading}
      isMounted={isMounted}
      memorizedHolidays={memorizedHolidays}
      selectedCustomerId={selectedCustomerId}
      setSelectedCustomerId={setSelectedCustomerId}
      handleModalAddHoliday={handleModalAddHoliday}
      showAddHolidayModal={showAddHolidayModal}
      submitRequestHoliday={submitRequestHoliday}
      handleAddHolidayCheckboxType={handleAddHolidayCheckboxType}
      holidayTypes={holidayTypes}
    />
  );
};

export const styles = theme => ({
  buttonIconSpacing: {
    paddingLeft: 10,
    paddingTop: 2,
  },
  durContainerOuter: {
    border: `1px solid ${theme.palette.primary.task.borderColor}`,
    borderRadius: 5,
    marginTop: theme.spacing(2),
  },
  rowButton: {
    marginLeft: 5,
    marginRight: 5,
    backgroundColor: theme.palette.primary.background,
  },
  clinicalContainer: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  durContainerInner: {
    padding: theme.spacing(1),
  },
  jumpButton: {
    marginRight: theme.spacing(1),
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
  unknownInteractionDot: {
    color: theme.palette.primary.trellisBlue,
  },
  moderateDot: {
    color: theme.palette.primary.yellow2,
  },
  seriousDot: {
    color: theme.palette.primary.lightRed,
  },
  severeDot: {
    color: theme.palette.primary.darkRed,
  },
  inlineTypographyDots: {
    paddingRight: theme.spacing(1),
    fontSize: theme.font.smallFontSize,
    fontColor: theme.palette.primary.steelGrey,
  },
  inlineTypographyDotsLarge: {
    fontSize: theme.font.mediumFontSize,
    fontColor: theme.palette.primary.steelGrey,
    fontWeight: 'Bold',
  },
  dotsRow: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  drugInteractionAnalysisDropdownHeader: {
    backgroundColor: theme.palette.primary.grey2,
  },
  drugInteractionAnalysisDropdown: {
    border: null,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  interactionDocumentation: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
  verticalCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  errorMessage: {
    color: theme.palette.primary.darkRed,
    textTransform: 'uppercase',
  },
  unverifiedIcon: {
    color: theme.palette.primary.yellow2,
    paddingLeft: theme.spacing(1),
  },
  unverified: {
    color: theme.palette.primary.valenciaRed,
  },
  lastUpdated: {
    marginRight: 5,
  },
  interventionDetailsSection: {
    background: theme.palette.primary.grey2,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  interventionDetailsSectionTitle: {
    marginBottom: theme.spacing(2),
    fontSize: theme.font.largeFontSize,
  },
  interventionDataTitle: {
    color: theme.palette.primary.grey12,
    marginTop: theme.spacing(1),
  },
  generalDurInformation: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  durTopHeader: {
    marginBottom: theme.spacing(2),
  },
  durPreGenerateDurHeader: {
    marginTop: theme.spacing(2),
  },
  newChangesBadge: {
    background: theme.palette.primary.patina,
    padding: `${theme.spacing(0.2)} ${theme.spacing(0.6)}`,
    borderRadius: theme.spacing(0.4),
    color: theme.palette.primary.white,
  },
  modal: {
    position: 'absolute',
    width: theme.spacing(150),
    backgroundColor: theme.palette.primary.background,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    overflowY: 'scroll',
    maxHeight: '80vh',
  },
  newInteractionLabel: {
    borderRadius: 5,
    color: theme.palette.primary.white,
    padding: theme.spacing(0, 1),
    fontSize: theme.font.smallFontSize,
    marginLeft: 10,
    backgroundColor: theme.palette.primary.success,
  },
  interactionContainter: {
    borderBottom: `1px solid ${theme.palette.primary.task.borderColor}`,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  problemsWrapper: {
    padding: 0,
    marginTop: theme.spacing(0.5),
  },
  nonDurInteractionWording: {
    fontSize: theme.font.largeFontSize,
    lineHeight: 2.2,
    color: theme.palette.primary.success,
    fontWeight: 'bold',
    padding: theme.spacing(1),
    margin: theme.spacing(3, 0),
    backgroundColor: theme.palette.primary.patinaWhite4,
    borderRadius: 4,
  },
});

import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { expandTheme } from 'utils/theme-util';

export const styles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    rowAlignment: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    copyToClipboardButtonMargin: {
      marginLeft: theme.spacing(0.625),
    },
    mrnTable: {
      'borderCollapse': 'collapse',
      'color': palette.primary.steelGrey,
      'width': 'auto',
      '& th': {
        border: `1px solid ${palette.primary.timberWolf}`,
        padding: '0 0 0 5px',
        textAlign: 'left',
      },
      '& td': {
        padding: '0 0 0 5px',
        border: `1px solid ${palette.primary.timberWolf}`,
      },
    },
    mrnTableHeader: {
      padding: 0,
      height: theme.spacing(3.5),
      fontSize: theme.spacing(1.375),
      backgroundColor: palette.primary.pearlGrey,
    },
    mrnDataContainer: {
      overflowY: 'auto',
      maxHeight: theme.spacing(19.125),
      marginBottom: theme.spacing(0.625),
    },
    mrnValue: {
      fontWeight: 700,
      fontSize: theme.spacing(1.625),
    },
    mrnSource: {
      fontWeight: 400,
      fontSize: theme.spacing(1.625),
      fontStyle: 'italic',
    },
  });
};

type Styles = WithStyles<typeof styles>;

const useStyles = makeStyles(styles);

export const useTypedStyles = (): Styles => ({
  classes: useStyles(),
});

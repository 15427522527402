import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CardHeader from '@mui/material/CardHeader';
import React from 'react';
import '../../react-table-v6-fork/react-table.css';
import { reports } from 'constants/lists';
import ChartCard from './chart-card';
import Metrics from './metrics';
import { styles } from './report-styles';

function ReportItem(props) {
  const { total, classes, category, reportData, reportId, clickReportHandler, reportItemIndex } =
    props;
  let numbers;
  if (total) {
    numbers = [
      {
        label: 'Total',
        percentage: '100',
        count: total.count,
        ids: total.ids,
        reportItemIndex,
        hasId: true,
      },
    ].concat(reportData);
  } else {
    numbers = reportData;
  }

  const report = reports.find(r => r.value === reportId);
  if (report.type === 'metrics') {
    return (
      <Grid item>
        <Grid item xs={10}>
          <CardHeader className={classes.cardHeader} title={category} />
        </Grid>
        <Grid item xs={12}>
          <Metrics
            numbers={numbers}
            reportId={reportId}
            clickReportHandler={clickReportHandler}
            reportItemIndex={reportItemIndex}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item>
      <Grid item xs={10}>
        <CardHeader className={classes.subCardHeader} title={category} />
      </Grid>
      <Grid item xs={10} />
      <ChartCard
        classes={classes}
        total={total.count}
        label={category}
        data={reportData}
        reportId={reportId}
        clickReportHandler={clickReportHandler}
      />
    </Grid>
  );
}

export default withStyles(styles, { withTheme: true })(ReportItem);

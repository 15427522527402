import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) => {
  return createStyles({
    monographTitle: {
      fontWeight: 'bold',
    },
    monographContainer: {
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
    pointer: {
      cursor: 'pointer',
    },
  });
};

export { styles };

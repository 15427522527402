import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { SearchField } from '../../../components/search-field';

const fieldPlaceholder = 'Brand Name, Generic Name or GPI-10';

const GPISearch: FC<{
  searchTerm: string;
  initialValue: string;
  onChange: (value: string) => void;
}> = ({ searchTerm, initialValue, onChange }) => (
  <Grid container direction="column" spacing={2}>
    <Grid item>
      <Grid container justifyContent="flex-end">
        <Grid item xs="auto">
          <Grid container spacing={2}>
            <Grid item>
              <SearchField
                width={48}
                value={searchTerm}
                onChange={onChange}
                initialValue={initialValue}
                placeholder={fieldPlaceholder}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default GPISearch;

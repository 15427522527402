import React, { useState } from 'react';
import { groupBy } from 'helpers/misc';
import { Grid } from '@mui/material';
import { ITask } from 'interfaces/redux/ITasks';
import { DC, ALLERGIES_VERIFIED, FC } from 'constants/index';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from 'interfaces/redux/IState';
import { generatorFactory } from 'components/document/generator/generator-factory';
import BundleTaskForm from 'components/dynamic-form/bundle-task-form';
import { DataCollectStatus } from 'interfaces/enums/TaskStatuses/DataCollectStatus';
import { minBy } from 'lodash';
import { isDcTransitioningToIntervening } from 'services/utils/data-collect';
import { DocumentGeneratorType } from 'components/document/generator/types';
import { getNotesHigestId, parseNoteToDb } from 'services/utils/note-service';
import { addTasksOldValues } from 'services/utils/task-service';
import { convertToArborDate } from 'models/time/arbor-date';
import { noteTagTypes } from 'constants/lists';
import { getTodayDateTime } from 'services/utils/date-service';
import { getDocuments } from 'services/utils/upload-document-service';
import { fetchNotes, fetchNewNotes } from 'actions/action-notes';
import { workListChanged } from 'actions/action-patient';
import { editTasks } from 'actions/action-tasks';
import { addNewDocument } from 'actions/action-uploaded-documents';
import { verifyAllergies } from 'actions/action-allergies';
import TaskContainer from 'containers/tasks/task-container';
import { CombinedTasks, TaskType } from './grouped-task-tabs';
import { ArchivedTaskRenderer } from './tasks-table/archived-task-renderer';

export interface GroupedTaskTabsFormProps {
  selectedTasks: ITask[];
  handleCancel: Function;
  taskType: string;
}

export const GroupedArchivedTaskTabsForm: React.FC<GroupedTaskTabsFormProps> = props => {
  interface ItaskState {
    followUpTherapyId: number | null;
    followUpDataCollectedDate: Date | null;
    followUpMinAssessmentDate: string | undefined | null;
    followUpTherapyDuration: number | null;
    value: any;
    newDocuments: any;
    existingNotes: any;
  }

  const dispatch = useDispatch();

  const { selectedTasks, handleCancel, taskType } = props;

  const existingNotes = useSelector((state: IState) => state.notes);
  const existingDocuments = useSelector((state: IState) => state.uploadedDocuments.documents);
  const patient = useSelector((state: IState) => state.patient);
  const selectedCustomerId = useSelector((state: IState) => state.filters.selectedCustomerId);
  const selectedPatientId = useSelector((state: IState) => state.selectedPatientId);
  const therapies = useSelector((state: IState) => state.therapies);
  const [taskState, setTaskState] = useState<ItaskState>();

  const combinedTask: CombinedTasks = groupBy(selectedTasks, 'taskType');

  const handleOnSubmit = (
    taskList: any,
    orders: any,
    type: any,
    existingNotes: any,
    attachedDocuments: any,
  ) => {
    const notes = taskList
      .filter((t: any) => t.note)
      .map((t: any) => {
        const parsedNote = parseNoteToDb({ note_text: t.note });
        const noteTagType = noteTagTypes?.find(tag => tag.label === t.taskType);

        const typeId = noteTagType ? noteTagType.value : null;
        return {
          patient_id: selectedPatientId,
          note_type_id: 1,
          note_text: parsedNote.note_text,
          is_pinned: 0,
          mentions: parsedNote.mentions,
          tags: [
            {
              tag_type_id: typeId,
              resource_id: t.id,
            },
          ],
        };
      });

    const combinedTaskOld = groupBy(selectedTasks, 'taskType');

    const withOldTasks = combinedTaskOld[type]
      ? addTasksOldValues(taskList, combinedTaskOld[type])
      : taskList;

    const newDocuments = attachedDocuments.map((doc: any) => ({
      uuid: doc.uuid,
      file_name: doc.filename,
      labels: doc.labels,
      tags: doc.taskIds.map((id: any) => ({
        tag_type_id: doc.taskTypeId,
        resource_id: id,
      })),
      noteText: doc.note,
    }));
    if (withOldTasks.some((task: any) => task.taskType === FC && !task.newAllergies)) {
      const allergyVerificationPayload = {
        patient_id: patient.id,
        verification_status_id: ALLERGIES_VERIFIED,
        verified_dt: convertToArborDate(getTodayDateTime()).getUtcDatetime(),
      };
      verifyAllergies(allergyVerificationPayload);
    }

    dispatch(editTasks(selectedPatientId, withOldTasks, orders, notes, newDocuments));
    dispatch(workListChanged());
    if (notes.length) {
      const { pinnedNoteHighestId, nonPinnedNoteHighestId } = getNotesHigestId(existingNotes);
      if (pinnedNoteHighestId === -1 && nonPinnedNoteHighestId === -1) {
        fetchNotes({
          patient_id: selectedPatientId,
          group_by_tag: true,
        });
      } else if (pinnedNoteHighestId > nonPinnedNoteHighestId) {
        fetchNewNotes({
          patient_id: selectedPatientId,
          group_by_tag: true,
          fetchFromId: pinnedNoteHighestId,
        });
      } else {
        fetchNewNotes({
          patient_id: selectedPatientId,
          group_by_tag: true,
          fetchFromId: nonPinnedNoteHighestId,
        });
      }
    }
    getDocuments({
      patient_id: selectedPatientId,
      fetchFromId: Math.max(...existingDocuments.map(d => d.id)),
    }).then(result => {
      addNewDocument(result.data.documents);
    });
    onCancel();
  };

  const updateTaskState = (obj: any) => {
    setTaskState(obj);
  };

  const onCancel = () => {
    handleCancel(selectedTasks);
  };

  let taskPropArr: any[] = [];
  Object.keys(combinedTask).map(item => {
    const type = item as TaskType;
    if (taskType === item) {
      const documentGeneratorType = item as DocumentGeneratorType;
      const generator = generatorFactory(
        documentGeneratorType,
        selectedCustomerId,
        selectedPatientId,
      );
      const submitButtonTextFn = generator && generator.saveButtonText;
      const taskType = type;
      const patientId = selectedPatientId;
      const taskIds = combinedTask[type].map(task => task.id);
      const shouldShowPreviewButtonFn = generator && generator.showPreviewButton;

      const onSubmit = (
        taskList: any,
        orders: any,
        attachedDocuments: any,
        generateDocumentAfterSave: any,
      ) => {
        const task = taskList.find(
          (task: any) =>
            task.taskType === DC &&
            task.data_collect_followup &&
            task.status_id === DataCollectStatus.Reviewed,
        );
        const isDCFollowUp = !!task;
        if (isDCFollowUp && task && !isDcTransitioningToIntervening(task)) {
          const assessmentDates = task.data_collect
            .map((dcItem: any) => dcItem.assessmentDate)
            .filter((assessDate: any) => !!assessDate);
          const minAssessmentDate = assessmentDates.length
            ? minBy<string>(assessmentDates, assessDateArborDate => Date.parse(assessDateArborDate))
            : undefined;
          setTaskState({
            followUpTherapyId: task.therapy_id,
            followUpDataCollectedDate: task.data_collected_date,
            followUpMinAssessmentDate: minAssessmentDate,
            followUpTherapyDuration: task.therapy_duration,
            value: { taskList, orders, type },
            newDocuments: attachedDocuments,
            existingNotes: existingNotes,
          });
          return;
        }
        handleOnSubmit(taskList, orders, type, existingNotes, attachedDocuments);
      };

      if (
        ['PA', 'FA', 'OR', 'INT', 'TPR', 'FC', 'CSL', 'QRE', 'FDC', 'DC', 'RS'].includes(taskType)
      ) {
        combinedTask[taskType].forEach(task => {
          const therapy = therapies.data[task.therapy_id];
          if (taskType === 'RS') {
            taskPropArr.push(
              <TaskContainer
                task={task}
                therapy={therapy}
                archived
                therapyTaskid={`${0}_therapyTask_${task.taskType}_${task.id}`}
                renderChildren={(innerProps: any = {}) => (
                  <BundleTaskForm
                    submitButtonTextFn={submitButtonTextFn}
                    combinedTask={combinedTask}
                    taskType={taskType}
                    patientId={patientId}
                    taskIds={taskIds}
                    isLoadingPreview={false}
                    shouldShowPreviewButtonFn={shouldShowPreviewButtonFn}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    taskState={taskState}
                    updateTaskState={updateTaskState}
                    handleOnSubmit={handleOnSubmit}
                    {...innerProps}
                  />
                )}
              />,
            );
          } else {
            taskPropArr.push(
              <ArchivedTaskRenderer
                key={`${task.taskType}-${task.id}`}
                task={task}
                therapy={therapy}
                expanded={combinedTask[taskType].length === 1}
              />,
            );
          }
        });
      } else {
        taskPropArr = [
          ...taskPropArr,
          <BundleTaskForm
            submitButtonTextFn={submitButtonTextFn}
            combinedTask={combinedTask}
            taskType={taskType}
            patientId={patientId}
            taskIds={taskIds}
            isLoadingPreview={false}
            shouldShowPreviewButtonFn={shouldShowPreviewButtonFn}
            onSubmit={onSubmit}
            onCancel={onCancel}
            taskState={taskState}
            updateTaskState={updateTaskState}
            handleOnSubmit={handleOnSubmit}
          />,
        ];
      }
    }
  });
  return (
    <Grid item xs={12}>
      {taskPropArr}
    </Grid>
  );
};

import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  FETCH_ORDERS,
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAILURE,
  BULK_TASKS_UPDATED,
  SELECTED_PATIENT_ID,
} from '../constants';
import HTTP from '../services/http';

function* workerFetchOrder() {
  const { selectedPatientId } = yield select();
  if (selectedPatientId) {
    const basePayload = { payload: { selectedPatientId } };
    yield put({ type: FETCH_ORDERS_REQUEST, ...basePayload });
    try {
      const response = yield call(() => HTTP.get(`/orders/patients/${selectedPatientId}`, {}));
      const data = {
        ...basePayload.payload,
        orders: response.data.orders,
      };
      yield put({ type: FETCH_ORDERS_SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: FETCH_ORDERS_FAILURE, ...basePayload });
    }
  }
}

export function* watcherOrdersSaga() {
  yield takeLatest([SELECTED_PATIENT_ID, FETCH_ORDERS, BULK_TASKS_UPDATED], workerFetchOrder);
}

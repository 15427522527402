import { IViewableResult } from 'models/document_generator/IViewableResult';
import { IGeneratedDocumentResponse } from 'models/document_generator/IGeneratedDocumentResponse';
import { b64toBlob } from 'services/utils/document-service';

export class GeneratorUtil {
  /**
   * Converts a result from services to an object
   * that can be used in the document previewer
   */
  static convertToViewable = (
    documentResponse: IGeneratedDocumentResponse,
    defaultFileName: string | undefined,
  ): IViewableResult => {
    const blob = b64toBlob(documentResponse.generatedDocument, documentResponse.mimeType);
    const fileName = documentResponse.fileName ?? defaultFileName;
    const name = `${fileName}.${documentResponse.fileType}`;
    const blobUrl = URL.createObjectURL(blob);

    return {
      url: blobUrl,
      fileName: name,
      fileType: documentResponse.fileType,
    };
  };
}

import React from 'react';
import { format } from 'phone-fns';
import BlueContainer from '../components/blue-container';
import PoweredBy from '../components/powered-by';
import Paragraph from '../components/paragraph';

const ChangesNeededPage = ({ store, submit }) => {
  const {
    pharmacyInfo: { pharmacyPhoneNumber, openTime, closeTime },
    patientAnswers,
  } = store;

  React.useEffect(() => {
    submit({ patient_contact_required: 1, therapies: patientAnswers.therapies });
  }, []);

  return (
    <BlueContainer>
      <Paragraph type="title">
        {`Thank you! A pharmacy team member will reach out to you to discuss the changes needed for
        your order. If you would like to call us directly, you can do so at ${format(
          'NNN-NNN-NNNN',
          pharmacyPhoneNumber,
        )}. `}
        {openTime && closeTime ? `Our hours are ${openTime} to ${closeTime}.` : null}
      </Paragraph>
      <PoweredBy pharmacyInfo={store.pharmacyInfo} />
    </BlueContainer>
  );
};

export default ChangesNeededPage;

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

export const RulesEngineManagerStyles = (theme: Theme) => {
  const { palette } = expandTheme(theme);
  return createStyles({
    addButton: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      backgroundColor: palette.primary.teal,
      color: palette.primary.white,
      textTransform: 'none',
    },
    modalContentWrapper: {
      position: 'absolute',
      width: theme.spacing(150),
      backgroundColor: palette.primary.background,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      borderRadius: theme.spacing(0.6),
      overflow: 'auto',
      maxHeight: '95vh',
    },
  });
};

import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { expandTheme } from 'utils/theme-util';

export const YesNoFormStyles = (theme: Theme) => {
  return createStyles({
    container: {
      padding: theme.spacing(1),
    },
    option: {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[200],
      },
    },
    optionType: {
      padding: theme.spacing(1),
    },
  });
};

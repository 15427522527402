import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';
import makeStyles from '@mui/styles/makeStyles';

export const CpmpStyles = (theme: Theme) => {
  const { palette } = expandTheme(theme);
  return createStyles({
    addButton: {
      alignSelf: 'end',
      backgroundColor: palette.primary.teal,
      color: palette.primary.white,
      textTransform: 'none',
    },
    contentTitle: {
      color: theme.palette.primary.dark,
      fontWeight: theme.typography.fontWeightBold,
    },
    contentDescription: {
      whiteSpace: 'break-spaces',
      lineBreak: 'auto',
    },
    advancedSearchContainer: {
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(3),
      border: `1px solid ${palette.primary.trellisBlue}`,
      borderRadius: theme.spacing(0.5),
    },
    containerPadding: {
      padding: theme.spacing(3.5),
    },
    asProtocolType: {
      fontWeight: 400,
      display: 'inline',
    },
    childRow: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    dataCell: {
      padding: '30px',
    },
    dataCellToEdit: {
      padding: '30px',
      paddingRight: theme.spacing(0),
    },
    editCell: {
      textAlign: 'center',
      heigth: '50vh',
    },
    titleWrp: {
      paddingBottom: 0,
    },
    title: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(1),
      fontWeight: 500,
    },
    fixedSection: {
      paddingLeft: theme.spacing(2),
      marginTop: theme.spacing(2.5),
      position: 'relative',
    },
  });
};

export const styles = makeStyles(theme => {
  const { palette } = expandTheme(theme);
  return {
    lastCell: {
      '&:last-child': {
        paddingRight: 0,
        paddingLeft: 0,
        textAlign: 'center',
      },
    },
    pointer: {
      cursor: 'pointer',
    },
    dcSubFields: {
      color: theme.palette.secondary.dark,
    },
    roundedGrayBackground: {
      backgroundColor: theme.palette.grey[300],
      borderRadius: theme.spacing(1.25),
      marginTop: 5,
      paddingRight: theme.spacing(1.25),
      paddingLeft: theme.spacing(1.25),
      paddingTop: theme.spacing(0.2),
      paddingBottom: theme.spacing(0.2),
      marginRight: theme.spacing(1),
      float: 'left',
    },
    alignIcon: {
      marginBottom: theme.spacing(-0.5),
      marginRight: theme.spacing(0.5),
    },
    gpiExpandedTableRow: {
      paddingTop: theme.spacing(1.7),
      paddingBottom: theme.spacing(1.7),
    },
    gpiFrequencyTitle: {
      fontSize: '.8rem',
      paddingBottom: theme.spacing(1.2),
    },
    gpiFrequencyIcon: {
      width: '0.9rem',
      height: '0.9rem',
      marginBottom: theme.spacing(-0.2),
      paddingRight: theme.spacing(0.5),
    },
    wrapText: {
      '& p': {
        whiteSpace: 'normal',
      },
    },
    lastCellEmpty: {
      '&:last-child': {
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
    addButton: {
      alignSelf: 'end',
      backgroundColor: palette.primary.teal,
      color: palette.primary.white,
      textTransform: 'none',
    },
    contentTitle: {
      color: theme.palette.primary.dark,
      fontWeight: theme.typography.fontWeightBold,
    },
    contentDescription: {
      whiteSpace: 'break-spaces',
      lineBreak: 'auto',
    },
  };
});

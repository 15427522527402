import React, { Component } from 'react';
import uuidV4 from 'uuid/v4';
import { logger } from '../../../winston-logger';
import ErrorComponent from './error';

const MAX_ATTEMPTS = 3;

export default class ErrorBoundary extends Component {
  state = {
    error: null,
    uuid: null,
    attempts: 0,
  };

  componentDidCatch(error) {
    const { attempts } = this.state;
    this.setState(prevState => {
      const { uuid: existingUuid } = prevState;
      if (attempts + 1 >= MAX_ATTEMPTS) {
        const uuid = existingUuid || uuidV4().substr(0, 6);
        if (error && error.message) {
          logger.error(`${uuid} - ${error?.message} - ${error?.stack}`);
        } else {
          logger.error(`${uuid} - ${error}`);
        }
        return {
          error,
          uuid,
        };
      }
      return {
        attempts: attempts + 1,
      };
    });
  }

  render() {
    const { children } = this.props;
    const { error, uuid } = this.state;
    if (error) {
      return <ErrorComponent error={error} uuid={uuid} />;
    }
    return children;
  }
}

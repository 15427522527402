import durJson from 'config/task-types/dur.json';
import { getTreeMapFromDataTypes } from 'services/utils/data-collect';
import { staticProviders, getCommonProviders } from './common-provider';

export default (task, state) => ({
  ...staticProviders,
  ...getCommonProviders(task, state, durJson),
  json: durJson,
  initialValues: {
    canceled_dt: state.currentDateTime,
    completed_dt: state.currentDateTime,
    reviewed_dt: state.currentDateTime,
    fdb_drug_interaction_severity_id: null,
  },
  dataCollectFields: getTreeMapFromDataTypes(state.clinicalDataTypes),
  dataCollectInformation: {
    id: task.id,
    patientClinicalDataId: task.patient_clinical_data_id,
  },
});

import React from 'react';
import { useDispatch } from 'react-redux';
import { nameOfFactory } from 'utils/types-util';
import { Grid, Tooltip } from '@mui/material';
import { WithStyles } from '@mui/styles';
import { addEmailModalActions } from 'actions/action-add-email-modal';
import withStyles from '@mui/styles/withStyles';
import { Email, CheckCircle, CreditCard } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';
// eslint-disable-next-line import/no-unresolved
import { PhoneUsage } from 'interfaces/redux/IPhone';
import IconPhoneOptIn from 'components/icons/IconPhoneOptIn';
import { SMSIcon, WKIcon } from '../../../components/icons/icons';
import { IPreferredContact } from '../../../interfaces/redux/IPreferredContact';
import { PatientStyles } from './patient.styles';
import { MobilePhoneSmsEnum } from '../../../constants/enums';
import PaymentMethodStatus from './payment-method-status';

interface IPatientSmsEmail extends IPreferredContact, WithStyles<typeof PatientStyles> {
  wkIsVisible: boolean;
  sourceNumber: string;
  patientLoaded?: boolean;
  expirationDate?: string;
  patientAutoFCStatus?: number;
  autoFCAnchorTherapy?: string | number;
}

const nameOfFormFields = nameOfFactory<IPatientSmsEmail>();

// eslint-disable-next-line @typescript-eslint/naming-convention
function PatientSmsEmail(props: IPatientSmsEmail): JSX.Element {
  const {
    classes,
    filteredPhones,
    filteredEmails,
    filteredWelcomeKit,
    sourceNumber,
    wkIsVisible = false,
    patientLoaded = true,
    patientAutoFCStatus,
    autoFCAnchorTherapy,
  } = props;

  const dispatch = useDispatch();

  const showEmailModal = () => {
    dispatch(
      addEmailModalActions.setState({
        visible: true,
        formId: 'patient-overview-header',
        field: 'patient-overview-email',
        mode: 'therapies',
      }),
    );
  };

  const displayPhoneProvided = (): JSX.Element => {
    const classNameIcon =
      filteredPhones &&
      filteredPhones?.length > 0 &&
      filteredPhones.some(
        ({ sms, use }) => sms === MobilePhoneSmsEnum.OptOut && use === PhoneUsage.Mobile,
      )
        ? classes.iconActive
        : classes.iconInactive;
    return <SMSIcon className={classNameIcon} />;
  };

  const displayEmailProvided = (): JSX.Element => {
    const classNameIcon =
      filteredEmails && filteredEmails?.length > 0 ? classes.iconActive : classes.iconInactive;
    return (
      <Email
        className={classNameIcon}
        onClick={() => {
          showEmailModal();
        }}
      />
    );
  };

  const displayEmailOptInCheck = (): JSX.Element => {
    if (!patientLoaded) {
      return <></>;
    }
    const emailSms =
      filteredEmails?.map(email => email.enrollment).filter(enrollment => enrollment != null) ?? [];
    const optIn = emailSms.includes(MobilePhoneSmsEnum.OptIn);
    const optOut = emailSms.includes(MobilePhoneSmsEnum.OptOut);
    const notSpecified = emailSms.includes(MobilePhoneSmsEnum.NotSpecified);
    return (
      <>
        {!emailSms?.length && <InfoIcon className={classes.notProvided} />}
        {optIn && <CheckCircle className={classes.provided} />}
        {optOut && <InfoIcon className={classes.notProvided} />}
        {notSpecified && <InfoIcon className={classes.notProvided} />}
      </>
    );
  };

  const displayWk = (): JSX.Element => {
    const classNameIcon = filteredWelcomeKit ? classes.iconActive : classes.iconInactive;
    return (
      <>
        {filteredWelcomeKit ? (
          <Tooltip title="Welcome Kit/Patient Educational materials received" placement="bottom">
            <span>
              <WKIcon className={classNameIcon} />
            </span>
          </Tooltip>
        ) : (
          <WKIcon className={classNameIcon} />
        )}
      </>
    );
  };

  const displayWkCheck = (): JSX.Element => {
    return filteredWelcomeKit ? (
      <CheckCircle className={classes.provided} />
    ) : (
      <CancelIcon className={classes.notProvided} />
    );
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid item>
          {displayPhoneProvided()}
          <IconPhoneOptIn
            phones={filteredPhones || []}
            sourceNumber={sourceNumber}
            patientAutoFCStatus={patientAutoFCStatus || null}
            anchorTherapy={autoFCAnchorTherapy}
          />
        </Grid>
        <Grid item>|</Grid>
        <Grid item>
          {displayEmailProvided()}
          {displayEmailOptInCheck()}
        </Grid>
        {wkIsVisible && (
          <>
            <Grid item>|</Grid>
            <Grid item>
              {displayWk()}
              {displayWkCheck()}
            </Grid>
          </>
        )}
        <Grid item>|</Grid>
        <Grid>
          <CreditCard
            className={props.expirationDate ? classes.iconActive : classes.iconInactive}
          />
          <PaymentMethodStatus expiration={props.expirationDate} />
        </Grid>
      </Grid>
      <br />
    </>
  );
}

export default withStyles(PatientStyles)(PatientSmsEmail);

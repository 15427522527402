import React, { Component } from 'react';
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Checkbox from '@mui/material/Checkbox';
import { styles } from './field-styles';
import FieldLabel from './field-label';

function checkBinaryValue(id, value) {
  const result = id & value; // eslint-disable-line
  return result > 0;
}
class CheckboxGroup extends Component {
  constructor(props) {
    super(props);
    this.handleCheckboxes = this.handleCheckboxes.bind(this);
  }

  handleCheckboxes(e) {
    const { updateParent, value } = this.props;
    updateParent(value + e);
  }

  render() {
    const { fields, checklistName, gridSize, classes, value, qaId } = this.props;
    return (
      <>
        <Typography variant="h6">{checklistName}</Typography>
        <Grid container>
          {fields.map(f => (
            <Grid item xs={gridSize} key={f.id}>
              <Grid container alignItems="center">
                {checkBinaryValue(f.id, value) ? (
                  <>
                    <Checkbox
                      key={f.id}
                      checked
                      color="primary"
                      onChange={() => this.handleCheckboxes(-f.id)}
                    />
                    <FieldLabel
                      fieldName={f.text}
                      className={classes.fieldLabel}
                      onClick={() => this.handleCheckboxes(-f.id)}
                      pointer
                    />
                  </>
                ) : (
                  <>
                    <Checkbox
                      key={f.id}
                      checked={false}
                      color="primary"
                      onChange={() => this.handleCheckboxes(f.id)}
                      id={qaId ? `${qaId}_${f.id}` : ''}
                      data-qa-id={qaId ? `${qaId}_${f.id}` : ''}
                    />
                    <FieldLabel
                      fieldName={f.text}
                      className={classes.fieldLabel}
                      onClick={() => this.handleCheckboxes(f.id)}
                      pointer
                    />
                  </>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CheckboxGroup);

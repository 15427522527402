import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useIcdGroup } from 'containers/patient/clinical/use-cdm-group';
import { renderDropdown as RenderDropdown } from 'components/form/field/redux-field';
import useProvider from 'hooks/useProvider';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { valueLabelFormatter } from '../formatters';
import { IRenderProps } from '../interfaces/interfaces';
import useStyles from './render-therapy-appropriate-styles';

const RenderTherapyAppropriate = ({
  providers,
  field,
  label,
  input,
  meta,
  disabled,
  labelSize,
  qaId,
}: IRenderProps) => {
  const { therapy_id: therapyId, id: taskId } = providers.task;
  const { therapies, patientId, tasks } = useTypedSelector(state => {
    return {
      therapies: state?.therapies?.data,
      patientId: state?.patient?.id,
      tasks: state?.tasks?.data,
    };
  });
  const classes = useStyles();
  const [value, setValue] = useState(input.value);
  const fields = useProvider(providers, field.provider, [], valueLabelFormatter);

  const icdGroup = useIcdGroup(patientId, therapyId, tasks[`DC${taskId}`].status_id);

  const workingAsGroup = providers.formValues.work_as_group;
  const therapyFields = workingAsGroup?.length > 0
    ? icdGroup.therapyIds.map((therapyId) => {
        const therapy = therapies[therapyId];
        return {
          value: therapyId,
          label: therapy.drug_name,
        };
      })
    : [];

  const therapyAppropriateInput = {
    onChange: (v: number) => {
      setValue(v);
      input.onChange(v);
    },
    value: typeof value === 'string' ? 0 : value,
  };

  const therapiesInput = {
    onChange: (v: string) => {
      if (v) {
        setValue(v);
        input.onChange(v);
      } else {
        setValue('');
        input.onChange('');
      }
    },
    value,
  };

  return (
    <Box className={classes.root}>
      <div className={classes.left}>
        <RenderDropdown
          className
          label={workingAsGroup ? 'Are all therapies appropriate *' : label}
          input={therapyAppropriateInput}
          fields={fields}
          meta={meta}
          disabled={disabled}
          labelSize={labelSize}
          id={qaId}
        />
      </div>
      {workingAsGroup && (value === 0 || typeof value === 'string') && (
        <RenderDropdown
          label="Non-appropriate therapies *"
          required
          meta={{}}
          input={therapiesInput}
          disabled={disabled}
          labelSize={labelSize}
          fields={therapyFields}
          isMulti
        />
      )}
    </Box>
  );
};

export default RenderTherapyAppropriate;

import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { addNote } from 'actions/action-notes';
import { workListChanged } from 'actions/action-patient';
import { editFillDeliveryConfirmation, fetchTaskCounts } from 'actions/action-tasks';
import { updateTherapy } from 'actions/action-therapies';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import ReactSelectForRedux from 'components/form/field/react-select';
import { renderCheckbox, renderDropdown, renderTextField } from 'components/form/field/redux-field';
import NoteArea from 'components/note/NoteArea';
import { convertToArborDate } from 'models/time/arbor-date';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { change, Field, getFormValues, reduxForm } from 'redux-form';
import { dropdownFormater } from 'services/utils/common-service';
import { parseNoteToDb } from 'services/utils/note-service';
import { addTaskOldValues, getReasonsByStatus } from 'services/utils/task-service';
import { translateUsers } from 'services/utils/users-service';
import { fetchResourceLink } from 'actions/action-resource-links';
import { renderDatePicker } from '../../../components/form/datepicker/datetime-picker';
import { required, validateDate } from '../../../components/form/validation/validation';
import {
  EDIT_FILL_DELIVERY_CONFIRMATION_FORM,
  FILL_DELIVERY_CONFIRMATION_STATUS_CANCELED,
  FILL_DELIVERY_CONFIRMATION_STATUS_DELIVERED,
  FILL_DELIVERY_CONFIRMATION_STATUS_DELIVERY_EXCEPTION,
  FILL_DELIVERY_CONFIRMATION_STATUS_PICKED_UP,
  FILL_DELIVERY_CONFIRMATION_STATUS_PICK_UP,
  FILL_DELIVERY_CONFIRMATION_STATUS_SHIPPED,
  FILL_DELIVERY_CONFIRMATION_STATUS_WILL_PICK_UP,
} from '../../../constants';
import { styles } from '../task-detail-styles';
import { FillDeliveryConfirmationOrderDetails } from '../../shipping/fill-delivery-confirmation-order-details';

class EditFillDeliveryConfirmation extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.state = { selectValue: props.taskStatus };
  }

  // handleSelect(e) {
  //   this.setState({
  //     selectValue: e.target.value,
  //   });
  // }

  // Handles the submit functionality. Right now it sets some fields to null for simplicity's sake
  handleSubmit(values) {
    const {
      editFillDeliveryConfirmation, // eslint-disable-line
      fetchTaskCounts, // eslint-disable-line
      fetchResourceLink, // eslint-disable-line
      workListChanged, // eslint-disable-line
      task,
      // cancel,
      statuses,
      update,
      reset,
      selectedStatus,
      addNote, // eslint-disable-line
      selectedPatientId,
      tagTypeId,
      tagResourceId,
      updateTherapy, // eslint-disable-line
    } = this.props;
    const payload = {
      ...values,
      id: task.id,
      service_group_id: task.service_group_id,
      patient_id: selectedPatientId,
      additional_reason: values.additional_reason,
      completed_dt: convertToArborDate(values.completed_dt).getUtcDatetime(),
      followup_dt: values.followup_dt
        ? convertToArborDate(values.followup_dt).getUtcDatetime()
        : null,
      last_checked_dt: convertToArborDate(values.last_checked_dt).getUtcDatetime(),
      status_id: getReasonsByStatus(statuses, selectedStatus)[0].label
        ? values.status_id
        : getReasonsByStatus(statuses, selectedStatus)[0].value,
      therapy_id: task.therapy_id,
      shipped_date: convertToArborDate(values.shipped_date).getUtcDate(),
      pick_up_date: convertToArborDate(values.pick_up_date).getUtcDate(),
      delivered_date: convertToArborDate(values.delivered_date).getUtcDate(),
      picked_up_date: convertToArborDate(values.picked_up_date).getUtcDate(),
      delivery_exception_date: convertToArborDate(values.delivery_exception_date).getUtcDate(),
      canceled_date: convertToArborDate(values.canceled_date).getUtcDate(),
      is_internal: values.is_internal,
      reporting_party_id: values.reporting_party_id,
      internal_responsibles:
        Array.isArray(values.internal_responsibles) &&
        values.internal_responsibles.map(({ value }) => value).join(','),
      external_responsibles:
        Array.isArray(values.external_responsibles) &&
        values.external_responsibles.map(({ value }) => value).join(','),
    };

    if (values.note_text) {
      const newValues = parseNoteToDb(values);
      const tags = [{ tag_type_id: tagTypeId, resource_id: tagResourceId }];

      const payloadNotes = {
        patient_id: selectedPatientId,
        note_type_id: 1,
        note_text: newValues.note_text,
        is_pinned: 0,
        mentions: newValues.mentions,
        tags,
      };

      addNote(payloadNotes);
    }
    editFillDeliveryConfirmation(addTaskOldValues(payload, task)).then(() => {
      workListChanged();
      fetchTaskCounts();
      fetchResourceLink();
      update();
      reset();
    });
  }

  render() {
    const {
      handleSubmit,
      submitting,
      classes,
      cancel,
      task,
      users,
      selectedStatus,
      incidentExternalResponsibles,
      incidentReportingParties,
      formValues,
    } = this.props;

    const userData = users && users.length > 0 && translateUsers(users);

    const renderInformationFdc = () => {
      return (
        <div className={classes.container}>
          <FillDeliveryConfirmationOrderDetails orderId={task.order_id} statusId={task.status_id} />
        </div>
      );
    };

    return (
      <Grid
        container
        justifyContent="flex-start"
        className={(classes.formRowContainer, classes.formContainer)}
      >
        <Grid item xs={12}>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            className={classes.editFormContainer}
            autoComplete="off"
          >
            {selectedStatus === FILL_DELIVERY_CONFIRMATION_STATUS_SHIPPED &&
              selectedStatus !== task.status && (
                <>
                  <Grid container className={classes.formRowContainer}>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="followup_dt"
                        label="Follow up Date *"
                        component={renderDatePicker}
                        validate={[validateDate, required]}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="last_checked_dt"
                        label="Last Checked Date"
                        component={renderDatePicker}
                        validate={[validateDate]}
                      />
                    </Grid>
                    <Grid item lg={3} className={classes.fieldContainer}>
                      <Field
                        name="shipped_date"
                        component={renderDatePicker}
                        label="Shipped Date *"
                        validate={[validateDate, required]}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field name="note_text" data={userData} component={NoteArea} />
                    </Grid>
                  </Grid>
                  {renderInformationFdc()}
                </>
              )}
            {selectedStatus === FILL_DELIVERY_CONFIRMATION_STATUS_PICK_UP &&
              selectedStatus !== task.status && (
                <>
                  <Grid container className={classes.formRowContainer}>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="followup_dt"
                        label="Follow up Date *"
                        component={renderDatePicker}
                        validate={[validateDate, required]}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="last_checked_dt"
                        label="Last Checked Date"
                        component={renderDatePicker}
                        validate={[validateDate]}
                      />
                    </Grid>
                    <Grid item lg={3} className={classes.fieldContainer}>
                      <Field
                        name="pick_up_date"
                        component={renderDatePicker}
                        label="Pick up Date *"
                        validate={[validateDate, required]}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field name="note_text" data={userData} component={NoteArea} />
                    </Grid>
                  </Grid>
                  {renderInformationFdc()}
                </>
              )}
            {selectedStatus === FILL_DELIVERY_CONFIRMATION_STATUS_WILL_PICK_UP &&
              selectedStatus !== task.status && (
                <>
                  <Grid container className={classes.formRowContainer}>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="followup_dt"
                        label="Follow up Date *"
                        component={renderDatePicker}
                        validate={[validateDate, required]}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="last_checked_dt"
                        label="Last Checked Date"
                        component={renderDatePicker}
                        validate={[validateDate]}
                      />
                    </Grid>
                    <Grid item xs={3} />
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field name="note_text" data={userData} component={NoteArea} />
                    </Grid>
                  </Grid>
                  {renderInformationFdc()}
                </>
              )}
            {selectedStatus === FILL_DELIVERY_CONFIRMATION_STATUS_DELIVERY_EXCEPTION &&
              selectedStatus !== task.status && (
                <>
                  <Grid container className={classes.formRowContainer}>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="followup_dt"
                        label="Follow up Date *"
                        component={renderDatePicker}
                        validate={[validateDate, required]}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="last_checked_dt"
                        label="Last Checked Date"
                        component={renderDatePicker}
                        validate={[validateDate]}
                      />
                    </Grid>
                    <Grid item lg={3} className={classes.fieldContainer}>
                      <Field
                        name="delivery_exception_date"
                        component={renderDatePicker}
                        label="Delivery Exception Date *"
                        validate={[validateDate, required]}
                      />
                    </Grid>
                    <Grid item lg={3} />
                    <Grid item xs={2} className={classes.fieldContainer}>
                      <Field name="is_internal" label="Internal" component={renderCheckbox} />
                    </Grid>
                    <Grid item xs={5} className={classes.fieldContainer}>
                      <Field
                        name="reporting_party_id"
                        label="Reporting Party  *"
                        validate={[required]}
                        component={renderDropdown}
                        fields={dropdownFormater(incidentReportingParties)}
                      />
                    </Grid>
                    {formValues.is_internal ? (
                      <Grid item xs={5} className={classes.fieldContainer}>
                        <Field
                          id="internal_responsibles"
                          name="internal_responsibles"
                          label="Responsible Party *"
                          fields={dropdownFormater(users, {
                            name: 'display_name',
                          })}
                          validate={[required]}
                          component={ReactSelectForRedux}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={5} className={classes.fieldContainer}>
                        <Field
                          id="external_responsibles"
                          name="external_responsibles"
                          label="Responsible Party *"
                          fields={dropdownFormater(incidentExternalResponsibles)}
                          validate={[required]}
                          component={ReactSelectForRedux}
                        />
                      </Grid>
                    )}

                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field name="note_text" data={userData} component={NoteArea} />
                    </Grid>
                  </Grid>
                  {renderInformationFdc()}
                </>
              )}
            {selectedStatus === FILL_DELIVERY_CONFIRMATION_STATUS_CANCELED &&
              selectedStatus !== task.status && (
                <>
                  <Grid container className={classes.formRowContainer}>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="followup_dt"
                        label="Follow up Date *"
                        component={renderDatePicker}
                        validate={[validateDate, required]}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="last_checked_dt"
                        label="Last Checked Date"
                        component={renderDatePicker}
                        validate={[validateDate]}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="additional_reason"
                        label="Reason"
                        component={renderTextField}
                        validate={[required]}
                      />
                    </Grid>
                    <Grid item lg={3} className={classes.fieldContainer}>
                      <Field
                        name="canceled_date"
                        component={renderDatePicker}
                        label="Canceled Date *"
                        validate={[validateDate, required]}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field name="note_text" data={userData} component={NoteArea} />
                    </Grid>
                  </Grid>
                  {renderInformationFdc()}
                </>
              )}
            {selectedStatus === FILL_DELIVERY_CONFIRMATION_STATUS_DELIVERED &&
              selectedStatus !== task.status && (
                <>
                  <Grid container className={classes.formRowContainer}>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="followup_dt"
                        label="Follow up Date *"
                        component={renderDatePicker}
                        validate={[validateDate, required]}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="last_checked_dt"
                        label="Last Checked Date"
                        component={renderDatePicker}
                        validate={[validateDate]}
                      />
                    </Grid>
                    <Grid item lg={3} className={classes.fieldContainer}>
                      <Field
                        name="delivered_date"
                        component={renderDatePicker}
                        label="Delivered Date *"
                        validate={[validateDate, required]}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field name="note_text" data={userData} component={NoteArea} />
                    </Grid>
                  </Grid>
                  {renderInformationFdc()}
                </>
              )}
            {selectedStatus === FILL_DELIVERY_CONFIRMATION_STATUS_PICKED_UP &&
              selectedStatus !== task.status && (
                <>
                  <Grid container className={classes.formRowContainer}>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="followup_dt"
                        label="Follow up Date *"
                        component={renderDatePicker}
                        validate={[validateDate, required]}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="last_checked_dt"
                        label="Last Checked Date"
                        component={renderDatePicker}
                        validate={[validateDate]}
                      />
                    </Grid>
                    <Grid item lg={3} className={classes.fieldContainer}>
                      <Field
                        name="picked_up_date"
                        component={renderDatePicker}
                        label="Picked up Date *"
                        validate={[validateDate, required]}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field name="note_text" data={userData} component={NoteArea} />
                    </Grid>
                  </Grid>
                  {renderInformationFdc()}
                </>
              )}
            {selectedStatus !== task.status && (
              <ConfirmationPanel
                cancelButtonName="pa_status_cancel_button"
                submitButtonName="pa_status_submit_button"
                handleCancel={cancel}
                disableSubmit={submitting}
              />
            )}
          </form>
        </Grid>
      </Grid>
    );
  }
}

function handleFormName(task) {
  if (!task) {
    return null;
  }
  const name = `${EDIT_FILL_DELIVERY_CONFIRMATION_FORM}_${task.therapy_id}_${task.id}`;
  return name;
}

function mapStateToProps(state, props) {
  const { selectedPatientId, therapies, lookups } = state;
  const name = handleFormName(props.task);
  const therapy = therapies && therapies[props.task.therapy_id];
  const formValues = getFormValues(name)(state) || {};
  const { incidentReportingParties, incidentExternalResponsibles, users } = lookups;
  return {
    selectedPatientId,
    lookups,
    incidentExternalResponsibles,
    incidentReportingParties,
    form: name,
    users,
    formValues,
    therapy,
    initialValues: {
      additional_reason: props.task.additional_reason ? props.task.additional_reason : '',
      completed_dt: convertToArborDate(props.task.completed_dt, true).getCustomerDatetime(),
      followup_dt: convertToArborDate(props.task.followup_dt, true).getCustomerDate(true),
      last_checked_dt: convertToArborDate(props.task.last_checked_dt, true).getCustomerDate(true),
      shipped_date: convertToArborDate(props.task.shipped_date, true).getUtcDate(true),
      pick_up_date: convertToArborDate(props.task.pick_up_date, true).getUtcDate(true),
      delivered_date: convertToArborDate(props.task.delivered_date, true).getUtcDate(true),
      picked_up_date: convertToArborDate(props.task.picked_up_date, true).getUtcDate(true),
      delivery_exception_date: convertToArborDate(
        props.task.delivery_exception_date,
        true,
      ).getUtcDate(true),
      canceled_date: convertToArborDate(props.task.canceled_date, true).getUtcDate(true),
      status_id: props.task.status_id,
      is_internal: false,
    },
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    editFillDeliveryConfirmation,
    fetchTaskCounts,
    fetchResourceLink,
    workListChanged,
    addNote,
    change,
    updateTherapy,
  }),
)(reduxForm({})(EditFillDeliveryConfirmation));

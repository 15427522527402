import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { expandTheme } from 'utils/theme-util';

const styles = (theme: Theme) => {
  const { palette, fonts } = expandTheme(theme);

  return createStyles({
    tableHeaderCell: {
      color: palette.primary.darkGrey,
      fontSize: fonts.mediumFontSize,
      fontWeight: 'bold',
    },
    parentRowCell: {
      color: palette.primary.steelGrey,
      fontSize: fonts.mediumFontSize,
      fontWeight: 'bold',
    },
    childRowCell: {
      color: palette.primary.steelGrey,
      fontSize: fonts.smallFontSize,
    },
  });
};

type Styles = WithStyles<typeof styles>;

export const useStyles = makeStyles(styles);

// Return the useStyles hooks result, but augmented with styles types
export const useTypedStyles = (): Styles => ({
  classes: useStyles(),
});

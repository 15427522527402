import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { ModalUtil } from 'services/utils/modal.util';
import { expandTheme } from 'utils/theme-util';

export const ArStyles = (theme: Theme) => {
  const { palette, font } = expandTheme(theme);
  return createStyles({
    button: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      minWidth: theme.spacing(17),
      marginLeft: theme.spacing(1),
    },
    selectedButton: {
      backgroundColor: palette.primary.teal,
      color: palette.primary.white,
    },
    searchField: {
      width: theme.spacing(33),
    },
    searchIcon: {
      color: theme.palette.grey[500],
    },
    textFieldEndAdornment: {
      cursor: 'pointer',
    },
    buttonGrid: {
      marginLeft: theme.spacing(1.5),
    },
    modalButton: {
      marginLeft: theme.spacing(3),
      backgroundColor: palette.primary.teal,
      color: palette.primary.white,
    },
    confirmationModal: {
      ...ModalUtil.BasicModalStyling(theme, 50, 3),
      maxHeight: '60vh',
    },
    actionButton: {
      backgroundColor: palette.primary.trellisBlue,
      color: palette.primary.white,
    },
    modal: {
      ...ModalUtil.BasicModalStyling(theme, 80, 3),
      width: theme.spacing(80),
    },
    labelContainer: {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      width: theme.spacing(400),
    },
    dividerMargin: {
      marginBottom: theme.spacing(1),
    },
    controller: {
      marginTop: theme.spacing(2),
    },
    subtitle: {
      marginTop: theme.spacing(2),
    },
    headerText: {
      fontSize: font.largeFontSize,
      fontWeight: 'bold',
    },
    subtitleText: {
      fontSize: font.smallFontSize,
    },
    labelContainerLeft: {
      paddingRight: theme.spacing(3),
    },
  });
};

import { UserRoles } from 'constants/enums';
import { IUser } from 'interfaces/redux/ILookups';
import { DropDownItem, TypeArrItem } from './types';

const defaultQreValue = {
  is_external: false,
  is_medication_error: false,
  is_hipaa_breach: null,
  reporting_party_id: null,
};

export const INCIDENT_ERROR_TYPE_OTHER = 3;

const responsiblePartiesRolesMap: Record<string, UserRoles[]> = {
  'Pharmacist': [UserRoles.NonCredentialedPharmacist, UserRoles.CredentialedPharmacist],
  'Technician': [UserRoles.NonCredentialedLiaison, UserRoles.CredentialedLiaison],
  'Clinic Staff': [
    UserRoles.CredentialedLiaison,
    UserRoles.SiteManager,
    UserRoles.CredentialedPharmacist,
    UserRoles.NonCredentialedPharmacist,
    UserRoles.NonCredentialedLiaison,
    UserRoles.PharmacistCOM,
    UserRoles.NonPharmacistCOM,
    UserRoles.NonPharmacistGM,
    UserRoles.PharmacyIntern,
    UserRoles.PharmacistClinic,
    UserRoles.PharmacistCentralOps,
    UserRoles.PharmacistCentralSupport,
    UserRoles.PharmacistDispensing,
    UserRoles.LiaisonClinic,
    UserRoles.LiaisonCentralOps,
    UserRoles.LiaisonDispensing,
    UserRoles.LiaisonDispensing,
    UserRoles.LiaisonTrainer,
    UserRoles.LiaisonAT,
    UserRoles.ManagerAT,
  ],
};

export const showDescriptionField = (INCIDENT_TYPES: any, typeId: number) => {
  return [INCIDENT_TYPES.OTHER, INCIDENT_TYPES.BENEFITS_INVESTIGATION].includes(typeId);
};

export const getContextMappingAll = (INCIDENT_TYPES: any): any => {
  const incidentTypesArr = Object.values(INCIDENT_TYPES);
  return {
    1: {
      types: incidentTypesArr,
      default: defaultQreValue,
    },
    2: {
      types: incidentTypesArr,
      default: defaultQreValue,
    },
    11: {
      types: incidentTypesArr,
      default: defaultQreValue,
    },
    12: {
      types: incidentTypesArr,
      default: defaultQreValue,
    },
    15: {
      types: incidentTypesArr,
      default: defaultQreValue,
    },
    16: {
      types: incidentTypesArr,
      default: defaultQreValue,
    },
    17: {
      types: incidentTypesArr,
      default: defaultQreValue,
    },
    18: {
      types: incidentTypesArr,
      default: defaultQreValue,
    },
    19: {
      types: incidentTypesArr,
      default: defaultQreValue,
    },
    20: {
      types: incidentTypesArr,
      default: defaultQreValue,
    },
    21: {
      types: incidentTypesArr,
      default: defaultQreValue,
    },
    22: {
      types: incidentTypesArr,
      default: defaultQreValue,
    },
    25: {
      types: incidentTypesArr,
      default: defaultQreValue,
    },
    26: {
      types: incidentTypesArr,
      default: defaultQreValue,
    },
    27: {
      types: incidentTypesArr,
      default: defaultQreValue,
    },
  };
};

export const getContextMapping = (INCIDENT_TYPES: any): any => ({
  // Patient
  1: {
    types: [
      INCIDENT_TYPES.INCORRECT_PERSONAL_INFORMATION,
      INCIDENT_TYPES.INCORRECT_ADDRESS_INPUT_BY_PHARMACY,
      INCIDENT_TYPES.INCORRECT_INCOME_BENEFITS,
      INCIDENT_TYPES.BENEFITS_INVESTIGATION,
      INCIDENT_TYPES.INCORRECT_DELIVERY_ADDRESS_SELECTED_PATIENT_HAS_MULTIPLE_ADDRESS_ON_FILE_,
      INCIDENT_TYPES.CORRECT_ADDRESS_INPUT_DELIVERED_BY_SHIPPER_TO_WRONG_ADDRESS,
      INCIDENT_TYPES.INCORRECT_DRUG_PRODUCT_DISPENSED,
      INCIDENT_TYPES.INCORRECT_RECIPIENT_SELECTED_BY_PHARMACY,
      INCIDENT_TYPES.INCORRECT_STRENGTH,
      INCIDENT_TYPES.INCORRECT_DOSAGE_FORM,
      INCIDENT_TYPES.INCORRECT_INSTRUCTIONS,
      INCIDENT_TYPES.INCORRECT_QUANTITY,
      INCIDENT_TYPES.UNACCEPTABLE_MEDICATIONS,
      INCIDENT_TYPES.MISSING_SUPPLIES_NEEDED_FOR_ADMINISTRATION,
      INCIDENT_TYPES.INCORRECT_PRESCRIPTION,
      INCIDENT_TYPES.INCORRECT_PHARMACY_LABEL,
      INCIDENT_TYPES.PACKAGING_ISSUE,
      INCIDENT_TYPES.INCORRECT_PACKAGING,
      INCIDENT_TYPES.PRESCRIPTION_ADJUDICATION,
      INCIDENT_TYPES.BILLING_INSURANCE,
      INCIDENT_TYPES.PAYMENT,
      INCIDENT_TYPES.PRODUCT_NOT_FULFILLED_CORRECTLY,
      INCIDENT_TYPES.REMS_NOT_FOLLOWED_CORRECTLY,
      INCIDENT_TYPES.NOTICE_OF_PRIVACY_PRACTICE,
      INCIDENT_TYPES.WELCOME_KITS,
      INCIDENT_TYPES.PHONE,
      INCIDENT_TYPES.COMPUTER,
      INCIDENT_TYPES.SOFTWARE,
      INCIDENT_TYPES.PACKAGE_DAMAGED_TAMPERED_IN_TRANSIT,
      INCIDENT_TYPES.COLD_CHAIN_FAILURE_PACKAGING_FAILED,
      INCIDENT_TYPES.INCORRECT_PAYMENT_METHOD_BILLED,
      INCIDENT_TYPES.NO_SIGNATURE_AVAILABLE,
      INCIDENT_TYPES.PACKAGE_LOST_IN_TRANSIT,
      INCIDENT_TYPES.STOLEN_PACKAGE,
      INCIDENT_TYPES.LATE_DELIVERY_WEATHER_DELAY,
      INCIDENT_TYPES.LATE_DELIVERY_VOLUME_SURGE,
      INCIDENT_TYPES.DISPENSING_ACCURACY,
      INCIDENT_TYPES.OTHER,
      INCIDENT_TYPES.INCORRECT_PATIENT,
      INCIDENT_TYPES.SERVICE_ENROLLMENT_INCOMPLETE,
      INCIDENT_TYPES.FAILED_TO_VERIFY_MEDLIST,
      INCIDENT_TYPES.FAILED_TO_VERIFY_INSURANCE,
      INCIDENT_TYPES.CORRECT_ADDRESS_PATIENT_UNAVAILABLE_OR_NO_ACCESS,
    ],
    default: defaultQreValue,
  },
  // Therapy
  2: {
    types: [
      INCIDENT_TYPES.INCORRECT_THERAPY_INFORMATION,
      INCIDENT_TYPES.INCORRECT_DELIVERY_ADDRESS_SELECTED_PATIENT_HAS_MULTIPLE_ADDRESS_ON_FILE_,
      INCIDENT_TYPES.CORRECT_ADDRESS_INPUT_DELIVERED_BY_SHIPPER_TO_WRONG_ADDRESS,
      INCIDENT_TYPES.INCORRECT_DRUG_PRODUCT_DISPENSED,
      INCIDENT_TYPES.INCORRECT_RECIPIENT_SELECTED_BY_PHARMACY,
      INCIDENT_TYPES.INCORRECT_STRENGTH,
      INCIDENT_TYPES.INCORRECT_DOSAGE_FORM,
      INCIDENT_TYPES.INCORRECT_INSTRUCTIONS,
      INCIDENT_TYPES.INCORRECT_QUANTITY,
      INCIDENT_TYPES.UNACCEPTABLE_MEDICATIONS,
      INCIDENT_TYPES.MISSING_SUPPLIES_NEEDED_FOR_ADMINISTRATION,
      INCIDENT_TYPES.INCORRECT_PRESCRIPTION,
      INCIDENT_TYPES.INCORRECT_PHARMACY_LABEL,
      INCIDENT_TYPES.PACKAGING_ISSUE,
      INCIDENT_TYPES.INCORRECT_PACKAGING,
      INCIDENT_TYPES.PRESCRIPTION_ADJUDICATION,
      INCIDENT_TYPES.BILLING_INSURANCE,
      INCIDENT_TYPES.PAYMENT,
      INCIDENT_TYPES.PRODUCT_NOT_FULFILLED_CORRECTLY,
      INCIDENT_TYPES.REMS_NOT_FOLLOWED_CORRECTLY,
      INCIDENT_TYPES.NOTICE_OF_PRIVACY_PRACTICE,
      INCIDENT_TYPES.WELCOME_KITS,
      INCIDENT_TYPES.PHONE,
      INCIDENT_TYPES.COMPUTER,
      INCIDENT_TYPES.SOFTWARE,
      INCIDENT_TYPES.PACKAGE_DAMAGED_TAMPERED_IN_TRANSIT,
      INCIDENT_TYPES.COLD_CHAIN_FAILURE_PACKAGING_FAILED,
      INCIDENT_TYPES.INCORRECT_PAYMENT_METHOD_BILLED,
      INCIDENT_TYPES.NO_SIGNATURE_AVAILABLE,
      INCIDENT_TYPES.PACKAGE_LOST_IN_TRANSIT,
      INCIDENT_TYPES.STOLEN_PACKAGE,
      INCIDENT_TYPES.LATE_DELIVERY_WEATHER_DELAY,
      INCIDENT_TYPES.LATE_DELIVERY_VOLUME_SURGE,
      INCIDENT_TYPES.DISPENSING_ACCURACY,
      INCIDENT_TYPES.OTHER,
      INCIDENT_TYPES.INCORRECT_PATIENT,
      INCIDENT_TYPES.SERVICE_ENROLLMENT_INCOMPLETE,
      INCIDENT_TYPES.FAILED_TO_VERIFY_MEDLIST,
      INCIDENT_TYPES.FAILED_TO_VERIFY_INSURANCE,
      INCIDENT_TYPES.CORRECT_ADDRESS_PATIENT_UNAVAILABLE_OR_NO_ACCESS,
    ],
    default: defaultQreValue,
  },
  // PA
  11: {
    types: [
      INCIDENT_TYPES.SUBMITTED_TO_INCORRECT_PAYOR,
      INCIDENT_TYPES.PA_NOT_COMPLETED_ACCURATELY,
      INCIDENT_TYPES.INCOMPLETE_INFORMATION_SUBMITTED,
      INCIDENT_TYPES.INCORRECT_DELIVERY_ADDRESS_SELECTED_PATIENT_HAS_MULTIPLE_ADDRESS_ON_FILE_,
      INCIDENT_TYPES.CORRECT_ADDRESS_INPUT_DELIVERED_BY_SHIPPER_TO_WRONG_ADDRESS,
      INCIDENT_TYPES.INCORRECT_DRUG_PRODUCT_DISPENSED,
      INCIDENT_TYPES.INCORRECT_RECIPIENT_SELECTED_BY_PHARMACY,
      INCIDENT_TYPES.INCORRECT_STRENGTH,
      INCIDENT_TYPES.INCORRECT_DOSAGE_FORM,
      INCIDENT_TYPES.INCORRECT_INSTRUCTIONS,
      INCIDENT_TYPES.INCORRECT_QUANTITY,
      INCIDENT_TYPES.UNACCEPTABLE_MEDICATIONS,
      INCIDENT_TYPES.MISSING_SUPPLIES_NEEDED_FOR_ADMINISTRATION,
      INCIDENT_TYPES.INCORRECT_PRESCRIPTION,
      INCIDENT_TYPES.INCORRECT_PHARMACY_LABEL,
      INCIDENT_TYPES.PACKAGING_ISSUE,
      INCIDENT_TYPES.INCORRECT_PACKAGING,
      INCIDENT_TYPES.PRESCRIPTION_ADJUDICATION,
      INCIDENT_TYPES.BILLING_INSURANCE,
      INCIDENT_TYPES.PAYMENT,
      INCIDENT_TYPES.PRODUCT_NOT_FULFILLED_CORRECTLY,
      INCIDENT_TYPES.REMS_NOT_FOLLOWED_CORRECTLY,
      INCIDENT_TYPES.NOTICE_OF_PRIVACY_PRACTICE,
      INCIDENT_TYPES.WELCOME_KITS,
      INCIDENT_TYPES.PHONE,
      INCIDENT_TYPES.COMPUTER,
      INCIDENT_TYPES.SOFTWARE,
      INCIDENT_TYPES.PACKAGE_DAMAGED_TAMPERED_IN_TRANSIT,
      INCIDENT_TYPES.COLD_CHAIN_FAILURE_PACKAGING_FAILED,
      INCIDENT_TYPES.INCORRECT_PAYMENT_METHOD_BILLED,
      INCIDENT_TYPES.NO_SIGNATURE_AVAILABLE,
      INCIDENT_TYPES.PACKAGE_LOST_IN_TRANSIT,
      INCIDENT_TYPES.STOLEN_PACKAGE,
      INCIDENT_TYPES.LATE_DELIVERY_WEATHER_DELAY,
      INCIDENT_TYPES.LATE_DELIVERY_VOLUME_SURGE,
      INCIDENT_TYPES.DISPENSING_ACCURACY,
      INCIDENT_TYPES.OTHER,
      INCIDENT_TYPES.INCORRECT_PATIENT,
      INCIDENT_TYPES.SERVICE_ENROLLMENT_INCOMPLETE,
      INCIDENT_TYPES.FAILED_TO_VERIFY_MEDLIST,
      INCIDENT_TYPES.FAILED_TO_VERIFY_INSURANCE,
      INCIDENT_TYPES.CORRECT_ADDRESS_PATIENT_UNAVAILABLE_OR_NO_ACCESS,
    ],
    default: defaultQreValue,
  },
  // FA
  12: {
    types: [
      INCIDENT_TYPES.PA_NOT_COMPLETED_ACCURATELY,
      INCIDENT_TYPES.INCOMPLETE_INFORMATION_SUBMITTED,
      INCIDENT_TYPES.INCORRECTLY_ASSESSED_AVAILABILITY_OF_FUNDING,
      INCIDENT_TYPES.INCORRECT_DELIVERY_ADDRESS_SELECTED_PATIENT_HAS_MULTIPLE_ADDRESS_ON_FILE_,
      INCIDENT_TYPES.CORRECT_ADDRESS_INPUT_DELIVERED_BY_SHIPPER_TO_WRONG_ADDRESS,
      INCIDENT_TYPES.INCORRECT_DRUG_PRODUCT_DISPENSED,
      INCIDENT_TYPES.INCORRECT_RECIPIENT_SELECTED_BY_PHARMACY,
      INCIDENT_TYPES.INCORRECT_STRENGTH,
      INCIDENT_TYPES.INCORRECT_DOSAGE_FORM,
      INCIDENT_TYPES.INCORRECT_INSTRUCTIONS,
      INCIDENT_TYPES.INCORRECT_QUANTITY,
      INCIDENT_TYPES.UNACCEPTABLE_MEDICATIONS,
      INCIDENT_TYPES.MISSING_SUPPLIES_NEEDED_FOR_ADMINISTRATION,
      INCIDENT_TYPES.INCORRECT_PRESCRIPTION,
      INCIDENT_TYPES.INCORRECT_PHARMACY_LABEL,
      INCIDENT_TYPES.PACKAGING_ISSUE,
      INCIDENT_TYPES.INCORRECT_PACKAGING,
      INCIDENT_TYPES.PRESCRIPTION_ADJUDICATION,
      INCIDENT_TYPES.BILLING_INSURANCE,
      INCIDENT_TYPES.PAYMENT,
      INCIDENT_TYPES.PRODUCT_NOT_FULFILLED_CORRECTLY,
      INCIDENT_TYPES.REMS_NOT_FOLLOWED_CORRECTLY,
      INCIDENT_TYPES.NOTICE_OF_PRIVACY_PRACTICE,
      INCIDENT_TYPES.WELCOME_KITS,
      INCIDENT_TYPES.PHONE,
      INCIDENT_TYPES.COMPUTER,
      INCIDENT_TYPES.SOFTWARE,
      INCIDENT_TYPES.PACKAGE_DAMAGED_TAMPERED_IN_TRANSIT,
      INCIDENT_TYPES.COLD_CHAIN_FAILURE_PACKAGING_FAILED,
      INCIDENT_TYPES.INCORRECT_PAYMENT_METHOD_BILLED,
      INCIDENT_TYPES.NO_SIGNATURE_AVAILABLE,
      INCIDENT_TYPES.PACKAGE_LOST_IN_TRANSIT,
      INCIDENT_TYPES.STOLEN_PACKAGE,
      INCIDENT_TYPES.LATE_DELIVERY_WEATHER_DELAY,
      INCIDENT_TYPES.LATE_DELIVERY_VOLUME_SURGE,
      INCIDENT_TYPES.DISPENSING_ACCURACY,
      INCIDENT_TYPES.OTHER,
      INCIDENT_TYPES.INCORRECT_PATIENT,
      INCIDENT_TYPES.SERVICE_ENROLLMENT_INCOMPLETE,
      INCIDENT_TYPES.FAILED_TO_VERIFY_MEDLIST,
      INCIDENT_TYPES.FAILED_TO_VERIFY_INSURANCE,
      INCIDENT_TYPES.CORRECT_ADDRESS_PATIENT_UNAVAILABLE_OR_NO_ACCESS,
    ],
    default: defaultQreValue,
  },
  // QRE
  25: {
    types: [
      INCIDENT_TYPES.INCORRECT_THERAPY_INFORMATION,
      INCIDENT_TYPES.INCORRECT_DELIVERY_ADDRESS_SELECTED_PATIENT_HAS_MULTIPLE_ADDRESS_ON_FILE_,
      INCIDENT_TYPES.CORRECT_ADDRESS_INPUT_DELIVERED_BY_SHIPPER_TO_WRONG_ADDRESS,
      INCIDENT_TYPES.INCORRECT_DRUG_PRODUCT_DISPENSED,
      INCIDENT_TYPES.INCORRECT_RECIPIENT_SELECTED_BY_PHARMACY,
      INCIDENT_TYPES.INCORRECT_STRENGTH,
      INCIDENT_TYPES.INCORRECT_DOSAGE_FORM,
      INCIDENT_TYPES.INCORRECT_INSTRUCTIONS,
      INCIDENT_TYPES.INCORRECT_QUANTITY,
      INCIDENT_TYPES.UNACCEPTABLE_MEDICATIONS,
      INCIDENT_TYPES.MISSING_SUPPLIES_NEEDED_FOR_ADMINISTRATION,
      INCIDENT_TYPES.INCORRECT_PRESCRIPTION,
      INCIDENT_TYPES.INCORRECT_PHARMACY_LABEL,
      INCIDENT_TYPES.PACKAGING_ISSUE,
      INCIDENT_TYPES.INCORRECT_PACKAGING,
      INCIDENT_TYPES.PRESCRIPTION_ADJUDICATION,
      INCIDENT_TYPES.BILLING_INSURANCE,
      INCIDENT_TYPES.PAYMENT,
      INCIDENT_TYPES.PRODUCT_NOT_FULFILLED_CORRECTLY,
      INCIDENT_TYPES.REMS_NOT_FOLLOWED_CORRECTLY,
      INCIDENT_TYPES.NOTICE_OF_PRIVACY_PRACTICE,
      INCIDENT_TYPES.WELCOME_KITS,
      INCIDENT_TYPES.PHONE,
      INCIDENT_TYPES.COMPUTER,
      INCIDENT_TYPES.SOFTWARE,
      INCIDENT_TYPES.PACKAGE_DAMAGED_TAMPERED_IN_TRANSIT,
      INCIDENT_TYPES.COLD_CHAIN_FAILURE_PACKAGING_FAILED,
      INCIDENT_TYPES.INCORRECT_PAYMENT_METHOD_BILLED,
      INCIDENT_TYPES.NO_SIGNATURE_AVAILABLE,
      INCIDENT_TYPES.PACKAGE_LOST_IN_TRANSIT,
      INCIDENT_TYPES.STOLEN_PACKAGE,
      INCIDENT_TYPES.LATE_DELIVERY_WEATHER_DELAY,
      INCIDENT_TYPES.LATE_DELIVERY_VOLUME_SURGE,
      INCIDENT_TYPES.DISPENSING_ACCURACY,
      INCIDENT_TYPES.OTHER,
      INCIDENT_TYPES.INCORRECT_PATIENT,
      INCIDENT_TYPES.SERVICE_ENROLLMENT_INCOMPLETE,
      INCIDENT_TYPES.FAILED_TO_VERIFY_MEDLIST,
      INCIDENT_TYPES.FAILED_TO_VERIFY_INSURANCE,
      INCIDENT_TYPES.CORRECT_ADDRESS_PATIENT_UNAVAILABLE_OR_NO_ACCESS,
    ],
    default: defaultQreValue,
  },
  // DUR
  26: {
    types: [
      INCIDENT_TYPES.INCORRECT_PATIENT_INFORMATION,
      INCIDENT_TYPES.INCOMPLETE_PATIENT_INFORMATION,
      INCIDENT_TYPES.SYSTEM_DID_NOT_APPROPRIATELY_ALERT,
      INCIDENT_TYPES.RPH_INAPPROPRIATELY_OVERRODE_ALERT,
      INCIDENT_TYPES.INCORRECT_DELIVERY_ADDRESS_SELECTED_PATIENT_HAS_MULTIPLE_ADDRESS_ON_FILE_,
      INCIDENT_TYPES.CORRECT_ADDRESS_INPUT_DELIVERED_BY_SHIPPER_TO_WRONG_ADDRESS,
      INCIDENT_TYPES.INCORRECT_DRUG_PRODUCT_DISPENSED,
      INCIDENT_TYPES.INCORRECT_RECIPIENT_SELECTED_BY_PHARMACY,
      INCIDENT_TYPES.INCORRECT_STRENGTH,
      INCIDENT_TYPES.INCORRECT_DOSAGE_FORM,
      INCIDENT_TYPES.INCORRECT_INSTRUCTIONS,
      INCIDENT_TYPES.INCORRECT_QUANTITY,
      INCIDENT_TYPES.UNACCEPTABLE_MEDICATIONS,
      INCIDENT_TYPES.MISSING_SUPPLIES_NEEDED_FOR_ADMINISTRATION,
      INCIDENT_TYPES.INCORRECT_PRESCRIPTION,
      INCIDENT_TYPES.INCORRECT_PHARMACY_LABEL,
      INCIDENT_TYPES.PACKAGING_ISSUE,
      INCIDENT_TYPES.INCORRECT_PACKAGING,
      INCIDENT_TYPES.PRESCRIPTION_ADJUDICATION,
      INCIDENT_TYPES.BILLING_INSURANCE,
      INCIDENT_TYPES.PAYMENT,
      INCIDENT_TYPES.PRODUCT_NOT_FULFILLED_CORRECTLY,
      INCIDENT_TYPES.REMS_NOT_FOLLOWED_CORRECTLY,
      INCIDENT_TYPES.NOTICE_OF_PRIVACY_PRACTICE,
      INCIDENT_TYPES.WELCOME_KITS,
      INCIDENT_TYPES.PHONE,
      INCIDENT_TYPES.COMPUTER,
      INCIDENT_TYPES.SOFTWARE,
      INCIDENT_TYPES.PACKAGE_DAMAGED_TAMPERED_IN_TRANSIT,
      INCIDENT_TYPES.COLD_CHAIN_FAILURE_PACKAGING_FAILED,
      INCIDENT_TYPES.INCORRECT_PAYMENT_METHOD_BILLED,
      INCIDENT_TYPES.NO_SIGNATURE_AVAILABLE,
      INCIDENT_TYPES.PACKAGE_LOST_IN_TRANSIT,
      INCIDENT_TYPES.STOLEN_PACKAGE,
      INCIDENT_TYPES.LATE_DELIVERY_WEATHER_DELAY,
      INCIDENT_TYPES.LATE_DELIVERY_VOLUME_SURGE,
      INCIDENT_TYPES.DISPENSING_ACCURACY,
      INCIDENT_TYPES.OTHER,
      INCIDENT_TYPES.INCORRECT_PATIENT,
      INCIDENT_TYPES.SERVICE_ENROLLMENT_INCOMPLETE,
      INCIDENT_TYPES.FAILED_TO_VERIFY_MEDLIST,
      INCIDENT_TYPES.FAILED_TO_VERIFY_INSURANCE,
      INCIDENT_TYPES.CORRECT_ADDRESS_PATIENT_UNAVAILABLE_OR_NO_ACCESS,
    ],
    default: defaultQreValue,
  },
  // DC
  17: {
    types: [
      INCIDENT_TYPES.INCORRECT_DATA_COLLECTED,
      INCIDENT_TYPES.RPH_INAPPROPRIATELY_INTERPRETED_DATA,
      INCIDENT_TYPES.INCORRECT_DELIVERY_ADDRESS_SELECTED_PATIENT_HAS_MULTIPLE_ADDRESS_ON_FILE_,
      INCIDENT_TYPES.CORRECT_ADDRESS_INPUT_DELIVERED_BY_SHIPPER_TO_WRONG_ADDRESS,
      INCIDENT_TYPES.INCORRECT_DRUG_PRODUCT_DISPENSED,
      INCIDENT_TYPES.INCORRECT_RECIPIENT_SELECTED_BY_PHARMACY,
      INCIDENT_TYPES.INCORRECT_STRENGTH,
      INCIDENT_TYPES.INCORRECT_DOSAGE_FORM,
      INCIDENT_TYPES.INCORRECT_INSTRUCTIONS,
      INCIDENT_TYPES.INCORRECT_QUANTITY,
      INCIDENT_TYPES.UNACCEPTABLE_MEDICATIONS,
      INCIDENT_TYPES.MISSING_SUPPLIES_NEEDED_FOR_ADMINISTRATION,
      INCIDENT_TYPES.INCORRECT_PRESCRIPTION,
      INCIDENT_TYPES.INCORRECT_PHARMACY_LABEL,
      INCIDENT_TYPES.PACKAGING_ISSUE,
      INCIDENT_TYPES.INCORRECT_PACKAGING,
      INCIDENT_TYPES.PRESCRIPTION_ADJUDICATION,
      INCIDENT_TYPES.BILLING_INSURANCE,
      INCIDENT_TYPES.PAYMENT,
      INCIDENT_TYPES.PRODUCT_NOT_FULFILLED_CORRECTLY,
      INCIDENT_TYPES.REMS_NOT_FOLLOWED_CORRECTLY,
      INCIDENT_TYPES.NOTICE_OF_PRIVACY_PRACTICE,
      INCIDENT_TYPES.WELCOME_KITS,
      INCIDENT_TYPES.PHONE,
      INCIDENT_TYPES.COMPUTER,
      INCIDENT_TYPES.SOFTWARE,
      INCIDENT_TYPES.PACKAGE_DAMAGED_TAMPERED_IN_TRANSIT,
      INCIDENT_TYPES.COLD_CHAIN_FAILURE_PACKAGING_FAILED,
      INCIDENT_TYPES.INCORRECT_PAYMENT_METHOD_BILLED,
      INCIDENT_TYPES.NO_SIGNATURE_AVAILABLE,
      INCIDENT_TYPES.PACKAGE_LOST_IN_TRANSIT,
      INCIDENT_TYPES.STOLEN_PACKAGE,
      INCIDENT_TYPES.LATE_DELIVERY_WEATHER_DELAY,
      INCIDENT_TYPES.LATE_DELIVERY_VOLUME_SURGE,
      INCIDENT_TYPES.DISPENSING_ACCURACY,
      INCIDENT_TYPES.OTHER,
      INCIDENT_TYPES.INCORRECT_PATIENT,
      INCIDENT_TYPES.SERVICE_ENROLLMENT_INCOMPLETE,
      INCIDENT_TYPES.FAILED_TO_VERIFY_MEDLIST,
      INCIDENT_TYPES.FAILED_TO_VERIFY_INSURANCE,
      INCIDENT_TYPES.CORRECT_ADDRESS_PATIENT_UNAVAILABLE_OR_NO_ACCESS,
    ],
    default: defaultQreValue,
  },
  // FC
  15: {
    types: [
      INCIDENT_TYPES.DID_NOT_OFFER_DOCUMENT_REQUIRED_SUPPLIES,
      INCIDENT_TYPES.INCORRECT_ADDRESS_PROVIDED_BY_PATIENT,
      INCIDENT_TYPES.INCORRECT_DELIVERY_METHOD,
      INCIDENT_TYPES.INCORRECT_DELIVERY_DATE_OBTAINED_DOCUMENTED,
      INCIDENT_TYPES.INCORRECT_PAYMENT_METHOD_OBTAINED_DOCUMENTED,
      INCIDENT_TYPES.FC_NOT_COMPLETED_BY_NEEDS_BY_DATE,
      INCIDENT_TYPES.DID_NOT_APPROPRIATELY_ASSESS_ADJUST_NEEDS_BY_DATE,
      INCIDENT_TYPES.INCORRECT_DELIVERY_ADDRESS_SELECTED_PATIENT_HAS_MULTIPLE_ADDRESS_ON_FILE_,
      INCIDENT_TYPES.CORRECT_ADDRESS_INPUT_DELIVERED_BY_SHIPPER_TO_WRONG_ADDRESS,
      INCIDENT_TYPES.INCORRECT_DRUG_PRODUCT_DISPENSED,
      INCIDENT_TYPES.INCORRECT_RECIPIENT_SELECTED_BY_PHARMACY,
      INCIDENT_TYPES.INCORRECT_STRENGTH,
      INCIDENT_TYPES.INCORRECT_DOSAGE_FORM,
      INCIDENT_TYPES.INCORRECT_INSTRUCTIONS,
      INCIDENT_TYPES.INCORRECT_QUANTITY,
      INCIDENT_TYPES.UNACCEPTABLE_MEDICATIONS,
      INCIDENT_TYPES.MISSING_SUPPLIES_NEEDED_FOR_ADMINISTRATION,
      INCIDENT_TYPES.INCORRECT_PRESCRIPTION,
      INCIDENT_TYPES.INCORRECT_PHARMACY_LABEL,
      INCIDENT_TYPES.PACKAGING_ISSUE,
      INCIDENT_TYPES.INCORRECT_PACKAGING,
      INCIDENT_TYPES.PRESCRIPTION_ADJUDICATION,
      INCIDENT_TYPES.BILLING_INSURANCE,
      INCIDENT_TYPES.PAYMENT,
      INCIDENT_TYPES.PRODUCT_NOT_FULFILLED_CORRECTLY,
      INCIDENT_TYPES.REMS_NOT_FOLLOWED_CORRECTLY,
      INCIDENT_TYPES.NOTICE_OF_PRIVACY_PRACTICE,
      INCIDENT_TYPES.WELCOME_KITS,
      INCIDENT_TYPES.PHONE,
      INCIDENT_TYPES.COMPUTER,
      INCIDENT_TYPES.SOFTWARE,
      INCIDENT_TYPES.PACKAGE_DAMAGED_TAMPERED_IN_TRANSIT,
      INCIDENT_TYPES.COLD_CHAIN_FAILURE_PACKAGING_FAILED,
      INCIDENT_TYPES.INCORRECT_PAYMENT_METHOD_BILLED,
      INCIDENT_TYPES.NO_SIGNATURE_AVAILABLE,
      INCIDENT_TYPES.PACKAGE_LOST_IN_TRANSIT,
      INCIDENT_TYPES.STOLEN_PACKAGE,
      INCIDENT_TYPES.LATE_DELIVERY_WEATHER_DELAY,
      INCIDENT_TYPES.LATE_DELIVERY_VOLUME_SURGE,
      INCIDENT_TYPES.DISPENSING_ACCURACY,
      INCIDENT_TYPES.OTHER,
      INCIDENT_TYPES.INCORRECT_PATIENT,
      INCIDENT_TYPES.SERVICE_ENROLLMENT_INCOMPLETE,
      INCIDENT_TYPES.FAILED_TO_VERIFY_MEDLIST,
      INCIDENT_TYPES.FAILED_TO_VERIFY_INSURANCE,
      INCIDENT_TYPES.CORRECT_ADDRESS_PATIENT_UNAVAILABLE_OR_NO_ACCESS,
    ],
    default: defaultQreValue,
  },
  // FDC
  18: {
    types: [
      INCIDENT_TYPES.SUPPLIES_OMITTED_IN_SHIPMENT,
      INCIDENT_TYPES.INCORRECT_DELIVERY_ADDRESS_SELECTED_PATIENT_HAS_MULTIPLE_ADDRESS_ON_FILE_,
      INCIDENT_TYPES.INCORRECT_SHIPPING_METHOD_SELECTED,
      INCIDENT_TYPES.PHARMACY_SELECTED_WRONG_DELIVERY_DATE,
      INCIDENT_TYPES.INCORRECT_PAYMENT_METHOD_SELECTED,
      INCIDENT_TYPES.FDC_NOT_COMPLETED_BY_DATE,
      INCIDENT_TYPES.LATE_DELIVERY_COURIER_CAUSED,
      INCIDENT_TYPES.PACKAGED_INCORRECTLY_BY_PHARMACY,
      INCIDENT_TYPES.CORRECT_ADDRESS_INPUT_DELIVERED_BY_SHIPPER_TO_WRONG_ADDRESS,
      INCIDENT_TYPES.INCORRECT_DRUG_PRODUCT_DISPENSED,
      INCIDENT_TYPES.INCORRECT_RECIPIENT_SELECTED_BY_PHARMACY,
      INCIDENT_TYPES.INCORRECT_STRENGTH,
      INCIDENT_TYPES.INCORRECT_DOSAGE_FORM,
      INCIDENT_TYPES.INCORRECT_INSTRUCTIONS,
      INCIDENT_TYPES.INCORRECT_QUANTITY,
      INCIDENT_TYPES.UNACCEPTABLE_MEDICATIONS,
      INCIDENT_TYPES.MISSING_SUPPLIES_NEEDED_FOR_ADMINISTRATION,
      INCIDENT_TYPES.INCORRECT_PRESCRIPTION,
      INCIDENT_TYPES.INCORRECT_PHARMACY_LABEL,
      INCIDENT_TYPES.PACKAGING_ISSUE,
      INCIDENT_TYPES.INCORRECT_PACKAGING,
      INCIDENT_TYPES.PRESCRIPTION_ADJUDICATION,
      INCIDENT_TYPES.BILLING_INSURANCE,
      INCIDENT_TYPES.PAYMENT,
      INCIDENT_TYPES.PRODUCT_NOT_FULFILLED_CORRECTLY,
      INCIDENT_TYPES.REMS_NOT_FOLLOWED_CORRECTLY,
      INCIDENT_TYPES.NOTICE_OF_PRIVACY_PRACTICE,
      INCIDENT_TYPES.WELCOME_KITS,
      INCIDENT_TYPES.PHONE,
      INCIDENT_TYPES.COMPUTER,
      INCIDENT_TYPES.SOFTWARE,
      INCIDENT_TYPES.PACKAGE_DAMAGED_TAMPERED_IN_TRANSIT,
      INCIDENT_TYPES.COLD_CHAIN_FAILURE_PACKAGING_FAILED,
      INCIDENT_TYPES.INCORRECT_PAYMENT_METHOD_BILLED,
      INCIDENT_TYPES.NO_SIGNATURE_AVAILABLE,
      INCIDENT_TYPES.PACKAGE_LOST_IN_TRANSIT,
      INCIDENT_TYPES.STOLEN_PACKAGE,
      INCIDENT_TYPES.LATE_DELIVERY_WEATHER_DELAY,
      INCIDENT_TYPES.LATE_DELIVERY_VOLUME_SURGE,
      INCIDENT_TYPES.DISPENSING_ACCURACY,
      INCIDENT_TYPES.OTHER,
      INCIDENT_TYPES.INCORRECT_PATIENT,
      INCIDENT_TYPES.SERVICE_ENROLLMENT_INCOMPLETE,
      INCIDENT_TYPES.FAILED_TO_VERIFY_MEDLIST,
      INCIDENT_TYPES.FAILED_TO_VERIFY_INSURANCE,
      INCIDENT_TYPES.CORRECT_ADDRESS_PATIENT_UNAVAILABLE_OR_NO_ACCESS,
    ],
    default: defaultQreValue,
  },
  // CSL
  20: {
    types: [
      INCIDENT_TYPES.INCORRECT_INFORMATION_GIVEN_TO_PATIENT,
      INCIDENT_TYPES.INCOMPLETE_INFORMATION_GIVEN_TO_PATIENT,
      INCIDENT_TYPES.INCORRECT_DELIVERY_ADDRESS_SELECTED_PATIENT_HAS_MULTIPLE_ADDRESS_ON_FILE_,
      INCIDENT_TYPES.CORRECT_ADDRESS_INPUT_DELIVERED_BY_SHIPPER_TO_WRONG_ADDRESS,
      INCIDENT_TYPES.INCORRECT_DRUG_PRODUCT_DISPENSED,
      INCIDENT_TYPES.INCORRECT_RECIPIENT_SELECTED_BY_PHARMACY,
      INCIDENT_TYPES.INCORRECT_STRENGTH,
      INCIDENT_TYPES.INCORRECT_DOSAGE_FORM,
      INCIDENT_TYPES.INCORRECT_INSTRUCTIONS,
      INCIDENT_TYPES.INCORRECT_QUANTITY,
      INCIDENT_TYPES.UNACCEPTABLE_MEDICATIONS,
      INCIDENT_TYPES.MISSING_SUPPLIES_NEEDED_FOR_ADMINISTRATION,
      INCIDENT_TYPES.INCORRECT_PRESCRIPTION,
      INCIDENT_TYPES.INCORRECT_PHARMACY_LABEL,
      INCIDENT_TYPES.PACKAGING_ISSUE,
      INCIDENT_TYPES.INCORRECT_PACKAGING,
      INCIDENT_TYPES.PRESCRIPTION_ADJUDICATION,
      INCIDENT_TYPES.BILLING_INSURANCE,
      INCIDENT_TYPES.PAYMENT,
      INCIDENT_TYPES.PRODUCT_NOT_FULFILLED_CORRECTLY,
      INCIDENT_TYPES.REMS_NOT_FOLLOWED_CORRECTLY,
      INCIDENT_TYPES.NOTICE_OF_PRIVACY_PRACTICE,
      INCIDENT_TYPES.WELCOME_KITS,
      INCIDENT_TYPES.PHONE,
      INCIDENT_TYPES.COMPUTER,
      INCIDENT_TYPES.SOFTWARE,
      INCIDENT_TYPES.PACKAGE_DAMAGED_TAMPERED_IN_TRANSIT,
      INCIDENT_TYPES.COLD_CHAIN_FAILURE_PACKAGING_FAILED,
      INCIDENT_TYPES.INCORRECT_PAYMENT_METHOD_BILLED,
      INCIDENT_TYPES.NO_SIGNATURE_AVAILABLE,
      INCIDENT_TYPES.PACKAGE_LOST_IN_TRANSIT,
      INCIDENT_TYPES.STOLEN_PACKAGE,
      INCIDENT_TYPES.LATE_DELIVERY_WEATHER_DELAY,
      INCIDENT_TYPES.LATE_DELIVERY_VOLUME_SURGE,
      INCIDENT_TYPES.DISPENSING_ACCURACY,
      INCIDENT_TYPES.OTHER,
      INCIDENT_TYPES.INCORRECT_PATIENT,
      INCIDENT_TYPES.SERVICE_ENROLLMENT_INCOMPLETE,
      INCIDENT_TYPES.FAILED_TO_VERIFY_MEDLIST,
      INCIDENT_TYPES.FAILED_TO_VERIFY_INSURANCE,
      INCIDENT_TYPES.CORRECT_ADDRESS_PATIENT_UNAVAILABLE_OR_NO_ACCESS,
    ],
    default: defaultQreValue,
  },
  // OR
  22: {
    types: [
      INCIDENT_TYPES.ERROR_MADE_DURING_OUTREACH,
      INCIDENT_TYPES.INCORRECT_DELIVERY_ADDRESS_SELECTED_PATIENT_HAS_MULTIPLE_ADDRESS_ON_FILE_,
      INCIDENT_TYPES.CORRECT_ADDRESS_INPUT_DELIVERED_BY_SHIPPER_TO_WRONG_ADDRESS,
      INCIDENT_TYPES.INCORRECT_DRUG_PRODUCT_DISPENSED,
      INCIDENT_TYPES.INCORRECT_RECIPIENT_SELECTED_BY_PHARMACY,
      INCIDENT_TYPES.INCORRECT_STRENGTH,
      INCIDENT_TYPES.INCORRECT_DOSAGE_FORM,
      INCIDENT_TYPES.INCORRECT_INSTRUCTIONS,
      INCIDENT_TYPES.INCORRECT_QUANTITY,
      INCIDENT_TYPES.UNACCEPTABLE_MEDICATIONS,
      INCIDENT_TYPES.MISSING_SUPPLIES_NEEDED_FOR_ADMINISTRATION,
      INCIDENT_TYPES.INCORRECT_PRESCRIPTION,
      INCIDENT_TYPES.INCORRECT_PHARMACY_LABEL,
      INCIDENT_TYPES.PACKAGING_ISSUE,
      INCIDENT_TYPES.INCORRECT_PACKAGING,
      INCIDENT_TYPES.PRESCRIPTION_ADJUDICATION,
      INCIDENT_TYPES.BILLING_INSURANCE,
      INCIDENT_TYPES.PAYMENT,
      INCIDENT_TYPES.PRODUCT_NOT_FULFILLED_CORRECTLY,
      INCIDENT_TYPES.REMS_NOT_FOLLOWED_CORRECTLY,
      INCIDENT_TYPES.NOTICE_OF_PRIVACY_PRACTICE,
      INCIDENT_TYPES.WELCOME_KITS,
      INCIDENT_TYPES.PHONE,
      INCIDENT_TYPES.COMPUTER,
      INCIDENT_TYPES.SOFTWARE,
      INCIDENT_TYPES.PACKAGE_DAMAGED_TAMPERED_IN_TRANSIT,
      INCIDENT_TYPES.COLD_CHAIN_FAILURE_PACKAGING_FAILED,
      INCIDENT_TYPES.INCORRECT_PAYMENT_METHOD_BILLED,
      INCIDENT_TYPES.NO_SIGNATURE_AVAILABLE,
      INCIDENT_TYPES.PACKAGE_LOST_IN_TRANSIT,
      INCIDENT_TYPES.STOLEN_PACKAGE,
      INCIDENT_TYPES.LATE_DELIVERY_WEATHER_DELAY,
      INCIDENT_TYPES.LATE_DELIVERY_VOLUME_SURGE,
      INCIDENT_TYPES.DISPENSING_ACCURACY,
      INCIDENT_TYPES.OTHER,
      INCIDENT_TYPES.INCORRECT_PATIENT,
      INCIDENT_TYPES.SERVICE_ENROLLMENT_INCOMPLETE,
      INCIDENT_TYPES.FAILED_TO_VERIFY_MEDLIST,
      INCIDENT_TYPES.FAILED_TO_VERIFY_INSURANCE,
      INCIDENT_TYPES.CORRECT_ADDRESS_PATIENT_UNAVAILABLE_OR_NO_ACCESS,
    ],
    default: defaultQreValue,
  },
  // AR
  21: {
    types: [
      INCIDENT_TYPES.INCORRECT_ASSESSMENT_OF_OPPORTUNITY,
      INCIDENT_TYPES.INCORRECT_DELIVERY_ADDRESS_SELECTED_PATIENT_HAS_MULTIPLE_ADDRESS_ON_FILE_,
      INCIDENT_TYPES.CORRECT_ADDRESS_INPUT_DELIVERED_BY_SHIPPER_TO_WRONG_ADDRESS,
      INCIDENT_TYPES.INCORRECT_DRUG_PRODUCT_DISPENSED,
      INCIDENT_TYPES.INCORRECT_RECIPIENT_SELECTED_BY_PHARMACY,
      INCIDENT_TYPES.INCORRECT_STRENGTH,
      INCIDENT_TYPES.INCORRECT_DOSAGE_FORM,
      INCIDENT_TYPES.INCORRECT_INSTRUCTIONS,
      INCIDENT_TYPES.INCORRECT_QUANTITY,
      INCIDENT_TYPES.UNACCEPTABLE_MEDICATIONS,
      INCIDENT_TYPES.MISSING_SUPPLIES_NEEDED_FOR_ADMINISTRATION,
      INCIDENT_TYPES.INCORRECT_PRESCRIPTION,
      INCIDENT_TYPES.INCORRECT_PHARMACY_LABEL,
      INCIDENT_TYPES.PACKAGING_ISSUE,
      INCIDENT_TYPES.INCORRECT_PACKAGING,
      INCIDENT_TYPES.PRESCRIPTION_ADJUDICATION,
      INCIDENT_TYPES.BILLING_INSURANCE,
      INCIDENT_TYPES.PAYMENT,
      INCIDENT_TYPES.PRODUCT_NOT_FULFILLED_CORRECTLY,
      INCIDENT_TYPES.REMS_NOT_FOLLOWED_CORRECTLY,
      INCIDENT_TYPES.NOTICE_OF_PRIVACY_PRACTICE,
      INCIDENT_TYPES.WELCOME_KITS,
      INCIDENT_TYPES.PHONE,
      INCIDENT_TYPES.COMPUTER,
      INCIDENT_TYPES.SOFTWARE,
      INCIDENT_TYPES.PACKAGE_DAMAGED_TAMPERED_IN_TRANSIT,
      INCIDENT_TYPES.COLD_CHAIN_FAILURE_PACKAGING_FAILED,
      INCIDENT_TYPES.INCORRECT_PAYMENT_METHOD_BILLED,
      INCIDENT_TYPES.NO_SIGNATURE_AVAILABLE,
      INCIDENT_TYPES.PACKAGE_LOST_IN_TRANSIT,
      INCIDENT_TYPES.STOLEN_PACKAGE,
      INCIDENT_TYPES.LATE_DELIVERY_WEATHER_DELAY,
      INCIDENT_TYPES.LATE_DELIVERY_VOLUME_SURGE,
      INCIDENT_TYPES.DISPENSING_ACCURACY,
      INCIDENT_TYPES.OTHER,
      INCIDENT_TYPES.INCORRECT_PATIENT,
      INCIDENT_TYPES.SERVICE_ENROLLMENT_INCOMPLETE,
      INCIDENT_TYPES.FAILED_TO_VERIFY_MEDLIST,
      INCIDENT_TYPES.FAILED_TO_VERIFY_INSURANCE,
      INCIDENT_TYPES.CORRECT_ADDRESS_PATIENT_UNAVAILABLE_OR_NO_ACCESS,
    ],
    default: defaultQreValue,
  },
  // TPR
  16: {
    types: [
      INCIDENT_TYPES.INCORRECT_DELIVERY_ADDRESS_SELECTED_PATIENT_HAS_MULTIPLE_ADDRESS_ON_FILE_,
      INCIDENT_TYPES.CORRECT_ADDRESS_INPUT_DELIVERED_BY_SHIPPER_TO_WRONG_ADDRESS,
      INCIDENT_TYPES.INCORRECT_DRUG_PRODUCT_DISPENSED,
      INCIDENT_TYPES.INCORRECT_RECIPIENT_SELECTED_BY_PHARMACY,
      INCIDENT_TYPES.INCORRECT_STRENGTH,
      INCIDENT_TYPES.INCORRECT_DOSAGE_FORM,
      INCIDENT_TYPES.INCORRECT_INSTRUCTIONS,
      INCIDENT_TYPES.INCORRECT_QUANTITY,
      INCIDENT_TYPES.UNACCEPTABLE_MEDICATIONS,
      INCIDENT_TYPES.MISSING_SUPPLIES_NEEDED_FOR_ADMINISTRATION,
      INCIDENT_TYPES.INCORRECT_PRESCRIPTION,
      INCIDENT_TYPES.INCORRECT_PHARMACY_LABEL,
      INCIDENT_TYPES.PACKAGING_ISSUE,
      INCIDENT_TYPES.INCORRECT_PACKAGING,
      INCIDENT_TYPES.PRESCRIPTION_ADJUDICATION,
      INCIDENT_TYPES.BILLING_INSURANCE,
      INCIDENT_TYPES.PAYMENT,
      INCIDENT_TYPES.PRODUCT_NOT_FULFILLED_CORRECTLY,
      INCIDENT_TYPES.REMS_NOT_FOLLOWED_CORRECTLY,
      INCIDENT_TYPES.NOTICE_OF_PRIVACY_PRACTICE,
      INCIDENT_TYPES.WELCOME_KITS,
      INCIDENT_TYPES.PHONE,
      INCIDENT_TYPES.COMPUTER,
      INCIDENT_TYPES.SOFTWARE,
      INCIDENT_TYPES.PACKAGE_DAMAGED_TAMPERED_IN_TRANSIT,
      INCIDENT_TYPES.COLD_CHAIN_FAILURE_PACKAGING_FAILED,
      INCIDENT_TYPES.INCORRECT_PAYMENT_METHOD_BILLED,
      INCIDENT_TYPES.NO_SIGNATURE_AVAILABLE,
      INCIDENT_TYPES.PACKAGE_LOST_IN_TRANSIT,
      INCIDENT_TYPES.STOLEN_PACKAGE,
      INCIDENT_TYPES.LATE_DELIVERY_WEATHER_DELAY,
      INCIDENT_TYPES.LATE_DELIVERY_VOLUME_SURGE,
      INCIDENT_TYPES.DISPENSING_ACCURACY,
      INCIDENT_TYPES.OTHER,
      INCIDENT_TYPES.INCORRECT_PATIENT,
      INCIDENT_TYPES.SERVICE_ENROLLMENT_INCOMPLETE,
      INCIDENT_TYPES.FAILED_TO_VERIFY_MEDLIST,
      INCIDENT_TYPES.FAILED_TO_VERIFY_INSURANCE,
      INCIDENT_TYPES.CORRECT_ADDRESS_PATIENT_UNAVAILABLE_OR_NO_ACCESS,
    ],
    default: defaultQreValue,
  },
  // INT
  19: {
    types: [
      INCIDENT_TYPES.INCORRECT_INFORMATION_GIVEN_TO_PATIENT,
      INCIDENT_TYPES.INCOMPLETE_INFORMATION_GIVEN_TO_PATIENT,
      INCIDENT_TYPES.INCORRECT_DELIVERY_ADDRESS_SELECTED_PATIENT_HAS_MULTIPLE_ADDRESS_ON_FILE_,
      INCIDENT_TYPES.CORRECT_ADDRESS_INPUT_DELIVERED_BY_SHIPPER_TO_WRONG_ADDRESS,
      INCIDENT_TYPES.INCORRECT_DRUG_PRODUCT_DISPENSED,
      INCIDENT_TYPES.INCORRECT_RECIPIENT_SELECTED_BY_PHARMACY,
      INCIDENT_TYPES.INCORRECT_STRENGTH,
      INCIDENT_TYPES.INCORRECT_DOSAGE_FORM,
      INCIDENT_TYPES.INCORRECT_INSTRUCTIONS,
      INCIDENT_TYPES.INCORRECT_QUANTITY,
      INCIDENT_TYPES.UNACCEPTABLE_MEDICATIONS,
      INCIDENT_TYPES.MISSING_SUPPLIES_NEEDED_FOR_ADMINISTRATION,
      INCIDENT_TYPES.INCORRECT_PRESCRIPTION,
      INCIDENT_TYPES.INCORRECT_PHARMACY_LABEL,
      INCIDENT_TYPES.PACKAGING_ISSUE,
      INCIDENT_TYPES.INCORRECT_PACKAGING,
      INCIDENT_TYPES.PRESCRIPTION_ADJUDICATION,
      INCIDENT_TYPES.BILLING_INSURANCE,
      INCIDENT_TYPES.PAYMENT,
      INCIDENT_TYPES.PRODUCT_NOT_FULFILLED_CORRECTLY,
      INCIDENT_TYPES.REMS_NOT_FOLLOWED_CORRECTLY,
      INCIDENT_TYPES.NOTICE_OF_PRIVACY_PRACTICE,
      INCIDENT_TYPES.WELCOME_KITS,
      INCIDENT_TYPES.PHONE,
      INCIDENT_TYPES.COMPUTER,
      INCIDENT_TYPES.SOFTWARE,
      INCIDENT_TYPES.PACKAGE_DAMAGED_TAMPERED_IN_TRANSIT,
      INCIDENT_TYPES.COLD_CHAIN_FAILURE_PACKAGING_FAILED,
      INCIDENT_TYPES.INCORRECT_PAYMENT_METHOD_BILLED,
      INCIDENT_TYPES.NO_SIGNATURE_AVAILABLE,
      INCIDENT_TYPES.PACKAGE_LOST_IN_TRANSIT,
      INCIDENT_TYPES.STOLEN_PACKAGE,
      INCIDENT_TYPES.LATE_DELIVERY_WEATHER_DELAY,
      INCIDENT_TYPES.LATE_DELIVERY_VOLUME_SURGE,
      INCIDENT_TYPES.DISPENSING_ACCURACY,
      INCIDENT_TYPES.OTHER,
      INCIDENT_TYPES.INCORRECT_PATIENT,
      INCIDENT_TYPES.SERVICE_ENROLLMENT_INCOMPLETE,
      INCIDENT_TYPES.FAILED_TO_VERIFY_MEDLIST,
      INCIDENT_TYPES.FAILED_TO_VERIFY_INSURANCE,
      INCIDENT_TYPES.CORRECT_ADDRESS_PATIENT_UNAVAILABLE_OR_NO_ACCESS,
    ],
    default: defaultQreValue,
  },
  // RS
  27: {
    types: [
      INCIDENT_TYPES.INCORRECT_DELIVERY_ADDRESS_SELECTED_PATIENT_HAS_MULTIPLE_ADDRESS_ON_FILE_,
      INCIDENT_TYPES.CORRECT_ADDRESS_INPUT_DELIVERED_BY_SHIPPER_TO_WRONG_ADDRESS,
      INCIDENT_TYPES.INCORRECT_DRUG_PRODUCT_DISPENSED,
      INCIDENT_TYPES.INCORRECT_RECIPIENT_SELECTED_BY_PHARMACY,
      INCIDENT_TYPES.INCORRECT_STRENGTH,
      INCIDENT_TYPES.INCORRECT_DOSAGE_FORM,
      INCIDENT_TYPES.INCORRECT_INSTRUCTIONS,
      INCIDENT_TYPES.INCORRECT_QUANTITY,
      INCIDENT_TYPES.UNACCEPTABLE_MEDICATIONS,
      INCIDENT_TYPES.MISSING_SUPPLIES_NEEDED_FOR_ADMINISTRATION,
      INCIDENT_TYPES.INCORRECT_PRESCRIPTION,
      INCIDENT_TYPES.INCORRECT_PHARMACY_LABEL,
      INCIDENT_TYPES.PACKAGING_ISSUE,
      INCIDENT_TYPES.INCORRECT_PACKAGING,
      INCIDENT_TYPES.PRESCRIPTION_ADJUDICATION,
      INCIDENT_TYPES.BILLING_INSURANCE,
      INCIDENT_TYPES.PAYMENT,
      INCIDENT_TYPES.PRODUCT_NOT_FULFILLED_CORRECTLY,
      INCIDENT_TYPES.REMS_NOT_FOLLOWED_CORRECTLY,
      INCIDENT_TYPES.NOTICE_OF_PRIVACY_PRACTICE,
      INCIDENT_TYPES.WELCOME_KITS,
      INCIDENT_TYPES.PHONE,
      INCIDENT_TYPES.COMPUTER,
      INCIDENT_TYPES.SOFTWARE,
      INCIDENT_TYPES.PACKAGE_DAMAGED_TAMPERED_IN_TRANSIT,
      INCIDENT_TYPES.COLD_CHAIN_FAILURE_PACKAGING_FAILED,
      INCIDENT_TYPES.INCORRECT_PAYMENT_METHOD_BILLED,
      INCIDENT_TYPES.NO_SIGNATURE_AVAILABLE,
      INCIDENT_TYPES.PACKAGE_LOST_IN_TRANSIT,
      INCIDENT_TYPES.STOLEN_PACKAGE,
      INCIDENT_TYPES.LATE_DELIVERY_WEATHER_DELAY,
      INCIDENT_TYPES.LATE_DELIVERY_VOLUME_SURGE,
      INCIDENT_TYPES.DISPENSING_ACCURACY,
      INCIDENT_TYPES.OTHER,
      INCIDENT_TYPES.INCORRECT_PATIENT,
      INCIDENT_TYPES.SERVICE_ENROLLMENT_INCOMPLETE,
      INCIDENT_TYPES.FAILED_TO_VERIFY_MEDLIST,
      INCIDENT_TYPES.FAILED_TO_VERIFY_INSURANCE,
      INCIDENT_TYPES.CORRECT_ADDRESS_PATIENT_UNAVAILABLE_OR_NO_ACCESS,
    ],
    default: defaultQreValue,
  },
});

export const getCategoryMapping = (INCIDENT_CATEGORIES: any, INCIDENT_TYPES: any): any => {
  if (!INCIDENT_CATEGORIES || !INCIDENT_TYPES) return {};
  return {
    [INCIDENT_CATEGORIES.COMPLIMENTS]: {
      types: [INCIDENT_TYPES.OTHER],
      values: {
        is_external: true,
        is_medication_error: false,
        is_hipaa_breach: null,
        reporting_party_id: 4, // Patient
      },
    },
    [INCIDENT_CATEGORIES.COMPLAINTS]: {
      types: [
        INCIDENT_TYPES.COLD_CHAIN,
        INCIDENT_TYPES.CUSTOMER_SERVICE,
        INCIDENT_TYPES.INSURANCE_OR_BILLING,
        INCIDENT_TYPES.PHONE,
        INCIDENT_TYPES.SHIPPING,
        INCIDENT_TYPES.OTHER,
        INCIDENT_TYPES.CUSTOMER_COMPLAINT_BILLING,
        INCIDENT_TYPES.CUSTOMER_COMPLAINT_CUSTOMER_SERVICE,
        INCIDENT_TYPES.CUSTOMER_COMPLAINT_DELIVERY,
        INCIDENT_TYPES.CUSTOMER_COMPLAINT_HIPPA,
        INCIDENT_TYPES.CUSTOMER_COMPLAINT_PRODUCT,
      ],
      values: {
        is_external: true,
        is_medication_error: false,
        is_hipaa_breach: null,
        reporting_party_id: 4, // Patient
      },
    },
    [INCIDENT_CATEGORIES.AUDIT]: {
      types: [
        INCIDENT_TYPES.FAILED_TO_VERIFY,
        INCIDENT_TYPES.FAILED_TO_COMPLETE,
        INCIDENT_TYPES.OTHER,
      ],
      values: {
        is_external: false,
        is_medication_error: false,
        is_hipaa_breach: null,
        reporting_party_id: 1, // Central Support
      },
    },
  };
};

export const getRoleStringsByResponsiblePartiesLabel = (label: string): UserRoles[] | null =>
  responsiblePartiesRolesMap[label] || null;

export const filterUsersByResponsibleParties = (
  responsibleParties: DropDownItem[] | undefined,
  users?: IUser[],
): IUser[] => {
  return (responsibleParties || []).reduce((responsibleUsers: IUser[], responsibleParty) => {
    const roles = getRoleStringsByResponsiblePartiesLabel(responsibleParty.label);

    if (roles?.length && users?.length) {
      return [
        ...responsibleUsers,
        ...(users || []).filter(user =>
          Boolean(user.roles?.find(role => roles.includes(role.name))),
        ),
      ];
    }
    return responsibleUsers;
  }, []);
};

export const getUserDropdownOptions = (users: IUser[]): DropDownItem[] =>
  users.map(user => ({
    value: user.id,
    label: user.display_name || user.username,
  }));

export const getIncidentTypesExceptions = (
  initialTypesArr: TypeArrItem[],
  errorTypeId: number,
  categoryId: number,
  incidentTypesCategories: any,
): TypeArrItem[] => {
  if (
    !incidentTypesCategories ||
    !incidentTypesCategories.types ||
    !incidentTypesCategories.categories
  ) {
    return initialTypesArr;
  }
  let retArr: TypeArrItem[] = initialTypesArr;

  const { types, categories } = incidentTypesCategories;
  if (categoryId === categories.INCIDENT) {
    retArr = retArr.filter(type => type.id !== types.OTHER);
    retArr = [
      ...retArr,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      {
        id: types.OTHER,
        name: 'Other',
        incident_error_type: INCIDENT_ERROR_TYPE_OTHER,
        active: 0,
      },
    ];
  } else if (categoryId === categories.COMPLAINTS) {
    retArr = retArr.filter(type => type.id !== types.OTHER);
    retArr = [
      ...retArr,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      {
        id: types.OTHER,
        name: 'Other',
        incident_error_type: INCIDENT_ERROR_TYPE_OTHER,
        active: 0,
      },
      {
        id: types.BILLING_INSURANCE,
        name: 'Billing/Insurance',
        incident_error_type: categoryId,
        active: 0,
      },
    ];
  } else if (categoryId === categories.AUDIT) {
    retArr = retArr.filter(type => type.id !== types.OTHER);
    retArr = [
      ...retArr,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      {
        id: types.OTHER,
        name: 'Other',
        incident_error_type: INCIDENT_ERROR_TYPE_OTHER,
        active: 0,
      },
    ];
  }
  return retArr;
};

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

export const styles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    button: {
      'borderRadius': theme.spacing(2),
      'backgroundColor': palette.primary.grey14,
      '&:hover': {
        backgroundColor: palette.primary.grey14,
      },
    },
    buttonLabel: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    selected: {
      'backgroundColor': palette.primary.teal,
      'color': palette.primary.white,
      '&:hover': {
        backgroundColor: palette.primary.teal,
      },
    },
    firstQuestionItem: {
      padding: `${theme.spacing(1)} 0`,
    },
    interventionForm: {
      border: `1px solid ${palette.primary.grey25}`,
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    interventionTitle: {
      padding: theme.spacing(1),
    },
    newSideEffectButton: {
      backgroundColor: palette.primary.grey2,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      border: 'none',
    },
    smallForm: {
      paddingTop: theme.spacing(1.5),
    },
    smallFormSaved: {
      paddingBottom: theme.spacing(3),
    },
  });
};

import React, { useMemo, useState } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import withStyles from '@mui/styles/withStyles';
import {
  copayCardTypeList,
  internalFoundationTypeList,
  externalFoundationTypeList,
  activeFinancialAssistanceList,
  FreeDrugReasonsLabels,
} from 'constants/lists';
import {
  FinancialAssistanceTypes,
  CopayCardTypes,
  FinancialAssistanceSubTypes,
} from 'constants/enums.ts';
import Dropdown from 'components/form/field/dropdown';
import FormHeader from 'components/form/header/header';
import { change } from 'redux-form';
import { useDispatch } from 'react-redux';
import AddCopayCard from './copay-card/add-copay-card';
// eslint-disable-next-line max-len
import AddPrescriptionAssistanceProgram from './prescription-assistance-program/add-prescription-assistance-program';
import AddExternalFoundation from './foundation/add-external-foundation';
import AddInternalFoundation from './foundation/add-internal-foundation';
import { styles } from '../task-detail-styles';
import FoundationDetail from './foundation/foundation-detail';
import { convertMapToList } from '../../../reducers/helper-reducer';
import { useTypedSelector } from '../../../hooks/use-typed-selector';
import { windowFeatureIsEnabled } from '../../../config/window-features';
import CopayCardDetail from './copay-card/copay-card-detail';
import { FA_STATUS_RESOURCE, FA_TASK_TYPE, SUCCESS_STATUS_CATEGORY_ID } from './constants';
import PrescriptionAssistanceProgramDetail from './prescription-assistance-program/prescription-assistance-program-detail';
import { STATUS_FA_CANCELED } from '../../../constants';

export function AddFAData(props) {
  const { classes, task, therapy, addingFromFinancials, patientId, handleCancel } = props;

  const FORM_NAME = `tasks-edit-${therapy.id}-FA-${task.id}-task`;
  const therapies = useTypedSelector(state => state.therapies.data);
  const financials = useTypedSelector(state => state.financials);
  const tasks = useTypedSelector(state => state.tasks.data);
  const statuses = useTypedSelector(state =>
    state.lookups.lookupStatusReasons.filter(
      s => s.resource === FA_STATUS_RESOURCE && s.category_id === SUCCESS_STATUS_CATEGORY_ID,
    ),
  );
  const formValues = useTypedSelector(state => state.form[FORM_NAME]?.values);
  const linkedFas = useTypedSelector(state => state.financials.has_linked);
  const [linkedFa] = linkedFas?.filter(fa => fa.id === task.id) || [];
  const dispatch = useDispatch();
  if (linkedFa?.status_id === STATUS_FA_CANCELED && formValues.status_id !== linkedFa?.status_id) {
    dispatch(change(FORM_NAME, 'status_id', linkedFa?.status_id));
    dispatch(change(FORM_NAME, 'additional_reason', linkedFa?.additional_reason));
  }
  // For Internal Foundation we consider Renewal tasks
  const approvedTasksForInternalFoundation = useMemo(
    () =>
      Object.values(tasks).filter(
        faTask => faTask.taskType === FA_TASK_TYPE && statuses.find(s => s.id === faTask.status_id),
      ),
    [tasks],
  );

  // For Internal Foundation we DONT consider Renewal tasks
  const approvedTasksForExternalFAndMpap = useMemo(
    () =>
      Object.values(tasks).filter(
        faTask => faTask.taskType === FA_TASK_TYPE && statuses.find(s => s.id === faTask.status_id),
      ),
    [tasks],
  );

  const foundationStatuses = useMemo(
    () =>
      financials.financial_assistance_category_statuses.filter(f =>
        [
          FinancialAssistanceTypes.InternalFoundation,
          FinancialAssistanceTypes.ExternalFoundation,
          FinancialAssistanceTypes.Foundation,
        ].includes(f.category),
      ),
    [financials],
  );
  const copayCardStatuses = useMemo(
    () =>
      financials.financial_assistance_category_statuses.filter(
        f => FinancialAssistanceTypes.CopayCard === f.category,
      ),
    [financials],
  );
  const papStatuses = useMemo(
    () =>
      financials.financial_assistance_category_statuses.filter(f =>
        [FinancialAssistanceTypes.Pap, FinancialAssistanceTypes.Mpap].includes(f.category),
      ),
    [financials],
  );

  const papAssistances = useMemo(() => {
    const financialAssistances = convertMapToList(financials.financial_assistances.data);
    if (!financialAssistances?.length) {
      return [];
    }
    const paps = financials.financial_assistance_category_statuses.filter(f =>
      [FinancialAssistanceTypes.Pap, FinancialAssistanceTypes.Mpap].includes(f.category),
    );
    const result = financialAssistances.filter(
      financialAssistance =>
        paps.some(status => status.id === financialAssistance.financial_assistance_type) &&
        approvedTasksForExternalFAndMpap.some(
          faTask => faTask.id === financialAssistance.task_fa_id,
        ),
    );
    return result;
  }, [financials, approvedTasksForExternalFAndMpap]);

  const internalFoundationFinancialAssistances = useMemo(() => {
    const financialAssistances = convertMapToList(financials.financial_assistances.data);
    if (!financialAssistances?.length) {
      return [];
    }
    const internalFoundationStatuses = financials.financial_assistance_category_statuses.filter(
      f => FinancialAssistanceTypes.InternalFoundation === f.category,
    );
    return financialAssistances.filter(
      financialAssistance =>
        internalFoundationStatuses.some(
          status => status.id === financialAssistance.financial_assistance_type,
        ) &&
        approvedTasksForInternalFoundation.some(
          faTask => faTask.id === financialAssistance.task_fa_id,
        ),
    );
  }, [financials, approvedTasksForInternalFoundation]);

  const externalFoundationAssistances = useMemo(() => {
    const financialAssistances = convertMapToList(financials.financial_assistances.data);
    if (!financialAssistances?.length) {
      return [];
    }
    const externalFoundationStatuses = financials.financial_assistance_category_statuses.filter(
      f => FinancialAssistanceTypes.ExternalFoundation === f.category,
    );
    return financialAssistances.filter(
      financialAssistance =>
        externalFoundationStatuses.some(
          status => status.id === financialAssistance.financial_assistance_type,
        ) &&
        approvedTasksForExternalFAndMpap.some(
          faTask => faTask.id === financialAssistance.task_fa_id,
        ),
    );
  }, [financials, approvedTasksForExternalFAndMpap]);

  const copayCardAssistances = useMemo(() => {
    const financialAssistances = convertMapToList(financials.financial_assistances.data);
    if (!financialAssistances?.length) {
      return [];
    }
    return financialAssistances.filter(
      financialAssistance =>
        copayCardStatuses.some(
          status => status.id === financialAssistance.financial_assistance_type,
        ) &&
        approvedTasksForExternalFAndMpap.some(
          faTask => faTask.id === financialAssistance.task_fa_id,
        ),
    );
  }, [financials, approvedTasksForExternalFAndMpap]);

  const [selectedType, setSelectedType] = useState(-1);
  const [copayCardType, setCopayCardType] = useState(-1);
  const [foundationType, setFoundationType] = useState(-1);
  const [freeDrugReason, setFreeDrugReason] = useState(-1);

  const handleFAType = e => {
    setSelectedType(e.target.value);
  };

  const handleFreeDrugReason = e => {
    setFreeDrugReason(e.target.value);
  };

  const handleCopayCardType = e => {
    setCopayCardType(e.target.value);
  };

  const handleFoundationType = e => {
    setFoundationType(e.target.value);
  };

  const resetFAStatus = () => {
    handleCancel();
    setSelectedType('');
  };

  const renderCopayCardTypes = statusSelectIdParam => {
    switch (statusSelectIdParam) {
      case CopayCardTypes.pharmacyBenefit:
        return (
          <Grid container>
            <AddCopayCard
              therapy={therapy}
              task={task}
              cancel={resetFAStatus}
              isPharmacyBenefit
              addingFromFinancials={addingFromFinancials}
              patientId={patientId}
              categoryId="1001"
            />
          </Grid>
        );
      case CopayCardTypes.creditCard:
        return (
          <Grid container>
            <AddCopayCard
              therapy={therapy}
              task={task}
              cancel={resetFAStatus}
              addingFromFinancials={addingFromFinancials}
              patientId={patientId}
              categoryId="1002"
            />
          </Grid>
        );
      case CopayCardTypes.check:
        return (
          <Grid container>
            <AddCopayCard
              therapy={therapy}
              task={task}
              cancel={resetFAStatus}
              addingFromFinancials={addingFromFinancials}
              patientId={patientId}
              categoryId="1003"
            />
          </Grid>
        );
      default:
        return (
          <div style={{ textAlign: 'right' }}>
            <Button
              name="add_task_cancel_button"
              type="button"
              onClick={handleCancel}
              className={classes.button}
            >
              Cancel
            </Button>
          </div>
        );
    }
  };

  const renderFoundationTypes = (statusSelectIdParam, financialAssistanceType) => {
    if (financialAssistanceType === FinancialAssistanceTypes.InternalFoundation) {
      const subTypeMap = {
        [CopayCardTypes.pharmacyBenefit]:
          FinancialAssistanceSubTypes.InternalFoundation_PharmacyBenefit,
        [CopayCardTypes.accountPayable]:
          FinancialAssistanceSubTypes.InternalFoundation_AccountPayable,
      };
      const categoryId = subTypeMap[statusSelectIdParam];
      if (categoryId == null) {
        return (
          <div style={{ textAlign: 'right' }}>
            <Button
              name="add_task_cancel_button"
              type="button"
              onClick={handleCancel}
              className={classes.button}
            >
              Cancel
            </Button>
          </div>
        );
      }
      return (
        <Grid container>
          <AddInternalFoundation
            therapy={therapy}
            task={task}
            cancel={resetFAStatus}
            isPharmacyBenefit={
              categoryId === FinancialAssistanceSubTypes.InternalFoundation_PharmacyBenefit
            }
            isAccountPayable={
              categoryId === FinancialAssistanceSubTypes.InternalFoundation_AccountPayable
            }
            addingFromFinancials={addingFromFinancials}
            patientId={patientId}
            categoryId={categoryId}
          />
        </Grid>
      );
    }

    switch (statusSelectIdParam) {
      case CopayCardTypes.pharmacyBenefit:
        return (
          <Grid container>
            <AddExternalFoundation
              therapy={therapy}
              task={task}
              cancel={resetFAStatus}
              isPharmacyBenefit
              addingFromFinancials={addingFromFinancials}
              patientId={patientId}
              categoryId={
                financialAssistanceType === FinancialAssistanceTypes.InternalFoundation
                  ? '3004'
                  : '3007'
              }
            />
          </Grid>
        );
      case CopayCardTypes.creditCard:
        return (
          <Grid container>
            <AddExternalFoundation
              therapy={therapy}
              task={task}
              cancel={resetFAStatus}
              addingFromFinancials={addingFromFinancials}
              patientId={patientId}
              categoryId={
                financialAssistanceType === FinancialAssistanceTypes.InternalFoundation
                  ? '3005'
                  : '3008'
              }
            />
          </Grid>
        );
      case CopayCardTypes.check:
        return (
          <Grid container>
            <AddExternalFoundation
              therapy={therapy}
              task={task}
              cancel={resetFAStatus}
              addingFromFinancials={addingFromFinancials}
              patientId={patientId}
              categoryId={
                financialAssistanceType === FinancialAssistanceTypes.InternalFoundation
                  ? '3006'
                  : '3009'
              }
            />
          </Grid>
        );
      default:
        return (
          <div style={{ textAlign: 'right' }}>
            <Button
              name="add_task_cancel_button"
              type="button"
              onClick={handleCancel}
              className={classes.button}
            >
              Cancel
            </Button>
          </div>
        );
    }
  };

  const renderPrescriptionAssistanceProgramTypes = () => (
    <Grid container>
      <AddPrescriptionAssistanceProgram
        therapy={therapy}
        task={task}
        cancel={resetFAStatus}
        isPharmacyBenefit
        addingFromFinancials={addingFromFinancials}
        patientId={patientId}
        freeDrugReason={freeDrugReason}
        categoryId="2001"
      />
    </Grid>
  );

  const [showMPAPForm, setShowMPAPForm] = useState(false);
  const renderPrescriptionAssistanceProgramWithFALink = () => {
    if (!windowFeatureIsEnabled('fa_linking')) {
      return renderPrescriptionAssistanceProgramTypes();
    }
    const linkableFATasks = papAssistances.filter(fa => {
      const faTherapy = therapies[fa.therapy_id];
      return faTherapy?.gpi === therapy.gpi;
    });

    if (!linkableFATasks.length) {
      return renderPrescriptionAssistanceProgramTypes();
    }

    const [existingMPAPFA] = linkableFATasks;
    return (
      <>
        {!showMPAPForm && (
          <Grid className={classes.statusContainer} container>
            <Grid xs={12}>
              <Typography fontWeight="bold">
                There is currently an active Manufacturer Prescription Assistance Program for this
                Therapy ({therapies[existingMPAPFA.therapy_id].drug_name})
              </Typography>
            </Grid>
            <Grid xs={12}>
              <PrescriptionAssistanceProgramDetail
                showLinkButton
                showForm={() => setShowMPAPForm(true)}
                noEdit
                type={
                  papStatuses.find(f => f.id === existingMPAPFA.financial_assistance_type)
                    .sub_category
                }
                statuses={papStatuses}
                financialAssistance={existingMPAPFA}
              />
            </Grid>
          </Grid>
        )}
        {showMPAPForm && renderPrescriptionAssistanceProgramTypes()}
      </>
    );
  };

  const [showCopayCardForm, setShowCopayCardForm] = useState(false);
  const renderCopayCardWithFALink = statusSelectIdParam => {
    if (!windowFeatureIsEnabled('fa_linking')) {
      return renderCopayCardTypes(statusSelectIdParam);
    }

    const linkableFATasks = copayCardAssistances.filter(fa => {
      if (fa.therapy_id) {
        const faTherapy = therapies[fa.therapy_id];
        return faTherapy?.gpi === therapy.gpi;
      }
      return false;
    });

    if (!linkableFATasks.length) {
      return renderCopayCardTypes(statusSelectIdParam);
    }

    const [existingCopayCardFA] = linkableFATasks;
    return (
      <>
        {!showCopayCardForm && (
          <Grid className={classes.statusContainer} container>
            <Grid xs={12}>
              <Typography fontWeight="bold">
                There is currently an active Manufacturer Copay Card for this Therapy (
                {therapies[existingCopayCardFA.therapy_id].drug_name})
              </Typography>
            </Grid>
            <Grid xs={12}>
              <CopayCardDetail
                showLinkButton
                showForm={() => setShowCopayCardForm(true)}
                noEdit
                type={
                  copayCardStatuses.find(
                    f => f.id === existingCopayCardFA.financial_assistance_type,
                  ).sub_category
                }
                statuses={copayCardStatuses}
                financialAssistance={existingCopayCardFA}
              />
            </Grid>
          </Grid>
        )}
        {showCopayCardForm && renderCopayCardTypes(statusSelectIdParam)}
      </>
    );
  };

  const renderFoundationTypesWithFALink = (statusSelectIdParam, financialAssistanceType) => {
    if (!windowFeatureIsEnabled('fa_linking')) {
      return renderFoundationTypes(statusSelectIdParam, financialAssistanceType);
    }

    const internalFoundationFA = internalFoundationFinancialAssistances?.[0];
    const externalFoundationFA = externalFoundationAssistances?.[0];
    const externalFoundationTherapy = therapies?.[externalFoundationFA?.therapy_id];

    switch (selectedType) {
      case FinancialAssistanceTypes.InternalFoundation:
        if (internalFoundationFinancialAssistances.length) {
          const internalFoundationTherapy = therapies?.[internalFoundationFA?.therapy_id];
          const faTaskTherapy = therapies?.[task.therapy_id];

          const matchesTherapy = internalFoundationTherapy?.gpi === faTaskTherapy?.gpi;

          return (
            <Grid className={classes.statusContainer} container>
              <Grid xs={12}>
                <Typography fontWeight="bold">
                  There is currently an active Internal Foundation
                </Typography>
              </Grid>
              <Grid xs={12} display="inline-flex">
                <InfoIcon fontSize="small" />
                <Typography marginLeft={1}>
                  To continue you must link the existing Internal Foundation.
                </Typography>
              </Grid>
              <Grid xs={12}>
                <FoundationDetail
                  showLinkButton
                  currentTaskId={task?.id}
                  noEdit={!matchesTherapy}
                  type={
                    foundationStatuses.find(
                      f => f.id === internalFoundationFA.financial_assistance_type,
                    ).sub_category
                  }
                  statuses={foundationStatuses}
                  financialAssistance={internalFoundationFA}
                />
              </Grid>
            </Grid>
          );
        }
        return renderFoundationTypes(statusSelectIdParam, financialAssistanceType);
      case FinancialAssistanceTypes.ExternalFoundation:
        return (
          <>
            {externalFoundationFA && (
              <Grid className={classes.statusContainer} container>
                <Grid xs={12}>
                  <Typography fontWeight="bold">
                    There is currently an active External Foundation for another therapy{' '}
                    {externalFoundationTherapy.drug_name}
                  </Typography>
                </Grid>
                <Grid xs={12} display="inline-flex">
                  <InfoIcon fontSize="small" />
                  <Typography marginLeft={1}>
                    If this therapy is also under that foundation, please link, otherwise continue
                    with new FA.
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  <FoundationDetail
                    showLinkButton
                    currentTaskId={task?.id}
                    noEdit
                    type={
                      foundationStatuses.find(
                        f => f.id === externalFoundationFA.financial_assistance_type,
                      ).sub_category
                    }
                    statuses={foundationStatuses}
                    financialAssistance={externalFoundationFA}
                  />
                </Grid>
              </Grid>
            )}
            {renderFoundationTypes(statusSelectIdParam, financialAssistanceType)}
          </>
        );
      default:
        return null;
    }
  };

  const renderConditionalDetailFields = (statusSelectIdParam, copayCardParam, foundationParam) => {
    switch (statusSelectIdParam) {
      case FinancialAssistanceTypes.CopayCard:
        return renderCopayCardWithFALink(copayCardParam);
      case FinancialAssistanceTypes.Mpap:
        return renderPrescriptionAssistanceProgramWithFALink();
      case FinancialAssistanceTypes.InternalFoundation:
      case FinancialAssistanceTypes.ExternalFoundation:
        return renderFoundationTypesWithFALink(foundationParam, statusSelectIdParam);
      default:
        return (
          <div style={{ textAlign: 'right' }}>
            <Button
              name="add_task_cancel_button"
              type="button"
              onClick={handleCancel}
              className={classes.button}
            >
              Cancel
            </Button>
          </div>
        );
    }
  };

  const idSuffix = task && task.therapy_id && task.id ? `_${task.therapy_id}_${task.id}` : '';

  const foundationArrayToUse =
    selectedType === FinancialAssistanceTypes.InternalFoundation
      ? internalFoundationTypeList
      : externalFoundationTypeList;

  return (
    <Grid container className={classes.addForm} justifyContent="center">
      <Grid
        container
        spacing={7}
        className={addingFromFinancials ? classes.addFromFinancials : classes.statusContainer}
      >
        <Grid item xs={12}>
          <FormHeader header="New Financial Assistance" />
        </Grid>
        <Grid item xs={4}>
          <Dropdown
            label="FA Type"
            state={selectedType}
            handler={handleFAType}
            fields={activeFinancialAssistanceList.sort()}
            name="fa_type"
            id={`fa_type_dropdown${idSuffix}`}
          />
        </Grid>
        {selectedType === FinancialAssistanceTypes.Mpap && (
          <Grid item xs={8} className={classes.fieldContainer}>
            <Dropdown
              label="Free Drug Reason"
              state={freeDrugReason}
              handler={handleFreeDrugReason}
              fields={FreeDrugReasonsLabels.map(x => x.label)}
              name="mpap_reason"
              id={`fa_mpap_reason_dropdown${idSuffix}`}
            />
          </Grid>
        )}
        {selectedType === FinancialAssistanceTypes.CopayCard && (
          <Grid item xs={3}>
            <Dropdown
              label="Copay Card Type"
              state={copayCardType}
              handler={handleCopayCardType}
              fields={copayCardTypeList}
              name="copay_card_type"
              id={`copay_card_type_dropdown${idSuffix}`}
            />
          </Grid>
        )}
        {[
          FinancialAssistanceTypes.InternalFoundation,
          FinancialAssistanceTypes.ExternalFoundation,
        ].includes(selectedType) && (
          <Grid item xs={3}>
            <Dropdown
              label="Foundation Type"
              state={foundationType}
              handler={handleFoundationType}
              fields={foundationArrayToUse.map(x => x.label)}
              name="foundation_type"
              id={`foundation_type_dropdown${idSuffix}`}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          {renderConditionalDetailFields(selectedType, copayCardType, foundationType)}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles, { withTheme: true })(AddFAData);

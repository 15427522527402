import { WithStyles } from '@mui/styles';
import { IPatientProblem } from 'interfaces/redux/IPatient';
import { Moment } from 'moment';
import { IGoal } from 'models/cdm/IGoal';
import { styles as listStyles } from './list.styles';
import { styles as containerStyles } from './container.styles';
import { styles as formStyles } from './form.styles';

interface IListProps extends WithStyles<typeof listStyles> {
  readOnly?: boolean;
  onClickEditRow: (problem: IPatientProblem) => void;
  onClickOpenHistory: (problem: IPatientProblem) => void;
  problems: IPatientProblem[];
}

interface IContainerProps extends WithStyles<typeof containerStyles> {
  readOnly?: boolean;
  problems?: IPatientProblem[];
  showTitle?: boolean;
  stretched?: boolean;
  formOpenByDefault?: boolean | null;
  onProblemAdded?: (problem: IPatientProblem) => void;
}

export enum ProblemStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

interface IFormProps extends WithStyles<typeof formStyles> {
  patientId: number;
  patientProblems?: IPatientProblem[];
  editingProblem?: IPatientProblem | null;
  onSubmit?: (problem: any) => void;
  handleCancel: () => void | null;
}

interface IFormGoal {
  labName: string;
  labValue: string;
}

interface IFormFields {
  diagnosis?: IDiagnosis;
  diagnosisCode?: string;
  onsetDate?: Moment | null;
  status?: ProblemStatus;
  notes?: string;
  clinicalSupport?: number;
  patientGoals?: IFormGoal[];
  optInReason?: number[];
  optOutReason?: number[];
  undecidedReason?: number;
  graduationDate?: Moment | null | undefined;
  enrollmentDate?: Moment | null | undefined;
  otherOptOutReason?: string | null;
  followUpdate?: Moment | null;
}

interface IFormFieldsList {
  diagnosis: IDiagnosis[];
  onsetDate: Moment[] | null[];
  status: ProblemStatus[];
  notes: string[];
}

interface IDiagnosis {
  code: string;
  description: string;
}

interface IFetchDiagnosisResponse {
  icd10s: IDiagnosis[];
}

interface IRiskStratLabel {
  labItemName: string;
  lastDataCollect: {
    date: string;
    value: string;
  };
  patientGoalValue: string;
}

interface IGoalActions {
  create: IGoal[];
  update: IGoal[];
}

interface IUpdateEnrollmentInput {
  patientId: number;
  therapyIds: number[];
  problemId: number;
  cdmProgramId: number;
  cdmEncounterId: number | null;
  formValues: IFormFields;
}

export type {
  IRiskStratLabel,
  IListProps,
  IContainerProps,
  IFormProps,
  IFormFields,
  IFormFieldsList,
  IFetchDiagnosisResponse,
  IDiagnosis,
  IFormGoal,
  IGoalActions,
  IUpdateEnrollmentInput,
};

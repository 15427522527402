import HTTP from 'services/http';
import {
  DATA_TASK_COUNTS_FETCH,
  ADD_THIRD_PARTY_REFERRAL,
  EDIT_PA,
  EDIT_FA,
  EDIT_THIRD_PARTY_REFERRAL,
  SET_THERAPY_ID,
  TASK_LIFECYCLE_ACTIONS,
  FETCH_TASKS_FOR_THERAPY_BY_TYPE,
  FETCH_STATUSES_FOR_TYPE,
  ADD_TASK,
  EDIT_TASK,
  LINK_SCHEDULE_OUTREACH_THERAPIES,
  UPDATE_SCHEDULE_DRUG_OUTCOME,
  UPDATE_FC_DISPENSING_PHARMACY,
  ADD_SCHEDULE_DRUG_OUTCOME,
  UPDATE_TASK_PROPERTIES,
  UPDATE_TASKS_PROPERTIES,
  ADD_TASK_TO_PATIENT,
  DUR,
  CSL,
  DC,
  RS,
  EDIT_COUNSELING_DRAFT,
  REDUX_FORM,
  DATA_RELATED_TASKS,
  FETCH_TASK,
  REFRESH_QUEUE_WORKLIST,
  REFRESH_SMS_MESSAGES,
  FETCH_SPECIFIC_QUEUE_TASK_COUNT_SUCCESS,
  UPDATE_TASK_FOLLOWUP_DATE,
  DATA_THERAPY_ARCHIVED_TASKS,
  CANCEL_PA_TASK,
  FETCH_TASKS,
  INTEGRATE_UPDATED_TASKS,
  TOGGLE_SELECTED_TASK_ROW_KEY,
  CLEAR_SELECTED_TASK_KEY,
  SELECT_TASK_KEY,
  SET_SELECTED_TASK_KEYS,
  BULK_TASKS_UPDATE_REQUEST,
  SET_WAG_SELECTED_TASK_KEYS,
  CLEAR_WAG_SELECTED_TASK_KEY,
  EXCLUDE_SELECTED_TASK_KEY,
  SET_MERGE_INTERVENTION_TASKS,
  UPDATE_FDC_STATUS,
} from 'constants/index';
import { logEvent } from 'services/utils/analytics';
import { Therapies } from 'constants/GA';
import {
  stripFieldsFromInteraction,
  stripFieldsFromDurTask,
} from 'containers/tasks/drug-utilization-review/common';
import { HttpStatusCodes } from 'constants/http-status-codes';
import { TasksArchived } from 'clients/tasks-archived';
import { DataCollectStatus } from 'interfaces/enums/TaskStatuses/DataCollectStatus';
import { NumberUtils } from 'utils/number-utils';
import { logger } from '../winston-logger';

export const updateTaskProperties = (taskType, taskId, properties) => ({
  type: UPDATE_TASK_PROPERTIES,
  payload: {
    taskType,
    taskId,
    properties,
  },
});

export const updateTasksProperties = tasks => ({
  type: UPDATE_TASKS_PROPERTIES,
  payload: {
    tasks,
  },
});

export const integrateUpdatedTasks = tasks => ({
  type: INTEGRATE_UPDATED_TASKS,
  payload: {
    tasks,
  },
});

const friendlyNameFromTaskType = taskType => {
  const parts = taskType.split('_');
  const capitalizedParts = parts.map(part => part.charAt(0).toUpperCase() + part.slice(1));
  return capitalizedParts.join(' ');
};

export const fetchTasksForTherapyByType = (therapyId, taskType) => {
  const request = HTTP.get(`/therapies/${therapyId}/tasks/${taskType}`, {});
  return {
    type: FETCH_TASKS_FOR_THERAPY_BY_TYPE,
    payload: request,
  };
};

export const fetchStatusesForType = taskType => {
  const request = HTTP.get(`/tasks/${taskType}/statuses`, {});
  return {
    type: FETCH_STATUSES_FOR_TYPE,
    payload: request,
  };
};

export function addTaskToState(taskId, taskType, newTask) {
  return {
    type: ADD_TASK,
    payload: {
      data: {
        added_task: [
          {
            ...newTask,
            id: taskId,
            taskType: taskType,
          },
        ],
      },
    },
  };
}

export function addTask(newTask, taskType) {
  const postURL = `/tasks/${taskType}`;
  const friendlyTaskType = friendlyNameFromTaskType(taskType);
  return {
    type: ADD_TASK,
    payload: HTTP.post(postURL, newTask, {}),
    meta: {
      errorMessage: `${friendlyTaskType} Add Failed: `,
      successMessage: `${friendlyTaskType} Added Successfully`,
    },
  };
}

export const fetchTask = (taskType, taskId) => {
  const request = HTTP.get(`/tasks/${taskType}/${taskId}`, {});
  return {
    type: FETCH_TASK,
    payload: request,
  };
};

export const cancelPATask = taskId => {
  const patchUrl = `/task/cancel/pa/${taskId}`;
  const request = HTTP.patch(patchUrl, {});
  return {
    type: CANCEL_PA_TASK,
    payload: request,
  };
};

export const fetchArchivedTasksForTaskTabv2 = (
  patientId,
  therapyIds,
  patientLevelTaskTypes,
  filteredTaskTypes,
) => {
  const searches = [];

  if (therapyIds.length && patientId) {
    let arrTaskTypes = [];
    if (filteredTaskTypes && filteredTaskTypes.length) {
      arrTaskTypes = filteredTaskTypes.map(item => item.value);
    }
    const request = TasksArchived.getTherapyArchivedTasks(patientId, therapyIds, arrTaskTypes);
    searches.push(request);
  }

  if (patientLevelTaskTypes && patientLevelTaskTypes.length) {
    const request = TasksArchived.getPatientArchivedTasks(patientId, patientLevelTaskTypes);
    searches.push(request);
  }

  return Promise.all(searches)
    .then(results => {
      let batchedResults = [];
      results.forEach(item => {
        if (item.status === HttpStatusCodes.OK) {
          batchedResults = batchedResults.concat(item.data.tasks);
        }
      });
      return {
        type: DATA_THERAPY_ARCHIVED_TASKS,
        payload: { data: { tasks: batchedResults } },
      };
    })
    .catch(e => {
      logger.error('Failed to load archived tasks: ', e);
      return {
        type: DATA_THERAPY_ARCHIVED_TASKS,
        payload: { data: { tasks: [] } },
      };
    });
};

export function updateFCDispensingPharmacyInRedux(updatedPayload, onSuccess, onFail) {
  return {
    type: UPDATE_FC_DISPENSING_PHARMACY,
    payload: {
      updatedPayload,
    },
  };
}
export function editTask(updatedTask, taskType) {
  logEvent(Therapies.category, Therapies.actions.single_task_selected, `Edited: ${taskType}`);

  const patchUrl = `/tasks/${taskType}/${updatedTask.id}`;
  const request = HTTP.patch(patchUrl, updatedTask, {});
  const friendlyTaskType = friendlyNameFromTaskType(taskType);
  return {
    meta: {
      errorMessage: `${friendlyTaskType} Edit Failed: `,
      successMessage: `${friendlyTaskType} Edited Successfully`,
    },
    type: EDIT_TASK,
    payload: Promise.all([request, taskType]),
  };
}

export function updateFdcStatusForPrintedOrder(payload) {
  return {
    type: UPDATE_FDC_STATUS,
    payload: payload,
  };
}

export const fetchTaskCounts = payload => ({
  type: DATA_TASK_COUNTS_FETCH,
  payload,
});

export const fetchSpecificQueueTaskCountSuccess = (queueType, count) => ({
  type: FETCH_SPECIFIC_QUEUE_TASK_COUNT_SUCCESS,
  payload: {
    queueType,
    count,
  },
});

export function setTherapyId(therapyId) {
  return {
    type: SET_THERAPY_ID,
    payload: therapyId,
  };
}

// ADD
export function addPa(newTask) {
  const postURL = '/tasks/pa';
  const request = HTTP.post(postURL, newTask, {});
  return {
    meta: {
      errorMessage: 'Prior Authorization Add Failed: ',
      successMessage: 'Prior Authorization Added Successfully',
    },
    type: TASK_LIFECYCLE_ACTIONS.ADD.PRIOR_AUTHORIZATION,
    payload: request,
  };
}

export function addThirdPartyReferral(newTask) {
  const postURL = '/tasks/tpr';
  const request = HTTP.post(postURL, newTask, {});
  return {
    type: ADD_THIRD_PARTY_REFERRAL,
    payload: request,
  };
}

export function addFa(newTask) {
  const postURL = '/tasks/fa';
  const request = HTTP.post(postURL, newTask, {});
  return {
    meta: {
      errorMessage: 'Financial Assistance Add Failed: ',
      successMessage: 'Financial Assistance Added Successfully',
    },
    type: TASK_LIFECYCLE_ACTIONS.ADD.FINANCIAL_ASSISTANCE,
    payload: request,
  };
}

export function addFillDeliveryConfirmationTask(newTask) {
  const postURL = '/tasks/fdc';
  const request = HTTP.post(postURL, newTask, {});
  return {
    meta: {
      errorMessage: 'Fill Delivery Confirmation Add Failed: ',
      successMessage: 'Fill Delivery Confirmation Added Successfully',
    },
    type: TASK_LIFECYCLE_ACTIONS.ADD.FILL_DELIVERY_CONFIRMATION,
    payload: request,
  };
}

export function addDataCollectTask(newTask) {
  const postURL = '/tasks/data-collect';
  const request = HTTP.post(postURL, newTask, {});
  return {
    meta: {
      errorMessage: 'Data Collect Add Failed: ',
      successMessage: 'Data Collect Added Successfully',
    },
    type: TASK_LIFECYCLE_ACTIONS.ADD.DATA_COLLECT,
    payload: request,
  };
}

// EDIT

export function editPa(updatedTask) {
  const patchUrl = `/tasks/pa/${updatedTask.id}`;
  const request = HTTP.patch(patchUrl, updatedTask, {});
  return {
    meta: {
      errorMessage: 'PA Edit Failed: ',
      successMessage: 'PA Edited Successfully',
    },
    type: EDIT_PA,
    payload: request,
  };
}

export function editFa(updatedTask) {
  const patchUrl = `/tasks/fa/${updatedTask.id}`;
  const request = HTTP.patch(patchUrl, updatedTask, {});
  return {
    type: EDIT_FA,
    payload: request,
    meta: {
      successMessage: 'Financial Assistance Edited Successfully',
      errorMessage: 'Financial Assistance Edited Failed',
    },
  };
}

export function editThirdPartyReferral(updatedTask) {
  const patchUrl = `/tasks/tpr/${updatedTask.id}`;
  const request = HTTP.patch(patchUrl, updatedTask, {});
  return {
    type: EDIT_THIRD_PARTY_REFERRAL,
    payload: request,
    meta: {
      errorMessage: 'Third Party Referral Edit failed',
      successMessage: 'Third Party Referral Edit Successfully',
    },
  };
}

export function editMedicationReview(updatedTask) {
  const patchUrl = `/tasks/mr/${updatedTask.id}`;
  const request = HTTP.patch(patchUrl, updatedTask, {});
  return {
    type: TASK_LIFECYCLE_ACTIONS.EDIT.MEDICATION_REVIEW,
    payload: request,
    meta: {
      errorMessage: 'Medication Review Edit failed',
      successMessage: 'Medication Review Edit Successfully',
    },
  };
}

export function editFillCoordination(updatedTask) {
  const patchUrl = `/tasks/fc/${updatedTask.id}`;
  const request = HTTP.patch(patchUrl, updatedTask, {});
  return {
    meta: {
      errorMessage: 'Fill Coordination edit failed',
      successMessage: 'Fill Coordination edited Successfully',
    },
    type: TASK_LIFECYCLE_ACTIONS.EDIT.FILL_COORDINATION,
    payload: request,
  };
}

export function editFillDeliveryConfirmation(updatedTask) {
  const patchUrl = `/tasks/fdc/${updatedTask.id}`;
  const request = HTTP.patch(patchUrl, updatedTask, {});
  return {
    type: TASK_LIFECYCLE_ACTIONS.EDIT.FILL_DELIVERY_CONFIRMATION,
    payload: request,
    meta: {
      successMessage: 'Fill Delivery Confirmation edited Successfully',
      errorMessage: 'Fill Delivery Confirmation edited Failed',
    },
  };
}

export function editDataCollect(updatedTask) {
  const patchUrl = `/tasks/data-collect/${updatedTask.id}`;
  const request = HTTP.patch(patchUrl, updatedTask, {});
  return {
    meta: {
      errorMessage: 'Data Collect Edit Failed: ',
      successMessage: 'Data Collect Edited Successfully',
    },
    type: TASK_LIFECYCLE_ACTIONS.EDIT.DATA_COLLECT,
    payload: request,
  };
}

export function editScheduleOutreach(updatedTask) {
  const patchUrl = `/tasks/so/${updatedTask.id}`;
  const request = HTTP.patch(patchUrl, updatedTask, {});
  return {
    meta: {
      errorMessage: 'Schedule Outreach Edit Failed: ',
      successMessage: 'Schedule Outreach Edited Successfully',
    },
    type: TASK_LIFECYCLE_ACTIONS.EDIT.SCHEDULE_OUTREACH,
    payload: request,
  };
}

export function editIncident(updatedTask) {
  const patchUrl = `/tasks/qre/${updatedTask.id}`;
  const request = HTTP.patch(patchUrl, updatedTask, {});
  return {
    meta: {
      errorMessage: 'Incident Edit Failed: ',
      successMessage: 'Incident Edited Successfully',
    },
    type: TASK_LIFECYCLE_ACTIONS.EDIT.INCIDENT,
    payload: request,
  };
}

export function addMedicationReview(newTask) {
  const postURL = '/tasks/mr';
  const request = HTTP.post(postURL, newTask, {});
  return {
    meta: {
      errorMessage: 'Medication Review Add Failed: ',
      successMessage: 'Medication Review Added Successfully',
    },
    type: TASK_LIFECYCLE_ACTIONS.ADD.MEDICATION_REVIEW,
    payload: request,
  };
}

export function addInterventionTask(newTask) {
  const postURL = '/tasks/int';
  const request = HTTP.post(postURL, newTask, {});
  return {
    meta: {
      errorMessage: 'Intervention Add Failed: ',
      successMessage: 'Intervention Added Successfully',
    },
    type: TASK_LIFECYCLE_ACTIONS.ADD.INTERVENTION,
    payload: request,
  };
}

export function addInterventionTaskPatient(newTask) {
  const postURL = '/tasks/int';
  const request = HTTP.post(postURL, newTask, {});
  return {
    meta: {
      errorMessage: 'Intervention Add Failed: ',
      successMessage: 'Intervention Added Successfully',
    },
    type: TASK_LIFECYCLE_ACTIONS.ADD.INTERVENTION_PATIENT,
    payload: request,
  };
}

export function editIntervention(updatedTask) {
  const patchUrl = `/tasks/int/${updatedTask.id}`;
  const request = HTTP.patch(patchUrl, updatedTask, {});
  return {
    meta: {
      errorMessage: 'Intervention Edit Failed: ',
      successMessage: 'Intervention Edited Successfully',
    },
    type: TASK_LIFECYCLE_ACTIONS.EDIT.INTERVENTION,
    payload: request,
  };
}

export function editInterventionPatient(updatedTask) {
  const patchUrl = `/tasks/int/${updatedTask.id}`;
  const request = HTTP.patch(patchUrl, updatedTask, {});
  return {
    meta: {
      errorMessage: 'Intervention Edit Failed: ',
      successMessage: 'Intervention Edited Successfully',
    },
    type: TASK_LIFECYCLE_ACTIONS.EDIT.INTERVENTION_PATIENT,
    payload: request,
  };
}

export function addAppointmentReferralTask(newTask) {
  const postURL = '/tasks/ar';
  const request = HTTP.post(postURL, newTask, {});
  return {
    meta: {
      errorMessage: 'Appointment Referral Add Failed: ',
      successMessage: 'Appointment Referral Added Successfully',
    },
    type: TASK_LIFECYCLE_ACTIONS.ADD.APPOINTMENT_REFERRAL,
    payload: request,
  };
}

export function addOutreachTask(newTask) {
  const postURL = '/tasks/or';
  const request = HTTP.post(postURL, newTask, {});
  return {
    meta: {
      errorMessage: 'Outreach Add Failed: ',
      successMessage: 'Outreach Added Successfully',
    },
    type: TASK_LIFECYCLE_ACTIONS.ADD.OUTREACH,
    payload: request,
  };
}

export function addOutreachTaskPatient(newTask) {
  const postURL = '/tasks/or';
  const request = HTTP.post(postURL, newTask, {});
  return {
    meta: {
      errorMessage: 'Outreach Add Failed: ',
      successMessage: 'Outreach Added Successfully',
    },
    type: TASK_LIFECYCLE_ACTIONS.ADD.OUTREACH_PATIENT,
    payload: request,
  };
}

export function addRiskStratTask(newTask) {
  const postURL = '/tasks/RS';
  const request = HTTP.post(postURL, newTask, {});
  return {
    meta: {
      errorMessage: 'Risk strat Add Failed: ',
      successMessage: 'Risk Strat Added Successfully',
    },
    type: TASK_LIFECYCLE_ACTIONS.ADD.RISK_STRAT,
    payload: request,
  };
}

export function editOutreach(updatedTask) {
  const patchUrl = `/tasks/or/${updatedTask.id}`;
  const request = HTTP.patch(patchUrl, updatedTask, {});
  return {
    meta: {
      errorMessage: 'Outreach Edit Failed: ',
      successMessage: 'Outreach Edited Successfully',
    },
    type: TASK_LIFECYCLE_ACTIONS.EDIT.OUTREACH,
    payload: request,
  };
}

export function editOutreachPatient(updatedTask) {
  const patchUrl = `/tasks/or/${updatedTask.id}`;
  const request = HTTP.patch(patchUrl, updatedTask, {});
  return {
    meta: {
      errorMessage: 'Outreach Edit Failed: ',
      successMessage: 'Outreach Edited Successfully',
    },
    type: TASK_LIFECYCLE_ACTIONS.EDIT.OUTREACH_PATIENT,
    payload: request,
  };
}

export const addInterventionCount = () => ({
  type: TASK_LIFECYCLE_ACTIONS.ADD.INTERVENTION,
});
export function fetchInterventionTasks(therapyId) {
  // API placeholder
  const request = HTTP.get(`/therapies/${therapyId}/interventions`, {});
  return {
    type: TASK_LIFECYCLE_ACTIONS.FETCH.INTERVENTION,
    payload: request,
  };
}

export function addCounselingTask(newTask) {
  const postURL = '/tasks/csl';
  const request = HTTP.post(postURL, newTask, {});
  return {
    meta: {
      errorMessage: 'Counseling Add Failed: ',
      successMessage: 'Counseling Added Successfully',
    },
    type: TASK_LIFECYCLE_ACTIONS.ADD.COUNSELING,
    payload: request,
  };
}

export function editCounseling(updatedTask, newAddendum) {
  const patchUrl = `/tasks/csl/${updatedTask.id}`;
  const request = HTTP.patch(patchUrl, { ...updatedTask, addendum: newAddendum }, {});
  return {
    meta: {
      errorMessage: 'Counseling Edit Failed: ',
      successMessage: 'Counseling Edited Successfully',
    },
    type: TASK_LIFECYCLE_ACTIONS.EDIT.COUNSELING,
    payload: request,
  };
}

export function saveCounselingDraft(taskId, data) {
  const taskType = CSL;
  const putUrl = `/tasks/${taskType}/draft/${taskId}`;
  const request = HTTP.put(putUrl, data, {});
  return {
    meta: {
      errorMessage: 'Counseling Draft Failed To Save: ',
      successMessage: 'Counseling Draft Saved Successfully',
    },
    type: EDIT_COUNSELING_DRAFT,
    payload: request,
  };
}

export const fetchScheduleOutreach = taskId => {
  const request = HTTP.get(`/tasks/so/${taskId}`, {});
  return {
    type: TASK_LIFECYCLE_ACTIONS.FETCH.SCHEDULE_OUTREACH,
    payload: request,
  };
};

export const fetchScheduleOutreachByPatient = patientId => {
  const request = HTTP.get(`/patients/${patientId}/schedule-outreach`, {});
  return {
    type: TASK_LIFECYCLE_ACTIONS.FETCH.SCHEDULE_OUTREACH,
    initialValue: { data: { tasks: [] } },
    payload: request,
  };
};

export const linkScheduleOutreachToTherapy = (taskId, payload) => {
  const request = HTTP.patch(`/tasks/so/${taskId}/links/therapies`, payload, {});
  return {
    type: LINK_SCHEDULE_OUTREACH_THERAPIES,
    payload: request,
  };
};

export const excludeScheduleDrugOutcome = payload => {
  const request = HTTP.post('/exclude-schedule-drug-outcome', payload, {});
  return {
    type: UPDATE_SCHEDULE_DRUG_OUTCOME,
    payload: request,
  };
};

export const addScheduleDrugOutcome = (taskId, payload) => {
  const request = HTTP.post(`/tasks/ar/${taskId}/drug-outcome`, payload, {});
  return {
    type: ADD_SCHEDULE_DRUG_OUTCOME,
    payload: request,
  };
};

export function editTasks(
  patientId,
  tasks,
  orders,
  notes,
  documents,
  newInterventions = [],
  variableParams = {},
  removedFCDetails,
) {
  if (!tasks.length) {
    throw new Error('No tasks selected to update.');
  }
  const sync_therapies_and_fcs_disp_pharm_payload = {};
  if (variableParams.sync_therapies_and_fcs_disp_pharm_payload) {
    sync_therapies_and_fcs_disp_pharm_payload.therapyIds = variableParams.therapyIds;
    sync_therapies_and_fcs_disp_pharm_payload.fcIds = variableParams.fcIds;
    sync_therapies_and_fcs_disp_pharm_payload.pharmacy = variableParams.pharmacy;
  }
  // Strip down the DUR task before sending it to server, remove unneccesary data
  let newInterventionsVar = newInterventions;
  const cleanUpTasksPayload = tasks.map(task => {
    let dcInterveningInterventionForm;
    let dcEfficacySafetyInterveningInterventionForm;

    try {
      dcInterveningInterventionForm =
        task.taskType === DC &&
        task.old_status_id === DataCollectStatus.Intervening &&
        task.intervention_json
          ? JSON.parse(task.intervention_json)
          : [];
    } catch {
      console.log(
        'JSON parse failed, possible invalid values in DC therapy non appropriate intervention form',
      );
      dcInterveningInterventionForm = [];
    }
    try {
      dcEfficacySafetyInterveningInterventionForm =
        task.taskType === DC &&
        task.old_status_id === DataCollectStatus.Intervening &&
        task.intervention_efficacy_safety_json
          ? JSON.parse(task.intervention_efficacy_safety_json)
          : [];
    } catch {
      console.log(
        'JSON parse failed, possible invalid values in DC efficacy safety intervention form',
      );
      dcEfficacySafetyInterveningInterventionForm = [];
    }
    const combinedDCInterventionFormsArr = Array.isArray(dcInterveningInterventionForm)
      ? [...dcInterveningInterventionForm, ...dcEfficacySafetyInterveningInterventionForm]
      : [dcInterveningInterventionForm, ...dcEfficacySafetyInterveningInterventionForm];
    if (task.taskType === DUR) {
      const { interactions, old_interactions: oldInteractions, ...restTask } = task;
      const cleanUpDurTask = {
        ...stripFieldsFromDurTask(restTask),
        interactions:
          interactions && interactions.length
            ? interactions.map(interaction => stripFieldsFromInteraction(interaction))
            : [],
        old_interactions:
          oldInteractions && oldInteractions.length
            ? oldInteractions.map(interaction => stripFieldsFromInteraction(interaction))
            : [],
      };
      return cleanUpDurTask;
    }
    if (
      task.taskType === DC &&
      task.status_id === DataCollectStatus.Intervening &&
      task.intervention_efficacy_safety_json === '[]'
    ) {
      task.status_id = DataCollectStatus.Collected;
    }
    if (
      task.old_status_id === DataCollectStatus.Intervening &&
      (combinedDCInterventionFormsArr.length === 0 ||
        combinedDCInterventionFormsArr.some(
          intervention => !intervention.outcome || intervention.outcome === '',
        )) &&
      task.taskType === DC
    ) {
      task.status_id = DataCollectStatus.Intervening;
      newInterventionsVar = combinedDCInterventionFormsArr;
    }
    if (task.taskType === DC && task.canceled_date) {
      task.status_id = DataCollectStatus.Canceled;
    }
    if (
      task.taskType === DC &&
      task.status_id === DataCollectStatus.Reviewed &&
      task.intervention &&
      Array.isArray(JSON.parse(task.intervention))
    ) {
      newInterventionsVar = task.intervention;
    }
    if (task.taskType === DC && task.data_collect) {
      task.data_collect.map(item => {
        item.value = NumberUtils.ensureLeadingZero(item.value);
        return item;
      });
    }
    if (task.taskType === RS && task.patientClinicalDataItems) {
      task.patientClinicalDataItems.map(item => {
        item.value = NumberUtils.ensureLeadingZero(item.value);
        return item;
      });
    }
    if (Object.keys(task).some(fieldName => fieldName.includes(REDUX_FORM.ALIAS_FIELD))) {
      const aliasedFields = Object.keys(task).filter(fieldName =>
        fieldName.includes(REDUX_FORM.ALIAS_FIELD),
      );
      const aliasedFieldsRenamed = aliasedFields.map(
        fieldName => fieldName.split(REDUX_FORM.ALIAS_FIELD)[0],
      );

      const aliasedObj = aliasedFieldsRenamed.reduce((result, key, i) => {
        result[key] = task[aliasedFields[i]];
        return result;
      }, {});
      task = { ...task, ...aliasedObj };
    }
    return { ...task, patient_id: patientId };
  });

  const request = HTTP.post(`/tasks/bundle/${patientId}`, {
    tasks: cleanUpTasksPayload,
    orders,
    notes,
    documents,
    new_interventions: newInterventionsVar,
    sync_therapies_and_fcs_disp_pharm_payload,
    removed_fc_details: removedFCDetails,
  });

  return {
    type: BULK_TASKS_UPDATE_REQUEST,
    payload: request,
    meta: {
      patientId,
    },
  };
}

export function updatePatientTasks(newTasks) {
  return {
    type: ADD_TASK_TO_PATIENT,
    payload: newTasks,
  };
}

export function fetchRelatedTasks(taskId, taskType) {
  const url = `/tasks/${taskType}/related/${taskId}`;

  const request = HTTP.get(url, {});
  return {
    type: DATA_RELATED_TASKS,
    payload: request,
  };
}

export function refreshWorkList(config) {
  return {
    type: REFRESH_QUEUE_WORKLIST,
    payload: config || {},
  };
}

export function refreshSMSMessageList(config) {
  return {
    type: REFRESH_SMS_MESSAGES,
    payload: config || {},
  };
}

export function updateTaskFollowupDate(payload) {
  return {
    type: UPDATE_TASK_FOLLOWUP_DATE,
    payload: { ...payload },
  };
}

export function updateTaskByIdsFCSelfService(taskIds) {
  return {
    type: FETCH_TASKS,
    payload: { taskIds },
  };
}

export const toggleSelectedTaskRow = taskKeyOrKeys => ({
  type: TOGGLE_SELECTED_TASK_ROW_KEY,
  payload: taskKeyOrKeys,
});

export const clearSelectedTask = taskKeyOrKeys => ({
  type: CLEAR_SELECTED_TASK_KEY,
  payload: taskKeyOrKeys,
});

export const excludeSelectedTask = taskKeyOrKeys => ({
  type: EXCLUDE_SELECTED_TASK_KEY,
  payload: taskKeyOrKeys,
});

export const selectTaskKey = taskKeyOrKeys => ({
  type: SELECT_TASK_KEY,
  payload: taskKeyOrKeys,
});

export const setSelectedTasks = taskKeys => ({
  type: SET_SELECTED_TASK_KEYS,
  payload: taskKeys,
});

export const setWagSelectedTasks = taskKeys => ({
  type: SET_WAG_SELECTED_TASK_KEYS,
  payload: taskKeys,
});

export const clearWagSelectedTask = taskKeyOrKeys => ({
  type: CLEAR_WAG_SELECTED_TASK_KEY,
  payload: taskKeyOrKeys,
});

export const clearSelectedTasks = () => ({
  type: SET_SELECTED_TASK_KEYS,
  payload: [],
});

export const setMergeIntTasks = mergeIntMapping => ({
  type: SET_MERGE_INTERVENTION_TASKS,
  payload: mergeIntMapping,
});

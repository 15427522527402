import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

export const styles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    modal: {
      position: 'absolute',
      width: '92vw',
      backgroundColor: palette.primary.background,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
      borderRadius: 5,
      overflow: 'auto',
      maxHeight: '92vh',
    },
    backdrop: {
      backgroundColor: palette.primary.white,
      zIndex: theme.zIndex.modal + 1,
      opacity: 0.9,
    },
    biRow: {
      borderBottom: `1px solid ${palette.primary.lightGrey}`,
    },
    biRowTypography: {
      padding: `${theme.spacing(2)} ${theme.spacing(0.5)}`,
    },
    selectedBiRow: {
      backgroundColor: palette.primary.grey3,
    },
    unselectedBiRow: {
      'cursor': 'pointer',
      '&:hover': {
        backgroundColor: palette.primary.grey2,
      },
    },
    biHistoryList: {
      overflowX: 'hidden',
      overflowY: 'scroll',
      height: '81vh',
    },
    errorContainer: {
      alignItems: 'center',
      textAlign: 'center',
      height: '100%',
    },
    rawTextFieldRoot: {
      width: '80%',
    },
    rawTextFieldInputBase: {
      backgroundColor: palette.primary.grey2,
    },
  });
};

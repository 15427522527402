import React, { useState, useMemo } from 'react';
import JsonForm from 'components/dynamic-form/json-form';
import useUuid from 'hooks/useUuid';
import formBuilder from '../form-builder';

const components = {
  options: 'radioList',
  range: 'range',
};

export default ({ questionnaires, initialAnswers, onChange, onSubmit, onCancel, disabled }) => {
  const formId = useUuid(6);
  const [answers, setAnswers] = useState(
    questionnaires.reduce((acc, questionnaire) => {
      acc[questionnaire.id] = questionnaire.questions.map((q, index) =>
        initialAnswers && initialAnswers[questionnaire.id]
          ? initialAnswers[questionnaire.id][index]
          : undefined,
      );
      return acc;
    }, {}),
  );

  const providers = questionnaires.reduce((acc, questionnaire) => {
    questionnaire.questions.forEach((question, questionIndex) => {
      acc[`options-question-${questionnaire.id}-${questionIndex}`] = question?.options?.map(
        option => ({
          id: option.value,
          name: option.label,
        }),
      );
      acc[`range-limit-question-${questionnaire.id}-${questionIndex}`] = {
        max: question.maxText,
        min: question.minText,
      };
      if (question && question.options) {
        question.options.forEach((option, optionIndex) => {
          if (option.subQuestion) {
            acc[`options-question-${questionnaire.id}-${questionIndex}-sub-${optionIndex}`] =
              option.subQuestion.options.map(subOption => ({
                id: subOption.value,
                name: subOption.label,
              }));
            acc[`range-limit-question-${questionnaire.id}-${questionIndex}-sub-${optionIndex}`] = {
              max: option.subQuestion.maxText,
              min: option.subQuestion.minText,
            };
          }
        });
      }
    });
    return acc;
  }, {});

  const json = useMemo(() => {
    const builder = formBuilder().form('questionnaire');
    let generalIndex = 1;
    let sectionPos;
    questionnaires.forEach(questionnaire => {
      questionnaire.questions.forEach((question, questionIndex) => {
        const questionId = `question-${questionnaire.id}-${questionIndex}`;
        const labelId = `label-${questionnaire.id}-${questionIndex}`;
        if (
          questionnaire.sections !== undefined &&
          Array.isArray(questionnaire.sections) &&
          questionnaire.sections.length &&
          question.sectionPos !== undefined &&
          question.sectionPos !== sectionPos
        ) {
          builder.addField({
            id: labelId,
            property: labelId,
            type: 'qlabel',
            component: 'label',
            label: questionnaire.sections[question.sectionPos]?.title || '',
          });
          sectionPos = question.sectionPos;
        } else if (question.type === 'text') {
          builder.addField({
            id: questionId,
            property: questionId,
            type: 'text',
            component: question.isActive === false ? 'hidden' : components[question.type] || 'text',
            noPadding: question.isActive === false,
            provider: `options-${questionId}`,
            rangeProvider: `range-limit-${questionId}`,
            label: `${generalIndex}. ${question.question}`,
            gridSize: 12,
            required: true,
            disabled,
            qaId: `question_${generalIndex}`,
            maxLength: question.maxLength ?? '',
            validCharacters: question.validCharacters ?? undefined,
          });
        }
        if (question.type !== 'text') {
          builder.addField({
            id: questionId,
            property: questionId,
            type: 'text',
            component: question.isActive === false ? 'hidden' : components[question.type] || 'text',
            noPadding: question.isActive === false,
            provider: `options-${questionId}`,
            rangeProvider: `range-limit-${questionId}`,
            label: `${generalIndex}. ${question.question}`,
            gridSize: 12,
            required: true,
            disabled,
            qaId: `question_${generalIndex}`,
            maxLength: '2',
          });
        }
        if (question && question.options) {
          question.options.forEach((option, optionIndex) => {
            if (option.subQuestion) {
              builder.addField({
                id: `${questionId}-sub-${optionIndex}`,
                property: `${questionId}-sub-${optionIndex}`,
                type: 'text',
                component:
                  question.isActive === false ? 'hidden' : components[question.type] || 'text',
                noPadding: question.isActive === false,
                provider: `options-${questionId}-sub-${optionIndex}`,
                rangeProvider: `range-limit-${questionId}-sub-${optionIndex}`,
                label: `- ${option.subQuestion.question}`,
                gridSize: 12,
                required: true,
                disabled,
                qaId: `question_${generalIndex}_subQuestion_${optionIndex}`,
                visible: {
                  or: [
                    {
                      field_id: questionId,
                      operator: 'eq',
                      value: String(option.value),
                    },
                    {
                      field_id: questionId,
                      operator: 'eq',
                      value: option.value,
                    },
                  ],
                },
              });
            }
          });
        }
        generalIndex += question.isActive === false ? 0 : 1;
      });
    });
    return builder.build();
  }, [questionnaires, disabled]);

  const data = questionnaires.reduce((acc, questionnaire) => {
    const listOfAnswers = answers[questionnaire.id] || [];
    listOfAnswers.forEach((value, index) => {
      const question = questionnaire.questions[index];
      const selectedOption = question?.options?.find(option => {
        if (question.type === 'text' && value) {
          return true;
        }
        if (option.subQuestion) {
          return option.subQuestion.options.some(it => String(it.value) === String(value));
        }
        return String(option.value) === String(value);
      });
      const prefix = `question-${questionnaire.id}-${index}`;
      if (selectedOption) {
        if (selectedOption.subQuestion) {
          const optionIndex = question.options.indexOf(selectedOption);
          acc[prefix] =
            selectedOption.value !== null && selectedOption.value !== undefined
              ? String(selectedOption.value)
              : null;
          acc[`${prefix}-sub-${optionIndex}`] = String(value);
        } else {
          acc[prefix] = String(value);
        }
      }
    });
    return acc;
  }, {});

  return (
    <>
      <JsonForm
        jsonForm={json}
        formName="questionnaire"
        formId={`questionnaire-${formId}`}
        data={data}
        providers={providers}
        onFormChange={values => {
          const newValues = questionnaires.reduce((acc, questionnaire) => {
            questionnaire.questions.forEach((question, index) => {
              if (!acc[questionnaire.id]) {
                acc[questionnaire.id] = [];
              }
              const key = `question-${questionnaire.id}-${index}`;
              const value = Object.prototype.hasOwnProperty.call(values, key)
                ? values[key]
                : undefined;
              const selectedOption = question?.options?.find(
                option => String(option.value) === String(value),
              );
              if (selectedOption && selectedOption.subQuestion) {
                const selectedOptionIndex = question.options.indexOf(selectedOption);
                const subKey = `${key}-sub-${selectedOptionIndex}`;
                const subValue = Object.prototype.hasOwnProperty.call(values, subKey)
                  ? values[subKey]
                  : undefined;
                acc[questionnaire.id].push(subValue);
              } else {
                acc[questionnaire.id].push(value);
              }
            });
            return acc;
          }, {});
          setAnswers(newValues);
          if (onChange) {
            onChange(newValues);
          }
        }}
        avoidReinitialize
        alwaysEnabled
        onSubmit={onSubmit ? () => onSubmit(answers) : null}
        onCancel={onCancel}
      />
    </>
  );
};

import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { ModalUtil } from 'services/utils/modal.util';
import { expandTheme } from 'utils/theme-util';

export const AddNewProductModalStyles = (theme: Theme) => {
  const { font, palette } = expandTheme(theme);

  return createStyles({
    modal: {
      ...ModalUtil.BasicModalStyling(theme, 80, 3),
      width: theme.spacing(80),
    },
    header: {
      fontWeight: 'bold',
      fontSize: font.extraLargeFontSize,
      marginBottom: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
    childItemContainer: {
      cursor: 'pointer',
    },
    childCheckItem: {
      paddingLeft: theme.spacing(3.3),
      paddingTop: theme.spacing(1),
    },
    alreadyInGtdItem: {
      opacity: 0.6,
    },
    itemsContainer: {
      maxHeight: '15vh',
      overflowY: 'auto',
    },
    loadingIndicator: {
      marginLeft: theme.spacing(1),
    },
    actionButton: {
      backgroundColor: palette.primary.patina,
    },
    tableContainer: {
      maxHeight: '37vh',
    },
  });
};

import { TableSortLabel, TableSortLabelProps } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { defaultTheme } from '../../../lib/themes';

export const CustomSortLabel = withStyles({
  root: {
    '&:hover': {
      color: defaultTheme.palette.primary.steelGrey,
    },
  },
})((props: TableSortLabelProps) => <TableSortLabel {...props} />);

import { Grid, Divider } from '@mui/material';
import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'interfaces/redux/IState';
import { IProfileTherapy, IPatientMergeSectionDetailsProps } from 'components/patient-merge-modal/interfaces';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { useTheme } from '@mui/styles';
import { TherapyInfo } from 'components/patient-merge-modal/info-component/therapy-info';
import { PatientMergeClient } from 'clients/patient-merge';
import { LookupResourceTypes } from 'constants/enums';

export const ConfirmTherapySection = (props: IPatientMergeSectionDetailsProps) => {
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);
  const { mergeId, expanded } = props;

  const [therapies, setTherapies] = useState<IProfileTherapy[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [primaryTherapies, setPrimaryTherapies] = useState<IProfileTherapy[]>([]);
  const [selectedTherapies, setSelectedTherapies] = useState<IProfileTherapy[]>([]);
  const [additionalTherapies, setAdditionalTherapies] = useState<IProfileTherapy[]>([]);

  const primaryId = useSelector((state: IState) => state.patientMerge.primary);
  const selectedId = useSelector((state: IState) => state.patientMerge.duplicate);
  const inputs = useSelector((state: IState) => state.patientMerge.inputs);

  useEffect(() => {
    if (expanded && !loaded) {
      PatientMergeClient.getMergeTherapyInfo(mergeId).then(response => {
        setTherapies(response.data);
        setLoaded(true);
      });
    }
  }, [mergeId, expanded]);

  useEffect(() => {
    setPrimaryTherapies(
      therapies.filter((therapy: IProfileTherapy) => therapy.patientId === primaryId),
    );
    setSelectedTherapies(
      therapies.filter((therapy: IProfileTherapy) => therapy.patientId === selectedId),
    );
  }, [therapies, primaryId, selectedId]);

  useEffect(() => {
    const ids = inputs
      .filter(input => input.type === LookupResourceTypes.Therapy)
      .map(input => input.selectedId);
    if (selectedTherapies?.length) {
      setAdditionalTherapies(
        selectedTherapies.filter((therapy: IProfileTherapy) => ids.includes(therapy.id)),
      );
    }
  }, [inputs, selectedTherapies]);

  return (
    <Grid container rowSpacing={3} sx={classes.confirmTherapiesWrp}>
      {additionalTherapies.map((therapy, idx) => {
        return (
          <Fragment key={`additional-therapy-${therapy.id}`}>
            <Grid item sx={classes.newItem} xs={12}>
              <TherapyInfo key={`additional-${therapy.id}`} therapy={therapy} />
            </Grid>
            {idx < additionalTherapies.length - 1 && (
              <Grid item xs={12}>
                <Divider sx={classes.divider} light />
              </Grid>
            )}
          </Fragment>
        );
      })}
      {!!primaryTherapies.length && !!additionalTherapies.length && (
        <Grid item xs={12}>
          <Divider sx={classes.divider} light />
        </Grid>
      )}
      {primaryTherapies.map((therapy, idx) => {
        return (
          <Fragment key={`primary-therapy-${therapy.id}`}>
            <Grid item xs={12}>
              <TherapyInfo key={`primary-${therapy.id}`} therapy={therapy} />
            </Grid>
            {idx < primaryTherapies.length - 1 && (
              <Grid item xs={12}>
                <Divider sx={classes.divider} light />
              </Grid>
            )}
          </Fragment>
        );
      })}
    </Grid>
  );
};

import { FETCH_ORDERS_REQUEST, FETCH_ORDERS_SUCCESS, SELECTED_PATIENT_ID } from 'constants/index';

const initialState = {
  patientId: null,
  list: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SELECTED_PATIENT_ID:
      return initialState;
    case FETCH_ORDERS_REQUEST:
      return {
        patientId: payload.patientId,
        list: {},
      };
    case FETCH_ORDERS_SUCCESS:
      return {
        patientId: payload.patientId,
        list: payload.orders.reduce((acc, order) => {
          acc[order.id] = order;
          return acc;
        }, {}),
      };
    default:
      return state;
  }
};

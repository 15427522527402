import React, { useState, useEffect, useRef } from 'react';
import { Sms } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { smsInfo } from 'services/utils/communication-service';
import { QuestionnaireClient } from 'clients/questionnaire';
import { sendQuestionnaire } from 'actions/action-clinical-data';
import { logEvent } from 'services/utils/analytics';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { invalidateExternalUrl } from 'actions/action-communication';
import dataCollectOnDemandJson from 'config/tasks/data-collect-on-demand.json';
import {
  SMS_COMPLETE_QUESTIONNAIRE_LINK_MSG,
  QUESTIONNAIRE_LINK,
  PATIENT_QUESTIONNAIRE_ACTIONS,
  PATIENT_QUESTIONNAIRE,
} from 'constants/index';
import { logger } from '../../../winston-logger';
import InfoSmsPopup from './popup';

const config = dataCollectOnDemandJson;

config.fields.find(f => f.id === 'text').validation = [
  'required',
  text => {
    if (!(text || '').includes(QUESTIONNAIRE_LINK)) {
      return `The message must include ${QUESTIONNAIRE_LINK}`;
    }
    return undefined;
  },
];

export default function StyledComponents(props) {
  const {
    smsId,
    questionnaire,
    questionnaireDataItemIds,
    dataCollectId,
    externalUrlId,
    isQuestionnaireSubmitted,
    submittedBy,
    questionnaireSubmittedDate,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const { id: patientId } = useSelector(state => state.patient);

  const divRef = useRef();

  const [infoPopupData, setInfoPopupData] = useState();

  const handleClick = async () => {
    setAnchorEl(divRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (smsId && anchorEl) {
      smsInfo(smsId, externalUrlId)
        .then(({ data: { info } }) => {
          if (info && questionnaire) setInfoPopupData({ ...info, questionnaire });
        })
        .catch(err => err);
    }
  }, [anchorEl, smsId, questionnaire]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : null;

  const dispatch = useDispatch();

  const handleOnSendReminder = async () => {
    try {
      const payload = {
        patient_id: patientId,
        msg: SMS_COMPLETE_QUESTIONNAIRE_LINK_MSG.replace('#PHARMACY#', ''),
        patient_clinical_data_item_ids: questionnaireDataItemIds,
        dc_id: dataCollectId,
        phone: infoPopupData.phoneNumber,
        externalUrlId,
      };
      const response = await QuestionnaireClient.sendQuestionnaire(payload);
      const { url_shorten: urlShorten, sms_response: smsResponse } = response.data;
      const channel = smsResponse ? 'SMS' : 'email';
      logEvent(
        PATIENT_QUESTIONNAIRE,
        PATIENT_QUESTIONNAIRE_ACTIONS.SEND,
        // eslint-disable-next-line max-len
        `Questionnaire(${urlShorten}) link reminder sent to patient #${patientId} by ${channel}`,
      );
      const action = sendQuestionnaire(response.data);
      dispatch(action);
      dispatch(notifySuccess('Questionnaire Reminder Sent Successfully'));
    } catch (error) {
      logger.error(error);
      dispatch(notifyError('Failed to Send Questionnaire'));
    }
    handleClose();
  };

  const handleOnDeleteSms = () => {
    dispatch(invalidateExternalUrl(externalUrlId));
    handleClose();
  };

  return (
    <div ref={divRef}>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        name="infoSmsPopup_button"
        size="small"
      >
        <Sms />
      </IconButton>
      {infoPopupData && (
        <InfoSmsPopup
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          onSendReminder={handleOnSendReminder}
          onDeleteSms={handleOnDeleteSms}
          externalUrlId={externalUrlId}
          onClick={e => {
            e.stopPropagation();
          }}
          {...infoPopupData}
          isQuestionnaireSubmitted={isQuestionnaireSubmitted}
          submittedBy={submittedBy}
          questionnaireSubmittedDate={questionnaireSubmittedDate}
        />
      )}
    </div>
  );
}

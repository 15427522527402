import React, { Fragment } from 'react';
import compose from 'recompose/compose';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { convertToArborDate } from 'models/time/arbor-date';
import { getStatusNameByStatusId, getLookupValById } from 'services/utils/task-service';
import { connect } from 'react-redux';
import { CounselingTaskType } from 'constants/enums';
import { styles } from './task-detail-styles';
import { getDeliveryMethodLabel } from './fill-coordination/fc-util';

// eslint-disable-next-line no-confusing-arrow
const dateFormatter = () => value => value ? convertToArborDate(value).getCustomerDate(true) : '';
const statusFormatter = (value, props) => getStatusNameByStatusId(value, props.taskStatuses);
const deliveryFormatter = (value, props) =>
  // eslint-disable-next-line camelcase
  getDeliveryMethodLabel(props.task?.shipping_method, Number(value));
const hubFormatter = (value, props) => {
  return props.tprHubs ? props.tprHubs.find(m => m.id === Number(value)).label : '';
};

// eslint-disable-next-line no-confusing-arrow
const findByIdFormatter = (listName, labelField) => (value, props) =>
  value ? getLookupValById(value, props[listName], labelField) : '';

const poundFormatter = value => (!Number.isNaN(value) ? `#${value}` : '');

const collapsedTaskDetails = {
  PA: [
    { name: 'status_id', format: statusFormatter },
    { name: 'expiration_date', label: 'Expiration', format: dateFormatter() },
  ],
  FA: [
    { name: 'status_id', format: statusFormatter },
    { name: 'expiration_date', label: 'Expiration', format: dateFormatter() },
  ],
  FC: [
    { name: 'status_id', format: statusFormatter },
    { name: 'fill_cycle_number', label: 'Fill Cycle', format: poundFormatter },
    { name: 'needsby_date', format: dateFormatter() },
    {
      name: 'preferred_rx_delivery_method',
      label: 'Delivery Method',
      format: deliveryFormatter,
    },
    { name: 'delivery_dt', format: dateFormatter() },
  ],
  FDC: [
    { name: 'status_id', format: statusFormatter },
    { name: 'fill_cycle_number', label: 'Fill Cycle', format: poundFormatter },
    { name: 'needsby_date', format: dateFormatter() },
    { name: 'updated', label: 'Date', format: dateFormatter() },
  ],
  DC: [{ name: 'completed_dt', label: 'Completed', format: dateFormatter() }],
  TPR: [
    { name: 'update', label: 'Completed', format: dateFormatter() },
    { name: 'hub', label: 'Hub', format: hubFormatter },
  ],
  INT: [
    { name: 'update', label: 'Completed', format: dateFormatter() },
    {
      name: 'category_id',
      label: 'Category',
      format: findByIdFormatter('interventionCategories', 'category_name'),
    },
    {
      name: 'type_id',
      label: 'Type',
      format: findByIdFormatter('interventionTypes', 'type_name'),
    },
  ],
  CSL: [
    { name: 'completed_dt', label: 'Completed', format: dateFormatter() },
    {
      name: 'counseling_type',
      label: 'Type',
      format: value => {
        switch (value) {
          case CounselingTaskType.Initial:
            return 'Initial';
          case CounselingTaskType.Ongoing:
            return 'Ongoing';
          case CounselingTaskType.CheckIn:
            return 'Check-In';
          default:
            return '-';
        }
      },
    },
  ],
  MR: [
    { name: 'meds_reviewed_date', label: 'Completed', format: dateFormatter() },
    {
      name: 'interaction',
      label: 'Result',
      format: findByIdFormatter('medicationReviewInteraction', 'interaction'),
    },
  ],
  OR: [
    { name: 'update', label: 'Completed', format: dateFormatter() },
    {
      name: 'category',
      label: 'Category',
      format: findByIdFormatter('outreachCategories', 'name'),
    },
    {
      name: 'type',
      label: 'Type',
      format: findByIdFormatter('outreachTypes', 'name'),
    },
  ],
};

const TaskProperties = props => {
  const { task, classes } = props;
  const options = collapsedTaskDetails[task.taskType] || [];
  return (
    <>
      {options.map(option => {
        const value = option.format
          ? option.format.call(null, task[option.name], props)
          : task[option.name];
        return value ? (
          <Typography
            className={classes.taskTitleDetails}
            key={option.name}
            id={`${task.therapy_id}_${task.taskType}_${task.id}_properties_${option.name}`}
          >
            {`${option.label ? `${option.label}: ` : ''}${value}`}
          </Typography>
        ) : null;
      })}
    </>
  );
};

function mapStateToProps(state) {
  const {
    tasks: {
      taskStatuses,
      intervention_categories: interventionCategories,
      intervention_types: interventionTypes,
      medication_review_interaction: medicationReviewInteraction,
      outreach_categories: outreachCategories,
      outreach_types: outreachTypes,
    },
  } = state;
  return {
    taskStatuses,
    interventionCategories,
    interventionTypes,
    medicationReviewInteraction,
    outreachCategories,
    outreachTypes,
  };
}

export default compose(withStyles(styles), connect(mapStateToProps))(TaskProperties);

import {
  CATEGORY_ARCHIVED,
  CATEGORY_DONE,
  CLEAR_ARCHIVED_TASKS,
  DATA_TASKS_REQUEST,
  DATA_TASKS_SUCCESS,
  DATA_TASKS_FAILURE,
  SELECTED_PATIENT_ID,
  TASK_LIFECYCLE_ACTIONS,
  ADD_THIRD_PARTY_REFERRAL,
  EDIT_THIRD_PARTY_REFERRAL,
  ADD_THERAPY,
  ADD_THERAPY_AR,
  EDIT_THERAPY,
  EDIT_PA,
  EDIT_FA,
  DUR_TRIGGER_CHECK_INTERACTION_SUCCESS,
  DUR_STATUS_TRANSITION_SUCCESS,
  BULK_TASKS_UPDATED,
  UPDATE_TASK_PROPERTIES,
  UPDATE_TASKS_PROPERTIES,
  UPDATE_FC_DISPENSING_PHARMACY,
  UPDATE_TASK_LIST_ITEMS,
  ADD_TASK,
  LINK_SCHEDULE_OUTREACH_THERAPIES,
  UPDATE_SCHEDULE_DRUG_OUTCOME,
  ADD_SCHEDULE_DRUG_OUTCOME,
  DUR_CREATE_NEW_SUCCESS,
  DUR_TOGGLE_SHOW_NEW_INTERACTIONS,
  DUR_FETCH_BY_ID_SUCCESS,
  DUR,
  DATA_THERAPY_ARCHIVED_TASKS,
  DATA_PATIENT_ARCHIVED_TASKS,
  DATA_RELATED_TASKS,
  DISCHARGE_PATIENT,
  FETCH_TASK,
  EDIT_PROBLEM_THERAPIES_ENROLLMENT_SUCCESS,
  UPDATE_TASK_FOLLOWUP_DATE,
  SET_SELECTED_TASK_KEYS,
  SET_DUR_HAS_INTERVENTION,
  ADDED_PAYMENT_METHOD,
  SET_SHOW_SET_SELECTED_TASKS,
  FETCH_TASKS_SUCCESS,
  INTEGRATE_UPDATED_TASKS,
  CLEAR_DRAFT_TASK,
  SET_DRAFT_TASK,
  RESET_DRAFT_TASK_STATE,
  SET_WAG_SELECTED_TASK_KEYS,
  CLEAR_WAG_SELECTED_TASK_KEY,
  EXCLUDE_SELECTED_TASK_KEY,
  SET_MERGE_INTERVENTION_TASKS,
  UPDATE_FDC_STATUS,
} from 'constants/index';
import moment from 'moment';
import omit from 'lodash/omit';
import { getInProgressStatus, taskTypeToPropName } from 'services/utils/task-service';
import { isValidArray } from 'services/utils/common-service';
import { combineWithState } from 'services/utils/reducer-service';
import { isVirtualCard } from 'containers/tasks/fill-coordination/payment-methods/payment-method-utils';
import { getTaskKey } from 'containers/patient/tasks-new/tasks-table/utils';
import { getReducerDataInitialState, convertListToMap, convertMapToList } from './helper-reducer';

const initialState = getReducerDataInitialState({
  selectedTasks: [],
  draftTasks: {},
  selectedTaskKeys: [],
  durFormsWithSelectedINT: [],
  virtualCards: [],
  showSelectedTasks: false,
  selectedWagTaskKeys: [],
});

const sortTaskListAndAddOrderProp = tasks => {
  // copying tasks to don't affect it in place when sorting
  const list = [...tasks];
  return list
    .sort((a, b) => {
      let statusA = a.status_category_id;
      let statusB = b.status_category_id;
      // give 1 and 2 the same priority
      const inProgressStatuses = getInProgressStatus();
      if (inProgressStatuses.indexOf(statusA) > -1) {
        statusA = 1;
      }
      if (inProgressStatuses.indexOf(statusB) > -1) {
        statusB = 1;
      }
      if (statusA === statusB) {
        const dateA = a.followup_dt ? new Date(a.followup_dt) : new Date();
        const dateB = b.followup_dt ? new Date(b.followup_dt) : new Date();
        return dateA - dateB;
      }
      return statusA - statusB;
    })
    .map((task, index) => ({
      ...task,
      order: index,
    }));
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SELECTED_TASK_KEYS: {
      const dedup = [...new Set(payload)];
      return {
        ...state,
        selectedTaskKeys: dedup,
        selectedWagTaskKeys: !dedup.length ? dedup : state.selectedWagTaskKeys,
        durFormsWithSelectedINT: [],
      };
    }
    case SET_WAG_SELECTED_TASK_KEYS: {
      const dedup = [...new Set(payload)];
      return {
        ...state,
        selectedWagTaskKeys: dedup,
        durFormsWithSelectedINT: [],
      };
    }
    case CLEAR_WAG_SELECTED_TASK_KEY: {
      const dedup = [...new Set(payload)];
      return {
        ...state,
        selectedWagTaskKeys: state.selectedWagTaskKeys.filter(t => !dedup.includes(t)),
        durFormsWithSelectedINT: [],
      };
    }
    case EXCLUDE_SELECTED_TASK_KEY: {
      const dedup = [...new Set(payload)];
      return {
        ...state,
        selectedTaskKeys: state.selectedTaskKeys.filter(t => !dedup.includes(t)),
        durFormsWithSelectedINT: [],
      };
    }
    case SET_MERGE_INTERVENTION_TASKS: {
      return {
        ...state,
        mergeInterventionMapping: payload,
      };
    }
    case SELECTED_PATIENT_ID: {
      return {
        ...state,
        loading: true,
        loaded: false,
        data: {},
        draftTasks: {},
      };
    }
    case DATA_TASKS_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false,
        data: {},
      };
    }
    case DATA_TASKS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: convertListToMap(sortTaskListAndAddOrderProp(payload), ['taskType', 'id']),
      };
    }
    case CLEAR_ARCHIVED_TASKS: {
      const filteredTasks = {};

      Object.keys(state.data).forEach(key => {
        const task = state.data[key];
        const isActive = task.status_category_id
          ? [CATEGORY_DONE, CATEGORY_ARCHIVED].indexOf(task.status_category_id) === -1
          : false;
        const preserveTaskTypes = [DUR];
        const updatedToday = moment.utc(task.updated).isSame(moment.utc(), 'day');
        if (isActive || updatedToday || preserveTaskTypes.includes(task.taskType)) {
          filteredTasks[key] = state.data[key];
        }
      });
      return {
        ...state,
        data: filteredTasks,
      };
    }
    case DATA_PATIENT_ARCHIVED_TASKS:
    case DATA_THERAPY_ARCHIVED_TASKS: {
      // Merging related tasks with current redux store tasks
      const sortedMergedTasksData = sortTaskListAndAddOrderProp([
        ...convertMapToList(state.data),
        ...payload.data.tasks,
      ]);

      return {
        ...state,
        data: convertListToMap(sortedMergedTasksData, ['taskType', 'id']),
      };
    }
    case DATA_RELATED_TASKS: {
      // Merging related tasks with current redux store tasks
      const sortedMergedTasksData = sortTaskListAndAddOrderProp([
        ...payload.data,
        ...convertMapToList(state.data),
      ]);

      return {
        ...state,
        data: convertListToMap(sortedMergedTasksData, ['taskType', 'id']),
      };
    }
    case FETCH_TASK: {
      if (payload.data && payload.data.task) {
        const [task] = payload.data.task;
        return combineWithState(state, {
          [`${task.taskType}${task.id}`]: task,
        });
      }
      return state;
    }
    case FETCH_TASKS_SUCCESS: {
      if (payload?.tasks?.length) {
        const { tasks } = payload;
        let newState = state;
        tasks.forEach(task => {
          newState = combineWithState(newState, {
            [`${task.taskType}${task.id}`]: task,
          });
        });
        return newState;
      }

      return state;
    }
    case DATA_TASKS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        data: {},
        error: true,
      };
    }
    case DUR_TOGGLE_SHOW_NEW_INTERACTIONS: {
      if (payload && !!payload.durId) {
        const dur = state.data[`${DUR}${payload.durId}`];
        return combineWithState(state, {
          [`${DUR}${payload.durId}`]: {
            ...dur,
            newInteractionsOnly: payload.newInteractionsOnly,
          },
        });
      }
      return state;
    }
    case TASK_LIFECYCLE_ACTIONS.ADD.PRIOR_AUTHORIZATION:
    case TASK_LIFECYCLE_ACTIONS.ADD.FINANCIAL_ASSISTANCE:
    case TASK_LIFECYCLE_ACTIONS.ADD.MEDICATION_REVIEW:
    case TASK_LIFECYCLE_ACTIONS.ADD.INTERVENTION:
    case TASK_LIFECYCLE_ACTIONS.ADD.INTERVENTION_PATIENT:
    case TASK_LIFECYCLE_ACTIONS.ADD.OUTREACH:
    case TASK_LIFECYCLE_ACTIONS.ADD.OUTREACH_PATIENT:
    case TASK_LIFECYCLE_ACTIONS.ADD.DATA_COLLECT:
    case TASK_LIFECYCLE_ACTIONS.ADD.FILL_DELIVERY_CONFIRMATION:
    case TASK_LIFECYCLE_ACTIONS.ADD.COUNSELING:
    case TASK_LIFECYCLE_ACTIONS.ADD.DRUG_UTILIZATION_REVIEW:
    case TASK_LIFECYCLE_ACTIONS.ADD.APPOINTMENT_REFERRAL:
    case TASK_LIFECYCLE_ACTIONS.ADD.RISK_STRAT:
    case ADD_THIRD_PARTY_REFERRAL:
    case DUR_CREATE_NEW_SUCCESS:
    case ADD_TASK: {
      if (
        payload &&
        payload.data &&
        payload.data.added_task &&
        payload.data.added_task.length > 0
      ) {
        return combineWithState(
          state,
          convertListToMap(
            payload.data.added_task.map(task => ({
              ...task,
              order: -1,
              links: [],
            })),
            ['taskType', 'id'],
            true,
          ),
        );
      }
      return state;
    }
    case DISCHARGE_PATIENT:
      if (payload.data && payload.data.updated_tasks) {
        const tasks = payload.data.updated_tasks;
        const mappedTasks = convertListToMap(
          tasks.map(task => ({
            ...task,
          })),
          ['taskType', 'id'],
          true,
        );
        return combineWithState(state, mappedTasks);
      }
      return state;
    case TASK_LIFECYCLE_ACTIONS.EDIT.PRIOR_AUTHORIZATION:
    case TASK_LIFECYCLE_ACTIONS.EDIT.FINANCIAL_ASSISTANCE:
    case TASK_LIFECYCLE_ACTIONS.EDIT.FILL_COORDINATION:
    case TASK_LIFECYCLE_ACTIONS.EDIT.MEDICATION_REVIEW:
    case TASK_LIFECYCLE_ACTIONS.EDIT.INTERVENTION:
    case TASK_LIFECYCLE_ACTIONS.EDIT.INTERVENTION_PATIENT:
    case TASK_LIFECYCLE_ACTIONS.EDIT.OUTREACH:
    case TASK_LIFECYCLE_ACTIONS.EDIT.OUTREACH_PATIENT:
    case TASK_LIFECYCLE_ACTIONS.EDIT.FILL_DELIVERY_CONFIRMATION:
    case TASK_LIFECYCLE_ACTIONS.EDIT.DATA_COLLECT:
    case TASK_LIFECYCLE_ACTIONS.EDIT.COUNSELING:
    case TASK_LIFECYCLE_ACTIONS.EDIT.SCHEDULE_OUTREACH:
    case TASK_LIFECYCLE_ACTIONS.EDIT.INCIDENT:
    case TASK_LIFECYCLE_ACTIONS.EDIT.DRUG_UTILIZATION_REVIEW:
    case EDIT_PA:
    case EDIT_FA:
    case EDIT_THIRD_PARTY_REFERRAL: {
      if (payload.data && payload.data.updated_task && payload.data.updated_task.length > 0) {
        return combineWithState(
          state,
          convertListToMap(payload.data.updated_task, ['taskType', 'id']),
        );
      }
      return state;
    }
    case UPDATE_FDC_STATUS: {
      const filteredTasks = {};
      Object.keys(state.data).forEach(key => {
        if (key === `${payload.taskType}${payload.id}`) {
          filteredTasks[key] = {
            ...state.data[key],
            status_id: payload.status_id,
            task_status_text: payload.task_status_text,
          };
        } else {
          filteredTasks[key] = state.data[key];
        }
      });
      return {
        ...state,
        data: filteredTasks,
      };
    }
    case ADD_THERAPY_AR:
    case ADD_THERAPY: {
      if (payload && payload.data && payload.data.therapy) {
        const { therapy } = payload.data;
        const tasks = Object.keys(taskTypeToPropName).reduce((acc, taskType) => {
          const prop = taskTypeToPropName[taskType];
          if (therapy[prop] && therapy[prop].length) {
            Array.prototype.push.apply(acc, therapy[prop]);
          }
          return acc;
        }, []);
        if (tasks.length) {
          return combineWithState(state, convertListToMap(tasks, ['taskType', 'id']));
        }
      }
      return state;
    }
    case EDIT_PROBLEM_THERAPIES_ENROLLMENT_SUCCESS:
    case EDIT_THERAPY: {
      if (payload && payload.data) {
        const { updatedTasks } = payload.data;
        if (updatedTasks) {
          const tasks = Object.keys(updatedTasks).reduce((acc, prop) => {
            Array.prototype.push.apply(acc, updatedTasks[prop]);
            return acc;
          }, []);
          const newState = combineWithState(state, convertListToMap(tasks, ['taskType', 'id']));
          // update NBD in all tasks that have the same fill cycle that changed
          const { therapy } = payload.data;

          // eslint-disable-next-line
          const therapyFillCycle = therapy?.[0]?.fill_cycle;

          const allTasksWithCurrentFillCycle = therapyFillCycle
            ? convertMapToList(state.data).filter(it => it.fill_cycle_number === therapyFillCycle)
            : [];

          return combineWithState(
            newState,
            allTasksWithCurrentFillCycle.reduce((acc, task) => {
              const key = `${task.taskType}${task.id}`;
              acc[key] = {
                ...newState.data[key],
                needsby_date: therapy[0].needsby_date,
              };
              return acc;
            }, {}),
          );
        }
      }
      return state;
    }
    case DUR_STATUS_TRANSITION_SUCCESS:
    case DUR_TRIGGER_CHECK_INTERACTION_SUCCESS:
    case BULK_TASKS_UPDATED: {
      if (payload.data && payload.data.updated_tasks) {
        const updatedTaskKeys = payload.data.updated_tasks.map(getTaskKey);
        const nextDraftTasks = omit(state.draftTasks, updatedTaskKeys);
        return combineWithState(
          { ...state, virtualCards: [], draftTasks: nextDraftTasks },
          convertListToMap(payload.data.updated_tasks, ['taskType', 'id']),
        );
      }
      return { ...state, virtualCards: [] };
    }
    case 'paymentMethods/delete/fulfilled': {
      const id = payload?.data?.deleted_payment_method_id ?? null;
      let nextVirtualCards = state.virtualCards;
      if (id) {
        nextVirtualCards = state.virtualCards.filter(card => card.id.toString() !== id.toString());
      }
      return { ...state, virtualCards: nextVirtualCards };
    }
    case UPDATE_TASK_PROPERTIES: {
      const { taskType, taskId, properties } = payload || {};
      if (taskType && taskId && properties) {
        return combineWithState(state, {
          [`${taskType}${taskId}`]: {
            ...state.data[`${taskType}${taskId}`],
            ...properties,
          },
        });
      }
      return state;
    }
    case INTEGRATE_UPDATED_TASKS: {
      const { tasks } = payload || {};

      if (tasks.length) {
        const newState = { ...state };

        tasks.forEach(task => {
          const { taskType } = task;
          const taskId = task.id;
          if (!taskType || !taskId) {
            return;
          }
          const keyIndex = `${taskType}${taskId}`;
          newState.data[keyIndex] = { ...state.data[keyIndex], ...task };
        });
        return newState;
      }
      return state;
    }
    case UPDATE_TASKS_PROPERTIES: {
      const { tasks } = payload || {};
      if (tasks.length) {
        const updatedTask = tasks.reduce(
          (prev, curr) => ({
            ...prev,
            [`${curr.taskType}${curr.taskId}`]: {
              ...state.data[`${curr.taskType}${curr.taskId}`],
              ...curr,
            },
          }),
          {},
        );
        return combineWithState(state, updatedTask);
      }
      return state;
    }
    case UPDATE_FC_DISPENSING_PHARMACY: {
      const newState = { ...state };
      const { fcIds: fcTaskIds, pharmacy } = payload.updatedPayload;
      fcTaskIds.forEach(taskId => {
        newState.data[`FC${taskId}`].dispensing_pharmacy_npi = pharmacy.npi;
        newState.data[`FC${taskId}`].dispensing_pharmacy_name = pharmacy.name;
      });
      return newState;
    }
    case UPDATE_TASK_LIST_ITEMS: {
      const { updated_tasks } = payload;
      const newState = { ...state };
      (updated_tasks || []).forEach(task => {
        const taskType = task.taskType.toUpperCase();
        const task_id = task.id;
        Object.keys(task).forEach(key => {
          newState.data[`${taskType}${task_id}`][key] = task[key];
        });
      });
      return newState;
    }
    case DUR_FETCH_BY_ID_SUCCESS: {
      const {
        data: { task },
      } = payload || {};
      if (task) {
        return combineWithState(state, {
          [`DUR${task.id}`]: {
            ...state.data[`DUR${task.id}`],
            ...task,
          },
        });
      }
      return state;
    }
    case LINK_SCHEDULE_OUTREACH_THERAPIES: {
      if (payload.data) {
        const updatedLinkedResource = payload.data.linked_resources[0];
        const taskId = updatedLinkedResource.schedule_outreach_id;
        const existing = state.data[`AR${taskId}`];
        const existingDrug = existing.drugs.some(d => d.ndc === updatedLinkedResource.ndc);
        const arCombinedWithState = combineWithState(state, {
          [`AR${taskId}`]: {
            ...existing,
            drugs:
              existing &&
              isValidArray(existing.drugs) &&
              (existingDrug
                ? existing.drugs.map(d => {
                    if (d.ndc === updatedLinkedResource.ndc) {
                      return {
                        ...d,
                        therapy_id: updatedLinkedResource.therapy_id,
                      };
                    }
                    return d;
                  })
                : [...existing.drugs, updatedLinkedResource]),
          },
        });
        // If there are updated tasks, also combine these with state.
        if (payload.data && payload.data.updated_tasks) {
          return combineWithState(
            arCombinedWithState,
            convertListToMap(payload.data.updated_tasks, ['taskType', 'id']),
          );
        }
        return arCombinedWithState;
      }
      return state;
    }
    case ADD_SCHEDULE_DRUG_OUTCOME: {
      if (payload.data) {
        const { data } = payload;
        const updatedSOIds = data.map(
          scheduleDrugOutcome => scheduleDrugOutcome.schedule_outreach_id,
        );
        const updatedSOIdsSet = new Set(updatedSOIds);
        return [...updatedSOIdsSet].reduce((previousState, scheduleOutreachId) => {
          const taskId = scheduleOutreachId;
          const existing = state.data[`AR${taskId}`];
          const addedDrugs = data.filter(
            scheduleDrugOutcome => scheduleDrugOutcome.schedule_outreach_id === taskId,
          );
          const newState = combineWithState(previousState, {
            [`AR${taskId}`]: {
              ...existing,
              drugs: existing && isValidArray(existing.drugs) && [...existing.drugs, ...addedDrugs],
            },
          });
          return newState;
        }, state);
      }
      return state;
    }
    case UPDATE_SCHEDULE_DRUG_OUTCOME: {
      if (payload.data) {
        const { data } = payload;
        const updatedSOIds = data.map(
          scheduleDrugOutcome => scheduleDrugOutcome.schedule_outreach_id,
        );
        const updatedSOIdsSet = new Set(updatedSOIds);

        return [...updatedSOIdsSet].reduce((previousState, scheduleOutreachId) => {
          const taskId = scheduleOutreachId;
          const existing = state.data[`AR${taskId}`];
          return combineWithState(previousState, {
            [`AR${taskId}`]: {
              ...existing,
              drugs:
                existing &&
                isValidArray(existing.drugs) &&
                existing.drugs.map(d => {
                  const updatedDrugOutcome = data.find(payloadDrug => payloadDrug.id === d.id);
                  if (updatedDrugOutcome) {
                    return {
                      ...d,
                      ...updatedDrugOutcome,
                    };
                  }
                  return d;
                }),
            },
          });
        }, state);
      }
      return state;
    }

    case UPDATE_TASK_FOLLOWUP_DATE: {
      const newState = {
        ...state,
        data: {
          ...state.data,
          [payload.taskIdentifier]: {
            ...state.data[payload.taskIdentifier],
            followup_dt: payload.followupDate,
          },
        },
      };
      return newState;
    }
    case SET_DUR_HAS_INTERVENTION: {
      const selectedForm = payload.checked ? [payload.formName] : [];
      return {
        ...state,
        durFormsWithSelectedINT: selectedForm,
      };
    }

    case SET_SHOW_SET_SELECTED_TASKS:
      return {
        ...state,
        showSelectedTasks: !!payload,
      };

    case ADDED_PAYMENT_METHOD:
      if (payload.added_payment_method) {
        if (!isVirtualCard(payload.added_payment_method)) {
          return state;
        }
        return {
          ...state,
          virtualCards: (state.virtualCards ?? []).concat(payload.added_payment_method),
        };
      }
      return state;

    case SET_DRAFT_TASK:
      return {
        ...state,
        draftTasks: {
          ...state.draftTasks,
          [payload.taskKey]: payload.data,
        },
      };

    case CLEAR_DRAFT_TASK: {
      const { taskKey } = payload;
      const nextDraftTasks = { ...state.draftTasks };
      delete nextDraftTasks[taskKey];
      return {
        ...state,
        draftTasks: nextDraftTasks,
      };
    }
    case RESET_DRAFT_TASK_STATE: {
      return {
        ...state,
        draftTasks: {},
      };
    }
    default:
      return state;
  }
};

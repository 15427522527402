import { AdministrationStatusHistory } from 'clients/history';
import { ArborDate } from 'models/time/arbor-date';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { withStyles, WithStyles } from '@mui/styles';
import { EditHistory } from 'components/icons/icons';
import { Tooltip, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { IState } from 'interfaces/redux/IState';
import { CSL } from 'constants/index';
import { styles } from './therapy-administration-history.styles';
import { selectSelectedTasks } from '../tasks-new/selectors';

interface TherapyAdministrationHistoryProps extends WithStyles<typeof styles> {
  therapyIds?: number[];
  patientId?: number;
}

const TherapyAdministrationHistory: FC<TherapyAdministrationHistoryProps> = props => {
  const { therapyIds, patientId, classes } = props;
  const therapies = useSelector((state: IState) => state.therapies.data);
  const selectedTasks = useSelector(selectSelectedTasks);
  const drugsWithDosisRegimens = selectedTasks.reduce((acc, task) => {
    if (task.therapy_id && therapies[task.therapy_id] && task.taskType === CSL) {
      const { drug_name: drugName, dosis_regimen: dosisRegimen = '----' } =
        therapies[task.therapy_id];
      acc[task.therapy_id] = { drugName, dosisRegimen };
    }
    return acc;
  }, {} as any);
  const [historyRaw, setHistoryRaw] = useState<any[]>([]);
  const history = useMemo(() => {
    if (!historyRaw.length) return [];

    return historyRaw
      .sort((a, b) => {
        if (a.id === b.id) return new Date(a.created).getTime() - new Date(b.created).getTime();

        return a.id - b.id;
      })
      .reduce((acc, record, index, array) => {
        if (index === 0 && Number(therapyIds?.length) > 1) {
          acc.push({
            drugWithDosisRegimen: drugsWithDosisRegimens[record.id],
          });
        }

        const data = {
          created: new ArborDate(record.created, true).getUtcDate(true),
          id: record.id,
          administrationStatus: record.administration_status,
          updatedBy: record.updated_by,
        };
        acc.push(data);

        const isLastElement = index === array.length - 1;
        const nextHasDifferentId = !isLastElement && array[index + 1].id !== record.id;
        if (nextHasDifferentId) {
          acc.push({
            drugWithDosisRegimen: drugsWithDosisRegimens[array[index + 1].id],
          });
        }

        return acc;
      }, []);
  }, [drugsWithDosisRegimens, historyRaw, therapyIds?.length]);

  useEffect(() => {
    if (therapyIds && patientId) {
      AdministrationStatusHistory.get(patientId, therapyIds).then(data => {
        if (Object.keys(data).length) setHistoryRaw(data);
      });
    }
    return () => {
      if (therapyIds && patientId) {
        AdministrationStatusHistory.invalidateCache(patientId, therapyIds);
      }
      setHistoryRaw([]);
    };
  }, [therapyIds, patientId]);

  if (!therapyIds || !patientId || !history.length) return null;

  return (
    <Tooltip
      title={
        <div className={classes.adminitrationTooltip}>
          <Typography className="info bold">Administration Status History</Typography>
          <hr />
          {history.map((h: any, i: number, arr: any[]) =>
            h.drugWithDosisRegimen ? (
              <div className={classes.dosisRegimenText}>
                {h.drugWithDosisRegimen.drugName} {h.drugWithDosisRegimen.dosisRegimen}
              </div>
            ) : (
              <>
                <Typography className="info" key={`${h.status}-history-${h.updated}`}>
                  {h.created} - {h.administrationStatus} {!!h.updatedBy && '-'} {h.updatedBy}
                </Typography>
                {i < arr.length - 1 && <hr />}
              </>
            ),
          )}
        </div>
      }
      placement="bottom-start"
    >
      <div className={classes.iconHistory}>
        <EditHistory />
      </div>
    </Tooltip>
  );
};

export default withStyles(styles, { withTheme: true })(TherapyAdministrationHistory);

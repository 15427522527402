import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import cx from 'classnames';
import React from 'react';
import { addPhoneModalActions } from 'actions/action-add-phone-modal';
import { assignRankingAndKeys, formatPatientName } from 'services/utils/demographic-service';
import { AxiosPromise } from 'axios';
import { buildQaId } from 'utils/build-qa-id';
import { change, Field, InjectedFormProps, reduxForm, untouch } from 'redux-form';
import { compose } from 'recompose';
import { contactEnrollment, phoneTypes } from 'constants/lists';
import { ContactUtil } from 'utils/contact-util';
import { editContact } from 'actions/action-contacts';
import { editPatient } from 'actions/action-patient';
import { getModalStyle } from 'services/utils/styles-service';
import { Grid, Modal, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { IState } from 'interfaces/redux/IState';
import { nameOfFactory } from 'utils/types-util';
import { PatientUtil } from 'utils/patient-util';
import { valueIfPatientSelected, useContactOptions } from 'hooks/useContactOptions';
import { required, validatePhone } from 'components/form/validation/validation';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  renderDropdown,
  renderPhoneTextField,
  renderRadioGroup,
} from 'components/form/field/redux-field';
import { renderTimePicker } from 'components/form/datepicker/datetime-picker';
import { styles } from '../add-address-modal/add-address-modal.styles';
import { IProps } from './interfaces/IProps';
import { IFormProps } from './interfaces/IFormProps';

// #region interfaces for the component
interface IAddPhoneModalProps extends IProps, InjectedFormProps<IFormProps> {}
interface IStateProps {
  initialValues: Partial<IFormProps>;
}
type Props = IStateProps & IAddPhoneModalProps;
// #endregion

// #region constant things
const qaIdBuilder = buildQaId('add-phone-modal');
const nameOfFormFields = nameOfFactory<IFormProps>();
// #endregion

const AddPhoneModal: React.FC<Props> = (props: Props): JSX.Element => {
  const { classes } = props;

  // #region component state
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [saveDisabled, setSaveDisabled] = React.useState<boolean>(false);
  const [showSmsEnrollment, setShowSmsEnrollment] = React.useState<boolean>(false);
  // #endregion

  // #region redux
  const patient = useSelector((state: IState) => state.patient);
  const contacts = useSelector((state: IState) => state.contactList);
  const addPhoneModal = useSelector((state: IState) => state.addPhoneModal);
  const contactOptions = useContactOptions();
  const dispatch = useDispatch();
  // #endregion

  // #region functions
  const handleSubmit = async (formValues: Partial<IFormProps>) => {
    setSaveDisabled(true);
    const { contact, ...resetFormValues } = formValues;

    if (contact === valueIfPatientSelected) {
      submitNewPatientPhone(resetFormValues);
    } else if (contact) {
      submitNewContactPhone(contact, resetFormValues);
    }
  };

  const handleCancel = (): void => {
    setErrorMessage('');
    setSaveDisabled(false);
    setShowSmsEnrollment(false);
    props.reset();
    if (addPhoneModal.formId && addPhoneModal.field) {
      dispatch(untouch(addPhoneModal.formId, addPhoneModal.field));
    }
    dispatch(addPhoneModalActions.resetModal());
  };

  const formatFormValues = (formValues: Partial<IFormProps>) => {
    const formatted = {
      use: formValues.use,
      value: formValues.phone,
      period: `${formValues.availabilityStart || ''}-${formValues.availabilityEnd || ''}`,
      sms: formValues.smsEnrollment,
    };
    return formatted;
  };

  const formatFieldValue = (value: string) => {
    if (addPhoneModal.mode === 'therapies') {
      return {
        label: value,
        value,
      };
    }

    return value;
  };

  const submitNewPatientPhone = (formValues: Partial<IFormProps>): void => {
    const formattedFormValues = formatFormValues(formValues);
    const rankedPhones = assignRankingAndKeys(
      (patient.phones || []).concat(formattedFormValues as any),
    );
    const payload = {
      ...PatientUtil.convertPatientFromStateForUpdate(patient),
      phones: JSON.stringify(rankedPhones),
    };
    (dispatch(editPatient(payload) as unknown) as AxiosPromise<any>)
      .then((result: any) => {
        if (addPhoneModal.formId && addPhoneModal.field) {
          const formValue = `${patient.first_name} ${patient.last_name} - ${formValues.use} - ${formValues.phone}`;
          dispatch(change(addPhoneModal.formId, addPhoneModal.field, formatFieldValue(formValue)));
        }
        handleCancel();
      })
      .catch(error => {
        setSaveDisabled(false);
        setErrorMessage('Could not update patient');
      });
  };

  const submitNewContactPhone = (contactId: number, formValues: Partial<IFormProps>): void => {
    const theContact = contacts[contactId];
    const formattedFormValues = formatFormValues(formValues);
    const rankedPhones = assignRankingAndKeys(theContact.phones.concat(formattedFormValues as any));
    const payload = {
      ...ContactUtil.convertContactFromStateForUpdate(theContact),
      phones: JSON.stringify(rankedPhones),
    };

    (dispatch(editContact(payload)) as unknown as AxiosPromise<any>)
      .then(result => {
        if (addPhoneModal.formId && addPhoneModal.field) {
          const fieldValue = `${theContact.first_name} ${theContact.last_name} (${theContact.relationship}) - ${formValues.use} - ${formValues.phone}`;
          dispatch(change(addPhoneModal.formId, addPhoneModal.field, formatFieldValue(fieldValue)));
        }
        handleCancel();
      })
      .catch(error => {
        setSaveDisabled(false);
        setErrorMessage('Could not update contact');
      });
  };
  // #endregion

  return (
    <Modal open={addPhoneModal.visible} data-qa-id={qaIdBuilder('modal')}>
      <form data-qa-id={qaIdBuilder('form')}>
        <div style={getModalStyle()} className={classes.addModal}>
          <Grid container>
            {errorMessage && (
              <Grid item xs={12} className={classes.row}>
                <Typography className={classes.error}>{errorMessage}</Typography>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} className={classes.row}>
            <Typography variant="h6" className={classes.heading}>
              Add New Phone
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.row}>
            <Grid container spacing={1}>
              <Grid item xs={7}>
                <Field
                  name={nameOfFormFields('contact')}
                  label="Contact *"
                  component={renderDropdown}
                  fields={contactOptions}
                  validate={[required]}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name={nameOfFormFields('use')}
                  label="Type *"
                  component={renderDropdown}
                  fields={phoneTypes}
                  validate={[required]}
                  onChange={(e: any) => {
                    if (e === 'Mobile') {
                      setShowSmsEnrollment(true);
                    } else {
                      setShowSmsEnrollment(false);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className={cx([
              classes.row,
              {
                [classes.lastRow]: !showSmsEnrollment,
              },
            ])}
          >
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Field
                  label="Phone *"
                  name={nameOfFormFields('phone')}
                  validate={[required, validatePhone]}
                  component={renderPhoneTextField}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  label="Availability Start"
                  name={nameOfFormFields('availabilityStart')}
                  component={renderTimePicker}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  label="Availability End"
                  name={nameOfFormFields('availabilityEnd')}
                  component={renderTimePicker}
                />
              </Grid>
            </Grid>
          </Grid>
          {showSmsEnrollment && (
            <Grid item xs={12} className={cx([{ [classes.lastRow]: showSmsEnrollment }])}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Field
                    label="SMS Enrollment *"
                    name={nameOfFormFields('smsEnrollment')}
                    component={renderRadioGroup}
                    radioMap={contactEnrollment}
                    validate={[required]}
                    caption
                    width="100%"
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <ConfirmationPanel
              handleSubmit={props.handleSubmit(handleSubmit)}
              handleCancel={handleCancel}
              disableSubmit={saveDisabled}
            />
          </Grid>
        </div>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state: IState): IStateProps => {
  return {
    initialValues: {
      availabilityStart: '08:00',
      availabilityEnd: '20:00',
    },
  };
};

export default compose<Props, IAddPhoneModalProps>(
  withStyles(styles),
  connect(mapStateToProps),
  reduxForm({ form: 'add-phone-modal' }),
)(AddPhoneModal);

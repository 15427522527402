import { useSelector } from 'react-redux';
import { IVaccine } from 'interfaces/redux/ILookups';
import { IState } from 'interfaces/redux/IState';
import { IPatientVaccination } from 'interfaces/redux/IPatient';
import { IDurTask } from 'interfaces/redux/task-types/IDurTask';
import { DurStatus } from 'interfaces/enums/TaskStatuses/DrugUtilizationReviewStatus';
import { IVaccinationRow } from '../table/interfaces';

const mergePatientVaccinationsWithLookupData = (
  vaccines: Record<string, IVaccine[]>,
  patientVaccinations: IPatientVaccination[],
): IVaccinationRow[] => {
  const flatVaccinesData: IVaccine[] = Object.values(vaccines).reduce(
    (acc: IVaccine[], vaccinesArr: IVaccine[]) => acc.concat(vaccinesArr),
    [],
  );

  return (patientVaccinations || []).map((vaccination: IPatientVaccination) => {
    const vaccineSpecificData = flatVaccinesData.find(
      vaccine => vaccine.id === vaccination.vaccine_id,
    );

    return {
      id: vaccination.id,
      category: vaccineSpecificData?.category?.category || '-',
      vaccine: vaccineSpecificData?.name || '-',
      type: vaccineSpecificData?.type?.type || '-',
      effectiveFor: vaccination.effective_for,
      dateReceived: vaccination.received_dt,
      source: vaccination.source,
    };
  });
};

const parseDurVaccines = (
  vaccineLookups: Record<string, IVaccine[]>,
  durTask: IDurTask,
): IDurTask => {
  let vaccines: IVaccinationRow[] = [];
  if (
    durTask &&
    durTask.vaccines &&
    durTask.vaccines.length &&
    durTask.status_id === DurStatus.Reviewed
  ) {
    vaccines = mergePatientVaccinationsWithLookupData(
      vaccineLookups,
      durTask.vaccines as IPatientVaccination[],
    );
  }

  return {
    ...durTask,
    vaccines,
  };
};

const usePatientVaccines = () => {
  const patientVaccinations = useSelector((state: IState) => state.patient.vaccines) || [];
  const vaccineLookups = useSelector((state: IState) => state.lookups.vaccines) || {};

  return mergePatientVaccinationsWithLookupData(vaccineLookups, patientVaccinations);
};

export { usePatientVaccines, mergePatientVaccinationsWithLookupData, parseDurVaccines };

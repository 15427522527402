import React from 'react';
import { Grid, Button, TextField, InputAdornment } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import cx from 'classnames';
import { useDebounce } from 'use-debounce';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { ActiveTable } from './tables/active/active-table';
import { NewTable } from './tables/new/new-table';
import { GtdStyles } from './index.styles';
import { InactiveTable } from './tables/inactive/inactive-table';
import { SectionTitleStyled } from '../components/section-title';
import { ReportModalStyled } from './report-modal/report-modal';
import { SearchField } from '../components/search-field';

enum ViewStateOptions {
  New = 'New',
  Active = 'Active',
  Inactive = 'Inactive',
}
const searchFieldPlaceholder = 'Search by drug name or GPI 10';
const debounceTimeMs = 500;
const initialSearchTerm = '';

type IGtd = WithStyles<typeof GtdStyles>

const Gtd = (props: IGtd): JSX.Element => {
  const { classes } = props;

  // #region useState
  const [showReportModal, setShowReportModal] = React.useState<boolean>(false);
  const [whichView, setWhichView] = React.useState<ViewStateOptions>(ViewStateOptions.New);
  const [newCount, setNewCount] = React.useState<number | undefined>(undefined);
  const [searchTerm, setSearchTerm] = React.useState<string>(initialSearchTerm);
  const [debouncedSearchTerm] = useDebounce<string | undefined>(searchTerm, debounceTimeMs);
  // #endregion

  // #region renders
  const renderBottomComponent = (): JSX.Element | null => {
    const componentDict: Record<ViewStateOptions, JSX.Element | null> = {
      [ViewStateOptions.New]: (
        <>
          <NewTable
            searchTerm={debouncedSearchTerm}
            setCount={(count: number) => setNewCount(count)}
          />
        </>
      ),
      [ViewStateOptions.Active]: <ActiveTable searchTerm={debouncedSearchTerm} />,
      [ViewStateOptions.Inactive]: (
        <>
          <InactiveTable searchTerm={debouncedSearchTerm} />
        </>
      ),
    };

    return componentDict[whichView];
  };

  const renderButtonLabel = (button: ViewStateOptions): string => {
    const buttonMap: Record<ViewStateOptions, () => string> = {
      [ViewStateOptions.New]: () => {
        if (newCount) {
          return `New (${newCount})`;
        }
          return 'New';
      },
      [ViewStateOptions.Active]: () => 'Active',
      [ViewStateOptions.Inactive]: () => 'Inactive',
    };
    return buttonMap[button]();
  };

  const renderReportButton = (): JSX.Element => {
    return (
      <Button
        variant="contained"
        color="primary"
        className={classes.reportButton}
        onClick={() => setShowReportModal(!showReportModal)}
      >
        Reports
      </Button>
    );
  };
  // #endregion

  return (
    <>
      <SectionTitleStyled
        title="Global GTD"
        rightSideItems={[
          {
            element: renderReportButton(),
            key: 'report-button',
          },
        ]}
      />
      <ReportModalStyled open={showReportModal} close={() => setShowReportModal(false)} />
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid item xs="auto">
              <Grid container spacing={1} data-qa-id="container 2">
                {/* Left Side - Buttons */}

                {Object.keys(ViewStateOptions).map(key => {
                  return (
                    <Grid item xs="auto" key={key}>
                      <Button
                        className={cx(classes.button, {
                          [classes.selectedButton]: whichView === (key as ViewStateOptions),
                        })}
                        variant="outlined"
                        onClick={() => {
                          setWhichView(key as ViewStateOptions);
                        }}
                      >
                        {renderButtonLabel(key as ViewStateOptions)}
                      </Button>
                    </Grid>
                  );
                })}

                {/* Right Side - Search Field */}
              </Grid>
            </Grid>
            <Grid item xs="auto">
              <SearchField
                value={searchTerm}
                onChange={setSearchTerm}
                placeholder={searchFieldPlaceholder}
                width={37}
                initialValue={initialSearchTerm}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Below the buttons */}
        <Grid item>{renderBottomComponent()}</Grid>
      </Grid>
    </>
  );
};

export default withStyles(GtdStyles)(Gtd);

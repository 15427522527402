import React, { FC } from 'react';
import { Grid } from '@mui/material';

import { IAdherenceData } from 'interfaces/redux/IAdherenceData';
import { useDispatch } from 'react-redux';
import { DUR_FETCH_BY_ID } from 'constants/index';
import { DurStatus } from 'interfaces/enums/TaskStatuses/DrugUtilizationReviewStatus';
import { IProps } from './interfaces/IProps';

import PatientSection from './components/patient-section';
import DurSection from './components/dur-section';
import DataCollectSection from './components/data-collect-section';
import AdherenceSection from './components/adherence-section';
import { IDurSectionData } from './interfaces/IDurSection';

const ChartReview: FC<IProps> = (props: IProps): JSX.Element => {
  const { patientReviewData, readOnly = false, tasks, taskQty, currentSelectedTasks } = props;
  const { patient, drugUtilizationReview, dataCollects, fdcItems, pdcTable } = patientReviewData;
  const tasksIds = tasks.map(task => task.therapy_id);
  const filteredPdcTable: IAdherenceData = { ...pdcTable } as IAdherenceData;

  const dispatch = useDispatch();

  const handleExpand = (event: any, expanded: boolean) => {
    if (expanded && !readOnly) {
      const { task: durTask = null } = drugUtilizationReview as IDurSectionData;
      if (
        durTask &&
        // Only DURs in finished state could be potentially empty
        [DurStatus.Reviewed, DurStatus.Canceled, DurStatus.CanceledPatientDeceased].includes(
          durTask.status_id,
        ) &&
        durTask?.medications == null
      ) {
        dispatch({ type: DUR_FETCH_BY_ID, payload: { id: durTask.id } });
      }
    }
  };

  if (pdcTable && filteredPdcTable) {
    filteredPdcTable.rows = pdcTable.rows.filter(row => tasksIds.includes(row.therapyId));
  }
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <PatientSection patient={patient} />
      </Grid>

      {drugUtilizationReview && (
        <Grid item xs={12}>
          <DurSection
            drugUtilizationReview={drugUtilizationReview}
            readOnly={readOnly}
            hideMedicationChildren={props.hideDurMedicationChildren}
            isCslDur
            onExpand={handleExpand}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <DataCollectSection
          dataCollects={dataCollects}
          taskQty={taskQty}
          currentSelectedTasks={currentSelectedTasks}
        />
      </Grid>
      <Grid item xs={12}>
        <AdherenceSection fdcItems={fdcItems} pdcTable={filteredPdcTable} />
      </Grid>
    </Grid>
  );
};

export { ChartReview };

import React, { useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import {
  Table,
  TableBody,
  TableCell,
  TableSortLabel,
  TableHead,
  TableRow,
  Grid,
  Modal,
  Box,
} from '@mui/material';
import { useSelector } from 'react-redux';
import compose from 'recompose/compose';
import { getModalStyle } from 'services/utils/styles-service';
import { NULLISH_DATE } from 'constants/index';
import { styles } from '../patient-clinical-styles';
import SpecialPopulationRow from './patient-special-population-row';
import PatientSpecialPopulationForm from './patient-special-population-form';

function descendingComparator(a, b, orderBy) {
  let aValue = a[orderBy];
  let bValue = b[orderBy];

  if (typeof aValue === 'string') aValue = (aValue ?? '').toLowerCase();
  if (typeof bValue === 'string') bValue = (bValue ?? '').toLowerCase();

  if (orderBy === 'start_date' || orderBy === 'end_date') {
    aValue = Date.parse(a[orderBy] ?? NULLISH_DATE);
    bValue = Date.parse(b[orderBy] ?? NULLISH_DATE);
  }

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function SpecialPopulationsTable(props) {
  const { classes, specialPopulations, readOnly } = props;

  const patient = useSelector(state => state.patient);
  const lookups = useSelector(state => state.lookups);
  const [displayEditSpecialPopulation, setDisplayEditSpecialPopulation] = useState(false);
  const [specialPopulationToEdit, setSpecialPopulationToEdit] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('type');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  const handleEditSpecialPopulationModal = (open, specialPopulation = null) => {
    setDisplayEditSpecialPopulation(open);
    setSpecialPopulationToEdit(specialPopulation);
  };

  const tableSortLabelComponent = (header, headerKey) => (
    <TableSortLabel
      active={orderBy === headerKey}
      direction={orderBy === headerKey ? order : 'asc'}
      onClick={createSortHandler(headerKey)}
    >
      {header}
      {orderBy === header ? (
        <Box component="span" sx={{ display: 'none' }}>
          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        </Box>
      ) : null}
    </TableSortLabel>
  );

  // Total cols should be 12 to mimic grid since grid isn't the most
  // compatible with table
  const tableColWidths = {
    specialPopulation: 8,
    endDate: 1,
    startDate: 1,
    active: 1,
    edit: 1,
    noSpecialPopulations: 11,
  };

  const renderTableColSpacing = spacingVal => Array(spacingVal - 1).fill(<TableCell />);
  return (
    <>
      <Modal
        open={displayEditSpecialPopulation}
        onClose={() => setDisplayEditSpecialPopulation(false)}
      >
        <Grid item xs={12} style={getModalStyle()} className={classes.editAllergyModal}>
          {!!specialPopulationToEdit && (
            <PatientSpecialPopulationForm
              lookups={lookups}
              specialPopulation={specialPopulationToEdit}
              cancelHandler={() => handleEditSpecialPopulationModal(false)}
            />
          )}
        </Grid>
      </Modal>
      <Grid container>
        <Table className={classes.tableAllergies} aria-label="simple table" dense>
          <TableHead>
            <TableRow>
              <TableCell>{tableSortLabelComponent('Special Population', 'type')}</TableCell>
              {renderTableColSpacing(tableColWidths.specialPopulation)}
              <TableCell>{tableSortLabelComponent('Start Date', 'start_date')}</TableCell>
              {renderTableColSpacing(tableColWidths.startDate)}
              <TableCell>{tableSortLabelComponent('End Date', 'end_date')}</TableCell>
              {renderTableColSpacing(tableColWidths.endDate)}
              <TableCell>{tableSortLabelComponent('Status', 'active')}</TableCell>
              {renderTableColSpacing(tableColWidths.active)}
              <TableCell />
              {!readOnly && renderTableColSpacing(tableColWidths.edit)}
            </TableRow>
          </TableHead>
          <TableBody>
            {specialPopulations && Array.isArray(specialPopulations) && specialPopulations.length
              ? stableSort(
                  specialPopulations,
                  getComparator(order, orderBy),
                ).map((specialPopulationVar, index) => (
                  <SpecialPopulationRow
                    editHandler={handleEditSpecialPopulationModal}
                    specialPopulation={specialPopulationVar}
                    patient={patient}
                    key={`special_population_key_${specialPopulationVar.id}`}
                    tableColWidths={tableColWidths}
                    readOnly={readOnly}
                    index={index}
                  />
                ))
              : (
                <TableRow>
                  <TableCell colSpan={4}>
                    No special populations.
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </Grid>
    </>
  );
}

export default compose(withStyles(styles, { withTheme: true }))(SpecialPopulationsTable);

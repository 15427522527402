import makeStyles from '@mui/styles/makeStyles';

export const styles = (width: number) =>
  makeStyles(theme => {
    return {
      searchField: {
        width: theme.spacing(width),
      },
      textFieldEndAdornment: {
        cursor: 'pointer',
      },
      searchIcon: {
        color: theme.palette.grey[500],
      },
    };
  });

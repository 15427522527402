import React, { forwardRef, useImperativeHandle, useState, useEffect, SyntheticEvent } from 'react';
import { Tabs, Tab as TabCmp } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { OnTabClose, Tab, TabLabel } from '../../../components/tab-label';

import { styles } from './meds-tabs-styles';

const useStyles = makeStyles(styles);

export type TabPropsGetter = (tab: Tab) => any;
export type TabChangeCallback = (tab: Tab, tabIdx: number) => void;

export interface MedsTabsProps {
  tabs: Tab[];
  instructions: any;
  onTabClose: OnTabClose;
  children: any;
  tabPropsGetter: TabPropsGetter;
  onTabChange?: TabChangeCallback;
}

export type Handler = {
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
};

const MedsTabsCmp: React.ForwardRefRenderFunction<Handler, MedsTabsProps> = (
  { tabs, instructions, children, onTabClose, onTabChange, tabPropsGetter, ...props },
  ref,
) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const classes = useStyles(styles);

  useImperativeHandle(ref, () => ({
    setSelectedTab,
  }));

  const handleTabsOnChange = (event: SyntheticEvent, tabIdx: number) => {
    setSelectedTab(tabIdx);
    const tab = tabs[tabIdx];

    if (onTabChange) {
      onTabChange(tab, tabIdx);
    }
  };

  // If the last tab gets closed but is currently selected,
  // set the selected tab to what is now the last tab.
  // Note - a tab may be closed by action taken outside of this component.
  useEffect(() => {
    const numberOfTabs = tabs.length;
    if (selectedTab >= numberOfTabs && numberOfTabs !== 0) {
      setSelectedTab(numberOfTabs - 1);
    }
  }, [tabs.length, selectedTab]);

  return (
    <div className={classes.container}>
      <Tabs
        value={selectedTab}
        onChange={handleTabsOnChange}
        variant="scrollable"
        scrollButtons
        textColor="primary"
        className={classes.tabs}
        classes={{ scrollButtons: classes.scrollButtons }}
        allowScrollButtonsMobile
      >
        {tabs.map((tab: any) => (
          <TabCmp
            key={tab.label}
            icon={tab.icon}
            classes={{ root: classes.tab, selected: classes.selectedTab }}
            label={
              <TabLabel
                tab={tab}
                onClose={() => {
                  onTabClose(tab);
                }}
                className={classes.tabLabel}
              />
            }
          />
        ))}
      </Tabs>
      <div className={classes.content}>
        {instructions ? <div className={classes.instructions}>{instructions}</div> : null}
        {tabs.map((tab: any, index: number) =>
          React.Children.map(children, child => (
            <div hidden={index !== selectedTab} key={tab.label}>
              {React.cloneElement(child, {
                tab,
                ...(tabPropsGetter ? tabPropsGetter(tab) : {}),
              })}
            </div>
          )),
        )}
      </div>
    </div>
  );
};

export const MedsTabs = forwardRef(MedsTabsCmp);

import { Button, Divider, Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { addPatient, fetchPatient, selectPatientTab, updateWorkList } from 'actions/action-patient';
import {
  fetchReportData,
  loadReports,
  updateReportModal,
  updateReportSidebar,
} from 'actions/action-report';
import ReportOverrideForm from 'components/dynamic-form/report/pa-tat-override-form';
import { EditPencilMd } from 'components/icons/icons';
import { LargeSpacer } from 'components/spacer/spacer';
import { advancedExpandTableHOC } from 'components/table/advanced-expand-table-hoc';
import {
  PRIOR_AUTHORIZATION_TAT_OVERRIDE_ERROR,
  PRIOR_AUTHORIZATION_TAT_OVERRIDE_TOTAL,
  REPORTS_TAB,
  INVOICING_TAB,
} from 'constants/index';
import { UserRoles } from 'constants/enums';
import { appointmentReportIds, reports as reportList } from 'constants/lists';
import { dateFilter } from 'helpers/react-table';
import { convertToArborDate } from 'models/time/arbor-date';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Loader } from 'components/loading-overlay/loading-overlay';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { goToAnchor } from 'utils/react-scrollable-anchor';
import compose from 'recompose/compose';
import { toUiDateTimeFormat, getStartOfDateForDb } from 'services/utils/date-service';
import { getDatesFromSidebarFilters } from 'services/utils/filters-service';
import { isValidArray } from 'services/utils/common-service';
import useHasRole from 'hooks/useHasRole';
import { windowFeatureIsEnabled } from 'config/window-features';
import classnames from 'classnames';
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useExpanded,
  useBlockLayout,
  useResizeColumns,
} from 'react-table-v7';
import { getReportUrl } from '../../helpers/router';
import ReportFilterPanel from './report-filter-panel';
import ReportItem from './report-item';
import { styles } from './report-styles';
import Invoicing from './invoicing/invoicing';
import PreviousInvoices from './invoicing/previous';
import { ReportVisibilityButton } from './report-visibility-button';
import { useTypedSelector } from '../../hooks/use-typed-selector';
import { fetchUserPreferences, updateUserPreferences } from '../../actions/action-user';

const PATIENT_NAME = 'Patient Name';
const SERVICE_GROUP = 'Service Group';
const UPDATED_BY = 'Updated By';

function generateTableCols(reportId, hasAccessToOverride = false) {
  // PA Cols
  if (reportId === 101) {
    const columns = [
      {
        Header: PATIENT_NAME,
        accessor: 'patient_name',
        minWidth: 250,
      },
      {
        Header: 'MRN',
        accessor: 'mrn',
        minWidth: 125,
      },
      {
        Header: 'Therapy',
        accessor: 'therapy_name',
        minWidth: 150,
      },
      {
        Header: SERVICE_GROUP,
        accessor: 'service_group',
        minWidth: 250,
      },
      {
        Header: 'Prescribing Clinic',
        accessor: 'prescribing_clinic',
        minWidth: 200,
      },
      {
        Header: 'Medication Status',
        accessor: 'rx_type',
        minWidth: 125,
      },
      {
        Header: 'PA Status',
        accessor: 'status',
        minWidth: 200,
      },
      {
        Header: 'Therapy Referral Date',
        accessor: 'referral_dt',
        minWidth: 185,
        Cell: props => convertToArborDate(props.value).getCustomerDate(true),
        filterMethod: dateFilter,
      },
      {
        Header: 'Therapy Start Date',
        accessor: 'therapy_created',
        minWidth: 185,
        Cell: props => toUiDateTimeFormat(props.value),
        filterMethod: dateFilter,
      },
      {
        Header: 'Turnaround Submitted Date',
        accessor: 'turnaround_submitted_dt',
        minWidth: 200,
        Cell: props => convertToArborDate(props.value).getCustomerDate(true),
        filterMethod: dateFilter,
      },
      {
        Header: 'Submitted Date',
        accessor: 'submitted_dt',
        minWidth: 200,
        Cell: props => convertToArborDate(props.value).getCustomerDate(true),
        filterMethod: dateFilter,
      },
      {
        Header: 'Approved Date',
        accessor: 'approved_dt',
        minWidth: 200,
        Cell: props => convertToArborDate(props.value).getCustomerDate(true),
        filterMethod: dateFilter,
      },
      {
        Header: 'Denied Date',
        accessor: 'denied_date',
        minWidth: 200,
        Cell: props => convertToArborDate(props.value).getUtcDate(true),
        filterMethod: dateFilter,
      },
      {
        Header: 'Timeout Days',
        accessor: 'timeout',
        minWidth: 200,
      },
      {
        Header: 'Turnaround Timeout Days',
        accessor: 'turnaround_timeout',
        minWidth: 200,
      },
      {
        Header: UPDATED_BY,
        accessor: 'updated_by',
        minWidth: 200,
      },
      {
        Header: 'Override Reason',
        accessor: 'override_reason',
        minWidth: 200,
        paOverride: true,
      },
      {
        Header: 'Override From',
        accessor: 'override_before',
        minWidth: 200,
        paOverride: true,
      },
    ];

    // Access control: only site managers have access to override PA turnaround
    if (hasAccessToOverride) {
      columns.push({
        Header: 'Override',
        accessor: 'override',
        expander: true,
        width: 100,
        paOverride: true,
        Cell: ({ row }) => {
          return (
            <div {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? <EditPencilMd /> : <EditPencilMd />}
            </div>
          );
        },
        style: {
          cursor: 'pointer',
          padding: '0',
          textAlign: 'center',
          userSelect: 'none',
        },
      });
    }

    return columns;
  }

  if (appointmentReportIds.includes(reportId)) {
    // Appointment Cols
    return [
      {
        Header: PATIENT_NAME,
        accessor: 'patient_name',
        minWidth: 250,
      },
      {
        Header: 'MRN',
        accessor: 'mrn',
        minWidth: 125,
      },
      {
        Header: 'Appointment Date',
        accessor: 'start_dt',
        minWidth: 165,
        Cell: props => toUiDateTimeFormat(props.value),
        filterMethod: dateFilter,
      },
      {
        Header: 'Status',
        accessor: 'ar_status_reason',
        minWidth: 300,
      },
      {
        Header: 'Type',
        accessor: 'communication_type',
        minWidth: 100,
      },
      {
        Header: 'Physician',
        accessor: 'clinician_name',
        minWidth: 125,
      },
      {
        Header: 'Appointment Reason',
        accessor: 'reason',
        minWidth: 250,
      },
      {
        Header: 'Clinic',
        accessor: 'source_facility_id',
        minWidth: 250,
      },
      {
        Header: SERVICE_GROUP,
        accessor: 'service_group',
        minWidth: 250,
      },
      {
        Header: UPDATED_BY,
        accessor: 'updated_by',
        minWidth: 200,
      },
    ];
  }
  // Therapy Cols
  return [
    {
      Header: PATIENT_NAME,
      accessor: 'patient_name',
      minWidth: 250,
    },
    {
      Header: 'MRN',
      accessor: 'mrn',
      minWidth: 125,
    },
    {
      Header: 'Therapy',
      accessor: 'therapy_name',
      minWidth: 250,
    },
    {
      Header: 'Admin Status',
      accessor: 'status',
      minWidth: 200,
    },
    {
      Header: SERVICE_GROUP,
      accessor: 'service_group',
      minWidth: 250,
    },
    {
      Header: 'Prescribing Clinic',
      accessor: 'prescribing_clinic',
      minWidth: 200,
    },
    {
      Header: 'Medication Status',
      accessor: 'rx_type',
      minWidth: 125,
    },
    {
      Header: 'Medical Insurance',
      accessor: 'medical_insurance',
      minWidth: 250,
    },
    {
      Header: 'Pharmacy Insurance',
      accessor: 'pharmacy_insurance',
      minWidth: 255,
    },
    {
      Header: UPDATED_BY,
      accessor: 'updated_by',
      minWidth: 200,
    },
  ];
}

function Report(props) {
  const {
    classes,
    filters,
    reports,
    history,
    loadReports, // eslint-disable-line
    updateReportModal, // eslint-disable-line
    fetchReportData, // eslint-disable-line
    updateReportSidebar, // eslint-disable-line
    sidebarFilters,
  } = props;
  const { modal, reportSidebar, isLoading } = reports;
  const hasAccessToOverride = useHasRole(UserRoles.SuperUser, UserRoles.SiteManager);
  const userPreferences = useTypedSelector(state => state.userPreferences) || {};
  const selectedReports = useTypedSelector(state => state.userPreferences.selectedReports || []);
  const [reportLeftSections, setReportLeftSections] = useState([[], [], [], []]);
  const [reportRightSections, setReportRightSections] = useState([[], [], []]);
  const dispatch = useDispatch();

  const [tabValue, setTabValue] = useState(0);

  const handleSelectReports = reports => {
    dispatch(
      updateUserPreferences({
        ...userPreferences,
        selectedReports: reports,
      }),
    );
  };

  // eslint-disable-next-line
  const customer = filters && filters.customers && filters.customers.filter(c => c.selected);
  let customerName;
  if (customer && customer.length > 0) {
    customerName = customer[0].name;
  }

  useEffect(() => {
    const newReportLeftSections = [[], [], [], []];
    const newReportRightSections = [[], [], []];
    if (reports.reports) {
      // Group reports with the same section
      reports.reports
        .filter(r => selectedReports.includes(r.id))
        .forEach(r => {
          const reportDefinition = reportList.find(rl => rl.value === r.id);
          const { section } = reportDefinition;
          const [sectionCol, sectionNumber] = section.split('-');
          if (sectionCol === 'left') {
            newReportLeftSections[sectionNumber].push(r);
          } else {
            newReportRightSections[sectionNumber].push(r);
          }
        });
      setReportRightSections(newReportRightSections);
      setReportLeftSections(newReportLeftSections);
    }
  }, [reports.reports]);

  let cols = generateTableCols(modal.reportId, hasAccessToOverride);
  if (
    modal.params != null &&
    modal.params.key != null &&
    (modal.params.key.toUpperCase() === PRIOR_AUTHORIZATION_TAT_OVERRIDE_TOTAL ||
      modal.params.key.toUpperCase() === PRIOR_AUTHORIZATION_TAT_OVERRIDE_ERROR)
  ) {
    cols = cols.filter(item => !item.paOverride);
  }
  const headers = cols.map(item => ({
    label: item.Header,
    key: item.accessor,
  }));

  // Ensure CSV keeps leading 0's when opening in Excel
  const downloadData = modal.data.map(x =>
    Object.keys(x).reduce((attrs, key) => ({
      ...attrs,
      [key]: Number(x[key]) ? `=""${x[key]}""` : x[key],
    })),
  );

  useEffect(() => {
    loadReports();
    updateReportModal({
      open: false,
      reportId: 0,
    });
  }, [loadReports, updateReportModal]);

  const openModal = (reportId, label, ids, params) => {
    const { from, to } = getDatesFromSidebarFilters(sidebarFilters);
    const fromDate = getStartOfDateForDb(from);
    const toDate = getStartOfDateForDb(to);
    const serviceGroups = isValidArray(sidebarFilters.serviceGroups)
      ? sidebarFilters.serviceGroups.map(group => group.idValue || group.value || group)
      : [];
    const clinics = isValidArray(sidebarFilters.clinics)
      ? sidebarFilters.clinics.map(group => group.idValue || group.value || group)
      : [];
    fetchReportData(reportId, ids, label, fromDate, toDate, serviceGroups, clinics).then(() => {
      updateReportModal({
        open: true,
        label,
        reportId,
        params,
      });
    });
  };

  const closeModal = () => {
    updateReportModal({
      ...modal,
      open: false,
    });
  };

  const loadPatient = rowInfo => {
    const patientId = rowInfo.original.patient_id;
    const reportRow = modal.data[rowInfo.index];
    if (appointmentReportIds.includes(modal.reportId)) {
      setTimeout(
        () => goToAnchor(`APPOINTMENT_REFERRAL_${reportRow.task_schedule_outreach_id}`),
        100,
      );
    } else {
      setTimeout(() => goToAnchor(`THERAPY_${reportRow.therapy_id}`), 100);
    }
    updateReportSidebar({
      ...reportSidebar,
      selectedRow: rowInfo.index,
      reportId: modal.reportId,
      reportLabel: modal.label,
      data: modal.data,
      params: modal.params,
    });
    history.push(getReportUrl(modal.reportId, patientId, reportRow));
  };

  const checkIsLoading = sectionId => {
    if (sectionId) {
      const reportDefinition = reportList.find(rl => rl.section === sectionId);
      const { category } = reportDefinition;
      return isLoading[category];
    }
    return false;
  };

  const renderReportSections = (reportSections, sectionSide) => {
    const nonEmptySections = reportSections.filter(section => section.length > 0);
    if (nonEmptySections.length === 0) {
      return <></>;
    }

    return (
      <Grid item xs={6}>
        <Grid container direction="column">
          <Grid item xs={12}>
            {reportSections.map(
              (reportSection, index) =>
                !!reportSection?.length && (
                  <div className={classes.loadContainer}>
                    <Loader loaded={!checkIsLoading(`${sectionSide}-${index}`)}>
                      <Grid
                        container
                        key={`${sectionSide}` - `${index}`}
                        className={classes.reportSection}
                        direction="column"
                      >
                        {reportSection.map(report => (
                          <ReportItem
                            key={report.id}
                            category={report.definition.title}
                            reportId={report.id}
                            reportData={report.data}
                            isLoading={reports.isLoading}
                            total={report.total}
                            clickReportHandler={openModal}
                            reportItemIndex={index}
                          />
                        ))}
                      </Grid>
                    </Loader>
                  </div>
                ),
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderContainerTabs = value => {
    switch (value) {
      case REPORTS_TAB:
        // do not add spacing to the first Grid container bellow
        // adding spacing to it will overlap it with the tabs in the report page
        return (
          <>
            <Grid container>
              <Grid item xs={12}>
                <Route component={ReportFilterPanel} />
              </Grid>
            </Grid>
            {!!selectedReports.length && (
              <Grid container spacing={7}>
                {renderReportSections(reportLeftSections, 'left')}
                {renderReportSections(reportRightSections, 'right')}
              </Grid>
            )}
            <LargeSpacer />
          </>
        );
      case INVOICING_TAB:
        return <Invoicing />;

      default:
        return <PreviousInvoices />;
    }
  };

  const renderRowSubComponent = React.useCallback(({ row, modal }) => {
    return (
      <Grid item xs={12} className={classes.addDataContainer}>
        <ReportOverrideForm
          priorAuthorizationId={row.original.id} // eslint-disable-line
          overrideBefore={modal.params.key}
          edit
          row={row.original}
          closeHandler={() => row.toggleRowExpanded()}
          reportId={modal.reportId}
        />
      </Grid>
    );
  });

  const ReactTableComp = ({ data, columns, renderRowSubComponent }) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      row,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0 },
      },
      useFilters,
      useSortBy,
      useExpanded,
      usePagination,
      useBlockLayout,
      useResizeColumns,
    );

    return (
      <Grid className={classes.generalReportTable}>
        <Grid className={classnames(classes.generalReportTableContainer, undefined)}>
          <table className={classes.generalReportTableMain} {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      className={[
                        classes.generalReportHeaderTh,
                        `resizer ${column.isResizing ? 'isResizing' : ''}`,
                      ]}
                      {...column.getResizerProps()}
                    >
                      {column.render('Header') && (
                        <tr className={classes.generalReportHeaderTr}>
                          <th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            className={classes.generalReportHeaderTh}
                          >
                            {column.render('Header')}
                          </th>
                        </tr>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className={classes.generalReportMainTbody}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <>
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <td {...cell.getCellProps()} className={classes.generalReportTbodyTr}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                    {row.isExpanded ? (
                      <tr>
                        <td colSpan={row.cells.length}>{renderRowSubComponent({ row, modal })}</td>
                      </tr>
                    ) : null}
                  </>
                );
              })}
            </tbody>
          </table>
        </Grid>
        <Grid container xs={12} className={classes.paginationBtnsContainer}>
          <Grid item xs={4} className={classes.paginationBtns}>
            <button type="button" onClick={() => previousPage(0)} disabled={!canPreviousPage}>
              Previous
            </button>
          </Grid>
          <Grid item xs={2} className={classes.paginationControls}>
            <span>
              Page{'  '}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                value={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: '100px' }}
              />{' '}
              of {pageOptions.length}
            </span>{' '}
          </Grid>
          <Grid item xs={2} className={classes.paginationControls}>
            <span>
              <select
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize} Rows
                  </option>
                ))}
              </select>
            </span>
          </Grid>
          <Grid item xs={4} className={classes.paginationBtns}>
            <button type="button" onClick={() => nextPage()} disabled={!canNextPage}>
              Next
            </button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const ReactTableGenericComp = ({ data, columns }) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      row,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0 },
      },
      useFilters,
      useSortBy,
      usePagination,
      useBlockLayout,
      useResizeColumns,
    );

    return (
      <Grid className={classes.generalReportTable}>
        <Grid className={classnames(classes.generalReportTableContainer, undefined)}>
          <table className={classes.generalReportTableMain} {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      className={[
                        classes.generalReportHeaderTh,
                        `resizer ${column.isResizing ? 'isResizing' : ''}`,
                      ]}
                      {...column.getResizerProps()}
                    >
                      {column.render('Header') && (
                        <tr className={classes.generalReportHeaderTr}>
                          <th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            className={classes.generalReportHeaderTh}
                          >
                            {column.render('Header')}
                          </th>
                        </tr>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className={classes.generalReportMainTbody}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={classes.generalReportTbodyTr}
                          onClick={() => loadPatient(row)}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Grid>
        <Grid container xs={12} className={classes.paginationBtnsContainer}>
          <Grid item xs={4} className={classes.paginationBtns}>
            <button type="button" onClick={() => previousPage(0)} disabled={!canPreviousPage}>
              Previous
            </button>
          </Grid>
          <Grid item xs={2} className={classes.paginationControls}>
            <span>
              Page{'  '}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                value={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: '100px' }}
              />{' '}
              of {pageOptions.length}
            </span>{' '}
          </Grid>
          <Grid item xs={2} className={classes.paginationControls}>
            <span>
              <select
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize} Rows
                  </option>
                ))}
              </select>
            </span>
          </Grid>
          <Grid item xs={4} className={classes.paginationBtns}>
            <button type="button" onClick={() => nextPage()} disabled={!canNextPage}>
              Next
            </button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={classes.reportContainer}>
      {modal.data !== null && modal.data !== undefined ? (
        <Dialog
          open={modal.open}
          onClose={closeModal}
          maxWidth="xl"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
            <DialogTitle id="alert-dialog-title">{modal.label}</DialogTitle>
            <DialogContent>
              <Grid item xs={12} className={classes.reportTable}>
                {modal.reportId === 101 ? (
                  <ReactTableComp
                    data={modal.data}
                    columns={cols}
                    renderRowSubComponent={renderRowSubComponent}
                  />
                ) : (
                  <ReactTableGenericComp data={modal.data} columns={cols} />
                )}
              </Grid>
            </DialogContent>
          </div>
          <Grid container spacing={7} justifyContent="center">
            <Grid item xs={2} align="center">
              <Button color="primary" variant="contained">
                <CSVLink
                  data={downloadData}
                  className={classes.downloadButton}
                  headers={headers}
                  filename={`arbor-${modal.label}-data.csv`}
                >
                  Download
                </CSVLink>
              </Button>
              <LargeSpacer />
            </Grid>
          </Grid>
        </Dialog>
      ) : (
        <span />
      )}
      <Box pt={2} />
      <Grid container spacing={7}>
        <Grid item xs={6}>
          <Typography className={classes.reportTitle}>{`${customerName} Reports`}</Typography>
        </Grid>
        {tabValue === REPORTS_TAB && (
          <Grid item xs={6}>
            <ReportVisibilityButton
              initialReports={selectedReports}
              onClose={handleSelectReports}
            />
          </Grid>
        )}
      </Grid>
      <Box pt={2} />
      <Tabs
        value={tabValue}
        onChange={(event, newValue) => setTabValue(newValue)}
        classes={{
          root: classes.secondaryTabRoot,
          indicator: classes.secondaryTabsIndicator,
        }}
        aria-label="Report's tabs"
      >
        <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label="Reports" />
        <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label="Invoicing" />
        <Tab
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          label="Previous Invoices"
        />
      </Tabs>
      <hr className={classes.tabsHr} />
      <Grid container className={classes.containerTabWrapper}>
        {!selectedReports.length && tabValue === REPORTS_TAB && (
          <Grid item xs={12} className={classes.noReportSelectedTxt}>
            <Typography>Select the reports to be displayed from the list above</Typography>
            <ReportVisibilityButton
              initialReports={selectedReports}
              onClose={handleSelectReports}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          className={
            !selectedReports.length && tabValue === REPORTS_TAB
              ? classes.noReportSelectedContainerWrapper
              : ''
          }
        >
          {renderContainerTabs(tabValue)}
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = state => {
  const { filters, sidebarFilters, reports } = state;
  return {
    filters,
    reports,
    sidebarFilters: sidebarFilters.data.report,
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    updateReportModal,
    addPatient,
    fetchPatient,
    fetchReportData,
    updateWorkList,
    selectPatientTab,
    updateReportSidebar,
    loadReports,
  }),
)(Report);

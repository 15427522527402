import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  ToggleDisplayAddIncome,
  ToggleDisplayAddMedicalInsurance,
  ToggleDisplayAddPBMInsurance,
  ToggleDisplayAddFinancialAssistance,
} from '../../actions/action-form-displays';
import { ToggleFabPanel } from '../../actions/action-view';
import { BasicActionItem, IBasicActionItemProps } from './basic-action-item';

export const NewIncomeButton = compose<IBasicActionItemProps, { inheritedClass: string }>(
  connect(
    () => ({ buttonText: '+ Income', id: 'NewIncomeButton' }),
    (dispatch: Dispatch) => {
      return {
        onClick: () =>
          dispatch(new ToggleDisplayAddIncome(true)) &&
          dispatch(new ToggleFabPanel(false)) &&
          window.scrollTo(0, 0),
      };
    },
  ),
)(BasicActionItem);

export const NewMedicalInsuranceButton = compose<IBasicActionItemProps, { inheritedClass: string }>(
  connect(
    () => ({ buttonText: '+ Medical Insurance', id: 'NewMedicalInsuranceButton' }),
    (dispatch: Dispatch) => {
      return {
        onClick: () =>
          dispatch(new ToggleDisplayAddMedicalInsurance(true)) &&
          dispatch(new ToggleFabPanel(false)) &&
          window.scrollTo(0, 0),
      };
    },
  ),
)(BasicActionItem);

export const NewPBMInsuranceButton = compose<IBasicActionItemProps, { inheritedClass: string }>(
  connect(
    () => ({ buttonText: '+ PBM Insurance', id: 'NewPBMInsuranceButton' }),
    (dispatch: Dispatch) => {
      return {
        onClick: () =>
          dispatch(new ToggleDisplayAddPBMInsurance(true)) &&
          dispatch(new ToggleFabPanel(false)) &&
          window.scrollTo(0, 0),
      };
    },
  ),
)(BasicActionItem);

export const NewFinancialAssistanceButton = compose<
  IBasicActionItemProps,
  { inheritedClass: string }
>(
  connect(
    () => ({ buttonText: '+ Financial Assistance', id: 'NewFinancialAssistanceButton' }),
    (dispatch: Dispatch) => {
      return {
        onClick: () =>
          dispatch(new ToggleDisplayAddFinancialAssistance(true)) &&
          dispatch(new ToggleFabPanel(false)) &&
          window.scrollTo(0, 0),
      };
    },
  ),
)(BasicActionItem);

import withStyles from '@mui/styles/withStyles';
import ReconciliationCard from 'components/card/reconciliation-card';
import AddPatientTherapy from 'containers/patient/therapy/add-patient-therapy/add-patient-therapy';
import { convertToArborDate } from 'models/time/arbor-date';
import React, { useState } from 'react';
// eslint-disable-next-line max-len
import EditMedicationFormFull from 'containers/patient/clinical/medication-list/edit-medication-form-full';
import { useSelector, useDispatch } from 'react-redux';
import { resolveReconciliationMedication } from 'actions/action-reconciliation';
import { findLinkedTherapyByMedication } from 'services/utils/medication-service';
import { setMedicationExcludedValue } from 'services/utils/reconciliation-service';
import { MED_SYNC_STATUS } from 'constants/index';
import { styles } from './reconciliation-styles';

function ReconciliationMedicationCard(props) {
  const {
    index,
    total,
    existingMed,
    matchedMed,
    newMed,
    isLoading,
    classes,
    canBeLinked,
    matchedMedGroup,
  } = props;
  const [displaySubForm, setDisplaySubForm] = useState('');
  const therapies = useSelector(state => state.therapies.data);
  const selectedPatientId = useSelector(state => state.selectedPatientId);
  const medSync = useSelector(state => state.patient?.med_sync === MED_SYNC_STATUS.OPT_IN); // eslint-disable-line
  const medicationReconcilationFields = [
    {
      label: 'Medication Name',
      name: 'drug_name',
    },
    {
      label: 'NDC',
      name: 'ndc',
    },
    {
      label: 'EMR Status',
      name: 'status_code',
    },
    {
      label: 'Strength',
      name: 'strength_str',
    },
  ];

  const dispatch = useDispatch();

  const excludedValues = newMed.excluded_values || {};

  const rejectMedication = () => {
    setMedicationExcludedValue(existingMed, newMed, excludedValues);
    dispatch(resolveReconciliationMedication('reject', newMed, excludedValues));
  };

  const resolveMedication = excludedObj => {
    dispatch(resolveReconciliationMedication('accept', newMed, excludedObj));
  };

  const linkMedication = excludedObj => {
    dispatch(resolveReconciliationMedication('merge', newMed, excludedObj));
  };

  const renderMedication = col => {
    let currentMedication;
    let comparedMedication;
    if (col === 'new') {
      currentMedication = newMed;
      comparedMedication = existingMed;
    } else {
      currentMedication = existingMed;
      comparedMedication = newMed;
    }
    if (!currentMedication) {
      return [{ value: 'No matching medication on file' }];
    }
    return medicationReconcilationFields.map(field => {
      const { name } = field;
      let highlight = false;
      if (!currentMedication) {
        return {};
      }
      let value = currentMedication[name];
      if (comparedMedication) {
        highlight = value !== comparedMedication[name];
      }
      if (name === 'start_dt' || name === 'end_dt') {
        value = convertToArborDate(currentMedication[name], true).getUtcDate(true);
        if (comparedMedication) {
          highlight = value !== convertToArborDate(comparedMedication[name], true).getUtcDate(true);
        }
      } else if (name === 'is_prn') {
        value = currentMedication[name] ? 'Yes' : 'No';
      }
      return {
        label: field.label,
        value,
        highlight,
      };
    });
  };

  const subForms = [];

  if (!existingMed || !findLinkedTherapyByMedication(therapies, existingMed)) {
    subForms.push({
      button: 'Add new therapy',
      buttonHandler: setDisplaySubForm,
      display: 'add_therapy',
      form: (
        <AddPatientTherapy
          selectedDrug={{
            ...newMed,
            id: matchedMed && matchedMed.id,
          }}
          excludedValues={excludedValues}
          stagingMedication={newMed}
          patientId={selectedPatientId}
          medSync={medSync}
          handleCloseAddForm={() => setDisplaySubForm()}
          submitCallback={resolveMedication}
        />
      ),
    });
  }

  if (!existingMed) {
    subForms.push({
      button: 'Add new medication',
      buttonHandler: setDisplaySubForm,
      display: 'add_medication',
      form: (
        <AddPatientTherapy
          selectedDrug={{
            ...newMed,
            id: null, // this id is for staging medication
          }}
          excludedValues={excludedValues}
          stagingMedication={newMed}
          forceDocumentOnlyOption
          patientId={selectedPatientId}
          medSync={medSync}
          handleCloseAddForm={() => setDisplaySubForm()}
          submitCallback={resolveMedication}
        />
      ),
    });
  }

  if (existingMed) {
    subForms.push({
      button: 'Update with new changes',
      buttonHandler: setDisplaySubForm,
      display: 'update_medication',
      form: (
        <div className={classes.subFormContainer}>
          <EditMedicationFormFull
            medication={{
              ...newMed,
              id: matchedMed && matchedMed.id,
              // Although we compare to group info we need to update matched med by source_med_id,
              // then it will automatically update group
            }}
            stagingMedication={newMed}
            submitCallback={excludedObj => resolveMedication(excludedObj)}
            cancelHandler={() => setDisplaySubForm()}
            isFromReconciliation
          />
        </div>
      ),
    });
  }

  if (!!canBeLinked && !!existingMed && !!matchedMedGroup) {
    subForms.push({
      button: 'Link medication',
      buttonHandler: setDisplaySubForm,
      display: 'link_medication',
      form: (
        <div className={classes.subFormContainer}>
          <EditMedicationFormFull
            medication={{
              ...newMed,
              id: existingMed.id,
              // Add group_id to update the med group instead of inserting a new one since there is
              // no match of ndc in medication_groups table.
              group_id: matchedMedGroup.id,
            }}
            submitCallback={excludedObj => linkMedication(excludedObj)}
            cancelHandler={() => setDisplaySubForm(false)}
            isFromReconciliation
          />
        </div>
      ),
    });
  }
  return (
    <ReconciliationCard
      header="Medication Reconcile Information"
      displaySubForm={displaySubForm}
      subForms={subForms}
      oldCol={renderMedication('old')}
      newCol={renderMedication('new')}
      sourceName={newMed.source_name || 'source not found'}
      rejectHandler={rejectMedication}
      index={index}
      total={total}
      isLoading={isLoading}
    />
  );
}

export default withStyles(styles, { withTheme: true })(ReconciliationMedicationCard);

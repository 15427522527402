import React, { useEffect } from 'react';
import {
  IContact,
  IPatientContact,
  IPreferenceDemographicInfoProps,
} from 'components/patient-merge-modal/interfaces';
import { constantListLabelLookup } from 'services/utils/common-service';
import { contactMethodTypes, methodOfDelivery } from 'constants/lists';
import { Divider, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { IState } from 'interfaces/redux/IState';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { useTheme } from '@mui/styles';

export function PreferencesDemographicInfo(props: IPreferenceDemographicInfoProps) {
  const { preference, patient, contacts } = props;
  const paymentMethodTypes = useSelector((state: IState) => state.lookups.paymentMethodTypes);
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);
  const contactMap: Record<string, IContact> = {};
  contacts.forEach(contact => {
    contactMap[String(contact.contactId)] = contact;
  });
  let preferredContact: IPatientContact | IContact | null = null;

  useEffect(() => {
    preferredContact = preference.preferredContactPatient
      ? patient
      : contactMap[preference.preferredContactId ?? ''];
  }, [preference, patient, contactMap]);

  const preferredRxDeliveryContact = preference.preferredRxDeliveryPatient
    ? patient
    : contactMap[preference.preferredRxDeliveryContactId ?? ''];

  const preferredContactMethodDisplay = constantListLabelLookup(
    contactMethodTypes,
    preference.preferredContactMethod,
    'Not Specified',
  );
  const preferredDeliveryMethodDisplay = constantListLabelLookup(
    methodOfDelivery,
    preference.preferredRxDeliveryMethod,
    'Not Specified',
  );

  let deliveryAddressStr;
  const preferredRxDeliveryAddress = preferredRxDeliveryContact?.addresses?.find(
    address => address.preferred,
  );
  if (preferredRxDeliveryAddress) {
    const deliveryAddressParts = [
      preferredRxDeliveryAddress.line1,
      preferredRxDeliveryAddress.line2,
      preferredRxDeliveryAddress.city,
      preferredRxDeliveryAddress.state,
      preferredRxDeliveryAddress.zip,
    ].filter(part => !!part);
    deliveryAddressStr = `${preferredRxDeliveryAddress.use} - ${deliveryAddressParts.join(', ')}`;
  }

  const paymentMethod = paymentMethodTypes.find(
    (item: any) => item.value === preference.paymentMethod.paymentType,
  )?.label;

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <Typography sx={classes.checkboxTitle}>Communication Preference</Typography>
      </Grid>
      {preferredContact !== null && (
        <>
          <Grid item xs={6}>
            <Typography sx={classes.checkboxInfoLabel}>Preferred Contact</Typography>
            <Typography sx={classes.checkboxInfoValue}>
              {`${(preferredContact as IContact).firstName} ${
                (preferredContact as IContact).lastName
              }`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={classes.checkboxInfoLabel}>Language</Typography>{' '}
            <Typography sx={classes.checkboxInfoValue}>
              {(preferredContact as IContact).languages?.length
                ? (preferredContact as IContact).languages
                    .map(language => language.value)
                    .join(', ')
                : '-'}
            </Typography>
          </Grid>
        </>
      )}
      <Grid item xs={6}>
        <Typography sx={classes.checkboxInfoLabel}>Preferred Contact Method</Typography>
        <Typography sx={classes.checkboxInfoValue}>{preferredContactMethodDisplay}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={classes.checkboxInfoLabel}>Contact Method Note</Typography>
        <Typography sx={classes.checkboxInfoValue}>{preference.contactMethodNote}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={classes.divider} light />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={classes.checkboxTitle}>Rx Delivery Preference</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={classes.checkboxInfoLabel}>Preferred Rx Delivery Contact</Typography>
        <Typography sx={classes.checkboxInfoValue}>
          {preferredRxDeliveryContact
            ? `${preferredRxDeliveryContact.firstName} ${preferredRxDeliveryContact.lastName}`
            : '-'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={classes.checkboxInfoLabel}>Preferred Rx Delivery Method</Typography>
        <Typography sx={classes.checkboxInfoValue}>{preferredDeliveryMethodDisplay}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={classes.checkboxInfoLabel}>Preferred Rx Delivery Address</Typography>
        <Typography sx={classes.checkboxInfoValue}>{deliveryAddressStr ?? '-'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={classes.checkboxInfoLabel}>Payment Method (Credit Card) on File</Typography>
        <Typography sx={classes.checkboxInfoValue}>
          {preference?.paymentMethod?.cardNum
            ? `****-****-****-${preference.paymentMethod.cardNum}`
            : '-'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={classes.checkboxInfoLabel}>Payment Method Type</Typography>
        <Typography sx={classes.checkboxInfoValue}>{paymentMethod}</Typography>
      </Grid>
    </Grid>
  );
}

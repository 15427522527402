import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../nav-styles';
import PatientDemographics from '../../../containers/patient/demographics/patient-demographics';

const Demographics = () => (
  <div>
    <PatientDemographics />
  </div>
);

export default withStyles(styles, { withTheme: true })(Demographics);

import { Divider, Grid, Typography, Button, Radio, Modal } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { updatePatientPreferences } from 'actions/action-patient';
import { updateFCDispensingPharmacyInRedux } from 'actions/action-tasks';
import { updateTherapiesDispensingPharmacyInRedux } from 'actions/action-therapies';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import {
  renderCheckboxHorizontal,
  renderDropdown,
  renderReactRadioGroup,
  renderTextField,
  renderNumberMaskedField,
  renderRadioGroup,
} from 'components/form/field/redux-field';
import SubHeader from 'components/form/header/subheader';
import {
  ContactRow,
  SelectPreferredEmail,
  SelectPreferredPhone,
  RxDeliveryAddressRow,
} from 'components/form/subform/subform';
import {
  EDIT_PATIENT_PREFERENCE_FORM,
  CONTACT_FORM,
  PATIENT_RX_DELIVERY_PREFERENCES as formConstants,
  EXPIRATION_DATE_FORMAT,
} from 'constants/index';
import { contactMethodTypes, methodOfDelivery } from 'constants/lists';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { change, Field, getFormValues, reduxForm, SubmissionError, FieldArray } from 'redux-form';
import { isValidContactMethod, isValidDeliveryAddress } from 'services/utils/demographic-service';
import { isValidArray } from 'services/utils/common-service';
import { getModalStyle } from 'services/utils/styles-service';
import { EditAdd, EditDelete } from 'components/icons/icons';
import {
  required,
  length,
  cardExpirationValidation,
  addressRequired,
} from 'components/form/validation/validation';
import { staticProviders } from 'components/dynamic-form/providers/common-provider';
import { sortBy } from 'lodash';
import { LookupPaymentMethodTypes } from 'containers/add-payment-method/types';
import { styles } from './patient-demographics-styles';

// eslint-disable-next-line max-len
import AddDeliveryProviderForm from '../../../components/add-contact-provider/add-delivery-provider';

const fourDigitCreditCardValidation = length(4, formConstants.PAYMENT_METHODS.ERRORS.LENGTH_4);

export class PreferenceForm extends Component {
  constructor(props) {
    super(props);
    this.formValues = props.formValues;
    this.internalPharmacies = props.internalPharmacies.map(ph => ({
      label: ph.name,
      value: ph.id,
    }));
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePreferred = this.handlePreferred.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.renderPaymentMethods = this.renderPaymentMethods.bind(this);
    this.addNewPaymentMethod = this.addNewPaymentMethod.bind(this);
    this.addNewProviderOffice = this.addNewProviderOffice.bind(this);
    this.updateField = this.updateField.bind(this);
    this.updateRank = this.updateRank.bind(this);
    this.state = {
      isAddProviderOfficeModalOpen: false,
      memoizedValidators: new Map(),
    };
  }

  updateField(value, fieldIndex, field) {
    const { change: changeEvent } = this.props;
    changeEvent(`${fieldIndex}[${field}]`, value);
    changeEvent(`${fieldIndex}.updated`, true);
  }

  updateRank(index, value) {
    const { change: changeEvent } = this.props;
    this.props.formValues.payment_methods?.forEach((item, oIndex) => {
      if (item.rank === value) {
        changeEvent(`payment_methods[${oIndex}][rank]`, null);
        changeEvent(`payment_methods[${oIndex}][updated]`, true);
      }
    });
    changeEvent(`payment_methods[${index}][rank]`, value);
    changeEvent(`payment_methods[${index}][updated]`, true);
  }

  addNewPaymentMethod() {
    const {
      formValues,
      array: { push: arrayPush },
    } = this.props;
    const currentValues = formValues.payment_methods ?? [];
    arrayPush('payment_methods', {
      id: undefined,
      card_num: undefined,
      rank: currentValues.filter(value => !value.deleted).length === 0 ? 1 : null,
      payment_type: undefined,
      expiration_Date: undefined,
    });
  }

  addNewProviderOffice() {
    this.setState({ isAddProviderOfficeModalOpen: true });
  }

  closeNewProviderOffice() {
    this.setState({ isAddProviderOfficeModalOpen: false });
  }

  handleCancel() {
    const { cancelHandler, reset } = this.props;
    cancelHandler();
    reset();
  }

  handlePreferred(type, key) {
    const { change } = this.props; // eslint-disable-line
    change(type, key);
  }

  handleSubmit(values) {
    const {
      activeTherapies,
      activeFCTasks,
      internalPharmacies,
      patient,
      updatePatientPreferences, // eslint-disable-line
      cancelHandler,
      preferenceContactList,
      updateTherapiesDispensingPharmacyInRedux,
      updateFCDispensingPharmacyInRedux,
    } = this.props;
    let errContactMethodMes;
    let errDeliveryMethodMes;
    let preferredContact;
    let preferredDeliveryContact;
    const paymentMethodsToSubmit =
      values.payment_methods
        ?.filter(
          paymentMethod => !paymentMethod.id || paymentMethod.deleted || paymentMethod.updated,
        )
        .map(({ updated, ...rest }) => rest) ?? [];
    const payload = {
      updateTherapiesAndFCs: values.apply_choice_to_therapies_and_FCs,
      patient_id: patient.id,
      preferred_contact_patient: !values.preferred_contact_id || values.preferred_contact_id === 0,
      preferred_contact_id: values.preferred_contact_id === 0 ? null : values.preferred_contact_id,
      preferred_contact_method: values.preferred_contact_method,
      contact_method_note: values.contact_method_note,
      preferred_rx_delivery_patient:
        !values.preferred_rx_delivery_contact_id || values.preferred_rx_delivery_contact_id === 0,
      preferred_rx_delivery_contact_id:
        values.preferred_rx_delivery_contact_id === 0
          ? null
          : values.preferred_rx_delivery_contact_id,
      preferred_rx_delivery_pharmacy_id: values.preferred_rx_delivery_pharmacy_id,
      preferred_rx_delivery_method: values.preferred_rx_delivery_method,
      preferred_rx_delivery_entity_key: values.preferred_rx_delivery_entity_key,
      rx_delivery_note: values.rx_delivery_note,
      rx_order_note: values.rx_order_note,
      payment_methods: paymentMethodsToSubmit,
      easy_open_caps: Number(values.easy_open_caps),
      adherence_packaging: Number(values.adherence_packaging),
      med_sync: Number(values.med_sync),
      welcome_kit: Number(values.welcome_kit),
      signature_required: Number(values.signature_required),
    };
    // Submit level validation
    if (payload.preferred_contact_patient) {
      errContactMethodMes = isValidContactMethod(
        patient,
        values.preferred_contact_method,
        values.preferred_contact_item,
      );
    } else {
      preferredContact = preferenceContactList[values.preferred_contact_id];
      errContactMethodMes = isValidContactMethod(
        preferredContact,
        values.preferred_contact_method,
        values.preferred_contact_item,
      );
    }
    if (payload.preferred_rx_delivery_patient) {
      errDeliveryMethodMes = isValidDeliveryAddress(patient, values.preferred_rx_delivery_method);
    } else {
      preferredDeliveryContact = preferenceContactList[values.preferred_rx_delivery_contact_id];
      errDeliveryMethodMes = isValidDeliveryAddress(
        preferredDeliveryContact,
        values.preferred_rx_delivery_method,
      );
    }
    if (errContactMethodMes) {
      throw new SubmissionError({
        preferred_contact_method: errContactMethodMes,
      });
    }
    if (errDeliveryMethodMes) {
      throw new SubmissionError({
        preferred_rx_delivery_method: errDeliveryMethodMes,
      });
    }
    updatePatientPreferences(payload, patient, preferredContact, values.preferred_contact_item);
    if (values.apply_choice_to_therapies_and_FCs) {
      const newPharmacy = internalPharmacies.find(
        ph => ph.id === values.preferred_rx_delivery_pharmacy_id,
      );
      const updatedTherapiesPayload = {
        therapyIds: activeTherapies.map(therapy => therapy.id),
        pharmacy: newPharmacy,
      };
      updateTherapiesDispensingPharmacyInRedux(updatedTherapiesPayload);
      const updatedFCPayload = {
        fcIds: activeFCTasks.map(task => task.id),
        pharmacy: newPharmacy,
      };
      updateFCDispensingPharmacyInRedux(updatedFCPayload);
    }

    cancelHandler();
  }

  getAddressValidator = address => {
    const { memoizedValidators } = this.state;

    if (memoizedValidators.has(address)) {
      return memoizedValidators.get(address);
    }

    const validator = addressRequired(address);
    memoizedValidators.set(address, validator);
    return validator;
  };

  renderContactPhoneOrEmail(preferredContact, preferredContactMethod) {
    const { classes, formValues } = this.props;
    if (!preferredContact || !preferredContactMethod || preferredContactMethod === 4) return null;
    // Email
    if (preferredContactMethod === 2) {
      if (preferredContact.email_options === 1) {
        return <Typography variant="body2">Decline to provide emails</Typography>;
      }
      if (preferredContact.email_options === 2) {
        return <Typography variant="body2">No email to provide</Typography>;
      }
      if (!isValidArray(preferredContact.filteredEmails)) {
        return <Typography variant="body2">No email on file</Typography>;
      }
      return (
        <>
          {preferredContact.filteredEmails &&
            preferredContact.filteredEmails.map((email, index) => (
              <SelectPreferredEmail
                key={email.key}
                index={index}
                preferredId={formValues && formValues.preferred_contact_item}
                preferredField="preferred_contact_item"
                email={email}
                preferredHandler={this.handlePreferred}
                classes={classes}
              />
            ))}
        </>
      );
    }
    // Phone or SMS
    if (preferredContact.is_declined_to_provide_phones) {
      return <Typography variant="body2">Decline to provide phones</Typography>;
    }
    if (!isValidArray(preferredContact.filteredPhones)) {
      return <Typography variant="body2">No phone on file</Typography>;
    }
    if (preferredContactMethod === 3) {
      return (
        <>
          {preferredContact.filteredPhones &&
            preferredContact.filteredPhones.map((phone, index) => (
              <SelectPreferredPhone
                key={phone.key}
                sms
                index={index}
                preferredId={formValues && formValues.preferred_contact_item}
                phone={phone}
                preferredHandler={this.handlePreferred}
                preferredField="preferred_contact_item"
                classes={classes}
              />
            ))}
        </>
      );
    }
    return (
      <>
        {preferredContact.filteredPhones &&
          preferredContact.filteredPhones.map((phone, index) => (
            <SelectPreferredPhone
              key={phone.key}
              index={index}
              preferredId={formValues && formValues.preferred_contact_item}
              phone={phone}
              preferredHandler={this.handlePreferred}
              preferredField="preferred_contact_item"
              classes={classes}
            />
          ))}
      </>
    );
  }

  // eslint-disable-next-line
  renderPaymentMethods = ({
    fields,
    formArray: { splice: arraySplice },
    formValues,
    classes,
    paymentMethodTypes,
  }) => {
    const setSelectedPaymentMethod = index => {
      const currentPrimaryIndex = formValues.payment_methods.findIndex(({ rank }) => rank === 1);
      const currentPrimary = formValues.payment_methods[currentPrimaryIndex];
      const paymentMethodToChange = formValues.payment_methods[index];
      arraySplice('payment_methods', index, 1, {
        ...paymentMethodToChange,
        rank: 1,
        updated: true,
      });

      if (currentPrimaryIndex > -1) {
        arraySplice('payment_methods', currentPrimaryIndex, 1, {
          ...currentPrimary,
          rank: null,
          updated: true,
        });
      }
    };

    /**
     *
     * @param {number} index index of the item in the array to mark as deleted
     */
    const setDeleted = index => {
      const itemToDelete = formValues.payment_methods[index];
      arraySplice('payment_methods', index, 1, {
        ...itemToDelete,
        deleted: true,
        rank: null,
      });
      // Ensure a primary payment method is set if you just deleted the primary.
      // Use the next available ranked method (2, then 3), or just the next one there.
      const otherPaymentMethods = formValues.payment_methods.filter(
        ({ deleted }, i) => !deleted && i !== index,
      );
      if (itemToDelete.rank === 1 && otherPaymentMethods.length) {
        // set the next available payment method to primary
        const nextAvailableMethod = sortBy(otherPaymentMethods, 'rank')[0];
        if (nextAvailableMethod) {
          arraySplice(
            'payment_methods',
            formValues.payment_methods.indexOf(nextAvailableMethod),
            1,
            {
              ...nextAvailableMethod,
              rank: 1,
              updated: true,
            },
          );
        }
      }
    };

    return (
      <Grid container>
        {fields.map((field, index) => {
          const fieldIndexPrefix = `payment_methods[${index}]`;
          const isPrimaryPaymentMethod = formValues.payment_methods[index]?.rank === 1;
          const { deleted } = formValues.payment_methods[index];
          if (deleted) {
            return null;
          }

          return (
            <Grid item xs={12} className={classes.paymentMethodOnFileRow} key={fieldIndexPrefix}>
              <Grid container>
                <Grid item xs={11}>
                  <Grid container>
                    <Grid item xs={1} data-qa-id={`${fieldIndexPrefix}.is_primary`}>
                      <Radio
                        checked={isPrimaryPaymentMethod}
                        color="primary"
                        style={{ marginTop: 18, marginRight: 10 }}
                        onClick={() => setSelectedPaymentMethod(index)}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ 'flexBasis': '25%', '& > div': { width: '176px!important' } }}
                      data-qa-id={`${fieldIndexPrefix}.${formConstants.PAYMENT_METHODS.FIELDS.CARD_NUM}`}
                    >
                      <Field
                        // eslint-disable-next-line max-len
                        name={`${fieldIndexPrefix}.${formConstants.PAYMENT_METHODS.FIELDS.CARD_NUM}`}
                        validate={[required, fourDigitCreditCardValidation]}
                        component={renderNumberMaskedField}
                        maskFormat={formConstants.PAYMENT_METHODS.FIELD_MASK}
                        label={formConstants.PAYMENT_METHODS.LABELS.LAST_4}
                        allowEmptyFormatting
                        onChange={event => {
                          event.preventDefault();
                        }}
                        onBlur={event => {
                          event.preventDefault();
                        }}
                        onValueChange={({ value }) =>
                          this.updateField(
                            value,
                            fieldIndexPrefix,
                            formConstants.PAYMENT_METHODS.FIELDS.CARD_NUM,
                          )
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      data-qa-id={`${fieldIndexPrefix}.${formConstants.PAYMENT_METHODS.FIELDS.EXPIRATION}`}
                    >
                      <Field
                        // eslint-disable-next-line max-len
                        name={`${fieldIndexPrefix}.${formConstants.PAYMENT_METHODS.FIELDS.EXPIRATION}`}
                        label={`${formConstants.PAYMENT_METHODS.LABELS.EXPIRATION_DATE} *`}
                        component={renderNumberMaskedField}
                        allowEmptyFormatting
                        maskFormat="##/####"
                        placeholder={EXPIRATION_DATE_FORMAT}
                        maskCharacter={['M', 'M', 'Y', 'Y', 'Y', 'Y']}
                        validate={[cardExpirationValidation, required]}
                        onChange={event => {
                          event.preventDefault();
                        }}
                        onBlur={event => {
                          event.preventDefault();
                        }}
                        onValueChange={({ formattedValue }) =>
                          this.updateField(
                            formattedValue,
                            fieldIndexPrefix,
                            formConstants.PAYMENT_METHODS.FIELDS.EXPIRATION,
                          )
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      data-qa-id={`${fieldIndexPrefix}.${formConstants.PAYMENT_METHODS.FIELDS.TYPE}`}
                    >
                      <Field
                        name={`${fieldIndexPrefix}.${formConstants.PAYMENT_METHODS.FIELDS.TYPE}`}
                        validate={[required]}
                        label={formConstants.PAYMENT_METHODS.LABELS.PAYMENT_TYPE}
                        component={renderDropdown}
                        fields={paymentMethodTypes.filter(
                          paymentType =>
                            paymentType.value !== LookupPaymentMethodTypes.VirtualCreditCard,
                        )}
                        onChange={({ value }) =>
                          this.updateField(
                            value,
                            fieldIndexPrefix,
                            formConstants.PAYMENT_METHODS.FIELDS.TYPE,
                          )
                        }
                      />
                    </Grid>
                    {isPrimaryPaymentMethod ? (
                      <Grid
                        item
                        sx={{ marginLeft: 'auto', flexBasis: '20%' }}
                        data-qa-id={`${fieldIndexPrefix}.${formConstants.PAYMENT_METHODS.FIELDS.ORDER}`}
                      >
                        <Field
                          name={`${fieldIndexPrefix}.rank`}
                          label={formConstants.PAYMENT_METHODS.LABELS.ORDER}
                          component={renderDropdown}
                          fields={[{ value: 1, label: 'Primary' }]}
                          disabled
                          value={1}
                        />
                      </Grid>
                    ) : (
                      <Grid
                        item
                        sx={{ marginLeft: 'auto', flexBasis: '20%' }}
                        data-qa-id={`${fieldIndexPrefix}.${formConstants.PAYMENT_METHODS.FIELDS.ORDER}`}
                      >
                        <Field
                          name={`${fieldIndexPrefix}.rank`}
                          label={formConstants.PAYMENT_METHODS.LABELS.ORDER}
                          component={renderDropdown}
                          fields={[
                            { value: 2, label: 'Secondary' },
                            { value: 3, label: 'Tertiary' },
                          ]}
                          isClearable
                          onClear={() => {
                            this.updateRank(index, null);
                          }}
                          onChange={val => {
                            if (val && typeof val === 'number' && typeof val !== 'undefined') {
                              this.updateRank(index, val);
                              return;
                            }
                            this.updateRank(index, null);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={1} className={classes.rightAlign}>
                  <div className={classes.paymentMethodOnFieldDeleteButtonContainer}>
                    <Button
                      onClick={() => setDeleted(index)}
                      className={classes.paymentMethodOnFieldDeleteButton}
                    >
                      <EditDelete />
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  render() {
    const {
      classes,
      preferenceContactList,
      handleSubmit,
      submitting,
      pristine,
      formValues,
      paymentMethodTypes,
    } = this.props;
    const filteredContactList = Object.entries(preferenceContactList).reduce(
      (acc, [key, value]) => {
        if (
          value[CONTACT_FORM.FIELD_NAMES.DOESNT_HAVE_EMERGENCY_CONTACT] ||
          value[CONTACT_FORM.FIELD_NAMES.DECLINED_TO_PROVIDE_EMERGENCY_CONTACT]
        ) {
          return acc;
        }
        return {
          ...acc,
          [key]: value,
        };
      },
      {},
    );

    if (!formValues) {
      return null;
    }

    let preferredContact;
    if (formValues.preferred_contact_id) {
      preferredContact = filteredContactList[formValues.preferred_contact_id];
    } else {
      preferredContact = filteredContactList[0]; //eslint-disable-line
    }

    // eslint-disable-next-line max-len
    const preferredContactDisplay = `Contact Method For ${preferredContact.first_name || ''}  ${
      preferredContact.last_name || ''
    }`;
    const preferredContactMethod = formValues.preferred_contact_method;

    const { isAddProviderOfficeModalOpen } = this.state;

    return (
      <form
        onSubmit={handleSubmit(this.handleSubmit)}
        className={classes.editForm}
        autoComplete="off"
      >
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Grid container spacing={7}>
              <Grid item xs={12}>
                <Typography className={classes.preferenceHeading}>
                  Communication Preference
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={7} alignItems="center">
              <Grid item xs={12}>
                <SubHeader name="Preferred Contact" />
              </Grid>
              <Grid item xs={12}>
                {filteredContactList &&
                  Object.keys(filteredContactList).map(key => (
                    <ContactRow
                      key={key}
                      id={Number(key)}
                      classes={classes}
                      preferredId={formValues && formValues.preferred_contact_id}
                      preferredField="preferred_contact_id"
                      preferredHandler={this.handlePreferred}
                      contact={filteredContactList[key]}
                    />
                  ))}
              </Grid>
              <Grid item xs={12}>
                <SubHeader name={preferredContactDisplay} />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="preferred_contact_method"
                  label="Preferred Method"
                  width="100%"
                  component={renderDropdown}
                  fields={contactMethodTypes}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="contact_method_note"
                  label="Contact Method Note"
                  width="100%"
                  component={renderTextField}
                  multiline
                />
              </Grid>
              <Grid item xs={12}>
                {this.renderContactPhoneOrEmail(preferredContact, preferredContactMethod)}
              </Grid>
            </Grid>
            <Divider className={classes.editPreferenceFormDivider} />

            <Grid container spacing={7}>
              <Grid item xs={12}>
                <Typography className={classes.preferenceHeading}>
                  Rx Delivery Preference
                </Typography>
              </Grid>
              <Grid container item spacing={7}>
                <Grid item xs={5}>
                  <Field
                    name="preferred_rx_delivery_pharmacy_id"
                    label="Preferred Rx Delivery Pharmacy"
                    fields={this.internalPharmacies}
                    component={renderDropdown}
                  />
                </Grid>
                {formValues.preferred_rx_delivery_pharmacy_id ? (
                  <Grid item xs={5} style={{ paddingLeft: '20px' }}>
                    <Field
                      styleOverride={{ alignItems: 'flex-end', height: '56px' }}
                      labelOverride={{ fontSize: '14px', fontWeight: 500, order: 1 }}
                      name="apply_choice_to_therapies_and_FCs"
                      label="Apply this Pharmacy choice to all active Therapies and FC tasks when saving"
                      component={renderCheckboxHorizontal}
                    />
                  </Grid>
                ) : null}
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="preferred_rx_delivery_method"
                  label="Preferred Rx Delivery Method"
                  width="100%"
                  radioMap={methodOfDelivery}
                  component={renderReactRadioGroup}
                />
              </Grid>
              <Grid item xs={12}>
                <Modal open={isAddProviderOfficeModalOpen}>
                  <Grid container style={getModalStyle()} className={classes.modalContentWrapper}>
                    <AddDeliveryProviderForm handleCancel={() => this.closeNewProviderOffice()} />
                  </Grid>
                </Modal>
                <Grid container item justifyContent="space-between">
                  <Grid item xs={2}>
                    <SubHeader name="Prefer Delivery To" />
                  </Grid>
                  <Grid item xs={3} className={classes.rightAlign}>
                    <Button variant="outlined" onClick={() => this.addNewProviderOffice()}>
                      <EditAdd />
                      <Typography className={classes.addPaymentMethodButtonText}>
                        Provider Information
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {filteredContactList &&
                  Object.keys(filteredContactList)
                    .filter(id => id === 0 || filteredContactList[id].filteredAddresses)
                    .reduce((acc, key) => {
                      const x = filteredContactList[key].filteredAddresses;
                      x.forEach((item, index) => acc.push({ key, index, subkey: item.key }));
                      return acc;
                    }, [])
                    .map(({ key, index, subkey }) => (
                      <Field
                        key={`${key}-${subkey}`}
                        name={`preferred_delivery_address_${key}`}
                        component={RxDeliveryAddressRow}
                        value={key}
                        itemProps={{
                          id: Number(key),
                          classes,
                          preferredId: formValues && formValues.preferred_rx_delivery_contact_id,
                          preferredIdKey: formValues && formValues.preferred_rx_delivery_entity_key,
                          preferredField: 'preferred_rx_delivery_contact_id',
                          preferredHandler: this.handlePreferred,
                          contact: filteredContactList[key],
                          subkey,
                          index,
                        }}
                        {...(formValues.preferred_rx_delivery_contact_id === Number(key) && {
                          validate: this.getAddressValidator(
                            filteredContactList[key]?.filteredAddresses,
                          ),
                        })}
                      />
                    ))}
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="rx_delivery_note"
                  label="Rx Delivery Note"
                  width="100%"
                  component={renderTextField}
                  multiline
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="rx_order_note"
                  label="Order Notes"
                  width="100%"
                  component={renderTextField}
                  multiline
                />
              </Grid>
              <Grid item xs={12} className={classes.paymentMethodOnFileContainer}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Typography className={classes.paymentMethodOnFileHeader}>
                        {formConstants.PAYMENT_METHODS.LABELS.SECTION_TITLE}
                      </Typography>
                      <Button
                        variant="outlined"
                        onClick={() => this.addNewPaymentMethod()}
                        data-qa-id={formConstants.PAYMENT_METHODS.LABELS.ADD_BUTTON}
                      >
                        <EditAdd />
                        <Typography className={classes.addPaymentMethodButtonText}>
                          {formConstants.PAYMENT_METHODS.LABELS.ADD_BUTTON}
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <FieldArray
                      name="payment_methods"
                      component={this.renderPaymentMethods}
                      // eslint-disable-next-line react/destructuring-assignment
                      formArray={this.props.array}
                      formValues={formValues}
                      paymentMethodTypes={paymentMethodTypes}
                      classes={classes}
                    />
                  </Grid>
                </Grid>{' '}
              </Grid>
            </Grid>

            <Divider className={classes.editPreferenceFormDivider} />

            <Grid container spacing={7}>
              <Grid item xs={12}>
                <Typography className={classes.preferenceHeading}>
                  Patient Preference - Medication Packaging
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      label="Easy Open Caps"
                      name="easy_open_caps"
                      radioMap={staticProviders.yesNo01.map(option => ({
                        label: option.name,
                        value: option.id.toString(),
                      }))}
                      component={renderRadioGroup}
                      caption
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      label="Adherence Packaging"
                      name="adherence_packaging"
                      radioMap={staticProviders.yesNo01.map(option => ({
                        label: option.name,
                        value: option.id.toString(),
                      }))}
                      component={renderRadioGroup}
                      caption
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className={classes.preferenceHeading}>
                      Patient Preference - Med Sync
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="med_sync"
                      radioMap={staticProviders.medSync.map(option => ({
                        label: option.name,
                        value: option.id,
                      }))}
                      component={renderRadioGroup}
                      caption
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className={classes.preferenceHeading}>
                      Welcome Kit/Patient Educational materials received
                    </Typography>
                  </Grid>
                  <Grid item xs={6} data-qa-id="welcome_kit_options">
                    <Field
                      name="welcome_kit"
                      radioMap={staticProviders.yesNo01.map(option => ({
                        label: option.name,
                        value: option.id,
                      }))}
                      component={renderRadioGroup}
                      caption
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className={classes.preferenceHeading}>
                      Signature Required
                    </Typography>
                  </Grid>
                  <Grid item xs={6} data-qa-id="signature_required_options">
                    <Field
                      name="signature_required"
                      radioMap={staticProviders.yesNo01.map(option => ({
                        label: option.name,
                        value: option.id,
                      }))}
                      component={renderRadioGroup}
                      caption
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <ConfirmationPanel
              handleCancel={this.handleCancel}
              disableSubmit={submitting || pristine}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

function mapStateToProps(state) {
  const { patient, therapies } = state;
  const initialValues = {
    apply_choice_to_therapies_and_FCs: false,
    preferred_contact_id: patient.preferred_contact_id,
    preferred_contact_method: patient.preferred_contact_method,
    contact_method_note: patient.contact_method_note,
    preferred_rx_delivery_entity_key: patient.preferred_rx_delivery_entity_key,
    preferred_rx_delivery_contact_id: patient.preferred_rx_delivery_contact_id,
    preferred_rx_delivery_method: patient.preferred_rx_delivery_method,
    preferred_rx_delivery_pharmacy_id: patient.preferred_rx_delivery_pharmacy_id,
    original_rx_delivery_pharmacy_id: patient.preferred_rx_delivery_pharmacy_id,
    rx_delivery_note: patient.rx_delivery_note,
    rx_order_note: patient.rx_order_note,
    preferred_contact_item: 0,
    payment_methods: patient.payment_methods,
    easy_open_caps: patient.easy_open_caps?.toString(),
    adherence_packaging: patient.adherence_packaging?.toString(),
    med_sync: patient.med_sync,
    welcome_kit: patient.welcome_kit,
    signature_required: patient.signature_required,
  };
  const formValues = getFormValues(EDIT_PATIENT_PREFERENCE_FORM)(state) || initialValues;

  return {
    patient,
    form: EDIT_PATIENT_PREFERENCE_FORM,
    enableReinitialize: true,
    formValues,
    initialValues,
    therapies: therapies.data,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    updatePatientPreferences,
    change,
    updateTherapiesDispensingPharmacyInRedux,
    updateFCDispensingPharmacyInRedux,
  }),
)(reduxForm({})(PreferenceForm));

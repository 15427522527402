import { AxiosPromise, AxiosResponse } from 'axios';
import { IGetCdmProgramResult } from 'models/cdm/IGetCdmProgramResult';
import { IDataCollectTask } from 'interfaces/redux/task-types/IDataCollectTask';
import { IGoal } from 'models/cdm/IGoal';
import { IPatientGoalRequest } from 'models/cdm/IPatientGoalsRequest';
import { IPatientGoalResponse } from 'models/cdm/IPatientGoalsResponse';
import HTTP from '../services/http';

const baseUrl = '/cdm_programs';

export class CdmProgramClient {
  static fetch = (
    patientId: number,
    diagnosisCode: string,
  ): AxiosPromise<IGetCdmProgramResult[]> => {
    const url = `${baseUrl}/${patientId}?diagnosis_code=${diagnosisCode}`;
    return HTTP.get(url, {});
  };

  static fetchDcTasksByTherapyId = (
    patientId: number,
    therapyId: number,
    taskStatusId: number,
  ): AxiosPromise<IDataCollectTask[]> => {
    const url = `${baseUrl}/${patientId}/pending-dc-tasks?therapy_id=${therapyId}&status_id=${taskStatusId}`;
    return HTTP.get(url, {});
  };

  static fetchDcTasksByTherapyIdNoCdm = (
    patientId: number,
    therapyId: number,
    taskStatusId: number,
  ): AxiosPromise<IDataCollectTask[]> => {
    const url = `dc-work-as-group/${patientId}/pending-dc-tasks?therapy_id=${therapyId}&status_id=${taskStatusId}`;
    return HTTP.get(url, {});
  };

  static createPatientGoals = async (
    patientId: number,
    programId: number,
    goals: IGoal[],
  ): Promise<IPatientGoalResponse> => {
    const url = `${baseUrl}/goals/${patientId}/program/${programId}`;
    const body: IPatientGoalRequest = {
      goals,
      cdmProgramId: programId,
    };
    const response: AxiosResponse<IPatientGoalResponse> = await HTTP.post(url, body);
    return response.data;
  };

  static updatePatientGoals = async (
    patientId: number,
    programId: number,
    goals: IGoal[],
  ): Promise<IPatientGoalResponse> => {
    const url = `${baseUrl}/goals/${patientId}/program/${programId}`;
    const body: IPatientGoalRequest = {
      goals,
      cdmProgramId: programId,
    };
    const response: AxiosResponse<IPatientGoalResponse> = await HTTP.patch(url, body);
    return response.data;
  };
}

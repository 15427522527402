import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Typography, Tooltip } from '@mui/material';
import FetchText from 'components/form/field/fetch-text';
import HTTP from 'services/http';

import { IDosingInformationTooltipContent } from '../interfaces';
import { DosingTooltipStyled } from './tooltip.styles';

export const DosingInformationTooltipContent = ({
  dosingInformation,
}: IDosingInformationTooltipContent) => {
  const {
    diagnosis_code: diagnosisCode,
    diagnosis_description: diagnosisDescription,
    start_date: startDate,
    dosis_regimen: dosisRegimen,
  } = dosingInformation;

  const [prescriberName, setPrescriberName] = useState<string | React.ReactElement>('-');

  const fetchPhysician = (npi: string) => {
    setPrescriberName(
      npi ? (
        <FetchText
          promise={() => HTTP.get(`providers/physician/${npi}`, {})}
          // eslint-disable-next-line no-confusing-arrow
          parse={(result: any) => {
            const credential = result.data.credential ? `, ${result.data.credential}` : '';
            return result && result.data && typeof result.data.full_name === 'string'
              ? `${result.data.full_name.toLowerCase()}${credential} - NPI (${npi})`
              : '-';
          }}
        />
      ) : (
        '-'
      ),
    );
  };

  useEffect(() => {
    if (dosingInformation.prescribing_physician_npi) {
      fetchPhysician(dosingInformation.prescribing_physician_npi);
    }
  }, [dosingInformation.prescribing_physician_npi]);

  return (
    <>
      <section>
        <Typography className="info bold">Primary ICD-10 Code:</Typography>
        <Typography className="info">
          {diagnosisCode && diagnosisDescription
            ? `${diagnosisCode} - ${diagnosisDescription}`
            : '-'}
        </Typography>
      </section>

      <hr />

      <section>
        <Typography className="info bold">Start Date:</Typography>
        <Typography className="info">
          {startDate ? moment(startDate).utc().format('MM/DD/YYYY') : '-'}
        </Typography>
      </section>

      <hr />

      <section>
        <Typography className="info bold">Dosing Regimen:</Typography>
        <Typography className="info">{dosisRegimen || '-'}</Typography>
      </section>

      <hr />
      <section>
        <Typography className="info bold">Prescriber:</Typography>
        <Typography className="info">{prescriberName}</Typography>
      </section>
    </>
  );
};

export const DosingInformationTooltip = DosingTooltipStyled(Tooltip);

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: any) => {
  const baseLabel = {
    lineHeight: 0.5,
    fontSize: theme.font.mediumFontSize,
  };

  const statusLabelBase = {
    ...baseLabel,
    fontWeight: 700,
  };

  return {
    link: {
      cursor: 'pointer',
    },
    statusLabelAccepted: {
      ...statusLabelBase,
      color: theme.palette.primary.teal,
    },
    statusLabelRejected: {
      ...statusLabelBase,
      color: theme.palette.error.main,
    },
    subtitle: {
      fontSize: theme.font.extraSmallFontSize,
      color: theme.palette.primary.steelGrey,
      lineHeight: '19px',
    },
    label: {
      ...baseLabel,
      color: theme.palette.primary.formLabel,
    },
    boldInLine: {
      fontWeight: 'bold',
      fontSize: theme.font.mediumFontSize,
      display: 'inline',
    },
    boldLabel: {
      ...baseLabel,
      fontWeight: 'bold',
      color: theme.palette.primary.formLabel,
    },
    statusTitle: {
      lineHeight: 2.5,
      fontWeight: 'bold',
      fontSize: theme.font.mediumFontSize,
      color: theme.palette.primary.formLabel,
    },
    therapyBenefitsInvestigationButton: {
      'backgroundColor': theme.palette.primary.task.checked,
      'textTransform': 'none',
      'color': theme.palette.primary.white,
      'fontWeight': 'unset',
      'marginTop': '4%',
      'boxShadow': 'none',
      '&:hover': {
        backgroundColor: theme.palette.primary.task.borderHighlightColor,
        borderColor: 'none',
        boxShadow: 'none',
      },
    },
    actionIconContainer: {
      textAlign: 'right',
    },
    toggleEdit: {
      minWidth: 10,
    },
    errorButtonContainer: {
      marginTop: theme.spacing(1),
    },
    errorButton: {
      'cursor': 'default',
      'backgroundColor': 'transparent',
      'marginRight': theme.spacing(1) / 2,
      'minWidth': theme.spacing(4),
      'color': theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.grey3,
      },
    },
  };
});

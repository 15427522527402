import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

export const styles = (theme: Theme) => {
  const { palette, font } = expandTheme(theme);
  return createStyles({
    formContainer: {
      overflowY: 'auto',
    },
    formWrapper: {
      padding: theme.spacing(1.5),
    },
    errorMessage: {
      fontSize: font.smallFontSize,
      color: palette.primary.error,
    },
  });
};

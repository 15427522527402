import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid, Backdrop, Modal } from '@mui/material';
import { InfoIcon } from 'components/icons/icons';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, formValueSelector, change as _change } from 'redux-form';
import { connect } from 'react-redux';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { editIncome, fetchExistingIncomeYears, fetchIncomes } from 'actions/action-financials';
import {
  validateDate,
  required,
  validateNumber,
  validateYear,
  validateUniqueIncomeYear,
  validateDollarValue,
} from 'components/form/validation/validation';
import { getModalStyle } from 'services/utils/styles-service';
import { totalSumIncome } from 'services/utils/financials-service';
import { convertToArborDate } from 'models/time/arbor-date';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import SubHeader from 'components/form/header/subheader';
import Divider from 'components/divider';
import { SmallSpacer } from 'components/spacer/spacer';
import { stripFormattedDollarValue } from 'services/utils/formatting';
import moment from 'moment';
import { getFileExtension, acceptedFileExtensions } from 'services/utils/document-service';
import { addNewDocument } from 'actions/action-uploaded-documents';
import { uploadFile, getDocuments } from 'services/utils/upload-document-service';
import DocumentConfirmationDialog from '../document-confirmation-dialog';
import { styles } from '../financials-styles';
import {
  EDIT_INCOME_FORM,
  UPLOAD_DOC_FILENAME_FIELD,
  UPLOAD_DOCUMENT_FORM,
  UPLOAD_DOC_FILENOTE_FIELD,
  UPLOAD_DOC_FILETYPE_FIELD,
  INCOME,
} from '../../../../constants';
import { FederalPovertyTable } from './federal-poverty-table';
import {
  renderTextField,
  renderYesNoDropdown,
  renderMoneyTextField,
  renderDropdown,
  renderCheckboxHorizontal,
} from '../../../../components/form/field/redux-field';
import WrappedFileUpload from '../wrapped-file-upload';
import { noteTagTypes } from '../../../../constants/lists';

const taskTypeName = INCOME;
const tagType = noteTagTypes.find(tag => tag.label === taskTypeName);

class EditIncome extends Component {
  constructor(props) {
    super(props);

    const { income } = props;
    this.state = {
      noIncome: !!income.is_no_income,
      povertyModalOpen: false,
      incomes: {
        pension_401k_retirement_income: income.pension_401k_retirement_income,
        taxable_income: income.taxable_income,
        unemployment_income: income.unemployment_income,
        patient_ssi: income.patient_ssi,
        dependent_ssi: income.dependent_ssi,
        spouse_ssi: income.spouse_ssi,
        longterm_disability_income: income.longterm_disability_income,
      },
      // upload file props
      pendingDocument: undefined,
      uploadedDocuments: [],
      lostData: [],
      uploadProcessing: false,
      pendingDocumentLoss: false,
      dialogCancel: undefined,
      dialogContinue: undefined,
    };

    this.setNoIncome = this.setNoIncome.bind(this);
    this.handlePovertyModal = this.handlePovertyModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // Upload File
    this.handleFileUploadCancel = this.handleFileUploadCancel.bind(this);
    this.handleFileUploadSubmit = this.handleFileUploadSubmit.bind(this);
    this.fileSelected = this.fileSelected.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    const { fetchExistingIncomeYears, patient } = this.props; // eslint-disable-line
    fetchExistingIncomeYears(patient.id);
  }

  componentDidUpdate(prevProps, prevState) {
    const { fileFormName } = this.props;
    if (prevState.pendingDocument && prevState.pendingDocument.fileName !== fileFormName) {
      this.onPendingDocumentFileNameChange(fileFormName);
    }
  }

  onPendingDocumentFileNameChange(newFileName) {
    this.setState(prevState => ({
      pendingDocument: {
        ...prevState.pendingDocument,
        ...{ fileName: newFileName },
      },
    }));
  }

  setNoIncome() {
    this.setState(prevState => ({
      noIncome: !prevState.noIncome,
    }));
  }

  handlePovertyModal() {
    this.setState(prevState => ({
      povertyModalOpen: !prevState.povertyModalOpen,
    }));
  }

  updateTotalSumIncome(input) {
    const { change } = this.props;
    this.setState(prevState => {
      const newIncomes = { ...prevState.incomes };
      const result = totalSumIncome(newIncomes, input);
      change('total_sum_income', result.sumIncome);
      return {
        incomes: result.incomes,
      };
    });
  }

  finalizeSubmit(values) {
    const {
      editIncome, // eslint-disable-line
      fetchIncomes, // eslint-disable-line
      cancel,
      existingDocuments,
      addNewDocument, // eslint-disable-line
    } = this.props;

    const { uploadedDocuments } = this.state;

    const payload = {
      ...values,
      patient_id: values.patient_id,
      pension_401k_retirement_income:
        values.pension_401k_retirement_income && !values.is_no_income
          ? stripFormattedDollarValue(values.pension_401k_retirement_income)
          : null,
      taxable_income:
        values.taxable_income && !values.is_no_income
          ? stripFormattedDollarValue(values.taxable_income)
          : 0,
      is_no_income: values.is_no_income,
      gross_business_income: values.gross_business_income,
      transcript_tax_form_completed: values.transcript_tax_form_completed,
      extension_filed: values.extension_filed,
      unemployment_income: values.unemployment_income
        ? stripFormattedDollarValue(values.unemployment_income)
        : null,
      unemployment_income_period_id: values.unemployment_income_period_id
        ? values.unemployment_income_period_id
        : null,
      unemployment_start_date: values.unemployment_start_date
        ? convertToArborDate(values.unemployment_start_date).getUtcDate()
        : null,
      unemployment_end_date: values.unemployment_end_date
        ? convertToArborDate(values.unemployment_end_date).getUtcDate()
        : null,
      patient_ssi: values.patient_ssi ? stripFormattedDollarValue(values.patient_ssi) : null,
      patient_ssi_income_period_id: values.patient_ssi_income_period_id
        ? values.patient_ssi_income_period_id
        : null,
      dependent_ssi: values.dependent_ssi ? stripFormattedDollarValue(values.dependent_ssi) : null,
      dependent_ssi_income_period_id: values.dependent_ssi_income_period_id
        ? values.dependent_ssi_income_period_id
        : null,
      spouse_ssi: values.spouse_ssi ? stripFormattedDollarValue(values.spouse_ssi) : null,
      spouse_ssi_income_period_id: values.spouse_ssi_income_period_id
        ? values.spouse_ssi_income_period_id
        : null,
      longterm_disability_income: values.longterm_disability_income
        ? stripFormattedDollarValue(values.longterm_disability_income)
        : null,
      longterm_disability_income_period_id: values.longterm_disability_income_period_id
        ? values.longterm_disability_income_period_id
        : null,
      longterm_disability_company: values.longterm_disability_company
        ? values.longterm_disability_company
        : null,
      retirement_income: values.retirement_income
        ? stripFormattedDollarValue(values.retirement_income)
        : null,
      retirement_income_period_id: values.retirement_income_period_id
        ? values.retirement_income_period_id
        : null,
      family_size: values.family_size,
      number_of_dependents: values.number_of_dependents,
      has_income: values.has_income ? 1 : 0,
      income_year: values.income_year,
      is_verified: 1, // If in UI, consider verified
      verified_dt: convertToArborDate(new Date()).getUtcDatetime(),
      documents: uploadedDocuments.map(doc => ({
        uuid: doc.uuid,
        file_name: doc.filename,
        labels: doc.labels,
        tags: doc.taskIds.map(id => ({
          tag_type_id: tagType.value,
          resource_id: id,
        })),
        noteText: doc.note,
      })),
    };

    return editIncome(payload).then(() => {
      fetchIncomes(values.patient_id).then(() => {
        cancel(payload.is_verified);
      });

      if (uploadedDocuments.length) {
        getDocuments({
          patient_id: values.patient_id,
          fetchFromId: Math.max(...existingDocuments.map(d => d.id)),
        }).then(result => {
          addNewDocument(result.data.documents);
        });
      }
    });
  }

  // Wrapped File Upload
  handleFileUploadCancel() {
    this.setState({ pendingDocument: undefined });
  }

  handleFileUploadSubmit() {
    this.setState({ uploadProcessing: true });
    const { pendingDocument } = this.state;
    const {
      selectedCustomerId,
      selectedPatientId,
      fileFormName,
      fileFormTypes,
      fileFormNote,
      income,
    } = this.props;
    // const tagType = noteTagTypes.find((tag) => tag.label === taskType);

    if (pendingDocument) {
      uploadFile(
        pendingDocument.file,
        `${pendingDocument.fileName}.${pendingDocument.fileExtension}`,
        selectedCustomerId,
        selectedPatientId,
      )
        .then(result => {
          this.setState(prevState => ({
            uploadedDocuments: [
              ...prevState.uploadedDocuments,
              {
                uuid: result.uuid,
                taskTypeId: tagType.value,
                taskIds: [income.id],
                filename: `${fileFormName}.${pendingDocument.fileExtension}`,
                labels: fileFormTypes.split(','),
                note: fileFormNote,
              },
            ],
            pendingDocument: undefined,
            uploadProcessing: false,
          }));
        })
        .catch(() => {
          this.setState({
            fileUploadErrorMessage: 'File could not be uploaded. Try again.',
          });
        });
    }
  }

  fileSelected(event) {
    if (event && event.length > 0) {
      const selectedFile = event[0];
      const { filePrefix, fileExtension } = getFileExtension(selectedFile.name);
      if (acceptedFileExtensions.includes(fileExtension.toLowerCase())) {
        const { changeField, fileUploadFormId } = this.props;
        changeField(fileUploadFormId, UPLOAD_DOC_FILENAME_FIELD, filePrefix);

        this.setState({
          pendingDocument: {
            fileName: filePrefix,
            fileExtension,
            file: selectedFile,
          },
        });
      }
    }
  }

  handleSubmit(values) {
    const { pendingDocument } = this.state;
    if (pendingDocument) {
      const lostData = [];

      if (pendingDocument) {
        lostData.push(`Pending: ${pendingDocument.fileName}.${pendingDocument.fileExtension}`);
      }

      this.setState({
        pendingDocumentLoss: true,
        lostData,
        dialogCancel: () => {
          this.setState({ pendingDocumentLoss: false });
        },
        dialogContinue: () => {
          this.finalizeSubmit(values);
        },
      });
    } else {
      this.finalizeSubmit(values);
    }
  }

  handleCancel() {
    const { uploadedDocuments, pendingDocument } = this.state;
    if ((uploadedDocuments && uploadedDocuments.length > 0) || pendingDocument) {
      const lostData = uploadedDocuments.map(ud => `Uploaded: ${ud.filename}`) || [];

      if (pendingDocument) {
        lostData.push(`Pending: ${pendingDocument.fileName}.${pendingDocument.fileExtension}`);
      }

      this.setState({
        pendingDocumentLoss: true,
        lostData,
        dialogCancel: () => {
          this.setState({ pendingDocumentLoss: false });
        },
        dialogContinue: () => {
          this.finalizeCancel();
        },
      });
    } else {
      this.finalizeCancel();
    }
  }

  finalizeCancel() {
    const { cancel } = this.props;
    cancel();
  }

  // - - - - - - - - - - -

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      classes,
      incomeTimePeriods,
      incomeYears,
      theme,
      fileUploadFormId,
      defaultDocumentLabels,
    } = this.props;

    const {
      pendingDocument,
      fileUploadErrorMessage,
      uploadProcessing,
      uploadedDocuments,
      pendingDocumentLoss,
      lostData,
      dialogCancel,
      dialogContinue,
    } = this.state;

    const uploadProps = {
      pendingDocument,
      fileUploadErrorMessage,
      uploadProcessing,
      uploadedDocuments,
      pendingDocumentLoss,
      classes,
      fileUploadFormId,
      defaultDocumentLabels,
      handleFileUploadSubmit: this.handleFileUploadSubmit,
      handleFileUploadCancel: this.handleFileUploadCancel,
      fileSelected: this.fileSelected,
    };

    const documentConfirmationDialogProps = {
      lostData,
      dialogCancel,
      dialogContinue,
      pendingDocumentLoss,
    };

    this.validateUniqueIncomeYear = validateUniqueIncomeYear(
      Object.keys(incomeYears).map(key => incomeYears[key].income_year),
    );

    let mappedIncomeTimePeriods = [];
    if (incomeTimePeriods && incomeTimePeriods.length > 0) {
      mappedIncomeTimePeriods = incomeTimePeriods.map(incomeTimePeriod => ({
        ...incomeTimePeriod,
        value: incomeTimePeriod.id,
        label: incomeTimePeriod.time_period,
      }));
    }

    const { noIncome, povertyModalOpen } = this.state;

    return (
      <Grid
        container
        justifyContent="center"
        spacing={7}
        className={(classes.formRowContainer, classes.formContainer)}
      >
        <Grid item xs={12}>
          <DocumentConfirmationDialog {...documentConfirmationDialogProps} />
          <form onSubmit={handleSubmit(this.handleSubmit)} autoComplete="off">
            <Grid container alignItems="flex-end">
              <Grid item xs={3} className={classes.addFormHeader}>
                <Field
                  name="income_year"
                  label="Income Year *"
                  component={renderTextField}
                  validate={[required, validateNumber, validateYear]}
                  className={classes.editFieldValue}
                  id="incomeYearFieldEdit"
                />
              </Grid>

              <Grid item xs={9} className={classes.addFormHeader}>
                <Field
                  name="is_no_income"
                  label="No Income"
                  component={renderCheckboxHorizontal}
                  id="noIncomeFieldEdit"
                  onChange={this.setNoIncome}
                />
              </Grid>
            </Grid>
            <div className={classes.noIncomeBackdropWrapper}>
              <Backdrop open={noIncome} className={classes.noIncomeBackdrop} />
              <Grid container alignItems="flex-end">
                <Grid item xs={3}>
                  <Field
                    name="taxable_income"
                    label="Total Income From Wages *"
                    component={renderMoneyTextField}
                    validate={noIncome ? [validateDollarValue] : [required, validateDollarValue]}
                    className={classes.editFieldValue}
                    id="taxableIncomeFieldEdit"
                    onChange={e => this.updateTotalSumIncome(e.target)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="gross_business_income"
                    label="Gross Business Income (Schedule C)"
                    component={renderYesNoDropdown}
                    className={classes.editFieldValue}
                    id="grossBusinessIncomeFieldEdit"
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="transcript_tax_form_completed"
                    label="4506-T Completed"
                    component={renderYesNoDropdown}
                    className={classes.editFieldValue}
                    id="transcriptTaxFormCompletedFieldEdit"
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="extension_filed"
                    label="Extension Filed"
                    component={renderYesNoDropdown}
                    className={classes.editFieldValue}
                    id="extensionFiledFieldEdit"
                  />
                </Grid>
              </Grid>
            </div>
            <Divider light />
            <SmallSpacer />
            <Grid container alignItems="flex-end">
              <Grid item xs={12}>
                <SubHeader name="Unemployment" />
                <SmallSpacer />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="unemployment_income"
                  label="Unemployment Income (Patient or Spouse)"
                  component={renderMoneyTextField}
                  validate={[validateDollarValue]}
                  className={classes.editFieldValue}
                  id="unemploymentIncomeFieldEdit"
                  onChange={e => this.updateTotalSumIncome(e.target)}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="unemployment_income_period_id"
                  label="Per Period"
                  component={renderDropdown}
                  fields={mappedIncomeTimePeriods}
                  validate={[validateNumber]}
                  className={classes.editFieldValue}
                  id="unemploymentIncomePeriodIdFieldEdit"
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="unemployment_start_date"
                  label="Benefit Start Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                  className={classes.editFieldValue}
                  id="unemploymentStartDateFieldEdit"
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="unemployment_end_date"
                  label="Benefit End Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                  className={classes.editFieldValue}
                  id="unemploymentEndDateFieldEdit"
                />
              </Grid>
            </Grid>
            <Divider light />
            <SmallSpacer />
            <Grid container alignItems="flex-end">
              <Grid item xs={12}>
                <SubHeader name="Retirement" />
                <SmallSpacer />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="pension_401k_retirement_income"
                  label="Pension/401K/Retirement Income"
                  component={renderMoneyTextField}
                  validate={[validateDollarValue]}
                  id="pension401kFieldEdit"
                  onChange={e => this.updateTotalSumIncome(e.target)}
                />
              </Grid>
            </Grid>
            <Divider light />
            <SmallSpacer />
            <Grid container alignItems="flex-end">
              <Grid item xs={12}>
                <SubHeader name="Social Security" />
                <SmallSpacer />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="patient_ssi"
                  label="Patient Social Security Income"
                  component={renderMoneyTextField}
                  validate={[validateDollarValue]}
                  className={classes.editFieldValue}
                  id="patientSocialSecurityIncomeFieldEdit"
                  onChange={e => this.updateTotalSumIncome(e.target)}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="patient_ssi_income_period_id"
                  label="Per Period"
                  component={renderDropdown}
                  fields={mappedIncomeTimePeriods}
                  validate={[validateNumber]}
                  className={classes.editFieldValue}
                  id="patientSSIIncomePeriodFieldEdit"
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="dependent_ssi"
                  label="Dependent Social Security Income"
                  component={renderMoneyTextField}
                  validate={[validateDollarValue]}
                  className={classes.editFieldValue}
                  id="dependentSocialSecurityIncomeFieldEdit"
                  onChange={e => this.updateTotalSumIncome(e.target)}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="dependent_ssi_income_period_id"
                  label="Per Period"
                  component={renderDropdown}
                  fields={mappedIncomeTimePeriods}
                  validate={[validateNumber]}
                  className={classes.editFieldValue}
                  id="dependentSSIIncomePeriodFieldEdit"
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="spouse_ssi"
                  label="Spouse Social Security Income"
                  component={renderMoneyTextField}
                  validate={[validateDollarValue]}
                  className={classes.editFieldValue}
                  id="spouseSSIFieldEdit"
                  onChange={e => this.updateTotalSumIncome(e.target)}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="spouse_ssi_income_period_id"
                  label="Per Period"
                  component={renderDropdown}
                  fields={mappedIncomeTimePeriods}
                  validate={[validateNumber]}
                  className={classes.editFieldValue}
                  id="spouseSSIIncomePeriodFieldEdit"
                />
              </Grid>
            </Grid>
            <Divider light className={classes.divider} />
            <SmallSpacer />
            <Grid container alignItems="flex-end">
              <Grid item xs={12}>
                <SubHeader name="Long Term Disability" />
                <SmallSpacer />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="longterm_disability_company"
                  label="Insurer"
                  component={renderTextField}
                  validate={[]}
                  className={classes.editFieldValue}
                  id="longtermDisabilityCompanyFieldEdit"
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="longterm_disability_income"
                  label="Benefit"
                  component={renderMoneyTextField}
                  validate={[validateDollarValue]}
                  className={classes.editFieldValue}
                  id="longtermDisabilityIncomeFieldEdit"
                  onChange={e => this.updateTotalSumIncome(e.target)}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="longterm_disability_income_period_id"
                  label="Per Period"
                  component={renderDropdown}
                  fields={mappedIncomeTimePeriods}
                  validate={[validateNumber]}
                  className={classes.editFieldValue}
                  id="longtermDisabilityIncomePeriodFieldEdit"
                />
              </Grid>
            </Grid>
            <Divider light className={classes.divider} />
            <SmallSpacer />
            <Grid container alignItems="flex-end">
              <Grid item xs={12}>
                <SubHeader name="Family" />
                <SmallSpacer />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="family_size"
                  label="Family Size *"
                  component={renderTextField}
                  validate={[required, validateNumber]}
                  className={classes.editFieldValue}
                  id="familySizeFieldEdit"
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="number_of_dependents"
                  label="Dependents"
                  component={renderTextField}
                  validate={[validateNumber]}
                  className={classes.editFieldValue}
                  id="numberOfDependentsFieldEdit"
                />
              </Grid>
            </Grid>
            <Divider light />
            <Grid container alignItems="flex-end">
              <Grid item xs={12}>
                <SubHeader name="Total" />
                <SmallSpacer />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="total_sum_income"
                  label="Total Income"
                  component={renderMoneyTextField}
                  id="totalSumIncome"
                  disabled
                />
              </Grid>
              <Grid item xs={3} onClick={this.handlePovertyModal}>
                <div className={classes.povertyInfoWrapper}>
                  <InfoIcon color={theme.palette.primary.formLabel} />
                  <p className={classes.povertyInfoLabel}>Federal Poverty Levels</p>
                </div>
              </Grid>
            </Grid>
            <Divider />
            <WrappedFileUpload {...uploadProps} />
            <SmallSpacer />
            <ConfirmationPanel
              handleCancel={this.handleCancel}
              disableSubmit={
                submitting || (pristine && !pendingDocument && !uploadedDocuments.length)
              }
            />
          </form>
          <Modal open={povertyModalOpen} onClose={this.handlePovertyModal}>
            <div style={getModalStyle()} className={classes.Modal}>
              <FederalPovertyTable />
            </div>
          </Modal>
        </Grid>
      </Grid>
    );
  }
}

function handleFormName(taskType, id) {
  const fileFormId = `${UPLOAD_DOCUMENT_FORM}-${taskType}-${id}`;
  return fileFormId;
}

function mapStateToProps(state, props) {
  const { patient, uploadedDocuments } = state;
  const { income } = props;
  const fileUploadFormId = handleFormName(tagType.value, props.income.id);
  const selector = formValueSelector(fileUploadFormId);
  const fileFormName = selector(state, UPLOAD_DOC_FILENAME_FIELD);
  const fileFormTypes = selector(state, UPLOAD_DOC_FILETYPE_FIELD);
  const fileFormNote = selector(state, UPLOAD_DOC_FILENOTE_FIELD);
  return {
    form: EDIT_INCOME_FORM,
    patient,
    enableReinitialize: true,
    incomeTimePeriods: state.lookups.incomeTimePeriods,
    incomeYears: state.financials.income_years,
    initialValues: {
      id: income.id,
      patient_id: income.patient_id,
      pension_401k_retirement_income: income.pension_401k_retirement_income
        ? income.pension_401k_retirement_income
        : null,
      taxable_income: income.taxable_income,
      is_no_income: Boolean(income.is_no_income),
      gross_business_income: income.gross_business_income,
      transcript_tax_form_completed: income.transcript_tax_form_completed,
      extension_filed: income.extension_filed,
      unemployment_income: income.unemployment_income ? income.unemployment_income : null,
      unemployment_income_period_id: income.unemployment_income_period_id
        ? income.unemployment_income_period_id
        : null,
      unemployment_start_date: income.unemployment_start_date
        ? moment.utc(income.unemployment_start_date)
        : null,
      unemployment_end_date: income.unemployment_end_date
        ? moment.utc(income.unemployment_end_date)
        : null,
      patient_ssi: income.patient_ssi ? income.patient_ssi : null,
      patient_ssi_income_period_id: income.patient_ssi_income_period_id
        ? income.patient_ssi_income_period_id
        : null,
      dependent_ssi: income.dependent_ssi ? income.dependent_ssi : null,
      dependent_ssi_income_period_id: income.dependent_ssi_income_period_id
        ? income.dependent_ssi_income_period_id
        : null,
      spouse_ssi: income.spouse_ssi ? income.spouse_ssi : null,
      spouse_ssi_income_period_id: income.spouse_ssi_income_period_id
        ? income.spouse_ssi_income_period_id
        : null,
      longterm_disability_company: income.longterm_disability_company
        ? income.longterm_disability_company
        : null,
      longterm_disability_income: income.longterm_disability_income
        ? income.longterm_disability_income
        : null,
      longterm_disability_income_period_id: income.longterm_disability_income_period_id
        ? income.longterm_disability_income_period_id
        : null,
      retirement_income: income.retirement_income ? income.retirement_income : null,
      retirement_income_period_id: income.retirement_income_period_id
        ? income.retirement_income_period_id
        : null,
      family_size: income.family_size,
      number_of_dependents: income.number_of_dependents,
      has_income: income.has_income ? 1 : 0,
      income_year: income.income_year,
      is_verified: income.is_verified ? income.is_verified : null,
      verified_dt: income.is_verified
        ? convertToArborDate(income.verified_dt, true).getCustomerDatetime()
        : null,
      verified_by: income.is_verified ? 1 : null,
      total_sum_income:
        income.pension_401k_retirement_income +
        income.taxable_income +
        income.unemployment_income +
        income.patient_ssi +
        income.dependent_ssi +
        income.spouse_ssi +
        income.longterm_disability_income,
    },
    defaultDocumentLabels: state.lookups.defaultDocumentLabels,
    fileUploadFormId,
    fileFormName,
    fileFormTypes,
    fileFormNote,
    selectedCustomerId: state.filters.selectedCustomerId,
    selectedPatientId: income.patient_id,
    existingDocuments: uploadedDocuments.documents,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      editIncome,
      fetchExistingIncomeYears,
      fetchIncomes,
      changeField: (formName, fieldName, value) => dispatch(_change(formName, fieldName, value)),
      addNewDocument,
    },
    dispatch,
  );
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(reduxForm({ form: EDIT_INCOME_FORM })(EditIncome));

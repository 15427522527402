import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: any) => {
  return {
    footer: {
      position: 'fixed',
      left: 0,
      bottom: 0,
      width: '100%',
      backgroundColor: theme.palette.primary.trellisLightBlue,
      color: 'white',
      textAlign: 'right',
      zIndex: 1,
      padding: theme.spacing(1),
      paddingRight: theme.spacing(4),
    },
    link: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(3),
      color: theme.palette.primary.trellisBlue,
    },
  };
});

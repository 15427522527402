import React from 'react';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Switch, Route } from 'react-router-dom';
import Shipping from 'containers/shipping/shipping';
import { ShippingViewStyles } from './index.styles';

type IShippingProps = WithStyles<typeof ShippingViewStyles>

const ShippingView = (props: IShippingProps): JSX.Element => {
  const { classes } = props;
  return (
    <div className={classes.shippingContent}>
      <Switch>
        <Route component={Shipping} />
      </Switch>
    </div>
  );
};

export default withStyles(ShippingViewStyles)(ShippingView);

import {
  TASK_LIFECYCLE_ACTIONS,
  LINK_SCHEDULE_OUTREACH_THERAPIES,
  UPDATE_SCHEDULE_DRUG_OUTCOME,
  UPDATE_TASK_PROPERTIES,
  SELECTED_PATIENT_ID,
  ADD_SCHEDULE_DRUG_OUTCOME,
} from 'constants/index';
import { updateTaskListWithProperties } from 'services/utils/reducer-service';

const initialState = [];

// TODO: make this part of tasks reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SELECTED_PATIENT_ID:
      return initialState;
    case TASK_LIFECYCLE_ACTIONS.FETCH.SCHEDULE_OUTREACH:
      if (payload.data) {
        return payload.data.tasks;
      }
      return state;
    case TASK_LIFECYCLE_ACTIONS.EDIT.SCHEDULE_OUTREACH: {
      if (payload.data) {
        const updatedScheduleOutreachTasks = payload.data.updated_task;
        return state.map(scheduleOutreach => {
          const task = updatedScheduleOutreachTasks.find(it => it.id === scheduleOutreach.id);
          if (task) {
            return { ...scheduleOutreach, ...task };
          }
          return scheduleOutreach;
        });
      }
      return state;
    }
    case LINK_SCHEDULE_OUTREACH_THERAPIES: {
      if (payload.data) {
        const updatedLinkedResource = payload.data.linked_resources[0];
        const updatedSoIndex = state.findIndex(
          so => so.id === updatedLinkedResource.schedule_outreach_id,
        );
        if (updatedSoIndex > -1) {
          const updatedDrugIndex = state[updatedSoIndex].drugs.findIndex(
            drug => drug.ndc === updatedLinkedResource.ndc,
          );
          return state.map(so => {
            if (so.id === state[updatedSoIndex].id) {
              return {
                ...so,
                drugs: so.drugs.map(drug => {
                  if (drug.id === state[updatedSoIndex].drugs[updatedDrugIndex].id) {
                    return {
                      ...drug,
                      therapy_id: updatedLinkedResource.therapy_id,
                    };
                  }
                  return drug;
                }),
              };
            }
            return so;
          });
        }
      }
      return state;
    }
    case ADD_SCHEDULE_DRUG_OUTCOME: {
      if (payload.data) {
        const { data } = payload;
        const updatedSOIds = data.map(
          scheduleDrugOutcome => scheduleDrugOutcome.schedule_outreach_id,
        );
        const updatedSOIdsSet = new Set(updatedSOIds);
        if (updatedSOIdsSet.size > 0) {
          return state.map(so => {
            if (updatedSOIdsSet.has(so.id)) {
              // This scheduled drug outcome needs to be updated with the payload drug details
              const updatedSoIndex = state.findIndex(stateSO => stateSO.id === so.id);
              if (updatedSoIndex !== -1) {
                return {
                  ...so,
                  drugs: [...so.drugs, ...data],
                };
              }
              return so;
            }
            return so;
          });
        }
      }
      return state;
    }
    case UPDATE_SCHEDULE_DRUG_OUTCOME: {
      if (payload.data) {
        const { data } = payload;
        const updatedSOIds = data.map(
          scheduleDrugOutcome => scheduleDrugOutcome.schedule_outreach_id,
        );
        const updatedSOIdsSet = new Set(updatedSOIds);
        if (updatedSOIdsSet.size > 0) {
          return state.map(so => {
            if (updatedSOIdsSet.has(so.id)) {
              // This scheduled drug outcome needs to be updated with the payload drug details
              const updatedSoIndex = state.findIndex(stateSO => stateSO.id === so.id);
              if (updatedSoIndex !== -1) {
                return {
                  ...so,
                  drugs: so.drugs.map(soDrug => {
                    const updatedDrugOutcome = data.find(
                      payloadDrug => payloadDrug.id === soDrug.id,
                    );
                    if (updatedDrugOutcome) {
                      // This drug should be updated with the payload details
                      const updatedDrugIndex = state[updatedSoIndex].drugs.findIndex(
                        stateDrug => soDrug.id === stateDrug.id,
                      );
                      const prevDrugOutcome = state[updatedSoIndex].drugs[updatedDrugIndex];
                      if (prevDrugOutcome && soDrug.id === prevDrugOutcome.id) {
                        return {
                          ...prevDrugOutcome,
                          ...updatedDrugOutcome,
                        };
                      }
                      return soDrug;
                    }
                    return soDrug;
                  }),
                };
              }
              return so;
            }
            return so;
          });
        }
      }
      return state;
    }
    case UPDATE_TASK_PROPERTIES: {
      const { taskType, taskId, properties } = payload || {};
      if (taskType && taskId && properties) {
        return updateTaskListWithProperties(state, taskType, taskId, properties);
      }
      return state;
    }
    default:
      return state;
  }
};

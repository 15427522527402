import { Button, Card, Grid, ListItem, Typography, Tooltip, ListSubheader } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import FormHeader from 'components/form/header/header';
import React from 'react';
import classNames from 'classnames';
import { styles } from './reconciliation-card-styles';

export const ReconciliationCard = props => {
  const {
    classes,
    subForms,
    displaySubForm,
    header,
    oldCol,
    newCol,
    index,
    total,
    rejectHandler,
    sourceName,
    isLoading,
    rejectTooltip = '',
    rejectVariant = 'contained',
    rejectColor = 'primary',
  } = props;

  const countStr = `${index}/${total} changes to review`;

  const renderRow = row =>
    row.isSubHeader ? (
      <ListSubheader color="inherit" disableSticky className={classes.subHeader}>
        <Typography variant="subtitle2">{`${row.label}:`}</Typography>
      </ListSubheader>
    ) : (
      !row.hidden && (
        <ListItem>
          {row.label && (
            <Typography variant="body2" className={classes.label}>
              {`${row.label}:`}
            </Typography>
          )}
          <Typography
            variant="body2"
            className={classNames({ [classes.highlight]: row.highlight })}
          >
            {row.value}
          </Typography>
        </ListItem>
      )
    );
  return (
    <>
      <Card className={classes.reconciliationCard}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} className={classes.reconciliationCardHeaderContainer}>
            <FormHeader header={header} withMargin={false} />
            <Typography>{countStr}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.contentContainer}>
            <Grid container>
              <Grid item xs={6}>
                <ListItem>
                  <Typography variant="body1" className={classes.headerText}>
                    Existing Information
                  </Typography>
                </ListItem>
                {oldCol.map(row => renderRow(row))}
              </Grid>
              <Grid item xs={6}>
                <ListItem>
                  <Typography component="span" key="new" className={classes.tagContainer}>
                    New
                  </Typography>
                  <Typography variant="body1" className={classes.headerText}>
                    {sourceName ? `Information from ${sourceName}` : 'Information'}
                  </Typography>
                </ListItem>
                {newCol.map(row => renderRow(row))}
              </Grid>
            </Grid>
          </Grid>
          {Array.isArray(subForms) &&
            subForms.filter(f => f.display === displaySubForm).map(f => <>{f.form}</>)}
          <Grid item xs={12}>
            <Grid
              container
              alignItems="center"
              className={classes.reconciliationCardFooterContainer}
            >
              <Grid item xs={12}>
                {Array.isArray(subForms) &&
                  subForms.map(form => (
                    <Tooltip arrow title={form.toolTip || ''} key={`${form.display}-tooltip`}>
                      <Button
                        data-qa-id={`${form.display}-button`}
                        className={classes.reconcileBtn}
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={isLoading || form.display === displaySubForm}
                        onClick={() => form.buttonHandler(form.display, index)}
                      >
                        {form.button}
                      </Button>
                    </Tooltip>
                  ))}
                <Tooltip arrow title={rejectTooltip}>
                  <Button
                    data-qa-id="reject-changes-button"
                    className={classes.reconcileBtn}
                    variant={rejectVariant}
                    color={rejectColor}
                    size="small"
                    disabled={isLoading}
                    onClick={rejectHandler}
                  >
                    Reject Changes
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(ReconciliationCard);

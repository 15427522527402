import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { changeCustomer } from 'services/login/index';
import { fetchCustomers, selectCustomer } from './actions/action-filters';

class CustomerCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    const {
      fetchCustomers, // eslint-disable-line
      selectCustomer, // eslint-disable-line
      location,
    } = this.props;
    fetchCustomers().then(res => {
      const availableCustomers = res?.payload?.data?.customers;
      if (availableCustomers === undefined) {
        throw new Error('Unable to load customers. Customer check failed.');
      }
      const selectedCustomerId = (availableCustomers.find(it => it.selected) || {}).id;
      const matcher = location.pathname.match('/.*customers/(\\d+).*/');
      const desiredCustomerId =
        matcher && matcher.length > 0 ? Number(matcher[1]) : selectedCustomerId;
      if (selectedCustomerId !== desiredCustomerId) {
        changeCustomer(desiredCustomerId)
          .then(() => selectCustomer(desiredCustomerId))
          .then(() => {
            this.setState({ loaded: true });
          });
      } else {
        this.setState({ loaded: true });
      }
    });
  }

  render() {
    const { loaded } = this.state;
    const { children } = this.props;
    if (!loaded) {
      return <div>Loading ...</div>;
    }
    return children;
  }
}

export default compose(
  withRouter,
  connect(null, {
    fetchCustomers,
    selectCustomer,
  }),
)(CustomerCheck);

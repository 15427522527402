import React from 'react';
import { FDC } from 'constants/index';
import { FillDeliveryConfirmationStatus } from 'interfaces/enums/TaskStatuses/FillDeliveryConfirmationStatus';
import { ITask } from 'interfaces/redux/ITasks';
import PrintOrderForm from './print-order-form';

interface IDisplayOrderForm {
  data: ITask;
  patientId: number;
  setStatus?: React.Dispatch<string>;
}

const DisplayOrderForm = ({ data, patientId, setStatus }: IDisplayOrderForm) => {
  const displayPrintOrderForm = data?.status_id === FillDeliveryConfirmationStatus.Dispense;

  const displayForms = () => {
    if (displayPrintOrderForm) {
      return <PrintOrderForm data={data} patientId={patientId} setStatus={setStatus} />;
    }
    return <></>;
  };

  return <>{data?.taskType && data?.taskType === FDC && displayForms()}</>;
};

export default DisplayOrderForm;

import { Button, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { LinkIcon } from 'components/icons/icons';
import React, { Component } from 'react';
import { goToAnchor } from 'utils/react-scrollable-anchor';
import { withRouter } from 'react-router';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { taskAbbreviationMap } from 'constants/index';
import { getChecklistOrTherapiesTaskUrl } from 'helpers/router';
import { push } from 'connected-react-router';
import { convertMapToList } from 'reducers/helper-reducer';
import { styles } from './task-detail-styles';

// eslint-disable-next-line no-shadow
function handleRedirect(e, link, { tasks, push }) {
  e.stopPropagation();
  const { meta, targetResourceId, targetResourceType } = link;
  const targetTaskAbbreviation = taskAbbreviationMap[targetResourceType];
  const { therapy_id: therapyId, patient_id: patientId } = meta;
  const url = getChecklistOrTherapiesTaskUrl(
    patientId,
    therapyId,
    targetTaskAbbreviation,
    targetResourceId,
    tasks,
  );
  push(url);
  if (targetResourceType === 'therapies') {
    setTimeout(goToAnchor(`THERAPY_${therapyId}`));
  } else {
    setTimeout(goToAnchor(`${targetTaskAbbreviation}_${targetResourceId}`));
  }
}

class TaskLinks extends Component {
  generateTaskName(link) {
    const { therapyList } = this.props;
    const { targetResourceType, meta } = link;
    const { therapy_id: therapyId } = meta;
    if (!therapyList[therapyId]) {
      return '';
    }
    const drugName = therapyList[therapyId].drug_name;
    const taskType = taskAbbreviationMap[targetResourceType];
    if (taskType) {
      return `${drugName} ${taskAbbreviationMap[targetResourceType]}`;
    }
    return drugName;
  }

  render() {
    const { links, isTherapy, classes } = this.props;
    const btnVariant = isTherapy ? 'text' : 'outlined';
    return (
      <Grid container>
        {links &&
          links
            .filter(link => !link.meta || !link.meta.cause)
            .map(link => (
              <Grid item key={link.id} align="right">
                <Button
                  className={classes.linkedButton}
                  onClick={e => handleRedirect(e, link, this.props)}
                  color="primary"
                  variant={btnVariant}
                >
                  <LinkIcon />
                  <span style={{ marginLeft: 10 }}>{this.generateTaskName(link)}</span>
                </Button>
              </Grid>
            ))}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const { therapies, tasks } = state;
  return {
    therapyList: therapies.data,
    tasks: convertMapToList(tasks.data),
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    push,
  }),
  withRouter,
)(TaskLinks);

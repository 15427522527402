import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import React, {
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
  forwardRef,
  useEffect,
} from 'react';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import {
  IStep,
  IPatientMergeModalRef,
  IPatientMergeModalProps,
} from 'components/patient-merge-modal/interfaces';
import { push } from 'connected-react-router';
import { getTaskUrl } from 'helpers/router';
import { SelectForm } from 'components/patient-merge-modal/select-form-view/select-form';
import { useSelector } from 'react-redux';
import { setPrimary, setDuplicate, reset, setInputs } from 'actions/action-patient-merge';
import { useTheme } from '@mui/styles';
import { ConfirmView } from 'components/patient-merge-modal/confirm-view/confirm-view';
import { PatientMergeClient } from 'clients/patient-merge';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { selectPatientId } from 'actions/action-selections';
import { IState } from 'interfaces/redux/IState';
import CircularProgress from '@mui/material/CircularProgress';
import { SAVE_IN_PROGRESS_THROTTLE } from 'components/patient-merge-modal/constants';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { SelectPrimaryProfile } from './select-primary-profile/select-primary-profile';

export const steps: IStep[] = [
  {
    title: 'Select the Primary Profile',
    render: props => <SelectPrimaryProfile {...props} />,
    renderNextButtonText: () => 'Mark as Primary and Continue',
  },
  {
    title: 'Select the information to add to the Primary Profile',
    render: props => <SelectForm {...props} />,
    renderNextButtonText: () => 'Next',
  },
  {
    title: 'Verify Information and confirm changes',
    render: props => <ConfirmView {...props} />,
    renderNextButtonText: () => 'Merge',
  },
];

const PatientMergeModalCmp: ForwardRefRenderFunction<
  IPatientMergeModalRef,
  IPatientMergeModalProps
> = (props, ref) => {
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);
  const { profiles = [], mergeId, defaultInputs } = props;

  const inputs = useSelector((state: IState) => state.patientMerge.inputs);
  const patientId = useSelector((state: IState) => state.patient.id);

  const dispatch = useAppDispatch();

  const primaryProfileId = useSelector((state: IState) => state.patientMerge.primary);
  const selectedProfileId = useSelector((state: IState) => state.patientMerge.duplicate);

  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);

  useEffect(() => {
    if (!profiles.length) {
      return;
    }

    const defaultPrimary = profiles?.find(profile => profile.primary)?.patientId || null;
    const defaultSelected = profiles?.find(profile => profile.duplicate)?.patientId || null;

    if (defaultPrimary && primaryProfileId !== defaultPrimary) {
      dispatch(setPrimary(defaultPrimary));
    }
    if (defaultSelected && defaultSelected !== selectedProfileId) {
      dispatch(setDuplicate(defaultSelected));
    }
  }, [profiles]);

  useEffect(() => {
    if (!inputs?.length) {
      dispatch(setInputs(defaultInputs));
    }
  }, [defaultInputs]);

  useImperativeHandle(ref, () => ({
    setOpen,
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveProgress = async () => {
    setLoading(true);
    try {
      await PatientMergeClient.savePatientMergeInputs(mergeId, inputs);
      if (selectedProfileId) {
        await PatientMergeClient.savePatientMergeDuplicate(mergeId, selectedProfileId);
      }
      if (primaryProfileId) {
        await PatientMergeClient.savePatientMergePrimary(mergeId, primaryProfileId);
      }
    } catch (err: any) {
      dispatch(notifyError(`Failed to save patient merge. ${err.message}`));
    } finally {
      setTimeout(() => setLoading(false), SAVE_IN_PROGRESS_THROTTLE);
    }
  };

  const handleMerge = async () => {
    setLoading(true);

    try {
      await PatientMergeClient.savePatientMergeInputs(mergeId, inputs);
      if (selectedProfileId) {
        await PatientMergeClient.savePatientMergeDuplicate(mergeId, selectedProfileId);
      }
      if (primaryProfileId) {
        await PatientMergeClient.savePatientMergePrimary(mergeId, primaryProfileId);
      }
      await PatientMergeClient.finishPatientMerge(mergeId);

      setOpen(false);
      dispatch(reset());
      dispatch(selectPatientId(primaryProfileId));
      const url = getTaskUrl(primaryProfileId, null, 'patients', null, false);

      dispatch(push(url));

      dispatch(notifySuccess('Profiles merged succesfully.'));
    } catch (err: any) {
      dispatch(notifyError(`Failed to finish patient merge. ${err.message}`));
    } finally {
      setTimeout(() => setLoading(false), SAVE_IN_PROGRESS_THROTTLE);
    }
  };

  const hasNext = step + 1 < steps.length;
  const hasPrevious = step > 0;
  const stepProps = {
    profiles,
    mergeId,
    onSectionChange: handleSaveProgress,
    goToNextStep: () => {
      handleNext();
    },
    setNextButtonEnabled: (enabled: boolean) => {
      setNextButtonEnabled(enabled);
    },
  };

  const handleNext = async () => {
    const nextStep = Math.min(step + 1, steps.length - 1);

    await handleSaveProgress();

    setNextButtonEnabled(false);
    setStep(nextStep);
  };

  const handlePrevious = () => {
    const previousStep = Math.max(step - 1, 0);
    setNextButtonEnabled(false);
    setStep(previousStep);
  };

  const renderNextButtonText = () => steps[step].renderNextButtonText();

  const currentStep = steps[step];

  return (
    <Dialog open={open} fullScreen sx={classes.dialog} onClose={handleClose}>
      <DialogTitle>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography variant="h6" align="left">
              {`Merge Duplicated Patient (${profiles.length})`}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" align="right">
              {`Step ${step + 1}/${steps.length}: ${currentStep.title}`}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>{currentStep.render(stepProps)}</DialogContent>
      <DialogActions>
        <Button onClick={handleSaveProgress} disabled={loading || !primaryProfileId}>
          {loading && <CircularProgress color="secondary" sx={classes.loading} size={13} />}
          {loading ? 'Saving...' : 'Save Draft'}
        </Button>
        <Button onClick={hasPrevious ? handlePrevious : handleClose} disabled={loading}>
          {hasPrevious ? 'Previous' : 'Cancel'}
        </Button>
        <Button
          onClick={hasNext ? handleNext : handleMerge}
          disabled={loading || !nextButtonEnabled}
          variant="contained"
          sx={classes.nextButton}
        >
          {renderNextButtonText()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const PatientMergeModal = forwardRef(PatientMergeModalCmp);

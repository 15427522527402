import { Typography, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { styles } from './display-styles';

const AbbreviatedList = props => {
  const {
    arr,
    primaryRenderComponent,
    noArrText,
    classes,
    changeCallbackPrimary = null,
    criteriaField,
    criteriaVal, // which criteria to bump a value to display first
    maxRowsToShow = 3,
    orientVertical = true,
    additionalTextToShow,
  } = props;
  let { secondaryRenderComponent, changeCallbackSecondary = null } = props;
  if (!secondaryRenderComponent) {
    secondaryRenderComponent = primaryRenderComponent;
  }
  if (!changeCallbackSecondary) {
    changeCallbackSecondary = changeCallbackPrimary;
  }
  const arrFilteredByCriteria = arr.filter(val => val[`${criteriaField}`] === criteriaVal);
  const arrFilteredByNotCriteria = arr.filter(val => val[`${criteriaField}`] !== criteriaVal);
  const gridSize = orientVertical ? 12 : null; // 12 being standard max grid size
  // eslint-disable-next-line
  const additionalToShowText = additionalTextToShow || `+ ${arr.length - (maxRowsToShow - 1)} more`; // -1 for the row the label takes up
  const hoverAdditionalToShowText = arr.slice(maxRowsToShow - 1).map(v => v.label);

  const getList = () => {
    if (!!arr && arr.length > 0) {
      if (arr && arr.length <= maxRowsToShow) {
        return (
          <>
            {arrFilteredByCriteria.map(val => (
              <Grid
                item
                xs={gridSize}
                key={`primary-component-${val.label}-${val.value}-abbreviated-list`}
              >
                {primaryRenderComponent(val)}
              </Grid>
            ))}
            {arrFilteredByNotCriteria.map(val => (
              <Grid
                item
                xs={gridSize}
                key={`secondary-component-${val.label}-${val.value}-abbreviated-list`}
              >
                {secondaryRenderComponent(val)}
              </Grid>
            ))}
          </>
        );
      }
      if (arrFilteredByCriteria.length > maxRowsToShow - 1) {
        return (
          <>
            {arrFilteredByCriteria.slice(0, maxRowsToShow - 1).map(val => (
              <Grid item xs={gridSize} key={`list_item_primary_${val}`}>
                {primaryRenderComponent(val)}
              </Grid>
            ))}
            <Grid item xs={gridSize}>
              {primaryRenderComponent({ label: additionalToShowText }, hoverAdditionalToShowText)}
            </Grid>
          </>
        );
      }
      return (
        <>
          {arrFilteredByCriteria.map(val => (
            <Grid item xs={gridSize} key={`list_item_primary_${val}`}>
              {primaryRenderComponent(val)}
            </Grid>
          ))}
          {arrFilteredByNotCriteria
            .slice(0, maxRowsToShow - 1 - arrFilteredByCriteria.length)
            .map(val => (
              <Grid item xs={gridSize} key={`list_item_secondary_${val}`}>
                {secondaryRenderComponent(val)}
              </Grid>
            ))}
          <Grid item xs={gridSize}>
            {secondaryRenderComponent({ label: additionalToShowText }, hoverAdditionalToShowText)}
          </Grid>
        </>
      );
    }
    return (
      <Grid item xs={gridSize} className={classes.pointer}>
        <Typography className={classes.abbreviatedListText}>{noArrText}</Typography>
      </Grid>
    );
  };
  return (
    <>
      {orientVertical ? (
        getList()
      ) : (
        <Grid container justifyContent="flex-start" alignItems="center">
          {getList()}
        </Grid>
      )}
    </>
  );
};

export default withStyles(styles, { withTheme: true })(AbbreviatedList);

const stripFormattedDollarValue = dollar =>
  Number(dollar.toString().replace(/,/g, '').replace(/\$/g, '').trim());

const stripFormattingPhoneNumber = phone =>
  Number(
    phone
      .toString()
      .replace(/"/g, '')
      .replace(/-/g, '')
      .replace(/'/g, '')
      .replace(/\(|\)/g, '')
      .replace(/\s/g, ''),
  );

/**
 * Checks whether input value is a valid finite number.
 * @param {*} param Mixed value to check if it is a number and finite value.
 * @returns {boolean} Returns true if input is a finite number, false otherwise.
 */
const isFiniteNumber = value =>
  value !== null &&
  value !== undefined &&
  !Number.isNaN(Number(value)) &&
  Number.isFinite(Number(value));

/**
 * Converts input param into a string which represent the param as a price in US dollars.
 * @param {*} param Mixed value which is the number to format into price in US dollars.
 * @returns {string|null} Returns a string price in US dollars if input is a valid finite number,
 * return null otherwise.
 */
const numberToDollars = param => {
  if (!isFiniteNumber(param)) {
    return null;
  }
  let number = Number(param);
  const hasDecimals = number % 1 !== 0;
  if (hasDecimals) {
    number = number.toFixed(2);
  }
  return '$'.concat(number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
};

/**
 * Converts input param into a string which represent the param as percentage.
 * @param {*} param Mixed value which is the number to format into percentage.
 * @returns {string} Returns a percentage string
 */
const numberToPercentage = param => {
  if (!isFiniteNumber(param)) {
    return '0.00%';
  }
  return `${(param * 100).toFixed(2)}%`;
};

const yesNoToText = x => (x === true || x === 1 ? 'Yes' : 'No');

const handleNullString = str => (str ? ` ${str}` : '');

const getYesNoHyphenValue = value => {
  if (value === undefined || value === null) {
    return '-';
  }
  return value ? 'Yes' : 'No';
};

const getMedSyncPreferenceText = value => {
  let medSyncText = '';
  switch (value) {
    case 1:
      medSyncText = 'Undecided';
      break;
    case 2:
      medSyncText = 'Opt-In';
      break;
    case 3:
      medSyncText = 'Opt-Out';
      break;
    default:
      medSyncText = '-';
      break;
  }
  return medSyncText;
};

const getFormattedProviderAddress = provider => provider.address;

export {
  stripFormattedDollarValue,
  stripFormattingPhoneNumber,
  isFiniteNumber,
  numberToDollars,
  numberToPercentage,
  yesNoToText,
  handleNullString,
  getYesNoHyphenValue,
  getFormattedProviderAddress,
  getMedSyncPreferenceText,
};

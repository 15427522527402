import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material';
import compose from 'recompose/compose';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { convertToArborDate } from 'models/time/arbor-date';
import {
  renderTextField,
  renderDropdown,
  renderNumberField,
  renderMultipleDropdown,
} from 'components/form/field/redux-field';
import { renderDatePicker, renderDateTimePicker } from 'components/form/datepicker/datetime-picker';
import {
  getStatusByStatusId,
  getReasonsByStatus,
  getInitialServiceGroupForReactSelect,
  followupDateChanged,
  addTaskOldValues,
} from 'services/utils/task-service';
import {
  getMedicalInsuranceArrForDropdown,
  getPbmInsuranceArrForDropdown,
  mapToIdNameInsuranceLabelValue,
} from 'services/utils/financials-service';
import { editPa, fetchTaskCounts } from 'actions/action-tasks';
import { workListChanged } from 'actions/action-patient';
import { ReactSelectForReduxSingle } from 'components/form/field/react-select';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { EDIT_PA_FORM } from '../../../constants';

import { styles } from '../task-detail-styles';
import {
  validateDate,
  validateDateTime,
  validateNumber,
  required,
} from '../../../components/form/validation/validation';

class EditPa extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleServiceGroupDropdown = this.handleServiceGroupDropdown.bind(this);
    this.state = { selectValue: props.taskStatus, serviceGroupDropdown: -1 };
  }

  handleSelect(e) {
    this.setState({
      selectValue: e.target.value,
    });
  }

  handleServiceGroupDropdown(e) {
    this.setState({
      serviceGroupDropdown: e.target.value,
    });
  }

  // Handles the submit functionality. Right now it sets some fields to null for simplicity's sake
  handleSubmit(values) {
    const {
      editPa, // eslint-disable-line
      task,
      cancel,
      workListChanged, //eslint-disable-line
      fetchTaskCounts, //eslint-disable-line
    } = this.props;

    const payload = {
      ...values,
      id: task.id,
      additional_reason: null,
      appealed_dt: convertToArborDate(values.appealed_dt).getUtcDatetime(),
      approved_dt: convertToArborDate(values.approved_dt).getUtcDatetime(),
      denied_date: convertToArborDate(values.denied_date).getUtcDate(),
      expiration_date: values.expiration_date
        ? convertToArborDate(values.expiration_date).getUtcDate()
        : null,
      canceled_complete_date: values.canceled_complete_date
        ? convertToArborDate(values.canceled_complete_date).getUtcDate()
        : null,
      time_out_complete_date: values.time_out_complete_date
        ? convertToArborDate(values.time_out_complete_date).getUtcDate()
        : null,
      not_required_complete_date: values.not_required_complete_date
        ? convertToArborDate(values.not_required_complete_date).getUtcDate()
        : null,
      followup_dt: convertToArborDate(values.followup_dt).getUtcDatetime(),
      last_checked_dt: values.last_checked_dt
        ? convertToArborDate(values.last_checked_dt).getUtcDatetime()
        : null,
      status_id: values.status_id,
      submitted_dt: values.submitted_dt
        ? convertToArborDate(values.submitted_dt).getUtcDatetime()
        : null,
      reference_number: values.reference_number,
      auth_number: values.auth_number,
      service_group_id: values.service_group_id.value,
      therapy_id: task.therapy_id,
      fill_cycle_number: values.fill_cycle_number,
      medical_insurances: values.medical_insurances,
      pbm_insurances: values.pbm_insurances,
    };
    return editPa(addTaskOldValues(payload, task)).then(() => {
      if (followupDateChanged(task.followup_dt, payload.followup_dt)) {
        workListChanged();
        fetchTaskCounts();
      }
      cancel();
    });
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      classes,
      cancel,
      task,
      statuses,
      serviceGroups,
      fillCycles,
      medicalInsurances,
      pbmInsurances,
    } = this.props;

    const { selectValue, serviceGroupDropdown } = this.state;
    const { therapy_id: therapyId } = task;

    return (
      <Grid
        container
        justifyContent="flex-start"
        className={(classes.formRowContainer, classes.formContainer)}
      >
        <Grid item xs={12}>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            className={classes.editFormContainer}
            autoComplete="off"
          >
            <Grid container className={classes.formRowContainer}>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="followup_dt"
                  label="Follow up Date *"
                  component={renderDatePicker}
                  validate={[validateDate, required]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="last_checked_dt"
                  label="Last Checked Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="service_group_id"
                  label="Service Group *"
                  validate={[required]}
                  component={ReactSelectForReduxSingle}
                  fields={serviceGroups.map(int => ({
                    label: int.display_name,
                    value: int.id,
                    isDisabled: !int.active,
                  }))}
                  state={serviceGroupDropdown}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="submitted_dt"
                  label="Submitted Date"
                  component={renderDateTimePicker}
                  validate={[validateDateTime]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field name="reference_number" label="Reference #" component={renderTextField} />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field name="auth_number" label="Authorization #" component={renderTextField} />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field name="number_of_fills" label="# of Fills" component={renderNumberField} />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="denied_date"
                  label="Denied Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="appealed_dt"
                  label="Appealed Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="approved_dt"
                  label="Approved Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="expiration_date"
                  label="Expiration Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="not_required_complete_date"
                  label="Not Required Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="canceled_complete_date"
                  label="Canceled Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="time_out_complete_date"
                  label="Time Out Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              {getReasonsByStatus(statuses, getStatusByStatusId(task.status_id, statuses))[0]
                .label && (
                <Grid item lg={8}>
                  <Field
                    name="status_id"
                    label={`Reason for ${
                      getReasonsByStatus(statuses, getStatusByStatusId(task.status_id, statuses))[0]
                        .status
                    } status`}
                    component={renderDropdown}
                    fields={getReasonsByStatus(
                      statuses,
                      getStatusByStatusId(task.status_id, statuses),
                    )}
                    state={selectValue}
                  />
                </Grid>
              )}
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="fill_cycle_number"
                  component={renderDropdown}
                  fields={
                    fillCycles[therapyId] &&
                    Object.keys(fillCycles[therapyId])
                      .map(k => fillCycles[therapyId][k])
                      .map(cycle => ({
                        label: `#${cycle.fill_cycle} NBD: ${convertToArborDate(
                          cycle.needsby_date,
                          true,
                        ).getUtcDate(true)}`,
                        value: cycle.fill_cycle,
                      }))
                  }
                  label="Fill cycle *"
                  validate={[required, validateNumber]}
                />
              </Grid>
              <Grid item xs={6} className={classes.fieldContainer}>
                <Field
                  name="medical_insurances"
                  label="Medical Insurances"
                  multiple
                  component={renderMultipleDropdown}
                  fields={medicalInsurances}
                />
              </Grid>
              <Grid item xs={6} className={classes.fieldContainer}>
                <Field
                  name="pbm_insurances"
                  label="PBM Insurances"
                  multiple
                  component={renderMultipleDropdown}
                  fields={pbmInsurances}
                />
              </Grid>
            </Grid>

            {/* Eighth Row */}
            <ConfirmationPanel
              cancelButtonName="edit_therapy_cancel_button"
              submitButtonName="edit_therapy_submit_button"
              handleCancel={cancel}
              disableSubmit={pristine || submitting}
            />
          </form>
        </Grid>
      </Grid>
    );
  }
}

function handleFormName(task) {
  if (!task) {
    return null;
  }
  const name = `${EDIT_PA_FORM}_${task.therapy_id}_${task.id}`;
  return name;
}

function mapStateToProps(state, props) {
  const name = handleFormName(props.task);
  const { fillCycles, financials } = state;
  const medicalInsuranceArr = getMedicalInsuranceArrForDropdown(state);
  const pbmInsuranceArr = getPbmInsuranceArrForDropdown(state);
  return {
    fillCycles,
    form: name,
    financials,
    medicalInsurances: medicalInsuranceArr.map(
      mapToIdNameInsuranceLabelValue('id', 'plan_name', 'insurance_type'),
    ),
    pbmInsurances: pbmInsuranceArr.map(
      mapToIdNameInsuranceLabelValue('id', 'pbm_name', 'insurance_type'),
    ),
    initialValues: {
      additional_reason: props.task.additional_reason ? props.task.additional_reason : '',
      appealed_dt: convertToArborDate(props.task.appealed_dt, true).getCustomerDate(true),
      approved_dt: convertToArborDate(props.task.approved_dt, true).getCustomerDate(true),
      denied_date: convertToArborDate(props.task.denied_date, true).getUtcDate(true),
      expiration_date: convertToArborDate(props.task.expiration_date, true).getUtcDate(true),
      followup_dt: convertToArborDate(props.task.followup_dt, true).getCustomerDate(true),
      last_checked_dt: convertToArborDate(props.task.last_checked_dt, true).getUtcDate(true),
      status_id: props.task.status_id,
      service_group_id: props.task.service_group_id
        ? getInitialServiceGroupForReactSelect(props.task.service_group_id, props.serviceGroups)
        : '',
      submitted_dt: convertToArborDate(props.task.submitted_dt, true).getCustomerDatetime(true),
      reference_number: props.task.reference_number ? props.task.reference_number : '',
      auth_number: props.task.auth_number ? props.task.auth_number : '',
      number_of_fills: props.task.number_of_fills ? props.task.number_of_fills : null,
      fill_cycle_number: props.task.fill_cycle_number || null,
      canceled_complete_date: convertToArborDate(
        props.task.canceled_complete_date,
        true,
      ).getUtcDate(true),
      not_required_complete_date: convertToArborDate(
        props.task.not_required_complete_date,
        true,
      ).getUtcDate(true),
      time_out_complete_date: convertToArborDate(
        props.task.time_out_complete_date,
        true,
      ).getUtcDate(true),
      medical_insurances: props.task.medical_insurances,
      pbm_insurances: props.task.pbm_insurances,
    },
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchTaskCounts,
    workListChanged,
    editPa,
  }),
)(reduxForm({})(EditPa));

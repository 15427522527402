import moment from 'moment';
import momenttz from 'moment-timezone';
import { Timezone } from './timezone';

export const dateFormat = 'YYYY-MM-DD';
export const dateFormatUI = 'MM/DD/YYYY';
export const datetimeFormat = 'YYYY-MM-DD HH:mm:ss';
export const datetimeFormatUI = 'MM/DD/YYYY hh:mm A';

/**
 * Convert a string or moment object to arbor date
 *
 * @param {(string | moment.Moment)} obj
 * @param {boolean} [isUtc=false]
 * @returns {ArborDate}
 */
export const convertToArborDate = (
  obj: string | moment.Moment | Date | null | undefined,
  isUtc = false,
): ArborDate => new ArborDate(obj, isUtc);

export const getCustomerCurrentArborDate = (): moment.Moment => {
  const timeInstance = Timezone.getInstance();
  return momenttz().tz(timeInstance.timezone);
};

/**
 * Class that handles date/datetime and timezone conversions
 *
 * @export
 * @class Date
 */
export class ArborDate {
  /**
   * store raw time data as moment object
   *
   * @type {moment.Moment}
   * @memberof ArborDate
   */
  dateObject: moment.Moment;

  /**
   * whether the date to parse is in GMT timezone
   *
   * @type {boolean}
   * @memberof ArborDate
   */
  isUtc: boolean;

  /**
   * Creates an instance of ArborDate.
   * @param {(string | moment.Moment)} time
   * @param {boolean} [isUtc=false]
   * @memberof ArborDate
   */
  constructor(time: string | moment.Moment | Date | null | undefined, isUtc: boolean) {
    if (time) {
      this.dateObject = isUtc ? moment(time) : momenttz.tz(time, this.getTimezone());
      this.isUtc = isUtc;
    } else {
      this.dateObject = moment('');
      this.isUtc = false;
    }
  }

  /**
   * Private helper method to get the local timezone
   *
   * @private
   * @returns {string}
   * @memberof ArborDate
   */
  private getTimezone(): string {
    const timeInstance = Timezone.getInstance();
    return timeInstance && timeInstance.timezone;
  }

  /**
   * Get date in UTC
   *
   * @param {boolean} [toUIFormat=false]
   * @returns {string}
   * @memberof ArborDate
   */
  getUtcDate(toUIFormat = false, customFormat: any = null): string | null {
    if (!this.dateObject.isValid()) {
      return null;
    }

    const format: string = customFormat || (toUIFormat ? dateFormatUI : dateFormat);

    if (this.isUtc) {
      return this.dateObject.utc().format(format);
    }

    return momenttz.tz(this.dateObject.format(), this.getTimezone()).utc().format(format);
  }

  /**
   * Get datetime in UTC
   *
   * @param {boolean} [toUIFormat=false]
   * @returns {string}
   * @memberof ArborDate
   */
  getUtcDatetime(toUIFormat = false): string | null {
    if (!this.dateObject.isValid()) {
      return null;
    }

    const format: string = toUIFormat ? datetimeFormatUI : datetimeFormat;

    if (this.isUtc) {
      return this.dateObject.format(format);
    }

    const offset = this.dateObject.utcOffset() * -1;
    return momenttz
      .tz(this.dateObject.format(), this.getTimezone())
      .utcOffset(offset)
      .format(format);
  }

  /**
   * Get date in local timezone
   *
   * @param {boolean} [toUIFormat=false]
   * @param {*} [customFormat=null]
   * @returns {string}
   * @memberof ArborDate
   */
  getCustomerDate(toUIFormat = false, customFormat: any = null): string | null {
    if (!this.dateObject.isValid()) {
      return null;
    }

    const format: string = customFormat || (toUIFormat ? dateFormatUI : dateFormat);

    if (this.isUtc) {
      return this.dateObject.tz(this.getTimezone()).format(format);
    }

    return this.dateObject.format(format);
  }

  /**
   * Get datetime in local timezone
   *
   * @param {boolean} [toUIFormat=false]
   * @returns {string}
   * @memberof ArborDate
   */
  getCustomerDatetime(toUIFormat = false, customFormat: any = null): string | null {
    if (!this.dateObject.isValid()) {
      return null;
    }

    const format: string = customFormat || (toUIFormat ? datetimeFormatUI : datetimeFormat);

    if (this.isUtc) {
      return this.dateObject.tz(this.getTimezone()).format(format);
    }

    return this.dateObject.format(format);
  }

  /**
   * Get the moment in local timezone
   *
   * @returns {moment.Moment}
   * @memberof Arbordate
   */
  getCustomerMoment(): moment.Moment | null {
    if (!this.dateObject.isValid()) {
      return null;
    }

    if (this.isUtc) {
      return this.dateObject.tz(this.getTimezone());
    }

    return this.dateObject;
  }

  /**
   * Get years count from given date to now
   *
   * @returns {Number}
   * @memberof ArborDate
   */
  getYearsFromNow(): number | null {
    return !this.dateObject.isValid()
      ? null
      : Math.abs(this.dateObject.diff(new Date(), 'years', false));
  }

  /**
   * Get the age in years, months or days. Returns null when date is invalid.
   *
   * @returns {string}
   * @memberof ArborDate
   */
  getCustomerAgeFormatted(yearOldText = 'yr', monthOldText = 'm', dayOldText = 'd'): string | null {
    if (!this.dateObject.isValid()) {
      return null;
    }
    const nowMomentObject = moment(new Date());
    const years = this.getYearsFromNow();
    if (Number(years) > 0) {
      return `${years} ${yearOldText}`;
    }
    const months = Math.abs(nowMomentObject.diff(this.dateObject, 'months', false));
    if (months > 0) {
      return `${months} ${monthOldText}`;
    }
    const days = Math.abs(nowMomentObject.diff(this.dateObject, 'days', false));
    return `${days} ${dayOldText}`;
  }

  /**
   * Check if the date is before the start of date today
   *
   * @returns {boolean}
   * @memberof ArborDate
   */
  isBeforeToday(): boolean {
    if (!this.dateObject.isValid()) {
      return false;
    }

    const now = momenttz.tz(this.getTimezone());
    return this.dateObject.isBefore(now.startOf('day'));
  }

  /**
   * Check if the datetime is after this moment
   *
   * @returns {boolean}
   * @memberof ArborDate
   */
  isAfterNow(): boolean {
    if (!this.dateObject.isValid()) {
      return false;
    }
    return this.dateObject.isAfter(moment.now());
  }

  /**
   * Add business days to the arbor date object
   *
   * @param {number} daysToAdd
   * @returns {ArborDate}
   * @memberof ArborDate
   */
  addBusinessDays(daysToAdd: number): ArborDate {
    return convertToArborDate(this.dateObject.businessAdd(daysToAdd));
  }

  /**
   * Add days to the arbor date object
   *
   * @param {number} daysToAdd
   * @returns {ArborDate}
   * @memberof ArborDate
   */
  addDays(daysToAdd: number): ArborDate {
    return convertToArborDate(this.dateObject.add(daysToAdd, 'days'), this.isUtc);
  }
}

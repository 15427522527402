import { Typography, Grid, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { fetchTaskCounts } from 'actions/action-tasks';
import { ToggleLeftDrawer } from 'actions/action-view';
import { RECENT_PATIENTS_REQUEST, SEARCH_FORM, SEARCH_SETTINGS } from 'constants/index';
import { formValueSelector, change } from 'redux-form';
import { clearWorkList } from 'actions/action-patient';
import AdvancedSearch from 'components/advanced-search';
import clasNames from 'classnames';
import { styles } from '../side-bar-styles';
import SearchList from './search-list';
import { CollapsibleSideBar } from '../collapsible-side-bar';

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => WrappedComponent => props => <WrappedComponent {...props} width="xs" />;

class SearchSideBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdvancedSearchActive: false,
    };

    this.toggleAdvancedSearch = this.toggleAdvancedSearch.bind(this);
  }

  /**
   * Using getDerivedStateFromProps since componentWillReceiveProps and componentWillUpdate
   * are deprecated and will be removed on React 17.
   * See: https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html#gradual-migration-path
   */
  static getDerivedStateFromProps(props) {
    const { searchStr } = props;
    const trimSearchStr = searchStr && searchStr.trim();
    if (searchStr && trimSearchStr.length) {
      return {
        isAdvancedSearchActive: false,
      };
    }
    return null;
  }

  componentDidMount() {
    const { onInitialize, isLeftDrawerOpen, onToggleDrawer } = this.props;
    let defaultToOpen = true;
    if (defaultToOpen && isLeftDrawerOpen) {
      onToggleDrawer();
      defaultToOpen = !defaultToOpen;
    }
    onInitialize();
  }

  toggleAdvancedSearch() {
    const { cleanExternalSearchForm, cleanPatientList } = this.props;
    const { isAdvancedSearchActive } = this.state;
    // clean external search form if advanced search is active
    if (!isAdvancedSearchActive === true) {
      cleanExternalSearchForm();
      cleanPatientList();
    }
    this.setState({
      isAdvancedSearchActive: !isAdvancedSearchActive,
    });
  }

  renderResults(trimSearchStr) {
    const { recentPatientIds, loadRecentPatients } = this.props;
    if (trimSearchStr && trimSearchStr.length >= SEARCH_SETTINGS.MIN_CHARS_TO_SEARCH) {
      const searchTerms = {
        name: trimSearchStr,
        sourcePatientId: trimSearchStr,
        phone: trimSearchStr,
        dob: trimSearchStr,
      };
      return (
        <SearchList
          searchTerms={searchTerms}
          tableLabel={SEARCH_SETTINGS.GLOBAL_SEARCH_RESULT_TITLE}
        />
      );
    }
    if (recentPatientIds.length) {
      loadRecentPatients();
      return (
        <SearchList searchTerms={{}} tableLabel={SEARCH_SETTINGS.RECENT_PATIENTS_RESULT_TITLE} />
      );
    }
    return undefined;
  }

  render() {
    const { isAdvancedSearchActive } = this.state;
    const { classes, onToggleDrawer, isLeftDrawerOpen, searchStr, width } = this.props;

    const trimSearchStr = searchStr && searchStr.trim();

    return (
      <CollapsibleSideBar
        classes={classes}
        isLeftDrawerOpen={isLeftDrawerOpen}
        width={width}
        onToggleDrawer={onToggleDrawer}
        usePersistentForMd
      >
        <Grid container className={classes.searchSideBarWrapper}>
          <Grid item xs={6}>
            <Typography variant="h6">Search Results</Typography>
            {(!trimSearchStr || trimSearchStr.length < SEARCH_SETTINGS.MIN_CHARS_TO_SEARCH) &&
              !isAdvancedSearchActive && (
                <Typography className={classes.searchInstructions}>
                  Please enter at least
                  {` ${SEARCH_SETTINGS.MIN_CHARS_TO_SEARCH} `}
                  characters
                </Typography>
              )}
          </Grid>
          <Grid item xs={6} className={classes.advancedSearchButtonWrapper}>
            <Button
              variant="outlined"
              onClick={this.toggleAdvancedSearch}
              className={
                isAdvancedSearchActive
                  ? clasNames(classes.advancedSearchButton, classes.advancedSearchButtonActive)
                  : classes.advancedSearchButton
              }
            >
              Advanced Search
            </Button>
          </Grid>
        </Grid>

        {isAdvancedSearchActive ? <AdvancedSearch /> : this.renderResults(trimSearchStr)}
      </CollapsibleSideBar>
    );
  }
}

function mapStateToProps(state) {
  const { patient, tabControl, view, userPreferences } = state;
  const { recent_items: recentItems } = userPreferences;
  const { patients } = recentItems;
  const recentPatientIds = patients || [];
  const { patientTab } = tabControl;
  const selector = formValueSelector(SEARCH_FORM);
  const searchStr = selector(state, 'search');
  return {
    patient,
    patientTab,
    isLeftDrawerOpen: view.leftDrawer,
    searchStr,
    recentPatientIds,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    cleanExternalSearchForm: () => {
      dispatch(change(SEARCH_FORM, 'search', ''));
    },
    onSelectTask: taskType => {
      dispatch(new ToggleLeftDrawer(true));
    },
    onInitialize: () => {
      dispatch(clearWorkList());
      return dispatch(fetchTaskCounts());
    },
    cleanPatientList: () => {
      dispatch(clearWorkList());
    },
    onToggleDrawer: state => dispatch(new ToggleLeftDrawer(state)),
    loadRecentPatients: () => dispatch({ type: RECENT_PATIENTS_REQUEST }),
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
  withWidth(),
)(SearchSideBar);

import React from 'react';
import withStyles from '@mui/styles/withStyles';
import DocumentsList from 'containers/patient/documents/documents-list';
import { styles } from '../nav-styles';

const Documents = ({ match }) => {
  const { patientId } = match.params;

  return (
    <div>
      <DocumentsList patientId={patientId} />
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Documents);

import React, { Component } from 'react';

export default class RadioGroup extends Component {
  constructor({ children, value }) {
    super();
    const index = children.findIndex(c => c.props.value === value);
    this.state = { checkedIndex: index };
    this.renderChild = this.renderChild.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  // This is the case to handle late arriving props,
  // and set the state according to the value
  // as long as it's not disabled
  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { children } = this.props;
    const { checkedIndex } = this.state;

    const index = children.findIndex(c => c.props.value === nextProps.value && !c.props.disabled);

    // Disabled is passed to childrens in redux-field.js
    // so if all children are disabled that mean radio-group is disabled.
    const isDisabled = children.every(
      childrenComponent => childrenComponent.props.disabled === true,
    );

    if (!isDisabled && index !== checkedIndex) {
      this.setState({ checkedIndex: index });
    }
  }

  onChange(index) {
    const { onChange, children, methodButtonCallback } = this.props;
    const child = children[index];
    if (!child) {
      return;
    }

    this.setState({ checkedIndex: index });
    if (onChange) {
      onChange(child.props.value || child.props.value === 0 ? child.props.value : '');
    }
    if (methodButtonCallback) {
      methodButtonCallback(index);
    }
  }

  get value() {
    const { checkedIndex } = this.state;
    const { children } = this.props;

    const child = children.find(c => c.props.index === checkedIndex);
    return (child && (child.props.value || child.props.value === 0)) || '';
  }

  renderChild(child, index, checked) {
    const { children, horizontal, wrap } = this.props;
    return React.cloneElement(child, {
      horizontal,
      wrap,
      index,
      checked,
      key: index,
      last: index === children.length - 1,
      onChange: this.onChange,
      ...child.props,
    });
  }

  render() {
    const { checkedIndex } = this.state;
    const { width, horizontal, wrap, label, children, indexOverride, ...props } = this.props;
    const style = horizontal
      ? {
          display: wrap ? 'flex' : 'inline-flex',
          width: width || '95%',
          flexWrap: wrap ? 'wrap' : 'nowrap',
        }
      : {};

    return (
      <div style={style}>
        {children.map((c, i) => this.renderChild(c, i, i === (indexOverride || checkedIndex)))}
      </div>
    );
  }
}

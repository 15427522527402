const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '50%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  divRow: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  addFormContainer: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: 5,
    padding: 15,
    margin: '15px 30px 30px 30px',
  },
  addBenefitsInvestigationModal: {
    position: 'absolute',
    width: theme.spacing(120),
    backgroundColor: theme.palette.primary.background,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: 5,
    overflow: 'auto',
    maxHeight: '92vh',
  },
  input: {
    display: 'flex',
    padding: 0,
  },
});

export default styles;

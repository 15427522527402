import { IPhone } from 'interfaces/redux/IPhone';
import { convert12HourTo24HourNumber } from 'services/utils/date-service';
import { MIDNIGHT_24_HOUR, ONE_MIN_BEFORE_MIDNIGHT_24_HOUR } from 'constants/index';
import moment from 'moment';

/**
 * Group index 1 = start hour
 * Group index 2 = start minute
 * Group index 3 = end hour
 * Group index 4 = end minute
 */
const periodRegex = /(\d?\d):(\d\d)-(\d?\d):(\d\d)/;

/**
 * 1:00 PM
 */
const timeFormat = 'h:mm A';

export class PhoneUtils {
  static formatAvailableTimes = (phone?: IPhone): string | null => {
    // Check that we can actually parse the data.
    if (
      phone != null &&
      phone.period &&
      typeof phone.period === 'string' &&
      periodRegex.test(phone.period)
    ) {
      const regExpGroups = periodRegex.exec(phone.period);
      if (regExpGroups != null) {
        const [, startHour, startMinute, endHour, endMinute] = regExpGroups;
        const startMoment = moment().set({ hour: Number(startHour), minute: Number(startMinute) });
        const endMoment = moment().set({ hour: Number(endHour), minute: Number(endMinute) });

        return `${startMoment.format(timeFormat)} - ${endMoment.format(timeFormat)}`;
      }
    }
    return null;
  };

  static convertToTalkdeskCallableFormat = (number: any): string => {
    const numberAsString = `${number}`;
    // Convert phone number to one callable by the talkdesk extension
    const strippedNumber = numberAsString.replace(/\D/g, '');
    const convertedNumber = `tel:+1-${strippedNumber.substring(0, 3)}-${strippedNumber.substring(
      3,
      6,
    )}-${strippedNumber.substring(6, 10)}`;
    return convertedNumber;
  };

  static isPhoneAvailable = (
    availability: string | undefined,
    time: Date = new Date(),
  ): boolean => {
    if (availability) {
      const timeArr = availability.replace(/\s+/g, '').split('-');
      const startAvailability: number = convert12HourTo24HourNumber(timeArr[0]) || MIDNIGHT_24_HOUR;
      const endAvailability: number =
        convert12HourTo24HourNumber(timeArr[1]) || ONE_MIN_BEFORE_MIDNIGHT_24_HOUR;
      let currentHour = `${time.getHours()}`;
      if (currentHour.length === 1) {
        currentHour = `0${currentHour}`;
      }
      let currentMinute = `${time.getMinutes()}`;
      if (currentMinute.length === 1) {
        currentMinute = `0${currentMinute}`;
      }
      const currentTime = Number(`${currentHour}${currentMinute}`);

      if (currentTime >= startAvailability && currentTime <= endAvailability) {
        return true;
      }
      return false;
    }
    return true;
  };
}

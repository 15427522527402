import React from 'react';
import { TextField, FormHelperText, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import FieldLabel from './field-label';
import { styles } from './field-styles';

const AutocompleteLabel = ({
  idValue,
  defaultIdValue,
  label,
  classes,
  useLargeLabel,
  fieldWidth,
}) => (
  <Grid>
    <Grid item xs={12} className={useLargeLabel ? classes.largeLabel : ''}>
      <FieldLabel fieldName={label} />
    </Grid>
    <Grid item xs={12}>
      <TextField
        variant="standard"
        style={{ width: fieldWidth }}
        value={idValue || defaultIdValue || ''}
        disabled
        InputProps={{
          className: classes.autocompleteLabelTextInterior,
          disabled: Boolean(classes.disabled),
          classes: {
            root: classes.autocompleteLabelTextInterior,
          },
          name: label,
        }}
      />
    </Grid>

    <Grid item xs={12}>
      <FormHelperText />
    </Grid>
  </Grid>
);

export default withStyles(styles, { withTheme: true })(AutocompleteLabel);

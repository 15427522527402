import {
  NOTIFY_SUCCESS,
  NOTIFY_SUCCESS_ACTION,
  NOTIFY_WARNING,
  NOTIFY_ERROR,
  PROCESS_NOTIFICATION,
} from 'constants/index';

export const notifySuccess = successMsg => ({
  type: NOTIFY_SUCCESS,
  payload: successMsg,
});

/**
 * Show a snackbar success message with a button to trigger a redux action on
 * the far right side.
 * @param {string} message The wording on the snackbar notification
 * @param {number=} timeout How long the snackbar notification should be visible
 * @param {string=} actionName Name of the redux action
 * @param {Object=} actionPayload Payload for the redux action
 * @param {string=} actionText Text on the button on the right side of the snackbar
 */
export const notifySuccessAction = (message, timeout, actionName, actionPayload, actionText) => ({
  type: NOTIFY_SUCCESS_ACTION,
  payload: { msg: message, timeout, actionName, actionPayload, actionText },
});

export const notifyWarning = warningMsg => ({
  type: NOTIFY_WARNING,
  payload: warningMsg,
});

export const notifyError = errorMsg => ({
  type: NOTIFY_ERROR,
  payload: errorMsg,
});

export const processNotification = () => ({
  type: PROCESS_NOTIFICATION,
  payload: null,
});

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ModalUtil } from 'services/utils/modal.util';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    modal: {
      ...ModalUtil.BasicModalStyling(theme, 140, 3),
      maxHeight: 'calc(95vh - 90px)',
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
  };
});

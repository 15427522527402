import React from 'react';
import cx from 'classnames';
import { Card, CardContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CSL, FC } from 'constants/index';
import { windowFeatureIsEnabled } from 'config/window-features';
import { ICounselingTask } from 'interfaces/redux/task-types/ICounselingTask';
import { IFillCoordinationTask } from 'interfaces/redux/task-types/IFillCoordinationTask';
import FillCoordinationTransition from '../../containers/tasks/fill-coordination/fill-coordination-transition/fill-coordination-transition';
import CounselingTransition from '../../containers/tasks/counseling/counseling-transition/counseling-transition';
import { IProps } from './interfaces/IProps';
import createLazyOnscreenRender from '../../containers/patient/tasks-new/create-lazy-onscreen-render';

const useStyles = makeStyles(() => ({
  wrapper: {
    boxShadow: 'none',
  },
}));

// This factory should return a component that can change the form
// based on the status of the task.
const SingleForm = (props: IProps) => {
  const classes = useStyles();
  const {
    tasks,
    taskWrapperClass,
    cancelClick,
    legacyFormProps,
    onSelectTaskIds: selectTasks,
    therapies,
    handleOnSelectedRow,
  } = props;

  switch (tasks?.[0]?.taskType) {
    case CSL:
      return (
        <Card className={cx(taskWrapperClass, classes.wrapper)}>
          <CounselingTransition
            therapyIndex={null}
            tasks={tasks.map(task => task as ICounselingTask)}
            legacyFormProps={legacyFormProps}
            legacyCancelClick={cancelClick}
            tab="tasks"
            therapies={therapies}
          />
        </Card>
      );
    case FC:
      return (
        <FillCoordinationTransition
          handleOnSelectedRow={handleOnSelectedRow}
          tasks={tasks as IFillCoordinationTask[]}
          legacyFormProps={legacyFormProps as any}
          legacyCancelClick={cancelClick}
          onSelectTaskIds={selectTasks}
          taskWrapperClass={taskWrapperClass}
          therapies={therapies}
        />
      );
    default:
      return null;
  }
};

const MultipleForms = (props: IProps) => {
  const {
    tasks,
    taskWrapperClass,
    cancelClick,
    legacyFormProps,
    onSelectTaskIds: selectTasks,
    taskStatusProcessor,
    therapies,
    handleOnSelectedRow,
  } = props;

  switch (tasks?.[0]?.taskType) {
    case CSL:
      // eslint-disable-next-line no-case-declarations
      const counselingStatusGroups = taskStatusProcessor.groupTasksByStatus(tasks);
      return (
        <>
          {Object.keys(counselingStatusGroups)
            .filter(group => counselingStatusGroups[group].length)
            .map(group => (
              <CounselingTransition
                therapyIndex={null}
                tasks={counselingStatusGroups[group] as ICounselingTask[]}
                legacyFormProps={legacyFormProps}
                legacyCancelClick={cancelClick}
                tab="tasks"
                therapies={therapies}
              />
            ))}
        </>
      );
    case FC:
      // eslint-disable-next-line no-case-declarations
      const fcStatusGroups = taskStatusProcessor.groupTasksByStatus(tasks);
      return (
        <>
          {Object.entries(fcStatusGroups)
            .filter(([, value]) => value.length)
            .map(([, value]) => {
              const taskIds = value.map(x => x.id);
              const updatedLegacyProps = {
                ...legacyFormProps,
                taskIds: value.map(x => x.id),
                combinedTask: {
                  [FC]: legacyFormProps.combinedTask[FC].filter((x: any) => taskIds.includes(x.id)),
                },
              };
              return (
                <FillCoordinationTransition
                  handleOnSelectedRow={handleOnSelectedRow}
                  tasks={value as IFillCoordinationTask[]}
                  legacyFormProps={updatedLegacyProps as any}
                  legacyCancelClick={cancelClick}
                  onSelectTaskIds={selectTasks}
                  taskWrapperClass={taskWrapperClass}
                  therapies={therapies}
                />
              );
            })}
        </>
      );
    default:
      return null;
  }
};

export default {
  SingleForm: createLazyOnscreenRender(SingleForm),
  MultipleForms: createLazyOnscreenRender(MultipleForms),
};

import React, { useState, useEffect, ChangeEvent, SyntheticEvent } from 'react';
import { Tabs, Tab, Grid, Tooltip, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';

import cx from 'classnames';

import { buildQaIdProp } from '../../utils/build-qa-id';
import { usePrevious } from '../../hooks/usePrevious';

import { styles } from './vertical-tabs.styles';
import { IProps } from './interfaces/IProps';
import { IVerticalTab } from './interfaces/IVerticalTab';

const qaIdProp = buildQaIdProp('VerticalTabs');

const VerticalTabs: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    classes,
    initialSelectedTabIndex,
    tabs,
    onChange,
    showNavigationButtons,
    previousButtonText,
    nextButtonText,
    augmentVerticalTabsInnerContainer,
    augmentVerticalTabsTabsContainer,
    variantProp,
  } = props;

  const tabsSelectorWidthPercentage = props.tabListWidthPercentage
    ? props.tabListWidthPercentage
    : 15;
  const tabsWidthPercentage = 100 - tabsSelectorWidthPercentage;

  const [selectedTab, setSelectedTab] = useState(initialSelectedTabIndex || 0);
  const [visitedTabs, setVisitedTabs] = useState(new Set<number>().add(0));
  // Keep track of the previous selected tab
  const previousSelectedTab = usePrevious(selectedTab);

  const handleTabChange = (_event: SyntheticEvent, selected: number): void => {
    const newVisitedTabs: Set<number> = new Set<number>();

    // Add selected tab to visited  tabs set
    newVisitedTabs.add(selected);

    // Is currently the first tab selected? if so, it means we are leaving first tab so mark it as visited as well
    if (selectedTab === 0 && selected !== selectedTab) {
      newVisitedTabs.add(selectedTab);
    }

    // Merge new and previous visited tabs
    visitedTabs.forEach(newVisitedTabs.add, newVisitedTabs);
    setVisitedTabs(newVisitedTabs);
    setSelectedTab(selected);
  };

  const nextTab = () => {
    const nextSelectedTab = selectedTab + 1;
    setSelectedTab(nextSelectedTab);
    handleTabChange({} as SyntheticEvent, nextSelectedTab);
  };

  const previousTab = () => {
    const nextSelectedTab = selectedTab - 1;
    setSelectedTab(nextSelectedTab);
    handleTabChange({} as SyntheticEvent, nextSelectedTab);
  };

  useEffect(() => {
    if (onChange && previousSelectedTab !== undefined) {
      onChange(selectedTab, previousSelectedTab, visitedTabs);
    }
  }, [selectedTab]);

  useEffect(() => {
    if (onChange) {
      onChange(selectedTab, undefined, visitedTabs);
    }
  }, []);

  const renderTabPanel = (
    tabIndex: number,
    label: JSX.Element | string,
    selected: boolean,
    valid?: boolean,
    invalidMessage?: string,
  ): JSX.Element => (
    <Grid container alignItems="center">
      <Grid {...qaIdProp(`tab-label-${tabIndex}`)} item className={classes.labelGrid} xs={9}>
        {label}
      </Grid>
      <Grid item xs={3}>
        {valid !== undefined &&
          valid === false &&
          (invalidMessage ? (
            <Tooltip {...qaIdProp(`invalid-tooltip-${tabIndex}`)} title={invalidMessage}>
              <CheckCircleIcon
                {...qaIdProp(`invalid-icon-${tabIndex}`)}
                className={cx(classes.invalidIcon)}
              />
            </Tooltip>
          ) : (
            <CheckCircleIcon
              {...qaIdProp(`invalid-icon-${tabIndex}`)}
              className={cx(classes.invalidIcon)}
            />
          ))}
        {valid !== undefined && valid === true && (
          <CheckCircleIcon
            {...qaIdProp(`valid-icon-${tabIndex}`)}
            className={cx(classes.validIcon, selected ? classes.validIconSelected : undefined)}
          />
        )}
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      spacing={3}
      className={cx(classes.container, augmentVerticalTabsInnerContainer)}
    >
      <Grid item xs={12}>
        <div className={classes.tabsWrapper} {...qaIdProp('tabs-wrapper')}>
          <Tabs
            {...variantProp}
            indicatorColor="primary"
            className={cx(classes.tabs, augmentVerticalTabsTabsContainer)}
            orientation="vertical"
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="vertical-tab"
            style={{ minWidth: `${tabsSelectorWidthPercentage}%` }}
          >
            {tabs.map((tab: IVerticalTab, index: number) => (
              <Tab
                key={index}
                label={renderTabPanel(
                  index,
                  tab.label,
                  index === selectedTab,
                  tab.valid,
                  tab.invalidMessage,
                )}
                {...qaIdProp(`tab-panel-${index}`)}
                className={cx(
                  classes.tab,
                  index === selectedTab ? classes.selectedTab : classes.unselectedTab,
                )}
                onClick={() => {
                  if (tab.onSelect) {
                    tab.onSelect();
                  }
                }}
              />
            ))}
          </Tabs>
          {tabs.map((tab: IVerticalTab, index: number) => (
            <div
              key={index}
              role="tabpanel"
              id={`vertical-tabpanel-${index}`}
              hidden={selectedTab !== index}
              aria-labelledby={`vertical-tab-${index}`}
              className={classes.tabContentWrapper}
              {...qaIdProp(`tab-content-${index}`)}
              style={{ width: `calc(${tabsWidthPercentage}% - 8px)` }}
            >
              {tab.content}
            </div>
          ))}
        </div>
      </Grid>
      {showNavigationButtons === true && (
        <Grid
          container
          justifyContent="space-between"
          className={classes.navigationButtonsContainer}
        >
          <Grid>
            {selectedTab !== 0 && (
              <Button variant="contained" onClick={previousTab}>
                {previousButtonText ?? 'previous'}
              </Button>
            )}
          </Grid>
          <Grid>
            {selectedTab !== tabs.length - 1 && (
              <Button variant="contained" onClick={nextTab}>
                {nextButtonText ?? 'next'}
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(styles)(VerticalTabs);

import moment from 'moment';
import rsJson from 'config/task-types/rs.json';
import { ITask } from 'interfaces/redux/ITasks';
import { IState } from 'interfaces/redux/IState';
import { TaskStatus } from 'interfaces/enums/TaskStatus';
import { rsCancelReasons } from 'constants/lists';
import { UserUtils } from 'utils/user-utils';
import { UserPermissions, CustomerFeatures } from 'interfaces/user/Permissions';
import { windowFeatureIsEnabled } from 'config/window-features';
import { TASK_CONTEXT } from 'constants/index';
import { staticProviders, getCommonProviders, getDateForProvider } from './common-provider';

const getTherapyData = (state: IState, therapyId: number) => {
  if (state?.therapies) {
    return Object.entries(state.therapies).reduce((acc, [key, value]) => {
      if (parseInt(key, 10) === therapyId) {
        acc = value;
      }
      return acc;
    }, null);
  }
  return null;
};

const getRsCollectInformation = (task: ITask, state: IState): any => {
  let rsCollectInformation = {};
  if (windowFeatureIsEnabled('remove_rs_from_dc_protocol')) {
    rsCollectInformation = {
      dataCollectInformation: {
        id: task.id,
        status_id: task.status_id,
        patientClinicalDataId: task.patientClinicalDataItems ?? [],
        context: TASK_CONTEXT,
        therapy: task.therapyInitialId ? getTherapyData(state, task.therapyInitialId) : null,
      },
    };
  }
  return rsCollectInformation;
};

export default (task: ITask, state: IState) => ({
  ...staticProviders,
  ...getCommonProviders(task, state, rsJson),
  rsCancelReasons: rsCancelReasons.map(p => ({
    id: p.value,
    name: p.label,
  })),
  json: rsJson,
  customStatusDisabled: (statusName: string) => {
    // User may only status transition to Accepted or Cancel if they have the proper permission. Disable all others.
    if (statusName === TaskStatus.Canceled) {
      return !UserUtils.isPermissionGrantedOnCustomerFeature(
        UserPermissions.RsTasksManagement,
        CustomerFeatures.CdmCustomer,
      );
    }
    return false;
  },
  riskStratViewOptions: [
    { id: 1, name: 'Risk Strat' },
    { id: 2, name: 'Previous Risk Strat' },
  ],
  ...getRsCollectInformation(task, state),
  initialValues: {
    risk_strat_view_toggle: 1,
    canceledDt: getDateForProvider(moment()),
  },
});

import ExpansionSection from 'components/expansion-section';
import HorizontalFdcList from 'components/horizontal-fdc-list/horizontal-fdc-list';
import React from 'react';
import SecondaryTitleBar from 'components/secondary-title-bar/secondary-title-bar';
import AdherenceTable from 'components/adherence/adherence-table';
import AdherenceInterventionButtonGroup from 'components/adherence/adherence-int-btn-group';
import { buildQaId } from 'utils/build-qa-id';
import withStyles from '@mui/styles/withStyles';
import { IAdherenceSectionProps } from '../interfaces/IAdherenceSection';
import { styles } from '../sections.styles';

const qaIdBuilder = buildQaId('adherence-section');

const AdherenceSection: React.FC<IAdherenceSectionProps> = (
  props: IAdherenceSectionProps,
): JSX.Element => {
  const { classes } = props;

  const outerSectionClasses = {
    header: classes.sectionWrapperHeader,
    expandIcon: classes.sectionWrapperExpandIcon,
    expansionPanel: classes.sectionWrapperExpansionPanel,
    content: classes.sectionWrapperContent,
  };

  return (
    <ExpansionSection title="Adherence" classes={outerSectionClasses}>
      <div className={classes.titleSpacing} data-qa-id={qaIdBuilder('secondary-title-container')}>
        <SecondaryTitleBar title="PDC" />
      </div>
      {props.pdcTable && (
        <>
          <AdherenceTable adherenceData={props.pdcTable} gridProps={{ xs: 12 }} />
          <AdherenceInterventionButtonGroup
            previousInterventionCount={props.pdcTable?.previousInterventionCount}
            gridProps={{ xs: 12 }}
          />
        </>
      )}
      <div className={classes.titleSpacing} data-qa-id={qaIdBuilder('secondary-title-container')}>
        <SecondaryTitleBar title="Previous FDC Dates" />
      </div>
      <HorizontalFdcList fdcItems={props.fdcItems} />
    </ExpansionSection>
  );
};

export default withStyles(styles)(AdherenceSection);

import makeStyles from '@mui/styles/makeStyles';
import { expandTheme } from 'utils/theme-util';
import { ModalUtil } from 'services/utils/modal.util';

export const styles = makeStyles(theme => {
  const { font, palette } = expandTheme(theme);

  return {
    modal: {
      ...ModalUtil.BasicModalStyling(theme, 130, 0),
      width: theme.spacing(300),
      maxHeight: 700,
    },
    title: {
      fontWeight: 400,
      fontSize: font.largeFontSize,
      padding: theme.spacing(1),
      backgroundColor: palette.primary.trellisLightBlue,
      borderRadius: 8,
    },
    medSyncContainer: {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    divider: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(5),
    },
    bottonContainer: {
      paddingBottom: theme.spacing(3),
    },
    table: {
      borderRadius: theme.spacing(0.5),
      minWidth: theme.spacing(80),
      border: `1px solid ${palette.primary.grey25}`,
      overflow: 'auto',
      maxHeight: 350,
    },
    dayText: {
      paddingTop: theme.spacing(10),
    },
    fieldsSection: {
      paddingLeft: theme.spacing(2),
    },
    notificationText: {
      color: palette.primary.darkRed,
      paddingLeft: theme.spacing(6),
      paddingTop: theme.spacing(3),
      fontWeight: 450,
    },
  };
});

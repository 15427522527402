import { UPDATE_DOCUMENT_BAR } from '../constants';

export default (
  state = {
    documentId: undefined,
    display: false,
    taskIdResourceIds: [],
    tagTypeId: undefined,
    resourceId: undefined,
    filePrefix: undefined,
    fileExtension: undefined,
    tags: [],
    hasNote: false,
  },
  { type, payload },
) => {
  switch (type) {
    case UPDATE_DOCUMENT_BAR:
      return { ...state, ...payload };
    default:
      return state;
  }
};

import React from 'react';
import { BaseTable } from 'containers/application-manager/base-table/base-table';
import { Popover } from '@mui/material';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { useDispatch } from 'react-redux';
import { UserPermissions } from 'interfaces/user/Permissions';
import { UserUtils } from 'utils/user-utils';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { dateConvert, booleanConvert, utcMomentOrUndefined } from '../../utils';
import { IPopoverStateBase } from '../types';
import { FieldTypes } from '../../types';
import { FormField } from '../../popover-field-forms/index';
import { IResponse, IResult } from './types';
import { logger } from '../../../../winston-logger';

const baseUrl = '/application-manager/ttc';

interface ITableProps {
  searchTerm: string | undefined;
  triggerReload: Date | undefined;
  handleReloadDataTrigger: (date: Date | undefined) => void;
  therapeuticAreas: { id: number; name: string }[];
}

interface IPopoverState extends IPopoverStateBase<IResult> {
  options?: {
    id: number;
    name: string;
  }[];
}

const initialPopoverState = { open: false } as IPopoverState;

export const TtcTable: React.FC<ITableProps> = (props): JSX.Element => {
  const dispatch = useDispatch();

  // #region component state
  const [popoverState, setPopoverState] = React.useState<IPopoverState>(initialPopoverState);
  // #endregion

  // #region helpers
  const afterRequestSuccess = (callbacks: (() => void)[] | undefined = undefined) => {
    dispatch(notifySuccess('Saved'));
    props.handleReloadDataTrigger(new Date());
    if (callbacks) {
      callbacks.forEach(cb => cb());
    }
  };

  const onSubmitFieldUpdate = async (value: unknown) => {
    try {
      const request = ApplicationManagerClient.updateTtc(
        popoverState.parent.id,
        popoverState.field,
        value,
      );
      await request;
      afterRequestSuccess([() => setPopoverState(initialPopoverState)]);
    } catch (error) {
      logger.error(error);
      dispatch(notifyError('An error occurred'));
    } finally {
      setPopoverState(initialPopoverState);
    }
  };

  const openPopover = (
    element: HTMLElement,
    value: unknown,
    config: {
      parent: IResult;
      fieldType: FieldTypes;
      field: keyof IResult;
      forceAsParent?: boolean;
      options?: {
        id: number;
        name: string;
      }[];
    },
  ): void => {
    setPopoverState({
      open: true,
      anchorElement: element,
      parent: config.parent,
      field: config.field,
      fieldType: config.fieldType,
      value: value,
      forceAsParent: config.forceAsParent,
      options: config.options,
    });
  };
  // #endregion

  // #region renders
  const renderPopover = (): JSX.Element => {
    const handleCancel = () => setPopoverState(initialPopoverState);
    const handleSubmit = (value: unknown) => onSubmitFieldUpdate(value);

    return (
      <Popover
        id="form"
        open={popoverState.open}
        anchorEl={popoverState?.anchorElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transitionDuration={{ exit: 0 }}
        onClose={() => setPopoverState(initialPopoverState)}
      >
        <FormField
          data-qa-id={`${popoverState.field}-${popoverState.parent?.id || ''}`}
          initialValue={popoverState.value}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          fieldType={popoverState.fieldType}
          options={popoverState.options}
        />
      </Popover>
    );
  };

  return (
    <>
      {renderPopover()}
      <BaseTable<IResponse, IResult, undefined>
        actionsPermitted={UserUtils.userIsPermitted(UserPermissions.ApplicationManagerTtcEdit)}
        enableParentCheckboxes={false}
        enableChildCheckboxes={UserUtils.userIsPermitted(UserPermissions.ApplicationManagerTtcEdit)}
        orderByDefaultParent="name"
        endpointBase={baseUrl}
        paginationQueryParamSettings={{
          pageSizeQueryStringKey: 'pageSize',
          pageNumberQueryStringKey: 'pageNumber',
          searchTermQueryStringKey: 'searchTerm',
          sortPropQueryStringKey: 'sortProp',
          sortOrderQueryStringKey: 'sortOrder',
        }}
        childPkSelector={() => ''}
        parentPkSelector={p => p.id}
        searchTerm={props.searchTerm}
        columnSettings={[
          {
            label: 'Therapeutic Class',
            sortable: true,
            serverSideSearchField: 'name',
            parentKey: 'name',
            hideLeftPadding: true,
          },
          {
            label: 'Active',
            sortable: true,
            parentKey: 'active',
            serverSideSearchField: 'active',
            parentValueFormatter: booleanConvert,
            parentCellClick: (event, parent) => {
              openPopover(event.currentTarget, !!parent.active, {
                parent: parent,
                field: 'active',
                fieldType: FieldTypes.YesNo,
              });
            },
          },
          {
            label: 'Restricted',
            sortable: true,
            parentKey: 'is_restricted',
            serverSideSearchField: 'is_restricted',
            parentValueFormatter: booleanConvert,
            parentCellClick: (event, parent) => {
              openPopover(event.currentTarget, !!parent.is_restricted, {
                parent,
                field: 'is_restricted',
                fieldType: FieldTypes.YesNo,
              });
            },
          },
          {
            label: 'PDC Eligible',
            sortable: true,
            parentKey: 'pdc_eligible',
            serverSideSearchField: 'pdc_eligible',
            parentValueFormatter: booleanConvert,
            parentCellClick: (event, parent) => {
              openPopover(event.currentTarget, !!parent.pdc_eligible, {
                parent,
                field: 'pdc_eligible',
                fieldType: FieldTypes.YesNo,
              });
            },
          },
          {
            label: 'Therapeutic Area',
            sortable: true,
            parentKey: 'therapeutic_area_id',
            serverSideSearchField: 'therapeutic_area_id',
            parentValueFormatter: (value: unknown) => {
              const therapeuticArea =
                props.therapeuticAreas?.find(item => item.id === value)?.name || '-';
              return therapeuticArea;
            },
            parentCellClick: (event, parent) => {
              openPopover(event.currentTarget, !!parent.active, {
                parent: parent,
                field: 'therapeutic_area_id',
                fieldType: FieldTypes.TherapeuticArea,
                options: props.therapeuticAreas,
              });
            },
          },
          {
            label: 'Start Date',
            sortable: true,
            parentKey: 'start_date',
            serverSideSearchField: 'start_date',
            parentValueFormatter: dateConvert,
            parentCellClick: (event, parent) => {
              openPopover(event.currentTarget, utcMomentOrUndefined(parent.start_date), {
                parent,
                field: 'start_date',
                fieldType: FieldTypes.DatePicker,
              });
            },
          },
          {
            label: 'End Date',
            sortable: true,
            parentKey: 'end_date',
            serverSideSearchField: 'end_date',
            parentValueFormatter: dateConvert,
            parentCellClick: (event, parent) => {
              openPopover(event.currentTarget, utcMomentOrUndefined(parent.end_date), {
                parent,
                field: 'end_date',
                fieldType: FieldTypes.DatePicker,
              });
            },
          },
          {
            label: 'Added',
            sortable: true,
            parentKey: 'created',
            serverSideSearchField: 'created',
            parentValueFormatter: dateConvert,
          },
          {
            label: 'Updated',
            sortable: true,
            parentKey: 'updated',
            serverSideSearchField: 'updated',
            parentValueFormatter: dateConvert,
          },
        ]}
        triggerReload={props.triggerReload}
      />
    </>
  );
};

import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { ITableProps } from './interface';

const HolidayTable = ({ data }: ITableProps) => {
  return (
    <TableContainer component={Paper} sx={{ maxWidth: '70%' }}>
      <Table sx={{ minWidth: 650 }} aria-label="holiday table">
        <TableHead>
          <TableRow>
            <TableCell>Holiday Date</TableCell>
            <TableCell>Customer</TableCell>
            <TableCell>Courier</TableCell>
            <TableCell>UPS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow
              key={`${row.holidayDate}-row`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.holidayDate}
              </TableCell>
              <TableCell>{row.customer}</TableCell>
              <TableCell>{row.courier}</TableCell>
              <TableCell>{row.ups}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HolidayTable;

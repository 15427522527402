import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

const styles = (theme: Theme) => {
  const { palette, fonts, typography } = expandTheme(theme);

  const wrapper = {
    borderRadius: theme.spacing(0.8),
    borderWidth: theme.spacing(0.3),
    maxWidth: 170,
  };

  return createStyles({
    root: wrapper,
    highlighted: {
      ...wrapper,
      backgroundColor: palette.primary.lightGreen,
    },
    title: {
      color: palette.primary.trellisBlue,
      marginBottom: theme.spacing(0.7),
    },
    statusLabel: {
      fontSize: fonts.mediumFontSize,
      fontWeight: typography.title.fontWeight,
    },
    dateLabel: {
      fontSize: fonts.smallFontSize,
    },
    date: {
      fontSize: typography.fontSize,
    },
  });
};

export { styles };

import React from 'react';
import { Grid, TextField, InputAdornment, Button } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { useDebounce } from 'use-debounce';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { UserUtils } from 'utils/user-utils';
import { UserPermissions } from 'interfaces/user/Permissions';
import CustomerDropdown from '../components/customer-dropdown';
import { SectionTitleStyled } from '../components/section-title';
import { searchFieldPlaceholder, debounceTimeMs, initialSearchTerm } from './constants';
import { ArTable } from './ar-table';
import { ArStyles } from './index.styles';
import { AddNewArModal } from './modal/add-new-ar-modal';

type IArProps = WithStyles<typeof ArStyles>

const Ar = (props: IArProps): JSX.Element => {
  const { classes } = props;
  // #region useState
  const [searchTerm, setSearchTerm] = React.useState<string>(initialSearchTerm);
  const [debouncedSearchTerm] = useDebounce<string | undefined>(searchTerm, debounceTimeMs);
  const stateSelectedCustomerId = useTypedSelector(state => state.filters.selectedCustomerId);
  const [selectedCustomerId, setSelectedCustomerId] =
    React.useState<number>(stateSelectedCustomerId);
  const [reloadDataTrigger, setReloadDataTrigger] = React.useState<Date | undefined>(undefined);
  const [addModalOpen, setAddModalOpen] = React.useState<boolean>(false);
  const customers = useTypedSelector(state => state.filters.customers);
  const user = useTypedSelector(state => state.auth.currentUser);

  const userHasArManagePermission = React.useMemo<boolean>(() => {
    return UserUtils.userIsPermitted(UserPermissions.ApplicationManagerArEdit, selectedCustomerId);
  }, [user, selectedCustomerId]);
  // #endregion

  // #region effects
  React.useEffect(() => {
    if (selectedCustomerId == null) {
      if (stateSelectedCustomerId != null) {
        setSelectedCustomerId(stateSelectedCustomerId);
      } else if (customers.length > 0) {
        setSelectedCustomerId(customers[0].id);
      }
    }
  }, [stateSelectedCustomerId]);
  // #endregion

  // #region renders
  const renderEnableArModal = (): JSX.Element => {
    return (
      <AddNewArModal
        open={addModalOpen}
        onCancel={() => setAddModalOpen(false)}
        onSuccess={() => setReloadDataTrigger(new Date())}
        selectedCustomerId={selectedCustomerId}
      />
    );
  };
  // #endregion

  return (
    <>
      {renderEnableArModal()}
      <SectionTitleStyled title="AR" />
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid item xs="auto">
              <Grid container spacing={1} data-qa-id="container 2">
                {/* Left Side - Customer Dropdown */}
                <Grid item xs="auto">
                  <CustomerDropdown
                    selectedCustomerId={selectedCustomerId}
                    setSelectedCustomerId={customerId => setSelectedCustomerId(customerId)}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Right Side - Search Field */}
            <Grid item xs="auto">
              <TextField
                variant="standard"
                placeholder={searchFieldPlaceholder}
                onChange={event => {
                  setSearchTerm(event.target.value);
                }}
                value={searchTerm}
                className={classes.searchField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                  endAdornment:
                    searchTerm != null && searchTerm !== initialSearchTerm ? (
                      <InputAdornment
                        position="end"
                        onClick={() => {
                          setSearchTerm(initialSearchTerm);
                        }}
                        className={classes.textFieldEndAdornment}
                      >
                        <CloseIcon fontSize="small" />
                      </InputAdornment>
                    ) : null,
                }}
              />
              {/* Right Side - Enable AR button */}
              {userHasArManagePermission ? (
                <Button
                  variant="contained"
                  className={classes.modalButton}
                  onClick={() => setAddModalOpen(true)}
                >
                  Enable AR for Clinic
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Grid>

        {/* Below the buttons */}
        <Grid item>
          <ArTable
            searchTerm={debouncedSearchTerm}
            selectedCustomerId={selectedCustomerId}
            triggerReload={reloadDataTrigger}
            canEdit={userHasArManagePermission}
            handleReloadDataTrigger={date => setReloadDataTrigger(date)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(ArStyles)(Ar);

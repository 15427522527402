import React, { useEffect } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Box,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { convertToArborDate } from 'models/time/arbor-date';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { IPatientProfile, IStepProps } from 'components/patient-merge-modal/interfaces';
import { SelectFormSections } from 'components/patient-merge-modal/select-form-view/select-form-sections';
import { useSelector } from 'react-redux';
import { IState } from 'interfaces/redux/IState';
import { setDuplicate } from 'actions/action-patient-merge';
import { parseCardPatientNameLabel, parseLabel } from 'components/patient-merge-modal/utils';
import { PatientGenderLabel } from 'components/patient-merge-modal/info-component/patient-gender-label';
import { useAppDispatch } from 'hooks/useAppDispatch';

export const SelectForm = (props: IStepProps) => {
  const { profiles: allProfiles, setNextButtonEnabled } = props;
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);

  const primaryId = useSelector((state: IState) => state.patientMerge.primary);
  const duplicateProfiles = allProfiles.filter(profile => profile.patientId !== primaryId);

  const selectedProfileId =
    useSelector((state: IState) => state.patientMerge.duplicate) || duplicateProfiles[0]?.patientId;

  const selectedProfile = duplicateProfiles.find(
    profile => profile.patientId === selectedProfileId,
  );
  const primaryProfile = allProfiles.find(profile => profile.patientId === primaryId);

  const dispatch = useAppDispatch();

  const handleDuplicateChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.value) {
      return;
    }

    const patientId = Number(e?.target?.value);
    dispatch(setDuplicate(patientId));
  };

  useEffect(() => {
    if (selectedProfileId) {
      dispatch(setDuplicate(selectedProfileId));
    }
  }, []);

  useEffect(() => {
    setNextButtonEnabled(!!selectedProfileId);
  }, [selectedProfileId]);

  return (
    <>
      <FormControl>
        <RadioGroup
          name="profile-radio-select"
          value={selectedProfileId}
          onChange={e => handleDuplicateChange(e)}
        >
          {duplicateProfiles.map((profile: IPatientProfile) => {
            return (
              <Grid
                key={`profile-item-${profile.patientId}`}
                display="inline-flex"
                alignItems="center"
              >
                <FormControlLabel
                  key={profile.patientId}
                  value={profile.patientId}
                  control={<Radio />}
                  label={parseLabel(profile)}
                />
              </Grid>
            );
          })}
        </RadioGroup>
      </FormControl>
      <Grid container spacing={3}>
        {selectedProfile ? (
          <Grid item xs={6}>
            <Grid sx={classes.profileHeader}>
              <Typography sx={classes.profileHeaderTitle}>
                {`Duplicated - MRN ${selectedProfile?.sourcePatientId}`}
              </Typography>
              <Typography sx={classes.profileHeaderSubtitle}>
                This profile will be removed from Arbor.
              </Typography>
            </Grid>
            <Grid sx={classes.profileContent}>
              <Typography fontWeight={400}>{parseCardPatientNameLabel(selectedProfile)}</Typography>
              <PatientGenderLabel profile={selectedProfile} />
              <Typography>
                {convertToArborDate(selectedProfile?.dob).getUtcDate(true)} (
                <Box sx={classes.highlight} component="span">
                  {convertToArborDate(selectedProfile?.dob).getYearsFromNow()}
                </Box>
                yr)
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={6}>
            <Grid sx={classes.profileHeader}>
              <Typography sx={classes.profileHeaderTitle}>Duplicated Profile Not Set</Typography>
            </Grid>
          </Grid>
        )}
        {primaryProfile ? (
          <Grid item xs={6} sx={classes.selectedWrp}>
            <Grid sx={[classes.profileHeader, classes.primaryHeader]}>
              <Typography sx={classes.profileHeaderTitle}>
                {`Primary - MRN ${primaryProfile?.sourcePatientId}`}
              </Typography>
              <Typography sx={classes.profileHeaderSubtitle}>
                Select the fields from the duplicated profile that will be added.
              </Typography>
            </Grid>
            <Grid sx={[classes.profileContent, classes.primaryContent]}>
              <Typography fontWeight={400}>{parseCardPatientNameLabel(primaryProfile)}</Typography>
              <PatientGenderLabel profile={primaryProfile} />
              <Typography>
                {convertToArborDate(primaryProfile?.dob).getUtcDate(true)} (
                <Box sx={classes.highlight} component="span">
                  {convertToArborDate(primaryProfile?.dob).getYearsFromNow()}
                </Box>
                yr)
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={6} sx={classes.selectedWrp}>
            <Grid sx={[classes.profileHeader, classes.primaryHeader]}>
              <Typography sx={classes.profileHeaderTitle}>Primary Profile Not Set</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <SelectFormSections {...props} />
        </Grid>
      </Grid>
    </>
  );
};

import React from 'react';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { Button, Grid, Modal, Typography } from '@mui/material';
import { CircularLoadingButton } from 'components/circular-loading-button/circular-loading-button';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { getModalStyle } from 'services/utils/styles-service';
import { nameOfFactory } from 'utils/types-util';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { useDispatch } from 'react-redux';
import Validation from 'components/form/validation/validation';
import { buildQaId } from 'utils/build-qa-id';
import { ReactSelect } from 'components/form/field/react-select';
import { StringOption } from 'interfaces/forms/types';
import LoadingOverlay from 'components/loading-overlay/loading-overlay';
import { ControlledText } from 'components/react-hook-form-fields';
import { logger } from '../../../../../winston-logger';
import { styles } from './add-edit-disease-modal.styles';
import { IAddEditDiseaseStateModalProps, IFormFields, IFormFieldsDefaultValues } from './types';

const formFieldsValues: IFormFieldsDefaultValues = {
  icd: null,
  diseaseStateName: '',
};

const getFieldName = nameOfFactory<IFormFields>();
const requiredErrorMsg = 'Required';
const getQaId = buildQaId('application-manager.add-new-disease-state-modal', '.');

export const AddEditDiseaseStateModal: React.FC<IAddEditDiseaseStateModalProps> = (
  props: IAddEditDiseaseStateModalProps,
): JSX.Element => {
  const classes = styles();

  const form = useForm<any>({});
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [icd10SearchTerm, setIcd10SearchTerm] = React.useState<string>('');
  const [icd10List, setIcd10List] = React.useState<StringOption[]>([]);
  const [formValues, setFormValues] = React.useState<IFormFieldsDefaultValues>(formFieldsValues);
  const [wildcards, setWildcards] = React.useState<string[]>([]);

  const icdValuesWatch = form ? form.watch(getFieldName('icd')) : [];

  React.useEffect(() => {
    const icdValuesWatchArr = icdValuesWatch || [];
    const wildcard = icdValuesWatchArr.reduce((foundWildcards: string[], curr: { value: any }) => {
      if (curr.value.includes('?')) {
        foundWildcards.push(curr.value);
      }
      return foundWildcards;
    }, []);
    setWildcards(wildcard);
  }, [icdValuesWatch]);

  React.useEffect(() => {
    const filteredIcd10List = icd10List.map(item => ({
      ...item,
      disabled: isInWildcard(item),
    }));
    setIcd10List(filteredIcd10List);
  }, [wildcards]);

  React.useEffect(() => {
    const icd10Codes = props?.editDiseaseState?.icd
      .split(',')
      .map(item => ({ value: item, label: item }));
    setFormValues({
      diseaseStateName: props?.editDiseaseState?.diseaseName as string,
      icd: icd10Codes as StringOption[],
    });
    form.setValue(getFieldName('icd'), icd10Codes);
  }, [props.editDiseaseState]);

  React.useMemo<void>(() => {
    if (icd10SearchTerm !== '') {
      setLoading(true);
      (async () => {
        try {
          const results = await ApplicationManagerClient.fetchIcd10Codes(icd10SearchTerm);
          const icd10Result = [];
          if (icd10SearchTerm.includes('?')) {
            icd10Result.push({
              label: icd10SearchTerm,
              value: icd10SearchTerm,
            });
          }
          if (results && results?.data?.result?.length > 0) {
            for (const item of results.data.result) {
              const label = item.name === null ? item.code : `${item.code} - ${item.name}`;
              const value = item.code;
              const disabled = isInWildcard({ label, value });
              icd10Result.push({ label, value, disabled });
            }
          }
          setIcd10List(icd10Result);
        } catch (error) {
          logger.error(error);
          dispatch(notifyError('Error when fetching ICD10 codes'));
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [icd10SearchTerm]);

  const saveIcdItem: SubmitHandler<any> = async (formValues: IFormFields): Promise<void> => {
    try {
      const diseaseName = formValues.diseaseStateName;
      const icdCode = formValues.icd.map(icdItem => icdItem.value.trim());
      const newDiseaseState = isEditMode
        ? { diseaseName, icdCode, diseaseStateId: props.editDiseaseState?.id }
        : { diseaseName, icdCode };

      const result = isEditMode
        ? await ApplicationManagerClient.updateDiseaseState(newDiseaseState)
        : await ApplicationManagerClient.addDiseaseState(newDiseaseState);
      if (result.data.success === true) {
        dispatch(notifySuccess('Saved'));
      } else {
        dispatch(notifyError(result.data.result));
      }
      props.onSuccess();
      props.onCancel();
    } catch (error) {
      logger.error(error);
      dispatch(notifyError('Error creating Icd Protocol'));
    }
  };

  const isInWildcard = (code: StringOption): boolean => {
    if (wildcards.length > 0 && code.value.includes('.')) {
      const [icd, wildcard] = code.value.split('.');
      return wildcards.some(curr => {
        const [currIcd, currWildcard] = curr.split('.');
        return icd === currIcd && wildcard.length === currWildcard.length;
      });
    }
    return false;
  };

  const isEditMode = props.editDiseaseState !== undefined;
  return (
    <Modal open={props.open} data-qa-id={getQaId('wrapper')}>
      <div style={getModalStyle()} className={classes.modal}>
        <Grid container direction="column" spacing={2}>
          {/* Title */}
          <Grid item xs="auto">
            <Typography className={classes.title} data-qa-id={getQaId('title')}>
              {isEditMode ? `Edit ${formValues.diseaseStateName}` : 'Add Disease State'}
            </Typography>
          </Grid>

          {/* Form */}
          <Grid item xs="auto" data-qa-id={getQaId('form')}>
            <Grid container spacing={1}>
              {isEditMode ? (
                <></>
              ) : (
                <Grid item xs={3}>
                  <ControlledText
                    name={getFieldName('diseaseStateName')}
                    control={form.control}
                    defaultValue=""
                    validations={{ required: true }}
                    percentWith={100}
                    label="Disease Name *"
                    inputMetaData={{
                      touched: Boolean(form.formState.errors.diseaseStateName),
                      error: requiredErrorMsg,
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={isEditMode ? 12 : 9}>
                <Controller
                  name={getFieldName('icd')}
                  defaultValue={formValues.icd}
                  control={form.control}
                  rules={{ required: true }}
                  render={(ctrlProps: any) => {
                    return (
                      <>
                        <LoadingOverlay open={loading} />
                        <ReactSelect
                          label="Associated ICD-10 *"
                          isMulti
                          value={ctrlProps?.field?.value}
                          fields={icd10List}
                          isOptionDisabled={(option: StringOption) => option.disabled === true}
                          handleOnChange={(value: StringOption) =>
                            ctrlProps?.field?.onChange(value)
                          }
                          handleOnInputChange={(value: string) =>
                            setIcd10SearchTerm(value.toUpperCase())
                          }
                          fullWidth
                        />
                        <Validation touched={form.formState.errors.icd} error={requiredErrorMsg} />
                      </>
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button
                      data-qa-id={getQaId('button.cancel')}
                      onClick={() => {
                        form.reset();
                        props.onCancel();
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <CircularLoadingButton
                      data-qa-id={getQaId('button.save')}
                      buttonLabel="Save"
                      onClick={form.handleSubmit(saveIcdItem)}
                      isLoading={submitting}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End Form */}
        </Grid>
      </div>
    </Modal>
  );
};

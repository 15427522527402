import { IPatientVaccination } from 'interfaces/redux/IPatient';
import { ADD_VACCINE_TO_PATIENT } from 'constants/index';

const addVaccineToPatient = (vaccination: IPatientVaccination) => {
  return {
    type: ADD_VACCINE_TO_PATIENT,
    payload: {
      data: vaccination,
    },
  };
};

export { addVaccineToPatient };

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectPatientId } from 'actions/action-selections';

export const usePatientId = patientId => {
  const dispatch = useDispatch();

  useEffect(() => {
    // TODO: patientId is parsed from application URL therefore possible to be invalid characters
    // TODO: the real issue is in getUrlValue() function
    // TODO: 2020-07 added temporary fix by [Hanxiong]
    // eslint-disable-next-line no-restricted-globals
    if (patientId && !isNaN(Number(patientId))) {
      dispatch(selectPatientId(Number(patientId)));
    }
  }, [patientId]);

  return null;
};

import {
  FETCH_USER_PREFERENCES,
  NOTES_VIEW_SET,
  RECENT_PATIENT_LIMIT,
  RESET_LOCAL_USER_PREFERENCES_TASKS_THERAPIES,
  UPDATE_LOCAL_USER_PREFERECENES_RECENT_CLINICS,
  UPDATE_LOCAL_USER_PREFERECENES_RECENT_PHARMACIES,
  UPDATE_LOCAL_USER_PREFERECENES_RECENT_PROVIDERS,
  UPDATE_LOCAL_USER_PREFERENCES_BI_PHARMACY_ID,
  UPDATE_LOCAL_USER_PREFERENCES_INVOICING,
  UPDATE_LOCAL_USER_PREFERENCES_TASKS,
  UPDATE_LOCAL_USER_PREFERENCES_TASKLIST,
  UPDATE_LOCAL_USER_PREFERENCES_THERAPIES_ADMINISTRATION,
  UPDATE_LOCAL_USER_PREFERENCES_THERAPIES_ENROLLMENT,
  UPDATE_USER_PREFERENCES,
  USER_PREFERENCES_SAVE_SUCCESS,
  USER_PREFERENCES_SELECT_RECENT_PATIENT,
} from 'constants/index';

const RECENT_TO_KEEP = 5;
const RECENT_PROVIDERS_TO_KEEP = 15;

const initialState = {
  loaded: false,
  worklist: {
    filters: {
      from_date: null,
      to_date: null,
      service_groups: [],
      category_ids: [],
      therapy_enrollment_statuses: [],
      therapy_administration_statuses: [],
    },
  },
  show_tree_view: true,
  recent_items: {
    providers: [],
    clinics: [],
    pharmacies: [],
    patients: [],
  },
  bi_preferred_pharmacy_id: undefined,
};

// Ensure that the preferences have all of the initial values
const ensureWellFormed = prefs => {
  Object.keys(initialState).forEach(key => {
    if (prefs[key] === undefined || prefs[key] === null) {
      prefs[key] = initialState[key];
    }
  });
  return prefs;
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_USER_PREFERENCES:
      return ensureWellFormed({
        ...payload,
        loaded: true,
      });
    case NOTES_VIEW_SET:
      return {
        ...state,
        show_tree_view: payload.view,
      };
    case FETCH_USER_PREFERENCES:
      if (payload.data && payload.data.user_preferences) {
        const prefs = payload.data.user_preferences;
        return ensureWellFormed({
          ...prefs,
          loaded: true,
        });
      }
      return initialState;
    case USER_PREFERENCES_SAVE_SUCCESS:
      return {
        ...payload,
        loaded: true,
      };
    case UPDATE_LOCAL_USER_PREFERENCES_THERAPIES_ENROLLMENT:
      return {
        ...state,
        worklist: {
          ...state.worklist,
          filters: {
            ...state.worklist.filters,
            therapy_enrollment_statuses: payload,
          },
        },
        loaded: true,
      };
    case UPDATE_LOCAL_USER_PREFERENCES_THERAPIES_ADMINISTRATION:
      return {
        ...state,
        worklist: {
          ...state.worklist,
          filters: {
            ...state.worklist.filters,
            therapy_administration_statuses: payload,
          },
        },
        loaded: true,
      };
    case UPDATE_LOCAL_USER_PREFERENCES_TASKS:
      return {
        ...state,
        worklist: {
          ...state.worklist,
          filters: {
            ...state.worklist.filters,
            category_ids: payload,
          },
        },
        loaded: true,
      };
    case UPDATE_LOCAL_USER_PREFERENCES_TASKLIST:
      return {
        ...state,
        tasklist: {
          ...state.tasklist,
          period: payload.tasklist.period,
        },
        loaded: true,
      };
    case UPDATE_LOCAL_USER_PREFERENCES_INVOICING:
      return {
        ...state,
        invoicing: payload,
        loaded: true,
      };
    case UPDATE_LOCAL_USER_PREFERECENES_RECENT_PROVIDERS:
      return {
        ...state,
        recent_items: {
          ...state.recent_items,
          providers: state.recent_items.providers.some(x => x.npi === payload.npi)
            ? state.recent_items.providers
            : [payload].concat(
                state.recent_items.providers.splice(0, RECENT_PROVIDERS_TO_KEEP - 1),
              ),
        },
        loaded: true,
      };
    case UPDATE_LOCAL_USER_PREFERECENES_RECENT_CLINICS:
      return {
        ...state,
        recent_items: {
          ...state.recent_items,
          clinics: state.recent_items.clinics.some(x => x.npi === payload.npi)
            ? state.recent_items.clinics
            : [payload].concat(state.recent_items.clinics.splice(0, RECENT_TO_KEEP - 1)),
        },
        loaded: true,
      };
    case UPDATE_LOCAL_USER_PREFERECENES_RECENT_PHARMACIES:
      return {
        ...state,
        recent_items: {
          ...state.recent_items,
          pharmacies: state.recent_items.pharmacies.some(x => x.npi === payload.npi)
            ? state.recent_items.pharmacies
            : [payload].concat(state.recent_items.pharmacies.splice(0, RECENT_TO_KEEP - 1)),
        },
        loaded: true,
      };
    case RESET_LOCAL_USER_PREFERENCES_TASKS_THERAPIES:
      if (payload.therapyVals) {
        return {
          ...state,
          worklist: {
            ...state.worklist,
            filters: {
              ...state.worklist.filters,
              therapy_enrollment_statuses: payload.therapyVals.filter(
                item => item.resource === 'therapy_enrollment',
              ),
              therapy_administration_statuses: payload.therapyVals.filter(
                item => item.resource === 'therapy_administration',
              ),
              category_ids: payload.taskVals,
            },
          },
          loaded: true,
        };
      }
      return state;
    case USER_PREFERENCES_SELECT_RECENT_PATIENT:
      if (payload.patientId) {
        let recentPatients = [];
        if (state.recent_items.patients) {
          recentPatients = state.recent_items.patients;
        }

        // If the patient id is already in the recent list, remove it
        const patientIdInRecent = recentPatients.findIndex(x => x === payload.patientId);
        if (patientIdInRecent !== -1) {
          recentPatients.splice(patientIdInRecent, 1);
        }

        // If the list of ids is longer than the limit, shrink it
        if (recentPatients.length >= RECENT_PATIENT_LIMIT) {
          recentPatients = recentPatients.slice(0, RECENT_PATIENT_LIMIT - 1);
        }

        // Put the patient id at the beginning of the array
        recentPatients.unshift(payload.patientId);

        return {
          ...state,
          recent_items: {
            ...state.recent_items,
            ...{
              patients: recentPatients,
            },
          },
        };
      }
      return state;
    case UPDATE_LOCAL_USER_PREFERENCES_BI_PHARMACY_ID:
      if (payload) {
        return {
          ...state,
          bi_preferred_pharmacy_id: payload,
        };
      }
      return state;
    default:
      return state;
  }
};

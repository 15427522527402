import moment from 'moment';
import mrJson from 'config/task-types/mr.json';
import { staticProviders, getCommonProviders, getDateForProvider } from './common-provider';

export default (task, state) => {
  const today = getDateForProvider(moment());
  return {
    ...staticProviders,
    ...getCommonProviders(task, state, mrJson),
    ddiDatabaseUsed: state.taskStatuses
      ? state.taskStatuses.medication_review_DDI_database_used
      : [],
    interactions: state.taskStatuses
      ? state.taskStatuses.medication_review_interaction.map(int => ({
          name: int.interaction,
          id: int.id,
        }))
      : [],
    json: mrJson,
    initialValues: {
      meds_reviewed_date: today,
      ddi_generated_date: today,
      ddi_reviewed_date: today,
    },
  };
};

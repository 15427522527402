export const Notes = {
  category: 'Notes',
  actions: {
    switch_notes_view: 'Switch Notes View',
  },
  labels: {
    tree_view: 'Tree View',
    date_view: 'Date View',
  },
};

export const Tasks = {
  category: 'Tasks',
  actions: {
    multi_tasks_selected: 'Multi tasks selected',
    single_task_selected: 'Single task selected',
  },
};

export const Therapies = {
  category: 'Therapies',
  actions: { single_task_selected: 'Single task selected' },
};

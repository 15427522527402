import React, { useEffect, useState, useCallback, useRef } from 'react';
import Mentions from 'rc-mentions';
import lodash from 'lodash';
import Validation from 'components/form/validation/validation';
import { useTypedSelector } from '../../hooks/use-typed-selector';

const INPUT_DEBOUNCE_MS = 500;

const NoteArea = props => {
  const {
    input,
    data,
    disabled,
    maxLength,
    defaultPlaceholder,
    id,
    'data-qa-id': dataQaId,
    meta,
    autoFocus,
  } = props;
  const [val, setVal] = useState(input.value);
  const noteBar = useTypedSelector(state => state.noteBar);
  const debouncedOnChange = useCallback(
    lodash.debounce(value => {
      input.onChange(value);
    }, INPUT_DEBOUNCE_MS),
    [],
  );

  const ref = useRef();
  useEffect(() => {
    if (!autoFocus) {
      return;
    }

    setTimeout(() => {
      ref?.current?.focus();
    }, 1000);
  }, [noteBar.display]);

  // Although we have setState during onChange event, there are
  // other cases which will change the input.value like reset, we need to
  // maintain this local state always be the "source of truth"
  useEffect(() => {
    if (input.value !== val) {
      setVal(input.value);
    }
  }, [input.value]); // eslint-disable-line

  let options = [];
  if (Array.isArray(data)) {
    options = data.map(d => {
      return {
        value: d.username,
        label: d.display_name,
      };
    });
  }

  return (
    <>
      <Mentions
        value={val}
        rows={3}
        ref={ref}
        maxLength={maxLength}
        placeholder={defaultPlaceholder || 'Add a Note'}
        disabled={disabled}
        id={id}
        data-qa-id={dataQaId}
        onChange={e => {
          setVal(e);
          debouncedOnChange(e);
        }}
        options={options}
      />
      <Validation touched={meta.touched} error={meta.error} warning={meta.warning} />
    </>
  );
};

export default NoteArea;

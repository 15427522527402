import { AxiosPromise } from 'axios';
import { ICounselingTask } from 'interfaces/redux/task-types/ICounselingTask';
import { TypedHttp } from 'services/typed-http';

export class TaskCounselingClient {
  static updateTasks = (tasks: unknown[], newAddendum?: string): AxiosPromise<unknown> => {
    const url = '/tasks/counseling';
    const payload = {
      tasks,
      newAddendum,
    };
    return TypedHttp.PatchPromise<{}, {}>(url, payload);
  };
}

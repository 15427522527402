import { Popover, Typography, Button, Switch } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import React, { useState } from 'react';
import { renderToggle } from 'components/form/field/redux-field';
import boolean from 'boolean';
import { TherapyUtil } from 'utils/therapy-util';
import { Field } from 'redux-form';
import ObsoleteNdcTooltip from '../../therapy/obsolete-tooltip';
import { styles } from '../patient-clinical-styles';
import MedicationForm from './medication-form';
import MedicationToggle from './medication-toggle';

import { GTD_SPECIALITY_TYPES } from '../../../../constants/index';

const EditableCell = props => {
  const {
    classes,
    fieldName,
    disabled,
    medication,
    displayInactiveTooltip,
    label,
    id,
    ...rest
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const value = medication?.[fieldName];
  // eslint-disable-next-line camelcase
  const isSpecialtyDrug = medication?.is_specialty_drug;
  const therapyExists = boolean(isSpecialtyDrug) && medication.therapy;
  const specialtyExists = therapyExists && medication.therapy.specialty_type;
  const specialtyTypes = GTD_SPECIALITY_TYPES.map(obj => obj.label);
  const specialtyCheck = specialtyExists
    ? specialtyTypes.some(specialtyType =>
        TherapyUtil.hasSpecialtyType(medication.therapy.specialty_type, specialtyType),
      )
    : false;

  const ButtonPopover = (
    <Button
      key={`${id}-button`}
      className={classes.medFormContainer}
      disabled={disabled}
      onClick={e => {
        if (!disabled) {
          setAnchorEl(e.currentTarget);
          e.stopPropagation();
          e.preventDefault();
        }
      }}
    >
      {displayInactiveTooltip && !medication?.is_active && specialtyCheck ? ( //eslint-disable-line
        <span className={classes.obsoleteNdc}>
          <span>{value}</span>
          <ObsoleteNdcTooltip />
        </span>
      ) : (
        <div>{value}</div>
      )}
      <Popover
        id={`medication-popover-${id}`}
        key={`${id}-popover`}
        classes={{
          paper: classes.popover,
        }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        onClick={e => {
          e.stopPropagation();
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography component="p" className={classes.medFormHeader}>
          {label}
        </Typography>
        <MedicationForm
          withConfirmation
          fieldName={fieldName}
          medication={medication}
          submitCallback={() => setAnchorEl(null)}
          initialValue={value}
          {...rest}
        />
      </Popover>
    </Button>
  );

  return fieldName === 'status_code' ? (
    <MedicationToggle medication={medication} disabled={disabled} />
  ) : (
    ButtonPopover
  );
};

export default withStyles(styles)(EditableCell);

import { ITask } from 'interfaces/redux/ITasks';
import { INT, TASK_INTERVENTIONS, TASK_DATA_COLLECT } from 'constants/index';
import { IIntPairs } from './types';

export class GroupedTaskTabUtil {
  static getAssociatedIntTask = (
    task: ITask,
    intPairs: IIntPairs[],
    tasksList: Record<string, ITask>,
  ): ITask | null => {
    if (intPairs.length) {
      for (const it of intPairs) {
        if (it.taskType === task.taskType && it.taskTypeId === task.id) {
          const tempTask = tasksList[`${INT}${it.intId}`];
          if (tempTask && Object.keys(tempTask).length) {
            return tempTask;
          }
        }
      }
    }
    return null;
  };
}

export const isValidJson = (possibleJson: string) => {
  try {
    JSON.parse(possibleJson);
    return true;
  } catch (e) {
    return false;
  }
};

export const arraysEqual = (arr1: number[], arr2: number[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = arr1.slice().sort((a, b) => a - b);
  const sortedArr2 = arr2.slice().sort((a, b) => a - b);
  /* eslint-disable-next-line no-plusplus */
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }
  return true;
};

export const shouldFetchDcProtocols = (
  taskId: number,
  mergeInterventionMapping: { wag_hash: string; ints: any[] }[],
  selectedTaskKeys: string[],
) => {
  const res: { fetchDcProtocols: boolean; tasksData: { taskId: number; therapyId: number }[] } = {
    fetchDcProtocols: false,
    tasksData: [],
  };
  if (mergeInterventionMapping.length) {
    for (const intMapping of mergeInterventionMapping) {
      /* eslint-disable-next-line no-plusplus */
      for (let i = 0; i < intMapping.ints.length; i++) {
        const intObject = intMapping.ints[i];
        const intData = Object.keys(intObject).reduce((acc, t) => {
          acc = acc.concat(intObject[t]);
          return acc;
        }, []);
        const possibleSelectedTasks = intData.map((t: any) => `${INT}${t.intId}`);
        if (
          selectedTaskKeys.length &&
          possibleSelectedTasks.some(t => selectedTaskKeys.includes(t)) &&
          intData.map((t: any) => t.linkedTaskId).includes(taskId)
        ) {
          res.fetchDcProtocols = true;
          res.tasksData = Array.from(
            new Set(
              intData.reduce((acc, it: { linkedTaskId: number; therapyId: number }) => {
                if (!acc.map(t => t.taskId).includes(it.linkedTaskId)) {
                  acc.push({
                    taskId: it.linkedTaskId,
                    therapyId: it.therapyId,
                  });
                }
                return acc;
              }, [] as { taskId: number; therapyId: number }[]),
            ),
          );
          return res;
        }
      }
    }
  }
  return res;
};

export const getClinicalItemsBasedOnTaskId = (
  taskId: number,
  mergeInterventionMapping: { wag_hash: string; ints: any[] }[],
) => {
  let intsData: any[] = [];
  if (mergeInterventionMapping?.length) {
    for (const intMapping of mergeInterventionMapping) {
      /* eslint-disable-next-line no-plusplus */
      for (let i = 0; i < intMapping.ints?.length; i++) {
        const intObject = intMapping.ints[i];
        const tempData = Object.keys(intObject).reduce((acc, t) => {
          for (const tempIntObj of intObject[t]) {
            acc.push({
              clinicalDataTypeId: parseInt(t, 10),
              taskId: tempIntObj.linkedTaskId,
              therapyId: tempIntObj.therapyId,
              intId: tempIntObj.intId,
            });
          }
          return acc;
        }, [] as { clinicalDataTypeId: number; taskId: number; therapyId: number; intId: number }[]);
        intsData = intsData.concat(tempData);
      }
    }
  }
  return intsData;
};

export const getClinicalItemsBasedOnIntMapping = (
  taskId: number,
  mergeInterventionMapping: { wag_hash: string; ints: any[] }[],
  selectedTaskKeys: string[],
) => {
  const res: {
    needToCheck: boolean;
    clinicalItemTaskPairs: {
      clinicalDataTypeId: number;
      taskId: number;
      therapyId: number;
      intId: number;
    }[];
  } = { needToCheck: false, clinicalItemTaskPairs: [] };
  if (mergeInterventionMapping?.length) {
    for (const intMapping of mergeInterventionMapping) {
      /* eslint-disable-next-line no-plusplus */
      for (let i = 0; i < intMapping.ints?.length; i++) {
        const intObject = intMapping.ints[i];
        const intData = Object.keys(intObject).reduce((acc, t) => {
          acc = acc.concat(intObject[t]);
          return acc;
        }, []);
        const possibleSelectedTasks = intData.map((t: any) => `${INT}${t.intId}`);
        if (
          selectedTaskKeys?.length &&
          possibleSelectedTasks.some(t => selectedTaskKeys.includes(t)) &&
          intData.map((t: any) => t.linkedTaskId).includes(taskId)
        ) {
          res.needToCheck = true;
          res.clinicalItemTaskPairs = Object.keys(intObject).reduce((acc, t) => {
            for (const tempIntObj of intObject[t]) {
              acc.push({
                clinicalDataTypeId: parseInt(t, 10),
                taskId: tempIntObj.linkedTaskId,
                therapyId: tempIntObj.therapyId,
                intId: tempIntObj.intId,
              });
            }
            return acc;
          }, [] as { clinicalDataTypeId: number; taskId: number; therapyId: number; intId: number }[]);
          return res;
        }
      }
    }
  }
  return res;
};

export const getResourceLinks = (taskId: number, links: any[]) => {
  return (
    links?.filter(
      it =>
        it.resource_one_type === TASK_DATA_COLLECT &&
        it.resource_one_id === taskId &&
        it.resource_two_type === TASK_INTERVENTIONS &&
        it.meta &&
        typeof it.meta === 'string' &&
        isValidJson(it.meta) &&
        JSON.parse(it.meta)?.clinical_data_type_id &&
        JSON.parse(it.meta)?.therapy_id,
    ) ?? []
  );
};

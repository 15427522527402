import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

import colors from 'lib/themes/colors.module.scss';

const { steelGrey, lightRed } = colors;

const styles = theme => {
  const { palette } = expandTheme(theme);

  return createStyles({
    button: {
      '&:hover': {
        backgroundColor: palette.primary.teal,
      },
      'backgroundColor': palette.primary.teal,
      'color': palette.primary.deepBlue,
      'width': '85%',
      'padding': 20,
      'fontSize': 16,
      'marginBottom': 6,
      'textTransform': 'initial',
      [`${theme.breakpoints.down('xl')} and (orientation: landscape)`]: {
        padding: 7,
      },
      [theme.breakpoints.down('xl')]: {
        width: '100%',
      },
    },
    dobInput: {
      '& input': {
        color: palette.primary.deepBlue,
        fontSize: 32,
        backgroundColor: `${palette.primary.trellisLightBlue}`,
        textAlign: 'center',
        borderRadius: 4,
        padding: '8px 12px',
        border: `1px solid ${palette.primary.deepBlue}`,
        height: 41,
        width: 210,
      },
      '& input:focus': {
        textAlign: 'left',
        padding: '8px 12px',
      },
      '& input:focus:not(& input[value=""])': {
        textAlign: 'center',
        padding: '8px 12px',
      },
      '& ::placeholder': {
        opacity: 1,
      },

      '& fieldset': {
        borderWidth: 0,
      },
    },

    dobInputError: {
      '& input': {
        color: lightRed,
        fontSize: 32,
        textAlign: 'center',
        backgroundColor: `${palette.primary.trellisLightBlue}`,
        borderRadius: 4,
        padding: '8px 0',
        border: `1px solid ${lightRed}`,
        height: 41,
      },
      '& .Mui-disabled, & ::placeholder': {
        'opacity': 1,
        '-webkit-text-fill-color': lightRed,
      },
      '& fieldset': {
        borderWidth: 0,
      },
    },
    link: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      'backgroundColor': 'transparent',
      'color': theme.palette.primary.deepBlue,
    },
    bordered: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      'backgroundColor': 'transparent',
      'color': theme.palette.primary.deepBlue,
      'border': '1px solid',
      'borderColor': theme.palette.primary.deepBlue,
    },
  });
};

export { styles };

import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

export const ShippingViewStyles = (theme: Theme) => {
  return createStyles({
    shippingContent: {
      marginLeft: theme.spacing(1),
    },
  });
};

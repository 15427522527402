export enum InterventionCategoryName {
  Patient = 'Patient',
  Prescriber = 'Prescriber',
  Other = 'Other',
}

export enum InterventionCategoryId {
  Patient = 1,
  Prescriber,
  Other,
}

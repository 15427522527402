import React from 'react';
import { Moment } from 'moment';

import { Controller } from 'react-hook-form';
import {
  renderDatePicker as RenderDatePicker,
  renderCalendarPicker as RenderCalendarPicker,
} from 'components/form/datepicker/datetime-picker';

import { IControlledFormField } from '../types';

interface IProps extends IControlledFormField {
  fullWidth?: boolean;
  defaultValue?: Moment | null | '';
  onChange?: (e: Moment) => void;
  minDate?: Date;
  autoComplete?: boolean;
}

export const ControlledDatePicker = (props: IProps): JSX.Element => {
  return (
    <Controller
      defaultValue={props.defaultValue}
      control={props.control}
      name={props.name}
      rules={props.validations || {}}
      render={(ctrlProps: any) => (
        <RenderDatePicker
          fullWidth
          label={props.label}
          input={{
            value: ctrlProps?.field?.value,
            onChange: props.onChange
              ? props.onChange
              : (event: React.ChangeEvent<HTMLInputElement>) => {
                  ctrlProps?.field?.onChange(event);
                },
            onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
              ctrlProps?.field?.onBlur(event);
            },
          }}
          disabled={props.disabled}
          meta={props.inputMetaData || {}}
          data-qa-id={props.qaId}
          minDate={props.minDate ?? null}
          autoComplete={props.autoComplete ? 'on' : 'off'}
        />
      )}
    />
  );
};

export const ControlledDatePickerCalendar = (props: IProps): JSX.Element => {
  return (
    <Controller
      defaultValue={props.defaultValue}
      control={props.control}
      name={props.name}
      rules={props.validations || {}}
      render={(ctrlProps: any) => (
        <RenderCalendarPicker
          fullWidth
          label={props.label}
          input={{
            value: ctrlProps?.field?.value,
            onChange: ctrlProps?.field?.onChange,
            onBlur: ctrlProps?.field?.onBlur,
          }}
          disabled={props.disabled}
          meta={props.inputMetaData || {}}
          data-qa-id={props.qaId}
        />
      )}
    />
  );
};

import { MobilePhoneSmsEnum } from 'constants/enums';

export enum PhoneUsage {
  Mobile = 'Mobile',
  Home = 'Home',
  Business = 'Business',
}

export interface IPhone {
  key: number;
  sms?: MobilePhoneSmsEnum;
  rank: number;
  use: PhoneUsage;
  value: string;
  period: string;
  fromTime?: string;
  toTime?: string;
  patient_choice?: boolean;
  is_opt_in_sent?: boolean;
  sms_stop_date?: string;
  deleted?: boolean;
}

/* eslint-disable arrow-body-style */
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Route } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect, useSelector } from 'react-redux';
import SideMenuComponent from 'containers/patient/side-bar/side-menu';
import SearchSideBar from 'containers/patient/side-bar/search-side-bar/search-side-bar';
import TaskSideBar from 'containers/patient/side-bar/task-side-bar/task-side-bar';
import PatientSideBar from 'containers/patient/side-bar/patient-side-bar/patient-side-bar';
import ReportSideBar from 'containers/patient/side-bar/report-side-bar/report-side-bar';
import ScheduleSideBar from 'containers/patient/side-bar/schedule-side-bar/schedule-side-bar';
import { fetchLookups, fetchServiceGroups } from 'actions/action-lookups';
import { loadSettings } from 'slices/settings-slice';
import PatientOverview from 'containers/patient/patient-overview/patient-overview';
import queryString from 'query-string';
import {
  TASK_SIDEBAR,
  PATIENT_SIDEBAR,
  SEARCH_SIDEBAR,
  NO_SIDEBAR,
  REPORT_SIDEBAR,
  SCHEDULE_SIDEBAR,
} from 'constants/index';
import { fetchEmrs } from 'actions/action-filters';
import FloatingActionButton from 'components/floating-action-button/floating-action-button';
import { FloatingPhoneButton } from 'components/floating-phone-button/floating-phone-button';
import { setActiveSidebar } from 'actions/action-view';
import { TrellisLogo } from 'components/icons/icons';
import { styles } from '../nav-styles';
import ClinicalComponent from './clinical';
import DemographicsComponent from './demographics';
import TherapiesComponent from './therapies';
import ChecklistComponent from './checklist';
import FinancialsComponent from './financials';
import DocumentsComponent from './documents';
import CreatePatientComponent from './create-patient';
import TasksComponent from './tasks';
import Footer from '../../footer';

const renderSideBar = sidebar => {
  switch (sidebar) {
    case PATIENT_SIDEBAR:
      return <PatientSideBar />;
    case SEARCH_SIDEBAR:
      return <SearchSideBar />;
    case TASK_SIDEBAR:
      return <TaskSideBar />;
    case SCHEDULE_SIDEBAR:
      return <ScheduleSideBar />;
    case REPORT_SIDEBAR:
      return <ReportSideBar />;
    case NO_SIDEBAR:
      return null;
    default:
      return null;
  }
};

const mapStateToProps = state => {
  return {
    statuses: state.therapyStatuses,
    patient: state.patient,
    sidebar: state.view.sidebar,
    sidebarType: state.sidebarType,
    isLeftDrawerOpen: state.view.leftDrawer,
  };
};

const mapDispatchToProps = dispatch => ({
  onLoad: () => {
    dispatch(fetchLookups());
    dispatch(fetchEmrs());
    dispatch(fetchServiceGroups());
    dispatch(loadSettings());
  },
  setActiveSidebar: sidebar => dispatch(setActiveSidebar(sidebar)),
});

function Patients(props) {
  const {
    classes,
    location,
    match: { path },
    sidebarType,
    onLoad,
    isLeftDrawerOpen,
    patient,
  } = props;

  const sidebarTaskType = useSelector(state => state.sidebarTaskType);
  const selectedPatientId = useSelector(state => state.selectedPatientId);

  const parsed = queryString.parse(location.search);
  let additionalClass = null;
  if (!parsed.sidebar) {
    additionalClass = classes.patientContentFullWidth;
  }
  let taskListClass = null;
  if (isLeftDrawerOpen) {
    taskListClass = classes.patientContentCollapsedTaskList;
  }
  const shouldRenderSideMenu = Boolean(sidebarType) && sidebarType !== NO_SIDEBAR;

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div>
      <SideMenuComponent show={shouldRenderSideMenu} />
      {renderSideBar(sidebarType)}
      <div>
        <Route path={`${path}/new`} component={CreatePatientComponent} />
      </div>
      {selectedPatientId ? (
        <div className={classNames(classes.patientContent, additionalClass, taskListClass)}>
          <Route path={`${path}/:patientId/:patientTab`} component={PatientOverview} />
          <div
            className={
              patient.needs_reconciliation
                ? classes.patientTabsContainerReconciliation
                : classes.patientTabsContainer
            }
          >
            <Route
              path={`${path}/:patientId/therapies/:therapyId?/:taskType?/:taskId?`}
              component={TherapiesComponent}
            />
            <Route
              path={`${path}/:patientId/medications/:therapyId?/:taskType?/:taskId?`}
              component={TherapiesComponent}
            />
            <Route
              path={`${path}/:patientId/tasks/:taskType?/:taskId?`}
              component={TasksComponent}
            />
            <Route path={`${path}/:patientId/checklist`} component={ChecklistComponent} />
            <Route path={`${path}/:patientId/clinical`} component={ClinicalComponent} />
            <Route path={`${path}/:patientId/demographics`} component={DemographicsComponent} />
            <Route path={`${path}/:patientId/financials`} component={FinancialsComponent} />
            <Route path={`${path}/:patientId/documents`} component={DocumentsComponent} />
          </div>
        </div>
      ) : (
        <Grid
          className={classNames(
            classes.patientContent,
            additionalClass,
            taskListClass,
            classes.noPatientLoadedClass,
          )}
          container
        >
          <Grid item>
            <Grid container justifyContent="center" alignItems="center">
              <TrellisLogo height={135} width={135} className={classes.halfOpacity} />
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <Typography className={classes.tasksAtAGlanceTextLarge}>
                {`Welcome, ${window.USER ? window.USER.username : 'Loading ...'}`}
              </Typography>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <Typography className={classes.tasksAtAGlanceText}>
                Select the queue and patient to start
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      <FloatingActionButton />
      <FloatingPhoneButton />
      <Footer />
    </div>
  );
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(Patients);

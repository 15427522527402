import classnames from 'classnames';
import React from 'react';
import Typography from '@mui/material/Typography';
import { fetchLookups } from 'actions/action-lookups';
import { Grid } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { RouteComponentProps } from 'react-router-dom';
import { ROUTES } from 'constants/index';
import { useDispatch } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { buildQaId } from 'utils/build-qa-id';
import { windowFeatureIsEnabled } from 'config/window-features';
import { styles } from './application-manager.styles';
import { IMenuItem, ISubsectionConfig } from './menu-structure';
import { getApplicationManagerUrlParts, sendToDefault } from './utils';
import { UserUtils } from '../../../utils/user-utils';

const qaIdBuilder = buildQaId('application-manager', '.');

interface IApplicationManagerProps extends WithStyles<typeof styles> {
  menu: Record<string, ISubsectionConfig>;
}

const ApplicationManagerComponent = (
  props: IApplicationManagerProps & RouteComponentProps,
): JSX.Element => {
  const { classes } = props;
  const dispatch = useDispatch();

  // #region useState
  const [newSelectedItem, setNewSelectedItem] = React.useState<
    { levelSelected: string; selectedItem: IMenuItem } | undefined
  >(undefined);

  const [menuState, setMenuState] = React.useState<Record<string, ISubsectionConfig>>(props.menu);
  // #endregion

  // #region useEffect
  React.useEffect(() => {
    dispatch(fetchLookups());
  }, []);

  React.useEffect(() => {
    const pathName = props.location.pathname;
    const theMatches = getApplicationManagerUrlParts(pathName);
    if (!theMatches) {
      sendToDefault(props.history.push);
    } else {
      const structurePart1 = menuState[theMatches.firstPart];
      if (!structurePart1) {
        sendToDefault(props.history.push);
      } else {
        const matchingItem = structurePart1.items?.find(
          x =>
            x.routeSlug === theMatches.secondPart ||
            (x.items && x.items.find(y => y.routeSlug === theMatches.secondPart)),
        );
        if (!matchingItem) {
          sendToDefault(props.history.push);
        } else if (matchingItem && matchingItem.component) {
          setNewSelectedItem({ levelSelected: theMatches.firstPart, selectedItem: matchingItem });
        } else if (matchingItem && matchingItem.items) {
          const matchingSubItem = matchingItem.items?.find(
            x => x.routeSlug === theMatches.secondPart,
          );
          if (matchingSubItem) {
            setNewSelectedItem({
              levelSelected: theMatches.firstPart,
              selectedItem: matchingSubItem,
            });
          }
        }

        // make sure the section is expanded correctly
        setMenuState(prevState => {
          return {
            ...prevState,
            [theMatches.firstPart]: { ...prevState[theMatches.firstPart], expanded: true },
          };
        });
      }
    }
  }, [props.location]);
  // #endregion

  // #region helpers
  const pushRouteToHistory = (parentKey: string, routeSlug: string): void => {
    props.history.push(`${ROUTES.APPLICATION_MANAGER}/${parentKey}/${routeSlug}`);
  };

  const onParentClick = (parentKey: string, section: ISubsectionConfig): void => {
    if (section.items.length > 1 || section.singleOpt === true) {
      setMenuState(prevState => {
        return {
          ...prevState,
          [parentKey]: { ...prevState[parentKey], expanded: !prevState[parentKey].expanded },
        };
      });
    } else {
      pushRouteToHistory(parentKey, section.items[0].routeSlug);
    }
  };
  // #endregion

  // #region renders
  const renderChildren = (
    parentKey: string,
    menuItems: IMenuItem[],
    isSubItems = false,
  ): JSX.Element | null => {
    if (
      (menuState[parentKey].expanded !== true && menuState[parentKey].singleOpt === true) ||
      ((menuItems.length === 1 || menuState[parentKey].expanded !== true) &&
        menuState[parentKey].singleOpt === undefined)
    ) {
      return null;
    }
    return (
      <>
        {menuItems
          .filter(item => item?.hide?.() !== true)
          .map<JSX.Element>(menuItem => {
            const selected =
              newSelectedItem?.levelSelected === parentKey &&
              newSelectedItem?.selectedItem?.routeSlug === menuItem.routeSlug;
            const isSubMenu = menuItem.items && menuItem.items.length > 0;

            if (isSubMenu) {
              return (
                <>
                  <Grid
                    key={menuItem.title}
                    item
                    className={classnames(
                      classes.applicationManagerItem,
                      classes.applicationManagerChildHeader,
                    )}
                  >
                    <Typography data-qa-id={qaIdBuilder(`child.${menuItem.title}`)}>
                      {menuItem.title}
                    </Typography>
                  </Grid>
                  {renderChildren(parentKey, menuItem.items as IMenuItem[], true)}
                </>
              );
            }

            return (
              <Grid
                key={menuItem.title}
                item
                className={classnames(classes.applicationManagerItem, {
                  [classes.applicationManagerSelectedItem]: selected,
                  [classes.applicationManagerNonSelectedItem]: !selected,
                  [classes.applicationManagerSubItem]: isSubItems,
                })}
                onClick={() => {
                  if (!selected) {
                    pushRouteToHistory(parentKey, menuItem.routeSlug);
                  }
                }}
              >
                <Typography data-qa-id={qaIdBuilder(`child.${menuItem.title}`)}>
                  {menuItem.title}
                </Typography>
              </Grid>
            );
          })}
      </>
    );
  };

  const renderParent = (parentKey: string, section: ISubsectionConfig): JSX.Element | null => {
    if (!section.items.length) {
      return null;
    }

    const hasMultipleChildren = section.items.length > 1 || section.singleOpt == true;

    if (!hasMultipleChildren && section.items[0]?.hide?.() === true) {
      return null;
    }

    const parentSelected =
      !hasMultipleChildren &&
      newSelectedItem?.levelSelected === parentKey &&
      newSelectedItem?.selectedItem.routeSlug === section.items[0].routeSlug;

    return (
      <React.Fragment key={parentKey}>
        <Grid
          item
          className={classnames(classes.applicationManagerSectionHeaderGrid, {
            [classes.applicationManagerTopLevelSelectedItem]: parentSelected,
          })}
          onClick={() => onParentClick(parentKey, section)}
        >
          <Grid container justifyContent="space-between">
            <Grid>
              <Typography
                className={classes.applicationManagerSectionHeaderTypography}
                data-qa-id={qaIdBuilder(section.title)}
              >
                {hasMultipleChildren ? (
                  <ExpandMoreIcon
                    className={classnames({
                      [classes.expandOpen]: menuState[parentKey].expanded === true,
                    })}
                  />
                ) : null}
                {section.title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
  // #endregion

  return (
    <div>
      <Grid container>
        {/* Left Side */}
        <Grid container item xs={2} className={classes.applicationManagerSelector}>
          <Grid container direction="column">
            {Object.entries(menuState).map(([key, value]) => {
              return (
                (!value.featureFlag || windowFeatureIsEnabled(value.featureFlag)) &&
                (!value.permission || UserUtils.userIsPermitted(value.permission)) && (
                  <React.Fragment key={key}>
                    {renderParent(key, value)}
                    {renderChildren(
                      key,
                      value.items.filter(
                        x => x.featureFlag === undefined || windowFeatureIsEnabled(x.featureFlag),
                      ),
                    )}
                  </React.Fragment>
                )
              );
            })}
          </Grid>
        </Grid>

        {/* Right Side */}
        <Grid container item xs={10} className={classes.rightSideContainer}>
          <Grid container direction="column">
            <Grid item>{newSelectedItem?.selectedItem?.component}</Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(ApplicationManagerComponent);

import React from 'react';

import {
  Modal,
  Grid,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';

import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { current } from '@reduxjs/toolkit';

import { getModalStyle } from 'services/utils/styles-service';
import { renderCheckboxInline as RenderCheckboxInline } from 'components/form/field/redux-field';

import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import Divider from 'components/divider';
import { ICounselingTask } from 'interfaces/redux/task-types/ICounselingTask';
import { ICollectedItem } from 'interfaces/redux/task-types/IDataCollectTask';
import TruncatedLabel from 'components/truncated-label';
import { IPatientReviewData } from '../../chart-review/interfaces/IProps';
import DataCollectSection from '../../chart-review/components/data-collect-section';
import { styles } from './add-lab-values-modal.styles';
import { IObjectiveDataCollect } from '../interfaces/IProps';

interface IProps extends WithStyles<typeof styles> {
  dataCollects: IObjectiveDataCollect[];
  open: boolean;
  initialSelectedLabs: ISelectableLab[];
  onSaveLabValues(labValues: ISelectableLab[]): void;
  handleCloseModal?(): void;
  patientReviewData: IPatientReviewData;
  taskQty?: number;
  currentSelectedTasks?: ICounselingTask[];
}

export interface ISelectableLab extends ICollectedItem {
  taskId: number;
  selected: boolean;
}

export type SelectableLabMap = Record<string, ISelectableLab>;

const buildSelectableLabs = (
  objectiveDataCollects: IObjectiveDataCollect[],
  selectedLabs: ISelectableLab[],
): SelectableLabMap => {
  const labsMap: SelectableLabMap = {};
  objectiveDataCollects?.forEach(dataCollect => {
    dataCollect?.labs?.forEach(lab => {
      labsMap[`${dataCollect.taskId}_${lab.id}`] = {
        ...lab,
        taskId: dataCollect.taskId,
        selected: selectedLabs.some(selectedLab => selectedLab.id === lab.id),
      };
    });
  });
  return labsMap;
};

const buildLabValueKey = (
  objectiveDataCollect: IObjectiveDataCollect,
  lab: ICollectedItem,
): string => `${objectiveDataCollect.taskId}_${lab.id}`;

const AddLabValuesModal: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { classes, patientReviewData, taskQty, currentSelectedTasks } = props;
  const reviewDataCollects = patientReviewData?.dataCollects || [];

  const [selectableLabValues, setSelectableLabValues] = React.useState<SelectableLabMap>(
    buildSelectableLabs(props.dataCollects, props.initialSelectedLabs),
  );

  const [dataCollectsFromDataCollectSection, setDataCollectsFromDataCollectSection] =
    React.useState<IObjectiveDataCollect[]>(props.dataCollects);

  React.useEffect(() => {
    if (props.open) {
      setSelectableLabValues(buildSelectableLabs(props.dataCollects, props.initialSelectedLabs));
    }
  }, [props.open]);

  React.useEffect(() => {
    if (props.open && reviewDataCollects && reviewDataCollects.length > 0) {
      setSelectableLabValues(
        buildSelectableLabs(dataCollectsFromDataCollectSection, props.initialSelectedLabs),
      );
    }
  }, dataCollectsFromDataCollectSection);

  const areAllValuesSelectedByTaskId = (taskId: number): boolean =>
    Object.values(selectableLabValues)
      .filter(selectableLabValue => selectableLabValue.taskId === taskId)
      .every(selectableLabValues => selectableLabValues.selected);

  const toggleAllValuesByTaskId = (taskId: number) => () => {
    const modifiedSelectableLabValues: SelectableLabMap = {};
    Object.keys(selectableLabValues).forEach(labValueKey => {
      const selectedValue = !areAllValuesSelectedByTaskId(taskId);
      if (labValueKey.startsWith(taskId.toString())) {
        modifiedSelectableLabValues[labValueKey] = {
          ...selectableLabValues[labValueKey],
          selected: selectedValue,
        };
      }
    });

    setSelectableLabValues({
      ...selectableLabValues,
      ...modifiedSelectableLabValues,
    });
  };

  const onSelectLabValue = (labValueKey: string) => () => {
    setSelectableLabValues({
      ...selectableLabValues,
      [labValueKey]: {
        ...selectableLabValues[labValueKey],
        selected: !selectableLabValues[labValueKey].selected,
      },
    });
  };

  const getSelectedLabs = (): ISelectableLab[] => {
    return Object.values(selectableLabValues).filter(
      selectableLabValue => selectableLabValue.selected,
    );
  };

  const handleSaveSelectedLabs = (): void => {
    props.onSaveLabValues(getSelectedLabs());
    props.handleCloseModal?.();
  };

  const setDataCollectsFromChild = (dataFromChild: any[]) => {
    const tableVals: IObjectiveDataCollect[] = dataFromChild.map(d => ({
      taskId: d?.task?.id,
      drugName: d?.therapyDrugName,
      reviewedDate: d?.lastCompletedDate,
      labs: d?.collectedItems,
    }));
    setDataCollectsFromDataCollectSection(tableVals);
  };

  return (
    <Modal open={props.open} onClose={props.handleCloseModal}>
      <Grid container style={getModalStyle()} spacing={3} className={classes.modalContentWrapper}>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.modalTitle}>
            Add Lab Values to Objective
          </Typography>
          <Divider />
        </Grid>
        <DataCollectSection
          dataCollects={reviewDataCollects}
          taskQty={taskQty}
          currentSelectedTasks={currentSelectedTasks}
          sendDataCollectsToParent={setDataCollectsFromChild}
          displayFilterOnly
          useLimitedTherapyList
          includeRS
        />
        <Grid item xs={12}>
          <Grid container className={classes.scrollingColumn}>
            {dataCollectsFromDataCollectSection.map(dataCollect => (
              <Grid
                key={`${dataCollect.taskId}_lab_values_table`}
                item
                xs={12}
                className={classes.tableWrapper}
              >
                {/* @ts-ignore */}
                <RenderCheckboxInline
                  input={{
                    name: `${dataCollect.taskId}_toggle_all`,
                    value: areAllValuesSelectedByTaskId(dataCollect.taskId),
                    onChange: toggleAllValuesByTaskId(dataCollect.taskId),
                  }}
                  classes={{
                    checkBoxInline: classes.checkboxWrapper,
                  }}
                />
                <Typography variant="subtitle2" className={classes.checkboxWrapper}>
                  {`${dataCollect.drugName} ${dataCollect.reviewedDate}`}{' '}
                </Typography>
                <Paper elevation={0} className={classes.labsTableWrapper}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left" />
                          <TableCell align="left">Data Type</TableCell>
                          <TableCell align="center">Required?</TableCell>
                          <TableCell align="center">Assessment Date</TableCell>
                          <TableCell align="center">Value</TableCell>
                          <TableCell align="center">Reference</TableCell>
                          <TableCell align="center">Not Able to Complete</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataCollect?.labs?.map(lab => (
                          <TableRow>
                            <TableCell style={{ maxWidth: 20 }} align="left">
                              {/* @ts-ignore */}
                              <RenderCheckboxInline
                                input={{
                                  name: buildLabValueKey(dataCollect, lab),
                                  value:
                                    selectableLabValues[buildLabValueKey(dataCollect, lab)]
                                      ?.selected,
                                  onChange: onSelectLabValue(buildLabValueKey(dataCollect, lab)),
                                }}
                                classes={{
                                  checkBoxInline: classes.checkboxWrapper,
                                }}
                              />
                            </TableCell>
                            <TableCell style={{ maxWidth: 100 }} align="left">
                              {lab.dataTypeName}
                            </TableCell>
                            <TableCell align="center">{lab.required}</TableCell>
                            <TableCell align="center">{lab.assessmentDate}</TableCell>
                            <TableCell align="center">{lab.value}</TableCell>
                            <TableCell align="center">{lab.reference}</TableCell>
                            <TableCell align="center">{lab.cannotCompleteReason}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider />
          <ConfirmationPanel
            cancelButtonName="cancel"
            submitButtonName="submit"
            hideSubmit={false}
            hideCancel={false}
            submitButtonText="Save Lab Values"
            handleCancel={props.handleCloseModal}
            handleSubmit={handleSaveSelectedLabs}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default withStyles(styles)(AddLabValuesModal);

import React, { useState } from 'react';

import {
  Grid,
  Box,
  Button,
  Popover,
  Typography,
  TextField,
  FormHelperText,
  FormControl,
} from '@mui/material';
import EditableCell from '../../table/editable-cell';

import useStyles from './styles';

export default function Comment(props) {
  const { mainButton, rootContainer } = useStyles();

  const [messageFailed, setMessageFailed] = useState(null);
  const {
    anchorEl,
    onClose,
    open,
    id: popoverId,
    onSave,
    values,
    headerTitle,
    isEditable,
    selectedValue,
    onSelectedValueChange,
    actionComment,
    onActionCommentChange,
    editableType,
    dataType,
  } = props;

  const handleOnSave = () => {
    if (actionComment) {
      onSave();
    } else {
      setMessageFailed('Please leave a comment to complete the operation');
    }
  };

  return (
    <Popover
      id={popoverId}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      onClick={e => {
        e.stopPropagation();
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Grid container className={rootContainer}>
        {isEditable && (
          <>
            <EditableCell
              selectedValue={selectedValue}
              onSelectedValueChange={onSelectedValueChange}
              values={values}
              editableType={editableType}
              dataType={dataType}
              headerTitle={headerTitle}
            />
            <Box pt={2} />
          </>
        )}
        <Typography variant="caption">Add a comment *</Typography>
        <Box pt={2} />
        <FormControl required>
          <TextField
            variant="standard"
            id="outlined-multiline-static"
            label="Why are you changing this status?"
            multiline
            rows={4}
            value={actionComment}
            onChange={onActionCommentChange}
          />

          <FormHelperText error>{messageFailed}</FormHelperText>
        </FormControl>
        <Box pt={2} />
        <Grid container justifyContent="flex-end">
          <Button className={mainButton} onClick={onClose}>
            Cancel
          </Button>
          <Button className={mainButton} variant="contained" color="primary" onClick={handleOnSave}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
}

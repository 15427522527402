import React from 'react';
import cx from 'classnames';
import { Button } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  button: {
    '&:hover': {
      backgroundColor: theme.palette.primary.teal,
    },
    'backgroundColor': theme.palette.primary.teal,
    'color': theme.palette.primary.white,
    'width': '85%',
    'padding': 20,
    'fontSize': 16,
    'marginBottom': 6,
    'textTransform': 'initial',
    [`${theme.breakpoints.down('xl')} and (orientation: landscape)`]: {
      padding: 7,
    },
    [theme.breakpoints.down('xl')]: {
      width: '100%',
    },
  },
  link: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    'backgroundColor': 'transparent',
    'color': theme.palette.primary.deepBlue,
  },
  bordered: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    'backgroundColor': 'transparent',
    'color': theme.palette.primary.deepBlue,
    'border': '1px solid',
    'borderColor': theme.palette.primary.deepBlue,
  },
}));

export default ({ variant = 'submit', className, ...props }) => {
  const classes = useStyles();
  return <Button className={cx(className, classes.button, classes[variant])} {...props} />;
};

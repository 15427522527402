import React from 'react';

import { Controller } from 'react-hook-form';

import { renderCheckbox as Checkbox } from 'components/form/field/redux-field';

import { IControlledFormField } from '../types';

interface IProps<TValue> extends IControlledFormField {
  defaultValue: TValue | null;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ControlledCheckbox = (props: IProps<boolean>): JSX.Element => {
  return (
    <Controller
      defaultValue={props.defaultValue}
      control={props.control}
      name={props.name}
      rules={props.validations || {}}
      render={(ctrlProps: any) => (
        <Checkbox
          label={props.label}
          input={{
            value: ctrlProps?.field?.value || ctrlProps?.value,
            onChange: props.onChange
              ? props.onChange
              : (event: React.ChangeEvent<HTMLInputElement>) => {
                  ctrlProps?.field?.onChange(event);
                },
            onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
              ctrlProps?.field?.onBlur(event);
            },
          }}
          disabled={props.disabled}
          meta={props.inputMetaData || {}}
          qaId={props.qaId}
        />
      )}
    />
  );
};

import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import classNames from 'classnames';
import { styles } from './report-styles';

function Metrics(props) {
  const { classes, numbers, clickReportHandler, reportId } = props;

  return (
    <Grid container className={classes.metricContainer}>
      {numbers.map(number =>
        number.hasId ? (
          <Grid
            item
            xs
            key={number.label}
            onClick={() => clickReportHandler(reportId, number.label, number.ids, { key: 'Total' })}
          >
            <Typography
              component="span"
              className={classes.number}
              color="primary"
              gutterBottom
              id={`report_metrics_count_${number.reportItemIndex}`}
            >
              {number.count}
            </Typography>
            <Typography
              component="span"
              className={classNames(classes.number, classes.percentage)}
              color="primary"
              gutterBottom
              id={`report_metrics_percentage_${number.reportItemIndex}`}
            >
              {`${number.percentage}%`}
            </Typography>
            <Typography
              className={classes.title}
              color="primary"
              gutterBottom
              id={`report_metrics_label_${number.reportItemIndex}`}
            >
              {number.label}
            </Typography>
          </Grid>
        ) : (
          <Grid
            item
            xs
            key={number.label}
            onClick={() =>
              clickReportHandler(reportId, number.label, number.ids, { key: number.key })
            }
          >
            <Typography component="span" className={classes.number} color="primary" gutterBottom>
              {number.count}
            </Typography>
            <Typography
              component="span"
              className={classNames(classes.number, classes.percentage)}
              color="primary"
              gutterBottom
            >
              {`${number.percentage}%`}
            </Typography>
            <Typography className={classes.title} color="primary" gutterBottom>
              {number.label}
            </Typography>
          </Grid>
        ),
      )}
    </Grid>
  );
}

export default withStyles(styles, { withTheme: true })(Metrics);

import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import cx from 'classnames';
import { styles } from './message-string-styles';

interface IMessageStringProps extends WithStyles<typeof styles> {
  label: string;
  value?: string;
  scrollOverflow?: boolean;
  children?: React.ReactNode;
}

const MessageString: React.FC<IMessageStringProps> = (
  props: React.PropsWithChildren<IMessageStringProps>,
): JSX.Element => {
  const { classes } = props;

  const boxClass = cx(classes.textBox, {
    [classes.overflowX]: props.scrollOverflow,
  });

  return (
    <Grid item xs={12} className={classes.gridRow}>
      <Typography className={classes.label}>{props.label}</Typography>
      <Box className={boxClass}>{props.children || props.value || '-'}</Box>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(MessageString);

import React, { useState, useEffect } from 'react';
import withStyles from '@mui/styles/withStyles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Switch,
} from '@mui/material';
import compose from 'recompose/compose';
import { useSelector } from 'react-redux';
import { getFilteredDocuments, getDocumentsForResource } from 'services/utils/document-service';
import { noteTagTypes } from 'constants/lists';
import { usePatient } from 'hooks/usePatient';
import { styles } from './documents-styles';
import DocumentsFilter from './documents-filter';
import DocumentListRow from './document-list-row';

const DocumentsList = props => {
  const { classes } = props;

  const { labels, tags } = useSelector(state => state.uploadedDocuments);
  const documents = useSelector(state => state.uploadedDocuments.documents) || [];
  const customerId = useSelector(state => state.filters.selectedCustomerId);
  const patientId = useSelector(state => state.selectedPatientId);
  const patient = useSelector(state => state.patient);
  const isLoading = useSelector(state => state.uploadedDocuments.loading);

  const [filteredDocuments, setFilteredDocuments] = useState(documents);
  const [documentsFilter, setDocumentsFilter] = useState({
    showArchived: false,
  });
  const [showPinned, setShowPinned] = useState(false);

  const tagType = noteTagTypes.find(x => x.label === 'Patient');
  const patientDocuments = getDocumentsForResource(
    documents,
    [{ resourceId: patientId, tagTypeId: tagType.value }],
    1,
  );
  const { patientFullName } = usePatient(patient);

  const sortDocumentsWhenPinned = tempDocs => {
    const pinnedDocs = tempDocs.filter(t => t.is_pinned === 1);
    const unPinnedDocs = tempDocs.filter(t => t.is_pinned === 0);
    if (pinnedDocs.length && unPinnedDocs.length) {
      return pinnedDocs.concat(getFilteredDocuments(unPinnedDocs, documentsFilter));
    }
    return tempDocs.sort((a, b) => (a.is_pinned < b.is_pinned ? 1 : -1));
  };

  useEffect(() => {
    const tempDocs = getFilteredDocuments(documents, documentsFilter);
    const isAnyDocumentPinned = tempDocs.find(t => t.is_pinned === 1);
    setShowPinned(!!isAnyDocumentPinned);
    setFilteredDocuments(
      tempDocs && tempDocs.length && !!isAnyDocumentPinned
        ? sortDocumentsWhenPinned(tempDocs)
        : tempDocs,
    );
  }, [documents]);

  const handleDocumentsFilter = documentFilterUpdate => {
    setDocumentsFilter(documentFilterUpdate);
    setFilteredDocuments(getFilteredDocuments(documents, documentFilterUpdate));
  };

  return !isLoading ? (
    <>
      <DocumentsFilter
        handleDocumentsFilter={handleDocumentsFilter}
        documentsCount={filteredDocuments.length}
        labels={labels}
        tags={tags}
        resourceId={patientId}
        tagTypeId={tagType.value}
        tagTypeLabel={tagType.displayLabel}
        drugName={patientFullName}
        documents={patientDocuments}
      />
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              <h4 className={classes.tableHeader}>Document Name</h4>
            </TableCell>
            <TableCell className={classes.labelHeader}>
              <h4 className={classes.tableHeader}>Document Type</h4>
            </TableCell>
            <TableCell>
              <h4 className={classes.tableHeader}>Upload Date</h4>
            </TableCell>
            <TableCell className={classes.noteHeader} />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredDocuments.map(document => (
            <DocumentListRow
              document={document}
              customerId={customerId}
              patientId={patientId}
              key={document.id}
              showPinned={showPinned}
            />
          ))}
        </TableBody>
      </Table>
    </>
  ) : (
    <Typography className={classes.loadingText} variant="caption">
      Loading...
    </Typography>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(DocumentsList);

/* eslint-disable max-len */
/* eslint-disable react/jsx-wrap-multilines */
import React, { Component } from 'react';
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  filterTasksByUniqueTherapy,
  filterTasksByUniqueTherapyAndFillCycle,
  findTaskInTasks,
} from 'services/utils/task-service';
import { combineObjects, buildObjectForJson } from 'helpers/misc';
import { logEvent } from 'services/utils/analytics';
import { TaskStatusFactory } from 'factories/task-status-factory/task-status-factory';
import { convertMapToList } from 'reducers/helper-reducer';
import { Tasks } from 'constants/GA';
import SelectFileForm from 'components/document/file-forms/select-file-form';
import { change, formValueSelector } from 'redux-form';
import {
  getFileExtension,
  acceptedFileExtensions,
  fileTypeErrorCaption,
  fileTypeError,
} from 'services/utils/document-service';
import { noteTagTypes } from 'constants/lists';
import { definedBoolean } from 'components/form/validation/validation';
import { updateDocumentBar } from 'actions/action-uploaded-documents';
import { uploadFile } from 'services/utils/upload-document-service';
import PendingDocument from 'components/document/pending-document';
import ConfirmationDialogPanel from 'components/form/confirmation/confirmation-dialog-panel';
import {
  UPLOAD_DOC_FILENAME_FIELD,
  UPLOAD_DOCUMENT_FORM,
  UPLOAD_DOC_FILENOTE_FIELD,
  UPLOAD_DOC_FILETYPE_FIELD,
} from 'constants/index';
import FileSelectedForm from 'components/document/file-forms/file-selected-form';
import { styles } from 'components/dynamic-form/dynamic-form-styles';
import { changeStatusToNextLogical, combineTasks } from 'components/dynamic-form/helpers';
import { FillCoordinationDescription } from 'models/tasks/index';

class RenderDocumentUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {},
      deliveryValues: {},
      valid: {},
      bundledDelivery: true,
      pendingDocument: undefined,
      uploadedDocuments: [],
      uploadProcessing: false,
      pendingDocumentLoss: false,
      lostData: [],
      dialogCancel: undefined,
      dialogContinue: undefined,
      fileErrorMessage: undefined,
      fileErrorCaption: undefined,
      generateDocumentAfterSave: false,
    };
    this.fileSelected = this.fileSelected.bind(this);
    this.fileRejected = this.fileRejected.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line
    const { taskIds } = this.props;
    this.onTasksChange(taskIds, taskIds, []);
  }

  componentDidUpdate(prevProps, prevState) {
    const { taskIds, fileFormName, taskIsSubmitting } = this.props;
    if (prevProps.taskIds !== taskIds) {
      const addedTasks = taskIds.filter(id => prevProps.taskIds.find(oldId => oldId === id));
      const removedTasks = prevProps.taskIds.filter(oldId => taskIds.find(id => oldId === id));
      this.onTasksChange(taskIds, addedTasks, removedTasks);
    }

    if (prevState.pendingDocument && prevState.pendingDocument.fileName !== fileFormName) {
      this.onPendingDocumentFileNameChange(fileFormName);
    }
    if (prevProps.taskIsSubmitting !== taskIsSubmitting && prevProps.taskIsSubmitting === false) {
      if (taskIsSubmitting) {
        this.handleSubmit(true);
      }
    }
  }

  onPendingDocumentFileNameChange(newFileName) {
    this.setState(prevState => ({
      pendingDocument: {
        ...prevState.pendingDocument,
        ...{ fileName: newFileName },
      },
    }));
  }

  onTasksChange(newTaskIds) {
    const { bundledDelivery } = this.state;
    // combine delivery
    if (bundledDelivery) {
      this.setState(prevState => {
        const values = prevState.deliveryValues;
        const valuesAsList = values ? Object.keys(values).map(key => values[key]) : null;
        const masterObject = combineObjects(...valuesAsList);
        const newDeliveryValues = newTaskIds
          ? newTaskIds.reduce((acc, taskId) => {
              acc[taskId] = { ...masterObject, id: taskId };
              return acc;
            }, {})
          : [];
        return {
          deliveryValues: newDeliveryValues,
        };
      });
    }
  }

  getTaskTypeName(task) {
    const { taskCounts } = this.props;
    return (taskCounts[task.taskType] || {}).task_description || '';
  }

  getTherapyName(task) {
    const { therapies } = this.props;
    if (task && therapies) {
      const matchingTherapy = therapies[task.therapy_id];
      if (matchingTherapy) {
        return matchingTherapy.drug_name;
      }
    }
    return undefined;
  }

  updateDeliveryForm(taskIds, values) {
    this.setState(prevState => {
      const newValues = { ...prevState.deliveryValues };
      taskIds.forEach(taskId => {
        newValues[taskId] = { ...newValues[taskId], ...values };
      });
      return { deliveryValues: newValues };
    });
  }

  updateFormTasks(taskIds, values) {
    this.setState(prevState => {
      const newValues = { ...prevState.values };
      taskIds.forEach(taskId => {
        newValues[taskId] = { ...newValues[taskId], ...values };
      });
      return { values: newValues };
    });
  }

  handleSubmit(generateDocumentAfterSave) {
    const { pendingDocument } = this.state;

    const generateState = { generateDocumentAfterSave };

    if (pendingDocument) {
      this.setState({
        ...generateState,
        pendingDocumentLoss: true,
        lostData: [`Pending: ${pendingDocument.fileName}.${pendingDocument.fileExtension}`],
        dialogCancel: () => {
          this.setState({ pendingDocumentLoss: false });
        },
        dialogContinue: () => {
          this.finalizeSubmit();
        },
      });
      return;
    }
    this.finalizeSubmit();
    this.setState(generateState, this.finalizeSubmit);
  }

  convertedValues() {
    const { taskType, taskIds, tasks } = this.props;

    const tasksList = convertMapToList(tasks);
    const filteredTaskIds = taskIds
      ? taskIds.filter(taskId => findTaskInTasks(tasksList, taskType, taskId))
      : [];

    const convertedValues = filteredTaskIds
      ? filteredTaskIds
          .map(taskId => tasksList.find(task => task.id === taskId && task.taskType === taskType))
          .map(task => ({ ...task, documentAddOnly: true }))
      : [];
    return convertedValues;
  }

  finalizeSubmit() {
    const { onSubmit, taskType, taskIds, tasks, combinedTask } = this.props;
    const { uploadedDocuments, deliveryValues, generateDocumentAfterSave } = this.state;

    const tasksList = convertMapToList(tasks);
    const filteredTaskIds = taskIds
      ? taskIds.filter(taskId => findTaskInTasks(tasksList, taskType, taskId))
      : [];

    // GA tracking
    const selectedTaskTypes = combinedTask ? Object.keys(combinedTask).join(',') : '';
    if (filteredTaskIds.length > 1) {
      logEvent(
        Tasks.category,
        Tasks.actions.multi_tasks_selected,
        `Edited: $(taskType), Selected: ${selectedTaskTypes}`,
      );
    } else {
      logEvent(Tasks.category, Tasks.actions.single_task_selected, `Edited: ${taskType}`);
    }

    const convertedValues = this.convertedValues();
    const { Completed } = FillCoordinationDescription.statusMap;
    // combine deliveries when they have same data
    const orders = Object.keys(deliveryValues).reduce((acc, taskId) => {
      const fcTask = findTaskInTasks(tasksList, 'FC', Number(taskId));
      const fcValues = convertedValues.find(it => it.id === Number(taskId));
      if (
        fcTask &&
        fcValues &&
        fcValues.old_status_id !== Completed &&
        fcValues.status_id === Completed
      ) {
        const order = deliveryValues[taskId];
        /**
         * If the new fc view is on, ALWAYS combine orders
         * since we're removing the ability to split them
         * for now.
         */
        const existing = acc[0];
        if (existing) {
          existing.taskIds.push(parseInt(taskId, 10));
        } else {
          acc.push({
            ...order,
            taskIds: [parseInt(taskId, 10)],
          });
        }
      }
      return acc;
    }, []);
    if (orders && orders[0] && orders[0].taskIds && orders[0].taskIds.length > 0) {
      orders[0].taskIds.forEach(taskId => {
        if (convertedValues.some(updatedFc => updatedFc.id === taskId)) {
          const indexOfUpdatedFc = convertedValues.findIndex(updatedFc => updatedFc.id === taskId);
          if (orders[0].address) {
            convertedValues[indexOfUpdatedFc].preferred_shipping_address = orders[0].address;
          }
          if (orders[0].delivery_dt) {
            convertedValues[indexOfUpdatedFc].delivery_dt = orders[0].delivery_dt;
          }
          if (orders[0].method) {
            convertedValues[indexOfUpdatedFc].preferred_rx_delivery_method = orders[0].method;
          }
          if (orders[0].phone) {
            convertedValues[indexOfUpdatedFc].preferred_phone_number = orders[0].phone;
          }
          if (orders[0].ship_dt) {
            convertedValues[indexOfUpdatedFc].ship_date = orders[0].ship_dt;
          }
          if (orders[0].signature_required) {
            convertedValues[indexOfUpdatedFc].signature_required = orders[0].signature_required;
          }
          if (orders[0].urgent) {
            convertedValues[indexOfUpdatedFc].urgent = definedBoolean(orders[0].signature_required)
              ? Number(orders[0].signature_required)
              : null;
          }
          if (orders[0].cold_chain_packing_recommended) {
            convertedValues[indexOfUpdatedFc].cold_chain_packing_recommended = definedBoolean(
              orders[0].cold_chain_packing_recommended,
            )
              ? Number(orders[0].cold_chain_packing_recommended)
              : null;
          }
          if (orders[0].cold_chain_packing_required) {
            convertedValues[indexOfUpdatedFc].cold_chain_packing_required = definedBoolean(
              orders[0].cold_chain_packing_required,
            )
              ? Number(orders[0].cold_chain_packing_required)
              : null;
          }
          if (orders[0].ancillary_supplies) {
            convertedValues[indexOfUpdatedFc].ancillary_supplies = orders[0].ancillary_supplies;
          }
          if (orders[0].medicaid_only) {
            convertedValues[indexOfUpdatedFc].medicaid_only = definedBoolean(
              orders[0].medicaid_only,
            )
              ? Number(orders[0].medicaid_only)
              : null;
          }
          if (orders[0].order_notes) {
            convertedValues[indexOfUpdatedFc].order_notes = orders[0].order_notes;
          }
          if (orders[0].payment_method_on_file) {
            convertedValues[indexOfUpdatedFc].payment_method_on_file = definedBoolean(
              orders[0].payment_method_on_file,
            )
              ? Number(orders[0].payment_method_on_file)
              : null;
          }
          if (orders[0].patient_advised_pickup) {
            convertedValues[indexOfUpdatedFc].patient_advised_pickup = definedBoolean(
              orders[0].patient_advised_pickup,
            )
              ? Number(orders[0].patient_advised_pickup)
              : null;
          }
          if (orders[0].special_instructions) {
            convertedValues[indexOfUpdatedFc].special_instructions = orders[0].special_instructions;
          }
          if (orders[0].order_type_address) {
            convertedValues[indexOfUpdatedFc].order_type_address = orders[0].order_type_address;
          }
          if (orders[0].to_patient) {
            convertedValues[indexOfUpdatedFc].to_patient = definedBoolean(orders[0].to_patient)
              ? Number(orders[0].to_patient)
              : null;
          }
          if (orders[0].to_md) {
            convertedValues[indexOfUpdatedFc].to_md = definedBoolean(orders[0].to_md)
              ? Number(orders[0].to_md)
              : null;
          }
          if (orders[0].patient_acknowledges) {
            convertedValues[indexOfUpdatedFc].patient_acknowledges = definedBoolean(
              orders[0].patient_acknowledges,
            )
              ? Number(orders[0].patient_acknowledges)
              : null;
          }
          if (orders[0].shipping_method) {
            convertedValues[indexOfUpdatedFc].shipping_method = buildObjectForJson(
              orders[0].shipping_method,
            );
          }
          if (orders[0].email) {
            convertedValues[indexOfUpdatedFc].email = orders[0].email;
          }
          if (orders[0].weather) {
            convertedValues[indexOfUpdatedFc].weather = orders[0].weather;
          }
          if (orders[0].ups_packaging_type) {
            convertedValues[indexOfUpdatedFc].ups_packaging_type = orders[0].ups_packaging_type;
          }
        }
      });
    }
    if (onSubmit && uploadedDocuments.length > 0) {
      onSubmit(convertedValues, orders, uploadedDocuments, generateDocumentAfterSave);
      this.setState(() => ({
        uploadedDocuments: [],
        pendingDocument: undefined,
        uploadProcessing: false,
      }));
    }
  }

  handleCancel() {
    const { uploadedDocuments, pendingDocument } = this.state;

    if ((uploadedDocuments && uploadedDocuments.length > 0) || pendingDocument) {
      const lostData = uploadedDocuments.map(ud => `Uploaded: ${ud.filename}`) || [];

      if (pendingDocument) {
        lostData.push(`Pending: ${pendingDocument.fileName}.${pendingDocument.fileExtension}`);
      }

      this.setState({
        pendingDocumentLoss: true,
        lostData,
        dialogCancel: () => {
          this.setState({ pendingDocumentLoss: false });
        },
        dialogContinue: () => {
          this.finalizeCancel();
        },
      });
    } else {
      this.finalizeCancel();
    }
  }

  finalizeCancel() {
    const { values } = this.state;
    const { onCancel, tasks, taskType, taskIds } = this.props;

    const filteredTaskIds = taskIds.filter(taskId =>
      findTaskInTasks(convertMapToList(tasks), taskType, taskId),
    );
    const selectTaskIds = values
      ? Object.keys(values)
          .filter(id => filteredTaskIds.indexOf(Number(id)) > -1)
          .map(id => Number(id))
      : [];
    onCancel(selectTaskIds, taskType);
  }

  toggleShipment() {
    this.setState(prevState => ({
      bundledDelivery: !prevState.bundledDelivery,
    }));
  }

  combineShipment() {
    this.setState(() => ({
      bundledDelivery: true,
    }));
  }

  splitShipment() {
    this.setState(() => ({
      bundledDelivery: false,
    }));
  }

  canSubmit() {
    const { valid } = this.state;
    return Object.keys(valid).reduce((v, formId) => v && valid[formId] !== false, true);
  }

  handleFileUploadCancel() {
    this.setState({ pendingDocument: undefined });
  }

  handleFileUploadSubmit() {
    this.setState({ uploadProcessing: true });
    const { pendingDocument } = this.state;
    const {
      selectedCustomerId,
      selectedPatientId,
      fileFormName,
      fileFormTypes,
      fileFormNote,
      taskType,
      taskIds,
    } = this.props;
    const tagType = noteTagTypes.find(tag => tag.label === taskType);
    uploadFile(
      pendingDocument.file,
      `${pendingDocument.fileName}.${pendingDocument.fileExtension}`,
      selectedCustomerId,
      selectedPatientId,
    )
      .then(result => {
        this.setState(prevState => ({
          uploadedDocuments: [
            ...prevState.uploadedDocuments,
            {
              uuid: result.uuid,
              taskTypeId: tagType.value,
              taskIds,
              filename: `${fileFormName}.${pendingDocument.fileExtension}`,
              labels: fileFormTypes.split(','),
              note: fileFormNote,
              tags: [tagType.label],
            },
          ],
          pendingDocument: undefined,
          uploadProcessing: false,
        }));
      })
      .catch(() => {
        this.setState({
          fileUploadErrorMessage: 'File could not be uploaded. Try again.',
        });
      });
  }

  fileSelected(event) {
    if (event && event.length > 0) {
      this.setState({
        fileErrorMessage: undefined,
        fileErrorCaption: undefined,
      });

      const { filePrefix, fileExtension } = getFileExtension(event[0].name);
      if (acceptedFileExtensions.includes(fileExtension.toLowerCase())) {
        const { changeField, fileUploadFormId } = this.props;
        const selectedFile = event[0];
        changeField(fileUploadFormId, UPLOAD_DOC_FILENAME_FIELD, filePrefix);
        this.setState({
          pendingDocument: {
            fileName: filePrefix,
            fileExtension,
            file: selectedFile,
          },
        });
      }
    }
  }

  fileRejected() {
    this.setState({
      fileErrorMessage: fileTypeError,
      fileErrorCaption: fileTypeErrorCaption,
    });
  }

  handleFilePaste(event) {
    if (event && event.clipboardData) {
      const { files } = event.clipboardData;
      if (files.length > 0) {
        this.fileSelected(files);
      } else {
        this.fileRejected();
      }
    }
  }

  render() {
    const {
      taskType,
      therapies,
      tasks: stateTasks,
      taskIds,
      notes,
      classes,
      defaultDocumentLabels,
      fileUploadFormId,
    } = this.props;
    const {
      bundledDelivery,
      values,
      pendingDocument,
      fileUploadErrorMessage,
      uploadProcessing,
      uploadedDocuments,
      pendingDocumentLoss,
      lostData,
      dialogCancel,
      dialogContinue,
      fileErrorCaption,
      fileErrorMessage,
    } = this.state;
    const forms = [];
    const deliveryForms = [];
    const filteredTasks = taskIds
      ? taskIds.map(taskId => stateTasks[`${taskType}${taskId}`]).filter(it => it)
      : [];
    const tasks =
      taskType && filteredTasks
        ? combineTasks(taskType, changeStatusToNextLogical(filteredTasks))
        : [];

    const allTaskIds = tasks ? tasks.map(it => it.id) : [];

    const editableTasks = [];
    const readonlyTasks = [];
    let tasksByUniqueTherapy = [];

    // put the readonly tasks in one bucket, others in other bucket
    tasks.forEach(task => {
      if (TaskStatusFactory.getProcessor(task.taskType).taskIsReadOnly(task)) {
        readonlyTasks.push(task);
      } else {
        editableTasks.push(task);
      }
    });

    if (tasks.length) {
      if (editableTasks.length) {
        tasksByUniqueTherapy = filterTasksByUniqueTherapy(editableTasks);
        const patientLevelTask =
          editableTasks.find(it => (therapies[it.therapy_id] || {}).is_specialty) ||
          editableTasks[0];
        // patient level fields
        forms.push({
          title: `${this.getTaskTypeName(patientLevelTask)} - Patient`,
          formName: 'edit',
          formId: `tasks-edit-${taskType}-${patientLevelTask.patient_id}-patient`,
          data: patientLevelTask,
          level: 'patient',
          linkedTasks: allTaskIds,
          notes,
          onFormChange: v => this.updateFormTasks(allTaskIds, v),
        });
        // therapy level fields
        tasksByUniqueTherapy.forEach((task, index) => {
          const therapyName = therapies[task.therapy_id]
            ? therapies[task.therapy_id].drug_name
            : index + 1;
          forms.push({
            title: therapyName,
            formName: 'edit',
            formId: `tasks-edit-${taskType}-${task.therapy_id || 0}-therapy`,
            linkedFormIds: {
              patient: `tasks-edit-${taskType}-${patientLevelTask.patient_id}-patient`,
            },
            data: task,
            level: 'therapy',
            notes,
            linkedTasks: task.taskIds,
            onFormChange: v => this.updateFormTasks(task.taskIds, v),
            therapyLevel: true,
          });
        });
        // patient level fields part 2
        forms.push({
          title: undefined,
          formName: 'edit',
          formId: `tasks-edit-${taskType}-${patientLevelTask.patient_id}-patient-2`,
          data: patientLevelTask,
          level: 'patient2',
          linkedFormIds: {
            patient: `tasks-edit-${taskType}-${patientLevelTask.patient_id}-patient`,
          },
          linkedTasks: allTaskIds,
          onFormChange: v => this.updateFormTasks(allTaskIds, v),
        });
        // fill cycle level fields
        filterTasksByUniqueTherapyAndFillCycle(tasks).forEach(task => {
          forms.push({
            title: `${this.getTaskTypeName(task)} - Fill Cycle ${task.fill_cycle_number}`,
            formName: 'edit',
            formId: `tasks-edit-${taskType}-${task.therapy_id}-${task.fill_cycle_number}-cycle`,
            linkedFormIds: {
              patient: `tasks-edit-${taskType}-${patientLevelTask.patient_id}-patient`,
              therapy: `tasks-edit-${taskType}-${task.therapy_id}-therapy`,
            },
            data: task,
            level: 'fillcycle',
            linkedTasks: task.taskIds,
            onFormChange: v => this.updateFormTasks(task.taskIds, v),
          });
        });
        // task level fields
        tasks.forEach(task => {
          const therapyName = (taskType === 'FDC' ? task.therapy_ids : `${task.therapy_id}`)
            .split(',')
            .map(id => {
              const therapy = therapies[id];
              return therapy ? therapy.drug_name : '';
            })
            .join(', ');
          forms.push({
            title: `${this.getTaskTypeName(task)} - ${therapyName}`,
            formName: 'edit',
            formId: `tasks-edit-${task.therapy_id}-${taskType}-${task.id}-task`,
            linkedFormIds: {
              patient: `tasks-edit-${taskType}-${patientLevelTask.patient_id}-patient`,
              therapy: `tasks-edit-${taskType}-${task.therapy_id}-therapy`,
              cycle: `tasks-edit-${taskType}-${task.therapy_id}-${task.fill_cycle_number}-cycle`,
            },
            notes,
            data: task,
            level: 'task',
            linkedTasks: [task.id],
            onFormChange: v => this.updateFormTasks([task.id], v),
          });
        });
        const { Completed } = FillCoordinationDescription.statusMap;
        // delivery form when at least 1 FC is transitioning to complete
        if (tasks.length > 0 && taskType === 'FC' && values[tasks[0].id]) {
          const statusId = values[tasks[0].id].status_id;
          if (tasks[0].status_id !== Completed && statusId === Completed) {
            const formTitle = 'Delivery';
            if (bundledDelivery) {
              deliveryForms.push({
                title: formTitle,
                formId: 'delivery',
                formName: 'delivery',
                linkedTasks: allTaskIds,
                onFormChange: v => this.updateDeliveryForm(allTaskIds, v),
                order_id: tasks[0].order_id,
              });
            } else {
              tasksByUniqueTherapy.forEach(task => {
                deliveryForms.push({
                  title: `${formTitle}: ${therapies[task.therapy_id].drug_name}`,
                  formId: `delivery-${task.therapy_id}`,
                  formName: 'delivery',
                  linkedTasks: task.taskIds,
                  onFormChange: v => this.updateDeliveryForm(task.taskIds, v),
                  order_id: task.order_id,
                });
              });
            }
          }
        }
      }
    }

    return (
      <>
        <ConfirmationDialogPanel
          open={pendingDocumentLoss}
          title="Wait! You might lose some data."
          content={
            <>
              <Typography gutterBottom>
                If you continue you will lose the following documents:
              </Typography>
              {lostData.map(ld => (
                <Typography>{ld}</Typography>
              ))}
            </>
          }
          backdropClick={dialogCancel}
          cancelText="Cancel"
          continueText="Continue"
          onCancel={dialogCancel}
          onContinue={dialogContinue}
        />
        {!pendingDocument && (
          <Grid container className={classes.customFormField}>
            <Grid item xs={6}>
              <SelectFileForm
                acceptedFileExtensions={acceptedFileExtensions}
                handleAcceptedDrop={this.fileSelected}
                handleRejectedDrop={this.fileRejected}
                handleFilePaste={e => this.handleFilePaste(e)}
                errorMessage={fileErrorMessage}
                errorCaption={fileErrorCaption}
              />
            </Grid>
          </Grid>
        )}
        {pendingDocument && (
          <Grid container>
            <Grid item xs={6} className={classes.customFormField}>
              <FileSelectedForm
                documentLabels={
                  defaultDocumentLabels
                    ? defaultDocumentLabels.map(dt => ({
                        label: dt.label,
                        value: dt.label,
                      }))
                    : null
                }
                submitButtonText="Upload"
                fileName={pendingDocument.fileName}
                fileExtension={pendingDocument.fileExtension}
                form={fileUploadFormId}
                formSubmit={e => this.handleFileUploadSubmit(e)}
                onCancel={() => this.handleFileUploadCancel()}
                errorMessage={fileUploadErrorMessage}
                isProcessing={uploadProcessing}
              />
            </Grid>
          </Grid>
        )}
        {uploadedDocuments && (
          <Grid container>
            {uploadedDocuments &&
              uploadedDocuments.map(document => <PendingDocument documentData={document} />)}
          </Grid>
        )}
      </>
    );
  }
}

function handleFormName(taskType, taskIds) {
  const fileFormId = `${UPLOAD_DOCUMENT_FORM}-${taskType}-${taskIds ? taskIds[0] : ''}`;
  return fileFormId;
}

function mapStateToProps(state, props) {
  const fileUploadFormId = handleFormName(props.taskType, props.taskIds);
  const selector = formValueSelector(fileUploadFormId);
  const fileFormName = selector(state, UPLOAD_DOC_FILENAME_FIELD);
  const fileFormTypes = selector(state, UPLOAD_DOC_FILETYPE_FIELD);
  const fileFormNote = selector(state, UPLOAD_DOC_FILENOTE_FIELD);

  return {
    therapies: state?.therapies?.data,
    fillCycles: state.fillCycles,
    taskCounts: state?.taskCounts?.data,
    orders: state?.orders?.list,
    tasks: state?.tasks?.data,
    notes: state.notes,
    allPinnedNotes: state?.notes?.notes?.allPinnedNotes,
    allNonPinnedNotes: state?.notes?.notes?.allNonPinnedNotes,
    users: state?.lookups?.users,
    serviceGroups: state?.lookups?.serviceGroups,
    defaultDocumentLabels: state?.lookups?.defaultDocumentLabels,
    selectedPatientId: state.selectedPatientId,
    selectedCustomerId: state?.filters?.selectedCustomerId,
    fileUploadFormId,
    fileFormName,
    fileFormTypes,
    fileFormNote,
    form: state.form,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    documentBarUpdate: state => dispatch(updateDocumentBar(state)),
    changeField: (formName, fieldName, value) => dispatch(change(formName, fieldName, value)),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, { withTheme: true }),
)(RenderDocumentUpload);

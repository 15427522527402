import moment from 'moment';
import React from 'react';
import intJson from 'config/task-types/int.json';
import {
  reduceStatusesWithSubstatusesToArray,
  sortWithOtherComparer,
} from 'services/utils/task-service';
import { TherapyUtil } from 'utils/therapy-util';
import {
  INTERVENTION_TYPES,
  INTERVENTION_SEVERITIES,
  INTERVENTION_REASON_TYPE,
} from 'constants/index';
import { InterventionStatuses } from 'constants/intervention-statuses';
import { interventionTypeRelatedFields } from 'constants/lists';
import { MedicationStatus } from 'constants/enums';
import { addMedsIntoMedGroups } from 'services/utils/medication-service';
import LabHistory from 'components/dynamic-form/custom-elements/lab-history';
// eslint-disable-next-line max-len
import InterventionCopyContentButton from 'components/intervention-button/intervention-copy-content-button';
import { InterventionSeverityBanner } from 'components/intervention-severity-banner/intervention-severity-banner';
import {
  DISEASE_PROGRESSION_OPTIONS,
  VISIT_TYPE_OPTIONS,
} from 'constants/intervention-hospitalization-options';
import { staticProviders, getCommonProviders, getDateForProvider } from './common-provider';
import { InterventionTypeId } from '../../../interfaces/enums/InterventionType';

const SCHEDULED_FOLLOW_UP = 'Scheduled Follow Up';
const ESCALATE_TO_PRESCRIBER = 'Escalate to Prescriber';
const PHARMACIST_COMPLETED = 'Pharmacist Completed';

const mapToIdName = (id, name) => it => ({
  name: it[name],
  id: it[id],
});

const createDropdownItemList = (list, id, name, filterId, filterName) => {
  let newFilter = list;
  if (filterId) {
    newFilter = list.filter(r => r[filterId] === filterName);
  }
  newFilter.sort(sortWithOtherComparer(name));
  return newFilter.map(mapToIdName(id, name));
};

export default function (task, state) {
  let { taskStatuses } = state;
  let medicationGroups;
  let interactionMedicationGroups;
  // eslint-disable-next-line camelcase
  let taskTypeId = state.formValues?.type_id;
  // eslint-disable-next-line camelcase
  if (state.formValues?.intervention_json?.values) {
    // eslint-disable-next-line camelcase
    taskTypeId = state.formValues?.intervention_json?.values.type_id;
  }
  if (!taskTypeId) {
    // eslint-disable-next-line camelcase
    taskTypeId = state.data?.type_id;
  }
  if (!taskTypeId && task) {
    taskTypeId = task.type_id;
  }

  const interventionType = typeId =>
    // eslint-disable-next-line camelcase
    taskStatuses.intervention_types.find(type => type.id === typeId)?.type_name;

  if (!taskStatuses || !taskStatuses.intervention_categories) {
    taskStatuses = {
      intervention_categories: [],
      intervention_severities: [],
      intervention_types: [],
      intervention_recommendations: [],
      intervention_reasons: [],
      intervention_outcomes: [],
    };
  }
  const recommendationArr = [
    ...taskStatuses.intervention_recommendations.filter(
      rec => String(rec.type_id) === String(taskTypeId),
    ),
  ];

  const reasonType = state.formValues?.reason_type;
  const showNoFurtherActionRequiredOpt =
    taskTypeId === INTERVENTION_TYPES.ADHERENCE &&
    (reasonType === 'Intentional' || reasonType === 'Unintentional');
  if (!showNoFurtherActionRequiredOpt) {
    const noFurtherActionRequired = recommendationArr.findIndex(x => x.id === 1007);
    if (noFurtherActionRequired !== -1) recommendationArr.splice(noFurtherActionRequired, 1);
  }

  const showCoordinateWithExternalEntityOpt =
    taskTypeId === INTERVENTION_TYPES.ADHERENCE && reasonType === 'Unintentional';
  if (!showCoordinateWithExternalEntityOpt) {
    const coordinateWithExternalEntity = recommendationArr.findIndex(x => x.id === 1008);
    if (coordinateWithExternalEntity !== -1) {
      recommendationArr.splice(coordinateWithExternalEntity, 1);
    }
  }

  const reasonArr = taskStatuses.intervention_reasons.filter(
    rec => String(rec.type_id) === String(taskTypeId),
  );
  const reasonTypeArr = taskStatuses.intervention_reasons;
  const outcomeArr = taskStatuses.intervention_outcomes.filter(
    rec => String(rec.type_id) === String(taskTypeId),
  );

  const commonProviders = getCommonProviders(task, state, intJson);
  let initialReasonType = '';
  if (task.reason) {
    const id = String(task.reason).split(',')[0];
    initialReasonType = (reasonArr.find(r => String(r.id) === id) || {}).reason_subtype;
  }
  const nowDate = getDateForProvider(moment());
  let initialOutcomeType =
    outcomeArr.find(o =>
      typeof task?.outcome === 'string'
        ? task?.outcome?.split(',')?.includes(String(o.id))
        : task?.outcome === o.id,
    ) || {};
  initialOutcomeType = initialOutcomeType.outcome_subtype || initialOutcomeType.outcome_name;

  if (state.medications) {
    const { medicationList, medicationGroups: overwriteGroups } = state.medications;
    const fullMedicationGroups = addMedsIntoMedGroups(overwriteGroups, medicationList);

    medicationGroups = fullMedicationGroups.map(group => ({
      name: group.header.medication,
      id: group.header.medication,
    }));

    const completedInt =
      task.status_id !== null &&
      [
        InterventionStatuses.Canceled,
        InterventionStatuses.Completed,
        InterventionStatuses.Canceled_PatientDeceased,
      ].includes(Number(task.status_id));

    const interactingMedicationsGpis =
      task?.interaction?.concepts
        ?.filter(concept => concept.fdb_concept_type_id === 1)
        .map(concept => concept.gpi) || [];

    interactionMedicationGroups = fullMedicationGroups
      .filter(group => interactingMedicationsGpis.includes(group.header.gpi))
      .map(group => ({
        name: group.header.medication,
        id: group.header.id,
        disabled:
          completedInt ||
          (completedInt === false &&
            (group.header.status_code !== MedicationStatus.Active || !!group.header.therapy_id)),
      }));
  }

  let updatedFields = [];

  const handleFields = checkboxValue => {
    const fieldsToUpdate = ['admission_date_completed', 'discharged_date_completed'];
    const listOfFields = intJson.fields.map(item => {
      if (fieldsToUpdate.includes(item.id)) {
        item.required = !checkboxValue;
      }
      return item;
    });
    updatedFields = updatedFields.concat(listOfFields);
  };

  const getRightOutComeId = currentIntType => {
    switch (currentIntType) {
      case InterventionTypeId.Dur:
      case InterventionTypeId.Adr:
      case InterventionTypeId.Regimen:
      case InterventionTypeId.ReferralOfService:
      case InterventionTypeId.OtherClinical:
      case InterventionTypeId.LinkageToCare:
      case InterventionTypeId.ProductIssue:
      case InterventionTypeId.DrugInformation:
      case InterventionTypeId.Vaccine:
      case InterventionTypeId.InfectionControl:
        return 'outcome';
      case InterventionTypeId.Adherence:
        return 'outcome_type_adherence';
      case InterventionTypeId.Lab:
        return 'outcome_multiple';
      default:
        return null;
    }
  };

  const dynamicallySetFieldsAsRequiredOrNot = (fieldsToUpdate, valueForRequiredProp) => {
    const listOfFields = intJson.fields.map(item => {
      if (fieldsToUpdate.includes(item.id)) {
        item.required = valueForRequiredProp;
      }
      return item;
    });
    updatedFields = updatedFields.concat(listOfFields);
  };

  const updateFieldsAsRequiredOrnot = (currentOutcomeValue, currentIntType) => {
    switch (currentIntType) {
      case InterventionTypeId.Regimen:
      case InterventionTypeId.Adr:
        const fieldsToUpdate1 = ['category', 'service_group', 'details', 'reason_single'];
        if (currentOutcomeValue) {
          dynamicallySetFieldsAsRequiredOrNot(fieldsToUpdate1, true);
        } else {
          dynamicallySetFieldsAsRequiredOrNot(fieldsToUpdate1, false);
        }
        break;
      case InterventionTypeId.Dur:
      case InterventionTypeId.ProductIssue:
      case InterventionTypeId.DrugInformation:
      case InterventionTypeId.Vaccine:
      case InterventionTypeId.InfectionControl:
        const fieldsToUpdate2 = ['category', 'service_group', 'details'];
        if (currentOutcomeValue) {
          dynamicallySetFieldsAsRequiredOrNot(fieldsToUpdate2, true);
        } else {
          dynamicallySetFieldsAsRequiredOrNot(fieldsToUpdate2, false);
        }
        break;
      case InterventionTypeId.OtherClinical:
        const fieldsToUpdate3 = ['category', 'service_group', 'details', 'additional_reason_other'];
        if (currentOutcomeValue) {
          dynamicallySetFieldsAsRequiredOrNot(fieldsToUpdate3, true);
        } else {
          dynamicallySetFieldsAsRequiredOrNot(fieldsToUpdate3, false);
        }
        break;
      case InterventionTypeId.ReferralOfService:
        const fieldsToUpdate4 = ['category', 'service_group', 'details', 'reason_all'];
        if (currentOutcomeValue) {
          dynamicallySetFieldsAsRequiredOrNot(fieldsToUpdate4, true);
        } else {
          dynamicallySetFieldsAsRequiredOrNot(fieldsToUpdate4, false);
        }
        break;
      case InterventionTypeId.LinkageToCare:
        const fieldsToUpdate5 = [
          'category',
          'service_group',
          'details',
          'reason_single_linkage_to_care_hiv',
          'reason_single_linkage_to_care_pep_prep',
          'reason_single_linkage_to_care_hcv',
          'reason_single_linkage_to_care_oncology',
          'reason_single_linkage_to_care_neurology',
          'reason_single_linkage_to_care_dermatology',
          'reason_single_linkage_to_care_gi',
          'reason_single_linkage_to_care_rhemautology',
          'reason_single_linkage_to_care_diabetes',
          'reason_single_linkage_to_care_endocrinology',
        ]; // reason_type_linkage_to_care
        if (currentOutcomeValue) {
          dynamicallySetFieldsAsRequiredOrNot(fieldsToUpdate5, true);
        } else {
          dynamicallySetFieldsAsRequiredOrNot(fieldsToUpdate5, false);
        }
        break;
      case InterventionTypeId.Adherence:
        const fieldsToUpdate6 = [
          'category',
          'service_group',
          'details',
          'reason_intentional',
          'reason_unintentional',
        ]; // reason_type_adherence
        if (currentOutcomeValue) {
          dynamicallySetFieldsAsRequiredOrNot(fieldsToUpdate6, true);
        } else {
          dynamicallySetFieldsAsRequiredOrNot(fieldsToUpdate6, false);
        }
        break;
      case InterventionTypeId.Lab:
        const fieldsToUpdate7 = ['category', 'service_group', 'details', 'reason_all'];
        if (currentOutcomeValue) {
          dynamicallySetFieldsAsRequiredOrNot(fieldsToUpdate7, true);
        } else {
          dynamicallySetFieldsAsRequiredOrNot(fieldsToUpdate7, false);
        }
        break;
      default:
        break;
    }
  };

  const handleChange = (fieldId, value, formValues) => {
    switch (fieldId) {
      case 'type': {
        interventionTypeRelatedFields.forEach(fieldName => {
          if (formValues[fieldName]) {
            state.change(state.formId, fieldName, null);
          }
        });
        const currentOutcomeIdToUse = getRightOutComeId(value) ?? '';
        const rightOutcomeValue = currentOutcomeIdToUse ? formValues[currentOutcomeIdToUse] : null;
        updateFieldsAsRequiredOrnot(rightOutcomeValue, value);
        state.change(state.formId, 'type_id', value);
        return handleChange('reason', null, formValues);
      }
      case 'outcome_type_adherence':
        if (value === 'Requires Follow Up') {
          state.change(state.formId, 'outcome', 1003);
        }
        return updateFieldsAsRequiredOrnot(value, formValues.type_id);
      case 'no_therapy_ids_checkbox':
        return state.change(state.formId, 'therapy_ids', null);
      case 'unknown_admission_discharge_date':
        return handleFields(value.target.checked);
      case 'outcome':
      case 'outcome_multiple':
        return updateFieldsAsRequiredOrnot(value, formValues?.type_id);
      default:
        return null;
    }
  };

  const therapies =
    state && state.therapies
      ? Object.values(state?.therapies).map(therapy => ({
          ...therapy,
          // eslint-disable-next-line max-len
          drug_name_with_dosage: TherapyUtil.therapyFormatDosage(therapy),
        }))
      : [];

  if (state?.formValues?.unknown_admission_discharge_date) {
    handleFields(true);
  } else if (!state?.formValues?.unknown_admission_discharge_date) {
    handleFields(false);
  }

  if (state?.firstRender) {
    updateFieldsAsRequiredOrnot('', taskTypeId);
  }

  const isAdherenceProactive =
    state?.formValues?.type_id === INTERVENTION_TYPES.ADHERENCE &&
    state?.formValues?.reason_type === INTERVENTION_REASON_TYPE.PROACTIVE;

  const outcomeTypes = isAdherenceProactive
    ? [{ id: PHARMACIST_COMPLETED, name: PHARMACIST_COMPLETED }]
    : [
        { id: 'Resolved', name: 'Resolved' },
        { id: SCHEDULED_FOLLOW_UP, name: SCHEDULED_FOLLOW_UP },
        { id: ESCALATE_TO_PRESCRIBER, name: ESCALATE_TO_PRESCRIBER },
        { id: PHARMACIST_COMPLETED, name: PHARMACIST_COMPLETED },
      ];

  return {
    ...staticProviders,
    ...commonProviders,
    json: {
      ...intJson,
      fields: updatedFields.length ? [...updatedFields] : [...intJson.fields],
    },
    events: {
      type: {
        onChange: (field, value, formValues) => {
          handleChange(field.id, value, formValues);
        },
      },
      outcome_type_adherence: {
        onChange: (field, value, formValues) => {
          handleChange(field.id, value, formValues);
        },
      },
      no_therapy_ids_checkbox: {
        onChange: (field, value, formValues) => {
          handleChange(field.id, value, formValues);
        },
      },
      unknown_admission_discharge_date: {
        onChange: (field, value, formValues) => {
          handleChange(field.id, value, formValues);
        },
      },
      outcome: {
        onChange: (field, value, formValues) => {
          handleChange(field.id, value, formValues);
        },
      },
      outcome_multiple: {
        onChange: (field, value, formValues) => {
          handleChange(field.id, value, formValues);
        },
      },
    },
    therapies: createDropdownItemList(therapies, 'id', 'drug_name_with_dosage'),
    categories: createDropdownItemList(taskStatuses.intervention_categories, 'id', 'category_name'),
    severities: createDropdownItemList(taskStatuses.intervention_severities, 'id', 'severity_name'),
    visitType: createDropdownItemList(VISIT_TYPE_OPTIONS, 'value', 'label'),
    diseaseProgression: createDropdownItemList(DISEASE_PROGRESSION_OPTIONS, 'value', 'label'),
    types: createDropdownItemList(taskStatuses.intervention_types, 'id', 'type_name'),
    reasons: createDropdownItemList(reasonArr, 'id', 'reason_name'),
    infectionTypes: createDropdownItemList(
      state?.lookups?.lookupInterventionInfectionTypes ?? [],
      'id',
      'type_name',
    ),
    recommendedAppropriateVaccines: state?.lookups?.lookupRecommendedAppropriateVaccines,
    reasonsLinkageToCareHIV: createDropdownItemList(
      reasonTypeArr,
      'id',
      'reason_name',
      'reason_subtype',
      'HIV',
    ).filter(status => status.name),
    reasonsLinkageToCarePEPPrEP: createDropdownItemList(
      reasonTypeArr,
      'id',
      'reason_name',
      'reason_subtype',
      'PEP/PrEP',
    ).filter(status => status.name),
    reasonsLinkageToCareHCV: createDropdownItemList(
      reasonTypeArr,
      'id',
      'reason_name',
      'reason_subtype',
      'HCV',
    ).filter(status => status.name),
    reasonsLinkageToCareOncology: createDropdownItemList(
      reasonTypeArr,
      'id',
      'reason_name',
      'reason_subtype',
      'Oncology',
    ).filter(status => status.name),
    reasonsLinkageToCareNeurology: createDropdownItemList(
      reasonTypeArr,
      'id',
      'reason_name',
      'reason_subtype',
      'Neurology',
    ).filter(status => status.name),
    reasonsLinkageToCareDermatology: createDropdownItemList(
      reasonTypeArr,
      'id',
      'reason_name',
      'reason_subtype',
      'Dermatology',
    ).filter(status => status.name),
    reasonsLinkageToCareGI: createDropdownItemList(
      reasonTypeArr,
      'id',
      'reason_name',
      'reason_subtype',
      'GI',
    ).filter(status => status.name),
    reasonsLinkageToCareRhemautology: createDropdownItemList(
      reasonTypeArr,
      'id',
      'reason_name',
      'reason_subtype',
      'Rhemautology',
    ).filter(status => status.name),
    reasonsLinkageToCareDiabetes: createDropdownItemList(
      reasonTypeArr,
      'id',
      'reason_name',
      'reason_subtype',
      'Diabetes',
    ).filter(status => status.name),
    reasonsLinkageToCareEndocrinology: createDropdownItemList(
      reasonTypeArr,
      'id',
      'reason_name',
      'reason_subtype',
      'Endocrinology',
    ).filter(status => status.name),
    reasonsIntentional: createDropdownItemList(
      reasonTypeArr,
      'id',
      'reason_name',
      'reason_subtype',
      'Intentional',
    ),
    reasonsUnintentional: createDropdownItemList(
      reasonTypeArr,
      'id',
      'reason_name',
      'reason_subtype',
      'Unintentional',
    ),
    reasonsProactive: createDropdownItemList(
      reasonTypeArr,
      'id',
      'reason_name',
      'reason_subtype',
      'Proactive',
    ),
    reasonsCheckin: createDropdownItemList(
      reasonTypeArr,
      'id',
      'reason_name',
      'reason_subtype',
      'Check In',
    ),
    reasonsDescriptionOfIssue: createDropdownItemList(
      reasonTypeArr,
      'id',
      'reason_name',
      'reason_subtype',
      'Description of Issue',
    ),
    reasonsAll: reasonArr.filter(r => !r.reason_subtype).map(mapToIdName('id', 'reason_name')),
    reasonTypesAdherence: createDropdownItemList(
      reduceStatusesWithSubstatusesToArray(
        reasonTypeArr,
        'reason_subtype',
        INTERVENTION_TYPES.ADHERENCE,
      ),
      'value',
      'label',
    ),
    reasonTypesOtherClinical: createDropdownItemList(
      reduceStatusesWithSubstatusesToArray(
        reasonTypeArr,
        'reason_subtype',
        INTERVENTION_TYPES.OTHER_CLINICAL,
      ),
      'value',
      'label',
    ),
    reasonTypesLinkageToCare: createDropdownItemList(
      reduceStatusesWithSubstatusesToArray(
        reasonTypeArr,
        'reason_subtype',
        INTERVENTION_TYPES.LINKAGE_TO_CARE,
      ),
      'value',
      'label',
    ),
    outcomes: createDropdownItemList(outcomeArr, 'id', 'outcome_name'),
    interactingMeds: interactionMedicationGroups,
    outcomeTypesAdherence: outcomeTypes,
    outcomesPharmacistCompleted: createDropdownItemList(
      outcomeArr,
      'id',
      'outcome_name',
      'outcome_subtype',
      PHARMACIST_COMPLETED,
    ),
    outcomesResolved: createDropdownItemList(
      outcomeArr,
      'id',
      'outcome_name',
      'outcome_subtype',
      'Resolved',
    ),
    outcomesScheduled: createDropdownItemList(
      outcomeArr,
      'id',
      'outcome_name',
      'outcome_subtype',
      SCHEDULED_FOLLOW_UP,
    ),
    outcomesEscalate: createDropdownItemList(
      outcomeArr,
      'id',
      'outcome_name',
      'outcome_subtype',
      ESCALATE_TO_PRESCRIBER,
    ),
    medicationGroups,
    customElements: [
      {
        id: 'lab-history',
        content: LabHistory,
      },
      {
        id: 'intervention-copy-content-button',
        content: () => {
          const formValues =
            state.formValues && state.formValues.intervention_json
              ? state.formValues.intervention_json.values
              : null;
          return (
            <InterventionCopyContentButton
              interventionType={formValues ? interventionType(formValues.type_id) : null}
              details={formValues ? formValues.details : null}
            />
          );
        },
      },
      {
        id: 'intervention-severity-banner',
        content: () => {
          const { severity_id } = state.formValues || {};

          return severity_id && <InterventionSeverityBanner severity_id={severity_id} />;
        },
      },
    ],
    recommendations: createDropdownItemList(recommendationArr, 'id', 'recommendation_name'),
    initialValues: {
      reason_type: initialReasonType,
      outcome_type: initialOutcomeType,
      in_progress_date: nowDate,
      completed_date: nowDate,
      canceled_dt: nowDate,
      followup_dt: state.currentDateTime,
      severity_id: null,
      no_therapy_ids_checkbox:
        task.status_id === InterventionStatuses.Completed && !task.therapy_ids,
      therapy_id: task.therapy_id || null,
      therapy_ids_inactive: task.therapy_ids_inactive || null,
      serious_adverse_event: task.serious_adverse_event,
      infection_type_id: task?.infection_type_id ?? null,
    },
    leftAlignedButtons: [
      {
        button: (
          <InterventionCopyContentButton
            interventionType={state.formValues ? interventionType(state.formValues.type_id) : null}
            details={state.formValues ? state.formValues.details : null}
          />
        ),
      },
    ],
  };
}

import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import Communication from './communication';
import Incident from './incident';
import Intervention from './intervention';
import { styles } from './note-styles';

const ActionRow = props => {
  const {
    therapy,
    task,
    tagTypeId,
    tagResourceId,
    serviceGroups,
    handleCancel,
    selectedServiceGroup,
    taskType,
    idPrefix,
    noIntervention,
  } = props;

  return (
    <Grid container spacing={1} justifyContent="flex-end">
      {!noIntervention && (
        <Grid item>
          <Intervention
            id={`${idPrefix}_intervention`}
            therapy={therapy}
            tagTypeId={tagTypeId}
            tagResourceId={tagResourceId}
            cancel={handleCancel}
            defaultServiceGroup={selectedServiceGroup}
            serviceGroups={serviceGroups}
            taskType={taskType}
          />
        </Grid>
      )}
      <Grid item>
        <Communication
          id={`${idPrefix}_contact`}
          task={task}
          tagTypeId={tagTypeId}
          tagResourceId={tagResourceId}
        />
      </Grid>
      <Grid item>
        <Incident
          incidentId={`${idPrefix}_incident`}
          therapy={therapy}
          tagTypeId={tagTypeId}
          tagResourceId={tagResourceId}
          cancel={handleCancel}
          defaultServiceGroup={selectedServiceGroup}
          serviceGroups={serviceGroups}
          taskType={taskType}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(ActionRow);

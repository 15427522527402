import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { expandTheme } from 'utils/theme-util';
import { ModalUtil } from 'services/utils/modal.util';
import { combineStyles } from 'services/utils/styles-service';
import { BaseTableStyles } from '../../base-table/base-table.styles';

export const AddRuleStyles = (theme: Theme) => {
  const { font, palette } = expandTheme(theme);

  return createStyles({
    mainTitle: {
      fontWeight: 'bold',
    },
    sectionContainer: {
      width: '100%',
      padding: theme.spacing(2, 0),
    },
    sectionTitle: {
      backgroundColor: palette.primary.grey2,
      fontWeight: 'bold',
      padding: theme.spacing(2),
      marginLeft: 0,
    },
    sectionBody: {
      padding: theme.spacing(4, 2),
      marginLeft: theme.spacing(2),
    },
    sectionDivider: {
      margin: theme.spacing(3, 0),
    },
    inputField: {
      minWidth: theme.spacing(30),
    },
    ruleNameTextField: {
      width: '100%',
      display: 'block',
    },
    chip: {
      backgroundColor: palette.primary.teal,
      color: palette.primary.white,
    },
    removeButton: {
      borderRadius: '100px',
    },
    errorMessage: {
      color: palette.primary.error,
      textTransform: 'uppercase',
      fontSize: '2.5em',
    },
  });
};

const useStyles = makeStyles(theme => AddRuleStyles(theme));
export { useStyles };

// @ts-ignore
import copy from 'copy-html-to-clipboard';
import EditableTextSection from 'components/editable-text-section/editable-text-section';
import RichTextEditor, { StandardFeatures, EditorValueFormat } from 'components/rich-text-editor';
import React from 'react';
import { buildQaId } from 'utils/build-qa-id';
import { Button, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Edit as EditIcon, FileCopyOutlined as FileCopyOutlinedIcon } from '@mui/icons-material';
import { EditableSection } from 'components/editable-text-section/interfaces';
import { IState } from 'interfaces/redux/IState';
import { notifySuccess } from 'actions/action-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { CounselingStatus } from 'interfaces/enums/TaskStatuses/CounselingStatus';
import { IProps } from './interfaces/IProps';
import { RightSideComponent, Section } from './interfaces/Section';
import { styles } from './documentation.styles';
import { Utils } from './utils';
import LabValuesList from './components/lab-values-list';
import { AddendumsList } from './components/addendums-list';

enum SectionContentHeaders {
  Objective = 'Objective',
  Subjective = 'Subjective',
  AssessmentPlan = 'Assessment / Plan',
}

const htmlTagsToRemove: string[] = ['<strong>', '</strong>'];

const getRightSideComponent = (rightSideComponent?: RightSideComponent) => {
  switch (rightSideComponent) {
    case RightSideComponent.LabValuesList: {
      return LabValuesList;
    }
    default: {
      return undefined;
    }
  }
};

const modifyHtmlBySection = (section: SectionContentHeaders, content: string): string => {
  switch (section) {
    case SectionContentHeaders.Subjective:
      return content.replace('<br /><br /><br />', '<br />');
    default:
      return content;
  }
};

const qaIdBuilder = buildQaId('documentation');

const Documentation: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { classes, patientReviewData, taskQty, currentSelectedTasks } = props;

  const dispatch = useDispatch();
  const state = useSelector((state: IState) => state);
  /**
   * "content" is the part of the section that cannot be edited by a user
   * "drugSubSections" is used to render the AP sections. Broken up by a drug title and
   *  then sub sections and each sub section's content is editable
   * "secondaryContent" is the part below the content for some sections that the user can edit. This
   *  is typically empty to begin with
   */
  const sectionContentValue: Record<
    string,
    {
      content?: string;
      drugSubSections?: Record<string, Record<string, string>>;
      secondaryContent?: string;
      editableContent?: EditableSection;
      readOnly?: boolean;
      rightSideComponent?: RightSideComponent;
    }
  > = {
    S: {
      content: props.subjective?.content,
      editableContent: EditableSection.Primary,
    },
    O: {
      content: props.objective?.content,
      secondaryContent: props.objective?.secondaryContent || '',
      editableContent: EditableSection.None,
      rightSideComponent: RightSideComponent.LabValuesList,
    },
    AP: {
      ...Utils.buildDrugSubSections(props.tasks, state.therapies.data, props.protocolData),
    },
  };

  const sectionContentHeadersVar: Record<string, string> = {
    S: SectionContentHeaders.Subjective,
    O: SectionContentHeaders.Objective,
    AP: SectionContentHeaders.AssessmentPlan,
  };

  const initialSectionContent: Section[] = [];

  props.soapOrder.forEach(order => {
    const header = sectionContentHeadersVar[order];
    initialSectionContent.push({
      header: header,
      ...sectionContentValue[order],
    });
  });

  const saveAllDocumentationContent = (finalEditingState: boolean | undefined = undefined) => {
    setSaveContentUpdatesTrigger(true);

    if (finalEditingState != null) {
      setEditingAllDocs(finalEditingState);
    } else {
      toggleEditDocumentation();
    }
  };

  const [sectionContent, setSectionContent] = React.useState<Section[]>(initialSectionContent);
  const [editingAllDocs, setEditingAllDocs] = React.useState<boolean>(
    props.defaultToEditing != null ? props.defaultToEditing : false,
  );
  const hasMounted = React.useRef<boolean>(false);

  // This are just flags that changes between true & false used for triggering useEffects on EditableTextSection
  const [saveContentUpdatesTrigger, setSaveContentUpdatesTrigger] = React.useState<boolean>(false);
  const [cancelContentUpdatesTrigger, setCancelContentUpdatesTrigger] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (hasMounted.current) {
      saveAllDocumentationContent(false);
    } else {
      hasMounted.current = true;
    }
  }, [props.forceSaveDocumentationTrigger]);

  React.useEffect(() => {
    setSectionContent(() => {
      const updatedSectionContent: Section[] = [];

      props.soapOrder.forEach(order => {
        const header = sectionContentHeadersVar[order];
        let newSectionContentValue: Section = { header: header, ...sectionContentValue[order] };

        if (header === SectionContentHeaders.Subjective) {
          newSectionContentValue = { ...newSectionContentValue, ...props.subjective };
        } else if (header === SectionContentHeaders.Objective) {
          newSectionContentValue = { ...newSectionContentValue, ...props.objective };
        }

        updatedSectionContent.push(newSectionContentValue);
      });
      return updatedSectionContent;
    });
  }, [
    props.tasks,
    props.protocolData,
    props.soapOrder,
    props.subjective,
    props.objective,
    props.addendums,
  ]);

  React.useEffect(() => {
    const allSectionsSaved = sectionContent.every(x => x.finishedSaving === true);
    if (allSectionsSaved && props.afterForceSaveFn) {
      setSectionContent(prevState => {
        const newState = [...prevState];
        newState.forEach(s => (s.finishedSaving = undefined));
        return newState;
      });
      props.afterForceSaveFn();
    }
  }, [sectionContent]);

  const setSectionFinishedSaving = (index: number) => {
    setSectionContent(prevState => {
      const newState = [...prevState];
      newState[index].finishedSaving = true;
      return newState;
    });
  };

  const changeSectionContent = (
    section: string,
    content?: string,
    secondaryContent?: string,
    drugIndex?: number,
    subSectionIndex?: number,
  ) => {
    setSectionContent(prevState => {
      const resultObj = prevState;
      const sectionToUpdate = sectionContent.find(sectionItem => sectionItem.header === section);
      if (
        sectionToUpdate &&
        drugIndex !== undefined &&
        subSectionIndex !== undefined &&
        sectionToUpdate.drugSubSections &&
        Object.keys(sectionToUpdate.drugSubSections)[drugIndex]
      ) {
        const subSection =
          sectionToUpdate.drugSubSections[Object.keys(sectionToUpdate.drugSubSections)[drugIndex]];
        if (subSection && content !== undefined) {
          subSection[Object.keys(subSection)[subSectionIndex]] = content;
        }
      } else if (sectionToUpdate) {
        if (content) {
          sectionToUpdate.content = content;
        }
        if (secondaryContent) {
          sectionToUpdate.secondaryContent = secondaryContent;
        }
      }
      return resultObj;
    });
    if (props.onContentChange) {
      let taskId: number | undefined;
      if (drugIndex !== undefined && props.tasks[drugIndex]) {
        taskId = props.tasks[drugIndex].id;
      }

      props.onContentChange(section, content, secondaryContent, drugIndex, subSectionIndex, taskId);
    }
  };

  const selectedLabsToHtml = (): string => {
    let labsHtmlContent = '';
    props.objectiveDataCollects
      .filter(dc =>
        props.objectiveSelectedLabs.some(selectedLab => selectedLab.taskId === dc.taskId),
      )
      .forEach(dataCollect => {
        labsHtmlContent = labsHtmlContent.concat(
          `<strong>Data Collect ${dataCollect.drugName} ${dataCollect.reviewedDate}</strong></br>`,
        );
        dataCollect.labs
          .filter(lab =>
            props.objectiveSelectedLabs.some(
              selectedLab =>
                selectedLab.taskId === dataCollect.taskId &&
                selectedLab.dataTypeName === lab.dataTypeName,
            ),
          )
          .forEach(lab => {
            labsHtmlContent = labsHtmlContent.concat(`
              <strong>${lab.dataTypeName}</strong></br>
              <strong>Assessment Date:</strong> ${lab.assessmentDate}</br>
              <strong>Value:</strong> ${lab.value}</br></br>
            `);
          });
      });
    return labsHtmlContent;
  };

  const copyAllText = () => {
    let htmlContent = '';
    sectionContent.forEach(section => {
      const key = section.header || '';
      if (section) {
        htmlContent = htmlContent.concat(`<p>${key.toUpperCase()}</p>`);
        if (section.content) {
          let updatedContent = section.content;
          if (section.header) {
            updatedContent = modifyHtmlBySection(
              section.header as SectionContentHeaders,
              section.content,
            );
          }

          htmlContent = htmlContent.concat(updatedContent);
        }
        if (
          section.rightSideComponent &&
          section.rightSideComponent === RightSideComponent.LabValuesList &&
          props.objectiveSelectedLabs.length
        ) {
          htmlContent = htmlContent.concat(selectedLabsToHtml());
        }
        if (section.secondaryContent) {
          htmlContent = htmlContent.concat(section.secondaryContent);
        }
        if (section.drugSubSections) {
          Object.keys(section.drugSubSections).forEach(drugSubSectionKey => {
            htmlContent = htmlContent.concat(`<p>${drugSubSectionKey}</p>`);
            if (section.drugSubSections && section.drugSubSections[drugSubSectionKey]) {
              Object.keys(section.drugSubSections[drugSubSectionKey]).forEach(key => {
                htmlContent = htmlContent.concat(`<p>${key}</p>`);
                htmlContent = htmlContent.concat(
                  // @ts-ignore
                  `<p>${section.drugSubSections[drugSubSectionKey][key]}</p>`,
                );
              });
            }
          });
        }

        if (props.addendums && props.addendums.length > 0) {
          htmlContent = htmlContent.concat('<p>Addendums</p>');
          props.addendums.forEach(add => {
            htmlContent = htmlContent.concat(`<p>${add.content}</p>`);
          });
        }
      }
    });

    const updatedHtmlContent = htmlTagsToRemove.reduce((acc, item) => {
      return acc.split(item).join('').trim();
    }, htmlContent);

    copy(
      `<div style="font-size: 10px; font-family: 'Arial', sans-serif;'">${updatedHtmlContent}</div>`,
      {
        asHtml: true,
      },
    );

    dispatch(notifySuccess('Documentation copied to clipboard successfully!'));
  };

  const toggleEditDocumentation = () => {
    setEditingAllDocs(!editingAllDocs);
  };

  const cancelAllDocumentationUpdates = () => {
    setCancelContentUpdatesTrigger(!cancelContentUpdatesTrigger);
    toggleEditDocumentation();
  };

  const renderButtonsPanel = (): JSX.Element | null => {
    return (
      <Grid
        container
        justifyContent="flex-end"
        data-qa-id={qaIdBuilder('documentation-button-container')}
        className={classes.headingContainer}
        direction="row"
      >
        {editingAllDocs ? (
          <>
            <Button
              variant="outlined"
              onClick={cancelAllDocumentationUpdates}
              className={classes.editPanelLeftButton}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => saveAllDocumentationContent()}
            >
              Apply Changes
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="outlined"
              startIcon={<FileCopyOutlinedIcon />}
              onClick={() => copyAllText()}
              className={classes.editPanelLeftButton}
            >
              Copy Documentation
            </Button>
            <Button variant="outlined" startIcon={<EditIcon />} onClick={toggleEditDocumentation}>
              Edit Documentation
            </Button>
          </>
        )}
      </Grid>
    );
  };

  return (
    <>
      {renderButtonsPanel()}
      {sectionContent &&
        sectionContent.length &&
        sectionContent.map((sectionItem, index) => {
          const key = sectionItem.header || '';
          const SideComponent = getRightSideComponent(sectionItem.rightSideComponent);
          return (
            <EditableTextSection
              qaId={`section-${sectionItem?.header?.replace(/\s/g, '') || ''}-tasks-${(
                props?.tasks?.map(task => task.id) || []
              ).join('-')}`}
              title={key}
              initialContent={sectionItem.content}
              initialSecondaryContent={sectionItem.secondaryContent}
              editSection={sectionItem.editableContent}
              readOnly={sectionItem.readOnly}
              viewOnly={props.viewOnly}
              rightSideComponent={
                SideComponent &&
                sectionItem.rightSideComponent === RightSideComponent.LabValuesList ? (
                  <SideComponent
                    selectedLabs={props.objectiveSelectedLabs}
                    onSaveLabValues={props.handleSaveSelectedLabs}
                    dataCollects={props.objectiveDataCollects}
                    onDeleteSelectedLabValue={props.handleDeleteSelectedLab}
                    patientReviewData={patientReviewData}
                    taskQty={taskQty}
                    currentSelectedTasks={currentSelectedTasks}
                  />
                ) : undefined
              }
              onChange={(section, content, secondaryContent, drugSubSection) => {
                changeSectionContent(
                  section,
                  content,
                  secondaryContent,
                  drugSubSection?.drugIndex,
                  drugSubSection?.subSectionIndex,
                );
                setSaveContentUpdatesTrigger(false);
              }}
              key={`editable-text-section-${key}`}
              drugSubSections={sectionItem.drugSubSections || {}}
              showEditButton={false}
              editing={editingAllDocs}
              saveContentUpdatesTrigger={saveContentUpdatesTrigger}
              cancelContentUpdatesTrigger={cancelContentUpdatesTrigger}
              onFinishedSaving={() => setSectionFinishedSaving(index)}
            />
          );
        })}
      <AddendumsList addendums={props.addendums} />
      {renderButtonsPanel()}
      {props.addendumField?.show ? (
        <div className={classes.newAddendumField}>
          <RichTextEditor
            autoFocus
            initialValue={undefined}
            initialValueFormat={EditorValueFormat.Html}
            outputFormat={EditorValueFormat.Html}
            placeHolder=""
            features={StandardFeatures}
            featuresBar
            onChangeValue={content => {
              props.addendumField?.onChange(content);
            }}
            clearTrigger={props.addendumField.clearTrigger}
          />
        </div>
      ) : null}
    </>
  );
};
export default withStyles(styles)(Documentation);

import { useSelector } from 'react-redux';
import { IState } from 'interfaces/redux/IState';

export interface IVaccineCategory {
  id: number;
  name: string;
  vaccines: IVaccine[];
}

export interface IVaccineType {
  id: number;
  name: string;
}

export interface IVaccine {
  id: number;
  name: string;
  effectiveFor: string;
  type: IVaccineType;
  category: Omit<IVaccineCategory, 'vaccines'>;
  default_selected: boolean | null;
}

export type CategoryIdVaccineMap = Record<number, IVaccineCategory>;
export type VaccineMap = Record<number, IVaccine>;
export type VaccineTypeMap = Record<number, IVaccineType>;

export interface IVaccinesData {
  categoriesMap: CategoryIdVaccineMap;
  vaccinesMap: VaccineMap;
  typesMap: VaccineTypeMap;
}

export const useVaccinesData = (): IVaccinesData => {
  const vaccinesLookups = useSelector((state: IState) => state.lookups.vaccines) || {};

  const categoriesMap: CategoryIdVaccineMap = {};
  const vaccinesMap: VaccineMap = {};
  const typesMap: VaccineTypeMap = {};

  for (const categoryKey of Object.keys(vaccinesLookups)) {
    for (const vaccineLookup of vaccinesLookups[categoryKey]) {
      const vaccineType: IVaccineType = {
        id: vaccineLookup.type.id,
        name: vaccineLookup.type.type,
      };

      const vaccineCategory: Omit<IVaccineCategory, 'vaccines'> = {
        id: vaccineLookup.category.id,
        name: vaccineLookup.category.category,
      };

      const vaccine: IVaccine = {
        id: vaccineLookup.id,
        name: vaccineLookup.name,
        effectiveFor: vaccineLookup.effective_for,
        type: vaccineType,
        category: vaccineCategory,
        default_selected: vaccineLookup.default_selected,
      };

      if (!typesMap[vaccineType.id]) {
        typesMap[vaccineType.id] = vaccineType;
      }

      if (!vaccinesMap[vaccine.id]) {
        vaccinesMap[vaccine.id] = vaccine;
      }

      if (categoriesMap[vaccine.category.id]) {
        categoriesMap[vaccine.category.id].vaccines.push(vaccine);
      } else {
        categoriesMap[vaccine.category.id] = {
          id: vaccine.category.id,
          name: vaccine.category.name,
          vaccines: [vaccine],
        };
      }
    }
  }

  return {
    categoriesMap,
    vaccinesMap,
    typesMap,
  };
};

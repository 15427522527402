import React, { Component } from 'react';
import { Button, Popover, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Divider from 'components/divider';
import { ReactComponent as InterventionIcon } from 'lib/logos/intervention.svg';
import AddIntervention from 'containers/tasks/interventions/add-intervention';
import { PATIENT_INACTIVE } from 'constants/index';
import { styles } from './note-styles';

class Intervention extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interventionAnchor: null,
    };
    this.clickIntervention = this.clickIntervention.bind(this);
    this.closeIntervention = this.closeIntervention.bind(this);
  }

  clickIntervention(e) {
    e.stopPropagation();
    this.setState({
      interventionAnchor: e.currentTarget,
    });
  }

  closeIntervention() {
    this.setState({
      interventionAnchor: null,
    });
  }

  render() {
    const {
      classes,
      therapy,
      isTaskSidebar,
      serviceGroups,
      defaultServiceGroup,
      defaultTypeId,
      defaultTherapyIds,
      defaultCategoryId,
      handleSubmitComplete,
      taskTherapyId,
      taskType,
      tagResourceId,
      buttonOverride,
      id,
      patient,
      activeForPatient,
      disabled,
    } = this.props;
    const taskInactive = (patient.status_id === PATIENT_INACTIVE && !activeForPatient) || disabled;

    const { interventionAnchor } = this.state;
    const open = Boolean(interventionAnchor);

    // TODO - Hard code function for now, the reason is in task tab the resource table name is
    // automatically generated based on type, since for
    // intervention and incident the table name are plural, the link doesn't work, i think
    // we need to rename task_interventions
    // to task_intervention for consistency, or store the resource mapping somewhere else
    const convertToResourceTable = type => {
      if (type === 'task_intervention') {
        return 'task_interventions';
      }
      if (type === 'task_incident') {
        return 'task_incidents';
      }
      return type;
    };

    const taskAddedFromData =
      taskType && tagResourceId
        ? {
            task_type: convertToResourceTable(taskType),
            task_id: tagResourceId,
          }
        : null; // eslint-disable-line

    let buttonEl = (
      <Button
        data-qa-id={`${taskType}_intervention_button`}
        variant="outlined"
        className={classNames(classes.button, classes.interventionButton)}
        onClick={e => {
          this.clickIntervention(e);
        }}
        id={id}
        disabled={taskInactive}
      >
        <span className={classes.communicationIconContainer}>
          <InterventionIcon />
        </span>
        Intervention
      </Button>
    );

    if (buttonOverride) {
      buttonEl = (
        <button
          data-qa-id="button-intervention"
          style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            outline: 'none',
          }}
          type="button"
          onClick={e => {
            this.clickIntervention(e);
          }}
          disabled={taskInactive}
        >
          {buttonOverride}
        </button>
      );
    }

    return (
      <>
        {buttonEl}

        {open && (
          <Popover
            classes={{
              paper: classes.popoverWithOverflow,
            }}
            id="intervention-popover"
            open={open}
            anchorEl={interventionAnchor}
            onClose={this.closeIntervention}
            onClick={e => {
              e.stopPropagation();
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <div className={classes.interventionForm}>
              <Typography variant="h6"> New Intervention </Typography>
              <Divider />
              <AddIntervention
                therapy={therapy}
                taskTherapyId={taskTherapyId}
                cancel={this.closeIntervention}
                isTaskSidebar={isTaskSidebar}
                serviceGroups={serviceGroups}
                defaultServiceGroup={defaultServiceGroup}
                taskAddedFromData={taskAddedFromData}
                defaultTypeId={defaultTypeId}
                defaultTherapyIds={defaultTherapyIds}
                defaultCategoryId={defaultCategoryId}
                handleSubmitComplete={handleSubmitComplete}
              />
            </div>
          </Popover>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { patient } = state;
  return {
    patient,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(() => mapStateToProps, null),
)(Intervention);

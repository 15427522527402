import {
  FETCH_SHIPPING_HOLIDAYS_REQUEST,
  FETCH_DAYS_SHIPPING_REQUEST,
  ADD_SHIPPING_HOLIDAYS_REQUEST,
  ADD_WEEKDAYS_REQUEST,
  FETCH_SHIPPING_HOLIDAYS_SUCCESS,
  FETCH_DAYS_SHIPPING_SUCCESS,
} from 'constants/index';

import {
  IHolidayFormFields,
  IRequestAddHolidayDate,
} from 'containers/application-manager/holidays/interface';

import {
  IDayAreaShipping,
  IRequestAddWeekday,
} from 'containers/application-manager/day-area-shipping/day-area-shipping.interface';
import HTTP from 'services/http';

export const fetchShippingHolidaysRequest = (customerId: number) => ({
  type: FETCH_SHIPPING_HOLIDAYS_REQUEST,
  payload: HTTP.get(`/shipping/customer/${customerId}/holidays`, {}),
});

export const fetchDaysShippingRequest = (customerId: number) => ({
  type: FETCH_DAYS_SHIPPING_REQUEST,
  payload: HTTP.get(`/area_shipping/customer/${customerId}/days_shipping`, {}),
});

export const fetchShippingHolidaysSuccess = (payload: any) => ({
  type: FETCH_SHIPPING_HOLIDAYS_SUCCESS,
  payload,
});

export const fetchDaysShippingSuccess = (payload: any) => ({
  type: FETCH_DAYS_SHIPPING_SUCCESS,
  payload,
});

export const addShippingHoliday = (
  { holidayDate, holidayTypes }: IHolidayFormFields,
  customerId: number,
) => {
  const data: IRequestAddHolidayDate = {
    date: holidayDate,
    holidayTypes,
    customerId,
  };

  const request = HTTP.post('/shipping/customer/holidays', data);
  return {
    type: ADD_SHIPPING_HOLIDAYS_REQUEST,
    payload: request,
    customerId,
  };
};

export const addWeekday = ({ weekday, zipCodes }: IDayAreaShipping, customerId: number) => {
  const data: IRequestAddWeekday = {
    weekday,
    zipCodes,
    customerId,
  };

  const request = HTTP.post('/area_shipping/customer/weekday', data);
  return {
    type: ADD_WEEKDAYS_REQUEST,
    payload: request,
    customerId,
  };
};

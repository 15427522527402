import { IndexType } from 'interfaces/IndexType';
import { IState } from 'interfaces/redux/IState';
import { AxiosPromise } from 'axios';
import HTTP from 'services/http';
import store from '../../../store';
import { IModalItem, IModalItemChecked, IPopoverStateBase } from './types';

const baseUrl = '/application-manager/gtd';

export const convertToFlatModalItems = <TParent extends { children: TChild[] }, TChild>(
  parents: TParent[],
  parentPkSelector: (parent: TParent) => IndexType,
  childPkSelector: (child: TChild) => IndexType,
  labelFormatter: (child: TChild) => string,
): IModalItem[] => {
  const results: IModalItem[] = [];

  parents.forEach(parent => {
    const parentPk = parentPkSelector(parent);
    parent.children.forEach(child => {
      const childPk = childPkSelector(child);
      results.push({
        childPk: childPk,
        parentPk: parentPk,
        label: labelFormatter(child),
        checked: true,
      });
    });
  });

  return results;
};

export const toggleModalChecked = <IModalState extends { items?: IModalItem[] }>(
  checked: boolean,
  item: IModalItem,
  currentState: IModalState,
  stateSetter: (newState: IModalState) => void,
  modalTriggerSetter: (item: IModalItemChecked) => void,
) => {
  // Update the state of the modal
  const newState = { ...currentState };

  const matchingItem = newState.items?.find(
    x => x.childPk === item.childPk && x.parentPk === item.parentPk,
  );
  if (matchingItem) {
    matchingItem.checked = checked;
  }

  stateSetter(newState);

  // Update the part of the state used to trigger the child table update
  modalTriggerSetter({ parentPk: item.parentPk, childPk: item.childPk, checked: checked });
};

export const therapeuticClassConvert = (value: unknown): string => {
  const defualtValue = '-';
  if (value && typeof value === 'number') {
    const trellisTherapeuticClasses = (store.getState() as unknown as IState).lookups
      .trellisTherapeuticsClasses;
    const therapeuticClass = trellisTherapeuticClasses.find(x => x.id === (value as number));
    if (therapeuticClass) {
      return therapeuticClass.name;
    }
  }

  return defualtValue;
};

export const updateItemRequest = <TParent, TChild>(
  popoverState: IPopoverStateBase<TParent, TChild>,
  value: unknown,
  childPkSelector: (child: TChild) => number,
  parentPkSelector: (parent: TParent) => number,
  currentlyActive: boolean,
): AxiosPromise<unknown> => {
  if (popoverState.field) {
    let ddi: number | undefined;
    let isParent: boolean | undefined;
    if (popoverState.child) {
      ddi = childPkSelector(popoverState.child);
      isParent = false;
    } else if (popoverState.parent) {
      ddi = parentPkSelector(popoverState.parent);
      isParent = true;
    } else {
      throw new Error('Neither child or parent selected');
    }

    if (popoverState.forceAsParent === true) {
      isParent = true;
    }

    const requestBody = [
      {
        parent: isParent,
        drugDescriptorId: ddi,
        currentlyActive: currentlyActive,
        fieldsToUpdate: {
          [popoverState.field as IndexType]: value,
        },
      },
    ];

    return HTTP.patch(baseUrl, requestBody);
  }

  throw new Error('Popover state is missing a field');
};

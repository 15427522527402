import { IState } from 'interfaces/redux/IState';
import { useSelector } from 'react-redux';
import { TasksUtil } from 'utils/tasks-util';
import { FDC } from 'constants/index';
import { dynamicFillDeliveryConfirmationStatusMap } from 'constants/task-statuses';
import { TaskCategory } from 'interfaces/enums/TaskCategory';
import { IFillDeliveryConfirmationTask } from 'interfaces/redux/task-types/IFillDeliveryConfirmationTask';
import { ICounselingTask } from 'interfaces/redux/task-types/ICounselingTask';
import { FillDeliveryConfirmationStatus } from 'interfaces/enums/TaskStatuses/FillDeliveryConfirmationStatus';
import moment from 'moment';
import { IAdherenceSectionData } from '../chart-review/interfaces/IAdherenceSection';

const getFdcDate = (
  task: IFillDeliveryConfirmationTask,
): { stringVer: string | undefined; momentVer: moment.Moment | undefined } => {
  let dateString: string | undefined;

  switch (task.status_id) {
    case FillDeliveryConfirmationStatus.Delivered:
      dateString = task.delivered_date;
      break;
    case FillDeliveryConfirmationStatus.PickedUp:
      dateString = task.picked_up_date;
      break;
  }

  let stringVer: string | undefined;
  let momentVer: moment.Moment | undefined;

  if (dateString) {
    momentVer = moment(dateString);
    if (momentVer.isValid()) {
      stringVer = momentVer.utc().format('MM/DD/YYYY');
    }
  }

  return { stringVer, momentVer };
};

const useLatestFdcs = (
  tasks: ICounselingTask[],
  limit: number | undefined = 3,
): IAdherenceSectionData[] => {
  const allTasks = useSelector((state: IState) => state.tasks.data);
  const allTherapies = useSelector((state: IState) => state.therapies.data);
  const fdcTasks = TasksUtil.getTasksOfType<IFillDeliveryConfirmationTask>(
    Object.values(allTasks),
    FDC,
  );
  const fdcStatusMap = dynamicFillDeliveryConfirmationStatusMap();
  const completedStatuses = Object.entries(fdcStatusMap)
    .filter(([key, value]) => value.category === TaskCategory.Done)
    .map(x => x[0]);
  const completedFdcs = fdcTasks
    .filter(x => completedStatuses.includes(x.status_id.toString()))
    .filter(x => x.delivered_date || x.picked_up_date);

  const results: { therapyName: string; fdcItems: string[] }[] = [];

  tasks.forEach(task => {
    const { therapy_id } = task;
    const therapy = allTherapies[task.therapy_id];

    const completedFdcsForThisTherapy = completedFdcs
      .filter(fdc => {
        const therapyIds = fdc.therapy_ids.split(',');
        return therapyIds.includes(therapy_id.toString());
      })
      .map(fdc => {
        return { fdcDate: getFdcDate(fdc), fillCycleNumber: fdc.fill_cycle_number };
      })
      .filter(x => x.fdcDate.momentVer !== undefined)
      .sort((a, b) => b.fdcDate.momentVer!.valueOf() - a.fdcDate.momentVer!.valueOf())
      .slice(0, limit);

    results.push({
      therapyName: therapy?.drug_name || '-',
      fdcItems: completedFdcsForThisTherapy.map(
        fdc => `Fill Cycle: #${fdc.fillCycleNumber} ${fdc.fdcDate.stringVer}`,
      ),
    });
  });

  return results;
};

export { useLatestFdcs };

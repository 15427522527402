import { IMedicationListItem } from 'interfaces/redux/IMedications';
import { MedicationStatus } from 'constants/enums';

export const getGroupedMedications = (
  medicationGroups: IMedicationListItem[],
): Record<string, IMedicationListItem> => {
  const filteredMedicationList = medicationGroups.reduce<Record<string, IMedicationListItem>>(
    (acc, curr) => {
      if (!acc[curr.ndc] && curr.status_code === MedicationStatus.Active) {
        return {
          ...acc,
          [curr.ndc]: curr,
        };
      }

      return acc;
    },
    {},
  );

  return filteredMedicationList;
};

import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field } from 'redux-form';
import { useTypedSelector } from 'hooks/use-typed-selector';
import {
  renderDropdown,
  renderTextField,
  renderReactRadioGroup,
} from 'components/form/field/redux-field';
import { required } from 'components/form/validation/validation';
import { Close as CloseIcon } from '@mui/icons-material';
import { ILabelValueNumber } from 'interfaces/ILabelValue';
import { equalityOperators, monthsList } from 'constants/lists';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { getClinicalDataTypesMap } from 'services/utils/data-collect';
import { Timezone } from 'models/time/timezone';
import momentBD from 'moment-business-days';
import { ClinicalItemProtocolFormModalStyles } from './styles';
import { numberParser } from '../utils';
import { THERAPY_ACTIVE_STATUS } from '../../../../constants/therapy-administration-statuses';
import { TEXT_CLINICAL_DATA_TYPE_OPTIONS } from '../../../../constants/index';
import { IEditEfficacyFormFieldsDefaultValues } from './types';

const useStyles = makeStyles(ClinicalItemProtocolFormModalStyles);

interface IClinicalItemsList extends ILabelValueNumber {
  type: string;
}

interface IEfficacyProtocolComponent {
  efficacyProtocolData: { id: number }[];
  deleteDynamicEfficacyProtocol: (id: number) => void;
  diseaseStateData: { id: number; diseaseName: string; icdCode: string[]; selected?: boolean }[];
  setFieldValue: (field: string, value: any) => void;
  consecutiveInterventionsOptions: { label: string; value: number }[];
  protocolStatus: { label: string; value: number }[];
  clinicalItems: IClinicalItemsList[];
  incorrectEfficacyInterventionScoreValue: number[];
  formListOfValues: any;
  updateIncorrectEfficacyInterventionScoreValue: (newValues: number[]) => void;
  incorrectEfficacyExpirationDateValue: number[];
  updateIncorrectEfficacyExpirationDateValue: (newValues: number[]) => void;
  editEfficacyFormValues: IEditEfficacyFormFieldsDefaultValues | null;
}

enum MonthsAgoCriteria {
  required = 1,
  notRequired = 2,
}

const dynamicFields = [
  { fieldName: 'efficacy_clinical_item_', defaultValue: [] },
  { fieldName: 'efficacy_intervention_criteria_', defaultValue: [] },
  { fieldName: 'efficacy_criteria_clinical_data_operator_', defaultValue: [] },
  { fieldName: 'efficacy_criteria_clinical_data_value_', defaultValue: '' },
  { fieldName: 'efficacy_intervention_score_value_', defaultValue: '' },
  { fieldName: 'efficacy_consecutive_intervention_scores_', defaultValue: 0 },
  { fieldName: 'therapy_status_', defaultValue: [] },
  { fieldName: 'therapy_status_operator_', defaultValue: [] },
  { fieldName: 'months_ago_', defaultValue: null },
  { fieldName: 'efficacy_protocol_status_', defaultValue: [] },
  { fieldName: 'efficacy_effective_date_', defaultValue: null },
  { fieldName: 'efficacy_expiration_date_', defaultValue: null },
  { fieldName: 'efficacy_consecutive_type_', defaultValue: [] },
];

const fixedFields = [
  { fieldName: 'disease_state', defaultValue: [] },
  { fieldName: 'icd10', defaultValue: '' },
];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const EfficacyProtocolComponent: React.FC<IEfficacyProtocolComponent> = (
  props,
): JSX.Element => {
  const [disableInterventionScoreValue, setDisableInterventionScoreValue] = useState<number[]>([]);
  const [currentConsecutiveIntScore, setCurrentConsecutiveIntScore] = useState<
    { id: number; value: string }[]
  >([]);
  const [requiredMonthsAgo, setRequiredMonthsAgo] = useState<number[]>([]);
  const [selectedClinicalItems, setSelectedClinicalItems] = useState<
    { groupId: number; clinicalItemId: number; type: string | undefined }[]
  >([]);
  const [requiredEfficacyInterventionCriteria, setRequiredEfficacyInterventionCriteria] = useState<
    { groupId: number; options: { label: string; value: string }[] }[]
  >([]);
  const [consecutiveTypesList, setConsecutiveTypesList] = useState<ILabelValueNumber[]>([]);
  const [disableThresholdAndValue, setDisableThresholdAndValue] = useState<number[]>([]);
  const clinicalDataTypes = useTypedSelector(state => state.lookups.clinicalDataTypes);
  const consecutiveTypes = useTypedSelector(state => state.lookups.consecutiveTypes) || [];

  const {
    efficacyProtocolData: efficacyProtocolList,
    deleteDynamicEfficacyProtocol,
    diseaseStateData,
    setFieldValue,
    consecutiveInterventionsOptions,
    protocolStatus,
    clinicalItems,
    incorrectEfficacyInterventionScoreValue,
    formListOfValues,
    updateIncorrectEfficacyInterventionScoreValue,
    incorrectEfficacyExpirationDateValue,
    updateIncorrectEfficacyExpirationDateValue,
    editEfficacyFormValues,
  } = props;
  const classes = useStyles();

  const getIcdCodes = (diseaseStateId: number) => {
    const selectedDiseaseSate = diseaseStateData.find(t => t.id === diseaseStateId);
    setFieldValue('icd10', selectedDiseaseSate?.icdCode.join());
  };

  const updateConsecutiveInterventionFieldState = (value: number, groupId: number) => {
    if (value === 1) {
      setDisableInterventionScoreValue(disableInterventionScoreValue.filter(t => t !== groupId));
      setDisableThresholdAndValue(disableThresholdAndValue => {
        return [...disableThresholdAndValue, groupId];
      });
      setFieldValue(`efficacy_criteria_clinical_data_operator_${groupId}`, []);
      setFieldValue(`efficacy_criteria_clinical_data_value_${groupId}`, '');
    }
    if (value === 0) {
      setFieldValue(`efficacy_intervention_score_value_${groupId}`, '');
      setFieldValue(`efficacy_consecutive_type_${groupId}`, []);
      updateIncorrectEfficacyInterventionScoreValue(
        incorrectEfficacyInterventionScoreValue.filter(t => t !== groupId),
      );
      setDisableInterventionScoreValue(disableInterventionScoreValue => {
        return [...disableInterventionScoreValue, groupId];
      });
      setDisableThresholdAndValue(disableThresholdAndValue.filter(t => t !== groupId));
    }
    trackIntScoreValues(groupId, '');
  };

  const updateMonthsAgoState = (criteria: number, groupId: number) => {
    if (criteria === MonthsAgoCriteria.required) {
      setRequiredMonthsAgo(requiredMonthsAgo => {
        return [...requiredMonthsAgo, groupId];
      });
    }
    if (criteria === MonthsAgoCriteria.notRequired) {
      setRequiredMonthsAgo(requiredMonthsAgo.filter(t => t !== groupId));
    }
  };

  const setClinicalItemOptionValues = (value: number, groupId: number) => {
    setFieldValue(`efficacy_criteria_clinical_data_operator_${groupId}`, []);
    setFieldValue(`efficacy_criteria_clinical_data_value_${groupId}`, '');
    const map = getClinicalDataTypesMap(clinicalDataTypes);
    const selectedItemOptions = Object.values(map).reduce(
      (acc: { label: string; value: string }[], item: any) => {
        if (parseInt(item.dataTypeId, 10) === value && item.options && item.options.length) {
          const itemOptions = item.options.map((t: string) => {
            acc.push({
              label: t,
              value: t,
            });
          });
        }
        return acc;
      },
      [] as { label: string; value: string }[],
    );
    setRequiredEfficacyInterventionCriteria(requiredEfficacyInterventionCriteria => {
      const tempOpts = requiredEfficacyInterventionCriteria.filter(t => t.groupId !== groupId);
      return [
        ...tempOpts,
        {
          groupId: groupId,
          options: selectedItemOptions,
        },
      ];
    });
    setSelectedClinicalItems(selectedClinicalItems => {
      const tempSelectedItems = selectedClinicalItems.filter(t => t.groupId !== groupId);
      return [
        ...tempSelectedItems,
        {
          groupId: groupId,
          clinicalItemId: value,
          type: clinicalItems.find(t => t.value === value)?.type ?? undefined,
        },
      ];
    });
  };

  const updateRequiredEfficacyInterventionCriteria = (groupId: number): void => {
    setRequiredEfficacyInterventionCriteria(
      requiredEfficacyInterventionCriteria.filter(t => t.groupId !== groupId),
    );
  };

  const updateSelectedClinicalItems = (groupId: number): void => {
    setSelectedClinicalItems(selectedClinicalItems.filter(t => t.groupId !== groupId));
  };

  const resetFields = (groupId: number): void => {
    if (efficacyProtocolList.length === 1) {
      fixedFields.map(f => setFieldValue(f.fieldName, f.defaultValue));
    }
    dynamicFields.map(f => setFieldValue(`${f.fieldName}${groupId}`, f.defaultValue));
  };

  const getEfficacyInterventionCriteriaOptions = (
    groupId: number,
  ): { label: string; value: string }[] => {
    const obj = requiredEfficacyInterventionCriteria.filter(t => t.groupId === groupId);
    return obj && Object.keys(obj).length > 0 ? obj[0].options : [];
  };

  const trackIntScoreValues = (id: number, value: string) => {
    const currentConsecutiveScoreObj = currentConsecutiveIntScore.find(t => t.id === id);
    if (currentConsecutiveScoreObj === undefined) {
      setCurrentConsecutiveIntScore(currentConsecutiveIntScore => {
        return [
          ...currentConsecutiveIntScore,
          {
            id,
            value,
          },
        ];
      });
    } else {
      setCurrentConsecutiveIntScore(currentConsecutiveIntScore => {
        const tempValues = currentConsecutiveIntScore.map(t => {
          if (t.id === id) {
            return {
              ...t,
              value,
            };
          }
          return t;
        });
        return tempValues;
      });
    }
  };

  useEffect(() => {
    setConsecutiveTypesList(
      consecutiveTypes.reduce((acc, t) => {
        acc.push({
          label: t.type,
          value: t.id,
        });
        return acc;
      }, [] as ILabelValueNumber[]),
    );
  }, [consecutiveTypes]);

  useEffect(() => {
    if (editEfficacyFormValues && Object.keys(editEfficacyFormValues).length) {
      if (editEfficacyFormValues.specialty_type_id && diseaseStateData.length) {
        const selectedSpecialty = diseaseStateData.find(
          t => t.id === editEfficacyFormValues.specialty_type_id,
        );
        setFieldValue('disease_state', {
          value: selectedSpecialty?.id,
          label: selectedSpecialty?.diseaseName,
        });
        if (selectedSpecialty && selectedSpecialty.id) getIcdCodes(selectedSpecialty.id);
      }
      if (editEfficacyFormValues.clinical_item_id) {
        const selectedClinicalItem = clinicalItems.find(
          t => t.value === editEfficacyFormValues.clinical_item_id,
        );
        if (selectedClinicalItem && Object.keys(selectedClinicalItem).length) {
          setFieldValue('efficacy_clinical_item_1', selectedClinicalItem.value);
          setClinicalItemOptionValues(selectedClinicalItem.value, 1);
        }
      }
      if (editEfficacyFormValues.options) {
        const tempPossibleOptions = editEfficacyFormValues.options
          .split(',')
          .map(t => t.toLowerCase().trim());
        const intCriteriaOpts = getEfficacyInterventionCriteriaOptions(1);
        if (tempPossibleOptions.length && intCriteriaOpts.length) {
          const selectedOpts = [];
          for (const opt of intCriteriaOpts) {
            if (tempPossibleOptions.includes(opt.label.toLowerCase().trim())) {
              selectedOpts.push(opt);
            }
          }
          setFieldValue(
            'efficacy_intervention_criteria_1',
            selectedOpts.map(t => t.value).join(','),
          );
        }
      }
      if (editEfficacyFormValues.clinical_data_operator) {
        const thresholdOp = equalityOperators.find(
          t => t.value === editEfficacyFormValues.clinical_data_operator,
        );
        if (thresholdOp) {
          setFieldValue('efficacy_criteria_clinical_data_operator_1', thresholdOp.value);
        }
      }
      if (editEfficacyFormValues.clinical_data_value) {
        setFieldValue(
          'efficacy_criteria_clinical_data_value_1',
          editEfficacyFormValues.clinical_data_value,
        );
      }
      if (
        editEfficacyFormValues.consecutive_intervention_score !== null &&
        editEfficacyFormValues.consecutive_intervention_score !== undefined
      ) {
        setFieldValue(
          'efficacy_consecutive_intervention_scores_1',
          Number(editEfficacyFormValues.consecutive_intervention_score),
        );
        updateConsecutiveInterventionFieldState(
          Number(editEfficacyFormValues.consecutive_intervention_score),
          1,
        );
        if (
          editEfficacyFormValues.consecutive_intervention_score &&
          editEfficacyFormValues.consecutive_type_id
        ) {
          setFieldValue('efficacy_consecutive_type_1', editEfficacyFormValues.consecutive_type_id);
        }
        if (editEfficacyFormValues.consecutive_value) {
          setFieldValue(
            'efficacy_intervention_score_value_1',
            editEfficacyFormValues.consecutive_value,
          );
        }
      }
      if (editEfficacyFormValues.therapy_status) {
        setFieldValue('therapy_status_1', editEfficacyFormValues.therapy_status);
      }
      if (editEfficacyFormValues.therapy_operator) {
        setFieldValue('therapy_status_operator_1', editEfficacyFormValues.therapy_operator);
      }
      if (editEfficacyFormValues.months_ago) {
        setFieldValue('months_ago_1', editEfficacyFormValues.months_ago);
      }
      if (editEfficacyFormValues.status !== null && editEfficacyFormValues.status !== undefined) {
        setFieldValue('efficacy_protocol_status_1', Number(editEfficacyFormValues.status));
      }
      const customerTimezone = Timezone.getInstance().timezone;
      if (
        editEfficacyFormValues.effective_date !== null &&
        editEfficacyFormValues.effective_date !== undefined
      ) {
        setFieldValue(
          'efficacy_effective_date_1',
          momentBD
            .utc(editEfficacyFormValues.effective_date)
            .tz(customerTimezone, true)
            .startOf('day')
            .toDate(),
        );
      }
      if (
        editEfficacyFormValues.expiration_date !== null &&
        editEfficacyFormValues.expiration_date !== undefined
      ) {
        setFieldValue(
          'efficacy_expiration_date_1',
          momentBD
            .utc(editEfficacyFormValues.expiration_date)
            .tz(customerTimezone, true)
            .startOf('day')
            .toDate(),
        );
      }
    }
  }, [editEfficacyFormValues, diseaseStateData]);

  return (
    <>
      {efficacyProtocolList.map((x, ind) => {
        return (
          <Grid container spacing={2}>
            {ind === 0 && (
              <Grid container className={classes.fixedSection}>
                <Grid item xs={7}>
                  <Field
                    name="disease_state"
                    label="Disease State *"
                    component={renderDropdown}
                    validate={[required]}
                    disabled={editEfficacyFormValues && editEfficacyFormValues.specialty_type_id}
                    fields={diseaseStateData.map(t => {
                      return {
                        label: t.diseaseName,
                        value: t.id,
                      };
                    })}
                    onChange={(value: any) => getIcdCodes(value)}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Field name="icd10" label="ICD-10" component={renderTextField} disabled />
                </Grid>
              </Grid>
            )}
            <Grid container className={classes.interventionCriteriaRow}>
              <Grid item xs={11}>
                <Typography className={classes.protocolSubtitle}>Intervention Criteria</Typography>
              </Grid>
              <Grid
                item
                xs={1}
                className={classes.centerIcon}
                key={`efficacy_delete_icon_${x.id}`}
                onClick={() => {
                  deleteDynamicEfficacyProtocol(x.id);
                  updateMonthsAgoState(MonthsAgoCriteria.notRequired, x.id);
                  updateRequiredEfficacyInterventionCriteria(x.id);
                  updateSelectedClinicalItems(x.id);
                  resetFields(x.id);
                }}
              >
                <CloseIcon />
              </Grid>
            </Grid>
            <Grid container item spacing={2} className={classes.interventionCriteriaFields}>
              <Grid item xs={12}>
                <Field
                  name={`efficacy_clinical_item_${x.id}`}
                  label="Clinical Item *"
                  component={renderDropdown}
                  validate={[required]}
                  fields={clinicalItems}
                  onChange={(value: any) => setClinicalItemOptionValues(value, x.id)}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name={`efficacy_intervention_criteria_${x.id}`}
                  label={
                    getEfficacyInterventionCriteriaOptions(x.id).length ? 'Options *' : 'Options'
                  }
                  component={renderDropdown}
                  isMulti
                  validate={getEfficacyInterventionCriteriaOptions(x.id).length ? [required] : []}
                  disabled={!getEfficacyInterventionCriteriaOptions(x.id).length}
                  fields={getEfficacyInterventionCriteriaOptions(x.id)}
                />
              </Grid>
              <Grid item xs={5}>
                <Field
                  name={`efficacy_criteria_clinical_data_operator_${x.id}`}
                  label={
                    !disableThresholdAndValue.includes(x.id) &&
                    !getEfficacyInterventionCriteriaOptions(x.id).length
                      ? 'Threshold Symbol *'
                      : 'Threshold Symbol'
                  }
                  component={renderDropdown}
                  validate={
                    disableThresholdAndValue.includes(x.id)
                      ? []
                      : !getEfficacyInterventionCriteriaOptions(x.id).length
                      ? [required]
                      : []
                  }
                  disabled={
                    getEfficacyInterventionCriteriaOptions(x.id).length ||
                    disableThresholdAndValue.includes(x.id)
                  }
                  fields={equalityOperators}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name={`efficacy_criteria_clinical_data_value_${x.id}`}
                  label={
                    !disableThresholdAndValue.includes(x.id) &&
                    !getEfficacyInterventionCriteriaOptions(x.id).length
                      ? 'Value *'
                      : 'Value'
                  }
                  component={renderTextField}
                  disabled={
                    getEfficacyInterventionCriteriaOptions(x.id).length ||
                    disableThresholdAndValue.includes(x.id)
                  }
                  validate={
                    disableThresholdAndValue.includes(x.id)
                      ? []
                      : !getEfficacyInterventionCriteriaOptions(x.id).length
                      ? [required]
                      : []
                  }
                  onKeyUp={(e: any) => {
                    const selectedItem = selectedClinicalItems.find(t => t.groupId === x.id)?.type;
                    TEXT_CLINICAL_DATA_TYPE_OPTIONS.includes(selectedItem ?? '')
                      ? ''
                      : numberParser(e);
                  }}
                />
              </Grid>
              <Grid xs={3} />
              <Grid
                item
                xs={3}
                className={
                  getEfficacyInterventionCriteriaOptions(x.id).length ? classes.disabledRow : ''
                }
              >
                <Field
                  label="Consecutive intervention scores *"
                  name={`efficacy_consecutive_intervention_scores_${x.id}`}
                  initialValueName={
                    consecutiveInterventionsOptions.find(t => t.label.toLowerCase() === 'no')?.label
                  }
                  radioMap={consecutiveInterventionsOptions}
                  component={renderReactRadioGroup}
                  validate={[required]}
                  width="100%"
                  onChange={(value: any) => updateConsecutiveInterventionFieldState(value, x.id)}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name={`efficacy_consecutive_type_${x.id}`}
                  label={
                    !disableInterventionScoreValue.includes(x.id)
                      ? 'Consecutive Type *'
                      : 'Consecutive Type'
                  }
                  component={renderDropdown}
                  disabled={disableInterventionScoreValue.includes(x.id)}
                  validate={!disableInterventionScoreValue.includes(x.id) ? [required] : []}
                  fields={consecutiveTypesList}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name={`efficacy_intervention_score_value_${x.id}`}
                  label={
                    !disableInterventionScoreValue.includes(x.id)
                      ? 'Number of consecutive intervention scores *'
                      : 'Number of consecutive intervention scores'
                  }
                  component={renderTextField}
                  disabled={disableInterventionScoreValue.includes(x.id)}
                  meta={
                    incorrectEfficacyInterventionScoreValue.includes(x.id)
                      ? {
                          touched: incorrectEfficacyInterventionScoreValue.includes(x.id),
                          error: 'Value must be greater than zero (0)',
                        }
                      : !disableInterventionScoreValue.includes(x.id) &&
                        (currentConsecutiveIntScore.find(t => t.id === x.id)?.value.length === 0 ||
                          currentConsecutiveIntScore.find(t => t.id === x.id) === undefined)
                      ? { touched: true, error: 'REQUIRED' }
                      : { touched: disableInterventionScoreValue.includes(x.id), error: '' }
                  }
                  onKeyUp={(e: any) => {
                    numberParser(e);
                  }}
                  onBlur={(e: any) => {
                    trackIntScoreValues(x.id, e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name={`therapy_status_${x.id}`}
                  label="Therapy Status"
                  deafultValue=""
                  component={renderDropdown}
                  fields={THERAPY_ACTIVE_STATUS}
                />
              </Grid>
              <Grid item xs={5}>
                <Field
                  name={`therapy_status_operator_${x.id}`}
                  label="Threshold Symbol"
                  component={renderDropdown}
                  fields={equalityOperators}
                  onChange={() => updateMonthsAgoState(MonthsAgoCriteria.required, x.id)}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name={`months_ago_${x.id}`}
                  label={requiredMonthsAgo.includes(x.id) ? 'Months ago * ' : 'Months ago'}
                  component={renderDropdown}
                  fields={monthsList}
                  validate={requiredMonthsAgo.includes(x.id) ? [required] : []}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name={`efficacy_protocol_status_${x.id}`}
                  label="Protocol Status *"
                  component={renderDropdown}
                  validate={[required]}
                  fields={protocolStatus}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name={`efficacy_effective_date_${x.id}`}
                  label="Effective date *"
                  component={renderDatePicker}
                  validate={[required]}
                  onChange={(value: any) =>
                    updateIncorrectEfficacyExpirationDateValue(
                      incorrectEfficacyExpirationDateValue.filter(t => t !== x.id),
                    )
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name={`efficacy_expiration_date_${x.id}`}
                  label="Expiration date"
                  component={renderDatePicker}
                  meta={
                    incorrectEfficacyExpirationDateValue.includes(x.id)
                      ? {
                          touched: incorrectEfficacyExpirationDateValue.includes(x.id),
                          error: 'It must be after effective date',
                        }
                      : { touched: !incorrectEfficacyExpirationDateValue.includes(x.id), error: '' }
                  }
                  onChange={(value: any) =>
                    updateIncorrectEfficacyExpirationDateValue(
                      incorrectEfficacyExpirationDateValue.filter(t => t !== x.id),
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

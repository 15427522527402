import createStyles from '@mui/styles/createStyles';

export const styles = theme =>
  createStyles({
    clinicalContainer: {
      paddingLeft: 30,
      paddingRight: 30,
    },
    allergyContainer: {
      border: `1px solid ${theme.palette.primary.grey25}`,
      borderRadius: theme.spacing(0.5),
      marginTop: theme.spacing(1),
      padding: theme.spacing(0.2),
    },
    addAllergyFormContainer: {
      border: `1px solid ${theme.palette.primary.grey25}`,
      borderRadius: theme.spacing(0.5),
      marginTop: theme.spacing(1),
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    addElementTitleWrapper: {
      padding: theme.spacing(1),
    },
    addElementTitle: {
      fontSize: theme.font.extraLargeFontSize,
      fontWeight: theme.font.weight.thick,
    },
    smallPaddedContainer: {
      padding: theme.spacing(1),
    },
    addDataContainer: {
      margin: 10,
      padding: 10,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.primary.task.borderColor,
      borderRadius: 5,
    },
    addDataButton: {
      paddingTop: 10,
      paddingBottom: 10,
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
    tableContainer: {
      width: `${theme.layout.taskBarWidth + theme.layout.taskListWidth}px`,
      maxWidth: '100%',
    },
    actionWording: {
      fontSize: 12,
      padding: '7px 5px',
    },
    therapyModal: {
      position: 'absolute',
      width: '85%',
      backgroundColor: theme.palette.primary.background,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      maxHeight: 800,
      overflow: 'auto',
    },
    addMedicationModal: {
      position: 'absolute',
      width: theme.spacing(120),
      height: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.primary.background,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
    },
    editAllergyModal: {
      position: 'absolute',
      width: theme.spacing(120),
      overflow: 'visible',
      backgroundColor: theme.palette.primary.background,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
    },
    mergeMedicationModal: {
      position: 'absolute',
      width: theme.spacing(150),
      overflow: 'auto',
      backgroundColor: theme.palette.primary.background,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
    },
    medicationNameLabel: {
      fontWeight: 'bold',
      fontSize: theme.font.mediumFontSize,
    },
    medicationLinkLabel: {
      fontWeight: 'bold',
      fontSize: theme.font.mediumFontSize,
      color: theme.typography.title.color,
      marginTop: 1,
    },
    medicationLink: {
      'padding': 0,
      '&:hover': {
        backgroundColor: theme.palette.primary.white,
      },
    },
    addTherapyModal: {
      position: 'absolute',
      width: theme.spacing(120),
      backgroundColor: theme.palette.primary.background,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      overflow: 'auto',
      maxHeight: '92vh',
    },
    specialtyTag: {
      'borderRadius': 5,
      'color': theme.palette.primary.white,
      'padding': theme.spacing(0, 0.5),
      'marginLeft': 5,
      'backgroundColor': theme.palette.primary.success,
      'fontWeight': theme.font.weight.thick,
      '&.CDM': {
        backgroundColor: theme.palette.primary.yellow,
        color: theme.palette.primary.deepBlue,
      },
      '&.OTF': {
        backgroundColor: theme.palette.primary.deepBlue,
        color: theme.palette.primary.white,
      },
    },
    buttonMargin: {
      marginRight: 10,
    },
    touchedButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.getContrastText(theme.palette.error.main),
    },
    verifiedButton: {
      'backgroundColor': theme.palette.primary.success,
      'color': theme.palette.getContrastText(theme.palette.primary.success),
      '&:hover': {
        backgroundColor: theme.palette.primary.success,
        color: theme.palette.getContrastText(theme.palette.primary.success),
      },
    },
    fieldContainer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    medFormConfirmation: {
      marginTop: -10,
      transform: 'scale(0.8)',
    },
    historyModal: {
      position: 'absolute',
      width: theme.spacing(150),
      height: '80%',
      backgroundColor: theme.palette.primary.background,
      boxShadow: theme.shadows[5],
      borderRadius: 5,
    },
    allergiesHistoryModal: {
      position: 'absolute',
      width: theme.spacing(150),
      backgroundColor: theme.palette.primary.background,
      boxShadow: theme.shadows[5],
      borderRadius: 5,
      padding: theme.spacing(),
    },
    popover: {
      overflow: 'visible',
      padding: 20,
      minWidth: 200,
    },
    medFormContainer: {
      minHeight: 33,
      width: '100%',
    },
    medFormHeader: {
      marginBottom: 15,
    },
    mergeButton: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      height: 35,
    },
    editHistoryCell: {
      width: 140,
      padding: 0,
    },
    rowButton: {
      marginLeft: 5,
      marginRight: 5,
      backgroundColor: theme.palette.primary.background,
    },
    buttonIconSpacing: {
      paddingLeft: 10,
      paddingTop: 2,
    },
    subFormContainer: {
      overflow: 'auto',
      border: '1px solid',
      marginTop: 15,
      marginBottom: 15,
      padding: 10,
      borderColor: theme.palette.primary.main,
      borderRadius: 5,
    },
    allergyRow: {
      '&:hover': {
        backgroundColor: theme.palette.primary.bluesky,
      },
    },
    newAllergyRow: {
      '&:hover': {
        backgroundColor: theme.palette.primary.bluesky,
      },
      'backgroundColor': theme.palette.primary.lightGreen,
    },
    allergyCell: {
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
    },
    allergyHeader: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
      paddingTop: '10px',
    },
    allergyHeaderStretched: {
      paddingLeft: 0,
    },
    medicationPanel: {
      width: '100%',
    },
    expandIcon: {
      position: 'absolute',
      left: 0,
    },
    noBorder: {
      border: 0,
    },
    groupDrug: {
      marginRight: 15,
      cursor: 'pointer',
    },
    childDrugCell: {
      paddingLeft: 20,
      transform: 'scale(0.8)',
      textAlign: 'left',
    },
    mainRow: {
      backgroundColor: theme.palette.primary.grey2,
    },
    dividerRow: {
      height: 3,
      padding: 0,
    },
    tableRow: {
      '&:hover': {
        backgroundColor: theme.palette.primary.bluesky,
      },
    },
    leftCell: {
      textAlign: 'left',
    },
    cell: {
      padding: 10,
    },
    centerCell: {
      textAlign: 'center',
    },
    editCell: {
      cursor: 'pointer',
    },
    iconCell: {
      width: 70,
    },
    moreIcon: {
      minWidth: 30,
    },
    drugCell: {
      whiteSpace: 'nowrap',
    },
    typographyHeader: {
      color: theme.palette.primary.formLabel,
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: theme.font.extraSmallFontSize,
      width: '100%',
    },
    tableAllergies: {
      backgroundColor: theme.palette.primary.white,
    },
    obsoleteNdc: {
      color: theme.palette.primary.valenciaRed,
    },
    questionnaireButton: {
      marginLeft: 10,
    },
    questionnaireButtonIcon: {
      margin: -10,
    },
    dosisRegimenLabel: {
      fontSize: theme.font.mediumFontSize,
      color: theme.palette.primary.steelGrey,
      marginTop: 1,
    },
    medicationLinkPillIcon: {
      marginLeft: 5,
      marginTop: 7,
    },
    clinicalAssessmentTable: {
      marginBottom: '1rem',
      fontFamily: "'Gotham SSm A', 'Gotham SSm B', Verdana, Geneva, Tahoma, sans-serif",
      padding: theme.spacing(0),
    },
    clinicalAssessmentTableContainer: {
      'overflow': 'scroll',
      'maxHeight': '60vh',
      '& thead th': {
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 1,
      },
    },
    clinicalAssessmentTableMain: {
      'borderSpacing': 0,
      'border': `1px solid ${theme.palette.primary.grey13}`,
      '& tr': {
        'fontSize': '0.8125rem',
        '& td': {
          borderTop: `0.1px solid ${theme.palette.primary.grey13}`,
        },
        '& :last-child': {
          '& td': {
            maxWidth: '280px',
            borderBottom: 0,
            whiteSpace: 'nowrap',
          },
        },
      },
    },
    clinicalAssessmentHeaderTh: {
      'position': 'relative',
      'margin': 0,
      'padding': '0.4rem',
      'borderRight': `1px solid ${theme.palette.primary.grey13}`,
      '& :last-child': {
        '& th': {
          borderRight: 0,
        },
      },
      'width': '100%',
      'borderTop': `1px solid ${theme.palette.primary.grey13}`,
      'borderBottom': `2px solid ${theme.palette.primary.grey13}`,
      'fontSize': '0.8125rem',
      'textAlign': 'center',
      'color': theme.palette.primary.steelGrey,
      '& button': {
        padding: '0px',
        marginLeft: '10px',
      },
      'backgroundColor': theme.palette.primary.white,
    },
    headerTh: {
      fontWeight: 300,
      fontSize: theme.spacing(1.75),
      minWidth: '100%',
    },
    headerTr: {
      'minWidth': '100%',
      'display': 'table',
      '& th > input': {
        padding: theme.spacing(0),
        textIndent: theme.spacing(1),
      },
    },
    tbodyTr: {
      textIndent: theme.spacing(1),
    },
    editActionWording: {
      fontSize: 12,
      padding: '7px 7px',
      textAlign: 'center',
      width: '45px',
    },
    filterInput: {
      minHeight: 23,
      border: `1px solid ${theme.palette.primary.grey13}`,
      minWidth: '100%',
    },
    paginationBtnsContainer: {
      backgroundColor: theme.palette.primary.white,
      border: `1px solid ${theme.palette.primary.grey13}`,
      minHeight: theme.spacing(6),
    },
    paginationBtns: {
      'padding': theme.spacing(0.5),
      '& button': {
        width: '100%',
        height: '100%',
        border: 0,
        borderRadius: theme.spacing(0.5),
        fontSize: 14,
        transition: 'all .1s ease',
        cursor: 'pointer',
        outlineWidth: 0,
      },
    },
    paginationControls: {
      'textAlign': 'center',
      '& span': {
        'height': theme.spacing(6),
        'lineHeight': theme.spacing(6),
        'display': 'inline-block',
        '& select': {
          height: theme.spacing(4.5),
          border: `1px solid ${theme.palette.primary.grey13}`,
        },
        '& input': {
          height: theme.spacing(4),
          minWidth: theme.spacing(2.5),
          border: `1px solid ${theme.palette.primary.grey13}`,
          textIndent: theme.spacing(1),
        },
      },
    },
  });

import ExpansionSection from 'components/expansion-section';
import React, { FC } from 'react';
import { getActionedByUserText } from 'utils/user-utils';
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { IPatientSectionProps } from '../interfaces/IPatientSection';
import { styles } from '../sections.styles';

const PatientSection: FC<IPatientSectionProps> = (props: IPatientSectionProps): JSX.Element => {
  const { patient, classes } = props;

  const {
    firstName,
    lastName,
    gender,
    dateOfBirth,
    age,
    diagnosis,
    therapies,
    lastUpdatedDate,
    lastUpdatedUser,
  } = patient;

  const outerSectionClasses = {
    header: classes.sectionWrapperHeader,
    expandIcon: classes.sectionWrapperExpandIcon,
    subtitle: classes.sectionWrapperSubtitle,
    expansionPanel: classes.sectionWrapperExpansionPanel,
  };

  return (
    <ExpansionSection
      title="Patient Info"
      subtitle={getActionedByUserText({
        action: 'Updated',
        user: lastUpdatedUser,
        date: lastUpdatedDate,
      })}
      classes={outerSectionClasses}
    >
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Typography className={classes.patientSectionTitle}>Patient</Typography>
          <Typography className={classes.patientSectionContent}>
            {`${firstName} ${lastName}`}
          </Typography>
          <Typography className={classes.patientSectionContent}>{gender}</Typography>
          <Typography className={classes.patientSectionContent}>
            {`${dateOfBirth} (${age})`}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.patientSectionTitle}>Active Therapies</Typography>
          {therapies.map((therapy: string, index: number) => (
            <Typography key={index} className={classes.patientSectionContent}>
              {therapy}
            </Typography>
          ))}
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.patientSectionTitle}>Diagnosis</Typography>

          {diagnosis.map((diagnosis: string, index: number) => (
            <Typography key={index} className={classes.patientSectionContent}>
              {diagnosis}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </ExpansionSection>
  );
};

export default withStyles(styles)(PatientSection);

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ToggleDisplayAddTherapy } from '../../actions/action-form-displays';
import { ToggleFabPanel } from '../../actions/action-view';
import { IBasicActionItemProps, BasicActionItem } from './basic-action-item';

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onClick: () =>
      dispatch(new ToggleDisplayAddTherapy(true)) &&
      dispatch(new ToggleFabPanel(false)) &&
      window.scrollTo(0, 0),
  };
}

export default compose<IBasicActionItemProps, { inheritedClass: string }>(
  connect(() => ({ buttonText: '+ Therapy', id: 'addTherapyActionButton' }), mapDispatchToProps),
)(BasicActionItem);

import { Grid, Button, Typography, Modal } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Intervention from '../note/intervention';
import { useTypedSelector } from '../../hooks/use-typed-selector';
import { INTERVENTION_TYPES } from '../../constants';
import { clearPatientAdherenceHistory } from '../../actions/action-patient';
import { styles } from './adherence-section-styles';
import { EditHistory } from '../icons/icons';
import AdherenceHistoryReview from './adherence-history-review';

const AdherenceInterventionButtonGroup = (props: any): JSX.Element => {
  const {
    classes,
    previousInterventionCount,
    gridProps,
    nonCompliantTherapies,
    handleSubmitComplete,
  } = props;
  const serviceGroups = useTypedSelector(state => state.lookups.serviceGroups);
  const patientId = useTypedSelector(state => state.selectedPatientId);
  const [isAdherenceHistoryModalOpen, setHistoryModal] = useState(false);
  const dispatch = useDispatch();
  const toggleAdherenceInterventionHistoryModal = () => {
    setHistoryModal(!isAdherenceHistoryModalOpen);
    dispatch(clearPatientAdherenceHistory());
  };

  return (
    <>
      <Grid
        item
        container
        direction="column"
        xs={4}
        className={classes.adherenceSectionButtonsContainer}
        {...gridProps}
      >
        <Grid item>
          <Button
            disabled={previousInterventionCount === 0}
            onClick={toggleAdherenceInterventionHistoryModal}
            className={classes.headerButtonMinimal}
          >
            <EditHistory />
            <Typography className={classes.adherenceHistoryLabel}>
              {previousInterventionCount} previous interventions
            </Typography>
          </Button>
        </Grid>
        <Grid item className={classes.adherenceInterventionButtonContainer}>
          <Intervention
            therapy={null}
            tagTypeId={1}
            tagResourceId={patientId}
            defaultServiceGroup={null}
            serviceGroups={serviceGroups}
            taskType="patients"
            defaultTypeId={INTERVENTION_TYPES.ADHERENCE}
            defaultTherapyIds={nonCompliantTherapies}
            defaultCategoryId={1}
            handleSubmitComplete={handleSubmitComplete}
          />
        </Grid>
      </Grid>
      <Modal open={isAdherenceHistoryModalOpen} onClose={toggleAdherenceInterventionHistoryModal}>
        <AdherenceHistoryReview data-qa-id="adr_history_modal" />
      </Modal>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(AdherenceInterventionButtonGroup);

import {
  ADD_MEDICATION,
  FETCH_MEDICATION_GROUPS_ONLY,
  MARK_EMR_CONFIRMED_MEDICATIONS_SUCCESS,
  MARK_PATIENT_CONFIRMED_MEDICATIONS_SUCCESS,
  SET_MEDICATIONS_VERIFICATION,
  UPDATE_MEDICATION,
  FETCH_MEDICATION_LIST_FOR_IDENTIFIER_SUCCESS,
  UPDATE_MEDICATION_VERIFICATION_STATUS_SUCCESS,
} from 'constants/index';
import { MedicationsClient } from 'clients/medications-client';
import HTTP from 'services/http';

export function fetchMedicationsForIdentifier(patientId, identifier) {
  const request = MedicationsClient.getMedicationsByGpi(patientId, identifier);

  return {
    type: FETCH_MEDICATION_LIST_FOR_IDENTIFIER_SUCCESS,
    payload: request,
  };
}

export function fetchMedicationGroups() {
  return {
    type: FETCH_MEDICATION_GROUPS_ONLY,
  };
}

export function updateMedication(updatedMedication) {
  return {
    type: UPDATE_MEDICATION,
    payload: updatedMedication,
  };
}

export function verifyMedications(payload, isReviewed) {
  const request = isReviewed
    ? HTTP.post(`/patients/${payload.patient_id}/medications/_verify_and_review`, payload, {})
    : HTTP.post(`/patients/${payload.patient_id}/medications/_verify`, payload, {});
  return {
    type: UPDATE_MEDICATION_VERIFICATION_STATUS_SUCCESS,
    payload: request,
  };
}

export function addMedication(payload) {
  return {
    type: ADD_MEDICATION,
    payload,
  };
}

export function updateMedicationVerifications(payload) {
  return {
    type: SET_MEDICATIONS_VERIFICATION,
    payload,
  };
}

export function markEmrConfirmed(patientId, confirmed) {
  const request = HTTP.post(`/patients/${patientId}/medications/_mark_emr_confirmed`, {
    confirmed,
  });
  return {
    type: MARK_EMR_CONFIRMED_MEDICATIONS_SUCCESS,
    payload: request,
  };
}

export function markPatientConfirmedMedications(patientId, confirmed) {
  const request = HTTP.post(`/patients/${patientId}/medications/_mark_patient_confirmed`, {
    confirmed,
  });
  return {
    type: MARK_PATIENT_CONFIRMED_MEDICATIONS_SUCCESS,
    payload: request,
  };
}

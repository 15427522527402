export const styles = theme => ({
  cancelButton: {
    margin: theme.spacing(1),
    marginLeft: 0,
  },
  saveButton: {
    margin: theme.spacing(1),
    marginLeft: 0,
  },
  confirmationDialogTitle: {
    backgroundColor: theme.palette.primary.bluesky,
    marginBottom: 20,
    minWidth: 600,
  },
  confirmationContinueButton: {
    marginLeft: 8,
  },
  loading: {
    marginLeft: 10,
  },
});

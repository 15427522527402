import React from 'react';
import { Grid, Button } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { useDebounce } from 'use-debounce';
import { ReactSelect } from 'components/form/field/react-select';
import { windowFeatureIsEnabled } from 'config/window-features';
import { NumberOption } from 'interfaces/forms/types';
import { useDispatch } from 'react-redux';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { notifyError } from 'actions/action-notifications';
import LoadingOverlay from 'components/loading-overlay/loading-overlay';
import { UserUtils } from 'utils/user-utils';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { UserPermissions } from 'interfaces/user/Permissions';
import { logger } from '../../../../winston-logger';
import { SearchField } from '../../components/search-field';
import { SectionTitleStyled } from '../../components/section-title';
import { CpmpStyles } from '../index.styles';
import { DataTable } from '../tables/data-table';
import { AddNewDcModal } from '../modal/add-new-dc-modal';

const searchFieldPlaceholder = 'Search by Category, Item';
const debounceTimeMs = 500;
const initialSearchTerm = '';
const initialValue = { label: 'All', value: -1 };

type ICpmpProps = WithStyles<typeof CpmpStyles>

const CpmpData = (props: ICpmpProps): JSX.Element => {
  const { classes } = props;
  const dispatch = useDispatch();

  // # region redux state
  const user = useTypedSelector(state => state.auth.currentUser);
  const stateSelectedCustomerId = useTypedSelector(state => state.filters.selectedCustomerId);
  const customers = useTypedSelector(state => state.filters.customers);
  const clinicalDataTypesData = useTypedSelector(state => state.lookups.clinicalDataTypes);
  const DataTableContext = React.createContext(clinicalDataTypesData);
  // # endregion

  // #region useState
  const [searchTerm, setSearchTerm] = React.useState<string>(initialSearchTerm);
  const [searchTermByCategory, setSearchTermByCategory] = React.useState<string>(initialSearchTerm);
  const [debouncedSearchTerm] = useDebounce<string | undefined>(searchTerm, debounceTimeMs);
  const [debouncedSearchTermByCategory] = useDebounce<string | undefined>(
    searchTermByCategory,
    debounceTimeMs,
  );
  const [reloadTrigger, setReloadTrigger] = React.useState<Date | undefined>(undefined);
  const [reloadDataTrigger, setReloadDataTrigger] = React.useState<Date | undefined>(undefined);
  const [selectedCustomerId, setSelectedCustomerId] =
    React.useState<number>(stateSelectedCustomerId);
  // #endregion

  // # region component states
  const [loading, setLoading] = React.useState<boolean>(false);
  const [categoryOptions, setCategoryOptions] = React.useState<NumberOption[]>([]);
  const [selectedCategories, setSelectedCategories] = React.useState<NumberOption[]>([]);
  const [addModalOpen, setAddModalOpen] = React.useState<boolean>(false);
  // # endregion

  // # region useEffect
  React.useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const results = await ApplicationManagerClient.fetchClinicalDataCategories();
        const categories = results.data.results.map(x => {
          return { label: x.category_name, value: x.id };
        });
        setCategoryOptions(categories);
      } catch (error) {
        logger.error(error);
        dispatch(notifyError('Error clinical data types categories'));
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (selectedCustomerId == null) {
      if (stateSelectedCustomerId != null) {
        setSelectedCustomerId(stateSelectedCustomerId);
      } else if (customers.length > 0) {
        setSelectedCustomerId(customers[0].id);
      }
    }
  }, [stateSelectedCustomerId]);
  // #endregion

  // # region memos
  const updateSearchTermByCategory = React.useMemo<void>(() => {
    setSearchTermByCategory(
      selectedCategories
        .filter(x => x.value !== initialValue.value)
        .map(x => x.label.toString())
        .join(','),
    );
  }, [selectedCategories]);

  const userHasPermissionToaddDcItem = React.useMemo<boolean>(() => {
    return UserUtils.userIsPermitted(
      UserPermissions.ApplicationManagerCpmpEdit,
      selectedCustomerId,
    );
  }, [user, selectedCustomerId]);

  const userManageUserLevel = React.useMemo<boolean>(() => {
    return UserUtils.userIsPermitted(UserPermissions.ApplicationManagerUsersManageUserLevel);
  }, [user]);
  // # endregion

  // #region renders
  const renderAddDcItemButton = (): JSX.Element => {
    return (
      <Button
        variant="contained"
        className={classes.addButton}
        onClick={() => setAddModalOpen(true)}
      >
        Add DC Item
      </Button>
    );
  };

  const renderEnableDcModal = (): JSX.Element => {
    return (
      <AddNewDcModal
        open={addModalOpen}
        onCancel={() => setAddModalOpen(false)}
        onSuccess={() => setReloadDataTrigger(new Date())}
        editingDcItem={undefined}
        categoryOptionsList={categoryOptions}
      />
    );
  };
  // #endregion
  if (windowFeatureIsEnabled('application_manager_dc')) {
    return (
      <>
        {renderEnableDcModal()}
        {userHasPermissionToaddDcItem || userManageUserLevel ? (
          <SectionTitleStyled
            title="Data Collect"
            rightSideItems={[
              {
                element: renderAddDcItemButton(),
                key: 'add-dc-item-button',
              },
            ]}
          />
        ) : (
          <SectionTitleStyled title="Data Collect" />
        )}
        <Grid container direction="column">
          <Grid container justifyContent="space-between" alignItems="flex-end">
            <Grid container item xs={6}>
              {/* Left Side - Category Field */}
              <Grid item xs={6}>
                <LoadingOverlay open={loading} />
                <ReactSelect
                  label="Category"
                  name="category"
                  isMulti
                  value={
                    selectedCategories.length === 0
                      ? [initialValue]
                      : selectedCategories.filter(x => x.value !== initialValue.value)
                  }
                  fields={categoryOptions}
                  handleOnChange={(value: NumberOption[] | undefined) => {
                    setSelectedCategories(value || []);
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
            {/* Right Side - Search Field */}
            <Grid item xs="auto">
              <Grid container spacing={0}>
                <Grid item xs="auto">
                  <SearchField
                    width={48}
                    value={searchTerm}
                    onChange={setSearchTerm}
                    initialValue={initialSearchTerm}
                    placeholder={searchFieldPlaceholder}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Below the table */}
          <Grid item xs="auto">
            <DataTableContext.Provider value={clinicalDataTypesData}>
              <DataTable
                searchTerm={debouncedSearchTerm}
                triggerReload={reloadTrigger}
                handleReloadDataTrigger={date => setReloadTrigger(date)}
                searchTermByCategory={debouncedSearchTermByCategory}
                userHasPermissionToEditDcItem={userHasPermissionToaddDcItem || userManageUserLevel}
                activeCategory={searchTermByCategory.length > 0}
                activeItem={!!(searchTermByCategory.length > 0 && searchTerm.length > 0)}
                activeOnlyItem={
                  !!(!(searchTermByCategory.length > 0) && searchTerm.length > 0)
                }
                categoryOptionsList={categoryOptions}
              />
            </DataTableContext.Provider>
          </Grid>
        </Grid>
      </>
    );
  }
    return <></>;
};

export default withStyles(CpmpStyles)(CpmpData);

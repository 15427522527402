import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  poweredByFooter: {
    display: 'flex',
    backgroundColor: theme.palette.primary.deepBlue,
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.white,
    height: '5%',
    bottom: 0,
    fontSize: theme.font.mediumFontSize,
  },
  textBox: {
    lineHeight: 1.5,
    [theme.breakpoints.down('xl')]: {
      paddingLeft: '30px',
      paddingRight: '30px',
      fontSize: theme.font.mediumFontSize,
    },
  },
}));

import {
  IPatientMergeProps,
  IPatientMergeSectionDetailsProps,
  IPatientProfile,
  IStepProps,
} from 'components/patient-merge-modal/interfaces';
import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { IState } from 'interfaces/redux/IState';
import { convertToArborDate } from 'models/time/arbor-date';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { useTheme } from '@mui/styles';
import { parseCardPatientNameLabel } from 'components/patient-merge-modal/utils';
import { PatientGenderLabel } from 'components/patient-merge-modal/info-component/patient-gender-label';
import { MrnSection } from 'components/patient-merge-modal/confirm-view/sections/mrn-section';
import { ConfirmDemographicSection } from 'components/patient-merge-modal/confirm-view/sections/confirm-demographic-section';
import { ConfirmTherapySection } from 'components/patient-merge-modal/confirm-view/sections/confirm-therapy-section';
import { ConfirmDocumentSection } from 'components/patient-merge-modal/confirm-view/sections/confirm-document-section';
import { ConfirmIncomeAndBenefitsSection } from 'components/patient-merge-modal/confirm-view/sections/confirm-income-benefits-section';
import { ConfirmClinicalSection } from 'components/patient-merge-modal/confirm-view/sections/confirm-clinical-section';
import { ConfirmPatientTasksSection } from 'components/patient-merge-modal/confirm-view/sections/confirm-patient-tasks-section';
import { PatientMergeSection } from 'components/patient-merge-modal/patient-merge-section';
import { ISection } from 'components/section/interfaces';

const sections: ISection<IPatientMergeProps>[] = [
  {
    title: 'MRN',
    render: (props: IPatientMergeSectionDetailsProps) => <MrnSection {...props} />,
  },
  {
    title: 'Demographics',
    render: (props: IPatientMergeSectionDetailsProps) => <ConfirmDemographicSection {...props} />,
  },
  {
    title: 'Income & Benefits',
    render: (props: IPatientMergeSectionDetailsProps) => (
      <ConfirmIncomeAndBenefitsSection {...props} />
    ),
  },
  {
    title: 'Clinical',
    render: (props: IPatientMergeSectionDetailsProps) => <ConfirmClinicalSection {...props} />,
  },
  {
    title: 'Therapies',
    render: (props: IPatientMergeSectionDetailsProps) => <ConfirmTherapySection {...props} />,
  },
  {
    title: 'Patient Level Tasks',
    render: (props: IPatientMergeSectionDetailsProps) => <ConfirmPatientTasksSection {...props} />,
  },
  {
    title: 'Document',
    render: (props: IPatientMergeSectionDetailsProps) => <ConfirmDocumentSection {...props} />,
  },
];

export function ConfirmView(props: IStepProps) {
  const { profiles, setNextButtonEnabled } = props;
  const [primaryProfile, setPrimaryProfile] = useState<IPatientProfile | undefined>();
  const [expanded, setExpanded] = useState<undefined | number>(undefined);
  const handleChange = (idx: number) => (_: unknown, isExpanded: boolean) => {
    setExpanded(isExpanded ? idx : undefined);
  };

  const primaryId = useSelector((state: IState) => state.patientMerge.primary);

  const theme = useTheme();
  const classes = PatientMergeStyles(theme);

  useEffect(() => {
    const primaryProfile = profiles.find(profile => profile.patientId === primaryId);
    setPrimaryProfile(primaryProfile);
    setNextButtonEnabled(!!primaryProfile);
  }, [primaryId]);

  return (
    <Grid container paddingLeft={2}>
      {primaryProfile && (
        <Grid item xs={12} sx={classes.selectedWrp}>
          <Grid sx={[classes.profileHeader, classes.primaryHeader]}>
            <Typography sx={classes.profileHeaderTitle}>
              {`Primary - MRN ${primaryProfile?.sourcePatientId}`}
            </Typography>
          </Grid>
          <Grid sx={[classes.profileContent, classes.primaryContent]}>
            <Typography fontWeight={400}>{parseCardPatientNameLabel(primaryProfile)}</Typography>
            <PatientGenderLabel profile={primaryProfile} />
            <Typography>
              {convertToArborDate(primaryProfile?.dob).getUtcDate(true)} (
              <Box sx={classes.highlight} component="span">
                {convertToArborDate(primaryProfile?.dob).getYearsFromNow()}
              </Box>
              yr )
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        {sections.map((section, idx) => (
          <PatientMergeSection
            detailsSx={classes.confirmSection}
            key={`section-${section.title}`}
            section={section}
            expanded={idx === expanded}
            onChange={handleChange(idx)}
            {...props}
          />
        ))}
      </Grid>
    </Grid>
  );
}

import React from 'react';
import { Typography, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import { styles } from './jump-button-styles';

const JumpButton = ({ classes, name, sessionId }) => (
  <Grid item xs className={classes.jumpBtnContainer}>
    <Typography className={classes.wording}>Jump To:</Typography>
    <Button className={classes.jumpBtn} href={sessionId}>
      {name}
    </Button>
  </Grid>
);

export default withStyles(styles, { withTheme: true })(JumpButton);

import React from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { Typography, TextField } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { parseGoogleAddress } from 'services/utils/demographic-service';
import { styles } from './address_search_bar_styles';
import Validation from '../validation/validation';

class AddressSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      errorMessage: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  handleChange(address) {
    const { input } = this.props;
    this.setState({
      address,
      errorMessage: '',
    });
    input.onChange(address);
  }

  handleSelect(selected) {
    const { input, setSuggestions } = this.props;
    geocodeByAddress(selected).then(res => {
      const newAddress = parseGoogleAddress(res);
      setSuggestions(newAddress);
      this.setState({ address: newAddress.line1 || '' });
      input.onChange(newAddress.line1 || '');
    });
  }

  handleError(status, clearSuggestions) {
    console.log('Error from Google Maps API', status); // eslint-disable-line no-console
    this.setState({ errorMessage: status }, () => {
      clearSuggestions();
    });
  }

  render() {
    const { address, errorMessage } = this.state;

    const { classes, meta, label, input } = this.props;

    const { error, warning, touched } = meta;

    return (
      <div onBlur={input.onBlur} onFocus={input.onFocus}>
        <Typography className={classes.fieldLabel}>{label}</Typography>
        <PlacesAutocomplete
          value={input.value}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          onError={this.handleError}
          shouldFetchSuggestions={address.length > 2}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <div className={classes.searchBarContainer}>
              <div className={classes.searchInputContainer}>
                <TextField
                  variant="standard"
                  {...input}
                  value={input.value}
                  {...getInputProps({
                    placeholder: 'Search Places...',
                    className: classes.searchInput,
                  })}
                />
                <Validation touched={touched} error={error} warning={warning} />
              </div>
              {suggestions.length > 0 && (
                <div className={classes.suggestionContainer}>
                  {suggestions.map(suggestion => {
                    const className = classes.suggestionItem;
                    return (
                      /* eslint-disable react/jsx-key */
                      <div {...getSuggestionItemProps(suggestion, { className })}>
                        <strong>{suggestion.formattedSuggestion.mainText}, </strong>
                        <small>{suggestion.formattedSuggestion.secondaryText}</small>
                      </div>
                    );
                    /* eslint-enable react/jsx-key */
                  })}
                </div>
              )}
            </div>
          )}
        </PlacesAutocomplete>
        {errorMessage.length > 0 && <div>{errorMessage}</div>}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AddressSearchBar);

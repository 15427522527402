import React from 'react';

import {
  Grid,
  Popover,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import useStyles from './styles';

export default function Comment(props) {
  const { rootContainer, table, tableContainer } = useStyles();
  const { anchorEl, onClose, open, comment, id } = props;
  const data = JSON.parse(comment);
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      onClick={e => {
        e.stopPropagation();
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Grid container direction="column" className={rootContainer}>
        <Typography variant="caption">Fields Comments</Typography>
        <TableContainer className={tableContainer}>
          <Table className={table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Field</TableCell>
                <TableCell>Comment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(row => (
                <TableRow key={row.column_name}>
                  <TableCell component="th" scope="row">
                    {row.header || row.column_name}
                  </TableCell>
                  <TableCell>{row.comment}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box pt={2} />
        <Grid item>
          <Grid container justifyContent="flex-end">
            <Box ml={2}>
              <Button onClick={onClose}>Cancel</Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Popover>
  );
}

import {
  PatientMergeActionsTypes,
  IPatientMergeInputState,
  IPatientMergeAction,
  IPatientMergeInput,
} from 'actions/action-patient-merge';

const initialState: IPatientMergeInputState = {
  inputs: [],
  duplicate: undefined,
  primary: undefined,
};

export const patientMergeReducer = (
  state: IPatientMergeInputState = initialState,
  action: IPatientMergeAction,
) => {
  switch (action.type) {
    case PatientMergeActionsTypes.Reset:
      return initialState;
    case PatientMergeActionsTypes.SetInputs:
      return {
        ...state,
        inputs: action.payload,
      };
    case PatientMergeActionsTypes.AddInput: {
      const toAdd = action.payload as IPatientMergeInput;

      // Check if makes sense to add an unique PK in merge_inputs
      const toAddIdx = state.inputs.findIndex(
        input =>
          input.selectedId === toAdd.selectedId &&
          input.type === toAdd.type &&
          input.extraInfo === toAdd.extraInfo,
      );

      if (toAddIdx !== -1) {
        return state;
      }

      return {
        ...state,
        inputs: [...state.inputs, toAdd],
      };
    }
    case PatientMergeActionsTypes.AddMultipleInputs: {
      const toAdd = action.payload as IPatientMergeInput[];
      const exists = (selected: IPatientMergeInput) =>
        state.inputs.findIndex(
          input =>
            input.selectedId === selected.selectedId &&
            input.type === selected.type &&
            input.extraInfo === selected.extraInfo,
        ) !== -1;

      const diff = toAdd.filter(x => !exists(x));

      return {
        ...state,
        inputs: [...state.inputs, ...diff],
      };
    }
    case PatientMergeActionsTypes.RemoveMultiInputs: {
      const toRemove = action.payload as IPatientMergeInput[];
      const exists = (selected: IPatientMergeInput) =>
        toRemove.find(
          input => input.selectedId === selected.selectedId && input.type === selected.type,
        ) !== undefined;

      const values = state.inputs.filter(x => !exists(x));

      return {
        ...state,
        inputs: [...values],
      };
    }
    case PatientMergeActionsTypes.RemoveInput: {
      const toRemove = action.payload as IPatientMergeInput;
      const toRemoveIdx = state.inputs.findIndex(
        input => input.selectedId === toRemove.selectedId && input.type === toRemove.type,
      );

      if (toRemoveIdx === -1) {
        return state;
      }

      return {
        ...state,
        inputs: [
          ...state.inputs.slice(0, toRemoveIdx),
          ...state.inputs.slice(toRemoveIdx + 1, state.inputs.length),
        ],
      };
    }
    case PatientMergeActionsTypes.SetDuplicateProfile:
      return {
        ...state,
        duplicate: action.payload,
        // inputs: [], // TODO: Check when this makes sense
      };
    case PatientMergeActionsTypes.SetPrimaryProfile:
      return {
        ...state,
        primary: action.payload,
      };
    default:
      return state;
  }
};

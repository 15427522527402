import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { styles as TherapyDetailStyles } from 'containers/patient/therapy/therapy-detail.styles';
import { combineStyles } from 'services/utils/styles-service';

const CounselingStyles = (theme: Theme) => {
  return createStyles({
    submissionButton: {
      margin: 8,
      marginLeft: 0,
    },
    counselingContainer: {
      padding: '10px 5px',
    },
    onGoingCounselingField: {
      width: '80%',
    },
    secondaryTitleHeader: {
      marginBottom: theme.spacing(1),
    },
    radioGroup: {
      flexDirection: 'row',
      marginBottom: 20,
    },
    durErrorMessage: {
      color: theme.palette.error.main,
      textAlign: 'right',
    },
  });
};

const styles = combineStyles(CounselingStyles, TherapyDetailStyles);

export { styles };

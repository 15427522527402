import { createStyles } from '@mui/styles';
import { defaultTheme } from 'lib/themes';

export const styles = theme =>
  createStyles({
    container: {
      position: 'relative',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    medicationList: {
      padding: 0,
      fontSize: 13,
      fontWeight: 600,
    },
    icon: {
      color: defaultTheme.palette.primary.teal,
      fontSize: 28,
    },
    iconWrapper: {
      marginRight: 16,
      minWidth: 'auto',
    },
    title: {
      marginBottom: 25,
      fontSize: 24,
    },
    itemListText: {
      fontWeight: 600,
      fontSize: 18,
    },
    btnContainer: {
      position: 'absolute',
      bottom: '0%',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '100%',
    },
    btnLarge: {
      '&': {
        color: '#000A',
        fontSize: 20,
        textTransform: 'capitalize',
        width: '100%',
        padding: 8,
        marginBottom: 10,
        borderRadius: 25.5,
        backgroundColor: defaultTheme.palette.primary.grey2,
      },
      '&:hover': {
        backgroundColor: defaultTheme.palette.primary.grey2,
      },
    },
    selectLabel: {
      fontSize: 24,
      transform: 'translateY(-28px)',
      color: defaultTheme.palette.primary.deepBlue,
    },
    select: {
      '& .MuiSelect-select': {
        padding: '12px 10px',
      },
      'color': defaultTheme.palette.primary.deepBlue,
      'fontSize': 20,
    },
    wrapText: {
      whiteSpace: 'pre-wrap',
    },
  });

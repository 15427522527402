import {
  DATA_LINKS_REQUEST,
  DATA_LINKS_SUCCESS,
  DATA_LINKS_FAILURE,
  SELECTED_PATIENT_ID,
} from 'constants/index';
import { getReducerDataInitialState } from './helper-reducer';

const initialState = getReducerDataInitialState({ data: [] });

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SELECTED_PATIENT_ID:
    case DATA_LINKS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case DATA_LINKS_SUCCESS:
      if (payload.data && payload.data.links) {
        return {
          loading: false,
          loaded: true,
          data: payload.data.links,
        };
      }
      return state;
    case DATA_LINKS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    default:
      return state;
  }
};

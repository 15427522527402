import { takeEvery, put, select } from 'redux-saga/effects';

import { IState } from 'interfaces/redux/IState';
import { ITask } from 'interfaces/redux/ITasks';
import { INoteBar } from 'interfaces/redux/INoteBar';
import { ITherapy } from 'interfaces/redux/ITherapies';
import {
  OPEN_NOTE_COMMUNICATION_SIDEBAR,
  NOTE_PAGE_LIMIT,
  COMMUNICATION_FORM,
} from 'constants/index';
import { updateNoteBar, fetchNotesSidebar, filterNotesSidebar } from 'actions/action-notes';
import { change, reset } from 'redux-form';
import { convertToArborDate } from 'models/time/arbor-date';
import { selectAllTasks } from 'containers/patient/tasks-new/selectors';

export interface IOpenCommunicationNotesAction {
  type: string;
  payload: {
    noteBar: Partial<INoteBar>;
    existingTasks: ITask[];
    therapy: ITherapy;
  };
}

function* openCommunicationNotesSideBar(
  action: IOpenCommunicationNotesAction,
): Generator<any, void, any> {
  const state: IState = yield select();

  const existingTasks = action.payload.existingTasks ?? selectAllTasks(state);

  yield put(
    updateNoteBar({
      ...action.payload.noteBar,
      loadingNotes: true,
    }),
  );

  const notesPayload = {
    patient_id: state.patient.id,
    tag_type_id: action.payload.noteBar.tagTypeId,
    tag_resource_id: action.payload.noteBar.tagResourceId,
    therapy_id: action.payload.therapy ? action.payload.therapy.id : null,
  };

  const fetchNotesSideBarPromise = yield put(
    fetchNotesSidebar(
      {
        ...notesPayload,
        limit: NOTE_PAGE_LIMIT + 1, // return 1 more note to check if loaded all
      },
      existingTasks,
    ),
  );

  const actionResponse = yield fetchNotesSideBarPromise;

  yield put(filterNotesSidebar(notesPayload));

  const notes =
    actionResponse && actionResponse.payload && actionResponse.payload.data
      ? actionResponse.payload.data.notes
      : []; // eslint-disable-line

  if (notes.length < NOTE_PAGE_LIMIT + 1) {
    // return notes less than NOTE_PAGE_LIMIT + 1 means we already
    // loaded all notes for this tag, set tagNotesReachEnd to true
    yield put(
      updateNoteBar({
        ...action.payload.noteBar,
        tagNoteLoadedNum: notes.length,
        loadingNotes: false,
        tagNotesReachEnd: true,
      }),
    );
  } else {
    yield put(
      updateNoteBar({
        ...action.payload.noteBar,
        tagNoteLoadedNum: NOTE_PAGE_LIMIT,
        loadingNotes: false,
      }),
    );
  }

  // Reset the followup date to be the task patient just select
  if (action.payload.noteBar?.initiatingTask) {
    yield put(reset(COMMUNICATION_FORM));
    yield put(
      change(
        COMMUNICATION_FORM,
        'followup_dt',
        convertToArborDate(action.payload.noteBar.initiatingTask.followup_dt).getCustomerDate(true),
      ),
    );
  }
}

export function* watcherCommunicationNotesSideBarSaga() {
  yield takeEvery([OPEN_NOTE_COMMUNICATION_SIDEBAR], openCommunicationNotesSideBar);
}

import moment from 'moment';
import HTTP from '../http';
import { ACCEPTED_FILE_EXTENSIONS } from '../../constants/index';

const findAmountDocuments = (documents, amount, totalCount) => {
  if (documents && documents.length > 0) {
    const amountDocuments = documents;
    amountDocuments.splice(0, amount);
    return { amountDocuments, totalCount };
  }
  return null;
};

export const acceptedFileExtensions = Object.keys(ACCEPTED_FILE_EXTENSIONS)
  .map(ext => `.${ext}`)
  .join(', ');
export const fileTypeErrorCaption = `Accepted file types: ${acceptedFileExtensions}`;
export const fileTypeError = 'You selected an invalid file, please try again.';

export const findMatchingDocuments = (documents, taskIdResourceIds) => {
  if (documents && documents.length > 0 && taskIdResourceIds && taskIdResourceIds.length > 0) {
    const documentMap = {};

    taskIdResourceIds.forEach(tr => {
      // eslint-disable-next-line arrow-body-style
      const filteredDocuments = documents.filter(document => {
        return (
          document.tags &&
          document.tags.some(
            tag => tag.resource_id === tr.resourceId && tag.tag_type_id === tr.tagTypeId,
          )
        );
      });
      filteredDocuments.forEach(document => {
        if (!documentMap[document.id]) {
          documentMap[document.id] = document;
        }
      });
    });

    return Object.values(documentMap);
  }
  return null;
};

// taskIdResourceIds = [{resourceId, tagTypeId}]
export const getDocumentsForResource = (allDocuments, taskIdResourceIds, amount = 1) => {
  const matchedDocuments = findMatchingDocuments(allDocuments, taskIdResourceIds);
  const matchedDocumentsCount = matchedDocuments
    ? matchedDocuments.filter(x => !x.is_archived).length
    : 0;
  const allDocumentsLength = allDocuments ? allDocuments.length : 0;
  const amountDocuments = findAmountDocuments(matchedDocuments, amount, allDocumentsLength);
  if (amountDocuments) {
    return {
      ...amountDocuments,
      filteredCount: matchedDocumentsCount,
    };
  }
  return null;
};

export const getFilteredDocuments = (allDocuments, documentsFilter) => {
  let documents = allDocuments;
  if (documentsFilter) {
    if (documentsFilter.fromDate) {
      const fromDate = moment.utc(documentsFilter.fromDate);
      documents = documents.filter(d => moment(d.created).isSameOrAfter(fromDate));
    }
    if (documentsFilter.toDate) {
      const toDate = moment.utc(documentsFilter.toDate).add(1, 'days');
      documents = documents.filter(d => moment(d.created).isSameOrBefore(toDate));
    }
    if (!documentsFilter.showArchived) {
      documents = documents.filter(d => !d.is_archived);
    }
    if (documentsFilter.labelsSelected && documentsFilter.labelsSelected.length > 0) {
      const documentsFiltered = [];
      documents.forEach(d => {
        documentsFilter.labelsSelected.forEach(l => {
          if (
            d.labels &&
            d.labels.includes(l) &&
            !documentsFiltered.map(x => x.id).includes(d.id)
          ) {
            documentsFiltered.push(d);
          }
        });
      });
      documents = documentsFiltered;
    }
    if (documentsFilter.tagsSelected && documentsFilter.tagsSelected.length > 0) {
      const documentsFiltered = [];
      documents.forEach(d => {
        documentsFilter.tagsSelected.forEach(t => {
          if (
            d.tag_display &&
            d.tag_display.includes(t) &&
            !documentsFiltered.map(x => x.id).includes(d.id)
          ) {
            documentsFiltered.push(d);
          }
        });
      });
      documents = documentsFiltered;
    }
  }
  return documents.sort((a, b) => b.id - a.id);
};

export const buildDistinctList = (existingItems, newItems) => {
  const set = new Set(existingItems ? existingItems.flat(Infinity).filter(item => item) : []);
  const newElements = newItems ? newItems.flat(Infinity) : [];

  newElements.forEach(element => {
    if (element) {
      set.add(element);
    }
  });

  return Array.from(set).sort((a, b) =>
    a.toString().toLowerCase().localeCompare(b.toString().toLowerCase()),
  );
};

export const mergeDocumentList = (documents, updatedDocument) => {
  const updatedDocuments = [];
  if (documents && updatedDocument) {
    documents.forEach(doc => {
      if (doc.id === updatedDocument.id) {
        updatedDocuments.push(updatedDocument);
      } else {
        updatedDocuments.push(doc);
      }
    });
  }
  return updatedDocuments;
};

export const getFileExtension = fname => {
  let filePrefix = '';
  let fileExtension = '';
  const fileStrList = fname && fname.length > 0 ? fname.split('.') : [];
  if (fileStrList && fileStrList[fileStrList.length - 1]) {
    fileExtension = fileStrList[fileStrList.length - 1];
    filePrefix = fname.substr(0, fname.lastIndexOf('.'));
  }
  return { filePrefix, fileExtension };
};

export const fileExtensionConfig = fileName => {
  const extension = getFileExtension(fileName).fileExtension;
  const result = ACCEPTED_FILE_EXTENSIONS[extension];
  return result;
};

export const getDocumentsForDisplay = (documents, resourceId, tagTypeId) =>
  getDocumentsForResource(documents, [{ resourceId, tagTypeId }], 1);

export const getGeneratedDocument = async (documentPages, customerId, patientId) => {
  const url = `/documents/generate/customers/${customerId}`;
  const stringifiedDocumentPages = JSON.stringify({ documents: documentPages });
  const payload = {
    patient_id: patientId,
    payload: window.btoa(unescape(encodeURIComponent(stringifiedDocumentPages))),
    /**
     * Uncomment next line and remove previous if we ever determine how to avoid base64 encoding
     * the document generation payload. Currently it's required to send SVG images as part of the
     * payload
     */
    // documents: documentPages,
  };
  return HTTP.post(url, payload, {});
};

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

  if (!base64regex.test(b64Data)) {
    throw new Error('Unable to show document since is not valid');
  }
  // eslint-disable-next-line no-undef
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  // eslint-disable-next-line no-undef
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

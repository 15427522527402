import React from 'react';
import { Grid } from '@mui/material';

const dpvConfirmationLookup: any = {
  N: 'Primary and secondary number information failed to DPV confirm.',
  D: 'Address was DPV confirmed for the primary number only, and the secondary number information was missing.',
  S: 'Address was DPV confirmed for the primary number only, and the secondary number information was present but not confirmed.',
  Y: 'Address was DPV confirmed for primary and any secondary numbers.',
};

const dpvFootnoteLookup: any = {
  AA: 'Input address matched to the ZIP+4 file',
  A1: 'Input address was not matched to the ZIP+4 file',
  BB: 'Matched to DPV (all components)',
  CC: 'Secondary number not matched and not required',
  C1: 'Secondary number not matched but required',
  N1: 'High-rise address missing secondary number',
  M1: 'Primary number missing',
  M3: 'Primary number invalid',
  P1: 'Input address PO, RR or HC box number missing',
  P3: 'Input address PO, RR, or HC Box number invalid',
  F1: 'Input address matched to a military address',
  G1: 'Input address matched to a general delivery address',
  U1: 'Input address matched to a unique ZIP code',
  PB: 'Input address matched to PBSA record',
  RR: 'DPV confirmed address with PMB information',
  R1: 'DPV confirmed address without PMB information',
  R7: 'Carrier Route R777 or R779 record',
  IA: 'Informed Address identified',
  TA: 'Primary number matched by dropping a trailing alpha',
};

const submittedValue = (input: any, sA: any) => {
  if (input.componentType === 'locality') {
    return sA.value.city;
  }
  if (input.componentType === 'postal_code') {
    return sA.value.zip;
  }
  if (input.componentType === 'administrative_area_level_1') {
    return sA.value.state;
  }
  if (input.componentType === 'route' || input.componentType === 'street_number') {
    return sA.value.line1;
  }
  if (input.componentType === 'subpremise') {
    return sA.value.line2;
  }
};

export const AddressValidationData = (props: any) => {
  const { validation_data, submittedAddress } = props;
  const debug_details: any = [];
  const debug_details_usps: any = {};
  validation_data.data.result.address.addressComponents.forEach((component: any) => {
    if (component.componentType === 'country') {
      return;
    }
    debug_details.push({
      attribute: component.componentType,
      submittedValue: submittedValue(component, submittedAddress),
      value: component.componentName.text,
      status: component.confirmationLevel,
      inferred: !!component.inferred,
      replaced: !!component.replaced,
      spellCorrected: !!component.spellCorrected,
    });
  });
  const usps_data = validation_data.data.result.uspsData;
  debug_details_usps.dpvConfirmation = usps_data.dpvConfirmation;
  debug_details_usps.dpvFootnote = usps_data.dpvFootnote || '';
  const dpvFootnoteArray = [];
  for (let i = 0; i < debug_details_usps.dpvFootnote.length; i += 2) {
    dpvFootnoteArray.push(debug_details_usps.dpvFootnote.slice(i, i + 2));
  }
  return (
    <>
      <Grid>
        <table style={{ width: '100%' }}>
          <tr>
            <th>Attribute</th>
            <th>Submitted Value</th>
            <th>Returned Value</th>
            <th>Status</th>
            <th>Inferred</th>
            <th>Replaced</th>
            <th>Spell Corrected</th>
          </tr>
          {debug_details.map((detail: any, index: number) => (
            <tr
              style={
                index % 2 === 0 ? { backgroundColor: 'azure', height: '30px' } : { height: '30px' }
              }
            >
              <td>{detail.attribute}</td>
              <td>{detail.submittedValue}</td>
              <td>{detail.value}</td>
              <td style={{ textAlign: 'center' }}>{detail.status}</td>
              <td style={{ textAlign: 'center', ...(detail.inferred ? { fontWeight: 700 } : {}) }}>
                {detail.inferred.toString()}
              </td>
              <td style={{ textAlign: 'center', ...(detail.replaced ? { fontWeight: 700 } : {}) }}>
                {detail.replaced.toString()}
              </td>
              <td
                style={{
                  textAlign: 'center',
                  ...(detail.spellCorrected ? { fontWeight: 700 } : {}),
                }}
              >
                {detail.spellCorrected.toString()}
              </td>
            </tr>
          ))}
        </table>
      </Grid>
      <hr />
      <Grid>
        <div>
          dpvConfirmation-{usps_data.dpvConfirmation || ''} -
          {dpvConfirmationLookup[usps_data.dpvConfirmation] ??
            'The address was not submitted for DPV validation.'}
        </div>
        {dpvFootnoteArray.map(note => (
          <div>
            dpvFootnote-{note} - {dpvFootnoteLookup[note] || 'Missing lookup value'}
          </div>
        ))}
      </Grid>
    </>
  );
};

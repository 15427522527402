import { Action } from 'redux';

import {
  TOGGLE_DISPLAY_ADD_CONTACT,
  TOGGLE_DISPLAY_ADD_THERAPY,
  TOGGLE_DISPLAY_ADD_THERAPY_MODAL,
  TOGGLE_DISPLAY_ADD_INCOME,
  TOGGLE_DISPLAY_ADD_MEDICAL_INSURANCE,
  TOGGLE_DISPLAY_ADD_PBM_INSURANCE,
  TOGGLE_DISPLAY_ADD_FINANCIAL_ASSISTANCE,
} from '../constants/index';

export class ToggleDisplayAddContact implements Action {
  readonly type = TOGGLE_DISPLAY_ADD_CONTACT;

  constructor(public payload: boolean | undefined) {}
}

export class ToggleDisplayAddTherapy implements Action {
  readonly type = TOGGLE_DISPLAY_ADD_THERAPY;

  constructor(public payload: boolean | undefined) {}
}

export class ToggleDisplayAddTherapyModal implements Action {
  readonly type = TOGGLE_DISPLAY_ADD_THERAPY_MODAL;

  constructor(public payload: boolean | undefined) {}
}

export class ToggleDisplayAddIncome implements Action {
  readonly type = TOGGLE_DISPLAY_ADD_INCOME;

  constructor(public payload: boolean | undefined) {}
}

export class ToggleDisplayAddMedicalInsurance implements Action {
  readonly type = TOGGLE_DISPLAY_ADD_MEDICAL_INSURANCE;

  constructor(public payload: boolean | undefined) {}
}

export class ToggleDisplayAddPBMInsurance implements Action {
  readonly type = TOGGLE_DISPLAY_ADD_PBM_INSURANCE;

  constructor(public payload: boolean | undefined) {}
}

export class ToggleDisplayAddFinancialAssistance implements Action {
  readonly type = TOGGLE_DISPLAY_ADD_FINANCIAL_ASSISTANCE;

  constructor(public payload: boolean | undefined) {}
}

export type FormDisplayAction =
  | ToggleDisplayAddTherapy
  | ToggleDisplayAddTherapyModal
  | ToggleDisplayAddContact
  | ToggleDisplayAddIncome
  | ToggleDisplayAddMedicalInsurance
  | ToggleDisplayAddPBMInsurance
  | ToggleDisplayAddFinancialAssistance;

import { convertToArborDate } from 'models/time/arbor-date';
import { IContactList, IContactListItem } from 'interfaces/redux/IContactList';
import { handleNullString } from 'services/utils/formatting';
import { otherCommunicationOptions } from 'constants/lists';

class ContactUtil {
  static convertContactFromStateForUpdate = (contact: IContactListItem) => {
    return {
      ...contact,
      addresses: JSON.stringify(contact.addresses),
      emails: JSON.stringify(contact.emails),
      phones: JSON.stringify(contact.phones),
      dob: convertToArborDate(contact.dob).getUtcDate(),
      languages:
        contact.languages && contact.languages.length > 0
          ? JSON.stringify(contact.languages)
          : null,
    };
  };
}

const contactListOptions = (contactList: IContactList, otherCommunication = false) => {
  let contactOptions: { label: string; value: number | string }[] = [
    { label: 'Patient', value: 'patient' },
  ];
  if (contactList) {
    Object.keys(contactList).forEach((contactId: any) => {
      const contact = contactList[contactId];
      if (contact.first_name) {
        contactOptions.push({
          label: `${contact.first_name} ${handleNullString(contact.last_name)} (${
            contact.relationship
          })`,
          value: Number(contactId),
        });
      }
    });
  }

  if (otherCommunication) {
    contactOptions = contactOptions.concat(otherCommunicationOptions);
  }

  return contactOptions;
};

export { ContactUtil, contactListOptions };

import React, { useState } from 'react';
import {
  Grid,
  Box,
  Button,
  Popover,
  Typography,
  TextField,
  FormControl,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  InputAdornment,
  Divider,
  ListItemSecondaryAction,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import SearchIcon from '@mui/icons-material/Search';

import useStyles, { getItemStyle, getListStyle } from './styles';

import { COMMENT } from '../../constants';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const VisibilityPopup = props => {
  const {
    anchorEl,
    onClose,
    open,
    id: popoverId,
    columnList,
    hiddenColumns,
    onPreferencesChange,
  } = props;

  const colors = useTheme();

  const [uncheckedList, setUncheckedList] = useState([...hiddenColumns]);
  const [fields, setFields] = useState([...columnList]);

  const { listItemIcon, filterListItem, checkboxList } = useStyles();
  const [textFilter, setTextFilter] = useState('');

  const handleOnChangeTextFilter = e => {
    setTextFilter(e.target.value);
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const items = reorder(fields, result.source.index, result.destination.index);
    setFields(items);
  };

  const handleToggle = value => () => {
    const currentIndex = uncheckedList.findIndex(o => o === value);
    const newUnchecked = [...uncheckedList];

    if (currentIndex === -1) {
      const checkedItemIndex = fields.findIndex(o => o.name === value);
      const checkedItem = fields[checkedItemIndex];
      newUnchecked.splice(checkedItemIndex, 0, checkedItem.name);
    } else {
      newUnchecked.splice(currentIndex, 1);
    }
    setUncheckedList(newUnchecked);
  };

  const handleOnApply = () => {
    onPreferencesChange(uncheckedList, fields);
    onClose();
  };

  const handleOnClear = () => {
    setUncheckedList([COMMENT]);
    setFields([...fields].sort((a, b) => a.order - b.order));
  };

  return (
    <Popover
      id={popoverId}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      onClick={e => {
        e.stopPropagation();
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box pt={2} pl={3} pr={3} pb={2}>
        <Grid direction="column" container>
          <Typography variant="h6">Display Preferences</Typography>
          <Box pt={1} />
          <Box>
            <Box pt={2} />
            <FormControl fullWidth>
              <TextField
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                id="outlined-basic"
                label="Filter value"
                value={textFilter}
                onChange={handleOnChangeTextFilter}
              />
            </FormControl>
            <Box pt={2} />
            <Grid>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <>
                      <List style={getListStyle(snapshot.isDraggingOver)} className={checkboxList}>
                        {fields
                          .filter(
                            d =>
                              (textFilter === '' && d.name !== COMMENT) ||
                              (d.name.toLowerCase().includes(textFilter.toLowerCase()) &&
                                d.name !== COMMENT),
                          )
                          .map((item, index) => (
                            <Draggable key={item.name} draggableId={item.name} index={index}>
                              {
                                // eslint-disable-next-line no-shadow
                                (provided, snapshot) => (
                                  <>
                                    <ListItem
                                      className={filterListItem}
                                      ContainerComponent="li"
                                      ContainerProps={{ ref: provided.innerRef }}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style,
                                        colors.palette.primary.grey23,
                                      )}
                                    >
                                      <ListItemIcon className={listItemIcon}>
                                        <Checkbox
                                          onChange={handleToggle(item.name)}
                                          checked={uncheckedList.indexOf(item.name) === -1}
                                          edge="start"
                                          color="primary"
                                        />
                                      </ListItemIcon>
                                      <ListItemText primary={item.label || item.name} />
                                      <ListItemSecondaryAction />
                                    </ListItem>
                                    <Divider />
                                  </>
                                )
                              }
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </List>
                    </>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>
          </Box>

          <Box pt={2} />
          <Grid container item justifyContent="flex-end">
            <Button onClick={handleOnClear}>Reset to default</Button>
            <Box ml={1}>
              <Button variant="contained" color="primary" onClick={handleOnApply}>
                Apply
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  );
};

export default VisibilityPopup;

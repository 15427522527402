import {
  TaskPaApprovedModalActionType as IType,
  ITaskPaApprovedModalAction as IAction,
  ITaskForm,
} from '../actions/action-task-pa-approved-modal';
import { ITaskPaApprovedModal as IState } from '../interfaces/redux/ITaskPaApprovedModal';

/* eslint-disable default-param-last */

const initialState = {
  visible: false,
};

export const taskPaApprovedModal = (
  state: IState = initialState,
  { type, payload }: IAction,
) => {
  switch (type) {
    case IType.open:
      // eslint-disable-next-line no-case-declarations
      const { lastApproved, newTask, event } =
        payload as ITaskForm;

      return {
        ...state, visible: true, newTask, lastApproved, event,
      };

    case IType.resetState:
      return initialState;

    default:
      return state;
  }
};

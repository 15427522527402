import React from 'react';
import { styled } from '@mui/material/styles';
import withTheme from '@mui/styles/withTheme';
import { Grid } from '@mui/material';

import { convertToArborDate } from '../../models/time/arbor-date';

const Box = styled(withTheme(Grid))(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
  padding: 25,
}));

const Subtitle = styled(withTheme(Grid))(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: theme.palette.primary.grey12,
  fontSize: 12,
  marginBottom: 8,
}));

const Container = styled(withTheme(Grid))({
  display: 'flex',
  marginBottom: 20,
});

const Column = styled(withTheme(Grid))(({ flex, marginBottom }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: flex || 0,
  marginBottom: marginBottom || 0,
}));

const Content = styled(withTheme(Grid))(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  textAlign: 'left',
  paddingBottom: 10,
  paddingTop: 8,
  width: '100%',
  color: theme.palette.primary.steelGrey,
}));

const Internal = styled(Content)(() => ({
  textTransform: 'uppercase',
}));

export default function StyledComponents(props) {
  const {
    type_display: incidentType,
    reporting_party_display: reportingParty,
    other_reporting_party: otherReportingParty,
    is_internal: internal,
    medication_error_type_display: medicationError,
    is_hipaa_breach: hipaa,
    followup_dt: followUpDate,
    incident_dt: incidentDate,
    submitted_dt: submitDate,
    responsible_parties: responsibles,
    details: incidentDetail,
    category,
    category_id,
    serviceGroup,
    dispensing_pharmacy_id: dispensingPharmacyId,
    pharmacyName,
    errorTypeName,
    incidentCategories,
    is_medication_error,
    medicationErrorCategoryName,
  } = props;
  const hipaaValue = hipaa === null ? '-' : (hipaa ? 'Yes' : 'No');
  const nearMiss = typeof internal === 'boolean' ? (internal ? 'Yes' : 'No') : null;
  const reportingPartyValue = otherReportingParty && reportingParty ? `${reportingParty} (${otherReportingParty})` : (reportingParty) ? `${reportingParty}` : '-';

  return (
    <Box>
      <Container>
        <Column flex={2}>
          <Subtitle>Category</Subtitle>
          <Content data-qa-id="qaIncidentCategory">{category || '-'}</Content>
        </Column>
        {errorTypeName && [incidentCategories.INCIDENT, incidentCategories.COMPLAINTS].includes(category_id) && (
          <Column flex={2}>
            <Subtitle>Error Type</Subtitle>
            <Content data-qa-id="qaErrorType">{errorTypeName || '-'}</Content>
          </Column>
        )}
        {medicationErrorCategoryName && [incidentCategories.INCIDENT].includes(category_id) && (
          <Column flex={2}>
            <Subtitle>Medication Error Category</Subtitle>
            <Content data-qa-id="qaMedicationErrorCategory">{medicationErrorCategoryName || '-'}</Content>
          </Column>
        )}
        <Column flex={3}>
          <Subtitle>Subtype</Subtitle>
          <Content data-qa-id="qaIncidentTypes">{incidentType || '-'}</Content>
        </Column>
        <Column flex={2}>
          <Subtitle>Reporting Party</Subtitle>
          <Content data-qa-id="qaReportingParty">{reportingPartyValue}</Content>
        </Column>
      </Container>
      <Container>
        {[incidentCategories.INCIDENT].includes(category_id) && (
          <Column flex={3}>
            <Subtitle>Near Miss?</Subtitle>
            <Internal data-qa-id="qaNearMiss">{nearMiss ?? '-'}</Internal>
          </Column>
        )}
        {[incidentCategories.COMPLAINTS].includes(category_id) && is_medication_error === 1 && (
          <Column flex={2}>
            <Subtitle>Error Type</Subtitle>
            <Content data-qa-id="qaMedicationError">{medicationError || '-'}</Content>
          </Column>
        )}
        {[incidentCategories.INCIDENT, incidentCategories.COMPLAINTS].includes(category_id) && (
          <Column flex={2}>
            <Subtitle>Potential PHI Exposure</Subtitle>
            <Content data-qa-id="qaHipaa">{hipaaValue}</Content>
          </Column>
        )}
      </Container>
      <Container>
        <Column flex={3}>
          <Subtitle>Follow up Date</Subtitle>
          <Content data-qa-id="qaFollowUpDate">
            {followUpDate ? convertToArborDate(followUpDate).getCustomerDate(true) : '-'}
          </Content>
        </Column>
        <Column flex={2}>
          <Subtitle>Date</Subtitle>
          <Content data-qa-id="qaIncidentDate">
            {incidentDate ? convertToArborDate(incidentDate).getCustomerDate(true) : '-'}
          </Content>
        </Column>
        <Column flex={2}>
          <Subtitle>Submit Date</Subtitle>
          <Content data-qa-id="qaSubmitDate">
            {submitDate ? convertToArborDate(submitDate).getCustomerDate(true) : '-'}
          </Content>
        </Column>
      </Container>
      <Container>
        <Column flex={3}>
          <Subtitle>Service Group</Subtitle>
          <Content data-qa-id="qaServiceGroup">{serviceGroup || '-'}</Content>
        </Column>
        {dispensingPharmacyId && (
          <Column flex={2}>
            <Subtitle>Dispensing Pharmacy</Subtitle>
            <Content data-qa-id="qaDispensingPharmacy">{pharmacyName || '-'}</Content>
          </Column>
        )}
        <Column flex={2}>
          <Subtitle>Responsible Parties</Subtitle>
          <Content data-qa-id="qaResponisbleParties">{responsibles || '-'}</Content>
        </Column>
      </Container>
      <Container>
        <Column flex>
          <Subtitle>Details </Subtitle>
          <Content data-qa-id="qaIncidentDetail">{incidentDetail || '-'}</Content>
        </Column>
      </Container>
    </Box>
  );
}

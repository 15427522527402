import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'recompose';
import { SmallSpacer } from 'components/spacer/spacer';
import PageHeader from 'components/form/header/page-header';
import JumpButton from 'components/anchor/jump-button';
import Anchor from 'components/anchor/anchor';
import { fetchPbmInsurances } from 'actions/action-financials';
import { withRouter } from 'react-router';
import { Loader } from 'components/loading-overlay/loading-overlay';
import { convertMapToList } from 'reducers/helper-reducer';
import MedicalDetailOld from './medical-insurances/medical-detail-old';
import PbmDetailOld from './pbm-insurances/pbm-detail-old';
import IncomeDetailOld from './incomes/income-detail-old';
import FinancialAssistanceDetail from './financial-assistances/financial-assistances-detail-old';
import AddIncome from './incomes/add-income';
import AddMedicalInsurance from './medical-insurances/add-medical-insurance';
import AddPbmInsurance from './pbm-insurances/add-pbm-insurance';
import { styles } from './financials-styles';
import AddFAData from '../../tasks/fa/add-fa-data';

import {
  ToggleDisplayAddIncome,
  ToggleDisplayAddMedicalInsurance,
  ToggleDisplayAddPBMInsurance,
  ToggleDisplayAddFinancialAssistance,
} from '../../../actions/action-form-displays';

class FinancialsListOld extends Component {
  constructor(props) {
    super(props);
    this.handleCancelClick = this.handleCancelClick.bind(this);
  }

  componentWillUnmount() {
    const { onCancelForms } = this.props;
    onCancelForms();
  }

  handleCancelClick() {
    const { onCancelForms } = this.props;
    onCancelForms();
  }

  render() {
    const {
      classes,
      patientId,
      fetchMedicalInsurances, // eslint-disable-line
      displayAddIncome,
      displayAddMedical,
      displayAddPbm,
      displayAddFinancialAssistance,
      loading,
      financials,
    } = this.props;

    const medicalInsurances = convertMapToList(financials.medical_insurances.data);
    const pbmInsurances = convertMapToList(financials.pbm_insurances.data);
    const incomes = convertMapToList(financials.incomes.data);

    const renderIncome = income => <IncomeDetailOld key={income.id} income={income} />;
    const renderMedicalInsurance = insurance => (
      <MedicalDetailOld
        key={insurance.id}
        medicalInsurance={insurance}
        fetchMedicalInsurances={fetchMedicalInsurances}
        patientId={patientId}
      />
    );
    const renderPbmInsurance = insurance => (
      <PbmDetailOld
        key={insurance.id}
        pbmInsurance={insurance}
        fetchPbmInsurances={fetchPbmInsurances}
      />
    );

    const currentMedical = Object.keys(medicalInsurances)
      .map(key => medicalInsurances[key])
      .filter(ins => ins.end_date === null);
    const pastMedical = Object.keys(medicalInsurances)
      .map(key => medicalInsurances[key])
      .filter(ins => ins.end_date !== null);
    const currentPbm = Object.keys(pbmInsurances)
      .map(key => pbmInsurances[key])
      .filter(ins => ins.end_date === null);
    const pastPbm = Object.keys(pbmInsurances)
      .map(key => pbmInsurances[key])
      .filter(ins => ins.end_date !== null);

    const currentIncome = Object.keys(incomes)
      .map(key => incomes[key])
      .filter(income => income.income_year === new Date().getFullYear());
    const pastIncome = Object.keys(incomes)
      .map(key => incomes[key])
      .filter(income => income.income_year !== new Date().getFullYear());

    return (
      <div className={classes.loadContainer}>
        <Loader loaded={!loading}>
          <PageHeader>
            {incomes && incomes.length && <JumpButton name="Income" sessionId="#income" />}
          </PageHeader>
          <Grid container alignItems="center">
            {displayAddIncome && (
              <AddIncome patientId={patientId} cancel={this.handleCancelClick} />
            )}
            {displayAddMedical && (
              <AddMedicalInsurance patientId={patientId} cancel={this.handleCancelClick} />
            )}
            {displayAddPbm && (
              <AddPbmInsurance patientId={patientId} cancel={this.handleCancelClick} />
            )}
            {displayAddFinancialAssistance && (
              <AddFAData
                handleCancel={this.handleCancelClick}
                patientId={patientId}
                addingFromFinancials
              />
            )}
          </Grid>
          <SmallSpacer />
          <div>
            {currentMedical && // eslint-disable-next-line
              Object.keys(currentMedical).map(key => renderMedicalInsurance(currentMedical[key]))}
          </div>
          <FinancialAssistanceDetail />
          <div>
            {currentPbm && Object.keys(currentPbm).map(key => renderPbmInsurance(currentPbm[key]))}
          </div>
          <Anchor id="income">
            <div>
              {currentIncome &&
                Object.keys(currentIncome).map(key => renderIncome(currentIncome[key]))}
            </div>
          </Anchor>
          <div>
            {pastMedical && // eslint-disable-next-line
              Object.keys(pastMedical).map(key => renderMedicalInsurance(pastMedical[key]))}
          </div>
          <div>{pastPbm && Object.keys(pastPbm).map(key => renderPbmInsurance(pastPbm[key]))}</div>
          <div>
            {pastIncome && Object.keys(pastIncome).map(key => renderIncome(pastIncome[key]))}
          </div>
        </Loader>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onToggleIncomeFormDisplay: bool => {
      dispatch(new ToggleDisplayAddIncome(bool));
    },
    onToggleMedicalInsuranceFormDisplay: bool => {
      dispatch(new ToggleDisplayAddMedicalInsurance(bool));
    },
    onTogglePBMInsuranceFormDisplay: bool => {
      dispatch(new ToggleDisplayAddPBMInsurance(bool));
    },
    onToggleFinancialAssistanceFormDisplay: bool => {
      dispatch(new ToggleDisplayAddFinancialAssistance(bool));
    },
    onCancelForms: () => {
      dispatch(new ToggleDisplayAddIncome(false));
      dispatch(new ToggleDisplayAddMedicalInsurance(false));
      dispatch(new ToggleDisplayAddPBMInsurance(false));
      dispatch(new ToggleDisplayAddFinancialAssistance(false));
    },
  };
}

function mapStateToProps(state) {
  const { patient, financials } = state;
  return {
    patient,
    financials,
    displayAddIncome: state.formDisplays.displayAddIncome,
    displayAddMedical: state.formDisplays.displayAddMedicalInsurance,
    displayAddPbm: state.formDisplays.displayAddPBMInsurance,
    displayAddFinancialAssistance: state.formDisplays.displayAddFinancialAssistance,
    selectedPatientId: state.selectedPatientId,
    loading:
      financials.medical_insurances.loading ||
      financials.pbm_insurances.loading ||
      financials.incomes.loading,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(FinancialsListOld);

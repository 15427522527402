import { Action } from 'redux';

import { IPatient } from 'interfaces/redux/IPatient';
import HTTP from '../services/http';
import {
  FETCH_USER_CUSTOM_LISTS_SUCCESS,
  ADD_USER_CUSTOM_LIST,
  DELETE_USER_CUSTOM_LIST_ITEM,
  ADD_USER_CUSTOM_LIST_ITEM,
  ADD_USER_CUSTOM_LIST_WORKFLOW,
  UPDATE_ADDED_USER_CUSTOM_LIST_ITEM,
} from '../constants/index';

export const fetchUserCustomLists = (userId?: number): UserCustomListAction => {
  const userIdParam = userId || 'me';
  const request = HTTP.get(`/custom-lists/${userIdParam}`, {});
  return {
    type: FETCH_USER_CUSTOM_LISTS_SUCCESS,
    payload: request as any,
  };
};

export const addUserCustomListWorkflow = (
  listId?: number,
  patient?: IPatient,
): UserCustomListAction => {
  return {
    type: ADD_USER_CUSTOM_LIST_WORKFLOW,
    payload: { listId, patient },
  };
};

export const addUserCustomList = (listName?: string): UserCustomListAction => {
  const listNameForAdd = listName || 'My Highlighted Patients';
  const request = HTTP.post('/custom-lists', { list_name: listNameForAdd }, {});

  return {
    type: ADD_USER_CUSTOM_LIST,
    payload: request as any,
  };
};

export const updateAddedUserCustomListItem = (
  listId: number,
  patient: IPatient,
): UserCustomListAction => {
  return {
    type: UPDATE_ADDED_USER_CUSTOM_LIST_ITEM,
    payload: { listId, patient },
  };
};

export const addUserCustomListItem = (listId: number, patient: IPatient): UserCustomListAction => {
  const patientId = patient.id;
  const request = HTTP.post('/custom-lists-value', { list_id: listId, patient_id: patientId }, {});

  return {
    type: ADD_USER_CUSTOM_LIST_ITEM,
    payload: request as any,
  };
};

export const deleteUserCustomListItem = (listItemId: number): UserCustomListAction => {
  const request = HTTP.delete(`/custom-lists-value-delete/${listItemId}`, {});

  return {
    type: DELETE_USER_CUSTOM_LIST_ITEM,
    payload: request as any,
  };
};

export class FetchUserCustomLists implements Action {
  readonly type = FETCH_USER_CUSTOM_LISTS_SUCCESS;

  constructor(public payload: any) {}
}

export class AddUserCustomListsWorkflow implements Action {
  readonly type = ADD_USER_CUSTOM_LIST_WORKFLOW;

  constructor(public payload: any) {}
}

export class UpdateAddedUserCustomListItem implements Action {
  readonly type = UPDATE_ADDED_USER_CUSTOM_LIST_ITEM;

  constructor(public payload: any) {}
}

export class AddUserCustomLists implements Action {
  readonly type = ADD_USER_CUSTOM_LIST;

  constructor(public payload: any) {}
}

export class AddUserCustomListsItem implements Action {
  readonly type = ADD_USER_CUSTOM_LIST_ITEM;

  constructor(public payload: any) {}
}

export class DeleteUserCustomListsItem implements Action {
  readonly type = DELETE_USER_CUSTOM_LIST_ITEM;

  constructor(public payload: any) {}
}

export type UserCustomListAction =
  | FetchUserCustomLists
  | AddUserCustomListsWorkflow
  | UpdateAddedUserCustomListItem
  | AddUserCustomLists
  | AddUserCustomListsItem
  | DeleteUserCustomListsItem;

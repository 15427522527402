import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

export const CslProtocolFormModalStyles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    modal: {
      minWidth: theme.spacing(100),
    },
    title: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(1),
    },
    titleWrp: {
      paddingBottom: 0,
    },
    snackbar: {
      backgroundColor: palette.primary.grey2,
      borderRadius: 0,
      boxShadow: 'none',
      color: theme.palette.text.primary,
      fontSize: theme.typography.fontSize * 1.1,
    },
    icdWrp: {
      width: '75%',
      borderBottom: `1px dotted ${theme.palette.text.disabled}`,
      minHeight: theme.spacing(4.5),
    },
    icdFieldLabel: {
      color: palette.primary.grey12,
      fontSize: theme.spacing(1.5),
      minHeight: theme.spacing(3.75),
      fontWeight: theme.typography.fontWeightRegular,
      marginBottom: theme.spacing(-0.75),
    },
    contentFieldLabel: {
      fontSize: theme.typography.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      paddingBottom: theme.spacing(1.5),
    },
    actionsWrp: {
      padding: theme.spacing(4, 0, 3), // 32px 0 24px;
      margin: theme.spacing(0, 3), // 0 24px;
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    icdChip: {
      marginBottom: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
    },
    progress: {
      marginLeft: theme.spacing(0.5),
    },
  });
};

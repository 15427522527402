import { useTypedSelector } from 'hooks/use-typed-selector';
import React from 'react';
import { buildQaId } from 'utils/build-qa-id';
import { Typography } from '@mui/material';
import { useStyles } from './preferred-contact.styles';
import { getPreferredContactName, getPatientFullName } from './utils';

const getQaId = buildQaId('preferred-contact-name');

export const PreferredContactName: React.FC = () => {
  const classes = useStyles();

  const preferredContact = useTypedSelector(state => state.preferredContact);
  const patient = useTypedSelector(state => state.patient);

  const contactName = React.useMemo<string | undefined>(() => {
    return preferredContact
      ? getPreferredContactName(preferredContact)
      : getPatientFullName(patient);
  }, [preferredContact]);

  return (
    <>
      <Typography data-qa-id={getQaId('name')} className={classes.typographyTextSmall}>
        {contactName}
      </Typography>
    </>
  );
};

import { ROUTES } from 'constants/index';
import { MenuStructure } from './menu-structure';

export const getApplicationManagerUrlParts = (
  currentPath: string,
): { firstPart: string; secondPart: string } | undefined => {
  // eslint-disable-next-line no-useless-escape
  const regexPathName = /\/application-manager\/([^\/]+)\/([^\/]+)/;
  const theMatches = currentPath.match(regexPathName);
  if (theMatches != null) {
    return { firstPart: theMatches[1], secondPart: theMatches[2] };
  }
    return undefined;
};

export const sendToDefault = (pushFn: (path: string) => void) => {
  const getFirstSlug = Object.keys(MenuStructure)[0];
  const getSecondSlug = MenuStructure[getFirstSlug].items[0].routeSlug;

  const defaultLocation = `${ROUTES.APPLICATION_MANAGER}/${getFirstSlug}/${getSecondSlug}`;

  pushFn(defaultLocation);
};

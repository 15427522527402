import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { useState } from 'react';
import {
  getStatusByStatusId,
  getStatusIdByStatusName,
  getStatusReasonByStatusId,
} from 'services/utils/task-service';
import NoteDisplay from 'components/note/note-display';
import DocumentDisplay from 'components/document/document-display';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import TaskDetailForm from 'components/dynamic-form/task-forms/task-detail-form';
import TaskStatusForm from 'components/dynamic-form/task-forms/task-status-form';
import ActionRow from 'components/note/action-row';
import { useFillCycleNumber } from 'hooks/useFillCycleNumber';
import { TASK_FINANCIAL_ASSISTANCE, FA } from '../../../constants/index';
import { styles } from '../task-detail-styles';
import EditFa from './edit-fa';
import AddFAData from './add-fa-data';
import TaskLinks from '../task-links';
import FaData from './fa-data';
import { StatusSelector } from '../common/status-selector';

const FaDetail = props => {
  const {
    statuses,
    task,
    classes,
    notes,
    documents,
    therapy,
    financials,
    tagTypeId,
    tagTypeLabel,
    serviceGroups,
    displayEdit,
    handleCancel,
    taskIDPrefix,
    drugName,
    currentUser,
    resourceStates,
    archived,
  } = props;

  const {
    status_id: statusId,
    service_group_id: serviceGroupId,
    fill_cycle_number: fillCycleNumber,
    therapy_id: therapyId,
  } = task;

  const [selectedStatus, setSelectedStatus] = useState(
    task.status ? task.status : getStatusByStatusId(task.status_id, statuses),
  );
  const [displayAddForm, setDisplayAddForm] = useState(false);

  const selectedStatusId = getStatusIdByStatusName(selectedStatus, statuses);

  const [needsByDate] = useFillCycleNumber(therapyId, fillCycleNumber);

  const faResourceStates = (resourceStates || []).filter(
    resourceState => resourceState.resource_name === FA,
  );

  return (
    <>
      <Grid
        container
        alignItems="center"
        className={displayEdit ? classes.taskDetailContainerEdit : classes.taskDetailContainer}
      >
        <Grid item lg={3}>
          <Grid container alignItems="center">
            {!displayEdit && (
              <StatusSelector
                value={selectedStatus}
                currentStatus={task.status}
                onChange={value => setSelectedStatus(value)}
                statuses={statuses}
                taskIDPrefix={taskIDPrefix}
                task={task}
                currentUser={currentUser}
                resourceStates={faResourceStates}
              />
            )}
          </Grid>
        </Grid>
        {!displayEdit && (
          <Grid item lg={5} xs={6}>
            <TaskDetailForm
              task={{
                ...task,
                needsby_date: needsByDate,
                status_reason: getStatusReasonByStatusId(statusId, statuses),
              }}
              taskType="FA"
            />
          </Grid>
        )}
        {displayEdit && <Grid item xs={5} />}
        <Grid item xs={4}>
          {!displayEdit && (
            <Grid item xs={12}>
              <Grid container className={classes.note}>
                <NoteDisplay {...notes} tagResourceId={task.id} />
              </Grid>
              <DocumentDisplay
                taskIdResourceIds={[{ tagTypeId, resourceId: task.id }]}
                tagTypeId={tagTypeId}
                resourceId={task.id}
                drugName={drugName}
                tagTypeLabel={tagTypeLabel}
                documents={documents}
              />
              <ActionRow
                therapy={therapy}
                task={task}
                tagTypeId={tagTypeId}
                tagResourceId={task.id}
                cancel={handleCancel}
                defaultServiceGroup={serviceGroupId}
                serviceGroups={serviceGroups}
                taskType={TASK_FINANCIAL_ASSISTANCE}
                idPrefix={taskIDPrefix}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <TaskLinks links={task.links} />
      <Grid item xs={12}>
        {displayAddForm && (
          <AddFAData
            handleCancel={() => setDisplayAddForm(!displayAddForm)}
            therapy={therapy}
            task={task}
          />
        )}
      </Grid>
      <Grid container>
        {displayEdit && (
          <Grid item xs={12}>
            <EditFa
              task={task}
              statuses={statuses}
              cancel={handleCancel}
              serviceGroups={serviceGroups}
            />
          </Grid>
        )}
        {!displayEdit &&
          selectedStatus !== getStatusByStatusId(statusId, statuses) &&
          statusId &&
          statuses && (
            <Grid
              item
              xs={12}
              className={selectedStatus !== task.status ? classes.statusContainer : null}
            >
              <TaskStatusForm
                task={task}
                forceStatusId={selectedStatusId}
                overrideForceStatusId
                onCancel={() => setSelectedStatus(getStatusByStatusId(task.status_id, statuses))}
              />
            </Grid>
          )}
        <FaData
          financials={financials}
          therapy={therapy}
          task={task}
          targetStatusId={selectedStatusId}
          archived
        />
      </Grid>
    </>
  );
};

function mapStateToProps(state) {
  const { financials, auth, lookups } = state;

  return {
    financials,
    users: state.lookups.users,
    currentUser: auth?.currentUser,
    resourceStates: lookups?.resourceStates,
  };
}

export default compose(withStyles(styles), connect(mapStateToProps))(FaDetail);

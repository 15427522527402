import orJson from 'config/task-types/or.json';
import { sortWithOtherComparer } from 'services/utils/task-service';
import { TherapyUtil } from 'utils/therapy-util';
import { staticProviders, getCommonProviders } from './common-provider';
import { OUTREACH_DEPRECATED_TYPES } from '../../../constants/index';

export default (task, state) => {
  const taskStatuses = state.taskStatuses || {
    outreach_categories: [],
    outreach_types: [],
    outreach_method: [],
  };

  const outreachDeprecatedTypeIds = taskStatuses.outreach_types.filter(item => OUTREACH_DEPRECATED_TYPES.includes(item.name.toLowerCase())).map(item => item.id);
  const mapToIdName = (id, name) => it => ({
    name: it[name],
    id: it[id],
  });

  const createDropdownItemList = (list, id, name, filterId, filterName) => {
    let newFilter = list;
    if (filterId) {
      newFilter = list.filter(r => r[filterId] === filterName);
    }
    newFilter.sort(sortWithOtherComparer(name));
    return newFilter.map(mapToIdName(id, name));
  };
  const therapies =
    state && state.therapies
      ? Object.values(state?.therapies).map(therapy => ({
          ...therapy,
          // eslint-disable-next-line max-len
          drug_name_with_dosage: TherapyUtil.therapyFormatDosage(therapy),
        }))
      : [];

  if (state?.formValues?.type_id && outreachDeprecatedTypeIds.includes(state.formValues.type_id)) {
    state.change(state.formId, 'type_id', null);
  }

  return {
    ...staticProviders,
    ...getCommonProviders(task, state, orJson),
    json: orJson,
    categories: taskStatuses.outreach_categories,
    liaisonTypes: taskStatuses.outreach_types.filter(type => type.category_id === 1 && !OUTREACH_DEPRECATED_TYPES.includes(type.name.toLowerCase())),
    pharmacistTypes: taskStatuses.outreach_types.filter(type => type.category_id === 2 && !OUTREACH_DEPRECATED_TYPES.includes(type.name.toLowerCase())),
    liaisonSubtypes: taskStatuses.outreach_types.filter(type => type.category_id === 3 && !OUTREACH_DEPRECATED_TYPES.includes(type.name.toLowerCase())),
    pharmacistSubtypes: taskStatuses.outreach_types.filter(type => type.category_id === 4 && !OUTREACH_DEPRECATED_TYPES.includes(type.name.toLowerCase())),
    methods: taskStatuses.outreach_method,
    outreachCategories: [],
    outreachMethods: [],
    therapies: createDropdownItemList(therapies, 'id', 'drug_name_with_dosage'),
    initialValues: {
      therapy_id: task.therapy_id || null,
      therapy_ids: task.therapy_id ? `${task.therapy_id}` : null,
    },
  };
};

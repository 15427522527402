import React from 'react';
import { Grid, Typography, Divider, CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { IPatientSmsMessage, SmsDirection } from 'interfaces/redux/IPatient';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { fetchSmsMessagesSuccess } from 'actions/action-patient';
import { clearNoteBarMessageFocus } from 'actions/action-notes';
import { notifyError } from 'actions/action-notifications';
import { PatientSmsClient } from 'clients/patient-sms';
import { usePatient } from 'hooks/usePatient';
import { mapSmsResponseToPatientSms } from '../utils';
import { useTypedStyles } from './conversation.styles';
import { IProps } from './types';
import { ActionRow } from '../action-row/action-row';
import CommunicationBox from '../CommunicationBox';
import { logger } from '../../../winston-logger';

export const Conversation: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const scrollAreaRef = React.useRef();
  const { classes } = useTypedStyles();
  const patient = useTypedSelector(state => state.patient);
  const userDisplayName = useTypedSelector(s => s.auth.currentUser.display_name);
  const smsIdFocus = useTypedSelector(state => state.noteBar?.focusOnSmsId || 0);
  const { patientFullName } = usePatient(patient);
  const [patientSmsMessages, setPatientSmsMessages] = React.useState<IPatientSmsMessage[]>(
    patient.sms_messages,
  );
  const [isFetchingMessages, setIsFetchingMessages] = React.useState<boolean>(false);
  const patientId = patient.id;
  const messageRef: any = React.useRef(0);
  const animationRef = React.useRef(false);
  const fetchMessages = async (patientId: number): Promise<void> => {
    setIsFetchingMessages(true);
    try {
      const { data } = await PatientSmsClient.fetchPatientSmsMessages(patientId);
      dispatch(
        fetchSmsMessagesSuccess(
          data.map(msg => mapSmsResponseToPatientSms(patientFullName, '', msg)),
        ),
      );
    } catch (error) {
      logger.error(error);
      dispatch(notifyError('Error retrieving patient messages.'));
    } finally {
      setIsFetchingMessages(false);
    }
  };

  const animationSettings = [
    { outline: '2px dashed transparent', offset: 0 },
    { outline: '2px dashed green', offset: 0.25 },
    { outline: '2px dashed transparent', offset: 0.5 },
    { outline: '2px dashed green', offset: 0.75 },
    { outline: '2px dashed transparent', offset: 1.0 },
  ];

  React.useEffect(() => {
    if (patientId) {
      fetchMessages(patientId);
    }
  }, [patientId]);

  React.useEffect(() => {
    animationRef.current = false;
  }, [smsIdFocus]);

  React.useEffect(() => {
    const areNewMessages =
      patient.sms_messages &&
      patientSmsMessages &&
      patientSmsMessages.length < patient.sms_messages.length;

    setPatientSmsMessages(patient.sms_messages);
    // scroll to top when a new message is appended
    if (areNewMessages && scrollAreaRef?.current) {
      (scrollAreaRef.current as any).scrollTop = 0;
    }
  }, [patient.sms_messages]);

  React.useEffect(() => {
    if (messageRef.current && !animationRef.current) {
      messageRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      messageRef.current.animate(animationSettings, 3000);
      messageRef.current = 0;
      animationRef.current = true;
      dispatch(clearNoteBarMessageFocus());
    }
  });

  const smsMessages = patientSmsMessages || [];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.title}>Recent Messages</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <>
          <Grid container spacing={1} className={classes.conversationContainer}>
            {isFetchingMessages === false && smsMessages.length === 0 && (
              <Grid item xs={12} className={classes.noMessagesWrapper}>
                <Typography className={classes.noMessagesLabel}>
                  No messages sent to this patient yet.
                </Typography>
              </Grid>
            )}
            {isFetchingMessages === true && (
              <Grid container justifyContent="center">
                <CircularProgress size={30} variant="indeterminate" />
              </Grid>
            )}
            {isFetchingMessages === false &&
              smsMessages.map(message => {
                const setReference =
                  message.id === smsIdFocus
                    ? (element: any) => {
                        messageRef.current = element;
                      }
                    : null;
                return (
                  <Grid item xs={12} ref={setReference}>
                    <ActionRow
                      smsMessage={message}
                      patientId={patientId}
                      userDisplayName={userDisplayName}
                    />
                    <CommunicationBox
                      communication={{
                        is_incoming: message.type === SmsDirection.Inbound,
                        type: 'SMS',
                      }}
                      display_status={message.display_status}
                      note_text={message.content}
                      to_number={message.toNumber}
                      patient_full_name={patientFullName}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </>
      </Grid>
    </Grid>
  );
};

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

const styles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    modalTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(3),
    },
    fieldsWrapper: {
      paddingTop: theme.spacing(3),
    },
    modalContentWrapper: {
      position: 'absolute',
      width: theme.spacing(150),
      backgroundColor: palette.primary.background,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      borderRadius: 5,
      maxHeight: '92vh',
      overflow: 'auto',
    },
    tableWrapper: {
      padding: theme.spacing(3),
    },
    checkboxWrapper: {
      display: 'inline',
    },
    labsTableWrapper: {
      border: `1px solid ${palette.primary.grey13}`,
    },
    scrollingColumn: {
      overflowY: 'scroll',
      maxHeight: theme.spacing(75),
      padding: theme.spacing(0.5),
    },
  });
};

export { styles };

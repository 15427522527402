import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import VerticalTabs from 'components/vertical-tabs';
import { getModalStyle } from 'services/utils/styles-service';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { convertToArborDate } from 'models/time/arbor-date';
import { getUserById } from 'services/utils/users-service';
import AdherenceHistoryReviewContainer from './adherence-history-review-container';
import { fetchPatientAdherenceHistory } from '../../actions/action-patient';
import { styles } from './adherence-history-review-styles';

const AdherenceReviewHistory = (props: any): JSX.Element => {
  const { classes } = props;
  const dispatch = useDispatch();
  const users = useTypedSelector(state => state.lookups.users);
  const patientId = useTypedSelector(state => state.selectedPatientId);
  const adherenceHistoryData = useTypedSelector(state => state.patient.adherence_history_data);

  const renderLabel = (instance: any) => {
    const reviewedBy = instance ? getUserById(instance.updated_by, users).username : '';
    const reviewedDate =
      instance && convertToArborDate(instance.updated)
        ? convertToArborDate(instance.updated).getCustomerDatetime(true)
        : null;

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body2">{`${reviewedDate} - ${reviewedBy}`}</Typography>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    if (!adherenceHistoryData) {
      dispatch(fetchPatientAdherenceHistory(patientId));
    }
  }, [adherenceHistoryData]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      style={getModalStyle()}
      className={classes.adherenceHistoryModal}
    >
      {adherenceHistoryData && (
        <Grid item xs={12}>
          {!!adherenceHistoryData && adherenceHistoryData.length ? (
            <VerticalTabs
              variantProp={{ variant: 'scrollable' }}
              augmentVerticalTabsInnerContainer={classes.historyInstancePadding}
              augmentVerticalTabsTabsContainer={classes.historyTabsContainer}
              tabs={adherenceHistoryData.map(snapShot => ({
                label: renderLabel(snapShot),
                content: (
                  <AdherenceHistoryReviewContainer
                    snapShot={snapShot}
                  />
                ),
              }))}
            />
          ) : (
            <Typography className={classes.adherenceLoading} variant="body1">
              No Adherence history
            </Typography>
          )}
        </Grid>
      )}
      {!adherenceHistoryData && (
        <Typography className={classes.adherenceLoading} variant="body1">
          ...Loading Adherence Intervention History
        </Typography>
      )}
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(AdherenceReviewHistory);

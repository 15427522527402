import { AxiosPromise } from 'axios';
import { ISendSmsRequest } from 'models/patient-sms/ISendSmsRequest';
import { ISendSmsResponse } from 'models/patient-sms/ISendSmsResponse';
import { ISmsResponse } from 'models/patient-sms/ISmsResponse';
import {
  ISmsMessageTagOptionsResponse,
  ISmsMessageTagUpdateResponse,
  ISmsMessageTagUpdateRequestBody,
  ISmsMessageTagValue,
} from 'models/patient-sms/ISmsTagUpdates';
import { TypedHttp } from 'services/typed-http';

const buildUri = (patientId: number) => `/patients/${patientId}`;

export class PatientSmsClient {
  static sendMessageToPatient(
    patientId: number,
    smsRequest: ISendSmsRequest,
  ): AxiosPromise<ISendSmsResponse> {
    const url = `${buildUri(patientId)}/send_sms_message`;
    return TypedHttp.PostPromise<ISendSmsRequest, ISendSmsResponse>(url, smsRequest);
  }

  static fetchPatientSmsMessages(patientId: number): AxiosPromise<ISmsResponse[]> {
    const url = `${buildUri(patientId)}/sms`;
    return TypedHttp.GetPromise<ISmsResponse[]>(url);
  }

  static fetchPatientTherapyAndTaskTags(
    patientId: number,
    customerId: number,
    smsId: number,
  ): AxiosPromise<ISmsMessageTagOptionsResponse[]> {
    const url = `/patient/${patientId}/customer/${customerId}/sms/${smsId}/sms_message_tag_options`;
    return TypedHttp.GetPromise<ISmsMessageTagOptionsResponse[]>(url);
  }

  static updateSmsMessageTags(
    smsId: number,
    payload: { tags: ISmsMessageTagValue[] },
  ): AxiosPromise<ISmsMessageTagUpdateResponse> {
    const url = `/sms/${smsId}/update_sms_message_tags`;
    return TypedHttp.PatchPromise<ISmsMessageTagUpdateRequestBody, ISmsMessageTagUpdateResponse>(
      url,
      payload,
    );
  }
}

export enum PhiEntityType {
  Age = 'AGE',
  Date = 'DATE',
  Name = 'NAME',
  PhoneOrFax = 'PHONE_OR_FAX',
  Email = 'EMAIL',
  Id = 'ID',
  Url = 'URL',
  Address = 'ADDRESS',
  Profession = 'PROFESSION',
}

export interface PhiEntity {
  text: string;
  type: PhiEntityType;
}

export interface ISendSmsErrorResponse {
  detected_phi?: PhiEntity[];
  error_type: string;
  message: string;
}

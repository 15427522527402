const makeIdNameListFormatter = (idProp, nameProp, valueAsString) => list =>
  list &&
  list.map(item => ({
    [idProp]: valueAsString ? String(item.id) : item.id,
    [nameProp]: item.display_name ? item.display_name : item.name,
    onSelect: item.onSelect,
    highlight: item.highlight,
    disabled: item.disabled,
  }));

export const valueLabelFormatter = makeIdNameListFormatter('value', 'label');
export const stringValueLabelFormatter = makeIdNameListFormatter('value', 'label', true);

export const noFormatter = list => list;

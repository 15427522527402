import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { DataTable } from '../table/Table';
import { IGpiTableProps } from '../types';

const GPITable: FC<IGpiTableProps> = ({
  userPermission,
  searchString,
  reloadTrigger,
  onReload,
  handleGpiProtocolEdit,
}) => (
  <Grid container direction="column" spacing={2}>
    <Grid item>
      <DataTable
        searchTerm={searchString}
        triggerReload={reloadTrigger}
        handleReloadDataTrigger={onReload}
        userHasPermissionToEditDcItem={userPermission}
        handleGpiProtocolEdit={handleGpiProtocolEdit}
      />
    </Grid>
  </Grid>
);

export default GPITable;

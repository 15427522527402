import withStyles from '@mui/styles/withStyles';
import { Table, TableBody, TableCell, TableHead, TableRow, Grid } from '@mui/material';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { formatPatientNameShort } from 'services/utils/demographic-service';
import { convertToArborDate } from 'models/time/arbor-date';
import { RetryIcon } from 'components/icons/icons';
import { styles } from '../side-bar-styles';

class PatientList extends Component {
  renderTableHeader() {
    const { classes } = this.props;
    return (
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHeader}>Patient Name</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  render() {
    const { classes, patient } = this.props;
    return (
      <div className={classes.tableContainer}>
        <Table className={classes.table}>
          {this.renderTableHeader()}
          <TableBody>
            <TableRow
              hover
              key={patient.id}
              id={patient.id}
              className={classNames(classes.tableRow, classes.tableRowSelected)}
            >
              <TableCell className={classes.tableCell} component="th" scope="row">
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  className={
                    patient.needs_reconciliation
                      ? classes.patientNameGrid
                      : classes.patientNameGridLonger
                  }
                >
                  <Grid item>{patient.needs_reconciliation && <RetryIcon size={16} />}</Grid>
                  <Grid item>
                    <div className={classes.tablePatientName}>
                      {formatPatientNameShort(patient)}
                    </div>
                    <div>
                      {patient.dob ? convertToArborDate(patient.dob, true).getUtcDate(true) : '-'}
                    </div>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { patient } = state;
  return {
    patient,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, null),
)(PatientList);

import React from 'react';
import { TableRow, TableCell, Checkbox, Tooltip, Typography } from '@mui/material';
import { IMedSyncRowData } from 'models/meds/IMedSync';
import { ExclamationCircleIcon } from 'components/icons/icons';
import { MED_SYNC_STATUS } from 'constants/index';
import { styles } from './table.style';
import trellislogo from '../../../../../lib/logos/trellis_logo.png';
import { displayMedSyncDate } from '../../med-sync-utils';

interface ITableRowsProps {
  data: IMedSyncRowData;
  onSelectRow: (therapyId: number) => void;
  onSelectAdherence: (therapyId: number) => void;
  disableRow: boolean;
}

export const MedSyncTableRow: React.FC<ITableRowsProps> = (props): JSX.Element => {
  const classes: any = styles();

  const { data, onSelectRow, onSelectAdherence, disableRow } = props;

  return (
    <TableRow
      className={
        // eslint-disable-next-line no-nested-ternary
        data.inConflict
          ? classes.rowInConflict
          : disableRow
          ? classes.disabledRow
          : classes.activeRow
      }
    >
      <TableCell className={classes.headerNarrowColumn}>
        <Checkbox
          color="success"
          disabled={!!disableRow}
          checked={!!data.inMedSync}
          className={classes.checkbox}
          onClick={() => onSelectRow(data.id)}
        />
      </TableCell>
      <TableCell>
        <span>{data.drugName.toUpperCase()} {data.strength}</span>
        {data.isSpecialty ? <span className={classes.specialtyBox}>SP</span> : ''}
        <img src={trellislogo} alt="trellis logo" className={classes.trellisLogo} />
      </TableCell>
      <TableCell>{data.ndc}</TableCell>
      <TableCell>{data.dose}</TableCell>
      <TableCell>{data.form}</TableCell>
      <TableCell>
        {data.dispenseStatusId === MED_SYNC_STATUS.OPT_IN ? displayMedSyncDate(data.nbd) : ''}
      </TableCell>
      <TableCell>
        <Checkbox
          color="success"
          disabled={!!disableRow}
          checked={!!data.adherencePack}
          className={classes.checkbox}
          onClick={() => onSelectAdherence(data.id)}
        />
      </TableCell>
      <TableCell>
        {data.dispenseStatusId === MED_SYNC_STATUS.OPT_IN ? data.daysSupply : ''}
        {data.inConflict && (
          <Tooltip title="This medication is out of sync" placement="top-end">
            <span>
              <ExclamationCircleIcon size={20} className={classes.exclamationCircleConflictIcon} />
            </span>
          </Tooltip>
        )}
        {!data.inConflict && disableRow && (
          <Tooltip
            title={
              <>
                <Typography>This therapy cannot be</Typography>
                <Typography>synchronized in this group.</Typography>
              </>
            }
            placement="top-end"
          >
            <span>
              <ExclamationCircleIcon
                size={20}
                className={
                  data.dispenseStatusId === MED_SYNC_STATUS.OPT_IN
                    ? classes.dispenseOptIn
                    : classes.dispenseNotOptIn
                }
              />
            </span>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

import React, { PropsWithChildren, useState, useEffect } from 'react';

import { Grid, Typography } from '@mui/material';

import useUuid from 'hooks/useUuid';

import {
  GroupTable,
  IColumn,
  ICustomComponents,
  IGroup,
} from 'components/medication-list-group-table';
import { IMedicationListItem } from 'interfaces/redux/IMedications';
import { IState } from 'interfaces/redux/IState';
import { MedicationStatus } from 'constants/enums';
import MedicationLink from '../medication-link';

import { buildMedGroups, buildMedicationIdLookup } from '../gpi-medication-group/utils';

import { IProps as MedListProps } from '../gpi-medication-group/types';
import { useTypedStyles } from '../gpi-medication-group/gpi-medication-group.styles';

type IProps = Pick<MedListProps, 'medications' | 'medicationGroups'>;
const statusFilters = [MedicationStatus.Active];

export const PrintableMedList: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { classes } = useTypedStyles();
  const id = useUuid();

  // #region component state
  const [originalMedGroups, setOriginalMedGroups] = useState<IGroup[] | false>(false);

  const [medsLookupById, setMedsLookupById] = useState<Record<number, IMedicationListItem>>(
    buildMedicationIdLookup(props.medicationGroups, props.medications),
  );

  const [medGroups, setMedGroups] = useState<IGroup[]>(() => {
    const data = buildMedGroups(props.medicationGroups, props.medications, statusFilters);
    if (!originalMedGroups) {
      setOriginalMedGroups(data);
    }
    return data;
  });

  const [columns, setColumns] = useState<IColumn[]>([]);
  // #endregion component state

  // #region side effects
  useEffect(() => {
    setMedsLookupById(buildMedicationIdLookup(props.medicationGroups, props.medications));
  }, [props.medicationGroups, props.medications]);

  useEffect(() => {
    const data = buildMedGroups(props.medicationGroups, props.medications, statusFilters);
    setOriginalMedGroups(data);
    setMedGroups(data);
  }, [props.medicationGroups, props.medications, statusFilters]);

  useEffect(() => {
    const data = buildMedGroups(props.medicationGroups, props.medications, statusFilters);
    if (!originalMedGroups) {
      setOriginalMedGroups(data);
    }
    setMedGroups(data);
  }, [props.medicationGroups, props.medications, statusFilters]);

  // #endregion side effects

  // #region table config
  const renderTableHeader = (props: PropsWithChildren<{}>): JSX.Element => (
    <Typography className={classes.tableHeaderCell}>{props.children}</Typography>
  );

  const renderNonEditableParentRow = (props: PropsWithChildren<{}>): JSX.Element => (
    <Typography className={classes.parentRowCell}>{props.children}</Typography>
  );

  const renderNonEditableChildRow = (props: PropsWithChildren<{}>): JSX.Element => (
    <Typography className={classes.childRowCell}>{props.children}</Typography>
  );

  const getDrugNameRenderer =
    (scale: number, isParent: boolean) =>
    (innerProps: PropsWithChildren<{ identifier: number }>): JSX.Element => {
      const med = medsLookupById[innerProps.identifier] || {};
      return (
        <div
          style={{
            transform: `scale(${scale})`,
          }}
        >
          <MedicationLink
            medication={med}
            id={innerProps.identifier}
            disabled
            displayPill={isParent}
            group
          />
        </div>
      );
    };

  const renderLinkIcon = (innerProps: PropsWithChildren<{ identifier: number }>): JSX.Element => {
    const med = medsLookupById[innerProps.identifier] || {};
    return (
      <MedicationLink
        medication={med}
        id={innerProps.identifier}
        disabled={false}
        group
        type="actionIcon"
      />
    );
  };

  const components: ICustomComponents = {
    headerCell: renderTableHeader,
    parentRowCell: renderNonEditableParentRow,
    childRowCell: renderNonEditableChildRow,
  };

  const generateColumns = (): IColumn[] => {
    return [
      {
        columnName: 'drug_name',
        displayName: 'Drug Name',
        sortable: true,
        customComponents: {
          parentRowCell: getDrugNameRenderer(0.9, true),
          childRowCell: getDrugNameRenderer(0.8, false),
        },
      },

      {
        columnName: 'dose',
        displayName: 'Dose',
        sortable: true,
        customComponents: {
          parentRowCell: renderNonEditableChildRow,
        },
      },
      {
        columnName: 'dosage_form',
        displayName: 'Form',
        sortable: true,
        customComponents: {
          parentRowCell: renderNonEditableChildRow,
        },
      },
    ];
  };

  useEffect(() => {
    setColumns(generateColumns());
  }, [medsLookupById]);

  // #endregion table config

  // #region main render
  return (
    <Grid container>
      <Grid item xs={12}>
        <GroupTable
          key={`table-${id}`}
          columns={columns}
          groups={medGroups}
          customComponents={components}
          defaultSortedBy="drug_name"
          hideChildren
        />
      </Grid>
    </Grid>
  );
  // #endregion main render
};

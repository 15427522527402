import { AddAddressModalActionType } from 'actions/action-add-address-modal';
import { AddEmailModalActionType, IAddEmailModalAction } from 'actions/action-add-email-modal';
import { IAddEmailModal } from 'interfaces/redux/IAddEmailModal';

const initialState: IAddEmailModal = {
  visible: false,
  formId: undefined,
  field: undefined,
};

export const addEmailModalReducer = (
  state: IAddEmailModal = initialState,
  action: IAddEmailModalAction,
) => {
  switch (action.type) {
    case AddEmailModalActionType.SetState: {
      return {
        ...action.payload,
      };
    }
    case AddEmailModalActionType.ResetModal: {
      return initialState;
    }
    default:
      return state;
  }
};

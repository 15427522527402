import { Typography, Grid, Button, TextField } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { convertMapToList } from 'reducers/helper-reducer';
import { updateTherapy } from 'actions/action-therapies';
import { TherapyLookupStatusReasons } from 'constants/lists';
import { markPatientAsDeceased } from '../../../actions/action-patient';
import { styles } from './patient-demographics-styles';

const PatientDeceased = props => {
  const { classes, verifyValue, isDeceasedType, cancelCallback, reconcileCallback } = props;
  const dispatch = useDispatch();
  const [verifyString, setVerifyString] = useState('');
  const [allowVerify, setAllowVerify] = useState(false);

  const selectedPatientId = useSelector(state => state.selectedPatientId);
  const patientState = useSelector(state => state.patient);
  const isRightPatient = patientState && patientState.id === selectedPatientId;

  const therapiesState = useSelector(state => state.therapies);
  const therapies = convertMapToList(therapiesState.data);
  const patient = isRightPatient ? patientState : {};
  const lookupStatusReasons = useSelector(state => state.lookups.lookupStatusReasons);

  const handleVerify = e => {
    setVerifyString(e.target.value);
    if (e.target.value === verifyValue.name) {
      setAllowVerify(true);
    } else {
      setAllowVerify(false);
    }
  };

  const handlePatientDeceased = () => {
    if (isDeceasedType && patient.id) {
      dispatch(markPatientAsDeceased(patient.id));
      const patientDeceasedStatusReason = lookupStatusReasons.find(item => item.id === TherapyLookupStatusReasons.patientDeceased);
      Object.values(therapies).forEach(item => {
        dispatch(
          updateTherapy(item.id, {
            administration_status_id: patientDeceasedStatusReason.id,
            administration_status: patientDeceasedStatusReason.status,
            administration_status_category_id: patientDeceasedStatusReason.category_id,
            administration_status_reason: patientDeceasedStatusReason.reason,
          }),
        );
      });
    }
    setVerifyString('');
    setAllowVerify(false);
    if (reconcileCallback) {
      reconcileCallback();
    }
  };

  const handleCancel = () => {
    setVerifyString('');
    setAllowVerify(false);
    if (cancelCallback) {
      cancelCallback();
    }
  };

  return (
    <Grid container alignItems="center">
      <Grid item xs={12} className={classes.modalTitle}>
        <Typography className={classes.patientStatusTitle}>
          Are you sure you want to mark as deceased?
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.modalRow}>
        <Typography variant="body2">
          This action
          <b> cannot </b>
          be undone
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.modalRow}>
        <Typography variant="body2">
          Marking
          <b>{` ${verifyValue.name} `}</b>
          as deceased will:
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.modalRow}>
        <Typography variant="body2">1) Permanently discontinue all therapies:</Typography>
      </Grid>
      {therapies.some(therapy => `${therapy.administration_status_category_id}` !== '4') ? (
        <div>
          <Grid item xs={12} className={classes.modalRow}>
            <Typography className={classes.patientDischargeText}>
              {therapies
                .filter(therapy => `${therapy.administration_status_category_id}` !== '4')
                .map(therapy => (
                  <span>{`${therapy.drug_name}, `}</span>
                ))}
            </Typography>
          </Grid>
        </div>
      ) : (
        <div>
          <Grid item xs={12} className={classes.modalRow}>
            <Typography className={classes.patientDischargeText}>No active therapies</Typography>
          </Grid>
        </div>
      )}

      <Grid item xs={12} className={classes.modalRow}>
        <Typography variant="body2">
          2) Discharge the patient from the following clinics:
        </Typography>
      </Grid>
      {patient.clinics && patient.clinics.length > 0 ? (
        patient.clinics.map(clinic => (
          <Grid item xs={12} className={classes.modalRow}>
            <Typography className={classes.patientDischargeText}>{clinic.name}</Typography>
          </Grid>
        ))
      ) : (
        <Grid item xs={12} className={classes.modalRow}>
          <Typography className={classes.patientDischargeText}>No active clinics</Typography>
        </Grid>
      )}
      <Grid item xs={12} className={classes.modalRow}>
        <Typography variant="body2">3) And cancel all outstanding tasks</Typography>
      </Grid>
      <Grid item xs={12} className={classes.modalRow}>
        <Grid container justifyContent="flex-start" alignItems="center">
          <TextField
            variant="standard"
            label="Please type patient's name as seen above to confirm"
            value={verifyString}
            onChange={handleVerify}
            className={classes.verifyTypography}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.modalRow}>
        <Grid container justifyContent="flex-end" alignItems="center">
          <div className={classes.verificationBtnRow}>
            <Button
              type="button"
              onClick={handleCancel}
              className={classes.verificationBtn}
              variant="outlined"
            >
              Cancel
            </Button>
          </div>
          <div className={classes.verificationBtnRow}>
            <Button
              type="button"
              disabled={!allowVerify}
              onClick={handlePatientDeceased}
              className={allowVerify ? classes.verificationBtn : classes.verificationBtnDisabled}
              variant="outlined"
            >
              I understand, mark as deceased
            </Button>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default compose(withStyles(styles))(PatientDeceased);

import React from 'react';
import { renderMultipleDropdownAsync as RenderMultipleDropdownAsync } from 'components/form/field/redux-field';
import useProvider from 'hooks/useProvider';

export default ({ providers, field, label, input, meta, disabled, labelSize, qaId }) => {
  const providerObject = useProvider(providers, field.provider);
  const { placeholder } = field;

  return (
    <RenderMultipleDropdownAsync
      label={label}
      input={input}
      meta={meta}
      disabled={disabled}
      labelSize={labelSize}
      id={qaId}
      provider={providerObject}
      placeholder={placeholder}
      isMulti={field.component === 'dropdownMultipleAsync'}
      preventEmpty={field.preventEmptyValue}
    />
  );
};

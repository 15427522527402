import { Moment } from 'moment';

class HospitalizationUtil {
  static calculateLengthOfStay(admissionDate: Moment, dischargedDate: Moment) {
    if (!(dischargedDate && admissionDate)) return 0;

    return Math.max(dischargedDate.diff(admissionDate, 'days'), 0);
  }
}

export { HospitalizationUtil };

import { Grid, Tooltip, Typography } from '@mui/material';
import { useTypedSelector } from 'hooks/use-typed-selector';
import React from 'react';
import { buildQaId } from 'utils/build-qa-id';
import { Email } from '@mui/icons-material';
import { IPreferredContactFilteredEmails } from 'interfaces/redux/IPreferredContact';
import { PreferredContactName } from './preferred-contact-name';
import { useStyles } from './preferred-contact.styles';
import { PreferredContactMethodNote } from './preferred-contact-method-note';

const getQaId = buildQaId('preferred-contact-email');

export const PreferredContactEmail: React.FC = (): JSX.Element | null => {
  const classes = useStyles();

  const patient = useTypedSelector(state => state.patient);
  const preferredContact = useTypedSelector(state => state.preferredContact);

  const contactEmails = React.useMemo<IPreferredContactFilteredEmails[] | undefined>(() => {
    if (preferredContact) {
      return preferredContact.filteredEmails?.length
        ? preferredContact.filteredEmails.filter(x => !x.deleted)
        : undefined;
    }
      return patient.emails?.length ? patient.emails.filter(x => x.value) : undefined;
  }, [preferredContact, patient]);

  const contactEmail = React.useMemo<string | undefined>(() => {
    if (contactEmails && contactEmails.length > 0) {
      return contactEmails[0].value;
    }
    return undefined;
  }, [contactEmails]);

  return patient.preferred_contact_method != null ? (
    <>
      <PreferredContactName />
      <Grid container alignItems="center" data-qa-id={getQaId('wrapper')}>
        <Grid item>
          <Tooltip title="Email">
            <Email data-qa-id={getQaId('email-icon')} className={classes.icon} />
          </Tooltip>
        </Grid>
        <Grid item>
          <Typography display="inline" className={classes.typographyTextSmall}>
            {contactEmail}
          </Typography>
        </Grid>
        <Grid item>
          <PreferredContactMethodNote />
        </Grid>
      </Grid>
    </>
  ) : null;
};

import { Typography, Button, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchTaskCounts } from 'actions/action-tasks';
import { ToggleLeftDrawer } from 'actions/action-view';
import { setSidebarFilter } from 'actions/action-sidebar-filters';
import { CompleteIcon } from 'components/icons/icons';
import { TASK_SIDEBAR } from 'constants/index';
import { ReactComponent as FilterIcon } from 'lib/logos/Filter.svg';
import { styles } from '../side-bar-styles';
import TaskList from './task-list';
import TaskFilterPanel from './task-filter-panel';
import { CollapsibleSideBar } from '../collapsible-side-bar';
import { withCustomer } from '../../../../helpers/router';

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => WrappedComponent => props => <WrappedComponent {...props} width="xs" />;

class TaskSideBar extends Component {
  constructor(props) {
    super(props);
    this.handleToggleFilterPanel = this.handleToggleFilterPanel.bind(this);
    this.handleToggleCompletedFilter = this.handleToggleCompletedFilter.bind(this);
    this.state = {
      displayFilterPanel: false,
    };
  }

  // Initialize redux state from url
  componentDidMount() {
    const { onRequestTasks } = this.props;
    onRequestTasks();
  }

  getTaskLink(taskType) {
    const { selectedTherapyId, selectedTaskId, patient, patientTab } = this.props;
    let taskLink = '/patients';
    if (patient.id) {
      taskLink += `/${patient.id}/${patientTab}`;
    }
    if (selectedTherapyId && selectedTaskId) {
      taskLink += `/${selectedTherapyId}`;
    }
    taskLink += `?sidebar=${TASK_SIDEBAR}&task_type=${taskType}`;
    return withCustomer(taskLink);
  }

  handleToggleFilterPanel() {
    const { displayFilterPanel } = this.state;
    this.setState({
      displayFilterPanel: !displayFilterPanel,
    });
  }

  handleToggleCompletedFilter() {
    const { sidebarFilters, setSidebarFilter } = this.props; //eslint-disable-line
    setSidebarFilter('task', {
      completed: !sidebarFilters.task.completed,
    });
  }

  render() {
    const {
      classes,
      taskCounts,
      sidebarTaskType,
      onToggleDrawer,
      isLeftDrawerOpen,
      sidebarFilters,
      width,
    } = this.props;
    const { displayFilterPanel } = this.state;
    const showCompletedButtonClasses = sidebarFilters.task.completed
      ? classes.completedTaskButtonChecked
      : classes.completedTaskButtonUnchecked;
    const filterButtonClasses = displayFilterPanel
      ? classes.filterButtonChecked
      : classes.filterButtonUnchecked;
    const filterButtonIconClasses = displayFilterPanel
      ? classes.filterButtonIconChecked
      : classes.filterButtonIconUnchecked;
    const sideBarTitle =
      taskCounts && taskCounts[sidebarTaskType] && taskCounts[sidebarTaskType].task_description;
    const drawerClasses = displayFilterPanel
      ? classes.drawerInternalDivContainerFilter
      : classes.drawerInternalDivContainer;
    const drawerInternal = (
      <div className={drawerClasses}>
        <Grid container direction="row">
          <Grid item xs={8}>
            <Typography variant="h6" className={classes.taskTitle}>
              {sideBarTitle}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              id="showCompletedButton"
              name="show_completed_button"
              variant="outlined"
              size="small"
              onClick={this.handleToggleCompletedFilter}
              className={showCompletedButtonClasses}
            >
              <CompleteIcon />
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              id="filterPanelButton"
              name="filter_panel_button"
              variant="outlined"
              size="small"
              onClick={this.handleToggleFilterPanel}
              className={filterButtonClasses}
            >
              <FilterIcon className={filterButtonIconClasses} />
            </Button>
          </Grid>
        </Grid>
        <Switch>
          {displayFilterPanel ? (
            <Route component={TaskFilterPanel} />
          ) : (
            <Route component={TaskList} />
          )}
        </Switch>
      </div>
    );

    return (
      <CollapsibleSideBar
        classes={classes}
        isLeftDrawerOpen={isLeftDrawerOpen}
        width={width}
        onToggleDrawer={onToggleDrawer}
      >
        {drawerInternal}
      </CollapsibleSideBar>
    );
  }
}

function mapStateToProps(state) {
  const { tasklistSort, taskCounts, patient, tabControl, view, sidebarFilters, sidebarTaskType } =
    state;
  const { patientTab } = tabControl;
  return {
    tasklistSort,
    taskCounts: taskCounts.data,
    patient,
    patientTab,
    isLeftDrawerOpen: view.leftDrawer,
    sidebarFilters: sidebarFilters.data,
    sidebarTaskType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSelectTask: taskType => {
      dispatch(new ToggleLeftDrawer(true));
      // The task used to get selected in the task table
      // when the user selects from the task sidebar, but
      // this got unhooked at some point.
    },
    setSidebarFilter: (type, props) => dispatch(setSidebarFilter(type, props)),
    onRequestTasks: () => dispatch(fetchTaskCounts()),
    onToggleDrawer: state => dispatch(new ToggleLeftDrawer(state)),
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withWidth(),
)(TaskSideBar);

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, CircularProgress } from '@mui/material';
import { ITask } from 'interfaces/redux/ITasks';
import { convertToArborDate } from 'models/time/arbor-date';
import { fetchParentPaTask } from 'services/utils/task-service';
import { AxiosResponse } from 'axios';
import { styles } from './styles';

const useStyles = makeStyles(styles);
interface ITaskExpirationProps {
  task: ITask;
}
export function TaskExpirationDate(props: ITaskExpirationProps) {
  const classes = useStyles(styles);
  const [expirationDate, setExpirationDate] = useState<string | undefined>('');
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (props.task.taskType !== 'PA') {
      return;
    }

    setLoading(true);
    fetchParentPaTask(props.task.id).then((result: AxiosResponse<ITask[]>) => {
      const [task] = result.data;
      setExpirationDate(task?.expiration_date);
      setLoading(false);
    });
  }, [props.task.id, props.task.taskType]);
  if (loading) {
    return <CircularProgress size={13} />;
  }

  if (!expirationDate) {
    return <></>;
  }

  return (
    <>
      <Typography gutterBottom className={classes.expirationDateLabel}>
        Current Expiration Date
      </Typography>
      <Typography>{convertToArborDate(expirationDate).getUtcDate(true, 'M/D/YYYY')}</Typography>
    </>
  );
}

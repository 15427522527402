import React from 'react';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { DeepPartial } from 'utils/types-util';

import { PatientSearchService } from 'services/utils/patient-search-service';
import { useDispatch } from 'react-redux';
import { PATIENTS_ADVANCED_SEARCH_REQUEST_SUCCESS } from 'constants/index';
import { notifyError } from 'actions/action-notifications';
import { clearWorkList } from 'actions/action-patient';
import { IWrapperProps, IFormFields } from './interfaces';
import { styles } from './advanced-search.styles';
import { AdvancedSearchForm } from './form';
import { AdvancedSearchResultList } from './result-list';
import { parseToServiceRequestBody } from './utils';

import { logger } from '../../winston-logger';

const AdvancedSearch: React.FC<IWrapperProps> = (props: IWrapperProps) => {
  const { classes } = props;

  const dispatch = useDispatch();

  const [currentSearchCriteria, setCurrentSearchCriteria] = React.useState<
    DeepPartial<IFormFields>
  >({});

  const submitSearch = async (searchCriteria: DeepPartial<IFormFields>): Promise<void> => {
    try {
      if (!Object.keys(searchCriteria).length) {
        // If no filters just clean the results list
        dispatch(clearWorkList());
      } else {
        const searchResult = await PatientSearchService.advanced(
          parseToServiceRequestBody(searchCriteria),
        );

        dispatch({ type: PATIENTS_ADVANCED_SEARCH_REQUEST_SUCCESS, data: searchResult });
        setCurrentSearchCriteria(searchCriteria);
      }
    } catch (error) {
      dispatch(notifyError('Advanced search failed'));
      logger.error(error);
    }
  };

  return (
    <Grid container className={classes.formContainer}>
      <Grid item xs={12} className={classes.formWrapper}>
        <AdvancedSearchForm debounceTime={props.debounceTime} submit={submitSearch} />
      </Grid>
      <Grid item xs={12}>
        <AdvancedSearchResultList searchCriteria={currentSearchCriteria} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(AdvancedSearch);

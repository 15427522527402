import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { styles } from './search-field.styles';

interface ISearchFieldProps {
  placeholder: string;
  value: string;
  initialValue: string;
  onChange: (value: string) => void;
  width: number;
}

export const SearchField: React.FC<ISearchFieldProps> = (props: ISearchFieldProps) => {
  const classes = styles(props.width)();

  return (
    <TextField
      variant="standard"
      placeholder={props.placeholder}
      onChange={event => {
        props.onChange(event.target.value);
      }}
      value={props.value}
      className={classes.searchField}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon className={classes.searchIcon} />
          </InputAdornment>
        ),
        endAdornment:
          props.value != null && props.value !== props.initialValue ? (
            <InputAdornment
              position="end"
              onClick={() => {
                props.onChange(props.initialValue);
              }}
              className={classes.textFieldEndAdornment}
            >
              <CloseIcon fontSize="small" />
            </InputAdornment>
          ) : null,
      }}
    />
  );
};

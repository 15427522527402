import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { archiveNote } from 'actions/action-notes';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { Field, reduxForm } from 'redux-form';
import { ARCHIVE_NOTE_FORM } from 'constants/index';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { renderTextField } from 'components/form/field/redux-field';
import { required } from 'components/form/validation/validation';
import { styles } from './note-styles';

class NoteArchiveForm extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    const { reset, archiveNote, note, cancel } = this.props; // eslint-disable-line
    const payload = {
      ...values,
      id: note.id,
    };
    archiveNote(payload);
    cancel();
    reset();
  }

  render() {
    const { handleSubmit, classes, pristine, submitting, cancel } = this.props;
    return (
      <Grid item xs={12}>
        <form onSubmit={handleSubmit(this.handleSubmit)} autoComplete="off">
          <Grid container spacer={24}>
            <Grid item xs={12} className={classes.noteFieldContainer}>
              <Field
                name="archived_reason"
                label="Archived Reason"
                component={renderTextField}
                multiline
                rows={3}
                validate={[required]}
              />
            </Grid>
            <Grid item xs={12} className={classes.confirmationPanelContainer}>
              <ConfirmationPanel
                cancelButtonName="archive_note_reason_cancel_button"
                submitButtonName="archive_note_reason_button"
                handleCancel={cancel}
                disableSubmit={pristine || submitting}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const { lookups } = state;
  return {
    lookups,
    users: lookups.users,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    archiveNote,
  }),
)(reduxForm({ form: ARCHIVE_NOTE_FORM })(NoteArchiveForm));

import React, { FC, useState } from 'react';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';

import { renderReactRadioGroup as RenderRadioGroup } from 'components/form/field/redux-field';
import { renderDatePicker as RenderDatePicker } from 'components/form/datepicker/datetime-picker';

import SmallTaskCard from 'components/card/small-task-card';

import { convertToArborDate } from 'models/time/arbor-date';
import { differenceWith } from 'lodash';
import ItemsCarousel from 'components/items-carousel';
import { IProps } from './interfaces/IProps';
import { styles } from './horizontal-task-list.styles';
import { IShortenTask } from './interfaces/IShortenTask';

type InputMetadataError = boolean | string;
type InputMetadata = {
  touched: boolean;
  error: InputMetadataError;
  warning: boolean;
};

const getDefaultDateFiltersFromDate = (fromDate: Date) => {
  return [
    {
      label: 'Next Month',
      startDate: moment(fromDate).toDate(),
      endDate: moment(fromDate).add(1, 'months').toDate(),
    },
    {
      label: 'Next 6 Months',
      startDate: moment(fromDate).toDate(),
      endDate: moment(fromDate).add(6, 'months').toDate(),
    },
    {
      label: 'Next 12 Months',
      startDate: moment(fromDate).toDate(),
      endDate: moment(fromDate).add(12, 'months').toDate(),
    },
  ];
};

const HorizontalTaskList: FC<IProps> = (props: IProps): JSX.Element => {
  const {
    classes,
    tasks,
    numberOfCards,
    showDateFilters = true,
    dateFilters = getDefaultDateFiltersFromDate(props.fromDate || new Date()),
    fromDate = new Date(),
  } = props;

  const initialMeta: InputMetadata = {
    touched: true,
    error: false,
    warning: false,
  };

  const [startDateValue, setStartDateValue] = useState<Date | null>(fromDate);
  const [startDateMeta, setStartDateMeta] = useState(initialMeta);
  const [endDateValue, setEndDateValue] = useState<Date | null>(null);
  const [endDateMeta, setEndDateMeta] = useState(initialMeta);
  const [selectedDateFilterIndex, setSelectedDateFilterIndex] = useState<number | null>(null);
  const [taskList, setTaskList] = useState<IShortenTask[]>(tasks ?? []);

  React.useEffect(() => {
    const nextTaskList = filterAndSortTasks(tasks);
    const diff = tasksDiff(taskList, nextTaskList);
    if (diff?.length) {
      const [addedTask] = diff;
      addedTask.highlighted = true;
      const newTaskList = taskList.slice();
      newTaskList.splice(0, 0, addedTask);
      setTaskList(newTaskList);
    }
  }, [tasks]);

  React.useEffect(() => {
    setTaskList(filterAndSortTasks(tasks));
  }, [endDateValue, startDateValue]);

  const getDateOnly = (date: Date | null): moment.Moment | undefined | null =>
    date && moment(convertToArborDate(moment(date)).getUtcDate());

  const filterAndSortTasks = (tasks: IShortenTask[]): IShortenTask[] => {
    return (
      tasks
        .filter(task => {
          const filterStartDate = getDateOnly(startDateValue);
          const filterEndDate = getDateOnly(endDateValue);
          const taskDate = getDateOnly(task.nextDate);

          // Is showDateFilters active and start date is not empty we should apply filtering by dates
          const shouldApplyFilters = Boolean(showDateFilters && filterStartDate);

          // Not filtering any tasks by date
          if (!shouldApplyFilters) {
            return true;
          }
          // If we should apply filters but there's no end date selected just get all task with nextDate same or after filterStartDate
          if (!filterEndDate) {
            return taskDate?.isSameOrAfter(filterStartDate);
          }
          // If end date is selected filter between both dates

          return taskDate?.isBetween(filterStartDate, filterEndDate, undefined, '[]');
        })
        // Sorting task by oldest task first
        .sort((firstTask, secondTask) => {
          return (
            new Date(firstTask.nextDate ?? '')?.getTime() -
            new Date(secondTask.nextDate ?? '')?.getTime()
          );
        })
    );
  };

  const tasksDiff = (
    previous: IShortenTask[],
    next: IShortenTask[],
  ): IShortenTask[] | undefined => {
    if (next.length > previous.length) {
      return differenceWith(
        next,
        previous,
        (firstTask, secondTask) =>
          firstTask.id === secondTask.id && firstTask.type === secondTask.type,
      );
    }

    return [];
  };

  const isStartDateValid = (startDate: Date, endDate: Date) => {
    const filterStartDate = getDateOnly(startDate);
    const filterEndDate = getDateOnly(endDate);

    if (filterEndDate && filterStartDate?.isAfter(filterEndDate)) {
      setStartDateMeta({ ...startDateMeta, error: "Start date can't be after end date" });
      return false;
    }
    setStartDateMeta({ ...startDateMeta, error: false });

    if (!startDate) {
      setStartDateMeta({ ...startDateMeta, error: 'Start date is required' });
    } else {
      setStartDateMeta({ ...startDateMeta, error: false });
    }

    return true;
  };

  const isEndDateValid = (endDate: Date, startDate: Date) => {
    const filterStartDate = getDateOnly(startDate);
    const filterEndDate = getDateOnly(endDate);

    if (filterEndDate && (filterEndDate as moment.Moment).isBefore(filterStartDate)) {
      setEndDateMeta({ ...endDateMeta, error: "End date can't be before start date" });
      return false;
    }
    setEndDateMeta({ ...endDateMeta, error: false });

    return true;
  };

  const onChangeDateFilter = (value: number) => {
    setSelectedDateFilterIndex(value);
    const selectedFilter = dateFilters[value];
    setStartDateValue(selectedFilter.startDate);
    setEndDateValue(selectedFilter.endDate);
    isStartDateValid(selectedFilter.startDate, selectedFilter.endDate);
    isEndDateValid(selectedFilter.endDate, selectedFilter.startDate);
  };

  const reinitializeRadioGroup = () => {
    setSelectedDateFilterIndex(null);
  };

  const onChangeStartDate = (value: any) => {
    if (isStartDateValid(value, endDateValue!)) {
      setStartDateValue(value);
    }
    reinitializeRadioGroup();
  };

  const onChangeEndDate = (value: any) => {
    if (isEndDateValid(value, startDateValue as Date)) {
      setEndDateValue(value);
    }
    reinitializeRadioGroup();
  };

  return (
    <Grid container className={classes.root}>
      {showDateFilters && (
        <>
          <Grid item xs={7} className={classes.dateRangeGroup}>
            <RenderRadioGroup
              input={{
                value: selectedDateFilterIndex,
                onChange: onChangeDateFilter,
              }}
              minItemWidth={120}
              maxItemWidth={120}
              radioMap={dateFilters.map((filterOption, index) => ({
                label: filterOption.label,
                value: index,
              }))}
              meta={initialMeta}
              wrap={120}
              width="auto"
            />
          </Grid>

          <Grid item xs={5}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <RenderDatePicker
                  label="From"
                  input={{
                    value: startDateValue,
                    onChange: onChangeStartDate,
                  }}
                  name="horizontal-tab-start-date"
                  id="horizontal-tab-start-date"
                  meta={startDateMeta}
                />
              </Grid>
              <Grid item xs={6}>
                <RenderDatePicker
                  label="To"
                  input={{
                    value: endDateValue,
                    onChange: onChangeEndDate,
                  }}
                  name="horizontal-tab-end-date"
                  id="horizontal-tab-end-date"
                  meta={endDateMeta}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <ItemsCarousel
          spaceBetween={5}
          numberOfItems={numberOfCards ?? 5}
          items={taskList.map(task => (
            <SmallTaskCard
              id={task.id}
              title={`${task?.type?.toUpperCase() ?? ''} - ${task?.therapyDrugName ?? ''}`}
              status={task.status || 'Unknown Status'}
              dateLabel={task.nextDateDescription}
              date={
                convertToArborDate(task.nextDate.toISOString(), true).getUtcDate(true, 'M/D/YY') ??
                '-'
              }
              highlighted={task.highlighted}
            />
          ))}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(HorizontalTaskList);

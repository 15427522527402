import { Theme, createStyles } from '@mui/material';
import { expandTheme } from 'utils/theme-util';

export const styles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    addModal: {
      position: 'absolute',
      width: theme.spacing(100),
      backgroundColor: palette.primary.background,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
      borderRadius: 5,
    },
    heading: {
      fontWeight: 'bold',
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      marginBottom: theme.spacing(2),
    },
  });
};

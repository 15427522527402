import React, { Fragment, useState, useRef, useEffect, PropsWithChildren } from 'react';

import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Typography,
  TableBody,
  IconButton,
  Button,
} from '@mui/material';
import { Printer } from 'components/icons/icons';

import composeClassNames from 'classnames';

import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';

import { ConditionalWrapper } from 'utils/conditional-wrapper';
import { MedicationListColumns } from 'containers/patient/clinical/medication-list/gpi-medication-group/types';
import { IProps, IRow, IColumn, IGroup, CellAlign, TriStateSortOrder } from './types';
import { useTypedStyles } from './medication-list-group-table.styles';

export function GroupTable(props: IProps): JSX.Element {
  const { classes } = useTypedStyles();

  const medicationListRef = useRef(null);

  // #region component state
  const [groupsExpandState, setGroupExpandState] = useState<Record<number, boolean>>(
    props.groups.reduce((acc, g) => ({ [g.parent.identifier]: false, ...acc }), {}),
  );

  const [sortOrder, setSortOrder] = useState<TriStateSortOrder>(TriStateSortOrder.DispenseStatus);
  const [sortByAttr, setSortByAttr] = useState<MedicationListColumns | null>(
    props.defaultSortedBy || null,
  );
  // #endregion component state

  // #region handlers
  const handleToggleGroupByIndex = (identifier: number) => (): void => {
    const groupsExpandStateCopy = { ...groupsExpandState };
    groupsExpandStateCopy[identifier] = !groupsExpandStateCopy[identifier];
    setGroupExpandState(groupsExpandStateCopy);
    if (props.expandHandler) {
      const foundGroup =
        props.groups.find(
          ({ parent: { identifier: parentId } }) => identifier === Number(parentId),
        ) || null;
      props.expandHandler(foundGroup, groupsExpandStateCopy[identifier]);
    }
  };

  const handleSortBy = (column: MedicationListColumns): void => {
    let newSortOrder = TriStateSortOrder.Asc;
    if (sortByAttr === column) {
      if (column === 'drug_name') {
        if (sortOrder === TriStateSortOrder.Asc) {
          newSortOrder = TriStateSortOrder.Desc;
        } else if (sortOrder === TriStateSortOrder.Desc) {
          newSortOrder = TriStateSortOrder.DispenseStatus;
        }
      } else {
        newSortOrder =
          sortOrder === TriStateSortOrder.Asc ? TriStateSortOrder.Desc : TriStateSortOrder.Asc;
      }
    } else {
      setSortByAttr(column);
    }
    setSortOrder(newSortOrder);
  };

  // #endregion hadlers

  // #region side effects
  useEffect(() => {
    if (sortByAttr) {
      props.sortHandler?.(sortByAttr, sortOrder);
    }
  }, [sortByAttr, sortOrder]);

  useEffect(() => {
    handleSortBy('drug_name');
  }, []);
  // #endregion side effects

  // #region rendering helpers
  const renderDefaultCell = (
    props: PropsWithChildren<{
      identifier: number;
      childrenIdentifiers?: number[];
      isParent?: boolean;
    }>,
  ) => {
    return <Typography>{props.children}</Typography>;
  };

  const renderHeaders = (columns: IColumn[]): JSX.Element => {
    return (
      <TableHead>
        <TableRow>
          {props.hideChildren === true ? null : <TableCell className={classes.leftCell} />}
          {columns.map((column, colIndex) => {
            const HeaderCellComponent =
              column.customComponents?.headerCell ||
              props.customComponents?.headerCell ||
              Typography;
            return (
              <TableCell
                key={`header-${column.columnName}`}
                align={colIndex === 0 ? CellAlign.Left : CellAlign.Center}
              >
                <ConditionalWrapper
                  condition={Boolean(column.sortable) === true}
                  wrapper={child => (
                    <TableSortLabel
                      className={
                        sortOrder === TriStateSortOrder.DispenseStatus ? classes.hideSortArrow : ''
                      }
                      active={sortByAttr === column.columnName}
                      // direction must be 'desc' or 'asc'. when the sort order is dispense_status it
                      // is artificially set to 'desc' and the direction indicator is hidden by styling
                      direction={
                        sortOrder === TriStateSortOrder.DispenseStatus
                          ? TriStateSortOrder.Desc
                          : sortOrder
                      }
                      onClick={() => handleSortBy(column.columnName)}
                    >
                      {child}
                    </TableSortLabel>
                  )}
                >
                  <HeaderCellComponent>{column.displayName}</HeaderCellComponent>
                </ConditionalWrapper>
              </TableCell>
            );
          })}
          {(props.customComponents?.parentAction || props.customComponents?.childAction) && (
            <TableCell>
              <Button
                onClick={() => {
                  if (props.onPrintClickHandler) {
                    props.onPrintClickHandler();
                  }
                }}
              >
                <Printer />
              </Button>
            </TableCell>
          )}
        </TableRow>
      </TableHead>
    );
  };

  const renderParentRow = (columns: IColumn[], parent: IRow, children: IRow[]): JSX.Element => {
    return (
      <TableRow
        className={groupsExpandState[parent.identifier] === true ? classes.mainExpandedRow : ''}
      >
        {props.hideChildren === true ? null : (
          <TableCell className={classes.leftCell}>
            {children.length > 0 && (
              <IconButton size="small" onClick={handleToggleGroupByIndex(parent.identifier)}>
                {groupsExpandState[parent.identifier] === true ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </IconButton>
            )}
          </TableCell>
        )}
        {columns.map((column, colIndex) => {
          const RowCellComponent =
            columns[colIndex].customComponents?.parentRowCell ||
            props.customComponents?.parentRowCell ||
            renderDefaultCell;
          return (
            <TableCell
              align={colIndex === 0 ? CellAlign.Left : CellAlign.Center}
              key={`parent-${colIndex}`}
              className={classes.cell}
            >
              <RowCellComponent
                identifier={parent.identifier}
                childrenIdentifiers={children.map(child => child.identifier)}
                isParent
              >
                {parent.rowData[column.columnName]}
              </RowCellComponent>
            </TableCell>
          );
        })}
        {props.customComponents?.parentAction && (
          <TableCell>
            <props.customComponents.parentAction identifier={parent.identifier} />
          </TableCell>
        )}
        {!props.customComponents?.parentAction && props.customComponents?.childAction && (
          <TableCell />
        )}
      </TableRow>
    );
  };

  const renderChildrenRows = (columns: IColumn[], parent: IRow, childrens: IRow[]): JSX.Element => {
    return groupsExpandState[parent.identifier] === true ? (
      <>
        {childrens.map(children => {
          return (
            <TableRow key={`child-row-${children.identifier}`} className={classes.noBorderRow}>
              <TableCell className={composeClassNames(classes.leftCell)} />
              {columns.map((column, colIndex) => {
                const RowCellComponent =
                  columns[colIndex].customComponents?.childRowCell ||
                  props.customComponents?.childRowCell ||
                  renderDefaultCell;
                return (
                  <TableCell
                    key={`child-cell-${colIndex}`}
                    align={colIndex === 0 ? CellAlign.Left : CellAlign.Center}
                    className={composeClassNames(classes.cell)}
                  >
                    <RowCellComponent
                      identifier={children.identifier}
                      childrenIdentifiers={[]}
                      isParent={false}
                    >
                      {children.rowData[column.columnName]}
                    </RowCellComponent>
                  </TableCell>
                );
              })}
              {props.customComponents?.childAction && (
                <TableCell>
                  <props.customComponents.childAction identifier={children.identifier} />
                </TableCell>
              )}
              {!props.customComponents?.childAction && props.customComponents?.parentAction && (
                <TableCell />
              )}
            </TableRow>
          );
        })}
      </>
    ) : (
      <></>
    );
  };

  const renderBody = (groups?: IGroup[]): JSX.Element => {
    return (
      <TableBody>
        {(groups || []).map((group, groupIndex) => {
          return (
            <Fragment key={`group-${groupIndex}`}>
              {renderParentRow(props.columns, group.parent, group.children)}
              {props.hideChildren === true
                ? null
                : renderChildrenRows(props.columns, group.parent, group.children)}
            </Fragment>
          );
        })}
      </TableBody>
    );
  };
  // #endregion rendering helpers

  // #region main rendering
  return (
    <Grid container>
      <Grid item xs={12}>
        <Table size={props.size} className={classes.table} ref={medicationListRef}>
          {renderHeaders(props.columns)}
          {renderBody(props.groups)}
        </Table>
      </Grid>
    </Grid>
  );
  // #endregion main rendering
}

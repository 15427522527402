import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { FormatItalic } from '@mui/icons-material';
import { expandTheme } from 'utils/theme-util';
import colors from 'lib/themes/colors.module.scss';

export const ShippingStyles = (theme: Theme) => {
  const { font, palette } = expandTheme(theme);
  return createStyles({
    'icon': {
      height: theme.spacing(2.5),
    },
    'container': {
      marginLeft: theme.spacing(5),
      marginTop: theme.spacing(5),
    },
    'title': {
      fontSize: font.extraLargeFontSize,
      fontWeight: 'bold',
      marginBottom: theme.spacing(5),
    },
    'searchIcon': {
      color: theme.palette.grey[500],
      marginRight: theme.spacing(1),
    },
    'contentsOfPackage': {
      marginTop: theme.spacing(1),
    },
    'contentsOfPackagePanel': {
      backgroundColor: palette.primary.summaryPanel,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      maxWidth: '98%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    'contentsOfPackageTitle': {
      fontSize: font.extraLargeFontSize,
      fontWeight: 'bold',
      paddingLeft: theme.spacing(2),
    },
    'shippingVerificationPanel': {
      backgroundColor: palette.primary.summaryPanel,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      maxWidth: '98%',
      fontSize: '16px',
      lineHeight: '32px',
      fontWeight: 'normal',
    },
    'shippingVerificationTitle': {
      fontSize: font.extraLargeFontSize,
      fontWeight: 'bold',
      paddingLeft: theme.spacing(2),
    },
    'shippingVerificationSection': {
      marginTop: theme.spacing(1),
    },
    'shippingVerificationScanField': {
      display: 'block',
      color: theme.palette.grey[500],
    },
    'shippingVerificationLabelValue': {
      fontSize: '16px',
      lineHeight: '32px',
      color: palette.primary.steelGrey,
    },
    'shippingVerificationLabelInvalid': {
      color: palette.primary.lightRed,
    },
    'subsection': {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    'subsectionTitles': {
      fontSize: font.largeFontSize,
      fontWeight: 'bold',
      paddingLeft: theme.spacing(2),
    },
    'subsectionItems': {
      paddingTop: theme.spacing(2.5),
      paddingLeft: theme.spacing(3),
    },
    'subsectionItemText': {
      paddingBottom: theme.spacing(0.5),
    },
    'nullText': {
      paddingTop: theme.spacing(2.5),
      paddingLeft: theme.spacing(3),
    },
    'deliveryInformationPanel': {
      backgroundColor: palette.primary.summaryPanel,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      maxWidth: '98%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    'deliveryInformationTitle': {
      fontSize: font.extraLargeFontSize,
      fontWeight: 'bold',
      paddingLeft: theme.spacing(2),
    },
    'deliveryInformationMethod': {
      alignItems: 'center',
    },
    'deliveryInformationLogo': {
      paddingRight: theme.spacing(1),
    },
    'detailHeader': {
      color: theme.palette.grey[500],
    },
    'deliveryInformation': {
      marginTop: theme.spacing(3),
    },
    'sectionBottomMargin': {
      marginBottom: theme.spacing(3),
    },
    'reprintLabelText': {
      paddingLeft: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    'reprintGrid': {
      paddingTop: theme.spacing(3),
      maxWidth: '98%',
    },
    'printLabelButton': {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
      width: theme.spacing(20),
    },
    'voidLabelButton': {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
      width: theme.spacing(20),
    },
    'trackingNumber': {
      backgroundColor: palette.primary.summaryPanel,
      padding: theme.spacing(2),
    },
    'trackingNumberTitle': {
      paddingBottom: theme.spacing(1),
      fontWeight: 'bold',
    },
    'signatureImage': {
      width: '90%',
      height: '100%',
    },
    'signatureNotRequired': {
      fontStyle: 'italic',
    },
    'rePrintLabel': {
      marginTop: theme.spacing(1),
    },
    'paymentInformation': {
      marginTop: theme.spacing(1),
    },
    'paymentMethodOnFile': {
      textDecoration: 'line-through',
    },
    'radioGroupMoreButton': {
      padding: '2px 0px 2px 1px',
    },
    'shippingButton': {
      marginLeft: theme.spacing(1),
    },
    '@keyframes pulse': {
      '0%': {
        opacity: 0.22,
        transform: 'scale(1)',
      },
      '25%': {
        opacity: 0.15,
        transform: 'scale(1)',
      },
      '68%': {
        opacity: 1,
        transform: 'scale(1.06)',
      },
      '100%': {
        opacity: 0.22,
        transform: 'scale(1)',
      },
    },
    '@keyframes scanFlash': {
      '0%': {
        opacity: 0,
      },
      '25%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0,
      },
    },
    'scanInputFlash': {
      '& input[type="text"]': {
        animation: `$scanFlash 500ms ${theme.transitions.easing.easeInOut}`,
      },
    },
    'scanIcon': {
      animation: '$pulse 1000ms infinite ease-in-out',
    },
    'noteWrapper': {
      marginTop: 30,
    },
    'noteTextArea': {
      maxWidth: 800,
    },
    'saveNoteButton': {
      'color': colors.steelGrey,
      'border': `1px solid ${colors.grey23}`,
      '&:hover': {
        border: `1px solid ${colors.steelGrey}`,
      },
    },
  });
};

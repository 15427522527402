import React from 'react';
import { useStyles } from './index.styles';

const Footer = () => {
  const { footer, link } = useStyles();
  return (
    <div className={footer}>
      <a
        className={link}
        href="https://cps.com/online-services-privacy-policy"
        rel="noreferrer"
        target="_blank"
      >
        Privacy
      </a>
      <a className={link} href="https://cps.com/terms-of-use" rel="noreferrer" target="_blank">
        Terms of Use
      </a>
    </div>
  );
};

export default Footer;

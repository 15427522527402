import React from 'react';
import cx from 'classnames';
import WidthController from '../width-controller';

const BlueContainer = ({ children, classes }) => {
  return (
    <div className={cx(classes.container, classes.bluePage)}>
      <WidthController className={classes.subContainer}>{children}</WidthController>
    </div>
  );
};

export default BlueContainer;

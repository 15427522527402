import { useSelector } from 'react-redux';
import { CATEGORY_DONE, CATEGORY_ARCHIVED } from 'constants/index';
import { IState } from 'interfaces/redux/IState';
import { ITask } from 'interfaces/redux/ITasks';
import { TaskType } from 'interfaces/TaskType';
import { TaskStatus } from 'interfaces/enums/TaskStatus';
import { isTaskCompleted } from 'services/utils/task-service';

import { IShortenTask } from 'components/horizontal-task-list';
import { StatusTaskType } from 'interfaces/StatusTaskType';

const usePatientShortenTasks = (): IShortenTask[] => {
  const therapies = useSelector((state: IState) => state.therapies.data);
  const tasks = useSelector((state: IState) => state.tasks.data);
  const fillCycles = useSelector((state: IState) => state.fillCycles);
  const taskStatuses = useSelector((state: IState) => state.taskStatuses.statuses);

  const getNeedsByDateByTask = (task: ITask): string | undefined =>
    fillCycles?.[task.therapy_id]?.[task.fill_cycle_number!]?.needsby_date;

  return (
    Object.values(tasks)
      // Filtering completed tasks
      .filter((task: ITask) => !isTaskCompleted(task))
      .map((task: ITask) => {
        const needsByDate = getNeedsByDateByTask(task);
        const followUpDate = task.followup_dt;
        let dateDescription = 'Unknown Next Date';

        if (needsByDate) {
          dateDescription = 'Needs By Date';
        } else if (followUpDate) {
          dateDescription = 'Follow Up Date';
        }

        const nextDate = needsByDate
          ? new Date(needsByDate)
          : (followUpDate && new Date(followUpDate)) || new Date();

        return {
          id: task.id,
          therapyDrugName: therapies[task.therapy_id]?.drug_name ?? '',
          type: task.taskType as TaskType,
          status: taskStatuses?.[task.taskType?.toLowerCase() as StatusTaskType]?.find(
            statuses => statuses.id === task.status_id,
          )?.status as TaskStatus,
          nextDateDescription: dateDescription,
          nextDate: nextDate,
        };
      })
  );
};

export { usePatientShortenTasks };

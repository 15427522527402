import React from 'react';
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { HospitalizationUtil } from 'utils/hospitalization-util';

const styles = theme => ({
  lengthOfStayContainer: {
    backgroundColor: theme.palette.primary.cornflowerblue,
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(0.75),
    marginBottom: theme.spacing(3.5),
    padding: theme.spacing(0, 2.75),
    minHeight: 62,
  },
  lengthOfStayTxt: {
    fontWeight: 600,
    color: '#1a1c1e',
  },
  lengthOfStayInnerTxt: {
    marginLeft: theme.spacing(2.75),
  },
});

const LengthOfStay = props => {
  const { classes, providers, xs = 12 } = props;
  let { dischargedDate, admissionDate } = props;

  const formValues = providers?.formValues;
  if (!formValues && !(dischargedDate && admissionDate)) return <></>;

  if (formValues) {
    // eslint-disable-next-line dot-notation
    dischargedDate = formValues?.['discharged_date'];
    // eslint-disable-next-line dot-notation
    admissionDate = formValues?.['admission_date'];
  }

  const lengthOfStay = HospitalizationUtil.calculateLengthOfStay(admissionDate, dischargedDate);

  if (lengthOfStay === 0) return <></>;

  return (
    <Grid item xs={xs} className={classes.lengthOfStayContainer}>
      <Typography className={classes.lengthOfStayTxt}>
        <span>LOS:</span>
        <span data-qa-id="length-of-stay" className={classes.lengthOfStayInnerTxt}>
          {`${lengthOfStay} ${lengthOfStay > 1 ? 'days' : 'day'}`}
        </span>
      </Typography>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(LengthOfStay);

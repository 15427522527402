import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HTTP from 'services/http';
import React from 'react';
import { ArborCheckbox } from 'components/arbor-checkbox/arbor-checkbox';
import { ArborSwitch } from 'components/arbor-switch/arbor-switch';
import { AxiosPromise } from 'axios';
import { BaseTable } from 'containers/application-manager/base-table/base-table';
import { getModalStyle } from 'services/utils/styles-service';
import { IndexType } from 'interfaces/IndexType';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { useDispatch } from 'react-redux';
import { UserPermissions } from 'interfaces/user/Permissions';
import { UserUtils } from 'utils/user-utils';
import { useTypedSelector } from 'hooks/use-typed-selector';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Modal,
  Button,
  CircularProgress,
  Popover,
} from '@mui/material';
import { buildQaId } from 'utils/build-qa-id';
import { FieldTypes } from 'containers/application-manager/types';
import { FormField } from 'containers/application-manager/popover-field-forms';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import {
  IPopoverStateBase,
  IIncludeExcludeApiResponse,
  IChild,
  IResult,
  IModalItem,
  IComponentStateItem,
  ITableProps,
  IModalState,
  ITherapeuticClassModalItem,
} from '../../types';
import { dateConvert, utcMomentOrUndefined, booleanConvert } from '../../../utils';

import { UseStyles } from '../table.styles';
import { toggleModalChecked, getDataAndUpdateState } from '../../utils';
import { TherapeuticModal } from '../therapeutic-class-modal/therapeutic-class-modal';

import { COLUMN_SETTINGS, PAGINATION_PARAMS } from '../../constants';
import { logger } from '../../../../../winston-logger';

const childPkSelector = (child: IChild) => child.drug_descriptor_id;
const parentPkSelector = (parent: IResult) => parent.drug_descriptor_id;

const baseUrl = '/application-manager/ctd';
const getUrl = `${baseUrl}/update-drugs`;

const initialIncludeModalState: IModalState = {
  open: false,
  submitting: false,
};

const getQaId = buildQaId('application-manager.ctd.manage.included', '.');

type IPopoverState = IPopoverStateBase<IResult, IChild>;

const initialPopoverState: IPopoverState = {
  open: false,
  field: 'id',
};

export type ComponentState = IComponentStateItem[];

export const IncludedTableManage: React.FC<ITableProps> = (props): JSX.Element => {
  const classes: any = UseStyles();
  const dispatch = useDispatch();
  // #region Redux
  const therapeuticClasses = useTypedSelector(state => state.lookups.trellisTherapeuticsClasses);
  const customers = useTypedSelector(state => state.filters.customers);
  // #endregion

  // #region useState
  const [popoverState, setPopoverState] = React.useState<IPopoverState>(initialPopoverState);
  const [tableState, setTableState] = React.useState<ComponentState>([]);
  const [includeModalState, setIncludeModalState] =
    React.useState<IModalState>(initialIncludeModalState);
  const [therapeuticClassModalState, settherapeuticClassModalState] = React.useState<
    ITherapeuticClassModalItem | undefined
  >(undefined);
  const [triggerReload, setTriggerReload] = React.useState<Date | undefined>(undefined);
  // #endregion

  // #region memos
  const allCustomers = React.useMemo(() => {
    return customers.map(c => ({
      label: c.name,
      value: c.id,
    }));
  }, [customers]);

  const currentCustomer = React.useMemo(() => {
    return allCustomers.find(c => c.value === props.selectedCustomerId);
  }, [props.selectedCustomerId, allCustomers]);

  const userHasPermissions = React.useMemo<boolean>(() => {
    if (currentCustomer) {
      const isPermitted = UserUtils.userIsPermitted(
        UserPermissions.ApplicationManagerCtdEdit,
        currentCustomer.value,
      );

      return isPermitted;
    }
    return false;
  }, [currentCustomer]);
  // #endregion

  // #region use effect method
  React.useEffect(() => {
    let therapeuticClassUrlBuilder = `application-manager/ctd/included/therapeutic-classes/${props.selectedCustomerId}`;
    const therapeuticClassDataUrlBUilder = (therapeuticClassId: number) =>
      `application-manager/ctd/included/${props.selectedCustomerId}/${therapeuticClassId}`;

    if (props.searchTerm) {
      therapeuticClassUrlBuilder += `?searchTerm=${props.searchTerm}`;
    }
    (async () => {
      await getDataAndUpdateState(
        therapeuticClasses,
        therapeuticClassUrlBuilder,
        therapeuticClassDataUrlBUilder,
        setTableState,
        true,
        false,
      );
    })();
  }, [props.selectedCustomerId, props.searchTerm, therapeuticClasses]);

  type ChildWithParentDdi = IChild & {
    parent_drug_descriptor_id: number;
  };
  // #endregion

  // #region helpers
  const getCheckedChildren = (): ChildWithParentDdi[] => {
    const checkedChildren: ChildWithParentDdi[] = [];

    tableState.forEach(stateItem => {
      if (Object.keys(stateItem.checkedItems)) {
        Object.keys(stateItem.checkedItems).forEach(key => {
          const keyNum = Number(key);
          const parent = stateItem.tableData?.results.find(x => x.drug_descriptor_id === keyNum);

          if (parent) {
            const childDdis = stateItem.checkedItems[key];

            const children = parent.children.filter(x => childDdis.includes(x.drug_descriptor_id));

            const childrenWithParentDdi = children.map(child => {
              return {
                ...child,
                parent_drug_descriptor_id: keyNum,
              };
            });

            checkedChildren.push(...childrenWithParentDdi);
          }
        });
      }
    });

    return checkedChildren;
  };

  const toggleExpansion = (id: number, open: boolean) => {
    const newState = [...tableState];
    const item = newState.find(x => x.id === id);
    if (item) {
      item.open = open;
      if (!open) {
        item.checkedItems = {};
        item.tableData = undefined;
      }
    }
    setTableState(newState);
  };

  const updateCheckedItems = (id: number, checkedItems: Record<IndexType, IndexType[]>): void => {
    const newState = [...tableState];
    const item = newState.find(x => x.id === id);
    if (item) {
      item.checkedItems = checkedItems;
    }
    setTableState(newState);
  };

  const updateTableData = (id: number, data: IIncludeExcludeApiResponse): void => {
    const newState = [...tableState];
    const item = newState.find(x => x.id === id);
    if (item) {
      item.tableData = data;
    }
    setTableState(newState);
  };

  const toggleCheck = (checked: boolean, item: IModalItem): void => {
    toggleModalChecked(checked, item, includeModalState, setIncludeModalState);
  };

  const defaultOpenState: Pick<IModalState, 'open' | 'header'> = {
    open: true,
    header: `You are excluding from ${currentCustomer?.label}:`,
  };

  const excludeItemsRequest = (items: IModalItem[]): AxiosPromise<unknown> => {
    const requestBody = items.map(item => {
      return {
        drug_descriptor_id: item.drugDescriptorId,
        is_excluded_from_contract: item.checked,
        gtd_id: item.gtdId,
      };
    });

    return HTTP.put(`${getUrl}/${props.selectedCustomerId}`, requestBody);
  };

  const onModalConfirm = async () => {
    try {
      const items = includeModalState.items?.filter(x => x.checked) || [];
      setIncludeModalState(prev => {
        return {
          ...prev,
          submitting: true,
        };
      });
      const request = excludeItemsRequest(items);
      await request;
      dispatch(notifySuccess('Saved'));
      setTriggerReload(new Date());
    } catch (_error) {
      dispatch(notifyError('An error occurred'));
    } finally {
      setIncludeModalState(initialIncludeModalState);
    }
  };

  const afterRequestSuccess = (callbacks: (() => void)[] | undefined = undefined) => {
    dispatch(notifySuccess('Saved'));
    setTriggerReload(new Date());
    if (callbacks) {
      callbacks.forEach(cb => cb());
    }
  };

  const onSubmitFieldUpdate = async (value: unknown) => {
    try {
      const request = ApplicationManagerClient.upsertCtd(
        popoverState.child?.gtd_id,
        popoverState.child?.drug_descriptor_id,
        currentCustomer?.value,
        popoverState.field,
        value,
      );

      await request;
      afterRequestSuccess([() => setPopoverState(initialPopoverState)]);
    } catch (error) {
      logger.error(error);
      dispatch(notifyError('An error occurred'));
    } finally {
      setPopoverState(initialPopoverState);
    }
  };

  const openPopover = (
    element: HTMLElement,
    value: unknown,
    config: {
      parent?: IResult;
      child?: IChild;
      fieldType: FieldTypes;
      field: keyof IChild | keyof IResult;
      forceAsParent?: boolean;
    },
  ): void => {
    if (config.parent != null && config.child != null) {
      throw new Error('Cannot select both a parent and child cell. Either one or the other');
    }

    setPopoverState({
      open: true,
      anchorElement: element,
      parent: config.parent,
      child: config.child,
      field: config.field,
      fieldType: config.fieldType,
      value: value,
      forceAsParent: config.forceAsParent,
    });
  };
  // #endregion

  // #region slider functions
  const disableSlider = (id: number) => {
    const newState = [...tableState];
    const item = newState.find(x => x.id === id);
    if (item) {
      item.sliderDisabled = true;
    }
    setTableState(newState);
  };

  const updateSlider = (id: number, included: boolean) => {
    const newState = [...tableState];
    const item = newState.find(x => x.id === id);
    if (item) {
      item.included = included;
    }
    setTableState(newState);
  };

  const toggleSlider = async (
    therapeuticClassId: number,
    therapeuticName: string,
    included: boolean,
  ) => {
    const message = `You are marking "${therapeuticName}" as not included in contract for ${currentCustomer?.label}.`;
    settherapeuticClassModalState({
      open: true,
      text: message,
      therapeuticClassId: therapeuticClassId,
      included: included,
    });
  };
  // #endregion

  // #region renders
  const renderPopover = (): JSX.Element => {
    const handleCancel = () => setPopoverState(initialPopoverState);
    const handleSubmit = (value: unknown) => onSubmitFieldUpdate(value);

    return (
      <Popover
        id="form"
        open={popoverState.open}
        anchorEl={popoverState?.anchorElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transitionDuration={{ exit: 0 }}
        onClose={() => setPopoverState(initialPopoverState)}
      >
        <FormField
          data-qa-id={`${popoverState.field}-${popoverState.parent?.id || ''}`}
          initialValue={popoverState.value}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          fieldType={popoverState.fieldType}
        />
      </Popover>
    );
  };

  const renderExcludeModal = (): JSX.Element => {
    return (
      <Modal open={includeModalState.open}>
        <div style={getModalStyle()} className={classes.confirmationModal}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs="auto">
              <Typography className={classes.modalHeader}>{includeModalState.header}</Typography>
            </Grid>
            <Grid item xs="auto" className={classes.confirmationModalDrugItem}>
              {includeModalState.items?.map(item => {
                return (
                  <Typography className={classes.modalDrugRow} key={item.childPk}>
                    <ArborCheckbox
                      checked={item.checked}
                      onChange={event => {
                        toggleCheck(event.target.checked, item);
                      }}
                    />
                    {item.label}
                  </Typography>
                );
              })}
            </Grid>
            <Grid item xs="auto">
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item xs="auto">
                  <Button
                    onClick={() => {
                      setIncludeModalState(initialIncludeModalState);
                    }}
                    disabled={includeModalState.submitting}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs="auto">
                  <Button
                    variant="contained"
                    className={classes.actionButton}
                    onClick={() => {
                      onModalConfirm();
                    }}
                    disabled={
                      includeModalState.submitting ||
                      includeModalState.items == null ||
                      includeModalState.items.every(x => !x.checked)
                    }
                  >
                    Confirm
                    {includeModalState?.submitting ? (
                      <CircularProgress
                        variant="indeterminate"
                        size={20}
                        className={classes.submittingIndicator}
                      />
                    ) : null}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal>
    );
  };

  return (
    <>
      {renderExcludeModal()}
      {therapeuticClassModalState && (
        <TherapeuticModal
          selectedCustomerId={props.selectedCustomerId}
          open={Boolean(therapeuticClassModalState && therapeuticClassModalState.open)}
          onCancel={() => {
            settherapeuticClassModalState(undefined);
          }}
          afterSuccess={() => {
            settherapeuticClassModalState(undefined);
            updateSlider(
              therapeuticClassModalState.therapeuticClassId,
              therapeuticClassModalState.included,
            );
            disableSlider(therapeuticClassModalState.therapeuticClassId);
          }}
          id={therapeuticClassModalState.therapeuticClassId}
          included={therapeuticClassModalState.included}
          text={therapeuticClassModalState.text}
        />
      )}

      {userHasPermissions ? (
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid item xs="auto" />
            {/* Right Side - Modals */}
            <Grid item xs="auto">
              <Button
                variant="contained"
                color="primary"
                className={classes.modalButton}
                onClick={() => {
                  const checkedChildren = getCheckedChildren();
                  setIncludeModalState({
                    ...defaultOpenState,
                    submitting: false,
                    open: true,
                    items: checkedChildren.map(child => {
                      return {
                        checked: true,
                        childPk: child.drug_descriptor_id,
                        parentPk: child.parent_drug_descriptor_id,
                        drugDescriptorId: child.drug_descriptor_id,
                        gtdId: child.gtd_id,
                        label: `${child.drug_name} ${child.strength}`,
                      };
                    }),
                  });
                }}
                disabled={!getCheckedChildren().length}
              >
                Exclude
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      {tableState.map(value => {
        return (
          <React.Fragment key={value.id}>
            <Accordion
              className={classes.expansionPanelStyles}
              expanded={value.open}
              onChange={(_event, expanded) => {
                toggleExpansion(value.id, expanded);
              }}
              TransitionProps={{
                unmountOnExit: true,
                mountOnEnter: true,
              }}
            >
              <AccordionSummary className={classes.expansionHeader}>
                <Grid container direction="row" spacing={3}>
                  <Grid container justifyContent="space-between">
                    <Grid item xs="auto" className={classes.expansionHeader}>
                      <Grid container spacing={1} data-qa-id="container 2">
                        {/* left stuff */}
                        <Typography className={classes.expansionHeaderTextLeft}>
                          {value.name}
                        </Typography>
                        <ExpandMoreIcon className={classes.expansionHeaderExpandIcon} />
                      </Grid>
                    </Grid>

                    {userHasPermissions ? (
                      <Grid item xs="auto">
                        <Grid container>
                          <Typography className={classes.expansionHeaderTextRight}>
                            {value.included ? 'Included in contract' : 'Excluded from contract'}
                          </Typography>
                          <ArborSwitch
                            onClick={e => e.stopPropagation()}
                            checked={value.included}
                            onChange={e => {
                              toggleSlider(value.id, value.name, e.target.checked);
                            }}
                            disabled={value.sliderDisabled}
                            data-qa-id={getQaId(value.id.toString())}
                          />
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails className={classes.expansionDetails}>
                {renderPopover()}
                <BaseTable<IIncludeExcludeApiResponse, IResult, IChild>
                  triggerReload={triggerReload}
                  actionsPermitted={userHasPermissions}
                  classesOverride={{ tableContainer: classes.tableContainer }}
                  orderByDefaultChild="drug_name"
                  endpointBase={value.url}
                  paginationQueryParamSettings={PAGINATION_PARAMS}
                  childPkSelector={childPkSelector}
                  parentPkSelector={parentPkSelector}
                  columnSettings={[
                    ...COLUMN_SETTINGS,
                    {
                      label: 'Purchase By Customer',
                      sortable: true,
                      childKey: 'can_be_purchased_by_customer',
                      childValueFormatter: booleanConvert,
                      childCellClick: (event, _parent, child) => {
                        openPopover(event.currentTarget, child.can_be_purchased_by_customer, {
                          child: child,
                          field: 'can_be_purchased_by_customer',
                          fieldType: FieldTypes.YesNo,
                        });
                      },
                    },
                    {
                      label: 'Available date',
                      sortable: true,
                      childKey: 'available_date',
                      childValueFormatter: dateConvert,
                      childCellClick: (event, _parent, child) => {
                        openPopover(
                          event.currentTarget,
                          utcMomentOrUndefined(child.available_date),
                          {
                            child: child,
                            field: 'available_date',
                            fieldType: FieldTypes.DatePicker,
                          },
                        );
                      },
                    },
                    {
                      label: 'End date',
                      sortable: true,
                      childKey: 'end_date',
                      childValueFormatter: dateConvert,
                      childCellClick: (event, _parent, child) => {
                        openPopover(event.currentTarget, utcMomentOrUndefined(child.end_date), {
                          child: child,
                          field: 'end_date',
                          fieldType: FieldTypes.DatePicker,
                        });
                      },
                    },
                  ]}
                  checkedItemTracking={{
                    items: value.checkedItems,
                    setCheckedItems: checkedItems => {
                      updateCheckedItems(value.id, checkedItems);
                    },
                  }}
                  searchTerm={props.searchTerm}
                  afterDataLoad={data => {
                    updateTableData(value.id, data);
                  }}
                />
              </AccordionDetails>
            </Accordion>
          </React.Fragment>
        );
      })}
    </>
  );
};

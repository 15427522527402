import createStyles from '@mui/styles/createStyles';

export const styles = (theme: any) =>
  createStyles({
    modalSelectInnerContainer: {
      width: theme.spacing(99.25),
      height: theme.spacing(68.5),
      top: `calc(50% - ${theme.spacing(34.25)})`,
      left: `calc(50% - ${theme.spacing(49.625)})`,
      position: 'absolute',
      overflow: 'hidden',
      borderRadius: theme.spacing(0.75),
    },
    modalFormInnerContainer: {
      width: theme.spacing(99.25),
      height: theme.spacing(68.5),
      top: `calc(50% - ${theme.spacing(53.5)})`,
      left: `calc(50% - ${theme.spacing(84.25)})`,
      position: 'absolute',
      overflow: 'visible',
      borderRadius: theme.spacing(0.75),
    },
    specialtyTag: {
      'borderRadius': 5,
      'color': theme.palette.primary.white,
      'padding': theme.spacing(0, 0.5),
      'marginLeft': 5,
      'height': theme.spacing(2.75),
      'backgroundColor': theme.palette.primary.success,
      'fontWeight': theme.font.weight.thick,
      '&.CDM': {
        backgroundColor: theme.palette.primary.yellow,
        color: theme.palette.primary.deepBlue,
      },
    },
    tableTitle: {
      height: theme.spacing(6.5),
      backgroundColor: theme.palette.primary.trellisLightBlue,
      paddingLeft: theme.spacing(3),
      alignItems: 'center',
      fontSize: theme.spacing(2),
      fontWeight: 500,
      display: 'flex',
      color: theme.palette.primary.steelGrey,
    },
    tableContainer: {
      marginTop: theme.spacing(2.75),
      marginRight: theme.spacing(3.75),
      marginBottom: theme.spacing(2.75),
      marginLeft: theme.spacing(2.75),
      borderWidth: theme.spacing(0.125),
      borderStyle: 'solid',
      borderColor: theme.palette.primary.silverMist,
      borderRadius: theme.spacing(0.75),
      height: theme.spacing(44.5),
      width: theme.spacing(92.75),
    },
    footerContainer: {
      width: theme.spacing(92.75),
      height: theme.spacing(11.25),
      marginLeft: theme.spacing(2.75),
    },
    confirmationPanel: {
      paddingTop: theme.spacing(2.25),
    },
    messageContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: theme.spacing(42.5),
    },
    checkboxColumn: {
      width: '6%',
      paddingLeft: 0,
    },
    startDateColumn: {
      width: '17%',
      paddingLeft: theme.spacing(0.625),
      paddingRight: theme.spacing(0.625),
      fontWeight: 500,
    },
    formColumn: {
      width: '10%',
      paddingLeft: theme.spacing(0.625),
      paddingRight: theme.spacing(0.625),
      fontWeight: 500,
    },
    doseColumn: {
      width: '20%',
      paddingLeft: theme.spacing(0.625),
      paddingRight: theme.spacing(0.625),
      fontWeight: 500,
    },
    ndcColumn: {
      width: '20%',
      paddingLeft: theme.spacing(0.625),
      paddingRight: theme.spacing(0.625),
      kontWeight: 500,
    },
    drugNameColumn: {
      width: '27%',
      paddingLeft: theme.spacing(0.625),
      paddingRight: theme.spacing(0.625),
      fontWeight: 500,
    },
    drugNameCellWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    drugNameCell: {
      float: 'left',
      maxWidth: `calc(100% - ${theme.spacing(2.75)})`,
    },
    trellisIcon: {
      paddingLeft: theme.spacing(0.625),
    },
  });

import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) => {
  return createStyles({
    content: {
      minHeight: theme.spacing(120),
      padding: '30px 30px',
    },
    eligibilityButtonWrp: {
      textAlign: 'right',
    },
  });
};

export { styles };

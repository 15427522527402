import { ReactChild } from 'react';
import { MED_SYNC_STATUS } from '../../../constants';

export enum MedSyncDisplayStatus {
  Undecided = 'Undecided',
  NotSynchronized = 'NotSynchronized',
  Synchronized = 'Synchronized',
  OptOut = 'OptOut',
  InConflict = 'InConflict',
  Default = 'Default',
}
export const MedSyncUndecidedTooltip = 'Med Sync: Undecided';
export const MedSyncNotSynchronizedTooltip = 'No therapies synchronized yet';
export const MedSyncSynchronizedTooltip = 'No therapies synchronized yet';
export const MedSyncInConflictTooltip = "There's a conflict in the therapies synchronization";
export const MedSyncOptOutTooltip = 'Med Sync: Opt Out';

export type MedSyncStatus<T = typeof MED_SYNC_STATUS> = T[keyof T];

export interface IMedSyncPreferences {
  anchorDate: Date | null;
  syncTimeFrame: number | null;
  nextSyncDate: Date | null;
  status: MedSyncStatus;
  patientId: number;
}

export interface IMedSyncFormFields {
  anchorDate: Date | null;
  syncTimeFrame: number | null;
}

export interface IMedSyncIconState {
  tooltipTitle: ReactChild;
  displayStatus: MedSyncDisplayStatus;
}

import makeStyles from '@mui/styles/makeStyles';
import { expandTheme } from 'utils/theme-util';

export const styles = makeStyles(theme => {
  const { palette } = expandTheme(theme);
  const colPadding = (size: number) => ({
    paddingTop: theme.spacing(size),
    paddingBottom: theme.spacing(size),
  });

  const exclamationCircleIcon = {
    color: palette.primary.rollingStone,
  };

  return {
    headerColumn: {
      ...colPadding(1),
    },
    headerNarrowColumn: {
      width: theme.spacing(0),
      padding: theme.spacing(0),
      textAlign: 'center',
    },
    headerLastColumn: {
      width: theme.spacing(11),
    },
    checkbox: {
      padding: theme.spacing(2),
    },
    disabledRow: {
      'backgroundColor': palette.primary.grey14,
      '& td': {
        color: palette.primary.grey12,
        fontWeight: 450,
        padding: '0 16px',
      },
    },
    rowInConflict: {
      'backgroundColor': palette.primary.redWhite,
      '& td': {
        fontWeight: 450,
        padding: '0 16px',
      },
    },
    activeRow: {
      '& td': {
        fontWeight: 450,
        padding: '0 16px',
      },
    },
    specialtyBox: {
      marginLeft: theme.spacing(1),
      backgroundColor: palette.primary.cuttySark,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0.5),
      color: palette.primary.white,
    },
    trellisLogo: {
      height: 22,
      weight: 22,
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(-0.7),
    },
    dispenseOptIn: {
      ...exclamationCircleIcon,
      paddingLeft: theme.spacing(4),
    },
    dispenseNotOptIn: {
      ...exclamationCircleIcon,
      paddingLeft: theme.spacing(6.2),
    },
    exclamationCircleConflictIcon: {
      paddingLeft: theme.spacing(4),
      color: palette.primary.darkRed,
    },
  };
});

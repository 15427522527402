import debounce from 'debounce-promise';
import { DEBOUNCE_IN_MS } from 'constants/config';
import {
  PATIENT_ALLERGY_SEVERITY_SEVERE_ID,
  NO_ALLERGY_INFORMATION_AVAILABLE_ID,
} from 'constants/index';
import { noKnownAllergyNames } from 'constants/lists';
import HTTP from '../http';

export const fetchAllergens = debounce(searchString => {
  const API = `/allergies/allergens?q=${searchString}`;
  return HTTP.get(API, {});
}, DEBOUNCE_IN_MS);

export const getAllergenCategories = allergen => {
  const retArr = [];
  if (allergen && allergen.is_environment) {
    retArr.push('is_environment');
  }
  if (allergen && allergen.is_biologic) {
    retArr.push('is_biologic');
  }
  if (allergen && allergen.is_food) {
    retArr.push('is_food');
  }
  if (allergen && allergen.is_medication) {
    retArr.push('is_medication');
  }
  return retArr.join(',');
};

export const getAllergenCategoriesDisplay = allergen => {
  const retArr = [];
  if (allergen && allergen.is_environment) {
    retArr.push('Environmental');
  }
  if (allergen && allergen.is_biologic) {
    retArr.push('Biologic');
  }
  if (allergen && allergen.is_food) {
    retArr.push('Food');
  }
  if (allergen && allergen.is_medication) {
    retArr.push('Medication');
  }
  return retArr.join(', ');
};

export const fetchReactions = debounce(searchString => {
  const API = `/allergies/reactions?q=${searchString}`;
  return HTTP.get(API, {});
}, DEBOUNCE_IN_MS);

export const getAllergiesOrderedBySeverity = (allergies, statusFilter) => {
  const highSeverityAllergies = allergies
    ? allergies.filter(allergy => allergy.severity_id === PATIENT_ALLERGY_SEVERITY_SEVERE_ID)
    : [];

  const otherSeverityAllergies = allergies
    ? allergies.filter(allergy => allergy.severity_id !== PATIENT_ALLERGY_SEVERITY_SEVERE_ID)
    : [];

  const allergiesToPass = [...highSeverityAllergies, ...otherSeverityAllergies].filter(
    allergy =>
      !statusFilter ||
      statusFilter.some(
        status =>
          allergy.status_id === status &&
          allergy.allergen.id !== NO_ALLERGY_INFORMATION_AVAILABLE_ID,
      ),
  );

  return allergiesToPass;
};

export const addedNoKnownAllergy = allergy => {
  if (allergy && allergy.allergy_name) {
    return noKnownAllergyNames.some(name => name === allergy.allergy_name);
  }
  return false;
};

import { groupBy } from 'lodash';
import { CSL, FC } from 'constants/index';
import { FillCoordinationStatusMap, CounselingStatusMap } from 'constants/task-statuses';
import { ITaskTransitionPermissions } from 'interfaces/redux/IUser';
import { IResourceState } from 'interfaces/redux/ILookups';
import { StatusTaskType } from 'interfaces/StatusTaskType';
import { AllTaskTypesStatuses } from 'interfaces/enums/TaskStatuses';
import { StatusMap } from '../interfaces/StatusMap';
import { ITaskStatus } from '../interfaces/ITaskStatus';
import { ITask } from '../interfaces/redux/ITasks';

class TaskStatusUtil {
  static taskStatusIdToResourceStateId<TaskTypeStatuses>(
    resourceStates: IResourceState<TaskTypeStatuses>[],
    search: TaskTypeStatuses,
  ): number | null {
    return (
      resourceStates.find(resourceState => resourceState.reason_status_id === search)
        ?.resource_state_id || null
    );
  }

  static buildCanTransitionFromPermissions<T extends ITask = ITask, S = AllTaskTypesStatuses>(
    permissions?: ITaskTransitionPermissions,
    resourceStates?: IResourceState<S>[],
  ): (task: T, to: S) => boolean {
    if (!permissions?.tasks_status || !resourceStates?.length) {
      return () => true;
    }

    return (task: T, to: S): boolean => {
      const allowedTransitions =
        permissions.tasks_status[task?.taskType.toLowerCase() as StatusTaskType]?.state_transitions;

      // No permission configured for the taskType then allow all transitions
      if (!allowedTransitions) {
        return true;
      }

      const taskTypeResourceStates = resourceStates.filter(
        resourceState => resourceState.resource_name === task.taskType,
      );

      // Mapping from task statuses codes to lookups
      const fromState = TaskStatusUtil.taskStatusIdToResourceStateId<S>(
        taskTypeResourceStates,
        task.status_id as unknown as S,
      );

      const toState = TaskStatusUtil.taskStatusIdToResourceStateId<S>(taskTypeResourceStates, to);
      if (fromState && toState) {
        // Find allowed transitions and rules from a specific initial status
        const fromStateRules = allowedTransitions[fromState];

        // Get the rules for a specific end status
        const fromToStateRules = fromStateRules?.find(transition => transition.id === toState);

        if (fromToStateRules?.special_conditions?.length) {
          const noNullConditions = fromToStateRules.special_conditions.filter(x => x !== null);
          const conditionGroups = groupBy(noNullConditions, 'name');
          // Meet all the conditions ?
          return Object.keys(conditionGroups).every(
            attribute =>
              conditionGroups?.[attribute]
                ?.filter(conditions => !!conditions)
                ?.map(conditions => conditions.value)
                ?.includes((task as any)[attribute]?.toString()) || false,
          );
        }

        return Boolean(fromToStateRules);
      }

      return false;
    };
  }
}

export { TaskStatusUtil };

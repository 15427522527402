import React, { Fragment, useState } from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { TextField } from '@mui/material';
import OptionSelector from './option-selector';
import RagenSelector from './range-selector';
import Button from './button';

const useStyles = makeStyles(theme => ({
  number: {
    backgroundColor: theme.palette.primary.deepBlue,
    height: 36,
    width: 36,
    margin: '0 auto',
    borderRadius: '18px',
    color: theme.palette.primary.white,
    verticalAlign: 'middle',
    lineHeight: '36px',
    fontWeight: 400,
  },
  question: {
    'margin': '30px 0 50px',
    [`${theme.breakpoints.down('xl')} and (orientation: landscape)`]: {
      margin: '10px 0 18px 0',
    },
    '@media (max-height : 800px) and (orientation: portrait)': {
      margin: '20px 0 25px',
    },
  },
  shrink: {
    '@media (max-height : 900px) and (orientation: portrait)': {
      margin: '10px 0 10px',
    },
  },
  subQuestionContainer: {
    'padding': 10,
    'fontSize': 16,
    'backgroundColor': theme.palette.primary.offWhite,
    '@media (max-height : 900px) and (orientation: portrait)': {
      padding: '0 10px',
    },
  },
  options: {
    width: '100%',
    [`${theme.breakpoints.down('xl')} and (orientation: landscape)`]: {
      maxHeight: 212,
      overflowY: 'auto',
    },
    fontSize: 15,
  },
  description: {
    marginBottom: 5,
  },
  inputText: {
    width: '100%',
  },
}));

export default ({
  question,
  description,
  handleChange,
  isSubQuestion = false,
  initialValue,
  number,
  onSubmit,
  shrink,
}) => {
  const classes = useStyles();
  const [val, setVal] = useState(initialValue);
  const { question: questionText, options, type, minText, maxText } = question;

  const getValidCharacters = (validCharacters, currentValue) => {
    if (currentValue) {
      const validChar = currentValue.split('').reduce((acc, itemCharacter, ind) => {
        if (validCharacters.includes(itemCharacter) && ind === 0) {
          acc.push(String(itemCharacter));
        } else if (validCharacters.includes(itemCharacter) && ind > 0 && acc[ind - 1] !== '0') {
          acc.push(String(itemCharacter));
        }
        return acc;
      }, []);
      return validChar.join('');
    }
    return null;
  };

  return (
    <>
      <div className={isSubQuestion && classes.subQuestionContainer}>
        {number && <div className={classes.number}>{number}</div>}
        <div className={cx(classes.question, shrink ? classes.shrink : null)}>
          {description && <div className={classes.description}>{description}</div>}
          {questionText}
        </div>
        <div className={classes.options}>
          {type === 'options' && (
            <>
              <OptionSelector
                options={options}
                onChange={value => handleChange(value)}
                initialValue={initialValue}
                questionNumber={number}
                shrink={shrink}
              />
              {onSubmit && (
                <Button onClick={() => onSubmit()} data-qa-id="button_submit_questionnaire">
                  Submit Questionnaire
                </Button>
              )}
            </>
          )}
          {type === 'range' && (
            <RagenSelector
              options={options}
              minText={minText}
              maxText={maxText}
              onChange={value => {
                handleChange(value);
                if (onSubmit) {
                  onSubmit();
                }
              }}
              selectButtonText={onSubmit ? 'Select & Submit Questionnaire' : 'Select'}
              initialValue={initialValue}
              questionNumber={number}
            />
          )}
          {type === 'text' && (
            <TextField
              value={val}
              variant="standard"
              placeholder="Only integers - maximum 2 digits"
              className={classes.inputText}
              id="outlined-basic"
              onChange={event => {
                const tempValue = getValidCharacters(question.validCharacters, event.target.value);
                setVal(
                  tempValue && tempValue.length
                    ? question?.maxLength
                      ? tempValue.slice(0, question?.maxLength)
                      : tempValue
                    : val,
                );
                event.persist();
                handleChange(tempValue);
                if (onSubmit) {
                  onSubmit();
                }
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

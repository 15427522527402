import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { expandTheme } from 'utils/theme-util';

const styles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    table: {
      borderRadius: theme.spacing(0.5),
      minWidth: theme.spacing(80),
    },
    mainExpandedRow: {
      backgroundColor: palette.primary.grey14,
    },
    noBorderRow: {
      border: 0,
    },
    leftCell: {
      width: theme.spacing(0.6),
      paddingLeft: theme.spacing(1.5),
      paddingRight: 0,
    },
    cell: {
      paddingLeft: theme.spacing(0.3),
      paddingRight: theme.spacing(0.3),
    },
    hideSortArrow: {
      '& svg': {
        display: 'none',
      },
    },
  });
};

type Styles = WithStyles<typeof styles>;

export const useStyles = makeStyles(styles);

// Return the useStyles hooks result, but augmented with styles types
export const useTypedStyles = (): Styles => ({
  classes: useStyles(),
});

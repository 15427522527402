import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { createNotesNewPatient } from 'actions/action-notes';
import {
  addPatient,
  checkDuplicte,
  fetchPatient,
  selectPatientTab,
  updatePatientPreferences,
  updateWorkList,
} from 'actions/action-patient';
import Divider from 'components/divider';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import ReactSelectForRedux from 'components/form/field/react-select';
import {
  renderCheckbox,
  renderDropdown,
  renderLast4ssnField,
  renderTextField,
} from 'components/form/field/redux-field';
import FormHeader from 'components/form/header/header';
import SubHeader from 'components/form/header/subheader';
import { RenderAddresses, renderEmails, renderPhones } from 'components/form/subform/subform';
import {
  required,
  validateDate,
  validateLast4ssn,
  validateContact,
} from 'components/form/validation/validation';
import { goBack, push } from 'connected-react-router';
import {
  ADD_PATIENT_FORM,
  NO_SIDEBAR,
  PATIENT_SIDEBAR,
  NEW_PATIENT_DUPLICATE_NAME,
  NEW_PATIENT_DUPLICATE_MRN,
  NEW_PATIENT_DUPLICATE_INACTIVE,
  NEW_PATIENT_NO_MRN,
  DEFAULT_CONTACT_SUPPORT_LINK,
} from 'constants/index';
import { genderList, raceList } from 'constants/lists';
import { convertToArborDate } from 'models/time/arbor-date';
import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withLastLocation } from 'react-router-last-location';
import { compose } from 'recompose';
import { Field, FieldArray, getFormValues, reduxForm, SubmissionError } from 'redux-form';
import {
  assignRankingAndKeys,
  getLanguageList,
  processPhoneData,
} from 'services/utils/demographic-service';
import { withCustomer } from '../../../helpers/router';
import { styles } from './create-patient-styles';
import ResponsiblePartyForm from '../demographics/responsible-party-form';

class CreatePatientForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      modalType: '',
      noMrnChecked: false,
      isMrnDuplicated: false,
      possiblePatientlist: [],
      payload: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleMrnBlur = this.handleMrnBlur.bind(this);
    this.handleNameBlur = this.handleNameBlur.bind(this);
    this.handleDobBlur = this.handleDobBlur.bind(this);
    this.checkNoMrn = this.checkNoMrn.bind(this);
    this.handleSubmitWithoutMrn = this.handleSubmitWithoutMrn.bind(this);
    this.loadPatient = this.loadPatient.bind(this);
  }

  componentDidMount() {
    const {
      push, // eslint-disable-line
      match,
    } = this.props;
    const { url } = match;
    // eslint-disable-next-line
    const { sidebar } = queryString.parse(location.search);
    if (sidebar !== '' && sidebar !== NO_SIDEBAR) {
      const updatedUrl = `${url}?sidebar=${NO_SIDEBAR}`;
      push(updatedUrl);
    }
  }

  // Must focus on mrn after the component get updated
  componentDidUpdate(prevProps, prevState) {
    const { openModal: prevOpenModel } = prevState;
    const { openModal: nextOpenModal, modalType } = this.state;
    // Check if this is the update that closing the duplciate mrn modal
    if (!prevOpenModel && nextOpenModal) {
      if ([NEW_PATIENT_DUPLICATE_MRN, NEW_PATIENT_NO_MRN].includes(modalType)) {
        this.mrnInput.focus();
      }
    }
  }

  loadPatient(patient) {
    const {
      push, // eslint-disable-line
    } = this.props;
    const patientLink = `/patients/${patient.id}/demographics?sidebar=${PATIENT_SIDEBAR}`;
    push(withCustomer(patientLink));
  }

  handleMrnBlur(e) {
    const mrn = e.target.value;
    if (mrn) {
      const searchCriteria = {
        source_patient_id: mrn,
      };
      checkDuplicte(searchCriteria).then(request => {
        const patientList = request.data.duplicate_patients;
        if (patientList.length > 0) {
          this.setState({
            openModal: true,
            modalType: NEW_PATIENT_DUPLICATE_MRN,
            isMrnDuplicated: true,
            possiblePatientlist: patientList,
          });
        } else {
          this.setState({
            isMrnDuplicated: false,
          });
        }
      });
    }
  }

  handleNameBlur() {
    const { formValues } = this.props;
    const { first_name: firstName, last_name: lastName } = formValues;
    if (firstName && lastName) {
      const searchCriteria = {
        first_name: firstName,
        last_name: lastName,
      };
      checkDuplicte(searchCriteria).then(request => {
        const patientList = request.data.duplicate_patients;
        if (patientList.length > 0) {
          const hasInactivePatient = patientList.find(({ active }) => !active);
          this.setState({
            openModal: true,
            modalType: hasInactivePatient
              ? NEW_PATIENT_DUPLICATE_INACTIVE
              : NEW_PATIENT_DUPLICATE_NAME,
            possiblePatientlist: patientList,
          });
        }
      });
    }
  }

  handleDobBlur() {
    const { formValues } = this.props;
    const { dob, last_name: lastName } = formValues;
    if (dob && lastName) {
      const searchCriteria = {
        dob,
        last_name: lastName,
      };
      checkDuplicte(searchCriteria).then(request => {
        const patientList = request.data.duplicate_patients;
        if (patientList.length > 0) {
          this.setState({
            openModal: true,
            modalType: NEW_PATIENT_DUPLICATE_NAME,
            possiblePatientlist: patientList,
          });
        }
      });
    }
  }

  checkNoMrn(e) {
    this.setState({
      noMrnChecked: e.target.checked,
    });
  }

  handleCloseModal() {
    this.setState({ openModal: false });
  }

  handleSubmit(values) {
    const {
      addPatient, // eslint-disable-line
      selectPatientTab, // eslint-disable-line
      updateWorkList, // eslint-disable-line
      push, // eslint-disable-line
      createNotesNewPatient, // eslint-disable-line
      updatePatientPreferences, // eslint-disable-line
      filters,
    } = this.props;
    const { emrs } = filters;
    const payload = {
      first_name: values.first_name,
      last_name: values.last_name,
      suffix: values.suffix,
      middle_name: values.middle_name,
      nickname: values.nickname,
      ssn: values.ssn,
      dob: convertToArborDate(values.dob).getUtcDate(),
      gender: values.gender,
      ethnicity: values.ethnicity,
      languages:
        values.languages && values.languages.length > 0 ? JSON.stringify(values.languages) : null,
      need_interpreter: values.need_interpreter,
      addresses:
        values.addresses && values.addresses.length > 0
          ? JSON.stringify(assignRankingAndKeys(values.addresses, values.preferred_address_index))
          : null,
      emails:
        values.emails && values.emails.length > 0
          ? JSON.stringify(assignRankingAndKeys(values.emails, values.preferred_email_index))
          : null,
      phones:
        values.phones && values.phones.length > 0
          ? JSON.stringify(
              processPhoneData(
                assignRankingAndKeys(values.phones, values.preferred_phone_index),
                true,
              ),
            )
          : null,
      is_declined_to_provide_addresses: values.is_declined_to_provide_addresses,
      email_options: values.email_options,
      is_declined_to_provide_phones: values.is_declined_to_provide_phones,
      responsible_party: values.responsible_party,
    };
    if (values.source_patient_id) {
      if (!emrs.length) {
        throw new SubmissionError();
      }

      const [emr] = emrs.length === 1 ? emrs : emrs.filter(e => e.id === values.source_id);
      payload.source_id = emr.id;
      payload.source_patient_id = values.source_patient_id;
      const searchCriteria = {
        source_patient_id: values.source_patient_id,
      };
      return checkDuplicte(searchCriteria).then(request => {
        const patientList = request.data.duplicate_patients;
        if (patientList.length > 0) {
          this.setState({
            openModal: true,
            modalType: NEW_PATIENT_DUPLICATE_MRN,
            possiblePatientlist: patientList,
          });
        } else {
          addPatient(payload).then(addedPatient => {
            const { patient } = this.props;
            const addedPatientData = addedPatient?.payload?.data?.added_patient;
            updatePatientPreferences({
              patient_id: addedPatientData.id,
              preferred_contact_patient: 1,
              preferred_rx_delivery_patient: 1,
            });
            const patientLink = `/patients/${addedPatientData.id}/demographics?sidebar=${PATIENT_SIDEBAR}`;
            updateWorkList([addedPatientData]);
            selectPatientTab('demographics');
            createNotesNewPatient();
            this.handleCancel(false);
            push(withCustomer(patientLink));
          });
        }
      });
    }
    return this.setState({
      openModal: true,
      modalType: NEW_PATIENT_NO_MRN,
      payload,
      noMrnChecked: false,
    });
  }

  handleSubmitWithoutMrn() {
    const {
      addPatient, // eslint-disable-line
      selectPatientTab, // eslint-disable-line
      updateWorkList, // eslint-disable-line
      push, // eslint-disable-line
      createNotesNewPatient, // eslint-disable-line
      updatePatientPreferences, // eslint-disable-line
    } = this.props;
    const { payload } = this.state;
    payload.source_id = 1; // trellis source id
    addPatient(payload).then(() => {
      const { patient } = this.props;
      updatePatientPreferences({
        patient_id: patient.id,
        preferred_contact_patient: 1,
        preferred_rx_delivery_patient: 1,
      });
      updateWorkList([patient]);
      selectPatientTab('demographics');
      this.handleCancel(false);
      createNotesNewPatient();
      const patientLink = `/patients/${patient.id}/demographics?sidebar=${PATIENT_SIDEBAR}`;
      push(withCustomer(patientLink));
    });
  }

  handleCancel(isGoBack) {
    const {
      reset,
      lastLocation,
      goBack, //eslint-disable-line
    } = this.props;
    reset();
    if (isGoBack) {
      if (lastLocation?.pathname.includes('/patients/new')) {
        window.location.href = '/';
        return;
      }

      goBack();
    }
  }

  renderPatientInModal(patient) {
    const patientIsActive = !!patient.active;
    const patientStr = `${patient.first_name} ${patient.last_name}${
      !patientIsActive ? ' (Inactive)' : ''
    }`;
    const dobStr = `DOB: ${
      patient.dob ? convertToArborDate(patient.dob, true).getUtcDate(true) : '-'
    }`;
    const primaryAddr = patient.addresses && patient.addresses.length > 0 && patient.addresses[0];
    const streetStr =
      primaryAddr && primaryAddr.line2
        ? `${primaryAddr.line1}, ${primaryAddr.line2}`
        : primaryAddr && primaryAddr.line1;
    const regionStr = primaryAddr && `${primaryAddr.city} ${primaryAddr.state} ${primaryAddr.zip}`;
    return (
      <div key={patient.id}>
        <Divider />
        <ListItem>
          <Grid container alignItems="center">
            <Grid item xs={3}>
              <div>
                <strong>{patientStr}</strong>
              </div>
              <div>{dobStr}</div>
            </Grid>
            <Grid item xs={2}>
              <div>
                <strong>MRN: </strong>
              </div>
              <div>{patient.source_patient_id}</div>
            </Grid>
            <Grid item xs={3}>
              <div>{streetStr}</div>
              <div>{regionStr}</div>
            </Grid>
            {patientIsActive && (
              <Grid item xs={4} align="right">
                <Button
                  onClick={() => this.loadPatient(patient)}
                  variant="outlined"
                  color="primary"
                >
                  Patient Chart
                </Button>
              </Grid>
            )}
          </Grid>
        </ListItem>
      </div>
    );
  }

  renderModal() {
    const { classes, formValues } = this.props;

    const { possiblePatientlist, modalType, openModal, noMrnChecked } = this.state;

    let formMrn = '';
    if (formValues) {
      formMrn = formValues.source_patient_id;
    }

    const duplciateMrnMes = `The MRN ${formMrn} already exists in the system.
     Please check the existing patient to make sure it’s not a duplicate.`;
    if (modalType === NEW_PATIENT_DUPLICATE_MRN) {
      return (
        <Dialog
          open={openModal}
          onClose={this.handleCloseModal}
          maxWidth="md"
          disableEscapeKeyDown
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
            <DialogTitle id="alert-dialog-title">MRN Exists</DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.alertWording} id="alert-dialog-description">
                {duplciateMrnMes}
              </DialogContentText>
            </DialogContent>
          </div>
          <List>{possiblePatientlist.map(patient => this.renderPatientInModal(patient))}</List>
          <DialogActions className={classes.modalActionBtnContainer}>
            <Button variant="contained" onClick={this.handleCloseModal} color="primary">
              Change MRN
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    if ([NEW_PATIENT_DUPLICATE_NAME, NEW_PATIENT_DUPLICATE_INACTIVE].includes(modalType)) {
      const modalBody =
        modalType === NEW_PATIENT_DUPLICATE_INACTIVE ? (
          <>
            {' Please '}
            <a
              href={DEFAULT_CONTACT_SUPPORT_LINK}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.customLink}
            >
              contact support
            </a>
            {' to resolve.'}
          </>
        ) : (
          ' Please review and determine if duplicate.'
        );

      return (
        <Dialog
          open={openModal}
          onClose={this.handleCloseModal}
          maxWidth="md"
          disableEscapeKeyDown
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
            <DialogTitle id="alert-dialog-title">Possible Duplicate</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                The following patient(s) have a similar name and/or date of birth.
                {modalBody}
              </DialogContentText>
            </DialogContent>
          </div>
          <List>{possiblePatientlist.map(patient => this.renderPatientInModal(patient))}</List>
          <DialogActions className={classes.modalActionBtnContainer}>
            <Button variant="contained" onClick={this.handleCloseModal} color="primary">
              Not a duplicate
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    if (modalType === NEW_PATIENT_NO_MRN) {
      return (
        <Dialog
          open={openModal}
          onClose={this.handleCloseModal}
          maxWidth="sm"
          disableEscapeKeyDown
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
            <DialogTitle id="alert-dialog-title">No MRN Entered</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You have not entered an MRN. Please lookup the patient’s MRN in the EMR and enter it
                in before saving.
              </DialogContentText>
              <Grid container>
                <Grid item xs={1} align="center">
                  <Checkbox
                    checked={noMrnChecked}
                    onChange={this.checkNoMrn}
                    value="checkedB"
                    color="primary"
                  />
                </Grid>
                <Grid item xs={11}>
                  <div className={classes.checkboxTextContainer}>
                    <Typography variant="body2">
                      The patient’s MRN is not currently available. I understand this might affect
                      the quality and accuracy of the patient chart.
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
          </div>
          <DialogActions className={classes.modalActionBtnContainer}>
            {noMrnChecked ? (
              <Button variant="contained" onClick={this.handleSubmitWithoutMrn} color="primary">
                MRN not available
              </Button>
            ) : (
              <Button variant="contained" disabled color="primary">
                MRN not available
              </Button>
            )}

            <Button variant="contained" onClick={this.handleCloseModal} color="primary">
              Add MRN
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  }

  render() {
    const { classes, handleSubmit, submitting, pristine, change, filters, formValues } = this.props;

    if (!formValues) {
      return null;
    }

    const { isMrnDuplicated } = this.state;
    const { emrs } = filters;
    const emrList = emrs.map(emr => ({ label: emr.name, value: emr.id }));
    const mrn = formValues.source_patient_id;
    const disableForm = mrn && isMrnDuplicated;
    let isUnderAge = false;
    if (formValues.dob) {
      const patientArborDob = convertToArborDate(formValues.dob, true);
      const patientYears = patientArborDob.getYearsFromNow();
      isUnderAge = patientYears < 18;
    }

    return (
      <div className={classes.patientFormContainer}>
        {this.renderModal()}
        <form onSubmit={handleSubmit(this.handleSubmit)} autoComplete="off">
          <FormHeader header="Add Patient" />
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Grid container spacing={7}>
                <Grid item xs={3}>
                  <Field
                    name="source_patient_id"
                    onBlur={this.handleMrnBlur}
                    onFocus={this.handleMrnFocus}
                    label="MRN"
                    component={renderTextField}
                    inputRef={input => {
                      this.mrnInput = input;
                    }}
                  />
                </Grid>
                {emrList.length > 1 && mrn && (
                  <Grid item xs={3}>
                    <Field
                      name="source_id"
                      label="Source"
                      validate={[required]}
                      component={renderDropdown}
                      fields={emrList}
                      id="new_patient_source_id"
                    />
                  </Grid>
                )}
                <Grid item xs={3}>
                  <Field
                    name="ssn"
                    label="Social Security Number"
                    adormentcontent="****-**-"
                    validate={[validateLast4ssn]}
                    component={renderLast4ssnField}
                    disabled={disableForm}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={7}>
                <Grid item xs={12}>
                  <SubHeader name="Personal Information" />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="last_name"
                    onBlur={this.handleNameBlur}
                    label="Last Name *"
                    validate={[required]}
                    component={renderTextField}
                    disabled={disableForm}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="first_name"
                    onBlur={this.handleNameBlur}
                    label="First Name *"
                    validate={[required]}
                    component={renderTextField}
                    disabled={disableForm}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="middle_name"
                    label="Middle Initial"
                    component={renderTextField}
                    disabled={disableForm}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="suffix"
                    label="Suffix"
                    component={renderTextField}
                    disabled={disableForm}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="nickname"
                    label="Preferred Name"
                    component={renderTextField}
                    disabled={disableForm}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={7}>
                <Grid item xs={3} qa-id="new_patient_dob">
                  <Field
                    name="dob"
                    label="Date of Birth *"
                    onBlur={this.handleDobBlur}
                    component={renderDatePicker}
                    validate={[required, validateDate]}
                    disabled={disableForm}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="gender"
                    label="Gender *"
                    validate={[required]}
                    component={renderDropdown}
                    fields={genderList}
                    disabled={disableForm}
                    id="new_patient_gender"
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="ethnicity"
                    label="Race"
                    component={renderDropdown}
                    fields={raceList}
                    disabled={disableForm}
                    id="new_patient_ethnicity"
                  />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} id="languages-demographics">
                  <Field
                    name="languages"
                    label={`Languages${formValues.need_interpreter ? ' *' : ''}`}
                    fields={getLanguageList()}
                    component={ReactSelectForRedux}
                    disabled={disableForm}
                    validate={formValues.need_interpreter ? [required] : []}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="need_interpreter"
                    label="Needs Interpreter"
                    component={renderCheckbox}
                  />
                </Grid>
              </Grid>
              <Divider />
              <Grid container>
                <FieldArray
                  name="addresses"
                  formValues={formValues}
                  preferredField="preferred_address_index"
                  declineField="is_declined_to_provide_addresses"
                  component={RenderAddresses}
                  change={change}
                  classes={classes}
                />
              </Grid>
              <Divider />
              <Grid container>
                <FieldArray
                  name="emails"
                  formValues={formValues}
                  preferredField="preferred_email_index"
                  declineField="email_options"
                  component={renderEmails}
                  change={change}
                  classes={classes}
                />
              </Grid>
              <Divider />
              <Grid container>
                <FieldArray
                  name="phones"
                  formValues={formValues}
                  preferredField="preferred_phone_index"
                  declineField="is_declined_to_provide_phones"
                  component={renderPhones}
                  change={change}
                  classes={classes}
                />
              </Grid>
              {isUnderAge && (
                <Grid container>
                  <Grid item xs={12}>
                    <Field
                      name="responsible_party"
                      component={ResponsiblePartyForm}
                      disabled={disableForm}
                      validate={[validateContact]}
                    />
                  </Grid>
                </Grid>
              )}
              <ConfirmationPanel
                handleCancel={() => this.handleCancel(true)}
                disableSubmit={submitting || isMrnDuplicated || pristine}
              />
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { patient, filters, selectedTaskType } = state;
  const formValues = getFormValues(ADD_PATIENT_FORM)(state);
  const initialValues = {
    preferred_address_index: 0,
    preferred_email_index: 0,
    preferred_phone_index: 0,
    is_declined_to_provide_addresses: 0,
    email_options: null,
    is_declined_to_provide_phones: 0,
  };
  return {
    patient,
    filters,
    form: ADD_PATIENT_FORM,
    enableReinitialize: true,
    initialValues,
    formValues,
    selectedTaskType,
  };
}

export default compose(
  withLastLocation,
  withStyles(styles),
  connect(mapStateToProps, {
    addPatient,
    updatePatientPreferences,
    createNotesNewPatient,
    fetchPatient,
    updateWorkList,
    selectPatientTab,
    push,
    goBack,
  }),
)(reduxForm({})(CreatePatientForm));

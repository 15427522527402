import { Action } from 'redux';
import { AxiosResponse } from 'axios';

import { FETCH_DOSES_REGIMEN_REASONS } from '../constants';
import HTTP from '../services/http';
import { IDosesRegimenReason } from '../interfaces/doses-regimen-reason/IDosesRegimenReason';

class FetchDosesRegimenReasonsAction implements Action {
  readonly type = FETCH_DOSES_REGIMEN_REASONS;

  constructor(public payload: AxiosResponse<IDosesRegimenReason[]>) {}
}

export type DosesRegimenReasonAction =
  | FetchDosesRegimenReasonsAction;

export const fetchDosesRegimenReasons = (): DosesRegimenReasonAction => {
  const request = HTTP.get(
    '/doses_regimen/reasons',
    {},
  );

  return {
    type: FETCH_DOSES_REGIMEN_REASONS,
    payload: request as any,
  };
};

import createStyles from '@mui/styles/createStyles';

const styles = theme => {
  return createStyles({
    paragraph: {
      margin: 0,
    },
    title: {
      marginTop: 25,
      marginBottom: 25,
      fontSize: 18,
      fontWeight: 'bold',
      [`${theme.breakpoints.down('xl')} and (orientation: landscape)`]: {
        marginTop: 12,
        marginBottom: 12,
      },
    },
    subTitle: {
      marginTop: 25,
      marginBottom: 25,
      fontSize: 16,
      [`${theme.breakpoints.down('xl')} and (orientation: landscape)`]: {
        marginTop: 10,
        marginBottom: 10,
      },
    },
    secondaryColor: {
      color: theme.palette.primary.teal,
    },
    text: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 100,
    },
    subText: {
      fontSize: 14,
      lineHeight: 1.5,
      fontWeight: 100,
      whiteSpace: 'nowrap',
    },
  });
};

export { styles };

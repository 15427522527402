export const createAddrValidationStringFromAVComponents = (inputObject: any) => {
  const validated_addr = `${inputObject.street_number ? `${inputObject.street_number} ` : ''}${
    inputObject.route
  }${inputObject.subpremise ? `, ${inputObject.subpremise}` : ''}, ${inputObject.locality}, ${
    inputObject.administrative_area_level_1
  }, ${inputObject.postal_code}${
    inputObject.postal_code_suffix
      ? `${inputObject.postal_code_suffix ? `-${inputObject.postal_code_suffix}` : ''}`
      : ''
  }`.toLowerCase();

  return validated_addr;
};

export const createAddressObjectValueFromAVComponents = (inputObject: any) => {
  const value: any = {};
  value.line1 = `${inputObject.street_number ? `${inputObject.street_number} ` : ''}${
    inputObject.route
  }`;
  if (inputObject.subpremise) {
    value.line2 = inputObject.subpremise;
  }
  value.city = inputObject.locality;
  value.state = inputObject.administrative_area_level_1;
  value.zip = `${inputObject.postal_code}${
    inputObject.postal_code_suffix ? `-${inputObject.postal_code_suffix}` : ''
  }`;

  return value;
};

export const createAddrValidationStringFromAddressObject = (addrObj: any) => {
  const str_addr = `${addrObj.line1}${addrObj.line2 ? `, ${addrObj.line2}` : ''}, ${
    addrObj.city
  }, ${addrObj.state}, ${addrObj.zip}`.toLowerCase();
  return str_addr;
};

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Grid, Typography, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { LargeSpacer } from 'components/spacer/spacer';
import InfiniteScroller from 'components/infinite-scroller/infinite-scroller';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { loadSchedules, resetSchedules } from 'actions/action-schedules';
import { Route } from 'react-router-dom';
import { setSidebarFilter } from 'actions/action-sidebar-filters';
import { getNewARUrl, getScheduleUrl } from 'helpers/router';
import { push } from 'connected-react-router';
import { convertToArborDate } from 'models/time/arbor-date';
import {
  useTable,
  useSortBy,
  useBlockLayout,
  useResizeColumns,
  usePagination,
} from 'react-table-v7';
import { dateSorting } from 'helpers/react-table';
import classnames from 'classnames';
import ScheduleFilterPanel from './schedule-filter-panel';
import { styles } from './schedule-list-styles';

const DEFAULT_INITIAL_PAGE_SIZE = 20;

const normalizeSchedule = schedule =>
  schedule.map(item => ({
    ...item,
    start_dt: convertToArborDate(item.start_dt, true).getCustomerDatetime(
      true,
      'YYYY-MM-DD h:mm A',
    ),
    dob: item.dob ? convertToArborDate(item.dob, true).getUtcDate(true) : null,
  }));

function ScheduleList(props) {
  const {
    loadSchedules, // eslint-disable-line
    resetSchedules, // eslint-disable-line
    classes,
    schedules,
    push, // eslint-disable-line
  } = props;
  const [sortedColumn, setSortedColumn] = React.useState({ column: null, asc: null });

  const columns = [
    {
      Header: 'Appointment Date',
      id: 'start_dt',
      minWidth: 165,
      accessor: d => convertToArborDate(d.updated).getCustomerDatetime(),
      sortMethod: dateSorting,
    },
    {
      Header: 'MRN',
      accessor: 'mrn',
      minWidth: 100,
    },
    {
      Header: 'Patient',
      accessor: 'patient',
      minWidth: 150,
      getHeaderProps: () => ({
        id: 'schedule_column_patient',
      }),
    },
    {
      Header: 'DOB',
      accessor: 'dob',
      minWidth: 100,
    },
    {
      Header: 'Physician',
      accessor: 'physician',
      minWidth: 200,
    },
    {
      Header: 'Appointment Reason',
      accessor: 'reason',
      minWidth: 150,
    },
    {
      Header: 'Clinic',
      accessor: 'clinic',
      minWidth: 150,
      getHeaderProps: () => ({
        id: 'schedule_column_clinic',
      }),
    },
    {
      Header: 'Status',
      accessor: 'status',
      minWidth: 100,
      getHeaderProps: () => ({
        id: 'schedule_column_status',
      }),
    },
    {
      Header: 'Active Insurance',
      accessor: 'insurance',
      minWidth: 150,
      sortable: false,
    },
    {
      Header: 'Targeted therapy',
      accessor: 'drugs',
      minWidth: 150,
      style: { whiteSpace: 'unset' },
      sortable: false,
    },
    {
      Header: 'Active therapy',
      accessor: 'therapies',
      minWidth: 150,
      style: { whiteSpace: 'unset' },
      sortable: false,
    },
    {
      Header: 'Patient Status',
      accessor: 'patient_status',
      minWidth: 150,
    },
    {
      Header: 'Active Clinics',
      accessor: 'clinic_status',
      minWidth: 150,
      style: { whiteSpace: 'unset' },
      sortable: false,
    },
    {
      Header: '',
      id: 'actionBtn',
      minWidth: 250,
      style: { whiteSpace: 'unset' },
      sortable: false,
      accessor: 'actionBtn',
    },
  ];

  const pageSize = Math.max(DEFAULT_INITIAL_PAGE_SIZE, schedules.schedules.length);

  useEffect(() => {
    loadSchedules({ paging: false, pageSize });
  }, [loadSchedules]);

  const [orderBy, setOrderBy] = useState();
  const [orderDirection, setOrderDirection] = useState();

  const getMappedColumnId = columnText => {
    const mappedColumns = [
      { name: 'Appointment Date', id: 'start_dt' },
      { name: 'Appointment Reason', id: 'reason' },
      { name: 'Active Insurance', id: 'insurance' },
      { name: 'Targeted therapy', id: 'drugs' },
      { name: 'Active therapy', id: 'therapies' },
      { name: 'Patient Status', id: 'patient_status' },
      { name: 'Active Clinics', id: 'clinic_status' },
    ];
    const columnId = mappedColumns.find(t => t.name.toLowerCase() === columnText);
    if (columnId && Object.keys(columnId).length) {
      return columnId.id;
    }
    return columnText;
  };

  const sortData = columnToSort => {
    if (sortedColumn.column !== null && sortedColumn.asc !== null) {
      if (sortedColumn.column === getMappedColumnId(columnToSort.target.innerText.toLowerCase())) {
        setSortedColumn(sortedColumn => {
          return {
            ...sortedColumn,
            asc: !sortedColumn.asc,
          };
        });
      } else {
        setSortedColumn({
          column: getMappedColumnId(columnToSort.target.innerText.toLowerCase()),
          asc: true,
        });
      }
    } else {
      setSortedColumn({
        column: getMappedColumnId(columnToSort.target.innerText.toLowerCase()),
        asc: true,
      });
    }
  };

  const getData = tempData => {
    const dataToProcess = tempData.reduce((acc, item) => {
      if (!acc.map(t => t.id).includes(item.id)) {
        acc.push(item);
      }
      return acc;
    }, []);
    if (sortedColumn.column !== null && sortedColumn.asc !== null) {
      return sortedColumn.column && sortedColumn.asc === true
        ? dataToProcess.sort((a, b) =>
            a[`${sortedColumn.column}`] > b[`${sortedColumn.column}`] ? 1 : -1,
          )
        : dataToProcess.sort((a, b) =>
            a[`${sortedColumn.column}`] < b[`${sortedColumn.column}`] ? 1 : -1,
          );
    }
    return dataToProcess;
  };

  const getDisplayName = field =>
    typeof field === 'string' &&
    field
      .toLowerCase()
      .split('_')
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');

  const ReactTableComp = ({ columns, data }) => {
    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, row, page } = useTable(
      {
        columns,
        data,
      },
      useSortBy,
      usePagination,
      useBlockLayout,
      useResizeColumns,
    );
    const dataToUse = getData(data);
    return (
      <Grid className={classes.scheduleListTable}>
        <Grid className={classnames(classes.scheduleListTableContainer, undefined)}>
          <table className={classes.scheduleListTableMain} {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()} style={{ minWidth: '100%' }}>
                  {headerGroup.headers.map(column => (
                    <th
                      className={[
                        classes.scheduleListHeaderTh,
                        `resizer ${column.isResizing ? 'isResizing' : ''}`,
                      ]}
                      {...column.getResizerProps()}
                    >
                      {column.render('Header') && (
                        <tr
                          className={classes.scheduleListHeaderTr}
                          onClick={column => sortData(column)}
                        >
                          <th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            className={classes.scheduleListHeaderTh}
                          >
                            {column.render('Header')}
                          </th>
                        </tr>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className={classes.scheduleListMainTbody}>
              {dataToUse &&
                dataToUse.length > 0 &&
                dataToUse.map((row, i) => {
                  return (
                    <tr>
                      <td className={classes.scheduleListTbodyTr}>{row.start_dt}</td>
                      <td className={classes.scheduleListTbodyTr}>{row.mrn}</td>
                      <td className={classes.scheduleListTbodyTr}>{row.patient}</td>
                      <td className={classes.scheduleListTbodyTr}>{row.dob}</td>
                      <td className={classes.scheduleListTbodyTr}>{row.physician}</td>
                      <td className={classes.scheduleListTbodyTr}>{row.reason}</td>
                      <td className={classes.scheduleListTbodyTr}>{row.clinic}</td>
                      <td className={classes.scheduleListTbodyTr}>{row.status}</td>
                      <td className={classes.scheduleListTbodyTr}>{row.insurance}</td>
                      <td className={classes.scheduleListTbodyTr}>{row.drugs}</td>
                      <td className={classes.scheduleListTbodyTr}>{row.therapies}</td>
                      <td className={classes.scheduleListTbodyTr}>{row.patient_status}</td>
                      <td className={classes.scheduleListTbodyTr}>{row.clinic_status}</td>
                      <td className={classes.scheduleListTbodyTr}>
                        <Button
                          className={classes.scheduleButton}
                          onClick={() => push(getScheduleUrl(row.patient_id, row.id))}
                        >
                          Schedule
                        </Button>
                      </td>
                      <td>
                        <Button
                          className={classes.addArButton}
                          disabled={row.on_going_ar_id !== null}
                          onClick={() => push(getNewARUrl(row.patient_id, row.id))}
                        >
                          AR+
                        </Button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </Grid>
      </Grid>
    );
  };

  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      <LargeSpacer />
      <Grid container spacing={7}>
        <Grid item xs={12}>
          <Typography className={classes.scheduleListTitle}>Schedule</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={7}>
        <Grid item xs={12}>
          <Route component={ScheduleFilterPanel} />
        </Grid>
        <Grid item xs={12} className={classes.tableContainer}>
          <InfiniteScroller
            onScrollToBottom={() => {
              loadSchedules({ paging: true, pageSize, orderBy, orderDirection });
            }}
            isLoading={schedules.isLoading}
            windowLevel
          >
            <ReactTableComp columns={columns} data={normalizeSchedule(schedules.schedules)} />
          </InfiniteScroller>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = state => {
  const { schedules } = state;
  return {
    schedules,
  };
};

const mapDispatchToProps = dispatch => ({
  setSidebarFilter: (filterType, props) => dispatch(setSidebarFilter(filterType, props)),
  loadSchedules: paging => dispatch(loadSchedules(paging)),
  resetSchedules: () => dispatch(resetSchedules()),
  push: url => dispatch(push(url)),
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(ScheduleList);

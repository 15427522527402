import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
  getDispensingIcon,
  getClinicalSupportIcon,
  EditPencilMd,
  CircleIndicatorIcon,
} from 'components/icons/icons';
import { SERVICE_ENROLLMENT_SHOULD_BE_SET } from 'constants/index';
import { auditContext } from 'constants/lists';
import Audit from 'components/display/audit';

import { getTherapyReasonById } from 'services/utils/therapy-service';
import { styles } from './therapy-detail.styles';

const TherapyEnrollment = props => {
  const {
    classes,
    theme,
    dispensingStatus,
    dispensingStatusId,
    dispensingStatusReason,
    getDispensingOptOutReasons,
    clinicalSupportStatus,
    clinicalSupportStatusId,
    clinicalSupportStatusReason,
    getClinicalSupportOptOutReasons,
    toggleEditEnrollService,
    enrollmentStatuses,
    isSpecialty,
    index,
    therapy,
  } = props;
  let dispensingStatusDisplay = dispensingStatus;
  let clinicalSupportStatusDisplay = clinicalSupportStatus;
  const dispensingReason = getTherapyReasonById(enrollmentStatuses, dispensingStatusId);
  const clinicalSupportReason = getTherapyReasonById(enrollmentStatuses, clinicalSupportStatusId);
  if (dispensingReason) {
    dispensingStatusDisplay += `: ${dispensingReason}`;
  }
  if (clinicalSupportReason) {
    clinicalSupportStatusDisplay += `: ${clinicalSupportReason}`;
  }

  return (
    <>
      <Grid container>
        <Grid item>
          <Grid container alignItems="center">
            <Typography className={classes.statusTitle} variant="h6">
              Service Enrollment
            </Typography>
            <Audit
              iconType="warning"
              auditRules={[SERVICE_ENROLLMENT_SHOULD_BE_SET]}
              context={auditContext.Warning}
              resource="therapy_id"
              resourceValue={therapy.id}
            />
          </Grid>
        </Grid>
        <Grid item xs className={classes.actionIconContainer}>
          <Typography variant="caption">
            <Button
              onClick={toggleEditEnrollService}
              name="edit_service_button"
              className={classes.toggleEdit}
            >
              <EditPencilMd id={`editServiceEnrollmentPencil_${index}`} />
            </Button>
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Grid container spacing={1}>
            <Grid item xs={2} className={classes.iconContainer}>
              <CircleIndicatorIcon
                size={20}
                color={theme.palette.map.therapyEnrollment[dispensingStatus]}
              />
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="caption" className={classes.enrollmentTitle}>
                    Dispensing
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="caption"
                    className={classes.enrollmentDetail}
                    id={`serviceEnrollmentDispensingStatus_${index}`}
                  >
                    {dispensingStatus === 'Opt out' && dispensingStatusReason
                      ? getDispensingOptOutReasons(dispensingStatusReason)
                      : dispensingStatusDisplay}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} className={classes.imageContainer}>
              {getDispensingIcon(dispensingStatusId)}
            </Grid>
          </Grid>
        </Grid>
        {isSpecialty && (
          <Grid item xs={6}>
            <Grid container spacing={3}>
              <Grid item xs={2} className={classes.iconContainer}>
                <CircleIndicatorIcon
                  size={20}
                  color={theme.palette.map.therapyEnrollment[clinicalSupportStatus]}
                />
              </Grid>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="caption" className={classes.enrollmentTitle}>
                      Clinical Support
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="caption"
                      className={classes.enrollmentDetail}
                      id={`serviceEnrollmentClinicalSupport_${index}`}
                    >
                      {clinicalSupportStatus === 'Opt out' && clinicalSupportStatusReason
                        ? getClinicalSupportOptOutReasons(clinicalSupportStatusReason)
                        : clinicalSupportStatusDisplay}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2} className={classes.imageContainer}>
                {getClinicalSupportIcon(clinicalSupportStatusId)}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(TherapyEnrollment);

/* eslint-disable @typescript-eslint/naming-convention */
import { TypedHttp as Http } from 'services/typed-http';
import {
  IAddPaymentMethodPayload,
  IAddPaymentMethodResponse,
  IDeletePaymentMethodsResponse,
  IFetchPaymentMethodsResponse,
} from './types';

export async function getPaymentMethods(patientId: number, paymentMethodIds?: number[]) {
  let query = '';
  if (paymentMethodIds && paymentMethodIds.length) {
    query = `?ids=${paymentMethodIds.join(',')}`;
  }
  return Http.GetPromise<IFetchPaymentMethodsResponse>(`/patients/${patientId}/payment-methods${query}`);
}

export async function postPaymentMethod(patientId: number, payload: IAddPaymentMethodPayload) {
  return Http.PostPromise<IAddPaymentMethodPayload, IAddPaymentMethodResponse>(
    `/patients/${patientId}/payment-methods`,
    payload,
  );
}

export async function deletePaymentMethod(patientId: number, paymentMethodId: number, orderId?: number) {
  let url = `/patients/${patientId}/payment-methods/${paymentMethodId}`;
  if (orderId) {
    url += `?order_id=${orderId}`;
  }
  return Http.DeletePromise<IDeletePaymentMethodsResponse>(url);
}

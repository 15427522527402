import { createStyles } from '@mui/styles';

export const styles = theme =>
  createStyles({
    container: {
      overflowY: 'auto',
      [`${theme.breakpoints.down('xl')}`]: {
        scrollSnapType: 'y mandatory',
        scrollBehavior: 'smooth',
      },
      height: '100%',
      width: '100%',
    },
    questionContainer: {
      [`${theme.breakpoints.down('xl')}`]: {
        height: '100%',
        scrollSnapAlign: 'start',
        padding: 0,
      },
      padding: '60px 0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    questionDivider: {
      [`${theme.breakpoints.down('xl')}`]: {
        display: 'none',
      },
      maxWidth: 600,
    },
    questionContent: {
      padding: 24,
    },
    questionContentContainer: {
      height: '100%',
    },
    confirmButton: {
      paddingTop: 18,
      paddingBottom: 18,
      marginTop: 36,
    },
    medicationList: {
      padding: 0,
      fontSize: 13,
      fontWeight: 600,
    },
    boxAnswer: {
      backgroundColor: theme.palette.primary.greySubRow,
      minWidth: 50,
      padding: '6px 16px',
      borderRadius: 200,
      textAlign: 'center',
    },
    boxAnswerWrapper: {
      maxWidth: 'fit-content',
    },
    editButton: {
      textTransform: 'capitalize',
      textDecoration: 'underline',
    },
    required: {
      '&::after': {
        content: '"*"',
        display: 'inline-block',
        color: theme.palette.primary.valenciaRed,
        marginLeft: 4,
      },
    },
    btnContainer: {
      position: 'absolute',
      bottom: '0%',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '100%',
    },
    btnLarge: {
      '&': {
        color: '#000A',
        fontSize: 20,
        textTransform: 'capitalize',
        width: '100%',
        padding: 8,
        marginBottom: 10,
        borderRadius: 25.5,
        backgroundColor: theme.palette.primary.grey2,
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.grey2,
      },
    },
    icon: {
      color: theme.palette.primary.teal,
      fontSize: 28,
    },
    iconWrapper: {
      marginRight: 16,
      minWidth: 'auto',
    },
  });

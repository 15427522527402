import {
  INT,
  INTERVENTION_TYPES,
  TASK_DATA_COLLECT,
  TASK_DRUG_UTILIZATION_REVIEW,
  TASK_INTERVENTIONS,
  TASK_RISK_STRAT,
} from 'constants/index';
import { convertToArborDate } from 'models/time/arbor-date';
import { DataCollectStatus } from 'interfaces/enums/TaskStatuses/DataCollectStatus';
import { RiskStratStatus } from 'interfaces/enums/TaskStatuses/RiskStratStatus';
import providerBuilder from './providers/provider-builder';
import { DC, DUR, RS } from '../../constants';
import { InterventionStatuses } from '../../constants/intervention-statuses';

export const getStatusByJsonId = (providers, jsonId) =>
  providers.statuses.find(s => s.status.id === jsonId); // eslint-disable-line

export const getStatusByStatusId = (providers, statusId) =>
  providers.statuses.find(s => s.id === statusId); // eslint-disable-line

export const changeStatusToNextLogical = tasks =>
  tasks.map(task => {
    const nextStatusId = task.status_id;
    return { ...task, status_id: nextStatusId };
  });

export const getConfigFieldsByLevels = (config, levels) =>
  config.allFields.filter(field => levels.indexOf(field.level) > -1).map(field => field.property); // eslint-disable-line

export const getLevelFields = (taskType, levels) => {
  const providers = providerBuilder(taskType)({ taskType }, {});
  return providers.json.fields
    .filter(field => levels.indexOf(field.level) > -1)
    .map(field => field.property);
};

export const getTaskConfig = taskType => {
  const preProvider = providerBuilder(taskType);
  if (!preProvider) {
    return {};
  }
  const providers = preProvider({ taskType }, {});
  return providers.json.config;
};

export const sortTasksByPriority = tasks => {
  const newList = [...tasks];
  newList.sort((a, b) => a.status_category_id - b.status_category_id);
  return newList;
};

export const combineTasks = (taskType, tasks) => {
  const fieldsToIgnore = ['id', 'therapy_id', 'patient_id', 'order_id'];
  const patientFields = getLevelFields(taskType, ['patient']);
  const therapyFields = getLevelFields(taskType, ['therapy']);
  const sortedTasks = sortTasksByPriority(tasks);
  return sortedTasks.map(task => {
    const allPatientLevelTasks = sortedTasks;
    const allTherapyLevelTasks = sortedTasks.filter(it => it.therapy_id === task.therapy_id);
    return Object.keys(task)
      .filter(it => !fieldsToIgnore.includes(it))
      .reduce(
        (acc, key) => {
          const isPatientLevel = patientFields.includes(key);
          const isTherapyLevel = therapyFields.includes(key);
          if (isPatientLevel) {
            acc[key] = (allPatientLevelTasks.find(
              it => it[key] !== undefined && it[key] !== null,
            ) || {})[key];
          }
          if (isTherapyLevel) {
            acc[key] = (allTherapyLevelTasks.find(
              it => it[key] !== undefined && it[key] !== null,
            ) || {})[key];
          }
          return acc;
        },
        { ...task },
      );
  });
};

export const bundleInteractionsForDur = formsState =>
  Object.keys(formsState)
    .filter(name => /dur_intervention_/.test(name) && !!formsState[name].registeredFields)
    .map(key => ({
      ...formsState[key].values,
    }))
    .map(interventionForm => ({
      ...interventionForm.interaction,
      intervention_checked: interventionForm.intervention_checked,
      intervention: {
        ...interventionForm,
        followup_dt: convertToArborDate(interventionForm.followup_dt).getUtcDatetime(),
        completed_dt: interventionForm.completed_dt
          ? convertToArborDate(interventionForm.completed_date).getUtcDate()
          : null,
        intervention: null,
      },
    }));

export const isDURReviewed = (tasks, durTaskId) => {
  const [durTask] = Object.values(tasks).filter(
    x => x.taskType === TASK_DRUG_UTILIZATION_REVIEW && x.id === durTaskId,
  );
  if (durTask !== undefined) {
    return durTask?.status_id === DurStatus.Reviewed;
  }
  return false;
};

export const isDurInterventionTask = (task, tasks) => {
  const condition =
    task.taskType === INT &&
    task.type_id === 9 &&
    tasks[`${DUR}${task.task_drug_utilization_review_id}`];
  if (task.task_drug_utilization_review_id !== null) {
    return isDURReviewed(tasks, task.id) && condition;
  }
  return condition;
};

export const getIdOfDcOrRsInterventionId = (task, links) => {
  if (task.taskType !== INT || !links) {
    return null;
  }
  const intLink =
    links && links.length
      ? links.find(
          link =>
            link.resource_two_id === Number(task.id) &&
            (link.resource_one_type === TASK_DATA_COLLECT ||
              link.resource_one_type === TASK_RISK_STRAT) &&
            link.resource_two_type === TASK_INTERVENTIONS,
        )
      : null;
  if (intLink) {
    const meta = typeof intLink.meta === 'string' ? JSON.parse(intLink.meta) : intLink.meta;
    if (!meta || !meta.cause) {
      return null;
    }
    return intLink.resource_one_id;
  }
  return null;
};

export const shouldRenderDcInIntervention = (task, links, tasks) => {
  // only these types allow to embed INT in DC
  const isCorrectType = [
    INTERVENTION_TYPES.DDI,
    INTERVENTION_TYPES.ADR,
    INTERVENTION_TYPES.REGIMEN,
    INTERVENTION_TYPES.LAB,
    INTERVENTION_TYPES.DRUG_INFORMATION,
  ].includes(task.type_id);

  if (isCorrectType) {
    // if it is INT and it has an associated DC/RS
    const dcOrRsId = getIdOfDcOrRsInterventionId(task, links);
    if (dcOrRsId) {
      // if DC/RS is completed, do not embed
      const dcOrRsTask =
        links?.find(it => it.resource_one_id === dcOrRsId)?.resource_one_type === TASK_RISK_STRAT
          ? tasks[`${RS}${dcOrRsId}`]
          : tasks[`${DC}${dcOrRsId}`];
      if (
        dcOrRsTask &&
        (dcOrRsTask.status_id === DataCollectStatus.Reviewed ||
          dcOrRsTask.status_id === RiskStratStatus.Accepted)
      ) {
        return false;
      }
      return true;
    }
  }
  return false;
};

export const interventionsHaveRecommendationsAndOutcomesOrUnchecked = forms =>
  forms &&
  forms.length > 0 &&
  forms.every(
    x =>
      !x.intervention_checked ||
      x.intervention ||
      x.intervention.outcome ||
      x.intervention.recommendation,
  );
export const hasSelectedAndOpenIntervention = (tasks, stateTasks) => {
  const taskInterventions = tasks
    .filter(t => t.taskType === DUR)
    .flatMap(t => t.interactions)
    .filter(int => int.intervention !== undefined)
    .map(i => i.intervention);
  const stateTasksFiltered = Object.values(stateTasks).filter(filtered =>
    taskInterventions.every(t => t.id === filtered.id && filtered.taskType === INT),
  );
  return stateTasksFiltered.some(x => x.status_id !== InterventionStatuses.Completed);
};

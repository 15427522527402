import { createStyles } from '@mui/styles';
import colors from 'lib/themes/colors.module.scss';

const { deepBlue, white, teal } = colors;

export const styles = theme =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 12,
      padding: 14,
      color: white,
      fontSize: 16,
      textAlign: 'center',
      backgroundColor: teal,
    },
    progressBar: {
      height: 13,
      width: '100%',
      backgroundColor: '#fff',
      borderRadius: 6.5,
    },
    progress: {
      display: 'block',
      height: 13,
      backgroundColor: deepBlue,
      borderRadius: 6.5,
    },
  });

import React, { FC } from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { buildQaId } from 'utils/build-qa-id';

import { IProps } from './interfaces/IProps';
import { styles } from './small-task-card.styles';

const getQaId = buildQaId('small-task-card');

const SmallTaskCard: FC<IProps> = (props: IProps): JSX.Element => {
  const { classes } = props;

  return (
    <Card
      data-qa-id={getQaId(`${props.id}${props.highlighted ? '-highlighted' : ''}`)}
      variant="outlined"
      className={props.highlighted ? classes.highlighted : classes.root}
    >
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.title}>{props.title}</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.statusLabel}>{props.status}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.dateLabel}>{props.dateLabel}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.date}>{props.date}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(SmallTaskCard);

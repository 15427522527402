import { IAddPaymentMethodModal } from 'interfaces/redux/IAddPaymentMethodModal';

export enum AddPaymentMethodModalActionType {
  SetState = 'ADD_PAYMENT_METHOD_MODAL_STATE',
  ResetModal = 'ADD_PAYMENT_METHOD_MODAL_RESET',
}

export interface IAddPaymentMethodModalAction {
  type: AddPaymentMethodModalActionType;
  payload: IAddPaymentMethodModal;
}

export const addPaymentMethodModalActions = {
  setState: (state: IAddPaymentMethodModal): IAddPaymentMethodModalAction => {
    return {
      type: AddPaymentMethodModalActionType.SetState,
      payload: state,
    };
  },
  resetModal: () => {
    return {
      type: AddPaymentMethodModalActionType.ResetModal,
    };
  },
};

import HTTP from '../services/http';

export class AdministrationStatusHistory {
  static cache: Record<string, object[]> = {};

  static getCacheKey(patientId: number, therapyIds: number[]): string {
    return `${patientId}-therapies-${therapyIds.sort().join('-')}`;
  }

  static invalidateCache(patientId: number, therapyIds: number[]): void {
    const cacheKey = this.getCacheKey(patientId, therapyIds);
    if (this.cache[cacheKey]) delete this.cache[cacheKey];
  }

  static async get(patientId: number, therapyIds: number[]): Promise<any> {
    const cacheKey = this.getCacheKey(patientId, therapyIds);
    if (this.cache[cacheKey]) return Promise.resolve(this.cache[cacheKey]);

    const url = `/patients/${patientId}/administration_status/therapies/history?therapyIds=${therapyIds.join(
      ',',
    )}`;
    const response = await HTTP.get(url);
    this.cache[cacheKey] = response.data;
    return response.data;
  }
}

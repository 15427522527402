import React from 'react';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { IProps } from './interfaces/IProps';
import { styles } from './field-label.styles';
import { buildQaId } from '../../utils/build-qa-id';

const qaIdBuilder = buildQaId('field-label');

const FieldLabel: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { classes } = props;
  const [className, setClassName] = React.useState<string | undefined>('');

  React.useEffect(() => {
    if (props.labelSize) {
      switch (props.labelSize) {
        case 'medium':
          setClassName(classes.fieldLabelMedium);
          break;
        case 'large':
          setClassName(classes.fieldLabelLarge);
          break;
        default:
          setClassName(undefined);
          break;
      }
    } else {
      setClassName(undefined);
    }
  }, [props.labelSize]);

  return (
    <Typography variant="caption" className={className} data-qa-id={qaIdBuilder(props.fieldName)}>
      {props.fieldName}
    </Typography>
  );
};

export default withStyles(styles)(FieldLabel);

import React from 'react';
import { Grid, Button, TextField, InputAdornment } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import cx from 'classnames';
import { useDebounce } from 'use-debounce';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { CtdStyles } from '../index.styles';
import { IncludedTableView } from './included/included-table';
import { ExcludedTableView } from './excluded/excluded-table';
import CustomerDropdown from '../../components/customer-dropdown';
import { SectionTitleStyled } from '../../components/section-title';
import {
  ViewStateOptions,
  searchFieldPlaceholder,
  debounceTimeMs,
  initialSearchTerm,
  renderButtonLabel,
} from '../constants';
import { ReportModalStyled } from '../report-modal/report-modal';

type ICtdProps = WithStyles<typeof CtdStyles>

const CtdView = (props: ICtdProps): JSX.Element => {
  const { classes } = props;

  // #region useState
  const [whichView, setWhichView] = React.useState<ViewStateOptions>(ViewStateOptions.Included);
  const [searchTerm, setSearchTerm] = React.useState<string>(initialSearchTerm);
  const [debouncedSearchTerm] = useDebounce<string | undefined>(searchTerm, debounceTimeMs);
  const [showReportModal, setShowReportModal] = React.useState<boolean>(false);
  const stateSelectedCustomerId = useTypedSelector(state => state.filters.selectedCustomerId);
  const [selectedCustomerId, setSelectedCustomerId] =
    React.useState<number>(stateSelectedCustomerId);
  const customers = useTypedSelector(state => state.filters.customers);
  // #endregion

  // #region effects
  React.useEffect(() => {
    if (selectedCustomerId == null) {
      if (stateSelectedCustomerId != null) {
        setSelectedCustomerId(stateSelectedCustomerId);
      } else if (customers.length > 0) {
        setSelectedCustomerId(customers[0].id);
      }
    }
  }, [stateSelectedCustomerId]);

  // #endregion

  // #region renders
  const renderBottomComponent = (): JSX.Element | null => {
    const componentDict: Record<ViewStateOptions, JSX.Element | null> = {
      [ViewStateOptions.Included]: (
        <IncludedTableView
          searchTerm={debouncedSearchTerm}
          selectedCustomerId={selectedCustomerId}
        />
      ),
      [ViewStateOptions.Excluded]: (
        <>
          <ExcludedTableView
            searchTerm={debouncedSearchTerm}
            selectedCustomerId={selectedCustomerId}
          />
        </>
      ),
    };

    return componentDict[whichView];
  };

  const renderReportButton = (): JSX.Element => {
    return (
      <Button
        variant="contained"
        color="primary"
        className={classes.reportButton}
        onClick={() => setShowReportModal(!showReportModal)}
      >
        Reports
      </Button>
    );
  };
  // #endregion

  return (
    <>
      <SectionTitleStyled
        title="View Targeted Drugs"
        rightSideItems={[
          {
            element: renderReportButton(),
            key: 'report-button',
          },
        ]}
      />
      <ReportModalStyled
        open={showReportModal}
        close={() => setShowReportModal(false)}
        customerId={selectedCustomerId}
      />
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid item xs="auto">
              <Grid container spacing={1} data-qa-id="container 2">
                {/* Left Side - Buttons */}
                <Grid item xs="auto">
                  <CustomerDropdown
                    selectedCustomerId={selectedCustomerId}
                    setSelectedCustomerId={customerId => setSelectedCustomerId(customerId)}
                  />
                </Grid>
                <Grid item xs="auto" className={classes.buttonGrid}>
                  {Object.keys(ViewStateOptions).map(key => {
                    return (
                      <Button
                        className={cx(classes.button, {
                          [classes.selectedButton]: whichView === (key as ViewStateOptions),
                        })}
                        variant="outlined"
                        onClick={() => {
                          setWhichView(key as ViewStateOptions);
                        }}
                      >
                        {renderButtonLabel(key as ViewStateOptions)}
                      </Button>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
            {/* Right Side - Search Field */}
            <Grid item xs="auto">
              <TextField
                variant="standard"
                placeholder={searchFieldPlaceholder}
                onChange={event => {
                  setSearchTerm(event.target.value);
                }}
                value={searchTerm}
                className={classes.searchField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                  endAdornment:
                    searchTerm != null && searchTerm !== initialSearchTerm ? (
                      <InputAdornment
                        position="end"
                        onClick={() => {
                          setSearchTerm(initialSearchTerm);
                        }}
                        className={classes.textFieldEndAdornment}
                      >
                        <CloseIcon fontSize="small" />
                      </InputAdornment>
                    ) : null,
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Below the buttons */}
        <Grid item>{renderBottomComponent()}</Grid>
      </Grid>
    </>
  );
};

export default withStyles(CtdStyles)(CtdView);

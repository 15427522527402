import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

const styles = (theme: Theme) => {
  const { palette, font } = expandTheme(theme);
  return createStyles({
    closeIcon: {
      cursor: 'pointer',
      float: 'right',
      marginTop: theme.spacing(0.5),
    },
    title: {
      fontSize: font.mediumFontSize,
    },
    root: {
      'padding': theme.spacing(2),
      '&:hover': {
        backgroundColor: palette.primary.grey14,
      },
    },
  });
};

export { styles };

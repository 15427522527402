import { BaseFieldProps, Field, GenericFieldHTMLAttributes } from 'redux-form';

/**
 * https://schneidenbach.gitbooks.io/typescript-cookbook/nameof-operator.html
 * @param name name of the property to get
 */
export const nameOfFactory =
  <T>() =>
  (name: keyof T) =>
    name;

export const nameOfFieldArrayFactory =
  <TField, TAttribute>() =>
  (field: keyof TField, attribute: keyof TAttribute, index: number) => {
    return `${String(field)}[${index}].${String(attribute)}`;
  };

export type Modify<T, R> = Omit<T, keyof R> & R;

/**
 * https://stackoverflow.com/questions/61132262/typescript-deep-partial
 */
export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

// Allow to modify any interface type to make all fields nullables.
export type Nullable<T> = { [K in keyof T]: T[K] | null };

// eslint-disable-next-line @typescript-eslint/naming-convention
export function TypedField<T = {}, P = { [k: string]: any }>(): React.ComponentType<
  & GenericFieldHTMLAttributes
  & BaseFieldProps
  & { name: keyof T; label?: string; }
  & P
> {
  return Field;
}

import Moment, { Moment as moment } from 'moment';
import ExpansionSection from 'components/expansion-section';
import React, { FC, useState, useMemo, useEffect } from 'react';
import { convertToArborDate } from 'models/time/arbor-date';
import { getActionedByUserText } from 'utils/user-utils';
import { getUserById } from 'services/utils/users-service';
import {
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { fetchArchivedTasksForTaskTabv2 } from 'actions/action-tasks';
import { ITherapy } from 'interfaces/redux/ITherapy';
import { ReactSelect } from 'components/form/field/react-select';
import { CATEGORY_ARCHIVED, DC, CLEAR_ARCHIVED_TASKS, RS } from 'constants/index';
import withStyles from '@mui/styles/withStyles';
import { getClinicalDataTypesMap } from 'services/utils/data-collect';

import { IDcSectionProps, IDcSectionData, IDCFetchFilterItem } from '../interfaces/IDcSection';
import { usePatientReviewDcs } from '../../hooks/use-patient-review-dcs';
import { styles } from '../sections.styles';
import { useTypedSelector } from '../../../../../../../hooks/use-typed-selector';

const DcSection: FC<IDcSectionProps> = (props: IDcSectionProps): JSX.Element => {
  const {
    dataCollects,
    classes,
    taskQty,
    currentSelectedTasks,
    sendDataCollectsToParent,
    displayFilterOnly,
    useLimitedTherapyList,
    includeRS,
  } = props;
  const users = useTypedSelector(state => state.lookups.users);
  const tasks = useTypedSelector(state => state.tasks.data);
  const patient = useTypedSelector(state => state.patient);
  const patientId = patient ? patient.id : null;
  const [filteredTherapies, setFilteredTherapies] = useState<IDCFetchFilterItem[]>([]);
  const [filteredClinicalItems, setFilteredClinicalItems] = useState<IDCFetchFilterItem[]>([]);
  const [dcDateAgo, setDCDateAgo] = useState<IDCFetchFilterItem | null>(null);
  const [shouldLoadArchivedDCTasks, setShouldLoadArchivedDCTasks] = useState<boolean>(false);
  const [archivedDCsLoaded, setArchivedDCsLoaded] = useState<boolean>(false);

  let therapiesData = useTypedSelector(state => state?.therapies?.data);
  therapiesData = therapiesData || {};
  const therapies = useMemo(() => Object.values(therapiesData), [therapiesData]);
  const allTherapiesArr = therapies
    ? therapies.map((therapy: ITherapy) => {
        const therapyDosage =
          therapy && therapy.strength && therapy.strength_unit_of_measure
            ? `- ${therapy.strength} ${therapy.strength_unit_of_measure}`
            : '';
        const isArchivedText =
          therapy.administration_status_category_id === CATEGORY_ARCHIVED ? ' - (Archived)' : '';
        const therapyNameFull = `${therapy.drug_name} ${therapyDosage || ''}${isArchivedText}`;
        return {
          value: therapy.id,
          label: therapyNameFull,
        };
      })
    : [];
  const limitedTherapyList =
    currentSelectedTasks && currentSelectedTasks.length > 0
      ? currentSelectedTasks.map(t => t.therapy_id)
      : [];
  let clinicalDataTypes = useTypedSelector(state => state?.lookups?.clinicalDataTypes);
  clinicalDataTypes = clinicalDataTypes || {};
  const clinicalDataTypesMap = Object.values(getClinicalDataTypesMap(clinicalDataTypes)).map(
    (clinicalDataType: any) => ({
      label: clinicalDataType.name,
      value: Number(clinicalDataType.dataTypeId),
    }),
  );

  // @ts-ignore
  const filteredDataCollectsArr = usePatientReviewDcs(taskQty, currentSelectedTasks, {
    filteredClinicalItems: filteredClinicalItems,
    filteredTherapies: filteredTherapies,
    dcDateAgo: dcDateAgo,
    includeRS,
  });

  const dateAgoArr = [
    { label: '1 Month', value: Moment.utc().add(-1, 'months').startOf('day') },
    { label: '3 Month', value: Moment.utc().add(-3, 'months').startOf('day') },
    { label: '6 Month', value: Moment.utc().add(-6, 'months').startOf('day') },
    { label: '1 Year', value: Moment.utc().add(-12, 'months').startOf('day') },
  ];

  const dispatch = useDispatch();

  const getArchivedDCs = () => {
    dispatch({ type: CLEAR_ARCHIVED_TASKS });
    const allTherapyIds = therapies.map((therapy: ITherapy) => therapy.id);
    const filterTherapyIds =
      filteredTherapies.length > 0
        ? filteredTherapies.map((therapy: IDCFetchFilterItem) => therapy.value)
        : null;
    const therapyIdsForFetch = filterTherapyIds || allTherapyIds;
    fetchArchivedTasksForTaskTabv2(
      patientId,
      therapyIdsForFetch,
      [],
      [
        { label: DC, value: DC },
        { label: RS, value: RS },
      ],
    ).then(results => {
      dispatch(results);
    });
    if (
      archivedDCsLoaded &&
      !filterTherapyIds &&
      filteredClinicalItems.length === 0 &&
      !dcDateAgo
    ) {
      setArchivedDCsLoaded(false);
    } else {
      setArchivedDCsLoaded(true);
    }
    setShouldLoadArchivedDCTasks(false);
  };

  const handleTherapyFilterChange = (value: IDCFetchFilterItem[] | undefined) => {
    setFilteredTherapies(value || []);
  };

  const handleClinicalDataTypesFilterChange = (value: IDCFetchFilterItem[] | undefined) => {
    setFilteredClinicalItems(value || []);
  };

  const handleDCDateAgoFilterChange = (value: IDCFetchFilterItem | null) => {
    setDCDateAgo(value || null);
  };

  useEffect(() => {
    // first time one of these filters is changed, trigger the loading of archived DC tasks
    const filterTouched =
      dcDateAgo || filteredTherapies.length > 0 || filteredClinicalItems.length > 0;
    const retTherapyArr = limitedTherapyList
      ? allTherapiesArr.filter(allTherapiesTherapy =>
          limitedTherapyList.some(t => t === allTherapiesTherapy.value),
        )
      : filteredDataCollectsArr;
    if (filterTouched && !shouldLoadArchivedDCTasks && !archivedDCsLoaded) {
      setShouldLoadArchivedDCTasks(true);
    }
    if (filterTouched && sendDataCollectsToParent) {
      sendDataCollectsToParent(filteredDataCollectsArr);
    }
    if (!filterTouched && sendDataCollectsToParent && retTherapyArr.length > 0) {
      sendDataCollectsToParent(
        filteredDataCollectsArr.filter(filteredDataCollect =>
          limitedTherapyList.some(
            limitedTherapyId => filteredDataCollect.task.therapy_id === limitedTherapyId,
          ),
        ),
      );
    }
  }, [dcDateAgo, filteredClinicalItems, filteredTherapies]);

  useEffect(() => {
    // first time one of these filters is changed, trigger the loading of archived DC tasks
    if (archivedDCsLoaded) {
      setShouldLoadArchivedDCTasks(true);
    }
  }, [filteredTherapies]);

  useEffect(() => {
    if (shouldLoadArchivedDCTasks) {
      getArchivedDCs();
    }
  }, [shouldLoadArchivedDCTasks]);

  const outerSectionClasses = {
    header: classes.sectionWrapperHeader,
    expandIcon: classes.sectionWrapperExpandIcon,
    subtitle: classes.sectionWrapperSubtitle,
    expansionPanel: classes.sectionWrapperExpansionPanel,
  };

  const outerSectionClassesNoPadding = {
    ...outerSectionClasses,
    content: classes.sectionWrapperContent,
  };

  const innerSectionClasses = {
    header: classes.innerSectionHeader,
    expansionPanel: classes.innerSectionExpansionPanel,
    subtitle: classes.innerSectionSubtitle,
  };

  const getDCWrapperSubtitle = (lastUpdateDcTaskDate: string, lastUpdatedDcBy: string) =>
    `Updated ${lastUpdateDcTaskDate} by ${lastUpdatedDcBy}`;

  const [mostRecentUpdatedTask] = dataCollects
    .map(dataCollect => dataCollect.task)
    .sort(
      (firstTask, secondTask) =>
        new Date(secondTask?.updated ?? '')?.getTime() -
        new Date(firstTask?.updated ?? '')?.getTime(),
    );

  const taskLastUpdatedUser = getUserById(mostRecentUpdatedTask?.updated_by, users)?.username ?? '';

  const taskLastUpdatedDate = convertToArborDate(mostRecentUpdatedTask?.updated ?? '')
    ? convertToArborDate(mostRecentUpdatedTask?.updated ?? '')?.getCustomerDatetime(true) ?? ''
    : '';

  const therapyFilterFieldsList = () => {
    if (useLimitedTherapyList) {
      return allTherapiesArr.filter(allTherapiesTherapy =>
        limitedTherapyList.some(t => t === allTherapiesTherapy.value),
      );
    }
    return allTherapiesArr;
  };

  const renderDataCollectsSection = (dataCollectsVar: IDcSectionData[], usingFilters: boolean) => {
    return (
      <>
        {dataCollectsVar.length ? (
          dataCollectsVar.map((dataCollect: IDcSectionData, index: number) => (
            <Grid key={index} item xs={12}>
              <ExpansionSection
                title={`${dataCollect.therapyDrugName} ${
                  dataCollect.lastCompletedDate
                    ? ` - Completed ${dataCollect.lastCompletedDate}`
                    : ''
                }`}
                subtitle={
                  dataCollect.lastVerifiedDate
                    ? getActionedByUserText({
                        action: 'Verified',
                        user: dataCollect.lastUpdatedUser,
                        date: dataCollect.lastVerifiedDate,
                      })
                    : ''
                }
                classes={innerSectionClasses}
              >
                {dataCollect.collectedItems.length ? (
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Data Type</TableCell>
                          <TableCell align="center">Required?</TableCell>
                          <TableCell align="center">Assessment Date</TableCell>
                          <TableCell align="center">Value</TableCell>
                          <TableCell align="center">Reference</TableCell>
                          <TableCell align="center">Not Able to Complete</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataCollect.collectedItems.map((item, key) => (
                          <TableRow key={key}>
                            <TableCell component="th" scope="row">
                              {item.dataTypeName}
                            </TableCell>
                            <TableCell align="center">{item.required}</TableCell>
                            <TableCell align="center">{item.assessmentDate}</TableCell>
                            <TableCell align="center">{item.value}</TableCell>
                            <TableCell align="center">{item.reference}</TableCell>
                            <TableCell align="center">{item.cannotCompleteReason}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  'No collected items for this therapy.'
                )}
              </ExpansionSection>
            </Grid>
          ))
        ) : (
          <>{usingFilters ? <></> : <div>Data Collect information not available yet.</div>}</>
        )}
      </>
    );
  };

  return (
    <ExpansionSection
      title="Data Collect"
      subtitle={getDCWrapperSubtitle(taskLastUpdatedDate, taskLastUpdatedUser)}
      classes={outerSectionClassesNoPadding}
      defaultExpanded={displayFilterOnly}
    >
      {!displayFilterOnly && (
        <Grid container spacing={1}>
          {renderDataCollectsSection(dataCollects, false)}
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 'bold' }}>Load Additional DC</Typography>
          </Grid>
          <Grid item xs={6}>
            <ReactSelect
              data-qa-id="csl_dc_task_therapy_select"
              defaultValues={null}
              label="Therapy"
              value={filteredTherapies}
              handleOnChange={(value: IDCFetchFilterItem[]) => handleTherapyFilterChange(value)}
              fields={therapyFilterFieldsList()}
            />
          </Grid>
          <Grid item xs={6}>
            <ReactSelect
              data-qa-id="csl_dc_task_clinical_items_select"
              defaultValues={null}
              label="Clinical Items"
              value={filteredClinicalItems}
              handleOnChange={(value: IDCFetchFilterItem[]) =>
                handleClinicalDataTypesFilterChange(value)
              }
              fields={clinicalDataTypesMap}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <ReactSelect
            data-qa-id="csl_dc_task_dates_select"
            defaultValues={null}
            label="Display Reviewed DCs from the last"
            value={dcDateAgo}
            handleOnChange={(value: IDCFetchFilterItem) => handleDCDateAgoFilterChange(value)}
            fields={dateAgoArr}
            isMulti={false}
          />
        </Grid>
      </Grid>
      {!displayFilterOnly && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {renderDataCollectsSection(
              filteredTherapies.length > 0 || filteredClinicalItems.length > 0 || dcDateAgo
                ? filteredDataCollectsArr
                : [],
              true,
            )}
          </Grid>
        </Grid>
      )}
    </ExpansionSection>
  );
};

export default withStyles(styles)(DcSection);

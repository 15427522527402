import React, { Component, Fragment } from 'react';
import {
  Button,
  Divider,
  Grid,
  Typography,
  Paper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import compose from 'recompose/compose';
import { CheckmarkIcon } from 'components/icons/icons';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { OR, INT, QRE, AR } from 'constants/index';
import { getDisplayNotes, getNoteTagId } from 'services/utils/note-service';
import { styles } from 'containers/tasks/task-detail-styles';
import InterventionDetail from 'containers/tasks/interventions/intervention-detail';
import OutreachDetail from 'containers/tasks/outreach/outreach-detail';
import IncidentDetail from 'containers/tasks/incidents/incident-detail';
import {
  combineTaskAndLinks,
  getInProgressStatus,
  getArchivedStatus,
} from 'services/utils/task-service';
import { convertMapToList } from 'reducers/helper-reducer';
import { fetchPatientArchivedTasks } from 'actions/action-patient';
import AddChecklistTask from './add-checklist-task';
import TaskContainer from '../../tasks/task-container';
import { getUrlQueryParam } from '../../../helpers/router';

class ChecklistTaskList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayAddForm: false,
      archivedTasks: {
        loading: false,
        loaded: false,
      },
    };
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
  }

  handleAddClick() {
    this.setState({
      displayAddForm: true,
    });
  }

  handleCancelClick() {
    this.setState({
      displayAddForm: false,
    });
  }

  render() {
    const {
      classes,
      taskCounts,
      update,
      selectedTaskId,
      selectedTaskType,
      users,
      tasks,
      serviceGroups,
      isTaskSidebar,
      defaultServiceGroup,
      statuses,
      allPinnedNotes,
      allNonPinnedNotes,
      selectedPatientId,
      links,
      theme,
      // eslint-disable-next-line no-shadow
      fetchPatientArchivedTasks,
      location,
    } = this.props;

    const { archivedTasks, displayAddForm } = this.state;
    const isTaskTypeAR = getUrlQueryParam(location, 'selectedType') === AR;

    const handleExpansionChange = (e, expanded) => {
      if (expanded && !archivedTasks.loaded && !archivedTasks.loading) {
        fetchPatientArchivedTasks(selectedPatientId, false, [OR, INT, QRE], true).then(() => {
          this.setState({
            archivedTasks: {
              loading: false,
              loaded: true,
            },
          });
        });
        this.setState({
          archivedTasks: {
            ...archivedTasks,
            loading: true,
          },
        });
      }
    };

    if (tasks.loading) {
      return <div>Loading...</div>;
    }

    const combinedTasks = convertMapToList(tasks.data).filter(
      task =>
        ((task.is_patient && task.is_patient === 1) || task.context_id === 1) &&
        (task.taskType === INT || task.taskType === OR || task.taskType === QRE),
    );

    const activeTasksList = combinedTasks.filter(task =>
      getInProgressStatus().includes(task.status_category_id),
    );

    const archivedTasksList = combinedTasks.filter(task =>
      getArchivedStatus().includes(task.status_category_id),
    );

    const { int: interventionStatuses, or: outreachStatuses, qre: incidentStatuses } = statuses;

    const renderInterventionTask = (task, innerProps) => {
      if (!interventionStatuses || !interventionStatuses.length) {
        return null;
      }
      const interventionTypeId = getNoteTagId(INT);

      const displayNotes = getDisplayNotes(
        allPinnedNotes,
        allNonPinnedNotes,
        interventionTypeId,
        task.id,
        users,
        serviceGroups,
        2,
      );

      const notesWithProps = {
        patientId: selectedPatientId,
        tagName: INT,
        tagTypeId: interventionTypeId,
        notes: displayNotes,
        maxLine: '2',
        isInContext: true,
      };
      return (
        <InterventionDetail
          task={task}
          statuses={interventionStatuses}
          update={update}
          notes={notesWithProps}
          highlighted={
            selectedTaskType === INT && selectedTaskId && Number(selectedTaskId) === task.id
          }
          serviceGroups={serviceGroups}
          defaultServiceGroup={defaultServiceGroup}
          tagTypeId={interventionTypeId}
          {...innerProps}
        />
      );
    };

    const renderOutreachTask = (task, innerProps) => {
      if (!outreachStatuses || !outreachStatuses.length) {
        return null;
      }
      const outreachTypeId = getNoteTagId(OR);

      const displayNotes = getDisplayNotes(
        allPinnedNotes,
        allNonPinnedNotes,
        outreachTypeId,
        task.id,
        users,
        serviceGroups,
        2,
      );
      const notesWithProps = {
        patientId: selectedPatientId,
        tagName: OR,
        tagTypeId: outreachTypeId,
        notes: displayNotes,
        maxLine: '2',
        isInContext: true,
      };
      return (
        <OutreachDetail
          task={task}
          statuses={outreachStatuses}
          update={update}
          notes={notesWithProps}
          highlighted={
            selectedTaskType === OR && selectedTaskId && Number(selectedTaskId) === task.id
          }
          serviceGroups={serviceGroups}
          defaultServiceGroup={defaultServiceGroup}
          tagTypeId={outreachTypeId}
          {...innerProps}
        />
      );
    };

    const renderIncidentTask = (task, innerProps) => {
      if (!incidentStatuses || !incidentStatuses.length) {
        return null;
      }
      const incidentTypeId = getNoteTagId(QRE);

      const displayNotes = getDisplayNotes(
        allPinnedNotes,
        allNonPinnedNotes,
        incidentTypeId,
        task.id,
        users,
        serviceGroups,
        2,
      );
      const notesWithProps = {
        patientId: selectedPatientId,
        tagName: QRE,
        tagTypeId: incidentTypeId,
        notes: displayNotes,
        maxLine: '2',
        isInContext: true,
      };
      return (
        <IncidentDetail
          task={task}
          statuses={incidentStatuses}
          update={update}
          notes={notesWithProps}
          highlighted={
            selectedTaskType === QRE && selectedTaskId && Number(selectedTaskId) === task.id
          }
          serviceGroups={serviceGroups}
          defaultServiceGroup={defaultServiceGroup}
          tagTypeId={incidentTypeId}
          {...innerProps}
        />
      );
    };

    const renderTask = (task, archived) => {
      const renderer = {
        INT: renderInterventionTask,
        OR: renderOutreachTask,
        QRE: renderIncidentTask,
      };
      return (
        <Fragment key={`${task.taskType}${task.id}`}>
          <Divider className={classes.divider} />
          <TaskContainer
            task={task}
            statuses={statuses}
            highlighted={
              selectedTaskType === task.taskType &&
              selectedTaskId &&
              Number(selectedTaskId) === task.id
            }
            archived={archived}
            therapyTaskid={`checklistTask_${task.taskType}_${task.id}`}
            renderChildren={innerProps => renderer[task.taskType](task, innerProps)}
          />
        </Fragment>
      );
    };

    return (
      <div className={classes.checklistTaskList}>
        {/* Header & Add Task Button */}
        <Grid container className={classes.checklistTaskListHeader} alignItems="center">
          <Grid item xs={10}>
            <Typography className={classes.taskHeader} variant="h6" color="primary">
              Tasks
            </Typography>
          </Grid>
          <Grid item xs={2} align="right">
            {!displayAddForm && (
              <Button
                className={classes.newTaskBtn}
                name="add_task_button"
                id="AddTaskButton"
                variant="contained"
                onClick={this.handleAddClick}
              >
                New Task
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            {(displayAddForm || isTaskTypeAR) && (
              <AddChecklistTask
                taskCounts={taskCounts}
                handleCancel={this.handleCancelClick}
                isTaskSidebar={isTaskSidebar}
                serviceGroups={serviceGroups}
                defaultServiceGroup={defaultServiceGroup}
              />
            )}
          </Grid>
        </Grid>
        <Paper>{activeTasksList.map(task => renderTask(combineTaskAndLinks(task, links)))}</Paper>

        <Accordion
          elevation={0}
          className={classes.archivedContainer}
          onChange={handleExpansionChange}
        >
          <AccordionSummary className={classes.expansionPanelSummaryArchived}>
            <Grid container className={classes.archivedToggleInner}>
              <Grid item xs>
                <Grid container>
                  <Grid item className={classes.archivedIconTitle}>
                    <CheckmarkIcon color={theme.palette.primary.formLabel} />
                  </Grid>
                  <Typography className={classes.archivedTitle} variant="h6">
                    {archivedTasks.loaded
                      ? `ARCHIVED TASKS (${archivedTasksList.length})`
                      : 'ARCHIVED TASKS'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.archivedInner}>
            {archivedTasks.loading && (
              <Box m={1} p={1}>
                Loading...
              </Box>
            )}
            {archivedTasks.loaded && archivedTasksList.map(task => renderTask(task, true))}
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

function mapStateToProps(state) {
  // Convert tasks array to object
  const { links, tasks, selectedPatientId, notes } = state;
  return {
    tasks,
    links: links.data,
    selectedPatientId,
    allPinnedNotes: notes && notes.notes ? notes.notes.allPinnedNotes : [],
    allNonPinnedNotes: notes && notes.notes ? notes.notes.allNonPinnedNotes : [],
    notes: notes && notes.notes ? notes.notes.allNotes : [],
    users: state.lookups.users,
    serviceGroups: state.lookups.serviceGroups,
    statuses: state.taskStatuses.statuses,
    selectedTaskId: state.selectedTaskId,
    selectedTaskType: state.selectedTaskType,
    taskCounts: state.taskCounts.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPatientArchivedTasks: (patientId, taskTypes, ignoreTherapyLevel) =>
      dispatch(fetchPatientArchivedTasks(patientId, false, taskTypes, ignoreTherapyLevel)),
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(ChecklistTaskList);

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

const styles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    pointer: {
      cursor: 'pointer',
    },
    detailHeader: {
      color: palette.primary.formLabel,
    },
    phoneInlineIcon: {
      paddingTop: 1,
      paddingRight: 1,
      width: '12px',
      height: '12px',
      color: palette.primary.trellisBlue,
    },
    phoneInlineIconBackground: {
      marginBottom: 2,
      width: '15px',
      height: '15px',
      backgroundColor: palette.primary.white,
      borderRadius: 100,
      verticalAlign: 'middle',
      textAlign: 'center',
      display: 'inline-block',
    },
    translateIcon: {
      paddingLeft: '5px',
      width: '12px',
      height: '12px',
    },
  });
};

export { styles };

import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Typography } from '@mui/material';
import { Dot } from 'components/icons/icons';
import { DurStatus } from 'interfaces/enums/TaskStatuses/DrugUtilizationReviewStatus';
import {
  DUR_SEVERITY_SEVERE,
  DUR_SEVERITY_SERIOUS,
  DUR_SEVERITY_MODERATE,
  DUR_SEVERITY_UNKNOWN,
  DUR,
} from 'constants/index';
import { styles } from './drug-utilization-review-styles';
import { MedicationStatus } from '../../../constants/enums';

const unknownSeverity = ({ classes, inline }) => (
  <Typography className={classes.inlineTypographyDots} display={inline ? 'inline' : ''}>
    <Dot className={classes.unknownInteractionDot} />
    {' Unknown'}
  </Typography>
);
const moderateSeverity = ({ classes, inline }) => (
  <Typography className={classes.inlineTypographyDots} display={inline ? 'inline' : ''}>
    <Dot className={classes.moderateDot} />
    {' Moderate'}
  </Typography>
);
const seriousSeverity = ({ classes, inline }) => (
  <Typography className={classes.inlineTypographyDots} display={inline ? 'inline' : ''}>
    <Dot className={classes.seriousDot} />
    {' Serious'}
  </Typography>
);
const severeSeverity = ({ classes, inline }) => (
  <Typography className={classes.inlineTypographyDots} display={inline ? 'inline' : ''}>
    <Dot className={classes.severeDot} />
    {' Severe'}
  </Typography>
);

const UnknownSeverity = withStyles(styles, { withTheme: true })(unknownSeverity);
const ModerateSeverity = withStyles(styles, { withTheme: true })(moderateSeverity);
const SeriousSeverity = withStyles(styles, { withTheme: true })(seriousSeverity);
const SevereSeverity = withStyles(styles, { withTheme: true })(severeSeverity);

export const legendFromSeverity = ({ severity, inline }) => {
  switch (severity) {
    case DUR_SEVERITY_SEVERE:
      return <SevereSeverity inline={inline} />;
    case DUR_SEVERITY_SERIOUS:
      return <SeriousSeverity inline={inline} />;
    case DUR_SEVERITY_MODERATE:
      return <ModerateSeverity inline={inline} />;
    case DUR_SEVERITY_UNKNOWN:
      return <UnknownSeverity inline={inline} />;
    default:
      return <UnknownSeverity inline={inline} />;
  }
};

export const filterInteractionsByType = (interactions, interactionType) =>
  interactions.filter(interaction => interaction.interaction_type.code === interactionType);

/**
 * Get current DUR to work on, which is the most recent outstanding DUR task or the most recent
 * completed DUR task.
 * @param {Object[]} tasks Array of tasks retrieved from redux state.
 * @return {Object} DUR task filtered out from tasks array.
 */
const getCurrentDur = tasks => {
  const durTasks = tasks ? Object.values(tasks).filter(task => task.taskType === DUR) : [];
  const openDurs = durTasks.filter(({ status_id: statusId }) =>
    [
      DurStatus.Required,
      DurStatus.Generated,
      DurStatus.ReadyForReview,
      DurStatus.Intervening,
    ].includes(statusId),
  );

  let durTask;
  if (openDurs.length > 0) {
    [durTask] = openDurs;
  } else {
    const recentClosedDurs = durTasks
      .filter(({ status_id: statusId }) =>
        [DurStatus.Reviewed, DurStatus.Canceled].includes(statusId),
      )
      .sort((a, b) => (a.updated > b.updated ? -1 : 1));

    if (recentClosedDurs.length) {
      [durTask] = recentClosedDurs;
    }
  }
  return durTask;
};

const sortAndFilterInteractions = (interactions, newOnly, includeInterventionLogic, onlyActive) =>
  interactions
    .filter(x => {
      if (!x) {
        return false;
      }
      let include = true;
      if (newOnly) {
        include = include && !!x.isNew;
      }
      if (onlyActive) {
        include = include && (x.intervention_id ? true : !!x.active);
      }
      return include;
    })
    .sort((a, b) => {
      // First sort by if intervention is attachted or not (If needed)
      if (a.intervention_id && !b.intervention_id && includeInterventionLogic) {
        return -1;
      }
      if (b.intervention_id && !a.intervention_id && includeInterventionLogic) {
        return 1;
      }
      // Then sort by new items first
      if (a.isNew !== b.isNew) {
        return b.isNew - a.isNew;
      }
      // Then by severity
      if (a.arbor_severity_id !== b.arbor_severity_id) {
        return a.arbor_severity_id - b.arbor_severity_id;
      }
      return a.id - b.id; // Tiebreaker is just id of interaction
    });

export const stripFieldsFromDurTask = durTask => {
  const { vaccines, medications, allergies, ...restDurTask } = durTask;
  return restDurTask;
};

const stripFieldsFromIntervention = intervention => {
  const { interaction, ...restIntervention } = intervention;
  if (!intervention.intervention_checked) {
    return { id: restIntervention.id, intervention_checked: false };
  }

  const innerIntervention = intervention?.interaction?.intervention;

  if (innerIntervention) {
    restIntervention.id = innerIntervention.id;
    restIntervention.status = innerIntervention.status;
    restIntervention.status_id = innerIntervention.status_id;
  }
  return restIntervention;
};

const stripFieldsFromInteraction = interaction => {
  const {
    id,
    interaction_type: { id: interactionTypeId },
    fdb_interaction_id: fdbInteractionId,
    intervention,
  } = interaction;

  return {
    id,
    interaction_type_id: interactionTypeId,
    fdb_interaction_id: fdbInteractionId,
    intervention: intervention ? stripFieldsFromIntervention(intervention) : null,
  };
};

const formatConceptsNameForInteraction = interaction => {
  const filterOnlyActive = !interaction.intervention;
  return Array.from(
    new Set(
      interaction.concepts
        .filter(({ active }) => {
          if (!filterOnlyActive) {
            return true;
          }
          return !!active;
        })
        .map(c => c.name),
    ),
  ).join(' / ');
};

export {
  UnknownSeverity,
  ModerateSeverity,
  SeriousSeverity,
  SevereSeverity,
  getCurrentDur,
  stripFieldsFromInteraction,
  sortAndFilterInteractions,
  formatConceptsNameForInteraction,
};

/**
 * Filter invalid medication groups which are kept active but don't have a matching medication
 * @param {*} medicationGroups Medication Groups List from store
 * @param {*} medications Medications List from store
 * @returns
 */
export const filterMedicationGroups = (medicationGroups, medications) =>
  medicationGroups.filter(
    ({ status_code: statusCode, ndc: medGroupNdc }) =>
      statusCode === MedicationStatus.Active &&
      !!medications.find(({ ndc: medNdc }) => medNdc === medGroupNdc),
  );

import React from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import ErrorIcon from '@mui/icons-material/Error';
import { DeliveryMethodValue } from 'constants/enums';
import { expandTheme } from 'utils/theme-util';
import { IValuesEdited } from './types';
import { transformDeliveryInformation } from './utils';

const useStyles = makeStyles((theme: Theme) => {
  const { palette, typography } = expandTheme(theme);
  const text = {
    fontFamily: typography.fontFamily,
    fontSize: theme.spacing(1.5),
    fontWeight: 500,
  };

  return {
    errorIcon: {
      position: 'absolute',
      top: 13,
      left: 10,
      color: palette.primary.important,
    },
    alertContent: {
      position: 'relative',
      backgroundColor: `${palette.primary.lightRed}22`,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderRadius: 3,
    },
    title: {
      color: palette.primary.important,
      ...text,
      fontWeight: 'bold',
    },
    text,
    subText: {
      ...text,
      fontSize: theme.spacing(1.375),
      fontWeight: typography.fontWeightRegular,
    },
  };
});

const ValuesEdited: React.FC<IValuesEdited> = ({ deliveryInformation }) => {
  const classes = useStyles();
  const { current, previous, keys } = transformDeliveryInformation(deliveryInformation);
  if (!keys.some(key => current[key] !== previous[key] && previous[key] !== null)) {
    return null;
  }

  return (
    <Grid item className={classes.alertContent}>
      <ErrorIcon className={classes.errorIcon} />
      <Typography className={classes.title}>
        The following changes were made since the last shipping label was printed, void and print a
        new shipping label
      </Typography>
      {current.deliveryMethod !== DeliveryMethodValue.PickUp &&
        current.shippingAddress !== previous.shippingAddress &&
        previous.shippingAddress != null && (
          <>
            <Typography className={classes.text}>Preferred Shipping Address</Typography>
            <Typography className={classes.subText}>
              Original value: {previous.shippingAddress}
              <br />
              Current value: {current.shippingAddress}
            </Typography>
          </>
        )}
      {current.shipDate !== previous.shipDate && previous.shipDate != null && (
        <>
          <Typography className={classes.text}>
            Expected{' '}
            {current.deliveryMethod !== DeliveryMethodValue.PickUp ? 'Delivery' : 'Pick Up'} Date
          </Typography>
          <Typography className={classes.subText}>
            Original value: {previous.shipDate} <br />
            Current value: {current.shipDate}
          </Typography>
        </>
      )}
      {previous.signature !== current.signature && previous.signature != null && (
        <>
          <Typography className={classes.text}>Signature Required</Typography>
          <Typography className={classes.subText}>
            Original value: {previous?.signature ? 'Required' : 'Not Required'} <br />
            Current value: {current.signature ? 'Required' : 'Not Required'}
          </Typography>
        </>
      )}
    </Grid>
  );
};

export default ValuesEdited;

import React, { FC, Fragment } from 'react';
import { Typography, Tooltip } from '@mui/material';

export interface IProps {
  text: string;
  maxChars?: number;
  display?: 'inline' | 'inline-block' | 'block';
}

const TruncatedLabel: FC<IProps> = (props: IProps): JSX.Element => {
  const { text, maxChars = 10, display = 'block' } = props;

  const getTruncatedText = (text: string, maxChars: number) => {
    return `${text.substring(0, maxChars)}...`;
  };

  return (
    <>
      {text.length > maxChars ? (
        <Tooltip title={text} style={{ display }}>
          <Typography>{getTruncatedText(text, maxChars)}</Typography>
        </Tooltip>
      ) : (
        <Typography style={{ display }}>{text}</Typography>
      )}
    </>
  );
};

export default TruncatedLabel;

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-destructuring */
import React from 'react';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { change, formValueSelector } from 'redux-form';
import { useDispatch } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';
import { deletePaymentMethod } from 'slices/patient-payment-methods-slice';
import { PaymentMethodResponse } from 'containers/add-payment-method/types';
import { addPaymentMethodModalActions } from '../../../../actions/action-add-payment-method-modal';
import PaymentMethodSelect from './payment-method-select-input';
import { usePaymentMethodSelectOptions } from './use-payment-method-options';
import { MAX_SELECTED_PAYMENT_METHODS } from './payment-method-constants';
import { isVirtualCard, selectPaymentMethodOptions, isExpired } from './payment-method-utils';
import { IFillCoordinationTask } from '../../../../interfaces/redux/task-types/IFillCoordinationTask';

interface PaymentMethodsSectionProps {
  formName: string;
  task: IFillCoordinationTask;
  tasks?: IFillCoordinationTask[]; // NOTE: This is only used when viewing an FC task in the THERAPIES TAB.
}

export default function PaymentMethodsSection({
  formName,
  task,
  tasks,
}: PaymentMethodsSectionProps) {
  const dispatch = useDispatch();
  const formSelect = formValueSelector(formName);
  const paymentMethodOptions = usePaymentMethodSelectOptions(tasks);
  const paymentMethodsById = useTypedSelector(state => state.paymentMethods.entities);
  const currentPatientId = useTypedSelector(state => state.patient.id);
  const selectedPaymentMethodIds = useTypedSelector<number[]>(state => {
    const allSelectedValues = formSelect(state, 'payment_method_ids') ?? [];
    if (allSelectedValues[0] < 0) {
      return [allSelectedValues[0]];
    }
    const allPaymentMethods = selectPaymentMethodOptions(state, tasks) ?? [];
    const sortedSelectedValues = allPaymentMethods.reduce((result, paymentMethod) => {
      if (allSelectedValues.includes(paymentMethod.id) && !isExpired(paymentMethod)) {
        return result.concat(paymentMethod.id);
      }
      return result;
    }, [] as number[]);
    return sortedSelectedValues.slice(0, MAX_SELECTED_PAYMENT_METHODS);
  });
  const totalCopayAmount = useTypedSelector<number>(state =>
    Object.values(formSelect(state, 'copay_amount') || {}).reduce((total: number, amount: any) => {
      const add = Number(amount?.toString?.().replace(/[^\d.]+/gi, ''));
      return !Number.isNaN(add) ? total + add : total;
    }, 0),
  );
  const onSelectAdd = React.useCallback(() => {
    dispatch(
      addPaymentMethodModalActions.setState({
        visible: true,
        formId: formName,
        field: 'payment_method_ids',
        tasks,
      }),
    );
  }, [dispatch, addPaymentMethodModalActions.setState, tasks]);

  const onChange = (values: number[] | null) => {
    const sortedPaymentMethodValues = paymentMethodOptions.reduce((result, option) => {
      if (values?.includes(option.value)) {
        return result.concat(option.value);
      }
      return result;
    }, [] as number[]);
    dispatch(change(formName, 'payment_method_ids', sortedPaymentMethodValues));
  };

  const onDelete = React.useCallback(
    (paymentMethodId: number) => {
      const paymentMethod = paymentMethodsById[paymentMethodId];
      const isPaymentVirtualCard = paymentMethod ? isVirtualCard(paymentMethod) : false;
      if (isPaymentVirtualCard) {
        dispatch(
          deletePaymentMethod({
            patientId: currentPatientId,
            paymentMethodId,
            orderId: task.order_id,
          }),
        );
      }
    },
    [paymentMethodsById, currentPatientId, task.order_id, dispatch],
  );

  return (
    <Grid container rowGap={2} sx={{ padding: '0 12px', margin: '6px 0' }}>
      <Grid container gap={3}>
        <Grid item width="100%">
          <PaymentMethodSelect
            fieldName="payment_method_ids"
            onSelectAdd={onSelectAdd}
            onChange={onChange}
            options={paymentMethodOptions}
            values={selectedPaymentMethodIds}
            maxSelectedValues={MAX_SELECTED_PAYMENT_METHODS}
            onDelete={onDelete}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ alignItems: 'center' }}>
        <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ marginRight: '6px' }}>Total Copay Amount</Typography>
          <Box
            sx={theme => ({
              borderRadius: theme.spacing(0.5),
              backgroundColor: '#F5F5F5',
              width: theme.spacing(18),
              padding: theme.spacing(2),
            })}
          >
            <Typography sx={{ fontWeight: 600, fontsize: '26px' }}>
              {totalCopayAmount ? `$ ${totalCopayAmount}` : 'Not specified'}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

import { AxiosPromise } from 'axios';
import { CustomerId, UserId } from 'interfaces/RecordTypes';
import { IGetNewUsersResponse } from 'models/application-manager/users/IGetNewUsersResponse';
import { IGetPermissionsResponseItem } from 'models/application-manager/users/IGetPermissionsResponse';
import { IGetServiceGroupsResponseItem } from 'models/application-manager/users/IGetServiceGroupsResponse';
import { IGetClinicaDataCategoriesResponseItem } from 'models/application-manager/cpmp/IGetClinicalDataCategoriesResponse';
import { IGetLoincCodesResponse } from 'models/application-manager/cpmp/IGetLoincCodesResponse';
import { IGetIcdProtocolsResponse } from 'models/application-manager/cpmp/IGetIcdProtocolsResponse';
import {
  ICustomerRoleItem,
  IGetUserResponse,
} from 'models/application-manager/users/IGetUserResponse';
import { TypedHttp } from 'services/typed-http';
import { IGetSpecialtyResponse } from 'models/application-manager/cpmp/IGetSpecialtyResponse';
import { IGetCdmProgramsResponse } from 'models/application-manager/cpmp/IGetCdmProgramsResponse';
import {
  IGetDiseaseStateResponse,
  IGetDiseaseStateNameByDiagnosisCodeResponse,
} from 'models/application-manager/cpmp/IGetDiseaseStateResponse';
import {
  IGetGpiProtocolsResponse,
  IGpiProtocolRequest,
} from 'models/application-manager/cpmp/IGetGpiProtocolsResponse';
import { IPostCslProtocolResponse } from 'models/application-manager/cpmp/IPostCslProtocolResponse';
import { IPostCslProtocolRequest } from 'models/application-manager/cpmp/IPostCslProtocolRequest';
import { IPatchCslProtocolRequest } from 'models/application-manager/cpmp/IPatchCslProtocolRequest';
import { IPatchCslProtocolResponse } from 'models/application-manager/cpmp/IPatchCslProtocolResponse';
import { IGetIcd10CodesResponse } from 'models/application-manager/cpmp/IGetIcd10CodesResponse';
import { IGetPayorDataResponse } from 'models/application-manager/cpmp/IGetPayorDataResponse';
import { IIcdItem } from '../containers/application-manager/cpmp/icd/modal/types';
import {
  IPostClinicalItemProtocolRequest,
  IPatchClinicalItemProtocolRequest,
} from '../containers/application-manager/cpmp/clinical-item-protocol/types';
import { ClinicalDataTypeTypes } from '../constants/clinical-data-type-types';
import {
  IDcItem,
  IEditDcItem,
  IClinicalDataType,
} from '../containers/application-manager/cpmp/modal/types';
import {
  IDiseaseStateRequest,
  IPostDiseaseStateResponse,
} from '../models/application-manager/cpmp/IGetDiseaseStateRequest';
import { IGetSafetyEfficacyProtocolsResponse } from '../models/application-manager/cpmp/IGetSafetyEfficacyProtocolsResponse';

export class ApplicationManagerClient {
  // #region users
  static updateUserField = (
    userId: number | undefined,
    field: string,
    value: unknown,
  ): AxiosPromise<unknown> | null => {
    const url = `/application-manager/users/${userId}`;
    const body = {
      id: userId,
      [field]: value,
    };
    return TypedHttp.PatchPromise<{}, unknown>(url, body);
  };

  static getPermissionsAssignablePerCustomer = (): AxiosPromise<IGetPermissionsResponseItem[]> => {
    const url = '/application-manager/users/permissions';
    return TypedHttp.GetPromise<IGetPermissionsResponseItem[]>(url);
  };

  static getCustomerServiceGroups = (
    customerId: CustomerId,
  ): AxiosPromise<IGetServiceGroupsResponseItem[]> => {
    const url = `/application-manager/users/${customerId}/service-groups`;
    return TypedHttp.GetPromise<IGetServiceGroupsResponseItem[]>(url);
  };

  static getUser = (userId: UserId): AxiosPromise<IGetUserResponse> => {
    const url = `/application-manager/users/${userId}`;
    return TypedHttp.GetPromise<IGetUserResponse>(url);
  };

  static changeUserRoleAtCustomerV2 = (
    userId: UserId,
    customerId: CustomerId,
    newRoleId: number,
  ): AxiosPromise<{ roleId: number }> => {
    const url = `/application-manager/users/${userId}/changeRole/${customerId}`;
    const payload = {
      newRoleId: newRoleId,
    };
    return TypedHttp.PatchPromise<{}, { roleId: number }>(url, payload);
  };

  static searchNewPatients = (searchTerm: string): AxiosPromise<IGetNewUsersResponse> => {
    const baseUrl = '/application-manager/users/new';
    const requestParams: Record<string, string> = {
      searchTerm: searchTerm,
    };
    const queryParams = new URLSearchParams(requestParams).toString();
    return TypedHttp.GetPromise<IGetNewUsersResponse>(`${baseUrl}?${queryParams}`);
  };

  static updateUser = (
    userId: number,
    active: boolean,
    licensedStateIds: number[],
    customerRoles: ICustomerRoleItem[],
  ): AxiosPromise<{ id: number }> => {
    const baseUrl = `/application-manager/users/${userId}`;
    const payload = {
      active: active,
      licensedStateIds: licensedStateIds,
      customerRoles: customerRoles,
    };
    return TypedHttp.PatchPromise(baseUrl, payload);
  };

  static addNewUser = (
    customerRoles: unknown,
    externalId: string,
    username: string,
    displayName: string,
    licensedStates?: number[],
  ): AxiosPromise<{ id: number }> => {
    const baseUrl = '/application-manager/users';
    const payload = {
      externalId: externalId,
      username: username,
      displayName: displayName,
      licensedStateIds: licensedStates,
      customerRoles: customerRoles,
    };
    return TypedHttp.PostPromise(baseUrl, payload);
  };
  // #endregion

  static ctdReport = (
    startDate: string,
    endDate: string,
    customerName: string,
    customerId: CustomerId,
  ): AxiosPromise<{}> => {
    const baseUrl = '/application-manager/ctd/report/audit';
    const requestParams: Record<string, string> = {
      startDate: startDate,
      endDate: endDate,
      customerName: customerName,
      customerId: customerId.toString(),
    };

    const queryParams = new URLSearchParams(requestParams).toString();
    const url = `${baseUrl}?${queryParams}`;
    return TypedHttp.GetPromise<{}>(url);
  };

  static addNewTtc = (
    name: string,
    active: boolean,
    is_restricted: boolean,
    pdc_eligible: boolean,
    therapeutic_area_id: number | null,
    start_date: string | null,
    end_date: string | null,
  ): AxiosPromise<{ id: number; name: string }> => {
    const baseUrl = '/application-manager/ttc';
    const payload = {
      name,
      active,
      is_restricted,
      pdc_eligible,
      therapeutic_area_id,
      start_date,
      end_date,
    };
    return TypedHttp.PostPromise(baseUrl, payload);
  };

  static updateTtc = (id: number, field: string, value: unknown): AxiosPromise<unknown> | null => {
    const url = `/application-manager/ttc/${id}`;
    const body = {
      [field]: value,
    };
    return TypedHttp.PatchPromise<{}, unknown>(url, body);
  };

  static addNewAr = (
    customerId: CustomerId,
    customerBranch: number,
    isActive: boolean,
    specialtyType: string[],
    therapeuticClass: number,
  ): AxiosPromise<{ id: number; name: string }> => {
    const baseUrl = `/application-manager/ar/${customerId}`;
    const payload = {
      customerBranch: customerBranch,
      isActive: isActive,
      specialtyType: specialtyType,
      therapeuticClass,
    };
    return TypedHttp.PutPromise(baseUrl, payload);
  };

  static getAllCustomerClinics = (
    customerId: number,
  ): AxiosPromise<{ id: number; name: string }[]> => {
    const url = `/application-manager/ar/clinics/${customerId}`;
    const request = TypedHttp.GetPromise<{ id: number; name: string }[]>(url);
    return request;
  };

  static updateAr = (
    id: number,
    customerId: number,
    field: string,
    value: unknown,
  ): AxiosPromise<unknown> | null => {
    const url = `/application-manager/ar/${customerId}/${id}`;
    const body = {
      customerBranchTrellisTherapeuticClassId: id,
      [field]: value,
    };
    return TypedHttp.PatchPromise<{}, unknown>(url, body);
  };

  static upsertCtd = (
    id: number | undefined,
    ddi: number | undefined,
    customerId: number | undefined,
    field: string,
    value: unknown,
  ): AxiosPromise<unknown> | null => {
    const url = `/application-manager/ctd/update-drugs/${customerId}`;
    const body = [
      {
        gtd_id: id,
        drug_descriptor_id: ddi,
        [field]: value,
      },
    ];
    return TypedHttp.PutPromise<{}, unknown>(url, body);
  };

  static fetchClinicalDataCategories = (): AxiosPromise<IGetClinicaDataCategoriesResponseItem> => {
    const url = '/application-manager/cpmp/categories';
    return TypedHttp.GetPromise<IGetClinicaDataCategoriesResponseItem>(url);
  };

  static addNewDc = (
    dcItem: IDcItem,
  ): AxiosPromise<{ success: boolean; result: number | string }> => {
    const baseUrl = '/application-manager/cpmp';
    const loincCodes =
      dcItem.loinc && dcItem.loinc.length > 0 ? dcItem.loinc.map(x => x.value) : [];
    const payload =
      dcItem.type === ClinicalDataTypeTypes.FreeText ||
      dcItem.type === ClinicalDataTypeTypes.Numeric
        ? {
            type_name: dcItem.name,
            is_active: dcItem.isActive,
            category_id: dcItem.category_id,
            type: dcItem.type,
            unit: dcItem.unit,
            example: dcItem.dynamicFieldValue,
            loinc_ids: loincCodes,
          }
        : {
            type_name: dcItem.name,
            is_active: dcItem.isActive,
            category_id: dcItem.category_id,
            type: dcItem.type,
            unit: dcItem.unit,
            option: dcItem.dynamicFieldValue,
            loinc_ids: loincCodes,
          };
    return TypedHttp.PostPromise(baseUrl, payload);
  };

  static editDc = (
    dcItem: IEditDcItem,
  ): AxiosPromise<IClinicalDataType | { status: number; errorMessage: string }> => {
    const baseUrl = '/application-manager/cpmp';
    const payload = {
      id: dcItem.id,
      category_id: dcItem.category_id,
      type_name: dcItem.name,
      is_active: dcItem.isActive,
      loinc_ids: dcItem.loinc,
      type: dcItem.type,
      unit: dcItem.unit,
      example: dcItem.example,
      option: dcItem.option,
    };
    return TypedHttp.PatchPromise(baseUrl, payload);
  };

  static fetchLoincCodes = (
    searchString: string,
  ): AxiosPromise<IGetLoincCodesResponse> | undefined => {
    if (searchString && searchString.length >= 2) {
      const requestParams: Record<string, string> = {
        searchTerm: searchString,
      };
      const queryParams = new URLSearchParams(requestParams).toString();
      const url = `/application-manager/cpmp/loinc?${queryParams}`;
      return TypedHttp.GetPromise<IGetLoincCodesResponse>(url);
    }
    return undefined;
  };

  static fetchIcdProtocols = (): AxiosPromise<IGetIcdProtocolsResponse> => {
    const url = '/application-manager/cpmp/icd/list';
    return TypedHttp.GetPromise<IGetIcdProtocolsResponse>(url);
  };

  static fetchSpecialties = (): AxiosPromise<IGetSpecialtyResponse> => {
    const url = '/application-manager/cpmp/icd/specialties';
    return TypedHttp.GetPromise<IGetSpecialtyResponse>(url);
  };

  static fetchIcdCodesBySpecialty = (specialtyId: number): AxiosPromise<{ result: string[] }> => {
    const url = `/application-manager/cpmp/icd/codes/${specialtyId}`;
    return TypedHttp.GetPromise<{ result: string[] }>(url);
  };

  static addNewIcd = (
    icdItem: IIcdItem,
  ): AxiosPromise<{ success: boolean; result: boolean | string }> => {
    const baseUrl = '/application-manager/cpmp/icd/add';
    return TypedHttp.PostPromise(baseUrl, icdItem);
  };

  static editIcd = (
    icdItem: IIcdItem,
  ): AxiosPromise<{ success: boolean; result: boolean | string }> => {
    const baseUrl = '/application-manager/cpmp/icd/update';
    return TypedHttp.PatchPromise(baseUrl, icdItem);
  };

  static getTherapeuticArea = ():
    | AxiosPromise<{ count: number; results: { id: number; name: string }[] }>
    | undefined => {
    const url = '/application-manager/ttc/therapeutic_area';

    return TypedHttp.GetPromise<{ count: number; results: { id: number; name: string }[] }>(url);
  };

  static fetchGpiDrugs = (searchTerm: string): AxiosPromise<IGetGpiProtocolsResponse> => {
    const query = new URLSearchParams({ searchTerm });
    const url = '/application-manager/cpmp/gpi/search';
    return TypedHttp.GetPromise<IGetGpiProtocolsResponse>(`${url}?${query.toString()}`);
  };

  static addGpiDrugs = (newGpiProtocol: IGpiProtocolRequest): AxiosPromise<IGpiProtocolRequest> => {
    const url = '/application-manager/cpmp/gpi/add';
    return TypedHttp.PostPromise<IGpiProtocolRequest, IGpiProtocolRequest>(url, newGpiProtocol);
  };

  static updateGpiDrugs = (gpiProtocol: IGpiProtocolRequest): AxiosPromise<IGpiProtocolRequest> => {
    const url = '/application-manager/cpmp/gpi/edit';
    return TypedHttp.PutPromise<IGpiProtocolRequest, IGpiProtocolRequest>(url, gpiProtocol);
  };

  static addDiseaseState = (
    newDiseaseState: IDiseaseStateRequest,
  ): AxiosPromise<IPostDiseaseStateResponse> => {
    const url = '/application-manager/cpmp/disease-state/add';
    return TypedHttp.PostPromise<IDiseaseStateRequest, IPostDiseaseStateResponse>(
      `${url}`,
      newDiseaseState,
    );
  };

  static updateDiseaseState = (
    diseaseState: IDiseaseStateRequest,
  ): AxiosPromise<IPostDiseaseStateResponse> => {
    const url = '/application-manager/cpmp/disease-state/update';
    return TypedHttp.PatchPromise<IDiseaseStateRequest, IPostDiseaseStateResponse>(
      `${url}`,
      diseaseState,
    );
  };

  static fetchIcd10Codes = (
    searchString: string,
  ): AxiosPromise<IGetIcd10CodesResponse> | undefined => {
    const url = '/application-manager/cpmp/icd/codes';
    if (searchString && searchString.length >= 2) {
      const requestParams: Record<string, string> = {
        searchTerm: searchString,
      };
      const queryParams = new URLSearchParams(requestParams).toString();
      return TypedHttp.GetPromise<IGetIcd10CodesResponse>(`${url}?${queryParams}`);
    }
    return undefined;
  };

  static fetchDiseaseStates = (): AxiosPromise<IGetDiseaseStateResponse> => {
    const url = '/application-manager/cpmp/disease-state/list';
    return TypedHttp.GetPromise<IGetDiseaseStateResponse>(`${url}`);
  };

  static fetchDiseaseStatesNameByDiagnosisCode = (
    diagnosisCode: string,
  ): AxiosPromise<IGetDiseaseStateNameByDiagnosisCodeResponse[]> => {
    const url = `/application-manager/cpmp/disease-state/${diagnosisCode}`;
    return TypedHttp.GetPromise<IGetDiseaseStateNameByDiagnosisCodeResponse[]>(`${url}`);
  };

  static createCslProtocol = (
    protocol: Partial<IPostCslProtocolRequest>,
  ): AxiosPromise<IPostCslProtocolResponse> => {
    const url = '/application-manager/cpmp/csl-protocol';
    return TypedHttp.PostPromise(url, protocol);
  };

  static updateCslProtocol = (
    id: number,
    protocol: Partial<IPatchCslProtocolRequest>,
  ): AxiosPromise<IPatchCslProtocolResponse> => {
    const url = `/application-manager/cpmp/csl-protocol/${id}`;
    return TypedHttp.PatchPromise(url, protocol);
  };

  static checkIfOpenRsTasks = (
    patientId: number,
    diseaseStateId: number,
  ): AxiosPromise<boolean> => {
    const url = `/tasks/openedRsTask/${patientId}/${diseaseStateId}`;
    return TypedHttp.GetPromise<boolean>(`${url}`);
  };

  static fetchPayorData = (): AxiosPromise<IGetPayorDataResponse> => {
    const url = '/application-manager/cpmp/payor/list';
    return TypedHttp.GetPromise<IGetPayorDataResponse>(url);
  };

  static updatePayorData = (
    bin: string,
    pcn: string | null,
    group: string | null,
    locked: number,
  ): AxiosPromise<void> => {
    const url = '/application-manager/cpmp/payor/update';
    const payload = {
      bin,
      pcn,
      group,
      locked,
    };
    return TypedHttp.PatchPromise(url, payload);
  };

  static fetchCdmProgramData = (): AxiosPromise<IGetCdmProgramsResponse> => {
    const url = '/cdm_programs';
    return TypedHttp.GetPromise<IGetCdmProgramsResponse>(url);
  };

  static addEfficacySafetyProtocol = (
    protocolObj: IPostClinicalItemProtocolRequest,
  ): AxiosPromise<{ success: boolean; result: boolean | string }> => {
    const baseUrl = '/application-manager/cpmp/clinical-item-protocol/add';
    return TypedHttp.PostPromise(baseUrl, protocolObj);
  };

  static updateEfficacySafetyProtocol = (
    protocolObj: IPatchClinicalItemProtocolRequest,
  ): AxiosPromise<{ success: boolean; result: boolean | string }> => {
    const baseUrl = '/application-manager/cpmp/clinical-item-protocol/update';
    return TypedHttp.PatchPromise(baseUrl, protocolObj);
  };

  static fetchEfficacySafetyProtocol = (): AxiosPromise<IGetSafetyEfficacyProtocolsResponse> => {
    const url = '/application-manager/cpmp/clinical-item-protocol';
    return TypedHttp.GetPromise<IGetSafetyEfficacyProtocolsResponse>(`${url}`);
  };
}

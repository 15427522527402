import React from 'react';
import { Controller } from 'react-hook-form';
import AddressSearchBar from 'components/form/address/address_search_bar';
import { IAddressSuggestions, IControlledFormField } from '../types';

interface IProps extends IControlledFormField {
  setSuggestions: (addr: IAddressSuggestions) => void;
}

export const ControlledAddressSearch = (props: IProps): JSX.Element => {
  const { name, label, setSuggestions, control, inputMetaData } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={(ctrlProps: any) => (
        <AddressSearchBar
          label={label}
          setSuggestions={setSuggestions}
          input={{
            value: ctrlProps?.field?.value,
            onBlur: ctrlProps?.field?.onBlur,
            onChange: ctrlProps?.field?.onChange,
          }}
          meta={inputMetaData || {}}
          data-qa-id={props.qaId}
        />
      )}
    />
  );
};

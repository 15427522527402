export const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.font.weight.regular,
  },
  panel: {
    marginTop: 0,
    marginLeft: 30,
    marginRight: 30,
  },
  summaryContainer: {
    paddingRight: '0',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  editForm: {
    width: '100%',
  },
  actionContainer: {
    paddingLeft: 10,
  },
  actionIconContainer: {
    textAlign: 'right',
  },
  actionBtnContainer: {
    textAlign: 'right',
    paddingRight: 10,
    marginTop: 30,
  },
  phonePeriodContainer: {
    display: 'flex',
  },
  expandIcon: {
    left: 0,
  },
  typography: {
    margin: theme.spacing(1),
  },
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  patientFormContainer: {
    border: '1px solid',
    margin: 50,
    padding: 15,
    borderColor: theme.palette.primary.trellisBlue,
    borderRadius: 5,
  },
  patientForm: {
    width: '100%',
  },
  alertWording: {
    color: theme.palette.primary.darkRed,
  },
  modalActionBtnContainer: {
    paddingRight: 16,
    paddingBottom: 5,
  },
  checkboxTextContainer: {
    marginTop: 10,
    paddingRight: 5,
  },
  radio: {
    height: 22,
  },
  customLink: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
});

import React from 'react';
import { Modal, Button, Grid, Typography, CircularProgress } from '@mui/material';
import { getModalStyle } from 'services/utils/styles-service';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { useDispatch } from 'react-redux';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { buildQaId } from 'utils/build-qa-id';
import { UseStyles } from './inactivate-user-modal.styles';

export interface IModalState {
  open: boolean;
  submitting?: boolean;
  header?: string;
}
const initialModalState: IModalState = {
  open: false,
  submitting: false,
};

const getQaId = buildQaId('application-manager.users.inactivate-user-modal', '.');

export const InactivateUserModal = (props: {
  open: boolean;
  selectedUserId?: number | undefined;
  onCancel: () => void;
  afterSuccess: () => void;
  text: string | undefined;
}): JSX.Element | null => {
  const classes: any = UseStyles();
  const dispatch = useDispatch();
  const [modalState, setModalState] = React.useState<IModalState>(initialModalState);

  const onConfirm = async () => {
    try {
      setModalState({ open: true, submitting: true });
      const request = ApplicationManagerClient.updateUserField(
        props.selectedUserId,
        'active',
        false,
      );
      await request;
      props.afterSuccess();
      dispatch(notifySuccess('Saved'));
    } catch (_error) {
      dispatch(notifyError('An error occurred'));
    } finally {
      setModalState(initialModalState);
      props.onCancel();
    }
  };

  return (
    <Modal open={props.open}>
      <div style={getModalStyle()} className={classes.confirmationModal}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs="auto">
            <Typography className={classes.modalHeader}>{props.text}</Typography>
          </Grid>
          <Grid item xs="auto">
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item xs="auto">
                <Button
                  onClick={() => {
                    props.onCancel();
                  }}
                  disabled={modalState.submitting}
                  data-qa-id={getQaId('button.cancel')}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs="auto">
                <Button
                  variant="contained"
                  className={classes.actionButton}
                  onClick={() => {
                    onConfirm();
                  }}
                  disabled={modalState.submitting}
                  data-qa-id={getQaId('button.confirm')}
                >
                  Confirm
                  {modalState.submitting ? (
                    <CircularProgress
                      variant="indeterminate"
                      size={20}
                      className={classes.submittingIndicator}
                    />
                  ) : null}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

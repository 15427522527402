import React from 'react';
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'recompose';
import { styles } from './location-styles';
import { Location as LocationIcon } from '../../icons/icons';

const Location: React.FC<any> = props => {
  const location = props.input.value as string;
  const { classes } = props;
  return (
    <Grid container className={classes.locationWrapper}>
      <Typography className={classes.locationTitle}>{location || 'Unknown Location'}</Typography>
      <LocationIcon />
    </Grid>
  );
};

export default compose<any, {}>(withStyles(styles as any, { withTheme: true }))(Location);

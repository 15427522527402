import { Grid, Typography } from '@mui/material';
import { convertToArborDate } from 'models/time/arbor-date';
import React from 'react';
import { numberToDollars } from 'services/utils/formatting';
import { IFinancialAssistance } from 'components/patient-merge-modal/interfaces';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { useTheme } from '@mui/styles';

const FinancialAssistanceSection = ({
  drugName: financialDrugName,
  expirationDate: financialExpirationDate,
  remainingBalance: financialRemainingBalance,
}: IFinancialAssistance) => {
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);

  const drugName = financialDrugName ?? '';
  const expirationDate = financialExpirationDate
    ? convertToArborDate(financialExpirationDate).getUtcDate(true)
    : 'Unknown';
  const remainingBalance = financialRemainingBalance
    ? numberToDollars(financialRemainingBalance)
    : numberToDollars(0);

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <Typography sx={classes.checkboxTitle}>
          {`${drugName} - ${expirationDate} - ${remainingBalance}`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FinancialAssistanceSection;

import AutocompleteMinigrid from 'components/form/field/render-minigrid-autocomplete';
import LoadingOverlay from 'components/loading-overlay/loading-overlay';
import React from 'react';
import Validation from 'components/form/validation/validation';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Button, Divider, Grid, Modal, TextField, Typography } from '@mui/material';
import { buildQaId } from 'utils/build-qa-id';
import { CircularLoadingButton } from 'components/circular-loading-button/circular-loading-button';
import { CustomerId } from 'interfaces/RecordTypes';
import { getModalStyle } from 'services/utils/styles-service';
import { IFiltersCustomerItem } from 'interfaces/redux/IFilters';
import { IGetNewUsersResponseResult } from 'models/application-manager/users/IGetNewUsersResponse';
import { IGetServiceGroupsResponseItem } from 'models/application-manager/users/IGetServiceGroupsResponse';
import { nameOfFactory, nameOfFieldArrayFactory } from 'utils/types-util';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { NumberOption } from 'interfaces/forms/types';
import { ReactSelect } from 'components/form/field/react-select';
import { renderDropdown as RenderDropdown } from 'components/form/field/redux-field';
import { StringUtils } from 'utils/string-utils';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'hooks/use-typed-selector';
import {
  IGetUserLicensedStateItem,
  IGetUserResponse,
} from 'models/application-manager/users/IGetUserResponse';
import { UserUtils } from 'utils/user-utils';
import { UserPermissions } from 'interfaces/user/Permissions';
import { UserRoles } from 'constants/enums';
import {
  IFormFields,
  ICredentialingAt,
  IUserModalProps,
  IRequestPayload,
  IRoleSetting,
  IAlreadyCredentialedAt,
  IInactivateUserModalItem,
} from './types';
import { styles } from './user-modal.styles';
import { logger } from '../../../../winston-logger';
import { InactivateUserModal } from './inactivate-user-modal';

const getFieldName = nameOfFactory<IFormFields>();

const getFieldArrayNameCredentialingAt = (attribute: keyof ICredentialingAt, index: number) =>
  nameOfFieldArrayFactory<IFormFields, ICredentialingAt>()('credentialingAt', attribute, index);

const getFieldArrayNameAlreadyCredentialedAt = (
  attribute: keyof IAlreadyCredentialedAt,
  index: number,
) =>
  nameOfFieldArrayFactory<IFormFields, IAlreadyCredentialedAt>()(
    'alreadyCredentialedAt',
    attribute,
    index,
  );

const requiredErrorMsg = 'Required';
const getQaId = buildQaId('application-manager.users.user-modal', '.');

export const UserModal: React.FC<IUserModalProps> = (props: IUserModalProps): JSX.Element => {
  const classes: any = styles();

  // #region component state
  const form: any = useForm<any>({
    defaultValues: {
      username: '',
      selectedAdUser: null,
      role: null,
      statesLicensed: null,
      alreadyCredentialedAt: [],
      credentialingAt: [],
    },
  });
  const credentialingAtFields = useFieldArray({
    control: form.control,
    name: getFieldName('credentialingAt'),
  });
  const alreadyCredentialedAtFields = useFieldArray({
    control: form.control,
    name: getFieldName('alreadyCredentialedAt'),
  });
  const {
    setError,
    clearErrors,
    formState: { errors },
  } = form;
  const formValues = form.watch();

  const dispatch = useDispatch();

  const states = useTypedSelector(state => state.lookups.states);
  const roles = useTypedSelector(state => state.lookups.roles);
  const customers = useTypedSelector(state => state.filters.customers);
  const users = useTypedSelector(state => state.lookups.users);

  const [loading, setLoading] = React.useState<boolean>(true);
  const [serviceGroupsPerCustomer, setServiceGroupsPerCustomer] = React.useState<
    Record<CustomerId, NumberOption[]> | undefined
  >(undefined);
  const [permissionOptions, setPermissionOptions] = React.useState<NumberOption[] | undefined>();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [currentRoleId, setCurrentRoleId] = React.useState<number>();
  const [selectedCustomerIds, setSelectedCustomerIds] = React.useState<Set<number>>(
    new Set<number>(),
  );
  const [inactivateUserModal, setInactivateUserModal] = React.useState<
    IInactivateUserModalItem | undefined
  >(undefined);
  const credentialedRoleIdValues = roles.reduce((result: number[], role) => {
    if (role.show_credentialed_at) {
      return result.concat(role.id);
    }
    return result;
  }, []);
  const [userBeingEdited, setUserBeingEdited] = React.useState<IGetUserResponse | undefined>(
    undefined,
  );
  const pharmacistRoles: string[] = [
    UserRoles.CredentialedPharmacist,
    UserRoles.PharmacistCOM,
    UserRoles.PharmacistClinic,
    UserRoles.PharmacistCentralOps,
    UserRoles.PharmacistCentralSupport,
    UserRoles.PharmacistDispensing,
  ];
  const listOfPharmacistRoles = roles.filter(t => pharmacistRoles.includes(t.name)).map(a => a.id);
  // #endregion

  // #region use effects
  React.useEffect(() => {
    if (permissionOptions && serviceGroupsPerCustomer && props.editingUser?.userId) {
      setLoading(true);
      (async () => {
        const request = ApplicationManagerClient.getUser(props.editingUser!.userId);
        const user = (await request).data;
        if (user) {
          setUserBeingEdited(user);

          /**
           * For each of the users' roles, find the first one that's a valid option
           */
          let roleOption: NumberOption | undefined;
          for (const cr of user.customerRoles) {
            const matchingRoleOption = roleOptions.find(x => x.value === cr.role_id);
            if (matchingRoleOption) {
              roleOption = matchingRoleOption;
              break;
            }
          }

          if (roleOption != null) {
            // set role fields
            form.setValue(getFieldName('role'), roleOption.value);
            setCurrentRoleId(roleOption.value);

            // set username / name fields
            form.setValue(getFieldName('username'), user.username);
            const adUser: Partial<IGetNewUsersResponseResult> = {
              username: user.username,
              displayName: user.display_name,
            };
            form.setValue(getFieldName('selectedAdUser'), adUser);

            // set licensed state fields if the current role supports licensed states
            const stateRole = roles.find(x => x.id === roleOption!.value);
            if (stateRole?.show_licensed) {
              const userStateIds = user.licensedStates.map(x => x.id);
              const licensedStates = stateOptions.filter(x => userStateIds.includes(x.value));
              form.setValue(getFieldName('statesLicensed'), licensedStates);
            }

            const customerRolesMatchingSelection = user.customerRoles.filter(
              x => x.role_id === roleOption!.value,
            );

            if (stateRole?.show_site_selector) {
              const customerRoles = customerRolesMatchingSelection.reduce<NumberOption[]>(
                (acc, curr) => {
                  const customer = customers.find(x => x.id === curr.customer_id);
                  if (customer) {
                    acc.push({ label: customer.name, value: customer.id });
                  }
                  return acc;
                },
                [],
              );
              form.setValue(getFieldName('customerRoles'), customerRoles);
            }

            if (stateRole?.show_customers_selector) {
              const newFormValue = buildCustomerRolesValues(
                customerRolesMatchingSelection.map(x => ({
                  customerId: x.customer_id,
                  roleId: x.role_id,
                })),
              );
              form.setValue(getFieldName('customerRoles'), newFormValue);
            }

            if (stateRole?.show_credentialed_at) {
              customerRolesMatchingSelection.forEach((cr, index) => {
                const customerOption = customerOptions.find(x => x.value === cr.customer_id);
                if (customerOption) {
                  const selectedServiceGroups = serviceGroupsPerCustomer
                    ? serviceGroupsPerCustomer[cr.customer_id].filter(x =>
                        cr.serviceGroupIds.includes(x.value),
                      )
                    : [];

                  const selectedPermissions = permissionOptions.filter(x =>
                    cr.permissionIds.includes(x.value),
                  );

                  alreadyCredentialedAtFields.append({
                    site: customerOption,
                    serviceGroups: selectedServiceGroups,
                    permissions: selectedPermissions,
                    removed: false,
                  });
                }
              });
            }
          }

          const userLicensedStates: NumberOption[] = [];
          user.licensedStates.forEach(ls => {
            const numberOption = stateOptions.find(x => x.value === ls.id);
            if (numberOption) {
              userLicensedStates.push(numberOption);
            }
          });
          form.setValue(getFieldName('statesLicensed'), userLicensedStates);
        }
        setLoading(false);
      })();
    }
  }, [props.editingUser, permissionOptions, serviceGroupsPerCustomer]);

  React.useEffect(() => {
    if (props.open) {
      setLoading(true);

      (async () => {
        // Load permissions
        try {
          const permissions = await ApplicationManagerClient.getPermissionsAssignablePerCustomer();
          setPermissionOptions(
            permissions.data.map(x => {
              return { label: x.display_name, value: x.id };
            }),
          );
        } catch (error) {
          logger.error(error);
          dispatch(notifyError('Error fetching permissions'));
        }

        // Load service groups
        try {
          const allPromises = customers.map(site => {
            const result = new Promise<{
              customerId: CustomerId;
              serviceGroups: IGetServiceGroupsResponseItem[];
            }>(async (resolve, reject) => {
              const apiResponse = await ApplicationManagerClient.getCustomerServiceGroups(site.id);
              resolve({
                customerId: site.id,
                serviceGroups: apiResponse.data,
              });
            });
            return result;
          });

          const serviceGroupPromises = await Promise.all(allPromises);
          const serviceGroups = serviceGroupPromises.reduce<Record<CustomerId, NumberOption[]>>(
            (acc, curr) => {
              acc[curr.customerId] = curr.serviceGroups.map<NumberOption>(sg => {
                return {
                  label: sg.display_name,
                  value: sg.id,
                };
              });
              return acc;
            },
            {},
          );
          setServiceGroupsPerCustomer(serviceGroups);
        } catch (error) {
          logger.error(error);
          dispatch(notifyError('Error fetching service groups'));
        }

        setLoading(false);
      })();
    }
  }, [props.open]);
  // #endregion

  // #region use memos
  const customerOptions = React.useMemo<NumberOption[]>(() => {
    const customerList = [...customers];

    const options = customerList.map<NumberOption>(c => {
      return {
        label: c.name,
        value: c.id,
        disabled: !UserUtils.userIsPermitted(
          UserPermissions.ApplicationManagerUsersManageCustomerLevel,
          c.id,
        ),
      };
    });

    return options;
  }, [customers, userBeingEdited]);

  const stateOptions = React.useMemo<NumberOption[]>(() => {
    return states.map<NumberOption>(state => {
      return {
        label: state.name,
        value: state.id,
      };
    });
  }, [states]);
  // #endregion

  // #region helper functions
  /**
   * When adding a user
   */
  const submitAddUser = async (
    submittedValues: IFormFields,
    sites: IFiltersCustomerItem[],
  ): Promise<void> => {
    try {
      if (
        submittedValues.role &&
        credentialedRoleIdValues.includes(submittedValues.role) &&
        !credentialingAtFields.fields.length &&
        !alreadyCredentialedAtFields.fields.length
      ) {
        setError(getFieldName('credentialingAt'), {
          type: 'manual',
        });
        throw new Error('User must be added to at least one site');
      }
      const payload: IRequestPayload = {
        customerRoles: [],
        externalId: submittedValues.selectedAdUser!.id,
        username: submittedValues.selectedAdUser!.username,
        displayName: submittedValues.selectedAdUser!.displayName,
        licensedStateIds: [],
      };

      if (currentRoleId) {
        if (submittedValues.customerRoles) {
          submittedValues.customerRoles.forEach(cr => {
            payload.customerRoles.push({
              customerId: cr.value,
              roleId: currentRoleId,
              permissionIds: undefined,
              serviceGroupIds: undefined,
            });
          });
        } else if (submittedValues.credentialingAt) {
          submittedValues.credentialingAt.forEach(ca => {
            if (ca.site != null) {
              const siteId = ca.site.value;
              const serviceGroupIds = ca.serviceGroups?.map(x => x.value);
              const permissionIds = ca.permissions?.map(x => x.value);
              payload.customerRoles.push({
                customerId: siteId,
                roleId: currentRoleId,
                permissionIds: permissionIds,
                serviceGroupIds: serviceGroupIds,
              });
            }
          });
        } else {
          sites.forEach(site => {
            payload.customerRoles.push({
              customerId: site.id,
              roleId: currentRoleId,
              permissionIds: undefined,
              serviceGroupIds: undefined,
            });
          });
        }
      }

      const fallbackRoleId = roles.find(x => x.id === currentRoleId)?.fallback_role_id;
      if (fallbackRoleId) {
        const payloadCustomerIds = payload.customerRoles.map(x => x.customerId);
        const customerIdsNotInPayload = sites
          .map(x => x.id)
          .filter(x => !payloadCustomerIds.includes(x));
        customerIdsNotInPayload.forEach(id => {
          payload.customerRoles.push({
            customerId: id,
            roleId: fallbackRoleId,
            permissionIds: undefined,
            serviceGroupIds: undefined,
          });
        });
      }

      if (submittedValues.statesLicensed) {
        payload.licensedStateIds = submittedValues.statesLicensed.map(x => x.value);
      }

      const response = ApplicationManagerClient.addNewUser(
        payload.customerRoles,
        payload.externalId,
        payload.username,
        payload.displayName,
        payload.licensedStateIds,
      );

      await response;
      dispatch(notifySuccess('Added user'));
      onSuccess();
    } catch (error) {
      logger.error(error);
      dispatchError(error, 'Error adding user');
    } finally {
      setSubmitting(false);
    }
  };

  const updateEditPayloadWithFallbackRole = (
    payload: IGetUserResponse,
    fallbackRoleId: number,
    submittedValues: IFormFields,
  ): void => {
    payload.customerRoles.forEach(cr => {
      const alreadyCredentialedAtExist = submittedValues.alreadyCredentialedAt
        ?.map(ac => ac.site!.value)
        ?.includes(cr.customer_id);
      const credentialedAtExist = submittedValues.credentialingAt
        ?.map(ca => ca.site!.value)
        ?.includes(cr.customer_id);
      const fallbackRoleIdValidation = cr.role_id !== fallbackRoleId;

      if (!alreadyCredentialedAtExist && !credentialedAtExist && fallbackRoleIdValidation) {
        cr.role_id = fallbackRoleId;
        cr.permissionIds = [];
        cr.serviceGroupIds = [];
      }
    });
  };

  /**
   * When editing an existing user
   */
  const submitEditUser = async (
    submittedValues: IFormFields,
    sites: IFiltersCustomerItem[],
  ): Promise<void> => {
    try {
      if (
        submittedValues.role &&
        credentialedRoleIdValues.includes(submittedValues.role) &&
        !credentialingAtFields.fields.length &&
        !alreadyCredentialedAtFields.fields.length
      ) {
        setError(getFieldName('credentialingAt'), {
          type: 'manual',
        });
        throw new Error('User must be added to at least one site');
      }
      if (
        currentRoleId &&
        listOfPharmacistRoles.includes(currentRoleId) &&
        !submittedValues.statesLicensed?.length
      ) {
        throw new Error('A license is required for all Pharmacist roles');
      }
      /**
       * Take the user that already exists in the database (userBeingEdited)
       * and the values in the form and combine it all. Then send it back to the API so that
       * it can update everything for that user.
       */
      if (userBeingEdited && currentRoleId) {
        const payload: IGetUserResponse = { ...userBeingEdited };
        const selectedRole = roles.find(x => x.id === currentRoleId);
        let fallbackRoleFlag = true;

        if (submittedValues.customerRoles) {
          fallbackRoleFlag = false;
          submittedValues.customerRoles.forEach(cr => {
            const existingCustomerRole = payload.customerRoles.find(
              x => x.customer_id === cr.value,
            );
            if (existingCustomerRole) {
              existingCustomerRole.role_id = currentRoleId;
            } else {
              payload.customerRoles.push({
                customer_id: cr.value,
                role_id: currentRoleId,
                permissionIds: [],
                serviceGroupIds: [],
              });
            }
          });
        }

        if (submittedValues.role && credentialedRoleIdValues.includes(submittedValues.role)) {
          const selectedSites =
            form && form.watch(getFieldName('customerRoles'))
              ? form.watch(getFieldName('customerRoles')).map((t: any) => t.value)
              : [];
          if (submittedValues.alreadyCredentialedAt && selectedSites.length) {
            submittedValues.alreadyCredentialedAt.forEach(ca => {
              if (ca.site) {
                const existingCustomerRole = payload.customerRoles.find(
                  x => x.customer_id === ca.site!.value && selectedSites.includes(x.customer_id),
                );
                const serviceGroups = ca.serviceGroups || [];
                const permissions = ca.permissions || [];
                if (existingCustomerRole) {
                  existingCustomerRole.role_id = currentRoleId;
                  existingCustomerRole.serviceGroupIds = serviceGroups.map(sg => sg.value);
                  existingCustomerRole.permissionIds = permissions.map(p => p.value);
                } else if (!payload.customerRoles.map(t => t.customer_id).includes(ca.site.value)) {
                  payload.customerRoles.push({
                    customer_id: ca.site.value,
                    role_id: currentRoleId,
                    serviceGroupIds: serviceGroups.map(sg => sg.value),
                    permissionIds: permissions.map(p => p.value),
                  });
                }
              }
            });
          } else if (submittedValues.alreadyCredentialedAt) {
            if (submittedValues.alreadyCredentialedAt) {
              submittedValues.alreadyCredentialedAt.forEach(ca => {
                if (ca.site) {
                  const existingCustomerRole = payload.customerRoles.find(
                    x => x.customer_id === ca.site!.value,
                  );
                  const serviceGroups = ca.serviceGroups || [];
                  const permissions = ca.permissions || [];
                  if (existingCustomerRole) {
                    existingCustomerRole.role_id = currentRoleId;
                    existingCustomerRole.serviceGroupIds = serviceGroups.map(sg => sg.value);
                    existingCustomerRole.permissionIds = permissions.map(p => p.value);
                  } else {
                    payload.customerRoles.push({
                      customer_id: ca.site.value,
                      role_id: currentRoleId,
                      serviceGroupIds: serviceGroups.map(sg => sg.value),
                      permissionIds: permissions.map(p => p.value),
                    });
                  }
                }
              });
            }
          }
          if (submittedValues.credentialingAt) {
            submittedValues.credentialingAt.forEach(ca => {
              if (ca.site) {
                const existingCustomerRole = payload.customerRoles.find(
                  cr => cr.customer_id === ca.site!.value,
                );
                const caServiceGroups = ca.serviceGroups || [];
                const caPermissions = ca.permissions || [];
                if (existingCustomerRole) {
                  existingCustomerRole.role_id = currentRoleId;
                  existingCustomerRole.serviceGroupIds = caServiceGroups.map(sg => sg.value);
                  existingCustomerRole.permissionIds = caPermissions.map(p => p.value);
                } else {
                  payload.customerRoles.push({
                    customer_id: ca.site.value,
                    role_id: currentRoleId,
                    serviceGroupIds: caServiceGroups.map(sg => sg.value),
                    permissionIds: caPermissions.map(p => p.value),
                  });
                }
              }
            });
          }
        } else {
          payload.customerRoles.forEach(cr => {
            cr.role_id = currentRoleId;
            cr.permissionIds = [];
            cr.serviceGroupIds = [];
          });
        }

        /**
         * Any roles in the payload that don't match the current role or the fallback role id,
         * update them so that they match.
         */
        if (selectedRole?.fallback_role_id && fallbackRoleFlag) {
          updateEditPayloadWithFallbackRole(
            payload,
            selectedRole.fallback_role_id,
            submittedValues,
          );
        }

        if (submittedValues.statesLicensed) {
          payload.licensedStates = submittedValues.statesLicensed.reduce<
            IGetUserLicensedStateItem[]
          >((acc, curr) => {
            acc.push({ id: curr.value, name: curr.label });
            return acc;
          }, []);
        } else {
          payload.licensedStates = [];
        }

        const response = ApplicationManagerClient.updateUser(
          userBeingEdited.id,
          true,
          (payload.licensedStates || []).map(x => x.id),
          payload.customerRoles,
        );
        await response;
        onSuccess();
      }
    } catch (error) {
      logger.error(error);
      if (error instanceof Error) {
        dispatchError(error, error.message ?? 'Error editing user');
      }
    } finally {
      setSubmitting(false);
    }
  };

  const onSuccess = () => {
    form.reset();
    props.onSuccess();
  };

  const onSubmit = async (
    submittedValues: IFormFields,
    sites: IFiltersCustomerItem[],
  ): Promise<void> => {
    setSubmitting(true);

    if (userBeingEdited == null) {
      await submitAddUser(submittedValues, sites);
    } else {
      await submitEditUser(submittedValues, sites);
    }
  };

  const submitInactive = async (userId: number | undefined) => {
    const user = users.find(x => x.id === userId);
    const message = `You are inactivating ${user?.display_name}. His/her credentials for any site will no longer be active and won't have access to Arbor anymore. Do you want to continue?`;
    setInactivateUserModal({
      open: true,
      text: message,
      userId: userId,
    });
  };

  const getModalTitle = (): string => {
    if (props.editingUser != null) {
      const user = users.find(x => x.id === props.editingUser?.userId);
      if (user) {
        return `Edit ${user?.display_name}`;
      }
    }
    return 'Add User';
  };

  const buildCustomerRolesValues = (
    customerRoles: { customerId: CustomerId; roleId: number }[],
  ): Record<CustomerId, IRoleSetting> => {
    const result = customerRoles.reduce<Record<CustomerId, IRoleSetting>>((acc, curr) => {
      acc[curr.customerId] = {
        roleId: curr.roleId,
        permissionIds: undefined,
        serviceGroupIds: undefined,
      };
      return acc;
    }, {});
    return result;
  };

  const clearCustomerRoleInfo = (): void => {
    form.setValue(getFieldName('customerRoles'), undefined);
  };

  const clearCredentialedAt = (): void => {
    form.setValue(getFieldName('credentialingAt'), []);
  };

  const selectResult = (value: IGetNewUsersResponseResult): void => {
    form.setValue(getFieldName('selectedAdUser'), value);
    form.setValue(getFieldName('username'), value.username);
  };

  const dispatchError = (error: any, fallbackMessage: string): void => {
    const errorData = error?.response?.data || {};
    const errorMsg: string = errorData.validationError || errorData.message || fallbackMessage;
    dispatch(notifyError(errorMsg));
  };

  const roleOptions = roles
    .filter(x => x.show_in_add_user_modal)
    .map<NumberOption>(x => {
      return { label: x.display_name, value: x.id };
    });

  const handleSearch = async (
    searchTerm: string,
  ): Promise<IGetNewUsersResponseResult[] | undefined> => {
    const request = ApplicationManagerClient.searchNewPatients(searchTerm);
    try {
      const response = await request;
      return response.data.results;
    } catch (error) {
      logger.error(error);
      dispatch(notifyError('Error searching for new users'));
    }

    return undefined;
  };

  const addSelectedCustomerId = (id: CustomerId): void => {
    setSelectedCustomerIds(prev => {
      const newState = new Set<number>(prev);
      newState.add(id);
      return newState;
    });
  };

  const removeSelectedCustomerId = (id: CustomerId): void => {
    setSelectedCustomerIds(prev => {
      const newState = new Set<number>(prev);
      newState.delete(id);
      return newState;
    });
  };

  const clearSelectedCustomerIds = (): void => {
    setSelectedCustomerIds(new Set<number>());
  };
  // #endregion

  // #region render functions
  /**
   * Wrapper for the credentialed liaision or pharmacist field set
   */
  const renderCredentialed = (): JSX.Element | null => {
    const selectedSites =
      form && form.watch(getFieldName('customerRoles'))
        ? form.watch(getFieldName('customerRoles')).map((t: any) => t.value)
        : [];
    const credentialedList: any[] = [];
    alreadyCredentialedAtFields.fields.map((field, index, arr) =>
      credentialedList.push(arr[index]),
    );
    const show = currentRoleId && roles.find(x => x.id === currentRoleId)?.show_credentialed_at;
    if (show) {
      return (
        <>
          <Grid item xs={12}>
            {(props.editingUser &&
            Object.keys(props.editingUser).length &&
            selectedSites.length &&
            credentialedList.length
              ? credentialedList.filter(field => selectedSites.includes(field.site.value))
              : alreadyCredentialedAtFields.fields
            ).map((field, index) => {
              return (
                <React.Fragment key={field.id}>
                  {renderAlreadyCredentialedAtFieldSet(field, index)}
                  <Divider />
                </React.Fragment>
              );
            })}
          </Grid>
          <Grid item xs={12}>
            {credentialingAtFields.fields.map((field, index) => {
              return (
                <React.Fragment key={field.id}>
                  {renderCredentialingFieldSet(field, index)}
                  <Divider />
                </React.Fragment>
              );
            })}
          </Grid>

          {customerOptions.length > 0 && (
            <Grid item xs={12} className={classes.addSiteButton}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  clearErrors();
                  credentialingAtFields.append({
                    site: undefined,
                    serviceGroups: [],
                    permissions: [],
                  });
                }}
              >
                + Add Site
              </Button>
            </Grid>
          )}
          {errors[getFieldName('credentialingAt')] && (
            <Validation
              touched={errors[getFieldName('credentialingAt')]}
              error="User must be added at least to one site"
            />
          )}
        </>
      );
    }
    return null;
  };

  const renderLicensedStates = (): JSX.Element | null => {
    return (
      <Grid item xs={7} className={classes.field}>
        <Controller
          name={getFieldName('statesLicensed')}
          control={form.control}
          rules={{ required: false }}
          render={(ctrlProps: any) => {
            return (
              <>
                <ReactSelect
                  label={StringUtils.formatRequired('Licensed', false)}
                  value={ctrlProps?.field?.value}
                  fields={stateOptions}
                  isMulti
                  menuPosition="relative"
                  data-qa-id={getQaId('form.states')}
                  handleOnChange={(value: NumberOption[]) => {
                    form.setValue(getFieldName('statesLicensed'), value);
                  }}
                  // data-qa-id={getQaId('states-licensed')}
                />
                <Validation
                  touched={
                    form.formState.errors.statesLicensed ||
                    (currentRoleId && listOfPharmacistRoles.includes(currentRoleId))
                  }
                  error={requiredErrorMsg}
                />
              </>
            );
          }}
        />
      </Grid>
    );
  };

  /**
   * For when it's things like site manager or corporate admin
   */
  const renderSitesSelector = (): JSX.Element | null => {
    return currentRoleId && roles.find(x => x.id === currentRoleId)?.show_site_selector ? (
      <Grid item xs={8}>
        <Controller
          name={getFieldName('customerRoles')}
          control={form.control}
          // rules={{ required: true }}
          render={(ctrlProps: any) => {
            return (
              <>
                <ReactSelect
                  label={StringUtils.formatRequired('Site', true)}
                  isMulti
                  fields={customerOptions}
                  value={formValues.customerRoles}
                  data-qa-id={getQaId('form.role')}
                  handleOnChange={(selectedSites: NumberOption[]) => {
                    form.setValue(getFieldName('customerRoles'), selectedSites);
                  }}
                />
                <Validation
                  touched={form.formState.errors.customerRoles}
                  error={requiredErrorMsg}
                />
              </>
            );
          }}
        />
      </Grid>
    ) : null;
  };

  /**
   * For things like credentialed liaision or pharmacist
   */
  const renderCredentialingFieldSet = (field: any, index: number): JSX.Element => {
    return (
      <React.Fragment key={field.id}>
        <Grid container item xs={12} className={classes.credentialedAtSiteFields}>
          {/* Credentialed At Site */}
          <Grid item xs={6}>
            <Controller
              name={getFieldArrayNameCredentialingAt('site', index)}
              control={form.control}
              rules={{ required: true }}
              render={(ctrlProps: any) => {
                return (
                  <>
                    <ReactSelect
                      label={StringUtils.formatRequired('Credentialed At Site', true)}
                      value={formValues.credentialingAt?.[index]?.site} // refactor
                      fields={customerOptions.filter(x => !selectedCustomerIds.has(x.value))}
                      isOptionDisabled={(option: NumberOption) => option.disabled === true}
                      isMulti={false}
                      data-qa-id={getQaId('form.add-credentialed-at-site')}
                      handleOnChange={(value: NumberOption) => {
                        // When the dropdown for site changes, remove the old item from the list
                        if (formValues.credentialingAt?.[index]?.site?.value != null) {
                          const value = formValues.credentialingAt?.[index]?.site?.value;
                          if (value != null) {
                            removeSelectedCustomerId(value);
                          }
                        }

                        // Then set the form value
                        const currentServiceGroups = serviceGroupsPerCustomer?.[value.value];
                        form.setValue(getFieldArrayNameCredentialingAt('site', index), value);
                        form.setValue(
                          getFieldArrayNameCredentialingAt('serviceGroups', index),
                          currentServiceGroups,
                        );

                        // Then add new the value to the list so that it can't be selected again
                        addSelectedCustomerId(value.value);
                      }}
                    />
                    <Validation
                      touched={form.formState.errors.credentialingAt?.[index]?.site}
                      error={requiredErrorMsg}
                    />
                  </>
                );
              }}
            />
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end" alignContent="center">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                // When the site is removed, remove the old item from the list so it can be selected again
                if (formValues.credentialingAt?.[index]?.site?.value != null) {
                  const value = formValues.credentialingAt?.[index]?.site?.value;
                  if (value != null) {
                    removeSelectedCustomerId(value);
                  }
                }
                // Then remove the site item field
                credentialingAtFields.remove(index);
              }}
            >
              Remove Site
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              {/* Service Groups */}
              <Controller
                name={getFieldArrayNameCredentialingAt('serviceGroups', index)}
                control={form.control}
                rules={{ required: false }}
                render={(ctrlProps: any) => {
                  return (
                    <>
                      <ReactSelect
                        label={StringUtils.formatRequired('Service Groups', false)}
                        value={formValues.credentialingAt?.[index]?.serviceGroups}
                        fields={
                          formValues.credentialingAt?.[index]?.site != null
                            ? serviceGroupsPerCustomer?.[
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
                                formValues.credentialingAt?.[index]?.site?.value!
                              ]
                            : []
                        }
                        isMulti
                        data-qa-id={getQaId('form.add-service-groups')}
                        handleOnChange={(value: NumberOption[]) => {
                          form.setValue(
                            getFieldArrayNameCredentialingAt('serviceGroups', index),
                            value,
                          );
                        }}
                      />
                      <Validation
                        touched={form.formState.errors.credentialingAt?.[index]?.serviceGroups}
                        error={requiredErrorMsg}
                      />
                    </>
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              {/* Permissions */}
              <Controller
                name={getFieldArrayNameCredentialingAt('permissions', index)}
                control={form.control}
                rules={{ required: false }}
                render={(ctrlProps: any) => {
                  return (
                    <>
                      <ReactSelect
                        label={StringUtils.formatRequired('Permissions', false)}
                        value={formValues.credentialingAt?.[index]?.permissions}
                        fields={permissionOptions}
                        isMulti
                        data-qa-id={getQaId('form.add.permissions')}
                        handleOnChange={(value: NumberOption[]) => {
                          form.setValue(
                            getFieldArrayNameCredentialingAt('permissions', index),
                            value,
                          );
                        }}
                      />
                      <Validation
                        touched={form.formState.errors.credentialingAt?.[index]?.permissions}
                        error={requiredErrorMsg}
                      />
                    </>
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const renderAlreadyCredentialedAtFieldSet = (field: any, index: number): JSX.Element => {
    return (
      <React.Fragment key={field.id}>
        <Controller
          name={getFieldArrayNameAlreadyCredentialedAt('removed', index)}
          control={form.control}
          defaultValue={field.removed}
          data-qa-id={getQaId('form.edit-already-credentialed-at')}
          render={() => {
            return <></>;
          }}
        />
        <Grid item xs={12} className={classes.crendentialedAtField}>
          <Grid container justifyContent="space-between">
            <Grid item xs="auto">
              <Controller
                name={getFieldArrayNameAlreadyCredentialedAt('site', index)}
                control={form.control}
                defaultValue={field.site}
                data-qa-id={getQaId('form.edit-credentialed-at-field')}
                render={(ctrlProps: any) => {
                  return (
                    <Typography key={index} variant="h6">
                      Credentialed @ {field.site?.label}{' '}
                    </Typography>
                  );
                }}
              />
            </Grid>
            <Grid item xs="auto">
              {formValues.alreadyCredentialedAt?.[index]?.removed ? null : (
                <Button
                  disabled={
                    !UserUtils.userIsPermitted(
                      UserPermissions.ApplicationManagerUsersManageCustomerLevel,
                      formValues.alreadyCredentialedAt?.[index]?.site?.value,
                    )
                  }
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    // When the site is removed, remove the old item from the list so it can be selected again
                    if (formValues.alreadyCredentialedAt?.[index]?.site?.value != null) {
                      const value = formValues.alreadyCredentialedAt?.[index]?.site?.value;
                      if (value != null) {
                        removeSelectedCustomerId(value);
                      }
                    }
                    // Then remove the site item field
                    alreadyCredentialedAtFields.remove(index);
                  }}
                  data-qa-id={getQaId('button.remove-credentials')}
                >
                  Remove Credentials
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.crendentialedAtField}>
          <Grid container>
            <Grid item xs={6}>
              <Controller
                name={getFieldArrayNameAlreadyCredentialedAt('serviceGroups', index)}
                control={form.control}
                defaultValue={field.serviceGroups}
                render={(ctrlProps: any) => {
                  return (
                    <ReactSelect
                      isDisabled={
                        formValues?.alreadyCredentialedAt?.[index]?.removed ||
                        !UserUtils.userIsPermitted(
                          UserPermissions.ApplicationManagerUsersManageCustomerLevel,
                          formValues.alreadyCredentialedAt?.[index]?.site?.value,
                        )
                      }
                      label={StringUtils.formatRequired('Service Group', false)}
                      value={formValues.alreadyCredentialedAt?.[index]?.serviceGroups || []}
                      fields={
                        field.site && serviceGroupsPerCustomer
                          ? serviceGroupsPerCustomer[field.site.value]
                          : []
                      }
                      isMulti
                      data-qa-id={getQaId('form.service-groups')}
                      key={index}
                      handleOnChange={(value: NumberOption | undefined) => {
                        const name: any = getFieldArrayNameAlreadyCredentialedAt(
                          'serviceGroups',
                          index,
                        );
                        form.setValue(name, value || []);
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={getFieldArrayNameAlreadyCredentialedAt('permissions', index)}
                control={form.control}
                defaultValue={field.permissions}
                render={(ctrlProps: any) => {
                  return (
                    <ReactSelect
                      isDisabled={
                        formValues.alreadyCredentialedAt?.[index]?.removed ||
                        !UserUtils.userIsPermitted(
                          UserPermissions.ApplicationManagerUsersManageCustomerLevel,
                          formValues.alreadyCredentialedAt?.[index]?.site?.value,
                        )
                      }
                      label={StringUtils.formatRequired('Permissions', false)}
                      value={formValues.alreadyCredentialedAt?.[index]?.permissions || []}
                      fields={permissionOptions}
                      data-qa-id={getQaId('form.permissions')}
                      isMulti
                      key={index}
                      handleOnChange={(value: NumberOption[] | undefined) => {
                        const name = getFieldArrayNameAlreadyCredentialedAt('permissions', index);
                        form.setValue(name, value || []);
                      }}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const renderInactivateModal = (): JSX.Element => {
    return (
      <InactivateUserModal
        selectedUserId={props.editingUser?.userId}
        open={Boolean(inactivateUserModal && inactivateUserModal.open)}
        onCancel={() => {
          setInactivateUserModal(undefined);
          props.onCancel();
        }}
        afterSuccess={() => {
          setInactivateUserModal(undefined);
          onSuccess();
        }}
        text={inactivateUserModal?.text}
      />
    );
  };
  // #endregion

  return (
    <Modal
      open={props.open}
      data-qa-id={getQaId('wrapper')}
      onBackdropClick={() => {
        props.onCancel();
      }}
    >
      <div style={getModalStyle()} className={classes.modal}>
        <Grid container direction="column" spacing={2}>
          {/* Title */}
          <Grid item xs="auto">
            <Typography className={classes.title} data-qa-id={getQaId('title')}>
              {getModalTitle()}
            </Typography>
          </Grid>

          {/* Form */}
          <Grid item xs="auto" data-qa-id={getQaId('form')}>
            <Grid container spacing={1}>
              {props.editingUser === undefined ? (
                <>
                  <Grid item xs={8}>
                    <Controller
                      name={getFieldName('selectedAdUser')}
                      control={form.control}
                      rules={{ required: true }}
                      render={(ctrlProps: any) => {
                        return (
                          <AutocompleteMinigrid
                            disabled={props.editingUser != null}
                            label={StringUtils.formatRequired('Search by name or last name', true)}
                            fetchOptions={handleSearch}
                            columnsToShow={{
                              displayName: 'Name',
                              username: 'Username',
                            }}
                            meta={{
                              touched: Boolean(form.formState.errors.selectedAdUser),
                              error: requiredErrorMsg,
                            }}
                            input={{
                              value: ctrlProps?.field?.value,
                              onBlur: ctrlProps?.field?.onBlur,
                              onChange: (item: IGetNewUsersResponseResult) => {
                                selectResult(item);
                              },
                            }}
                            labelField="displayName"
                            data-qa-id={getQaId('form.name')}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name={getFieldName('username')}
                      control={form.control}
                      rules={{ required: true }}
                      render={(ctrlProps: any) => {
                        return (
                          <>
                            <TextField
                              variant="standard"
                              fullWidth
                              label="User Name *"
                              value={ctrlProps?.field?.value}
                              disabled
                              InputProps={{
                                className: classes.disabledTextFieldInput,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              data-qa-id={getQaId('form.username')}
                            />
                            <Validation
                              touched={form.formState.errors.username}
                              error={requiredErrorMsg}
                            />
                          </>
                        );
                      }}
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12}>
                {renderLicensedStates()}
              </Grid>
              <Grid item xs={5}>
                <Controller
                  name={getFieldName('role')}
                  control={form.control}
                  rules={{ required: true }}
                  render={(ctrlProps: any) => {
                    return (
                      <RenderDropdown
                        dataQaId="edit-user-role-select"
                        width="100%"
                        label={StringUtils.formatRequired('Role', true)}
                        fields={roleOptions}
                        input={{
                          value: ctrlProps?.field?.value,
                          onBlur: ctrlProps?.field?.onBlur,
                          onChange: (item: any) => {
                            form.setValue(getFieldName('role'), item);
                            setCurrentRoleId(item);
                            clearSelectedCustomerIds();
                            clearCustomerRoleInfo();
                            clearCredentialedAt();
                          },
                        }}
                        meta={{
                          touched: Boolean(form.formState.errors.role),
                          error: requiredErrorMsg,
                        }}
                      />
                    );
                  }}
                />
              </Grid>
              {renderSitesSelector()}
              {renderCredentialed()}
              {renderInactivateModal()}

              <Grid item xs={12}>
                <Grid container>
                  {props.editingUser != undefined ? (
                    <Grid container justifyContent="flex-start" xs={6}>
                      <Button
                        onClick={() => {
                          submitInactive(props.editingUser?.userId);
                        }}
                        className={classes.inactivateButton}
                        data-qa-id={getQaId('button.inactivate')}
                        disabled={Boolean(userBeingEdited?.active) === false}
                      >
                        Inactivate User
                      </Button>
                    </Grid>
                  ) : (
                    <Grid container justifyContent="flex-start" xs={6} />
                  )}
                  <Grid container justifyContent="flex-end" spacing={2} xs={6}>
                    <Grid item>
                      <Button
                        data-qa-id={getQaId('button.cancel')}
                        onClick={() => {
                          setCurrentRoleId(undefined);
                          form.reset();
                          props.onCancel();
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <CircularLoadingButton
                        data-qa-id={getQaId('button.save')}
                        buttonLabel="Save"
                        onClick={form.handleSubmit((submittedValues: any) =>
                          onSubmit(submittedValues, customers),
                        )}
                        isLoading={submitting}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <LoadingOverlay open={loading} />
      </div>
    </Modal>
  );
};

import { renderDropdown } from 'components/form/field/redux-field';
import { ITaskTypeDescription } from './ITaskTypeDescription';
import { IDropdownDescription } from './ITaskFieldDescription';

import { renderDatePicker } from '../../components/form/datepicker/datetime-picker';

import { validateDateTime, required } from '../../components/form/validation/validation';

type FillCoordinationField =
  | 'canceled_dt'
  | 'completed_dt'
  | 'preferred_rx_delivery_method'
  | 'followup_dt'
  | 'last_checked_dt';

type FillCoordinationStatus = 'Required' | 'Completed' | 'In_Progress' | 'Canceled' | 'NeedsReview';

export const FillCoordinationDescription: ITaskTypeDescription<
  FillCoordinationField,
  FillCoordinationStatus
> = {
  taskType: 'fill_coordination',

  taskAbbreviation: 'FC',

  fieldMap: {
    followup_dt: {
      type: 'datetime',
      component: renderDatePicker,
      label: 'Follow Up Date',
      validators: [validateDateTime, required],
    },
    last_checked_dt: {
      type: 'datetime',
      component: renderDatePicker,
      label: 'Last Checked Date',
      validators: [validateDateTime],
    },
    canceled_dt: {
      type: 'datetime',
      component: renderDatePicker,
      label: 'Canceled Date',
      validators: [validateDateTime],
    },
    completed_dt: {
      type: 'datetime',
      component: renderDatePicker,
      label: 'Completed Date',
      validators: [validateDateTime],
    },
    preferred_rx_delivery_method: {
      type: 'dropdown',
      component: renderDropdown,
      label: 'Preferred Rx Delivery',
      options: [
        { label: 'Ship', value: 'Ship' },
        { label: 'Pick up', value: 'Pick up' },
      ],
      validators: [required],
    } as IDropdownDescription,
  },

  statusMap: {
    Required: 4001,
    Completed: 4002,
    Canceled: 4003,
    In_Progress: 4007,
    NeedsReview: 4008,
  },

  defaultStatus: 'Required',

  fieldsByStatus: {
    4001: ['followup_dt', 'last_checked_dt'],
    4002: ['followup_dt', 'last_checked_dt', 'completed_dt', 'preferred_rx_delivery_method'],
    4003: ['followup_dt', 'last_checked_dt', 'canceled_dt'],
    4007: [],
    4008: [],
  },
};

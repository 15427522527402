import React from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';
import { convertToArborDate } from 'models/time/arbor-date';
import { Grid, Modal, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { getModalStyle } from 'services/utils/styles-service';
import FormHeader from 'components/form/header/header';
import styles from './styles';

const EligibilityCheckHistoryDialog = props => {
  const { open, onClose, classes } = props;

  const eligibilityCheckHistory =
    useSelector(item => item.eligibilityCheck.eligibility_check_history) || [];
  return (
    <Modal open={open} onClick={e => e.stopPropagation()} onBackdropClick={onClose}>
      <div style={getModalStyle()} className={classes.addBenefitsInvestigationModal}>
        <Grid container justifyContent="center" spacing={1}>
          <Grid item xs={12}>
            <div className={classes.addFormContainer}>
              <Grid container>
                <Grid item xs={9}>
                  <FormHeader header="Eligibility Check History" />
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                {eligibilityCheckHistory.map(item => (
                  <>
                    <Grid item xs={2}>
                      <Typography>{`Id: ${item.id}`}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography>
                        {`Created:
                                  ${convertToArborDate(item.created).getCustomerDate(true)}`}
                      </Typography>
                    </Grid>
                  </>
                ))}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(EligibilityCheckHistoryDialog);

import React from 'react';
import WidthController from '../width-controller';

const Header = ({ title, classes }) => (
  <div className={classes.root}>
    <WidthController>{title}</WidthController>
  </div>
);

export default Header;

import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../nav-styles';
import FinancialsList from '../../../containers/patient/financials/financials-list';
import FinancialsListOld from '../../../containers/patient/financials/financials-list-old';

const Financials = ({ selectedPatientId }) => (
  <div>
    {window.FEATURES.new_financials_view ? (
      <FinancialsList patientId={selectedPatientId} />
    ) : (
      <FinancialsListOld patientId={selectedPatientId} />
    )}
  </div>
);

function mapStateToProps({ selectedPatientId, financials }) {
  return {
    selectedPatientId,
    financials,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps),
)(Financials);

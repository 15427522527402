import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import { expandTheme } from 'utils/theme-util';

export const useStyles = makeStyles((theme: Theme) => {
  const { palette } = expandTheme(theme);

  return {
    fpbContainer: {
      position: 'fixed',
      bottom: '200px',
      right: '0px',
      zIndex: 2,
    },
    fpbButton: {
      'color': palette.primary.white,
      'backgroundColor': palette.primary.deepBlue,
      'height': '48px',
      'width': '48px',
      'borderBottomLeftRadius': '10px',
      'borderTopLeftRadius': '10px',
      'cursor': 'pointer',
      'padding': '18px',
      'boxSizing': 'border-box',
      '& svg': {
        'width': '24px',
        'height': '24px',
        'top': '13px',
        'position': 'absolute',
        'left': '15px',
        '& #Fill-768': {
          fill: palette.primary.success,
        },
        '& #Fill-769': {
          fill: palette.primary.success,
        },
        '& #Fill-770': {
          fill: palette.primary.success,
        },
        '& #Fill-771': {
          fill: palette.primary.success,
        },
      },
    },
    fpbControlPanel: {
      position: 'absolute',
      right: '0px',
      bottom: '75px',
      color: palette.primary.white,
      backgroundColor: palette.primary.white,
      borderColor: palette.primary.deepBlue,
      border: '1px solid',
      minWidth: 380,
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
      padding: '20px',
      boxSizing: 'border-box',
      zindex: 999,
    },
    fpbControlPanelOnCall: {
      position: 'absolute',
      right: '0px',
      bottom: '0px',
      color: palette.primary.white,
      backgroundColor: palette.primary.white,
      borderColor: palette.primary.deepBlue,
      border: '1px solid',
      minWidth: 380,
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
      padding: '20px',
      boxSizing: 'border-box',
      zindex: 999,
    },
  };
});

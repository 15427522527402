import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { expandTheme } from 'utils/theme-util';

export const styles = (theme: Theme) => {
  const { palette, font } = expandTheme(theme);

  return createStyles({
    conversationContainer: {
      height: theme.spacing(55),
      overflowY: 'scroll',
      padding: theme.spacing(1.5),
    },
    title: {
      fontSize: font.largeFontSize,
      fontWeight: 'bold',
      color: palette.primary.steelGrey,
    },
    noMessagesWrapper: {
      backgroundColor: palette.primary.grey14,
      height: theme.spacing(12),
      borderRadius: theme.spacing(0.5),
    },
    noMessagesLabel: {
      color: palette.primary.rollingStone,
      fontSize: font.largeFontSize,
      fontWeight: 'bold',
    },
  });
};

type Styles = WithStyles<typeof styles>;

const useStyles = makeStyles(styles);

export const useTypedStyles = (): Styles => ({
  classes: useStyles(),
});

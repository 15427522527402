import { Theme } from '@mui/material';
import { expandTheme } from 'utils/theme-util';

export class ModalUtil {
  public static BasicModalStyling = (
    theme: Theme,
    widthInSpacing: number,
    padding = 2,
  ): any => {
    const { palette } = expandTheme(theme);
    return {
      position: 'absolute',
      maxWidth: theme.spacing(widthInSpacing),
      backgroundColor: palette.primary.background,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(padding),
      borderRadius: 5,
    };
  };
}

import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Dispatch } from 'redux';
import { ToggleDisplayAddTherapyModal } from '../../actions/action-form-displays';
import { ToggleFabPanel } from '../../actions/action-view';
import { IBasicActionItemProps, BasicActionItem } from './basic-action-item';

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onClick: () =>
      dispatch(new ToggleDisplayAddTherapyModal(true)) && dispatch(new ToggleFabPanel(false)),
  };
}

export default compose<IBasicActionItemProps, { inheritedClass: string }>(
  connect(() => ({ buttonText: '+ Medication' }), mapDispatchToProps),
)(BasicActionItem);

// eslint-disable-next-line no-unused-vars
import { IconButton, TableCell, TableRow, Tooltip, Box } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { INVOICING_SUMMARY_TYPES } from 'constants/index';
import React, { useState } from 'react';
import { numberToDollars, numberToPercentage } from 'services/utils/formatting';

import useStyles, { TableCellStyled } from './styles';

const format = (num, type) => {
  switch (type) {
    case INVOICING_SUMMARY_TYPES.PERCENTAGE:
      return numberToPercentage(num);
    case INVOICING_SUMMARY_TYPES.CURRENCY:
      return numberToDollars(num);
    default:
      return num;
  }
};
export const MOM_STYLE_SETTING = 'mom_table_cell_style';

const getStyleMomCellColor = (momStyleSetting, momValue) => {
  const { setting_value: settingValue } = momStyleSetting;
  const momValuePercentage = Math.abs(momValue * 100);
  let styleMom;
  JSON.parse(settingValue || '[]').forEach(item => {
    const { condition, value, style } = item;
    const [firstValue, secondValue] = value;
    if (condition === 'between') {
      if (momValuePercentage > firstValue && momValuePercentage < secondValue) {
        styleMom = style;
      }
    } else if (condition === 'gt') {
      if (momValuePercentage > firstValue) {
        styleMom = style;
      }
    }
  });

  return styleMom;
};

const renderDataPerPeriod = (period, category, summaryStyledTableCell, momStyleSetting) => {
  const styleMom = momStyleSetting
    ? getStyleMomCellColor(momStyleSetting, period.mom_percentage)
    : null;

  return (
    <>
      <TableCellStyled className={summaryStyledTableCell} align="center">
        {format(period.value, category.type)}
      </TableCellStyled>
      <TableCellStyled className={summaryStyledTableCell} align="center">
        {format(period.mom, category.type)}
      </TableCellStyled>
      <TableCellStyled {...styleMom} className={summaryStyledTableCell} align="center">
        {format(period.mom_percentage, INVOICING_SUMMARY_TYPES.PERCENTAGE)}
      </TableCellStyled>
    </>
  );
};

function SummaryCategoryRow(props) {
  const {
    invoicingCenteredItems,
    summaryStyledTableRow,
    summaryStyledCategoryCell,
    summaryStyledTableCell,
    summaryStyledClinicTableRow,
  } = useStyles();
  const { category, invoiceSettings } = props;
  const [displayChildren, setDisplayChildren] = useState(false);

  const momStyleSetting = invoiceSettings?.find(item => item.setting_name === MOM_STYLE_SETTING);

  return (
    <>
      <TableRow className={summaryStyledTableRow}>
        <TableCell className={summaryStyledCategoryCell}>
          <Box className={invoicingCenteredItems} justify="flex-start">
            <IconButton size="small" onClick={() => setDisplayChildren(!displayChildren)}>
              {!displayChildren ? <KeyboardArrowRight /> : <KeyboardArrowDown />}
            </IconButton>
            <Tooltip title={category.tooltip || ''}>
              <span>{category.display_name}</span>
            </Tooltip>
          </Box>
        </TableCell>
        {category.data.map(period =>
          renderDataPerPeriod(period, category, summaryStyledTableCell, momStyleSetting),
        )}
      </TableRow>

      {displayChildren &&
        category.clinics.map(clinic => (
          <TableRow className={summaryStyledClinicTableRow}>
            <TableCell>{clinic.clinic_name}</TableCell>
            {clinic.data.map(period => renderDataPerPeriod(period, category))}
          </TableRow>
        ))}
    </>
  );
}

export default SummaryCategoryRow;

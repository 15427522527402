import { EventTypeEnum } from '../containers/application-manager/rules-engine/modal/types';

// eslint-disable-next-line no-shadow
enum RuleStatusEnum {
  active = 1,
  inactive = 0,
}

export const RuleStatusOptions = [
  {
    label: 'Active',
    value: RuleStatusEnum.active,
  },
  {
    label: 'Inactive',
    value: RuleStatusEnum.inactive,
  },
];

export const EventTypeOptions = [
  {
    label: 'Therapy Enrolled',
    value: EventTypeEnum.TherapyEnrolled,
  },
  {
    label: 'Therapy Added',
    value: EventTypeEnum.TherapyAdded,
  },
  {
    label: 'Patient Enrolled',
    value: EventTypeEnum.PatientEnrolled,
  },
  {
    label: 'Insurance Added',
    value: EventTypeEnum.InsuranceAdded,
  },
  {
    label: 'Patient Document Added',
    value: EventTypeEnum.PatientDocumentAdded,
  },
  {
    label: 'Patient Document Updated',
    value: EventTypeEnum.PatientDocumentUpdated,
  },
  {
    label: 'Patient Preferences Updated',
    value: EventTypeEnum.PatientPreferencesUpdated,
  },
];

export const styles = theme => ({
  success: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.success,
  },
  warning: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.warning,
  },
  error: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.error.main,
  },
});

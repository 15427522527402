import React, { useMemo, useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { touch } from 'redux-form';
import JsonForm from 'components/dynamic-form/json-form';
import useProvider from 'hooks/useProvider';
import {
  INT,
  INTERVENTION_TYPES,
  DRUG_INFORMATION_INT_ITEMS,
  LINKAGE_TO_CARE_INT_ITEMS,
} from 'constants/index';
import useStore from 'hooks/useStore';
import { InterventionDescription } from 'models/tasks/index';
import { getEfficacySafetyInterventionOfDc } from 'services/utils/data-collect';
import { cloneDeep } from 'lodash';
import providerBuilder from '../providers/provider-builder';
import taskProviderHoc from '../task-forms/task-form-hoc';
import { styles } from '../../form/field/field-styles';
import { shouldRenderDcInIntervention } from '../helpers-tasks';

export const InterventionForm = ({ formId, ...otherProps }) => {
  const auth = useSelector(state => state.auth);
  const [firstRender, setFirstRender] = useState(true);

  const providers = providerBuilder('INT')(otherProps.data, { ...otherProps, auth, firstRender });
  const dispatch = useDispatch();

  useEffect(() => {
    setFirstRender(false);
  }, []);

  useEffect(() => {
    if (otherProps.meta.submitFailed) {
      dispatch(
        touch(`efficacySafetyIntervention${formId}`, ...providers.json.fields.map(f => f.property)),
      );
    }
  }, [otherProps.meta.submitFailed]);

  return (
    <JsonForm
      type={INT}
      formName="efficacySafetyIntervention"
      formId={`efficacySafetyIntervention${formId}`}
      jsonForm={providers.json}
      data={otherProps.data}
      forceStatusId={InterventionDescription.statusMap.Required}
      providers={providers}
      alwaysEnabled
      onFormChange={otherProps.onFormChange}
      onValidityChange={otherProps.onValidityChange}
      onUnmount={otherProps.onUnmount}
    />
  );
};

export const WrappedInterventionForm = taskProviderHoc(InterventionForm);

const useStyles = makeStyles(styles);

export default ({ input, providers, meta }) => {
  const classes = useStyles();
  const dcId = providers.dataCollectInformation ? providers.dataCollectInformation.id : null;
  const [efficacyProtocols, safetyProtocols, tasks, links, selectedWagTaskKeys, dcProtocols] =
    useStore(
      `dcProtocols.data.${dcId}.efficacyProtocols`,
      `dcProtocols.data.${dcId}.safetyProtocols`,
      'tasks.data',
      'links.data',
      'tasks.selectedWagTaskKeys',
      'dcProtocols.data',
    );
  const task = useProvider(providers, 'task', {});

  const protocols = useMemo(() => {
    let tempProtocols = [];
    if (
      selectedWagTaskKeys &&
      selectedWagTaskKeys.length &&
      dcProtocols &&
      Object.values(dcProtocols).length
    ) {
      let wagEfficacyProtocols = [];
      let wagSafetyProtocols = [];
      const listOfProtocolsKeys = Object.keys(dcProtocols);
      listOfProtocolsKeys.map(it => {
        const tempWagProtocols = dcProtocols[it];
        const tempTherapyId = tasks[`DC${it}`].therapy_id;
        if (tempWagProtocols && Object.keys(tempWagProtocols).length) {
          if (!wagEfficacyProtocols.length && tempWagProtocols.efficacyProtocols?.length) {
            wagEfficacyProtocols = tempWagProtocols.efficacyProtocols.map(eff => {
              return {
                ...eff,
                therapyId: tempTherapyId,
              };
            });
          } else if (wagEfficacyProtocols.length && tempWagProtocols.efficacyProtocols?.length) {
            const efficayPro = tempWagProtocols.efficacyProtocols.map(eff => {
              return {
                ...eff,
                therapyId: tempTherapyId,
              };
            });
            wagEfficacyProtocols = wagEfficacyProtocols.concat(efficayPro);
          }
          if (!wagSafetyProtocols.length && tempWagProtocols.safetyProtocols?.length) {
            wagSafetyProtocols = tempWagProtocols.safetyProtocols.map(eff => {
              return {
                ...eff,
                therapyId: tempTherapyId,
              };
            });
          } else if (wagSafetyProtocols.length && tempWagProtocols.safetyProtocols?.length) {
            const safetyPro = tempWagProtocols.safetyProtocols.map(eff => {
              return {
                ...eff,
                therapyId: tempTherapyId,
              };
            });
            wagSafetyProtocols = wagSafetyProtocols.concat(safetyPro);
          }
        }
      });
      tempProtocols = [
        ...wagEfficacyProtocols.map(protocol => ({
          ...protocol,
          type: 'efficacy',
        })),
        ...wagSafetyProtocols.map(protocol => ({
          ...protocol,
          type: 'safety',
        })),
      ];
    } else if (efficacyProtocols && safetyProtocols) {
      tempProtocols = [
        ...efficacyProtocols.map(protocol => ({
          ...protocol,
          type: 'efficacy',
        })),
        ...safetyProtocols.map(protocol => ({
          ...protocol,
          type: 'safety',
        })),
      ];
    }
    return tempProtocols;
  }, [efficacyProtocols, safetyProtocols, dcProtocols]);

  const getDefaultIntType = protocol => {
    if (DRUG_INFORMATION_INT_ITEMS.includes(protocol.clinicalDataTypeId)) {
      return INTERVENTION_TYPES.DRUG_INFORMATION;
    }

    if (LINKAGE_TO_CARE_INT_ITEMS.includes(protocol.clinicalDataTypeId)) {
      return INTERVENTION_TYPES.LINKAGE_TO_CARE;
    }

    return INTERVENTION_TYPES.REGIMEN;
  };

  const inputTypeIdValue = input.value[0]?.values?.type_id;
  const data = useMemo(() => {
    const dataList = protocols.map(protocol => {
      const existingIntervention =
        getEfficacySafetyInterventionOfDc(task.id, protocol.clinicalDataTypeId, links, tasks) || {};
      return {
        taskType: INT,
        status_id: InterventionDescription.statusMap.Required,
        ...existingIntervention,
        service_group_id:
          existingIntervention.service_group_id || task.service_group_id || task.serviceGroupId,
        type_id:
          existingIntervention.type_id ??
          (inputTypeIdValue &&
          protocol.clinicalDataTypeId === input.value[0]?.values?.clinical_data_type_id
            ? inputTypeIdValue
            : getDefaultIntType(protocol)),
        protocol,
      };
    });
    return dataList.filter(dataItem => {
      const shouldRender = !dataItem.id || shouldRenderDcInIntervention(dataItem, links, tasks);
      return shouldRender;
    });
  }, [protocols, links, inputTypeIdValue]);

  const getNextItem = (accumulatedValues, inputValues) => {
    const itemToAdd = accumulatedValues
      .filter(t => t && t.values && Object.keys(t.values).length)
      .reduce((acc, it) => {
        if (it.values && Object.keys(it.values).length) {
          const listToCompare = inputValues.map(a => a?.values);
          let exist = false;
          if (listToCompare.length) {
            for (const item of listToCompare) {
              if (
                item?.clinical_data_type_id === it.values.clinical_data_type_id &&
                item?.therapy_id === it.values.therapy_id
              ) {
                exist = true;
              }
            }
          }
          if (!exist) {
            acc.push(it);
          }
        }
        return acc;
      }, []);
    return itemToAdd;
  };

  if (protocols.length === 0) {
    return null;
  }
  const accumulatedValues = [];
  const accumulatedValid = [];

  return (
    <>
      {data.map((dataItem, index) => {
        const { protocol } = dataItem;
        const formId = `${protocol.type}-${protocol.clinicalDataTypeName}-${index}`;

        const handleOnChange = (values, valid) => {
          accumulatedValues[index] = {
            values: {
              ...values,
              id: dataItem.id,
              clinical_data_type_id: dataItem.protocol.clinicalDataTypeId,
              trigger: dataItem.protocol.trigger,
              trigger_condition: dataItem.protocol.triggerCondition,
              trigger_threshold: dataItem.protocol.triggerThreshold,
              trigger_type: dataItem.protocol.type,
              therapy_id: dataItem.protocol.therapyId,
            },
            valid,
          };

          if (!selectedWagTaskKeys.length) {
            const newValues = data.map((d, formIndex) => {
              const formValues = input.value[formIndex] ||
                accumulatedValues[formIndex] || {
                  values: {},
                  valid: false,
                };
              if (formIndex === index) {
                return accumulatedValues[formIndex];
              }
              return formValues;
            });
            input.onChange(newValues);
          } else {
            const inputValueList = cloneDeep(input.value);
            const newValues = data.map((d, formIndex) => {
              let formValues = {
                values: {},
                valid: false,
              };
              if (
                input.value[formIndex]?.values &&
                Object.keys(input.value[formIndex].values).length
              ) {
                const mostRecentData = accumulatedValues.find(
                  t =>
                    t &&
                    t.values &&
                    Object.keys(t.values).length &&
                    t.values.clinical_data_type_id ===
                      input.value[formIndex].values.clinical_data_type_id &&
                    t.values.therapy_id === input.value[formIndex].values.therapy_id,
                );
                if (mostRecentData !== undefined) {
                  formValues = mostRecentData;
                } else {
                  formValues = input.value[formIndex];
                }
              } else if (accumulatedValues.length) {
                const item = getNextItem(accumulatedValues, inputValueList);
                formValues = item[0] ?? formValues;
                inputValueList.push(item[0]);
              }
              return formValues;
            });
            input.onChange(newValues);
          }
        };

        const handleValidityChange = valid => {
          accumulatedValid[index] = valid;
          const newValues = data.map((d, formIndex) => {
            accumulatedValid[formIndex] = valid;
            const formValues = input.value[formIndex]
              ? {
                  values: input.value[formIndex].values,
                  valid: accumulatedValid[formIndex] || input.value[formIndex].valid,
                }
              : {
                  values: {},
                  valid: true,
                };
            if (formIndex === index) {
              return {
                values: formValues.values,
                valid: accumulatedValid[formIndex],
              };
            }
            return formValues;
          });
          input.onChange(newValues);
        };

        // when form gets unmounted, clean it from values
        const handleOnUnmount = () => {
          const filteredValues = input.value.filter((form, i) => i !== index);
          input.onChange(filteredValues);
        };

        return (
          <Box className={classes.innerFormsItemContainer} key={formId}>
            <Typography
              className={classes.fieldLabelLarge}
              data-qa-id={`intervention-name-${index}`}
            >
              {`Intervention for ${protocol.clinicalDataTypeName}`}
            </Typography>
            <Typography>{protocol.trigger}</Typography>
            <WrappedInterventionForm
              data={data[index]}
              formId={formId}
              protocol={protocol}
              onValidityChange={handleValidityChange}
              onFormChange={handleOnChange}
              onUnmount={handleOnUnmount}
              meta={meta}
            />
          </Box>
        );
      })}
    </>
  );
};

import React from 'react';
import withStyles, { WithStyles } from '@mui/styles/withStyles';
import { CheckCircle, Cancel, Help, Error } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { IndicatorStyles } from './styles';

interface IconWithTooltipProps extends WithStyles<typeof IndicatorStyles> {
  tooltip: string;
  type: 'check' | 'cancel' | 'help' | 'warning';
  inlineStyle?: any;
}

function iconWithTooltip({
  tooltip,
  type,
  classes,
  inlineStyle,
}: IconWithTooltipProps): JSX.Element {
  const renderIcon = () => {
    switch (type) {
      case 'cancel':
        return <Cancel style={inlineStyle} className={classes.cancel} />;
      case 'help':
        return <Help style={inlineStyle} className={classes.help} />;
      case 'warning':
        return <Error style={inlineStyle} className={classes.cancel} />;
      case 'check':
        return <CheckCircle style={inlineStyle} className={classes.check} />;
      default:
        return <></>;
    }
  };
  if (!tooltip) {
    return renderIcon();
  }
  return <Tooltip title={tooltip}>{renderIcon()}</Tooltip>;
}

export default withStyles(IndicatorStyles)(iconWithTooltip);

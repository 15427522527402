import React from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { pbmModalActions } from 'slices/pbm-modal-slice';
import { useDispatch } from 'react-redux';
import { useStyles } from './styles';
import { Title } from './title';

export const NoPbmInsurance: React.FC = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Title />
      </Grid>

      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={7}>
          <Typography className={classes.content}>
            This patient does not have any PBM insurance on file. Add insurance before running a
            benefit investigation.
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <Grid container>
            <Grid item justifyContent="flex-end">
              <Button
                onClick={() => dispatch(pbmModalActions.showModal())}
                className={classes.button}
              >
                Add Insurance
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

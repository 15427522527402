export const styles = theme => ({
  container: {
    border: `1px solid ${theme.palette.primary.black}`,
    borderRadius: 5,
    padding: 10,
    marginTop: 20,
    cursor: 'pointer',
  },
  docContainer: {
    backgroundColor: theme.palette.primary.white,
    marginBottom: 5,
    borderRadius: 5,
    padding: 5,
    width: '98%',
    maxHeight: 170,
    overflow: 'hidden',
    position: 'relative',
    fontSize: theme.font.smallFontSize,
  },
  pointer: {
    cursor: 'pointer',
  },
  archivedDocContainer: {
    backgroundColor: theme.palette.primary.archive.background,
    cursor: 'initial',
  },
  bottomPadding: {
    paddingBottom: 5,
  },
  fileTypeIcon: {
    height: 25,
  },
  closeIconContainer: {
    textAlign: 'center',
  },
  linkIcon: {
    color: theme.palette.primary.lightGrey,
    paddingRight: 5,
  },
  linkText: {
    fontSize: theme.font.smallFontSize,
    fontWeight: 500,
    color: theme.palette.primary.lightGrey,
  },
  metaText: {
    color: theme.palette.primary.lightGrey,
    fontWeight: 500,
    fontSize: theme.font.smallFontSize,
  },
  documentMessage: {
    fontWeight: 500,
    fontSize: theme.font.smallFontSize,
    whiteSpace: 'pre-wrap',
  },
  alertText: {
    color: theme.palette.error.main,
  },
  warningText: {
    color: theme.palette.primary.warning,
  },
  fileName: {
    fontSize: theme.font.mediumFontSize,
    fontWeight: 400,
  },
  uploadedDocumentItemContainer: {
    padding: 10,
  },
  alignRight: {
    textAlign: 'right',
  },
  tag: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    bottom: 0,
  },
});

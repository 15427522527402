import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { Table, TableBody } from '@mui/material';
import { connect } from 'react-redux';
import { isValidArray } from 'services/utils/common-service';
import { compose } from 'recompose';
import { updateReportSidebar } from 'actions/action-report';
import { push } from 'connected-react-router';
import { styles } from '../side-bar-styles';
import ReportListHeader from './report-list-header';
import ReportListRow from './report-list-row';

const ReportList = props => {
  const { classes, reports } = props;
  const { reportSidebar } = reports;

  return (
    <div className={classes.tableContainer}>
      <Table className={classes.table}>
        <ReportListHeader />
        <TableBody className={classes.tableBody}>
          {reportSidebar &&
            isValidArray(reportSidebar.data) &&
            reportSidebar.data.map((reportRow, index) => (
              <ReportListRow reportRow={reportRow} index={index} />
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

function mapStateToProps(state) {
  const { patient, reports } = state;
  return {
    patient,
    reports,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, { updateReportSidebar, push }),
)(ReportList);

import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import HTTP from 'services/http';
import { compose } from 'recompose';
import { styles } from '../styles/auth-page.styles';
import Paragraph from '../components/paragraph';
import Button from '../components/button';
import DobInput from '../components/dob-input';
import PAGE_INDEX from './page_index';

const CONTINUE_WITH_ORDER_PAGE = PAGE_INDEX.CONTINUE_WITH_ORDER_PAGE;

const AuthPage = ({
  classes,
  setPage,
  setDobIsVerified,
  setPatientDob,
  questionnaireLink,
  onError,
  setQuestionnaireFromData,
  setReady,
}) => {
  const [remainingAuthAttempts, setRemainingAuthAttempts] = useState(3);
  const [hasBirthDateError, setHasBirthDateError] = useState(false);
  const [forcedDobWasChecked, setForcedDobWasChecked] = useState(false);
  const [dob, setDob] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const forcedDob = urlParams.get('dob');
    if (forcedDob) {
      return forcedDob;
    }
    return null;
  });

  const local_response_handler = async () => {
    HTTP.post(`/public/questionnaires/${questionnaireLink}`, { dob_provided: dob })
      .then(response => {
        try {
          setForcedDobWasChecked(true);
          const { data } = response;
          if (data.successful_auth) {
            setPatientDob(dob);
            setDobIsVerified(true);
            setReady(true);
            setQuestionnaireFromData(data);
            setPage(1);
          } else if (data?.remaining_auth_attempts <= 0) {
            setHasBirthDateError(true);
            setRemainingAuthAttempts(0);
            if (window.self !== window.top) {
              setPage(CONTINUE_WITH_ORDER_PAGE);
            }
          } else {
            setRemainingAuthAttempts(data?.remaining_auth_attempts);
          }
        } catch (e) {
          if (window.self !== window.top) {
            setPage(CONTINUE_WITH_ORDER_PAGE);
          } else {
            onError('Failed to load the questionnaire', e);
          }
        }
      })
      .catch(ex => {
        if (window.self !== window.top) {
          setPage(CONTINUE_WITH_ORDER_PAGE);
        } else {
          onError('The questionnaire was not found', ex);
        }
      });
  };

  const urlParams = new URLSearchParams(window.location.search);
  const forcedDob = urlParams.get('dob');
  if (forcedDob && !forcedDobWasChecked) {
    local_response_handler();
    return null;
  }

  return (
    <Box className={classes.container}>
      <Grid container spacing={3.5}>
        <Grid container item xs={12}>
          <Grid item xs={12} data-qa-id="title">
            <Paragraph type="title">We have some health-related questions for you.</Paragraph>
          </Grid>
          <Grid item xs={12}>
            <Box my={0} component="hr" width={250} className={classes.divider} />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={3.25}>
          <Grid item xs={12} data-qa-id="sub-title">
            <Paragraph>Please confirm the patient&apos;s</Paragraph>
            <Paragraph>date of birth to continue</Paragraph>
          </Grid>
          <Grid item xs={12}>
            <DobInput
              updateDob={setDob}
              dobValue={dob}
              {...(hasBirthDateError && { error: true })}
            />
          </Grid>
          <Grid item xs={12} marginBottom={6} data-qa-id="notes">
            {remainingAuthAttempts > 0 ? (
              <>
                {remainingAuthAttempts !== 3 ? (
                  <Typography
                    type="subText"
                    className={classes.failedAttemptsMessage}
                    data-qa-id="attempts"
                  >
                    {`You have ${remainingAuthAttempts} attempt${
                      remainingAuthAttempts > 1 ? 's' : ''
                    } left.`}
                  </Typography>
                ) : null}
                <Paragraph type="subText">Note - After 3 unsuccessful</Paragraph>
                <Paragraph type="subText">attempts you will not be able</Paragraph>
                <Paragraph type="subText">to continue.</Paragraph>
              </>
            ) : (
              <>
                <Paragraph type="subText">The date of birth does not match</Paragraph>
                <Paragraph type="subText">your records. A clinic representative</Paragraph>
                <Paragraph type="subText">will contact you soon to help in</Paragraph>
                <Paragraph type="subText">completing your health information.</Paragraph>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Box textAlign="center">
        <Grid container item xs={12} spacing={6}>
          <Grid item xs={12}>
            <Button
              className={classes.button}
              {...(hasBirthDateError || !dob
                ? { disabled: true }
                : { onClick: () => local_response_handler() })}
              data-qa-id="start"
            >
              Start
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default compose(withStyles(styles))(AuthPage);

export enum globalZIndex {
  logoutModal = 1350,
  loadingBlock = 1345,
  tooltip = 1340,
  reactSelect = 1330,
  accreditationModal = 1305,
  editSMSTagsModal = 1305,
  notePanel = 1301,
  taskBar = 1201,
}

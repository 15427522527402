import makeStyles from '@mui/styles/makeStyles';
import { ModalUtil } from 'services/utils/modal.util';

export const styles = makeStyles(theme => {
  return {
    modal: {
      ...ModalUtil.BasicModalStyling(theme, 50, 3),
      width: theme.spacing(50),
    },
  };
});

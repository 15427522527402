import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

export const styles = (theme: Theme) => ({
  rootContainer: {
    padding: 15,
    minWidth: theme.spacing(45),
  },
  titleText: {
    color: '#5d87af',
  },
});

export default makeStyles(theme => styles(theme));

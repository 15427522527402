import React from 'react';
import { Grid, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { ActiveInactiveFormStyles } from './active-inactive-form.styles';

interface ITherapeuticAreaFormProps extends WithStyles<typeof ActiveInactiveFormStyles> {
  initialValue: boolean;
  options?: { id: number; name: string }[];
  handleCancel: () => void;
  handleSubmit: (value: number) => void;
}

/** This isn't really a "form" per se, but it's still rendered in a popout thing.  */
const TherapeuticAreaForm: React.FC<ITherapeuticAreaFormProps> = (
  props: ITherapeuticAreaFormProps,
): JSX.Element => {
  const { classes } = props;

  // #endregion

  return (
    <Grid container direction="column" className={classes.container}>
      {props?.options?.map(item => (
        <Grid
          item
          className={classes.option}
          onClick={() => {
            props.handleSubmit(item.id);
          }}
        >
          <Typography className={classes.optionType}>{item.name}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default withStyles(ActiveInactiveFormStyles)(TherapeuticAreaForm);

import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import React from 'react';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { Grid } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { nameOfFactory } from 'utils/types-util';
import { NumberOption } from 'interfaces/forms/types';
import { ReactSelect } from 'components/form/field/react-select';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { TherapeuticClassFormStyles } from './therapeutic-class-form.styles';
import { IFormFields } from './types';

type TherapeuticClassFormFields = IFormFields<NumberOption>;

interface ITherapeuticFormProps extends WithStyles<typeof TherapeuticClassFormStyles> {
  initialValue: number;
  handleCancel: () => void;
  handleSubmit: (value: number) => void;
}

const getFieldName = nameOfFactory<TherapeuticClassFormFields>();

const TherapeuticClassForm: React.FC<ITherapeuticFormProps> = (
  props: ITherapeuticFormProps,
): JSX.Element => {
  const { classes } = props;
  const therapeuticClasses = useTypedSelector(state => state.lookups.trellisTherapeuticsClasses);

  // #region component state
  const { handleSubmit, control } = useForm<any>();
  // #endregion

  // #region useMemo
  const options = React.useMemo<NumberOption[]>(() => {
    const mappedOptions = therapeuticClasses.map<NumberOption>(x => {
      return {
        label: x.name,
        value: x.id,
      };
    });
    return mappedOptions;
  }, [therapeuticClasses]);
  // #endregion

  // #region helpers
  const onSubmit: SubmitHandler<any> = (formValues: TherapeuticClassFormFields) => {
    const formattedValue = formValues.selectedValue.value;
    props.handleSubmit(formattedValue);
  };

  const onCancel = () => {
    props.handleCancel();
  };
  // #endregion

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item>
        <form>
          <Controller
            control={control}
            name={getFieldName('selectedValue')}
            rules={{ required: true }}
            defaultValue={options.find(x => x.value === props.initialValue)}
            render={(fieldProps: any) => (
              <ReactSelect
                name={getFieldName('selectedValue')}
                value={fieldProps?.field?.value}
                fields={options}
                isMulti={false}
                menuPosition="relative"
                handleOnChange={fieldProps?.field?.onChange}
              />
            )}
          />
        </form>
      </Grid>
      <Grid item>
        <ConfirmationPanel
          handleSubmit={handleSubmit(onSubmit)}
          handleCancel={() => {
            onCancel();
          }}
        />
      </Grid>
    </Grid>
  );
};

export const TherapeuticClassFormStyled = withStyles(TherapeuticClassFormStyles)(
  TherapeuticClassForm,
);

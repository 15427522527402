export const buildQaId =
  (componentName: string, separator = '-') =>
  (suffix: string): string =>
    `${componentName}${separator}${suffix}`;

export const buildQaIdProp =
  (componentName: string, separator = '-') =>
  (suffix: string): Record<string, string> => ({
    'data-qa-id': buildQaId(componentName, separator)(suffix),
  });

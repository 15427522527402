import { ITherapy } from 'interfaces/redux/ITherapy';

// eslint-disable-next-line no-shadow
export enum TaskFCDispensingModalActionType {
  open = 'TASK_FC_DISPENSING_MODAL_OPEN',
  resetState = 'TASK_FC_DISPENSING_MODAL_RESET_STATE',
}

export interface ITaskFCDispensingModalAction {
  type: TaskFCDispensingModalActionType;
}

export const taskFCDispensingModalActions = {
  open: (): ITaskFCDispensingModalAction => ({
    type: TaskFCDispensingModalActionType.open,
  }),
  resetModal: () => {
    return {
      type: TaskFCDispensingModalActionType.resetState,
    };
  },
};

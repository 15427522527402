import { ITask } from '../ITasks';

export enum IncidentResponsibleTypes {
  User = 'User',
  IncidentExternalResponsible = 'IncidentExternalResponsible',
  IncidentInternalResponsible = 'IncidentInternalResponsible',
}

interface IResponsible {
  reference: string;
  type: IncidentResponsibleTypes;
  display: string;
  identifier: number;
  other: string | null;
}

interface IIncidentTask extends ITask {
  patient_id: number;
  category_id: number;
  context_id: number;
  is_internal: boolean;
  is_medication_error: boolean;
  is_hipaa_breach: boolean | null;
  is_patient: boolean;
  medication_error_category_id: number | null;
  medication_error_type_id: number | null;
  other_medication_error_type: number | null;
  reporting_party_id: number | null;
  other_reporting_party: number | null;
  type_id: number;
  other_type: number | null;
  due_date: string | null;
  submitted_dt: string | null;
  resolved_dt: string | null;
  incident_dt: string | null;
  resolution_type: number | null;
  resolution_detail: number | null;
  override_reason_id: number | null;
  patient_medical_insurances: string;
  patient_pbm_insurances: string;
  details: string | null;
  discharged_date: string | null;
  status_id_prior_to_discharge: number | null;
  status_category_id: number;
  responsibles: IResponsible[];
  order: number;
}

export type { IIncidentTask, IResponsible };

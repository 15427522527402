import React from 'react';
import { Grid } from '@mui/material';
import { buildHandleEnterKeyPress } from '../../services/utils/accessibility';

export interface IBasicActionItemProps {
  buttonText: string;
  inheritedClass: string;
  onClick: () => void;
  id: string;
}

// tslint:disable-next-line:function-name
export function BasicActionItem(props: IBasicActionItemProps) {
  return (
    <Grid
      item
      xs={6}
      className={props.inheritedClass}
      onClick={() => props.onClick()}
      onKeyPress={buildHandleEnterKeyPress(() => props.onClick())}
      role="button"
      tabIndex={0}
      id={props.id}
    >
      {props.buttonText}
    </Grid>
  );
}

import React from 'react';
import { BaseTable } from 'containers/application-manager/base-table/base-table';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid } from '@mui/material';
import { useTypedSelector } from 'hooks/use-typed-selector';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { UseStyles } from '../../table.styles';
import { COLUMN_SETTINGS, PAGINATION_PARAMS, CtdConstants } from '../../constants';
import {
  IResult,
  IChild,
  IIncludeExcludeApiResponse,
  ITableProps,
  ComponentState,
} from '../../types';
import { getDataAndUpdateState } from '../../utils';
import { booleanConvert, dateConvert } from '../../../utils';

const childPkSelector = (child: IChild) => child.drug_descriptor_id;
const parentPkSelector = (parent: IResult) => parent.drug_descriptor_id;

export const IncludedTableView: React.FC<ITableProps> = (props): JSX.Element => {
  const classes: any = UseStyles();
  // #region Redux
  const therapeuticClasses = useTypedSelector(state => state.lookups.trellisTherapeuticsClasses);
  // #endregion

  // #region useState
  const [tableState, setTableState] = React.useState<ComponentState>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(CtdConstants.defaultRowsPerPage);
  // #endregion

  // #region use effect method
  React.useEffect(() => {
    let therapeuticClassUrlBuilder = `application-manager/ctd/included/therapeutic-classes/${props.selectedCustomerId}`;
    const therapeuticClassDataUrlBUilder = (therapeuticClassId: number) =>
      `application-manager/ctd/included/${props.selectedCustomerId}/${therapeuticClassId}`;

    if (props.searchTerm) {
      therapeuticClassUrlBuilder += `?searchTerm=${props.searchTerm}`;
    }
    (async () => {
      await getDataAndUpdateState(
        therapeuticClasses,
        therapeuticClassUrlBuilder,
        therapeuticClassDataUrlBUilder,
        setTableState,
        true,
        false,
      );
    })();
  }, [props.selectedCustomerId, props.searchTerm, therapeuticClasses]);
  // #endregion

  // #region helpers
  const toggleExpansion = (id: number, open: boolean) => {
    const newState = [...tableState];
    const item = newState.find(x => x.id === id);
    if (item) {
      item.open = open;
    }
    setTableState(newState);
  };
  // #endregion

  return (
    <>
      {tableState.map(value => {
        return (
          <React.Fragment key={value.id}>
            <Accordion
              className={classes.expansionPanelStyles}
              expanded={value.open}
              onChange={(event, expanded) => {
                toggleExpansion(value.id, expanded);
              }}
              TransitionProps={{
                unmountOnExit: true,
                mountOnEnter: true,
              }}
            >
              <AccordionSummary className={classes.expansionHeader}>
                <Grid container direction="row" spacing={3}>
                  <Grid container justifyContent="space-between">
                    <Grid item xs="auto" className={classes.expansionHeader}>
                      <Grid container spacing={1} data-qa-id="container 2">
                        <Typography className={classes.expansionHeaderTextLeft}>
                          {value.name}
                        </Typography>
                        <ExpandMoreIcon className={classes.expansionHeaderExpandIcon} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails className={classes.expansionDetails}>
                <BaseTable<IIncludeExcludeApiResponse, IResult, IChild>
                  actionsPermitted={false}
                  classesOverride={{ tableContainer: classes.tableContainer }}
                  orderByDefaultChild="drug_name"
                  endpointBase={value.url}
                  paginationQueryParamSettings={PAGINATION_PARAMS}
                  childPkSelector={childPkSelector}
                  parentPkSelector={parentPkSelector}
                  columnSettings={[
                    ...COLUMN_SETTINGS,
                    {
                      label: 'Purchase By Customer',
                      sortable: true,
                      childKey: 'can_be_purchased_by_customer',
                      childValueFormatter: booleanConvert,
                    },
                    {
                      label: 'Available date',
                      sortable: true,
                      childKey: 'available_date',
                      childValueFormatter: dateConvert,
                    },
                    {
                      label: 'End date',
                      sortable: true,
                      childKey: 'end_date',
                      childValueFormatter: dateConvert,
                    },
                  ]}
                  rowsPerPageSettings={{
                    override: rowsPerPage,
                    onChange: perPage => {
                      setRowsPerPage(perPage);
                    },
                  }}
                  searchTerm={props.searchTerm}
                />
              </AccordionDetails>
            </Accordion>
          </React.Fragment>
        );
      })}
    </>
  );
};

import * as React from 'react';

import { Drawer, Backdrop, useTheme, useMediaQuery } from '@mui/material';
import { Breakpoint } from '@mui/material/styles';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const classNames = require('classnames');

interface ICollapsibleSideBarProps {
  classes: any;
  onToggleDrawer: (newState?: boolean) => void;
  isLeftDrawerOpen: boolean;
  usePersistentForMd: boolean;
  width: Breakpoint;
  children?: React.ReactNode;
}

export const CollapsibleSideBar = (props: ICollapsibleSideBarProps) => {
  const { classes, children, isLeftDrawerOpen, onToggleDrawer } = props;
  let backdrop;
  const theme = useTheme();
  if (useMediaQuery(theme.breakpoints.down('xl'))) {
    backdrop = (
      <Backdrop
        open={!isLeftDrawerOpen}
        classes={{
          root: classNames(classes.taskListBackdrop),
        }}
      />
    );
  }

  return (
    <>
      <Drawer
        classes={{
          paper: classNames(classes.taskList),
        }}
        variant="persistent"
        open={!isLeftDrawerOpen}
        anchor="left"
        onClose={() => onToggleDrawer(false)}
      >
        {children}
      </Drawer>
      {backdrop}
    </>
  );
};

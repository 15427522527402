import { TableCell, TableRow } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { FDC, STAR } from 'constants/index';
import { sortTaskListBy, groupTasksByDate } from 'services/utils/task-service';
import { styles } from '../side-bar-styles';
import TaskListRow from './task-list-row';
import { FillDeliveryConfirmationStatus } from '../../../../interfaces/enums/TaskStatuses/FillDeliveryConfirmationStatus';

const DateSortedTaskList = props => {
  const {
    classes,
    objList,
    objListSort,
    defaultDateSortType,
    selectedRow,
    clinicalDataFields,
    taskType,
    userCustomList,
  } = props;

  const { order, orderBy } = objListSort;
  const sortedTaskArrMoments = sortTaskListBy(objList, orderBy, order, true, defaultDateSortType);
  const timeDivider = (time, index) => (
    <TableRow key={index}>
      <TableCell colSpan={4} align="center" className={classes.tableRowTimeDivider}>
        {time}
      </TableCell>
    </TableRow>
  );

  let bucketedTasks = [];
  if (taskType === STAR) {
    bucketedTasks = userCustomList.map(item => ({
      label: item.name || 'My Highlighted Patients',
      tasks: item.list.map(obj => ({ ...obj, taskType: STAR })),
    }));
  } else {
    bucketedTasks = orderBy
      ? groupTasksByDate(sortedTaskArrMoments, orderBy, 'taskSidebar')
      : groupTasksByDate(sortedTaskArrMoments, defaultDateSortType, 'taskSidebar');
  }

  const sortedBucketedTasks = bucketedTasks.map((bucket, index) => {
    if (bucket.tasks.length) {
      let sortedTasks = bucket.tasks;
      if (taskType === FDC) {
        sortedTasks = sortedTasks.sort((x, y) => {
          if (x.status_id === FillDeliveryConfirmationStatus.Packed) {
            return -1;
          }
          return 1;
        });
      }
      return (
        <React.Fragment key={bucket.label}>
          {timeDivider(bucket.label, index)}
          {sortedTasks.map(task => (
            <TaskListRow
              patient={task}
              selectedRow={selectedRow}
              clinicalDataFields={clinicalDataFields}
              key={`dateSortedTasklist${task.taskType}-${task.id}-${task.task_id}${
                task.sms_id ? `-${task.sms_id}` : ''
              }`}
            />
          ))}
        </React.Fragment>
      );
    }
    return null;
  });

  return <>{order === 'asc' ? sortedBucketedTasks : sortedBucketedTasks.reverse()}</>;
};

function mapStateToProps(state) {
  const { lookups, patient } = state;
  return {
    patient,
    users: lookups.users,
  };
}

export default compose(withStyles(styles), connect(mapStateToProps))(DateSortedTaskList);

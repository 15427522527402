import moment from 'moment';
import tprJson from 'config/task-types/tpr.json';
import { submitMethods, tprServices } from 'constants/lists';
import { staticProviders, getCommonProviders, getDateForProvider } from './common-provider';

export default (task, state) => {
  const { lookups = {} } = state;
  const tprHubs =
    lookups && lookups.tprHubs
      ? lookups.tprHubs.map(tprHub => ({
          id: tprHub.id,
          name: tprHub.label,
        }))
      : [];

  return {
    ...staticProviders,
    ...getCommonProviders(task, state, tprJson),
    submitMethods: submitMethods.map(submitMethod => ({
      id: submitMethod.label,
      name: submitMethod.value,
    })),
    tprServices: tprServices.map(tprService => ({
      id: tprService.id,
      name: tprService.label,
    })),
    tprHubs,
    json: tprJson,
    initialValues: {
      submitted_date: getDateForProvider(moment()),
      completed_date: state.currentDateTime,
      canceled_dt: getDateForProvider(moment()),
    },
  };
};

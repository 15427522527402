import { LOAD_SCHEDULES, RESET_SCHEDULES } from 'constants/index';

export const loadSchedules = payload => ({
  type: LOAD_SCHEDULES,
  payload,
});

export const resetSchedules = () => ({
  type: RESET_SCHEDULES,
});

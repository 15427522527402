import { IAddAddressModal } from 'interfaces/redux/IAddAddressModal';

export enum AddAddressModalActionType {
  SetState = 'ADD_ADDRESS_MODAL_STATE',
  ResetModal = 'ADD_ADDRESS_MODAL_RESET',
}

export interface IAddAddressModalAction {
  type: AddAddressModalActionType;
  payload: IAddAddressModal;
}

export const addAddressModalActions = {
  setState: (state: IAddAddressModal): IAddAddressModalAction => ({
    type: AddAddressModalActionType.SetState,
    payload: state,
  }),
  resetModal: () => {
    return {
      type: AddAddressModalActionType.ResetModal,
    };
  },
};

import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { SectionTitleStyled } from '../../containers/application-manager/components/section-title';
import { SendNewBulkSmsTab } from './send-new-bulk-sms-tab';
import { SentBulkSmsTab } from './sent-bulk-sms-tab';

const SEND_NEW_BULK_SMS_TAB_IDX = 0;
const SENT_BULK_SMS_TAB_IDX = 1;

export const BulkSms: React.FC<{}> = (_): JSX.Element => {
  const [tabValue, setTabValue] = useState(SEND_NEW_BULK_SMS_TAB_IDX);
  return (
    <>
      <SectionTitleStyled title="Bulk SMS" />
      <Tabs
        value={tabValue}
        onChange={(event, newValue) => setTabValue(newValue)}
        aria-label="Bulk SMS's tabs"
      >
        <Tab label="Send new bulk SMS" />
        <Tab label="Sent bulk SMS" />
      </Tabs>
      {tabValue === SEND_NEW_BULK_SMS_TAB_IDX && <SendNewBulkSmsTab />}
      {tabValue === SENT_BULK_SMS_TAB_IDX && <SentBulkSmsTab />}
    </>
  );
};

import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
    minWidth: theme.spacing(45),
  },
  mainButton: {
    marginLeft: 10,
  },
}));

export enum STRINGS {
  delivery = 'delivery',
  pickup = 'pickup',
  dob_error = 'birthdate_error',
  new_address = 'A different address, please call me to collect',
  add_new_address = 'Please call me to collect delivery address',
  add_new_payment_method = 'A new payment method, please call me to collect',
  new_payment_method = 'If the order total is more than $0.00, please call me to collect payment method',
  answer_required = 'Answer Required',
}

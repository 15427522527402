import { convertToArborDate } from 'models/time/arbor-date';
import {
  FETCH_ALLERGIES_SUCCESS,
  ADD_ALLERGY,
  EDIT_ALLERGY,
  NO_ALLERGY_INFORMATION_AVAILABLE_ID,
} from '../constants';

function convertData(data) {
  if (!data) return null;
  data.onset_dt = convertToArborDate(data.onset_dt, true).getUtcDate(true);
  return data;
}

const determinePatientHasAllergies = allergies =>
  !!allergies &&
  Array.isArray(allergies) &&
  !allergies.some(
    allergy =>
      allergy.allergen.id === NO_ALLERGY_INFORMATION_AVAILABLE_ID && allergy.status_id === 1,
  );

const initialState = {
  allergiesList: [],
  patientHasKnownAllergies: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ALLERGIES_SUCCESS: {
      if (payload && payload.allergies) {
        const retAllergiesList = payload.allergies.map(data => convertData(data));
        return {
          patientHasKnownAllergies: determinePatientHasAllergies(retAllergiesList),
          allergiesList: retAllergiesList,
        };
      }
      return state;
    }
    case EDIT_ALLERGY: {
      if (payload.data && payload.data.length > 0) {
        const updatedAllergy = payload.data[0];
        const newAllergiesList = state.allergiesList.map(a => {
          if (a.id === updatedAllergy.id) {
            if (updatedAllergy.onset_dt) {
              updatedAllergy.onset_dt = convertToArborDate(
                updatedAllergy.onset_dt,
                true,
              ).getUtcDate(true);
            }
            return { ...a, ...updatedAllergy };
          }
          return a;
        });
        return {
          ...state,
          allergiesList: newAllergiesList,
        };
      }
      return state;
    }
    case ADD_ALLERGY: {
      if (payload.data && payload.data.added_allergy) {
        const patientNowHasKnownAllergies =
          payload.data.added_allergy[0].allergen.id !== NO_ALLERGY_INFORMATION_AVAILABLE_ID &&
          payload.data.added_allergy[0].status_id === 1;
        const newAllergy = convertData(payload.data.added_allergy[0]);

        if (newAllergy) {
          newAllergy.new = true;
        }

        let newAllergiesList = [
          ...state.allergiesList.filter(a => a.id !== newAllergy.id),
          {
            ...(state.allergiesList.find(a => a.id === newAllergy.id) || {}),
            ...newAllergy,
          },
        ];

        if (patientNowHasKnownAllergies) {
          newAllergiesList = newAllergiesList.filter(
            allergy => allergy.allergen.id !== NO_ALLERGY_INFORMATION_AVAILABLE_ID,
          );
        }
        return {
          ...state,
          patientHasKnownAllergies: patientNowHasKnownAllergies,
          allergiesList: newAllergiesList,
        };
      }
      return state;
    }
    default:
      return state;
  }
};

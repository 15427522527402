import { globalZIndex } from '../../../constants/z-index';

const BORDER_RADIUS = '0 0 10px 10px';

export const styles = theme => ({
  'taskBar': {
    'top': theme.layout.topBarHeight,
    'height': `calc(100% - ${theme.layout.topBarHeight}px)`,
    'whiteSpace': 'nowrap',
    'width': theme.layout.taskBarWidth,
    'transition': theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    'zIndex': globalZIndex.taskBar,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  'listItem': {
    padding: 4,
  },
  'loadingIndicator': {
    height: '300px',
    textAlign: 'center',
    paddingTop: '80px',
    fontSize: '18px',
  },
  'taskList': {
    top: theme.layout.topBarHeight,
    left: theme.layout.taskBarWidth + 1,
    height: `calc(100% - ${theme.layout.topBarHeight}px)`,
    whiteSpace: 'nowrap',
    width: theme.layout.taskListWidth,
    backgroundColor: theme.palette.summaryPanel,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // why isn't this color in our theme?
    boxShadow: `0px 0 5px 0 ${theme.palette.primary.faintBorder}`,
    overflow: 'visible',
  },
  'taskListBackdrop': {
    zIndex: 1100,
  },
  'overlayResponsive': {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  'taskTitle': {
    marginLeft: 15,
    marginTop: 8,
    display: 'inline-block',
  },
  'taskName': {
    fontSize: theme.font.largeFontSize,
  },
  'drawerInternalDivContainer': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  'drawerInternalDivContainerFilter': {
    height: '100%',
  },
  'tableContainer': {
    overflowX: 'hidden',
    height: '100%',
    overflowY: 'visible',
    maxHeight: 'initial',
  },
  'table': {
    width: '100%',
    overflowY: 'visible',
    whiteSpace: 'pre-wrap',
  },
  'tableRowSelected': {
    borderRight: `8px solid ${theme.palette.primary.positive}`,
    backgroundColor: theme.palette.primary.task.borderColor,
  },
  'tableRowSelectedArrows': {
    backgroundColor: theme.palette.primary.task.focusColor,
  },
  'tableHeader': {
    padding: '0px 4px 0px 4px',
    fontWeight: 'bold',
    fontSize: theme.font.smallFontSize,
    color: theme.palette.primary.task.number,
    backgroundColor: theme.palette.primary.white,
  },
  'fullCol': {
    width: '100%',
  },
  'patientCol': {
    width: 160,
    paddingLeft: 15,
  },
  'therapyCol': {
    width: 220,
  },
  'followupCol': {
    width: 61,
  },
  'needsByCol': {
    width: 45,
  },
  'appointmentDateCol': {
    width: 100,
  },
  'tableBody': {
    overflow: 'auto',
  },
  'tableMainContent': {
    color: theme.palette.primary.formItem,
  },
  'tableMainContentReconciliation': {
    color: theme.palette.primary.formItem,
  },
  'tableLongContent': {
    width: 111,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingBottom: 3,
  },
  'tableCellNoBreak': {
    whiteSpace: 'nowrap',
  },
  'tablePatientName': {
    fontWeight: 'bold',
    color: theme.palette.primary.formItem,
  },
  'tableRow': {
    'cursor': 'pointer',
    'boxSizing': 'border-box',
    'padding': '4px 4px 4px 2px',
    '&:focus': {
      backgroundColor: theme.palette.primary.task.focusColor,
    },
  },
  'tableRowTimeDivider': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.task.focusColor,
    fontSize: theme.font.smallFontSize,
    color: theme.palette.primary.task.number,
    fontWeight: 'bold',
  },
  'tableClass': {
    '&:focus': {
      backgroundColor: theme.palette.primary.task.focusColor,
    },
  },
  'tableRowCheckIcon': {
    display: 'inline-block',
    marginLeft: '4px',
  },
  'tableCell': {
    padding: 4,
    paddingLeft: 2,
  },
  'tableCellReconciliation': {
    paddingRight: 10,
  },
  'tableContent': {
    width: 50,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: theme.font.smallFontSize,
    color: theme.palette.primary.task.number,
  },
  'completedTaskButtonUnchecked': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.background,
    fontWeight: theme.font.weight.thick,
    border: '1px solid',
    borderTop: 'none',
    borderColor: theme.palette.primary.faintBorder,
    borderRadius: BORDER_RADIUS,
    minHeight: '50px',
  },
  'completedTaskButtonChecked': {
    'color': theme.palette.primary.main,
    'backgroundColor': theme.palette.primary.hover,
    'fontWeight': theme.font.weight.thick,
    'border': '1px solid',
    'borderTop': 'none',
    'borderColor': theme.palette.primary.faintBorder,
    'borderRadius': BORDER_RADIUS,
    'minHeight': '50px',
    '& path': {
      fill: theme.palette.primary.white,
    },
    '&:hover': {
      border: '1px solid',
      backgroundColor: theme.palette.primary.teal,
    },
  },
  'filterButtonUnchecked': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.background,
    fontWeight: theme.font.weight.thick,
    border: '1px solid',
    borderTop: 'none',
    borderColor: theme.palette.primary.faintBorder,
    borderRadius: BORDER_RADIUS,
    minHeight: '50px',
  },
  'filterButtonChecked': {
    'color': theme.palette.primary.contrastText,
    'backgroundColor': theme.palette.primary.hover,
    'fontWeight': theme.font.weight.thick,
    'border': '1px solid',
    'borderTop': 'none',
    'borderColor': theme.palette.primary.faintBorder,
    'borderRadius': BORDER_RADIUS,
    'minHeight': '50px',
    '&:hover': {
      border: '1px solid',
      backgroundColor: theme.palette.primary.teal,
    },
  },
  'filterButtonIconChecked': {
    '& *': {
      fill: theme.palette.primary.white,
    },
  },
  'filterButtonIconUnchecked': {
    '& *': {
      fill: theme.palette.primary.trellisBlue,
    },
  },
  'tablePPIcon': {
    display: 'flex',
    paddingRight: 0,
    width: 72,
  },
  'needsByDate': {
    marginRight: 5,
  },
  'filterPanelContainer': {
    padding: theme.spacing(1),
  },
  'searchTextContainer': {
    marginTop: 10,
    marginLeft: 10,
  },
  'patientDOB': {
    fontSize: 11,
    color: theme.palette.primary.task.number,
    paddingBottom: 2,
  },
  'filterPills': {
    paddingLeft: 15,
    whiteSpace: 'normal',
  },
  'expandButton': {
    width: '100%',
    minWidth: 0,
  },
  'expandButtonExpanded': {
    transform: 'scaleX(-1)',
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'expandButtonCollapsed': {
    transform: 'scaleX(1)',
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'menuLoader': {
    height: '100%',
  },
  'menuList': {
    height: 'calc(100% - 10px)',
    padding: 0,
    marginTop: '8px',
  },
  'patientNameGrid': {
    paddingLeft: theme.spacing(1),
  },
  'patientNameGridLonger': {
    paddingLeft: 11,
  },
  'tasksAtAGlanceExpansionPanel': {
    border: 0,
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  'tasksAtAGlanceExpansionPanelDetails': {
    padding: 0,
    margin: 0,
  },
  'tasksAtAGlanceHeader': {
    backgroundColor: theme.palette.primary.grey14,
    fontColor: theme.palette.primary.steelGrey,
    paddingTop: 0,
    paddingBottom: 0,
    height: '40px',
    fontSize: theme.font.mediumFontSize,
  },
  'tasksAtAGlanceStickyHeader': {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  'searchSideBarWrapper': {
    padding: theme.spacing(1),
  },
  'advancedSearchButtonWrapper': {
    textAlign: 'right',
  },
  'advancedSearchButton': {
    'borderRadius': theme.spacing(1),
    '&:hover': {
      color: theme.palette.primary.trellisBlue,
    },
  },
  'advancedSearchButtonActive': {
    backgroundColor: theme.palette.primary.trellisBlue,
    color: theme.palette.primary.white,
  },
  'unreadSms': {
    color: theme.palette.primary.patina,
  },
  'unreadOverdueSms': {
    color: theme.palette.primary.lightRed,
  },
  'smsHoverArrow': {
    width: '0px',
    height: '0px',
    borderRight: `${theme.spacing(2)} solid ${theme.palette.primary.steelGrey}`,
    borderTop: `${theme.spacing(1)} solid transparent`,
    borderBottom: `${theme.spacing(1)} solid transparent`,
    left: theme.spacing(-2),
  },
  'smsHoverContainer': {
    zIndex: 100000,
    maxWidth: theme.spacing(44),
    opacity: '0.9',
    borderRadius: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
  },
  'smsHoverContent': {
    padding: `${theme.spacing(1.25)} ${theme.spacing(2.5)} ${theme.spacing(1.25)} ${theme.spacing(
      1,
    )}`,
    background: `${theme.palette.primary.steelGrey}`,
    color: `${theme.palette.primary.white}`,
    fontFamily: `${theme.typography.fontFamily}`,
    zIndex: 100000000,
  },
  'userCustomListSelect': {
    minWidth: 320,
  },
  'moneyIcon': {
    backgroundColor: theme.palette.primary.steelblue,
    color: 'white',
    borderRadius: '51px',
    width: '20px',
    textAlign: 'center',
  },
  'blinkBackground': {
    background: `${theme.palette.primary.white}`,
    animation: `$blinkFa 1s ${theme.transitions.easing.easeIn}`,
  },
  '@keyframes blinkFa': {
    '0%': {
      backgroundColor: theme.palette.primary.teal,
    },
    '100%': {
      backgroundColor: theme.palette.primary.white,
    },
  },
});

import {
  NOTIFY_SUCCESS,
  NOTIFY_SUCCESS_ACTION,
  NOTIFY_WARNING,
  NOTIFY_ERROR,
  PROCESS_NOTIFICATION,
} from 'constants/index';

const initialState = [];

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case NOTIFY_SUCCESS:
      return [
        ...state,
        {
          type: 'success',
          msg: payload,
        },
      ];
    case NOTIFY_WARNING:
      return [
        ...state,
        {
          type: 'warning',
          msg: payload,
        },
      ];
    case NOTIFY_ERROR:
      return [
        ...state,
        {
          type: 'error',
          msg: payload,
        },
      ];
    case NOTIFY_SUCCESS_ACTION:
      return [
        ...state,
        {
          type: 'success',
          msg: payload.msg,
          timeout: payload.timeout,
          actionName: payload.actionName,
          actionPayload: payload.actionPayload,
          actionText: payload.actionText,
        },
      ];
    case PROCESS_NOTIFICATION: {
      const newState = [...state];
      newState.shift();
      return newState;
    }
    default:
      return state;
  }
};

import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid, Backdrop } from '@mui/material';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import {
  ADD_MEDICAL_INSURANCE_FORM,
  ENSURE_MEDICAL_INSURANCE_ADDRESSED,
  COMMERCIAL,
} from 'constants/index';
import classesName from 'classnames';
import { resolveAudit } from 'actions/action-audit';
import { resolveAuditDefinition } from 'services/utils/audit-service';
import { StringUtils } from 'utils/string-utils';
import {
  addMedicalInsurance,
  fetchHasPrimaryMedicalInsurance,
  fetchMedicalInsurances,
} from 'actions/action-financials';
import { insuranceTypes } from 'constants/lists';
import {
  validateDate,
  required,
  validatePhone,
  validateSinglePrimaryInsurance,
} from 'components/form/validation/validation';
import { convertToArborDate } from 'models/time/arbor-date';
import { stripFormattingPhoneNumber } from 'services/utils/formatting';
import { SmallSpacer } from 'components/spacer/spacer';
import Divider from 'components/divider';
import FormHeader from 'components/form/header/header';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import SubHeader from 'components/form/header/subheader';
import { prioritizeListById } from 'helpers/misc';
import { convertMapToList } from 'reducers/helper-reducer';
import {
  renderTextField,
  renderPhoneTextField,
  renderDropdown,
  renderCheckboxHorizontal,
} from '../../../../components/form/field/redux-field';
import { styles } from '../financials-styles';
import { RELATIONSHIP_SELF_ID } from '../../../../constants';
import { isValidInsuranceToResolveAudit } from '../utils';

class AddMedicalInsurance extends Component {
  constructor(props) {
    super(props);

    this.requiredValidation = [required];
    this.requiredAndDateValidation = [required, validateDate];
    this.validatePhone = [validatePhone];
    this.validateDate = [validateDate];

    this.state = {
      endTime: null,
      requiredAndSinglePrimary: null,
      noInsurance: false,
      selectedPlanSponsor: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.setEndTime = this.setEndTime.bind(this);
    this.setNoInsurance = this.setNoInsurance.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const {
      fetchHasPrimaryMedicalInsurance, // eslint-disable-line
      fetchMedicalInsurances, // eslint-disable-line
      patientId,
    } = this.props; // eslint-disable-line

    fetchMedicalInsurances(patientId);
    fetchHasPrimaryMedicalInsurance(patientId);
  }

  componentDidUpdate(prevProps, prevState) {
    const { medicalInsurances } = this.props;
    const { endTime, requiredAndSinglePrimary } = this.state;
    if (
      (this.getPrimary(medicalInsurances) && !this.getPrimary(prevProps.medicalInsurances)) ||
      !requiredAndSinglePrimary ||
      (endTime && !prevState.endTime) ||
      (endTime &&
        prevState.endTime &&
        Object.keys(endTime).length !== Object.keys(prevState.endTime).length)
    ) {
      this.setRequiredAndSinglePrimaryValidation(this.getPrimary(medicalInsurances), endTime);
    }
  }

  setRequiredAndSinglePrimaryValidation(primary, endTime) {
    this.setState({
      requiredAndSinglePrimary: [required, validateSinglePrimaryInsurance(primary, endTime)],
      singlePrimary: [validateSinglePrimaryInsurance(primary, endTime)],
    });
  }

  getPrimary(medicalInsurancesArr) {
    const { medicalInsurances } = this.props;
    const mInsurances = medicalInsurancesArr || medicalInsurances;
    return mInsurances.find(insurance => {
      const result =
        insurance.insurance_type === 'Primary' &&
        (insurance.end_date === null || convertToArborDate(insurance.end_date).isAfterNow());
      return result;
    });
  }

  setEndTime(value) {
    this.setState({
      endTime: value,
    });
  }

  setNoInsurance() {
    this.setState(prevState => ({
      noInsurance: !prevState.noInsurance,
    }));
  }

  handleChange(value) {
    const { initialValues, patient } = this.props;
    if (value.relationship_name === 'Self') {
      this.props.change('policy_holder_first_name', patient.first_name); // eslint-disable-line
      this.props.change('policy_holder_last_name', patient.last_name); // eslint-disable-line
      this.props.change('ph_dob', convertToArborDate(patient.dob).getUtcDate(true)); // eslint-disable-line
    } else {
      this.props.change('policy_holder_first_name', initialValues.policy_holder_first_name); // eslint-disable-line
      this.props.change('policy_holder_last_name', initialValues.policy_holder_last_name); // eslint-disable-line
      this.props.change('ph_dob', initialValues.ph_dob); // eslint-disable-line
    }
  }

  handleSubmit(values) {
    const {
      addMedicalInsurance, // eslint-disable-line
      fetchMedicalInsurances, // eslint-disable-line
      fetchHasPrimaryMedicalInsurance, // eslint-disable-line
      resolveAudit, // eslint-disable-line
      cancel,
      patientId,
      patient,
      auditState,
    } = this.props;

    const payload = {
      ...values,
      patient_id: patientId || values.patientId,
      insurance_type_id: values.insurance_type_id,
      no_insurance: values.no_insurance,
      plan_name: values.plan_name,
      plan_phone_number: values.plan_phone_number
        ? stripFormattingPhoneNumber(values.plan_phone_number)
        : null,
      policy_holder_first_name: values.policy_holder_first_name,
      policy_holder_last_name: values.policy_holder_last_name,
      policy_holder_relationship_id: values.policy_holder_relationship_id,
      policy_holder_dob: convertToArborDate(values.ph_dob).getUtcDate(),
      policy_holder_sponsor: values.policy_holder_sponsor,
      plan_sponsor: values.plan_sponsor,
      group_number: values.group_number,
      member_id: values.member_id,
      start_date: convertToArborDate(values.start_date).getUtcDate(),
      end_date: convertToArborDate(values.end_date).getUtcDate(),
      is_verified: 1,
      verified_dt: convertToArborDate(new Date()).getUtcDatetime(),
    };

    addMedicalInsurance(payload).then(() => {
      if (isValidInsuranceToResolveAudit(payload, insuranceTypes)) {
        resolveAuditDefinition({
          auditDefinitionType: ENSURE_MEDICAL_INSURANCE_ADDRESSED,
          auditState,
          patient,
          resolverFunction: resolveAudit,
        });
      }

      fetchMedicalInsurances(patientId).then(() => fetchHasPrimaryMedicalInsurance(patientId));
    });
    cancel();
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      classes,
      relationships,
      cancel,
      lookups,
      selectedRelationShipId,
      stretch,
    } = this.props;

    const { requiredAndSinglePrimary, singlePrimary, noInsurance } = this.state;

    let mappedRelationships = [];
    let sortedRelationships = [];
    if (relationships && relationships.length > 0) {
      sortedRelationships = prioritizeListById(RELATIONSHIP_SELF_ID, relationships);
      mappedRelationships = sortedRelationships.map(relationship => ({
        ...relationship,
        value: relationship.id,
        label: relationship.relationship_name,
      }));
    }
    const dobRequired = selectedRelationShipId === RELATIONSHIP_SELF_ID;
    const { selectedPlanSponsor } = this.state;
    const employer = lookups.planSponsors.find(o => o.id === selectedPlanSponsor);
    const addFormContainerClassname = classesName(classes.addFormContainer, {
      [classes.addFormContainerStretched]: stretch,
    });
    return (
      <Grid
        container
        justifyContent="center"
        spacing={7}
        className={(classes.formRowContainer, classes.formContainer)}
      >
        <Grid item xs={12}>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            className={addFormContainerClassname}
            autoComplete="off"
          >
            <Grid container>
              <Grid item xs={12} className={classes.addFormHeader}>
                <FormHeader header="New Insurance" />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Field
                  name="no_insurance"
                  label="No Insurance"
                  component={renderCheckboxHorizontal}
                  onChange={this.setNoInsurance}
                />
              </Grid>
            </Grid>
            <div className={classes.noInsuranceBackdropWrapper}>
              <Backdrop open={noInsurance} className={classes.noInsuranceBackdrop} />
              <Grid container>
                <Grid item xs={3} className={classes.formDropdown}>
                  <Field
                    name="insurance_type_id"
                    label="Type *"
                    component={renderDropdown}
                    fields={lookups.medicalInsuranceTypes
                      .map(type => ({
                        label: type.insurance_type,
                        value: type.id,
                      }))
                      .reverse()}
                    validate={noInsurance ? singlePrimary : requiredAndSinglePrimary}
                    disabled={noInsurance}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="start_date"
                    label="Start Date"
                    component={renderDatePicker}
                    validate={this.validateDate}
                    disabled={noInsurance}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="end_date"
                    label="End Date"
                    onChange={this.setEndTime}
                    component={renderDatePicker}
                    validate={this.validateDate}
                    disabled={noInsurance}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="flex-end">
                <Grid item xs={3}>
                  <Field
                    name="plan_name"
                    label="Insurance Plan Name *"
                    component={renderTextField}
                    validate={noInsurance ? null : this.requiredValidation}
                    disabled={noInsurance}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="plan_phone_number"
                    label="Insurance Plan Phone Number"
                    component={renderPhoneTextField}
                    validate={this.validatePhone}
                    disabled={noInsurance}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="group_number"
                    label="Group ID"
                    component={renderTextField}
                    normalize={StringUtils.toUpper}
                    disabled={noInsurance}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="member_id"
                    label=" Member ID *"
                    component={renderTextField}
                    normalize={StringUtils.toUpper}
                    validate={noInsurance ? null : this.requiredValidation}
                    disabled={noInsurance}
                  />
                </Grid>
              </Grid>
              <Divider />
              <Grid container alignItems="flex-end">
                <Grid item xs={12}>
                  <SubHeader name="Policy Holder" />
                  <SmallSpacer />
                </Grid>
                <Grid container alignItems="flex-end">
                  <Grid item xs={6}>
                    <Field
                      name="policy_holder_relationship_id"
                      label="Relationship to Patient *"
                      component={renderDropdown}
                      fields={mappedRelationships}
                      onChange={value =>
                        this.handleChange(mappedRelationships.find(o => o.id === value))
                      }
                      validate={noInsurance ? null : this.requiredValidation}
                      disabled={noInsurance}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="policy_holder_first_name"
                    label="Policy Holder First Name *"
                    component={renderTextField}
                    validate={noInsurance ? null : this.requiredValidation}
                    disabled={noInsurance}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="policy_holder_last_name"
                    label="Policy Holder Last Name *"
                    component={renderTextField}
                    validate={noInsurance ? null : this.requiredValidation}
                    disabled={noInsurance}
                  />
                </Grid>
                <Grid item xs={3}>
                  {dobRequired ? (
                    <Field
                      name="ph_dob"
                      label="Policy Holder Date of Birth *"
                      component={renderDatePicker}
                      validate={noInsurance ? this.validateDate : this.requiredValidation}
                      disabled={noInsurance}
                    />
                  ) : (
                    <Field
                      name="ph_dob"
                      label="Policy Holder Date of Birth"
                      component={renderDatePicker}
                      validate={this.validateDate}
                      disabled={noInsurance}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container alignItems="flex-end">
                <Grid item xs={3}>
                  <Field
                    name="plan_sponsor"
                    label="Plan sponsor *"
                    component={renderDropdown}
                    fields={lookups.planSponsors.map(item => ({
                      label: item.type,
                      value: item.id,
                    }))}
                    onChange={value => this.setState({ selectedPlanSponsor: value })}
                    validate={noInsurance ? null : this.requiredValidation}
                    disabled={noInsurance}
                    width="75%"
                  />
                </Grid>
                {employer?.type === COMMERCIAL && (
                  <Grid item xs={3}>
                    <Field
                      name="policy_holder_sponsor"
                      label="Employer"
                      component={renderTextField}
                      disabled={noInsurance}
                    />
                  </Grid>
                )}
              </Grid>
            </div>
            <Divider />
            <ConfirmationPanel handleCancel={cancel} disableSubmit={pristine || submitting} />
          </form>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const { patient, lookups, audit } = state;
  const selector = formValueSelector(ADD_MEDICAL_INSURANCE_FORM);
  const selectedRelationShipId = selector(state, 'policy_holder_relationship_id');
  return {
    patient,
    lookups,
    auditState: audit,
    relationships: state.lookups.insuranceRelationships,
    medicalInsurances: convertMapToList(state.financials.medical_insurances.data),
    enableReinitialize: true,
    selectedRelationShipId,
    initialValues: {
      patient_id: null,
      insurance_type_id: null,
      no_insurance: null,
      plan_name: null,
      plan_phone_number: null,
      policy_holder_first_name: null,
      policy_holder_last_name: null,
      policy_holder_relationship_id: null,
      ph_dob: null, // ph instead of policy_holder due to autocomplete thinking its a text field
      policy_holder_sponsor: null,
      plan_sponsor: null,
      group_number: null,
      member_id: null,
      start_date: null,
      end_date: null,
      is_verified: null,
      verified_dt: null,
      verified_by: null,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addMedicalInsurance,
      fetchHasPrimaryMedicalInsurance,
      fetchMedicalInsurances,
      resolveAudit,
    },
    dispatch,
  );
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(reduxForm({ form: ADD_MEDICAL_INSURANCE_FORM })(AddMedicalInsurance));

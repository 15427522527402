import { NoteBarTrigger } from 'constants/enums';
import { UPDATE_NOTE_BAR, CLEAR_NOTE_BAR_SMS_MESSAGE_FOCUS } from '../constants';

export default (state = { display: false, trigger: NoteBarTrigger.Notes }, { type, payload }) => {
  switch (type) {
    case UPDATE_NOTE_BAR:
      return { ...state, ...payload };
    case CLEAR_NOTE_BAR_SMS_MESSAGE_FOCUS:
      return {
        ...state,
        focusOnSmsId: 0,
      };
    default:
      return state;
  }
};

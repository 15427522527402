import { FETCH_HISTORY, CLEAR_HISTORY } from '../constants';

export default (state = [], { type, payload }) => {
  switch (type) {
    case FETCH_HISTORY:
      if (payload.data) {
        return payload.data;
      }
      return [];
    case CLEAR_HISTORY:
      return [];
    default:
      return state;
  }
};

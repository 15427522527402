import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material';
import compose from 'recompose/compose';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { convertToArborDate } from 'models/time/arbor-date';
import { workListChanged } from 'actions/action-patient';
import {
  getStatusByStatusId,
  getReasonsByStatus,
  getInitialServiceGroupForReactSelect,
  followupDateChanged,
  addTaskOldValues,
} from 'services/utils/task-service';
import { editFillDeliveryConfirmation, fetchTaskCounts } from 'actions/action-tasks';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { renderDropdown, renderTextField } from 'components/form/field/redux-field';
import { ReactSelectForReduxSingle } from 'components/form/field/react-select';
import { EDIT_FILL_DELIVERY_CONFIRMATION_FORM } from '../../../constants';
import { renderDatePicker } from '../../../components/form/datepicker/datetime-picker';
import { styles } from '../task-detail-styles';
import {
  validateDate,
  validateNumber,
  required,
} from '../../../components/form/validation/validation';

class EditFillDeliveryConfirmation extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleServiceGroupDropdown = this.handleServiceGroupDropdown.bind(this);
    this.state = { selectValue: props.taskStatus, serviceGroupDropdown: -1 };
  }

  handleSelect(e) {
    this.setState({
      selectValue: e.target.value,
    });
  }

  handleServiceGroupDropdown(e) {
    this.setState({
      serviceGroupDropdown: e.target.value,
    });
  }

  // Handles the submit functionality. Right now it sets some fields to null for simplicity's sake
  handleSubmit(values) {
    const {
      editFillDeliveryConfirmation, // eslint-disable-line
      task,
      cancel,
      workListChanged, //eslint-disable-line
      fetchTaskCounts, //eslint-disable-line
      fdcInformation,
    } = this.props;

    const payload = {
      ...values,
      id: task.id,
      additional_reason: values.additional_reason,
      completed_dt: values.completed_dt
        ? convertToArborDate(values.completed_dt).getUtcDatetime()
        : null,
      followup_dt: convertToArborDate(values.followup_dt).getUtcDatetime(),
      last_checked_dt: values.last_checked_dt
        ? convertToArborDate(values.last_checked_dt).getUtcDatetime()
        : null,
      status_id: values.status_id,
      service_group_id: values.service_group_id.value,
      therapy_id: task.therapy_id,
      shipped_date: convertToArborDate(values.shipped_date).getUtcDate(),
      pick_up_date: convertToArborDate(values.pick_up_date).getUtcDate(),
      delivered_date: convertToArborDate(values.delivered_date).getUtcDate(),
      picked_up_date: convertToArborDate(values.picked_up_date).getUtcDate(),
      delivery_exception_date: convertToArborDate(values.delivery_exception_date).getUtcDate(),
      canceled_date: convertToArborDate(values.canceled_date).getUtcDate(),
      fdcInformation: fdcInformation,
    };
    editFillDeliveryConfirmation(addTaskOldValues(payload, task)).then(() => {
      if (followupDateChanged(task.followup_dt, payload.followup_dt)) {
        workListChanged();
        fetchTaskCounts();
      }
      cancel();
    });
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      classes,
      cancel,
      task,
      statuses,
      serviceGroups,
      fillCycles,
    } = this.props;

    const { selectValue, serviceGroupDropdown } = this.state;
    const { therapy_id: therapyId } = task;

    return (
      <Grid
        container
        justifyContent="flex-start"
        className={(classes.formRowContainer, classes.formContainer)}
      >
        <Grid item xs={12}>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            className={classes.editFormContainer}
            autoComplete="off"
          >
            <Grid container className={classes.formRowContainer}>
              <Grid item lg={3} className={classes.fieldContainer}>
                <Field
                  name="followup_dt"
                  component={renderDatePicker}
                  label="Follow up Date *"
                  validate={[validateDate, required]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="additional_reason"
                  label="Additional Reason"
                  component={renderTextField}
                />
              </Grid>
              <Grid item lg={3} className={classes.fieldContainer}>
                <Field
                  name="last_checked_dt"
                  component={renderDatePicker}
                  label="Last Checked Date"
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item lg={3} className={classes.fieldContainer}>
                <Field
                  name="shipped_date"
                  component={renderDatePicker}
                  label="Shipped Date"
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item lg={3} className={classes.fieldContainer}>
                <Field
                  name="pick_up_date"
                  component={renderDatePicker}
                  label="Pick up Date"
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item lg={3} className={classes.fieldContainer}>
                <Field
                  name="delivered_date"
                  component={renderDatePicker}
                  label="Delivered Date"
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item lg={3} className={classes.fieldContainer}>
                <Field
                  name="picked_up_date"
                  component={renderDatePicker}
                  label="Picked up Date"
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item lg={3} className={classes.fieldContainer}>
                <Field
                  name="delivery_exception_date"
                  component={renderDatePicker}
                  label="Delivery exception Date"
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item lg={3} className={classes.fieldContainer}>
                <Field
                  name="canceled_date"
                  component={renderDatePicker}
                  label="Canceled Date"
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="service_group_id"
                  label="Service Group *"
                  validate={[required]}
                  component={ReactSelectForReduxSingle}
                  fields={serviceGroups.map(int => ({
                    label: int.display_name,
                    value: int.id,
                    isDisabled: !int.active,
                  }))}
                  state={serviceGroupDropdown}
                />
              </Grid>
              {getReasonsByStatus(statuses, getStatusByStatusId(task.status_id, statuses))[0]
                .label && (
                <Grid item lg={8} className={classes.fieldContainer}>
                  <Field
                    name="status_id"
                    label={`Reason for ${
                      getReasonsByStatus(statuses, getStatusByStatusId(task.status_id, statuses))[0]
                        .status
                    } status`}
                    component={renderDropdown}
                    fields={getReasonsByStatus(
                      statuses,
                      getStatusByStatusId(task.status_id, statuses),
                    )}
                    state={selectValue}
                  />
                </Grid>
              )}
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="fill_cycle_number"
                  component={renderDropdown}
                  fields={
                    fillCycles[therapyId] &&
                    Object.keys(fillCycles[therapyId])
                      .map(k => fillCycles[therapyId][k])
                      .map(cycle => ({
                        label: `#${cycle.fill_cycle} NBD: ${convertToArborDate(
                          cycle.needsby_date,
                          true,
                        ).getUtcDate(true)}`,
                        value: cycle.fill_cycle,
                      }))
                  }
                  label="Fill cycle *"
                  validate={[required, validateNumber]}
                />
              </Grid>
            </Grid>

            {/* Eighth Row */}
            <ConfirmationPanel
              cancelButtonName="edit_therapy_cancel_button"
              submitButtonName="edit_therapy_submit_button"
              handleCancel={cancel}
              disableSubmit={pristine || submitting}
            />
          </form>
        </Grid>
      </Grid>
    );
  }
}

function handleFormName(task) {
  if (!task) {
    return null;
  }
  const name = `${EDIT_FILL_DELIVERY_CONFIRMATION_FORM}_${task.therapy_id}_${task.id}`;
  return name;
}

function mapStateToProps(state, props) {
  const name = handleFormName(props.task);
  const { fillCycles } = state;
  return {
    form: name,
    fillCycles,
    initialValues: {
      additional_reason: props.task.additional_reason ? props.task.additional_reason : '',
      completed_dt: convertToArborDate(props.task.completed_dt, true).getCustomerDate(),
      followup_dt: convertToArborDate(props.task.followup_dt, true).getCustomerDate(true),
      last_checked_dt: convertToArborDate(props.task.last_checked_dt, true).getCustomerDate(true),
      status_id: props.task.status_id,
      service_group_id: props.task.service_group_id
        ? getInitialServiceGroupForReactSelect(props.task.service_group_id, props.serviceGroups)
        : '',
      fill_cycle_number: props.task.fill_cycle_number || null,
      shipped_date: convertToArborDate(props.task.shipped_date, true).getUtcDate(true),
      pick_up_date: convertToArborDate(props.task.pick_up_date, true).getUtcDate(true),
      delivered_date: convertToArborDate(props.task.delivered_date, true).getUtcDate(true),
      picked_up_date: convertToArborDate(props.task.picked_up_date, true).getUtcDate(true),
      delivery_exception_date: convertToArborDate(
        props.task.delivery_exception_date,
        true,
      ).getUtcDate(true),
      canceled_date: convertToArborDate(props.task.canceled_date, true).getUtcDate(true),
    },
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchTaskCounts,
    workListChanged,
    editFillDeliveryConfirmation,
  }),
)(reduxForm({})(EditFillDeliveryConfirmation));

import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import { getFileExtension } from 'services/utils/document-service';
import compose from 'recompose/compose';
import withStyles from '@mui/styles/withStyles';
import DocumentFileIcon from './document-file-icon';
import { styles } from './pending-document-styles';

const PendingDocument = props => {
  const { documentData, xs = 4, classes } = props;
  const { filePrefix, fileExtension } = getFileExtension(documentData.filename);

  return (
    <Grid item xs={xs} className={classes.item}>
      <Paper data-qa-id="uploaded-document">
        <Grid container alignItems="center" className={classes.container}>
          <Grid item xs={1}>
            <DocumentFileIcon fileName={filePrefix} fileNameExtension={fileExtension} />
          </Grid>

          <Grid item xs={11}>
            <Typography className={classes.fileName}>{documentData.filename}</Typography>

            {documentData.labels && (
              <Typography className={classes.labels}>{documentData.labels.join(', ')}</Typography>
            )}

            {documentData.tags && (
              <Typography className={classes.tags}>
                {documentData.tags.map(t => `#${t}`).join(' ')}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(PendingDocument);

import React from 'react';
import { Typography, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import Audit from 'components/display/audit';
import { EditPencilMd, EditHistory } from 'components/icons/icons';
import classNames from 'classnames';
import { auditContext } from 'constants/lists';
import ObsoleteNdcTooltip from 'containers/patient/therapy/obsolete-tooltip';
import { getActionedByUserText } from 'utils/user-utils';
import { styles } from './header-styles';

function AccordionHeader({
  classes,
  name,
  tags,
  updatedDt,
  updatedBy,
  editHandler,
  editID,
  withHistory,
  hideEdit,
  historyHandler,
  id,
  subheader,
  tooltip,
  hideUpdated,
  noLeftMargin,
  index,
  minimal,
  inactiveView,
  smallHeader,
  auditRules,
  warningName,
}) {
  let updateStr;
  if (updatedDt && updatedBy) {
    updateStr = getActionedByUserText({
      action: 'Updated',
      user: updatedBy,
      date: updatedDt,
    });
  }
  const titleClasses = classNames(classes.accordionHeaderTitle, {
    [classes.accordionHeaderTitleNoMarginLeft]: noLeftMargin,
    [classes.accordionHeaderTitleInactive]: inactiveView,
    [classes.minimalAccordion]: smallHeader,
  });
  return (
    <Grid
      container
      className={
        inactiveView ? classes.accordionHeaderContainerInactive : classes.accordionHeaderContainer
      }
    >
      <Grid item xs={8}>
        {!subheader && (
          <div className={classes.accordionHeaderTitleContainer}>
            <Typography variant="h6" className={titleClasses} id={`${id}_name`}>
              {auditRules && (
                <Audit
                  iconType="warning"
                  auditRules={auditRules}
                  className={classes.accordionHeaderAudit}
                  context={auditContext.Warning}
                />
              )}
              {name} <span className={classes.warningTitle}>{warningName}</span>
            </Typography>
            {tags && tags.map(tag => tag)}
          </div>
        )}
        {!!subheader && (
          <div>
            <Grid container>
              <div className={classes.accordionHeaderTitleContainer}>
                <Typography
                  data-qa-id={`therapy_name_${index}`}
                  variant="h6"
                  className={
                    noLeftMargin
                      ? classes.accordionHeaderTitleNoMarginLeft
                      : classes.accordionHeaderTitle
                  }
                >
                  {name} {warningName}
                </Typography>
                {tags && tags.map(tag => tag)}
              </div>
            </Grid>
            <Grid container className={classes.accordionSubheaderContainer}>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.accordionSubheaderText}
                id={`${id}_name`}
              >
                {subheader}
              </Typography>
              {!!tooltip && (
                <>
                  <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.accordionSubheaderTextWarning}
                    id={`${id}_name`}
                  >
                    {tooltip}
                  </Typography>
                  <ObsoleteNdcTooltip />
                </>
              )}
            </Grid>
          </div>
        )}
      </Grid>
      <Grid item xs={4} className={classes.actionIconContainer}>
        <Typography variant="caption" className={classes.accordionUpdatedText}>
          {!hideUpdated && updateStr}
          {withHistory && (
            <Button
              onClick={historyHandler}
              id={`${id}_edit_history_button`}
              className={minimal ? classes.headerButtonMinimal : classes.accordionHeaderButton}
            >
              <EditHistory />
            </Button>
          )}
          {!hideEdit && (
            <Button
              onClick={editHandler}
              id={`${id}_edit_button`}
              name="edit_button"
              className={minimal ? classes.headerButtonMinimal : classes.accordionHeaderButton}
            >
              <EditPencilMd id={editID} />
            </Button>
          )}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles, { withTheme: true })(AccordionHeader);

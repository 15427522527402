import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { renderDropdown } from 'components/form/field/redux-field';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { required, validateDate } from 'components/form/validation/validation';
import {
  SPECIAL_POPULATION_FORM,
  EDIT_SPECIAL_POPULATION_FORM,
  SPECIAL_POPULATIONS_PREGNANT,
  SPECIAL_POPULATIONS_GERIATRIC,
  SPECIAL_POPULATIONS_PEDIATRIC,
  SPECIAL_POPULATIONS_ADHERENCE_PACKAGING,
} from 'constants/index';
import moment from 'moment';
import { activeInactive10 } from 'constants/lists';
import { useSelector, connect, useDispatch } from 'react-redux';
import { convertToArborDate } from 'models/time/arbor-date';
import React from 'react';

import { compose } from 'recompose';
import { Field, reduxForm, formValueSelector, getFormValues, change } from 'redux-form';
import { addSpecialPopulation, editSpecialPopulation } from 'actions/action-special-populations';
import { styles } from '../patient-clinical-styles';

export function PatientSpecialPopulationForm(props) {
  const {
    cancelHandler,
    submitting,
    classes,
    handleSubmit,
    specialPopulation,
    form,
    lookups,
    reset,
    formValues,
    isFromReconciliationAdd,
    submitCallback,
    selectedPopulationId,
  } = props;

  const dispatch = useDispatch();

  const patient = useSelector(state => state.patient);
  const specialPopulations = useSelector(state => state.specialPopulations);
  const specialPopulationsList = specialPopulations
    ? specialPopulations.specialPopulationsList
    : [];

  const filteredLookupSpecialPopulations = lookups
    ? lookups.specialPopulations.filter(
        pop => pop.id !== SPECIAL_POPULATIONS_GERIATRIC && pop.id !== SPECIAL_POPULATIONS_PEDIATRIC && pop.id !== SPECIAL_POPULATIONS_ADHERENCE_PACKAGING,
      )
    : [];

  const specialPopulationListNoRepeats = specialPopulation
    ? filteredLookupSpecialPopulations.filter(
        pop => specialPopulation.special_population_id === pop.id,
      )
    : filteredLookupSpecialPopulations.filter(
        pop =>
          !specialPopulationsList.some(
            existingSpecialPop => {
              if (existingSpecialPop.special_population_id === SPECIAL_POPULATIONS_PREGNANT && !existingSpecialPop.active) {
                return false;
              }

              return existingSpecialPop.special_population_id === pop.id;
            },
          ),
      );

  if (!formValues || !lookups) {
    return null;
  }

  const handleCancel = () => {
    cancelHandler();
    reset();
  };

  const handlePregnancyStartDateChange = newValue => {
    const newEndDate = moment(newValue).add(10, 'months');
    if (newEndDate) {
      dispatch(change(form, 'end_date', newEndDate));
    }
  };

  const onSubmit = values => {
    const specialPopulationPayload = {
      special_population_id: values.special_population_id,
      active: values.active,
      end_date: convertToArborDate(values.end_date).getUtcDate(),
      start_date: convertToArborDate(values.start_date).getUtcDate(),
      patient_id: patient ? patient.id : null,
    };

    if (specialPopulation && !isFromReconciliationAdd) {
      const revisedSpecialPopulationPayload = {
        ...specialPopulationPayload,
        id: specialPopulation.id,
      };
      dispatch(editSpecialPopulation(revisedSpecialPopulationPayload));
    } else {
      dispatch(addSpecialPopulation(specialPopulationPayload));
    }

    handleCancel();
    if (submitCallback) {
      submitCallback();
    }
  };

  return (
    <form autoComplete="off" className={classes.tableAllergies}>
      <Grid container>
        <Grid item xs={6}>
          <Field
            name="special_population_id"
            label="Specialty Population *"
            id="special_population_id"
            component={renderDropdown}
            validate={[required]}
            fields={specialPopulationListNoRepeats.map(pop => ({
              label: pop.type,
              value: pop.id,
            }))}
          />
        </Grid>
        {selectedPopulationId &&
          selectedPopulationId.id !== SPECIAL_POPULATIONS_PREGNANT &&
          !!selectedPopulationId.need_end_date && (
            <Grid item xs={3}>
              <Field
                name="start_date"
                label="Start Date"
                component={renderDatePicker}
                validate={[validateDate]}
                placeholder="mm/dd/yyyy"
                id="start_date"
              />
            </Grid>
          )}
        {selectedPopulationId && selectedPopulationId.id === SPECIAL_POPULATIONS_PREGNANT && (
          <Grid item xs={3}>
            <Field
              name="start_date"
              label="Start Date *"
              component={renderDatePicker}
              validate={[validateDate, required]}
              placeholder="mm/dd/yyyy"
              id="start_date"
              onChange={handlePregnancyStartDateChange}
            />
          </Grid>
        )}
        {selectedPopulationId && !!selectedPopulationId.need_end_date && (
          <Grid item xs={3}>
            <Field
              name="end_date"
              label={`End Date${
                selectedPopulationId.id === SPECIAL_POPULATIONS_PREGNANT ? ' *' : ''
              }`}
              component={renderDatePicker}
              validate={
                selectedPopulationId.id === SPECIAL_POPULATIONS_PREGNANT
                  ? [validateDate, required]
                  : [validateDate]
              }
              placeholder="mm/dd/yyyy"
              id="end_date"
            />
          </Grid>
        )}
        <Grid item xs={3}>
          <Field
            name="active"
            label="Status *"
            component={renderDropdown}
            validate={[required]}
            fields={activeInactive10}
          />
        </Grid>
        <ConfirmationPanel
          handleCancel={handleCancel}
          handleSubmit={handleSubmit(onSubmit)}
          disableSubmit={submitting}
          buttonIdPrefix={`patient_special_population_${
            specialPopulation ? specialPopulation.id : 'not_found'
          }`}
        />
      </Grid>
    </form>
  );
}

function handleFormName(specialPopulation) {
  if (!specialPopulation) {
    return SPECIAL_POPULATION_FORM;
  }
  const name = `${EDIT_SPECIAL_POPULATION_FORM}_${specialPopulation.id}}`;
  return name;
}

function mapStateToProps(state, props) {
  const { specialPopulation, lookups } = props;
  const name = handleFormName(specialPopulation);
  const selector = formValueSelector(name);
  const selectedPopulationId =
    lookups && lookups.specialPopulations
      ? lookups.specialPopulations.find(pop => pop.id === selector(state, 'special_population_id'))
      : null;

  const initialValues = {
    special_population_id: specialPopulation ? specialPopulation.special_population_id : null,
    active: specialPopulation ? specialPopulation.active : 1, // if add default to active
    end_date: specialPopulation ? specialPopulation.end_date : null,
    start_date: specialPopulation ? specialPopulation.start_date : null,
  };
  const formValues = getFormValues(name)(state) || initialValues;
  return {
    form: name,
    formValues,
    initialValues,
    selectedPopulationId,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps),
)(reduxForm()(PatientSpecialPopulationForm));

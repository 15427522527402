import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { useState } from 'react';
import { getStatusByStatusId } from 'services/utils/task-service';
import NoteDisplay from 'components/note/note-display';
import DocumentDisplay from 'components/document/document-display';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import ActionRow from 'components/note/action-row';
import { useFillCycleNumber } from 'hooks/useFillCycleNumber';
import TaskDetailForm from 'components/dynamic-form/task-forms/task-detail-form';
import { TASK_MEDICATION_REVIEW } from '../../../constants/index';
import { styles } from '../task-detail-styles';
import TaskLinks from '../task-links';
import { StatusSelector } from '../common/status-selector';

const MedicationReviewDetail = props => {
  const {
    statuses,
    task,
    classes,
    notes,
    tagTypeId,
    tagTypeLabel,
    serviceGroups,
    therapy,
    displayEdit,
    handleCancel,
    taskIDPrefix,
    documents,
    drugName,
  } = props;
  const {
    // status_id: statusId,
    service_group_id: serviceGroupId,
    fill_cycle_number: fillCycleNumber,
    therapy_id: therapyId,
  } = task;

  const [selectedStatus, setSelectedStatus] = useState(
    task.status ? task.status : getStatusByStatusId(task.status_id, statuses),
  );

  const [needsByDate] = useFillCycleNumber(therapyId, fillCycleNumber);

  return (
    <div>
      <Grid
        container
        alignItems="center"
        className={displayEdit ? classes.taskDetailContainerEdit : classes.taskDetailContainer}
      >
        <Grid item lg={3}>
          <Grid container alignItems="center">
            {!displayEdit && (
              <StatusSelector
                value={selectedStatus}
                currentStatus={task.status}
                onChange={value => setSelectedStatus(value)}
                statuses={statuses}
                taskIDPrefix={taskIDPrefix}
                enabled={false}
                task={task}
              />
            )}
          </Grid>
        </Grid>
        {!displayEdit && (
          <Grid item lg={5} xs={6}>
            <TaskDetailForm
              task={{
                ...task,
                needsby_date: needsByDate,
              }}
              taskType="MR"
            />
          </Grid>
        )}
        {displayEdit && <Grid item xs={5} />}
        <Grid item xs={4}>
          {!displayEdit && (
            <Grid item xs={12}>
              <Grid container className={classes.note}>
                <NoteDisplay {...notes} tagResourceId={task.id} />
              </Grid>
              <DocumentDisplay
                taskIdResourceIds={[{ tagTypeId, resourceId: task.id }]}
                tagTypeId={tagTypeId}
                resourceId={task.id}
                drugName={drugName}
                tagTypeLabel={tagTypeLabel}
                documents={documents}
              />
              <ActionRow
                therapy={therapy}
                task={task}
                tagTypeId={tagTypeId}
                tagResourceId={task.id}
                cancel={handleCancel}
                defaultServiceGroup={serviceGroupId}
                serviceGroups={serviceGroups}
                taskType={TASK_MEDICATION_REVIEW}
                taskIDPrefix={taskIDPrefix}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <TaskLinks links={task.links} />
    </div>
  );
};

function mapStateToProps(state) {
  const { taskStatuses } = state;
  return {
    taskStatuses,
    users: state.lookups.users,
  };
}

export default compose(withStyles(styles), connect(mapStateToProps))(MedicationReviewDetail);

import React, { Component } from 'react';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { Grid, FormControl, FormControlLabel, FormLabel, RadioGroup, Radio } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { linkScheduleOutreachToTherapy } from 'actions/action-tasks';
import { styles } from './schedule-outreach-details-styles';

class ScheduleOutreachTherapyLinking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTherapyId: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ selectedTherapyId: event.target.value });
  }

  handleSubmit() {
    const { selectedTherapyId } = this.state;
    const { drug, scheduleOutreachId, linkScheduleOutreachToTherapy, handleClose } = this.props; // eslint-disable-line
    const payload = { therapy_id: selectedTherapyId, ndc: drug.ndc };
    linkScheduleOutreachToTherapy(scheduleOutreachId, payload).then(() => handleClose());
  }

  render() {
    const { handleClose, therapies, classes } = this.props;
    const { selectedTherapyId } = this.state;

    return (
      <Grid container className={classes.linkedDrugModalContainer}>
        <Grid item xs={12}>
          <FormControl component="fieldset" className={classes.linkTherapyContainer}>
            <FormLabel component="legend">Therapies</FormLabel>
            <RadioGroup
              aria-label="Therapies"
              name="link-therapies"
              value={selectedTherapyId}
              onChange={this.handleChange}
              className={classes.linkTherapyList}
            >
              {Object.values(therapies).map(therapy => (
                <FormControlLabel
                  key={therapy.id}
                  value={therapy.id.toString()}
                  control={<Radio color="primary" />}
                  label={`${therapy.drug_name} 
                  ${therapy.strength || ''} 
                  ${therapy.strength_unit_of_measure || ''}`}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <ConfirmationPanel handleCancel={handleClose} handleSubmit={this.handleSubmit} />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  const { therapies } = state;
  return { therapies: therapies.data };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, { linkScheduleOutreachToTherapy }),
)(ScheduleOutreachTherapyLinking);

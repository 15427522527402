import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { useState } from 'react';
import { getStatusByStatusId, getStatusIdByStatusName } from 'services/utils/task-service';
import NoteDisplay from 'components/note/note-display';
import DocumentDisplay from 'components/document/document-display';
import compose from 'recompose/compose';
import { useSelector } from 'react-redux';
import Communication from 'components/note/communication';
import TaskStatusForm from 'components/dynamic-form/task-forms/task-status-form';
import TaskDetailForm from 'components/dynamic-form/task-forms/task-detail-form';
import { parseOtherDisplay } from 'services/utils/common-service';
import { QRE } from 'constants/index';
import { styles } from '../task-detail-styles';
import IncidentForm from './incident-form';
import TaskLinks from '../task-links';
import { StatusSelector } from '../common/status-selector';
import { getResponsiblesString } from './utils';

export const IncidentDetail = props => {
  const {
    statuses,
    noStatus,
    task,
    classes,
    notes,
    documents,
    serviceGroups,
    tagTypeId,
    tagTypeLabel,
    therapy,
    displayEdit,
    handleCancel,
    taskIDPrefix,
    drugName,
  } = props;

  const lookups = useSelector(state => state.lookups);
  const currentUser = useSelector(state => state.auth?.currentUser);
  const resourceStates = (lookups?.resourceStates || []).filter(
    resourceState => resourceState.resource_name === QRE,
  );

  const {
    CONSTANTS: { INCIDENT_REPORTING_PARTIES, INCIDENT_TYPES, MEDICATION_ERROR_TYPES },
    incidentTypes,
    incidentReportingParties,
    medicationErrorTypes,
  } = lookups;

  const isExternal = !task.is_internal;

  task.responsible_parties = getResponsiblesString(task.responsibles, isExternal);

  const incidentType = incidentTypes.find(type => type.id === task.type_id);
  task.type_display =
    task.type_id !== INCIDENT_TYPES.OTHER && incidentType
      ? incidentType.name
      : parseOtherDisplay(task.other_type);

  const medicationErrorType = medicationErrorTypes.find(
    type => type.id === task.medication_error_type_id,
  );
  task.medication_error_type_display =
    task.medication_error_type_id !== MEDICATION_ERROR_TYPES.OTHER && medicationErrorType
      ? medicationErrorType.name
      : parseOtherDisplay(task.other_medication_error_type);

  task.reporting_party_display =
    task.reporting_party_id !== INCIDENT_REPORTING_PARTIES.OTHER
      ? incidentReportingParties.find(rp => rp.id === task.reporting_party_id).name
      : parseOtherDisplay(task.other_reporting_party);

  const { status_id: statusId, therapy_id: therapyId } = task;

  const [selectedStatus, setSelectedStatus] = useState(
    task.status ? task.status : getStatusByStatusId(task.status_id, statuses),
  );

  const selectedStatusId = getStatusIdByStatusName(selectedStatus, statuses);

  return (
    <div>
      <Grid
        container
        alignItems="center"
        className={displayEdit ? classes.taskDetailContainerEdit : classes.taskDetailContainer}
      >
        {!noStatus && (
          <Grid item xs={3}>
            <Grid container alignItems="center">
              {!displayEdit && (
                <StatusSelector
                  value={selectedStatus}
                  currentStatus={task.status}
                  onChange={value => setSelectedStatus(value)}
                  statuses={statuses}
                  taskIDPrefix={taskIDPrefix}
                  task={task}
                  currentUser={currentUser}
                  resourceStates={resourceStates}
                />
              )}
            </Grid>
          </Grid>
        )}

        {!displayEdit && (
          <Grid item xs={noStatus ? 8 : 5}>
            <TaskDetailForm task={task} taskType="QRE" />
          </Grid>
        )}
        {displayEdit && <Grid item xs={5} />}
        <Grid item xs={4}>
          {!displayEdit && (
            <Grid item xs={12}>
              <Grid container className={classes.note}>
                <NoteDisplay {...notes} tagResourceId={task.id} />
              </Grid>
              <DocumentDisplay
                taskIdResourceIds={[{ tagTypeId, resourceId: task.id }]}
                tagTypeId={tagTypeId}
                resourceId={task.id}
                drugName={drugName}
                tagTypeLabel={tagTypeLabel}
                documents={documents}
              />
              <Grid container spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Communication
                    tagTypeId={tagTypeId}
                    tagResourceId={task.id}
                    task={task}
                    id={`${taskIDPrefix}_contact`}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <TaskLinks links={task.links} />
      <Grid container>
        {displayEdit && (
          <Grid item xs={12}>
            <IncidentForm
              edit
              task={task}
              statuses={statuses}
              therapyId={therapyId}
              cancel={handleCancel}
              serviceGroups={serviceGroups}
              tagTypeId={tagTypeId}
              therapy={therapy}
            />
          </Grid>
        )}
        {!displayEdit &&
          selectedStatus !== getStatusByStatusId(statusId, statuses) &&
          statusId &&
          statuses && (
            <Grid
              item
              xs={12}
              className={selectedStatus !== task.status ? classes.statusContainer : null}
            >
              <TaskStatusForm
                task={task}
                forceStatusId={selectedStatusId}
                onCancel={() => setSelectedStatus(getStatusByStatusId(task.status_id, statuses))}
              />
            </Grid>
          )}
      </Grid>
    </div>
  );
};

export default compose(withStyles(styles))(IncidentDetail);

import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

export const styles = (theme: Theme) => {
  const colorPalette = theme.palette.primary as any;

  return createStyles({
    expansionPanel: {
      border: `1px solid ${colorPalette.lightGrey}`,
    },
    headerLabel: {
      fontSize: 12,
      lineHeight: '15px',
      paddingBottom: 9,
    },
    headerText: {
      fontSize: 14,
      color: colorPalette.darkerBlue,
      lineHeight: '20px',
      fontWeight: 'bold',
    },
    detailLabel: {
      fontSize: 12,
      lineHeight: '15px',
      color: colorPalette.grey12,
      paddingBottom: 6,
    },
    detailText: {
      fontSize: 14,
      lineHeight: '15px',
      color: colorPalette.steelGray,
    },
    detailGridItem: {
      paddingBottom: 14,
    },
  });
};

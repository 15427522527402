import { takeEvery } from 'redux-saga/effects';
import { logEvent } from 'services/utils/analytics';
import {
  GA_QUESTIONNAIRE_PATIENT_VIEW,
  GA_QUESTIONNAIRE_PATIENT_ANSWER_QUESTION,
  GA_QUESTIONNAIRE_PATIENT_SUBMIT,
  PATIENT_QUESTIONNAIRE,
  PATIENT_QUESTIONNAIRE_ACTIONS,
} from 'constants/index';

export function* watcherQuestionnaireSaga() {
  yield takeEvery(GA_QUESTIONNAIRE_PATIENT_VIEW, payload => {
    const { questionnaireLink, patientId } = payload;
    return logEvent(
      PATIENT_QUESTIONNAIRE,
      PATIENT_QUESTIONNAIRE_ACTIONS.VIEW,
      `Questionnaire(${questionnaireLink}) viewed by patient #${patientId}`,
    );
  });

  yield takeEvery(GA_QUESTIONNAIRE_PATIENT_ANSWER_QUESTION, payload => {
    const { questionnaireLink, patientId, questionNumber } = payload;
    return logEvent(
      PATIENT_QUESTIONNAIRE,
      PATIENT_QUESTIONNAIRE_ACTIONS.UPDATE,
      // eslint-disable-next-line max-len
      `Question #${questionNumber} for questionnaire(${questionnaireLink}) has been answered by a patient #${patientId}`,
    );
  });

  yield takeEvery(GA_QUESTIONNAIRE_PATIENT_SUBMIT, payload => {
    const { questionnaireLink, patientId } = payload;
    return logEvent(
      PATIENT_QUESTIONNAIRE,
      PATIENT_QUESTIONNAIRE_ACTIONS.SUBMIT,
      `Questionnaire(${questionnaireLink}) submitted by patient #${patientId}`,
    );
  });
}

import HTTP from '../services/http';
import {
  FETCH_USER_PREFERENCES,
  RESET_LOCAL_USER_PREFERENCES_TASKS_THERAPIES,
  UPDATE_LOCAL_USER_PREFERECENES_RECENT_CLINICS,
  UPDATE_LOCAL_USER_PREFERECENES_RECENT_PHARMACIES,
  UPDATE_LOCAL_USER_PREFERECENES_RECENT_PROVIDERS,
  UPDATE_LOCAL_USER_PREFERENCES_BI_PHARMACY_ID,
  UPDATE_LOCAL_USER_PREFERENCES_INVOICING,
  UPDATE_LOCAL_USER_PREFERENCES_TASKS,
  UPDATE_LOCAL_USER_PREFERENCES_TASKLIST,
  UPDATE_LOCAL_USER_PREFERENCES_THERAPIES_ADMINISTRATION,
  UPDATE_LOCAL_USER_PREFERENCES_THERAPIES_ENROLLMENT,
  UPDATE_USER_PREFERENCES,
} from '../constants';

const USERS_PREFERENCES_URL = '/users/me/preferences';

export function fetchUserPreferences() {
  const request = HTTP.get(USERS_PREFERENCES_URL, {});

  return {
    type: FETCH_USER_PREFERENCES,
    payload: request,
  };
}

let timer = null;
const DEBOUNCE_TIMEOUT = 5000;

export function updateUserPreferences(updatedUserPreferences) {
  window.localStorage.setItem('user_preferences', updatedUserPreferences);
  if (timer) {
    clearTimeout(timer);
  }

  timer = setTimeout(async () => {
    const putUrl = USERS_PREFERENCES_URL;
    try {
      await HTTP.put(putUrl, updatedUserPreferences, {});
    } catch (err) {
      // if an error occurs just log and ignore
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, DEBOUNCE_TIMEOUT);

  return {
    type: UPDATE_USER_PREFERENCES,
    payload: updatedUserPreferences,
  };
}

export function updateLocalUserPreferencesTherapiesEnrollment(updatedUserPreferences) {
  return {
    type: UPDATE_LOCAL_USER_PREFERENCES_THERAPIES_ENROLLMENT,
    payload: updatedUserPreferences,
  };
}

export function updateLocalUserPreferencesTherapiesAdministration(updatedUserPreferences) {
  return {
    type: UPDATE_LOCAL_USER_PREFERENCES_THERAPIES_ADMINISTRATION,
    payload: updatedUserPreferences,
  };
}

export function updateLocalUserPreferencesTasks(updatedUserPreferences) {
  return {
    type: UPDATE_LOCAL_USER_PREFERENCES_TASKS,
    payload: updatedUserPreferences,
  };
}

export function updateLocalUserPreferencesTaskList(updatedUserPreferences) {
  return {
    type: UPDATE_LOCAL_USER_PREFERENCES_TASKLIST,
    payload: updatedUserPreferences,
  };
}

export function updateIndexPeriod(period) {
  return {
    type: UPDATE_LOCAL_USER_PREFERENCES_TASKLIST,
    payload: { tasklist: { period } },
  };
}

export function updateLocalUserPreferencesRecentPrescribers(prescriberToAdd) {
  return {
    type: UPDATE_LOCAL_USER_PREFERECENES_RECENT_PROVIDERS,
    payload: prescriberToAdd,
  };
}
export function updateLocalInvoicingPreferences(updatedUserPreferences) {
  return {
    type: UPDATE_LOCAL_USER_PREFERENCES_INVOICING,
    payload: updatedUserPreferences,
  };
}
export function updateLocalUserPreferencesRecentClinics(clinicToToAdd) {
  return {
    type: UPDATE_LOCAL_USER_PREFERECENES_RECENT_CLINICS,
    payload: clinicToToAdd,
  };
}

export function updateLocalUserPreferencesRecentPharmacies(pharmacyToAdd) {
  return {
    type: UPDATE_LOCAL_USER_PREFERECENES_RECENT_PHARMACIES,
    payload: pharmacyToAdd,
  };
}

export function updateLocalUserPreferencesBiPharmacy(pharmacyId) {
  return {
    type: UPDATE_LOCAL_USER_PREFERENCES_BI_PHARMACY_ID,
    payload: pharmacyId,
  };
}

export function resetLocalUserPreferencesTasksTherapies(
  taskResetVals,
  therapyResetVals,
  resetUserPreferences,
) {
  const putUrl = USERS_PREFERENCES_URL;
  HTTP.put(putUrl, resetUserPreferences, {});
  return {
    type: RESET_LOCAL_USER_PREFERENCES_TASKS_THERAPIES,
    payload: { taskVals: taskResetVals, therapyVals: therapyResetVals },
  };
}

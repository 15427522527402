import { expandTheme } from 'utils/theme-util';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material';

export const styles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    addFCModal: {
      position: 'absolute',
      width: theme.spacing(80),
      backgroundColor: palette.primary.background,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
      borderRadius: 5,
    },
    text: {
      color: palette.primary.grey23,
      marginTop: theme.spacing(1.25),
      marginBottom: theme.spacing(0.25),
    },
    textRadio: {
      fontSize: theme.spacing(1.8),
      marginTop: theme.spacing(1.5),
      marginBotton: theme.spacing(1.5),
    },
    textRadioStrong: {
      fontSize: theme.spacing(1.9),
      fontWeight: 'bold',
    },
  });
};

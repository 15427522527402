/**
 * This is a copy of the redux-promise library, with the addition of a special
 * logic to dispatch an action before promise starts.
 *
 * When the property initialValue is included in the action object, the same action
 * is dispatched before the promise starts with such default value as the payload
 * The initialValue property is deleted to ensure the action object meets the standards.
 *
 * Original library: https://www.npmjs.com/package/redux-promise
 */
import isPromise from 'is-promise';
import { isFSA } from 'flux-standard-action';

export default function promiseMiddleware({ dispatch }) {
  return next => action => {
    if (action.initialValue) {
      const payload = action.initialValue;
      delete action.initialValue;
      dispatch({ ...action, payload });
    }
    if (!isFSA(action)) {
      return isPromise(action) ? action.then(dispatch) : next(action);
    }
    return isPromise(action.payload)
      ? action.payload
          .then(result => dispatch({ ...action, payload: result }))
          .catch(error => {
            dispatch({ ...action, payload: error, error: true });
            return Promise.reject(error);
          })
      : next(action);
  };
}

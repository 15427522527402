import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Paper,
  Checkbox,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import { IMedsRowData } from 'models/meds/IMed';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { specialtyTypeFormatter } from 'services/utils/therapy-service';
import { MedUtils } from 'utils/med-utils';
import trellislogo from '../../../lib/logos/trellis_logo.png';
import { styles } from './meds-styles';
import {
  TitleBar,
  Footer,
  TableHeader,
} from './select-meds-form-components/meds-select-form-components';
import LoadingIndicator from '../../../components/loading-overlay/loading-overlay';

function descendingComparator(a: IMedsRowData, b: IMedsRowData, orderBy: keyof IMedsRowData) {
  let aValue = a[orderBy] ?? '';
  let bValue = b[orderBy] ?? '';

  if (typeof aValue === 'string') aValue = aValue.toLowerCase();
  if (typeof bValue === 'string') bValue = bValue.toLowerCase();

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof IMedsRowData>(
  order: Order,
  orderBy: Key,
): (a: IMedsRowData, b: IMedsRowData) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface IProps {
  classes: any;
  onClose: (event: {}, reason: string) => void;
  handleContinue: (selectedMeds: IMedsRowData[]) => void;
  medList: IMedsRowData[];
  loadingMessage: string;
  isLoading: boolean;
}

function MedsSelectForm(props: IProps) {
  const { classes, onClose, handleContinue, medList, loadingMessage, isLoading } = props;
  const [sortedMedList, setSortedMedList] = React.useState(medList);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof IMedsRowData>('drug_name');
  const [selected, setSelected] = React.useState<readonly number[]>(
    sortedMedList.filter(item => item.isSelected).map(item => item.id),
  );
  const therapies = useTypedSelector(state => state.therapies.data);
  const maxSelectable = sortedMedList.filter(item => !item.therapy_id).length;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IMedsRowData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const newSort = sortedMedList;
    setSortedMedList(newSort.sort(getComparator(order, orderBy)));
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      sortedMedList.forEach(
        (item, index) => !item.therapy_id && (sortedMedList[index].isSelected = true),
      );
      const newSelecteds =
        sortedMedList.map(n => (!n.therapy_id ? n.id : -1)).filter(n => n !== -1) || [];
      setSelected(newSelecteds);
      return;
    }
    sortedMedList.forEach(item => (item.isSelected = false));
    setSelected([]);
  };

  const handleContinueWrapper = () => {
    if (handleContinue) {
      const selectedMeds = medList.filter(item => item.isSelected);
      handleContinue(selectedMeds);
    }
  };
  React.useEffect(() => {
    setSortedMedList(medList);
  }, [medList]);

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      sortedMedList.forEach((item, index) => {
        if (item.id === id) {
          sortedMedList[index].isSelected = true;
        }
      });
    } else if (selectedIndex > -1) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      sortedMedList.forEach((item, index) => {
        if (item.id === id) {
          sortedMedList[index].isSelected = false;
        }
      });
    }
    setSelected(newSelected);
  };
  return (
    <Box>
      <Paper>
        <TitleBar />
        <TableContainer className={classes.tableContainer}>
          {isLoading && (
            <div className={classes.messageContainer} data-qa-id="message_container">
              <>
                <LoadingIndicator open />
                {loadingMessage}
              </>
            </div>
          )}
          {!isLoading && sortedMedList.length === 0 && (
            <div className={classes.messageContainer}>No Medications Found</div>
          )}
          {!isLoading && sortedMedList.length > 0 && (
            <Table aria-labelledby="tableTitle" size="medium" stickyHeader>
              <TableHeader
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={sortedMedList.length}
                maxSelectable={maxSelectable}
              />
              <TableBody>
                {sortedMedList.map(row => {
                  const isItemSelected = row.isSelected;
                  const labelId = `enhanced-table-checkbox-${row.id}`;
                  const therapy = row.therapy_id ? therapies?.[row.therapy_id] : null;
                  const tags: { text: string; description: string }[] = [];

                  if (row.is_specialty_drug) {
                    tags.push(
                      ...MedUtils.getMedicationTags(therapy?.specialty_type, row.specialty_type),
                    );
                  }

                  return (
                    <TableRow
                      hover
                      onClick={!row.therapy_id ? event => handleClick(event, row.id) : undefined}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox" className={classes.checkboxColumn}>
                        {!row.therapy_id ? (
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        ) : null}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        className={classes.drugNameColumn}
                      >
                        <div className={classes.drugNameCellWrapper}>
                          <span className={classes.drugNameCell}>{row.drug_name}</span>
                          {row.therapy_id && (
                            <span className={classes.trellisIcon}>
                              <Tooltip title="Managed by Trellis">
                                <img src={trellislogo} width={22} height={22} alt="trellis logo" />
                              </Tooltip>
                            </span>
                          )}
                          {tags.map((tag, index: number) => (
                            <Tooltip title={tag.description} key={`tag-${index}`}>
                              <Typography
                                component="span"
                                key="is_specialty"
                                className={classNames(classes.specialtyTag, tag.text)}
                              >
                                {tag.text}
                              </Typography>
                            </Tooltip>
                          ))}
                        </div>
                      </TableCell>
                      <TableCell align="center" className={classes.ndcColumn}>
                        {row.ndc}
                      </TableCell>
                      <TableCell align="center" className={classes.doseColumn}>
                        {row.strength_with_unit}
                      </TableCell>
                      <TableCell align="center" className={classes.formColumn}>
                        {row.dosage_form}
                      </TableCell>
                      <TableCell align="center" className={classes.startDateColumn}>
                        {row.start_date}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <Footer
          onClose={onClose}
          handleContinue={handleContinueWrapper}
          hideSubmitButton={isLoading || selected.length === 0}
        />
      </Paper>
    </Box>
  );
}
export default withStyles(styles)(MedsSelectForm);

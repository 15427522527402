export const styles = () => ({
  smallSpacer: {
    height: 10,
  },
  mediumSpacer: {
    height: 20,
  },
  largeSpacer: {
    height: 30,
  },
});

import { AxiosResponse } from 'axios';
import { IPatientProblem, IProblemTherapy, ICdmProgramEncounter } from 'interfaces/redux/IPatient';

import HTTP from '../http';

// Full API response
export interface IPatientProblemsResponse {
  id: number;
  therapies: IProblemTherapy[];
  diagnosis: { code: string; description: string };
  onset_date: string;
  active: boolean;
  notes: string;
  source: string;
  created_date: string;
  created_by: number;
  updated_date: string;
  updated_by: number;
  cdm_encounter: ICdmProgramEncounter;
  clinical_support_status?: number;
  cdm_program_id: number | null;
}

export interface IUpdatePatientProblemResponse {
  problem: IPatientProblemsResponse;
  should_unverify_problems_list: boolean;
}

const buildUrl = (patientId: number): string => `/patients/${patientId}/problems`;

const mapResponseToPatientProblem = (response: IPatientProblemsResponse): IPatientProblem => ({
  id: response.id,
  therapies: response.therapies,
  diagnosis: response.diagnosis,
  onset_date: response.onset_date,
  active: response.active,
  notes: response.notes,
  cdm_encounter: response.cdm_encounter,
  clinical_support_status: response.clinical_support_status,
  cdm_program_id: response.cdm_program_id,
});

export const getPatientProblems = async (patientId: number): Promise<IPatientProblem[]> => {
  const response: AxiosResponse<IPatientProblemsResponse[]> = await HTTP.get(buildUrl(patientId));
  return response.data.map(mapResponseToPatientProblem);
};

export const createPatientProblem = async (
  patientId: number,
  problem: Partial<IPatientProblem>,
): Promise<IPatientProblem> => {
  const response: AxiosResponse<IPatientProblemsResponse> = await HTTP.post(
    buildUrl(patientId),
    problem,
  );

  return mapResponseToPatientProblem(response.data);
};

export const updatePatientProblem = async (
  patientId: number,
  problem: IPatientProblem,
): Promise<{
  problem: IPatientProblem;
  should_unverify_problems_list: boolean;
}> => {
  const response: AxiosResponse<IUpdatePatientProblemResponse> = await HTTP.patch(
    `${buildUrl(patientId)}/${problem.id}`,
    problem,
  );

  return {
    problem: mapResponseToPatientProblem(response.data.problem),
    should_unverify_problems_list: response.data.should_unverify_problems_list,
  };
};

export const verifyPatientProblems = async (patientId: number): Promise<void> => {
  await HTTP.post(`${buildUrl(patientId)}/_verify`);
};

import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid, Checkbox } from '@mui/material';
import DetailField from 'components/form/field/field';
import compose from 'recompose/compose';
import { Field, reduxForm, formValueSelector, change as _change } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  EDIT_FINANCIAL_ASSISTANCE_FORM,
  FINANCIAL,
  UPLOAD_DOCUMENT_FORM,
  UPLOAD_DOC_FILENAME_FIELD,
  UPLOAD_DOC_FILETYPE_FIELD,
  UPLOAD_DOC_FILENOTE_FIELD,
} from 'constants/index';
import { noteTagTypes } from 'constants/lists';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { fetchFinancialAssistance, editFinancialAssistanceTask } from 'actions/action-financials';
import {
  validateDate,
  validateDollarValue,
  required,
  validateNumeric,
  length6,
  maxLength20,
} from 'components/form/validation/validation';
import { stripFormattedDollarValue } from 'services/utils/formatting';

import { convertToArborDate } from 'models/time/arbor-date';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import {
  renderDropdown,
  renderTextField,
  renderMoneyTextField,
  renderPhoneTextField,
} from 'components/form/field/redux-field';
import { getFileExtension, acceptedFileExtensions } from 'services/utils/document-service';
import { uploadFile, getDocuments } from 'services/utils/upload-document-service';

import { SmallSpacer } from 'components/spacer/spacer';
import { addNewDocument } from 'actions/action-uploaded-documents';
import DocumentConfirmationDialog from '../../../patient/financials/document-confirmation-dialog';
import { styles } from '../../task-detail-styles';
import WrappedFileUpload from '../../../patient/financials/wrapped-file-upload';

const tagType = noteTagTypes.find(tag => tag.label === FINANCIAL);

class EditCopayCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: null,
      therapyId: null,
      noGroupIdCheckbox: false,
      noPCNCheckbox: false,

      // upload file props
      pendingDocument: undefined,
      uploadedDocuments: [],
      uploadProcessing: false,
      pendingDocumentLoss: false,
      lostData: [],
      dialogCancel: undefined,
      dialogContinue: undefined,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDialogContinue = this.handleDialogContinue.bind(this);
    this.handleDialogCancel = this.handleDialogCancel.bind(this);

    this.handleGroupIdCheckbox = this.handleGroupIdCheckbox.bind(this);
    this.handleNoPCNCheckbox = this.handleNoPCNCheckbox.bind(this);

    // upload file
    this.handleFileUploadCancel = this.handleFileUploadCancel.bind(this);
    this.handleFileUploadSubmit = this.handleFileUploadSubmit.bind(this);
    this.fileSelected = this.fileSelected.bind(this);
  }

  componentDidMount() {
    const { financialAssistance } = this.props;
    this.setState({
      noGroupIdCheckbox: !financialAssistance.group_id,
      noPCNCheckbox: !financialAssistance.pcn,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { fileFormName } = this.props;
    if (prevState.pendingDocument && prevState.pendingDocument.fileName !== fileFormName) {
      this.onPendingDocumentFileNameChange(fileFormName);
    }
  }

  onPendingDocumentFileNameChange(newFileName) {
    this.setState(prevState => ({
      pendingDocument: {
        ...prevState.pendingDocument,
        ...{ fileName: newFileName },
      },
    }));
  }

  // upload file
  handleFileUploadCancel() {
    this.setState({ pendingDocument: undefined });
  }

  handleFileUploadSubmit() {
    this.setState({ uploadProcessing: true });
    const { pendingDocument } = this.state;
    const {
      selectedCustomerId,
      selectedPatientId,
      fileFormName,
      fileFormTypes,
      fileFormNote,
      financialAssistance,
    } = this.props;

    if (pendingDocument) {
      uploadFile(
        pendingDocument.file,
        `${pendingDocument.fileName}.${pendingDocument.fileExtension}`,
        selectedCustomerId,
        selectedPatientId,
      )
        .then(result => {
          this.setState(prevState => ({
            uploadedDocuments: [
              ...prevState.uploadedDocuments,
              {
                uuid: result.uuid,
                taskTypeId: tagType.value,
                taskIds: [financialAssistance.id],
                filename: `${fileFormName}.${pendingDocument.fileExtension}`,
                labels: fileFormTypes.split(','),
                note: fileFormNote,
              },
            ],
            pendingDocument: undefined,
            uploadProcessing: false,
          }));
        })
        .catch(() => {
          this.setState({
            fileUploadErrorMessage: 'File could not be uploaded. Try again.',
          });
        });
    }
  }

  fileSelected(event) {
    if (event && event.length > 0) {
      const selectedFile = event[0];
      const { filePrefix, fileExtension } = getFileExtension(selectedFile.name);
      if (acceptedFileExtensions.includes(fileExtension.toLowerCase())) {
        const { changeField, fileUploadFormId } = this.props;
        changeField(fileUploadFormId, UPLOAD_DOC_FILENAME_FIELD, filePrefix);

        this.setState({
          pendingDocument: {
            fileName: filePrefix,
            fileExtension,
            file: selectedFile,
          },
        });
      }
    }
  }

  handleDialogContinue(values) {
    const { pendingDocument } = this.state;
    if (pendingDocument) {
      const lostData = [];

      if (pendingDocument) {
        lostData.push(`Pending: ${pendingDocument.fileName}.${pendingDocument.fileExtension}`);
      }

      this.setState({
        pendingDocumentLoss: true,
        lostData,
        dialogCancel: () => {
          this.setState({ pendingDocumentLoss: false });
        },
        dialogContinue: () => {
          this.handleSubmit(values);
        },
      });
    } else {
      this.handleSubmit(values);
    }
  }

  handleDialogCancel() {
    const { uploadedDocuments, pendingDocument } = this.state;
    const { cancel } = this.props;
    if ((uploadedDocuments && uploadedDocuments.length > 0) || pendingDocument) {
      const lostData = uploadedDocuments.map(ud => `Uploaded: ${ud.filename}`) || [];

      if (pendingDocument) {
        lostData.push(`Pending: ${pendingDocument.fileName}.${pendingDocument.fileExtension}`);
      }

      this.setState({
        pendingDocumentLoss: true,
        lostData,
        dialogCancel: () => {
          this.setState({ pendingDocumentLoss: false });
        },
        dialogContinue: () => {
          cancel();
        },
      });
    } else {
      cancel();
    }
  }

  // Handles the submit functionality. Right now it sets some fields to null for simplicity's sake
  handleSubmit(values) {
    const {
      editFinancialAssistanceTask, // eslint-disable-line
      reset,
      cancel,
      financialAssistance,
      existingDocuments,
      addNewDocument, // eslint-disable-line
    } = this.props;
    const { noGroupIdCheckbox, noPCNCheckbox, uploadedDocuments } = this.state;
    const payload = {
      ...values,
      id: financialAssistance.id,
      patient_id: financialAssistance.patient_id,
      therapy_id: financialAssistance.therapy_id,
      task_fa_id: financialAssistance.task_fa_id,
      status: values.status || values.status === 0 ? values.status : '',
      annual_limit: values.annual_limit ? stripFormattedDollarValue(values.annual_limit) : null,
      remaining_balance: values.remaining_balance
        ? stripFormattedDollarValue(values.remaining_balance)
        : null,
      expiration_date: values.expiration_date
        ? convertToArborDate(values.expiration_date).getUtcDatetime()
        : null,
      phone_number: values.phone_number ? values.phone_number : '',
      bin: values.bin ? values.bin : null,
      pcn: noPCNCheckbox ? null : values.pcn,
      member_id: values.member_id || null,
      group_id: noGroupIdCheckbox ? null : values.group_id,
      instructions: values.instructions ? values.instructions : '',
      award_amount: values.award_amount ? stripFormattedDollarValue(values.award_amount) : null,
      copay: values.copay ? stripFormattedDollarValue(values.copay) : null,
      documents: uploadedDocuments.map(doc => ({
        uuid: doc.uuid,
        file_name: doc.filename,
        labels: doc.labels,
        tags: doc.taskIds.map(id => ({
          tag_type_id: tagType.value,
          resource_id: id,
        })),
        noteText: doc.note,
      })),
    };

    editFinancialAssistanceTask(payload);

    if (uploadedDocuments.length) {
      getDocuments({
        patient_id: financialAssistance.patient_id,
        fetchFromId: Math.max(...existingDocuments.map(d => d.id)),
      }).then(result => {
        addNewDocument(result.data.documents);
      });
    }

    cancel();
    reset();
  }

  handleGroupIdCheckbox() {
    this.setState(prevState => ({
      noGroupIdCheckbox: !prevState.noGroupIdCheckbox,
    }));
  }

  handleNoPCNCheckbox() {
    this.setState(prevState => ({
      noPCNCheckbox: !prevState.noPCNCheckbox,
    }));
  }

  render() {
    const {
      submitting,
      classes,
      addingFromFinancials,
      therapies,
      financialAssistance,
      fileUploadFormId,
      defaultDocumentLabels,
      pristine,
      handleSubmit,
    } = this.props;

    const {
      status,
      therapyId,
      noGroupIdCheckbox,
      noPCNCheckbox,
      pendingDocument,
      fileUploadErrorMessage,
      uploadProcessing,
      uploadedDocuments,
      pendingDocumentLoss,
      lostData,
      dialogCancel,
      dialogContinue,
    } = this.state;

    const isPharmacyBenefit = financialAssistance.financial_assistance_type === 1001;

    const uploadProps = {
      pendingDocument,
      fileUploadErrorMessage,
      uploadProcessing,
      uploadedDocuments,
      pendingDocumentLoss,
      classes,
      fileUploadFormId,
      defaultDocumentLabels,
      handleFileUploadSubmit: this.handleFileUploadSubmit,
      handleFileUploadCancel: this.handleFileUploadCancel,
      fileSelected: this.fileSelected,
    };

    const documentConfirmationDialogProps = {
      lostData,
      dialogCancel,
      dialogContinue,
      pendingDocumentLoss,
    };

    return (
      <Grid container justifyContent="center" spacing={7}>
        <Grid item xs={12}>
          <DocumentConfirmationDialog {...documentConfirmationDialogProps} />
          <form className={classes.addFormContainer} autoComplete="off">
            <Grid container>
              {addingFromFinancials && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name="therapy_id"
                    label="Therapy *"
                    validate={[required]}
                    component={renderDropdown}
                    fields={Object.values(therapies).map(t => ({
                      label: t.drug_name,
                      value: t.id,
                    }))}
                    state={therapyId}
                  />
                </Grid>
              )}
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="status"
                  label="Status"
                  component={renderDropdown}
                  fields={[
                    { label: 'Active', value: 1 },
                    { label: 'Inactive', value: 0 },
                  ]}
                  state={status}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="award_amount"
                  label="Award Amount"
                  component={renderMoneyTextField}
                  validate={[validateDollarValue]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="annual_limit"
                  label="Annual Limit"
                  component={renderMoneyTextField}
                  validate={[validateDollarValue]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="expiration_date"
                  label="Expiration Date *"
                  component={renderDatePicker}
                  validate={[validateDate, required]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="remaining_balance"
                  label="Remaining Balance"
                  component={renderMoneyTextField}
                  validate={[validateDollarValue]}
                />
              </Grid>
              {isPharmacyBenefit && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name="bin"
                    label="BIN"
                    component={renderTextField}
                    validate={[validateNumeric, length6]}
                  />
                </Grid>
              )}
              {isPharmacyBenefit && (
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={6} className={classes.fieldContainer}>
                      <Field
                        name="pcn"
                        label={!noPCNCheckbox ? 'PCN *' : 'PCN'}
                        component={renderTextField}
                        disabled={noPCNCheckbox}
                        validate={!noPCNCheckbox ? [required, maxLength20] : null}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container alignItems="center" justifyContent="flex-start">
                        <Checkbox
                          checked={noPCNCheckbox}
                          color="primary"
                          onChange={this.handleNoPCNCheckbox}
                          value="checkedPCN"
                          label="Secondary"
                        />
                        <DetailField defaultValue="No PCN" />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {isPharmacyBenefit && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name="phone_number"
                    label="Phone Number"
                    component={renderPhoneTextField}
                  />
                </Grid>
              )}
              {isPharmacyBenefit && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name="member_id"
                    label="Member ID *"
                    validate={[required]}
                    component={renderTextField}
                  />
                </Grid>
              )}

              {!isPharmacyBenefit && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field name="member_id" component={renderTextField} label="Member ID" />
                </Grid>
              )}
              {isPharmacyBenefit && (
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={6} className={classes.fieldContainer}>
                      <Field
                        name="group_id"
                        label={!noGroupIdCheckbox ? 'Group ID *' : 'Group ID'}
                        validate={!noGroupIdCheckbox ? [required] : null}
                        component={renderTextField}
                        disabled={noGroupIdCheckbox}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container alignItems="center" justifyContent="flex-start">
                        <Checkbox
                          checked={noGroupIdCheckbox}
                          color="primary"
                          onChange={this.handleGroupIdCheckbox}
                          value="checkedA"
                          label="Secondary"
                        />
                        <DetailField defaultValue="No Group Id" />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={6} className={classes.fieldContainer}>
                <Field
                  name="instructions"
                  label="Processing Instructions"
                  component={renderTextField}
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="copay"
                  label="Copay"
                  component={renderMoneyTextField}
                  decimalScale={2}
                  fixedDecimalScale
                  decimalSeparator="."
                />
              </Grid>
            </Grid>
            <WrappedFileUpload {...uploadProps} />
            <SmallSpacer />
            <ConfirmationPanel
              cancelButtonName="add_task_cancel_button"
              submitButtonName="add_task_submit_button"
              handleCancel={this.handleDialogCancel}
              handleSubmit={handleSubmit(this.handleDialogContinue)}
              disableSubmit={
                submitting || (pristine && !pendingDocument && !uploadedDocuments.length)
              }
            />
          </form>
        </Grid>
      </Grid>
    );
  }
}

function handleFormName(id) {
  const fileFormId = `${UPLOAD_DOCUMENT_FORM}-${tagType.label}-${id}`;
  return fileFormId;
}

function mapStateToProps(state, props) {
  const { therapies, uploadedDocuments } = state;
  const { financialAssistance } = props;

  const fileUploadFormId = handleFormName(financialAssistance.id);
  const selector = formValueSelector(fileUploadFormId);
  const fileFormName = selector(state, UPLOAD_DOC_FILENAME_FIELD);
  const fileFormTypes = selector(state, UPLOAD_DOC_FILETYPE_FIELD);
  const fileFormNote = selector(state, UPLOAD_DOC_FILENOTE_FIELD);
  return {
    therapies: therapies.data,
    initialValues: {
      status:
        props.financialAssistance.status || props.financialAssistance.status === 0
          ? props.financialAssistance.status
          : '',
      annual_limit: props.financialAssistance.annual_limit
        ? props.financialAssistance.annual_limit
        : '',
      remaining_balance: props.financialAssistance.remaining_balance
        ? props.financialAssistance.remaining_balance
        : '',
      expiration_date: props.financialAssistance.expiration_date
        ? convertToArborDate(props.financialAssistance.expiration_date, true).getCustomerDate(true)
        : null,
      bin: props.financialAssistance.bin ? props.financialAssistance.bin : '',
      pcn: props.financialAssistance.pcn ? props.financialAssistance.pcn : '',
      group_id: props.financialAssistance.group_id ? props.financialAssistance.group_id : '',
      phone_number: props.financialAssistance.phone_number
        ? props.financialAssistance.phone_number
        : '',
      instructions: props.financialAssistance.instructions
        ? props.financialAssistance.instructions
        : '',
      award_amount: props.financialAssistance.award_amount
        ? props.financialAssistance.award_amount
        : '',
      member_id: props.financialAssistance.member_id || null,
      copay: props.financialAssistance.copay,
    },
    defaultDocumentLabels: state.lookups.defaultDocumentLabels,
    fileUploadFormId,
    fileFormName,
    fileFormTypes,
    fileFormNote,
    selectedCustomerId: state.filters.selectedCustomerId,
    selectedPatientId: props.financialAssistance.patient_id,
    existingDocuments: uploadedDocuments.documents,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      editFinancialAssistanceTask,
      fetchFinancialAssistance,
      changeField: (formName, fieldName, value) => dispatch(_change(formName, fieldName, value)),
      addNewDocument,
    },
    dispatch,
  );
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(reduxForm({ form: EDIT_FINANCIAL_ASSISTANCE_FORM })(EditCopayCard));

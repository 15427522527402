enum TaskStatus {
  AttemptedToReachPatient = 'Attempted To Reach Patient',
  Canceled = 'Canceled',
  Completed = 'Completed',
  Delivered = 'Delivered',
  DeliveryException = 'Delivery Exception',
  OnHold = 'On Hold',
  PickedUp = 'Picked Up',
  Required = 'Required',
  Scheduled = 'Scheduled',
  Submitted = 'Submitted',
  Approved = 'Approved',
  Denied = 'Denied',
  Appealed = 'Appealed',
  Expired = 'Expired',
  NotRequired = 'Not Required',
  TimeOut = 'Time Out',
  Investigate = 'Investigate',
  AlreadyInPlace = 'AlreadyInPlace',
  DeniedNoAppeal = 'Denied - No Appeal',
  PharmacistReviewNeeded = 'Pharmacist Review Needed',
  Requested = 'Requested',
  NotRequested = 'Not Requested',
  Exhausted = 'Exhausted',
  Shipped = 'Shipped',
  WillPickUp = 'Will Pick Up',
  Packed = 'Packed',
  Dispense = 'Dispense',
  InProgress = 'In Progress',
  InProgressCallPatient = 'In Progress - Call Patient',
  InProgressWaitingForPatientResponse = 'In Progress - Waiting for Patient Response',
  InProgressPatientResponseReady = 'In Progress - Patient Response Ready',
  InProgressSecureLinkExpired = 'In Progress - Secure Link Expired',
  InProgressLeftVm = 'In Progress - Left VM',
  DataCollected = 'Data Collected',
  DataReviewed = 'Data Reviewed',
  Intervening = 'Intervening',
  RequiresAttention = 'Requires Attention',
  MedsReviewed = 'Meds reviewed',
  DdiGenerated = 'DDI generated',
  DdiReviewed = 'DDI reviewed',
  Generated = 'Generated',
  Reviewed = 'Reviewed',
  Escalated = 'Escalated',
  Resolved = 'Resolved',
  DeferredMigration = 'Deferred (migration only)',
  DeclinedMigration = 'Declined (migration only)',
  NotACandidateMigration = 'Not a Candidate (migration only)',
  ClinicalSupportOnlyMigration = 'Clinical Support-Only (migration only)',
  NoFurtherAssistanceMigration = 'No Further Assistance (migration only)',
  EnrolledInternalPharmacyMigration = 'Enrolled Internal Pharmacy (migration only)',
  WillNotMeetWithPatient = 'Will not meet with patient',
  NoValidTherapies = 'No Valid Therapies',
  UnableToMeetWithPatient = 'Unable to meet with patient',
  PatientUnreachable = 'Patient Unreachable',
  Unknown = 'Unknown',
  /* @deprecated */
  Validate = 'Offer Service', // Replace by Offer Service
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  OfferService = 'Offer Service',
  Accepted = 'Accepted',
  NeedsReview = 'Needs Review',
  FundingAvailable = 'Funding Available',
  OfferLaterDate = 'Offer at a Later Date',
  SpokewithPatient = 'Spoke with Patient',
}
export { TaskStatus };

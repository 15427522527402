import { Grid, Divider, Modal } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import AccordionHeader from 'components/form/header/accordion-header';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import { accordianStyles } from 'components/accordian/accordian-styles';
import DocumentDisplay from 'components/document/document-display';
import { combineStyles, getModalStyle } from 'services/utils/styles-service';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SmallSpacer, LargeSpacer } from 'components/spacer/spacer';
import { editMedicalInsurance, fetchMedicalInsurances } from 'actions/action-financials';
import History from 'containers/common/history';
import moment from 'moment';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getMedicalInsuranceHeader } from 'services/utils/financials-service';
import { getDocumentsForDisplay } from 'services/utils/document-service';
import { convertToArborDate } from 'models/time/arbor-date';
import { VerificationPanel } from 'components/form/verification/verification-panel';
import { getUserById } from 'services/utils/users-service';
import TalkdeskPhoneNumber from 'components/talkdesk-phone-number/talkdesk-phone-number';
import SubHeader from '../../../../components/form/header/subheader';
import { styles } from '../financials-styles';
import DetailField from '../../../../components/form/field/field';
import EditMedicalInsurance from './edit-medical-insurance';
import { MEDICAL_INSURANCE, EDIT_MEDICAL_INSURANCE_FORM } from '../../../../constants';
import { noteTagTypes } from '../../../../constants/lists';

const tagType = noteTagTypes.find(tag => tag.label === MEDICAL_INSURANCE);

class MedicalDetail extends Component {
  constructor(props) {
    super(props);
    const { medicalInsurance } = this.props;
    this.state = {
      displayDetail: medicalInsurance.end_date === null,
      displayEdit: false,
      editHistoryModalOpen: false,
      verified: medicalInsurance.is_verified,
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.handleEditClose = this.handleEditClose.bind(this);
    this.handleEditHistoryModal = this.handleEditHistoryModal.bind(this);
    this.handleVerify = this.handleVerify.bind(this);
    this.handleExpand = this.handleExpand.bind(this);
  }

  handleEdit(e) {
    e.stopPropagation();
    this.setState({
      displayEdit: true,
    });
  }

  handleEditClose(verified) {
    this.setState({
      displayEdit: false,
      verified,
    });
  }

  handleExpand() {
    const { displayDetail, editHistoryModalOpen } = this.state;
    if (!editHistoryModalOpen) {
      this.setState({
        displayDetail: !displayDetail,
      });
    }
  }

  handleEditHistoryModal(e) {
    e.stopPropagation();
    this.setState(prevState => ({
      editHistoryModalOpen: !prevState.editHistoryModalOpen,
    }));
  }

  handleVerify() {
    const {
      medicalInsurance,
      editMedicalInsurance, //eslint-disable-line
      fetchMedicalInsurances, //eslint-disable-line
      patientId,
    } = this.props;

    const payload = {
      ...medicalInsurance,
      policy_holder_dob: medicalInsurance.policy_holder_dob
        ? convertToArborDate(medicalInsurance.policy_holder_dob).getUtcDate()
        : null,
      start_date: medicalInsurance.start_date
        ? convertToArborDate(medicalInsurance.start_date).getUtcDate()
        : null,
      end_date: medicalInsurance.end_date
        ? convertToArborDate(medicalInsurance.end_date).getUtcDate()
        : null,
      is_verified: 1, // set is_verified to 1
      verified_dt: convertToArborDate(moment()).getUtcDatetime(),
    };

    this.setState({
      verified: 1,
    });

    editMedicalInsurance(payload).then(() => {
      fetchMedicalInsurances(patientId);
    });
  }

  displayDetail() {
    const { classes, medicalInsurance, users, planSponsors } = this.props;
    const { verified } = this.state;
    const isNoInsurance = Boolean(medicalInsurance.is_no_insurance);
    const verifiedUser = getUserById(medicalInsurance.verified_by, users);

    if (isNoInsurance) {
      return (
        <div className={classes.containerDiv}>
          <Grid container>
            <Grid item xs={12}>
              <VerificationPanel
                type="pbm"
                isVerified={verified}
                handleVerify={this.handleVerify}
                verifiedBy={verifiedUser}
                verifiedDate={medicalInsurance.verified_dt}
              />
            </Grid>
          </Grid>
        </div>
      );
    }
    const planSponsorName = planSponsors.find(o => o.id === medicalInsurance.plan_sponsor)?.type;
    return (
      <div className={classes.containerDiv}>
        <Grid container alignItems="flex-end">
          <Grid item xs={3}>
            <DetailField fieldName="Insurance Plan Name" field={medicalInsurance.plan_name} />
          </Grid>
          <Grid item xs={3}>
            <TalkdeskPhoneNumber
              number={medicalInsurance.plan_phone_number}
              headerText="Insurance Plan Phone Number"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField fieldName="Group ID" field={medicalInsurance.group_number} variant="raw" />
          </Grid>
          <Grid item xs={3}>
            <DetailField fieldName="Member ID" field={medicalInsurance.member_id} variant="raw" />
          </Grid>
        </Grid>
        <SmallSpacer />
        <Divider light />
        <SmallSpacer />
        <Grid container alignItems="flex-end">
          <Grid item xs={12}>
            <SubHeader name="Policy Holder" />
            <SmallSpacer />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Policy Holder Name"
              field={`${
                medicalInsurance.policy_holder_last_name
                  ? medicalInsurance.policy_holder_last_name
                  : ''
              }, ${
                medicalInsurance.policy_holder_first_name
                  ? medicalInsurance.policy_holder_first_name
                  : ''
              }`}
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Relationship to Patient"
              field={medicalInsurance.relationship_name}
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Policy Holder Date of Birth"
              field={
                medicalInsurance.policy_holder_dob &&
                convertToArborDate(medicalInsurance.policy_holder_dob, true).getUtcDate(true)
              }
            />
          </Grid>
        </Grid>
        <Grid container alignItems="flex-end">
          <Grid item xs={3}>
            <DetailField fieldName="Plan Sponsor" field={planSponsorName} />
          </Grid>
          <Grid item xs={3}>
            <DetailField fieldName="Employer" field={medicalInsurance.policy_holder_sponsor} />
          </Grid>
        </Grid>
        <LargeSpacer />
        <Grid container>
          <Grid item xs={12}>
            <VerificationPanel
              type="medical"
              isVerified={verified}
              handleVerify={this.handleVerify}
              verifiedBy={verifiedUser}
              verifiedDate={medicalInsurance.verified_dt}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    const { classes, medicalInsurance, uploadedDocuments } = this.props;
    const { displayEdit, displayDetail, editHistoryModalOpen } = this.state;

    const getHistoryURL = (patientId, resourceId) =>
      `/patients/${patientId}/financials/patient-medical-insurances/${resourceId}`;

    const isNoInsurance = Boolean(medicalInsurance.is_no_insurance);

    const getDocuments = (resourceId, tagTypeId) =>
      getDocumentsForDisplay(uploadedDocuments.documents, resourceId, tagTypeId);

    return (
      <div>
        <Accordion expanded={!!displayDetail} className={classes.accordianPanel}>
          <AccordionSummary
            classes={{
              expandIconWrapper: classes.expandIcon,
              content: classes.summaryContent,
            }}
            onClick={this.handleExpand}
            expandIcon={<ExpandMoreIcon />}
          >
            <AccordionHeader
              name={getMedicalInsuranceHeader(medicalInsurance, isNoInsurance)}
              updatedDt={medicalInsurance.updated}
              updatedBy={medicalInsurance.updated_by_user}
              editHandler={this.handleEdit}
              withHistory
              minimal
              historyHandler={this.handleEditHistoryModal}
              id={`${medicalInsurance.plan_name} (${convertToArborDate(
                medicalInsurance.start_date,
                true,
              ).getCustomerDate(true)} -
              ${
                medicalInsurance.end_date === null
                  ? 'Present'
                  : convertToArborDate(medicalInsurance.end_date, true).getCustomerDate(true)
              })`}
            />
            <Modal open={editHistoryModalOpen} onClose={this.handleEditHistoryModal}>
              <div style={getModalStyle()} className={classes.Modal}>
                <History
                  url={getHistoryURL(medicalInsurance.patient_id, medicalInsurance.id)}
                  subType="patient-medical-insurances"
                />
              </div>
            </Modal>
          </AccordionSummary>
          <AccordionDetails>
            {!displayEdit ? (
              <Grid container direction="column">
                <Grid container justifyContent="flex-end">
                  <Grid item xs={3}>
                    <DocumentDisplay
                      containerClass={classes.innerDocumentContainer}
                      taskIdResourceIds={[
                        {
                          tagTypeId: tagType.value,
                          resourceId: medicalInsurance.id,
                        },
                      ]}
                      tagTypeId={tagType.value}
                      resourceId={medicalInsurance.id}
                      drugName={MEDICAL_INSURANCE}
                      tagTypeLabel={medicalInsurance.insurance_type}
                      documents={getDocuments(medicalInsurance.id, tagType.value)}
                    />
                  </Grid>
                </Grid>
                <Grid item>{this.displayDetail()}</Grid>
              </Grid>
            ) : (
              <EditMedicalInsurance
                medicalInsurance={medicalInsurance}
                cancel={this.handleEditClose}
                form={`${EDIT_MEDICAL_INSURANCE_FORM}_${medicalInsurance.id}`}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <SmallSpacer />
      </div>
    );
  }
}

const combinedStyles = combineStyles(styles, accordianStyles);

function mapStateToProps(state) {
  const { lookups, uploadedDocuments } = state;
  return {
    users: lookups.users,
    uploadedDocuments,
    planSponsors: lookups.planSponsors,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchMedicalInsurances,
      editMedicalInsurance,
    },
    dispatch,
  );
}

export default compose(
  withStyles(combinedStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(MedicalDetail);

import { Grid, Tooltip, Typography } from '@mui/material';
import { useTypedSelector } from 'hooks/use-typed-selector';
import React from 'react';
import { contactMethodTypes } from 'constants/lists';
import { ContactMethodTypes } from 'constants/enums';
import { buildQaId } from 'utils/build-qa-id';
import { EHRIcon } from 'components/icons/icons';
import { PreferredContactMethodNote } from './preferred-contact-method-note';
import { useStyles } from './preferred-contact.styles';
import { PreferredContactName } from './preferred-contact-name';

const getQaId = buildQaId('preferred-contact-ehr');

export const PreferredContactEhr: React.FC = (): JSX.Element | null => {
  const classes = useStyles();

  const preferredContact = useTypedSelector(state => state.preferredContact);
  const patient = useTypedSelector(state => {
    return state.patient;
  });

  const label = React.useMemo<string | undefined>(() => {
    const label = contactMethodTypes.find(x => x.value === ContactMethodTypes.EHRMessage)?.label;
    if (label) {
      return label;
    }
    return undefined;
  }, [patient]);

  return patient?.preferred_contact_method != null || preferredContact != null ? (
    <>
      <PreferredContactName />
      <Grid container alignItems="center" data-qa-id={getQaId('wrapper')}>
        <Grid item>
          <Tooltip title="EHR">
            <EHRIcon data-qa-id={getQaId('ehr-icon')} className={classes.icon} />
          </Tooltip>
        </Grid>
        <Grid item>
          <Typography
            display="inline"
            data-qa-id={getQaId('secondary-line')}
            className={classes.typographyTextSmall}
          >
            {label}
          </Typography>
        </Grid>
        <Grid item>
          <PreferredContactMethodNote />
        </Grid>
      </Grid>
    </>
  ) : null;
};

import { takeLatest, call, put } from 'redux-saga/effects';
import {
  SELECTED_PATIENT_ID,
  PATIENT_CONTACT_LIST_REQUEST,
  PATIENT_CONTACT_LIST_SUCCESS,
  PATIENT_CONTACT_LIST_FAILURE,
} from '../constants';
import HTTP from '../services/http';

function* workerFetchContactList(action) {
  const patientId = action.payload;
  yield put({ type: PATIENT_CONTACT_LIST_REQUEST });
  try {
    const response = yield call(() => HTTP.get(`patients/${patientId}/contacts`, {}));
    yield put({
      type: PATIENT_CONTACT_LIST_SUCCESS,
      payload: response.data.contacts,
      meta: {
        patientId,
      },
    });
  } catch (error) {
    yield put({ type: PATIENT_CONTACT_LIST_FAILURE });
  }
}

export function* watcherContactsSaga() {
  yield takeLatest([SELECTED_PATIENT_ID], workerFetchContactList);
}

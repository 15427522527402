import { MED_SYNC_UPDATE_SUCCESS } from 'containers/patient/med-sync/med-sync-actions';
import {
  FETCH_FILL_CYCLES_SUCCESS,
  ADD_THERAPY,
  ADD_THERAPY_AR,
  EDIT_THERAPY,
  SELECTED_PATIENT_ID,
  UPDATE_THERAPY_PROPERTIES,
} from '../constants';

export default (state = {}, { type, payload }) => {
  switch (type) {
    case SELECTED_PATIENT_ID:
      return {};
    case FETCH_FILL_CYCLES_SUCCESS:
      if (payload.data) {
        return { ...payload.data };
      }
      return state;
    case ADD_THERAPY_AR:
    case ADD_THERAPY: {
      if (payload && payload.data) {
        const { therapy } = payload.data;
        const newState = { ...state };
        if (therapy.fill_cycle) {
          newState[therapy.id] = {
            [therapy.fill_cycle]: {
              therapy_id: therapy.id,
              fill_cycle: therapy.fill_cycle,
              is_needsby_per_protocol: therapy.is_needsby_per_protocol,
              days_supply: therapy.days_supply,
              needsby_date: therapy.needsby_date,
              one_time_fill: therapy.one_time_fill,
            },
          };
        }
        return newState;
      }
      return state;
    }
    case EDIT_THERAPY: {
      if (payload && payload.data) {
        const [therapy] = payload.data.therapy;
        const newState = { ...state };
        if (therapy.fill_cycle && therapy.id) {
          if (newState[therapy.id]) {
            newState[therapy.id][therapy.fill_cycle] = {
              therapy_id: therapy.id,
              fill_cycle: therapy.fill_cycle,
              is_needsby_per_protocol: therapy.is_needsby_per_protocol,
              days_supply: therapy.days_supply,
              needsby_date: therapy.needsby_date,
              one_time_fill: therapy.one_time_fill,
            };
          } else {
            newState[therapy.id] = {
              [therapy.fill_cycle]: {
                therapy_id: therapy.id,
                fill_cycle: therapy.fill_cycle,
                is_needsby_per_protocol: therapy.is_needsby_per_protocol,
                days_supply: therapy.days_supply,
                needsby_date: therapy.needsby_date,
                one_time_fill: therapy.one_time_fill,
              },
            };
          }
        }
        return newState;
      }
      return state;
    }
    case UPDATE_THERAPY_PROPERTIES: {
      /**
       * The needsby_date associated with a therapy originates from fill_cycles,
       * but needsby_date also exists on therapies in our redux store. This reducer
       * keeps the fill_cycles in sync when a therapy's needsby_date state is updated.
       */
      const therapyId = payload.id;
      const needsbyDate = payload.values?.needsby_date;
      const fillCycleTherapy = state[therapyId];
      const maxFillCycle = fillCycleTherapy ? Math.max(...Object.keys(fillCycleTherapy)) : null;

      if (
        [therapyId, needsbyDate, fillCycleTherapy, maxFillCycle].every(
          t => typeof t !== 'undefined',
        )
      ) {
        return {
          ...state,
          [therapyId]: {
            ...fillCycleTherapy,
            [maxFillCycle]: {
              ...state[therapyId][maxFillCycle],
              needsby_date: needsbyDate,
            },
          },
        };
      }
      return state;
    }
    case MED_SYNC_UPDATE_SUCCESS: {
      const { medSyncData } = payload;
      const nextState = { ...state };
      medSyncData.forEach(medSync => {
        const fillCycleTherapy = state[medSync.id];
        const maxFillCycle = fillCycleTherapy ? Math.max(...Object.keys(fillCycleTherapy)) : null;
        if (maxFillCycle) {
          nextState[medSync.id][maxFillCycle].days_supply = medSync.daysSupply;
          nextState[medSync.id][maxFillCycle].needsby_date = medSync.nbd;
        }
      });
      return nextState;
    }
    default:
      return state;
  }
};

import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

export const styles = (theme: Theme) => {
  const themeAny = theme as any;
  const fontTheme = themeAny.font as any;

  return createStyles({
    locationTitle: {
      fontSize: fontTheme.mediumFontSize,
      fontWeight: 'bold',
    },
    locationWrapper: {
      height: '100%',
      alignContent: 'flex-end',
    },
  });
};

export const styles = theme => ({
  jumpBtnContainer: {
    marginRight: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  jumpBtn: {
    margin: theme.spacing(1),
    borderRadius: 20,
    backgroundColor: theme.palette.primary.grey2,
  },
  wording: {
    display: 'inline-block',
  },
  anchor: {
    display: 'block',
    position: 'relative',
    visibility: 'hidden',
    top: -85,
  },
});

import { diff } from 'deep-diff';
import React, { ComponentClass, PropsWithChildren, memo } from 'react';
import { ConfigProps, DecoratedFormProps, InjectedFormProps, reduxForm } from 'redux-form';

const ignoredReduxFormPropKeys: (keyof InjectedFormProps | string)[] = [
  'anyTouched',
  'array',
  'asyncValidate',
  'asyncValidating',
  'autofill',
  'blur',
  'change',
  'clearAsyncError',
  'clearSubmit',
  'destroy',
  'handleSubmit',
  'reset',
  'initialize',
  'submitting',
  'touch',
  'untouch',
];

const reduxFormPrefilter = (path: string[], key: string) =>
  ignoredReduxFormPropKeys.includes(path[0] || key);

export function createMemoReduxForm(formConfig: Partial<ConfigProps> = {}) {
  return function withMemoReduxForm<P extends object, T extends PropsWithChildren<P>>(
    BaseComponent: React.ComponentType<T>,
  ) {
    const WithFormComponent = reduxForm(formConfig)(BaseComponent as any) as ComponentClass<
      T & DecoratedFormProps<object, T>
    >;
    const MemoFormComponent = memo(WithFormComponent, (prevProps, nextProps) => {
      // Ignore prop changes where function equality fails on redux form fn props
      const differences = diff(prevProps, nextProps, reduxFormPrefilter);
      return !differences;
    });
    return MemoFormComponent;
  };
}

export default createMemoReduxForm;

export class TimeoutUtils {
  /**
   * The initial intention of this was to ensure that certain API requests take a minium amount of
   * time to avoid certain things in the UI like showing a loader for 20ms then hiding it which
   * causes ugly UI events.
   *
   * Usage in that use case is something like:
   *  const request = HTTP.get();
   *  const wait = TimeoutUtils.wait(250);
   *
   *  const [,result] = Promise.all([request, wait]);
   * @param minLength Amount of ms to wait before resolving the promise.
   */
  public static wait(minLength: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, minLength));
  }
}

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';
import { grey } from '@mui/material/colors';

export const styles = (theme: Theme) => {
  const colorPalette = theme.palette.primary as any;
  const { font } = expandTheme(theme);

  return createStyles({
    outerContainer: {
      padding: 20,
    },
    label: {
      fontSize: 12,
      lineHeight: '15px',
      color: colorPalette.grey12,
      paddingBottom: 6,
    },
    value: {
      fontSize: 14,
      lineHeight: '15px',
      color: colorPalette.steelGray,
    },
    heading: {
      paddingBottom: 10,
    },
    gridRow: {
      paddingBottom: 15,
    },
    divider: {
      border: `1px solid ${grey[300]}`,
      width: '100%',
      marginBottom: 20,
    },
    textBox: {
      backgroundColor: grey[200],
      padding: 10,
    },
    rejectMessage: {
      fontSize: font.mediumFontSize,
    },
  });
};

import { isFunction } from 'lodash';

type LabelCb<T> = (row: T) => string;
export function transformToOptions<T>(
  valueField: keyof T,
  labelField: keyof T | LabelCb<T>,
  data: T[],
) {
  return data.map(item => {
    if (isFunction(labelField)) {
      return {
        value: item[valueField],
        label: (labelField as LabelCb<T>)(item),
      };
    }
    return {
      value: item[valueField],
      label: item[labelField as keyof T],
    };
  });
}

export const SmsOptions = [
  {
    label: 'Not Specified',
    value: 'Not Specified',
  },
  {
    label: 'Opt in',
    value: 'Opt in',
  },
];

export const FirstFillOptions = [
  {
    label: 'Yes',
    value: 0,
  },
  {
    label: 'No',
    value: 1,
  },
];

export const AdherencePackagingOptions = [
  {
    label: 'Yes',
    value: 0,
  },
  {
    label: 'No',
    value: 1,
  },
];

import BundleTaskForm from 'components/dynamic-form/bundle-task-form';
import RadioStatusSelector from 'components/radio-status-selector/radio-status-selector';
import React from 'react';
import TaskStatusForm from 'components/dynamic-form/task-forms/task-status-form';
import TitleHeader from 'components/title-header';
import { compose } from 'redux';
import { CounselingStatusMap } from 'constants/task-statuses';
import withStyles from '@mui/styles/withStyles';
import { IStatusSelectorOption } from 'interfaces/IStatusSelectorOption';
import { ITaskStatus } from 'interfaces/ITaskStatus';
import { StatusMapUtil } from 'utils/status-map-util';
import { TaskStatus } from 'interfaces/enums/TaskStatus';
import { ICounselingTask } from 'interfaces/redux/task-types/ICounselingTask';
import EditCounseling from 'containers/tasks/counseling/edit-counseling';
import { CounselingStatus } from 'interfaces/enums/TaskStatuses/CounselingStatus';
import { COMPLETE_COUNSELING_FORM, CSL } from 'constants/index';
import { useSelector } from 'react-redux';
import { IState } from 'interfaces/redux/IState';
import { TaskStatusUtil } from 'utils/task-status-util';
import { IProps } from './interfaces/IProps';
import { styles } from './forms/styles/counseling.styles';
import Complete from './forms/complete';
import { selectSelectedTasks } from '../../../patient/tasks-new/selectors';

const CounselingTransition: React.FC<IProps> = (props: IProps) => {
  const [statusOptions, setStatusOptions] = React.useState<IStatusSelectorOption[]>([]);
  const [currentStatusName, setCurrentStatusName] = React.useState<string | undefined>(undefined);
  const [statusForm, setStatusForm] = React.useState<JSX.Element | null>(null);
  const [currentSelectedStatus, setCurrentSelectedStatus] = React.useState<number | undefined>();
  const [canUpdateStatus, setCanUpdateStatus] = React.useState<boolean>(true);
  const selectedTasks = useSelector(selectSelectedTasks);
  const drugsWithDosisRegimens = selectedTasks
    .filter(task => props.therapies[task?.therapy_id] && task.taskType === CSL)
    .map(task => {
      const { drug_name: drugName, dosis_regimen: dosisRegimen } =
        props.therapies[task?.therapy_id];

      return { drugName, dosisRegimen: dosisRegimen || '----' };
    });

  const currentUser = useSelector((state: IState) => state.auth.currentUser);
  const resourceStates = useSelector((state: IState) => state.lookups.resourceStates);
  const [firstTask] = props.tasks;
  const canTransition = React.useMemo(
    () =>
      TaskStatusUtil.buildCanTransitionFromPermissions(currentUser?.permissions, resourceStates),
    [currentUser?.permissions, resourceStates],
  );

  React.useEffect(() => {
    const startingStatus = CounselingStatusMap[firstTask.status_id];
    if (startingStatus) {
      const nextStatusId =
        startingStatus.nextStatus && canTransition(firstTask, startingStatus.nextStatus)
          ? startingStatus.nextStatus
          : firstTask.status_id;
      const mappedStatuses = StatusMapUtil.convertMapToStatusOptions(
        CounselingStatusMap,
        nextStatusId,
        nextStatusId,
        handleStatusChange,
      );
      setStatusOptions(mappedStatuses.sort((a, b) => a.sortIndex - b.sortIndex));
      setCurrentStatusName(startingStatus.status.toString());
      if (nextStatusId) {
        const status = statusFormMap(CounselingStatusMap[nextStatusId], nextStatusId, props.tasks);
        setCurrentSelectedStatus(nextStatusId);
        setStatusForm(status.form);
        setCanUpdateStatus(status.canChangeStatus);
      }
    } else {
      setCurrentStatusName(undefined);
    }
  }, [props.newStatusId]);

  React.useEffect(() => {
    if (currentSelectedStatus) {
      const statusId = props.newStatusId || currentSelectedStatus;
      const status = statusFormMap(CounselingStatusMap[statusId], statusId, props.tasks);
      setStatusForm(status.form);
      setCanUpdateStatus(status.canChangeStatus);
    }
  }, [props.tasks]);

  const statusToNotShowForm = [
    TaskStatus.Canceled,
    TaskStatus.OnHold,
    TaskStatus.Scheduled,
    TaskStatus.AttemptedToReachPatient,
  ];

  const statusFormMap = (
    taskStatus: ITaskStatus,
    statusId: number,
    tasks: ICounselingTask[],
  ): { form: JSX.Element | null; canChangeStatus: boolean } => {
    if (!statusToNotShowForm.includes(taskStatus.status)) {
      const editing =
        props.editing || props.tasks.every(x => x.status_id === CounselingStatus.Completed);

      return {
        form: (
          <Complete
            form={`${COMPLETE_COUNSELING_FORM}_${tasks[0].id}`}
            tasks={tasks}
            tab={props.tab}
            requestReview={taskStatus.status === TaskStatus.PharmacistReviewNeeded}
            therapyIndex={props.therapyIndex}
            onCancel={(taskIds: number[]) => {
              props.legacyCancelClick?.(taskIds);
            }}
            editing={editing}
            afterSubmit={props.afterSubmit}
          />
        ),
        canChangeStatus: !editing,
      };
    }
    const statusIds: Record<number, number> = {};
    const taskIds: number[] = [];
    props.tasks.forEach(task => {
      statusIds[task.id] = statusId;
      taskIds.push(task.id);
    });

    switch (props.tab) {
      case 'tasks':
        return {
          form: (
            <BundleTaskForm
              {...props.legacyFormProps}
              combinedTask={tasks}
              taskIds={taskIds}
              hideCard
              hideTitles
              currentTaskStatusIds={statusIds}
            />
          ),
          canChangeStatus: true,
        };
      case 'therapies':
        if (props.editing) {
          return {
            form: <EditCounseling {...props.legacyFormProps} task={props.tasks[0]} />,
            canChangeStatus: true,
          };
        }
        return {
          form: (
            <TaskStatusForm
              task={props.tasks[0]}
              forceStatusId={props.newStatusId}
              onCancel={props.legacyCancelClick}
            />
          ),
          canChangeStatus: true,
        };
    }
  };

  const handleStatusChange = (statusId: number) => {
    const startingStatus = CounselingStatusMap[firstTask.status_id];
    const mappedStatuses = StatusMapUtil.convertMapToStatusOptions(
      CounselingStatusMap,
      startingStatus.nextStatus && canTransition(firstTask, startingStatus.nextStatus)
        ? startingStatus.nextStatus
        : firstTask.status_id,
      statusId,
      handleStatusChange,
    );
    setStatusOptions(mappedStatuses.sort((a, b) => a.sortIndex - b.sortIndex));
    const selectedStatus = CounselingStatusMap[statusId];
    if (selectedStatus) {
      const status = statusFormMap(selectedStatus, statusId, props.tasks);
      setCurrentSelectedStatus(statusId);
      setStatusForm(status.form);
      setCanUpdateStatus(status.canChangeStatus);
    }
  };

  return (
    <>
      {!props.hideTitle && (
        <TitleHeader
          title="Counseling"
          currentStatusName={currentStatusName}
          drugs={drugsWithDosisRegimens}
          isCounseling
        />
      )}

      {!props.hideStatusSelector && (
        <RadioStatusSelector
          displayInline
          label="Status *"
          options={statusOptions}
          task={firstTask}
          disableMoreOption={!canUpdateStatus}
          currentUser={currentUser}
          resourceStates={resourceStates}
        />
      )}

      {statusForm}
    </>
  );
};

export default compose(withStyles(styles))(CounselingTransition);

import React from 'react';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import cx from 'classnames';
import { styles } from './field-styles';

const FieldLabel = ({ fieldName, classes, labelSize, onClick, pointer, variant }) => {
  let className = classes.fieldLabel;

  if (labelSize && labelSize === 'medium') {
    className = classes.fieldLabelMedium;
  }
  if (labelSize && labelSize === 'large') {
    className = classes.fieldLabelLarge;
  }

  return (
    <Typography
      variant={variant || 'caption'}
      className={cx(className, { [classes.fieldLabelPointer]: pointer })}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {fieldName}
    </Typography>
  );
};

export default withStyles(styles, { withTheme: true })(FieldLabel);

import React, { Component } from 'react';
import HTTP from 'services/http';
import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material';
import compose from 'recompose/compose';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { notifyError } from 'actions/action-notifications';
import { RS, ADD_TASK_RISK_STRAT_FORM } from 'constants/index';
import { workListChanged } from 'actions/action-patient';
import { fetchTaskCounts, addRiskStratTask } from 'actions/action-tasks';
import { convertToArborDate } from 'models/time/arbor-date';
import { TherapyUtil } from 'utils/therapy-util';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { ReactSelectForReduxSingle } from 'components/form/field/react-select';
import { getInitialServiceGroupForReactSelect } from 'services/utils/task-service';
import { validateDateTime, required } from 'components/form/validation/validation';
import { renderMultipleDropdown, renderDropdown } from 'components/form/field/redux-field';
import { styles } from '../task-detail-styles';

class AddRsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceGroupDropdown: -1,
    };
    this.handleServiceGroupDropdown = this.handleServiceGroupDropdown.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleServiceGroupDropdown(e) {
    this.setState({
      serviceGroupDropdown: e.target.value,
    });
  }

  handleSubmit(values) {
    const {
      fetchTaskCounts, //eslint-disable-line
      addRiskStratTask, // eslint-disable-line
      workListChanged, // eslint-disable-line
      reset,
      patient,
      cancel,
      notifyError,
    } = this.props;

    const payload = {
      taskType: RS,
      serviceGroupId: values.service_group_id.value,
      followUpDate: convertToArborDate(values.followup_dt).getUtcDatetime(),
      therapyIds: [values.therapy_ids],
      diseaseState: values.disease_state,
      patientId: patient.id,
    };

    return HTTP.get(`/tasks/openedRsTask/${patient.id}/${values.disease_state}`, {})
      .then(response => {
        if (!response.data) {
          addRiskStratTask(payload).then(() => {
            workListChanged();
            fetchTaskCounts();
            cancel();
            reset();
          });
        } else {
          notifyError('There is already an existing one.');
        }
      })
      .catch(err => {
        notifyError('There was an error when creating the task.');
      });
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      classes,
      cancel,
      lookups,
      buttonIdPrefix,
      therapies,
      cdmTherapies,
      diseaseStateData,
      initializeCdmSelectedTherapyId,
      cdmSelectedTherapyId,
    } = this.props;

    const cdmTherapiesIds = cdmTherapies.map(item => item.therapyId);
    const filteredTherapies = Object.values(therapies.data).filter(item =>
      cdmTherapiesIds.includes(item.id),
    );
    const therapiesListWithDiagnosisCode =
      TherapyUtil.therapiesListWithDiagnosisCode(filteredTherapies);
    const diseaseStateOpt = TherapyUtil.diseaseStateList(
      diseaseStateData,
      filteredTherapies.filter(t => t.id === cdmSelectedTherapyId),
    );

    const { serviceGroupDropdown } = this.state;

    return (
      <Grid container justifyContent="center" spacing={7}>
        <Grid item xs={12}>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            className={classes.addFormContainer}
            autoComplete="off"
          >
            <Grid container className={classes.formRowContainer}>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="service_group_id"
                  label="Service Group *"
                  validate={[required]}
                  component={ReactSelectForReduxSingle}
                  fields={lookups.serviceGroups.map(int => ({
                    label: int.display_name || int.name,
                    value: int.id,
                  }))}
                  state={serviceGroupDropdown}
                />
              </Grid>
              <Grid item xs={3} lx={2} className={classes.fieldContainer}>
                <Field
                  name="followup_dt"
                  label="Follow up Date *"
                  component={renderDatePicker}
                  validate={[validateDateTime, required]}
                />
              </Grid>
              <Grid item xs={6} className={classes.fieldContainer}>
                <Field
                  name="therapy_ids"
                  label="Therapies *"
                  component={renderDropdown}
                  fields={therapiesListWithDiagnosisCode}
                  validate={[required]}
                  onChange={initializeCdmSelectedTherapyId}
                />
              </Grid>
              <Grid item xs={6} className={classes.fieldContainer}>
                <Field
                  name="disease_state"
                  label="Disease State *"
                  component={renderDropdown}
                  fields={cdmSelectedTherapyId ? diseaseStateOpt : []}
                  validate={[required]}
                />
              </Grid>
            </Grid>

            {/* Eighth Row */}
            <ConfirmationPanel
              cancelButtonName="edit_therapy_cancel_button"
              submitButtonName="edit_therapy_submit_button"
              handleCancel={cancel}
              disableSubmit={pristine || submitting}
              buttonIdPrefix={buttonIdPrefix}
            />
          </form>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state, props) {
  const { lookups, therapies } = state;

  return {
    therapies,
    lookups,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    fetchTaskCounts,
    workListChanged,
    addRiskStratTask,
    notifyError,
  }),
)(reduxForm({ form: ADD_TASK_RISK_STRAT_FORM })(AddRsForm));

import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { ModalUtil } from 'services/utils/modal.util';
import { expandTheme } from 'utils/theme-util';

export const styles = (theme: Theme) => {
  const { palette, font } = expandTheme(theme);

  return createStyles({
    modal: {
      ...ModalUtil.BasicModalStyling(theme, 80, 3),
      width: theme.spacing(80),
    },
    centerButton: {
      alignSelf: 'center',
    },
    labelContainer: {
      paddingBottom: theme.spacing(1),
    },
    button: {
      backgroundColor: palette.primary.trellisBlue,
      color: palette.primary.white,
      marginBottom: theme.spacing(0.5),
      marginLeft: theme.spacing(2),
    },
    submittingIndicator: {
      marginRight: theme.spacing(1),
      color: palette.primary.white,
    },
    dividerMargin: {
      marginBottom: theme.spacing(1),
    },
    controller: {
      marginTop: theme.spacing(2),
    },
    subtitle: {
      marginTop: theme.spacing(2),
    },
    headerText: {
      fontSize: font.largeFontSize,
      fontWeight: 'bold',
    },
    subtitleText: {
      fontSize: font.smallFontSize,
      fontWeight: 'bold',
    },
  });
};

import React from 'react';
import { getUniqueSourceMedId } from 'services/utils/reconciliation-service';
import { addMedsIntoMedGroups } from 'services/utils/medication-service';

import { useTypedSelector } from 'hooks/use-typed-selector';
import { IMedicationListItem } from 'interfaces/redux/IMedications';
import ReconciliationDemographic from './reconciliation-demographic';
import ReconciliationMedication from './reconciliation-medication';
import ReconciliationAllergy from './reconciliation-allergy';

import {
  patientReconciliationTotalSelector,
  patientSelector,
  reconciliationSelector,
} from './common';

export function ReconciliationList() {
  const patient = useTypedSelector(patientSelector);
  const medications = useTypedSelector(state => state.medications);
  const {
    isLoading,
    stagingPatient,
    stagingPatientCards,
    stagingMedications,
    stagingAllergies,
  } = useTypedSelector(reconciliationSelector);
  const total = useTypedSelector(patientReconciliationTotalSelector);
  const allergiesState = useTypedSelector(state => state.allergies);
  const allergies = allergiesState ? allergiesState.allergiesList : [];
  const lookups = useTypedSelector(state => state.lookups);
  const severities = lookups && lookups.allergyLookups ? lookups.allergyLookups.severities : [];
  const medicationList = medications ? medications.medicationList : [];
  const medicationGroups = medications ? medications.medicationGroups : [];

  const currentMedicationGroups: (IMedicationListItem & {
    header?: IMedicationListItem;
  })[] = addMedsIntoMedGroups(medicationGroups, medicationList);

  const renderMedicationCards = () =>
    stagingMedications.map((newMed, index) => {
      const similarMedForGpi = medicationGroups.find(({ gpi }) => gpi === newMed.gpi);
      const matchedMedGroup = similarMedForGpi
        ? medicationGroups.find(({ ndc: medGroupNdc }) => medGroupNdc === similarMedForGpi.ndc)
        : null;

      const matchedGroup = currentMedicationGroups.find(group => newMed.ndc === group.header?.ndc);
      // First consider as existing med the one retrieved from med groups woth same NDC as the new
      // med, if that is not exists then fallback and try to find a med match between new med's GPI
      // and any med with same first 10 numbers in GPI.
      const existingMedGroup = matchedGroup ? matchedGroup.header : similarMedForGpi;
      const matchedMed = medicationGroups.find(
        m => getUniqueSourceMedId(m) === getUniqueSourceMedId(newMed),
      );
      const canBeLinked = similarMedForGpi !== null;
      return (
        <ReconciliationMedication
          newMed={newMed}
          existingMed={existingMedGroup}
          matchedMed={matchedMed || similarMedForGpi}
          matchedMedGroup={matchedMedGroup}
          index={index + 1}
          total={total}
          isLoading={isLoading}
          canBeLinked={canBeLinked}
          key={newMed.id}
        />
      );
    });

  const renderDemographicCards = () => (
    <ReconciliationDemographic
      patient={patient}
      stagingPatient={stagingPatient}
      stagingPatientCards={stagingPatientCards}
      startingIndex={stagingMedications.length + stagingAllergies.length + 1}
      isLoading={isLoading}
      total={total}
    />
  );

  const renderAllergyCards = () =>
    stagingAllergies.map((newAllergy, index) => {
      const existingAllergy = allergies.find(group => newAllergy.id === group.id);
      const severityText = severities.find((s: { id: number }) => s.id === newAllergy.severity_id)
        ? severities.find((s: { id: number }) => s.id === newAllergy.severity_id).value
        : null;
      newAllergy.severity = severityText;

      return (
        <ReconciliationAllergy
          newAllergy={newAllergy}
          matchedAllergy={existingAllergy}
          startingIndex={stagingMedications.length + 1}
          index={index}
          total={total}
          isLoading={isLoading}
          key={newAllergy.id}
        />
      );
    });
  return (
    <div>
      {total ? renderMedicationCards() : null}
      {total && stagingPatient ? renderAllergyCards() : null}
      {total && stagingPatient ? renderDemographicCards() : null}
    </div>
  );
}

// export default ReconciliationList;
export default function () {
  return <ReconciliationList />;
}

import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { compose } from 'recompose';
import { styles } from './multiple-mrns-section-styles';
import MRNTable from './mrn-table';

const MRNSection = (props: any): JSX.Element => {
  const { classes, mrnData } = props;

  return (
    <Grid container item xs={12} className={classes.mrnSectionContainer}>
      <MRNTable mrnData={mrnData} />
    </Grid>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(MRNSection);

import React, { useEffect, useState } from 'react';
import { IState } from 'interfaces/redux/IState';
import { useSelector } from 'react-redux';
import {
  IPatientMergeSectionDetailsProps,
  IProfileClinicalInfo,
} from 'components/patient-merge-modal/interfaces';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { useTheme } from '@mui/styles';
import { Divider, Grid, Typography } from '@mui/material';
import {
  ClinicalAllergySection,
  ClinicalProblemSection,
  ClinicalSpecialPopulationSection,
} from 'components/patient-merge-modal/select-form-view/sections/clinical-subsections';
import { PatientMergeClient } from 'clients/patient-merge';
import { LookupResourceTypes } from 'constants/enums';

export const ConfirmClinicalSection = (props: IPatientMergeSectionDetailsProps) => {
  const { mergeId, expanded } = props;
  const [infos, setInfos] = useState<IProfileClinicalInfo[]>([]);
  const [loaded, setLoaded] = useState(false);
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);

  const [selectedProfile, setSelectedProfile] = useState<IProfileClinicalInfo>();
  const [primaryProfile, setPrimaryProfile] = useState<IProfileClinicalInfo>();
  const [additionalInfo, setAdditionalInfo] = useState<IProfileClinicalInfo>();

  const primaryProfileId = useSelector((state: IState) => state.patientMerge.primary);
  const selectedProfileId = useSelector((state: IState) => state.patientMerge.duplicate);
  const inputs = useSelector((state: IState) => state.patientMerge.inputs);

  useEffect(() => {
    if (!loaded && expanded) {
      PatientMergeClient.getMergeClinicalInfo(mergeId).then(response => {
        setInfos(response.data);
        setLoaded(true);
      });
    }
  }, [mergeId, expanded]);

  useEffect(() => {
    const allergyIds = inputs
      .filter(input => input.type === LookupResourceTypes.Allergy)
      .map(input => input.selectedId);
    const specialPopulationIds = inputs
      .filter(input => input.type === LookupResourceTypes.PatientSpecialPopulation)
      .map(input => input.selectedId);
    const problemIds = inputs
      .filter(input => input.type === LookupResourceTypes.PatientProblem)
      .map(input => input.selectedId);

    if (selectedProfile) {
      setAdditionalInfo({
        patientId: selectedProfile.patientId,
        allergies: selectedProfile.allergies
          ?.filter(item => allergyIds.includes(item.allergyId)) || [],
        specialPopulations: selectedProfile.specialPopulations
          ?.filter(item => specialPopulationIds.includes(item.specialPopulationId)) || [],
        problems: selectedProfile.problems
          ?.filter(item => problemIds.includes(item.problemId)) || [],
      });
    }
  }, [selectedProfile]);

  useEffect(() => {
    setPrimaryProfile(
      infos.find(profile => profile.patientId === primaryProfileId),
    );
  }, [primaryProfileId, infos]);

  useEffect(() => {
    setSelectedProfile(
      infos.find(profile => profile.patientId === selectedProfileId),
    );
  }, [selectedProfileId, infos]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid paddingX={1.75}>
          <Typography sx={classes.sectionInputTitle}>Allergies</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid container item xs={12}>
          {(!!primaryProfile?.allergies?.length || !!additionalInfo?.allergies?.length) ? (
            <>
              {primaryProfile?.allergies?.map((allergy, i) => (
                <Grid
                  item
                  xs={12}
                  paddingY={1}
                  paddingX={1.75}
                  key={`primary-${allergy.allergyId}`}
                >
                  <ClinicalAllergySection {...allergy} />
                </Grid>
              ))}
              {additionalInfo?.allergies?.map((allergy, i) => (
                <Grid
                  item
                  xs={12}
                  paddingY={1}
                  paddingX={1.75}
                  key={`additional-${allergy.allergyId}`}
                  sx={classes.newItem}
                >
                  <ClinicalAllergySection {...allergy} />
                </Grid>
              ))}
            </>
          ) : (
            <Typography>-</Typography>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={classes.divider} light />
      </Grid>
      <Grid item xs={12}>
        <Grid paddingX={1.75}>
          <Typography sx={classes.sectionInputTitle}>Problem List</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid container item xs={12}>
          {(!!primaryProfile?.problems?.length || !!additionalInfo?.problems?.length) ? (
            <>
              {primaryProfile?.problems?.map((problem, i) => (
                <Grid
                  paddingY={1}
                  paddingX={1.75}
                  key={`primary-${problem.problemId}`}
                  item
                  xs={12}
                >
                  <ClinicalProblemSection {...problem} />
                </Grid>
              ))}
              {additionalInfo?.problems?.map((problem, i) => (
                <Grid
                  paddingY={1}
                  paddingX={1.75}
                  key={`additional-${problem.problemId}`}
                  sx={classes.newItem}
                  item
                  xs={12}
                >
                  <ClinicalProblemSection {...problem} />
                </Grid>
              ))}
            </>
          ) : (
            <Typography>-</Typography>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={classes.divider} light />
      </Grid>
      <Grid item xs={12}>
        <Grid paddingX={1.75}>
          <Typography sx={classes.sectionInputTitle}>Special Population</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid container item xs={12}>
          {(
              !!primaryProfile?.specialPopulations?.length
              || !!additionalInfo?.specialPopulations?.length
          ) ? (
            <>
              {primaryProfile?.specialPopulations?.map((specialPopulation, i) => (
                <Grid
                  paddingY={1}
                  paddingX={1.75}
                  key={`primary-${specialPopulation.specialPopulationId}`}
                  item
                  xs={12}
                >
                  <ClinicalSpecialPopulationSection {...specialPopulation} />
                </Grid>
              ))}
              {additionalInfo?.specialPopulations?.map((specialPopulation, i) => (
                <Grid
                  paddingY={1}
                  paddingX={1.75}
                  key={`primary-${specialPopulation.specialPopulationId}`}
                  sx={classes.newItem}
                  item
                  xs={12}
                >
                  <ClinicalSpecialPopulationSection {...specialPopulation} />
                </Grid>
              ))}
            </>
          ) : (
            <Typography>-</Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

import { assignKeys, processPhoneData } from 'services/utils/demographic-service';
import { getFilteredList } from 'services/utils/task-service';
import { FETCH_PREFERRED_CONTACT, EDIT_CONTACT, CLEAR_CONTACT } from '../constants';

const convertContactData = contact => ({
  ...contact,
  addresses: assignKeys(contact.addresses),
  emails: assignKeys(contact.emails),
  phones: assignKeys(contact.phones),
  filteredAddresses: getFilteredList(assignKeys(contact.addresses)),
  filteredEmails: getFilteredList(assignKeys(contact.emails)),
  filteredPhones: processPhoneData(getFilteredList(assignKeys(contact.phones))).sort(
    (a, b) => a?.rank - b?.rank,
  ),
});

export default (state = {}, { type, payload }) => {
  switch (type) {
    case FETCH_PREFERRED_CONTACT: {
      const { data: { contact } = {} } = payload;
      return contact ? convertContactData(contact) : null;
    }
    case EDIT_CONTACT: {
      const { updated_contact: updatedContact } = payload.data;
      if (!!state && state.id === updatedContact.id) {
        return { ...state, ...convertContactData(updatedContact) };
      }
      return state;
    }
    case CLEAR_CONTACT: {
      return null;
    }
    default:
      return state;
  }
};

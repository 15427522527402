import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ToggleDisplayAddContact } from '../../actions/action-form-displays';
import { ToggleFabPanel } from '../../actions/action-view';
import { BasicActionItem, IBasicActionItemProps } from './basic-action-item';

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onClick: () =>
      dispatch(new ToggleDisplayAddContact(true)) &&
      dispatch(new ToggleFabPanel(false)) &&
      window.scrollTo(0, 0),
  };
}

export default compose<IBasicActionItemProps, { inheritedClass: string }>(
  connect(() => ({ buttonText: '+ Contact', id: 'tfb-newContact' }), mapDispatchToProps),
)(BasicActionItem);

import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { windowFeatureIsEnabled } from 'config/window-features';
import { Grid } from '@mui/material';
import compose from 'recompose/compose';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { EDIT_INTERVENTION_FORM, INT, INTERVENTION_TYPES } from 'constants/index';
import { MedicationStatus } from 'constants/enums';
import { editIntervention, editInterventionPatient, fetchTaskCounts } from 'actions/action-tasks';
import { convertToArborDate } from 'models/time/arbor-date';
import {
  reduceStatusesWithSubstatusesToArray,
  getInitialServiceGroupForReactSelect,
  followupDateChanged,
  evaluateRecommendationForIntervention,
  addTaskOldValues,
  sortWithOtherComparer,
} from 'services/utils/task-service';
import { addMedsIntoMedGroups } from 'services/utils/medication-service';
import { workListChanged } from 'actions/action-patient';
// eslint-disable-next-line max-len
import InterventionCopyContentButton from 'components/intervention-button/intervention-copy-content-button';
import {
  interventionRecommendationIds,
  interventionReasonIds,
  severityAlertList,
} from 'constants/lists';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import {
  renderDropdown,
  renderTextField,
  renderMultipleDropdown,
  renderCheckbox,
} from 'components/form/field/redux-field';
import StaticDropdown from 'components/form/field/static-dropdown';
import { TherapyUtil } from 'utils/therapy-util';
import { ReactSelectForReduxSingle } from 'components/form/field/react-select';
import {
  validateDate,
  required,
  requiredNotWhitespace,
  validateNumber,
} from 'components/form/validation/validation';
import { InterventionStatuses } from 'constants/intervention-statuses';
import { StringUtils } from 'utils/string-utils';
import {
  DISEASE_PROGRESSION_OPTIONS,
  VISIT_TYPE_OPTIONS,
} from 'constants/intervention-hospitalization-options';
import LengthOfStay from 'components/dynamic-form/renders/render-length-of-stay';
import cx from 'classnames';
import { styles } from '../task-detail-styles';

class EditIntervention extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryDropdown: -1,
      typeDropdown: -1,
      severityDropdown: -1,
      serviceGroupDropdown: -1,
      reasonTypeDropdown: -1,
      outcomeTypeDropdown: -1,
      outcomeDropdown: -1,
      showAdditionalRecommendation:
        props.task && props.task.recommendation
          ? props.task.recommendation
              .split(',')
              .includes(String(interventionRecommendationIds.DDI_OTHER)) ||
            props.task.recommendation
              .split(',')
              .includes(String(interventionRecommendationIds.REGIMEN_OTHER)) ||
            props.task.recommendation
              .split(',')
              .includes(String(interventionRecommendationIds.LAB_OTHER))
          : false,
    };
    this.handleCategoryDropdown = this.handleCategoryDropdown.bind(this);
    this.handleTypeDropdown = this.handleTypeDropdown.bind(this);
    this.handleSeverityDropdown = this.handleSeverityDropdown.bind(this);
    this.handleServiceGroupDropdown = this.handleServiceGroupDropdown.bind(this);
    this.handleReasonTypeDropdown = this.handleReasonTypeDropdown.bind(this);
    this.handleOutcomeTypeDropdown = this.handleOutcomeTypeGroupDropdown.bind(this);
    this.handleOutcomeDropdown = this.handleOutcomeDropdown.bind(this);
    this.handleAdditionalRecommendation = this.handleAdditionalRecommendation.bind(this);
    this.taskIsComplete = this.taskIsComplete.bind(this);
    this.validateRecommendationAndOutcomeFields =
      this.validateRecommendationAndOutcomeFields.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAdmissionDateChange = this.handleAdmissionDateChange.bind(this);
    this.handleDischargedDateChange = this.handleDischargedDateChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      interventionTypeFormVal,
      change,
      initialValues,
      reasonTypeVal,
      noTherapyIdsCheckboxVal,
    } = this.props;
    if (
      prevProps.interventionTypeFormVal &&
      initialValues.type_id !== interventionTypeFormVal &&
      interventionTypeFormVal !== prevProps.interventionTypeFormVal
    ) {
      // eslint-disable-next-line
      change('reason', null);
      change('recommendation', null);
    }
    if (
      interventionTypeFormVal === initialValues.type_id &&
      interventionTypeFormVal !== prevProps.interventionTypeFormVal
    ) {
      change('reason', initialValues.reason);
      change('recommendation', initialValues.recommendation);
    }
    if (reasonTypeVal !== prevProps.reasonTypeVal && prevProps.reasonTypeVal) {
      change('additional_reason', null);
      change('reason', null);
    }
    if (noTherapyIdsCheckboxVal && noTherapyIdsCheckboxVal !== prevProps.noTherapyIdsCheckboxVal) {
      change('therapy_ids', null);
    }
  }

  taskIsComplete() {
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.task.status_id === InterventionStatuses.Completed;
  }

  /**
   * Conditionally require the outcome and recommendation fields.
   *
   * When the task is complete recommendation, outcome and outcome type fields should be required.
   */
  validateRecommendationAndOutcomeFields() {
    return this.taskIsComplete() ? [required] : undefined;
  }

  validateAdmissionDateField() {
    return this.taskIsComplete() ? [validateDate, required] : [validateDate];
  }

  validateDischargedDateField() {
    return this.taskIsComplete() ? [validateDate, required] : [validateDate];
  }

  handleCategoryDropdown(e) {
    this.setState({
      categoryDropdown: e.target.value,
    });
  }

  handleTypeDropdown(e) {
    this.setState({
      typeDropdown: e,
    });
  }

  handleSeverityDropdown(e) {
    this.setState({
      severityDropdown: e.target.value,
    });
  }

  handleServiceGroupDropdown(e) {
    this.setState({
      serviceGroupDropdown: e.target.value,
    });
  }

  handleReasonTypeDropdown(e) {
    this.setState({
      reasonTypeDropdown: e.target.value,
    });
  }

  handleOutcomeDropdown(e) {
    this.setState({
      outcomeDropdown: e.target.value,
    });
  }

  handleOutcomeTypeGroupDropdown(e) {
    this.setState({
      outcomeTypeDropdown: e.target.value,
    });
  }

  handleAdditionalRecommendation(e, val) {
    if (val) {
      const recommendationIds = String(val).split(',');
      if (
        recommendationIds.includes(String(interventionRecommendationIds.DDI_OTHER)) ||
        recommendationIds.includes(String(interventionRecommendationIds.REGIMEN_OTHER)) ||
        recommendationIds.includes(String(interventionRecommendationIds.LAB_OTHER))
      ) {
        this.setState({ showAdditionalRecommendation: true });
      } else {
        this.setState({ showAdditionalRecommendation: false });
      }
    } else {
      this.setState({ showAdditionalRecommendation: false });
    }
  }

  handleAdmissionDateChange(value) {
    this.setState({
      admissionDate: value,
    });
  }

  handleDischargedDateChange(value) {
    this.setState({
      dischargedDate: value,
    });
  }

  // Handles the submit functionality. Right now it sets some fields to null for simplicity's sake
  handleSubmit(values) {
    const {
      editIntervention, // eslint-disable-line
      task,
      cancel,
      workListChanged, //eslint-disable-line
      fetchTaskCounts, //eslint-disable-line
      editInterventionPatient, // eslint-disable-line
      interventionTypeFormVal,
      therapy,
      selectedPatientId,
    } = this.props;

    const {
      OTHER_CLINICAL: otherClinical,
      LAB: lab,
      REFERRAL_OF_SERVICE: referralOfService,
    } = INTERVENTION_TYPES;
    const payload = {
      id: task.id,
      completed_date: values.completed_date
        ? convertToArborDate(values.completed_date).getUtcDatetime()
        : null,
      canceled_dt: convertToArborDate(values.canceled_dt).getUtcDatetime(),
      followup_dt: convertToArborDate(values.followup_dt).getUtcDatetime(),
      in_progress_date: convertToArborDate(values.in_progress_date).getUtcDate(),
      last_checked_dt: values.last_checked_dt
        ? convertToArborDate(values.last_checked_dt).getUtcDatetime()
        : null,
      therapy_id: task && task.therapy_id ? task.therapy_id : null,
      service_group_id: values.service_group_id ? values.service_group_id.value : null,
      category_id: values.category_id ? values.category_id : null,
      type_id: values.type_id ? values.type_id : null,
      outcome: values.outcome ? values.outcome : null,
      disease_progression: values?.disease_progression, // eslint-disable-line
      visit_type: values?.visit_type, // eslint-disable-line
      admission_date: values.admission_date
        ? convertToArborDate(values.admission_date).getUtcDate()
        : null,
      discharged_date: values.discharged_date
        ? convertToArborDate(values.discharged_date).getUtcDate()
        : null,
      additional_outcome:
        values.additional_outcome && values.outcome === 1003 ? values.additional_outcome : null,
      reason: values.reason,
      additional_reason: values.additional_reason ? values.additional_reason : null,
      reason_start_date: values.reason_start_date
        ? convertToArborDate(values.reason_start_date, true).getUtcDate()
        : null,
      reason_end_date: values.reason_end_date
        ? convertToArborDate(values.reason_end_date, true).getUtcDate()
        : null,
      recommendation: evaluateRecommendationForIntervention(interventionTypeFormVal)
        ? evaluateRecommendationForIntervention(interventionTypeFormVal)
        : values.recommendation,
      additional_recommendation:
        values.additional_recommendation &&
        (values.recommendation === 5001 ||
          interventionTypeFormVal === otherClinical ||
          interventionTypeFormVal === lab ||
          interventionTypeFormVal === referralOfService ||
          (values.recommendation &&
            (values.recommendation
              .split(',')
              .includes(String(interventionRecommendationIds.DDI_OTHER)) ||
              values.recommendation
                .split(',')
                .includes(String(interventionRecommendationIds.REGIMEN_OTHER)) ||
              values.recommendation
                .split(',')
                .includes(String(interventionRecommendationIds.LAB_OTHER)))) ||
          evaluateRecommendationForIntervention(interventionTypeFormVal) === 6001)
          ? values.additional_recommendation
          : null,
      severity_id: values.severity_id ? values.severity_id : null,
      serious_adverse_event:
        typeof values.serious_adverse_event === 'boolean' ? values.serious_adverse_event : null,
      patient_id: selectedPatientId,
      is_patient: therapy && therapy.id ? 0 : 1,
      drug_interaction_description: values.drug_interaction_description,
      interacting_drugs: values.interacting_drugs,
      fill_cycle_number: values.fill_cycle_number,
      therapy_ids: values.therapy_ids || null,
      details: values.details,
    };
    if (payload.is_patient === 1) {
      return editInterventionPatient(payload).then(() => {
        if (followupDateChanged(task.followup_dt, payload.followup_dt)) {
          workListChanged();
          fetchTaskCounts();
        }
        cancel();
      });
    }
    return editIntervention(addTaskOldValues(payload, task)).then(() => {
      if (followupDateChanged(task.followup_dt, payload.followup_dt)) {
        workListChanged();
        fetchTaskCounts();
      }
      cancel();
    });
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      classes,
      cancel,
      taskStatuses,
      lookups,
      interventionTypeFormVal,
      detailsVal,
      outcomeVal,
      outcomeTypeVal,
      reasonTypeVal,
      fillCycles,
      therapy,
      reasonVal,
      task,
      medications,
      auth,
      therapies,
      noTherapyIdsCheckboxVal,
      formValues,
    } = this.props;

    const recommendationArr = taskStatuses.intervention_recommendations
      .filter(rec => rec.type_id === interventionTypeFormVal)
      .sort(sortWithOtherComparer('recommendation_name'));
    const reasonArr = taskStatuses.intervention_reasons
      .filter(rec => rec.type_id === interventionTypeFormVal)
      .sort(sortWithOtherComparer('reason_name'));
    const outcomeArr = taskStatuses.intervention_outcomes.filter(
      rec => rec.type_id === interventionTypeFormVal,
    );
    const reasonTypeArr = reduceStatusesWithSubstatusesToArray(reasonArr, 'reason_subtype');
    const outcomeTypeArr = reduceStatusesWithSubstatusesToArray(outcomeArr, 'outcome_subtype');

    const {
      ADHERENCE: adherence,
      CARE_PLAN: carePlan,
      DDI,
      DUR,
      ADR,
      REGIMEN: regimen,
      REFERRAL_OF_SERVICE: referralOfService,
      LINKAGE_TO_CARE: linkageToCare,
      LAB: lab,
      OTHER_CLINICAL: otherClinical,
      VACCINE: vaccine,
      HOSPITALIZATION: hospitalization,
    } = INTERVENTION_TYPES;

    const interventionType = taskStatuses.intervention_types.find(
      type => type.id === interventionTypeFormVal,
    );

    const { medicationList, medicationGroups: overwriteGroups } = medications;
    const fullMedicationGroups = addMedsIntoMedGroups(overwriteGroups, medicationList);
    const medicationGroups = [];
    fullMedicationGroups.forEach(group => {
      if (group.header.active && group.header.status_code === MedicationStatus.Active) {
        medicationGroups.push({
          label: group.header.medication,
          value: group.header.medication,
        });
      }
    });

    const therapiesListWithDosage = therapies?.data
      ? TherapyUtil.therapiesListWithDosage(Object.values(therapies.data))
      : [];
    const {
      categoryDropdown,
      typeDropdown,
      severityDropdown,
      serviceGroupDropdown,
      reasonTypeDropdown,
      outcomeTypeDropdown,
      outcomeDropdown,
      showAdditionalRecommendation,
      admissionDate,
      dischargedDate,
    } = this.state;
    const { therapy_id: therapyId } = task;

    const taskFieldTransitions =
      // eslint-disable-next-line camelcase
      auth?.currentUser?.permissions?.tasks_field[INT.toLowerCase()] || [];
    // eslint-disable-next-line camelcase
    const fieldPermissions = taskFieldTransitions?.field_transitions || null;

    const showLinkedTherapiesField =
      [adherence, otherClinical, hospitalization].includes(interventionTypeFormVal) ||
      interventionTypeFormVal === INTERVENTION_TYPES.INFECTION_CONTROL;

    return (
      <Grid
        container
        justifyContent="flex-start"
        className={(classes.formRowContainer, classes.formContainer)}
      >
        <Grid item xs={12}>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            className={classes.editFormContainer}
            autoComplete="off"
          >
            <Grid container className={classes.formRowContainer}>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="followup_dt"
                  label="Follow up Date *"
                  component={renderDatePicker}
                  validate={[validateDate, required]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="last_checked_dt"
                  label="Last Checked Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="service_group_id"
                  label="Service Group *"
                  validate={[required]}
                  component={ReactSelectForReduxSingle}
                  fields={lookups.serviceGroups.map(int => ({
                    label: int.display_name,
                    value: int.id,
                  }))}
                  state={serviceGroupDropdown}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="in_progress_date"
                  label="In Progress Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="completed_date"
                  label="Completed Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="canceled_dt"
                  label="Canceled Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="category_id"
                  label="Category *"
                  validate={[required]}
                  component={renderDropdown}
                  fields={taskStatuses.intervention_categories.map(int => ({
                    label: int.category_name,
                    value: int.id,
                  }))}
                  state={categoryDropdown}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="severity_id"
                  label="Severity *"
                  validate={[required]}
                  component={renderDropdown}
                  fields={taskStatuses.intervention_severities.map(int => ({
                    label: int.severity_name,
                    value: int.id,
                  }))}
                  disabled={
                    // eslint-disable-next-line camelcase
                    fieldPermissions?.severity_id &&
                    !fieldPermissions.severity_id.some(p => p.canEdit)
                  }
                  state={severityDropdown}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="type_id"
                  label="Type"
                  validate={[required]}
                  component={renderDropdown}
                  fields={taskStatuses.intervention_types.map(int => ({
                    label: int.type_name,
                    value: int.id,
                  }))}
                  state={typeDropdown}
                />
              </Grid>
              {formValues?.severity_id > 0 && (
                <Grid
                  item
                  xs={12}
                  className={cx(classes.severityAlert, {
                    [classes.severityLow]: formValues?.severity_id === 1,
                    [classes.severityMedium]: formValues?.severity_id === 2,
                    [classes.severityHigh]: formValues?.severity_id === 3,
                  })}
                >
                  {severityAlertList[formValues?.severity_id]}
                </Grid>
              )}
              {!!therapy && (
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name="fill_cycle_number"
                    component={renderDropdown}
                    fields={
                      fillCycles[therapyId] &&
                      Object.keys(fillCycles[therapyId])
                        .map(k => fillCycles[therapyId][k])
                        .map(cycle => ({
                          label: `#${cycle.fill_cycle} NBD: ${convertToArborDate(
                            cycle.needsby_date,
                            true,
                          ).getUtcDate(true)}`,
                          value: cycle.fill_cycle,
                        }))
                    }
                    label="Fill cycle *"
                    validate={[required, validateNumber]}
                  />
                </Grid>
              )}
              {interventionTypeFormVal === hospitalization && (
                <>
                  <Grid container>
                    <Grid item xs={4} className={classes.fieldContainer}>
                      <Field
                        name="disease_progression"
                        label="Disease Progression/Exacerbation *"
                        fields={DISEASE_PROGRESSION_OPTIONS}
                        validate={[required]}
                        component={renderDropdown}
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.fieldContainer}>
                      <Field
                        name="visit_type"
                        label="Type of visit *"
                        fields={VISIT_TYPE_OPTIONS}
                        validate={[required]}
                        component={renderDropdown}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="admission_date"
                        label={`Admin date ${this.taskIsComplete() ? '*' : ''}`}
                        component={renderDatePicker}
                        validate={this.validateAdmissionDateField()}
                        onChange={this.handleAdmissionDateChange}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="discharged_date"
                        label={`Discharge date ${this.taskIsComplete() ? '*' : ''}`}
                        component={renderDatePicker}
                        validate={this.validateDischargedDateField()}
                        onChange={this.handleDischargedDateChange}
                      />
                    </Grid>
                    <LengthOfStay
                      xs={3}
                      admissionDate={admissionDate}
                      dischargedDate={dischargedDate}
                    />
                  </Grid>
                </>
              )}
              {showLinkedTherapiesField && (
                <>
                  <Grid item xs={4} className={classes.fieldContainer}>
                    {!noTherapyIdsCheckboxVal ? (
                      <Field
                        name="therapy_ids"
                        label="Therapies"
                        component={renderMultipleDropdown}
                        fields={therapiesListWithDosage}
                      />
                    ) : (
                      <StaticDropdown
                        fieldName="Therapies"
                        field="No Associated Therapies"
                        variant="raw"
                      />
                    )}
                  </Grid>
                  <Grid item xs={2} className={classes.fieldContainer}>
                    <Field
                      name="no_therapy_ids_checkbox"
                      label="No Associated Therapies"
                      component={renderCheckbox}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Grid container>
                  {reasonTypeArr.length > 0 && (
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="reason_type"
                        label="Reason Type *"
                        component={renderDropdown}
                        fields={reasonTypeArr}
                        state={reasonTypeDropdown}
                        validate={[required]}
                      />
                    </Grid>
                  )}
                  {reasonTypeArr.length === 0 &&
                    (interventionTypeFormVal === ADR ||
                      interventionTypeFormVal === referralOfService ||
                      interventionTypeFormVal === lab ||
                      interventionTypeFormVal === linkageToCare ||
                      interventionTypeFormVal === regimen) && (
                      <Grid item xs={3} className={classes.fieldContainer}>
                        <Field
                          name="reason"
                          label="Reason *"
                          multiple
                          component={
                            interventionTypeFormVal === ADR ||
                            interventionTypeFormVal === linkageToCare ||
                            interventionTypeFormVal === regimen
                              ? renderDropdown
                              : renderMultipleDropdown
                          }
                          fields={reasonArr.map(int => ({
                            label: int.reason_name,
                            value: int.id,
                          }))}
                          validate={[required]}
                        />
                      </Grid>
                    )}
                  {reasonTypeArr.length > 0 && reasonTypeVal && (
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="reason"
                        label="Reason *"
                        multiple
                        component={
                          interventionTypeFormVal === otherClinical ||
                          interventionTypeFormVal === linkageToCare
                            ? renderDropdown
                            : renderMultipleDropdown
                        }
                        validate={[required]}
                        fields={reasonArr
                          .filter(r => reasonTypeVal === r.reason_subtype && r.reason_name)
                          .map(int => ({
                            label: int.reason_name,
                            value: int.id,
                            key: int.id,
                          }))}
                      />
                    </Grid>
                  )}

                  {interventionTypeFormVal === adherence &&
                    reasonVal &&
                    (reasonVal
                      .split(',')
                      .includes(String(interventionReasonIds.INTENTIONAL_OTHER)) ||
                      reasonVal
                        .split(',')
                        .includes(String(interventionReasonIds.UNINTENTIONAL_OTHER))) && (
                      // eslint-disable-next-line react/jsx-indent
                      <Grid item xs={4} className={classes.fieldContainer}>
                        <Field
                          name="additional_reason"
                          label="Reason"
                          validate={[required]}
                          component={renderTextField}
                        />
                      </Grid>
                    )}

                  {interventionTypeFormVal === adherence &&
                    reasonVal &&
                    (reasonVal
                      .split(',')
                      .includes(String(interventionReasonIds.INTENTIONAL_PRESCRIBER_HELD)) ||
                      reasonVal
                        .split(',')
                        .includes(String(interventionReasonIds.INTENTIONAL_ILLNESS)) ||
                      reasonVal
                        .split(',')
                        .includes(
                          String(interventionReasonIds.INTENTIONAL_PLANNED_MEDICAL_PROCEDURE),
                        ) ||
                      reasonVal
                        .split(',')
                        .includes(String(interventionReasonIds.INTENTIONAL_DOSE_REDUCTION))) && (
                      // eslint-disable-next-line react/jsx-indent
                      <>
                        <Grid item xs={4} className={classes.fieldContainer}>
                          <Field
                            name="reason_start_date"
                            label="Start Date"
                            component={renderDatePicker}
                            validate={[validateDate]}
                          />
                        </Grid>
                        <Grid item xs={4} className={classes.fieldContainer}>
                          <Field
                            name="reason_end_date"
                            label="End Date"
                            component={renderDatePicker}
                            validate={[validateDate]}
                          />
                        </Grid>
                      </>
                    )}
                  {interventionTypeFormVal === carePlan && (
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="additional_reason"
                        label="Problems/Determination of Needs of the Patient"
                        component={renderTextField}
                      />
                    </Grid>
                  )}
                  {(interventionTypeFormVal === DDI || interventionTypeFormVal === DUR) && (
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="interacting_drugs"
                        label="Interacting Drugs"
                        component={renderMultipleDropdown}
                        plainTextValue
                        maxOptions={2}
                        fields={medicationGroups}
                      />
                    </Grid>
                  )}
                  {(interventionTypeFormVal === DDI || interventionTypeFormVal === DUR) && (
                    <Grid item xs={6} className={classes.fieldContainer}>
                      <Field
                        name="drug_interaction_description"
                        label="Drug Interaction Description"
                        multiline
                        component={renderTextField}
                      />
                    </Grid>
                  )}
                  {interventionTypeFormVal === otherClinical && (
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="additional_reason"
                        label="Description of Reason"
                        component={renderTextField}
                      />
                    </Grid>
                  )}
                  {interventionTypeFormVal !== referralOfService &&
                    interventionTypeFormVal !== otherClinical &&
                    interventionTypeFormVal !== hospitalization && (
                      <Grid item xs={3} className={classes.fieldContainer}>
                        <Field
                          name="recommendation"
                          label={StringUtils.formatRequired(
                            'Recommendation',
                            this.taskIsComplete(),
                          )}
                          multiple
                          component={
                            interventionTypeFormVal === lab ||
                            interventionTypeFormVal === linkageToCare ||
                            interventionTypeFormVal === regimen ||
                            interventionTypeFormVal === vaccine
                              ? renderDropdown
                              : renderMultipleDropdown
                          }
                          fields={recommendationArr.map(int => ({
                            label: int.recommendation_name,
                            value: int.id,
                          }))}
                          onChange={this.handleAdditionalRecommendation}
                          validate={this.validateRecommendationAndOutcomeFields()}
                        />
                      </Grid>
                    )}
                  {(interventionTypeFormVal === referralOfService ||
                    interventionTypeFormVal === otherClinical ||
                    showAdditionalRecommendation) && (
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="additional_recommendation"
                        label="Recommendation"
                        component={renderTextField}
                      />
                    </Grid>
                  )}
                  {outcomeTypeArr.length > 0 && (
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="outcome_type"
                        label={StringUtils.formatRequired('Outcome Type', this.taskIsComplete())}
                        component={renderDropdown}
                        fields={outcomeTypeArr}
                        state={outcomeTypeDropdown}
                        validate={this.validateRecommendationAndOutcomeFields()}
                      />
                    </Grid>
                  )}
                  {outcomeTypeArr.length > 0 && outcomeTypeVal && (
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="outcome"
                        label={StringUtils.formatRequired('Outcome', this.taskIsComplete())}
                        component={renderDropdown}
                        fields={outcomeArr
                          .filter(r => outcomeTypeVal === r.outcome_subtype)
                          .map(int => ({
                            label: int.outcome_name,
                            value: int.id,
                          }))}
                        state={outcomeDropdown}
                        validate={this.validateRecommendationAndOutcomeFields()}
                      />
                    </Grid>
                  )}
                  {outcomeTypeArr.length === 0 &&
                    interventionTypeFormVal !== adherence &&
                    interventionTypeFormVal !== hospitalization && (
                      <Grid item xs={3} className={classes.fieldContainer}>
                        <Field
                          name="outcome"
                          label={StringUtils.formatRequired('Outcome', this.taskIsComplete())}
                          component={
                            interventionTypeFormVal === lab
                              ? renderMultipleDropdown
                              : renderDropdown
                          }
                          fields={outcomeArr.map(int => ({
                            label: int.outcome_name,
                            value: int.id,
                          }))}
                          state={outcomeDropdown}
                          validate={this.taskIsComplete() ? [requiredNotWhitespace] : undefined}
                        />
                      </Grid>
                    )}
                  {interventionTypeFormVal === ADR && (
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="serious_adverse_event"
                        label="Serious/Unexpected Adverse Event from edit"
                        component={renderCheckbox}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  {outcomeTypeArr.length === 0 && interventionTypeFormVal === adherence && (
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="outcome"
                        label={StringUtils.formatRequired('Outcome', this.taskIsComplete())}
                        component={
                          interventionTypeFormVal !== adherence
                            ? renderDropdown
                            : renderMultipleDropdown
                        }
                        fields={outcomeArr.map(int => ({
                          label: int.outcome_name,
                          value: int.id,
                        }))}
                        state={outcomeDropdown}
                        validate={this.validateRecommendationAndOutcomeFields()}
                      />
                    </Grid>
                  )}
                  {outcomeVal === 1003 && (
                    <Grid item xs={3} className={classes.fieldContainer}>
                      <Field
                        name="additional_outcome"
                        label={StringUtils.formatRequired('Outcome', this.taskIsComplete())}
                        component={renderTextField}
                        validate={this.validateRecommendationAndOutcomeFields()}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={9} className={classes.fieldContainer}>
              <Field
                name="details"
                label="Details"
                component={renderTextField}
                multiline
                rows={3}
              />
            </Grid>

            {/* Eighth Row */}
            <ConfirmationPanel
              cancelButtonName="edit_therapy_cancel_button"
              submitButtonName="edit_therapy_submit_button"
              handleCancel={cancel}
              disableSubmit={pristine || submitting}
              leftAlignedButtons={[
                {
                  button: (
                    <InterventionCopyContentButton
                      details={detailsVal}
                      interventionType={interventionType ? interventionType.type_name : null}
                    />
                  ),
                },
              ]}
            />
          </form>
        </Grid>
      </Grid>
    );
  }
}

function handleFormName(task) {
  if (!task) {
    return null;
  }
  const name = `${EDIT_INTERVENTION_FORM}_${task.therapy_id}_${task.id}`;
  return name;
}

function mapStateToProps(state, props) {
  const { taskStatuses, selectedPatientId, lookups, fillCycles, medications, auth, therapies } =
    state;
  const name = handleFormName(props.task);
  const selector = formValueSelector(name);
  const interventionTypeFormVal = selector(state, 'type_id');
  const detailsVal = selector(state, 'details');
  const outcomeVal = selector(state, 'outcome');
  const outcomeTypeVal = selector(state, 'outcome_type');
  const recommendationVal = selector(state, 'recommendation');
  const reasonTypeVal = selector(state, 'reason_type');
  const reasonVal = selector(state, 'reason');
  const noTherapyIdsCheckboxVal = selector(state, 'no_therapy_ids_checkbox');
  const formValues = getFormValues(name)(state);

  return {
    taskStatuses,
    selectedPatientId,
    lookups,
    interventionTypeFormVal,
    detailsVal,
    outcomeVal,
    reasonTypeVal,
    recommendationVal,
    outcomeTypeVal,
    reasonVal,
    form: name,
    fillCycles,
    medications,
    auth,
    therapies,
    noTherapyIdsCheckboxVal,
    formValues,
    initialValues: {
      completed_date: convertToArborDate(props.task.completed_date, true).getCustomerDate(true),
      followup_dt: convertToArborDate(props.task.followup_dt, true).getCustomerDate(true),
      canceled_dt: convertToArborDate(props.task.canceled_dt, true).getCustomerDate(true),
      last_checked_dt: convertToArborDate(props.task.last_checked_dt, true).getCustomerDate(true),
      disease_progression: props.task.disease_progression,
      visit_type: props.task.visit_type,
      admission_date: convertToArborDate(props.task.admission_date, true).getUtcDate(true),
      discharged_date: convertToArborDate(props.task.discharged_date, true).getUtcDate(true),
      status_id: props.task.status_id,
      service_group_id: props.task.service_group_id
        ? getInitialServiceGroupForReactSelect(props.task.service_group_id, props.serviceGroups)
        : '',
      category_id: props.task.category_id ? props.task.category_id : '',
      type_id: props.task.type_id ? props.task.type_id : '',
      severity_id: props.task.severity_id ? props.task.severity_id : '',
      reason: props.task.reason ? props.task.reason : null,
      additional_reason: props.task.additional_reason ? props.task.additional_reason : null,
      reason_start_date: props.task.reason_start_date
        ? convertToArborDate(props.task.reason_start_date, true).getUtcDate(true)
        : null,
      reason_end_date: props.task.reason_end_date
        ? convertToArborDate(props.task.reason_end_date, true).getUtcDate(true)
        : null,
      recommendation: props.task.recommendation ? props.task.recommendation : null,
      additional_recommendation: props.task.additional_recommendation
        ? props.task.additional_recommendation
        : null,
      outcome: props.task.outcome ? props.task.outcome : null,
      additional_outcome: props.task.additional_outcome ? props.task.additional_outcome : '',
      reason_type: props.task.reason
        ? taskStatuses.intervention_reasons.find(r => r.id === parseInt(props.task.reason, 10))
            .reason_subtype
        : null,
      outcome_type:
        props.task.outcome &&
        taskStatuses.intervention_outcomes &&
        taskStatuses.intervention_outcomes.find(r => r.id === props.task.outcome)
          ? taskStatuses.intervention_outcomes.find(r => r.id === props.task.outcome)
              .outcome_subtype
          : null,
      interacting_drugs: props.task.interacting_drugs ? props.task.interacting_drugs : null,
      drug_interaction_description: props.task.drug_interaction_description
        ? props.task.drug_interaction_description
        : null,
      fill_cycle_number: props.task.fill_cycle_number || null,
      in_progress_date: convertToArborDate(props.task.in_progress_date, true).getUtcDate(true),
      details: props.task.details,
      therapy_id: props.task.therapy_id || null,
      therapy_ids: props.task.therapy_ids ? props.task.therapy_ids : null,
      serious_adverse_event: props.task.serious_adverse_event,
    },
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    workListChanged,
    fetchTaskCounts,
    editInterventionPatient,
    editIntervention,
  }),
)(reduxForm({})(EditIntervention));

/* eslint-disable no-useless-escape */
export const statusFormatter = (value: unknown): string => {
  if (value) {
    return 'Active';
  }
  return 'Deprecated';
};

export const buildIdFromDataItem = (item: { unit: string; name: string }): string => {
  const unit = item.unit ? `-${item.unit.replace(/[\(\)\s]/, '-')}` : ''; // will replace spaces and parentheses with dashes
  const unitRegex = new RegExp(`-(${item.unit})?$`, 'i'); // will search for a lonly dash followed by an optional unit
  const id = item.name
    .replace(/[^\s\w-\/]/g, '') // remove everything apart from spaces words and slashes
    .replace(/[\s\/]/g, '-') // convert spaces and slashes into dashes
    .toLowerCase() // everything to lower case
    .replace(unitRegex, unit); // replace the lower case unit to a dash separated value
  return id;
};

export const numberParser = (e: any, nDecimals = 0): void => {
  const onlyNumbers = '0123456789';
  const dot = '.';
  if (nDecimals === 0) {
    if (!onlyNumbers.includes(e.key)) {
      e.target.value = e.target.value.replace(e.key, '');
    }
  } else if (nDecimals > 0) {
    if (onlyNumbers.includes(e.key) || e.key === dot) {
      e.target.value = e.target.value.replace(/(^[\.\s])|(\.\.$)/g, '').replace(/\.+/g, '.');
      const decimalPoint = e.target.value.indexOf('.');
      if (decimalPoint !== -1) e.target.value = e.target.value.substring(0, decimalPoint + 1 + nDecimals);
    } else e.target.value = '';
  } else if (nDecimals === -1) {
    if (onlyNumbers.includes(e.key) || e.key === dot) {
      e.target.value = e.target.value.replace(/(^[\.\s])|(\.\.$)/g, '').replace(/\.+/g, '.');
    } else e.target.value = '';
  }
};

export const payorStatusFormatter = (value: unknown): string => {
  if (value && value === 1) {
    return 'Locked';
  }
  return 'Unlocked';
};

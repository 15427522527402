import { useSelector } from 'react-redux';
import { IState } from 'interfaces/redux/IState';
import { ITherapy } from 'interfaces/redux/ITherapy';
import { ICounselingTask } from 'interfaces/redux/task-types/ICounselingTask';

const useTasksTherapies = (tasks: ICounselingTask[]): Record<number, ITherapy> => {
  const therapies = useSelector((state: IState) => state.therapies.data);
  const taskTherapyIds = tasks.map(task => task.therapy_id);

  return taskTherapyIds.reduce((taskTherapies: Record<number, ITherapy>, taskTherapyId: number) => {
    if (therapies[taskTherapyId]) {
      taskTherapies[taskTherapyId] = therapies[taskTherapyId];
    }
    return taskTherapies;
  }, {});
};

export { useTasksTherapies };

import React, { useState, useLayoutEffect } from 'react';
import { useParams } from 'react-router';
import { Box, Typography } from '@mui/material';
import { getSmsTermsData } from 'services/utils/communication-service';

import { POWERED_BY } from 'constants/index';
import Container from '../../questionnaire/components/container';
import LoadingPage from '../../questionnaire/pages/loading-page';
import useStyles from '../styles';

export default () => {
  const { poweredByFooter, textBox } = useStyles();
  const [smsTermsData, setSmsTermsData] = useState();
  const { smsTermsLink } = useParams();

  useLayoutEffect(() => {
    getSmsTermsData(smsTermsLink).then(response => {
      const { data } = response;
      setSmsTermsData(data);
    });
  }, []);

  return (
    <>
      {smsTermsData ? (
        <Container title="Specialty Pharmacy SMS Terms of Service">
          <Box pt={5}>
            <Box className={textBox} pl={20} pr={20}>
              <Typography paragraph align="justify" variant="subtitle" gutterBottom>
                <strong>{`${smsTermsData.clinicName || '[SPECIALTY_PHARMACY]'} `}</strong>
                <span>
                  offers access to specialty pharmacy service messages via SMS (Short Message
                  Service) text alerts (“Text Alerts”). By enrolling in the Text Alerts, you agree
                  the following Terms of Service, which become effective upon your enrollment. If
                  you do not agree to these Terms of Service, please do not participate in the Text
                  Alerts.
                </span>
              </Typography>
            </Box>
            <Box className={textBox} pl={20} pr={20}>
              <Typography paragraph align="justify" variant="subtitle" gutterBottom>
                By providing your mobile phone number to us and confirming your agreement to receive
                text messages, you authorize us to send you text messages by automatic telephone
                dialing system. Your participation in the Text Alerts program is not required and
                you may opt-out of receiving Text Alerts at any time by following the instructions
                below.
              </Typography>
            </Box>
            <Box className={textBox} pl={20} pr={20}>
              <Typography paragraph align="justify" variant="subtitle" gutterBottom>
                The messages we will send via the Text Alerts include, but are not limited to,
                appointment reminders, refill reminders, and other notifications related to the
                specialty pharmacy services you are receiving. These messages may include protected
                health information. The frequency of messages we send to you will vary by your
                prescription activity and the specialty pharmacy services you are receiving.
              </Typography>
            </Box>
            <Box className={textBox} pl={20} pr={20}>
              <Typography paragraph align="justify" variant="h6">
                How to Opt Out
              </Typography>
              <Typography paragraph align="justify" variant="subtitle" gutterBottom>
                <span>
                  You can opt out of receiving Text Alerts at any time by texting STOP to the short
                  code. You will then receive confirmation of your opt-out of the Text Alerts. No
                  additional Text Alerts will be sent to you unless you re-subscribe to the Text
                  Alerts service. To re-subscribe, please contact
                </span>
                <span>
                  <strong>{` ${smsTermsData.supportNumber || '[555.555.555]'}`}</strong>
                </span>
              </Typography>
            </Box>
            <Box className={textBox} pl={20} pr={20}>
              <Typography paragraph align="justify" variant="subtitle" gutterBottom>
                <span>
                  If you are experiencing issues with the Text Alerts service you can reply
                </span>
                <strong>{' HELP '}</strong>
                <span>
                  to any text message you receive from us for more assistance or call us directly at
                  800-503-5420.
                </span>
              </Typography>
            </Box>
            <Box className={textBox} pl={20} pr={20}>
              <Typography paragraph align="justify" variant="h6">
                Messaging and Data Rates May Apply
              </Typography>
              <Typography paragraph align="justify" variant="subtitle" gutterBottom>
                There is no charge from us for this service, but message and data rates may apply
                depending on the terms and conditions of your wireless plan. You are solely
                responsible for all message and data charges that you incur. Please contact your
                wireless service provider about such charges.
              </Typography>
            </Box>
            <Box className={textBox} pl={20} pr={20}>
              <Typography paragraph align="justify" variant="subtitle" gutterBottom>
                <span>
                  {`The Text Alerts are provided on an "as is" basis and may not be available in all
                areas at all times. We reserve the right to terminate this program, in whole or in
                part, at any time without notice. We cannot guarantee that text messages provided
                through your wireless service provider will be delivered to you. You acknowledge
                that`}
                </span>
                <span>
                  <strong>{` ${smsTermsData.clinicName || '[SPECIALTY_PHARMACY]'} `}</strong>
                </span>
                <span>
                  and your wireless service provider are not liable to you for any delayed or
                  undelivered text messages.
                </span>
              </Typography>
            </Box>
            <Box className={textBox} pl={20} pr={20}>
              <Typography paragraph align="justify" variant="h6">
                Privacy
              </Typography>
              <Typography paragraph align="justify" variant="subtitle" gutterBottom>
                You acknowledge that our text messages are sent to you without being encrypted and
                whoever has access to your mobile phone or wireless account will also be able to see
                this information. If you do not choose to accept these risks, please follow the
                instructions above to opt out of receiving the Text Alerts.
              </Typography>
            </Box>
            <Box className={textBox} pl={20} pr={20}>
              <Typography paragraph align="justify" variant="subtitle" gutterBottom>
                Your participation in the Text Alerts program is at your sole risk. If you are
                dissatisfied with the program, its content or with these Terms of Service, your sole
                and exclusive remedy is to opt out of the Text Alerts.
              </Typography>
            </Box>
            <Box className={poweredByFooter}>{POWERED_BY}</Box>
          </Box>
        </Container>
      ) : (
        <LoadingPage />
      )}
    </>
  );
};

import colors from '../../../lib/themes/colors.module.scss';

const { grey23 } = colors;

export const styles = theme => ({
  fieldLabelPointer: {
    cursor: 'pointer',
  },
  centerInputValue: {
    '& input': {
      textAlign: 'center',
    },
  },
  fieldLabel: {
    fontSize: theme.font.formLabelSize,
    fontWeight: theme.font.weight.regular,
    color: theme.palette.primary.formLabel,
    minHeight: theme.layout.taskFormLabelMinHeight + 3,
  },
  fieldLabelMedium: {
    fontSize: theme.font.mediumFontSize,
    fontWeight: theme.font.weight.regular,
    color: theme.palette.primary.formLabel,
    minHeight: theme.layout.taskFormLabelMinHeight + 3,
  },
  fieldLabelLarge: {
    fontSize: theme.font.largeFontSize,
    fontWeight: theme.font.weight.thick,
    color: theme.palette.text.primary,
    minHeight: theme.layout.taskFormLabelMinHeight + 3,
  },
  switchLabel: {
    lineHeight: 2.5,
  },
  smallerFieldLabel: {
    display: 'block',
    color: theme.palette.primary.formLabel,
    minHeight: theme.layout.taskFormLabelMinHeight,
  },
  horizontalCheckbox: {
    paddingTop: 0,
  },
  radioGroupMoreButton: {
    marginLeft: 12,
  },
  selectedIntervention: {
    borderRight: `5px solid ${theme.palette.primary.patina}`,
    borderBottom: `1px solid ${theme.palette.primary.task.borderColor}`,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  checkBoxInline: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  checkBoxInlineLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldValue: {
    fontSize: theme.font.formItemSize,
    color: theme.palette.primary.formItem,
  },
  inputAdornment: {
    whiteSpace: 'nowrap',
    marginRight: 0,
  },
  searchAdornment: {
    whiteSpace: 'nowrap',
    marginRight: 5,
  },
  dropdown: {
    position: 'absolute',
    zIndex: 1,
    maxHeight: 500,
    minWidth: 200,
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  minigridRow: {
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
    },
    'minWidth': 0,
    'height': 10,
  },
  minigridRowKeyboard: {
    backgroundColor: theme.palette.primary.hover,
    minWidth: 0,
    height: 10,
  },
  minigridCols: {
    padding: theme.spacing(1),
    minWidth: 125,
    height: 0,
  },
  minigridTable: {
    maxHeight: 500,
  },
  cell: {
    padding: theme.spacing(1),
  },
  autocompleteLabelText: {
    paddingTop: 5,
  },
  listItem: {
    marginLeft: -theme.spacing(1),
  },
  autocompleteLabel: {
    paddingLeft: theme.spacing(1),
  },
  searchPlaceholder: {
    '&::placeholder': {
      fontSize: theme.font.extraSmallFontSize,
    },
  },
  minigridTableHeader: {
    height: 0,
    padding: 0,
  },
  autocompleteLabelTextInterior: {
    '&.Mui-disabled': {
      color: theme.palette.primary.formLabel,
    },
  },
  largeLabel: {
    height: theme.layout.taskFormLabelMinHeight + 3,
  },
  disabled: {},
  retryIcon: {
    marginLeft: 10,
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
  },
  detailHeader: {
    color: theme.palette.primary.formLabel,
  },
  radioLabelPadding: {
    paddingLeft: 13,
    color: theme.palette.primary.formLabel,
  },
  formTitleWithWrapper: {
    display: 'inline-block',
    margin: '10px 5px 10px 5px',
  },
  formTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.grey2,
    padding: 5,
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(0.5),
  },
  radioGroup: {
    display: 'flex',
  },
  radioGroupInline: {
    display: 'inline',
    paddingLeft: 15,
  },
  radioGroupInlineWrapper: {
    backgroundColor: theme.palette.primary.grey2,
    padding: '12px 0px 12px 8px',
  },
  formControlListView: {
    flexDirection: 'column',
  },
  innerFormContainer: {
    border: `1px solid ${grey23}`,
    padding: 10,
  },
  innerFormsItemContainer: {
    border: `1px solid ${grey23}`,
    padding: 10,
    marginBottom: 10,
  },
  noteDetail: {
    backgroundColor: theme.palette.primary.grey2,
    padding: 10,
    fontSize: theme.font.largeFontSize,
    marginLeft: 5,
  },
  asyncDetail: {
    fontSize: theme.font.mediumFontSize,
    marginRight: 20,
    paddingLeft: 10,
  },
  detailHeaderNote: {
    fontSize: theme.font.smallFontSize,
  },
  detailHeaderTherapy: {
    borderLeft: '6px solid',
    borderColor: theme.palette.primary.main,
    paddingLeft: 15,
    marginLeft: 6,
  },
  formFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '75%',
  },
  newInteractionLabel: {
    borderRadius: 5,
    color: theme.palette.primary.white,
    padding: theme.spacing(0, 1),
    fontSize: theme.font.smallFontSize,
    marginLeft: 5,
    backgroundColor: theme.palette.primary.success,
  },
  interventionContainer: {
    borderBottom: `1px solid ${theme.palette.primary.task.borderColor}`,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  overrideFieldSection: {
    border: `1px solid ${theme.palette.primary.grey13}`,
    padding: theme.spacing(),
  },
});

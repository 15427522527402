import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState } from 'react';
import { useTheme } from '@mui/styles';
import { ISectionDetailsProps, ISectionProps } from 'components/section/interfaces';
import { SectionStyle } from './styles';

function displayCount(n?: number) {
  return typeof n === 'number' ? ` (${n})` : '';
}

export function Section<T>(props: ISectionProps<T>) {
  const { section, expanded, onChange, detailsSx, ...otherProps } = props;
  const theme = useTheme();
  const classes = SectionStyle(theme);

  const [alert, setAlert] = useState<string>('');
  const [verifiedStyle, setAlertStyle] = useState(false);
  const onAlert = (message: string, verified = false) => {
    setAlert(message);
    setAlertStyle(verified);
  };

  return (
    <Accordion sx={classes.sectionAccordion} expanded={expanded} onChange={onChange}>
      <AccordionSummary sx={classes.sectionAccordionSummary} expandIcon={<ExpandMoreIcon />}>
        <Typography sx={classes.sectionAccordionTitle}>
          {section.title}
          {displayCount(section.count)}
        </Typography>
        <Typography sx={[classes.sectionAccordionSubtitle, verifiedStyle && classes.verifiedText]}>
          {alert}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={[classes.sectionAccordionDetails, detailsSx]}>
        {section.render({
          ...otherProps,
          alert: onAlert,
          expanded,
        } as unknown as ISectionDetailsProps<T>)}
      </AccordionDetails>
    </Accordion>
  );
}

import React from 'react';

import { groupBy } from 'helpers/misc';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { ITask } from 'interfaces/redux/ITasks';
import {
  shouldRenderDcInIntervention,
  isDurInterventionTask,
} from 'components/dynamic-form/helpers-tasks';
import { DUR, DC } from 'constants/index';
import { useSelector } from 'react-redux';
import { IState } from 'interfaces/redux/IState';
import { noteTagTypes } from 'constants/lists';
import { EditClose } from 'components/icons/icons';
import { Styles } from './index.styles';
import { GroupedArchivedTaskTabsForm } from './grouped-archived-task-tabs-form';

export interface GroupedTaskTabsProps {
  selectedTasks: ITask[];
  onSelectedTasksChange(newSelectedTasks: ITask[]): void;
}

export type CombinedTasks = Record<ITask['taskType'], ITask[]>;

export type TaskType = ITask['taskType'];

export const TabPanel = (props: any) => {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && children}
    </div>
  );
};

export const GroupedArchivedTaskTabs: React.FC<GroupedTaskTabsProps> = ({
  selectedTasks,
  onSelectedTasksChange,
}) => {
  const tasks = useSelector((state: IState) => state.tasks.data);
  const links = useSelector((state: IState) => state.links.data);

  let updatedSelectedTasks = selectedTasks;
  if (
    selectedTasks &&
    selectedTasks.some(
      x => shouldRenderDcInIntervention(x, links, tasks) || isDurInterventionTask(x, tasks),
    )
  ) {
    updatedSelectedTasks = selectedTasks
      .map(t => {
        if (isDurInterventionTask(t, tasks)) {
          return tasks[`${DUR}${t.task_drug_utilization_review_id}`];
        }
        return t;
      })
      .filter(x => !!x); // Ensure values
  }
  updatedSelectedTasks = [...Array.from(new Set(updatedSelectedTasks))];
  const combinedTask: CombinedTasks = groupBy(updatedSelectedTasks, 'taskType');

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleCancel = (canceledTasks: ITask[]) => {
    onSelectedTasksChange(canceledTasks);
    if (value > Number(Object.keys(combinedTask).length - 2) && value > 0) {
      // -1 zero offset, -1 from removed task type
      setValue(value - 1);
    }
  };

  function makeTabProps(index: number) {
    return {
      'id': `tab-taskType-${index}`,
      'aria-controls': `tabpanel-taskType-${index}`,
    };
  }

  return (
    <Box sx={{ width: '100%', my: 2 }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        sx={Styles.tabsStyles}
      >
        {Object.keys(combinedTask).map((taskType, index) => {
          const tasksLength = combinedTask[taskType as TaskType].length;
          const tasksLengthLabel = tasksLength > 1 ? ` (${tasksLength})` : '';
          return (
            <Tab
              key={taskType}
              value={index}
              {...makeTabProps(index)}
              sx={value === index ? Styles.selectedTab : Styles.tab}
              label={
                <Typography sx={value === index ? Styles.tabTextSelected : Styles.tabText}>
                  {`${
                    noteTagTypes.find(tag => tag.label === taskType)
                      ? noteTagTypes.find(tag => tag.label === taskType)?.displayLabel
                      : taskType
                  }${tasksLengthLabel}`}
                  <EditClose
                    onClick={(e: Event) => {
                      e.stopPropagation();
                      handleCancel(combinedTask[taskType as TaskType]);
                    }}
                  />
                </Typography>
              }
            />
          );
        })}
      </Tabs>
      <Box sx={Styles.formsContainer}>
        {Object.keys(combinedTask).map((taskType, index) => (
          <TabPanel key={taskType} value={value} index={index}>
            <GroupedArchivedTaskTabsForm
              selectedTasks={Object.values(combinedTask)[index]}
              handleCancel={handleCancel}
              taskType={taskType}
            />
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};

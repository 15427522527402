import {
  DocumentAnswerValue,
  DownloadDocumentResponse,
  RemoveDocumentResponse,
  UploadDocumentBody,
  UploadDocumentResponse,
} from 'containers/accreditation/types';
import { AxiosResponse } from 'axios';
import { FETCH_ACCREDITATIONS_STATUS } from '../constants';
import HTTP from '../services/http';

export function fetchAccreditationsStatus(customerId: number) {
  const request = HTTP.get(`/accreditations/${customerId}/accreditations_status`, {});
  return {
    type: FETCH_ACCREDITATIONS_STATUS,
    payload: request,
  };
}

export function fetchPendingAccreditationsList(customerId: number) {
  const request = HTTP.get(`/accreditations/${customerId}/get_pending_accreditations`, {});
  return request;
}

export function fetchCompletedAccreditationsList(customerId: number) {
  const request = HTTP.get(`/accreditations/${customerId}/get_completed_accreditations`, {});
  return request;
}

export function fetchPendingAccreditation(questionnaireId: number, customerId: number) {
  const request = HTTP.get(
    `/accreditations/${customerId}/${questionnaireId}/get_accreditation_answers`,
    {},
  );
  return request;
}

export function savePendingAccreditation(
  questionnaireId: number,
  answers: any,
  customerId: number,
) {
  const payload = { id: questionnaireId, answers: answers };
  const request = HTTP.patch(`/accreditations/${customerId}/save_accreditation_answers`, payload);
  return request;
}

export function submitAccreditation(questionnaireId: number, answers: any, customerId: number) {
  const payload = { id: questionnaireId, answers: answers };
  const request = HTTP.patch(
    `/accreditations/${customerId}/submit_accreditation_questionnaire`,
    payload,
  );
  return request;
}

export function approvePendingAccreditation(questionnaireId: number, customerId: number) {
  const payload = { id: questionnaireId };
  const request = HTTP.patch(
    `/accreditations/${customerId}/approve_accreditation_questionnaire`,
    payload,
  );
  return request;
}

export function rejectPendingAccreditation(
  questionnaireId: number,
  customerId: number,
  rejectionMessage: string,
) {
  const payload = { id: questionnaireId, message: rejectionMessage };
  const request = HTTP.patch(
    `/accreditations/${customerId}/reject_accreditation_questionnaire`,
    payload,
  );
  return request;
}

export function downloadAccreditationDocument(
  questionnaireId: number,
  customerId: number,
  docUuid: string,
) {
  const request = HTTP.get(
    `/accreditations/${customerId}/${questionnaireId}/document/${docUuid}`,
    {},
    true, // retry
  ) as Promise<AxiosResponse<DownloadDocumentResponse>>;
  return request;
}

export function uploadAccreditationDocument(
  questionnaireId: number,
  customerId: number,
  body: UploadDocumentBody,
) {
  const request = HTTP.post(
    `/accreditations/${customerId}/${questionnaireId}/document`,
    body,
  ) as Promise<AxiosResponse<UploadDocumentResponse>>;
  return request;
}

export function removeAccreditationDocument(
  questionnaireId: number,
  customerId: number,
  docUuid: string,
) {
  const request = HTTP.delete(
    `/accreditations/${customerId}/${questionnaireId}/document/${docUuid}`,
    {},
  ) as Promise<AxiosResponse<RemoveDocumentResponse>>;
  return request;
}

import moment from 'moment';

import {
  PatientVaccinationsService,
  ISavePatientVaccinationRequest,
} from 'services/utils/patient-vaccinations-service';

import { convertToArborDate } from 'models/time/arbor-date';
import { VACCINATION_SOURCES } from 'constants/index';

import { addVaccineToPatient } from 'actions/action-vaccinations';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { IVaccinationForm, ISaveVaccinationParams } from './interfaces';

const patientVaccinationsService = new PatientVaccinationsService();

const findSelectedSourceLabel = (selectedSource: number): string =>
  VACCINATION_SOURCES.find(source => selectedSource === source.value)?.label || '';

const buildSaveRequest = (vaccinationData: IVaccinationForm): ISavePatientVaccinationRequest => {
  const utcNow = moment.utc();

  vaccinationData.dateReceived.set({
    hour: utcNow.get('hour'),
    minute: utcNow.get('minute'),
    second: utcNow.get('second'),
  });

  return {
    data: {
      vaccine_id: vaccinationData.vaccine.value,
      type: vaccinationData.type.value,
      date_received: convertToArborDate(vaccinationData.dateReceived, true).getUtcDatetime() || '',
      effective_for: vaccinationData.effectiveFor,
      source: findSelectedSourceLabel(vaccinationData.source),
    },
  };
};

const saveVaccination = async (params: ISaveVaccinationParams) => {
  try {
    const addedPatientVaccine = await patientVaccinationsService.saveVaccine(
      params.patientId,
      buildSaveRequest(params.formData),
    );
    params.actionDispatcher(addVaccineToPatient(addedPatientVaccine));
    params.actionDispatcher(notifySuccess('Added vaccination to patient successfully'));
  } catch {
    params.actionDispatcher(notifyError('Failed trying to save vaccination to patient'));
  } finally {
    if (params.afterSaveCallback) {
      params.afterSaveCallback();
    }
  }
};

export { saveVaccination };

import { IPatientGenderLabelProps } from 'components/patient-merge-modal/interfaces';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/styles';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';

export const PatientGenderLabel = (props: IPatientGenderLabelProps) => {
  const { profile } = props;
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);

  return (
    <Typography>
      {profile?.gender}
      {profile.pronouns && (
        <Box sx={classes.highlight}>
          {`(${profile?.pronouns})`}
        </Box>
      )}
    </Typography>
  );
};

import { IAddress, IPatientProfile } from 'components/patient-merge-modal/interfaces';
import { LookupResourceTypes } from 'constants/enums';
import { IPatientMergeInput } from 'actions/action-patient-merge';
import store from 'store';
import { IState } from 'interfaces/redux/IState';
import { PATIENT } from '../../constants';

export const parseStatus = (status: number) => {
  const state = store.getState() as unknown as IState;
  const states = (state?.lookups?.resourceStates || []).filter(
    item => item.resource_name === PATIENT,
  );

  return states.find(item => item.reason_status_id === status)?.state_name || 'Unknown';
};
export const parseLabel = (profile: IPatientProfile) =>
  `${profile.sourcePatientId} (${profile.source.name}) | ${parseStatus(profile.status)} ${
    profile.is_manually_created ? '| Manually created' : ''
  } `;

export const parseCardPatientNameLabel = (profile: IPatientProfile) => {
  return [
    profile.first_name,
    profile.last_name,
    profile.nickname ? `(${profile?.nickname})` : undefined,
  ]
    .filter(part => !!part)
    .join(' ');
};

export const isInputSelected = (
  selectedId: number,
  type: LookupResourceTypes,
  inputs: IPatientMergeInput[],
) => {
  return inputs.some(input => input.type === type && input.selectedId === selectedId);
};

export const isInputSelectedWithExtra = (
  selectedId: number,
  extraInfo: string,
  type: LookupResourceTypes,
  inputs: IPatientMergeInput[],
) => {
  if (
    [
      LookupResourceTypes.PatientEmail,
      LookupResourceTypes.PatientPhone,
      LookupResourceTypes.PatientAddress,
      LookupResourceTypes.PatientContactEmail,
      LookupResourceTypes.PatientContactPhone,
      LookupResourceTypes.PatientContactAddress,
    ].includes(type)
  ) {
    return inputs.some(input => input.type === type && input.extraInfo === extraInfo);
  }
  return isInputSelected(selectedId, type, inputs);
};

export const buildAddressLine = ({ use, line1, line2, city, state, zip }: IAddress) =>
  `${use || ''} ${line1 || ''} ${line2 || ''} ${city || ''} ${state || ''} ${zip || ''}`.replace(
    /[\s]{2,}/gi,
    '',
  );

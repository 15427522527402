export const styles = theme => ({
  document: {
    fontSize: theme.font.smallFontSize,
    border: '1px solid',
    borderColor: theme.palette.primary.grey25,
    borderRadius: 5,
    padding: 7,
    margin: '7px 0',
    overflow: 'hidden',
    maxHeight: 200,
    position: 'relative',
  },
  documentContainer: {
    backgroundColor: theme.palette.primary.white,
    marginBottom: 1,
    borderRadius: 5,
    padding: 5,
    width: '98%',
    maxHeight: 105,
    overflow: 'hidden',
    cursor: 'pointer',
    position: 'relative',
    fontSize: theme.font.smallFontSize,
  },
  addNewDocumentDisplay: {
    padding: 2,
    cursor: 'pointer',
  },
  displayFileName: {
    fontWeight: 400,
    display: 'block',
  },
  displayIconContainer: {
    width: 25,
    paddingLeft: 2,
    paddingTop: 6,
  },
  tag: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    bottom: 0,
  },
});

import {
  PATIENT_CHECKLIST_REQUEST,
  PATIENT_CHECKLIST_SUCCESS,
  PATIENT_CHECKLIST_FAILURE,
  SELECTED_PATIENT_ID,
} from 'constants/index';

const initialState = {
  loading: false,
  patientId: null,
  data: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SELECTED_PATIENT_ID:
      return initialState;
    case PATIENT_CHECKLIST_SUCCESS:
      if (payload) {
        return {
          loading: false,
          patientId: payload.patientId,
          data: payload.clinics,
        };
      }
      return state;

    case PATIENT_CHECKLIST_REQUEST:
    case PATIENT_CHECKLIST_FAILURE:
      return {
        loading: false,
        patientId: null,
        data: [],
      };
    default:
      return state;
  }
};

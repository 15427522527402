import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  FETCH_AUDITS_REQUEST,
  FETCH_AUDIT_SUCCESS,
  NOTIFY_ERROR,
  RESOLVE_AUDIT_FLOW,
  RESOLVE_AUDIT,
} from '../constants';
import HTTP from '../services/http';

export function* workerFetchPatientAudits() {
  try {
    const state = yield select();
    const { selectedPatientId } = state;
    if (selectedPatientId) {
      const patientResponse = yield call(() =>
        HTTP.get(`/patients/${selectedPatientId}/audits`, {}),
      );
      if (patientResponse.data && patientResponse.data.audits) {
        yield put({
          type: FETCH_AUDIT_SUCCESS,
          payload: patientResponse.data,
        });
      }
    }
  } catch (error) {
    yield put({
      type: NOTIFY_ERROR,
      payload: 'Error Fetching Audits',
    });
  }
}

export function* workerResolveAudit(action) {
  try {
    const audit = action.payload;
    const request = HTTP.patch(`/patients/${audit.patient_id}/audits/${audit.id}`, audit);
    if (request) {
      yield put({
        type: RESOLVE_AUDIT,
        payload: request,
      });
    }
  } catch (error) {
    yield put({
      type: NOTIFY_ERROR,
      payload: 'Error Resolving Audit',
    });
  }
}

export function* watcherAuditSaga() {
  yield takeLatest([FETCH_AUDITS_REQUEST], workerFetchPatientAudits);
  yield takeLatest([RESOLVE_AUDIT_FLOW], workerResolveAudit);
}

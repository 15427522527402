import React from 'react';
import { Divider } from '@mui/material';
import moment from 'moment';
import qreJson from 'config/task-types/qre.json';
import { qreIncidentSubtypesForIncidentAndComplaints } from 'constants/lists';
import QreDetailsWrapper from 'components/qre-details/wrapper';
import { getCommonProviders, getDateForProvider, staticProviders } from './common-provider';

export default (task, state) => {
  const { lookups = {} } = state;
  return {
    ...staticProviders,
    ...getCommonProviders(task, state, qreJson),
    incidentCategories: lookups.incidentCategories,
    incidentTypes: lookups.incidentTypes,
    medicationErrorCategories: lookups.medicationErrorCategories,
    medicationErrorTypes: lookups.medicationErrorTypes,
    incidentExternalResponsibles: lookups.incidentExternalResponsibles,
    incidentReportingParties: lookups.incidentReportingParties,
    resolutionTypes: [
      { id: 'Action taken', name: 'Action taken' },
      { id: 'No further action taken', name: 'No further action taken' },
      { id: 'Needs escalation', name: 'Needs escalation' },
    ],
    errorTypes: qreIncidentSubtypesForIncidentAndComplaints.map(t => ({
      id: t.value,
      name: t.label,
    })),
    overrideReasons: lookups.incidentOverrideReasons,
    customElements: [
      {
        id: 'incident-detail',
        content: () => (
          <>
            <QreDetailsWrapper task={task} />
            <Divider />
          </>
        ),
      },
    ],
    json: qreJson,
    initialValues: {
      // for dates in initial values, utc values must be used
      time_out_date: getDateForProvider(task.time_out_complete_date),
      not_required_date: task.not_required_complete_date
        ? getDateForProvider(task.not_required_complete_date)
        : getDateForProvider(moment()),
      canceled_date: task.canceled_complete_date
        ? getDateForProvider(task.canceled_complete_date)
        : getDateForProvider(moment()),
      submitted_dt: state.currentDateTime,
      reviewed_dt: state.currentDateTime,
      resolved_dt: state.currentDateTime,
    },
  };
};

import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { Grid } from '@mui/material';
import { SmallSpacer } from 'components/spacer/spacer';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { useSelector } from 'react-redux';
import { DynamicFormContext } from './dynamic-form-context';

export const DynamicForm = ({
  cancelButtonText,
  submitButtonText,
  children,
  alwaysEnabled,
  disableSubmitIfInvalid,
  buttonIdPrefix,
  className,
  onFormChange,
  onCancel,
  onSubmit,
  form: formId,
  providers,
  onValidityChange,
  // injected props from redux hoc
  handleSubmit,
  initialValues,
  cancel,
  pristine,
  valid,
  submitting,
}) => {
  const form = useSelector(state => state.form[formId]) || {};

  useEffect(() => {
    if (onFormChange) {
      onFormChange(form.values, valid);
    }
  }, [form && form.values]);

  useEffect(() => {
    if (onValidityChange) {
      onValidityChange(valid, pristine);
    }
  }, [valid]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <DynamicFormContext.Provider value={{ providers, initialValues, formId }}>
      <form onSubmit={handleSubmit} autoComplete="off" className={className}>
        <Grid container>{children}</Grid>
        <SmallSpacer />

        {onSubmit && (
          <ConfirmationPanel
            cancelButtonName="edit_therapy_cancel_button"
            submitButtonName="edit_therapy_submit_button"
            cancelButtonText={cancelButtonText}
            submitButtonText={submitButtonText}
            handleCancel={() => {
              cancel();
              if (onCancel) {
                onCancel();
              }
            }}
            hideCancel={!onCancel}
            disableSubmit={
              (!alwaysEnabled && pristine) || (disableSubmitIfInvalid && !valid) || submitting
            }
            buttonIdPrefix={buttonIdPrefix}
          />
        )}
      </form>
    </DynamicFormContext.Provider>
  );
};

const ConnectedForm = reduxForm({})(DynamicForm);

export default ({ formId, data, ...props }) => (
  <ConnectedForm form={formId} initialValues={data} {...props} />
);

import { FETCH_SHIPPING_HOLIDAYS_SUCCESS, FETCH_DAYS_SHIPPING_SUCCESS } from 'constants/index';

const initialState = {
  shippingHolidays: {
    shippingCustomerHolidays: [],
    shippingCourierHolidays: [],
    shippingUpsHolidays: [],
  },
  daysShipping: [],
};

export default (
  state = initialState,
  { type, payload }: { type: string; payload: { data: unknown } },
) => {
  switch (type) {
    case FETCH_SHIPPING_HOLIDAYS_SUCCESS:
      if (payload.data) {
        return {
          ...state,
          shippingHolidays: payload.data,
        };
      }
      return state;
    case FETCH_DAYS_SHIPPING_SUCCESS:
      if (payload.data) {
        return {
          ...state,
          daysShipping: payload.data,
        };
      }
      return state;
    default:
      return state;
  }
};

class ArrayUtils {
  /**
   * Given an array of objects, will sort that array on multiple properties.
   * The last parameter, @param addlSortProperties, doesn't allow for idealistic orders
   * since in my use case (sorting tasks by type, then order) it was not necessary
   *
   * Note: this returns a new array, not the original sorted.
   *
   * @param arrayToSort The array to sort
   * @param initialProperty The primary property to sort on
   * @param idealOrder Array to sort the primary properties by
   * @param addlSortProperties Additional properties on the objects to sort by
   */
  static sortArrayIdeallyAndMulti = (
    arrayToSort: any[],
    initialProperty: string,
    idealOrder: any[],
    addlSortProperties?: string[],
  ): any[] => {
    if (arrayToSort == null || idealOrder == null || !idealOrder.length) {
      return arrayToSort;
    }

    // No need to go through the additional steps if we don't have additional sort properties
    if (!addlSortProperties || !addlSortProperties.length) {
      return ArrayUtils.sortItemsBasedOnProperty(arrayToSort, initialProperty, idealOrder);
    }

    // first get the items in groups based on the initial property
    const grouped = arrayToSort.reduce((arr, item) => {
      const itemVal = item[initialProperty];

      if (arr[itemVal]) {
        arr[itemVal].push(item);
      } else {
        arr[itemVal] = [item];
      }

      return arr;
    }, {});

    // second go through and order the grouped things by the ideal order
    const orderedGroups = ArrayUtils.sortObjectPropertiesBasedOnList(grouped, idealOrder);

    // third, sort all of the secondary arrays based on the additional properties
    if (addlSortProperties) {
      Object.entries(orderedGroups).map(([, value]) => {
        addlSortProperties.forEach(property => {
          value.sort((a: any, b: any) => {
            const aVal = a[property];
            const bVal = b[property];

            return aVal - bVal;
          });
        });
      });
    }

    // fourth, flatten everything back and return
    const results: any[] = [];

    Object.entries(orderedGroups).forEach(([, value]) => {
      value.forEach((val: any) => results.push(val));
    });

    return results;
  };

  /**
   * Takes a list of items and an ideal order of those items
   * and sorts the initial list by that ideal order.
   *
   * Initial use case: tasks should be sorted [C, B, A]
   * Task lists we have are ordered: [D, A, B, C]
   * Result: [C, B, A, D]
   *
   * @param arrayToSort The array to sort
   * @param idealOrder The array to base the sorting off of
   */
  static sortBasedOnList = <T>(arrayToSort: T[], idealOrder: T[]): T[] => {
    if (arrayToSort == null || idealOrder == null || !idealOrder.length) {
      return arrayToSort;
    }

    return arrayToSort.sort((a, b) => {
      if (!idealOrder.includes(b)) {
        return 1;
      }
      return idealOrder.indexOf(a) - idealOrder.indexOf(b);
    });
  };

  /**
   * Takes an array of objects and sorts them by
   *
   *
   * @param arrayToSort Array of objects to sort
   * @param property The property of those objects to sort by
   * @param idealOrder The array defining the ideal order of the objects
   */
  static sortItemsBasedOnProperty = (
    arrayToSort: any[],
    property: string,
    idealOrder: any[],
  ): any[] => {
    if (arrayToSort == null || idealOrder == null || property == null) {
      return arrayToSort;
    }
    return arrayToSort.sort((a, b) => {
      const aVal = a[property];
      const bVal = b[property];

      if (!idealOrder.includes(bVal)) {
        return 1;
      }

      return idealOrder.indexOf(aVal) - idealOrder.indexOf(bVal);
    });
  };

  /**
   * Sorts the properties of an object based on the values in an array.
   * Note that this does not update the original object but instead returns a new one.
   *
   * Example:
   *  objectToSort: {a: 1, b: 2, c: 3}
   *  basedOnArray: ['c', 'b', 'a']
   *  result: {c: 3, b: 2, a: 1}
   *
   *
   * @param objectToSort Object with properties we want to sort
   * @param idealOrder Array to sort the properties by
   */
  static sortObjectPropertiesBasedOnList = (
    objectToSort: Record<any, any>,
    idealOrder: any[],
  ): Record<any, any> => {
    if (objectToSort == null || idealOrder == null) {
      return objectToSort;
    }

    const sorted = Object.entries(objectToSort).sort(([aKey], [bKey]) => {
      const aKeyAny = aKey as any;
      const bKeyAny = bKey as any;
      if (!idealOrder.includes(bKeyAny)) {
        return 1;
      }
      return idealOrder.indexOf(aKeyAny) - idealOrder.indexOf(bKeyAny);
    });

    const result = sorted.reduce<{}>((acc, [key, value]) => {
      return { ...acc, [key]: value };
    }, {});

    return result;
  };
}

export { ArrayUtils };

import { windowFeatureIsEnabled } from 'config/window-features';
import { FillDeliveryConfirmationStatus } from 'interfaces/enums/TaskStatuses/FillDeliveryConfirmationStatus';
import { FETCH_LOOKUPS } from 'constants/index';

const initialState = {
  statuses: {},
  intervention_types: [],
  intervention_categories: [],
  intervention_severities: [],
  intervention_reasons: [],
  intervention_outcomes: [],
  intervention_recommendations: [],
  outreach_types: [],
  outreach_categories: [],
  outreach_method: [],
  outreach_reason: [],
  outreach_outcome: [],
  medication_review_DDI_database_used: [],
  medication_review_interaction: [],
  counseling_completed_details: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LOOKUPS: {
      const { lookups } = payload.data;
      if (lookups) {
        const statuses = [
          'therapy_administration',
          'therapy_enrollment',
          'pa',
          'fa',
          'fc',
          'tpr',
          'rs',
          'dc',
          'fdc',
          'int',
          'csl',
          'ar',
          'or',
          'mr',
          'qre',
          'dur',
          'patient',
        ].reduce((acc, taskType) => {
          acc[taskType] = lookups.lookup_status_reason
            .filter(it => it.resource === taskType && it.is_active === 1)
            .map(it => {
              const category = lookups.task_status_categories.find(c => c.id === it.category_id);
              return {
                ...it,
                category_name: category ? category.name : null,
                category_id: category ? category.id : null,
              };
            });
          return acc;
        }, {});

        return {
          ...state,
          statuses: {
            ...statuses,
            fdc: windowFeatureIsEnabled('fdc_statuses')
              ? statuses.fdc
              : statuses.fdc?.filter(it => it.id !== FillDeliveryConfirmationStatus.NeedsReview),
          },
          outreach_types: lookups.lookup_task_outreach_type,
          outreach_categories: lookups.lookup_task_outreach_category,
          outreach_method: lookups.lookup_task_outreach_methods,
          outreach_reason: lookups.lookup_task_outreach_reasons,
          schedule_outreach_reason: lookups.lookup_task_schedule_outreach_reasons,
          intervention_severities: lookups.lookup_task_interventions_severity,
          intervention_reasons: lookups.lookup_task_interventions_reasons,
          intervention_outcomes: lookups.lookup_task_interventions_outcomes,
          intervention_recommendations: lookups.lookup_task_interventions_recommendations,
          intervention_categories: lookups.lookup_task_interventions_category,
          intervention_types: lookups.lookup_task_interventions_type,
          medication_review_DDI_database_used: lookups.lookup_task_medication_review_ddi_database,
          medication_review_interaction: lookups.lookup_task_medication_review_interactions,
          counseling_completed_details: lookups.task_counseling_checkboxes,
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export const styles = theme => ({
  scheduleOutreachDetails: {
    padding: 20,
  },
  detailContainer: {
    display: 'block',
  },
  drugRow: {
    border: '1px solid',
    borderColor: theme.palette.primary.grey2,
    borderRadius: 5,
    marginTop: 5,
    padding: 10,
  },
  drugButton: {
    marginLeft: 5,
    marginRight: 5,
    color: theme.palette.text.secondary,
  },
  linkedDrugButton: {
    marginLeft: 5,
    marginRight: 5,
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.pageHeader,
  },
  addTherapyModal: {
    position: 'absolute',
    width: theme.spacing(120),
    backgroundColor: theme.palette.primary.background,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
  linkOptionsSpan: {
    marginLeft: 10,
  },
  linkedDrugModalContainer: {
    padding: 10,
  },
  Modal: {
    position: 'absolute',
    width: theme.spacing(150),
    backgroundColor: theme.palette.primary.background,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
  note: {
    fontSize: theme.font.smallFontSize,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 5,
    padding: 5,
    marginBottom: 2,
    overflow: 'hidden',
    maxHeight: 200,
    position: 'relative',
  },
  expandIconExpanded: {
    left: 0,
    position: 'absolute',
  },
  expandIconColapsed: {
    left: 0,
    position: 'absolute',
  },
  summaryContainer: {
    'width': '100%',
    '&:last-child': {
      paddingRight: 0,
    },
  },
  scheduleOutreachSummary: {
    paddingRight: 0,
    paddingBottom: theme.spacing(1),
  },
  soPanel: {
    marginBottom: 30,
  },
  communicationTypeContainer: {
    borderRadius: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
    paddingBottom: 2,
    color: theme.palette.primary.white,
    marginLeft: 10,
    backgroundColor: theme.palette.primary.trellisBlue,
  },
  statusContainer: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  excludeWording: {
    marginTop: 8,
    marginBottom: 8,
  },
  formControl: {
    width: '100%',
  },
  excludedForm: {
    width: 300,
    padding: 10,
  },
  addTargetForm: {
    'width': 700,
    'padding': 24,
    '& #add_target_title': {
      paddingBottom: 12,
    },
  },
  addTargetFormWrapper: {
    paddingTop: theme.spacing(3),
  },
  therapySearchWrapper: {
    '& .MuiInput-root': {
      marginTop: 11,
    },
  },
  popoverWithOverflow: {
    overflowY: 'visible',
    overflowX: 'visible',
  },
  linkTherapyContainer: {
    width: '100%',
  },
  linkTherapyList: {
    overflowY: 'auto',
    maxHeight: 400,
    flexWrap: 'nowrap',
  },
  clinicName: {
    display: 'inline',
    paddingLeft: 10,
    fontWeight: 'bold',
  },
  clinicStatusContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  selectAllTherapies: {
    marginLeft: 11,
    fontWeight: 'bold',
  },
  outcomeButton: {
    backgroundColor: theme.palette.primary.trellisBlue,
    color: theme.palette.primary.white,
    marginRight: 15,
    paddingLeft: 15,
    paddingRight: 15,
  },
  excludedDrugLabel: {
    fontWeight: 'bold',
  },
});

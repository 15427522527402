import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

const styles = (theme: Theme) => {
  const { font } = expandTheme(theme);
  return createStyles({
    titleWrapper: {
      paddingLeft: theme.spacing(2),
    },
    titleText: {
      fontSize: font.mediumFontSize,
      fontWeight: 'bold',
    },
    container: {
      marginTop: theme.spacing(2),
    },
  });
};

export { styles };

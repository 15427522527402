import React, { useState } from 'react';
import { get, isEmpty } from 'lodash';
import { IconButton } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { parseSearchValueCriteria, validateFilterValues } from '../utils';
import { SORT_DEFAULT } from '../constants';

import ColumnFilterPopup from '../popups/column-filter/column-filter';
import useStyles from './styles';

function DefaultHeader(props) {
  const { invoicingCenteredItems } = useStyles();
  const { column, columnFilter, onColumnFilterChange, source, gotoPage } = props;
  const { criteria } = get(columnFilter, column.id, {});
  const [anchorCellEl, setAnchorCellEl] = useState();
  const [searchValueCriteria, setSearchValueCriteria] = useState();
  const [secondSearchValueCriteria, setSecondSearchValueCriteria] = useState();
  const [sortValue, setSortValue] = useState('default');
  const [selectedCriteria, setSelectedCriteria] = useState(criteria);

  const { values, valuesBySource, canFilter, dataType, label } = column.raw;
  const handleShowColumnFilterPopup = event => {
    setAnchorCellEl(event.currentTarget);
  };

  const handleOnClose = () => {
    setAnchorCellEl(null);
  };

  const handleOnColumnFilter = checkedList => {
    let filter = {};

    if (
      validateFilterValues({
        dataType,
        selectedCriteria,
        searchValueCriteria,
        secondSearchValueCriteria,
        checkedList,
      })
    ) {
      filter = {
        value: parseSearchValueCriteria(dataType, searchValueCriteria, secondSearchValueCriteria),
        criteria: selectedCriteria,
      };
    }

    if (sortValue !== SORT_DEFAULT) {
      filter.sort = sortValue;
    }

    if (!isEmpty(filter)) {
      onColumnFilterChange({
        ...columnFilter,
        [column.id]: filter,
      });
    }
  };

  const handleOnClearColumnFilter = () => {
    const { [column.id]: value, ...rest } = columnFilter;
    onColumnFilterChange(rest);
  };

  const open = Boolean(anchorCellEl);
  const columnValues = valuesBySource ? values[source.source] : values;

  const isFilterable =
    canFilter && ((valuesBySource && columnValues.length > 0) || !valuesBySource);
  return (
    <>
      <div className={invoicingCenteredItems}>
        <div>{get(column.raw, 'label', 'None')}</div>
        {isFilterable && (
          <IconButton size="small" onClick={handleShowColumnFilterPopup}>
            {columnFilter && Object.keys(columnFilter).some(o => o === column.id) ? (
              <FilterListIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        )}
      </div>
      {anchorCellEl && (
        <ColumnFilterPopup
          headerTitle={label}
          columnId={column.id}
          anchorEl={anchorCellEl}
          onClick={e => {
            e.stopPropagation();
          }}
          columnFilter={columnFilter}
          onColumnFilterChange={handleOnColumnFilter}
          goToPage={value => gotoPage(value)}
          onClearColumnFilter={handleOnClearColumnFilter}
          searchValueCriteria={searchValueCriteria}
          secondSearchValueCriteria={secondSearchValueCriteria}
          setSearchValueCriteria={setSearchValueCriteria}
          setSecondSearchValueCriteria={setSecondSearchValueCriteria}
          sortValue={sortValue}
          setSortValue={setSortValue}
          selectedCriteria={selectedCriteria}
          setSelectedCriteria={setSelectedCriteria}
          open={open}
          onClose={handleOnClose}
          id="filtering-popover"
          columnValues={columnValues}
          dataType={dataType}
        />
      )}
    </>
  );
}

export default DefaultHeader;

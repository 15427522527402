import { IHeaderCell } from 'containers/application-manager/base-table/types';
import { IResult, IChild } from './types';
import { commaSeparatedStringConvert } from './utils';

export enum ViewStateOptions {
  Included = 'Included',
  Excluded = 'Excluded',
}
export const searchFieldPlaceholder = 'Search by drug name or GPI 10';
export const debounceTimeMs = 500;
export const initialSearchTerm = '';

export const renderButtonLabel = (button: ViewStateOptions): string => {
  const buttonMap: Record<ViewStateOptions, () => string> = {
    [ViewStateOptions.Included]: () => 'Included',
    [ViewStateOptions.Excluded]: () => 'Excluded',
  };
  return buttonMap[button]();
};

export const PAGINATION_PARAMS = {
  pageSizeQueryStringKey: 'pageSize',
  pageNumberQueryStringKey: 'pageNumber',
  searchTermQueryStringKey: 'searchTerm',
  sortPropQueryStringKey: 'sortProp',
  sortOrderQueryStringKey: 'sortOrder',
};

export const COLUMN_SETTINGS: IHeaderCell<IResult, IChild>[] = [
  {
    label: 'Drug Name',
    serverSideSearchField: 'drug_name',
    parentKey: 'drug_name',
    childKey: 'drug_name',
    sortable: true,
  },
  {
    label: 'GPI 10',
    serverSideSearchField: 'gpi_10',
    parentKey: 'gpi_10',
    childKey: 'gpi_10',
    sortable: true,
  },
  {
    label: 'Strength',
    childKey: 'strength',
    sortable: true,
  },
  {
    label: 'Dosage Form',
    childKey: 'dosage',
    sortable: true,
  },
  {
    label: 'Specialty Type',
    childKey: 'specialty_type',
    parentKey: 'specialty_type',
    sortable: true,
  },
  {
    label: 'Purchase Account',
    childKey: 'purchase_account',
    childValueFormatter: commaSeparatedStringConvert,
    sortable: true,
  },
];

export const CtdConstants = {
  defaultRowsPerPage: 10,
};

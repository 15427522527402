import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import { Variant, StarSvgProps } from './highlight-star.types';

const DEFAULT_STAR_FILL = '#D6DEE1';
const PATIENT_OVERVIEW_STAR = '#5E88B0';
const STAR_FILL = '#E0BB53';

const variantStyles: Record<Variant, StarSvgProps> = {
  'default': {
    fill: DEFAULT_STAR_FILL,
    width: '20px',
    height: '19px',
  },
  'default-highlight': {
    fill: STAR_FILL,
    width: '20px',
    height: '19px',
  },
  'patient-overview': {
    fill: PATIENT_OVERVIEW_STAR,
    width: '24.17px',
    height: '22.96px',
  },
  'patient-overview-highlight': {
    fill: STAR_FILL,
    width: '24.17px',
    height: '22.96px',
  },
};

export const useStyles = makeStyles<Theme, { variant: Variant }>((theme: Theme) => {
  return {
    container: {
      position: 'relative',
      width: theme.spacing(2.7),
      height: theme.spacing(2.7),
    },
    star: {
      'position': 'absolute',
      'width': (props: { variant: Variant }) => variantStyles[props.variant].width,
      'height': (props: { variant: Variant }) => variantStyles[props.variant].height,
      '& path': {
        fill: (props: { variant: Variant }) => variantStyles[props.variant].fill,
      },
    },
    badge: {
      position: 'absolute',
      top: theme.spacing(1.4),
      left: theme.spacing(1.4),
    },
  };
});

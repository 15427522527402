import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material';
import compose from 'recompose/compose';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { workListChanged } from 'actions/action-patient';
import {
  getStatusByStatusId,
  getReasonsByStatus,
  getInitialServiceGroupForReactSelect,
  followupDateChanged,
  addTaskOldValues,
} from 'services/utils/task-service';
import { editFa, fetchTaskCounts } from 'actions/action-tasks';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { renderDropdown, renderTextField } from 'components/form/field/redux-field';
import { ReactSelectForReduxSingle } from 'components/form/field/react-select';
import { convertToArborDate } from 'models/time/arbor-date';
import {
  EDIT_FA_FORM,
  STATUS_FA_TIMEOUT_OTHER,
  STATUS_FA_APPROVED,
  STATUS_FA_ALREADY_IN_PLACE,
} from '../../../constants';
import { renderDatePicker } from '../../../components/form/datepicker/datetime-picker';
import { styles } from '../task-detail-styles';
import {
  validateDateTime,
  validateDate,
  validateNumber,
  required,
} from '../../../components/form/validation/validation';

class EditFa extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleServiceGroupDropdown = this.handleServiceGroupDropdown.bind(this);

    this.state = { selectValue: props.taskStatus, serviceGroupDropdown: -1 };
  }

  handleSelect(e) {
    this.setState({
      selectValue: e.target.value,
    });
  }

  handleServiceGroupDropdown(e) {
    this.setState({
      serviceGroupDropdown: e.target.value,
    });
  }

  // Handles the submit functionality. Right now it sets some fields to null for simplicity's sake
  handleSubmit(values) {
    const {
      editFa, // eslint-disable-line
      task,
      cancel,
      workListChanged, // eslint-disable-line
      fetchTaskCounts, //eslint-disable-line
    } = this.props;

    const payload = {
      ...values,
      id: task.id,
      appealed_date: convertToArborDate(values.appealed_date, true).getUtcDatetime(),
      approved_date: convertToArborDate(values.approved_date, true).getUtcDatetime(),
      not_requested_date: convertToArborDate(values.not_requested_date, true).getUtcDatetime(),
      canceled_date: convertToArborDate(values.canceled_date, true).getUtcDatetime(),
      time_out_date: convertToArborDate(values.time_out_date, true).getUtcDatetime(),
      denied_date: convertToArborDate(values.denied_date).getUtcDate(),
      expiration_date: values.expiration_date
        ? convertToArborDate(values.expiration_date).getUtcDate()
        : null,
      followup_dt: convertToArborDate(values.followup_dt).getUtcDatetime(),
      last_checked_dt: values.last_checked_dt
        ? convertToArborDate(values.last_checked_dt, true).getUtcDatetime()
        : null,
      status_id: values.status_id,
      submitted_dt: values.submitted_dt
        ? convertToArborDate(values.submitted_dt).getUtcDatetime()
        : null,
      therapy_id: task.therapy_id,
      service_group_id: values.service_group_id.value,
      fill_cycle_number: values.fill_cycle_number,
      copay_textarea: values.copay_textarea,
    };
    return editFa(addTaskOldValues(payload, task)).then(() => {
      if (followupDateChanged(task.followup_dt, payload.followup_dt)) {
        workListChanged();
        fetchTaskCounts();
      }
      cancel();
    });
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      classes,
      cancel,
      task,
      statuses,
      serviceGroups,
      fillCycles,
      statusId,
    } = this.props;

    const { selectValue, serviceGroupDropdown } = this.state;
    const { therapy_id: therapyId } = task;

    return (
      <Grid
        container
        justifyContent="flex-start"
        className={(classes.formRowContainer, classes.formContainer)}
      >
        <Grid item xs={12}>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            className={classes.editFormContainer}
            autoComplete="off"
          >
            <Grid container className={classes.formRowContainer}>
              <Grid item lg={3} className={classes.fieldContainer}>
                <Field
                  name="followup_dt"
                  component={renderDatePicker}
                  label="Follow up Date *"
                  validate={[validateDate, required]}
                />
              </Grid>
              <Grid item lg={3} className={classes.fieldContainer}>
                <Field
                  name="last_checked_dt"
                  component={renderDatePicker}
                  label="Last Checked Date"
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="service_group_id"
                  label="Service Group *"
                  validate={[required]}
                  component={ReactSelectForReduxSingle}
                  fields={serviceGroups.map(int => ({
                    label: int.display_name,
                    value: int.id,
                    isDisabled: !int.active,
                  }))}
                  state={serviceGroupDropdown}
                />
              </Grid>
              <Grid item lg={3} className={classes.fieldContainer}>
                <Field
                  name="submitted_dt"
                  label="Submitted Date"
                  component={renderDatePicker}
                  validate={[validateDateTime]}
                />
              </Grid>
              <Grid item lg={3} className={classes.fieldContainer}>
                <Field
                  name="denied_date"
                  label="Denied Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              {[STATUS_FA_APPROVED, STATUS_FA_ALREADY_IN_PLACE].includes(statusId) && (
                <Grid item lg={3} className={classes.fieldContainer}>
                  <Field
                    name="expiration_date"
                    label="Expiration Date"
                    component={renderDatePicker}
                    validate={[validateDate]}
                  />
                </Grid>
              )}
              <Grid item lg={3} className={classes.fieldContainer}>
                <Field
                  name="appealed_date"
                  label="Appealed Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item lg={3} className={classes.fieldContainer}>
                <Field
                  name="approved_date"
                  label="Approved Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item lg={3} className={classes.fieldContainer}>
                <Field
                  name="not_requested_date"
                  label="Not Requested Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item lg={3} className={classes.fieldContainer}>
                <Field
                  name="canceled_date"
                  label="Canceled Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item lg={3} className={classes.fieldContainer}>
                <Field
                  name="time_out_date"
                  label="Time Out Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              {getReasonsByStatus(statuses, getStatusByStatusId(task.status_id, statuses))[0]
                .label && (
                <Grid item xs={6} className={classes.fieldContainer}>
                  <Field
                    name="status_id"
                    label="Reason"
                    component={renderDropdown}
                    fields={getReasonsByStatus(
                      statuses,
                      getStatusByStatusId(task.status_id, statuses),
                    )}
                    state={selectValue}
                  />
                </Grid>
              )}
              {statusId === STATUS_FA_TIMEOUT_OTHER && (
                <Grid item xs={6} className={classes.fieldContainer}>
                  <Field
                    name="additional_reason"
                    label="Additional Reason *"
                    component={renderTextField}
                    validate={[required]}
                  />
                </Grid>
              )}
              <Grid item xs={6} className={classes.fieldContainer}>
                <Field name="copay_textarea" label="Copay" component={renderTextField} multiline />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="fill_cycle_number"
                  component={renderDropdown}
                  fields={
                    fillCycles[therapyId] &&
                    Object.keys(fillCycles[therapyId])
                      .map(k => fillCycles[therapyId][k])
                      .map(cycle => ({
                        label: `#${cycle.fill_cycle} NBD: ${convertToArborDate(
                          cycle.needsby_date,
                          true,
                        ).getUtcDate(true)}`,
                        value: cycle.fill_cycle,
                      }))
                  }
                  label="Fill cycle *"
                  validate={[required, validateNumber]}
                />
              </Grid>
            </Grid>

            {/* Eighth Row */}
            <ConfirmationPanel
              cancelButtonName="edit_therapy_cancel_button"
              submitButtonName="edit_therapy_submit_button"
              handleCancel={cancel}
              disableSubmit={pristine || submitting}
            />
          </form>
        </Grid>
      </Grid>
    );
  }
}

function handleFormName(task) {
  if (!task) {
    return null;
  }
  const name = `${EDIT_FA_FORM}_${task.therapy_id}_${task.id}`;
  return name;
}

function mapStateToProps(state, props) {
  const name = handleFormName(props.task);
  const selector = formValueSelector(name);
  const statusId = selector(state, 'status_id');
  const { fillCycles } = state;

  return {
    form: name,
    fillCycles,
    statusId,
    initialValues: {
      additional_reason: props.task.additional_reason ? props.task.additional_reason : '',
      appealed_date: convertToArborDate(props.task.appealed_date, true).getUtcDate(true),
      approved_date: convertToArborDate(props.task.approved_date, true).getUtcDate(true),
      denied_date: convertToArborDate(props.task.denied_date, true).getUtcDate(true),
      expiration_date: convertToArborDate(props.task.expiration_date, true).getUtcDate(true),
      followup_dt: convertToArborDate(props.task.followup_dt, true).getCustomerDate(true),
      last_checked_dt: convertToArborDate(props.task.last_checked_dt, true).getUtcDate(true),
      status_id: props.task.status_id,
      service_group_id: props.task.service_group_id
        ? getInitialServiceGroupForReactSelect(props.task.service_group_id, props.serviceGroups)
        : '',
      submitted_dt: convertToArborDate(props.task.submitted_dt, true).getCustomerDate(
        true,
        'MM/DD/YYYY hh:mm A',
      ),
      fill_cycle_number: props.task.fill_cycle_number || null,
      not_requested_date: convertToArborDate(props.task.not_requested_date, true).getUtcDate(true),
      canceled_date: convertToArborDate(props.task.canceled_date, true).getUtcDate(true),
      time_out_date: convertToArborDate(props.task.time_out_date, true).getUtcDate(true),
      copay_textarea: props.task.copay_textarea,
    },
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchTaskCounts,
    workListChanged,
    editFa,
  }),
)(reduxForm({})(EditFa));

import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../hooks/use-typed-selector';
import { styles } from './adherence-section-styles';
import {
  fetchPatientAdherenceData,
  fetchAdherenceComplianceStatus,
} from '../../actions/action-patient';
import AdherenceTable from './adherence-table';
import AdherenceInterventionButtonGroup from './adherence-int-btn-group';

const AdherenceSection = (props: any): JSX.Element => {
  const { classes } = props;
  const dispatch = useDispatch();
  const adherenceData = useTypedSelector(state => state.patient.adherenceData);
  const patientId = useTypedSelector(state => state.selectedPatientId);

  useEffect(() => {
    if (!adherenceData) {
      dispatch(fetchPatientAdherenceData(patientId));
      dispatch(fetchAdherenceComplianceStatus(patientId));
    }
  }, [adherenceData]);

  if (adherenceData) {
    const nonCompliantTherapiesString = adherenceData.nonCompliantTherapies.join(',');
    return (
      <Grid container item xs={12} className={classes.adherenceSectionContainer}>
        <AdherenceTable adherenceData={adherenceData} />
        <AdherenceInterventionButtonGroup
          nonCompliantTherapies={nonCompliantTherapiesString}
          previousInterventionCount={adherenceData.previousInterventionCount}
          handleSubmitComplete={() => dispatch(fetchPatientAdherenceData(patientId))}
        />
      </Grid>
    );
  }
    return <div className={classes.adherenceSectionLoading}>...Loading Adherence Data</div>;
};

export default compose(withStyles(styles, { withTheme: true }))(AdherenceSection);

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from '../../utils/theme-util';

const styles = (theme: Theme) => {
  const { palette, fonts, layout } = expandTheme(theme);

  return createStyles({
    fieldLabel: {
      fontSize: fonts.formLabelSize,
      fontWeight: fonts.weight.regular,
      color: palette.primary.formLabel,
      minHeight: layout.taskFormLabelMinHeight + 3,
    },
    fieldLabelMedium: {
      fontSize: fonts.mediumFontSize,
      fontWeight: fonts.weight.regular,
      color: palette.primary.formLabel,
      minHeight: layout.taskFormLabelMinHeight + 3,
    },
    fieldLabelLarge: {
      fontSize: fonts.largeFontSize,
      fontWeight: fonts.weight.thick,
      color: palette.text.primary,
      minHeight: layout.taskFormLabelMinHeight + 3,
    },
  });
};

export { styles };

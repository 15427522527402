import { Grid, Typography, Divider } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { workListChanged } from 'actions/action-patient';
import { addTask, editIncident, fetchTaskCounts } from 'actions/action-tasks';
import classNames from 'classnames';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import ReactSelectForRedux from 'components/form/field/react-select';
import {
  renderCheckboxHorizontal,
  renderDropdown,
  renderReactRadioGroup,
  renderTextField,
  renderToggle,
} from 'components/form/field/redux-field';
import { required, validateDate } from 'components/form/validation/validation';
import { ADD_TASK_INCIDENT_FORM } from 'constants/index';
import {
  qreIncidentTypes,
  qreIncidentSubtypes,
  yesNo10,
  qreIncidentSubtypesForIncidentAndComplaints,
} from 'constants/lists';
import { dropdownFormater, dropdownFormaterToFilterOpts } from 'services/utils/common-service';
import { convertToArborDate } from 'models/time/arbor-date';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import compose from 'recompose/compose';
import { initial } from 'lodash';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { fetchResourceLink } from 'actions/action-resource-links';
import { styles } from '../task-detail-styles';
import {
  getContextMapping,
  getContextMappingAll,
  getCategoryMapping,
  filterUsersByResponsibleParties,
  getUserDropdownOptions,
  showDescriptionField,
  INCIDENT_ERROR_TYPE_OTHER,
  getIncidentTypesExceptions,
} from './incident-helper';
import colors from '../../../lib/themes/colors.module.scss';
import { IncidentResponsibleTypes } from '../../../interfaces/redux/task-types/IIncidentTask';

const { deepBlue2, teal } = colors;
const categoriesForNearMiss = [1, 2];

function IncidentForm(props) {
  const {
    selectedPatientId,
    handleSubmit,
    pristine,
    submitting,
    classes,
    lookups,
    formValues,
    buttonIdPrefix,
    edit,
    cancel,
    therapy,
    contextId,
    task,
    addTask, // eslint-disable-line
    editIncident, // eslint-disable-line
    fetchResourceLink, // eslint-disable-line
    taskAddedFromData,
    contextMapping,
    categoryMapping,
    change,
    financials,
    defaultByContext,
    INCIDENT_CATEGORIES,
    INCIDENT_TYPES,
    INCIDENT_REPORTING_PARTIES,
    INCIDENT_EXTERNAL_RESPONSIBLES,
    MEDICATION_ERROR_TYPES,
    fetchTaskCountsAction,
    isMedicationErrorVal,
    selectedIncidentCategory,
    medicationErrorCategoryOpt,
  } = props;

  const radioProps = {
    width: '100%',
    radius: 5,
    checkedColor: deepBlue2,
  };

  const typeRadioProps = {
    width: '100%',
    radius: 15,
    checkedColor: teal,
  };

  const {
    incidentCategories,
    incidentTypes,
    medicationErrorCategories,
    medicationErrorTypes,
    incidentReportingParties,
    incidentExternalResponsibles,
    serviceGroups,
    users,
  } = lookups;

  const dispensingPharmacyName = therapy ? therapy.dispensing_pharmacy_name : null;

  const [showDispensingPharmacy, setShowDispensingPharmacy] = useState(!!edit);
  const [nearMiss, setNearMiss] = useState(false);
  const listOfPharmacies = useSelector(state => state.pharmacies?.pharmacies || []);

  const qreIsIncident =
    INCIDENT_CATEGORIES &&
    ![
      INCIDENT_CATEGORIES.COMPLIMENTS,
      INCIDENT_CATEGORIES.AUDIT,
      INCIDENT_CATEGORIES.COMPLAINTS,
    ].includes(selectedIncidentCategory);
  const qreIsComplaint =
    INCIDENT_CATEGORIES &&
    ![
      INCIDENT_CATEGORIES.COMPLIMENTS,
      INCIDENT_CATEGORIES.AUDIT,
      INCIDENT_CATEGORIES.INCIDENT,
    ].includes(selectedIncidentCategory);
  const qreIsIncidentOrComplaint = qreIsComplaint || qreIsIncident;

  let validIncidentTypeIds = [];
  if (
    INCIDENT_CATEGORIES &&
    selectedIncidentCategory === INCIDENT_CATEGORIES.INCIDENT &&
    contextMapping &&
    contextMapping[contextId]
  ) {
    validIncidentTypeIds = contextMapping[contextId].types;
  } else {
    const categoryMap = categoryMapping[selectedIncidentCategory];
    validIncidentTypeIds = categoryMap ? categoryMap.types : [];
  }
  const validIncidentTypesBeforeIsMedicationError = incidentTypes.filter(type =>
    validIncidentTypeIds.includes(type.id),
  );
  const validIncidentTypesBeforeExceptions =
    qreIsIncident && (isMedicationErrorVal || isMedicationErrorVal === 0)
      ? validIncidentTypesBeforeIsMedicationError.filter(
          type =>
            Number(type.incident_error_type) === Number(isMedicationErrorVal) && type.active === 1,
        )
      : validIncidentTypesBeforeIsMedicationError;
  const validIncidentTypes = getIncidentTypesExceptions(
    validIncidentTypesBeforeExceptions,
    isMedicationErrorVal,
    selectedIncidentCategory,
    { categories: INCIDENT_CATEGORIES, types: INCIDENT_TYPES },
  );
  const changeCategory = categoryId => {
    const { values = {}, types = [] } = categoryMapping[categoryId] || {};
    if (types.length) {
      Object.keys(values).forEach(name => {
        change(name, values[name]);
      });
    } else {
      Object.keys(defaultByContext).forEach(name => {
        change(name, defaultByContext[name]);
      });
    }
    change('type_id', null);
    change('responsibles', []);
    change('details', '');
    change('reporting_party_id', '');
    setNearMiss(false);
  };

  const formatInsurances = insuranceStore => {
    const result = [];
    if (insuranceStore && insuranceStore.data) {
      const insuranceData = insuranceStore.data;
      Object.keys(insuranceData).forEach(insuranceId => {
        const { id, insurance_type: type } = insuranceData[insuranceId];
        result.push({ id, type });
      });
    }
    return result;
  };

  const getPrimaryInsurance = insurances => {
    if (insurances && insurances.data) {
      const primaryInsuranceId = Object.keys(insurances.data).find(
        id => insurances.data[id].insurance_type === 'Primary',
      );
      return insurances.data[primaryInsuranceId];
    }
    return null;
  };

  const { medical_insurances: medicalInsurances, pbm_insurances: pbmInsurances } = financials;

  const primaryMedicalInsurance = getPrimaryInsurance(medicalInsurances);
  const primaryPbmInsurance = getPrimaryInsurance(pbmInsurances);

  const showInternalResponsible = INCIDENT_CATEGORIES && selectedIncidentCategory === INCIDENT_CATEGORIES.AUDIT
    ? false
    : !formValues.is_external || (INCIDENT_CATEGORIES && selectedIncidentCategory === INCIDENT_CATEGORIES.COMPLIMENTS);

  const onInternalExternalChange = value => {
    if (value === 0) {
      change('is_hipaa_breach', null);
    }
    setNearMiss(value === 0);
    change('responsibles', []);
    setShowDispensingPharmacy(false);
  };

  const calcTypeIdTypeText = () => {
    let retText = 'Subtype *';
    switch (selectedIncidentCategory) {
      case INCIDENT_CATEGORIES.COMPLIMENTS:
        retText = 'Compliment Subtype *';
        break;
      case INCIDENT_CATEGORIES.AUDIT:
        retText = 'Audit Subtype *';
        break;
      case INCIDENT_CATEGORIES.INCIDENT:
        retText = 'Incident Subtype *';
        break;
      case INCIDENT_CATEGORIES.COMPLAINTS:
        retText = 'Complaint Subtype *';
        break;
    }
    return retText;
  };

  const onSubmit = values => {
    const payload = {
      patient_id: selectedPatientId,
      therapy_id: therapy ? therapy.id : null,
      service_group_id: values.service_group_id,
      category_id: values.category_id,
      type_id: values.type_id,
      other_type:
        INCIDENT_TYPES && values.type_id === INCIDENT_TYPES.OTHER ? values.other_type : null,
      is_internal: !values.is_external,
      is_medication_error:
        typeof values.is_medication_error === 'boolean'
          ? values.is_medication_error
          : parseInt(values.is_medication_error, 10),
      medication_error_category_id: values.is_medication_error
        ? values.medication_error_category_id
        : null,
      medication_error_type_id: values.is_medication_error ? values.medication_error_type_id : null,
      other_medication_error_type:
        MEDICATION_ERROR_TYPES &&
        values.is_medication_error &&
        values.medication_error_type_id === MEDICATION_ERROR_TYPES.OTHER
          ? values.other_medication_error_type
          : null,
      is_hipaa_breach: values.is_hipaa_breach,
      context_id: contextId,
      reporting_party_id: values.reporting_party_id,
      other_reporting_party:
        INCIDENT_REPORTING_PARTIES && values.reporting_party_id === INCIDENT_REPORTING_PARTIES.OTHER
          ? values.other_reporting_party
          : null,
      followup_dt: convertToArborDate(values.followup_dt).getUtcDatetime(),
      incident_dt: convertToArborDate(values.incident_dt).getUtcDatetime(),
      submitted_dt: convertToArborDate(values.submitted_dt).getUtcDatetime(),
      task_added_from_data: taskAddedFromData || null,
      details: values.details,
      is_patient: therapy && therapy.id ? 0 : 1,
      dispensing_pharmacy_id: values.dispensing_pharmacy_id ?? null,
    };

    if (showInternalResponsible) {
      payload.responsibles = values.responsibles.map(({ value }) => ({
        internal_responsible_id: value,
      }));
    } else {
      payload.responsibles = values.responsibles.map(({ value }) => ({
        external_responsible_id: value,
        other_external_responsible:
          INCIDENT_EXTERNAL_RESPONSIBLES && value === INCIDENT_EXTERNAL_RESPONSIBLES.OTHER
            ? values.other_external_responsible
            : null,
      }));
    }

    payload.responsibles = [
      ...payload.responsibles,
      ...(values.responsible_users || []).map(responsibleUser => ({
        internal_responsible_id: responsibleUser.value,
      })),
    ];

    if (edit) {
      payload.id = task.id;
      payload.reviewed_dt = convertToArborDate(values.reviewed_dt).getUtcDatetime();
      payload.resolved_dt = convertToArborDate(values.resolved_dt).getUtcDatetime();
      editIncident(payload, 'qre');
    } else {
      payload.status_id = 15000;
      // Auto Capture patient's insurance
      payload.patient_medical_insurances = formatInsurances(medicalInsurances);
      payload.patient_pbm_insurances = formatInsurances(pbmInsurances);
      addTask(payload, 'qre').then(() => {
        fetchTaskCountsAction();
        if (taskAddedFromData) {
          fetchResourceLink(taskAddedFromData.task_type, taskAddedFromData.task_id);
        }
      });
    }

    cancel();
  };

  const hangleOnChangeResponsibleParty = responsibleParties => {
    setShowDispensingPharmacy(
      !!dispensingPharmacyName &&
        formValues.is_external &&
        responsibleParties &&
        responsibleParties.some(
          ({ value }) => value === INCIDENT_EXTERNAL_RESPONSIBLES.DISPENSING_PHARMACY,
        ),
    );
  };

  const getSelectedInsuranceInformation = resportingParty => {
    const isMedicalInsurancePayor =
      resportingParty === INCIDENT_REPORTING_PARTIES.PAYOR_MEDICAL_INSURANCE;
    const isPbmInsurancePayor = resportingParty === INCIDENT_REPORTING_PARTIES.PAYOR_PBM_INSURANCE;

    if (isMedicalInsurancePayor) {
      return primaryMedicalInsurance ? primaryMedicalInsurance.policy_holder_sponsor : null;
    }
    if (isPbmInsurancePayor) {
      return primaryPbmInsurance ? primaryPbmInsurance.pbm_name : null;
    }
    return null;
  };

  const [payorInsuranceInformation, setPayorInsuranceInformation] = useState(
    edit ? getSelectedInsuranceInformation(formValues.reporting_party_id) : null,
  );

  const hangleOnChangeReportingParty = resportingParty => {
    setPayorInsuranceInformation(getSelectedInsuranceInformation(resportingParty));
  };

  const responsibleUsers = getUserDropdownOptions(
    filterUsersByResponsibleParties(formValues.responsibles, users),
  );

  const handleFetchInternalPharmacyOptions = React.useMemo(() => {
    const internalPharmacies = listOfPharmacies.length ? listOfPharmacies : [];
    if (internalPharmacies.length === 1) {
      change('dispensing_pharmacy_id', internalPharmacies[0].id);
    }
    return internalPharmacies.map(pharmacy => ({
      id: pharmacy.id,
      name: pharmacy.name,
      display_name: pharmacy.name,
    }));
  }, [listOfPharmacies]);

  const hasOtherReportingParty =
    INCIDENT_REPORTING_PARTIES &&
    formValues.reporting_party_id === INCIDENT_REPORTING_PARTIES.OTHER;

  return (
    <Grid container justifyContent="center" spacing={7}>
      <Grid item xs={12}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.addFormContainer}
          autoComplete="off"
        >
          <Grid container className={classes.formRowContainer}>
            <Grid item xs={12} className={classes.fieldContainer}>
              <Field
                name="category_id"
                className={classes.radioGroup}
                id="category_id"
                radioMap={incidentCategories.map(c => ({
                  label: c.name,
                  value: c.id,
                }))}
                component={renderReactRadioGroup}
                buttonProps={{ ...radioProps }}
                onChange={v => changeCategory(v)}
                validate={[required]}
                width="100%"
                qaId="add_category_id_incident"
              />
            </Grid>
            {INCIDENT_TYPES && showDescriptionField(INCIDENT_TYPES, formValues.type_id) && (
              <Grid item xs={12} className={classes.fieldContainer}>
                <Field
                  name="other_type"
                  label="Description"
                  width="100%"
                  component={renderTextField}
                />
              </Grid>
            )}
            {(qreIsIncident ||
              (qreIsComplaint && (isMedicationErrorVal || !formValues.is_external))) && (
              <>
                <Grid item xs={4} className={classes.fieldContainer}>
                  <Field
                    label="Label *"
                    name="is_external"
                    radioMap={qreIncidentTypes}
                    component={renderReactRadioGroup}
                    buttonProps={{ ...typeRadioProps }}
                    validate={[required]}
                    width="100%"
                    qaId="add_qre_incident_type"
                    onChange={value => onInternalExternalChange(value)}
                  />
                </Grid>
                <Grid item xs={4} className={classes.fieldContainer}>
                  {INCIDENT_CATEGORIES &&
                  [INCIDENT_CATEGORIES.INCIDENT].includes(selectedIncidentCategory) ? (
                    <Field
                      label="Error Type *"
                      name="is_medication_error"
                      validate={[required]}
                      component={renderDropdown}
                      fields={qreIncidentSubtypesForIncidentAndComplaints}
                    />
                  ) : (
                    <Field
                      label="Error Type *"
                      name="is_medication_error"
                      radioMap={qreIncidentSubtypes}
                      component={renderReactRadioGroup}
                      buttonProps={{ ...typeRadioProps }}
                      validate={[required]}
                      width="100%"
                      qaId="add_qre_incident_subtype"
                    />
                  )}
                </Grid>
              </>
            )}
            {qreIsIncidentOrComplaint && (
              <Grid item xs={4} className={classes.fieldContainer}>
                <Field
                  label="Potential PHI Exposure *"
                  name="is_hipaa_breach"
                  disabled={
                    [INCIDENT_CATEGORIES.COMPLAINTS, INCIDENT_CATEGORIES.INCIDENT].includes(
                      selectedIncidentCategory,
                    ) && nearMiss === true
                  }
                  radioMap={yesNo10}
                  component={renderReactRadioGroup}
                  buttonProps={{ ...typeRadioProps }}
                  validate={nearMiss === true ? [] : [required]}
                  width="100%"
                  qaId="add_qre_incident_phi"
                />
              </Grid>
            )}
            {qreIsComplaint && !isMedicationErrorVal && isMedicationErrorVal !== 0 && (
              <Grid item xs={8} className={classes.fieldContainer} />
            )}

            {Number(isMedicationErrorVal) === 1 && (
              <>
                <Grid item xs={12} className={classes.fieldContainer}>
                  <Field
                    name="medication_error_category_id"
                    label="Medication Error Category *"
                    validate={[required]}
                    component={renderDropdown}
                    fields={dropdownFormaterToFilterOpts(edit ? medicationErrorCategories : medicationErrorCategories.reduce((acc, it) => {
                      if (medicationErrorCategoryOpt.includes(it.id)) {
                        acc.push(it);
                      }
                      return acc;
                    }, []), medicationErrorCategoryOpt)}
                    disabled={typeof formValues.is_external === 'boolean'}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={hasOtherReportingParty ? 3 : 4} className={classes.fieldContainer}>
              <Field
                name="type_id"
                label={calcTypeIdTypeText()}
                validate={[required]}
                component={renderDropdown}
                fields={validIncidentTypes
                  .map(t => ({
                    value: t.id,
                    label: t.name,
                    isDisabled: !t.active,
                  }))
                  .filter(t => !t.isDisabled || (t.value === formValues.type_id && t.isDisabled))}
              />
            </Grid>

            <Grid item xs={hasOtherReportingParty ? 3 : 4} className={classes.fieldContainer}>
              <>
                <Field
                  name="reporting_party_id"
                  label="Reporting Party  *"
                  validate={[required]}
                  component={renderDropdown}
                  fields={incidentReportingParties.map(incidentReportingParty => {
                    return {
                      label: incidentReportingParty.name,
                      value: incidentReportingParty.id,
                      isDisabled: incidentReportingParty.system_only,
                    };
                  })}
                  onChange={hangleOnChangeReportingParty}
                />
                {!!payorInsuranceInformation && (
                  <Typography variant="subtitle1">{payorInsuranceInformation}</Typography>
                )}
              </>
            </Grid>
            {hasOtherReportingParty && (
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  name="other_reporting_party"
                  label="Other Reporting Party *"
                  component={renderTextField}
                  validate={[required]}
                />
              </Grid>
            )}
            <Grid item xs={hasOtherReportingParty ? 3 : 4} className={classes.fieldContainer}>
              <>
                <Field
                  id="responsibles"
                  name="responsibles"
                  label="Responsible Party *"
                  fields={
                    showInternalResponsible
                      ? users.map(item => ({
                          value: item.id,
                          label: item.display_name || item.username,
                          isDisabled: item.hide_from_ui,
                        }))
                      : dropdownFormaterToFilterOpts(
                          incidentExternalResponsibles,
                          [11, 12],
                          { isDisabled: 'disabled' },
                          true,
                        )
                  }
                  validate={[required]}
                  component={ReactSelectForRedux}
                  onChange={hangleOnChangeResponsibleParty}
                />
                {showDispensingPharmacy && (
                  <Typography variant="subtitle1">{dispensingPharmacyName}</Typography>
                )}
              </>
            </Grid>
            {Boolean(formValues.responsibles?.length) && Boolean(responsibleUsers?.length) && (
              <>
                <Grid item xs={6} />
                <Grid item xs={6} className={classes.fieldContainer}>
                  <Field
                    id="responsible_users"
                    name="responsible_users"
                    label="Responsible Users *"
                    fields={responsibleUsers}
                    validate={[required]}
                    component={ReactSelectForRedux}
                  />
                </Grid>
              </>
            )}
            {!!formValues.is_external &&
              Array.isArray(formValues.responsibles) &&
              formValues.responsibles.some(
                r =>
                  INCIDENT_EXTERNAL_RESPONSIBLES &&
                  r.value === INCIDENT_EXTERNAL_RESPONSIBLES.OTHER,
              ) && (
                <Grid item xs={6} className={classes.fieldContainer}>
                  <Field
                    name="other_external_responsible"
                    label="Other Responsible *"
                    component={renderTextField}
                    validate={[required]}
                  />
                </Grid>
              )}
            <Grid item xs={4} className={classes.fieldContainer}>
              <Field
                name="service_group_id"
                label="Service Group *"
                validate={[required]}
                component={renderDropdown}
                fields={dropdownFormater(serviceGroups, {
                  name: 'display_name',
                })}
              />
            </Grid>
            {[INCIDENT_CATEGORIES.INCIDENT, INCIDENT_CATEGORIES.COMPLAINTS].includes(
              selectedIncidentCategory,
            ) && (
              <Grid item xs={4} className={classes.fieldContainer}>
                <Field
                  name="dispensing_pharmacy_id"
                  label="Dispensing Pharmacy *"
                  validate={handleFetchInternalPharmacyOptions.length > 1 ? [required] : []}
                  component={renderDropdown}
                  fields={dropdownFormater(handleFetchInternalPharmacyOptions, {
                    name: 'display_name',
                  })}
                />
              </Grid>
            )}
            <Grid item xs={4} className={classes.fieldContainer}>
              <Field
                name="followup_dt"
                label="Follow up Date *"
                component={renderDatePicker}
                validate={[validateDate, required]}
              />
            </Grid>
            <Grid item xs={4} className={classes.fieldContainer}>
              <Field
                name="incident_dt"
                label="Date *"
                component={renderDatePicker}
                validate={[validateDate, required]}
              />
            </Grid>
            <Grid item xs={4} className={classes.fieldContainer}>
              <Field
                name="submitted_dt"
                label="Submitted Date *"
                component={renderDatePicker}
                validate={[validateDate, required]}
              />
            </Grid>
            {edit && (
              <>
                <Grid item xs={6} className={classes.fieldContainer}>
                  <Field
                    name="reviewed_dt"
                    label="Reviewed Date"
                    component={renderDatePicker}
                    validate={[validateDate]}
                  />
                </Grid>
                <Grid item xs={6} className={classes.fieldContainer}>
                  <Field
                    name="resolved_dt"
                    label="Resolved Date"
                    component={renderDatePicker}
                    validate={[validateDate]}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} className={classes.fieldContainer}>
              <Grid item className={classes.detailFieldContainer}>
                <Field
                  name="details"
                  label={
                    [INCIDENT_CATEGORIES.INCIDENT, INCIDENT_CATEGORIES.COMPLAINTS].includes(
                      selectedIncidentCategory,
                    )
                      ? 'Details *'
                      : 'Details'
                  }
                  component={renderTextField}
                  validate={
                    [INCIDENT_CATEGORIES.INCIDENT, INCIDENT_CATEGORIES.COMPLAINTS].includes(
                      selectedIncidentCategory,
                    )
                      ? [required]
                      : undefined
                  }
                  width="100%"
                  multiline
                  rows={3}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
              <Divider />
            </Grid>
          </Grid>

          {/* Eighth Row */}
          <ConfirmationPanel
            cancelButtonName="add_incident_cancel_button"
            submitButtonName="add_incident_submit_button"
            handleCancel={cancel}
            disableSubmit={pristine || submitting}
            buttonIdPrefix={buttonIdPrefix}
          />
        </form>
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state, props) {
  const { taskStatuses, selectedPatientId, lookups, financials } = state;
  const { tagTypeId } = props;
  const { task } = props;
  const contextId = task ? task.context_id : tagTypeId;
  const formValues = getFormValues(ADD_TASK_INCIDENT_FORM)(state) || {};
  const {
    CONSTANTS: {
      INCIDENT_CATEGORIES,
      INCIDENT_TYPES,
      INCIDENT_REPORTING_PARTIES,
      INCIDENT_EXTERNAL_RESPONSIBLES,
      MEDICATION_ERROR_TYPES,
    },
  } = lookups;
  const selectedIncidentCategory = formValues.category_id;
  const isMedicationErrorVal = formValues.is_medication_error;
  const contextMapping = INCIDENT_TYPES ? getContextMappingAll(INCIDENT_TYPES) : {};
  const categoryMapping =
    INCIDENT_CATEGORIES && INCIDENT_TYPES
      ? getCategoryMapping(INCIDENT_CATEGORIES, INCIDENT_TYPES)
      : {};
  const defaultByContext = contextMapping[contextId] ? contextMapping[contextId].default : {};
  const initialValues = {
    category_id: INCIDENT_CATEGORIES ? INCIDENT_CATEGORIES.AUDIT : null,
    followup_dt: convertToArborDate(new Date()).getCustomerDate(true),
    incident_dt: convertToArborDate(new Date()).getCustomerDate(true),
    submitted_dt: convertToArborDate(new Date()).getCustomerDate(true),
    ...defaultByContext,
  };
  let medicationErrorCategoryOpt = [];
  if (INCIDENT_CATEGORIES && [INCIDENT_CATEGORIES.INCIDENT].includes(selectedIncidentCategory) && typeof isMedicationErrorVal !== 'boolean' && Number(isMedicationErrorVal) === 1 && typeof formValues.is_external !== 'boolean') {
    medicationErrorCategoryOpt = formValues.is_external === 0
      ? categoriesForNearMiss
      : lookups.medicationErrorCategories.reduce((acc, it) => {
        if (!categoriesForNearMiss.includes(it.id)) {
          acc.push(it.id);
        }
        return acc;
      }, []);
  }
  if (task) {
    const isExternal = !task.is_internal ? 1 : 0;
    const otherResponsible =
      Array.isArray(task.responsibles) && task.responsibles.find(res => !!res.other);
    initialValues.category_id = task.category_id;
    initialValues.service_group_id = task.service_group_id;
    initialValues.dispensing_pharmacy_id = task.dispensing_pharmacy_id;
    initialValues.is_external = isExternal;
    initialValues.is_medication_error = task.is_medication_error;
    initialValues.medication_error_category_id = task.medication_error_category_id;
    initialValues.medication_error_type_id = task.medication_error_type_id;
    initialValues.other_medication_error_type = task.other_medication_error_type;
    initialValues.is_hipaa_breach = task.is_hipaa_breach ? '1' : '0';
    initialValues.type_id = task.type_id;
    initialValues.other_type = task.other_type;
    initialValues.reporting_party_id = task.reporting_party_id;
    initialValues.other_reporting_party = task.other_reporting_party;
    initialValues.details = task.details;

    initialValues.followup_dt = convertToArborDate(task.followup_dt).getCustomerDate(true);
    initialValues.incident_dt = convertToArborDate(task.incident_dt).getCustomerDate(true);
    initialValues.submitted_dt = convertToArborDate(task.submitted_dt).getCustomerDate(true);
    initialValues.reviewed_dt = convertToArborDate(task.reviewed_dt).getCustomerDate(true);
    initialValues.resolved_dt = convertToArborDate(task.resolved_dt).getCustomerDate(true);

    initialValues.responsibles = task.responsibles
      ? task.responsibles
          // If is external incident responsible users shouldn't be shown in the responsible section
          .filter(
            responsible =>
              !(isExternal && Boolean(responsible.type === IncidentResponsibleTypes.User)),
          )
          .map(responsible => ({
            value: responsible.identifier,
            label: responsible.display,
          }))
      : [];

    initialValues.other_external_responsible = otherResponsible ? otherResponsible.other : null;

    // If is external incident users should be shown in the responsible users section
    if (isExternal) {
      initialValues.responsible_users = task.responsibles
        .filter(responsible => Boolean(responsible.type === IncidentResponsibleTypes.User))
        .map(responsible => ({
          value: responsible.identifier,
          label: responsible.display,
        }));
    }
  } else {
    initialValues.is_external = 1;
  }

  return {
    taskStatuses,
    selectedPatientId,
    contextId,
    financials,
    lookups,
    formValues,
    initialValues,
    contextMapping,
    categoryMapping,
    defaultByContext,
    INCIDENT_TYPES,
    INCIDENT_CATEGORIES,
    INCIDENT_REPORTING_PARTIES,
    INCIDENT_EXTERNAL_RESPONSIBLES,
    MEDICATION_ERROR_TYPES,
    isMedicationErrorVal,
    selectedIncidentCategory,
    medicationErrorCategoryOpt,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    fetchTaskCountsAction: fetchTaskCounts,
    addTask,
    editIncident,
    fetchResourceLink,
    workListChanged,
  }),
)(reduxForm({ form: ADD_TASK_INCIDENT_FORM })(IncidentForm));

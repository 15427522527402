import React, { useState } from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import FieldLabel from 'components/form/field/field-label';
import Validation from 'components/form/validation/validation';
import colors from 'lib/themes/colors.module.scss';
import useProvider from 'hooks/useProvider';

const { deepBlue2, grey14, grey12, steelGrey, white } = colors;

const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    display: 'flex',
    marginTop: theme.spacing(1),
  },
  button: {
    backgroundColor: grey14,
    color: steelGrey,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.7),
    display: 'inline',
    marginRight: theme.spacing(1.5),
    cursor: 'pointer',
  },
  selected: {
    '&:hover': {
      backgroundColor: deepBlue2,
    },
    'backgroundColor': deepBlue2,
    'color': white,
  },
  disabled: {
    backgroundColor: grey14,
    color: grey12,
    cursor: 'default',
  },
}));

export const Pills = ({
  fields,
  input,
  className,
  meta,
  meta: { touched, error, warning },
  label,
  ...props
}) => {
  const [values, setValues] = useState(input.value || []);
  const classes = useStyles();

  const handleClick = value => {
    let newValues;
    if (values.includes(value)) {
      newValues = values.filter(v => v !== value);
    } else {
      newValues = [...values, value];
    }
    setValues(newValues);
    input.onChange(newValues);
    input.onBlur();
    return false;
  };

  return (
    <div className={cx(classes.root, className)} {...props}>
      <FieldLabel variant="caption" fieldName={label} />
      <div className={classes.container}>
        {fields.map(field => (
          // eslint-disable-next-line
          <div
            role="button"
            key={field.id}
            onClick={() => !field.disabled && handleClick(field.id)}
            className={cx(
              classes.button,
              field.disabled ? classes.disabled : null,
              values.includes(field.id) ? classes.selected : classes.unSelected,
            )}
          >
            {field.name}
          </div>
        ))}
      </div>
      <Validation touched={touched} error={error} warning={warning} />
    </div>
  );
};

export default ({ providers, field, label, input, meta, disabled, labelSize, qaId }) => {
  const fields = useProvider(providers, field.provider, []);

  return (
    <Pills
      label={label}
      input={input}
      meta={meta}
      disabled={disabled}
      labelSize={labelSize}
      qaId={qaId}
      fields={fields}
    />
  );
};

import React from 'react';
import { Box, Chip, Grid, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import DetailField from 'components/form/field/field';
import { buildQaId } from 'utils/build-qa-id';
import { nameOfFactory } from 'utils/types-util';
import { IFillCoordinationEditFormFields } from 'containers/tasks/fill-coordination/types';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { getPaymentTypeLabel } from 'containers/tasks/fill-coordination/fc-util';
import { ShippingStyles } from './shipping.styles';
import { IPaymentInformation } from './types';

const nameOfFormFields = nameOfFactory<IFillCoordinationEditFormFields>();

interface IShippingProps extends IPaymentInformation, WithStyles<typeof ShippingStyles> {}
const getQaId = buildQaId('shipping.payment-information', '.');

const PaymentInformation = (props: IShippingProps): JSX.Element | null => {
  const { classes } = props;

  const currentCopayAmount = props.currentPaymentInformation?.copayAmount;
  const currentOrderNotes = props.currentPaymentInformation?.orderNotes;
  const currentPaymentOnFile = props.currentPaymentInformation?.paymentMethodOnFile;
  const paymentMethods = props.currentPaymentInformation?.paymentMethods;
  const paymentMethodTypes = useTypedSelector(state => state.lookups.paymentMethodTypes);

  const displayPaymentMethodOnFile = (): JSX.Element | null => {
    if (currentPaymentOnFile || paymentMethods?.length) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginRight: 'auto',
            padding: '16px',
            backgroundColor: '#456189',
          }}
          borderRadius="3px"
        >
          <Typography color="white" fontWeight="600">
            Payment Method On File
          </Typography>
          {paymentMethods?.map((paymentMethod, i) => (
            <Typography color="white">
              {`${['1st', '2nd', '3rd', '4th'][i]}: ${getPaymentTypeLabel(
                paymentMethod,
                paymentMethodTypes,
              )} ${paymentMethod.card_num}`}
            </Typography>
          ))}
        </Box>
      );
    }
    return (
      <Grid item xs={8}>
        <Chip
          className={classes.paymentMethodOnFile}
          color="primary"
          label="Payment method on file"
        />
      </Grid>
    );
  };

  const displayCopayAmount = (): JSX.Element | null => {
    if (currentCopayAmount) {
      return (
        <Grid item xs={8} data-qa-id={getQaId(nameOfFormFields('copay_amount'))}>
          <DetailField
            fieldName="Copay Amount"
            field={`$${Number(currentCopayAmount).toFixed(2)}`}
          />
        </Grid>
      );
    }
    return <Grid />;
  };

  const displayOrderNotes = (): JSX.Element | null => {
    if (currentOrderNotes) {
      return (
        <Grid item xs={8} data-qa-id={getQaId(nameOfFormFields('order_notes'))}>
          <DetailField fieldName="Order Notes" field={currentOrderNotes} />
        </Grid>
      );
    }
    return <Grid />;
  };

  return (
    <Grid className={classes.sectionBottomMargin}>
      <Grid item xs={12} className={classes.paymentInformation}>
        <Grid container className={classes.subsection}>
          {displayPaymentMethodOnFile()}
        </Grid>
        <Grid container className={classes.subsection}>
          {displayCopayAmount()}
        </Grid>
        <Grid container className={classes.subsection}>
          {displayOrderNotes()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(ShippingStyles)(PaymentInformation);

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

const styles = (theme: Theme) => {
  const { typography } = expandTheme(theme);
  return createStyles({
    root: {
      fontFamily: typography.fontFamily,
      padding: theme.spacing(1),
    },
    dateRangeGroup: {
      fontFamily: typography.fontFamily,
      fontSize: typography.fontSize,
      paddingTop: theme.spacing(3),
    },
    tasksWrapper: {
      padding: theme.spacing(1),
    },
  });
};

export { styles };

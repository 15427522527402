import {
  FETCH_RECONCILIATION_PATIENT_SUCCESS,
  FETCH_RECONCILIATION_MEDICATION_SUCCESS,
  FETCH_RECONCILIATION_ALLERGY_SUCCESS,
  RESOLVE_RECONCILIATION_MEDICATION_REQUEST,
  RESOLVE_RECONCILIATION_MEDICATION_SUCCESS,
  TOGGLE_RECONCILIATION_MODAL,
  FETCH_RECONCILIATIONS_REQUEST,
  RESOLVE_RECONCILIATION_PATIENT_REQUEST,
  LINK_RECONCILIATION_MEDICATION_REQUEST,
  RESOLVE_RECONCILIATION_ALLERGY_SUCCESS,
} from 'constants/index';
import {
  processCommunicationArray,
  checkPatientBasicInfoChange,
  removeExcludedChangesForPatient,
} from 'services/utils/reconciliation-service';
import { isNotEmptyObject } from 'services/utils/common-service';
import { convertToArborDate } from 'models/time/arbor-date';
import { getMedicationStrengthStr } from './helper-reducer';

const initialState = {
  isLoading: false,
  reconciliationModalOpen: false,
  sourceNames: [],
  stagingMedications: [],
  stagingPatient: {},
  stagingPatientCards: [],
  stagingAllergies: [],
};

function convertMedication(data) {
  if (!data) return null;
  data.original_start_dt = data.start_dt;
  data.original_end_dt = data.end_dt;
  data.start_dt = convertToArborDate(data.start_dt, true).getUtcDate(true);
  data.end_dt = convertToArborDate(data.end_dt, true).getUtcDate(true);
  data.strength_str = getMedicationStrengthStr(data);
  return data;
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_RECONCILIATION_MODAL: {
      if (payload || payload === false) {
        return {
          ...state,
          reconciliationModalOpen: payload,
        };
      }
      return state;
    }
    case FETCH_RECONCILIATIONS_REQUEST: {
      return {
        ...state,
        sourceNames: [],
        stagingMedications: [],
        stagingAllergies: [],
        stagingPatient: {},
        isLoading: true,
      };
    }
    case LINK_RECONCILIATION_MEDICATION_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case RESOLVE_RECONCILIATION_MEDICATION_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case RESOLVE_RECONCILIATION_PATIENT_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case RESOLVE_RECONCILIATION_MEDICATION_SUCCESS: {
      const reconciliationListFiltered = state.stagingMedications.filter(
        med => med.source_medication_id !== payload.source_medication_id,
      );
      return {
        ...state,
        stagingMedications: reconciliationListFiltered,
        isLoading: false,
      };
    }
    case RESOLVE_RECONCILIATION_ALLERGY_SUCCESS: {
      const reconciliationListFiltered = state.stagingAllergies.filter(
        allergy => allergy.allergen.id !== payload.allergen_id,
      );
      return {
        ...state,
        stagingAllergies: reconciliationListFiltered,
        isLoading: false,
      };
    }
    case FETCH_RECONCILIATION_ALLERGY_SUCCESS: {
      return {
        ...state,
        stagingAllergies: payload.stagingAllergies,
        isLoading: false,
      };
    }
    case FETCH_RECONCILIATION_MEDICATION_SUCCESS: {
      const newSourceNames = [...state.sourceNames];
      payload.stagingMedications.forEach(m => {
        newSourceNames.push(m.source_name);
      });
      return {
        ...state,
        stagingMedications: payload.stagingMedications.map(m => convertMedication(m)),
        sourceNames: [...new Set(newSourceNames)],
        isLoading: false,
      };
    }
    case FETCH_RECONCILIATION_PATIENT_SUCCESS: {
      const { patient, stagingPatient } = payload;
      if (patient && isNotEmptyObject(stagingPatient)) {
        const cards = [];
        const newSourceNames = [...state.sourceNames];
        newSourceNames.push(stagingPatient.source_name);
        removeExcludedChangesForPatient(patient, stagingPatient);
        if (checkPatientBasicInfoChange(patient, stagingPatient)) {
          cards.push('patient');
        }
        const addressReconcileInfo = processCommunicationArray(
          'addresses',
          patient.filteredAddresses,
          stagingPatient.addresses,
        );
        if (addressReconcileInfo.showCard) {
          cards.push('address');
        }
        const emailReconcileInfo = processCommunicationArray(
          'emails',
          patient.filteredEmails,
          stagingPatient.emails,
        );
        if (emailReconcileInfo.showCard) {
          cards.push('email');
        }
        const phoneReconcileInfo = processCommunicationArray(
          'phones',
          patient.filteredPhones,
          stagingPatient.phones,
        );
        if (phoneReconcileInfo.showCard) {
          cards.push('phone');
        }
        return {
          ...state,
          sourceNames: [...new Set(newSourceNames)],
          stagingPatient: {
            ...stagingPatient,
            addressReconcileInfo,
            emailReconcileInfo,
            phoneReconcileInfo,
          },
          stagingPatientCards: cards,
          isLoading: false,
        };
      }
      return {
        ...state,
        stagingPatientCards: [],
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

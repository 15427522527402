import {
  DATA_TASK_COUNTS_REQUEST,
  DATA_TASK_COUNTS_SUCCESS,
  DATA_TASK_COUNTS_FAILURE,
  DUR,
  MARK_SMS_AS_READ_SUCCESS,
  FETCH_SPECIFIC_QUEUE_TASK_COUNT_SUCCESS,
} from 'constants/index';
import { getReducerDataInitialState } from './helper-reducer';

const initialState = getReducerDataInitialState({
  loading: true,
  data: {
    SMS: {
      task_name: 'SMS',
      task_description: 'SMS Messages',
      task_cnt: 0,
    },
    therapies: {
      task_name: 'therapies',
      task_description: 'Therapies',
      task_cnt: 0,
    },
    PA: {
      task_name: 'PA',
      task_description: 'Prior Authorization',
      task_cnt: 0,
    },
    FA: {
      task_name: 'FA',
      task_description: 'Financial Assistance',
      task_cnt: 0,
    },
    FC: { task_name: 'FC', task_description: 'Fill Coordination', task_cnt: 0 },
    FDC: {
      task_name: 'FDC',
      task_description: 'Fill Delivery Confirmation',
      task_cnt: 0,
    },
    RS: { task_name: 'RS', task_description: 'Risk Strat', task_cnt: 0 },
    DC: { task_name: 'DC', task_description: 'Data Collection', task_cnt: 0 },
    TPR: {
      task_name: 'TPR',
      task_description: 'Third Party Referral',
      task_cnt: 0,
    },
    INT: { task_name: 'INT', task_description: 'Intervention', task_cnt: 0 },
    CSL: { task_name: 'CSL', task_description: 'Counseling', task_cnt: 0 },
    MR: { task_name: 'MR', task_description: 'Medication Review', task_cnt: 0 },
    DUR: { task_name: DUR, task_description: 'Drug Utilization Review', task_cnt: 0 },
    AR: {
      task_name: 'AR',
      task_description: 'Appointment Referral',
      task_cnt: 0,
    },
    OR: { task_name: 'OR', task_description: 'Outreach', task_cnt: 0 },
    QRE: { task_name: 'QRE', task_description: 'Quality Related Event', task_cnt: 0 },
    STAR: { task_name: 'STAR', task_description: 'Starred Patients', task_cnt: 0 },
  },
});

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case DATA_TASK_COUNTS_REQUEST:
      return {
        loading: true,
        loaded: false,
        data: { ...state.data },
      };
    case DATA_TASK_COUNTS_SUCCESS:
      return {
        loading: false,
        loaded: true,
        data: Object.keys(initialState.data).reduce(
          (acc, taskType) => {
            if (payload[taskType]) {
              acc[taskType] = payload[taskType];
            } else {
              acc[taskType] = initialState.data[taskType];
            }
            return acc;
          },
          { ...state.data },
        ),
      };
    case FETCH_SPECIFIC_QUEUE_TASK_COUNT_SUCCESS: {
      return {
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          [action.payload.queueType]: action.payload.count[action.payload.queueType],
        },
      };
    }
    case DATA_TASK_COUNTS_FAILURE: {
      return {
        loading: false,
        loaded: false,
        data: { ...initialState.data, ...state.data },
        error: true,
      };
    }
    case MARK_SMS_AS_READ_SUCCESS: {
      // eslint-disable-next-line camelcase
      if (state.data?.SMS?.task_cnt > 0) {
        return {
          ...state,
          data: {
            ...state.data,
            SMS: {
              ...state.data.SMS,
              task_cnt: state.data.SMS.task_cnt - 1,
            },
          },
        };
      }
      return state;
    }
    default:
      return state;
  }
};

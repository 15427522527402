import { Theme, useTheme } from '@mui/material/styles';
import { expandTheme } from 'utils/theme-util';

export const getAccreditationColors = (theme: Theme) => {
  const { palette } = expandTheme(theme);
  return {
    yellow: palette.primary.yellow,
    yellowLight: 'rgba(237, 204, 124, 0.2)',
    bodyText: palette.primary.steelGrey,
    titleText: '#464448', // palette.primary.adherenceIntHistoryTitleGrey, // #464448
    buttonText: '#44484C',
    lightText: '#B6B7B9',
    infoText: '#70777A',
    labelText: palette.primary.rollingStone,
    gray: '#F5F5F5',
    separatorGray: '#CCCCCC',
    chipGray: '#F2F4F4',
    chipGrayDark: '#C4C4C4',
    borderGray: '#E2E2E2',
    buttonBorderGray: '#C4C4C4',
    textAreaBorder: '#ABABAB',
    green: palette.primary.teal,
    greenDark: palette.primary.patina,
    blue: palette.primary.trellisBlue,
    blueLight: 'rgba(93, 135, 175, 0.5)',
    blueSolid: 'rgba(93, 135, 175, 1)',
    blueDark: palette.primary.deepBlue,
    infoBg: 'rgba(238, 244, 250, 1)',
    red: palette.primary.darkRed,
    redLight: palette.primary.lightRed,
    redWhite: palette.primary.redWhite,
    white: palette.primary.white,
  };
};

export const useColors = () => getAccreditationColors(useTheme());

export const styles = {
  questionnaireRow: {
    'flex': '100%',
    'display': 'block',
    'boxSizing': 'border-box',
    'padding': '16px 0',
    'borderBottom': '1px solid #ccc',
    '&:last-child': {
      borderBottom: 0,
    },
    '& .questionnaire-row': {
      marginRight: 0,
      borderBottom: 0,
      padding: 0,
    },
  },
  questionnaireRowInner: {
    'display': 'flex',
    'flexFlow': 'row nowrap',
    'justifyContent': 'space-between',
    'alignItems': 'baseline',
    '& .questionnaire-answer': {
      flex: '0 0 25%',
      marginLeft: 'auto',
      width: '25%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    '&.TextAnswer:not(.inline), &.CheckboxAnswer, &.SelectAnswer.multiselect.readOnly, &.DocumentAnswer':
      {
        'flexFlow': 'column nowrap',
        '& .questionnaire-answer': {
          flex: '1 1 100%',
          width: '100%',
          marginTop: '8px',
        },
      },
    '&.DocumentAnswer': {
      '& .file-upload-container-inner': {
        padding: '8px',
      },
    },
  },
  questionnaireQuestion: {
    flex: '1 1 auto',
    marginRight: '16px',
    color: '#44484C',
  },
  answerFullWidth: {
    flex: '1 1 100%',
    marginTop: '8px',
  },
  questionnaireHint: {
    flexBasis: '100%',
  },
  documentUploadedContainer: {
    border: '1px solid rgba(93, 135, 175, 1)',
    padding: '18px',
    borderRadius: '4px',
  },
};

import React from 'react';
import { IPhoneDemographicInfoProps } from 'components/patient-merge-modal/interfaces';
import { Grid, Typography } from '@mui/material';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { useTheme } from '@mui/styles';

export const PhoneDemographicInfo = (props: IPhoneDemographicInfoProps) => {
  const { phone } = props;
  const theme = useTheme();
  const { checkboxInfoLabel, checkboxInfoValue, checkboxTitle, infoTag } =
    PatientMergeStyles(theme);
  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <Typography sx={checkboxTitle}>Phone</Typography>
        {phone?.preferred && <Typography sx={infoTag}>Preferred</Typography>}
      </Grid>
      <Grid item xs={6}>
        <Typography sx={checkboxInfoLabel}>Type</Typography>
        <Typography sx={checkboxInfoValue}>{phone.use ?? '-'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={checkboxInfoLabel}>Number</Typography>
        <Typography sx={checkboxInfoValue}>{phone.value ?? '-'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={checkboxInfoLabel}>Available</Typography>
        <Typography sx={checkboxInfoValue}>{phone.period ?? '-'}</Typography>
      </Grid>
    </Grid>
  );
};

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { fetchDaysShippingRequest, addWeekday } from 'actions/action-shipping';
import { useDispatch, useSelector } from 'react-redux';
import { StringUtils } from 'utils/string-utils';
import { IDayAreaShipping, weekdayOrder } from './day-area-shipping.interface';
import DayAreaShipping from './day-area-shipping';

export default (): JSX.Element => {
  const [selectedCustomerId, setSelectedCustomerId] = useState(0);
  const [showAddWeekdayModal, setShowAddWeekdayModal] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [daysShipping, setDaysShipping] = useState<IDayAreaShipping[]>([]);
  const [loading, setLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const dispatch = useDispatch();
  const memorizedDaysShipping = useMemo(
    () =>
      daysShipping.sort((a, b) => {
        const orderA = weekdayOrder.indexOf(a.weekday);
        const orderB = weekdayOrder.indexOf(b.weekday);
        return orderA - orderB;
      }),
    [daysShipping],
  );
  const { daysShipping: customerDaysShipping } = useSelector((state: any) => state.shipping);

  const fetchDaysShipping = useCallback(async () => {
    setLoading(true);
    dispatch(fetchDaysShippingRequest(selectedCustomerId));
  }, [selectedCustomerId]);

  const submitRequestWeekday = useCallback(
    (data: IDayAreaShipping) => {
      setShowAddWeekdayModal(false);
      dispatch(addWeekday(data, selectedCustomerId));
    },
    [selectedCustomerId],
  );

  useEffect(() => {
    if (customerDaysShipping && isMounted) {
      setDaysShipping(customerDaysShipping);
    }
  }, [customerDaysShipping, isMounted]);

  useEffect(() => {
    if (isMounted && selectedCustomerId !== 0) {
      fetchDaysShipping();
    } else {
      setIsMounted(true);
    }
  }, [fetchDaysShipping, isMounted, selectedCustomerId]);

  useEffect(() => {
    if (memorizedDaysShipping) {
      setLoading(false);
    }
  }, [memorizedDaysShipping]);

  const handleModalAddWeekday = () => {
    setShowAddWeekdayModal(!showAddWeekdayModal);
  };

  const handleSnackbar = () => {
    setShowSnackbar(!showSnackbar);
  };

  return (
    <DayAreaShipping
      loading={loading}
      isMounted={isMounted}
      showSnackbar={showSnackbar}
      handleSnackbar={handleSnackbar}
      snackbarMessage={snackbarMessage}
      setSnackbarMessage={setSnackbarMessage}
      daysShipping={memorizedDaysShipping}
      selectedCustomerId={selectedCustomerId}
      setSelectedCustomerId={setSelectedCustomerId}
      handleModalAddWeekday={handleModalAddWeekday}
      showAddWeekdayModal={showAddWeekdayModal}
      submitRequestWeekday={submitRequestWeekday}
    />
  );
};

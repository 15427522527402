import React from 'react';
import withStyles from '@mui/styles/withStyles';
import ScheduleList from 'containers/schedule/schedule-list/schedule-list';
import { Switch, Route } from 'react-router-dom';
import { styles } from '../nav-styles';

const Schedule = ({ classes }) => (
  <div className={classes.scheduleListContent}>
    <Switch>
      <Route component={ScheduleList} />
    </Switch>
  </div>
);

export default withStyles(styles, { withTheme: true })(Schedule);

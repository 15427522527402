import { AxiosPromise } from 'axios';
import { TypedHttp } from 'services/typed-http';
import {
  IDocumentInfo,
  IPatientMerge,
  IProfileDemographicInfo,
  IProfileTherapy,
  IProfileClinicalInfo,
  IProfileIncomingBenefitsInfo,
  IPatientMergeTask,
} from 'components/patient-merge-modal/interfaces';
import { IPatientMergeInput } from 'actions/action-patient-merge';
import { LookupResourceTypes } from 'constants/enums';

const buildUri = (mergeId: number) => `/merged-profile/${mergeId}`;

export class PatientMergeClient {
  static getMergeDemographicInfo = (mergeId: number): AxiosPromise<IProfileDemographicInfo[]> => {
    return TypedHttp.GetPromise<IProfileDemographicInfo[]>(
      `${buildUri(mergeId)}/section/demographics`,
    );
  };

  static loadPatientMerge = (patientId: number): AxiosPromise<IPatientMerge> => {
    return TypedHttp.PutPromise<{}, IPatientMerge>(`/patients/${patientId}/merged-profiles`, {});
  };

  static setAsNotDuplicated = (duplicateIds: number[]): AxiosPromise<IPatientMerge> => {
    return TypedHttp.PutPromise<{}, IPatientMerge>('/merged-profile/not-duplicated', {
      duplicateIds,
    });
  };

  static getMergeTherapyInfo = (mergeId: number): AxiosPromise<IProfileTherapy[]> => {
    return TypedHttp.GetPromise<IProfileTherapy[]>(`${buildUri(mergeId)}/section/therapies`);
  };

  static getMergeDocumentInfo = (mergeId: number): AxiosPromise<IDocumentInfo[]> => {
    return TypedHttp.GetPromise<IDocumentInfo[]>(`${buildUri(mergeId)}/section/documents`);
  };

  static getMergeIncomeAndBenefitInfo = (
    mergeId: number,
  ): AxiosPromise<IProfileIncomingBenefitsInfo[]> => {
    return TypedHttp.GetPromise<IProfileIncomingBenefitsInfo[]>(
      `${buildUri(mergeId)}/section/incomeAndBenefits`,
    );
  };

  static getMergeClinicalInfo = (mergeId: number): AxiosPromise<IProfileClinicalInfo[]> => {
    return TypedHttp.GetPromise<IProfileClinicalInfo[]>(`${buildUri(mergeId)}/section/clinical`);
  };

  static getMergePatientTasksInfo = (mergeId: number): AxiosPromise<IPatientMergeTask[]> => {
    return TypedHttp.GetPromise<IPatientMergeTask[]>(`${buildUri(mergeId)}/section/patient-tasks`);
  };

  static finishPatientMerge = (mergeId: number): AxiosPromise<void> => {
    return TypedHttp.PutPromise<{}, void>(`/merged-profile/${mergeId}`, {});
  };

  static savePatientMergeInputs = (
    mergeId: number,
    inputs: IPatientMergeInput[],
  ): AxiosPromise<void> => {
    const payload = inputs.map(input => ({
      tagId: input.type,
      selectedId: input.selectedId,
      extraInfo: input.extraInfo,
    }));
    return TypedHttp.PutPromise<{ tagId: LookupResourceTypes; selectedId: number }[], void>(
      `${buildUri(mergeId)}/inputs`,
      payload,
    );
  };

  static savePatientMergeDuplicate = (mergeId: number, patientId: number): AxiosPromise<void> => {
    const payload = {
      patientId,
    };

    return TypedHttp.PutPromise<typeof payload, void>(`${buildUri(mergeId)}/duplicate`, payload);
  };

  static savePatientMergePrimary = (mergeId: number, patientId: number): AxiosPromise<void> => {
    const payload = {
      patientId,
    };

    return TypedHttp.PutPromise<typeof payload, void>(`${buildUri(mergeId)}/primary`, payload);
  };
}

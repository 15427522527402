import React, { useEffect, useCallback } from 'react';
import classnames from 'classnames';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { IconButton, Grid, Select, MenuItem, Typography, Input } from '@mui/material';

import { useTable, usePagination, useColumnOrder, useExpanded } from 'react-table-v7';

import NoData from 'containers/report/invoicing/no-data';
import { SubRowAsync } from './sub-row';

import { defaultColumn } from '../utils';

import useStyles from './styles';

import { EXPANDER_ID } from '../constants';

export default function Table({
  columns,
  data,
  totalsColumns,
  globalFilter,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  visibleColumns: customVisibleColumns,
  hiddenColumns: customHiddenColumns,
  cellUpdate,
  skipPageReset,
  source,
  columnFilter,
  onColumnFilterChange,
  invoicingStage,
}) {
  const {
    rowSkeleton,
    invoicingContentTr,
    invoicingTable,
    invoicingTableContainer,
    invoicingHeaderTh,
    invoicingTd,
    invoicingGoToPage,
    invoicingPageControl,
    ellipsisContainer,
    invoicingTableMain,
    invoicingRightCornerTriangle,
    invoicingTableContainerIsLoading,
    invoicingStickyFooterTh,
  } = useStyles();

  const tableProps = useTable(
    {
      columns,
      data,
      totalsColumns,
      defaultColumn,
      autoResetPage: !skipPageReset,
      initialState: { pageIndex: 0, pageSize: 50, hiddenColumns: customHiddenColumns },
      manualPagination: true,
      manualSortBy: true,
      pageCount: controlledPageCount,
      cellUpdate,
      source,
      globalFilter,
      columnFilter,
      onColumnFilterChange,
      invoicingStage,
    },
    useExpanded,
    usePagination,
    useColumnOrder,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    setColumnOrder,
    setHiddenColumns,
  } = tableProps;

  useEffect(() => {
    fetchData({ pageIndex, pageSize, globalFilter });
  }, [fetchData, pageIndex, pageSize, globalFilter]);

  useEffect(() => {
    setColumnOrder([EXPANDER_ID, ...customVisibleColumns.map(o => o.name)]);
  }, [customVisibleColumns]);

  useEffect(() => {
    setHiddenColumns(customHiddenColumns);
  }, [customHiddenColumns]);

  const renderRowSubComponent = useCallback(
    ({ row, rowProps, columns: subColumns, source: subSource }) => (
      <SubRowAsync row={row} rowProps={rowProps} columns={subColumns} source={subSource} />
    ),
    [],
  );

  const tableComponent = (
    <div className={invoicingTable}>
      <div
        className={classnames(
          invoicingTableContainer,
          loading ? invoicingTableContainerIsLoading : undefined,
        )}
      >
        <table className={invoicingTableMain} {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup =>
              headerGroup.headers.map(column => (
                <th className={invoicingHeaderTh}>
                  {column?.raw?.isEditable && <div className={invoicingRightCornerTriangle} />}
                  <div className={ellipsisContainer}>{column.render('Header')}</div>
                </th>
              )),
            )}
          </thead>
          {!loading && (
            <>
              <tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row);
                  const rowProps = row.getRowProps();
                  return (
                    <>
                      <tr
                        className={row?.values?.Flagged === '1' ? invoicingContentTr : undefined}
                        {...row.getRowProps()}
                      >
                        {row.cells.map(cell => (
                          <td className={invoicingTd} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        ))}
                      </tr>
                      {row.isExpanded &&
                        renderRowSubComponent({
                          row,
                          rowProps,
                          columns: customVisibleColumns,
                          source: source.source,
                        })}
                    </>
                  );
                })}
              </tbody>
              <tfoot>
                {headerGroups.map(group => (
                  <tr {...group.getFooterGroupProps()}>
                    {group.headers.map(column => (
                      <td
                        className={classnames(invoicingTd, invoicingStickyFooterTh)}
                        {...column.getFooterProps()}
                      >
                        {column.render('Footer')}
                      </td>
                    ))}
                  </tr>
                ))}
              </tfoot>
            </>
          )}
        </table>
      </div>

      {loading && [...Array(15)].map(() => <div className={rowSkeleton} />)}
      {!data.length && !loading ? (
        <NoData />
      ) : (
        <>
          <div className={invoicingPageControl}>
            {loading ? (
              <span colSpan="10000">Loading...</span>
            ) : (
              <span colSpan="10000">
                {`Showing ${page.length} of ~ ${controlledPageCount * pageSize} results`}
              </span>
            )}
          </div>
          <Grid container spacing={2} wrap="nowrap" alignItems="center">
            <IconButton onClick={() => gotoPage(0)} disabled={!canPreviousPage} size="large">
              <FirstPageIcon />
            </IconButton>
            <IconButton onClick={() => previousPage()} disabled={!canPreviousPage} size="large">
              <ChevronLeftIcon />
            </IconButton>
            <IconButton onClick={() => nextPage()} disabled={!canNextPage} size="large">
              <ChevronRightIcon />
            </IconButton>
            <IconButton
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              size="large"
            >
              <LastPageIcon />
            </IconButton>
            <Grid item>
              Page&nbsp;
              <strong>{` ${pageIndex + 1} of ${pageOptions.length}`}</strong>
            </Grid>
            <Grid item>
              <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography>| Go to page:</Typography>
                </Grid>
                <Grid item>
                  <Input
                    className={invoicingGoToPage}
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={e => {
                      gotoPage(e.target.value ? Number(e.target.value) - 1 : 0);
                    }}
                  />
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="baseline"
                    sx={{ gap: 2 }}
                  >
                    <Grid item>
                      <Typography>Show: </Typography>
                    </Grid>
                    <Grid Item>
                      <Select
                        value={pageSize}
                        onChange={e => {
                          setPageSize(Number(e.target.value));
                        }}
                        variant="standard"
                      >
                        {[50, 100].map(_pageSize => (
                          <MenuItem key={_pageSize} value={_pageSize}>
                            {_pageSize}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );

  return tableComponent;
}

import React, { useEffect, useState, Children } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { BaseTable } from 'containers/application-manager/base-table/base-table';
import { CircleIndicatorIcon, EditPencil } from 'components/icons/icons';
import { Box, Chip, Grid, Typography } from '@mui/material';
import { UserPermissions } from 'interfaces/user/Permissions';
import { UserUtils } from 'utils/user-utils';
import { useStyles } from './rules-table.styles';
import { IApiResponse, IParentRecord, IChildRecord } from './types';
import { dateConvert } from '../../utils';
import { getUserById } from '../../../../services/utils/users-service';
import { useTypedSelector } from '../../../../hooks/use-typed-selector';

interface IProps {
  searchTerm: string | undefined;
  filters: Record<string, any>;
  setCount: (count: number) => void;
  triggerReload?: Date | undefined;
}

const childPkSelector = (child: IChildRecord): number => child.id;
const parentPkSelector = (parent: IParentRecord): number => parent.id;

export const RulesTable = (props: IProps): JSX.Element => {
  const classes: any = useStyles();
  const users = useTypedSelector(state => state.lookups.users);
  const customers = useTypedSelector(state => state.filters.customers);
  const [forcedQueryParams, setForcedQueryParams] = useState({});

  useEffect(() => {
    const { status, event_type } = props.filters;
    setForcedQueryParams({
      ...(status !== undefined ? { active: status } : {}),
      ...(event_type && event_type.length ? { event_type: event_type.join(',') } : {}),
    });
  }, [props.filters]);

  function formatDisplayableText(input: string) {
    const classNamesMap: Record<string, string> = {
      'highlight-1': 'highlineLevel1',
      'highlight-2': 'highlineLevel2',
    };

    // Regular expression to match the highlight parts
    const regex = /<highlight-(\d+)>(.*?)<\/highlight-\d+>/g;
    const highlightParts = input.split(regex);
    if (highlightParts.length === 1) {
      return <>{highlightParts}</>;
    }

    const result = highlightParts.map((part, index, parts) => {
      // Find the highlight level
      if (index % 3 === 1) {
        const highlightLevel = part;
        const highlighted = parts[index + 1];
        if (classNamesMap[`highlight-${highlightLevel}`]) {
          return (
            <Typography className={classes[classNamesMap[`highlight-${highlightLevel}`]]}>
              {highlighted}
            </Typography>
          );
        }
      }
      return /\s+(AND)$/gi.test(part) ? null : part;
    });

    return <>{result}</>;
  }

  const renderChildRecord = (parent: IParentRecord) => {
    return (
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={2} className={classes.recordContainer}>
          <Grid item xs={2}>
            <Chip label="TRIGGER" className={classNames(classes.recordChip, classes.triggerChip)} />
          </Grid>
          <Grid item xs={10}>
            <Typography className={classNames(classes.recordDescription, classes.highlineLevel1)}>
              {parent.display_trigger}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Chip label="IF" className={classNames(classes.recordChip, classes.ifChip)} />
          </Grid>
          <Grid item xs={10}>
            <Typography className={classes.recordDescription}>
              {formatDisplayableText(parent.display_condition)}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Chip label="THEN" className={classNames(classes.recordChip, classes.thenChip)} />
          </Grid>
          <Grid item xs={10}>
            <Typography className={classNames(classes.recordDescription)}>
              {formatDisplayableText(parent.display_action)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };

  // #region useEffects
  // #endregion

  const userCanEdit = UserUtils.userIsPermitted(UserPermissions.ApplicationManagerGtdEdit);

  return (
    <>
      <BaseTable<IApiResponse, IParentRecord, IChildRecord>
        actionsPermitted={false}
        enableParentCheckboxes={false}
        enableChildCheckboxes={false}
        orderByDefaultChild="name"
        setCount={props.setCount}
        triggerReload={props.triggerReload}
        endpointBase="/rule"
        paginationQueryParamSettings={{
          pageSizeQueryStringKey: 'pageSize',
          pageNumberQueryStringKey: 'pageNumber',
          searchTermQueryStringKey: 'name',
          sortPropQueryStringKey: 'sortProp',
          sortOrderQueryStringKey: 'sortOrder',
        }}
        customChildrenComponent={(parent: IParentRecord) => renderChildRecord(parent)}
        transformResponseData={(data: IApiResponse): IApiResponse => {
          if (data) {
            const { totalCount, results } = data;
            if (data.results?.length > 0) {
              const newResults: IParentRecord[] = results.map((item: IParentRecord) => ({
                ...item,
                children: [item as IChildRecord],
              }));
              return {
                totalCount,
                results: newResults,
              };
            }
          }
          return data;
        }}
        columnSettings={[
          {
            label: '',
            sortable: false,
            parentComponent: p => {
              if (
                moment(p.start_dt).toDate() > new Date() ||
                moment(p.end_dt).toDate() < new Date()
              ) {
                return (
                  <CircleIndicatorIcon
                    size={12}
                    color="red"
                    styles={{ paddingLeft: 'none !important' }}
                  />
                );
              }
              return <CircleIndicatorIcon size={12} color="green" />;
            },
          },
          {
            label: 'Rule Name',
            sortable: false,
            serverSideSearchField: 'name',
            parentKey: 'name',
            childKey: 'name',
          },
          {
            label: 'Site',
            sortable: false,
            serverSideSearchField: 'customer_id',
            parentKey: 'constraints',
            childKey: 'customer_id',
            parentValueFormatter: (constraints: unknown) => {
              const fields = [].concat(
                ...(constraints as any[]).map(({ fields: _fields }) => JSON.parse(_fields)),
              );
              const customerId = (fields as any[]).find(field => field.id === 'customer_id')?.value;

              const getCustomerName = (id: number) => {
                if (!id) {
                  return '-';
                }

                const customerName = customers.find(customer => customer.id === id)?.name;

                return customerName ?? `#${id}`;
              };

              if (Array.isArray(customerId)) {
                return (customerId as number[]).map(getCustomerName).join(', ');
              }

              return getCustomerName(customerId);
            },
          },
          {
            label: 'Updated By',
            sortable: false,
            parentKey: 'updated_by',
            childKey: 'updated_by',
            parentValueFormatter: (value: unknown) => getUserById(value, users).username,
          },
          {
            label: 'Effective Date',
            sortable: false,
            parentKey: 'start_dt',
            childKey: 'start_dt',
            parentValueFormatter: dateConvert,
          },
          {
            label: 'End Date',
            sortable: false,
            parentKey: 'end_dt',
            childKey: 'end_dt',
            parentValueFormatter: dateConvert,
          },
        ]}
        forcedQueryParams={forcedQueryParams}
        childPkSelector={childPkSelector}
        parentPkSelector={parentPkSelector}
        searchTerm={props.searchTerm}
      />
    </>
  );
};

import React from 'react';
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'recompose';
import moment from 'moment';
import { ViewListIcon } from 'components/icons/icons';
import { windowFeatureIsEnabled } from 'config/window-features';
import { ILabHistoryItemView } from '../../../interfaces/dynamic-forms/ILabHistoryView';
import { styles } from './lab-history-styles';

const LabHistoryItem = ({ date, value, decreasing, classes }: ILabHistoryItemView) => {
  return (
    <>
      {windowFeatureIsEnabled('dc_work_as_a_group') ? (
        <>
          <Typography className={classes.labHistoryItemDate}>
            {moment(date).format('MM/DD/YYYY')}
          </Typography>
          <Typography className={decreasing ? classes.redLabel : classes.value}>{value}</Typography>
          <Typography>
            <ViewListIcon size={25} className={classes.viewListIcon} />
          </Typography>
        </>
      ) : (
        <>
          <Typography className={classes.labHistoryItemDate}>{date}</Typography>
          <Typography className={decreasing ? classes.redLabel : classes.value}>{value}</Typography>
        </>
      )}
    </>
  );
};

const LabHistory: React.FC<any> = props => {
  const { classes } = props;
  const historicalValues: ILabHistoryItemView[] =
    props?.providers?.task?.protocol?.historicalValues;
  const clinicalDataTypeName: string = props?.providers?.task?.protocol?.clinicalDataTypeName;

  if (!historicalValues || historicalValues.length === 0) {
    return null;
  }

  return (
    <Grid container className={classes.outerContainer} data-qa-id="lab-history-container">
      <Grid item xs>
        <Typography className={classes.fieldLabelLarge}>{clinicalDataTypeName}</Typography>
      </Grid>
      {historicalValues
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        .map(historyItem => {
          return (
            <Grid
              container
              xs
              alignItems="center"
              direction="row"
              justifyContent="center"
              key={historyItem?.date}
            >
              <LabHistoryItem
                date={historyItem?.date}
                value={historyItem?.value}
                classes={classes}
                decreasing
              />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default compose<any, {}>(withStyles(styles as any, { withTheme: true }))(LabHistory);

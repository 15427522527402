import { AxiosResponse } from 'axios';

import {
  ICounselingProtocol,
  ICounselingProtocolResponse,
  IIsOngoingScheduledResponse,
} from 'interfaces/counseling/ICounselingProtocol';

import { CounselingTaskType, CounselingMethod } from 'constants/enums';

// @ts-ignore
import queryString from 'query-string';
import { logger } from '../../winston-logger';
import HTTP from '../http';

export const DEFAULT_SOAP_ORDER = ['S', 'O', 'AP'];

class CounselingProtocolService {
  private getBaseUrlByTaskId(taskId: number): string {
    return `/pmp/task/${taskId}/counseling`;
  }

  private getProtocolUrlByTaskId(
    taskId: number,
    counselingTaskType: CounselingTaskType,
    counselingMethod?: CounselingMethod,
  ): string {
    const params = {
      counseling_type: counselingTaskType,
      method: counselingMethod,
    };

    const query = queryString.stringify(params);
    return `${this.getBaseUrlByTaskId(taskId)}?${query}`;
  }

  private getBaseUrlSoapOrder(): string {
    return '/pmp/protocols/counseling/soap_order';
  }

  async getByTaskId(
    taskId: number,
    counselingTaskType: CounselingTaskType,
    counselingMethod?: CounselingMethod,
  ): Promise<ICounselingProtocol> {
    const response = (await HTTP.get(
      this.getProtocolUrlByTaskId(taskId, counselingTaskType, counselingMethod),
      {},
    )) as AxiosResponse<ICounselingProtocolResponse>;
    return response.data.protocol;
  }

  async getSoapOrder(): Promise<string[]> {
    try {
      const response = (await HTTP.get(this.getBaseUrlSoapOrder(), {})) as AxiosResponse<any>;
      return response.data.soap_order ? JSON.parse(response.data.soap_order) : DEFAULT_SOAP_ORDER;
    } catch (error) {
      return DEFAULT_SOAP_ORDER;
    }
  }

  async getIsOngoingScheduled(taskId: number): Promise<boolean> {
    try {
      const response = (await HTTP.get(
        `${this.getBaseUrlByTaskId(taskId)}/ongoingScheduled`,
        {},
      )) as AxiosResponse<IIsOngoingScheduledResponse>;

      return response.data.isOngoingScheduled;
    } catch (error) {
      logger.error(error);
      return false;
    }
  }
}

export { CounselingProtocolService };

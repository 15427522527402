import { IState } from 'interfaces/redux/IState';
import { ITrellisTherapeuticsClass } from 'interfaces/redux/ILookups';
import HTTP from 'services/http';
import { IModalItem, IModalItemChecked, IComponentStateItem, ComponentState } from './types';
import store from '../../../store';

export const toggleModalChecked = <IModalState extends { items?: IModalItem[] }>(
  checked: boolean,
  item: IModalItem,
  currentState: IModalState,
  stateSetter: (newState: IModalState) => void,
  modalTriggerSetter?: (item: IModalItemChecked) => void,
) => {
  // Update the state of the modal
  const newState = { ...currentState };

  const matchingItem = newState.items?.find(
    x => x.childPk === item.childPk && x.parentPk === item.parentPk,
  );
  if (matchingItem) {
    matchingItem.checked = checked;
  }

  stateSetter(newState);

  // Update the part of the state used to trigger the child table update
  if (modalTriggerSetter) {
    modalTriggerSetter({ parentPk: item.parentPk, childPk: item.childPk, checked: checked });
  }
};

export const commaSeparatedStringConvert = (value: unknown): string => {
  const defaultValue = '-';
  if (typeof value === 'string') {
    const split = value.split(',');
    return split.join(', ');
  }
  return defaultValue;
};

export const therapeuticClassConvert = (value: unknown): string => {
  const defualtValue = '-';
  if (value && typeof value === 'number') {
    const trellisTherapeuticClasses = (store.getState() as unknown as IState).lookups
      .trellisTherapeuticsClasses;
    const therapeuticClass = trellisTherapeuticClasses.find(x => x.id === (value as number));
    if (therapeuticClass) {
      return therapeuticClass.name;
    }
  }

  return defualtValue;
};

export const getDataAndUpdateState = async (
  therapeuticClasses: ITrellisTherapeuticsClass[],
  therapeuticClassUrlBuilder: string,
  therapeuticClassDataUrlBUilder: (therapeuticClassId: number) => string,
  tableStateSetter: (state: ComponentState) => void,
  included: boolean,
  sliderDisabled: boolean,
): Promise<void> => {
  if (therapeuticClasses.length > 0) {
    const url = therapeuticClassUrlBuilder;
    const request = HTTP.get(url);
    const results = (await request).data as (number | any)[];
    const newTableState = results
      .map<IComponentStateItem | undefined>(result => {
        const therapeuticClass = therapeuticClasses.find(
          x => x.id === (included ? result : result.id),
        );
        if (therapeuticClass) {
          const url = therapeuticClassDataUrlBUilder(included ? result : result.id);
          return {
            id: included ? result : result.id,
            name: therapeuticClass.name,
            open: false,
            url: url,
            included: included,
            sliderDisabled: sliderDisabled,
            checkedItems: {},
            isExcluded: !!result.isExcluded,
          };
        }
        return undefined;
      })
      .filter(x => x != null);
    const sortedTableState = (newTableState as ComponentState).sort((a, b) =>
      a.name.localeCompare(b.name),
    );
    tableStateSetter(sortedTableState as ComponentState);
  }
};

type DosageFormOrderFormDisplayRecord = Record<string, string>;

const dosageFormsUIDisplays: DosageFormOrderFormDisplayRecord = {
  AEPB: 'GM(s)',
  AERB: 'GM(s)',
  AERO: 'GM(s)',
  AERP: 'GM(s)',
  AERS: 'ML(s)',
  AJKT: 'PEN(s)',
  AUIJ: 'PEN(s)',
  BAR: 'BAR(s)',
  BEAD: 'BEAD(s)',
  C12A: 'CAP(s)',
  C4PK: 'CAP(s)',
  CAPS: 'CAP(s)',
  CART: 'CARTRIDGE(s)',
  CHER: 'Each',
  CHEW: 'TAB(s)',
  CONC: 'ML(s)',
  CP12: 'CAP(s)',
  CP24: 'CAP(s)',
  CPCR: 'CAP(s)',
  CPDR: 'CAP(s)',
  CPEP: 'CAP(s)',
  CPPK: 'CAP(s)',
  CPSP: 'CAP(s)',
  CREA: 'GM(s)',
  CRYS: 'CRYSTAL(s)',
  CS24: 'CAP(s)',
  CSDR: 'CAP(s)',
  CSPK: 'CAP(s)',
  DEVI: 'DEVICE(s)',
  DISK: 'DISK(s)',
  DPRH: 'DIAPHRAGM(s)',
  ELIX: 'ML(s)',
  EMUL: 'ML(s)',
  ENEM: 'ENEMA(s)',
  EXHP: 'GM(s)',
  EXHU: 'ML(s)',
  EXTR: 'ML(s)',
  FILM: 'FILM(s)',
  FLAK: 'FLAKE(s)',
  FOAM: 'ML(s)',
  GAS: 'ML(s)',
  GEL: 'GM(s)',
  GRAN: 'GRANULE(s)',
  GREF: 'GRANULE(s)',
  GUM: 'GUM(s)',
  IMPL: 'IMPLANT(s)',
  INHA: 'ML(s)',
  INJ: 'INJECTABLE(S)',
  INST: 'INSERT(s)',
  IUD: 'IUD',
  JTAJ: 'INJECTOR(s)',
  KIT: 'KIT(s)',
  LEAV: 'LEAVES',
  LIQD: 'ML(s)',
  LOTN: 'ML(s)',
  LOZG: 'LOZENGE(s)',
  LPOP: 'LOLLIPOP(s)',
  LQCR: 'ML(s)',
  LQPK: 'ML(s)',
  MISC: 'Each',
  NEBU: 'ML(s)',
  OIL: 'ML(s)',
  OINT: 'GM(s)',
  PACK: 'PACKET(s)',
  PADS: 'PAD(s)',
  PDEF: 'GM(s)',
  PEN: 'PEN(s)',
  PLLT: 'PELLET(s)',
  PNKT: 'PEN(s)',
  POWD: 'GM(s)',
  PRSY: 'SYRINGE(s)',
  PSKT: 'SYRINGE(s)',
  PSTE: 'GM(s)',
  PT24: 'PATCHES',
  PT72: 'PATCHES',
  PTCH: 'PATCHES',
  PTTW: 'PATCHES',
  PTWK: 'PATCHES',
  PUDG: 'PUDDING(s)',
  RING: 'RING(s)',
  SHAM: 'ML(s)',
  SHEE: 'SHEET(s)',
  // Warning: SOAJ must not be changed again
  SOAJ: 'ML(s)',
  SOCT: 'CARTRIDGE(s)',
  SOLG: 'ML(s)',
  SOLN: 'ML(s)',
  SOLR: 'ML(s)',
  SOPK: 'ML(s)',
  SOPN: 'ML(s)',
  SOSY: 'SYRINGE(s)',
  SOTJ: 'INJECTOR(s)',
  SPRT: 'ML(s)',
  SRER: 'ML(s)',
  STCK: 'STICK(s)',
  STRP: 'STRIP(s)',
  SUBL: 'TAB(s)',
  SUER: 'ML(s)',
  SUPN: 'ML(s)',
  SUPP: 'SUPPOSITORIES',
  SUSP: 'ML(s)',
  SUSR: 'ML(s)',
  SUSY: 'SYRINGE(s)',
  SWAB: 'SWAB(s)',
  SYRP: 'ML(s)',
  T12A: 'TAB(s)',
  T24A: 'TAB(s)',
  T4PK: 'TAB(s)',
  TABA: 'TAB(s)',
  TABS: 'TAB(s)',
  TAMP: 'TAMPONS',
  TAPE: 'TAPE',
  TAR: 'GM(s)',
  TB12: 'TAB(s)',
  TB24: 'TAB(s)',
  TB3D: 'TAB(s)',
  TBCR: 'TAB(s)',
  TBDD: 'TAB(s)',
  TBDP: 'TAB(s)',
  TBEA: 'TAB(s)',
  TBEC: 'TAB(s)',
  TBED: 'TAB(s)',
  TBEF: 'TAB(s)',
  TBPK: 'TAB(s)',
  TBSO: 'TAB(s)',
  TEST: 'TEST(s)',
  THPK: 'PACK(s)',
  TINC: 'ML(s)',
  TROC: 'TROCHE(s)',
  WAFR: 'WAFER(s)',
  WAX: 'WAX',
};

export const dosageFormToUIDisplay = (dosageForm: string): string | null => {
  return dosageFormsUIDisplays[dosageForm] ?? null;
};

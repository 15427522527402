import React from 'react';
import { Grid, Typography } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import BusinessIcon from '@mui/icons-material/Business';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import { BaseTable } from 'containers/application-manager/base-table/base-table';
import { IGetIcdProtocolsResponse } from 'models/application-manager/cpmp/IGetIcdProtocolsResponse';
import { ISpecialty } from 'models/application-manager/cpmp/IGetSpecialtyResponse';
import { IHeaderCell } from '../../../base-table/types';
import { IResponse, IResult, IResultChildren, IFrequencyData, IIcdCodesOpt } from './types';
import { styles } from '../../index.styles';
import { AddNewIcdModal } from '../modal/add-new-icd-modal';

interface ITableProps {
  searchTerm: string | undefined;
  triggerReload: Date | undefined;
  handleReloadDataTrigger: (date: Date | undefined) => void;
  userHasPermissionToEditIcdItem: boolean;
  activeAnyField: boolean;
  icdProtocolsList: IGetIcdProtocolsResponse;
  specialties: ISpecialty[];
}

export const DataTable: React.FC<ITableProps> = (props): JSX.Element => {
  const classes = styles();

  const [editModalOpen, setEditModalOpen] = React.useState<boolean>(false);
  const [editIcd, setEditIcd] = React.useState<IResult | undefined>(undefined);

  // # region helper functions
  const getIcdProtocols = React.useMemo<IResponse | undefined>(() => {
    const protocolData = props.icdProtocolsList.result;
    if (protocolData.length > 0) {
      const icdProtocol = protocolData.reduce((acc, protocol) => {
        const icds = Object.entries(protocol.icd)
          .map(x => x[1].name)
          .join(', ');
        const dcItem = Object.entries(protocol.dcItem);
        const childrenData = protocol.frequencyData.reduce((acc, item) => {
          const childrenDcItem = Object.entries(item.dcItem);
          acc.push({
            frequency: item.frequency,
            frequencyUnit: item.frequencyUnit,
            frequencyType: item.frequencyType,
            dcItem: childrenDcItem,
          });
          return acc;
        }, [] as IFrequencyData[]);

        const childrenDcItems = childrenData.reduce((acc, dat) => {
          dat.dcItem.forEach(x => {
            acc.push(x[1].name);
          });
          return acc;
        }, [] as string[]);

        const icdCodes = icds.split(',').reduce((acc, icdItem) => {
          acc.push({
            label: icdItem,
            value: icdItem,
            isFixed: true,
          });
          return acc;
        }, [] as IIcdCodesOpt[]);

        acc.push({
          id: protocol.id,
          name: protocol.name,
          icdCode: icdCodes,
          icd: icds,
          dcItem: dcItem,
          allDcItemNames: dcItem
            .map(x => x[1].name)
            .concat(childrenDcItems)
            .join(','),
          children: [
            {
              mainFrequency: protocol.mainFrequency,
              dcItem: dcItem.map(x => `${x[0]} - ${x[1].name}`),
              frequency: childrenData,
            },
          ],
        });
        return acc;
      }, [] as IResult[]);
      return { totalCount: icdProtocol.length - 1, results: icdProtocol };
    }
    return undefined;
  }, [props.icdProtocolsList]);
  // #endregion

  // # region constants
  const COLUMN_SETTINGS: IHeaderCell<IResult, IResultChildren>[] = [
    {
      label: 'Specialty',
      parentKey: 'name',
      hideLeftPadding: true,
      childColSpan: 2,
      sortable: true,
      childComponent: (parent, child) => {
        return (
          <>
            <Grid container direction="column" spacing={1}>
              <Grid item xs="auto">
                <Grid container justifyContent="space-between">
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs="auto">
                        <Typography>
                          <CalendarIcon className={classes.alignIcon} />
                          <strong>BaseLine</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item xs="auto">
                        <Typography>
                          {child.dcItem.map(x => {
                            return <span className={classes.roundedGrayBackground}>{`${x}`}</span>;
                          })}
                        </Typography>
                      </Grid>
                    </Grid>
                    {child.frequency.map(x => {
                      return (
                        <Grid container spacing={4}>
                          <Grid item xs="auto">
                            <Typography>
                              <strong>
                                <CalendarIcon className={classes.alignIcon} />
                                {x.frequencyUnit !== null && x.frequencyType !== null
                                  ? parseInt(x.frequencyUnit) > 1
                                    ? `${
                                        x.frequency.charAt(0).toUpperCase() + x.frequency.slice(1)
                                      } - ${x.frequencyUnit} ${x.frequencyType}s`
                                    : `${
                                        x.frequency.charAt(0).toUpperCase() + x.frequency.slice(1)
                                      } - ${x.frequencyUnit} ${x.frequencyType}`
                                  : `${x.frequency.charAt(0).toUpperCase() + x.frequency.slice(1)}`}
                              </strong>
                            </Typography>
                            <div>
                              {x.dcItem.map(i => {
                                return (
                                  <span
                                    className={classes.roundedGrayBackground}
                                  >{`${i[0]} - ${i[1].name}`}
                                  </span>
                                );
                              })}
                            </div>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        );
      },
    },
    {
      label: 'ICD-10',
      parentKey: 'icd',
      sortable: true,
      hideLeftPadding: true,
    },
  ];
  // #endregion

  // # region memos
  const columnSettings = React.useMemo(() => {
    if (props.userHasPermissionToEditIcdItem) {
      return COLUMN_SETTINGS.concat({
        sortable: true,
        parentComponent: parent => {
          return (
            <>
              <div
                onClick={e => {
                  e.stopPropagation();
                  setEditIcd(parent);
                  setEditModalOpen(true);
                }}
              >
                <CreateIcon />
              </div>
            </>
          );
        },
      });
    }
    return COLUMN_SETTINGS;
  }, [props.userHasPermissionToEditIcdItem]);
  // #endregion

  const renderEnableIcdModal = (): JSX.Element => {
    return (
      <AddNewIcdModal
        open={editModalOpen}
        onCancel={() => {
          setEditIcd(undefined);
          setEditModalOpen(false);
        }}
        onSuccess={() => {
          setEditIcd(undefined);
          props.handleReloadDataTrigger(new Date());
        }}
        editingIcdItem={editIcd}
        icdProtocols={props.icdProtocolsList}
        specialties={props.specialties}
      />
    );
  };

  return (
    <>
      {props.userHasPermissionToEditIcdItem ? renderEnableIcdModal() : null}
      <BaseTable<IResponse, IResult, IResultChildren>
        actionsPermitted={props.userHasPermissionToEditIcdItem}
        orderByDefaultParent="name"
        dataSet={getIcdProtocols}
        paginationQueryParamSettings={{
          pageSizeQueryStringKey: 'pageSize',
          pageNumberQueryStringKey: 'pageNumber',
          searchTermQueryStringKey: 'searchTerm',
          sortPropQueryStringKey: 'sortProp',
          sortOrderQueryStringKey: 'sortOrder',
        }}
        childPkSelector={() => ''}
        parentPkSelector={p => p.id}
        columnSettings={columnSettings}
        searchTerm={JSON.stringify({
          type: 'MULTIPLE',
          search: [
            {
              values: props.searchTerm,
              columns: ['name', 'icd', 'allDcItemNames'],
              fullMatch: false,
              active: props.activeAnyField,
            },
          ],
        })}
        classesOverride={{
          lastCell: props.userHasPermissionToEditIcdItem ? classes.lastCell : classes.lastCellEmpty,
          table: classes.wrapText,
        }}
        triggerReload={props.triggerReload}
      />
    </>
  );
};

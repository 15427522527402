const baseFilterPill = theme => ({
  backgroundColor: theme.palette.primary.summaryPanel,
  fontWeight: theme.font.weight.regular,
  border: '1px solid',
  borderColor: theme.palette.primary.faintBorder,
  height: '20px',
  lineHeight: '20px',
  padding: '0 6px',
  borderRadius: '15px',
  minWidth: null,
  margin: '4px 2px 4px 0',
  fontSize: '12px',
});

export const styles = theme => ({
  filterPill: {
    ...baseFilterPill(theme),
  },
  inlineFilterPill: {
    ...baseFilterPill(theme),
    display: 'inline-block',
  },
  xFilterPill: {
    cursor: 'pointer',
    marginLeft: '10px',
    float: 'right',
  },
});

import { createStyles } from '@mui/styles';
import colors from 'lib/themes/colors.module.scss';

const { white } = colors;

export const styles = createStyles({
  container: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    fontSize: 18,
    minHeight: '100vh',
  },
  whitePage: {
    'backgroundColor': white,
    'justifyContent': 'start',
    '& h1': {
      margin: 0,
      fontSize: 26,
    },
    '& hr': {
      width: '85%',
    },
  },
});

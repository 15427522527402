import React from 'react';
import ReviewItem from 'components/fc-self-service/components/review-item';
import Question from 'components/fc-self-service/components/question';
import { STRINGS } from '../../fc_ss_constants';

const PickUpOrDelivery = (props: any) => {
  const { nextQuestion, isReview, currentAnswers, onEdit, saveAnswer, answerOptions, isEdit } =
    props;
  const keyName = 'pickup_or_delivery';
  const questionText = 'How do you want to get your medications?';

  const handleResponse = (value: any) => {
    if (value === currentAnswers.pickup_or_delivery) {
      nextQuestion();
      return;
    }
    const responseValue: { keyName: string; value: string | number | null | {} } = {
      keyName,
      value: value === 'delivery' ? STRINGS.delivery : STRINGS.pickup,
    };
    saveAnswer(responseValue);
    if (value === 'pickup') {
      if (isEdit && !answerOptions.pickup_dates) {
        nextQuestion();
      } else if (answerOptions.pickup_dates) {
        nextQuestion('pickup_dates');
      } else {
        nextQuestion('payment_method');
      }
    } else {
      nextQuestion('delivery_questions', true);
    }
  };

  return isReview ? (
    <ReviewItem
      questionText={questionText}
      onEdit={onEdit}
      currentAnswer={currentAnswers[keyName]}
      keyName={keyName}
    />
  ) : (
    <Question
      questionText={questionText}
      response={handleResponse}
      keyName={keyName}
      buttons={{
        button1Text: 'Delivery',
        button1Value: 'delivery',
        button2Text: 'Pickup',
        button2Value: 'pickup',
      }}
    />
  );
};

export default PickUpOrDelivery;

import React from 'react';
import BlueContainer from '../components/blue-container';
import PoweredBy from '../components/powered-by';
import Paragraph from '../components/paragraph';

const ErrorPage = ({ message, uuid }) => (
  <BlueContainer>
    <Paragraph type="title">An unexpected error has happened. {`${message ?? ''}`}</Paragraph>
    <PoweredBy />
  </BlueContainer>
);

export default ErrorPage;

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { BaseTable } from 'containers/application-manager/base-table/base-table';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { useDispatch } from 'react-redux';
import { UserPermissions } from 'interfaces/user/Permissions';
import { UserUtils } from 'utils/user-utils';
import { useTypedSelector } from 'hooks/use-typed-selector';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Popover,
} from '@mui/material';
import { CustomerBranchTrellisTherapeuticClassPriority } from 'constants/enums';
import { ArConstants, PAGINATION_PARAMS } from './constants';
import {
  CUSTOMER_BRANCH_TTC_HIGH_DISPLAY_TEXT,
  CUSTOMER_BRANCH_TTC_LOW_DISPLAY_TEXT,
} from '../../../constants';
import { booleanConvert, dateConvert } from '../utils';
import { ComponentState, IResult, ITableApiResponse, ITableProps, IPopoverStateBase } from './types';
import { FieldTypes } from '../types';
import { FormField } from '../popover-field-forms';
import { getDataAndUpdateState } from './utils';

import { logger } from '../../../winston-logger';
import { UseStyles } from './table.styles';

const parentPkSelector = (parent: IResult) => parent.id;

type IPopoverState = IPopoverStateBase<IResult>

const initialPopoverState = {
  open: false,
} as IPopoverState;

export const ArTable: React.FC<ITableProps> = (props): JSX.Element => {
  const classes: any = UseStyles();
  const dispatch = useDispatch();
  // #region Redux
  const user = useTypedSelector(state => state.auth.currentUser);
  const userHasArManagePermission = React.useMemo<boolean>(() => {
    return UserUtils.userIsPermitted(
      UserPermissions.ApplicationManagerArEdit,
      props.selectedCustomerId,
    );
  }, [user, props.selectedCustomerId]);
  // #endregion

  // #region useState
  const [tableState, setTableState] = React.useState<ComponentState>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(ArConstants.defaultRowsPerPage);
  const [popoverState, setPopoverState] = React.useState<IPopoverState>(initialPopoverState);
  // #endregion

  // #region use effect method
  React.useEffect(() => {
    const serviceGroupUrlBuilder = `application-manager/ar/service-groups/${props.selectedCustomerId}`;
    const serviceGroupDataUrlBuilder = (serviceGroupId: number) =>
      `application-manager/ar/${props.selectedCustomerId}/${serviceGroupId}`;
    (async () => {
      await getDataAndUpdateState(
        serviceGroupUrlBuilder,
        serviceGroupDataUrlBuilder,
        tableState,
        setTableState,
      );
    })();
  }, [props.selectedCustomerId, props.triggerReload]);
  // #endregion

  // #region helpers
  const toggleExpansion = (id: number, open: boolean) => {
    const newState = [...tableState];
    const item = newState.find(x => x.id === id);
    if (item) {
      item.open = open;
    }
    setTableState(newState);
  };

  const afterRequestSuccess = (callbacks: (() => void)[] | undefined = undefined) => {
    dispatch(notifySuccess('Saved'));
    props.handleReloadDataTrigger(new Date());
    if (callbacks) {
      callbacks.forEach(cb => cb());
    }
  };
  // #endregion

  const openPopover = (
    element: HTMLElement,
    value: unknown,
    config: {
      parent: IResult;
      fieldType: FieldTypes;
      field: keyof IResult;
      forceAsParent?: boolean;
    },
  ): void => {
    if (config.parent === null) {
      throw new Error('Cannot select a parent cell.');
    }

    setPopoverState({
      open: true,
      anchorElement: element,
      parent: config.parent,
      field: config.field,
      fieldType: config.fieldType,
      value: value,
      forceAsParent: config.forceAsParent,
    });
  };

  const renderPopover = (): JSX.Element => {
    const handleCancel = () => setPopoverState(initialPopoverState);
    const handleSubmit = (value: unknown) => onSubmitFieldUpdate(value);

    return (
      <Popover
        id="form"
        open={popoverState.open}
        anchorEl={popoverState?.anchorElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transitionDuration={{ exit: 0 }}
        onClose={() => setPopoverState(initialPopoverState)}
      >
        <FormField
          data-qa-id={`${popoverState.field}-${popoverState.parent?.id || ''}`}
          initialValue={popoverState.value}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          fieldType={popoverState.fieldType}
        />
      </Popover>
    );
  };

  const onSubmitFieldUpdate = async (value: unknown) => {
    try {
      const request = ApplicationManagerClient.updateAr(
        popoverState.parent.id,
        props.selectedCustomerId,
        popoverState.field,
        value,
      );
      await request;

      afterRequestSuccess([() => setPopoverState(initialPopoverState)]);
    } catch (error) {
      logger.error(error);
      dispatch(notifyError('An error occurred'));
    } finally {
      setPopoverState(initialPopoverState);
    }
  };

  return (
    <>
      {userHasArManagePermission ? renderPopover() : null}
      {tableState.map(value => {
        return (
          <React.Fragment key={value.id}>
            <Accordion
              className={classes?.expansionPanelStyles}
              expanded={value.open}
              onChange={(event, expanded) => {
                toggleExpansion(value.id, expanded);
              }}
              TransitionProps={{
                unmountOnExit: true,
                mountOnEnter: true,
              }}
            >
              <AccordionSummary className={classes?.expansionHeader}>
                <Grid container direction="row" spacing={3}>
                  <Grid container justifyContent="space-between">
                    <Grid item xs="auto" className={classes?.expansionHeader}>
                      <Grid container spacing={1} data-qa-id="container 2">
                        <Typography className={classes?.expansionHeaderTextLeft}>
                          {value.displayName}
                        </Typography>
                        <Typography className={classes?.expansionHeaderCount} />
                        <ExpandMoreIcon className={classes?.expansionHeaderExpandIcon} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails className={classes?.expansionDetails}>
                <BaseTable<ITableApiResponse, IResult, undefined>
                  actionsPermitted={props.canEdit}
                  enableParentCheckboxes={false}
                  enableChildCheckboxes={props.canEdit}
                  classesOverride={{ tableContainer: classes?.tableContainer }}
                  endpointBase={value.url}
                  paginationQueryParamSettings={PAGINATION_PARAMS}
                  childPkSelector={() => ''}
                  parentPkSelector={parentPkSelector}
                  searchTerm={props.searchTerm}
                  columnSettings={[
                    {
                      label: 'Clinic',
                      parentKey: 'name',
                      hideLeftPadding: true,
                      sortable: true,
                    },
                    {
                      label: 'Specialty Type',
                      parentKey: 'specialtyType',
                      sortable: true,
                      parentValueFormatter: (value: unknown) => {
                        return value == null ? '-' : (value as string);
                      },
                      parentCellClick: (event, parent) => {
                        openPopover(
                          event.currentTarget,
                          // TODO: think of a way to make this better...
                          (parent.specialtyType ?? '')
                            .split(',')
                            .map(val => val.trim())
                            .join(','),
                          {
                            parent: parent,
                            field: 'specialtyType',
                            fieldType: FieldTypes.SpecialityType,
                          },
                        );
                      },
                    },
                    {
                      label: 'Enabled',
                      parentKey: 'isActive',
                      sortable: true,
                      parentValueFormatter: booleanConvert,
                      parentCellClick: (event, parent) => {
                        openPopover(event.currentTarget, !!parent.isActive, {
                          parent: parent,
                          field: 'isActive',
                          fieldType: FieldTypes.YesNo,
                        });
                      },
                    },
                    {
                      label: 'Therapeutic Class',
                      parentKey: 'therapeuticClass',
                      sortable: true,
                    },
                    {
                      label: 'Priority',
                      parentKey: 'priority',
                      sortable: true,
                      parentValueFormatter: (value: unknown) => {
                        return value === CustomerBranchTrellisTherapeuticClassPriority.High
                          ? CUSTOMER_BRANCH_TTC_HIGH_DISPLAY_TEXT
                          : CUSTOMER_BRANCH_TTC_LOW_DISPLAY_TEXT;
                      },
                      parentCellClick: (event, parent) => {
                        openPopover(event.currentTarget, parent.priority === 1, {
                          parent: parent,
                          field: 'priority',
                          fieldType: FieldTypes.HighLow,
                        });
                      },
                    },
                    {
                      label: 'Added',
                      parentKey: 'created',
                      sortable: true,
                      parentValueFormatter: dateConvert,
                    },
                    {
                      label: 'Updated',
                      parentKey: 'updated',
                      sortable: true,
                      parentValueFormatter: dateConvert,
                    },
                  ]}
                  rowsPerPageSettings={{
                    override: rowsPerPage,
                    onChange: perPage => {
                      setRowsPerPage(perPage);
                    },
                  }}
                  triggerReload={value.reloadTrigger}
                />
              </AccordionDetails>
            </Accordion>
          </React.Fragment>
        );
      })}
    </>
  );
};

import React from 'react';
import {
  Grid,
  Popover,
  Typography,
  Box,
  Button,
  List,
  ListItem,
  IconButton,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import { get } from 'lodash';
import useStyles from './styles';

export default function Comment(props) {
  const classes = useStyles();
  const { anchorEl, onClose, onColumnFilterChange, open, columnFilter, columnList, id } = props;

  const handleOnClearColumnFilter = columnId => {
    const { [columnId]: value, ...rest } = columnFilter;
    onColumnFilterChange(rest);
  };

  const handleOnClearAllColumnFilter = () => {
    onClose();
    onColumnFilterChange({});
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      onClick={e => {
        e.stopPropagation();
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Grid container direction="column" className={classes.root}>
        <Typography variant="caption">Filters</Typography>
        <Box className={classes.tableContainer}>
          <List dense>
            {Object.keys(columnFilter).map(item => (
              <ListItem>
                <ListItemText
                  primary={get(
                    columnList.find(o => o.name === item),
                    'label',
                    item,
                  )}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => handleOnClearColumnFilter(item)}
                    edge="end"
                    aria-label="delete"
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box pt={2} />
        <Grid item>
          <Grid container justifyContent="flex-end">
            <Box ml={2}>
              <Button onClick={handleOnClearAllColumnFilter}>Clear All</Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Popover>
  );
}

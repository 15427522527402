import { EditableSection } from 'components/editable-text-section';

export enum RightSideComponent {
  LabValuesList = 'LabValuesList',
}

type Section = {
  header?: string;
  content?: string;
  secondaryContent?: string;
  drugSubSections?: Record<string, Record<string, string>>;
  editableContent?: EditableSection;
  readOnly?: boolean;
  finishedSaving?: boolean;
  rightSideComponent?: RightSideComponent;
};

export type { Section };

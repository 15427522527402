/* eslint-disable react/function-component-definition */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { IState } from 'interfaces/redux/IState';
import { Config } from '../models/config/config';
import {
  fetchServiceGroups,
  fetchCustomerClinics,
  fetchAllergyLookups,
  fetchAllergyReactionTypes,
  fetchAppointmentClinics,
  fetchLookups,
} from '../actions/action-lookups';
import { fetchUserCustomLists } from '../actions/action-user-custom-lists';
import { fetchUserPreferences } from '../actions/action-user';
import { fetchCdmPrograms } from '../actions/action-cdm-programs';

import { loadPharmacies } from '../slices/pharmacies-slice';
import { logger } from '../winston-logger';

/**
 * Developer Note: Using a component for app-wide side-effects is NOT STABLE.
 * This should be handled in redux-saga, or as a separate hook which maintains
 * the "appConfigLoaded" state in a global variable.
 */

export const DataLoader: React.FC<{ children?: React.ReactNode }> = (props): JSX.Element => {
  const { children } = props;
  const [appConfigLoaded, setAppConfigLoaded] = React.useState<boolean>(false);
  const customerId = useTypedSelector((state: IState) => state.filters.selectedCustomerId);
  const dispatch = useDispatch();
  const isUserGuide = window.location.href.includes('/user-guide/');
  const shouldLoadData = !isUserGuide;

  React.useEffect(() => {
    (async () => {
      try {
        if (shouldLoadData) {
          const group1 = Promise.all([
            Config.init(),
            dispatch(fetchServiceGroups()),
            dispatch(fetchCustomerClinics()),
            dispatch(fetchUserPreferences()),
            dispatch(fetchAllergyLookups()),
            dispatch(fetchAllergyReactionTypes()),
          ]);
          const group2 = Promise.all([
            dispatch(loadPharmacies(customerId)),
            dispatch(fetchAppointmentClinics()),
            dispatch(fetchLookups()),
            dispatch(fetchUserCustomLists()),
            dispatch(fetchCdmPrograms()),
          ]);
          await Promise.all([group1, group2]);
          setAppConfigLoaded(true);
        } else {
          setAppConfigLoaded(true);
        }
      } catch (error) {
        logger.error(error);
      }
    })();
  }, []);

  return <>{appConfigLoaded ? children : <div>Loading ...</div>}</>;
};

import { CounselingProcessor } from './processors/counseling-processor';
import { CSL, FC } from '../../constants/index';
import { EmptyProcessor } from './processors/empty-processor';
import { FillCoordinationProcessor } from './processors/fill-coordination-processor';
import { TaskType } from '../../interfaces/TaskType';
import { ITaskStatusProcessor } from './interfaces/TaskStatusProcessor';

/**
 * Given a task, returns a factory that can be used to determine the status of it
 *
 * Usage should be to call `TaskStatusFactory.getProcessor(<taskType>, <task>)` and then
 * use that processor to get the status of the given task
 */
class TaskStatusFactory {
  static getProcessor = (taskType: TaskType): ITaskStatusProcessor => {
    switch (taskType) {
      case FC: {
        return new FillCoordinationProcessor();
      }
      case CSL: {
        return new CounselingProcessor();
      }
    }

    return new EmptyProcessor();
  };
}

export { TaskStatusFactory };

import withStyles from '@mui/styles/withStyles';
import { Table, TableBody, TableCell, TableHead, Grid, TableRow } from '@mui/material';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { RetryIcon } from 'components/icons/icons';
import {
  fetchPatient,
  selectPatientTab,
  toggleTabRefresh,
  recentPatientSelected,
} from 'actions/action-patient';
import { fetchNotes, addNote } from 'actions/action-notes';
import { withRouter } from 'react-router';
import { formatPatientNameShort, getPatientPreferred } from 'services/utils/demographic-service';
import { getStateForDisplay } from 'services/utils/task-service';
import { windowFeatureIsEnabled } from 'config/window-features';
import { convertToArborDate } from 'models/time/arbor-date';
import { SEARCH_SETTINGS } from 'constants/index';
import { styles } from '../side-bar-styles';
import { withCustomer } from '../../../../helpers/router';

class SearchList extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  selectPatient(patientId) {
    const {
      location,
      history,
      toggleTabRefresh, // eslint-disable-line
      selectPatientTab, // eslint-disable-line
      tabControl,
      // eslint-disable-next-line no-shadow
      recentPatientSelected,
    } = this.props;
    const { patientTab } = tabControl;

    recentPatientSelected(patientId);

    if (patientTab === 'therapies') {
      toggleTabRefresh(true);
    } else {
      selectPatientTab('therapies');
    }
    let patientLink = `/patients/${patientId}/therapies`;
    patientLink += location.search;
    history.push(withCustomer(patientLink));
  }

  handleClick({ id: patientId }) {
    this.selectPatient(patientId);
  }

  renderTableRow(patientInList, patientIndex) {
    const { classes, selectedPatientId, patientInListIndex } = this.props;
    const primaryAddr = getPatientPreferred(patientInList, 'addresses');
    const state = getStateForDisplay(primaryAddr);
    const streetStr =
      primaryAddr && primaryAddr.line2
        ? `${primaryAddr.line1}, ${primaryAddr.line2}`
        : primaryAddr && primaryAddr.line1;
    const regionStr =
      primaryAddr && `${primaryAddr.city || ''} ${state} ${primaryAddr.zip || ''}`.trim();
    let tableClass;
    if (patientInList.id === selectedPatientId) {
      tableClass = classNames(classes.tableRow, classes.tableRowSelected);
    } else if (patientInListIndex === patientIndex) {
      tableClass = classNames(classes.tableRow, classes.tableRowSelectedArrows);
    } else {
      tableClass = classes.tableRow;
    }
    const dobToShow = patientInList.dob
      ? convertToArborDate(patientInList.dob, true).getUtcDate(true)
      : '-';
    const phoneNumbers =
      patientInList.phones &&
      Array.isArray(patientInList.phones) &&
      patientInList.phones
        .filter(({ deleted, value }) => !deleted && !!value)
        .map(({ value }) => value.replace(/\(/, '').replace(/\)/, '-').replace(/\s/g, ''));
    const displayPhoneNumbers = phoneNumbers && phoneNumbers.join(', ').replace(/(,\s*)+$/, '');
    const mrnValue = patientInList.source_patient_id;
    const mrnSource = `${
      windowFeatureIsEnabled('display_mrn_source_name') && patientInList.mrn_source_name
        ? `(${patientInList.mrn_source_name})`
        : ''
    }`;
    const mrnInfo = `${mrnValue} ${mrnSource}`;

    return (
      <TableRow
        hover
        key={patientInList.id}
        id={patientInList.id}
        onClick={e => this.handleClick(patientInList)} // eslint-disable-line
        className={tableClass}
      >
        <TableCell className={classes.tableCell} component="th" scope="row">
          <Grid container alignItems="center" className={classes.tableMainContent}>
            <Grid item xs={1}>
              {!!patientInList.needs_reconciliation && <RetryIcon size={16} />}
            </Grid>
            <Grid item xs={11}>
              <div className={classes.tablePatientName}>
                {formatPatientNameShort(patientInList)}
              </div>
              <div>{dobToShow}</div>
              {mrnInfo}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <div style={{ textAlign: 'right' }}>{streetStr}</div>
          <div style={{ textAlign: 'right' }}>{regionStr}</div>
          {!!phoneNumbers && <div style={{ textAlign: 'right' }}>{displayPhoneNumbers}</div>}
        </TableCell>
      </TableRow>
    );
  }

  render() {
    const { classes, patientList, searchTerms = {}, tableLabel } = this.props;

    // eslint-disable-next-line arrow-body-style
    const updatedSearchTerms = Object.entries(searchTerms).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: (Array.isArray(value) ? value : value.split(',')).map(searchVal => searchVal.trim()),
      };
    }, {});

    return (
      <div className={classes.tableContainer}>
        {patientList.data && patientList.data.length > 0 ? (
          <Table className={classes.table} id="patient-search-results">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>{tableLabel}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {patientList.data.map((patient, index) =>
                this.renderTableRow(patient, index, updatedSearchTerms),
              )}
            </TableBody>
          </Table>
        ) : (
          <div className={classes.searchTextContainer}>{SEARCH_SETTINGS.NO_RESULTS_MESSAGE}</div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { filters, patient, selectedPatientId, patientList, lookups, tabControl } = state;
  const { selectedCustomerId } = filters;
  const patientInListIndex = patientList.selectedPatientInListIndex;
  return {
    filters,
    patient,
    selectedPatientId,
    patientList,
    lookups,
    tabControl,
    patientInListIndex,
    selectedCustomerId,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    fetchPatient,
    selectPatientTab,
    fetchNotes,
    addNote,
    toggleTabRefresh,
    recentPatientSelected,
  }),
  withRouter,
)(SearchList);

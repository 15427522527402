import React, { useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import {
  Table,
  TableBody,
  TableCell,
  TableSortLabel,
  TableHead,
  TableRow,
  Grid,
  Modal,
  Box,
} from '@mui/material';
import { CheckmarkIcon } from 'components/icons/icons';
import { PATIENT_ALLERGY_SEVERITY_SEVERE_ID } from 'constants/index';
import { useSelector } from 'react-redux';
import compose from 'recompose/compose';
import { getModalStyle } from 'services/utils/styles-service';
import { styles } from '../patient-clinical-styles';
import AllergiesRow from './patient-allergies-row';
import PatientAllergiesForm from './patient-allergies-form';

function descendingComparator(a, b, orderBy, allergiesSources) {
  switch (orderBy) {
    case 'allergen': {
      const aName = b[orderBy].name.toLowerCase();
      const bName = a[orderBy].name.toLowerCase();
      if (bName < aName) {
        return -1;
      }
      if (bName > aName) {
        return 1;
      }
      return 0;
    }
    case 'source': {
      const sourceA = (
        allergiesSources.find(source => source.id === a.allergy_source_id)?.value ?? ''
      ).toLowerCase();
      const sourceB = (
        allergiesSources.find(source => source.id === b.allergy_source_id)?.value ?? ''
      ).toLowerCase();
      if (sourceB < sourceA) {
        return -1;
      }
      if (sourceB > sourceA) {
        return 1;
      }
      return 0;
    }
    default: {
      const aValue = (a[orderBy] ?? '').toLowerCase();
      const bValue = (b[orderBy] ?? '').toLowerCase();

      if (bValue < aValue) {
        return -1;
      }
      if (bValue > aValue) {
        return 1;
      }
      return 0;
    }
  }
}

function getComparator(order, orderBy, allergiesSources) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, allergiesSources)
    : (a, b) => -descendingComparator(a, b, orderBy, allergiesSources);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const AllergiesTable = props => {
  const { classes, allergies, readOnly } = props;
  const patient = useSelector(state => state.patient);
  const patientHasKnownAllergies = useSelector(state => state.allergies.patientHasKnownAllergies);
  const allergiesSources = useSelector(state => state.lookups.allergyLookups.sources);
  const [displayEditAllergy, setDisplayEditAllergy] = useState(false);
  const [allergyToEdit, setAllergyToEdit] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('allergen');

  const handleEditAllergyModal = (open, allergy = null) => {
    setDisplayEditAllergy(open);
    setAllergyToEdit(allergy);
  };

  // Total cols should be 12 to mimic grid since grid isn't the most
  // compatible with table
  const tableColWidths = {
    allergy: 2,
    severity: 2,
    reactions: 4,
    status: 2,
    source: 1,
    edit: 1,
    noAllergies: 11,
  };

  const renderTableColSpacing = spacingVal => Array(spacingVal - 1).fill(<TableCell />);

  const renderAllergies = allergiesToRender => {
    allergiesToRender.sort((a, b) => {
      const { severity_id: aSeverityId } = a;
      const { severity_id: bSeverityId } = b;
      if (aSeverityId === bSeverityId) {
        return 0;
      }
      if (!!aSeverityId && !!bSeverityId) {
        return aSeverityId === PATIENT_ALLERGY_SEVERITY_SEVERE_ID ? -1 : 1;
      }
      return aSeverityId ? -1 : 1;
    });

    return allergiesToRender && Array.isArray(allergiesToRender)
      ? stableSort(
          allergiesToRender,
          getComparator(order, orderBy, allergiesSources),
        ).map((allergyVar, index) => (
          <AllergiesRow
            editHandler={handleEditAllergyModal}
            allergy={allergyVar}
            patient={patient}
            key={`allergy_key_${allergyVar.id}`}
            tableColWidths={tableColWidths}
            readOnly={readOnly}
            index={index}
          />
        ))
      : null;
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  const tableSortLabelComponent = (header, headerKey) => (
    <TableSortLabel
      active={orderBy === headerKey}
      direction={orderBy === headerKey ? order : 'asc'}
      onClick={createSortHandler(headerKey)}
    >
      {header}
      {orderBy === header ? (
        <Box component="span" sx={{ display: 'none' }}>
          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        </Box>
      ) : null}
    </TableSortLabel>
  );

  return (
    <>
      <Modal open={displayEditAllergy} onClose={() => setDisplayEditAllergy(false)}>
        <Grid item xs={12} style={getModalStyle()} className={classes.editAllergyModal}>
          {!!allergyToEdit && (
            <PatientAllergiesForm
              allergy={allergyToEdit}
              cancelHandler={() => handleEditAllergyModal(false)}
              noAllergiesForm={!patientHasKnownAllergies}
            />
          )}
        </Grid>
      </Modal>
      <Grid container>
        <Table className={classes.tableAllergies} aria-label="simple table" dense>
          <TableHead>
            {patientHasKnownAllergies ? (
              <TableRow>
                <TableCell>{tableSortLabelComponent('Allergy', 'allergen')}</TableCell>
                {renderTableColSpacing(tableColWidths.allergy)}
                <TableCell>{tableSortLabelComponent('Severity', 'severity')}</TableCell>
                {renderTableColSpacing(tableColWidths.severity)}
                <TableCell>Reaction(s)</TableCell>
                {renderTableColSpacing(tableColWidths.reactions)}
                <TableCell>{tableSortLabelComponent('Status', 'status')}</TableCell>
                {renderTableColSpacing(tableColWidths.status)}
                <TableCell>{tableSortLabelComponent('Source', 'source')}</TableCell>
                {renderTableColSpacing(tableColWidths.source)}
                <TableCell />
                {!readOnly && renderTableColSpacing(tableColWidths.edit)}
              </TableRow>
            ) : (
              <TableRow>
                <TableCell>
                  <CheckmarkIcon size={12} />
                  {' Patient has no known allergies'}
                </TableCell>
                {renderTableColSpacing(tableColWidths.noAllergies)}
                <TableCell />
                {!readOnly && renderTableColSpacing(tableColWidths.edit)}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {patientHasKnownAllergies ? <>{renderAllergies(allergies)}</> : null}
          </TableBody>
        </Table>
      </Grid>
    </>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(AllergiesTable);

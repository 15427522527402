import React from 'react';

const PoweredBy = props => {
  const { classes } = props;
  if (!props.pharmacyInfo?.customerName) {
    return null;
  }
  return (
    <div className={classes.poweredBy} data-qa-id="powered-by">
      Powered by {props.pharmacyInfo?.customerName}
    </div>
  );
};
export default PoweredBy;

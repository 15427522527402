import React from 'react';
import DcQuestionnaire from 'containers/tasks/data-collect/dc-questionnaire';

export const validatorValid = () => undefined;
export const validatorInvalid = () => 'Invalid questionnaire state';

export default ({ providers, field, label, input, meta, disabled, labelSize, qaId }) => (
  <DcQuestionnaire
    label={label}
    input={input}
    meta={meta}
    disabled={disabled}
    labelSize={labelSize}
    qaId={qaId}
    required={field.required}
    dataTypeField={providers[field.provider]}
    dataCollectId={providers.dataCollectId}
    patientClinicalDataId={providers.patientClinicalDataId}
    onValidityChange={v => {
      field.formValidators = field.required ? [v ? validatorValid : validatorInvalid] : [];
    }}
  />
);

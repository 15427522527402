/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid, Checkbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector, useDispatch } from 'react-redux';
import DynamicForm from 'components/dynamic-form/dynamic-form';
import { MedicationStatus, InterventionSeverityEnum } from 'constants/enums';
import DynamicField from 'components/dynamic-form/dynamic-field';
import { DurStatus } from 'interfaces/enums/TaskStatuses/DrugUtilizationReviewStatus';
import { TaskCategory } from 'interfaces/enums/TaskCategory';

import {
  DUR,
  INT,
  DUR_INTERACTION_TYPE_DDI,
  DUR_INTERACTION_TYPE_DAI,
  DUR_INTERACTION_TYPE_DFI,
  DUR_INTERACTION_TYPE_DDC,
  DUR_DUPLICATE_THERAPY_ALERT,
  SET_DUR_HAS_INTERVENTION,
  DUR_INT_OUTCOME_MED_DISCONTINUED_ID,
} from 'constants/index';

// eslint-disable-next-line max-len
import InterventionCopyContentButton from 'components/intervention-button/intervention-copy-content-button';
import { convertToArborDate, getCustomerCurrentArborDate } from 'models/time/arbor-date';
import { change } from 'redux-form';
import {
  legendFromSeverity,
  filterInteractionsByType,
  sortAndFilterInteractions,
  formatConceptsNameForInteraction,
} from '../../../containers/tasks/drug-utilization-review/common';
import providerBuilder from '../providers/provider-builder';
import { styles } from '../../form/field/field-styles';

const useStyles = makeStyles(styles);

const areSelectableMedsForInteractingDropdown = (interaction, medications) => {
  const interactionMedicationsGpis = interaction?.concepts
    ?.filter(concept => concept.fdb_concept_type_id === 1)
    .map(concept => concept.gpi);

  if (interaction !== null) {
    const interactingMedications = medications?.filter(({ gpi }) =>
      interactionMedicationsGpis.includes(gpi),
    );
    const selectableInteractingMedications = interactingMedications?.filter(
      med => med.active && med.status_code === MedicationStatus.Active && !med.therapy_id,
    );
    return selectableInteractingMedications.length > 0;
  }
  return false;
};

const DurInterventionForm = ({ interaction, patient, isSelected }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [interventionVisible, setInterventionVisible] = useState(
    (interaction.is_create_intervention_unchecked === 0 &&
      interaction.arbor_does_recommend_intervention === 1) ||
      interaction.intervention_id !== null,
  );
  const formsState = useSelector(state => state.form);
  const [showInteractionMedsSelect, setShowInteractionMedsSelect] = useState(false);
  const state = useSelector(s => s);
  const medications = useSelector(s => s.medications?.medicationGroups);

  const initialValuesInt = {
    patient_id: patient.id,
    interaction_id: interaction.id,
    category_id: (interaction.intervention && interaction.intervention.category_id) || 2,
    type_id: 9,
    taskType: INT,
    followup_dt:
      (interaction.intervention && interaction.intervention.followup_dt) ||
      getCustomerCurrentArborDate(),
    completed_dt: (interaction.intervention && interaction.intervention.completed_dt) || null,
    severity_id: interaction?.intervention?.severity_id || InterventionSeverityEnum.Low,
    service_group_id: interaction.service_group_id,
    recommendation: (interaction.intervention && interaction.intervention.recommendation) || null,
    outcome: (interaction.intervention && interaction.intervention.outcome) || null,
    additional_reason:
      (interaction.intervention && interaction.intervention.additional_reason) || null,
    interacting_drugs: interaction.concepts ? formatConceptsNameForInteraction(interaction) : '',
    drug_interaction_description: interaction.screen_message,
    does_recommend_intervention: interaction.arbor_does_recommend_intervention,
    interaction,
    status_id: interaction?.intervention?.status_id,
    intervention_checked:
      (interaction.is_create_intervention_unchecked === 0 &&
        interaction.arbor_does_recommend_intervention === 1) ||
      interaction.intervention_id !== null,
    interacting_medications_ids: interaction?.intervention?.interacting_medications_ids,
    details: interaction.intervention?.details,
  };

  const providers = providerBuilder(INT)(initialValuesInt, state);

  const formName = `dur_intervention_${interaction.id}`;

  // eslint-disable-next-line camelcase
  const detailsVal = formsState[formName]?.values?.additional_reason || null;

  const setVisible = () => {
    Promise.all([
      dispatch(
        change(formName, 'intervention_checked', !formsState[formName].values.intervention_checked),
      ),
      dispatch({
        type: SET_DUR_HAS_INTERVENTION,
        payload: { formName, checked: !formsState[formName].values.intervention_checked },
      }),
    ]).then(_ => setInterventionVisible(!interventionVisible));
  };

  const setCompletedDt = () => {
    dispatch(
      change(formName, 'completed_date', convertToArborDate(new Date()).getUtcDatetime(false)),
    );
  };

  const formSelectedRecommendation = formsState[formName]?.values?.recommendation;
  const formSelectedOutcome = formsState[formName]?.values?.outcome;

  const checkIfShowDiscontinueMedications = (selectedRecommendationID, selectedOutcomeId) => {
    const recommendationAndOutcomeMetValues =
      Number(selectedRecommendationID) === DUR_INT_OUTCOME_MED_DISCONTINUED_ID &&
      Number(selectedOutcomeId) === DUR_INT_OUTCOME_MED_DISCONTINUED_ID;
    setShowInteractionMedsSelect(recommendationAndOutcomeMetValues);
  };

  useEffect(() => {
    const recommendationAndOutcomeMetValues =
      Number(interaction?.intervention?.outcome) === DUR_INT_OUTCOME_MED_DISCONTINUED_ID &&
      Number(interaction?.intervention?.recommendation) === DUR_INT_OUTCOME_MED_DISCONTINUED_ID;
    setShowInteractionMedsSelect(recommendationAndOutcomeMetValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderMedicationsDropdown = () => {
    if (showInteractionMedsSelect) {
      return (
        <Grid item xs={6}>
          <DynamicField
            id="interacting_medications_ids"
            property="interacting_medications_ids"
            component="dropdownMultiple"
            provider="interactingMeds"
            label="Medications"
            disabled={
              interaction?.intervention !== null &&
              [TaskCategory.Done, TaskCategory.Archived].includes(
                interaction?.intervention?.status_category_id,
              )
            }
          />
        </Grid>
      );
    }
    return null;
  };

  return (
    <DynamicForm
      formId={formName}
      providers={providers}
      initialValues={initialValuesInt}
      key={formName}
    >
      <Grid
        container
        className={isSelected ? classes.selectedIntervention : classes.interventionContainer}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="flex-start" alignItems="center">
            {!interaction.intervention && interaction.intervention_id === null && (
              <Grid item xs={1}>
                <Checkbox
                  checked={interventionVisible}
                  color="primary"
                  onChange={setVisible}
                  disabled={interaction.intervention && interaction.intervention_id !== null}
                />
              </Grid>
            )}
            <Grid item xs={11}>
              <Typography>
                <Typography className={classes.fieldLabelLarge} display="inline">
                  {interaction.concepts ? formatConceptsNameForInteraction(interaction) : ''}
                </Typography>
                {' - '}
                {interaction.interaction_type && interaction.interaction_type.code}
                {' - '}
                {legendFromSeverity({ severity: interaction.arbor_severity_id, inline: true })}
                {interaction.isNew && (
                  <Typography
                    component="span"
                    key="is_specialty"
                    className={classes.newInteractionLabel}
                  >
                    New
                  </Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {interventionVisible && (
          <>
            <Grid item xs={3}>
              <DynamicField
                id="category"
                property="category_id"
                type="text"
                component="dropdown"
                provider="categories"
                label="Category"
              />
            </Grid>

            <Grid item xs={3}>
              <DynamicField
                id="type"
                property="type_id"
                type="text"
                component="dropdownStatic"
                provider="type_id"
                label="Type"
                value="DUR"
              />
            </Grid>
            <Grid item xs={3}>
              <DynamicField
                id="followup_dt"
                property="followup_dt"
                type="date"
                component="date"
                label="Follow up Date"
              />
            </Grid>
            <Grid item xs={3}>
              <DynamicField
                id="severity_id"
                property="severity_id"
                type="text"
                component="dropdown"
                provider="severities"
                label="Severity"
                required="true"
              />
            </Grid>
            <Grid item xs={3}>
              <DynamicField
                id="service_group_id"
                property="service_group_id"
                type="integer"
                required="true"
                displayFormat="serviceGroupDisplayFormatter"
                component="dropdown"
                provider="serviceGroups"
                label="Service Group"
              />
            </Grid>
            <Grid item xs={3}>
              <DynamicField
                id="interacting_drugs"
                property="interacting_drugs"
                type="text"
                component="textStatic"
                multiline
                rowsMax={4}
                label="Interacting Drugs"
              />
            </Grid>
            <Grid item xs={6}>
              <DynamicField
                id="drug_interaction_description"
                property="drug_interaction_description"
                type="text"
                component="textStatic"
                label="Drug Interaction Description"
              />
            </Grid>
            <Grid item xs={6}>
              <DynamicField
                id="recommendation"
                property="recommendation"
                type="text"
                component="dropdown"
                provider="recommendations"
                label="Recommendation"
                onChange={selectedRecommendation => {
                  checkIfShowDiscontinueMedications(selectedRecommendation, formSelectedOutcome);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <DynamicField
                id="outcome"
                property="outcome"
                type="text"
                component="dropdown"
                provider="outcomes"
                label="Outcome"
                onChange={selectedOutcomeId => {
                  setCompletedDt(selectedOutcomeId);
                  checkIfShowDiscontinueMedications(formSelectedRecommendation, selectedOutcomeId);
                }}
              />
            </Grid>
            {renderMedicationsDropdown()}
            <Grid item xs={6}>
              <DynamicField
                id="details"
                property="details"
                type="text"
                component="text"
                label="Details"
              />
            </Grid>
            <Grid item xs={12}>
              <InterventionCopyContentButton
                // interventionType is always DUR for this form
                interventionType={DUR}
                details={detailsVal}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid item xs={12} hidden>
        <DynamicField id="patient_id" property="patient_id" type="integer" component="text" />
        <DynamicField id="status_id" property="status_id" type="integer" component="text" />
        <DynamicField
          id="completed_dt"
          property="completed_date"
          type="datetime"
          component="hidden"
        />
        <DynamicField
          id="intervention_checked"
          property="intervention_checked"
          type="boolean"
          component="hidden"
        />
      </Grid>
    </DynamicForm>
  );
};

export default () => {
  const tasks = useSelector(state => state.tasks.data);
  const patient = useSelector(state => state.patient);
  const durTasks = Object.values(tasks).filter(t => t.taskType === DUR);
  const prevDurTask = durTasks.find(({ status_id: statusId }) =>
    [DurStatus.Reviewed].includes(statusId),
  );
  const activeDurTask = durTasks.find(({ status_id: statusId }) =>
    [
      DurStatus.Required,
      DurStatus.Generated,
      DurStatus.ReadyForReview,
      DurStatus.Intervening,
    ].includes(statusId),
  );

  if (activeDurTask && activeDurTask.interactions) {
    activeDurTask.interactions.forEach(x => {
      if (!prevDurTask) {
        // First dur for patient
        x.isNew = true;
      } else {
        x.isNew =
          activeDurTask.new_interactions && activeDurTask.new_interactions.some(i => i.id === x.id);
      }
    });
  }

  const classes = useStyles();
  let ddis = [];
  let dfis = [];
  let dais = [];
  let ddci = [];
  let ddta = [];
  if (activeDurTask && activeDurTask.interactions) {
    ddis = sortAndFilterInteractions(
      filterInteractionsByType(activeDurTask.interactions, DUR_INTERACTION_TYPE_DDI),
      activeDurTask.newInteractionsOnly,
      true,
      true,
    );
    dfis = sortAndFilterInteractions(
      filterInteractionsByType(activeDurTask.interactions, DUR_INTERACTION_TYPE_DFI),
      activeDurTask.newInteractionsOnly,
      true,
      true,
    );
    dais = sortAndFilterInteractions(
      filterInteractionsByType(activeDurTask.interactions, DUR_INTERACTION_TYPE_DAI),
      activeDurTask.newInteractionsOnly,
      true,
      true,
    );
    ddci = sortAndFilterInteractions(
      filterInteractionsByType(activeDurTask.interactions, DUR_INTERACTION_TYPE_DDC),
      activeDurTask.newInteractionsOnly,
      true,
      true,
    );
    ddta = sortAndFilterInteractions(
      filterInteractionsByType(activeDurTask.interactions, DUR_DUPLICATE_THERAPY_ALERT),
      activeDurTask.newInteractionsOnly,
      true,
      true,
    );
  }
  return (
    <Grid item xs={12}>
      <Box className={classes.innerFormContainer}>
        <Typography className={classes.fieldLabel}>Drug-Drug Interventions</Typography>
        {ddis.length > 0 &&
          ddis.map(interaction => (
            <DurInterventionForm
              key={interaction.id}
              interaction={{
                ...interaction,
                service_group_id: activeDurTask.service_group_id,
              }}
              isSelected={
                activeDurTask && activeDurTask.selected_interventions
                  ? activeDurTask.selected_interventions.some(
                      x => x === interaction.intervention_id,
                    )
                  : null
              }
              patient={patient}
            />
          ))}
        <Typography className={classes.fieldLabel}>Drug-Allergy Interventions</Typography>
        {dais.length > 0 &&
          dais.map(interaction => (
            <DurInterventionForm
              key={interaction.id}
              interaction={{
                ...interaction,
                service_group_id: activeDurTask.service_group_id,
              }}
              isSelected={
                activeDurTask && activeDurTask.selected_interventions
                  ? activeDurTask.selected_interventions.some(
                      x => x === interaction.intervention_id,
                    )
                  : null
              }
              patient={patient}
            />
          ))}
        <Typography className={classes.fieldLabel}>Drug-Food Interventions</Typography>
        {dfis.length > 0 &&
          dfis.map(interaction => (
            <DurInterventionForm
              key={interaction.id}
              interaction={{
                ...interaction,
                service_group_id: activeDurTask.service_group_id,
              }}
              isSelected={
                activeDurTask && activeDurTask.selected_interventions
                  ? activeDurTask.selected_interventions.some(
                      x => x === interaction.intervention_id,
                    )
                  : null
              }
              patient={patient}
            />
          ))}
        <Typography className={classes.fieldLabel}>Drug-Disease Interventions</Typography>
        {ddci.length > 0 &&
          ddci.map(interaction => (
            <DurInterventionForm
              key={interaction.id}
              interaction={{
                ...interaction,
                service_group_id: activeDurTask.service_group_id,
              }}
              isSelected={
                activeDurTask && activeDurTask.selected_interventions
                  ? activeDurTask.selected_interventions.some(
                      x => x === interaction.intervention_id,
                    )
                  : null
              }
              patient={patient}
            />
          ))}
        <Typography className={classes.fieldLabel}>Duplicate Therapies Interventions</Typography>
        {ddta.length > 0 &&
          ddta.map(interaction => (
            <DurInterventionForm
              key={interaction.id}
              interaction={{
                ...interaction,
                service_group_id: activeDurTask.service_group_id,
              }}
              isSelected={
                activeDurTask && activeDurTask.selected_interventions
                  ? activeDurTask.selected_interventions.some(
                      x => x === interaction.intervention_id,
                    )
                  : null
              }
              patient={patient}
            />
          ))}
      </Box>
    </Grid>
  );
};

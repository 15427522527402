import { UPDATE_TASK_LIST_ITEMS } from 'constants/index';
import HTTP from '../services/http';

export class FillCoordinationClient {
  static moveToRequiredState = async (payload: any): Promise<any> => {
    const { tasks, patient_id } = payload;
    const task_ids = tasks.map((task: any) => task.id);
    const url = '/tasks/fc/move-to-required-state';
    const new_payload = { task_ids, patient_id };
    const response = await HTTP.patch(url, new_payload);
    const { updated_tasks } = response.data;
    return Promise.resolve(updated_tasks);
  };

  static moveToRequiredStateAndSendSecureLink = async (payload: any): Promise<any> => {
    const { tasks, smsPhoneNumber, patientId } = payload;
    const task_ids = tasks.map((task: any) => task.id);
    const url = '/tasks/fc/move-to-required-and-send-link';
    const new_payload = { task_ids, sms_phone_number: smsPhoneNumber, patient_id: patientId };
    const response = await HTTP.patch(url, new_payload);
    const { updated_tasks } = response.data;
    return Promise.resolve(updated_tasks);
  };
}

import React from 'react';
import { renderDropdown as RenderDropdown } from 'components/form/field/redux-field';
import useProvider from 'hooks/useProvider';
import { valueLabelFormatter } from '../formatters';

export default ({ providers, field, label, input, meta, disabled, labelSize, qaId }) => {
  const fields = useProvider(providers, field.provider, [], valueLabelFormatter);

  return (
    <RenderDropdown
      label={label}
      input={input}
      meta={meta}
      disabled={disabled}
      labelSize={labelSize}
      id={qaId}
      fields={fields}
      isMulti={field.component === 'dropdownMultiple'}
      plainTextValue={field.plainTextValue}
      maxOptions={field.maxOptions}
      preventEmpty={field.preventEmptyValue}
      closeMenuOnSelect={field.closeMenuOnSelect}
      blurInputOnSelect={field.blurInputOnSelect}
    />
  );
};

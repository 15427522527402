import React from 'react';
import { Grid, Button } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { UserUtils } from 'utils/user-utils';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { UserPermissions } from 'interfaces/user/Permissions';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { IGetIcdProtocolsResponse } from 'models/application-manager/cpmp/IGetIcdProtocolsResponse';
import { IGetSpecialtyResponse } from 'models/application-manager/cpmp/IGetSpecialtyResponse';
import { useDebounce } from 'use-debounce';
import { SectionTitleStyled } from '../../components/section-title';
import { SearchField } from '../../components/search-field';
import { CpmpStyles } from '../index.styles';
import { DataTable } from './tables/data-table';
import { AddNewIcdModal } from './modal/add-new-icd-modal';

const searchFieldPlaceholder = 'Specialty, ICD-10, DC Item';
const initialSearchTerm = '';
const debounceTimeMs = 500;

type ICpmpProps = WithStyles<typeof CpmpStyles>

const CpmpIcd = (props: ICpmpProps): JSX.Element => {
  const { classes } = props;

  // # region redux state
  const user = useTypedSelector(state => state.auth.currentUser);
  const stateSelectedCustomerId = useTypedSelector(state => state.filters.selectedCustomerId);
  const customers = useTypedSelector(state => state.filters.customers);
  // # endregion

  // #region useState
  const [selectedCustomerId, setSelectedCustomerId] =
    React.useState<number>(stateSelectedCustomerId);
  const [searchTerm, setSearchTerm] = React.useState<string>(initialSearchTerm);
  const [reloadTrigger, setReloadTrigger] = React.useState<Date | undefined>(undefined);
  const [icdProtocols, setIcdProtocols] = React.useState<IGetIcdProtocolsResponse>({ result: [] });
  const [specialties, setSpecialties] = React.useState<IGetSpecialtyResponse>({ result: [] });
  const [debouncedSearchTerm] = useDebounce<string | undefined>(searchTerm, debounceTimeMs);
  const [addModalOpen, setAddModalOpen] = React.useState<boolean>(false);
  const [reloadDataTrigger, setReloadDataTrigger] = React.useState<Date | undefined>(undefined);
  const DataTableContext = React.createContext(icdProtocols);
  // #endregion

  // # region useEffect
  React.useEffect(() => {
    if (selectedCustomerId == null) {
      if (stateSelectedCustomerId != null) {
        setSelectedCustomerId(stateSelectedCustomerId);
      } else if (customers.length > 0) {
        setSelectedCustomerId(customers[0].id);
      }
    }
  }, [stateSelectedCustomerId]);

  React.useEffect(() => {
    let active = true;
    (async () => {
      const result = await ApplicationManagerClient.fetchIcdProtocols();
      if (result.data.result.length > 0) {
        setIcdProtocols({ result: result.data.result });
      }
    })();
    return () => {
      active = false;
    };
  }, [reloadDataTrigger]);

  React.useEffect(() => {
    let active = true;
    (async () => {
      const result = await ApplicationManagerClient.fetchSpecialties();
      if (result.data.result.length > 0) {
        setSpecialties({ result: result.data.result });
      }
    })();
    return () => {
      active = false;
    };
  }, []);
  // #endregion

  // # region memos
  const userHasPermissionToaddDcItem = React.useMemo<boolean>(() => {
    return UserUtils.userIsPermitted(UserPermissions.ApplicationManagerCpmpEdit);
  }, [user]);

  const userManageUserLevel = React.useMemo<boolean>(() => {
    return UserUtils.userIsPermitted(UserPermissions.ApplicationManagerUsersManageUserLevel);
  }, [user]);
  // # endregion

  const renderAddIcdProtocolButton = (): JSX.Element => {
    return (
      <Button
        variant="contained"
        className={classes.addButton}
        onClick={() => setAddModalOpen(true)}
      >
        Add ICD-10 Protocol
      </Button>
    );
  };

  const renderEnableIcdModal = (): JSX.Element => {
    return (
      <AddNewIcdModal
        open={addModalOpen}
        onCancel={() => setAddModalOpen(false)}
        onSuccess={() => setReloadDataTrigger(new Date())}
        editingIcdItem={undefined}
        icdProtocols={icdProtocols}
        specialties={specialties.result}
      />
    );
  };

  return (
    <>
      {renderEnableIcdModal()}
      {userHasPermissionToaddDcItem || userManageUserLevel ? (
        <SectionTitleStyled
          title="ICD-10 Codex"
          rightSideItems={[
            {
              element: renderAddIcdProtocolButton(),
              key: 'add-icd-item-button',
            },
          ]}
        />
      ) : (
        <SectionTitleStyled title="ICD-10 Codex" />
      )}
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container justifyContent="flex-end">
            <Grid item xs="auto">
              <Grid container spacing={2}>
                <Grid item>
                  <SearchField
                    width={48}
                    value={searchTerm}
                    onChange={setSearchTerm}
                    initialValue={initialSearchTerm}
                    placeholder={searchFieldPlaceholder}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <DataTableContext.Provider value={icdProtocols}>
              <DataTable
                searchTerm={debouncedSearchTerm}
                triggerReload={reloadTrigger}
                handleReloadDataTrigger={date => setReloadDataTrigger(date)}
                userHasPermissionToEditIcdItem={userHasPermissionToaddDcItem || userManageUserLevel}
                activeAnyField={searchTerm.length > 0}
                icdProtocolsList={icdProtocols}
                specialties={specialties.result}
              />
            </DataTableContext.Provider>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(CpmpStyles)(CpmpIcd);

export enum DocumentGeneratorType {
  FillCoordination = 'FC',
}

export enum DocumentTemplate {
  DC_Questionnaire = 'DC_Questionnaire.html',
  FC_OrderForm = 'FC_OrderForm.html',
  FC_OrderFormDelivery = 'FC_OrderFormDelivery.html',
  FC_OrderFormMedications = 'FC_OrderFormMedications.html',
}

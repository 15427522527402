import moment from 'moment';
import { convertToArborDate } from 'models/time/arbor-date';

export const stringFilter = (filter, row) => {
  const retVal = row[filter.id]
    ? row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
    : false;
  return retVal;
};

export const dateFilter = (filter, row) => {
  const value = row[filter.id];
  if (value.getMonth) {
    return convertToArborDate(value.toISOString(), true)
      .getCustomerDate(true)
      .includes(filter.value);
  }
  return false;
};

export const dateSorting = (a, b) => {
  if (!a) {
    return -1;
  }
  return moment(a).isBefore(moment(b)) ? -1 : 1;
};

export const dateAndManagedFilter = (filter, row) => {
  // eslint-disable-next-line
  if (row && row['_original'] && row['_original'].showIcon) {
    return true;
  }
  const value = row[filter.id];
  if (value) {
    if (filter && filter.value) {
      const rowDate = new Date(
        convertToArborDate(new Date(value).toISOString(), true).getCustomerDate(true),
      );
      const filterDate = new Date(filter.value);
      return rowDate >= filterDate;
    }
  }
  return true;
};

/**
 * Allows for filtering in cases where the column uses a custom Cell render function.
 * It matches the searching text with the rendered value returned by the Cell function.
 */
export const customCellStringFilter = (a, b, c) =>
  c.Cell({ value: b[a.id] }).toLowerCase().indexOf(a.value.toLowerCase()) > -1;

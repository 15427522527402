import React from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { useDebounce } from 'use-debounce';
import { useDispatch } from 'react-redux';
import { UserUtils } from 'utils/user-utils';
import { UserPermissions } from 'interfaces/user/Permissions';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { IGetCdmProgramsResponse } from 'models/application-manager/cpmp/IGetCdmProgramsResponse';
import { DataTable } from './table/data-table';

import { CpmpStyles } from '../index.styles';
import { SectionTitleStyled } from '../../components/section-title';
import { SearchField } from '../../components/search-field';
import { IRsProtocolFormModalRef, RsProtocolFormModal } from './form-modal';
import { notifyError, notifySuccess } from '../../../../actions/action-notifications';

const searchFieldPlaceholder = 'Disease state, ICD-10';
const initialSearchTerm = '';
const debounceTimeMs = 1000;

type ICpmpProps = WithStyles<typeof CpmpStyles>;

const CpmpRsProtocol = (props: ICpmpProps): JSX.Element => {
  const { classes } = props;
  const [reloadTrigger, setReloadTrigger] = React.useState<Date | undefined>(undefined);
  const [searchTerm, setSearchTerm] = React.useState<string>(initialSearchTerm);
  const [debouncedSearchTerm] = useDebounce<string | undefined>(searchTerm, debounceTimeMs);
  const [reloadDataTrigger, setReloadDataTrigger] = React.useState<Date | undefined>(undefined);
  const [cdmProgramsData, setCdmProgramsData] = React.useState<IGetCdmProgramsResponse>({
    result: [],
  });
  const DataTableContext = React.createContext(cdmProgramsData);
  const customers = useTypedSelector(state => state.filters.customers);
  const selectedCustomerId = useTypedSelector(state => state.filters.selectedCustomerId);
  const dispatch = useDispatch();

  const addFormModalRef = React.useRef<IRsProtocolFormModalRef>(null);

  React.useEffect(() => {
    let active = true;
    (async () => {
      const result = await ApplicationManagerClient.fetchCdmProgramData();
      if (result.data.result.length > 0) {
        setCdmProgramsData({ result: result.data.result });
      }
    })();
    return () => {
      active = false;
    };
  }, [reloadDataTrigger, reloadTrigger]);

  const allCustomers = React.useMemo(() => {
    return customers.map(c => ({
      label: c.name,
      value: c.id,
    }));
  }, [customers]);

  const currentCustomer = React.useMemo(() => {
    return allCustomers.find(c => c.value === selectedCustomerId);
  }, [selectedCustomerId, allCustomers]);

  const userHasPermissionToAddUpdateRsprotocol = React.useMemo<boolean>(() => {
    if (currentCustomer) {
      const test = UserUtils.userIsPermitted(
        UserPermissions.ApplicationManagerCpmpRsProtocolEdit,
        currentCustomer.value,
      );
      return test;
    }
    return false;
  }, [currentCustomer]);

  const renderAddRsButton = () => (
    <Button
      variant="contained"
      className={classes.addButton}
      onClick={() => {
        addFormModalRef.current?.setOpen(true);
      }}
    >
      Add RS Protocol
    </Button>
  );

  const getCdmProgramData = (): IGetCdmProgramsResponse => {
    return cdmProgramsData;
  };

  const onRsProtocolFormSuccess = () => {
    const message = 'RS protocol information was saved successfully';
    dispatch(notifySuccess(message));
    setReloadTrigger(new Date());
  };

  const responseDict: Record<string, string> = {
    'There is already a RS protocol for this gpi_10 and specialty_id':
      'RS Assessment / Plan for GPI and Disease State already exists',
  };

  const onRsProtocolFormError = (err: any) => {
    const errMessage: string = err?.response?.data?.message || err?.message;
    const message =
      responseDict[errMessage] || errMessage || 'Failed to save Rs protocol information';
    dispatch(notifyError(message));
  };

  return (
    <>
      {userHasPermissionToAddUpdateRsprotocol && (
        <SectionTitleStyled
          title="RS Protocols"
          rightSideItems={[
            {
              element: renderAddRsButton(),
              key: 'add-rs-protocol-item-button',
            },
          ]}
        />
      )}

      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container justifyContent="flex-end">
            <Grid item xs="auto">
              <Grid container spacing={0}>
                <Grid item xs="auto">
                  <SearchField
                    width={48}
                    value={searchTerm}
                    onChange={setSearchTerm}
                    initialValue={initialSearchTerm}
                    placeholder={searchFieldPlaceholder}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <DataTableContext.Provider value={cdmProgramsData}>
            <DataTable
              searchTerm={debouncedSearchTerm}
              triggerReload={reloadTrigger}
              handleReloadDataTrigger={date => setReloadDataTrigger(date)}
              userHasPermissionToAddUpdateRsprotocol={userHasPermissionToAddUpdateRsprotocol}
              activeAnyField={searchTerm.length > 0}
              cdmProgramsList={cdmProgramsData}
              onRsProtocolFormSuccess={onRsProtocolFormSuccess}
            />
          </DataTableContext.Provider>
        </Grid>
      </Grid>
      <RsProtocolFormModal
        ref={addFormModalRef}
        onSuccess={onRsProtocolFormSuccess}
        getCdmProgramData={getCdmProgramData}
      />
    </>
  );
};

export default withStyles(CpmpStyles)(CpmpRsProtocol);

import { Grid } from '@mui/material';
import React from 'react';
import compose from 'recompose/compose';
import withStyles from '@mui/styles/withStyles';
import { Field, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { required } from '../form/validation/validation';
import { updateNewDocument } from '../../actions/action-uploaded-documents';
import { notifySuccess, notifyError } from '../../actions/action-notifications';
import { updateDocument } from '../../services/utils/upload-document-service';
import { renderTextField } from '../form/field/redux-field';
import { styles } from '../../containers/patient/documents/documents-styles';

const DocumentArchiveReasonForm = props => {
  const {
    onArchiveReasonClose,
    customerId,
    patientId,
    documentId,
    fileName,
    labels,
    handleSubmit,
    classes,
    pristine,
  } = props;
  const dispatch = useDispatch();

  const submitArchive = async values => {
    const newNoteText = undefined;
    try {
      const updateDocumentResult = await updateDocument(
        customerId,
        patientId,
        documentId,
        fileName,
        labels,
        newNoteText,
        !document.is_archived,
        values.archived_reason,
      );
      dispatch(updateNewDocument(updateDocumentResult));
      dispatch(notifySuccess('Document archived'));
    } catch {
      dispatch(notifyError('Could not archive document'));
    }
    onArchiveReasonClose();
  };

  return (
    <form
      onSubmit={handleSubmit(submitArchive)}
      className={classes.archiveReasonContainer}
      autoComplete="off"
    >
      <Grid container>
        <Grid item xs={12}>
          <Field
            name="archived_reason"
            label="Archived Reason"
            component={renderTextField}
            width="95%"
            multiline
            rows={3}
            validate={[required]}
            inputRef={input => input && input.focus()}
          />
        </Grid>
        <Grid item xs={12} className={classes.buttonContainer}>
          <ConfirmationPanel
            cancelButtonName="archived_reason_cancel_button"
            submitButtonName="archived_reason_submit_button"
            handleCancel={onArchiveReasonClose}
            disableSubmit={pristine}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(
  reduxForm()(DocumentArchiveReasonForm),
);

import React from 'react';
import { Box, SxProps, Typography } from '@mui/material';
import { IPatient } from 'interfaces/redux/IPatient';
import { displayMedSyncDate } from '../med-sync-utils';
import { MedSyncDisplayStatus } from '../common';

export default function MedSyncAnchorDate({
  anchorDate,
  displayStatus,
  sx = {},
}: {
  anchorDate?: IPatient['anchor_date'] | null;
  displayStatus?: MedSyncDisplayStatus;
  sx?: SxProps;
}) {
  return displayStatus !== MedSyncDisplayStatus.NotSynchronized ? (
    <Box
      sx={{
        color: displayStatus === MedSyncDisplayStatus.InConflict ? '#B5302C' : '#5BA389',
        display: 'inline-flex',
        flexFlow: 'row nowrap',
        marginRight: 'auto',
        ...sx,
      }}
    >
      <Typography fontWeight={500} letterSpacing="-0.025rem" fontSize="13px">
        {displayMedSyncDate(anchorDate)}
      </Typography>
    </Box>
  ) : null;
}

import { takeLatest, takeEvery, select, put, call } from 'redux-saga/effects';
import { convertToArborDate } from 'models/time/arbor-date';
import { notifyError } from 'actions/action-notifications';
import {
  SIDEBAR_FILTERS_CLEAR_IDS,
  SIDEBAR_FILTERS_CLEAR,
  SIDEBAR_FILTERS_SET,
  FETCH_SCHEDULES_REQUEST,
  FETCH_SCHEDULES_SUCCESS,
  FETCH_SCHEDULES_FAILURE,
  FETCH_USER_PREFERENCES,
  LOAD_SCHEDULES,
} from '../constants';
import { getDatesFromSidebarFilters } from '../services/utils/filters-service';
import HTTP from '../services/http';

function* workerFetchSchedulesSaga(action) {
  const state = yield select();
  const { sidebarFilters, router } = state;
  const filters = sidebarFilters.data.schedule;
  if (
    router &&
    router.location &&
    router.location.pathname &&
    (router.location.pathname.includes('schedule') ||
      router.location.search.includes('schedule')) &&
    state.userPreferences.loaded
  ) {
    const { from, to } = getDatesFromSidebarFilters(filters);
    const { paging, orderBy, orderDirection, pageSize } = action.payload ?? {};

    let lastReceivedId = null;
    if (paging && state.schedules.schedules.length) {
      const currentList = state.schedules.schedules;
      lastReceivedId = currentList[currentList.length - 1].id;
    }

    const fromDate = from && convertToArborDate(from, false).getUtcDatetime();
    const toDate = to && convertToArborDate(to, false).getUtcDatetime();
    const serviceGroups =
      filters.serviceGroups && filters.serviceGroups.length
        ? filters.serviceGroups.map(group => group.idValue || group.value || group)
        : [];
    const customerClinics =
      filters.customerClinics && filters.customerClinics.length
        ? filters.customerClinics.map(group => group.idValue || group.value || group)
        : [];
    const appointmentClinics =
      filters.appointmentClinics && filters.appointmentClinics.length
        ? filters.appointmentClinics.map(group => group.idValue || group.value || group)
        : [];
    const appointmentStatuses = filters.appointmentStatuses
      ? filters.appointmentStatuses.map(status => status.id)
      : [];
    const patientStatuses = filters.patientStatuses
      ? filters.patientStatuses.map(status => status.id)
      : [];

    if (action?.payload?.noApiCall) {
      return;
    }

    const request = HTTP.post(
      '/schedule',
      {
        fromDate,
        toDate,
        serviceGroups,
        customerClinics,
        appointmentClinics,
        lastReceivedId,
        appointmentStatuses,
        patientStatuses,
        orderBy,
        orderDirection,
        pageSize,
      },
      {},
    );

    try {
      yield put({
        type: FETCH_SCHEDULES_REQUEST,
        meta: { paging: !!paging },
      });
      const response = yield call(() => request);
      const { data } = response;
      yield put({
        type: FETCH_SCHEDULES_SUCCESS,
        payload: { data },
        meta: { paging: !!paging },
      });
    } catch (error) {
      yield put(
        notifyError('We had problems fetching the appointments. Please try again in a moment'),
      );
      yield put({ type: FETCH_SCHEDULES_FAILURE });
    }
  }
}

export function* watcherScheduleSaga() {
  yield takeLatest(
    [SIDEBAR_FILTERS_CLEAR_IDS, SIDEBAR_FILTERS_CLEAR, SIDEBAR_FILTERS_SET, FETCH_USER_PREFERENCES],
    workerFetchSchedulesSaga,
  );
  yield takeEvery(LOAD_SCHEDULES, workerFetchSchedulesSaga);
}

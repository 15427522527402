import createStyles from '@mui/styles/createStyles';
import colors from '../../../../lib/themes/colors.module.scss';

const { deepBlue, white } = colors;

const styles = (theme) => {
  return createStyles({
    container: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      fontSize: 18,
    },
    subContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
      padding: '50px 30px',
    },
    bluePage: {
      'backgroundColor': deepBlue,
      'justifyContent': 'center',
      '& h1': {
        margin: 0,
        fontSize: 26,
      },
      '& hr': {
        width: '95%',
      },
      'color': white,
      'textAlign': 'center',
    },
  });
};

export { styles };

import React from 'react';
import { Moment } from 'moment';
import withStyles from '@mui/styles/withStyles';
import { DeepPartial } from 'utils/types-util';
import SearchList from 'containers/patient/side-bar/search-side-bar/search-list';
import { SEARCH_SETTINGS } from 'constants/index';
import { styles } from './advanced-search.styles';
import { IResultListProps, IFormFields } from './interfaces';

const ResultList: React.FC<IResultListProps> = (props: IResultListProps) => {
  return <SearchList tableLabel={SEARCH_SETTINGS.ADVANCED_SEARCH_RESULT_TITLE} />;
};

export const AdvancedSearchResultList = withStyles(styles)(ResultList);

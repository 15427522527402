import React, { useEffect, useState, useCallback } from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import {
  // fetchMedicationList,
  fetchMedicationGroups,
} from 'actions/action-medications';
import { fetchPatientArchivedTasks } from 'actions/action-patient';
import { saveClinicalData } from 'actions/action-clinical-data';
import SingleDataCollectForm from 'components/dynamic-form/task-forms/single-data-collect-form';
import { EditAdd, EditPencilMd } from 'components/icons/icons';
import ListIcon from '@mui/icons-material/List';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import { dateSorting, stringFilter } from 'helpers/react-table';
import { convertToArborDate } from 'models/time/arbor-date';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { getDataType, getClinicalDataTypesMap } from 'services/utils/data-collect';
import DcQuestionnaire from 'containers/tasks/data-collect/dc-questionnaire';
import { DC, RS } from 'constants/index';
import { GeneratorUtil } from 'components/document/generator/generator-util';
import { getGeneratedDocument } from 'services/utils/document-service';
import { getUserById } from 'services/utils/users-service';
// eslint-disable-next-line max-len
import { QuestionnairePrintPayload } from 'components/document/generator/questionnaire-print-payload';
import { findMedicationByGpi10 } from 'services/utils/medication-service';
import { ClinicalDataClient } from 'clients/clinical-data';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { DownloadFileUtils } from 'utils/download-file-utils';
import { NumberUtils } from 'utils/number-utils';
import { useTable, usePagination, useExpanded, useSortBy, useFilters } from 'react-table-v7';
import classnames from 'classnames';
import { styles } from './patient-clinical-styles';
import { useClinicalData } from './use-clinical-data';
import { logger } from '../../../winston-logger';

const useStyles = makeStyles(styles);

function PatientClinical(props) {
  const {
    selectedPatientId,
    patient,
    therapies,
    users,
    clinicalDataTypes,
    displayOnly,
    tasks,
    taskType,
    input,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [displayAddData, handleAddData] = useState(false);
  const [questionnaire, setQuestionnaire] = useState(false);
  const [loading, setLoading] = useState(false);
  const patientState = useSelector(state => state.patient);
  const questionnaires = useSelector(state => state.lookups.patientQuestionnaires);
  const medications = useSelector(state => state.medications.medicationGroups);

  const clinicalDataItems = useClinicalData(selectedPatientId);
  const clinicalDataTypesMap = getClinicalDataTypesMap(clinicalDataTypes);
  const questionnaireDataTypes = [];

  Object.keys(clinicalDataTypesMap).forEach(dataTypeId => {
    const field = clinicalDataTypesMap[dataTypeId];
    if (field.type === 'questionnaire') {
      questionnaireDataTypes.push(dataTypeId);
    }
  });

  const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter } }) => {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
        className={classes.filterInput}
      />
    );
  };

  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <Grid item xs={12} className={classes.addDataContainer}>
        <SingleDataCollectForm
          dcItem={{
            data_type: `${row.original.categoryId}-${row.original.dataTypeId}`,
            date: row.original.date,
            therapy_id: row.original.therapy_id,
            value: row.original.value,
          }}
          patientLevel={!row.original.task_id}
          onCancel={() => row.toggleRowExpanded()}
          onSubmit={async values => {
            const dataTypeId = values.data_type.split('-')[1];
            values.value = NumberUtils.ensureLeadingZero(values.value);
            const updatedItemValues = {
              assessmentDate: values.date,
              cannotCompleteReason: row.original.cannot_complete_reason,
              clinicalDataTypeId: dataTypeId,
              id: row.original.id,
              patientClinicalDataId: row.original.patient_clinical_data_id,
              required: Boolean(row.original.required),
              taskDataCollectId: row.original.task_data_collect_id,
              therapyId: values.therapy_id,
              value: values.value,
            };
            await callAndDispatchSaveClinicalData([updatedItemValues]);
            row.toggleRowExpanded();
          }}
        />
      </Grid>
    ),
    [],
  );

  const ReactTableComp = ({ columns, data, renderRowSubComponent }) => {
    const filterTypes = React.useMemo(
      () => ({
        text: (rows, id, filterValue) => {
          return rows.filter(row => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      [],
    );

    const defaultColumn = React.useMemo(
      () => ({
        Filter: DefaultColumnFilter,
      }),
      [],
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      row,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        filterTypes,
        initialState: { pageIndex: 0 },
      },
      useFilters,
      useSortBy,
      useExpanded,
      usePagination,
    );

    return (
      <Grid className={classes.clinicalAssessmentTable}>
        <Grid className={classnames(classes.clinicalAssessmentTableContainer, undefined)}>
          <table className={classes.clinicalAssessmentTableMain} {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th className={classes.clinicalAssessmentHeaderTh}>
                      {column.render('Header') && (
                        <>
                          <tr className={classes.headerTr}>
                            <th
                              {...column.getHeaderProps(column.getSortByToggleProps())}
                              className={classes.headerTh}
                            >
                              {column.render('Header')}
                            </th>
                          </tr>
                          <tr className={classes.headerTr}>
                            <th>{column.canFilter ? column.render('Filter') : null}</th>
                          </tr>
                        </>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {!loading && (
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <>
                      <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className={
                                taskType &&
                                taskType === RS &&
                                ['Value', 'Assessment Date'].includes(cell?.column?.Header)
                                  ? `${classes.tbodyTr} ${classes.centerCell}`
                                  : classes.tbodyTr
                              }
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                      {row.isExpanded ? (
                        <tr>
                          <td colSpan={row.cells.length}>{renderRowSubComponent({ row })}</td>
                        </tr>
                      ) : null}
                    </>
                  );
                })}
              </tbody>
            )}
          </table>
        </Grid>
        <Grid container xs={12} className={classes.paginationBtnsContainer}>
          <Grid item xs={4} className={classes.paginationBtns}>
            <button type="button" onClick={() => previousPage(0)} disabled={!canPreviousPage}>
              Previous
            </button>
          </Grid>
          <Grid item xs={2} className={classes.paginationControls}>
            <span>
              Page{'  '}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                value={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: '100px' }}
              />{' '}
              of {pageOptions.length}
            </span>{' '}
          </Grid>
          <Grid item xs={2} className={classes.paginationControls}>
            <span>
              <select
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize} Rows
                  </option>
                ))}
              </select>
            </span>
          </Grid>
          <Grid item xs={4} className={classes.paginationBtns}>
            <button type="button" onClick={() => nextPage()} disabled={!canNextPage}>
              Next
            </button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getSubmittedByUserNameQuestionnaire = (questionnaireData, updatedBy) => {
    if (questionnaireData && questionnaireData.smsSent && questionnaireData.submittedDate) {
      return `${patientState.first_name} ${patientState.last_name}`;
    }
    if (!users || users.length === 0) {
      return 'Unknown User';
    }
    return users.find(user => user.id === updatedBy).username;
  };

  const clinicalDataItemToUIClinicalDataItem = it => {
    const dataType = getDataType(clinicalDataTypes, it.clinicalDataTypeId);
    // eslint-disable-next-line camelcase
    const therapyName = therapies[it?.therapyId]?.drug_name ?? '';
    const assessmentArborDate = convertToArborDate(it.assessmentDate);
    const assessmentDateFormatted = assessmentArborDate.getUtcDate(true);
    const questionnaireData = it.questionnaire;
    const itemQuestionnaireData = questionnaireData
      ? {
          id: questionnaireData.id,
          smsSent: questionnaireData.smsSent,
          submittedDate: questionnaireData.submittedDate,
          submittedBy: questionnaireData.submittedBy,
          answers: questionnaireData.answers,
          lastViewedDt: questionnaireData.lastViewedDt
            ? convertToArborDate(questionnaireData.lastViewedDt, true).getCustomerDate()
            : null,
          score: questionnaireData.score,
        }
      : undefined;

    return {
      id: it.id,
      cannot_complete_reason: it.cannotCompleteReason,
      required: it.required,
      categoryId: dataType.categoryId,
      categoryName: dataType.categoryName,
      typeName: dataType.name,
      dataTypeId: dataType.dataTypeId,
      value: it.value,
      therapyName,
      date: assessmentDateFormatted,
      recordedBy: getSubmittedByUserNameQuestionnaire(questionnaireData, it.updatedBy),
      therapy_id: it.therapyId,
      dataType,
      task_data_collect_id: it.taskDataCollectId,
      task_risk_strat_id: it.taskRiskStratId,
      questionnaireData: itemQuestionnaireData,
    };
  };
  const isLoading = !clinicalDataItems;

  const clinicalData = (clinicalDataItems || [])
    .filter(item => item.value)
    .map(item => clinicalDataItemToUIClinicalDataItem(item))
    .reduce((acc, it) => {
      if (acc.length) {
        let itemExists = false;
        for (const [index, savedItem] of acc.entries()) {
          if (
            it.dataTypeId === savedItem.dataTypeId &&
            it.value === savedItem.value &&
            it.date === savedItem.date &&
            !it.questionnaireData &&
            !savedItem.questionnaireData
          ) {
            if (!savedItem.therapyName.includes(it.therapyName)) {
              savedItem.therapyName += `, ${it.therapyName}`;
            }
            itemExists = true;
          } else if (
            it.questionnaireData &&
            Object.keys(it.questionnaireData).length &&
            savedItem.dataTypeId === it.dataTypeId &&
            !input &&
            ((savedItem.task_data_collect_id &&
              it.task_data_collect_id &&
              savedItem.task_data_collect_id === it.task_data_collect_id) ||
              (savedItem.task_risk_strat_id &&
                it.task_risk_strat_id &&
                savedItem.task_risk_strat_id === it.task_risk_strat_id))
          ) {
            if (it.id > savedItem.id) {
              acc[index] = it;
            }
            itemExists = true;
          }
        }
        if (!itemExists) {
          acc.push(it);
        }
      } else {
        acc.push(it);
      }
      return acc;
    }, []);

  useEffect(() => {
    dispatch(fetchMedicationGroups());
    if (selectedPatientId) {
      // fetching archived DCs to show in the clinical asssesment
      dispatch(fetchPatientArchivedTasks(selectedPatientId, false, [DC]));
    }
  }, [selectedPatientId]); // eslint-disable-line

  useEffect(() => {
    if (clinicalData && clinicalData.length) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [clinicalData]);

  const callAndDispatchSaveClinicalData = async values => {
    try {
      const response = await ClinicalDataClient.save(selectedPatientId, values);
      const action = saveClinicalData(response.data);
      dispatch(action);
      dispatch(notifySuccess('Clinical data saved'));
    } catch (error) {
      logger.error(error);
      dispatch(notifyError('Failed to save clinical data'));
    }
  };

  const columnsDisplayOnly = [
    {
      Header: 'Category',
      accessor: 'categoryName',
      sortType: 'basic',
      filterMethod: stringFilter,
      getHeaderProps: () => ({
        data_qa_id: 'category_name_header',
      }),
    },
    {
      Header: 'Type',
      accessor: 'typeName',
      sortType: 'basic',
      filterMethod: stringFilter,
      getHeaderProps: () => ({
        data_qa_id: 'type_name_header',
      }),
      width: 300,
    },
    {
      Header: 'Value',
      accessor: 'value',
      sortType: 'basic',
      filterMethod: (filter, row) => {
        const rowVal =
          typeof row[filter.id] === 'string' ? row[filter.id] : row[filter.id].toString();
        return rowVal && rowVal.toLowerCase().includes(filter.value.toLowerCase());
      },
      getHeaderProps: () => ({
        data_qa_id: 'value_header',
      }),
      Cell: row => {
        const { value, row: cellRow } = row;
        const { original } = cellRow;
        if (original.dataType?.type === 'questionnaire') {
          const onClick = () =>
            setQuestionnaire({
              dataTypeField: original.dataType,
              clinicalDataItemId: original.id,
            });

          const isPrintButtonVisible = () =>
            original.questionnaireData?.submittedDate &&
            original.dataTypeId === QuestionnairePrintPayload.RAPID_3_DATA_TYPE_ID;

          const printButtonFunctionality = async () => {
            const { questionnaireId, name, references } = original.dataType;
            const { answers, score, submittedDate, submittedBy } = original.questionnaireData;
            const { questions } = questionnaires[questionnaireId];
            const submittedByUser = getUserById(submittedBy, users);
            const pdc = new QuestionnairePrintPayload();
            const payload = await pdc.getPayload(
              patient,
              name,
              questions,
              answers,
              score,
              references,
              submittedByUser,
              submittedDate,
            );
            const generatedDocumentResponse = await getGeneratedDocument(
              payload,
              patient.customer_id,
              patient.id,
            );
            const viewableResult = GeneratorUtil.convertToViewable(
              generatedDocumentResponse.data,
              name,
            );
            DownloadFileUtils.downloadUrl(viewableResult.url, viewableResult.fileName);
          };

          const questionnaireButton = (
            <IconButton
              onClick={onClick}
              className={classes.questionnaireButton}
              name="questionnaire_button"
              size="large"
            >
              <ListIcon className={classes.questionnaireButtonIcon} />
            </IconButton>
          );
          const questionnairePrintButton = (
            <IconButton
              size="small"
              onClick={async () => {
                await printButtonFunctionality();
              }}
              name="questionnaire_print_button"
            >
              <PdfIcon />
            </IconButton>
          );

          return (
            <>
              {value}
              {questionnaireButton}
              {isPrintButtonVisible() && questionnairePrintButton}
            </>
          );
        }
        if (original.dataType?.type === 'therapy') {
          return (value || '')
            .split(',')
            .map(gpi => {
              const medication = findMedicationByGpi10(medications, gpi);
              if (medication) {
                return medication.drug_name;
              }
              return gpi;
            })
            .join(', ');
        }
        if (original.dataType?.type === 'numeric') {
          return `${value}${original.dataType.unit ? ` (${original.dataType.unit})` : ''}`;
        }
        return value;
      },
    },
    {
      Header: 'Therapy',
      accessor: 'therapyName',
      sortType: 'basic',
      filterMethod: stringFilter,
      getHeaderProps: () => ({
        data_qa_id: 'drug_name_header',
      }),
    },
    {
      Header: 'Assessment Date',
      accessor: 'date',
      sortType: 'basic',
      filterMethod: stringFilter,
      sortMethod: dateSorting,
      getHeaderProps: () => ({
        data_qa_id: 'assessment_date_display_header',
      }),
    },
    {
      Header: 'Recorded',
      accessor: 'recordedBy',
      sortType: 'basic',
      filterMethod: stringFilter,
      getHeaderProps: () => ({
        data_qa_id: 'recorded_by_header',
      }),
    },
  ];

  const columns = [
    ...columnsDisplayOnly,
    {
      Header: () => null,
      id: 'expander',
      Cell: ({ row }) => {
        return (
          <div className={classes.editActionWording} {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <EditPencilMd /> : <EditPencilMd />}
          </div>
        );
      },
    },
  ];

  return (
    <Grid container className={classes.clinicalContainer}>
      {!displayOnly && (
        <>
          <Grid item xs={10}>
            <Typography variant="h6" component="span">
              Clinical Assessment
            </Typography>
          </Grid>

          {displayAddData ? (
            <Grid item xs={12} className={classes.addDataContainer}>
              <SingleDataCollectForm
                patientLevel
                onCancel={() => handleAddData(false)}
                onSubmit={async values => {
                  let dcTaskId = null;
                  const dcRecords = Object.entries(tasks)
                    .map(([key, val]) => val)
                    .reduce((acc, item) => {
                      if (
                        item.therapy_id === parseInt(values.therapy_id, 10) &&
                        item.taskType === DC
                      ) {
                        acc.push(item);
                      }
                      return acc;
                    }, [])
                    .sort((a, b) => b.id - a.id);
                  if (dcRecords.length) {
                    dcTaskId = dcRecords[0].id;
                  }
                  values.value = NumberUtils.ensureLeadingZero(values.value?.trim());
                  // values.data_type has this format: <therapyId>-<dataTypeId>
                  const dataTypeId = values.data_type.split('-')[1];
                  const newClinicalDataValues = {
                    assessmentDate: values.date,
                    cannotCompleteReason: null,
                    clinicalDataTypeId: dataTypeId,
                    // Adding a record; id will be assigned
                    id: undefined,
                    patientId: selectedPatientId,
                    patientClinicalDataId: null,
                    required: false,
                    taskDataCollectId: dcTaskId,
                    therapyId: values.therapy_id,
                    value: values.value,
                  };
                  await callAndDispatchSaveClinicalData([newClinicalDataValues]);
                  handleAddData(false);
                }}
              />
            </Grid>
          ) : (
            <Grid item xs={12} align="right" className={classes.addDataButton}>
              <Button
                name="add_data_button"
                id="add_clinical_data_button"
                variant="outlined"
                onClick={() => handleAddData(true)}
              >
                <EditAdd />
                <Typography variant="body2">New</Typography>
              </Button>
            </Grid>
          )}
        </>
      )}

      {taskType && taskType === RS ? (
        <Grid item xs={12} className={classes.tableContainer}>
          <ReactTableComp
            columns={
              displayOnly
                ? columnsDisplayOnly.reduce((acc, it) => {
                    if (it.Header !== 'Therapy') {
                      acc.push(it);
                    }
                    return acc;
                  }, [])
                : columns.reduce((acc, it) => {
                    if (it.Header !== 'Therapy') {
                      acc.push(it);
                    }
                    return acc;
                  }, [])
            }
            data={clinicalData
              .reduce((acc, it) => {
                if (
                  acc.length &&
                  !acc.find(i => i.id === it.id) &&
                  it.task_data_collect_id === null
                ) {
                  acc.push(it);
                } else if (!acc.length && it.task_data_collect_id === null) {
                  acc.push(it);
                }
                return acc;
              }, [])
              .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id)}
            renderRowSubComponent={renderRowSubComponent}
          />
        </Grid>
      ) : (
        <Grid item xs={12} className={classes.tableContainer}>
          <ReactTableComp
            columns={displayOnly ? columnsDisplayOnly : columns}
            data={clinicalData.sort((a, b) => new Date(b.date) - new Date(a.date))}
            renderRowSubComponent={renderRowSubComponent}
          />
        </Grid>
      )}
      {questionnaire && (
        <DcQuestionnaire
          clinicalDataItemId={[questionnaire.clinicalDataItemId]}
          dataTypeField={questionnaire.dataTypeField}
          patientId={selectedPatientId}
          onClose={() => {
            setQuestionnaire(null);
          }}
          disabled={displayOnly}
        />
      )}
    </Grid>
  );
}

function mapStateToProps(state) {
  const { patient, selectedPatientId, lookups, therapies, tabControl, tasks } = state;
  const { clinicalDataTypes, users } = lookups;
  return {
    patient,
    selectedPatientId,
    therapies: therapies.data,
    tabControl,
    clinicalDataTypes,
    users,
    tasks: tasks.data,
  };
}

export default compose(withRouter, connect(mapStateToProps, null))(PatientClinical);

import { INotes } from 'interfaces/redux/INotes';

export class NotesUtil {
  static getNotesHighestId = (
    existingNotes: INotes,
  ): { pinnedNoteHighestId: number; nonPinnedNoteHighestId: number } => {
    const pinnedNoteHighestId = existingNotes?.notes?.allPinnedNotes?.[0]?.id || -1;
    const nonPinnedNoteHighestId = existingNotes?.notes?.allNonPinnedNotes?.[0]?.id || -1;

    return { pinnedNoteHighestId, nonPinnedNoteHighestId };
  };
}

import { createStyles } from '@mui/styles';

export const styles = theme =>
  createStyles({
    container: {
      marginTop: 63,
    },
    contactDetail: {
      color: theme.palette.primary.deepBlue,
      margin: '30px 0',
      fontWeight: 'bold',
      [`${theme.breakpoints.down('xl')} and (orientation: landscape)`]: {
        margin: '10px 0',
      },
    },
    contactButton: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    message: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 1.25,
    },
    message2: {
      fontSize: 20,
    },
  });

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TherapyId } from 'interfaces/RecordTypes';

export const NAME = 'benefitsInvestigationDetailedHistory';

export interface IBenefitsInvestigationDetailedHistoryState {
  open?: boolean;
  therapyId?: TherapyId;
  arTaskId?: number;
}
const initialState: IBenefitsInvestigationDetailedHistoryState = {
  open: false,
};

const benefitsInvestigationDetailedHistorySlice = createSlice({
  name: NAME,
  initialState: initialState,
  reducers: {
    open: (state, action: PayloadAction<number>) => {
      if (action.payload) {
        state.open = true;
        state.therapyId = action.payload;
      }
    },
    openFromAr: (state, action: PayloadAction<number>) => {
      if (action.payload) {
        state.open = true;
        state.arTaskId = action.payload;
      }
    },
    close: () => {
      return initialState;
    },
  },
});

export const benefitsInvestigationDetailedHistoryActions =
  benefitsInvestigationDetailedHistorySlice.actions;
export const benefitsInvestigationDetailedHistoryReducer =
  benefitsInvestigationDetailedHistorySlice.reducer;

import React, { useState, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Tooltip, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import InfoIcon from '@mui/icons-material/Info';
import { getClinicalDataTypesMap } from 'services/utils/data-collect';
import { logEvent } from 'services/utils/analytics';
import {
  PATIENT_QUESTIONNAIRE_ACTIONS,
  PATIENT_QUESTIONNAIRE,
  SMS_COMPLETE_QUESTIONNAIRE_LINK_MSG,
} from 'constants/index';
import dataCollectOnDemandJson from 'config/tasks/data-collect-on-demand.json';
import { getOptInSmsPhoneList, getOptInEmailList } from 'services/utils/demographic-service';
import { sendQuestionnaire } from 'actions/action-clinical-data';
import { QuestionnaireClient } from 'clients/questionnaire';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { fetchNotes } from 'actions/action-notes';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { selectSelectedWagTasks } from 'containers/patient/tasks-new/selectors';
import { logger } from '../../../winston-logger';
import PopupForm from '../popup-form';

const useStyles = makeStyles(() => ({
  infoIcon: {
    verticalAlign: 'middle',
  },
  info: { display: 'flex', alignItems: 'center' },
  sendDc: { display: 'flex', alignItems: 'center' },
  sendRs: { display: 'flex', marginTop: '-90px', alignItems: 'center' },
  buttonSendDc: { marginRight: 10 },
  buttonSendRs: { marginRight: 10, marginTop: 10 },
}));

// add custom validation to text
const config = dataCollectOnDemandJson;
config.fields.find(f => f.id === 'text').validation = [
  'required',
  text => {
    if (!(text || '').includes('<questionnaire-link>')) {
      return 'The message must include <questionnaire-link>';
    }
    return undefined;
  },
];

const isQuestionnaireValid = (clinicalDataTypesMap, dcItem, clinicalData) => {
  const isQuestionnaire = !!clinicalDataTypesMap[dcItem.clinicalDataTypeId].questionnaireId;
  if (isQuestionnaire) {
    if (clinicalData && clinicalData.length) {
      const dcItemFullInfo = clinicalData.find(t => t.id === dcItem.id);
      if (dcItemFullInfo && dcItemFullInfo.questionnaire) {
        return dcItemFullInfo.questionnaire.submittedDate === null;
      }
    }
  }
  return false;
};

const getPharmacyName = (
  clinicalData,
  dataCollectId,
  isRiskStrat,
  therapiesList,
  customersList,
) => {
  let pharmacyName = '';
  if (
    dataCollectId &&
    clinicalData &&
    clinicalData.length &&
    therapiesList &&
    Object.values(therapiesList).length
  ) {
    const fullClinicalData = clinicalData.find(t =>
      isRiskStrat ? t.taskRiskStratId === dataCollectId : t.taskDataCollectId === dataCollectId,
    );
    if (fullClinicalData) {
      for (const elem of Object.values(therapiesList)) {
        if (elem.id === fullClinicalData.therapyId) {
          const current_customer_id = elem.customer_id;
          pharmacyName =
            customersList.find(t => t.id === current_customer_id)?.alternate_name_sms ?? '';
          break;
        }
      }
    }
  }
  return pharmacyName;
};

export default function ({ dc, dataCollectId, className, isRiskStrat, workingAsGroupTasks }) {
  const [onDemandOpen, setOnDemandOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const buttonRef = useRef(null);
  const clinicalDataTypes = useSelector(state => state.lookups.clinicalDataTypes);
  const patient = useSelector(state => state.patient);
  const contactList = useSelector(state => state.contactList);
  const clinicalData = useSelector(state => state.clinicalData);
  const therapiesList = useSelector(state => state.therapies?.data || []);
  const clinicalDataTypesMap = getClinicalDataTypesMap(clinicalDataTypes);
  const customersList = useSelector(state => state.filters?.customers || []);
  const selectedWagTaskList = useTypedSelector(selectSelectedWagTasks);
  const pharmacyName = getPharmacyName(
    clinicalData,
    dataCollectId,
    isRiskStrat,
    therapiesList,
    customersList,
  );
  const initialDataTypeIds = useMemo(
    () =>
      (dc || [])
        .filter(dcItem => clinicalDataTypesMap[dcItem.clinicalDataTypeId].type === 'questionnaire')
        .map(it => it.clinicalDataTypeId),
    [dc],
  );
  const initialDc = dc || [];

  const hasQuestionnaires = initialDc.find(
    dcItem => clinicalDataTypesMap[dcItem.clinicalDataTypeId].type === 'questionnaire' && dcItem.id,
  );

  const smsSentArr = [];
  initialDc.forEach(idc => {
    if (idc.questionnaire && idc.questionnaire.smsSent) {
      smsSentArr.push(clinicalDataTypesMap[idc.clinicalDataTypeId].name);
    }
  });

  const handleOnDemandOpen = () => {
    setOnDemandOpen(true);
  };

  const handleOnDemandClose = () => {
    setOnDemandOpen(false);
  };

  const phones = useMemo(() => {
    const list = getOptInSmsPhoneList(patient || {}, contactList || {});
    return list.map(phone => ({
      id: phone.rawPhone.value,
      name: phone.label,
    }));
  }, [patient, contactList]);

  const emails = useMemo(() => {
    const list = getOptInEmailList(patient || {}, contactList || {});
    return list.map(email => ({
      id: email.rawEmail.value,
      name: email.label,
    }));
  }, [patient, contactList]);

  const canSendDcToPatient = phones.length;

  const validQuestionnaires = initialDc.some(dcItem =>
    isQuestionnaireValid(clinicalDataTypesMap, dcItem, clinicalData),
  );

  const questionnaireProviders = initialDataTypeIds.map(id => ({
    id: clinicalDataTypesMap[id].dataTypeId,
    name: clinicalDataTypesMap[id].name,
    disabled: !isQuestionnaireValid(
      clinicalDataTypesMap,
      initialDc.find(item => item.clinicalDataTypeId === id),
      clinicalData,
    ),
  }));

  const handleSubmit = async values => {
    const payload = {
      patient_id: patient.id,
      msg: values.text,
      patient_clinical_data_item_ids: values.questionnaires.map(dataTypeId => {
        const patientClinicalDataItem = dc.find(
          dcItem => Number(dcItem.clinicalDataTypeId) === Number(dataTypeId),
        );
        return patientClinicalDataItem.id;
      }),
      dc_id:
        selectedWagTaskList && selectedWagTaskList.length
          ? selectedWagTaskList.map(t => t.id)
          : dataCollectId,
      phone: values.phone,
      email: values.email,
      taskLevel: true,
      isRiskStrat: isRiskStrat,
    };
    try {
      const response = await QuestionnaireClient.sendQuestionnaire(payload);
      const { url_shorten: urlShorten, sms_response: smsResponse } = response.data;
      const channel = smsResponse ? 'SMS' : 'email';
      logEvent(
        PATIENT_QUESTIONNAIRE,
        PATIENT_QUESTIONNAIRE_ACTIONS.SEND,
        // eslint-disable-next-line max-len
        `Questionnaire(${urlShorten}) link sent to patient #${patient.id} by ${channel}`,
      );
      const action = sendQuestionnaire(response.data);
      dispatch(action);
      dispatch(fetchNotes({ patient_id: patient.id }));
      dispatch(notifySuccess('Questionnaire Sent Successfully'));
    } catch (error) {
      logger.error(error);
      dispatch(notifyError('Failed to Send Questionnaire'));
    }
    handleOnDemandClose();
  };

  return (
    <div className={className}>
      {onDemandOpen && (
        <PopupForm
          title="Data Collect on Demand"
          open
          disableSubmitIfInvalid
          jsonForm={config}
          formId="dataCollectOnDemandForm"
          formName="send"
          data={{
            text: pharmacyName
              ? SMS_COMPLETE_QUESTIONNAIRE_LINK_MSG.replace(
                  '#PHARMACY#',
                  `This is from ${pharmacyName} - `,
                )
              : SMS_COMPLETE_QUESTIONNAIRE_LINK_MSG.replace('#PHARMACY#', ''),
          }}
          providers={{
            questionnaires: questionnaireProviders,
            phones,
            emails,
          }}
          onSubmit={async values => {
            await handleSubmit(values);
          }}
          submitButtonText="Send"
          cancelButtonText="Cancel"
          onCancel={handleOnDemandClose}
          anchorEl={buttonRef ? buttonRef.current : undefined}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
        />
      )}
      {hasQuestionnaires && (
        <div className={isRiskStrat ? classes.sendRs : classes.sendDc}>
          <Button
            className={
              isRiskStrat && smsSentArr.length > 0 ? classes.buttonSendRs : classes.buttonSendDc
            }
            color="primary"
            variant="contained"
            onClick={handleOnDemandOpen}
            disabled={!canSendDcToPatient || !validQuestionnaires}
            ref={buttonRef}
          >
            {isRiskStrat ? 'Send RS to Patient' : 'Send DC to Patient'}
          </Button>
          <div className={classes.info}>
            {smsSentArr.length > 0 && (
              <>{` (${smsSentArr.join(', ')} sent by SMS to the patient) `}</>
            )}
            {!canSendDcToPatient && (
              <Tooltip title="Patient has no SMS / Email Opt In" placement="top">
                <InfoIcon className={classes.infoIcon} />
              </Tooltip>
            )}
            <Box pt={1} />
            {!validQuestionnaires && (
              <Tooltip title="No valid Questionnaires / All already sent" placement="right">
                <InfoIcon className={classes.infoIcon} />
              </Tooltip>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

import { Grid, Typography } from '@mui/material';
import { convertToArborDate } from 'models/time/arbor-date';
import React from 'react';
import { IAllergy } from 'components/patient-merge-modal/interfaces';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { useTheme } from '@mui/styles';

const ClinicalAllergySection = ({ allergyName, allergyUpdatedAt, allergyStatus }: IAllergy) => {
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);
  const allergyUpdatedAtFormatted = convertToArborDate(allergyUpdatedAt).getUtcDate(true);

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <Typography sx={classes.checkboxTitle}>
          {`${allergyName} (${allergyUpdatedAtFormatted}) - ${allergyStatus}`}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default ClinicalAllergySection;

import { styled } from '@mui/material/styles';
import withTheme from '@mui/styles/withTheme';
import { Divider } from '@mui/material';
import colors from '../../lib/themes/colors.module.scss';

const { grey23, cornflowerblue, yellow2, valenciaRed } = colors;

export const LineDivider = styled(withTheme(Divider))(({ theme }) => ({
  width: '100%',
  marginInline: 'auto',
  backgroundColor: grey23,
}));

const INLINE_BLOCK = 'inline-block';
const FLEX_START = 'flex-start';

export const styles = theme => ({
  spacer: {
    height: 30,
  },
  errorMessage: {
    color: theme.palette.error.main,
    maxWidth: '315px',
  },
  panel: {
    marginLeft: 30,
    marginRight: 30,
  },
  therapyTaskList: {
    margin: 0,
  },
  linkedDrugButton: {
    marginLeft: 5,
    marginRight: 5,
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.pageHeader,
  },
  linkOptionsSpan: {
    marginLeft: 10,
  },
  editPanel: {
    margin: 30,
    paddingTop: 12,
    paddingRight: 55,
    paddingLeft: 24,
  },
  button: {
    margin: 0,
    minWidth: 0,
    paddingRight: 10,
    paddingLeft: 10,
  },
  textContainer: {
    margin: theme.spacing(1),
  },
  fieldContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  severityAlert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginInline: theme.spacing(1),
    paddingTop: theme.spacing(1.4),
    paddingBottom: theme.spacing(1.4),
    paddingInline: theme.spacing(2),
    borderRadius: 5,
  },
  severityHigh: {
    color: valenciaRed,
    backgroundColor: `${valenciaRed}33`,
  },
  severityMedium: {
    backgroundColor: `${yellow2}55`,
  },
  severityLow: {
    backgroundColor: `${cornflowerblue}AA`,
  },
  detailFieldContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
    borderRadius: 5,
    border: `1px solid ${grey23}`,
  },
  fieldDropdownContainer: {
    paddingRight: '30px',
  },
  divider: {
    marginTop: 0,
    marginBottom: 0,
  },
  summary: {
    backgroundColor: theme.palette.primary.summaryPanel,
    paddingRight: 0,
  },
  note: {
    fontSize: theme.font.smallFontSize,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 5,
    padding: 5,
    marginBottom: 2,
    overflow: 'hidden',
    maxHeight: 200,
    position: 'relative',
  },
  detailContainer: {
    display: 'block',
    width: '100%',
  },
  detailItem: {
    marginTop: theme.spacing(2),
  },
  paDue: {
    fontSize: theme.font.smallFontSize,
    marginLeft: 10,
    textTransform: 'capitalize',
  },
  addBtn: {
    backgroundColor: theme.palette.primary.addBtnColor,
  },
  statusTitle: {
    fontSize: theme.font.largeFontSize,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  formControl: {
    minWidth: 120,
  },
  ndc: {
    margin: 8,
  },
  Modal: {
    position: 'absolute',
    width: theme.spacing(150),
    backgroundColor: theme.palette.primary.background,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
  statusContainer: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    borderRadius: 5,
    padding: theme.spacing(2),
    margin: 0,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  addForm: {
    padding: 0,
  },
  spacerTask: {
    paddingTop: theme.spacing(30),
  },
  taskDetailContainerEdit: {
    marginBottom: theme.spacing(1),
    padding: 0,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-1),
    borderRadius: 5,
  },
  taskDetailSubTitle: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  taskDetailContainer: {
    padding: 0,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  taskHeader: {},
  taskBlock: {
    padding: 0,
    margin: 0,
  },
  highlighted: {
    border: `2px solid ${theme.palette.primary.task.borderColor}`,
    borderRight: `4px solid ${theme.palette.primary.task.borderHighlightColor}`,
  },
  newTask: {
    border: `2px solid ${theme.palette.primary.task.borderColor}`,
    borderRight: `4px solid ${theme.palette.primary.task.borderNewTaskColor}`,
  },
  dataTableContainer: {
    marginRight: 24,
  },
  actionWording: {
    fontSize: 12,
    padding: '7px 5px',
  },
  newFA: {
    marginBottom: theme.spacing(2),
  },
  addFromFinancials: {
    marginBottom: 15,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: 5,
    marginLeft: 17,
    marginRight: 13,
    marginTop: 30,
  },
  linkedButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    paddingRight: 12,
    fontSize: theme.font.mediumFontSize,
  },
  titleOriginalCase: {
    fontWeight: 'bold',
    paddingRight: 12,
    fontSize: theme.font.mediumFontSize,
  },
  taskTitleDetails: {
    fontWeight: 'bold',
    display: INLINE_BLOCK,
    paddingRight: 18,
    fontSize: theme.font.smallFontSize,
    color: theme.palette.primary.formItem,
  },
  taskTitleDetailsUpdated: {
    display: INLINE_BLOCK,
    paddingRight: 18,
    fontSize: theme.font.smallFontSize,
    color: theme.palette.primary.formLabel,
  },
  titleSuccess: {
    color: theme.palette.primary.success,
  },
  titleInfo: {
    color: theme.palette.primary.main,
  },
  titleDanger: {
    color: theme.palette.error.main,
  },
  iconTitle: {
    paddingRight: 10,
  },
  archivedIconTitle: {
    paddingRight: 10,
  },
  expansionPanelLabel: {
    fontWeight: 'bold',
    fontSize: theme.font.mediumFontSize,
    color: theme.palette.primary.formLabel,
  },
  archivedTitle: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: theme.font.mediumFontSize,
    color: theme.palette.primary.formLabel,
  },
  expansionPanelSummaryContent: {
    'margin': 0,
    '&.Mui-expanded': {
      margin: '16px 0',
    },
  },
  expansionPanelSummaryContentInner: {
    padding: 0,
  },
  expansionPanelDetails: {
    flexDirection: 'column',
  },
  archivedContainer: {
    margin: 0,
  },
  archivedInner: {
    padding: 0,
    flexDirection: 'column',
  },
  expansionPanelSummaryArchived: {
    backgroundColor: theme.palette.primary.summaryPanel,
  },
  taskPropertiesContainer: {
    flex: 1,
    padding: 8,
  },
  taskProperty: {
    display: INLINE_BLOCK,
    paddingRight: 18,
    fontWeight: 'bold',
  },
  checklistTaskList: {
    marginLeft: 30,
    marginRight: 30,
    marginBottom: theme.spacing(3),
  },
  checklistTaskListHeader: {
    marginBottom: 10,
  },
  addTherapyTaskContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  formTitle: {
    display: INLINE_BLOCK,
    marginBottom: theme.spacing(4),
    marginRight: 5,
  },
  formTitleStatus: {
    fontSize: '0.8em',
    color: theme.palette.primary.addBtnColor,
    marginLeft: theme.spacing(3),
  },
  formTitleCurrentStatus: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
  },
  expansionPanelRoot: {
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  expanded: {
    margin: 0,
  },
  visibleContainer: {
    overflow: 'visible !important',
  },
  financialDropdownContainer: {
    paddingRight: '15px',
  },
  confirmationContinueButton: {
    marginLeft: 8,
  },
  confirmationDialogTitle: {
    backgroundColor: theme.palette.primary.bluesky,
    marginBottom: 20,
    minWidth: 600,
  },
  previewDocumentButton: {
    width: 200,
    height: 50,
    marginTop: 25,
  },
  formTitleHeaderWrapper: {
    display: 'flex',
    justifyContent: FLEX_START,
  },
  formTitleHeaderWrapperDivider: {
    marginLeft: 16,
    marginRight: 16,
    height: '2px',
  },
  formTitleHeaderWrapperItem1: {
    display: 'flex',
    justifyContent: FLEX_START,
    flexGrow: 5,
    fontWeight: 'bold',
    fontSize: 'large',
    paddingTop: theme.spacing(1.25),
  },
  formTitleHeaderWrapperItem2: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 0.3,
    fontSize: theme.font.mediumFontSize,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  chip: {
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: theme.font.mediumFontSize,
    marginLeft: theme.spacing(2.25),
  },
  therapyLevelHeader: {
    borderLeft: '6px solid',
    borderColor: theme.palette.primary.main,
    paddingLeft: 15,
    marginLeft: 6,
  },
  incidentCheckboxContainer: {
    paddingTop: 8,
  },
  fieldLabel: {
    fontSize: theme.font.formLabelSize,
    fontWeight: theme.font.weight.regular,
    color: theme.palette.primary.formLabel,
    minHeight: theme.layout.taskFormLabelMinHeight + 3,
  },
  submissionButton: {
    margin: 8,
    marginLeft: 0,
  },
  loading: {
    marginLeft: 10,
    color: theme.palette.primary.trellisBlue,
  },
  backdrop: {
    zIndex: 1301,
    color: '#fff',
  },
  pillIcon: {
    display: 'flex',
    justifyContent: FLEX_START,
    marginBottom: 15,
  },
  dosisRegimenText: {
    marginLeft: 5,
    fontSize: theme.font.smallFontSize,
  },
  shippingAddressText: {
    color: theme.palette.primary.black,
    fontWeight: '500',
  },
  alertIsShipped: {
    textAlign: 'right',
    color: theme.palette.primary.important,
    paddingRight: theme.spacing(2),
  },
  statusLabelContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  statusLabel: {
    fontSize: '.75rem',
    color: 'rgb(138, 149, 153)',
    marginBottom: '18px',
  },
  statusValue: {
    fontSize: '1rem',
    color: 'rgb(68, 72, 76)',
  },
  afterHoursContent: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    backgroundColor: `${theme.palette.primary.yellow2}33`,
    paddingInline: theme.spacing(1.5),
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    marginBottom: theme.spacing(2),
    borderRadius: 3,
  },
  afterHoursIcon: {
    color: `${theme.palette.primary.yellow2}`,
    height: 16,
    width: 16,
    filter: 'brightness(90%)',
  },
  afterHoursText: {
    fontSize: 12,
    fontWeight: 500,
  },
});

export const Styles = {
  addForm: {
    padding: 0,
  },
  button: {
    margin: 0,
    minWidth: 0,
    paddingRight: 10,
    paddingLeft: 10,
  },
  statusContainer: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'palette.primary.main',
    borderRadius: '4px',
    padding: 1,
    margin: 0,
    marginTop: 1,
    marginBottom: 2,
  },
};

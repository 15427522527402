export enum InterventionStatuses {
  Required = 8001,
  InProgress = 8002,
  Canceled = 8004,
  Completed = 8005,
  Canceled_PatientDeceased = 8099,
}

export enum InterventionStatusesIds {
  Required = 'required',
  InProgress = 'inprogress',
  Canceled = 'canceled',
  Completed = 'completed',
}

import moment from 'moment';
import { TASK_TYPES } from 'constants/index';

const DATE_FORMAT = 'MM/DD/YYYY';

export const getTaskInitialValues = (therapy, taskType) => {
  switch (taskType) {
    case TASK_TYPES.PRIOR_AUTHORIZATION:
    case TASK_TYPES.FINANCIAL_ASSISTANCE: {
      const { needsby_date, followup_dt } = therapy; // eslint-disable-line
      // eslint-disable-next-line camelcase
      const dueDate = needsby_date ? moment.utc(needsby_date).format(DATE_FORMAT) : null; // eslint-disable-line
      const followupDate =
        moment.utc(needsby_date).diff(moment(), 'days') <= 2
          ? moment().format(DATE_FORMAT)
          : moment.utc().add(2, 'days').format(DATE_FORMAT);

      return { dueDate, followupDate };
    }
    default:
      return {};
  }
};

import React, { FC } from 'react';
import cx from 'classnames';

import { Box } from '@mui/material';

import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';

import {
  ArrowBackIos as ArrowLeftIcon,
  ArrowForwardIos as ArrowRightIcon,
} from '@mui/icons-material';

import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

import { IProps } from './IProps';
import { styles } from './items-carousel.styles';

SwiperCore.use([Navigation]);

const ItemsCarousel: FC<IProps> = (props: IProps): JSX.Element => {
  const { classes, numberOfItems, items, spaceBetween } = props;
  return (
    <>
      <div className={cx(classes.arrowContainer, classes.fullHeight, classes.inline)}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={cx(classes.fullHeight, classes.pointer, 'arrow-left')}
        >
          <ArrowLeftIcon fontSize="small" className={cx(classes.arrow)} />
        </Box>
      </div>
      <div className={cx(classes.sliderContainer, classes.inline)}>
        <Swiper
          spaceBetween={spaceBetween ?? 20}
          slidesPerView={numberOfItems ?? 5}
          navigation={{
            prevEl: '.arrow-left',
            nextEl: '.arrow-right',
            disabledClass: classes.hidden,
          }}
        >
          {items.map((item, key) => (
            <SwiperSlide key={key}>{item}</SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={cx(classes.arrowContainer, classes.fullHeight, classes.inline)}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={cx(classes.fullHeight, classes.pointer, 'arrow-right')}
        >
          <ArrowRightIcon fontSize="small" className={classes.arrow} />
        </Box>
      </div>
    </>
  );
};

export default withStyles(styles)(ItemsCarousel);

import React, { useCallback, useMemo } from 'react';
import { compose } from 'redux';
import moment from 'moment';
import { Grid, Typography, Button } from '@mui/material';

import DatetimePicker from 'components/form/datepicker/datetime-picker';
import RadioButton from 'components/form/radio/radio-button';
import RadioGroup from 'components/form/radio/radio-group';
import { addMonths, subMonths, parse, format, isBefore, isAfter } from 'date-fns';
import { useDispatch, connect } from 'react-redux';
import { setSidebarFilter } from 'actions/action-sidebar-filters';
import { ReactSelect } from 'components/form/field/react-select';
import { dateFormat as momentIsoFormat } from 'models/time/arbor-date';
import { MediumSpacer } from 'components/spacer/spacer';

import {
  getDatesFromSidebarFilters,
  getRadioCheckedDateFilter,
  evaluateDate,
} from '../../../services/utils/filters-service';

const DATE_OPTIONS = {
  YESTERDAY: 'YESTERDAY',
  TODAY: 'TODAY',
  TOMORROW: 'TOMORROW',
  NEXT_7_DAYS: 'NEXT_7_DAYS',
  NEXT_14_DAYS: 'NEXT_14_DAYS',
};

const dateFnsIsoFormat = 'YYYY-MM-DD';
const dateFnsSlashFormat = 'MM/DD/YYYY';

const clinicsStyle = {
  option: (baseStyles, { data }) => ({
    ...baseStyles,
    ...(data.id ? { 'font-weight': 'bold' } : {}),
  }),
};

const ScheduleFilterPanel = props => {
  const dispatch = useDispatch();
  const {
    sidebarFilters,
    setSidebarFilter, // eslint-disable-line no-shadow
    lookups,
  } = props;
  const { serviceGroups, appointmentClinics, lookupStatuses, lookupStatusReasons } = lookups;
  const patientStatuses = useMemo(
    () =>
      lookupStatusReasons
        .filter(({ resource }) => resource === 'patient')
        .map(({ id, status }) => ({ id, status })),
    [lookupStatusReasons],
  );
  const selectedServiceGroups = sidebarFilters.serviceGroups || [];
  const selectedAppointmentStatuses = sidebarFilters.appointmentStatuses || [];
  const selectedPatientStatuses = sidebarFilters.patientStatuses || [];
  const clinics = sidebarFilters.appointmentClinics || [];

  const radioChecked = getRadioCheckedDateFilter(sidebarFilters);

  const { from: fromDateValue, to: toDateValue } = useMemo(() => {
    const today = format(new Date(), dateFnsIsoFormat);
    let { from, to } = getDatesFromSidebarFilters(sidebarFilters);

    from = from || today;
    to = to || today;

    const parsedFrom = parse(
      from,
      from.includes('-') ? dateFnsIsoFormat : dateFnsSlashFormat,
      new Date(),
    );
    const parsedTo = parse(
      to,
      to.includes('-') ? dateFnsIsoFormat : dateFnsSlashFormat,
      new Date(),
    );
    return {
      from: format(parsedFrom, dateFnsIsoFormat),
      to: format(parsedTo, dateFnsIsoFormat),
    };
  }, [sidebarFilters]);

  const dateChipsEnabled = true;

  const handleRadioButtonChange = value => {
    const { setSidebarFilter } = props; // eslint-disable-line no-shadow
    dispatch(
      setSidebarFilter(
        'schedule',
        {
          date: value,
          from: null,
          to: null,
        },
        true,
      ),
    );
  };

  const handleServiceGroupChange = value => {
    const serviceGroupsVals = value
      ? value.map(it => ({
          displayValue: it.label,
          idValue: it.value,
        }))
      : [];
    dispatch(
      setSidebarFilter(
        'schedule',
        {
          serviceGroups: serviceGroupsVals,
          appointmentClinics: sidebarFilters.clinics
            .filter(clinic => Number.isInteger(clinic.value))
            .filter(clinic =>
              (value || []).some(serviceGroup => serviceGroup.value === clinic.serviceGroupId),
            ),
        },
        true,
      ),
    );
  };

  const handleClinicsChange = value => {
    const { setSidebarFilter } = props; // eslint-disable-line no-shadow
    const selectedClinics = value
      ? value
          .filter(clinic => !Number.isInteger(clinic.value))
          .map(clinic => ({
            displayValue: clinic.label,
            idValue: clinic.value,
            serviceGroupId: clinic.serviceGroupId,
          }))
      : [];
    dispatch(
      setSidebarFilter(
        'schedule',
        {
          appointmentClinics: selectedClinics,
        },
        true,
      ),
    );
  };

  const handleAppointmentStatusesChange = value => {
    const selectedStatuses = value
      ? value.map(item => ({
          description: item.label,
          id: item.value,
        }))
      : [];
    dispatch(
      setSidebarFilter(
        'schedule',
        {
          appointmentStatuses: selectedStatuses,
        },
        true,
      ),
    );
  };

  const handlePatientStatusesChange = value => {
    const selectedStatuses = value
      ? value.map(item => ({
          description: item.label,
          id: item.value,
        }))
      : [];
    dispatch(
      setSidebarFilter(
        'schedule',
        {
          patientStatuses: selectedStatuses,
        },
        true,
      ),
    );
  };

  const handleToDateChange = useCallback(
    e => {
      // is a moment if the datepicker is clicked
      const incomingDate =
        e instanceof moment ? moment(e).format(momentIsoFormat) : evaluateDate(e);

      const toDate = parse(incomingDate, dateFnsIsoFormat, new Date());
      let fromDate = parse(fromDateValue, dateFnsIsoFormat, new Date());

      const monthSpan = subMonths(toDate, 1);
      if (isBefore(fromDate, monthSpan)) {
        fromDate = monthSpan;
      }

      dispatch(
        setSidebarFilter(
          'schedule',
          {
            from: format(fromDate, dateFnsIsoFormat),
            to: format(toDate, dateFnsIsoFormat),
          },
          true,
        ),
      );
    },
    [dispatch, fromDateValue],
  );

  const handleFromDateChange = useCallback(
    e => {
      // is a moment if the datepicker is clicked
      const incomingDate =
        e instanceof moment ? moment(e).format(momentIsoFormat) : evaluateDate(e);

      const fromDate = parse(incomingDate, dateFnsIsoFormat, new Date());
      let toDate = parse(toDateValue, dateFnsIsoFormat, new Date());

      const monthSpan = addMonths(fromDate, 1);
      if (isAfter(toDate, monthSpan)) {
        toDate = monthSpan;
      }

      dispatch(
        setSidebarFilter(
          'schedule',
          {
            from: format(fromDate, dateFnsIsoFormat),
            to: format(toDate, dateFnsIsoFormat),
          },
          true,
        ),
      );
    },
    [dispatch, toDateValue],
  );

  const handleScheduleFiltersSearch = () => {
    const { setSidebarFilter, sidebarFilters } = props; // eslint-disable-line no-shadow
    dispatch(setSidebarFilter('schedule', sidebarFilters));
  };

  return (
    <>
      <Grid container>
        <Grid item lg={5}>
          <RadioGroup horizontal onChange={value => handleRadioButtonChange(value)}>
            <RadioButton
              value={DATE_OPTIONS.YESTERDAY}
              checked={dateChipsEnabled && radioChecked[DATE_OPTIONS.YESTERDAY]}
              disabled={!dateChipsEnabled}
            >
              Yesterday
            </RadioButton>
            <RadioButton
              value={DATE_OPTIONS.TODAY}
              checked={dateChipsEnabled && radioChecked[DATE_OPTIONS.TODAY]}
              disabled={!dateChipsEnabled}
            >
              Today
            </RadioButton>
            <RadioButton
              value={DATE_OPTIONS.TOMORROW}
              checked={dateChipsEnabled && radioChecked[DATE_OPTIONS.TOMORROW]}
              disabled={!dateChipsEnabled}
            >
              Tomorrow
            </RadioButton>
            <RadioButton
              value={DATE_OPTIONS.NEXT_7_DAYS}
              checked={dateChipsEnabled && radioChecked[DATE_OPTIONS.NEXT_7_DAYS]}
              disabled={!dateChipsEnabled}
            >
              Next 7 Days
            </RadioButton>
            <RadioButton
              value={DATE_OPTIONS.NEXT_14_DAYS}
              checked={dateChipsEnabled && radioChecked[DATE_OPTIONS.NEXT_14_DAYS]}
              disabled={!dateChipsEnabled}
            >
              Next 14 Days
            </RadioButton>
          </RadioGroup>
        </Grid>
      </Grid>
      <MediumSpacer />
      <Grid container justifyContent="space-around">
        <Grid md={10} container spacing={4} justifyContent="flex-start">
          <Grid item md={1}>
            <Typography variant="caption" component="div">
              From
            </Typography>
            <DatetimePicker
              value={fromDateValue}
              onChange={e => handleFromDateChange(e)}
              dateFormat="MM/dd/yyyy"
              placeholder="mm/dd/yyyy"
              id="schedule_from_date"
            />
          </Grid>
          <Grid item md={1}>
            <Typography variant="caption" component="div">
              To
            </Typography>
            <DatetimePicker
              value={toDateValue}
              onChange={e => handleToDateChange(e)}
              dateFormat="MM/dd/yyyy"
              placeholder="mm/dd/yyyy"
              id="schedule_to_date"
              showDisabledMonthNavigation
              textFieldInputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item md={2}>
            <Typography variant="caption" component="div">
              Clinics
            </Typography>
            <ReactSelect
              name="clinics"
              value={clinics.map(clinic => ({
                value: clinic.idValue,
                label: `${clinic.displayValue}`,
              }))}
              handleOnChange={e => handleClinicsChange(e)}
              fields={appointmentClinics.map(clinic => ({
                label: clinic.source_facility_id,
                value: clinic.source_facility_id,
                serviceGroupId: clinic.service_group_id,
                id: clinic.id,
              }))}
              id="schedule_clinics_select"
              style={clinicsStyle}
            />
          </Grid>
          <Grid item md={2}>
            <Typography variant="caption" component="div">
              Service Groups
            </Typography>
            <ReactSelect
              value={selectedServiceGroups.map(serviceGroup => ({
                value: serviceGroup.idValue,
                label: `${serviceGroup.displayValue}`,
              }))}
              name="serviceGroups"
              handleOnChange={e => handleServiceGroupChange(e)}
              fields={serviceGroups.map(serviceGroup => ({
                label: serviceGroup.display_name,
                value: serviceGroup.id,
                isDisabled: !serviceGroup.active,
              }))}
              id="schedule_service_groups_select"
            />
          </Grid>
          <Grid item md={2}>
            <Typography variant="caption" component="div">
              Appointment Status
            </Typography>
            <ReactSelect
              value={selectedAppointmentStatuses.map(({ id: value, description: label }) => ({
                value,
                label,
              }))}
              name="appointmentStatuses"
              handleOnChange={e => handleAppointmentStatusesChange(e)}
              fields={lookupStatuses.map(status => ({
                label: status.description,
                value: status.id,
              }))}
              id="schedule_appointment_statuses_select"
            />
          </Grid>
          <Grid item md={2}>
            <Typography variant="caption" component="div">
              Patient Status
            </Typography>
            <ReactSelect
              value={selectedPatientStatuses.map(({ id: value, description: label }) => ({
                value,
                label,
              }))}
              name="patientStatuses"
              handleOnChange={e => handlePatientStatusesChange(e)}
              fields={patientStatuses.map(status => ({
                label: status.status,
                value: status.id,
              }))}
              id="schedule_patient_statuses_select"
            />
          </Grid>
        </Grid>
        <Grid item md={2} justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            data-qa-id="schedule-search-query"
            onClick={() => handleScheduleFiltersSearch()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

function mapStateToProps(state) {
  const { sidebarFilters, lookups } = state;
  return {
    sidebarFilters: sidebarFilters.data.schedule,
    lookups,
  };
}

export default compose(
  connect(mapStateToProps, {
    setSidebarFilter,
  }),
)(ScheduleFilterPanel);

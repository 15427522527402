import { Grid, Typography } from '@mui/material';
import { convertToArborDate } from 'models/time/arbor-date';
import React from 'react';
import { useTheme } from '@mui/styles';
import { IPharmacyBenefit } from 'components/patient-merge-modal/interfaces';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';

const PharmacyBenefitsSection = (props: IPharmacyBenefit) => {
  const {
    name: pbmName,
    startDate: pharmacyStartDate,
    endDate: pharmacyEndDate,
    type: pharmacyInsuranceType,
  } = props;

  const theme = useTheme();
  const classes = PatientMergeStyles(theme);
  const insuranceType = pharmacyInsuranceType ? `- ${pharmacyInsuranceType}` : '';
  const pharmacyName = pbmName ? `- ${pbmName}` : '';
  const startDate = pharmacyStartDate
    ? convertToArborDate(pharmacyStartDate).getUtcDate(true)
    : 'Unknown';
  const endDate = pharmacyEndDate
    ? convertToArborDate(pharmacyEndDate).getUtcDate(true)
    : 'Present';

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <Typography sx={classes.checkboxTitle}>
          {`Pharmacy Benefit ${insuranceType} ${pharmacyName} (${startDate} - ${endDate})`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PharmacyBenefitsSection;

import React from 'react';
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { getModalStyle } from 'services/utils/styles-service';
import { compose } from 'recompose';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { useDispatch } from 'react-redux';
import { BenefitsInvestigationService } from 'services/utils/benefits-investigation-service';
import { IBenefitsInvestigationDetailedHistoryItem } from 'interfaces/benefits-investigations/IBenefitsInvestigationDetailedHistoryItem';
import cx from 'classnames';
import { BenefitsInvestigationId } from 'interfaces/RecordTypes';
import { IRequest, ISegment } from 'interfaces/benefits-investigations/ncpdp/IRequest';
import BenefitsInvestigationFormHistory from 'containers/patient/therapy/therapy-benefits-investigation/benefits-investigation-form-history';
import {
  IBenefitsInvestigationApiResponse,
  IBenefitsInvestigationFormFields,
  IDur,
} from 'interfaces/benefits-investigations';
import { convertToArborDate } from 'models/time/arbor-date';
import { BenefitsInvestigationFields } from 'constants/lists';
import { NcpdpStringUtils } from 'utils/ncpdp-string-utils';
import { StringUtils } from 'utils/string-utils';
import { get as lsGet } from 'local-storage';
import { PharmacyService } from 'services/utils/pharmacy-service';
import { IPharmacy } from 'models/services/providers/pharmacy/IPharmacy';
import { benefitsInvestigationDetailedHistoryActions } from 'slices/benefits-investigation-detailed-history-slice';
import { parseFullName } from 'utils/name';
import { styles } from './benefits-investigation-detailed-history-modal-styles';
import { IClinic } from '../../../../interfaces/redux/ILookups';

// #region helper functions
const biFilter = (): ((item: IBenefitsInvestigationDetailedHistoryItem) => boolean) => {
  const lsValueSet = lsGet<boolean>('bi_history_show_all');
  if (lsValueSet === true) {
    return (item: IBenefitsInvestigationDetailedHistoryItem) => true;
  }
  return (item: IBenefitsInvestigationDetailedHistoryItem) => item.saved;
};

const buildDurs = (segment: ISegment): IDur[] => {
  if (segment.Fields) {
    /**
     * The segment for DUR information can contain multiple DURs. Need to iterate over each
     * of them until we hit a ceratin field which indicates the start of a new DUR.
     *
     * Example:
     *  The response comes to us and looks something like:
     *    [
     *      {field: '7E', value: 1},
     *      {field: 'E4', value: 'a1'},
     *      {field: 'E5', value: 'b1'},
     *      {field: 'E6', value: 'c1'},
     *      {field: '8E', value: 'd1'},
     *      {field: 'J9', value: 'e1'},
     *      {field: 'H6', value: 'f1'},
     *      {field: '7E', value: 2},
     *      {field: 'E4', value: 'a2'},
     *      {field: 'E5', value: 'b2'},
     *      {field: 'E6', value: 'c2'},
     *      {field: '8E', value: 'd2'},
     *      {field: 'J9', value: 'e2'},
     *      {field: 'H6', value: 'f2'},
     *    ]
     * and we need to turn that into objects that the UI understands which is something like:
     * [
     *   {
     *     reason: a1,
     *     otherProp: b1,
     *     otherProp2: b2,
     *     ...
     *   },
     *   {
     *     reason: a2,
     *     otherProp: b2,
     *     otherProp2: c2,
     *     ...
     *   }
     * ]
     *
     * In this case '7E' is the field that we use when iterating through the objects that tells
     * us when we need to create a new object for our UI.
     */
    const indicatorField = '7E';
    const results: IDur[] = [];

    const fieldToPropMap: Record<string, keyof IDur> = {
      'E4': 'reasonForService',
      'E5': 'professionalService',
      'E6': 'resultOfService',
      '8E': 'levelOfEffort',
      'J9': 'coAgentIdQualifier',
      'H6': 'coAgentId',
    };

    // Skip the first one because we know it's always the indicator field
    let tempItem: Partial<IDur> = {};
    segment.Fields.slice(1).forEach((field, index) => {
      if (field.Value) {
        switch (field.Id) {
          case indicatorField:
            results.push(tempItem as IDur);
            tempItem = {};
            break;
          default: {
            const fieldToUpdate = fieldToPropMap[field.Id];
            if (fieldToUpdate) {
              tempItem = {
                ...tempItem,
                [fieldToUpdate]: field.Value,
              };
            }
            break;
          }
        }
      }

      // @ts-ignore There's already an if check to make sure segment.fields is defined above.
      if (index === segment.Fields - 2) {
        results.push(tempItem as IDur);
      }
    });

    return results;
  }
  return [];
};
// #endregion

type IBenefitsInvestigationHistoryModalProps = WithStyles<typeof styles>;

type Props = IBenefitsInvestigationHistoryModalProps;

const BenefitsInvestigationHistoryModal: React.FC<Props> = (props: Props): JSX.Element => {
  const { classes } = props;

  // #region Redux
  const { open, therapyId, arTaskId } = useTypedSelector(
    state => state.benefitsInvestigationDetailedHistory,
  );
  const users = useTypedSelector(state => state.lookups.users);
  const patient = useTypedSelector(state => state.patient);
  const dispatch = useDispatch();
  // #endregion

  // #region State
  const [biService] = React.useState<BenefitsInvestigationService>(
    new BenefitsInvestigationService(),
  );
  const [historyItems, setHistoryItems] = React.useState<
    IBenefitsInvestigationDetailedHistoryItem[]
  >([]);
  const [pharmacyService] = React.useState<PharmacyService>(new PharmacyService());
  const [errorTracker, setErrorTracker] = React.useState<boolean>(false);
  const [selectedBiId, setSelectedBiId] = React.useState<BenefitsInvestigationId | undefined>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const [requestResponseData, setRequestResponseData] = React.useState<{
    request?: IRequest;
    response?: IBenefitsInvestigationApiResponse;
    request_string?: string;
    response_string?: string;
    pharmacy?: IPharmacy | null;
  }>();
  // #endregion

  const tasks = useTypedSelector(state => state.tasks?.data) || {};
  const taskList = Object.values(tasks) || [];
  const managingClinics = useTypedSelector(state => state.lookups.customerClinics);

  // #region functions
  const resetState = (callback: (() => void) | undefined = undefined) => {
    setLoading(true);
    setHistoryItems([]);
    setSelectedBiId(undefined);
    setRequestResponseData({});
    callback?.();
  };

  const retryLoading = () => {
    resetState();
    setErrorTracker(prev => !prev);
  };

  const onClose = () => {
    resetState();
    dispatch(benefitsInvestigationDetailedHistoryActions.close());
  };

  const onBiRowClick = (id: BenefitsInvestigationId) => {
    if (selectedBiId !== id) {
      setLoading(true);
      setSelectedBiId(id);
    }
  };

  const setNoListError = (): void => {
    setErrorMessage('Benefits investigations history not found.');
  };

  const setGenericError = (): void => {
    setErrorMessage('Error loading benefits investigation history.');
  };
  // #endregion

  // #region effects
  const patientId = patient.id;

  // Load history list when therapy id changes or the error tracker triggers
  React.useEffect(() => {
    (async () => {
      try {
        if (therapyId) {
          const results = await biService.getHistoryDetailList(patientId, therapyId);
          if (results.length) {
            const filteredResults = results.filter(biFilter());
            if (filteredResults.length) {
              setHistoryItems(filteredResults);
              setSelectedBiId(filteredResults[0].id);
              setErrorMessage(undefined);
            } else {
              throw new Error();
            }
          } else {
            throw new Error();
          }
        } else if (arTaskId) {
          const results = await biService.getHistoryDetailListFromAr(patientId, arTaskId);
          if (results.length) {
            const filteredResults = results.filter(biFilter());
            if (filteredResults.length) {
              setHistoryItems(filteredResults);
              setSelectedBiId(filteredResults[0].id);
              setErrorMessage(undefined);
            } else {
              throw new Error();
            }
          } else {
            throw new Error();
          }
        }
      } catch (error) {
        setNoListError();
        // Set to false since we don't care if it's successful. It's finished.
        setLoading(false);
      }
    })();
  }, [therapyId, arTaskId, errorTracker]);

  // Load history details when the selected bi changes or the error tracker triggers
  React.useEffect(() => {
    (async () => {
      if (selectedBiId) {
        try {
          let result = null;
          if (therapyId) {
            result = await biService.getHistoryDetail(patientId, therapyId, selectedBiId);
          }
          if (arTaskId) {
            result = await biService.getHistoryDetailFromAr(patientId, arTaskId, selectedBiId);
          }
          const pharmacyNpi = result?.request.ServiceProviderID;
          if (pharmacyNpi) {
            const pharmacyResult = await pharmacyService.getPharmacyByNpi(pharmacyNpi);
            setRequestResponseData({
              request: result?.request,
              response: result?.response,
              request_string: result?.request_string,
              response_string: result?.response_string,
              pharmacy: pharmacyResult,
            });
          } else {
            setGenericError();
          }
          setLoading(false);
        } catch (error) {
          setGenericError();
        } finally {
          // Set loading to false since we don't care if it worked or not .. it's finished.
          setLoading(false);
        }
      }
    })();
  }, [selectedBiId]);

  const getManagingClinicName = (): IClinic | null => {
    if (arTaskId !== undefined) {
      const [arTask] = taskList.filter(t => t.drugs?.some(drug => drug.id === arTaskId));
      if (arTask) {
        const [result] = managingClinics.filter(clinic => clinic.id === arTask.managing_clinic_id);
        return result;
      }
    }
    return null;
  };
  // #endregion

  // #region renders
  const renderBiHistoryList = (): JSX.Element => {
    return (
      <Grid container>
        {historyItems.map(item => {
          const className = cx({
            [classes.unselectedBiRow]: item.id !== selectedBiId,
            [classes.selectedBiRow]: item.id === selectedBiId,
            [classes.biRow]: true,
          });
          const userDisplayName = users.find(x => x.id === item.created_by)?.display_name;
          const createdString = convertToArborDate(item.created).getCustomerDatetime(true);
          return (
            <Grid
              item
              xs={12}
              onClick={() => onBiRowClick(item.id)}
              className={className}
              key={item.id}
            >
              <Typography className={classes.biRowTypography}>
                {createdString} - {userDisplayName}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const renderBiDetails = (): JSX.Element | null => {
    const requestData = requestResponseData?.request;
    const responseData = requestResponseData?.response;
    const requestString = requestResponseData?.request_string;
    const responseString = requestResponseData?.response_string;
    const pharmacy = requestResponseData?.pharmacy;

    if (requestData && responseData) {
      const patientSegment = requestData?.PatientSegment;
      const clinicalSegment = requestData?.ClinicalSegment;
      const insuranceSegment = requestData?.InsuranceSegment;
      const claimSegment = requestData?.AdditionalSegments?.find(
        seg => seg.SegmentIdentification === '07',
      );
      const pricingSegment = requestData?.AdditionalSegments?.find(
        seg => seg.SegmentIdentification === '11',
      );
      const prescriberSegment = requestData?.AdditionalSegments?.find(
        seg => seg.SegmentIdentification === '03',
      );
      const durSegment = requestData?.AdditionalSegments?.find(
        seg => seg.SegmentIdentification === '08',
      );

      const durItems = durSegment ? buildDurs(durSegment) : [];

      const biData = {
        // patient info
        firstName: patientSegment?.Fields?.find(x => x.Id === 'CA')?.Value,
        lastName: patientSegment?.Fields?.find(x => x.Id === 'CB')?.Value,
        dob: patientSegment?.Fields?.find(x => x.Id === 'C4')?.Value,
        placeOfResidence: patientSegment?.Fields?.find(x => x.Id === '4X')?.Value,
        gender: patientSegment?.Fields?.find(x => x.Id === 'C5')?.Value === '1' ? 'Male' : 'Female',
        street: patientSegment?.Fields?.find(x => x.Id === 'CM')?.Value,
        city: patientSegment?.Fields?.find(x => x.Id === 'CN')?.Value,
        state: patientSegment?.Fields?.find(x => x.Id === 'CO')?.Value,
        zip: patientSegment?.Fields?.find(x => x.Id === 'CP')?.Value,

        // rx info
        drugNameNdc: claimSegment?.Fields?.find(x => x.Id === 'D7')?.Value,
        compoundCode: claimSegment?.Fields?.find(x => x.Id === 'D6')?.Value,
        dawCode: claimSegment?.Fields?.find(x => x.Id === 'D8')?.Value,
        dateWritten: claimSegment?.Fields?.find(x => x.Id === 'DE')?.Value,
        prescriptionOriginCode: claimSegment?.Fields?.find(x => x.Id === 'DJ')?.Value,
        dateOfService: requestData?.DateOfService,
        fillNo: claimSegment?.Fields?.find(x => x.Id === 'D3')?.Value,
        daysSupply: claimSegment?.Fields?.find(x => x.Id === 'D5')?.Value,
        quantity: claimSegment?.Fields?.find(x => x.Id === 'E7')?.Value,
        rxNo: claimSegment?.Fields?.find(x => x.Id === 'D2')?.Value,
        dispensingPharmacyNpi: requestData?.ServiceProviderID,
        dispensingPharmacy: pharmacy,

        // insurance info
        bin: requestData?.Bin,
        pcn: requestData?.Pcn || '',
        // eslint-disable-next-line eqeqeq
        group: insuranceSegment?.Fields?.find(x => x.Id == 'C1')?.Value,
        // eslint-disable-next-line eqeqeq
        relationCode: insuranceSegment?.Fields?.find(x => x.Id == 'C6')?.Value,

        // segment
        segmentClarificationCd: insuranceSegment?.Fields?.find(x => x.Id === 'C9')?.Value,
        segmentOtherCvgCode: claimSegment?.Fields?.find(x => x.Id === 'C8')?.Value,
        segmentSpecialPkgIndicator: claimSegment?.Fields?.find(x => x.Id === 'DT')?.Value,
        segmentUnitOfMeasure: claimSegment?.Fields?.find(x => x.Id === '28')?.Value,
        segmentLevelOfService: claimSegment?.Fields?.find(x => x.Id === 'DI')?.Value,
        segmentPharmServiceType: claimSegment?.Fields?.find(x => x.Id === 'U7')?.Value,
        segmentPlaceOfService: patientSegment?.Fields?.find(x => x.Id === 'C7')?.Value,

        // pricing
        pricingBasisOfCostDebt: pricingSegment?.Fields?.find(x => x.Id === 'DN')?.Value,
        pricingIngredientCost: pricingSegment?.Fields?.find(x => x.Id === 'D9')?.Value,
        pricingAwpUnitPrice: '',
        pricingUsualAndCustomary: pricingSegment?.Fields?.find(x => x.Id === 'DQ')?.Value,
        pricingGrossAmountDue: pricingSegment?.Fields?.find(x => x.Id === 'DU')?.Value,

        // pharmacy provider segments
        // pharmacyProviderProviderType: undefined,
        prescriberPhone: prescriberSegment?.Fields?.find(x => x.Id === 'PM')?.Value,

        // dur

        // others
        softwareCertId: requestData?.SoftwareVendor,

        icdCode: clinicalSegment?.Fields?.find(x => x.Id === '424-DO')?.Value,

        prescriber: {
          prescriberNpi: prescriberSegment?.Fields?.find(x => x.Id === 'DB')?.Value,
          prescriberName: prescriberSegment?.Fields?.find(x => x.Id === 'DR')?.Value,
          prescriberPhone: prescriberSegment?.Fields?.find(x => x.Id === 'PM')?.Value,
          prescribingClinic: '',
          managingClinic: getManagingClinicName()?.name,
          serviceGroup: insuranceSegment?.Fields?.find(x => x.Id === 'C1')?.Value,
        },
      };

      /**
       * List of functions required to go from an overpunch to a number.
       *
       * Given something like:
       *  "999E" should return "99.95"
       *  "25}" should return "-2.50"
       */
      const overpunchToNumber = [
        NcpdpStringUtils.convertOverpunchNumber,
        NcpdpStringUtils.prettyNumberString,
      ];

      const initialValues: Partial<IBenefitsInvestigationFormFields> = {
        // patient info
        firstName: biData.firstName,
        lastName: biData.lastName,
        placeOfResidence: BenefitsInvestigationFields.PlaceOfResidence.find(
          x => x.value === biData.placeOfResidence,
        )?.value,
        dob: convertToArborDate(biData.dob).getUtcDate(true),
        gender: biData.gender,
        street: biData.street,
        city: biData.city,
        state: biData.state,
        zip: biData.zip,

        // rx info
        drugNameNdc: biData.drugNameNdc,
        compoundCode: BenefitsInvestigationFields.CompoundCodes.find(
          x => x.value === biData.compoundCode,
        )?.value,
        dawCode: BenefitsInvestigationFields.DawCodes.find(x => x.value === biData.dawCode)?.value,
        dateWritten: convertToArborDate(biData.dateWritten).getUtcDate(true),
        prescriptionOriginCode: BenefitsInvestigationFields.PrescriptionOriginCode.find(
          x => x.value === biData.prescriptionOriginCode,
        )?.value,
        dateOfService: convertToArborDate(biData.dateOfService).getUtcDate(true),
        fillNo: biData.fillNo,
        daysSupply: biData.daysSupply,
        quantity: NcpdpStringUtils.toStandardNumber(biData.quantity),
        rxNo: biData.rxNo,
        dispensingPharmacyNpi: biData.dispensingPharmacyNpi,
        dispensingPharmacy: biData.dispensingPharmacy?.id as any,

        // insurance info
        bin: biData.bin,
        pcn: biData.pcn || '',
        group: biData.group,
        relationCode: biData.relationCode,
        personCode: insuranceSegment?.Fields?.find(x => x.Id === 'C3')?.Value,

        // segment
        segmentClarificationCd: biData.segmentClarificationCd,
        segmentOtherCvgCode: BenefitsInvestigationFields.OtherCoverageCode.find(
          x => x.value === biData.segmentOtherCvgCode,
        )?.value,
        segmentSpecialPkgIndicator: BenefitsInvestigationFields.SpecialPackagingIndicator.find(
          x => x.value === biData.segmentSpecialPkgIndicator,
        )?.value,
        segmentUnitOfMeasure: BenefitsInvestigationFields.UnitOfMeasure.find(
          x => x.value === biData.segmentUnitOfMeasure,
        )?.value,
        segmentLevelOfService: BenefitsInvestigationFields.LevelOfService.find(
          x => x.value === biData.segmentLevelOfService,
        )?.value,
        segmentPharmServiceType: BenefitsInvestigationFields.PharmServiceType.find(
          x => x.value === biData.segmentPharmServiceType,
        )?.value,
        segmentPlaceOfService: BenefitsInvestigationFields.PlaceOfService.find(
          x => x.value === biData.segmentPlaceOfService,
        )?.value,

        // pricing
        pricingBasisOfCostDebt: BenefitsInvestigationFields.BasisOfCostDet.find(
          x => x.value === biData.pricingBasisOfCostDebt,
        )?.value,
        pricingIngredientCost: StringUtils.continuallyConvert(
          biData.pricingIngredientCost,
          overpunchToNumber,
        ),
        pricingUsualAndCustomary: StringUtils.continuallyConvert(
          biData.pricingUsualAndCustomary,
          overpunchToNumber,
        ),
        pricingGrossAmountDue: StringUtils.continuallyConvert(
          biData.pricingGrossAmountDue,
          overpunchToNumber,
        ),

        // pharmacy provider segments
        // pharmacyProviderProviderType: undefined
        prescriberPhone: biData.prescriberPhone,

        // others
        softwareCertId: biData.softwareCertId,
        prescriberHistory: biData.prescriber,

        icdCode: {
          code: biData.icdCode || '',
        },
      };

      const stateValues = {
        patientFullName: parseFullName(patient),
      };

      /**
       * convert the {code: string, message: string}[] items to Record<string, string> so that
       * the convertResponseForUi method understands the shape of the information. The key is the
       * code, the value is the message.
       *
       * TODO: Figure out why we expect Record<string,string> in one place but
       * {message: string, code: string}[] in another.
       */
      const parsedErrorCodeMessages: Record<string, string> = (responseData?.errors || []).reduce(
        (acc, curr) => {
          return {
            ...acc,
            [curr.code]: curr.message,
          };
        },
        {},
      );

      const convertedResponseData = biService.convertResponseForUi(
        {
          responseObj: responseData || {},
          benefitInvestigationId: selectedBiId || -1,
          errorCodeMessages: parsedErrorCodeMessages,
        },
        { drug_name: '', days_supply: initialValues.daysSupply || '' },
        {
          rxNo: initialValues.rxNo || '',
          bin: initialValues.bin || '',
          pcn: initialValues.pcn || '',
          quantity: initialValues.quantity || '',
          daysSupply: initialValues.daysSupply || '',
        },
      );

      return (
        <BenefitsInvestigationFormHistory
          historicalData={{
            responseData: convertedResponseData || {},
            stateValues: { initialValues: initialValues, ...stateValues },
            durItems: durItems,
            pharmacies: [requestResponseData?.pharmacy],
          }}
          therapyId={therapyId}
          arTaskId={arTaskId}
          buttonsOverride={
            <Grid
              container
              justifyContent="flex-end"
              alignItems="flex-end"
              style={{ marginTop: 10 }}
            >
              {[
                { label: 'NCPDP Request (Raw)', value: requestString, key: 'request_string' },
                { label: 'NCPDP Response (Raw)', value: responseString, key: 'response_string' },
              ].map(item => {
                // Remove the first and last character since they are always a quote ('"')
                const trimmedValue = item.value
                  ? item.value.substring(1, item.value.length - 1)
                  : undefined;
                return (
                  <Grid item xs={3} key={item.key}>
                    <TextField
                      variant="standard"
                      defaultValue={trimmedValue}
                      label={item.label}
                      classes={{
                        root: classes.rawTextFieldRoot,
                      }}
                      inputProps={{
                        className: classes.rawTextFieldInputBase,
                      }}
                    />
                  </Grid>
                );
              })}

              <Grid item>
                <Button data-qa-id="bi-detailed-history-modal-close" onClick={onClose}>
                  Close
                </Button>
              </Grid>
            </Grid>
          }
        />
      );
    }
    return null;
  };
  // #endregion

  return (
    <Modal
      open={open || false}
      onClick={event => event.preventDefault()}
      data-qa-id="benefits-investigation-history-modal"
      onClose={onClose}
    >
      <div style={getModalStyle()} className={classes.modal}>
        <Grid container spacing={2}>
          {errorMessage == null ? (
            <>
              <Grid item xs={3} className={classes.biHistoryList}>
                {renderBiHistoryList()}
              </Grid>
              <Grid item xs={9}>
                {!loading && renderBiDetails()}
              </Grid>
            </>
          ) : (
            <Grid item xs={12} className={classes.biHistoryList}>
              <Grid container className={classes.errorContainer}>
                <Grid item xs={12}>
                  <h1>{errorMessage || 'An Error Occured'}</h1>
                  <Button onClick={retryLoading} variant="outlined">
                    Retry?
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Backdrop
          open={loading}
          className={cx(classes.backdrop)}
          transitionDuration={{ appear: 0, enter: 0, exit: 250 }}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      </div>
    </Modal>
  );
};

export default compose<Props, IBenefitsInvestigationHistoryModalProps>(withStyles(styles))(
  BenefitsInvestigationHistoryModal,
);

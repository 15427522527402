import React, { FC } from 'react';
import cx from 'classnames';
import { Tooltip, TooltipProps } from '@mui/material';

interface DisplayOnlyItemProps extends Omit<TooltipProps, 'classes' | 'children'> {
  className: string;
  Icon: FC<{ className: string }>;
}

const DisplayOnlyBar: React.FC<DisplayOnlyItemProps> = ({ className, Icon, ...props }) => {
  return (
    <Tooltip {...props}>
      <span>
        <Icon className={className} />
      </span>
    </Tooltip>
  );
};

export default DisplayOnlyBar;

export const styles = theme => ({
  header: {
    backgroundColor: theme.palette.primary.headerCardBackground.main,
    height: '100%',
    paddingLeft: 20,
  },
  headerText: {
    fontWeight: 'bold',
  },
  headerContainer: {
    height: '10%',
  },
  highlight: {
    backgroundColor: theme.palette.primary.warning,
  },
  container: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  contentContainer: {
    height: '90%',
    overflow: 'auto',
  },
  historyContainer: {
    height: '100%',
  },
  historyTable: {
    marginBottom: '1rem',
    fontFamily: "'Gotham SSm A', 'Gotham SSm B', Verdana, Geneva, Tahoma, sans-serif",
    padding: theme.spacing(0),
  },
  historyTableContainer: {
    'overflow': 'scroll',
    'maxHeight': '60vh',
    '& thead th': {
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      zIndex: 1,
      padding: 0,
    },
  },
  historyTableMain: {
    'minWidth': '100%',
    'borderSpacing': 0,
    'border': `1px solid ${theme.palette.primary.grey13}`,
    '& tr': {
      'fontSize': '0.8125rem',
      '& td': {
        borderTop: `0.1px solid ${theme.palette.primary.grey13}`,
      },
    },
  },
  historyHeaderTh: {
    'minWidth': '100%',
    'position': 'relative',
    'margin': 0,
    'padding': '0.4rem',
    'borderRight': `1px solid ${theme.palette.primary.grey13}`,
    '& :last-child': {
      '& th': {
        borderRight: 0,
      },
    },
    'borderTop': `1px solid ${theme.palette.primary.grey13}`,
    'borderBottom': `2px solid ${theme.palette.primary.grey13}`,
    'fontSize': '0.8125rem',
    'textAlign': 'center',
    'color': theme.palette.primary.steelGrey,
    '& button': {
      padding: '0px',
      marginLeft: '10px',
    },
    'backgroundColor': theme.palette.primary.white,
  },
  headerTh: {
    fontWeight: 300,
    fontSize: theme.spacing(1.75),
    minWidth: '100%',
    height: theme.spacing(3),
  },
  headerTr: {
    'minWidth': '100%',
    'display': 'table',
    '& th > input': {
      padding: theme.spacing(0),
      textIndent: theme.spacing(1),
    },
    'paddingTop': theme.spacing(0.8),
  },
  headerTrFilter: {
    'minWidth': '100%',
    'display': 'table',
    '& th > input': {
      padding: theme.spacing(0),
      textIndent: theme.spacing(1),
      minWidth: '95%',
      justifyContent: 'space-between',
      borderRadius: theme.spacing(0.5),
    },
    'paddingBottom': theme.spacing(0.8),
  },
  tbodyTr: {
    textIndent: theme.spacing(1),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  mainTbody: {
    '& tr:nth-child(odd)': {
      backgroundColor: theme.palette.primary.grey14,
    },
    '& tr': {
      height: theme.spacing(4.5),
    },
    '& td': {
      'position': 'relative',
      'borderRight': `1px solid ${theme.palette.primary.grey13}`,
      '& :last-child': {
        borderRight: 0,
      },
      '& td.resizer': {
        display: 'inline-block',
        background: theme.palette.primary.steelGrey,
        width: '10px',
        height: '100%',
        top: 0,
        transform: 'translateX(10%)',
        zIndex: 1,
        touchAction: 'none',
      },
    },
  },
  filterInput: {
    minHeight: 23,
    border: `1px solid ${theme.palette.primary.grey13}`,
    minWidth: '100%',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  paginationBtnsContainer: {
    backgroundColor: theme.palette.primary.white,
    border: `1px solid ${theme.palette.primary.grey13}`,
    minHeight: theme.spacing(6),
  },
  paginationBtns: {
    'padding': theme.spacing(0.5),
    '& button': {
      width: '100%',
      height: '100%',
      border: 0,
      borderRadius: theme.spacing(0.5),
      fontSize: 14,
      transition: 'all .1s ease',
      cursor: 'pointer',
      outlineWidth: 0,
    },
  },
  paginationControls: {
    'textAlign': 'center',
    '& span': {
      'height': theme.spacing(6),
      'lineHeight': theme.spacing(6),
      'display': 'inline-block',
      '& select': {
        height: theme.spacing(4.5),
        border: `1px solid ${theme.palette.primary.grey13}`,
      },
      '& input': {
        height: theme.spacing(4),
        minWidth: theme.spacing(2.5),
        border: `1px solid ${theme.palette.primary.grey13}`,
        textIndent: theme.spacing(1),
      },
    },
  },
});

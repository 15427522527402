import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { useMemo, useEffect } from 'react';
import { notifyError } from 'actions/action-notifications';
import { ClinicalDataItem } from 'interfaces/clinical-data/ClinicalDataResponse';
import { windowFeatureIsEnabled } from 'config/window-features';
import { fetchClinicalData } from '../../../actions/action-clinical-data';
import { ClinicalDataClient } from '../../../clients/clinical-data';
import { logger } from '../../../winston-logger';
import { getClinicalItemsBasedOnIntMapping } from '../tasks-new/util';
/**
 * Ensures that the patient's clinical data is refreshed from the state, and returns it.
 * @param patientId
 * @returns the patient's clinical data. Returns undefined while the data is still being fetched
 */
export const useClinicalData = (patientId: number): ClinicalDataItem[] | undefined => {
  const clinicalData = useTypedSelector(state => {
    return state.clinicalData;
  });
  const dispatch = useDispatch();

  const fetchAndSetPatientClinicalData = async () => {
    if (!clinicalData && patientId) {
      try {
        const response = await ClinicalDataClient.fetch(patientId);
        const fetchAction = fetchClinicalData(response.data);
        dispatch(fetchAction);
      } catch (error) {
        logger.error(error);
        dispatch(notifyError('Unable to fetch patient clinical data.'));
      }
    }
  };

  useEffect(() => {
    fetchAndSetPatientClinicalData();
  }, [patientId, clinicalData]);

  return clinicalData ?? undefined;
};

export const useClinicalDataDCTask = (
  patientId: number,
  dcTaskId: number,
  mergeInterventionMapping: { wag_hash: string; ints: any[] }[],
  selectedTaskKeys: string[],
): ClinicalDataItem[] | undefined => {
  const clinicalDataForPatient = useClinicalData(patientId);
  const clinicalDataForPatientTask = useMemo(() => {
    const clinicalItemsToCheck = getClinicalItemsBasedOnIntMapping(
      dcTaskId,
      mergeInterventionMapping,
      selectedTaskKeys,
    );
    const dcClinicalData =
      windowFeatureIsEnabled('merge_interventions') &&
      clinicalItemsToCheck.needToCheck &&
      clinicalItemsToCheck.clinicalItemTaskPairs.length
        ? filterClinicalDatafromIntMapping(
            clinicalDataForPatient,
            clinicalItemsToCheck.clinicalItemTaskPairs,
          )
        : filterClinicalDataForDCTask(clinicalDataForPatient, dcTaskId);
    return dcClinicalData;
  }, [clinicalDataForPatient, dcTaskId]);
  return clinicalDataForPatientTask;
};

export const useClinicalDataRSTask = (
  patientId: number,
  rsTaskId: number,
): ClinicalDataItem[] | undefined => {
  const clinicalDataForPatient = useClinicalData(patientId);
  const clinicalDataForPatientTask = useMemo(
    () => filterClinicalDataForRSTask(clinicalDataForPatient, rsTaskId),
    [clinicalDataForPatient, rsTaskId],
  );
  return clinicalDataForPatientTask;
};

export const useClinicalDataItem = (
  patientId: number,
  clinicalDataItemId: number,
): ClinicalDataItem | undefined => {
  const clinicalDataForPatient = useClinicalData(patientId);
  const clinicalDataItem = useMemo(
    () => findClinicalDataItem(clinicalDataForPatient, clinicalDataItemId),
    [clinicalDataForPatient, clinicalDataItemId],
  );
  return clinicalDataItem;
};

const filterClinicalDataForDCTask = (
  clinicalDataForPatient: ClinicalDataItem[] | undefined,
  taskId: number,
): ClinicalDataItem[] | undefined => {
  return clinicalDataForPatient?.filter(data => data.taskDataCollectId === taskId);
};

const filterClinicalDatafromIntMapping = (
  clinicalDataForPatient: ClinicalDataItem[] | undefined,
  clinicalItemTaskPairs: { clinicalDataTypeId: number; taskId: number }[],
): ClinicalDataItem[] | undefined => {
  return clinicalDataForPatient?.reduce((acc, it) => {
    const tempItem = clinicalItemTaskPairs.find(
      t => t.clinicalDataTypeId === it.clinicalDataTypeId && t.taskId === it.taskDataCollectId,
    );
    if (tempItem && Object.keys(tempItem).length) {
      acc.push(it);
    }
    return acc;
  }, [] as ClinicalDataItem[]);
};

const filterClinicalDataForRSTask = (
  clinicalDataForPatient: ClinicalDataItem[] | undefined,
  taskId: number,
): ClinicalDataItem[] | undefined => {
  return clinicalDataForPatient?.filter(data => data.taskRiskStratId === taskId);
};

const findClinicalDataItem = (
  clinicalDataForPatient: ClinicalDataItem[] | undefined,
  itemId: number,
): ClinicalDataItem | undefined => {
  return clinicalDataForPatient?.find(data => data.id === itemId);
};

import { isEqualStringIgnoreCase, isEmptyString } from 'services/utils/common-service';
import { parseLocation } from 'parse-address';
import { format as formatPhone } from 'phone-fns';

export const getUniqueSourceMedId = med => `source-${med.source_id}-${med.source_medication_id}`;

const parseAddress = addr => {
  const parsedObj = parseLocation(
    `${addr.line1} ${addr.line2 || ''} ${addr.city} ${addr.state} ${addr.zip}`,
  );
  return `${addr.use}: ${parsedObj.number || ''} ${parsedObj.prefix || ''} 
  ${parsedObj.street || ''} ${parsedObj.type || ''}, ${parsedObj.city || ''},
  ${parsedObj.state || ''} ${parsedObj.zip || ''}`;
};

const parsePhone = phone => `${phone.use}: ${formatPhone('(NNN) NNN-NNNN', phone.value)}`;

const parseEmail = email => `${email.use}: value: ${email.value}`;

export const getParsingFunc = name => {
  switch (name) {
    case 'addresses':
      return parseAddress;
    case 'emails':
      return parseEmail;
    case 'phones':
      return parsePhone;
    default:
      return () => {};
  }
};

export const addCommunicationToExcludedValues = (name, arr, excludedValues) => {
  const excludedArr = excludedValues[name] || [];
  const parsingFunc = getParsingFunc(name);
  const excludedStrArr = excludedArr.map(p => parsingFunc(p));
  arr.forEach(a => {
    if (!excludedStrArr.some(esa => isEqualStringIgnoreCase(esa, parsingFunc(a)))) {
      excludedArr.push(a);
    }
  });
  if (excludedArr.length) {
    excludedValues[name] = excludedArr;
  }
};

export const processCommunicationArray = (name, array, stagingArray) => {
  const parsingFunc = getParsingFunc(name);
  const existingStrArr = Array.isArray(array) ? array.map(a => parsingFunc(a)) : [];
  let newStrArr = Array.isArray(stagingArray)
    ? stagingArray.map(a => ({ stringForm: parsingFunc(a), object: a }))
    : [];
  let showCard = false;
  const highlightIndexes = [];
  const reconciliationObjs = [];
  const reconciliationObjStrArr = [];
  newStrArr.forEach((addrStr, index) => {
    if (!existingStrArr.some(esr => isEqualStringIgnoreCase(esr, addrStr.stringForm))) {
      showCard = true;
      highlightIndexes.push(index);
      reconciliationObjs.push(addrStr.object);
    }
  });
  newStrArr = newStrArr.map(str => str.stringForm);

  return {
    showCard,
    existingStrArr,
    newStrArr,
    highlightIndexes,
    reconciliationObjs,
    reconciliationObjStrArr,
  };
};

const removeExcludedCommunication = (name, arr, excludedArr) => {
  if (!Array.isArray(arr) || !Array.isArray(excludedArr)) {
    return arr;
  }
  const parsingFunc = getParsingFunc(name);
  const excludedStrArr = excludedArr.map(a => parsingFunc(a));
  if (name === 'phones' || name === 'emails') {
    arr = arr.filter(a => !isEmptyString(a.value));
  }
  return arr.filter(a => !excludedStrArr.includes(parsingFunc(a)));
};

export const removeExcludedChangesForPatient = (patient, stagingPatient) => {
  const excludedValues = stagingPatient.excluded_values || {};
  Object.keys(stagingPatient).forEach(key => {
    if (key === 'addresses' || key === 'emails' || key === 'phones') {
      const deletedItems = Array.isArray(patient[key]) ? patient[key].filter(i => i.deleted) : [];
      const excludedItems = Array.isArray(excludedValues[key]) ? excludedValues[key] : [];
      stagingPatient[key] = removeExcludedCommunication(key, stagingPatient[key], [
        ...excludedItems,
        ...deletedItems,
      ]);
    } else {
      stagingPatient[`original_${key}`] = stagingPatient[key];
      if (stagingPatient[key] === excludedValues[key]) {
        stagingPatient[key] = patient[key];
      }
    }
  });
};

export const checkPatientBasicInfoChange = (patient, stagingPatient) =>
  !isEqualStringIgnoreCase(patient.first_name, stagingPatient.first_name) ||
  !isEqualStringIgnoreCase(patient.last_name, stagingPatient.last_name) ||
  !isEqualStringIgnoreCase(patient.middle_name, stagingPatient.middle_name) ||
  !isEqualStringIgnoreCase(patient.gender, stagingPatient.gender) ||
  patient.dob !== stagingPatient.dob ||
  patient.deceased !== stagingPatient.deceased;

export const setMedicationExcludedValue = (
  comparedMedication,
  stagingMedication,
  excludedValues,
) => {
  const medicationReconcilationFields = [
    'ndc',
    'status_code',
    'category_code',
    'start_dt',
    'end_dt',
    'number_of_refills',
    'is_prn',
    'prn_instructions',
    'prn_reason',
  ];
  medicationReconcilationFields.forEach(field => {
    if (!comparedMedication || comparedMedication[field] !== stagingMedication[field]) {
      if (field === 'start_dt' || field === 'end_dt') {
        excludedValues[field] = stagingMedication[`original_${field}`];
      } else {
        excludedValues[field] = stagingMedication[field];
      }
    }
  });
};

export const setAllergyExcludedValue = (comparedAllergy, stagingAllergy, excludedValues = {}) => {
  const allergyReconcilationFields = [
    'allergen_name',
    'allergen_id',
    'category',
    'reaction_type_ids',
    'onset_dt',
    'severity_id',
    'status_id',
    'allergy_source_id',
    'allergen_notes',
  ];
  allergyReconcilationFields.forEach(field => {
    if (comparedAllergy[field] !== null && stagingAllergy[field] !== null) {
      if (!comparedAllergy || comparedAllergy[field] !== stagingAllergy[field]) {
        if (field === 'onset_dt') {
          excludedValues[field] = stagingAllergy[`original_${field}`];
        } else {
          excludedValues[field] = stagingAllergy[field];
        }
      }
    }
  });
};

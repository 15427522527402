import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

export const CtdStyles = (theme: Theme) => {
  const { palette } = expandTheme(theme);
  return createStyles({
    button: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      minWidth: theme.spacing(17),
      marginLeft: theme.spacing(1),
    },
    selectedButton: {
      backgroundColor: palette.primary.teal,
      color: palette.primary.white,
    },
    searchField: {
      width: theme.spacing(37),
    },
    searchIcon: {
      color: theme.palette.grey[500],
    },
    textFieldEndAdornment: {
      cursor: 'pointer',
    },
    buttonGrid: {
      marginLeft: theme.spacing(1.5),
    },
    reportButton: {
      alignSelf: 'end',
    },
  });
};

import React from 'react';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { styles } from './header-styles';

const SubHeader = ({ name }) => (
  <div>
    <Typography variant="subtitle2">{name}</Typography>
  </div>
);

export default withStyles(styles, { withTheme: true })(SubHeader);

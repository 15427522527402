import { Grid, Typography } from '@mui/material';
import { convertToArborDate } from 'models/time/arbor-date';
import React from 'react';
import { IMedicalBenefit } from 'components/patient-merge-modal/interfaces';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { useTheme } from '@mui/styles';

const MedicalBenefitsSection = ({
  name: medicalPlanName,
  startDate: medicalStartDate,
  endDate: medicalEndDate,
  type: medicalInsuranceType,
}: IMedicalBenefit) => {
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);

  const insuranceType = medicalInsuranceType ? `- ${medicalInsuranceType}` : '';
  const planName = medicalPlanName ? `- ${medicalPlanName}` : '';
  const startDate = medicalStartDate
    ? convertToArborDate(medicalStartDate).getUtcDate(true)
    : 'Unknown';
  const endDate = medicalEndDate ? convertToArborDate(medicalEndDate).getUtcDate(true) : 'Present';

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <Typography sx={classes.checkboxTitle}>
          {`Medical Benefit ${insuranceType} ${planName} (${startDate} - ${endDate})`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MedicalBenefitsSection;

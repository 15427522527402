import React, { useMemo } from 'react';
import moment from 'moment';

import withStyles from '@mui/styles/withStyles';
import { compose } from 'recompose';
import {
  SCHEDULE_OUTREACH_STATUS_COMMUNICATION_PHONE_ID,
  SCHEDULE_OUTREACH_STATUS_COMMUNICATION_INPERSON_ID,
  fillCycleFilterOptions,
} from 'constants/index';
import { getAuditCategory, auditCategoriesRaw } from 'services/utils/audit-service';
import {
  getFinancialTypeFilterValueByKey,
  methodOfDelivery,
  fcInProgressReasons as FCReasons,
} from 'constants/lists';
import { styles } from './task-list-filter-pills-styles';
import {
  getDatesFromSidebarFilters,
  outreachTypeByNameForFilters,
  incidentTypeByNameForFilters,
  taskStatusByNameForFilters,
  calcIncidentCombinedResponsibles,
} from '../../../../services/utils/filters-service';

type FilterKey =
  | 'from'
  | 'to'
  | 'date'
  | 'complete'
  | 'dispensingEnrollmentStatusIds'
  | 'administrationStatusIds'
  | 'clinics'
  | 'serviceGroups'
  | 'customers'
  | 'selectedCustomerId'
  | 'emrs'
  | 'statusIds'
  | 'excludeOverdueARs'
  | 'AR'
  | 'DUR'
  | 'DC'
  | 'PA'
  | 'FA'
  | 'CSL'
  | 'FC'
  | 'FDC'
  | 'RS'
  | 'INT'
  | 'OR'
  | 'TPR'
  | 'QRE'
  | 'perProtocol'
  | 'paNewRenewal'
  | 'faNewRenewal'
  | 'fdc'
  | 'cslInitialOngoing'
  | 'fdcMethodsOfDelivery'
  | 'orType'
  | 'orCategory'
  | 'incidentType'
  | 'incidentCategory'
  | 'incidentResponsibleParty'
  | 'auditWarningsCategory'
  | 'auditWarningsRule'
  | 'therapyTypes'
  | 'coldChain'
  | 'urgent'
  | 'adherencePackaging'
  | 'displayOnlyFC'
  | 'displayOnlyDC'
  | 'displayOnlyCSL'
  | 'displayOnlyINT'
  | 'fillCycle'
  | 'dispensingPharmacies'
  | 'fcInProgressReason'
  | 'assistanceProgram'
  | 'arCommunicationType'
  | 'arReasonIds';

interface Styles {
  filterPill: string;
  inlineFilterPill: string;
  xFilterPill: string;
}

interface ITaskListFilterPillsProps {
  classes: Styles;
  filters: Record<FilterKey, any>;
  lookups: any;
  sidebarTaskType: any;
  taskStatuses: any;
  onRemoveFilters: (filterKeys: FilterKey[], filterIds?: number[]) => void;
}

const queueIgnoresTherapyTypeFilter = ['STAR', 'SMS', 'AR', 'DUR', 'RS', 'QRE'];
const queueIgnoresClinicFilter = ['STAR', 'SMS', 'DUR', 'RS'];
const queueIgnoresDateFilter = ['STAR'];
const queueIgnoresServiceGroupFilter = ['STAR'];

const constructDateRangeFilterPill = (
  classes: Styles,
  fromKey: FilterKey,
  toKey: FilterKey,
  filters: Record<FilterKey, any>,
  onRemoveFilters: (filterKeys: FilterKey[]) => void,
) => {
  const { from, to } = getDatesFromSidebarFilters(filters);
  if (!from && !to) {
    return null;
  }
  const fromDate = moment(from as string);
  const toDate = moment(to as string);
  // apparently sometimes these are truthy but not moments
  const validFrom = fromDate.isValid();
  const validTo = toDate.isValid();
  return (
    <div key={`${fromKey}_${toKey}`} className={classes.inlineFilterPill}>
      {validFrom ? fromDate.format('MM/DD/YY') : '...'} -{' '}
      {validTo ? toDate.format('MM/DD/YY') : '...'}
      <span
        className={classes.xFilterPill}
        onClick={() => {
          onRemoveFilters([fromKey, toKey, 'date']);
          onRemoveFilters(['excludeOverdueARs']);
        }}
      >
        X
      </span>
    </div>
  );
};

const constructServiceGroupPill = (
  classes: Styles,
  key: FilterKey,
  serviceGroup: IServiceGroup,
  onRemoveFilters: (filterKeys: FilterKey[], filterIds?: number[]) => void,
) => {
  if (!serviceGroup) {
    return null;
  }
  return (
    <div key={`${key}${serviceGroup.id}`} className={classes.inlineFilterPill}>
      {serviceGroup.display_name}
      <span
        className={classes.xFilterPill}
        onClick={() => onRemoveFilters([key], [Number(serviceGroup.id)])}
      >
        X
      </span>
    </div>
  );
};

const buildDisplayName = (key: FilterKey, display_name: string) => {
  const keyNamedStatuses = [
    'AR',
    'DUR',
    'DC',
    'PA',
    'FA',
    'CSL',
    'FC',
    'FDC',
    'RS',
    'INT',
    'OR',
    'TPR',
    'QRE',
  ];
  if (key === 'orCategory') {
    return `OR Category - ${display_name}`;
  }
  if (key === 'orType') {
    return `OR Type - ${display_name}`;
  }
  if (key === 'incidentCategory') {
    return `QRE Category - ${display_name}`;
  }
  if (key === 'incidentType') {
    return `QRE Type - ${display_name}`;
  }
  if (key === 'incidentResponsibleParty') {
    return `QRE Responsible Party - ${display_name}`;
  }
  if (keyNamedStatuses.indexOf(key) > -1) {
    return `${key} - ${display_name}`;
  }
  if (key === 'excludeOverdueARs') {
    return `AR - ${display_name}`;
  }
  if (key === 'arReasonIds') {
    return `AR Reason - ${display_name}`;
  }
  if (key === 'arCommunicationType') {
    return `AR communication type - ${display_name}`;
  }
  return display_name;
};

const constructTaskPill = (
  classes: Styles,
  key: FilterKey,
  task: ITask,
  onRemoveFilters: (filterKeys: FilterKey[], filterIds?: number[]) => void,
  nonNumericId?: boolean,
) => {
  if (!task) {
    return null;
  }
  const final_display_name = buildDisplayName(key, task.display_name);
  const finalTaskId = nonNumericId ? task.id : Number(task.id);
  return (
    <div key={`${key}${task.id}`} className={classes.inlineFilterPill}>
      {final_display_name}
      <span className={classes.xFilterPill} onClick={() => onRemoveFilters([key], [finalTaskId])}>
        X
      </span>
    </div>
  );
};

const constructSimpleTextPill = (
  classes: Styles,
  key: FilterKey,
  task: ITask,
  onRemoveFilters: (filterKeys: FilterKey[], filterIds?: number[]) => void,
) => {
  if (!task) {
    return null;
  }
  const final_display_name = buildDisplayName(key, task.display_name);

  return (
    <div key={`${key}${task.id}`} className={classes.inlineFilterPill}>
      {final_display_name}
      <span className={classes.xFilterPill} onClick={() => onRemoveFilters([key])}>
        X
      </span>
    </div>
  );
};

const constructTaskStatusPill = (
  classes: Styles,
  key: FilterKey,
  task: ITask,
  onRemoveFilters: (filterKeys: FilterKey[], filterIds?: number[], parentKey?: string) => void,
) => {
  if (!task) {
    return null;
  }
  const final_display_name = buildDisplayName(key, task.display_name);

  return (
    <div key={`${key}${task.id}`} className={classes.inlineFilterPill}>
      {final_display_name}
      <span
        className={classes.xFilterPill}
        onClick={() =>
          onRemoveFilters(
            [key],
            task.id.split(',').map((val: any) => Number(val)),
            'statusIds',
          )
        }
      >
        X
      </span>
    </div>
  );
};

const constructFaTaskPills = (
  classes: Styles,
  key: FilterKey,
  task: ITask,
  onRemoveFilters: (filterKeys: FilterKey[], filterIds?: number[], parentKey?: string) => void,
) => {
  if (!task) {
    return null;
  }
  const final_display_name = buildDisplayName(key, task.display_name);

  return (
    <div key={`${key}${task.id}`} className={classes.inlineFilterPill}>
      {final_display_name}
      <span className={classes.xFilterPill} onClick={() => onRemoveFilters([key], task.id)}>
        X
      </span>
    </div>
  );
};

const constructTaskPillOutreachType = (
  classes: Styles,
  key: FilterKey,
  task: ITask,
  onRemoveFilters: (filterKeys: FilterKey[], filterIds?: any[]) => void,
) => {
  if (!task) {
    return null;
  }
  const final_display_name = buildDisplayName(key, task.display_name);
  return (
    <div key={`${key}${task.id}`} className={classes.inlineFilterPill}>
      {final_display_name}
      <span
        className={classes.xFilterPill}
        onClick={() =>
          onRemoveFilters(
            [key],
            task?.id?.split(',').map((val: any) => Number(val)),
          )
        }
      >
        X
      </span>
    </div>
  );
};

const constructClinicPill = (
  classes: Styles,
  key: FilterKey,
  clinic: IClinic,
  onRemoveFilters: (filterKeys: FilterKey[], filterIds?: number[]) => void,
) => {
  if (!clinic) {
    return null;
  }
  return (
    <div key={`${key}${clinic.id}`} className={classes.inlineFilterPill}>
      {clinic.name}
      <span
        className={classes.xFilterPill}
        onClick={() => onRemoveFilters([key], [Number(clinic.id)])}
      >
        X
      </span>
    </div>
  );
};

const constructDisplayOnlyFCPill = (
  classes: Styles,
  key: FilterKey,
  displayOnlyFC: IDisplayOnlyFC,
  onRemoveFilters: (filterKeys: FilterKey[], filterIds?: any[]) => void,
) => {
  if (!displayOnlyFC) {
    return null;
  }
  return (
    <div key={`${key}${displayOnlyFC.id}`} className={classes.inlineFilterPill}>
      {displayOnlyFC.display_name}
      <span
        className={classes.xFilterPill}
        onClick={() => onRemoveFilters([key], [displayOnlyFC.id])}
      >
        X
      </span>
    </div>
  );
};

const constructDisplayOnlyPill = (
  classes: Styles,
  key: FilterKey,
  displayOnlyOption: IDisplayOnlyDC,
  onRemoveFilters: (filterKeys: FilterKey[], filterIds?: any[]) => void,
) => {
  if (!displayOnlyOption) {
    return null;
  }
  return (
    <div key={`${key}${displayOnlyOption.id}`} className={classes.inlineFilterPill}>
      {displayOnlyOption.display_name}
      <span
        className={classes.xFilterPill}
        onClick={() => onRemoveFilters([key], [displayOnlyOption.id])}
      >
        X
      </span>
    </div>
  );
};

const constructFCProgressReasonPill = (
  classes: Styles,
  key: FilterKey,
  progressReason: IFCProgressReason,
  onRemoveFilters: (filterKeys: FilterKey[], filterIds?: any[]) => void,
) => {
  if (!progressReason) {
    return null;
  }
  return (
    <div key={`${key}${progressReason.value}`} className={classes.inlineFilterPill}>
      FC - Reason: {progressReason.label}
      <span
        className={classes.xFilterPill}
        onClick={() => onRemoveFilters([key], [progressReason.value])}
      >
        X
      </span>
    </div>
  );
};

interface IServiceGroup {
  id: number;
  display_name: string;
}

interface ITask {
  id: any;
  display_name: string;
}

interface IClinic {
  id: number;
  name: string;
}

interface IDisplayOnlyFC {
  id: string;
  display_name: string;
}

interface IDisplayOnlyDC {
  id: string;
  display_name: string;
}

interface IFCProgressReason {
  value: number;
  label: string;
}

function TaskListFilterPills({
  classes,
  filters,
  lookups,
  onRemoveFilters,
  sidebarTaskType,
  taskStatuses,
}: ITaskListFilterPillsProps) {
  let serviceGroups: IServiceGroup[];
  let clinics: IClinic[];
  let displayOnlyFC: IDisplayOnlyFC[];
  let displayOnlyDC: IDisplayOnlyDC[];
  let displayOnlyCSL: IDisplayOnlyDC[];
  let displayOnlyINT: IDisplayOnlyDC[];
  let fcInProgressReason: any[];
  let dispensingStatuses: ITask[] = [
    { id: 1, display_name: 'Undecided' },
    { id: 2, display_name: 'Opt-in' },
    { id: 3, display_name: 'Opt-out' },
  ];
  let administrationStatuses: ITask[] = [
    { id: 'Pre-Therapy', display_name: 'Pre-Therapy' },
    { id: 'On Therapy', display_name: 'On Therapy' },
    { id: 'On Hold', display_name: 'On Hold' },
    { id: 'Discontinued', display_name: 'Discontinued' },
    { id: 'No Go', display_name: 'No Go' },
    { id: 'Migration-Unknown', display_name: 'Migration-Unknown' },
    { id: 'Migration-Fallthrough', display_name: 'Migration-Fallthrough' },
  ];
  let paNewRenewals: ITask[] = [
    { id: 0, display_name: 'New PA' },
    { id: 1, display_name: 'Renewal PA' },
    { id: -1, display_name: 'All PA' },
  ];
  let faNewRenewals: ITask[] = [
    { id: 0, display_name: 'New FA' },
    { id: 1, display_name: 'Renewal FA' },
    { id: -1, display_name: 'All FA' },
  ];
  let cslInitialOngoings: ITask[] = [
    { id: 1, display_name: 'Initial CSL' },
    { id: 2, display_name: 'Ongoing CSL' },
    { id: 3, display_name: 'Check-In CSL' },
    { id: -1, display_name: 'All CSL' },
  ];

  const qreResponsibles: any[] = lookups ? calcIncidentCombinedResponsibles(lookups) : [];
  const arReasons: any[] = lookups
    ? lookups.lookupScheduleOutreachReasons
        .filter((reason: any) => filters.arReasonIds?.includes(reason.id))
        .map((arReason: any) => arReason.name)
    : [];

  let fdcMethodsOfDelivery: ITask[] = methodOfDelivery.map(method => ({
    id: method.value,
    display_name: method.label,
  }));

  let coldChain: ITask[] = taskStatusByNameForFilters(taskStatuses.statuses.fdc || []).map(
    (type: any) => ({
      id: type.related,
      display_name: type.name,
      cold_chain_packing_required: type.cold_chain_packing_required,
    }),
  );

  let urgent: ITask[] = taskStatusByNameForFilters(taskStatuses.statuses.fdc || []).map(
    (type: any) => ({
      id: type.related,
      display_name: type.name,
      urgent: type.urgent,
    }),
  );

  let adherencePackaging: ITask[] = taskStatusByNameForFilters(taskStatuses.statuses.fdc || []).map(
    (type: any) => ({
      id: type.related,
      display_name: type.name,
      adherencePackaging: type.adherence_packaging,
    }),
  );

  let orTypes: ITask[] = outreachTypeByNameForFilters(taskStatuses.outreach_types || []).map(
    (type: any) => ({
      id: type.related,
      display_name: type.name,
    }),
  );

  let arStatuses: any[] = taskStatusByNameForFilters(taskStatuses.statuses.ar || []).map(
    (type: any) => ({
      id: type.related,
      display_name: type.name,
    }),
  );

  let durStatuses: any[] = taskStatusByNameForFilters(taskStatuses.statuses.dur || []).map(
    (type: any) => ({
      id: type.related,
      display_name: type.name,
    }),
  );

  let dcStatuses: any[] = taskStatusByNameForFilters(taskStatuses.statuses.dc || []).map(
    (type: any) => ({
      id: type.related,
      display_name: type.name,
    }),
  );

  let paStatuses: any[] = taskStatusByNameForFilters(taskStatuses.statuses.pa || []).map(
    (type: any) => ({
      id: type.related,
      display_name: type.name,
    }),
  );

  let faStatuses: any[] = taskStatusByNameForFilters(taskStatuses.statuses.fa || []).map(
    (type: any) => ({
      id: type.related,
      display_name: type.name,
    }),
  );

  let cslStatuses: any[] = taskStatusByNameForFilters(taskStatuses.statuses.csl || []).map(
    (type: any) => ({
      id: type.related,
      display_name: type.name,
    }),
  );

  let fcStatuses: any[] = taskStatusByNameForFilters(taskStatuses.statuses.fc || []).map(
    (type: any) => ({
      id: type.related,
      display_name: type.name,
    }),
  );

  let fdcStatuses: any[] = taskStatusByNameForFilters(taskStatuses.statuses.fdc || []).map(
    (type: any) => ({
      id: type.related,
      display_name: type.name,
    }),
  );

  let rsStatuses: any[] = taskStatusByNameForFilters(taskStatuses.statuses.rs || []).map(
    (type: any) => ({
      id: type.related,
      display_name: type.name,
    }),
  );

  let intStatuses: any[] = taskStatusByNameForFilters(taskStatuses.statuses.int || []).map(
    (type: any) => ({
      id: type.related,
      display_name: type.name,
    }),
  );

  let orStatuses: any[] = taskStatusByNameForFilters(taskStatuses.statuses.or || []).map(
    (type: any) => ({
      id: type.related,
      display_name: type.name,
    }),
  );

  let tprStatuses: any[] = taskStatusByNameForFilters(taskStatuses.statuses.tpr || []).map(
    (type: any) => ({
      id: type.related,
      display_name: type.name,
    }),
  );

  let qreStatuses: any[] = taskStatusByNameForFilters(taskStatuses.statuses.qre || []).map(
    (type: any) => ({
      id: type.related,
      display_name: type.name,
    }),
  );

  let orCategories: ITask[] = taskStatuses.outreach_categories
    .filter((cat: any) => !taskStatuses.outreach_categories.includes(cat.id))
    .map((cat: any) => ({
      id: cat.id,
      display_name: cat.name,
    }));

  let incidentCategories: ITask[] = lookups.incidentCategories
    .filter((cat: any) => !lookups.incidentCategories.includes(cat.id))
    .map((cat: any) => ({
      id: cat.id,
      display_name: cat.name,
    }));

  let auditCategories: any[] = auditCategoriesRaw(lookups).map((cat: any) => ({
    id: cat,
    display_name: getAuditCategory(cat),
  }));

  let auditRules: any[] = lookups.auditConstants
    ? lookups.auditConstants
        .filter((cat: any) => !lookups.auditConstants.includes(cat.arbor_audit_area_id))
        .map((cat: any) => ({
          id: cat.arbor_audit_definition_id,
          display_name: cat.audit_item,
        }))
    : [];

  let incidentTypes: ITask[] = incidentTypeByNameForFilters(lookups.incidentTypes).map(
    (type: any) => ({
      id: type.id,
      display_name: type.name,
    }),
  );

  let incidentResponsibleParties: ITask[] = qreResponsibles
    .filter((responsible: any) => !qreResponsibles.includes(responsible.value))
    .map((responsible: any) => ({
      id: responsible.value,
      display_name: responsible.label,
    }));

  let arCommunicationTypes: ITask[] = [
    {
      display_name: 'Phone',
      id: SCHEDULE_OUTREACH_STATUS_COMMUNICATION_PHONE_ID,
    },
    {
      display_name: 'In person',
      id: SCHEDULE_OUTREACH_STATUS_COMMUNICATION_INPERSON_ID,
    },
  ];

  let therapyTypes: any[] = lookups.therapyTypes
    ? lookups.therapyTypes.map((type: any) => ({
        id: type.id,
        display_name: type.type,
      }))
    : [];

  if (!filters.serviceGroups || !filters.serviceGroups.length) {
    serviceGroups = [];
  } else {
    serviceGroups = filters.serviceGroups.map((serviceGroupId: any) => {
      const matchedServiceGroup = lookups.serviceGroups.find(
        (lookupGroup: any) => lookupGroup.id.toString() === serviceGroupId.idValue.toString(),
      );
      return matchedServiceGroup;
    });
  }

  if (!filters.therapyTypes || !filters.therapyTypes.length) {
    therapyTypes = [];
  } else {
    therapyTypes = filters.therapyTypes.map((therapyTypeId: any) => {
      const matchedTherapyType = lookups.therapyTypes.find(
        (lookupGroup: any) => lookupGroup.id.toString() === therapyTypeId.idValue.toString(),
      );
      const retMatchedTherapyType = {
        id: matchedTherapyType.id,

        display_name: matchedTherapyType.type,
      };
      return retMatchedTherapyType;
    });
  }

  if (!filters.clinics || !filters.clinics.length) {
    clinics = [];
  } else {
    clinics = filters.clinics.map((clinicId: any) => {
      const matchedClinic = lookups.customerClinics.find(
        (lookupGroup: any) => lookupGroup.id.toString() === clinicId.idValue.toString(),
      );
      return matchedClinic;
    });
  }

  if (!filters.displayOnlyFC || !filters.displayOnlyFC.length) {
    displayOnlyFC = [];
  } else {
    displayOnlyFC = filters.displayOnlyFC.map((item: string) => {
      const matchedDisplayOnlyFC = lookups.lookupDisplayOnlyFillCoordination?.find(
        (lookupFC: any) => lookupFC.id.toString() === item.toString(),
      );

      return matchedDisplayOnlyFC;
    });
  }

  if (!filters.displayOnlyDC || !filters.displayOnlyDC.length) {
    displayOnlyDC = [];
  } else {
    displayOnlyDC = filters.displayOnlyDC.map((item: string) => {
      const matchedDisplayOnlyDC = lookups.lookupDisplayOnlyDataCollect?.find(
        (lookupFC: any) => lookupFC.id.toString() === item.toString(),
      );

      return matchedDisplayOnlyDC;
    });
  }

  if (!filters.displayOnlyCSL?.length) {
    displayOnlyCSL = [];
  } else {
    displayOnlyCSL = filters.displayOnlyCSL.map((item: string) => {
      const matchedDisplayOnlyCSL = lookups.lookupDisplayOnlyCounseling?.find(
        (lookup: any) => lookup.id.toString() === item.toString(),
      );

      return matchedDisplayOnlyCSL;
    });
  }

  if (!filters.displayOnlyINT?.length) {
    displayOnlyINT = [];
  } else {
    displayOnlyINT = filters.displayOnlyINT.map((item: string) => {
      const matchedDisplayOnlyINT = lookups.lookupDisplayOnlyIntervention?.find(
        (lookup: any) => lookup.id.toString() === item.toString(),
      );

      return matchedDisplayOnlyINT;
    });
  }

  if (!filters.fcInProgressReason || !filters.fcInProgressReason.length) {
    fcInProgressReason = [];
  } else {
    fcInProgressReason = filters.fcInProgressReason.map((value: number) =>
      FCReasons.find(lkp => lkp.value === value),
    );
  }

  if (!filters.paNewRenewal && !Number(filters.paNewRenewal) && filters.paNewRenewal !== 0) {
    paNewRenewals = [];
  } else {
    paNewRenewals = paNewRenewals.filter(
      (newRenewalVal: ITask) => newRenewalVal.id === filters.paNewRenewal,
    );
  }

  if (!filters.faNewRenewal && !Number(filters.faNewRenewal) && filters.faNewRenewal !== 0) {
    faNewRenewals = [];
  } else {
    faNewRenewals = faNewRenewals.filter(
      (newRenewalVal: ITask) => newRenewalVal.id === filters.faNewRenewal,
    );
  }

  if (!filters.cslInitialOngoing || !Number(filters.cslInitialOngoing)) {
    cslInitialOngoings = [];
  } else {
    cslInitialOngoings = cslInitialOngoings.filter(
      (newRenewalVal: ITask) => newRenewalVal.id === filters.cslInitialOngoing,
    );
  }

  if (!filters.dispensingEnrollmentStatusIds || !filters.dispensingEnrollmentStatusIds.length) {
    dispensingStatuses = [];
  } else {
    dispensingStatuses = dispensingStatuses.filter((status: ITask) =>
      filters.dispensingEnrollmentStatusIds.some((val: any) => val === status.id),
    );
  }

  if (!filters.administrationStatusIds || !filters.administrationStatusIds.length) {
    administrationStatuses = [];
  } else {
    administrationStatuses = administrationStatuses.filter((status: ITask) =>
      filters.administrationStatusIds.some((val: any) => val === status.id),
    );
  }

  if (!filters.fdcMethodsOfDelivery || !filters.fdcMethodsOfDelivery.length) {
    fdcMethodsOfDelivery = [];
  } else {
    fdcMethodsOfDelivery = fdcMethodsOfDelivery.filter((newMethodOfDelivery: ITask) =>
      filters.fdcMethodsOfDelivery.some((val: any) => val === newMethodOfDelivery.id),
    );
  }

  if (!filters.coldChain || !filters.coldChain.length) {
    coldChain = [];
  } else {
    coldChain = coldChain.filter((coldChainRequired: any) =>
      filters.coldChain.some((val: any) => {
        val === coldChainRequired.cold_chain_packing_required;
      }),
    );
  }

  if (!filters.urgent || !filters.urgent.length) {
    urgent = [];
  } else {
    urgent = urgent.filter((urgentSelected: any) =>
      filters.urgent.some((val: any) => {
        val === urgentSelected.urgent;
      }),
    );
  }

  if (!filters.adherencePackaging || !filters.adherencePackaging.length) {
    adherencePackaging = [];
  } else {
    adherencePackaging = adherencePackaging.filter((adherencePackagingSelected: any) =>
      filters.adherencePackaging.some((val: any) => {
        val === adherencePackagingSelected.adherence_packaging;
      }),
    );
  }

  if (!filters.orCategory || !filters.orCategory.length) {
    orCategories = [];
  } else {
    orCategories = orCategories.filter((orCategory: ITask) =>
      filters.orCategory.some((val: any) => val === orCategory.id),
    );
  }

  if (!filters.orType || !filters.orType.length) {
    orTypes = [];
  } else {
    orTypes = orTypes.filter(type =>
      type.id
        .split(',')
        .map((type: any) => Number(type))
        .some((val: any) => filters.orType.includes(val)),
    );
  }

  if (!filters.statusIds?.AR || !filters.statusIds.AR.length) {
    arStatuses = [];
  } else {
    arStatuses = arStatuses.filter(type =>
      type.id
        .split(',')
        .map((type: any) => Number(type))
        .some((val: any) => filters.statusIds.AR.includes(val)),
    );
  }
  if (!filters.statusIds?.DUR || !filters.statusIds.DUR.length) {
    durStatuses = [];
  } else {
    durStatuses = durStatuses.filter(type =>
      type.id
        .split(',')
        .map((type: any) => Number(type))
        .some((val: any) => filters.statusIds.DUR.includes(val)),
    );
  }
  if (!filters.statusIds?.DC || !filters.statusIds.DC.length) {
    dcStatuses = [];
  } else {
    dcStatuses = dcStatuses.filter(type =>
      type.id
        .split(',')
        .map((type: any) => Number(type))
        .some((val: any) => filters.statusIds.DC.includes(val)),
    );
  }

  if (!filters.statusIds?.PA || !filters.statusIds.PA.length) {
    paStatuses = [];
  } else {
    paStatuses = paStatuses.filter(type =>
      type.id
        .split(',')
        .map((type: any) => Number(type))
        .some((val: any) => filters.statusIds.PA.includes(val)),
    );
  }

  if (!filters.statusIds?.FA || !filters.statusIds.FA.length) {
    faStatuses = [];
  } else {
    faStatuses = faStatuses.filter(type =>
      type.id
        .split(',')
        .map((type: any) => Number(type))
        .some((val: any) => filters.statusIds.FA.includes(val)),
    );
  }

  if (!filters.statusIds?.CSL || !filters.statusIds.CSL.length) {
    cslStatuses = [];
  } else {
    cslStatuses = cslStatuses.filter(type =>
      type.id
        .split(',')
        .map((type: any) => Number(type))
        .some((val: any) => filters.statusIds.CSL.includes(val)),
    );
  }

  if (!filters.statusIds?.FC || !filters.statusIds.FC.length) {
    fcStatuses = [];
  } else {
    fcStatuses = fcStatuses.filter(type =>
      type.id
        .split(',')
        .map((type: any) => Number(type))
        .some((val: any) => filters.statusIds.FC.includes(val)),
    );
  }

  if (!filters.statusIds?.FDC || !filters.statusIds.FDC.length) {
    fdcStatuses = [];
  } else {
    fdcStatuses = fdcStatuses.filter(type =>
      type.id
        .split(',')
        .map((type: any) => Number(type))
        .some((val: any) => filters.statusIds.FDC.includes(val)),
    );
  }

  if (!filters.statusIds?.RS || !filters.statusIds.RS.length) {
    rsStatuses = [];
  } else {
    rsStatuses = rsStatuses.filter(type =>
      type.id
        .split(',')
        .map((type: any) => Number(type))
        .some((val: any) => filters.statusIds.RS.includes(val)),
    );
  }

  if (!filters.statusIds?.INT || !filters.statusIds.INT.length) {
    intStatuses = [];
  } else {
    intStatuses = intStatuses.filter(type =>
      type.id
        .split(',')
        .map((type: any) => Number(type))
        .some((val: any) => filters.statusIds.INT.includes(val)),
    );
  }

  if (!filters.statusIds?.OR || !filters.statusIds.OR.length) {
    orStatuses = [];
  } else {
    orStatuses = orStatuses.filter(type =>
      type.id
        .split(',')
        .map((type: any) => Number(type))
        .some((val: any) => filters.statusIds.OR.includes(val)),
    );
  }

  if (!filters.statusIds?.TPR || !filters.statusIds.TPR.length) {
    tprStatuses = [];
  } else {
    tprStatuses = tprStatuses.filter(type =>
      type.id
        .split(',')
        .map((type: any) => Number(type))
        .some((val: any) => filters.statusIds.TPR.includes(val)),
    );
  }

  if (!filters.statusIds?.QRE || !filters.statusIds.QRE.length) {
    qreStatuses = [];
  } else {
    qreStatuses = qreStatuses.filter(type =>
      type.id
        .split(',')
        .map((type: any) => Number(type))
        .some((val: any) => filters.statusIds.QRE.includes(val)),
    );
  }

  if (!filters.auditWarningsCategory || !filters.auditWarningsCategory.length) {
    auditCategories = [];
  } else {
    auditCategories = auditCategories.filter((auditCategory: ITask) =>
      filters.auditWarningsCategory.some((val: any) => val === auditCategory.id),
    );
  }

  if (!filters.auditWarningsRule || !filters.auditWarningsRule.length) {
    auditRules = [];
  } else {
    auditRules = auditRules.filter((auditRule: ITask) =>
      filters.auditWarningsRule.some((val: any) => val === auditRule.id),
    );
  }

  if (!filters.incidentCategory || !filters.incidentCategory.length) {
    incidentCategories = [];
  } else {
    incidentCategories = incidentCategories.filter((incidentCategory: ITask) =>
      filters.incidentCategory.some((val: any) => val === incidentCategory.id),
    );
  }

  if (!filters.incidentType || !filters.incidentType.length) {
    incidentTypes = [];
  } else {
    incidentTypes = incidentTypes.filter(type =>
      type.id
        .split(',')
        .map((type: any) => Number(type))
        .some((val: any) => filters.incidentType.includes(val)),
    );
  }
  if (!filters.incidentResponsibleParty || !filters.incidentResponsibleParty.length) {
    incidentResponsibleParties = [];
  } else {
    incidentResponsibleParties = incidentResponsibleParties.filter(responsibleParty =>
      filters.incidentResponsibleParty.includes(responsibleParty.id),
    );
  }
  if (!filters.arCommunicationType || !filters.arCommunicationType.length) {
    arCommunicationTypes = [];
  } else {
    arCommunicationTypes = arCommunicationTypes.filter(type =>
      filters.arCommunicationType.includes(type.id),
    );
  }

  const serviceGroupPills = serviceGroups.map(serviceGroup =>
    queueIgnoresServiceGroupFilter.indexOf(sidebarTaskType) > -1
      ? null
      : constructServiceGroupPill(classes, 'serviceGroups', serviceGroup, onRemoveFilters),
  );

  const assistanceProgramsPills = filters.assistanceProgram?.map((ap: any, index: number) => {
    const displayName = getFinancialTypeFilterValueByKey(ap)?.label || '';
    return constructFaTaskPills(
      classes,
      'assistanceProgram',
      {
        id: ap,
        display_name: displayName,
      },
      onRemoveFilters,
    );
  });

  const arStatusPills = arStatuses.map(status =>
    constructTaskStatusPill(classes, 'AR', status, onRemoveFilters),
  );

  const arExcludeOverduePill = filters.excludeOverdueARs
    ? constructSimpleTextPill(
        classes,
        'excludeOverdueARs',
        {
          id: 'excludeOverdue',
          display_name: 'Exclude Overdue',
        },
        onRemoveFilters,
      )
    : null;

  const arReasonPill = arReasons.length
    ? constructSimpleTextPill(
        classes,
        'arReasonIds',
        {
          id: 'arReason',
          display_name: arReasons.join(', '),
        },
        onRemoveFilters,
      )
    : null;

  const arCommunicationTypePills = arCommunicationTypes.map(type =>
    constructTaskPill(classes, 'arCommunicationType', type, onRemoveFilters),
  );
  const durStatusPills = durStatuses.map(status =>
    constructTaskStatusPill(classes, 'DUR', status, onRemoveFilters),
  );

  const dcStatusPills = dcStatuses.map(status =>
    constructTaskStatusPill(classes, 'DC', status, onRemoveFilters),
  );

  const paStatusPills = paStatuses.map(status =>
    constructTaskStatusPill(classes, 'PA', status, onRemoveFilters),
  );

  const faStatusPills = faStatuses.map(status =>
    constructTaskStatusPill(classes, 'FA', status, onRemoveFilters),
  );

  const cslStatusPills = cslStatuses.map(status =>
    constructTaskStatusPill(classes, 'CSL', status, onRemoveFilters),
  );

  const fcStatusPills = fcStatuses.map(status =>
    constructTaskStatusPill(classes, 'FC', status, onRemoveFilters),
  );

  const fdcStatusPills = fdcStatuses.map(status =>
    constructTaskStatusPill(classes, 'FDC', status, onRemoveFilters),
  );

  const rsStatusPills = rsStatuses.map(status =>
    constructTaskStatusPill(classes, 'RS', status, onRemoveFilters),
  );

  const intStatusPills = intStatuses.map(status =>
    constructTaskStatusPill(classes, 'INT', status, onRemoveFilters),
  );

  const orStatusPills = orStatuses.map(status =>
    constructTaskStatusPill(classes, 'OR', status, onRemoveFilters),
  );

  const tprStatusPills = tprStatuses.map(status =>
    constructTaskStatusPill(classes, 'TPR', status, onRemoveFilters),
  );

  const qreStatusPills = qreStatuses.map(status =>
    constructTaskStatusPill(classes, 'QRE', status, onRemoveFilters),
  );

  const paNewRenewalPills = paNewRenewals.map(paNewRenewal =>
    constructTaskPill(classes, 'paNewRenewal', paNewRenewal, onRemoveFilters),
  );

  const dispensingStatusPills = dispensingStatuses.map(dispensingStatus =>
    constructTaskPill(classes, 'dispensingEnrollmentStatusIds', dispensingStatus, onRemoveFilters),
  );

  const administrationStatusPills = administrationStatuses.map(adminStatus =>
    constructTaskPill(classes, 'administrationStatusIds', adminStatus, onRemoveFilters, true),
  );

  const faNewRenewalPills = faNewRenewals.map(faNewRenewal =>
    constructTaskPill(classes, 'faNewRenewal', faNewRenewal, onRemoveFilters),
  );

  const cslInitialOngoingPills = cslInitialOngoings.map(cslInitialOngoing =>
    constructTaskPill(classes, 'cslInitialOngoing', cslInitialOngoing, onRemoveFilters),
  );

  const fdcMethodOfDeliveryPills = fdcMethodsOfDelivery.map(methodOfDeliveryType =>
    constructTaskPill(classes, 'fdcMethodsOfDelivery', methodOfDeliveryType, onRemoveFilters),
  );

  const coldChainPills = filters.coldChain
    ? constructSimpleTextPill(
        classes,
        'coldChain',
        {
          id: 'coldChain',
          display_name: 'Cold Chain',
        },
        onRemoveFilters,
      )
    : null;

  const urgentPills = filters.urgent
    ? constructSimpleTextPill(
        classes,
        'urgent',
        {
          id: 'urgent',
          display_name: 'Urgent FDC',
        },
        onRemoveFilters,
      )
    : null;

  const adherencePackagingPills = filters.adherencePackaging
    ? constructSimpleTextPill(
        classes,
        'adherencePackaging',
        {
          id: 'adherencePackaging',
          display_name: 'AdPak',
        },
        onRemoveFilters,
      )
    : null;

  const orCategoryPills = orCategories.map(orCategory =>
    constructTaskPill(classes, 'orCategory', orCategory, onRemoveFilters),
  );

  const orTypePills = orTypes.map(orType =>
    constructTaskPillOutreachType(classes, 'orType', orType, onRemoveFilters),
  );

  const incidentCategoryPills = incidentCategories.map(incidentCategory =>
    constructTaskPill(classes, 'incidentCategory', incidentCategory, onRemoveFilters),
  );

  const incidentTypePills = incidentTypes.map(incidentType =>
    constructTaskPillOutreachType(classes, 'incidentType', incidentType, onRemoveFilters),
  );

  const incidentResponsiblePartyPills = incidentResponsibleParties.map(responsibleParty =>
    constructTaskPill(classes, 'incidentResponsibleParty', responsibleParty, onRemoveFilters, true),
  );

  const clinicPills = clinics.map(clinic =>
    queueIgnoresClinicFilter.indexOf(sidebarTaskType) > -1
      ? null
      : constructClinicPill(classes, 'clinics', clinic, onRemoveFilters),
  );

  const displayOnlyFCPills = displayOnlyFC.map(dpOnlyItem =>
    constructDisplayOnlyFCPill(classes, 'displayOnlyFC', dpOnlyItem, onRemoveFilters),
  );

  const displayOnlyDCPills = displayOnlyDC.map(dpOnlyItem =>
    constructDisplayOnlyPill(classes, 'displayOnlyDC', dpOnlyItem, onRemoveFilters),
  );

  const displayOnlyCSLPills = displayOnlyDC.map(dpOnlyItem =>
    constructDisplayOnlyPill(classes, 'displayOnlyCSL', dpOnlyItem, onRemoveFilters),
  );

  const displayOnlyINTPills = displayOnlyDC.map(dpOnlyItem =>
    constructDisplayOnlyPill(classes, 'displayOnlyINT', dpOnlyItem, onRemoveFilters),
  );

  const fcInProgressReasonPills = fcInProgressReason.map(progressReason =>
    constructFCProgressReasonPill(classes, 'fcInProgressReason', progressReason, onRemoveFilters),
  );

  const therapyTypePills = therapyTypes.map(therapyType =>
    queueIgnoresTherapyTypeFilter.indexOf(sidebarTaskType) > -1
      ? null
      : constructTaskPill(classes, 'therapyTypes', therapyType, onRemoveFilters),
  );

  const { fillCycle } = filters;
  const fillCyclePill =
    fillCycle > 1
      ? constructTaskPill(
          classes,
          'fillCycle',
          { id: fillCycle, display_name: fillCycleFilterOptions[fillCycle - 1].label },
          onRemoveFilters,
        )
      : null;

  const { dispensingPharmacies } = filters;
  const areDispensingPharmaciesAvailable =
    Array.isArray(dispensingPharmacies) && dispensingPharmacies.length > 0;
  const dispensingPharmaciesPills = areDispensingPharmaciesAvailable
    ? dispensingPharmacies.map((pharmacy: any) =>
        constructTaskPill(classes, 'dispensingPharmacies', pharmacy, onRemoveFilters),
      )
    : null;

  const taskPillsToDisplay = () => {
    switch (sidebarTaskType) {
      case 'AR':
        return (
          <>
            {arStatusPills}
            {arExcludeOverduePill}
            {arCommunicationTypePills}
            {arReasonPill}
          </>
        );
      case 'DUR':
        return durStatusPills;
      case 'DC':
        return (
          <>
            {dcStatusPills}
            {displayOnlyDCPills}
          </>
        );
      case 'FC':
        return (
          <>
            {fcStatusPills}
            {fillCyclePill}
            {dispensingPharmaciesPills}
            {fcInProgressReasonPills}
            {displayOnlyFCPills}
          </>
        );
      case 'RS':
        return rsStatusPills;
      case 'INT':
        return (
          <>
            {intStatusPills}
            {displayOnlyINTPills}
          </>
        );
      case 'TPR':
        return tprStatusPills;
      case 'PA':
        return (
          <>
            {paNewRenewalPills}
            {paStatusPills}
          </>
        );
      case 'therapies':
        return (
          <>
            {dispensingStatusPills}
            {administrationStatusPills}
          </>
        );
      case 'FA':
        return (
          <>
            {faNewRenewalPills}
            {faStatusPills}
            {assistanceProgramsPills}
          </>
        );
      case 'CSL':
        return (
          <>
            {cslInitialOngoingPills}
            {cslStatusPills}
            {displayOnlyCSLPills}
          </>
        );
      case 'FDC':
        return (
          <>
            {fdcMethodOfDeliveryPills}
            {fdcStatusPills}
            {coldChainPills}
            {urgentPills}
            {adherencePackagingPills}
          </>
        );
      case 'OR':
        return (
          <>
            {orCategoryPills}
            {orTypePills}
            {orStatusPills}
          </>
        );
      case 'QRE':
        return (
          <>
            {incidentCategoryPills}
            {incidentTypePills}
            {incidentResponsiblePartyPills}
            {qreStatusPills}
          </>
        );
      default:
        // eslint-disable-next-line no-useless-return, consistent-return
        return;
    }
  };

  return (
    <div>
      {queueIgnoresDateFilter.indexOf(sidebarTaskType) > -1
        ? null
        : constructDateRangeFilterPill(classes, 'from', 'to', filters, onRemoveFilters)}
      {serviceGroupPills}
      {clinicPills}
      {/*
      // disabling auditFilter Pills as part of ARBOR-7982.
        // These will be re-enabled as part of a followup task
      */}
      {/* {auditCategoryPills} */}
      {/* {auditRulePills} */}
      {therapyTypePills}
      {taskPillsToDisplay()}
    </div>
  );
}

export default compose<ITaskListFilterPillsProps, {}>(
  withStyles(styles as any, { withTheme: true }),
)(TaskListFilterPills);

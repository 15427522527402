import React from 'react';
import { styled } from '@mui/material/styles';
import withTheme from '@mui/styles/withTheme';
import withStyles from '@mui/styles/withStyles';
import { Grid, Divider, Icon } from '@mui/material';
import { Email, Sms } from '@mui/icons-material';
import { styles } from './communication-box-styles';
import { convertToArborDate } from '../../../models/time/arbor-date';
import { FCSSQuickLink } from './fc_ss_quick_link';

const inboundColor = '#9cd1bb';
const outboundTextColor = '#5c88af';
const inboundBgColor = '#f2f9f6';
const outboundBgColor = '#eef4f8';
const FLEX_START = 'flex-start';

const UNKNOWN = 'Unknown';
const FAILED = 'Failed';

const isProduction = ['arbor.trellisrx.com', 'staging-arbor.trellisrx.com'].includes(
  window.location.hostname,
);

const SmsBox = styled(withTheme(Grid))(({ isIncoming, theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.spacing(1.625),
  backgroundColor: isIncoming ? inboundBgColor : outboundBgColor,
  display: 'flex',
  flexDirection: 'column',
  width: theme.spacing(55),
  float: isIncoming ? 'left' : 'right',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1.25),
  borderRadius: theme.spacing(0.5),
}));

const Header = styled(withTheme(Grid))(({ theme }) => ({
  display: 'flex',
  justifyContent: FLEX_START,
  paddingBottom: theme.spacing(0.375),
}));

const receivedStatusComponent = ({ status, classes }) => {
  return <Grid className={classes.receivedInfoText}>{status}</Grid>;
};
const sentStatusComponent = ({ status, deliveryStatus, classes }) => {
  let deliveryStatusStyle = classes.sentDelivered;
  if (deliveryStatus === FAILED) {
    deliveryStatusStyle = classes.sentFailed;
  } else if (deliveryStatus === UNKNOWN) {
    deliveryStatusStyle = classes.sentUnknown;
  }
  return (
    <>
      <Grid className={deliveryStatusStyle}>{deliveryStatus}</Grid>
      <Grid className={classes.deliveryInfoText}>{status}</Grid>
    </>
  );
};
const InboundStatus = withStyles(styles)(receivedStatusComponent);
const OutboundStatus = withStyles(styles)(sentStatusComponent);

const Container = styled(withTheme(Grid))({
  display: 'flex',
  flexDirection: 'column',
});

const Content = styled(withTheme(Grid))(({ theme }) => ({
  display: 'flex',
  justifyContent: FLEX_START,
  textAlign: 'left',
  paddingBottom: theme.spacing(0.75),
  paddingTop: theme.spacing(1),
}));

const Time = styled(withTheme(Grid))(({ theme }) => ({
  display: 'flex',
  justifyContent: FLEX_START,
  fontSize: theme.spacing(1.375),
}));

const IconSvg = styled(withTheme(Icon))(({ isIncoming, displayStatus, theme }) => {
  let outboundColor = outboundTextColor;
  if (displayStatus === UNKNOWN) {
    outboundColor = theme.palette.primary.steelGrey;
  } else if (displayStatus === FAILED) {
    outboundColor = theme.palette.primary.lightRed;
  }
  const iconColor = isIncoming ? inboundColor : outboundColor;

  return {
    color: iconColor,
    paddingRight: theme.spacing(0.375),
    paddingTop: theme.spacing(0.375),
    height: theme.spacing(2.5),
  };
});

const LIST_ICONS = {
  SMS: Sms,
  Email,
};

const LIST_TYPES = {
  SMS: 'SMS',
  Email: 'Email',
};

const LineDivider = styled(withTheme(Divider))(({ theme }) => ({
  marginRight: theme.spacing(1.25),
  height: theme.spacing(0.25),
}));

export default function StyledComponents(props) {
  const {
    communication: {
      is_incoming: isIncoming,
      date,
      type,
      other_contact_type: otherContactType,
      sms_status: smsStatus,
    },
    display_status: displayStatus,
    note_display: noteDisplay,
    note_text: noteText,
    to_number: toNumber,
    patient_full_name: patientFullName,
  } = props;

  const sentTo = otherContactType || patientFullName || 'Patient';
  const receivedStatusText = `${LIST_TYPES[type]} received from ${sentTo}`;
  const sentStatusText = noteDisplay || ` - ${LIST_TYPES[type]} to ${sentTo} ${toNumber}`;

  return (
    <SmsBox isIncoming={isIncoming}>
      <Header>
        <IconSvg
          component={LIST_ICONS[type]}
          isIncoming={isIncoming}
          displayStatus={displayStatus}
        />
        {isIncoming ? (
          <InboundStatus status={receivedStatusText} />
        ) : (
          <OutboundStatus deliveryStatus={displayStatus} status={sentStatusText} />
        )}
      </Header>
      <LineDivider />
      <Container>
        <Content data-qa-id="note-text">{noteText}</Content>
        {!isProduction ? <FCSSQuickLink noteText={noteText} /> : null}
        <Time>
          {smsStatus && `SMS ${smsStatus.toUpperCase()} - `}
          {convertToArborDate(date).getCustomerDatetime(true, 'HH:mm')}
        </Time>
      </Container>
    </SmsBox>
  );
}

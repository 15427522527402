import React, { useEffect, useState } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { IState } from 'interfaces/redux/IState';
import { useSelector } from 'react-redux';
import { PatientMergeClient } from 'clients/patient-merge';
import { ContactDemographicInfo } from 'components/patient-merge-modal/info-component/contact-demographic-info';
import { PreferencesDemographicInfo } from 'components/patient-merge-modal/info-component/preferences-demographic-info';
import { useTheme } from '@mui/styles';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import {
  IContact,
  IPatientMergeSectionDetailsProps,
  IProfileDemographicInfo,
} from 'components/patient-merge-modal/interfaces';
import { LookupResourceTypes } from 'constants/enums';
import { EmailDemographicInfo } from 'components/patient-merge-modal/info-component/email-demographic-info';
import { AddressDemographicInfo } from 'components/patient-merge-modal/info-component/address-demographic-info';
import { PhoneDemographicInfo } from 'components/patient-merge-modal/info-component/phone-demographic-info';
import { ContactInformationHeader } from 'components/patient-merge-modal/info-component/contact-information-header';
import { buildAddressLine } from 'components/patient-merge-modal/utils';

export const ConfirmDemographicSection = (props: IPatientMergeSectionDetailsProps) => {
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);
  const { mergeId, expanded } = props;

  const [infos, setInfos] = useState<IProfileDemographicInfo[]>([]);
  const [loaded, setLoaded] = useState(false);

  const [primaryData, setPrimaryData] = useState<IProfileDemographicInfo>();
  const [selectedData, setSelectedData] = useState<IProfileDemographicInfo>();
  const [additionalContacts, setAdditionalContacts] = useState<IContact[]>([]);
  const [additionalInfo, setAdditionalInfo] = useState<IProfileDemographicInfo>();

  const inputs = useSelector((state: IState) => state.patientMerge.inputs);
  const primary = useSelector((state: IState) => state.patientMerge.primary);
  const selectedProfileId = useSelector((state: IState) => state.patientMerge.duplicate);

  const getMergeDemographicInfo = async (): Promise<IProfileDemographicInfo[]> => {
    const response = await PatientMergeClient.getMergeDemographicInfo(mergeId);
    return response.data;
  };

  useEffect(() => {
    if (!loaded && expanded) {
      getMergeDemographicInfo().then(data => {
        setInfos(data);
        setLoaded(true);
      });
    }
  }, [mergeId, expanded]);

  useEffect(() => {
    let additionalContactsTmp: IContact[] = [];
    const contactIds = inputs
      .filter(input => input.type === LookupResourceTypes.PatientContact)
      .map(input => input.selectedId);
    if (selectedData?.contacts?.length) {
      additionalContactsTmp = selectedData.contacts.filter((contact: IContact) =>
        contactIds.includes(contact.contactId),
      );
    }

    if (selectedData) {
      // patient info
      const inputEmails = inputs
        .filter(input => input.type === LookupResourceTypes.PatientEmail)
        .map(input => input.extraInfo);
      const inputPhones = inputs
        .filter(input => input.type === LookupResourceTypes.PatientPhone)
        .map(input => input.extraInfo);
      const inputAddresses = inputs
        .filter(input => input.type === LookupResourceTypes.PatientAddress)
        .map(input => input.extraInfo);

      const additionalInfoTmp = {
        patientId: selectedData.patientId,
        patient: selectedData.patient,
        contacts: selectedData.contacts,
        preference: selectedData.preference,
      };

      additionalInfoTmp.patient.emails =
        selectedData.patient.emails?.filter(item => inputEmails.includes(item.value)) || [];

      additionalInfoTmp.patient.phones =
        selectedData.patient.phones?.filter(item => inputPhones.includes(item.value)) || [];

      additionalInfoTmp.patient.addresses =
        selectedData.patient.addresses?.filter(item =>
          inputAddresses.includes(buildAddressLine(item)),
        ) || [];

      setAdditionalInfo(additionalInfoTmp);

      // contacts info
      additionalContactsTmp.map(additionalContact => {
        const inputContactEmails = inputs
          .filter(
            input =>
              input.type === LookupResourceTypes.PatientContactEmail &&
              input.selectedId === additionalContact.contactId,
          )
          .map(input => input.extraInfo);

        const inputContactPhones = inputs
          .filter(
            input =>
              input.type === LookupResourceTypes.PatientContactPhone &&
              input.selectedId === additionalContact.contactId,
          )
          .map(input => input.extraInfo);

        const inputContactAddresses = inputs
          .filter(
            input =>
              input.type === LookupResourceTypes.PatientContactAddress &&
              input.selectedId === additionalContact.contactId,
          )
          .map(input => input.extraInfo);

        additionalContact.emails =
          additionalContact.emails?.filter(item => inputContactEmails.includes(item.value)) || [];
        additionalContact.phones =
          additionalContact.phones?.filter(item => inputContactEmails.includes(item.value)) || [];
        additionalContact.addresses =
          additionalContact.addresses?.filter(item =>
            inputContactEmails.includes(buildAddressLine(item)),
          ) || [];

        return additionalContact;
      });

      setAdditionalContacts(additionalContactsTmp);
    }
  }, [inputs, selectedData]);

  useEffect(() => {
    setPrimaryData(infos.find(info => info.patientId === primary));
  }, [primary, infos]);

  useEffect(() => {
    setSelectedData(infos.find(info => info.patientId === selectedProfileId));
  }, [selectedProfileId, infos]);

  const hasPatientInformationEmails = !!additionalInfo?.patient?.emails?.length;
  const hasPatientInformationAddresses = !!additionalInfo?.patient?.addresses?.length;
  const hasPatientInformationPhones = !!additionalInfo?.patient?.phones?.length;
  const hasPatientInformation =
    hasPatientInformationEmails || hasPatientInformationAddresses || hasPatientInformationPhones;

  return (
    <Grid container spacing={3} paddingLeft={2}>
      {/* Patient Info */}
      {hasPatientInformation && (
        <>
          <Grid item xs={12}>
            <Typography sx={classes.sectionInputTitle}>Patient Information</Typography>
          </Grid>
          <Grid container item xs={12}>
            {hasPatientInformationAddresses && (
              <>
                {additionalInfo?.patient.addresses?.map((address, idx) => (
                  <Grid item xs={12}>
                    <AddressDemographicInfo
                      key={`primary-${additionalInfo.patient.id}-address-${idx}`}
                      address={address}
                    />
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Divider sx={classes.divider} light />
                </Grid>
              </>
            )}
            {hasPatientInformationEmails && (
              <>
                {additionalInfo?.patient.emails.map((email, idx) => (
                  <Grid item xs={12}>
                    <EmailDemographicInfo
                      key={`primary-${additionalInfo.patient.id}-email-${idx}`}
                      email={email}
                    />
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Divider sx={classes.divider} light />
                </Grid>
              </>
            )}
            {hasPatientInformationPhones &&
              additionalInfo?.patient.phones.map((phone, idx) => (
                <Grid item xs={12}>
                  <PhoneDemographicInfo
                    key={`primary-${additionalInfo.patient.id}-phone-${idx}`}
                    phone={phone}
                  />
                </Grid>
              ))}
          </Grid>
          <Grid item xs={12}>
            <Divider sx={classes.divider} light />
          </Grid>
        </>
      )}
      {/* /Patient Info */}

      {/* Contact */}
      <Grid item xs={12}>
        <Typography sx={classes.sectionInputTitle}>Contact</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          {primaryData?.contacts?.map(contact => (
            <>
              <ContactInformationHeader contact={contact} key={`confirm-${contact.contactId}`} />
              <Grid container item xs={12}>
                {hasPatientInformationAddresses && (
                  <>
                    {additionalInfo?.patient.addresses?.map((address, idx) => (
                      <Grid item xs={12}>
                        <AddressDemographicInfo
                          key={`primary-${additionalInfo.patient.id}-address-${idx}`}
                          address={address}
                        />
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <Divider sx={classes.divider} light />
                    </Grid>
                  </>
                )}
                {hasPatientInformationEmails && (
                  <>
                    {additionalInfo?.patient.emails.map((email, idx) => (
                      <Grid item xs={12}>
                        <EmailDemographicInfo
                          key={`primary-${additionalInfo.patient.id}-email-${idx}`}
                          email={email}
                        />
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <Divider sx={classes.divider} light />
                    </Grid>
                  </>
                )}
                {hasPatientInformationPhones &&
                  additionalInfo?.patient.phones.map((phone, idx) => (
                    <Grid item xs={12}>
                      <PhoneDemographicInfo
                        key={`primary-${additionalInfo.patient.id}-phone-${idx}`}
                        phone={phone}
                      />
                    </Grid>
                  ))}
              </Grid>
            </>
          ))}
          {additionalContacts.map(contact => (
            <ContactDemographicInfo
              keyPrefix="confirm"
              key={`confirm-${contact.contactId}`}
              contact={contact}
            />
          ))}
        </Grid>
      </Grid>
      {/* /Contact */}

      <Grid item xs={12}>
        <Divider sx={classes.divider} light />
      </Grid>
      {/* Com & Delivery Preference */}
      <Grid item xs={12}>
        <Typography sx={classes.sectionInputTitle}>
          Communication &amp; Rx delivery preference
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          {!inputs.some(input => input.type === LookupResourceTypes.PatientPreference) &&
            primaryData?.preference && (
              <PreferencesDemographicInfo
                preference={primaryData.preference}
                patient={primaryData.patient}
                contacts={primaryData.contacts}
              />
            )}
          {inputs.some(input => input.type === LookupResourceTypes.PatientPreference) &&
            selectedData?.preference && (
              <PreferencesDemographicInfo
                preference={selectedData.preference}
                patient={selectedData.patient}
                contacts={selectedData.contacts}
              />
            )}
        </Grid>
      </Grid>
      {/* /Com & Delivery Preference */}
    </Grid>
  );
};

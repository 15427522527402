import { AxiosPromise } from 'axios';
import { TypedHttp } from 'services/typed-http';
import { SmsStatus } from 'interfaces/redux/IPatient';

import { IUpdateSmsStatusRequest } from 'models/sms/IUpdateSmsStatusRequest';
import { IUpdateSmsStatusResponse } from 'models/sms/IUpdateSmsStatusResponse';

const buildUri = (smsId: number) => `/sms/${smsId}`;

export class SmsClient {
  static updateSmsStatus(id: number, status: SmsStatus): AxiosPromise<IUpdateSmsStatusResponse> {
    const url = `${buildUri(id)}/update_sms_read_status`;
    return TypedHttp.PatchPromise<IUpdateSmsStatusRequest, IUpdateSmsStatusResponse>(url, {
      status: status,
    });
  }
}

import React from 'react';
import { Popover, Typography, DialogContent, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import JsonForm from 'components/dynamic-form/json-form';
import colors from '../../lib/themes/colors.module.scss';

const useStyles = makeStyles(theme => ({
  popover: {
    width: 800,
  },
  title: {
    borderBottom: '1px solid',
    borderColor: colors.grey12,
    paddingBottom: theme.spacing(2),
    margin: theme.spacing(2, 2, 0, 2),
  },
}));

const PopupForm = ({
  open,
  onSubmit,
  onCancel,
  title,
  instructions,
  anchorEl,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'center',
  },
  width,
  ...other
}) => {
  const classes = useStyles();
  return (
    <Popover
      id="form-popover"
      open={open}
      anchorEl={anchorEl}
      onClose={onCancel}
      onClick={e => {
        e.stopPropagation();
      }}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      classes={{ paper: classes.popover }}
    >
      <Box>
        {title && (
          <Typography className={classes.title} variant="subtitle1">
            {title}
          </Typography>
        )}
        <DialogContent>
          {typeof instructions === 'string' ? (
            <Typography variant="body">{instructions}</Typography>
          ) : (
            instructions
          )}
          <JsonForm onSubmit={onSubmit} onCancel={onCancel} {...other} />
        </DialogContent>
      </Box>
    </Popover>
  );
};

export default PopupForm;

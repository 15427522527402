import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

const styles = (theme: Theme) => {
  const { palette, font } = expandTheme(theme);

  return createStyles({
    panel: {
      marginLeft: 30,
      marginRight: 30,
    },
    editPanel: {
      margin: 30,
      marginTop: 0,
      paddingTop: 12,
      paddingRight: 32,
      paddingLeft: 24,
    },
    therapyFilterMedsButton: {
      marginRight: theme.spacing(0.5),
    },
    summaryContainer: {
      'width': '100%',
      '&:last-child': {
        paddingRight: 0,
      },
    },
    button: {
      margin: theme.spacing(1),
      marginLeft: 0,
    },
    summary: {
      backgroundColor: palette.primary.summaryPanel,
      paddingRight: 0,
    },
    detailContainer: {
      display: 'block',
      width: '100%',
    },
    drugName: {
      fontSize: font.largeFontSize,
    },
    note: {
      fontSize: font.smallFontSize,
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: 5,
      padding: 5,
      marginBottom: 2,
      overflow: 'hidden',
      maxHeight: 200,
      position: 'relative',
    },
    communication: {
      width: '90%',
    },
    paDue: {
      fontSize: font.smallFontSize,
      marginLeft: 10,
      textTransform: 'capitalize',
    },
    formControl: {
      minWidth: 120,
    },
    ndc: {
      margin: 8,
    },
    Modal: {
      position: 'absolute',
      width: theme.spacing(150),
      backgroundColor: palette.primary.background,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
    },
    StatusContainer: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: palette.primary.main,
      borderRadius: 5,
      marginTop: 1,
      marginBottom: 1,
      padding: theme.spacing(1),
    },
    therapySummary: {
      padding: 5,
    },
    therapyDetails: {
      padding: 0,
      flexDirection: 'column',
    },
    oustandingTaskLabel: {
      fontSize: font.formLabelSize,
      fontWeight: font.weight.regular,
      color: palette.primary.formLabel,
      paddingBottom: theme.spacing(2),
    },
    expandIconCollapsed: {
      position: 'absolute',
      left: 10,
      top: 24,
    },
    expandIconExpanded: {
      position: 'absolute',
      left: 10,
      top: 45,
    },
    focused: {
      'backgroundColor': palette.primary.background,
      '&:focus': {
        backgroundColor: palette.primary.background,
      },
    },
    communicationIconContainer: {
      position: 'relative',
      top: 3,
      marginRight: 5,
    },
    loadContainer: {
      position: 'relative',
      minHeight: 200,
    },
    therapyFilterChecklist: {
      width: 200 * 3,
    },
    checklistLeft: {
      padding: theme.spacing(1),
      borderRight: 1,
      borderTop: 1,
      borderBottom: 0,
      borderLeft: 0,
      borderStyle: 'solid',
      borderColor: palette.primary.task.borderColor,
    },
    checklistRight: {
      padding: theme.spacing(1),
      borderRight: 0,
      borderTop: 1,
      borderBottom: 0,
      borderLeft: 0,
      borderStyle: 'solid',
      borderColor: palette.primary.task.borderColor,
    },
    filterText: {
      padding: theme.spacing(1),
    },
    filterContainer: {
      paddingRight: 25,
    },
    filterTitle: {
      paddingLeft: 20,
    },
    filterButton: {
      position: 'fixed',
      top: 210,
      zIndex: 2,
    },
    divider: {
      marginLeft: -5,
      marginRight: -5,
      marginTop: 5,
      marginBottom: 5,
    },
    dividerStrech: {
      marginBottom: 0,
    },
    dividerRight: {
      borderRight: '0.1em solid rgba(0, 0, 0, 0.12)',
    },
    actionContainer: {
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
    actionIconContainer: {
      textAlign: 'right',
    },
    actionFormContainer: {
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    statusTitle: {
      lineHeight: 2.5,
      fontWeight: 'bold',
      marginBottom: 5,
      fontSize: font.mediumFontSize,
      color: palette.primary.formLabel,
    },
    progressBarContainer: {
      marginTop: 15,
      marginLeft: 25,
      textAlign: 'right',
    },
    npiLookupButtonContainer: {
      position: 'relative',
    },
    npiLookupButton: {
      position: 'absolute',
      right: 4,
      bottom: 22,
    },
    npiDialogClose: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: palette.grey[500],
    },
    therapyStatus: {
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    therapyStatusTitle: {
      margin: 0,
      color: palette.primary.warning,
    },
    therapyStatusTitle1: {
      color: palette.primary.success,
    },
    therapyStatusTitle2: {
      color: palette.primary.success,
    },
    therapyStatusTitle3: {
      color: palette.primary.warning,
    },
    therapyStatusTitle4: {
      color: palette.primary.warning,
    },
    therapyStatusTitle5: {
      color: palette.error.warning,
    },
    therapyStatusTitle6: {
      color: palette.primary.warning,
    },
    therapyStatusReason: {
      margin: 0,
    },
    therapyStatusIcon: {
      marginRight: theme.spacing(1),
      lineHeight: '36px',
      position: 'relative',
      width: 48,
      height: 32,
    },
    therapyStatusIconBorder: {
      marginLeft: theme.spacing(-1),
      marginRight: theme.spacing(1),
      lineHeight: '36px',
      position: 'relative',
      width: 48,
      height: 32,
      borderRight: '1px solid rgba(0, 0, 0, 0.23)',
    },
    therapyStatusSmall: {
      width: 16,
      height: 16,
      position: 'absolute',
      right: 0,
      bottom: 0,
    },
    therapyStatusText: {
      position: 'absolute',
      bottom: 0,
      lineHeight: '1em',
      right: 5,
    },
    toggleEdit: {
      minWidth: 10,
    },
    iconContainer: {},
    textContainer: {},
    imageContainer: {},
    headerContainer: {
      // backgroundColor: palette.primary.bluesky,
      padding: 15,
      borderRadius: 5,
    },
    headerContainerArchived: {
      backgroundColor: palette.primary.summaryPanel,
    },
    therapySummaryContent: {
      margin: 0,
    },
    dataContainer: {
      padding: theme.spacing(1),
      paddingLeft: 35,
    },
    newTaskBtn: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    newTaskBtnLabel: {
      marginLeft: 10,
    },
    archivedToggle: {
      backgroundColor: palette.primary.summaryPanel,
    },
    archivedToggleInner: {
      padding: '0 !important',
      fontWeight: 'bold',
    },
    editTherapyContainer: {
      padding: theme.spacing(2),
    },
    archivedTitle: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      color: palette.primary.formLabel,
    },
    archivedIconTitle: {
      paddingRight: 10,
    },
    minigridRow: {
      '&:hover': {
        backgroundColor: palette.primary.task.focusColor,
      },
      '&:focus': {
        backgroundColor: palette.primary.task.focusColor,
      },
    },
    therapyDetailField: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    administrationField: {
      paddingRight: theme.spacing(1),
    },
    enrollmentTitle: {
      fontSize: font.mediumFontSize,
      color: palette.primary.formLabel,
    },
    enrollmentDetail: {
      fontSize: font.smallFontSize,
      color: palette.primary.formLabel,
    },
    administrationTitle: {
      fontSize: font.mediumFontSize,
    },
    focusedBackground: {
      '&.Mui-focused': {
        backgroundColor: palette.primary.background,
      },
    },
    taskIconSmall: {
      'border': '1px',
      'borderStyle': 'solid',
      'borderColor': palette.primary.task.borderColor,
      'backgroundColor': palette.primary.task.backgroundColor,
      'borderRadius': 5,
      'fontSize': font.mediumFontSize,
      'fontWeight': 'bold',
      'color': palette.primary.white,
      'marginRight': 5,
      'width': 40,
      'minWidth': 40,
      'height': 36,
      'minHeight': 36,
      'alignItems': 'center',
      'justifyContent': 'center',
      '&:hover': {
        backgroundColor: palette.primary.task.backgroundColor,
      },
    },
    taskNameSmall: {},
    therapyFilter: {
      backgroundColor: palette.primary.white,
      color: palette.text.primary,
      zIndex: 1,
      fontSize: font.smallFontSize,
      marginLeft: 30,
      paddingBottom: 5,
    },
    therapyFilterMedicationList: {
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 10,
    },
    therapyFilterDurWrapper: {
      paddingTop: theme.spacing(2),
    },
    therapyFilterMultiselect: {
      fontSize: font.smallFontSize,
      minWidth: '100px',
      marginLeft: 8,
      paddingLeft: 5,
      marginRight: 3,
    },
    therapyFilterMultiselectItem: {
      fontSize: font.smallFontSize,
    },
    medicationCount: {
      textDecoration: 'underline',
      paddingTop: 8,
      cursor: 'pointer',
      marginLeft: 10,
      color: palette.navigation.main,
    },
    therapyFilterButton: {
      float: 'right',
    },
    therapyFilterButtonsContainer: {
      paddingRight: 60,
      paddingLeft: 40,
      display: 'inline-flex',
      flexDirection: 'row-reverse',
    },
    therapyFilterAncillaryMedsButton: {
      float: 'left',
      marginRight: 3,
    },
    therapyFilterButtonText: {
      paddingRight: 5,
      paddingTop: 2,
    },
    therapyFilterContainerPadding: {
      paddingBottom: 15,
    },
    infoIcon: {
      verticalAlign: 'middle',
      color: palette.primary.valenciaRed,
    },
    serviceEnrollmentLabel: {
      fontWeight: 'bold',
    },
    dispenseHistoryError: {
      color: theme.palette.error.main,
      margin: '10px 0',
      fontSize: font.mediumFontSize,
    },
    dispenseHistoryContainer: {
      maxHeight: theme.spacing(30),
      overflowY: 'auto',
      backgroundColor: palette.primary.grey2,
      marginBottom: theme.spacing(3),
    },
    dispenseHistoryDetails: {
      padding: theme.spacing(1),
    },
    dosisRegimenField: {
      marginLeft: 20,
      marginTop: 5,
    },
    optOutMessage: {
      fontSize: font.mediumFontSize,
      color: palette.primary.valenciaRed,
      display: 'inline',
    },
    clinicalStatusIconDivider: {
      borderLeft: '1px solid rgba(0, 0, 0, 0.23)',
    },
    clinicalOptInPrompt: {
      backgroundColor: palette.primary.bluesky,
      fontSize: font.mediumFontSize,
    },
    daySupplyConflict: {
      '& p, input, div': {
        color: palette.primary.darkRed,
        borderColor: palette.primary.darkRed,
      },
    },
    daysSupplyInfo: {
      float: 'left',
      position: 'relative',
      top: theme.spacing(-8),
      paddingLeft: theme.spacing(12),
      cursor: 'pointer',
      color: palette.primary.rollingStone,
    },
    medSyncIconPadding: {
      paddingTop: theme.spacing(0.7),
    },
    prnIcon: {
      width: '30px',
      paddingLeft: theme.spacing(1),
      marginBottom: theme.spacing(-0.5),
    },
    dispensingPharmacyInfoIcon: {
      color: palette.primary.cuttySark,
      fontSize: 20,
    },
    icdValidator: {
      '& div, span': {
        color: palette.primary.valenciaRed,
      },
    },
    icdValidatorIcon: {
      float: 'left',
      position: 'relative',
      top: theme.spacing(-5),
      paddingLeft: theme.spacing(16),
      cursor: 'pointer',
      color: palette.primary.valenciaRed,
    },
  });
};

export { styles };

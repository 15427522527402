import React, { PropsWithChildren } from 'react';
import { Button, ClickAwayListener, Menu, MenuItem, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { IStatusSelectorOption } from 'interfaces/IStatusSelectorOption';
import { TaskStatusUtil } from 'utils/task-status-util';
import { AllTaskTypesStatuses } from 'interfaces/enums/TaskStatuses';
import { Error as ErrorIcon } from '@mui/icons-material';
import { IProps } from './interfaces/IProps';
import { buildQaId } from '../../utils/build-qa-id';
import { styles } from './radio-status-selector.styles';
import PillButton from '../pill-button/pill-button';
import FieldLabel from '../field-label/field-label';

const qaIdBuilder = buildQaId('radio-status-selector');
const qaIdOptionBuilder = buildQaId('option');

const RadioStatusSelector = (props: PropsWithChildren<IProps>): JSX.Element => {
  const { classes, errorMessage } = props;
  const [visibleOptions, setVisibleOptions] = React.useState<IStatusSelectorOption[]>([]);
  const [hiddenOptions, setHiddenOptions] = React.useState<IStatusSelectorOption[]>([]);
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const [anchorElement, setAnchorElement] = React.useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const menuOptionClick = (option: IStatusSelectorOption) => {
    menuClose();
    option.onClick(option.value);
  };

  const menuClose = () => {
    setMenuOpen(false);
    setAnchorElement(null);
  };

  const menuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorElement(event.currentTarget);
    setMenuOpen(true);
  };

  const canTransition = React.useMemo(() => {
    if (
      props.options.filter(x => !x.hidden).length === 1 &&
      props.options.filter(x => x.hidden).length === 0
    ) {
      // this is an edge case where there is only 1 option.
      // this will allow the option to show up enabled in the UI for better visibility
      return () => true;
    }
    return TaskStatusUtil.buildCanTransitionFromPermissions(
      props.currentUser?.permissions,
      props.resourceStates,
    );
  }, [props.currentUser?.permissions, props.resourceStates]);

  React.useEffect(() => {
    setVisibleOptions(props.options.filter(x => !x.hidden));
    setHiddenOptions(props.options.filter(x => x.hidden));
  }, [props.options]);

  return (
    <>
      <div
        className={props.displayInline ? classes.radioGroupInlineWrapper : undefined}
        data-qa-id={qaIdBuilder(`container-${props.label}`)}
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <FieldLabel fieldName={props.label} />
        <div className={props.displayInline ? classes.radioGroupInline : classes.radioGroup}>
          {visibleOptions?.length &&
            visibleOptions.map(option => {
              const shouldBeDisabled = props.task
                ? !canTransition(props.task, option.value as AllTaskTypesStatuses)
                : false;
              const isTaskStatusDisabled = props.disableStatuses?.includes(option.label ?? '');

              return (
                <PillButton
                  label={option.label}
                  value={option.value}
                  onClick={option.onClick}
                  selected={option.selected}
                  key={`pill-button-${option.label}`}
                  disabled={shouldBeDisabled || isTaskStatusDisabled}
                />
              );
            })}
          {hiddenOptions?.length ? (
            <>
              <Button
                onClick={event => menuClick(event)}
                disabled={props.disableMoreOption}
                data-qa-id="status-more-button"
              >
                More
              </Button>
              <ClickAwayListener onClickAway={() => menuClose()} mouseEvent="onMouseDown">
                <Menu
                  keepMounted
                  open={menuOpen}
                  anchorEl={anchorElement}
                  onClose={() => menuClose()}
                  data-qa-id={qaIdBuilder(`menu-${props.label}`)}
                >
                  {hiddenOptions.map(option => {
                    const qdId = qaIdOptionBuilder(`${option.label}`);
                    const shouldBeDisabled = props.task
                      ? !canTransition(props.task, option.value as AllTaskTypesStatuses)
                      : false;
                    const isTaskStatusDisabled = props.disableStatuses?.includes(
                      option.label ?? '',
                    );

                    return (
                      <MenuItem
                        onClick={() => menuOptionClick(option)}
                        data-qa-id={qdId}
                        key={qdId}
                        disabled={shouldBeDisabled || isTaskStatusDisabled}
                      >
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </ClickAwayListener>
            </>
          ) : null}
        </div>
        {errorMessage ? (
          <div style={{ display: 'flex', marginLeft: 'auto', flexFlow: 'row nowrap' }}>
            <Typography variant="body2" color="error" sx={{ fontWeight: 500 }}>
              {errorMessage}
            </Typography>
            <ErrorIcon className={classes.errorIcon} />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default withStyles(styles)(RadioStatusSelector);

import HTTP from 'services/http';
import React from 'react';
import { AxiosPromise } from 'axios';
import { BaseTable } from 'containers/application-manager/base-table/base-table';
import { Button, CircularProgress, Grid, Modal, Typography } from '@mui/material';
import { getModalStyle } from 'services/utils/styles-service';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { useDispatch } from 'react-redux';
import { UserPermissions } from 'interfaces/user/Permissions';
import { UserUtils } from 'utils/user-utils';
import { ArborCheckbox } from 'components/arbor-checkbox/arbor-checkbox';
import { UseStyles } from './new-table.styles';
import { IModalItem, IModalItemChecked } from '../../types';
import { IChild, INewAPIResponse, IResult } from './types';
import { convertToFlatModalItems, toggleModalChecked } from '../../utils';
import { ButtonActiveWhen } from '../../../base-table/types';

enum SubmitMode {
  Include,
  Exclude,
}

interface INewTable2Props {
  searchTerm: string | undefined;
  setCount: (count: number) => void;
}

interface IModalState {
  open: boolean;
  mode?: SubmitMode;
  heading?: string;
  subHeading?: string;
  items?: IModalItem[];
  submitting: boolean;
}

const baseUrl = '/application-manager/gtd/new';

const defaultIncludeState: Pick<IModalState, 'heading' | 'mode'> = {
  heading: 'You are including to the GTD Global:',
  mode: SubmitMode.Include,
};

const defaultExcludeState: Pick<IModalState, 'heading' | 'subHeading' | 'mode'> = {
  heading: 'You are excluding to the GTD Global:',
  subHeading: 'They will be stored in the inactive medications table',
  mode: SubmitMode.Exclude,
};

const initialModalState: IModalState = {
  open: false,
  submitting: false,
};

const childPkSelector = (child: IChild): number => child.drugDescriptorId;
const parentPkSelector = (parent: IResult): number => parent.drugDescriptorId;

const convertToCheckedItems = (parents: IResult[]): IModalItem[] => {
  const labelFormatter = (child: IChild) => `${child.drug_name} ${child.strength}`;
  const modalItems = convertToFlatModalItems<IResult, IChild>(
    parents,
    parentPkSelector,
    childPkSelector,
    labelFormatter,
  );
  return modalItems;
};

const submitItemsRequest = (items: IModalItem[], mode: SubmitMode): AxiosPromise<unknown> => {
  const requestBody = {
    active: mode === SubmitMode.Include ? 1 : 0,
    records: items.map(x => ({
      parentDrugDescriptorId: x.parentPk,
      childDrugDescriptorId: x.childPk,
    })),
  };
  return HTTP.post(baseUrl, requestBody);
};

export const NewTable = (props: INewTable2Props): JSX.Element => {
  const classes: any = UseStyles();
  const dispatch = useDispatch();

  // #region useEffects
  const [modalState, setModalState] = React.useState<IModalState>(initialModalState);
  const [reloadDataTrigger, setReloadDataTrigger] = React.useState<Date | undefined>(undefined);
  const [modalItemCheckedTrigger, setModalItemCheckedTrigger] = React.useState<
    IModalItemChecked | undefined
  >(undefined);
  // #endregion

  // #region helper functions
  const onSubmit = async () => {
    setModalState(prevState => {
      return {
        ...prevState,
        submitting: true,
      };
    });

    if (modalState.mode != null) {
      try {
        const items = modalState.items?.filter(x => x.checked) || [];
        const request = submitItemsRequest(items, modalState.mode);

        await request;
        dispatch(notifySuccess('Saved'));
        setModalState(initialModalState);
        setReloadDataTrigger(new Date());
      } catch (_error) {
        dispatch(notifyError('An error occurred'));
      } finally {
        setModalState(prevState => {
          return {
            ...prevState,
            submitting: false,
          };
        });
      }
    }
  };

  const toggleCheck = (checked: boolean, item: IModalItem) => {
    toggleModalChecked(checked, item, modalState, setModalState, setModalItemCheckedTrigger);
  };

  // #endregion

  // #region renders
  const renderModal = (): JSX.Element => {
    return (
      <Modal open={modalState.open}>
        <div style={getModalStyle()} className={classes.confirmationModal}>
          <Grid container direction="column" spacing={2}>
            {/* Header */}
            <Grid item xs="auto">
              <Typography className={classes.confirmationModalHeader}>
                {modalState.heading}
              </Typography>
              {modalState.subHeading != null ? (
                <Typography className={classes.confirmationModalSubHeading}>
                  {modalState.subHeading}
                </Typography>
              ) : null}
            </Grid>
            {/* Selectable Items */}
            <Grid item xs="auto" className={classes.confirmationModalDrugItem}>
              {modalState.items?.map(item => {
                return (
                  <Typography className={classes.modalDrugRow}>
                    <ArborCheckbox
                      className={classes.modalCheckbox}
                      checked={item.checked}
                      onChange={event => {
                        toggleCheck(event.target.checked, item);
                      }}
                    />
                    {`${item.label}`}
                  </Typography>
                );
              })}
            </Grid>
            {/* Buttons */}
            <Grid item xs="auto">
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item xs="auto">
                  <Button
                    onClick={() => {
                      setModalState(initialModalState);
                    }}
                    disabled={modalState && modalState.submitting}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs="auto">
                  <Button
                    variant="contained"
                    className={classes.actionButton}
                    onClick={() => {
                      onSubmit();
                    }}
                    disabled={
                      modalState.submitting ||
                      modalState.items == null ||
                      modalState.items.every(x => !x.checked)
                    }
                  >
                    Confirm
                    {modalState?.submitting ? (
                      <CircularProgress
                        variant="indeterminate"
                        size={20}
                        className={classes.submittingIndicator}
                      />
                    ) : null}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal>
    );
  };
  // #endregion

  return (
    <>
      {renderModal()}
      <BaseTable<INewAPIResponse, IResult, IChild>
        actionsPermitted={UserUtils.userIsPermitted(UserPermissions.ApplicationManagerGtdEdit)}
        enableParentCheckboxes={UserUtils.userIsPermitted(
          UserPermissions.ApplicationManagerGtdEdit,
        )}
        enableChildCheckboxes={UserUtils.userIsPermitted(UserPermissions.ApplicationManagerGtdEdit)}
        orderByDefaultChild="drug_name"
        setCount={props.setCount}
        triggerReload={reloadDataTrigger}
        triggerExternalCheckUpdate={modalItemCheckedTrigger}
        endpointBase={baseUrl}
        paginationQueryParamSettings={{
          pageSizeQueryStringKey: 'pageSize',
          pageNumberQueryStringKey: 'pageNumber',
          searchTermQueryStringKey: 'searchTerm',
          sortPropQueryStringKey: 'sortProp',
          sortOrderQueryStringKey: 'sortOrder',
        }}
        columnSettings={[
          {
            label: 'Drug Name',
            sortable: true,
            serverSideSearchField: 'drug_name',
            parentKey: 'drug_name',
            childKey: 'drug_name',
          },
          {
            label: 'GPI 10',
            sortable: true,
            serverSideSearchField: 'gpi_10',
            parentKey: 'gpi_10',
            childKey: 'gpi_10',
          },
          { label: 'Strength', sortable: true, childKey: 'strength' },
          { label: 'Dosage Form', sortable: true, childKey: 'dosage' },
        ]}
        childPkSelector={childPkSelector}
        parentPkSelector={parentPkSelector}
        searchTerm={props.searchTerm}
        rightActionButtons={[
          {
            key: 'include',
            label: 'Include',
            activeWhen: ButtonActiveWhen.SomeSelected,
            onClick: (checkedItems: IResult[]) => {
              setModalState({
                ...defaultIncludeState,
                submitting: false,
                open: true,
                items: convertToCheckedItems(checkedItems),
              });
            },
          },
          {
            key: 'exclude',
            label: 'Exclude',
            activeWhen: ButtonActiveWhen.SomeSelected,
            onClick: (checkedItems: IResult[]) => {
              setModalState({
                ...defaultExcludeState,
                submitting: false,
                open: true,
                items: convertToCheckedItems(checkedItems),
              });
            },
          },
        ]}
      />
    </>
  );
};

import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from '../../utils/theme-util';

const styles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    therapy: {
      borderLeft: '6px solid',
      borderColor: palette.primary.main,
      paddingLeft: 15,
    },
    leftMargin: { marginLeft: 6 },
  });
};

export { styles };

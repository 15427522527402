import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

const styles = (theme: Theme) => {
  const { font } = expandTheme(theme);

  return createStyles({
    root: {
      borderRadius: 7,
      borderWidth: 2,
      height: '95%',
      width: 200,
    },
    cardContent: {
      height: '71%',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      textAlign: 'center',
    },
    gridContainer: {
      height: '100%',
    },
    listItem: {
      fontSize: font.smallFontSize,
    },
  });
};

export { styles };

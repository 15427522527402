import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { expandTheme } from 'utils/theme-util';

export const styles = (theme: Theme) => {
  return createStyles({
    editTagsContainer: {
      padding: theme.spacing(1.5),
      width: theme.spacing(70),
      minHeight: '112px',
    },
  });
};

type Styles = WithStyles<typeof styles>;

const useStyles = makeStyles(styles);

export const useTypedStyles = (): Styles => ({
  classes: useStyles(),
});

import React from 'react';
import classesName from 'classnames';
import { Grid, Typography } from '@mui/material';
import { INVOICING_STAGES } from 'constants/index';
import { CircularLoadingButton } from 'components/circular-loading-button/circular-loading-button';
import useStyles from './styles';

const InvoicingWorkflows = ({
  sourceIsDirty,
  invoicingStage,
  downloadHandler,
  changeStageHandler,
  isLoadingChangeStage,
}) => {
  const {
    rightSpacing,
    invocingWorkflowContainer,
    invoicingWorkflowFirstButton,
    invoicingWorkflowApprovalLabel,
  } = useStyles();

  return (
    <Grid className={invocingWorkflowContainer}>
      <Grid justifyContent="flex-start" className={invoicingWorkflowFirstButton}>
        <CircularLoadingButton
          isLoading={isLoadingChangeStage}
          className={rightSpacing}
          buttonLabel="Export XLSX"
          variant="contained"
          color="secondary"
          onClick={() => downloadHandler()}
        />
        {(invoicingStage === INVOICING_STAGES.PREPPED ||
          invoicingStage === INVOICING_STAGES.REJECTED) &&
          sourceIsDirty && (
            <CircularLoadingButton
              onClick={() => changeStageHandler(INVOICING_STAGES.REJECTED)}
              isLoading={isLoadingChangeStage}
              buttonLabel="Send to IT for review"
            />
          )}
      </Grid>
      {invoicingStage === INVOICING_STAGES.PREPPED && !sourceIsDirty && (
        <Grid container alignItems="center" justifyContent="flex-end">
          <Grid item>
            <Typography
              className={classesName(rightSpacing, invoicingWorkflowApprovalLabel)}
              variant="subtitle1"
              display="block"
            >
              if everything is OK
            </Typography>
          </Grid>
          <Grid item>
            <CircularLoadingButton
              onClick={() => changeStageHandler(INVOICING_STAGES.VALIDATED)}
              isLoading={isLoadingChangeStage}
              buttonLabel="Validate Data"
            />
          </Grid>
        </Grid>
      )}
      {invoicingStage === INVOICING_STAGES.FINANCE_REVIEW && (
        <Grid container alignItems="center" justifyContent="flex-end">
          <Grid>
            <CircularLoadingButton
              onClick={() => changeStageHandler(INVOICING_STAGES.FINALIZED)}
              isLoading={isLoadingChangeStage}
              buttonLabel="Submit"
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default InvoicingWorkflows;

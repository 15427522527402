import {
  Grid,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Modal,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import History from 'containers/common/history';
import { accordianStyles } from 'components/accordian/accordian-styles';
import Divider from 'components/divider';
import DetailField from 'components/form/field/field';
import AccordionHeader from 'components/form/header/accordion-header';
import SubHeader from 'components/form/header/subheader';
import { yesNoToText } from 'services/utils/formatting';
import { LargeSpacer, SmallSpacer } from 'components/spacer/spacer';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';
import Audit from 'components/display/audit';
import {
  ENSURE_EMAIL_ADDRESSED,
  ENSURE_SMS_ADDRESSED,
  ENSURE_PATIENT_INFORMATION_VERIFIED,
} from 'constants/index';
import debounce from 'debounce-promise';
import { resolveAudit } from 'actions/action-audit';
import { auditContext, secureLinkAutomationStatus, secureLinkAutomation } from 'constants/lists';
import { resolveAuditDefinition } from 'services/utils/audit-service';
import { combineStyles, getModalStyle } from 'services/utils/styles-service';
import { isValidArray } from 'services/utils/common-service';
import { AddressRow, EmailRow, PhoneRow } from 'components/form/subform/subform';
import { convertToArborDate } from 'models/time/arbor-date';
import { VerificationPanel } from 'components/form/verification/verification-panel';
import { getTodayDateTime } from 'services/utils/date-service';
import { editPatient, sendPatientOptInSms } from 'actions/action-patient';
import { getLanguageDisplay } from 'services/utils/demographic-service';
import { getUserById } from 'services/utils/users-service';
import classNames from 'classnames';
import { styles } from './patient-demographics-styles';
import PatientForm from './patient-form';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function PatientInformation(props) {
  // eslint-disable-next-line
  const {
    classes,
    patient,
    editPatient,
    sendPatientOptInSms,
    users,
    resolveAudit,
    hideExpand,
    stretchContent,
    sourceNumber,
    numberOfPotentialRxDeliveryAddresses,
  } = props;
  const [displayEdit, setDisplayEdit] = useState(false);
  const [displayEditHistory, setDisplayEditHistory] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const auditState = useSelector(state => state.audit);
  const showFCSecureLinkAutomationSection =
    (patient?.filteredPhones || []).filter(
      phone => phone.use === 'Mobile' && phone.sms === 'Opt in',
    ).length > 0;

  const handleEdit = e => {
    if (expanded) {
      e.stopPropagation();
    }
    setDisplayEdit(!displayEdit);
  };

  const handleEditHistory = e => {
    e.stopPropagation();
    setDisplayEditHistory(!displayEditHistory);
  };

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleCancel = () => {
    setDisplayEdit(false);
  };

  const handleVerify = () => {
    const payload = {
      id: patient.id,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      is_verified: true, // set is_verified to 1
      // eslint-disable-next-line @typescript-eslint/naming-convention
      verified_dt: convertToArborDate(getTodayDateTime()).getUtcDatetime(),
    };
    editPatient(payload);

    resolveAuditDefinition({
      auditDefinitionType: ENSURE_PATIENT_INFORMATION_VERIFIED,
      auditState,
      patient,
      resolverFunction: resolveAudit,
    });
  };

  const handleSendOptInSms = debounce(phone => {
    const payload = {
      patientId: patient.id,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      tag_type_id: 1,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      resource_id: patient.id,
      phone,
    };
    sendPatientOptInSms(payload);
  }, 1000);
  const ssnStr = patient.ssn ? `***-**-${patient.ssn}` : '';
  const verifiedUser = getUserById(patient.verified_by, users);

  const accordionPanelClass = classNames({
    [classes.accordianPanel]: !stretchContent,
    [classes.stretchedPanel]: stretchContent,
    [classes.hiddenExpandAccordionPanel]: hideExpand,
  });
  return (
    <div>
      <LargeSpacer />
      <Accordion expanded={expanded} className={accordionPanelClass}>
        <AccordionSummary
          classes={{
            expandIconWrapper: classes.expandIcon,
          }}
          onClick={!hideExpand ? handleExpand : () => {}}
          expandIcon={!hideExpand && <ExpandMoreIcon />}
        >
          {displayEdit ? (
            <AccordionHeader
              name="Edit Patient Information"
              updatedDt={patient.updated}
              updatedBy={patient.updated_by}
              editHandler={handleEdit}
              id="edit_patient_information"
              noLeftMargin={hideExpand}
            />
          ) : (
            <AccordionHeader
              name="Patient Information"
              withHistory
              updatedDt={patient.updated}
              updatedBy={patient.updated_by}
              editHandler={handleEdit}
              historyHandler={handleEditHistory}
              id="patient_information"
              noLeftMargin={hideExpand}
            />
          )}
          <Modal open={displayEditHistory} onClose={handleEditHistory}>
            <div style={getModalStyle()} className={classes.historyModal}>
              <History url={`/patients/${patient.id}`} subType="patient" />
            </div>
          </Modal>
        </AccordionSummary>
        <AccordionDetails>
          {displayEdit ? (
            // Render Edit Patient Info Form
            <PatientForm
              patient={patient}
              cancelHandler={handleCancel}
              isEditing
              numberOfPotentialRxDeliveryAddresses={numberOfPotentialRxDeliveryAddresses}
            />
          ) : (
            // Render Patient Info View
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Grid container spacing={7}>
                  <Grid item xs={12}>
                    <SubHeader name="Personal Information" />
                  </Grid>
                </Grid>
                <Grid container spacing={7}>
                  <Grid item xs={3}>
                    <DetailField
                      fieldName="Last Name"
                      field={patient.last_name}
                      id="patient_last_name"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField
                      fieldName="First Name"
                      field={patient.first_name}
                      id="patient_first_name"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField fieldName="Middle Initial" field={patient.middle_name} />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField fieldName="Suffix" field={patient.suffix} />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField fieldName="Preferred Name" field={patient.nickname} />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField fieldName="Preferred Pronouns" field={patient.pronouns} />
                  </Grid>
                </Grid>
                <Grid container spacing={7}>
                  <Grid item xs={3}>
                    <DetailField
                      fieldName="Date of Birth"
                      field={convertToArborDate(patient.dob, true).getUtcDate(true)}
                      id="patient_DOB"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField fieldName="Gender" field={patient.gender} />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField fieldName="Race" field={patient.ethnicity} />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField fieldName="Social Security Number" field={ssnStr} />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField
                      fieldName="MRN"
                      field={patient.source_patient_id}
                      varaint="raw"
                      id="patient_MRN"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DetailField
                      fieldName="Languages"
                      field={getLanguageDisplay(patient.languages)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField
                      fieldName="Needs Interpreter"
                      field={yesNoToText(patient.need_interpreter)}
                    />
                  </Grid>
                </Grid>

                <Divider />
                <Grid container spacing={7}>
                  <Grid item xs={12}>
                    <SubHeader name="Addresses" />
                  </Grid>
                </Grid>
                {patient.is_declined_to_provide_addresses ? (
                  <Grid item>
                    <Typography variant="body2">Declines to provide an address</Typography>
                  </Grid>
                ) : (
                  isValidArray(patient.filteredAddresses) &&
                  patient.filteredAddresses.map((addr, index) => (
                    <AddressRow
                      source="patient"
                      key={addr.key}
                      index={index}
                      address={addr}
                      classes={classes}
                    />
                  ))
                )}

                <Divider />
                <Grid container spacing={7}>
                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      <Audit
                        iconType="warning"
                        auditRules={[ENSURE_EMAIL_ADDRESSED]}
                        context={auditContext.Warning}
                      />
                      <SubHeader name="Emails" />
                    </Grid>
                  </Grid>
                </Grid>
                {patient.email_options ? (
                  <Grid item>
                    {patient.email_options === 1 ? (
                      <Typography variant="body2">Declines to provide an email</Typography>
                    ) : (
                      <Typography variant="body2">No email to provide</Typography>
                    )}
                  </Grid>
                ) : (
                  isValidArray(patient.filteredEmails) &&
                  patient.filteredEmails.map((email, index) => (
                    <EmailRow key={email.key} index={index} email={email} classes={classes} />
                  ))
                )}
                <Divider />
                <Grid container spacing={7}>
                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      <Audit
                        iconType="warning"
                        auditRules={[ENSURE_SMS_ADDRESSED]}
                        context={auditContext.Warning}
                      />
                      <SubHeader name="Phones" />
                    </Grid>
                  </Grid>
                </Grid>
                {patient.is_declined_to_provide_phones ? (
                  <Grid item>
                    <Typography variant="body2">Declines to provide a phone</Typography>
                  </Grid>
                ) : (
                  isValidArray(patient.filteredPhones) &&
                  patient.filteredPhones
                    .sort((a, b) => a.rank - b.rank)
                    .map((phone, index) => (
                      <PhoneRow
                        key={phone.key}
                        index={index}
                        phone={phone}
                        sourceNumber={sourceNumber}
                        handleSendOptInSms={handleSendOptInSms}
                        classes={classes}
                        owner={patient}
                      />
                    ))
                )}
                {showFCSecureLinkAutomationSection ? (
                  <>
                    <Divider />
                    <Grid container>
                      <Grid item>
                        <Grid item xs={12}>
                          <Grid container alignItems="center">
                            <SubHeader name="Fill Coordination Secure Link Automation" />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        style={{ marginLeft: '70px', paddingTop: '15px' }}
                      >
                        <Grid item xs={6}>
                          <RadioGroup
                            row
                            name="automated_secure_link"
                            value={Number(patient.auto_secure_link)}
                          >
                            {secureLinkAutomation.map(item => (
                              <FormControlLabel
                                value={Number(item.value)}
                                control={<Radio disableRipple />}
                                label={item.label}
                                disabled={Number(patient.auto_secure_link) !== Number(item.value)}
                              />
                            ))}
                          </RadioGroup>
                        </Grid>
                        <Grid item xs={6}>
                          {patient.auto_secure_link === secureLinkAutomationStatus.AUTOMATION
                            ? `Anchor Therapy: ${props.anchorTherapyString}`
                            : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : null}
                <Divider />
                <VerificationPanel
                  type="patient"
                  isVerified={patient.is_verified}
                  handleVerify={handleVerify}
                  verifiedBy={verifiedUser}
                  verifiedDate={patient.verified_dt}
                  auditRules={[ENSURE_PATIENT_INFORMATION_VERIFIED]}
                />
              </Grid>
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
      <SmallSpacer />
    </div>
  );
}

const combinedStyles = combineStyles(styles, accordianStyles);

function mapStateToProps(state) {
  const { patient, lookups, therapies } = state;
  const anchorTherapy = therapies?.data?.[patient.asl_anchor_therapy_id] || {};
  const anchorTherapyString = anchorTherapy.id
    ? `${anchorTherapy.drug_name} ${anchorTherapy.strength} ${anchorTherapy.strength_unit_of_measure} ${anchorTherapy.dosage_form_description}`
    : 'Not Specified';

  return {
    anchorTherapyString,
    patient,
    users: lookups.users,
    sourceNumber: lookups.sourceNumber,
  };
}

export default compose(
  withStyles(combinedStyles),
  connect(mapStateToProps, {
    editPatient,
    resolveAudit,
    sendPatientOptInSms,
  }),
)(PatientInformation);

import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import ListIcon from '@mui/icons-material/List';
import PdfIcon from '@mui/icons-material/PictureAsPdf';

import { getDataTypeName, getClinicalDataTypesMap } from 'services/utils/data-collect';
import { convertToArborDate } from 'models/time/arbor-date';
import DcQuestionnaire from 'containers/tasks/data-collect/dc-questionnaire';
import { getUserById } from 'services/utils/users-service';
import { DownloadFileUtils } from 'utils/download-file-utils';

import { QuestionnairePrintPayload } from 'components/document/generator/questionnaire-print-payload';
import { getGeneratedDocument } from 'services/utils/document-service';
import { GeneratorUtil } from 'components/document/generator/generator-util';
import { useClinicalDataDCTask } from '../../../containers/patient/clinical/use-clinical-data';
import InfoSmsPopup from '../../sms/InfoPopup';
import { Reference } from '../renders/render-reference';
import Table from '../../form/table/table';

const DcDataType = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
});
export default props => {
  const clinicalDataTypes = useSelector(state => state.lookups.clinicalDataTypes);
  const medications = useSelector(state => state.medications.medicationGroups);
  const users = useSelector(state => state.lookups.users);
  const questionnaires = useSelector(state => state.lookups.patientQuestionnaires);
  const [questionnaire, setQuestionnaire] = useState(null);
  const [shouldCreateNewQuestionnaire, setShouldCreateNewQuestionnaire] = useState(null);
  const [missingItemsToCreateState, setMissingItemsToCreateState] = useState(null);
  const [newQuestionnaireWasCreated, setNewQuestionnaireWasCreated] = useState(null);
  const [clinicalObjectAndAnswers, setClinicalObjectAndAnswers] = useState(null);

  useEffect(() => {
    if (questionnaire && Object.keys(questionnaire).length && questionnaire.value && newQuestionnaireWasCreated === null) {
      const missingItemsToCreate = [{
        therapyId: questionnaire.therapyId,
        dataTypeId: questionnaire.dataTypeId,
        dcTaskId: questionnaire.taskDataCollectId,
      }];
      setShouldCreateNewQuestionnaire(true);
      setMissingItemsToCreateState(missingItemsToCreate);
    } else if (newQuestionnaireWasCreated && missingItemsToCreateState && missingItemsToCreateState.length) {
      setMissingItemsToCreateState(null);
      setShouldCreateNewQuestionnaire(null);
      const { clinicalObject, answers } = clinicalObjectAndAnswers;
      setQuestionnaire({
        ...questionnaire,
        clinicalDataItemId: clinicalObject.id,
        id: clinicalObject.id,
        questionnaire: {
          ...questionnaire.questionnaire,
          id: clinicalObject.questionnaire.id,
          answers: answers,
          score: answers.reduce((acc, it) => {
            if (it) {
              acc += parseInt(it, 10);
            }
            return acc;
          }, 0),
        },
      });
    }
  }, [questionnaire, newQuestionnaireWasCreated]);

  const clinicalDataTypesMap = getClinicalDataTypesMap(clinicalDataTypes);
  const { patient, task } = props;
  const clinicalDataItems = useClinicalDataDCTask(patient.id, task.id);
  if (!clinicalDataItems || clinicalDataItems.length === 0) {
    return null;
  }

  const getMostRecentClinicalDataItems = clinicalDataItems => {
    const clinicalDataTypeIds = clinicalDataItems.reduce((acc, item) => {
      if (!acc.includes(item.clinicalDataTypeId)) {
        acc.push(item.clinicalDataTypeId);
      }
      return acc;
    }, []);

    const dataPerDataTypeId = clinicalDataTypeIds.map(dataTypeId => {
      const rowsWithAssessmentDate = clinicalDataItems.reduce((acc, item) => {
        if (dataTypeId === item.clinicalDataTypeId && item.assessmentDate) {
          acc.push(item);
        }
        return acc;
      }, []).sort((a, b) => b.assessmentDate.localeCompare(a.assessmentDate) || b.id - a.id);

      const rowsWithoutAssessmentDate = clinicalDataItems.reduce((acc, item) => {
        if (dataTypeId === item.clinicalDataTypeId && item.assessmentDate === null) {
          acc.push(item);
        }
        return acc;
      }, []).sort((a, b) => b.id - a.id);
      return rowsWithAssessmentDate.concat(rowsWithoutAssessmentDate)[0];
    });
    return dataPerDataTypeId;
  };

  const latestClinicalDataItems = getMostRecentClinicalDataItems(clinicalDataItems);
  const labFields = latestClinicalDataItems.map(it => ({
    ...clinicalDataTypesMap[it.clinicalDataTypeId],
    ...it,
    dataTypeId: it.clinicalDataTypeId,
  }));

  // Print button is only active for submitted Rapid 3
  const isPrintButtonVisible = dcItem => {
    const isRapid3DataType =
      dcItem?.clinicalDataTypeId === Number(QuestionnairePrintPayload.RAPID_3_DATA_TYPE_ID);
    const isSubmitted = Boolean(dcItem?.questionnaire?.submittedDate);
    return isRapid3DataType && isSubmitted;
  };

  const printButtonFunctionality = async dcItem => {
    const { name, references, questionnaireId, questionnaire: questionnaireData } = dcItem;
    const { questions } = questionnaires[questionnaireId];
    const { submittedBy, submittedDate, answers, score } = questionnaireData;
    const submittedByUser = getUserById(submittedBy, users);
    const pdc = new QuestionnairePrintPayload();
    const payload = await pdc.getPayload(
      patient,
      name,
      questions,
      answers,
      score,
      references,
      submittedByUser,
      submittedDate,
    );
    const generatedDocumentResponse = await getGeneratedDocument(
      payload,
      patient.customer_id,
      patient.id,
    );
    const viewableResult = GeneratorUtil.convertToViewable(generatedDocumentResponse.data, name);
    DownloadFileUtils.downloadUrl(viewableResult.url, viewableResult.fileName);
  };

  return (
    <>
      <Table
        data={labFields}
        columns={[
          {
            id: 'dataType',
            name: 'Data Type',
            getValue: dcItem => {
              const name = getDataTypeName(clinicalDataTypesMap, dcItem.clinicalDataTypeId);
              const { questionnaire: dcItemQuestionnaire } = dcItem;
              const labFieldsWithExternalUrl = Array.isArray(labFields)
                ? labFields.filter(
                    item =>
                      dcItemQuestionnaire &&
                      item.questionnaire &&
                      item.questionnaire.externalUrlId === dcItemQuestionnaire.externalUrlId,
                  )
                : [];
              const questionnaireNames = labFieldsWithExternalUrl.map(item => item.name);
              const questionnaireDataItemIds = labFieldsWithExternalUrl.map(item => item.id);
              const infoSmsPopupButton = dcItemQuestionnaire && !!dcItemQuestionnaire.smsSent && (
                <InfoSmsPopup
                  smsId={
                    dcItemQuestionnaire &&
                    dcItemQuestionnaire.smsIds &&
                    dcItemQuestionnaire.smsIds.length
                      ? dcItemQuestionnaire.smsIds[0]
                      : null
                  }
                  questionnaire={questionnaireNames || [name]}
                  questionnaireDataItemIds={questionnaireDataItemIds || [dcItem.id]}
                  dataCollectId={task.id}
                  isQuestionnaireSubmitted={Boolean(
                    dcItemQuestionnaire && dcItemQuestionnaire.submittedDate,
                  )}
                  submittedBy={dcItemQuestionnaire && dcItemQuestionnaire.submittedBy}
                  questionnaireSubmittedDate={
                    dcItemQuestionnaire && dcItemQuestionnaire.submittedDate
                  }
                  externalUrlId={dcItemQuestionnaire.externalUrlId}
                />
              );

              return (
                <DcDataType>
                  {name}
                  {infoSmsPopupButton}
                </DcDataType>
              );
            },
          },
          {
            id: 'required',
            name: 'Required?',
            getValue: dcItem => (dcItem.required ? 'Yes' : 'No'),
          },
          {
            id: 'assessmentDate',
            name: 'Assessment Date',
            // eslint-disable-next-line no-confusing-arrow
            getValue: dcItem =>
              dcItem.assessmentDate
                ? convertToArborDate(dcItem.assessmentDate, true).getUtcDate(true)
                : '-',
          },
          {
            id: 'value',
            name: 'Value',
            getValue: dcItem => {
              const onClick = () =>
                setQuestionnaire({
                  ...dcItem,
                  dataTypeField: clinicalDataTypesMap[dcItem.clinicalDataTypeId],
                  clinicalDataItemId: dcItem.id,
                });
              const questionnaireButton = dcItem.type === 'questionnaire' && (
                <IconButton onClick={onClick} name="questionnaire_button" size="small">
                  <ListIcon />
                </IconButton>
              );
              const printQuestionnaireButton = isPrintButtonVisible(dcItem) && (
                <IconButton
                  size="small"
                  onClick={async () => {
                    await printButtonFunctionality(dcItem);
                  }}
                  name="questionnaire_print_button"
                >
                  <PdfIcon />
                </IconButton>
              );

              if (dcItem.type === 'therapy' && dcItem.value) {
                const failedMedicationsList = dcItem.value.split(',');
                const therapyFieldValue = [];
                failedMedicationsList.forEach(failedMedication => {
                  const medication = medications.find(
                    med => med.gpi.substr(0, 10) === failedMedication,
                  );
                  if (medication) {
                    return therapyFieldValue.push(medication.drug_name);
                  }
                  return null;
                });
                return (
                  <>
                    {dcItem.value !== undefined && dcItem.value !== null
                      ? `${therapyFieldValue.join()}`
                      : '-'}
                  </>
                );
              }

              return (
                <>
                  {dcItem.value !== undefined && dcItem.value !== null
                    ? `${dcItem.value} ${dcItem.unit || ''}`
                    : '-'}
                  {questionnaireButton}
                  {printQuestionnaireButton}
                </>
              );
            },
          },
          {
            id: 'reference',
            name: 'Reference',
            // eslint-disable-next-line no-confusing-arrow
            getValue: dcItem => (
              <Reference input={{ value: dcItem.value }} references={dcItem.references} />
            ),
          },
          {
            id: 'cannotCompleteReason',
            name: 'Not Able to Complete Reason',
            getValue: dcItem => dcItem.cannotCompleteReason || '',
          },
        ]}
      />
      {questionnaire && (
        <DcQuestionnaire
          clinicalDataItemId={questionnaire.clinicalDataItemId}
          dataTypeField={questionnaire.dataTypeField}
          patientId={patient.id}
          shouldCreateNewQuestionnaire={shouldCreateNewQuestionnaire}
          setShouldCreateNewQuestionnaire={setShouldCreateNewQuestionnaire}
          missingItemsToCreateState={missingItemsToCreateState}
          newQuestionnaireWasCreated={newQuestionnaireWasCreated}
          setNewQuestionnaireWasCreated={setNewQuestionnaireWasCreated}
          setClinicalObjectAndAnswers={setClinicalObjectAndAnswers}
          onClose={() => {
            setQuestionnaire(null);
            setShouldCreateNewQuestionnaire(null);
            setMissingItemsToCreateState(null);
            setNewQuestionnaireWasCreated(null);
            setClinicalObjectAndAnswers(null);
          }}
        />
      )}
    </>
  );
};

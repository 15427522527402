import React, { useState, useEffect } from 'react';
import CreateIcon from '@mui/icons-material/Create';

import { BaseTable } from 'containers/application-manager/base-table/base-table';
import { UserUtils } from 'utils/user-utils';
import { UserPermissions } from 'interfaces/user/Permissions';
import { IHeaderCell } from '../../../base-table/types';
import { styles } from '../../index.styles';
import {
  IResponse,
  IGpiProtocolItem,
  IFrequencyItem,
  ITableProps,
  IEditGpiProtocolProps,
} from '../types';
import FrequencyDetails from './FrequencyDetails';

const ENDPOINT_BASE = '/application-manager/cpmp/gpi/list';
const DEFAULT_ORDERBY = 'drugName';
const USER_HAS_PERMISSION = UserUtils.userIsPermitted(UserPermissions.ApplicationManagerCpmpEdit);
const PAGINATION_SETTINGS = {
  pageSizeQueryStringKey: 'pageSize',
  pageNumberQueryStringKey: 'pageNumber',
  searchTermQueryStringKey: 'searchTerm',
  sortPropQueryStringKey: 'sortProp',
  sortOrderQueryStringKey: 'sortOrder',
};
const COLUMN_SETTINGS: IHeaderCell<IGpiProtocolItem, IFrequencyItem>[] = [
  {
    label: 'Brand/ Drug Name',
    parentKey: 'drugName',
    hideLeftPadding: true,
    childColSpan: 3,
    childComponent: (_, child) => <FrequencyDetails frequency={child} />,
  },
  {
    label: 'Medication',
    parentKey: 'medicationType',
    hideLeftPadding: true,
    hideChildCol: true,
  },
  {
    label: 'GPI-10',
    parentKey: 'gpi_10',
    hideLeftPadding: true,
    hideChildCol: true,
  },
];

const transformData = (data: IResponse) => ({
  ...data,
  results: data.results.map(item => ({ ...item, children: item.items })),
});

const EditGpiProtocol: React.FC<IEditGpiProtocolProps> = ({ parent, onEdit }) => {
  const handleEditAction = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    onEdit(parent);
  };
  return (
    <a onClick={handleEditAction}>
      <CreateIcon />
    </a>
  );
};

export const DataTable: React.FC<ITableProps> = ({
  triggerReload,
  searchTerm,
  userHasPermissionToEditDcItem,
  handleGpiProtocolEdit,
}): JSX.Element => {
  const [columnSettings, setColumnSettings] = useState(COLUMN_SETTINGS);
  const classes = styles();

  useEffect(() => {
    if (userHasPermissionToEditDcItem) {
      setTableEditButton();
    }
  }, []);

  const setTableEditButton = (): void => {
    setColumnSettings([
      ...COLUMN_SETTINGS,
      {
        parentComponent: parent => (
          <EditGpiProtocol parent={parent} onEdit={handleGpiProtocolEdit} />
        ),
      },
    ]);
  };

  return (
    <BaseTable<IResponse, IGpiProtocolItem, IFrequencyItem>
      actionsPermitted={userHasPermissionToEditDcItem}
      enableParentCheckboxes={false}
      searchTerm={searchTerm}
      classesOverride={{
        lastCell: classes.lastCell,
      }}
      childPkSelector={c => c.protocolFrequency}
      parentPkSelector={p => p.gpi_10}
      endpointBase={ENDPOINT_BASE}
      transformResponseData={transformData}
      orderByDefaultParent={DEFAULT_ORDERBY}
      enableChildCheckboxes={USER_HAS_PERMISSION}
      paginationQueryParamSettings={PAGINATION_SETTINGS}
      columnSettings={columnSettings}
      triggerReload={triggerReload}
    />
  );
};

import { dateFormatUI } from 'models/time/arbor-date';
import moment from 'moment';

export const dateConvert = (value: unknown): string => {
  const defaultValue = '-';

  if (value && typeof value === 'string') {
    return moment.utc(value).format(dateFormatUI);
  }

  return defaultValue;
};

export const sortResults = <T>(
  a: T,
  b: T,
  sortProp: keyof T,
  valueFormatter: ((value: unknown) => unknown) | undefined,
): number => {
  const aVal = valueFormatter ? (valueFormatter(a[sortProp]) as any) : a[sortProp];
  const bVal = valueFormatter ? (valueFormatter(b[sortProp]) as any) : b[sortProp];

  if (bVal < aVal) {
    return -1;
  }
  if (bVal > aVal) {
    return 1;
  }
  return 0;
};

export const booleanConvert = (value: unknown): string => {
  return value ? 'Yes' : 'No';
};

export const utcMomentOrUndefined = (
  value: string | undefined | null,
): moment.Moment | undefined => {
  return value ? moment.utc(value) : undefined;
};

export const diagnosisForSql = (icd: string): string => {
  const indexOfPeriod = icd.indexOf('.');
  if (indexOfPeriod > 0) {
    return icd.replace(/./g, (s, i) => (i > indexOfPeriod ? '_' : s));
  }
  return icd;
};

import React, { Fragment } from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CheckmarkIcon } from 'components/icons/icons';
import Weather from 'components/dynamic-form/custom-elements/weather';
import Location from 'components/dynamic-form/custom-elements/location';
import { styles } from '../../form/field/field-styles';
import { getFormLabel, getProvider } from '../helpers';

function getDisplayValForReadOnly(val, formatter, field, providers) {
  if ((!!val || val === 0) && formatter) {
    return formatter(val, providers[field.provider] || []);
  }
  if (val || val === 0) {
    return val;
  }
  return '-';
}

const useStyles = makeStyles(styles);

export const RenderReadonly = props => {
  const { label, value } = props;
  const classes = useStyles();

  return (
    <>
      <Typography variant="caption" className={classes.detailHeader}>
        {label}
      </Typography>
      <Typography variant="body2">{value}</Typography>
    </>
  );
};

export default ({ providers, field }) => {
  const classes = useStyles();
  let { displayFormat } = field;
  const { component } = field;
  if (!displayFormat) {
    if (component === 'date') {
      displayFormat = 'dateFormatterDisplay';
    } else if (component === 'datetime') {
      displayFormat = 'datetimeFormatterDisplay';
    }
  }
  const formatter = displayFormat ? getProvider(providers, displayFormat) : undefined;
  const val =
    providers &&
    providers.task &&
    field &&
    field.property &&
    (!!providers.task[field.property] || providers.task[field.property] === 0)
      ? providers.task[field.property]
      : null;
  if (field.component === 'dropdownMultipleAsync') {
    const values = getDisplayValForReadOnly(val, formatter, field, providers);
    return (
      <>
        <Typography variant="caption" className={classes.detailHeaderNote}>
          {getFormLabel(providers, field)}
        </Typography>
        {values &&
          Array.isArray(values) &&
          values.map(value => (
            <Fragment key={value}>
              <CheckmarkIcon />
              <Typography variant="caption" className={classes.asyncDetail}>
                {value}
              </Typography>
            </Fragment>
          ))}
      </>
    );
  }
  if (field.component === 'note') {
    return (
      <>
        <Typography variant="caption" className={classes.detailHeaderNote}>
          {getFormLabel(providers, field)}
        </Typography>
        <Typography className={classes.noteDetail}>
          {getDisplayValForReadOnly(val, formatter, field, providers)}
        </Typography>
      </>
    );
  }
  if (field.component === 'title' && field.type === 'therapy') {
    return (
      <div className={classes.detailHeaderTherapy}>
        <h3>{getDisplayValForReadOnly(val, formatter, field, providers)}</h3>
      </div>
    );
  }
  if (field.component === 'location') {
    if (val) {
      const weather = JSON.parse(val);
      const locationValue = { value: weather ? weather.city : '' };
      return <Location input={locationValue} />;
    }
    return null;
  }
  if (field.component === 'weather') {
    if (val) {
      const weatherValue = { value: JSON.parse(val) };
      return <Weather input={weatherValue} />;
    }
    return null;
  }
  return (
    <RenderReadonly
      label={getFormLabel(providers, field)}
      value={getDisplayValForReadOnly(val, formatter, field, providers)}
    />
  );
};

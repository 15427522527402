import makeStyles from '@mui/styles/makeStyles';
import { ModalUtil } from 'services/utils/modal.util';
import { expandTheme } from 'utils/theme-util';
import { modal, modalTitle } from '../constants.styles';

export const styles = makeStyles(theme => {
  const { font, palette } = expandTheme(theme);

  return {
    modal: {
      ...modal(theme),
      maxHeight: '80%',
      overflowY: 'scroll',
      minWidth: '35%',
    },
    title: {
      ...modalTitle(theme),
    },
    disabledTextFieldInput: {
      paddingBottom: theme.spacing(0.375),
    },
    headerTitleLeft: {
      fontSize: font.mediumFontSize,
      fontWeight: 'bold',
      color: palette.primary.steelGrey,
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    siteNames: {
      fontSize: font.mediumFontSize,
      color: palette.primary.steelGrey,
      paddingLeft: theme.spacing(1),
    },
    siteRow: {
      paddingTop: theme.spacing(1),
    },
    arborSwitch: {
      paddingLeft: theme.spacing(5),
    },
    serviceGroupField: {
      paddingLeft: theme.spacing(2),
    },
    button: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    credentialedAtSiteFields: {
      paddingTop: theme.spacing(1),
    },
    crendentialedAtField: {
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
    inactivateButton: {
      color: palette.primary.valenciaRed,
    },
    addSiteButton: {
      paddingBotton: theme.spacing(6),
    },
  };
});

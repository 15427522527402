import React, { useState } from 'react';
import {
  IStepProps,
  IPatientMergeSectionDetailsProps,
  IPatientMergeProps,
} from 'components/patient-merge-modal/interfaces';
import { DemographicSection } from 'components/patient-merge-modal/select-form-view/sections/demographic-section';
import { ClinicalSection } from 'components/patient-merge-modal/select-form-view/sections/clinical-section';
import { TherapySection } from 'components/patient-merge-modal/select-form-view/sections/therapy-section';
import { DocumentSection } from 'components/patient-merge-modal/select-form-view/sections/document-section';
import { PatientTasksSection } from 'components/patient-merge-modal/select-form-view/sections/patient-tasks-section';
import { IncomeAndBenefitsSection } from 'components/patient-merge-modal/select-form-view/sections/income-benefits-section';
import { ISection } from 'components/section/interfaces';
import { PatientMergeSection } from 'components/patient-merge-modal/patient-merge-section';

const sections: ISection<IPatientMergeProps>[] = [
  {
    title: 'Demographics',
    render: (props: IPatientMergeSectionDetailsProps) => <DemographicSection {...props} />,
  },
  {
    title: 'Income And Benefits',
    render: (props: IPatientMergeSectionDetailsProps) => <IncomeAndBenefitsSection {...props} />,
  },
  {
    title: 'Clinical',
    render: (props: IPatientMergeSectionDetailsProps) => <ClinicalSection {...props} />,
  },
  {
    title: 'Therapies',
    render: (props: IPatientMergeSectionDetailsProps) => <TherapySection {...props} />,
  },
  {
    title: 'Patient Level Tasks',
    render: (props: IPatientMergeSectionDetailsProps) => <PatientTasksSection {...props} />,
  },
  {
    title: 'Documents',
    render: (props: IPatientMergeSectionDetailsProps) => <DocumentSection {...props} />,
  },
];

export function SelectFormSections(props: IStepProps) {
  const { onSectionChange, ...patientMergeProps } = props;
  const [expanded, setExpanded] = useState<undefined|number>(undefined);
  const handleChange = (idx: number) => async (_: unknown, isExpanded: boolean) => {
    setExpanded(isExpanded ? idx : undefined);
  };

  return (
    <>
      {sections.map(
        (section, idx) => (
          <PatientMergeSection
            key={`section-${section.title}`}
            section={section}
            expanded={idx === expanded}
            onChange={handleChange(idx)}
            {...patientMergeProps}
          />
        ),
      )}
    </>
  );
}

import React from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import EditIcon from '@mui/icons-material/Edit';
import { BaseTable } from 'containers/application-manager/base-table/base-table';
import { IHeaderCell } from 'containers/application-manager/base-table/types';
import { useDebounce } from 'use-debounce';
import { useDispatch } from 'react-redux';
import { UserUtils } from 'utils/user-utils';
import { UserPermissions } from 'interfaces/user/Permissions';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { CpmpStyles } from '../index.styles';
import { SectionTitleStyled } from '../../components/section-title';
import { SearchField } from '../../components/search-field';

import { IResponse, IResult, IResultChildren, mapCslPlanToTableResult } from './data-table';
import { CslPlan } from './types';
import { ICslProtocolFormModalRef, CslProtocolFormModal } from './form-modal';
import { notifyError } from '../../../../actions/action-notifications';

const searchFieldPlaceholder = 'Search by Medication or Disease State';
const initialSearchTerm = '';
const debounceTimeMs = 1000;

type ICpmpProps = WithStyles<typeof CpmpStyles>;

type OnClickCb = () => void;
type OnClickCbBuilder = (protocol: CslPlan) => OnClickCb;

const EditCell = (props: CslPlan & { onClickBuilder: OnClickCbBuilder }) => {
  const { onClickBuilder, ...protocol } = props;

  return (
    <>
      <EditIcon fontSize="small" onClick={onClickBuilder(protocol)} />
    </>
  );
};

const buildRendererEditCell = (onClickBuilder: OnClickCbBuilder) => (props: IResult) =>
  <EditCell {...props} onClickBuilder={onClickBuilder} />;

const CpmpCsl = (props: ICpmpProps): JSX.Element => {
  const { classes } = props;
  const [reloadTrigger, setReloadTrigger] = React.useState<Date | undefined>(undefined);
  const [protocol, setProtocol] = React.useState<CslPlan | undefined>(undefined);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [debouncedSearchTerm] = useDebounce<string | undefined>(searchTerm, debounceTimeMs);
  const user = useTypedSelector(state => state.auth.currentUser);
  const dispatch = useDispatch();

  const addFormModalRef = React.useRef<ICslProtocolFormModalRef>(null);
  const editFormModalRef = React.useRef<ICslProtocolFormModalRef>(null);

  const userManageUserLevel = React.useMemo<boolean>(() => {
    return UserUtils.userIsPermitted(UserPermissions.ApplicationManagerUsersManageUserLevel);
  }, [user]);

  const userHasPermissionToaddDcItem = React.useMemo<boolean>(() => {
    return UserUtils.userIsPermitted(UserPermissions.ApplicationManagerCpmpEdit);
  }, [user]);

  const canEdit = userManageUserLevel || userHasPermissionToaddDcItem;

  const url = 'application-manager/cpmp/csl-protocol';

  const getCslData = (csl: CslPlan[]): IResult[] =>
    csl.map((x: CslPlan) => mapCslPlanToTableResult(x));

  const renderAddCslButton = () => (
    <Button
      variant="contained"
      className={classes.addButton}
      onClick={() => {
        addFormModalRef.current?.setOpen(true);
      }}
    >
      Add CSL Assessment / Plan
    </Button>
  );

  const onCslFormSuccess = () => {
    setReloadTrigger(new Date());
  };

  const responseDict: Record<string, string> = {
    'There is already a CSL protocol for this gpi_10 and specialty_id':
      'CSL Assessment / Plan for GPI and Disease State already exists',
  };

  const onCslFormError = (err: any) => {
    const errMessage: string = err?.response?.data?.message || err?.message;
    const message =
      responseDict[errMessage] || errMessage || 'Failed to save CSL protocol information';
    dispatch(notifyError(message));
  };

  const getOnEditCellClick = (clickedProtocol: CslPlan) => () => {
    editFormModalRef.current?.setOpen(true);
    setProtocol(clickedProtocol);
  };

  const COLUMN_SETTINGS: IHeaderCell<IResult, IResultChildren>[] = [
    {
      label: 'Medication Name',
      parentKey: 'drug_name',
      sortable: true,
      serverSideSearchField: 'drug_name',
      hideLeftPadding: true,
      childColSpan: 4,
      childComponent: (parent: IResult, child: IResultChildren) => {
        return (
          <div>
            <Typography className={classes.contentTitle}>Goals of therapy</Typography>
            <Typography className={classes.contentDescription}>
              <div dangerouslySetInnerHTML={{ __html: child.expectations_and_goals }} />
            </Typography>
            <br />
            <Typography className={classes.contentTitle}>Disease state education</Typography>
            <Typography paragraph className={classes.contentDescription}>
              <div dangerouslySetInnerHTML={{ __html: child.disease_state_education }} />
            </Typography>
            <br />
            <Typography className={classes.contentTitle}>Administration</Typography>
            <Typography className={classes.contentDescription}>
              <div dangerouslySetInnerHTML={{ __html: child.administration }} />
            </Typography>
            <br />
            <Typography className={classes.contentTitle}>Storage/Disposal</Typography>
            <Typography className={classes.contentDescription}>
              <div dangerouslySetInnerHTML={{ __html: child.storage_disposal }} />
            </Typography>
            <br />
            <Typography className={classes.contentTitle}>Side Effects</Typography>
            <Typography className={classes.contentDescription}>
              <div dangerouslySetInnerHTML={{ __html: child.side_effects }} />
            </Typography>
            <br />
            <Typography className={classes.contentTitle}>Contact</Typography>
            <Typography className={classes.contentDescription}>
              <div dangerouslySetInnerHTML={{ __html: child.contact }} />
            </Typography>
            <br />
            <Typography className={classes.contentTitle}>Adherence</Typography>
            <Typography className={classes.contentDescription}>
              <div dangerouslySetInnerHTML={{ __html: child.adherence }} />
            </Typography>
            <br />
            <Typography className={classes.contentTitle}>Monitoring and Follow Up</Typography>
            <Typography className={classes.contentDescription}>
              <div dangerouslySetInnerHTML={{ __html: child.monitoring_follow_up }} />
            </Typography>
            <br />
          </div>
        );
      },
    },
    {
      label: 'Associated GPI',
      parentKey: 'gpi_10',
      sortable: true,
      serverSideSearchField: 'gpi_10',
      hideLeftPadding: true,
      hideChildCol: true,
    },
    {
      label: 'Associated ICD-10',
      parentKey: 'icd_list',
      hideLeftPadding: true,
      sortable: false,
      hideChildCol: true,
    },
    {
      label: 'Disease State Name',
      parentKey: 'specialty_name',
      sortable: true,
      serverSideSearchField: 'specialty_name',
      hideLeftPadding: true,
      hideChildCol: true,
    },
  ];

  if (canEdit) {
    COLUMN_SETTINGS.push({
      label: '',
      sortable: false,
      hideLeftPadding: true,
      parentComponent: buildRendererEditCell(getOnEditCellClick),
    });
  }

  return (
    <>
      <SectionTitleStyled
        title="Counseling"
        rightSideItems={[
          {
            element: renderAddCslButton(),
            key: 'add-csl-item-button',
          },
        ]}
      />

      <Grid container direction="column">
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item xs="auto">
            <Grid container spacing={0}>
              <Grid item xs="auto">
                <SearchField
                  width={48}
                  value={searchTerm}
                  onChange={setSearchTerm}
                  initialValue={initialSearchTerm}
                  placeholder={searchFieldPlaceholder}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs="auto">
          <BaseTable<IResponse, IResult, IResultChildren>
            actionsPermitted
            enableParentCheckboxes={false}
            enableChildCheckboxes={false}
            paginationQueryParamSettings={{
              pageSizeQueryStringKey: 'pageSize',
              pageNumberQueryStringKey: 'pageNumber',
              searchTermQueryStringKey: 'q',
              sortPropQueryStringKey: 'sortProp',
              sortOrderQueryStringKey: 'sortOrder',
            }}
            endpointBase={url}
            childPkSelector={c => c.specialty_id}
            parentPkSelector={p => p.id}
            columnSettings={COLUMN_SETTINGS}
            triggerReload={reloadTrigger}
            searchTerm={debouncedSearchTerm}
            transformResponseData={response =>
              ({
                results: getCslData(response.items),
                totalCount: response.totalItems,
              } as IResponse)
            }
          />
        </Grid>
      </Grid>
      <CslProtocolFormModal ref={addFormModalRef} onSuccess={onCslFormSuccess} />
      <CslProtocolFormModal
        ref={editFormModalRef}
        editMode
        protocol={protocol}
        onSuccess={onCslFormSuccess}
      />
    </>
  );
};

export default withStyles(CpmpStyles)(CpmpCsl);

import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { styles } from './field-styles';

const FieldTitle = ({ title, classes, children, ...props }) => (
  <div className={classes.formTitleWrapper} {...props}>
    <h4 className={classes.formTitleWithWrapper}>{title}</h4>
    {children}
  </div>
);

export default withStyles(styles, { withTheme: true })(FieldTitle);

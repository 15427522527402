import { ModalUtil } from 'services/utils/modal.util';

export const styles = theme => ({
  container: {
    padding: 20,
  },
  card: {
    overflow: 'visible',
  },
  customFormField: {
    margin: theme.spacing(1),
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.bluesky,
    marginBottom: 20,
    minWidth: 600,
  },
  rightAlignedConfirmation: {
    justifyContent: 'flex-end',
    paddingBottom: 10,
    marginRight: 8,
    marginBottom: 40,
  },
  leftAlignedActionButtons: {
    marginLeft: 8,
  },
  formDivider: {
    marginLeft: 16,
    marginRight: 16,
    height: '2px',
  },
  deliveryButton: {
    float: 'right',
  },
  deliveryButtonContainer: {
    width: '100%',
    height: 0,
  },
  deliveryButtonActive: {
    backgroundColor: theme.palette.primary.cuttySark,
    color: theme.palette.primary.white,
  },
  chip: {
    padding: theme.spacing(1),
    fontSize: theme.font.mediumFontSize,
    marginLeft: theme.spacing(2.25),
  },
  loading: {
    marginLeft: 10,
    color: theme.palette.primary.trellisBlue,
  },
  formTitleHeaderWrapperItem1: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexGrow: 5,
    fontWeight: 'bold',
    fontSize: 'large',
  },
  expirationDateLabel: {
    fontSize: theme.font.smallFontSize,
  },
  modal: {
    ...ModalUtil.BasicModalStyling(theme, 90, 3),
    top: '45%',
    left: '40%',
    width: theme.spacing(70),
  },
  modalButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(10),
  },
});

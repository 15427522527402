import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { useState } from 'react';
import { getStatusByStatusId } from 'services/utils/task-service';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import TaskDetailForm from 'components/dynamic-form/task-forms/task-detail-form';
import NoteDisplay from 'components/note/note-display';
import DocumentDisplay from 'components/document/document-display';
import ActionRow from 'components/note/action-row';
import FillDeliveryConfirmationStatus from './status-fill-delivery-confirmation';
import EditFillDeliveryConfirmation from './edit-fill-delivery-confirmation';
import TaskLinks from '../task-links';
import { StatusSelector } from '../common/status-selector';
import { TASK_FILL_DELIVERY_CONFIRMATION } from '../../../constants/index';
import { styles } from '../task-detail-styles';

const FillDeliveryConfirmationDetail = props => {
  const {
    statuses,
    documents,
    task,
    classes,
    update,
    notes,
    tagTypeId,
    tagTypeLabel,
    therapy,
    serviceGroups,
    displayEdit,
    handleCancel,
    taskIDPrefix,
    drugName,
  } = props;
  const { status_id: statusId, service_group_id: serviceGroupId } = task;

  const [selectedStatus, setSelectedStatus] = useState(
    task.status ? task.status : getStatusByStatusId(task.status_id, statuses),
  );

  return (
    <div>
      <Grid
        container
        className={displayEdit ? classes.taskDetailContainerEdit : classes.taskDetailContainer}
      >
        <Grid item xs={2}>
          <Grid container alignItems="center">
            {!displayEdit && (
              <StatusSelector
                value={selectedStatus}
                currentStatus={task.status}
                onChange={value => setSelectedStatus(value)}
                statuses={statuses}
                taskIDPrefix={taskIDPrefix}
                task={task}
              />
            )}
          </Grid>
        </Grid>
        {!displayEdit && (
          <Grid item xs={7}>
            <TaskDetailForm task={task} taskType="FDC" />
          </Grid>
        )}
        {displayEdit && <Grid item xs={5} />}
        <Grid item xs={3}>
          {!displayEdit && (
            <Grid item xs={12}>
              <Grid container className={classes.note}>
                <NoteDisplay {...notes} tagResourceId={task.id} />
              </Grid>
              <DocumentDisplay
                taskIdResourceIds={[{ tagTypeId, resourceId: task.id }]}
                tagTypeId={tagTypeId}
                resourceId={task.id}
                drugName={drugName}
                tagTypeLabel={tagTypeLabel}
                documents={documents}
              />
              <ActionRow
                therapy={therapy}
                task={task}
                tagTypeId={tagTypeId}
                tagResourceId={task.id}
                cancel={handleCancel}
                defaultServiceGroup={serviceGroupId}
                serviceGroups={serviceGroups}
                taskType={TASK_FILL_DELIVERY_CONFIRMATION}
                idPrefix={taskIDPrefix}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <TaskLinks links={task.links} />
      <Grid container>
        {displayEdit && (
          <Grid item xs={12}>
            <EditFillDeliveryConfirmation
              task={task}
              statuses={statuses}
              cancel={handleCancel}
              serviceGroups={serviceGroups}
            />
          </Grid>
        )}
        {!displayEdit &&
          selectedStatus !== getStatusByStatusId(statusId, statuses) &&
          statusId &&
          statuses && (
            <Grid
              item
              xs={12}
              className={selectedStatus !== task.status ? classes.statusContainer : null}
            >
              <FillDeliveryConfirmationStatus
                selectedStatus={selectedStatus}
                therapyStatusName={selectedStatus}
                task={task}
                statuses={statuses}
                cancel={() => setSelectedStatus(getStatusByStatusId(task.status_id, statuses))}
                update={update}
                tagTypeId={tagTypeId}
                tagResourceId={task.id}
              />
            </Grid>
          )}
      </Grid>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    users: state.lookups.users,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(FillDeliveryConfirmationDetail);

import React from 'react';
import { History } from '@mui/icons-material';
import { IconButton, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getModalStyle } from 'services/utils/styles-service';
import { UseLabelScanState } from './use-shipping-scan';
import ShippingScanHistoryTable from './shipping-scan-history-table';

const StyledModalContent = styled('div')(({ theme }) => ({
  ...getModalStyle(),
  position: 'absolute',
  width: theme.spacing(120),
  backgroundColor: (theme.palette.primary as any).background,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  borderRadius: 5,
  overflow: 'auto',
  maxHeight: '92vh',
}));

function ShippingScanHistoryModal({ scanProps }: { scanProps: UseLabelScanState }) {
  const [isOpen, toggleOpen] = React.useReducer(s => !s, false);
  return (
    <>
      <IconButton onClick={toggleOpen} size="large">
        <History />
      </IconButton>
      <Modal open={isOpen} onBackdropClick={toggleOpen}>
        <StyledModalContent>
          <ShippingScanHistoryTable rows={scanProps.history} />
        </StyledModalContent>
      </Modal>
    </>
  );
}

export default ShippingScanHistoryModal;

import { convertToArborDate } from 'models/time/arbor-date';
import { IState } from 'interfaces/redux/IState';
import { ITherapy } from 'interfaces/redux/ITherapy';
import { TherapyAdministrationStatuses } from 'constants/therapy-administration-statuses';
import { useSelector } from 'react-redux';
import { IPatientData } from '../chart-review/interfaces/IPatientSection';

const usePatient = (onlyActiveTherapies = false): IPatientData => {
  const selectedPatient = useSelector((state: IState) => state.patient);
  const patientTherapies = useSelector((state: IState) => state.therapies.data);

  const id = selectedPatient?.id || null;
  const firstName = selectedPatient?.first_name ?? '';
  const lastName = selectedPatient?.last_name ?? '';
  const dateOfBirth = convertToArborDate(selectedPatient?.dob, true);
  const age = (dateOfBirth?.getCustomerAgeFormatted() as string) ?? '';
  const gender = selectedPatient?.gender ?? '';

  const lastUpdatedDate =
    convertToArborDate(selectedPatient?.updated)?.getCustomerDatetime(true) ?? '';

  const lastUpdatedUser = selectedPatient?.updated_by ?? '';

  const therapyValues = onlyActiveTherapies
    ? Object.values(patientTherapies).filter(
        x => x.administration_status_id === TherapyAdministrationStatuses.OnTherapy,
      )
    : Object.values(patientTherapies);

  const therapiesAndDiagnosis = therapyValues.reduce(
    (tadAccum: { therapies: string[]; diagnosis: string[] }, therapy: ITherapy) => {
      const curTherapy = `${therapy.drug_name} ${therapy.strength}${therapy.strength_unit_of_measure}`;
      const curDiagnosis = therapy.diagnosis_description;

      return {
        therapies: curTherapy ? [...tadAccum.therapies, curTherapy] : tadAccum.therapies,
        diagnosis: curDiagnosis ? [...tadAccum.diagnosis, curDiagnosis] : tadAccum.diagnosis,
      };
    },
    { therapies: [], diagnosis: [] },
  );

  return {
    id,
    firstName,
    lastName,
    age,
    gender,
    lastUpdatedDate,
    lastUpdatedUser,
    dateOfBirth: dateOfBirth?.getUtcDate(true) ?? '',
    therapies: therapiesAndDiagnosis.therapies,
    diagnosis: therapiesAndDiagnosis.diagnosis,
  };
};

export { usePatient };

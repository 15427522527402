/* eslint-disable react/function-component-definition */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { buildQaId } from 'utils/build-qa-id';
import { compose } from 'recompose';
import { InjectedFormProps, reduxForm, getFormValues } from 'redux-form';
import { getModalStyle } from 'services/utils/styles-service';
import { Grid, Modal, Typography } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';
import { cardExpirationValidation, length, required } from 'components/form/validation/validation';
import { EXPIRATION_DATE_FORMAT, PATIENT_RX_DELIVERY_PREFERENCES } from 'constants/index';
import { StringUtils } from 'utils/string-utils';
import { renderDropdown, renderNumberMaskedField } from 'components/form/field/redux-field';
import { connect } from 'react-redux';
import { IAddPaymentMethodFields } from 'containers/add-payment-method/types';
import { TypedField } from 'utils/types-util';
import { styles } from '../../components/add-address-modal/add-address-modal.styles';
import { useAddPaymentMethod } from './use-payment-methods';

const Field = TypedField<
  IAddPaymentMethodFields,
  {
    maskFormat?: any;
    allowEmptyFormatting?: boolean;
    fields?: { label: string; value: any }[];
    onValueChange?(x: any): void;
    maskCharacter?: string[];
  }
>();

interface IAddPaymentMethodModalProps
  extends WithStyles<typeof styles>,
    InjectedFormProps<IAddPaymentMethodFields> {
  initialValues: Partial<IAddPaymentMethodFields>;
  formValues: IAddPaymentMethodFields;
}
const qaIdBuilder = buildQaId('add-payment-method-modal');
const fourDigitCreditCardValidation = length(
  4,
  PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.ERRORS.LENGTH_4,
);

const AddPaymentMethodModal = ({
  classes,
  formValues,
  form,
  submitting,
  change,
  untouch,
  handleSubmit,
}: IAddPaymentMethodModalProps) => {
  const state = useAddPaymentMethod({ formName: form });
  return (
    <Modal open={state.isModalVisible} data-qa-id={qaIdBuilder('modal')}>
      <form data-qa-id={qaIdBuilder('form')}>
        <div style={getModalStyle()} className={classes.addModal}>
          {/* <Grid container>
            {errorMessage && (
              <Grid item xs={12} className={classes.row}>
                <Typography className={classes.error}>{errorMessage}</Typography>
              </Grid>
            )}
          </Grid> */}
          <Grid item xs={12} className={classes.row}>
            <Typography variant="h6" className={classes.heading}>
              Add New Payment Method
            </Typography>
          </Grid>
          <Grid container className={classes.row}>
            <Grid
              item
              xs={4}
              data-qa-id={PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.FIELDS.CARD_NUM}
            >
              <Field
                name="add_payment_method_card_number"
                validate={[required, fourDigitCreditCardValidation]}
                component={renderNumberMaskedField}
                maskFormat={PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.FIELD_MASK}
                label={PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.LABELS.LAST_4}
                allowEmptyFormatting
                onChange={(event: any) => {
                  event.preventDefault();
                }}
                onBlur={(event: any) => {
                  event.preventDefault();
                }}
                onValueChange={({ value }: any) => change('add_payment_method_card_number', value)}
              />
            </Grid>
            <Grid
              item
              xs={4}
              data-qa-id={PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.FIELDS.EXPIRATION}
            >
              <Field
                // eslint-disable-next-line max-len
                name="add_payment_method_expiration_date"
                label={`${PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.LABELS.EXPIRATION_DATE} *`}
                component={renderNumberMaskedField}
                placeholder={EXPIRATION_DATE_FORMAT}
                allowEmptyFormatting
                maskFormat="##/####"
                maskCharacter={['M', 'M', 'Y', 'Y', 'Y', 'Y']}
                validate={[cardExpirationValidation, required]}
                onChange={(event: any) => {
                  event.preventDefault();
                }}
                onBlur={(event: any) => {
                  event.preventDefault();
                }}
                onValueChange={({ formattedValue }: any) =>
                  change('add_payment_method_expiration_date', formattedValue)
                }
              />
            </Grid>
            <Grid
              item
              xs={4}
              data-qa-id={PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.FIELDS.TYPE}
            >
              <Field
                name="add_payment_method_type"
                validate={[required]}
                label={PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.LABELS.PAYMENT_TYPE}
                component={renderDropdown}
                fields={state.paymentMethodTypeOptions}
                onChange={() => {
                  change('add_payment_method_therapy_associated', null);
                  untouch('add_payment_method_therapy_associated');
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            {formValues && formValues.add_payment_method_type === 4 ? (
              <Grid item xs={4}>
                <Field
                  name="add_payment_method_therapy_associated_id"
                  validate={[required]}
                  label={PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.LABELS.THERAPY_ASSOCIATED}
                  component={renderDropdown}
                  fields={state.therapyAssociatedOptions}
                />
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <ConfirmationPanel
              handleSubmit={handleSubmit(state.onSubmit)}
              handleCancel={state.onCancel}
              disableSubmit={submitting}
            />
          </Grid>
        </div>
      </form>
    </Modal>
  );
};

const initialValues: IAddPaymentMethodFields = {
  add_payment_method_card_number: null,
  add_payment_method_type: null,
  add_payment_method_expiration_date: null,
  add_payment_method_therapy_associated_id: null,
};

export default compose<IAddPaymentMethodModalProps, {}>(
  withStyles(styles),
  reduxForm({ form: 'add-payment-method-modal', initialValues }),
  connect(state => ({ formValues: getFormValues('add-payment-method-modal')(state) })),
)(AddPaymentMethodModal);

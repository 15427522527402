import { AxiosResponse, AxiosPromise } from 'axios';
import HTTP from './http';

export class TypedHttp {
  public static async Get<TResponse>(
    url: string,
    params?: object,
  ): Promise<AxiosResponse<TResponse>> {
    const result = (await HTTP.get(url, { params })) as AxiosResponse<TResponse>;
    return result;
  }

  public static GetPromise<TResponse>(url: string, params?: object): AxiosPromise<TResponse> {
    return HTTP.get(url, { params }) as AxiosPromise<TResponse>;
  }

  public static async Post<TRequest, TResponse>(
    url: string,
    payload: TRequest,
  ): Promise<AxiosResponse<TResponse>> {
    const response = (await HTTP.post(url, payload)) as AxiosResponse<TResponse>;
    return response;
  }

  public static PostPromise<TRequest, TResponse>(
    url: string,
    payload: TRequest,
  ): AxiosPromise<TResponse> {
    return HTTP.post(url, payload) as AxiosPromise<TResponse>;
  }

  public static PutPromise<TRequest, TResponse>(
    url: string,
    payload: TRequest,
  ): AxiosPromise<TResponse> {
    return HTTP.put(url, payload) as AxiosPromise<TResponse>;
  }

  public static PatchPromise<TRequest, TResponse>(
    url: string,
    payload: TRequest,
  ): AxiosPromise<TResponse> {
    return HTTP.patch(url, payload) as AxiosPromise<TResponse>;
  }

  public static DeletePromise<TResponse>(url: string): AxiosPromise<TResponse> {
    return HTTP.delete(url) as AxiosPromise<TResponse>;
  }
}

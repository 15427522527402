import React from 'react';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { taskStatusCheckboxes, noteTagTypes } from 'constants/lists';
import { getStatusCategoryByStatusId } from 'services/utils/task-service';
import { getDisplayNotes } from 'services/utils/note-service';
import { getDocumentsForResource } from 'services/utils/document-service';
import CslDetail from 'containers/tasks/counseling/counseling-detail';
import DcDetail from 'containers/tasks/data-collect/data-collect-detail';
import PaDetail from 'containers/tasks/pa/pa-detail';
import FaDetail from 'containers/tasks/fa/fa-detail';
import FdcDetail from 'containers/tasks/fill-delivery-confirmation/fill-delivery-confirmation-detail';
import OrDetail from 'containers/tasks/outreach/outreach-detail';
import IntDetail from 'containers/tasks/interventions/intervention-detail';
import TprDetail from 'containers/tasks/third-party-referral/third-party-referral-detail';
import FcDetail from 'containers/tasks/fill-coordination/fill-coordination-detail';
import QreDetail from 'containers/tasks/incidents/incident-detail';
import ErrorBoundary from 'containers/shared/error/error-boundary';
import TaskContainer from '../../../tasks/task-container';
import { styles as taskStyles } from '../../../tasks/task-detail-styles';

type ITagTypes = Record<string, { value: number; displayLabel: string }>;

const noteTagTypeLookup: ITagTypes = noteTagTypes.reduce((result, tag) => {
  result[tag.label] = { value: tag.value, displayLabel: tag.displayLabel };
  return result;
}, {} as ITagTypes);

const Noop: React.FC = () => null;
const getTaskComponent = (props: { taskType?: string; [key: string]: any }) => {
  const taskType = props.taskType ?? props.task.taskType;
  return taskType ? (
    (
      {
        DC: <DcDetail {...props} />,
        PA: <PaDetail {...props} />,
        FA: <FaDetail {...props} />,
        OR: <OrDetail {...props} />,
        INT: <IntDetail {...props} />,
        TPR: <TprDetail {...props} />,
        FC: <FcDetail {...props} />,
        CSL: <CslDetail {...props} />,
        QRE: <QreDetail {...props} />,
        FDC: <FdcDetail {...props} />,
      } as { [key: string]: any }
    )[taskType]
  ) : (
    <Noop />
  );
};

export const ArchivedTaskRenderer = (props: any): JSX.Element | null => {
  const { task, therapy } = props;
  const { taskType } = task;
  const patient = useTypedSelector(state => state.patient);
  const statuses: Record<string, any> = useTypedSelector(state => state.taskStatuses.statuses);
  const allPinnedNotes = useTypedSelector(state => state.notes?.notes?.allPinnedNotes ?? []);
  const allNonPinnedNotes = useTypedSelector(state => state.notes?.notes?.allNonPinnedNotes ?? []);
  const users = useTypedSelector(state => state.lookups.users);
  const serviceGroups = useTypedSelector(state => state.lookups.serviceGroups);
  const documents = useTypedSelector(state => state.uploadedDocuments || []);
  const therapyIndex = 0;

  const noteDisplayCommonProps = {
    patientId: patient.id,
    isInContext: true,
    containerClasses: taskStyles,
    maxLine: '1',
  };

  const localStatuses = statuses[(task.taskType || '').toLowerCase()];
  if (!localStatuses || !localStatuses.length) {
    return null;
  }
  if (
    !taskStatusCheckboxes.some(
      s => s.text === getStatusCategoryByStatusId(task.status_id, localStatuses),
    )
  ) {
    return null;
  }
  const { value: typeId, displayLabel: typeLabel } = noteTagTypeLookup[task.taskType] ?? {};
  if (!typeId || !typeLabel) {
    return null;
  }

  const displayNotes = getDisplayNotes(
    allPinnedNotes,
    allNonPinnedNotes,
    typeId,
    task.id,
    users,
    serviceGroups,
    2,
  );

  const displayDocuments = getDocumentsForResource(
    documents,
    [{ tagTypeId: typeId, resourceId: task.id }],
    1,
  );

  const notesWithProps = {
    therapy,
    tagName: taskType,
    tagTypeId: typeId,
    notes: displayNotes,
    ...noteDisplayCommonProps,
    patient,
  };
  const forcedProps = {
    task: task,
    tagTypeId: typeId,
    tagTypeLabel: typeLabel,
    statuses: localStatuses,
    therapy: therapy,
    update: true,
    notes: notesWithProps,
    documents: displayDocuments,
    handleCancel: props.handleCancel,
    displayEdit: props.displayEdit,
    serviceGroups: serviceGroups,
    defaultServiceGroup: therapy?.service_group_id,
    taskIDPrefix: `${therapyIndex}_${taskType}_${task.id}`,
    drugName: therapy?.drug_name,
    patient: patient,
  };

  return (
    <ErrorBoundary>
      <TaskContainer
        task={task}
        therapy={therapy}
        statuses={statuses}
        archived
        therapyTaskid={`${therapyIndex}_therapyTask_${task.taskType}_${task.id}`}
        allowEdition
        renderChildren={(innerProps: any = {}) =>
          getTaskComponent({ taskType, ...forcedProps, ...innerProps })
        }
      />
    </ErrorBoundary>
  );
};

import { AxiosResponse } from 'axios';
import moment from 'moment';
import HTTP from '../http';
import { DailyForecastAPIResponse, OpenWeatherCondition } from '../../interfaces/weather/IWeather';
import { IWeatherView, IWeatherItemView } from '../../interfaces/weather/IWeatherView';

interface IWeatherRequest {
  zip: string;
  count: number;
}

export class WeatherService {
  getWeatherRequest = (
    zip: string,
    count: number,
  ): {
    url: string;
    payload: Partial<IWeatherRequest>;
  } => {
    const url = `/weather?zip=${zip}&count=${count}`;
    const payload = { zip: zip, count: count };
    return { url, payload: payload };
  };

  mapWeatherView = (response: DailyForecastAPIResponse, date: Date): IWeatherView => {
    const lastDate =
      response.forecast.length > 0
        ? response.forecast[response.forecast.length - 1].date
        : new Date();
    const forecast: IWeatherItemView[] = response.forecast
      .filter(day => new Date(day.date) >= date)
      .slice(0, 7)
      .map(day => {
        return {
          weekDate: moment(day.date).format('ddd').toUpperCase(),
          date: moment(day.date).format('MMM DD').toUpperCase(),
          temp: `${parseInt(day.maxTemp.toString())}&#176;F / ${parseInt(
            day.minTemp.toString(),
            10,
          )}&#176;F`,
          icon: day.weatherCondition,
        };
      });
    if (forecast.length < 7) {
      const daysToAdd = 7 - forecast.length;
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= daysToAdd; i++) {
        const currentDate = moment(lastDate).add(i, 'days');
        forecast.push({
          weekDate: moment(currentDate).format('ddd').toUpperCase(),
          date: moment(currentDate).format('MMM DD').toUpperCase(),
          temp: 'No Data',
          icon: OpenWeatherCondition.NoData,
        });
      }
    }
    const weatherView = {
      city: response.city,
      forecast: forecast,
    };
    return weatherView;
  };

  // TODO: return a promise rejection if the zip isn't a number and handle that use case in usage
  public getResponse = async (zip: string, count: number, date: Date): Promise<IWeatherView> => {
    let weatherView: IWeatherView = { city: undefined, forecast: [] };
    if (Number.isInteger(parseInt(zip, 10))) {
      const requestDetails = this.getWeatherRequest(zip, count);
      const response = (await HTTP.get(
        requestDetails.url,
        requestDetails.payload,
      )) as AxiosResponse<DailyForecastAPIResponse>;
      weatherView = this.mapWeatherView(response.data, date);
    }
    return weatherView;
  };
}

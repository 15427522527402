import React, { useState } from 'react';
import compose from 'recompose/compose';
import { set as lsSet } from 'local-storage';
import {
  FormControl,
  Grid,
  MenuList,
  MenuItem,
  Typography,
  Button,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
} from '@mui/material';
import { windowFeatureIsEnabled } from 'config/window-features';
import withStyles from '@mui/styles/withStyles';
import { DropdownIcon } from 'components/icons/icons';
import { ROUTES } from 'constants/index';
import { Link, Switch, Route } from 'react-router-dom';
import { styles } from './dropdown-styles';

const UserDropdown = props => {
  const { classes } = props;

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
    setOpen(!open);
  };

  return (
    <form autoComplete="off">
      <FormControl className={classes.formControl}>
        <Grid container direction="row">
          <Grid item>
            <Grid container direction="column" alignItems="flex-end">
              <Typography variant="caption" className={classes.label}>
                User
              </Typography>
              <Typography variant="body2">
                {window.USER ? window.USER.username : 'Loading ...'}
              </Typography>
            </Grid>
          </Grid>

          <Grid item>
            <Button
              className={classes.dropdownButton}
              aria-owns={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <DropdownIcon />
            </Button>
            <Popper open={open} anchorEl={anchorEl} transition disablePortal placement="bottom-end">
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={() => {
                        setOpen(false);
                      }}
                    >
                      <MenuList>
                        <MenuItem
                          onClick={() => {
                            setOpen(false);
                            window.open(
                              // eslint-disable-next-line max-len
                              'https://trellisrx.atlassian.net/servicedesk/customer/portal/3/group/10',
                            );
                          }}
                        >
                          <Typography className={classes.listItem}>Submit a ticket</Typography>
                        </MenuItem>

                        <Switch>
                          <Route path={ROUTES.APPLICATION_MANAGER}>
                            <MenuItem component={Link} to="/" onClick={() => setOpen(!open)}>
                              <Typography className={classes.listItem}>Arbor</Typography>
                            </MenuItem>
                          </Route>
                          <Route path="/">
                            <MenuItem
                              component={Link}
                              to={`${ROUTES.APPLICATION_MANAGER}/gtd/global-gtd`}
                              onClick={() => setOpen(!open)}
                            >
                              <Typography className={classes.listItem}>
                                Application Manager
                              </Typography>
                            </MenuItem>
                          </Route>
                        </Switch>

                        <MenuItem
                          onClick={() => {
                            lsSet('logout_signal', true);
                          }}
                        >
                          <Typography className={classes.listItem}>Logout</Typography>
                        </MenuItem>
                        {windowFeatureIsEnabled('user_guide') && (
                          <MenuItem
                            onClick={() => {
                              window.open('/user-guide/', '_blank');
                            }}
                          >
                            <Typography className={classes.listItem}>User Guide</Typography>
                          </MenuItem>
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
        </Grid>
      </FormControl>
    </form>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(UserDropdown);

import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) => {
  const colorPalette = theme.palette.primary as any;
  const fonts = (theme as any).font;

  return createStyles({
    formTitleHeaderWrapper: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    formTitleHeaderWrapperItem1: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexGrow: 5,
      fontWeight: 'bold',
      fontSize: 'large',
      paddingTop: theme.spacing(1.25),
    },
    formTitleHeaderWrapperItem2: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 0.3,
      fontSize: fonts.mediumFontSize,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    formTitleHeaderWrapperDivider: {
      marginLeft: 16,
      marginRight: 16,
      height: '2px',
    },
    chip: {
      padding: theme.spacing(1),
      fontWeight: 'bold',
      fontSize: fonts.mediumFontSize,
      marginLeft: theme.spacing(2.25),
    },
    pillIcon: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginBottom: 15,
    },
    dosisRegimenText: {
      marginLeft: 5,
      fontSize: fonts.smallFontSize,
    },
  });
};

export { styles };

import HTTP from 'services/http';
import moment from 'moment';
import React from 'react';
import { ArborCheckbox } from 'components/arbor-checkbox/arbor-checkbox';
import { AxiosPromise } from 'axios';
import { BaseTable } from 'containers/application-manager/base-table/base-table';
import { Button, CircularProgress, Grid, Modal, Popover, Typography } from '@mui/material';
import { ButtonActiveWhen } from 'containers/application-manager/base-table/types';
import { datetimeFormat } from 'models/time/arbor-date';
import { getModalStyle } from 'services/utils/styles-service';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { useDispatch } from 'react-redux';
import { UserPermissions } from 'interfaces/user/Permissions';
import { UserUtils } from 'utils/user-utils';
import { FieldTypes } from 'containers/application-manager/types';
import { dateConvert, utcMomentOrUndefined, booleanConvert } from '../../../utils';
import { IModalItem, IModalItemChecked, IPopoverStateBase } from '../../types';
import { FormField } from '../../../popover-field-forms/index';
import { IActiveAPIResponse, IChild, IResult } from './types';
import { UseStyles } from './active-table.styles';
import {
  convertToFlatModalItems,
  therapeuticClassConvert,
  toggleModalChecked,
  updateItemRequest,
} from '../../utils';

import { AddNewProductModalStyled } from './add-new-product-modal/add-new-product-modal';

const baseUrl = '/application-manager/gtd';
const getUrl = `${baseUrl}/active`;

const childPkSelector = (child: IChild) => child.drug_descriptor_id;
const parentPkSelector = (parent: IResult) => parent.drug_descriptor_id;
const modalLabelFormatter = (child: IChild) => `${child.drug_name} ${child.strength}`;

interface IActiveTableProps {
  searchTerm: string | undefined;
}

interface IExcludeModalState {
  open: boolean;
  submitting?: boolean;
  header?: string;
  items?: IModalItem[];
}

type IPopoverState = IPopoverStateBase<IResult, IChild>;

const initialExcludeModalState: IExcludeModalState = {
  open: false,
};

const defaultOpenState: Pick<IExcludeModalState, 'open' | 'header'> = {
  open: true,
  header: 'You are excluding:',
};

const initialPopoverState: IPopoverState = {
  open: false,
};

const excludeItemsRequest = (items: IModalItem[]): AxiosPromise<unknown> => {
  const requestBody = items.map(item => {
    return {
      parent: true,
      drugDescriptorId: item.childPk,
      currentlyActive: true,
      fieldsToUpdate: {
        active: false,
        end_date: moment.utc().format(datetimeFormat),
      },
    };
  });

  return HTTP.patch(baseUrl, requestBody);
};

export const ActiveTable: React.FC<IActiveTableProps> = (props): JSX.Element => {
  const classes: any = UseStyles();
  const dispatch = useDispatch();

  // #region component state
  const [excludeModalState, setExcludeModalState] = React.useState<IExcludeModalState>(
    initialExcludeModalState,
  );
  const [modalItemCheckedTrigger, setModalItemCheckedTrigger] = React.useState<
    IModalItemChecked | undefined
  >(undefined);
  const [reloadDataTrigger, setReloadDataTrigger] = React.useState<Date | undefined>(undefined);
  const [popoverState, setPopoverState] = React.useState<IPopoverState>(initialPopoverState);
  const [showAddNewProduct, setShowAddNewProduct] = React.useState<boolean>(false);
  // #endregion

  // #region helpers
  const afterRequestSuccess = (callbacks: (() => void)[] | undefined = undefined) => {
    dispatch(notifySuccess('Saved'));
    setReloadDataTrigger(new Date());
    if (callbacks) {
      callbacks.forEach(cb => cb());
    }
  };

  const onSubmitExclude = async () => {
    try {
      const items = excludeModalState.items?.filter(x => x.checked) || [];

      const request = excludeItemsRequest(items);
      await request;

      afterRequestSuccess([() => setExcludeModalState(initialExcludeModalState)]);
    } catch (_error) {
      dispatch(notifyError('An error occurred'));
    }
  };

  const onSubmitFieldUpdate = async (value: unknown) => {
    try {
      const request = updateItemRequest<IResult, IChild>(
        popoverState,
        value,
        childPkSelector,
        parentPkSelector,
        true,
      );
      await request;

      afterRequestSuccess([() => setPopoverState(initialPopoverState)]);
    } catch {
      dispatch(notifyError('An error occurred'));
    }
  };

  const convertToModalItems = (items: IResult[]): IModalItem[] => {
    const modalItems = convertToFlatModalItems<IResult, IChild>(
      items,
      parentPkSelector,
      childPkSelector,
      modalLabelFormatter,
    );
    return modalItems;
  };

  const toggleCheck = (checked: boolean, item: IModalItem): void => {
    toggleModalChecked(
      checked,
      item,
      excludeModalState,
      setExcludeModalState,
      setModalItemCheckedTrigger,
    );
  };

  const openPopover = (
    element: HTMLElement,
    value: unknown,
    config: {
      parent?: IResult;
      child?: IChild;
      fieldType: FieldTypes;
      field: keyof IChild | keyof IResult;
      forceAsParent?: boolean;
    },
  ): void => {
    if (config.parent != null && config.child != null) {
      throw new Error('Cannot select both a parent and child cell. Either one or the other');
    }

    setPopoverState({
      open: true,
      anchorElement: element,
      parent: config.parent,
      child: config.child,
      field: config.field,
      fieldType: config.fieldType,
      value: value,
      forceAsParent: config.forceAsParent,
    });
  };
  // #endregion

  // #region renders
  const renderExcludeModal = (): JSX.Element => {
    return (
      <Modal open={excludeModalState.open}>
        <div style={getModalStyle()} className={classes.confirmationModal}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs="auto">
              <Typography className={classes.modalHeader}>{excludeModalState.header}</Typography>
            </Grid>
            <Grid item xs="auto" className={classes.confirmationModalDrugItem}>
              {excludeModalState.items?.map(item => {
                return (
                  <Typography className={classes.modalDrugRow}>
                    <ArborCheckbox
                      checked={item.checked}
                      onChange={event => {
                        toggleCheck(event.target.checked, item);
                      }}
                    />
                    {item.label}
                  </Typography>
                );
              })}
            </Grid>
            <Grid item xs="auto">
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item xs="auto">
                  <Button
                    onClick={() => {
                      setExcludeModalState(initialExcludeModalState);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs="auto">
                  <Button
                    variant="contained"
                    className={classes.actionButton}
                    onClick={() => {
                      onSubmitExclude();
                    }}
                    disabled={
                      excludeModalState.submitting ||
                      excludeModalState.items == null ||
                      excludeModalState.items.every(x => !x.checked)
                    }
                  >
                    Confirm
                    {excludeModalState?.submitting ? (
                      <CircularProgress
                        variant="indeterminate"
                        size={20}
                        className={classes.submittingIndicator}
                      />
                    ) : null}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal>
    );
  };

  const renderPopover = (): JSX.Element => {
    const handleCancel = () => setPopoverState(initialPopoverState);
    const handleSubmit = (value: unknown) => onSubmitFieldUpdate(value);

    return (
      <Popover
        id="form"
        open={popoverState.open}
        anchorEl={popoverState?.anchorElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transitionDuration={{ exit: 0 }}
        onClose={() => setPopoverState(initialPopoverState)}
      >
        <FormField
          initialValue={popoverState.value}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          fieldType={popoverState.fieldType}
        />
      </Popover>
    );
  };

  return (
    <>
      {renderExcludeModal()}
      <AddNewProductModalStyled
        open={showAddNewProduct}
        onCancel={() => setShowAddNewProduct(false)}
        reloadTrigger={setReloadDataTrigger}
      />
      {renderPopover()}
      <BaseTable<IActiveAPIResponse, IResult, IChild>
        actionsPermitted={UserUtils.userIsPermitted(UserPermissions.ApplicationManagerGtdEdit)}
        enableParentCheckboxes={UserUtils.userIsPermitted(
          UserPermissions.ApplicationManagerGtdEdit,
        )}
        enableChildCheckboxes={UserUtils.userIsPermitted(UserPermissions.ApplicationManagerGtdEdit)}
        orderByDefaultChild="drug_name"
        endpointBase={getUrl}
        paginationQueryParamSettings={{
          pageSizeQueryStringKey: 'pageSize',
          pageNumberQueryStringKey: 'pageNumber',
          searchTermQueryStringKey: 'searchTerm',
          sortPropQueryStringKey: 'sortProp',
          sortOrderQueryStringKey: 'sortOrder',
        }}
        childPkSelector={childPkSelector}
        parentPkSelector={parentPkSelector}
        columnSettings={[
          {
            label: 'Drug Name',
            serverSideSearchField: 'drug_name',
            parentKey: 'drug_name',
            childKey: 'drug_name',
            sortable: true,
          },
          {
            label: 'GPI 10',
            serverSideSearchField: 'gpi_10',
            parentKey: 'gpi_10',
            childKey: 'gpi_10',
            sortable: true,
          },
          {
            label: 'Strength',
            childKey: 'strength',
            sortable: true,
          },
          {
            label: 'Dosage Form',
            childKey: 'dosage',
            sortable: true,
          },
          {
            label: 'Specialty Type',
            childKey: 'specialty_type',
            parentKey: 'specialty_type',
            sortable: true,
            parentCellClick: (event, parent) => {
              openPopover(event.currentTarget, parent.specialty_type, {
                parent: parent,
                field: 'specialty_type',
                fieldType: FieldTypes.SpecialityType,
              });
            },
          },
          {
            label: 'Therapeutic Class',
            childKey: 'trellis_therapeutic_class_id',
            parentKey: 'trellis_therapeutic_class_id',
            sortable: true,
            childValueFormatter: therapeuticClassConvert,
            parentCellClick: (event, parent) => {
              openPopover(event.currentTarget, parent.trellis_therapeutic_class_id, {
                parent: parent,
                field: 'trellis_therapeutic_class_id',
                fieldType: FieldTypes.TherapeuticClass,
              });
            },
          },
          {
            label: 'Is LDD?',
            childKey: 'is_ldd',
            sortable: true,
            childValueFormatter: booleanConvert,
            childCellClick: (event, _parent, child) => {
              openPopover(event.currentTarget, !!child.is_ldd, {
                child: child,
                field: 'is_ldd',
                fieldType: FieldTypes.YesNo,
              });
            },
          },
          {
            label: 'Is Orphan?',
            childKey: 'is_orphan',
            sortable: true,
            childValueFormatter: booleanConvert,
            childCellClick: (event, _parent, child) => {
              openPopover(event.currentTarget, !!child.is_orphan, {
                child: child,
                field: 'is_orphan',
                fieldType: FieldTypes.YesNo,
              });
            },
          },
          {
            label: 'Available Date',
            childKey: 'available_date',
            sortable: true,
            childValueFormatter: dateConvert,
            childCellClick: (event, _parent, child) => {
              openPopover(event.currentTarget, utcMomentOrUndefined(child.available_date), {
                child: child,
                field: 'available_date',
                fieldType: FieldTypes.DatePicker,
              });
            },
          },
          {
            label: 'End Date',
            childKey: 'end_date',
            sortable: true,
            childValueFormatter: dateConvert,
            childCellClick: (event, _parent, child) => {
              openPopover(event.currentTarget, utcMomentOrUndefined(child.end_date), {
                child: child,
                field: 'end_date',
                fieldType: FieldTypes.DatePicker,
              });
            },
          },
        ]}
        searchTerm={props.searchTerm}
        triggerExternalCheckUpdate={modalItemCheckedTrigger}
        triggerReload={reloadDataTrigger}
        rightActionButtons={[
          {
            key: 'exclude',
            label: 'Exclude',
            activeWhen: ButtonActiveWhen.SomeSelected,
            onClick: (checkedItems: IResult[]) => {
              setExcludeModalState({
                ...defaultOpenState,
                items: convertToModalItems(checkedItems),
              });
            },
          },
          {
            key: 'addNewProduct',
            label: 'Add New Product',
            activeWhen: ButtonActiveWhen.Always,
            onClick: () => {
              setShowAddNewProduct(true);
            },
          },
        ]}
      />
    </>
  );
};

import { AxiosPromise } from 'axios';
import { TypedHttp } from 'services/typed-http';
import { ITask } from 'interfaces/redux/ITasks';
import { CATEGORY_ARCHIVED, CATEGORY_DONE } from 'constants/index';

const buildUri = (patientId: number) => `/patients/${patientId}`;

interface ITaskQueryResponse {
  tasks: ITask[];
}

export class TasksArchived {
  static getTherapyArchivedTasks(
    patientId: number,
    therapyIds: number[],
    arrTaskTypes: string[],
  ): AxiosPromise<ITaskQueryResponse> {
    const therapyIdsString = therapyIds.join(',');
    let url = therapyIds.length
      ? `${buildUri(
          patientId,
        )}/therapies/${therapyIdsString}/tasks?categories=${CATEGORY_DONE},${CATEGORY_ARCHIVED}`
      : '';

    if (url && arrTaskTypes.length > 0) {
      url = `${url}&task_types=${arrTaskTypes.join(',')}`;
    }
    return TypedHttp.GetPromise<ITaskQueryResponse>(url);
  }

  static getPatientArchivedTasks(
    patientId: number,
    arrTaskTypes: string[],
  ): AxiosPromise<ITaskQueryResponse> {
    const categories = `${CATEGORY_DONE},${CATEGORY_ARCHIVED}`;
    let url = `${buildUri(patientId)}/tasks?categories=${categories}`;

    if (url && arrTaskTypes && arrTaskTypes.length) {
      url = `${url}&task_types=${arrTaskTypes.join(',')}`;
    }

    return TypedHttp.GetPromise<ITaskQueryResponse>(url);
  }
}

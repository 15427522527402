import React, { useEffect, useState } from 'react';
import { Grid, TextField, Button } from '@mui/material';

import { ApplicationManagerClient } from 'clients/application-manager-client';
import { nameOfFactory } from 'utils/types-util';
import { buildQaId } from 'utils/build-qa-id';
import AutoCompleteMiniGrid from 'components/form/field/render-minigrid-autocomplete';
import {
  IGpiDrugs,
  IGpiProtocolRequest,
} from 'models/application-manager/cpmp/IGetGpiProtocolsResponse';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { getClinicalDataTypesMap } from 'services/utils/data-collect';
import { IDataType } from 'interfaces/redux/IDataType';
import FormFrequencyItem from './GpiFormFrequencyItem';
import {
  IFormFields,
  IFormItemsErrors,
  IGpiFormFrequencyItem,
  IGpiProtocolFormProps,
  IItemsType,
} from '../types';
import { styles } from '../styles';

const getQaId = buildQaId('application-manager.add-new-gpi-modal-form', '.');
const getFieldName = nameOfFactory<IFormFields>();

const requiredErrorMsg = 'Required';
const fetchDrugList = async (searchTerm: string): Promise<IGpiDrugs[]> => {
  try {
    const { data } = await ApplicationManagerClient.fetchGpiDrugs(searchTerm);
    if (data.success) {
      return data.results;
    }
  } catch (e) {
    console.error(e);
  }
  return [];
};

const protocolFrequencies = [
  { label: 'Baseline', value: 'baseline' },
  { label: 'Ongoing', value: 'ongoing' },
  { label: 'Scheduled', value: 'scheduled' },
  { label: 'Suggested', value: 'suggested' },
];
const uniqueProtocolFrequencies = ['baseline', 'suggested'];
const miniGridLabel = 'Brand Name *';
const placeholder = 'Search by Brand Name, Drug Name or GPI-10';
let protocolId = 0;

const GpiProtocolForm: React.FC<IGpiProtocolFormProps> = ({
  onChange,
  errors,
  edit,
  gpiProtocol,
}) => {
  const classes = styles();
  const clinicalDataTypesData = useTypedSelector(state => state.lookups.clinicalDataTypes);
  const clinicalDataType: Record<number, IDataType> =
    getClinicalDataTypesMap(clinicalDataTypesData);
  const [drug, setDrug] = useState<IGpiDrugs>();
  const [items, setItems] = useState<Record<string, IGpiFormFrequencyItem>>({
    baseline: {
      prevFrequency: 'baseline',
      protocolFrequency: 'baseline',
      dcItems: [],
    },
  });
  const usedFrequencies = Object.values(items).map(item => item.protocolFrequency);
  const dropdownFrequencies = protocolFrequencies.filter(
    item =>
      !uniqueProtocolFrequencies.includes(item.value) || !usedFrequencies.includes(item.value),
  );
  const handleOnDrugChange = (value: IGpiDrugs) => {
    setDrug(value);
  };
  const handleOnFrequencyItemChange = (
    frequencyItemId: string,
    frequencyItem: IGpiFormFrequencyItem,
  ): void => {
    setItems({
      ...items,
      [frequencyItemId]: frequencyItem,
    });
  };

  const handleAddFrequency = (): void => {
    const [, prot] = protocolFrequencies;
    const frequencyId = `${prot.value}-${(protocolId += 1)}`;
    setItems({
      ...items,
      [frequencyId]: {
        prevFrequency: prot.value,
        protocolFrequency: prot.value,
        dcItems: [],
      },
    });
  };

  const handleDeleteFrequency = (frequency: string): void => {
    const updatedItems = { ...items };
    delete updatedItems[frequency];
    setItems(updatedItems);
  };

  const handleOnChange = (): void => {
    const protocol = {
      gpi_10: drug?.gpi_10,
      items: Object.values(items).map(item => ({
        protocolFrequency: item.protocolFrequency,
        frequencyUnit: item.frequencyUnit,
        frequencyType: item.frequencyType,
        dcItems: (item.dcItems || []).map(dcItem =>
          typeof dcItem === 'object' ? Number(dcItem.value) : dcItem,
        ),
      })),
    };
    onChange(protocol as unknown as IGpiProtocolRequest);
  };

  const handleSetEditGpiProtocol = (): void => {
    if (gpiProtocol) {
      setDrug({
        gpi_10: gpiProtocol.gpi_10,
        drugName: gpiProtocol.drugName,
        medicationType: gpiProtocol.medicationType,
      });
      const gpiItems = gpiProtocol.items.reduce((its, curr) => {
        const frequencyId = uniqueProtocolFrequencies.includes(curr.protocolFrequency)
          ? curr.protocolFrequency
          : `${curr.protocolFrequency}-${(protocolId += 1)}`;
        return {
          ...its,
          [frequencyId]: {
            protocolFrequency: curr.protocolFrequency,
            frequencyUnit: curr.frequencyUnit,
            frequencyType: curr.frequencyType,
            dcItems: curr.dcItems,
          },
        };
      }, {});
      setItems(gpiItems);
    }
  };

  useEffect(() => {
    if (edit && gpiProtocol && !drug) {
      handleSetEditGpiProtocol();
    }
  }, [drug, gpiProtocol]);

  useEffect(() => {
    handleOnChange();
  }, [items]);

  const renderDrugInfo = () => (
    <Grid container spacing={4}>
      {edit ? (
        <></>
      ) : (
        <Grid item xs={4}>
          <AutoCompleteMiniGrid
            name={getFieldName('medicationType')}
            label={miniGridLabel}
            labelField="medicationType"
            placeholder={placeholder}
            fetchOptions={fetchDrugList}
            columnsToShow={{
              drugName: 'Drug Name',
              gpi_10: 'GPI',
              medicationType: 'MedicationType',
            }}
            input={{
              onChange: handleOnDrugChange,
              value: drug?.medicationType ? { medicationType: drug.medicationType } : '',
            }}
            onChange={handleOnDrugChange}
            meta={{ touched: errors.drug, error: errors.drug && requiredErrorMsg }}
          />
        </Grid>
      )}
      <Grid item xs={4}>
        <TextField
          variant="standard"
          label="Drug Name"
          value={drug?.drugName || ''}
          // data-qa-id={dataQaId}
          disabled
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          variant="standard"
          label="GPI-10"
          value={drug?.gpi_10 || ''}
          // data-qa-id={dataQaId}
          disabled
          fullWidth
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid item xs="auto" data-qa-id={getQaId('form')}>
      <Grid container spacing={1}>
        {renderDrugInfo()}
      </Grid>
      {Object.keys(items).map(frequencyItemId => {
        const frequencyItem = items[frequencyItemId];
        return (
          <FormFrequencyItem
            key={frequencyItemId}
            frequencyId={frequencyItemId}
            frequencyItem={frequencyItem}
            clinicalDataTypes={clinicalDataType}
            protocolFrequencies={protocolFrequencies}
            frequencies={dropdownFrequencies}
            errors={errors.items[frequencyItem.protocolFrequency as IItemsType] as IFormItemsErrors}
            single={Object.keys(items).length === 1}
            onChange={handleOnFrequencyItemChange}
            onRemove={handleDeleteFrequency}
          />
        );
      })}
      <Grid container spacing={1} className={classes.addFrequencyButton}>
        <Button onClick={handleAddFrequency}>+ ADD FREQUENCY</Button>
      </Grid>
    </Grid>
  );
};

export default GpiProtocolForm;

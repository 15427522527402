import React, { useEffect } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { PatientMergeStyles } from 'components/patient-merge-modal/styles';
import { IPatientProfile } from 'components/patient-merge-modal/interfaces';
import { useSelector } from 'react-redux';
import { IState } from 'interfaces/redux/IState';
import { setPrimary } from 'actions/action-patient-merge';
import { parseCardPatientNameLabel, parseLabel } from 'components/patient-merge-modal/utils';
import { PatientGenderLabel } from 'components/patient-merge-modal/info-component/patient-gender-label';
import { useAppDispatch } from 'hooks/useAppDispatch';

export const SelectPrimaryProfile = (props: any) => {
  const { profiles, goToNextStep, setNextButtonEnabled } = props;
  const theme = useTheme();
  const classes = PatientMergeStyles(theme);

  const primaryId = useSelector((state: IState) => state.patientMerge.primary);

  const dispatch = useAppDispatch();

  const handlePrimaryChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.value) {
      return;
    }

    const patientId = Number(e?.target?.value);
    dispatch(setPrimary(patientId));
  };

  useEffect(() => {
    setNextButtonEnabled(!!primaryId);
  }, [primaryId]);

  return (
    <>
      <FormControl>
        <RadioGroup
          name="profile-radio-select"
          value={primaryId}
          onChange={e => handlePrimaryChange(e)}
        >
          {profiles.map((profile: IPatientProfile) => {
            return (
              <Grid
                key={`profile-item-${profile.patientId}`}
                display="inline-flex"
                alignItems="center"
              >
                <FormControlLabel
                  key={profile.patientId}
                  value={profile.patientId}
                  control={<Radio disabled={!!profile.is_manually_created} />}
                  label={parseLabel(profile)}
                />
              </Grid>
            );
          })}
        </RadioGroup>
      </FormControl>
    </>
  );
};

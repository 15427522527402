import {
  AUDIT_NOT_RESOLVED,
  AUDIT_DEMOGRAPHICS,
  AUDIT_INB,
  AUDIT_ALLERGIES,
  AUDIT_THERAPIES,
  THERAPY_ADMIN_STATUS_PRE_THERAPY,
  THERAPY_ADMIN_STATUS_ON_HOLD,
  ENSURE_THERAPY_MUST_MOVE_TO_UPDATE_STATUS,
  THERAPY_SHOULD_NOT_BE_ON_ON_HOLD_FOR_TOO_LONG,
  SERVICE_ENROLLMENT_SHOULD_BE_SET,
  ENSURE_PATIENT_INFORMATION_VERIFIED,
  THERAPY_SHOULD_HAVE_START_DATE,
  THERAPY_START_DATE_MUST_BE_AFTER_REFERRAL_DATE,
  ENSURE_MEDICATION_LIST_VERIFIED,
  ENSURE_EMERGENCY_CONTACT_ADDRESSED,
  ENSURE_SMS_ADDRESSED,
  ENSURE_EMAIL_ADDRESSED,
  ENSURE_PBM_INSURANCE_ADDRESSED,
  ENSURE_MEDICAL_INSURANCE_ADDRESSED,
  ENSURE_ALLERGIES_VERIFIED,
} from 'constants/index';
import { auditCategories, auditContext, auditResolutionStates } from 'constants/lists';
import { isDispensingUndecided, isClinicalSupportUndecided } from 'constants/enums';
import { TherapyAdministrationStatuses } from 'constants/therapy-administration-statuses';

import { StringUtils } from 'utils/string-utils';

export const existsAuditsOfStatus = (auditsArr, status = AUDIT_NOT_RESOLVED) => {
  if (auditsArr) {
    return auditsArr.some(a => a.resolution_state.name === status);
  }
  return false;
};

export const doesPatientHaveUnresolvedAudits = (auditState, selectedPatientId, auditPatientId) => {
  if (auditPatientId && auditPatientId === selectedPatientId) {
    const auditKeys = Object.keys(auditState);
    const retCategories = auditKeys.filter(auditCategory =>
      existsAuditsOfStatus(auditState[auditCategory], AUDIT_NOT_RESOLVED),
    );
    if (retCategories && retCategories.length > 0) {
      return StringUtils.oxfordCommaConcatenate(retCategories);
    }
  }
  return 'patient';
};

export const findAuditByCategoryRuleAndStatus = (
  auditState,
  patient,
  category,
  context,
  rule,
  status,
  resource,
  resourceValue,
) => {
  if (context === auditContext.Tab) {
    return existsAuditsOfStatus(auditState[category], status);
  }
  if (context === auditContext.Sidebar) {
    return doesPatientHaveUnresolvedAudits(auditState);
  }
  if (auditState && auditState[category]) {
    const auditToResolve = auditState[category].find(a => {
      const norm =
        a.patient_id === patient.id &&
        a.resolution_state.name === status &&
        a.definition.name === rule;
      // If narrowing by actual resource (particular therapy or insurance e.x)
      if (resourceValue && resource) {
        return norm && a.resources[resource] === resourceValue;
      }
      return norm;
    });
    return auditToResolve;
  }
  return null;
};

export const resolveAuditDefinition = params => {
  const { auditDefinitionType, auditState, patient, resolverFunction } = params;
  let auditCategory;

  switch (auditDefinitionType) {
    case ENSURE_PATIENT_INFORMATION_VERIFIED:
    case ENSURE_EMERGENCY_CONTACT_ADDRESSED:
    case ENSURE_SMS_ADDRESSED:
    case ENSURE_EMAIL_ADDRESSED:
      auditCategory = auditCategories.Demographics;
      break;
    case ENSURE_PBM_INSURANCE_ADDRESSED:
    case ENSURE_MEDICAL_INSURANCE_ADDRESSED:
      auditCategory = auditCategories.Financials;
      break;
    case ENSURE_ALLERGIES_VERIFIED:
      auditCategory = auditCategories.Clinical;
      break;
    case THERAPY_SHOULD_HAVE_START_DATE:
    case THERAPY_START_DATE_MUST_BE_AFTER_REFERRAL_DATE:
    case ENSURE_THERAPY_MUST_MOVE_TO_UPDATE_STATUS:
    case SERVICE_ENROLLMENT_SHOULD_BE_SET:
    case THERAPY_SHOULD_NOT_BE_ON_ON_HOLD_FOR_TOO_LONG:
    case ENSURE_MEDICATION_LIST_VERIFIED:
      auditCategory = auditCategories.Therapies;
      break;
    default:
      throw new Error('No valid audit definition provided for resolve');
  }

  const auditItem = findAuditByCategoryRuleAndStatus(
    auditState,
    patient,
    auditCategory,
    auditContext.Warning,
    auditDefinitionType,
    AUDIT_NOT_RESOLVED,
  );

  if (auditItem) {
    const { resolution_state: resolutionState, ...restAuditItem } = auditItem;
    const resolvedAudit = {
      ...restAuditItem,
      resolution_state_id: auditResolutionStates.Resolved,
    };
    resolverFunction(resolvedAudit);
  }
};

export const getAuditTooltip = (
  audit,
  context,
  auditCategory,
  auditState,
  selectedPatientId,
  auditPatientId,
) => {
  if (context === auditContext.Tab) {
    return `${StringUtils.upperCaseFirstLetter(auditCategory)} audits found`;
  }
  if (context === auditContext.Sidebar) {
    return `Audits found for ${doesPatientHaveUnresolvedAudits(
      auditState,
      selectedPatientId,
      auditPatientId,
    )}`;
  }
  if (audit && audit.definition) {
    return audit.definition.name;
  }
  return '';
};
export const getAuditCategory = auditCat => {
  let retStr = '';
  switch (auditCat) {
    case AUDIT_DEMOGRAPHICS: {
      retStr = 'Demographics';
      break;
    }
    case AUDIT_INB: {
      retStr = 'Income and Benefits';
      break;
    }
    case AUDIT_ALLERGIES: {
      retStr = 'Allergies';
      break;
    }
    case AUDIT_THERAPIES: {
      retStr = 'Therapies';
      break;
    }
    default:
      break;
  }
  return retStr;
};

export const auditCategoriesRaw = lookups => {
  if (lookups && lookups.auditConstants && lookups.auditConstants.length > 0) {
    return [...new Set(lookups.auditConstants.map(a => a.arbor_audit_area_id))];
  }
  return [];
};

export const therapyAdministrationAuditDetector = (
  audit,
  patient,
  initialTherapyValues,
  updatedTherapyValues,
  therapyAdminStatusArr,
) => {
  switch (initialTherapyValues.administration_status) {
    case THERAPY_ADMIN_STATUS_PRE_THERAPY:
      if (
        updatedTherapyValues.administration_status_id === TherapyAdministrationStatuses.OnTherapy
      ) {
        return findAuditByCategoryRuleAndStatus(
          audit,
          patient,
          auditCategories.Therapies,
          auditContext.Warning,
          ENSURE_THERAPY_MUST_MOVE_TO_UPDATE_STATUS,
          AUDIT_NOT_RESOLVED,
          'therapy_id',
          initialTherapyValues.id,
        );
      }
      return null;
    case THERAPY_ADMIN_STATUS_ON_HOLD:
      if (
        initialTherapyValues.administration_status === THERAPY_ADMIN_STATUS_ON_HOLD &&
        !therapyAdminStatusArr.some(
          adminStatus => adminStatus === updatedTherapyValues.administration_status_id,
        )
      ) {
        return findAuditByCategoryRuleAndStatus(
          audit,
          patient,
          auditCategories.Therapies,
          auditContext.Warning,
          THERAPY_SHOULD_NOT_BE_ON_ON_HOLD_FOR_TOO_LONG,
          AUDIT_NOT_RESOLVED,
          'therapy_id',
          initialTherapyValues.id,
        );
      }
      return null;
    default:
      return null;
  }
};

export const therapyServiceEnrollmentAuditDetector = (
  audit,
  patient,
  initialTherapyValues,
  updatedTherapyValues,
) => {
  if (
    updatedTherapyValues &&
    !isClinicalSupportUndecided(updatedTherapyValues.clinical_support_status_id) &&
    updatedTherapyValues.dispensing_status_id &&
    !isDispensingUndecided(updatedTherapyValues.dispensing_status_id)
  ) {
    return findAuditByCategoryRuleAndStatus(
      audit,
      patient,
      auditCategories.Therapies,
      auditContext.Warning,
      SERVICE_ENROLLMENT_SHOULD_BE_SET,
      AUDIT_NOT_RESOLVED,
      'therapy_id',
      initialTherapyValues.id,
    );
  }
  return null;
};

import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const DateFormStyles = (theme: Theme) => {
  return createStyles({
    otherButton: {
      margin: theme.spacing(1),
      marginLeft: 0,
    },
  });
};

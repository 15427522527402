import { Grid } from '@mui/material';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { ReactSelect } from 'components/form/field/react-select';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PatientSmsClient } from 'clients/patient-sms';
import { notifySuccess, notifyError } from 'actions/action-notifications';
import {
  updateSmsMessageTags,
  workListChanged,
  refreshPatientTherapiesAndTasks,
} from '../../../actions/action-patient';
import { useTypedStyles } from './edit-tags.styles';
import styles from '../styles';

const EditSmsTagsForm = props => {
  const { cancel } = props;
  const { classes } = useTypedStyles();
  const [value, setValue] = useState();
  const [selectOptions, setSelectOptions] = useState([{}]);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const customerId = useSelector(state => state.patient.customer_id);
  const patientId = useSelector(state => state.patient.id);
  const [initialSelectionCount, setInitialSelectionCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchTags() {
      const response = await PatientSmsClient.fetchPatientTherapyAndTaskTags(
        props.patientId,
        customerId,
        props.smsId,
      );
      const options = response.data;
      const initialSelections = options.filter(v => v.isInitiallySelected);
      setInitialSelectionCount(initialSelections.length);
      setValue(initialSelections);
      setSelectOptions(options);
      setIsLoading(false);
    }
    fetchTags();
  }, []);

  const handleTagChange = (values, { action, removedValue }) => {
    switch (action) {
      case 'remove-value':
      case 'pop-value':
        if (removedValue.isFixed) {
          return;
        }
        break;
      case 'clear':
        values = selectOptions.filter(v => v.isFixed);
        break;
      default:
        break;
    }
    setValue(values);
  };
  const dispatch = useDispatch();

  const onSubmit = async e => {
    e.preventDefault();
    setDisableSubmitButton(true);
    const haveTagsChanged =
      value.length !== initialSelectionCount || value.some(item => !item.isInitiallySelected);
    if (haveTagsChanged) {
      const newTags = value.map(item => item.value);
      const tagsPayload = { tags: newTags };
      const response = await PatientSmsClient.updateSmsMessageTags(props.smsId, tagsPayload);
      if (response.status === 200) {
        dispatch(refreshPatientTherapiesAndTasks(patientId));
        dispatch(updateSmsMessageTags(props.smsId, response.data.newSetOfTags));
        dispatch(workListChanged());
        dispatch(notifySuccess('Message tags updated successfully'));
      } else {
        dispatch(notifyError('Message tags update failed'));
      }
    } else {
      dispatch(notifySuccess('Message tags updated successfully'));
    }

    props.cancel();
  };

  return (
    <Grid container justifyContent="center" className={classes.editTagsContainer}>
      {!isLoading && (
        <Grid item xs={12}>
          <Grid item>
            <ReactSelect
              name="editSmsTags"
              label="Edit Tags"
              handleOnChange={handleTagChange}
              isClearable
              fields={selectOptions}
              isMulti
              value={value}
              closeMenuOnSelect={false}
              styles={styles}
            />
          </Grid>
          <ConfirmationPanel
            handleCancel={cancel}
            disableSubmit={disableSubmitButton}
            handleSubmit={onSubmit}
          />
        </Grid>
      )}
      {isLoading && (
        <Grid item xs={12}>
          ...Loading
        </Grid>
      )}
    </Grid>
  );
};

export default EditSmsTagsForm;

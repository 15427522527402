import { convertToArborDate } from 'models/time/arbor-date';
import { IFiltersCustomerItem } from 'interfaces/redux/IFilters';
import { IState } from 'interfaces/redux/IState';
import { UserPermissions } from 'interfaces/user/Permissions';
import store from '../store';

// TODO: Move into `UserUtils` class
const getActionedByUserText = (params: {
  action?: string;
  date?: Date | string;
  dateFormat?: string;
  user: any;
  isDateUtc?: boolean;
}): string => {
  const { action, date = null, user, dateFormat = null, isDateUtc = false } = params;
  if (!user) {
    return '';
  }
  let dateString = date
    ? ` ${convertToArborDate(date.toString()).getCustomerDatetime(true, dateFormat)}`
    : ' ';

  if (date && isDateUtc && !dateFormat) {
    dateString = ` ${convertToArborDate(date, true).getUtcDate(true)}`;
  }
  let userString = user.toString();

  if (typeof user !== 'string') {
    userString = `${user.display_name}${
      user.certifications && user.certifications.length ? `, ${user.certifications[0]}` : ''
    }${!user.active ? ' (Inactive)' : ''}`;
  }
  const actionString = action ? `${action} by ` : '';
  return `${actionString}${userString}${dateString}`;
};

export class UserUtils {
  public static isPermissionGrantedOnCustomerFeature = (
    permissionName: string,
    featureToCheck: string,
  ): boolean => {
    const user = (store.getState() as unknown as IState).auth.currentUser;
    const customerFeatures = user.permissions[permissionName]?.customerFeatures ?? [];
    for (const data of customerFeatures) {
      const feature = Object.keys(data).find(key => key === featureToCheck);
      if (feature) {
        return !!data[feature];
      }
    }
    return false;
  };

  public static userIsSuperUser = (): boolean => {
    const user = (store.getState() as unknown as IState).auth.currentUser;
    return user.isSuperUser;
  };

  public static userHasResourcePermission = (resource: string, action: string) => {
    const user = (store.getState() as unknown as IState).auth.currentUser;
    const userRoles = user?.roles || [];
    return !!userRoles.find(
      item => item.resource_name === resource && item.resource_action === action,
    );
  };

  public static userIsPermitted = (
    permissionName: UserPermissions,
    customerId: number | undefined = undefined,
  ): boolean => {
    const user = (store.getState() as unknown as IState).auth.currentUser;
    const { customers } = (store.getState() as unknown as IState).filters;

    let customer: IFiltersCustomerItem | undefined;
    if (customerId && customers) {
      customer = customers.find(x => x.id === customerId);
    }

    // check if super user
    if (customer && customer.is_super_user) {
      return true;
    }

    const permission = user.permissions[permissionName];

    // If the user has the permission
    if (permission) {
      // If all customers, return true immediately
      if (permission.all_customers) {
        return true;
      }

      // If this permission cares about customer id, check it
      if (customerId) {
        return (
          permission.customers != null &&
          permission.customers.length > 0 &&
          permission.customers.includes(customerId.toString())
        );
      }
      // If the permission doesn't care about customer id, return true
      return true;
    }

    return false;
  };
}

export { getActionedByUserText };

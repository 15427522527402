import { DUR, RS } from 'constants/index';
import fcProviders from './fc-provider';
import fdcProviders from './fdc-provider';
import cslProviders from './csl-provider';
import faProviders from './fa-provider';
import paProviders from './pa-provider';
import mrProviders from './mr-provider';
import intProviders from './int-provider';
import tprProviders from './tpr-provider';
import orProviders from './or-provider';
import dcProviders from './dc-provider';
import rsProviders from './rs-provider';
import arProviders from './ar-provider';
import qreProviders from './qre-provider';
import durProviders from './dur-provider';

export default taskType => {
  switch (taskType) {
    case 'FC':
      return fcProviders;
    case 'FDC':
      return fdcProviders;
    case 'CSL':
      return cslProviders;
    case 'FA':
      return faProviders;
    case 'PA':
      return paProviders;
    case 'MR':
      return mrProviders;
    case 'INT':
      return intProviders;
    case 'TPR':
      return tprProviders;
    case 'OR':
      return orProviders;
    case 'DC':
      return dcProviders;
    case 'AR':
      return arProviders;
    case 'QRE':
      return qreProviders;
    case DUR:
      return durProviders;
    case RS:
      return rsProviders;
    default:
      return null;
  }
};

import {
  AddPaymentMethodModalActionType,
  IAddPaymentMethodModalAction,
} from 'actions/action-add-payment-method-modal';
import { IAddPaymentMethodModal } from 'interfaces/redux/IAddPaymentMethodModal';

const initialState: IAddPaymentMethodModal = {
  visible: false,
  formId: undefined,
  field: undefined,
  tasks: undefined,
};

export const addPaymentMethodModalReducer = (
  state: IAddPaymentMethodModal = initialState,
  action: IAddPaymentMethodModalAction,
) => {
  switch (action.type) {
    case AddPaymentMethodModalActionType.SetState: {
      return {
        ...action.payload,
      };
    }
    case AddPaymentMethodModalActionType.ResetModal: {
      return initialState;
    }
    default:
      return state;
  }
};

import React from 'react';
import { useForm, Controller, useWatch, FieldError } from 'react-hook-form';
import { DeepPartial, nameOfFactory } from 'utils/types-util';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { renderDatePicker as RenderDatePicker } from 'components/form/datepicker/datetime-picker';
import { renderTextField as RenderTextField } from 'components/form/field/redux-field';
import { debounce, isEqual } from 'lodash';
import { SEARCH_SETTINGS } from 'constants/index';
import { IFormFields, IFormProps } from './interfaces';
import { styles } from './advanced-search.styles';

const getFieldName = nameOfFactory<IFormFields>();

const allFormFieldNames = [
  getFieldName('firstName'),
  getFieldName('lastName'),
  getFieldName('dateOfBirth'),
  getFieldName('phone'),
  getFieldName('medicalRecordNumber'),
];

const minCharsErrorMessage = `At least ${SEARCH_SETTINGS.MIN_CHARS_TO_SEARCH} characters required`;
const phoneErrorMessage = '10 digits or +1 then 10 digits';
const defaultDebounceTime = 1000;

const Form: React.FC<IFormProps> = (props: IFormProps) => {
  // #region component state
  const {
    control,
    trigger: isFieldValid,

    formState: { errors },
  } = useForm<IFormFields>({
    mode: 'onChange',
  });

  /**
   * react-hook-form useWatch return a DeepPartial from react-hooks-form, we cast it to our own DeepPartial
   * to don't depend to the library type on other places that we use the type.
   */
  const formValues = useWatch<IFormFields>({ control }) as DeepPartial<IFormFields>;

  const [lastSubmitFormSnapshot, setLastSubmitFormSnapshot] = React.useState<
    DeepPartial<IFormFields>
  >({});

  // #endregion

  // #region useEffects
  React.useEffect(() => {
    debouncedOnFormValueChange(formValues, lastSubmitFormSnapshot);
  }, [formValues]);

  // #endregion

  // #region helpers
  const isMinLenError = (error?: FieldError) => error?.type === 'minLength';
  const isPhoneError = (error?: FieldError) => error?.type === 'pattern';

  const buildSearchCriteria = (
    fieldValidations: Record<keyof IFormFields, boolean>,
    formValues: DeepPartial<IFormFields>,
  ): DeepPartial<IFormFields> => {
    return allFormFieldNames.reduce((criteria: DeepPartial<IFormFields>, fieldName) => {
      if (fieldValidations[fieldName]) {
        criteria[fieldName] = formValues[fieldName] as any;
      }
      return criteria;
    }, {});
  };

  const onFormValueChange = async (
    formValues: DeepPartial<IFormFields>,
    lastSentForm: DeepPartial<IFormFields>,
  ) => {
    const [isFirstNameValid, isLastNameValid, isDateOfBirthValid, isPhoneValid, isMrnValid] =
      await Promise.all(allFormFieldNames.map(fieldName => isFieldValid(fieldName)));

    const searchCriteria = buildSearchCriteria(
      {
        firstName: isFirstNameValid,
        lastName: isLastNameValid,
        dateOfBirth: isDateOfBirthValid,
        phone: isPhoneValid,
        medicalRecordNumber: isMrnValid,
      },
      formValues,
    );
    // Checking if something has changed before submiting it.
    if (!isEqual(searchCriteria, lastSentForm)) {
      setLastSubmitFormSnapshot(searchCriteria);
      props.submit?.(searchCriteria);
    }
  };

  // use callback in order to avoid function re-creation on each re-render
  const debouncedOnFormValueChange = React.useCallback(
    debounce(
      (formValues: DeepPartial<IFormFields>, lastSentForm: DeepPartial<IFormFields>) =>
        onFormValueChange(formValues, lastSentForm),
      props.debounceTime || defaultDebounceTime,
    ),
    [props.debounceTime],
  );

  // #endregion

  // #region render
  return (
    <form>
      <Grid container>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={getFieldName('firstName')}
              rules={{ minLength: 2, required: true }}
              defaultValue=""
              render={(ctrlProps: any) => (
                <RenderTextField
                  autoFocus
                  width="100%"
                  label="First Name"
                  id="search-first-name"
                  input={{
                    value: ctrlProps?.field?.value,
                    onChange: ctrlProps?.field?.onChange,
                    onBlur: ctrlProps?.field?.onBlur,
                  }}
                  meta={{ touched: isMinLenError(errors.firstName), error: minCharsErrorMessage }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={getFieldName('lastName')}
              rules={{ minLength: 2, required: true }}
              defaultValue=""
              render={(ctrlProps: any) => (
                <RenderTextField
                  width="100%"
                  label="Last Name"
                  id="search-last-name"
                  input={{
                    value: ctrlProps?.field?.value,
                    onChange: ctrlProps?.field?.onChange,
                    onBlur: ctrlProps?.field?.onBlur,
                  }}
                  meta={{ touched: isMinLenError(errors.lastName), error: minCharsErrorMessage }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={getFieldName('dateOfBirth')}
              rules={{ minLength: 2, required: true }}
              defaultValue=""
              render={(ctrlProps: any) => (
                <RenderDatePicker
                  fullWidth
                  label="Date of birth"
                  id="search-date-of-birth"
                  input={{
                    value: ctrlProps?.field?.value,
                    onChange: ctrlProps?.field?.onChange,
                    onBlur: ctrlProps?.field?.onBlur,
                  }}
                  meta={{
                    touched: isMinLenError(errors.dateOfBirth as unknown as FieldError),
                    error: minCharsErrorMessage,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={getFieldName('phone')}
              rules={{ pattern: /^(\+1)?\d{10}$/, required: true }}
              defaultValue=""
              render={(ctrlProps: any) => (
                <RenderTextField
                  width="100%"
                  label="Phone"
                  id="search-phone"
                  input={{
                    value: ctrlProps?.field?.value,
                    onChange: ctrlProps?.field?.onChange,
                    onBlur: ctrlProps?.field?.onBlur,
                  }}
                  meta={{
                    touched: isPhoneError(errors.phone),
                    error: phoneErrorMessage,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={getFieldName('medicalRecordNumber')}
              rules={{ minLength: 2, required: true }}
              defaultValue=""
              render={(ctrlProps: any) => (
                <RenderTextField
                  width="100%"
                  label="MRN"
                  id="search-mrn"
                  input={{
                    value: ctrlProps?.field?.value,
                    onChange: ctrlProps?.field?.onChange,
                    onBlur: ctrlProps?.field?.onBlur,
                  }}
                  meta={{
                    touched: isMinLenError(errors.medicalRecordNumber),
                    error: minCharsErrorMessage,
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );

  // #endregion
};

export const AdvancedSearchForm = withStyles(styles)(Form);

import { useEffect, useRef } from 'react';

export function useTimeoutFn(callbackFn: () => void) {
  const t = useRef(-1);
  const clear = () => {
    clearTimeout(t.current);
    t.current = -1;
  };
  const start = (delay = 500) => {
    t.current = window.setTimeout(callbackFn, delay);
    return clear;
  };
  useEffect(() => clear, []);
  return { start, clear };
}

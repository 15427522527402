export enum DataCollectStatus {
  Required = 6001,
  InProgress = 6002,
  Collected = 6003,
  Reviewed = 6004,
  Canceled = 6005,
  CanceledPatientDeceased = 6099,
  Intervening = 6006,
}

/*
 * Using typescript const assertions
 * https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-4.html#const-assertions
 * To generate a type from an array of values and keep consistency  with the enum and the list
 */
const STATUS_LIST = [
  DataCollectStatus.Canceled,
  DataCollectStatus.CanceledPatientDeceased,
  DataCollectStatus.Collected,
  DataCollectStatus.Intervening,
  DataCollectStatus.InProgress,
  DataCollectStatus.Required,
  DataCollectStatus.Reviewed,
] as const;

// This type  allow use to make some composition with other task status types to treat them as
// a single type of statuses if we need it.
export type DataCollectTaskStatusList = typeof STATUS_LIST[number];

import React from 'react';
import { useSelector } from 'react-redux';

import JsonField from './json-field';
import { DynamicFormContext } from './dynamic-form-context';

export default field => {
  const { providers, initialValues, formId } = React.useContext(DynamicFormContext);
  const form = useSelector(state => state.form[formId]) || {};

  if (typeof field.visible === 'function') {
    if (!field.visible(form)) {
      return null;
    }
  }

  return (
    <JsonField providers={providers} field={field} initialValues={initialValues} formId={formId} />
  );
};

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material';
import AutocompleteLabel from 'components/form/field/autocomplete-label';
import AutocompleteMinigrid from 'components/form/field/render-minigrid-autocomplete';
import { fetchPhysicians } from 'services/utils/therapy-service';
import { styles } from '../edit-patient-therapy/edit-patient-therapy-styles';
import { updateLocalUserPreferencesRecentPrescribers } from '../../../../actions/action-user';

export default withStyles(styles)(({ classes, therapy, input, label, ...other }) => {
  const dispatch = useDispatch();
  const [prescriber, setPrescriber] = useState(
    input.value
      ? {
          ...input.value,
          id: input.value.npi,
          label: input.value.name,
          description: input.value.address,
        }
      : {},
  );
  const recentProviders = useSelector(
    state => (state.userPreferences && state.userPreferences.recent_items.providers) || [],
  );

  const setAndSavePrescriber = prescriberItem => {
    dispatch(updateLocalUserPreferencesRecentPrescribers(prescriberItem));
    setPrescriber(prescriberItem);
  };

  useEffect(() => {
    input.onChange(prescriber);
  }, [input, prescriber]);

  return (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={6} className={classes.prescriberClinicContainer}>
        <AutocompleteMinigrid
          name={input.name}
          label={input.label || label}
          fetchOptions={searchText =>
            // eslint-disable-next-line no-confusing-arrow
            fetchPhysicians(searchText).then(result =>
              result.data
                ? result.data.physicians.map(c => ({
                    ...c,
                    id: c.npi,
                    label: c.name,
                    description: c.address,
                  }))
                : [],
            )
          }
          hint="Search by Physician Last, First Name or NPI"
          onChange={setAndSavePrescriber}
          simplistic
          hasMinSearchLength
          recentOptions={recentProviders.map(icd10 => ({
            ...icd10,
            id: icd10.npi,
            label: icd10.name,
            description: icd10.address,
          }))}
          input={{
            onChange: setAndSavePrescriber,
            value: prescriber,
          }}
          {...other}
        />
      </Grid>
      <Grid item xs={3}>
        <AutocompleteLabel
          idValue={prescriber.npi}
          defaultIdValue={therapy.prescribing_physician_npi}
          label="Physician NPI"
        />
      </Grid>
    </Grid>
  );
});

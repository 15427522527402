import React, { useMemo } from 'react';
import { uniqBy } from 'lodash';
import Dropdown from 'components/form/field/dropdown';
import { stopPropagation } from 'services/utils/task-service';
import { TaskStatusUtil } from 'utils/task-status-util';
import { IUser } from 'interfaces/redux/IUser';
import { IResourceState } from 'interfaces/redux/ILookups';
import { AllTaskTypesStatuses } from 'interfaces/enums/TaskStatuses';
import { IStatus } from 'interfaces/redux/ITaskStatuses';
import { ITask } from 'interfaces/redux/ITasks';

interface IProps {
  value: string;
  currentStatus: string;
  onChange: Function;
  manuallyRestricted: string[];
  statuses: IStatus[];
  taskIDPrefix: string;
  enabled?: boolean;
  task?: ITask;
  currentUser?: IUser;
  resourceStates?: IResourceState[];
}

export const StatusSelector = ({
  value,
  onChange,
  manuallyRestricted,
  currentStatus,
  statuses,
  taskIDPrefix,
  enabled,
  task,
  currentUser,
  resourceStates,
}: IProps) => {
  const statusList = useMemo(
    () =>
      uniqBy<IStatus>(statuses, 'status').filter(
        ({ status }) =>
          !manuallyRestricted ||
          manuallyRestricted.includes(currentStatus) ||
          !manuallyRestricted.includes(status),
      ),
    [statuses, currentStatus, manuallyRestricted],
  );

  const canTransition = useMemo(
    () =>
      TaskStatusUtil.buildCanTransitionFromPermissions(currentUser?.permissions, resourceStates),
    [currentUser?.permissions, resourceStates],
  );

  const options: { value: string; key: string; disabled?: boolean }[] = statusList.map(
    ({ status, id }) => ({
      value: status,
      key: status,
      disabled: task ? !canTransition(task, id as AllTaskTypesStatuses) : false,
    }),
  );

  return (
    <Dropdown
      label="Status"
      state={value}
      handler={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      onClick={stopPropagation}
      fields={options}
      name="task_detail"
      width="70%"
      id={`${taskIDPrefix}_statusDropdown`}
      enabled={enabled}
      dataQaId={`therapy-task-${task?.type}-status`}
    />
  );
};

import React from 'react';
import cx from 'classnames';

const ProgressContainer = ({ index, length, classes }) => {
  return (
    <div className={cx(classes.container)} data-qa-id="progress">
      {index} / {length}
      <div className={classes.progressBar}>
        <div className={classes.progress} style={{ width: `${(100 / length) * index}%` }} />
      </div>
    </div>
  );
};

export default ProgressContainer;

import { OpenWeatherCondition } from '../interfaces/weather/IWeather';
import Clear, { ReactComponent as ClearIcon } from '../lib/weather/ico-clear.svg';
import Clouds, { ReactComponent as CloudsIcon } from '../lib/weather/ico-clouds.svg';
import Drizzle, { ReactComponent as DrizzleIcon } from '../lib/weather/ico-drizzle.svg';
import Mist, { ReactComponent as MistIcon } from '../lib/weather/ico-mist.svg';
import Rain, { ReactComponent as RainIcon } from '../lib/weather/ico-rain.svg';
import Snow, { ReactComponent as SnowIcon } from '../lib/weather/ico-snow.svg';
import Thunderstorm, {
  ReactComponent as ThunderstormIcon,
} from '../lib/weather/ico-thunderstorm.svg';
import NoData, { ReactComponent as NoDataIcon } from '../lib/weather/ico-no-data.svg';

const WeatherIconMap = (
  icon: string,
): {
  icon: string;
  component: any;
} => {
  switch (icon) {
    case OpenWeatherCondition.Clear:
      return { icon: Clear, component: ClearIcon };
    case OpenWeatherCondition.Clouds:
      return { icon: Clouds, component: CloudsIcon };
    case OpenWeatherCondition.Drizzle:
      return { icon: Drizzle, component: DrizzleIcon };
    case OpenWeatherCondition.Mist:
      return { icon: Mist, component: MistIcon };
    case OpenWeatherCondition.Rain:
      return { icon: Rain, component: RainIcon };
    case OpenWeatherCondition.Snow:
      return { icon: Snow, component: SnowIcon };
    case OpenWeatherCondition.Thunderstorm:
      return { icon: Thunderstorm, component: ThunderstormIcon };
    case OpenWeatherCondition.NoData:
      return { icon: NoData, component: NoDataIcon };
    default:
      return { icon: Clouds, component: CloudsIcon };
  }
};

const GetWeatherIcon = (icon: string): string => {
  return WeatherIconMap(icon).icon;
};

export { GetWeatherIcon };

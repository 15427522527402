import React, { MouseEvent } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { Typography } from '@mui/material';
import {
  PaymentMethodBadge,
  PaymentMethodDropdownSelectOption,
} from './use-payment-method-options';
import { FCPaymentOptions } from '../../../../constants/fc-refill-payment-choice';

interface SelectProps {
  fieldName: string;
  onSelectAdd: () => void;
  onChange: (value: number[] | null) => void;
  options: PaymentMethodDropdownSelectOption[];
  values: number[] | null;
  disabled?: boolean;
  onDelete?: (id: number) => void;
  maxSelectedValues?: number;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const badgeStyle = {
  display: 'inline-block',
  padding: '2px 15px',
  borderRadius: 99,
  fontSize: '11px',
  lineHeight: '16px',
  fontWeight: 600,
  marginRight: '6px',
};

const getBadge = (name?: PaymentMethodBadge | null) => {
  if (name === PaymentMethodBadge.Virtual) {
    return (
      <Typography sx={{ ...badgeStyle, background: '#E0BB53', color: '#44484C' }}>
        Virtual
      </Typography>
    );
  }
  if (!name) {
    return null;
  }
  return (
    <Typography sx={{ ...badgeStyle, background: '#5D87AF', color: 'white' }}>{name}</Typography>
  );
};

export default function PaymentMethodSelect({
  options,
  fieldName,
  onSelectAdd,
  onChange,
  values,
  disabled,
  onDelete,
  maxSelectedValues,
}: SelectProps) {
  const theme = useTheme();
  const handleChange = (event: SelectChangeEvent<typeof values>) => {
    const {
      target: { value },
    } = event;
    if (Array.isArray(value)) onChange(value.filter(val => val !== -1));
  };
  const disableValues = values && maxSelectedValues ? values.length >= maxSelectedValues : false;

  return (
    <div>
      <FormControl sx={{ m: 1 }} fullWidth focused disabled={disabled}>
        <InputLabel
          id="payment-methods-multiple-chip-label"
          sx={{
            fontSize: '16px',
            color: '#8a9599!important',
            transform: 'translate(0, -9px) scale(0.75)',
          }}
        >
          Payment Methods
        </InputLabel>
        <Select
          labelId="payment-methods-multiple-chip-label"
          id="payment-methods-multiple-chip-select"
          multiple
          value={values ?? []}
          onChange={handleChange}
          input={<Input id="payment-method-chip" name={fieldName} />}
          renderValue={selected => {
            if (selected[0] < 0) {
              // negative values are special cases of payment indicating some type
              // of patient contact. The values are set using the patient responses
              // in the FC Self service questionnaire.
              let message = 'Contact patient to collect payment method';
              if (selected[0] === FCPaymentOptions.PatientRequestedCallIfNeeded) {
                message =
                  'Patient requested a call to collect new payment method only if one is required';
              }
              if (selected[0] === FCPaymentOptions.PatientRequestedCall) {
                message = 'Patient requested a call to collect new payment method';
              }
              return (
                <div
                  style={{
                    display: 'inline-block',
                    color: 'red',
                    padding: '0 15px 0 15px',
                    border: '1px solid red',
                    borderRadius: '15px',
                  }}
                >
                  {message}
                </div>
              );
            }

            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected?.map(value => (
                  <Chip
                    key={value}
                    label={
                      <Box>
                        {getBadge(options.find(opt => opt.value === value)?.badge)}
                        {options.find(opt => opt.value === value)?.label}
                      </Box>
                    }
                    sx={{ opacity: '1!important' }}
                    clickable={false}
                    onMouseDown={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onDelete={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      onChange(values?.filter(selectedValue => selectedValue !== value) ?? values);
                      onDelete?.(value);
                    }}
                    disabled={disabled}
                  />
                ))}
              </Box>
            );
          }}
          MenuProps={MenuProps}
        >
          {options.map(option => (
            <MenuItem
              data-qa-id={`option-${
                option.badge === PaymentMethodBadge.Virtual ? 'Virtual' : option.badge ?? 'no rank'
              }-${option.label}`}
              key={option.value}
              value={option.value}
              disabled={(disableValues && !values?.includes(option.value)) || option.expired}
            >
              {getBadge(option.badge)}
              {option.label}
            </MenuItem>
          ))}
          <MenuItem
            data-qa-id="option-Add Payment Method"
            key="add-payment-method-select-option"
            value={-1}
            style={{
              fontWeight: 600,
              color: theme.palette.success.main,
              borderTop: `1px solid ${theme.palette.grey[200]}`,
            }}
            disabled={disableValues}
            onMouseDown={(e: MouseEvent<HTMLLIElement>) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onClick={(e: MouseEvent<HTMLLIElement>) => {
              e.preventDefault();
              e.stopPropagation();
              onSelectAdd();
            }}
          >
            + Add Payment Method
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

import React from 'react';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { Button, Grid, Modal, Typography } from '@mui/material';
import { CircularLoadingButton } from 'components/circular-loading-button/circular-loading-button';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { getModalStyle } from 'services/utils/styles-service';
import { nameOfFactory } from 'utils/types-util';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { useDispatch } from 'react-redux';
import Validation from 'components/form/validation/validation';
import { buildQaId } from 'utils/build-qa-id';
import { ReactSelect } from 'components/form/field/react-select';
import { NumberOption, StringOption } from 'interfaces/forms/types';
import LoadingOverlay from 'components/loading-overlay/loading-overlay';
import { ControlledText } from 'components/react-hook-form-fields';
import Divider from 'components/divider';
import { IGetIcdProtocolsResponse } from 'models/application-manager/cpmp/IGetIcdProtocolsResponse';
import { ISpecialty } from 'models/application-manager/cpmp/IGetSpecialtyResponse';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { Close as CloseIcon } from '@mui/icons-material';
import { DynamicFields, IIcdItem, IIcdFrequencyItemToEdit } from './types';
import { IResult, IIcdCodesOpt } from '../tables/types';
import { logger } from '../../../../../winston-logger';
import {
  PROTOCOL_FREQUENCY,
  PROTOCOL_FREQUENCY_UNIT,
  PROTOCOL_FREQUENCY_TYPE,
} from '../../../../../constants/index';
import { styles } from './add-new-icd-modal.styles';

interface IAddNewIcdModalProps {
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  editingIcdItem: IResult | undefined;
  icdProtocols: IGetIcdProtocolsResponse;
  specialties: ISpecialty[];
}

interface IFormFields {
  specialtyId: NumberOption;
  icd: IIcdCodesOpt[];
  mainFrequencyName: string;
  dcItem: NumberOption[];
}

interface IFormFieldsDefaultValues {
  specialtyId: NumberOption | null;
  icd: IIcdCodesOpt[] | null;
  mainFrequencyName: string | '';
  dcItem: NumberOption[] | null;
}

const formFieldsValues: IFormFieldsDefaultValues = {
  specialtyId: null,
  icd: null,
  mainFrequencyName: '',
  dcItem: null,
};

const getFieldName = nameOfFactory<IFormFields>();
const requiredErrorMsg = 'Required';
const getQaId = buildQaId('application-manager.add-new-icd-modal', '.');

export const AddNewIcdModal: React.FC<IAddNewIcdModalProps> = (
  props: IAddNewIcdModalProps,
): JSX.Element => {
  const classes: any = styles();

  // #region component state
  const form = useForm<any>({});
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [specialtySearchTerm, setSpecialtySearchTerm] = React.useState<string>('');
  const [specialtyList, setSpecialtyList] = React.useState<NumberOption[]>([]);
  const [dcItemSearchTerm, setDcItemSearchTerm] = React.useState<string>('');
  const [dcItemList, setDcItemList] = React.useState<NumberOption[]>([]);
  const [dynamicFrequencyList, setDynamicFrequencyList] = React.useState<any[]>([]);
  const [formValues, setFormValues] = React.useState<IFormFieldsDefaultValues>(formFieldsValues);
  // #endregion

  // # region redux state
  const clinicalDataTypesData = useTypedSelector(state => state.lookups.clinicalDataTypes);
  // # endregion

  // # region useEffect
  React.useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        if (props.editingIcdItem) {
          const { children } = props.editingIcdItem;
          const editValues: any = {};
          editValues.icd = props.editingIcdItem.icdCode;
          editValues.dcItem = props.editingIcdItem.dcItem.reduce((acc, item) => {
            const id = item[0];
            const label = item[1].name;
            acc.push({
              label: `${id} - ${label}`,
              value: parseInt(id),
            });
            return acc;
          }, [] as NumberOption[]);
          setFormValues(editValues);
          const dataToEdit = children[0].frequency.reduce((acc, item, index) => {
            acc.push({
              id: index + 1,
              frequency: PROTOCOL_FREQUENCY.filter(
                x => x.value.toLowerCase() === item.frequency.toLowerCase(),
              )[0],
              frequencyUnit: item.frequencyUnit
                ? PROTOCOL_FREQUENCY_UNIT.filter(x => x.value === parseInt(item.frequencyUnit))[0]
                : null,
              frequencyType: item.frequencyType
                ? PROTOCOL_FREQUENCY_TYPE.filter(
                    x => x.value.toLowerCase() === item.frequencyType.toLowerCase(),
                  )[0]
                : null,
              dcItem: item.dcItem.map(x => {
                return { label: x[1].name, value: parseInt(x[0]) };
              }),
            });
            return acc;
          }, [] as IIcdFrequencyItemToEdit[]);
          setDynamicFrequencyList(dataToEdit);
          form.setValue(getFieldName('icd'), editValues.icd);
          form.setValue(getFieldName('dcItem'), editValues.dcItem);
        }
      } catch (err) {
        logger.error(err);
        dispatch(notifyError('Error when editing an ICD Protocol'));
      } finally {
        setLoading(false);
      }
    })();
  }, [props.editingIcdItem]);

  const fetchSpecialties = React.useMemo<void>(() => {
    if (specialtySearchTerm.length > 0 && props.specialties.length > 0) {
      const specialtyOpt = props.specialties
        .filter(x => x.name.toLowerCase().includes(specialtySearchTerm.toLowerCase()))
        .map(x => {
          return { label: x.name, value: x.id };
        });
      setSpecialtyList(specialtyOpt);
    }
  }, [specialtySearchTerm]);
  // #endregion

  // #memo region

  const fetchIcdCodes = async (selectedSpecialty: NumberOption) => {
    try {
      const result = await ApplicationManagerClient.fetchIcdCodesBySpecialty(
        selectedSpecialty.value,
      );
      if (result.data.result.length > 0) {
        form.setValue(
          getFieldName('icd'),
          result.data.result.reduce((acc, icdItem) => {
            acc.push({
              label: icdItem,
              value: icdItem,
              isFixed: true,
            });
            return acc;
          }, [] as IIcdCodesOpt[]),
        );
      } else {
        form.setValue(getFieldName('icd'), []);
        dispatch(
          notifyError(
            `There are not ICD10 codes associated to the specialty ${selectedSpecialty.label}`,
          ),
        );
      }
    } catch (err) {
      logger.error(err);
      dispatch(notifyError('Error when fetching ICD10 codes'));
    }
  };

  const fetchDCItems = React.useMemo<void>(() => {
    const dcItems = [];
    for (const categoryId in clinicalDataTypesData) {
      for (const dataTypeId in clinicalDataTypesData[categoryId].dataTypes) {
        if (clinicalDataTypesData[categoryId].dataTypes[dataTypeId].isActive === true) {
          dcItems.push({
            label: `${dataTypeId} - ${clinicalDataTypesData[categoryId].dataTypes[dataTypeId].name}`,
            value: parseInt(dataTypeId),
          });
        }
      }
    }
    setDcItemList(dcItems);
  }, [clinicalDataTypesData]);
  // #endmemoregion

  // #region helper functions
  const addDynamicFrequency = () => {
    const newId = dynamicFrequencyList.length > 0 ? dynamicFrequencyList.slice(-1)[0].id + 1 : 1;
    setDynamicFrequencyList(dynamicFrequencyList => {
      return [
        ...dynamicFrequencyList,
        {
          id: newId,
          frequency: null,
          frequencyUnit: null,
          frequencyType: null,
          dcItem: null,
        },
      ];
    });
  };

  const deleteDynamicFrequency = (id: number) => {
    setDynamicFrequencyList(dynamicFrequencyList => dynamicFrequencyList.filter(x => x.id !== id));
  };

  const addValue = (id: number, fieldName: string, newValue: any) => {
    const newList = dynamicFrequencyList.map(x => {
      if (x.id === id) {
        if (fieldName === DynamicFields.FREQUENCY) {
          x.frequency = newValue;
        } else if (fieldName === DynamicFields.FREQUENCY_UNIT) {
          x.frequencyUnit = newValue;
        } else if (fieldName === DynamicFields.FREQUENCY_TYPE) {
          x.frequencyType = newValue;
        } else if (fieldName === DynamicFields.DC_ITEM) {
          x.dcItem = newValue;
        }
      }
      return x;
    });
    setDynamicFrequencyList(newList);
  };

  const onSubmit: SubmitHandler<any> = async (formValues: IFormFields): Promise<void> => {
    if (props.editingIcdItem) {
      await editIcdItem(formValues);
    } else {
      await submitIcdItem(formValues);
    }
  };

  const editIcdItem = async (formValues: IFormFields): Promise<void> => {
    try {
      const specialtyId = props.editingIcdItem?.id;
      if (specialtyId) {
        const icdCodes = formValues.icd.map(x => x.value);
        const mainFrequency = formValues.mainFrequencyName.toLowerCase();
        const dcItem = formValues.dcItem.map(x => x.value);
        const frequencyData = dynamicFrequencyList.map(x => {
          return {
            frequency: x.frequency.value,
            frequencyUnit: x.frequencyUnit.value,
            frequencyType: x.frequencyType.value,
            dcItem: x.dcItem.reduce((acc: number[], val: { label: string; value: number }) => {
              acc.push(val.value);
              return acc;
            }, [] as number[]),
          };
        });
        const editIcdProtocol: IIcdItem = {
          specialtyId,
          icdCodes,
          mainFrequency,
          dcItem,
          frequencyData,
        };
        if (dcItem.length > 0) {
          if (frequencyData.every(x => x.dcItem.length > 0)) {
            const result = await ApplicationManagerClient.editIcd(editIcdProtocol);
            props.onSuccess();
            props.onCancel();
            if (result.data.success === true) {
              dispatch(notifySuccess('Saved'));
            } else {
              dispatch(notifyError(result.data.result));
            }
            setDynamicFrequencyList([]);
          }
        } else {
          form.setError('dcItem', { type: 'required', message: '' });
        }
      } else {
        dispatch(notifyError('The specialty for this record is undefined.'));
      }
    } catch (error) {
      logger.error(error);
      dispatch(notifyError('Error editing Icd item'));
    }
  };

  const submitIcdItem = async (formValues: IFormFields): Promise<void> => {
    try {
      const specialtyId = formValues.specialtyId.value;
      const icdCodes = formValues.icd.map(x => x.value);
      const mainFrequency = formValues.mainFrequencyName.toLowerCase();
      const dcItem = formValues.dcItem.map(x => x.value);
      const frequencyData = dynamicFrequencyList.map(x => {
        return {
          frequency: x.frequency.value,
          frequencyUnit: x.frequencyUnit.value,
          frequencyType: x.frequencyType.value,
          dcItem: x.dcItem.reduce((acc: number[], val: { label: string; value: number }) => {
            acc.push(val.value);
            return acc;
          }, [] as number[]),
        };
      });

      if (icdCodes.length > 0) {
        if (dcItem.length > 0) {
          if (frequencyData.every(x => x.dcItem.length > 0)) {
            const newIcdProtocol: IIcdItem = {
              specialtyId,
              icdCodes,
              mainFrequency,
              dcItem,
              frequencyData,
            };
            const result = await ApplicationManagerClient.addNewIcd(newIcdProtocol);
            props.onSuccess();
            props.onCancel();
            if (result.data.success === true) {
              dispatch(notifySuccess('Saved'));
            } else {
              dispatch(notifyError(result.data.result));
            }
            setDynamicFrequencyList([]);
          }
        } else {
          form.setError('dcItem', { type: 'required', message: '' });
        }
      } else {
        throw new Error('Can not use a specialty without ICD-10 codes associated.');
      }
    } catch (error) {
      logger.error(error);
      dispatch(notifyError(`Error creating Icd Protocol: ${error}`));
    }
  };
  // #endregion

  return (
    <Modal open={props.open} data-qa-id={getQaId('wrapper')}>
      <div style={getModalStyle()} className={classes.modal}>
        <Grid container direction="column" spacing={2}>
          {/* Title */}
          <Grid item xs="auto">
            <Typography className={classes.title} data-qa-id={getQaId('title')}>
              {!props.editingIcdItem
                ? 'Add ICD-10 Protocol'
                : `Edit "${props.editingIcdItem.name}"`}
            </Typography>
          </Grid>

          {/* Form */}
          <Grid item xs="auto" data-qa-id={getQaId('form')}>
            <Grid container spacing={1}>
              {props.editingIcdItem === undefined && (
                <Grid item xs={5}>
                  <Controller
                    name={getFieldName('specialtyId')}
                    defaultValue={formValues.specialtyId}
                    control={form.control}
                    rules={{ required: true }}
                    render={(ctrlProps: any) => {
                      return (
                        <>
                          <LoadingOverlay open={loading} />
                          <ReactSelect
                            label="Specialty *"
                            isMulti={false}
                            value={ctrlProps?.field?.value}
                            fields={specialtyList}
                            handleOnChange={(value: NumberOption) => {
                              ctrlProps?.field?.onChange(value);
                              fetchIcdCodes(value);
                            }}
                            handleOnInputChange={(value: string) => setSpecialtySearchTerm(value)}
                            fullWidth
                          />
                          <Validation
                            touched={form.formState.errors.specialtyId}
                            error={requiredErrorMsg}
                          />
                        </>
                      );
                    }}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={props.editingIcdItem === undefined ? 7 : 12}
                className={classes.alignIcdElem}
              >
                <Controller
                  name={getFieldName('icd')}
                  defaultValue={formValues.icd}
                  control={form.control}
                  rules={{ required: true }}
                  render={(ctrlProps: any) => {
                    return (
                      <>
                        <LoadingOverlay open={loading} />
                        <ReactSelect
                          label="ICD-10"
                          placeholder=""
                          isMulti
                          value={ctrlProps?.field?.value}
                          isDisabled
                          fullWidth
                          hideDropdownIcon
                        />
                        <Validation touched={form.formState.errors.icd} error={requiredErrorMsg} />
                      </>
                    );
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <ControlledText
                  name={getFieldName('mainFrequencyName')}
                  control={form.control}
                  disabled
                  defaultValue="Baseline"
                  validations={{ required: true }}
                  percentWith={100}
                  label="Frequency *"
                  inputMetaData={{
                    touched: Boolean(form.formState.errors.mainFrequencyName),
                    error: requiredErrorMsg,
                  }}
                />
              </Grid>

              <Grid item xs={9}>
                <Controller
                  name={getFieldName('dcItem')}
                  defaultValue={formValues.dcItem}
                  control={form.control}
                  rules={{ required: true }}
                  render={(ctrlProps: any) => {
                    return (
                      <>
                        <LoadingOverlay open={loading} />
                        <ReactSelect
                          label="DC Item *"
                          isMulti
                          value={ctrlProps?.field?.value}
                          fields={
                            dcItemSearchTerm.length > 0
                              ? dcItemList.filter(x =>
                                  x.label.toLowerCase().includes(dcItemSearchTerm.toLowerCase()),
                                )
                              : []
                          }
                          handleOnChange={(value: StringOption) =>
                            ctrlProps?.field?.onChange(value)
                          }
                          handleOnInputChange={(value: string) => setDcItemSearchTerm(value)}
                          fullWidth
                        />
                        <Validation
                          touched={form.formState.errors.dcItem}
                          error={requiredErrorMsg}
                        />
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {dynamicFrequencyList.map(x => {
                  return (
                    <Grid container spacing={2}>
                      <Grid item xs={5}>
                        <Controller
                          name={`frequencyId-${x.id}`}
                          defaultValue={x.frequency}
                          control={form.control}
                          rules={{ required: true }}
                          render={(ctrlProps: any) => {
                            return (
                              <>
                                <LoadingOverlay open={loading} />
                                <ReactSelect
                                  label="Frequency *"
                                  isMulti={false}
                                  value={ctrlProps?.field?.value}
                                  fields={PROTOCOL_FREQUENCY}
                                  handleOnChange={(value: StringOption) => {
                                    ctrlProps?.field?.onChange(value);
                                    addValue(x.id, DynamicFields.FREQUENCY, value);
                                  }}
                                  fullWidth
                                />
                                <Validation
                                  touched={`form.formState.errors.frequencyId-${x.id}`}
                                  error={
                                    x.frequency !== null
                                      ? ''
                                      : form.formState.isSubmitted
                                      ? requiredErrorMsg
                                      : ''
                                  }
                                />
                              </>
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Controller
                          name={`frequencyUnit-${x.id}`}
                          defaultValue={x.frequencyUnit}
                          control={form.control}
                          rules={{ required: true }}
                          render={(ctrlProps: any) => {
                            return (
                              <>
                                <LoadingOverlay open={loading} />
                                <ReactSelect
                                  label="Frequency Unit *"
                                  isMulti={false}
                                  value={ctrlProps?.field?.value}
                                  fields={PROTOCOL_FREQUENCY_UNIT}
                                  handleOnChange={(value: NumberOption) => {
                                    ctrlProps?.field?.onChange(value);
                                    addValue(x.id, DynamicFields.FREQUENCY_UNIT, value);
                                  }}
                                  fullWidth
                                />
                                <Validation
                                  touched={`form.formState.errors.frequencyUnit-${x.id}`}
                                  error={
                                    x.frequencyUnit !== null
                                      ? ''
                                      : form.formState.isSubmitted
                                      ? requiredErrorMsg
                                      : ''
                                  }
                                />
                              </>
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`frequencyType-${x.id}`}
                          defaultValue={x.frequencyType}
                          control={form.control}
                          rules={{ required: true }}
                          render={(ctrlProps: any) => {
                            return (
                              <>
                                <LoadingOverlay open={loading} />
                                <ReactSelect
                                  label="Frequency Type *"
                                  isMulti={false}
                                  value={ctrlProps?.field?.value}
                                  fields={PROTOCOL_FREQUENCY_TYPE}
                                  handleOnChange={(value: StringOption) => {
                                    ctrlProps?.field?.onChange(value);
                                    addValue(x.id, DynamicFields.FREQUENCY_TYPE, value);
                                  }}
                                  fullWidth
                                />
                                <Validation
                                  touched={`form.formState.errors.frequencyType-${x.id}`}
                                  error={
                                    x.frequencyType !== null
                                      ? ''
                                      : form.formState.isSubmitted
                                      ? requiredErrorMsg
                                      : ''
                                  }
                                />
                              </>
                            );
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        className={classes.centerIcon}
                        onClick={() => {
                          deleteDynamicFrequency(x.id);
                        }}
                      >
                        <CloseIcon />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name={`dcItemId-${x.id}`}
                          defaultValue={x.dcItem}
                          control={form.control}
                          rules={{ required: true }}
                          render={(ctrlProps: any) => {
                            return (
                              <>
                                <LoadingOverlay open={loading} />
                                <ReactSelect
                                  label="DC Item *"
                                  isMulti
                                  value={ctrlProps?.field?.value}
                                  fields={
                                    dcItemSearchTerm.length > 0
                                      ? dcItemList.filter(x =>
                                          x.label
                                            .toLowerCase()
                                            .includes(dcItemSearchTerm.toLowerCase()),
                                        )
                                      : []
                                  }
                                  handleOnChange={(value: StringOption) => {
                                    ctrlProps?.field?.onChange(value);
                                    addValue(x.id, DynamicFields.DC_ITEM, value);
                                  }}
                                  handleOnInputChange={(value: string) =>
                                    setDcItemSearchTerm(value)
                                  }
                                  fullWidth
                                />
                                <Validation
                                  touched={`form.formState.errors.dcItemId-${x.id}`}
                                  error={
                                    x.dcItem !== null && x.dcItem.length > 0
                                      ? ''
                                      : form.formState.isSubmitted
                                      ? requiredErrorMsg
                                      : ''
                                  }
                                />
                              </>
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent="flex-start" spacing={2}>
                  <Grid item>
                    <Button
                      data-qa-id={getQaId('button.addFrequency')}
                      onClick={() => addDynamicFrequency()}
                      variant="outlined"
                    >
                      + ADD Frequency
                    </Button>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button
                      data-qa-id={getQaId('button.cancel')}
                      onClick={() => {
                        form.reset();
                        props.onCancel();
                        setDynamicFrequencyList([]);
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <CircularLoadingButton
                      data-qa-id={getQaId('button.save')}
                      buttonLabel="Save"
                      onClick={form.handleSubmit(onSubmit)}
                      isLoading={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

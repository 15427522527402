import React from 'react';
import RecordVoiceOverRoundedIcon from '@mui/icons-material/RecordVoiceOverRounded';
import { buildQaId } from 'utils/build-qa-id';
import { IInterpreterService } from 'models/services/interpreter-service/IInterpreterService';
import { useStyles } from './need-interpreter-icon.styles';
import { ACCOUNT_NA, PIN_NA } from './constants';

const getQaId = buildQaId('need-interpreter-icon');

interface INeedInterpreterIconProps {
  languages?: { value: string; label?: string }[];
  interpreterServicePhoneNumbers?: IInterpreterService[];
}

export const NeedInterpreterIcon: React.FC<INeedInterpreterIconProps> = (
  props: INeedInterpreterIconProps,
): JSX.Element | null => {
  const classes = useStyles();
  const toolTipContent = React.useMemo<string | undefined>(() => {
    if (props.languages) {
      let defaultLabel = 'Needs interpreter';
      if (props.languages.length > 0) {
        defaultLabel += ' - ';
        defaultLabel += props.languages
          .filter(({ value }) => value != null)
          .map(({ label, value }) => label || value)
          .join(', ');
      }

      if (props.interpreterServicePhoneNumbers) {
        defaultLabel += ' - ';
        defaultLabel += props.interpreterServicePhoneNumbers
          .map(
            (item: IInterpreterService) =>
              `${item.service_group_name || 'All'} - ${item.interpreter_phone} - ${
                item.interpreter_account_number || ACCOUNT_NA
              } - ${item.interpreter_pin || PIN_NA}`,
          )
          .join(', ');
      }
      return defaultLabel;
    }
    return undefined;
  }, [props.languages, props.interpreterServicePhoneNumbers]);

  return props.languages != null ? (
    <span data-qa-id={getQaId('typography')} title={toolTipContent}>
      <RecordVoiceOverRoundedIcon className={classes.icon} />
    </span>
  ) : null;
};

import React from 'react';
import { InterventionButton } from 'components/intervention-button/intervention-button';
import dcJson from 'config/task-types/dc.json';
import { getTreeMapFromDataTypes } from 'services/utils/data-collect';
import { THERAPY_CONTEXT, TASK_CONTEXT } from 'constants/index';
import {
  DispensingStatusValue,
  SpecialtyTherapiesWf,
  TherapyWorkflowLabel,
  TtcNames,
} from 'constants/enums';
import { windowFeatureIsEnabled } from 'config/window-features';
import { staticProviders, getCommonProviders } from './common-provider';
import { PatientTherapiesClient } from '../../../clients/patient-therapies';

const DisabledInterventionButton = () => <InterventionButton disabled />;

const therapyWorkflow = async therapy => {
  const response = await PatientTherapiesClient.getTherapyWorkflow(
    therapy.diagnosis_code,
    therapy.specialty_type ?? null,
  );
  const workflowLabel = TherapyWorkflowLabel[response.data?.therapyWorkflow] ?? '';
  return workflowLabel;
};

export default (task, state) => ({
  ...staticProviders,
  ...getCommonProviders(task, state, dcJson),
  json: dcJson,
  initialValues: {
    canceled_dt: state.currentDateTime,
    completed_dt: state.currentDateTime,
    reviewed_dt: state.currentDateTime,
    data_collect_followup: !(
      task?.therapy_id &&
      state.therapies[task.therapy_id]?.dispensing_status_id === DispensingStatusValue.OptOut &&
      windowFeatureIsEnabled('task_firing_based_on_dispensing_status') &&
      (async () =>
        (await therapyWorkflow(state.therapies[task.therapy_id])) === SpecialtyTherapiesWf.SP)() &&
      state.therapies[task.therapy_id].trellis_therapeutic_class_name.toLowerCase() !==
        `${TtcNames.Oncology.toLowerCase()}`
    ),
    data_collect_view_toggle: 1,
    data_collected_date: state.currentDateTime,
  },
  dataCollectFields: getTreeMapFromDataTypes(state.clinicalDataTypes),
  dataCollectInformation: {
    id: task.id,
    status_id: task.status_id,
    patientClinicalDataId: task.patient_clinical_data_id,
    context: state.therapy ? THERAPY_CONTEXT : TASK_CONTEXT,
    therapy: task.therapy_id ? state.therapies[task.therapy_id] : null,
  },
  dataCollectViewOptions: [
    { id: 1, name: 'Data Collect' },
    { id: 2, name: 'Previous Data Collect' },
  ],
  daysFormatter: days => {
    if (!days) {
      return 'N/A';
    }
    return days % 7 === 0 ? `${days / 7} weeks` : `${days} days`;
  },
  customElements: [
    {
      id: 'disabled-intervention-button',
      content: DisabledInterventionButton,
    },
  ],
});

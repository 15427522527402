import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { compose } from 'recompose';
import { styles } from '../components/question/question.styles';

const ChangesNeededConfirmationPage = ({
  responseHandler,
  classes,
  confirmationPageIndex,
}: any) => {
  let questionText = (
    <p>
      The selection you have made will require contact with a pharmacy team member. Please select
      &quot;Confirm&quot; and a pharmacy team member will contact you. If you wish to change your
      selection please select &quot;I need to change my selection&quot;.
    </p>
  );
  if (confirmationPageIndex === 2) {
    questionText = (
      <p>
        You have removed all the items from this order. Please select &quot;Confirm&quot; to
        complete this request. If you wish to change your selection please select &quot;I need to
        change my selection&quot;.
      </p>
    );
  }

  return (
    <>
      <Typography className={classes.title}>
        {questionText}
        <br />
        <br />
        Please confirm your choice.
      </Typography>
      <Grid item container>
        <Grid item xs={12}>
          <Button
            size="large"
            className={classes.btnLarge}
            onClick={() => responseHandler(1)}
            data-qa-id="change-medications-confirm"
          >
            Confirm
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            size="large"
            className={classes.btnLarge}
            onClick={() => responseHandler(0)}
            data-qa-id="change-medications-cancel"
          >
            I need to change my selection
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default compose<any, any>(withStyles(styles))(ChangesNeededConfirmationPage);

import moment from 'moment';
import { convertToArborDate } from 'models/time/arbor-date';
import {
  ICurrentDeliveryInformation,
  IShippingVerificationScanHistoryItem,
  PackageLabelScanStatus,
  ShippingLabelScanStatus,
} from './types';

export const printLabelDetails = (
  createUserDisplayName: string | undefined,
  printedDate: Date | undefined,
): string | undefined => {
  const date = moment(printedDate).format('MM/DD/YYYY');
  const time = moment(printedDate).format('hh:mm A');

  return `${date} - Label generated ${time}${
    createUserDisplayName ? ` by ${createUserDisplayName}` : ''
  }`;
};

export const fakeScan = (input: string, delay = 24) => {
  const [key, ...keys] = input.split('');
  document.dispatchEvent(new KeyboardEvent('keydown', { key }));
  if (keys.length) {
    setTimeout(() => fakeScan(keys.join(''), delay), delay);
  }
};

export enum ScanSounds {
  Default, // any default scanner action
  Ok, // when scanning in an order
  Complete, // when the shipping label is successfully scanned and an order is complete
  Error, // when an improper scan happens
}

const soundAudio: Record<ScanSounds, HTMLAudioElement | null> = {
  [ScanSounds.Default]: null,
  [ScanSounds.Ok]: new Audio('/media/scan_ok_2.wav'),
  [ScanSounds.Complete]: new Audio('/media/scan_complete_1.wav'),
  [ScanSounds.Error]: new Audio('/media/scan_error_1.wav'),
};

export const playScanSound = (soundName: ScanSounds) => {
  if (soundName === ScanSounds.Default) {
    return;
  }
  const sound = soundAudio[soundName];
  sound?.play();
};

export const getSoundFor = (historyItem: IShippingVerificationScanHistoryItem) => {
  if (historyItem.status_message === 'ok') {
    if (historyItem.field_name === 'label') {
      return ScanSounds.Complete;
    }
    return ScanSounds.Ok;
  }
  if (historyItem.status_message === 'error') {
    return ScanSounds.Error;
  }
  return ScanSounds.Default;
};

export const isShippingPage = () => {
  const currentPath = window.location.pathname;
  return currentPath.endsWith('/shipping');
};

export interface PackageScanRequirements {
  ccCount: number;
  nrCount: number;
  isPickup: boolean;
}

export const validateOrderBarcodeScan = (
  orderId: string,
  labelValue: string,
  scanType: 'package' | 'label' | 'order',
) => {
  if (scanType === 'package') {
    const matcher = new RegExp(`^${orderId}-(NR|CC)$`);
    return matcher.test(labelValue);
  }
  if (scanType === 'label') {
    const matcher = new RegExp(`^${orderId}$`);
    return matcher.test(labelValue);
  }
  if (scanType === 'order') {
    return `${orderId}-FC` === labelValue;
  }
  return false;
};

export const getPackageScanCounts = (scanHistory: IShippingVerificationScanHistoryItem[]) => {
  const okPackages = scanHistory.filter(
    scan => scan.field_name === 'package' && scan.status_message === 'ok',
  );
  const ccOkCount = okPackages.filter(scan => scan.code_value.endsWith('CC')).length;
  const nrOkCount = okPackages.filter(scan => scan.code_value.endsWith('NR')).length;
  return {
    ccScans: ccOkCount,
    nrScans: nrOkCount,
  };
};

export const validatePackageScanCounts = (
  scanHistory: IShippingVerificationScanHistoryItem[],
  packageCountRequirements: PackageScanRequirements | null,
) => {
  if (!packageCountRequirements) return false;
  const { ccScans, nrScans } = getPackageScanCounts(scanHistory);
  const { ccCount, nrCount } = packageCountRequirements;
  const ccValid = ccCount > 0 ? ccScans > 0 : true;
  const nrValid = nrCount > 0 ? nrScans > 0 : true;
  return ccValid && nrValid;
};

export const createScanEntry = (
  currentUser: string,
  orderId: string,
  labelValue: string,
  scanType: 'package' | 'label' | 'order',
): IShippingVerificationScanHistoryItem => {
  const isValid = validateOrderBarcodeScan(orderId, labelValue, scanType);
  const scanEntry = {
    username: currentUser,
    field_name: scanType,
    status_message: isValid ? 'ok' : 'error',
    code_value: labelValue,
    updated_dt: new Date().toISOString(),
  };
  return scanEntry;
};

export const getPackageScanStatus = (
  scanHistory: IShippingVerificationScanHistoryItem[],
  packageCountRequirements: PackageScanRequirements | null,
) => {
  if (
    packageCountRequirements &&
    validatePackageScanCounts(scanHistory, packageCountRequirements)
  ) {
    return PackageLabelScanStatus.Complete;
  }
  return PackageLabelScanStatus.Pending;
};

export const getShippingScanStatus = (scanHistory: IShippingVerificationScanHistoryItem[]) => {
  const shippingHistory = scanHistory.filter(item => item.field_name === 'label');
  const mostRecent = shippingHistory[shippingHistory.length - 1];
  if (mostRecent && mostRecent.status_message === 'error') {
    return ShippingLabelScanStatus.Incorrect;
  }
  if (mostRecent && mostRecent.status_message === 'ok') {
    return ShippingLabelScanStatus.Complete;
  }
  return ShippingLabelScanStatus.Pending;
};

interface DeliveryTransformed {
  current: Partial<ICurrentDeliveryInformation>;
  previous: Partial<ICurrentDeliveryInformation>;
  keys: (keyof ICurrentDeliveryInformation)[];
}

const convertDate = (date?: string | Date) =>
  date ? convertToArborDate(date).getUtcDate(true) : null;

const transformKey = (key: string): string => {
  const newKey = key.replace(/^old/, '');
  return `${newKey[0].toLowerCase()}${newKey.slice(1)}`;
};

export const transformDeliveryInformation = (
  deliveryInformation?: ICurrentDeliveryInformation,
): DeliveryTransformed => {
  if (!deliveryInformation) {
    return { current: {}, previous: {}, keys: [] };
  }

  const deliveryInformationKeys = Object.keys(deliveryInformation);
  const { current, previous, keys } = deliveryInformationKeys.reduce(
    (obj, key) => {
      if (/^old/.test(key)) {
        const newKey = transformKey(key);
        return {
          current: {
            ...obj.current,
            [newKey]: deliveryInformation[newKey as keyof ICurrentDeliveryInformation],
          },
          previous: {
            ...obj.previous,
            [newKey]: deliveryInformation[key as keyof ICurrentDeliveryInformation],
          },
          keys: [...obj.keys, newKey] as DeliveryTransformed['keys'],
        };
      }
      return obj;
    },
    { current: {}, previous: {}, keys: [] } as any,
  );

  return {
    current: { ...current, shipDate: convertDate(current.shipDate) },
    previous: { ...previous, shipDate: convertDate(previous.shipDate) },
    keys,
  };
};

import React from 'react';
import WidthController from '../width-controller';

const SubHeader = ({ title, classes }) => {
  return (
    <div className={classes.root}>
      <WidthController>{title}</WidthController>
    </div>
  );
};
export default SubHeader;

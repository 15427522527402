import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const DosingPillIcon = React.forwardRef((props: SvgIconProps, ref: any) => (
  <SvgIcon width="20" height="16" viewBox="0 0 20 16" {...props} ref={ref}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3.91304C0 1.75039 1.73964 0 3.88901 0C6.03838 0 7.77801 1.75039 7.77801 3.91304V11.7391C7.77801 13.9018 6.03838 15.6522 3.88901 15.6522C1.73964 15.6522 0 13.9018 0 11.7391V3.91304ZM2.22229 3.91304V7.82609H5.55572V3.91304C5.55572 2.98719 4.80917 2.23602 3.88901 2.23602C2.96884 2.23602 2.22229 2.98719 2.22229 3.91304ZM10.4066 6.78843C10.285 6.66615 10.0802 6.68012 9.97947 6.81638C8.4065 9 8.57665 12.0606 10.5316 14.0276C12.4865 15.9946 15.5282 16.1658 17.6984 14.5831C17.8373 14.4818 17.8478 14.2756 17.7262 14.1533L10.4066 6.78843ZM11.2191 5.5691C13.3893 3.98641 16.431 4.15761 18.386 6.12461C20.3444 8.09161 20.511 11.1487 18.9381 13.3358C18.8374 13.472 18.6325 13.486 18.511 13.3637L11.1913 5.99884C11.0698 5.87655 11.0802 5.67042 11.2191 5.5691Z"
      fill="#70777A"
    />
  </SvgIcon>
));

import React from 'react';
import Header from '../header';

const Container = ({ title, header, footer, classes, children }) => {
  return (
    <div className={classes.container}>
      <Header title={title} />
      <div>{header}</div>
      <div className={classes.subContainer}>{children}</div>
      <div>{footer}</div>
    </div>
  );
};

export default Container;

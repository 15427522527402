export const groupBy = (list, property) =>
  list.reduce((acc, item) => {
    const value = item[property];
    if (Object.prototype.hasOwnProperty.call(acc, value)) {
      acc[value].push(item);
    } else {
      acc[value] = [item];
    }
    return acc;
  }, {});

export const combineObjects = (...objects) => {
  const result = {};
  objects.forEach(obj => {
    Object.keys(obj).forEach(key => {
      if (result[key] === undefined || result[key] === null) {
        result[key] = obj[key];
      }
    });
  });
  return result;
};

export const getObjectProperty = (obj, prop, defaultValue = '') => {
  if (obj) {
    if (Object.hasOwnProperty.call(obj, prop)) {
      return obj[prop];
    }
  }
  return defaultValue;
};

export const prioritizeListById = (prioritizedID, list) =>
  list.sort(i => {
    switch (i.id) {
      case prioritizedID:
        return -1;
      default:
        return 1;
    }
  });

/**
 *
 * @param {string} listStr comma seperated list of items in a string
 */
export const buildObjectForJson = listStr => {
  const json = {};
  if (listStr && listStr.length) {
    listStr.split(',').forEach(e => {
      json[e] = true;
    });
  }
  return json;
};

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Popover, Box, Button, IconButton } from '@mui/material';

import { ITherapy } from 'interfaces/redux/ITherapy';
import { IState } from 'interfaces/redux/IState';
import { ITask } from 'interfaces/redux/ITasks';
import { ControlledDatePickerCalendar } from 'components/react-hook-form-fields/date-picker/controlled-date-picker';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { CalendarToday } from '@mui/icons-material';
import { editTherapiesNBD } from 'actions/action-therapies';
import { ControlledDropdown } from 'components/react-hook-form-fields';

import { buildQaIdProp, buildQaId } from 'utils/build-qa-id';
import { nameOfFactory } from 'utils/types-util';

import { convertToArborDate } from 'models/time/arbor-date';
import { getTherapyFillCycleReasonOptions } from 'services/utils/task-service';
import { clearSelectedTasks } from 'actions/action-tasks';

type TherapyType = { patient_id: number; id: number };

interface TherapyDateFormProps {
  therapy: TherapyType | ITherapy | TherapyType[];
  disabled?: boolean;
  title?: string;
  iconButtonType?: boolean;
}

interface IFormFields {
  needsby_date?: Date;
  reason?: string;
}

const qaIdProp = buildQaIdProp('nbd-therapy-form');
const qaId = buildQaId('nbd-therapy-form');
const getFieldName = nameOfFactory<IFormFields>();
export const NeedsByDateDialogForm = React.forwardRef(
  ({ therapy, iconButtonType, ...props }: TherapyDateFormProps, ref: any): JSX.Element => {
    const [openNBD, setOpenNBD] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
    const handleClick = (e: any) => {
      setAnchorEl(e.currentTarget);
      setOpenNBD(true);
    };
    const {
      handleSubmit,
      control,
      formState: { isValid },
    } = useForm<any>({ mode: 'onChange' });

    const dispatch = useDispatch();

    const therapyFillCycleReasons = useSelector((state: IState) => {
      let t = ([] as (TherapyType | ITherapy)[]).concat(therapy)[0];
      if (!t || !t.id) {
        return state.lookups.therapyFillCycleReasons;
      }
      if (!('fill_cycle' in t)) {
        t = state.therapies.data[t.id];
      }
      return getTherapyFillCycleReasonOptions(state.lookups.therapyFillCycleReasons, t);
    });

    const onButtonClick = (e: any) => {
      setAnchorEl(e.currentTarget);
      setOpenNBD(true);
    };

    const onSubmit: SubmitHandler<IFormFields> = data => {
      if (therapy) {
        const therapies = Array.isArray(therapy) ? therapy : [therapy];
        const updatedTherapy = therapies.map((item: any) => {
          const payload = {
            patient_id: item.patient_id,
            fill_cycle_reason_id: data.reason,
            therapy_id: item.id,
            needsby_date: convertToArborDate(data.needsby_date).getUtcDate(),
          };

          return payload;
        });
        dispatch(
          editTherapiesNBD(
            updatedTherapy,
            () => setOpenNBD(false),
            () => setOpenNBD(false),
          ),
        );
      }

      dispatch(clearSelectedTasks());
    };
    return (
      <>
        {iconButtonType ? (
          <IconButton onClick={onButtonClick} disabled={props.disabled} size="small">
            <CalendarToday fontSize="small" />
          </IconButton>
        ) : (
          <Button
            onClick={onButtonClick}
            variant="contained"
            color="primary"
            startIcon={<CalendarToday />}
            disabled={props.disabled}
          >
            Edit NBD
          </Button>
        )}
        <Popover
          ref={ref}
          id="followup-popover"
          open={openNBD}
          anchorEl={anchorEl}
          onClose={() => setOpenNBD(false)}
          onClick={e => {
            e.stopPropagation();
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box p={2} minWidth={336} display="flex" flexDirection="column" alignItems="center">
            <h3>Select Needs by Date</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ControlledDatePickerCalendar
                defaultValue={null}
                name={getFieldName('needsby_date')}
                control={control}
              />
              <ControlledDropdown
                label="Reason for Change*"
                control={control}
                name={getFieldName('reason')}
                options={therapyFillCycleReasons}
                defaultValue={null}
                validations={{ required: true }}
                {...qaIdProp('reason')}
              />
              <ConfirmationPanel
                cancelButtonName="edit_therapy_cancel_button"
                submitButtonName="edit_therapy_submit_button"
                cancelButtonText="Cancel"
                submitButtonText="Save"
                disableSubmit={!isValid}
                handleCancel={() => setOpenNBD(false)}
              />
            </form>
          </Box>
        </Popover>
      </>
    );
  },
);

import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { fetchServiceGroups, fetchUsers } from 'actions/action-lookups';
import {
  addNote,
  fetchNotesSidebar,
  togglePinnedNote,
  updateNoteBar,
  filterNotesSidebar,
  fetchArchivedNotes,
} from 'actions/action-notes';
import { ADD_NOTE_FORM } from 'constants/index';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import ExpandingView from 'components/display/expanding-view';
import { noteTagTypes } from 'constants/lists';
import { goToAnchor } from 'utils/react-scrollable-anchor';

import { parseNote, noteAnchorTag } from 'services/utils/note-service';
import NoteDetail from './note-detail';
// import { NoteCommunicationDetail } from './note-communication-detail ';

import { styles } from './note-styles';

class NoteTreeView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { notes } = this.props;
    if (notes && notes.expandedLevel) {
      if (notes.expandedLevel.tag_type_id === 2) {
        setTimeout(() => goToAnchor(`therapy_${notes.expandedLevel.therapy_id}`), 0);
      } else {
        setTimeout(
          () =>
            goToAnchor(`${notes.expandedLevel.tag_type_id}_${notes.expandedLevel.tag_resource_id}`),
          0,
        );
      }
    }
  }

  render() {
    const { notes, users, serviceGroups, classes, therapies, expandedLevel, patient } = this.props;

    const findNoteById = noteId => notes.notes.allNotes.find(note => note.id === noteId);

    const taskStructure = taskVar => (
      <div>
        {Object.entries(taskVar).map(task => (
          <div>
            {task && task[1].length > 0
              ? task[1].map(singleTask => (
                  <div
                    id={`${noteTagTypes.find(taskType => taskType.label === task[0]).value}_${
                      singleTask.taskId
                    }`}
                  >
                    {singleTask &&
                      singleTask.notes &&
                      (singleTask.notes.pinnedNotes.length > 0 ||
                        singleTask.notes.nonPinnedNotes.length > 0 ||
                        (noteTagTypes &&
                          noteTagTypes.find(note => note.value === expandedLevel.tag_type_id) &&
                          noteTagTypes.find(note => note.value === expandedLevel.tag_type_id)
                            .label === task[0] &&
                          expandedLevel.tag_resource_id === singleTask.taskId)) && (
                        <ExpandingView
                          noBorder
                          label={`${
                            noteTagTypes.find(taskType => taskType.label === task[0]).displayLabel
                          }`}
                          isExpanded={
                            noteTagTypes &&
                            noteTagTypes.find(note => note.value === expandedLevel.tag_type_id) &&
                            noteTagTypes.find(note => note.value === expandedLevel.tag_type_id)
                              .label === task[0] &&
                            expandedLevel.tag_resource_id === singleTask.taskId
                          }
                          expandedContent={
                            <div>
                              <div>
                                {singleTask.notes.pinnedNotes.map(pinnedNote => (
                                  <div id={noteAnchorTag(pinnedNote)}>
                                    <NoteDetail
                                      key={
                                        pinnedNote && findNoteById(pinnedNote.id)
                                          ? findNoteById(pinnedNote.id).id
                                          : null
                                      }
                                      toggle={this.handleTogglePin}
                                      note={parseNote(
                                        findNoteById(pinnedNote.id),
                                        users,
                                        serviceGroups,
                                      )}
                                      classes={classes}
                                      inTreeView
                                    />
                                  </div>
                                ))}
                              </div>
                              <div>
                                {singleTask.notes.nonPinnedNotes.map(nonPinnedNote => (
                                  <div
                                    className={classes.treeViewSpacing}
                                    id={noteAnchorTag(nonPinnedNote)}
                                  >
                                    <NoteDetail
                                      key={
                                        findNoteById(nonPinnedNote.id)
                                          ? findNoteById(nonPinnedNote.id).id
                                          : null
                                      }
                                      toggle={this.handleTogglePin}
                                      note={parseNote(
                                        findNoteById(nonPinnedNote.id),
                                        users,
                                        serviceGroups,
                                      )}
                                      classes={classes}
                                      inTreeView
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          }
                        />
                      )}
                  </div>
                ))
              : null}
          </div>
        ))}
      </div>
    );

    return (
      <div className={classes.noteInLineWithTasks}>
        {notes && notes.notes && (
          <div>
            <Typography className={classes.patientNameDisplay}>
              {`${patient.first_name} ${patient.last_name}`}
            </Typography>
            <div>
              {notes.notes.pinnedNotes.map(pinnedNote => (
                <div className={classes.treeViewSpacing}>
                  <NoteDetail
                    key={findNoteById(pinnedNote.id).id}
                    toggle={this.handleTogglePin}
                    note={parseNote(findNoteById(pinnedNote.id), users, serviceGroups)}
                    classes={classes}
                    inTreeView
                  />
                </div>
              ))}
              {notes.notes.nonPinnedNotes.map(nonPinnedNote => (
                <div key={nonPinnedNote.id} className={classes.treeViewSpacing}>
                  <NoteDetail
                    key={findNoteById(nonPinnedNote.id).id}
                    toggle={this.handleTogglePin}
                    note={parseNote(findNoteById(nonPinnedNote.id), users, serviceGroups)}
                    classes={classes}
                    inTreeView
                  />
                </div>
              ))}
            </div>
            {notes && notes.tasks && <div>{taskStructure(notes.tasks)}</div>}

            {notes && notes.therapies && (
              <div>
                {notes.therapies.map(therapyVal => (
                  <div id={`therapy_${therapyVal.therapyId}`}>
                    {therapyVal &&
                      therapyVal.tasks &&
                      (therapyVal.notes.pinnedNotes.length > 0 ||
                        therapyVal.notes.nonPinnedNotes.length > 0 ||
                        Object.keys(therapyVal.tasks)
                          .map(task => therapyVal.tasks[task])
                          .filter(taskType => taskType.length > 0)
                          .some(taskArr =>
                            taskArr.some(
                              specificTask =>
                                specificTask.notes.pinnedNotes.length > 0 ||
                                specificTask.notes.nonPinnedNotes.length > 0,
                            ),
                          ) ||
                        expandedLevel.therapy_id === therapyVal.therapyId) && (
                        <ExpandingView
                          label={
                            therapies &&
                            therapyVal &&
                            therapyVal.therapyId &&
                            therapies.data &&
                            therapies.data[therapyVal.therapyId]
                              ? therapies.data[therapyVal.therapyId].drug_name
                              : ''
                          }
                          noBorder
                          isExpanded={expandedLevel.therapy_id === therapyVal.therapyId}
                          expandedContent={
                            <div>
                              <div>
                                {therapyVal.notes.pinnedNotes.map(pinnedNote => (
                                  <div
                                    className={classes.treeViewSpacing}
                                    id={noteAnchorTag(pinnedNote)}
                                  >
                                    <NoteDetail
                                      key={findNoteById(pinnedNote.id).id}
                                      toggle={this.handleTogglePin}
                                      note={parseNote(
                                        findNoteById(pinnedNote.id),
                                        users,
                                        serviceGroups,
                                      )}
                                      classes={classes}
                                      inTreeView
                                    />
                                  </div>
                                ))}
                                {therapyVal.notes.nonPinnedNotes.map(nonPinnedNote => (
                                  <div
                                    className={classes.treeViewSpacing}
                                    id={noteAnchorTag(nonPinnedNote)}
                                  >
                                    <NoteDetail
                                      key={findNoteById(nonPinnedNote.id).id}
                                      toggle={this.handleTogglePin}
                                      note={parseNote(
                                        findNoteById(nonPinnedNote.id),
                                        users,
                                        serviceGroups,
                                      )}
                                      classes={classes}
                                      inTreeView
                                    />
                                  </div>
                                ))}
                              </div>
                              {taskStructure(therapyVal.tasks)}
                            </div>
                          }
                        />
                      )}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { notes, patient, therapies } = state;
  return {
    patient,
    notes: notes || [],
    therapies,
    expandedLevel: notes.expandedLevel || false,
    archivedNotes: notes && notes.notes ? notes.notes.archivedNotes : [],
    users: state.lookups.users,
    serviceGroups: state.lookups.serviceGroups,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchNotesSidebar,
      filterNotesSidebar,
      fetchUsers,
      addNote,
      fetchServiceGroups,
      togglePinnedNote,
      updateNoteBar,
      fetchArchivedNotes,
    },
    dispatch,
  );
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(reduxForm({ form: ADD_NOTE_FORM })(NoteTreeView));

import React, { useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  benefitsInvestigationSubmissionFormActions,
  loadDrugPricing,
} from 'slices/benefits-investigation-modal-slice';
import { benefitsInvestigationDetailedHistoryActions } from 'slices/benefits-investigation-detailed-history-slice';
import { useStyles } from './styles';
import { EditHistory } from '../../../../components/icons/icons';
import { fetchPatientBi } from '../../../../actions/action-patient';
import { useTypedSelector } from '../../../../hooks/use-typed-selector';

interface IProps {
  therapyId: number;
  ndc: string;
  disabled?: boolean;
  taskId?: number;
  shouldFetchPatientBi?: boolean;
}

export const RunBiRightSide: React.FC<IProps> = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedPatientId = useTypedSelector(state => state.selectedPatientId);

  React.useEffect(() => {
    if (!selectedPatientId || !props.shouldFetchPatientBi) {
      return;
    }
    dispatch(fetchPatientBi(selectedPatientId));
  }, [selectedPatientId]);

  return (
    <>
      <Typography variant="caption">
        <Button
          className={classes.historyButton}
          onClick={() => {
            dispatch(benefitsInvestigationDetailedHistoryActions.open(props.therapyId));
          }}
        >
          <EditHistory />
        </Button>
      </Typography>
      <Button
        className={classes.button}
        onClick={() => {
          dispatch(loadDrugPricing(props.ndc));
          dispatch(benefitsInvestigationSubmissionFormActions.open(props.therapyId));
          if (props.taskId !== undefined) {
            dispatch(benefitsInvestigationSubmissionFormActions.setTaskId(props.taskId));
          }
        }}
        disabled={props.disabled}
      >
        Run Benefit Investigation
      </Button>
    </>
  );
};

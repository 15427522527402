import React from 'react';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { styles } from './field-styles';

const StaticText = ({ classes, fieldName, field, defaultValue }) => {
  const value = field ? field.toString() : defaultValue;
  return (
    <div className={classes.formFieldContainer}>
      {fieldName && (
        <Typography variant="caption" className={classes.fieldLabel}>
          {fieldName}
        </Typography>
      )}
      <Typography>{value || '-'}</Typography>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(StaticText);

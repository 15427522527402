/* eslint-disable import/no-import-module-exports */
import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './reducers/index';
import rootSaga from './sagas';
import { ScreenResize } from './actions/action-view';
import { history } from './history';
import ReduxPromise from './middleware/custom-redux-promise';
import patientIdCheckMiddleware from './middleware/patient-id-check';
import { actionToPlainObject } from './middleware/actionClassToPlainObject';
import { exceptionHandlerMiddleware } from './middleware/exception-handler';
import { ASYNC_REDUX_ERROR } from './constants/index';
import { notifyError, notifySuccess } from './actions/action-notifications';

const sagaMiddleware = createSagaMiddleware();

const snackbarErrorHandler = (error, lastState, lastAction, dispatch) => {
  // eslint-disable-next-line no-console
  console.error(error);
  if (lastAction.meta && lastAction.meta.errorMessage) {
    dispatch(notifyError(lastAction.meta.errorMessage + error));
    throw error;
  }
};

const handleRejectedPromiseMiddleware = errorHandler => store => next => action => {
  const result = next(action);
  if (!errorHandler || !result || !result.then) {
    return result || {};
  }

  return result.catch(err => errorHandler(err, store.getState(), action, store.dispatch));
};

const successMessenger = store => next => action => {
  const result = next(action);
  if (Boolean(result.then) && action.meta && action.meta.successMessage) {
    return result.then(res => {
      if (res === ASYNC_REDUX_ERROR) {
        return undefined;
      }
      store.dispatch(notifySuccess(action.meta.successMessage));
      return res;
    });
  }
  return result;
};

const errorMessenger = store => next => action => {
  const { error, type, payload } = action;
  if (action.meta && action.meta.ignoreError) {
    return next(action);
  }
  if (error) {
    switch (type) {
      case '@@redux-form/SET_SUBMIT_FAILED':
        break;
      case '@@redux-form/STOP_SUBMIT':
        break;
      default: {
        if (payload) {
          const { config, response } = payload;
          const { status, data } = response;
          const url = config.url ? `URL: ${config.url}` : '';
          const statusCode = status ? ` with status code ${status}` : '';
          let errorMsg;
          switch (status) {
            case 401:
              errorMsg = 'ERROR: User is not authenticated, please login';
              break;
            case 403:
              errorMsg = 'ERROR: User is not authorized for this action';
              break;
            case 404:
              errorMsg = 'ERROR: URL not found';
              break;
            case 501:
              errorMsg = 'ERROR: Internal server error, please contact admin';
              break;
            default:
              errorMsg = `ERROR: ${data?.message ?? 'unknown error, please contact admin'}`;
              break;
          }
          const messageDisplay = (
            <span>
              {url}
              {statusCode}
              <br />
              {errorMsg}
            </span>
          );
          store.dispatch(notifyError(messageDisplay));
          break;
        }
        break;
      }
    }
  }
  return next(action);
};

// add enhancers if any
const allStoreEnhancers = composeWithDevTools(
  applyMiddleware(
    thunk,
    actionToPlainObject,
    successMessenger,
    errorMessenger,
    handleRejectedPromiseMiddleware(snackbarErrorHandler),
    ReduxPromise,
    patientIdCheckMiddleware,
    exceptionHandlerMiddleware,
    routerMiddleware(history), // for dispatching history actions
    sagaMiddleware,
  ),
);

const configureStore = (context, isTest = false) => {
  const rootReducer = createRootReducer(history);
  const store = createStore(rootReducer, allStoreEnhancers);
  sagaMiddleware.run(rootSaga, context);

  if (isTest) {
    document.getElementById('root').addEventListener('resize', () => {
      store.dispatch(new ScreenResize(window.innerWidth));
    });
  }

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducers/index', () => {
        const reloadedRootReducer = createRootReducer(history);
        store.replaceReducer(reloadedRootReducer);
      });
    }
  }

  return store;
};

export default configureStore;

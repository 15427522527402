import React from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RadioGroup from 'components/form/radio/radio-group';
import RadioButton from 'components/form/radio/radio-button';
import Validation from 'components/form/validation/validation';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export default ({ input, meta: { touched, error, warning } }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <RadioGroup horizontal name="rx-type" id="rx-type" {...input} className={classes.radioGroup}>
        <RadioButton value="1">New</RadioButton>
        <RadioButton value="2">Existing</RadioButton>
      </RadioGroup>
      <Validation touched={touched} error={error} warning={warning} />
    </Grid>
  );
};

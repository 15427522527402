import {
  IGpiFrequencyItem,
  IGpiProtocolRequest,
} from 'models/application-manager/cpmp/IGetGpiProtocolsResponse';

export interface ITableProps {
  searchTerm: string | undefined;
  triggerReload: Date | undefined;
  userHasPermissionToEditDcItem: boolean;
  handleGpiProtocolEdit: (gpi_10: IGpiProtocolItem) => void;
  handleReloadDataTrigger: (date: Date | undefined) => void;
}

export interface IEditGpiProtocolProps {
  parent: IGpiProtocolItem;
  onEdit: (gpi_10: IGpiProtocolItem) => void;
}

export interface IGpiTableProps {
  userPermission: boolean;
  searchString: string;
  reloadTrigger: Date | undefined;
  onReload: (value: any) => void;
  handleGpiProtocolEdit: (gpi_10: IGpiProtocolItem) => void;
}

export interface IAddNewGpiProtocolModal {
  open: boolean;
  closeModal: () => void;
  reloadListTrigger: (date: Date) => void;
}

export interface IEditGpiProtocolModal {
  open: boolean;
  gpiProtocol: IGpiProtocolItem;
  closeModal: () => void;
  reloadListTrigger: (date: Date) => void;
}

export interface IGpiProtocolFormProps {
  onChange: (gpiProtocol: IGpiProtocolRequest) => void;
  errors: IFormErrors;
  edit?: boolean;
  gpiProtocol?: IGpiProtocolItem;
}

export interface IGpiFormFrequencyItem extends Omit<IGpiFrequencyItem, 'dcItems'> {
  dcItems: { label: string; value: number }[];
  prevFrequency: string;
}

export interface IFrequencyItem {
  protocolFrequency: string;
  frequencyUnit: string;
  frequencyType: string;
  dcItems: number[];
}

export interface IGpiProtocolItem {
  drugName: string;
  medicationType: string;
  gpi_10: number;
  children: IFrequencyItem[];
  items: IFrequencyItem[];
}

export interface IResponse {
  totalCount: number;
  results: IGpiProtocolItem[];
}

export interface IFormFields {
  gpi_10: number;
  medicationType: string;
  drugName: string;
  frequency: string;
  dcItems: number[];
}

export enum ITimedFrequencies {
  ongoing = 'ongoing',
  scheduled = 'scheduled',
}

export type IItemsType = 'baseline' | 'ongoing' | 'scheduled' | 'suggested';

export interface IFormItemsErrors {
  protocolFrequency: boolean;
  frequencyUnit: boolean;
  frequencyType: boolean;
  dcItems: boolean;
}
export interface IFormErrors {
  drug: boolean;
  frequencyItems: boolean;
  items: Partial<Record<IItemsType, IFormItemsErrors>>;
}

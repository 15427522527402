import DrugUtilizationReviewContainer from 'containers/tasks/drug-utilization-review/drug-utilization-review-container';
import ExpansionSection from 'components/expansion-section';
import React, { FC } from 'react';
import { ITask } from 'interfaces/redux/ITasks';
import { windowFeatureIsEnabled } from 'config/window-features';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../sections.styles';
import { IDurSectionProps } from '../interfaces/IDurSection';

const DurSection: FC<IDurSectionProps> = (props: IDurSectionProps): JSX.Element => {
  const { drugUtilizationReview, classes, readOnly = false, isCslDur = false } = props;

  const { task, lastReviewDate, lastReviewUser } = drugUtilizationReview;

  const outerSectionClasses = {
    header: classes.sectionWrapperHeader,
    expandIcon: classes.sectionWrapperExpandIcon,
    subtitle: classes.sectionWrapperSubtitle,
    expansionPanel: classes.sectionWrapperExpansionPanel,
    content: classes.sectionWrapperContent,
  };

  const getDURWrapperSubtitle = (
    lastDurTask: ITask,
    lastReviewDate: string,
    lastReviewUser: string,
  ) => {
    if (!lastDurTask) {
      return '';
    }
    return lastReviewDate ? `Reviewed ${lastReviewDate} by ${lastReviewUser}` : 'Pending Review';
  };

  const generateButtonEnabled = task.allergies || task.medications;
  return (
    <ExpansionSection
      title="Drug Utilization Review"
      subtitle={getDURWrapperSubtitle(task, lastReviewDate, lastReviewUser)}
      classes={outerSectionClasses}
      unmountOnExit
      mountOnEnter
      onChange={props.onExpand}
    >
      {task ? (
        <div>
          <DrugUtilizationReviewContainer
            currentTab="tasks"
            durTask={task}
            generateButtonEnabled={generateButtonEnabled}
            hideTopHeader
            readOnly={readOnly}
            isSnapshot={readOnly}
            isCslDur={isCslDur}
            hideMedicationChildren={props.hideMedicationChildren}
          />
        </div>
      ) : (
        <div>No Drug Utilizations Reviews created yet.</div>
      )}
    </ExpansionSection>
  );
};

export default withStyles(styles)(DurSection);

import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

export const LockedUnlockedFormStyles = (theme: Theme) => {
  return createStyles({
    container: {
      padding: theme.spacing(1),
    },
    option: {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[200],
      },
    },
    optionType: {
      padding: theme.spacing(1),
    },
  });
};

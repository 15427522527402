import * as React from 'react';

const SVGComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 8 18"
    width={6}
    height={16}
    {...props}
  >
    <g transform="translate(1, 1)">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        data-darkreader-inline-fill=""
        data-darkreader-inline-stroke=""
        style={{
          // @ts-ignore
          '--darkreader-inline-fill': 'none',
          '--darkreader-inline-stroke': 'none',
        }}
      >
        <g
          id="Shipping-Module-V2"
          transform="translate(-151.000000, -756.000000)"
          fill="#5B87B0"
          data-darkreader-inline-fill=""
          style={{
            // @ts-ignore
            '--darkreader-inline-fill': '#6a95b7',
          }}
        >
          <rect
            id="Rectangle-Copy-2"
            x={151}
            y={756}
            width={6}
            height={16}
            rx={2}
            vectorEffect="non-scaling-stroke"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVGComponent;

/* eslint-disable react/function-component-definition */
import React from 'react';
import { Button, Grid, Modal, Typography, TableContainer, Table, TableBody } from '@mui/material';
import { getModalStyle } from 'services/utils/styles-service';
import Divider from 'components/divider';
import { buildQaId } from 'utils/build-qa-id';
import moment from 'moment';
import { IMedSyncRowData } from 'models/meds/IMedSync';
import { nameOfFactory } from 'utils/types-util';
import { ControlledDatePicker } from 'components/react-hook-form-fields/date-picker/controlled-date-picker';
import { ControlledText } from 'components/react-hook-form-fields';
import { MED_SYNC_STATUS, THERAPY_MED_SYNC } from 'constants/index';
import { CircularProgress } from '@mui/material';
import { styles } from './medSyncModal.style';
import { MedSyncTableRow } from '../table/tableRow';
import { MedSyncTableHeader } from '../table/tableHeader';
import useMedSyncModal from './use-med-sync-modal';
import { IMedSyncFormFields, IMedSyncPreferences } from '../../common';

export interface IMedSyncModalProps {
  open: boolean;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: (formValues: IMedSyncFormFields, medSyncData: IMedSyncRowData[]) => Promise<any>;
  currentMedSyncData: IMedSyncRowData[];
  currentMedSyncPreferences: IMedSyncPreferences;
}

const getQaId = buildQaId('med-sync.med-sync-modal', '.');
const getFieldName = nameOfFactory<IMedSyncFormFields>();

const disableItemRow = (item: IMedSyncRowData, syncTimeFrame: number | null) => {
  return Boolean(
    item.dispenseStatusId !== MED_SYNC_STATUS.OPT_IN ||
      (syncTimeFrame !== null &&
        item.inMedSync === THERAPY_MED_SYNC.NOT_IN_MED_SYNC &&
        syncTimeFrame % item.daysSupply !== 0 &&
        item.daysSupply % syncTimeFrame !== 0),
  );
};

export const MedSyncModal: React.FC<IMedSyncModalProps> = ({
  currentMedSyncData,
  currentMedSyncPreferences,
  open,
  isLoading,
  onCancel,
  onSubmit,
}: IMedSyncModalProps): JSX.Element | null => {
  const classes: any = styles();

  const {
    modalMedSyncData: medSyncData,
    modalAnchorDate: anchorDate,
    modalSyncTimeFrame: syncTimeFrame,
    handleChangeAnchorDate,
    handleSelectId,
    handleClickSelectAll,
    handleSelectAdherencePack,
    handleRequestSort,
    selectAllValue,
    order,
    orderBy,
    form,
    reset,
    isEdited,
  } = useMedSyncModal({ currentMedSyncData, currentMedSyncPreferences });

  const disableAnchorDate = selectAllValue === 'none';
  const disableSave = !isEdited || medSyncData.some(therapy => therapy.inConflict);

  return (
    <Modal open={open} data-qa-id={getQaId('wrapper')}>
      <div style={getModalStyle()} className={classes.modal}>
        <Grid container direction="column">
          {/* Title */}
          <Grid item xs={12} className={classes.title}>
            <Typography className={classes.title} data-qa-id={getQaId('title')}>
              Med Sync
            </Typography>
          </Grid>

          <Grid
            container
            item
            xs={12}
            data-qa-id={getQaId('form')}
            className={classes.medSyncContainer}
            spacing={5}
          >
            <Grid item xs={12}>
              <TableContainer className={classes.table}>
                <Table>
                  <MedSyncTableHeader
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    selectAll={selectAllValue}
                    onSelectAllClick={handleClickSelectAll}
                    disableMainCheckbox={false}
                  />
                  <TableBody>
                    {medSyncData.map(item => {
                      return (
                        <MedSyncTableRow
                          key={item.id}
                          data={item}
                          onSelectRow={handleSelectId}
                          onSelectAdherence={handleSelectAdherencePack}
                          disableRow={disableItemRow(item, syncTimeFrame)}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              {medSyncData.length > 0 && (
                <Grid container spacing={5} className={classes.fieldsSection}>
                  <Grid item xs={3}>
                    <ControlledDatePicker
                      label={disableAnchorDate ? 'Anchor Date' : 'Anchor Date *'}
                      defaultValue={anchorDate ?? ''}
                      control={form.control}
                      name={getFieldName('anchorDate')}
                      fullWidth
                      qaId="anchor-date"
                      validations={{ required: !disableAnchorDate }}
                      disabled={disableAnchorDate}
                      inputMetaData={{
                        touched: Boolean(form.formState.errors.anchorDate),
                        error: 'Required',
                      }}
                      onChange={handleChangeAnchorDate}
                      minDate={moment().toDate()}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <ControlledText
                      name={getFieldName('syncTimeFrame')}
                      control={form.control}
                      defaultValue={syncTimeFrame ?? ''}
                      disabled
                      validations={{ required: false }}
                      percentWith={100}
                      label="Sync Timeframe"
                    />
                  </Grid>
                  <Typography className={classes.dayText}>Days</Typography>
                </Grid>
              )}
            </Grid>
            {medSyncData.length === 0 && (
              <Grid item xs={12}>
                <Typography className={classes.notificationText}>
                  No active therapies with dispensing turned on
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} className={classes.divider}>
              <Divider />
            </Grid>
          </Grid>

          {/* {Cancel and save buttons} */}
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-end"
            className={classes.bottonContainer}
          >
            <Grid item xs={3}>
              <Grid container justifyContent="space-around">
                <Grid item>
                  <Button
                    data-qa-id={getQaId('button.cancel')}
                    onClick={() => {
                      reset();
                      onCancel();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    data-qa-id={getQaId('button.save')}
                    color="primary"
                    variant="contained"
                    onClick={form.handleSubmit((formValues: any) => {
                      onSubmit(formValues, medSyncData);
                    })}
                    disabled={isLoading || disableSave}
                  >
                    {isLoading ? (
                      <CircularProgress
                        data-qa-id="loading-preview"
                        size={20}
                        style={{ marginLeft: 10, color: 'white' }}
                        variant="indeterminate"
                      />
                    ) : (
                      'Save'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

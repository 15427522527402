import { isValidArray, isObject } from 'services/utils/common-service';

const compareMedication = (a, b) => {
  // Sort meds by trellis icon
  if (!a.showIcon && b.showIcon) {
    return 1;
  }
  if (a.showIcon && !b.showIcon) {
    return -1;
  }
  // Sort meds by specialty
  if (a.is_specialty_drug !== 'Y' && b.is_specialty_drug === 'Y') {
    return 1;
  }
  if (a.is_specialty_drug === 'Y' && b.is_specialty_drug !== 'Y') {
    return -1;
  }
  return a.drug_name.localeCompare(b.drug_name);
};

export const sortMedicationGroups = medicationsGroups => {
  if (isValidArray(medicationsGroups)) {
    return medicationsGroups.sort((a, b) => compareMedication(a.header, b.header));
  }
  return [];
};

export const findLinkedTherapyByMedication = (therapies, medication) => {
  let result;
  if (!isObject(therapies) || !isObject(medication)) {
    return result;
  }
  Object.keys(therapies).forEach(tid => {
    const therapy = therapies[tid];
    if (therapy.medication_id === medication.id) {
      result = therapy;
    }
  });
  return result;
};

// eslint-disable-next-line
// Show trellis icon if On therapy and (opt-in dispensing or opt-in counseling) or adminStatus in (pre therapy, on-hold)
export const showMedicationTrellisIcon = therapy => {
  if (therapy) {
    return (
      therapy.administration_status === 'Pre-Therapy' ||
      therapy.administration_status === 'On Hold' ||
      (therapy.administration_status === 'On Therapy' &&
        (therapy.dispensing_status_id === 2 || therapy.clinical_support_status_id === 2))
    );
  }
  return false;
};

export const linkTherapiesMedications = (medications, therapies) => {
  if (isValidArray(medications) && therapies) {
    return medications.map(med => {
      const linkedTherapy = findLinkedTherapyByMedication(therapies, med);
      return {
        ...med,
        therapy: linkedTherapy,
        showIcon: showMedicationTrellisIcon(linkedTherapy),
      };
    });
  }
  return [];
};

/**
 * Prepare medications groups for table rendering. It only works for NDC grouping.
 * If there are medications it incldues them for the appropieted med group, elsewhere it just uses
 * the med group as only medication child.
 * @param {*} medicationGroups Medications groups from store (not empty on first render)
 * @param {*} medicationList Medications from store (empty on first render)
 * @returns Medications groups ready to populate meds list component table.
 */
export const addMedsIntoMedGroups = (medicationGroups, medicationList) => {
  const medicationGroupMap = new Map();
  medicationGroups.forEach(mg => {
    const medicationsForNdc = medicationList.filter(({ ndc }) => ndc === mg.ndc);
    medicationGroupMap.set(mg.ndc, {
      header: {
        ...mg,
      },
      children: !medicationsForNdc.length ? [mg] : medicationsForNdc,
    });
  });
  return sortMedicationGroups(Array.from(medicationGroupMap.values()));
};

export const sortMedications = medications => {
  if (isValidArray(medications)) {
    return medications.sort((a, b) => compareMedication(a, b));
  }
  return null;
};

export const sliceGpi10 = gpi => {
  if (!gpi) {
    return null;
  }
  return `${gpi}`.slice(0, 10);
};

export const getUniqueListOfGpi10 = medications => {
  const medicationMap = new Map();
  medications.forEach(med => {
    const gpi10 = sliceGpi10(med.gpi);
    const mapKey = `${gpi10}-${med.drug_name}`;
    medicationMap.set(mapKey, {
      id: gpi10,
      name: med.drug_name,
    });
  });
  return Array.from(medicationMap.values());
};

export const findMedicationByGpi10 = (medications, gpi10) =>
  medications.find(med => med.gpi.substr(0, 10) === gpi10);

enum FillCoordinationFieldNames {
  New_Allergies = 'new_allergies',
  Side_Effects = 'side_effects',
  Changes_In_Medication = 'changes_in_medication',
  Patient_Missed_Doses = 'patient_missed_doses',
  New_Medical_Conditions = 'new_medical_conditions',
  Hospital_Visit = 'hospital_visit',
  New_Infections = 'new_infections',
  Patient_Questions = 'patient_questions',
}

export default FillCoordinationFieldNames;

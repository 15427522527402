import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'interfaces/redux/IState';
import { getTaskKey } from './tasks-table/utils';

export const selectAllTasks = (state: IState) => Object.values(state.tasks?.data ?? {});
export const selectDraftTasksState = (state: IState) => state.tasks.draftTasks;
export const selectSelectedTaskKeysState = (state: IState) => state.tasks.selectedTaskKeys;
export const selectSelectedWagTaskKeysState = (state: IState) => state.tasks.selectedWagTaskKeys;

export const selectSelectedTasks = createSelector(
  [selectAllTasks, selectSelectedTaskKeysState],
  (tasks, selectedTaskKeys) => {
    return tasks.filter(task => selectedTaskKeys.includes(getTaskKey(task)));
  },
);

export const selectSelectedWagTasks = createSelector(
  [selectAllTasks, selectSelectedWagTaskKeysState],
  (tasks, selectedWagTaskKeys) => {
    return tasks.filter(task => selectedWagTaskKeys.includes(getTaskKey(task)));
  },
);

const getTaskKeyArg = (state: IState, taskKeyArg: string) => taskKeyArg;
export const getIsTaskKeySelected = createSelector(
  [selectSelectedTaskKeysState, getTaskKeyArg],
  (selectedTaskKeys, taskKeyArg) => selectedTaskKeys.includes(taskKeyArg),
);

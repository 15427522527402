export const accordianStyles = theme => ({
  accordianPanel: {
    marginTop: 0,
    marginLeft: '30px !important',
    marginRight: '30px !important',
    borderRadius: '5px !important',
  },
  accordianPanelNoSideMargin: {
    marginLeft: '0 !important',
    marginRight: '0 !important',
    border: 'none',
    boxShadow: 'none',
  },
  hiddenExpandAccordionPanel: {
    boxShadow: 'none',
  },
  hiddenExpandAccordionTitle: {
    marginLeft: '0 !important',
  },
  stretchedPanel: {
    paddingRight: 0,
    paddingLeft: 10,
    paddingVertical: 0,
    width: '100%',
    margin: '0 !important',
  },
  specialtyTag: {
    'borderRadius': 3,
    'color': theme.palette.primary.white,
    'marginLeft': 5,
    'paddingLeft': 2,
    'paddingRight': 2,
    'backgroundColor': theme.palette.primary.success,
    'fontSize': theme.font.smallFontSize,
    'fontWeight': theme.font.weight.thick,
    'marginTop': 3,
    '&.CDM': {
      backgroundColor: theme.palette.primary.yellow,
      color: theme.palette.primary.deepBlue,
    },
    '&.RS': {
      backgroundColor: theme.palette.primary.yellow,
      color: theme.palette.primary.deepBlue,
    },
  },
});

import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {
  renderDropdown as RenderDropdown,
  renderNumberField as RenderNumberField,
} from 'components/form/field/redux-field';
import { PERIOD_DAYS_UNITS, PERIOD_WEEKS_UNITS, PERIOD_DAYS_SELECTOR_UNITS } from 'constants/index';

const useStyles = makeStyles(theme => ({
  formRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  fieldLabel: {
    fontSize: '12px',
    fontWeight: '300',
    color: theme.palette.primary.formLabel,
    minHeight: 27 + 3,
  },
}));

export default ({ label, input, meta, disabled, labelSize, qaId }) => {
  const classes = useStyles();

  let numberValue = input.value;
  let defaultUnit = PERIOD_DAYS_UNITS;
  if (input.value % 7 === 0) {
    numberValue = parseInt(numberValue / 7, 10);
    defaultUnit = PERIOD_WEEKS_UNITS;
  }

  const [amount, setAmount] = useState(numberValue);
  const [unit, setUnit] = useState(defaultUnit);

  const na = !amount;

  useEffect(() => {
    let totalDays = parseInt(amount || 0, 10);
    if (unit === PERIOD_WEEKS_UNITS) {
      totalDays *= 7;
    }

    input.onChange(totalDays);
  }, [amount, unit]);

  useEffect(() => {
    if (Number(amount) === 0) {
      setAmount('');
    }
  }, [amount]);

  return (
    <Grid item data-qa-id={qaId}>
      <Typography className={classes.fieldLabel}>{label}</Typography>
      <Grid className={classes.formRow}>
        <RenderNumberField
          input={{
            value: amount,
            onChange: e => setAmount(e.target.value),
          }}
          meta={meta}
          disabled={disabled}
          labelSize={labelSize}
          qaId={`${qaId}-value`}
          width="30%"
          placeholder="N/A"
        />
        {!na && (
          <RenderDropdown
            onBlur={() => input.onBlur()}
            meta={meta}
            disabled={disabled || na}
            labelSize={labelSize}
            input={{ value: unit, onChange: setUnit }}
            fields={PERIOD_DAYS_SELECTOR_UNITS}
            qaId={`${qaId}-unit`}
          />
        )}
      </Grid>
    </Grid>
  );
};

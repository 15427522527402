/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import { Modal, Box, Grid, Typography } from '@mui/material';
import { reduxForm, Field } from 'redux-form';
import {
  renderTextField,
  renderDropdown,
  renderLast4ssnField,
} from 'components/form/field/redux-field';
import { required, validateDate, validateLast4ssn } from 'components/form/validation/validation';
import FormHeader from 'components/form/header/header';
import { compose } from 'recompose';
import withStyles from '@mui/styles/withStyles';
import { convertToArborDate } from 'models/time/arbor-date';
import { useSelector, connect, useDispatch } from 'react-redux';
import { getPreferredZip } from 'services/utils/demographic-service';
import moment from 'moment';
import { notifySuccess } from 'actions/action-notifications';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import ReconciliationCard from 'components/card/reconciliation-card';
import { closeEligibilityCheck, updateEligibilityCheck } from 'actions/action-eligibility-check';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { addPbmInsurance, fetchPbmInsurances } from 'actions/action-financials';
import { BenefitsInvestigationFields } from '../../constants/lists';
// eslint-disable-next-line max-len
import EditPbmInsurance from '../../containers/patient/financials/pbm-insurances/edit-pbm-insurance';
import { styles } from './eligibility-check-modal-styles';
import {
  eligibilityCheck,
  ERROR_MESSAGE_PREFIX,
  APPROVED_ELIGIBILITY_CHECK_TRANSACTION_CODE,
} from '../../services/utils/eligibility-check-service';
import { EDIT_PBM_INSURANCE_FORM } from '../../constants';
import { logger } from '../../winston-logger';

const EligibilityCheckModal = props => {
  const { classes, handleSubmit, patient } = props;

  const eligibilityCheckState = useSelector(state => state.eligibilityCheck);
  const financials = useSelector(state => state.financials);
  const relationships = useSelector(state => state.lookups.insuranceRelationships);
  const dispatch = useDispatch();

  const [displayInitialForm, setDisplayInitialForm] = useState(true);
  const [comparedInsuranceData, setComparedInsuranceData] = useState([]);
  const [displaySubForm, setDisplaySubForm] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleEligibilityFormSubmit = values => {
    setSubmitting(true);
    setErrorMessage('');
    eligibilityCheck(
      patient.id,
      values.last_name,
      values.first_name,
      values.dob,
      values.zip,
      values.gender,
      values.ssn,
      values.date_of_service,
      financials,
      relationships,
    )
      .then(result => {
        setSubmitting(false);
        const { comparedData, message, transactionResponseStatus } = result;
        if (message && message.startsWith(ERROR_MESSAGE_PREFIX)) {
          setErrorMessage(message);
          if (transactionResponseStatus !== APPROVED_ELIGIBILITY_CHECK_TRANSACTION_CODE) {
            return;
          }
        }

        if (comparedData && comparedData.length) {
          setComparedInsuranceData(comparedData);
          setDisplayInitialForm(false);
        } else if (!message) {
          setErrorMessage('No eligibility check results');
        }
      })
      .catch(error => {
        logger.error(error);
        setSubmitting(false);
        setErrorMessage('Eligibility check could not be processed. Please try again.');
      });
  };

  const handleClose = () => {
    setErrorMessage('');
    setSubmitting(false);
    dispatch(closeEligibilityCheck({ ...eligibilityCheckState, open: false }));
  };

  const getUpdateInformation = (left, original, field) => {
    const leftValue =
      left && left.filter(i => i.name === field)[0]
        ? left.filter(i => i.name === field)[0].value
        : undefined;
    const originalValue = original[field];
    return leftValue || originalValue;
  };

  const removeInsurance = (index, otherFunction) => {
    const remainingInsurances = comparedInsuranceData.filter((item, itemIndex) => {
      return index !== itemIndex;
    });
    setComparedInsuranceData(remainingInsurances);
    if (remainingInsurances.length === 0) {
      handleClose();
    }
    if (otherFunction) {
      otherFunction();
    }
  };

  const getLisFieldInfo = insuranceData => {
    const lisFields = {
      benefitId: getUpdateInformation(insuranceData.left, insuranceData.original, 'lis_benefit_id'),
      cmsLowIncomeCostSharing: getUpdateInformation(
        insuranceData.left,
        insuranceData.original,
        'lis_cms_low_income_cost_sharing',
      ),
      contractNumber: getUpdateInformation(
        insuranceData.left,
        insuranceData.original,
        'lis_contract_number',
      ),
      dateOfService: getUpdateInformation(
        insuranceData.left,
        insuranceData.original,
        'lis_date_of_service',
      ),
      effectiveDate: getUpdateInformation(
        insuranceData.left,
        insuranceData.original,
        'lis_effective_date',
      ),
      effectiveDateMbi: getUpdateInformation(
        insuranceData.left,
        insuranceData.original,
        'lis_effective_date_mbi',
      ),
      formularyId: getUpdateInformation(
        insuranceData.left,
        insuranceData.original,
        'lis_formulary_id',
      ),
      level: getUpdateInformation(insuranceData.left, insuranceData.original, 'lis_level'),
      medicareBeneficiaryId: getUpdateInformation(
        insuranceData.left,
        insuranceData.original,
        'lis_medicare_beneficiary_id',
      ),
      medicarePartDCode: getUpdateInformation(
        insuranceData.left,
        insuranceData.original,
        'lis_medicare_part_d_code',
      ),
      medicarePlanType: getUpdateInformation(
        insuranceData.left,
        insuranceData.original,
        'lis_medicare_plan_type',
      ),
      nextMedicarePartDEffectiveStart: getUpdateInformation(
        insuranceData.left,
        insuranceData.original,
        'lis_next_medicare_part_d_effective_start',
      ),
      nextMedicarePartDTermination: getUpdateInformation(
        insuranceData.left,
        insuranceData.original,
        'lis_next_medicare_part_d_termination',
      ),
      otherPayorIdCount: getUpdateInformation(
        insuranceData.left,
        insuranceData.original,
        'lis_other_payor_id_count',
      ),
      terminationDate: getUpdateInformation(
        insuranceData.left,
        insuranceData.original,
        'lis_termination_date',
      ),
      transactionResponseStatus: getUpdateInformation(
        insuranceData.left,
        insuranceData.original,
        'lis_transaction_response_status',
      ),
    };
    return lisFields;
  };

  const handleAddNewInsurance = index => {
    const d = comparedInsuranceData[index - 1];
    const startDate = getUpdateInformation(d.left, d.original, 'start_date');
    const endDate = getUpdateInformation(d.left, d.original, 'end_date');

    // Low income subsidy fields
    const lisFields = getLisFieldInfo(d);

    const payload = {
      start_date: startDate ? convertToArborDate(startDate).getUtcDate() : undefined,
      end_date: endDate ? convertToArborDate(endDate).getUtcDate() : undefined,
      pbm_rx_group_number: getUpdateInformation(d.left, d.original, 'pbm_rx_group_number'),
      pbm_phone_number: getUpdateInformation(d.left, d.original, 'plan_phone_number'),
      pbm_processor_control_number: getUpdateInformation(
        d.left,
        d.original,
        'pbm_processor_control_number',
      ),
      insurance_type_id: getUpdateInformation(d.left, d.original, 'insurance_type_id'),
      pbm_name: getUpdateInformation(d.left, d.original, 'pbm_name'),
      pbm_patient_id: getUpdateInformation(d.left, d.original, 'pbm_patient_id'),
      pbm_bin_number: getUpdateInformation(d.left, d.original, 'pbm_bin_number'),
      pbm_relation_code: getUpdateInformation(d.left, d.original, 'pbm_relation_code'),

      // LIS fields
      lis_benefit_id: lisFields.benefitId,
      lis_cms_low_income_cost_sharing: lisFields.cmsLowIncomeCostSharing,
      lis_contract_number: lisFields.contractNumber,
      lis_date_of_service: lisFields.dateOfService
        ? convertToArborDate(lisFields.dateOfService).getUtcDate()
        : undefined,
      lis_effective_date: lisFields.effectiveDate
        ? convertToArborDate(lisFields.effectiveDate).getUtcDate()
        : undefined,
      lis_effective_date_mbi: lisFields.effectiveDateMbi
        ? convertToArborDate(lisFields.effectiveDateMbi).getUtcDate()
        : undefined,
      lis_formulary_id: lisFields.formularyId,
      lis_level: lisFields.level,
      lis_medicare_beneficiary_id: lisFields.medicareBeneficiaryId,
      lis_medicare_part_d_code: lisFields.medicarePartDCode,
      lis_medicare_plan_type: lisFields.medicarePlanType,
      lis_next_medicare_part_d_effective_start: lisFields.nextMedicarePartDEffectiveStart
        ? convertToArborDate(lisFields.nextMedicarePartDEffectiveStart).getUtcDate()
        : undefined,
      lis_next_medicare_part_d_termination: lisFields.nextMedicarePartDTermination
        ? convertToArborDate(lisFields.nextMedicarePartDTermination).getUtcDate()
        : undefined,
      lis_other_payor_id_count: lisFields.otherPayorIdCount,
      lis_termination_date: lisFields.terminationDate
        ? convertToArborDate(lisFields.terminationDate).getUtcDate()
        : undefined,
      lis_transaction_response_status: lisFields.transactionResponseStatus,
    };
    if (payload) {
      dispatch(addPbmInsurance({ ...payload, patient_id: patient.id })).then(() => {
        setComparedInsuranceData(comparedInsuranceData.filter((o, i) => i !== index - 1));
        removeInsurance(index - 1);
      });
    }
  };

  /**
   *
   * @param {*} left the new pbm data to compare
   * @param {*} right the old pbm data to compare
   * @returns boolean false if values are not the same between important data points
   */
  const pbmDataMatches = (left, right) => {
    const leftBin = left.find(x => x.name === 'pbm_bin_number');
    const rightBin = right.find(x => x.name === 'pbm_bin_number');
    if (leftBin && rightBin && leftBin.value !== rightBin.value) {
      return false;
    }

    const leftPcn = left.find(x => x.name === 'pbm_processor_control_number');
    const rightPcn = right.find(x => x.name === 'pbm_processor_control_number');
    if (leftPcn && rightPcn && leftPcn.value !== rightPcn.value) {
      return false;
    }

    const leftPatientId = left.find(x => x.name === 'pbm_patient_id');
    const rightPatientId = right.find(x => x.name === 'pbm_patient_id');
    if (leftPatientId && rightPatientId && leftPatientId.value !== rightPatientId.value) {
      return false;
    }

    return true;
  };

  const addSubFormData = (key, index, displayName) => {
    return {
      button: 'Add New Insurance',
      toolTip: `Creates a new ${key.toString().toLowerCase()} PBM Insurance for the patient`,
      buttonHandler: () => handleAddNewInsurance(index + 1),
      display: displayName,
      form: <div className={classes.subFormContainer} />,
    };
  };

  // eslint-disable-next-line arrow-body-style
  const reconcileResults = () => {
    const hasInsuranceData = comparedInsuranceData && comparedInsuranceData.length > 0;
    const toolTip =
      "Update the patient's existing insurance information with the latest information received";
    return (
      <Box className={classes.modal} data-qa-id="eligibility-check-modal-results">
        {hasInsuranceData && (
          <Typography variant="h6" className={classes.header}>
            Eligibility check results for:
            {` ${patient.first_name} ${patient.last_name}`}
          </Typography>
        )}
        {errorMessage && (
          <Typography
            data-qa-id="eligibility-check-reconciliation-error-message"
            variant="h6"
            className={classes.errorText}
          >
            {`Error: ${errorMessage}`}
          </Typography>
        )}
        {comparedInsuranceData.map((d, index) => {
          const subForms = [];
          const updateDisplayName = `update_insurance_${index}`;
          const newDisplayName = `new_insurance_${index}`;

          const pbmInsurance = {
            patient_id: d.original.patient_id,
            id: d.original.id,
            start_date: getUpdateInformation(d.left, d.original, 'start_date'),
            end_date: getUpdateInformation(d.left, d.original, 'end_date'),
            pbm_rx_group_number: getUpdateInformation(d.left, d.original, 'pbm_rx_group_number'),
            pbm_phone_number: getUpdateInformation(d.left, d.original, 'plan_phone_number'),
            pbm_processor_control_number: getUpdateInformation(
              d.left,
              d.original,
              'pbm_processor_control_number',
            ),
            insurance_type_id: getUpdateInformation(d.left, d.original, 'insurance_type_id'),
            pbm_name: getUpdateInformation(d.left, d.original, 'pbm_name'),
            pbm_patient_id: getUpdateInformation(d.left, d.original, 'pbm_patient_id'),
            pbm_bin_number: getUpdateInformation(d.left, d.original, 'pbm_bin_number'),
            pbm_relation_code: getUpdateInformation(d.left, d.original, 'pbm_relation_code'),

            lis_level: getUpdateInformation(d.left, d.original, 'lis_level'),
            lis_effective_date: getUpdateInformation(d.left, d.original, 'lis_effective_date'),
            lis_termination_date: getUpdateInformation(d.left, d.original, 'lis_termination_date'),
            lis_medicare_plan_type: getUpdateInformation(
              d.left,
              d.original,
              'lis_medicare_plan_type',
            ), // eslint-disable-line
            lis_medicare_beneficiary_id: getUpdateInformation(
              d.left,
              d.original,
              'lis_medicare_beneficiary_id',
            ), // eslint-disable-line
            lis_effective_date_mbi: getUpdateInformation(
              d.left,
              d.original,
              'lis_effective_date_mbi',
            ), // eslint-disable-line
            lis_benefit_id: getUpdateInformation(d.left, d.original, 'lis_benefit_id'),
            lis_formulary_id: getUpdateInformation(d.left, d.original, 'lis_formulary_id'),
            lis_date_of_service: getUpdateInformation(d.left, d.original, 'lis_date_of_service'),
            lis_contract_number: getUpdateInformation(d.left, d.original, 'lis_contract_number'),
            lis_medicare_part_d_code: getUpdateInformation(
              d.left,
              d.original,
              'lis_medicare_part_d_code',
            ), // eslint-disable-line
            lis_other_payor_id_count: getUpdateInformation(
              d.left,
              d.original,
              'lis_other_payor_id_count',
            ), // eslint-disable-line
            lis_cms_low_income_cost_sharing: getUpdateInformation(
              d.left,
              d.original,
              'lis_cms_low_income_cost_sharing',
            ), // eslint-disable-line
            lis_transaction_response_status: getUpdateInformation(
              d.left,
              d.original,
              'lis_transaction_response_status',
            ), // eslint-disable-line
            lis_next_medicare_part_d_effective_start: getUpdateInformation(
              d.left,
              d.original,
              'lis_next_medicare_part_d_effective_start',
            ), // eslint-disable-line
            lis_next_medicare_part_d_termination: getUpdateInformation(
              d.left,
              d.original,
              'lis_next_medicare_part_d_termination',
            ), // eslint-disable-line
          };

          if (d.right.length > 0) {
            if (!pbmDataMatches(d.left, d.right)) {
              subForms.push(addSubFormData(d.key, index, newDisplayName));
            }
            subForms.push({
              button: 'Update Insurance',
              toolTip,
              buttonHandler: formDisplay => setDisplaySubForm(formDisplay),
              display: updateDisplayName,
              form: (
                <Grid container className={classes.subFormContainer}>
                  <Grid item xs={12}>
                    <EditPbmInsurance
                      pbmInsurance={pbmInsurance}
                      cancel={() => setDisplaySubForm()}
                      otherSuccessFunction={() => removeInsurance(index)}
                      pristineOverride
                      form={`${EDIT_PBM_INSURANCE_FORM}_${pbmInsurance.id}`}
                    />
                  </Grid>
                </Grid>
              ),
            });
          } else {
            subForms.push(addSubFormData(d.key, index, newDisplayName));
          }
          return (
            <ReconciliationCard
              key={d.key}
              header={`Insurance Information - ${d.key}`}
              index={index + 1}
              total={comparedInsuranceData.length}
              displaySubForm={displaySubForm}
              oldCol={d.right}
              newCol={d.left}
              rejectHandler={() =>
                removeInsurance(index, () => dispatch(notifySuccess('Insurance rejected')))
              }
              rejectTooltip="Reject the latest updates received"
              subForms={subForms}
              rejectVariant="text"
            />
          );
        })}
        {hasInsuranceData && (
          <Typography variant="body1" className={classes.footer}>
            Warning: if you click out of this modal information may be lost.
          </Typography>
        )}
      </Box>
    );
  };

  const initialForm = () => {
    return (
      <Box className={classes.modal} data-qa-id="eligibility-check-modal-form">
        <Grid container justifyContent="center" spacing={1}>
          <Grid item xs={12}>
            <form
              onSubmit={handleSubmit(handleEligibilityFormSubmit)}
              className={classes.formContainer}
              autoComplete="off"
            >
              <Grid container>
                <Grid item xs={9}>
                  <FormHeader header="Eligibility Check" />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  <Field
                    name="last_name"
                    label="Last Name *"
                    component={renderTextField}
                    validate={[required]}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="first_name"
                    label="First Name *"
                    component={renderTextField}
                    validate={[required]}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="dob"
                    label="Date of Birth *"
                    component={renderDatePicker}
                    validate={[validateDate, required]}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  <Field
                    name="zip"
                    label="Zip *"
                    component={renderTextField}
                    validate={[required]}
                  />
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.field}>
                    <Field
                      id="gender"
                      name="gender"
                      label="Gender *"
                      component={renderDropdown}
                      fields={BenefitsInvestigationFields.benefitsInvestigationGenderList}
                      validate={[required]}
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="ssn"
                    label="SSN"
                    adormentcontent="****-**-"
                    validate={[validateLast4ssn]}
                    component={renderLast4ssnField}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="date_of_service"
                    label="Date of Service *"
                    component={renderDatePicker}
                    validate={[validateDate, required]}
                  />
                </Grid>
              </Grid>
              <ConfirmationPanel
                submitButtonText="Submit"
                cancelButtonName="eligibility_cancel_button"
                submitButtonName="eligibility_submit_button"
                handleCancel={handleClose}
                disableSubmit={submitting}
                isLoading={submitting}
              />
            </form>
          </Grid>
          {errorMessage && (
            <Grid item xs={12}>
              <Typography
                data-qa-id="eligibility-check-form-error-message"
                className={classes.errorText}
              >
                {`Error: ${errorMessage}`}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  };

  return (
    <Modal
      open={eligibilityCheckState.open}
      onBackdropClick={handleClose}
      data-qa-id="eligibility-check-modal"
    >
      {displayInitialForm ? initialForm() : reconcileResults()}
    </Modal>
  );
};

const mapStateToProps = props => {
  const { patient } = props;
  const primaryZip = getPreferredZip(patient);
  const initialValues = {
    last_name: patient.last_name,
    first_name: patient.first_name,
    dob: convertToArborDate(patient.dob, true).getUtcDate(true) || null,
    date_of_service: convertToArborDate(moment()).getUtcDate(true),
    gender: patient.gender,
    zip: primaryZip,
    ssn: patient.ssn,
  };
  return { initialValues };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps),
)(reduxForm({ form: 'eligibility_check' })(EligibilityCheckModal));

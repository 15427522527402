import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
} from '@mui/material';
import { ITableProps } from './day-area-shipping.interface';

const AreaDayShippingTable = ({ data }: ITableProps) => {
  return (
    <TableContainer component={Paper} sx={{ maxWidth: '70%' }}>
      <Table sx={{ minWidth: 650 }} aria-label="Day area shipping table">
        <TableHead>
          <TableRow>
            <TableCell>Weekday</TableCell>
            <TableCell>Zipcodes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow
              key={"'row.weekday'-row"}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.weekday}
              </TableCell>
              <TableCell>
                {row.zipCodes.map(zipcode => (
                  <Chip label={zipcode} sx={{ marginRight: 1 }} />
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AreaDayShippingTable;

import AddIntervention from 'containers/tasks/interventions/add-intervention';
import cx from 'classnames';
import InterventionDetail from 'containers/tasks/interventions/intervention-detail';
import React from 'react';
import { ADD_TASK_INTERVENTION_FORM, CSL, INT, TASK_COUNSELING } from 'constants/index';
import { buildQaId } from 'utils/build-qa-id';
import { Button, Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'recompose';
import { IState } from 'interfaces/redux/IState';
import { ITask } from 'interfaces/redux/ITasks';
import { noteTagTypes } from 'constants/lists';
import { RenderReadonly } from 'components/dynamic-form/renders/render-readonly';
import { TasksUtil } from 'utils/tasks-util';
import { getGroupedMedications } from 'hooks/getGroupedMedications';
import { useSelector } from 'react-redux';
import { renderDropdown as RenderDropdown } from 'components/form/field/redux-field';
import TaskContainer from 'containers/tasks/task-container';
import { getDocumentsForResource } from 'services/utils/document-service';
import { getDisplayNotes } from 'services/utils/note-service';
import { usePrevious } from 'hooks/usePrevious';
import { buildCausedByOptions } from 'containers/tasks/common/utils/intervention-utils';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { StringUtils } from 'utils/string-utils';
import { nameOfFactory } from 'utils/types-util';
import { CounselingTaskType } from 'constants/enums';
import { InterventionType } from './interfaces/InterventionType';
import { ISideEffect } from './interfaces/ISideEffect';
import { styles } from './side-effects-form.styles';
import { IProps } from './interfaces/IProps';
import { IFormsState } from './interfaces/IFormsState';
import { AllFormFields } from '../../interfaces/IProps';

const options: { label: string; value: boolean }[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const optionsForInitialCsl: { label: string; value: boolean | string }[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
  { label: 'N/A', value: 'N/A' },
];
const defaultInterventionCategoryId = 1;
const interventionTypeAdherenceId = 1;
const interventionTypeAdrId = 4;
const qaIdBuilder = buildQaId(`${CSL}-side-effects-form`, '.');
const tagType = noteTagTypes.find(x => x.label === INT);
const nameOfFormFields = nameOfFactory<AllFormFields>();

// #region get all the data ready for the component
const formStateFromSideEfffect = (sideEffect: ISideEffect) => {
  return {
    formFields: {
      reaction: sideEffect.reaction,
      causedBy: sideEffect.causedBy,
      severity: sideEffect.severity?.id,
    },
    showSmallForm: true,
    showInterventionForm: false,
    showCompletedIntervention: true,
    saved: true,
    taskId: sideEffect.interventionId,
  };
};
// #endregion

function buildDetails(formState: IFormsState): string | undefined {
  if (formState.formFields && formState.formFields.reaction && formState.formFields.causedBy) {
    const fields = formState.formFields;
    const details = `During CSL, patient declare to suffer from ${fields.reaction}, suspected caused by ${fields.causedBy?.text}.`;
    return details;
  }

  return undefined;
}

const SideEffectsForm: React.FC<IProps> = (props: IProps): JSX.Element | null => {
  const { classes, nameField } = props;

  // #region get everything from redux
  const therapies = Object.values(useSelector((state: IState) => state.therapies.data));
  const severityOptions = useSelector(
    (state: IState) => state.taskStatuses.intervention_severities,
  );
  const medications = useTypedSelector(state => state.medications);
  const sideEffectOptions = useSelector((state: IState) => state.lookups.fdbSideEffects);
  const groupedMedications = getGroupedMedications(medications.medicationGroups);
  const serviceGroups = useSelector((state: IState) => state.lookups.serviceGroups);
  const interventionStatuses = useSelector((state: IState) => state.taskStatuses.statuses.int);
  const documents = useSelector((state: IState) => state.uploadedDocuments.documents);
  const interventionTasks = useSelector((state: IState) =>
    TasksUtil.getTasksOfType(Object.values(state.tasks.data), INT),
  );
  const patient = useSelector((state: IState) => state.patient);
  const allPinnedNotes = useSelector((state: IState) => state.notes.notes.allPinnedNotes);
  const allNonPinnedNotes = useSelector((state: IState) => state.notes.notes.allNonPinnedNotes);
  const users = useSelector((state: any) => state.lookups.users);
  const listOfTasks = useTypedSelector(state => state.tasks.data);
  const prevSideEffects = usePrevious<ISideEffect[]>(props.sideEffects ?? []);
  // #endregion

  // #region configure state for this component
  const [checkFormsState, setCheckFormsState] = React.useState<boolean>(false);
  const [formsState, setFormsState] = React.useState<IFormsState[]>(
    (props.sideEffects || []).map(formStateFromSideEfffect),
  );
  const [showAddSideEffectButton, setShowAddSideEffectButton] = React.useState<boolean>(
    !props.readOnly,
  );
  // #endregion

  // #region useEffect
  React.useEffect(() => {
    /**
     * Only update forms when more sideEffects gets added not every times to avoid unexpected forms state overrides
     * (useEffect hook doesn't compare deep when objects or array it just perform === comparisson)
     */
    if (prevSideEffects?.length !== props.sideEffects?.length) {
      const currentSideEffects = props.sideEffects || [];
      const renderedSideEffectIndexes: number[] = [];
      let newFormsState = [...formsState];

      // Update current forms with sideEffects data
      // Saving a side effect it has to update the current formState with the new intervention data
      formsState.forEach((formState, index) => {
        const formSideEffectIndex = currentSideEffects.findIndex(
          sideEffect => sideEffect.interventionId === formState?.taskId,
        );

        if (formSideEffectIndex !== -1) {
          newFormsState[index] = {
            formFields: {
              reaction: currentSideEffects[formSideEffectIndex].reaction,
              causedBy: currentSideEffects[formSideEffectIndex].causedBy,
              severity: currentSideEffects[formSideEffectIndex].severity?.id,
            },
            showSmallForm: true,
            showInterventionForm: false,
            showCompletedIntervention: true,
            saved: true,
            taskId: currentSideEffects[formSideEffectIndex].interventionId,
          };
          renderedSideEffectIndexes.push(formSideEffectIndex);
        }
      });

      const notRenderedSideEffects = currentSideEffects.filter(
        (_sideEffect, index) => !renderedSideEffectIndexes.includes(index),
      );

      // When more side effects arrive (pick more CSL tasks), need to render them (since they are not in formState yet)
      newFormsState = [...newFormsState, ...notRenderedSideEffects.map(formStateFromSideEfffect)];

      // Clean not passed anymore (unselecting CSL task) sideEffects from formState
      const currentSideEffectsTaskIds = currentSideEffects.map(
        sideEffect => sideEffect.interventionId,
      );

      const cleanedFormState = newFormsState.filter(
        /**
         * Not removing the form from state in the following cases
         * - Still has not taskId (A pending side effect small form, without intervention form opened yet)
         * - Still not saved intervention (a pending side effect form with all options selected but still not saved intervention)
         * - A Side effect form with intervention id included in resource links for this CSL tasks (already saved intervention
         *   related with current CSL selected)
         */
        formState =>
          !formState.taskId ||
          !formState.saved ||
          currentSideEffectsTaskIds.includes(formState.taskId) === true,
      );

      setFormsState(cleanedFormState);
    }
  }, [props.sideEffects]);

  React.useEffect(() => {
    let showButton = false;
    formsState.forEach((value, index) => {
      const { formFields } = formsState[index];
      if (
        formFields &&
        formFields.causedBy &&
        formFields.severity &&
        formFields.reaction &&
        formsState[index].saved !== true
      ) {
        showButton = true;
        setFormsState(prevState => {
          const newState = [...prevState];
          newState[index] = {
            ...newState[index],
            showInterventionForm: true,
            details: buildDetails(formsState[index]),
          };
          return newState;
        });
      } else if (!props.smallForm && formsState[index].saved !== true) {
        setFormsState(prevState => {
          const newState = [...prevState];
          newState[index] = {
            ...newState[index],
            showInterventionForm: true,
            details: '',
          };
          return newState;
        });
      } else {
        setFormsState(prevState => {
          const newState = [...prevState];
          newState[index] = {
            ...newState[index],
            showInterventionForm: false,
          };
          return newState;
        });
      }
    });

    if (showButton) {
      setShowAddSideEffectButton(true);
    }
  }, [checkFormsState]);
  // #endregion

  // #region other functions
  const evaluateFormState = (): void => {
    setCheckFormsState(prevValue => !prevValue);
  };

  const addTaskIdToSavedForm = (index: number, task: ITask) => {
    setFormsState(prevState => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        taskId: task.id,
      };
      return newState;
    });
  };

  const cleanFormAtIndex = (index: number) => {
    setFormsState(prevState => {
      const newState = [...prevState];
      newState.splice(index, 1);
      const outOfSideEffects = formsState.length === 0;
      if (outOfSideEffects) {
        props.change(nameOfFormFields(nameField), false);
        setShowAddSideEffectButton(false);
      }
      return newState;
    });
  };

  const isInitialCsl = () => {
    if (
      props.counselingTaskIds.length > 0 &&
      props.counselingTaskIds[0] !== null &&
      listOfTasks[`CSL${props.counselingTaskIds[0]}`]
    ) {
      const counselingType = listOfTasks[`CSL${props.counselingTaskIds[0]}`].counseling_type;
      return counselingType === CounselingTaskType.Initial;
    }
    return false;
  };

  // #endregion

  // #region renders -- to reduce many large blocks of code
  const renderInterventionDetail = (state: IFormsState, innerProps: any = {}): JSX.Element => {
    const task = interventionTasks.find(x => x.id === state.taskId);
    const displayDocuments = getDocumentsForResource(documents, [
      { tagTypeId: tagType?.value, resourceId: state.taskId },
    ]);
    const displayNotes = getDisplayNotes(
      allPinnedNotes,
      allNonPinnedNotes,
      tagType?.value,
      state.taskId,
      users,
      serviceGroups,
      2,
    );

    const notesWithProps = {
      tagName: INT,
      tagTypeId: tagType?.value,
      notes: displayNotes,
      patientId: patient.id,
      isInContext: true,
      maxLine: '1',
      containerClasses: classes,
      patient,
    };

    return (
      <InterventionDetail
        task={task}
        tagTypeId={tagType?.value}
        tagTypeLabel={tagType?.displayLabel}
        statuses={interventionStatuses}
        serviceGroups={serviceGroups}
        documents={displayDocuments}
        notes={notesWithProps}
        {...innerProps}
      />
    );
  };

  const renderFirstQuestion = (): JSX.Element => {
    const isCsl = isInitialCsl();
    const optionsToBeUsed = isCsl ? optionsForInitialCsl : options;
    return (
      <Grid item xs={12} className={classes.firstQuestionItem}>
        <Grid container justifyContent="space-between">
          <Grid item xs={isCsl ? 8 : 9}>
            <Typography>{props.question}</Typography>
          </Grid>
          <Grid item xs={isCsl ? 3 : 2}>
            <Grid container justifyContent="space-between">
              {optionsToBeUsed.map(option => {
                const qaKey =
                  props.interventionType === InterventionType.SideEffect
                    ? 'sideEffectButtons'
                    : 'adherenceButtons';
                return (
                  <Grid item xs>
                    <Button
                      disabled={props.readOnly}
                      disableRipple
                      className={cx(classes.button, {
                        [classes.selected]: option.value === props.formValues[nameField],
                      })}
                      onClick={() => {
                        props.change(nameOfFormFields(nameField), option.value);
                        // yes
                        if (option.value === true) {
                          if (!props.smallForm) {
                            setFormsState([{ showSmallForm: true }]);
                            evaluateFormState();
                          } else if (formsState.length === 0) {
                            setFormsState([{ showSmallForm: true }]);
                          } else {
                            setFormsState(prevState => {
                              const newState = [...prevState];
                              newState.forEach(state => (state.showSmallForm = true));
                              return newState;
                            });
                          }
                        }
                        // no
                        if (option.value === false || option.value === 'N/A') {
                          setFormsState(prevState => {
                            const newState = [...prevState];
                            newState.forEach(state => {
                              state.showInterventionForm = false;
                              state.showSmallForm = !!state.showCompletedIntervention;
                            });
                            return newState;
                          });
                        }
                      }}
                      classes={
                        {
                          // label: classes.buttonLabel, TODO: fix styling
                        }
                      }
                      data-qa-id={qaIdBuilder(`first-question-${qaKey}`)}
                    >
                      {option.label}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderSmallForm = (index: number, state: IFormsState): JSX.Element => {
    const qaKey = qaIdBuilder(`small-form-${index}`);
    const formSaved = state.saved === true;

    return (
      <Grid
        container
        className={cx(classes.smallForm, { [classes.smallFormSaved]: formSaved })}
        key={qaKey}
      >
        <Grid item xs={4} data-qa-id={`${qaKey}-side-effect`}>
          {formSaved ? (
            <RenderReadonly
              label="Side Effect *"
              value={StringUtils.upperCaseFirstLetter(state.formFields?.reaction)}
            />
          ) : (
            <RenderDropdown
              label="Side Effect *"
              meta={{}}
              fields={sideEffectOptions.map(x => ({
                label: StringUtils.upperCaseFirstLetter(x.description),
                value: x.description,
              }))}
              width="75%"
              input={{
                value: state.formFields?.reaction,
                onChange: (value: string) => {
                  setFormsState(prevState => {
                    const newState = [...prevState];
                    newState[index] = {
                      ...newState[index],
                      formFields: {
                        ...newState[index].formFields,
                        reaction: value,
                      },
                    };
                    return newState;
                  });
                  evaluateFormState();
                },
              }}
            />
          )}
        </Grid>
        <Grid item xs={5} data-qa-id={`${qaKey}-causedBy`}>
          {formSaved ? (
            <RenderReadonly
              label="Suspected Caused By *"
              value={state.formFields?.causedBy?.text}
            />
          ) : (
            <RenderDropdown
              label="Suspected Caused By *"
              meta={{}}
              fields={Object.entries(buildCausedByOptions(therapies, groupedMedications)).map(
                ([key, value]) => {
                  return {
                    label: key,
                    value: key,
                  };
                },
              )}
              input={{
                value: state.formFields?.causedBy?.text,
                onChange: (value: string) => {
                  const selectedOption = buildCausedByOptions(therapies, groupedMedications)[value];
                  const selectedCausedBy = {
                    ...selectedOption,
                    text: value,
                  };
                  setFormsState(prevState => {
                    const newState = [...prevState];
                    newState[index] = {
                      ...newState[index],
                      formFields: {
                        ...newState[index].formFields,
                        causedBy: selectedCausedBy,
                      },
                    };
                    return newState;
                  });
                  evaluateFormState();
                },
              }}
              width="75%"
            />
          )}
        </Grid>
        <Grid item xs={3} data-qa-id={`${qaKey}-severity`}>
          {formSaved ? (
            <RenderReadonly
              label="Severity *"
              value={severityOptions.find(x => state.formFields?.severity === x.id)?.severity_name}
            />
          ) : (
            <RenderDropdown
              label="Severity *"
              input={{
                value: state.formFields?.severity,
                onChange: (value: number) => {
                  setFormsState(prevState => {
                    const newState = [...prevState];
                    newState[index] = {
                      ...newState[index],
                      formFields: { ...newState[index].formFields, severity: value },
                    };
                    return newState;
                  });
                  evaluateFormState();
                },
              }}
              meta={{}}
              fields={severityOptions.map(option => {
                return {
                  label: option.severity_name,
                  value: option.id,
                };
              })}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          Test
        </Grid>
      </Grid>
    );
  };

  const renderForms = (): JSX.Element | undefined => {
    return (
      <>
        {formsState.map((state, index) => {
          const qaKey = qaIdBuilder(`render-forms-${index}`);
          const { formFields } = formsState[index];
          return (
            <React.Fragment key={qaKey}>
              {state.showSmallForm && props.smallForm && renderSmallForm(index, state)}
              {state.showInterventionForm && (
                <Grid
                  item
                  xs={12}
                  className={classes.interventionForm}
                  data-qa-id={`${qaKey}-intervention-form`}
                >
                  <Typography variant="h6" className={classes.interventionTitle}>
                    Create Intervention
                  </Typography>
                  <AddIntervention
                    form={`${ADD_TASK_INTERVENTION_FORM}_CSL_${formFields?.causedBy?.type}_${
                      formFields?.causedBy?.id || 'unknown'
                    }__${index}`}
                    serviceGroups={serviceGroups}
                    defaultServiceGroup={props.selectedServiceGroupId}
                    defaultSeverityId={formFields?.severity}
                    defaultCategoryId={defaultInterventionCategoryId}
                    defaultTypeId={
                      props.interventionType === InterventionType.SideEffect
                        ? interventionTypeAdrId
                        : interventionTypeAdherenceId
                    }
                    defaultDetails={formsState[index].details}
                    therapy={
                      formFields?.causedBy?.type === 'therapy'
                        ? { id: formFields?.causedBy?.id }
                        : undefined
                    }
                    medicationIds={
                      formFields?.causedBy?.type === 'med' ? [formFields?.causedBy.id] : []
                    }
                    taskAddedFromData={(props.counselingTaskIds || []).map(taskId => ({
                      task_type: TASK_COUNSELING,
                      task_id: taskId,
                    }))}
                    handleSubmitComplete={(addedTasks: ITask[]) => {
                      if (addedTasks && addedTasks.length === 1) {
                        addTaskIdToSavedForm(index, addedTasks[0]);
                      }
                    }}
                    additionalLinkMetadata={
                      props.interventionType === InterventionType.SideEffect
                        ? {
                            reaction: state.formFields?.reaction || '',
                            causedBy: state.formFields?.causedBy || {},
                            severity:
                              severityOptions.find(x => x.id === state.formFields?.severity) || {},
                            type: InterventionType.SideEffect,
                          }
                        : { type: InterventionType.Adherence }
                    }
                    cancelOnSubmit={false}
                    cancel={() => {
                      cleanFormAtIndex(index);
                    }}
                  />
                </Grid>
              )}
              {state.showCompletedIntervention &&
                props.formValues[nameField] &&
                interventionTasks.find(x => x.id === state.taskId) && (
                  <TaskContainer
                    task={interventionTasks.find(x => x.id === state.taskId)}
                    forceExpand
                    renderChildren={(innerProps: any) =>
                      renderInterventionDetail(state, innerProps)
                    }
                  />
                )}
            </React.Fragment>
          );
        })}
      </>
    );
  };
  // #endregion

  // #region  main render
  return (
    <Grid container>
      {renderFirstQuestion()}
      {renderForms()}
      {props.formValues[nameField] &&
        typeof props.formValues[nameField] === 'boolean' &&
        showAddSideEffectButton &&
        Boolean(props.multiple) && (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              disableRipple
              disableElevation
              className={classes.newSideEffectButton}
              onClick={() => {
                if (props.smallForm) {
                  setFormsState(prevState => {
                    const newState = [...prevState];
                    newState.push({ showSmallForm: true });
                    return newState;
                  });
                } else {
                  setFormsState(prevState => {
                    const newState = [...prevState];
                    newState.push({
                      showSmallForm: false,
                      showInterventionForm: true,
                      details: '',
                    });
                    return newState;
                  });
                  evaluateFormState();
                }
              }}
            >
              {props.addButtonText || 'Add New Side Effect'}
            </Button>
          </Grid>
        )}
    </Grid>
  );
  // #endregion main render
};

export default compose<IProps, Partial<IProps>>(withStyles(styles))(SideEffectsForm);

import React from 'react';
import { Typography, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { styles } from './header-styles';

const PageHeader = props => {
  const { header, classes, children } = props;
  return (
    <Grid container justifyContent="space-between" alignItems="center" alignContent="center">
      <Grid item xs={6}>
        <Typography variant="subtitle2" className={classes.pageHeaderTitle}>
          {header}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        {!!children && children}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(PageHeader);

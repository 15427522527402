import React from 'react';
import fdcJson from 'config/task-types/fdc.json';
import { windowFeatureIsEnabled } from 'config/window-features';
import moment from 'moment';
import { staticProviders, getCommonProviders, getDateForProvider } from './common-provider';
import { FillDeliveryConfirmationOrderDetails } from '../../../containers/shipping/fill-delivery-confirmation-order-details';

function FdcDataWrapper(props) {
  const { providers } = props;
  const { task } = providers;
  return (
    <FillDeliveryConfirmationOrderDetails
      orderId={task.order_id}
      statusId={task.status_id}
      key={`FDC-task-${task.id}`}
    />
  );
}

export default (task, state) => {
  const { lookups = {} } = state;

  if (windowFeatureIsEnabled('fdc_statuses')) {
    fdcJson.statuses.push({ parent: [], name: 'Needs Review', id: 'needsreview', next: 'shipped' });
  }

  return {
    ...staticProviders,
    ...getCommonProviders(task, state, fdcJson),
    json: fdcJson,
    users: lookups.users,
    incidentExternalResponsibles: lookups.incidentExternalResponsibles,
    incidentReportingParties: lookups.incidentReportingParties,
    customElements: [
      {
        id: 'delivery-table',
        content: FdcDataWrapper,
      },
    ],
    initialValues: {
      is_internal: false,
      canceled_date: getDateForProvider(moment()),
    },
  };
};

import moment, { Moment } from 'moment';
import { MedSyncClient } from 'clients/med-sync-client';
import { memoize } from 'lodash';
import { IPatient } from '../../../interfaces/redux/IPatient';
import { MED_SYNC_STATUS, THERAPY_ADHERENCE_PACKAGE, THERAPY_MED_SYNC } from '../../../constants';
import { IMedSyncRowData } from '../../../models/meds/IMedSync';
import { AdministrationStatus, ITherapy } from '../../../interfaces/redux/ITherapy';
import { IMedSyncFormFields, IMedSyncPreferences } from './common';

/**
 * Given a patient, return next med sync date
 * @param patient IPatient
 * @returns date
 */
export const getNextSyncDate = (patient: IPatient): Date | null => {
  if (patient.anchor_date && patient.sync_time_frame) {
    const anchorDate = moment.utc(new Date(patient.anchor_date));
    const currentDate = moment.utc();

    return moment(anchorDate).isAfter(currentDate)
      ? anchorDate.toDate()
      : anchorDate.add(patient.sync_time_frame, 'days').toDate();
  }
  return null;
};

export const calculateNextNeedsByDate = (daysSupply: number, needsByDate: Moment | Date | string) =>
  daysSupply === 0 ? needsByDate : moment.utc(needsByDate).add(daysSupply, 'days');

export const displayMedSyncDate = (date: string | Date | null | undefined): string => {
  return date ? moment.utc(new Date(date)).format('MM/DD/YYYY') : '';
};

export const getMedsInSyncNames = (medSyncData: IMedSyncRowData[]): string =>
  medSyncData
    .filter(x => x.inMedSync === THERAPY_MED_SYNC.IN_MED_SYNC)
    .map(x => x.drugName)
    .join(', ');
export const medSyncInConflict = (medSyncData: IMedSyncRowData[]): boolean =>
  medSyncData.some(item => item.inConflict);
export const therapiesSynchronized = (medSyncData: IMedSyncRowData[]): boolean =>
  !medSyncData.every(item => item.inMedSync === THERAPY_MED_SYNC.NOT_IN_MED_SYNC);

/**
 * used by selectCurrentMedSyncData - converts store.therapies.data list into IMedSyncRowData[]
 */
export const parseMedSyncDataFn = (
  data: ITherapy[],
  syncTimeFrame: number | null,
): IMedSyncRowData[] => {
  const nonActiveAdministrationStatuses = [
    AdministrationStatus.OnHold,
    AdministrationStatus.Discontinued,
    AdministrationStatus.NoGo,
  ];
  return data
    .filter((item: ITherapy) => item.dispensing_status_id === MED_SYNC_STATUS.OPT_IN)
    .sort((a: ITherapy, b: ITherapy) => {
      return a.drug_name.toLowerCase().localeCompare(b.drug_name.toLowerCase());
    })
    .reduce((acc: IMedSyncRowData[], item: ITherapy) => {
      // exclude the therapies that are not in administration status
      if (!nonActiveAdministrationStatuses.includes(item.administration_status)) {
        const inConflict =
          syncTimeFrame !== null &&
          item.days_supply % syncTimeFrame !== 0 &&
          item.in_med_sync === THERAPY_MED_SYNC.IN_MED_SYNC;
        acc.push({
          inMedSync: item.in_med_sync ?? THERAPY_MED_SYNC.NOT_IN_MED_SYNC,
          id: item.id,
          drugName: item.drug_name,
          ndc: item.ndc,
          dose: item.dosis_regimen ?? '',
          strength: `${item.strength} ${item.strength_unit_of_measure}`,
          form: item.dosage_form,
          nbd: item.needsby_date,
          adherencePack:
            item.adherence_packaging ?? THERAPY_ADHERENCE_PACKAGE.ADHERENCE_PACKAGE_NOT_REQUIRED,
          daysSupply: item.days_supply,
          isSpecialty: item.is_specialty,
          inConflict,
          dispenseStatusId: item.dispensing_status_id,
        });
      }
      return acc;
    }, []);
};

const getById = (id: number, medSyncData: IMedSyncRowData[]) => {
  return medSyncData.find(item => item.id === id);
};
const getUpdatedTherapiesIdInMedSync = (
  medSyncData: IMedSyncRowData[],
  previousMedSync?: IMedSyncRowData[],
) => {
  return medSyncData
    .filter(x => {
      const prev = previousMedSync ? getById(x.id, previousMedSync) : null;
      return (
        x.inMedSync === THERAPY_MED_SYNC.IN_MED_SYNC &&
        (!prev || x.inMedSync !== prev?.inMedSync || x.adherencePack !== prev?.adherencePack)
      );
    })
    .map(x => x.id);
};
const getUpdatedTherapiesIdNotInMedSync = (
  medSyncData: IMedSyncRowData[],
  previousMedSync?: IMedSyncRowData[],
) =>
  medSyncData
    .filter(x => {
      const prev = previousMedSync ? getById(x.id, previousMedSync) : null;
      return (
        x.inMedSync === THERAPY_MED_SYNC.NOT_IN_MED_SYNC &&
        (!prev || x.inMedSync !== prev.inMedSync)
      );
    })
    .map(x => x.id);

/**
 * This function groups update requests by in med sync/not in med sync, and fires multiple requests.
 * @param formValues this is the form the med sync modal form
 * @param patient current patient med sync preferences - can come from patient selector
 * @param medSyncData this is the "working copy" of med sync data from original therapy state
 * @param prevMedSyncData this is from the selector that takes therapies state
 */
export const submitMedSyncUpdates = async (
  formValues: IMedSyncFormFields,
  currentMedSyncPreferences: IMedSyncPreferences,
  nextMedSyncData: IMedSyncRowData[],
  prevMedSyncData?: IMedSyncRowData[],
): Promise<void> => {
  const payload = {
    patientId: currentMedSyncPreferences.patientId,
    status: nextMedSyncData[0].inMedSync,
    anchorDate: formValues.anchorDate ? moment.utc(formValues.anchorDate).toDate() : null,
    syncTimeFrame:
      formValues.syncTimeFrame && formValues.syncTimeFrame > 0 ? formValues.syncTimeFrame : null,
    therapiesId: [],
    adherencePackMapping: nextMedSyncData.map(({ id, adherencePack }) => ({ id, adherencePack })),
  };
  const therapiesIdInMedSync = getUpdatedTherapiesIdInMedSync(nextMedSyncData, prevMedSyncData);
  const therapiesIdNotInMedSync = getUpdatedTherapiesIdNotInMedSync(
    nextMedSyncData,
    prevMedSyncData,
  );

  const isAnchorDateChanged =
    moment.utc(formValues.anchorDate).format('MM/DD/YYYY') !==
    moment.utc(currentMedSyncPreferences.anchorDate).format('MM/DD/YYYY');

  if (isAnchorDateChanged) {
    const therapies = nextMedSyncData
      .filter(t => t.inMedSync === THERAPY_MED_SYNC.IN_MED_SYNC)
      .map(t => t.id);
    await MedSyncClient.updateMedSync({
      ...payload,
      therapiesId: therapies,
      status: THERAPY_MED_SYNC.IN_MED_SYNC,
    });
  } else if (therapiesIdInMedSync.length > 0) {
    await MedSyncClient.updateMedSync({
      ...payload,
      therapiesId: therapiesIdInMedSync,
      status: THERAPY_MED_SYNC.IN_MED_SYNC,
    });
  }
  if (therapiesIdNotInMedSync.length > 0) {
    await MedSyncClient.updateMedSync({
      ...payload,
      therapiesId: therapiesIdNotInMedSync,
      status: THERAPY_MED_SYNC.NOT_IN_MED_SYNC,
    });
  } else if (therapiesIdInMedSync.length === 0 && therapiesIdNotInMedSync.length === 0) {
    await MedSyncClient.updateMedSync(payload);
  }
};

export const applyAnchorDate = (
  anchorDate: Date | null,
  medSyncData: IMedSyncRowData[],
  backupMedSyncData: IMedSyncRowData[],
) => {
  return medSyncData.map((therapy, index) => {
    const nextItem = { ...therapy };
    if (therapy.inMedSync === THERAPY_MED_SYNC.IN_MED_SYNC && anchorDate) {
      nextItem.nbd = moment.utc(anchorDate).toDate().toISOString();
    } else if (therapy.inMedSync === THERAPY_MED_SYNC.NOT_IN_MED_SYNC) {
      nextItem.nbd = backupMedSyncData[index].nbd;
    }
    return nextItem;
  });
};

export function findLargestGroup<
  TItem extends Record<TProperty, number>,
  TProperty extends keyof TItem,
>(items: TItem[], property: TProperty) {
  const sortedItems = items.sort((a, b) => a[property] - b[property]);
  const groups = sortedItems.map(item =>
    sortedItems.filter(item2 => item2[property] % item[property] === 0),
  );
  return groups.reduce((acc, group) => (group.length > acc.length ? group : acc), []);
}

/**
 * This function is used in src/reducers/reducer-therapies.js to update therapy properties
 * after successful med sync updates. It applies edited med sync data to an existing record
 * of therapies (state.therapies.data) and produces a new therapies data state (for the reducer).
 */
export function updateTherapiesStateWithMedSyncData<T extends Record<number, ITherapy>>(
  therapiesData: T,
  medSyncData: IMedSyncRowData[],
) {
  medSyncData.forEach(({ id, inMedSync, adherencePack, nbd }) => {
    therapiesData[id].in_med_sync = inMedSync;
    therapiesData[id].adherence_packaging = adherencePack;
    therapiesData[id].needsby_date = moment.utc(nbd).toISOString();
  });
  return therapiesData;
}

import { AxiosPromise } from 'axios';
import HTTP from '../services/http';

export enum ShippingPreferredWeekdayEnum {
  sunday = 'sunday',
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
}

export interface IAreaShippingZipCode {
  id: number;
  customerId: number;
  zipCode: string;
  preferredWeekdays: ShippingPreferredWeekdayEnum[];
}

const baseUrl = '/area_shipping';
export class AreaShippingClient {
  static fetchShippingDates = (zipCode: string): AxiosPromise<Date[]> => {
    const url = `${baseUrl}/zip_code/${zipCode.substr(0, 5)}/shipping_dates`;
    return HTTP.get(url);
  };

  static fetchHolidays = (): AxiosPromise<Date[]> => {
    const url = `${baseUrl}/holidays`;
    return HTTP.get(url);
  };

  static fetch = (q: string): AxiosPromise<IAreaShippingZipCode[]> => {
    const url = `${baseUrl}/zip_code`;
    return HTTP.get(url, { params: { q: q.substr(0, 5) } });
  };

  static create = (zipCode: string, preferredWeekdays: string[]): AxiosPromise<IAreaShippingZipCode> => {
    const url = `${baseUrl}/zip_code`;
    return HTTP.post(url, { zipCode: zipCode.substr(0, 5), preferredWeekdays });
  };

  static delete = (id: number): AxiosPromise<IAreaShippingZipCode> => {
    const url = `${baseUrl}/zip_code/${id}`;
    return HTTP.delete(url);
  };
}

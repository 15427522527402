import {
  DocumentAnswerValue,
  DownloadDocumentResponse,
  RemoveDocumentResponse,
  UploadDocumentBody,
  UploadDocumentResponse,
} from 'containers/accreditation/types';
import { AxiosResponse, AxiosPromise } from 'axios';
import { ICdmProgramAndConditions } from 'reducers/reducer-cdm-programs';
import {
  FETCH_CDM_PROGRAMS,
  ADD_CDM_PROGRAMS_CONDITIONS,
  EDIT_CDM_PROGRAMS_CONDITIONS,
} from '../constants';
import HTTP from '../services/http';

export function fetchCdmPrograms() {
  const request = HTTP.get('/cdm_programs', {});
  return {
    type: FETCH_CDM_PROGRAMS,
    payload: request,
  };
}

export function addCdmProgramsAndConditions(payload: ICdmProgramAndConditions) {
  const request = HTTP.post('/cdm_programs_and_conditions', payload);
  return {
    type: ADD_CDM_PROGRAMS_CONDITIONS,
    payload: request,
  };
}

export function editCdmProgramsAndConditions(payload: ICdmProgramAndConditions) {
  const request = HTTP.patch('/cdm_programs_and_conditions', payload);
  return {
    type: EDIT_CDM_PROGRAMS_CONDITIONS,
    payload: request,
  };
}

export function deactivateCdmProgram(cdmProgramId: number) {
  const request = HTTP.patch(`/cdm_programs/deactivate/${cdmProgramId}`, {});
  return {
    type: EDIT_CDM_PROGRAMS_CONDITIONS,
    payload: request,
  };
}

import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { addNote } from 'actions/action-notes';
import { workListChanged } from 'actions/action-patient';
import { editScheduleOutreach, fetchTaskCounts } from 'actions/action-tasks';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { renderDatePicker, renderDateTimePicker } from 'components/form/datepicker/datetime-picker';
import { renderDropdown } from 'components/form/field/redux-field';
import RadioButton from 'components/form/radio/radio-button';
import RadioGroup from 'components/form/radio/radio-group';
import Validation, {
  required,
  validateDateTime,
  validateDate,
} from 'components/form/validation/validation';
import NoteArea from 'components/note/NoteArea';
import {
  EDIT_SCHEDULE_OUTREACH_FORM,
  SCHEDULE_OUTREACH_STATUS_COMPLETED,
  SCHEDULE_OUTREACH_STATUS_REQUIRED,
  SCHEDULE_OUTREACH_STATUS_SCHEDULED,
  SCHEDULE_OUTREACH_STATUS_WILL_NOT_MEET,
  SCHEDULE_OUTREACH_STATUS_COMMUNICATION_PHONE_ID,
  SCHEDULE_OUTREACH_STATUS_COMMUNICATION_INPERSON_ID,
  SCHEDULE_OUTREACH_STATUS_COMPLETED_REASON_PHONE_ID,
  SCHEDULE_OUTREACH_STATUS_COMPLETED_REASON_INPERSON_ID,
  SCHEDULE_OUTREACH_STATUS_UNABLE_TO_MEET,
  SCHEDULE_OUTREACH_STATUS_OFFER_LATER_DATE,
} from 'constants/index';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { convertToArborDate } from 'models/time/arbor-date';
import { parseNoteToDb } from 'services/utils/note-service';
import { getReasonsByStatus } from 'services/utils/task-service';
import { translateUsers } from 'services/utils/users-service';
import { styles } from './schedule-outreach-styles';

function ifNeedSubreasons(statusId) {
  // 10006 Will not meet patient - unable to see
  if (statusId === 10006) {
    return true;
  }
  return false;
}

class ScheduleOutreachStatus extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = { selectStatus: props.taskStatus }; //eslint-disable-line
  }

  handleSelect(e) {
    this.setState({
      selectStatus: e.target.value, //eslint-disable-line
    });
  }

  // Handles the submit functionality. Right now it sets some fields to null for simplicity's sake
  handleSubmit(values) {
    const {
      editScheduleOutreach, // eslint-disable-line
      fetchTaskCounts, // eslint-disable-line
      workListChanged, // eslint-disable-line
      task,
      reset,
      statuses,
      selectedStatus,
      addNote, // eslint-disable-line
      patient,
      tagTypeId,
      tagResourceId,
      cancel,
    } = this.props;

    const statusWithReasons = getReasonsByStatus(statuses, selectedStatus);
    // eslint-disable-next-line
    let statusId =
      statusWithReasons && statusWithReasons[0] && statusWithReasons[0].label
        ? values.status_reason_id
        : statusWithReasons[0].value;
    // Add special handling for status because we are not showing the reasons selector
    // instead we infer it from the communication type.
    if (statusWithReasons[0].value === SCHEDULE_OUTREACH_STATUS_COMPLETED_REASON_INPERSON_ID) {
      if (values.communication_type_id === SCHEDULE_OUTREACH_STATUS_COMMUNICATION_INPERSON_ID) {
        statusId = SCHEDULE_OUTREACH_STATUS_COMPLETED_REASON_INPERSON_ID;
      } else {
        statusId = SCHEDULE_OUTREACH_STATUS_COMPLETED_REASON_PHONE_ID;
      }
    }

    const payload = {
      ...values,
      id: task.id,
      communication_type_id: values.communication_type_id,
      additional_reason: ifNeedSubreasons(values.status_reason_id) ? values.additional_reason : '',
      followup_dt: convertToArborDate(values.followup_dt).getUtcDatetime(),
      last_checked_dt: convertToArborDate(values.last_checked_dt).getUtcDatetime(),
      scheduled_date: convertToArborDate(values.scheduled_date).getUtcDate(),
      will_not_meet_with_patient_date: convertToArborDate(
        values.will_not_meet_with_patient_date,
      ).getUtcDate(),
      completed_date: convertToArborDate(values.completed_date).getUtcDate(),
      status_id: statusId,
    };

    if (values.note_text) {
      const newValues = parseNoteToDb(values);
      const tags = [{ tag_type_id: tagTypeId, resource_id: tagResourceId }];
      const payloadNotes = {
        patient_id: patient.id,
        note_type_id: 1,
        note_text: newValues.note_text,
        is_pinned: 0,
        mentions: newValues.mentions,
        tags,
      };
      addNote(payloadNotes);
    }
    editScheduleOutreach(payload).then(() => {
      workListChanged();
      fetchTaskCounts();
      reset();
      cancel();
    });
  }

  render() {
    const { handleSubmit, submitting, classes, selectedStatus, task, cancel, users, statuses } =
      this.props;

    const statusReasons = getReasonsByStatus(statuses, selectedStatus);

    const userData = users && users.length > 0 && translateUsers(users);

    const radioProps = {
      radius: 5,
    };

    const renderCommunicationType = ({ input, meta: { touched, error, warning } }) => (
      <Grid container justifyContent="center">
        <RadioGroup
          horizontal
          name="communication_type_id"
          id="communication_type_id"
          width="80%"
          {...input}
          className={classes.radioGroup}
        >
          <RadioButton {...radioProps} value={SCHEDULE_OUTREACH_STATUS_COMMUNICATION_PHONE_ID}>
            <Typography variant="body2">Phone</Typography>
          </RadioButton>
          <RadioButton {...radioProps} value={SCHEDULE_OUTREACH_STATUS_COMMUNICATION_INPERSON_ID}>
            <Typography variant="body2">In Person</Typography>
          </RadioButton>
        </RadioGroup>
        <Validation touched={touched} error={error} warning={warning} />
      </Grid>
    );

    return (
      <Grid container justifyContent="center" spacing={7}>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(this.handleSubmit)} autoComplete="off">
            {selectedStatus === SCHEDULE_OUTREACH_STATUS_REQUIRED &&
              selectedStatus !== task.status && (
                <Grid container className={classes.communicationContainer}>
                  <Grid item xs={3}>
                    <Field
                      name="communication_type_id"
                      component={renderCommunicationType}
                      validate={[required]}
                    />
                  </Grid>
                  <Grid item xs={3} className={classes.fieldContainer}>
                    <Field
                      name="last_checked_dt"
                      label="Last Checked Date"
                      component={renderDatePicker}
                      validate={[validateDateTime]}
                    />
                  </Grid>
                  <Grid item xs={3} />
                  <Grid item xs={3}>
                    <Field name="note_text" data={userData} component={NoteArea} />
                  </Grid>
                </Grid>
              )}
            {selectedStatus === SCHEDULE_OUTREACH_STATUS_SCHEDULED &&
              selectedStatus !== task.status && (
                <Grid container>
                  <Grid item xs={3}>
                    <Field
                      name="communication_type_id"
                      component={renderCommunicationType}
                      validate={[required]}
                    />
                  </Grid>
                  <Grid item xs={3} className={classes.fieldContainer}>
                    <Field
                      name="followup_dt"
                      label="Followup Date *"
                      component={renderDateTimePicker}
                      validate={[validateDateTime, required]}
                    />
                  </Grid>
                  <Grid item xs={3} className={classes.fieldContainer}>
                    <Field
                      name="scheduled_date"
                      component={renderDatePicker}
                      label="Scheduled Date *"
                      validate={[validateDate, required]}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field name="note_text" data={userData} component={NoteArea} />
                  </Grid>
                </Grid>
              )}
            {selectedStatus === SCHEDULE_OUTREACH_STATUS_WILL_NOT_MEET &&
              selectedStatus !== task.status && (
                <Grid container>
                  <Grid item xs={3} className={classes.fieldContainer}>
                    <Field
                      name="will_not_meet_with_patient_date"
                      component={renderDatePicker}
                      label="Will not meet with patient Date *"
                      validate={[validateDate, required]}
                    />
                  </Grid>
                  {statusReasons && (
                    <Grid item xs={5} className={classes.formRowContainer}>
                      <Field
                        name="status_reason_id"
                        label={`Reason for ${selectedStatus} status *`}
                        component={renderDropdown}
                        fields={statusReasons}
                        validate={[required]}
                      />
                    </Grid>
                  )}

                  <Grid item xs={3}>
                    <Field name="note_text" data={userData} component={NoteArea} />
                  </Grid>
                </Grid>
              )}
            {selectedStatus === SCHEDULE_OUTREACH_STATUS_COMPLETED &&
              selectedStatus !== task.status && ( // eslint-disable-line
                <Grid container>
                  <Grid item xs={3}>
                    <Field
                      name="communication_type_id"
                      component={renderCommunicationType}
                      validate={[required]}
                    />
                  </Grid>
                  <Grid item xs={3} className={classes.fieldContainer}>
                    <Field
                      name="completed_date"
                      component={renderDatePicker}
                      label="Completed Date *"
                      validate={[validateDate, required]}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field name="note_text" data={userData} component={NoteArea} />
                  </Grid>
                </Grid>
              )}

            {selectedStatus === SCHEDULE_OUTREACH_STATUS_UNABLE_TO_MEET &&
              selectedStatus !== task.status && (
                <Grid container>
                  {statusReasons && (
                    <Grid item xs={5} className={classes.formRowContainer}>
                      <Field
                        name="status_reason_id"
                        label={`Reason for ${selectedStatus} status *`}
                        component={renderDropdown}
                        fields={statusReasons}
                        validate={[required]}
                      />
                    </Grid>
                  )}
                  <Grid item xs={3} className={classes.fieldContainer}>
                    <Field
                      name="followup_dt"
                      label="Followup Date *"
                      component={renderDateTimePicker}
                      validate={[validateDateTime, required]}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field name="note_text" data={userData} component={NoteArea} />
                  </Grid>
                </Grid>
              )}

            {selectedStatus === SCHEDULE_OUTREACH_STATUS_OFFER_LATER_DATE &&
              selectedStatus !== task.status && (
                <Grid container>
                  {statusReasons && (
                    <Grid item xs={5} className={classes.formRowContainer}>
                      <Field
                        name="status_reason_id"
                        label={`Reason for ${selectedStatus} status *`}
                        component={renderDropdown}
                        fields={statusReasons}
                        validate={[required]}
                      />
                    </Grid>
                  )}
                </Grid>
              )}

            {selectedStatus !== task.status && (
              <ConfirmationPanel
                cancelButtonName="schedule_outreach_status_cancel_button"
                submitButtonName="schedule_outreach_status_submit_button"
                handleCancel={cancel}
                disableSubmit={submitting}
              />
            )}
          </form>
        </Grid>
      </Grid>
    );
  }
}

function handleFormName(task) {
  const name = `${EDIT_SCHEDULE_OUTREACH_FORM}_status_${task.id}`;
  return name;
}

function handleFields(task) {
  if (!task) {
    return null;
  }
  return {
    communication_type_id: task.communication_type_id && task.communication_type_id.toString(),
    followup_dt: convertToArborDate(task.followup_dt, true).getCustomerDate(
      true,
      'MM/DD/YYYY hh:mm A',
    ),
    scheduled_date: convertToArborDate(task.scheduled_date, true).getCustomerDate(true),
    will_not_meet_with_patient_date: convertToArborDate(task.will_not_meet_with_patient_date, true) // eslint-disable-line
      .getCustomerDate(true),
    completed_date: convertToArborDate(task.completed_date, true).getCustomerDate(true),
    last_checked_dt: convertToArborDate(task.last_checked_dt, true).getCustomerDatetime(
      true,
      'MM/DD/YYYY hh:mm A',
    ),
    status_id: task.status_id,
  };
}

function mapStateToProps(state, props) {
  const { patient } = state;
  const formName = handleFormName(props.task);
  const selector = formValueSelector(formName);
  const formStatusId = selector(state, 'status_reason_id');
  return {
    patient,
    formStatusId,
    form: formName,
    initialValues: handleFields(props.task),
    users: state.lookups.users,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    editScheduleOutreach,
    fetchTaskCounts,
    workListChanged,
    addNote,
  }),
)(reduxForm({})(ScheduleOutreachStatus));

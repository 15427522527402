export const styles = theme => ({
  searchBarContainer: {
    width: '100%',
    maxWidth: 500,
    marginTop: 0,
  },
  searchInputContainer: {
    width: '100%',
    height: '100%',
  },
  searchInput: {
    width: '100%',
    height: '100%',
    border: 0,
    outlineWidth: 0,
  },
  suggestionContainer: {
    borderBottom: theme.palette.primary.patinaWhite,
    borderLeft: theme.palette.primary.patinaWhite,
    borderRight: theme.palette.primary.patinaWhite,
    borderTop: theme.palette.primary.grey2,
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    borderRadius: '0 0 2px 2px',
  },
  suggestionItem: {
    'padding': 8,
    'textAlign': 'left',
    'backgroundColor': theme.palette.primary.white,
    'cursor': 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.grey2,
    },
  },
  fieldLabel: {
    fontSize: theme.font.formLabelSize,
    fontWeight: theme.font.weight.regular,
    color: theme.palette.primary.formLabel,
    minHeight: theme.layout.taskFormLabelMinHeight + 3,
  },
});

export const styles = theme => ({
  modal: {
    position: 'absolute',
    width: theme.spacing(150),
    height: '80%',
    backgroundColor: theme.palette.primary.background,
    boxShadow: theme.shadows[5],
    borderRadius: 5,
    overflow: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 20,
  },
  formContainer: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: 5,
    padding: 15,
    margin: '15px 30px 30px 30px',
  },
  field: {
    width: '75%',
  },
  header: {
    marginLeft: 30,
  },
  footer: {
    marginLeft: 30,
    color: theme.palette.primary.darkRed,
  },
  bold: {
    fontWeight: 'bold',
  },
  fieldValue: {
    fontWeight: 'bold',
  },
  formBody: {
    fontSize: 16,
  },
  errorText: {
    paddingLeft: 34,
    color: theme.palette.error.main,
  },
  subFormContainer: {
    paddingLeft: 10,
    paddingRight: 10,
  },
});

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

const styles = (theme: Theme) => {
  const { palette } = expandTheme(theme);
  const arrowWidthPercent = 2;
  const sliderWidthPercent = 100 - arrowWidthPercent * 2;

  return createStyles({
    arrow: {
      color: palette.primary.lightGrey,
    },
    inline: {
      display: 'inline-block',
    },
    arrowContainer: {
      width: `${arrowWidthPercent}%`,
      verticalAlign: 'top',
    },
    sliderContainer: {
      width: `${sliderWidthPercent}%`,
    },
    fullHeight: {
      height: '100%',
    },
    hidden: {
      display: 'none',
    },
    pointer: {
      cursor: 'pointer',
    },
  });
};

export { styles };

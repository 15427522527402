import { Typography, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchTaskCounts } from 'actions/action-tasks';
import { ToggleLeftDrawer } from 'actions/action-view';
import { styles } from '../side-bar-styles';
import ReportList from './report-list';
import { CollapsibleSideBar } from '../collapsible-side-bar';

const withWidth = () => WrappedComponent => props => <WrappedComponent {...props} width="xs" />;

class ReportSideBar extends Component {
  componentDidMount() {
    const { onInitialize } = this.props;
    onInitialize();
  }

  render() {
    const { classes, onToggleDrawer, isLeftDrawerOpen, width } = this.props;

    const drawerInternal = (
      <div className={classes.drawerInternalDivContainer}>
        <Grid container direction="row">
          <Grid item xs={8}>
            <Typography variant="h6" className={classes.taskTitle}>
              Report
            </Typography>
          </Grid>
        </Grid>
        <Switch>
          <Route component={ReportList} />
        </Switch>
      </div>
    );
    return (
      <CollapsibleSideBar
        classes={classes}
        isLeftDrawerOpen={isLeftDrawerOpen}
        width={width}
        onToggleDrawer={onToggleDrawer}
      >
        {drawerInternal}
      </CollapsibleSideBar>
    );
  }
}

function mapStateToProps(state) {
  const { taskCounts, patient, tabControl, view } = state;
  const { patientTab } = tabControl;
  return {
    taskCounts,
    patient,
    patientTab,
    isLeftDrawerOpen: view.leftDrawer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSelectTask: taskType => {
      dispatch(new ToggleLeftDrawer(true));
    },
    onInitialize: () => dispatch(fetchTaskCounts()),
    onToggleDrawer: state => dispatch(new ToggleLeftDrawer(state)),
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withWidth(),
)(ReportSideBar);

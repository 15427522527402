import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { editPatient, workListChanged } from 'actions/action-patient';
import { resolveReconciliationPatient } from 'actions/action-reconciliation';
import Divider from 'components/divider';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { renderDropdown, renderTextField, renderCheckbox } from 'components/form/field/redux-field';
import SubHeader from 'components/form/header/subheader';
import { RenderAddresses, renderEmails, renderPhones } from 'components/form/subform/subform';
import { required, validateDate } from 'components/form/validation/validation';
import { EDIT_PATIENT_INFO_FORM } from 'constants/index';
import { genderList } from 'constants/lists';
import { convertToArborDate } from 'models/time/arbor-date';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { Field, FieldArray, getFormValues, reduxForm, formValueSelector } from 'redux-form';
import {
  archiveInfo,
  assignRankingAndKeys,
  processPhoneData,
  getCommunicationDisplay,
  getPatientNameForVerify,
} from 'services/utils/demographic-service';
import { addCommunicationToExcludedValues } from 'services/utils/reconciliation-service';
import { getAddressForDisplay } from 'services/utils/task-service';
import PatientDeceased from 'containers/patient/demographics/patient-deceased';
import { compareAny } from './common';
import { styles } from './reconciliation-styles';

export function ReconciliationPatientForm(props) {
  const [deceasedCancelFlag, setDeceasedCancelFlag] = useState(false);

  const {
    editPatient, // eslint-disable-line
    workListChanged, // eslint-disable-line
    reconciliationType,
    resolveReconciliationPatient, // eslint-disable-line
    classes,
    handleSubmit,
    cancelHandler,
    reset,
    formValues,
    change,
    isLastPatientCard,
    stagingPatient,
    patient,
    isDeceasedField,
    initialValues,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    if (deceasedCancelFlag) {
      dispatch(change('is_deceased', 0));
      setDeceasedCancelFlag(false);
    }
  });

  if (!formValues) {
    return null;
  }

  const patientName = getPatientNameForVerify(patient);

  const handleCancel = () => {
    cancelHandler();
    reset();
  };

  const reconcileDeceased = () => {
    resolveReconciliationPatient(
      'update',
      'patient',
      { ...initialValues, is_deceased: 1 },
      stagingPatient.excluded_values || {},
      isLastPatientCard,
      true,
    );
    cancelHandler();
  };

  const onSubmit = values => {
    const commonPayload = {
      id: patient.id,
      source_patient_id: stagingPatient.source_patient_id,
      is_verified: 1, // If in UI, consider verified
      verified_dt: convertToArborDate(new Date()).getUtcDatetime(),
    };
    let payload;
    switch (reconciliationType) {
      case 'patient':
        payload = {
          first_name: values.first_name,
          last_name: values.last_name,
          middle_name: values.middle_name,
          gender: values.gender,
          dob: values.dob ? convertToArborDate(values.dob).getUtcDate() : null,
          deceased: values.is_deceased ? 1 : 0,
        };
        break;
      case 'address':
        payload = {
          addresses: JSON.stringify(
            assignRankingAndKeys(
              archiveInfo(values.addresses, patient.addresses),
              values.preferred_address_index,
            ),
          ),
        };
        break;
      case 'email':
        payload = {
          emails: JSON.stringify(
            assignRankingAndKeys(
              archiveInfo(values.emails, patient.emails),
              values.preferred_email_index,
            ),
          ),
        };
        break;
      case 'phone':
        payload = {
          phones: JSON.stringify(
            processPhoneData(
              assignRankingAndKeys(
                archiveInfo(values.phones, patient.phones),
                values.preferred_phone_index,
              ),
              true,
            ),
          ),
        };
        break;
      default:
        break;
    }
    cancelHandler();
    const excludedValues = stagingPatient.excluded_values || {};
    Object.keys(payload).forEach(key => {
      if (key === 'addresses' && values && Array.isArray(values.addresses)) {
        const { reconciliationObjs } =
          stagingPatient && stagingPatient.addressReconcileInfo
            ? stagingPatient.addressReconcileInfo
            : [];
        const valuesArr =
          values && values.addresses ? values.addresses.map(a => getAddressForDisplay(a)) : [];
        const excludedArr =
          reconciliationObjs && reconciliationObjs.length > 0
            ? reconciliationObjs.filter(o => {
                const str = getAddressForDisplay(o);
                return !valuesArr.some(v => compareAny(v, str));
              })
            : [];
        addCommunicationToExcludedValues(key, excludedArr, excludedValues);
      } else if (key === 'emails' && Array.isArray(values.emails)) {
        const { reconciliationObjs } =
          stagingPatient && stagingPatient.emailReconcileInfo
            ? stagingPatient.emailReconcileInfo
            : [];
        const valuesArr =
          values && values.emails ? values.emails.map(e => getCommunicationDisplay(e)) : [];
        const excludedArr =
          reconciliationObjs && reconciliationObjs.length > 0
            ? reconciliationObjs.filter(o => {
                const str = getCommunicationDisplay(o);
                return !valuesArr.some(v => compareAny(v, str));
              })
            : [];
        addCommunicationToExcludedValues(key, excludedArr, excludedValues);
      } else if (key === 'phones' && Array.isArray(values.phones)) {
        const { reconciliationObjs } =
          stagingPatient && stagingPatient.phoneReconcileInfo
            ? stagingPatient.phoneReconcileInfo
            : [];
        const valuesArr =
          values && values.phones ? values.phones.map(e => getCommunicationDisplay(e)) : [];
        const excludedArr =
          reconciliationObjs && reconciliationObjs.length > 0
            ? reconciliationObjs.filter(o => {
                const str = getCommunicationDisplay(o);
                return !valuesArr.some(v => compareAny(v, str));
              })
            : [];
        addCommunicationToExcludedValues(key, excludedArr, excludedValues);
      } else if (key === 'dob') {
        if (payload.dob !== stagingPatient.original_dob_value) {
          excludedValues[key] = stagingPatient.original_dob;
        }
      } else if (!compareAny(payload[key], stagingPatient[`original_${key}`])) {
        excludedValues[key] = stagingPatient[`original_${key}`];
      }
    });
    const finalPayload = {
      ...commonPayload,
      ...payload,
    };
    resolveReconciliationPatient(
      'update',
      reconciliationType,
      finalPayload,
      excludedValues,
      isLastPatientCard,
    );
    return editPatient(finalPayload).then(() => {
      if (values.first_name !== patient.first_name || values.last_name !== patient.last_name) {
        workListChanged();
      }
    });
  };

  const renderPatientForm = type => {
    switch (type) {
      case 'patient': {
        return (
          <>
            <Grid container spacing={7}>
              <Grid item xs={12}>
                <SubHeader name="Personal Information" />
              </Grid>
            </Grid>
            <Grid container spacing={7}>
              <Grid item xs={3}>
                <Field
                  name="last_name"
                  label="Last Name *"
                  validate={[required]}
                  component={renderTextField}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="first_name"
                  label="First Name *"
                  validate={[required]}
                  component={renderTextField}
                />
              </Grid>
              <Grid item xs={3}>
                <Field name="middle_name" label="Middle Initial" component={renderTextField} />
              </Grid>
            </Grid>
            <Grid container spacing={7}>
              <Grid item xs={3}>
                <Field
                  name="dob"
                  label="Date of Birth"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  id="gender"
                  name="gender"
                  label="Gender"
                  validate={[required]}
                  component={renderDropdown}
                  fields={genderList}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="is_deceased"
                  label="Is Deceased"
                  component={renderCheckbox}
                  disabled={patient.deceased}
                />
              </Grid>
            </Grid>
            <Divider />
          </>
        );
      }
      case 'address': {
        return (
          <Grid container>
            <FieldArray
              name="addresses"
              formValues={formValues}
              preferredField="preferred_address_index"
              declineField="is_declined_to_provide_addresses"
              component={RenderAddresses}
              change={change}
              classes={classes}
              idPrefix="patient_form"
            />
          </Grid>
        );
      }
      case 'email': {
        return (
          <Grid container>
            <FieldArray
              name="emails"
              formValues={formValues}
              preferredField="preferred_email_index"
              declineField="email_options"
              component={renderEmails}
              change={change}
              classes={classes}
              idPrefix="patient_form"
            />
          </Grid>
        );
      }
      case 'phone': {
        return (
          <Grid container>
            <FieldArray
              name="phones"
              formValues={formValues}
              preferredField="preferred_phone_index"
              declineField="is_declined_to_provide_phones"
              component={renderPhones}
              change={change}
              classes={classes}
              idPrefix="patient_form"
            />
          </Grid>
        );
      }
      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.editForm} autoComplete="off">
      <Grid container alignItems="center">
        {renderPatientForm(reconciliationType)}
        {!!isDeceasedField && !patient.deceased && (
          <PatientDeceased
            verifyValue={{ name: patientName, id: patient.id }}
            isDeceasedType={isDeceasedField}
            cancelCallback={() => setDeceasedCancelFlag(true)}
            reconcileCallback={() => reconcileDeceased()}
          />
        )}
        {!!(!isDeceasedField || patient.deceased) && (
          <ConfirmationPanel
            handleCancel={handleCancel}
            disableSubmit={isDeceasedField && !patient.deceased}
            buttonIdPrefix="patient_form"
          />
        )}
      </Grid>
    </form>
  );
}

function mapStateToProps(state, props) {
  const { stagingPatient } = props;
  const { patient } = state;
  const selector = formValueSelector(EDIT_PATIENT_INFO_FORM);
  const isDeceasedField = selector(state, 'is_deceased');

  stagingPatient.original_dob_value =
    convertToArborDate(stagingPatient.original_dob, true).getUtcDate() || null;
  const initialValues = {
    first_name: stagingPatient.first_name,
    last_name: stagingPatient.last_name,
    middle_name: stagingPatient.middle_name,
    dob: convertToArborDate(stagingPatient.dob, true).getUtcDate(true) || null,
    gender: stagingPatient.gender,
    addresses: stagingPatient.filteredAddresses,
    emails: stagingPatient.filteredEmails,
    phones:
      Array.isArray(stagingPatient.filteredPhones) &&
      stagingPatient.filteredPhones.map(p => ({
        ...p,
        fromTime: '08:00',
        toTime: '20:00',
      })),
    preferred_address_index: 0,
    preferred_email_index: 0,
    preferred_phone_index: 0,
    is_deceased: stagingPatient.deceased,
  };
  const formValues = getFormValues(EDIT_PATIENT_INFO_FORM)(state) || initialValues;
  return {
    patient,
    isDeceasedField,
    form: EDIT_PATIENT_INFO_FORM,
    enableReinitialize: true,
    initialValues,
    formValues,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    editPatient,
    workListChanged,
    resolveReconciliationPatient,
  }),
)(reduxForm({})(ReconciliationPatientForm));

import { Moment } from 'moment';
import { ITask } from 'interfaces/redux/ITasks';

export enum ContactTypeLabel {
  Patient = 'Patient',
}

export enum ContactTypeValue {
  Patient = 1,
}

export interface IProps {
  handleCancel?: () => void;
  task?: ITask;
}

export interface IFormFields {
  date?: Moment;
  contact?: number;
  taskFollowUpDate?: Moment;
  messageType?: number | undefined | null;
  message?: string | undefined;
}

import { IPatient } from 'interfaces/redux/IPatient';
import { convertToArborDate } from 'models/time/arbor-date';

class PatientUtil {
  static convertPatientFromStateForUpdate = (patient: IPatient) => {
    // @ts-ignore
    const { filteredAddresses, filteredEmails, filteredPhones, tasks, ...restPatient } = patient;
    return {
      ...restPatient,
      addresses: patient.addresses ? JSON.stringify(patient.addresses) : null,
      languages: patient.languages ? JSON.stringify(patient.languages) : null,
      emails: patient.emails ? JSON.stringify(patient.emails) : null,
      phones: patient.phones ? JSON.stringify(patient.phones) : null,
      dob: convertToArborDate(patient.dob).getUtcDate(),
      verified_dt: convertToArborDate(patient.verified_dt).getUtcDatetime(),
      created: convertToArborDate(patient.created).getUtcDatetime(),
    };
  };
}

export { PatientUtil };

import React from 'react';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { styles } from './validation-styles';

interface IValidationTypographyProps {
  variant?: any;
  error: string;
  touched: boolean;
  classes: any;
}

const ValidationTypography: React.FC<IValidationTypographyProps> = (
  props: IValidationTypographyProps,
): JSX.Element | null => {
  return (
    <Typography variant={props.variant ?? 'caption'} className={props.classes.errorMessage}>
      {props.touched ? props.error : ''}
    </Typography>
  );
};

export const ValidationTypographyStyled = withStyles(styles as any, { withTheme: true })(
  ValidationTypography,
);

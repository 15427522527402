import { AxiosPromise } from 'axios';
import { ClinicalDataItem } from 'interfaces/clinical-data/ClinicalDataResponse';
import HTTP from '../services/http';

export class QuestionnaireClient {
  static saveAnswers = (
    patientId: number,
    questionnaireId: number,
    answers: JSON,
    workingAsGroup?: number[],
  ): AxiosPromise<ClinicalDataItem> => {
    const url = `patients/${patientId}/questionnaire/${questionnaireId}/answers`;
    return HTTP.patch(url, { answers: answers, workingAsGroup });
  };

  static submitQuestionnaire = (
    patientId: number,
    questionnaireId: number,
    workingAsGroup?: number[],
  ): AxiosPromise<ClinicalDataItem> => {
    const url = `patients/${patientId}/questionnaire/${questionnaireId}/submit`;
    return HTTP.post(url, { workingAsGroup });
  };

  static sendQuestionnaire = (payload: any): AxiosPromise<any> => {
    const url = '/questionnaires/message';
    return HTTP.post(url, payload);
  };

  static getActiveQuestionnaires = (payload: number[]): AxiosPromise<number[]> => {
    const url = '/questionnaires/get_questionnaire_from_active_dc_tasks';
    return HTTP.get(url, { params: { questionnaireIds: payload } });
  };
}

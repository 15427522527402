import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { expandTheme } from 'utils/theme-util';

const styles = (theme: Theme) => {
  const { palette } = expandTheme(theme);

  return createStyles({
    addModal: {
      position: 'absolute',
      width: theme.spacing(100),
      backgroundColor: palette.primary.background,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
      borderRadius: 5,
    },
    heading: {
      fontWeight: 'bold',
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      marginBottom: theme.spacing(2),
    },
    row: {
      marginBottom: theme.spacing(2),
    },
    lastRow: {
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
    error: {
      color: palette.primary.error,
    },
  });
};

export { styles };

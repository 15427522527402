import { ICounselingProtocol } from 'interfaces/counseling/ICounselingProtocol';
import { ITask } from 'interfaces/redux/ITasks';
import { ITherapy } from 'interfaces/redux/ITherapy';
import { TherapyUtil } from 'utils/therapy-util';

class Utils {
  static mergeContent = (
    title: string,
    startingContent: string | undefined,
    nextContent: string | undefined,
    separator: string,
  ): string | undefined => {
    return startingContent?.concat(
      nextContent ? `${title}${Utils.unwrapFromPTags(nextContent)}${separator}` : '',
    );
  };

  static buildDrugSubSections = (
    tasks: ITask[],
    therapies: Record<number, ITherapy>,
    protocolData: Record<number, ICounselingProtocol>,
  ): { drugSubSections: Record<string, Record<string, string>> } => {
    const result = tasks.reduce<{ drugSubSections: Record<string, Record<string, string>> }>(
      (accum: any, task: ITask) => {
        const drugName = TherapyUtil.getParagraphFormat(therapies[task.therapy_id]);
        const dotPhrases = protocolData[task.id]?.dotPhrase;
        if (drugName && dotPhrases) {
          accum.drugSubSections[drugName] = {
            ...dotPhrases,
          };
        }
        return accum;
      },
      { drugSubSections: {} },
    );

    return result;
  };

  /**
   * Wrap text or html in p tags
   * @param html string to be wrapped
   * @returns string wrapped in p tags , Ex: "hello world" -> <p>hello world</p>
   */
  static wrapWithPtags = (html: string | undefined): string | undefined =>
    html == null ? html : `<p>${html}</p>`;

  /**
   * Remove outer p tags from an html string
   * @param html html string wrapped in p tags (if there's not p tags it just keep it as it is)
   * @returns html string without outer (not that, only outer not replacing all p tags) p tags
   * Ex1: "<p>hello world</p>" -> hello world
   * Ex2: "<p><div><p>Hello world</p></div></p>" -> "<div><p>Hello world</p></div>"
   */
  static unwrapFromPTags = (html: string | undefined): string | undefined =>
    html == null
      ? html
      : html.replace(/^<p>(.*?)<\/p>$/gms, (_a, matchingGroup) => `${matchingGroup}`);
}

export { Utils };

import { convertToArborDate } from 'models/time/arbor-date';
import { Reducer } from 'redux';
import { SpecialPopulationAction } from 'actions/action-special-populations';
import {
  FETCH_SPECIAL_POPULATIONS_SUCCESS,
  ADD_SPECIAL_POPULATION,
  EDIT_SPECIAL_POPULATION,
} from '../constants/index';
import {
  ISpecialPopulation,
  ISpecialPopulationState,
} from '../interfaces/redux/ISpecialPopulation';

const convertData = (data: any): any => {
  if (!data) return null;
  data.end_date = convertToArborDate(data.end_date, true).getUtcDate(true);
  data.start_date = data.start_date
    ? convertToArborDate(data.start_date, true).getUtcDate(true)
    : null;
  return data;
};

const initialState: ISpecialPopulationState = {
  specialPopulationsList: [],
};

const specialPopulationsReducer: Reducer<ISpecialPopulationState, SpecialPopulationAction> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SPECIAL_POPULATIONS_SUCCESS: {
      if (payload) {
        return {
          ...state,
          specialPopulationsList: (payload as any).map((population: ISpecialPopulation) =>
            convertData(population),
          ),
        };
      }
      return state;
    }
    case ADD_SPECIAL_POPULATION: {
      return {
        ...state,
        specialPopulationsList: (payload as any).data.map((population: ISpecialPopulation) =>
          convertData(population),
        ),
      };
    }
    case EDIT_SPECIAL_POPULATION: {
      if (
        (payload as any).data &&
        (payload as any).data.updatedSpecialPopulation &&
        (payload as any).data.updatedSpecialPopulation.length > 0
      ) {
        const updatedSpecialPopulation = (payload as any).data.updatedSpecialPopulation[0];
        const newSpecialPopulationsList = state.specialPopulationsList.map(
          (population: ISpecialPopulation) => {
            if (population.id === updatedSpecialPopulation.id) {
              if (updatedSpecialPopulation.end_date) {
                updatedSpecialPopulation.end_date = convertData(updatedSpecialPopulation).end_date;
              }
              if (updatedSpecialPopulation.start_date) {
                updatedSpecialPopulation.start_date =
                  convertData(updatedSpecialPopulation).start_date;
              }
              return updatedSpecialPopulation;
            }
            return population;
          },
        );
        return {
          ...state,
          specialPopulationsList: newSpecialPopulationsList,
        };
      }

      return state;
    }
    default:
      return state;
  }
};

export default specialPopulationsReducer;

import { createStyles } from '@mui/styles';

export const styles = theme =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 600,
      margin: '0 auto',
      padding: '0 30px',
      boxSizing: 'border-box',
      [`${theme.breakpoints.down('xl')} and (orientation: landscape)`]: {
        maxWidth: 800,
      },
    },
  });

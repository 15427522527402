import React from 'react';
import { Grid, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { ActiveInactiveFormStyles } from './active-inactive-form.styles';

interface IVerifiedNotVerifiedFormProps extends WithStyles<typeof ActiveInactiveFormStyles> {
  handleSubmit: (value: boolean) => void;
}

const VerifiedNotVerifiedForm: React.FC<IVerifiedNotVerifiedFormProps> = (
  props: IVerifiedNotVerifiedFormProps,
): JSX.Element => {
  const { classes } = props;

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid
        item
        className={classes.option}
        onClick={() => {
          props.handleSubmit(true);
        }}
      >
        <Typography className={classes.optionType}>Verified</Typography>
      </Grid>
      <Grid
        item
        className={classes.option}
        onClick={() => {
          props.handleSubmit(false);
        }}
      >
        <Typography className={classes.optionType}>Not Verified</Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(ActiveInactiveFormStyles)(VerifiedNotVerifiedForm);

import React from 'react';
import { Grid, Typography } from '@mui/material';
import CalendarIcon from '@mui/icons-material/CalendarTodayTwoTone';

import { useTypedSelector } from 'hooks/use-typed-selector';
import { getClinicalDataTypesMap } from 'services/utils/data-collect';
import { IClinicalDataType } from 'interfaces/redux/IClinicalDataType';
import { styles } from '../../index.styles';
import { IFrequencyItem } from '../types';

const pluralize = (frequency: string, unit: number): string =>
  unit > 1 ? `${frequency}s` : frequency;

const Frequency: React.FC<{
  info: IFrequencyItem;
}> = ({ info }) => {
  const classes = styles();
  const clinicalDataTypesData = useTypedSelector(state => state.lookups.clinicalDataTypes);
  const clinicalDataType: Record<number, IClinicalDataType> =
    getClinicalDataTypesMap(clinicalDataTypesData);
  const dcItemToString = (dcItem: number) => `${dcItem} - ${clinicalDataType[dcItem].name}`;

  const { protocolFrequency, frequencyType, frequencyUnit, dcItems } = info;
  const frequencyName = protocolFrequency.charAt(0).toUpperCase() + protocolFrequency.slice(1);
  const frequency = frequencyType
    ? `${frequencyUnit} ${pluralize(frequencyType, Number(frequencyUnit))}`
    : '';
  const frequencyTitle = `${frequencyName} ${frequencyType ? `- ${frequency}` : ''}`;

  return (
    <Grid container spacing={4}>
      <Grid item xs="auto">
        <Typography className={classes.gpiFrequencyTitle}>
          <CalendarIcon className={classes.gpiFrequencyIcon} />
          <strong>{frequencyTitle}</strong>
        </Typography>
        <div>
          {dcItems.map(dcItem => {
            return (
              <span key={dcItem} className={classes.roundedGrayBackground}>
                {dcItemToString(dcItem)}
              </span>
            );
          })}
        </div>
      </Grid>
    </Grid>
  );
};

const FrequencyDetails: React.FC<{
  frequency: IFrequencyItem;
}> = ({ frequency }) => {
  const classes = styles();
  return (
    <Grid container justifyContent="space-between" className={classes.gpiExpandedTableRow}>
      <Grid item xs={12}>
        <Frequency info={frequency} />
      </Grid>
    </Grid>
  );
};

export default FrequencyDetails;

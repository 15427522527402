import React, { Fragment } from 'react';
import { convertToArborDate } from 'models/time/arbor-date';
import { makeStyles } from '@mui/styles';
import Label from 'components/display/label';
import { THERAPIES, THERAPY_ENROLLMENT_OPTOUT, SMS_RELATED_TASK } from 'constants/index';
import { PerProtocolIcon } from 'components/icons/icons';
import colors from '../../../../lib/themes/colors.module.scss';

const { white } = colors;

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
  },
  label: {
    flex: 1,
    display: 'inline-flex',
    maxWidth: 198,
    backgroundColor: white,
    height: 14,
  },
  therapy: {
    flex: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    height: 17,
    fontSize: 13,
  },
  date: {
    marginLeft: 7,
  },
  icon: {
    width: 28,
    height: 13,
  },
}));

const TaskListTherapyLabel = ({ patient, sidebarTaskType, task }) => {
  const classes = useStyles();
  const therapyName =
    patient.drug_name || patient.interacting_drugs || task?.specialtyDiagnosisName || 'N/A';
  const needsByDate =
    patient.needsby_date &&
    patient.dispensing_status_id !== THERAPY_ENROLLMENT_OPTOUT &&
    convertToArborDate(patient.needsby_date, true).getUtcDate(false, 'MM/DD');
  const content = (
    <>
      <div className={classes.therapy}>{therapyName}</div>
      {needsByDate && patient.taskType !== THERAPIES && (
        <div className={classes.date}>{needsByDate}</div>
      )}
      {needsByDate && Boolean(patient.is_needsby_per_protocol) && (
        <PerProtocolIcon className={classes.icon} />
      )}
    </>
  );

  const primaryLabel = (
    <Label
      key={`${patient.taskType}-${patient.id}`}
      className={classes.label}
      labelName={content}
      labelType="taskSidebarDescriptor"
    />
  );

  let additionalLabels = [];

  if (patient.additionalTags?.length > 0) {
    additionalLabels = patient.additionalTags
      .map(item => {
        if (item.tagTypeId === 2) {
          const therapyNameAdditional = item.drug_name || item.interacting_drugs || 'N/A';

          const needsByDateAdditional =
            item.needsby_date &&
            item.dispensing_status_id !== THERAPY_ENROLLMENT_OPTOUT &&
            convertToArborDate(item.needsby_date, true).getUtcDate(false, 'MM/DD');
          const contentAdditional = (
            <>
              <div className={classes.therapy}>{therapyNameAdditional}</div>
              {needsByDateAdditional && item.taskType !== THERAPIES && (
                <div className={classes.date}>{needsByDateAdditional}</div>
              )}
              {needsByDateAdditional && Boolean(item.is_needsby_per_protocol) && (
                <PerProtocolIcon className={classes.icon} />
              )}
            </>
          );
          return (
            <Label
              key={`${patient.taskType}-${patient.id}`}
              className={classes.label}
              labelName={contentAdditional}
              labelType="taskSidebarDescriptor"
            />
          );
        }
        return null;
      })
      .filter(item => item);
  }

  const finalLabels = (
    (therapyName === 'N/A' && additionalLabels.length > 0) ||
    (therapyName === 'N/A' && sidebarTaskType === SMS_RELATED_TASK)
      ? []
      : [primaryLabel]
  ).concat(additionalLabels);
  const finalContent = <div className={classes.root}>{finalLabels.map(entry => entry)}</div>;
  return finalContent;
};

export default TaskListTherapyLabel;

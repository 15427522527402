import HTTP from 'services/http';
import {
  CLEAR_TASK_FILTERS_BY_KEYS,
  UPDATE_TASK_FILTER_FROM_DATE,
  UPDATE_TASK_FILTER_TO_DATE,
  ADD_TASK_FILTER_SERVICE_GROUP,
  DELETE_TASK_FILTER_SERVICE_GROUP,
  UPDATE_SCHEDULE_FILTER_FROM_TO_DATES,
  UPDATE_SCHEDULE_FILTER_FROM_DATE,
  UPDATE_SCHEDULE_FILTER_TO_DATE,
  UPDATE_SCHEDULE_SERVICE_GROUP,
  UPDATE_SCHEDULE_CLINICS,
  UPDATE_REPORT_FILTER_FROM_DATE,
  UPDATE_REPORT_FILTER_TO_DATE,
  UPDATE_REPORT_SERVICE_GROUP,
  UPDATE_REPORT_CLINICS,
  UPDATE_REPORT_FILTERS,
  UPDATE_TASK_SERVICE_GROUP,
  UPDATE_TASK_CLINICS,
  FETCH_CUSTOMERS,
  SELECT_CUSTOMER,
  FETCH_EMRS,
  UPDATE_SCHEDULE_FILTERS,
} from 'constants/index';

export const updateTaskFilterFromDate = taskFromDate => ({
  type: UPDATE_TASK_FILTER_FROM_DATE,
  payload: Promise.resolve({ taskFromDate }),
});

export const updateTaskFilterToDate = taskToDate => ({
  type: UPDATE_TASK_FILTER_TO_DATE,
  payload: Promise.resolve({ taskToDate }),
});

export const addTaskFilterServiceGroup = taskServiceGroups => ({
  type: ADD_TASK_FILTER_SERVICE_GROUP,
  payload: Promise.resolve({ taskServiceGroups }),
});

export const deleteTaskFilterServiceGroup = taskServiceGroups => ({
  type: DELETE_TASK_FILTER_SERVICE_GROUP,
  payload: Promise.resolve({ taskServiceGroups }),
});

export const updateTaskFilterServiceGroup = taskServiceGroups => ({
  type: UPDATE_TASK_SERVICE_GROUP,
  payload: Promise.resolve({ taskServiceGroups }),
});

export const updateTaskClinics = taskClinics => ({
  type: UPDATE_TASK_CLINICS,
  payload: Promise.resolve({ taskClinics }),
});

export const updateScheduleFilters = (
  scheduleFromDate,
  scheduleToDate,
  scheduleServiceGroups,
  scheduleClinics,
) => ({
  type: UPDATE_SCHEDULE_FILTERS,
  payload: Promise.resolve({
    scheduleFromDate,
    scheduleToDate,
    scheduleServiceGroups,
    scheduleClinics,
  }),
});

export const updateReportFilters = (
  reportFromDate,
  reportToDate,
  reportServiceGroups,
  reportClinics,
) => ({
  type: UPDATE_REPORT_FILTERS,
  payload: Promise.resolve({
    reportFromDate,
    reportToDate,
    reportServiceGroups,
    reportClinics,
  }),
});

export const updateScheduleFilterFromAndToDates = (scheduleFromDate, scheduleToDate) => ({
  type: UPDATE_SCHEDULE_FILTER_FROM_TO_DATES,
  payload: Promise.resolve({ scheduleFromDate, scheduleToDate }),
});

export const updateScheduleFilterFromDate = scheduleFromDate => ({
  type: UPDATE_SCHEDULE_FILTER_FROM_DATE,
  payload: Promise.resolve({ scheduleFromDate }),
});

export const updateScheduleFilterToDate = scheduleToDate => ({
  type: UPDATE_SCHEDULE_FILTER_TO_DATE,
  payload: Promise.resolve({ scheduleToDate }),
});

export const updateScheduleFilterServiceGroup = scheduleServiceGroups => ({
  type: UPDATE_SCHEDULE_SERVICE_GROUP,
  payload: Promise.resolve({ scheduleServiceGroups }),
});

export const updateScheduleFilterClinics = scheduleClinics => ({
  type: UPDATE_SCHEDULE_CLINICS,
  payload: Promise.resolve({ scheduleClinics }),
});

export const updateReportFilterFromDate = reportFromDate => ({
  type: UPDATE_REPORT_FILTER_FROM_DATE,
  payload: Promise.resolve({ reportFromDate }),
});

export const updateReportFilterToDate = reportToDate => ({
  type: UPDATE_REPORT_FILTER_TO_DATE,
  payload: Promise.resolve({ reportToDate }),
});

export const updateReportFilterServiceGroup = reportServiceGroups => ({
  type: UPDATE_REPORT_SERVICE_GROUP,
  payload: Promise.resolve({ reportServiceGroups }),
});

export const updateReportFilterClinics = reportClinics => ({
  type: UPDATE_REPORT_CLINICS,
  payload: Promise.resolve({ reportClinics }),
});

export const fetchCustomers = () => {
  const request = HTTP.get('/customers', {});
  return {
    type: FETCH_CUSTOMERS,
    payload: request,
  };
};

export const selectCustomer = customerId => ({
  type: SELECT_CUSTOMER,
  payload: Promise.resolve({ customerId }),
});

export const fetchEmrs = () => {
  const request = HTTP.get('/customers/me/emrs', {}, true);
  return {
    type: FETCH_EMRS,
    payload: request,
  };
};

export const clearTaskFiltersByKeys = (keys, ids) => ({
  type: CLEAR_TASK_FILTERS_BY_KEYS,
  payload: {
    keys,
    ids,
  },
});

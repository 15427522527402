import React from 'react';
import { Controller } from 'react-hook-form';
import { renderPhoneTextField as RenderPhoneTextField } from 'components/form/field/redux-field';
import { IControlledFormField } from '../types';

interface IProps extends IControlledFormField {
  percentWith?: number;
}

export const ControlledTextPhone: React.FC<IProps> = (props: IProps): JSX.Element => {
  return (
    <Controller
      defaultValue={props.defaultValue}
      control={props.control}
      name={props.name}
      rules={props.validations || {}}
      render={(ctrlProps: any) => (
        <RenderPhoneTextField
          width={props.percentWith && `${props.percentWith}%`}
          label={props.label}
          input={{
            value: ctrlProps?.field?.value,
            onChange: ctrlProps?.field?.onChange,
            onBlur: ctrlProps?.field?.onBlur,
          }}
          meta={props.inputMetaData || {}}
          data-qa-id={props.qaId}
        />
      )}
    />
  );
};

import React from 'react';
import { Typography, Grid } from '@mui/material';
import JsonForm from 'components/dynamic-form/json-form';
import { styles } from './follow-up-data-collect-modal-style';

const FollowUpDataCollectComp = ({
  open,
  onSubmit,
  onCancel,
  instructions,
  marginTop,
  ...other
}) => {
  const classes = styles();
  return (
    <Grid container className={classes.mainBox}>
      {typeof instructions === 'string' ? (
        <Typography variant="body">{instructions}</Typography>
      ) : (
        instructions
      )}
      <JsonForm onSubmit={onSubmit} onCancel={onCancel} {...other} />
    </Grid>
  );
};

export default FollowUpDataCollectComp;

import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Add, Remove } from '@mui/icons-material';
import { styles } from './display-styles';

const ExpandingView = ({
  label,
  classes,
  noBorder,
  isExpanded,
  expandedContent,
  hideExpandIcon,
}) => {
  const [isExpandedVar, setIsExpandedVar] = useState(!!isExpanded || hideExpandIcon);

  return (
    <Grid item xs={12} className={noBorder ? null : classes.indentedLeft}>
      <Grid container alignItems="center" justifyContent="flex-start">
        {!hideExpandIcon && (
          <div>
            {isExpandedVar ? (
              <Remove
                onClick={() => setIsExpandedVar(!isExpandedVar)}
                onKeyPress={() => setIsExpandedVar(!isExpandedVar)}
                role="button"
                tabIndex={0}
                className={classes.expandingViewIcon}
              />
            ) : (
              <Add
                onClick={() => setIsExpandedVar(!isExpandedVar)}
                onKeyPress={() => setIsExpandedVar(!isExpandedVar)}
                role="button"
                tabIndex={0}
                className={classes.expandingViewIcon}
              />
            )}
          </div>
        )}
        <Typography
          className={classes.expandingViewLabel}
          onClick={() => setIsExpandedVar(!isExpandedVar)}
        >
          {label}
        </Typography>
      </Grid>
      <div className={noBorder ? classes.indentedLeft : classes.indentedLeftBorder}>
        {(isExpandedVar || hideExpandIcon) && expandedContent}
      </div>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(ExpandingView);

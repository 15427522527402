import { FillCoordinationStatusMap } from '../../../constants/task-statuses';
import { ITask } from '../../../interfaces/redux/ITasks';
import { TaskStatus } from '../../../interfaces/enums/TaskStatus';
import { ITaskStatusProcessor } from '../interfaces/TaskStatusProcessor';

class FillCoordinationProcessor implements ITaskStatusProcessor {
  taskIsEditable(task: ITask): boolean {
    return true;
  }

  taskShouldRenderCustomForm(task: ITask): boolean {
    return true;
  }

  taskShouldRenderCustomMultipleForms(tasks: ITask[]) {
    const groups = this.groupTasksByStatus(tasks);

    /**
     * The groups could be "complete" and "incomplete". If we have both, return true since
     * the complete ones will be their own form and
     * then all the incompletes will be grouped together
     */
    const result = Boolean(groups.complete.length) && Boolean(groups.incomplete.length);

    return result;
  }

  taskTransitioningToComplete(prevTask: ITask, currentTask: ITask): boolean {
    const prevTaskNotComplete =
      FillCoordinationStatusMap[prevTask.status_id]?.status !== TaskStatus.Completed;
    const currentTaskCompleted =
      FillCoordinationStatusMap[currentTask.status_id]?.status === TaskStatus.Completed;

    return prevTaskNotComplete && currentTaskCompleted;
  }

  taskIsComplete(task: ITask): boolean {
    const taskStatus = FillCoordinationStatusMap[task.status_id];
    return Boolean(taskStatus && taskStatus.status === TaskStatus.Completed);
  }

  taskIsReadOnly(task: ITask): boolean {
    const taskStatus = FillCoordinationStatusMap[task.status_id];
    return Boolean(taskStatus && taskStatus.readonly);
  }

  taskCanTransition(task: ITask): boolean {
    return true;
  }

  groupTasksByStatus(tasks: ITask[]): { complete: ITask[]; incomplete: ITask[] } {
    const result: { complete: ITask[]; incomplete: ITask[] } = {
      incomplete: [],
      complete: [],
    };

    tasks.forEach(task => {
      if (this.taskIsComplete(task)) {
        result.complete.push(task);
      } else {
        result.incomplete.push(task);
      }
    });

    return result;
  }
}

export { FillCoordinationProcessor };

import moment from 'moment';
import { IState } from 'interfaces/redux/IState';
import { IMedicationListItem, IMedications } from 'interfaces/redux/IMedications';
import { StringUtils } from 'utils/string-utils';
import { IAllergies, IAllergyListItem } from 'interfaces/redux/IAllergies';
import { ICounselingTask } from 'interfaces/redux/task-types/ICounselingTask';
import { ICollectedItem } from 'interfaces/redux/task-types/IDataCollectTask';
import { ALLERGY_ACTIVE_STRING } from 'constants/index';
import { getGroupedMedications } from 'hooks/getGroupedMedications';
import { TaskId, TherapyId } from 'interfaces/RecordTypes';
import { ICounselingProtocol } from 'interfaces/counseling/ICounselingProtocol';
import { ITherapy } from 'interfaces/redux/ITherapy';
import { TherapyUtil } from 'utils/therapy-util';
import { windowFeatureIsEnabled } from 'config/window-features';
import { getGroupedMedicationsByGpi } from 'hooks/getGroupedMedicationsByGpi';
import { getSideEffects } from './get-side-effects';
import { Utils } from '../documentation/utils';
import { ISideEffect } from '../education-and-counseling/side-effects/interfaces/ISideEffect';
import { ITherapies } from '../../../../../../interfaces/redux/ITherapies';
import { IResourceLinks } from '../../../../../../interfaces/redux/IResourceLinks';

const lineSeparator = '<br />';
const reviewedByEhrLanguage =
  '<strong>Medical History & Comorbidities:</strong><p>Problem list has been reviewed in the EHR</p>';

// Pick only first (latest) collected item for each dataTypeName (this works since they are sorted by date desc)
const uniqueItemsByDataTypeName = (collectedItems: ICollectedItem[]): ICollectedItem[] => {
  const pickedDataTypeNames = new Set<string>();
  const uniqueCollectedItems = [];

  for (const item of collectedItems) {
    if (!pickedDataTypeNames.has(item.dataTypeName)) {
      uniqueCollectedItems.push(item);
      pickedDataTypeNames.add(item.dataTypeName);
    }
  }

  return uniqueCollectedItems;
};

const getMedicationHtml = (
  therapies: Record<TherapyId, ITherapy>,
  medications: IMedicationListItem[],
  protocolData: Record<TaskId, ICounselingProtocol>,
): string | undefined => {
  if (medications && medications.length) {
    const medicationNames = medications
      .map(med => {
        return StringUtils.concatenate('&nbsp;', med.drug_name, med.strength_str);
      })
      .sort((a, b) => {
        if (a > b) {
          return 1;
        }
        if (b > a) {
          return -1;
        }
        return 0;
      });

    const supportiveDrugNames: string[] = [];
    /**
     * A data object that has the medication name as the key and an array of tasks as the value
     */
    const medTaskIdMap = Object.entries(protocolData).reduce<Record<string, number[]>>(
      (acc, [key, value]) => {
        value.supportiveMeds?.forEach(sMed => {
          if (sMed.checked) {
            if (!acc[sMed.name]) {
              acc[sMed.name] = [];
            }
            acc[sMed.name].push(value.therapyId);
          }
        });
        return acc;
      },
      {},
    );

    Object.entries(medTaskIdMap).forEach(([key, value]) => {
      const therapyNames = value.map(therapyId => {
        const therapy = therapies[therapyId];
        return TherapyUtil.getParagraphFormat(therapy);
      });
      supportiveDrugNames.push(`${key} for ${therapyNames.join(', ')}`);
    });

    const medicationText = `<strong>Medications:</strong>${lineSeparator}${StringUtils.concatenate(
      lineSeparator,
      ...medicationNames,
      lineSeparator,
    )}`;

    const supportiveMedicationText = `${lineSeparator}<strong>Supportive Medications:</strong>${lineSeparator}${StringUtils.concatenate(
      lineSeparator,
      ...supportiveDrugNames,
      lineSeparator,
    )}`;

    return medicationText + (supportiveDrugNames.length ? supportiveMedicationText : []);
  }

  return undefined;
};

const getAllergiesHtml = (allergies: IAllergyListItem[]): string | undefined => {
  const allergyLabel = `<strong>Allergies:</strong>${lineSeparator}`;
  if (
    allergies &&
    allergies.length &&
    allergies.some(allergy => allergy.status === ALLERGY_ACTIVE_STRING)
  ) {
    const allergyNames = allergies
      .filter(allergy => allergy.status === ALLERGY_ACTIVE_STRING)
      .map(x => x.allergen.name);
    const allergyHtml =
      allergyLabel + StringUtils.concatenate(lineSeparator, ...allergyNames, lineSeparator);
    return allergyHtml;
  }
  const allergyHtml = `${allergyLabel}No known drug allergies.${lineSeparator}`;
  return allergyHtml;
};

const getDataCollectHtml = (collectedItems: ICollectedItem[]): string | undefined => {
  if (collectedItems.length) {
    const itemsCollectedString = collectedItems.map(
      (itemCollected: ICollectedItem) =>
        `${itemCollected.assessmentDate} - ${itemCollected.dataTypeName} = ${itemCollected.value}`,
    );
    const itemsCollectedHtml = `<strong>Data:</strong>${lineSeparator.repeat(
      2,
    )}${StringUtils.concatenate(lineSeparator, ...itemsCollectedString, lineSeparator)}`;

    return itemsCollectedHtml;
  }
  return undefined;
};

const getSideEffectsHtml = (sideEffects: ISideEffect[]): string | undefined => {
  if (sideEffects.length) {
    const sideEffectStrings: string[] = [];
    sideEffects.forEach((sideEffect: ISideEffect) => {
      // Per Adherence side effect has not reaction or causedBy
      if (sideEffect.reaction && sideEffect.causedBy) {
        sideEffectStrings.push(
          `${sideEffect.reaction}, suspected caused by ${sideEffect.causedBy.text}`,
        );
      }
    });

    const sideEffectsHtml = `<strong>Side Effects:</strong>${lineSeparator}${StringUtils.concatenate(
      lineSeparator,
      ...sideEffectStrings,
      lineSeparator,
    )}`;

    return sideEffectsHtml;
  }
  return undefined;
};

interface IPartialState {
  medications: IMedications;
  therapies: ITherapies;
  allergies: IAllergies;
  links: IResourceLinks;
}
const getPatientObjectiveText = (
  currentSelectedTasks: ICounselingTask[],
  state: IPartialState,
  protocolData: Record<TaskId, ICounselingProtocol>,
): { content: string; secondaryContent: string; initialContent: string } => {
  const sectionSeperator = lineSeparator;

  const allergyList = state.allergies.allergiesList;
  const filteredMedicationList = getGroupedMedicationsByGpi(state.medications.medicationGroups);
  const sideEffects = getSideEffects(state, currentSelectedTasks);

  const medicationHtml = getMedicationHtml(
    state.therapies.data,
    Object.values(filteredMedicationList),
    protocolData,
  );
  const allergiesHtml = getAllergiesHtml(allergyList);
  const sideEffectsHtml = getSideEffectsHtml(sideEffects);

  const result = Utils.wrapWithPtags(
    StringUtils.concatenate(
      sectionSeperator,
      // dataCollectHtml,
      medicationHtml,
      sideEffectsHtml,
      allergiesHtml,
      reviewedByEhrLanguage,
    ),
  );

  return {
    content: result || '',
    initialContent: result || '',
    secondaryContent: '',
  };
};

export { getPatientObjectiveText, getMedicationHtml, getAllergiesHtml };

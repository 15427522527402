import {
  UPDATE_DOCUMENT_BAR,
  ADD_PATIENT_DOCUMENT,
  UPDATE_PATIENT_DOCUMENT,
} from 'constants/index';

export const updateDocumentBar = state => ({
  type: UPDATE_DOCUMENT_BAR,
  payload: state,
});

export const addNewDocument = newDocument => ({
  type: ADD_PATIENT_DOCUMENT,
  payload: newDocument,
});

export const updateNewDocument = document => ({
  type: UPDATE_PATIENT_DOCUMENT,
  payload: document,
});

import { SELECT_PATIENT_TAB, TOGGLE_TAB_REFRESH } from '../constants';

const initialState = {
  patientTab: 'therapies',
  refresh: false,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SELECT_PATIENT_TAB:
      return {
        ...state,
        patientTab: payload,
      };
    case TOGGLE_TAB_REFRESH: {
      return {
        ...state,
        refresh: payload,
      };
    }
    default:
      return state;
  }
};

import React, { useEffect, useRef, useState } from 'react';
import lodash from 'lodash';
import cx from 'classnames';
import { makeStyles } from '@mui/styles';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles({
  outer: {
    overflowY: 'auto',
    maxHeight: 120,
  },
  progress: {
    padding: 10,
    margin: '0 auto',
    display: 'block',
  },
});

const InfiniteScroller = ({ isLoading, onScrollToBottom, children, windowLevel, className }) => {
  const outerEl = useRef(null);
  const innerEl = useRef(null);
  const classes = useStyles();
  const [lastHeight, setLastHeight] = useState(0);
  const outerElement = windowLevel ? window : outerEl.current;
  const innerElement = windowLevel ? document.documentElement : innerEl.current;

  useEffect(() => {
    const eventHandler = lodash.debounce(() => {
      if (isLoading) return;
      const reachedBottom = windowLevel
        ? innerElement &&
          outerElement &&
          innerElement.offsetHeight > lastHeight &&
          innerElement.scrollTop + outerElement.innerHeight >= innerElement.offsetHeight
        : innerElement &&
          outerElement &&
          innerElement.scrollHeight > lastHeight &&
          innerElement.scrollHeight === outerElement.scrollTop + outerElement.clientHeight;
      if (onScrollToBottom && reachedBottom) {
        setLastHeight(windowLevel ? innerElement.offsetHeight : innerElement.scrollHeight);
        onScrollToBottom();
      }
    }, 100);

    if (outerElement) {
      outerElement.onscroll = eventHandler;
    }
    return () => {
      if (outerElement) {
        outerElement.onscroll = null;
      }
    };
  });

  useEffect(() => {
    if (innerElement) {
      const currentHeight = windowLevel ? innerElement.offsetHeight : innerElement.scrollHeight;
      if (currentHeight < lastHeight - 50) {
        setLastHeight(0);
      }
    }
    if (outerElement) {
      outerElement.onscroll();
    }
  }, [children]);

  useEffect(() => {
    if (isLoading && outerElement) {
      outerElement.scrollTop += 50;
    }
  }, [isLoading]);

  const loading = isLoading && <CircularProgress size={30} className={classes.progress} />;

  return windowLevel ? (
    <div ref={innerEl} className={className}>
      {children}
      {loading}
    </div>
  ) : (
    <div ref={outerEl} className={cx(classes.outer, className)}>
      <div ref={innerEl}>
        {children}
        {loading}
      </div>
    </div>
  );
};

export default InfiniteScroller;

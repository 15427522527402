import React, { useEffect } from 'react';
import JsonForm from 'components/dynamic-form/json-form';
import { useDispatch, useSelector } from 'react-redux';
import { ShippingClient } from 'clients/shipping-client';
import { notifyError } from 'actions/action-notifications';
import providerBuilder from '../providers/provider-builder';
import taskProviderHoc from './task-form-hoc';
import { FDC } from '../../../constants/index';

const TaskDetailForm = props => {
  const { taskType, task, taskStatusId, paymentMethods } = props;
  const [dataState, setDataState] = React.useState();
  const listOfPharmacies = useSelector(state => state.pharmacies?.pharmacies || []);
  const [pharmacies, setPharmacies] = React.useState(listOfPharmacies);
  const [providers, setProviders] = React.useState(null);
  const dispatch = useDispatch();

  async function getFCData(orderId) {
    try {
      const response = await ShippingClient.fetch(orderId, 'task-detail-form');
      if (response.data.error) {
        dispatch(notifyError(response.data.error));
      } else {
        setDataState(response.data);
      }
    } catch (_error) {
      dispatch(notifyError('Failed to load order details.'));
    }
  }

  // Therapies Tab - Archived FDC
  useEffect(() => {
    if (task.order_id && !dataState && taskType === FDC) {
      getFCData(task.order_id);
    }
  }, [task.order_id]);

  useEffect(() => {
    setProviders(
      providerBuilder(taskType)(
        task,
        { ...props, pharmacies, paymentMethods: { ...paymentMethods } },
        dataState,
      ),
    );
  }, [paymentMethods]);

  return providers ? (
    <JsonForm
      jsonForm={providers.json}
      formName="view"
      formId={`view-${taskType}-${task.id}`}
      data={task}
      providers={providers}
      readOnly
      taskStatusId={taskStatusId}
    />
  ) : null;
};

export default taskProviderHoc(TaskDetailForm, true);

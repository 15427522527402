import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PoweredBy from '../components/powered-by';
import Paragraph from '../components/paragraph';
import Container from '../components/container';
import WidthController from '../components/width-controller';
import Button from '../components/button';

const useStyles = makeStyles(() => ({
  container: {
    alignSelf: 'center',
  },
}));

export default ({ onNext }) => {
  const classes = useStyles();
  return (
    <Container title="Medication effectiveness questionnaire">
      <WidthController className={classes.container}>
        <Paragraph type="title" color="secondary">
          Next questionnaire
        </Paragraph>
        <Paragraph type="subTitle">
          Thank you for completing the questionnaire. Now please complete the next questionnaire
        </Paragraph>
        <Button onClick={() => onNext()} variant="bordered">
          Start next questionnaire
        </Button>
        <PoweredBy />
      </WidthController>
    </Container>
  );
};

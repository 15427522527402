export const styles = theme => ({
  fabContainer: {
    position: 'fixed',
    bottom: '140px',
    right: '0px',
    zIndex: 2,
  },
  fabButton: {
    'color': theme.palette.primary.white,
    'backgroundColor': theme.palette.primary.deepBlue,
    'height': '48px',
    'width': '48px',
    'borderBottomLeftRadius': '10px',
    'borderTopLeftRadius': '10px',
    'cursor': 'pointer',
    'padding': '18px',
    'boxSizing': 'border-box',
    '& svg': {
      'width': '24px',
      'height': '24px',
      'top': '13px',
      'position': 'absolute',
      'left': '15px',
      '& #Fill-768': {
        fill: theme.palette.primary.success,
      },
      '& #Fill-769': {
        fill: theme.palette.primary.success,
      },
      '& #Fill-770': {
        fill: theme.palette.primary.success,
      },
      '& #Fill-771': {
        fill: theme.palette.primary.success,
      },
    },
  },
  fabControlPanel: {
    position: 'absolute',
    right: '0px',
    bottom: '75px',
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.deepBlue,
    minWidth: 400,
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    padding: '20px',
    paddingBottom: '0px',
    boxSizing: 'border-box',
  },
  fabControlPanelMenuItem: {
    'fontWeight': 600,
    '&:nth-child(2n+1)': {
      borderRight: '1px solid',
      borderColor: theme.palette.primary.trellisBlue,
      paddingBottom: theme.spacing(2),
    },
    'cursor': 'pointer',
    '&:hover': {
      color: theme.palette.primary.success,
    },
  },
  patientName: {
    fontWeight: 600,
    fontSize: theme.font.largeFontSize,
  },
});
